import { FC, useRef } from "react";
import { IManageModal } from "@interface/IGlobal.interface";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { PRE_REGISTRATIONMARK } from "@assets/data/pathRouter";
import { useGetDraftOrderListMutation } from "@services/api/draft_order";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Button, Col, Divider, Modal, Row } from "antd";
import PatientList from "./PatientList";
import MainSearchPatientProvider from "@provider/search_patient";

type Props = {
  visible: IManageModal;
};

const SearchPatient: FC<Props> = ({ visible }) => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const patientId = useRef([0]);
  const match: any = useRouteMatch();
  const eventID = match.params.eventID;
  const draftOrderList = useGetDraftOrderListMutation();

  const savePatient = () => {
    draftOrderList?.mutate(
      { patientId: patientId?.current?.[0], eventId: eventID },
      {
        onSuccess: () => {
          openNotification({
            method: "POST_ERROR",
            message: t("clientInformationHasBeenRegisteredForThisEvent"),
          });
        },
        onError: () => {
          history.replace(
            `${PRE_REGISTRATIONMARK.event}/${eventID}/old-customer/patient/${patientId?.current?.[0]}`
          );
          onCancel();
        },
      }
    );
  };

  const onCancel = () => {
    visible.setVisible(false);
  };

  return (
    <MainSearchPatientProvider
      value={{
        patientId: patientId,
      }}
    >
      <Modal
        className="manage-modal"
        title={
          <>
            <Row>
              <Col className="t-blue ">
                <b>{t("allNursery")}</b>
              </Col>
            </Row>
          </>
        }
        centered
        visible={visible?.visible}
        onCancel={onCancel}
        width="90%"
        forceRender={true}
        footer={null}
      >
        <div className="scroll">
          <div className="p-1">
            <PatientList />
          </div>
        </div>

        <Row gutter={[14, 14]}>
          <Col span={24}>
            <Divider
              className="m-0 "
              style={{ position: "absolute", top: 0 }}
            />
            <Row
              gutter={[14, 14]}
              justify="end"
              align="middle"
              className="footer"
            >
              <Col>
                <Button className="cancel " key="back" onClick={onCancel}>
                  {t("cancel")}
                </Button>
              </Col>
              <Col className="mr-05">
                <Button
                  className="btn-create "
                  loading={draftOrderList?.isLoading}
                  onClick={savePatient}
                >
                  {t("save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </MainSearchPatientProvider>
  );
};

export default SearchPatient;
