import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import dayjs from "dayjs";

type Props = {
  setSearchValue: (value: any) => void;
  setCurrentPage: (e: any) => void;
};

const Search = ({ setSearchValue, setCurrentPage }: Props) => {
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const dateFormat = "DD-MM-YYYY";

  const onFinish = (values: any) => {
    const data = {
      ...values,
      startDate: values?.date?.[0]
        ? dayjs(values?.date?.[0]).format("YYYY-MM-DD")
        : undefined,
      endDate: values?.date?.[1]
        ? dayjs(values?.date?.[1]).format("YYYY-MM-DD")
        : undefined,
    };
    setSearchValue(data);
    setCurrentPage(1);
  };
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col sm={7} lg={7} xs={9} xl={9} xxl={9}>
            <Form.Item name="search">
              <Input placeholder={t("searchEvent")} />
            </Form.Item>
          </Col>

          <Col sm={8} lg={9} xs={10} xl={10} xxl={11}>
            <Form.Item name="date">
              <RangePicker format={dateFormat} />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
