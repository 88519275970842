import { useTranslation, Trans } from "react-i18next";
import { Col, Form, Input, Row } from "antd";
import FInput from "@components/form_float_label/FInput";

type Props = {
  medicalRecord: boolean;
};

const VitalSign = ({ medicalRecord }: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <Row gutter={[14, 14]} className="margin-form">
      <Col className="mt-1" md={8} lg={8}>
        <div className="t-grey-form">{t('vitalSignForm.BP')} :</div>
        <div className="mt-form">
          <Row gutter={[5, 14]}>
            <Col md={12} lg={12}>
              <Form.Item name="systolic">
                <FInput
                  label="Systolic"
                  name="systolic"
                  disabled={!medicalRecord}
                />
              </Form.Item>
            </Col>
            <Col md={12} lg={12}>
              <Form.Item name="diastolic">
                <FInput
                  label="Diastolic"
                  name="diastolic"
                  disabled={!medicalRecord}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Col>

      <Col md={8} lg={8}>
        <Form.Item
          label={t('vitalSignForm.PR')}
          name="pRmin"
        // rules={[{ required: true, }]}
        >
          <Input disabled={!medicalRecord} />
        </Form.Item>
      </Col>
      <Col md={8} lg={8}>
        <Form.Item
          label={t('vitalSignForm.RR')}
          name="rRmin"
        // rules={[{ required: true, }]}
        >
          <Input disabled={!medicalRecord} />
        </Form.Item>
      </Col>
      <Col md={8} lg={8}>
        <Form.Item
          label={t('vitalSignForm.BT')}
          name="bt"
        // rules={[{ required: true, }]}
        >
          <Input disabled={!medicalRecord} />
        </Form.Item>
      </Col>
      <Col md={8} lg={8}>
        <Form.Item
          // label={
          //   <div>
          //     O<sub>2</sub>sat (%)
          //   </div>
          // }
          label={i18n.language === 'en' ? t('vitalSignForm.O2Set') : <span>O<sub>2</sub>set(%) (ระดับออกซิเจนในเลือด)</span>}
          name="o2"
        // rules={[{ required: true, }]}
        >
          <Input disabled={!medicalRecord} />
        </Form.Item>
      </Col>
      <Col md={8} lg={8}>
        <Form.Item
          label={t('vitalSignForm.BW')}
          name="bw"
        // rules={[{ required: true, }]}
        >
          <Input disabled={!medicalRecord} type="number" />
        </Form.Item>
      </Col>

      <Col md={8} lg={8}>
        <Form.Item
          label={t('vitalSignForm.Height')}
          name="height"
        // rules={[{ required: true, }]}
        >
          <Input disabled={!medicalRecord} type="number" />
        </Form.Item>

        <Form.Item name="time" hidden>
          <Input />
        </Form.Item>
      </Col>
    </Row>
  );
};

export default VitalSign;
