import React, { useState } from "react";
import { COUPON } from "@assets/data/pathRouter";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Search from "./Search";
import TableCoupon from "./Table";
import RoleContext from "@provider/RoleProvider";

const Coupon = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "COUPON";
  });

  const createCoupon = () => {
    history.push(`${COUPON.create}`);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("coupon.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("marketing.title")}</b>
          </span>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col lg={2} className="justify-col">
            <Button
              className="btn-create"
              type="primary"
              onClick={createCoupon}
            >
              <PlusOutlined className="icons-size" /> {t("coupon.create")}
            </Button>
          </Col>
        ) : null}
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card ">{t("coupon.all")}</b>}
        >
          <Search
            setSearchValue={setSearchValue}
            setCurrentPage={setCurrentPage}
          />
          <TableCoupon
            searchValue={searchValue}
            currentPage={{
              currentPage: currentPage,
              setCurrentPage: setCurrentPage,
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default Coupon;
