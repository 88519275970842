import { IItem, IOrder } from "@interface/IOrder.interface";
import { thrownResponse } from "./auth";
import { IGetLabInterface } from "@interface/IWorkOrder.interface";
import { openNotification } from "@components/notification/Notification";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { IGetWorkOrderItem } from "@interface/IWorkOrder.interface";
import { IOrderListAndRecord } from "@interface/IOrder.interface";
import { useQuery, UseQueryResult } from "react-query";
import { IInChartOrderList, IParamet } from "@interface/IGlobal.interface";
import { reformatOutStanding, _isEmpty, handleMassageError } from "@tools/util";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetWorkOrdersByPatient = (
  id?: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IOrderListAndRecord>> => {
  return useQuery(
    ["get-work-order-out", id, params],
    async () => {
      const res = await axios.get(`/work-order/patient/${id}`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        const dataList = reformatOutStanding(res?.data?.result);

        return { ...res?.data, result: dataList };
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !_isEmpty(id) }
  );
};

export const useCreateWorkOrdersUse = () => {
  return useMutation(
    ["create-work-orders-use"],
    async ({ data, id, workOrderId }: any) => {
      const res = await axios.post(
        `/work-order/${Number(id)}/use/${workOrderId}`,
        data
      );

      if (res?.status >= 200 && res?.status < 300) {
        openNotification({ method: "POST_SUCCESS" });
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useCreateWorkOrdersConfirm = (): UseMutationResult => {
  return useMutation(
    ["create-work-orders-confirm"],
    async ({ data, id }: any) => {
      const res = await axios.post(`/work-order/${Number(id)}/confirm`, data);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useGetWorkOrdersOutStanding = (
  id?: number,
  params?: any
): UseQueryResult<IGetWorkOrderItem, IGetWorkOrderItem> => {
  return useQuery(
    ["get-workOrders-out-standing", id, params],
    async () => {
      const res = await axios.get(`/work-order/groups/${id}/outstanding`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data?.result?.[0] || {};
      }
      thrownResponse(res);
    },
    { enabled: !_isEmpty(id) }
  );
};

export const useGetWorkOrdersMedicine = (
  serviceGroup: string,
  id: number,
  params?: any
): UseQueryResult<IInChartOrderList> => {
  return useQuery(
    ["get-work-orders-medicine", serviceGroup, id, params],
    async () => {
      const res = await axios.get(
        `/work-order/medicine/patient/${id}/${serviceGroup}`,
        { params }
      );

      if (res?.status >= 200 && res?.status < 300) {
        return {
          oneDay:
            res?.data?.result?.[0]?.one?.groups?.flatMap((item: IItem) => {
              return item?.children;
            }) || [],
          continuous:
            res?.data?.result?.[0]?.continuous?.groups?.flatMap(
              (item: IItem) => {
                return item?.children;
              }
            ) || [],

          optionDrugs:
            res?.data?.result?.[0]?.optionDrugs?.groups?.flatMap(
              (item: IItem) => {
                return item?.children;
              }
            ) || [],
        };
      }
      if (res?.status >= 400 || res?.status <= 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useUpdateWorkOrderItemById = (): UseMutationResult<any, any> => {
  return useMutation(
    ["update-work-order-item-by-id"],
    async ({ data, id }: any) => {
      const res = await axios.put(`/work-order-item/${id}`, data);

      if (res?.status >= 200 && res?.status < 300) {
        return res;
      }
      thrownResponse(res);
    }
  );
};

export const useUpdateWorkOrderCourseItemById = (): UseMutationResult => {
  return useMutation(
    ["update-work-order-course-item-by-id"],
    async ({ data, id }: any) => {
      const res = await axios.put(`/work-order-course-item/${id}`, data);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useUpdateWorkOrderUpdateStatus = (): UseMutationResult<
  any,
  any
> => {
  return useMutation(
    ["update-work-order-update-status"],
    async ({ id, data }: any) => {
      const res = await axios.put(`/work-order/update-status/${id}`, data);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useUpdateWorkOrderAddProducts = (): UseMutationResult => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/work-order/${id}/add-products`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateWorkOrdersEditProduct = () => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/work-order/${id}/edit-products`, data);

    if (res?.status >= 200 && res?.status < 300) {
      openNotification({ method: "PUT_SUCCESS" });
      return;
    } else {
      openNotification({
        method: "PUT_ERROR",
        message: handleMassageError(res?.data.message),
      });
    }
    thrownResponse(res);
  });
};

export const useDeleteWorkOrdersEditProduct = () => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.delete(`/work-order/${id}/delete-products`, {
      data,
    });

    if (res?.status >= 200 && res?.status < 300) {
      openNotification({ method: "DEL_SUCCESS" });
      return res?.data;
    } else {
      openNotification({
        method: "DEL_ERROR",
        message: handleMassageError(res?.data.message),
      });
    }
    thrownResponse(res);
  });
};

export const useGetLabInterface = (
  id: number,
  params?: any
): UseQueryResult<IParamet<IGetLabInterface[]>> => {
  return useQuery(
    ["get-lab-interface", id],
    async () => {
      const res = await axios.get(`/work-order/lab-interface/${id}`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !_isEmpty(id) }
  );
};

export const useGetWorkOrderGroupsOutstanding = (
  id: number,
  params?: any
): UseQueryResult<any | ElementaryOneMore<IOrder>> => {
  return useQuery(
    ["get-work-order-groups-outstanding ", id, params],
    async () => {
      const res = await axios.get(
        `/work-order/groups/${id}/visit/outstanding`,
        {
          params: params,
        }
      );

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};
