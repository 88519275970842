import React, { useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { dayjs } from "@tools/timezone";
import { IItem } from "@interface/IOrder.interface";
import { IGetVisits } from "@interface/IVisits.interface";
import { IOptionToID } from "@interface/IWorkOrder.interface";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { IInChartOrderList } from "@interface/IGlobal.interface";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ORDER_STATUS, ORDER_TYPE } from "@tools/util.enum";
import { IOneContinuous, ISelected } from "@interface/IGlobal.interface";
import { useUpdateWorkOrderItemById } from "@services/api/work_order";
import { ENUM_G6PD, ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { useUpdateWorkOrderCourseItemById } from "@services/api/work_order";
import { Button, Card, Col, Form, FormInstance, Row } from "antd";
import Advice from "@components/advice/Advice";
import PrintMore from "./print_more/PrintMore";
import SymbolView from "@components/symbol/Symbol";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import ProductCategory from "@components/product_category/ProductCategory";
import OrderList from "../order_list/OrderList";
import OrderListOptionDrugs from "../order_list/order_list_option_drugs/OrderListOptionDrugs";
import MainMedicineRoomProvider from "@provider/medicine_room";

type Props = {
  visitsByID: IGetVisits | undefined;
  patientList: FormInstance;
  orderList?: IInChartOrderList;
  seletection: ISelected;
  medicalRecord: boolean;
};

const Fields = ({
  visitsByID,
  patientList,
  orderList,
  seletection,
  medicalRecord,
}: Props) => {
  const { t, i18n } = useTranslation();

  const pageStyle = `@page {size: 6.5cm 4.5cm; margin: 7px 0px 0px 0px; }`;
  // const pageStyle = `@page {size:6.5cm  297mm ;  margin: 0px 0px 0px 0px ;}`;
  const [open, setOpen] = useState({
    oneDay: true,
    continuous: true,
    optionDrugs: true,
  });
  const [visible, setVisible] = useState({ oneDay: false, continuous: false });
  const updateWorkOrderItemById = useUpdateWorkOrderItemById();
  const updateWorkOrderCourseItemById = useUpdateWorkOrderCourseItemById();
  const [optionToID, setOptionToID] = useState<IOptionToID>({
    id: 0,
    option: {},
  });
  const [totalList, setTotalList] = useState({
    aggregate: 0,
    total: 0,
    discount: 0,
    grandTotal: 0,
    oneDay: 0,
    continuous: 0,
  });
  const printReportRefContinuous = useRef(null);
  const printReportRefOneDay = useRef(null);
  const printReportRefOptionDrugs = useRef(null);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const [printDrug, setPrintDrug] = useState<IOneContinuous>({
    oneDay: [],
    continuous: [],
  });
  const client = useQueryClient();

  const handlePrintOneDay = useReactToPrint({
    content: () => printReportRefOneDay.current,
    pageStyle: pageStyle,
  });

  const handlePrintOptionDrugs = useReactToPrint({
    content: () => printReportRefOptionDrugs.current,
    pageStyle: pageStyle,
  });

  const handlePrintContinuous = useReactToPrint({
    content: () => printReportRefContinuous.current,
    pageStyle: pageStyle,
  });

  const viewDetail = (key: "oneDay" | "continuous" | "optionDrugs") => {
    setOpen({ ...open, [key]: !open?.[key] });
  };

  const handleUpdateWorkOrderItem = (data: any) => {
    seletection.setSelectedRowKeys({
      ...seletection.selectedRowKeys,
      oneDay: [],
      continuous: [],
    });
    const dataList = { ...optionToID.option, ...data };

    if (data?.categoryId === "C-0006" || data?.categoryId === "C-0007") {
      updateWorkOrderCourseItemById.mutate(
        {
          data: {
            ...data,
            status: ORDER_STATUS.PARTIAL,
            option: JSON.stringify(dataList),
          },
          id: optionToID.id,
        },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
            client.invalidateQueries(["get-work-orders-medicine"]);
          },
          onError: (err) => {
            openNotification({ method: "PUT_ERROR", message: String(err) });
          },
        }
      );
    } else {
      updateWorkOrderItemById.mutate(
        {
          data: {
            option: JSON.stringify(dataList),
            qty: data?.qty,
          },
          id: optionToID.id,
        },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
            client.invalidateQueries(["get-work-orders-medicine"]);
          },
          onError: (err) => {
            openNotification({ method: "PUT_ERROR", message: String(err) });
          },
        }
      );
    }
  };

  const onManageModalOneDay = () => {
    setVisible({ ...visible, oneDay: !visible.oneDay });
  };

  const onManageModalContinuous = () => {
    setVisible({ ...visible, continuous: !visible.continuous });
  };

  const list: {
    key: "continuous" | "oneDay";
    title: string;
    add: () => void;
    subKey: "continuous" | "oneDay";
  }[] = [
    {
      title: `${t("list")}${t("order")} One Day `,
      key: "oneDay",
      add: () => handlePrintOneDay(),
      subKey: "continuous",
    },
    {
      title: `${t("list")}${t("order")} Continue `,
      key: "continuous",
      add: () => handlePrintContinuous(),
      subKey: "oneDay",
    },
  ];

  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room "
        extra={
          <>
            <Row gutter={[14, 14]} className="text-row-profile ml-05">
              <Col>
                <ImagePatient src={visitsByID?.patient?.image} />
              </Col>
              <Col>
                <Row>
                  <Col xs={24} md={24} lg={24}>
                    {i18n.language === "th"
                      ? ` ${visitsByID?.patient?.firstNameTh || " "}    ${
                          visitsByID?.patient?.lastNameTh || " "
                        }`
                      : ` ${visitsByID?.patient?.firstNameEn || " "}    ${
                          visitsByID?.patient?.lastNameEn || " "
                        }`}
                  </Col>

                  <Col xs={24} md={24} lg={24}>
                    <div className="code-profile">
                      HN : {visitsByID?.patient?.hn}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginLeft: "auto" }}>
                <div className="t-center ">
                  <div className=" t-grey fs-18 ">{t("orderNumber")} </div>
                  <div className=" t-grey-light fs-12 ">
                    {visitsByID?.orderNo}{" "}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <Row align="middle">
          <Col>
            <div className="mb-05">
              <span className=" t-blue fs-18 f-normal ">{t("fullList")} </span>
              <span className=" t-grey-light  fs-12 ">
                {`${t("orderCreationDate")}:`}{" "}
              </span>
              <span className="t-grey fs-12 ">
                {visitsByID
                  ? `${dayjs(visitsByID.date).format("DD-MM-YYYY")}`
                  : ``}
              </span>
            </div>
          </Col>

          <Col style={{ marginLeft: "auto" }}>
            <div style={{ color: "#FEB139" }}>
              <SymbolView />
            </div>
            <div
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {visitsByID?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                ? "G6PD Deficiency"
                : null}
            </div>
          </Col>
        </Row>

        <Form name="patientList" form={patientList}>
          <ImportantInfo />
        </Form>
        <Advice visitsByID={visitsByID} />
        {list?.map((item, i) => {
          return (
            <div key={i} className="mt-2">
              <Row className="header-table " align="middle">
                <Col className="f-normal  t-dark-blue">
                  <span className="f-bold mr-05 ">{item?.title}</span>
                  <span className="pl-05">
                    {React.createElement(
                      open?.[item?.key] ? UpOutlined : DownOutlined,
                      {
                        onClick: () => viewDetail(item?.key),
                      }
                    )}
                  </span>
                </Col>

                <Col style={{ marginLeft: "auto" }}>
                  <Row gutter={[14, 14]}>
                    <Col>
                      <Button
                        className="btn-add"
                        type="primary"
                        onClick={item?.add}
                      >
                        {`${t("print")}${t("drugLabel")}`}
                      </Button>
                    </Col>
                    {medicalRecord ? (
                      <Col>
                        <Button
                          className="btn-print"
                          type="primary"
                          onClick={() => {
                            setVisible({
                              ...visible,
                              [item?.key]: !visible?.[item?.key],
                            });
                          }}
                        >
                          {`+ ${t("addMedicineList")}`}
                        </Button>
                      </Col>
                    ) : null}
                  </Row>
                </Col>
              </Row>

              <div className={open?.[item?.key] ? "open" : "close"}>
                <MainMedicineRoomProvider
                  value={{
                    orderList: orderList,
                    handleUpdateWorkOrderItem: handleUpdateWorkOrderItem,
                    select: {
                      selectedRowKeys: seletection?.selectedRowKeys,
                      setSelectedRowKeys: seletection.setSelectedRowKeys,
                    },
                    setOptionToId: setOptionToID,
                    visitsById: visitsByID,
                    printDrug: { printDrug, setPrintDrug },
                    totalList: {
                      totalList: totalList,
                      setTotalList: setTotalList,
                    },
                    medicalRecord: medicalRecord,
                    key: item?.key,
                    subKey: item?.subKey,
                  }}
                >
                  <OrderList />
                </MainMedicineRoomProvider>
              </div>
            </div>
          );
        })}

        <div className="mt-2">
          <Row className="header-table " align="middle">
            <Col className="f-normal  t-red">
              <span className="f-bold mr-05 ">{t("changeDoseMedication")}</span>
              <span className="pl-05">
                {React.createElement(
                  open?.optionDrugs ? UpOutlined : DownOutlined,
                  {
                    onClick: () => viewDetail("optionDrugs"),
                  }
                )}
              </span>
            </Col>

            <Col style={{ marginLeft: "auto" }}>
              <Row gutter={[14, 14]}>
                <Col>
                  <Button
                    className="btn-add"
                    type="primary"
                    onClick={handlePrintOptionDrugs}
                  >
                    {`${t("print")}${t("drugLabel")}`}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className={open?.optionDrugs ? "open" : "close"}>
            <OrderListOptionDrugs
              orderList={orderList?.optionDrugs}
              setOptionToID={setOptionToID}
              handleUpdateWorkOrderItem={handleUpdateWorkOrderItem}
              medicalRecord={medicalRecord}
            />
          </div>
        </div>
      </Card>
      {visible.oneDay && (
        <ProductCategory
          patientID={visitID}
          visible={visible.oneDay}
          onModal={onManageModalOneDay}
          status={`Drug & Supplement & Medical supplies`}
          type={ORDER_TYPE.ONE_DAY}
          room={ENUM_ROOM_SIGNATURE.DISPENSARY}
        />
      )}
      {visible.continuous && (
        <ProductCategory
          patientID={visitID}
          visible={visible.continuous}
          onModal={onManageModalContinuous}
          status={`Drug & Supplement & Medical supplies`}
          type={ORDER_TYPE.CONTINUE}
          room={ENUM_ROOM_SIGNATURE.DISPENSARY}
        />
      )}

      <div style={{ display: "none" }}>
        <ReactToPrint content={() => printReportRefOneDay?.current} />
        <PrintMore
          refData={printReportRefOneDay}
          visitsByID={visitsByID}
          data={printDrug?.oneDay?.map((item: IItem) => {
            let option = JSON.parse(item?.option || `{}`);
            return {
              ...item,
              option: option,
            };
          })}
        />

        <ReactToPrint content={() => printReportRefContinuous?.current} />
        <PrintMore
          refData={printReportRefContinuous}
          visitsByID={visitsByID}
          data={printDrug?.continuous?.map((item: IItem) => {
            let option = JSON.parse(item?.option || `{}`);
            return {
              ...item,
              option: option,
            };
          })}
        />

        <ReactToPrint content={() => printReportRefOptionDrugs?.current} />
        <PrintMore
          refData={printReportRefOptionDrugs}
          visitsByID={visitsByID}
          data={orderList?.optionDrugs
            ?.flatMap((item) => {
              return item?.children?.flatMap((parent) => {
                return parent?.children?.flatMap((child) => {
                  return child;
                });
              });
            })
            ?.map((item: IItem) => {
              let option = JSON.parse(item?.option || `{}`);
              return {
                ...item,
                option: option,
              };
            })}
        />
      </div>
    </>
  );
};

export default Fields;
