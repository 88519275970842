import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useUpdateVisits } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetVisitReportTreatment } from "@services/api/visits";
import { Button, Card, Col, Form, Row } from "antd";
import { ITreatmentRecord, IWorkOrderItem } from "@interface/IVisits.interface";
import Nodata from "@assets/img/nodata.svg";
import BackHome from "@components/button/BackHome";
import ReportProcedure from "@components/report_procedure/ReportProcedure";

const Report = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const pageStyle = `  @page { size:A4;
        margin: 90px;}`;
  const printReportRef = useRef(null);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const [treatmentRecord] = useForm();
  const visitReportTreatment = useGetVisitReportTreatment(visitID);
  const updateVisits = useUpdateVisits();
  const client = useQueryClient();

  useEffect(() => {
    treatmentRecord.setFieldsValue({
      bw:
        visitReportTreatment?.data?.visit?.data?.standing1Mins?.bw ||
        visitReportTreatment?.data?.visit?.data?.sitting1Min?.bw ||
        visitReportTreatment?.data?.visit?.data?.lyingDown?.bw ||
        visitReportTreatment?.data?.visit?.data?.doctorRoom?.bw ||
        visitReportTreatment?.data?.visit?.data?.procedure?.bw,
      ...visitReportTreatment?.data?.visit?.data?.treatmentRecord,
      note: visitReportTreatment?.data?.patient?.note,
      nurseNote: visitReportTreatment?.data?.patient?.nurseNote,
    });
    //eslint-disable-next-line
  }, [visitReportTreatment?.data]);

  const onFinish = (values: ITreatmentRecord) => {
    const formData = new FormData();

    formData?.append(
      "data",
      JSON.stringify({
        ...visitReportTreatment?.data?.visit?.data,
        note: values?.note,
        treatmentRecord: values,
      })
    );
    formData?.append("note", values?.note);
    formData?.append("nurseNote", values?.nurseNote);

    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-visitsByID", visitID]);
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("procedure.title")}</h1>
              </div>
              <span className="t-light-gray">
                {t("nursery")} / {t("procedure.title")} /
              </span>
              <span className="t-dark-blue f-bold">{t("report")}</span>
            </Col>
          </Row>
        </Col>

        <Col lg={10} className="d-flex justify-between justify-col">
          <Button
            className="btn-create mr-05 "
            htmlType="button"
            onClick={() => treatmentRecord.submit()}
          >
            {t("save")}
          </Button>
          <Button
            className="mr-05 reserve"
            htmlType="reset"
            onClick={() => handlePrint()}
          >
            {t("print")}
          </Button>
        </Col>
      </Row>
      <div className="set-layout ">
        <Form
          className="label-unset"
          name="treatmentRecord"
          form={treatmentRecord}
          onFinish={onFinish}
        >
          <Card
            className="bg-grey  "
            extra={<b className="header-card">{t("reportProcedure.title")}</b>}
          >
            {visitReportTreatment?.data?.patient?.hn ? (
              <>
                <ReactToPrint content={() => printReportRef?.current} />
                <div className="report-procedure">
                  <ReportProcedure
                    refValue={printReportRef}
                    reportTreatment={visitReportTreatment?.data}
                    indexMedication={
                      visitReportTreatment?.data?.workOrderItem
                        ? visitReportTreatment?.data?.workOrderItem?.findIndex(
                            (item: IWorkOrderItem) =>
                              item?.group === "ID_SQ_IM_MEDICATIONS"
                          )
                        : -1
                    }
                    indexIV={
                      visitReportTreatment?.data?.workOrderItem
                        ? visitReportTreatment?.data?.workOrderItem?.findIndex(
                            (item: IWorkOrderItem) =>
                              item?.group === "IV_MEDICATIONS"
                          )
                        : -1
                    }
                    indexphysical={
                      visitReportTreatment?.data?.workOrderItem
                        ? visitReportTreatment?.data?.workOrderItem?.findIndex(
                            (item: IWorkOrderItem) =>
                              item?.group === "PHYSICAL_THERAPY"
                          )
                        : -1
                    }
                    i18n={i18n.language}
                  />
                </div>
              </>
            ) : (
              <>
                <div>
                  <img src={Nodata} alt="nodata" className="image-nodata" />
                </div>
                <div className="text-nodata f-bold">NO DATA</div>
              </>
            )}
          </Card>
        </Form>
      </div>
    </div>
  );
};

export default Report;
