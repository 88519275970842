import React, { FC, useEffect, useState } from "react";
import { IItem } from "@interface/IOrder.interface";
import { BigNumber } from "bignumber.js";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { useGetProductList } from "@services/api/product";
import MoTable from "@components/table/Table";

type Props = {
  currentPage: ICurrentPage;
  search: any;
};

const TableWareHouse: FC<Props> = ({ currentPage, search }) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const productList = useGetProductList({
    page: currentPage.currentPage,
    limit: limitPage,
    search: search?.name,
    categoryId: search?.categoryId,
    orderby: ENUM_ORDERBY.DESC,
  });

  useEffect(() => {
    setTotal(productList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [productList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: t("sku"),
      dataIndex: "sku",
      key: "sku",
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: String, data: IItem) => {
        return (
          <div>
            {i18n.language === "th"
              ? data?.category?.nameTh
              : data?.category?.nameEn}
          </div>
        );
      },
    },
    {
      title: t("productName"),
      dataIndex: "product",
      key: "product",
      render: (_: String, data: IItem) => {
        return (
          <div>{i18n.language === "th" ? data?.nameTh : data?.nameEn}</div>
        );
      },
    },
    {
      title: t("numberOfItems"),
      dataIndex: "productBranch",
      key: "productBranch",
      render: (productBranch: [{ qty: number }], data: IItem) => {
        return (
          <div>
            {data?.categoryId === "C-0006"
              ? !!productBranch?.[0]?.qty
                ? productBranch?.[0]?.qty
                : "-"
              : productBranch?.[0]?.qty}
          </div>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "normal",
      key: "normal",
      render: (normal: string) => {
        return <div>{`${new BigNumber(normal || 0).toFormat(2)}`}</div>;
      },
    },
  ];

  return (
    <React.Fragment>
      <MoTable
        dataSource={productList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        // onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </React.Fragment>
  );
};

export default TableWareHouse;
