import { useState } from "react";
import { IBilling } from "@interface/IBill.interface";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { PrinterFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { RouteComponentProps } from "react-router-dom";
import { useGetInvoiceNumber } from "@services/api/invoice";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHandleDeleteProductBill } from "@services/api/bill";
import { DatePicker, Input, InputNumber, Row } from "antd";
import { Card, Col, Divider, Form, FormInstance } from "antd";
import Modaler from "@components/modaler/Modaler";
import TextArea from "antd/lib/input/TextArea";
import BigNumber from "bignumber.js";
import TableBill from "./Table";

type Props = {
  locationPath: string;
  billIDList?: ElementaryOneMore<IBilling>;
  billForm: FormInstance;
  onFinish: (values: any) => void;
  history: RouteComponentProps["history"];
  billID: number;
  medicalRecord: boolean;
};

const Fields = ({
  locationPath,
  billIDList,
  billForm,
  onFinish,
  history,
  billID,
  medicalRecord,
}: Props) => {
  const [visible, setVisible] = useState({
    delete: false,
  });
  const [netPrice, setNetPrice] = useState("");
  const [product, setProduct] = useState({
    orderItemId: 0,
  });
  const client = useQueryClient();
  const dateFormat = "DD-MM-YYYY";
  const { t, i18n } = useTranslation();
  const invoiceNumber = useGetInvoiceNumber(
    locationPath.includes("create-invoice") ? true : false
  );
  const deleteItems = useHandleDeleteProductBill();

  const onNumberChangeDiscount = () => {
    billForm.setFieldsValue({
      ...billForm,

      discountPercent: null,
    });
  };

  const onNumberChange = (value: any, netPrice: number) => {
    if (value < 101 && value > 0) {
      let totalDiscount = new BigNumber(netPrice || 0)
        .dividedBy(100)
        .multipliedBy(value)
        .toFixed(2);
      billForm.setFieldsValue({
        ...billForm,
        discount: totalDiscount,
      });
    } else {
      billForm.setFieldsValue({
        ...billForm,
        discount: "0.00",
      });
    }
  };

  const onOpenViewReport = () => {
    history.push(`${PAY_LIST_TAB.reportBill}/${billID}`);
  };

  const onModalDelete = () => {
    setVisible({ ...visible, delete: !visible.delete });
  };

  const onDelete = () => {
    deleteItems.mutate(
      {
        data: {
          ...product,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
          onModalDelete();
          client.invalidateQueries(["get-bill-byID"]);
        },
        onError: (res: any) => {
          openNotification({ method: "DEL_ERROR", message: res.message });
          onModalDelete();
        },
      }
    );
  };

  return (
    <Card
      className="bg-grey m-1-doctor-room"
      extra={
        <div>
          <div className="fs-20 f-normal t-grey-label">
            {locationPath.includes("create-invoice")
              ? t("createInvoice")
              : t("editBill")}
          </div>
          {invoiceNumber?.data ? (
            <span>
              <span className="fs-16 ">{`${t("number")} :`} </span>
              <span className="fs-16 t-blue">
                {invoiceNumber?.data?.result?.[0]}
              </span>
            </span>
          ) : (
            <span>
              <span className="fs-16 ">{`${t("number")} :`} </span>
              <span className="fs-16 t-blue">
                {billIDList?.result?.[0]?.billNumber}
              </span>
            </span>
          )}
        </div>
      }
    >
      <Form name="billForm1" form={billForm} onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col lg={15}>
            <Row gutter={[14, 14]} align="middle">
              <Col lg={12}>
                <Form.Item label={t("customerName")} name="name">
                  <Input disabled />
                </Form.Item>

                <Form.Item label={t("address")} name="address">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    placeholder=""
                  />
                </Form.Item>

                <Form.Item label={t("zipCode")} name="zipCode">
                  <Input disabled placeholder={t("zipCode")} />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <div className="example">
                  <span className="f-bold ">{`${t("contact")} :`}</span>
                  <span className="break-inside ">
                    {i18n.language === "th"
                      ? billIDList?.result?.[0]?.branch?.nameTh
                      : billIDList?.result?.[0]?.branch?.nameEn}
                  </span>
                  <span className="break-inside ">
                    {[
                      billIDList?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "addressTh" : "addressEn"
                      ],
                      billIDList?.result?.[0]?.branch?.[
                        i18n.language === "th"
                          ? "subDistrictTh"
                          : "subDistrictEn"
                      ],
                      billIDList?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "districtTh" : "districtEn"
                      ],
                      billIDList?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "provinceTh" : "provinceEn"
                      ],
                      billIDList?.result?.[0]?.branch?.zipcode,
                      billIDList?.result?.[0]?.branch?.country,
                    ]
                      ?.join(" ")
                      ?.trim()}
                  </span>
                </div>

                <div>
                  <span className="f-bold">{`${t("taxId")} :`}</span>{" "}
                  {billIDList?.result?.[0]?.branch?.taxId}
                </div>

                <div>
                  <span className="f-bold">{`${t("phone")} :`}</span>{" "}
                  {billIDList?.result?.[0]?.branch?.phone}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={9}>
            {locationPath.includes("create-invoice") ? null : (
              <Row justify="end" align="middle">
                <Col className="col-end" span={24}>
                  <PrinterFilled
                    onClick={onOpenViewReport}
                    style={{ fontSize: "32px" }}
                  />
                </Col>
                <Col>{t("print")}</Col>
              </Row>
            )}

            <div className="fs-16 f-bold">{t("theTotalAmount")}</div>
            <div className="fs-20 f-bold t-blue mt-1 ">
              {netPrice ||
                new BigNumber(billIDList?.result?.[0]?.netPrice || 0).toFormat(
                  2
                )}
            </div>
            <Row gutter={[14, 14]} className="mt-1">
              <Col md={24} lg={24}>
                <Form.Item
                  label={t("date")}
                  className="pre-register-form"
                  name="date"
                >
                  <DatePicker disabled format={dateFormat} />
                </Form.Item>
              </Col>

              <Col md={24} lg={24}>
                <Form.Item
                  label={t("salesperson")}
                  name="sale"
                  className="pre-register-form"
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />
        <Row gutter={[14, 14]}>
          <Col span={24}>
            <Form.Item
              label={t("referenceNumber")}
              name="referenceNumber"
              className="pre-register-form"
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item name="billByIdOrder">
          <TableBill
            billForm={billForm}
            setProduct={setProduct}
            onModalDelete={onModalDelete}
            medicalRecord={medicalRecord}
          />
        </Form.Item>

        <Row gutter={[14, 14]}>
          <Col span={15}>
            <Form.Item label={t("remarks")} name="description">
              <TextArea
                autoSize={{ minRows: 2, maxRows: 3 }}
                disabled={!medicalRecord}
              />
            </Form.Item>
          </Col>

          <Col span={9}>
            <Row className="mt-1">
              <Col span={12}>{t("totalMoney")}</Col>
              <Col span={12} className="col-end">
                <Form.Item
                  shouldUpdate={(prev, next) => prev.netPrice !== next.netPrice}
                >
                  {(form: FormInstance) => {
                    const netPrice = form.getFieldValue("netPrice");
                    return (
                      <div>{new BigNumber(netPrice || 0).toFormat(2)}</div>
                    );
                  }}
                </Form.Item>

                {/* <TotalPrice /> */}
              </Col>
            </Row>

            {locationPath.includes("create-invoice") ? (
              <Row className="mt-1">
                <Col span={4} style={{ paddingTop: "12px" }}>
                  {t("discounts")}
                </Col>
                <Col span={6}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) =>
                      prev.netPrice !== next.netPrice
                    }
                  >
                    {(form: FormInstance) => {
                      const netPrice = form.getFieldValue("netPrice");

                      return (
                        <Form.Item
                          name="discountPercent"
                          rules={[
                            {
                              pattern: /^[0-9][0-9]?$|^100$/,
                              message: `${t("APercentageOfNotLessThan")} 0 ${t(
                                "andNotMore"
                              )}100!`,
                            },
                          ]}
                        >
                          <InputNumber
                            onChange={(e) =>
                              onNumberChange(e, Number(netPrice))
                            }
                            disabled={!medicalRecord}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Col span={2} style={{ paddingTop: "12px" }} className="pl-05 ">
                  %
                </Col>
                <Col span={12}>
                  <div>
                    <Form.Item
                      name="discount"
                      rules={[
                        () => ({
                          validator(_, value) {
                            if (
                              value > (billIDList?.result?.[0]?.totalPrice || 0)
                            ) {
                              return Promise.reject(
                                new Error(
                                  `${t("amountNotLessThan")} 0 ${t(
                                    "andNotMore"
                                  )} ${new BigNumber(
                                    billIDList?.result?.[0]?.totalPrice || 0
                                  ).toFixed(2)}!`
                                )
                              );
                            } else if (value < 0) {
                              return Promise.reject(
                                new Error(
                                  `${t("amountNotLessThan")} 0 ${t(
                                    "andNotMore"
                                  )} ${new BigNumber(
                                    billIDList?.result?.[0]?.totalPrice || 0
                                  ).toFixed(2)}!`
                                )
                              );
                            } else {
                              return Promise.resolve();
                            }
                          },
                        }),
                      ]}
                    >
                      <Input
                        disabled={!medicalRecord}
                        type="number"
                        onChange={() => onNumberChangeDiscount()}
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            ) : null}

            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.discount !== next.discount ||
                prev.netPrice !== next.netPrice
              }
            >
              {(form: FormInstance) => {
                const discount = form.getFieldValue("discount");
                const netPrice = form.getFieldValue("netPrice");
                const cal = new BigNumber(netPrice || 0)
                  .minus(discount || 0)
                  .toFormat(2);
                setNetPrice(cal);
                return (
                  <span>
                    {locationPath.includes("create-invoice") ? (
                      <Row className="mt-1">
                        <Col span={12}>{t("priceAfterDiscount")}</Col>
                        <Col span={12} className="col-end">
                          <div>
                            {Number(discount) <= (netPrice || 0) &&
                            Number(discount) > 0
                              ? new BigNumber(netPrice || 0)
                                  .minus(discount || 0)
                                  .toFormat(2)
                              : new BigNumber(netPrice || 0).toFormat(2)}
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row className="mt-1">
                        <Col span={12}>{`${t("discounts")} ${
                          billIDList?.result?.[0]?.discountPercent &&
                          billIDList?.result?.[0]?.discountPercent !== 0
                            ? `${billIDList?.result?.[0]?.discountPercent} %`
                            : ""
                        }`}</Col>
                        <Col span={12} className="col-end">
                          <div>
                            {`${new BigNumber(
                              discount || billIDList?.result?.[0]?.discount || 0
                            ).toFormat(2)}`}
                          </div>
                        </Col>
                      </Row>
                    )}

                    <Row className="mt-1">
                      <Col span={12}>{t("theTotalAmount")}</Col>
                      <Col span={12} className="col-end">
                        <div>
                          {Number(discount) <= (netPrice || 0) &&
                          Number(discount) > 0
                            ? new BigNumber(netPrice || 0)
                                .minus(discount || 0)
                                .toFormat(2)
                            : new BigNumber(netPrice || 0).toFormat(2)}
                        </div>
                      </Col>
                    </Row>
                  </span>
                );
              }}
            </Form.Item>

            <Divider />
          </Col>
        </Row>
      </Form>

      <Modaler
        visible={visible.delete}
        onCancel={onModalDelete}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {t("doYouWantToDelete")}
      </Modaler>
    </Card>
  );
};

export default Fields;
