import React, { FC, useEffect, useState } from "react";
import { Age } from "@tools/age";
import { Col, Row } from "antd";
import { keyVitalSign } from "@interface/IVisits.interface";
import { useRouteMatch } from "react-router-dom";
import { useGetPatientByHistory } from "@services/api/patient";
import { IGetVisits, keyNameProcedure } from "@interface/IVisits.interface";
import moment from "moment";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";

type Props = {
  refValue: React.RefObject<HTMLInputElement>;
  visitVitalSign?: IGetVisits[];
  i18n: string;
};
const vitalSignKey: keyNameProcedure[] = [
  "date",
  "time",
  "systolic",
  "diastolic",
  "pRmin",
  "rRmin",
  "bt",
  "o2",
  "bw",
  "height",
];

const Detail: FC<Props> = ({ refValue, visitVitalSign, i18n }) => {
  const userList = React.useContext(UserContext);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const patient = useGetPatientByHistory(patientID);
  const [vitalSign, setVitalSign] = useState<
    { [K in keyNameProcedure]?: string }[]
  >([{} as { [K in keyNameProcedure]: string }]);

  useEffect(() => {
    if (!visitVitalSign) return;

    const key: keyVitalSign[] = ["doctorRoom", "visit"];

    let data: { [K in keyNameProcedure]?: string }[] = [];
    visitVitalSign?.forEach((item) => {
      key?.forEach((key) => {
        if (item?.data?.[key]) {
          data = [...data, { ...item?.data?.[key], date: String(item?.date) }];
        }
      });
    });

    setVitalSign(data);

    // eslint-disable-next-line
  }, [visitVitalSign]);

  return (
    <div ref={refValue}>
      <Row className="t-start document-border-header p-05">
        <Col span={6}>
          <div className="mt-1">
            <ImageLogo
              height="50px"
              width="120px"
              src={userList?.branch?.image}
              alt="logo"
            />
          </div>
        </Col>
        <Col span={18}>
          <Row>
            <Col>
              <div>Name : </div>
            </Col>
            <Col span={20} className="t-underline">
              <div className=" f-normal" style={{ paddingLeft: 10 }}>
                {i18n === "th"
                  ? `${patient?.data?.titleTh}${[
                      patient?.data?.firstNameTh,
                      patient?.data?.lastNameTh,
                    ]
                      ?.join(" ")
                      ?.trim()}`
                  : `${patient?.data?.titleEn}${[
                      patient?.data?.firstNameEn,
                      patient?.data?.lastNameEn,
                    ]
                      ?.join(" ")
                      ?.trim()}`}
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-05">HN:</Col>

            <Col span={7} className="t-underline mt-05">
              <div style={{ paddingLeft: 10 }} className=" f-normal">
                {patient?.data?.hn}
              </div>
            </Col>

            <Col className="mt-05">Birth Date:</Col>

            <Col span={7} className="t-underline mt-05">
              <div style={{ paddingLeft: 10 }} className=" f-normal">
                {patient?.data?.dateOfBirth
                  ? moment(patient?.data?.dateOfBirth).format("DD-MM-YYYY")
                  : "-"}
              </div>
            </Col>

            <Col className="mt-05">Age :</Col>

            <Col span={2} className="t-underline mt-05">
              <div style={{ paddingLeft: 10 }} className=" f-normal">
                {Age(patient?.data?.dateOfBirth || "")}
              </div>
            </Col>

            <Col className="mt-05">y</Col>
          </Row>

          <Row>
            <Col className="mt-05">Allergies : </Col>

            <Col style={{ minWidth: 130 }} className="t-underline mt-05 t-red">
              <div style={{ paddingLeft: 10 }} className=" f-normal ">
                {` ${[patient?.data?.drugAllergy, patient?.data?.foodAllergy]
                  ?.join(" / ")
                  ?.trim()}`}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <table
        style={{ width: "100%" }}
        className="print-marker fs-14-marker t-center mt-1"
      >
        <thead>
          <tr>
            <td className="print-marker-bottom" colSpan={10}>
              <div className="t-center fs-20 f-bold mt-05 mb-05">
                Vital Signs Flow Sheet
              </div>
            </td>
          </tr>
          <tr style={{ height: "30px" }}>
            <td rowSpan={2} className="print-marker-right f-bold">
              Date
            </td>
            <td rowSpan={2} className="print-marker-right f-bold">
              Time
            </td>
            <td colSpan={2} className="print-marker-right f-bold">
              BP
            </td>

            <td rowSpan={2} className="print-marker-right f-bold">
              PR/min
            </td>
            <td rowSpan={2} className="print-marker-right f-bold">
              RR/min
            </td>
            <td rowSpan={2} className="print-marker-right f-bold">
              BT (c)
            </td>
            <td rowSpan={2} className="print-marker-right f-bold">
              O<sub>2</sub>sat (%)
            </td>
            <td rowSpan={2} className="print-marker-right f-bold">
              BW (kg)
            </td>
            <td rowSpan={2} className="print-marker-right f-bold">
              HT (cm)
            </td>
          </tr>
          <tr style={{ height: "30px" }}>
            <td className="print-marker-right print-marker-top f-bold">
              Systolic
            </td>

            <td className="print-marker-right print-marker-top f-bold">
              Diastolic
            </td>
          </tr>
        </thead>
        <tbody>
          {vitalSign?.map((item, i) => {
            if (
              !![
                item?.systolic,
                item?.diastolic,
                item?.pRmin,
                item?.rRmin,
                item?.bt,
                item?.o2,
                item?.bw,
                item?.height,
              ]
                ?.join("")
                ?.trim()
            )
              return (
                <tr key={i}>
                  {vitalSignKey?.map((vitalSign, index) => {
                    return (
                      <td
                        style={{ height: "30px" }}
                        className="print-marker-right print-marker-top"
                        key={` ${index}-${index}`}
                      >
                        {item?.[vitalSign]}
                      </td>
                    );
                  })}
                </tr>
              );
            return null;
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Detail;
