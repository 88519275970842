import { ISearchPrefix } from "@interface/ISearch.interface";
import { useTranslation } from "react-i18next";
import { useGetPrefixList } from "@services/api/prefix";
import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import { ENUM_ORDERBY, ENUM_PREFIX_TYPE } from "@tools/util.enum";
import TableEthnicity from "./Table";

const Citizenship = () => {
  const { t } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [search, setSearch] = useState<ISearchPrefix>();
  const prefixList = useGetPrefixList({
    page: currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    type: [ENUM_PREFIX_TYPE.CITIZENSHIP],
    name: search?.name,
  });

  useEffect(() => {
    setTotal(prefixList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [prefixList?.data]);

  const onFinish = (values: ISearchPrefix) => {
    setSearch(values);
    setCurrentPage(1);
  };
  return (
    <div>
      <div className="pb-1">
        <Form onFinish={onFinish}>
          <Row gutter={[14, 14]}>
            <Col lg={18}>
              <Form.Item name="name">
                <Input placeholder={`${t("search")}${t("citizenship")}`} />
              </Form.Item>
            </Col>

            <Col>
              <Button className="btn-search" htmlType="submit">
                {t("search")}
              </Button>
              <Button className="clear ml-05" htmlType="reset">
                {t("reset")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <TableEthnicity
        citizenship={prefixList?.data?.result?.[0]?.data || []}
        limitPage={limitPage}
        setLimitPage={setLimitPage}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Citizenship;
