import { t } from "i18next";
import { SETTING } from "@assets/data/pathRouter";
import { _isEmpty } from "@tools/util";
import { FC, useState } from "react";
import { FormInstance } from "antd/es/form/Form";
import { useIsMutating } from "react-query";
import { useDeleteBranch } from "@services/api/branch";
import { Row, Col, Button } from "antd";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Delete from "@assets/img/icon/OPD/delete.svg";
import Modaler from "@components/modaler/Modaler";
import BackHome from "@components/button/BackHome";

type Props = {
  branchManageForm: FormInstance;
};

const Header: FC<Props> = ({ branchManageForm }) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const branchId = match.params.branchId;
  const [visible, setVisible] = useState<boolean>(false);
  const deleteBranch = useDeleteBranch();
  const loadingCreate = useIsMutating(["create-branch"]);
  const loadingUpdate = useIsMutating(["update-branch"]);

  const onDelete = () => {
    deleteBranch.mutate(branchId, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        history.replace(SETTING.branchManagement);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
      onSettled: () => {
        setVisible(false);
      },
    });
  };

  return (
    <>
      <Modaler
        loadingButton={deleteBranch?.isLoading}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {t("doYouWantToDelete")}
      </Modaler>
      <Row className="second-header" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("setting")}</h1>
              </div>
              <span className="t-light-gray">
                {t("branchManagement.title")} /{" "}
              </span>
              <span className="t-dark-blue f-normal">
                {!_isEmpty(branchId) ? t("update") : t("create")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          {!!branchId && (
            <Button
              className="delete mr-05"
              onClick={(e) => {
                setVisible(true);
              }}
            >
              <img className="circle editor-button" src={Delete} alt="delete" />
            </Button>
          )}

          <Button className="mr-05 cancel" onClick={history.goBack}>
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            onClick={() => branchManageForm.submit()}
            loading={!!loadingCreate || !!loadingUpdate}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
