import { Form } from "antd";
import { useTranslation } from "react-i18next";
import FormGeneral from "../../form/Form";

const FormCountry = () => {
  const { t } = useTranslation();
  const [confirmCountry] = Form.useForm();
  return (
    <div>
      <FormGeneral title={t("country")} confirm={confirmCountry} />
    </div>
  );
};

export default FormCountry;
