import type { IAddress } from "@assets/data/address.interface";
import { useTranslation } from "react-i18next";
import { ISeparatePrefix } from "@interface/IOpd.interface";
import { Card, Col, Form, FormInstance, Input, Row, Switch } from "antd";
import Selector from "@components/selector/Selector";
import { address } from "@assets/data/address";
import SelectorAddress from "@components/selector/SelectorAddress";

type Props = {
  openAddress: any;
  setOpenAddress: (e: any) => void;
  prefixList?: ISeparatePrefix;
  basicInfoForm: FormInstance;
};
let homeAddress: IAddress[] = [...address];
const ThailandCountryWording = ["Thailand", "ไทย"];

const isValueChange = (
  prevValues: Record<string, string | undefined>,
  curValues: Record<string, string | undefined>,
  key: string
): boolean => {
  if (curValues[key] === undefined && prevValues[key] === undefined)
    return false;
  if (prevValues[key] !== curValues[key]) {
    return true;
  }
  return false;
};
const ContactInformation = ({
  openAddress,
  setOpenAddress,
  prefixList,
  basicInfoForm,
}: Props) => {
  const { i18n, t } = useTranslation();

  const address = (address: boolean) => {
    setOpenAddress(address);
  };

  const setHomeAddress = (id: number) => {
    const homeAdders: any = homeAddress?.find((item: any) => item?.id === id);

    basicInfoForm.setFieldsValue({
      subDistrict: homeAdders,
      province: homeAdders,
      district: homeAdders,
      zipcode: homeAdders,
      country: id === undefined ? undefined : ThailandCountryWording[0],
    });
  };

  const setMailAddress = (id: number) => {
    const mailingAddress: any = homeAddress?.find(
      (item: any) => item?.id === id
    );
    basicInfoForm.setFieldsValue({
      subDistrictDoc: mailingAddress,
      provinceDoc: mailingAddress,
      districtDoc: mailingAddress,
      zipcodeDoc: mailingAddress,
      countryDoc: id === undefined ? undefined : ThailandCountryWording[0],
    });
  };

  return (
    <Card className="cardTop ">
      <div className="title-in-card mt-1">
        {`${t("part")} 2 ${t("contactInfo")}`}
      </div>
      <Card className="cardBG mt-1">
        <div className="title-color ">{t("addressAccording")}</div>
        <Row gutter={[14, 14]} className="margin-form">
          <Col md={24} lg={24}>
            <Form.Item label={t("addressTH")} name="addressTh" rules={[]}>
              <Input />
            </Form.Item>
          </Col>
          <Col md={24} lg={24}>
            <Form.Item label={t("addressEN")} name="addressEn" rules={[]}>
              <Input />
            </Form.Item>
          </Col>

          <Col md={24} lg={12}>
            <Form.Item
              shouldUpdate={(prev, next) => {
                return isValueChange(prev, next, "country");
              }}
              noStyle
            >
              {(form) => {
                const country = form.getFieldValue("country");

                return (
                  <Form.Item
                    label={t("subdistrict")}
                    name="subDistrict"
                    id="subdistrict"
                  >
                    {ThailandCountryWording.includes(country) ? (
                      <SelectorAddress
                        placeholder=""
                        source={homeAddress}
                        keyName={
                          i18n.language === "th"
                            ? "subdistrictTh"
                            : "subdistrictEn"
                        }
                        keyData={i18n.language === "th" ? "name_th" : "name_en"}
                        keyValue="id"
                        onChange={(e) => {
                          setHomeAddress(e);
                        }}
                      />
                    ) : (
                      <Input />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Col md={24} lg={12}>
            <Form.Item
              shouldUpdate={(prev, next) => {
                const _shouldUpdate = isValueChange(prev, next, "country");
                console.log(_shouldUpdate);
                return _shouldUpdate;
              }}
              noStyle
            >
              {(form) => {
                const country = form.getFieldValue("country");
                return (
                  <Form.Item label={t("district")} name="district">
                    {ThailandCountryWording.includes(country) ? (
                      <SelectorAddress
                        placeholder=""
                        source={homeAddress}
                        keyName={
                          i18n.language === "th" ? "districtTh" : "districtEn"
                        }
                        keyData={i18n.language === "th" ? "name_th" : "name_en"}
                        keyValue="id"
                        onChange={(e) => {
                          setHomeAddress(e);
                        }}
                      />
                    ) : (
                      <Input />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Col md={24} lg={12}>
            <Form.Item
              shouldUpdate={(prev, next) => {
                return isValueChange(prev, next, "country");
              }}
              noStyle
            >
              {(form) => {
                const country = form.getFieldValue("country");
                return (
                  <Form.Item label={t("province")} name="province">
                    {ThailandCountryWording.includes(country) ? (
                      <SelectorAddress
                        placeholder=""
                        source={homeAddress}
                        keyName={
                          i18n.language === "th" ? "provinceTh" : "provinceEn"
                        }
                        keyData={i18n.language === "th" ? "name_th" : "name_en"}
                        keyValue="id"
                        onChange={(e) => {
                          setHomeAddress(e);
                        }}
                      />
                    ) : (
                      <Input />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>

          <Col md={12} lg={12}>
            <Form.Item label={t("country")} name="country">
              <Selector
                placeholder={t("country")}
                Source={prefixList?.COUNTRY || []}
                keyName="name"
                keyValue="name"
                onChange={() => {
                  basicInfoForm.setFieldsValue({
                    subDistrict: undefined,
                    province: undefined,
                    district: undefined,
                    zipcode: undefined,
                  });
                }}
              />
            </Form.Item>
          </Col>

          <Col md={12} lg={12}>
            <Form.Item
              shouldUpdate={(prev, next) => {
                return isValueChange(prev, next, "country");
              }}
              noStyle
            >
              {(form) => {
                const country = form.getFieldValue("country");
                return (
                  <Form.Item label={t("zipCode")} name="zipcode">
                    {ThailandCountryWording.includes(country) ? (
                      <SelectorAddress
                        placeholder={""}
                        source={homeAddress}
                        keyName="zipcode"
                        keyData={i18n.language === "th" ? "name_th" : "name_en"}
                        keyValue="id"
                        onChange={(e) => {
                          setHomeAddress(e);
                        }}
                      />
                    ) : (
                      <Input />
                    )}
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Card>
      <Card className="cardTopClass cardBG">
        <div className="title-color mb-1">{t("mailAddress")}</div>
        <span className="mr-1">{t("accordingAddress")}</span>
        <Switch checked={openAddress} onChange={address} />

        {openAddress === false && (
          <div>
            <Row gutter={[14, 14]} className="margin-form">
              <Col md={24} lg={24}>
                <Form.Item
                  label={t("addressTH")}
                  name="addressDocTh"
                  rules={[]}
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col md={24} lg={24}>
                <Form.Item
                  label={t("addressEN")}
                  name="addressDocEn"
                  rules={[]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  shouldUpdate={(prev, next) => {
                    return isValueChange(prev, next, "countryDoc");
                  }}
                  noStyle
                >
                  {(form) => {
                    const country = form.getFieldValue("countryDoc");
                    return (
                      <Form.Item label={t("subdistrict")} name="subDistrictDoc">
                        {ThailandCountryWording.includes(country) ? (
                          <SelectorAddress
                            placeholder=""
                            source={homeAddress}
                            keyName={
                              i18n.language === "th"
                                ? "subdistrictTh"
                                : "subdistrictEn"
                            }
                            keyData={
                              i18n.language === "th" ? "name_th" : "name_en"
                            }
                            keyValue="id"
                            onChange={(e) => {
                              setMailAddress(e);
                            }}
                          />
                        ) : (
                          <Input />
                        )}
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  shouldUpdate={(prev, next) => {
                    return isValueChange(prev, next, "countryDoc");
                  }}
                  noStyle
                >
                  {(form) => {
                    const country = form.getFieldValue("countryDoc");
                    return (
                      <Form.Item label={t("district")} name="districtDoc">
                        {ThailandCountryWording.includes(country) ? (
                          <SelectorAddress
                            placeholder=""
                            source={homeAddress}
                            keyName={
                              i18n.language === "th"
                                ? "districtTh"
                                : "districtEn"
                            }
                            keyData={
                              i18n.language === "th" ? "name_th" : "name_en"
                            }
                            keyValue="id"
                            onChange={(e) => {
                              setMailAddress(e);
                            }}
                          />
                        ) : (
                          <Input />
                        )}
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  shouldUpdate={(prev, next) => {
                    return isValueChange(prev, next, "countryDoc");
                  }}
                  noStyle
                >
                  {(form) => {
                    const country = form.getFieldValue("countryDoc");
                    return (
                      <Form.Item label={t("province")} name="provinceDoc">
                        {ThailandCountryWording.includes(country) ? (
                          <SelectorAddress
                            placeholder=""
                            source={homeAddress}
                            keyName={
                              i18n.language === "th"
                                ? "provinceTh"
                                : "provinceEn"
                            }
                            keyData={
                              i18n.language === "th" ? "name_th" : "name_en"
                            }
                            keyValue="id"
                            onChange={(e) => {
                              setMailAddress(e);
                            }}
                          />
                        ) : (
                          <Input />
                        )}
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item label={t("country")} name="countryDoc">
                  <Selector
                    placeholder=""
                    Source={prefixList?.COUNTRY || []}
                    keyName="name"
                    keyValue="name"
                    onChange={() => {
                      basicInfoForm.setFieldsValue({
                        subDistrictDoc: undefined,
                        provinceDoc: undefined,
                        districtDoc: undefined,
                        zipcodeDoc: undefined,
                      });
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  shouldUpdate={(prev, next) => {
                    return isValueChange(prev, next, "countryDoc");
                  }}
                  noStyle
                >
                  {(form) => {
                    const country = form.getFieldValue("countryDoc");
                    return (
                      <Form.Item label={t("zipCode")} name="zipcodeDoc">
                        {ThailandCountryWording.includes(country) ? (
                          <SelectorAddress
                            placeholder=""
                            source={homeAddress}
                            keyName="zipcode"
                            keyData={
                              i18n.language === "th" ? "name_th" : "name_en"
                            }
                            keyValue="id"
                            onChange={(e) => {
                              setMailAddress(e);
                            }}
                          />
                        ) : (
                          <Input />
                        )}
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Card>
      <Card className="cardTopClass cardBG">
        <div className="title-color ">{t("contactInfo")}</div>
        <Row gutter={[14, 14]} className="margin-form">
          <Col md={12} lg={12}>
            <Form.Item label={t("email")} name="email">
              <Input />
            </Form.Item>
          </Col>

          <Col md={12} lg={6}>
            <Form.Item
              id="mobile"
              label={t("phoneNum")}
              name="mobile"
              rules={[
                {
                  pattern: /^[()+\d]{9,20}$/,
                  message: t("pleaseNumber"),
                },
                { required: true },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col md={12} lg={6}>
            <Form.Item
              id="phone"
              rules={[
                {
                  pattern: /^[()+\d]{9,20}$/,
                  message: t("pleaseNumber"),
                },
              ]}
              label={t("homeNumber")}
              name="phone"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              id="contactPerson"
              label={t("emergencyContact")}
              name="contactPerson"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={6}>
            <Form.Item
              rules={[
                {
                  pattern: /^[()+\d]{9,20}$/,
                  message: t("pleaseNumber"),
                },
              ]}
              id="contactNumber"
              label={t("emergencyNumber")}
              name="contactNumber"
            >
              <Input />
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item
              label={t("contactRelationship")}
              name="contactRelationship"
            >
              <Input id="contactRelationship" />
            </Form.Item>
          </Col>

          {/* <Col lg={6}>
            <Form.Item label="ผู้รับบริบาลสาขา" name="hn">
              <Input />
            </Form.Item>
          </Col> */}
        </Row>
      </Card>
    </Card>
  );
};

export default ContactInformation;
