import React, { useEffect, useRef, useState } from "react";
import { dayjs } from "@tools/timezone";
import { useForm } from "antd/es/form/Form";
import { IGetVisits } from "@interface/IVisits.interface";
import { keyNameOrder } from "@provider/modal_order/interface";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useCreateMedia } from "@services/api/media_object";
import { openNotification } from "@components/notification/Notification";
import { IGetWorkOrderItem } from "@interface/IWorkOrder.interface";
import { Button, Card, Col, Form, Row } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useUpdateWorkOrderItemById } from "@services/api/work_order";
import { dataUrlToFile, optionDataList } from "@tools/util";
import { ENUM_G6PD, ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { useUpdateWorkOrderCourseItemById } from "@services/api/work_order";
import { ENUM_SERVICE_GROUP, EVENT_ROOM, ORDER_TYPE } from "@tools/util.enum";
import Note from "@components/note_visit/NoteVisit";
import Advice from "@components/advice/Advice";
import VitalSign from "@components/vital_sign/VitalSign";
import OrderList from "../order_list/OrderList";
import SymbolView from "@components/symbol/Symbol";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import VitalSignModal from "@components/vital_sign/modal/VitalSign";
import ProductCategory from "@components/product_category/ProductCategory";
import NursingAssessment from "@components/note_visit/NoteVisit";
import MainModalOrderProvider from "@provider/modal_order";

type Props = {
  patientID: number;
  visitsByID: IGetVisits | undefined;
  orderList?: IGetWorkOrderItem;
  medicalRecord: boolean;
};

const Fields = ({ patientID, visitsByID, orderList, medicalRecord }: Props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState({ oneDay: true, continuous: true });
  const [visible, setVisible] = useState({ oneDay: false, continuous: false });
  const stageRef = useRef<any>(null);
  const [workOrderID, setWorkOrderID] = useState<number>();
  const client = useQueryClient();
  const updateWorkOrderItemById = useUpdateWorkOrderItemById();
  const createMedia = useCreateMedia();
  const updateWorkOrderCourseItemById = useUpdateWorkOrderCourseItemById();
  const [patientList] = useForm();
  const [important] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState({
    one: false,
    continuous: false,
  });
  const match: any = useRouteMatch();
  const visitId = match.params.visitID;

  useEffect(() => {
    if (!visitsByID) {
      return;
    }

    important.setFieldsValue({
      ...visitsByID?.data?.visit,
    });
    patientList?.setFieldsValue({
      foodAllergy: visitsByID?.patient?.foodAllergy || undefined,
      drugAllergy: visitsByID?.patient?.drugAllergy || undefined,
    });
    // eslint-disable-next-line
  }, [visitsByID]);

  const viewDetailOneDay = () => {
    setOpen({ ...open, oneDay: !open.oneDay });
  };

  const viewDetailContinuous = () => {
    setOpen({ ...open, continuous: !open.continuous });
  };

  const handleCreateMedia = (data: any) => {
    if (stageRef?.current) {
      let imageData =
        stageRef?.current?.canvas?.drawing?.toDataURL("image/png");
      const file = dataUrlToFile(imageData, "signature.png");
      if (file) {
        const formData = new FormData();
        formData?.append("file", file);
        if (data?.categoryId === "C-0006" || data?.categoryId === "C-0007") {
          createMedia.mutate(formData, {
            onSuccess: (res) => {
              handleUpdateWorkOrderCourseItem({
                ...data,
                signatureId: res?.result?.[0]?.id,
              });
            },
            onError: (error) => {
              openNotification({
                method: "POST_ERROR",
                message: String(error),
              });
            },
          });
        } else {
          createMedia.mutate(formData, {
            onSuccess: (res) => {
              handleUpdateWorkOrderItem({
                ...data,
                signatureId: res?.result?.[0]?.id,
              });
            },
            onError: (error) => {
              openNotification({
                method: "POST_ERROR",
                message: String(error),
              });
            },
            onSettled: () => {
              client.invalidateQueries(["get-visitsByID", visitId]);
            },
          });
        }
      }
    } else {
      if (data?.categoryId === "C-0006" || data?.categoryId === "C-0007") {
        handleUpdateWorkOrderCourseItem({
          ...data,
        });
      } else {
        handleUpdateWorkOrderItem({
          ...data,
        });
      }
    }
  };

  const handleUpdateWorkOrderItem = (data: any) => {
    const option = optionDataList(data);
    updateWorkOrderItemById.mutate(
      {
        data: {
          ...data,
          option: JSON.stringify(option),
          status: "PARTIAL",
          remark: data?.remark || "",
          use: data?.use || false,
        },
        id: workOrderID,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries([
            `get-workOrders-out-standing`,
            patientID,
            {
              serviceGroup: ENUM_SERVICE_GROUP.MEDICAL_PROCEDURE,
            },
          ]);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
        onSettled: () => {
          let dataKey = data?.key as keyNameOrder;
          setIsModalOpen({ ...isModalOpen, [dataKey]: false });
        },
      }
    );
  };

  const handleUpdateWorkOrderCourseItem = async (data: any) => {
    const option = optionDataList(data);
    updateWorkOrderCourseItemById.mutate(
      {
        data: {
          ...data,
          option: JSON.stringify(option),
          status: "PARTIAL",
          remark: data?.remark || "",
          use: data?.use || false,
        },
        id: workOrderID,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries([
            `get-workOrders-out-standing`,
            patientID,
            {
              serviceGroup: ENUM_SERVICE_GROUP.MEDICAL_PROCEDURE,
            },
          ]);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
        onSettled: () => {
          let dataKey = data?.key as keyNameOrder;
          client.invalidateQueries(["get-visitsByID", visitId]);
          setIsModalOpen({ ...isModalOpen, [dataKey]: false });
        },
      }
    );
  };

  const onManageModal = (key: "oneDay" | "continuous") => {
    setVisible({
      ...visible,
      [key]: !visible?.[key],
    });
  };

  return (
    <React.Fragment>
      <Card
        className="bg-grey m-1-doctor-room "
        extra={
          <React.Fragment>
            <Row gutter={[14, 14]} className="text-row-profile ml-05">
              <Col>
                <ImagePatient src={visitsByID?.patient?.image} />
              </Col>
              <Col>
                <Row>
                  <Col xs={24} lg={24}>
                    {i18n.language === "th"
                      ? ` ${visitsByID?.patient?.firstNameTh || " "}    ${
                          visitsByID?.patient?.lastNameTh || " "
                        }`
                      : ` ${visitsByID?.patient?.firstNameEn || " "}    ${
                          visitsByID?.patient?.lastNameEn || " "
                        }`}
                  </Col>

                  <Col xs={24} lg={24}>
                    <div className="code-profile">
                      HN : {visitsByID?.patient?.hn}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className=" pr-15" style={{ marginLeft: "auto" }}>
                <div className="t-center ">
                  <div className=" t-grey fs-18 ">{t("orderNumber")} </div>
                  <div className=" t-grey-light fs-12 ">
                    {visitsByID?.orderNo || ""}{" "}
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        }
      >
        <Row align="middle">
          <Col>
            <div className="mb-05">
              <span className=" t-blue fs-18 f-normal ">{t("fullList")} </span>
              <span className=" t-grey-light  fs-12 ">
                {`${t("orderCreationDate")}:`}{" "}
              </span>
              <span className="t-grey fs-12 ">
                {visitsByID
                  ? `${dayjs(visitsByID.date).format("DD-MM-YYYY")}`
                  : ``}
              </span>
            </div>
          </Col>

          <Col style={{ marginLeft: "auto" }}>
            <div style={{ color: "#FEB139" }}>
              <SymbolView />
            </div>
            <div
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {visitsByID?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                ? "G6PD Deficiency"
                : null}
            </div>
          </Col>
        </Row>

        <Form name="patientList" form={patientList}>
          <ImportantInfo />
        </Form>
        <Advice visitsByID={visitsByID} />
        <NursingAssessment
          title={t("nursingRecords")}
          name="nurseNote"
          visitsByID={visitsByID}
          medicalRecord={medicalRecord}
        />
        <Note
          title={t("note")}
          name="note"
          visitsByID={visitsByID}
          medicalRecord={medicalRecord}
        />

        <Form name="vitalSignCrm" id="vitalSignCrm" form={important}>
          <Card
            className="comment-card mt-2 "
            extra={
              <React.Fragment>
                <Row gutter={[14, 14]}>
                  <Col lg={12}>Vital Sign CRM</Col>
                </Row>
              </React.Fragment>
            }
          >
            <div className="t-black-light">
              <VitalSign medicalRecord={false} />
            </div>
          </Card>
        </Form>

        <VitalSignModal
          title="Vital Sign Lying down (5mins)"
          visitsByID={visitsByID}
          keyName="lyingDown"
        />

        <VitalSignModal
          title="Vital Sign Sitting (1 min)"
          visitsByID={visitsByID}
          keyName="sitting1Min"
        />

        <VitalSignModal
          title="Vital Sign Standing (3mins)"
          visitsByID={visitsByID}
          keyName="standing1Mins"
        />

        <div className="mt-2 ">
          <Row className=" header-table" align="middle">
            <Col className="f-normal  t-dark-blue  ">
              <span className="f-bold mr-05 ">
                {`${t("listExecutedOrders")} One Day`}
              </span>
              <span>
                {React.createElement(open.oneDay ? UpOutlined : DownOutlined, {
                  onClick: viewDetailOneDay,
                })}
              </span>
            </Col>
            {medicalRecord && (
              <Col style={{ marginLeft: "auto" }}>
                <Button
                  className="btn-print"
                  type="primary"
                  onClick={() =>
                    setVisible({
                      ...visible,
                      oneDay: !visible.oneDay,
                    })
                  }
                >
                  {`+ ${t("addOrder")}`}
                </Button>
              </Col>
            )}
          </Row>

          <div className={open.oneDay ? "open" : "close"}>
            <MainModalOrderProvider
              value={{
                isModalOpen: isModalOpen,
                key: "one",
                setIsModalOpen: setIsModalOpen,
              }}
            >
              <OrderList
                orderList={orderList?.one?.groups || []}
                handleUpdateWorkOrderItem={handleCreateMedia}
                stageRef={stageRef || undefined}
                setWorkOrderID={setWorkOrderID}
                medicalRecord={medicalRecord}
              />
            </MainModalOrderProvider>
          </div>
        </div>

        <div className="mt-2">
          <Row className="header-table" align="middle">
            <Col className="f-normal t-dark-blue">
              <span className="f-bold mr-05 ">
                {`${t("listExecutedOrders")} Continuous`}
              </span>
              <span>
                {React.createElement(
                  open.continuous ? UpOutlined : DownOutlined,
                  {
                    onClick: viewDetailContinuous,
                  }
                )}
              </span>
            </Col>
            {medicalRecord && (
              <Col style={{ marginLeft: "auto" }}>
                <Button
                  className="btn-print"
                  type="primary"
                  onClick={() =>
                    setVisible({
                      ...visible,
                      continuous: !visible.continuous,
                    })
                  }
                >
                  {`+ ${t("addOrder")}`}
                </Button>
              </Col>
            )}
          </Row>

          <div className={open.continuous ? "open" : "close"}>
            <MainModalOrderProvider
              value={{
                isModalOpen: isModalOpen,
                key: "continuous",
                setIsModalOpen: setIsModalOpen,
              }}
            >
              <OrderList
                orderList={orderList?.continuous?.groups || []}
                handleUpdateWorkOrderItem={handleCreateMedia}
                stageRef={stageRef || undefined}
                setWorkOrderID={setWorkOrderID}
                medicalRecord={medicalRecord}
              />
            </MainModalOrderProvider>
          </div>
        </div>
      </Card>
      {visible.oneDay && (
        <ProductCategory
          patientID={visitId}
          visible={visible.oneDay}
          onModal={() => onManageModal("oneDay")}
          status={EVENT_ROOM.NURSE}
          type={ORDER_TYPE.ONE_DAY}
          room={ENUM_ROOM_SIGNATURE.PROCEDURE}
        />
      )}
      {visible.continuous && (
        <ProductCategory
          patientID={visitId}
          visible={visible.continuous}
          onModal={() => onManageModal("continuous")}
          status={EVENT_ROOM.NURSE}
          type={ORDER_TYPE.CONTINUE}
          room={ENUM_ROOM_SIGNATURE.PROCEDURE}
        />
      )}
    </React.Fragment>
  );
};

export default Fields;
