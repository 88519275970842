import React, { FC, useEffect, useState } from "react";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useGetProducts } from "@services/api/order";
import { useTranslation } from "react-i18next";
import { IProductBranch } from "@interface/IProduct.interface";
import { MANAGE_COURSES } from "@assets/data/pathRouter";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  search: any;
  currentPage: ICurrentPage;
};

const TableCourses: FC<Props> = ({ search, currentPage }) => {
  const { t, i18n } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const history = useHistory<RouteComponentProps["history"]>();
  const productList = useGetProducts("C-0006", {
    page: currentPage?.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    search: search?.name,
    branchId: search?.branchId,
  });

  useEffect(() => {
    if (!productList?.data) return;
    setTotal(productList?.data?.result?.[0]?.total || 1);
    //eslint-disable-next-line
  }, [productList?.data?.result?.[0]?.total]);

  const onClickRow = (e: any) => {
    history.push(`${MANAGE_COURSES.update}/${e.id}`);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage?.setCurrentPage(page);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div>{limitPage * (currentPage?.currentPage - 1) + index + 1}</div>
        );
      },
    },
    {
      title: t("productName"),
      dataIndex: i18n?.language === "th" ? "nameTh" : "nameEn",
      key: i18n?.language === "th" ? "nameTh" : "nameEn",
    },

    {
      title: t("branch"),
      dataIndex: "productBranch",
      key: "productBranch",
      render: (productBranch: IProductBranch[]) => {
        return (
          <div>
            {productBranch
              ?.flatMap((item) => {
                return item?.branch;
              })
              ?.map((branch) => {
                return i18n.language === "th" ? branch?.nameTh : branch?.nameEn;
              })
              ?.join(" , ")
              ?.trim() || ""}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <MoTable
        dataSource={productList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage?.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </React.Fragment>
  );
};

export default TableCourses;
