import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Col, Row } from "antd";
import { useGetVisitsByID } from "@services/api/visits";
import { useGetCompareVisit } from "@services/api/lab";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Header from "./Header";
import Print from "@components/lab_result/print/Print";

const PrintLabResult = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const printReportRef = useRef(null);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const visitsByID = useGetVisitsByID(visitID);
  const compare = useGetCompareVisit(visitID, true);
  const pageStyle = `  @page { size:210mm 297mm;
      margin: 40px;}`;

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Header history={history} handlePrint={handlePrint} />
      <ReactToPrint content={() => printReportRef.current} />

      <div
        style={{ justifyContent: "center", display: "flex" }}
        className="set-layout  "
      >
        <div style={{ minHeight: "1000px" }} className="document-bill ">
          <Row justify="center">
            <Col span={24}>
              <Print
                refData={printReportRef}
                compare={compare?.data}
                visitsByID={visitsByID?.data}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PrintLabResult;
