import { IBranch } from "@interface/IBranch.interface";
import { thrownResponse } from "./auth";
import { IUserRoom, IUserRoomUser } from "@interface/IUserRoom.interface";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetUserRoomList = (
  params?: any
): UseQueryResult<Elementary<IUserRoom[]>> => {
  return useQuery(["get-user-room-list"], async () => {
    const res = await axios.get(`/user-room`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetUserRoomUser = (
  id?: any
): UseQueryResult<ElementaryOneMore<IUserRoomUser>> => {
  return useQuery(["get-user-room-user", id], async () => {
    if (!id && id !== 0) {
      return;
    }
    const res = await axios.get(`/user-room/user/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateUserRoom = (): UseMutationResult => {
  return useMutation(["create-user-room"], async (data: any) => {
    const res = await axios.post(`/user-room`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteUserRoom = (): UseMutationResult => {
  return useMutation(async (id?: any) => {
    if (!id) {
      return;
    }
    const res = await axios.delete(`/user-room/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetUserRoomRoomID = (
  id?: number
): UseQueryResult<
  ElementaryOneMore<{
    userIds: number[];
    roomId: number;
    branch: IBranch;
  }>
> => {
  return useQuery(
    ["get-room-by-ID", id],
    async () => {
      const res = await axios.get(`/user-room/room/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !id ? false : true }
  );
};

export const useUpdateUserRoom = (): UseMutationResult => {
  return useMutation(["update-user-room"], async ({ data }: any) => {
    const res = await axios.put(`/user-room`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
