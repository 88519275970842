import { t } from "i18next";
import { FC } from "react";
import { Age } from "@tools/age";
import { IParamet } from "@interface/IGlobal.interface";
import { IGetLabInfo } from "@interface/IWorkOrder.interface";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { capitalize, genderToThaiWord } from "@tools/util";

type Props = {
  refData: React.MutableRefObject<null>;
  labInfoList?: IParamet<IGetLabInfo[]>;
};

const Print: FC<Props> = ({ refData, labInfoList }) => {
  const { i18n } = useTranslation();
  const labInfo = labInfoList?.result?.[0];
  return (
    <div ref={refData}>
      <div className="print-label">
        {i18n.language === "en"
          ? [
              labInfo?.patient?.titleEn,
              labInfo?.patient?.firstNameEn,
              labInfo?.patient?.lastNameEn,
            ]
              ?.join(" ")
              ?.trim()
          : [
              labInfo?.patient?.titleTh,
              labInfo?.patient?.firstNameTh,
              labInfo?.patient?.lastNameTh,
            ]
              ?.join(" ")
              ?.trim()}

        {!!labInfo?.patient?.dateOfBirth
          ? ` , ${Age(labInfo?.patient?.dateOfBirth || "")} ${t("year")}`
          : ""}
      </div>
      <div className="print-label">
        DOB
        {` ${
          !!labInfo?.patient?.dateOfBirth
            ? dayjs(labInfo?.patient?.dateOfBirth).format("DD/MM/YYYY")
            : ""
        } `}
        {`${labInfo?.labNumber || ""}`}
      </div>

      <div className="print-label">
        {dayjs().format("DD/MM/YYYY")} (AH) {t("sex")}{" "}
        {i18n.language === "en"
          ? capitalize(labInfo?.patient?.gender || "")
          : genderToThaiWord(labInfo?.patient?.gender || "")}
      </div>
      <div className="print-label">HN {labInfo?.patient?.hn || ""}</div>
    </div>
  );
};

export default Print;
