import { RETURN_COURSE } from "@assets/data/pathRouter";
import PatientList from "@components/patient_list/PatientList";

const History = () => {
  return (
    <div>
      <PatientList path={RETURN_COURSE.historyList} />
    </div>
  );
};

export default History;
