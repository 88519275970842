import { useForm } from "antd/lib/form/Form";
import { UploadOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useEffect, useState } from "react";
import { useUpdateDocumentName } from "@services/api/media_object";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import { Button, Form, FormInstance, Input, Modal, Upload } from "antd";

interface Props extends UploadProps {
  uploadLabel?: string;
  form?: FormInstance;
  keyName?: string;
}
const FileSpace = ({
  multiple = false,
  uploadLabel = "Upload",
  form,
  keyName,
  ...props
}: Props) => {
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState<UploadFile | undefined>();
  const [_form] = Form.useForm();

  const toggle = () => {
    setVisible(!visible);
  };

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  return (
    <>
      <ChangeFileNameModal
        form={form || _form}
        onClose={toggle}
        visible={visible}
        file={current}
        keyName={keyName || ""}
      />

      <Upload
        className={form ? "file-space cursor-upload" : "file-space "}
        multiple={multiple}
        listType="picture"
        customRequest={props.customRequest ? props.customRequest : dummyRequest}
        onPreview={
          form
            ? (e) => {
                toggle();
                setCurrent(e);
              }
            : undefined
        }
        {...props}
      >
        {!props?.disabled ? (
          <Button
            icon={<UploadOutlined />}
            disabled={false}
            block
            type="dashed"
          >
            {uploadLabel}
          </Button>
        ) : null}
      </Upload>
    </>
  );
};

const ChangeFileNameModal = ({
  onClose,
  visible,
  form,
  file,
  keyName,
}: {
  onClose: () => void;
  visible: boolean;
  form: FormInstance;
  file?: UploadFile;
  keyName: string;
}) => {
  const { t } = useTranslation();
  const [formEditFileName] = useForm();
  const editName = useUpdateDocumentName();
  useEffect(() => {
    if (!file?.name) return;
    let name = file?.name.split(".");

    formEditFileName.setFieldsValue({
      text: file?.name?.slice(
        0,
        file?.name?.length - Number(name?.[name?.length - 1]?.length + 1)
      ),
    });
    // eslint-disable-next-line
  }, [file]);

  const onSubmit = (value: { text: string }) => {
    const data = form.getFieldsValue();
    const prev = form.getFieldsValue()[`${keyName}`] as UploadFile[];

    const next = prev?.map((item: any) => {
      if (item?.uid === file?.uid && !item?.url) {
        let name = item?.originFileObj?.name?.split(".");
        return {
          ...item,
          name: `${value?.text}${item?.originFileObj?.name?.slice(
            item?.originFileObj?.name?.length -
              Number(name?.[name?.length - 1]?.length + 1),
            item?.originFileObj?.name?.length
          )}`,
          originFileObj: new File(
            [item?.originFileObj],
            `${value?.text}${item?.originFileObj?.name?.slice(
              item?.originFileObj?.name?.length -
                Number(name?.[name?.length - 1]?.length + 1),
              item?.originFileObj?.name?.length
            )}`,
            {
              type: item?.originFileObj?.type,
            }
          ),
        };
      } else if (!!item?.url && item?.uid === file?.uid) {
        let name = item?.name.split(".");

        editName.mutate(
          {
            id: Number(item?.fileId),
            data: {
              name: `${value?.text}${item?.name?.slice(
                item?.name?.length -
                  Number(name?.[name?.length - 1]?.length + 1),
                item?.name?.length
              )}`,
            },
          },
          {
            onSuccess: () => {
              openNotification({ method: "PUT_SUCCESS" });
            },
            onError: (err) => {
              openNotification({ method: "PUT_ERROR", message: String(err) });
            },
          }
        );

        return {
          ...item,
          name: `${value?.text}${item?.name?.slice(
            item?.name?.length - Number(name?.[name?.length - 1]?.length + 1),
            item?.name?.length
          )}`,
        };
      }
      return item;
    });

    form.setFieldsValue({ ...data, [`${keyName}`]: next });
    onClose();
  };

  return (
    <Modal
      okText={t("save")}
      cancelText={t("cancel")}
      centered
      visible={visible}
      onCancel={onClose}
      title={t("editFileName")}
      onOk={() => formEditFileName.submit()}
    >
      <Form onFinish={onSubmit} form={formEditFileName}>
        <Form.Item
          name="text"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FileSpace;
