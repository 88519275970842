import { ISignature } from "@interface/IGlobal.interface";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
  handlePrint: (e?: any) => void;
  createOrderDone: () => void;
  signature: ISignature;
};

const Header = ({
  history,
  handlePrint,
  createOrderDone,
  signature,
}: Props) => {
  const { t } = useTranslation();
  const create = useIsMutating(["create-orders-done"]);
  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("executeorderOrder.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} /</span>
              <span className="t-light-gray">{t("execute")} / </span>
              <span className="t-dark-blue f-normal">
                {t("consentDocuments")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button
            className="cancel mr-05 "
            htmlType="submit"
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>

          <Button
            className="mr-05 reserve"
            htmlType="reset"
            onClick={() => handlePrint()}
          >
            {t("print")}
          </Button>
          {signature?.signature ? (
            <Button
              className="btn-create"
              htmlType="submit"
              onClick={createOrderDone}
              loading={!!create}
            >
              {t("save")}
            </Button>
          ) : (
            <>
              <Button className="btn-create" htmlType="submit" disabled>
                {t("save")}
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Header;
