import { io } from "socket.io-client";
import { dayjs } from "@tools/timezone";
import { INotification } from "@interface/INotification.interface";
import { useTranslation } from "react-i18next";
import { useGetNotification } from "@services/api/notification";
import { useCallback, useEffect, useState } from "react";
import { NotificationTwoTone } from "@ant-design/icons";
import { useGetNotificationCount } from "@services/api/notification";
import { Avatar, Badge, Col, Divider, notification, Popover, Row } from "antd";
import React from "react";
import Alert from "@assets/img/icon/Alert.svg";
import UserContext from "@provider/UserProvider";
import InfiniteScroll from "react-infinite-scroll-component";
import debounce from "lodash.debounce";

const Notifications = () => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [bellCount, setBellCount] = useState(0);
  const notificationList = useGetNotification(visible);
  const notificationCount = useGetNotificationCount();
  const endpoint = `${window._env_[`REACT_APP_API_URL`]}`;
  const userList = React.useContext(UserContext);
  let token: string | null = "";
  if (typeof window !== "undefined") {
    token = localStorage.getItem("accessToken");
  }
  const [socket, setSocket] = useState<any>();

  useEffect(() => {
    const socketConnection = io(endpoint.split("/api/")?.[0] || "/", {
      query: { token: `"${token}"` },
    });
    setSocket(socketConnection);

    // eslint-disable-next-line
  }, []);

  const enqueueCountNotification = useCallback(
    debounce(() => {
      notificationCount.mutate(
        {},
        {
          onSuccess: (data) => {
            setBellCount(data?.result?.[0]?.count || 0);
          },
          onError: () => {},
        }
      );
    }, 1500),
    []
  );

  const countDown = () => {
    let secondsToGo = 5;
    const key = "updatable";

    const timer = setInterval(() => {
      secondsToGo -= 1;
      notification.open({
        key,
        message: t("logOut"),
        description: `${t(
          "willBeLoggedOutOfTheInternalSystem"
        )} ${secondsToGo}`,
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(timer);
    }, secondsToGo * 1000);
  };

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.on("role-change", (res: any) => {
      if (userList?.role === res?.role) {
        countDown();
        setTimeout(() => {
          localStorage.removeItem("accessToken");
          window.location.reload();
        }, 5000);
      }
    });

    socket.on("user-deleted", (res: any) => {
      countDown();
      setTimeout(() => {
        localStorage.removeItem("accessToken");
        window.location.reload();
      }, 5000);
    });

    socket.on("notification", (res: INotification) => {
      if (res) {
        openNotification(res);
        enqueueCountNotification();
      }
    });

    socket.on("alarm-slot", (res: any) => {
      if (res) {
        openNotification(res);
        enqueueCountNotification();
      }
    });

    enqueueCountNotification();
    return () => {
      socket?.disconnect();
    };
    // eslint-disable-next-line
  }, [socket]);

  const openNotification = (res: any, message?: string) => {
    const args = {
      message: message || "Notification",
      description:
        i18n.language === "th"
          ? res?.massage?.massageTh
          : res?.massage?.massageEn,
      icon: (
        <NotificationTwoTone
          style={{
            color: "#108ee9",
          }}
        />
      ),
    };

    notification.info(args);
  };

  const entireData = (): { data: any[]; hasMore: boolean; current: number } => {
    const { pages } = notificationList?.data || {};
    const pagesLength = pages?.length || 0;
    const data = pages
      ?.map((item) => {
        return item?.data;
      })
      ?.flat();
    const pageCount = pages?.[pagesLength - 1]?.pageCount || 0;
    const current = pages?.[pagesLength - 1]?.page || 1;
    return { data: data || [], hasMore: pageCount > current, current: current };
  };

  const { data, hasMore, current } = entireData();

  const fetchMoreData = () => {
    notificationList.fetchNextPage({ pageParam: { page: current + 1 } });
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
    setBellCount(0);
  };

  return (
    <div
      style={{ height: "40px", paddingRight: "0.3rem" }}
      className="d-flex items-center"
    >
      <Popover
        visible={visible}
        onVisibleChange={handleVisibleChange}
        arrowPointAtCenter={true}
        overlayClassName="notification notification-display"
        overlayInnerStyle={{ padding: "0px" }}
        placement="bottom"
        content={
          <div className="notification-list-massage">
            <InfiniteScroll
              dataLength={data?.length}
              next={fetchMoreData}
              hasMore={hasMore}
              loader={<div>Loading...</div>}
              height={350}
            >
              {data?.map((item, index) => {
                return (
                  <div key={index} className="mt-1">
                    <Row align="middle">
                      <Col span={3}>
                        <Avatar
                          style={{
                            backgroundColor: "#094C97",
                          }}
                        >
                          {i18n.language === "th"
                            ? item?.massageTh?.[0]?.toLocaleUpperCase() || ""
                            : item?.massageEn?.[0]?.toLocaleUpperCase() || ""}
                        </Avatar>
                      </Col>
                      <Col span={21}>
                        {i18n.language === "th"
                          ? item?.massageTh
                          : item?.massageEn}
                      </Col>
                    </Row>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "end",
                        fontSize: 8,
                        color: "grey",
                      }}
                    >
                      {dayjs(item?.date)?.format("DD/MM/YYYY")}
                    </div>
                    <Divider style={{ marginTop: 0 }} className=" mb-05" />
                  </div>
                );
              })}
            </InfiniteScroll>
          </div>
        }
        trigger="click"
      >
        <Badge count={bellCount || 0} className="notifications">
          <img className="circle avatar" src={Alert} alt="aleart" />
        </Badge>
      </Popover>
    </div>
  );
};

export default Notifications;
