import React, { FC } from "react";
import { INum } from "@interface/IGlobal.interface";
import { IItem } from "@interface/IOrder.interface";
import { IChild2 } from "@interface/IBill.interface";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { keyLabInterface } from "@interface/IDraftOrder.interface";
import { ISelectedRowKeys } from "../../order/Header";
import { useGetDraftOrderList } from "@services/api/draft_order";
import { useGetAppointmentByID } from "@services/api/appointments";
import { Col, Divider, Row, Table } from "antd";
import dayjs from "dayjs";
import Decimal from "@tools/decimal";
import BigNumber from "bignumber.js";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";
import { _amountToWordsEN } from "@tools/util";

type Props = {
  refData: any;
};

const PrintReport: FC<Props> = ({ refData }) => {
  const userList = React.useContext(UserContext);
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const appointmentsID = match.params.appointmentsID;
  const appointmentByID = useGetAppointmentByID(
    !appointmentsID && appointmentsID !== 0 ? false : true,
    appointmentsID
  );
  const draftOrderList = useGetDraftOrderList(
    patientID,
    appointmentByID?.data?.result?.[0]?.visit?.eventId || 0
  );
  const dataSessionStorage = !!sessionStorage.getItem("selectedRowKeys")
    ? sessionStorage.getItem("selectedRowKeys") || "{}"
    : "{}";
  const selectedRowKeys: ISelectedRowKeys = JSON.parse(dataSessionStorage);
  const TAQSessionStorage = !!sessionStorage.getItem("totalAndQuantity")
    ? sessionStorage.getItem("totalAndQuantity") || "{}"
    : "{}";

  const totalAndQuantity: INum = JSON.parse(TAQSessionStorage);
  const THBText =
    i18n.language === "th" ? require("thai-baht-text") : _amountToWordsEN;

  const productOrder = () => {
    const product = draftOrderList?.data?.result?.[0];
    const keyLab: keyLabInterface[] = [
      "groups1",
      "groups2",
      "groups3",
      "groups4",
      "other1",
      "other2",
    ];

    const productList = [
      ...(product?.continuous?.groups?.flatMap((item) => {
        return item?.children?.flatMap((child) => {
          return child?.children;
        });
      }) || []),

      ...(keyLab?.flatMap((item) => {
        return product?.labInterface?.[item]?.flatMap((item) => {
          if (item.name !== "Promotion") {
            return item?.children?.flatMap((child) => {
              return child?.children;
            });
          }
        });
      }) || []),

      ...(product?.one?.groups?.flatMap((item) => {
        return item?.children?.flatMap((child) => {
          return child?.children;
        });
      }) || []),
    ];

    const result: any[] =
      productList?.filter((item) => {
        return [
          ...(selectedRowKeys?.continuous || []),
          ...(selectedRowKeys?.oneDay || []),
          ...(selectedRowKeys?.other1 || []),
          ...(selectedRowKeys?.other2 || []),
          ...(selectedRowKeys?.labInterfaceGroup || []),
        ]?.some((itemKey) => itemKey === item?.id);
      }) || [];

    return result;
  };

  const total = () => {
    let data;
    if (
      selectedRowKeys?.continuous?.length ||
      selectedRowKeys?.oneDay?.length
    ) {
      data = Decimal(
        Number(
          new BigNumber(totalAndQuantity?.oneDay?.total)
            .plus(new BigNumber(totalAndQuantity?.continuous?.total))
            .plus(totalAndQuantity?.labInterface?.total)
            .toFixed(2)
        )
      );
    } else {
      data = Decimal(
        Number(
          new BigNumber(0)
            .plus(new BigNumber(totalAndQuantity?.labInterface.total))
            .toFixed(2)
        )
      );
    }

    return data;
  };

  const grandTotal = () => {
    let data;
    if (
      selectedRowKeys?.continuous?.length ||
      selectedRowKeys?.oneDay?.length ||
      totalAndQuantity?.labInterface?.quantity
    ) {
      if (
        totalAndQuantity?.oneDay?.total ||
        totalAndQuantity?.continuous?.total ||
        totalAndQuantity?.labInterface?.total
      ) {
        data = Decimal(
          Number(
            new BigNumber(
              totalAndQuantity?.discountTotal || totalAndQuantity?.total
            ).toFixed(2)
          )
        );
      } else {
        data = new BigNumber(totalAndQuantity?.total).toFixed(2);
      }
    } else {
      data = new BigNumber(totalAndQuantity?.total).toFixed(2);
    }

    return data;
  };

  const address = (status?: string) => {
    const mailingAddress =
      appointmentByID?.data?.result?.[0]?.patient?.mailingAddress;
    const mailAddress =
      mailingAddress?.addressTh || mailingAddress?.districtTh
        ? [
            mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            mailingAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "districtTh" : "districtEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "provinceTh" : "provinceEn"
            ],
            mailingAddress?.zipcode,
            mailingAddress?.country,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const _homeAddress =
      appointmentByID?.data?.result?.[0]?.patient?.homeAddress;
    const homeAddress =
      _homeAddress?.addressTh || _homeAddress?.districtTh
        ? [
            _homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            _homeAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            _homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
            _homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
            _homeAddress?.country,
            _homeAddress?.zipcode,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    if (mailAddress) {
      if (status === "zipcode") {
        return appointmentByID?.data?.result?.[0]?.patient?.mailingAddress
          ?.zipcode;
      }
      return mailAddress;
    } else {
      if (status === "zipcode") {
        return appointmentByID?.data?.result?.[0]?.patient?.homeAddress
          ?.zipcode;
      }
      return homeAddress;
    }
  };

  const columns: any = [
    {
      title: "#",
      dataIndex: "id",
      key: "index",
      render: (_: any, __: IChild2, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: `${t("productName")} / ${t("details")}`,
      dataIndex: "nameEn",
      key: "index",
      render: (_: any, data: IItem) => {
        return <div>{data?.product?.nameTh}</div>;
      },
    },
    {
      title: t("amount"),
      dataIndex: "qty",
      render: (qty: string) => {
        return <div>{qty}</div>;
      },
    },

    {
      title: t("unitPrice"),
      dataIndex: "normal",
      render: (normal: string) => {
        return <div>{Decimal(normal || 0)}</div>;
      },
    },

    {
      title: t("discounts"),
      dataIndex: "unitPrice",
      render: (_: any, data: IItem) => {
        return (
          <div>
            {data?.discountPercent
              ? `${data?.discountPercent} % `
              : data?.discount
              ? `${data?.discount} ฿ `
              : ""}
          </div>
        );
      },
    },
    {
      title: t("totalPrice"),
      dataIndex: "net",
      render: (net: string) => {
        return <div>{Decimal(net)}</div>;
      },
    },
  ];

  return (
    <div ref={refData}>
      <table className="report-drug f-light  " width="100%">
        <tbody>
          <tr>
            <td
              style={{
                width: "50vw",
                fontWeight: "lighter",
                textAlign: "start",
              }}
            >
              <div className="mb-1">
                <ImageLogo
                  src={userList?.branch?.image}
                  alt="Logo"
                  width="200px"
                  height="70px"
                />
              </div>
              <div className="example">
                <span className="break-inside ">
                  {
                    i18n.language === "th"
                      ? userList?.branch?.nameTh
                      : userList?.branch?.nameEn
                    // t("location.title")
                  }
                </span>
                <span className="break-inside ">
                  {i18n.language === "th"
                    ? userList?.branch?.addressTh
                    : userList?.branch?.addressEn}
                </span>
                <span className="break-inside ">
                  {[
                    userList?.branch?.[
                      i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
                    ],
                    userList?.branch?.[
                      i18n.language === "th" ? "districtTh" : "districtEn"
                    ],
                    userList?.branch?.[
                      i18n.language === "th" ? "provinceTh" : "provinceEn"
                    ],
                    userList?.branch?.zipcode,
                  ]
                    ?.join(" ")
                    ?.trim()}
                </span>
              </div>
              <div>
                <span>{t("taxId")} :</span> {userList?.branch?.taxId}
              </div>
              <div>
                <span>{t("tel")} :</span> {userList?.branch?.phone}
              </div>
              <div className="pt-1">
                <span className="f-bold t-blue">{t("customer")} </span>
              </div>
              <div>
                <span className="break-inside ">
                  {t("customerName")} :{" "}
                  {i18n.language === "th"
                    ? [
                        appointmentByID?.data?.result?.[0]?.patient
                          ?.firstNameTh,
                        appointmentByID?.data?.result?.[0]?.patient?.lastNameTh,
                      ]
                        ?.join(" ")
                        ?.trim()
                    : [
                        appointmentByID?.data?.result?.[0]?.patient
                          ?.firstNameEn,
                        appointmentByID?.data?.result?.[0]?.patient?.lastNameEn,
                      ]
                        ?.join(" ")
                        ?.trim()}
                </span>{" "}
              </div>
              <div>
                <span className="break-inside ">
                  {t("address")} : {address() || "-"}
                </span>
              </div>

              <div>
                <span className="break-inside ">
                  {t("zipCode")} : {address("zipcode") || "-"}
                </span>
              </div>
            </td>

            <td
              style={{
                width: "50vw",
                fontWeight: "lighter",
                textAlign: "start",
              }}
            >
              <div className="t-center fs-20 t-blue f-bold">
                {t("medicalBillDetails")}
              </div>
              <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                <Divider className="m-0 b-grey-white" />
              </div>

              <Row align="top" style={{ paddingBottom: "40px" }}>
                <Col span="6">{t("number")}</Col>

                <Col span="18">
                  {`QT${appointmentByID?.data?.result?.[0]?.visit?.orderNo}`}
                  {/* {this.props?.billIDList?.result?.[0]?.billNumber} */}
                </Col>

                <Col span="6"> {t("date")}</Col>

                <Col span="18">{dayjs().format("DD-MM-YYYY")}</Col>
                <Col
                  span="24"
                  style={{ paddingTop: "15px", paddingBottom: "15px" }}
                >
                  <Divider className="m-0 b-grey-white" />
                </Col>
              </Row>
            </td>
          </tr>

          <tr>
            <td colSpan={2} style={{ paddingTop: "19px" }}>
              <Table
                rowKey="key"
                className="components-table-demo-nested table-selection table-confirm  table-list-children report-pay no-cursor"
                rowClassName={(r) => {
                  return " no-header ";
                }}
                columns={columns}
                dataSource={productOrder()}
                pagination={false}
              />
            </td>
          </tr>
          <tr>
            <td></td>
            <td>
              <table className="report-drug " width="100%">
                <tbody>
                  <tr className="t-end">
                    <td style={{ width: "20vw" }}></td>

                    <td style={{ width: "70vw" }}>
                      <Row style={{ paddingTop: "30px" }}>
                        <Col span={12}>{t("totalMoney")}</Col>
                        <Col span={12}>{total()}</Col>
                        <Col span={12}>{t("discounts")}</Col>
                        <Col span={12}>{`${totalAndQuantity?.percent} ฿`}</Col>

                        <Col span={24}>
                          <Row
                            style={{
                              backgroundColor: "#498dcb",
                              color: "white",
                            }}
                          >
                            <Col span={12}>{t("theTotalAmount")}</Col>
                            <Col span={12}>{grandTotal()} </Col>
                          </Row>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
          <tr>
            <td colSpan={2}>
              <span style={{ backgroundColor: "#498dcb", color: "white" }}>
                {`(${THBText(
                  Number(
                    totalAndQuantity?.discountTotal ||
                      totalAndQuantity?.total ||
                      0
                  )
                )})
              `}
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default PrintReport;
