import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { Card, Col, Row, Tabs } from "antd";
import { ITabsState, tabsState } from "@tools/recoil/recoil-atom";
import PreRegistrationToday from "./today/Today";
import PreRegistrationNotYet from "./not_yet/NotYet";
import PreRegistrationHistory from "./history/History";

const PreRegistration = () => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();
  const [tab, setTab] = useRecoilState<ITabsState>(tabsState);

  const tabsCallback = (key: string) => {
    setTab({ ...tab, preRegister: key });
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("preRegister.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("marketing.title")}</b>
          </span>
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card ">{t("event.all")}</b>}
        >
          <Tabs
            className="tab-printdraw f-normal"
            defaultActiveKey={tab.preRegister}
            onChange={tabsCallback}
          >
            <TabPane tab={t("today")} key="1">
              <div className="mt-1">
                <PreRegistrationToday />
              </div>
            </TabPane>
            <TabPane tab={t("notYet")} key="2">
              <div className="mt-1">
                <PreRegistrationNotYet />
              </div>
            </TabPane>
            <TabPane tab={t("record")} key="3">
              <div className="mt-1">
                <PreRegistrationHistory />
              </div>
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </div>
  );
};

export default PreRegistration;
