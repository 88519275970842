import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Popover, Row } from "antd";
import { productName, TextBRHTML } from "@tools/util";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { IItem, IRejectOrderGroups } from "@interface/IOrder.interface";
import MoTable from "@components/table/Table";
import BigNumber from "bignumber.js";

type Props = {
  labInterface?: IRejectOrderGroups[];
};

const Order = ({ labInterface }: Props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(true);
  const num = useRef(0);

  const viewDetail = () => {
    setOpen(!open);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: string, data: IItem, index: number) => {
        return <div>{data?.level === 1 && index + 1}</div>;
      },
    },
    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (name: string, data: IItem) => {
        return (
          <div>
            {data?.level === 1
              ? name
              : data?.level === 2 || data?.level === 4
              ? t("nameList")
              : productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "orderType",
      key: "orderType",
      align: "center",
      render: (_: string, data: IItem) => {
        return (
          <>
            {data?.level === 1
              ? ""
              : data?.level === 2 || data?.level === 4
              ? t("type")
              : data?.level === 3 || data?.level === 5
              ? productName(
                  i18n.language,
                  data?.product?.group?.nameTh,
                  data?.product?.group?.nameEn
                )
              : ""}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      align: "center",
      render: (item: string, dataList: IItem) => {
        return (
          <>
            {dataList?.level === 1 ? (
              ""
            ) : dataList?.level === 2 || dataList?.level === 4 ? (
              t("details")
            ) : (
              <Popover
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html: TextBRHTML(
                        item || dataList?.product?.descriptionTh || "-"
                      ),
                    }}
                  />
                }
                key={dataList?.id}
              >
                <div className="text-content">
                  {item || dataList?.product?.descriptionTh || "-"}
                </div>
              </Popover>
            )}
          </>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "product",
      key: "product",
      render: (_: string, data: IItem) => {
        return (
          <>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length || "-"
              : data?.level === 2 || data?.level === 4
              ? t("amount")
              : `${data?.qty || ""} `}
          </>
        );
      },
    },

    {
      title: t("totalPrice"),
      dataIndex: "net",
      key: "net",
      render: (item: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? new BigNumber(item).toFormat(2)
              : data?.level === 2
              ? t("price")
              : data?.level === 3
              ? new BigNumber(item).toFormat(2)
              : null}
          </div>
        );
      },
    },
  ];

  return (
    <div className="mt-2">
      <Row className="header-table  ">
        <Col className="f-normal t-dark-blue">
          <span className="f-bold mr-05 ">{`${t("orderList")}`} </span>

          <span className="ml-05">
            {React.createElement(open ? UpOutlined : DownOutlined, {
              onClick: viewDetail,
            })}
          </span>
        </Col>
      </Row>

      <div className={open ? "open" : "close"}>
        <MoTable
          className="components-table-demo-nested table-selection table-confirm  "
          rowClassName={(r) => {
            if (r?.level === 1) {
              return "no-header";
            } else if (r?.level === 2) {
              return "table-header no-header ";
            } else if (r?.level === 5) {
              num.current = num?.current - 1;
              if (num?.current === 0) {
                return "table-confirm-first table-confirm-level-course table-bottom--level-course-num no-cursor no-header";
              } else {
                return "table-confirm-first table-confirm-level-course no-cursor no-header";
              }
            } else if (r?.level === 4) {
              num.current = r?.children?.length;
              return "table-header no-header table-confirm-level-course table-top-level-course ";
            }
            return "no-header";
          }}
          rowKey="key"
          expandRowByClick
          dataSource={labInterface}
          columns={columns}
          scroll={{ x: "calc(100vw -20%)" }}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default Order;
