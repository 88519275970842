import { Col, Row } from "antd";
import NurseRoom from "./NurseRoom";
import MedicineRoom from "./MedicineRoom";
import PainManagement from "./PainManagement ";
import DashboardProduct from "./DashboardProduct";
import DashboardPurchase from "./DashboardPurchase";
import DashboardTreatment from "./DashboardTreatment";
import DashboardPromotion from "./DashboardPromotion";
import DashboardCourse from "./DashboardCourse";

const TableList = () => {
  return (
    <Row gutter={[14, 25]}>
      <Col md={24} lg={12}>
        <NurseRoom />
      </Col>
      {/* <Col md={24} lg={12}>
        <MedicalEquipment />
      </Col> */}

      <Col md={24} lg={12}>
        <MedicineRoom />
      </Col>
      <Col md={24} lg={12}>
        <PainManagement />
      </Col>

      <Col md={24} lg={12}>
        <DashboardTreatment />
      </Col>
      <Col md={24} lg={12}>
        <DashboardPurchase />
      </Col>
      <Col md={24} lg={12}>
        <DashboardProduct />
      </Col>
      <Col md={24} lg={12}>
        <DashboardPromotion />
      </Col>
      <Col md={24} lg={12}>
        <DashboardCourse />
      </Col>
    </Row>
  );
};

export default TableList;
