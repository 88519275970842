import { IBilling } from "@interface/IBill.interface";
import { thrownResponse } from "./auth";
import { UseMutationResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetBill = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IBilling[]>> => {
  return useQuery(["get-bill", id, params], async () => {
    const res = await axios.get(`/bill/patient/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else {
      return res?.data;
    }
  });
};

export const useGetBillByBill = (
  id: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IBilling>> => {
  return useQuery(["get-bill-byID", id, params], async () => {
    const res = await axios.get(`/bill/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else {
      return res?.data;
    }
  });
};

export const useGetBillByID = (
  id: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IBilling>> => {
  return useQuery(["get-bill-byID", id, params], async () => {
    const res = await axios.get(`/bill/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else {
      return res?.data;
    }
  });
};

export const useUpdateBill = (): UseMutationResult<any, Error> => {
  return useMutation(["update-bill"], async ({ id, data }: any) => {
    const res = await axios.put(`/bill/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useHandleCreateBillAddProducts = (): UseMutationResult<
  any,
  Error
> => {
  return useMutation(async ({ data }: any) => {
    const res = await axios.post(`/bill/add-product`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useHandleDeleteProductBill = (): UseMutationResult<any, Error> => {
  return useMutation(async (data: any) => {
    const res = await axios.delete(`/bill/delete-product`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateBillDiscount = (): UseMutationResult<any, Error> => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.put(`/bill/update-discount`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateBill = (): UseMutationResult<any, Error> => {
  return useMutation(["create-bill"], async (id: any) => {
    const res = await axios.post(`/bill/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
