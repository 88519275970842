import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
  receiptForm: FormInstance;
  medicalRecord: boolean;
};

const Header = ({ history, receiptForm, medicalRecord }: Props) => {
  const { t } = useTranslation();
  const update = useIsMutating(["update-receipt"]);

  return (
    <>
      <Row className="second-header" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("pay.title")}</h1>
              </div>
              <span className="t-light-gray">
                {t("nursery")} / {t("information")} /{" "}
              </span>
              <span className="t-dark-blue f-normal">{`${t("edit")}${t(
                "receipt"
              )}`}</span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button
            className="cancel mr-05 "
            htmlType="submit"
            onClick={history.goBack}
          >
            {`${t("close")}${t("window")}`}
          </Button>

          {medicalRecord ? (
            <Button
              className="btn-create"
              htmlType="submit"
              onClick={() => receiptForm.submit()}
              loading={!!update}
            >
              {`${t("update")}${t("document")}`}
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Header;
