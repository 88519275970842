import { useState } from "react";
import TableBeforeBill from "./Table";
import SearchBeforeBill from "./Search";

const BeforeBill = () => {
  const [searchValue, setSearchValue] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <div>
      <SearchBeforeBill
        setSearchValue={setSearchValue}
        setCurrentPage={setCurrentPage}
      />
      <TableBeforeBill
        searchValue={searchValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default BeforeBill;
