import type { IVisitDrug } from "@interface/IVisits.interface";
import type {
  IReportDrug,
  IReportDrugView,
} from "@interface/IWorkOrder.interface";
import type { CheckboxValueType } from "antd/lib/checkbox/Group";
import type { CheckboxChangeEvent } from "antd/lib/checkbox";
import type { Dispatch, SetStateAction } from "react";

import React, { useMemo, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useTranslation } from "react-i18next";
import {
  Checkbox,
  Modal,
  Row,
  Col,
  Button,
  Divider,
  Collapse,
  Spin,
  Card,
} from "antd";
import PrintMore, { IItem } from "./PrintMore";
import View from "./View";

interface Props {
  value: IVisitDrug;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
}

const ModalLabel = ({ visible, setVisible, value }: Props) => {
  const printReportRef = useRef(null);

  const { t, i18n } = useTranslation();
  const [selectedProductID, setSelectProductID] = useState<
    (string | number | boolean)[]
  >([]);
  const [selectedLoading, setSelectedLoading] = useState<boolean>(false);
  const [selectItem, setSelectItem] = useState<IItem[]>([]);
  const [allSelect, setAllSelect] = useState<boolean>(false);
  const pageStyle = `@page {size: 6.5cm 4.5cm; margin: 7px 0px 0px 0px; }`;
  const result = useMemo(() => {
    return value?.workOrderItem
      .filter((item) => item.drugStatus === "CONTINUOUS")
      .map((item: IReportDrug) => {
        let option = JSON.parse(item?.option || `{}`);
        return {
          ...item,
          option: option,
        };
      });
  }, [value]);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });
  const onOk = () => {
    handlePrint();
  };
  const onCancel = () => {
    setVisible(false);
  };
  const onChange = (checkedValue: CheckboxValueType[]) => {
    setSelectedLoading(true);
    const workOrderItem =
      value?.workOrderItem.filter((item) => item.drugStatus === "CONTINUOUS") ||
      [];
    if (checkedValue.length === workOrderItem.length) {
      setAllSelect(true);
    }
    !!allSelect && setAllSelect(false);
    const dataMapItem = value.workOrderItem
      .filter((item) => !!checkedValue.includes(item.id))
      .map((item: IReportDrug) => {
        let option = JSON.parse(item?.option || `{}`);
        return {
          ...item,
          option: option,
        };
      });

    setSelectItem(dataMapItem);
    setSelectProductID(checkedValue);
    setSelectedLoading(false);
  };
  const onChangeAllSelect = (e: CheckboxChangeEvent) => {
    setSelectedLoading(true);
    if (!!e.target.checked) {
      const dataMapItem = value.workOrderItem
        .filter((item) => item.drugStatus === "CONTINUOUS")
        .map((item: IReportDrug) => {
          let option = JSON.parse(item?.option || `{}`);
          return {
            ...item,
            option: option,
          };
        });
      const checkedID =
        dataMapItem.map((item: IReportDrug) => {
          return item.id;
        }) || [];
      setAllSelect(true);
      setSelectItem(dataMapItem);
      setSelectProductID(checkedID);
    } else {
      setAllSelect(false);
      setSelectItem([]);
      setSelectProductID([]);
    }
    setSelectedLoading(false);
  };
  const onChangeCollapse = () => {};
  return (
    <React.Fragment>
      <Modal
        className="doctorroom-modal"
        title={
          <React.Fragment>
            <div className="t-blue">{`${t(
              "ListOfDrugsAndDietarySupplements"
            )} (${value?.workOrderItem.length} ${t("list")})`}</div>
          </React.Fragment>
        }
        centered
        visible={visible}
        onCancel={onCancel}
        width={500}
        forceRender={true}
        footer={
          <Row justify="end">
            <Col>
              <Button className="mr-05 cancel" onClick={onCancel}>
                {t("cancel")}
              </Button>
              <Button className="mr-05 btn-search" onClick={onOk}>
                {t("printLabel")}
              </Button>
            </Col>
          </Row>
        }
      >
        <Spin tip="Loading..." spinning={selectedLoading}>
          <Row justify="center">
            <Col span={22} style={{ marginTop: 2 }}>
              <Checkbox
                checked={allSelect}
                onChange={(e) => {
                  onChangeAllSelect(e);
                }}
              >
                {t("allSelect")}
              </Checkbox>
              <Divider
                dashed
                className="m-0"
                style={{ marginTop: 2, marginBottom: 2 }}
              />
            </Col>
          </Row>
          <Checkbox.Group
            style={{ width: "100%" }}
            onChange={onChange}
            value={selectedProductID}
          >
            <div style={{ minHeight: 300, maxHeight: 500, overflowY: "auto" }}>
              {result?.map((item: IReportDrugView) => (
                <Row justify="center" key={item.id}>
                  <Col span={22}>
                    <Collapse
                      ghost
                      onChange={onChangeCollapse}
                      expandIconPosition="right"
                    >
                      <Collapse.Panel
                        key={`${item.id}`}
                        header={
                          <Checkbox key={item.id} value={item.id}>{`${
                            i18n?.language === "th"
                              ? item?.product?.nameTh !== "-"
                                ? item?.product?.nameTh
                                : item?.product?.nameEn
                              : item?.product?.nameEn !== "-"
                              ? item?.product?.nameEn
                              : item?.product?.nameTh
                          }`}</Checkbox>
                        }
                      >
                        <div style={{ padding: 4 }}>
                          <Card
                            className="drug-label-card t-black"
                            style={{
                              boxShadow: "12px 13px 9px -13px rgba(0,0,0,0.1)",
                            }}
                          >
                            <View
                              visitsByID={{
                                patient: {
                                  hn: value?.patient?.hn || "",
                                  titleTh: value?.patient?.titleTh || "",
                                  titleEn: value?.patient?.titleEn || "",
                                  lastNameTh: value?.patient?.lastNameTh || "",
                                  lastNameEn: value?.patient?.lastNameEn || "",
                                  firstNameTh:
                                    value?.patient?.firstNameTh || "",
                                  firstNameEn:
                                    value?.patient?.firstNameEn || "",
                                },
                                nameShown: item?.option?.nameShown || "",
                                frequencyTh: item?.option?.frequencyTh || "",
                                frequencyEn: item?.option?.frequencyEn || "",
                                genericName: item?.option?.genericName || "",
                                eatingPattern:
                                  item?.option?.eatingPattern || undefined,
                                quantityUsed: item?.option?.quantityUsed || 0,
                                unitAltTh: item?.product?.unitAltTh,
                                unitAltEn: item?.product?.unitAltEn,
                                timeSpent: item?.option?.timeSpent || undefined,
                                // specialInstructions: item?.option?.nameShown || "",
                                descriptionTh:
                                  item?.option?.descriptionTh || "",
                                descriptionEn:
                                  item?.option?.descriptionEn || "",
                              }}
                            />
                          </Card>
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                    <Divider dashed className="m-0" />
                  </Col>
                </Row>
              ))}
            </div>
          </Checkbox.Group>
        </Spin>
      </Modal>
      {!!value?.patient && !!selectItem?.length && (
        <div style={{ display: "none" }}>
          <ReactToPrint content={() => printReportRef?.current} />
          <PrintMore
            refData={printReportRef}
            visitsByID={value.patient}
            data={selectItem}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default ModalLabel;
