import { RcFile } from "antd/lib/upload";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { useCreateVisitOldFile } from "@services/api/visit_old_file";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Card, Col, Form, Input, Row } from "antd";
import Header from "./Header";
import FileSpace from "@components/file_space/FileSpace";

const UploadFile = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const type = match.params.fileId;
  const patientID = match.params.patientID;
  const [visitOldFile] = Form.useForm();
  const create = useCreateVisitOldFile();
  const history = useHistory<RouteComponentProps["history"]>();

  useEffect(() => {
    visitOldFile.setFieldsValue({ type: type });
    // eslint-disable-next-line
  }, []);

  const getFileName = () => {
    return false;
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = (data: {
    type: string;
    file: [{ originFileObj: RcFile }];
  }) => {
    let formData = new FormData();
    formData?.append("type", data?.type);
    formData?.append("patientId", patientID);
    data?.file?.forEach((item: { originFileObj: RcFile }) => {
      formData?.append("file", item?.originFileObj);
    });

    create.mutate(
      { data: formData },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
        onSettled: () => {
          history.goBack();
        },
      }
    );
  };
  return (
    <div>
      <Header form={visitOldFile} isLoading={create?.isLoading} />
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={<b className="fs-16 t-black-light ">{t("uploadDocuments")}</b>}
        >
          <Card
            className="card-prefix card-prefix-width card-prefix-chi"
            extra={<b className="fs-14 c-white ">{t("uploadDocuments")}</b>}
          >
            <Form onFinish={onFinish} name="visitOldFile" form={visitOldFile}>
              <Row gutter={[0, 12]}>
                <Col lg={24}>
                  <Form.Item
                    label={t("type")}
                    rules={[{ required: true }]}
                    name="type"
                  >
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col lg={24}>
                  <Form.Item
                    label={t("attachFile")}
                    rules={[{ required: true, message: t("selectItem") }]}
                    name="file"
                    valuePropName="fileList"
                    getValueFromEvent={getFile}
                  >
                    <FileSpace
                      multiple={true}
                      beforeUpload={getFileName}
                      keyName="file"
                      form={visitOldFile}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default UploadFile;
