import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MEDICAL_DOCUMENT } from "@assets/data/pathRouter";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { useCreateDocumentType } from "@services/api/documents_type";
import { useGetDocumentTypeByID } from "@services/api/documents_type";
import { useCreateDocumentTypeByID } from "@services/api/documents_type";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Card, Col, Form, Input, Row } from "antd";
import Header from "./Header";

const FormTypeMedicalDocument = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const byId = match.params.id;
  const [confirmTypeMedicalDocument] = Form.useForm();
  const createDocumentType = useCreateDocumentType();
  const getDocumentTypeByID = useGetDocumentTypeByID(byId);
  const updateDocumentTypeByID = useCreateDocumentTypeByID();

  useEffect(() => {
    confirmTypeMedicalDocument.setFieldsValue({
      ...getDocumentTypeByID?.data?.result?.[0],
    });
    //eslint-disable-next-line
  }, [getDocumentTypeByID?.data]);

  const onFinish = (value: any) => {
    if (byId) {
      update(value);
    } else {
      create(value);
    }
  };

  const update = (value: any) => {
    updateDocumentTypeByID.mutate(
      { id: byId, data: value },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(`${MEDICAL_DOCUMENT.type}`);
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };

  const create = (value: any) => {
    createDocumentType.mutate(value, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.replace(`${MEDICAL_DOCUMENT.type}`);
      },
      onError: (res) => {
        openNotification({ method: "POST_ERROR", message: String(res) });
      },
    });
  };
  return (
    <div>
      <Header confirmTypeMedicalDocument={confirmTypeMedicalDocument} />
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={
            <b className="fs-16 t-black-light ">{t("medicalDocument.type")}</b>
          }
        >
          <Card
            className="card-prefix card-prefix-width card-prefix-chi"
            extra={
              <b className="fs-14 c-white ">{t("medicalDocument.type")}</b>
            }
          >
            <Form
              onFinish={onFinish}
              name="confirmTypeMedicalDocument"
              form={confirmTypeMedicalDocument}
            >
              <Row gutter={[0, 12]}>
                <Col lg={24}>
                  <Form.Item
                    label={`${t("type")} (TH)`}
                    rules={[{ required: true }]}
                    name="nameTh"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col lg={24}>
                  <Form.Item
                    label={`${t("type")} (EN)`}
                    rules={[{ required: true }]}
                    name="nameEn"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default FormTypeMedicalDocument;
