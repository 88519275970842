import { useEffect, useState } from "react";
import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { PRE_REGISTRATION } from "@assets/data/pathRouter";
import { RouteComponentProps } from "react-router-dom";
import { useGetPatientsListByEvent } from "@services/api/patient";
import { ENUM_ORDERBY, EVENT_PATIENT_STATUS } from "@tools/util.enum";
import MoTable from "@components/table/Table";

type Props = {
  searchValue: any;
  currentPage: ICurrentPage;
  eventID: number;
  history: RouteComponentProps["history"];
};

const Table = ({ searchValue, currentPage, eventID, history }: Props) => {
  const { t, i18n } = useTranslation();
  const statusEvent: {
    [K: string]: {
      color: string;
      name: string;
    };
  } = {
    [`${EVENT_PATIENT_STATUS?.PENDING}`]: {
      color: "#FF5D5D",
      name: t("pending"),
    },
    [`${EVENT_PATIENT_STATUS?.IN_PROGRESS}`]: {
      color: "#77C48B",
      name: t("execute"),
    },
    [`${EVENT_PATIENT_STATUS?.CANCEL}`]: {
      color: "#FF5D5D",
      name: t("cancel"),
    },
  };
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const patientListByEvent = useGetPatientsListByEvent(eventID, {
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    ...searchValue,
  });

  useEffect(() => {
    setTotal(patientListByEvent?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [patientListByEvent?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    if (event?.status === EVENT_PATIENT_STATUS?.PENDING) {
      history.push(
        `${PRE_REGISTRATION.event}/${eventID}/edit-by/patient/${event?.patient?.id}`
      );
    } else if (
      event?.status === EVENT_PATIENT_STATUS?.IN_PROGRESS ||
      event?.status === EVENT_PATIENT_STATUS?.CANCEL
    ) {
      history.push(
        `${PRE_REGISTRATION.event}/${eventID}/view/history/patient/${event?.patient?.id}`
      );
    }
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage?.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "patient",
      render: (patient: any) => {
        return <div className="f-normal ">{patient?.hn}</div>;
      },
    },

    {
      title: `${t("name")} - ${t("lastName")}`,
      dataIndex: "patient",
      key: "patient",
      render: (patient: any) => {
        return (
          <div className="f-normal ">
            {i18n.language === "th"
              ? [patient?.firstNameTh, patient?.lastNameTh]?.join(" ")?.trim()
              : [patient?.firstNameEn, patient?.lastNameEn]?.join(" ")?.trim()}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "patient",
      key: "patient",
      render: (patient: any) => {
        let nameGender = gender(patient?.gender, i18n?.language);
        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "patient",
      key: "patient",
      width: "20%",
      sorter: (a: any, b: any) => a.age - b.age,
      render: (patient: IGetPatientList) => {
        return <>{patient?.dateOfBirth ? Age(patient?.dateOfBirth) : "-"}</>;
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "patient",
      sorter: (a: any, b: any) => a.mobile - b.mobile,
      key: "patient",
      render: (patient: IGetPatientList) => {
        return <>{patient?.mobile ? patient?.mobile : "-"}</>;
      },
    },

    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        return (
          <div
            className="table-queue-status mr-1"
            style={{
              backgroundColor: statusEvent?.[status]?.color,
            }}
          >
            {statusEvent?.[status]?.name}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={patientListByEvent?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
