import { Affix, Button } from "antd";
import { FileDoneOutlined } from "@ant-design/icons";

type Props = {
  onClick: () => void;
  offsetBottom?: number;
};

const Affixes = ({ onClick, offsetBottom }: Props) => {
  return (
    <Affix offsetBottom={offsetBottom || 10} className="button-affix">
      <Button onClick={onClick} className="open-card" type="primary">
        <FileDoneOutlined
          style={{
            fontSize: "30px",
            display: "flex",
            justifyContent: "center",
          }}
        />
      </Button>
    </Affix>
  );
};

export default Affixes;
