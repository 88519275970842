import { IInvoice } from "@interface/IInvoice.interface";
import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetInvoice = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IInvoice[]>> => {
  return useQuery(["get-invoice", params], async () => {
    const res = await axios.get(`/invoice/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else {
      return res?.data;
    }
  });
};

export const useGetInvoiceByPatient = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IInvoice[]>> => {
  return useQuery(["get-invoice", params], async () => {
    const res = await axios.get(`/invoice/patient/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else {
      return res?.data;
    }
  });
};

export const useGetInvoiceByID = (
  id: number
): UseQueryResult<ElementaryOneMore<IInvoice>> => {
  return useQuery(
    ["get-invoice-ByID", id],
    async () => {
      const res = await axios.get(`/invoice/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else {
        return res?.data;
      }
    },
    { enabled: !id ? false : true }
  );
};

export const useGetInvoiceNumber = (
  enabled: boolean
): UseQueryResult<ElementaryOneMore<IInvoice>> => {
  return useQuery(
    ["get-invoice-number"],
    async () => {
      const res = await axios.get(`/invoice/invoice-number`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else {
        return res?.data;
      }
    },
    { enabled: enabled }
  );
};

export const useCreateInvoice = () => {
  return useMutation(["create-invoice"], async ({ data }: any) => {
    const res = await axios.post(`/invoice`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateInvoice = (): UseMutationResult<any, Error> => {
  return useMutation(["update-invoice"], async ({ id, data }: any) => {
    const res = await axios.put(`/invoice/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
