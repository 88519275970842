import { useState } from "react";
import Header from "./Header";
import TableWareHouse from "./Table";

const ProductWareHouse = () => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState();

  const onFinish = (values: any) => {
    setSearch(values);
    setCurrentPage(1);
  };

  return (
    <div>
      <Header onFinish={onFinish} />
      <TableWareHouse
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
        search={search}
      />
    </div>
  );
};

export default ProductWareHouse;
