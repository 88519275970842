const Decimal = (item: number | string) => {
  const options = {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  };

  return Number(item).toLocaleString("en", options);
};

export default Decimal;
