import React, { useState } from "react";
import { Table } from "antd";
import { IOrder } from "@interface/IOrder.interface";
import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import Decimal from "@tools/decimal";

const TableLabDetail = () => {
  const [selected, setSelected] = useState([]);
  const { t } = useTranslation();

  const onSelectChange = (id: any, value: any) => {
    setSelected(id);
  };

  const rowSelection = {
    selectedRowKeys: selected,
    onChange: onSelectChange,
  };

  const data: any[] = [
    {
      key: "children10",
      level: 1,
      name: "Lab Test",
      total: "16000",
      children: [
        {
          key: "children20",
          level: 2,
          children: [
            {
              key: "children36870e822-f5ca-4289-b1d4-41e2103bc721",
              unitPrice: "8000",
              discount: "0",
              total: "8000",
              isCourse: false,
              id: 119,
              uuid: "6870e822-f5ca-4289-b1d4-41e2103bc721",
              workOrderId: 68,
              orderId: 78,
              qty: 1,
              option: null,
              discountPercent: null,
              createdAt: "2022-04-11T03:04:56.240Z",
              updatedAt: "2022-04-11T03:05:38.765Z",
              status: 0,
              confirmed: null,
              use: true,
              success: true,
              reject: false,
              signature: null,
              orderType: "0",
              remark: null,
              drugStatus: null,
              nurseId: null,
              rejectDrug: false,
              rejectConfirm: false,
              link: "http://localhost:3000/lab-file/NK-retrieval-form-1649646320425.pdf",
              product: {
                isCourse: false,
                id: 11,
                categoryId: "C-0003",
                nameTh: "RETRIEVAL",
                nameEn: "RETRIEVAL",
                package: "1x1",
                descriptionTh: "lab",
                descriptionEn: null,
                usageTh: null,
                usageEn: null,
                qty: 1,
                normal: "8000",
                fileType: "RETRIEVAL",
                group: {
                  id: 9,
                  nameTh: "Lab",
                  nameEn: "Lab",
                  serviceGroup: 4,
                  group: null,
                },
              },
              courseItems: [],
              name: "Lab Test",
            },
            {
              key: "children3f8c6f7d8-542a-48db-b2bd-6e1ed905b1a8",
              unitPrice: "8000",
              discount: "1",
              total: "8000",
              isCourse: false,
              id: 120,
              uuid: "f8c6f7d8-542a-48db-b2bd-6e1ed905b1a8",
              workOrderId: 68,
              orderId: 78,
              qty: 1,
              option: null,
              discountPercent: "%",
              createdAt: "2022-04-11T03:04:56.276Z",
              updatedAt: "2022-04-11T03:05:38.760Z",
              status: 0,
              confirmed: null,
              use: true,
              success: true,
              reject: false,
              signature: null,
              orderType: "0",
              remark: null,
              drugStatus: null,
              nurseId: null,
              rejectDrug: false,
              rejectConfirm: false,
              link: "http://localhost:3000/lab-file/Questionnaire_Thai-Eng-1649646320616.pdf",
              product: {
                isCourse: false,
                id: 12,
                categoryId: "C-0003",
                nameTh: "QUESTIONNIARE",
                nameEn: "QUESTIONNIARE",
                package: "1x1",
                descriptionTh: "lab",
                descriptionEn: null,
                usageTh: null,
                usageEn: null,
                qty: 1,
                normal: "8000",
                fileType: "QUESTIONNAIRE",
                group: {
                  id: 9,
                  nameTh: "Lab",
                  nameEn: "Lab",
                  serviceGroup: 4,
                  group: null,
                },
              },
              courseItems: [],
              name: "Lab Test",
            },
          ],
        },
      ],
    },
  ];

  const columns: ColumnsType<IOrder> = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, data: any, index: number) => {
        return <div key={data?.key}>{data?.level === 1 && index + 1}</div>;
      },
    },

    {
      title: `${t("name")}${t("list")}`,
      dataIndex: "name",
      key: "name",
      render: (_: any, data: any) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? data?.name
              : data?.level === 2
              ? t("nameList")
              : data?.level === 3
              ? data?.product?.nameTh
              : data?.level === 4
              ? t("nameList")
              : data?.level === 5
              ? data?.product?.nameTh
              : data?.product?.nameTh}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: any, data: any) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("price")
              : data?.level === 3
              ? Decimal(data?.product?.normal)
              : data?.level === 4
              ? t("price")
              : data?.level === 5
              ? Decimal(data?.product?.normal)
              : Decimal(data?.product?.normal)}
          </div>
        );
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",
      key: "qty",
      render: (qty: any, data: any) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length
              : data?.level === 2
              ? t("amount")
              : data?.level === 3
              ? qty
              : data?.level === 4
              ? t("amount")
              : data?.level === 5
              ? qty
              : qty}
          </div>
        );
      },
    },

    {
      title: t("discounts"),
      dataIndex: "discount",
      key: "discount",
      render: (discount: any, data: any) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("discounts")
              : data?.level === 3
              ? `${discount}  ${data?.discountPercent || ""}`
              : data?.level === 4
              ? t("discounts")
              : data?.level === 5
              ? `${discount}  ${data?.discountPercent || ""}`
              : `${discount}  ${data?.discountPercent || ""}`}
          </div>
        );
      },
    },

    {
      title: t("total"),
      dataIndex: "total",
      key: "total",
      render: (total: any, data: any) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? Decimal(total)
              : data?.level === 2
              ? t("total")
              : data?.level === 3
              ? Decimal(total)
              : data?.level === 4
              ? t("total")
              : data?.level === 5
              ? Decimal(total)
              : Decimal(total)}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        className="components-table-demo-nested table-selection table-confirm "
        columns={columns}
        expandRowByClick
        rowSelection={{ ...rowSelection, checkStrictly: false }}
        dataSource={data}
        pagination={false}
        rowClassName={(r) => {
          if (r?.level === 2) {
            return "table-header";
          } else if (r?.uuid && !r?.children) {
            return "no-header";
          }

          return "";
        }}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default TableLabDetail;
