import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import React from "react";
import Delete from "@assets/img/icon/OPD/delete.svg";
import BackHome from "@components/button/BackHome";
import RoleContext from "@provider/RoleProvider";

type Props = {
  history: RouteComponentProps["history"];
  agentForm: FormInstance;
  onModal: () => void;
};

const Header = ({ history, agentForm, onModal }: Props) => {
  const { t } = useTranslation();
  const roleList = React.useContext(RoleContext);
  const loadingUpdate = useIsMutating(["update-agent-by-id"]);
  const loadingDelete = useIsMutating(["delete-agent-by-id"]);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "GENERAL";
  });
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("setting")}</h1>
              </div>
              <span className="t-light-gray">{t("generalInfo.title")} / </span>
              <span className="t-dark-blue f-normal">{t("edit")}</span>
            </Col>
          </Row>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="delete mr-05 "
              loading={!!loadingDelete}
              onClick={(e) => {
                onModal();
                e.stopPropagation();
              }}
            >
              <img className="circle editor-button" src={Delete} alt="delete" />
            </Button>
            <Button className="mr-05 cancel" onClick={history.goBack}>
              {t("cancel")}
            </Button>
            <Button
              className="btn-create"
              onClick={() => agentForm.submit()}
              loading={!!loadingUpdate}
            >
              {t("save")}
            </Button>
          </Col>
        ) : null}
      </Row>
    </div>
  );
};

export default Header;
