import { IPromotionContext } from "./interface";
import { createContext, useContext } from "react";

const MainPromotionContext = createContext<IPromotionContext>(
  {} as IPromotionContext
);

const MainPromotionProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: any;
}) => {
  return (
    <MainPromotionContext.Provider value={{ ...value }}>
      {children}
    </MainPromotionContext.Provider>
  );
};

export const useMainPromotion = () => useContext(MainPromotionContext);
export default MainPromotionProvider;
