import { date, gender } from "../../../../assets/data/data";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";
import SelectorDouble from "../../../../component/selector/SelectorDouble";

type Props = {
  setSearchData: (e: any) => void;
  setCurrentPage: (e: number) => void;
};

const Search = ({ setSearchData, setCurrentPage }: Props) => {
  const { t, i18n } = useTranslation();

  const status = [
    { name: t("waitConsult"), id: `WAITING_FOR_ADVICE` },
    { name: t("bookAdvance"), id: `BOOK_IN_ADVANCE` },
  ];

  const onFinish = (value: any) => {
    setSearchData(value);
    setCurrentPage(1);
  };

  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={8} lg={6}>
            <Form.Item name="name">
              <Input
                placeholder={`${t("search")} ${t("name")} , ${t("code")} HN`}
              />
            </Form.Item>
          </Col>

          <Col lg={5}>
            <Form.Item name="gender">
              <SelectorDouble
                Source={gender}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("sex")}
              />
            </Form.Item>
          </Col>
          <Col lg={4}>
            <Form.Item name="status">
              <SelectorDouble
                Source={status}
                keyName="name"
                keyValue="id"
                title={t("status")}
              />
            </Form.Item>
          </Col>

          <Col lg={4}>
            <Form.Item name="sort">
              <SelectorDouble
                Source={date}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("sort")}
                placeholder={t("oldest")}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-05 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
