import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
// import GetNurse from "./get_nurse/GetNurse";
// import SendNurse from "./send_nurse/SendNurse";
import AllCaregivers from "./all_caregivers/AllCaregivers";

const TabsTransfer = () => {
  const { TabPane } = Tabs;
  const { t } = useTranslation();

  return (
    <Tabs defaultActiveKey="1" className="tab-referral f-normal ">
      <TabPane tab={t("allNursery")} key="1" className="mt-2">
        <AllCaregivers />
      </TabPane>
      {/* <TabPane tab={t("sendKeeper")} key="2" className="mt-2">
        <SendNurse />
      </TabPane>
      <TabPane tab={t("receiveKeeper")} key="3" className="mt-2">
        <GetNurse />
      </TabPane> */}
    </Tabs>
  );
};

export default TabsTransfer;
