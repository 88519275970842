import { IGetRoom } from "@interface/IRoom.interface";
import { IUserRoom } from "@interface/IUserRoom.interface";
import { IFormSlots } from "@interface/ISlots.interface";
import { useTranslation } from "react-i18next";
import { ValidateMessages } from "rc-field-form/lib/interface";
import { notificationDetail } from "@assets/data/data";
import { useEffect, useState } from "react";
import { useGetPatientsDropdown } from "@services/api/patient";
import { Col, ConfigProvider, DatePicker, Form } from "antd";
import { FormInstance, Input, Row, Select, TimePicker } from "antd";
import thTH from "antd/es/locale/th_TH";
import enUS from "antd/es/locale/en_US";
import dayjs from "dayjs";
import moment from "moment";
import Period from "@assets/img/icon/OPD/period.svg";
import Selector from "../../selector/Selector";
import useDebounce from "@tools/useDebounce";
import Notification from "@assets/img/icon/OPD/notification.svg";

const { Option } = Select;

type Props = {
  dataSlots?: IFormSlots;
  CommitChanges: (added: any) => void;
  slotForm: FormInstance;
  setVisible: (e: boolean) => void;
  doctorList: IUserRoom[];
  roomList: IGetRoom[] | [];
};

const Fields = ({
  dataSlots,
  CommitChanges,
  slotForm,
  setVisible,
  doctorList,
  roomList,
}: Props) => {
  const format = "HH:mm";
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState<string | undefined>();
  const dSearch = useDebounce(search, 500);
  const [disabledHours, setDisabledHours] = useState(dataSlots?.startDate);
  const validateMessages: ValidateMessages = {
    required: i18n?.language === "th" ? "โปรดกรอกข้อมูล!" : "Please input!",
    types: {
      email:
        i18n?.language === "th"
          ? "โปรดกรอกอีเมลให้ถูกต้อง!"
          : "Please enter a valid email address!",
    },
  };
  const patientsList = useGetPatientsDropdown(true, {
    search: dSearch,
    myBranch: true,
  });

  useEffect(() => {
    setVisible(true);

    slotForm.setFieldsValue({
      ...dataSlots,
      userId: dataSlots?.userId,
      roomId: dataSlots?.roomId,
      date: moment(dataSlots?.startDate),
      startDate: moment(dataSlots?.startDate),
      endDate: moment(dataSlots?.endDate),
      patient:
        i18n.language === "th"
          ? [dataSlots?.patient?.firstNameTh, dataSlots?.patient?.lastNameTh]
              ?.join(" ")
              ?.trim()
          : [dataSlots?.patient?.firstNameEn, dataSlots?.patient?.lastNameEn]
              ?.join(" ")
              ?.trim(),
      minutesToNotify: dataSlots?.minutesToNotify,
    });

    // eslint-disable-next-line
  }, [dataSlots]);

  const entireData = (): { data: any[]; hasMore: boolean; current: number } => {
    const { pages } = patientsList?.data || {};
    const pagesLength = pages?.length || 0;

    const data = pages?.flatMap((item) => {
      return item?.data;
    });

    const pageCount = pages?.[pagesLength - 1]?.pageCount || 0;
    const current = pages?.[pagesLength - 1]?.page || 1;
    return { data: data || [], hasMore: pageCount > current, current: current };
  };

  const { data, current } = entireData();

  const fetchMoreData = () => {
    patientsList?.fetchNextPage({
      pageParam: { page: current + 1, search: !!search ? search : undefined },
    });
  };

  const handleScroll = (event: any) => {
    event.stopPropagation();
    const { target }: any = event;
    if (target?.scrollTop + target?.offsetHeight === target?.scrollHeight) {
      fetchMoreData();
    }
  };
  const onSearch = (value: string) => {
    setSearch(value);
  };

  const onChangeTimePicker = (time: any) => {
    var timeNext = moment(time).add(15, "minutes");
    slotForm.setFieldsValue({
      [`endDate`]: timeNext,
    });
    setDisabledHours(time);
  };

  const getDisabledHours = () => {
    let hours = [];
    for (let i = 0; i < moment(disabledHours).hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledMinutes = () => {
    let selectedHour = Number(moment(disabledHours).format("H"));

    let minutes = [];
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment(disabledHours).minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const onFinish = (dataForm: any) => {
    if (dataSlots?.id != null) {
      const data = {
        ...dataForm,
        id: dataSlots?.id,
        date: dayjs(dataForm.date).format("YYYY-MM-DD"),
      };

      CommitChanges({ changed: data });
    } else {
      const data = {
        ...dataForm,
        date: dayjs(dataForm.date).format("YYYY-MM-DD"),
      };
      CommitChanges({ added: data });
    }
    setVisible(false);
  };

  return (
    <ConfigProvider
      locale={i18n?.language === "th" ? thTH : enUS}
      form={{ validateMessages }}
    >
      <Form
        onFinish={onFinish}
        layout="vertical"
        name="slotForm"
        form={slotForm}
      >
        <Row gutter={[23, 18]} className="margin-form">
          <Col lg={24}>
            <div>{`${t("section")}`}</div>
            <div className="mt-1">
              <Form.Item name="description" rules={[{ required: true }]}>
                <Input placeholder={`${t("section")}`} />
              </Form.Item>
            </div>
          </Col>

          <Col lg={12}>
            <div>{t("room")}</div>
            <div className="mt-1">
              <Form.Item name="roomId" rules={[{ required: true }]}>
                <Selector
                  Source={roomList || []}
                  keyName="name"
                  keyValue="id"
                />
              </Form.Item>
            </div>
          </Col>

          <Col md={12} lg={12}>
            <div>{t("doctorName")}</div>
            <div className="mt-1">
              <Form.Item name="userId">
                <Selector
                  Source={doctorList || []}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="userId"
                />
              </Form.Item>
            </div>
          </Col>

          <Col lg={12}>
            <div>{`${t("nurseName")}`}</div>
            <div className="mt-1">
              <Form.Item
                hidden={!!dataSlots?.patientId}
                noStyle={!!dataSlots?.patientId}
                name="patientId"
                rules={[{ required: true }]}
              >
                <Select
                  onDropdownVisibleChange={(e) => {
                    if (e === false) {
                      setSearch(undefined);
                    }
                  }}
                  showSearch
                  filterOption={() => {
                    return true;
                  }}
                  onSearch={onSearch}
                  loading={patientsList?.isLoading}
                  onPopupScroll={(e) => {
                    handleScroll(e);
                  }}
                >
                  {data?.map((item) => {
                    return (
                      <Option key={item?.id} value={item?.id}>
                        {i18n.language === "th" ? item?.nameTh : item?.nameEn}{" "}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>

              <Form.Item
                hidden={!dataSlots?.patientId}
                noStyle={!dataSlots?.patientId}
                name="patient"
              >
                <Input disabled />
              </Form.Item>
            </div>
          </Col>
          <Col lg={12}>
            <div>{t("date")}</div>
            <div className="mt-1">
              <Form.Item name="date" rules={[{ required: true }]}>
                <DatePicker placeholder="วันที่จอง" format="YYYY-MM-DD" />
              </Form.Item>
            </div>
          </Col>

          <Col lg={12}>
            <div className="">{t("period")}</div>
            <Row align="middle" className="mt-1" justify="space-around">
              <Col>
                <img className="img-icon-modal" src={Period} alt="Period" />
              </Col>
              <Col lg={10}>
                <Form.Item name="startDate" rules={[{ required: true }]}>
                  <TimePicker
                    format={format}
                    onChange={(e) => onChangeTimePicker(e)}
                  />
                </Form.Item>
              </Col>

              <Col>
                <div className=" t-to">to</div>
              </Col>

              <Col lg={10}>
                <Form.Item
                  name="endDate"
                  rules={[
                    { required: true, message: t("pleaseSelect") },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (
                          moment(getFieldValue("startDate")).isAfter(value) ||
                          moment(getFieldValue("startDate")).format("HH:mm") ===
                            moment(value).format("HH:mm")
                        ) {
                          slotForm.setFieldsValue({
                            [`${"endDate"}`]: moment(
                              getFieldValue("startDate")
                            ).add(15, "minutes"),
                          });
                          return Promise.resolve();
                        } else {
                          return Promise.resolve();
                        }
                      },
                    }),
                  ]}
                >
                  <TimePicker
                    format={format}
                    disabledHours={getDisabledHours}
                    disabledMinutes={getDisabledMinutes}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col lg={12}>
            <div>{t("booking.notification")}</div>
            <Row className="mt-1">
              <Col lg={2} className="icon-modal">
                <img
                  className="img-icon-modal"
                  src={Notification}
                  alt="Notification"
                />
              </Col>
              <Col lg={22}>
                <Form.Item name="minutesToNotify">
                  <Selector
                    // placeholder=""
                    Source={notificationDetail}
                    keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                    keyValue="id"
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </ConfigProvider>
  );
};

export default Fields;
