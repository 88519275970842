import { IGetRoom } from "@interface/IRoom.interface";
import { useQueryClient } from "react-query";
import { ISearchSlots } from "@views/booking/medical_room_reservation/interface";
import { useGetAppointments } from "@services/api/appointments";
import { useGetSlotDateList } from "@services/api/slots";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import Fields from "./Fields";

type Props = {
  roomList: IGetRoom[];
  slotType?: number;
  search?: ISearchSlots;
};

const Overview = ({ roomList, slotType = undefined, search }: Props) => {
  const [currentDateChange, setCurrentDateChange] = useState<Date>(new Date());
  const client = useQueryClient();
  const slotDateList = useGetSlotDateList();
  const appointmentsList = useGetAppointments();

  useEffect(() => {
    if (slotType === 0) {
      handleGetSlotsList();
    } else {
      handleGetAppointmentsList();
    }
    // eslint-disable-next-line
  }, [currentDateChange, search]);

  const handleGetSlotsList = async () => {
    const current = dayjs(currentDateChange);
    let startDate = current.startOf("day").format("YYYY-MM-DD");
    let endDate = current.endOf("day").format("YYYY-MM-DD");
    slotDateList.mutate(
      {
        slotType: "OTHER",
        startDate: startDate,
        endDate: endDate,
        limit: 0,
        ...search,
      },
      {
        onSuccess: () => {
          client.invalidateQueries(["get-slot-room"]);
        },
      }
    );
  };

  const handleGetAppointmentsList = async () => {
    const current = dayjs(currentDateChange);
    let startDate = current.startOf("day").format("YYYY-MM-DD");
    let endDate = current.endOf("day").format("YYYY-MM-DD");
    appointmentsList.mutate(
      {
        slotType: "DOCTOR",
        startDate: startDate,
        endDate: endDate,
        limit: 0,
        ...search,
      },
      {
        onSuccess: () => {},
      }
    );
  };

  const changeCurrentDateChange = (currentDate: any) => {
    setCurrentDateChange(currentDate);
  };

  return (
    <div className="height-body scrollbar-list">
      <Fields
        dataScheduler={
          slotDateList?.data?.result?.[0]?.data ||
          appointmentsList?.data?.result?.[0]?.data ||
          []
        }
        roomList={roomList}
        changeCurrentDateChange={changeCurrentDateChange}
      />
    </div>
  );
};

export default Overview;
