import React, { useEffect, useState } from "react";
import { productName } from "@tools/util";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetOrderRejectList } from "@services/api/order";
import { IItem, IOrder, IReject } from "@interface/IOrder.interface";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, Form, Input, Row, Table } from "antd";
import Decimal from "@tools/decimal";

const RejectGroupOrder = () => {
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientsID || match.params.patientID;
  const [search, setSearch] = useState({ search: "" });
  const [openOrder, setOpenOrder] = useState({ confirm: true, cancel: false });
  const reject = useGetOrderRejectList(patientID, {
    limit: 0,
    orderby: ENUM_ORDERBY.DESC,
    ...search,
  });

  useEffect(() => {
    if (!!search?.search) return;
    setOpenOrder({
      ...openOrder,
      cancel: !!reject?.data?.result?.[0]?.data?.length,
    });
    //eslint-disable-next-line
  }, [reject?.data]);

  const viewDetailCancel = () => {
    setOpenOrder({ ...openOrder, cancel: !openOrder?.cancel });
  };

  const onFinish = (value: { search: string }) => {
    setSearch(value);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: string, data: IReject, index: number) => {
        return <div key={index}>{data?.index}</div>;
      },
    },

    {
      title: t("orderNumber"),
      dataIndex: "order",
      key: "order",
      render: (order: IOrder) => {
        return <div>{order?.no || ""}</div>;
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IReject) => {
        return (
          <div>
            {productName(
              i18n.language,
              data?.product?.nameTh,
              data?.product?.nameEn
            )}
          </div>
        );
      },
    },

    {
      title: t("productCategory"),
      dataIndex: "product",
      key: "product",
      render: (product: IItem) => {
        return (
          <div>
            {productName(
              i18n.language,
              product?.category?.nameTh,
              product?.category?.nameEn
            )}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "product",
      key: "product",
      render: (_: string, data: IReject) => {
        return <div>{data?.qty + "   "}</div>;
      },
    },
    {
      title: t("grandTotal"),
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IReject, index: number) => {
        return <div>{Decimal(data?.total)}</div>;
      },
    },
  ];

  return (
    <React.Fragment>
      <div className=" mt-2 f-normal col-start t-dark-blue header-table">
        <span className="f-bold mr-05 cursor" onClick={viewDetailCancel}>
          {t("unconfirmedList")}
        </span>
        <span className="mr-05">
          {React.createElement(openOrder.cancel ? UpOutlined : DownOutlined, {
            onClick: viewDetailCancel,
          })}
        </span>
      </div>

      <div className={openOrder.cancel ? "open" : "close"}>
        <div className="mt-05 mb-05">
          <Form onFinish={onFinish} labelCol={{ span: 4 }}>
            <Row align="middle" justify="end">
              <Col span={12}>
                <Form.Item label={t("search")} name="search">
                  <Input />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item hidden>
              <Button type="primary" htmlType="submit">
                {t("search")}
              </Button>
            </Form.Item>
          </Form>
        </div>
        <Table
          loading={reject?.isLoading}
          className="components-table-demo-nested table-selection table-confirm pagi"
          columns={columns}
          expandRowByClick
          showSorterTooltip
          rowKey={"id"}
          dataSource={
            reject?.data?.result?.[0]?.data?.map((item, index) => {
              return { ...item, index: index + 1 };
            }) || []
          }
          scroll={{ x: "calc(100vw -20%)" }}
        />
      </div>
    </React.Fragment>
  );
};

export default RejectGroupOrder;
