import { Form } from "antd";
import { IReceipt } from "@interface/IReceipt.interface";
import { IGroupsItem } from "@interface/IInvoice.interface";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useUpdateInvoice } from "@services/api/invoice";
import { useCreateReceipt } from "@services/api/receipt";
import { useGetInvoiceByID } from "@services/api/invoice";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RouteComponentProps, useLocation } from "react-router-dom";
import React from "react";
import moment from "moment";
import Fields from "./Fields";
import Header from "./Header";
import RoleContext from "@provider/RoleProvider";

const CreateInvoice = () => {
  const { i18n } = useTranslation();
  const [invoiceForm] = Form.useForm();
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const match: any = useRouteMatch();
  const client = useQueryClient();
  const history = useHistory<RouteComponentProps["history"]>();
  const receipt = useCreateReceipt();
  const location = useLocation();
  const invoiceID = match.params.invoiceID;
  const patientID = match.params.patientID;
  const locationPath = location.pathname;
  const invoiceIDList = useGetInvoiceByID(invoiceID);
  const updateInvoice = useUpdateInvoice();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PAY";
  });

  useEffect(() => {
    if (!invoiceIDList?.data) return;
    const patient = invoiceIDList?.data?.result?.[0]?.bill?.order?.patient;
    let total = 0;
    invoiceIDList?.data?.result?.[0]?.bill?.order?.groups?.forEach((item) => {
      total = total + Number(item?.outstandingBalance || 0);
    });

    const nameTH = [patient?.titleTh, patient?.firstNameTh, patient?.lastNameTh]
      ?.join(" ")
      ?.trim();
    const nameEn = [patient?.titleEn, patient?.firstNameEn, patient?.lastNameEn]
      ?.join(" ")
      ?.trim();
    const mailingAddress = patient?.mailingAddress;
    const mailAddress =
      mailingAddress?.addressTh || mailingAddress?.districtTh
        ? [
            mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            mailingAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "districtTh" : "districtEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "provinceTh" : "provinceEn"
            ],
            mailingAddress?.zipcode,
            mailingAddress?.country,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const _homeAddress = patient?.homeAddress;
    const homeAddress =
      _homeAddress?.addressTh || _homeAddress?.districtTh
        ? [
            _homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            _homeAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            _homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
            _homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
            _homeAddress?.country,
            _homeAddress?.zipcode,
          ]
            ?.join(" ")
            ?.trim()
        : "";

    invoiceForm.setFieldsValue({
      name: i18n.language === "th" ? nameTH : nameEn,
      address: mailAddress || homeAddress,
      zipCode: !!mailAddress
        ? patient?.mailingAddress?.zipcode
        : patient?.homeAddress?.zipcode,
      date: moment(),
      referenceNumber: invoiceIDList?.data?.result?.[0]?.invoiceNumber,
      price: Number(
        invoiceIDList?.data?.result?.[0]?.outstandingBalance || total || 0
      ).toFixed(2),
      discount: invoiceIDList?.data?.result?.[0]?.discount || "0.00",
      discountPercent: invoiceIDList?.data?.result?.[0]?.discountPercent || 0,
      sale:
        i18n?.language === "th"
          ? [
              invoiceIDList?.data?.result?.[0]?.user?.firstNameTh,
              invoiceIDList?.data?.result?.[0]?.user?.lastNameTh,
            ]
              ?.join(" ")
              ?.trim()
          : [
              invoiceIDList?.data?.result?.[0]?.user?.firstNameEn,
              invoiceIDList?.data?.result?.[0]?.user?.lastNameEn,
            ]
              ?.join(" ")
              ?.trim(),
      description: invoiceIDList?.data?.result?.[0]?.description,
      receiptWorkOrderGroups:
        invoiceIDList?.data?.result?.[0]?.bill?.order?.groups || [],
      grandTotal: invoiceIDList?.data?.result?.[0]?.bill?.order?.groups?.reduce(
        (result: number, current: IGroupsItem) => {
          return result + Number(current?.outstandingBalance || 0);
        },
        0
      ),
    });

    setTotalPrice(total);
    // eslint-disable-next-line
  }, [invoiceIDList?.data, i18n.language]);

  const onFinish = (value: any) => {
    if (locationPath.includes("create-receipt")) {
      onConfirmReceipt(value);
    } else {
      onUpdateInvoice(value);
    }
  };

  const onUpdateInvoice = (values: any) => {
    updateInvoice.mutate(
      {
        id: invoiceID,
        data: {
          description: values?.description,
          price: values?.price,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });

          client.invalidateQueries(["get-invoice-ByID"]);
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
      }
    );
  };

  const onConfirmReceipt = (values: any) => {
    const receiptWorkOrderGroups = values?.receiptWorkOrderGroups?.map(
      (item: IGroupsItem) => {
        return {
          orderGroupId: item?.id,
          total:
            item?.price || item?.price === 0
              ? item?.price
              : item?.outstandingBalance,
        };
      }
    );

    receipt.mutate(
      {
        data: {
          receiptOrderGroups: receiptWorkOrderGroups,
          price:
            values?.price !== undefined
              ? values?.price
              : Number(invoiceIDList?.data?.result?.[0]?.netPrice),
          description: values?.description,
          invoiceId: invoiceIDList?.data?.result?.[0]?.id,
        },
      },
      {
        onSuccess: (res: ElementaryOneMore<IReceipt>) => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(
            `${PAY_LIST_TAB.editReceipt}/${res?.result?.[0]?.id}/patient/${patientID}`
          );
          // client.invalidateQueries(["get-invoice-ByID"]);
        },
        onError: (e: any) => {
          openNotification({ method: "POST_ERROR", message: e.message });
        },
      }
    );
  };

  return (
    <>
      <Header
        locationPath={locationPath}
        history={history}
        invoiceForm={invoiceForm}
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
      <div className="set-layout ">
        <Fields
          locationPath={locationPath}
          invoiceIDList={invoiceIDList?.data}
          invoiceForm={invoiceForm}
          onFinish={onFinish}
          totalPrice={{ totalPrice: totalPrice, setTotalPrice: setTotalPrice }}
          history={history}
          invoiceID={invoiceID}
          medicalRecord={medicalRecord?.[0]?.isEdit}
        />
      </div>
    </>
  );
};

export default CreateInvoice;
