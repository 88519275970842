import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useHandleGetVisitReportDrug } from "@services/api/visits";
import Print from "./Print";
import BackHome from "@components/button/BackHome";
import Nodata from "@assets/img/nodata.svg";

const ReportDrugQ = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const drugList = useHandleGetVisitReportDrug(patientID);
  const pageStyle = `  @page { size:297mm 210mm;
    margin: 20px;}`;
  const printReportRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("dispenseMedicine.title")}</h1>
              </div>
              <span className="t-light-gray">
                {t("nursery")} / {t("dispenseMedicine.title")} /
              </span>
              <span className="t-dark-blue f-bold">{t("report")}</span>
            </Col>
          </Row>
        </Col>
        {drugList?.data?.result?.[0] ? (
          <Col lg={10} className="d-flex justify-between justify-col">
            <Button
              className="mr-05 reserve"
              htmlType="reset"
              onClick={() => handlePrint()}
            >
              {t("print")}
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("dispensingReport")}</b>}
        >
          {drugList?.data?.result?.[0] ? (
            <>
              <ReactToPrint content={() => printReportRef.current} />
              <Print
                drugList={drugList?.data?.result?.[0]}
                refData={printReportRef}
              />
            </>
          ) : (
            <>
              <div>
                <img src={Nodata} alt="nodata" className="image-nodata" />
              </div>
              <div className="text-nodata f-bold">NO DATA</div>
            </>
          )}
        </Card>
      </div>
    </div>
  );
};

export default ReportDrugQ;
