import { SETTING } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { generateUniqueId } from "@tools/util";
import { openNotification } from "@components/notification/Notification";
import { MinusCircleFilled } from "@ant-design/icons";
import { useCreateAgentList } from "@services/api/agent";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Button, Card, Col, Divider, Form, Input, Row } from "antd";
import Header from "./Header";

interface IFormField {
  firstNameTh: string;
  lastNameTh: string;
  firstNameEn: string;
  lastNameEn: string;
  descriptionTh: string;
  descriptionEn: string;
  branchId: string;
  key: string;
}

const FormAgent = () => {
  const [contract] = Form.useForm();
  const { t } = useTranslation();
  const [fields, setFields] = useState<IFormField[]>([]);
  const createAgent = useCreateAgentList();
  const history = useHistory<RouteComponentProps["history"]>();
  const uniqueId = generateUniqueId();

  useEffect(() => {
    const initial: IFormField = {
      firstNameTh: `firstNameTh_${uniqueId}`,
      lastNameTh: `lastNameTh_${uniqueId}`,
      firstNameEn: `firstNameEn_${uniqueId}`,
      lastNameEn: `lastNameEn_${uniqueId}`,
      descriptionTh: `descriptionTh_${uniqueId}`,
      descriptionEn: `descriptionEn_${uniqueId}`,
      branchId: `branchId_${uniqueId}`,
      key: `${uniqueId}`,
    };
    setFields([...(fields || []), initial]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (rest: any) => {
    let obj: { name?: string; code?: string; key: string }[] = [];
    for (const [key, value] of Object.entries(rest)) {
      const prefix = key?.split("_")?.[0];
      const keyName = key?.split("_")?.[1];

      const find = obj?.some((e) => e?.key === keyName);
      if (find) {
        obj = obj?.map((item) => {
          if (item?.key === keyName) {
            return { ...item, [prefix]: value };
          }
          return item;
        });
      } else {
        obj = [...obj, { key: keyName, [prefix]: value }];
      }
    }

    handleCreateAgent(obj);
  };

  const handleCreateAgent = async (values: any) => {
    createAgent.mutate(values, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.replace(`${SETTING.title}`);
      },
      onError: () => {
        openNotification({ method: "POST_ERROR" });
      },
    });
  };

  const addField = () => {
    const key = generateUniqueId();
    const next: IFormField[] = [
      ...fields,
      {
        firstNameTh: `firstNameTh_${key}`,
        lastNameTh: `lastNameTh_${key}`,
        firstNameEn: `firstNameEn_${key}`,
        lastNameEn: `lastNameEn_${key}`,
        descriptionTh: `descriptionTh_${key}`,
        descriptionEn: `descriptionEn_${key}`,
        branchId: `branchId_${key}`,
        key: `${key}`,
      },
    ];
    setFields(next);
  };

  const deleteField = (_field: IFormField) => {
    const next = fields?.filter((item) => {
      return item?.key !== _field?.key;
    });

    setFields(next);
  };
  return (
    <div>
      <Header confirm={contract} />
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={<b className="fs-16 t-black-light ">{t("recommender")}</b>}
        >
          <Card
            className="card-prefix card-prefix-width card-prefix-chi"
            extra={<b className="fs-14 c-white ">{t("createAgent")}</b>}
          >
            <Form onFinish={onFinish} name="contract" form={contract}>
              <Row gutter={[0, 12]}>
                <Col lg={24}>
                  {fields?.map((item, index) => {
                    return (
                      <div key={index} className="mb-1">
                        <Row gutter={[12, 12]}>
                          <Col lg={12}>
                            <Form.Item
                              label={`${t("agentName")} (TH)`}
                              name={item?.firstNameTh}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={12}>
                            <Form.Item
                              label={`${t("agentLastName")} (TH)`}
                              name={item?.lastNameTh}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={12}>
                            <Form.Item
                              label={`${t("agentName")} (EN)`}
                              name={item?.firstNameEn}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={12}>
                            <Form.Item
                              label={`${t("agentLastName")} (EN)`}
                              name={item?.lastNameEn}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={24}>
                            <Form.Item
                              label={`${t("details")} (TH)`}
                              name={item?.descriptionTh}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={24}>
                            <Form.Item
                              label={`${t("details")} (EN)`}
                              name={item?.descriptionEn}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          {/* <Col lg={24}>
                            <Form.Item
                              label={`${t("branch")}`}
                              name={item?.branchId}
                              rules={[{ required: true }]}
                            >
                              <Selector
                                placeholder={t("branch")}
                                Source={
                                  branchList?.data?.result?.[0]?.data || []
                                }
                                keyName="name"
                                keyValue="id"
                              />
                            </Form.Item>
                          </Col> */}
                          <Col lg={22}>
                            <Divider />
                          </Col>

                          <Col lg={2}>
                            {fields?.length > 1 ? (
                              <MinusCircleFilled
                                className="minusCircle"
                                onClick={(e) => deleteField(item)}
                                key={index}
                              />
                            ) : (
                              <MinusCircleFilled
                                className="minusCircle-nodelete"
                                key={index}
                              />
                            )}
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <Col lg={22}>
                    <div className="mb-1">
                      <Button
                        className="add-prefix"
                        type="dashed"
                        ghost
                        onClick={() => addField()}
                      >
                        {`+ ${t("add")}`}
                      </Button>
                    </div>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default FormAgent;
