import React, { useState } from "react";
import { IAppointments } from "@interface/IAppointments.interface";
import { useTranslation } from "react-i18next";
import { useGetSlotById } from "@services/api/slots";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import Fields from "./Fields";
import BackHome from "@components/button/BackHome";
import RoleContext from "@provider/RoleProvider";
import SlotsContext from "@provider/SlotsProvider";

const DoctorRoomSlots = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const slotID = match.params.slotID;
  const visitID = match.params.visitID;
  const patientID = match.params.patientID;
  const dateNew = new Date(moment().format());
  const roleList = React.useContext(RoleContext);
  const [appointmentsList, setAppointmentsList] = useState<IAppointments[]>([]);
  const slotById = useGetSlotById(slotID);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "QUE_MEDICAL_RECORD";
  });

  const booking = () => {
    const reserve = appointmentsList?.findIndex((e) => {
      return (
        Number(e?.patientId) === Number(patientID) &&
        Number(e?.visit?.id) === Number(visitID)
      );
    });

    return reserve > -1;
  };

  const onClick = () => {
    setVisible(!visible);
  };

  return (
    <SlotsContext.Provider value={{ ...(slotById?.data?.result?.[0] || {}) }}>
      <div>
        <Row className="second-header " justify="space-between" align="middle">
          <Col>
            <Row gutter={[14, 14]}>
              <BackHome history={history} />

              <Col className="header-text">
                <div>
                  <h1>{t("nurseryAppointments")}</h1>
                </div>
                <span className="t-light-gray">{`${t("queue")} / `}</span>
                <span className="t-dark-blue f-normal">{`${t(
                  "patientQueue"
                )}`}</span>
              </Col>
            </Row>
          </Col>
          {!booking() ? (
            medicalRecord ? (
              <Col>
                {slotById?.data?.result?.[0]?.endTime &&
                dateNew.getTime() <
                  new Date(
                    slotById?.data?.result?.[0]?.date +
                      " " +
                      slotById?.data?.result?.[0]?.endTime
                  ).getTime() ? (
                  <Button
                    className="reserve"
                    htmlType="submit"
                    block
                    onClick={onClick}
                  >
                    {`${t("BookAnAppointment")}`}
                  </Button>
                ) : (
                  <Button className="edit" disabled htmlType="submit" block>
                    {`${t("BookAnAppointment")}`}
                  </Button>
                )}
              </Col>
            ) : null
          ) : (
            <Col>
              <Button className="edit" disabled htmlType="submit">
                {`${t("BookAnAppointment")}`}
              </Button>
            </Col>
          )}
        </Row>
        <Fields
          slotID={slotID}
          visible={visible}
          setVisible={setVisible}
          visitID={visitID}
          patientID={patientID}
          appointmentsList={{
            appointmentsList: appointmentsList,
            setAppointmentsList: setAppointmentsList,
          }}
        />
      </div>
    </SlotsContext.Provider>
  );
};

export default DoctorRoomSlots;
