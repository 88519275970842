import { FC } from "react";
import { SelectProps, Select } from "antd";
import { useGetBranchDropdownList } from "@services/api/branch";
import { useBranchSelector } from "@provider/select_branch";
import { useTranslation } from "react-i18next";
export interface SelectorBranchProps extends SelectProps<any> {
  width?: number;
}

const SelectorBranch: FC<SelectorBranchProps> = ({
  width = 250,
  defaultValue = null,
  ...restProps
}) => {
  const { i18n } = useTranslation();
  const { branchId, onChangeBranch } = useBranchSelector();
  const { data: branchList } = useGetBranchDropdownList();
  return (
    <>
      {/*   
       // @ts-ignore */}
      <Select
        onChange={(v) => {
          onChangeBranch(Number(v));
        }}
        style={{ ...restProps.style, width }}
        value={branchId}
        {...restProps}
      >
        {/* 
        // @ts-ignore */}
        <Select.Option value={0}>สาขาทั้งหมด</Select.Option>
        {branchList?.result?.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {i18n?.language === "th" ? item["nameTh"] : item["nameEn"]}
          </Select.Option>
        ))}
      </Select>
    </>
  );
};

export default SelectorBranch;
