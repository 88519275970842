import { IGroup } from "@interface/IDraftOrder.interface";
import { useEffect } from "react";
import { checkUpdate } from "@tools/util";
import { ICheckUpdate } from "@interface/IGlobal.interface";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useDeleteDraftOrder } from "@services/api/draft_order";
import { useGetProductLabOther } from "@services/api/order";
import { TransferOperationProps } from "antd/lib/transfer";
import { useCreateNewAndProducts } from "@services/api/draft_order";
import { useMainLabInterfaceGroup } from "@provider/lab_inteface_group";
import { Button, Col, Divider, Form } from "antd";
import { useUpdateWorkOrderAddProducts } from "@services/api/work_order";
import { useDeleteWorkOrdersEditProduct } from "@services/api/work_order";
import { ENUM_ROOM_SIGNATURE, ORDER_TYPE } from "@tools/util.enum";
import { FormInstance, Modal, Row, Transfer } from "antd";

interface IAddProducts {
  productId?: number | string;
  id?: number | string;
  qty: number | string;
  type: string;
}
interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

type Props = {
  patientID: number;
  title: string;
  visible: IVisible;
  modalTransfer: FormInstance;
  draftOrderList?: IGroup[];
  type: ORDER_TYPE;
  eventId: number;
  room: ENUM_ROOM_SIGNATURE;
};

const ModalTransfer = ({
  patientID,
  title,
  visible,
  modalTransfer,
  draftOrderList,
  type,
  eventId,
  room,
}: Props) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const appointmentsID = match.params.appointmentsID;
  const createNewAndProducts = useCreateNewAndProducts();
  const client = useQueryClient();
  const updateWorkOrderAddProducts = useUpdateWorkOrderAddProducts();
  const delWorkOrders = useDeleteWorkOrdersEditProduct();
  const deleteDraftOrder = useDeleteDraftOrder();
  const products = useGetProductLabOther({
    groupOther: type,
  });
  const { visitId, status } = useMainLabInterfaceGroup();

  useEffect(() => {
    let lab: number[] = [];
    draftOrderList?.map((item) => (lab = [...lab, ...item.listKey]));
    modalTransfer.setFieldsValue({
      lab: lab,
    });
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    visible.setVisible(!visible.visible);
  };

  const onFinish = (value: any) => {
    try {
      let lab: number[] = [];
      draftOrderList?.map((item) => (lab = [...lab, ...item.listKey]));
      const { newData, removeData } = checkUpdate(
        lab,
        value?.lab
      ) as ICheckUpdate;

      const addProducts = newData?.map((item: any) => {
        return { id: item, qty: 1, type: type };
      });

      handleDeleteDraftOrder(removeData, addProducts);
      if (!addProducts?.length && !removeData?.length) {
        visible.setVisible(!visible?.visible);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleDeleteDraftOrder = async (
    removeData: number[],
    addProducts: { id: number; qty: number; type: ORDER_TYPE }[]
  ) => {
    if (status === "interface_lab") {
      let ids: string[] = [];
      for (const item of draftOrderList || []) {
        for (const cItem of item?.children?.[0]?.children || []) {
          if (
            removeData?.some((itemList) => itemList === cItem?.product?.id) &&
            (!cItem?.product?.isPromotion || true)
          ) {
            ids = [...ids, cItem?.uuid];
          }
        }
      }

      deleteWorkOrders(ids, addProducts);
    } else {
      let ids: string[] = [];
      for (const item of draftOrderList || []) {
        for (const cItem of item?.children?.[0]?.children || []) {
          if (
            removeData?.some((itemList) => itemList === cItem?.product?.id) &&
            (!cItem?.product?.isPromotion || true)
          ) {
            ids = [...ids, cItem?.id];
          }
        }
      }
      deleteDraft(ids, addProducts);
    }
  };

  const deleteWorkOrders = (id?: any, addProducts?: IAddProducts[]) => {
    if (id) {
      delWorkOrders.mutate(
        {
          data: {
            uuids: id,
            room: room,
          },
          id: visitId,
        },
        {
          onSettled: () => {
            addProducts?.length &&
              handleCreateNewAndProducts(addProducts || []);
            client.invalidateQueries(["get-lab-info"]);
          },
        }
      );
    } else {
      addProducts?.length && handleCreateNewAndProducts(addProducts || []);
    }
  };

  const deleteDraft = (id: string[], addProducts: IAddProducts[]) => {
    if (id) {
      deleteDraftOrder.mutate(
        {
          id: patientID,
          idProduct: {
            eventId: eventId,
            itemId: id,
            room: room,
          },
        },
        {
          onSuccess: () => {
            // openNotification({ method: "DEL_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "DEL_ERROR", message: String(err) });
          },
          onSettled: () => {
            handleCreateNewAndProducts(addProducts);
          },
        }
      );
    } else {
      handleCreateNewAndProducts(addProducts);
    }
  };

  const handleCreateNewAndProducts = async (addProducts: IAddProducts[]) => {
    if (status === "interface_lab") {
      const productIds = { productIds: addProducts, room: room };
      updateWorkOrderAddProducts.mutate(
        {
          id: visitId,
          data: productIds,
        },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
            client.invalidateQueries(["get-lab-info"]);
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            visible.setVisible(false);
          },
        }
      );
    } else {
      const productIds = {
        productIds: addProducts,
        eventId: eventId,
        room: room,
      };

      createNewAndProducts.mutate(
        { data: productIds, id: patientID },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
            client.invalidateQueries(["get-appointment-byID", appointmentsID]);
            client.invalidateQueries(["get-draft-order-list"]);
            visible.setVisible(false);
          },
          onError: () => {
            openNotification({ method: "PUT_ERROR" });
          },
        }
      );
    }
  };

  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col md={20} lg={20} className="t-blue ">
              <b>{title}</b>
            </Col>
            <Col md={4} lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={visible.visible}
      onOk={() => visible.setVisible(false)}
      onCancel={onCancel}
      width="70%"
      footer={null}
    >
      <div className="scroll ">
        <Form
          onFinish={onFinish}
          className="p-2"
          name="modalTransfer"
          form={modalTransfer}
        >
          <Row gutter={[14, 14]} className="margin-form ">
            <Col md={24} lg={24}>
              <Form.Item label={title} name={"lab"} className="modal-note">
                <TransferForm
                  dataSource={products?.data?.result || []}
                  modalTransfer={modalTransfer}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => modalTransfer.submit()}
                loading={
                  deleteDraftOrder.isLoading ||
                  createNewAndProducts.isLoading ||
                  delWorkOrders?.isLoading ||
                  updateWorkOrderAddProducts?.isLoading
                }
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalTransfer;

interface PropsTransfer extends TransferOperationProps {
  value?: [];
  onChange?: (event: any) => void;
  dataSource: any;
  modalTransfer: FormInstance;
}

export const TransferForm = ({
  value,
  dataSource,
  modalTransfer,
  ...props
}: PropsTransfer) => {
  const { i18n } = useTranslation();
  const handleSelectChange = (
    sourceSelectedKeys: any,
    targetSelectedKeys: any
  ) => {
    props.onChange &&
      props.onChange([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  return (
    <Transfer
      className="lab-transfer lab-transfer1"
      dataSource={dataSource?.map((item: any) => {
        return {
          ...item,
          disabled:
            typeof item?.isTestCode === "boolean" ? !item?.isTestCode : true,
        };
      })}
      onSelectChange={handleSelectChange}
      showSearch
      selectedKeys={value || []}
      targetKeys={value || []}
      render={(item: any) =>
        i18n.language === "th" ? item?.nameTh : item?.nameEn
      }
      rowKey={(record: any) => record?.id}
      filterOption={(input, option: any) => {
        return (
          option?.nameTh?.toLowerCase()?.includes(input?.toLowerCase()) ||
          option?.nameEn?.toLowerCase()?.includes(input?.toLowerCase())
        );
      }}
    />
  );
};
