import { COUPON_STATUS } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { IItem, IProduct } from "@interface/IOrder.interface";
import { useGetCategories } from "@services/api/order";
import { discount, statusCoupon } from "@assets/data/data";
import { Empty, Form, FormInstance, Input, Row } from "antd";
import { Card, Checkbox, Col, DatePicker, Radio, Select } from "antd";
import empty from "@assets/img/empty.svg";
import MoTable from "@components/table/Table";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  confirm: FormInstance;
  orderList: any;
};

const Fields = ({ confirm, orderList }: Props) => {
  const { t } = useTranslation();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const categoryList = useGetCategories({ limit: 0 });

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: any, data: any, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: any, data: IItem) => {
        return <div>{data?.product?.nameTh || ""}</div>;
      },
    },

    {
      title: t("type"),
      dataIndex: "group",
      key: "group",
      render: (_: any, data: IProduct) => {
        return <div>{data?.group?.nameTh || "-"}</div>;
      },
    },

    {
      title: t("details"),
      dataIndex: "descriptionTh",
      key: "descriptionTh",
      render: (_: any, data: IProduct) => {
        return <div>{data?.descriptionTh || "-"}</div>;
      },
    },
  ];

  return (
    <div className="set-layout ">
      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={<b className="fs-16 t-black-light ">{t("coupon.title")}</b>}
      >
        <Card
          className="card-prefix card-prefix-coupon-width card-prefix-chi"
          extra={<b className="fs-14 c-white ">{t("coupon.manage")}</b>}
        >
          <Form name="confirm" form={confirm}>
            <Row gutter={[14, 14]}>
              <Col span={12}>
                <Form.Item name="name" label={t("coupon.name")}>
                  <Input placeholder={t("coupon.name")} disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="status" label={`${t("status")}`}>
                  <Selector.Translation
                    Source={statusCoupon?.filter((item) => {
                      return item?.id !== COUPON_STATUS.EXPIRED;
                    })}
                    keyName={"name"}
                    keyValue="id"
                    disabled={true}
                  />
                </Form.Item>
              </Col>

              <Col md={24} lg={24}>
                <Form.Item name="categorySelete">
                  <Radio.Group disabled>
                    <Radio style={{ width: "20vw" }} value="category">
                      {t("category")}
                    </Radio>
                    <Radio value="product">{t("product")}</Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col md={24} lg={24}>
                <Form.Item
                  shouldUpdate={(prev, next) =>
                    prev.categorySelete !== next.categorySelete
                  }
                >
                  {(form: FormInstance) => {
                    const categorySelete = form.getFieldValue("categorySelete");

                    if (categorySelete === "category") {
                      return (
                        <Col span={24}>
                          <Form.Item name="category">
                            <Select
                              mode="multiple"
                              className="promotion-select"
                              allowClear
                              placeholder="Please select"
                              filterOption={(input, option: any) => {
                                return (
                                  option.children
                                    .toLowerCase()
                                    .includes(input) ||
                                  `${input}` === `${option.value}`
                                );
                              }}
                            >
                              {categoryList?.data?.result?.[0]?.data?.map(
                                (item, idx) => (
                                  <Option value={item?.["id"]} key={idx}>
                                    {item?.["nameTh"]}
                                  </Option>
                                )
                              )}
                            </Select>
                          </Form.Item>
                        </Col>
                      );
                    } else if (categorySelete === "product") {
                      return (
                        <Col span={24}>
                          <Row className="header-table  ">
                            <Col
                              md={14}
                              lg={18}
                              className="f-normal col-start t-dark-blue"
                            >
                              <span className="f-bold mr-05 ">
                                {t("selectAProductList")}
                              </span>
                            </Col>
                          </Row>
                          <MoTable
                            className="components-table-demo-nested table-selection table-confirm "
                            pagination={false}
                            locale={{
                              emptyText: (
                                <>
                                  <Empty
                                    image={empty}
                                    imageStyle={{
                                      height: 60,
                                    }}
                                    description={
                                      <span className="t-grey-white">
                                        {t("noInformation")}
                                      </span>
                                    }
                                  ></Empty>
                                </>
                              ),
                            }}
                            rowClassName={(r) => {
                              if (r?.level === 1) {
                                return " no-header no-cursor";
                              } else if (r?.level === 2) {
                                return "table-header no-header no-cursor";
                              } else if (r?.level === 3) {
                                return " no-header";
                              }
                              return "";
                            }}
                            expandRowByClick
                            rowKey={"id"}
                            key={"id"}
                            columns={columns}
                            dataSource={orderList?.order || []}
                            onDoubleClick={false}
                            scroll={{ x: "calc(100vw -20%)" }}
                          />
                        </Col>
                      );
                    }
                  }}
                </Form.Item>
              </Col>

              <Col md={24} lg={12}>
                <Form.Item name="start" label={t("promotionPeriod")}>
                  <RangePicker format="DD/MM/YYYY" disabled />
                </Form.Item>
              </Col>

              <Col md={24} lg={12}>
                <Form.Item name="type" label={t("discounts")}>
                  <SelectorDouble
                    placeholder="฿"
                    status={2}
                    Source={discount}
                    disabled
                    value={20}
                    keyName="name"
                    keyValue="id"
                    title={t("status")}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item name="uses" valuePropName="checked" noStyle>
                  <Checkbox disabled>{t("numberOfUses")}</Checkbox>
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item name="right" valuePropName="checked" noStyle>
                  <Checkbox disabled>{t("numberOfRightsPerson")}</Checkbox>
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  shouldUpdate={(prev, next) => prev?.uses !== next?.uses}
                >
                  {(form: FormInstance) => {
                    const uses = form.getFieldValue("uses");

                    if (uses) {
                      return (
                        <Row gutter={[14, 14]}>
                          <Col span={24}>
                            <Form.Item name="qtyUse">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    }
                  }}
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  shouldUpdate={(prev, next) => prev?.right !== next?.right}
                >
                  {(form: FormInstance) => {
                    const uses = form.getFieldValue("right");

                    if (uses) {
                      return (
                        <Row gutter={[14, 14]}>
                          <Col span={24}>
                            <Form.Item name="qty">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    }
                  }}
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t("details")} name="detail">
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </div>
  );
};

export default Fields;
