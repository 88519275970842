import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useCreateMedia } from "@services/api/media_object";
import { openNotification } from "@components/notification/Notification";
import { useEffect, useState } from "react";
import { useGetProfile, useUpdateUser } from "@services/api/users";
import { Button, Col, Form, FormInstance, Input, Row } from "antd";
import ImageProfile from "@components/file_space/ImageProfile";

type Props = {
  formUser: FormInstance;
};

const Fields = ({ formUser }: Props) => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const profile = useGetProfile();
  const userUpdate = useUpdateUser();
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const createMedia = useCreateMedia();

  useEffect(() => {
    formUser.setFieldsValue({
      ...profile?.data?.result?.[0],
      password: "",
    });

    //eslint-disable-next-line
  }, [profile?.data]);

  const changePassword = () => {
    setEditPassword(!editPassword);
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getFileName = () => {
    return false;
  };

  const onFinish = (value: any) => {
    if (value?.image?.includes("base64")) {
      const file = dataUrlToFile(value?.image, "branchImage.png");
      if (file) {
        const formData = new FormData();
        formData?.append("file", file);
        createMedia.mutate(formData, {
          onSuccess: (res) => {
            update({ ...value, imageId: res?.result?.[0]?.id });
          },
          onError: (error) => {
            openNotification({ method: "POST_ERROR", message: String(error) });
          },
        });
      }
    } else {
      update(value);
    }
  };

  const update = (value: any) => {
    delete value?.image;

    userUpdate.mutate(
      { id: profile?.data?.result?.[0]?.id, data: value },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-user-profile"]);
        },
        onError: (res) => {
          openNotification({
            method: "PUT_ERROR",
            message: String(res),
          });
        },
      }
    );
  };
  return (
    <div>
      <Form name="formUser" form={formUser} onFinish={onFinish}>
        <Row gutter={[50, 14]} align="middle">
          <Col md={8} lg={8}>
            <Form.Item name="image" getValueFromEvent={getFile}>
              <ImageProfile
                form={formUser}
                accept=".png,.jpg,.jpeg"
                name="image"
                beforeUpload={getFileName}
              />
            </Form.Item>
          </Col>
          <Col md={16} lg={16}>
            <div>{t("basicInfo")}</div>

            <Row gutter={[14, 14]}>
              <Col md={12} lg={12}>
                <Form.Item
                  label={`${t("name")} (TH)`}
                  name="firstNameTh"
                  rules={[{ required: true }]}
                >
                  <Input
                    id="firstNameTh"
                    placeholder={`${t("name")} (TH)`}
                    onPressEnter={(e) => {
                      document.getElementById("lastName")?.focus();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={12} lg={12}>
                <Form.Item
                  label={`${t("lastName")} (TH)`}
                  name="lastNameTh"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={`${t("lastName")} (TH)`}
                    id="lastNameTh"
                    onPressEnter={(e) => {
                      document.getElementById("nickname")?.focus();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={`${t("name")} (EN)`}
                  name="firstNameEn"
                  rules={[{ required: true }]}
                >
                  <Input
                    id="firstNameEn"
                    placeholder={`${t("name")} (EN)`}
                    onPressEnter={(e) => {
                      document.getElementById("lastName")?.focus();
                    }}
                  />
                </Form.Item>
              </Col>
              <Col md={12} lg={12}>
                <Form.Item
                  label={`${t("lastName")} (EN)`}
                  name="lastNameEn"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={`${t("lastName")} (EN)`}
                    id="lastNameEn"
                    onPressEnter={(e) => {
                      document.getElementById("nickname")?.focus();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item label={t("nickname")} name="nickname">
                  <Input
                    id="nickname"
                    placeholder={t("nickname")}
                    onPressEnter={(e) => {
                      document.getElementById("phone")?.focus();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={t("telephoneNumber")}
                  name="phone"
                  rules={[{ required: true }]}
                >
                  <Input
                    id="phone"
                    placeholder={t("telephoneNumber")}
                    onPressEnter={(e) => {
                      document.getElementById("username")?.focus();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={t("userName")}
                  name="username"
                  rules={[{ required: true }]}
                >
                  <Input
                    id="username"
                    placeholder={t("userName")}
                    onPressEnter={(e) => {
                      document.getElementById("email")?.focus();
                    }}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={t("email")}
                  name="email"
                  rules={[
                    {
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    id="email"
                    type="email"
                    placeholder={t("email")}
                    onPressEnter={(e) => {
                      document.getElementById("newPassword")?.focus();
                    }}
                  />
                </Form.Item>
              </Col>

              {editPassword ? (
                <>
                  <Col md={24} lg={24}>
                    <div>ตั้งค่ารหัสผ่าน</div>
                  </Col>
                  <Col md={12} lg={12}>
                    <Form.Item
                      label={t("newPassword")}
                      name="newPassword"
                      rules={[{ required: true }]}
                      hasFeedback
                    >
                      <Input.Password
                        id="newPassword"
                        type="password"
                        placeholder={t("newPassword")}
                        onPressEnter={(e) => {
                          document
                            .getElementById("confirmNewPassword")
                            ?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12}>
                    <Form.Item
                      label={t("confirmNewPassword")}
                      name="confirmNewPassword"
                      hasFeedback
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue("newPassword") === value
                            ) {
                              return Promise.resolve();
                            }

                            return Promise.reject(
                              new Error(t("theTwoPasswords"))
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        id="confirmNewPassword"
                        type="password"
                        placeholder={t("confirmNewPassword")}
                        onPressEnter={(e) => {
                          document.getElementById("oldPassword")?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={12} lg={12}>
                    <Form.Item
                      label={t("oldPassword")}
                      name="password"
                      rules={[{ required: true }]}
                    >
                      <Input.Password
                        id="oldPassword"
                        type="password"
                        placeholder={t("oldPassword")}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col md={24} lg={24}>
                    <div>เปลี่ยนรหัสผ่าน</div>
                  </Col>

                  <Col md={12} lg={12}>
                    <Button
                      className="password-button"
                      type="primary"
                      onClick={changePassword}
                    >
                      ตั้งรหัสผ่านใหม่
                    </Button>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Fields;
