import React, { FC } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row, Space } from "antd";
import { loadingButtonState } from "@tools/recoil/recoil-atom";

import BackHome from "@components/button/BackHome";

type Props = {
  managePickUpGoods: FormInstance;
  visible?: boolean;
  status: string;
  setVisible: React.Dispatch<React.SetStateAction<boolean>>;
  salesReturn?: boolean;
  setSalesReturn: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderPickUpGoods: FC<Props> = ({
  managePickUpGoods,
  visible,
  status,
  setVisible,
  setSalesReturn,
}) => {
  const { t } = useTranslation();
  // const match: any = useRouteMatch();

  const history = useHistory<RouteComponentProps["history"]>();
  const loadingButton = useRecoilValue(loadingButtonState);
  // const pickUpGoodsId = match.params.id;

  return (
    <>
      <Row className="second-header" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("wareHouse.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("wareHouse.title")} / `}
              </span>
              <span className="t-dark-blue f-normal">
                {t("receiptProductManagement")}
              </span>
            </Col>
          </Row>
        </Col>
        {visible ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="btn-check mr-05"
              onClick={() => {
                setSalesReturn(false);
                managePickUpGoods.submit();
              }}
              loading={loadingButton}
            >
              {t("save")}
            </Button>

            <Button
              className="btn-save-and-return"
              onClick={() => {
                setSalesReturn(true);
                managePickUpGoods.submit();
              }}
            >
              {t("saveAndReturn")}
            </Button>
          </Col>
        ) : (
          <Col style={{ marginLeft: "auto" }}>
            <Space align="center">
              <Button className="mr-05 btn-print" onClick={() => {}}>
                {t("print")}
              </Button>
              {status === "SENDING_COMPLETE" ? (
                <></>
              ) : (
                <Button
                  className="btn-check"
                  onClick={() => {
                    setVisible((prevState) => !prevState);
                  }}
                  loading={loadingButton}
                >
                  {t("checkProduct")}
                </Button>
              )}
            </Space>
          </Col>
        )}
      </Row>
    </>
  );
};

export default HeaderPickUpGoods;
