import React, { useState } from "react";
import { Form } from "antd";
import { useRouteMatch } from "react-router-dom";
import { useGetPatientByHistory } from "@services/api/patient";
import Fields from "./Fields";
import Header from "./Header";
import MainTransferDetailProvider from "@provider/transfer_detail";

const TransferDetail = () => {
  const match: any = useRouteMatch();
  const pathID = match.params.patientID;
  const patientIDByID = useGetPatientByHistory(pathID);
  const [basicInfoForm] = Form.useForm();
  const [send, setSend] = useState(false);
  const [branchId, setBranchId] = useState<number | null>(null);

  const onFinish = (value: { branchId: number }) => {
    setBranchId(value?.branchId);
    setSend(!send);
  };

  return (
    <MainTransferDetailProvider
      value={{
        patientIDByID: patientIDByID?.data,
        form: basicInfoForm,
        setSend: setSend,
        send: send,
        onFinish: onFinish,
        branchId: branchId,
      }}
    >
      <React.Fragment>
        <Header />

        <div className="set-layout body-height ">
          <Fields />
        </div>
      </React.Fragment>
    </MainTransferDetailProvider>
  );
};

export default TransferDetail;
