import { Input } from "antd";
import { useState } from "react";
import { displayHide } from "@tools/util";
import { useTranslation } from "react-i18next";
import { Card, Radio, Divider, Form, FormInstance, Row, Col } from "antd";
import FInput from "../form_float_label/FInput";
import FileSpace from "@components/file_space/FileSpace";
import DrawingCanvas from "../drawing_canvas/DrawingCanvas";

interface IDrawingCanvas {
  drawing: string;
  image: string;
}

type Props = {
  status?: string;
  printAndDrawForm: FormInstance;
  headerCard: string;
  drawingCanvas: IDrawingCanvas;
  setDrawingCanvas: (e: IDrawingCanvas) => void;
  handleCreateAndUpdate: (value: any) => void;
};

const PrintDraw = ({
  status = "",
  printAndDrawForm,
  headerCard,
  drawingCanvas,
  setDrawingCanvas,
  handleCreateAndUpdate,
}: Props) => {
  const { t } = useTranslation();
  const { TextArea } = Input;
  const [tabSelected, setTabSelected] = useState("1");

  const onFinish = (values: any) => {
    handleCreateAndUpdate(values);
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getFileName = () => {
    return false;
  };

  return (
    <div>
      <Card extra={<b className="header-card">{headerCard}</b>}>
        <Radio.Group
          defaultValue="1"
          onChange={(e) => setTabSelected(e.target.value)}
        >
          <Radio.Button value="1" className="first-child">
            {t("typeMessage")}
          </Radio.Button>
          <Radio.Button value="2" className="last-child">
            {t("drawing")}
          </Radio.Button>
        </Radio.Group>

        <Divider className="canvasdraw" />
        <div className={displayHide(tabSelected, "1")}>
          <div className="mt-2">
            <Form
              onFinish={onFinish}
              name="printAndDrawForm"
              id="printAndDrawForm"
              form={printAndDrawForm}
            >
              <Form.Item name="print">
                <TextArea
                  placeholder={`${t("typeLeadingSymptoms")}.....`}
                  autoSize={{ minRows: 20 }}
                />
              </Form.Item>

              {status && (
                <Row gutter={[14, 14]} className="margin-form">
                  <Col className="mt-1" md={8} lg={8}>
                    <div className="t-grey-form">BP :</div>
                    <div className="mt-form">
                      <Row gutter={[5, 14]}>
                        <Col md={12} lg={12}>
                          <Form.Item name="systolic">
                            <FInput label="Systolic" name="systolic" />
                          </Form.Item>
                        </Col>
                        <Col md={12} lg={12}>
                          <Form.Item name="diastolic">
                            <FInput label="Diastolic" name="diastolic" />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col md={8} lg={8}>
                    <Form.Item label="PR/min " name="pRmin">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8}>
                    <Form.Item label="RR/min " name="rRmin">
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8}>
                    <Form.Item
                      label="BT (c) "
                      name="bt"
                      // rules={[{ required: true,  }]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8}>
                    <Form.Item
                      label={
                        <div>
                          O<sub>2</sub>sat (%)
                        </div>
                      }
                      name="o2"
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={8} lg={8}>
                    <Form.Item label="BW (kg) " name="bw">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={8}>
                    <Form.Item label={t("height")} name="height">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Form.Item name="time" hidden>
                    <Input />
                  </Form.Item>

                  <Col md={24} lg={24} className="mt-1">
                    <div className="f-normal t-blue-theme">
                      {t("attachFile")}
                    </div>
                  </Col>

                  <Col md={24} lg={24}>
                    <div className="f-normal ">Radiology Result</div>
                  </Col>

                  <Col md={24} lg={24}>
                    <Form.Item
                      name="radiology"
                      valuePropName="fileList"
                      getValueFromEvent={getFile}
                    >
                      <FileSpace
                        accept=".pdf,.png,.JPG"
                        beforeUpload={getFileName}
                        form={printAndDrawForm}
                        keyName="radiology"
                      />
                    </Form.Item>
                  </Col>

                  <Col md={24} lg={24}>
                    <div className="f-normal ">Medical Report</div>
                  </Col>

                  <Col md={24} lg={24}>
                    <Form.Item
                      name="medical"
                      valuePropName="fileList"
                      getValueFromEvent={getFile}
                    >
                      <FileSpace
                        accept=".pdf,.png,.JPG"
                        beforeUpload={getFileName}
                        form={printAndDrawForm}
                        keyName="medical"
                      />
                    </Form.Item>
                  </Col>

                  <Col md={24} lg={24}>
                    <div className="f-normal ">Summary Report</div>
                  </Col>

                  <Col md={24} lg={24}>
                    <Form.Item
                      name="summary"
                      valuePropName="fileList"
                      getValueFromEvent={getFile}
                    >
                      <FileSpace
                        accept=".pdf,.png,.JPG"
                        beforeUpload={getFileName}
                        form={printAndDrawForm}
                        keyName="summary"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              )}
            </Form>
          </div>
        </div>
        {tabSelected === "2" && (
          <div className="mt-2">
            <DrawingCanvas
              drawingCanvas={drawingCanvas}
              setDrawingCanvas={setDrawingCanvas}
            />
          </div>
        )}
      </Card>
    </div>
  );
};

export default PrintDraw;
