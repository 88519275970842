import { useState } from "react";
import { useTranslation } from "react-i18next";
import Header from "./Header";
import TableBounceGoods from "./Table";
import dayjs from "dayjs";

const BounceGoods = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState();

  const onFinish = (values: any) => {
    setSearch({
      ...values,
      startDate: values.start?.[0]
        ? dayjs(values.start?.[0]).format("YYYY-MM-DD")
        : undefined,
      endDate: values.start?.[1]
        ? dayjs(values.start?.[1]).format("YYYY-MM-DD")
        : undefined,
    });
    setCurrentPage(1);
  };

  return (
    <div>
      <Header onFinish={onFinish} translation={t} />
      <div className="header-card mb-05">{t("bounceGoods")}</div>
      <TableBounceGoods
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
        search={search}
      />
    </div>
  );
};

export default BounceGoods;
