import { IOptionToID } from "@interface/IWorkOrder.interface";
import { productName } from "@tools/util";
import { useTranslation } from "react-i18next";
import { IItem, IProduct } from "@interface/IOrder.interface";
import { useRef, useState } from "react";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { Button, Form, Popover, Table } from "antd";
import Decimal from "@tools/decimal";
import ModalInfo from "../ModalInfo";
import moment from "moment";

type Props = {
  orderList?: ISelectedExecuteOrder[];
  setOptionToID: (e: IOptionToID) => void;
  medicalRecord: boolean;
  handleUpdateWorkOrderItem?: (e: any, type: string) => void;
};

const OrderListOptionDrugs = ({
  orderList,
  setOptionToID,
  medicalRecord,
  handleUpdateWorkOrderItem,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const num = useRef(0);
  const [manageInfo] = Form.useForm();
  const onClickRow = (event: IItem) => {
    const option = event.option ? JSON.parse(event.option || `{}`) : {};

    setOptionToID({ id: event.id, option: option });
    manageInfo.resetFields();
    let qtyOutstanding = event?.qtyOutstanding + event?.qty;
    let qtyOutstandingCourse =
      event?.qtyOutstandingCourse || 0 + event?.qty || 0;
    manageInfo.setFieldsValue({
      ...event,
      nameShown: event?.product?.nameTh,
      genericName: event?.product?.nameEn,
      descriptionTh:
        option?.productDescriptionTh || event?.product?.propertiesTh,

      descriptionEn:
        option?.productDescriptionEn || event?.product?.propertiesEn,
      ...option,
      quantityUsed: option?.quantityUsed || 1,
      drugExpiration: option?.drugExpiration
        ? moment(option.drugExpiration)
        : undefined,
      dateStart: option?.dateStart ? moment(option?.dateStart) : undefined,
      dateStop: option?.dateStop ? moment(option?.dateStop) : undefined,
      editBy:
        i18n.language === "th"
          ? [event?.editBy?.firstNameTh, event?.editBy?.lastNameTh]
              ?.join(" ")
              ?.trim()
          : [event?.editBy?.firstNameEn, event?.editBy?.lastNameEn]
              ?.join(" ")
              ?.trim(),
      qtyOutstanding: !!event?.allQty
        ? event?.allQty
        : qtyOutstanding || qtyOutstandingCourse,
      unitAltTh: event?.product?.unitAltTh,
      unitAltEn: event?.product?.unitAltEn,
      frequencyTh: option?.frequencyTh || event?.product?.usageTh,
      frequencyEn: option?.frequencyEn || event?.product?.usageEn,
    });
    setVisible(!visible);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      width: "7%",
      render: (_: string, data: IItem, index: number) => {
        return <div key={data?.key}>{data?.level === 1 && index + 1}</div>;
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? i18n.language === "th"
                ? data?.children?.[0]?.children?.[0]?.product?.category
                    ?.nameTh || ""
                : data?.children?.[0]?.children?.[0]?.product?.category
                    ?.nameEn || ""
              : data?.level === 2 || data?.level === 4
              ? t("nameList")
              : (data?.level === 3 || data?.level === 5) &&
                productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem & IProduct) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("price")
              : data?.level === 3
              ? Decimal(data?.normal) || "-"
              : ""}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (_: string, data: IItem) => {
        return (
          <>
            {data?.level === 1 ? (
              ``
            ) : data?.level === 2 || data?.level === 4 ? (
              t("amount")
            ) : data?.level === 3 && data?.children ? (
              `${data?.qty} ${
                i18n.language === "th"
                  ? data?.product?.unitTh || ""
                  : data?.product?.unitEn || ""
              }`
            ) : data?.level === 3 && !data?.category ? (
              <div>{`${data?.qty} ${
                i18n.language === "th"
                  ? data?.product?.unitTh || ""
                  : data?.product?.unitEn || ""
              }`}</div>
            ) : data?.level === 5 ? (
              <div>{`${data?.qty} ${
                i18n.language === "th"
                  ? data?.product?.unitTh || ""
                  : data?.product?.unitEn || ""
              }`}</div>
            ) : null}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ``
              : data?.level === 2 || data?.level === 4
              ? `${t("amount")} / ${t("totalNumber")}`
              : data?.level === 3
              ? `${data?.qty || 0} / ${data?.allQty}`
              : data?.level === 5
              ? `${data?.qty || 0} / ${data?.allQty}`
              : data?.qtyOutstanding
              ? `${data?.qty || 0} / ${data?.allQty}`
              : `${data?.qty || 0} / ${data?.allQty}`}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      render: (productDescription: string, data: IItem) => {
        const option = data?.option ? JSON.parse(data?.option || `{}`) : {};
        return (
          <div key={data?.key}>
            {data?.level === 1 ? (
              ``
            ) : (data?.level === 2 && !data?.children?.[0]?.children) ||
              data?.level === 4 ? (
              t("properties")
            ) : (data?.level === 3 && !data?.children) || data?.level === 5 ? (
              <Popover
                content={
                  option?.description !== undefined
                    ? option?.description
                    : i18n.language === "th"
                    ? productDescription || data?.product?.propertiesTh
                    : productDescription || data?.product?.propertiesEn
                }
              >
                <div className="text-content">
                  {option?.description !== undefined
                    ? option?.description
                    : i18n.language === "th"
                    ? productDescription || data?.product?.propertiesTh
                    : productDescription || data?.product?.propertiesEn}
                </div>
              </Popover>
            ) : null}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1 ? (
              data?.children?.[0]?.children?.length
            ) : data?.level === 2 ? (
              data?.status
            ) : medicalRecord ? (
              (data?.level === 3 && !data?.children) || data?.level === 5 ? (
                <Button
                  type="primary"
                  className={!!data?.option ? "btn-manage-drug-labels" : ""}
                  onClick={() => onClickRow(data)}
                >
                  {t("manageDrugLabels")}
                </Button>
              ) : null
            ) : null}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("total")
              : data?.level === 3
              ? Decimal(data?.net) || "-"
              : null}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        className="components-table-demo-nested table-selection table-confirm "
        columns={columns}
        dataSource={orderList}
        rowKey={"key"}
        expandRowByClick={true}
        showSorterTooltip={true}
        pagination={false}
        rowClassName={(r) => {
          if (r?.level === 1) {
            return " no-header no-cursor";
          } else if (r?.level === 2) {
            return "table-header no-header no-cursor";
          } else if (r?.level === 3 && !r?.children) {
            return "";
          } else if (r?.level === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "table-confirm-first table-confirm-level-course table-bottom--level-course-num no-cursor";
            } else {
              return "table-confirm-first table-confirm-level-course no-cursor";
            }
          } else if (r?.level === 4) {
            num.current = r?.children?.length;
            return "table-header no-header table-confirm-level-course no-cursor table-top-level-course ";
          }
          return " no-header";
        }}
        scroll={{ x: "calc(100vw -20%)" }}
      />

      {visible && (
        <ModalInfo
          manageInfo={manageInfo}
          visible={{
            visible: visible,
            setVisible: setVisible,
          }}
          updateWorkOrderItem={handleUpdateWorkOrderItem}
        />
      )}
    </div>
  );
};

export default OrderListOptionDrugs;
