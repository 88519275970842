import { IDocument } from "@interface/IDocument.interface";
import { Elementary } from "@interface/IGlobal.interface";
import { thrownResponse } from "./auth";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetDocument = (
  params?: any
): UseQueryResult<Elementary<IDocument[]>> => {
  return useQuery(["get-document", params], async () => {
    const res = await axios.get(`/document`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateDocument = () => {
  return useMutation(["create-document"], async (data: any) => {
    const res = await axios.post(`document`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteDocument = () => {
  return useMutation(async (id: number | undefined) => {
    const res = await axios.delete(`/document/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
