import { Col, Row } from "antd";
import { PAYMENT_TYPE } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useMainDashboard } from "@provider/dashboard";
import ChartDoughnut from "./ChartDoughnut";
import cash from "@assets/img/icon/cash.svg";
import credit from "@assets/img/icon/credit.svg";
import numeral from "numeral";
import alienate from "@assets/img/icon/alienate.svg";

const Cashier = () => {
  const { t } = useTranslation();
  const { cashier } = useMainDashboard();

  const valuePlay = (key: string) => {
    return (
      cashier?.payment?.filter((item) => item?.payment === key)?.[0]?.sum || 0
    );
  };

  return (
    <div>
      <ChartDoughnut />

      <div className=" mt-2">
        <Row>
          <Col
            span={5}
            className="align-self-center"
            style={{ alignContent: "space-around" }}
          >
            <img
              src={cash}
              style={{
                padding: "8px",
                backgroundColor: "#004C97",
                borderRadius: 5,
              }}
              alt="cash"
              width="61%"
            />
          </Col>
          <Col span={12} className="col-start">
            {t("cash")}
          </Col>
          <Col span={7} className="col-end f-bold pr-05">
            ฿{numeral(valuePlay(PAYMENT_TYPE.CASH)).format("0,0")}
          </Col>
        </Row>
      </div>

      <div className=" mt-2">
        <Row>
          <Col
            span={5}
            className="align-self-center"
            style={{ alignContent: "space-around" }}
          >
            <img
              src={alienate}
              style={{
                padding: "8px",
                backgroundColor: "#498DCB",
                borderRadius: 5,
              }}
              alt="alienate"
              width="61%"
            />
          </Col>
          <Col span={12} className="col-start">
            {t("transfer")}
          </Col>
          <Col span={7} className="col-end f-bold pr-05">
            ฿{numeral(valuePlay(PAYMENT_TYPE.TRANSFER)).format("0,0")}
          </Col>
        </Row>
      </div>

      <div className=" mt-2">
        <Row>
          <Col
            span={5}
            className="align-self-center"
            style={{ alignContent: "space-around" }}
          >
            <img
              src={credit}
              style={{
                padding: "8px",
                backgroundColor: "#77C48B",
                borderRadius: 5,
              }}
              alt="credit"
              width="61%"
            />
          </Col>
          <Col span={12} className="col-start">
            {t("credit")}
          </Col>
          <Col span={7} className="col-end f-bold pr-05">
            ฿{numeral(valuePlay(PAYMENT_TYPE.CREDIT)).format("0,0")}
          </Col>
        </Row>
      </div>
      {/* <Card
        className=" mt-2 drug-label-card  t-white"
        style={{ backgroundColor: "#498DCB" }}
      >
        <div className="mt-15 mb-15 ml-05 mr-05">
          <Row>
            <Col
              span={5}
              className="align-self-center"
              style={{ alignContent: "space-around" }}
            >
              <img
                src={AdvanceDeposit}
                style={{
                  padding: "5px",
                  backgroundColor: "#F1F1F1",
                  borderRadius: 5,
                }}
                alt="alienate"
                width="61%"
              />
            </Col>
            <Col span={12} className="col-start">
              {t("advanceDeposit")}
            </Col>
            <Col span={7} className="col-end f-bold">
              ฿{numeral(24000).format("0,0")}
            </Col>
          </Row>
        </div>
      </Card> */}
    </div>
  );
};

export default Cashier;
