import { IDashboardContext } from "./interface";
import { createContext, useContext } from "react";

const MainDashboardContext = createContext<IDashboardContext>(
  {} as IDashboardContext
);

const MainDashboardProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: any;
}) => {
  return (
    <MainDashboardContext.Provider value={{ ...value }}>
      {children}
    </MainDashboardContext.Provider>
  );
};

export const useMainDashboard = () => useContext(MainDashboardContext);
export default MainDashboardProvider;
