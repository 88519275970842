import { OPD } from "@assets/data/pathRouter";
import { _isEmpty } from "@tools/util";
import { stepOPDState } from "@tools/recoil/recoil-atom";
import { FormInstance } from "antd";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { useGetPatientById } from "@services/api/patient";
import { useEffect, useState } from "react";
import { ENUM_FOOD, ENUM_G6PD } from "@tools/util.enum";
import { illnessOptions, symbol } from "@assets/data/data";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { Card, Col, Form, Input, Row, Radio, Checkbox } from "antd";
import Selector from "@components/selector/Selector";

type Props = {
  otherInfoForm: FormInstance;
  history: RouteComponentProps["history"];
  pathID: number;
};

const Fields = ({ otherInfoForm, history, pathID }: Props) => {
  const { t, i18n } = useTranslation();
  const { TextArea } = Input;
  const [value, setValue] = useState(1);
  const match: any = useRouteMatch();
  const eventId = match.params.eventId;
  const setStepOPD = useSetRecoilState(stepOPDState);
  const patientIDByID = useGetPatientById(
    pathID,
    localStorage.getItem("otherInfo")
  );

  useEffect(() => {
    getDataFormList();
    setStepOPD(2);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!patientIDByID?.data) return;
    handleGetData();
    //eslint-disable-next-line
  }, [patientIDByID?.data]);

  const getDataFormList = () => {
    if (localStorage.getItem("otherInfo") !== null) {
      const otherInfo = localStorage.getItem("otherInfo");
      const dataList = JSON.parse(otherInfo ? otherInfo : `{}`);

      if (dataList !== "") {
        otherInfoForm.setFieldsValue({
          ...dataList,
        });
      }
    } else {
      handleGetData();
    }
  };

  const handleGetData = () => {
    if (!_isEmpty(pathID)) {
      handleGetPatientById();
    }
  };

  const handleGetPatientById = async () => {
    otherInfoForm.setFieldsValue({
      ...patientIDByID?.data,
      symbol: patientIDByID?.data?.symbol || undefined,
    });
  };

  const onChange = (e: any) => {
    setValue(e.target.value);
  };

  const onFinish = (values: any) => {
    localStorage.setItem("otherInfo", JSON.stringify(values));

    if (!_isEmpty(pathID)) {
      history.push(
        `${OPD.contractingCompany}/${pathID}${
          !!eventId ? `/event/${eventId}` : ``
        }`
      );
    } else {
      history.push(OPD.contractingCompany);
    }
  };

  return (
    <>
      <div>
        <Form onFinish={onFinish} name="otherInfoForm" form={otherInfoForm}>
          <Card className="mt-2">
            <Row className="mb-1">
              <Col lg={6}>
                <Form.Item label={t("G6PD")} name="g6pd">
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={ENUM_G6PD.DEFICIENCY}>G6PD Deficiency</Radio>
                    <Radio value={ENUM_G6PD.NORMAL}>G6PD Normal</Radio>
                    <Radio value={ENUM_G6PD.UNKNOWN}>Unknow</Radio>
                  </Radio.Group>
                </Form.Item>

                <Form.Item label={t("foodSpecify")} name="food">
                  <Radio.Group onChange={onChange} value={value}>
                    <Radio value={ENUM_FOOD.REGULAR}>{t("general")}</Radio>
                    <Radio value={ENUM_FOOD.HALAL}>{t("halal")}</Radio>
                    <Radio value={ENUM_FOOD.VEGAN}>{t("vegan")}</Radio>
                    <Radio value={ENUM_FOOD.VEGETARIAN}>
                      {t("vegetarian")}
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              <Col lg={18}>
                <Row gutter={[14, 14]} className="margin-form">
                  <Col lg={24}>
                    <Form.Item label={t("insertingASymbol")} name="symbol">
                      <Selector
                        mode="multiple"
                        Source={symbol}
                        keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
                        keyValue="id"
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={12}>
                    <Form.Item label={t("foodAllergy")} name="foodAllergy">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col lg={12}>
                    <Form.Item label={t("drugAllergy")} name="drugAllergy">
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item
                      label={t("congenitalDisease")}
                      name="medicalProblem"
                    >
                      <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item
                      label={t("congenitalDisease")}
                      name="medicalRecords"
                    >
                      <Checkbox.Group options={illnessOptions} />
                    </Form.Item>
                  </Col>

                  <Col lg={12}>
                    <Form.Item
                      label={`${t("other")}`}
                      name="otherMedicalHistory"
                    >
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col lg={12}>
                    <Form.Item label={t("organCancer")} name="cancerOrgan">
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Form>
      </div>
    </>
  );
};

export default Fields;
