import { Tabs } from "antd";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { tabsInchartState } from "@tools/recoil/recoil-atom";
import TableInchartWait from "./wait/Wait";
import InchartBetween from "./between/Between";

const TabsInchart = () => {
  const { t } = useTranslation();
  const [tabsInchart, setTabsInchart] = useRecoilState(tabsInchartState);
  const { TabPane } = Tabs;

  const tabsCallback = (key: string) => {
    setTabsInchart(key);
  };

  return (
    <Tabs
      defaultActiveKey={tabsInchart}
      className="tab-printdraw f-normal "
      onChange={tabsCallback}
    >
      <TabPane tab={t("pending")} key="1" className="mt-2">
        <TableInchartWait />
      </TabPane>
      <TabPane tab={t("inProcess")} key="2" className="mt-2">
        <InchartBetween />
      </TabPane>
    </Tabs>
  );
};

export default TabsInchart;
