import { USER_MANAGE } from "@assets/data/pathRouter";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  formUserRoom: FormInstance;
  userRoomID: number;
  history: RouteComponentProps["history"];
  medicalRecord: boolean;
};

const Header = ({
  formUserRoom,
  userRoomID,
  history,
  medicalRecord,
}: Props) => {
  const { t } = useTranslation();
  const create = useIsMutating(["create-user-room"]);
  const update = useIsMutating(["update-user-room"]);

  return (
    <>
      <Row className="second-header" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("userManage")}</h1>
              </div>
              <span className="t-light-gray">{t("doctorRoomInfo")} / </span>
              <span className="t-dark-blue f-normal">
                {userRoomID ? t("edit") : `${t("create")}`}
              </span>
            </Col>
          </Row>
        </Col>
        {medicalRecord ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="mr-05 cancel"
              onClick={() => history.replace(USER_MANAGE.title)}
            >
              {t("cancel")}
            </Button>
            <Button
              className="btn-create"
              onClick={() => formUserRoom.submit()}
              loading={!!create || !!update}
            >
              {t("save")}
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
