import { dayjs } from "@tools/timezone";
import { RETURN_COURSE } from "@assets/data/pathRouter";
import { IRejectCourse } from "@interface/IOrder.interface";
import { useTranslation } from "react-i18next";
import { useGetRejectCourse } from "@services/api/order";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Search from "./Search";
import MoTable from "@components/table/Table";
import bignumber from "bignumber.js";

type Props = {
  patientID: number;
};

const TableReturnCourse = ({ patientID }: Props) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState({
    receiptNumber: "",
    date: "",
  });
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const [total, setTotal] = useState<number>(1);
  const rejectCourseList = useGetRejectCourse(patientID, {
    receiptNumber: searchValue?.receiptNumber,
    date: searchValue?.date,
    limit: limitPage,
    offset: currentPage,
  });

  useEffect(() => {
    if (!rejectCourseList?.data?.result?.[0]?.total) return;
    setTotal(rejectCourseList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [rejectCourseList?.data]);

  const onClickRow = (event: IRejectCourse) => {
    history.push(`${RETURN_COURSE.report}/${event.id}`);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, data: IRejectCourse, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("date"),
      dataIndex: "date",
      render: (date: string) => {
        return dayjs(date).format("DD-MM-YYYY");
      },
    },

    {
      title: t("documentNumber"),
      dataIndex: "receiptNumber",
      key: "receiptNumber",
    },

    {
      title: t("netTotal"),
      dataIndex: "price",
      key: "price",
      render: (price: string) => {
        return new bignumber(price).toFormat(2);
      },
    },
  ];

  return (
    <div>
      <Search setSearchValue={setSearchValue} setCurrentPage={setCurrentPage} />
      <MoTable
        dataSource={rejectCourseList?.data?.result?.[0]?.data || []}
        rowKey="id"
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default TableReturnCourse;
