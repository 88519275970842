import { ISearch } from "./interface";
import { useState } from "react";
import Table from "./Table";
import Search from "./Search";

const PatientList = () => {
  const [search, setSearch] = useState<ISearch>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const patientsFilter = (values: ISearch) => {
    setSearch(values);
    setCurrentPage(1);
  };

  return (
    <div>
      <Search onFinish={patientsFilter} />
      <Table
        search={search}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </div>
  );
};

export default PatientList;
