import { displayHide } from "@tools/util";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { loadingModalState } from "@tools/recoil/recoil-atom";
import { Table, Pagination, TableProps, Spin } from "antd";

interface Props extends TableProps<any> {
  config?: any;
  onChangePage?: (page: number, pageSize?: string) => void;
  onClickRow?: (row: any) => void;
  pagination?: any;
}

const SelectionTable = ({
  className,
  dataSource = [],
  columns = [],
  loading = false,
  config = { total: 1, currentPage: 1, pageSize: 10 },
  onChangePage,
  rowKey = "id",
  onClickRow,
  rowSelection,
  expandRowByClick,
  showSorterTooltip,
  expandable,
  scroll,
  pagination = false,
}: Props) => {
  const loadingModal = useRecoilValue(loadingModalState);
  const { t } = useTranslation();

  return (
    <Spin spinning={loadingModal} size="large" tip="กำลังโหลด..." delay={500}>
      <div className="card-table">
        <Table
          scroll={scroll}
          className={className ? className : "table-selection"}
          expandRowByClick={expandRowByClick}
          showSorterTooltip={showSorterTooltip}
          rowSelection={rowSelection}
          dataSource={dataSource}
          columns={columns}
          pagination={pagination}
          loading={loading}
          rowKey={rowKey}
          expandable={expandable}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                onClickRow && onClickRow(record);
              },
            };
          }}
        />
        {onChangePage && (
          <div
            className={displayHide(
              config.total,
              config.total === 0 ? null : config.total
            )}
          >
            <div className="mt-2 mb-2 w-100 d-flex justify-flex-end">
              <div className="d-flex items-center pr-1">{t("showList")}</div>
              <Pagination
                showSizeChanger
                onChange={(page) => onChangePage && onChangePage(page, "page")}
                total={config.total}
                current={config.currentPage}
                pageSize={config.pageSize}
                className="pagi"
                onShowSizeChange={(_current, pageSize) =>
                  onChangePage && onChangePage(pageSize, "pageSize")
                }
              />
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
};

export default SelectionTable;
