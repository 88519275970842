import React, { FC, useEffect, useState } from "react";
import { Checkbox, Col, Row } from "antd";
import { useMainLabInterfaceGroup } from "@provider/lab_inteface_group";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";
import { useTranslation } from "react-i18next";
import { Age } from "@tools/age";
import { gender } from "@tools/util";

const CheckboxGroup = Checkbox.Group;
type Props = {
  visible?: boolean;
  viewDetailOpen?: (e: boolean) => void;
};

const LabInterfaceGroupTwo: FC<Props> = ({ visible, viewDetailOpen }) => {
  const { t, i18n } = useTranslation();
  const userList = React.useContext(UserContext);
  const {
    labInterface,
    draftOrderList,
    labInterfaceGroupTwo,
    labInfoList,
    patient,
  } = useMainLabInterfaceGroup();
  const [select, setSelect] = useState(
    !!labInfoList
      ? [...(labInfoList?.groups4?.[0]?.listKey || [])]
      : [
          ...(draftOrderList?.result?.[0]?.labInterface?.groups4?.[0]
            ?.listKey || []),
        ]
  );

  useEffect(() => {
    if (!draftOrderList) return;
    const dataSelect = [
      ...(draftOrderList?.result?.[0]?.labInterface?.groups4?.[0]?.listKey ||
        []),
    ];
    setSelect(dataSelect);

    !!viewDetailOpen && viewDetailOpen(!!dataSelect?.length);
    //eslint-disable-next-line
  }, [draftOrderList]);

  useEffect(() => {
    if (!labInfoList) return;
    const dataSelect = [...(labInfoList?.groups4?.[0]?.listKey || [])];
    setSelect(dataSelect);

    !!viewDetailOpen && viewDetailOpen(!!dataSelect?.length);
    //eslint-disable-next-line
  }, [labInfoList]);

  const onChange = (list: any) => {
    setSelect(list);
    const labInterfaceSelect = list?.map((keyId: number) => {
      const resultList = [...labInterface?.groupTwo].find(
        (key) => key?.id === keyId
      );
      return {
        id: resultList?.id,
        type: resultList?.type,
        qty: 1,
      };
    });
    labInterfaceGroupTwo.current = labInterfaceSelect;
  };
  const KeyHomeAddress = patient?.homeAddress;
  const homeAddress =
    KeyHomeAddress?.addressTh || KeyHomeAddress?.districtTh
      ? [
          KeyHomeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
          KeyHomeAddress[
            i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
          ],
          KeyHomeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
          KeyHomeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
          KeyHomeAddress?.country,
          KeyHomeAddress?.zipcode,
        ]
          ?.join(" ")
          ?.trim()
      : "";
  return (
    <div>
      <CheckboxGroup onChange={onChange} value={select}>
        <table width="100%">
          <tbody>
            <tr className="border-interface-lab">
              <td
                width="20%"
                rowSpan={2}
                colSpan={2}
                className="border-interface-lab-right"
              >
                <ImageLogo
                  className="circles"
                  src={userList?.branch?.image}
                  height="60px"
                  width="150px"
                  alt="logo"
                />
              </td>
              <td
                width="50%"
                rowSpan={2}
                className="border-interface-lab-right text-table-top-management t-start"
              >
                Patient Information :
                <div style={{ paddingLeft: 20 }}>
                  {i18n.language === "th"
                    ? `${patient?.firstNameTh} ${patient?.lastNameTh}`
                    : `${patient?.firstNameEn} ${patient?.lastNameEn}`}
                  {patient?.gender &&
                    ` ${t("sex")} : ${gender(patient?.gender, i18n?.language)}`}
                  {patient?.dateOfBirth &&
                    ` ${t("age")} : ${Age(patient?.dateOfBirth)}`}
                  {patient?.height &&
                    ` ${t("height")} : ${patient?.height || ""}`}
                </div>
                <div style={{ paddingLeft: 20 }}>
                  <span>{homeAddress}</span>
                </div>
                <div style={{ paddingLeft: 20 }}>
                  {patient?.mobile &&
                    ` ${t("contactNumber")} : ${patient?.mobile || ""}`}
                </div>
              </td>
              <td
                width="30%"
                colSpan={2}
                className="text-table-top-management t-start"
              >
                Note :
              </td>
            </tr>

            <tr className="border-interface-lab">
              <td width="5%" className="border-interface-lab-right"></td>
              <td width="20%" className="text-table-top-management t-start">
                J121 ขอคู่มือการแปลผล + Card
              </td>
            </tr>

            <tr className="border-interface-lab">
              <td colSpan={5} className="t-blue-theme t-center">
                Payer : 10L624 (2800001377) Absolute Health Clinic (C2F/LG){" "}
              </td>
            </tr>

            {labInterface?.groupTwo?.map((item, index) => {
              return (
                <React.Fragment key={index}>
                  <tr>
                    <td
                      rowSpan={2}
                      width="5%"
                      className="border-interface-lab-right border-interface-lab-left border-interface-lab-bottom t-center"
                    >
                      <Checkbox
                        disabled={
                          !visible
                            ? true
                            : item?.disabled
                            ? typeof item?.isTestCode === "boolean"
                              ? !item?.isTestCode
                              : !item?.disabled
                            : !item?.disabled
                        }
                        key={item?.id}
                        value={item?.id}
                      />
                    </td>
                    <td className="border-interface-lab-right border-interface-lab-bottom">
                      {item?.name}
                    </td>

                    <td
                      colSpan={2}
                      className="border-interface-lab-right border-interface-lab-bottom"
                    >
                      {item?.nameProduct}
                    </td>
                    {index === 0 && (
                      <td
                        rowSpan={16}
                        className="border-interface-lab-right border-interface-lab-bottom"
                      ></td>
                    )}
                  </tr>

                  <tr>
                    <td className="border-interface-lab-right border-interface-lab-bottom text-table-top-management">
                      <Row className="border-interface-lab-right  border-interface-lab-left border-interface-lab-top">
                        {item?.color?.map((colors, indexColors) => {
                          return (
                            <Col
                              className={
                                indexColors === 0
                                  ? "  border-interface-lab-right t-center  border-interface-lab-bottom"
                                  : (indexColors + 1) % 4 === 0
                                  ? "    t-center  border-interface-lab-bottom"
                                  : "  border-interface-lab-right t-center border-interface-lab-bottom"
                              }
                              span={6}
                              key={indexColors}
                              style={{ backgroundColor: colors?.color }}
                            >
                              {colors?.name}
                            </Col>
                          );
                        })}
                      </Row>
                    </td>

                    <td
                      colSpan={2}
                      className="border-interface-lab-right border-interface-lab-bottom"
                    >
                      {item?.testCode}
                    </td>
                  </tr>
                </React.Fragment>
              );
            })}
            <tr>
              <td
                colSpan={5}
                className="border-interface-lab-right border-interface-lab-left border-interface-lab-bottom"
              >
                OTHER :
                {` ${
                  !!labInfoList
                    ? labInfoList?.other2
                        ?.flatMap((children1) =>
                          children1?.children?.flatMap((children2) =>
                            children2?.children?.flatMap(
                              (children3) => children3?.product?.nameTh
                            )
                          )
                        )
                        ?.join(" , ")
                        ?.trim() || ""
                    : draftOrderList?.result?.[0]?.labInterface?.other2
                        ?.flatMap((children1) =>
                          children1?.children?.flatMap((children2) =>
                            children2?.children?.flatMap(
                              (children3) => children3?.product?.nameTh
                            )
                          )
                        )
                        ?.join(" , ")
                        ?.trim() || ""
                } `}
              </td>
            </tr>
          </tbody>
        </table>
      </CheckboxGroup>
    </div>
  );
};

export default LabInterfaceGroupTwo;
