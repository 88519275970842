import React, { useEffect, useState } from "react";
import { IItem } from "@interface/IOrder.interface";
import { randomString } from "@tools/util";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "../notification/Notification";
import { IValueSelectedRowKey } from "@interface/IDoctorRoom.interface";
import { Button, Divider, Modal } from "antd";
import { useCreateNewAndProducts } from "@services/api/draft_order";
import { useUpdateWorkOrderAddProducts } from "@services/api/work_order";
import { useCreateDraftBillAddProducts } from "@services/api/draft_bill";
import { ENUM_ROOM_SIGNATURE, EVENT_ROOM } from "@tools/util.enum";
import Fields from "./Fields";
import BigNumber from "bignumber.js";

interface IAddProducts {
  productId?: string | number | any;
  id?: string | number | any;
  qty: number | string;
  type: string;
}

type Props = {
  orderId?: number;
  patientID?: string | number;
  visible: boolean;
  onModal: () => void;
  status?: string;
  type?: string;
  orderList?: any;
  eventId?: number;
  room?: ENUM_ROOM_SIGNATURE;
};

const ProductCategory = ({
  orderId,
  patientID,
  visible,
  onModal,
  status,
  type,
  orderList,
  eventId,
  room,
}: Props) => {
  const { t } = useTranslation();
  const createNewAndProducts = useCreateNewAndProducts();
  const [selectedRowKeys, setSelectedRowKeys] = useState<
    IValueSelectedRowKey[]
  >([
    { key: [], dataList: [] },
    { key: [], dataList: [] },
    { key: [], dataList: [] },
    { key: [], dataList: [] },
    { key: [], dataList: [] },
    { key: [], dataList: [] },
  ]);

  const client = useQueryClient();
  const addProductDraftBill = useCreateDraftBillAddProducts();
  const updateWorkOrderAddProducts = useUpdateWorkOrderAddProducts();
  const match: any = useRouteMatch();
  const visitId = match.params.visitID;
  const appointmentId = match.params.appointmentsID;

  useEffect(() => {
    if (!orderList?.orderList?.key) return;
    if (status === "manage-courses") {
      setSelectedRowKeys(orderList?.orderList?.key);
    } else {
      if (orderList?.orderList?.key?.length > 0) {
        setSelectedRowKeys(orderList?.orderList?.key);
      }
    }

    // eslint-disable-next-line
  }, [orderList?.orderList]);

  const onConfirm = () => {
    const productList = selectedRowKeys?.flatMap((product) => {
      return product?.dataList;
    });

    const addProducts = productList?.map((product: IItem) => {
      if (
        status === "add-product-draft-bill" ||
        status === "lab-other-two" ||
        status === "lab-other-one"
      ) {
        return {
          productId: product?.id,
          qty: product?.qty || 0,
          type: type || "",
        };
      } else {
        return { id: product?.id, qty: product?.qty || 0, type: type || "" };
      }
    });

    if (
      status === "Drug & Supplement" ||
      status === "Drug & Supplement & Medical supplies" ||
      status === "lab" ||
      status === EVENT_ROOM.NURSE ||
      status === EVENT_ROOM.PHYSIOTHERAPY
    ) {
      handleUpdateMedicineAddProduct(addProducts);
    } else if (
      status === "add-product-draft-bill" ||
      status === "lab-other-two" ||
      status === "lab-other-one"
    ) {
      createNewAndProductsDraftBill(addProducts);
    } else if (status === "coupon" || status === "manage-courses") {
      const order = selectedRowKeys
        ?.map((item) => {
          return item?.dataList?.map((list) => {
            return list;
          });
        })
        ?.flat(Infinity);

      orderList?.setOrderList({
        ...orderList,
        orderList,
        key: selectedRowKeys,
        order: order?.filter((item) => {
          return item !== undefined;
        }),
      });
      onModal();
    } else if (status === "promotion") {
      const order = selectedRowKeys
        ?.map((item) => {
          return item?.dataList?.map((list) => {
            return list;
          });
        })
        ?.flat(Infinity);

      const addProduct = [
        ...(order?.filter((item) => {
          return item !== undefined;
        }) || []),
        ...(orderList?.orderList?.order || []),
      ]?.reduce((result: any, current: IItem) => {
        if (result?.some((item: IItem) => item?.id === current?.id)) {
          return result?.map((itemProduct: IItem) => {
            if (itemProduct?.id === current?.id) {
              return {
                ...itemProduct,
                qty: Number(
                  new BigNumber(itemProduct?.qty || 0).plus(current?.qty || 0)
                ),
              };
            }
            return itemProduct;
          });
        }

        return [...result, current];
      }, []);

      orderList?.setOrderList({
        order: addProduct,
      });
      onModal();
    } else if (status === "out-type") {
      const order = selectedRowKeys
        ?.map((item) => {
          return item?.dataList?.map((list) => {
            return list;
          });
        })
        ?.flat(Infinity);

      const addProduct = [
        ...(order?.filter((item) => {
          return item !== undefined;
        }) || []),
        ...(orderList?.orderList?.order || []),
      ]?.reduce((result: any, current: IItem, index) => {
        if (
          result?.some(
            (item: IItem) =>
              item?.id === current?.id &&
              current?.name !== "C-0006" &&
              current?.name !== "C-0007"
          )
        ) {
          return result?.map((itemProduct: IItem) => {
            if (itemProduct?.id === current?.id) {
              return {
                ...itemProduct,
                qty: Number(
                  new BigNumber(itemProduct?.qty || 0).plus(current?.qty || 0)
                ),
              };
            }
            return itemProduct;
          });
        }

        return [
          ...result,
          {
            ...current,
            key: `${current?.id}${new Date().getTime()}${index}${randomString(
              20
            )}`,
          },
        ];
      }, []);

      orderList?.setOrderList({
        order: addProduct,
      });
      onModal();
    } else {
      handleCreateNewAndProducts(addProducts);
    }
  };

  const handleCreateNewAndProducts = (addProducts: IAddProducts[]) => {
    const productIds = {
      productIds: addProducts,
      eventId: eventId || 0,
      room: room,
    };

    createNewAndProducts.mutate(
      { data: productIds, id: patientID },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          status === "Drug & Supplement" ||
          status === "Drug & Supplement & Medical supplies"
            ? client.invalidateQueries(["get-work-orders-medicine"])
            : client.invalidateQueries(["get-draft-order-list"]);
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
        onSettled: () => {
          client.invalidateQueries(["get-appointment-byID", appointmentId]);

          onModal();
        },
      }
    );
  };

  const createNewAndProductsDraftBill = (addProducts: IAddProducts[]) => {
    addProductDraftBill.mutate(
      {
        data: {
          orderId: orderId,
          productIds: addProducts,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
        },
        onError: () => {
          openNotification({ method: "POST_ERROR" });
        },
        onSettled: () => {
          client.invalidateQueries(["get-draft-bill-by-id"]);
          onModal();
        },
      }
    );
  };

  const handleUpdateMedicineAddProduct = (addProducts: IAddProducts[] | []) => {
    const productIds = { productIds: addProducts, room: room };
    updateWorkOrderAddProducts.mutate(
      { id: patientID, data: productIds },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          if (
            status === "Drug & Supplement" ||
            status === "Drug & Supplement & Medical supplies"
          ) {
            client.invalidateQueries(["get-work-orders-medicine"]);
          } else if (
            status === "lab" ||
            status === EVENT_ROOM.NURSE ||
            status === EVENT_ROOM.PHYSIOTHERAPY
          ) {
            client.invalidateQueries(["get-workOrders-out-standing"]);
          } else {
            client.invalidateQueries(["get-draft-order-list"]);
          }
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
        onSettled: () => {
          client.invalidateQueries(["get-visitsByID", visitId]);
          onModal();
        },
      }
    );
  };

  return (
    <Modal
      className="doctorroom-modal"
      title={
        <React.Fragment>
          <div className="t-blue">{`${t("productCategory")}`}</div>
        </React.Fragment>
      }
      centered
      visible={visible}
      onOk={onModal}
      onCancel={onModal}
      width="85%"
      forceRender={true}
      footer={null}
    >
      <Fields
        selectedRowKeys={selectedRowKeys}
        setSelectedRowKeys={setSelectedRowKeys}
        status={status}
        type={type}
      />

      <Divider className="mt-2" />
      <div className="button-end">
        <Button className="mr-05 cancel" htmlType="reset" onClick={onModal}>
          {t("cancel")}
        </Button>

        <Button
          className="btn-create"
          htmlType="submit"
          onClick={onConfirm}
          loading={
            addProductDraftBill?.isLoading ||
            updateWorkOrderAddProducts?.isLoading ||
            createNewAndProducts?.isLoading
          }
        >
          {t("confirm")}
        </Button>
      </div>
    </Modal>
  );
};

export default ProductCategory;
