import React, { useEffect, useState } from "react";
import { Form } from "antd";
import { dayjs } from "@tools/timezone";
import { IDateChange } from "@interface/ISlots.interface";
import { ISearchSlots } from "./interface";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useSetRecoilState } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { useCreateSlotBulk, useDeleteSlot } from "@services/api/slots";
import { useGetSlotDateList, useUpdateSlot } from "@services/api/slots";
import RoleContext from "@provider/RoleProvider";
import SchedulerFunctionAll from "@components/slots/function_all/FunctionAll";

type Props = {
  currentDateChange: IDateChange;
};

const Scheduler = ({ currentDateChange }: Props) => {
  const [viewSwitcher, setViewSwitcher] = useState<string>("Day");
  const setLoadingButton = useSetRecoilState<boolean>(loadingButtonState);
  const [searchValue, setSearchValue] = useState<ISearchSlots>(
    {} as ISearchSlots
  );
  const [formSearchSlotsAllFunction] = Form.useForm();
  const roleList = React.useContext(RoleContext);
  const updateSlot = useUpdateSlot();
  const slotDateList = useGetSlotDateList();
  const deleteSlot = useDeleteSlot();
  const createSlotBulk = useCreateSlotBulk();
  const client = useQueryClient();
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "QUE_PROCEDURE";
  });

  useEffect(() => {
    setLoadingButton(createSlotBulk.isLoading);

    // eslint-disable-next-line
  }, [createSlotBulk.isLoading]);

  useEffect(() => {
    handleGetSlotsList();

    // eslint-disable-next-line
  }, [searchValue, viewSwitcher, currentDateChange?.currentDateChange]);

  const handleGetSlotsList = async () => {
    const current = dayjs(currentDateChange?.currentDateChange);
    let startDate: string = current.startOf("day").format("YYYY-MM-DD");
    let endDate = current.endOf("day").format("YYYY-MM-DD");

    if (viewSwitcher === "Week") {
      startDate = current.startOf("week").format("YYYY-MM-DD");
      endDate = current.endOf("week").format("YYYY-MM-DD");
    } else if (viewSwitcher === "Month") {
      startDate = current.startOf("month").format("YYYY-MM-DD");
      endDate = current.endOf("month").format("YYYY-MM-DD");
    }

    slotDateList.mutate(
      {
        ...searchValue,
        slotType: "DOCTOR",
        startDate: startDate,
        endDate: endDate,
        limit: 0,
      },
      {
        onSuccess: () => {
          client.invalidateQueries(["get-slot-room"]);
        },
      }
    );
  };

  const changeCurrentDateChange = (currentDate: any) => {
    currentDateChange.setCurrentDateChange(
      dayjs(currentDate).format("YYYY-MM-DD")
    );
  };

  const CommitChanges = async ({ added, changed, deleted }: any) => {
    if (added) {
      handleCreateSlots(added);
    }
    if (changed) {
      handleUpdateSlots(changed);
    }
    if (deleted !== undefined) {
      handleDeleteSlots(deleted);
    }
  };

  const handleCreateSlots = async (added: any) => {
    const add = added?.data?.map((item: any) => {
      return {
        roomId: added?.roomId,
        userId: added?.userId,
        slotType: added?.slotType || "DOCTOR",
        minutesToNotify: added?.minutesToNotify,
        date: String(dayjs(item?.date).format("YYYY-MM-DD")),
        startTime: dayjs(item?.startDate).format("HH:mm:ssZ"),
        endTime: dayjs(item?.endDate).format("HH:mm:ssZ"),
      };
    });

    createSlotBulk.mutate(add, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        handleGetSlotsList();
      },
      onError: (res) => {
        openNotification({ method: "POST_ERROR", message: String(res) });
      },
    });
  };

  const handleUpdateSlots = async (changed: any) => {
    const add = {
      ...changed,
      startTime: dayjs(changed?.startDate).format("HH:mm:ssZ"),
      date: String(dayjs(changed?.startDate).format("YYYY-MM-DD")),
      endTime: dayjs(changed?.endDate).format("HH:mm:ssZ"),
    };
    updateSlot.mutate(
      { id: changed?.id, data: add },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          handleGetSlotsList();
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
    setLoadingButton(false);
  };

  const handleDeleteSlots = async (id: number) => {
    deleteSlot.mutate(id, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        handleGetSlotsList();
      },
      onError: (res) => {
        openNotification({ method: "DEL_ERROR", message: String(res) });
      },
    });
  };

  const onFinish = (values: ISearchSlots) => {
    formSearchSlotsAllFunction.setFieldsValue({
      ...values,
    });

    setSearchValue(values);
  };

  return (
    <div className="height-body scrollbar-list">
      <SchedulerFunctionAll
        viewSwitcher={viewSwitcher}
        setViewSwitcher={setViewSwitcher}
        CommitChanges={CommitChanges}
        dataScheduler={slotDateList?.data?.result?.[0]?.data || []}
        currentDateChange={currentDateChange?.currentDateChange}
        changeCurrentDateChange={changeCurrentDateChange}
        searchOnFinish={onFinish}
        formSearch={formSearchSlotsAllFunction}
        slotType="DOCTOR"
        status="medical-room-reservation"
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
    </div>
  );
};

export default Scheduler;
