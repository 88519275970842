import React, { useRef, useState } from "react";
import { dayjs } from "@tools/timezone";
import { IGetVisits } from "@interface/IVisits.interface";
import { useRouteMatch } from "react-router-dom";
import { dataUrlToFile } from "@tools/util";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useCreateMedia } from "@services/api/media_object";
import { openNotification } from "@components/notification/Notification";
import { IGetWorkOrderItem } from "@interface/IWorkOrder.interface";
import { ORDER_STATUS, ORDER_TYPE } from "@tools/util.enum";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useUpdateWorkOrderItemById } from "@services/api/work_order";
import { ENUM_G6PD, ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { useUpdateWorkOrderCourseItemById } from "@services/api/work_order";
import { Button, Card, Col, Form, FormInstance, Row } from "antd";
import Advice from "@components/advice/Advice";
import OrderList from "../order_list/OrderList";
import SymbolView from "@components/symbol/Symbol";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import ProductCategory from "@components/product_category/ProductCategory";

type Props = {
  visitsByID?: IGetVisits;
  patientList: FormInstance;
  ordersList?: IGetWorkOrderItem;
};

const Fields = ({ visitsByID, patientList, ordersList }: Props) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState({ oneDay: false, continuous: false });
  const [open, setOpen] = useState({ oneDay: true, continuous: true });
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const stageRef = useRef<any>(null);
  const [workOrderID, setWorkOrderID] = useState<number>();
  const client = useQueryClient();
  const updateWorkOrderItemById = useUpdateWorkOrderItemById();
  const updateWorkOrderCourseItemById = useUpdateWorkOrderCourseItemById();
  const createMedia = useCreateMedia();

  const handleCreateMedia = (data: any) => {
    if (stageRef?.current) {
      let imageData =
        stageRef?.current?.canvas?.drawing?.toDataURL("image/png");
      const file = dataUrlToFile(imageData, "signature.png");
      if (file) {
        const formData = new FormData();
        formData?.append("file", file);
        createMedia.mutate(formData, {
          onSuccess: (res) => {
            if (
              data?.categoryId === "C-0006" ||
              data?.categoryId === "C-0007"
            ) {
              handleUpdateWorkOrderItemCourse({
                ...data,
                signatureId: res?.result?.[0]?.id,
              });
            } else
              handleUpdateWorkOrderItem({
                ...data,
                signatureId: res?.result?.[0]?.id,
              });
          },
          onError: (error) => {
            openNotification({ method: "POST_ERROR", message: String(error) });
          },
        });
      }
    } else {
      if (data?.categoryId === "C-0006" || data?.categoryId === "C-0007") {
        handleUpdateWorkOrderItemCourse(data);
      } else handleUpdateWorkOrderItem(data);
    }
  };

  const handleUpdateWorkOrderItem = (data: any) => {
    updateWorkOrderItemById.mutate(
      {
        data: {
          ...data,
          remark: data?.remark || "",
          use: data?.use || false,
        },
        id: workOrderID,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-workOrders-out-standing"]);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const handleUpdateWorkOrderItemCourse = (data: any) => {
    updateWorkOrderCourseItemById.mutate(
      {
        data: {
          ...data,
          status: ORDER_STATUS.PARTIAL,
          remark: data?.remark || "",
          use: data?.use || false,
        },
        id: workOrderID,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-workOrders-out-standing"]);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const viewDetailOneDay = () => {
    setOpen({ ...open, oneDay: !open.oneDay });
  };
  const viewDetailContinuous = () => {
    setOpen({ ...open, continuous: !open.continuous });
  };

  const onManageModal = (key: "oneDay" | "continuous") => {
    setVisible({
      ...visible,
      [key]: !visible?.[key],
    });
  };

  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room "
        extra={
          <>
            <Row gutter={[14, 14]} align="middle">
              <Col>
                <ImagePatient src={visitsByID?.patient?.image} />
              </Col>
              <Col>
                <Row align="middle">
                  <Col xs={24} lg={24}>
                    {i18n.language === "th"
                      ? ` ${visitsByID?.patient?.firstNameTh || " "}    ${
                          visitsByID?.patient?.lastNameTh || " "
                        }`
                      : ` ${visitsByID?.patient?.firstNameEn || " "}    ${
                          visitsByID?.patient?.lastNameEn || " "
                        }`}
                  </Col>

                  <Col xs={24} lg={24}>
                    <div className="code-profile">
                      HN : {visitsByID?.patient?.hn}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginLeft: "auto" }}>
                <Row justify="end" align="middle" gutter={[24, 14]}>
                  <Col>
                    <div className="t-center ">
                      <div className=" t-grey fs-18 ">{t("orderNumber")} </div>
                      <div className=" t-grey-light fs-12 ">
                        {visitsByID?.orderNo || ""}{" "}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        }
      >
        <Row>
          <Col>
            <div className="mb-05">
              <span className=" t-blue fs-18 ">{t("fullList")} </span>
              <span className=" t-grey-light  fs-12 ">
                {`${t("orderCreationDate")}:`}{" "}
              </span>
              <span className="t-grey fs-12 ">
                {" "}
                {visitsByID
                  ? `${dayjs(visitsByID.date).format("DD-MM-YYYY")}`
                  : ``}
              </span>
            </div>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <div style={{ color: "#FEB139" }}>
              <SymbolView />
            </div>
            <div
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {visitsByID?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                ? "G6PD Deficiency"
                : null}
            </div>
          </Col>
        </Row>

        <Form name="patientList" form={patientList}>
          <ImportantInfo />
        </Form>
        <Advice visitsByID={visitsByID} />

        <div className="mt-2 ">
          <Row className=" header-table" align="middle">
            <Col className="f-normal  t-dark-blue  ">
              <span className="f-bold mr-05 ">
                {`${t("listExecutedOrders")} One Day`}
              </span>
              <span>
                {React.createElement(open.oneDay ? UpOutlined : DownOutlined, {
                  onClick: viewDetailOneDay,
                })}
              </span>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Button
                className="btn-print"
                type="primary"
                onClick={() =>
                  setVisible({
                    ...visible,
                    oneDay: !visible.oneDay,
                  })
                }
              >
                {`+ ${t("addOrder")}`}
              </Button>
            </Col>
          </Row>

          <div className={open.oneDay ? "open" : "close"}>
            <OrderList
              orderList={ordersList?.one?.groups || []}
              stageRef={stageRef || undefined}
              handleUpdateWorkOrderItem={handleCreateMedia}
              setWorkOrderID={setWorkOrderID}
            />
          </div>
        </div>

        <div className="mt-2">
          <Row className="header-table" align="middle">
            <Col className="f-normal t-dark-blue">
              <span className="f-bold mr-05 ">
                {`${t("listExecutedOrders")} Continuous`}
              </span>
              <span>
                {React.createElement(
                  open.continuous ? UpOutlined : DownOutlined,
                  {
                    onClick: viewDetailContinuous,
                  }
                )}
              </span>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Button
                className="btn-print"
                type="primary"
                onClick={() =>
                  setVisible({
                    ...visible,
                    continuous: !visible.continuous,
                  })
                }
              >
                {`+ ${t("addOrder")}`}
              </Button>
            </Col>
          </Row>

          <div className={open.continuous ? "open" : "close"}>
            <OrderList
              orderList={ordersList?.continuous?.groups || []}
              stageRef={stageRef || undefined}
              handleUpdateWorkOrderItem={handleCreateMedia}
              setWorkOrderID={setWorkOrderID}
            />
          </div>
        </div>
      </Card>

      {visible.oneDay && (
        <ProductCategory
          patientID={visitID}
          visible={visible.oneDay}
          onModal={() => onManageModal("oneDay")}
          status="lab"
          type={ORDER_TYPE.ONE_DAY}
          room={ENUM_ROOM_SIGNATURE.LAB_ROOM}
        />
      )}
      {visible.continuous && (
        <ProductCategory
          patientID={visitID}
          visible={visible.continuous}
          onModal={() => onManageModal("continuous")}
          status="lab"
          type={ORDER_TYPE.CONTINUE}
          room={ENUM_ROOM_SIGNATURE.LAB_ROOM}
        />
      )}
    </>
  );
};

export default Fields;
