import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useHandleGetVisitReportDrug } from "@services/api/visits";
import BackHome from "@components/button/BackHome";
import MedicalReport from "@components/medical_report/MedicalReport";
import ModalLabel from "./print/ModalLabel";

const ReportDrugQ = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const [searchData, setSearchData] = useState();
  const [visibleModalPrintLabel, setVisibleModalPrintLabel] = useState<boolean>(false);
  const drugList = useHandleGetVisitReportDrug(patientID, searchData);
  const pageStyle = `  @page { size:297mm 210mm;
    margin: 20px;}`;
  const printReportRef = useRef<HTMLHeadingElement>(null);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("dispenseMedicine.title")}</h1>
              </div>
              <span className="t-light-gray">
                {t("nursery")} / {t("reportDrug.title")} /
              </span>
              <span className="t-dark-blue f-bold">{t("report")}</span>
            </Col>
          </Row>
        </Col>
        {drugList ? (
          <Col lg={10} className="d-flex justify-between justify-col">
            <Button
              className="mr-05 reserve"
              htmlType="reset"
              onClick={() =>
                !!drugList?.data?.result?.length && setVisibleModalPrintLabel(true)
              }
            >
              {t("printLabel")}
            </Button>
            <Button
              className="mr-05 reserve"
              htmlType="reset"
              onClick={() => handlePrint()}
            >
              {t("printReport")}
            </Button>
          </Col>
        ) : (
          <></>
        )}
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("dispensingReport")}</b>}
        >
          <MedicalReport
            setSearchData={setSearchData}
            drugList={drugList?.data?.result?.[0]}
            printReportRef={printReportRef}
          />
        </Card>
      </div>
      {drugList?.data?.result?.length && <ModalLabel visible={visibleModalPrintLabel} setVisible={setVisibleModalPrintLabel} value={drugList?.data?.result?.[0]} />}
    </div>
  );
};

export default ReportDrugQ;
