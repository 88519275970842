import { IItem } from "@interface/IOrder.interface";
import { useForm } from "antd/lib/form/Form";
import { RefObject } from "@interface/IGlobal.interface";
import { productName } from "@tools/util";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { Form, Input, Popover } from "antd";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useUpdateWorkOrdersEditProduct } from "@services/api/work_order";
import { useDeleteWorkOrdersEditProduct } from "@services/api/work_order";
import { ENUM_ROLE_EN, ENUM_SERVICE_GROUP } from "@tools/util.enum";
import React from "react";
import moment from "moment";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import EditModal from "@components/modaler/EditModal";
import ModalInfo from "./ModalInfo";
import UserContext from "@provider/UserProvider";

type Props = {
  orderList: ISelectedExecuteOrder[];
  handleUpdateWorkOrderItem: (e: any) => void;
  stageRef: RefObject | undefined | any;
  setWorkOrderID: (e: number) => void;
  medicalRecord: boolean;
};

const OrderList = ({
  orderList,
  handleUpdateWorkOrderItem,
  stageRef,
  setWorkOrderID,
  medicalRecord,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [deleteForm] = useForm();
  const match: any = useRouteMatch();
  const visitId = match.params.visitID;
  const userList = React.useContext(UserContext);
  const [visible, setVisible] = useState(false);
  const [manageInfo] = Form.useForm();
  const [signature, setSignature] = useState<string>("");
  const num = useRef(0);
  const [editProduct, setEditProduct] = useState({
    id: "",
    addByCashier: false,
  });
  const [editOrderQty] = Form.useForm();
  const updateWorkOrders = useUpdateWorkOrdersEditProduct();
  const client = useQueryClient();
  const delWorkOrders = useDeleteWorkOrdersEditProduct();
  const [updateAndDelete, setUpdateAndDelete] = useState({
    update: false,
    delete: false,
  });

  const checkNurse = (id?: number) => {
    return !!id;
  };

  const onClickRow = (event: IItem) => {
    const option = event.option ? JSON.parse(event.option || `{}`) : {};
    const optionList =
      event?.product?.group?.group === ENUM_SERVICE_GROUP.MEDICAL_PROCEDURE
        ? {
            dose: option?.dose || event?.qty,
            fluid: option.fluid,
            start_time: option.start_time
              ? moment(option.start_time, "HH:mm:ssZ")
              : undefined,
            end_time: option.end_time
              ? moment(option.end_time, "HH:mm:ssZ")
              : undefined,
          }
        : event?.product?.group?.group === ENUM_SERVICE_GROUP.PHYSICAL_THERAPY
        ? {
            dose: option?.dose || event?.qty,
            location: option.location,
            time: option.time ? moment(option.time, "HH:mm:ssZ") : undefined,
          }
        : event?.product?.group?.group === ENUM_SERVICE_GROUP.MEDICINE
        ? {
            time: option.time ? moment(option.time, "HH:mm:ssZ") : undefined,
          }
        : {
            time: option.time ? moment(option.time, "HH:mm:ssZ") : undefined,
          };
    setWorkOrderID(event.id);
    setSignature(event?.signature || "");
    let qtyOutstanding =
      Number(event?.qtyOutstanding || event?.qtyOutstandingCourse || 0) +
      Number(event?.qty || 0);

    manageInfo.resetFields();
    manageInfo.setFieldsValue({
      ...option,
      ...event,
      success: event.success,
      categoryId: event?.product?.categoryId || event?.categoryId,
      useData: event.use,
      productName: event?.product?.nameTh || "",
      nameGroup: event?.name,
      description: i18n.language
        ? option?.productDescription || event?.product?.propertiesTh
        : option?.productDescription || event?.product?.propertiesEn,
      qty: event.qty || "",
      remark: option?.remark || event?.remark || "",
      group: event?.product?.group?.nameTh || "",
      groupId: event?.product?.group?.group,
      ...optionList,
      qtyOutstanding: qtyOutstanding,
      nurseId: checkNurse(event?.nurse?.id || event?.nurseId || undefined)
        ? event?.nurse?.id || event?.nurseId || undefined
        : userList?.role === ENUM_ROLE_EN.NURSE
        ? userList?.id || undefined
        : undefined,
    });
    setVisible(!visible);
  };

  const onModal = (key: "delete" | "update") => {
    setUpdateAndDelete({ ...updateAndDelete, [key]: !updateAndDelete?.[key] });
  };

  const onDelete = (value?: { remark: string }) => {
    delWorkOrders.mutate(
      {
        data: {
          uuids: [editProduct?.id],
          room: ENUM_ROOM_SIGNATURE.PHYSICAL_THERAPY_ROOM,
          ...value,
        },
        id: visitId,
      },
      {
        onSuccess: () => {
          client.invalidateQueries(["get-workOrders-out-standing"]);
          onModal("delete");
        },
        onError: () => {
          onModal("delete");
        },
      }
    );
  };

  const updateOrderItem = (data: any) => {
    updateWorkOrders.mutate(
      {
        data: {
          uuids: [{ uuid: editProduct?.id, qty: Number(data?.qty) }],
          room: ENUM_ROOM_SIGNATURE.PHYSICAL_THERAPY_ROOM,
        },
        id: visitId,
      },
      {
        onSuccess: () => {
          client.invalidateQueries(["get-workOrders-out-standing"]);
          onModal("update");
        },
        onError: () => {},
      }
    );
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      width: "7%",
      render: (_: string, data: IItem, index: number) => {
        return <div key={data?.key}>{data?.level === 1 && index + 1}</div>;
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? productName(
                  i18n.language,
                  data?.children?.[0]?.children?.[0]?.product?.category?.nameTh,
                  data?.children?.[0]?.children?.[0]?.product?.category?.nameEn
                )
              : data?.level === 2
              ? t("nameList")
              : data?.level === 3
              ? productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )
              : data?.level === 4
              ? t("nameList")
              : data?.level === 5
              ? productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )
              : productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ``
              : data?.level === 2 || data?.level === 4
              ? t("type")
              : data?.level === 3 || data?.level === 5
              ? productName(
                  i18n.language,
                  data?.product?.group?.nameTh,
                  data?.product?.group?.nameEn
                )
              : productName(
                  i18n.language,
                  data?.product?.group?.nameTh,
                  data?.product?.group?.nameEn
                )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "product",
      key: "product",

      render: (_: string, data: IItem) => {
        return (
          <>
            {data?.level === 1 ? (
              ``
            ) : data?.level === 2 ? (
              t("amount")
            ) : data?.level === 3 ? (
              data?.qty + "   "
            ) : data?.level === 4 ? (
              t("amount")
            ) : data?.level === 5 ? (
              data?.qty
            ) : (
              <div onClick={(e) => e.stopPropagation()}>
                {`${data?.qty} `}
                {data?.addByCashier && (
                  <EditOutlined
                    onClick={(e) => {
                      setEditProduct({
                        ...editProduct,
                        id: data?.uuid,
                      });
                      editOrderQty?.resetFields();
                      editOrderQty.setFieldsValue({
                        qty: data?.allQty || data?.qty,
                        oldQty: data?.allQty,
                      });
                      onModal("update");
                      e.stopPropagation();
                    }}
                  />
                )}
              </div>
            )}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ``
              : data?.level === 2
              ? `${t("amount")}/${t("totalNumber")}`
              : data?.level === 3 && !data?.children?.length
              ? `${data?.qty || 0} / ${
                  Number(data?.qtyOutstandingCourse) + Number(data?.qty)
                }`
              : data?.level === 4
              ? ``
              : data?.level === 5
              ? `${data?.qty || 0} / ${
                  Number(data?.qtyOutstandingCourse) + Number(data?.qty)
                }`
              : data?.qtyOutstanding
              ? `${data?.qty || 0} / ${data?.allQty}`
              : `${data?.qty || 0} / ${data?.allQty}`}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "remark",
      key: "name",
      render: (remark: string, data: IItem) => {
        const option = data?.option ? JSON.parse(data?.option || `{}`) : {};
        return (
          <div key={data?.key}>
            {data?.level === 1 ? (
              ``
            ) : data?.level === 2 ? (
              t("remarks")
            ) : (
              <Popover content={remark || option?.remark || "-"}>
                <div className="text-content">
                  {remark || option?.remark || "-"}
                </div>{" "}
              </Popover>
            )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_: string, data: IItem) => {
        let color;
        if (data?.status === 0) {
          color = "#00377C";
        } else if (data?.status === 1) {
          color = "#77C48B";
        }

        return (
          <div
            key={data?.key}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 ? (
              data?.status
            ) : data?.level === 3 ? (
              ``
            ) : data?.level === 4 ? (
              ``
            ) : (
              <div
                className="table-queue-status mr-1"
                style={{ backgroundColor: color }}
              >
                {data?.status === 0
                  ? t("waitingCheck")
                  : data?.status === 1 && t("finish")}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("numberOfItems"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_: string, data: IItem) => {
        let color;

        if (!data?.signature) {
          color = "#00377C";
        } else if (!!data?.signature) {
          color = "#77C48B";
        }

        return (
          <div
            key={data?.key}
            style={{ display: "flex", justifyContent: "center" }}
          >
            {data?.level === 1 ? (
              data?.children?.[0]?.children?.length
            ) : data?.level === 2 ? (
              t(`status`)
            ) : data?.level === 3 ? (
              ``
            ) : data?.level === 4 ? (
              ``
            ) : (
              <div
                className="table-queue-status mr-1"
                style={{ backgroundColor: color }}
              >
                {!data?.signature
                  ? t("waitingCheck")
                  : !!data?.signature && t("finish")}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "product",
      key: "product",
      align: "center",

      render: (_: string, data: IItem, index: number) => {
        return (
          <div
            key={index}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {data?.level === 1
              ? ""
              : data?.level === 2 || data?.level === 4
              ? t("delete")
              : !data?.level || data?.level === 3
              ? medicalRecord && (
                  <DeleteOutlined
                    onClick={(e) => {
                      deleteForm.resetFields();
                      setEditProduct({
                        ...editProduct,
                        addByCashier: data?.addByCashier,
                        id: data?.uuid,
                      });
                      onModal("delete");
                      e.stopPropagation();
                    }}
                  />
                )
              : null}
          </div>
        );
      },
    },
  ];

  const checkRow = (e: IItem) => {
    if (e.uuid && !e?.children && medicalRecord) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div>
      <EditModal
        loadingButton={updateWorkOrders?.isLoading}
        visible={updateAndDelete.update}
        onCancel={() => onModal("update")}
        editOrder={editOrderQty}
        width="25%"
        header={<div>{t("modifyTheNumberOfProducts")}</div>}
      >
        <Form
          onFinish={updateOrderItem}
          name="editOrderQty"
          form={editOrderQty}
        >
          <Form.Item
            label={t("numberOfProducts")}
            name="qty"
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("qty") > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`${t("theNumberOfProductsIsNotLessThan")} 0!`)
                  );
                },
              }),
            ]}
          >
            <Input type="number" className="modify-product" />
          </Form.Item>
        </Form>
      </EditModal>
      <Modaler
        loadingButton={delWorkOrders?.isLoading}
        visible={updateAndDelete.delete}
        onCancel={() => onModal("delete")}
        onOk={() => {
          editProduct?.addByCashier === false
            ? deleteForm.submit()
            : onDelete();
        }}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        <div>
          {t("doYouWantToDelete")}
          {editProduct?.addByCashier === false && (
            <Form name="deleteForm" form={deleteForm} onFinish={onDelete}>
              <Form.Item
                name="remark"
                label={t("reasonForDeletion")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Form>
          )}
        </div>
      </Modaler>
      <MoTable
        className="components-table-demo-nested table-selection table-confirm "
        rowClassName={(r) => {
          if (r?.level === 1) {
            return "no-header";
          } else if (r?.level === 2) {
            return "table-header no-header ";
          } else if (r?.level === 3 && r?.children) {
            return "no-header";
          } else if (r?.level === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "table-confirm-first table-confirm-level-course table-bottom--level-course-num";
            } else {
              return "table-confirm-first table-confirm-level-course ";
            }
          } else if (r?.level === 4) {
            num.current = r?.children?.length;
            return "table-header no-header table-confirm-level-course table-top-level-course ";
          }
          return "";
        }}
        columns={columns}
        expandRowByClick={true}
        showSorterTooltip={true}
        defaultExpandedRowKeys={["children1", "children2"]}
        rowKey={"key"}
        dataSource={orderList}
        pagination={false}
        onClickRow={(e: IItem) => checkRow(e) && onClickRow(e)}
        scroll={{ x: "calc(100vw -20%)" }}
      />

      {visible && (
        <ModalInfo
          manageInfor={manageInfo}
          handleUpdateWorkOrderItem={handleUpdateWorkOrderItem}
          stageRef={stageRef}
          signature={{ signature: signature, setSignature: setSignature }}
          visible={{
            visible: visible,
            setVisible: setVisible,
          }}
        />
      )}
    </div>
  );
};

export default OrderList;
