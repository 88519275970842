import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Card } from "antd";
import TableExecuteOrder from "./Table";
import SearchExecuteOrder from "./Search";

const ExecuteOrder = () => {
  const [searchData, setSearchData] = useState();
  const { t } = useTranslation();
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("physicianAssistant")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{`${t("executeorderOrder.title")}${t("nursery")}`}</b>
          </span>
        </Col>
        <Col lg={2} className="justify-col"></Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("allNursery")}</b>}
        >
          <SearchExecuteOrder setSearchData={setSearchData} />
          <TableExecuteOrder searchData={searchData} />
        </Card>
      </div>
    </div>
  );
};

export default ExecuteOrder;
