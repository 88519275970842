import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { MANAGE_COURSES } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Search from "./Search";
import TableCourses from "./Table";
import RoleContext from "@provider/RoleProvider";
import React from "react";
import { ENUM_MENU } from "@tools/util.enum";

const ManageCourses = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === ENUM_MENU.COURSE_MANAGEMENT;
  });

  const onFinish = (value: any) => {
    setSearch(value);
    setCurrentPage(1);
  };

  const handleManagePage = () => {
    history.push(`${MANAGE_COURSES.create}`);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("manageCourses.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("courseList")}</b>
          </span>
        </Col>
        {medicalRecord?.[0]?.isEdit && (
          <Col lg={2} className="justify-col">
            <Button
              className="btn-create "
              type="primary"
              onClick={handleManagePage}
            >
              <PlusOutlined className="icons-size" /> {t("createCourseList")}
            </Button>
          </Col>
        )}
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("courseList")}</b>}
        >
          <Search onFinish={onFinish} />
          <TableCourses
            search={search}
            currentPage={{
              currentPage: currentPage,
              setCurrentPage: setCurrentPage,
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default ManageCourses;
