import { Col, Row } from "antd";
import { IGetVisits } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { ISignature, IVisit } from "@interface/IGlobal.interface";
import Print from "./print/Print";

interface IOrderList {
  id: number;
  nameTh: string;
  nameEn: string;
  qty: number;
}

type Props = {
  printReportRef: any;
  orderList: IOrderList[];
  visitsByID: IGetVisits | undefined;
  visible: IVisit;
  setVisible: (e: IVisit) => void;
  signature: ISignature;
};

const Printreport = ({
  printReportRef,
  orderList,
  visitsByID,
  visible,
  setVisible,
  signature,
}: Props) => {
  const { i18n } = useTranslation();
  return (
    <div className="set-layout ">
      <div className="document-body ">
        <Row>
          <Col lg={24} className="display-center ">
            <div className="document1">
              <Row>
                <Col lg={24} className="display-center ">
                  <Print
                    ref={printReportRef}
                    orderList={orderList}
                    visitsByID={visitsByID}
                    visible={visible}
                    setVisible={setVisible}
                    signature={signature}
                    i18n={i18n.language}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Printreport;
