import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { INCHART } from "@assets/data/pathRouter";
import { IGetVisits } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { useGetVisitList } from "@services/api/visits";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ENUM_ORDERBY, ENUM_SERVICE_GROUP, ROOM_ENUM } from "@tools/util.enum";
import MoTable from "@components/table/Table";
import SearchInchartWait from "./Search";

const TableInchartWait = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const patientList = useGetVisitList({
    serviceGroups: [
      ENUM_SERVICE_GROUP.LAB,
      ENUM_SERVICE_GROUP.LAB_INTERFACE,
      ENUM_SERVICE_GROUP.MEDICAL_PROCEDURE,
    ],
    menu: ROOM_ENUM.INCHARGE,
    page: currentPage,
    limit: limitPage,
    name: searchValue?.name,
    gender: searchValue?.gender,
    mobile: searchValue?.mobile,
    orderby: ENUM_ORDERBY.ASC,
  });

  useEffect(() => {
    setTotal(patientList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [patientList?.data?.result?.[0]?.total]);

  const patientsFilter = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(
      `${INCHART.waitDetail}/patient/${event.patientId}/visit/${event.id}`
    );
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: number, data: IGetVisits, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "hn",
      width: "20%",
      sorter: (a: IGetVisits, b: IGetVisits) => a.id - b.id,
      render: (data: IGetPatientList) => {
        return (
          <div className="f-normal " key={data?.id}>
            {data?.hn}
          </div>
        );
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "name",
      sorter: (a: IGetPatientList, b: IGetPatientList) => a.id - b.id,
      render: (data: IGetPatientList) => {
        return (
          <div className="f-normal " key={data?.id}>
            {i18n.language === "th"
              ? [data?.firstNameTh, data?.lastNameTh]?.join(" ")?.trim()
              : [data?.firstNameEn, data?.lastNameEn]?.join(" ")?.trim()}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "patient",
      key: "gender",

      render: (data: IGetPatientList) => {
        let nameGender = gender(data?.gender, i18n?.language);

        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "patient",
      key: "dateOfBirth",
      width: "20%",
      render: (data: IGetPatientList) => {
        return <>{Age(data?.dateOfBirth)}</>;
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "patient",
      sorter: (a: IGetPatientList, b: IGetPatientList) => a.phone - b.phone,
      key: "phone",
      render: (data: IGetPatientList) => {
        return <>{data?.mobile}</>;
      },
    },
  ];

  return (
    <>
      <SearchInchartWait onFinish={patientsFilter} />
      <MoTable
        dataSource={patientList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default TableInchartWait;
