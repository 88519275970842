import { useHistory } from "react-router";
import { createOrder } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useUpdateVisits } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { useSetRecoilState } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { useEffect, useState } from "react";
import { useGetAppointmentByID } from "@services/api/appointments";
import { Button, Col, Form, Row } from "antd";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";
import PrintDraw from "@components/print_draw/PrintDraw";

const Comment = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [drawingCanvas, setDrawingCanvas] = useState({
    drawing: "",
    image: "",
  });
  const [printAndDrawForm] = Form.useForm();
  const setLoadingButton = useSetRecoilState<boolean>(loadingButtonState);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const patientID = match.params.patientID;
  const appointmentsID = match.params.appointmentsID;
  const appointmentByID = useGetAppointmentByID(
    appointmentsID && appointmentsID !== 0 ? false : true,
    appointmentsID
  );
  const updateVisits = useUpdateVisits();

  useEffect(() => {
    setDrawingCanvas({
      ...drawingCanvas,
      drawing:
        appointmentByID?.data?.result?.[0]?.visit?.suggestion?.drawing || "",
      image: appointmentByID?.data?.result?.[0]?.visit?.suggestion?.image || "",
    });
    printAndDrawForm.setFieldsValue({
      print: appointmentByID?.data?.result?.[0]?.visit?.suggestion?.print,
    });
    //eslint-disable-next-line
  }, [appointmentByID?.data]);

  const handleCreateDoctorRoomComment = (values: any) => {
    setLoadingButton(true);

    const formData = new FormData();

    formData?.append(
      "suggestion",
      JSON.stringify({ ...values, ...drawingCanvas })
    );

    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(
            `${createOrder}/appointments/${appointmentsID}/visit/${visitID}/patients/${patientID}`
          );
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("doctorroom")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-light-gray">{t("createOrder")} / </span>
              <span className="t-dark-blue f-normal">{t("doctorOpinion")}</span>
            </Col>
          </Row>
        </Col>

        <Col lg={4} className="d-flex justify-between justify-col">
          <Button
            className="mr-1 cancel"
            htmlType="reset"
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            htmlType="submit"
            onClick={() => printAndDrawForm.submit()}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
      <div className="set-layout ">
        <PrintDraw
          printAndDrawForm={printAndDrawForm}
          headerCard={t("doctorOpinion")}
          drawingCanvas={drawingCanvas}
          setDrawingCanvas={setDrawingCanvas}
          handleCreateAndUpdate={handleCreateDoctorRoomComment}
        />
      </div>
    </div>
  );
};

export default Comment;
