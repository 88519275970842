import React, { useState } from "react";
import TableOurBranch from "./Table";
import SearchOurBranch from "./Search";

const OurBranch = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <div>
      <SearchOurBranch
        setSearchValue={setSearchValue}
        setCurrentPage={setCurrentPage}
      />
      <TableOurBranch
        searchValue={searchValue}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </div>
  );
};

export default OurBranch;
