import { Card, Form } from "antd";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import React from "react";
import Fields from "./Fields";
import Header from "./Header";
import FieldsEdit from "./FieldsEdit";
import RoleContext from "@provider/RoleProvider";

const FormUser = () => {
  const { t } = useTranslation();
  const [formRole] = Form.useForm();
  const match: any = useRouteMatch();
  const roleID = match.params.id;
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "USER";
  });

  return (
    <div>
      <Header
        formRole={formRole}
        roleID={roleID}
        history={history}
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
      <div className="set-layout ">
        <Card
          className="card-prefix"
          extra={
            <b className="fs-16 t-black-light ">
              {roleID ? `${t("editRole")}` : `${t("createRole")}`}
            </b>
          }
        >
          {roleID ? (
            <FieldsEdit
              formRole={formRole}
              history={history}
              roleID={roleID}
              medicalRecord={medicalRecord?.[0]?.isEdit}
            />
          ) : (
            <Fields formRole={formRole} history={history} />
          )}
        </Card>
      </div>
    </div>
  );
};

export default FormUser;
