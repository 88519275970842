import React, { FC } from "react";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { usePatientUploadFile } from "@services/api/patient";
import { Button, Col, Divider, Form, Modal, Row } from "antd";
import FileSpace from "@components/file_space/FileSpace";

interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

type Props = {
  visible: IVisible;
};

const UploadFile: FC<Props> = ({ visible }) => {
  const { t } = useTranslation();
  const [uploadFileForm] = Form.useForm();
  const uploadFile = usePatientUploadFile();

  const getFileName = () => {
    return false;
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const modalActive = () => {
    visible.setVisible(!visible.visible);
  };

  const onFinish = (value: any) => {
    let formData = new FormData();
    formData?.append("file", value?.file?.[0]?.originFileObj);

    uploadFile.mutate(
      { data: formData },
      {
        onSuccess: (res) => {
          openNotification({ method: "POST_SUCCESS" });
          modalActive();
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  return (
    <Modal
      className="manage-modal"
      title={
        <React.Fragment>
          <Row>
            <Col md={20} lg={20} className="t-blue ">
              <b>{t("manageData")}</b>
            </Col>
            <Col md={4} lg={4} className="col-end"></Col>
          </Row>
        </React.Fragment>
      }
      centered
      visible={visible.visible}
      onOk={modalActive}
      onCancel={modalActive}
      width="90%"
      forceRender={true}
      footer={null}
    >
      <div className="scroll">
        <Form
          onFinish={onFinish}
          name="uploadFileForm"
          form={uploadFileForm}
          className="p-2"
        >
          <Row className="margin-form" justify="center" align="middle">
            <Col span={24}>
              <Form.Item
                label={t("file")}
                name="file"
                valuePropName="fileList"
                getValueFromEvent={getFile}
                rules={[{ required: true, message: t("pleaseSelect") }]}
              >
                <FileSpace
                  maxCount={1}
                  accept=".xlsx ,.xls ,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  beforeUpload={getFileName}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={modalActive}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => uploadFileForm.submit()}
                loading={uploadFile.isLoading}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default UploadFile;
