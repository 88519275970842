import { FC } from "react";
import { Row } from "antd";
import CanvasDraw from "react-canvas-draw";
import { useTranslation } from "react-i18next";

type Props = {
  stageRef: any;
  signature?: { signature?: string; setSignature?: (e: string) => void };
  drawnState?: {
    isDrawn: boolean;
    setIsDrawn: React.Dispatch<React.SetStateAction<boolean>> | undefined;
  };
};

const Signature: FC<Props> = ({ stageRef, signature, drawnState }) => {
  const { t } = useTranslation();
  const handleDrawingChange = (canvas: any) => {
    // Check if there is any drawing on the canvas
    const canvasHasDrawing = canvas.canvas.drawing;
    drawnState?.setIsDrawn &&
      drawnState.setIsDrawn(canvasHasDrawing ? true : false);
  };
  const handleClick = () => {
    stageRef?.current?.clear();
    signature?.setSignature && signature?.setSignature("");
    drawnState?.setIsDrawn && drawnState?.setIsDrawn(false);
  };
  return (
    <div>
      <div className="mb-1 ">
        <Row justify="space-between" className="w-100">
          <div className="t-grey-form f-normal "> {t("yourSignature")}</div>

          <div className="t-blue f-normal divonClick " onClick={handleClick}>
            {t("clear")}
          </div>
        </Row>
      </div>
      {signature?.signature ? (
        <img
          style={{ border: "1px solid #f0f0f0" }}
          src={signature?.signature}
          alt="signature"
        />
      ) : (
        <CanvasDraw
          ref={stageRef}
          lazyRadius={0}
          canvasHeight={250}
          catenaryColor={"black"}
          brushColor={"black"}
          brushRadius={1}
          hideGrid={true}
          onChange={handleDrawingChange}
          immediateLoading={false}
          style={{
            borderStyle: "solid",
            borderWidth: 1,
            borderColor: drawnState?.isDrawn ? "#d3ddea" : "red",
          }}
        />
      )}
    </div>
  );
};

export default Signature;
