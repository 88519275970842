import { IInvoice } from "@interface/IInvoice.interface";
import { useTranslation } from "react-i18next";
import { FormInstance, InputNumber, Table, TableProps } from "antd";
import bignumber from "bignumber.js";

interface Props extends TableProps<any> {
  value?: IInvoice[];
  onChange?: (event: any) => void;
  disabled: boolean;
  setTotalPrice: (e: number) => void;
  invoiceForm: FormInstance;
  medicalRecord: boolean;
}

const TableInput = ({
  value,
  setTotalPrice,
  invoiceForm,
  medicalRecord,
  ...props
}: Props) => {
  const { t } = useTranslation();

  const onDisabled = (category: string) => {
    if (medicalRecord) {
      if (props.disabled) {
        return true;
      } else {
        if (category === "C-0001" || category === "C-0006") {
          return false;
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  };
  const onUpdate = (e: number, id: string | number) => {
    let total = 0;
    const next = value?.map((item) => {
      if (item?.id === id) {
        total = total + Number(e);
        return { ...item, price: e || 0 };
      } else {
        const amount = !isNaN(Number(item?.price))
          ? Number(item?.price)
          : Number(item?.outstandingBalance || item?.total || 0);
        total = total + amount;
        return item;
      }
    });
    setTotalPrice(total);
    invoiceForm.setFieldsValue({ ...invoiceForm, price: total });

    props.onChange && props.onChange(next);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, __: IInvoice, index: number) => {
        return <div key={index}>{index + 1}</div>;
      },
    },

    {
      title: `${t("productName")} / ${t("details")}`,
      dataIndex: "name",
      key: "index",
      render: (name: string) => {
        return <div>{t(`${name}`)}</div>;
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",
      key: "index",
    },

    {
      title: t("totalPrice"),
      dataIndex: "total",
      render: (total: number, data: IInvoice) => {
        return <div>{new bignumber(total || 0).toFormat(2)}</div>;
      },
    },
    {
      title: t("outstandingBalance"),
      dataIndex: "outstandingBalance",
      render: (outstandingBalance: number, _: IInvoice) => {
        return (
          <div>
            {outstandingBalance
              ? new bignumber(outstandingBalance || 0).toFormat(2)
              : "-"}
          </div>
        );
      },
    },

    {
      title: t("splitPayment"),
      dataIndex: "total",
      width: "15%",
      render: (total: number, data: IInvoice) => {
        return (
          <InputNumber
            max={Number(data?.outstandingBalance || total)}
            min={0}
            value={
              data?.price === 0
                ? 0
                : Number(data?.price || data?.outstandingBalance)
            }
            onChange={(e: number) => onUpdate(e, data?.id)}
            disabled={onDisabled(data?.category)}
            type="number"
            className="modify-product"
          />
        );
      },
    },
  ];

  return (
    <div className="mt-1 mb-1 ">
      <Table
        className="components-table-demo-nested table-selection table-confirm "
        rowClassName={(r) => {
          if (r.id) {
            return " no-header no-cursor";
          }
          return "";
        }}
        columns={columns}
        dataSource={value || []}
        rowKey="id"
        scroll={{ x: 500 }}
        pagination={false}
      />
    </div>
  );
};

export default TableInput;
