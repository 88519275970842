import { dayjs } from "@tools/timezone";
import { IVisitBill } from "@interface/IGlobal.interface";
import { _amountToWordsEN, productName } from "@tools/util";
import { heightTopPrint } from "@tools/heightTopPrint";
import { useTranslation } from "react-i18next";
import { IRejectOrderGroups } from "@interface/IOrder.interface";
import { IRejectCourseReport } from "@interface/IOrder.interface";
import { FC, MutableRefObject } from "react";
import { Button, Col, Divider, Row, Table } from "antd";
import React from "react";
import Decimal from "@tools/decimal";
import bignumber from "bignumber.js";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";

type Props = {
  refData: MutableRefObject<null>;
  divHeight: number | undefined;
  rejectCourseByID: IRejectCourseReport | undefined;
  visible: IVisitBill;
  setVisible: (e: IVisitBill) => void;
};

const Print: FC<Props> = ({
  refData,
  divHeight,
  rejectCourseByID,
  visible,
  setVisible,
}) => {
  const { t, i18n } = useTranslation();
  const userList = React.useContext(UserContext);
  const THBText = i18n.language === "th"
    ? require("thai-baht-text")
    : _amountToWordsEN;
  const nameTH = [
    rejectCourseByID?.receiptRejectOrder?.[0]?.order?.patient?.firstNameTh,
    rejectCourseByID?.receiptRejectOrder?.[0]?.order?.patient?.lastNameTh,
  ]
    ?.join(" ")
    ?.trim();

  const nameEN = [
    rejectCourseByID?.receiptRejectOrder?.[0]?.order?.patient?.firstNameEn,
    rejectCourseByID?.receiptRejectOrder?.[0]?.order?.patient?.lastNameEn,
  ]
    ?.join(" ")
    ?.trim();

  const mailingAddress =
    rejectCourseByID?.receiptRejectOrder?.[0]?.order?.patient?.mailingAddress;
  const mailAddress =
    mailingAddress?.addressTh || mailingAddress?.districtTh
      ? [
        mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
        mailingAddress[
        i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
        ],
        mailingAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
        mailingAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
        mailingAddress?.country,
        mailingAddress?.zipcode,
      ]
        ?.join(" ")
        ?.trim()
      : "";
  const _homeAddress =
    rejectCourseByID?.receiptRejectOrder?.[0]?.order?.patient?.homeAddress;
  const homeAddress =
    _homeAddress?.addressTh || _homeAddress?.districtTh
      ? [
        _homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
        _homeAddress[
        i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
        ],
        _homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
        _homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
        _homeAddress?.country,
        _homeAddress?.zipcode,
      ]
        ?.join(" ")
        ?.trim()
      : "";

  const userTh = [userList?.firstNameTh, userList?.lastNameTh]
    ?.join(" ")
    ?.trim();
  const userEn = [userList?.firstNameEn, userList?.lastNameEn]
    ?.join(" ")
    ?.trim();

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "index",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? index + 1
              : data?.level === 2
                ? ""
                : data?.level === 3
                  ? ""
                  : index + 1}
          </div>
        );
      },
    },
    {
      title: `${t("productName")} / ${t("details")}`,
      dataIndex: "nameEn",
      key: "index",
      render: (_: any, data: any | IRejectOrderGroups) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? t(`${data?.name}`)
              : data?.level === 2
                ? t("productName")
                : data?.level === 3
                  ? productName(
                    i18n.language,
                    data?.item?.product?.nameTh,
                    data?.item?.product?.nameEn
                  )
                  : data?.name}
          </div>
        );
      },
    },
    {
      title: t("amount"),
      dataIndex: "qty",
      render: (qty: any, data: any | IRejectOrderGroups) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? data?.item?.qty
              : data?.level === 2
                ? t("numberOfNights")
                : data?.level === 3
                  ? data?.item?.qtyOutstanding
                  : qty}
          </div>
        );
      },
    },

    {
      title: t("totalPrice"),
      dataIndex: "total",
      render: (total: any, data: any | IRejectOrderGroups) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? Decimal(data?.item?.total)
              : data?.level === 2
                ? ""
                : data?.level === 3
                  ? ""
                  : Decimal(total)}
          </div>
        );
      },
    },
  ];

  return (
    <div ref={refData}>
      <div id="container">
        <table className="report-drug f-light  " width="100%">
          <tbody>
            <tr>
              <td
                style={{
                  width: "50vw",
                  fontWeight: "lighter",
                  textAlign: "start",
                }}
              >
                <div className="mb-1">
                  <ImageLogo
                    height="70px"
                    width="200px"
                    src={userList?.branch?.image}
                    alt="logo"
                  />
                </div>
                <div className="example">
                  <span className="break-inside ">
                    {
                      i18n.language === "th"
                        ? userList?.branch?.nameTh
                        : userList?.branch?.nameEn
                      // t("location.title")
                    }
                  </span>
                  <span className="break-inside ">
                    {userList?.branch?.addressTh}
                  </span>
                  <span className="break-inside ">
                    {[
                      userList?.branch?.[
                      i18n.language === "th"
                        ? "subDistrictTh"
                        : "subDistrictEn"
                      ],
                      userList?.branch?.[
                      i18n.language === "th" ? "districtTh" : "districtEn"
                      ],
                      userList?.branch?.[
                      i18n.language === "th" ? "provinceTh" : "provinceEn"
                      ],
                      userList?.branch?.zipcode,
                    ]
                      ?.join(" ")
                      ?.trim()}
                  </span>
                </div>
                <div>
                  <span>{t("tel")} :</span> {userList?.branch?.phone}
                </div>
                <div className="pt-1">
                  <span className="f-bold t-blue">{t("customer")} </span>
                </div>
                <div>
                  <span className="break-inside ">
                    {t("customerName")} : {nameTH}
                  </span>{" "}
                </div>
                <div>
                  <span className="break-inside ">
                    {t("address")} : {mailAddress || homeAddress || "-"}
                  </span>
                </div>

                <div>
                  <span className="break-inside ">
                    {t("zipCode")} :{" "}
                    {!!mailAddress
                      ? rejectCourseByID?.receiptRejectOrder?.[0]?.order
                        ?.patient?.mailingAddress?.zipcode || "-"
                      : rejectCourseByID?.receiptRejectOrder?.[0]?.order
                        ?.patient?.homeAddress?.zipcode || ""}
                  </span>
                </div>
              </td>

              <td
                style={{
                  width: "50vw",
                  fontWeight: "lighter",
                  textAlign: "start",
                }}
              >
                <div className="t-center fs-20 t-blue f-bold">
                  {t("returnReceiptCredit")}
                </div>
                <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                  <Divider className="m-0 b-grey-white" />
                </div>

                <Row>
                  <Col span="6">{t("number")}</Col>

                  <Col span="18">{rejectCourseByID?.receiptNumber || "-"}</Col>

                  <Col span="6"> {t("date")}</Col>

                  <Col span="18">
                    {rejectCourseByID?.date
                      ? dayjs(rejectCourseByID?.date).format("DD-MM-YYYY")
                      : "-"}
                  </Col>
                  <Col
                    span="24"
                    style={{ paddingTop: "15px", paddingBottom: "15px" }}
                  >
                    <Divider className="m-0 b-grey-white" />
                  </Col>

                  <Col span="6">{t("contact")}</Col>
                  <Col span="18">
                    {i18n.language === "th"
                      ? [userList?.firstNameTh, userList?.lastNameTh]
                        ?.join(" ")
                        ?.trim()
                      : [userList?.firstNameEn, userList?.lastNameEn]
                        ?.join(" ")
                        ?.trim()}
                  </Col>
                </Row>
              </td>
            </tr>

            <tr>
              <td colSpan={2} style={{ paddingTop: "19px" }}>
                <Table
                  rowKey="key"
                  className="components-table-demo-nested table-selection table-confirm  table-list-children report-pay no-cursor"
                  rowClassName={(r) => {
                    if (r?.level === 1) {
                      return " no-header ";
                    } else if (r?.level === 2) {
                      return "table-header no-header ";
                    } else if (r?.level === 3) {
                      return " no-header ";
                    } else if (r?.level === 5) {
                      return "table-confirm-first no-header  ";
                    } else if (r?.level === 4) {
                      return "table-header no-header  ";
                    }
                    return "";
                  }}
                  columns={columns}
                  dataSource={rejectCourseByID?.groups || []}
                  expandedRowKeys={rejectCourseByID?.allKey || []}
                  pagination={false}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="report-drug " width="100%">
                  <tbody>
                    <tr className="t-end">
                      <td>
                        <Row style={{ paddingTop: "30px" }}>
                          <Col span={12}>{t("totalMoney")}</Col>
                          <Col span={12}>
                            {new bignumber(
                              rejectCourseByID?.price || 0
                            ).toFormat(2)}
                          </Col>
                          <Col span={24}>
                            <Row
                              style={{
                                backgroundColor: "#498dcb",
                                color: "white",
                              }}
                            >
                              <Col span={12}>{t("theTotalAmount")}</Col>
                              <Col span={12}>
                                {new bignumber(
                                  rejectCourseByID?.price || 0
                                ).toFormat(2)}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <span style={{ backgroundColor: "#498dcb", color: "white" }}>
                  {`(${THBText(Number(rejectCourseByID?.price || 0))})
            `}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        style={{
          marginTop: `${heightTopPrint(divHeight)}px`,
        }}
        className="footer-bill"
      >
        <Row
          style={{ paddingTop: "80px" }}
          align="middle"
          className="break-inside"
        >
          <Col span={6} className="t-center pl-20 pt-10">
            <div className=" f-normal t-center  image-signature">
              {rejectCourseByID?.firstSignature && (
                <img
                  src={rejectCourseByID?.firstSignature || ""}
                  alt="first"
                  height={70}
                />
              )}
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div className=" f-normal t-center  date-signature">
              {rejectCourseByID?.date &&
                dayjs(rejectCourseByID.date).format("DD-MM-YYYY")}
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div className=" f-normal t-center  image-signature">
              {rejectCourseByID?.secondSignature && (
                <img
                  src={rejectCourseByID?.secondSignature || ""}
                  alt="second"
                  height={70}
                />
              )}
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div className=" f-normal t-center  date-signature">
              {rejectCourseByID?.date &&
                dayjs(rejectCourseByID?.date).format("DD-MM-YYYY")}
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 ">
            <div className="pb-30">
              <Divider className="m-0 b-grey-white" />
            </div>

            <Button
              className="pb-1 signature-button"
              onClick={(e) =>
                setVisible({
                  ...visible,
                  first: !visible.first,
                })
              }
            >
              {t("signHere")}
            </Button>
          </Col>
          <Col span={6} className="t-center pl-20">
            <div style={{ paddingBottom: "30px" }}>
              <Divider className="m-0 b-grey-white" />
            </div>
          </Col>
          <Col span={6} className="t-center pl-20">
            <div className="pb-30">
              <Divider className="m-0 b-grey-white" />
            </div>
            <Button
              className="pb-1 signature-button"
              onClick={(e) =>
                setVisible({
                  ...visible,
                  second: !visible.second,
                })
              }
            >
              {t("signHere")}
            </Button>
          </Col>
          <Col span={6} className="t-center pl-20">
            <div style={{ paddingBottom: "30px" }}>
              <Divider className="m-0 b-grey-white" />
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div
              style={{
                fontSize:
                  Number(
                    i18n.language === "th"
                      ? nameTH?.length || 0
                      : nameEN?.length || 0
                  ) < 22
                    ? 14
                    : 250 /
                    Number(
                      i18n.language === "th"
                        ? nameTH?.length || 0
                        : nameEN?.length || 0
                    ),
              }}
            >
              ({i18n.language === "th" ? nameTH : nameEN})
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            {t("date")}
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div
              style={{
                fontSize:
                  Number(
                    i18n.language === "th"
                      ? userTh?.length || 0
                      : userEn?.length || 0
                  ) < 22
                    ? 14
                    : 250 /
                    Number(
                      i18n.language === "th"
                        ? userTh?.length || 0
                        : userEn?.length || 0
                    ),
              }}
            >
              ({i18n.language === "th" ? userTh : userEn})
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            {t("date")}
          </Col>
          <Col span={6} className="t-center pl-20 ">
            <div>ผู้รับบริการ</div>
          </Col>
          <Col span={6} className="t-center pl-20 "></Col>
          <Col span={6} className="t-center pl-20 ">
            {`${t("location.title")}`}
          </Col>
          <Col span={6} className="t-center pl-20 "></Col>
        </Row>
      </div>
    </div>
  );
};

export default Print;
