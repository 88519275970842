import axios from "@axios/index";
import { IGetLabInfo } from "@interface/IWorkOrder.interface";
import { thrownResponse } from "./auth";
import { IGetCallbackLab } from "@interface/IWorkOrderITem.interface";
import { IRejectDrugFormat } from "@interface/IWorkOrderITem.interface";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { IWorkOrderItemDrug } from "@interface/IWorkOrderITem.interface";
import { Elementary, IParamet } from "@interface/IGlobal.interface";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";

export const useWorkOrderItemDrug = (
  id?: number,
  params?: any
): UseQueryResult<Elementary<IWorkOrderItemDrug[]>> => {
  return useQuery(["get-work-order-item-drugs", id, params], async () => {
    const res = await axios.get(`/work-order-item/${id}/drugs`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
  });
};

export const useWorkOrderItemRejectDrug = (
  id: number
): UseQueryResult<ElementaryOneMore<any>> => {
  return useQuery(["get-work-order-item-reject-drugs", id], async () => {
    const res = await axios.get(`/work-order-item/${id}/reject-drugs`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
  });
};

export const useWorkOrderItemRejectDrugFormat = (
  id: number
): UseQueryResult<IRejectDrugFormat[]> => {
  return useQuery(["get-work-order-item-reject-drugs-format", id], async () => {
    const res = await axios.get(`/work-order-item/${id}/reject-drugs`);

    if (res?.status >= 200 && res?.status < 300) {
      const parent = res?.data?.result?.[0]?.workOrderItems?.flatMap(
        (item: ISelectedExecuteOrder) => item?.children
      );
      return parent;
    } else if (res?.status >= 400 && res?.status < 500) {
      return [];
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
  });
};

export const useUpdateWorkOrderItemRejectDrugs = (): UseMutationResult => {
  return useMutation(
    ["update-work-order-item-reject-drugs"],
    async ({ data, id }: any) => {
      const res = await axios.put(`/work-order-item/reject-drugs/${id}`, data);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useUpdateWorkOrderItemRejectDrugsConfirm =
  (): UseMutationResult => {
    return useMutation(async ({ id, data }: any) => {
      const res = await axios.put(
        `/work-order-item/reject/confirm/${id}`,
        data
      );

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    });
  };

export const useUpdateWorkOrderItemRejectDrugsCancel =
  (): UseMutationResult => {
    return useMutation(async ({ id, data }: any) => {
      const res = await axios.put(
        `/work-order-item/cancel/reject-drugs/${id}`,
        data
      );

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    });
  };

export const useGetWorkOrderItemLab = (
  id?: number,
  params?: any
): UseQueryResult<Elementary<any>> => {
  return useQuery(["get-workOrderItem-lab", id], async () => {
    const res = await axios.get(`/work-order-item/${id}/lab`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
  });
};

export const useGetWorkOrderItemHistoryLab = (
  params: any
): UseQueryResult<Elementary<any>> => {
  return useQuery(
    ["get-workOrderItem-history-lab", params],
    async () => {
      const res = await axios.get(`/work-order-item/history-lab`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
    },
    { refetchOnWindowFocus: true }
  );
};

export const useGetLabInfo = (
  id: number
): UseQueryResult<IParamet<IGetLabInfo[]>> => {
  return useQuery(["get-lab-info", id], async () => {
    const res = await axios.get(`/work-order-item/${id}/lab-info`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
  });
};

export const useGetCallBackLab = (
  params?: any
): UseQueryResult<IParamet<IGetCallbackLab[]>> => {
  return useQuery(
    ["get-work-order-item-call-back-lab", params],
    async () => {
      const res = await axios.get(`/work-order-item/callback-lab`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
    },
    { refetchOnWindowFocus: true }
  );
};
