import { useHistory } from "react-router";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  confirm: FormInstance;
};

const Header = ({ confirm }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const loadingCreate = useIsMutating(["create-insurances"]);
  const { t } = useTranslation();

  return (
    <>
      <Row className="second-header" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("setting")}</h1>
              </div>
              <span className="t-light-gray">{t("generalInfo.title")} / </span>
              <span className="t-dark-blue f-normal">{t("create")}</span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button className="mr-05 cancel" onClick={history.goBack}>
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            onClick={() => confirm.submit()}
            loading={!!loadingCreate}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
