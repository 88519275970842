import { SETTING } from "@assets/data/pathRouter";
import { ColumnsType } from "antd/lib/table";
import { ISearchPrefix } from "@interface/ISearch.interface";
import { useTranslation } from "react-i18next";
import { IInsurancesList } from "@interface/IInsurances.interface";
import { useGetInsurancesList } from "@services/api/insurances";
import { useState, useEffect, FC } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  currentPage: { currentPage: number; setCurrentPage: (e: number) => void };
  search?: ISearchPrefix;
};

const TableContractCompany: FC<Props> = ({ currentPage, search }) => {
  const { t } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const insurancesList = useGetInsurancesList({
    limit: limitPage,
    page: currentPage.currentPage,
    search: search?.name,
  });

  useEffect(() => {
    setTotal(insurancesList?.data?.result?.[0]?.total || 1);

    // eslint-disable-next-line
  }, [insurancesList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage?.setCurrentPage(page);
  };

  const onClickRow = (e: IInsurancesList) => {
    history.push(`${SETTING.contractCompanyManage}/${e.id}`);
  };

  const columns: ColumnsType = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage?.currentPage - 1) + index + 1}
          </div>
        );
      },
    },
    {
      title: `${t("code")}`,
      dataIndex: "code",
      key: "code",
    },
    {
      title: `${t("name")} (TH)`,
      dataIndex: "nameTh",
      key: "nameTh",
    },

    {
      title: `${t("name")} (EN)`,
      dataIndex: "nameEn",
      key: "nameEn",
    },
  ];

  return (
    <>
      <MoTable
        dataSource={insurancesList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage?.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default TableContractCompany;
