import { IBranch } from "@interface/IBranch.interface";
import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetBranchList = (
  enabled: boolean,
  params?: any
): UseQueryResult<Elementary<IBranch[]>> => {
  return useQuery(
    ["get-branch", enabled, params],
    async () => {
      const res = await axios.get("/branch", { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: enabled }
  );
};

export const useGetBranchDropdownList = (): UseQueryResult<
  ElementaryOneMore<IBranch>
> => {
  return useQuery(["get-branch-dropdown"], async () => {
    const res = await axios.get("/branch/dropdown");

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetBranchById = (
  id: number | string
): UseQueryResult<ElementaryOneMore<IBranch>> => {
  return useQuery(
    ["get-branch-by-id", id],
    async () => {
      const res = await axios.get(`/branch/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !!id ? true : false }
  );
};

export const useCreateBranch = (): UseMutationResult => {
  return useMutation(["create-branch"], async (data: any) => {
    const res = await axios.post(`/branch`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateBranch = (): UseMutationResult => {
  return useMutation(["update-branch"], async ({ data, id }: any) => {
    const res = await axios.put(`/branch/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteBranch = (): UseMutationResult => {
  return useMutation(["delete-branch"], async (id: any) => {
    const res = await axios.delete(`/branch/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
