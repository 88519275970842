import { useEffect, useState } from "react";
import { Form } from "antd";
import { SETTING } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { useGetInsurancesById } from "@services/api/insurances";
import { useDeleteInsurancesByID } from "@services/api/insurances";
import { useUpdateInsurancesByID } from "@services/api/insurances";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";
import Modaler from "@components/modaler/Modaler";

const UpdateContractCompany = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [visible, setVisible] = useState<boolean>(false);
  const [contractCompanyForm] = Form.useForm();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const insurancesById = useGetInsurancesById(pathID);
  const update = useUpdateInsurancesByID();
  const deleteInsurances = useDeleteInsurancesByID();

  useEffect(() => {
    contractCompanyForm.setFieldsValue({
      ...insurancesById?.data?.result?.[0],
    });
    // eslint-disable-next-line
  }, [insurancesById?.data]);

  const onModal = () => {
    setVisible(!visible);
  };

  const onFinish = (value: any) => {
    update.mutate(
      {
        id: pathID,
        data: value,
      },
      {
        onSuccess: () => {
          history.replace(`${SETTING.title}`);
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
      }
    );
  };

  const onDelete = () => {
    deleteInsurances.mutate(pathID, {
      onSuccess: () => {
        history.replace(`${SETTING.title}`);
        openNotification({ method: "DEL_SUCCESS" });
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
    });
  };

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {t("doYouWantToDelete")}
      </Modaler>

      <Header
        history={history}
        contractCompanyForm={contractCompanyForm}
        onModal={onModal}
      />
      <Fields contractCompanyForm={contractCompanyForm} onFinish={onFinish} />
    </div>
  );
};

export default UpdateContractCompany;
