import { Doughnut } from "react-chartjs-2";
import { showTextInside } from "./showText";
import { useTranslation } from "react-i18next";
import { useMainDashboard } from "@provider/dashboard";
import { useMemo, useState } from "react";
import { Chart, ChartEvent, LegendElement, LegendItem } from "chart.js";
import numeral from "numeral";

const ChartDoughnut = () => {
  const pieDoughnutLegendClickHandler: any =
    Chart.overrides.doughnut.plugins.legend.onClick;
  const [valueDoughnut, setValueDoughnut] = useState(0);
  const { t } = useTranslation();
  const { consult } = useMainDashboard();
  const data = {
    labels: [t("doctor"), t("consultDoctor")],
    datasets: [
      {
        label: "# of Votes",
        data: [consult?.doctor || 0, consult?.consult || 0],
        backgroundColor: ["#004C97", "#77C48B"],
        borderColor: ["#004C97", "#77C48B"],
        // hoverBackgroundColor: ["#0070df", "#4cd871"],
        borderWidth: 0,
        cutout: "80%",
        // rotation: 1,
        spacing: 0,
        // offset: 1
        // rotation: 2,
        // circumference: 20
      },
    ],

    text: "45",
  };

  const options: any = {
    elements: {
      center: {
        text: `${numeral(valueDoughnut).format("0,0")}`,
        text1: t("total"),
        color: "#000000",
        align: "center",
      },
    },
    chart: {
      width: 200,
    },

    plugins: {
      title: {
        display: true,
        text: t("consultDoctor"),
        align: "start",
        font: {
          size: 15,
          family: "Sukhumvit-Normal",
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",
        align: "center",
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          font: {
            size: 12,
            family: "Sukhumvit-Light",
          },
        },
        onClick: function (
          e: ChartEvent,
          legendItem: LegendItem,
          legend: LegendElement<any>
        ) {
          const type = legend.chart.config.type;
          const data = [
            { name: t("doctor"), value: consult?.doctor },
            {
              name: t("consultDoctor"),
              value: consult?.consult,
            },
          ];

          if (type === "pie" || type === "doughnut") {
            pieDoughnutLegendClickHandler(e, legendItem, legend);
          }
          const chartOnClick = data?.filter((item) => {
            return legend?.legendItems?.some((list) => {
              return list?.text === item?.name && list?.hidden === false;
            });
          });
          const value = chartOnClick?.reduce((result, current) => {
            return result + current?.value;
          }, 0);
          setValueDoughnut(value);
        },
      },

      tooltip: {
        usePointStyle: true,
        titleColor: "#fff",
        titleFont: {
          family: "Sukhumvit-Light",
          weight: "bold",
          size: 14,
        },
        bodyFont: {
          family: "Sukhumvit-Light",
          weight: "bold",
          size: 12,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    showScale: false,
    animations: {
      tension: {
        duration: 1000,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 10,
        bottom: 0,
      },
    },
  };

  useMemo(() => {
    setValueDoughnut(consult?.sum);
    //eslint-disable-next-line
  }, [consult]);

  return (
    <div>
      <Doughnut
        width="100vh"
        height={window.screen.height / 3.5}
        data={data}
        options={options}
        plugins={[showTextInside]}
      />
    </div>
  );
};

export default ChartDoughnut;
