import React, { useEffect, useState } from "react";
import { IBranch } from "@interface/IBranch.interface";
import { IGetRoom } from "@interface/IRoom.interface";
import { USER_MANAGE } from "@assets/data/pathRouter";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useGetRoomList } from "@services/api/rooms";
import { openNotification } from "@components/notification/Notification";
import { useDeleteUserRoom } from "@services/api/user_room";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import RoleContext from "@provider/RoleProvider";

type Props = {
  searchValue: any;
  currentPage: ICurrentPage;
};

const Table = ({ searchValue, currentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [visible, setVisible] = useState(false);
  const [userRoomID, setUserRoomID] = useState<number>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const deleteUserRoom = useDeleteUserRoom();
  const client = useQueryClient();
  const history = useHistory<RouteComponentProps["history"]>();
  const roomList = useGetRoomList({
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    search: searchValue?.name,
    branchId: searchValue?.branchId,
    doctor: true,
  });
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "USER";
  });

  useEffect(() => {
    setTotal(roomList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [roomList?.data?.result?.[0]?.total]);

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteRoom(userRoomID);
  };

  const handleDeleteRoom = (userRoomID?: number) => {
    deleteUserRoom.mutate(userRoomID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        client.invalidateQueries([
          "get-room-list",
          {
            page: currentPage.currentPage,
            limit: limitPage,
            orderby: ENUM_ORDERBY.DESC,
            search: searchValue?.name,
            doctor: true,
          },
        ]);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
      onSettled: () => {
        onModal();
      },
    });
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${USER_MANAGE.updateUserRoom}/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "10%",
      render: (_: any, __: any, index: number) => {
        return (
          <div>{limitPage * (currentPage?.currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("creationDate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        return (
          <div>{!!createdAt ? dayjs(createdAt).format("DD-MM-YYYY") : ""}</div>
        );
      },
    },

    {
      title: `${t("code")}`,
      dataIndex: "code",
      key: "code",
      render: (code: string) => {
        return <div>{code || ""}</div>;
      },
    },

    {
      title: `${t("name")}`,
      dataIndex: "name",
      key: "name",
      render: (name: string, data: IGetRoom) => {
        return (
          <div className="f-normal " key={data?.id}>
            {`${name}`}
          </div>
        );
      },
    },
    {
      title: t("branch"),
      dataIndex: "branch",
      key: "branch",
      render: (branch: IBranch) => {
        return (
          <>{i18n.language === "th" ? branch?.nameTh : branch?.nameEn || ""}</>
        );
      },
    },

    medicalRecord?.[0]?.isEdit
      ? {
          title: t("manage"),
          dataIndex: "manage",
          key: "manage",
          align: "center",
          width: "9%",
          render: (_: any, data: IGetRoom) => {
            return (
              <div>
                <DeleteOutlined
                  onClick={(e) => {
                    setUserRoomID(data?.id);
                    onModal();
                    e.stopPropagation();
                  }}
                />
              </div>
            );
          },
        }
      : {},
  ];

  return (
    <>
      <Modaler
        loadingButton={deleteUserRoom.isLoading}
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>

      <MoTable
        dataSource={roomList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
