import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PRE_REGISTRATIONMARK } from "@assets/data/pathRouter";
import { Button, Card, Col, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { RouteComponentProps, useLocation } from "react-router-dom";
import Table from "./Table";
import Search from "./Search";
import BackHome from "@components/button/BackHome";

const PatientsList = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const eventID = match.params.eventID;
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const location = useLocation();
  const locationPath = location.pathname;
  const historyPatient = locationPath?.search("patient-history") > -1;

  const handleChangePage = () => {
    history.push(`${PRE_REGISTRATIONMARK.event}/${eventID}/create`);
  };

  const patientsFilter = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("preRegister.title")}</h1>
              </div>

              <span className="t-light-gray">
                {`${t("marketing.title")} /  `}
              </span>
              <span className="t-dark-blue f-normal">{t("event.title")}</span>
            </Col>
          </Row>
        </Col>
        {!historyPatient && (
          <Col className="justify-col">
            <Button
              className="btn-create"
              type="primary"
              onClick={handleChangePage}
            >
              {t("preRegister.register")}
            </Button>
          </Col>
        )}
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card ">{t("allNursery")}</b>}
        >
          <div>
            <Search onFinish={patientsFilter} />
            <Table
              searchValue={searchValue}
              currentPage={{
                currentPage: currentPage,
                setCurrentPage: setCurrentPage,
              }}
              eventID={eventID}
              history={history}
              historyPatient={historyPatient}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PatientsList;
