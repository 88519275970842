import { useHistory } from "react-router";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  confirm: FormInstance;
  couponID: number;
  expiredStatus: boolean;
};

const Header = ({ confirm, couponID, expiredStatus }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const create = useIsMutating(["create-coupon"]);
  const update = useIsMutating(["update-coupon-by-id"]);

  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("marketing.title")}</h1>
              </div>
              <span className="t-light-gray">
                {couponID
                  ? `${t("coupon.title")} / ${t("coupon.manage")} / `
                  : `${t("coupon.title")} / `}
              </span>
              <span className="t-dark-blue f-normal">
                {couponID ? t("edit") : "สร้าง"}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button className="cancel" htmlType="reset" onClick={history.goBack}>
            {t("cancel")}
          </Button>
        </Col>
        {!expiredStatus ? (
          <Col className="ml-05">
            <Button
              className="btn-create"
              htmlType="submit"
              onClick={() => confirm.submit()}
              loading={!!create || !!update}
            >
              {t("save")}
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
