import { useTranslation } from "react-i18next";
import { CheckCircleOutlined } from "@ant-design/icons";
import send from "@assets/img/icon/send.svg";
import Modaler from "@components/modaler/Modaler";

type Props = {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
};

const ModalSent = ({ visible, handleOk, handleCancel }: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        width="45%"
        header={
          <div>
            <CheckCircleOutlined className="icon-success" />
            {t("areYouSure")}
          </div>
        }
      >
        <div className="send-center">
          <div className="send-container">
            <img className="send-image" src={send} alt="logo" />
          </div>
        </div>
        <div className="send-text">
          <p>{`${t("confirmAdmissionToNurses")}`} </p>
        </div>
      </Modaler>
    </div>
  );
};

export default ModalSent;
