import { dayjs } from "@tools/timezone";
import { Popover } from "antd";
import { TextBRHTML } from "@tools/util";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useGetVisitTreatment } from "@services/api/visit_history";
import { reportProcedureDetail } from "@assets/data/pathRouter";
import { useHistory, useRouteMatch } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  searchValue: any;
  currentPage: number;
  setCurrentPage: any;
};

const Table = ({ searchValue, currentPage, setCurrentPage }: Props) => {
  const { t } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const [total, setTotal] = useState<number>(1);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const visitList = useGetVisitTreatment(patientID, {
    page: currentPage,
    limit: limitPage,
    date: searchValue?.date
      ? dayjs(searchValue?.date).format("YYYY-MM-DD")
      : undefined,
    orderby: `${
      searchValue?.sort
        ? searchValue?.sort === 1
          ? ENUM_ORDERBY.DESC
          : ENUM_ORDERBY.ASC
        : ENUM_ORDERBY.DESC
    }`,
  });

  useEffect(() => {
    setTotal(visitList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [visitList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${reportProcedureDetail}/visit/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "15%",
      render: (_: string, data: any, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },
    {
      title: t("symptoms"),
      dataIndex: "data",
      key: "data",

      render: (data: any, list: any) => {
        return (
          <div className="f-normal " key={list?.id}>
            <Popover content={data?.print || "-"}>
              <div className="text-content">{data?.print || "-"}</div>
            </Popover>
          </div>
        );
      },
    },

    {
      title: t("doctorOpinionDetails"),
      dataIndex: "suggestion",
      key: "suggestion",

      render: (data: any, list: any) => {
        return (
          <div className="f-normal " key={list?.id}>
            <Popover
              content={
                <div
                  dangerouslySetInnerHTML={{
                    __html: TextBRHTML(data?.print || "-"),
                  }}
                ></div>
              }
            >
              <div className="text-content">{data?.print || "-"}</div>
            </Popover>
          </div>
        );
      },
    },

    {
      title: t("date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any) => {
        return <>{dayjs(createdAt).format("DD-MM-YYYY HH:mm")}</>;
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={visitList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
