import axios from "@axios/index";
import { _isEmpty } from "@tools/util";
import { INotification } from "@interface/INotification.interface";
import { thrownResponse } from "./auth";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { ICountNotification } from "@interface/INotification.interface";
import { Elementary, IParamet } from "@interface/IGlobal.interface";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";

export const useGetNotificationCount = (): UseMutationResult<
  IParamet<ICountNotification[]>
> => {
  return useMutation(async () => {
    const res = await axios.get(`/notification/count`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetNotification = (
  visible: boolean
): UseInfiniteQueryResult<Elementary<any[]>> => {
  return useInfiniteQuery(
    ["get-notification", visible],
    async ({ pageParam }) => {
      if (!visible) {
        return;
      }
      const res = await axios.get(`/notification/bell`, {
        params: { page: 1, ...pageParam, limit: 20 },
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data?.result?.[0];
      }
      thrownResponse(res);
    }
  );
};

export const useGetNotificationTable = (
  params?: any
): UseQueryResult<Elementary<INotification[]>> => {
  return useQuery(["get-notification-table", params], async () => {
    const res = await axios.get(`/notification`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetNotificationByID = (
  id: number
): UseQueryResult<ElementaryOneMore<INotification>> => {
  return useQuery(
    ["get-notification-byid", id],
    async () => {
      const res = await axios.get(`/notification/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !_isEmpty(id) }
  );
};

export const useCreateNotification = () => {
  return useMutation(async (data: any) => {
    const res = await axios.post(`/notification`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateNotification = () => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/notification/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteNotification = () => {
  return useMutation(async (id: number | undefined) => {
    const res = await axios.delete(`/notification/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
