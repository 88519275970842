import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { useMainDashboard } from "@provider/dashboard";
import { Legend, ArcElement } from "chart.js";
import { PointElement, LineElement, Title, Tooltip } from "chart.js";
import { Chart as ChartJS, CategoryScale, LinearScale } from "chart.js";
import numeral from "numeral";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const ChartLine = () => {
  const { monthlyIncome } = useMainDashboard();
  const { t } = useTranslation();
  const labels = [
    t("jan"),
    t("feb"),
    t("mar"),
    t("apr"),
    t("may"),
    t("jun"),
    t("jul"),
    t("aug"),
    t("sep"),
    t("oct"),
    t("nov"),
    t("dec"),
  ];

  const data = {
    labels,
    datasets: [
      {
        label: t("cash"),
        data: monthlyIncome?.map((item) => item?.cash),
        borderColor: "#004C97",
        backgroundColor: "#004C97",
      },
      {
        label: t("transfer"),
        data: monthlyIncome?.map((item) => item?.transfer),
        borderColor: "#498DCB",
        backgroundColor: "#498DCB",
      },
      {
        label: t("credit"),
        data: monthlyIncome?.map((item) => item?.credit),
        borderColor: "#77C48B",
        backgroundColor: "#77C48B",
      },
    ],
  };

  const options: any = {
    chart: {
      width: 200,
    },
    // responsive: true, gun Natalie that that I decide
    plugins: {
      font: {
        weight: "bold",
        size: 100,
        family: "Josefin Sans",
      },
      legend: {
        align: "end",
        display: true,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          font: {
            size: 12,
            family: "Sukhumvit-Light",
          },
        },
      },
      tooltip: {
        usePointStyle: true,
        titleColor: "#fff",
        titleFont: {
          family: "Sukhumvit-Light",
          weight: "bold",
          size: 14,
        },
        bodyFont: {
          family: "Sukhumvit-Light",
          weight: "bold",
          size: 12,
        },
      },
      scales: {
        y: {
          stacked: true,
          size: 40,
        },
      },
    },
    scales: {
      y: {
        min: 0,
        ticks: {
          callback: (value: number) => {
            return `${numeral(value).format("0,0")}`;
          },
          font: {
            size: 12,
            family: "Sukhumvit-Light",
          },
        },
      },

      x: {
        ticks: {
          font: {
            size: 12,
            family: "Sukhumvit-Light",
          },
        },
      },
    },
  };

  return <Line data={data} options={options} />;
};

export default ChartLine;
