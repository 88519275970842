import { LAB } from "@assets/data/pathRouter";
import { IItem } from "@interface/IOrder.interface";
import { useState } from "react";
import { ISignature } from "@interface/IGlobal.interface";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { useUpdateVisits } from "@services/api/visits";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { IGetWorkOrderItem } from "@interface/IWorkOrder.interface";
import { RouteComponentProps } from "react-router-dom";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { KeyNameSignatureFirst } from "@interface/IGlobal.interface";
import { useCreateSignatureRoom } from "@services/api/signature_room";
import { useCreateWorkOrdersConfirm } from "@services/api/work_order";
import { useQueryClient } from "react-query";
import BackHome from "@components/button/BackHome";
import ModalInfo from "@components/signature/ModalInfo";

type Props = {
  history: RouteComponentProps["history"];
  handleCreateLab: () => void;
  ordersList?: IGetWorkOrderItem;
  visitID: number;
  isSignature?: boolean;
};

const Header = ({
  history,
  handleCreateLab,
  ordersList,
  visitID,
  isSignature,
}: Props) => {
  const { t } = useTranslation();
  const { mutate, isLoading } = useCreateWorkOrdersConfirm();
  const updateVisits = useUpdateVisits();
  const [visible, setVisible] = useState({ first: false });
  const createSignatureRoom = useCreateSignatureRoom();
  const client = useQueryClient();

  const confirmUse = () => {
    const oneList: any = ordersList?.one?.groups?.flatMap((item: any) => {
      return item?.children?.[0]?.children?.flatMap((itemList: IItem) => {
        if (!!itemList?.children) {
          return itemList?.children?.flatMap((parent) => {
            return parent?.children?.flatMap((child) => {
              return { uuid: child?.uuid, confirmed: true };
            });
          });
        }
        return { uuid: itemList?.uuid, confirmed: true };
      });
    });
    const continuousList: any = ordersList?.continuous?.groups?.flatMap(
      (item: any) => {
        return item?.children?.[0]?.children?.flatMap((itemList: IItem) => {
          if (!!itemList?.children) {
            return itemList?.children?.flatMap((parent) => {
              return parent?.children?.flatMap((child) => {
                return { uuid: child?.uuid, confirmed: true };
              });
            });
          }
          return { uuid: itemList?.uuid, confirmed: true };
        });
      }
    );

    mutate(
      {
        data: [...(oneList || []), ...(continuousList || [])],
        id:
          ordersList?.one?.groups?.[0]?.children?.[0]?.children?.[0]
            ?.workOrderId ||
          ordersList?.continuous?.groups?.[0]?.children?.[0]?.children?.[0]
            ?.workOrderId,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
        onSettled: () => {
          handleUpdateVisits();
        },
      }
    );
  };

  const handleUpdateVisits = () => {
    const formData = new FormData();
    formData?.append("proceed", "4");
    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(LAB.title);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const updateSignature = (
    values: {
      date: string;
      title: KeyNameSignatureFirst;
      doctorName?: string;
    } & ISignature
  ) => {
    const file = dataUrlToFile(
      values?.firstSignature,
      `labRoomSignature${new Date().getTime()}.png`
    );
    if (file) {
      let formData = new FormData();
      formData?.append("signature", file);
      formData?.append("room", ENUM_ROOM_SIGNATURE.LAB_ROOM);
      !!values?.doctorName &&
        formData?.append("doctorName", values?.doctorName);

      createSignatureRoom.mutate(
        { data: formData, visitId: visitID },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            client.invalidateQueries(["get-visitsByID", visitID]);
            setVisible({
              ...visible,
              [values?.title]: !visible[values?.title],
            });
          },
        }
      );
    } else {
      setVisible({
        ...visible,
        [values?.title]: !visible[values?.title],
      });
    }
  };
  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("lab.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-bold">{t("lab.title")}</span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button
            className="cancel mr-05 "
            htmlType="submit"
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>

          {/* <Button
            className="send-lab mr-05 "
            htmlType="submit"
            onClick={handleCreateLab}
            loading={!!isMutatingPosts}
          >
            {`${t("sendTo")}${t("lab.title")}`}
          </Button> */}

          <Button
            className="btn-create"
            htmlType="submit"
            onClick={() => {
              !!isSignature ? setVisible({ first: true }) : confirmUse();
            }}
            loading={isLoading}
          >
            {t("confirm")}
          </Button>
        </Col>
      </Row>

      {visible.first && (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="first"
          title={t("medicalAssistantSignature")}
          updateSignature={updateSignature}
          isLoading={createSignatureRoom?.isLoading}
        />
      )}
    </>
  );
};

export default Header;
