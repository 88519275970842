import React, { useEffect, useState } from "react";
import { IItem } from "@interface/IOrder.interface";
import { ISelectedList } from "@interface/IOrder.interface";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { useCreateOrdersDone } from "@services/api/order";
import { useGetOrderSignature } from "@services/api/order";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { useUpdateAppointments } from "@services/api/appointments";
import { Col, Drawer, Form, Row } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useHistory, useRouteMatch } from "react-router-dom";
import { idProductGetOrderSignature } from "@tools/util";
import { useGetOrderListOutstanding } from "@services/api/order";
import { APPOINTMENT_STATUS, ORDER_TYPE } from "@tools/util.enum";
import { useGetVisitsByID, useUpdateVisits } from "@services/api/visits";
import { documentExecuteOrder, executeOrder } from "@assets/data/pathRouter";
import Header from "./Header";
import Affixes from "@components/affixes/Affixes";
import Modaler from "@components/modaler/Modaler";
import ListRecord from "@components/list_history/ListHistory";
import FormProfile from "./form/Form";
import RoleContext from "@provider/RoleProvider";
import ListHistoryCheckbox from "@components/list_history_checkbox/ListHistoryCheckbox";
import MainPatientByIdProvider from "@provider/patient_by_id";
import MainProductOutStandingProvider from "@provider/product_out_standing";

interface visible {
  course: boolean;
  select: boolean;
  drawer: boolean;
}

const OrderList = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const visitID = match.params.visitID;
  const [orderList, setOrderList] = useState<ISelectedExecuteOrder[]>([]);
  const [patientList] = Form.useForm();
  const outStanding = useGetOrderListOutstanding(patientID);
  const createOrdersDone = useCreateOrdersDone();
  const updateVisit = useUpdateVisits();
  const updateAppointment = useUpdateAppointments();
  const getOrderSignature = useGetOrderSignature();
  const [visible, setVisible] = useState<visible>({
    course: false,
    select: false,
    drawer: false,
  });
  const [selection, setSelection] = useState<ISelectedList>({
    course: [],
    list: [],
    oldValueList: [],
    oldValue: [],
    expandAllRows: [],
  });
  const { data } = useGetVisitsByID(visitID);
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PROCESS_ORDER";
  });
  const [orderSignatureList, setOrderSignatureList] = useState<any>();

  useEffect(() => {
    patientList?.setFieldsValue({
      foodAllergy: data?.patient?.foodAllergy || undefined,
      drugAllergy: data?.patient?.drugAllergy || undefined,
    });
    getOrderSignature.mutate(
      {
        data: idProductGetOrderSignature(orderList, selection),
        id: patientID,
      },
      {
        onSuccess: (res) => {
          setOrderSignatureList(res);
        },
      }
    );
    // eslint-disable-next-line
  }, [data, selection]);

  const createOrderDone = () => {
    const result = orderList?.some(
      (item) =>
        item?.categoryId === "C-0001" ||
        item?.children?.[0]?.children?.[0]?.product?.category?.id ===
          "C-0006" ||
        item?.children?.[0]?.children?.[0]?.product?.category?.id === "C-0007"
    );
    if (result) {
      const noneCourses = orderList?.filter(
        (item) => item?.categoryId !== "C-0006" && item?.categoryId !== "C-0007"
      );

      if (!noneCourses?.length && !selection?.course?.length) {
        onModalConfirm();
      } else {
        checkSelectCourses();
      }
    }
    if (!result) {
      orderList?.length ? confirmOrderDone(undefined) : onModalConfirm();
    }
  };

  const checkSelectCourses = async () => {
    let course = 0;
    const children = orderList
      ?.flatMap((childrenItem: ISelectedExecuteOrder) => {
        return childrenItem?.children?.map((i: any) => i?.children);
      })
      ?.flat(Infinity);

    children?.forEach((item: IItem) => {
      if (
        item?.product?.category?.id === "C-0006" ||
        item?.product?.category?.id === "C-0007"
      ) {
        course++;
      }
    });

    if (course > 0 && selection?.course?.length <= 0) {
      onModalCancelCourse();
    } else {
      if (orderSignatureList && orderSignatureList?.result?.length > 0) {
        sessionStorage.setItem("orderList", JSON.stringify(orderList));
        sessionStorage.setItem("visitsByID", JSON.stringify(data));
        sessionStorage.setItem(
          "seletection",
          JSON.stringify(selection?.course)
        );

        sessionStorage.setItem(
          "orderSignature",
          JSON.stringify(orderSignatureList?.result)
        );
        history.push(
          `${documentExecuteOrder}/patient/${patientID}/visit/${visitID}`
        );
      } else {
        confirmOrderDone(undefined);
      }
    }
  };

  const confirmOrderDone = async (proceed?: number) => {
    await Promise.all([
      handleCreateOrderDone(proceed, data?.appointment?.[0]?.id),
    ])
      .then(() => {})
      .catch((_) => openNotification({ method: "POST_ERROR" }));
  };

  const intersection = (array1: IItem[], array2: string[]) => {
    return array1?.filter((item: IItem) => {
      return array2?.indexOf(item?.id) !== -1;
    });
  };

  const handleCreateOrderDone = (proceed?: number, appointmentId?: number) => {
    let dataCourse: any = [];
    let result: IItem[] | any;

    const parent = orderList
      ?.map((childrenItem: any) => {
        return childrenItem?.children?.map((i: any) => i?.children);
      })
      ?.flat(Infinity);

    const dataList = parent?.filter((item: IItem) => {
      if (
        item?.product?.category?.id === "C-0006" ||
        item?.product?.category?.id === "C-0007"
      ) {
        result = intersection(
          item?.children?.[0]?.children?.map((dataList: IItem) => {
            return { ...dataList, orderId: item?.orderId };
          }),
          selection?.course
        );

        result?.forEach((item: IItem) => {
          if (item?.type === ORDER_TYPE.OPTION_DRUG) {
            dataCourse?.push({
              uuid: item?.id,
              qty: 1,
              orderId: item?.orderId,
            });
          } else
            dataCourse?.push({
              uuid: item?.id,
              qty:
                item?.qtyChange !== undefined
                  ? item?.qtyChange
                  : item?.qtyOutstanding,
              orderId: item?.orderId,
            });
        });
      }
      return (
        item?.product?.category?.id !== "C-0006" &&
        item?.product?.category?.id !== "C-0007"
      );
    });

    const dataTreatment = dataList
      ?.map((item: any) => {
        if (item?.type === ORDER_TYPE.OPTION_DRUG) {
          return {
            uuid: item?.id,
            qty: 1,
            orderId: item?.orderId,
          };
        } else if (item?.name === "Treatment") {
          return {
            uuid: item?.id,
            qty:
              item?.qtyChange !== undefined
                ? item?.qtyChange
                : item?.qtyOutstanding,
            orderId: item?.orderId,
          };
        } else
          return {
            uuid: item?.id,
            qty:
              item?.qtyChange !== undefined
                ? item?.qtyChange
                : item?.qtyOutstanding,
            orderId: item?.orderId,
          };
      })
      ?.flat(Infinity);
    const data = [...(dataCourse || []), ...(dataTreatment || [])];
    const qtyIsNotNull = data?.filter((item) => {
      return item?.qty !== 0;
    });
    createOrdersDone.mutate(
      { id: visitID, data: qtyIsNotNull },
      {
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
        onSuccess: () => {
          sessionStorage.removeItem("visitsByID");
          sessionStorage.removeItem("orderList");
          sessionStorage.removeItem("checkedValues");
          sessionStorage.removeItem("seletection");
          sessionStorage.removeItem("orderSignature");
          openNotification({ method: "POST_SUCCESS" });
          updateAppointment.mutate(
            {
              id: appointmentId,
              data: {
                status: APPOINTMENT_STATUS.CONFIRM,
              },
            },
            {
              onSuccess: () => {
                openNotification({ method: "PUT_SUCCESS" });
                if (proceed !== undefined && proceed >= 0) {
                  const formData = new FormData();
                  formData?.append("proceed", String(proceed));
                  updateVisit.mutate(
                    { id: visitID, data: formData },
                    {
                      onSuccess: () => {
                        openNotification({ method: "PUT_SUCCESS" });
                      },
                      onSettled: () => {
                        history.replace(executeOrder);
                      },
                    }
                  );
                }
                history.replace(executeOrder);
              },
            }
          );
        },
      }
    );
  };

  const onModalConfirm = () => {
    setVisible({ ...visible, select: !visible?.select });
  };

  const onModalCancelCourse = () => {
    setVisible({ ...visible, course: !visible?.course });
  };

  const onConfirm = () => {
    confirmOrderDone(7);
    setVisible({ ...visible, select: !visible?.select });
  };

  const onConfirmCourse = () => {
    sessionStorage.setItem("orderList", JSON.stringify(orderList));
    sessionStorage.setItem("visitsByID", JSON.stringify(data));
    sessionStorage.setItem("seletection", JSON.stringify(selection));
    if (orderSignatureList && orderSignatureList?.result?.length > 0) {
      sessionStorage.setItem(
        "orderSignature",
        JSON.stringify(orderSignatureList?.result)
      );
    }
    history.push(
      `${documentExecuteOrder}/patient/${patientID}/visit/${visitID}`
    );
    setVisible({ ...visible, course: !visible?.course });
  };

  const showDrawer = () => {
    setVisible({ ...visible, drawer: !visible?.drawer });
  };

  return (
    <MainPatientByIdProvider value={{ patientById: data?.patient }}>
      <MainProductOutStandingProvider
        value={{ outStanding: outStanding?.data?.result }}
      >
        <React.Fragment>
          <Modaler
            visible={visible?.select}
            onCancel={onModalConfirm}
            onOk={onConfirm}
            width="45%"
            header={
              <div>
                <QuestionCircleOutlined className="icon-warning-cancel" />
                {`${t("areYouSure")}`}
              </div>
            }
          >
            {t("doYouWantToSaveWithoutSelecting")}
          </Modaler>

          <Modaler
            visible={visible?.course}
            onCancel={onModalCancelCourse}
            onOk={onConfirmCourse}
            width="45%"
            header={
              <div>
                <QuestionCircleOutlined className="icon-warning-cancel" />
                {`${t("areYouSure")}`}
              </div>
            }
          >
            {t("modalSelectedCourse")}
          </Modaler>

          <div>
            <Header
              history={history}
              createOrderDone={createOrderDone}
              medicalRecord={medicalRecord?.[0]?.isEdit}
            />
            <div className="set-layout body-height">
              <Row gutter={[14, 14]}>
                <Col md={24} lg={18}>
                  <FormProfile
                    patientID={patientID}
                    visitsByID={data}
                    patientList={patientList}
                    orderList={orderList}
                    selection={{
                      selection: selection,
                      setSelection: setSelection,
                    }}
                  />
                </Col>

                <Col lg={6} className="card-display">
                  {medicalRecord?.[0]?.isEdit ? (
                    <ListHistoryCheckbox
                      setOrderList={setOrderList}
                      status={"executeOrder"}
                      selection={{
                        selection: selection,
                        setSelection: setSelection,
                      }}
                    />
                  ) : (
                    <ListRecord />
                  )}
                </Col>
              </Row>

              <Affixes onClick={showDrawer} />

              <Drawer
                width={"50%"}
                closable={false}
                placement="right"
                onClose={showDrawer}
                visible={visible.drawer}
              >
                {medicalRecord?.[0]?.isEdit ? (
                  <ListHistoryCheckbox
                    setOrderList={setOrderList}
                    status={"executeOrder"}
                    selection={{
                      selection: selection,
                      setSelection: setSelection,
                    }}
                    noCard="noCard"
                  />
                ) : (
                  <ListRecord status="noCard" />
                )}
              </Drawer>
            </div>
          </div>
        </React.Fragment>
      </MainProductOutStandingProvider>
    </MainPatientByIdProvider>
  );
};

export default OrderList;
