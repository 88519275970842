export const TreatmentIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="clip-Treatment_Record">
          <path d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-Treatment_Record)" data-name="Treatment Record">
        <g data-name="vuesax/bold/directbox-notif" transform="translate(3 3)">
          <g fill="#fff">
            <path
              d="M61.957.9a31.532 31.532 0 00-8.02-.9c-4.364 0-5.976 1.061-8.216 2.752a2.543 2.543 0 00-.354.315l-3.735 3.97a8.377 8.377 0 01-11.951 0l-3.734-3.931a1.5 1.5 0 00-.354-.354A11.383 11.383 0 0017.376 0a29.852 29.852 0 00-8.02.9C0 3.42 0 10.85 0 17.376v3.656C0 30.9 0 42.065 21.032 42.065h29.249c13.956 0 21.032-7.076 21.032-21.033v-3.656C71.314 10.85 71.314 3.42 61.957.9z"
              transform="translate(11.519 44.424)"
            ></path>
            <path
              d="M39.313 0H17.376C0 0 0 9.239 0 17.376v14.546a2.543 2.543 0 01.511-.157 36.263 36.263 0 019.553-1.1c6.054 0 8.924 1.769 11.755 3.931a6.87 6.87 0 011.14 1.022l3.7 3.892a2.279 2.279 0 001.69.668A2.252 2.252 0 0030 39.549l3.774-3.971a8.25 8.25 0 011.061-.944c2.909-2.2 5.74-3.971 11.794-3.971a36.263 36.263 0 019.553 1.1 2.543 2.543 0 01.511.157V17.376C56.689 9.239 56.689 0 39.313 0zm-4.875 25.554H22.251a2.752 2.752 0 010-5.5h12.187a2.752 2.752 0 010 5.5zM37.5 14.585H19.185a2.752 2.752 0 010-5.5H37.5a2.752 2.752 0 010 5.5z"
              data-name="Vector"
              transform="translate(18.831 7.863)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
