import { OPD } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { VISIT_OLD_FILES } from "@tools/util.enum";
import { useReactToPrint } from "react-to-print";
import { useRef, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useGetVisitReportDrug } from "@services/api/visit_history";
import { Button, Card, Col, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import MedicalReport from "@components/medical_report/MedicalReport";

const ReportDrugQ = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const [searchData, setSearchData] = useState();
  const drugList = useGetVisitReportDrug(patientID, searchData);
  const pageStyle = `  @page { size:297mm 210mm;
    margin: 20px;}`;
  const printReportRef = useRef<HTMLHeadingElement>(null);
  const history = useHistory<RouteComponentProps["history"]>();

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });
  return (
    <Card
      className="bg-grey "
      extra={
        <Row justify="space-between">
          <Col>
            <b className="header-card"> {t("medicationAdministration")}</b>
          </Col>
          {drugList?.data?.result?.[0] ? (
            <Col style={{ marginLeft: "auto" }}>
              <Button
                className="mr-05 reserve"
                htmlType="reset"
                onClick={() => handlePrint()}
              >
                {t("print")}
              </Button>
            </Col>
          ) : null}

          <Col>
            <Button
              className="btn-create mr-05"
              onClick={() => {
                history.push(
                  `${OPD.nursingHistory}/patient/${patientID}/history-upload-file/${VISIT_OLD_FILES?.REPORT_DRUG}`
                );
              }}
            >
              {t("file")}
            </Button>
          </Col>
        </Row>
      }
    >
      <MedicalReport
        setSearchData={setSearchData}
        drugList={drugList?.data?.result?.[0]}
        printReportRef={printReportRef}
      />
    </Card>
  );
};

export default ReportDrugQ;
