import { t } from "i18next";
import { dayjs } from "@tools/timezone";
import { Component } from "react";
import { IVisitBill } from "@interface/IGlobal.interface";
import { heightTopPrint } from "@tools/heightTopPrint";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { IGroupsItem, IInvoice } from "@interface/IInvoice.interface";
import { Button, Col, Divider, Row, Table } from "antd";
import Decimal from "@tools/decimal";
import BigNumber from "bignumber.js";
import ImageLogo from "@components/image/ImageLogo";
import { _amountToWordsEN } from "@tools/util";

type Props = {
  ref: any;
  invoiceIDList?: ElementaryOneMore<IInvoice>;
  divHeight: any;
  visible: IVisitBill;
  setVisible: (e: IVisitBill) => void;
  i18n: string;
};

class Print extends Component<Props> {
  render() {
    const patient =
      this.props?.invoiceIDList?.result?.[0]?.bill?.order?.patient;
    const THBText =
      this.props.i18n === "th" ? require("thai-baht-text") : _amountToWordsEN;
    const nameTH = [patient?.firstNameTh, patient?.lastNameTh]
      ?.join(" ")
      ?.trim();

    const nameEN = [patient?.firstNameEn, patient?.lastNameEn]
      ?.join(" ")
      ?.trim();

    const userTh = [
      this.props?.invoiceIDList?.result?.[0]?.user?.firstNameTh,
      this.props?.invoiceIDList?.result?.[0]?.user?.lastNameTh,
    ]
      ?.join(" ")
      ?.trim();

    const userEn = [
      this.props?.invoiceIDList?.result?.[0]?.user?.firstNameEn,
      this.props?.invoiceIDList?.result?.[0]?.user?.lastNameEn,
    ]
      ?.join(" ")
      ?.trim();
    const mailingAddress = patient?.mailingAddress;
    const mailAddress =
      mailingAddress?.addressTh || mailingAddress?.districtTh
        ? [
            mailingAddress[
              this.props?.i18n === "th" ? "addressTh" : "addressEn"
            ],
            mailingAddress[
              this.props?.i18n === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            mailingAddress[
              this.props?.i18n === "th" ? "districtTh" : "districtEn"
            ],
            mailingAddress[
              this.props?.i18n === "th" ? "provinceTh" : "provinceEn"
            ],
            mailingAddress?.zipcode,
            mailingAddress?.country,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const _homeAddress = patient?.homeAddress;
    const homeAddress =
      _homeAddress?.addressTh || _homeAddress?.districtTh
        ? [
            _homeAddress[this.props?.i18n === "th" ? "addressTh" : "addressEn"],
            _homeAddress[
              this.props?.i18n === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            _homeAddress[
              this.props?.i18n === "th" ? "districtTh" : "districtEn"
            ],
            _homeAddress[
              this.props?.i18n === "th" ? "provinceTh" : "provinceEn"
            ],
            _homeAddress?.country,
            _homeAddress?.zipcode,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const columns = [
      {
        title: t("sort"),
        dataIndex: "id",
        key: "index",
        render: (_: string, __: IGroupsItem, index: number) => {
          return <div key={index}>{index + 1}</div>;
        },
      },
      {
        title: `${t("productName")} / ${t("details")}`,
        dataIndex: "name",
        key: "index",
        render: (name: string) => {
          return <div>{t(`${name}`)}</div>;
        },
      },

      {
        title: t("amount"),
        dataIndex: "qty",
        key: "index",
      },

      {
        title: t("totalPrice"),
        dataIndex: "total",
        render: (total: string) => {
          return Decimal(total || 0);
        },
      },

      {
        title: t("outstandingBalance"),
        dataIndex: "outstandingBalance",
        key: "index",
        render: (outstandingBalance: string) => {
          return new BigNumber(outstandingBalance || 0).toFormat(2);
        },
      },

      // {
      //   title: t("paidAmount"),
      //   dataIndex: "outstandingBalance",
      //   key: "index",
      //   render: (outstandingBalance: string, data: IInvoice | any) => {
      //     return new BigNumber(data?.total || 0)
      //       ?.minus(outstandingBalance || 0)
      //       ?.toFormat(2);
      //   },
      // },
    ];

    return (
      <div>
        <div id="container">
          <table className="report-drug f-light  " width="100%">
            <tbody>
              <tr>
                <td
                  style={{
                    width: "50vw",
                    fontWeight: "lighter",
                    textAlign: "start",
                  }}
                >
                  <div className="mb-1">
                    <ImageLogo
                      src={
                        this.props?.invoiceIDList?.result?.[0]?.branch?.image
                      }
                      alt="Logo"
                      width="200px"
                      height="70px"
                    />
                  </div>
                  <div className="example">
                    <span className="break-inside ">
                      {
                        this.props?.invoiceIDList?.result?.[0]?.branch?.nameEn
                        // t("location.title")
                      }
                    </span>
                    <span className="break-inside ">
                      {
                        this.props?.invoiceIDList?.result?.[0]?.branch?.nameTh
                        // t("location.title")
                      }
                    </span>
                    <span className="break-inside ">
                      {this.props.i18n === "th"
                        ? this.props?.invoiceIDList?.result?.[0]?.branch
                            ?.addressTh
                        : this.props?.invoiceIDList?.result?.[0]?.branch
                            ?.addressEn}
                    </span>
                    <span className="break-inside ">
                      {[
                        this.props?.invoiceIDList?.result?.[0]?.branch?.[
                          this.props.i18n === "th"
                            ? "subDistrictTh"
                            : "subDistrictEn"
                        ],
                        this.props?.invoiceIDList?.result?.[0]?.branch?.[
                          this.props.i18n === "th" ? "districtTh" : "districtEn"
                        ],
                        this.props?.invoiceIDList?.result?.[0]?.branch?.[
                          this.props.i18n === "th" ? "provinceTh" : "provinceEn"
                        ],
                        this.props?.invoiceIDList?.result?.[0]?.branch?.zipcode,
                        this.props?.invoiceIDList?.result?.[0]?.branch?.country,
                      ]
                        ?.join(" ")
                        ?.trim()}
                    </span>
                  </div>
                  <div>
                    <span>{t("tel")} :</span>{" "}
                    {this.props?.invoiceIDList?.result?.[0]?.branch?.phone}
                  </div>
                  <div>
                    <span>{t("taxId")} :</span>{" "}
                    {this.props?.invoiceIDList?.result?.[0]?.branch?.taxId}
                  </div>
                  <div className="pt-1">
                    <span className="f-bold t-blue">{t("customer")} </span>
                  </div>
                  <div>
                    <span className="break-inside ">
                      {t("customerName")} :{" "}
                      {this.props?.i18n === "th" ? nameTH : nameEN}
                    </span>{" "}
                  </div>
                  <div>
                    <span className="break-inside ">
                      {t("address")} : {mailAddress || homeAddress || "-"}
                    </span>
                  </div>

                  <div>
                    <span className="break-inside ">
                      {t("zipCode")} :
                      {!!mailAddress
                        ? patient?.mailingAddress?.zipcode || "-"
                        : patient?.homeAddress?.zipcode || "-"}
                    </span>
                  </div>
                </td>

                <td
                  style={{
                    width: "50vw",
                    fontWeight: "lighter",
                    textAlign: "start",
                  }}
                >
                  <div className="t-center fs-20 t-blue f-bold">
                    {t("invoice")}
                  </div>
                  <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                    <Divider className="m-0 b-grey-white" />
                  </div>

                  <Row align="top" style={{ paddingBottom: "40px" }}>
                    <Col span="6">{t("number")}</Col>

                    <Col span="18">
                      {this.props?.invoiceIDList?.result?.[0]?.invoiceNumber}
                    </Col>

                    <Col span="6"> {t("date")}</Col>

                    <Col span="18">
                      {dayjs(
                        this.props?.invoiceIDList?.result?.[0]?.date
                      ).format("DD-MM-YYYY")}
                    </Col>

                    <Col span="6">{t("refer")}</Col>

                    <Col span="18">
                      {this.props?.invoiceIDList?.result?.[0]?.billNumber}
                    </Col>
                    <Col
                      span="24"
                      style={{ paddingTop: "15px", paddingBottom: "15px" }}
                    >
                      <Divider className="m-0 b-grey-white" />
                    </Col>
                  </Row>
                </td>
              </tr>

              <tr>
                <td colSpan={2} style={{ paddingTop: "19px" }}>
                  <Table
                    rowKey="id"
                    className="components-table-demo-nested table-selection table-confirm  table-list-children report-pay"
                    rowClassName={(r) => {
                      if (r?.id) {
                        return " no-header no-cursor";
                      }
                      return "";
                    }}
                    columns={columns}
                    dataSource={
                      this.props?.invoiceIDList?.result?.[0]?.bill?.order
                        ?.groups || []
                    }
                    pagination={false}
                  />
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <table className="report-drug " width="100%">
                    <tbody>
                      <tr className="t-end">
                        <td style={{ width: "20vw" }}></td>

                        <td style={{ width: "70vw" }}>
                          <Row style={{ paddingTop: "30px" }}>
                            <Col span={12}>{t("totalMoney")}</Col>
                            <Col span={12}>
                              {Decimal(
                                Number(
                                  this.props?.invoiceIDList?.result?.[0]
                                    ?.totalPrice
                                )
                              )}
                            </Col>

                            <Col span={12}>{t("theTotalAmount")}</Col>
                            <Col span={12}>
                              {" "}
                              {Decimal(
                                Number(
                                  this.props?.invoiceIDList?.result?.[0]
                                    ?.netPrice
                                )
                              )}
                            </Col>
                          </Row>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {`(${THBText(
                    Number(
                      this.props?.invoiceIDList?.result?.[0]?.netPrice || 0
                    )
                  )})
              `}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div
          style={{
            marginTop: `${heightTopPrint(this.props?.divHeight)}px`,
          }}
          className="footer-bill"
        >
          <Row
            style={{ paddingTop: "80px" }}
            align="middle"
            className="break-inside"
          >
            <Col span={6} className="t-center pl-20 pt-10">
              <div className=" f-normal t-center  image-signature">
                {this?.props?.invoiceIDList?.result?.[0]?.firstSignature && (
                  <img
                    src={
                      this?.props?.invoiceIDList?.result?.[0]?.firstSignature ||
                      ""
                    }
                    alt="first"
                    height={70}
                  />
                )}
              </div>
            </Col>
            <Col span={6} className="t-center pl-20 pt-10">
              <div className=" f-normal t-center  date-signature">
                {dayjs(this.props?.invoiceIDList?.result?.[0]?.date).format(
                  "DD-MM-YYYY"
                )}
              </div>
            </Col>
            <Col span={6} className="t-center pl-20 pt-10">
              <div className=" f-normal t-center  image-signature">
                {this?.props?.invoiceIDList?.result?.[0]?.secondSignature && (
                  <img
                    src={
                      this?.props?.invoiceIDList?.result?.[0]
                        ?.secondSignature || ""
                    }
                    alt="second"
                    height={70}
                  />
                )}
              </div>
            </Col>
            <Col span={6} className="t-center pl-20 pt-10">
              <div className=" f-normal t-center  date-signature">
                {dayjs(this.props?.invoiceIDList?.result?.[0]?.date).format(
                  "DD-MM-YYYY"
                )}
              </div>
            </Col>
            <Col span={6} className="t-center pl-20 ">
              <div className="pb-30">
                <Divider className="m-0 b-grey-white" />
              </div>

              <Button
                className="pb-1 signature-button"
                onClick={(e) =>
                  this.props?.setVisible({
                    ...this.props?.visible,
                    first: !this.props?.visible?.first,
                  })
                }
              >
                {t("signHere")}
              </Button>
            </Col>
            <Col span={6} className="t-center pl-20">
              <div style={{ paddingBottom: "30px" }}>
                <Divider className="m-0 b-grey-white" />
              </div>
            </Col>
            <Col span={6} className="t-center pl-20">
              <div className="pb-30">
                <Divider className="m-0 b-grey-white" />
              </div>
              <Button
                className="pb-1 signature-button"
                onClick={(e) =>
                  this.props?.setVisible({
                    ...this.props?.visible,
                    second: !this.props?.visible?.second,
                  })
                }
              >
                {t("signHere")}
              </Button>
            </Col>
            <Col span={6} className="t-center pl-20">
              <div style={{ paddingBottom: "30px" }}>
                <Divider className="m-0 b-grey-white" />
              </div>
            </Col>
            <Col span={6} className="t-center pl-20 pt-10">
              <div
                style={{
                  fontSize:
                    Number(
                      this.props?.i18n === "th"
                        ? nameTH?.length || 0
                        : nameEN?.length || 0
                    ) < 22
                      ? 14
                      : 250 /
                        Number(
                          this.props?.i18n === "th"
                            ? nameTH?.length || 0
                            : nameEN?.length || 0
                        ),
                }}
              >
                ({this.props?.i18n === "th" ? nameTH : nameEN})
              </div>
            </Col>
            <Col span={6} className="t-center pl-20 pt-10">
              {t("date")}
            </Col>
            <Col span={6} className="t-center pl-20 pt-10">
              <div
                style={{
                  fontSize:
                    Number(
                      this.props?.i18n === "th"
                        ? userTh?.length || 0
                        : userEn?.length || 0
                    ) < 22
                      ? 14
                      : 250 /
                        Number(
                          this.props?.i18n === "th"
                            ? userTh?.length || 0
                            : userEn?.length || 0
                        ),
                }}
              >
                ({this.props?.i18n === "th" ? userTh : userEn})
              </div>
            </Col>
            <Col span={6} className="t-center pl-20 pt-10">
              {t("date")}
            </Col>
            <Col span={6} className="t-center pl-20 ">
              <div>{this.props?.i18n === "th" ? "ผู้รับบริการ" : ""}</div>
            </Col>
            <Col span={6} className="t-center pl-20 "></Col>
            <Col span={6} className="t-center pl-20 ">
              {`${t("location.title")}`}
            </Col>
            <Col span={6} className="t-center pl-20 "></Col>
          </Row>
        </div>
        <div className="mt-1">
          <span className="f-bold t-blue">{t("remarks")} : </span>
          <span>{this.props?.invoiceIDList?.result?.[0]?.description}</span>
        </div>
      </div>
    );
  }
}

export default Print;
