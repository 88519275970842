import { Form } from "antd";
import { EVENT } from "@assets/data/pathRouter";
import { _isEmpty } from "@tools/util";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDeleteEventByID, useGetEventByID } from "@services/api/event";
import moment from "moment";
import Fields from "./Fields";
import Header from "./Header";
import Modaler from "@components/modaler/Modaler";

const DetailCoupon = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [confirm] = Form.useForm();
  const match: any = useRouteMatch();
  const eventID: number = match.params.id;
  const eventByID = useGetEventByID(eventID, !_isEmpty(eventID));
  const [visible, setVisible] = useState<boolean>(false);
  const deleteEvent = useDeleteEventByID();

  useEffect(() => {
    if (!eventByID?.data) return;
    confirm.setFieldsValue({
      name: eventByID?.data?.result?.[0]?.name,
      date:
        eventByID?.data?.result?.[0]?.startDate ||
        eventByID?.data?.result?.[0]?.endDate
          ? [
              moment(eventByID?.data?.result?.[0]?.startDate),
              moment(eventByID?.data?.result?.[0]?.endDate),
            ]
          : undefined,
      location: eventByID?.data?.result?.[0]?.location,
      detail: eventByID?.data?.result?.[0]?.detail,
      addPromotion: eventByID?.data?.result?.[0]?.eventPromotion?.flatMap(
        (promotionId) => {
          return promotionId?.promotionId;
        }
      ),
    });
    // eslint-disable-next-line
  }, [eventByID?.data]);

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    deleteEvent.mutate(eventID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        onModal();
        history.replace(`${EVENT.title}`);
      },
      onError: (res) => {
        openNotification({ method: "DEL_ERROE", message: String(res) });
      },
    });
  };

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>
      <Header eventID={eventID} onModal={onModal} />
      <Fields confirm={confirm} />
    </div>
  );
};

export default DetailCoupon;
