import { general } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { generateUniqueId } from "@tools/util";
import { openNotification } from "@components/notification/Notification";
import { tabsGeneralState } from "@tools/recoil/recoil-atom";
import { ENUM_PREFIX_TYPE } from "@tools/util.enum";
import { MinusCircleFilled } from "@ant-design/icons";
import { useCreatePrefixMany } from "@services/api/prefix";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Button, Card, Col, Form, FormInstance, Input, Row } from "antd";
import Header from "./Header";

interface IFormField {
  label: string;
  name?: string;
}

type Props = {
  title: string;
  confirm: FormInstance;
};

const prefix: { [key: string]: string } = {
  "0": ENUM_PREFIX_TYPE.TITLE_TH,
  "1": ENUM_PREFIX_TYPE.TITLE_EN,
  "2": ENUM_PREFIX_TYPE.NATIONALITY,
  "3": ENUM_PREFIX_TYPE.CITIZENSHIP,
  "4": ENUM_PREFIX_TYPE.COUNTRY,
};

const FormGeneral = ({ title, confirm }: Props) => {
  const { t } = useTranslation();
  const [fields, setFields] = useState<IFormField[]>([]);
  const tabsGeneral = useRecoilValue(tabsGeneralState);
  const history = useHistory<RouteComponentProps["history"]>();
  const createPrefixMany = useCreatePrefixMany();

  useEffect(() => {
    const initial: IFormField = { label: "field 1", name: generateUniqueId() };
    setFields([...(fields || []), initial]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (values: any) => {
    let arr: any[] = [];
    for (const [key, value] of Object?.entries(values)) {
      if (key !== "titleTh") {
        arr = [...arr, { name: value, type: prefix?.[tabsGeneral] }];
      }
    }
    handlePrefix(arr);
  };

  const handlePrefix = (values: any) => {
    const createPrefix = values;
    createPrefixMany.mutate(createPrefix, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.push(general);
      },
      onError: (err) => {
        openNotification({ method: "POST_ERROR", message: String(err) });
      },
    });
  };

  const addField = () => {
    const source: string[] | [] = fields?.map((i) => {
      return i?.name || "";
    });
    const name = generateUniqueId(source);
    const next: IFormField[] = [
      ...fields,
      {
        label: `field ${fields?.length + 1}`,
        name: name,
      },
    ];
    setFields(next);
  };

  const deleteField = (_field: IFormField) => {
    const next = fields?.filter((item) => {
      return item?.name !== _field?.name;
    });

    setFields(next);
  };
  return (
    <div>
      <Header confirm={confirm} />
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={<b className="fs-16 t-black-light ">{title}</b>}
        >
          <Card
            className="card-prefix card-prefix-width card-prefix-chi"
            extra={
              <b className="fs-14 c-white ">
                {t("create")}
                {title}
              </b>
            }
          >
            <Form onFinish={onFinish} name="confirm" form={confirm}>
              <Row gutter={[0, 12]}>
                <Col lg={24}>
                  <div className="mb-05 t-grey-light">{title}</div>
                  {fields?.map((item, index) => {
                    return (
                      <div key={index} className="mb-1">
                        <Row gutter={[12, 12]}>
                          <Col lg={22}>
                            <Form.Item
                              name={item?.name}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={2}>
                            {fields?.length > 1 ? (
                              <MinusCircleFilled
                                className="minusCircle"
                                onClick={(e) => deleteField(item)}
                                key={index}
                              />
                            ) : (
                              <MinusCircleFilled
                                className="minusCircle-nodelete"
                                key={index}
                              />
                            )}
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <Col lg={22}>
                    <div className="mb-1">
                      <Button
                        className="add-prefix"
                        type="dashed"
                        ghost
                        onClick={() => addField()}
                      >
                        {`+ ${t("add")}`}
                      </Button>
                    </div>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default FormGeneral;
