import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useGetVisitsByID } from "@services/api/visits";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Header from "./Header";
import ReportNotesPhysical from "@components/report_notes_physical/ReportNotesPhysical";

const ReportPhysicianOrder = () => {
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const pageStyle = `  @page { size:A4;
        margin: 80px;}`;
  const printReportRef = useRef(null);
  const history = useHistory<RouteComponentProps["history"]>();
  const visitByID = useGetVisitsByID(visitID);
  const [divHeight, setDivHeight] = useState<number>(0);

  useEffect(() => {
    setDivHeight(window.innerHeight);
    // eslint-disable-next-line
  }, [window.innerHeight]);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Header history={history} handlePrint={handlePrint} />
      <ReactToPrint content={() => printReportRef.current} />
      <div className="set-layout ">
        <div className="document-body ">
          <div className="display-center ">
            <div className="document-bill">
              <div className="pb-2">
                <ReportNotesPhysical
                  refData={printReportRef}
                  visitsByID={visitByID?.data}
                  divHeight={divHeight}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportPhysicianOrder;
