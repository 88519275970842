import { t } from "i18next";
import { FC } from "react";
import { keyName } from "@interface/IVisits.interface";
import { useHistory } from "react-router-dom";
import { FormInstance } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps, useLocation } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  form: FormInstance;
  isLoading: boolean;
};

const navigationName: { [key: string]: string } = {
  radiology: t("radiologyReport"),
  medical: t("medicalReport"),
  summary: t("medicalSummaryReports"),
};

const Header: FC<Props> = ({ form, isLoading }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { keyName } = location.state as { keyName: keyName; visitID: number };

  const history = useHistory<RouteComponentProps["history"]>();
  return (
    <Row className="second-header " justify="space-between" align="middle">
      <Col>
        <Row gutter={[14, 14]}>
          <BackHome history={history} />

          <Col className="header-text">
            <div>
              <h1>{t("medicalRecord.title")}</h1>
            </div>
            <span className="t-light-gray">
              {`${t("nursery")} / ${t("historyFile")} / ${t(
                "treatmentHistory"
              )} / ${t(`${navigationName?.[keyName] || ""}`)} /  `}
            </span>
            <span className="t-dark-blue f-normal">{t("uploadDocuments")}</span>
          </Col>
        </Row>
      </Col>

      <Col>
        <Button
          className="mr-1 cancel"
          htmlType="reset"
          onClick={history.goBack}
        >
          {t("cancel")}
        </Button>
        <Button
          className="btn-create"
          onClick={() => form.submit()}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
