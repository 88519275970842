export const MedicationIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <path
        fill="#10112c"
        d="M91.85 41.13c-1.07 1.73-2.52 3.23-3.84 4.8-.86 1.02-1.83 1.96-2.69 2.98-.45.53-.82.64-1.5.37-3.71-1.47-7.55-2.05-11.53-1.76-5.22.38-9.88 2.21-14.05 5.34-.36.26-.71.53-1 .74C49.71 46.06 42.2 38.55 34.65 31c.14-.15.35-.38.58-.61 6.21-6.21 12.44-12.41 18.63-18.64 3.59-3.62 7.81-6.08 12.83-7.11.84-.17 1.68-.31 2.52-.47h4.6s.06 0 .09.01l1.18.24h.01c.95.19 1.89.38 2.82.64 7.74 2.24 13.2 7.12 16.07 14.62 2.84 7.43 2.07 14.68-2.13 21.45zM49.51 64.07c-2.8 6.4-2.81 12.93-.4 19.46.34.91.29 1.46-.46 2.12-2.13 1.85-4.03 4-6.3 5.65-5.11 3.72-10.94 4.97-17.17 4.1-6.07-.84-11.15-3.61-15.16-8.26-2.66-3.09-4.39-6.59-5.24-10.47 0-.01-.01-.02-.01-.04-.18-.89-.33-1.8-.43-2.72-.04-.38-.11-.75-.17-1.13v-2.69c.15-.84.27-1.7.41-2.54.01-.05.02-.08.02-.08.12-.7.25-1.39.41-2.08 1.04-4.3 3.24-7.97 6.34-11.09 6.36-6.4 12.75-12.78 19.13-19.16.06-.06.13-.11.13-.11 7.53 7.54 15.06 15.07 22.47 22.49-1.17 2.13-2.57 4.25-3.57 6.55zM71.47 53.15v42.11c-8.42-.55-18.7-8.47-18.74-21-.04-12.28 9.95-20.47 18.74-21.1zM76.89 53.09c10.37 1.19 19.21 10.35 18.7 22-.53 12.19-10.51 19.57-18.7 20.18V53.09zM73.81 4.17h-4.6s2.35-.31 4.6 0z"
      ></path>
      <path
        fill="#10112c"
        d="M75.08 4.42l-1.18-.24c.3.04.59.09.88.16.1.02.2.05.3.08z"
      ></path>
      <path d="M4.58 67.55c-.14.84-.26 1.7-.41 2.54.11-1.35.34-2.27.41-2.54zM4.77 76.63c-.03-.08-.05-.17-.08-.27-.63-2.41-.66-4.63-.52-6.25v2.67c.06.38.13.75.17 1.13.1.92.25 1.83.43 2.72z"></path>
    </svg>
  );
};
