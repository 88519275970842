import { useState } from "react";
import { IGetVisits } from "@interface/IVisits.interface";
import { ISignature } from "@interface/IGlobal.interface";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { KeyNameSignatureFirst } from "@interface/IGlobal.interface";
import { useCreateSignatureRoom } from "@services/api/signature_room";
import { useIsMutating, useQueryClient } from "react-query";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";
import ModalInfo from "@components/signature/ModalInfo";

type Props = {
  history: RouteComponentProps["history"];
  setAction: (e: "create" | "update") => void;
  draftBillForm: FormInstance;
  medicalRecord: boolean;
  visit?: IGetVisits;
};

const Header = ({
  history,
  draftBillForm,
  medicalRecord,
  visit,
  setAction,
}: Props) => {
  const { t } = useTranslation();
  const update = useIsMutating("update-draft-bill-discount");
  const create = useIsMutating("create-bill");
  const client = useQueryClient();
  const [visible, setVisible] = useState({
    first: false,
  });
  const createSignatureRoom = useCreateSignatureRoom();

  const updateSignature = (
    values: {
      date: string;
      title: KeyNameSignatureFirst;
      doctorName?: string;
    } & ISignature
  ) => {
    const file = dataUrlToFile(
      values?.firstSignature,
      `cashierRoomSignature${new Date().getTime()}.png`
    );
    if (file) {
      let formData = new FormData();
      formData?.append("signature", file);
      formData?.append("room", ENUM_ROOM_SIGNATURE.CASHIER);
      !!values?.doctorName &&
        formData?.append("doctorName", values?.doctorName);

      createSignatureRoom.mutate(
        { data: formData, visitId: visit?.id },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            client.invalidateQueries(["get-draft-bill-by-id"]);
            setVisible({
              ...visible,
              [values?.title]: !visible[values?.title],
            });
          },
        }
      );
    } else {
      setVisible({
        ...visible,
        [values?.title]: !visible[values?.title],
      });
    }
  };

  const updateOrCreateBill = (action: "update" | "create") => {
    if (action === "create") {
      setAction("create");
      !!visit?.isSignature?.cashier
        ? setVisible({ first: true })
        : draftBillForm.submit();
    } else if (action === "update") {
      setAction("update");
      draftBillForm.submit();
    }
  };

  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("pay.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("nursery")} / ${t("information")} / `}
              </span>
              <span className="t-dark-blue f-normal">{t("createBill")}</span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button
            className="cancel  "
            htmlType="submit"
            onClick={history.goBack}
          >
            {`${t("close")}${t("window")}`}
          </Button>
          {medicalRecord ? (
            <>
              <Button
                className="btn-create ml-05"
                htmlType="submit"
                onClick={() => updateOrCreateBill("update")}
                loading={!!update}
              >
                {`${t("save")}`}
              </Button>
              <Button
                className="btn-create ml-05"
                htmlType="submit"
                onClick={() => updateOrCreateBill("create")}
                loading={!!create}
              >
                {`${t("createBill")}`}
              </Button>
            </>
          ) : null}
        </Col>
      </Row>
      {visible.first && (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="first"
          title={t("medicalAssistantSignature")}
          updateSignature={updateSignature}
          isLoading={createSignatureRoom?.isLoading}
        />
      )}
    </>
  );
};

export default Header;
