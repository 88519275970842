import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
};

const Header = ({ history }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("medicalRecord.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("nursery")} / ${t("historyFile")} / `}
              </span>
              <span className="t-dark-blue f-normal">
                {t("treatmentHistory")}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default Header;
