import { useState } from "react";
import { IUserList } from "@interface/IUsers.interface";
import { removeMenu } from "@tools/util";
import { Layout, Menu } from "antd";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory, useLocation } from "react-router-dom";
import React from "react";
import navMenu from "../nav";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";

type Props = {
  onCollapse: (collapsed: any) => void;
  collapsed: boolean;
  profile?: IUserList;
};

const TheSideBar = ({ onCollapse, collapsed, profile }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const location = useLocation();
  const { SubMenu } = Menu;
  const { Sider } = Layout;
  const [openKeys, setOpenKeys] = useState<string[]>([]);
  const rootSubmenuKeys = navMenu
    ?.map((item: any) => {
      return item?.route;
    })
    ?.flat(Infinity);
  const userList = React.useContext(UserContext);

  const onChangePath = (pathname: string) => {
    history.replace(pathname);
  };

  const onOpenChange = (keys: any) => {
    const latestOpenKey = keys?.find(
      (key: any) => openKeys?.indexOf(key) === -1
    );

    if (rootSubmenuKeys?.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  return (
    <Sider
      width="240px"
      className="site-layout-background  sider-menubar hidden"
      collapsed={collapsed}
      onCollapse={onCollapse}
    >
      <div className="logo hidden">
        <div className="d-flex justify-center items-center w-80 logo-visible hidden">
          <ImageLogo
            className="circles"
            src={profile?.branch?.image}
            height="60px"
            width="150px"
            alt="logo"
          />
        </div>
      </div>
      <div className="scrollbar sider-menubar-scrollbar hidden">
        <Menu
          className="hidden"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          theme="dark"
          defaultSelectedKeys={[location?.pathname]}
          mode="inline"
        >
          {removeMenu(navMenu, userList)?.map((nav, index) =>
            nav.children ? (
              <SubMenu
                className="hidden"
                key={nav?.route}
                icon={
                  <img
                    src={nav?.icons}
                    width={17}
                    className="icons"
                    alt={nav?.name}
                  />
                }
                title={t(nav?.name)}
              >
                {removeMenu(navMenu, userList)?.[index]?.children?.map(
                  (navChildren) => (
                    <Menu.Item
                      className="custom-tab fs-13 c-menu-item hidden align-menu"
                      key={navChildren?.route}
                      onClick={() => onChangePath(navChildren?.route)}
                    >
                      {t(navChildren?.name)}
                    </Menu.Item>
                  )
                )}
              </SubMenu>
            ) : (
              <Menu.Item
                className="sidebar-menu-main hidden"
                key={nav?.route}
                icon={
                  <img
                    src={nav?.icons}
                    width={17}
                    className="icons"
                    alt={nav?.name}
                  />
                }
                onClick={() => onChangePath(nav?.route)}
              >
                {t(nav?.name)}
              </Menu.Item>
            )
          )}
        </Menu>
      </div>
    </Sider>
  );
};

export default TheSideBar;
