import { Form } from "antd";
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import { useWorkOrderItemRejectDrug } from "@services/api/work_order_item";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";
import MainPatientByIdProvider from "@provider/patient_by_id";

const FormReturnMedicine = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const [selectedRow, setSelectedRow] = useState<number[]>([]);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const workOrderItemRejectDrug = useWorkOrderItemRejectDrug(visitID);
  const [patientList] = Form.useForm();

  useEffect(() => {
    patientList?.setFieldsValue({
      foodAllergy:
        workOrderItemRejectDrug?.data?.result?.[0]?.patient?.foodAllergy ||
        undefined,
      drugAllergy:
        workOrderItemRejectDrug?.data?.result?.[0]?.patient?.drugAllergy ||
        undefined,
    });

    // eslint-disable-next-line
  }, [workOrderItemRejectDrug?.data]);

  return (
    <MainPatientByIdProvider
      value={{
        patientById: workOrderItemRejectDrug?.data?.result?.[0]?.patient,
      }}
    >
      <>
        <Header
          history={history}
          selectedRow={selectedRow}
          orderItemRejectDrug={workOrderItemRejectDrug?.data?.result?.[0]}
        />
        <div className="set-layout ">
          <Fields
            selectedRow={{
              selectedRow: selectedRow || [],
              setSelectedRow: setSelectedRow,
            }}
            orderItemRejectDrug={workOrderItemRejectDrug?.data?.result?.[0]}
            patientList={patientList}
          />
        </div>
      </>
    </MainPatientByIdProvider>
  );
};

export default FormReturnMedicine;
