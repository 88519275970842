import { Card } from "antd";
import { SETTING } from "@assets/data/pathRouter";
import { useState } from "react";
import { ENUM_ROLE_EN } from "@tools/util.enum";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router";
import SearchBranchManagement from "./Search";
import TableBranchManagement from "./Table";
import React from "react";
import RoleContext from "@provider/RoleProvider";
import UserContext from "@provider/UserProvider";

const BranchManagement = () => {
  const { t } = useTranslation();
  const userList = React.useContext(UserContext);
  const history = useHistory<RouteComponentProps["history"]>();
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "BRANCH_MANAGEMENT";
  });
  const handleChangePage = () => {
    history.push(`${SETTING.manageBranchManagement}`);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <Col className="header-text">
              <div>
                <h1>{t("setting")}</h1>
              </div>
              <span className="t-dark-blue f-normal">
                <b>{t("branchManagement.title")}</b>
              </span>
            </Col>
          </Row>
        </Col>

        <Col lg={2} className="justify-col">
          {medicalRecord?.[0]?.isEdit &&
          userList?.role === ENUM_ROLE_EN.SUPER_ADMIN ? (
            <Button
              className="btn-create"
              type="primary"
              onClick={handleChangePage}
            >
              <PlusOutlined className="icons-size" />{" "}
              {t("branchManagement.add")}
            </Button>
          ) : null}
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("branchManagement.title")}</b>}
        >
          <SearchBranchManagement
            setSearchData={setSearchData}
            setCurrentPage={setCurrentPage}
          />
          <TableBranchManagement
            searchData={searchData}
            currentPage={{
              currentPage: currentPage,
              setCurrentPage: setCurrentPage,
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default BranchManagement;
