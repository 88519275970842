import React, { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { doctorroom, OPD } from "@assets/data/pathRouter";
import { useDeletePatient } from "@services/api/patient";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { APPOINTMENT_STATUS, ENUM_ROLE_EN } from "@tools/util.enum";
import { useMainPatientById } from "@provider/patient_by_id";
import { useCreateSaveToOrder } from "@services/api/draft_order";
import { useGetDraftOrderList } from "@services/api/draft_order";
import { useMainPatientProfile } from "@provider/profile/opd/patient_profile";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCreateAppointmentOther } from "@services/api/appointments";
import { useMainProductOutStanding } from "@provider/product_out_standing";
import { useGetVisitsStatus, useUpdateVisits } from "@services/api/visits";
import Delete from "@assets/img/icon/OPD/delete.svg";
import Modaler from "@components/modaler/Modaler";
import BackHome from "@components/button/BackHome";
import ModalSent from "../important_symptoms/ModalSent";
import RoleContext from "@provider/RoleProvider";
import UserContext from "@provider/UserProvider";

const Header = () => {
  const match: any = useRouteMatch();
  const eventID = match.params.eventId;
  const { patientById } = useMainPatientById();
  const { outStanding } = useMainProductOutStanding();
  const { pathID, history } = useMainPatientProfile();
  const createAppointmentOther = useCreateAppointmentOther();
  const { t } = useTranslation();
  const [visible, setVisible] = useState({
    delete: false,
    send: false,
    confirm: false,
  });
  const visitStatus = useGetVisitsStatus(pathID);
  const draftOrderList = useGetDraftOrderList(
    pathID,
    eventID === undefined
      ? visitStatus?.data?.result?.[0]?.eventId || 0
      : eventID
  );
  const roleList = React.useContext(RoleContext);
  const updateVisits = useUpdateVisits();
  const deletePatient = useDeletePatient();
  const userList = React.useContext(UserContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "MEDICAL_RECORD";
  });
  const createSaveToOrder = useCreateSaveToOrder();

  const pushLeadingSymptoms = () => {
    if (!!eventID) {
      history.push(`${OPD.leadingSymptoms}/${pathID}/event/${eventID}`);
    } else {
      history.push(`${OPD.leadingSymptoms}/${pathID}`);
    }
  };

  const pushBookToday = () => {
    history.replace(
      `${OPD.bookToday}/patient/${pathID}/visit/${visitStatus?.data?.result?.[0]?.id}`
    );
  };

  const pushBookInAdvance = () => {
    history.push(`${OPD.bookInAdvance}/${pathID}`);
  };

  const editPatient = () => {
    history.push(`${OPD.basicInfo}/${pathID}`);
  };

  const nursingHistory = () => {
    history.push(`${OPD.nursingHistory}/patient/${pathID}`);
  };

  const onModal = () => {
    setVisible({ ...visible, delete: !visible?.delete });
  };

  const onDelete = () => {
    deletePatient.mutate(pathID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
      onSettled: () => {
        history.push(OPD.title);
        onModal();
      },
    });
  };

  const handleOkConfirm = (key: "confirm" | "send") => {
    createAppointmentOther.mutate(visitStatus?.data?.result?.[0]?.id, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.push(OPD.title);
        handleCancel(key);
      },
      onError: (err) => {
        openNotification({ method: "POST_ERROR", message: String(err) });
      },
      onSettled: () => {
        if (key === "send") {
          handleUpdateVisits();
        }
        if (
          draftOrderList?.data?.result?.[0]?.outType &&
          draftOrderList?.data?.result?.[0]?.outType?.groups?.length > 0 &&
          patientById?.preregisterProduct === true
        ) {
          handlePostSaveToOrder();
        }
      },
    });
  };

  const handlePostSaveToOrder = async () => {
    const data =
      draftOrderList?.data?.result?.[0]?.outType?.groups?.[0]?.children?.[0]?.children?.map(
        (item) => {
          return item?.id;
        }
      );

    createSaveToOrder.mutate(
      { data: { uuids: data }, id: pathID },
      {
        onSettled: () => {
          setVisible({ ...visible, send: false });
          history.replace(`${OPD.title}`);
        },
      }
    );
  };

  const handleUpdateVisits = () => {
    const formData = new FormData();
    formData?.append("proceed", "1");
    updateVisits.mutate(
      { data: formData, id: visitStatus?.data?.result?.[0]?.id },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const handleCancel = (key: "confirm" | "send") => {
    setVisible({ ...visible, [key]: false });
  };

  const disabled = () => {
    if (
      (!visitStatus?.data?.result?.[0]?.proceed &&
        visitStatus?.data?.result?.[0]?.proceed !== 0) ||
      visitStatus?.data?.result?.[0]?.proceed > 5
    ) {
      return false;
    } else {
      return true;
    }
  };

  const checkStatus = () => {
    if (
      visitStatus?.data?.result?.[0]?.proceed === 0 &&
      visitStatus?.data?.result?.[0]?.slotId === null &&
      (visitStatus?.data?.result?.[0]?.appointment?.status ===
        APPOINTMENT_STATUS.WAITING_FOR_ADVICE ||
        !visitStatus?.data?.result?.[0]?.appointment?.status)
    ) {
      return 1;
    } else if (
      visitStatus?.data?.result?.[0]?.proceed === 0 &&
      visitStatus?.data?.result?.[0]?.slotId !== null &&
      visitStatus?.data?.result?.[0]?.appointment?.status ===
        APPOINTMENT_STATUS.WAITING_FOR_ADVICE
    ) {
      return 2;
    } else {
      return false;
    }
  };

  const checkAppointmentStatus = () => {
    if (
      visitStatus?.data?.result?.[0]?.appointment?.status ===
        APPOINTMENT_STATUS.WAITING_FOR_ADVICE ||
      visitStatus?.data?.result?.[0]?.appointment?.status ===
        APPOINTMENT_STATUS.ADVISED ||
      visitStatus?.data?.result?.[0]?.appointment?.status ===
        APPOINTMENT_STATUS.ADVISING
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <React.Fragment>
      <Modaler
        visible={visible?.delete}
        onCancel={onModal}
        loadingButton={deletePatient?.isLoading}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>
      <Row
        id="height-medical-record"
        className="second-header "
        justify="space-between"
        align="middle"
      >
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} goHome={OPD.title} />

            <Col className="header-text">
              <div>
                <h1>{t("medicalRecord.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-normal">{t("historyFile")}</span>
            </Col>
          </Row>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col style={{ marginLeft: "auto" }}>
            {userList?.role === ENUM_ROLE_EN.SUPER_ADMIN ? (
              <Button
                className=" delete mr-05 "
                htmlType="submit"
                onClick={(e) => {
                  onModal();
                  e?.stopPropagation();
                }}
              >
                <img
                  className="circle editor-button"
                  src={Delete}
                  alt="delete"
                />
              </Button>
            ) : null}
            <Button
              className="edit mr-05 "
              htmlType="submit"
              onClick={editPatient}
            >
              {t("edit")}
            </Button>

            <Button
              className="history-opd mr-05 "
              htmlType="submit"
              onClick={nursingHistory}
            >
              {t("treatmentHistory")}
            </Button>

            <Button
              className="mr-05 reserve"
              htmlType="reset"
              onClick={pushBookInAdvance}
            >
              {t("bookAdvance")}
            </Button>

            {disabled() ? (
              <>
                {checkStatus() === 1 ? (
                  <>
                    {outStanding?.[0]?.items ||
                    (draftOrderList?.data?.result?.[0]?.outType &&
                      draftOrderList?.data?.result?.[0]?.outType?.groups
                        ?.length > 0) ? (
                      <>
                        <Button
                          className="btn-create mr-05"
                          onClick={() => setVisible({ ...visible, send: true })}
                        >
                          {`${t("sendToProcessOrder")}`}
                        </Button>
                      </>
                    ) : null}
                    {checkAppointmentStatus() ? (
                      <>
                        <Button
                          className="send-lab mr-05"
                          onClick={() =>
                            setVisible({ ...visible, confirm: true })
                          }
                        >
                          {`${t("sentToTheOrderConfirmationRoom")}`}
                        </Button>
                        <Button
                          className=" btn-create mr-05"
                          onClick={pushBookToday}
                        >
                          {`${t("sendTo")}${t("doctor")}`}
                        </Button>
                      </>
                    ) : null}
                  </>
                ) : checkStatus() === 2 ? (
                  <>
                    {outStanding?.[0]?.items ||
                    (draftOrderList?.data?.result?.[0]?.outType &&
                      draftOrderList?.data?.result?.[0]?.outType?.groups
                        ?.length > 0 &&
                      patientById?.preregisterProduct === true) ? (
                      <Button
                        className="btn-create "
                        onClick={(e) => history.push(doctorroom)}
                      >
                        {`${t("sendTo")}${t("doctorroom")}`}
                      </Button>
                    ) : null}
                  </>
                ) : null}
              </>
            ) : (
              <Button className="btn-create" onClick={pushLeadingSymptoms}>
                {t("createLeadingSymptoms")}
              </Button>
            )}
          </Col>
        ) : null}
      </Row>
      {visible?.send && (
        <ModalSent
          visible={visible?.send}
          handleOk={() => handleOkConfirm("send")}
          handleCancel={() => handleCancel("send")}
          title={`${t("sendToProcessOrder")}`}
          loadingButton={createAppointmentOther?.isLoading}
        />
      )}
      {visible?.confirm && (
        <ModalSent
          visible={visible?.confirm}
          handleOk={() => handleOkConfirm("confirm")}
          handleCancel={() => handleCancel("confirm")}
          title={`${t("confirmSentToTheOrderConfirmationRoom")}`}
          loadingButton={createAppointmentOther?.isLoading}
        />
      )}
    </React.Fragment>
  );
};
export default Header;
