import { SETTING } from "@assets/data/pathRouter";
import { ENUM_MENU, ENUM_ROLE_EN } from "@tools/util.enum";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { tabsWarehouseState } from "@tools/recoil/recoil-atom";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import React from "react";
import RoleContext from "@provider/RoleProvider";
import TabsWarehouse from "./Tabs";
import UserContext from "@provider/UserProvider";
import { useGetProductExport } from "@services/api/product";

const Warehouse = () => {
  const { t } = useTranslation();
  const [tabsWarehouse, setTabsWarehouse] = useRecoilState(tabsWarehouseState);
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const userList = React.useContext(UserContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === ENUM_MENU.WAREHOUSE;
  });
  const exportProduct = useGetProductExport();
  const onClick = () => {
    exportProduct.mutate("", {});
  };
  const handleCreateWareHouse = () => {
    history.push(`${SETTING.createPurchaseOrder}`);
  };

  const handleWareHouseUploadFile = () => {
    history.push(`${SETTING.wareHouseUploadFile}`);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("setting")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("wareHouse.title")}</b>
          </span>
        </Col>
        <Col style={{ marginLeft: "auto" }}>
          {medicalRecord?.[0]?.isEdit ? (
            tabsWarehouse === "1" &&
            userList?.role === ENUM_ROLE_EN.SUPER_ADMIN ? (
              <React.Fragment>
                <Button
                  className="btn-create mr-05"
                  type="primary"
                  onClick={handleWareHouseUploadFile}
                >
                  {t("wareHouse.upload")}
                </Button>
                <Button
                  className="btn-create mr-05"
                  type="primary"
                  onClick={() => onClick()}
                  loading={exportProduct.isLoading}
                >
                  {t("download")}
                </Button>
              </React.Fragment>
            ) : tabsWarehouse === "2" ? (
              <Button
                className="btn-create"
                type="primary"
                onClick={handleCreateWareHouse}
              >
                <PlusOutlined className="icons-size" />
                {`${t("createPurchaseOrder")}`}
              </Button>
            ) : null
          ) : null}
        </Col>
        {/* {medicalRecord?.[0]?.isEdit ? (
          tabsWarehouse === "1" &&
          userList?.role === ENUM_ROLE_EN.SUPER_ADMIN ? (
            <></>
          ) : // <Col md={10} lg={10} style={{ marginLeft: "auto" }}>
          //   <React.Fragment>
          //     <Button
          //       className="btn-create"
          //       type="primary"
          //       onClick={handleWareHouseUploadFile}
          //     >
          //       <PlusOutlined className="icons-size" />
          //       {`${t("wareHouse.upload")}`}
          //     </Button>
          //     <Button
          //       className="btn-create"
          //       type="primary"
          //       onClick={handleWareHouseUploadFile}
          //     >
          //       <PlusOutlined className="icons-size" />
          //       {`${t("wareHouse.upload")}`}
          //     </Button>
          //   </React.Fragment>
          // </Col>
          tabsWarehouse === "2" ? (
            <Col md={10} lg={10} className="justify-col">
              <Button
                className="btn-create"
                type="primary"
                onClick={handleCreateWareHouse}
              >
                <PlusOutlined className="icons-size" />
                {`${t("createPurchaseOrder")}`}
              </Button>
            </Col>
          ) : null
        ) : null} */}
      </Row>
      <div className="set-layout ">
        <Card className="bg-grey ">
          <TabsWarehouse
            tabsWarehouse={tabsWarehouse}
            setTabsWarehouse={setTabsWarehouse}
          />
        </Card>
      </div>
    </div>
  );
};

export default Warehouse;
