import { Card } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import FieldsBounceGoods from "./Fields";
import HeaderBounceGoods from "./Header";

const FormBounceGoods = () => {
  const { t } = useTranslation();
  const [manageBounceGoods] = useForm();
  return (
    <div>
      <HeaderBounceGoods manageBounceGoods={manageBounceGoods} />
      <div className="set-layout ">
        <Card
          className=" pb-2"
          extra={<b className="header-card">{t("bounceGoodsManagement")}</b>}
        >
          <FieldsBounceGoods manageBounceGoods={manageBounceGoods} />
        </Card>
      </div>
    </div>
  );
};

export default FormBounceGoods;
