import { IGetRoom } from "@interface/IRoom.interface";
import { thrownResponse } from "./auth";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetRoomList = (
  params?: any
): UseQueryResult<Elementary<IGetRoom[]>> => {
  return useQuery(["get-room-list", params], async () => {
    const res = await axios.get(`/room`, { params: params });

    if ([200, 201].includes(res?.status)) {
      const dataRoom = res?.data?.result?.[0]?.data?.map((item: any) => {
        return {
          ...item,
          text: item?.name,
        };
      });

      return {
        ...res?.data,
        result: [
          {
            ...res?.data?.result?.[0],
            data: dataRoom,
          },
        ],
      };
    }
    thrownResponse(res);
  });
};

export const useGetRoomByID = (
  id: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IGetRoom>> => {
  return useQuery(["get-room-by-ID", id, params], async () => {
    if (!id && id !== 0) {
      return null;
    }

    const res = await axios.get(`/room/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateRoom = () => {
  return useMutation(["update-room"], async ({ id, data }: any) => {
    const res = await axios.put(`/room/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateRoom = () => {
  return useMutation(["create-room"], async ({ data }: any) => {
    const res = await axios.post(`/room`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteRoom = () => {
  return useMutation(["delete-room"], async (id?: number) => {
    if (!id && id !== 0) {
      return null;
    }
    const res = await axios.delete(`/room/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
