import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  locationPath: string;
  history: RouteComponentProps["history"];
  billForm: FormInstance;
  medicalRecord: boolean;
};

const Header = ({ locationPath, history, billForm, medicalRecord }: Props) => {
  const { t } = useTranslation();
  const create = useIsMutating("create-invoice");
  const update = useIsMutating("update-bill");

  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("pay.title")}</h1>
              </div>
              <span className="t-light-gray">
                {t("nursery")} / {t("information")} /{" "}
              </span>
              <span className="t-dark-blue f-normal">
                {locationPath.includes("create-invoice")
                  ? t("createInvoice")
                  : t("editBill")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button
            className="cancel  "
            htmlType="submit"
            onClick={history.goBack}
          >
            {`${t("close")}${t("window")}`}
          </Button>
          {medicalRecord ? (
            locationPath.includes("create-invoice") ? (
              <Button
                className="btn-create ml-05"
                htmlType="submit"
                onClick={() => billForm.submit()}
                loading={!!create}
              >
                {`${t("save")}${t("document")}`}
              </Button>
            ) : (
              <Button
                className="btn-create ml-05"
                htmlType="submit"
                onClick={() => billForm.submit()}
                loading={!!update}
              >
                {`${t("update")}${t("document")}`}
              </Button>
            )
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Header;
