import { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Col, Drawer, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import dayjs from "dayjs";
import Affixes from "@components/affixes/Affixes";
import BackHome from "@components/button/BackHome";
import Scheduler from "./Scheduler";
import ListQueue from "@components/list_queue/ListQueue";

const BookToday = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [currentDateChange, setCurrentDateChange] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const [visible, setVisible] = useState<boolean>(false);

  const showDrawer = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("medicalRecord.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-light-gray">{t("historyFile")} / </span>
              <span className="t-dark-blue f-normal">{t("queue")} </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout body-height">
        <Row gutter={[14, 14]}>
          <Col lg={17}>
            <Scheduler
              currentDateChange={{
                currentDateChange: currentDateChange,
                setCurrentDateChange: setCurrentDateChange,
              }}
            />
          </Col>
          <Col lg={7} className="card-display">
            <ListQueue
              currentDateChange={{
                currentDateChange: currentDateChange,
                setCurrentDateChange: setCurrentDateChange,
              }}
            />
          </Col>
        </Row>

        <Affixes onClick={showDrawer} />

        <Drawer
          width={"50%"}
          closable={false}
          placement="right"
          onClose={showDrawer}
          visible={visible}
        >
          <ListQueue
            status="noCard"
            currentDateChange={{
              currentDateChange: currentDateChange,
              setCurrentDateChange: setCurrentDateChange,
            }}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default BookToday;
