import { IGetRoom } from "@interface/IRoom.interface";
import { IUserRoom } from "@interface/IUserRoom.interface";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, FormInstance, Row } from "antd";
import Selector from "../../selector/Selector";

type Props = {
  doctorList: IUserRoom[];
  roomList: IGetRoom[];
  searchOnFinish: (values: any) => void;
  formSearch: FormInstance;
  status: string;
};

const SearchFunctionAll = ({
  doctorList,
  roomList,
  searchOnFinish,
  formSearch,
  status,
}: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="search-function-all">
      <Form onFinish={searchOnFinish} name="formSearch" form={formSearch}>
        <Row gutter={[14, 14]}>
          {status === "book-another-room" ? (
            <Col md={10} lg={14} xxl={16}>
              <Form.Item name="roomId">
                <Selector
                  placeholder={t("allRooms")}
                  Source={roomList}
                  keyName="name"
                  keyValue="id"
                />
              </Form.Item>
            </Col>
          ) : (
            <Col md={10} lg={14} xxl={16}>
              <Form.Item name="userId">
                <Selector
                  placeholder={t("allDoctorNames")}
                  Source={doctorList}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="userId"
                />
              </Form.Item>
            </Col>
          )}

          <Col md={7} lg={7} xxl={6} className="d-flex justify-between">
            <Button className="mr-1 btn-search" htmlType="submit" block>
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset" block>
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchFunctionAll;
