import { ENUM_ID_CARD } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { ISeparatePrefix } from "@interface/IOpd.interface";
import { useEffect, useState } from "react";
import { bloodGroup, bloodType, cardType } from "@assets/data/data";
import { Card, Col, Form, Input, Row, FormInstance } from "antd";
import { day, gender, maritalStatus, month, religion } from "@assets/data/data";
import Selector from "@components/selector/Selector";
import ImageProfile from "@components/file_space/ImageProfile";
import SelectorChildrenValue from "@components/selector/SelectorChildrenValue";

type Props = { basicInfoForm: FormInstance; prefixList?: ISeparatePrefix };

const GeneralInformation = ({ basicInfoForm, prefixList }: Props) => {
  const [yearSelect, setYearSelect] = useState<any[]>([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    setYear();
  }, []);

  const setYear = () => {
    const yearNow = new Date().getFullYear();
    const year = new Array(130)
      ?.fill({ name: "", id: "" })
      ?.map((_: any, index: number) => {
        return { name: `${yearNow + 543 - index}`, id: `${yearNow - index}` };
      });
    setYearSelect(year);
  };

  const changeYear = (year: string) => {
    basicInfoForm?.setFieldsValue({
      year: year ? (Number(year) - 543)?.toString() : undefined,
    });
  };

  const getFile = (e: any) => {
    if (Array?.isArray(e)) {
      return e;
    }
    return e && e?.fileList;
  };

  const getFileName = () => {
    return false;
  };

  return (
    <Card className="mt-2">
      <div className="title-in-card mt-1">
        {`${t("part")} 1 ${t("generalInfo.title")}`}
      </div>

      <Row className="mt-0.5">
        <Col md={24} lg={18}>
          <Row gutter={[14, 14]} className="margin-form">
            <Col md={10} lg={3}>
              <Form.Item
                label={t("prefixTh")}
                name="titleTh"
                rules={[{ required: true, message: t("pleaseSelect") }]}
              >
                <Selector
                  placeholder=""
                  Source={prefixList?.TITLE_TH || []}
                  keyName="name"
                  keyValue="name"
                  onChange={() => {
                    document.getElementById("firstNameTh")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={14} lg={10}>
              <Form.Item
                label={t("nameTh")}
                name="firstNameTh"
                rules={[
                  // {
                  //   pattern: /^[ก-๏\s]+$/,
                  //   message: t("pleaseFillOutTheInformationInThai"),
                  // },
                  { required: true },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={24} lg={11}>
              <Form.Item
                label={t("lastNameTh")}
                name="lastNameTh"
                rules={[
                  // {
                  //   pattern: /^[ก-๏\s]+$/,
                  //   message: t("pleaseFillOutTheInformationInThai"),
                  // },
                  { required: true },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={10} lg={3}>
              <Form.Item
                label={t("prefixEn")}
                name="titleEn"
                rules={[{ required: true, message: t("pleaseSelect") }]}
              >
                <Selector
                  placeholder=""
                  Source={prefixList?.TITLE_EN || []}
                  keyName="name"
                  keyValue="name"
                  onChange={() => {
                    document.getElementById("firstNameEn")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={14} lg={10}>
              <Form.Item
                label={t("nameEn")}
                name="firstNameEn"
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/,
                    message: t("pleaseFillOutTheInformationInEnglish"),
                  },
                  { required: true },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={24} lg={11}>
              <Form.Item
                label={t("lastNameEn")}
                name="lastNameEn"
                rules={[
                  {
                    pattern: /^[a-zA-Z\s]+$/,
                    message: t("pleaseFillOutTheInformationInEnglish"),
                  },
                  { required: true },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col md={7} lg={3}>
              <Form.Item label={t("sex")} name="gender">
                <Selector
                  placeholder=""
                  Source={gender}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                  onChange={() => {
                    document.getElementById("day")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col className="mt-1" md={17} lg={10}>
              <div className="t-grey-form">
                <span className="form-item-required-data">*</span>
                {t("dateOfBirth")} :
              </div>
              <div className="mt-form">
                <Row gutter={[5, 14]}>
                  <Col md={6} lg={4}>
                    <Form.Item
                      name="day"
                      rules={[{ required: true, message: t("pleaseSelect") }]}
                    >
                      <SelectorChildrenValue
                        placeholder={t("day")}
                        Source={day}
                        keyName="name"
                        keyValue="id"
                        onChange={() => {
                          document.getElementById("month")?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col md={10} lg={10}>
                    <Form.Item
                      name="month"
                      rules={[{ required: true, message: t("pleaseSelect") }]}
                    >
                      <SelectorChildrenValue
                        placeholder={t("month")}
                        Source={month}
                        keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                        keyValue="id"
                        onChange={() => {
                          document.getElementById("year")?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col md={8} lg={10}>
                    <Form.Item
                      name="year"
                      rules={[{ required: true, message: t("pleaseSelect") }]}
                    >
                      <SelectorChildrenValue
                        placeholder={t("year")}
                        Source={yearSelect}
                        keyName="id"
                        keyValue="name"
                        onChange={(e) => {
                          document.getElementById("maritalStatus")?.focus();
                          changeYear(e);
                        }}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={24} lg={11}>
              <Form.Item label={t("maritalStatus")} name="maritalStatus">
                <Selector
                  placeholder=""
                  Source={maritalStatus}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                  onChange={() => {
                    document.getElementById("idCard")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={10} lg={3}>
              <Form.Item
                label={t("cardType")}
                name="idCard"
                rules={[{ required: true, message: t("pleaseSelect") }]}
              >
                <Selector
                  onChange={(e) => {
                    document.getElementById("idCardNumber")?.focus();
                    basicInfoForm.setFieldsValue({
                      ...basicInfoForm,
                      idCardNumber: "",
                    });
                  }}
                  placeholder=""
                  Source={cardType}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                />
              </Form.Item>
            </Col>

            <Form.Item
              shouldUpdate={(prev, next) => prev?.idCard !== next?.idCard}
              noStyle
            >
              {(form: FormInstance) => {
                const idCard = form.getFieldValue("idCard");

                if (idCard === ENUM_ID_CARD.PASSPORT) {
                  return (
                    <Col md={14} lg={7}>
                      <Form.Item
                        label={t("cardNumber")}
                        name="idCardNumber"
                        rules={[{ required: true }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  );
                } else if (idCard === ENUM_ID_CARD.CITIZEN) {
                  return (
                    <Col md={14} lg={7}>
                      <Form.Item
                        label={t("cardNumber")}
                        name="idCardNumber"
                        rules={[
                          { required: true },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.resolve();
                              } else if (!/^(-$)|\d+$/.test(value)) {
                                return Promise.reject(
                                  new Error(t("pleaseFillInNumbers"))
                                );
                              } else if (
                                value?.length > 13 ||
                                value?.length < 13
                              ) {
                                return Promise.reject(
                                  new Error(t("theCardNumberLess"))
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  );
                } else {
                  return null;
                }
              }}
            </Form.Item>

            <Col md={8} lg={7}>
              <Form.Item label={t("citizenship")} name="citizenship">
                <Selector
                  placeholder=""
                  Source={prefixList?.CITIZENSHIP || []}
                  keyName="name"
                  keyValue="name"
                  onChange={() => {
                    document.getElementById("nationality")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={7}>
              <Form.Item label={t("nationality")} name="nationality">
                <Selector
                  placeholder=""
                  Source={prefixList?.NATIONALITY || []}
                  keyName="name"
                  keyValue="name"
                  onChange={() => {
                    document.getElementById("religion")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={5}>
              <Form.Item label={t("religion")} name="religion">
                <Selector
                  placeholder=""
                  Source={religion}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                  onChange={() => {
                    document.getElementById("bloodGroup")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={6}>
              <Form.Item label={t("bloodGroup")} name="bloodGroup">
                <Selector
                  placeholder=""
                  Source={bloodGroup}
                  keyName="name"
                  keyValue="id"
                  onChange={() => {
                    document.getElementById("bloodType")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={6}>
              <Form.Item label="RH Factor" name="bloodType">
                <Selector
                  placeholder=""
                  Source={bloodType}
                  keyName="name"
                  keyValue="name"
                  onChange={() => {
                    document.getElementById("height")?.focus();
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={8} lg={7}>
              <Form.Item label={t("height")} name="height">
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} lg={7}>
              <Form.Item label={t("occupation")} name="occupation">
                <Input />
              </Form.Item>
            </Col>

            <Col md={8} lg={7}>
              <Form.Item label={t("lineId")} name="lineUserId">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>
        <Col md={24} lg={6} className="uploadImageBorder">
          <Form.Item name="image" getValueFromEvent={getFile}>
            <ImageProfile
              form={basicInfoForm}
              accept=".png,.jpg,.jpeg"
              name="image"
              beforeUpload={getFileName}
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default GeneralInformation;
