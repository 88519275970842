import React, { useState } from "react";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import TableOrderList from "./OrderList";

type Props = {
  patientID: number;
  draftOrderList?: any;
};

const OrderHistory = ({ patientID, draftOrderList }: Props) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(true);

  const viewDetail = () => {
    setOpen(!open);
  };

  return (
    <div className="mt-2">
      <Row className="header-table  ">
        <Col lg={18} className="f-normal col-start t-dark-blue">
          <span className="f-bold mr-05 ">{t("orderList")} </span>

          <span className="ml-05">
            {React.createElement(open ? UpOutlined : DownOutlined, {
              onClick: viewDetail,
            })}
          </span>
        </Col>
      </Row>

      <div className={open ? "open" : "close"}>
        <TableOrderList patientID={patientID} draftOrderList={draftOrderList} />
      </div>
    </div>
  );
};

export default OrderHistory;
