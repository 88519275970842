import { Card } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import FieldsPurchaseOrder from "./Fields";
import HeaderPurchaseOrder from "./Header";

const FormPurchaseOrder = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const purchaseOrderId = match.params.id;
  const [managePurchaseOrder] = useForm();
  return (
    <div>
      <HeaderPurchaseOrder managePurchaseOrder={managePurchaseOrder} />
      <div className="set-layout ">
        <Card
          className=" pb-2"
          extra={
            <b className="header-card">
              {!!purchaseOrderId
                ? t("purchaseOrderManagement")
                : t("createPurchaseOrder")}
            </b>
          }
        >
          <FieldsPurchaseOrder managePurchaseOrder={managePurchaseOrder} />
        </Card>
      </div>
    </div>
  );
};

export default FormPurchaseOrder;
