import { OPD } from "@assets/data/pathRouter";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Header from "./Header";
import DetailLabInterface from "@components/lab_interface/detail/Detail";

const Detail = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  return (
    <div>
      <Header history={history} />
      <div className="set-layout ">
        <DetailLabInterface link={OPD.detailLabResult} />
      </div>
    </div>
  );
};

export default Detail;
