import { Popover } from "antd";
import { TextBRHTML } from "@tools/util";
import { IAppointments } from "@interface/IAppointments.interface";
import { useTranslation } from "react-i18next";
import { ISearchDetailSlots } from "@interface/IAppointments.interface";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import MoTable from "../table/Table";
import FormReserveQueue from "../form_reserve_queue/FormReserveQueue";

type Props = {
  getAppointmentsList: (e?: any) => void;
  current: {
    currentPage: number;
    setCurrentPage: any;
  };
  limit: {
    limitPage: number;
    setLimitPage: any;
  };
  total: number;
  appointmentsList?: IAppointments[];
  search: ISearchDetailSlots;
};

const PatientQueue = ({
  getAppointmentsList,
  current,
  limit,
  total,
  appointmentsList,
  search,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [appointmentsID, setAppointmentsID] = useState<number>();
  const [appointmentStatus, setAppointmentStatus] = useState<string>();
  useEffect(() => {
    getAppointmentsList(search);
    // eslint-disable-next-line
  }, [current.currentPage, limit.limitPage, search]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") limit.setLimitPage(page);
    else current.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    setVisible(!visible);
    setAppointmentsID(event.id);
    setAppointmentStatus(event.status);
  };

  const columns: any = [
    {
      title: t("queue"),
      dataIndex: "id",
      key: "index",
      align: "center",

      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limit.limitPage * (current.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "keyHN",

      sorter: (a: any, b: any) => a.id - b.id,
      render: (item: any) => {
        return <div key={item?.id}>{item?.hn}</div>;
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "patient",
      render: (item: any) => {
        const name =
          i18n.language === "th"
            ? [item?.firstNameTh, item?.lastNameTh]?.join("   ")?.trim()
            : [item?.firstNameEn, item?.lastNameEn]?.join("   ")?.trim();
        return (
          <div className="f-normal " key={item?.id}>
            {name}
          </div>
        );
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "patient",
      key: "mobile",
      render: (item: any) => {
        return <div key={item?.id}>{item?.mobile}</div>;
      },
    },

    {
      title: t("status"),
      dataIndex: "status",
      key: "status",

      render: (status: any) => {
        let color;
        if (status === `ADVISED`) {
          color = "#00377C";
        } else if (status === `ADVISING`) {
          color = "#77C48B";
        } else if (status === `WAITING_FOR_TREATMENT`) {
          color = "#7AC692";
        } else if (status === `WAITING_FOR_ADVICE`) {
          color = "#498DCB";
        } else if (status === `CANCEL`) {
          color = "#FF5D5D";
        } else if (status === `BOOK_IN_ADVANCE`) {
          color = "#989898";
        }

        return (
          <div
            className="table-queue-status mr-1"
            style={{ backgroundColor: color }}
          >
            {status === `WAITING_FOR_ADVICE`
              ? t("waitConsult")
              : status === `ADVISING`
              ? t("consulting")
              : status === `WAITING_FOR_TREATMENT`
              ? t("waitingForTreatment")
              : status === `ADVISED`
              ? t("consulted")
              : status === `CANCEL`
              ? t("cancel")
              : status === `BOOK_IN_ADVANCE` && t("bookAdvance")}
          </div>
        );
      },
    },

    {
      title: t("remarks"),
      dataIndex: "detail",
      key: "detail",
      width: "10px",
      render: (detail: string) => {
        return (
          <Popover
            content={
              <div
                dangerouslySetInnerHTML={{ __html: TextBRHTML(detail || "-") }}
              />
            }
          >
            <div className="text-content">{detail}</div>
          </Popover>
        );
      },
    },

    {
      title: t("manage"),
      dataIndex: "detail",
      key: "detail",
      render: (_: any, data: any) => {
        return (
          <div key={data?.id}>
            <div>{` ${data?.editBy?.firstName || ""}    ${
              data?.editBy?.lastName || ""
            }`}</div>
            <div>{`${dayjs(data?.updatedAt).format("DD-MM-BBBB  HH:mm")}`}</div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <MoTable
        dataSource={appointmentsList}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limit.limitPage,
          currentPage: current.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
      {visible && (
        <FormReserveQueue
          setVisible={setVisible}
          visible={visible}
          getAppointmentsList={getAppointmentsList}
          appointmentsID={appointmentsID}
          appointmentStatus={appointmentStatus}
        />
      )}
    </div>
  );
};

export default PatientQueue;
