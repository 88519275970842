import React, { useEffect, useState } from "react";
import { IGroup } from "@interface/IDraftOrder.interface";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { ISelected, ITotalAndQuantity } from "@interface/IGlobal.interface";
import ProductCategory from "@components/product_category/ProductCategory";
import TableConfirmOrder from "../table/Table";

type Props = {
  patientID: string | number;
  draftOrderList?: IGroup[];
  selected: ISelected;
  totalAndQuantity: ITotalAndQuantity;
  medicalRecord: boolean;
  eventId: number;
  status?: string;
};

const OrderContinuousList = ({
  patientID,
  draftOrderList,
  selected,
  totalAndQuantity,
  medicalRecord,
  eventId,
  status,
}: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [openOrder, setOpenOrder] = useState<boolean>();

  useEffect(() => {
    setOpenOrder(!!draftOrderList?.length);
    //eslint-disable-next-line
  }, [draftOrderList]);

  const createNow = () => {
    setVisible(true);
  };

  const viewDetailConfirm = () => {
    setOpenOrder(!openOrder);
  };

  const onManageModal = () => {
    setVisible(!visible);
  };

  return (
    <div className="mt-2">
      <Row className="header-table " justify="space-between">
        <Col className="f-normal col-start t-dark-blue">
          <span className="f-bold mr-05 ">
            {`${t("list")}${t("order")} Continuous`}{" "}
          </span>
          <span className="mr-05">
            {React.createElement(openOrder ? UpOutlined : DownOutlined, {
              onClick: viewDetailConfirm,
            })}
          </span>
        </Col>
        {medicalRecord ? (
          <Col>
            <Button type="primary" onClick={createNow}>
              {`${t("edit")}${t("order")}`}
            </Button>
          </Col>
        ) : null}
      </Row>

      <div className={openOrder ? "open" : "close"}>
        <TableConfirmOrder
          patientID={patientID}
          draftOrderList={draftOrderList}
          selected={selected}
          totalAndQuantity={totalAndQuantity}
          medicalRecord={medicalRecord}
          nameKey="continuous"
          eventId={eventId}
        />
      </div>

      {visible && (
        <ProductCategory
          patientID={patientID}
          visible={visible}
          onModal={onManageModal}
          type="CONTINUE"
          eventId={eventId}
          status={status}
        />
      )}
    </div>
  );
};

export default OrderContinuousList;
