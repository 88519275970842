import { ISearch } from "./interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { Col, Row, Card } from "antd";
import { useTranslation } from "react-i18next";
import { useGetPatientsList } from "@services/api/patient";
import { useEffect, useState } from "react";
import TableSignCourse from "./Table";
import SearchSignCourse from "./Search";

const SignCourse = () => {
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<ISearch>({} as ISearch);
  const [total, setTotal] = useState<number>(1);
  const { t } = useTranslation();

  const patientList = useGetPatientsList({
    page: currentPage,
    limit: limitPage,
    sort: ENUM_ORDERBY.DESC,
    ...searchValue,
  });

  useEffect(() => {
    setTotal(patientList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [patientList?.data?.result?.[0]?.total]);

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("signCourse.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("nursery")}</b>
          </span>
        </Col>
        <Col lg={2} className="justify-col"></Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("allNursery")}</b>}
        >
          <SearchSignCourse setSearchValue={setSearchValue} />
          <TableSignCourse
            currentPage={{
              currentPage: currentPage,
              setCurrentPage: setCurrentPage,
            }}
            limitPage={{ limitPage: limitPage, setLimitPage: setLimitPage }}
            visitList={patientList?.data}
            total={total}
          />
        </Card>
      </div>
    </div>
  );
};

export default SignCourse;
