import React, { FC, useEffect } from "react";
import { IGetVisits } from "@interface/IVisits.interface";
import { FormInstance } from "antd/es/form/Form";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useUpdateVisits } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { Button, Col, Divider, Form, Modal, Row } from "antd";
import dayjs from "dayjs";
import VitalSign from "@components/vital_sign/VitalSign";
import RoleContext from "@provider/RoleProvider";

type IKeyName = "sitting1Min" | "standing1Mins" | "lyingDown" | "doctorRoom";
interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

type Props = {
  visible: IVisible;
  title: string;
  vitalSign: FormInstance;
  visitsByID?: IGetVisits;
  keyName: IKeyName;
};

const ModalVitalSign: FC<Props> = ({
  visible,
  title,
  vitalSign,
  visitsByID,
  keyName,
}) => {
  const { t } = useTranslation();
  const roleList = React.useContext(RoleContext);
  const match: any = useRouteMatch();
  const appointmentsID = match.params.appointmentsID;
  const visitID = match.params.visitID;
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "MEDICAL";
  });
  const updateVisits = useUpdateVisits();
  const client = useQueryClient();

  useEffect(() => {
    vitalSign.setFieldsValue({
      time: dayjs().format("HH:mm"),
    });

    if (!visitsByID?.data) return;

    if (!!visitsByID?.data) {
      vitalSign.resetFields();
      vitalSign.setFieldsValue({
        time: dayjs().format("HH:mm"),
        height:
          visitsByID?.data?.sitting1Min?.height ||
          visitsByID?.data?.lyingDown?.height ||
          visitsByID?.data?.doctorRoom?.height ||
          visitsByID?.data?.visit?.height,
      });
    }
    if (visitsByID?.data?.[keyName]) {
      vitalSign.resetFields();
      vitalSign.setFieldsValue({
        time: dayjs().format("HH:mm"),
        height:
          visitsByID?.data?.sitting1Min?.height ||
          visitsByID?.data?.lyingDown?.height ||
          visitsByID?.data?.doctorRoom?.height ||
          visitsByID?.data?.visit?.height ||
          undefined,
        ...visitsByID?.data?.[keyName],
      });
    }

    // eslint-disable-next-line
  }, [visitsByID?.data]);

  const onCancel = () => {
    visible.setVisible(!visible);
  };
  const onFinish = (value: any) => {
    let dataList = {};

    dataList = {
      ...visitsByID?.data,
      [keyName]: value,
    };

    const formData = new FormData();
    formData?.append(
      "data",
      JSON.stringify({ ...visitsByID?.data, ...dataList })
    );

    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          if (!appointmentsID)
            client.invalidateQueries(["get-visitsByID", visitID]);
          else
            client.invalidateQueries(["get-appointment-byID", appointmentsID]);

          onCancel();
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  return (
    <div>
      <Modal
        className="manage-modal"
        title={
          <React.Fragment>
            <Row>
              <Col md={20} lg={20} className="t-blue ">
                <b>{title}</b>
              </Col>
              <Col md={4} lg={4} className="col-end"></Col>
            </Row>
          </React.Fragment>
        }
        centered
        visible={visible.visible}
        onOk={() => visible.setVisible(false)}
        onCancel={onCancel}
        width="70%"
        footer={null}
      >
        <div className="scroll">
          <Form
            onFinish={onFinish}
            className="p-2"
            name="vitalSign"
            form={vitalSign}
          >
            <VitalSign medicalRecord={medicalRecord?.[0]?.isEdit} />
          </Form>
        </div>

        <Row gutter={[14, 14]}>
          <Col span={24}>
            <Divider
              className="m-0 "
              style={{ position: "absolute", top: 0 }}
            />
            <Row
              gutter={[14, 14]}
              justify="end"
              align="middle"
              className="footer"
            >
              <Col>
                <Button className="cancel " key="back" onClick={onCancel}>
                  {t("cancel")}
                </Button>
              </Col>
              <Col className="mr-05">
                <Button
                  className="btn-create "
                  onClick={() => vitalSign.submit()}
                  // loading={loadingButton}
                >
                  {t("save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </div>
  );
};

export default ModalVitalSign;
