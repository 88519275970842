export const MedicalReportIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <path
        fill="#10112c"
        d="M60.66 4.62H39.33c-4.72-.01-8.56 3.81-8.58 8.53v4.27c0 4.71 3.81 8.53 8.51 8.53h21.39c4.71 0 8.53-3.81 8.53-8.51V13.15c.02-4.69-3.76-8.51-8.45-8.53h-.08z"
      ></path>
      <path
        fill="#10112c"
        d="M73.78 17.42c-.02 7.23-5.88 13.09-13.11 13.11H39.34c-7.23-.02-13.09-5.88-13.11-13.11a3.393 3.393 0 00-3.43-3.35c-.55 0-1.08.14-1.56.4A20.318 20.318 0 0010.48 32.4v42.7c.03 11.19 9.09 20.26 20.28 20.28h38.48c11.19-.03 20.26-9.09 20.28-20.28V32.39c0-7.5-4.13-14.4-10.76-17.93-1.65-.88-3.7-.26-4.59 1.39-.26.48-.4 1.02-.4 1.56zM51.73 72.47H31.85c-1.88 0-3.4-1.52-3.4-3.4s1.52-3.4 3.4-3.4h19.88c1.88 0 3.4 1.52 3.4 3.4s-1.52 3.4-3.4 3.4zm11.89-18.15H31.85c-1.88 0-3.4-1.52-3.4-3.4s1.52-3.4 3.4-3.4h31.77c1.88 0 3.4 1.52 3.4 3.4s-1.52 3.4-3.4 3.4z"
      ></path>
    </svg>
  );
};
