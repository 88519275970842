import { Col, Row } from "antd";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FC, MutableRefObject } from "react";
import { useGetVisitVitalSign } from "@services/api/visit_history";
import ReactToPrint from "react-to-print";
import DetailReport from "./Detail";

type Props = {
  printReportRef: MutableRefObject<null>;
};

const ReportPhysicianOrder: FC<Props> = ({ printReportRef }) => {
  const { i18n } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const { data } = useGetVisitVitalSign(patientID, { limit: 0, page: 1 });

  return (
    <div>
      <ReactToPrint content={() => printReportRef.current} />

      <div className="document-body ">
        <Row>
          <Col lg={24} className="display-center ">
            <div className="document-bill" style={{ height: 800 }}>
              <Row>
                <Col lg={24}>
                  <DetailReport
                    refValue={printReportRef}
                    visitVitalSign={data?.result?.[0]?.data}
                    i18n={i18n.language}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ReportPhysicianOrder;
