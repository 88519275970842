import { ISearch } from "../inteface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IDashboardTreatment } from "@interface/IDashBoard.interface";
import { Card, Col, Row, Image, Table } from "antd";
import { useGetReportPurchaseTreatment } from "@services/api/report";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useGetReportPurchaseTreatmentExport } from "@services/api/report";
import Excel from "@assets/img/icon/excel.png";
import Search from "./Search";
import MoTable from "@components/table/Table";
import BackHome from "@components/button/BackHome";
import BigNumber from "bignumber.js";
import dayjs from "dayjs";
import { ENUM_ORDERBY } from "@tools/util.enum";

const OperatingRoomReport = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [search, setSearch] = useState<ISearch>();
  const dataReportList = useGetReportPurchaseTreatment();
  const exportReport = useGetReportPurchaseTreatmentExport();
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    dataReportList?.mutate({
      limit: limitPage,
      page: currentPage,
      ...search,
      startDate: search?.startDate,
      endDate: search?.endDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limitPage, search]);

  useEffect(() => {
    setTotal(dataReportList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [dataReportList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onFinish = (value: ISearch) => {
    setSearch({
      ...value,
      startDate: value.start?.[0]
        ? dayjs(value.start?.[0]).format("YYYY-MM-DD")
        : undefined,
      endDate: value.start?.[1]
        ? dayjs(value.start?.[1]).format("YYYY-MM-DD")
        : undefined,
    });
  };

  const onClick = () => {
    exportReport.mutate({
      ...search,
      page: 1,
      limit: 0,
      orderby: ENUM_ORDERBY.ASC,
    });
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: any, data: any, index: number) => {
        return <div> {limitPage * (currentPage - 1) + index + 1}</div>;
      },
    },
    {
      title: t("operativeNursing"),
      dataIndex: "title",
      render: (_: string, result: IDashboardTreatment) => {
        return (
          <div>
            {i18n.language === "th"
              ? result?.nameTh || ""
              : result?.nameEn || ""}
          </div>
        );
      },
    },
  ];
  const expandedRowRender = (row: any) => {
    const titleList = {
      ราคาไทย: "thaiPrice",
      ราคาต่างชาติ: "foreignerPrice",
      ราคาพันธมิตร: "partnerPrice",
    };
    const columns = [
      {
        title: t("sort"),
        dataIndex: "order",
        key: "order",
        render: (_: any, data: any, index: number) => {
          return <div> {limitPage * (currentPage - 1) + index + 1}</div>;
        },
      },
      {
        title: t("nameList"),
        dataIndex: "title",
        render: (title: "ราคาไทย" | "ราคาต่างชาติ" | "ราคาพันธมิตร") => {
          const newTitle = title && titleList[title];
          return t(`${newTitle}`);
        },
      },
      {
        title: t("sum"),
        dataIndex: "sum",
        align: "right" as const,
        render: (sum: string, row: any) => {
          return sum || 0;
        },
      },
      {
        title: t("sales"),
        dataIndex: "price",
        align: "right" as const,
        render: (price: string) => {
          return <div>{`${new BigNumber(price || 0).toFormat(2)}`}</div>;
        },
      },
    ];

    const data = row?.sum?.map((item: any, i: number) => {
      return { key: `${i}`, ...item };
    });
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };

  const newDatalist = dataReportList?.data?.result?.[0]?.data
    .map((item: any) => {
      if (
        item?.sum?.[0]?.title !== "Total" &&
        item?.sum?.[0]?.title !== "Grand Total"
      ) {
        return item;
      }
    })
    .filter((item: any) => item);

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("reportSummary.title")}</h1>
              </div>
              <span className="t-light-gray">{t("report")} / </span>
              <span className="t-dark-blue f-normal">
                {t("operatingRoomReport")}
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={
            <Row align="middle">
              <Col>
                <b className="header-card ">{t("operatingRoomReport")}</b>
              </Col>
              <Col
                className="cursor"
                style={{ marginLeft: "auto" }}
                onClick={() => onClick()}
              >
                <Image src={Excel} width={70} preview={false} />
              </Col>
            </Row>
          }
        >
          <Search onFinish={onFinish} />
          <MoTable
            rowClassName={() => {
              return "no-header no-cursor";
            }}
            className="table-selection"
            columns={columns}
            dataSource={newDatalist || []}
            expandable={{
              expandedRowRender,
              rowExpandable: (record) => record?.sum?.length > 0,
            }}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            scroll={{ x: "calc(100vw)" }}
            size="middle"
            rowKey="key"
            key="key"
          />
        </Card>
      </div>
    </div>
  );
};

export default OperatingRoomReport;
