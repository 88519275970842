import { FC } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  manageBounceGoods: FormInstance;
};

const HeaderBounceGoods: FC<Props> = ({ manageBounceGoods }) => {
  const { t } = useTranslation();

  const history = useHistory<RouteComponentProps["history"]>();
  const loadingButton = useRecoilValue(loadingButtonState);

  return (
    <>
      <Row className="second-header" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("wareHouse.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("wareHouse.title")} / `}
              </span>
              <span className="t-dark-blue f-normal">
                {t("bounceGoodsManagement")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button
            className="btn-print"
            onClick={() => {}}
            loading={loadingButton}
          >
            {t("print")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default HeaderBounceGoods;
