import { Form } from "antd";
import { useGetPatientById } from "@services/api/patient";
import { useGetPrefixGroupType } from "@services/api/prefix";
import { useLocation, useRouteMatch } from "react-router-dom";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";

const PreRegistrationDetail = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const location = useLocation();
  const match: any = useRouteMatch();
  const [preRegister] = Form.useForm();
  const patientID = match.params.patientID;
  const eventID = match.params.eventID;
  const prefixGroupType = useGetPrefixGroupType();
  const locationPath = location.pathname;
  const patientByID = useGetPatientById(patientID);
  const historyPatient = locationPath?.search("patient-history") > -1;

  return (
    <>
      <Header
        history={history}
        preRegister={preRegister}
        patientID={patientID}
        locationPath={locationPath}
        eventID={eventID}
        historyPatient={historyPatient}
      />
      <div className="set-layout ">
        <Fields
          prefixList={prefixGroupType?.data?.result?.[0]}
          preRegister={preRegister}
          patientID={patientID}
          history={history}
          locationPath={locationPath}
          eventID={eventID}
          patientById={patientByID?.data}
          historyPatient={historyPatient}
        />
      </div>
    </>
  );
};

export default PreRegistrationDetail;
