import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useGetPromotionList } from "@services/api/promotion";
import { Card, Col, DatePicker, Select } from "antd";
import { Form, FormInstance, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";

type Props = {
  confirm: FormInstance;
};

const { Option } = Select;

const Fields = ({ confirm }: Props) => {
  const { RangePicker } = DatePicker;
  const { t, i18n } = useTranslation();
  const dateFormat = "DD-MM-YYYY";
  const promotionList = useGetPromotionList({
    limit: 0,
    orderby: ENUM_ORDERBY.DESC,
  });

  return (
    <div className="set-layout ">
      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={<b className="fs-16 t-black-light ">{t("event.title")}</b>}
      >
        <Card
          className="card-prefix card-prefix-coupon-width card-prefix-chi"
          extra={<b className="fs-14 c-white ">{t("event.manage")}</b>}
        >
          <Form name="prefixForm" form={confirm}>
            <Row gutter={[14, 14]}>
              <Col span={24}>
                <Form.Item name="name" label={t("eventName")}>
                  <Input placeholder={t("eventName")} disabled />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item name="addPromotion" label={t("promotion.title")}>
                  <Select
                    disabled
                    mode="multiple"
                    className="promotion-select"
                    allowClear
                    placeholder={t("pleaseSelectPromotion")}
                    filterOption={(input, option: any) => {
                      return (
                        option?.children?.toLowerCase()?.includes(input) ||
                        `${input}` === `${option?.value}`
                      );
                    }}
                  >
                    {promotionList?.data?.result?.[0]?.data?.map(
                      (item, idx) => (
                        <Option value={item?.id} key={idx}>
                          {i18n.language === "th" ? item?.nameTh : item?.nameEn}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item name="date" label={t("date")}>
                  <RangePicker format={dateFormat} disabled />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item name="location" label={t("place")}>
                  <Input placeholder={t("place")} disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t("details")} name="detail">
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </div>
  );
};

export default Fields;
