import { CSSProperties } from "react";
import { useTranslation } from "react-i18next";
import "./button.css";
type Props = {
  status: string;
  text: string;
};

let btnstyle: CSSProperties;
export default function ButtonWareHouse({ status, text }: Props) {
  const { t } = useTranslation();
  switch (status) {
    case "COMPLETE":
      btnstyle = {
        backgroundColor: "#77C48B",
      };
      break;
    case "CONFIRM":
      btnstyle = {
        backgroundColor: "#77C48B",
      };
      break;
    case "CANCEL":
      btnstyle = {
        backgroundColor: "#FC0002",
      };
      break;
    case "PENDING_CONFIRM":
      btnstyle = {
        backgroundColor: "#989898",
      };
      break;
    case "PENDING_SEND":
      btnstyle = {
        backgroundColor: "#989898",
      };
      break;

    case "PREPARE_IS_COMPLETE":
      btnstyle = {
        backgroundColor: "#77C48B",
      };
      break;

    case "PENDING":
      btnstyle = {
        backgroundColor: "#004C97",
      };
      break;

    case "SENDING_COMPLETE":
      btnstyle = {
        backgroundColor: "#77C48B",
      };
      break;

    case "SENDING":
      btnstyle = {
        backgroundColor: "#004C97",
      };
      break;
  }
  return (
    <div style={btnstyle} className="btn-warehouse">
      <div> {`${t(text)}`}</div>
    </div>
  );
}
