import { Form, Input } from "antd";
import { Modal, Row } from "antd";
import { productName } from "@tools/util";
import { Button, Col } from "antd";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ICheckUpdate } from "@interface/IGlobal.interface";
import { IGroup, IItem } from "@interface/IOrder.interface";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useGetProductWarehouse } from "@services/api/warehouse";
import MoTable from "@components/table/Table";
import BigNumber from "bignumber.js";

interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}
type Props = {
  visible: IVisible;
  onOk: (e: any) => void;
  selectedRowKeys: IItem[];
};

const ModalPurchaseOrder = ({ visible, onOk, selectedRowKeys }: Props) => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [selectRowKeys, setSelectedRowKeys] = useState<IItem[]>([]);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [search, setSearch] = useState("");
  const productList = useGetProductWarehouse({
    page: currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    search: search && search?.search,
  });

  useEffect(() => {
    if (!productList?.data) {
      return;
    }
    setTotal(productList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [productList?.data]);

  useEffect(() => {
    if (!productList?.data?.result?.[0]?.data?.length) {
      return;
    }
    setSelectedRowKeys(selectedRowKeys);
    // eslint-disable-next-line
  }, [selectedRowKeys, productList?.data]);

  const onCancel = () => {
    visible.setVisible(!visible?.visible);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const checkProductUpdate = (oldData: any[], newData: any[]) => {
    try {
      const filerRemoveArray = oldData?.filter(
        (item: any) => !newData?.includes(item?.id)
      );
      const newArray = oldData?.filter((item: any) =>
        newData?.includes(item?.id)
      );
      return {
        newData: newArray,
        removeData: filerRemoveArray,
      } as ICheckUpdate;
    } catch (err: any) {
      console.log(err, "err");
    }
  };

  const onFinish = (value: any) => {
    setSearch(value);
    setCurrentPage(1);
  };

  const columns: any = [
    {
      title: "#",
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "5%",
      render: (_: any, data: any, index: number) => {
        return <div>{limitPage * (currentPage - 1) + index + 1}</div>;
      },
    },

    {
      title: t("sku"),
      dataIndex: "group",
      key: "group",
      render: (group: IGroup) => {
        return <div>{group?.sku}</div>;
      },
    },
    {
      title: t("sub sku"),
      dataIndex: "sku",
      key: "sku",
    },

    {
      title: t("productName"),
      dataIndex: "name",
      key: "name",
      render: (_: IItem, name: IItem) => {
        return (
          <div>{productName(i18n.language, name?.nameTh, name?.nameEn)}</div>
        );
      },
    },
    {
      title: `${t("price")} / ${t("unit")}`,
      dataIndex: "retailPrice",
      key: "normal",
      render: (retailPrice: string) => {
        return <div>{BigNumber(retailPrice)?.toFormat(2)}</div>;
      },
    },
  ];

  const SearchField = () => {
    return (
      <div className="mb-1">
        <Form onFinish={onFinish}>
          <Row gutter={[10, 14]} align="middle">
            <Col style={{ marginLeft: "auto" }}>
              <Form.Item name="search">
                <Input
                  className="search-modal"
                  placeholder={`${t("nameSearch")}`}
                />
              </Form.Item>
            </Col>
            <Col>
              <Button className=" btn-search" htmlType="submit">
                {t("search")}
              </Button>
            </Col>
            <Col>
              <Button className="clear" htmlType="reset">
                {t("reset")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  };

  return (
    <Modal
      title={
        <span>
          <b className="f-normal">{t("productDetails")}</b>
        </span>
      }
      centered
      visible={visible?.visible}
      onCancel={onCancel}
      width="70%"
      footer={null}
    >
      {SearchField()}
      <MoTable
        style={{
          marginTop: "15px",
          borderLeft: "1px solid #f0f0f0",
          borderRight: "1px solid #f0f0f0",
        }}
        rowClassName={(r) => {
          return " no-header no-cursor";
        }}
        rowKey="id"
        scroll={{ x: "calc(100vw -20%)" }}
        columns={columns}
        dataSource={productList?.data?.result?.[0]?.data || []}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        rowSelection={{
          selectedRowKeys: selectRowKeys?.map((item: IItem) => item?.id),
          onChange(_selectedRowKeys, selectedRows) {
            const check = checkProductUpdate(
              productList?.data?.result?.[0]?.data || [],
              _selectedRowKeys
            );

            const select = Array.from(
              new Map(
                [...(check?.newData || []), ...(selectedRowKeys || [])]?.map(
                  (item) => [item?.id, item]
                )
              ).values()
            )?.filter((val) => {
              if (
                check?.removeData
                  ?.map((item: any) => item?.id)
                  ?.includes(val?.id)
              ) {
                return false;
              } else {
                return true;
              }
            });

            setSelectedRowKeys(select);
          },
          checkStrictly: false,
        }}
      />

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>

            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => onOk(selectRowKeys)}
                // loading={updateReceipt.isLoading}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalPurchaseOrder;
