import React, { FC, MutableRefObject } from "react";
import { Age } from "@tools/age";
import { dayjs } from "@tools/timezone";
import { Col, Row } from "antd";
import { IVisitDrug } from "@interface/IVisits.interface";
import { DRUG_STATUS } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { eatingPattern, timeSpent } from "@tools/util";
import { IOptionDetail, IReportDrug } from "@interface/IWorkOrder.interface";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";

type Props = {
  refData: MutableRefObject<null>;
  drugList: IVisitDrug | undefined;
};

const Print: FC<Props> = ({ refData, drugList }) => {
  const userList = React.useContext(UserContext);
  const { i18n } = useTranslation();
  return (
    <div ref={refData}>
      <table className="report-drug t-center  " width="100%">
        <thead>
          <tr>
            <th
              colSpan={1}
              rowSpan={2}
              className="rd-bottom  rd-top rd-left rd-right"
            >
              <ImageLogo
                className="circles"
                src={userList?.branch?.image}
                height="70px"
                width="200px"
                alt="logo"
              />
            </th>
            <th colSpan={23} className=" rd-top rd-right">
              <Row>
                <Col className="mt-05">Name : </Col>

                <Col span={6} className="t-underline mt-05">
                  <div className=" f-normal t-center">
                    {`${drugList?.patient?.firstNameTh || ""}  ${
                      drugList?.patient?.lastNameTh || ""
                    }`}
                  </div>
                </Col>

                <Col className="mt-05">HN:</Col>

                <Col span={3} className="t-underline mt-05">
                  <div className=" f-normal t-center">
                    {`${drugList?.patient?.hn || ""}`}
                  </div>
                </Col>
                <Col className="mt-05 ">Date of Birth: </Col>

                <Col span={3} className="t-underline mt-05">
                  <div className=" f-normal t-center">
                    {`${drugList?.patient?.dateOfBirth || ""}`}
                  </div>
                </Col>

                <Col className="mt-05">Age:</Col>

                <Col span={2} className="t-underline mt-05">
                  <div className=" f-normal t-center">
                    {`${Age(drugList?.patient?.dateOfBirth || "") || 0}`}
                  </div>
                </Col>
                <Col className="mt-05">Allergies: </Col>

                <Col
                  style={{ minWidth: 100 }}
                  className="t-underline mt-05 t-red"
                >
                  <div className=" f-normal t-center">
                    {`${[
                      drugList?.patient?.drugAllergy,
                      drugList?.patient?.foodAllergy,
                    ]
                      ?.join(" / ")
                      ?.trim()} `}
                  </div>
                </Col>
              </Row>
            </th>
          </tr>
          <tr>
            <th colSpan={23} className="rd-right rd-bottom rd-background ">
              Medication Supplement Log
            </th>
          </tr>

          <tr>
            <th rowSpan={2} className="rd-left rd-right rd-bottom">
              Name of Medication Supplement
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              Directions
            </th>
            <th colSpan={3} className="rd-right rd-bottom">
              Before
            </th>
            <th colSpan={3} className="rd-right rd-bottom">
              After
            </th>
            <th colSpan={3} className="rd-right rd-bottom">
              With meal
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              As Needed
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              Bed Time
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              Special instructions
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              Purpose
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              Date started
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              Date stopped
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              Prescribed by
            </th>
            <th rowSpan={2} className="rd-right rd-bottom">
              Status
            </th>
          </tr>
          <tr>
            <th className="rd-right rd-bottom rd-rightTH">B</th>
            <th className="rd-right rd-bottom rd-rightTH">L</th>
            <th className="rd-right rd-bottom rd-rightTH">D</th>
            <th className="rd-right rd-bottom rd-rightTH">B</th>
            <th className="rd-right rd-bottom rd-rightTH">L</th>
            <th className="rd-right rd-bottom rd-rightTH">D</th>
            <th className="rd-right rd-bottom rd-rightTH">B</th>
            <th className="rd-right rd-bottom rd-rightTH">L</th>
            <th className="rd-right rd-bottom rd-rightTH">D</th>
          </tr>
        </thead>
        <tbody>
          {drugList?.workOrderItem?.map((item: IReportDrug, index: number) => {
            const option: IOptionDetail = JSON.parse(item?.option || `{}`);

            return (
              <tr key={index}>
                <td className="rd-left rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {option?.nameShown || item?.product?.nameTh}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {option?.genericName || item?.product?.nameEn}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "beforeMeal")
                      ? (timeSpent(option?.timeSpent || [], "morning") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "beforeMeal")
                      ? (timeSpent(option?.timeSpent || [], "daytime") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "beforeMeal")
                      ? (timeSpent(option?.timeSpent || [], "evening") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "afterMeal")
                      ? (timeSpent(option?.timeSpent || [], "morning") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "afterMeal")
                      ? (timeSpent(option?.timeSpent || [], "daytime") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "afterMeal")
                      ? (timeSpent(option?.timeSpent || [], "evening") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "withFood")
                      ? (timeSpent(option?.timeSpent || [], "morning") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "withFood")
                      ? (timeSpent(option?.timeSpent || [], "daytime") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.eatingPattern || [], "withFood")
                      ? (timeSpent(option?.timeSpent || [], "evening") &&
                          option?.quantityUsed) ||
                        ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom ">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {timeSpent(option?.eatingPattern || [], "asNeeded")
                      ? option?.quantityUsed || ""
                      : ""}
                  </div>
                </td>

                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {eatingPattern(option?.timeSpent || [], "night")
                      ? option?.quantityUsed || ""
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {option?.specialInstructions || ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {option?.purpose || ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {option?.dateStart
                      ? dayjs(option?.dateStart).format("DD-MM-YYYY")
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {" "}
                    {option?.dateStop
                      ? dayjs(option?.dateStop).format("DD-MM-YYYY")
                      : ""}
                  </div>
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {i18n.language === "th"
                      ? `${[
                          item?.rejectConfirmBy?.firstNameTh,
                          item?.rejectConfirmBy?.lastNameTh,
                        ]
                          ?.join(" ")
                          ?.trim()}`
                      : `${[
                          item?.rejectConfirmBy?.firstNameEn,
                          item?.rejectConfirmBy?.lastNameEn,
                        ]
                          ?.join(" ")
                          ?.trim()}`}
                  </div>
                  Dr.{" "}
                </td>
                <td className="rd-right rd-bottom">
                  <div
                    style={
                      item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                        ? { color: "red" }
                        : {}
                    }
                  >
                    {item?.drugStatus === DRUG_STATUS.CONTINUOUS
                      ? "Continue"
                      : "Discontinue"}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Print;
