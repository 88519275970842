import { IMedicineRoomContext } from "./interface";
import { createContext, useContext } from "react";

const MainMedicineRoomContext = createContext<IMedicineRoomContext>(
  {} as IMedicineRoomContext
);

const MainMedicineRoomProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: IMedicineRoomContext;
}) => {
  return (
    <MainMedicineRoomContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </MainMedicineRoomContext.Provider>
  );
};

export const useMainMedicineRoom = () => useContext(MainMedicineRoomContext);
export default MainMedicineRoomProvider;
