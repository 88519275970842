import { useState } from "react";
import { ISignature } from "@interface/IGlobal.interface";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { ENUM_MENU, ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { KeyNameSignatureFirst } from "@interface/IGlobal.interface";
import { useCreateSignatureRoom } from "@services/api/signature_room";
import { Button, Col, FormInstance, Row } from "antd";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";
import ModalInfo from "@components/signature/ModalInfo";
import RoleContext from "@provider/RoleProvider";
import React from "react";

type Props = {
  history: RouteComponentProps["history"];
  labInfo: FormInstance;
  isSignature?: boolean;
};

const Header = ({ history, labInfo, isSignature }: Props) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const visitId = match.params.visitID;
  const [visible, setVisible] = useState({ first: false });
  const createSignatureRoom = useCreateSignatureRoom();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === ENUM_MENU.INTERFACE_LAB;
  });

  const client = useQueryClient();

  const updateSignature = (
    values: {
      date: string;
      title: KeyNameSignatureFirst;
      doctorName?: string;
    } & ISignature
  ) => {
    const file = dataUrlToFile(
      values?.firstSignature,
      `interfaceLabRoomSignature${new Date().getTime()}.png`
    );
    if (file) {
      let formData = new FormData();
      formData?.append("signature", file);
      formData?.append("room", ENUM_ROOM_SIGNATURE.INTERFACE_LAB_ROOM);
      !!values?.doctorName &&
        formData?.append("doctorName", values?.doctorName);

      createSignatureRoom.mutate(
        { data: formData, visitId: visitId },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            client.invalidateQueries(["get-lab-info", visitId]);
            setVisible({
              ...visible,
              [values?.title]: !visible[values?.title],
            });
          },
        }
      );
    } else {
      setVisible({
        ...visible,
        [values?.title]: !visible[values?.title],
      });
    }
  };

  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>Interface Lab</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-bold">Interface Lab</span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button className="cancel mr-05 " onClick={history.goBack}>
            {t("cancel")}
          </Button>
          {medicalRecord?.[0]?.isEdit && (
            <Button
              className="btn-create"
              onClick={() => {
                !!isSignature ? setVisible({ first: true }) : labInfo.submit();
              }}
            >
              {t("confirm")}
            </Button>
          )}
        </Col>
      </Row>
      {visible.first && (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="first"
          title={t("medicalAssistantSignature")}
          updateSignature={updateSignature}
          isLoading={createSignatureRoom?.isLoading}
        />
      )}
    </>
  );
};

export default Header;
