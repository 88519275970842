import { Col, Row, Card } from "antd";
import { useTranslation } from "react-i18next";
import TabsInterfaceLab from "./Tabs";

const InterfaceLab = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>Interface Lab</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("nursery")}</b>
          </span>
        </Col>
        <Col lg={2} className="justify-col"></Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("allNursery")}</b>}
        >
          <TabsInterfaceLab />
        </Card>
      </div>
    </div>
  );
};

export default InterfaceLab;
