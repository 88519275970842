import { dayjs } from "@tools/timezone";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useGetOrderHistory } from "@services/api/order";
import { useEffect, useState } from "react";
import { Button, Card, Col, Row } from "antd";
import BigNumber from "bignumber.js";
import PaginationPage from "@components/pagination/Pagination";

const History = () => {
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const patientID = match.params.patientID;
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const orderHistory = useGetOrderHistory(pathID || patientID, {
    page: currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
  });

  useEffect(() => {
    setTotal(orderHistory?.data?.result?.[0]?.total || 1);

    // eslint-disable-next-line
  }, [orderHistory?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  return (
    <div className="list-record-scroll scrollbar">
      {orderHistory?.data?.result?.[0]?.data?.map((item, index) => {
        return (
          <Button key={index} className="list-record">
            <Card className="card-list-record mt-05">
              <Row justify="space-between">
                <Col>
                  <div className="text-topic-record">
                    {i18n.language === "th"
                      ? item?.product?.nameTh
                      : item?.product?.nameEn}
                  </div>
                </Col>
                <Col>{`${item?.qtyOutstanding}/${item?.qty}`}</Col>
              </Row>

              <div className="date-details-recoil">
                {`${t("orderNumber")} : ${item?.order?.no}`}
              </div>

              <span className="date-details-recoil">
                {`${t("grandTotal")} : `}
              </span>
              <span>{`${new BigNumber(item?.total || 0).toFormat(2)}  ${t(
                "baht"
              )}`}</span>
              <div className="date-details">
                {dayjs(item?.createdAt).format("DD/MM/YYYY HH:mm")}
              </div>
            </Card>
          </Button>
        );
      })}
      <PaginationPage
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
      />
    </div>
  );
};

export default History;
