import { discount } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { Card, Col, Divider, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  status?: string;
};

const OrderSummary = ({ status }: Props) => {
  const { t } = useTranslation();
  const onChange = (seleter: number) => {};

  return (
    <>
      {status === "noCard" ? (
        <>
          <Row gutter={[14, 14]}>
            <Col md={12} lg={12} className="fs-18 fw-900">
              {`${t("summary")}${t("order")}`}
            </Col>
          </Row>
          <Divider className="mt-1" />
          <Row>
            <Col md={12} lg={12} className="t-blue">
              {t("fullList")}
            </Col>
            <Col md={12} lg={12} className="col-end">
              8 รายการ
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue">
              {t("grandTotal")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              1,480 บ.
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue">
              {t("discounts")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              <SelectorDouble
                placeholder="฿"
                onChange={onChange}
                status={1}
                Source={discount}
                value={20}
                keyName="name"
                keyValue="id"
                title={t("status")}
              />
            </Col>
            <Col md={12} lg={12} className="mt-1 t-blue">
              {`${t("summary")}${t("discounts")}`}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              1,184 บ.
            </Col>
            <Col md={24} lg={24} className=" mt-1">
              <hr className="line-horizontal" />
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue fw-900">
              {t("grandTotal")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1 fs-18 fw-900">
              1,184 บ.
            </Col>
          </Row>
        </>
      ) : (
        <Card
          className="bg-grey  order-summarize"
          extra={
            <>
              <Row gutter={[14, 14]}>
                <Col md={12} lg={12} className="fs-18 fw-900">
                  {`${t("summary")}${t("discounts")}`}
                </Col>
              </Row>
            </>
          }
        >
          <Row>
            <Col md={12} lg={12} className="t-blue">
              {t("fullList")}
            </Col>
            <Col md={12} lg={12} className="col-end">
              8 รายการ
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue">
              {t("grandTotal")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              1,480 บ.
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue">
              {t("discounts")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              <SelectorDouble
                placeholder="฿"
                onChange={onChange}
                status={1}
                Source={discount}
                value={20}
                keyName="name"
                keyValue="id"
                title={t("status")}
              />
            </Col>
            <Col md={12} lg={12} className="mt-1 t-blue">
              {`${t("summary")}${t("discounts")}`}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              1,184 บ.
            </Col>
            <Col md={24} lg={24} className=" mt-1">
              <hr className="line-horizontal" />
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue fw-900">
              {t("grandTotal")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1 fs-18 fw-900">
              1,184 บ.
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default OrderSummary;
