import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import { IPurchaseById } from "@interface/IWarehouse.interface";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetWarehouseGoodsReceipt = (
  params?: any
): UseQueryResult<Elementary<any[]>> => {
  return useQuery(["get-goods-receipt", params], async () => {
    const res = await axios.get(`/goods-receipt`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateWarehouseGoodsReceipt = (): UseMutationResult => {
  return useMutation(["create-goods-receipt"], async (data: any) => {
    const res = await axios.post(`/goods-receipt`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetWarehouseGoodsReceiptById = (
  id?: number
): UseQueryResult<ElementaryOneMore<IPurchaseById>> => {
  return useQuery(
    ["get-goods-receipt-by-id", id],
    async () => {
      const res = await axios.get(`/goods-receipt/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetWarehouseGoodsReceiptPoNumber = (
  id?: number
): UseQueryResult<ElementaryOneMore<string>> => {
  return useQuery(
    ["get-goods-receipt-po-number", id],
    async () => {
      const res = await axios.get(`/goods-receipt/po-number`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !id }
  );
};
