import { t } from "i18next";
import { Age } from "@tools/age";
import { dayjs } from "@tools/timezone";
import { Component } from "react";
import { IGetVisits } from "@interface/IVisits.interface";
import { Col, Row, Button } from "antd";
import { ISignature, IVisit } from "@interface/IGlobal.interface";
import { onImgLogoError, productName } from "@tools/util";
import ImageLogo from "@components/image/ImageLogo";

interface IOrderList {
  id: number;
  nameTh: string;
  nameEn: string;
  qty: number;
}

type Props = {
  ref: any;
  orderList: IOrderList[];
  visitsByID: IGetVisits | undefined;
  visible: IVisit;
  setVisible: (e: IVisit) => void;
  signature: ISignature;
  i18n: string;
};

class Print extends Component<Props> {
  render() {
    return (
      <div>
        <div className=" document ">
          <Row className="t-start document-border-header p-05">
            <Col span={6}>
              <div className="mt-1">
                <ImageLogo
                  width="120px"
                  height="50px"
                  alt="logo"
                  src={
                    this.props?.visitsByID?.branch?.image || onImgLogoError()
                  }
                />
              </div>
            </Col>
            <Col span={18}>
              <Row>
                <Col>
                  <div>Name:</div>
                </Col>
                <Col span={13} className="t-underline">
                  <div className=" f-normal  " style={{ paddingLeft: 10 }}>{`${
                    this.props?.i18n === "th"
                      ? `${this.props?.visitsByID?.patient?.titleTh || ""}${[
                          this.props?.visitsByID?.patient?.firstNameTh,
                          this.props?.visitsByID?.patient?.lastNameTh,
                        ]
                          ?.join(" ")
                          ?.trim()}`
                      : `${this.props?.visitsByID?.patient?.titleEn || ""}${[
                          this.props?.visitsByID?.patient?.firstNameEn,
                          this.props?.visitsByID?.patient?.lastNameEn,
                        ]
                          ?.join(" ")
                          ?.trim()}`
                  } `}</div>
                </Col>

                <Col>Date:</Col>

                <Col span={6} className="t-underline">
                  <div className=" f-normal t-center">
                    {" "}
                    {`${this.props?.visitsByID?.date || ""}`}{" "}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="mt-05">HN:</Col>

                <Col span={7} className="t-underline mt-05">
                  <div className=" f-normal t-center">
                    {" "}
                    {`${this.props?.visitsByID?.patient?.hn || ""}`}{" "}
                  </div>
                </Col>

                <Col className="mt-05">Birth Date:</Col>

                <Col span={7} className="t-underline mt-05">
                  <div className=" f-normal t-center">
                    {" "}
                    {`${
                      this.props?.visitsByID?.patient?.dateOfBirth || ""
                    }`}{" "}
                  </div>
                </Col>

                <Col className="mt-05">Age :</Col>

                <Col span={2} className="t-underline mt-05">
                  <div className=" f-normal t-center">
                    {Age(this.props?.visitsByID?.patient?.dateOfBirth || "")}
                  </div>
                </Col>

                <Col className="mt-05">y</Col>
              </Row>

              <Row>
                <Col className="mt-05">Physician:</Col>

                <Col className="t-underline mt-05 ">
                  <div
                    className=" f-normal t-center "
                    style={{ minWidth: "130px" }}
                  >
                    {this.props?.i18n === "th"
                      ? [
                          this.props?.visitsByID?.appointment?.[0]?.slot?.user
                            ?.firstNameTh,
                          this.props?.visitsByID?.appointment?.[0]?.slot?.user
                            ?.lastNameTh,
                        ]
                          ?.join(" ")
                          ?.trim()
                      : [
                          this.props?.visitsByID?.appointment?.[0]?.slot?.user
                            ?.firstNameEn,
                          this.props?.visitsByID?.appointment?.[0]?.slot?.user
                            ?.lastNameEn,
                        ]
                          ?.join(" ")
                          ?.trim()}
                  </div>
                </Col>

                <Col className="mt-05">Allergies :</Col>

                <Col
                  style={
                    [
                      this.props?.visitsByID?.patient?.drugAllergy,
                      this.props?.visitsByID?.patient?.foodAllergy,
                    ]
                      ?.join(" / ")
                      ?.trim()?.length > 50
                      ? {
                          textDecoration: "underline",
                          textDecorationColor: "#00000067",
                          minWidth: 130,
                        }
                      : { minWidth: 130 }
                  }
                  className={
                    [
                      this.props?.visitsByID?.patient?.drugAllergy,
                      this.props?.visitsByID?.patient?.foodAllergy,
                    ]
                      ?.join(" / ")
                      ?.trim()?.length > 50
                      ? " mt-05"
                      : "t-underline mt-05"
                  }
                >
                  <div className=" f-normal  t-red">
                    {[
                      this.props?.visitsByID?.patient?.drugAllergy,
                      this.props?.visitsByID?.patient?.foodAllergy,
                    ]
                      ?.join(" / ")
                      ?.trim() || "-"}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="mt-2 mb-2 f-normal fs-16">
            {t("consentForm.title")}
          </div>

          <div className="t-justify t-index mt-1 break-inside">
            {t("consentForm.first")}
          </div>
          <div className=" t-justify t-index mt-05 break-inside">
            {t("consentForm.second")}
          </div>

          <div className="mt-2 t-start">
            {this?.props?.orderList?.map((item: any, index: number) => {
              return (
                <span key={index}>
                  <span className="pr-05">{index + 1}.</span>
                  <span className="pr-2 t-start">
                    {productName(this.props?.i18n, item?.nameTh, item?.nameEn)}
                  </span>
                </span>
              );
            })}
          </div>

          <div className=" t-justify t-index mt-2 break-inside">
            {t("consentForm.third")}
          </div>

          <div className=" t-justify t-index mt-05 break-inside">
            {t("consentForm.fourth")}
          </div>

          <div className=" t-justify t-index mt-05 break-inside">
            {t("consentForm.fifth")}
          </div>
        </div>

        <div className="page" style={{ boxShadow: "none", border: 0 }}>
          <div className=" document-footer">
            <div className="  mt-2 ">
              <Button
                className="pb-1 signature-button"
                onClick={(e) =>
                  this.props?.setVisible({
                    ...this.props?.visible,
                    first: !this.props?.visible?.first,
                  })
                }
              >
                {t("signHere")}
              </Button>
            </div>
            <Row>
              <Col>
                <span> {t("consentForm.sign")} </span>
              </Col>
              <Col span={9} className="signature-patient t-underline-dotted">
                <div className=" f-normal t-center  image-signature">
                  {this?.props?.signature?.firstSignature && (
                    <img
                      src={this?.props?.signature?.firstSignature || ""}
                      alt="first"
                      height={70}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row justify="space-around" className="mt-1">
              <Col span={7}></Col>
              <Col span={10}>
                <Row>
                  (
                  <Col className="t-underline-dotted f-normal t-center signature-end">
                    {this?.props?.signature?.firstName ||
                      `${
                        this.props?.i18n === "th"
                          ? [
                              this.props?.visitsByID?.patient?.firstNameTh,
                              this.props?.visitsByID?.patient?.lastNameTh,
                            ]
                              ?.join(" ")
                              ?.trim()
                          : [
                              this.props?.visitsByID?.patient?.firstNameEn,
                              this.props?.visitsByID?.patient?.lastNameEn,
                            ]
                              ?.join(" ")
                              ?.trim()
                      }`}
                  </Col>
                  )
                </Row>
              </Col>
              <Col span={7}>
                <Row>
                  <Col> {t("date")}</Col>
                  <Col span={20} className="t-underline-dotted">
                    <div className="f-normal t-center">
                      {this?.props?.signature?.firstDate
                        ? dayjs(this?.props?.signature?.firstDate).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
            <div className="  mt-2">
              <Button
                className="pb-1 signature-button"
                onClick={(e) =>
                  this.props?.setVisible({
                    ...this.props?.visible,
                    second: !this.props?.visible?.second,
                  })
                }
              >
                {t("signHere")}
              </Button>
            </div>

            <Row>
              <Col>
                <span>{t("signTheContributor")} </span>
              </Col>

              <Col span={9} className="signature-user t-underline-dotted">
                <div className=" f-normal t-center  image-signature">
                  {this?.props?.signature?.secondSignature && (
                    <img
                      src={this?.props?.signature?.secondSignature || ""}
                      alt="second"
                      height={70}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row justify="space-around" className="  mt-1">
              <Col span={7}></Col>
              <Col span={10}>
                <Row>
                  (
                  <Col className="t-underline-dotted f-normal t-center signature-end">
                    {this?.props?.signature?.secondName || ""}
                  </Col>
                  )
                </Row>
              </Col>
              <Col span={7}>
                <Row>
                  <Col> {t("date")}</Col>
                  <Col span={20} className="t-underline-dotted">
                    <div className="f-normal t-center">
                      {this?.props?.signature?.secondDate
                        ? dayjs(this?.props?.signature?.secondDate).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div className="  mt-2">
              <Button
                className="pb-1 signature-button"
                onClick={(e) =>
                  this.props?.setVisible({
                    ...this.props?.visible,
                    third: !this.props?.visible?.third,
                  })
                }
              >
                {t("signHere")}
              </Button>
            </div>

            <Row>
              <Col>
                <span>{t("signTheWitness")}</span>
              </Col>

              <Col span={9} className="signature-witness t-underline-dotted">
                <div className=" f-normal t-center  image-signature">
                  {this?.props?.signature?.thirdSignature && (
                    <img
                      src={this?.props?.signature?.thirdSignature || ""}
                      alt="third"
                      height={70}
                    />
                  )}
                </div>
              </Col>
            </Row>
            <Row justify="space-around" className="  mt-1">
              <Col span={7}></Col>
              <Col span={10}>
                <Row>
                  (
                  <Col className="t-underline-dotted f-normal t-center signature-end">
                    {this?.props?.signature?.thirdName || ""}
                  </Col>
                  )
                </Row>
              </Col>
              <Col span={7}>
                <Row>
                  <Col> {t("date")}</Col>
                  <Col span={20} className="t-underline-dotted">
                    <div className="f-normal t-center">
                      {this?.props?.signature?.thirdDate
                        ? dayjs(this?.props?.signature?.thirdDate).format(
                            "DD/MM/YYYY"
                          )
                        : ""}
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default Print;
