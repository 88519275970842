import { date } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, Form, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  onFinish: (value: any) => void;
};

const Search = ({ onFinish }: Props) => {
  const { t, i18n } = useTranslation();
  const dateFormat = "DD-MM-YYYY";
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={6} lg={10}>
            <Form.Item name="date">
              <DatePicker
                format={dateFormat}
                placeholder={t("chooseDateLeadingSymptoms")}
              />
            </Form.Item>
          </Col>

          <Col md={10} lg={6}>
            <Form.Item name="sort">
              <SelectorDouble
                placeholder={t("newest")}
                Source={date}
                keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("sortByDay")}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
