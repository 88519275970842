import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Table from "./Table";
import Search from "./Search";
import BackHome from "@components/button/BackHome";

const PatientsList = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const eventID = match.params.eventID;
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const patientsFilter = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("preRegister.title")}</h1>
              </div>{" "}
              <span className="t-light-gray">{`${t("event.title")} / `}</span>
              <span className="t-dark-blue f-normal">{t("nursery")}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card ">{t("allNursery")}</b>}
        >
          <div>
            <Search onFinish={patientsFilter} />
            <Table
              searchValue={searchValue}
              currentPage={{
                currentPage: currentPage,
                setCurrentPage: setCurrentPage,
              }}
              eventID={eventID}
              history={history}
            />
          </div>
        </Card>
      </div>
    </div>
  );
};

export default PatientsList;
