import i18n from "i18next";
import { useMutation } from "react-query";
import { AxiosResponse } from "axios";
import axios from "@axios/index";
interface IUserLogin {
  username: string;
  password: string;
}

export const thrownResponse = (res: AxiosResponse): never => {
  if (res?.status === 401) {
    localStorage.clear();
    let url: string = window.location.origin;
    window.location.replace(url + "/#/login");
  }

  throw new Error(
    i18n.language === "th"
      ? res?.data?.message?.TH || res?.data?.message
      : res?.data?.message?.EN || res?.data?.message
  );
};

export const useLogin = () => {
  return useMutation(async (data: IUserLogin) => {
    const res = await axios.post(`/auth/login`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data?.result?.[0];
    }
    thrownResponse(res);
  });
};

export const useSelectBranch = () =>
  useMutation(async ({ branchId }: { branchId: number } & any) => {
    const res = await axios.post("/auth/select", { branchId });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data?.result?.[0];
    }
    thrownResponse(res);
  });
