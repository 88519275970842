import { useTranslation } from "react-i18next";
import { Card, FormInstance } from "antd";
import { RouteComponentProps } from "react-router-dom";
import Fields from "./Fields";
import StepOPD from "@components/step/Step";

type Props = {
  otherInfoForm: FormInstance;
  history: RouteComponentProps["history"];
  pathID: number;
};

const FormOtherInfo = ({ otherInfoForm, history, pathID }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="set-layout ">
      <Card className="bg-grey m-2 from-card" extra={<StepOPD />}>
        <span className="strong h1 header-top ">{t("createKeeper")}</span>
        <div className="title-top pt-05">{t("importantInformation")}</div>

        <Fields
          otherInfoForm={otherInfoForm}
          history={history}
          pathID={pathID}
        />
      </Card>
    </div>
  );
};

export default FormOtherInfo;
