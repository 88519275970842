import { date } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  setSearchValue: (value: any) => void;
  setCurrentPage: (e: number) => void;
};

const Search = ({ setSearchValue, setCurrentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const dateFormat = "DD-MM-YYYY";
  const onFinish = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={12} lg={8}>
            <Form.Item name="date">
              <DatePicker
                format={dateFormat}
                placeholder={t("invoiceCreationDate")}
              />
            </Form.Item>
          </Col>

          <Col md={12} lg={5}>
            <Form.Item name="invoiceNumber">
              <Input placeholder={`${t("number")}${t("document")}`} />
            </Form.Item>
          </Col>

          <Col md={10} lg={6}>
            <Form.Item name="status">
              <SelectorDouble
                placeholder={t("newest")}
                Source={date}
                keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("status")}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
