export const ConsentIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            d="M0 0H73.286V80.201H0z"
            data-name="Rectangle 22691"
          ></path>
        </clipPath>
        <clipPath id="clip-Consent">
          <path d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-Consent)">
        <g data-name="Group 27078" transform="translate(13 10.001)">
          <g fill="#fff" clipPath="url(#clip-path)" data-name="Group 27078">
            <path
              d="M121.02 8.305A9.693 9.693 0 00111.166 0c-4.457.015-8.914 0-13.37 0C86.81 0 75.824-.019 64.837.005a9.66 9.66 0 00-9.662 9.717v9.135h1.008c2.026.032 4.068-.086 6.072.134a7.556 7.556 0 01.082 14.93c-2.09.242-4.224.116-6.338.155-.257.005-.513 0-.786 0v12.043c1.548 0 3.058-.024 4.566.009a16.1 16.1 0 012.989.211 7.555 7.555 0 015.869 8.305 7.637 7.637 0 01-7.537 6.695h-5.927v8.969a9.674 9.674 0 009.892 9.892h42.919c1.6 0 3.212.02 4.8-.122a9.56 9.56 0 008.289-9.132q.006-30.818 0-61.637a6.225 6.225 0 00-.06-1M91.214 58.877a19.313 19.313 0 1119.323-19.384 19.234 19.234 0 01-19.323 19.384"
              data-name="Path 22258"
              transform="translate(-47.795 .001)"
            ></path>
            <path
              d="M8.558 176.479H3.879A3.865 3.865 0 010 172.839a3.775 3.775 0 013.619-4.085q4.942-.13 9.89 0a3.765 3.765 0 013.631 4.129 3.833 3.833 0 01-3.907 3.6c-1.56.006-3.119 0-4.679 0"
              data-name="Path 22259"
              transform="translate(-.001 -146.133)"
            ></path>
            <path
              d="M8.618 372.867h4.544a3.877 3.877 0 11.086 7.752c-2.383.032-4.767 0-7.15 0-.735 0-1.471.022-2.205 0a3.879 3.879 0 11.114-7.755q1.169 0 2.339-.005h2.272v.005"
              data-name="Path 22260"
              transform="translate(0 -323.003)"
            ></path>
            <path
              d="M232.931 205.691v8.285a1.412 1.412 0 01-1.593 1.608q-3.309.011-6.617 0c-1.3 0-1.789-.481-1.794-1.771-.007-2.384 0-4.768 0-7.152v-.971h-8.107c-1.352 0-1.818-.466-1.817-1.792q0-3.242.006-6.484c0-1.2.5-1.715 1.7-1.718 2.451-.007 4.9 0 7.353 0h.862v-.793c0-2.429-.005-4.857 0-7.286 0-1.462.458-1.9 1.93-1.9h6.484a1.4 1.4 0 011.593 1.6c.008 2.518.006 5.035.007 7.553v.822h.825c2.451 0 4.9-.005 7.353 0 1.275 0 1.781.506 1.786 1.783q.012 3.242 0 6.484c0 1.223-.523 1.727-1.766 1.728h-8.206"
              data-name="Path 22261"
              transform="translate(-184.519 -160.885)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
