import React, { useEffect, useState } from "react";
import { t } from "i18next";
import { ISelected } from "@interface/IOrder.interface";
import { IGetVisits } from "@interface/IVisits.interface";
import { DRUG_STATUS } from "@tools/util.enum";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Empty, Row } from "antd";
import empty from "@assets/img/empty.svg";
import OrderList from "../order_list/OrderList";
import ViewMarker from "@components/marker/view_marker/ViewMarker";
import ModalMarker from "@components/marker/modal_marker/ModalMarker";
import RoleContext from "@provider/RoleProvider";
import ReturnMedicine from "@components/medicine/Medicine";
import ProductCategory from "@components/product_category/ProductCategory";
import RejectGroupOrder from "@components/order/reject/RejectGroupOrder";
import MainMedicineDoctorRoomProvider from "@provider/medicine_doctor_room";

type Props = {
  patientID: string | number;
  orderList: ISelectedExecuteOrder[];
  selection: ISelected;
  visitsByID?: IGetVisits;
};

const Fields = ({ patientID, orderList, selection, visitsByID }: Props) => {
  const [visible, setVisible] = useState({
    productCategory: false,
    marker: false,
    open: true,
    orderList: false,
  });
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PROCESS_ORDER";
  });

  useEffect(() => {
    const marker = [
      ...(visitsByID?.visitMarker?.doctorMarker0 || []),
      ...(visitsByID?.visitMarker?.doctorMarker1 || []),
      ...(visitsByID?.visitMarker?.doctorMarker2 || []),
      ...(visitsByID?.visitMarker?.doctorMarker3 || []),
    ];

    setVisible({ ...visible, open: marker?.length ? true : false });

    //eslint-disable-next-line
  }, [visitsByID?.visitMarker]);

  useEffect(() => {
    setVisible({ ...visible, orderList: !!selection?.selection?.list?.length });
    //eslint-disable-next-line
  }, [selection?.selection?.list]);

  const viewDetail = () => {
    setVisible({ ...visible, open: !visible?.open });
  };

  const viewDetailOrderList = () => {
    setVisible({ ...visible, orderList: !visible?.orderList });
  };

  const onManageModal = () => {
    setVisible({ ...visible, productCategory: !visible?.productCategory });
  };

  const openMaker = () => {
    setVisible({ ...visible, marker: !visible?.marker });
  };

  return (
    <div className="mt-2">
      <Row className="header-table">
        <Col className="f-normal col-start t-dark-blue">
          <span className="f-bold pr-05 ">{t("confirmedOrderList")}</span>
          <span>
            {React.createElement(
              visible.orderList ? UpOutlined : DownOutlined,
              {
                onClick: viewDetailOrderList,
              }
            )}
          </span>
        </Col>
      </Row>

      <div className={visible.orderList ? "open" : "close"}>
        <OrderList orderList={orderList} selection={selection} />
      </div>
      <RejectGroupOrder />

      <MainMedicineDoctorRoomProvider
        value={{
          medicalRecord: medicalRecord?.[0]?.isEdit,
          title: "returnMedicine.title",
          buttonName: "editMedicineReturn",
          buttonAdd: "addMedicineReturn",
          key: "key",
          status: "return_drug",
          eventId: visitsByID?.eventId || 0,
          drugStatus: DRUG_STATUS.CONTINUOUS,
        }}
      >
        <ReturnMedicine />
      </MainMedicineDoctorRoomProvider>

      <div className="mt-2 ">
        <Checkbox
          checked={visible.open}
          defaultChecked={visible.open}
          onChange={viewDetail}
        >
          <span className="f-normal t-dark-blue">{t("physicalData")}</span>
        </Checkbox>
      </div>

      <div className={visible.open ? "open" : "close"}>
        <Row className="header-table mt-2">
          <Col lg={18} className="f-normal col-start t-dark-blue">
            <span className="f-bold pr-05 ">{t("physicalData")}</span>
          </Col>
          {medicalRecord?.[0]?.isEdit ? (
            <Col lg={6} className="col-end">
              {visitsByID?.visitMarker?.doctorMarker0 ||
              visitsByID?.visitMarker?.doctorMarker1 ||
              visitsByID?.visitMarker?.doctorMarker2 ||
              visitsByID?.visitMarker?.doctorMarker3 ? (
                <Button type="primary" onClick={openMaker}>
                  {t("edit")}
                </Button>
              ) : null}
            </Col>
          ) : null}
        </Row>

        {visitsByID?.visitMarker?.doctorMarker0 ||
        visitsByID?.visitMarker?.doctorMarker1 ||
        visitsByID?.visitMarker?.doctorMarker2 ||
        visitsByID?.visitMarker?.doctorMarker3 ? (
          <ViewMarker visitsByID={visitsByID} />
        ) : (
          <div className="border-note p-1">
            <Empty
              image={empty}
              imageStyle={{
                height: 60,
              }}
              description={
                <span className="t-grey-white">{`${t(
                  "addPhysicalPoint"
                )}`}</span>
              }
            >
              {medicalRecord?.[0]?.isEdit ? (
                <Button type="primary" onClick={openMaker}>
                  + {t("addPhysicalPoint")}
                </Button>
              ) : null}
            </Empty>
          </div>
        )}
      </div>

      {visible?.productCategory && (
        <ProductCategory
          patientID={patientID}
          visible={visible?.productCategory}
          onModal={onManageModal}
        />
      )}

      {visible.marker && (
        <ModalMarker
          visible={visible?.marker}
          onModal={openMaker}
          visitsByID={visitsByID}
        />
      )}
    </div>
  );
};

export default Fields;
