export const VitalSignIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="clip-Vital_sign">
          <path d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-Vital_sign)" data-name="Vital sign">
        <g
          fill="#fff"
          data-name="Group 43622"
          transform="translate(24243.859 22898.885)"
        >
          <path
            d="M248.167 93.913c-1.567-.723-2.024-1.961-2.021-3.649q.056-34.3-.006-68.6c-.005-2.314 1.142-3.769 3.546-3.545.476.045.96.007 1.5.007.073 1.207.055 2.382.229 3.527a7.946 7.946 0 007.757 6.813q18.12.066 36.241 0a8.043 8.043 0 007.844-7.838c.025-.783 0-1.567 0-2.418a19.328 19.328 0 013.06.032 2.558 2.558 0 012.033 2.735c.011 3.473 0 6.947.005 10.421V46.7c-2.019-.11-3.944-.337-5.864-.3a26.3 26.3 0 00-25.613 22.533c-1.348 9.694 1.858 17.824 9.252 24.271.273.238.549.471.823.707zM262.1 52.042c5.929.023 10.242-6.4 7.247-11.789a27.722 27.722 0 00-5.469-6.825 2.582 2.582 0 00-3.619.029 30.13 30.13 0 00-5.384 6.67c-3.126 5.39 1.2 11.952 7.225 11.915m30.016-11.714c2.535 0 5.072.032 7.605-.011a2.7 2.7 0 002.795-2.722 2.747 2.747 0 00-2.833-2.692c-1.025-.027-2.051-.006-3.078-.006-3.954 0-7.907-.007-11.861 0a2.747 2.747 0 00-2.847 1.928 2.7 2.7 0 002.7 3.488c2.5.048 5.01.011 7.515.011m-29.957 22.597c1.872 0 3.743.008 5.615 0a2.723 2.723 0 10.02-5.432q-5.615-.018-11.228 0a2.724 2.724 0 10-.021 5.432c1.871.01 3.743 0 5.614 0m-.041 10.879c1.9 0 3.8.009 5.7 0a2.72 2.72 0 10.012-5.43q-5.659-.022-11.319 0a2.721 2.721 0 10-.012 5.431c1.871.013 3.743 0 5.614 0m0 10.876c1.9 0 3.8.01 5.7 0a2.72 2.72 0 10.005-5.43q-5.66-.021-11.319 0a2.721 2.721 0 10-.005 5.43c1.871.013 3.743 0 5.614 0"
            data-name="Path 40746"
            transform="translate(-24479 -22896)"
          ></path>
          <path
            d="M301.09 93.912c-1.289-.28-2.6-.5-3.865-.851a21 21 0 118.279.7 4.7 4.7 0 00-.607.155zm-.137-20.621c-.1-.019-.136-.015-.148-.032-.641-.876-1.269-1.761-1.919-2.629a2.753 2.753 0 00-3.9-.726 2.709 2.709 0 00-.527 3.864q2.1 3 4.261 5.969a2.688 2.688 0 004.567-.05c2.3-3.435 4.552-6.9 6.793-10.375a2.712 2.712 0 10-4.564-2.924c-1.373 2.058-2.712 4.139-4.068 6.209-.16.245-.337.479-.49.694"
            data-name="Path 40747"
            transform="translate(-24479 -22896)"
          ></path>
          <path
            d="M269.461 11.751c-.711-3.135-.224-5.885 1.976-8.167a7.764 7.764 0 015.745-2.469 7.9 7.9 0 016.618 3.311 8.4 8.4 0 011.263 7.325h1.085c2.9 0 5.8-.008 8.7 0a2.712 2.712 0 012.977 2.979q.014 2.673 0 5.345a2.71 2.71 0 01-2.9 2.974q-17.666.015-35.33 0a2.715 2.715 0 01-2.9-2.892q-.025-2.763 0-5.527a2.7 2.7 0 012.9-2.877c2.929-.016 5.858 0 8.787 0h1.07"
            data-name="Path 40748"
            transform="translate(-24479 -22896)"
          ></path>
          <path
            d="M262.139 39.31c.837 1.19 1.626 2.213 2.306 3.3a2.341 2.341 0 01-.277 3.066 2.824 2.824 0 01-3.226.685 2.546 2.546 0 01-1.23-3.745 15.213 15.213 0 01.992-1.51c.409-.557.86-1.083 1.435-1.8"
            data-name="Path 40749"
            transform="translate(-24479 -22896)"
          ></path>
        </g>
      </g>
    </svg>
  );
};
