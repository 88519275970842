import React, { useEffect, useState } from "react";
import { IAppointments } from "@interface/IAppointments.interface";
import { ISearchDetailSlots } from "@interface/IAppointments.interface";
import { useGetAppointments } from "@services/api/appointments";
import { APPOINTMENT_STATUS, ENUM_ORDERBY } from "@tools/util.enum";
import FormReserveQueue from "@components/form_reserve_queue/FormReserveQueue";
import ListDetailScheduler from "@components/detail_list_slots/DetailListSlots";

type Props = {
  slotID: number;
  visible: boolean;
  setVisible: (e: boolean) => void;
  patientID?: number;
  visitID?: number;
  appointmentsList: {
    appointmentsList: IAppointments[];
    setAppointmentsList: (e: IAppointments[]) => void;
  };
};

const Fields = ({
  slotID,
  visible,
  setVisible,
  patientID,
  visitID,
  appointmentsList,
}: Props) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const appointmentList = useGetAppointments();

  useEffect(() => {
    setTotal(appointmentList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [appointmentList?.data]);

  const getAppointmentsList = async (e?: ISearchDetailSlots) => {
    appointmentList.mutate(
      {
        orderby: ENUM_ORDERBY.ASC,
        name: e?.name,
        status: e?.status
          ? [e?.status]
          : [
              APPOINTMENT_STATUS.WAITING_FOR_ADVICE,
              APPOINTMENT_STATUS.WAITING_FOR_TREATMENT,
              APPOINTMENT_STATUS.ADVISING,
              APPOINTMENT_STATUS.ADVISED,
              APPOINTMENT_STATUS.BOOK_IN_ADVANCE,
            ],
        slotId: slotID,
        limit: limitPage,
        page: currentPage,
      },
      {
        onSuccess: (res) => {
          appointmentsList?.setAppointmentsList(res.result?.[0]?.data || []);
        },
      }
    );
  };

  return (
    <React.Fragment>
      <div className="set-layout ">
        <ListDetailScheduler
          slotID={slotID}
          getAppointmentsList={getAppointmentsList}
          current={{ currentPage: currentPage, setCurrentPage: setCurrentPage }}
          limit={{ limitPage: limitPage, setLimitPage: setLimitPage }}
          total={total}
          appointmentsList={appointmentsList?.appointmentsList}
        />
      </div>
      {visible && (
        <FormReserveQueue
          setVisible={setVisible}
          visible={visible}
          status="create"
          getAppointmentsList={getAppointmentsList}
          patientID={patientID}
          visitID={visitID}
        />
      )}
    </React.Fragment>
  );
};

export default Fields;
