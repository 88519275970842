import { FC } from "react";
import { useTranslation } from "react-i18next";
import Result from "./Result";
import ModalLabResult from "./Modal";

interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

type Props = {
  onModal: () => void;
  visible: IVisible;
};

const DetailLabInterface: FC<Props> = ({ onModal, visible }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="set-layout ">
        <Result
          title={<b className="header-card ">{`${t("labResults")}`}</b>}
        />
      </div>

      {visible && <ModalLabResult visible={visible} onModal={onModal} />}
    </div>
  );
};

export default DetailLabInterface;
