import { useTranslation } from "react-i18next";
import { Col, Row, Card } from "antd";
import { useEffect, useState } from "react";
import TableConfirmOrder from "./Table";
import SearchConfirmOrder from "./Search";

const ConfirmOrder = () => {
  const [searchData, setSearchData] = useState();
  const { t } = useTranslation();
  useEffect(() => {
    sessionStorage.removeItem("selectedRowKeys");
    sessionStorage.removeItem("totalAndQuantity");

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("physicianAssistant")}</h1>
          </div>

          <span className="t-dark-blue">
            <b>{`${t("confirmOrder.title")}${t("nursery")}`}</b>
          </span>
        </Col>
        <Col lg={2} className="justify-col"></Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("allNursery")}</b>}
        >
          <SearchConfirmOrder setSearchData={setSearchData} />
          <TableConfirmOrder searchData={searchData} />
        </Card>
      </div>
    </div>
  );
};

export default ConfirmOrder;
