import { thrownResponse } from "./auth";
import { IRoleGetList } from "@interface/IRole.interface";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetRoleList = (
  enabled: boolean,
  params?: any
): UseQueryResult<Elementary<IRoleGetList[]>> => {
  return useQuery(
    ["get-role-list", params],
    async () => {
      const res = await axios.get("/role", { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: enabled }
  );
};

export const useGetRoleByID = (
  id: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IRoleGetList>> => {
  return useQuery(["get-role-by-id", id, params], async () => {
    if (!id && id !== 0) {
      return;
    }
    const res = await axios.get(`/role/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data?.message;
    }
    thrownResponse(res);
  });
};

export const useGetRoleForm = (): UseQueryResult<
  ElementaryOneMore<IRoleGetList>
> => {
  return useQuery(["get-role-list"], async () => {
    const res = await axios.get(`/role/form`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data?.message;
    }
    thrownResponse(res);
  });
};

export const useCreateRole = () => {
  return useMutation(["create-role"], async ({ data }: any) => {
    const res = await axios.post(`/role`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateRoleByID = () => {
  return useMutation(["update-role-by-id"], async ({ id, data }: any) => {
    if (!id && id !== 0) {
      return null;
    }
    const res = await axios.put(`/role/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteRoleByID = () => {
  return useMutation(async (id?: number) => {
    if (!id && id !== 0) {
      return null;
    }
    const res = await axios.delete(`/role/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
