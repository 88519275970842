import { t } from "i18next";
import { FC } from "react";
import { Age } from "@tools/age";
import { dayjs } from "@tools/timezone";
import { IGetVisits } from "@interface/IVisits.interface";
import { TextBRHTML } from "@tools/util";
import { keyNameMarker } from "@interface/IAppointments.interface";
import { useTranslation } from "react-i18next";
import { Col, Row, Image, Checkbox, Divider } from "antd";
import React from "react";
import moment from "moment";
import ImageLogo from "@components/image/ImageLogo";

type Props = {
  refData: React.MutableRefObject<null>;
  visitsByID?: IGetVisits;
  divHeight: number;
};

const keyName: keyNameMarker[] = [
  "soapMarker0",
  "soapMarker1",
  "soapMarker2",
  "soapMarker3",
];

const ReportNotesPhysical: FC<Props> = ({ refData, visitsByID, divHeight }) => {
  const { i18n } = useTranslation();
  return (
    <div ref={refData} id="container-physical">
      {visitsByID?.physicalNote ? (
        <div className="mb-3">
          <Row className="t-start document-border-header p-05">
            <Col span={6}>
              <div className="mt-1">
                <ImageLogo
                  height="50px"
                  width="120px"
                  src={visitsByID?.branch?.image}
                  alt="logo"
                />
              </div>
            </Col>
            <Col span={18}>
              <Row>
                <Col>
                  <div>Name:</div>
                </Col>
                <Col span={13} className="t-underline">
                  <div className=" f-normal" style={{ paddingLeft: 10 }}>
                    {i18n.language === "th"
                      ? `${visitsByID?.patient?.titleTh || ""}${[
                          visitsByID?.patient?.firstNameTh,
                          visitsByID?.patient?.lastNameTh,
                        ]
                          ?.join(" ")
                          ?.trim()}`
                      : `${visitsByID?.patient?.titleEn || ""}${[
                          visitsByID?.patient?.firstNameEn,
                          visitsByID?.patient?.lastNameEn,
                        ]
                          ?.join(" ")
                          ?.trim()}`}
                  </div>
                </Col>

                <Col>Date:</Col>

                <Col span={6} className="t-underline">
                  <div className=" f-normal" style={{ paddingLeft: 10 }}>
                    {moment(visitsByID?.createdAt).format("DD-MM-YYYY")}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="mt-05">HN:</Col>

                <Col span={7} className="t-underline mt-05">
                  <div className=" f-normal" style={{ paddingLeft: 10 }}>
                    {visitsByID?.patient?.hn}
                  </div>
                </Col>

                <Col className="mt-05">Birth Date:</Col>

                <Col span={7} className="t-underline mt-05">
                  <div className=" f-normal" style={{ paddingLeft: 10 }}>
                    {visitsByID?.patient?.dateOfBirth
                      ? moment(visitsByID?.patient?.dateOfBirth).format(
                          "DD-MM-YYYY"
                        )
                      : "-"}
                  </div>
                </Col>

                <Col className="mt-05">Age :</Col>

                <Col span={2} className="t-underline mt-05">
                  <div className=" f-normal" style={{ paddingLeft: 10 }}>
                    {Age(visitsByID?.patient?.dateOfBirth || "")}
                  </div>
                </Col>

                <Col className="mt-05">y</Col>
              </Row>

              <Row>
                <Col className="mt-05">Physician:</Col>

                <Col span={8} className="t-underline mt-05">
                  <div className=" f-normal " style={{ paddingLeft: 10 }}>
                    {i18n.language === "th"
                      ? `${[
                          visitsByID?.appointment?.[0]?.slot?.user?.firstNameTh,
                          visitsByID?.appointment?.[0]?.slot?.user?.lastNameTh,
                        ]
                          ?.join(" ")
                          ?.trim()}
                  `
                      : `${[
                          visitsByID?.appointment?.[0]?.slot?.user?.firstNameEn,
                          visitsByID?.appointment?.[0]?.slot?.user?.lastNameEn,
                        ]
                          ?.join(" ")
                          ?.trim()}
                  `}
                  </div>
                </Col>

                <Col className="mt-05">Allergies : </Col>

                <Col
                  style={{ minWidth: 100 }}
                  className="t-underline mt-05 t-red"
                >
                  <div className=" f-normal " style={{ paddingLeft: 10 }}>
                    {` ${[
                      visitsByID?.patient?.drugAllergy,
                      visitsByID?.patient?.foodAllergy,
                    ]
                      ?.join(" / ")
                      ?.trim()}`}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="t-center fs-16 f-bold mt-2 mb-05">
            {t("physicalTherapyTreatmentRecordForm")}
          </div>

          <div className=" fs-14  mt-2">
            <span className="f-bold">{`${t("congenitalDisease")} : `} </span>
            <span>
              {visitsByID?.patient?.medicalProblem || t("noInformation")}
            </span>
          </div>
          <div className=" fs-14 mt-02  ">
            <Row>
              <Col span={24}>
                <span className="f-bold">{`${t("occupation")} :`} </span>
                <span>
                  {visitsByID?.patient?.occupation || t("noInformation")}
                </span>
              </Col>

              <Col span={24}></Col>
            </Row>
          </div>

          <div className=" fs-14 mt-02">
            <span className="f-bold">
              {`${t("physicalTherapyStartDate")} : `}{" "}
            </span>
            <span>
              {(!!visitsByID?.physicalNote?.date &&
                dayjs(visitsByID?.physicalNote?.date).format(
                  "YYYY-MM-DD HH:mm"
                )) ||
                t("noInformation")}
            </span>
          </div>

          <div className="mt-1 fs-14 f-bold">
            {`${t("diagnosis")} / ${t("importantSymptoms")} : `}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: TextBRHTML(
                visitsByID?.physicalNote?.symptoms || t("noInformation")
              ),
            }}
          />

          <Row className="mt-3 mb-3">
            <Col>
              <div className="f-bold">
                {`${t("summaryPatientProblems")} : `}
              </div>
              <div
                dangerouslySetInnerHTML={{
                  __html: TextBRHTML(
                    visitsByID?.physicalNote?.summary || t("noInformation")
                  ),
                }}
              />
            </Col>
          </Row>

          <div className="mt-02 fs-14">
            <span className="f-bold">{`${t("shortTermGoals")} : `}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: TextBRHTML(
                  visitsByID?.physicalNote?.shortTerm || t("noInformation")
                ),
              }}
            />
          </div>
          <div className="mt-02 fs-14">
            <span className="f-bold">{`${t("longTermGoals")} : `}</span>
            <span
              dangerouslySetInnerHTML={{
                __html: TextBRHTML(
                  visitsByID?.physicalNote?.longTerm || t("noInformation")
                ),
              }}
            />
          </div>
          <div className="mt-02 fs-14 f-bold">
            {t("physicalTherapyTreatmentPlan")}
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: TextBRHTML(
                visitsByID?.physicalNote?.treatmentPlan || t("noInformation")
              ),
            }}
          />

          <Row justify="end">
            <span className="f-bold">{`${t("physicalTherapist")}`} </span>
          </Row>
          <Row justify="end">
            <span className="f-normal">{visitsByID?.soapNote?.physical} </span>
          </Row>
        </div>
      ) : null}

      <div className=" break-inside">
        <Row className="t-start document-border-header p-05">
          <Col span={6}>
            <div>
              <ImageLogo
                height="50px"
                width="120px"
                src={visitsByID?.branch?.image}
                alt="logo"
              />
            </div>
          </Col>
          <Col span={18}>
            <Row>
              <Col>
                <div>Name:</div>
              </Col>
              <Col span={13} className="t-underline">
                <div className=" f-normal  " style={{ paddingLeft: 10 }}>
                  {i18n.language === "th"
                    ? `${visitsByID?.patient?.titleTh || ""}${[
                        visitsByID?.patient?.firstNameTh,
                        visitsByID?.patient?.lastNameTh,
                      ]
                        ?.join(" ")
                        ?.trim()}`
                    : `${visitsByID?.patient?.titleEn || ""}${[
                        visitsByID?.patient?.firstNameEn,
                        visitsByID?.patient?.lastNameEn,
                      ]
                        ?.join(" ")
                        ?.trim()}`}
                </div>
              </Col>

              <Col>Date:</Col>

              <Col span={6} className="t-underline">
                <div className=" f-normal " style={{ paddingLeft: 10 }}>
                  {moment().format("DD-MM-YYYY")}
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="mt-05">HN:</Col>

              <Col span={7} className="t-underline mt-05">
                <div className=" f-normal " style={{ paddingLeft: 10 }}>
                  {visitsByID?.patient?.hn}
                </div>
              </Col>

              <Col className="mt-05">Birth Date:</Col>

              <Col span={7} className="t-underline mt-05">
                <div className=" f-normal " style={{ paddingLeft: 10 }}>
                  {visitsByID?.patient?.dateOfBirth
                    ? moment(visitsByID?.patient?.dateOfBirth).format(
                        "DD-MM-YYYY"
                      )
                    : "-"}
                </div>
              </Col>

              <Col className="mt-05">Age :</Col>

              <Col span={2} className="t-underline mt-05">
                <div className=" f-normal " style={{ paddingLeft: 10 }}>
                  {Age(visitsByID?.patient?.dateOfBirth || "")}
                </div>
              </Col>

              <Col className="mt-05">y</Col>
            </Row>

            <Row>
              <Col className="mt-05">Physician:</Col>

              <Col span={8} className="t-underline mt-05">
                <div className=" f-normal " style={{ paddingLeft: 10 }}>
                  {i18n.language === "th"
                    ? `${[
                        visitsByID?.appointment?.[0]?.slot?.user?.firstNameTh,
                        visitsByID?.appointment?.[0]?.slot?.user?.lastNameTh,
                      ]
                        ?.join(" ")
                        ?.trim()}
                  `
                    : `${[
                        visitsByID?.appointment?.[0]?.slot?.user?.firstNameEn,
                        visitsByID?.appointment?.[0]?.slot?.user?.lastNameEn,
                      ]
                        ?.join(" ")
                        ?.trim()}
                  `}
                </div>
              </Col>

              <Col className="mt-05">Allergies : </Col>

              <Col
                style={{ minWidth: 100, paddingLeft: 10 }}
                className="t-underline mt-05 t-red"
              >
                <div className=" f-normal ">
                  {`  ${[
                    visitsByID?.patient?.drugAllergy,
                    visitsByID?.patient?.foodAllergy,
                  ]
                    ?.join(" / ")
                    ?.trim()}`}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="t-center fs-18 f-bold mt-2 mb-05 ">SOAP Note</div>

        <Row className=" mt-02 ">
          <Col span={12}>
            <span className="f-bold">Date : </span>
            <span>
              {visitsByID?.soapNote?.date
                ? moment(visitsByID?.soapNote?.date).format("DD-MM-YYYY")
                : t("noInformation")}
            </span>
          </Col>

          <Col span={12}>
            <div style={{ display: "flex" }}>
              <div className="f-bold mr-05">Duration : </div>
              <div>
                <div
                  dangerouslySetInnerHTML={{
                    __html: TextBRHTML(
                      visitsByID?.soapNote?.duration || t("noInformation")
                    ),
                  }}
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="mt-02 ">
          <div style={{ display: "flex" }}>
            <div className="f-bold mr-05">S : </div>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: TextBRHTML(
                    visitsByID?.soapNote?.s || t("noInformation")
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-02 ">
          <div style={{ display: "flex" }}>
            <div className="f-bold mr-05">O : </div>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: TextBRHTML(
                    visitsByID?.soapNote?.o || t("noInformation")
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-02 ">
          <div style={{ display: "flex" }}>
            <div className="f-bold mr-05">A : </div>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: TextBRHTML(
                    visitsByID?.soapNote?.a || t("noInformation")
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-02 ">
          <div style={{ display: "flex" }}>
            <div className="f-bold mr-05">P :</div>
            <div>
              <div
                dangerouslySetInnerHTML={{
                  __html: TextBRHTML(
                    visitsByID?.soapNote?.p || t("noInformation")
                  ),
                }}
              />
            </div>
          </div>
        </div>
        <div className="mt-02">
          <span className="f-bold">{`${t("physicalTherapist")} : `} </span>
          <span>{visitsByID?.soapNote?.physical || t("noInformation")}</span>
        </div>

        <Row className=" mt-1 break-inside ">
          {keyName?.map((key, index) => {
            const image = visitsByID?.visitMarker?.[key];
            return (
              <React.Fragment key={index}>
                {image ? (
                  <Col span={12} className="  col-center ">
                    <div className=" center-extents">
                      <Image src={image?.[0]?.image} preview={false} />
                    </div>
                  </Col>
                ) : null}
              </React.Fragment>
            );
          })}
        </Row>

        <div className=" break-inside">
          <div className="t-center fs-18 f-bold mt-2 mb-05 ">PT NOTE</div>

          <table width="100%" className="print-border">
            <thead>
              <tr className=" print-border text-center f-bold">
                <td width="90%" className="print-border">
                  NOTE
                </td>
                <td width="10%">SIGN</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="print-right p-1">
                  {visitsByID?.pTNote?.ptNoteList?.map((item, index) => {
                    return (
                      <div key={index} className=" break-inside">
                        <Row>
                          <Col>DATE : </Col>
                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note t-center t-black"
                          >
                            {item?.date}
                          </Col>

                          <Col>CC :</Col>
                          <Col
                            style={{ minWidth: 300 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.cc}
                          </Col>
                        </Row>

                        <Row>
                          <Col>Pain Score : Pre </Col>
                          <Col
                            style={{ minWidth: 30 }}
                            className="t-underline area-checkbox-pt-note t-center t-black"
                          >
                            {item?.pre}
                          </Col>
                          <Col>/10</Col>
                          <Col style={{ marginLeft: "50%" }}>Post </Col>
                          <Col
                            style={{ minWidth: 30 }}
                            className="t-underline area-checkbox-pt-note t-center t-black"
                          >
                            {item?.post}
                          </Col>
                          <Col>/10</Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.PMS} />
                          </Col>
                          <Col> PMS : mode</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.PMS_mode}
                          </Col>
                          <Col> intensity</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.PMS_intensity}
                          </Col>
                          <Col>Area</Col>
                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.PMS_area}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.shock} />
                          </Col>
                          <Col> Shock Wave :</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.shock_wave}
                          </Col>
                          <Col> bar</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.shock_bar}
                          </Col>
                          <Col>Hz Area</Col>
                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.shock_area}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.HPLT} />
                          </Col>
                          <Col> HPLT :</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.HPLT_watt}
                          </Col>
                          <Col> watt Area</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.HPLT_area}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.hypervolt} />
                          </Col>
                          <Col> Hypervolt :</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.hypervolt_text}
                          </Col>
                          <Col> (area)</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.hypervolt_area}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.stretching} />
                          </Col>
                          <Col> Stretching :</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.stretching_text}
                          </Col>
                          <Col> (area)</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.stretching_area}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.manual} />
                          </Col>
                          <Col> Manual Release :</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.manual_release}
                          </Col>
                          <Col> (area)</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.manual_area}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.home} />
                          </Col>
                          <Col> Home Program :</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.home_program}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.mobilization} />
                          </Col>
                          <Col> Mobilization :</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.mobilization_text}
                          </Col>
                        </Row>

                        <Row>
                          <Col className="area-checkbox-pt-note">
                            <Checkbox checked={item?.other} />
                          </Col>
                          <Col>Other :</Col>

                          <Col
                            style={{ minWidth: 100 }}
                            className="t-underline area-checkbox-pt-note pl-1 t-black"
                          >
                            {item?.other_text}
                          </Col>
                        </Row>
                        {visitsByID?.pTNote?.ptNoteList?.length !==
                          index + 1 && <Divider className="mt-05 mb-05" />}
                      </div>
                    );
                  })}
                </td>
                <td>
                  {visitsByID?.pTNote?.image ? (
                    <img
                      width={100}
                      src={visitsByID?.pTNote?.image}
                      alt="image_sign"
                    />
                  ) : null}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* <Col span={7}>
          <img src={Physique} alt="Physique" width="100%" />
        </Col> */}
        {/* 
      <Row>
        <Col span={14}>
          <Row align="bottom" className="border-physical">
            <Col span={12} className="padding-physical">
              <div className="f-bold">Key : Abbreviations</div>
              <div className="f-normal">R = right</div>
              <div className="f-normal">L = left</div>
              <div className="f-normal">BL = bilateral</div>
              <div className="f-normal">ROM = range of motion</div>
              <div className="f-normal">XFF = cross fiber friction</div>
              <div className="f-normal">P = pain</div>
              <div className="f-normal">M = massage</div>
              <div className="f-normal">HA = headache</div>
              <div className="f-normal">{`< = less than`}</div>
              <div className="f-normal">{`> = greater than`}</div>
            </Col>

            <Col span={12} className="padding-physical">
              <div className="f-bold">Key : Symbols for figures </div>
              <div className="f-normal">Pain O</div>
              <div className="f-normal">Stiffness/tension</div>
              <div className="f-normal">Spasm ≈ </div>
              <div className="f-normal">Adhesion/Scar tissue X</div>
              <div className="f-normal">Inflammation O</div>
              <div className="f-normal">Elevation or depression /</div>
              <div className="f-normal"> Rotated ↲ or ↲</div>
            </Col>
          </Row>
        </Col>
      </Row> */}
        {/* <div
          style={{
            marginTop: `${927 - divHeight}px`,
          }}
        ></div> */}
      </div>
    </div>
  );
};

export default ReportNotesPhysical;
