import { RefObject } from "@interface/IGlobal.interface";
import { useLocation } from "react-router-dom";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { useGetUserNurse } from "@services/api/users";
import { useMainModalOrder } from "@provider/modal_order";
import { Radio, Row, TimePicker } from "antd";
import { Button, Col, Divider, Form, FormInstance, Input, Modal } from "antd";
import React, { useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import Signature from "@components/drawing_canvas/Signature";

export const UseItem = (
  success: boolean,
  use: boolean,
  status: "NEW" | "USED" | "REJECTED"
) => {
  if (
    success ||
    use ||
    (!use && status === "USED") ||
    (!use && status === "REJECTED")
  ) {
    return false;
  } else return true;
};

type Props = {
  manageInfor: FormInstance;
  handleUpdateWorkOrderItem: (e: any) => void;
  stageRef: RefObject;
  signature: { signature: string; setSignature: (e: string) => void };
};

const ModalInfo = ({
  manageInfor,
  handleUpdateWorkOrderItem,
  stageRef,
  signature,
}: Props) => {
  const { t, i18n } = useTranslation();
  const format = "HH:mm";
  const { isModalOpen, setIsModalOpen, key } = useMainModalOrder();
  const [isDrawn, setIsDrawn] = useState(signature?.signature ? true : false);
  const userList = useGetUserNurse();
  const location = useLocation();
  const locationPath = location.pathname;
  const updateItem = useIsMutating(["update-work-order-item-by-id"]);
  const updateCourseItem = useIsMutating([
    "update-work-order-course-item-by-id",
  ]);

  const onCancel = () => {
    setIsModalOpen({ ...isModalOpen, [key]: false });
  };

  const onFinish = (data: any) => {
    handleUpdateWorkOrderItem({ ...data, key: key });
  };

  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col md={20} lg={20} className="t-blue ">
              <b>{t("manageData")}</b>
            </Col>
            <Col md={4} lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={isModalOpen[key]}
      onCancel={onCancel}
      width="70%"
      forceRender={true}
      footer={null}
    >
      <div className="scroll">
        <Form
          onFinish={onFinish}
          className="p-2"
          name="manageInfor"
          form={manageInfor}
        >
          <Row gutter={[14, 14]} className="margin-form">
            <Form.Item name="qtyOutstanding" hidden={true}>
              <Input type="number" />
            </Form.Item>
            <Form.Item name="groupId" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item name="categoryId" hidden={true}>
              <Input disabled />
            </Form.Item>
            <Col md={24} lg={24}>
              <Form.Item label={t("category")} name="nameGroup">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={12} lg={12}>
              <Form.Item label={t("nameList")} name="productName">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col md={12} lg={12}>
              <Form.Item label={t("type")} name="group">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={24} lg={24}>
              <Form.Item label={t("Frequency")} name="frequency">
                <Input />
              </Form.Item>
            </Col>

            <Col md={24} lg={24}>
              <Form.Item label={t("details")} name="description">
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  id="remark"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col md={24} lg={24}>
              <Form.Item label={t("remarks")} name="remark">
                <TextArea autoSize={{ minRows: 2, maxRows: 3 }} id="remark" />
              </Form.Item>
            </Col>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.userId !== next.userId || prev.useData !== next.useData
              }
            >
              {(form: FormInstance) => {
                const success = form.getFieldValue("success");
                const use = form.getFieldValue("useData");
                const status = form.getFieldValue("status");
                return (
                  <Col md={24} lg={24}>
                    <Form.Item
                      hidden={!UseItem(success, use, status)}
                      label={t("use")}
                      name="use"
                      rules={[
                        {
                          required: true,
                          message: t("pleaseSelect"),
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>{t("confirm")}</Radio>
                        <Radio value={false}>{t("unconfirmed")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                );
              }}
            </Form.Item>

            <Col md={24} lg={24}>
              <Form.Item label={t("nurseSign")} name="nurseId">
                <Selector
                  placeholder=""
                  Source={userList?.data?.result || []}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                  id="nurse"
                />
              </Form.Item>
            </Col>
            <Form.Item name="groupId" hidden>
              <Input />
            </Form.Item>
            <Col md={24} lg={24}>
              <Form.Item
                shouldUpdate={(prev, next) => prev.groupId !== next.groupId}
              >
                {() => {
                  return (
                    <React.Fragment>
                      {locationPath.includes("nurse-room/procedure/detail") ? (
                        <Row gutter={[14, 14]}>
                          <Col md={12} lg={12}>
                            <Form.Item
                              label="DOSE"
                              name="qty"
                              rules={[
                                {
                                  required: true,
                                },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    const qty = getFieldValue("qtyOutstanding");
                                    if (
                                      qty >= Number(value) &&
                                      Number(value) > 0
                                    ) {
                                      return Promise.resolve();
                                    } else if (!value) {
                                      return Promise.reject();
                                    } else
                                      return Promise.reject(
                                        new Error(
                                          `${t(
                                            "theNumberOfProductsIsNotLessThan"
                                          )} 0 ${t("andNotMore")} ${qty} `
                                        )
                                      );
                                  },
                                }),
                              ]}
                            >
                              <Input type="number" />
                            </Form.Item>
                          </Col>

                          <Col md={12} lg={12}>
                            <Form.Item label="FLUID VOL/IVP " name="fluid">
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col md={12} lg={12}>
                            <Form.Item label="START TIME " name="start_time">
                              <TimePicker format={format} />
                            </Form.Item>
                          </Col>

                          <Col md={12} lg={12}>
                            <Form.Item label="END TIME " name="end_time">
                              <TimePicker format={format} />
                            </Form.Item>
                          </Col>
                        </Row>
                      ) : (
                        //  : groupId ===
                        //   ENUM_PRODUCT_GROUP.ID_SQ_IM_MEDICATIONS ? (
                        //   <Row gutter={[14, 14]}>
                        //     <Col md={12} lg={12}>
                        //       <Form.Item label="DOSE " name="dose">
                        //         <Input />
                        //       </Form.Item>
                        //     </Col>

                        //     <Col md={12} lg={12}>
                        //       <Form.Item label="LOCATION " name="location">
                        //         <Input />
                        //       </Form.Item>
                        //     </Col>

                        //     <Col md={12} lg={12}>
                        //       <Form.Item label="TIME " name="time">
                        //         <TimePicker format={format} />
                        //       </Form.Item>
                        //     </Col>
                        //   </Row>
                        // )
                        locationPath.includes("pain-management") && (
                          <Row gutter={[14, 14]}>
                            <Col md={12} lg={12}>
                              <Form.Item label="TIME " name="time">
                                <TimePicker format={format} />
                              </Form.Item>
                            </Col>
                          </Row>
                        )
                      )}
                    </React.Fragment>
                  );
                }}
              </Form.Item>
            </Col>

            <Col md={24} lg={24}>
              <div className="t-grey-form mt-1 mb-1">{t("signature")}</div>
              <div className="signature">
                <Row gutter={[14, 14]}>
                  <Col span={24} className="col-center">
                    <Signature
                      stageRef={stageRef}
                      signature={signature}
                      drawnState={{ isDrawn, setIsDrawn }}
                    />
                  </Col>

                  {((!isDrawn && !signature?.signature) ||
                    (!isDrawn && signature?.signature)) && (
                    <Col span={24} className="col-center">
                      <p style={{ color: "red" }}>{t("pleaseSign")}</p>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => manageInfor.submit()}
                loading={!!updateItem || !!updateCourseItem}
                disabled={!isDrawn}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInfo;
