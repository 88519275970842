import { ICategories } from "@interface/IOrder.interface";
import { thrownResponse } from "./auth";
import { IProductLabList } from "@interface/IProduct.interface";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";
import { openNotification } from "@components/notification/Notification";
import { downloadXLSX } from "@tools/util";
import i18n from "i18next";

export const useGetProductLab = (
  params?: any
): UseQueryResult<ElementaryOneMore<IProductLabList>> => {
  return useQuery(["get-product-lab", params], async () => {
    const res = await axios.get(`/product/lab`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetProductGroup = (
  params?: any
): UseQueryResult<Elementary<ICategories[]>> => {
  return useQuery(["get-group", params], async () => {
    const res = await axios.get(`/product-group`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetProductList = (
  params?: any
): UseQueryResult<Elementary<IProductLabList[]>> => {
  return useQuery(["get-product-list", params], async () => {
    const res = await axios.get(`/product`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useProductUpload = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(`/product/upload`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetProductExport = (): UseMutationResult<
  ElementaryOneMore<any>
> => {
  return useMutation(["get-product-export"], async (params: any) => {
    const res = await axios.get(`/product/export`, {
      responseType: "blob",
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      downloadXLSX(res?.data, "สินค้า");
      return res?.data || {};
    }

    openNotification({
      method: "GET_ERROR",
      message: i18n.language === "th" ? "ไม่พบข้อมูล" : "Not found",
    });
  });
};
