import { IAppointments } from "../../interface/IAppointments.interface";
import { thrownResponse } from "./auth";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { IParamsGetAppointment } from "../../interface/IAppointments.interface";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";
import { openNotification } from "@components/notification/Notification";
import { handleMassageError } from "@tools/util";

export const useGetAppointments = (params?: IParamsGetAppointment) => {
  return useMutation(
    [`get-appointment`, params],
    async (params: IParamsGetAppointment) => {
      const res = await axios.get(`/appointment`, { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        const appointmentList = res?.data?.result?.[0]?.data?.map(
          (item: IAppointments) => {
            return {
              ...item,
              patientEn: [item?.patient?.firstNameEn, item?.patient?.lastNameEn]
                ?.join(" ")
                ?.trim(),

              patientTh: [item?.patient?.firstNameTh, item?.patient?.lastNameTh]
                ?.join(" ")
                ?.trim(),
              startDate: new Date(item?.date + " " + item?.startDate),
              endDate: new Date(item?.date + " " + item?.endDate),
              roomId: item?.slot?.room?.id,
            };
          }
        );

        return {
          ...res?.data,
          result: [
            {
              ...res?.data?.result?.[0],
              data: appointmentList,
            },
          ],
        };
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useGetAppointmentByID = (
  enabled: boolean,
  id?: number
): UseQueryResult<ElementaryOneMore<IAppointments>> => {
  return useQuery(
    ["get-appointment-byID", id],
    async () => {
      const res = await axios.get(`/appointment/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: enabled }
  );
};

export const useCreateAppointments = () => {
  return useMutation([`create-appointment`], async (data: any) => {
    const res = await axios.post(`/appointment`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }

    thrownResponse(res);
  });
};

export const useUpdateAppointments = () => {
  return useMutation(["update-appointment"], async ({ id, data }: any) => {
    const res = await axios.put(`/appointment/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteAppointment = (): UseMutationResult => {
  return useMutation(async (id: any) => {
    const res = await axios.delete(`/appointment/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateAppointmentOther = () => {
  return useMutation([`create-appointment-other`], async (visitId: any) => {
    const res = await axios.post(`/appointment/other/${visitId}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }

    thrownResponse(res);
  });
};
