import React, { useEffect, useState } from "react";
import { Popover } from "antd";
import { IBranch } from "@interface/IBranch.interface";
import { IGetRoom } from "@interface/IRoom.interface";
import { USER_MANAGE } from "@assets/data/pathRouter";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useDeleteRoom, useGetRoomList } from "@services/api/rooms";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import RoleContext from "@provider/RoleProvider";

type Props = {
  searchValue: any;
  currentPage: ICurrentPage;
};

const Table = ({ searchValue, currentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [visible, setVisible] = useState(false);
  const [roomID, setRoomID] = useState<number>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const deleteRoom = useDeleteRoom();
  const client = useQueryClient();
  const history = useHistory<RouteComponentProps["history"]>();
  const roomList = useGetRoomList({
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    branchId: searchValue?.branchId,
    search: searchValue?.name,
  });
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "USER";
  });

  useEffect(() => {
    setTotal(roomList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [roomList?.data?.result?.[0]?.total]);

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteRoom(roomID);
  };

  const handleDeleteRoom = (roomID?: number) => {
    deleteRoom.mutate(roomID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        client.invalidateQueries([
          "get-room-list",
          {
            page: currentPage.currentPage,
            limit: limitPage,
            orderby: ENUM_ORDERBY.DESC,
            search: searchValue?.name,
          },
        ]);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
      onSettled: () => {
        onModal();
      },
    });
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${USER_MANAGE.updateRoom}/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "5%",
      render: (_: any, data: any, index: number) => {
        return (
          <div>{limitPage * (currentPage?.currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("creationDate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        return <>{createdAt ? moment(createdAt).format("DD-MM-YYYY") : "-"}</>;
      },
    },

    {
      title: `${t("code")}`,
      dataIndex: "code",
      key: "code",
      render: (code: string) => {
        return <div>{code || ""}</div>;
      },
    },

    {
      title: `${t("name")}`,
      dataIndex: "name",
      key: "name",
      render: (name: string, data: IGetRoom) => {
        return (
          <div className="f-normal " key={data?.id}>
            {`${name}`}
          </div>
        );
      },
    },

    {
      title: `${t("details")}`,
      dataIndex: "description",
      key: "description",
      render: (description: string) => {
        return (
          <div>
            <Popover content={description || "-"}>
              <div className="text-content">{description || "-"}</div>
            </Popover>
          </div>
        );
      },
    },

    {
      title: t("branch"),
      dataIndex: "branch",
      key: "branch",
      render: (branch: IBranch) => {
        return (
          <div>
            {i18n.language === "th" ? branch?.nameTh : branch?.nameEn || ""}
          </div>
        );
      },
    },

    {
      title: t("tags"),
      dataIndex: "tags",
      key: "tags",
      render: (tags: string) => {
        return <div>{tags || ""}</div>;
      },
    },

    {
      title: t("color"),
      dataIndex: "color",
      key: "color",
      render: (color: string) => {
        return <div>{color || ""}</div>;
      },
    },
    medicalRecord?.[0]?.isEdit
      ? {
          title: t("manage"),
          dataIndex: "manage",
          key: "manage",
          align: "center",
          width: "9%",
          render: (_: any, data: IGetRoom) => {
            return (
              <div>
                <DeleteOutlined
                  onClick={(e) => {
                    setRoomID(data?.id);
                    onModal();
                    e.stopPropagation();
                  }}
                />
              </div>
            );
          },
        }
      : {},
  ];

  return (
    <>
      <Modaler
        loadingButton={deleteRoom.isLoading}
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>

      <MoTable
        dataSource={roomList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
