import { dayjs } from "@tools/timezone";
import { useTranslation } from "react-i18next";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { Button, Popover, Table } from "antd";

interface SelectedRow {
  selectedRow: number[];
  setSelectedRow: (e: number[]) => void;
}

type Props = {
  selectedRow: SelectedRow;
  orderItemRejectDrug: any;
};

const TableRejectDrugs = ({ selectedRow, orderItemRejectDrug }: Props) => {
  const { t, i18n } = useTranslation();

  const onSelectChange = (selected: any) => {
    const dataList = selected?.filter(
      (item: string | number) => typeof item !== "string"
    );

    selectedRow.setSelectedRow(dataList);
  };

  const rowSelection = {
    selectedRowKeys: selectedRow.selectedRow,
    onChange: onSelectChange,
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, data: any, index: number) => {
        return <div key={index}> {data?.level === 1 ? index + 1 : ""}</div>;
      },
    },

    {
      title: t("orderNumber"),
      dataIndex: "name",
      key: "name",
      render: (_: any, data: any, index: number) => {
        if (data?.level === 1) {
          return <div key={data?.key}>{data?.name}</div>;
        } else if (data?.level === 2) {
          return <div key={index}>{t("drugName")}</div>;
        } else {
          return <div key={index}>{data?.product?.nameTh}</div>;
        }
      },
    },

    {
      title: "",
      dataIndex: "rejectBy",
      key: "rejectBy",
      align: "center",
      render: (rejectBy: any, data: any, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("doctorName")
              : `${
                  i18n.language
                    ? [rejectBy?.firstNameTh, rejectBy?.lastNameTh]
                        ?.join(" ")
                        ?.trim()
                    : [rejectBy?.firstNameEn, rejectBy?.lastNameEn]
                        ?.join(" ")
                        ?.trim()
                }`}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",

      render: (date: string, data: any, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("date")
              : dayjs(date).format("DD-MM-YYYY")}
          </div>
        );
      },
    },

    {
      title: t("amount"),
      dataIndex: "editBy",
      key: "editBy",
      align: "center",

      render: (editBy: any, data: any, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length
              : data?.level === 2
              ? t("amount")
              : `${data?.qty}`}
          </div>
        );
      },
    },
    {
      title: t("details"),
      dataIndex: "data",
      key: "data",
      align: "center",

      render: (_: any, data: any, index: number) => {
        const options = JSON.parse(data?.order?.orderItems[0]?.option || `{}`);

        return (
          <div key={index}>
            {data?.level === 1 ? (
              "-"
            ) : data?.level === 2 ? (
              t("details")
            ) : (
              <Popover
                content={
                  <div>
                    <div>
                      {`${t("startMedication")}    ${
                        options.dateStart
                          ? dayjs(options.dateStart).format("DD-MM-YYYY")
                          : ""
                      } `}
                    </div>
                    <div>
                      {` ${t("stopMedicine")}   ${
                        options.dateStop
                          ? dayjs(options.dateStop).format("DD-MM-YYYY")
                          : ""
                      }`}
                    </div>

                    <div>
                      {`${t("medicineExp")}     ${
                        options.drugExpiration
                          ? dayjs(options.drugExpiration).format("MM-YYYY")
                          : ""
                      }`}
                    </div>
                  </div>
                }
                title={t("details")}
                trigger="click"
              >
                <Button type="primary">{t("clickMe")}</Button>
              </Popover>
            )}
          </div>
        );
      },
    },
  ];

  return (
    <Table
      className="components-table-demo-nested table-selection table-confirm "
      dataSource={orderItemRejectDrug?.workOrderItems?.flatMap(
        (item: ISelectedExecuteOrder) => item?.children
      )}
      columns={columns}
      expandRowByClick
      showSorterTooltip
      rowKey={"key"}
      pagination={false}
      rowSelection={{ ...rowSelection, checkStrictly: false }}
      scroll={{ x: "calc(100vw -20%)" }}
    />
  );
};

export default TableRejectDrugs;
