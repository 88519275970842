import Checkbox, { CheckboxGroupProps } from "antd/lib/checkbox";
import { FC } from "react";
import { useTranslation } from "react-i18next";

interface CCheckboxGroupProps extends CheckboxGroupProps {}
type NotationType = {
  EatingPattern: FC<CCheckboxGroupProps>;
  Period: FC<CCheckboxGroupProps>;
};

const CCheckbox: FC<CCheckboxGroupProps> & NotationType = ({ ...props }) => {
  return <Checkbox.Group {...props} />;
};

const EatingPattern: FC<CCheckboxGroupProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Checkbox.Group
      options={[
        { label: `${t("beforeMeal")}`, value: "beforeMeal" },
        { label: `${t("afterMeal")}`, value: "afterMeal" },
        { label: `${t("withFood")}`, value: "withFood" },
        { label: `${t("asNeeded")}`, value: "asNeeded" },
      ]}
      {...props}
    />
  );
};

const Period: FC<CCheckboxGroupProps> = ({ ...props }) => {
  const { t } = useTranslation();

  return (
    <Checkbox.Group
      options={[
        { label: `${t("morning")}`, value: "morning" },
        { label: `${t("daytime")}`, value: "daytime" },
        { label: `${t("evening")}`, value: "evening" },
        { label: `${t("night")}`, value: "night" },
      ]}
      {...props}
    />
  );
};

CCheckbox.EatingPattern = EatingPattern;
CCheckbox.Period = Period;

export default CCheckbox;
