import { Form } from "antd";
import { _isEmpty } from "@tools/util";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useGetEventByID } from "@services/api/event";
import moment from "moment";
import Fields from "./Fields";
import Header from "./Header";

const CreateCoupon = () => {
  const [confirm] = Form.useForm();
  const match: any = useRouteMatch();
  const eventID = match.params.id;
  const eventByID = useGetEventByID(eventID, !_isEmpty(eventID));

  useEffect(() => {
    if (!eventByID?.data) return;
    confirm.setFieldsValue({
      ...eventByID?.data?.result?.[0],
      date:
        eventByID?.data?.result?.[0]?.startDate ||
        eventByID?.data?.result?.[0]?.endDate
          ? [
              moment(eventByID?.data?.result?.[0]?.startDate),
              moment(eventByID?.data?.result?.[0]?.endDate),
            ]
          : undefined,
      addPromotion: eventByID?.data?.result?.[0]?.eventPromotion?.flatMap(
        (promotionId) => {
          return promotionId?.promotionId;
        }
      ),
    });
    // eslint-disable-next-line
  }, [eventByID?.data]);

  return (
    <div>
      <Header confirm={confirm} eventID={eventID} />
      <Fields confirm={confirm} eventID={eventID} />
    </div>
  );
};

export default CreateCoupon;
