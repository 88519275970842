import { Form } from "antd";
import { useEffect, useState } from "react";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { useCreateBill } from "@services/api/bill";
import { useTranslation } from "react-i18next";
import { IDraftBillByID } from "@interface/IDraftBill.interface";
import { openNotification } from "@components/notification/Notification";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { RouteComponentProps } from "react-router-dom";
import { useGetDraftBillById } from "@services/api/draft_bill";
import { IChild2, IItemProduct } from "@interface/IBill.interface";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useUpdateDraftBillDiscount } from "@services/api/draft_bill";
import React from "react";
import Fields from "./Fields";
import Header from "./Header";
import Decimal from "@tools/decimal";
import RoleContext from "@provider/RoleProvider";
import moment from "moment";
import UserContext from "@provider/UserProvider";
import BigNumber from "bignumber.js";

const CreateBill = () => {
  const { i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const billID = match.params.billingID;
  const draftBillById = useGetDraftBillById(billID);
  const [draftBillForm] = Form.useForm();
  const createBill = useCreateBill();
  const updateDraftBillDiscount = useUpdateDraftBillDiscount();
  const roleList = React.useContext(RoleContext);
  const [action, setAction] = useState<"create" | "update">("update");
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PAY";
  });
  const userList = React.useContext(UserContext);

  useEffect(() => {
    if (!draftBillById?.data) return;
    const patient = draftBillById?.data?.result?.[0]?.order?.patient;
    const nameTH = [
      patient?.[i18n.language === "th" ? "titleTh" : "titleEn"],
      patient?.[i18n.language === "th" ? "firstNameTh" : "firstNameEn"],
      patient?.[i18n.language === "th" ? "lastNameTh" : "lastNameEn"],
    ]
      ?.join(" ")
      ?.trim();
    const user =
      i18n.language === "th"
        ? [userList?.firstNameTh, userList?.lastNameTh]?.join(" ")?.trim()
        : [userList?.firstNameEn, userList?.lastNameEn]?.join(" ")?.trim();
    const mailingAddress = patient?.mailingAddress;
    const mailAddress =
      mailingAddress?.addressTh || mailingAddress?.districtTh
        ? [
            mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            mailingAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "districtTh" : "districtEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "provinceTh" : "provinceEn"
            ],
            mailingAddress?.zipcode,
            mailingAddress?.country,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const _homeAddress = patient?.homeAddress;
    const homeAddress =
      _homeAddress?.addressTh || _homeAddress?.districtTh
        ? [
            _homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            _homeAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            _homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
            _homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
            _homeAddress?.country,
            _homeAddress?.zipcode,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    draftBillForm.setFieldsValue({
      name: nameTH,
      address: mailAddress || homeAddress,
      zipCode: !!mailAddress
        ? patient?.mailingAddress?.zipcode
        : patient?.homeAddress?.zipcode,
      sale: user,
      date: moment(),
      billByIdOrder: draftBillById?.data,
      discount: draftBillById?.data?.result?.[0]?.discount
        ? draftBillById?.data?.result?.[0]?.discount || 0
        : draftBillById?.data?.result?.[0]?.discountPercent
        ? new BigNumber(draftBillById?.data?.result?.[0]?.totalPrice)
            .dividedBy(100)
            .multipliedBy(draftBillById?.data?.result?.[0]?.discountPercent)
        : 0,
      discountPercent: draftBillById?.data?.result?.[0]?.discountPercent || 0,
      netPrice: draftBillById?.data?.result?.[0]?.totalPrice,
      description: draftBillById?.data?.result?.[0]?.description,
    });

    // eslint-disable-next-line
  }, [draftBillById?.data, i18n.language]);

  const onFinish = async (value: any) => {
    const discount = value?.billByIdOrder?.result?.[0]?.order?.groups
      ?.flatMap((item: IItemProduct) =>
        item?.children?.[0]?.children?.flatMap((child) => child)
      )
      ?.map((discount: IChild2) => {
        return {
          workOrderItemId: discount?.item?.id,
          discountPercent:
            discount?.item?.discountType === "%"
              ? discount?.item?.discountPercent
              : "0.00",

          discount:
            discount?.item?.discountType === "฿"
              ? Decimal(discount?.item?.discount)
              : "0.00",
        };
      });

    updateDraftBillDiscount.mutate(
      { data: discount },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          if (action === "create") {
            onConfirmBill(value);
          }
        },
        onError: (res: any) => {
          openNotification({ method: "PUT_ERROR", message: res.message });
        },
      }
    );
  };

  const onConfirmBill = (values: {
    billByIdOrder: ElementaryOneMore<IDraftBillByID>;
  }) => {
    createBill.mutate(values?.billByIdOrder?.result?.[0]?.id, {
      onSuccess: (res: ElementaryOneMore<IDraftBillByID>) => {
        openNotification({ method: "POST_SUCCESS" });
        history.replace(`${PAY_LIST_TAB?.title}/patient/${patientID}`);
      },
      onError: (err) => {
        openNotification({ method: "POST_ERROR", message: String(err) });
      },
    });
  };

  return (
    <React.Fragment>
      <Header
        history={history}
        setAction={setAction}
        draftBillForm={draftBillForm}
        medicalRecord={medicalRecord?.[0]?.isEdit}
        visit={draftBillById?.data?.result?.[0]?.visit}
      />
      <div className="set-layout ">
        <Fields
          billID={billID}
          draftBillById={draftBillById?.data}
          draftBillForm={draftBillForm}
          onFinish={onFinish}
          medicalRecord={medicalRecord?.[0]?.isEdit}
        />
      </div>
    </React.Fragment>
  );
};

export default CreateBill;
