import React, { useState } from "react";
import TableBillingNotes from "./Table";
import SearchBillingNotes from "./Search";

const BillingNotes = () => {
  const [searchValue, setSearchValue] = useState<any>("");
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <div>
      <SearchBillingNotes
        setSearchValue={setSearchValue}
        setCurrentPage={setCurrentPage}
      />
      <TableBillingNotes
        searchValue={searchValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default BillingNotes;
