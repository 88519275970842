import { dayjs } from "@tools/timezone";
import { PROMOTION } from "@assets/data/pathRouter";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { IPromotionList } from "@interface/IPromotion.interface";
import { useMainPromotion } from "@provider/promotion";
import { useEffect, useState } from "react";
import { useGetPromotionList } from "@services/api/promotion";
import { RouteComponentProps, useHistory } from "react-router-dom";
import React from "react";
import MoTable from "@components/table/Table";

const Table = () => {
  const { t, i18n } = useTranslation();
  const { currentPage, search, setCurrentPage } = useMainPromotion();
  const statusValue: {
    [key: string]: {
      color: string;
      text: string;
    };
  } = {
    DAFT: {
      color: "#498DCB",
      text: t("draft"),
    },
    PUBLIC: {
      color: "#77C48B",
      text: t("publish"),
    },
    EXPIRED: {
      color: "#FF5D5D",
      text: t("expire"),
    },
  };
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const [total, setTotal] = useState<number>(1);
  const promotionList = useGetPromotionList({
    page: currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    ...search,
  });

  useEffect(() => {
    if (!promotionList?.data?.result?.[0]?.total) return;
    setTotal(promotionList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [promotionList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: IPromotionList) => {
    history.push(`${PROMOTION.detail}/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: string, __: IPromotionList, index: number) => {
        return <div>{limitPage * (currentPage - 1) + index + 1}</div>;
      },
    },

    {
      title: t("promotion.name"),
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IPromotionList) => {
        return (
          <div>
            {i18n.language === "th" ? data?.nameTh || "" : data?.nameEn || ""}
          </div>
        );
      },
    },

    {
      title: t("startDate"),
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: string) => {
        return <>{dayjs(startDate).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: t("endDate"),
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: string) => {
        return <>{dayjs(endDate).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: number) => {
        return (
          <div
            className="table-queue-status mr-1"
            style={{ backgroundColor: statusValue?.[status]?.color }}
          >
            {statusValue?.[status]?.text}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <MoTable
        rowKey="id"
        dataSource={promotionList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </React.Fragment>
  );
};

export default Table;
