import React, { useEffect, useState } from "react";
import { general } from "@assets/data/pathRouter";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, Form, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDeletePrefix, useGetPrefixById } from "@services/api/prefix";
import Fields from "./Fields";
import Delete from "@assets/img/icon/OPD/delete.svg";
import Modaler from "@components/modaler/Modaler";
import BackHome from "@components/button/BackHome";
import RoleContext from "@provider/RoleProvider";

const FormEdit = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const prefixID = match.params.id;
  const [prefixForm] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);
  const deletePrefix = useDeletePrefix();
  const prefixById = useGetPrefixById(prefixID);
  const roleList = React.useContext(RoleContext);
  const loadingUpdate = useIsMutating(["update-prefix"]);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "GENERAL";
  });

  useEffect(() => {
    const data = prefixById?.data?.result?.[0];
    prefixForm.setFieldsValue({
      ...data,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prefixById?.data]);

  const handleDeletePrefix = () => {
    deletePrefix.mutate(prefixID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        history.push(general);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
      onSettled: () => {
        setVisible(false);
      },
    });
  };

  const onDelete = () => {
    handleDeletePrefix();
  };

  return (
    <div>
      <Modaler
        loadingButton={deletePrefix?.isLoading}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {t("doYouWantToDelete")}
      </Modaler>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("setting")}</h1>
              </div>
              <span className="t-light-gray">{t("generalInfo.title")} / </span>
              <span className="t-dark-blue f-normal">{t("edit")}</span>
            </Col>
          </Row>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="delete mr-05 "
              onClick={(e) => {
                setVisible(true);
                e.stopPropagation();
              }}
            >
              <img className="circle editor-button" src={Delete} alt="delete" />
            </Button>
            <Button className="mr-05 cancel" onClick={history.goBack}>
              {t("cancel")}
            </Button>
            <Button
              className="btn-create"
              onClick={() => prefixForm.submit()}
              loading={!!loadingUpdate}
            >
              {t("save")}
            </Button>
          </Col>
        ) : null}
      </Row>
      <Fields
        prefixForm={prefixForm}
        history={history}
        prefixID={prefixID}
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
    </div>
  );
};

export default FormEdit;
