export const PhysicalIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            d="M0 0H92.631V76.47H0z"
            data-name="Rectangle 22690"
          ></path>
        </clipPath>
        <clipPath id="clip-Physical_therapyrecords">
          <path d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip-Physical_therapyrecords)"
        data-name="Physical therapyrecords"
      >
        <g data-name="Group 27077" transform="translate(4 12)">
          <g fill="#fff" clipPath="url(#clip-path)" data-name="Group 27076">
            <path
              d="M0 192.707c.187-.382.367-.767.563-1.145a3.643 3.643 0 013.391-2.275q25.311.008 50.623-.022c1.209 0 1.3.58 1.292 1.511-.02 6.173-.046 12.346.019 18.518.014 1.312-.42 1.59-1.658 1.587q-26.179-.059-52.358-.023c-.624 0-1.247.058-1.871.089v-18.24"
              data-name="Path 22251"
              transform="translate(0 -134.478)"
            ></path>
            <path
              d="M282.407 188.407c-.144.4-.31.787-.43 1.191a7.582 7.582 0 11-9.457-9.331 7.639 7.639 0 019.468 5.2c.116.407.278.8.419 1.2z"
              data-name="Path 22252"
              transform="translate(-189.776 -127.862)"
            ></path>
            <path
              d="M96.015 78.994v9.452c-3-1.112-5.8-2.117-8.557-3.206-.365-.144-.647-.673-.826-1.085-.957-2.2-1.869-4.422-2.858-6.781l-2.637.929c.172.546.278 1 .453 1.42.924 2.224 1.8 4.474 2.841 6.64a3.649 3.649 0 001.747 1.658c3.433 1.4 6.924 2.646 10.388 3.966 2.109.8 2.987 2.442 2.325 4.266-.647 1.781-2.453 2.48-4.565 1.712-4.206-1.531-8.429-3.024-12.581-4.69a4.6 4.6 0 01-2.32-2.142c-2.083-4.711-4.042-9.48-5.931-14.274-1.612-4.092.329-8.507 4.108-9.547a9.514 9.514 0 012.431-.255c3.714-.025 7.428.034 11.142-.025a8.305 8.305 0 016.856 3.044c2.576 3 5.345 5.832 7.96 8.8a5.886 5.886 0 011.238 2.381c.909 3.64 1.709 7.307 2.546 10.965.485 2.117-.388 3.862-2.131 4.267-2.042.475-3.547-.558-4.028-2.848-.732-3.484-1.379-6.986-2.156-10.459a3.012 3.012 0 00-1.145-1.651c-1.247-.865-2.612-1.559-4.3-2.538"
              data-name="Path 22253"
              transform="translate(-51.815 -47.628)"
            ></path>
            <path
              d="M217.218 246.582c-4.966 0-9.932-.029-14.9.023-1.09.012-1.451-.338-1.4-1.405.07-1.492.046-2.99.009-4.484-.021-.862.3-1.186 1.184-1.184 9.883.022 19.766-.007 29.65.032 2.478.01 3.3 1.169 3.251 4.2-.034 2-.983 2.8-3.333 2.806q-7.232.006-14.463 0z"
              data-name="Path 22254"
              transform="translate(-142.756 -170.194)"
            ></path>
            <path
              d="M104.6 8.377a8.387 8.387 0 01-16.773-.014 8.387 8.387 0 0116.773.014"
              data-name="Path 22255"
              transform="translate(-62.406)"
            ></path>
            <path
              d="M200.961 195.426c0-1.687-.037-3.376.029-5.06.015-.379.361-1.047.6-1.071 3.814-.374 7.655-1.042 11.452-.862a5.586 5.586 0 015.479 5.662 7.01 7.01 0 01-5.213 7.117 22.838 22.838 0 01-4.586.329c-2.167.049-4.338-.025-6.505.03-.933.023-1.3-.278-1.274-1.229.049-1.638.014-3.277.014-4.916"
              data-name="Path 22256"
              transform="translate(-142.777 -133.865)"
            ></path>
            <path
              d="M86.905 140.844c-1.117 1.57-2.3 2.456-4.259 2.324-2.688-.18-5.4-.045-8.1-.047-.639 0-1.262.058-1.257-.921.023-5.1.011-10.191.251-15.348.968 2.277 2.043 4.516 2.876 6.842A7.781 7.781 0 0082 139.017c1.647.447 3.215 1.187 4.9 1.827"
              data-name="Path 22257"
              transform="translate(-52.075 -90.132)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
