import { TextBRHTML } from "@tools/util";
import { INoteVisible } from "@interface/IGlobal.interface";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useUpdateVisits } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { useSetRecoilState } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { useEffect, useState } from "react";
import { IGetVisits, IVisitsUpDate } from "@interface/IVisits.interface";
import { Button, Card, Col, Empty, Form, Row } from "antd";
import empty from "@assets/img/empty.svg";
import ModalNote from "./ModalNote";

type KeyName = "nurseNote" | "note" | "diagnosis" | "lab" | "other" | "labNote";

type Props = {
  title: string;
  name: KeyName;
  visitsByID: IGetVisits | undefined | any;
  medicalRecord: boolean;
  status?: string;
};

const Note = ({ title, name, visitsByID, medicalRecord, status }: Props) => {
  const { t } = useTranslation();
  const setLoadingButton = useSetRecoilState(loadingButtonState);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const updateVisits = useUpdateVisits();
  const [modalNote] = Form.useForm();
  const client = useQueryClient();

  const [visible, setVisible] = useState<INoteVisible>({
    note: false,
    nurseNote: false,
    diagnosis: false,
    lab: false,
    other: false,
    labNote: false,
  });

  useEffect(() => {
    setLoadingButton(updateVisits.isLoading);
    // eslint-disable-next-line
  }, [updateVisits.isLoading]);

  const onClick = () => {
    modalNote.setFieldsValue({
      [`${name}`]: visitsByID?.[`${name}`],
    });
    setVisible({ ...visible, [`${name}`]: !visible[`${name}`] });
  };

  const onFinishUpdate = (value: IVisitsUpDate) => {
    handleUpdateVisitById(value);
  };

  const handleUpdateVisitById = (changed: IVisitsUpDate) => {
    const formData = new FormData();

    formData?.append(name, changed?.[`${name}`]);

    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          setVisible({ ...visible, [`${name}`]: !visible[`${name}`] });
          if (status === "appointment") {
            client.invalidateQueries(["get-appointment-byID"]);
          } else {
            client.invalidateQueries(["get-visitsByID"]);
          }
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  return (
    <div>
      <Card
        className="comment-card mt-2 "
        extra={
          <>
            <Row gutter={[14, 14]} align="middle">
              <Col className="f-normal t-dark-blue ">{title}</Col>
              {medicalRecord ? (
                <Col
                  style={{ marginLeft: "auto" }}
                  className=" link-btn f-normal"
                >
                  <Button type="link" onClick={onClick}>
                    {`${t("edit")} ${title}`}
                  </Button>
                </Col>
              ) : null}
            </Row>
          </>
        }
      >
        {visitsByID?.[`${name}`] ? (
          <div
            className="t-black-light"
            dangerouslySetInnerHTML={{
              __html: TextBRHTML(visitsByID[`${name}`] || ""),
            }}
          />
        ) : (
          <div className="t-black-light">
            <Empty
              image={empty}
              imageStyle={{
                height: 60,
              }}
              description={
                <span className="t-grey-white">
                  {t("no")} {title}
                </span>
              }
            >
              {medicalRecord ? (
                <Button type="primary" onClick={onClick}>
                  {t("createNow")}
                </Button>
              ) : null}
            </Empty>
          </div>
        )}
      </Card>

      {visible[`${name}`] && (
        <ModalNote
          visible={{ visible: visible, setVisible: setVisible }}
          name={name}
          title={title}
          onFinishUpdate={onFinishUpdate}
          modalNote={modalNote}
        />
      )}
    </div>
  );
};

export default Note;
