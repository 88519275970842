import { t } from "i18next";
import { RightOutlined } from "@ant-design/icons";
import { Card, Col, Row, Slider } from "antd";
import React from "react";
import SelectColor from "../selector/SeleteColor";

type Props = {
  setColorSelect: (e: string) => void;
  setSize: (e: number) => void;
  size: number;
  rightOutlined?: boolean;
};

const Pencil = ({ setColorSelect, setSize, size, rightOutlined }: Props) => {
  const onChange = (value: any) => {
    setSize(value);
  };

  return (
    <React.Fragment>
      <Row>
        <Col
          span={2}
          className={!!rightOutlined ? "ml-1 close-icon " : "ml-1 "}
        >
          <RightOutlined className="pencil" />
        </Col>

        <Col span={24}>
          <Card className="mt-05">
            <Col span={24}>
              <Slider
                min={1}
                max={100}
                onChange={onChange}
                value={typeof size === "number" ? size : 0}
              />
            </Col>

            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Col lg={12}>{t("selectColor")} :</Col>
              <Col lg={12}>
                <SelectColor setColorSelect={setColorSelect} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Pencil;
