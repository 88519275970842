import { IUserRoom } from "@interface/IUserRoom.interface";
import { IFormSlots } from "@interface/ISlots.interface";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Divider, Form } from "antd";
import Fields from "./Fields";

type Props = {
  dataSlots: IFormSlots;
  CommitChanges: (added: any) => void;
  doctorList: IUserRoom[];
};

const FormScheduler = ({ dataSlots, CommitChanges, doctorList }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [slotForm] = Form.useForm();
  const create = useIsMutating(["create-slot"]);
  const update = useIsMutating(["update-slot"]);

  useEffect(() => {
    setVisible(!visible);
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={t("booking.doctorBooking")}
      centered
      visible={visible}
      onOk={() => setVisible(true)}
      onCancel={onCancel}
      width="65%"
      footer={null}
    >
      <div className="m-modal">
        <Fields
          dataSlots={dataSlots}
          CommitChanges={CommitChanges}
          slotForm={slotForm}
          setVisible={setVisible}
          doctorList={Array.from(
            new Map(doctorList?.map((item) => [item?.userId, item]))?.values()
          )}
        />
      </div>

      <Divider className="mt-2" />
      <Row gutter={[14, 14]} justify="end">
        <Col lg={3}>
          <Button className="cancel" key="back" onClick={onCancel} block>
            {t("cancel")}
          </Button>
        </Col>
        <Col lg={3}>
          <Button
            className="btn-create"
            block
            onClick={() => slotForm.submit()}
            loading={!!create || !!update}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default FormScheduler;
