import { useMainImageCanvasGroup } from "@provider/image_canvas";
import BackMarker from "@assets/img/BackCanvas.png";
import ImageCanvas from "@components/drawing_canvas/ImageCanvas";

const Back = () => {
  const { imageBackCanvasRef, appointmentsByID, visitsByID, SOAP } =
    useMainImageCanvasGroup();

  return (
    <div>
      <ImageCanvas
        imageCanvasRef={imageBackCanvasRef}
        image={
          SOAP
            ? visitsByID?.visitMarker?.soapMarker1?.[0]?.image || BackMarker
            : `${
                appointmentsByID?.visit?.visitMarker?.doctorMarker1?.[0]
                  ?.image ||
                visitsByID?.visitMarker?.doctorMarker1?.[0]?.image ||
                BackMarker
              }`
        }
      />
    </div>
  );
};

export default Back;
