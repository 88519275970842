import { IItem } from "@interface/IOrder.interface";
import { Elementary } from "@interface/IGlobal.interface";
import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetProductWarehouse = (
  params?: any
): UseQueryResult<Elementary<IItem[]>> => {
  return useQuery(["get-warehouse-product", params], async () => {
    const res = await axios.get(`/warehouse/product/modal`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
