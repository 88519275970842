import ReactToPrint, { useReactToPrint } from "react-to-print";
import { t } from "i18next";
import { IItem } from "@interface/IOrder.interface";
import { IGetVisits } from "@interface/IVisits.interface";
import { ORDER_TYPE } from "@tools/util.enum";
import { executeOrder } from "@assets/data/pathRouter";
import { printOrderList } from "@tools/util";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { ISignature, IVisit } from "@interface/IGlobal.interface";
import { useCreateOrdersDone } from "@services/api/order";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import Header from "./Header";
import ModalConsent from "@components/signature/ModalConsent";
import PrintReport from "./Printreport";

const Document = () => {
  const { i18n } = useTranslation();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const pageStyle = `  @page { size:210mm 297mm;
    margin: 96px;}`;
  const history = useHistory();
  const printReportRef = useRef(null);
  const [orderList, setOrderList] = useState<IItem[]>([]);
  const [visitsByID, setVisitsByID] = useState<IGetVisits>();
  const createOrdersDone = useCreateOrdersDone();
  const [visible, setVisible] = useState<IVisit>({
    first: false,
    second: false,
    third: false,
  });
  const [signature, setSignature] = useState<ISignature>({
    firstSignature: "",
    secondSignature: "",
    thirdSignature: "",
    firstName: "",
    secondName: "",
    thirdName: "",
    firstDate: "",
    secondDate: "",
    thirdDate: "",
    signature: 0,
  });
  const [seletection, setSeletection] = useState<string[]>([]);

  

  useEffect(() => {
    if (sessionStorage.getItem("visitsByID") !== null) {
      const visitsSessionList = sessionStorage.getItem("visitsByID");
      const visitsParse = JSON.parse(
        visitsSessionList ? visitsSessionList : `{}`
      );
      setVisitsByID(visitsParse);
    }

    if (sessionStorage.getItem("seletection") !== null) {
      const seletectionSessionList = sessionStorage.getItem("seletection");
      const seletectionParse = JSON.parse(
        seletectionSessionList ? seletectionSessionList : `{}`
      );
      setSeletection(seletectionParse);
    }
    getOrderList();

    // eslint-disable-next-line
  }, []);

  const handlePrint = useReactToPrint({
    content: () => printReportRef?.current,
    pageStyle: pageStyle,
  });

  const getOrderList = async () => {
    let children = [];
    if (sessionStorage.getItem("orderList") !== null) {
      const orderSessionList = sessionStorage.getItem("orderList");
      const orderParse = JSON.parse(orderSessionList ? orderSessionList : `{}`);
      children = orderParse
        ?.map((childrenItem: any) => {
          return childrenItem?.children?.map((i: any) => i?.children);
        })
        ?.flat(Infinity);

      setOrderList(children);
    }
  };
  const createOrderDone = async () => {
  await  Promise.all([handleCreateOrderDone()])
      .then(([res1]) => {})
      .catch((_) => openNotification({ method: "POST_ERROR" }));
  };

  const intersection = (array1: IItem[], array2: string[]) => {
    return array1?.filter((item: IItem) => {
      return array2?.indexOf(item?.id) !== -1;
    });
  };

  const handleCreateOrderDone = () => {
    let dataCourse: any = [];
    let result: IItem[] | any;

    let orderSignatureList: number[] = [];
    if (sessionStorage?.getItem("orderSignature") !== null) {
      const orderSignature = sessionStorage?.getItem("orderSignature");
      orderSignatureList = JSON.parse(orderSignature ? orderSignature : `{}`);
    }

    const dataList = orderList?.filter((item: IItem) => {
      if (
        item?.product?.category?.id === "C-0006" ||
        item?.product?.category?.id === "C-0007"
      ) {
        result = intersection(
          item?.children?.[0]?.children?.map((dataList: IItem) => {
            return { ...dataList, orderId: item?.orderId };
          }),
          seletection
        );

        result?.forEach((item: IItem) => {
          if (item?.type === ORDER_TYPE.OPTION_DRUG) {
            dataCourse = [
              ...(dataCourse || []),
              {
                uuid: item?.id,
                qty: 1,
                orderId: item?.orderId,
              },
            ];
          } else if (
            orderSignatureList?.indexOf(item?.product?.id) !== -1 &&
            item?.product?.category?.id === "C-0001"
          ) {
            dataCourse = [
              ...(dataCourse || []),
              {
                uuid: item?.id,
                qty:
                  item?.qtyChange !== undefined
                    ? item?.qtyChange
                    : item?.qtyOutstanding,
                orderId: item?.orderId,
                firstSignature: JSON.stringify({
                  firstSignature: signature?.firstSignature,
                  firstName: signature?.firstName,
                  firstDate: signature?.firstDate,
                }),
                secondSignature: JSON.stringify({
                  secondSignature: signature?.secondSignature,
                  secondName: signature?.secondName,
                  secondDate: signature?.secondDate,
                }),
                thirdSignature: JSON.stringify({
                  thirdSignature: signature?.thirdSignature,
                  thirdName: signature?.thirdName,
                  thirdDate: signature?.thirdDate,
                }),
              },
            ];
          } else {
            dataCourse = [
              ...(dataCourse || []),
              {
                uuid: item?.id,
                qty:
                  item?.qtyChange !== undefined
                    ? item?.qtyChange
                    : item?.qtyOutstanding,
                orderId: item?.orderId,
              },
            ];
          }
        });
      }
      return (
        item?.product?.category?.id !== "C-0006" &&
        item?.product?.category?.id !== "C-0007"
      );
    });

    const dataTreatment = dataList
      ?.map((item: IItem) => {
        if (item?.type === ORDER_TYPE.OPTION_DRUG) {
          return {
            uuid: item?.id,
            qty: 1,
            orderId: item?.orderId,
          };
        } else if (
          item?.product?.category?.id === "C-0001" &&
          orderSignatureList?.indexOf(item?.product?.id) !== -1
        ) {
          return {
            uuid: item?.id,
            qty:
              item?.qtyChange !== undefined
                ? item?.qtyChange
                : item?.qtyOutstanding,
            orderId: item?.orderId,
            firstSignature: JSON.stringify({
              firstSignature: signature?.firstSignature,
              firstName: signature?.firstName,
              firstDate: signature?.firstDate,
            }),
            secondSignature: JSON.stringify({
              secondSignature: signature?.secondSignature,
              secondName: signature?.secondName,
              secondDate: signature?.secondDate,
            }),
            thirdSignature: JSON.stringify({
              thirdSignature: signature?.thirdSignature,
              thirdName: signature?.thirdName,
              thirdDate: signature?.thirdDate,
            }),
          };
        } else
          return {
            uuid: item?.id,
            qty:
              item?.qtyChange !== undefined
                ? item?.qtyChange
                : item?.qtyOutstanding,
            orderId: item?.orderId,
          };
      })
      ?.flat(Infinity);
    const data = [...dataCourse ||[], ...dataTreatment ||[]];
    const qtyIsNotNull = data?.filter((item) => {
      return item?.qty !== 0;
    });

    createOrdersDone.mutate(
      { id: visitID, data: qtyIsNotNull },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          sessionStorage.removeItem("visitsByID");
          sessionStorage.removeItem("orderList");
          sessionStorage.removeItem("checkedValues");
          sessionStorage.removeItem("seletection");
          sessionStorage.removeItem("orderSignature");
          history.replace(executeOrder);
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  const filterTreatment = (value: IItem[]) => {
    let orderSignatureList: number[] = [];
    const data = value?.filter((item: any) => {
      if (
        item?.product?.category?.id === "C-0007" ||
        item?.product?.category?.id === "C-0006" ||
        item?.product?.category?.id === "C-0001"
      ) {
        return item;
      }
      return false;
    });

    if (sessionStorage?.getItem("orderSignature") !== null) {
      const orderSignature = sessionStorage?.getItem("orderSignature");
      orderSignatureList = JSON.parse(orderSignature ? orderSignature : `{}`);
    }
    const printOrder = printOrderList(data, seletection);

    const print = printOrder?.filter((item: IItem) => {
      return orderSignatureList?.indexOf(item?.id) !== -1;
    });

    const qtyIsNotNull = print?.filter((item: IItem) => {
      return item?.qty !== 0;
    });

    return qtyIsNotNull;
  };

  return (
    <div>
      <Header
        history={history}
        handlePrint={handlePrint}
        createOrderDone={createOrderDone}
        signature={signature}
      />

      <ReactToPrint content={() => printReportRef?.current} />
      <PrintReport
        printReportRef={printReportRef}
        orderList={filterTreatment(orderList)}
        visitsByID={visitsByID}
        visible={visible}
        setVisible={setVisible}
        signature={signature}
      />
      {visible?.first ? (
        <ModalConsent
          visible={visible}
          setVisible={setVisible}
          signature={signature}
          setSignature={setSignature}
          title={t("consentForm.sign")}
          name="first"
          patientName={`${
            i18n.language === "th"
              ? [
                  visitsByID?.patient?.firstNameTh,
                  visitsByID?.patient?.lastNameTh,
                ]
                  ?.join(" ")
                  ?.trim()
              : [
                  visitsByID?.patient?.firstNameEn,
                  visitsByID?.patient?.lastNameEn,
                ]
                  ?.join(" ")
                  ?.trim()
          }`}
        />
      ) : visible?.second ? (
        <ModalConsent
          visible={visible}
          setVisible={setVisible}
          signature={signature}
          title={t("signTheContributor")}
          setSignature={setSignature}
          name="second"
        />
      ) : (
        visible?.third && (
          <ModalConsent
            visible={visible}
            setVisible={setVisible}
            title={t("signTheWitness")}
            signature={signature}
            setSignature={setSignature}
            name="third"
          />
        )
      )}
    </div>
  );
};

export default Document;
