import { Tabs } from "antd";
import { ITabsState } from "@tools/recoil/recoil-atom";
import { useTranslation } from "react-i18next";
import RoleList from "./role/Role";
import UserList from "./user/User";
import RoomList from "./room/Room";
import UserRoomList from "./user_room/UserRoom";

interface ITab {
  tab: ITabsState;
  setTab: (e: ITabsState) => void;
}
type Props = {
  tab: ITab;
};
const TabsGeneral = ({ tab }: Props) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const callBack = (key: string) => {
    tab.setTab({ ...tab.tab, userManage: key });
  };

  return (
    <Tabs
      defaultActiveKey={tab.tab.userManage}
      className="tab-printdraw "
      onChange={callBack}
    >
      <TabPane forceRender={false} tab={t("user")} key="1" className="mt-2">
        <UserList />
      </TabPane>
      <TabPane forceRender={false} tab={t("role")} key="2" className="mt-2">
        <RoleList />
      </TabPane>
      <TabPane forceRender={false} tab={t("room")} key="3" className="mt-2">
        <RoomList />
      </TabPane>
      <TabPane
        forceRender={false}
        tab={t("doctorRoom")}
        key="4"
        className="mt-2"
      >
        <UserRoomList />
      </TabPane>
    </Tabs>
  );
};

export default TabsGeneral;
