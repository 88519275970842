import { IUser } from "@interface/IUsers.interface";
import { useState } from "react";
import { useLogin } from "@services/api/auth";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { Button, Col, Input, Row } from "antd";
import { encryptWithAES, removeMenu } from "@tools/util";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { RouteComponentProps, useHistory } from "react-router-dom";
import logo from "@assets/img/logo/Ab.png";
import imageLogin from "@assets/img/imageLogin.svg";
import navMenu from "../../nav";

const LoginPage = () => {
  const { t, i18n } = useTranslation();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const history = useHistory<RouteComponentProps["history"]>();
  const login = useLogin();

  const handleLogin = () => {
    const data = {
      username: username,
      password: password,
    };
    login.mutate(data, {
      onSuccess: (res: IUser) => {
        if (!res?.permission?.length) {
          openNotification({
            type: "error",
            message: t("youDoNotHaveAccessRights"),
            // description: res.message,
            custom: true,
          });
        } else {
          const userInfo = encryptWithAES(JSON.stringify(res));
          localStorage.setItem("userInfo", userInfo || "");
          localStorage.setItem("accessToken", res?.accessToken);
          const menu = removeMenu(navMenu, res);

          history.replace(
            menu?.[0]?.children
              ? `${menu?.[0]?.children?.[0]?.route}`
              : `${menu?.[0]?.route}`
          );
          openNotification({
            type: "success",
            message: "ยินดีต้อนรับ",
            description: (
              <>
                {`${t("Mr./Mrs.")} `}
                <b className="text-massage-name">
                  {i18n.language === "th"
                    ? [res?.firstNameTh, res?.lastNameTh]?.join(" ")?.trim()
                    : [res?.firstNameEn, res?.lastNameEn]?.join(" ")?.trim()}
                </b>
                {"  "}
                เข้าสู่ระบบ
              </>
            ),
            custom: true,
          });
        }
      },
      onError: (res) => {
        openNotification({
          type: "error",
          message: String(res),
          // description: res.message,
          custom: true,
        });
      },
    });
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("local-lang", lang);
  };

  return (
    <div style={{ height: "100vh" }}>
      <Row style={{ height: "100vh" }}>
        <Col md={24} lg={14}>
          <div className="container-login">
            <img className="image-login" src={imageLogin} alt="imageLogin" />
            <div className="overlay-login"></div>
            <div className="ti-login-h">ABSOLUTE HEALTH</div>
            <div className="ti-login-hc">Regenerative Clinic</div>
          </div>
        </Col>
        <Col md={24} lg={9}>
          <div className=" language ">
            <span
              className={
                i18n.language === "en"
                  ? "pr-05 f-bold hover t-black-dark"
                  : "pr-05 hover c-black-dark"
              }
              onClick={() => changeLanguage("en")}
            >
              EN
            </span>
            <span className="pr-05 c-black-dark">|</span>
            <span
              className={
                i18n.language === "th"
                  ? "pr-05 f-bold hover t-black-dark"
                  : "pr-05 hover c-black-dark"
              }
              onClick={() => changeLanguage("th")}
            >
              TH
            </span>
          </div>

          <div className="flex-layout-login">
            <img className="image-logo-login" src={logo} alt="logo" />
            <div className="login-header mt-1">{t("welcome")}</div>
            <div className="login-child ">
              {t("logInToYourAccountToContinue")}
            </div>
            <Input
              prefix={<UserOutlined className="site-form-item-icon" />}
              className="mt-1 input-user-login"
              id="username"
              placeholder={t("userName")}
              onChange={(e) => setUsername(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? handleLogin() : "")}
            />

            <Input.Password
              prefix={<LockOutlined />}
              type="password"
              id="password"
              placeholder={t("password")}
              className="mt-1 input-user-login"
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => (e.key === "Enter" ? handleLogin() : "")}
            />

            <div className="d-button">
              <Button
                className="login-form-button mt-3"
                onClick={() => handleLogin()}
                loading={login.isLoading}
              >
                {t("signIn")}
              </Button>
            </div>
          </div>
        </Col>
        <Col lg={1} />
      </Row>
    </div>
  );
};

export default LoginPage;
