import { Elementary } from "@interface/IGlobal.interface";
import { thrownResponse } from "./auth";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useCreateVisitOldFile = () => {
  return useMutation(async ({ data }: any) => {
    const res = await axios.post(`/visit-old-file`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitOldFile = (
  id: number,
  type: string,
  params?: any
): UseQueryResult<Elementary<any[]>> => {
  return useQuery(["get-Visit-old-file", id, type, params], async () => {
    const res = await axios.get(`/visit-old-file/${id}/${type}`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteVisitOldFile = () => {
  return useMutation(async (id?: number) => {
    const res = await axios.delete(`/visit-old-file/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
