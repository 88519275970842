import React, { useEffect, useState } from "react";
import { dayjs } from "@tools/timezone";
import { IInvoice } from "@interface/IInvoice.interface";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useGetInvoiceByPatient } from "@services/api/invoice";
import { Button, Dropdown, Menu } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import MoTable from "@components/table/Table";
import Decimal from "@tools/decimal";
import RoleContext from "@provider/RoleProvider";

type Props = {
  searchValue: any;
  currentPage: number;
  setCurrentPage: (e: number) => void;
};

const Table = ({ searchValue, currentPage, setCurrentPage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const invoiceList = useGetInvoiceByPatient(patientID, {
    page: currentPage,
    limit: limitPage,
    patientId: patientID,
    ...searchValue,
    date: searchValue?.date
      ? dayjs(searchValue?.date).format("YYYY-MM-DD")
      : undefined,

    orderby: `${
      searchValue?.status === 2 ? ENUM_ORDERBY.ASC : ENUM_ORDERBY.DESC
    }`,
  });
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PAY";
  });

  useEffect(() => {
    setTotal(invoiceList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [invoiceList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onChangeSelect = (value: string, data: IInvoice) => {
    if (value === "1") {
      history.push(
        `${PAY_LIST_TAB.invoiceCreateReceipt}/${data?.id}/create-receipt-full-amount/patient/${patientID}`
      );
    } else if (value === "2") {
      history.push(
        `${PAY_LIST_TAB.invoiceCreateReceipt}/${data?.id}/create-receipt-split-up/patient/${patientID}`
      );
    } else {
    }
  };

  const onClickRow = (event: any) => {
    history.push(
      `${PAY_LIST_TAB.editInvoice}/${event.id}/patient/${patientID}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "15%",
      render: (_: any, data: IInvoice, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <>{dayjs(date).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: `${t("number")}${t("document")}`,
      dataIndex: "invoiceNumber",
      key: "invoiceNumber",
    },

    {
      title: t("netTotal"),
      dataIndex: "netPrice",
      key: "netPrice",
      render: (netPrice: number) => {
        return <>{Decimal(Number(netPrice || 0))}</>;
      },
    },

    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: string, data: IInvoice) => {
        return (
          <>
            {medicalRecord?.[0]?.isEdit ? (
              status === "OPENING" ? (
                <Button className="btn-confirm-receipt">
                  {t("openReceipt")}
                </Button>
              ) : status === "WAITING" ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={(e) => onChangeSelect(e.key, data)}
                        key="1"
                      >
                        {`${t("create")}${t("receipt")}`}
                      </Menu.Item>
                      <Menu.Item
                        onClick={(e) => onChangeSelect(e.key, data)}
                        key="2"
                      >
                        {t("splitPayment")}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <Button className="btn-wait-receipt">
                    {t("waitingToCollect")} <DownOutlined />
                  </Button>
                </Dropdown>
              ) : status === "INSTALLMENT" ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={(e) => onChangeSelect(e.key, data)}
                        key="2"
                      >
                        {t("splitPayment")}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <Button>
                    {t("splitUp")} <DownOutlined />
                  </Button>
                </Dropdown>
              ) : null
            ) : status === "OPENING" ? (
              <Button className="btn-confirm-receipt">
                {t("openReceipt")}
              </Button>
            ) : status === "WAITING" ? (
              <Button className="btn-wait-receipt">
                {t("waitingToCollect")}
              </Button>
            ) : status === "INSTALLMENT" ? (
              <Button> {t("splitUp")}</Button>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={invoiceList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
        onDoubleClick={true}
        onClickRow={onClickRow}
      />
    </>
  );
};

export default Table;
