import { useTranslation } from "react-i18next";
import { useMainDashboard } from "@provider/dashboard";
import { IProductDashboard } from "@provider/dashboard/interface";
import { Badge, Card, Col, Row } from "antd";
import MoTable from "@components/table/Table";

const MedicineRoom = () => {
  const { t, i18n } = useTranslation();
  const { productMedicine, medicine } = useMainDashboard();
  const columns = [
    {
      title: t("pharmacyRoom"),
      dataIndex: "title",
      width: "35%",
      render: (_: string, result: IProductDashboard) => {
        return (
          <div className="f-bold" style={{ color: "#004C97" }}>
            {i18n.language === "th"
              ? result?.nameTh || ""
              : result?.nameEn || ""}
          </div>
        );
      },
    },
    {
      title: <Badge color="#004C97" text={t("newOrder")} />,
      dataIndex: "sumOrder",
      render: (sumOrder: string) => {
        return (
          <div className="f-bold" style={{ color: "#004C97" }}>
            {sumOrder}
          </div>
        );
      },
    },
    {
      title: <Badge color="#498DCB" text={t("cure")} />,
      dataIndex: "sumWorkOrder",
      render: (sumWorkOrder: string) => {
        return (
          <div className="f-bold" style={{ color: "#498DCB" }}>
            {sumWorkOrder}
          </div>
        );
      },
    },
    {
      title: <Badge color="#77C48B" text={t("finish")} />,
      dataIndex: "sumSuccess",
      render: (sumSuccess: string) => {
        return (
          <div className="f-bold" style={{ color: "#77C48B" }}>
            {sumSuccess}
          </div>
        );
      },
    },
  ];

  return (
    <Card className="card-table-dashboard">
      <Row gutter={[14, 14]}>
        <Col md={4} lg={4}>
          <span>{medicine?.general || 0}</span>
          <span>/{t("person")}</span>
        </Col>
        <Col>
          <span>{medicine?.employee || 0}</span>
          <span>/{t("person")}</span>
        </Col>
      </Row>
      <Row gutter={[14, 14]}>
        <Col md={4} lg={4}>
          {t("performer")}
        </Col>
        <Col>{t("employeeWelfare")}</Col>
      </Row>

      <MoTable
        className="null"
        rowClassName={(r) => {
          return " no-header no-cursor";
        }}
        columns={columns}
        dataSource={productMedicine || []}
        pagination={false}
        scroll={{ y: 220, x: 500 }}
        rowKey="key"
        key="key"
      />
    </Card>
  );
};

export default MedicineRoom;
