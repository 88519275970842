import { Doughnut } from "react-chartjs-2";
import { PAYMENT_TYPE } from "@tools/util.enum";
import { showTextInside } from "../showText";
import { useTranslation } from "react-i18next";
import { useMainDashboard } from "@provider/dashboard";
import numeral from "numeral";

const ChartDoughnut = () => {
  const { t } = useTranslation();
  const { cashier } = useMainDashboard();

  const valueDoughnut = (key: string) => {
    return (
      cashier?.payment?.filter((item) => item?.payment === key)?.[0]?.sum || 0
    );
  };

  const data = {
    labels: [t("cash"), t("transfer"), t("credit")],
    datasets: [
      {
        label: "# of Votes",
        data: [
          valueDoughnut(PAYMENT_TYPE.CASH),
          valueDoughnut(PAYMENT_TYPE.TRANSFER),
          valueDoughnut(PAYMENT_TYPE.CREDIT),
        ],
        backgroundColor: ["#004C97", "#498DCB", "#77C48B"],
        borderColor: ["#004C97", "#498DCB", "#77C48B"],
        borderWidth: 0,
        cutout: "80%",
        spacing: 0,
      },
    ],
    text: "45",
  };

  const options: any = {
    elements: {
      center: {
        text: `฿${numeral(cashier?.sum || "0").format("0,0")}`,
        text1: t("total"),
        color: "#000000",
        align: "center",
      },
    },
    chart: {
      width: 200,
    },
    plugins: {
      title: {
        align: "start",
        display: true,
        text: t("cashier"),
        font: {
          size: 15,
          family: "Sukhumvit-Normal",
          weight: "bold",
        },
      },
      legend: {
        position: "bottom",
        align: "center",
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 10,
          font: {
            size: 12,
            family: "Sukhumvit-Light",
          },
        },
      },
      tooltip: {
        usePointStyle: true,
        titleColor: "#fff",
        titleFont: {
          family: "Sukhumvit-Light",
          weight: "bold",
          size: 14,
        },
        bodyFont: {
          family: "Sukhumvit-Light",
          weight: "bold",
          size: 12,
        },
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    aspectRatio: 1,
    showScale: false,
    animations: {
      tension: {
        duration: 1000,
        easing: "linear",
        from: 1,
        to: 0,
        loop: true,
      },
    },
    layout: {
      padding: {
        left: 0,
        right: 0,
        top: 10,
        bottom: 0,
      },
    },
  };

  return (
    <div>
      <Doughnut
        width="130px"
        height="240px"
        data={data}
        options={options}
        plugins={[showTextInside]}
      />
    </div>
  );
};

export default ChartDoughnut;
