import { useMainImageCanvasGroup } from "@provider/image_canvas";
import ImageCanvas from "@components/drawing_canvas/ImageCanvas";
import RightMarker from "@assets/img/RightCanvas.png";

const Right = () => {
  const { imageRightCanvasRef, appointmentsByID, visitsByID, SOAP } =
    useMainImageCanvasGroup();

  return (
    <div>
      <ImageCanvas
        imageCanvasRef={imageRightCanvasRef}
        image={
          SOAP
            ? visitsByID?.visitMarker?.soapMarker2?.[0]?.image || RightMarker
            : `${
                appointmentsByID?.visit?.visitMarker?.doctorMarker2?.[0]
                  ?.image ||
                visitsByID?.visitMarker?.doctorMarker2?.[0]?.image ||
                RightMarker
              }`
        }
      />
    </div>
  );
};

export default Right;
