import { Col, Drawer, Row } from "antd";
import { useLayoutEffect, useState } from "react";
import Fields from "./Fields";
import Affixes from "@components/affixes/Affixes";
import ListAndHistory from "@components/list_history/ListHistory";

const FormProfile = () => {
  const [visible, setVisible] = useState<boolean>(false);
  const [heightView, setHeightView] = useState(190);
  const heightOutput = document.getElementById("height-medical-record");

  useLayoutEffect(() => {
    var viewport_height = heightOutput?.clientHeight;
    if (!!viewport_height && viewport_height !== heightView) {
      setHeightView(Number(viewport_height) + 90);
    }
    // eslint-disable-next-line
  }, [heightOutput?.clientHeight]);

  const showDrawer = () => {
    setVisible(!visible);
  };

  window.addEventListener("resize", function () {
    var viewport_height = heightOutput?.clientHeight;
    if (!!viewport_height && viewport_height !== heightView) {
      setHeightView(Number(viewport_height) + 90);
    }
  });
  return (
    <div
      className="set-layout body-height "
      style={{ padding: `${heightView}px 30px 50px 30px` }}
    >
      <Row gutter={[14, 14]}>
        <Col md={24} lg={18} xs={18}>
          <Fields heightView={heightView} />
        </Col>

        <Col lg={6} xs={6} className="card-display">
          <ListAndHistory heightView={heightView} />
        </Col>
      </Row>
      <Affixes onClick={showDrawer} />

      <Drawer
        width={"50%"}
        closable={false}
        placement="right"
        onClose={showDrawer}
        visible={visible}
      >
        <ListAndHistory status="noCard" />
      </Drawer>
    </div>
  );
};

export default FormProfile;
