import { Layout } from "antd";
import { IUser } from "@interface/IUsers.interface";
import { useGetProfile } from "@services/api/users";
import { useRecoilState } from "recoil";
import { collapsedState } from "@tools/recoil/recoil-atom";
import { decryptWithAES } from "@tools/util";
import Turn from "@assets/img/icon/Moon.png";
import React from "react";
import TheHeader from "./TheHeader";
import TheSideBar from "./TheSideBar";
import TheContent from "./TheContent";
import RoleContext from "@provider/RoleProvider";
import UserContext from "@provider/UserProvider";
import { BranchSeletedProvider } from "@provider/select_branch";

const defaultUser = `{ "firstName": "", "lastName": "","username":"" ,"branch":${null},"permission":[],"role":"","accessToken":"","permission":[]}`;

// import TheFooter from "./TheFooter";

type Props = {
  children?: React.ReactNode;
};

const DefaultLayout: React.FC<Props> = (props) => {
  const [collapsed, setCollapsed] = useRecoilState(collapsedState);
  const dataLocalStorage = localStorage.getItem("userInfo");
  const user: IUser = decryptWithAES(dataLocalStorage || defaultUser);
  const profile = useGetProfile() || JSON.parse(defaultUser);

  const onCollapse = (collapsed: any) => {
    setCollapsed(collapsed);
  };

  const toggleSidebar = () => {
    setCollapsed(!collapsed);
  };

  return (
    <UserContext.Provider
      value={{
        ...user,
        id: profile?.data?.result?.[0]?.id,
        branch: profile?.data?.result?.[0]?.branch,
      }}
    >
      <RoleContext.Provider
        value={
          !!user?.permission?.length
            ? [
                ...user?.permission?.filter((item) => {
                  return !(item?.subMenu?.length > 0);
                }),

                ...user?.permission?.flatMap((item) => {
                  return item?.subMenu;
                }),
              ] || []
            : []
        }
      >
        <BranchSeletedProvider>
          <div>
            <div className="hidden">
              <Layout style={{ minHeight: "100vh" }}>
                <TheSideBar
                  onCollapse={onCollapse}
                  collapsed={collapsed}
                  profile={profile?.data?.result?.[0]}
                />

                <Layout
                  className={
                    collapsed
                      ? "site-layout flex-layout collapsed-class hidden"
                      : "site-layout collapsed-open hidden"
                  }
                >
                  <TheHeader
                    toggleSidebar={toggleSidebar}
                    profile={profile?.data?.result?.[0]}
                  />

                  <TheContent />
                  {/* <TheFooter /> */}
                </Layout>
              </Layout>
            </div>
            <div className="md-hidden">
              <div
                style={{
                  width: "100vw",
                  textAlign: "center",
                  top: "50%",
                  left: "50%",
                  position: "absolute",
                  transform: "translate(-50%, -50%)",
                  fontSize: 30,
                }}
              >
                <div>This website does not support mobile size</div>
                <div>
                  <img src={Turn} alt="Turn" style={{ width: 200 }} />
                </div>
              </div>
            </div>
          </div>
        </BranchSeletedProvider>
      </RoleContext.Provider>
    </UserContext.Provider>
  );
};

export default DefaultLayout;
