import { t } from "i18next";
import { SelectProps } from "antd/lib/select";
import { ITotalAndQuantity } from "@interface/IGlobal.interface";
import { Col, Form, Input, Row, Select } from "antd";
import BigNumber from "bignumber.js";

const { Option } = Select;

interface Props extends SelectProps<any> {
  Source: any[];
  keyName: string;
  keyValue: string;
  title?: any;
  status?: number | any;
  totalAndQuantity?: ITotalAndQuantity;
}

const SelectorDouble = ({
  Source = [],
  onChange,
  value,
  keyName,
  keyValue,
  placeholder = t("all"),
  options,
  disabled = false,
  title,
  status = 0,
  id,
  totalAndQuantity,
  ...props
}: Props) => {
  const percent = (discount: number | string) => {
    let data;
    if (totalAndQuantity?.totalAndQuantity.selector === 1) {
      data = new BigNumber(totalAndQuantity?.totalAndQuantity?.oneDay?.total)
        ?.plus(
          new BigNumber(totalAndQuantity?.totalAndQuantity?.continuous?.total)
        )
        ?.plus(totalAndQuantity?.totalAndQuantity?.labInterface?.total)
        ?.dividedBy(100)
        ?.multipliedBy(new BigNumber(discount));
    } else if (totalAndQuantity?.totalAndQuantity?.selector === 2) {
      data = discount;
    }

    return data;
  };

  const setDiscount = (discount: number | string) => {
    totalAndQuantity?.setTotalAndQuantity({
      ...totalAndQuantity.totalAndQuantity,
      discount: Number(discount),
      percent: percent(discount),
      discountTotal: new BigNumber(
        totalAndQuantity.totalAndQuantity?.oneDay?.total
      )
        .plus(
          new BigNumber(totalAndQuantity.totalAndQuantity?.continuous?.total)
        )
        .plus(totalAndQuantity.totalAndQuantity?.labInterface?.total)
        .minus(new BigNumber(Number(percent(discount)))),
    });
  };

  return (
    <>
      <Row>
        {status === 0 ? (
          <Col md={10} lg={10} className="select-search-text">
            <div>{title} :</div>
          </Col>
        ) : status === 2 ? (
          <Col md={19} lg={19}>
            <Form.Item name="number">
              <Input
                min={1}
                disabled={disabled}
                type="number"
                value={
                  totalAndQuantity?.totalAndQuantity?.discount || undefined
                }
                className="order-summary"
                onChange={(e) => {
                  if (e.target.value === "") {
                    setDiscount(0);
                  } else if (Number(e.target.value) <= 0) {
                    setDiscount(1);
                  } else {
                    setDiscount(e.target.value);
                  }
                }}
              />
            </Form.Item>
          </Col>
        ) : (
          <Col md={status === 1 ? 14 : 19} lg={status === 1 ? 14 : 19}>
            <Input
              min={1}
              disabled={disabled}
              type="number"
              value={totalAndQuantity?.totalAndQuantity?.discount || undefined}
              className="order-summary"
              onChange={(e) => {
                if (e.target.value === "") {
                  setDiscount(0);
                } else if (Number(e.target.value) <= 0) {
                  setDiscount(1);
                } else {
                  setDiscount(e.target.value);
                }
              }}
            />
          </Col>
        )}

        {status === 2 ? (
          <Col md={5} lg={5}>
            <Select
              id={id}
              className="select-search text-select"
              showSearch
              placeholder={placeholder}
              onChange={onChange}
              allowClear
              value={value}
              disabled={disabled}
              bordered={false}
              {...options}
              filterOption={(input, option: any) => {
                return option?.children
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              {...props}
            >
              {Source?.map((item, idx) => (
                <Option value={item?.[keyValue]} key={idx}>
                  {item?.[keyName]}
                </Option>
              ))}
            </Select>
          </Col>
        ) : (
          <Col md={status === 0 ? 14 : 10} lg={status === 0 ? 14 : 10}>
            <Select
              id={id}
              className="select-search text-select"
              showSearch
              placeholder={placeholder}
              onChange={onChange}
              allowClear
              value={value}
              disabled={disabled}
              bordered={false}
              {...options}
              filterOption={(input, option: any) => {
                return option?.children
                  ?.toLowerCase()
                  ?.includes(input?.toLowerCase());
              }}
              {...props}
            >
              {Source?.map((item, idx) => (
                <Option value={item?.[keyValue]} key={idx}>
                  {item?.[keyName]}
                </Option>
              ))}
            </Select>
          </Col>
        )}
      </Row>
    </>
  );
};

export default SelectorDouble;
