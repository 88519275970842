import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { ISignature, IVisitBill } from "@interface/IGlobal.interface";
import { KeyNameSignatureSecond } from "@interface/IGlobal.interface";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { useUpdateDraftBill } from "@services/api/draft_bill";
import Header from "./Header";
import ModalInfo from "@components/signature/ModalInfo";
import { useGetDraftBillById } from "@services/api/draft_bill";
import ComponentToPrint from "../print/ViewDraftA4";

const ViewReport = () => {
  const { i18n } = useTranslation();
  const pageStyle = `  
  @page { 
    size: A4;
    margin: 0; 
  }
`;
  const history = useHistory();
  const match: any = useRouteMatch();
  const billID = match.params.billingID;
  const draftBillIDList = useGetDraftBillById(billID);
  const updateDraftBill = useUpdateDraftBill();
  const componentRef = useRef(null);

  const client = useQueryClient();
  const [visible, setVisible] = useState<IVisitBill>({
    first: false,
    second: false,
  });

  const [divHeight, setDivHeight] = useState(
    document.getElementById("container")?.clientHeight
  );

  useEffect(() => {
    setDivHeight(document.getElementById("container")?.clientHeight);
    // eslint-disable-next-line
  }, [document.getElementById("container")?.clientHeight]);

  const handlePrintComponentRef = useReactToPrint({
    content: () => componentRef.current,
    pageStyle: pageStyle,
  });

  const updateSignature = (
    values: { date: string; title: KeyNameSignatureSecond } & ISignature
  ) => {
    updateDraftBill.mutate(
      {
        id: billID,
        data: values,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-draft-bill-by-id"]);
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
        onSettled: () => {
          setVisible({
            ...visible,
            [values?.title]: !visible[values?.title],
          });
        },
      }
    );
  };

  return (
    <div>
      <Header history={history} handlePrint={handlePrintComponentRef} />

      <ReactToPrint content={() => componentRef.current} />

      <div className="set-layout ">
        <div className="document-body ">
          <ComponentToPrint
            ref={componentRef}
            billIDList={draftBillIDList?.data}
            divHeight={divHeight}
            visible={visible}
            setVisible={setVisible}
            language={i18n.language}
          />
        </div>
      </div>

      {visible.first ? (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={draftBillIDList?.data?.result?.[0]?.date || ""}
          name="first"
          updateSignature={updateSignature}
          isLoading={updateDraftBill?.isLoading}
        />
      ) : visible.second ? (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={draftBillIDList?.data?.result?.[0]?.date || ""}
          name="second"
          updateSignature={updateSignature}
          isLoading={updateDraftBill?.isLoading}
        />
      ) : null}
    </div>
  );
};

export default ViewReport;
