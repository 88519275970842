import { useState } from "react";
import Table from "./Table";
import Search from "./Search";

const RoleList = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const roleFilter = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };

  return (
    <div>
      <Search onFinish={roleFilter} />
      <Table
        searchValue={searchValue}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </div>
  );
};

export default RoleList;
