import React, { useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { collapsedState } from "@tools/recoil/recoil-atom";
import { useTranslation } from "react-i18next";
import { Col, Row, Button, Tabs } from "antd";
import eraser from "@assets/img/icon/OPD/eraser.svg";
import Eraser from "./Eraser";
import pencil from "@assets/img/icon/OPD/pancil.svg";
import Pencil from "./Pencil";
import saveImg from "@assets/img/icon/OPD/saveImg.svg";
import backward from "@assets/img/icon/OPD/backward.svg";
import deleteAll from "@assets/img/icon/OPD/deleteAll.svg";
import CanvasDraw from "react-canvas-draw";

const DrawingCanvas = ({ drawingCanvas, setDrawingCanvas }) => {
  const { t } = useTranslation()
  const stageRef = useRef(null);
  const { TabPane } = Tabs;
  const [size, setSize] = useState(1);
  const [colorSelete, setColorSelete] = useState("black");
  const [colorPen, setColorPen] = useState("black");
  const collapsed = useRecoilValue(collapsedState);


  const setColorPenTab = () => {
    setColorPen(colorSelete);
    setColorSelete("white");
  };

  const tabsCallback = (key) => {
    key === "1" ? setColorSelete(colorPen) : setColorPenTab();
  };

  const onChangeImage = () => {
    // let canvas = stageRef.current.canvas.drawing;
    // let context = canvas.getContext("2d");
    // let data = context.getImageData(0, 0, canvas.width, canvas.height);

    let imageData = stageRef?.current?.canvas.drawing.toDataURL("image/png");

    const data = stageRef.current.getSaveData();
    setDrawingCanvas({ ...drawingCanvas, drawing: data, image: imageData });
  };

  const saveData = () => {
    let canvas = stageRef.current.canvas.drawing;
    let context = canvas.getContext("2d");
    let data = context.getImageData(0, 0, canvas.width, canvas.height);
    let compositeOperation = context.globalCompositeOperation;

    context.globalCompositeOperation = "destination-over";
    context.fillStyle = "#fff";
    context.fillRect(0, 0, canvas.width, canvas.height);
    let imageData = stageRef.current.canvas.drawing.toDataURL("image/png");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.putImageData(data, 0, 0);
    context.globalCompositeOperation = compositeOperation;

    let a = document.createElement("a");
    a.href = imageData;
    a.download = "canvas.png";
    a.click();
  };

  const widthSize = (value) => {
    if (window.innerWidth > 480 && window.innerWidth < 992 && !collapsed) {
      return (window.innerWidth / 24) * 7.3
    } else if (window.innerWidth > 480 && window.innerWidth < 992 && collapsed) {
      return (window.innerWidth / 24) * 10
    } else if (window.innerWidth > 992 && window.innerWidth < 1200 && !collapsed) {
      return (window.innerWidth / 24) * 13
    } else if (window.innerWidth > 992 && window.innerWidth < 1200 && collapsed) {
      return (window.innerWidth / 24) * 13
    } else if (window.innerWidth > 1200 && window.innerWidth < 1600 && !collapsed) {
      return (window.innerWidth / 24) * 13
    } else if (window.innerWidth > 1200 && window.innerWidth < 1600 && collapsed) {
      return (window.innerWidth / 24) * 15
    } else if (window.innerWidth > 1600 && !collapsed) {
      return (window.innerWidth / 24) * 15.5
    }
    else return (window.innerWidth / 24) * 17


  }

  return (
    <div>
      <Row gutter={[14, 14]}>
        <Col sm={10} md={10} lg={20} xl={6} xxl={5}>
          <Button
            className="mr-1 canvas"
            htmlType="submit"
            onClick={() => {
              stageRef.current.undo();
            }}
          >
            <img src={backward} alt="backward" />
          </Button>

          <Button
            className="mr-1 canvas"
            htmlType="submit"
            onClick={() => {
              stageRef.current.clear();
            }}
          >
            <img className="mr-05" src={deleteAll} alt="deleteAll" />
            {t("deleteAll")}
          </Button>

          <Tabs
            className="tab-canvas mt-2 mb-2"
            defaultActiveKey="1"
            tabPosition="left"
            onChange={tabsCallback}
          >
            <TabPane
              tab={
                <>
                  <img src={pencil} alt="pencil" />
                </>
              }
              key="1"
            >
              <Pencil
                setColorSelete={setColorSelete}
                setSize={setSize}
                size={size}
              />
            </TabPane>
            <TabPane
              tab={
                <>
                  <img src={eraser} alt="eraser" />
                </>
              }
              key="2"
            >
              <Eraser setSize={setSize} size={size} />
            </TabPane>
          </Tabs>

          <Row
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button type="dashed" danger onClick={saveData}>
              <img className="mr-05" src={saveImg} alt="saveImg" /> {t("savePNGImage")}
            </Button>
          </Row>
        </Col>
        <Col sm={14} md={14} lg={24} xl={18} xxl={19}>
          <CanvasDraw
            onChange={onChangeImage}
            ref={stageRef}
            saveData={drawingCanvas?.drawing}
            immediateLoading={true}
            lazyRadius={0}
            canvasWidth={widthSize()}
            canvasHeight={500}
            catenaryColor={"black"}
            brushColor={colorSelete}
            brushRadius={size}
            hideGrid={true}
            style={{
              boxShadow:
                "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)",
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default DrawingCanvas;
