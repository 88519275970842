import { t } from "i18next";
import { Select } from "antd";
import { useState } from "react";
import { SelectProps } from "antd/lib/select";

const { Option } = Select;
interface Props extends SelectProps<any> {
  source: any[];
  keyName: string;
  keyValue: string;
  keyData?: string;
}

const SelectorAddress = ({
  bordered = true,
  className,
  source = [],
  onChange,
  value,
  keyName,
  keyValue,
  placeholder = `${t("selectItem")}`,
  options,
  disabled = false,
  id,
  keyData = "name",
}: Props) => {
  const [open, setOpen] = useState<boolean>(false);
  return (
    <Select
      bordered={bordered}
      id={id}
      className={className}
      showSearch
      placeholder={placeholder}
      onChange={onChange}
      allowClear
      value={value?.[keyName]}
      disabled={disabled}
      optionFilterProp="children"
      {...options}
      onBlur={() => setOpen(false)}
      onFocus={() => setOpen(true)}
    >
      {source?.map((item, idx) => (
        <Option value={item?.[keyValue]} key={idx}>
          {open ? item?.[keyData] : item?.[keyName]}
        </Option>
      ))}
    </Select>
  );
};

export default SelectorAddress;
