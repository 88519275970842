import { OPD } from "@assets/data/pathRouter";
import { keyName } from "@interface/IVisits.interface";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import Table from "./Table";
import Header from "./Header";

type Props = {
  name: string;
  keyName: keyName;
};

const pathName: { [kek: string]: string } = {
  medical: OPD.UpLoadFileMedicalReport,
  summary: OPD.UpLoadFileMedicalSummary,
  radiology: OPD.UpLoadFileRadiologyReport,
};

const ViewFile = ({ name, keyName }: Props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const UploadFile = () => {
    history.push({
      pathname: pathName?.[keyName] || "",
      state: { visitID: visitID, keyName: keyName },
    });
  };
  return (
    <div>
      <Header history={history} name={name} />
      <div className="set-layout ">
        <Card
          extra={
            <Row align="middle">
              <Col>
                <b className="header-card ">{name}</b>
              </Col>

              <Col style={{ marginLeft: "auto" }}>
                <Button
                  className="btn-create"
                  type="primary"
                  onClick={UploadFile}
                >
                  <PlusOutlined className="icons-size" />
                  {`${t("upload")}`}
                </Button>
              </Col>
            </Row>
          }
        >
          <Table keyName={keyName} />
        </Card>
      </div>
    </div>
  );
};

export default ViewFile;
