import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { IGetVisits } from "@interface/IVisits.interface";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useGetVisitOrderList } from "@services/api/visits";
import { FC, useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  search: any;
  currentPage: ICurrentPage;
};

const Table: FC<Props> = ({ search, currentPage }) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t, i18n } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const visitOrderList = useGetVisitOrderList({
    limit: limitPage,
    page: currentPage.currentPage,
    orderby: search?.sort
      ? search?.sort === 1
        ? ENUM_ORDERBY.DESC
        : ENUM_ORDERBY.ASC
      : ENUM_ORDERBY.ASC,
    ...search,
  });

  useEffect(() => {
    const resData = visitOrderList?.data;
    !!resData?.result?.length && setTotal(resData?.result[0]?.total || 1);
  }, [visitOrderList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") {
      setLimitPage(page);
    } else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`/doctor-room/order-info/visit/${event?.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      sorter: (a: IGetVisits, b: IGetVisits) => a.id - b.id,
      render: (_: number, data: IGetVisits, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "keyHN",
      sorter: (a: IGetVisits, b: IGetVisits) => a.id - b.id,
      render: (item: any) => {
        return <div>{item?.hn}</div>;
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "patient",
      render: (item: any) => {
        return (
          <div className="f-normal">
            {i18n.language === "th"
              ? [item?.firstNameTh, item?.lastNameTh]?.join(" ")?.trim()
              : [item?.firstNameEn, item?.lastNameEn]?.join(" ")?.trim()}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "patient",
      key: "gender",
      render: (item: any) => {
        let nameGender = gender(item?.gender, i18n.language);

        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "patient",
      key: "patient",
      render: (item: any) => {
        return <div>{Age(item?.dateOfBirth)}</div>;
      },
    },

    {
      title: t("room"),
      dataIndex: "rooms",
      key: "rooms",
      render: (item: string[]) => {
        if (item.length) {
          const newItems = item.map((room) => {
            return t(`${room.replace("_", "").toLowerCase()}Room`);
          });
          return (
            <div className="f-normal" style={{ color: "red" }}>
              {newItems.toString()}
            </div>
          );
        }
      },
    },
    // {
    //   title: t("date"),
    //   dataIndex: "createdAt",
    //   key: "createdAt",
    //   render: (createdAt: any, data: any) => {
    //     return (
    //       <div key={data?.id}>{dayjs(createdAt).format("DD/MM/YYYY HH:mm")}</div>
    //     );
    //   },
    //   sorter: (a: any, b: any) =>
    //     dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
    // },
  ];

  return (
    <>
      <MoTable
        dataSource={visitOrderList?.data?.result?.[0]?.data || []}
        loading={visitOrderList?.isLoading}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
