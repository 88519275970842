import { ORDER_TYPE } from "@tools/util.enum";
import { useLocation } from "react-router-dom";
import { IItem, IOrder } from "@interface/IOrder.interface";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { openNotification } from "@components/notification/Notification";
import { useUpDateOrderItem } from "@services/api/draft_order";
import { useDeleteDraftOrder } from "@services/api/draft_order";
import { IGetDraftOrder, IGroup } from "@interface/IDraftOrder.interface";
import { productName, TextBRHTML } from "@tools/util";
import { Button, Empty, Form, Popover } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import empty from "@assets/img/empty.svg";
import React from "react";
import Modaler from "@components/modaler/Modaler";
import MoTable from "@components/table/Table";
import BigNumber from "bignumber.js";
import ModalUpdate from "../modal_update/ModalUpdate";
import moment from "moment";

type Props = {
  patientID: string | number;
  draftOrderList?: IGetDraftOrder;
  createNow: () => void;
  status?: string;
  medicalRecord: boolean;
  dataSource: IItem[];
  onDeleteDraftOrderList: (e: string) => void;
  onUpdateDraftOrderList: (e: any) => void;
  draftStatus: boolean;
  eventId?: number;
};

const TableOrderList = ({
  patientID,
  draftOrderList,
  createNow,
  status,
  medicalRecord,
  dataSource,
  onDeleteDraftOrderList,
  onUpdateDraftOrderList,
  draftStatus,
  eventId,
}: Props) => {
  const num = useRef(0);
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const locationPath = location.pathname;
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleInput, setVisibleInput] = useState<boolean>(false);
  const [editOrder] = Form.useForm();
  const updateOrderItem = useUpDateOrderItem();
  const deleteDraftOrder = useDeleteDraftOrder();
  const client = useQueryClient();
  const historyPatient = locationPath?.search("patient-history") > -1;
  const [product, setProduct] = useState<any>({
    idProduct: "",
    productKey: "",
    edit: {},
  });

  const checkDrug = (name: string) => {
    return name?.includes("Drug & Supplement") || name === "C-0002";
  };
  const onModal = () => {
    setVisible(!visible);
  };

  const checkRoute = () => {
    return (
      locationPath.includes("/doctorroom") ||
      locationPath.includes("/physician-assistant/confirm-order")
    );
  };

  const onClickRow = async (event: IItem) => {
    setVisibleInput(!visibleInput);
    setProduct({ ...product, edit: event });
    const option = event.option ? JSON.parse(event.option || `{}`) : {};

    const description =
      event?.product?.category?.id === "C-0006"
        ? event?.courseItems
            ?.map((item, index) => {
              return `${index + 1}. ${item?.product?.nameTh} \n`;
            })
            .join("")
        : event?.productDescriptionTh || event?.product?.descriptionTh;
    editOrder.resetFields();
    editOrder.setFieldsValue({
      ...event.product,
      nameShown: event?.product?.nameTh,
      genericName: event?.product?.nameEn,
      ...option,
      drugExpiration: option?.drugExpiration
        ? moment(option.drugExpiration)
        : undefined,
      dateStart: option?.dateStart ? moment(option.dateStart) : undefined,
      dateStop: option?.dateStop ? moment(option.dateStop) : undefined,
      qty: event.qty,
      specialInstructions: event?.usageTh,
      productDescriptionTh:
        event?.productDescriptionTh || event?.product?.descriptionTh,
      productDescriptionEn:
        event?.productDescriptionEn || event?.product?.descriptionEn,
      frequencyTh: option?.frequencyTh || event?.product?.usageTh || "",
      frequencyEn: option?.frequencyEn || event?.product?.usageEn || "",
      name: event?.product?.category?.nameTh,
      categoryID: event?.category?.id || event?.product?.category?.id,
      groupNameTh: event?.product?.group?.nameTh,
      product: event?.product || event,
      key: event?.key,
      parentId: event?.parentId,
    });
  };

  const onDelete = () => {
    handleDeleteDraftOrder(product.idProduct);
  };

  const onDeleteDraft = () => {
    onDeleteDraftOrderList(product.productKey);
    onModal();
  };
  const onCancel = () => {
    setVisibleInput(!visibleInput);
  };

  const onUpdateDraftOrder = (event: any) => {
    onUpdateDraftOrderList(event);
    setVisibleInput(false);
  };

  const handleDeleteDraftOrder = (id: string) => {
    deleteDraftOrder.mutate(
      {
        id: patientID,
        idProduct: {
          eventId: eventId,
          itemId: [id],
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
          client.invalidateQueries(["get-draft-order-list"]);
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
        },
      }
    );

    onModal();
  };

  const handleUpDateOrderItem = (value: any) => {
    const data = {
      eventId: eventId,
      qty: Number(value.qty),
      productDescriptionTh: value.productDescriptionTh,
      productDescriptionEn: value.productDescriptionEn,
      type: value?.type,
      gratis: value?.gratis,
      option: JSON.stringify(value),
    };

    updateOrderItem.mutate(
      { data: data, patientId: patientID, itemId: product.edit.id },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
        onSettled: () => {
          client.invalidateQueries(["get-draft-order-list"]);
          onCancel();
        },
      }
    );
  };

  const checkRow = (e: IItem) => {
    if (e?.level === 3 || e?.level === 5) return true;
    return false;
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: IOrder, data: IGroup & IItem, index: number) => {
        return (
          <div>
            {data?.level === 1 ? index + 1 : data?.level === 2 ? "" : ""}
          </div>
        );
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (name: String, data: IGroup & IItem) => {
        return (
          <div>
            {data?.level === 1
              ? productName(
                  i18n.language,
                  data?.nameTh ||
                    data?.children?.[0]?.children?.[0]?.product?.category
                      ?.nameTh,
                  data?.nameEn ||
                    data?.children?.[0]?.children?.[0]?.product?.category
                      ?.nameEn
                )
              : data?.level === 2 || data?.level === 4
              ? t("nameList")
              : data?.level === 3
              ? productName(
                  i18n.language,
                  data?.nameTh || data?.product?.nameTh,
                  data?.nameEn || data?.product?.nameEn
                )
              : data?.level === 5 &&
                productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (product: IItem, data: IGroup & IItem) => {
        return (
          <div key={data?.id}>
            {data?.level === 1
              ? ""
              : data?.level === 2 || data?.level === 4
              ? t("type")
              : (data?.level === 3 || data?.level === 5) &&
                productName(
                  i18n.language,
                  data?.product?.group?.nameTh,
                  data?.product?.group?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "product",
      key: "product",
      render: (_: IItem, data: IGroup & IItem) => {
        return (
          <React.Fragment>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length || "-"
              : data?.level === 2 || data?.level === 4
              ? t("amount")
              : data?.qty + "   "}
          </React.Fragment>
        );
      },
    },

    {
      title: t("price"),
      dataIndex: "total",
      key: "total",
      render: (total: string, data: IGroup & IItem) => {
        return (
          <React.Fragment>
            {data?.level === 1
              ? new BigNumber(total || 0).toFormat(2)
              : data?.level === 2
              ? t("price")
              : data?.level === 3 &&
                new BigNumber(data?.normal || 0).toFormat(2)}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      align: "center",
      render: (_: IItem, data: IGroup & IItem) => {
        return (
          <React.Fragment>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? "Type"
              : data?.level === 3
              ? data?.type === ORDER_TYPE.ONE_DAY
                ? "One Day"
                : data?.type === ORDER_TYPE.CONTINUE
                ? "Continuous"
                : data?.type === ORDER_TYPE.OUT_TYPE
                ? "ไม่มี Type"
                : "ไม่มี Type"
              : ""}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      align: "center",
      render: (productDescription: string, dataList: IGroup & IItem) => {
        return (
          <React.Fragment>
            {dataList?.level === 1 ? (
              ""
            ) : dataList?.level === 2 || dataList?.level === 4 ? (
              t("details")
            ) : (
              <Popover
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "th"
                          ? TextBRHTML(
                              productDescription ||
                                dataList?.product?.propertiesTh ||
                                ""
                            )
                          : TextBRHTML(
                              productDescription ||
                                dataList?.product?.propertiesEn ||
                                ""
                            ),
                    }}
                  />
                }
              >
                <div className="text-content">
                  {i18n.language === "th"
                    ? productDescription || dataList?.product?.propertiesTh
                    : productDescription || dataList?.product?.propertiesEn}
                </div>
              </Popover>
            )}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (item: IItem, dataList: IGroup & IItem) => {
        let color;
        if (dataList?.option) {
          color = "#77C48B";
        } else if (!dataList?.option) {
          color = "#498DCB";
        }
        return (
          <React.Fragment>
            {dataList?.level === 1 ? (
              ""
            ) : dataList?.level === 2 && checkDrug(dataList?.id) ? (
              t("drugLabel")
            ) : checkDrug(item?.category?.id) ? (
              <div
                className="table-queue-status  d-flex justify-center"
                style={{ backgroundColor: color }}
              >
                {dataList?.option
                  ? t("haveDrugDetails")
                  : !dataList?.option && t("noDrugDetails")}
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      },
    },

    !historyPatient
      ? {
          title: "",
          dataIndex: "product",
          key: "product",
          align: "center",
          render: (product: IItem, data: IGroup & IItem) => {
            return status !== "not-edit" ? (
              <React.Fragment>
                {data?.level === 1
                  ? ""
                  : data?.level === 2
                  ? t("manage")
                  : data?.level === 3 && (
                      <DeleteOutlined
                        onClick={(e) => {
                          setProduct({
                            ...product,
                            idProduct: data?.id,
                            productKey: data?.key,
                          });
                          onModal();
                          e.stopPropagation();
                        }}
                      />
                    )}
              </React.Fragment>
            ) : null;
          },
        }
      : {},
  ];

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={
          (locationPath.includes("edit-by/patient") && !draftStatus) ||
          locationPath.includes(
            "/physician-assistant/confirm-order/order-list"
          ) ||
          (locationPath.includes("/doctorroom/create-order/appointments") &&
            !draftStatus)
            ? onDelete
            : onDeleteDraft
        }
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>
      {!!visibleInput && (
        <ModalUpdate
          editOrder={editOrder}
          visibleInput={visibleInput}
          onCancel={onCancel}
          handleUpDateOrderItem={
            (locationPath.includes("edit-by/patient") && !draftStatus) ||
            locationPath.includes(
              "/physician-assistant/confirm-order/order-list"
            ) ||
            (locationPath.includes("/doctorroom/create-order/appointments") &&
              !draftStatus)
              ? handleUpDateOrderItem
              : onUpdateDraftOrder
          }
          outType={true}
          isLoading={updateOrderItem?.isLoading}
        />
      )}

      <MoTable
        className="components-table-demo-nested table-selection table-confirm "
        pagination={false}
        locale={{
          emptyText: (
            <>
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">{t("noInformation")}</span>
                }
              >
                {status !== "not-edit" && !historyPatient && medicalRecord ? (
                  !checkRoute() ? (
                    <Button type="primary" onClick={createNow}>
                      {`+ ${t("addOrder")}`}
                    </Button>
                  ) : null
                ) : null}
              </Empty>
            </>
          ),
        }}
        rowClassName={(r) => {
          if (r?.evel === 1) {
            return "no-header";
          } else if (r?.evel === 2) {
            return "table-header no-header ";
          } else if (r?.evel === 3 && r?.children) {
            return "no-header";
          } else if (r?.evel === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "table-confirm-first table-confirm-level-course table-bottom--level-course-num no-header";
            } else {
              return "table-confirm-first table-confirm-level-course no-header ";
            }
          } else if (r?.evel === 4) {
            num.current = r?.children?.length;
            return "table-header no-header table-confirm-level-course table-top-level-course ";
          }
          return "no-header";
        }}
        rowKey={"key"}
        key={"key"}
        columns={columns}
        dataSource={
          (locationPath.includes("edit-by/patient") && !draftStatus) ||
          locationPath.includes(
            "/physician-assistant/confirm-order/order-list"
          ) ||
          (locationPath.includes("/doctorroom/create-order/appointments") &&
            !draftStatus)
            ? draftOrderList?.outType?.groups || []
            : dataSource || []
        }
        onClickRow={(e: IItem) =>
          status !== "not-edit" ? checkRow(e) && onClickRow(e) : null
        }
        onDoubleClick={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default TableOrderList;
