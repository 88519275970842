import { Radio, Row } from "antd";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { Button, Col, Divider, Form, FormInstance, Input, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import FileSpace from "@components/file_space/FileSpace";

interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

type Props = {
  manageInfor: FormInstance;
  visible: IVisible;
  handleUpdateWorkOrderItem: (e: any) => void;
};

const ModalInfo = ({
  manageInfor,
  visible,
  handleUpdateWorkOrderItem,
}: Props) => {
  const { t } = useTranslation();
  const [loadingButton, setLoadingButton] =
    useRecoilState<boolean>(loadingButtonState);

  const onCancel = () => {
    visible.setVisible(false);
  };

  const onFinish = (data: any) => {
    handleUpdateWorkOrderItem(data);
    setLoadingButton(true);
    visible.setVisible(false);
    setLoadingButton(false);
  };

  const getFileName = () => {
    return false;
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col className="t-blue ">
              <b>{`${t("manage")}${t("information")}`}</b>
            </Col>
          </Row>
        </>
      }
      centered
      visible={visible.visible}
      onOk={() => visible.setVisible(false)}
      onCancel={onCancel}
      width="70%"
      footer={null}
    >
      <div className="scroll">
        <Form
          onFinish={onFinish}
          className="p-2"
          name="manageInfor"
          form={manageInfor}
        >
          <Row gutter={[14, 14]} className="margin-form">
            <Form.Item name="course" hidden={true}>
              <Input />
            </Form.Item>

            <Form.Item name="workOrderItemId" hidden={true}>
              <Input />
            </Form.Item>

            <Col lg={24}>
              <Form.Item label={t("category")} name="nameGroup">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item label={t("nameList")} name="productName">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item label={t("type")} name="group">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col lg={24}>
              <Form.Item label={t("details")} name="description">
                <TextArea
                  autoSize={{ minRows: 2, maxRows: 3 }}
                  id="remark"
                  disabled
                />
              </Form.Item>
            </Col>

            <Col lg={24}>
              <Form.Item
                label={t("answer")}
                name="callback"
                rules={[
                  {
                    required: true,
                    message: t("pleaseSelect"),
                  },
                ]}
              >
                <Radio.Group>
                  <Radio value={1}>{t("reportAccepted")}</Radio>
                  <Radio value={2}>{t("reportNotAccepted")}</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={t("file")}
                name="file"
                valuePropName="fileList"
                getValueFromEvent={getFile}
              >
                <FileSpace
                  maxCount={1}
                  accept=".pdf"
                  beforeUpload={getFileName}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => manageInfor.submit()}
                loading={loadingButton}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInfo;
