import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openNotification } from "../notification/Notification";
import { APPOINTMENT_STATUS } from "@tools/util.enum";
import { useUpdateAppointments } from "@services/api/appointments";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useIsMutating, useQueryClient } from "react-query";
import { Col, Form, Modal, Row, Button, Popconfirm } from "antd";
import React from "react";
import Fields from "./Fields";
import Delete from "@assets/img/icon/OPD/delete.svg";
import RoleContext from "@provider/RoleProvider";
import SlotsContext from "@provider/SlotsProvider";

type Props = {
  setVisible: (e: boolean) => void;
  visible: boolean;
  patientID?: number;
  visitID?: number;
  status?: string;
  getAppointmentsList: any;
  appointmentsID?: number;
  appointmentStatus?: string;
};

const FormReserveQueue = ({
  setVisible,
  visible,
  patientID,
  visitID,
  status = " update",
  getAppointmentsList,
  appointmentsID,
  appointmentStatus,
}: Props) => {
  const { t } = useTranslation();
  const [reservation] = Form.useForm();
  const dateNew = new Date();
  const slotList = React.useContext(SlotsContext);
  const roleList = React.useContext(RoleContext);
  const deleteAppointment = useUpdateAppointments();
  const isMutatingPosts = useIsMutating(["create-appointment"]);
  const client = useQueryClient();
  const match: any = useRouteMatch();
  const slotID = match.params.slotID;
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "QUE_MEDICAL_RECORD";
  });

  const onCancel = () => {
    setVisible(false);
  };

  const handleDeleteSlots = async () => {
    deleteAppointment.mutate(
      {
        id: appointmentsID,
        data: {
          status: APPOINTMENT_STATUS.CANCEL,
          isCancel: true,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
          getAppointmentsList();
          client.invalidateQueries([
            "get-appointment",
            { limit: 10, page: 1, slotId: slotID },
          ]);
          setVisible(false);
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
          setVisible(false);
        },
      }
    );
  };

  return (
    <Modal
      title={
        <>
          <Row>
            <Col lg={20}>{`${t("bookAnAppointmentForAPatient")}`}</Col>
          </Row>
        </>
      }
      centered
      visible={visible}
      onOk={() => setVisible(false)}
      onCancel={onCancel}
      destroyOnClose
      width="65%"
      footer={null}
    >
      <Fields
        reservation={reservation}
        setVisible={setVisible}
        patientID={patientID}
        visitID={visitID}
        getAppointmentsList={getAppointmentsList}
        appointmentsID={appointmentsID}
      />

      {medicalRecord?.[0]?.isEdit ? (
        slotList?.endTime &&
        dateNew.getTime() <
          new Date(slotList?.date + " " + slotList?.endTime).getTime() ? (
          <Row gutter={[14, 14]} align="middle" justify="end">
            <Col>
              {status !== "create" && (
                <Popconfirm
                  placement="bottomLeft"
                  icon={
                    <ExclamationCircleOutlined className="icon-warning-del" />
                  }
                  title={
                    <div>
                      {`${t("areYouSure")}`}
                      <div>{`${t("doYouWantToDelete")}`}</div>
                    </div>
                  }
                  onConfirm={handleDeleteSlots}
                  okText={t("save")}
                  cancelText={t("cancel")}
                >
                  <Button className=" delete  " htmlType="submit">
                    <img
                      className="circle editor-button"
                      src={Delete}
                      alt="delete"
                    />
                  </Button>
                </Popconfirm>
              )}
            </Col>

            <Col>
              <Button className="cancel" key="back" onClick={onCancel} block>
                {t("cancel")}
              </Button>
            </Col>

            <Col>
              <Button
                className="btn-create"
                onClick={() => reservation.submit()}
                loading={isMutatingPosts ? true : false}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        ) : (
          <Row gutter={[14, 14]} align="middle" justify="end">
            {appointmentStatus === "BOOK_IN_ADVANCE" && (
              <Col>
                <Popconfirm
                  placement="bottomLeft"
                  icon={
                    <ExclamationCircleOutlined className="icon-warning-del" />
                  }
                  title={
                    <div>
                      {`${t("areYouSure")}`}
                      <div>{`${t("doYouWantToDelete")}`}</div>
                    </div>
                  }
                  onConfirm={handleDeleteSlots}
                  okText={t("save")}
                  cancelText={t("cancel")}
                >
                  <Button className=" delete  " htmlType="submit">
                    <img
                      className="circle editor-button"
                      src={Delete}
                      alt="delete"
                    />
                  </Button>
                </Popconfirm>
              </Col>
            )}
            <Col>
              <Button
                className="btn-create"
                onClick={() => reservation.submit()}
              >
                {t("save")}
              </Button>
            </Col>
            <Col>
              <Button className="cancel" key="back" onClick={onCancel} block>
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        )
      ) : null}
    </Modal>
  );
};

export default FormReserveQueue;
