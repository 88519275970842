import React, { useEffect } from "react";
import { useState } from "react";
import { IGetDraftOrder } from "@interface/IDraftOrder.interface";
import { useTranslation } from "react-i18next";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useMainLabInterfaceGroup } from "@provider/lab_inteface_group";
import { Button, Card, Col, Form, Row } from "antd";
import { ENUM_ROOM_SIGNATURE, ORDER_TYPE } from "@tools/util.enum";
import ModalTransfer from "./ModalTransfer";
import TableOrderList from "@components/draft_order/order_lab_interface/OrderList";

type Props = {
  patientID: number;
  draftOrderList?: IGetDraftOrder;
  title: string;
  editRecord: boolean;
  type: ORDER_TYPE;
  eventId: number;
  room: ENUM_ROOM_SIGNATURE;
};

const TransferComponent = ({
  patientID,
  draftOrderList,
  title,
  editRecord,
  type,
  eventId,
  room,
}: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [modalTransfer] = Form.useForm();
  const [open, setOpen] = useState(false);
  const { labInfoList } = useMainLabInterfaceGroup();

  useEffect(() => {
    if (!draftOrderList) return;
    setOpen(
      !!draftOrderList?.labInterface?.[
        type === ORDER_TYPE.LAB_INTERFACE_OTHER_ONE ? "other1" : "other2"
      ]?.length
    );
    //eslint-disable-next-line
  }, [draftOrderList]);
  useEffect(() => {
    if (!labInfoList) return;
    setOpen(
      !!labInfoList?.[
        type === ORDER_TYPE.LAB_INTERFACE_OTHER_ONE ? "other1" : "other2"
      ]?.length
    );
    //eslint-disable-next-line
  }, [labInfoList]);

  const viewDetail = () => {
    setOpen(!open);
  };

  const createNow = () => {
    setVisible(true);
  };

  const onClick = () => {
    setVisible(!visible);
  };
  return (
    <div>
      <Card
        className="comment-card mt-2 card-out-padding"
        extra={
          <>
            <Row gutter={[14, 14]} align="middle">
              <Col className="f-normal  t-dark-blue ">
                <span className="f-bold cursor" onClick={viewDetail}>
                  {title}
                </span>

                <span className="ml-05">
                  {React.createElement(open ? UpOutlined : DownOutlined, {
                    onClick: viewDetail,
                  })}
                </span>
              </Col>
              {editRecord ? (
                <Col
                  style={{ marginLeft: "auto" }}
                  className="link-btn f-normal"
                >
                  <Button type="link" onClick={onClick}>
                    {`${t("manage")} ${title}`}
                  </Button>
                </Col>
              ) : null}
            </Row>
          </>
        }
      >
        <div className={open ? "open" : "close"}>
          <TableOrderList
            patientID={patientID}
            draftOrderList={draftOrderList}
            createNow={createNow}
            editRecord={editRecord}
            title={title}
            type={type}
            eventId={eventId}
            room={room}
          />
        </div>
      </Card>

      {visible && (
        <ModalTransfer
          patientID={patientID}
          visible={{ visible: visible, setVisible: setVisible }}
          title={title}
          modalTransfer={modalTransfer}
          draftOrderList={
            type === ORDER_TYPE.LAB_INTERFACE_OTHER_ONE
              ? !!labInfoList
                ? labInfoList?.other1
                : draftOrderList?.labInterface?.other1 || []
              : !!labInfoList
              ? labInfoList?.other2
              : draftOrderList?.labInterface?.other2 || []
          }
          type={type}
          eventId={eventId}
          room={room}
        />
      )}
    </div>
  );
};

export default TransferComponent;
