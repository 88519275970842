import { IReceipt } from "@interface/IReceipt.interface";
import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetReceipt = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IReceipt[]>> => {
  return useQuery(["get-receipt", params], async () => {
    const res = await axios.get(`/receipt/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReceiptByPatient = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IReceipt[]>> => {
  return useQuery(["get-receipt", params], async () => {
    const res = await axios.get(`/receipt/patient/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReceiptByID = (
  id: number
): UseQueryResult<ElementaryOneMore<IReceipt>> => {
  return useQuery(["get-receipt-by-ID", id], async () => {
    const res = await axios.get(`/receipt/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateReceipt = (): UseMutationResult<any> => {
  return useMutation(["create-receipt"], async ({ data }: any) => {
    const res = await axios.post(`/receipt`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateReceipt = () => {
  return useMutation(["update-receipt"], async ({ id, data }: any) => {
    const res = await axios.put(`/receipt/${id}`, data);
    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
