import { FC } from "react";
import { useHistory } from "react-router";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { Button, Col, FormInstance, Row } from "antd";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  managePurchaseOrder: FormInstance;
};

const HeaderPurchaseOrder: FC<Props> = ({ managePurchaseOrder }) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const purchaseOrderId = match.params.id;
  const history = useHistory<RouteComponentProps["history"]>();
  const loadingCreate = useIsMutating(["create-purchase-order"]);

  return (
    <>
      <Row className="second-header" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("wareHouse.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("wareHouse.title")} / `}
              </span>
              <span className="t-dark-blue f-normal">
                {!!purchaseOrderId
                  ? t("purchaseOrderManagement")
                  : t("createPurchaseOrder")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button className="mr-05 cancel" onClick={history.goBack}>
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            onClick={() => managePurchaseOrder.submit()}
            loading={!!loadingCreate}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default HeaderPurchaseOrder;
