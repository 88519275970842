import { MedicalIcon } from "@assets/img/icon/Medical";
import { ConsentIcon } from "@assets/img/icon/Consent";
import { PhysicalIcon } from "@assets/img/icon/Physical";
import { TreatmentIcon } from "@assets/img/icon/Treatment";
import { VitalSignIcon } from "@assets/img/icon/VitalSign";
import { RadiologyIcon } from "@assets/img/icon/Radiology";
import { PhysicianIcon } from "@assets/img/icon/Physician";
import { LabResultsIcon } from "@assets/img/icon/LabResults";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { MedicationIcon } from "@assets/img/icon/Medication";
import { MedicalReportIcon } from "@assets/img/icon/MedicalReport";
import { CorrespondenceIcon } from "@assets/img/icon/Correspondence";
import { BiologicalTherapyIcon } from "@assets/img/icon/BiologicalTherapy";
import { keyMenuHistoryNursingState } from "@tools/recoil/recoil-atom";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Icon from "@ant-design/icons";
import Header from "./Header";
import Consent from "./consent/Consent";
import VitalSign from "./vital_sign/VitalSign";
import LabResults from "./lab_results/LabResults";
import Medication from "./medication/Medication";
import MedicalReport from "./medical_report/MedicalReport";
import PhysicianOrder from "./physician_order/PhysicianOrder";
import MedicalSummary from "./medical_summary/MedicalSummary";
import Correspondence from "./correspondence/Correspondence";
import RadiologyReport from "./radiology_report/RadiologyReport";
import TreatmentRecord from "./treatment_record/TreatmentRecord";
import PhysicalRecords from "./physical_records/PhysicalRecords";
import BiologicalTherapy from "./biological_therapy/BiologicalTherapy";

const obj: { [key: number]: { menu: JSX.Element } } = {
  1: { menu: <VitalSign /> },
  2: { menu: <PhysicianOrder /> },
  3: { menu: <LabResults /> },
  4: { menu: <RadiologyReport /> },
  5: { menu: <MedicalReport /> },
  6: { menu: <Medication /> },
  7: { menu: <BiologicalTherapy /> },
  8: { menu: <MedicalSummary /> },
  9: { menu: <TreatmentRecord /> },
  10: { menu: <PhysicalRecords /> },
  11: { menu: <Consent /> },
  12: { menu: <Correspondence /> },
};

const NursingHistory = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [keyMenu, setKeyMenu] = useRecoilState<number>(
    keyMenuHistoryNursingState
  );
  const squadMenu = [
    {
      icon: VitalSignIcon,
      name: t("vitalSign"),
      key: 1,
    },
    {
      icon: PhysicianIcon,
      name: t("physicianOrder"),
      key: 2,
    },
    {
      icon: LabResultsIcon,
      name: t("labResults"),
      key: 3,
    },
    {
      icon: RadiologyIcon,
      name: t("radiologyReport"),
      key: 4,
    },
    {
      icon: MedicalReportIcon,
      name: t("medicalReport"),
      key: 5,
    },

    {
      icon: MedicationIcon,
      name: t("medicationAdministration"),
      key: 6,
    },
    {
      icon: BiologicalTherapyIcon,
      name: t("biologicalTherapy"),
      key: 7,
    },
    {
      icon: MedicalIcon,
      name: t("medicalSummaryReports"),
      key: 8,
    },
    {
      icon: TreatmentIcon,
      name: t("treatmentRecord"),
      key: 9,
    },
    {
      icon: PhysicalIcon,
      name: t("physicalTherapyRecords"),
      key: 10,
    },
    {
      icon: ConsentIcon,
      name: t("consent"),
      key: 11,
    },
    {
      icon: CorrespondenceIcon,
      name: t("correspondence"),
      key: 12,
    },
  ];
  return (
    <div>
      <Header history={history} />
      <div className="set-layout ">
        <div className="squad-menu-header">
          {squadMenu?.map((item) => {
            return (
              <div
                key={item?.key}
                className={
                  keyMenu === item?.key
                    ? "squad-menu-active mr-05 mb-1 "
                    : "squad-menu mr-05 mb-1 "
                }
                onClick={() => setKeyMenu(item?.key)}
              >
                <div className="squad-block-menu squad-menu-sub">
                  <div>{item?.name}</div>
                  <div>
                    <Icon
                      component={item?.icon}
                      className={
                        keyMenu === item?.key
                          ? "squad-menu-icon-active"
                          : "squad-menu-icon"
                      }
                      style={{ fontSize: 45 }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {obj?.[keyMenu]?.menu}
      </div>
    </div>
  );
};

export default NursingHistory;
