import Axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";

const axios = Axios.create({
  baseURL: `${window._env_[`REACT_APP_API_URL`]}`,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
  validateStatus: (status) => status >= 400,
});

interface AxiosCustomRequestConfig<D = any> extends AxiosRequestConfig<D> {
  _retry: boolean;
}
interface AxiosCustomError<T = any, D = any> extends AxiosError<T, D> {
  config: AxiosCustomRequestConfig<D>;
}
const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const acToken = localStorage.getItem("accessToken");
  config.validateStatus = (_) => true;
  config.headers = {
    Authorization: `Bearer ${acToken}`,
  };
  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  return response;
};

const onResponseError = async (error: AxiosCustomError) => {
  // const originalConfig = error.config;
  if (error.response) {
    // Access Token was expired
    // if (error.response.status === 401 && !originalConfig._retry) {
    //   originalConfig._retry = true;
    //   try {
    //     const rs = await Axios.post<
    //       any,
    //       AxiosResponse<{ accessToken: string }>
    //     >(apiURL + "auth/token/renew", {}, { withCredentials: true });
    //     localStorage.setItem("access_token", rs?.data?.accessToken);
    //     return axios(originalConfig);
    //   } catch (_error) {
    //     if (
    //       Axios.isAxiosError(_error) &&
    //       _error.response &&
    //       _error.response?.data
    //     ) {
    //       return Promise.resolve(_error.response?.data);
    //     }
    //     return Promise.resolve(_error);
    //   }
    // }
    if (error?.response?.status === 403 && error?.response?.data) {
      return Promise.resolve(error?.response?.data);
    }
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

axios.interceptors.request.use(onRequest, onRequestError);

axios.interceptors.response.use(onResponse, onResponseError);
export default axios;
