import { useTranslation } from "react-i18next";
import { useGetUserNurse } from "@services/api/users";
import { Radio, Row, TimePicker } from "antd";
import { IManageModal, RefObject } from "@interface/IGlobal.interface";
import { Button, Col, Divider, Form, FormInstance, Input, Modal } from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import Signature from "@components/drawing_canvas/Signature";
import { useState } from "react";

export const UseItem = (
  success: boolean,
  use: boolean,
  status: "NEW" | "USED" | "REJECTED"
) => {
  if (
    success ||
    use ||
    (!use && status === "USED") ||
    (!use && status === "REJECTED")
  ) {
    return false;
  } else return true;
};

type Props = {
  manageInfor: FormInstance;
  stageRef: RefObject | undefined;
  signature: { signature: string; setSignature: (e: string) => void };
  visible: IManageModal;
  handleUpdateWorkOrderItem: (e: any) => void;
};

const ModalInfo = ({
  manageInfor,
  stageRef,
  signature,
  visible,
  handleUpdateWorkOrderItem,
}: Props) => {
  const { t, i18n } = useTranslation();

  const nurseList = useGetUserNurse();
  const [isDrawn, setIsDrawn] = useState(signature?.signature ? true : false);
  const onCancel = () => {
    visible.setVisible(false);
  };

  const update = (data: any) => {
    return new Promise((res: any) => {
      handleUpdateWorkOrderItem(data);
      res();
    });
  };

  const onFinish = async (data: any) => {
    await update({ ...data, time: moment(data?.time).format("HH:mm") }).then(
      () => {
        visible.setVisible(false);
      }
    );
  };

  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col className="t-blue ">
              <b>{t("manageData")}</b>
            </Col>
          </Row>
        </>
      }
      centered
      visible={visible.visible}
      onCancel={onCancel}
      width="70%"
      footer={null}
    >
      <div className="scroll">
        <Form
          onFinish={onFinish}
          className="p-2"
          name="manageInfor"
          form={manageInfor}
        >
          <Row gutter={[14, 14]} className="margin-form">
            <Form.Item name="groupId" hidden={true}>
              <Input />
            </Form.Item>
            <Form.Item name="categoryId" hidden={true}>
              <Input disabled />
            </Form.Item>

            <Col md={24} lg={24}>
              <Form.Item label={t("category")} name="nameGroup">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={12} lg={12}>
              <Form.Item label={t("nameList")} name="productName">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={12} lg={12}>
              <Form.Item label={t("type")} name="group">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={12} lg={12}>
              <Form.Item label={t("Frequency")} name="frequency">
                <Input />
              </Form.Item>
            </Col>

            <Col md={24} lg={24}>
              <Form.Item label={t("details")} name="description">
                <TextArea autoSize={{ minRows: 2, maxRows: 3 }} id="remark" />
              </Form.Item>
            </Col>

            <Col md={12} lg={12}>
              <Form.Item
                label={t("amount")}
                name="qty"
                rules={[
                  {
                    required: true,
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      const qty = getFieldValue("qtyOutstanding");
                      if (qty >= Number(value) && Number(value) > 0) {
                        return Promise.resolve();
                      } else if (!!value) {
                        return Promise.reject(
                          new Error(
                            `${t("theNumberOfProductsIsNotLessThan")} 0 ${t(
                              "andNotMore"
                            )} ${qty} `
                          )
                        );
                      } else {
                        return Promise.reject();
                      }
                    },
                  }),
                ]}
              >
                <Input type="number" />
              </Form.Item>
            </Col>

            <Col md={12} lg={12}>
              <Form.Item label="TIME " name="time">
                <TimePicker format={"HH:mm"} />
              </Form.Item>
            </Col>

            <Col md={24} lg={24}>
              <Form.Item label={t("remarks")} name="remark">
                <TextArea autoSize={{ minRows: 2, maxRows: 3 }} id="remark" />
              </Form.Item>
            </Col>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.userId !== next.userId || prev.useData !== next.useData
              }
            >
              {(form: FormInstance) => {
                const success = form.getFieldValue("success");
                const use = form.getFieldValue("useData");
                const status = form.getFieldValue("status");
                return (
                  <Col md={24} lg={24}>
                    <Form.Item
                      hidden={!UseItem(success, use, status)}
                      label={t("use")}
                      name="use"
                      rules={[
                        {
                          required: true,
                          message: t("pleaseSelect"),
                        },
                      ]}
                    >
                      <Radio.Group>
                        <Radio value={true}>{t("confirm")}</Radio>
                        <Radio value={false}>{t("unconfirmed")}</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                );
              }}
            </Form.Item>

            <Col md={24} lg={24}>
              <Form.Item label={t("nurseSign")} name="nurseId">
                <Selector
                  placeholder=""
                  Source={nurseList?.data?.result || []}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                  id="nurse"
                />
              </Form.Item>
            </Col>

            <Col md={24} lg={24}>
              <div className="t-grey-form mt-1 mb-1">{t("signature")}</div>
              <div className="signature">
                <Row gutter={[14, 14]}>
                  <Col span={24} className="col-center">
                    <Signature
                      stageRef={stageRef}
                      signature={signature}
                      drawnState={{ isDrawn, setIsDrawn }}
                    />
                  </Col>
                  {((!isDrawn && !signature?.signature) ||
                    (!isDrawn && signature?.signature)) && (
                    <Col span={24} className="col-center">
                      <p style={{ color: "red" }}>{t("pleaseSign")}</p>
                    </Col>
                  )}
                </Row>
              </div>
            </Col>
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => manageInfor.submit()}
                disabled={!isDrawn}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInfo;
