import { useTranslation } from "react-i18next";
import { useRef, useEffect, useState } from "react";
import { ISignature, IVisit } from "@interface/IGlobal.interface";
import { Button, Col, Divider, Modal, Row, Form, Input } from "antd";
import moment from "moment";
import Signature from "@components/drawing_canvas/Signature";

type Props = {
  visible: IVisit | any;
  setVisible: (e: IVisit) => void;
  setSignature: (e: ISignature) => void;
  signature: ISignature | any;
  name: string;
  patientName?: string;
  title: string;
};

const ModalInfo = ({
  visible,
  setVisible,
  setSignature,
  signature,
  name,
  patientName,
  title,
}: Props) => {
  const { t } = useTranslation();
  const stageRef = useRef<any>(null);
  const [signatureInfo] = Form.useForm();
  const [isDrawn, setIsDrawn] = useState(false);
  useEffect(() => {
    signatureInfo.setFieldsValue({
      name: signature[`${name}Name`] || patientName,
    });
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible({ ...visible, [`${name}`]: !visible[`${name}`] });
  };

  const onFinish = (data: any) => {
    if (isDrawn) {
      let imageData = stageRef?.current?.canvas.drawing.toDataURL("image/png");
      setSignature({
        ...signature,
        [`${name}Signature`]: imageData,
        [`${name}Name`]: data?.name || "",
        [`${name}Date`]: moment().format("YYYY-MM-DD") || "",
        signature: 1,
      });

      setVisible({ ...visible, [`${name}`]: !visible[`${name}`] });
    }
  };

  return (
    <Modal
      className="document-modal"
      title={
        <>
          <Row>
            <Col lg={20} className="t-black-dark f-bold ">
              <b>{`${title}`}</b>
            </Col>
            <Col lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={visible[`${name}`]}
      onOk={() => setVisible({ ...visible, [`${name}`]: !visible[`${name}`] })}
      onCancel={onCancel}
      width="50%"
      footer={null}
    >
      <div className="scroll ">
        <Form
          onFinish={onFinish}
          className="p-2"
          name="signatureInfo"
          form={signatureInfo}
        >
          <Row gutter={[14, 14]}>
            <Col lg={24}>
              <Form.Item label="ลงชื่อ" name="name">
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} className="col-center">
              <Signature
                stageRef={stageRef}
                drawnState={{ isDrawn, setIsDrawn }}
                // signature={{ signature: signature, setSignature: setSignature }}
              />
            </Col>
            {!isDrawn && (
              <Col span={24} className="col-center">
                <p style={{ color: "red" }}>{t("pleaseSign")}</p>
              </Col>
            )}
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => signatureInfo.submit()}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInfo;
