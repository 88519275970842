import { useState } from "react";
import TableInterfaceLab from "./Table";
import SearchInterfaceLab from "./Search";

const SendInterfaceLab = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const visitFilter = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };

  return (
    <>
      <SearchInterfaceLab onFinish={visitFilter} />
      <TableInterfaceLab
        searchValue={searchValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
};

export default SendInterfaceLab;
