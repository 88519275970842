import React, { FC, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { Button, Card, Col, Row, Space } from "antd";
import { DownOutlined, PrinterOutlined, UpOutlined } from "@ant-design/icons";
import ModalGroup from "@components/lab_interface/ModalGroup";
import LabInterfaceGroupTwo from "@components/lab_interface/lab_interface_group_two/LabInterfaceGroupTwo";
import { useReactToPrint } from "react-to-print";

type Props = {
  title: string;
  room: ENUM_ROOM_SIGNATURE;
};

const LabInterfaceGroup: FC<Props> = ({ title, room }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState({ open: false, modal: false });
  const printReportRef = useRef(null);
  const pageStyle = `  @page { 
    size:A4;
}`;
  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });
  const viewDetail = () => {
    setVisible({ ...visible, open: !visible.open });
  };

  const onClick = (e: boolean) => {
    setVisible({ ...visible, modal: e });
  };

  const viewDetailOpen = (open: boolean) => {
    setVisible({ ...visible, open: open });
  };

  return (
    <div>
      <Card
        className="comment-card mt-2 card-out-padding"
        extra={
          <>
            <Row gutter={[14, 14]} align="middle">
              <Col className="f-normal t-dark-blue ">
                <span className="f-bold mr-05 cursor " onClick={viewDetail}>
                  {title}
                </span>
                <span className="ml-05">
                  {React.createElement(
                    visible.open ? UpOutlined : DownOutlined,
                    {
                      onClick: viewDetail,
                    }
                  )}
                </span>
              </Col>
              <Col style={{ marginLeft: "auto" }} className="link-btn f-normal">
                <Button
                  onClick={() => handlePrint()}
                  // type="ghost"
                  style={{ paddingLeft: "5", paddingRight: "10" }}
                >
                  <Space>
                    <PrinterOutlined />
                    {/* {t("print")} */}
                  </Space>
                </Button>
                <Button type="link" onClick={() => onClick(true)}>
                  {`${t("manage")} ${title}`}
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <div className={visible.open ? "open" : "close"}>
          <div className="p-1">
            <PrintReport ref={printReportRef} >
              <LabInterfaceGroupTwo viewDetailOpen={viewDetailOpen} />
            </PrintReport>
          </div>
        </div>
      </Card>

      <ModalGroup
        onClick={onClick}
        visible={visible.modal}
        title={title}
        group="groupTwo"
        room={room}
      />
    </div>
  );
};

export default LabInterfaceGroup;

class PrintReport extends React.Component {
  render() {
    return this.props.children;
  }
}