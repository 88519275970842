import { t } from "i18next";
import { dayjs } from "@tools/timezone";
import { useState } from "react";
import { IDateChange } from "@interface/ISlots.interface";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { useGetSlotRoomList } from "@services/api/slots";
import { Button, Calendar, Form } from "antd";
import { Card, Col, Divider, Input, Row } from "antd";
import moment from "moment";
import "moment/locale/th";

type Props = {
  display?: string;
  status?: string;
  currentDateChange?: IDateChange;
  other?: boolean;
};

const ListQueue = ({
  display,
  status = "",
  currentDateChange,
  other = false,
}: Props) => {
  return (
    <>
      {status === "noCard" ? (
        <>
          <LayoutDetail
            display={display}
            currentDateChange={currentDateChange}
          />
        </>
      ) : (
        <Card className="bg-grey  calendar height-body scrollbar-list">
          <LayoutDetail
            display={display}
            currentDateChange={currentDateChange}
            other={other}
          />
        </Card>
      )}
    </>
  );
};

export default ListQueue;

interface IFormSearch {
  search?: string;
}

const LayoutDetail = ({ display, currentDateChange, other = false }: Props) => {
  const { i18n } = useTranslation();
  const [search, setSearch] = useState<IFormSearch>();
  const slotRoom = useGetSlotRoomList({
    ...search,
    date: currentDateChange?.currentDateChange
      ? currentDateChange?.currentDateChange
      : moment(),
    other: other,
  });

  const setCalendar = (e: any) => {
    currentDateChange?.setCurrentDateChange(moment(e).format("YYYY-MM-DD"));
  };

  const onFinish = (value: IFormSearch) => {
    setSearch({ ...value });
  };

  return (
    <>
      <div className="site-calendar-demo-card">
        <Calendar
          fullscreen={false}
          value={moment(currentDateChange?.currentDateChange)}
          onChange={setCalendar}
        />
      </div>
      <div className="mt-1 ">
        <div className="list-queue-header mr-ml-5">{t("queueList")}</div>
        <Divider className="mt-05 mb-05" />
        <div className="mr-ml-5">
          <Form name="form" onFinish={onFinish}>
            <Form.Item name="search">
              <Input
                className="mt-05 "
                placeholder={`${t("roomSearch")} , ${t("doctor")} , ${t(
                  "nursery"
                )}`}
                allowClear
              />
            </Form.Item>

            <Form.Item hidden>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>

        {slotRoom?.data?.result?.map((item, index) => {
          return (
            <div key={index} className="page-list-queue mt-05">
              <div
                className={
                  display === "Month"
                    ? "list-queue-scroll"
                    : "list-queue-scroll-month"
                }
              >
                <Row
                  className="list-queue-room mt-05"
                  style={{ backgroundColor: "#004c97" }}
                >
                  {item?.room?.name}
                </Row>

                {item?.room?.detail?.map((detail, i) => {
                  return (
                    <div key={i}>
                      <Row justify="space-between" className="mt-05 mb-05">
                        <Col className="topic doctor-text">
                          {i18n?.language === "th"
                            ? [
                                detail?.user?.firstNameTh,
                                detail?.user?.lastNameTh,
                              ]
                                ?.join(" ")
                                ?.trim()
                            : [
                                detail?.user?.firstNameEn,
                                detail?.user?.lastNameEn,
                              ]
                                ?.join(" ")
                                ?.trim()}
                        </Col>
                        <Col className="date doctor-time">
                          {`( ${dayjs(`2022-1-1 ${detail?.startTime}`).format(
                            "HH:mm"
                          )} น. - ${dayjs(`2022-1-1 ${detail?.endTime}`).format(
                            "HH:mm"
                          )}  น. ) `}
                        </Col>
                      </Row>

                      {!!detail?.patient?.length ? (
                        detail?.patient?.map(
                          (
                            patientList: IGetPatientList,
                            patientKey: number
                          ) => {
                            return (
                              <Row
                                key={patientKey}
                                className="list-patient mt-05"
                              >
                                {i18n?.language === "th"
                                  ? [
                                      `${patientList?.hn || ""} |`,
                                      patientList?.firstNameTh || "",
                                      patientList?.lastNameTh || "",
                                    ]
                                      ?.join(" ")
                                      ?.trim()
                                  : [
                                      `${patientList?.hn} |`,
                                      patientList?.firstNameEn,
                                      patientList?.lastNameEn,
                                    ]
                                      ?.join(" ")
                                      ?.trim()}
                              </Row>
                            );
                          }
                        )
                      ) : !Array.isArray(detail?.patient) ? (
                        <Row className="list-patient mt-05">
                          {i18n?.language === "th"
                            ? [
                                `${detail?.patient?.hn || ""} |`,
                                detail?.patient?.firstNameTh || "",
                                detail?.patient?.lastNameTh || "",
                              ]
                                ?.join(" ")
                                ?.trim()
                            : [
                                `${detail?.patient?.hn} |`,
                                detail?.patient?.firstNameEn,
                                detail?.patient?.lastNameEn,
                              ]
                                ?.join(" ")
                                ?.trim()}
                        </Row>
                      ) : (
                        <Row className="list-patient mt-05 ">-</Row>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};
