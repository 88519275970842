import { useState } from "react";
import { reportDrug } from "@assets/data/pathRouter";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { Button, Col, Row } from "antd";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { KeyNameSignatureFirst } from "@interface/IGlobal.interface";
import { useCreateSignatureRoom } from "@services/api/signature_room";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useIsMutating, useQueryClient } from "react-query";
import { IInChartOrderList, ISignature } from "@interface/IGlobal.interface";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import Modaler from "@components/modaler/Modaler";
import BackHome from "@components/button/BackHome";
import ModalInfo from "@components/signature/ModalInfo";

type Props = {
  history: RouteComponentProps["history"];
  handleCreateWorkOrderUse: () => void;
  medicalRecord: boolean;
  isSignature?: boolean;
  orderList?: IInChartOrderList;
};

const Header = ({
  history,
  handleCreateWorkOrderUse,
  medicalRecord,
  isSignature,
  orderList,
}: Props) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const visitId = match.params.visitID;
  const patientID = match.params.patientID;
  const create = useIsMutating(["create-work-orders-use"]);
  const [visible, setVisible] = useState({ first: false, option: false });
  const createSignatureRoom = useCreateSignatureRoom();
  const client = useQueryClient();

  const confirmWorkOrderStatus = () => {
    handleCreateWorkOrderUse();
  };

  const reportDrugHistory = () => {
    history.push(`${reportDrug}/patient/${patientID}`);
  };

  const updateSignature = (
    values: {
      date: string;
      title: KeyNameSignatureFirst;
      doctorName?: string;
    } & ISignature
  ) => {
    const file = dataUrlToFile(
      values?.firstSignature,
      `dispensarySignature${new Date().getTime()}.png`
    );
    if (file) {
      let formData = new FormData();
      formData?.append("signature", file);
      formData?.append("room", ENUM_ROOM_SIGNATURE.DISPENSARY);
      !!values?.doctorName &&
        formData?.append("doctorName", values?.doctorName);

      createSignatureRoom.mutate(
        { data: formData, visitId: visitId },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            client.invalidateQueries(["get-visitsByID", visitId]);
            setVisible({
              ...visible,
              [values?.title]: !visible[values?.title],
            });
          },
        }
      );
    } else {
      setVisible({
        ...visible,
        [values?.title]: !visible[values?.title],
      });
    }
  };

  const checkOption = () => {
    const oneDay = orderList?.oneDay
      ?.flatMap((parent) => {
        return parent?.children?.flatMap((child) => {
          return child?.children?.flatMap((child1) => {
            if (!!child1?.courseItems?.length) {
              return child1?.courseItems;
            } else {
              return child1;
            }
          });
        });
      })
      ?.filter((item) => {
        return !item?.option;
      });

    const continuous = orderList?.continuous
      ?.flatMap((parent) => {
        return parent?.children?.flatMap((child) => {
          return child?.children?.flatMap((child1) => {
            if (!!child1?.courseItems?.length) {
              return child1?.courseItems;
            } else {
              return child1;
            }
          });
        });
      })
      ?.filter((item) => {
        return !item?.option;
      });

    return [...(oneDay || []), ...(continuous || [])];
  };

  const onModal = () => {
    setVisible({ ...visible, option: !visible?.option });
  };

  return (
    <>
      <Modaler
        visible={visible?.option}
        onCancel={onModal}
        width="45%"
        footer={[
          <Button key="1" className="cancel " onClick={onModal}>
            {t("cancel")}
          </Button>,
        ]}
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-cancel" />
            {`${t("youMustManageTheDrugLabelFirst")}!`}
          </div>
        }
      >
        {t("thereAreSomeDrugLabelsThatHaveNotYetBeenFilledIn")}
      </Modaler>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("dispenseMedicine.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-bold">
                {t("dispenseMedicine.title")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          {medicalRecord ? (
            <Button className="cancel  " onClick={history.goBack}>
              {t("cancel")}
            </Button>
          ) : null}

          <Button className="reserve ml-05 " onClick={reportDrugHistory}>
            {t("report")}
          </Button>
          {medicalRecord ? (
            <Button
              className="btn-create ml-05"
              onClick={() => {
                !!isSignature
                  ? setVisible({ ...visible, first: true })
                  : !!checkOption()?.length
                  ? onModal()
                  : confirmWorkOrderStatus();
              }}
              loading={!!create}
            >
              {t("confirm")}
            </Button>
          ) : null}
        </Col>
      </Row>
      {visible.first && (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="first"
          title={t("medicalAssistantSignature")}
          updateSignature={updateSignature}
          isLoading={createSignatureRoom?.isLoading}
        />
      )}
    </>
  );
};

export default Header;
