import React, { FC, useEffect, useRef, useState } from "react";
import { dayjs } from "@tools/timezone";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useUpdateVisits } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { IGetVisits, IPTNote } from "@interface/IVisits.interface";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Form, Input, Modal, Row } from "antd";
import Signature from "@components/drawing_canvas/Signature";

interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

type Props = {
  visible: IVisible;
  title: string;
  visitsByID?: IGetVisits;
};

export const ModalPTNote: FC<Props> = ({ title, visible, visitsByID }) => {
  const { t } = useTranslation();
  const updateVisits = useUpdateVisits();
  const client = useQueryClient();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const [ptNote] = Form.useForm<any>();
  const stageRef = useRef<any>(null);
  const [signature, setSignature] = useState("");
  const [isDrawn, setIsDrawn] = useState(signature ? true : false);
  useEffect(() => {
    if (!visitsByID?.pTNote) return;
    ptNote.setFieldsValue(visitsByID?.pTNote);
    setSignature(visitsByID?.pTNote?.image);

    // eslint-disable-next-line
  }, [visitsByID?.pTNote]);

  const onCancel = () => {
    visible.setVisible(false);
  };

  const onFinish = (value: IPTNote) => {
    let imageData = stageRef?.current?.canvas.drawing.toDataURL(
      "image/png",
      1.0
    );

    const values = {
      ...value,
      date: dayjs().format("DD/MM/YYYY"),
      image: imageData || visitsByID?.pTNote?.image,
    };

    const formData = new FormData();
    formData?.append("pTNote", JSON.stringify(values));

    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
        onSettled: () => {
          client.invalidateQueries(["get-visitsByID", visitID]);
          visible?.setVisible(false);
        },
      }
    );
  };
  return (
    <Modal
      className="manage-modal"
      title={
        <React.Fragment>
          <Row>
            <Col md={20} lg={20} className="t-blue ">
              <b>{title}</b>
            </Col>
            <Col md={4} lg={4} className="col-end"></Col>
          </Row>
        </React.Fragment>
      }
      centered
      visible={visible?.visible}
      onCancel={() => visible.setVisible(false)}
      width="70%"
      footer={null}
    >
      <div className="scroll">
        <Form
          onFinish={onFinish}
          name="ptNote"
          form={ptNote}
          className="p-2"
          initialValues={{ ptNoteList: [""] }}
        >
          <Form.List name="ptNoteList">
            {(fields, { add, remove }) => {
              return (
                <React.Fragment>
                  {fields?.map(({ key, name, ...restField }) => {
                    return (
                      <React.Fragment key={key}>
                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Form.Item
                            hidden
                            {...restField}
                            name={[name, "date"]}
                            style={{ width: "50vw" }}
                            initialValue={dayjs().format("DD-MM-YYYY")}
                          >
                            <Input />
                          </Form.Item>
                          <Col>CC : </Col>
                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "cc"]}
                              style={{ width: "50vw" }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[10, 10]} align="middle" className="mb-1">
                          <Col>Pain Score : Pre</Col>
                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "pre"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col>/10</Col>
                          <Col style={{ marginLeft: "auto" }}>Post</Col>
                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "post"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col>/10</Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "PMS"]}
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col> PMS : mode</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "PMS_mode"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col> intensity</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "PMS_intensity"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col>Area</Col>
                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "PMS_area"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "shock"]}
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col> Shock Wave :</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "shock_wave"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col> bar</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "shock_bar"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col>Hz Area</Col>
                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "shock_area"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "HPLT"]}
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col> HPLT :</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "HPLT_watt"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col> watt Area</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "HPLT_area"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "hypervolt"]}
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col> Hypervolt :</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "hypervolt_text"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col> (area)</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "hypervolt_area"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "stretching"]}
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col> Stretching :</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "stretching_text"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col> (area)</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "stretching_area"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "manual"]}
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col> Manual Release :</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "manual_release"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col> (area)</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "manual_area"]}
                              noStyle
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "home"]}
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col> Home Program :</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "home_program"]}
                              style={{ width: "50vw" }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "mobilization"]}
                              noStyle
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col> Mobilization :</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "mobilization_text"]}
                              style={{ width: "50vw" }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>

                        <Row gutter={[14, 14]} align="middle" className="mb-1">
                          <Col className="area-checkbox-pt-note">
                            <Form.Item
                              {...restField}
                              name={[name, "other"]}
                              valuePropName="checked"
                            >
                              <Checkbox />
                            </Form.Item>
                          </Col>
                          <Col>Other :</Col>

                          <Col>
                            <Form.Item
                              {...restField}
                              name={[name, "other_text"]}
                              style={{ width: "50vw" }}
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                        <Button
                          type="primary"
                          danger
                          style={{ marginBottom: 15 }}
                          onClick={() => remove(name)}
                          block
                          icon={<MinusCircleOutlined />}
                        >
                          Delete
                        </Button>
                      </React.Fragment>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add field
                    </Button>
                  </Form.Item>
                </React.Fragment>
              );
            }}
          </Form.List>

          <div className="t-grey-form mt-1 mb-1">{t("signature")}</div>
          <div className="signature">
            <Row gutter={[14, 14]}>
              <Col span={24} className="col-center">
                <Signature
                  stageRef={stageRef}
                  drawnState={{ isDrawn, setIsDrawn }}
                  signature={{
                    signature: signature,
                    setSignature: setSignature,
                  }}
                />
              </Col>
              {((!isDrawn && !signature) || (!isDrawn && signature)) && (
                <Col span={24} className="col-center">
                  <p style={{ color: "red" }}>{t("pleaseSign")}</p>
                </Col>
              )}
            </Row>
          </div>
        </Form>
      </div>
      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => ptNote.submit()}
                loading={updateVisits?.isLoading}
                disabled={!isDrawn}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};
