import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { MEDICAL_DOCUMENT } from "@assets/data/pathRouter";
import { openNotification } from "@components/notification/Notification";
import { useSetRecoilState } from "recoil";
import { useCreateDocument } from "@services/api/document";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { useGetDocumentType } from "@services/api/documents_type";
import { Card, Col, Form, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Header from "./Header";
import Selector from "@components/selector/Selector";
import FileSpace from "@components/file_space/FileSpace";

const FormMedicalDocument = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [confirmMedicalDocument] = Form.useForm();
  const createDocument = useCreateDocument();
  const setLoadingButton = useSetRecoilState<boolean>(loadingButtonState);
  const documentType = useGetDocumentType({ limit: 0 });

  useEffect(() => {
    setLoadingButton(createDocument.isLoading);
    //eslint-disable-next-line
  }, [createDocument.isLoading]);

  const getFileName = () => {
    return false;
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = (value: any) => {
    const formData = new FormData();
    formData?.append("typeId", value?.typeId);
    value?.file?.forEach((item: any) => {
      formData?.append("file", item?.originFileObj);
    });

    createDocument.mutate(formData, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.replace(`${MEDICAL_DOCUMENT.title}`);
      },
      onError: (res) => {
        openNotification({ method: "POST_ERROR", message: String(res) });
      },
    });
  };
  return (
    <div>
      <Header confirmMedicalDocument={confirmMedicalDocument} />
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={
            <b className="fs-16 t-black-light ">
              {t("medicalDocument.create")}
            </b>
          }
        >
          <Card
            className="card-prefix card-prefix-width card-prefix-chi"
            extra={
              <b className="fs-14 c-white ">{t("medicalDocument.create")}</b>
            }
          >
            <Form
              onFinish={onFinish}
              name="confirmMedicalDocument"
              form={confirmMedicalDocument}
            >
              <Row gutter={[0, 12]}>
                <Col lg={24}>
                  <Form.Item
                    label={t("type")}
                    rules={[{ required: true }]}
                    name="typeId"
                  >
                    <Selector
                      placeholder=""
                      Source={documentType?.data?.result?.[0]?.data || []}
                      keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
                      keyValue="id"
                      onChange={() => {
                        document.getElementById("firstNameTh")?.focus();
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col lg={24}>
                  <Form.Item
                    label={t("attachFile")}
                    rules={[{ required: true, message: t("selectItem") }]}
                    name="file"
                    valuePropName="fileList"
                    getValueFromEvent={getFile}
                  >
                    <FileSpace
                      accept=".pdf"
                      beforeUpload={getFileName}
                      form={confirmMedicalDocument}
                      keyName="file"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default FormMedicalDocument;
