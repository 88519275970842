import React, { useEffect, useState } from "react";
import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { Popover } from "antd";
import { IBranch } from "@interface/IBranch.interface";
import { ISearch } from "./interface";
import { TRANSFER } from "@assets/data/pathRouter";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { useGetPatientsAllList } from "@services/api/patient";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";
import SearchTransfer from "./Search";

const AllCaregivers = () => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [search, setSearch] = useState<ISearch>({} as ISearch);
  const history = useHistory<RouteComponentProps["history"]>();
  const patientsAllList = useGetPatientsAllList({
    orderby: ENUM_ORDERBY.DESC,
    limit: limitPage,
    page: currentPage,
    ...search,
  });

  useEffect(() => {
    setTotal(patientsAllList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [patientsAllList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${TRANSFER.detail}/${event.id}`);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: string, data: IGetPatientList, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "hn",
      key: "hn",
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "firstNameTH",
      key: "firstNameTH",
      render: (_: string, data: IGetPatientList) => {
        return (
          <div className="f-normal " key={data?.id}>
            {i18n.language === "th"
              ? `${data?.firstNameTh}   ${data?.lastNameTh}`
              : `${data?.firstNameEn}   ${data?.lastNameEn}`}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "gender",
      key: "gender",
      render: (data: string) => {
        let nameGender = gender(data, i18n?.language);
        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      render: (dateOfBirth: string) => {
        return (
          <React.Fragment>
            {dateOfBirth ? Age(dateOfBirth) : "-"}
          </React.Fragment>
        );
      },
    },

    {
      title: t("originBranch"),
      dataIndex: "primaryBranch",
      key: "primaryBranch",
      render: (primaryBranch: IBranch) => {
        return (
          <React.Fragment>
            <Popover
              content={
                i18n.language === "th"
                  ? primaryBranch?.nameTh
                  : primaryBranch?.nameEn
              }
            >
              <div className="text-content">
                {i18n.language === "th"
                  ? primaryBranch?.nameTh
                  : primaryBranch?.nameEn}
              </div>
            </Popover>
          </React.Fragment>
        );
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "mobile",
      key: "mobile",
      render: (mobile: string) => {
        return <React.Fragment>{mobile ? mobile : "-"}</React.Fragment>;
      },
    },
  ];

  return (
    <React.Fragment>
      <SearchTransfer setSearch={setSearch} setCurrentPage={setCurrentPage} />
      <MoTable
        dataSource={patientsAllList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </React.Fragment>
  );
};

export default AllCaregivers;
