import { useForm } from "antd/lib/form/Form";
import { productName } from "@tools/util";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { IItem, IProduct } from "@interface/IOrder.interface";
import { useRef, useState } from "react";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { useMainMedicineRoom } from "@provider/medicine_room";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDeleteWorkOrdersEditProduct } from "@services/api/work_order";
import { useUpdateWorkOrdersEditProduct } from "@services/api/work_order";
import { Button, Form, Input, Popover, Table } from "antd";
import Decimal from "@tools/decimal";
import Modaler from "@components/modaler/Modaler";
import ModalInfo from "./ModalInfo";
import BigNumber from "bignumber.js";
import EditModal from "@components/modaler/EditModal";
import moment from "moment";

const OrderList = () => {
  const { t, i18n } = useTranslation();
  const [deleteForm] = useForm();
  const num = useRef(0);
  const {
    setOptionToId,
    select,
    totalList,
    printDrug,
    medicalRecord,
    orderList,
    key,
    subKey,
  } = useMainMedicineRoom();
  const [visible, setVisible] = useState(false);
  const [updateAndDelete, setUpdateAndDelete] = useState({
    update: false,
    delete: false,
  });
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const [manageInfo] = Form.useForm();
  const [editProduct, setEditProduct] = useState({
    uuid: "",
    addByCashier: false,
  });
  const client = useQueryClient();
  const updateWorkOrders = useUpdateWorkOrdersEditProduct();
  const delWorkOrders = useDeleteWorkOrdersEditProduct();
  const [editOrderQty] = Form.useForm();

  const onClickRow = (event: IItem) => {
    const option = event.option ? JSON.parse(event.option || `{}`) : {};
    setOptionToId({ id: event.id, option: option });
    manageInfo.resetFields();
    let qtyOutstanding = event?.qtyOutstanding + event?.qty;
    let qtyOutstandingCourse =
      event?.qtyOutstandingCourse || 0 + event?.qty || 0;
    manageInfo.setFieldsValue({
      ...event,
      nameShown: event?.product?.nameTh,
      genericName: event?.product?.nameEn,
      descriptionTh:
        option?.productDescriptionTh || event?.product?.propertiesTh,
      descriptionEn:
        option?.productDescriptionEn || event?.product?.propertiesEn,
      ...option,
      categoryId: event?.categoryId || event?.product?.categoryId,
      quantityUsed: option?.quantityUsed || 1,
      drugExpiration: option?.drugExpiration
        ? moment(option.drugExpiration)
        : undefined,
      dateStart: option?.dateStart ? moment(option?.dateStart) : undefined,
      dateStop: option?.dateStop ? moment(option?.dateStop) : undefined,
      editBy:
        i18n.language === "th"
          ? [event?.editBy?.firstNameTh, event?.editBy?.lastNameTh]
              ?.join(" ")
              ?.trim()
          : [event?.editBy?.firstNameEn, event?.editBy?.lastNameEn]
              ?.join(" ")
              ?.trim(),
      qtyOutstanding: !!event?.allQty
        ? event?.allQty
        : qtyOutstanding || qtyOutstandingCourse,
      unitAltTh: event?.product?.unitAltTh,
      unitAltEn: event?.product?.unitAltEn,
      frequency: !!option?.frequency
        ? option?.frequency
        : i18n?.language === "th"
        ? event?.product?.usageTh || ""
        : event?.product?.usageEn || "",
    });
    setVisible(!visible);
  };

  const updateOrderItem = (data: any) => {
    updateWorkOrders.mutate(
      {
        data: {
          uuids: [{ ...editProduct, qty: Number(data?.qty) }],
          room: ENUM_ROOM_SIGNATURE.DISPENSARY,
        },
        id: visitID,
      },
      {
        onSuccess: () => {
          client.invalidateQueries(["get-work-orders-medicine"]);
          client.invalidateQueries(["get-visitsByID", visitID]);
          onEdit();
        },
        onError: () => {},
      }
    );
  };

  const onDelete = (value?: { remark: string }) => {
    delWorkOrders.mutate(
      {
        data: {
          uuids: [editProduct?.uuid],
          room: ENUM_ROOM_SIGNATURE.DISPENSARY,
          ...value,
        },
        id: visitID,
      },
      {
        onSuccess: () => {
          client.invalidateQueries(["get-work-orders-medicine"]);
          client.invalidateQueries(["get-visitsByID", visitID]);
          onModal();
        },
        onError: () => {
          onModal();
        },
      }
    );
  };

  const onSelectChange = (selected: any, data: ISelectedExecuteOrder[]) => {
    const selectedNotChild = selected?.filter((item: string) => {
      return item?.search("children");
    });

    select.setSelectedRowKeys({
      ...select.selectedRowKeys,
      [key]: selectedNotChild,
    });
    let total = 0;
    if (data?.length > 0) {
      data?.forEach((item: any) => {
        total = Number(new BigNumber(total).plus(Number(item?.total)));
      });

      totalList?.setTotalList({
        ...totalList?.totalList,
        [key]: total,
        total: new BigNumber(total).plus(
          Number(totalList?.totalList?.[subKey] || 0)
        ),
        aggregate: new BigNumber(total).plus(
          Number(totalList?.totalList?.[subKey] || 0)
        ),
        grandTotal: new BigNumber(total).plus(
          Number(totalList?.totalList?.[subKey] || 0)
        ),
      });
    } else {
      totalList?.setTotalList({
        ...totalList?.totalList,
        [key]: total,
        total: Number(
          new BigNumber(total).plus(Number(totalList?.totalList?.[subKey]))
        ),
        aggregate: Number(
          new BigNumber(total).plus(Number(totalList?.totalList?.[subKey]))
        ),
        grandTotal: Number(
          new BigNumber(total).plus(Number(totalList?.totalList?.[subKey]))
        ),
      });
    }

    const selectedOrder = orderList?.[key]?.flatMap((parent) => {
      return parent?.children?.flatMap((child) => {
        return child?.children?.flatMap((childLevel1) => {
          if (
            childLevel1?.product?.categoryId === "C-0006" ||
            childLevel1?.product?.categoryId === "C-0007"
          ) {
            return childLevel1?.children?.flatMap((childLevel2) => {
              return childLevel2?.children;
            });
          }

          return childLevel1;
        });
      });
    });

    const resultList = selectedNotChild?.flatMap((item: any) => {
      const result = selectedOrder?.filter((info) => info.key === item);
      return result;
    });

    printDrug.setPrintDrug({ ...printDrug.printDrug, [key]: resultList });
  };

  const rowSelection = {
    selectedRowKeys: select.selectedRowKeys?.[key],
    onChange: onSelectChange,
  };

  const onEdit = () => {
    setUpdateAndDelete({ ...updateAndDelete, update: !updateAndDelete.update });
  };
  const onModal = () => {
    setUpdateAndDelete({ ...updateAndDelete, delete: !updateAndDelete.delete });
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      width: "7%",
      render: (_: string, data: IItem, index: number) => {
        return <div key={data?.key}>{data?.level === 1 && index + 1}</div>;
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? i18n.language === "th"
                ? data?.children?.[0]?.children?.[0]?.product?.category
                    ?.nameTh || ""
                : data?.children?.[0]?.children?.[0]?.product?.category
                    ?.nameEn || ""
              : data?.level === 2 || data?.level === 4
              ? t("nameList")
              : (data?.level === 3 || data?.level === 5) &&
                productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem & IProduct) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("price")
              : data?.level === 3
              ? Decimal(data?.normal) || "-"
              : ""}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (_: string, data: IItem) => {
        return (
          <>
            {data?.level === 1 ? (
              ``
            ) : data?.level === 2 || data?.level === 4 ? (
              t("amount")
            ) : data?.level === 3 && data?.children ? (
              `${data?.qty} ${
                i18n.language === "th"
                  ? data?.product?.unitTh || ""
                  : data?.product?.unitEn || ""
              }`
            ) : data?.level === 3 && !data?.category ? (
              <div>
                {`${data?.qty} ${
                  i18n.language === "th"
                    ? data?.product?.unitTh || ""
                    : data?.product?.unitEn || ""
                }`}
                {medicalRecord && data?.addByCashier ? (
                  <EditOutlined
                    onClick={(e) => {
                      setEditProduct({
                        ...editProduct,
                        uuid: data?.uuid,
                      });

                      editOrderQty.setFieldsValue({
                        qty: data?.allQty || data?.qty,
                        oldQty: data?.allQty || data?.qty,
                      });
                      onEdit();
                      e.stopPropagation();
                    }}
                  />
                ) : null}
              </div>
            ) : data?.level === 5 ? (
              <div>{`${data?.qty} ${
                i18n.language === "th"
                  ? data?.product?.unitTh || ""
                  : data?.product?.unitEn || ""
              }`}</div>
            ) : null}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {
              data?.level === 1
                ? ``
                : data?.level === 2 || data?.level === 4
                ? `${t("amount")} / ${t("totalNumber")}`
                : data?.level === 3 && !data?.children?.length
                ? `${data?.qty || 0} / ${data?.allQty}` //** children item */
                : data?.level === 5
                ? `${data?.qty || 0} / ${data?.allQty}` //** children corse item */
                : data?.qtyOutstanding
                ? `${data?.qty || 0} / ${data?.allQty}`
                : `${data?.qty || 0} / ${data?.allQty}` //** parent corse item  */
            }
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      render: (productDescription: string, data: IItem) => {
        const option = data?.option ? JSON.parse(data?.option || `{}`) : {};
        return (
          <div key={data?.key}>
            {data?.level === 1 ? (
              ``
            ) : (data?.level === 2 && !data?.children?.[0]?.children) ||
              data?.level === 4 ? (
              t("properties")
            ) : (data?.level === 3 && !data?.children) || data?.level === 5 ? (
              <Popover
                content={
                  option?.description !== undefined
                    ? option?.description
                    : i18n.language === "th"
                    ? productDescription || data?.product?.propertiesTh
                    : productDescription || data?.product?.propertiesEn
                }
              >
                <div className="text-content">
                  {option?.description !== undefined
                    ? option?.description
                    : i18n.language === "th"
                    ? productDescription || data?.product?.propertiesTh
                    : productDescription || data?.product?.propertiesEn}
                </div>
              </Popover>
            ) : null}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "name",
      key: "name",
      align: "center",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1 ? (
              data?.children?.[0]?.children?.length
            ) : data?.level === 2 ? (
              data?.status
            ) : medicalRecord ? (
              (data?.level === 3 && !data?.children) || data?.level === 5 ? (
                <Button
                  className={!!data?.option ? "btn-manage-drug-labels" : ""}
                  type="primary"
                  onClick={() => onClickRow(data)}
                >
                  {t("manageDrugLabels")}
                </Button>
              ) : null
            ) : null}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("total")
              : data?.level === 3
              ? Decimal(data?.net) || "-"
              : null}
          </div>
        );
      },
    },

    medicalRecord
      ? {
          title: "",
          dataIndex: "product",
          key: "product",
          align: "center",

          render: (_: string, data: IItem, index: number) => {
            return (
              <div key={index}>
                {data?.level === 1 ? (
                  ""
                ) : data?.level === 2 || data?.level === 4 ? (
                  t("delete")
                ) : data?.level === 3 ? (
                  <DeleteOutlined
                    onClick={(e) => {
                      deleteForm.resetFields();
                      setEditProduct({
                        ...editProduct,
                        addByCashier: data?.addByCashier,
                        uuid: data?.uuid,
                      });
                      onModal();
                      e.stopPropagation();
                    }}
                  />
                ) : null}
              </div>
            );
          },
        }
      : {},
  ];

  return (
    <div>
      <EditModal
        loadingButton={updateWorkOrders?.isLoading}
        visible={updateAndDelete.update}
        onCancel={onEdit}
        editOrder={editOrderQty}
        width="25%"
        header={<div>{t("modifyTheNumberOfProducts")}</div>}
      >
        <Form
          onFinish={updateOrderItem}
          name="editOrderQty"
          form={editOrderQty}
        >
          <Form.Item
            label={t("numberOfProducts")}
            name="qty"
            rules={[
              {
                required: true,
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("qty") > 0) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error(`${t("theNumberOfProductsIsNotLessThan")} 0!`)
                  );
                },
              }),
            ]}
          >
            <Input type="number" className="modify-product" />
          </Form.Item>
        </Form>
      </EditModal>

      <Modaler
        loadingButton={delWorkOrders?.isLoading}
        visible={updateAndDelete.delete}
        onCancel={onModal}
        onOk={() => {
          editProduct?.addByCashier === false
            ? deleteForm.submit()
            : onDelete();
        }}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        <div>
          {t("doYouWantToDelete")}
          {editProduct?.addByCashier === false && (
            <Form name="deleteForm" form={deleteForm} onFinish={onDelete}>
              <Form.Item
                name="remark"
                label={t("reasonForDeletion")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Form>
          )}
        </div>
      </Modaler>
      <Table
        className="components-table-demo-nested table-selection table-confirm "
        columns={columns}
        dataSource={orderList?.[key]}
        rowKey={"key"}
        rowSelection={
          medicalRecord ? { ...rowSelection, checkStrictly: false } : undefined
        }
        expandRowByClick={true}
        showSorterTooltip={true}
        pagination={false}
        rowClassName={(r) => {
          if (r?.level === 1) {
            return " no-header no-cursor";
          } else if (r?.level === 2) {
            return "table-header no-header no-cursor";
          } else if (r?.level === 3 && !r?.children) {
            return "";
          } else if (r?.level === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "table-confirm-first table-confirm-level-course table-bottom--level-course-num no-cursor";
            } else {
              return "table-confirm-first table-confirm-level-course no-cursor";
            }
          } else if (r?.level === 4) {
            num.current = r?.children?.length;
            return "table-header no-header table-confirm-level-course no-cursor table-top-level-course ";
          }
          return " no-header";
        }}
        scroll={{ x: "calc(100vw -20%)" }}
      />

      {visible && (
        <ModalInfo
          manageInfo={manageInfo}
          visible={{
            visible: visible,
            setVisible: setVisible,
          }}
        />
      )}
    </div>
  );
};

export default OrderList;
