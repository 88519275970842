export const CorrespondenceIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="clip-Correspondence">
          <path d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-Correspondence)">
        <g data-name="vuesax/bold/sms-tracking" transform="translate(5.44 4)">
          <g fill="#fff" transform="translate(0 .001)">
            <path
              d="M19.349 0A19.349 19.349 0 1038.7 19.349 19.331 19.331 0 0019.349 0zm-7.895 11.454h7.894a2.631 2.631 0 010 5.263h-7.894a2.631 2.631 0 010-5.263zm15.789 15.789H11.454a2.631 2.631 0 010-5.263h15.828a2.634 2.634 0 012.631 2.631 2.668 2.668 0 01-2.67 2.632z"
              data-name="Vector"
              transform="translate(3.87 50.307)"
            ></path>
            <path
              d="M58.046 0h-38.7C7.739 0 0 5.8 0 19.349V31.5a3.873 3.873 0 005.5 3.483 25.189 25.189 0 0114.163-1.741 25.3 25.3 0 0120.466 19.735 25.57 25.57 0 01.271 8.552 3.844 3.844 0 003.831 4.3h13.815c11.609 0 19.349-5.8 19.349-19.349V19.349C77.395 5.8 69.655 0 58.046 0zm1.819 21.632l-12.113 9.674a14.939 14.939 0 01-18.11 0L17.53 21.632a2.977 2.977 0 01-.464-4.1 2.894 2.894 0 014.063-.464l12.112 9.672a9.234 9.234 0 0010.874 0l12.112-9.674a2.856 2.856 0 014.063.464 2.935 2.935 0 01-.425 4.102z"
              data-name="Vector"
              transform="translate(7.739 11.609)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
