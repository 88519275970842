import { FC, useState } from "react";
import { FormInstance } from "antd/es/form/Form";
import { useRouteMatch } from "react-router-dom";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { MANAGE_COURSES } from "@assets/data/pathRouter";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDeleteProductCourseItem } from "@services/api/product_course_item";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Delete from "@assets/img/icon/OPD/delete.svg";
import BackHome from "@components/button/BackHome";
import Modaler from "@components/modaler/Modaler";

type Props = {
  formCourse: FormInstance;
};

const Header: FC<Props> = ({ formCourse }) => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const manageCourseId = match.params.id;
  const update = useIsMutating(["update-product-course-item"]);
  const create = useIsMutating(["create-product-course-item"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const deleteCourse = useDeleteProductCourseItem();

  const onModal = () => {
    setIsModalOpen(true);
  };

  const onDelete = () => {
    deleteCourse.mutate(manageCourseId, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        history.replace(`${MANAGE_COURSES?.title}`);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
      onSettled: () => {
        setIsModalOpen(false);
      },
    });
  };

  return (
    <>
      {!!isModalOpen && (
        <Modaler
          loadingButton={deleteCourse?.isLoading}
          visible={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          onOk={onDelete}
          width="45%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              {`${t("areYouSure")}`}
            </div>
          }
        >
          {`${t("doYouWantToDelete")}`}
        </Modaler>
      )}

      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("manageCourses.title")}</h1>
              </div>
              <span className="t-light-gray">{`${t("courseList")} / `}</span>
              <span className="t-dark-blue f-normal">
                {!manageCourseId && manageCourseId !== 0
                  ? t("create")
                  : t("update")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex justify-between">
          {!manageCourseId && manageCourseId !== 0 ? null : (
            <Button
              className=" delete mr-05 "
              onClick={(e) => {
                onModal();
                e.stopPropagation();
              }}
            >
              <img className="circle editor-button" src={Delete} alt="delete" />
            </Button>
          )}

          <Button
            className="mr-05 cancel"
            htmlType="reset"
            block
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            onClick={() => {
              formCourse.submit();
            }}
            loading={!!create || !!update}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
