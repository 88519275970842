import React, { useEffect } from "react";
import { ISelected } from "@interface/IOrder.interface";
import { ORDER_TYPE } from "@tools/util.enum";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { Card, Col, Row, Checkbox } from "antd";
import { useMainProductOutStanding } from "@provider/product_out_standing";
import { IItem, IOrderListAndRecord } from "@interface/IOrder.interface";
import { checkCourse, productName, reformatDataOrderList } from "@tools/util";
import dayjs from "dayjs";
import bignumber from "bignumber.js";

type Props = {
  setOrderList: (e: ISelectedExecuteOrder[]) => void;
  status: string;
  selection: ISelected;
  noCard?: string;
};

const List = ({ setOrderList, status, selection, noCard }: Props) => {
  const { outStanding } = useMainProductOutStanding();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const locationPath = location.pathname;

  useEffect(() => {
    if (!outStanding?.length) {
      return;
    } else if (status === "executeOrder") {
      if (sessionStorage.getItem("checkedValues") !== null) {
        const checkedValuesSession = sessionStorage.getItem("checkedValues");
        const checkedValuesParse = JSON.parse(
          checkedValuesSession ? checkedValuesSession : ""
        );

        selection?.setSelection({
          ...selection?.selection,
          list: [...(checkedValuesParse || [])],
          oldValueList: checkedValuesParse,
        });
        onChange({ checkedValues: checkedValuesParse });
      } else {
        onChange({});
      }
    } else if (status === "inchartBetween") {
      checkboxList();
    } else {
      onChange({});
    }
    // eslint-disable-next-line
  }, [outStanding]);

  const checkboxList = (outStandingId?: string[], status?: string) => {
    try {
      const childrenItem = outStanding?.flatMap((itemList) => {
        return itemList?.items;
      });
      const dataCheckbox = childrenItem?.filter((item: IItem) => {
        if (
          item?.product?.categoryId === "C-0006" ||
          item?.product?.categoryId === "C-0007"
        ) {
          return (
            item?.use === true &&
            item?.courseItems
              ?.flatMap((itemCourse) => {
                return itemCourse;
              })
              ?.findIndex((list) => {
                return list?.use === true;
              }) > -1
          );
        }
        return item?.use === true;
      });

      const result = dataCheckbox?.flatMap((item: IItem) => {
        return item?.id;
      });

      let dataCheckboxCourse: IItem[] = [];

      if (status && status === "all") {
        const dataCourseNon = checkCourse(childrenItem || []);
        const itemCourse = childItemCourse(dataCourseNon || []);
        dataCheckboxCourse = itemCourse?.filter((item: IItem) => {
          if (item?.uuid) {
            return item?.uuid;
          } else {
            return false;
          }
        });
      } else {
        const dataCourse = checkCourse(childrenItem || [], "use");
        const itemCourse = childItemCourse(dataCourse || []);

        dataCheckboxCourse = itemCourse?.filter((item: IItem) => {
          if (item?.use === true) {
            return item?.uuid;
          } else {
            return false;
          }
        });
      }

      const itemSelect = dataCheckboxCourse?.flatMap(
        (item: IItem) => item?.uuid
      );

      onChange({
        checkedValues: result,
        start: "start",
        itemSelect: itemSelect,
        result: outStandingId ? outStandingId : result,
      });
    } catch (err) {
      console.log(err);
    }
  };

  const childItemCourse = (dataCourse: IItem[]) => {
    const itemCourse = dataCourse?.flatMap(
      (item: IItem) => item?.children?.[0]?.children
    );
    return itemCourse;
  };

  const onChangeAll = (event: boolean) => {
    console.log("event", event);
    if (event) {
      const outStandingId = outStanding
        ?.map((item: IOrderListAndRecord) => {
          return item?.items?.map((list) => {
            return list?.id;
          });
        })
        ?.flat(Infinity);
      sessionStorage?.setItem("checkedValues", JSON?.stringify(outStandingId));
      if (status === "inchartBetween") {
        checkboxList(outStandingId, "all");
      } else {
        selection?.setSelection({
          ...selection.selection,
          list: outStandingId || [],
          oldValueList: outStandingId || [],
        });

        reformatData(outStandingId || []);
      }
    } else {
      if (status === "inchartBetween") {
        checkboxList();
      } else {
        const optionDrug =
          outStanding
            ?.filter(
              (item) => item?.items?.[0]?.product?.category?.id === "C-0002"
            )
            ?.flatMap((paren) => {
              return paren?.items?.filter(
                (child) => child?.type === ORDER_TYPE.OPTION_DRUG
              );
            })
            ?.map((item) => {
              return item?.id;
            }) || [];
        selection?.setSelection({
          ...selection?.selection,
          list: [],
          oldValueList: [],
        });
        reformatData(optionDrug);
        sessionStorage.setItem("checkedValues", JSON.stringify(optionDrug));
      }
    }
  };

  const onChange = (value: {
    checkedValues?: string[] | any;
    start?: string;
    itemSelect?: string[];
    result?: string[];
  }) => {
    if (status === "inchartBetween") {
      if (value?.start || value?.itemSelect || value?.result) {
        reformatData(value?.result ? value?.result : [], value?.itemSelect);
      } else {
        let result: string[] = [];
        let resultDelete: string[] = [];
        const outStandingItem = outStanding?.flatMap((item) => item?.items);

        const checkIncrease = value?.checkedValues?.filter((item: string) => {
          return selection?.selection?.oldValueList?.indexOf(item) === -1;
        });

        const checkDelete = selection?.selection?.oldValueList?.filter(
          (item: string) => {
            return value?.checkedValues?.indexOf(item) === -1;
          }
        );

        const dataOutStandingIncrease = outStandingItem?.filter((item) => {
          return checkIncrease.indexOf(item?.id) !== -1;
        });

        const dataOutStandingDelete = outStandingItem?.filter((item) => {
          return checkDelete.indexOf(item?.id) !== -1;
        });

        const deleteKeyOutStanding = checkCourse(dataOutStandingDelete || []);

        deleteKeyOutStanding?.forEach((item) => {
          item?.children?.[0]?.children?.forEach((item) => {
            resultDelete.push(item?.uuid);
          });
        });

        const dataDelete = selection?.selection?.oldValue?.filter((item) => {
          return resultDelete?.indexOf(item) === -1;
        });

        const dataOutStanding = checkCourse(dataOutStandingIncrease || []);

        if (dataOutStanding) {
          dataOutStanding?.forEach((item) => {
            const children = item?.children?.[0]?.children?.filter(
              (children) => children?.use === false
            );
            if (children?.length === item?.children?.[0]?.children?.length) {
              item?.children?.[0]?.children?.forEach((item) => {
                result.push(item?.uuid);
              });
            }
          });

          if (result?.length > 0) {
            reformatData(value?.checkedValues, [
              ...result,
              ...selection?.selection?.oldValue,
            ]);
          } else {
            reformatData(value?.checkedValues, dataDelete);
          }
        }
      }
    } else if (status === "executeOrder") {
      value?.checkedValues &&
        sessionStorage.setItem(
          "checkedValues",
          JSON.stringify(value?.checkedValues)
        );

      reformatData(value?.checkedValues);
    } else if (
      status === "transfercourse" ||
      status === "signcourse" ||
      status === "returncourse"
    ) {
      reformatData(value?.checkedValues, []);
    } else {
      reformatData(value?.checkedValues);
    }
  };

  const reformatData = (checkedValues: string[], course?: string[]) => {
    const optionDrug =
      outStanding
        ?.flatMap((paren) => {
          return paren?.items?.filter(
            (child) => child?.type === ORDER_TYPE.OPTION_DRUG
          );
        })
        ?.map((item) => {
          return item?.id;
        }) || [];

    const keyData: string[] = Array.from(
      new Set([...(checkedValues || []), ...(optionDrug || [])])
    );

    const optionDrugCourse = outStanding
      ?.flatMap((parent) => {
        return parent?.items;
      })
      ?.filter(
        (item) =>
          (item?.product?.category?.id === "C-0006" ||
            item?.product?.category?.id === "C-0007") &&
          item?.type === ORDER_TYPE.OPTION_DRUG
      )
      ?.flatMap((parent) => {
        return parent?.children?.flatMap((child) => {
          return child?.children;
        });
      })
      ?.map((item) => {
        return item?.id;
      });

    const checkArray = !Array.isArray(outStanding?.[0]) ? outStanding : [];

    const result = reformatDataOrderList(
      checkArray || [],
      [...(checkedValues || []), ...(optionDrug || [])],
      status
    );

    let price = 0;
    result?.dataList?.forEach((item: IItem) => {
      price = price + Number(item?.total);
    });

    if (course) {
      selection?.setSelection({
        ...selection?.selection,
        course: Array.from(
          new Set([...(course || []), ...(optionDrugCourse || [])])
        ),
        oldValue: Array.from(
          new Set([...(course || []), ...(optionDrugCourse || [])])
        ),
        expandAllRows: result?.result?.flat(Infinity),
        list: keyData,
        oldValueList: keyData,
        price: new bignumber(price || 0).toFormat(2),
      });
    } else {
      selection?.setSelection({
        ...selection?.selection,
        course: optionDrugCourse || [],
        expandAllRows: result?.result?.flat(Infinity),
        list: keyData,
        oldValueList: keyData,
        price: new bignumber(price || 0).toFormat(2),
      });
    }
    setOrderList(result?.dataList);
  };

  return (
    <React.Fragment>
      <div className="pr-02 pl-05 ">
        <Checkbox onChange={(e) => onChangeAll(e.target.checked)}>
          ทั้งหมด
        </Checkbox>
      </div>
      <div
        className={
          noCard ? " pr-02  " : "pr-02 height-body-list scrollbar-list"
        }
      >
        <Checkbox.Group
          className="list-re"
          value={selection?.selection?.list}
          onChange={(e) => {
            onChange({ checkedValues: e });
          }}
        >
          {locationPath.includes("sign-course") ||
          locationPath.includes("transfer-course")
            ? outStanding?.map((data: IOrderListAndRecord) =>
                data?.items?.map((item: IItem, index: number) =>
                  item?.transformById ? null : (
                    <Card className="card-list-record mt-05" key={index}>
                      <Row>
                        <Col md={3} lg={3}>
                          {!item?.use ? (
                            <Checkbox key={index} value={item?.id}></Checkbox>
                          ) : (
                            <Checkbox
                              key={index}
                              value={item?.id}
                              disabled
                            ></Checkbox>
                          )}
                        </Col>

                        <Col md={21} lg={21}>
                          <Row>
                            <Col md={12} lg={12} className="text-topic">
                              {i18n.language === "th"
                                ? item?.product?.category?.nameTh ||
                                  item?.product?.group?.nameTh ||
                                  " "
                                : item?.product?.category?.nameEn ||
                                  item?.product?.group?.nameEn ||
                                  " "}
                            </Col>
                            <Col md={12} lg={12} className="number-topic">
                              {`${
                                item?.qtyOutstandingCourse
                                  ? item?.qtyOutstandingCourse
                                  : item?.qtyOutstanding
                                  ? item?.qtyOutstanding
                                  : item?.qty
                              }/${
                                item?.qtyCourse ? item?.qtyCourse : item?.qty
                              }`}
                            </Col>
                          </Row>

                          <div className="details">
                            {i18n.language === "th"
                              ? item?.product?.nameTh || " "
                              : item?.product?.nameEn || " "}
                          </div>

                          <div className="date-details">
                            <Row>
                              <Col span={12}>
                                {data?.order?.date ||
                                  dayjs(item?.updatedAt).format("DD-MM-YYYY") ||
                                  ""}
                              </Col>
                              <Col style={{ color: "red", marginLeft: "auto" }}>
                                {item?.transformById && item?.assign
                                  ? t("signCourse.title")
                                  : item?.transformById
                                  ? t("transferredCourse")
                                  : item?.type === ORDER_TYPE.OPTION_DRUG
                                  ? t("changeDoseMedication")
                                  : ""}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  )
                )
              )
            : outStanding?.map((data: IOrderListAndRecord) =>
                data?.items?.map((item: IItem, index: number) => {
                  let use = item?.use;
                  if (
                    item?.product?.categoryId === "C-0006" ||
                    item?.product?.categoryId === "C-0007"
                  ) {
                    const product = item?.courseItems?.flatMap((item) => {
                      return item;
                    });

                    const result = product?.findIndex((item) => {
                      return item?.use === true;
                    });
                    if (result > -1) {
                      use = true;
                    } else {
                      use = false;
                    }
                  }
                  return (
                    <Card className="card-list-record mt-05" key={index}>
                      <Row>
                        <Col md={3} lg={3}>
                          {!use && item?.type !== ORDER_TYPE.OPTION_DRUG ? (
                            <Checkbox key={index} value={item?.id}></Checkbox>
                          ) : (
                            <Checkbox
                              key={index}
                              value={item?.id}
                              disabled
                            ></Checkbox>
                          )}
                        </Col>

                        <Col md={21} lg={21}>
                          <Row>
                            <Col md={12} lg={12} className="text-topic">
                              {i18n.language === "th"
                                ? item?.product?.category?.nameTh ||
                                  item?.product?.group?.nameTh ||
                                  " "
                                : item?.product?.category?.nameEn ||
                                  item?.product?.group?.nameEn ||
                                  " "}
                            </Col>
                            <Col md={12} lg={12} className="number-topic">
                              {`${
                                item?.qtyOutstandingCourse
                                  ? item?.qtyOutstandingCourse
                                  : item?.qtyOutstanding
                                  ? item?.qtyOutstanding
                                  : item?.qty
                              }/${
                                item?.qtyCourse
                                  ? item?.qtyCourse
                                  : item?.allQty ||
                                    item?.qtyOutstanding ||
                                    item?.oldQty ||
                                    ""
                              }`}
                            </Col>
                          </Row>

                          <div className="details">
                            {productName(
                              i18n.language,
                              item?.product?.nameTh,
                              item?.product?.nameEn
                            )}
                          </div>

                          <div className="date-details">
                            <Row>
                              <Col>
                                {data?.order?.date ||
                                  dayjs(item?.updatedAt).format("DD-MM-YYYY") ||
                                  ""}
                              </Col>
                              <Col style={{ color: "red", marginLeft: "auto" }}>
                                {item?.transformById && item?.assign
                                  ? t("signCourse.title")
                                  : item?.transformById
                                  ? t("transferredCourse")
                                  : item?.type === ORDER_TYPE.OPTION_DRUG
                                  ? t("changeDoseMedication")
                                  : ""}
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Card>
                  );
                })
              )}
        </Checkbox.Group>
      </div>
    </React.Fragment>
  );
};

export default List;
