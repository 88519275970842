import React, { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { NOTIFICATION } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import TableNotification from "./Table";
import RoleContext from "@provider/RoleProvider";
import { ENUM_MENU } from "@tools/util.enum";

const Notification = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === ENUM_MENU.NOTIFICATION;
  });

  const create = (event: any) => {
    history.push(`${NOTIFICATION.create}`);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("booking.notification")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("booking.notification")}</b>
          </span>
        </Col>
        <Col lg={2} className="justify-col">
          {medicalRecord?.[0]?.isEdit && (
            <Button className="btn-create" type="primary" onClick={create}>
              <PlusOutlined className="icons-size" /> {t("createNotification")}
            </Button>
          )}
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card ">{t("allNotification")}</b>}
        >
          {/* <PatientList path={OPD.profile} /> */}
          <TableNotification
            currentPage={{
              currentPage: currentPage,
              setCurrentPage: setCurrentPage,
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default Notification;
