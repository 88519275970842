import React, { useEffect, useState } from "react";
import { IItem } from "@interface/IOrder.interface";
import { useTranslation } from "react-i18next";
import { IGetDraftOrder } from "@interface/IDraftOrder.interface";
import { Button, Card, Col, Row } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useLocation, useRouteMatch } from "react-router-dom";
import TableOrderList from "./OrderList";
import ProductCategory from "@components/product_category/ProductCategory";
import BigNumber from "bignumber.js";

type Props = {
  patientID: number;
  draftOrderList?: IGetDraftOrder;
  status?: string;
  medicalRecord: boolean;
  orderList?: {
    orderList: {
      order: IItem[];
    };
    setOrderList: (e: { order: IItem[] }) => void;
  };
  draftStatus?: boolean;
  eventId?: number;
};

const CreateOrderItem = ({
  patientID,
  draftOrderList,
  status,
  medicalRecord,
  orderList,
  draftStatus = false,
  eventId,
}: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const locationPath = location.pathname;
  const match: any = useRouteMatch();
  const eventID = match.params.eventID;
  const historyPatient = locationPath?.search("patient-history") > -1;

  useEffect(() => {
    setOpen(
      !!draftOrderList?.outType.groups?.length || !!orderList?.orderList?.order
    );
    //eslint-disable-next-line
  }, [draftOrderList?.outType, orderList?.orderList?.order]);

  const createNow = () => {
    setVisible(true);
  };

  const checkRoute = () => {
    return locationPath.includes("/doctorroom");
  };

  const viewDetail = () => {
    setOpen(!open);
  };

  const onManageModal = () => {
    setVisible(!visible);
  };

  const manageFormat = (orderList: IItem[]) => {
    const reformat = orderList?.reduce((result: any[], current: IItem) => {
      if (
        result?.some(
          (item) =>
            item?.categoryId === current?.categoryId ||
            item?.categoryId === current?.name
        )
      ) {
        return result?.map((parent: IItem, indexParent) => {
          if (
            parent?.categoryId === current?.categoryId ||
            parent?.categoryId === current?.name
          ) {
            return {
              ...parent,
              total: Number(
                BigNumber(current?.qty || 0)
                  .multipliedBy(current?.normal || 0)
                  .plus(parent?.total || 0)
              ),
              children: parent?.children?.map((child) => {
                if (current?.name === "C-0006" || current?.name === "C-0007") {
                  return {
                    ...child,
                    children: [
                      ...child.children,
                      {
                        ...current,
                        category: {
                          id: current?.categoryId || current?.name,
                        },
                        categoryId: current?.name,
                        level: 3,
                        qty: 1,
                        children: current?.children1?.map((parent) => {
                          return {
                            ...parent,
                            level: 4,
                            children: parent?.children?.map((child) => {
                              return {
                                ...child,
                                level: 5,
                                parentId: current?.key,
                                category: {
                                  id: current?.categoryId || current?.name,
                                },
                              };
                            }),
                          };
                        }),
                      },
                    ],
                  };
                }

                return {
                  ...child,
                  children: [
                    ...child.children,
                    {
                      ...current,
                      categoryId: current?.categoryId || current?.name,
                      level: 3,
                    },
                  ],
                };
              }),
            };
          }

          return parent;
        });
      }
      if (current?.name === "C-0006" || current?.name === "C-0007") {
        return [
          ...(result || []),
          {
            categoryId: current?.categoryId || current?.name,
            key: current?.nameTh || current?.nameEn,
            level: 1,
            nameEn: current?.nameEn,
            nameTh: current?.nameTh,
            total: Number(
              BigNumber(current?.normal || 0).multipliedBy(current?.qty || 0)
            ),
            children: [
              {
                key: `children-${current?.nameTh || current?.nameEn}`,
                level: 2,
                children: [
                  {
                    ...current,
                    category: {
                      id: current?.categoryId || current?.name,
                    },
                    level: 3,
                    categoryId: current?.name,
                    qty: 1,
                    children: current?.children1?.map((parent) => {
                      return {
                        ...parent,
                        level: 4,
                        children: parent?.children?.map((child) => {
                          return {
                            ...child,
                            level: 5,
                            parentId: current?.key,
                            category: {
                              id: current?.categoryId || current?.name,
                            },
                          };
                        }),
                      };
                    }),
                  },
                ],
              },
            ],
          },
        ];
      } else
        return [
          ...(result || []),
          {
            categoryId: current?.categoryId || current?.name,
            key: current?.nameTh || current?.nameEn,
            level: 1,
            nameEn: current?.category?.nameEn || current?.nameEn,
            nameTh: current?.category?.nameTh || current?.nameTh,
            total: Number(
              BigNumber(current?.normal || 0).multipliedBy(current?.qty || 0)
            ),
            children: [
              {
                key: `children-${current?.nameTh || current?.nameEn}`,
                level: 2,
                children: [
                  {
                    ...current,
                    level: 3,
                  },
                ],
              },
            ],
          },
        ];
    }, []);

    return reformat;
  };

  const onDeleteDraftOrderList = (id: string) => {
    const order = orderList?.orderList?.order?.filter((order) => {
      return order?.key !== id;
    });

    orderList?.setOrderList({
      order: order || [],
    });
  };

  const onUpdateDraftOrderList = (event: any) => {
    delete event?.product;
    const order = orderList?.orderList?.order?.map((item) => {
      if (item?.key === event?.key) {
        return {
          ...item,
          productDescription: event?.productDescription,
          qty: event?.qty,
          option: JSON.stringify(event),
          gratis: event?.gratis,
        };
      } else if (
        event?.categoryID === "C-0006" ||
        event?.categoryID === "C-0007"
      ) {
        if (event?.parentId === item?.key) {
          return {
            ...item,
            children1: item?.children1?.flatMap((parent) => {
              return {
                ...parent,
                children: parent?.children?.map((child) => {
                  if (child?.key === event?.key) {
                    return {
                      ...child,
                      productDescription: event?.productDescription,
                      qty: event?.qty,
                      option: JSON.stringify(event),
                    };
                  }
                  return child;
                }),
              };
            }),
          };
        }
        return item;
      } else return item;
    });
    orderList?.setOrderList({
      order: order || [],
    });
  };

  return (
    <div className="mt-2">
      <Card
        className="comment-card mt-2 card-out-padding "
        extra={
          <Row style={{ width: "100%" }} align="middle">
            <Col onClick={viewDetail}>
              <span className="f-bold mr-05 cursor ">{t("orderList")} </span>

              <span className="ml-05">
                {React.createElement(open ? UpOutlined : DownOutlined)}
              </span>
            </Col>

            {status !== "not-edit" && !historyPatient && medicalRecord ? (
              draftOrderList?.outType.groups?.length ||
              orderList?.orderList?.order?.length ? (
                <Col style={{ marginLeft: "auto" }}>
                  {!checkRoute() && (
                    <Button type="primary" onClick={createNow}>
                      {t("editOrder")}
                    </Button>
                  )}
                </Col>
              ) : (
                " "
              )
            ) : null}
          </Row>
        }
      >
        <div className={open ? "open " : "close"}>
          <TableOrderList
            patientID={patientID}
            draftOrderList={draftOrderList}
            createNow={createNow}
            status={status}
            medicalRecord={medicalRecord}
            dataSource={manageFormat(orderList?.orderList?.order || [])}
            onDeleteDraftOrderList={onDeleteDraftOrderList}
            onUpdateDraftOrderList={onUpdateDraftOrderList}
            draftStatus={draftStatus}
            eventId={eventID || eventId}
          />
        </div>
      </Card>

      {locationPath.includes("edit-by") && !draftStatus
        ? visible && (
            <ProductCategory
              patientID={patientID}
              visible={visible}
              onModal={onManageModal}
              type="OUT_TYPE"
              status="pre_registration"
              orderList={{
                orderList: orderList?.orderList,
                setOrderList: orderList?.setOrderList,
              }}
              eventId={eventID || eventId}
            />
          )
        : visible && (
            <ProductCategory
              patientID={patientID}
              visible={visible}
              onModal={onManageModal}
              type="OUT_TYPE"
              status="out-type"
              orderList={{
                orderList: orderList?.orderList,
                setOrderList: orderList?.setOrderList,
              }}
            />
          )}
    </div>
  );
};

export default CreateOrderItem;
