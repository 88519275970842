import { IImageCanvasGroupContext } from "./inteface";
import { createContext, useContext } from "react";

const MainImageCanvasGroupContext = createContext<IImageCanvasGroupContext>(
  {} as IImageCanvasGroupContext
);

const MainImageCanvasGroupProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: IImageCanvasGroupContext;
}) => {
  return (
    <MainImageCanvasGroupContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </MainImageCanvasGroupContext.Provider>
  );
};

export const useMainImageCanvasGroup = () =>
  useContext(MainImageCanvasGroupContext);
export default MainImageCanvasGroupProvider;
