import { useState } from "react";
import TableCounsel from "./Table";
import SearchCounsel from "./Search";

const Counsel = () => {
  const [searchData, setSearchData] = useState();
  const [currentPage, setCurrentPage] = useState<number>(1);
  return (
    <>
      <SearchCounsel
        setSearchData={setSearchData}
        setCurrentPage={setCurrentPage}
      />
      <TableCounsel
        searchData={searchData}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </>
  );
};

export default Counsel;
