import React, { Suspense } from "react";
import { LOGIN } from "@assets/data/pathRouter";
import { routers } from "./routes/router";
import { RecoilRoot } from "recoil";
import { useTranslation } from "react-i18next";
import { Route, Redirect } from "react-router-dom";
import { ValidateMessages } from "rc-field-form/lib/interface";
import { HashRouter, Switch } from "react-router-dom";
import { ConfigProvider, Spin } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import thTH from "antd/es/locale/th_TH";
import enUS from "antd/es/locale/en_US";
import LoginPage from "./views/login/LoginPage";
import DefaultLayout from "./layout/DefaultLayout";
import "./assets/scss/index.scss";
import "./assets/less/variables.less";
const ProtectedRoute = React.lazy(
  () => import("./component/protected_route/ProtectedRoute")
);

const client = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, retry: 0 },
  },
});

function App() {
  const { i18n } = useTranslation();
  const validateMessages: ValidateMessages = {
    required: i18n?.language === "th" ? "โปรดกรอกข้อมูล!" : "Please input!",
    types: {
      email:
        i18n?.language === "th"
          ? "โปรดกรอกอีเมลให้ถูกต้อง!"
          : "Please enter a valid email address!",
    },
  };

  return (
    <QueryClientProvider client={client}>
      <ConfigProvider
        locale={i18n?.language === "th" ? thTH : enUS}
        form={{ validateMessages }}
      >
        <RecoilRoot>
          {/* 
          // @ts-ignore */}
          <HashRouter>
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => <Redirect from="/" to="/dashboard" />}
              />
              <Route exact path="/404" component={() => <div>404</div>} />
              <Route
                exact
                path={LOGIN.title}
                render={(props) => (
                  <HomeRoute>
                    <LoginPage />
                  </HomeRoute>
                )}
              />

              {routers?.map((route, ind) => (
                <Route
                  key={ind}
                  path="/"
                  render={(props) => (
                    <Suspense
                      fallback={
                        <div className="example-loading">
                          <Spin size="large" tip="Loading..." />
                        </div>
                      }
                    >
                      <ProtectedRoute>
                        <DefaultLayout />
                      </ProtectedRoute>
                    </Suspense>
                  )}
                />
              ))}
            </Switch>
          </HashRouter>
        </RecoilRoot>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

const HomeRoute = (rest: any): any => {
  const accessToken = getToken();
  if (accessToken) return <Redirect to="/dashboard" />;
  return <Route {...rest} exact={true} />;
};

export const getToken = (): string => {
  const token = localStorage.getItem("accessToken");
  return token || "";
};

export default App;
