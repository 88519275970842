import { IItem } from "@interface/IOrder.interface";
import { ORDER_TYPE } from "@tools/util.enum";
import { productName } from "@tools/util";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";
import { useMainProductOutStanding } from "@provider/product_out_standing";

const List = () => {
  const { outStanding } = useMainProductOutStanding();
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="list-record-scroll scrollbar-list pr-02">
        {/* <Button className="list-record">
          <Row className="notification-list">
            <Col lg={12} className="topic">
              นัดหมายล่วงหน้า
            </Col>
            <Col lg={12} className="date">
              24/01/2564
            </Col>
          </Row>
        </Button> */}

        {outStanding?.map((data: any) =>
          data?.items?.map((item: IItem, index: number) => (
            <div key={index}>
              <Card className="card-list-record mt-05">
                <Row>
                  <Col span={12} className="text-topic">
                    {i18n.language === "th"
                      ? item?.product?.category?.nameTh ||
                        item?.product?.group?.nameTh ||
                        " "
                      : item?.product?.category?.nameEn ||
                        item?.product?.group?.nameEn ||
                        " "}
                  </Col>
                  <Col span={12} className="number-topic">
                    {`${
                      item?.qtyOutstandingCourse
                        ? item?.qtyOutstandingCourse
                        : item?.qtyOutstanding
                        ? item?.qtyOutstanding
                        : item?.qty
                    }/${item?.qtyCourse ? item?.qtyCourse : item?.qty}`}
                  </Col>
                </Row>

                <div className="details">
                  {productName(
                    i18n.language,
                    item?.product?.nameTh,
                    item?.product?.nameEn
                  )}
                </div>
                <div className="date-details">
                  <Row>
                    <Col span={12}>{data?.order?.date || " "}</Col>

                    <Col style={{ color: "red", marginLeft: "auto" }}>
                      {item?.transformById && item?.assign
                        ? t("signCourse.title")
                        : item?.transformById
                        ? t("transferredCourse")
                        : item?.type === ORDER_TYPE.OPTION_DRUG
                        ? t("changeDoseMedication")
                        : ""}
                    </Col>
                  </Row>
                </div>
              </Card>
            </div>
          ))
        )}
      </div>
    </>
  );
};

export default List;
