import { timeSlot } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { RangePickerProps } from "antd/lib/date-picker";
import { useMainDashboard } from "@provider/dashboard";
import { IDashboardSearch } from "@interface/IDashBoard.interface";
import { Button, Col, DatePicker, Form, FormInstance, Row } from "antd";
import moment from "moment";
import Selector from "@components/selector/Selector";

const dateFormat = "DD-MM-YYYY";
const monthFormat = "MM-YYYY";
const yearFormat = "YYYY";

const Search = () => {
  const { search, setSearch } = useMainDashboard();
  const { t, i18n } = useTranslation();

  const [form] = Form.useForm();

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current > moment().endOf("day");
  };

  const onFinish = (value: IDashboardSearch) => {
    setSearch({
      ...search,
      ...value,
      date: moment(value?.date).format("YYYY-MM-DD"),
    });
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Row gutter={[14, 14]}>
        <Col>
          <Form.Item name="option">
            <Selector
              Source={timeSlot}
              keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
              keyValue="id"
            />
          </Form.Item>
        </Col>
        <Col>
          <Form.Item shouldUpdate={(prev, next) => prev.option !== next.option}>
            {(form: FormInstance) => {
              const option = form.getFieldValue("option");

              if (option === "DAY") {
                return (
                  <Col>
                    <Form.Item name="date" rules={[{ required: true }]}>
                      <DatePicker
                        dropdownClassName="panel"
                        disabledDate={disabledDate}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Col>
                );
              } else if (option === "MONTH") {
                return (
                  <Col>
                    <Form.Item name="date" rules={[{ required: true }]}>
                      <DatePicker
                        dropdownClassName="panel"
                        disabledDate={disabledDate}
                        format={monthFormat}
                        picker="month"
                      />
                    </Form.Item>
                  </Col>
                );
              } else if (option === "YEAR") {
                return (
                  <Col>
                    <Form.Item name="date" rules={[{ required: true }]}>
                      <DatePicker
                        dropdownClassName="panel"
                        disabledDate={disabledDate}
                        format={yearFormat}
                        picker="year"
                      />
                    </Form.Item>
                  </Col>
                );
              } else {
                return (
                  <Col>
                    <DatePicker
                      dropdownClassName="panel"
                      disabled={true}
                      format={dateFormat}
                    />
                  </Col>
                );
              }
            }}
          </Form.Item>
        </Col>
        <Col className="d-flex justify-between">
          <Button className="mr-1 btn-search" htmlType="submit">
            {t("search")}
          </Button>
          <Button className="clear" htmlType="reset">
            {t("reset")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default Search;
