import { ISlotsRoom } from "../../interface/ISlots.interface";
import { IGetSlotsList } from "@interface/ISlots.interface";
import { thrownResponse } from "./auth";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useCreateSlotBulk = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.post(`/slot/bulk`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateSlot = (): UseMutationResult => {
  return useMutation(["create-slot"], async (data: any) => {
    const res = await axios.post(`/slot`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateSlot = (): UseMutationResult => {
  return useMutation(["update-slot"], async ({ id, data }: any) => {
    const res = await axios.put(`/slot/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetSlotById = (
  id: number,
  enabled?: boolean
): UseQueryResult<ElementaryOneMore<IGetSlotsList>> => {
  return useQuery(
    ["get-slot-room", id],
    async () => {
      const res = await axios.get(`/slot/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }

      thrownResponse(res);
    },
    { enabled: enabled }
  );
};
export const useDeleteSlot = (): UseMutationResult => {
  return useMutation(async (id: any) => {
    const res = await axios.delete(`/slot/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetSlotsGetTimeList = (
  id: number,
  enabled?: boolean
): UseQueryResult<ElementaryOneMore<{ date: string; time: string }>> => {
  return useQuery(
    ["get-slot-get-time", id],
    async () => {
      const res = await axios.get(`/slot/${id}/get-time`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }

      thrownResponse(res);
    },
    { enabled: enabled }
  );
};

export const useGetSlotRoomList = (
  params: any
): UseQueryResult<ElementaryOneMore<ISlotsRoom>> => {
  return useQuery(["get-slot-room", params], async () => {
    const res = await axios.get(`/slot/room`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }

    thrownResponse(res);
  });
};

export const useGetSlotDateList = () => {
  return useMutation(async (params?: any) => {
    const res = await axios.get(`/slot/date`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }

    thrownResponse(res);
  });
};
