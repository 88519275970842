import { thrownResponse } from "./auth";
import { IInsurancesList } from "@interface/IInsurances.interface";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetInsurancesList = (
  params?: any
): UseQueryResult<Elementary<IInsurancesList[]>> => {
  return useQuery(["get-insurances-list", params], async () => {
    const res = await axios.get(`/insurances`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetInsurancesById = (
  id: number
): UseQueryResult<ElementaryOneMore<IInsurancesList>> => {
  return useQuery(["get-insurances-by-id", id], async () => {
    if (!id) {
      return null;
    } else {
      const res = await axios.get(`/insurances/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  });
};

export const useCreateInsurancesList = () => {
  return useMutation(["create-insurances"], async (data: any) => {
    const res = await axios.post(`/insurances/bulk`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    thrownResponse(res);
  });
};

export const useUpdateInsurancesByID = () => {
  return useMutation(["update-insurances-by-id"], async ({ id, data }: any) => {
    const res = await axios.put(`/insurances/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteInsurancesByID = () => {
  return useMutation(["delete-insurances-by-id"], async (id: number) => {
    const res = await axios.delete(`/insurances/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
