import { IUserRoom } from "@interface/IUserRoom.interface";
import { IFormSlots } from "@interface/ISlots.interface";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Divider, Form } from "antd";
import Fields from "./Fields";

type Props = {
  dataSlots: IFormSlots;
  CommitChanges: (added: any) => void;
  doctorList: IUserRoom[];
};

const FormScheduler = ({ dataSlots, CommitChanges, doctorList }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [slotForm] = Form.useForm();

  useEffect(() => {
    setVisible(!visible);
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      className="manage-modal"
      title={t("booking.doctorBooking")}
      centered
      visible={visible}
      onOk={() => setVisible(true)}
      onCancel={onCancel}
      width="65%"
      footer={null}
    >
      <div className="scroll">
        <Fields
          dataSlots={dataSlots}
          CommitChanges={CommitChanges}
          slotForm={slotForm}
          setVisible={setVisible}
          doctorList={Array.from(
            new Map(doctorList?.map((item) => [item?.userId, item])).values()
          )}
        />
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button className="btn-create " onClick={() => slotForm.submit()}>
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default FormScheduler;
