import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useForm } from "antd/es/form/Form";
import { Col, Form, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useUpdateVisits } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { useEffect, useRef } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetVisitReportTreatment } from "@services/api/visits";
import { ITreatmentRecord, IWorkOrderItem } from "@interface/IVisits.interface";
import moment from "moment";
import Header from "./Header";
import ReportProcedure from "@components/report_procedure/ReportProcedure";

const ReportPhysicianOrder = () => {
  const { i18n } = useTranslation();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const [treatmentRecord] = useForm();
  const pageStyle = `  @page { size:A4;
        margin: 40px;}`;
  const printReportRef = useRef(null);
  const visitReportTreatment = useGetVisitReportTreatment(visitID);
  const history = useHistory<RouteComponentProps["history"]>();
  const updateVisits = useUpdateVisits();
  const client = useQueryClient();

  useEffect(() => {
    treatmentRecord.setFieldsValue({
      bloodCollection: visitReportTreatment?.data?.labs?.length
        ? ["yes"]
        : ["no"],
      bw:
        visitReportTreatment?.data?.visit?.data?.standing1Mins?.bw ||
        visitReportTreatment?.data?.visit?.data?.sitting1Min?.bw ||
        visitReportTreatment?.data?.visit?.data?.lyingDown?.bw ||
        visitReportTreatment?.data?.visit?.data?.doctorRoom?.bw ||
        visitReportTreatment?.data?.visit?.data?.procedure?.bw ||
        visitReportTreatment?.data?.visit?.data?.visit?.bw,
      ...visitReportTreatment?.data?.visit?.data?.treatmentRecord,
      timeNurse: !!visitReportTreatment?.data?.visit?.data?.treatmentRecord
        ?.timeNurse
        ? moment(
            visitReportTreatment?.data?.visit?.data?.treatmentRecord?.timeNurse
          )
        : undefined,
    });
    //eslint-disable-next-line
  }, [visitReportTreatment?.data]);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  const onFinish = (values: ITreatmentRecord) => {
    const formData = new FormData();

    formData?.append(
      "data",
      JSON.stringify({
        ...visitReportTreatment?.data?.visit?.data,
        treatmentRecord: values,
      })
    );

    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-visitsByID", visitID]);
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  return (
    <div>
      <Header
        history={history}
        handlePrint={handlePrint}
        treatmentRecord={treatmentRecord}
      />
      <ReactToPrint content={() => printReportRef.current} />
      <div className="set-layout ">
        <Form
          className="label-unset"
          name="treatmentRecord"
          form={treatmentRecord}
          onFinish={onFinish}
        >
          <div className="document-body ">
            <Row>
              <Col lg={24} className="display-center ">
                <div className="document-bill">
                  <Row>
                    <Col lg={24}>
                      <ReportProcedure
                        refValue={printReportRef}
                        reportTreatment={visitReportTreatment?.data}
                        indexMedication={
                          visitReportTreatment?.data?.workOrderItem
                            ? visitReportTreatment?.data?.workOrderItem?.findIndex(
                                (item: IWorkOrderItem) =>
                                  item?.group === "ID_SQ_IM_MEDICATIONS"
                              )
                            : -1
                        }
                        indexIV={
                          visitReportTreatment?.data?.workOrderItem
                            ? visitReportTreatment?.data?.workOrderItem?.findIndex(
                                (item: IWorkOrderItem) =>
                                  item?.group === "IV_MEDICATIONS"
                              )
                            : -1
                        }
                        indexphysical={
                          visitReportTreatment?.data?.workOrderItem
                            ? visitReportTreatment?.data?.workOrderItem?.findIndex(
                                (item: IWorkOrderItem) =>
                                  item?.group === "PHYSICAL_THERAPY"
                              )
                            : -1
                        }
                        i18n={i18n.language}
                      />
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default ReportPhysicianOrder;
