import { useTranslation } from "react-i18next";
import ViewFile from "../../view_file/ViewFile";

const ViewFileRadiologyReport = () => {
  const { t } = useTranslation();
  return (
    <div>
      <ViewFile name={t("medicalReport")} keyName="medical" />
    </div>
  );
};

export default ViewFileRadiologyReport;
