import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { useTranslation } from "react-i18next";
import { confirmOrderList } from "@assets/data/pathRouter";
import { useGetAppointments } from "@services/api/appointments";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { APPOINTMENT_STATUS, ENUM_ORDERBY } from "@tools/util.enum";
import moment from "moment";
import MoTable from "@components/table/Table";

type Props = {
  searchData: any;
};

const Table = ({ searchData }: Props) => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const history = useHistory<RouteComponentProps["history"]>();
  const appointmentList = useGetAppointments();

  useEffect(() => {
    setTotal(appointmentList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [appointmentList?.data]);

  useEffect(() => {
    GetAppointmentsList();

    // eslint-disable-next-line
  }, [currentPage, limitPage, searchData]);

  const GetAppointmentsList = async () => {
    const dateNew = moment(new Date()).format("YYYY-MM-DD");
    appointmentList.mutate({
      orderby: ENUM_ORDERBY.ASC,
      name: searchData?.name,
      status: [APPOINTMENT_STATUS.ADVISED],
      gender: searchData?.gender,
      mobile: searchData?.mobile,
      limit: limitPage,
      page: currentPage,
      slotDate: dateNew,
      visitDate: dateNew,
    });
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(
      confirmOrderList +
        `/appointments/${event.id}/patient/${event.patientId}/visit/${event?.visit?.id}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "keyHN",
      width: "10%",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (item: any) => {
        return <div key={item?.id}>{item?.hn}</div>;
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "patient",
      render: (item: any) => {
        return (
          <div className="f-normal " key={item?.id}>
            {i18n.language === "th"
              ? `${item?.firstNameTh}  ${item?.lastNameTh}`
              : `${item?.firstNameEn}  ${item?.lastNameEn}`}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "patient",
      key: "gender",

      render: (item: any) => {
        let nameGender = gender(item?.gender, i18n?.language);

        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "patient",
      key: "dateOfBirth",
      width: "20%",
      // sorter: (a: any, b: any) => a.age - b.age,
      render: (item: any) => {
        return <div key={item?.id}>{Age(item?.dateOfBirth)}</div>;
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "patient",
      key: "phone",

      sorter: (a: any, b: any) => a.id - b.id,
      render: (item: any) => {
        return <div key={item?.id}>{item?.mobile}</div>;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",

      render: (status: any) => {
        let color;
        if (status === `ADVISED`) {
          color = "#00377C";
        } else if (status === `ADVISING`) {
          color = "#77C48B";
        } else if (status === `WAITING_FOR_TREATMENT`) {
          color = "#7AC692";
        } else if (status === `WAITING_FOR_ADVICE`) {
          color = "#498DCB";
        } else if (status === `CANCEL`) {
          color = "#FF5D5D";
        } else if (status === `BOOK_IN_ADVANCE`) {
          color = "#989898";
        }

        return (
          <div
            className="table-queue-status mr-1"
            style={{ backgroundColor: color }}
          >
            {status === `WAITING_FOR_ADVICE`
              ? t("waitConsult")
              : status === `ADVISING`
              ? t("consulting")
              : status === `WAITING_FOR_TREATMENT`
              ? t("waitingForTreatment")
              : status === `ADVISED`
              ? t("consulted")
              : status === `CANCEL`
              ? t("cancel")
              : status === `BOOK_IN_ADVANCE` && t("bookAdvance")}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <MoTable
        dataSource={appointmentList?.data?.result?.[0]?.data || []}
        loading={appointmentList?.isLoading}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
