import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
  confirmInchart: () => void;
  medicalRecord: boolean;
};

const Header = ({ history, confirmInchart, medicalRecord }: Props) => {
  const { t } = useTranslation();
  const create = useIsMutating("create-work-orders-use");

  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("inchart.title")}</h1>
              </div>
              <span className="t-light-gray">
                {t("nursery")} / {t("inchart.title")} /{" "}
              </span>
              <span className="t-dark-blue f-normal">{t("inProcess")}</span>
            </Col>
          </Row>
        </Col>

        {medicalRecord ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="cancel mr-05 "
              htmlType="submit"
              onClick={history.goBack}
            >
              {t("cancel")}
            </Button>

            <Button
              className="btn-create"
              htmlType="submit"
              onClick={confirmInchart}
              loading={!!create}
            >
              {t("confirm")}
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
