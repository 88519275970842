import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  otherInfoForm: FormInstance;
  history: RouteComponentProps["history"];
};

const Header = ({ otherInfoForm, history }: Props) => {
  const { t } = useTranslation();
  return (
    <Row className="second-header " justify="space-between" align="middle">
      <Col>
        <Row gutter={[14, 14]}>
          <BackHome history={history} />

          <Col className="header-text">
            <div>
              <h1>{t("medicalRecord.title")}</h1>
            </div>
            <span className="t-light-gray">{t("nursery")} / </span>
            <span className="t-dark-blue f-normal">{t("createKeeper")}</span>
          </Col>
        </Row>
      </Col>
      <Col lg={4} className="d-flex justify-between justify-col">
        <Button
          className="mr-1 cancel"
          htmlType="reset"
          block
          onClick={history.goBack}
        >
          {t("cancel")}
        </Button>
        <Button
          className=" btn-search"
          htmlType="submit"
          block
          onClick={() => otherInfoForm.submit()}
        >
          {t("next")}
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
