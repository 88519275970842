import { useTranslation } from "react-i18next";
import { APPOINTMENT_STATUS } from "@tools/util.enum";
import { ISearchDetailSlots } from "@interface/IAppointments.interface";
import { Button, Col, Form, Input, Row } from "antd";
import SelectorDouble from "../selector/SelectorDouble";

type Props = {
  onFinish: (e: ISearchDetailSlots) => void;
};

const SearchPatientQueue = ({ onFinish }: Props) => {
  const { t, i18n } = useTranslation();
  const status = [
    {
      nameTh: "รอปรึกษา",
      nameEn: "Wait consult",
      id: APPOINTMENT_STATUS.WAITING_FOR_ADVICE,
    },
    {
      nameTh: "จองล่วงหน้า",
      nameEn: "Book advance",
      id: APPOINTMENT_STATUS.BOOK_IN_ADVANCE,
    },
  ];
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={8} lg={8}>
            <Form.Item name="name">
              <Input placeholder={`${t("nameSearch")} , ${t("code")} HN`} />
            </Form.Item>
          </Col>

          <Col xl={5} xxl={5}>
            <Form.Item name="status">
              <SelectorDouble
                Source={status}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("status")}
              />
            </Form.Item>
          </Col>

          <Col lg={4} className="d-flex justify-between">
            <Button className="mr-1 btn-search" htmlType="submit" block>
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset" block>
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchPatientQueue;
