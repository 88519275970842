import { useMutation } from "react-query";
import { thrownResponse } from "./auth";
import { ENUM_VIEW_MARKER_TYPE } from "@tools/util.enum";
import axios from "@axios/index";

interface IFileCanvas {
  file: File;
  index: number;
}

export const useCreateMedia = () => {
  return useMutation(["create-media"], async (data: any) => {
    const res = await axios.post(`/media-object/media`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateMediaMulti = () => {
  return useMutation(async (data: any) => {
    const imageId = await Promise.all(
      data?.image?.map(async (item: IFileCanvas) => {
        const formData = new FormData();
        formData?.append("file", item?.file);
        const res = await axios.post(`/media-object/media`, formData);

        if (res?.status >= 200 && res?.status < 300) {
          return {
            sequence: item?.index,
            imageId: res?.data?.result?.[0]?.id,
            tags: data?.SOAP
              ? ENUM_VIEW_MARKER_TYPE.SOAP
              : ENUM_VIEW_MARKER_TYPE.DOCTOR,
          };
        }
        thrownResponse(res);
      })
    );

    return imageId;
  });
};

export const useUpdateDocumentName = () => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.put(`/media-object/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
