import { USER_MANAGE } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useGetRoomList } from "@services/api/rooms";
import { openNotification } from "@components/notification/Notification";
import { useGetBranchList } from "@services/api/branch";
import { useGetUserDoctor } from "@services/api/users";
import { RouteComponentProps } from "react-router-dom";
import { useGetUserRoomRoomID } from "@services/api/user_room";
import { useContext, useEffect, useState } from "react";
import { useCreateUserRoom, useUpdateUserRoom } from "@services/api/user_room";
import { Card, Col, Form, FormInstance, Row, Select } from "antd";
import Selector from "@components/selector/Selector";
import UserContext from "@provider/UserProvider";
import { useBranchSelector } from "@provider/select_branch";

const { Option } = Select;

type Props = {
  formUserRoom: FormInstance;
  userRoomID: number;
  history: RouteComponentProps["history"];
  medicalRecord: boolean;
};

const Fields = ({
  formUserRoom,
  userRoomID,
  history,
  medicalRecord,
}: Props) => {
  const { t, i18n } = useTranslation();
  const branchSelector = useBranchSelector();
  const [branchId, setBranchId] = useState<number>();
  const userListContext = useContext(UserContext);
  const branchList = useGetBranchList(true, { limit: 0 });
  const userRoomByID = useGetUserRoomRoomID(userRoomID);
  const userRoomUpdate = useUpdateUserRoom();
  const userRoomCreate = useCreateUserRoom();
  const roomList = useGetRoomList(
    !userRoomID
      ? {
          limit: 0,
          doctor: false,
          branchId: branchId,
        }
      : { limit: 0, doctor: true, branchId: branchId }
  );
  const userList = useGetUserDoctor({
    branchId: branchId,
  });

  useEffect(() => {
    formUserRoom.setFieldsValue({
      branchId:
        branchSelector?.branchId ||
        userRoomByID?.data?.result?.[0]?.branch?.id ||
        userListContext?.branch?.id,
      ...userRoomByID?.data?.result?.[0],
    });

    //eslint-disable-next-line
  }, [userRoomByID?.data, userListContext]);

  useEffect(() => {
    setBranchId(
      branchSelector?.branchId ||
        userRoomByID?.data?.result?.[0]?.branch?.id ||
        userListContext?.branch?.id
    );

    //eslint-disable-next-line
  }, [userListContext, userRoomByID?.data]);

  const onFinish = (value: any) => {
    delete value?.branchId;
    if (userRoomID) {
      userRoomUpdate.mutate(
        { data: value },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
            history.replace(USER_MANAGE?.title);
          },
          onError: (res) => {
            openNotification({
              method: "PUT_ERROR",
              message: String(res),
            });
          },
        }
      );
    } else {
      userRoomCreate.mutate(value, {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(USER_MANAGE?.title);
        },
        onError: (res) => {
          openNotification({
            method: "POST_ERROR",
            message: String(res),
          });
        },
      });
    }
  };
  return (
    <Card
      className="card-prefix card-prefix-width card-prefix-chi"
      extra={
        <b className="fs-14 c-white ">
          {userRoomID ? `${t("editDoctorRoom")}` : `${t("createDoctorRoom")}`}
        </b>
      }
    >
      <Form onFinish={onFinish} name="formUserRoom" form={formUserRoom}>
        <Row gutter={[14, 12]}>
          <Col md={24} lg={24}>
            <Form.Item
              label={`${t("branch")}`}
              rules={[{ required: true }]}
              name="branchId"
            >
              <Selector
                placeholder={t("pleaseSelect")}
                disabled={
                  branchSelector?.branchId || userRoomID ? true : !medicalRecord
                }
                Source={branchList?.data?.result?.[0]?.data || []}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                onChange={(e) => {
                  setBranchId(e);
                  document.getElementById("firstNameTh")?.focus();
                }}
              />
            </Form.Item>
          </Col>

          <Col md={24} lg={24}>
            <Form.Item
              label={`${t("room")}`}
              rules={[{ required: true }]}
              name="roomId"
            >
              <Selector
                placeholder={t("pleaseSelect")}
                loading={roomList?.isLoading}
                disabled={userRoomID ? true : !medicalRecord}
                Source={roomList?.data?.result?.[0]?.data || []}
                keyName={"name"}
                keyValue="id"
                onChange={() => {
                  document.getElementById("firstNameTh")?.focus();
                }}
              />
            </Form.Item>
          </Col>

          <Col md={24} lg={24}>
            <Form.Item
              label={`${t("user")}`}
              rules={[{ required: true }]}
              name="userIds"
            >
              <Select
                className="user-room-update"
                disabled={!medicalRecord}
                loading={userList?.isLoading}
                mode="multiple"
                allowClear
                placeholder={t("pleaseSelect")}
                filterOption={(input, option: any) => {
                  return (
                    option.children?.toLowerCase()?.includes(input) ||
                    `${input}` === `${option?.value}`
                  );
                }}
              >
                {userList?.data?.result?.map((item, idx) => (
                  <Option value={item?.id} key={idx}>
                    {i18n.language === "th"
                      ? [item?.firstNameTh, item?.lastNameTh]?.join(" ")?.trim()
                      : [item?.firstNameEn, item?.lastNameEn]
                          ?.join(" ")
                          ?.trim()}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Fields;
