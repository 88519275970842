import React, { useState } from "react";
import { PROMOTION } from "@assets/data/pathRouter";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { useDeletePromotion } from "@services/api/promotion";
import { RouteComponentProps } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ENUM_MENU, ENUM_PROMOTION_STATUS } from "@tools/util.enum";
import Delete from "@assets/img/icon/OPD/delete.svg";
import BackHome from "@components/button/BackHome";
import RoleContext from "@provider/RoleProvider";
import Modaler from "@components/modaler/Modaler";

type Props = {
  promotionId: number;
  status?: ENUM_PROMOTION_STATUS;
  isEdit?: boolean;
};

const Header = ({ promotionId, status, isEdit }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const roleList = React.useContext(RoleContext);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const deletePromotion = useDeletePromotion();
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === ENUM_MENU.PROMOTION;
  });

  const editPromotion = () => {
    history.push(`${PROMOTION.title}/${promotionId}`);
  };

  const onModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onDelete = () => {
    deletePromotion.mutate(promotionId, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        history.replace(`${PROMOTION?.title}`);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
    });
    onModal();
  };

  return (
    <>
      <Modaler
        loadingButton={deletePromotion?.isLoading}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("marketing.title")}</h1>
              </div>
              <span className="t-light-gray">{t("promotion.title")} / </span>
              <span className="t-dark-blue f-normal">
                {t("promotion.manage")}
              </span>
            </Col>
          </Row>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col style={{ marginLeft: "auto" }}>
            {status !== ENUM_PROMOTION_STATUS.EXPIRED &&
              status !== ENUM_PROMOTION_STATUS.PUBLIC &&
              isEdit && (
                <Button
                  className=" delete mr-05 "
                  htmlType="submit"
                  onClick={(e) => {
                    onModal();
                    e.stopPropagation();
                  }}
                >
                  <img
                    className="circle editor-button"
                    src={Delete}
                    alt="delete"
                  />
                </Button>
              )}
            {status !== ENUM_PROMOTION_STATUS.EXPIRED && isEdit && (
              <Button
                className="edit mr-05 "
                htmlType="submit"
                onClick={editPromotion}
              >
                {t("edit")}
              </Button>
            )}

            {status === ENUM_PROMOTION_STATUS.EXPIRED && (
              <Button
                className="btn-create mr-05"
                type="primary"
                onClick={() =>
                  history.push(`${PROMOTION.duplicate}/${promotionId}`)
                }
              >
                {t("Duplicate")}
              </Button>
            )}
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
