import { Scheduler } from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { MonthView } from "@devexpress/dx-react-scheduler-material-ui";
import { AllDayPanel } from "@devexpress/dx-react-scheduler-material-ui";
import { TodayButton } from "@devexpress/dx-react-scheduler-material-ui";
import { FormInstance } from "antd";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { DateNavigator } from "@devexpress/dx-react-scheduler-material-ui";
import { useTranslation } from "react-i18next";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import { Resources, Toolbar } from "@devexpress/dx-react-scheduler-material-ui";
import { CurrentTimeIndicator } from "@devexpress/dx-react-scheduler-material-ui";
import Paper from "@material-ui/core/Paper";

type Props = {
  dataScheduler: any[];
  currentDateChange: string;
  changeCurrentDateChange: (currentDate: any) => void;
  searchOnFinish: (values: any) => void;
  formSearch: FormInstance;
};

const FunctionAll = ({
  dataScheduler,
  currentDateChange,
  changeCurrentDateChange,
  searchOnFinish,
  formSearch,
}: Props) => {
  const { t, i18n } = useTranslation();
  const resources = [
    {
      fieldName: "id",
      title: "title",
      instances: dataScheduler || [],
    },
  ];

  const allDayLocalizationMessages: any = {
    "th-TH": {
      allDay: t("all"),
    },
  };

  const getAllDayMessages = (locale: any) => allDayLocalizationMessages[locale];

  const layerBody = ({ ...restProps }: any) => {
    return (
      <AppointmentTooltip.Content {...restProps}>
        <div className="f-normal">{restProps?.appointmentData?.title}</div>
      </AppointmentTooltip.Content>
    );
  };

  const currentTimeIndicator = {
    shadePreviousCells: true,
    shadePreviousAppointments: true,
    updateInterval: 10000,
  };

  const FlexibleSpace = ({ restProps }: any) => {
    return (
      <Toolbar.FlexibleSpace {...restProps}>
        {/* <SearchFunctionAll
          doctorList={[]}
          searchOnFinish={searchOnFinish}
          formSearch={formSearch}
        /> */}
      </Toolbar.FlexibleSpace>
    );
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "20px" }}>
      <Paper>
        <Scheduler
          locale={i18n.language === "th" ? "th-TH" : "en-US"}
          data={dataScheduler}
        >
          <ViewState
            currentDate={currentDateChange}
            onCurrentDateChange={changeCurrentDateChange}
          />

          <MonthView />
          <AllDayPanel messages={getAllDayMessages("th-TH")} />

          <Appointments />
          <Resources data={resources} />
          <AppointmentTooltip contentComponent={layerBody} />

          <Toolbar flexibleSpaceComponent={FlexibleSpace} />
          <DateNavigator />
          <TodayButton />

          <CurrentTimeIndicator
            shadePreviousCells={currentTimeIndicator.shadePreviousCells}
            shadePreviousAppointments={
              currentTimeIndicator.shadePreviousAppointments
            }
            updateInterval={currentTimeIndicator.updateInterval}
          />
        </Scheduler>
      </Paper>
    </div>
  );
};

export default FunctionAll;
