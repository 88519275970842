import { Age } from "@tools/age";
import { dayjs } from "@tools/timezone";
import { gender } from "@tools/util";
import { createOrder } from "@assets/data/pathRouter";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { IAppointments } from "@interface/IAppointments.interface";
import { useTranslation } from "react-i18next";
import { useGetAppointments } from "@services/api/appointments";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { APPOINTMENT_STATUS, ENUM_ORDERBY } from "@tools/util.enum";
import MoTable from "@components/table/Table";

type Props = {
  searchData: any;
  currentPage: ICurrentPage;
};

const Table = ({ searchData, currentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const history = useHistory<RouteComponentProps["history"]>();
  const appointmentsList = useGetAppointments();

  useEffect(() => {
    const res = appointmentsList?.data
    !!res?.result?.length && setTotal(res?.result[0]?.total || 1);
    // eslint-disable-next-line
  }, [appointmentsList?.data]);

  useEffect(() => {
    appointmentsList?.mutate({
      limit: limitPage,
      page: currentPage.currentPage,
      startDate: dayjs().format("YYYY-MM-DD"),
      endDate: dayjs().format("YYYY-MM-DD"),
      status: searchData?.status
        ? [searchData?.status]
        : [
          APPOINTMENT_STATUS.WAITING_FOR_ADVICE,
          APPOINTMENT_STATUS.BOOK_IN_ADVANCE,
        ],
      orderby: searchData?.sort
        ? searchData?.sort === 1
          ? ENUM_ORDERBY.DESC
          : ENUM_ORDERBY.ASC
        : ENUM_ORDERBY.ASC,
      name: searchData?.name,
      gender: searchData?.gender,
    });
    // eslint-disable-next-line
  }, [currentPage.currentPage, limitPage, searchData]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(
      createOrder +
      `/appointments/${event.id}/visit/${event?.visit?.id || undefined
      }/patients/${event?.patientId || undefined}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      sorter: (a: IAppointments, b: IAppointments) => a.id - b.id,
      render: (_: number, data: IAppointments, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "keyHN",
      sorter: (a: IAppointments, b: IAppointments) => a.id - b.id,
      render: (item: any) => {
        return <div>{item?.hn}</div>;
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "patient",
      render: (item: any) => {
        return (
          <div className="f-normal">
            {i18n.language === "th"
              ? [item?.firstNameTh, item?.lastNameTh]?.join(" ")?.trim()
              : [item?.firstNameEn, item?.lastNameEn]?.join(" ")?.trim()}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "patient",
      key: "gender",
      render: (item: any) => {
        let nameGender = gender(item?.gender, i18n.language);

        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "patient",
      key: "patient",
      render: (item: any) => {
        return <div>{Age(item?.dateOfBirth)}</div>;
      },
    },

    {
      title: t("date"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: any, data: any) => {
        return (
          <div key={data?.id}>
            {dayjs(createdAt).format("DD/MM/YYYY HH:mm")}
          </div>
        );
      },
      sorter: (a: any, b: any) =>
        dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix(),
    },

    {
      title: t("start"),
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: string) => {
        return <div>{dayjs(startDate).format("HH:mm")}</div>;
      },
    },
    {
      title: t("end"),
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: string) => {
        return <div>{dayjs(endDate).format("HH:mm")}</div>;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: string, _: IAppointments) => {
        let color;
        if (status === `ADVISED`) {
          color = "#00377C";
        } else if (status === `ADVISING`) {
          color = "#77C48B";
        } else if (status === `WAITING_FOR_TREATMENT`) {
          color = "#7AC692";
        } else if (status === `WAITING_FOR_ADVICE`) {
          color = "#498DCB";
        } else if (status === `CANCEL`) {
          color = "#FF5D5D";
        } else if (status === `BOOK_IN_ADVANCE`) {
          color = "#989898";
        }

        return (
          <div
            className="table-queue-status mr-1"
            style={{ backgroundColor: color }}
          >
            {status === `WAITING_FOR_ADVICE`
              ? t("waitConsult")
              : status === `ADVISING`
                ? t("consulting")
                : status === `WAITING_FOR_TREATMENT`
                  ? t("waitingForTreatment")
                  : status === `ADVISED`
                    ? t("consulted")
                    : status === `CANCEL`
                      ? t("cancel")
                      : status === `BOOK_IN_ADVANCE` && t("bookAdvance")}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <MoTable
        dataSource={appointmentsList?.data?.result?.[0]?.data || []}
        loading={appointmentsList?.isLoading}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
