import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";

type Props = {
  onFinish: (value: any) => void;
};

const Search = ({ onFinish }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col lg={18} md={16} xl={18}>
            <Form.Item name="search">
              <Input placeholder={`${t("search")}${t("type")} `} />
            </Form.Item>
          </Col>

          <Col >
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
