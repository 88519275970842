import { thrownResponse } from "./auth";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useCreateSignatureRoom = (): UseMutationResult => {
  return useMutation(async ({ visitId, data }: any) => {
    const res = await axios.post(`/signature-room/${visitId}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
