import { useForm } from "antd/es/form/Form";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { date, selectDate } from "@assets/data/data";
import { RangePickerProps } from "antd/lib/date-picker";
import { IParamsGetAppointment } from "@interface/IAppointments.interface";
import { Button, Col, DatePicker, Form } from "antd";
import { FormInstance, Input, Radio, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";
import moment from "moment";

type Props = {
  onFinish: (value: IParamsGetAppointment) => void;
};

const dateFormat = "DD-MM-YYYY";

const Search = ({ onFinish }: Props) => {
  const [form] = useForm();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    form.setFieldsValue({
      selectDate: 1,
      date: moment(new Date()),
    });
    //eslint-disable-next-line
  }, []);

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < moment().add(-1, "day").endOf("day");
  };

  return (
    <div className="pb-1">
      <Form onFinish={onFinish} form={form}>
        <Row gutter={[14, 14]}>
          <Col md={7} lg={6}>
            <Form.Item name="name">
              <Input placeholder={t("search")} />
            </Form.Item>
          </Col>

          <Col>
            <Form.Item label={t("type")} name="selectDate" noStyle>
              <Radio.Group>
                {selectDate?.map((item) => {
                  return (
                    <Radio key={item?.id} value={item?.id}>
                      {i18n.language === "th" ? item?.nameTh : item?.nameEn}
                    </Radio>
                  );
                })}
              </Radio.Group>
            </Form.Item>
          </Col>

          <Form.Item
            noStyle
            shouldUpdate={(prev, next) => prev.selectDate !== next.selectDate}
          >
            {(form: FormInstance) => {
              const selectDate = form.getFieldValue("selectDate");

              switch (selectDate) {
                case 1:
                  return (
                    <Col md={6} lg={5}>
                      <Form.Item name="date">
                        <DatePicker
                          disabledDate={disabledDate}
                          format={dateFormat}
                          placeholder={t("date")}
                        />
                      </Form.Item>
                    </Col>
                  );

                case 2:
                  return (
                    <Col md={6} lg={5}>
                      <Form.Item name="date">
                        <DatePicker
                          disabledDate={disabledDate}
                          picker="week"
                          format={dateFormat}
                          placeholder={t("date")}
                        />
                      </Form.Item>
                    </Col>
                  );

                case 3:
                  return (
                    <Col md={6} lg={5}>
                      <Form.Item name="date">
                        <DatePicker
                          disabledDate={disabledDate}
                          picker="month"
                          format="YYYY-MM"
                          placeholder={t("date")}
                        />
                      </Form.Item>
                    </Col>
                  );
              }
            }}
          </Form.Item>

          <Col md={9} lg={5}>
            <Form.Item name="sort">
              <SelectorDouble
                placeholder={t("newest")}
                Source={date}
                keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("sortByDay")}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
