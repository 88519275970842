import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";

type Props = {
  title?: string;
  onFinish: (e: any) => void;
};

const SearchProductCategory = ({ title = "", onFinish }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="mb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[10, 14]} align="middle">
          <Col>
            <div className="fs-16 ">{title}</div>
          </Col>

          <Col style={{ marginLeft: "auto" }}>
            <Form.Item name="name">
              <Input
                className="search-modal"
                placeholder={`${t("nameSearch")} / ${t("type")}`}
              />
            </Form.Item>
          </Col>
          <Col>
            <Button className=" btn-search" htmlType="submit">
              {t("search")}
            </Button>
          </Col>
          <Col>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchProductCategory;
