import React, { useState } from "react";
import TableBranchAnother from "./Table";
import SearchBranchAnother from "./Search";

const BranchAnother = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  return (
    <div>
      <SearchBranchAnother
        setSearchValue={setSearchValue}
        setCurrentPage={setCurrentPage}
      />
      <TableBranchAnother
        searchValue={searchValue}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </div>
  );
};

export default BranchAnother;
