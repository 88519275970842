import { ageRange } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { useMainDashboard } from "@provider/dashboard";
import { Card, Col, Row } from "antd";
import { IDashboardPurchaseProduct } from "@interface/IDashBoard.interface";
import MoTable from "@components/table/Table";
import Selector from "@components/selector/Selector";

const DashboardPurchase = () => {
  const { t, i18n } = useTranslation();
  const { purchaseByAge } = useMainDashboard();

  const columns = [
    {
      title: `${t("name")}`,
      dataIndex: "name",
      render: (_: string, result: IDashboardPurchaseProduct) => {
        return (
          <div>
            {i18n.language === "th"
              ? result?.nameTh || ""
              : result?.nameEn || ""}
          </div>
        );
      },
    },

    {
      title: t("amount"),
      dataIndex: "count",
      render: (count: string) => {
        return count || 0;
      },
    },
  ];
  return (
    <div>
      <Card className="card-table-dashboard">
        <Row justify="space-between" align="middle">
          <Col>
            <div>
              {t("purchaseByAgeReport")}
              <span>
                {" "}
                {t("sinceTheAge")} {purchaseByAge?.age} {t("year")}
              </span>
            </div>
          </Col>
          <Col>
            <Selector
              className="search-age"
              Source={ageRange}
              keyName="name"
              keyValue="id"
            />
          </Col>
        </Row>

        <MoTable
          className="null"
          rowClassName={(r) => {
            return " no-header no-cursor";
          }}
          columns={columns}
          dataSource={purchaseByAge?.product || []}
          pagination={false}
          scroll={{ y: 200, x: 500 }}
          rowKey="key"
          key="key"
        />
      </Card>
    </div>
  );
};

export default DashboardPurchase;
