import { createContext, useContext } from "react";
import { IProductOutStandingContext } from "./interface";

const MainProductOutStandingContext = createContext<IProductOutStandingContext>(
  {} as IProductOutStandingContext
);

const MainProductOutStandingProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: IProductOutStandingContext;
}) => {
  return (
    <MainProductOutStandingContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </MainProductOutStandingContext.Provider>
  );
};

export const useMainProductOutStanding = () =>
  useContext(MainProductOutStandingContext);
export default MainProductOutStandingProvider;
