export const heightTopPrint = (divHeight: any) => {
  if (927 - (divHeight % 927) > 600) {
    return 774 - (divHeight % 774);
  } else if (927 - (divHeight % 927) > 550) {
    return 927 - (divHeight % 927);
  } else if (927 - (divHeight % 927) > 500) {
    return 827 - (divHeight % 827);
  } else if (927 - (divHeight % 927) > 450) {
    return 827 - (divHeight % 827);
  } else if (927 - (divHeight % 927) > 440) {
    return 827 - (divHeight % 827);
  } else if (927 - (divHeight % 927) > 400) {
    return 774 - (divHeight % 774);
  } else if (927 - (divHeight % 927) > 350) {
    return 574 - (divHeight % 574);
  } else if (927 - (divHeight % 927) > 200) {
    return 764 - (divHeight % 764);
  } else if (927 - (divHeight % 927) > 107) {
    return 827 - (divHeight % 827);
  } else if (927 - (divHeight % 927) > 100) {
    return 927 - (divHeight % 927);
  } else if (927 - (divHeight % 927) > 70) {
    return 0;
  } else if (927 - (divHeight % 927) > 10) {
    return 947 - (divHeight % 947);
  } else {
    return 977 - (divHeight % 977);
  }
};
