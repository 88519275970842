import { OPD } from "@assets/data/pathRouter";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useReactToPrint } from "react-to-print";
import { VISIT_OLD_FILES } from "@tools/util.enum";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import ReportBiologicalTherapy from "./report/Report";

const BiologicalTherapy = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const history = useHistory<RouteComponentProps["history"]>();
  const printReportRef = useRef(null);
  const pageStyle = `  @page { size:A4;
    margin: 40px;}`;

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Card
        className="bg-grey "
        extra={
          <Row align="middle">
            <Col>
              <b className="header-card ">{t("biologicalTherapy")}</b>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Button
                className="btn-create mr-05"
                onClick={() => {
                  history.push(
                    `${OPD.nursingHistory}/patient/${patientID}/history-upload-file/${VISIT_OLD_FILES?.BIOLOGY}`
                  );
                }}
              >
                {t("file")}
              </Button>
            </Col>
            <Col>
              <Button className="reserve " key="back" onClick={handlePrint}>
                {t("print")}
              </Button>
            </Col>
          </Row>
        }
      >
        <ReportBiologicalTherapy printReportRef={printReportRef} />
      </Card>
    </div>
  );
};

export default BiologicalTherapy;
