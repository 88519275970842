import { thrownResponse } from "./auth";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { IGetProductCourseItem } from "@interface/IProductCourseItem";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useCreateProductCourseItem = (): UseMutationResult => {
  return useMutation(["create-product-course-item"], async (data: any) => {
    const res = await axios.post(`/product-course-item`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetProductCourseItem = (
  id?: number
): UseQueryResult<ElementaryOneMore<IGetProductCourseItem>> => {
  return useQuery(["get-product-course-item", id], async () => {
    if (!id) {
      return;
    }
    const res = await axios.get(`/product-course-item/${id}`);
    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    throw new Error(res?.data?.message);
  });
};

export const useUpdateProductCourseItem = (): UseMutationResult => {
  return useMutation(
    ["update-product-course-item"],
    async ({ data, id }: any) => {
      const res = await axios.put(`/product-course-item/${id}`, data);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useDeleteProductCourseItem = (): UseMutationResult => {
  return useMutation(["delete-product-course-item"], async (id: any) => {
    const res = await axios.delete(`/product-course-item/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
