import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";

type Props = {
  setSearchValue: (value: any) => void;
  setCurrentPage: (e: any) => void;
};

const Search = ({ setSearchValue, setCurrentPage }: Props) => {
  const { t } = useTranslation();
  const onFinish = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col sm={15} lg={18} xs={19} xxl={20}>
            <Form.Item name="search">
              <Input placeholder={t("searchEvent")} />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
