import { ENUM_MENU } from "@tools/util.enum";
import { DASHBOARD, LOGIN, OPD } from "@assets/data/pathRouter";
import OPDPage from "@views/profile/opd/OPD";
import Pay from "@views/cashier/pay/Pay";
import Lab from "@views/nurse_room/lab/Lab";
import Login from "@views/login/LoginPage";
import Queue from "@views/profile/queue/Queue";
import React from "react";
import NoPage from "@views/404/NoPage";
import Coupon from "@views/marketing/coupon/Coupon";
import Event from "@views/marketing/event/Event";
import General from "@views/setting/general/General";
import LabList from "@views/nurse_room/interface_lab/lab_list/LabList";
import Receipt from "@views/cashier/pay/receipt/form/Form";
import Inchart from "@views/nurse_room/inchart/Inchart";
import FormLab from "@views/nurse_room/lab/confirm_lab/form/Form";
import Transfer from "@views/profile/transfer/Transfer";
import Dashboard from "@views/dashboard/Dashboard";
import Warehouse from "@views/setting/warehouse/Warehouse";
import FormAgent from "@views/setting/general/agent/form/create/Create";
import Procedure from "@views/nurse_room/procedure/Procedure";
import Promotion from "@views/marketing/promotion/Promotion";
import PayDetail from "@views/cashier/pay/form/Form";
import BookToday from "@views/profile/opd/book_today/BookToday";
import UserManage from "@views/user_manage/UserManage";
import ReportDrug from "@views/pharmacy_room/dispense_medicine/report_drug/ReportDrug";
import DoctorRoom from "@views/doctor_room/DoctorRoom";
import CompareLab from "@views/nurse_room/interface_lab/compare/Compare";
import SignCourse from "@views/cashier/sign_course/SignCourse";
import PayListTab from "@views/cashier/pay/Tabs";
import RoomManage from "@views/user_manage/room/form/Form";
import CreateBill from "@views/cashier/pay/before_bill/form/Form";
import BillReport from "@views/cashier/pay/bill/view_report/ViewReport";
import BillCreateReport from "@views/cashier/pay/bill/view_report/ViewDraftBillReport";
import AgentReport from "@views/report_summary/agent_report/AgentReport";
import DetailSlots from "@views/profile/queue/detail_slots/DetailSlots";
import EventDetail from "@views/marketing/event/our_branch/detail/Detail";
import ManageEvent from "@views/marketing/event/our_branch/form/Form";
import InterfaceLab from "@views/nurse_room/interface_lab/InterfaceLab";
import ManageCoupon from "@views/marketing/coupon/form/Form";
import CouponDetail from "@views/marketing/coupon/detail/Detail";
import ConfirmOrder from "@views/physician_assistant/confirm_order/ConfirmOrder";
import ReturnCourse from "@views/cashier/return_course/ReturnCourse";
import CreatePrefix from "@views/setting/general/prefix/form/Form";
import Notification from "@views/booking/notification/Notification";
import PrintCompare from "@views/nurse_room/interface_lab/print_compare/PrintCompare";
import ExecuteOrder from "@views/physician_assistant/execute_order/ExecuteOrder";
import ReportConsent from "@views/profile/opd/nursing_history/consent/report/Report";
import InvoiceReport from "@views/cashier/pay/invoice/view_report/ViewReport";
import ReceiptReport from "@views/cashier/pay/receipt/view_report/ViewReport";
import FormProcedure from "@views/nurse_room/procedure/form/Form";
import CommentDoctor from "@views/doctor_room/tabs/counsel/create_order/Comment";
import ManageCourses from "@views/marketing/manage_courses/ManageCourses";
import CreateCountry from "@views/setting/general/country/form/Form";
import BookInAdvance from "@views/profile/opd/book_in_advance/BookInAdvance";
import ProductReport from "@views/report_summary/product_report/ProductReport";
import ReportSummary from "@views/report_summary/ReportSummary";
import SlotsOverview from "@views/profile/queue/SlotsViewAll";
import UserRoomManage from "@views/user_manage/user_room/form/Form";
import PurchaseReport from "@views/report_summary/purchase_report/PurchaseReport";
import NursingHistory from "@views/profile/opd/nursing_history/NursingHistory";
import SettingProfile from "@views/setting_profile/SettingProfile";
import TransferCourse from "@views/cashier/transfer_course/TransferCourse";
import PainManagement from "@views/pain_management/PainManagement";
import TransferDetail from "@views/profile/transfer/all_caregivers/detail/Detail";
import CorrectGeneral from "@views/setting/general/form_edit/FormEdit";
import ReturnMedicine from "@views/pharmacy_room/return_medicine/ReturnMedicine";
import PatientProfile from "@views/profile/opd/patient_profile/PatientProfile";
import FormWaitInchart from "@views/nurse_room/inchart/wait/form/Form";
import FormAgentUpdate from "@views/setting/general/agent/form/update/Update";
import MedicalDocument from "@views/medical_document/MedicalDocument";
import TreatmentRecord from "@views/profile/opd/nursing_history/treatment_record/report/Report";
import ManagePromotion from "@views/marketing/promotion/form/Form";
import PromotionDetail from "@views/marketing/promotion/detail/Detail";
import DetailLabResult from "@views/nurse_room/interface_lab/lab_result/LabResult";
import LeadingSymptoms from "@views/profile/opd/leading_symptoms/LeadingSymptoms";
import Preregistration from "@views/profile/pre_registration/PreRegistration";
import ReportProcedure from "@views/nurse_room/report_procedure/ReportProcedure";
import UserCreateManage from "@views/user_manage/user/form/Form";
import RoleCreateManage from "@views/user_manage/role/form/Form";
import ExecuteOrderList from "@views/physician_assistant/execute_order/order/Order";
import LabResultsDetail from "@views/profile/opd/nursing_history/lab_results/detail/Detail";
import ConfirmOrderList from "@views/physician_assistant/confirm_order/order/Order";
import PatientBasicInfo from "@views/profile/opd/patient_basic_info/PatientBasicInfo";
import CourseManageForm from "@views/marketing/manage_courses/form/Form";
import SignCourseDetail from "@views/cashier/sign_course/form/Form";
import PatientOtherInfo from "@views/profile/opd/patient_other_info/PatientOtherInfo";
import BranchManagement from "@views/setting/branch_management/BranchManagement";
import FormInterfaceLab from "@views/nurse_room/interface_lab/form/Form";
import DispenseMedicine from "@views/pharmacy_room/dispense_medicine/DispenseMedicine";
import ImportantSymptoms from "@views/profile/opd/important_symptoms/ImportantSymptoms";
import BillCreateInvoice from "@views/cashier/pay/bill/form/Form";
import ManagePickUpGoods from "@views/setting/warehouse/pick_up_goods/form/Form";
import ManageBounceGoods from "@views/setting/warehouse/bounce_goods/form/Form";
import CreateNationality from "@views/setting/general/nationality/form/Form";
import CreateCitizenship from "@views/setting/general/citizenship/form/Form";
import ReturnCourseDetail from "@views/cashier/return_course/return/form/Form";
import ReturnCourseReport from "@views/cashier/return_course/report/Report";
import CreateNotification from "@views/booking/notification/form/Form";
import FormReturnMedicine from "@views/pharmacy_room/return_medicine/form/Form";
import FormBetweenInchart from "@views/nurse_room/inchart/between/form/Form";
import ContractingCompany from "@views/profile/opd/contracting_company/ContractingCompany";
import DetailLabInterface from "@views/nurse_room/interface_lab/detail/Detail";
import FormPainManagement from "@views/pain_management/form/Form";
import DetailLabResultOpd from "@views/profile/opd/nursing_history/lab_results/detail_lab_result/DetailLabResult";
import BookingAnotherRoom from "@views/booking/booking_another_room/BookingAnotherRoom";
import NursingHistoryFile from "@views/profile/opd/nursing_history/history_upload_file/HistoryUploadFile";
import ReportConfirmOrder from "@views/physician_assistant/confirm_order/report/Report";
import ReportDrugMedicine from "@views/pharmacy_room/report/Report";
import CompareLabInterface from "@views/profile/opd/nursing_history/lab_results/compare/Compare";
import TypeMedicalDocument from "@views/medical_document/type_medical_document/TypeMedicalDocument";
import FormContractCompany from "@views/setting/general/contract_company/form/create/Create";
import OperatingRoomReport from "@views/report_summary/operating_room_report/OperatingRoomReport";
import FormMedicalDocument from "@views/medical_document/form/Form";
import PrintCompareHistory from "@views/profile/opd/nursing_history/lab_results/print_compare/PrintCompare";
import ManagePurchaseOrder from "@views/setting/warehouse/purchase_order/form/Form";
import PreRegistrationMark from "@views/marketing/pre_registration/PreRegistration";
import WareHouseUploadFile from "@views/setting/warehouse/product/form/Form";
import OrderInfoDoctorRoom from "@views/doctor_room/tabs/order_info/order_info_doctor_room/OrderInfoDoctorRoom";
import InvoiceCreateReceipt from "@views/cashier/pay/invoice/form/Form";
import PrintDetailLabResult from "@views/nurse_room/interface_lab/print_lab_result/PrintLabResult";
import ReportPhysicianOrder from "@views/profile/opd/nursing_history/physician_order/report/Report";
import PreregistrationEvent from "@views/profile/pre_registration/patients_list/PatientsList";
import EventViewPreRegister from "@views/marketing/event/view/View";
import TransferCourseDetail from "@views/cashier/transfer_course/form/Form";
import FormDispenseMedicine from "@views/pharmacy_room/dispense_medicine/form/Form";
import DocumentExecuteOrder from "@views/physician_assistant/execute_order/document/Document";
import ViewFileMedicalReport from "@views/profile/opd/nursing_history/medical_report/view_file/ViewFile";
import ReportProcedureDetail from "@views/nurse_room/report_procedure/report/Report";
import CreateOrderDoctorRoom from "@views/doctor_room/tabs/counsel/create_order/CreateOrder";
import PreRegistrationDetail from "@views/profile/pre_registration/form/Form";
import ManageContractCompany from "@views/setting/general/contract_company/form/update/Update";
import PreRegistrationReport from "@views/profile/pre_registration/view_report/ViewReport";
import PrintLabelLabInterface from "@views/nurse_room/interface_lab/print_label/PrintLabel";
import ViewFileMedicalSummary from "@views/profile/opd/nursing_history/medical_summary/view_file/ViewFile";
import MedicalRoomReservation from "@views/booking/medical_room_reservation/MedicalRoomReservation";
import ManageBranchManagement from "@views/setting/branch_management/form/Form";
import UpLoadFileMedicalReport from "@views/profile/opd/nursing_history/upload_file_visit/UploadFileVisit";
import FormTypeMedicalDocument from "@views/medical_document/type_medical_document/form/Form";
import ViewFileRadiologyReport from "@views/profile/opd/nursing_history/radiology_report/view_file/ViewFile";
import PrintDetailLabResultOpd from "@views/profile/opd/nursing_history/lab_results/detail_lab_result/print_lab_result/PrintLabResult";
import ReturnCourseHistoryList from "@views/cashier/return_course/history/form/Form";
import ReportProcedureVisitAll from "@views/nurse_room/report_procedure/visit_list/VisitList";
import PreRegistrationMarkEvent from "@views/marketing/pre_registration/patients_list/PatientsList";
import UpLoadFileMedicalSummary from "@views/profile/opd/nursing_history/upload_file_visit/UploadFileVisit";
import NurseRoomReportProcedure from "@views/nurse_room/procedure/report/Report";
import ReportDrugMedicineDetail from "@views/pharmacy_room/report/report_drug/ReportDrug";
import UpLoadFileRadiologyReport from "@views/profile/opd/nursing_history/upload_file_visit/UploadFileVisit";
import PreRegistrationDetailMark from "@views/marketing/pre_registration/today/form/Form";
import PreRegistrationReportMark from "@views/marketing/pre_registration/today/view_report/ViewReport";
import PhysicalTherapyRecordReport from "@views/profile/opd/nursing_history/physical_records/report/Report";
import PrintLabelDetailLabInterface from "@views/nurse_room/interface_lab/lab_list/print_label/PrintLabel";
import PreRegistrationReportHistory from "@views/profile/pre_registration/history/view_report/ViewReport";
import PreRegistrationDetailHistory from "@views/profile/pre_registration/history/form/Form";
import NursingHistoryHistoryUploadFile from "@views/profile/opd/nursing_history/upload_file/UploadFile";
import SlotsOverViewBookingAnotherRoom from "@views/booking/booking_another_room/SlotsViewAll";
import PreRegistrationReportHistoryMark from "@views/marketing/pre_registration/history/view_report/ViewReport";
import PreRegistrationDetailHistoryMark from "@views/marketing/pre_registration/history/form/Form";
import PreRegistrationMarkPatientHistory from "@views/marketing/pre_registration/patients_list/PatientsList";
import SlotsOverViewMedicalRoomReservation from "@views/booking/medical_room_reservation/SlotsViewAll";
import ProductPurchaseHnReport from "@views/report_summary/product_purchase_hn_report/ProductPurchaseHnReport";
import ProductPromotionPurchaseReport from "@views/report_summary/product_promotion_purchase_report/ProductPromotionPurchaseReport";
import ProductProcedureReport from "@views/report_summary/product_procedure_report/ProductProcedureReport";
import ProductProcedurePurchaseReport from "@views/report_summary/product_procedure_purchase_report/ProductProcedurePurchaseReport";
import PatientReport from "@views/report_summary/patient_report/PatientReport";
import ProductPurchaseReport from "@views/report_summary/product_purchase_report/ProductPurchaseReport";
import BranchReport from "@views/report_summary/branch_report/BranchReport";
import InvoiceReportSummary from "@views/report_summary/invoice_report/InvoiceReport";
import ReceiptReportSummary from "@views/report_summary/receipt_report/ReceiptReport";

interface IRouter {
  path: string;
  name: string;
  exact: boolean;
  component: React.FC;
  authMenuName: string;
}

export const routers: IRouter[] = [
  {
    path: LOGIN.title,
    name: "login",
    exact: true,
    component: Login,
    authMenuName: "LOGIN",
  },

  {
    path: DASHBOARD.title,
    name: "dashboard",
    exact: true,
    component: Dashboard,
    authMenuName: "DASHBOARD",
  },

  {
    path: OPD.title,
    name: "opd",
    exact: true,
    component: OPDPage,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: OPD.basicInfo,
    name: "patient-basic-info",
    exact: true,
    component: PatientBasicInfo,
    authMenuName: "MEDICAL_RECORD-EDIT",
  },

  {
    path: `${OPD.basicInfo}/:id`,
    name: "edit-patient-basic-info-EDIT",
    exact: true,
    component: PatientBasicInfo,
    authMenuName: "MEDICAL_RECORD-EDIT",
  },
  {
    path: `${OPD.basicInfo}/:id/event/:eventId`,
    name: "edit-patient-basic-info-EDIT",
    exact: true,
    component: PatientBasicInfo,
    authMenuName: "MEDICAL_RECORD-EDIT",
  },

  {
    path: OPD.otherInfo,
    name: "patient-other-info",
    exact: true,
    component: PatientOtherInfo,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: `${OPD.otherInfo}/:id`,
    name: "patient-other-info",
    exact: true,
    component: PatientOtherInfo,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: `${OPD.otherInfo}/:id/event/:eventId`,
    name: "patient-other-info",
    exact: true,
    component: PatientOtherInfo,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: OPD.contractingCompany,
    name: "contracting-company",
    exact: true,
    component: ContractingCompany,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: `${OPD.contractingCompany}/:id`,
    name: "contracting-company",
    exact: true,
    component: ContractingCompany,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: `${OPD.contractingCompany}/:id/event/:eventId`,
    name: "contracting-company",
    exact: true,
    component: ContractingCompany,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: OPD.profile,
    name: "patient-profile",
    exact: true,
    component: PatientProfile,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: `${OPD.profile}/patient/:id`,
    name: "patient-profile-edit",
    exact: true,
    component: PatientProfile,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: `${OPD.profile}/patient/:id/event/:eventId`,
    name: "patient-profile-edit",
    exact: true,
    component: PatientProfile,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: `${OPD.nursingHistory}/patient/:patientID`,
    name: "patient-profile-nursing-history",
    exact: true,
    component: NursingHistory,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: `${OPD.nursingHistory}/patient/:patientID/history-upload-file/:fileId`,
    name: "nursing-history-file",
    exact: true,
    component: NursingHistoryFile,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: `${OPD.nursingHistory}/patient/:patientID/history-upload-file/:fileId/upload`,
    name: "nursing-history-upload-file",
    exact: true,
    component: NursingHistoryHistoryUploadFile,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/lab-interface/patient/:patientID/visit/:visitID",
    name: "patient-profile-nursing-history-detail",
    exact: true,
    component: LabResultsDetail,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/patient-profile/nursing-history/lab-interface/detail/lab-result/patient/:patientID/visit/:visitID",
    name: "lab-result",
    exact: true,
    component: DetailLabResultOpd,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/lab-interface/detail/lab-result/print/patient/:patientID/visit/:visitID",
    name: "lab/detail/lab-result/print",
    exact: true,
    component: PrintDetailLabResultOpd,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/lab-interface/compare/patient/:patientID",
    name: "patient-profile-nursing-history-compare",
    exact: true,
    component: CompareLabInterface,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/lab-interface/compare/print/patient/:patientID",
    name: "patient-profile-nursing-history-compare-print",
    exact: true,
    component: PrintCompareHistory,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/physician-order/patient/:patientID/visit/:visitID",
    name: "nursing-history-physician-order",
    exact: true,
    component: ReportPhysicianOrder,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/patient-profile/nursing-history/treatment-record/patient/:patientID/visit/:visitID",
    name: "nursing-history-treatment-record",
    exact: true,
    component: TreatmentRecord,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/patient-profile/nursing-history/radiology-report/patient/:patientID/visit/:visitID",
    name: "nursing-history-radiology",
    exact: true,
    component: ViewFileRadiologyReport,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/patient-profile/nursing-history/radiology-report/upload-file",
    name: "nursing-history-radiology-upload",
    exact: true,
    component: UpLoadFileRadiologyReport,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/medical-report/patient/:patientID/visit/:visitID",
    name: "nursing-history-medical-report",
    exact: true,
    component: ViewFileMedicalReport,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/patient-profile/nursing-history/medical-report/upload-file",
    name: "nursing-history-medical-report-upload",
    exact: true,
    component: UpLoadFileMedicalReport,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/medical-summary/patient/:patientID/visit/:visitID",
    name: "nursing-history-medication",
    exact: true,
    component: ViewFileMedicalSummary,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/patient-profile/nursing-history/medical-summary/upload-file",
    name: "nursing-history-medication-upload",
    exact: true,
    component: UpLoadFileMedicalSummary,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/physical-therapy-record/patient/:patientID/visit/:visitID",
    name: "nursing-history-radiology",
    exact: true,
    component: PhysicalTherapyRecordReport,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/nursing-history/consent/patient/:patientID/visit/:visitID",
    name: "nursing-history-consent",
    exact: true,
    component: ReportConsent,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/leading-symptoms/:patientID",
    name: "leadingSymptoms",
    exact: true,
    component: LeadingSymptoms,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/leading-symptoms/:patientID/event/:eventId",
    name: "leadingSymptoms",
    exact: true,
    component: LeadingSymptoms,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/leading-symptoms/patient/:patientID/visit/:visitID",
    name: "leadingSymptoms",
    exact: true,
    component: LeadingSymptoms,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/leading-symptoms/patient/:patientID/visit/:visitID/event/:eventId",
    name: "leadingSymptoms",
    exact: true,
    component: LeadingSymptoms,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/book-in-advance",
    name: "book-in-advance",
    exact: true,
    component: BookInAdvance,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/book-in-advance/:id",
    name: "book-in-advance",
    exact: true,
    component: BookInAdvance,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/book-today/patient/:patientID/visit/:visitsID",
    name: "book-today",
    exact: true,
    component: BookToday,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/book-today/patient/:patientID/visit/:visitsID/event/:eventId",
    name: "book-today",
    exact: true,
    component: BookToday,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/patient-profile/important-symptoms/patient/:patientID/visit/:visitsID",
    name: "important-symptoms",
    exact: true,
    component: ImportantSymptoms,
    authMenuName: "MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/patient-profile/important-symptoms/patient/:patientID/visit/:visitsID/event/:eventId",
    name: "important-symptoms",
    exact: true,
    component: ImportantSymptoms,
    authMenuName: "MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/slots-detail",
    name: "slots-detail",
    exact: true,
    component: DetailSlots,
    authMenuName: "QUE_MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/slots-detail/slot/:slotID",
    name: "slots-detail",
    exact: true,
    component: DetailSlots,
    authMenuName: "QUE_MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/slots-detail/slot/:slotID/patient/:patientID",
    name: "slots-detail",
    exact: true,
    component: DetailSlots,
    authMenuName: "QUE_MEDICAL_RECORD",
  },
  {
    path: "/profile/opd/slots-detail/slot/:slotID/patient/:patientID/visit/:visitID",
    name: "slots-detail",
    exact: true,
    component: DetailSlots,
    authMenuName: "QUE_MEDICAL_RECORD",
  },

  {
    path: "/profile/opd/slots-detail/slot/:slotID/patient/:patientID/visit/:visitID/event/:eventId",
    name: "slots-detail",
    exact: true,
    component: DetailSlots,
    authMenuName: "QUE_MEDICAL_RECORD",
  },

  {
    path: "/profile/queue",
    name: "queue",
    exact: true,
    component: Queue,
    authMenuName: "QUE_MEDICAL_RECORD",
  },
  {
    path: "/profile/queue/slots-overview",
    name: "slots-overview",
    exact: true,
    component: SlotsOverview,
    authMenuName: "QUE_MEDICAL_RECORD",
  },
  {
    path: "/profile/transfer",
    name: "transfer",
    exact: true,
    component: Transfer,
    authMenuName: "FOREWORD_MEDICAL_RECORD",
  },

  {
    path: "/profile/transfer/detail/:patientID",
    name: "transfer",
    exact: true,
    component: TransferDetail,
    authMenuName: "FOREWORD_MEDICAL_RECORD",
  },

  {
    path: "/profile/pre-registration",
    name: "pre-registration",
    exact: true,
    component: Preregistration,
    authMenuName: "PREREGISTER1",
  },
  {
    path: "/profile/pre-registration/manage/event/:eventID",
    name: "pre-registration-event",
    exact: true,
    component: PreregistrationEvent,
    authMenuName: "PREREGISTER1",
  },

  {
    path: "/profile/pre-registration/manage/event/:eventID/edit-by/patient/:patientID",
    name: "pre-registration-detail-id",
    exact: true,
    component: PreRegistrationDetail,
    authMenuName: "PREREGISTER1",
  },

  {
    path: "/profile/pre-registration/manage/event/:eventID/report/patient/:patientID",
    name: "pre-registration-report",
    exact: true,
    component: PreRegistrationReport,
    authMenuName: "PREREGISTER1",
  },

  {
    path: "/profile/pre-registration/manage/event/:eventID/old-customer/patient/:patientID",
    name: "pre-registration-old-customer",
    exact: true,
    component: PreRegistrationDetail,
    authMenuName: "PREREGISTER1",
  },

  {
    path: "/profile/pre-registration/manage/event/:eventID/create-draft/patient/:patientID",
    name: "pre-registration-detail-id",
    exact: true,
    component: PreRegistrationDetail,
    authMenuName: "PREREGISTER1",
  },

  {
    path: "/profile/pre-registration/manage/event/:eventID/report/history/patient/:patientID",
    name: "pre-registration-report-history",
    exact: true,
    component: PreRegistrationReportHistory,
    authMenuName: "PREREGISTER1",
  },

  {
    path: "/profile/pre-registration/manage/event/:eventID/view/history/patient/:patientID",
    name: "pre-registration-report-history",
    exact: true,
    component: PreRegistrationDetailHistory,
    authMenuName: "PREREGISTER1",
  },

  {
    path: "/marketing/pre-registration",
    name: "pre-registration",
    exact: true,
    component: PreRegistrationMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/patient-history/event/:eventID",
    name: "pre-registration-history",
    exact: true,
    component: PreRegistrationMarkPatientHistory,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID",
    name: "pre-registration-event",
    exact: true,
    component: PreRegistrationMarkEvent,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID/create",
    name: "pre-registration-detail",
    exact: true,
    component: PreRegistrationDetailMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID/edit-by/patient/:patientID",
    name: "pre-registration-detail-id",
    exact: true,
    component: PreRegistrationDetailMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID/edit-by/patient-history/:patientID",
    name: "pre-registration-detail-id",
    exact: true,
    component: PreRegistrationDetailMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID/report/patient/:patientID",
    name: "pre-registration-report",
    exact: true,
    component: PreRegistrationReportMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID/report/history/patient/:patientID",
    name: "pre-registration-report-history",
    exact: true,
    component: PreRegistrationReportHistoryMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID/view/history/patient/:patientID",
    name: "pre-registration-report-history",
    exact: true,
    component: PreRegistrationDetailHistoryMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID/old-customer/patient/:patientID",
    name: "pre-registration-old-customer",
    exact: true,
    component: PreRegistrationDetailMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/pre-registration/manage/event/:eventID/create-draft/patient/:patientID",
    name: "pre-registration-detail-id",
    exact: true,
    component: PreRegistrationDetailMark,
    authMenuName: "PREREGISTER2",
  },

  {
    path: "/marketing/coupon",
    name: "coupon",
    exact: true,
    component: Coupon,
    authMenuName: "COUPON",
  },

  {
    path: "/marketing/coupon/:id",
    name: "coupon-edit",
    exact: true,
    component: ManageCoupon,
    authMenuName: "COUPON",
  },

  {
    path: "/marketing/coupon/manage/create",
    name: "coupon-create",
    exact: true,
    component: ManageCoupon,
    authMenuName: "COUPON",
  },

  {
    path: "/marketing/coupon/detail/:id",
    name: "coupon-detail",
    exact: true,
    component: CouponDetail,
    authMenuName: "COUPON",
  },

  {
    path: "/marketing/event",
    name: "event",
    exact: true,
    component: Event,
    authMenuName: "EVENT",
  },

  {
    path: "/marketing/event/:id",
    name: "event-edit",
    exact: true,
    component: ManageEvent,
    authMenuName: "EVENT",
  },

  {
    path: "/marketing/event/manage/create",
    name: "event-create",
    exact: true,
    component: ManageEvent,
    authMenuName: "EVENT",
  },

  {
    path: "/marketing/event/detail/:id",
    name: "event-detail",
    exact: true,
    component: EventDetail,
    authMenuName: "EVENT",
  },

  {
    path: "/marketing/event/view/pre-register",
    name: "event-pre-register",
    exact: true,
    component: EventViewPreRegister,
    authMenuName: "EVENT",
  },

  {
    path: "/marketing/manage-courses",
    name: "manage-courses",
    exact: true,
    component: ManageCourses,
    authMenuName: "COURSE_MANAGEMENT",
  },

  {
    path: "/marketing/manage-courses/create",
    name: "course-manage-form",
    exact: true,
    component: CourseManageForm,
    authMenuName: "COURSE_MANAGEMENT",
  },

  {
    path: "/marketing/manage-courses/update/:id",
    name: "course-manage-form-update",
    exact: true,
    component: CourseManageForm,
    authMenuName: "COURSE_MANAGEMENT",
  },

  {
    path: "/marketing/promotion",
    name: "promotion",
    exact: true,
    component: Promotion,
    authMenuName: ENUM_MENU.PROMOTION,
  },
  {
    path: "/marketing/promotion/detail/:id",
    name: "promotion-detail",
    exact: true,
    component: PromotionDetail,
    authMenuName: ENUM_MENU.PROMOTION,
  },
  {
    path: "/marketing/promotion/manage/create",
    name: "promotion-create",
    exact: true,
    component: ManagePromotion,
    authMenuName: ENUM_MENU.PROMOTION,
  },

  {
    path: "/marketing/promotion/:id",
    name: "promotion-edit",
    exact: true,
    component: ManagePromotion,
    authMenuName: ENUM_MENU.PROMOTION,
  },

  {
    path: "/marketing/promotion/duplicate/:duplicateId",
    name: "promotion-duplicate",
    exact: true,
    component: ManagePromotion,
    authMenuName: ENUM_MENU.PROMOTION,
  },

  {
    path: "/booking/medical-room-reservation",
    name: "medical-room-reservation",
    exact: true,
    component: MedicalRoomReservation,
    authMenuName: "QUE_MEDICAL",
  },
  {
    path: "/booking/medical-room-reservation/overview",
    name: "medical-room-reservation-overview",
    exact: true,
    component: SlotsOverViewMedicalRoomReservation,
    authMenuName: "QUE_MEDICAL",
  },
  {
    path: "/booking/book-another-room",
    name: "booking-another-room",
    exact: true,
    component: BookingAnotherRoom,
    authMenuName: "QUE_PROCEDURE",
  },
  {
    path: "/booking/book-another-room/overview",
    name: "booking-another-room-overview",
    exact: true,
    component: SlotsOverViewBookingAnotherRoom,
    authMenuName: "QUE_PROCEDURE",
  },

  {
    path: "/booking/notification",
    name: "notification",
    exact: true,
    component: Notification,
    authMenuName: "NOTIFICATION",
  },

  {
    path: "/booking/notification/create",
    name: "notification-create",
    exact: true,
    component: CreateNotification,
    authMenuName: "NOTIFICATION",
  },

  {
    path: "/booking/notification/:id",
    name: "notification-edit",
    exact: true,
    component: CreateNotification,
    authMenuName: "NOTIFICATION",
  },

  {
    path: "/doctorroom",
    name: "doctorroom",
    exact: true,
    component: DoctorRoom,
    authMenuName: "MEDICAL",
  },

  {
    path: "/doctorroom/create-order/appointments/:appointmentsID/visit/:visitID/patients/:patientsID",
    name: "create-order",
    exact: true,
    component: CreateOrderDoctorRoom,
    authMenuName: "MEDICAL",
  },

  {
    path: "/doctor-room/order-info/visit/:visitId",
    name: "create-order",
    exact: true,
    component: OrderInfoDoctorRoom,
    authMenuName: "MEDICAL",
  },

  {
    path: "/doctorroom/comment/appointments/:appointmentsID/visit/:visitID/patient/:patientID",
    name: "comment",
    exact: true,
    component: CommentDoctor,
    authMenuName: "MEDICAL",
  },

  {
    path: "/physician-assistant/confirm-order",
    name: "confirm-order",
    exact: true,
    component: ConfirmOrder,
    authMenuName: "CONFIRM_ORDER",
  },

  {
    path: "/physician-assistant/confirm-order/order-list/appointments/:appointmentsID/patient/:patientID/visit/:visitID",
    name: "confirm-order/order-list",
    exact: true,
    component: ConfirmOrderList,
    authMenuName: "CONFIRM_ORDER",
  },

  {
    path: "/physician-assistant/confirm-order/order-list/appointments/:appointmentsID/patient/:patientID/visit/:visitID/report",
    name: "confirm-order/order-list/report",
    exact: true,
    component: ReportConfirmOrder,
    authMenuName: "CONFIRM_ORDER",
  },

  {
    path: "/physician-assistant/execute-order",
    name: "execute-order",
    exact: true,
    component: ExecuteOrder,
    authMenuName: "PROCESS_ORDER",
  },

  {
    path: "/physician-assistant/execute-order/order-list/patient/:patientID/visit/:visitID",
    name: "execute-order/order-list",
    exact: true,
    component: ExecuteOrderList,
    authMenuName: "PROCESS_ORDER",
  },

  {
    path: "/physician-assistant/execute-order/document/patient/:patientID/visit/:visitID",
    name: "execute-order-document",
    exact: true,
    component: DocumentExecuteOrder,
    authMenuName: "PROCESS_ORDER",
  },

  {
    path: "/nurse-room/inchart",
    name: "inchart",
    exact: true,
    component: Inchart,
    authMenuName: "INCHARGE",
  },
  {
    path: "/nurse-room/inchart/wait-detail/patient/:patientID/visit/:visitID",
    name: "inchart/detail",
    exact: true,
    component: FormWaitInchart,
    authMenuName: "INCHARGE",
  },

  {
    path: "/nurse-room/inchart/between-detail/patient/:patientID/visit/:visitID",
    name: "inchart/detail",
    exact: true,
    component: FormBetweenInchart,
    authMenuName: "INCHARGE",
  },

  {
    path: "/nurse-room/lab",
    name: "lab",
    exact: true,
    component: Lab,
    authMenuName: "LAB",
  },

  {
    path: "/nurse-room/lab/detail/patient/:patientID/visit/:visitID",
    name: "lab/detail",
    exact: true,
    component: FormLab,
    authMenuName: "LAB",
  },

  {
    path: "/nurse-room/interface-lab",
    name: "interface-lab",
    exact: true,
    component: InterfaceLab,
    authMenuName: "INTERFACE_LAB",
  },
  {
    path: "/nurse-room/interface-lab/detail/visit/:visitID",
    name: "interface-lab/detail",
    exact: true,
    component: FormInterfaceLab,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/interface-lab/print-label/visit/:visitID",
    name: "interface-lab/print-label",
    exact: true,
    component: PrintLabelLabInterface,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/interface-lab/list/patient/:patientID",
    name: "lab/detail/list",
    exact: true,
    component: LabList,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/interface-lab/list/detail/patient/:patientID/visit/:visitID",
    name: "lab/detail/list",
    exact: true,
    component: DetailLabInterface,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/interface-lab/list/detail/print-label/visit/:visitID",
    name: "lab/detail/list",
    exact: true,
    component: PrintLabelDetailLabInterface,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/interface-lab/list/detail/lab-result/patient/:patientID/visit/:visitID",
    name: "lab/detail/lab-result",
    exact: true,
    component: DetailLabResult,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/interface-lab/list/detail/lab-result/print/patient/:patientID/visit/:visitID",
    name: "lab/detail/lab-result/print",
    exact: true,
    component: PrintDetailLabResult,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/interface-lab/list/compare-lab/patient/:patientID",
    name: "lab/detail/compare-lab",
    exact: true,
    component: CompareLab,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/interface-lab/list/compare-lab/print/patient/:patientID",
    name: "lab/detail/compare-lab-print",
    exact: true,
    component: PrintCompare,
    authMenuName: "INTERFACE_LAB",
  },

  {
    path: "/nurse-room/procedure",
    name: "procedure",
    exact: true,
    component: Procedure,
    authMenuName: "PROCEDURE",
  },
  {
    path: "/nurse-room/procedure/detail/patient/:patientID/visit/:visitID",
    name: "procedure/detail",
    exact: true,
    component: FormProcedure,
    authMenuName: "PROCEDURE",
  },

  {
    path: "/nurse-room/procedure/detail/report/visit/:visitID",
    name: "nurseRoomReportProcedure",
    exact: true,
    component: NurseRoomReportProcedure,
    authMenuName: "PROCEDURE",
  },
  {
    path: "/report-procedure",
    name: "reportProcedure",
    exact: true,
    component: ReportProcedure,
    authMenuName: "REPORT_PROCEDURE",
  },

  {
    path: "/report-procedure/visit-all/patient/:patientID",
    name: "reportProcedureVisitAll",
    exact: true,
    component: ReportProcedureVisitAll,
    authMenuName: "REPORT_PROCEDURE",
  },
  {
    path: "/report-procedure/visit-all/detail/visit/:visitID",
    name: "reportProcedureDetail",
    exact: true,
    component: ReportProcedureDetail,
    authMenuName: "REPORT_PROCEDURE",
  },
  {
    path: "/pain-management",
    name: "pain-management",
    exact: true,
    component: PainManagement,
    authMenuName: "PHYSICAL_THERAPY",
  },
  {
    path: "/pain-management/detail/patient/:patientID/visit/:visitID",
    name: "pain-management/detail",
    exact: true,
    component: FormPainManagement,
    authMenuName: "PHYSICAL_THERAPY",
  },

  {
    path: "/pharmacy-room/dispense-medicine",
    name: "dispense-medicine",
    exact: true,
    component: DispenseMedicine,
    authMenuName: "DISPENSE_MEDICINE",
  },

  {
    path: "/pharmacy-room/dispense-medicine/detail/patient/:patientID/visit/:visitID",
    name: "dispense-medicine-detail",
    exact: true,
    component: FormDispenseMedicine,
    authMenuName: "DISPENSE_MEDICINE",
  },

  {
    path: "/pharmacy-room/dispense-medicine/report-drug/patient/:patientID",
    name: "report-drug",
    exact: true,
    component: ReportDrug,
    authMenuName: "DISPENSE_MEDICINE",
  },

  {
    path: "/pharmacy-room/return-medicine",
    name: "return-medicine",
    exact: true,
    component: ReturnMedicine,
    authMenuName: "RETURN_MEDICINE",
  },

  {
    path: "/pharmacy-room/return-medicine/detail/patient/:patientID/visit/:visitID",
    name: "return-medicine-detail",
    exact: true,
    component: FormReturnMedicine,
    authMenuName: "RETURN_MEDICINE",
  },

  {
    path: "/pharmacy-room/report-drug",
    name: "/pharmacy-room/report-drug",
    exact: true,
    component: ReportDrugMedicine,
    authMenuName: "MEDICINE_REPORT",
  },

  {
    path: "/pharmacy-room/report-drug/detail/patient/:patientID",
    name: "report-drug",
    exact: true,
    component: ReportDrugMedicineDetail,
    authMenuName: "MEDICINE_REPORT",
  },

  {
    path: "/financial-room/pay",
    name: "pay",
    exact: true,
    component: Pay,
    authMenuName: "PAY",
  },
  {
    path: "/financial-room/pay/list-tabs/patient/:patientID",
    name: "pay-list-tabs",
    exact: true,
    component: PayListTab,
    authMenuName: "PAY",
  },
  {
    path: "/financial-room/pay/list-tabs/before-bill/create-billing-notes/:billingID/patient/:patientID",
    name: "pay-list-tabs-bill-create-invoice",
    exact: true,
    component: CreateBill,
    authMenuName: "PAY",
  },
  {
    path: "/financial-room/pay/list-tabs/billing-notes/create-invoice/:billingID/patient/:patientID",
    name: "pay-list-tabs-bill-create-invoice",
    exact: true,
    component: BillCreateInvoice,
    authMenuName: "PAY",
  },

  {
    path: "/financial-room/pay/list-tabs/billing-note/:billingID/patient/:patientID",
    name: "pay-list-tabs-bill-edit",
    exact: true,
    component: BillCreateInvoice,
    authMenuName: "PAY",
  },

  {
    path: "/financial-room/pay/list-tabs/billing-note/report/:billingID",
    name: "pay-list-tabs-bill-edit",
    exact: true,
    component: BillReport,
    authMenuName: "PAY",
  },
  {
    path: "/financial-room/pay/list-tabs/create-billing-notes/report/:billingID",
    name: "pay-list-tabs-bill-create",
    exact: true,
    component: BillCreateReport,
    authMenuName: "PAY",
  },

  {
    path: "/financial-room/pay/list-tabs/invoice/:invoiceID/create-receipt-full-amount/patient/:patientID",
    name: "pay-list-tabs-invoice-create-receipt",
    exact: true,
    component: InvoiceCreateReceipt,
    authMenuName: "PAY",
  },

  {
    path: "/financial-room/pay/list-tabs/invoice/:invoiceID/create-receipt-split-up/patient/:patientID",
    name: "pay-list-tabs-invoice-create-receipt",
    exact: true,
    component: InvoiceCreateReceipt,
    authMenuName: "PAY",
  },

  {
    path: "/financial-room/pay/list-tabs/invoice/:invoiceID/patient/:patientID",
    name: "pay-list-tabs-invoice-edit",
    exact: true,
    component: InvoiceCreateReceipt,
    authMenuName: "PAY",
  },
  {
    path: "/financial-room/pay/list-tabs/invoice/report/:invoiceID",
    name: "pay-list-tabs-bill-edit",
    exact: true,
    component: InvoiceReport,
    authMenuName: "PAY",
  },

  {
    path: "/financial-room/pay/list-tabs/receipt/:receiptID/patient/:patientID",
    name: "pay-list-tabs-receipt-edit",
    exact: true,
    component: Receipt,
    authMenuName: "PAY",
  },

  {
    path: "/financial-room/pay/list-tabs/receipt/report/:receiptID",
    name: "pay-list-tabs-bill-edit",
    exact: true,
    component: ReceiptReport,
    authMenuName: "PAY",
  },

  {
    path: "/financial-room/pay/detail/patient/:patientID/visit/:visitID",
    name: "pay-detail",
    exact: true,
    component: PayDetail,
    authMenuName: "PAY",
  },
  {
    path: "/financial-room/transfer-course",
    name: "transfer-course",
    exact: true,
    component: TransferCourse,
    authMenuName: "TRANSFER_COURSE",
  },

  {
    path: "/financial-room/transfer-course/detail/patient/:patientID",
    name: "transfer-course-detail",
    exact: true,
    component: TransferCourseDetail,
    authMenuName: "TRANSFER_COURSE",
  },

  {
    path: "/financial-room/return-course",
    name: "return-course",
    exact: true,
    component: ReturnCourse,
    authMenuName: "RETURN_COURSE",
  },

  {
    path: "/financial-room/return-course/detail/patient/:patientID",
    name: "return-course-detail",
    exact: true,
    component: ReturnCourseDetail,
    authMenuName: "RETURN_COURSE",
  },

  {
    path: "/financial-room/return-course/report/:id",
    name: "return-course-report",
    exact: true,
    component: ReturnCourseReport,
    authMenuName: "RETURN_COURSE",
  },

  {
    path: "/financial-room/return-course/history-list/patient/:patientID",
    name: "return-course-history-list",
    exact: true,
    component: ReturnCourseHistoryList,
    authMenuName: "RETURN_COURSE",
  },

  {
    path: "/financial-room/sign-course",
    name: "sign-course",
    exact: true,
    component: SignCourse,
    authMenuName: "ASSIGN_COURSE",
  },

  {
    path: "/financial-room/sign-course/detail/patient/:patientID",
    name: "sign-course-detail",
    exact: true,
    component: SignCourseDetail,
    authMenuName: "ASSIGN_COURSE",
  },

  {
    path: "/setting/general",
    name: "general",
    exact: true,
    component: General,
    authMenuName: "GENERAL",
  },
  {
    path: "/setting/prefix/create",
    name: "create-prefix",
    exact: true,
    component: CreatePrefix,
    authMenuName: "GENERAL",
  },
  {
    path: "/setting/nationality/create",
    name: "create-nationality",
    exact: true,
    component: CreateNationality,
    authMenuName: "GENERAL",
  },
  {
    path: "/setting/citizenship/create",
    name: "create-citizenship",
    exact: true,
    component: CreateCitizenship,
    authMenuName: "GENERAL",
  },
  {
    path: "/setting/country/create",
    name: "create-country",
    exact: true,
    component: CreateCountry,
    authMenuName: "GENERAL",
  },

  {
    path: "/setting/contract-company/create",
    name: "create-contract-company",
    exact: true,
    component: FormContractCompany,
    authMenuName: "GENERAL",
  },

  {
    path: "/setting/contract-company/:id",
    name: "create-contract-company",
    exact: true,
    component: ManageContractCompany,
    authMenuName: "GENERAL",
  },

  {
    path: "/setting/agent/create",
    name: "create-agent",
    exact: true,
    component: FormAgent,
    authMenuName: "GENERAL",
  },

  {
    path: "/setting/agent/:id",
    name: "create-agent",
    exact: true,
    component: FormAgentUpdate,
    authMenuName: "GENERAL",
  },

  {
    path: "/setting/correct/general/:id",
    name: "correct-general",
    exact: true,
    component: CorrectGeneral,
    authMenuName: "GENERAL",
  },

  {
    path: "/setting/ware-house",
    name: "ware-house",
    exact: true,
    component: Warehouse,
    authMenuName: "WAREHOUSE",
  },

  {
    path: "/setting/ware-house/upload-file",
    name: "ware-house-upload-file",
    exact: true,
    component: WareHouseUploadFile,
    authMenuName: "WAREHOUSE-EDIT",
  },
  {
    path: "/setting/ware-house/create/purchase-order",
    name: "create-purchase-order",
    exact: true,
    component: ManagePurchaseOrder,
    authMenuName: "WAREHOUSE-EDIT",
  },

  {
    path: "/setting/ware-house/manage/purchase-order/:id",
    name: "manage-purchase-order",
    exact: true,
    component: ManagePurchaseOrder,
    authMenuName: "WAREHOUSE-EDIT",
  },

  {
    path: "/setting/ware-house/manage/pick-up-goods/:id",
    name: "manage-pick-up-goods",
    exact: true,
    component: ManagePickUpGoods,
    authMenuName: "WAREHOUSE-EDIT",
  },

  {
    path: "/setting/ware-house/manage/bounce-goods/:id",
    name: "manage-bounce-goods",
    exact: true,
    component: ManageBounceGoods,
    authMenuName: "WAREHOUSE-EDIT",
  },

  {
    path: "/setting/branch-management",
    name: "branch-management",
    exact: true,
    component: BranchManagement,
    authMenuName: "BRANCH_MANAGEMENT",
  },

  {
    path: "/setting/branch-management/manage",
    name: "branch-management/manage",
    exact: true,
    component: ManageBranchManagement,
    authMenuName: "BRANCH_MANAGEMENT",
  },

  {
    path: "/setting/branch-management/manage/:branchId",
    name: "branch-management/manage",
    exact: true,
    component: ManageBranchManagement,
    authMenuName: "BRANCH_MANAGEMENT",
  },

  {
    path: "/user-manage",
    name: "user-manage",
    exact: true,
    component: UserManage,
    authMenuName: "USER",
  },

  {
    path: "/user-manage/create-user",
    name: "create-user",
    exact: true,
    component: UserCreateManage,
    authMenuName: "USER",
  },

  {
    path: "/user-manage/update-user/:id",
    name: "update-user",
    exact: true,
    component: UserCreateManage,
    authMenuName: "USER",
  },

  {
    path: "/user-manage/create-role",
    name: "create-role",
    exact: true,
    component: RoleCreateManage,
    authMenuName: "USER-EDIT",
  },

  {
    path: "/user-manage/update-role/:id",
    name: "update-role",
    exact: true,
    component: RoleCreateManage,
    authMenuName: "USER-EDIT",
  },

  {
    path: "/user-manage/create-room",
    name: "create-room",
    exact: true,
    component: RoomManage,
    authMenuName: "USER-EDIT",
  },

  {
    path: "/user-manage/update-room/:id",
    name: "update-room",
    exact: true,
    component: RoomManage,
    authMenuName: "USER-EDIT",
  },

  {
    path: "/user-manage/create-user-room",
    name: "create-user-room",
    exact: true,
    component: UserRoomManage,
    authMenuName: "USER-EDIT",
  },

  {
    path: "/user-manage/update-user-room/:id",
    name: "update-user-room",
    exact: true,
    component: UserRoomManage,
    authMenuName: "USER-EDIT",
  },

  {
    path: "/medical-document",
    name: "medical-document",
    exact: true,
    component: MedicalDocument,
    authMenuName: "DOCUMENT",
  },

  {
    path: "/medical-document/create",
    name: "medical-document-create",
    exact: true,
    component: FormMedicalDocument,
    authMenuName: "DOCUMENT",
  },

  {
    path: "/medical-document/type",
    name: "medical-document-type",
    exact: true,
    component: TypeMedicalDocument,
    authMenuName: "DOCUMENT",
  },

  {
    path: "/medical-document/type/create",
    name: "medical-document-type-create",
    exact: true,
    component: FormTypeMedicalDocument,
    authMenuName: "DOCUMENT",
  },

  {
    path: "/medical-document/type/:id",
    name: "medical-document-type-edit",
    exact: true,
    component: FormTypeMedicalDocument,
    authMenuName: "DOCUMENT",
  },

  {
    path: "/report-summary",
    name: "report-summary",
    exact: true,
    component: ReportSummary,
    authMenuName: "REPORT",
  },

  {
    path: "/report-summary/product-report",
    name: "product-report",
    exact: true,
    component: ProductReport,
    authMenuName: "REPORT",
  },

  {
    path: "/report-summary/purchase-report",
    name: "purchase-report",
    exact: true,
    component: PurchaseReport,
    authMenuName: "REPORT",
  },

  {
    path: "/report-summary/operating-room-report",
    name: "operating-room-report",
    exact: true,
    component: OperatingRoomReport,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/agent-report",
    name: "agent-report",
    exact: true,
    component: AgentReport,
    authMenuName: "REPORT",
  },
  //** new */
  {
    path: "/report-summary/receipt-report",
    name: "receipt-report",
    exact: true,
    component: ReceiptReportSummary,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/invoice-report",
    name: "invoice-report",
    exact: true,
    component: InvoiceReportSummary,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/patient-report",
    name: "patient-report",
    exact: true,
    component: PatientReport,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/branch-report",
    name: "branch-report",
    exact: true,
    component: BranchReport,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/product/purchase-report",
    name: "product-purchase-report",
    exact: true,
    component: ProductPurchaseReport,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/product/purchase/hn-report",
    name: "product-purchase/hn-report",
    exact: true,
    component: ProductPurchaseHnReport,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/product-procedure/purchase-report",
    name: "product-procedure-purchase-report",
    exact: true,
    component: ProductProcedurePurchaseReport,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/product-procedure-report",
    name: "product-procedure-report",
    exact: true,
    component: ProductProcedureReport,
    authMenuName: "REPORT",
  },
  {
    path: "/report-summary/product-promotion/purchase-report",
    name: "product-promotion-purchase-report",
    exact: true,
    component: ProductPromotionPurchaseReport,
    authMenuName: "REPORT",
  },

  //** new */
  {
    path: "/setting-profile",
    name: "setting-profile",
    exact: true,
    component: SettingProfile,
    authMenuName: "NOPAGE",
  },

  {
    path: "/*",
    name: "nopage",
    exact: true,
    component: NoPage,
    authMenuName: "NOPAGE",
  },
];
