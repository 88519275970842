import { FC } from "react";
import { onImgLogoError } from "@tools/util";
import { Image, ImageProps } from "antd";

interface Props extends ImageProps {}

const ImageLogo: FC<Props> = ({ preview = false, ...props }) => {
  return (
    <Image
      {...props}
      preview={preview}
      src={props?.src || onImgLogoError()}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = onImgLogoError();
      }}
    />
  );
};

export default ImageLogo;
