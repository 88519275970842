import { useState } from "react";
import { onImgError } from "@tools/util";
import { DeleteOutlined, EyeOutlined } from "@ant-design/icons";
import { Col, Image, ImageProps, Row } from "antd";

interface Props extends ImageProps {
  deleteImage: (e: string | number) => void;
  idItem: string | number;
}

const CImage = ({ deleteImage, idItem, ...props }: Props) => {
  const [visible, setVisible] = useState(false);
  return (
    <Image
      {...props}
      src={props?.src || onImgError()}
      preview={{
        visible: visible,
        onVisibleChange: (e, prevValue) => {
          if (prevValue) {
            setVisible(e);
          }
        },
        mask: (
          <Row gutter={[14, 14]}>
            <Col>
              <EyeOutlined onClick={() => setVisible(!visible)} />
            </Col>
            <Col>
              <DeleteOutlined
                onClick={() => deleteImage(idItem)}
                style={{ color: "white" }}
              />
            </Col>
          </Row>
        ),
      }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = onImgError();
      }}
    />
  );
};

export default CImage;
