import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useMainMedicineDoctorRoom } from "@provider/medicine_doctor_room";
import { Button, Empty, Popover, Table } from "antd";
import { useUpdateWorkOrderItemRejectDrugsCancel } from "@services/api/work_order_item";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import empty from "@assets/img/empty.svg";
import Modaler from "@components/modaler/Modaler";
import TableOrderList from "@components/draft_order/order_list/OrderList";

type Props = {
  InfoManagerModal: () => void;
  orderItemRejectDrug: any;
};

const ReturnOrder = ({ InfoManagerModal, orderItemRejectDrug }: Props) => {
  const { t, i18n } = useTranslation();
  const { buttonAdd, medicalRecord, status, eventId, draftOrderList } =
    useMainMedicineDoctorRoom();
  const [visible, setVisible] = useState(false);
  const [rejectDrug, setRejectDrug] = useState<number>();
  const client = useQueryClient();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const patientId = match.params.patientsID || match.params.patientID;
  const updateWorkOrderItemRejectDrugsCancel =
    useUpdateWorkOrderItemRejectDrugsCancel();

  const onModal = () => {
    setVisible(!visible);
  };

  const handleDeleteRejectDrug = () => {
    updateWorkOrderItemRejectDrugsCancel.mutate(
      {
        id: visitID,
        data: {
          ids: [rejectDrug],
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
          onModal();
          client.invalidateQueries([
            "get-work-order-item-reject-drugs-format",
            visitID,
          ]);
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
        },
      }
    );
  };

  const onDelete = () => {
    handleDeleteRejectDrug();
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, data: any, index: number) => {
        return <div key={index}> {data?.level === 1 ? index + 1 : ""}</div>;
      },
    },

    {
      title: t("orderNumber"),
      dataIndex: "name",
      key: "name",
      render: (_: any, data: any, index: number) => {
        if (data?.level === 1) {
          return <div key={data?.key}>{data?.name}</div>;
        } else if (data?.level === 2) {
          return <div key={index}>{t("drugName")}</div>;
        } else {
          return <div key={index}>{data?.product?.nameTh}</div>;
        }
      },
    },

    {
      title: "",
      dataIndex: "rejectBy",
      key: "rejectBy",
      align: "center",
      render: (editBy: any, data: any, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? `${t("doctor")} ${t("name")}`
              : `${
                  i18n.language === "th"
                    ? [editBy?.firstNameTh, editBy?.lastNameTh]
                        ?.join(" ")
                        ?.trim()
                    : [editBy?.firstNameEn, editBy?.lastNameEn]
                        ?.join(" ")
                        ?.trim()
                }
           `}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",

      render: (date: string, data: any, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("date")
              : dayjs(date).format("DD-MM-YYYY")}
          </div>
        );
      },
    },

    {
      title: t("totalNumber"),
      dataIndex: "data",
      key: "data",
      align: "center",

      render: (_: any, data: any, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length
              : data?.level === 2
              ? t("amount")
              : `${data?.qty}  `}
          </div>
        );
      },
    },
    {
      title: t("details"),
      dataIndex: "data",
      key: "data",
      align: "center",

      render: (_: any, data: any, index: number) => {
        const options = JSON.parse(data?.order?.orderItems[0]?.option || `{}`);

        return (
          <div key={index}>
            {data?.level === 1 ? (
              "-"
            ) : data?.level === 2 ? (
              t("details")
            ) : (
              <Popover
                content={
                  <div>
                    <div>
                      {`${t("startMedication")}    ${
                        options.dateStart
                          ? dayjs(options.dateStart).format("DD-MM-YYYY")
                          : ""
                      } `}
                    </div>
                    <div>
                      {` ${t("stopMedicine")}  ${
                        options.dateStop
                          ? dayjs(options.dateStop).format("DD-MM-YYYY")
                          : ""
                      }`}
                    </div>

                    <div>
                      {`${t("medicineExp")}  ${
                        options.drugExpiration
                          ? dayjs(options.drugExpiration).format("MM-YYYY")
                          : ""
                      }`}
                    </div>
                  </div>
                }
                title={t("details")}
                trigger="click"
              >
                <Button type="primary">{t("clickMe")}</Button>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "product",
      key: "product",
      align: "center",

      render: (_: any, data: any, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 ? (
              t("manage")
            ) : (
              <DeleteOutlined
                onClick={(e) => {
                  setRejectDrug(data?.id);
                  onModal();
                  e.stopPropagation();
                }}
              />
            )}
          </div>
        );
      },
    },
  ];
  return (
    <>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {t("areYouSure")}
          </div>
        }
      >
        {t("doYouWantToDelete")}
      </Modaler>
      <div>
        {status === "return_drug" ? (
          <Table
            className="components-table-demo-nested table-selection table-confirm "
            locale={{
              emptyText: (
                <>
                  <Empty
                    image={empty}
                    imageStyle={{
                      height: 60,
                    }}
                    description={
                      <span className="t-grey-white">{t("noInformation")}</span>
                    }
                  >
                    {medicalRecord ? (
                      <Button type="primary" onClick={InfoManagerModal}>
                        {buttonAdd && `+ ${t(buttonAdd)} `}
                      </Button>
                    ) : null}
                  </Empty>
                </>
              ),
            }}
            dataSource={orderItemRejectDrug}
            columns={columns}
            expandRowByClick
            showSorterTooltip
            rowKey={"key"}
            pagination={false}
            scroll={{ x: "calc(100vw -20%)" }}
          />
        ) : (
          <TableOrderList
            patientID={patientId}
            draftOrderList={draftOrderList}
            createNow={InfoManagerModal}
            medicalRecord={medicalRecord}
            keyData="optionDrug"
            eventId={eventId}
          />
        )}
      </div>
    </>
  );
};

export default ReturnOrder;
