import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Col, Row, Card } from "antd";
import TableDispenseMedicine from "./Table";
import SearchDispenseMedicine from "./Search";

const DispenseMedicine = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslation();

  const visitFilter = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("dispenseMedicine.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("nursery")}</b>
          </span>
        </Col>
        <Col lg={2} className="justify-col"></Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("allNursery")}</b>}
        >
          <SearchDispenseMedicine onFinish={visitFilter} />
          <TableDispenseMedicine
            searchValue={searchValue}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card>
      </div>
    </div>
  );
};

export default DispenseMedicine;
