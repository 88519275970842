import { SETTING } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { generateUniqueId } from "@tools/util";
import { openNotification } from "@components/notification/Notification";
import { MinusCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useCreateInsurancesList } from "@services/api/insurances";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Button, Card, Col, Form, Input, Row } from "antd";
import Header from "./Header";

interface IFormField {
  nameEn: string;
  nameTh: string;
  code: string;
  key: string;
}

const FormContractCompany = () => {
  const [contract] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [fields, setFields] = useState<IFormField[]>([]);
  const uniqueId = generateUniqueId();
  const createInsurances = useCreateInsurancesList();

  useEffect(() => {
    const initial: IFormField = {
      nameTh: `nameTh_${uniqueId}`,
      nameEn: `nameEn_${uniqueId}`,
      code: `code_${uniqueId}`,
      key: `${uniqueId}`,
    };
    setFields([...(fields || []), initial]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinish = (rest: any) => {
    let obj: { nameTh?: string; code?: string; key: string }[] = [];
    for (const [key, value] of Object.entries(rest)) {
      const prefix = key?.split("_")?.[0];
      const keyName = key?.split("_")?.[1];

      const find = obj?.some((e) => e.key === keyName);
      if (find) {
        obj = obj?.map((item) => {
          if (item?.key === keyName) {
            return { ...item, [prefix]: value };
          }
          return item;
        });
      } else {
        obj = [...obj, { key: keyName, [prefix]: value }];
      }
    }
    handleCreateInsurances(obj);
  };

  const handleCreateInsurances = async (values: any) => {
    createInsurances.mutate(values, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.replace(`${SETTING.title}`);
      },
      onError: () => {
        openNotification({ method: "POST_ERROR" });
      },
    });
  };

  const addField = () => {
    const name = generateUniqueId();
    const next: IFormField[] = [
      ...fields,
      {
        nameTh: `nameTh_${name}`,
        code: `code_${name}`,
        nameEn: `nameEn_${name}`,
        key: `${name}`,
      },
    ];
    setFields(next);
  };

  const deleteField = (_field: IFormField) => {
    const next = fields?.filter((item) => {
      return item?.nameTh !== _field?.nameTh;
    });

    setFields(next);
  };
  return (
    <div>
      <Header confirm={contract} />
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={<b className="fs-16 t-black-light ">{t("contractCompany")}</b>}
        >
          <Card
            className="card-prefix card-prefix-width card-prefix-chi"
            extra={
              <b className="fs-14 c-white ">{t("createContractCompany")}</b>
            }
          >
            <Form onFinish={onFinish} name="contract" form={contract}>
              <Row gutter={[0, 12]}>
                <Col lg={24}>
                  {fields?.map((item, index) => {
                    return (
                      <div key={index} className="mb-1">
                        <Row gutter={[12, 12]}>
                          <Col lg={24}>
                            <Form.Item
                              label={t("code")}
                              name={item?.code}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={11}>
                            <Form.Item
                              label={`${t("contractingCompanyName")} (TH)`}
                              name={item?.nameTh}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={11}>
                            <Form.Item
                              label={`${t("contractingCompanyName")} (EN)`}
                              name={item?.nameEn}
                              rules={[{ required: true }]}
                            >
                              <Input />
                            </Form.Item>
                          </Col>

                          <Col lg={2} className="mt-3">
                            {fields?.length > 1 ? (
                              <MinusCircleFilled
                                className="minusCircle"
                                onClick={(e) => deleteField(item)}
                                key={index}
                              />
                            ) : (
                              <MinusCircleFilled
                                className="minusCircle-nodelete"
                                key={index}
                              />
                            )}
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                  <Col lg={22}>
                    <div className="mb-1">
                      <Button
                        className="add-prefix"
                        type="dashed"
                        ghost
                        onClick={() => addField()}
                      >
                        {`+ ${t("add")}`}
                      </Button>
                    </div>
                  </Col>
                </Col>
              </Row>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default FormContractCompany;
