import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAssignBranch } from "@services/api/patient";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { useMainTransferDetail } from "@provider/transfer_detail";
import { RouteComponentProps, useHistory } from "react-router-dom";
import React from "react";
import BackHome from "@components/button/BackHome";
import ModalSent from "./ModalSent";
import RoleContext from "@provider/RoleProvider";

const Header = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { form, send, setSend, branchId } = useMainTransferDetail();
  const assignBranch = useAssignBranch();
  const match: any = useRouteMatch();
  const pathID = match.params.patientID;
  const { t } = useTranslation();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "FOREWORD_MEDICAL_RECORD";
  });

  const handleCancel = () => {
    setSend(false);
  };

  const handleOk = () => {
    assignBranch.mutate(
      { id: pathID, data: { branchId: branchId } },
      {
        onSuccess: () => {
          history.replace("/profile/transfer");
          openNotification({ method: "PUT_SUCCESS" });
        },
      }
    );
    setSend(true);
  };
  return (
    <React.Fragment>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("referral")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-normal">{t("historyFile")}</span>
            </Col>
          </Row>
        </Col>
        <Col className=" justify-col">
          {medicalRecord?.[0]?.isEdit ? (
            <Button
              className="btn-create mr-05"
              htmlType="submit"
              onClick={() => form.submit()}
            >
              {`${t("admitted")}`}
            </Button>
          ) : null}
        </Col>
      </Row>
      {send && (
        <ModalSent
          visible={send}
          handleOk={handleOk}
          handleCancel={handleCancel}
        />
      )}
    </React.Fragment>
  );
};

export default Header;
