import { IGetRoom } from "@interface/IRoom.interface";
import { ViewState } from "@devexpress/dx-react-scheduler";
import { Resources } from "@devexpress/dx-react-scheduler-material-ui";
import { Scheduler } from "@devexpress/dx-react-scheduler-material-ui";
import { AllDayPanel } from "@devexpress/dx-react-scheduler-material-ui";
import { slotsDetail } from "@assets/data/pathRouter";
import { TodayButton } from "@devexpress/dx-react-scheduler-material-ui";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { GroupingPanel } from "@devexpress/dx-react-scheduler-material-ui";
import { GroupingState } from "@devexpress/dx-react-scheduler";
import { DateNavigator } from "@devexpress/dx-react-scheduler-material-ui";
import { useTranslation } from "react-i18next";
import { Button, Tooltip } from "antd";
import { Toolbar, DayView } from "@devexpress/dx-react-scheduler-material-ui";
import { AppointmentModel } from "@devexpress/dx-react-scheduler";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import { IntegratedGrouping } from "@devexpress/dx-react-scheduler";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import Group from "@assets/img/icon/group.svg";

type Props = {
  dataScheduler: AppointmentModel[];
  roomList: IGetRoom[];
  changeCurrentDateChange: (date: any) => void;
};

const Fields = ({
  dataScheduler = [],
  roomList,
  changeCurrentDateChange,
}: Props) => {
  const { t, i18n } = useTranslation();
  const priorityData = [{ text: "", id: 1 }];
  const history = useHistory<RouteComponentProps["history"]>();

  const resources = [
    {
      fieldName: "roomId",
      title: t("room"),
      instances: roomList?.length ? roomList : priorityData,
    },
  ];

  const grouping = [
    {
      resourceName: "roomId",
    },
  ];

  const allDayLocalizationMessages: any = {
    "th-TH": {
      allDay: `${t("doctorroom")} \n ${t("time")}`,
    },
  };

  const FlexibleSpace = ({ restProps }: any) => {
    return (
      <Toolbar.FlexibleSpace {...restProps}>
        {/* <SearchFunctionAll doctorList={doctorList} /> */}
      </Toolbar.FlexibleSpace>
    );
  };
  const viewDay = () => {
    return null;
  };

  const getAllDayMessages = (locale: any) =>
    allDayLocalizationMessages?.[locale];

  const onFinish = (id: number | string) => {
    history.push(slotsDetail + `/slot/${id}`);
  };

  const header = ({
    children,
    appointmentData,
    classes,
    ...restProps
  }: any) => {
    return (
      <>
        {!appointmentData?.patientId && (
          <AppointmentTooltip.Header {...restProps}>
            <Tooltip title="">
              <Button
                className="icon-detail"
                type="primary"
                shape="circle"
                icon={
                  <img
                    className="slots-group"
                    width="20"
                    src={Group}
                    alt="group"
                  />
                }
                onClick={(e: any) => onFinish(appointmentData?.id)}
              />
            </Tooltip>
          </AppointmentTooltip.Header>
        )}
      </>
    );
  };

  const layerBody = ({ ...restProps }: any) => {
    const appointmentData = restProps?.appointmentData;
    return (
      <AppointmentTooltip.Content {...restProps}>
        <div className="appointmentTooltip mt-1 mb-1">
          <div>
            <span className="f-bold fs-16">{`${t("doctorName")} : `}</span>
            {i18n.language === "th"
              ? `${[
                  appointmentData?.slot?.user?.firstNameTh,
                  appointmentData?.slot?.user?.lastNameTh,
                ]
                  ?.join(" ")
                  ?.trim()}`
              : `${[
                  appointmentData?.slot?.user?.firstNameEn,
                  appointmentData?.slot?.user?.lastNameEn,
                ]
                  ?.join(" ")
                  ?.trim()}`}
          </div>
          <span className="f-bold fs-16">{`${t("name")} : `}</span>
          <span className="f-light fs-16">
            {i18n.language === "th"
              ? ` ${appointmentData?.patient?.firstNameTh || ""}  `
              : ` ${appointmentData?.patient?.firstNameEn || ""}  `}
          </span>
          <span className="f-bold fs-16">{`${t("lastName")} : `}</span>
          <span className="f-light fs-16">
            {i18n.language === "th"
              ? ` ${appointmentData?.patient?.lastNameTh || ""} `
              : ` ${appointmentData?.patient?.lastNameEn || ""} `}
          </span>
          <span className="f-bold fs-16">{`${t("phoneNumber")} : `}</span>
          <span className="f-light fs-16">{` ${appointmentData?.patient?.mobile} `}</span>
        </div>
      </AppointmentTooltip.Content>
    );
  };

  const AppointmentContent = ({
    classes,
    data,
    formatDate,
    ...restProps
  }: any) => {
    return (
      <Appointments.AppointmentContent
        {...restProps}
        formatDate={formatDate}
        data={data}
      >
        <div className="f-light">
          <div>
            {data?.title
              ? `${data?.title || ""}`
              : i18n.language === "th"
              ? data?.patientTh
              : data?.patientEn}
          </div>
          <div>{data?.slot?.room?.name}</div>
          <div>{data?.phone || ""}</div>
        </div>
      </Appointments.AppointmentContent>
    );
  };

  return (
    <div id="container" className="slot71">
      <Paper>
        <Scheduler
          locale={i18n.language === "th" ? "th-TH" : "en-US"}
          data={dataScheduler}
        >
          <ViewState onCurrentDateChange={changeCurrentDateChange} />

          <GroupingState grouping={grouping} />

          <DayView
            // dayScaleCellComponent={viewDay}
            dayScaleCellComponent={viewDay}
            startDayHour={8}
            endDayHour={19}
            cellDuration={15}
          />
          <AllDayPanel messages={getAllDayMessages("th-TH")} />
          <Appointments appointmentContentComponent={AppointmentContent} />
          <Resources data={resources} mainResourceName="roomId" />
          <IntegratedGrouping />

          <AppointmentTooltip
            headerComponent={header}
            contentComponent={layerBody}
          />
          <Toolbar flexibleSpaceComponent={FlexibleSpace} />
          <DateNavigator />
          <TodayButton />

          {/* <ViewSwitcher /> */}
          <GroupingPanel />
        </Scheduler>
      </Paper>
    </div>
  );
};

export default Fields;
