import { RouteComponentProps, useHistory } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";

const Form = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  return (
    <>
      <Header history={history} />
      <Fields />
    </>
  );
};

export default Form;
