import React, { useState } from "react";
import { ISearch } from "./interface";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { MEDICAL_DOCUMENT } from "@assets/data/pathRouter";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import BackHome from "@components/button/BackHome";
import RoleContext from "@provider/RoleProvider";
import TableTypeMedicalDocument from "./Table";
import SearchTypeMedicalDocument from "./Search";

const TypeMedicalDocument = () => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<ISearch>();
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "GENERAL";
  });

  const onFinish = (value: ISearch) => {
    setSearchValue(value);
  };

  const handleCreateTypeDocument = () => {
    history.push(`${MEDICAL_DOCUMENT.createType}`);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("medicalDocument.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("medicalDocument.title")} / `}
              </span>
              <span className="t-dark-blue f-normal">{t("type")}</span>
            </Col>
          </Row>
        </Col>
        <Col lg={10} className="d-flex justify-between justify-col">
          {medicalRecord?.[0]?.isEdit ? (
            <Button
              className="btn-create"
              type="primary"
              onClick={handleCreateTypeDocument}
            >
              <PlusOutlined className="icons-size" />
              {`${t("medicalDocument.type")}`}
            </Button>
          ) : null}
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey table-card"
          extra={<b className="header-card ">{t("medicalDocument.type")}</b>}
        >
          <SearchTypeMedicalDocument onFinish={onFinish} />
          <TableTypeMedicalDocument searchValue={searchValue} />
        </Card>
      </div>
    </div>
  );
};

export default TypeMedicalDocument;
