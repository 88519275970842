import { USER_MANAGE } from "@assets/data/pathRouter";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { useGetRoleForm } from "@services/api/role";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  formRole: FormInstance;
  roleID: number;
  history: RouteComponentProps["history"];
  medicalRecord: boolean;
};

const Header = ({ formRole, roleID, history, medicalRecord }: Props) => {
  const { t } = useTranslation();
  const roleByID = useGetRoleForm();
  const loadingCreate = useIsMutating(["create-role"]);
  const loadingUpdate = useIsMutating(["update-role-by-id"]);

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("userManage")}</h1>
              </div>
              <span className="t-light-gray">{t("role")} / </span>
              <span className="t-dark-blue f-normal">
                {roleID ? t("edit") : t("create")}
              </span>
            </Col>
          </Row>
        </Col>
        {medicalRecord ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className={roleByID?.data?.result ? "mr-05 cancel" : " cancel"}
              onClick={() => history.replace(USER_MANAGE.title)}
            >
              {t("cancel")}
            </Button>
            {roleByID?.data?.result && (
              <Button
                className="btn-create"
                onClick={() => formRole.submit()}
                loading={!!loadingCreate || !!loadingUpdate}
              >
                {t("save")}
              </Button>
            )}
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
