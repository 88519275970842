import React, { useState } from "react";
import { COUPON } from "@assets/data/pathRouter";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useDeleteCoupon } from "@services/api/coupon";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Delete from "@assets/img/icon/OPD/delete.svg";
import Modaler from "@components/modaler/Modaler";
import BackHome from "@components/button/BackHome";
import RoleContext from "@provider/RoleProvider";

type Props = {
  couponId: number;
};

const Header = ({ couponId }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const deleteCoupon = useDeleteCoupon();
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "COUPON";
  });

  const editCoupon = () => {
    history.push(`${COUPON.title}/${couponId}`);
  };

  const onModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const onDelete = () => {
    deleteCoupon.mutate(couponId, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        history.replace(`${COUPON?.title}`);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
    });
    onModal();
  };

  return (
    <>
      <Modaler
        loadingButton={deleteCoupon?.isLoading}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("marketing.title")}</h1>
              </div>
              <span className="t-light-gray">{t("coupon.title")} / </span>
              <span className="t-dark-blue f-normal">{t("coupon.manage")}</span>
            </Col>
          </Row>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className=" delete mr-05 "
              htmlType="submit"
              onClick={(e) => {
                onModal();
                e.stopPropagation();
              }}
            >
              <img className="circle editor-button" src={Delete} alt="delete" />
            </Button>
            <Button
              className="edit mr-05 "
              htmlType="submit"
              onClick={editCoupon}
            >
              {t("edit")}
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
