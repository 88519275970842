import { Form } from "antd";
import { Course } from "@interface/IPromotion.interface";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useGetPromotionByID } from "@services/api/promotion";
import moment from "moment";
import Fields from "./Fields";
import Header from "./Header";
import BigNumber from "bignumber.js";

const PromotionDetail = () => {
  const [confirm] = Form.useForm();
  const match: any = useRouteMatch();
  const promotionID = match.params.id;
  const promotionById = useGetPromotionByID(promotionID);

  useEffect(() => {
    if (!promotionById?.data) return;
    let price = 0;
    let totalForeigner = 0;
    let totalPartner = 0;
    promotionById?.data?.result?.[0]?.courses?.forEach((item: Course) => {
      price = Number(
        new BigNumber(item?.normal || 0)
          .multipliedBy(item?.qty || 0)
          .plus(price)
      );
      totalForeigner = Number(
        new BigNumber(item?.foreigner || 0)
          .multipliedBy(item?.qty || 0)
          .plus(totalForeigner)
      );
      totalPartner = Number(
        new BigNumber(item?.partner || 0)
          .multipliedBy(item?.qty || 0)
          .plus(totalPartner)
      );
    });
    confirm.setFieldsValue({
      ...promotionById?.data?.result?.[0],
      ...promotionById?.data?.result?.[0]?.product,
      price: new BigNumber(price).toFormat(2),
      totalForeigner: new BigNumber(totalForeigner).toFormat(2),
      totalPartner: new BigNumber(totalPartner).toFormat(2),
      start: [
        promotionById?.data?.result?.[0]?.product?.startDate
          ? moment(promotionById?.data?.result?.[0]?.product?.startDate)
          : undefined,
        promotionById?.data?.result?.[0]?.product?.endDate
          ? moment(promotionById?.data?.result?.[0]?.product?.endDate)
          : undefined,
      ],
    });

    // eslint-disable-next-line
  }, [promotionById?.data]);

  return (
    <div>
      <Header
        promotionId={promotionID}
        status={promotionById?.data?.result?.[0]?.product?.status}
        isEdit={promotionById?.data?.result?.[0]?.isEdit || false}
      />
      <Fields confirm={confirm} promotionById={promotionById?.data} />
    </div>
  );
};

export default PromotionDetail;
