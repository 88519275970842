import { Form } from "antd";
import { useEffect } from "react";
import { stepOPDState } from "@tools/recoil/recoil-atom";
import { useSetRecoilState } from "recoil";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Header from "./Header";
import FormCompany from "./form/Form";

const ContractingCompany = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const setStepOPD = useSetRecoilState(stepOPDState);
  const [companyForm] = Form.useForm();
  const match: any = useRouteMatch();
  const pathID = match.params.id;

  useEffect(() => {
    setStepOPD(3);

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Header companyForm={companyForm} history={history} />

      <FormCompany
        companyForm={companyForm}
        history={history}
        pathID={pathID}
      />
    </div>
  );
};

export default ContractingCompany;
