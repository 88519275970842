import { date, gender } from '@assets/data/data'
import SelectorDouble from '@components/selector/SelectorDouble'
import { Button, Col, Form, Input, Row } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

type Props = {
    onFinish: (e: any) => void;
    setCurrentPage: (e: number) => void;
  };

const Search:FC<Props> = ({onFinish,setCurrentPage}) => {
    const {t,i18n} = useTranslation()

  return (
    <div className="pb-1">
    <Form onFinish={onFinish}>
      <Row gutter={[14, 14]}>
        <Col md={8} lg={6}>
          <Form.Item name="name">
            <Input
              placeholder={`${t("search")} ${t("name")} , ${t("code")} HN`}
            />
          </Form.Item>
        </Col>

        <Col lg={5}>
          <Form.Item name="gender">
            <SelectorDouble
              Source={gender}
              keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
              keyValue="id"
              title={t("sex")}
            />
          </Form.Item>
        </Col>
      

        <Col lg={4}>
          <Form.Item name="sort">
            <SelectorDouble
              Source={date}
              keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
              keyValue="id"
              title={t("sort")}
              placeholder={t("oldest")}
            />
          </Form.Item>
        </Col>

        <Col>
          <Button className="mr-1 btn-search" htmlType="submit">
            {t("search")}
          </Button>
          <Button className="clear" htmlType="reset">
            {t("reset")}
          </Button>
        </Col>
      </Row>
    </Form>
  </div>
  )
}

export default Search