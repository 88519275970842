import React, { useEffect, useState } from "react";
import { _isEmpty } from "@tools/util";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { openNotification } from "../notification/Notification";
import { IPostAppointments } from "@interface/IAppointments.interface";
import { APPOINTMENT_STATUS } from "@tools/util.enum";
import { useCreateAppointments } from "@services/api/appointments";
import { useGetAppointmentByID } from "@services/api/appointments";
import { useUpdateAppointments } from "@services/api/appointments";
import { useGetPatientsDropdown } from "@services/api/patient";
import { useGetPatientByHistory } from "@services/api/patient";
import { Input, Row, Select, TimePicker } from "antd";
import { Col, Divider, Form, FormInstance } from "antd";
import { useGetSlotById, useGetSlotsGetTimeList } from "@services/api/slots";
import moment from "moment";
import Period from "@assets/img/icon/OPD/period.svg";
import Selector from "../selector/Selector";
import TextArea from "antd/lib/input/TextArea";
import useDebounce from "@tools/useDebounce";

const { Option } = Select;

const status = [
  {
    nameTh: "รอปรึกษา",
    nameEn: "Wait consult",
    id: APPOINTMENT_STATUS.WAITING_FOR_ADVICE,
  },
  { nameTh: "ยกเลิก", nameEn: "Cancel", id: APPOINTMENT_STATUS.CANCEL },
];
const statusConfirm = [
  {
    nameTh: "รอการรักษา",
    nameEn: "Waiting for treatment",
    id: APPOINTMENT_STATUS.WAITING_FOR_TREATMENT,
  },
  {
    nameTh: "เสร็จสิ้น",
    nameEn: "Confirm",
    id: APPOINTMENT_STATUS.CONFIRM,
  },
];
const consult = [
  {
    nameTh: "รอการรักษา",
    nameEn: "Waiting for treatment",
    id: APPOINTMENT_STATUS.WAITING_FOR_TREATMENT,
  },
  {
    nameTh: "จองล่วงหน้า",
    nameEn: "Book advance",
    id: APPOINTMENT_STATUS.BOOK_IN_ADVANCE,
  },
  {
    nameTh: "รอปรึกษา",
    nameEn: "Wait consult",
    id: APPOINTMENT_STATUS.WAITING_FOR_ADVICE,
  },
  { nameTh: "ยกเลิก", nameEn: "Cancel", id: APPOINTMENT_STATUS.CANCEL },
];
const format = "HH:mm";

type Props = {
  reservation: FormInstance;
  setVisible: (e: boolean) => void;
  patientID?: number;
  visitID?: number;
  getAppointmentsList: any;
  appointmentsID?: number;
};

const Fields = ({
  reservation,
  setVisible,
  patientID,
  visitID,
  getAppointmentsList,
  appointmentsID,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [search, setSearch] = useState<string | undefined>();
  const dSearch = useDebounce(search, 500);
  const [disabledHours, setDisabledHours] = useState();
  const match: any = useRouteMatch();
  const slotID = match.params.slotID;
  const patientByHistory = useGetPatientByHistory(patientID);
  const slotById = useGetSlotById(
    slotID,
    appointmentsID && appointmentsID !== 0 ? false : true
  );
  const updateAppointments = useUpdateAppointments();
  const slotsGetTimeList = useGetSlotsGetTimeList(
    slotID,
    appointmentsID && appointmentsID !== 0 ? false : true
  );
  const createAppointments = useCreateAppointments();
  const appointmentByID = useGetAppointmentByID(
    !appointmentsID && appointmentsID !== 0 ? false : true,
    appointmentsID
  );
  const patientsList = useGetPatientsDropdown(
    appointmentByID?.data?.result?.[0]?.patientId || patientID ? false : true,
    {
      search: dSearch,
      myBranch: true,
    }
  );

  useEffect(() => {
    if (appointmentsID || appointmentsID === 0) {
      const data = appointmentByID?.data?.result?.[0];
      reservation.setFieldsValue({
        patientName:
          i18n.language === "th"
            ? [data?.patient?.firstNameTh, data?.patient?.lastNameTh]
                ?.join(" ")
                ?.trim()
            : [data?.patient?.firstNameEn, data?.patient?.lastNameEn]
                ?.join(" ")
                ?.trim(),
        patientId: data?.patientId,
        doctor_name:
          i18n.language === "th"
            ? [data?.slot?.user?.firstNameTh, data?.slot?.user?.lastNameTh]
                ?.join(" ")
                ?.trim()
            : [data?.slot?.user?.firstNameEn, data?.slot?.user?.lastNameEn]
                ?.join(" ")
                ?.trim(),

        room: data?.slot?.room.name,
        status: data?.status,
        startDate: data?.startDate
          ? moment(`${data?.date} ${data?.startDate}`)
          : undefined,
        endDate: data?.endDate
          ? moment(`${data?.date} ${data?.endDate}`)
          : undefined,
        detail: data?.detail,
      });
    }
    // eslint-disable-next-line
  }, [appointmentByID?.data]);

  useEffect(() => {
    if (!appointmentsID && appointmentsID !== 0) {
      const form = reservation.getFieldsValue();
      const data: any = slotsGetTimeList?.data?.result?.[0];

      let status;
      if (visitID || (appointmentsID && data?.visitId)) {
        status = APPOINTMENT_STATUS.WAITING_FOR_ADVICE;
      } else {
        status = APPOINTMENT_STATUS.BOOK_IN_ADVANCE;
      }

      reservation.setFieldsValue({
        ...form,
        status: status,
        patientName:
          i18n?.language === "th"
            ? [
                patientByHistory?.data?.firstNameTh,
                patientByHistory?.data?.lastNameTh,
              ]
                ?.join(" ")
                ?.trim()
            : [
                patientByHistory?.data?.firstNameEn,
                patientByHistory?.data?.lastNameEn,
              ]
                ?.join(" ")
                ?.trim(),
        patientId: patientID,
        startDate: data?.time
          ? moment(`${data?.date} ${data?.time}`)
          : undefined,
        endDate: data?.time
          ? moment(`${data?.date} ${data?.time}`).add(15, "minute")
          : undefined,
      });
    }

    // eslint-disable-next-line
  }, [slotsGetTimeList?.data !== undefined, patientByHistory?.data]);

  useEffect(() => {
    const form = reservation.getFieldsValue();
    const data: any = slotById?.data?.result?.[0];
    reservation.setFieldsValue({
      ...form,
      doctor_name:
        i18n.language === "th"
          ? [data?.user?.firstNameTh, data?.user?.lastNameTh]?.join(" ")?.trim()
          : [data?.user?.firstNameEn, data?.user?.lastNameEn]
              ?.join(" ")
              ?.trim(),

      room: data?.room.name,
    });
    // eslint-disable-next-line
  }, [slotById?.data]);

  const onFinish = (dataForm: any) => {
    delete dataForm?.patientName;
    const data = {
      ...dataForm,
    };
    if (!_isEmpty(appointmentsID)) {
      handleUpdateAppointment(data);
    } else {
      handleCreateAppointment(data);
    }
  };

  const onChangeTimePicker = (time: any, nextName?: string) => {
    var timeNext = moment(time).add(15, "minutes");
    reservation.setFieldsValue({
      [nextName ? nextName : `endDate`]: timeNext,
    });
    setDisabledHours(time);
  };

  const getDisabledHours = () => {
    let hours = [];
    for (let i = 0; i < moment(disabledHours).hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const handleUpdateAppointment = (values: any) => {
    const data = {
      status: values.status,
      detail: values.detail,
      startDate: moment(values?.startDate).format("HH:mm:ssZ"),
      endDate: moment(values?.endDate).format("HH:mm:ssZ"),
    };
    updateAppointments.mutate(
      { data: data, id: appointmentsID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          getAppointmentsList();
          setVisible(false);
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  const handleCreateAppointment = async (values: any) => {
    const date = moment(slotsGetTimeList?.data?.result?.[0]?.date).format();
    const createAppointment: IPostAppointments = {
      date: date,
      slotId: Number(slotID),
      visitId: Number(visitID),
      detail: values?.detail,
      patientId: values?.patientId,
      status: values?.status,
      startDate: moment(values?.startDate).format("HH:mm:ssZ"),
      endDate: moment(values?.endDate).format("HH:mm:ssZ"),
    };
    createAppointments.mutate(createAppointment, {
      onSuccess: (res) => {
        openNotification({ method: "POST_SUCCESS" });
        getAppointmentsList();
        setVisible(false);
      },
      onError: (res: any) => {
        openNotification({ method: "POST_ERROR", message: String(res) });
        setVisible(false);
      },
    });
  };

  const sleetedForm = () => {
    if (
      visitID ||
      (appointmentsID &&
        appointmentByID?.data?.result?.[0]?.status === "WAITING_FOR_ADVICE")
    ) {
      return (
        <>
          <Form.Item
            name="status"
            rules={[{ required: true, message: t("pleaseSelect") }]}
          >
            <Selector
              Source={status}
              keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
              keyValue="id"
              disabled={appointmentsID ? false : true}
            />
          </Form.Item>
        </>
      );
    } else if (
      visitID ||
      (appointmentsID &&
        appointmentByID?.data?.result?.[0]?.status ===
          APPOINTMENT_STATUS.CONFIRM)
    ) {
      return (
        <React.Fragment>
          <Form.Item name="status" rules={[{ required: true }]}>
            <Selector
              Source={consult}
              keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
              keyValue="id"
            />
          </Form.Item>
        </React.Fragment>
      );
    } else if (
      appointmentsID &&
      appointmentByID?.data?.result?.[0]?.status ===
        APPOINTMENT_STATUS.WAITING_FOR_TREATMENT
    ) {
      return (
        <React.Fragment>
          <Form.Item name="status" rules={[{ required: true }]}>
            <Selector
              Source={statusConfirm}
              keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
              keyValue="id"
            />
          </Form.Item>
        </React.Fragment>
      );
    } else
      return (
        <React.Fragment>
          <Form.Item name="status" rules={[{ required: true }]}>
            <Selector
              Source={consult}
              keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
              keyValue="id"
              disabled={true}
            />
          </Form.Item>
        </React.Fragment>
      );
  };

  const entireData = (): { data: any[]; hasMore: boolean; current: number } => {
    const { pages } = patientsList?.data || {};
    const pagesLength = pages?.length || 0;

    const data = pages?.flatMap((item) => {
      return item?.data;
    });

    const pageCount = pages?.[pagesLength - 1]?.pageCount || 0;
    const current = pages?.[pagesLength - 1]?.page || 1;
    return { data: data || [], hasMore: pageCount > current, current: current };
  };

  const { data, current } = entireData();

  const fetchMoreData = () => {
    patientsList?.fetchNextPage({
      pageParam: { page: current + 1, search: !!search ? search : undefined },
    });
  };

  const handleScroll = (event: any) => {
    event.stopPropagation();
    const { target }: any = event;
    if (target?.scrollTop + target?.offsetHeight === target?.scrollHeight) {
      fetchMoreData();
    }
  };
  const onSearch = (value: string) => {
    setSearch(value);
  };

  return (
    <>
      <Form onFinish={onFinish} layout="vertical" form={reservation}>
        <div className="list-queue-scroll-calendar-room">
          <Row gutter={[23, 18]} className="margin-form">
            <Col md={12} lg={12}>
              <div>{t("nurseName")}</div>
              <div className="mt-1">
                <Form.Item
                  name="patientName"
                  rules={[
                    {
                      required: !!patientID || !!appointmentsID ? true : false,
                    },
                  ]}
                  hidden={!!patientID || !!appointmentsID ? false : true}
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  name="patientId"
                  rules={[{ required: true }]}
                  hidden={!!patientID || !!appointmentsID ? true : false}
                >
                  {!!patientID || !!appointmentsID ? (
                    <Input disabled />
                  ) : (
                    <Select
                      onDropdownVisibleChange={(e) => {
                        if (e === false) {
                          setSearch(undefined);
                        }
                      }}
                      showSearch
                      filterOption={() => {
                        return true;
                      }}
                      onSearch={onSearch}
                      loading={patientsList?.isLoading}
                      onPopupScroll={(e) => {
                        handleScroll(e);
                      }}
                    >
                      {data?.map((item) => {
                        return (
                          <Option key={item?.id} value={item?.id}>
                            {i18n.language === "th"
                              ? item?.nameTh
                              : item?.nameEn}{" "}
                          </Option>
                        );
                      })}
                    </Select>
                    // <Selector
                    //   disabled={patientID || appointmentsID ? true : false}
                    //   Source={patientsList?.data?.result?.[0]?.data || []}
                    //   keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                    //   keyValue="id"
                    //   components={{
                    //     MenuList,
                    //   }}
                    // />
                  )}
                </Form.Item>
              </div>
            </Col>

            <Col md={12} lg={12}>
              <div>{t("doctorName")}</div>
              <div className="mt-1">
                <Form.Item name="doctor_name">
                  <Input disabled />
                </Form.Item>
              </div>
            </Col>
            <Col md={24} lg={12}>
              <div>{t("room")}</div>
              <div className="mt-1">
                <Form.Item name="room" rules={[{ required: true }]}>
                  <Input disabled />
                </Form.Item>
              </div>
            </Col>
            <Col md={12} lg={6}>
              <div className="">{t("period")}</div>
              <Row className="mt-1">
                <Col md={4} lg={3} className="icon-modal">
                  <img className="img-icon-modal" src={Period} alt="Period" />
                </Col>
                <Col md={20} lg={21}>
                  <Form.Item name="startDate" rules={[{ required: true }]}>
                    <TimePicker
                      format={format}
                      onChange={(e) => onChangeTimePicker(e)}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col md={2} lg={1}>
              <div className="mt-3 t-to">to</div>
            </Col>

            <Col md={10} lg={5} className="mt-input">
              <Form.Item
                name="endDate"
                rules={[
                  { required: true, message: t("pleaseSelect") },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (
                        moment(getFieldValue("startDate")).isAfter(value) ||
                        moment(getFieldValue("startDate")).format("HH:mm") ===
                          moment(value).format("HH:mm")
                      ) {
                        reservation.setFieldsValue({
                          [`${"endDate"}`]: moment(
                            getFieldValue("startDate")
                          ).add(15, "minutes"),
                        });
                        return Promise.resolve();
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <TimePicker format={format} disabledHours={getDisabledHours} />
              </Form.Item>
            </Col>

            <Col md={12} lg={12}>
              <div>{t("status")}</div>
              <div className="mt-1">{sleetedForm()}</div>
            </Col>

            <Col md={24} lg={24}>
              <div>{t("details")}</div>
              <div className="mt-1">
                <Form.Item name="detail">
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>
              </div>
            </Col>
          </Row>
          <Divider className="mt-2" />
        </div>
      </Form>
    </>
  );
};

export default Fields;
