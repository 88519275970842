import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";

type Props = {
  onFinish: (value: any) => void;
};

const Search = ({ onFinish }: Props) => {
  const { t } = useTranslation();
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col lg={11} md={7} xl={11}>
            <Form.Item name="name">
              <Input
                placeholder={`${t("search")}${t("name")} , ${t(
                  "code"
                )} HN , ${t("name")}${t("product")}`}
              />
            </Form.Item>
          </Col>

          <Col md={7} lg={9} xl={9}>
            <Form.Item name="date">
              <DatePicker format="DD/MM/YYYY" />
            </Form.Item>
          </Col>
          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
