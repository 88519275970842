import { ISearch } from "../inteface";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row, Image } from "antd";
import {
  useGetReportInvoice,
  useGetReportInvoiceExport,
} from "@services/api/report";
import { RouteComponentProps, useHistory } from "react-router-dom";

import Excel from "@assets/img/icon/excel.png";
import Search from "./Search";
import MoTable from "@components/table/Table";
import BackHome from "@components/button/BackHome";
import { IInvoiceReport } from "@interface/IReport.interface";
import dayjs from "dayjs";
import { ENUM_ORDERBY } from "@tools/util.enum";
import BigNumber from "bignumber.js";

const InvoiceReport = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [search, setSearch] = useState<ISearch>();
  const dataReportList = useGetReportInvoice();
  const exportReport = useGetReportInvoiceExport();
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);

  useEffect(() => {
    dataReportList?.mutate({
      limit: limitPage,
      page: currentPage,
      ...search,
      startDate: search?.startDate,
      endDate: search?.endDate,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limitPage, search]);

  useEffect(() => {
    setTotal(dataReportList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [dataReportList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onFinish = (value: ISearch) => {
    let data = {
      ...value,
      startDate: value.start?.[0]
        ? dayjs(value.start?.[0]).format("YYYY-MM-DD")
        : undefined,
      endDate: value.start?.[1]
        ? dayjs(value.start?.[1]).format("YYYY-MM-DD")
        : undefined,
    };

    setSearch({ ...data });
  };
  const onClick = () => {
    exportReport.mutate(
      { ...search, page: 1, limit: 0, orderby: ENUM_ORDERBY.ASC },
      {}
    );
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: any, data: any, index: number) => {
        return <div> {limitPage * (currentPage - 1) + index + 1}</div>;
      },
    },
    {
      title: t("invoiceNumber"),
      dataIndex: "invoiceNumber",
    },
    {
      title: "HN",
      dataIndex: "hn",
      render: (_: string, result: IInvoiceReport) => {
        const patient = result?.bill?.order?.patient;
        return patient?.hn;
      },
    },
    {
      title: t("name"),
      dataIndex: "name",
      render: (_: string, result: IInvoiceReport) => {
        const patient = result?.bill?.order?.patient;
        return [patient.firstNameTh, patient.lastNameTh].join(" ").trim();
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
    },

    {
      title: t("numberDaysOverdue"),
      dataIndex: "amountOwed",
      render: (count: string) => {
        return count || 0;
      },
    },
    {
      title: t("total"),
      dataIndex: "outstandingBalance",
      align: "right" as const,
      render: (outstandingBalance: string) => {
        return (
          <div>{`${new BigNumber(outstandingBalance || 0).toFormat(2)}`}</div>
        );
      },
    },
    {
      title: t("remarks"),
      dataIndex: "description",
      render: (description: string) => {
        return description || "";
      },
    },
    {
      title: t("branch"),
      dataIndex: "branch",
      render: (_: string, result: IInvoiceReport) => {
        const branch = result?.bill?.order?.branch;
        return branch?.code;
      },
    },
  ];
  const newDatalist = dataReportList?.data?.result?.[0]?.data?.map(
    (item, i) => {
      return { key: i, ...item };
    }
  );
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("reportSummary.title")}</h1>
              </div>
              <span className="t-light-gray">{t("report")} / </span>
              <span className="t-dark-blue f-normal">{t("invoiceReport")}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={
            <Row align="middle">
              <Col>
                <b className="header-card ">{t("invoiceReport")}</b>
              </Col>
              <Col
                className="cursor"
                style={{ marginLeft: "auto" }}
                onClick={() => onClick()}
              >
                <Image src={Excel} width={70} preview={false} />
              </Col>
            </Row>
          }
        >
          <Search onFinish={onFinish} />
          <MoTable
            rowClassName={() => {
              return "no-header no-cursor";
            }}
            className="table-selection"
            columns={columns}
            dataSource={newDatalist || []}
            onChangePage={onChangePage}
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
            scroll={{ x: "calc(100vw)" }}
            size="middle"
            rowKey="key"
            key="key"
          />
        </Card>
      </div>
    </div>
  );
};

export default InvoiceReport;
