import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Col, Row } from "antd";
import { useGetLabInfo } from "@services/api/work_order_item";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Print from "./print/Print";
import Header from "./Header";

const PrintLabel = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const printReportRef = useRef(null);
  const pageStyle = `@page{size:50.8mm 24.4mm;
    margin: 7px 3px 3px 3px;}`;
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const labInfoList = useGetLabInfo(visitID);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Header history={history} handlePrint={handlePrint} />
      <ReactToPrint content={() => printReportRef.current} />

      <div
        style={{ justifyContent: "center", display: "flex" }}
        className="set-layout  "
      >
        <div style={{ minHeight: "300px" }} className="document-bill ">
          <Row justify="center">
            <Col span={24}>
              <Print refData={printReportRef} labInfoList={labInfoList?.data} />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PrintLabel;
