import { IAgent } from "@interface/IAgent.interface";
import { SETTING } from "@assets/data/pathRouter";
import { ColumnsType } from "antd/lib/table";
import { ISearchPrefix } from "@interface/ISearch.interface";
import { useTranslation } from "react-i18next";
import { useGetAgentsList } from "@services/api/agent";
import { useState, useEffect, FC } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  currentPage: { currentPage: number; setCurrentPage: (e: number) => void };
  search?: ISearchPrefix;
};

const TableRecommender: FC<Props> = ({ currentPage, search }) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const agentsList = useGetAgentsList({
    limit: limitPage,
    page: currentPage.currentPage,
    search: search?.name,
  });

  useEffect(() => {
    setTotal(agentsList?.data?.result?.[0]?.total || 1);

    // eslint-disable-next-line
  }, [agentsList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage?.setCurrentPage(page);
  };

  const onClickRow = (e: IAgent) => {
    history.push(`${SETTING.agentManage}/${e.id}`);
  };

  const columns: ColumnsType<IAgent> = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: IAgent, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage?.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("name")} - ${t("lastName")}`,
      dataIndex: "fName",
      key: "fName",
      render: (_: string, data: IAgent) => {
        return (
          <div>
            {i18n.language === "th"
              ? [data?.firstNameTh, data?.lastNameTh]?.join(" ")?.trim()
              : [data?.firstNameEn, data?.lastNameEn]?.join(" ")?.trim()}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={agentsList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage?.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default TableRecommender;
