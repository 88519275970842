import { t } from "i18next";
import { FC } from "react";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useGetCategories } from "@services/api/order";
import { Button, Col, Form, Input, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";
import { useTranslation } from "react-i18next";
type Props = {
  onFinish: (e: any) => void;
};
const Header: FC<Props> = ({ onFinish }) => {
  const { i18n } = useTranslation();
  const categoriesList = useGetCategories({
    orderby: ENUM_ORDERBY.ASC,
    limit: 0,
  });
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col sm={7} lg={7} xs={9} xl={9} xxl={9}>
            <Form.Item name="name">
              <Input placeholder={`${t("search")}`} />
            </Form.Item>
          </Col>
          <Col sm={8} lg={9} xs={10} xl={10} xxl={11}>
            <Form.Item name="categoryId">
              <SelectorDouble
                Source={categoriesList?.data?.result?.[0]?.data || []}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("type")}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear ml-05" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Header;
