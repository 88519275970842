import React, { useEffect, useState } from "react";
import { Age } from "@tools/age";
import { symbol } from "@assets/data/data";
import { gender } from "@tools/util";
import { IBranch } from "@interface/IBranch.interface";
import { Popover } from "antd";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useMainSearchPatient } from "@provider/search_patient";
import { useGetPatientsAllList } from "@services/api/patient";
import MoTable from "@components/table/Table";

type Props = {
  search: any;
  currentPage: ICurrentPage;
};

const Table = ({ search, currentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const { patientId } = useMainSearchPatient();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);

  const patientList = useGetPatientsAllList({
    page: currentPage?.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    all: true,
    ...search,
    search: search?.name,
    gender: search?.gender,
    phone: search?.phone,
  });

  useEffect(() => {
    if (!patientList?.data) return;
    setTotal(patientList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [patientList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[]) => {
      patientId.current = selectedRowKeys;
    },
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div>{limitPage * (currentPage?.currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "hn",
      key: "hn",
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "firstNameTH",
      key: "firstNameTH",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: any) => {
        return (
          <div className="f-normal " key={data?.id}>
            {i18n?.language === "th"
              ? [data?.firstNameTh, data?.lastNameTh]?.join(" ")?.trim()
              : [data?.firstNameEn, data?.lastNameEn]?.join(" ")?.trim()}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "gender",
      key: "gender",
      render: (data: any) => {
        let nameGender = gender(data, i18n?.language);
        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      sorter: (a: any, b: any) => a.age - b.age,
      render: (dateOfBirth: string) => {
        return (
          <React.Fragment>
            {dateOfBirth ? Age(dateOfBirth) : "-"}
          </React.Fragment>
        );
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "mobile",
      sorter: (a: any, b: any) => a.phone - b.phone,
      key: "mobile",
      render: (mobile: string) => {
        return <React.Fragment>{mobile ? mobile : "-"}</React.Fragment>;
      },
    },

    {
      title: t("originBranch"),
      dataIndex: "primaryBranch",
      key: "primaryBranch",
      render: (primaryBranch: IBranch) => {
        return (
          <React.Fragment>
            <Popover
              content={
                i18n.language === "th"
                  ? primaryBranch?.nameTh
                  : primaryBranch?.nameEn
              }
            >
              <div className="text-content">
                {i18n.language === "th"
                  ? primaryBranch?.nameTh
                  : primaryBranch?.nameEn}
              </div>
            </Popover>
          </React.Fragment>
        );
      },
    },

    {
      title: t("insertingASymbol"),
      dataIndex: "symbol",
      key: "symbol",
      render: (symbolList: number[]) => {
        if (!symbolList) return;
        const dataSymbol = symbol?.filter((item) => {
          if (!symbolList?.includes(Number(item?.id))) {
            return false;
          }
          return item;
        });

        return (
          <div style={{ color: "#FEB139" }}>
            {dataSymbol
              ?.map((item) =>
                i18n.language === "th" ? item?.["nameTh"] : item?.["nameEn"]
              )
              ?.join(" , ")}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <MoTable
        loading={patientList?.isLoading}
        rowSelection={{
          type: "radio",
          ...rowSelection,
        }}
        rowClassName={() => {
          return "no-header no-cursor";
        }}
        dataSource={patientList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage?.currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </React.Fragment>
  );
};

export default Table;
