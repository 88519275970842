import React, { useEffect, useState } from "react";
import { dayjs } from "@tools/timezone";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { DownOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useGetReceiptByPatient } from "@services/api/receipt";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Dropdown, Form, Menu } from "antd";
import moment from "moment";
import MoTable from "@components/table/Table";
import Decimal from "@tools/decimal";
import RoleContext from "@provider/RoleProvider";
import ModalSaveMoney from "./modal/Modal";

type Props = {
  searchValue: any;
  currentPage: number;
  setCurrentPage: (e: number) => void;
};

const Table = ({ searchValue, currentPage, setCurrentPage }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const history = useHistory<RouteComponentProps["history"]>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PAY";
  });
  const receiptList = useGetReceiptByPatient(patientID, {
    page: currentPage,
    limit: limitPage,
    patientId: patientID,
    ...searchValue,
    date: searchValue?.date
      ? dayjs(searchValue?.date).format("YYYY-MM-DD")
      : "",
    orderby: `${
      searchValue?.status === 2 ? ENUM_ORDERBY.ASC : ENUM_ORDERBY.DESC
    }`,
  });
  const [receiptID, setReceiptID] = useState<number>();
  const [receiptForm] = Form.useForm();

  useEffect(() => {
    setTotal(receiptList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [receiptList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onChangeSelect = (value: string, data: any) => {
    if (value === "1") {
      receiptForm.resetFields();
      receiptForm.setFieldsValue({
        ...data,
        payment: data?.payment || "CASH",
        price: data?.price.toFixed(2),
        date: moment(),
        total: data?.price,
      });
      setReceiptID(data?.id);
      setVisible(!visible);
    } else {
    }
  };

  const onClickRow = (event: any) => {
    history.push(
      `${PAY_LIST_TAB.editReceipt}/${event.id}/patient/${patientID}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "15%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <>{dayjs(date).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: `${t("number")}${t("document")}`,
      dataIndex: "receiptNumber",
      key: "receiptNumber",
    },

    {
      title: t("netTotal"),
      dataIndex: "price",
      key: "price",
      render: (price: number) => {
        return <>{Decimal(Number(price))}</>;
      },
    },

    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: string, data: any) => {
        return (
          <React.Fragment>
            {medicalRecord?.[0]?.isEdit ? (
              status === "WAITING" ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={(e) => onChangeSelect(e.key, data)}
                        key="1"
                      >
                        เก็บเงิน
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <Button className="btn-waiting-bill">
                    {t("pending")} <DownOutlined />
                  </Button>
                </Dropdown>
              ) : (
                <Button className="btn-confirm-receipt">
                  {t("collectedMoney")}
                </Button>
              )
            ) : status === "WAITING" ? (
              <Button className="btn-waiting-bill">{t("pending")}</Button>
            ) : (
              <Button className="btn-confirm-receipt">
                {t("collectedMoney")}
              </Button>
            )}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={receiptList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
        onDoubleClick={true}
        onClickRow={onClickRow}
      />
      {visible && (
        <ModalSaveMoney
          visible={{ visible: visible, setVisible: setVisible }}
          receiptForm={receiptForm}
          receiptID={receiptID}
        />
      )}
    </>
  );
};

export default Table;
