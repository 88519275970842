import { dayjs } from "@tools/timezone";
import { useTranslation } from "react-i18next";
import { useGetBranchDropdownList } from "@services/api/branch";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  setSearchValue: (value: any) => void;
  setCurrentPage: (e: any) => void;
};

const Search = ({ setSearchValue, setCurrentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const { RangePicker } = DatePicker;
  const dateFormat = "DD-MM-YYYY";
  const branchDropdown = useGetBranchDropdownList();

  const onFinish = (values: any) => {
    const data = {
      ...values,
      startDate: values?.date?.[0]
        ? dayjs(values?.date?.[0]).format("YYYY-MM-DD")
        : undefined,
      endDate: values?.date?.[1]
        ? dayjs(values?.date?.[1]).format("YYYY-MM-DD")
        : undefined,
    };
    setSearchValue(data);
    setCurrentPage(1);
  };
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={8} lg={6}>
            <Form.Item name="search">
              <Input
                placeholder={`${t("search")}${t("name")}${t(
                  "marketing.activity"
                )}`}
              />
            </Form.Item>
          </Col>

          <Col md={6} xl={7}>
            <Form.Item name="date">
              <RangePicker format={dateFormat} />
            </Form.Item>
          </Col>
          <Col md={9} xl={6}>
            <Form.Item name="branchId">
              <SelectorDouble
                Source={branchDropdown?.data?.result || []}
                keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("branch")}
                placeholder={t("all")}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
