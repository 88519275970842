import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  companyForm: FormInstance;
  history: RouteComponentProps["history"];
};

const Header = ({ companyForm, history }: Props) => {
  const updatePatient = useIsMutating(["update-patient"]);
  const createPatient = useIsMutating(["create-patient"]);
  const { t } = useTranslation();
  return (
    <Row className="second-header " align="middle">
      <Col>
        <Row gutter={[14, 14]}>
          <BackHome history={history} />

          <Col className="header-text">
            <div>
              <h1>{t("medicalRecord.title")}</h1>
            </div>
            <span className="t-light-gray">{t("nursery")} / </span>
            <span className="t-dark-blue f-normal">{t("createKeeper")}</span>
          </Col>
        </Row>
      </Col>

      <Col style={{ marginLeft: "auto" }}>
        <Button
          className="mr-1 cancel"
          htmlType="reset"
          onClick={history.goBack}
        >
          {t("cancel")}
        </Button>
        <Button
          className="btn-create"
          htmlType="submit"
          onClick={() => companyForm.submit()}
          loading={!!updatePatient || !!createPatient}
        >
          {t("save")}
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
