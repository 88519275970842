import { useTranslation } from "react-i18next";
import { Card, Col, Form, FormInstance, Input, Row } from "antd";

type Props = {
  contractCompanyForm: FormInstance;
  onFinish: (e: any) => void;
};

const Fields = ({ contractCompanyForm, onFinish }: Props) => {
  const { t } = useTranslation();

  return (
    <div className="set-layout ">
      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={
          <b className="fs-16 t-black-light ">{`${t(
            "editContractCompany"
          )} `}</b>
        }
      >
        <Card
          className="card-prefix card-prefix-width card-prefix-chi"
          extra={
            <b className="fs-14 c-white ">{`${t("editContractCompany")} `}</b>
          }
        >
          <Form
            onFinish={onFinish}
            name="contractCompanyForm"
            form={contractCompanyForm}
          >
            <Row gutter={[12, 12]}>
              <Col lg={24}>
                <Form.Item
                  label={`${t("code")}`}
                  name="code"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  label={`${t("contractingCompanyName")} (TH)`}
                  name="nameTh"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  label={`${t("contractingCompanyName")} (EN)`}
                  name="nameEn"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </div>
  );
};

export default Fields;
