import { Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { useMainDashboard } from "@provider/dashboard";

const CardTotalRoom = () => {
  const { t } = useTranslation();
  const { physical, treatment } = useMainDashboard();
  return (
    <Row gutter={[15, 15]} className="mt-15">
      <Col span={8}>
        <Card className="t-center" style={{ height: "100%" }}>
          <Row align="middle" className="f-normal">
            <Col span={14}>
              <div className="t-start">{t("physical therapy")}</div>
              <div>{`${physical?.general || 0}`}</div>
              <div className="fs-12">{t("performer")}</div>
              <div>{`${physical?.employee || 0}`}</div>
              <div className="fs-12">{t("employeeWelfare")}</div>
            </Col>

            <Col span={10}>
              <Card
                className="operative-nursing-card t-white t-center"
                style={{ backgroundColor: "#00377C" }}
              >
                <div className="fs-24">{`${physical?.sum || 0}`} </div>

                <div className="fs-12">{t("total")}</div>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={8}>
        <Card className="t-center" style={{ height: "100%" }}>
          <Row align="middle" className="f-normal">
            <Col span={14}>
              <div className="t-start">{t("operativeNursing")}</div>
              <div>{`${treatment?.general || 0}`}</div>
              <div className="fs-12">{t("performer")}</div>
              <div>{`${treatment?.employee || 0}`}</div>
              <div className="fs-12">{t("employeeWelfare")}</div>
            </Col>

            <Col span={10}>
              <Card
                className="operative-nursing-card t-white t-center"
                style={{ backgroundColor: "#00377C" }}
              >
                <div className="fs-24">{`${treatment?.sum || 0}`} </div>

                <div className="fs-12">{t("total")}</div>
              </Card>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={8}>
        <Card style={{ height: "100%" }}>
          <div>{t("totalPerformers")}</div>
          <div className="fs-3 f-bold t-blue t-center">
            {(treatment?.sum || 0) + (physical?.sum || 0)}
          </div>
        </Card>
      </Col>
    </Row>
  );
};

export default CardTotalRoom;
