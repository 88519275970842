import { Card } from "antd";
import Nopage from "@assets/img/nopage.svg";

const NoPage = () => {
  return (
    <div>
      <Card>
        <div className="set-layout ">
          <img src={Nopage} alt="nodata" className="image-nopage" />
        </div>
        <div className="text-nodata f-bold">Page not found</div>
      </Card>
    </div>
  );
};

export default NoPage;
