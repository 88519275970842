export const MedicalIcon = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" {...props}>
      <path
        fill="#10112c"
        d="M87.66 41.98H74.85c-10.52-.01-19.04-8.54-19.05-19.05V10.12c0-2.45-1.98-4.43-4.43-4.43H32.58C19.69 4.95 8.64 14.8 7.9 27.7c-.05.89-.05 1.78 0 2.67v39.26c-.74 12.89 9.11 23.94 22.01 24.68.89.05 1.78.05 2.67 0h34.83c12.89.74 23.94-9.11 24.68-22.01.05-.89.05-1.78 0-2.67V46.41c0-2.45-1.98-4.43-4.43-4.43z"
      ></path>
      <path
        fill="#10112c"
        d="M66.84 6.62a2.894 2.894 0 00-4.96 1.95v15.46c.14 6.62 5.57 11.89 12.18 11.83 4.21.04 10.06.04 15.06.04a2.8 2.8 0 002.87-2.72c.02-.75-.26-1.48-.79-2.02-6.38-6.42-17.81-17.99-24.37-24.55z"
      ></path>
    </svg>
  );
};
