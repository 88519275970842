import { IUserList } from "@interface/IUsers.interface";
import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetUser = (
  params?: any
): UseQueryResult<Elementary<IUserList[]>> => {
  return useQuery(["get-user", params], async () => {
    const res = await axios.get("/user", { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetUserDoctor = (
  params?: any
): UseQueryResult<ElementaryOneMore<IUserList>> => {
  return useQuery(["get-user-doctor", params], async () => {
    const res = await axios.get("/user/doctor", { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetUserNurse = (
  params?: any
): UseQueryResult<ElementaryOneMore<IUserList>> => {
  return useQuery(["get-user-nurse", params], async () => {
    const res = await axios.get("/user/nurse", { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      const nurse = res?.data?.result?.map((item: IUserList) => {
        return {
          ...item,
          nameTh: [item?.firstNameTh, item?.lastNameTh]?.join(" ")?.trim(),
          nameEn: [item?.firstNameEn, item?.lastNameEn]?.join(" ")?.trim(),
        };
      });
      return {
        ...res?.data,
        result: nurse,
      };
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetUserByID = (
  id: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IUserList>> => {
  return useQuery(["get-user-by-ID", id, params], async () => {
    if (!id) {
      return;
    }

    const res = await axios.get(`/user/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateUser = (): UseMutationResult => {
  return useMutation(["update-user"], async ({ id, data }: any) => {
    const res = await axios.put(`/user/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateUser = (): UseMutationResult => {
  return useMutation(["create-user"], async ({ data }: any) => {
    const res = await axios.post("/user", data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetProfile = (): UseQueryResult<
  ElementaryOneMore<IUserList>
> => {
  return useQuery(["get-user-profile"], async () => {
    const res = await axios.get(`/user/profile`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteUser = (): UseMutationResult => {
  return useMutation(async (id?: any) => {
    if (!id) {
      return null;
    }
    const res = await axios.delete(`user/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    thrownResponse(res);
  });
};
