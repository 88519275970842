import { useTranslation } from "react-i18next";
import { useRef } from "react";
import { IGroup } from "@interface/IDraftOrder.interface";
import { productName, TextBRHTML } from "@tools/util";
import { Empty, Popover } from "antd";
import { IChild, IItem, IOrder, IProduct } from "@interface/IOrder.interface";
import empty from "@assets/img/empty.svg";
import React from "react";
import MoTable from "@components/table/Table";
import BigNumber from "bignumber.js";

type Props = {
  orderList?: IItem[];
  disable: boolean;
};

const TableOrderList = ({ orderList, disable }: Props) => {
  const { t, i18n } = useTranslation();
  const num = useRef(0);
  const checkDrug = (name: string) => {
    return name?.includes("Drug & Supplement") || name === "C-0002";
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: IOrder, data: IGroup & IItem, index: number) => {
        return (
          <div>
            {data?.level === 1 ? index + 1 : data?.level === 2 ? "" : ""}
          </div>
        );
      },
    },
    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: String, data: IGroup & IItem) => {
        return (
          <div>
            {data?.level === 1
              ? productName(
                  i18n.language,
                  data?.children?.[0]?.children?.[0]?.product?.category?.nameTh,
                  data?.children?.[0]?.children?.[0]?.product?.category?.nameEn
                )
              : data?.level === 2 || data?.level === 4
              ? t("nameList")
              : productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (product: IItem, data: IGroup & IItem) => {
        return (
          <div key={data?.id}>
            {data?.level === 1
              ? ""
              : data?.level === 2 || data?.level === 4
              ? t("type")
              : i18n.language === "th"
              ? product?.group?.nameTh || ""
              : product?.group?.nameEn || ""}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "product",
      key: "product",
      render: (_: IItem, data: IGroup & IItem) => {
        return (
          <React.Fragment>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length || "-"
              : data?.level === 2 || data?.level === 4
              ? t("amount")
              : `${data?.qty || ""} ${
                  i18n.language === "th"
                    ? data?.product?.unitTh || ""
                    : data?.product?.unitEn || ""
                }`}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "gratis",
      key: "gratis",
      render: (gratis: boolean, data: IGroup & IItem & IChild) => {
        return data?.category === "C-0001" ||
          data?.product?.categoryId === "C-0001" ||
          data?.children?.[0]?.product?.categoryId === "C-0001" ? (
          <React.Fragment>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("Gratis")
              : !gratis && data?.level === 3
              ? "NO"
              : data?.level === 3
              ? "YES"
              : ""}
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        );
      },
    },

    {
      title: t("price"),
      dataIndex: "total",
      key: "total",
      render: (total: string, data: IGroup & IItem) => {
        return (
          <React.Fragment>
            {data?.level === 1
              ? new BigNumber(total || 0).toFormat(2)
              : data?.level === 2
              ? t("price")
              : data?.level === 3
              ? new BigNumber(total || 0).toFormat(2)
              : !data?.level
              ? new BigNumber(total || 0).toFormat(2)
              : ""}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      align: "center",
      render: (item: String, dataList: IGroup & IItem & IProduct) => {
        return (
          <React.Fragment>
            {dataList?.level === 1 ? (
              ""
            ) : dataList?.level === 2 || dataList?.level === 4 ? (
              t("details")
            ) : dataList?.level === 3 ||
              dataList?.level === 5 ||
              !dataList?.level ? (
              <Popover
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "th"
                          ? TextBRHTML(
                              dataList?.productDescriptionTh ||
                                dataList?.product?.propertiesTh ||
                                dataList?.product?.descriptionTh ||
                                "-"
                            )
                          : TextBRHTML(
                              dataList?.productDescriptionEn ||
                                dataList?.product?.propertiesEn ||
                                dataList?.product?.descriptionEn ||
                                "-"
                            ),
                    }}
                  />
                }
                key={dataList?.id}
              >
                <div className="text-content">
                  {item || i18n.language === "th"
                    ? dataList?.productDescriptionTh ||
                      dataList?.product?.propertiesTh ||
                      dataList?.product?.descriptionTh ||
                      "-"
                    : dataList?.productDescriptionEn ||
                      dataList?.product?.propertiesEn ||
                      dataList?.product?.descriptionEn ||
                      "-"}
                </div>
              </Popover>
            ) : null}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (item: IItem, dataList: IGroup & IItem) => {
        let color;
        if (dataList?.option) {
          color = "#77C48B";
        } else if (!dataList?.option) {
          color = "#498DCB";
        }
        return (
          <>
            {dataList?.level === 1 ? (
              ""
            ) : dataList?.level === 2 && checkDrug(dataList?.id) ? (
              t("drugLabel")
            ) : checkDrug(item?.category?.id) ? (
              <div
                className="table-queue-status  d-flex justify-center"
                style={{ backgroundColor: color }}
              >
                {dataList?.option
                  ? t("haveDrugDetails")
                  : !dataList?.option && t("noDrugDetails")}
              </div>
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      title: "",
      dataIndex: "success",
      disable: disable,
      key: "success",

      render: (success: boolean, data: IGroup & IItem) => {
        return (
          <React.Fragment>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 || data?.level === 4 ? (
              t("status")
            ) : data?.level === 3 || data?.level === 5 || !data?.level ? (
              <div
                style={{
                  width: 70,
                  borderRadius: 4,
                  textAlign: "center",
                  background: !!success ? "#78C48B" : "#094C97",
                  color: "white",
                }}
              >
                {!!success ? t("finish") : t("execute")}
              </div>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      },
    },
  ];

  return (
    <div>
      <MoTable
        className="components-table-demo-nested table-selection table-confirm "
        pagination={false}
        locale={{
          emptyText: (
            <>
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">{t("noInformation")} </span>
                }
              ></Empty>
            </>
          ),
        }}
        rowClassName={(r) => {
          if (r?.level === 1) {
            return "no-header";
          } else if (r?.level === 2) {
            return "table-header no-header ";
          } else if (r?.level === 3 && r?.children) {
            return "no-header";
          } else if (r?.level === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "table-confirm-first table-confirm-level-course table-bottom--level-course-num";
            } else {
              return "table-confirm-first table-confirm-level-course";
            }
          } else if (r?.level === 4) {
            num.current = r?.children?.length;
            return "table-header no-header table-confirm-level-course no-cursor table-top-level-course ";
          }
          return "";
        }}
        rowKey={"key"}
        key={"key"}
        columns={columns?.filter((item) => {
          return item?.disable === undefined || item?.disable === true;
        })}
        dataSource={orderList}
        expandRowByClick={true}
        onDoubleClick={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default TableOrderList;
