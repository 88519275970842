import React, { useEffect, useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useMainMedicineDoctorRoom } from "@provider/medicine_doctor_room";
import { useWorkOrderItemRejectDrugFormat } from "@services/api/work_order_item";
import ModalInfoDrug from "./modal_info_drug/ModalInfoDrug";
import OrderList from "./OrderList";

const Medicine = () => {
  const { title, medicalRecord, buttonName, draftOrderList, drug } =
    useMainMedicineDoctorRoom();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const workOrderItemRejectDrug = useWorkOrderItemRejectDrugFormat(visitID);

  useEffect(() => {
    if (!workOrderItemRejectDrug?.data?.length) return;
    setOpen(true);
    // eslint-disable-next-line
  }, [workOrderItemRejectDrug?.data]);

  useEffect(() => {
    if (!draftOrderList?.optionDrug?.groups?.length) return;
    setOpen(true);
    // eslint-disable-next-line
  }, [draftOrderList?.optionDrug?.groups?.length]);

  const viewDetail = () => {
    setOpen(!open);
  };

  const InfoManagerModal = () => {
    setVisible(!visible);
  };

  return (
    <div className="mt-2">
      <Card
        className="comment-card mt-2 card-out-padding "
        extra={
          <React.Fragment>
            <Row align="middle" justify="space-between">
              <Col className="f-normal col-start t-dark-blue">
                <span className="pr-05 f-bold cursor" onClick={viewDetail}>
                  {t(title)}
                </span>

                <span className="pr-05"></span>
                {!drug && (
                  <span className="pr-05">
                    {React.createElement(open ? UpOutlined : DownOutlined, {
                      onClick: viewDetail,
                    })}
                  </span>
                )}
              </Col>
              {!!medicalRecord ? (
                <Col className="col-end">
                  {!!workOrderItemRejectDrug?.data?.length ||
                  draftOrderList?.optionDrug?.groups?.length ||
                  drug ? (
                    <Button type="primary" onClick={InfoManagerModal}>
                      {buttonName && t(buttonName)}
                    </Button>
                  ) : null}
                </Col>
              ) : null}
            </Row>
          </React.Fragment>
        }
      >
        {drug ? null : (
          <div className={open ? "open" : "close"}>
            <OrderList
              InfoManagerModal={InfoManagerModal}
              orderItemRejectDrug={workOrderItemRejectDrug?.data || []}
            />
          </div>
        )}
      </Card>
      {visible && (
        <ModalInfoDrug InfoManagerModal={InfoManagerModal} visible={visible} />
      )}
    </div>
  );
};

export default Medicine;
