import { Button, Modal, ModalProps } from "antd";
import React from "react";

interface Props extends ModalProps {
  onOk?: (e?: any) => void;
  okText?: string;
  cancelText?: string;
  // onModal?: ([key]?: any, [key2]?: any, [key3]?: any) => void;
  onCancel?: () => void;
  visible?: boolean;
  children?: React.ReactNode;
  header?: any;
  width?: string;
  footer?: boolean | any;
  closable?: boolean;
  loadingButton?: boolean;
}
const Modaler = ({
  onOk,
  // onModal,
  onCancel,
  loadingButton,
  header = " ",
  visible,
  children,
  width = "50%",
  footer = true,
  closable = true,
  okText = "ยืนยัน",
  cancelText = "ยกเลิก",
  ...props
}: Props) => {
  return (
    <div>
      {footer ? (
        <Modal
          {...props}
          title={header}
          onCancel={onCancel}
          visible={visible}
          width={width}
          centered
          closable={closable}
          footer={
            typeof footer === "boolean"
              ? [
                  <Button
                    className="cancel"
                    key="1"
                    htmlType="submit"
                    onClick={onCancel}
                  >
                    {cancelText}
                  </Button>,
                  <Button
                    key="2"
                    className="btn-create"
                    htmlType="submit"
                    onClick={onOk}
                    loading={loadingButton}
                  >
                    {okText}
                  </Button>,
                ]
              : footer
          }
        >
          {children}
        </Modal>
      ) : (
        <Modal
          title={header}
          visible={visible}
          onCancel={onCancel}
          width={width}
          centered
          onOk={onOk}
          okText="ยืนยัน"
          cancelText="ยกเลิก"
          closable={closable}
          footer={
            <>
              <Button key="3" onClick={onOk} type="primary">
                OK
              </Button>
            </>
          }
        >
          {children}
        </Modal>
      )}
    </div>
  );
};

export default Modaler;
