import { IItem } from "@interface/IOrder.interface";
import { Popover } from "antd";
import { IProduct } from "@interface/IDraftOrder.interface";
import { ICheckUpdate } from "@interface/IGlobal.interface";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetProducts } from "@services/api/order";
import { useSetRecoilState } from "recoil";
import { loadingModalState } from "@tools/recoil/recoil-atom";
import { checkUpdate, productName } from "@tools/util";
import { useEffect, useRef, useState } from "react";
import ChildrenTable from "./ChildrenTable";
import SelectionTable from "../../table/TableSelection";
import SearchProductCategory from "../Search";
import BigNumber from "bignumber.js";

type Props = {
  categoriesID: string;
  selectedRowKeys: any;
  setSelectedRowKeys: (e: any) => void;
  title: string;
  status?: string;
};

const Course = ({
  categoriesID,
  selectedRowKeys,
  setSelectedRowKeys,
  title,
  status = "",
}: Props) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [search, setSearch] = useState<any>({});
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const setLoadingModal = useSetRecoilState<boolean>(loadingModalState);
  const match: any = useRouteMatch();
  const eventId = match.params.eventID;
  const productsList = useGetProducts(
    categoriesID,
    categoriesID === "C-0007" && status === "OUT_TYPE"
      ? {
          page: currentPage,
          limit: limitPage,
          search: search.name,
          eventId: eventId,
          isPromotion: true,
        }
      : {
          page: currentPage,
          limit: limitPage,
          search: search.name,
        }
  );
  const isSelect = useRef<number[]>([]);

  useEffect(() => {
    setTotal(productsList?.data?.result?.[0]?.total || 1);
    isSelect.current =
      productsList?.data?.result?.[0]?.data?.flatMap((item) => item?.id) || [];
    // eslint-disable-next-line
  }, [productsList?.data]);

  useEffect(() => {
    setLoadingModal(productsList?.isLoading);
    // eslint-disable-next-line
  }, [productsList?.isLoading]);

  // useEffect(() => {
  //   handleGetProductsList();
  //   // eslint-disable-next-line
  // }, []);

  // const handleGetProductsList = async () => {
  //   let filter = searchQrs([
  //     {
  //       qrs: `{"$or":[{"group.nameTh":{"$contL":"%variable"}},{"group.nameTh":{"$contL":"%variable"}},{"nameTh":{"$contL":"%variable"}},{"nameEn":{"$contL":"%variable"}}]}`,
  //       value: search.name,
  //     },
  //   ]);

  //   try {
  //     setLoadingModal(true);
  //     const res: IResStatus = await getProducts({ s: filter }, categoriesID);
  //     if (res.code === 200) {
  //       Array.isArray(res.result?.[0]) === false && setProductsList(res.result);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  //   setLoadingModal(false);
  // };

  const onSelectChange = (selectedRowKeysTable: any, value: any) => {
    try {
      const check = checkUpdate(
        isSelect.current,
        selectedRowKeysTable
      ) as ICheckUpdate;
      const select = Array.from(
        new Set([
          ...(check?.newData || []),
          ...(check?.oldData || []),
          ...(selectedRowKeys?.[5]?.key || []),
        ])
      )?.filter((val) => {
        if (check?.removeData?.includes(val)) {
          return false;
        } else {
          return true;
        }
      });
      const dataList = selectedRowKeys?.map((valueList: any, index: number) => {
        if (index === 5) {
          return {
            key: select,
            dataList: Array.from(
              new Map(
                [
                  ...(selectedRowKeys?.[index]?.dataList || []),
                  ...(value || []),
                ]?.map((item) => [item?.id, item])
              )?.values()
            )?.filter((value) => select?.includes(value?.id)),
            number: null,
          };
        } else return valueList;
      });

      setSelectedRowKeys(dataList);
      isSelect.current = selectedRowKeysTable;
    } catch (err) {
      console.log(err);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys[5].key,
    getCheckboxProps: (product: IItem) => ({
      disabled: product?.productBranch?.[0]?.qty < 1,
    }),
    onChange: onSelectChange,
  };

  const onFinish = (value: any) => {
    setSearch(value);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const dataList = (record: number) => {
    return (
      <ChildrenTable
        record={record}
        dataSource={productsList?.data?.result?.[0]?.data}
      />
    );
  };

  const expandable = {
    expandedRowRender: (_: any, index: any) => dataList(index),
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "5%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.key}>
            {data?.level === 1 && limitPage * (currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: t("examinationName"),
      dataIndex: "name",
      key: "name",
      render: (_: IItem, name: IItem) => {
        return (
          <div>{productName(i18n.language, name?.nameTh, name?.nameEn)}</div>
        );
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",
      key: "qty",
    },
    status &&
      status === "promotion" && {
        title: t("thaiPrice"),
        dataIndex: "normal",
        key: "normal",
        render: (normal: string) => {
          return new BigNumber(normal || "0").toFormat(2);
        },
      },
    status &&
      status === "promotion" && {
        title: t("foreignerPrice"),
        dataIndex: "foreigner",
        key: "foreigner",
        render: (foreigner: string) => {
          return new BigNumber(foreigner || "0").toFormat(2);
        },
      },
    {
      title: t("details"),
      dataIndex: "descriptionTh",
      key: "descriptionTh",
      render: (_: string, data: IItem & IProduct) => {
        const description =
          i18n.language === "th" ? data?.descriptionTh : data?.descriptionEn;
        return (
          <Popover content={description || "-"}>
            <div className="text-content">{description}</div>
          </Popover>
        );
      },
    },
  ];

  return (
    <div>
      <SearchProductCategory title={title} onFinish={onFinish} />

      <SelectionTable
        scroll={{ x: "calc(100vw -20%)" }}
        dataSource={
          productsList?.data?.result?.[0]?.data?.map((item) => {
            const productIndex = selectedRowKeys?.[5]?.dataList
              ?.flatMap((productId: IItem) => productId?.id)
              ?.indexOf(item?.id);
            if (productIndex > -1) {
              return selectedRowKeys?.[5]?.dataList?.[productIndex];
            } else {
              return item;
            }
          }) || []
        }
        columns={columns?.filter((item: any) => item)}
        onChangePage={onChangePage}
        rowSelection={rowSelection}
        expandable={expandable}
        expandRowByClick={true}
        loading={productsList?.isLoading}
        rowKey="key"
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        size="middle"
      />
    </div>
  );
};

export default Course;
