import { IGetVisits } from "@interface/IVisits.interface";
import { TextBRHTML } from "@tools/util";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useUpdateVisits } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { IPhysicalVisible } from "@interface/IGlobal.interface";
import { useSetRecoilState } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { useEffect, useState } from "react";
import { IVisitsUpDatePhysical } from "@interface/IVisits.interface";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Col, Empty, Form, Row } from "antd";
import React from "react";
import empty from "@assets/img/empty.svg";
import moment from "moment";
import ModalPhysicalRecord from "./ModalPhysicalRecord";

type keyName = "physicalNote" | "soapNote";
interface ITypeOpen {
  oneDay: boolean;
  continuous: boolean;
  soap: boolean;
  notes: boolean;
}

interface IOpen {
  open: ITypeOpen;
  setOpen: (e: ITypeOpen) => void;
}

type Props = {
  title: string;
  name: keyName;
  visitsByID: IGetVisits | undefined | any;
  medicalRecord: boolean;
  open?: IOpen;
  haveData?: string;
};

const PhysicalRecord = ({
  title,
  name,
  visitsByID,
  medicalRecord,
  open,
  haveData,
}: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<IPhysicalVisible>({
    physicalNote: false,
    soapNote: false,
  });
  const setLoadingButton = useSetRecoilState(loadingButtonState);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const [modalNote] = Form.useForm();
  const client = useQueryClient();
  const updateVisits = useUpdateVisits();

  useEffect(() => {
    setLoadingButton(updateVisits.isLoading);
    // eslint-disable-next-line
  }, [updateVisits.isLoading]);

  const onClick = () => {
    modalNote.setFieldsValue({
      ...visitsByID[`${name}`],
      date: visitsByID[`${name}`]?.date
        ? moment(visitsByID[`${name}`]?.date)
        : moment(),
    });
    setVisible({ ...visible, [`${name}`]: !visible[`${name}`] });
  };

  const onFinishUpdate = (value: IVisitsUpDatePhysical) => {
    handleUpdateVisitById(value);
  };

  const handleUpdateVisitById = (changed: IVisitsUpDatePhysical) => {
    const formData = new FormData();
    formData?.append(`${name}`, JSON.stringify(changed));

    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          setVisible({ ...visible, [`${name}`]: !visible[`${name}`] });
          client.invalidateQueries(["get-visitsByID", visitID]);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const viewDetailSoap = () => {
    open?.setOpen({ ...open?.open, soap: !open?.open.soap });
  };

  return (
    <div>
      <div className="mt-2 ">
        <Row className=" header-table " justify="space-between" align="middle">
          <Col className="f-normal  t-dark-blue  ">
            <span className="f-bold mr-05 ">{title}</span>
            {open ? (
              <span>
                {React.createElement(
                  open?.open.soap ? UpOutlined : DownOutlined,
                  {
                    onClick: viewDetailSoap,
                  }
                )}
              </span>
            ) : null}
          </Col>
          <Col lg={6} className="col-end">
            {haveData && (
              <Button type="primary" onClick={onClick}>
                {t("edit")}
              </Button>
            )}
          </Col>
        </Row>

        {open ? (
          <div className={open?.open.soap ? "open" : "close"}>
            <div className="t-black-light p-1 border-note ">
              {visitsByID?.[`${name}`] && name === "physicalNote" ? (
                <React.Fragment>
                  <div>
                    {`${t("diagnosis")} / ${t("importantSymptoms")} : `}
                    {visitsByID[`${name}`]?.symptoms}
                  </div>
                  <div>
                    {`${t("summaryPatientProblems")} : `}
                    {visitsByID[`${name}`]?.summary}{" "}
                  </div>
                  <div>
                    {`${t("shortTermGoals")} : `}
                    {visitsByID[`${name}`]?.shortTerm}{" "}
                  </div>
                  <div>
                    {`${t("longTermGoals")} : `}
                    {visitsByID[`${name}`]?.longTerm}{" "}
                  </div>
                  <div>
                    {`${t("physicalTherapyTreatmentPlan")} :  
                ${visitsByID[`${name}`]?.treatmentPlan}`}
                  </div>
                </React.Fragment>
              ) : visitsByID?.[`${name}`] && name === "soapNote" ? (
                <>
                  <div>
                    <span className="f-bold">{`${t("date")} : `}</span>

                    {visitsByID[`${name}`]?.date
                      ? moment(visitsByID[`${name}`]?.date).format("DD-MM-YYYY")
                      : ""}
                  </div>

                  <div style={{ display: "flex" }}>
                    <div className="f-bold mr-05">Duration : </div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: TextBRHTML(
                            visitsByID[`${name}`]?.duration || "-"
                          ),
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div className="f-bold mr-05">S :</div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: TextBRHTML(visitsByID[`${name}`]?.s || ""),
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div className="f-bold mr-05">O : </div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: TextBRHTML(visitsByID[`${name}`]?.o || ""),
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div className="f-bold mr-05">A : </div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: TextBRHTML(visitsByID[`${name}`]?.a || ""),
                        }}
                      />
                    </div>
                  </div>

                  <div style={{ display: "flex" }}>
                    <div className="f-bold mr-05">P : </div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: TextBRHTML(visitsByID[`${name}`]?.p || ""),
                        }}
                      />
                    </div>
                  </div>

                  <div>
                    <span className="f-bold">
                      {`${t("physicalTherapist")} : `}
                    </span>
                    {visitsByID[`${name}`]?.physical || ""}
                  </div>
                </>
              ) : (
                <Empty
                  image={empty}
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    <span className="t-grey-white">
                      {t("no")} {title}
                    </span>
                  }
                >
                  {medicalRecord ? (
                    <Button type="primary" onClick={onClick}>
                      {t("createNow")}
                    </Button>
                  ) : null}
                </Empty>
              )}
            </div>
          </div>
        ) : (
          <div className="t-black-light p-1 border-note ">
            {visitsByID?.[`${name}`] && name === "physicalNote" ? (
              <>
                <div>
                  <span className="f-bold">
                    {`${t("diagnosis")} / ${t("importantSymptoms")} : `}
                  </span>

                  <span
                    dangerouslySetInnerHTML={{
                      __html: TextBRHTML(visitsByID[`${name}`]?.symptoms || ""),
                    }}
                  />
                </div>
                <div>
                  <span className="f-bold">
                    {`${t("summaryPatientProblems")} : `}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: TextBRHTML(visitsByID[`${name}`]?.summary || ""),
                    }}
                  />
                </div>
                <div>
                  <span className="f-bold">{`${t("shortTermGoals")} : `}</span>

                  <span
                    dangerouslySetInnerHTML={{
                      __html: TextBRHTML(
                        visitsByID[`${name}`]?.shortTerm || ""
                      ),
                    }}
                  />
                </div>
                <div>
                  <span className="f-bold">{`${t("longTermGoals")} : `}</span>

                  <span
                    dangerouslySetInnerHTML={{
                      __html: TextBRHTML(visitsByID[`${name}`]?.longTerm || ""),
                    }}
                  />
                </div>
                <div>
                  <span className="f-bold">
                    {`  ${t("physicalTherapyTreatmentPlan")} :  `}
                  </span>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: TextBRHTML(
                        visitsByID[`${name}`]?.treatmentPlan || ""
                      ),
                    }}
                  />
                </div>
              </>
            ) : visitsByID?.[`${name}`] && name === "soapNote" ? (
              <>
                <div>
                  Date :
                  {visitsByID[`${name}`]?.date
                    ? moment(visitsByID[`${name}`]?.date).format("DD-MM-YYYY")
                    : ""}
                </div>
                <div>S : {visitsByID[`${name}`]?.s} </div>
                <div>O : {visitsByID[`${name}`]?.o} </div>
                <div>A : {visitsByID[`${name}`]?.a} </div>
                <div>P : {visitsByID[`${name}`]?.p} </div>
                <div>
                  {`${t("physicalTherapist")} : `}
                  {visitsByID[`${name}`]?.physical}
                </div>
              </>
            ) : (
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">
                    {t("no")} {title}
                  </span>
                }
              >
                {medicalRecord ? (
                  <Button type="primary" onClick={onClick}>
                    {t("createNow")}
                  </Button>
                ) : null}
              </Empty>
            )}
          </div>
        )}
      </div>

      {visible[`${name}`] && (
        <ModalPhysicalRecord
          visible={{ visible: visible, setVisible: setVisible }}
          name={name}
          title={title}
          onFinishUpdate={onFinishUpdate}
          modalNote={modalNote}
        />
      )}
    </div>
  );
};

export default PhysicalRecord;
