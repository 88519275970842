import React, { FC, useEffect } from "react";
import { Age } from "@tools/age";
import { gender } from "@assets/data/data";
import { ENUM_G6PD } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useMainPatientById } from "@provider/patient_by_id";
import { Card, Col, Input, Row, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import SymbolView from "@components/symbol/Symbol";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";

type Props = {
  heightView: number;
};

const Fields: FC<Props> = ({ heightView }) => {
  const { patientById } = useMainPatientById();
  const { t, i18n } = useTranslation();
  const [basicInfoForm] = Form.useForm();

  useEffect(() => {
    let address: string = "";
    const mailingAddress = patientById?.mailingAddress;
    if (mailingAddress?.addressTh || mailingAddress?.districtTh) {
      address = [
        mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
        mailingAddress[i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"],
        mailingAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
        mailingAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
        mailingAddress?.zipcode,
        mailingAddress?.country,
      ]
        ?.join(" ")
        ?.trim();
    } else {
      const homeAddress = patientById?.homeAddress;
      if (!!homeAddress) {
        address = [
          homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
          homeAddress[i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"],
          homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
          homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
          homeAddress?.country,
          homeAddress?.zipcode,
        ]
          ?.join(" ")
          ?.trim();
      }
    }
    basicInfoForm.setFieldsValue({
      address: !!address ? address : "",
      age: patientById?.dateOfBirth ? Age(patientById?.dateOfBirth) : "",
      ...patientById,
    });
    // eslint-disable-next-line
  }, [patientById, i18n.language]);

  const viewHeight = () => {
    return window?.innerHeight - heightView - 10;
  };

  return (
    <Card
      className="bg-grey  height-body scrollbar-list "
      style={{
        height: `${viewHeight()}px`,
        minHeight: `${viewHeight()}px`,
      }}
      extra={
        <React.Fragment>
          <Row gutter={[14, 14]} className="text-row-profile">
            <Col>
              <ImagePatient src={patientById?.image} />
            </Col>
            <Col>
              <Row>
                <Col xs={24} lg={24}>
                  {patientById
                    ? i18n.language === "th"
                      ? `${patientById?.firstNameTh}  ${patientById?.lastNameTh}`
                      : `${patientById?.firstNameEn}  ${patientById?.lastNameEn}`
                    : " "}
                </Col>

                <Col xs={24} lg={24}>
                  <div className="code-profile">
                    HN : {patientById ? patientById?.hn : " "}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <div style={{ color: "#FEB139" }}>
                <SymbolView />
              </div>
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {patientById?.g6pd === ENUM_G6PD.DEFICIENCY
                  ? "G6PD Deficiency"
                  : null}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      }
    >
      <Row justify="center" className="pb-1">
        <Form name="basicInfoForm" form={basicInfoForm}>
          <Col lg={23}>
            <span className="fs-15 fw-900 mt-2 ">{t("generalInfo.title")}</span>

            <Card className="mt-05">
              <Row gutter={[14, 14]} className="margin-form">
                <Col md={3} lg={3} className="text-col-center">
                  {t("sex")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="gender">
                    <Selector
                      disabled={true}
                      Source={gender}
                      keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                      keyValue="id"
                    />
                  </Form.Item>
                </Col>

                <Col md={3} lg={3} className="text-col-center">
                  {t("age")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="age">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col md={3} lg={3} className="text-col-center">
                  {t("dateOfBirth")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="dateOfBirth">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col md={3} lg={3} className="text-col-center">
                  {t("telephoneNumber")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="mobile">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Row gutter={[0, 14]}>
                    <Col span={6} className="text-col-center">
                      {t("email")} :
                    </Col>

                    <Col span={18}>
                      <Form.Item name="email">
                        <Input disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6} className="text-col-center">
                      {t("height")} :
                    </Col>

                    <Col span={18}>
                      <Form.Item name="height">
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col md={3} lg={3} className="text-col-center">
                  {t("address")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="address">
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <ImportantInfo />

            <div className="fs-15 fw-900 mt-2  ">{t("contractCompany")}</div>

            <Card className="mt-05">
              <Row gutter={[14, 14]} className="margin-form">
                {patientById?.patientInsurance?.length ? (
                  patientById?.patientInsurance?.map((item, index: number) => {
                    return (
                      <Col lg={12} key={index}>
                        <Row>
                          <Col lg={4} className="text-col-center">
                            {`${index + 1}.`}
                          </Col>

                          <Col lg={20}>
                            <Input
                              disabled
                              value={
                                i18n.language === "th"
                                  ? item?.insurance?.nameTh
                                  : item?.insurance?.nameEn
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    );
                  })
                ) : (
                  <Col lg={24}>
                    <div className="t-grey-light">
                      {t("informationContracting")}
                    </div>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </Form>
      </Row>
    </Card>
  );
};

export default Fields;
