import React, { useEffect, useState } from "react";
import { dayjs } from "@tools/timezone";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { useGetAppointments } from "@services/api/appointments";
import { IAppointments, ISlot } from "@interface/IAppointments.interface";
import { IParamsGetAppointment } from "@interface/IAppointments.interface";
import MoTable from "@components/table/Table";
import SearchModalBooking from "./Search";

const Table = () => {
  const { t, i18n } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const appointmentList = useGetAppointments();
  const [search, setSearch] = useState<IParamsGetAppointment>();

  useEffect(() => {
    appointmentList.mutate({
      ...search,
      limit: limitPage,
      page: currentPage,
      modal: true,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limitPage, search]);

  useEffect(() => {
    setTotal(appointmentList?.data?.result?.[0]?.total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentList?.data]);

  const onFinish = (values: IParamsGetAppointment) => {
    const current = dayjs(values?.date);
    let startDate = undefined;
    let endDate = undefined;

    if (values?.selectDate === 3) {
      startDate = current.startOf("month").format("YYYY-MM-DD");
      endDate = current.endOf("month").format("YYYY-MM-DD");
    }
    if (values?.selectDate === 2) {
      startDate = current.startOf("week").format("YYYY-MM-DD");
      endDate = current.endOf("week").format("YYYY-MM-DD");
    } else if (values?.selectDate === 1) {
      startDate = current.startOf("day").format("YYYY-MM-DD");
      endDate = current.endOf("day").format("YYYY-MM-DD");
    }
    delete values?.date;
    delete values?.selectDate;
    setSearch({ ...values, startDate: startDate, endDate: endDate });
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: number, __: IAppointments, index: number) => {
        return <div>{limitPage * (currentPage - 1) + index + 1}</div>;
      },
    },

    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <div>{dayjs(date).format("DD-MM-YYYY")}</div>;
      },
    },

    {
      title: t("startTime"),
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: string) => {
        return <div>{dayjs(startDate).format("HH:mm")}</div>;
      },
    },

    {
      title: t("endTime"),
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: string) => {
        return <div>{dayjs(endDate).format("HH:mm")}</div>;
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "patient",
      render: (item: IGetPatientList) => {
        const name =
          i18n.language === "th"
            ? [item?.firstNameTh, item?.lastNameTh]?.join("   ")?.trim()
            : [item?.firstNameEn, item?.lastNameEn]?.join("   ")?.trim();
        return (
          <div className="f-normal " key={item?.id}>
            {name}
          </div>
        );
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "patient",
      key: "mobile",
      render: (item: IGetPatientList) => {
        return <div>{item?.mobile}</div>;
      },
    },

    {
      title: `${t("doctorName")} `,
      dataIndex: "slot",
      key: "slot",
      render: (slot: ISlot) => {
        const name =
          i18n.language === "th"
            ? [slot?.user?.firstNameTh, slot?.user?.lastNameTh]
                ?.join("   ")
                ?.trim()
            : [slot?.user?.firstNameEn, slot?.user?.lastNameEn]
                ?.join("   ")
                ?.trim();
        return <div className="f-normal ">{name}</div>;
      },
    },

    {
      title: t("room"),
      dataIndex: "slot",
      key: "slot",
      render: (slot: ISlot) => {
        return <div>{slot?.room?.name || ""}</div>;
      },
    },
  ];

  return (
    <React.Fragment>
      <SearchModalBooking onFinish={onFinish} />
      <MoTable
        rowKey="id"
        key="id"
        className="no-cursor-header no-cursor"
        dataSource={appointmentList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </React.Fragment>
  );
};

export default Table;
