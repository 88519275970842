import React, { useEffect, useState } from "react";
import { IUserList } from "@interface/IUsers.interface";
import { onImgError } from "@tools/util";
import { useTranslation } from "react-i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { branchIDState, collapsedState } from "../tools/recoil/recoil-atom";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Col, Dropdown, Menu, Modal, Row } from "antd";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import DropDown from "../assets/img/icon/dropdown.svg";
import UserContext from "@provider/UserProvider";
import Notifications from "./Notifications";
import SelectorBranch from "@components/selector/SelectorBranch";
import { ENUM_ROLE_EN } from "@tools/util.enum";

type Props = {
  toggleSidebar: () => void;
  profile?: IUserList;
};

const TheHeader = ({ toggleSidebar, profile }: Props) => {
  const { t, i18n } = useTranslation();
  const collapsed = useRecoilValue(collapsedState);
  const history = useHistory<RouteComponentProps["history"]>();
  const [visible, setVisible] = useState<boolean>(false);
  const setBranchID = useSetRecoilState(branchIDState);
  const userList = React.useContext(UserContext);

  useEffect(() => {
    setBranchID(userList?.branch);

    // eslint-disable-next-line
  }, [userList]);

  const clearLocalStorage = () => {
    setVisible(!visible);
    localStorage.removeItem("userInfo");
    localStorage.removeItem("accessToken");
    window.location.reload();
  };

  const onClickModal = () => {
    setVisible(!visible);
  };

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("local-lang", lang);
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          history.replace(`/setting-profile`);
        }}
      >
        <h5 className="list-menu mb-0">{t("setUpProfile")}</h5>
      </Menu.Item>
      <Menu.Item
        key="2"
        onClick={() => {
          setVisible(!visible);
        }}
      >
        <h5 className="list-menu mb-0">{t("logOut")}</h5>
      </Menu.Item>
    </Menu>
  );

  return (
    <React.Fragment>
      <div className="header">
        <Row className="header-row" justify="space-between" align="middle">
          <Col>
            <div className={collapsed ? "collapsed-class" : "collapsed-open"}>
              {React.createElement(
                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                {
                  className: "trigger",
                  onClick: toggleSidebar,
                }
              )}
            </div>
          </Col>
          <Col className="d-flex justify-flex-end items-center">
            {(userList?.role === ENUM_ROLE_EN.SUPER_ADMIN ||
              userList?.role === ENUM_ROLE_EN.SUPER_MARKETING) && (
              <div className="mr-1">
                <SelectorBranch />
              </div>
            )}
            <div className=" language-header ">
              <span
                className={
                  i18n.language === "en"
                    ? "pr-05 f-bold hover c-white"
                    : "pr-05 hover c-whitesmoke"
                }
                onClick={() => changeLanguage("en")}
              >
                EN
              </span>
              <span className="pr-05 c-whitesmoke">|</span>
              <span
                className={
                  i18n.language === "th"
                    ? "pr-05 f-bold hover c-white"
                    : "pr-05 hover c-whitesmoke"
                }
                onClick={() => changeLanguage("th")}
              >
                TH
              </span>
            </div>
            <Notifications />
            <div className="pr-1">
              <Dropdown
                overlay={menu}
                placement="bottomCenter"
                className="cursor"
              >
                <div className="d-flex justify-center menu-hover ">
                  <div className="pr-1 display-center">
                    <img
                      src={profile?.image || onImgError()}
                      className="square avatar"
                      alt="avatar"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = onImgError();
                      }}
                    />
                  </div>
                  <div className="pr-1">
                    <h4 className="c-white mb-0 menu-list">
                      {i18n.language === "th"
                        ? [profile?.firstNameTh, profile?.lastNameTh]
                            ?.join(" ")
                            ?.trim()
                        : [profile?.firstNameEn, profile?.lastNameEn]
                            ?.join(" ")
                            ?.trim()}
                    </h4>
                    <h5 className="c-whitesmoke mb-0 menu-list">
                      {profile?.username}
                    </h5>
                  </div>
                  <div className="d-flex items-center">
                    <img
                      src={DropDown}
                      alt="DropDown"
                      style={{ width: "32px" }}
                      className="c-white"
                    />
                  </div>
                </div>
              </Dropdown>
            </div>
          </Col>
        </Row>
      </div>
      <Modal
        title={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
        visible={visible}
        onOk={clearLocalStorage}
        centered
        onCancel={onClickModal}
        okText={t("confirm")}
        cancelText={t("cancel")}
      >
        {t("sureToLogOut")}
      </Modal>
    </React.Fragment>
  );
};

export default TheHeader;
