import { useState } from "react";
import Table from "./Table";
import Search from "./Search";

const UserRoom = () => {
  const [searchValue, setSearchValue] = useState<any>({
    name: "",
    branchId: null,
  });
  const [currentPage, setCurrentPage] = useState<number>(1);

  const userRoomFilter = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };

  return (
    <div>
      <Search onFinish={userRoomFilter} />
      <Table
        searchValue={searchValue}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </div>
  );
};

export default UserRoom;
