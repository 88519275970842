import { useState } from "react";
import { ORDER_TYPE } from "@tools/util.enum";
import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { IChild, IItem, ISelected } from "@interface/IOrder.interface";
import { Form, Input, InputNumber, Table } from "antd";
import Decimal from "@tools/decimal";
import EditModal from "@components/modaler/EditModal";

type Props = {
  orderList: ISelectedExecuteOrder[];
  selection: ISelected;
  medicalRecord: boolean;
};

const OrderList = ({ orderList, selection, medicalRecord }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [editOrderQty] = Form.useForm();
  const [product, setProduct] = useState<any>({
    idProduct: "",
    number: null,
    validateStatus: "",
    errorMsg: null,
    level: null,
  });

  const onSelectChange = (selected: any) => {
    const result = selected?.filter(
      (item: string) => !item?.includes("children")
    );

    selection.setSelection({
      ...selection.selection,
      course: result,
    });
  };

  const rowSelection = {
    selectedRowKeys: selection.selection.course,
    onChange: onSelectChange,
  };

  const onCancelNumber = () => {
    setVisible(!visible);
  };

  const updateOrderItem = (value: any) => {
    orderList?.forEach((item: ISelectedExecuteOrder) => {
      item?.children?.[0]?.children?.forEach((itemChild) => {
        if (itemChild?.id === value?.id) {
          itemChild.qtyList = value?.qty;
        }

        if (itemChild?.children?.length > 0) {
          itemChild.children?.[0]?.children?.forEach((itemChildChild) => {
            if (itemChildChild?.id === value?.id) {
              itemChildChild.qtyList = value?.qty;
            }
          });
        }
      });
    });

    onCancelNumber();
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: string, data: IItem & IChild, index: number) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? index + 1
              : data?.level === 2
              ? data?.name
              : data?.level === 3
              ? data?.product?.nameTh
              : data?.level === 4
              ? ""
              : ""}
          </div>
        );
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem & IChild) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? data?.group
              : data?.level === 2
              ? data?.times
              : data?.level === 3
              ? data?.qtyOutstanding
                ? data?.qtyOutstanding
                : data?.qty + "/" + data?.qty
              : data?.level === 4
              ? t("nameList")
              : data?.product?.nameTh}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "qty",
      key: "qty",
      render: (_: string, data: IItem & IChild) => {
        return (
          <>
            {data?.level === 1 ? (
              data?.children?.[0]?.children?.length
            ) : data?.level === 2 ? (
              data?.quantity
            ) : data?.level === 3 && data?.children ? (
              <div>{data?.qty || ""}</div>
            ) : data?.level === 3 && !data?.children ? (
              <div>
                {data?.qtyList || data?.qtyOutstanding || ""}

                <EditOutlined
                  onClick={(e) => {
                    onCancelNumber();
                    editOrderQty.setFieldsValue({
                      id: data?.id,
                      qty: data?.qtyList || data?.qtyOutstanding,
                    });
                    setProduct({ ...product, number: data?.qtyOutstanding });
                    e.stopPropagation();
                  }}
                />
              </div>
            ) : data?.level === 4 ? (
              t("amount")
            ) : (
              <div>
                {data?.qtyList || data?.qtyOutstanding || ""}

                <EditOutlined
                  onClick={(e) => {
                    setProduct({ ...product, number: data?.qtyOutstanding });
                    editOrderQty.setFieldsValue({
                      id: data?.id,
                      qty: data?.qtyList || data?.qtyOutstanding,
                    });
                    onCancelNumber();
                    e.stopPropagation();
                  }}
                />
              </div>
            )}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",

      render: (_: string, data: IItem) => {
        return (
          <>
            {data?.level === 1
              ? ``
              : data?.level === 2
              ? t("type")
              : data?.level === 3
              ? data?.orderType === ORDER_TYPE.ONE_DAY
                ? `One Day`
                : data?.orderType === ORDER_TYPE.CONTINUE
                ? `Continuous`
                : `-`
              : data?.level === 4
              ? t("type")
              : "-"}
          </>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "total",
      key: "total",
      render: (item: string, data: IItem & IChild) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? Decimal(item)
              : data?.level === 2
              ? data?.price || ""
              : data?.level === 3
              ? Decimal(item)
              : data?.level === 4
              ? t("price")
              : Decimal(item)}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <EditModal
        visible={visible}
        onCancel={onCancelNumber}
        editOrder={editOrderQty}
        width="25%"
        header={<div>{t("modifyTheNumberOfProducts")}</div>}
      >
        <Form
          onFinish={updateOrderItem}
          name="editOrderQty"
          form={editOrderQty}
        >
          <Form.Item
            label={t("numberOfProducts")}
            name="qty"
            rules={[
              {
                required: true,
              },
              () => ({
                validator(_, value) {
                  if (value < 1 && value !== null) {
                    return Promise.reject(
                      new Error(
                        `${t("theNumberOfProductsIsNotLessThan")} 0 ${t(
                          "andNotMore"
                        )} ${product?.number}!`
                      )
                    );
                  } else if (value > product?.number) {
                    return Promise.reject(
                      new Error(
                        `${t("theNumberOfProductsIsNotLessThan")} 0 ${t(
                          "andNotMore"
                        )} ${product?.number}!`
                      )
                    );
                  }

                  return Promise.resolve();
                },
              }),
            ]}
          >
            <InputNumber className="modify-product" type="number" />
          </Form.Item>

          <Form.Item name="id">
            <Input type="hidden" />
          </Form.Item>
        </Form>
      </EditModal>

      <Table
        className="components-table-demo-nested table-selection table-confirm  "
        rowClassName={(r) => {
          if (r?.level === 1) {
            return " no-header ";
          } else if (r?.level === 2) {
            return "table-header no-header  ";
          } else if (r?.level === 3 && r?.children) {
            return " no-header table-confirm-level-course table-top-level-course";
          } else if (r?.level === 3 && r?.children) {
            return " no-header table-confirm-level-course ";
          } else if (r?.level === 3) {
            return " no-header ";
          } else if (r?.level === 5) {
            return "table-confirm-first no-header table-confirm-level-course table-bottom--level-course  ";
          } else if (r?.level === 4) {
            return "table-header no-header table-confirm-level-course redio-rowClass ";
          }
          return "";
        }}
        rowSelection={
          medicalRecord ? { ...rowSelection, checkStrictly: false } : undefined
        }
        expandedRowKeys={selection.selection.expandAllRows}
        onExpandedRowsChange={(e) =>
          selection.setSelection({
            ...selection.selection,
            expandAllRows: e,
          })
        }
        columns={columns}
        expandRowByClick
        showSorterTooltip
        rowKey={"key"}
        dataSource={orderList}
        pagination={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default OrderList;
