import { Age } from "@tools/age";
import { dayjs } from "@tools/timezone";
import { IItem } from "@interface/IOrder.interface";
import { IVisitDrug } from "@interface/IVisits.interface";
import { TextBRHTML } from "@tools/util";
import { FC, MutableRefObject } from "react";
import { Checkbox, Col, Form, Input, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import ImageLogo from "@components/image/ImageLogo";

type Props = {
  refValue: MutableRefObject<null>;
  reportTreatment?: IVisitDrug;
  indexMedication: number;
  indexIV: number;
  indexphysical: number;
  i18n: string;
};

const ReportProcedure: FC<Props> = ({
  refValue,
  reportTreatment,
  indexMedication,
  indexIV,
  indexphysical,
  i18n,
}) => {
  const nameTH = `${reportTreatment?.patient?.titleTh || ""}${[
    reportTreatment?.patient?.firstNameTh,
    reportTreatment?.patient?.lastNameTh,
  ]
    ?.join(" ")
    ?.trim()}`;

  const nameEN = `${reportTreatment?.patient?.titleEn || ""}${[
    reportTreatment?.patient?.firstNameEn,
    reportTreatment?.patient?.lastNameEn,
  ]
    ?.join(" ")
    ?.trim()}`;

  return (
    <div ref={refValue} className="treatment-print">
      <table className="report-drug t-center" width="100%">
        <thead>
          <tr>
            <th
              colSpan={1}
              rowSpan={2}
              className="rd-top rd-left"
              style={{ width: "20%" }}
            >
              <ImageLogo
                src={reportTreatment?.branch}
                alt="Logo"
                width="200px"
                height="70px"
              />
            </th>
            <th
              colSpan={20}
              className=" rd-top rd-right"
              style={{ width: "80%" }}
            >
              <Row justify="end">
                <Col className="mt-05 f-bold">HN: </Col>

                <Col className="t-underline-treatment-record mt-05">
                  <div className=" f-normal t-center">
                    {reportTreatment?.patient?.hn || "-"}
                  </div>
                </Col>

                <Col className="mt-05 f-bold ml-05">Date : </Col>

                <Col className="t-underline-treatment-record mt-05">
                  <div className=" f-normal t-center">
                    {dayjs(reportTreatment?.createdAt).format("DD-MM-YYYY")}
                  </div>
                </Col>
              </Row>
            </th>
          </tr>

          <tr>
            <th
              className="rd-right f-bold t-dark-blue"
              colSpan={20}
              style={{ width: "80%", textAlign: "start" }}
            >
              MEDICATION ADMINISTRATION & TREATMENT RECORD
            </th>
          </tr>

          <tr>
            <th colSpan={22} className="rd-right rd-left rd-bottom mt-1 ">
              <Row>
                <Col className="mt-05 f-bold">Name : </Col>

                <Col
                  style={{ minWidth: "150px" }}
                  className="t-underline-treatment-record mt-05"
                >
                  <div
                    className=" f-normal t-start"
                    style={{ paddingLeft: 10 }}
                  >
                    {i18n === "th" ? nameTH || "-" : nameEN || "-"}
                  </div>
                </Col>

                <Col className="mt-05 ml-05 f-bold">DOB : </Col>

                <Col
                  style={{ minWidth: "100px" }}
                  className="t-underline-treatment-record mt-05"
                >
                  <div
                    className=" f-normal t-start"
                    style={{ paddingLeft: 10 }}
                  >
                    {reportTreatment?.patient?.dateOfBirth
                      ? ` ${dayjs(reportTreatment?.patient?.dateOfBirth).format(
                          "DD-MM-YYYY"
                        )}`
                      : "-"}
                  </div>
                </Col>

                <Col className="mt-05 ml-05 f-bold">Age:</Col>

                <Col span={2} className="t-underline-treatment-record mt-05">
                  <div
                    className=" f-normal t-start "
                    style={{ paddingLeft: 10 }}
                  >
                    {reportTreatment?.patient?.dateOfBirth
                      ? Age(reportTreatment?.patient?.dateOfBirth || "")
                      : "-"}
                  </div>
                </Col>
                <Col className="mt-05 f-bold">y</Col>
                <Col className="mt-05 ml-05 f-bold">Allergies: </Col>

                <Col
                  style={{ minWidth: "100px" }}
                  className="t-underline-treatment-record mt-05 t-red"
                >
                  <div
                    className=" f-normal t-start"
                    style={{ paddingLeft: 10 }}
                  >
                    {`${[
                      reportTreatment?.patient?.drugAllergy,
                      reportTreatment?.patient?.foodAllergy,
                    ]
                      ?.join(" / ")
                      ?.trim()}`}
                  </div>
                </Col>
              </Row>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={22} className="rd-bottom rd-right rd-left">
              <Row>
                <Form.Item
                  noStyle
                  name="accessTypeOfDevice"
                  valuePropName="checked"
                >
                  <Checkbox className="treatment-checkbox treatment-print">
                    Access Type of device:
                  </Checkbox>
                </Form.Item>

                <Form.Item noStyle name="Jelco" valuePropName="checked">
                  <Checkbox className="treatment-checkbox treatment-print">
                    Jelco
                  </Checkbox>
                </Form.Item>

                <Form.Item noStyle name="port-ACath1" valuePropName="checked">
                  <Checkbox className="treatment-checkbox treatment-print">
                    Port-A cath
                  </Checkbox>
                </Form.Item>

                <Form.Item noStyle name="port-ACath2" valuePropName="checked">
                  <Checkbox className="treatment-checkbox treatment-print mr-05">
                    Port-A cath
                  </Checkbox>
                </Form.Item>

                <Col className="m-t-print"> No.</Col>
                <Col span={2} className="m-t-print t-underline-treatment ">
                  <div className="print-close">
                    <Form.Item name="no" noStyle>
                      <Input className="input-report" />
                    </Form.Item>
                  </div>

                  <div className=" f-normal t-center print-open ">
                    {reportTreatment?.visit?.data?.treatmentRecord?.no || ""}
                  </div>
                </Col>
                <Col className="mr-05 m-t-print"> Gauge</Col>
                <Col className="m-t-print" style={{ paddingRight: 2 }}>
                  {" "}
                  insert into
                </Col>

                <Form.Item noStyle name="lt" valuePropName="checked">
                  <Checkbox className="treatment-checkbox treatment-print ml-05">
                    Lt
                  </Checkbox>
                </Form.Item>

                <Form.Item noStyle name="rt" valuePropName="checked">
                  <Checkbox className="treatment-checkbox treatment-print ">
                    Rt.
                  </Checkbox>
                </Form.Item>

                <Col className="m-t-print "> Location: </Col>
                <Col span={2} className="m-t-print  t-underline-treatment   ">
                  <div className="print-close">
                    <Form.Item name="location" noStyle>
                      <Input className="input-report" />
                    </Form.Item>
                  </div>
                  <div className=" f-normal t-center print-open ">
                    {reportTreatment?.visit?.data?.treatmentRecord?.location ||
                      ""}
                  </div>
                </Col>
              </Row>
            </td>
          </tr>
          <tr>
            <td colSpan={5} className="rd-bottom rd-right rd-left f-bold">
              TIME
            </td>
            <td colSpan={4} className="rd-bottom rd-right f-bold">
              <Row gutter={[5, 0]}>
                <Col>Arrived time:</Col>
                <Col style={{ width: 50 }}>
                  <div className="print-close">
                    <Form.Item name="arrivedTime" noStyle>
                      <Input className="input-report" />
                    </Form.Item>
                  </div>
                  <div className=" f-normal t-center print-open ">
                    {reportTreatment?.visit?.data?.treatmentRecord
                      ?.arrivedTime || "__________"}
                  </div>
                </Col>
              </Row>
            </td>
            <td colSpan={4} className="rd-bottom rd-right f-bold">
              <Row gutter={[5, 0]}>
                <Col> Discharge time:</Col>
                <Col style={{ width: 50 }}>
                  <div className="print-close">
                    <Form.Item name="dischargeTime" noStyle>
                      <Input className="input-report" />
                    </Form.Item>
                  </div>
                  <div className=" f-normal t-center print-open ">
                    {reportTreatment?.visit?.data?.treatmentRecord
                      ?.dischargeTime || "__________"}
                  </div>
                </Col>
              </Row>
            </td>
            <td colSpan={8} className="rd-bottom rd-right f-bold">
              <Row justify="center" align="middle" gutter={[5, 0]}>
                <Col> BW </Col>
                <Col>
                  <div style={{ width: 50 }} className="print-close">
                    <Form.Item name="bw" noStyle>
                      <Input className="input-report" />
                    </Form.Item>
                  </div>
                  <div
                    style={{ width: 50 }}
                    className=" f-normal t-center print-open "
                  >
                    {` ${
                      reportTreatment?.visit?.data?.treatmentRecord?.bw ||
                      reportTreatment?.visit?.data?.visit?.bw ||
                      reportTreatment?.visit?.data?.lyingDown?.bw ||
                      reportTreatment?.visit?.data?.sitting1Min?.bw ||
                      reportTreatment?.visit?.data?.standing1Mins?.bw ||
                      "_______"
                    } `}
                  </div>
                </Col>
                <Col> kg</Col>
              </Row>
            </td>
          </tr>

          <tr>
            <td
              colSpan={5}
              className="rd-bottom rd-right rd-left  f-bold tb-treatment-blue"
              width="25%"
            >
              Orthostatic Position
            </td>
            <td className="rd-bottom rd-right f-bold tb-treatment-yellow br-width">
              BP
            </td>
            <td
              className="rd-bottom rd-right f-bold tb-treatment-yellow"
              width="5%"
            >
              HR
            </td>
            <td
              className="rd-bottom rd-right f-bold tb-treatment-yellow"
              width="5%"
            >
              BT
            </td>
            <td
              className="rd-bottom rd-right f-bold tb-treatment-yellow"
              width="5%"
            >
              SpO2%
            </td>
            <td
              className="rd-bottom rd-right f-bold tb-treatment-yellow"
              width="5%"
            >
              BP
            </td>
            <td
              className="rd-bottom rd-right f-bold tb-treatment-yellow"
              width="5%"
            >
              HR
            </td>
            <td
              className="rd-bottom rd-right f-bold tb-treatment-yellow"
              width="5%"
            >
              BT
            </td>
            <td
              className="rd-bottom rd-right f-bold tb-treatment-yellow"
              width="5%"
            >
              SpO2%
            </td>
            <td
              colSpan={8}
              rowSpan={4}
              className="rd-bottom rd-right rd-left"
              width="50%"
            >
              <b className="f-bold " style={{ textDecoration: "underline" }}>
                Note
              </b>
              : A drop in BP of ≥20 mm Hg, or in diastolic BP of ≥10 mm Hg, or
              experiencing lightheadedness or dizziness is considered abnormal.
            </td>
          </tr>

          <tr>
            <td colSpan={5} className="rd-bottom rd-right rd-left  t-start">
              Lying down (5mins)
            </td>
            <td className="rd-bottom rd-right ">
              {`${reportTreatment?.visit?.data?.lyingDown?.systolic || ""} / ${
                reportTreatment?.visit?.data?.lyingDown?.diastolic || ""
              }`}
            </td>
            <td className="rd-bottom rd-right ">
              {reportTreatment?.visit?.data?.lyingDown?.pRmin}
            </td>
            <td rowSpan={3} className="rd-bottom rd-right ">
              {reportTreatment?.visit?.data?.standing1Mins?.bt ||
                reportTreatment?.visit?.data?.sitting1Min?.bt ||
                reportTreatment?.visit?.data?.lyingDown?.bt}
            </td>
            <td className="rd-bottom rd-right ">
              {reportTreatment?.visit?.data?.lyingDown?.o2}
            </td>
            <td className="rd-bottom rd-right ">
              <div className="print-close">
                <Form.Item noStyle name="discharge1_bp">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge1_bp ||
                  ""}
              </div>
            </td>
            <td className="rd-bottom rd-right ">
              <div className="print-close">
                <Form.Item noStyle name="discharge1_hr">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge1_hr ||
                  ""}
              </div>
            </td>
            <td rowSpan={3} className="rd-bottom rd-right ">
              <div className="print-close">
                <Form.Item noStyle name="discharge_bt">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge_bt ||
                  ""}
              </div>
            </td>
            <td className="rd-bottom rd-right tb-treatment-height ">
              <div className="print-close">
                <Form.Item noStyle name="discharge1_o2">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge1_o2 ||
                  ""}
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={5} className="rd-bottom rd-right rd-left t-start">
              Sitting (1 min)
            </td>
            <td className="rd-bottom rd-right ">
              {`${
                reportTreatment?.visit?.data?.sitting1Min?.systolic || ""
              } / ${
                reportTreatment?.visit?.data?.sitting1Min?.diastolic || ""
              }`}
            </td>
            <td className="rd-bottom rd-right ">
              {reportTreatment?.visit?.data?.sitting1Min?.pRmin}
            </td>
            <td className="rd-bottom rd-right ">
              {reportTreatment?.visit?.data?.sitting1Min?.o2}
            </td>
            <td className="rd-bottom rd-right ">
              <div className="print-close">
                <Form.Item noStyle name="discharge2_bp">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge2_bp ||
                  ""}
              </div>
            </td>
            <td className="rd-bottom rd-right ">
              <div className="print-close">
                <Form.Item noStyle name="discharge2_hr">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge2_hr ||
                  ""}
              </div>
            </td>
            <td className="rd-bottom rd-right tb-treatment-height ">
              <div className="print-close">
                <Form.Item noStyle name="discharge2_o2">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge2_o2 ||
                  ""}
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={5} className="rd-bottom rd-right rd-left t-start">
              Standing (3mins)
            </td>
            <td className="rd-bottom rd-right ">
              {`${
                reportTreatment?.visit?.data?.standing1Mins?.systolic || ""
              } / ${
                reportTreatment?.visit?.data?.standing1Mins?.diastolic || ""
              }`}
            </td>
            <td className="rd-bottom rd-right ">
              {reportTreatment?.visit?.data?.standing1Mins?.pRmin}
            </td>
            <td className="rd-bottom rd-right ">
              {reportTreatment?.visit?.data?.standing1Mins?.o2}
            </td>
            <td className="rd-bottom rd-right ">
              <div className="print-close">
                <Form.Item noStyle name="discharge3_bp">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge3_bp ||
                  ""}
              </div>
            </td>
            <td className="rd-bottom rd-right ">
              <div className="print-close">
                <Form.Item noStyle name="discharge3_hr">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge3_hr ||
                  ""}
              </div>
            </td>
            <td className="rd-bottom rd-right tb-treatment-height ">
              <div className="print-close">
                <Form.Item noStyle name="discharge3_o2">
                  <Input className="input-report" />
                </Form.Item>
              </div>
              <div className="print-open ">
                {reportTreatment?.visit?.data?.treatmentRecord?.discharge3_o2 ||
                  ""}
              </div>
            </td>
          </tr>

          <tr>
            <td colSpan={22} className="rd-bottom rd-right rd-left t-start ">
              <Row>
                <Col
                  className="f-bold pr-05 "
                  style={{ textDecoration: "underline" }}
                >
                  Nurse Note:
                </Col>
                <Col span={20}>
                  <div className="print-close">
                    <Form.Item name="nurseNote" noStyle>
                      <TextArea
                        className="input-report-nursing-history"
                        autoSize
                      />
                    </Form.Item>
                  </div>

                  <div
                    className=" f-normal t-center print-open "
                    dangerouslySetInnerHTML={{
                      __html: TextBRHTML(
                        reportTreatment?.patient?.nurseNote || ""
                      ),
                    }}
                  />
                </Col>
              </Row>
            </td>
          </tr>

          <tr>
            <td
              colSpan={5}
              className="rd-bottom rd-right rd-left  f-bold"
              width="25%"
            >
              TREATMENT
            </td>
            <td colSpan={2} className="rd-bottom rd-right f-bold ">
              DOSE
            </td>
            <td colSpan={2} className="rd-bottom rd-right f-bold">
              FLUID VOL/IVP
            </td>
            <td colSpan={2} className="rd-bottom rd-right f-bold">
              START TIME
            </td>
            <td colSpan={2} className="rd-bottom rd-right f-bold">
              END TIME
            </td>
            <td colSpan={2} className="rd-bottom rd-right f-bold" width="6%">
              RN
            </td>
            <td colSpan={7} className="rd-bottom rd-right f-bold">
              Remarks
            </td>
          </tr>

          {indexMedication < 0 && indexIV < 0
            ? new Array(1)?.fill(null)?.map((_, index) => {
                return (
                  <tr key={index}>
                    <td
                      colSpan={5}
                      className="rd-bottom rd-right rd-left tb-treatment-height pl-1 t-start"
                      width="25%"
                    ></td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    ></td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    ></td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    ></td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    ></td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                      width="6%"
                    ></td>
                    <td
                      colSpan={7}
                      className="rd-bottom rd-right tb-treatment-height"
                    ></td>
                  </tr>
                );
              })
            : [
                ...(reportTreatment?.workOrderItem?.[indexIV]?.items || []),
                ...(reportTreatment?.workOrderItem?.[indexMedication]?.items ||
                  []),
              ]?.map((item: IItem, index: number) => {
                let options;
                if (item?.option) {
                  options = JSON.parse(item?.option || `{}`);
                }

                return (
                  <tr key={index}>
                    <td
                      colSpan={5}
                      className="rd-bottom rd-right rd-left tb-treatment-height pl-1 t-start"
                      width="25%"
                    >
                      {item?.product?.nameTh}
                    </td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    >
                      {item?.qty}
                    </td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    >
                      {options?.fluid}
                    </td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    >
                      {!!options?.start_time &&
                        dayjs(options?.start_time, "HH:mm:ss").format("HH:mm")}
                    </td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    >
                      {!!options?.end_time &&
                        dayjs(options?.end_time, "HH:mm:ss").format("HH:mm")}
                    </td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                      width="6%"
                    >
                      {item?.nurse?.firstNameEn}
                    </td>
                    <td
                      colSpan={7}
                      className="rd-bottom rd-right tb-treatment-height"
                    >
                      {item?.remark}
                    </td>
                  </tr>
                );
              })}

          <tr>
            <td colSpan={22} className="rd-bottom pt-1 "></td>
          </tr>

          <tr>
            <td colSpan={11} className="rd-bottom rd-right rd-left  f-bold">
              Physical therapy / Nursing care/ Education
            </td>
            <td colSpan={2} className="rd-bottom rd-right f-bold">
              TIME
            </td>
            <td colSpan={2} className="rd-bottom rd-right f-bold">
              Staff
            </td>
            <td
              colSpan={8}
              className="rd-bottom rd-right f-bold tb-treatment-height"
              width="25%"
            >
              Remarks
            </td>
          </tr>

          {indexphysical < 0
            ? new Array(1)?.fill(null)?.map((_, index) => {
                return (
                  <tr key={index}>
                    <td
                      colSpan={11}
                      className="rd-bottom rd-right rd-left tb-treatment-height pl-1 t-start"
                    ></td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height "
                    ></td>
                    <td
                      colSpan={2}
                      className="rd-bottom rd-right tb-treatment-height"
                    ></td>
                    <td
                      colSpan={8}
                      className="rd-bottom rd-right tb-treatment-height "
                      width="25%"
                    ></td>
                  </tr>
                );
              })
            : reportTreatment?.workOrderItem?.[indexphysical]?.items?.map(
                (item: IItem, index: number) => {
                  let options;
                  if (item?.option) {
                    options = JSON.parse(item?.option || `{}`);
                  }
                  return (
                    <tr key={index}>
                      <td
                        colSpan={11}
                        className="rd-bottom rd-right rd-left tb-treatment-height pl-1 t-start"
                      >
                        {item?.product?.nameTh}
                      </td>
                      <td
                        colSpan={2}
                        className="rd-bottom rd-right tb-treatment-height "
                      >
                        {options?.time}
                      </td>
                      <td
                        colSpan={2}
                        className="rd-bottom rd-right tb-treatment-height"
                      >
                        {item?.nurse?.firstNameEn}
                      </td>
                      <td
                        colSpan={8}
                        className="rd-bottom rd-right tb-treatment-height "
                        width="25%"
                      >
                        {item?.remark}
                      </td>
                    </tr>
                  );
                }
              )}

          <tr>
            <td
              colSpan={22}
              className="rd-bottom  rd-right  rd-left treatment-print"
            >
              <span>
                <Row justify="start">
                  <div
                    className=" m-t-print mr-05 f-normal"
                    style={{ color: "red" }}
                  >
                    BLOOD COLLECTION
                  </div>
                  <Form.Item noStyle name="bloodCollection">
                    <Checkbox.Group
                      style={{ textAlign: "left", display: "contents" }}
                    >
                      <Checkbox
                        value="no"
                        className="treatment-checkbox treatment-print"
                      >
                        NO
                      </Checkbox>
                      <Checkbox
                        value="yes"
                        className="treatment-checkbox treatment-print mr-05"
                      >
                        YES :
                      </Checkbox>
                    </Checkbox.Group>
                  </Form.Item>
                  {reportTreatment?.labs?.map((item, index) => {
                    return (
                      <span key={index} style={{ paddingLeft: 3 }}>
                        {" "}
                        {i18n === "th"
                          ? item?.product?.nameTh || ""
                          : item?.product?.nameEn || ""}{" "}
                        {index + 1 >= reportTreatment?.labs?.length ? (
                          ` `
                        ) : (
                          <span className="t-red "> {` , `}</span>
                        )}
                      </span>
                    );
                  })}

                  <Col className="m-t-print ml-05">TIME:</Col>
                  <Col className="m-t-print ">
                    <div style={{ width: 100 }} className=" print-close">
                      <Form.Item noStyle name="time">
                        <Input className="input-report" />
                      </Form.Item>
                    </div>
                    <span style={{ width: 80 }} className=" print-open ">
                      {reportTreatment?.visit?.data?.treatmentRecord?.time ||
                        "_____________"}
                    </span>
                  </Col>
                  <Col className="m-t-print">hrs.</Col>
                </Row>
              </span>
            </td>
          </tr>

          <tr>
            <td
              colSpan={22}
              className="rd-bottom rd-right rd-left treatment-print"
            >
              <Row justify="start">
                <span className=" m-t-print mr-05">IVA:</span>
                <Form.Item noStyle name="IVA">
                  <Checkbox.Group
                    style={{ textAlign: "left", display: "contents" }}
                  >
                    <Checkbox
                      value="capped"
                      className="treatment-checkbox treatment-print mr-05"
                    >
                      Capped and flushed with saline/heparin done; Redness or
                      swelling
                    </Checkbox>
                    <Checkbox
                      value="no"
                      className="treatment-checkbox treatment-print"
                    >
                      NO
                    </Checkbox>
                    <Checkbox
                      value="yes"
                      className="treatment-checkbox treatment-print"
                    >
                      YES
                    </Checkbox>
                    <Checkbox
                      value=" Catheter Removeal"
                      className="treatment-checkbox treatment-print"
                    >
                      Catheter Removeal
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <span className="m-t-print ">
                  <span className=" treatment-print">time : </span>
                </span>
                <span style={{ width: 100 }} className="print-close">
                  <Form.Item name="removalTime" noStyle>
                    <Input className="input-report" />
                  </Form.Item>
                </span>
                <span style={{ width: 80 }} className="m-t-print print-open ">
                  {reportTreatment?.visit?.data?.treatmentRecord?.removalTime ||
                    "_____________"}
                </span>
                <span className="m-t-print">hrs.</span>
              </Row>
            </td>
          </tr>

          <tr>
            <td
              colSpan={22}
              className="rd-bottom rd-right rd-left treatment-print"
            >
              <Row justify="start">
                <div className="m-t-print mr-05">Discharge condition:</div>
                <Form.Item noStyle name="discharge">
                  <Checkbox.Group
                    style={{ textAlign: "left", display: "contents" }}
                  >
                    <Checkbox
                      value="independence"
                      className="treatment-checkbox treatment-print "
                    >
                      Independence
                    </Checkbox>
                    <Checkbox
                      value="dependence"
                      className="treatment-checkbox treatment-print mr-05"
                    >
                      Dependence
                    </Checkbox>
                    <div className="mr-05 treatment-print m-t-print f-bold">
                      Discharge to:
                    </div>

                    <Checkbox
                      value="home"
                      className="treatment-checkbox treatment-print"
                    >
                      Home
                    </Checkbox>
                  </Checkbox.Group>
                </Form.Item>
                <Checkbox
                  name="refer"
                  className="treatment-checkbox treatment-print"
                >
                  Refer:
                </Checkbox>

                <span style={{ width: 120 }} className="print-close">
                  <Form.Item name="refer" noStyle>
                    <Input className="input-report" />
                  </Form.Item>
                </span>
                <span
                  style={{ width: 120 }}
                  className=" m-t-print  print-open "
                >
                  {reportTreatment?.visit?.data?.treatmentRecord?.refer ||
                    "____________________________"}
                </span>
              </Row>
            </td>
          </tr>
          <tr>
            <td colSpan={22} className="rd-bottom rd-right rd-left t-start ">
              <Row gutter={[0, 5]}>
                <Col>
                  <b
                    className="f-bold pr-05"
                    style={{ textDecoration: "underline" }}
                  >
                    NOTE:
                  </b>
                </Col>
                <Col sm={21} lg={22} xl={22}>
                  <div className="print-close">
                    <Form.Item name="note" noStyle>
                      <TextArea
                        className="input-report-nursing-history"
                        autoSize
                      />
                    </Form.Item>
                  </div>
                  <div
                    className=" f-normal t-center print-open "
                    dangerouslySetInnerHTML={{
                      __html: TextBRHTML(
                        reportTreatment?.visit?.data?.treatmentRecord?.note ||
                          reportTreatment?.patient?.note ||
                          ""
                      ),
                    }}
                  />
                </Col>
              </Row>
            </td>
          </tr>

          <tr>
            <td colSpan={22} className="rd-bottom rd-right rd-left">
              <Row justify="end">
                <Col>Nurse's name</Col>

                <Col span={4} className="t-underline-treatment ">
                  <span style={{ width: 100 }} className="print-close">
                    <Form.Item name="nurse" noStyle>
                      <Input className="input-report" />
                    </Form.Item>
                  </span>
                  <span style={{ width: 80 }} className="m-t-print print-open ">
                    {reportTreatment?.visit?.data?.treatmentRecord?.nurse ||
                      "_____________"}
                  </span>
                </Col>

                <Col>Time:</Col>

                <Col span={4} className="t-underline-treatment ">
                  <span style={{ width: 100 }} className="print-close">
                    <Form.Item name="timeNurse" noStyle>
                      <Input className="input-report" />
                      {/* <TimePicker
                        clearIcon
                        placeholder=""
                        className="nursing-history-input"
                        format="HH:mm"
                        suffixIcon=""
                      /> */}
                    </Form.Item>
                  </span>
                  <span style={{ width: 80 }} className="m-t-print print-open ">
                    {reportTreatment?.visit?.data?.treatmentRecord?.timeNurse ||
                      ""}
                    {/* {!!reportTreatment?.visit?.data?.treatmentRecord?.timeNurse
                      ? dayjs(
                          reportTreatment?.visit?.data?.treatmentRecord
                            ?.timeNurse
                        ).format("HH:mm")
                      : "_____________"} */}
                  </span>
                </Col>
                <Col>hrs.</Col>
              </Row>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ReportProcedure;
