import React, { useEffect, useState } from "react";
import { IBranch } from "@interface/IBranch.interface";
import { USER_MANAGE } from "@assets/data/pathRouter";
import { IRoleGetList } from "@interface/IRole.interface";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useDeleteRoleByID, useGetRoleList } from "@services/api/role";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import RoleContext from "@provider/RoleProvider";

type Props = {
  searchValue: any;
  currentPage: ICurrentPage;
};

const Table = ({ searchValue, currentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [visible, setVisible] = useState(false);
  const [roleId, setRoleId] = useState<number>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const client = useQueryClient();
  const history = useHistory<RouteComponentProps["history"]>();
  const deleteRole = useDeleteRoleByID();
  const roleListIsEdit = React.useContext(RoleContext);
  const roleList = useGetRoleList(true, {
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    branchId: searchValue?.branchId,
    search: searchValue?.name,
  });
  const medicalRecord = roleListIsEdit?.filter((item) => {
    return item?.nameEn === "USER";
  });

  useEffect(() => {
    setTotal(roleList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [roleList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${USER_MANAGE.updateRole}/${event.id}`);
  };

  const onDelete = () => {
    handleDeleteRole(roleId);
  };

  const handleDeleteRole = (roleId?: number) => {
    deleteRole.mutate(roleId, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        client.invalidateQueries(["get-role-list"]);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
      onSettled: () => {
        setVisible(false);
      },
    });
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: IRoleGetList, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("name")} (TH) `,
      dataIndex: "nameTh",
      key: "nameTh",
      render: (nameTh: string, data: IRoleGetList) => {
        return (
          <div className="f-normal " key={data?.id}>
            {`${nameTh}`}
          </div>
        );
      },
    },

    {
      title: `${t("name")} (EN) `,
      dataIndex: "nameEn",
      key: "nameTh",
      render: (nameTh: string, data: IRoleGetList) => {
        return (
          <div className="f-normal " key={data?.id}>
            {`${nameTh}`}
          </div>
        );
      },
    },

    {
      title: `${t("branch")}`,
      dataIndex: "branch",
      key: "branch",
      render: (branch: IBranch) => {
        return (
          <div>{`${
            i18n.language === "th" ? branch?.nameTh : branch?.nameEn
          }`}</div>
        );
      },
    },
    medicalRecord?.[0]?.isEdit
      ? {
          title: t("manage"),
          dataIndex: "manage",
          key: "manage",
          align: "center",
          width: "9%",
          render: (_: any, data: IRoleGetList) => {
            return (
              <div>
                <DeleteOutlined
                  onClick={(e) => {
                    setRoleId(data?.id);
                    setVisible(true);
                    e.stopPropagation();
                  }}
                />
              </div>
            );
          },
        }
      : {},
  ];

  return (
    <>
      <Modaler
        loadingButton={deleteRole.isLoading}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>

      <MoTable
        dataSource={roleList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
