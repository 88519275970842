import React, { FC, useEffect, useState } from "react";
import { SETTING } from "@assets/data/pathRouter";
import { IBranch } from "@interface/IBranch.interface";
import { Popover } from "antd";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { useGetBranchList } from "@services/api/branch";
import { useHistory, RouteComponentProps } from "react-router-dom";
import MoTable from "@components/table/Table";
import RoleContext from "@provider/RoleProvider";

type Props = {
  searchData: any;
  currentPage: ICurrentPage;
};

const TableBranchManagement: FC<Props> = ({ currentPage, searchData }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "BRANCH_MANAGEMENT";
  });

  const branchList = useGetBranchList(true, {
    page: currentPage?.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    search: searchData?.name,
    province: searchData?.province,
  });

  useEffect(() => {
    if (!branchList?.data?.result?.[0]?.total) return;
    setTotal(branchList?.data?.result?.[0]?.total || 1);
    //eslint-disable-next-line
  }, [branchList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: IBranch) => {
    if (medicalRecord?.[0]?.isEdit) {
      history.push(`${SETTING.manageBranchManagement}/${event?.id}`);
    }
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: number, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: t("branchManagement.branchName"),
      dataIndex: i18n.language === "th" ? "nameTh" : "nameEn",
      key: "name",
    },

    {
      title: t("address"),
      dataIndex: i18n.language === "th" ? "addressTh" : "addressEn",
      key: "address",
      render: (address: string, data: IBranch) => {
        return (
          <Popover
            content={[
              address,
              data?.[i18n.language === "th" ? "districtTh" : "districtEn"],
              data?.[i18n.language === "th" ? "provinceTh" : "provinceEn"],
              data?.country,
              data?.zipcode,
            ]
              ?.join(" ")
              ?.trim()}
          >
            <div className="text-content">
              {[
                address,
                data?.[i18n.language === "th" ? "districtTh" : "districtEn"],
                data?.[i18n.language === "th" ? "provinceTh" : "provinceEn"],
                data?.country,
                data?.zipcode,
              ]
                ?.join(" ")
                ?.trim()}
            </div>
          </Popover>
        );
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: t("email"),
      dataIndex: "email",
      key: "email",
    },

    {
      title: t("province"),
      dataIndex: i18n.language === "th" ? "provinceTh" : "provinceEn",
      key: "province",
    },
  ];

  return (
    <React.Fragment>
      <MoTable
        dataSource={branchList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </React.Fragment>
  );
};

export default TableBranchManagement;
