import { IUserRoom } from "@interface/IUserRoom.interface";
import { IFormSlots } from "@interface/ISlots.interface";
import { useTranslation } from "react-i18next";
import { notificationDetail } from "@assets/data/data";
import { useGetUserRoomUser } from "@services/api/user_room";
import { useEffect, useState } from "react";
import { Col, Form, FormInstance, Input, Row, TimePicker } from "antd";
import moment from "moment";
import Period from "@assets/img/icon/OPD/period.svg";
import Selector from "../../selector/Selector";
import Notification from "@assets/img/icon/OPD/notification.svg";

type Props = {
  dataSlots: IFormSlots;
  CommitChanges: (added: any) => void;
  slotForm: FormInstance;
  setVisible: (e: boolean) => void;
  doctorList: IUserRoom[];
};

const Fields = ({
  dataSlots,
  CommitChanges,
  slotForm,
  setVisible,
  doctorList,
}: Props) => {
  const format = "HH:mm";
  const { t, i18n } = useTranslation();
  const [disabledHours, setDisabledHours] = useState(dataSlots.startDate);
  const [roomList, setRoomList] = useState<number | null>(null);
  const userRoomUser = useGetUserRoomUser(dataSlots?.userId || roomList);

  useEffect(() => {
    setVisible(true);

    slotForm.setFieldsValue({
      userId: dataSlots?.userId,
      date: String(moment(dataSlots.startDate).format("YYYY-MM-DD")),
      roomId: dataSlots?.roomId,
      startDate: moment(dataSlots.startDate),
      endDate: moment(dataSlots.endDate),
      minutesToNotify: dataSlots.minutesToNotify,
    });
    // setRoomList(dataSlots?.roomId);

    // eslint-disable-next-line
  }, []);

  const onChangeTimePicker = (time: any) => {
    var timeNext = moment(time).add(15, "minutes");
    slotForm.setFieldsValue({ [`endDate`]: timeNext });
    setDisabledHours(time);
  };

  const getDisabledHours = () => {
    let hours = [];
    for (let i = 0; i < moment(disabledHours).hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledMinutes = () => {
    let selectedHour = Number(moment(disabledHours).format("H"));

    let minutes = [];
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment(disabledHours).minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const onFinish = (dataForm: any) => {
    if (dataSlots.id != null) {
      // const userRoomList = doctorList?.find(
      //   (item: IUserRoom) => item?.id === dataForm.userId
      // );
      const data = {
        ...dataForm,

        id: dataSlots.id,
      };

      CommitChanges({ changed: data });
    } else {
      // const userRoomList = doctorList?.find(
      //   (item: IUserRoom) => item?.id === dataForm.userId
      // );
      const data = {
        ...dataForm,
      };
      CommitChanges({ added: data });
    }
    setVisible(false);
  };

  return (
    <Form onFinish={onFinish} layout="vertical" name="slotForm" form={slotForm}>
      <Row gutter={[23, 18]} className="margin-form">
        <Col md={12} lg={12}>
          <div>{t("doctorName")}</div>
          <div className="mt-1">
            <Form.Item
              name="userId"
              rules={[{ required: true, message: t("pleaseSelect") }]}
            >
              <Selector
                disabled={dataSlots.userId ? true : false}
                Source={doctorList || []}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="userId"
                onChange={(e) => {
                  setRoomList(e);
                  slotForm.setFieldsValue({
                    roomId: undefined,
                  });
                }}
              />
            </Form.Item>
          </div>
        </Col>

        <Col md={12} lg={12}>
          <div>{t("room")}</div>
          <div className="mt-1">
            <Form.Item
              name="roomId"
              rules={[{ required: true, message: t("pleaseSelect") }]}
            >
              <Selector
                loading={userRoomUser?.isLoading}
                disabled={dataSlots.userId ? true : false}
                Source={userRoomUser?.data?.result?.[0]?.roomIds || []}
                keyName="name"
                keyValue="id"
                // onChange={(e) => {
                //   slotForm.setFieldsValue({
                //     userId: e,
                //   });
                // }}
              />
            </Form.Item>
          </div>
        </Col>
        <Col md={24} lg={12}>
          <div>{t("date")}</div>
          <div className="mt-1">
            <Form.Item name="date" rules={[{ required: true }]}>
              <Input placeholder="วันที่จอง" disabled />
            </Form.Item>
          </div>
        </Col>
        <Col md={12} lg={6}>
          <div className="">{t("period")}</div>
          <Row className="mt-1" gutter={[10, 18]}>
            <Col md={4} lg={4} className="icon-modal">
              <img className="img-icon-modal" src={Period} alt="Period" />
            </Col>
            <Col md={20} lg={20}>
              <Form.Item
                name="startDate"
                rules={[{ required: true, message: t("pleaseSelect") }]}
              >
                <TimePicker
                  format={format}
                  onChange={(e) => onChangeTimePicker(e)}
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        <Col md={2} lg={1}>
          <div className="mt-3 t-to">to</div>
        </Col>

        <Col md={10} lg={5} className="mt-input">
          <Form.Item
            name="endDate"
            rules={[
              { required: true, message: t("pleaseSelect") },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (
                    moment(getFieldValue("startDate")).isAfter(value) ||
                    moment(getFieldValue("startDate")).format("HH:mm") ===
                      moment(value).format("HH:mm")
                  ) {
                    slotForm.setFieldsValue({
                      [`endDate`]: moment(getFieldValue("startDate")).add(
                        15,
                        "minutes"
                      ),
                    });
                    return Promise.resolve();
                  } else {
                    return Promise.resolve();
                  }
                },
              }),
            ]}
          >
            <TimePicker
              format={format}
              disabledHours={getDisabledHours}
              disabledMinutes={getDisabledMinutes}
            />
          </Form.Item>
        </Col>

        <Col md={14} lg={12}>
          <div>{t("booking.notification")}</div>
          <Row className="mt-1">
            <Col md={4} lg={2} className="icon-modal">
              <img
                className="img-icon-modal"
                src={Notification}
                alt="Notification"
              />
            </Col>
            <Col md={20} lg={22}>
              <Form.Item name="minutesToNotify">
                <Selector
                  Source={notificationDetail}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Fields;
