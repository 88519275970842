import { Form } from "antd";
import { _isEmpty } from "@tools/util";
import { useEffect } from "react";
import { COUPON_STATUS } from "@tools/util.enum";
import { useRouteMatch } from "react-router-dom";
import { useGetCouponByID } from "@services/api/coupon";
import { ICouponProductCategory } from "@interface/ICoupon.interface";
import moment from "moment";
import Fields from "./Fields";
import Header from "./Header";

const CreateCoupon = () => {
  const [confirm] = Form.useForm();
  const match: any = useRouteMatch();
  const couponID = match.params.id;
  const couponByID = useGetCouponByID(couponID, !_isEmpty(couponID));

  useEffect(() => {
    if (couponID) {
      confirm.setFieldsValue({
        ...couponByID?.data?.result?.[0],
        categorySelete:
          (couponByID?.data?.result?.[0]?.couponProductCategory?.length || 0) >
          0
            ? "category"
            : "product",
        start: [
          couponByID?.data?.result?.[0]?.startDate
            ? moment(couponByID?.data?.result?.[0]?.startDate)
            : undefined,
          couponByID?.data?.result?.[0]?.endDate
            ? moment(couponByID?.data?.result?.[0]?.endDate)
            : undefined,
        ],
        number: couponByID?.data?.result?.[0]?.discountPercent,
        right: couponByID?.data?.result?.[0]?.qty ? true : false,
        uses: couponByID?.data?.result?.[0]?.qtyUse ? true : false,
        category: couponByID?.data?.result?.[0]?.couponProductCategory.flatMap(
          (item: ICouponProductCategory) => {
            return item?.productCategory?.id;
          }
        ),
      });
    } // eslint-disable-next-line
  }, [couponByID?.data]);

  return (
    <div>
      <Header
        confirm={confirm}
        couponID={couponID}
        expiredStatus={
          couponByID?.data?.result?.[0]?.status === COUPON_STATUS.EXPIRED
        }
      />
      <Fields
        confirm={confirm}
        couponByID={couponByID?.data}
        couponID={couponID}
      />
    </div>
  );
};

export default CreateCoupon;
