import React, { useState } from "react";
import { IOutType } from "@interface/IDraftOrder.interface";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { INum, ISignature } from "@interface/IGlobal.interface";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { useCreateSaveToOrder } from "@services/api/draft_order";
import { KeyNameSignatureFirst } from "@interface/IGlobal.interface";
import { useCreateSignatureRoom } from "@services/api/signature_room";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useMainPhysicianAssistant } from "@provider/physician_assistant";
import { confirmOrder, confirmOrderList } from "@assets/data/pathRouter";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import Modaler from "@components/modaler/Modaler";
import BackHome from "@components/button/BackHome";
import ModalInfo from "@components/signature/ModalInfo";
import RoleContext from "@provider/RoleProvider";

interface IVisible {
  delete: boolean;
  confirm: boolean;
  first: boolean;
  outType: boolean;
}
export interface ISelectedRowKeys {
  oneDay: string[];
  continuous: string[];
  outType?: IOutType;
  other1: string[];
  other2: string[];
  labInterfaceGroup: string[];
  optionDrug: string[];
}

type Props = {
  patientID: number;
  history: RouteComponentProps["history"];
  selectedRowKeys: ISelectedRowKeys;
  totalAndQuantity: INum;
  eventId: number;
};

const Header = ({
  history,
  patientID,
  selectedRowKeys,
  totalAndQuantity,
  eventId,
}: Props) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const appointmentsID = match.params.appointmentsID;
  const visitID = match.params.visitID;
  const [visible, setVisible] = useState<IVisible>({
    delete: false,
    confirm: false,
    first: false,
    outType: false,
  });

  const roleList = React.useContext(RoleContext);
  const client = useQueryClient();
  const { appointmentsId, visitId, appointmentById } =
    useMainPhysicianAssistant();
  const createSignatureRoom = useCreateSignatureRoom();
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "CONFIRM_ORDER";
  });
  const createSaveToOrder = useCreateSaveToOrder();

  const handleCreateSaveToOrder = (value?: any) => {
    const discount =
      totalAndQuantity?.discount && totalAndQuantity?.selector === 1
        ? {
            discountPercent: Number(totalAndQuantity?.discount),
            discountType: "%",
          }
        : totalAndQuantity?.percent && totalAndQuantity?.selector === 2
        ? {
            discount: Number(totalAndQuantity?.percent),
            discountType: "฿",
          }
        : {};
    const data = {
      eventId: eventId,
      uuids:
        selectedRowKeys?.oneDay ||
        selectedRowKeys?.continuous ||
        selectedRowKeys?.optionDrug ||
        selectedRowKeys?.other1 ||
        selectedRowKeys?.other2 ||
        selectedRowKeys?.labInterfaceGroup
          ? [
              ...(selectedRowKeys?.oneDay || []),
              ...(selectedRowKeys?.continuous || []),
              ...(selectedRowKeys?.other1 || []),
              ...(selectedRowKeys?.optionDrug || []),
              ...(selectedRowKeys?.other2 || []),
              ...(selectedRowKeys?.labInterfaceGroup || []),
            ]
          : value,
      ...discount,
    };

    createSaveToOrder.mutate(
      { id: patientID, data: data },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
        onSettled: () => {
          history.replace(confirmOrder);
        },
      }
    );
  };

  // const onModalDelete = () => {
  //   setVisible({ ...visible, delete: !visible?.delete });
  // };

  const updateSignature = (
    values: {
      date: string;
      title: KeyNameSignatureFirst;
      doctorName?: string;
    } & ISignature
  ) => {
    let file = dataUrlToFile(
      values?.firstSignature,
      `PASignature${new Date().getTime()}.png`
    );

    if (file) {
      let formData = new FormData();
      formData?.append("signature", file);
      formData?.append("room", ENUM_ROOM_SIGNATURE.PA_ROOM);
      !!values?.doctorName &&
        formData?.append("doctorName", values?.doctorName);

      createSignatureRoom.mutate(
        { data: formData, visitId: visitId },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
            client.invalidateQueries(["get-appointment-byID", appointmentsId]);
          },
          onError: (err) => {
            openNotification({ method: "PUT_ERROR", message: String(err) });
          },
          onSettled: () => {
            setVisible({
              ...visible,
              [values?.title]: !visible?.[values?.title],
            });
          },
        }
      );
    }
  };

  const onModalOutType = () => {
    setVisible({ ...visible, outType: !visible?.outType });
  };

  const onModalConfirm = () => {
    setVisible({ ...visible, confirm: !visible?.confirm });
  };

  // const onDelete = () => {
  //   deletePatient.mutate(patientID, {
  //     onSuccess: () => {
  //       openNotification({ method: "DEL_SUCCESS" });
  //       history.push("/profile/opd");
  //       onModalDelete();
  //     },
  //     onError: (err) => {
  //       openNotification({ method: "DEL_ERROR", message: String(err) });
  //     },
  //   });
  // };

  const onConfirm = () => {
    handleCreateSaveToOrder([]);
    onModalConfirm();
  };

  return (
    <>
      {!!visible?.outType && (
        <Modaler
          visible={visible?.outType}
          onCancel={onModalOutType}
          onOk={onModalOutType}
          width="45%"
          header={
            <div>
              <QuestionCircleOutlined className="icon-warning-cancel" />
              {`${t("areYouSure")}`}
            </div>
          }
        >
          {t("selectOneDayOrContinuous")}
        </Modaler>
      )}

      {/* <Modaler
        visible={visible?.delete}
        onCancel={onModalDelete}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {t("doYouWantToDelete")}
      </Modaler> */}

      <Modaler
        visible={visible?.confirm}
        onCancel={onModalConfirm}
        onOk={onConfirm}
        width="45%"
        header={
          <div>
            <QuestionCircleOutlined className="icon-warning-cancel" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {t("wantToSaveWithoutSelectingData")}
      </Modaler>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("confirmOrder.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-normal">
                {t("confirmOrder.title")}
              </span>
            </Col>
          </Row>
        </Col>

        {medicalRecord?.[0]?.isEdit ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="reserve mr-05 "
              onClick={() => {
                sessionStorage.setItem(
                  "selectedRowKeys",
                  JSON.stringify(selectedRowKeys)
                );
                sessionStorage.setItem(
                  "totalAndQuantity",
                  JSON.stringify(totalAndQuantity)
                );

                history.push({
                  pathname: `${confirmOrderList}/appointments/${appointmentsID}/patient/${patientID}/visit/${visitID}/report`,
                });
              }}
            >
              {t("report")}
            </Button>
            <Button className="cancel mr-05 " onClick={history.goBack}>
              {t("cancel")}
            </Button>
            <Button
              className="btn-create"
              onClick={() => {
                !!appointmentById?.visit?.isSignature?.paRoom
                  ? setVisible({ ...visible, first: !visible?.first })
                  : !!selectedRowKeys?.outType &&
                    !!selectedRowKeys?.outType?.groups?.length
                  ? onModalOutType()
                  : selectedRowKeys?.oneDay?.length ||
                    selectedRowKeys?.continuous?.length ||
                    selectedRowKeys?.other1?.length ||
                    selectedRowKeys?.other2?.length ||
                    selectedRowKeys?.labInterfaceGroup?.length
                  ? handleCreateSaveToOrder()
                  : onModalConfirm();
              }}
              loading={createSaveToOrder.isLoading}
            >
              {t("confirm")}
            </Button>
          </Col>
        ) : null}
      </Row>
      {visible?.first && (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="first"
          title={t("medicalAssistantSignature")}
          updateSignature={updateSignature}
          isLoading={createSignatureRoom?.isLoading}
          hiddenDoctorName={
            !appointmentById?.visit?.isSignature?.paRoom &&
            !!appointmentById?.slotId
          }
        />
      )}
    </>
  );
};

export default Header;
