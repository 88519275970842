import { FC } from "react";
import { gender } from "@assets/data/data";
import { ISearch } from "./interface";
import { useTranslation } from "react-i18next";
import { useGetBranchDropdownList } from "@services/api/branch";
import { Button, Col, Form, Input, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  setCurrentPage: (e: number) => void;
  setSearch: (e: ISearch) => void;
};

const Search: FC<Props> = ({ setSearch, setCurrentPage }) => {
  const { t, i18n } = useTranslation();
  const { data } = useGetBranchDropdownList();

  const onFinish = (value: ISearch) => {
    setSearch(value);
    setCurrentPage(1);
  };
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col lg={5} xl={8}>
            <Form.Item name="search">
              <Input placeholder={`${t("search")}`} />
            </Form.Item>
          </Col>
          <Col lg={5} xl={5}>
            <Form.Item name="branchId">
              <SelectorDouble
                Source={data?.result || []}
                keyName={i18n?.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("branch")}
              />
            </Form.Item>
          </Col>
          <Col lg={5} xl={3}>
            <Form.Item name="gender">
              <SelectorDouble
                Source={gender}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("sex")}
              />
            </Form.Item>
          </Col>

          <Col lg={3}>
            <Form.Item
              name="phone"
              rules={[
                {
                  pattern: /^[+]{0}[0-9]{9,10}$/,
                  message: t("pleaseNumber"),
                },
              ]}
            >
              <Input placeholder={`${t("phoneNumber")}`} />
            </Form.Item>
          </Col>
          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
