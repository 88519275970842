import React, { useState } from "react";
import TableOPD from "./Table";
import SearchOPD from "./Search";

const History = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onFinish = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };

  return (
    <div>
      <SearchOPD onFinish={onFinish} />
      <TableOPD
        searchValue={searchValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default History;
