import { dayjs } from "@tools/timezone";
import { useState } from "react";
import { slotsOverview } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { Button, Col, Drawer, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Affixes from "@components/affixes/Affixes";
import Scheduler from "./Scheduler";
import ListQueue from "@components/list_queue/ListQueue";

const Queue = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [visible, setVisible] = useState<boolean>(false);
  const [currentDateChange, setCurrentDateChange] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const handleOverView = () => {
    history.push(slotsOverview);
  };

  const showDrawer = () => {
    setVisible(!visible);
  };

  return (
    <div className="none-scroll">
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("medicalRecord.queue")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("queue")}</b>
          </span>
        </Col>
        <Col lg={4} className="justify-col">
          <Button className="seeMore" type="primary" onClick={handleOverView}>
            {t("seeOverview")}
          </Button>
        </Col>
      </Row>
      <div className="set-layout body-height">
        <Row gutter={[14, 14]}>
          <Col md={24} lg={17}>
            <Scheduler
              currentDateChange={{
                currentDateChange: currentDateChange,
                setCurrentDateChange: setCurrentDateChange,
              }}
            />
          </Col>
          <Col lg={7} className="card-display">
            <ListQueue
              currentDateChange={{
                currentDateChange: currentDateChange,
                setCurrentDateChange: setCurrentDateChange,
              }}
            />
          </Col>
        </Row>

        <Affixes onClick={showDrawer} />

        <Drawer
          width={"50%"}
          closable={false}
          placement="right"
          onClose={showDrawer}
          visible={visible}
        >
          <ListQueue
            status="noCard"
            currentDateChange={{
              currentDateChange: currentDateChange,
              setCurrentDateChange: setCurrentDateChange,
            }}
          />
        </Drawer>
      </div>
    </div>
  );
};

export default Queue;
