import { RETURN_COURSE } from "@assets/data/pathRouter";
import PatientList from "@components/patient_list/PatientList";
import React from "react";

const Return = () => {
  return (
    <div>
      <PatientList path={RETURN_COURSE.details} />
    </div>
  );
};

export default Return;
