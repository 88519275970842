import { IVisitDrug } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import Print from "./Print";
import React from "react";
import Nodata from "@assets/img/nodata.svg";
import Search from "./Search";
import ReactToPrint from "react-to-print";

type Props = {
  setSearchData: (e: any) => void;
  drugList?: IVisitDrug;
  printReportRef: React.RefObject<HTMLElement | any>;
};

const MedicalReport = ({ setSearchData, drugList, printReportRef }: Props) => {
  const { i18n } = useTranslation();
  return (
    <React.Fragment>
      <Search setSearchData={setSearchData} />
      {drugList ? (
        <>
          <ReactToPrint content={() => printReportRef.current} />

          <Print
            drugList={drugList}
            ref={printReportRef}
            i18n={i18n.language}
          />
        </>
      ) : (
        <>
          <div>
            <img src={Nodata} alt="nodata" className="image-nodata" />
          </div>
          <div className="text-nodata f-bold">NO DATA</div>
        </>
      )}
    </React.Fragment>
  );
};

export default MedicalReport;
