import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";

type Props = {
  formUser: FormInstance;
  history: RouteComponentProps["history"];
};

const Header = ({ formUser, history }: Props) => {
  const { t } = useTranslation();
  const loadingButton = useRecoilValue(loadingButtonState);

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <Col className="header-text">
              <div>
                <h1>{t("manageProfile")}</h1>
              </div>
              <span className="t-light-gray">{t("manageProfile")} / </span>
              <span className="t-dark-blue f-normal">{t("edit")}</span>
            </Col>
          </Row>
        </Col>

        <Col lg={4} className="d-flex justify-between">
          <Button
            className="mr-1 cancel"
            htmlType="reset"
            block
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            htmlType="submit"
            onClick={() => formUser.submit()}
            block
            loading={loadingButton}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
