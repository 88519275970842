import { Button, Col } from "antd";
import { RouteComponentProps } from "react-router-dom";
import Back from "@assets/img/icon/OPD/back.svg";

type Props = {
  history: RouteComponentProps["history"];
  goHome?: string;
};

const BackHome = ({ history, goHome }: Props) => {
  return (
    <Col className="bottom-back-home">
      <span className="blackHome">
        <Button
          onClick={() => (goHome ? history.replace(goHome) : history.goBack())}
          type="primary"
          shape="circle"
        >
          <img src={Back} alt="" />
        </Button>
      </span>
    </Col>
  );
};

export default BackHome;
