import { dayjs } from "@tools/timezone";
import { IBilling } from "@interface/IBill.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useGetDraftBillList } from "@services/api/draft_bill";
import { useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import MoTable from "@components/table/Table";
import Decimal from "@tools/decimal";

type Props = {
  searchValue: any;
  currentPage: number;
  setCurrentPage: (e: number) => void;
};

const Table = ({ searchValue, currentPage, setCurrentPage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const draftBillList = useGetDraftBillList(patientID, {
    page: currentPage,
    limit: limitPage,
    ...searchValue,
    date: searchValue?.date
      ? dayjs(searchValue?.date).format("YYYY-MM-DD")
      : undefined,
    orderby: `${
      searchValue?.status === 2 ? ENUM_ORDERBY.ASC : ENUM_ORDERBY.DESC
    }`,
  });

  useEffect(() => {
    setTotal(draftBillList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [draftBillList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: IBilling) => {
    history.push(
      `${PAY_LIST_TAB.beforeBillCreateBill}/${event.id}/patient/${patientID}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "15%",
      render: (_: any, data: IBilling, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <>{dayjs(date).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: `${t("number")}${t("document")}`,
      dataIndex: "draftBillNumber",
      key: "draftBillNumber",
    },

    {
      title: t("netTotal"),
      dataIndex: "netPrice",
      key: "netPrice",
      render: (netPrice: number) => {
        return <>{Decimal(Number(netPrice))}</>;
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={draftBillList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
        onClickRow={onClickRow}
      />
    </>
  );
};

export default Table;
