import { ColumnsType } from "antd/lib/table";
import { useTranslation } from "react-i18next";
import { correctGeneral } from "@assets/data/pathRouter";
import { IGetPrefixList } from "@interface/IPrefix.interface";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  country?: IGetPrefixList[];
  total: number;
  currentPage: number;
  setCurrentPage: (e: any) => void;
  limitPage: number;
  setLimitPage: (e: any) => void;
};

const TableCountry = ({
  country,
  total,
  currentPage,
  setCurrentPage,
  limitPage,
  setLimitPage,
}: Props) => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${correctGeneral}/${event.id}`);
  };

  const columns: ColumnsType = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("country"),
      dataIndex: "name",
      key: "name",
    },
  ];
  return (
    <>
      <MoTable
        dataSource={country}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default TableCountry;
