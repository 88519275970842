import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import PickUpGoods from "./pick_up_goods/PickUpGoods";
import BounceGoods from "./bounce_goods/BounceGoods";
import PurchaseOrder from "./purchase_order/PurchaseOrder";
import ProductWareHouse from "./product/Product";
import UserContext from "@provider/UserProvider";
import { ENUM_ROLE_EN } from "@tools/util.enum";
import React from "react";

type Props = {
  tabsWarehouse: string;
  setTabsWarehouse: (e: string) => void;
};

const TabsWarehouse = ({ tabsWarehouse, setTabsWarehouse }: Props) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const userList = React.useContext(UserContext);
  let tabsList = [
    { name: t("product"), key: "1", display: <ProductWareHouse /> },
    { name: t("purchaseOrder"), key: "2", display: <PurchaseOrder /> },
    { name: t("pickUp"), key: "3", display: <PickUpGoods /> },
    { name: t("bounceGoods"), key: "4", display: <BounceGoods /> },
  ];
  if (userList?.role === ENUM_ROLE_EN.SUPER_MARKETING) {
    tabsList = [
      { name: t("product"), key: "1", display: <ProductWareHouse /> },
    ];
  }
  const callBack = (key: string) => {
    setTabsWarehouse(key);
  };

  return (
    <Tabs
      defaultActiveKey={tabsWarehouse}
      className="tab-printdraw "
      onChange={callBack}
    >
      {tabsList?.map((item) => {
        return (
          <TabPane tab={item?.name} key={item?.key} className="mt-2">
            {item?.display}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default TabsWarehouse;
