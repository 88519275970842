import { useState } from "react";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { PRE_REGISTRATIONMARK } from "@assets/data/pathRouter";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useDeletePreRegistrationByID } from "@services/api/event";
import { Button, Col, FormInstance, Row } from "antd";
import Delete from "@assets/img/icon/OPD/delete.svg";
import BackHome from "@components/button/BackHome";
import Modaler from "@components/modaler/Modaler";

type Props = {
  history: RouteComponentProps["history"];
  preRegister: FormInstance;
  patientID: number;
  locationPath: string;
  eventID: number;
  historyPatient: boolean;
};

const Header = ({
  history,
  preRegister,
  patientID,
  locationPath,
  eventID,
  historyPatient,
}: Props) => {
  const { t } = useTranslation();
  const updatePatient = useIsMutating(["update-patient"]);
  const createPatient = useIsMutating(["create-patient"]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const deletePreRegistrationByID = useDeletePreRegistrationByID();

  const onModal = (e: boolean) => {
    setIsModalOpen(e);
  };

  const onDelete = () => {
    deletePreRegistrationByID.mutate(
      {
        patientId: patientID,
        eventId: eventID,
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
        },
        onSettled: () => {
          history.replace(`${PRE_REGISTRATIONMARK.event}/${eventID}`);
        },
      }
    );
  };

  return (
    <>
      <Modaler
        forceRender
        loadingButton={deletePreRegistrationByID?.isLoading}
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>
      <Row className="second-header" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("preRegister.title")}</h1>
              </div>
              <span className="t-light-gray">{`${t("marketing.title")} / ${t(
                "event.title"
              )} / `}</span>
              <span className="t-dark-blue f-normal">
                {locationPath.includes("create-draft") ||
                locationPath.includes("old-customer")
                  ? t("preRegister.add")
                  : patientID
                  ? t("preRegister.edit")
                  : t("preRegister.register")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          {patientID ? (
            <>
              <Button
                className=" delete mr-05 "
                onClick={(e) => {
                  onModal(true);
                  e.stopPropagation();
                }}
              >
                <img
                  className="circle editor-button"
                  src={Delete}
                  alt="delete"
                />
              </Button>

              <Button
                className="reserve mr-05 "
                key="back"
                onClick={() => {
                  history.push(
                    `${PRE_REGISTRATIONMARK.event}/${eventID}/report/patient/${patientID}`
                  );
                }}
              >
                {t("print")}
              </Button>
            </>
          ) : null}
          {!historyPatient && (
            <>
              <Button
                className="cancel mr-05 "
                htmlType="submit"
                onClick={history.goBack}
              >
                {t("cancel")}
              </Button>
              <Button
                className="btn-create  mr-05"
                htmlType="submit"
                onClick={() => preRegister.submit()}
                loading={!!updatePatient || !!createPatient}
              >
                {patientID ? t("update") : t("save")}
              </Button>
            </>
          )}
        </Col>
      </Row>
    </>
  );
};

export default Header;
