import React, { useState } from "react";
import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { useTranslation } from "react-i18next";
import { Col, Row, Card } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useGetPatientByHistory } from "@services/api/patient";
import { useHistory, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";
import ImagePatient from "@components/image/ImagePatient";
import TableVisitList from "./Table";
import SearchVisitList from "./Search";

const VisitList = () => {
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const patientByID = useGetPatientByHistory(patientID);

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("reportProcedure.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-bold">{t("leadingSymptoms")}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey m-1-doctor-room "
          extra={
            <React.Fragment>
              <Row
                gutter={[14, 14]}
                className="text-row-profile ml-05"
                align="middle"
              >
                <Col>
                  <ImagePatient src={patientByID?.data?.image} />
                </Col>
                <Col>
                  <div>
                    {i18n.language === "th"
                      ? ` ${patientByID?.data?.firstNameTh || " "}    ${
                          patientByID?.data?.lastNameTh || " "
                        }`
                      : ` ${patientByID?.data?.firstNameEn || " "}    ${
                          patientByID?.data?.lastNameEn || " "
                        }`}
                  </div>

                  <div className="code-profile ">
                    HN : {patientByID?.data?.hn}
                  </div>
                </Col>
                <Col>
                  <span className="mr-05">{t("age")} : </span>
                  <span className="t-grey-light">
                    {Age(patientByID?.data?.dateOfBirth || "")}
                  </span>
                </Col>

                <Col>
                  <span className="mr-05">{t("sex")} : </span>
                  <span className="t-grey-light">
                    {gender(patientByID?.data?.gender, i18n?.language)}
                  </span>
                </Col>

                <Col>
                  <span className="mr-05">{t("phoneNumber")} : </span>
                  <span className="t-grey-light">
                    {patientByID?.data?.mobile || "-"}
                  </span>
                </Col>
              </Row>
            </React.Fragment>
          }
        >
          <SearchVisitList
            setSearchValue={setSearchValue}
            setCurrentPage={setCurrentPage}
          />
          <TableVisitList
            searchValue={searchValue}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
          />
        </Card>
      </div>
    </div>
  );
};

export default VisitList;
