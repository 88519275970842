import { downloadXLSX } from "@tools/util";
import { thrownResponse } from "./auth";
import { IDashboardList } from "@interface/IDashBoard.interface";
import { openNotification } from "@components/notification/Notification";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { IDashboardTreatment } from "@interface/IDashBoard.interface";
import { useQuery, UseQueryResult } from "react-query";
import i18n from "i18next";
import axios from "@axios/index";

export const useGetDashboard = (
  params?: any
): UseQueryResult<IDashboardList> => {
  return useQuery(
    ["get-dashboard", params],
    async () => {
      const res = await axios.get(`/dashboard`, { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data?.result?.[0] || {};
      }
      thrownResponse(res);
    }
    // { refetchInterval: 10000 }
  );
};

export const useGetDashboardPurchaseExport = (
  enabled: boolean,
  params?: any
): UseQueryResult<ElementaryOneMore<IDashboardTreatment>> => {
  return useQuery(
    ["get-dashboard-purchase-export"],
    async () => {
      const res = await axios.get(`/dashboard/patient/purchase/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "ยอดการซื้อสินค้า");
        return res?.data || {};
      }

      openNotification({
        method: "GET_ERROR",
        message: i18n.language === "th" ? "ไม่พบข้อมูล" : "Not found",
      });
    },
    { enabled: enabled }
  );
};

export const useGetDashboardTreatmentExport = (
  enabled: boolean,
  params?: any
): UseQueryResult<ElementaryOneMore<IDashboardTreatment>> => {
  return useQuery(
    ["get-dashboard-treatment-export"],
    async () => {
      const res = await axios.get(`/dashboard/product/treatment/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "สินค้าทร๊ทเม้นท์");
        return res?.data || {};
      }

      openNotification({
        method: "GET_ERROR",
        message: i18n.language === "th" ? "ไม่พบข้อมูล" : "Not found",
      });
    },
    { enabled: enabled }
  );
};

export const useGetDashboardProductExport = (
  enabled: boolean,
  params?: any
): UseQueryResult<ElementaryOneMore<IDashboardTreatment>> => {
  return useQuery(
    ["get-dashboard-product-export"],
    async () => {
      const res = await axios.get(`/dashboard/product/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "สินค้า");
        return res?.data || {};
      }

      openNotification({
        method: "GET_ERROR",
        message: i18n.language === "th" ? "ไม่พบข้อมูล" : "Not found",
      });
    },
    { enabled: enabled }
  );
};
