export enum ENUM_ROLE_EN {
  SUPER_ADMIN = "SUPER_ADMIN",
  SUPER_MARKETING = "SUPER_MARKETING",
  DOCTOR = "DOCTOR",
  CRM = "CRM",
  PA = "PA",
  NURSE = "NURSE",
  PHARMACIST = "PHARMACIST",
  CASHIER = "CASHIER",
  MANAGER = "MANAGER",
  OTHER = "OTHER",
}

export enum ENUM_ORDERBY {
  DESC = "DESC",
  ASC = "ASC",
}

export enum ENUM_ROLE_TH {
  SUPER_ADMIN = "SUPER_ADMIN",
  DOCTOR = "หมอ",
  CRM = "CRM",
  PA = "PA",
  NURSE = "พยาบาล",
  PHARMACIST = "เภสัชกร",
  CASHIER = "แคชเชียร",
  MANAGER = "ผู้จัดการสาขา",
  OTHER = "อื่น ๆ",
}

// ภาวะ G6PD
export enum ENUM_G6PD {
  DEFICIENCY = "DEFICIENCY",
  NORMAL = "NORMAL",
  UNKNOWN = "UNKNOWN",
}

export enum ENUM_FOOD {
  REGULAR = "REGULAR",
  HALAL = "HALAL",
  VEGAN = "VEGAN", // เจ
  VEGETARIAN = "VEGETARIAN", // มังสวิรัต
}
// ประเภทบัตร
export enum ENUM_ID_CARD {
  CITIZEN = "CITIZEN",
  PASSPORT = "PASSPORT",
}

export enum ENUM_PREFIX_TYPE {
  TITLE_TH = "TITLE_TH",
  TITLE_EN = "TITLE_EN",
  NATIONALITY = "NATIONALITY",
  CITIZENSHIP = "CITIZENSHIP",
  COUNTRY = "COUNTRY",
}

export enum ENUM_SLOT_TYPE {
  // ห้องอื่นๆ
  OTHER = "OTHER",
  // ห้องแพทย์
  DOCTOR = "DOCTOR",
}

export enum PAYMENT_TYPE {
  //เงินสด
  CASH = "CASH",
  //โอนเงิน
  TRANSFER = "TRANSFER",
  //บัตรเครดิต
  CREDIT = "CREDIT",
}
export enum ACCOUNT_TYPE {
  PRE_REGISTER = "PRE_REGISTER",
  REGISTER = "REGISTER",
}

export enum ENUM_PRODUCT_GROUP {
  IV_MEDICATIONS = "IV_MEDICATIONS",

  ID_SQ_IM_MEDICATIONS = "ID_SQ_IM_MEDICATIONS",

  PHYSICAL_THERAPY = "PHYSICAL_THERAPY",
}

export enum ENUM_SERVICE_GROUP {
  // หัตถการ
  MEDICAL_PROCEDURE = "MEDICAL_PROCEDURE",
  // กายภาพบำบัด
  PHYSICAL_THERAPY = "PHYSICAL_THERAPY",
  // ยา
  MEDICINE = "MEDICINE",
  LAB_INTERFACE = "LAB_INTERFACE",
  LAB = "LAB",
}

export enum ORDER_STATUS {
  // รายการที่ยังไม่ได้มีการใช้
  NEW = "NEW",
  // รายการที่ใช้ไปบางส่วน
  PARTIAL = "PARTIAL",
  // รายการที่ใช้ทั้งหมดแล้ว
  USED = "USED",
  // รายการที่ถูกคืน
  REJECTED = "REJECTED",
}

export enum PATIENT_TYPE {
  THAI = "THAI",
  FOREIGNER = "FOREIGNER",
  AH_PARTNER_GROUP = "AH_PARTNER_GROUP",
  GRATIS = "GRATIS",
}

export enum ORDER_TYPE {
  ONE_DAY = "ONE_DAY",
  CONTINUE = "CONTINUE",
  OPTION_DRUG = "OPTION_DRUG",
  OUT_TYPE = "OUT_TYPE",
  LAB_INTERFACE = "LAB_INTERFACE",
  LAB_INTERFACE_ONE = "LAB_INTERFACE_ONE",
  LAB_INTERFACE_TWO = "LAB_INTERFACE_TWO",
  LAB_INTERFACE_THREE = "LAB_INTERFACE_THREE",
  LAB_INTERFACE_FOUR = "LAB_INTERFACE_FOUR",
  LAB_INTERFACE_OTHER_ONE = "LAB_INTERFACE_OTHER_ONE",
  LAB_INTERFACE_OTHER_TWO = "LAB_INTERFACE_OTHER_TWO",
}

export enum VISIT_FILES_TYPE {
  RADIOLOGY = "RADIOLOGY",
  MEDICAL = "MEDICAL",
  SUMMARY = "SUMMARY",
}

export enum VISIT_OLD_FILES {
  PHYSICAL_ORDER = "PHYSICAL_ORDER",
  LAB_RESULT = "LAB_RESULT",
  RADIOLOGY_REPORT = "RADIOLOGY_REPORT",
  MEDICAL_REPORT = "MEDICAL_REPORT",
  BIOLOGY = "BIOLOGY",
  MEDICAL_SUMMARY = "MEDICAL_SUMMARY",
  TREATMENT = "TREATMENT",
  PHYSICAL = "PHYSICAL",
  CONSENT = "CONSENT",
  CORRESPONDENCE = "CORRESPONDENCE",
  VITAL_SIGN = "VITAL_SIGN",
  REPORT_DRUG = "REPORT_DRUG",
}

export enum ENUM_EVENT_QUERY_STRING {
  IN = "IN",
  WAIT = "WAIT",
  OUT = "OUT",
}

export enum ENUM {
  DONE = "DONE",
}

export enum MARITAL_STATUS {
  SINGLE = "SINGLE",
  MARRIED = "MARRIED",
  SEPARATED = "SEPARATED",
  DIVORCED = "DIVORCED",
  WIDOWED = "WIDOWED",
}

export enum APPOINTMENT_STATUS {
  //รอปรึกษา
  WAITING_FOR_ADVICE = "WAITING_FOR_ADVICE",
  //รอการรักษา
  WAITING_FOR_TREATMENT = "WAITING_FOR_TREATMENT",
  //กำลังปรึกษา
  ADVISING = "ADVISING",
  //ปรึกษาแล้ว
  ADVISED = "ADVISED",
  //ยกเลิก
  CANCEL = "CANCEL",
  //ยืนยัน
  CONFIRM = "CONFIRM",
  //จองล่วงหน้า
  BOOK_IN_ADVANCE = "BOOK_IN_ADVANCE",
}

export enum BILL_STATUS {
  //รอดำเนินการ
  WAITING = "WAITING",
  //เปิดบิล
  OPENING = "OPENING",
  //ยกเลิก
  CANCEL = "CANCEL",
}

export enum INVOICE_STATUS {
  //เปิดใบสำเร็จร
  OPENING = "OPENING",
  //รอดำเนินการ
  WAITING = "WAITING",
  //ยกเลิก
  CANCEL = "CANCEL",
  //แบ่งชำระ
  INSTALLMENT = "INSTALLMENT",
}

export enum RECEIPT_STATUS {
  //รอดำเนินการ
  WAITING = "WAITING",
  //ยกเลิก
  CANCEL = "CANCEL",
  //เก็บเงินแล้ว
  COMPLETED = "COMPLETED",
}

export enum COUPON_STATUS {
  DRAFT = "DRAFT", //แบบร่าง
  PUBLIC = "PUBLIC", //เผยแพร่
  EXPIRED = "EXPIRED", //หมดอายุ
}

export enum DRUG_STATUS {
  CONTINUOUS = "CONTINUOUS",
  DIS_CONTINUOUS = "DIS_CONTINUOUS",
  HOLDING = "HOLDING",
}

export enum RECEIPT_REJECT_COURSE_STATUS {
  //รอดำเนินการ
  WAITING = "WAITING",
  //ยกเลิก
  CANCEL = "CANCEL",
  //เก็บเงินแล้ว
  COMPLETED = "COMPLETED",
}

export enum ROOM_ENUM {
  INCHARGE = "INCHARGE",
  //ระหว่างการดำเนินงาน
  IN_PROCESS = "IN_PROCESS",
  //หัตถการ
  PROCEDURE = "PROCEDURE",
  //แล็บ
  LAB = "LAB",
  //
  INTERFACE_LAB = "INTERFACE_LAB",
  //ห้องกายภาพบำบัด
  PHYSICAL_THERAPY = "PHYSICAL_THERAPY",
}

export enum ENUM_MENU {
  DASHBOARD = "DASHBOARD",
  NURSERY = "NURSERY",
  MEDICAL_RECORD = "MEDICAL_RECORD",
  QUE_MEDICAL_RECORD = "QUE_MEDICAL_RECORD",
  FOREWORD_MEDICAL_RECORD = "FOREWORD_MEDICAL_RECORD",
  PREREGISTER1 = "PREREGISTER1",
  MARKETING = "MARKETING",
  PREREGISTER2 = "PREREGISTER2",
  PROMOTION = "PROMOTION",
  COUPON = "COUPON",
  EVENT = "EVENT",
  SCHEDULE = "SCHEDULE",
  QUE_MEDICAL = "QUE_MEDICAL",
  QUE_PROCEDURE = "QUE_PROCEDURE",
  NOTIFICATION = "NOTIFICATION",
  MEDICAL = "MEDICAL",
  MEDICAL_ASSISTANT = "MEDICAL_ASSISTANT",
  CONFIRM_ORDER = "CONFIRM_ORDER",
  PROCESS_ORDER = "PROCESS_ORDER",
  INFIRMARY = "INFIRMARY",
  INCHARGE = "INCHARGE",
  PROCEDURE = "PROCEDURE",
  REPORT_PROCEDURE = "REPORT_PROCEDURE",
  LAB = "LAB",
  INTERFACE_LAB = "INTERFACE_LAB",
  PHYSICAL_THERAPY = "PHYSICAL_THERAPY",
  DISPENSARY = "DISPENSARY",
  DISPENSE_MEDICINE = "DISPENSE_MEDICINE",
  RETURN_MEDICINE = "RETURN_MEDICINE",
  MEDICINE_REPORT = "MEDICINE_REPORT",
  CASHIER = "CASHIER",
  PAY = "PAY",
  TRANSFER_COURSE = "TRANSFER_COURSE",
  RETURN_COURSE = "RETURN_COURSE",
  ASSIGN_COURSE = "ASSIGN_COURSE",
  SETTING = "SETTING",
  GENERAL = "GENERAL",
  WAREHOUSE = "WAREHOUSE",
  USER = "USER",
  DOCUMENT = "DOCUMENT",
  BRANCH_MANAGEMENT = "BRANCH_MANAGEMENT",
  COURSE_MANAGEMENT = "COURSE_MANAGEMENT",
}
export enum ENUM_MENU_TH {
  DASHBOARD = "รายงานข้อมูลประจำวัน",
  NURSERY = "ข้อมูลผู้รับบริบาล",
  MEDICAL_RECORD = "เวชระเบียน",
  QUE_MEDICAL_RECORD = "คิวผู้รับบริบาล",
  FOREWORD_MEDICAL_RECORD = "ส่งต่อผู้รับบริบาล",
  PREREGISTER1 = "ข้อมูลลงทะเบียนล่วงหน้า",
  MARKETING = "การตลาด",
  PREREGISTER2 = "ข้อมูลลงทะเบียนล่วงหน้า",
  PROMOTION = "โปรโมชั่น",
  COUPON = "คูปอง",
  EVENT = "กิจกรรม",
  COURSE_MANAGEMENT = "จัดการคอร์ส",
  SCHEDULE = "ตารางนัด",
  QUE_MEDICAL = "จองคิวแพทย์",
  QUE_PROCEDURE = "จองห้องหัตถการ",
  NOTIFICATION = "แจ้งเตือน",
  MEDICAL = "ห้องแพทย์",
  MEDICAL_ASSISTANT = "ผู้ช่วยแพทย์",
  CONFIRM_ORDER = "ยืนยันออเดอร์",
  PROCESS_ORDER = "ดำเนินการออเดอร์",
  INFIRMARY = "ห้องพยาบาล",
  INCHARGE = "อินชาร์จ",
  PROCEDURE = "หัตถการ",
  REPORT_PROCEDURE = "รายงานหัตถการ",
  LAB = "แล็บ",
  INTERFACE_LAB = "INTERFACE_LAB",
  PHYSICAL_THERAPY = "ห้องกายภาพบำบัด",
  DISPENSARY = "ห้องจ่ายยา",
  DISPENSE_MEDICINE = "จ่ายยา",
  RETURN_MEDICINE = "คืนยา",
  MEDICINE_REPORT = "รายงานยา",
  CASHIER = "ห้องการเงิน",
  PAY = "ชำระเงิน",
  TRANSFER_COURSE = "โอนคอร์ส",
  RETURN_COURSE = "คืนคอร์ส",
  ASSIGN_COURSE = "ASSIGN_COURSE",
  SETTING = "ตั้งค่า",
  GENERAL = "ทั่วไป",
  WAREHOUSE = "คลังสินค้า",
  USER = "จัดการผู้ใช้",
  DOCUMENT = "เอกสาร",
  BRANCH_MANAGEMENT = "จัดการสาขา",
}

export enum ENUM_VIEW_MARKER_TYPE {
  SOAP = "SOAP",
  DOCTOR = "DOCTOR",
}

export enum ENUM_PROMOTION_STATUS {
  //แบบร่าง
  DAFT = "DAFT",
  //เผยแผร่
  PUBLIC = "PUBLIC",
  //หมดอายุ
  EXPIRED = "EXPIRED",
}

export enum EVENT_PATIENT_STATUS {
  //รอดำเนินการ
  PENDING = "PENDING",
  //ดำเนินการ
  IN_PROGRESS = "IN_PROGRESS",
  //ยกเลิก
  CANCEL = "CANCEL",
}

export enum EVENT_ROOM {
  LAB = "LAB",
  PHYSIOTHERAPY = "PHYSIOTHERAPY",
  NURSE = "NURSE",
  PHARMACY = "PHARMACY",
}
export enum ENUM_ROOM_SIGNATURE {
  // ห้องแพทย์
  DOCTOR_ROOM = "DOCTOR_ROOM",
  // ห้องผู้ช่วยแพทย์
  PA_ROOM = "PA_ROOM",
  //หัตถการ
  PROCEDURE = "PROCEDURE",
  //แล็บ
  LAB_ROOM = "LAB_ROOM",
  INTERFACE_LAB_ROOM = "INTERFACE_LAB_ROOM",
  // ห้องกายภาพบำบัด
  PHYSICAL_THERAPY_ROOM = "PHYSICAL_THERAPY_ROOM",
  // ห้องจ่ายยา
  DISPENSARY = "DISPENSARY",
  //ห้องชำระเงิน
  CASHIER = "CASHIER",
}
