import { IGetVisits } from "@interface/IVisits.interface";
import { IAppointments } from "@interface/IAppointments.interface";
import { useTranslation } from "react-i18next";
import { Col, Row, Image } from "antd";
import Left from "@assets/img/LeftCanvas.png";
import Back from "@assets/img/BackCanvas.png";
import Right from "@assets/img/RightCanvas.png";
import Front from "@assets/img/FrontCanvas.png";

type Props = {
  appointmentsByID?: IAppointments | undefined;
  visitsByID?: IGetVisits;
  SOAP?: boolean;
};

const ViewMarker = ({ appointmentsByID, visitsByID, SOAP }: Props) => {
  const { t } = useTranslation();

  return (
    <Row>
      <Col
        span={12}
        className="marker-view col-center border-marker-left border-marker-right"
      >
        {t("front")}
      </Col>
      <Col span={12} className="marker-view col-center border-marker-right">
        {t("behind")}
      </Col>
      <Col
        span={12}
        className="border-marker-left  border-marker-right col-center"
      >
        <div className="fix-pointer-event">
          <Image
            src={
              SOAP
                ? visitsByID?.visitMarker?.soapMarker0?.[0]?.image || Front
                : appointmentsByID?.visit?.visitMarker?.doctorMarker0?.[0]
                    ?.image ||
                  visitsByID?.visitMarker?.doctorMarker0?.[0]?.image ||
                  Front
            }
            fallback={Front}
          />
        </div>
      </Col>

      <Col span={12} className="border-marker-right col-center">
        <div className=" fix-pointer-event">
          <Image
            src={
              SOAP
                ? visitsByID?.visitMarker?.soapMarker1?.[0]?.image || Back
                : appointmentsByID?.visit?.visitMarker?.doctorMarker1?.[0]
                    ?.image ||
                  visitsByID?.visitMarker?.doctorMarker1?.[0]?.image ||
                  Back
            }
            fallback={Back}
          />
        </div>
      </Col>

      <Col
        span={12}
        className="marker-view col-center border-marker-left border-marker-right"
      >
        {t("sideRight")}
      </Col>
      <Col span={12} className="marker-view col-center border-marker-right">
        {t("sideLeft")}
      </Col>

      <Col
        span={12}
        className="border-marker-left  border-marker-right border-marker-bottom col-center"
      >
        <div className="fix-pointer-event">
          <Image
            src={
              SOAP
                ? visitsByID?.visitMarker?.soapMarker2?.[0]?.image || Right
                : appointmentsByID?.visit?.visitMarker?.doctorMarker2?.[0]
                    ?.image ||
                  visitsByID?.visitMarker?.doctorMarker2?.[0]?.image ||
                  Right
            }
            fallback={Right}
          />
        </div>
      </Col>

      <Col
        span={12}
        className="border-marker-right border-marker-bottom col-center"
      >
        <div className="fix-pointer-event">
          <Image
            src={
              SOAP
                ? visitsByID?.visitMarker?.soapMarker3?.[0]?.image || Right
                : appointmentsByID?.visit?.visitMarker?.doctorMarker3?.[0]
                    ?.image ||
                  visitsByID?.visitMarker?.doctorMarker3?.[0]?.image ||
                  Right
            }
            fallback={Left}
          />
        </div>
      </Col>
    </Row>
  );
};

export default ViewMarker;
