import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import { IPurchaseById, IPurchaseList } from "@interface/IWarehouse.interface";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";
import { openNotification } from "@components/notification/Notification";
import { handleMassageError } from "@tools/util";

export const useCreateWarehousePurchaseOrder = (): UseMutationResult => {
  return useMutation(["create-purchase-order"], async (data: any) => {
    const res = await axios.post(`/warehouse/purchase-order`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateWarehousePurchaseOrder = (): UseMutationResult => {
  return useMutation(async (data: any) => {
    const res = await axios.put(
      `/warehouse/purchase-order/${data?.id}`,
      data?.value
    );
    if (res.status >= 200 && res.status < 300) {
      return;
    }
    thrownResponse(res);
  });
};

export const useGetWarehousePurchaseOrder = (
  params?: any
): UseQueryResult<Elementary<IPurchaseList[]>> => {
  return useQuery(["get-warehouse-purchase-order", params], async () => {
    const res = await axios.get(`/warehouse/purchase-order`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetWarehousePurchaseOrderById = (
  id?: number
): UseQueryResult<ElementaryOneMore<IPurchaseById>> => {
  return useQuery(
    ["get-warehouse-purchase-order-by-id", id],
    async () => {
      const res = await axios.get(`/warehouse/purchase-order/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetWarehousePurchaseOrderPoNumber = (
  id?: number
): UseQueryResult<ElementaryOneMore<string>> => {
  return useQuery(
    ["get-warehouse-purchase-order-po-number", id],
    async () => {
      const res = await axios.get(`/warehouse/purchase-order/po-number`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !id }
  );
};

export const useGetWarehouseBranchList = (): UseQueryResult<
  Elementary<any>
> => {
  return useQuery(["get-warehouse-purchase-order-po-number"], async () => {
    const res = await axios.get(`/warehouse/branch`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
