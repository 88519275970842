import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { IGetDraftOrder } from "@interface/IDraftOrder.interface";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { Button, Card, Col, Row } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import TableOrderList from "../order_list/OrderList";
import ProductCategory from "@components/product_category/ProductCategory";

type Props = {
  patientID: number;
  draftOrderList?: IGetDraftOrder;
  medicalRecord: boolean;
  eventId: number;
};

const CreateOrderItem = ({
  patientID,
  draftOrderList,
  medicalRecord,
  eventId,
}: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!draftOrderList?.continuous?.groups?.length);
    //eslint-disable-next-line
  }, [draftOrderList]);

  const createNow = () => {
    setVisible(true);
  };

  const viewDetail = () => {
    setOpen(!open);
  };

  const onManageModal = () => {
    setVisible(!visible);
  };

  return (
    <div className="mt-2">
      <Card
        className="comment-card mt-2 card-out-padding "
        extra={
          <React.Fragment>
            <Row align="middle">
              <Col className="f-normal  t-dark-blue">
                <span className="f-bold pr-05 cursor" onClick={viewDetail}>
                  {`${t("orderList")} Continuous`}
                </span>

                <span className="pr-05">
                  {React.createElement(open ? UpOutlined : DownOutlined, {
                    onClick: viewDetail,
                  })}
                </span>
              </Col>
              {draftOrderList?.continuous?.groups?.length && medicalRecord ? (
                <Col style={{ marginLeft: "auto" }}>
                  <Button type="primary" onClick={createNow}>
                    {t("editOrder")}
                  </Button>
                </Col>
              ) : (
                " "
              )}
            </Row>
          </React.Fragment>
        }
      >
        <div className={open ? "open" : "close"}>
          <TableOrderList
            patientID={patientID}
            draftOrderList={draftOrderList}
            createNow={createNow}
            medicalRecord={medicalRecord}
            keyData="continuous"
            eventId={eventId}
          />
        </div>
      </Card>

      {visible && (
        <ProductCategory
          patientID={patientID}
          visible={visible}
          onModal={onManageModal}
          type="CONTINUE"
          eventId={eventId}
          room={ENUM_ROOM_SIGNATURE.DOCTOR_ROOM}
        />
      )}
    </div>
  );
};

export default CreateOrderItem;
