import { Card } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import FieldsPickUpGoods from "./Fields";
import HeaderPickUpGoods from "./Header";
import { useRouteMatch } from "react-router-dom";
import { useGetWarehouseSaleOrderById } from "@services/api/warehouse_sale_order";

const FormPickUpGoods = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const saleOrderById = match.params.id;
  const [managePickUpGoods] = useForm();
  const pickUpGoodsById = useGetWarehouseSaleOrderById(saleOrderById);
  const info: any = pickUpGoodsById?.data?.result?.[0];

  const [visible, setVisible] = useState<boolean>(false);
  const [salesReturn, setSalesReturn] = useState<boolean>(false);

  useEffect(() => {
    if (!info) return;
  });

  return (
    <div>
      <HeaderPickUpGoods
        managePickUpGoods={managePickUpGoods}
        visible={visible}
        setVisible={setVisible}
        setSalesReturn={setSalesReturn}
        status={info?.status}
      />

      <div className="set-layout ">
        <Card
          className=" pb-2"
          extra={<b className="header-card">{t("receiptProductManagement")}</b>}
        >
          <FieldsPickUpGoods
            managePickUpGoods={managePickUpGoods}
            visible={visible}
            salesReturn={salesReturn}
          />
        </Card>
      </div>
    </div>
  );
};

export default FormPickUpGoods;
