import { IUser } from "@interface/IUsers.interface";
import { IAgent } from "@interface/IAgent.interface";
import { ICanvasDraw } from "@interface/IImageCanvas";
import { ENUM_PRODUCT_GROUP } from "./util.enum";
import { IRejectOrderGroups, ISelectedList } from "@interface/IOrder.interface";
import { IFormatLabInterface } from "@interface/ILab.interface";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { ICheckUpdate, INavMenu } from "@interface/IGlobal.interface";
import { IItem, IOrderListAndRecord } from "@interface/IOrder.interface";
import Logo from "../assets/img/logo/Ab.png";
import dayjs from "dayjs";
import moment from "moment";
import CryptoJS from "crypto-js";
import NotImage from "../assets/img/NotImage.jpeg";
import i18n from "i18next";
export const displayHide = (
  keyActive: string | number,
  visible?: string | number
) => {
  if (keyActive === visible) {
    return "";
  }
  return "d-none";
};

export const _isEmpty = (data: any) => {
  try {
    const queryDefault =
      data === null ||
      data === "undefined" ||
      data === undefined ||
      data === "";
    if (queryDefault) return true;
    if (typeof data === "number") return false;
    if (typeof data === "string") return false;
    const obj = queryDefault ? [] : data;
    return Object?.entries(obj)?.length === 0;
  } catch (err) {
    console.log(err);
  }
};

export const createQueryString = (values: any) => {
  try {
    if (!values) {
      return "";
    }
    let query = "";
    let result = {};
    for (const [key, value] of Object.entries(values)) {
      if (value || value === 0 || value === false) {
        result = { ...result, [key]: value };
      }
    }
    Object?.entries(result)?.forEach(([key, value], index) => {
      if (index === Object?.keys(result)?.length - 1) {
        if (value || value === 0 || value === false) {
          query += `${key}=${value}`;
        }
      } else {
        if (value || value === 0 || value === false) {
          query += `${key}=${value}&`;
        }
      }
    });
    return "?" + query;
  } catch (err) {
    console.log(err);
  }
};

export const filterHaveValue = (value: any, qrFormat: any) => {
  try {
    let resultStr = "";
    if (!_isEmpty(value)) {
      const slited = qrFormat?.split("%variable");
      slited.forEach((item: any) => {
        if (item) resultStr = resultStr + item + value;
      });
    }

    return resultStr;
  } catch (err) {
    console.log(err);
  }
};

export const toSpecialQrs = (qrsArr: any) => {
  try {
    let resultQrs = "";
    qrsArr.forEach((item: any, index: any) => {
      const variableQrs = filterHaveValue(item?.value, item?.qrs);
      resultQrs = resultQrs + variableQrs;
      if (index !== qrsArr?.length - 1) {
        resultQrs = !_isEmpty(qrsArr?.[index + 1]?.value)
          ? resultQrs + `&${item?.type}=`
          : resultQrs;
      }
      if (resultQrs === "&") {
        resultQrs = "";
      }
      if (resultQrs === `&${item?.type}=`) {
        resultQrs = "";
      }
    });
    return resultQrs;
  } catch (err) {
    console.log(err);
  }
};

export const searchHaveValue = (value: any, qrFormat: any) => {
  try {
    let resultStr = "";

    if (!_isEmpty(value)) {
      const slited = qrFormat.split("%variable");
      slited.forEach((item: any, ind: number) => {
        if (!_isEmpty(item) && ind < slited?.length - 1) {
          resultStr = resultStr + item + value;
        } else {
          resultStr = resultStr + item;
        }
      });
    }

    return resultStr;
  } catch (err) {
    console.log(err);
  }
};

export const searchQrs = (qrsArr: any) => {
  try {
    let resultQrs = "";
    qrsArr.forEach((item: any, index: any) => {
      const variableQrs = searchHaveValue(item?.value, item?.qrs);
      resultQrs = resultQrs + variableQrs;
      if (index !== qrsArr?.length - 1) {
        resultQrs = !_isEmpty(qrsArr?.[index + 1]?.value)
          ? resultQrs + `,`
          : resultQrs;
      }
      if (resultQrs === ",") {
        resultQrs = "";
      }
    });
    return resultQrs;
  } catch (err) {
    console.log(err);
  }
};

export const generateUniqueId = (source?: string[]): string | undefined => {
  try {
    if (source) {
      const random = `${Math.random().toString(16).slice(2)}`;
      if (source.some((e) => e === random)) {
        return generateUniqueId(source);
      }
      return random;
    }
    return `${Math.random().toString(16).slice(2)}`;
  } catch (err) {
    console.log(err);
  }
};

export const checkThereKey = (value: any[], type: string) => {
  try {
    const checkKey = value?.filter((item: any) => {
      return item?.id && typeof item?.id === type;
    });

    return checkKey;
  } catch (err) {
    console.log(err);
  }
};

export const blastChildren = (value: any) => {
  try {
    const dataList = value?.reduce((result: any, current: any) => {
      if (result.some((e: any) => e?.group === current?.name)) {
        const group = result?.map((item: any) => {
          if (item?.group === current?.name) {
            return {
              ...item,
              total: item?.total + Number(current?.total),
              items: [...(item?.items || []), ...(current?.items || [])],
            };
          }
          return item;
        });
        return group;
      } else {
        return [
          ...result,
          {
            group: current?.name,
            key: current?.name,
            level: 1,

            total: Number(current?.total),
            items: [...(current?.items || [])],
          },
        ];
      }
    }, []);

    dataList?.forEach((item: any, index: number) => {
      item.children = [
        {
          key: index,
          name: "ชื่อรายการ",
          times: "ครั้งที่",
          type: "ประเภท",
          testCode: "Test Code",
          note: "หมายเหตุ",
          status: "สถานะ",
          quantity: "จำนวน",
          price: "ราคา",
          level: 2,
          children: item?.items.map((data: any) => {
            return {
              ...data,
              nameGroup: item?.group,
              key: data?.id,
              level: 3,
            };
          }),
        },
      ];
      delete item?.items;
    });
    return dataList;
  } catch (err) {
    console.log(err);
  }
};

export const manageArray = (arr: any, first: number, second: number) => {
  try {
    var newArr = [];
    let i = -1;
    while (arr?.length) {
      i++;
      if (i === 0) {
        newArr?.push(arr?.splice(0, first));
      } else {
        newArr?.push(arr?.splice(0, second));
      }
    }
    if (newArr?.length === 1) {
      newArr?.push([]);
    }

    return newArr;
  } catch (err) {
    console.log(err);
  }
};

export const gender = (status: string | undefined, language?: string) => {
  try {
    if (status === "MALE") {
      return language === "th" ? "ชาย" : "Male";
    } else if (status === "FEMALE") {
      return language === "th" ? "หญิง" : "Female";
    } else {
      return "-";
    }
  } catch (err) {
    console.log(err);
  }
};

export const formatDataDraft = (dataList?: any, status?: string) => {
  try {
    let data = dataList;
    if (dataList) {
      data?.forEach((item: any, index: number) => {
        item.children = [
          {
            id: index,
            key: "children" + index,
            total: "ยอดรวม",
            title: "ชื่อรายการ",
            price: "ราคา",
            number: "จำนวน",
            discount: "ส่วนลด",
            level: status ? 2 : 1,
            children: status ? item?.items : item?.children,
          },
        ];

        item.key = index;

        data?.[index].children?.[0]?.children?.forEach((itemList: any) => {
          itemList.key = itemList?.id;
          itemList.level = status ? 3 : 2;
        });
      });
    }

    return data || [];
  } catch (err) {
    console.log(err);
  }
};

export const groupWorkOrderByListType = (
  parent: IItem[],
  children: string[]
) => {
  try {
    const dataList = parent?.map((item: IItem) => {
      const list = children?.find((value: string) => value === item?.uuid);

      if (list) {
        return {
          uuid: list,
          use: true,
        };
      }
      return {
        uuid: item?.uuid,
        use: false,
      };
    });

    return dataList;
  } catch (err) {
    console.log(err);
  }
};

export const printOrderList = (orderList: IItem[], seletection: string[]) => {
  try {
    let result: IItem[] = [];
    const dataTreatment = orderList?.filter((item: any) => {
      if (item?.product?.category?.id === "C-0001") {
        return item;
      } else {
        if (item?.children?.[0]?.children) {
          const courseItem = item?.children?.[0]?.children?.filter(
            (item: IItem) => {
              return (
                seletection?.indexOf(item?.id) !== -1 &&
                item?.product?.category?.id === "C-0001"
              );
            }
          );
          result?.push(...courseItem);
        }

        return false;
      }
    });

    const data = [...(dataTreatment || []), ...(result || [])]?.reduce(
      (result: any, current: IItem) => {
        if (result?.some((e: any) => e.id === current?.product?.id)) {
          const group = result?.map((item: IItem) => {
            if (item?.id === current?.product?.id) {
              return {
                ...item,
                qty:
                  Number(item?.qty) +
                  Number(
                    current?.qtyChange !== undefined
                      ? current?.qtyChange
                      : current?.qtyOutstanding
                  ),
              };
            }
            return item;
          });

          return group;
        } else {
          return [
            ...result,
            {
              id: current?.product?.id,
              nameTh: current?.product?.nameTh,
              nameEn: current?.product?.nameEn,
              qty:
                current?.qtyChange !== undefined
                  ? current?.qtyChange
                  : current?.qtyOutstanding,
            },
          ];
        }
      },
      []
    );
    return data;
  } catch (err) {
    console.log(err);
  }
};

export const eatingPattern = (option: string[], time: string) => {
  try {
    return option?.some((e: string) => e === time);
  } catch (err) {
    console.log(err);
  }
};

export const timeSpent = (option: string[], time: string) => {
  try {
    return option?.some((e: string) => e === time) && 1;
  } catch (err) {
    console.log(err);
  }
};

export const optionDataList = (data: any) => {
  try {
    const option =
      data.groupId === ENUM_PRODUCT_GROUP.IV_MEDICATIONS ||
      data.groupId === ENUM_PRODUCT_GROUP.ID_SQ_IM_MEDICATIONS
        ? {
            ...data,
            dose: data?.dose,
            fluid: data?.fluid,
            start_time: data?.start_time
              ? moment(data?.start_time).format("HH:mm:ssZ")
              : undefined,
            end_time: data?.end_time
              ? moment(data?.end_time).format("HH:mm:ssZ")
              : undefined,
          }
        : data.groupId === ENUM_PRODUCT_GROUP.PHYSICAL_THERAPY && {
            ...data,
            time: data?.time
              ? moment(data?.time).format("HH:mm:ssZ")
              : undefined,
          };
    return option;
  } catch (err) {
    console.log(err);
  }
};

export const checkCourse = (parentITem: IItem[], status?: string) => {
  try {
    let dataCourse: IItem[];

    if (status === "use") {
      dataCourse = parentITem?.filter((item: IItem) => {
        return (
          item?.use === true &&
          (item?.product?.categoryId === "C-0006" ||
            item?.product?.category?.id === "C-0006" ||
            item?.product?.categoryId === "C-0007" ||
            item?.product?.category?.id === "C-0007")
        );
      });
    } else if (status === "product") {
      dataCourse = parentITem?.filter(
        (item: IItem) =>
          item?.product?.categoryId === "C-0006" ||
          item?.product?.category?.id === "C-0006" ||
          item?.product?.categoryId === "C-0007" ||
          item?.product?.category?.id === "C-0007"
      );
    } else if (status === "noCourse") {
      dataCourse = parentITem?.filter(
        (item: IItem) =>
          item?.product?.categoryId !== "C-0007" &&
          item?.product?.category?.id !== "C-0007" &&
          item?.product?.categoryId !== "C-0006" &&
          item?.product?.category?.id !== "C-0006" &&
          (item?.product?.category?.id === "C-0001" ||
            item?.product?.categoryId === "C-0001")
      );
    } else {
      dataCourse = parentITem?.filter(
        (item: IItem) =>
          item?.product?.categoryId === "C-0006" ||
          item?.product?.category?.id === "C-0006" ||
          item?.product?.categoryId === "C-0007" ||
          item?.product?.category?.id === "C-0007"
      );
    }

    return dataCourse;
  } catch (err) {
    console.log(err);
  }
};

export const recursive = (obj: any) => {
  try {
    return obj?.map((item: any) => {
      if (item?.children) {
        return [recursive(item?.children), item?.key];
      }
      return item?.key;
    });
  } catch (err) {
    console.log(err);
  }
};

export const reformatDataOrderList = (
  outStanding: IOrderListAndRecord[],
  seletectionList: string[] | number[],
  status: string
) => {
  try {
    const entires = outStanding
      ?.map((item) => {
        const dataList = item?.items?.map((data) => {
          if (status === "signcourse" && data?.children) {
            return {
              ...data,
              name: item?.name,
              key: data?.id,
              level: 3,
              no: item?.order?.no,
              orderId: item?.order?.id,
              children: [
                {
                  ...data?.children?.[0],
                  children: data?.children?.[0]?.children?.map((list) => {
                    return {
                      ...list,
                      key: `children${list?.id}`,
                    };
                  }),
                },
              ],
            };
          }
          if (status === "signcourse" && !data?.children) {
            return {
              ...data,
              name: item?.name,
              key: data?.id,
              level: 3,
              no: item?.order?.no,
              orderId: item?.order?.id,
            };
          } else {
            return {
              ...data,
              name: item?.name,
              key: [
                "executeOrder",
                "inchartWait",
                "inchartBetween",
                "returncourse",
              ]?.includes(status)
                ? `children${data?.id}${Math.random() * 10000}`
                : (status === "transfercourse" &&
                    data?.product?.category?.id === "C-0006") ||
                  data?.product?.category?.id === "C-0007"
                ? `children${data?.id}${Math.random() * 10000}`
                : (status === "transfercourse" &&
                    data?.product?.category?.id !== "C-0006") ||
                  data?.product?.category?.id !== "C-0007"
                ? data?.id
                : data?.id,
              level: 3,
              no: item?.order?.no,
              qtyChange:
                data?.product?.category?.id !== "C-0002"
                  ? 1
                  : data.qtyOutstanding,
              orderId: item?.order?.id,
              children: data?.children?.map((parent) => {
                return {
                  ...parent,
                  children: parent?.children?.map((child) => {
                    return {
                      ...child,
                      qtyChange: 1,
                    };
                  }),
                };
              }),
            };
          }
        });
        return dataList;
      })
      ?.flat();

    const filter = entires?.filter((item) => {
      return seletectionList?.some((e: string | number) => e === item?.id);
    });

    const dataList = filter?.reduce(
      (result: IOrderListAndRecord[] | any, current: any, index: number) => {
        if (
          result?.some((e: IOrderListAndRecord) => e?.group === current?.name)
        ) {
          const group = result?.map((item: any) => {
            if (item?.group === current?.name) {
              return {
                ...item,
                total: item?.total + Number(current?.total),
                items: [...(item?.items || []), current]?.map(
                  (parent: IItem) => {
                    return {
                      ...parent,
                      children: parent?.children?.map((child) => {
                        return {
                          ...child,
                          type: parent?.type,
                          children: child?.children?.map((child1) => {
                            return {
                              ...child1,
                              type: parent?.type,
                            };
                          }),
                        };
                      }),
                    };
                  }
                ),
              };
            }
            return item;
          });
          return group;
        } else {
          return [
            ...result,
            {
              group: current?.name,
              groupTh: current?.product?.category?.nameTh,
              groupEn: current?.product?.category?.nameEn,
              key: [
                "executeOrder",
                "inchartWait",
                "inchartBetween",
                "signcourse",
                "returncourse",
              ]?.includes(status)
                ? `children${current?.name}${index}`
                : status === "transfercourse"
                ? `children${current?.name}${index}`
                : current?.name,
              level: 1,
              total: Number(current?.net || 0),
              categoryId: current?.product?.category?.id,
              items: [current]?.map((parent: IItem) => {
                return {
                  ...parent,
                  children: parent?.children?.map((child) => {
                    return {
                      ...child,
                      type: parent?.type,
                      children: child?.children?.map((child1) => {
                        return {
                          ...child1,
                          type: parent?.type,
                        };
                      }),
                    };
                  }),
                };
              }),
            },
          ];
        }
      },
      []
    );

    dataList?.forEach((item: IRejectOrderGroups, index: number) => {
      item.children = [
        {
          key: [
            "executeOrder",
            "inchartWait",
            "inchartBetween",
            "signcourse",
            "returncourse",
          ]?.includes(status)
            ? `children${index}`
            : status === "transfercourse"
            ? `children${index}`
            : index,
          name: "ชื่อรายการ",
          times: "ครั้งที่",
          quantity: "จำนวน",
          price: "ราคา",
          level: 2,
          children: item?.items,
        },
      ];

      delete item?.items;
    });
    const dataCourse = dataList?.filter(
      (item: ISelectedExecuteOrder) =>
        item?.children?.[0]?.children?.[0]?.product?.category?.id ===
          "C-0006" ||
        item?.children?.[0]?.children?.[0]?.product?.categoryId === "C-0006" ||
        item?.children?.[0]?.children?.[0]?.product?.category?.id ===
          "C-0007" ||
        item?.children?.[0]?.children?.[0]?.product?.categoryId === "C-0007"
    );

    const result = recursive(dataCourse);

    return { dataList: dataList, result: result };
  } catch (err: any) {
    console.log(err, "err");
  }
};

export const onImgError = () => {
  return NotImage;
};

export const onImgLogoError = () => {
  return Logo;
};

export const splitKeyFormData = (data: string | Blob | Object | number) => {
  try {
    let formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (!!value?.[0]?.originFileObj) {
        value?.forEach((item: any) => {
          formData?.append(key, item?.originFileObj);
        });
      } else if ((!!value && value !== 0) || value === false) {
        formData?.append(key, value);
      }
    }
    return formData;
  } catch (err: any) {
    console.log(err, "err");
  }
};

export const setFormFieldValue = (dataList: any) => {
  try {
    let num = 0;

    Object.values(dataList)?.forEach((item) => {
      if (typeof item !== "undefined") {
        num++;
      }
    });
    return num;
  } catch (err: any) {
    console.log(err, "err");
  }
};

export const reformatDataLabInterface = (
  _data: IFormatLabInterface,
  dataTable: string[]
) => {
  var dateOfInfo: string[] = dataTable;
  let obx: any = [];
  try {
    for (const _data2 of _data?.data) {
      const _obx = _data2?.result?.obx;
      for (let i = 1; i < _obx?.length; i++) {
        const find = obx.find(
          (obxItem: any) => obxItem?.testCode === _obx?.[i]?.testCode
        );
        const date = dayjs(_data2?.createdAt).format("YYYY-MM-DD");

        _obx[i].date = _data2?.createdAt;
        if (find) {
          const findDate = find?.group?.[date];
          if (findDate) {
            find.group[date] = [...(find?.group?.[date] || []), _obx?.[i]];
          } else {
            find.group[date] = [_obx?.[i]];
          }
        } else {
          const format = {
            ..._obx?.[i],
            testCode: _obx?.[i]?.testCode,
            normalRange: _obx?.[i]?.normalRange,
            unit: _obx?.[i]?.unit,
            group: { [date]: [_obx?.[i]] },
          };
          delete _obx?.testCode;
          obx = [...(obx || []), format];
        }

        const even = dateOfInfo?.some((elementDate) => elementDate === date);
        if (!even) {
          dateOfInfo = [...(dateOfInfo || []), date];
        }
      }
    }

    return {
      result: {
        testCode: _data?.testCode,
        group: obx,
      },
      date: dateOfInfo?.sort(),
    };
  } catch (err) {
    console.log(err);
  }
};

export const checkUpdate = (oldData: any[], newData: any[]) => {
  try {
    const removeDataArray = oldData?.filter((val) => !newData?.includes(val));
    const newArray: number[] = newData?.filter(
      (val) => !oldData?.includes(val)
    );
    const oldArray: number[] = newData?.filter((val) => oldData?.includes(val));
    const filerRemoveArray: number[] = oldData?.filter((val) =>
      removeDataArray?.includes(val)
    );
    const filerOldArray: number[] = oldData?.filter((val) =>
      oldArray?.includes(val)
    );
    return {
      newData: newArray,
      oldData: filerOldArray,
      removeData: filerRemoveArray,
    } as ICheckUpdate;
  } catch (err: any) {
    console.log(err, "err");
  }
};

export const removeMenu = (navMenu: INavMenu[], userList: IUser) => {
  try {
    const permission = navMenu?.filter((item) => {
      return (
        userList?.permission
          ?.flatMap((itemLst) => {
            return itemLst?.nameEn;
          })
          .indexOf(item?.authMenuName) !== -1
      );
    });

    const newSubMenu = userList?.permission?.flatMap((item) => {
      return item?.subMenu;
    });

    const newMenu = permission?.map((item) => {
      return {
        ...item,
        children: item?.children?.filter((subMenu) => {
          return (
            newSubMenu
              ?.flatMap((itemLst) => {
                return itemLst?.nameEn;
              })
              ?.indexOf(subMenu?.subMenuName) !== -1
          );
        }),
      };
    });

    return newMenu;
  } catch (err: any) {
    console.log(err, "err");
  }
};

export const reformatOutStanding = (
  outStanding: IOrderListAndRecord[] | []
) => {
  try {
    const result = outStanding?.map((item: IOrderListAndRecord) => {
      if (item?.groups?.length > 0) {
        const formatGroup = item?.groups?.map((group) => {
          return {
            ...group,
            items: group?.items?.map((itemList: IItem) => {
              if (itemList?.parent || itemList?.parentId) {
                const list = itemList?.parent || itemList;
                return {
                  ...list,
                  transformById: itemList?.transformById,
                  assign: true,
                };
              } else {
                return { ...itemList };
              }
            }),
          };
        });
        item.groups = formatGroup as IOrderListAndRecord[];
        return item;
      }
      if (item?.items?.length > 0) {
        return {
          ...item,
          items: item?.items?.map((itemList: IItem) => {
            if (itemList?.parent || itemList?.parentId) {
              return {
                ...itemList?.parent,
                transformById: itemList?.transformById,
                assign: true,
              };
            } else {
              return { ...itemList };
            }
          }),
        };
      } else {
        return outStanding;
      }
    });
    return result;
  } catch (e) {
    console.error(e);
  }
};

export const formatAgents = (agents: IAgent[]) => {
  try {
    const list = agents?.map((item) => {
      return {
        ...item,
        nameTh: [item?.firstNameTh, item?.lastNameTh]?.join(" ")?.trim(),
        nameEn: [item?.firstNameEn, item?.lastNameEn]?.join(" ")?.trim(),
      };
    });
    return list;
  } catch (err: any) {
    console.log(err, "err");
  }
};

export const idProductGetOrderSignature = (
  orderList: ISelectedExecuteOrder[],
  selection: ISelectedList
) => {
  try {
    const children = orderList
      ?.map((childrenItem: any) => {
        return childrenItem?.children?.map((i: IItem) => i?.children);
      })
      ?.flat(Infinity);

    const course = checkCourse(children);
    const product = checkCourse(children, "noCourse");

    const productID = product
      ?.map((product: IItem) => {
        return product?.product?.id;
      })
      ?.flat(Infinity);

    const childrenCourse = course?.flatMap((item) => {
      return item?.children?.[0]?.children
        ?.filter((itemList) => {
          return (
            itemList?.product?.category?.id === "C-0001" &&
            selection.course.includes(itemList.id)
          );
        })
        ?.flatMap((list) => {
          return list?.product?.id;
        });
    });

    return {
      ids:
        Array.from(
          new Set([...(productID || []), ...(childrenCourse || [])])
        ) || [],
    };
  } catch (err) {
    console.log(err);
  }
};

export const dataUrlToFile = (
  dataURI: string,
  fileName: string = "file.png"
) => {
  try {
    var byteString = atob(dataURI?.split(",")?.[1]);
    var mimeString = dataURI?.split(",")?.[0]?.split(":")?.[1]?.split(";")?.[0];

    var ab = new ArrayBuffer(byteString?.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString?.length; i++) {
      ia[i] = byteString?.charCodeAt(i);
    }
    return new File([ab], fileName, { type: mimeString });
  } catch (err) {
    return new File([], fileName);
  }
};

export const fileToDataUrl = (file: File) =>
  new Promise<string | ArrayBuffer | null>((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader?.readAsDataURL(file);
      reader.onload = () => resolve(reader?.result);
      reader.onerror = (error) => reject(error);
    } catch (err) {
      console.log(err);
    }
  });

type IImageToCanvas = {
  ctx: ICanvasDraw | null;
  signature: Text | string;
};

export const imageToCanvas = async ({ ctx, signature }: IImageToCanvas) => {
  if (!ctx) return;
  try {
    var image: any = new Image();
    if (signature) {
      image.onload = async () => {
        await ctx?.drawImage(image, 0, 0);
      };

      image.src = signature;
      image.crossOrigin = "anonymous";
    }
  } catch (err) {
    console.log(err);
  }
};

export const encryptWithAES = (text: string) => {
  try {
    const passphrase = "P@ssW0rd";
    return CryptoJS.AES.encrypt(text, passphrase).toString();
  } catch (err) {
    console.log(err);
  }
};

export const decryptWithAES = (cipherText: string): any => {
  try {
    const passphrase = "P@ssW0rd";
    const bytes = CryptoJS.AES.decrypt(cipherText, passphrase);
    const originalText = bytes?.toString(CryptoJS.enc.Utf8);

    return JSON.parse(originalText || `{}`);
  } catch (err) {
    localStorage.removeItem("accessToken");
    window.location.reload();
    console.log(err);
  }
};

export const downloadXLSX = (blobFile: Blob, name: string) => {
  const url = window.URL.createObjectURL(new Blob([blobFile]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", `${name}-${new Date().toISOString()}.xlsx`);
  document.body.appendChild(link);
  link.click();
};

export const enterNextInput = (
  event: React.KeyboardEvent<HTMLFormElement>,
  key: string
) => {
  if (event?.code === "Enter") {
    const element = document?.getElementById(key);
    const inputElement = element?.getElementsByTagName("input") || [];
    let index = 0;
    for (let i = 0; i < inputElement?.length; i++) {
      let item = inputElement?.[i];

      if (document?.activeElement === item) {
        index = i;
        break;
      }
    }

    document.getElementById(inputElement?.[index + 1]?.id)?.focus();
  }
};

export const TextBRHTML = (text: string) => {
  let result2 = text.replace(/\n/g, "<br>");
  return result2;
};

export const productName = (
  language: string,
  nameTh?: string,
  nameEn?: string
) => {
  let nameProduct =
    language === "th"
      ? !nameTh || nameTh === "-"
        ? nameEn || "-"
        : nameTh || "-"
      : !nameEn || nameEn === "-"
      ? nameTh || "-"
      : nameEn || "-";
  return nameProduct;
};

export const randomString = (length: number) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  const charactersLength = characters?.length;
  let counter = 0;
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
    counter += 1;
  }
  return result;
};

export const _amountToWordsEN = (amount: number): string => {
  const numberToWordsMap: Record<number, string> = {
    0: "zero",
    1: "one",
    2: "two",
    3: "three",
    4: "four",
    5: "five",
    6: "six",
    7: "seven",
    8: "eight",
    9: "nine",
    10: "ten",
    11: "eleven",
    12: "twelve",
    13: "thirteen",
    14: "fourteen",
    15: "fifteen",
    16: "sixteen",
    17: "seventeen",
    18: "eighteen",
    19: "nineteen",
    20: "twenty",
    30: "thirty",
    40: "forty",
    50: "fifty",
    60: "sixty",
    70: "seventy",
    80: "eighty",
    90: "ninety",
    100: "hundred",
    1000: "thousand",
    1000000: "million",
    1000000000: "billion",
  };

  function convertLessThanOneThousand(num: number): string {
    let words = "";
    if (num >= 100) {
      words +=
        numberToWordsMap[Math.floor(num / 100)] +
        " " +
        numberToWordsMap[100] +
        " ";
      num %= 100;
    }

    if (num >= 20) {
      words += numberToWordsMap[Math.floor(num / 10) * 10] + " ";
      num %= 10;
    }

    if (num > 0) {
      words += numberToWordsMap[num] + " ";
    }

    return words;
  }

  if (amount === 0) {
    return numberToWordsMap[0];
  }

  let words = "";

  if (Math.floor(amount / 1000000000) > 0) {
    words +=
      convertLessThanOneThousand(Math.floor(amount / 1000000000)) +
      numberToWordsMap[1000000000] +
      " ";
    amount %= 1000000000;
  }

  if (Math.floor(amount / 1000000) > 0) {
    words +=
      convertLessThanOneThousand(Math.floor(amount / 1000000)) +
      numberToWordsMap[1000000] +
      " ";
    amount %= 1000000;
  }

  if (Math.floor(amount / 1000) > 0) {
    words +=
      convertLessThanOneThousand(Math.floor(amount / 1000)) +
      numberToWordsMap[1000] +
      " ";
    amount %= 1000;
  }

  if (amount > 0) {
    words += convertLessThanOneThousand(amount);
  }

  return `${words.trim()} baht.`;
};

export const handleMassageError = (
  error: any | { TH: string; EN: string }
): string => {
  if (typeof error === "object") {
    return String(
      i18n.language === "th" ? error?.TH || error : error?.EN || error
    );
  } else {
    return String(error);
  }
};

export const capitalize = (word: string) => {
  if (!word) {
    return "";
  }
  return word[0].toUpperCase() + word.slice(1).toLowerCase();
};

export const genderToThaiWord = (gender: string) => {
  switch (gender) {
    case "MALE":
      return "ชาย";
    case "FEMALE":
      return "หญิง";
    default:
      return "-";
  }
};

type IOrderGroups = {
  key: any;
  level?: number | undefined;
  item?: IItem | undefined;
  children?: any[] | undefined;
};

export const splitData = <T>(
  data: IOrderGroups[],
  ...groupSizes: number[]
): IOrderGroups[][] => {
  const result: IOrderGroups[][] = [];
  let currentIndex = 0;
  let currentRow = 0;

  for (let i = 0; i < data.length; i++) {
    if (currentIndex >= data.length) break;
    const rowSizeOfName = calTitle(data[i].item?.product["nameTh"] || "", 45);
    const rowSizeOfDescription = !!data[i].item?.product["descriptionTh"]
      ?.length
      ? calTitle(data[i].item?.product["descriptionTh"] || "", 45)
      : 0;
    const lastIndex = rowSizeOfName + rowSizeOfDescription > 2 ? i - 1 : i;
    currentRow = currentRow + rowSizeOfName + rowSizeOfDescription;

    if (result.length) {
      if (currentRow > groupSizes[1]) {
        const group = data.slice(currentIndex, lastIndex);
        result.push(group);
        currentIndex = lastIndex;
        currentRow =
          rowSizeOfName + rowSizeOfDescription > 2
            ? rowSizeOfName + rowSizeOfDescription
            : 0;
      } else if (i === data.length - 1) {
        const group = data.slice(currentIndex, data.length);
        result.push(group);
        currentIndex = lastIndex;
        currentRow = 0;
      }
    } else {
      if (currentRow > groupSizes[0]) {
        const group = data.slice(currentIndex, lastIndex);
        result.push(group);
        currentIndex = lastIndex;
        currentRow = 0;
      } else if (i === data.length - 1) {
        const group = data.slice(currentIndex, data.length);
        result.push(group);
        currentIndex = lastIndex;
        currentRow = 0;
      }
    }
  }

  return result;
};

export const calTitle = (test: string, length: number): number => {
  return test.length ? Math.ceil(test.length / length) : 1;
};
