import { displayHide } from "@tools/util";
import { useTranslation } from "react-i18next";
import { Table, Pagination, TableProps } from "antd";

interface Props extends TableProps<any> {
  config?: any;
  onChangePage?: (page: number, pageSize?: string) => void;
  onClickRow?: (row: any) => void;
  pagination?: boolean | any;
  onDoubleClick?: boolean;
  columns?: any[];
}

const MoTable = ({
  className = "",
  pagination = true,
  dataSource = [],
  expandRowByClick = false,
  showSorterTooltip = false,
  columns = [],
  loading = false,
  defaultExpandedRowKeys = [],
  config = { total: 15, currentPage: 1, pageSize: 10 },
  onChangePage,
  rowKey = "id",
  onClickRow,
  onDoubleClick = false,
  rowSelection,
  onChange,
  scroll,
  rowClassName,
  locale,
  ...props
}: Props) => {
  const { t } = useTranslation();
  return (
    <div className="card-table">
      <Table
        scroll={scroll}
        className={className || "table-selection"}
        onChange={onChange}
        locale={locale}
        dataSource={dataSource}
        columns={columns}
        expandRowByClick={expandRowByClick}
        showSorterTooltip={showSorterTooltip}
        pagination={false}
        defaultExpandedRowKeys={defaultExpandedRowKeys}
        loading={loading}
        rowKey={rowKey}
        rowSelection={rowSelection}
        rowClassName={
          rowClassName
            ? rowClassName
            : (r) => {
                if (r?.level === 1) {
                  return " no-header ";
                } else if (r?.level === 2) {
                  return "table-header no-header  ";
                } else if (r?.level === 3 && r?.children) {
                  return " no-header table-confirm-level-course table-top-level-course";
                } else if (r?.level === 3 && r?.children) {
                  return " no-header table-confirm-level-course ";
                } else if (r?.level === 3) {
                  return " no-header ";
                } else if (r?.level === 5) {
                  return "table-confirm-first no-header table-confirm-level-course table-bottom--level-course  ";
                } else if (r?.level === 4) {
                  return "table-header no-header table-confirm-level-course  ";
                }
                return "";
              }
        }
        onRow={(record, rowIndex) => {
          return onDoubleClick
            ? {
                onDoubleClick: (event) => {
                  onClickRow && onClickRow(record);
                },
              }
            : {
                onClick: (event) => {
                  onClickRow && onClickRow(record);
                },
              };
        }}
        {...props}
      />
      {pagination && (
        <div
          className={displayHide(
            config.total,
            config.total === 0 ? null : config.total
          )}
        >
          <div className="mt-2 mb-2 w-100 d-flex justify-flex-end">
            <div className="d-flex items-center pr-1">{t("showList")}</div>
            <Pagination
              showSizeChanger
              onChange={(page) => onChangePage && onChangePage(page, "page")}
              total={config.total}
              current={config.currentPage}
              pageSize={config.pageSize}
              className="pagi"
              onShowSizeChange={(_current, pageSize) =>
                onChangePage && onChangePage(pageSize, "pageSize")
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default MoTable;
