import { useState } from "react";
import { ISearchPrefix } from "@interface/ISearch.interface";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";
import TableContractCompany from "./Table";

const ContractCompany = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<ISearchPrefix>();

  const onFinish = (value: ISearchPrefix) => {
    setSearch(value);
    setCurrentPage(1);
  };
  return (
    <div>
      <div className="pb-1">
        <Form onFinish={onFinish}>
          <Row gutter={[14, 14]}>
            <Col lg={18}>
              <Form.Item name="name">
                <Input placeholder={`${t("search")}`} />
              </Form.Item>
            </Col>

            <Col>
              <Button className="btn-search" htmlType="submit">
                {t("search")}
              </Button>
              <Button className="clear ml-05" htmlType="reset">
                {t("reset")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <TableContractCompany
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
        search={search}
      />
    </div>
  );
};

export default ContractCompany;
