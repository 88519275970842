import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { MEDICAL_DOCUMENT } from "@assets/data/pathRouter";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import React from "react";
import RoleContext from "@provider/RoleProvider";
import ListMedicalDocument from "./ListMedicalDocument";

const MedicalDocument = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "DOCUMENT";
  });

  const handleCreateDocument = () => {
    history.push(`${MEDICAL_DOCUMENT.create}`);
  };

  const typeMedicalDocument = () => {
    history.push(`${MEDICAL_DOCUMENT.type}`);
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("medicalDocument.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("medicalDocument.title")}</b>
          </span>
        </Col>

        {medicalRecord?.[0]?.isEdit ? (
          <Col md={10} lg={10} className="justify-col">
            <Button
              className="btn-search mr-05"
              type="primary"
              onClick={typeMedicalDocument}
            >
              {`${t("type")}`}
            </Button>
            <Button
              className="btn-create"
              type="primary"
              onClick={handleCreateDocument}
            >
              <PlusOutlined className="icons-size" />
              {`${t("medicalDocument.create")}`}
            </Button>
          </Col>
        ) : null}
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey table-card"
          extra={<b className="header-card ">{t("medicalDocument.title")}</b>}
        >
          <ListMedicalDocument />
        </Card>
      </div>
    </div>
  );
};

export default MedicalDocument;
