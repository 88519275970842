import { IItem } from "@interface/IOrder.interface";
import { EVENT_ROOM } from "@tools/util.enum";
import { ICheckUpdate } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { useGetProducts } from "@services/api/order";
import { useQueryClient } from "react-query";
import { checkUpdate, productName } from "@tools/util";
import { useEffect, useRef, useState } from "react";
import { Button, InputNumber, Popover } from "antd";
import SelectionTable from "../../table/TableSelection";
import SearchProductCategory from "../Search";
import BigNumber from "bignumber.js";

type Props = {
  categoriesID: string;
  selectedRowKeys: any;
  setSelectedRowKeys: (e: any) => void;
  title: string;
  status?: string;
};

const MedicalSupplies = ({
  categoriesID,
  selectedRowKeys,
  setSelectedRowKeys,
  title,
  status,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [search, setSearch] = useState<any>({});
  const client = useQueryClient();
  const statusList = ["PHARMACY", "NURSE"];
  const productsList = useGetProducts(categoriesID, {
    page: currentPage,
    limit: limitPage,
    search: search.name,
    room:
      status && statusList.includes(status?.toUpperCase())
        ? status?.toUpperCase()
        : undefined,
  });
  const isSelect = useRef<number[]>([]);

  useEffect(() => {
    setTotal(productsList?.data?.result?.[0]?.total || 1);
    isSelect.current =
      productsList?.data?.result?.[0]?.data?.flatMap((item) => item?.id) || [];
    // eslint-disable-next-line
  }, [productsList?.data]);

  const onFinish = (value: any) => {
    setSearch(value);
    setCurrentPage(1);
  };

  const onSelectChange = (selectedRowKeysTable: any, value: any) => {
    try {
      const check = checkUpdate(
        isSelect.current,
        selectedRowKeysTable
      ) as ICheckUpdate;
      const select = Array.from(
        new Set([
          ...(check?.newData || []),
          ...(check?.oldData || []),
          ...(selectedRowKeys?.[0]?.key || []),
        ])
      )?.filter((val) => {
        if (check?.removeData?.includes(val)) {
          return false;
        } else {
          return true;
        }
      });

      const dataList = selectedRowKeys?.map((valueList: any, index: number) => {
        if (index === 0) {
          return {
            key: select || [],
            dataList:
              Array.from(
                new Map(
                  [
                    ...(selectedRowKeys?.[index]?.dataList || []),
                    ...(value || []),
                  ]?.map((item) => [item?.id, item])
                )?.values()
              )?.filter((value) => select?.includes(value?.id)) || [],
            number: null,
          };
        } else return valueList;
      });

      setSelectedRowKeys(dataList);
      isSelect.current = selectedRowKeysTable;
    } catch (err) {
      console.log(err);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys?.[0]?.key,
    getCheckboxProps: (product: IItem) => ({
      disabled: product?.productBranch?.[0]?.qty < 1,
    }),
    onChange: onSelectChange,
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const editDataList = (data: any, list: any) => {
    let dataList = selectedRowKeys;
    dataList?.[0]?.dataList?.forEach((item: any, index: number) => {
      if (item?.id === list?.id) {
        dataList[0].dataList[index] = { ...list, qty: data };
      }
    });
    setSelectedRowKeys(dataList);

    const dataProductsList = productsList?.data?.result?.[0]?.data?.map(
      (item: any) => {
        if (item?.id === list?.id) {
          return { ...item, qty: data };
        }
        return item;
      }
    );

    client.setQueryData(
      [
        `get-products`,
        categoriesID,
        status === EVENT_ROOM.NURSE || status === EVENT_ROOM.PHYSIOTHERAPY
          ? {
              page: currentPage,
              limit: limitPage,
              search: search?.name,
              room: status?.toLocaleUpperCase(),
            }
          : {
              page: currentPage,
              limit: limitPage,
              search: search?.name,
            },
      ],
      {
        ...productsList?.data,
        result: [
          { ...productsList?.data?.result?.[0], data: dataProductsList },
        ],
      }
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "5%",
      render: (_: IItem, data: IItem, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("code"),
      dataIndex: "sku",
      key: "sku",
    },

    {
      title: t("examinationName"),
      dataIndex: "name",
      key: "name",
      width: "10%",
      render: (_: IItem, name: IItem) => {
        return (
          <div>{productName(i18n.language, name?.nameTh, name?.nameEn)}</div>
        );
      },
    },

    {
      title: t("course"),
      dataIndex: "isCourse",
      key: "isCourse",
      width: "7%",
      render: (isCourse: IItem) => {
        return (
          <div>
            {isCourse ? (
              <Button className="btn-isCourse" type="primary">
                {t("have")}
              </Button>
            ) : (
              <div
                className="isCourse-status"
                style={{ border: "1px solid #B7B7B7", color: "#B7B7B7" }}
              >
                {t("no")}
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: t("type"),
      dataIndex: "group",
      key: "group",
      render: (group: IItem) => {
        return (
          <div>{i18n.language === "th" ? group?.nameTh : group?.nameEn}</div>
        );
      },
    },
    {
      title: t("amount"),
      dataIndex: "qty",
      key: "qty",
      render: (qty: number, data: IItem) => {
        return (
          <div>
            <InputNumber
              min={1}
              value={qty}
              onChange={(e: any) => editDataList(e, data)}
            />
          </div>
        );
      },
    },
    status &&
      status === "promotion" && {
        title: t("thaiPrice"),
        dataIndex: "normal",
        key: "normal",
        render: (normal: string) => {
          return new BigNumber(normal || "0").toFormat(2);
        },
      },
    status &&
      status === "promotion" && {
        title: t("foreignerPrice"),
        dataIndex: "foreigner",
        key: "foreigner",
        render: (foreigner: string) => {
          return new BigNumber(foreigner || "0").toFormat(2);
        },
      },
    {
      title: t("properties"),
      dataIndex: "descriptionTh",
      key: "descriptionTh",
      render: (_: string, data: IItem) => {
        const description =
          i18n.language === "th" ? data?.propertiesTh : data?.propertiesEn;
        return (
          <Popover content={description || "-"}>
            <div className="text-content">{description}</div>
          </Popover>
        );
      },
    },

    {
      title: t("howUse"),
      dataIndex: "usageTh",
      key: "usageTh",
      render: (_: string, data: IItem) => {
        const usage = i18n.language === "th" ? data?.usageTh : data?.usageEn;
        return (
          <Popover content={usage || "-"}>
            <div className="text-content">{usage}</div>
          </Popover>
        );
      },
    },
  ];

  return (
    <div>
      <SearchProductCategory title={title} onFinish={onFinish} />
      <SelectionTable
        scroll={{ x: "calc(100vw -20%)" }}
        dataSource={
          productsList?.data?.result?.[0]?.data?.map((item) => {
            const productIndex = selectedRowKeys?.[0]?.dataList
              ?.flatMap((productId: IItem) => productId?.id)
              ?.indexOf(item?.id);
            if (productIndex > -1) {
              return selectedRowKeys?.[0]?.dataList?.[productIndex];
            } else {
              return item;
            }
          }) || []
        }
        columns={columns?.filter((item: any) => item)}
        onChangePage={onChangePage}
        rowSelection={rowSelection}
        loading={productsList?.isLoading}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        size="middle"
      />
    </div>
  );
};

export default MedicalSupplies;
