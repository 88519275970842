import { USER_MANAGE } from "@assets/data/pathRouter";
import { useUpdateUser } from "@services/api/users";
import { useGetRoleList } from "@services/api/role";
import { useTranslation } from "react-i18next";
import { useCreateMedia } from "@services/api/media_object";
import { useGetBranchList } from "@services/api/branch";
import { openNotification } from "@components/notification/Notification";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { dataUrlToFile, _isEmpty } from "@tools/util";
import { useCreateUser, useGetUserByID } from "@services/api/users";
import { Col, Form, FormInstance, Input, Row } from "antd";
import Selector from "@components/selector/Selector";
import ImageProfile from "@components/file_space/ImageProfile";

type Props = {
  formUser: FormInstance;
  userID: number;
  history: RouteComponentProps["history"];
  medicalRecord: boolean;
};

const Fields = ({ formUser, userID, history, medicalRecord }: Props) => {
  const { t, i18n } = useTranslation();
  const [branchId, setBranchId] = useState<number | null>();
  const branchList = useGetBranchList(true, { limit: 0 });
  const roleList = useGetRoleList(branchId ? true : false, {
    branchId: branchId,
    limit: 0,
  });
  const userByID = useGetUserByID(userID);
  const userUpdate = useUpdateUser();
  const userCreate = useCreateUser();
  const createMedia = useCreateMedia();

  useEffect(() => {
    formUser.setFieldsValue({
      ...userByID?.data?.result?.[0],
      password: null,
    });

    setBranchId(userByID?.data?.result?.[0]?.branchId);

    //eslint-disable-next-line
  }, [userByID?.data]);

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getFileName = () => {
    return false;
  };

  const onFinish = (value: any) => {
    if (value?.image?.includes("base64")) {
      const file = dataUrlToFile(value?.image, "userImage.png");
      if (file) {
        const formData = new FormData();
        formData?.append("file", file);

        createMedia.mutate(formData, {
          onSuccess: (res) => {
            if (!_isEmpty(userID)) {
              update({ ...value, imageId: res?.result?.[0]?.id });
            } else {
              create({ ...value, imageId: res?.result?.[0]?.id });
            }
          },
          onError: (error) => {
            openNotification({ method: "POST_ERROR", message: String(error) });
          },
        });
      }
    } else {
      if (!_isEmpty(userID)) {
        update(value);
      } else {
        create({ ...value });
      }
    }
  };

  const update = (value: any) => {
    delete value?.image;
    userUpdate.mutate(
      { id: userID, data: value },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(USER_MANAGE.title);
        },
        onError: (res) => {
          openNotification({
            method: "PUT_ERROR",
            message: String(res),
          });
        },
      }
    );
  };

  const create = (value: any) => {
    delete value?.image;
    userCreate.mutate(
      { data: value },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(USER_MANAGE.title);
        },
        onError: (res) => {
          openNotification({
            method: "POST_ERROR",
            message: String(res),
          });
        },
      }
    );
  };
  return (
    <div>
      <Form name="formUser" form={formUser} onFinish={onFinish}>
        <Row gutter={[50, 14]} align="middle">
          <Col md={8} lg={8}>
            <Form.Item name="image" getValueFromEvent={getFile}>
              <ImageProfile
                accept=".png,.jpg,.jpeg"
                form={formUser}
                name="image"
                disabled={!medicalRecord}
                beforeUpload={getFileName}
              />
            </Form.Item>
          </Col>
          <Col md={16} lg={16}>
            <div>{t("basicInfo")}</div>

            <Row gutter={[14, 14]}>
              <Col md={12} lg={12}>
                <Form.Item
                  id="firstNameTh"
                  label={`${t("name")} (TH)`}
                  name="firstNameTh"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t("name")}
                    onPressEnter={(e) => {
                      document.getElementById("lastName")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  id="lastNameTh"
                  label={`${t("lastName")} (TH)`}
                  name="lastNameTh"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t("lastName")}
                    onPressEnter={(e) => {
                      document.getElementById("nickname")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  id="firstNameEn"
                  label={`${t("name")} (EN)`}
                  name="firstNameEn"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t("name")}
                    onPressEnter={(e) => {
                      document.getElementById("lastName")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={`${t("lastName")} (EN)`}
                  id="lastNameEn"
                  name="lastNameEn"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t("lastName")}
                    onPressEnter={(e) => {
                      document.getElementById("nickname")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item label={t("nickname")} name="nickname" id="nickname">
                  <Input
                    placeholder={t("nickname")}
                    onPressEnter={(e) => {
                      document.getElementById("phone")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={t("telephoneNumber")}
                  name="phone"
                  id="phone"
                  rules={[
                    {
                      pattern: /^[+]{0}[0-9]{9,10}$/,
                      message: t("pleaseNumber"),
                    },
                  ]}
                >
                  <Input
                    placeholder={t("telephoneNumber")}
                    onPressEnter={(e) => {
                      document.getElementById("username")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={t("userName")}
                  name="username"
                  id="username"
                  rules={[{ required: true }]}
                >
                  <Input
                    placeholder={t("userName")}
                    onPressEnter={(e) => {
                      document.getElementById("email")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={t("email")}
                  name="email"
                  id="email"
                  rules={[
                    {
                      type: "email",
                    },
                  ]}
                >
                  <Input
                    type="email"
                    placeholder={t("email")}
                    onPressEnter={(e) => {
                      document.getElementById("password")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item label={t("password")} name="password" id="password">
                  <Input.Password
                    type="password"
                    placeholder={t("password")}
                    onPressEnter={(e) => {
                      document.getElementById("branchId")?.focus();
                    }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  label={t("branch")}
                  id="branchId"
                  name="branchId"
                  rules={[{ required: true }]}
                >
                  <Selector
                    Source={branchList?.data?.result?.[0]?.data || []}
                    keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                    keyValue="id"
                    onChange={(e) => {
                      formUser.setFieldsValue({
                        roleId: undefined,
                      });
                      document.getElementById("roleId")?.focus();
                    }}
                    disabled={!medicalRecord}
                    onSelect={(e) => setBranchId(e)}
                  />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.branchId !== curValues.branchId
                  }
                >
                  {(form: FormInstance) => {
                    const branchId = form.getFieldValue("branchId");
                    return (
                      <Form.Item
                        label={t("role")}
                        id="roleId"
                        name="roleId"
                        rules={[{ required: true }]}
                      >
                        <Selector
                          Source={roleList?.data?.result?.[0]?.data || []}
                          keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                          keyValue="id"
                          disabled={branchId ? !medicalRecord : true}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
              <Col md={12} lg={12}>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.roleId !== curValues.roleId
                  }
                >
                  {(form: FormInstance) => {
                    const roleId = form.getFieldValue("roleId");

                    const roleIndex =
                      roleList?.data?.result?.[0]?.data?.findIndex(
                        (item) => item?.id === roleId
                      ) as -1;

                    if (
                      roleIndex > -1 &&
                      roleList?.data?.result?.[0]?.data?.[roleIndex]?.nameEn ===
                        "DOCTOR"
                    ) {
                      return (
                        <Form.Item
                          label={t("accompanyingSheet")}
                          id="attachment"
                          name="attachment"
                        >
                          <Input placeholder={t("accompanyingSheet")} />
                        </Form.Item>
                      );
                    }

                    return null;
                  }}
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Fields;
