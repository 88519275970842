import { Card, Form } from "antd";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import React from "react";
import Fields from "./Fields";
import Header from "./Header";
import RoleContext from "@provider/RoleProvider";

const FormRoom = () => {
  const { t } = useTranslation();
  const [formRoom] = Form.useForm();
  const match: any = useRouteMatch();
  const roomID = match.params.id;
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "USER";
  });

  return (
    <div>
      <Header
        formRoom={formRoom}
        roomID={roomID}
        history={history}
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={
            <b className="fs-16 t-black-light ">
              {roomID ? `${t("editRoom")}` : `${t("createRoom")}`}
            </b>
          }
        >
          <Fields
            formRoom={formRoom}
            roomID={roomID}
            history={history}
            medicalRecord={medicalRecord?.[0]?.isEdit}
          />
        </Card>
      </div>
    </div>
  );
};

export default FormRoom;
