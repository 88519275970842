import { useState } from "react";
import TableLab from "./Table";
import SearchLab from "./Search";

const ConfirmLab = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const visitFilter = (values: any) => {
    setSearchValue(values);
    setCurrentPage(1);
  };

  return (
    <div>
      <SearchLab onFinish={visitFilter} />
      <TableLab
        searchValue={searchValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default ConfirmLab;
