import { Form } from "antd";
import { useEffect } from "react";
import { LabDetail } from "@tools/lab";
import { useCreateLab } from "@services/api/lab";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useGetVisitsByID } from "@services/api/visits";
import { ENUM_SERVICE_GROUP } from "@tools/util.enum";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetWorkOrdersOutStanding } from "@services/api/work_order";
import Fields from "./Fields";
import Header from "./Header";
import MainPatientByIdProvider from "@provider/patient_by_id";

const FormInchart = () => {
  const { mutate } = useCreateLab();
  const match: any = useRouteMatch();
  const client = useQueryClient();
  const history = useHistory<RouteComponentProps["history"]>();
  const visitID = match.params.visitID;
  const patientID = match.params.patientID;
  const visitsByID = useGetVisitsByID(visitID);
  const ordersList = useGetWorkOrdersOutStanding(patientID, {
    serviceGroup: ENUM_SERVICE_GROUP.LAB,
    lab: true,
    visitId: visitID,
  });
  const [patientList] = Form.useForm();

  useEffect(() => {
    if (!visitsByID?.data) return;

    patientList?.setFieldsValue({
      foodAllergy: visitsByID?.data?.patient?.foodAllergy || undefined,
      drugAllergy: visitsByID?.data?.patient?.drugAllergy || undefined,
    });

    //eslint-disable-next-line
  }, [visitsByID?.data]);

  const handleCreateLab = () => {
    const dataList = LabDetail(
      {
        oneDay: ordersList?.data?.one?.groups || [],
        continuous: ordersList?.data?.continuous?.groups || [],
      },
      visitsByID?.data
    );

    dataList?.length > 0 &&
      mutate(dataList, {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          client.invalidateQueries(["get-workOrders-out-standing"]);
          // Invalidate and refetch
        },
        onError: () => {
          openNotification({ method: "POST_ERROR" });
          client.invalidateQueries(["get-workOrders-out-standing"]);
        },
      });
  };

  return (
    <MainPatientByIdProvider value={{ patientById: visitsByID?.data?.patient }}>
      <>
        <Header
          history={history}
          handleCreateLab={handleCreateLab}
          ordersList={ordersList?.data}
          visitID={visitID}
          isSignature={visitsByID?.data?.isSignature?.labRoom}
        />
        <div className="set-layout ">
          <Fields
            visitsByID={visitsByID?.data}
            patientList={patientList}
            ordersList={ordersList?.data}
          />
        </div>
      </>
    </MainPatientByIdProvider>
  );
};

export default FormInchart;
