import { RouteComponentProps, useHistory } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";

const LabList = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  return (
    <div>
      <Header history={history} />
      <div className="set-layout ">
        <Fields history={history} />
      </div>
    </div>
  );
};

export default LabList;
