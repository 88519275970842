import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  confirmMedicalDocument: FormInstance;
};

const Header = ({ confirmMedicalDocument }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const loadingButton = useRecoilValue(loadingButtonState);

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("wareHouse.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("wareHouse.title")} / `}
              </span>
              <span className="t-dark-blue f-normal">
                {t("wareHouse.upload")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col lg={4} className="d-flex justify-between">
          <Button
            className="mr-1 cancel"
            htmlType="reset"
            block
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            htmlType="submit"
            onClick={() => confirmMedicalDocument.submit()}
            block
            loading={loadingButton}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
