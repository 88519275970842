import React, { useState } from "react";
import { dayjs } from "@tools/timezone";
import { Col, Row } from "antd";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";
import Scheduler from "./Scheduler";
import ListQueue from "@components/list_queue/ListQueue";

const BookInAdvance = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [viewSwitcher, setViewSwitcher] = useState<string>("Day");
  const match: any = useRouteMatch();
  const patientID = match.params.id;
  const [currentDateChange, setCurrentDateChange] = useState<string>(
    dayjs(new Date()).format("YYYY-MM-DD")
  );
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("medicalRecord.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-light-gray">{t("historyFile")} / </span>
              <span className="t-dark-blue f-normal">{t("bookAdvance")} </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout body-height ">
        <Row gutter={[14, 14]}>
          <Col lg={17}>
            <Scheduler
              viewSwitcher={viewSwitcher}
              setViewSwitcher={setViewSwitcher}
              patientID={patientID}
              currentDateChange={{
                currentDateChange: currentDateChange,
                setCurrentDateChange: setCurrentDateChange,
              }}
            />
          </Col>
          <Col lg={7}>
            <ListQueue
              display={viewSwitcher === "Month" ? " " : "Month"}
              currentDateChange={{
                currentDateChange: currentDateChange,
                setCurrentDateChange: setCurrentDateChange,
              }}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default BookInAdvance;
