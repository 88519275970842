import { IParamet } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { IPromotionList } from "@interface/IPromotion.interface";
import { statusPromotion } from "@assets/data/data";
import { useGetBranchList } from "@services/api/branch";
import { ICategory, IItem, IProduct } from "@interface/IOrder.interface";
import { Card, Col, DatePicker, Select } from "antd";
import { Empty, Form, FormInstance, Input, Row } from "antd";
import empty from "@assets/img/empty.svg";
import MoTable from "@components/table/Table";
import Selector from "@components/selector/Selector";
import BigNumber from "bignumber.js";

type Props = {
  confirm: FormInstance;
  promotionById?: IParamet<IPromotionList[]>;
};

const Fields = ({ confirm, promotionById }: Props) => {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const { RangePicker } = DatePicker;
  const branchList = useGetBranchList(true, { limit: 0 });

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: string, __: string, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: t("nameList"),
      dataIndex: "nameTh",
      key: "nameTh",
      render: (nameTh: string, data: IPromotionList) => {
        return <div>{nameTh || data?.product?.nameTh || "-"}</div>;
      },
    },

    {
      title: t("category"),
      dataIndex: "category",
      key: "category",
      render: (category: ICategory) => {
        return (
          <div>
            {i18n.language === "th"
              ? category?.nameTh || "-"
              : category?.nameEn || "-"}
          </div>
        );
      },
    },

    {
      title: t("type"),
      dataIndex: "group",
      key: "group",
      render: (_: string, data: IProduct) => {
        return <div>{data?.group?.nameTh || "-"}</div>;
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",
      key: "qty",
      render: (qty: string) => {
        return <div>{qty || "-"}</div>;
      },
    },

    {
      title: t("price"),
      dataIndex: "normal",
      key: "normal",
      render: (normal: string, data: IItem) => {
        let price = new BigNumber(normal || 0)
          .multipliedBy(data?.qty || 0)
          .toFormat(2);

        return <div>{price || "-"}</div>;
      },
    },
  ];

  return (
    <div className="set-layout ">
      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={<b className="fs-16 t-black-light ">{t("promotion.title")}</b>}
      >
        <Card
          className="card-prefix card-prefix-coupon-width card-prefix-chi"
          extra={<b className="fs-14 c-white ">{t("promotion.manage")}</b>}
        >
          <Form name="confirm" form={confirm}>
            <Row gutter={[14, 14]}>
              <Col lg={12}>
                <Form.Item name="nameTh" label={`${t("name")} (TH)`}>
                  <Input placeholder={`${t("name")} (TH)`} disabled />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item name="nameEn" label={`${t("name")} (EN)`}>
                  <Input placeholder={`${t("name")} (EN)`} disabled />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="qty" label={`${t("amount")}`}>
                  <Input placeholder={`${t("amount")}`} disabled />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  name="descriptionTh"
                  label={`${t("description")} (TH)`}
                >
                  <Input placeholder={`${t("description")} (TH)`} disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  name="descriptionEn"
                  label={`${t("description")} (EN)`}
                >
                  <Input placeholder={`${t("description")} (EN)`} disabled />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item name="status" label={`${t("status")}`}>
                  <Selector.Translation
                    Source={statusPromotion}
                    keyName={"name"}
                    keyValue="id"
                    disabled
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={`${t("branch")}`} name="branchIds">
                  <Select
                    className="user-room-update"
                    mode="multiple"
                    allowClear
                    disabled={true}
                    placeholder="Please select"
                    filterOption={(input, option: any) => {
                      return (
                        option?.children?.toLowerCase()?.includes(input) ||
                        `${input}` === `${option?.value}`
                      );
                    }}
                  >
                    {Array.from(
                      new Map(
                        [
                          ...(branchList?.data?.result?.[0]?.data || []),
                          ...(promotionById?.result?.[0]?.branchList || []),
                        ]?.map((item) => [item?.id, item])
                      )?.values()
                    )?.map((item, idx) => (
                      <Option value={item?.id} key={idx}>
                        {i18n.language === "th" ? item?.nameTh : item?.nameEn}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col span={24}>
                <Row className="header-table  ">
                  <Col className="f-normal col-start t-dark-blue">
                    <span className="f-bold mr-05 ">
                      {t("selectAProductList")}
                    </span>
                  </Col>
                </Row>
                <MoTable
                  className="components-table-demo-nested table-selection table-confirm "
                  pagination={false}
                  locale={{
                    emptyText: (
                      <>
                        <Empty
                          image={empty}
                          imageStyle={{
                            height: 60,
                          }}
                          description={
                            <span className="t-grey-white">
                              {t("noInformation")}
                            </span>
                          }
                        ></Empty>
                      </>
                    ),
                  }}
                  rowClassName={(r) => {
                    return " no-header no-cursor";
                  }}
                  expandRowByClick
                  rowKey={"id"}
                  key={"id"}
                  columns={columns}
                  dataSource={promotionById?.result?.[0]?.courses || []}
                  onDoubleClick={false}
                  scroll={{ x: "calc(100vw -20%)" }}
                />
              </Col>

              <Col lg={12}>
                <Form.Item name="price" label={`${t("initialThaiPrice")}`}>
                  <Input placeholder={`${t("price")}`} disabled />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="normal" label={`${t("thaiPrice")}`}>
                  <Input placeholder={`${t("thaiPrice")}`} disabled />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  name="totalForeigner"
                  label={`${t("initialForeignerPrice")}`}
                >
                  <Input placeholder={`${t("foreignerPrice")}`} disabled />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="foreigner" label={`${t("foreignerPrice")}`}>
                  <Input placeholder={`${t("foreignerPrice")}`} disabled />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  name="totalPartner"
                  label={`${t("initialPartnerPrice")}`}
                >
                  <Input disabled />
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item name="partner" label={`${t("partnerPrice")}`}>
                  <Input placeholder={`${t("partnerPrice")}`} disabled />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item name="start" label={t("promotionPeriod")}>
                  <RangePicker format="DD/MM/YYYY" disabled />
                </Form.Item>
              </Col>

              {/* <Col md={12} lg={12}>
                <Form.Item name="uses" valuePropName="checked" noStyle>
                  <Checkbox disabled>{t("numberOfUses")}</Checkbox>
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item name="right" valuePropName="checked" noStyle>
                  <Checkbox disabled>{t("numberOfRightsPerson")}</Checkbox>
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) => prev.uses !== next.uses}
                >
                  {(form: FormInstance) => {
                    const uses = form.getFieldValue("uses");

                    if (uses) {
                      return (
                        <Row gutter={[14, 14]}>
                          <Col span={24}>
                            <Form.Item name="qtyUse">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    }
                  }}
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) => prev.right !== next.right}
                >
                  {(form: FormInstance) => {
                    const uses = form.getFieldValue("right");

                    if (uses) {
                      return (
                        <Row gutter={[14, 14]}>
                          <Col span={24}>
                            <Form.Item name="qty">
                              <Input disabled />
                            </Form.Item>
                          </Col>
                        </Row>
                      );
                    }
                  }}
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </Card>
      </Card>
    </div>
  );
};

export default Fields;
