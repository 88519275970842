import { OPD } from "@assets/data/pathRouter";
import { dayjs } from "@tools/timezone";
import { useForm } from "antd/es/form/Form";
import { ICardId } from "./interface";
import { stepOPDState } from "@tools/recoil/recoil-atom";
// import { IPostPatientForm } from "@interface/IOpd.interface";
import { useTranslation } from "react-i18next";
import { useSetRecoilState } from "recoil";
import { useGetPatientById } from "@services/api/patient";
import { useEffect, useState } from "react";
import { useGetPrefixGroupType } from "@services/api/prefix";
import { enterNextInput, _isEmpty } from "@tools/util";
import { ENUM_ID_CARD, PATIENT_TYPE } from "@tools/util.enum";
import { useGetCardDevice, useGetCardReader } from "@services/api/card_render";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { Button, Card, Col, Form, FormInstance, Input, Row, Space } from "antd";
import moment from "moment";
import StepOPD from "@components/step/Step";
import Selector from "@components/selector/Selector";
import GeneralInfoOPD from "./GeneralInfo";
import ContactInfoOPD from "./ContactInfo";
import AboutAbsoluteHealth from "./AboutAbsoluteHealth";
import { address } from "@assets/data/address";
import type { IAddress } from "@assets/data/address.interface";
type Props = {
  pathID: number;
  basicInfoForm: FormInstance;
  history: RouteComponentProps["history"];
};
let branchAddress: IAddress[] = [...address];

const FormBasic = ({ pathID, basicInfoForm, history }: Props) => {
  const { t } = useTranslation();
  const setStepOPD = useSetRecoilState(stepOPDState);
  const match: any = useRouteMatch();
  const eventId = match.params.eventId;
  const [openAddress, setOpenAddress] = useState<boolean>(true);
  const prefixGroupType = useGetPrefixGroupType();
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const cardDevice = useGetCardDevice();
  const [cardID, setCardID] = useState<ICardId>({
    number: Math.random() * 100,
  } as ICardId);
  const cardReader = useGetCardReader(cardID);
  const [cardRender] = useForm();
  const patientIDByID = useGetPatientById(
    pathID,
    localStorage.getItem("otherInfo")
  );

  useEffect(() => {
    getDataFormList();
    setStepOPD(1);

    basicInfoForm.setFieldsValue({
      // dateOfBirth: moment(new Date(), dateFormatList[0]),
      firstVisit: moment(new Date(), dateFormatList[0]),
    });

    // eslint-disable-next-line
  }, [pathID, patientIDByID?.data]);

  useEffect(() => {
    if (!cardDevice?.data?.result?.length) {
      return;
    }
    cardRender.setFieldsValue({ selectorCard: 0 });
    // eslint-disable-next-line
  }, [cardDevice]);

  useEffect(() => {
    if (!cardReader?.data?.result) {
      return;
    }
    const data = cardReader?.data?.result;

    const address = branchAddress?.find((item) => {
      if (
        data?.address?.province.search(item?.provinceTh) > -1 &&
        data?.address?.district.search(item?.subdistrictTh) > -1 &&
        data?.address?.subdistrict.search(item?.districtTh) > -1
      ) {
        return item;
      } else {
        return null;
      }
    });

    basicInfoForm.setFieldsValue({
      titleTh: data?.nameTh?.prefix,
      firstNameTh: data?.nameTh?.firstname,
      lastNameTh: data?.nameTh?.lastname,
      titleEn: data?.nameEn?.prefix,
      firstNameEn: data?.nameEn?.firstname,
      lastNameEn: data?.nameEn?.lastname,
      gender: data?.gender === "1" ? "MALE" : data?.gender === "2" && "FEMALE",
      day: dayjs(data?.dateOfBirth, "YYYYMMDD", "th").format("DD"),
      month: dayjs(data?.dateOfBirth, "YYYYMMDD", "th").format("MM"),
      year: String(
        Number(dayjs(data?.dateOfBirth, "YYYYMMDD", "th").format("YYYY")) - 543
      ),
      idCard: ENUM_ID_CARD.CITIZEN,
      idCardNumber: data?.cid,
      addressTh: `บ้านเลขที่ ${data?.address?.houseNo} หมู่${
        data?.address?.moo
      }${!!data?.address?.soi ? ` ซอย${data?.address?.soi}` : " "}${
        !!data?.address?.street ? ` ถนน${data?.address?.street}` : " "
      }`,
      subDistrict: address,
      province: address,
      district: address,
      zipcode: address,
      country: "ไทย",
      image: `data:image/jpeg;base64,${data?.photo}`,
    });
    // eslint-disable-next-line
  }, [cardReader]);

  const getDataFormList = () => {
    if (localStorage.getItem("basicInfo") !== null) {
      const otherInfo = localStorage.getItem("basicInfo");
      const dataList = JSON.parse(otherInfo ? otherInfo : `{}`);

      if (
        dataList?.addressDoc ||
        dataList?.countryDoc ||
        dataList?.provinceDoc ||
        dataList?.districtOrcountyDoc ||
        dataList?.zipCodeDoc
      ) {
        setOpenAddress(false);
      } else {
        setOpenAddress(true);
      }

      if (dataList !== "") {
        basicInfoForm.setFieldsValue({
          ...dataList,

          // dateOfBirth: dataList?.dateOfBirth
          //   ? moment(dataList?.dateOfBirth, dateFormatList[0])
          //   : undefined,
        });
      }
    } else {
      handleGetData();
    }
  };

  const handleGetData = () => {
    if (pathID) {
      handleGetPatientById();
    } else {
      basicInfoForm.setFieldsValue({ privilege: PATIENT_TYPE.THAI });
    }
  };

  const handleGetPatientById = async () => {
    if (
      patientIDByID?.data?.mailingAddress?.addressTh ||
      patientIDByID?.data?.mailingAddress?.country ||
      patientIDByID?.data?.mailingAddress?.zipcode
    ) {
      setOpenAddress(false);
    } else {
      setOpenAddress(true);
    }
    const firstMeetHow = patientIDByID?.data?.firstMeetHow?.split(":");
    const resMailingAddress = patientIDByID?.data?.mailingAddress;
    const resHomeAddress = patientIDByID?.data?.homeAddress;
    const homeAddress = branchAddress.find(
      (item: any) =>
        item?.districtTh === resHomeAddress?.districtTh &&
        item?.provinceTh === resHomeAddress?.provinceTh &&
        item?.subdistrictTh === resHomeAddress?.subDistrictTh
    );
    const mailAddress = branchAddress.find(
      (item: any) =>
        item?.districtTh === resMailingAddress?.districtTh &&
        item?.provinceTh === resMailingAddress?.provinceTh &&
        item?.subdistrictTh === resMailingAddress?.subDistrictTh
    );
    const initData = {
      ...patientIDByID?.data,
      addressTh: resHomeAddress?.addressTh,
      addressEn: resHomeAddress?.addressEn,
      district: homeAddress,
      subDistrict: homeAddress,
      province: homeAddress,
      zipcode: homeAddress,
      country: patientIDByID?.data?.homeAddress.country,

      firstMeetHow: firstMeetHow?.[0] || undefined,
      firstMeetHow1: firstMeetHow?.[1] || undefined,
      addressDocTh: resMailingAddress?.addressTh,
      addressDocEn: resMailingAddress?.addressEn,
      districtDoc: mailAddress,
      subDistrictDoc: mailAddress,
      provinceDoc: mailAddress,
      zipcodeDoc: mailAddress,
      countryDoc: patientIDByID?.data?.mailingAddress.country,
      firstVisit: patientIDByID?.data?.firstVisit
        ? moment(patientIDByID?.data?.firstVisit)
        : undefined,
      day: patientIDByID?.data?.dateOfBirth
        ? moment(patientIDByID?.data?.dateOfBirth).format("DD")
        : "",
      month: patientIDByID?.data?.dateOfBirth
        ? moment(patientIDByID?.data?.dateOfBirth).format("MM")
        : "",
      year: patientIDByID?.data?.dateOfBirth
        ? moment(patientIDByID?.data?.dateOfBirth).format("YYYY")
        : "",
      privilege:
        patientIDByID?.data?.privilege === "0"
          ? "THAI"
          : patientIDByID?.data?.privilege,
    };
    basicInfoForm.setFieldsValue(initData);
  };

  const onFinish = (values: any) => {
    if (!_isEmpty(pathID)) {
      history.push(
        `${OPD.otherInfo}/${pathID}${!!eventId ? `/event/${eventId}` : ``}`
      );
    } else {
      history.push(`${OPD.otherInfo}${!!eventId ? `/event/${eventId}` : ``}`);
    }
    const data = {
      ...values,
      homeAddress: {
        addressTh: values?.addressTh,
        addressEn: values?.addressEn,
        country: values.country,
        districtTh: values?.district?.districtTh,
        districtEn: values?.district?.districtEn,
        subDistrictTh: values?.subDistrict?.subdistrictTh,
        subDistrictEn: values?.subDistrict?.subdistrictEn,
        provinceTh: values?.province?.provinceTh,
        provinceEn: values?.province?.provinceEn,
        zipcode: values?.zipcode?.zipcode,
      },
      mailingAddress: {
        addressTh: values?.addressDocTh,
        addressEn: values?.addressDocEn,
        country: values.countryDoc,
        districtTh: values?.districtDoc?.districtTh,
        districtEn: values?.districtDoc?.districtEn,
        subDistrictTh: values?.subDistrictDoc?.subdistrictTh,
        subDistrictEn: values?.subDistrictDoc?.subdistrictEn,
        provinceTh: values?.provinceDoc?.provinceTh,
        provinceEn: values?.provinceDoc?.provinceEn,
        zipcode: values?.zipcodeDoc?.zipcode,
      },
    };
    localStorage.setItem("basicInfo", JSON.stringify(data));
  };

  const cardReadFunction = (value: ICardId) => {
    basicInfoForm.setFieldsValue({
      titleTh: undefined,
      firstNameTh: undefined,
      lastNameTh: undefined,
      titleEn: undefined,
      firstNameEn: undefined,
      lastNameEn: undefined,
      gender: undefined,
      day: undefined,
      month: undefined,
      year: undefined,
      idCard: undefined,
      idCardNumber: undefined,
      address: undefined,
      subdistrict: undefined,
      district: undefined,
      province: undefined,
      country: undefined,
      zipcode: undefined,
      image: undefined,
    });
    setCardID(value);
    cardRender.setFieldsValue({ number: Math.random() * 100 });
  };

  return (
    <div className="set-layout ">
      <Card className="bg-grey m-2 from-card" extra={<StepOPD />}>
        <Form form={cardRender} onFinish={cardReadFunction}>
          <Row align="middle">
            <Col>
              <span className="strong h1 header-top ">
                {!_isEmpty(pathID) ? t("editKeeper") : t("createKeeper")}
              </span>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <Space>
                <Form.Item noStyle name="selectorCard">
                  <Selector
                    placeholder=""
                    Source={cardDevice?.data?.result || []}
                    keyName="name"
                    keyValue="key"
                    style={{ width: 200 }}
                  />
                </Form.Item>

                <Form.Item noStyle hidden name="number">
                  <Input />
                </Form.Item>
                <Button className=" btn-search" htmlType="submit" block>
                  {t("readCard")}
                </Button>
              </Space>
            </Col>
          </Row>
        </Form>

        <div className="title-top pt-05">{t("leadingSymptoms")}</div>
        <Form
          id="basicInfoForm"
          onFinish={onFinish}
          form={basicInfoForm}
          scrollToFirstError={true}
          onKeyPress={(e) => {
            enterNextInput(e, "basicInfoForm");
          }}
        >
          <GeneralInfoOPD
            basicInfoForm={basicInfoForm}
            prefixList={prefixGroupType?.data?.result?.[0]}
          />

          <ContactInfoOPD
            openAddress={openAddress}
            setOpenAddress={setOpenAddress}
            prefixList={prefixGroupType?.data?.result?.[0]}
            basicInfoForm={basicInfoForm}
          />
          <AboutAbsoluteHealth />
        </Form>
      </Card>
    </div>
  );
};

export default FormBasic;
