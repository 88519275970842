export const LabResultsIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            d="M0 0H65.286V84.289H0z"
            data-name="Rectangle 22693"
          ></path>
        </clipPath>
        <clipPath id="clip-Lab_results">
          <path d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-Lab_results)" data-name="Lab results">
        <g data-name="Group 27081" transform="translate(17 7.999)">
          <g clipPath="url(#clip-path)" data-name="Group 27081">
            <path
              fill="#fff"
              d="M32.457 0c7.212 6.67 13.766 13.339 19.26 20.9a143.623 143.623 0 0110.444 16.533c9.1 17-2.225 45.192-26.735 46.776C17.9 85.342 4.028 74.369.591 56.907c-1.835-9.325.768-17.448 5.464-25.233A136.162 136.162 0 0126.636 6.046C28.555 4.121 30.409 2.131 32.457 0m5.836 53.1c1.919 0 3.646.12 5.351-.027a5.252 5.252 0 004.99-5.239 5.459 5.459 0 00-4.812-5.448 14.987 14.987 0 00-1.935-.12c-3.79-.056-3.777-.052-3.833-3.738a13.083 13.083 0 00-.261-2.346 5.057 5.057 0 00-5.524-4.339 5.362 5.362 0 00-5.007 5.023 24.47 24.47 0 00-.027 3.448c.1 1.453-.349 2.124-1.931 1.981a19.5 19.5 0 00-3.876.074A5.3 5.3 0 0016.64 47.6a5.224 5.224 0 004.76 5.453 17.766 17.766 0 003.659.052c1.727-.156 2.311.52 2.161 2.214a19.179 19.179 0 00.1 3.875 5.354 5.354 0 004.972 4.587 5.131 5.131 0 005.425-3.972c.388-2.089.382-4.25.577-6.712"
              data-name="Path 22269"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
