import { atom } from "recoil";
import { dayjs } from "@tools/timezone";

export interface ITabsState {
  lab: string;
  userManage: string;
  preRegister: string;
  labInterface: string;
}

export const stepOPDState = atom<number>({
  key: "step",
  default: 1,
});

export const keyMenuHistoryNursingState = atom<number>({
  key: "keyMenu",
  default: 1,
});

export const loadingButtonState = atom({
  key: "loadingButton",
  default: false,
});

export const tabsReturnCourseState = atom<string>({
  key: "returnCourse",
  default: "1",
});

export const loadingModalState = atom<boolean>({
  key: "loadingModal",
  default: false,
});

export const drawingState = atom<string>({
  key: "drawing",
  default: "",
});

export const tabsGeneralState = atom<string>({
  key: "tabsGeneral",
  default: "1",
});

export const tabsWarehouseState = atom<string>({
  key: "tabsWarehouse",
  default: "1",
});

export const tabsPayState = atom<string>({
  key: "tabsPay",
  default: "1",
});

export const tabsInchartState = atom<string>({
  key: "tabsInchart",
  default: "1",
});

export const compareLabState = atom<{
  startDate: string;
  endDate: string;
  name: string | undefined;
}>({
  key: "compareLab",
  default: {
    startDate: dayjs().format("YYYY-MM-DD"),
    endDate: dayjs().add(9, "day").format("YYYY-MM-DD"),
    name: undefined,
  },
});

export const tabsState = atom<ITabsState>({
  key: "tabsState",
  default: {
    lab: "1",
    userManage: "1",
    preRegister: "1",
    labInterface: "1",
  },
});

export const branchIDState = atom<number | null | any>({
  key: "branchID",
  default: null,
});

export const collapsedState = atom<boolean>({
  key: "collapsed",
  default: window.innerWidth < 1200 ? true : false,
});

export const loadingBranchState = atom<boolean>({
  key: "loadingBranch",
  default: false,
});

export const tabsDoctorOrderState = atom<string>({
  key: "tabsDoctorOrder",
  default: "1",
});
