import { ISearch } from "../inteface";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";
import { useGetCategories } from "@services/api/order";
import { ENUM_ORDERBY } from "@tools/util.enum";

type Props = {
  onFinish: (value: ISearch) => void;
};

const Search = ({ onFinish }: Props) => {
  const { t, i18n } = useTranslation();
  const categoriesList = useGetCategories({
    orderby: ENUM_ORDERBY.ASC,
    limit: 0,
  });

  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={5} lg={5}>
            <Form.Item name="search">
              <Input placeholder={`${t("searchProductName")}`} />
            </Form.Item>
          </Col>

          <Col sm={5} lg={5}>
            <Form.Item name="categoryId">
              <SelectorDouble
                Source={categoriesList?.data?.result?.[0]?.data || []}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("type")}
              />
            </Form.Item>
          </Col>

          <Col md={8} lg={4}>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
