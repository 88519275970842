import { Form } from "antd";
import { useRouteMatch } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";

const FormNotification = () => {
  const [create] = Form.useForm();
  const match: any = useRouteMatch();
  const notificationID = match.params.id;
  return (
    <div>
      <Header create={create} notificationID={notificationID} />
      <div className="set-layout">
        <Fields create={create} notificationID={notificationID} />
      </div>
    </div>
  );
};

export default FormNotification;
