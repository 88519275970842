import { FC } from "react";
import { ISearchSlots } from "./interface";
import { useTranslation } from "react-i18next";
import { useGetUserRoomList } from "@services/api/user_room";
import { Button, Col, Form, Row } from "antd";
import Selector from "@components/selector/Selector";

type Props = {
  onFinish: (value: ISearchSlots) => void;
};

const Search: FC<Props> = ({ onFinish }) => {
  const doctorList = useGetUserRoomList({ limit: 0 });
  const { t, i18n } = useTranslation();
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={6} lg={10}>
            <Form.Item name="userId">
              <Selector
                placeholder={t("doctorName")}
                Source={Array.from(
                  new Map(
                    doctorList?.data?.result?.[0]?.data?.map((item) => [
                      item?.userId,
                      item,
                    ])
                  ).values()
                )}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="userId"
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
