import { Table } from "antd";
import { ORDER_TYPE } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { IChild, IItem, ISelected } from "@interface/IOrder.interface";
import Decimal from "@tools/decimal";

type Props = {
  orderList: ISelectedExecuteOrder[];
  selection: ISelected;
};

const OrderList = ({ orderList, selection }: Props) => {
  const { t } = useTranslation();
  const onSelectChange = (selected: any) => {
    const result = selected?.filter(
      (item: string) => !item?.includes("children")
    );

    selection.setSelection({
      ...selection.selection,
      course: result,
    });
  };

  const rowSelection = {
    selectedRowKeys: selection.selection.course,
    onChange: onSelectChange,
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: string, data: IItem & IChild, index: number) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? index + 1
              : data?.level === 2
              ? data?.name
              : data?.level === 3
              ? data?.product?.nameTh
              : data?.level === 4
              ? ""
              : ""}
          </div>
        );
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: string, data: IItem & IChild) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? data?.group
              : data?.level === 2
              ? data?.times
              : data?.level === 3
              ? data?.qtyOutstanding
                ? data?.qtyOutstanding
                : data?.qty + "/" + data?.qty
              : data?.level === 4
              ? t("nameList")
              : data?.product?.nameTh}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "qty",
      key: "qty",

      render: (_: string, data: IItem & IChild) => {
        return (
          <>
            {data?.level === 1 ? (
              data?.children?.[0]?.children?.length
            ) : data?.level === 2 ? (
              data?.quantity
            ) : data?.level === 3 && data?.children ? (
              <div>{data?.qty || ""}</div>
            ) : data?.level === 3 && !data?.children ? (
              <div>{data?.qty || ""}</div>
            ) : data?.level === 4 ? (
              t("amount")
            ) : (
              <div>{data?.qtyOutstanding || ""}</div>
            )}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",

      render: (_: string, data: IItem & IChild) => {
        return (
          <>
            {data?.level === 1
              ? ``
              : data?.level === 2
              ? t("type")
              : data?.level === 3
              ? data?.orderType === ORDER_TYPE.ONE_DAY
                ? `One Day`
                : data?.orderType === ORDER_TYPE.CONTINUE
                ? `Continuous`
                : `-`
              : data?.level === 4
              ? t("type")
              : "-"}
          </>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "total",
      key: "total",
      render: (item: string, data: IItem & IChild) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? Decimal(item)
              : data?.level === 2
              ? data?.price
              : data?.level === 3
              ? Decimal(item)
              : data?.level === 4
              ? t("price")
              : Decimal(item)}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        className="components-table-demo-nested table-selection table-confirm thead-table-row3 "
        rowClassName={(r) => {
          if (r?.level === 1) {
            return "";
          } else if (r?.level === 2) {
            return "table-header  ";
          } else if (r?.level === 3) {
            return "no-header ";
          } else if (r?.level === 5) {
            return "table-confirm-first no-header redio-rowClass";
          } else if (r?.level === 4) {
            return "redio-rowClass";
          }
          return "";
        }}
        rowSelection={{ ...rowSelection, checkStrictly: false }}
        expandedRowKeys={selection.selection.expandAllRows}
        onExpandedRowsChange={(e) =>
          selection.setSelection({
            ...selection.selection,
            expandAllRows: e,
          })
        }
        columns={columns}
        expandRowByClick
        showSorterTooltip
        rowKey={"key"}
        dataSource={orderList}
        pagination={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default OrderList;
