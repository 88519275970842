import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import {
  ISaleOrderById,
  ISaleOrderList,
} from "@interface/IWarehouse.interface";
import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetWarehouseSaleOrder = (
  params?: any
): UseQueryResult<Elementary<ISaleOrderList[]>> => {
  return useQuery(["get-warehouse-sale-order", params], async () => {
    const res = await axios.get(`/warehouse/sale-order`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetWarehouseSaleOrderById = (
  id?: number
): UseQueryResult<ElementaryOneMore<ISaleOrderById>> => {
  return useQuery(
    ["get-warehouse-sale-order-by-id", id],
    async () => {
      const res = await axios.get(`/warehouse/sale-order/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !!id }
  );
};

export const useGetGoodsReceiptBySONumber = (
  id?: string
): UseQueryResult<ElementaryOneMore<any>> => {
  return useQuery(
    ["get-goods-receipt-by-so-number", id],
    async () => {
      const res = await axios.get(`/goods-receipt/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !!id }
  );
};
