import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { DatePicker, Row } from "antd";
import { useMainMedicineRoom } from "@provider/medicine_room";
import { Button, Card, Col, Divider, Form, Input, Modal } from "antd";
import View from "./print/View";
import Print from "./print/Print";
import TextArea from "antd/lib/input/TextArea";
import CCheckbox from "@components/checkbox/Checkbox";

interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}

type Props = {
  manageInfo: any;
  visible: IVisible;
  updateWorkOrderItem?: (e: any, type: string) => void;
};

const ModalInfo = ({ manageInfo, visible, updateWorkOrderItem }: Props) => {
  const { t, i18n } = useTranslation();
  const { visitsById, handleUpdateWorkOrderItem, key } = useMainMedicineRoom();
  const monthFormat = "MM-YYYY";
  const dateFormat = "DD-MM-YYYY";
  // const pageStyle = `@page {size:6.5cm  297mm ;  margin: 0px 0px 0px 0px ;}`;
  const pageStyle = `@page {size: 6.5cm 4.5cm; margin: 7px 0px 0px 0px; }`;
  const printReportRef = useRef(null);

  const onCancel = () => {
    visible.setVisible(false);
  };

  const onFinish = (data: any) => {
    const mapData = {
      nameShown: data.nameShown,
      genericName: data.genericName,
      qty: data.qty,
      frequencyTh: data.frequencyTh,
      frequencyEn: data.frequencyEn,
      quantityUsed: data.quantityUsed,
      dateStart: data.dateStart,
      dateStop: data.dateStop,
      drugExpiration: data.drugExpiration,
      eatingPattern: data.eatingPattern,
      timeSpent: data.timeSpent,
      categoryId: data.categoryId,
      specialInstructions: data.specialInstructions,
      purpose: data.purpose,
      descriptionTh: data.descriptionTh,
      descriptionEn: data.descriptionEn,
    };
    updateWorkOrderItem
      ? updateWorkOrderItem(mapData, key)
      : handleUpdateWorkOrderItem(mapData, key);
    visible.setVisible(false);
  };

  const handlePrintToSave = () => {
    manageInfo.submit();

    handlePrint();
  };

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    localStorage.setItem("local-lang", lang);
  };

  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col lg={20} className="fs-18">
              <b className="f-normal">{t("drugLabel")}</b>
            </Col>
            <Col lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={visible.visible}
      onOk={() => visible.setVisible(false)}
      onCancel={onCancel}
      width="70%"
      footer={null}
    >
      <Form
        onFinish={onFinish}
        name="manageInfo"
        form={manageInfo}
        initialValues={{}}
      >
        <Form.Item name="categoryId" hidden={true}>
          <Input disabled />
        </Form.Item>
        <Row>
          <Col span={12}>
            <div className="scroll  scrollbar-list ">
              <Row justify="space-between" className="pl-2 pr-2 pt-1">
                <Col className="f-normal">{t("drugLabelInformation")}</Col>
                <Col className="col-end">
                  {" "}
                  <div className=" language-header ">
                    <span
                      className={
                        i18n.language === "en"
                          ? "pr-05 f-bold hover "
                          : "pr-05 hover "
                      }
                      onClick={() => changeLanguage("en")}
                    >
                      EN
                    </span>

                    <span
                      className={
                        i18n.language === "th"
                          ? "pr-05 f-bold hover "
                          : "pr-05 hover "
                      }
                      onClick={() => changeLanguage("th")}
                    >
                      TH
                    </span>
                  </div>
                </Col>
              </Row>

              <Row gutter={[14, 14]} className="margin-form p-2">
                <Col span={24}>
                  <Form.Item label={t("productNameShown")} name="nameShown">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("genericName")} name="genericName">
                    <Input />
                  </Form.Item>
                </Col>
                {!!key ? (
                  <Col span={24}>
                    <Form.Item
                      label={t("amount")}
                      name="qty"
                      rules={[
                        {
                          required: true,
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            const qty = getFieldValue("qtyOutstanding");
                            if (qty >= Number(value) && Number(value) > 0) {
                              return Promise.resolve();
                            } else if (!value) {
                              return Promise.reject();
                            } else
                              return Promise.reject(
                                new Error(
                                  `${t(
                                    "theNumberOfProductsIsNotLessThan"
                                  )} 0 ${t("andNotMore")} ${qty} `
                                )
                              );
                          },
                        }),
                      ]}
                    >
                      <Input type="number" />
                    </Form.Item>
                  </Col>
                ) : null}

                <Col span={24}>
                  <Form.Item label={t("Frequency (TH)")} name="frequencyTh">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item label={t("Frequency (EN)")} name="frequencyEn">
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) => prev.unitAlt === next.unitAlt}
                  >
                    {(form) => {
                      const unitAlt =
                        i18n.language === "th"
                          ? form.getFieldValue("unitAltTh")
                          : form.getFieldValue("unitAltEn");
                      return (
                        <Form.Item
                          label={t("quantityUsed")}
                          name="quantityUsed"
                        >
                          <Input suffix={unitAlt || ""} />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("startMedication")} name="dateStart">
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("stopMedicine")} name="dateStop">
                    <DatePicker format={dateFormat} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("medicineExp")} name="drugExpiration">
                    <DatePicker format={monthFormat} picker="month" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("eatingPattern")} name="eatingPattern">
                    <CCheckbox.EatingPattern />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("timeSpent")} name="timeSpent">
                    <CCheckbox.Period />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t("specialMethod")}
                    name="specialInstructions"
                    rules={[{ max: 45, message: "" }]}
                  >
                    <TextArea
                      showCount
                      maxLength={45}
                      style={{ resize: "none" }}
                      autoSize={{ minRows: 2, maxRows: 3 }}
                      id="remark"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("objective")} name="purpose">
                    <TextArea
                      autoSize={{ minRows: 2, maxRows: 3 }}
                      id="remark"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t("propertiesTh")}
                    name="descriptionTh"
                    rules={[{ max: 45, message: "" }]}
                  >
                    <TextArea
                      showCount
                      maxLength={45}
                      style={{ resize: "none" }}
                      autoSize={{ minRows: 2, maxRows: 3 }}
                      id="remark"
                    />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    label={t("propertiesEn")}
                    name="descriptionEn"
                    rules={[{ max: 45, message: "" }]}
                  >
                    <TextArea
                      showCount
                      maxLength={45}
                      style={{ resize: "none" }}
                      autoSize={{ minRows: 2, maxRows: 3 }}
                      id="remark"
                    />
                  </Form.Item>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={12} className="label-example">
            <div className="scroll  ">
              <div className=" pl-1 pr-1 pt-1 f-normal">
                {t("drugLabelExample")}
                <div className="pt-6">
                  <Card className="drug-label-card t-black">
                    <View visitsByID={visitsById} />
                    <div style={{ display: "none" }}>
                      <ReactToPrint content={() => printReportRef.current} />
                      <Print refData={printReportRef} visitsByID={visitsById} />
                    </div>
                  </Card>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Form>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>

            <Col>
              <Button
                className="reserve "
                key="back"
                onClick={() => {
                  handlePrintToSave();
                }}
              >
                {t("print")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => manageInfo.submit()}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInfo;
