import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";
import FloatLabel from "./FloatLabel";

type Props = {
  disabled?: boolean;
  options?: any;
  label: string;
  name: string | any[];
  rules?: { required: boolean; message?: string };
  type?: "number" | "";
  fieldKey?: any;
};
const FInput = ({
  disabled = false,
  options,
  label = "",
  name,
  rules = { required: false, message: "" },
  type,
  fieldKey,
}: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Form.Item
        className="formlabel"
        name={name}
        fieldKey={fieldKey}
        rules={[
          {
            required: rules && rules.required,
            message:
              rules && rules.message
                ? rules.message
                : `${t("pleaseInput")} ${label}!`,
          },
        ]}
      >
        <ChildrenRender
          {...options}
          label={label}
          type={type}
          name={name}
          disabled={disabled}
        />
      </Form.Item>
    </>
  );
};
const ChildrenRender = (propsChild: any) => (
  <>
    <FloatLabel
      label={propsChild.label ? propsChild.label : ""}
      name={propsChild.restField ? propsChild.id : propsChild.name}
      value={propsChild.value}
    >
      <Input
        {...propsChild}
        type={propsChild.type}
        disabled={propsChild?.disabled}
      />
    </FloatLabel>
  </>
);

export default FInput;
