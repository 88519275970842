import { useTranslation } from "react-i18next";
import { PRE_REGISTRATION } from "@assets/data/pathRouter";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
  preRegister: FormInstance;
  patientID: number;
  locationPath: string;
  sendPatient: () => void;
  eventID: number;
};

const Header = ({
  history,
  preRegister,
  patientID,
  locationPath,
  sendPatient,
  eventID,
}: Props) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("preRegister.title")}</h1>
              </div>
              <span className="t-light-gray">{`${t("event.title")} / ${t(
                "nursery"
              )} / `}</span>
              <span className="t-dark-blue f-normal">
                {locationPath.includes("create-draft") ||
                locationPath.includes("old-customer")
                  ? t("preRegister.add")
                  : patientID
                  ? t("information")
                  : t("preRegister.register")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col lg={10} className="d-flex justify-between justify-col">
          {patientID ? (
            <>
              <Button
                className="reserve mr-05 "
                key="back"
                onClick={() => {
                  history.push(
                    `${PRE_REGISTRATION.event}/${eventID}/report/history/patient/${patientID}`
                  );
                }}
              >
                {t("print")}
              </Button>
            </>
          ) : null}
        </Col>
      </Row>
    </>
  );
};

export default Header;
