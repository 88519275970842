import React from "react";
import { render } from "@testing-library/react";
import { _isEmpty } from "@yim/util-tool-ts";
import { Resources } from "@devexpress/dx-react-scheduler-material-ui";
import { Scheduler } from "@devexpress/dx-react-scheduler-material-ui";
import { AllDayPanel } from "@devexpress/dx-react-scheduler-material-ui";
import { slotsDetail } from "@assets/data/pathRouter";
import { IDateChange } from "@interface/ISlots.interface";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { useTranslation } from "react-i18next";
import { useGetRoomList } from "@services/api/rooms";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import { AppointmentModel } from "@interface/IAppointments.interface";
import { Toolbar, DayView } from "@devexpress/dx-react-scheduler-material-ui";
import { useGetUserDoctor } from "@services/api/users";
import { useGetUserRoomList } from "@services/api/user_room";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import { EditRecurrenceMenu } from "@devexpress/dx-react-scheduler-material-ui";
import { RouteComponentProps } from "react-router-dom";
import { CurrentTimeIndicator } from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, FormInstance, Tooltip } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";
import Paper from "@material-ui/core/Paper";
import Group from "@assets/img/icon/group.svg";
import FormSlots from "../form_doctor/Form";
import SearchQueue from "./SearchQueue";
import RoleContext from "@provider/RoleProvider";
import GetAppointmentTooltip from "./useGetAppointmentTooltip";

type Props = {
  viewSwitcher: string;
  dataScheduler: AppointmentModel[] & any;
  handleCreateSlots: (added: any) => void;
  handleUpdateSlots: (changed: any) => void;
  handleDeleteSlots: (id: any) => void;
  searchOnFinish: (values: any) => void;
  formSearch: FormInstance;
  currentDateChange: IDateChange;
};

const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 0 } },
});

const Fields = ({
  viewSwitcher,
  dataScheduler,
  handleCreateSlots,
  handleUpdateSlots,
  handleDeleteSlots,
  searchOnFinish,
  formSearch,
  currentDateChange,
}: Props) => {
  const { t, i18n } = useTranslation();
  const appointmentData: any = {};
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const visitsID = match.params.visitsID;
  const eventId = match.params.eventId;
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "QUE_MEDICAL_RECORD";
  });
  const doctorList = useGetUserRoomList({ limit: 0 });
  const roomList = useGetRoomList({ limit: 0, doctor: true });
  const userList = useGetUserDoctor();

  const resources = [
    {
      fieldName: "roomId",
      title: "id",
      instances: roomList?.data?.result?.[0]?.data || [],
    },
  ];

  const changeCurrentDateChange = (currentDate: any) => {
    currentDateChange?.setCurrentDateChange(currentDate);
  };

  const CommitChanges = async ({ added, changed, deleted }: any) => {
    if (added) {
      handleCreateSlots(added);
    }
    if (changed) {
      handleUpdateSlots(changed);
    }
    if (deleted !== undefined) {
      handleDeleteSlots(deleted);
    }
  };

  const allDayLocalizationMessages: any = {
    "th-TH": {
      allDay: t("all"),
    },
  };

  const getAllDayMessages = (locale: any) =>
    allDayLocalizationMessages?.[locale];

  const AppointmentContent = ({
    classes,
    data,
    formatDate,
    ...restProps
  }: any) => {
    return (
      <Appointments.AppointmentContent
        {...restProps}
        formatDate={formatDate}
        data={data}
      >
        <div className="f-light">
          <div>
            {i18n.language === "th" ? data?.userTh || "" : data?.userEn || ""}
          </div>
          <div>{data?.room?.name}</div>
          <div>{data?.info}</div>
        </div>
      </Appointments.AppointmentContent>
    );
  };

  const onFinish = (id: number | string) => {
    _isEmpty(patientID) && _isEmpty(visitsID)
      ? history.push(slotsDetail + `/slot/${id}`)
      : history.push(
          slotsDetail +
            `/slot/${id}/patient/${patientID}/visit/${visitsID}${
              !!eventId ? `/event/${eventId}` : ``
            }`
        );
  };

  const header = ({
    children,
    appointmentData,
    classes,
    ...restProps
  }: any) => {
    const dateNew: Date = new Date();
    if (dateNew.getTime() < new Date(appointmentData?.endDate).getTime()) {
      return (
        <>
          {medicalRecord ? (
            <AppointmentTooltip.Header
              {...restProps}
              showOpenButton
              showDeleteButton
            >
              <Tooltip title="">
                <Button
                  className="icon-detail"
                  type="primary"
                  shape="circle"
                  icon={
                    <img
                      className="slots-group"
                      width="20"
                      src={Group}
                      alt="group"
                    />
                  }
                  onClick={(e: any) => onFinish(appointmentData?.id)}
                />
              </Tooltip>
            </AppointmentTooltip.Header>
          ) : (
            <AppointmentTooltip.Header {...restProps}>
              <Tooltip title="">
                <Button
                  className="icon-detail"
                  type="primary"
                  shape="circle"
                  icon={
                    <img
                      className="slots-group"
                      width="20"
                      src={Group}
                      alt="group"
                    />
                  }
                  onClick={(e: any) => onFinish(appointmentData?.id)}
                />
              </Tooltip>
            </AppointmentTooltip.Header>
          )}
        </>
      );
    } else {
      return (
        <AppointmentTooltip.Header {...restProps}>
          <Tooltip title="">
            <Button
              className="icon-detail"
              type="primary"
              shape="circle"
              icon={
                <img
                  className="slots-group"
                  width="20"
                  src={Group}
                  alt="group"
                />
              }
              onClick={(e: any) => onFinish(appointmentData?.id)}
            />
          </Tooltip>
        </AppointmentTooltip.Header>
      );
    }
  };

  const getAppointments = () => {
    const dataList: any = GetAppointmentTooltip({
      date: currentDateChange?.currentDateChange,
      viewSwitcher: viewSwitcher,
      slotType: "DOCTOR",
    });

    return dataList || [];
  };

  const layerBody = ({ ...restProps }: any) => {
    const result = getAppointments()?.filter(
      (word: any) => word?.slotId === restProps?.appointmentData?.id
    );
    return (
      <AppointmentTooltip.Content {...restProps}>
        {result?.length > 0 ? (
          result?.map((item: any, index: number) => {
            return (
              <div key={index} className="appointmentTooltip">
                <span className="f-bold fs-16">{t("name")} :</span>
                <span className="f-light fs-16">{` ${item?.patient?.firstNameTh} `}</span>
                <span className="f-bold fs-16">{t("lastName")} :</span>
                <span className="f-light fs-16">{` ${item?.patient?.lastNameTh} `}</span>
                <span className="f-bold fs-16">{t("phoneNumber")} :</span>
                <span className="f-light fs-16">{` ${item?.patient?.mobile} `}</span>
              </div>
            );
          })
        ) : (
          <div className=" appointmentTooltip f-light fs-16">
            {t("theCaregiversWereNotFound")}
          </div>
        )}
      </AppointmentTooltip.Content>
    );
  };

  const booleanEditorComponent = ({ restProps }: any) => {
    return (
      <AppointmentForm.BooleanEditor
        {...restProps}
        value={true}
      ></AppointmentForm.BooleanEditor>
    );
  };

  const currentTimeIndicator = {
    shadePreviousCells: true,
    shadePreviousAppointments: true,
    updateInterval: 10000,
  };

  const FlexibleSpace = ({ restProps }: any) => {
    return (
      <Toolbar.FlexibleSpace {...restProps}>
        <SearchQueue
          searchOnFinish={searchOnFinish}
          formSearch={formSearch}
          roomList={roomList?.data?.result?.[0]?.data || []}
          doctorList={userList?.data?.result || []}
        />
      </Toolbar.FlexibleSpace>
    );
  };

  const formModal = (dataSlots: any) => {
    const dateNew = new Date();
    if (dateNew.getTime() < new Date(dataSlots?.endDate).getTime()) {
      viewSwitcher === "Month"
        ? render(
            <QueryClientProvider client={queryClient}>
              <FormSlots
                dataSlots={dataSlots}
                CommitChanges={CommitChanges}
                doctorList={doctorList?.data?.result?.[0]?.data || []}
              />
            </QueryClientProvider>
          )
        : render(
            <QueryClientProvider client={queryClient}>
              <FormSlots
                dataSlots={dataSlots}
                CommitChanges={CommitChanges}
                doctorList={doctorList?.data?.result?.[0]?.data || []}
              />
            </QueryClientProvider>
          );
    }
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "20px" }}>
      <Paper>
        <Scheduler
          locale={i18n.language === "th" ? "th-TH" : "en-US"}
          data={dataScheduler}
        >
          <ViewState
            currentDate={currentDateChange?.currentDateChange}
            currentViewName={viewSwitcher}
            onCurrentDateChange={changeCurrentDateChange}
          />
          <EditingState onCommitChanges={CommitChanges} />

          <DayView startDayHour={8} cellDuration={15} endDayHour={23} />

          <AllDayPanel messages={getAllDayMessages("th-TH")} />
          <EditRecurrenceMenu />

          <Appointments appointmentContentComponent={AppointmentContent} />
          <Resources data={resources} />
          <AppointmentTooltip
            headerComponent={header}
            contentComponent={layerBody}
          />
          {medicalRecord?.[0]?.isEdit ? (
            <AppointmentForm
              booleanEditorComponent={booleanEditorComponent}
              appointmentData={appointmentData}
              visible={false}
              onAppointmentDataChange={(e) => formModal(e)}
            />
          ) : null}

          <Toolbar flexibleSpaceComponent={FlexibleSpace} />

          <CurrentTimeIndicator
            shadePreviousCells={currentTimeIndicator?.shadePreviousCells}
            shadePreviousAppointments={
              currentTimeIndicator?.shadePreviousAppointments
            }
            updateInterval={currentTimeIndicator?.updateInterval}
          />
        </Scheduler>
      </Paper>
    </div>
  );
};

export default Fields;
