import { ISearchPatientContext } from "./interface";
import { createContext, useContext } from "react";

const MainSearchPatientContext = createContext<ISearchPatientContext>(
  {} as ISearchPatientContext
);

const MainSearchPatientProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: any;
}) => {
  return (
    <MainSearchPatientContext.Provider value={{ ...value }}>
      {children}
    </MainSearchPatientContext.Provider>
  );
};

export const useMainSearchPatient = () => useContext(MainSearchPatientContext);
export default MainSearchPatientProvider;
