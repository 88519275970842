import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Card } from "antd";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { ISignature, IVisitBill } from "@interface/IGlobal.interface";
import { KeyNameSignatureSecond } from "@interface/IGlobal.interface";
import { useUpdateRejectCourses } from "@services/api/order";
import { useGetRejectCourseDetail } from "@services/api/order";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Print from "./Print";
import Header from "./Header";
import ModalInfo from "@components/signature/ModalInfo";

const Report = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const printReportRef = useRef(null);
  const client = useQueryClient();
  const match: any = useRouteMatch();
  const rejectByID = match.params.id;
  const rejectCourseByID = useGetRejectCourseDetail(rejectByID);
  const rejectCourses = useUpdateRejectCourses();
  const pageStyle = `  @page { size:A4;
    margin: 40px;}`;
  const [divHeight, setDivHeight] = useState(
    document.getElementById("container")?.clientHeight
  );
  const [visible, setVisible] = useState<IVisitBill>({
    first: false,
    second: false,
  });

  useEffect(() => {
    setDivHeight(document.getElementById("container")?.clientHeight);
    // eslint-disable-next-line
  }, [document.getElementById("container")?.clientHeight]);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  const updateSignature = (
    values: { date: string; title: KeyNameSignatureSecond } & ISignature
  ) => {
    rejectCourses.mutate(
      { id: rejectByID, data: values },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["order-reject-course"]);
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
        onSettled: () => {
          setVisible({
            ...visible,
            [values?.title]: !visible[values?.title],
          });
        },
      }
    );
  };

  return (
    <>
      <Header history={history} handlePrint={handlePrint} />
      <div className="set-layout ">
        <Card
          className=" "
          extra={<b className="header-card">รายงานคืนคอร์ส</b>}
        >
          <div className="report-procedure">
            <div className="border-print-bill p-4">
              <ReactToPrint content={() => printReportRef.current} />
              <Print
                refData={printReportRef}
                divHeight={divHeight}
                rejectCourseByID={rejectCourseByID?.data?.result?.[0]}
                visible={visible}
                setVisible={setVisible}
              />
            </div>
          </div>
        </Card>
        {visible.first ? (
          <ModalInfo
            visible={visible}
            setVisible={setVisible}
            date={rejectCourseByID?.data?.result?.[0]?.firstSignatureDate || ""}
            name="first"
            updateSignature={updateSignature}
            isLoading={rejectCourses?.isLoading}
          />
        ) : visible.second ? (
          <ModalInfo
            visible={visible}
            setVisible={setVisible}
            date={
              rejectCourseByID?.data?.result?.[0]?.secondSignatureDate || ""
            }
            name="second"
            updateSignature={updateSignature}
            isLoading={rejectCourses?.isLoading}
          />
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default Report;
