import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { IItem, IOrder } from "@interface/IOrder.interface";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useDeleteDraftOrder } from "@services/api/draft_order";
import { IGetDraftOrder, IGroup } from "@interface/IDraftOrder.interface";
import { useMainLabInterfaceGroup } from "@provider/lab_inteface_group";
import { useDeleteWorkOrdersEditProduct } from "@services/api/work_order";
import { ENUM_ROOM_SIGNATURE, ORDER_TYPE } from "@tools/util.enum";
import { Button, Empty, Form, Input, Popover } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import empty from "@assets/img/empty.svg";
import Modaler from "@components/modaler/Modaler";
import MoTable from "@components/table/Table";

type Props = {
  patientID: string | number;
  draftOrderList?: IGetDraftOrder;
  createNow: () => void;
  editRecord: boolean;
  title: string;
  type: ORDER_TYPE;
  eventId: number;
  room: ENUM_ROOM_SIGNATURE;
};

const TableOrderList = ({
  patientID,
  draftOrderList,
  createNow,
  editRecord,
  title,
  type,
  eventId,
  room,
}: Props) => {
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const [deleteForm] = useForm();
  const appointmentsID = match.params.appointmentsID;
  const [visible, setVisible] = useState<boolean>(false);
  // const [visibleInput, setVisibleInput] = useState<boolean>(false);
  const delWorkOrders = useDeleteWorkOrdersEditProduct();
  // const updateWorkOrderItemById = useUpdateWorkOrderItemById();
  const [product, setProduct] = useState<any>({
    idProduct: "",
    edit: {},
    type: "",
    uuid: "",
    addByCashier: false,
  });
  // const [editOrder] = Form.useForm();
  // const updateOrderItem = useUpDateOrderItem();
  const deleteDraftOrder = useDeleteDraftOrder();
  const client = useQueryClient();
  const { labInfoList, status, visitId } = useMainLabInterfaceGroup();

  const checkDrug = (name: string) => {
    return name?.includes("Drug & Supplement") || name === "C-0002";
  };
  const onModal = () => {
    setVisible(!visible);
  };

  // const onClickRow = async (event: IItem) => {
  //   setVisibleInput(!visibleInput);
  //   setProduct({ ...product, edit: event });
  //   const option = event.option ? JSON.parse(event.option || `{}`) : {};

  //   const description =
  //     event?.product?.category?.id === "C-0006"
  //       ? event?.courseItems
  //           ?.map((item, index) => {
  //             return `${index + 1}. ${item?.product?.nameTh} \n`;
  //           })
  //           .join("")
  //       : event?.productDescription || event?.product?.descriptionTh;
  //   editOrder.resetFields();
  //   editOrder.setFieldsValue({
  //     ...event.product,
  //     nameShown: event?.product?.nameTh,
  //     genericName: event?.product?.nameEn,
  //     ...option,
  //     drugExpiration: option?.drugExpiration
  //       ? dayjs(option.drugExpiration)
  //       : undefined,
  //     dateStart: option?.dateStart ? dayjs(option.dateStart) : undefined,
  //     dateStop: option?.dateStop ? dayjs(option.dateStop) : undefined,
  //     qty: event.qty,
  //     specialInstructions: event?.usageTh,
  //     productDescription: description,
  //     name: event?.product?.category?.nameTh,
  //     categoryID: event?.product?.category?.id,
  //     groupNameTh: event?.product?.group?.nameTh,
  //     disabled: true,
  //   });
  // };

  const onDelete = (value?: { remark: string }) => {
    if (status === "interface_lab") {
      deleteWorkOrders([product?.uuid], value);
    } else {
      handleDeleteDraftOrder(product.idProduct);
    }
  };
  // const onCancel = () => {
  //   setVisibleInput(!visibleInput);
  // };

  const deleteWorkOrders = (id?: any, value?: { remark: string }) => {
    delWorkOrders.mutate(
      {
        data: {
          uuids: id,
          room: room,
          ...value,
        },
        id: visitId,
      },
      {
        onSuccess: () => {
          client.invalidateQueries(["get-lab-info"]);
        },
        onError: () => {},
        onSettled: () => {
          onModal();
        },
      }
    );
  };

  const handleDeleteDraftOrder = (id: string) => {
    deleteDraftOrder.mutate(
      {
        id: patientID,
        idProduct: {
          eventId: eventId,
          itemId: [id],
          room: room,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
          client.invalidateQueries(["get-draft-order-list"]);
          client.invalidateQueries(["get-appointment-byID", appointmentsID]);
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
        },
      }
    );

    onModal();
  };

  // const handleUpDate = (value: any) => {
  //   if (status === "interface_lab") {
  //     updateWorkOrderItem(value);
  //   } else {
  //     handleUpDateOrderItem(value);
  //   }
  // };

  // const handleUpDateOrderItem = (value: any) => {
  //   const data = {
  //     qty: Number(value.qty),
  //     productDescription: value.productDescription,
  //     option: JSON.stringify(value),
  //     eventId: eventId,
  //   };
  //   updateOrderItem.mutate(
  //     { data: data, patientId: patientID, itemId: product.edit.id },
  //     {
  //       onSuccess: () => {
  //         openNotification({ method: "PUT_SUCCESS" });
  //       },
  //       onError: () => {
  //         openNotification({ method: "PUT_ERROR" });
  //       },
  //       onSettled: () => {
  //         client.invalidateQueries(["get-draft-order-list"]);
  //         onCancel();
  //       },
  //     }
  //   );
  // };

  // const updateWorkOrderItem = (data: any) => {
  //   updateWorkOrderItemById.mutate(
  //     {
  //       data: {
  //         ...data,
  //         remark: data?.remark || "",
  //         use: data?.use || false,
  //         option: JSON.stringify(data),
  //       },
  //       id: product?.edit?.id,
  //     },
  //     {
  //       onSuccess: () => {
  //         openNotification({ method: "PUT_SUCCESS" });
  //       },
  //       onError: (err) => {
  //         openNotification({ method: "PUT_ERROR", message: String(err) });
  //       },
  //       onSettled: () => {
  //         client.invalidateQueries(["get-lab-info"]);
  //         onCancel();
  //       },
  //     }
  //   );
  // };

  // const checkRow = (e: IItem) => {
  //   if (!e?.children) {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: IOrder, data: IGroup & IItem, index: number) => {
        return (
          <div>
            {data?.level === 1 ? index + 1 : data?.level === 2 ? "" : ""}
          </div>
        );
      },
    },
    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (name: String, data: IGroup & IItem) => {
        return (
          <div>
            {data?.level === 1
              ? i18n.language === "th"
                ? data?.children?.[0]?.children?.[0]?.product?.category?.nameTh
                : data?.children?.[0]?.children?.[0]?.product?.category?.nameEn
              : data?.level === 2
              ? t("nameList")
              : i18n.language === "th"
              ? data?.product?.nameTh || ""
              : data?.product?.nameEn || ""}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (product: IItem, data: IGroup & IItem) => {
        return (
          <div key={data?.id}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("type")
              : i18n.language === "th"
              ? product?.group?.nameTh || ""
              : product?.group?.nameEn || ""}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "product",
      key: "product",
      render: (_: IItem, data: IGroup & IItem) => {
        return (
          <>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length || "-"
              : data?.level === 2
              ? t("amount")
              : data?.qty + "   "}
          </>
        );
      },
    },

    // {
    //   title: "",
    //   dataIndex: "product",
    //   key: "product",
    //   align: "center",
    //   render: (_: IItem, data: IGroup & IItem) => {
    //     return (
    //       <>{data?.level === 1 ? "" : data?.level === 2 ? "Test Code" : "   "}</>
    //     );
    //   },
    // },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      align: "center",
      render: (item: String, dataList: IGroup & IItem) => {
        return (
          <>
            {dataList?.level === 1 ? (
              ""
            ) : dataList?.level === 2 ? (
              t("details")
            ) : (
              <Popover
                content={
                  item || i18n.language === "th"
                    ? dataList?.product?.descriptionTh || "-"
                    : dataList?.product?.descriptionEn || "-"
                }
                key={dataList?.id}
              >
                <div className="text-content">
                  {item || i18n.language === "th"
                    ? dataList?.product?.descriptionTh || "-"
                    : dataList?.product?.descriptionEn || "-"}
                </div>
              </Popover>
            )}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (item: IItem, dataList: IGroup & IItem) => {
        let color;
        if (dataList?.option) {
          color = "#77C48B";
        } else if (!dataList?.option) {
          color = "#498DCB";
        }
        return (
          <>
            {dataList?.level === 1 ? (
              ""
            ) : dataList?.level === 2 && checkDrug(dataList?.id) ? (
              t("drugLabel")
            ) : checkDrug(item?.category?.id) ? (
              <div
                className="table-queue-status  d-flex justify-center"
                style={{ backgroundColor: color }}
              >
                {dataList?.option
                  ? t("haveDrugDetails")
                  : !dataList?.option && t("noDrugDetails")}
              </div>
            ) : (
              ""
            )}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      align: "center",
      render: (product: any, data: IGroup & IItem) => {
        return (
          <>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("manage")
              : !product?.isPromotion && (
                  <DeleteOutlined
                    onClick={(e) => {
                      deleteForm.resetFields();

                      setProduct({
                        ...product,
                        idProduct: data?.id,
                        uuid: data?.uuid,
                        type: data?.orderType || "",
                        addByCashier: data?.addByCashier,
                      });
                      onModal();
                      e.stopPropagation();
                    }}
                  />
                )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modaler
        visible={visible}
        loadingButton={delWorkOrders.isLoading || deleteDraftOrder?.isLoading}
        onCancel={onModal}
        onOk={() => {
          product?.addByCashier === false ? deleteForm.submit() : onDelete();
        }}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        <div>
          {t("doYouWantToDelete")}
          {product?.addByCashier === false && (
            <Form name="deleteForm" form={deleteForm} onFinish={onDelete}>
              <Form.Item
                name="remark"
                label={t("reasonForDeletion")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Form>
          )}
        </div>
      </Modaler>
      {/* {!!visibleInput && (
        <ModalUpdate
          editOrder={editOrder}
          visibleInput={visibleInput}
          onCancel={onCancel}
          handleUpDateOrderItem={handleUpDate}
          isLoading={updateOrderItem?.isLoading}
        />
      )} */}

      <MoTable
        className="components-table-demo-nested table-selection table-confirm "
        pagination={false}
        locale={{
          emptyText: (
            <>
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">{t("noInformation")} </span>
                }
              >
                {editRecord ? (
                  <Button type="primary" onClick={createNow}>
                    {`+ ${t("addOrder")} ${title}`}
                  </Button>
                ) : null}
              </Empty>
            </>
          ),
        }}
        rowClassName={(r) => {
          if (r?.evel === 1) {
            return " no-header no-cursor";
          } else if (r?.evel === 2) {
            return "table-header no-header no-cursor";
          } else if (r?.evel === 3) {
            return " no-header";
          }
          return "";
        }}
        expandRowByClick
        rowKey={"key"}
        key={"key"}
        columns={columns}
        dataSource={
          type === ORDER_TYPE.LAB_INTERFACE_OTHER_ONE
            ? !!labInfoList
              ? labInfoList?.other1
              : draftOrderList?.labInterface?.other1
            : !!labInfoList
            ? labInfoList?.other2
            : draftOrderList?.labInterface?.other2
        }
        // onClickRow={(e: IItem) => checkRow(e) && onClickRow(e)}
        onDoubleClick={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default TableOrderList;
