import { useTranslation } from "react-i18next";
import { Col, Row, Card } from "antd";
import TabsTransfer from "./Tabs";

const Transfer = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col className="header-text">
          <div>
            <h1>{t("referral")}</h1>
          </div>

          <span className="t-dark-blue f-normal">{t("nursery")}</span>
        </Col>
        <Col lg={2} className="justify-col"></Col>
      </Row>
      <div className="set-layout ">
        <Card>
          <TabsTransfer />
        </Card>
      </div>
    </div>
  );
};

export default Transfer;
