import { ENUM_MENU } from "./tools/util.enum";
// import { MenuFoldOutlined } from "@ant-design/icons";

import { MANAGE_COURSES } from "@assets/data/pathRouter";
import { PROMOTION, REPORT_SUMMARY } from "@assets/data/pathRouter";
import { OPD, PRE_REGISTRATION, SETTING } from "@assets/data/pathRouter";
import { PRE_REGISTRATIONMARK, USER_MANAGE } from "@assets/data/pathRouter";
import { LAB, MEDICAL_DOCUMENT, NOTIFICATION } from "@assets/data/pathRouter";
import { COUPON, EVENT, INCHART, INTERFACE_LAB } from "@assets/data/pathRouter";
import Profile from "./assets/img/icon/Profile.svg";
import Booking from "./assets/img/icon/Booking.svg";
import Cashier from "./assets/img/icon/Cashier.svg";
import Setting from "./assets/img/icon/Setting.svg";
import Dashboard from "./assets/img/icon/Dashboard.svg";
import NurseRoom from "./assets/img/icon/NurseRoom.svg";
import Marketing from "./assets/img/icon/Marketing.svg";
import UserManage from "./assets/img/icon/UserManage.svg";
import DoctorRoom from "./assets/img/icon/DoctorRoom.svg";
import PharmacyRoom from "./assets/img/icon/PharmacyRoom.svg";
import ReportSummary from "./assets/img/icon/ReportSummary.svg";
import PainManagement from "./assets/img/icon/PainManagement.svg";
import MedicalDocument from "./assets/img/icon/MedicalDocuments.svg";
import PhysicianAssistant from "./assets/img/icon/PhysicianAssistant.svg";

const approveManagement = [
  {
    name: "medicalRecord.title",
    route: `${OPD.title}`,
    subMenuName: "MEDICAL_RECORD",
  },
  {
    name: "medicalRecord.queue",
    route: "/profile/queue",
    subMenuName: "QUE_MEDICAL_RECORD",
  },
  {
    name: "medicalRecord.transfer",
    route: "/profile/transfer",
    subMenuName: "FOREWORD_MEDICAL_RECORD",
  },
  {
    name: "preRegister.title",
    route: `${PRE_REGISTRATION.title}`,
    subMenuName: "PREREGISTER1",
  },
];

const marketing = [
  {
    name: "preRegister.title",
    route: `${PRE_REGISTRATIONMARK.title}`,
    subMenuName: ENUM_MENU.PREREGISTER2,
  },
  {
    name: "promotion.title",
    route: `${PROMOTION.title}`,
    subMenuName: ENUM_MENU.PROMOTION,
  },
  {
    name: "coupon.title",
    route: `${COUPON.title}`,
    subMenuName: ENUM_MENU.COUPON,
  },
  {
    name: "event.title",
    route: `${EVENT.title}`,
    subMenuName: ENUM_MENU.EVENT,
  },
  {
    name: "manageCourses.title",
    route: `${MANAGE_COURSES.title}`,
    subMenuName: ENUM_MENU.COURSE_MANAGEMENT,
  },
];

const physicianAssistant = [
  {
    name: "confirmOrder.title",
    route: "/physician-assistant/confirm-order",
    subMenuName: "CONFIRM_ORDER",
  },
  {
    name: "executeorderOrder.title",
    route: "/physician-assistant/execute-order",
    subMenuName: "PROCESS_ORDER",
  },
];

const nurseRoom = [
  { name: "inchart.title", route: `${INCHART.title}`, subMenuName: "INCHARGE" },
  {
    name: "procedure.title",
    route: "/nurse-room/procedure",
    subMenuName: "PROCEDURE",
  },
  {
    name: "reportProcedure.title",
    route: "/report-procedure",
    subMenuName: "REPORT_PROCEDURE",
  },
  { name: "lab.title", route: `${LAB.title}`, subMenuName: "LAB" },
  {
    name: "interfaceLab.title",
    route: `${INTERFACE_LAB.title}`,
    subMenuName: "INTERFACE_LAB",
  },
];

const pharmacyRoom = [
  {
    name: "dispenseMedicine.title",
    route: "/pharmacy-room/dispense-medicine",
    subMenuName: "DISPENSE_MEDICINE",
  },
  {
    name: "returnMedicine.title",
    route: "/pharmacy-room/return-medicine",
    subMenuName: "RETURN_MEDICINE",
  },
  {
    name: "reportDrug.title",
    route: "/pharmacy-room/report-drug",
    subMenuName: "MEDICINE_REPORT",
  },
];

const financialRoom = [
  { name: "pay.title", route: "/financial-room/pay", subMenuName: "PAY" },
  {
    name: "transferCourse.title",
    route: "/financial-room/transfer-course",
    subMenuName: "TRANSFER_COURSE",
  },
  {
    name: "returnCourse.title",
    route: "/financial-room/return-course",
    subMenuName: "RETURN_COURSE",
  },
  {
    name: "signCourse.title",
    route: "/financial-room/sign-course",
    subMenuName: "ASSIGN_COURSE",
  },
];

const setting = [
  {
    name: "generalInfo.title",
    route: SETTING.title,
    subMenuName: "GENERAL",
  },
  {
    name: "wareHouse.title",
    route: SETTING.wareHouse,
    subMenuName: "WAREHOUSE",
  },

  {
    name: "branchManagement.title",
    route: `${SETTING.branchManagement}`,
    subMenuName: "BRANCH_MANAGEMENT",
  },
];

const booking = [
  {
    name: "booking.doctorBooking",
    route: "/booking/medical-room-reservation",
    subMenuName: "QUE_MEDICAL",
  },
  {
    name: "booking.anotherBooking",
    route: "/booking/book-another-room",
    subMenuName: "QUE_PROCEDURE",
  },
  {
    name: "booking.notification",
    route: `${NOTIFICATION.title}`,
    subMenuName: "NOTIFICATION",
  },
];

const navMenu = [
  {
    name: "dashboard.title",
    route: "/dashboard",
    icons: Dashboard,
    authMenuName: "DASHBOARD",
  },
  {
    name: "opd",
    route: "profile",
    icons: Profile,
    children: approveManagement,
    authMenuName: "NURSERY",
  },

  {
    name: "marketing.title",
    route: "marketing",
    icons: Marketing,
    children: marketing,
    authMenuName: "MARKETING",
  },
  {
    name: "booking.title",
    route: "booking",
    icons: Booking,
    children: booking,
    authMenuName: "SCHEDULE",
  },
  {
    name: "doctorroom",
    route: "/doctorroom",
    icons: DoctorRoom,
    authMenuName: "MEDICAL",
  },
  {
    name: "physicianAssistant",
    route: "physician-assistant",
    icons: PhysicianAssistant,
    children: physicianAssistant,
    authMenuName: "MEDICAL_ASSISTANT",
  },
  {
    name: "nurseRoom",
    route: "nurse-room",
    icons: NurseRoom,
    children: nurseRoom,
    authMenuName: "INFIRMARY",
  },
  {
    name: "physiotherapy.title",
    route: "/pain-management",
    icons: PainManagement,
    authMenuName: "PHYSICAL_THERAPY",
  },
  {
    name: "pharmacyRoom",
    route: "pharmacy-room",
    icons: PharmacyRoom,
    children: pharmacyRoom,
    authMenuName: "DISPENSARY",
  },
  {
    name: "cashier",
    route: "financial-room",
    icons: Cashier,
    children: financialRoom,
    authMenuName: "CASHIER",
  },
  {
    name: "setting",
    route: "setting",
    icons: Setting,
    children: setting,
    authMenuName: "SETTING",
  },
  {
    name: "userManage",
    route: `${USER_MANAGE.title}`,
    icons: UserManage,
    authMenuName: "USER",
  },
  {
    name: "medicalDocument.title",
    route: `${MEDICAL_DOCUMENT.title}`,
    icons: MedicalDocument,
    authMenuName: "DOCUMENT",
  },
  {
    name: "reportSummary.title",
    route: `${REPORT_SUMMARY.title}`,
    icons: ReportSummary,
    authMenuName: "REPORT",
  },
];
export default navMenu;
