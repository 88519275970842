// import { dayjs } from "@tools/timezone";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { openNotification } from "@components/notification/Notification";
import { useDeleteDraftOrder } from "@services/api/draft_order";
import { useUpDateOrderItem } from "@services/api/draft_order";
import { ENUM_ROOM_SIGNATURE, EVENT_ROOM } from "@tools/util.enum";
import { IGetDraftOrder, IGroup } from "@interface/IDraftOrder.interface";
import { productName, TextBRHTML } from "@tools/util";
import { useMainMedicineDoctorRoom } from "@provider/medicine_doctor_room";
import { Button, Empty, Form, Popover } from "antd";
import { IChild, IItem, IOrder, IProduct } from "@interface/IOrder.interface";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import empty from "@assets/img/empty.svg";
import React from "react";
import Modaler from "@components/modaler/Modaler";
import MoTable from "@components/table/Table";
import BigNumber from "bignumber.js";
import ModalUpdate from "../modal_update/ModalUpdate";
import moment from "moment";

type keyName = "continuous" | "one" | "optionDrug";

type Props = {
  patientID: string | number;
  draftOrderList?: IGetDraftOrder;
  createNow: () => void;
  medicalRecord: boolean;
  keyData: keyName;
  eventId: number;
};

const TableOrderList = ({
  patientID,
  draftOrderList,
  createNow,
  medicalRecord,
  keyData,
  eventId,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { buttonAdd } = useMainMedicineDoctorRoom();
  const num = useRef(0);
  const [visible, setVisible] = useState<boolean>(false);
  const [visibleInput, setVisibleInput] = useState<boolean>(false);
  const [product, setProduct] = useState<any>({
    idProduct: "",
    edit: {},
  });
  const [editOrder] = Form.useForm();
  const updateOrderItem = useUpDateOrderItem();
  const deleteDraftOrder = useDeleteDraftOrder();
  const client = useQueryClient();

  const checkDrug = (name: string) => {
    return name?.includes("Drug & Supplement") || name === "C-0002";
  };
  const onModal = () => {
    setVisible(!visible);
  };

  const onClickRow = async (event: IItem) => {
    setVisibleInput(!visibleInput);
    setProduct({ ...product, edit: event });
    const option = event?.option ? JSON?.parse(event?.option || `{}`) : {};
    console.log("event", event);

    // const description = !!event?.productDescription
    //   ? event?.productDescription
    //   : i18n.language === "th"
    //   ? event?.product?.propertiesTh || event?.product?.descriptionTh
    //   : event?.product?.propertiesEn || event?.product?.descriptionEn;
    editOrder.resetFields();
    editOrder.setFieldsValue({
      ...event,
      ...event.product,
      nameShown: event?.product?.nameTh,
      genericName: event?.product?.nameEn,
      ...option,
      categoryId: event?.product?.categoryId || option?.categoryId,
      quantityUsed: option?.quantityUsed || 1,
      drugExpiration: option?.drugExpiration
        ? moment(option.drugExpiration)
        : undefined,
      dateStart: option?.dateStart ? moment(option.dateStart) : undefined,
      dateStop: option?.dateStop ? moment(option.dateStop) : undefined,
      qty: event?.qty,
      specialInstructions: option?.specialInstructions || event?.usageTh,
      productDescriptionTh:
        event?.productDescriptionTh ||
        event?.product?.propertiesTh ||
        event?.product?.descriptionTh,
      productDescriptionEn:
        event?.productDescriptionEn ||
        event?.product?.propertiesEn ||
        event?.product?.descriptionEn,
      name: event?.product?.category?.nameTh,
      categoryID: event?.category?.id || event?.product?.category?.id,
      groupNameTh: event?.product?.group?.nameTh,
      product: event?.product,
      frequencyTh: option?.frequencyTh || event?.product?.usageTh,
      frequencyEn: option?.frequencyEn || event?.product?.usageEn,
      room: ENUM_ROOM_SIGNATURE.DOCTOR_ROOM,
    });
  };

  const onDelete = () => {
    handleDeleteDraftOrder(product.idProduct);
  };
  const onCancel = () => {
    setVisibleInput(!visibleInput);
  };

  const handleDeleteDraftOrder = (id: string) => {
    deleteDraftOrder.mutate(
      {
        id: patientID,
        idProduct: {
          eventId: eventId,
          itemId: [id],
          room: ENUM_ROOM_SIGNATURE.DOCTOR_ROOM,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
          client.invalidateQueries(["get-draft-order-list"]);
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
        },
      }
    );

    onModal();
  };

  const handleUpDateOrderItem = (value: any) => {
    const data = {
      eventId: eventId,
      qty: Number(value?.qty),
      productDescriptionTh: value.productDescriptionTh,
      productDescriptionEn: value.productDescriptionEn,
      type: value?.type,
      gratis: value?.gratis,
      option: JSON?.stringify(value),
      room: value?.room,
    };
    updateOrderItem.mutate(
      { data: data, patientId: patientID, itemId: product?.edit?.id },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
        onSettled: () => {
          client.invalidateQueries(["get-draft-order-list"]);
          onCancel();
        },
      }
    );
  };

  const checkRow = (e: IItem) => {
    if (e?.level === 3 || e?.level === 5) {
      return true;
    } else {
      return false;
    }
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: IOrder, data: IGroup & IItem, index: number) => {
        return (
          <div>
            {data?.level === 1 ? index + 1 : data?.level === 2 ? "" : ""}
          </div>
        );
      },
    },
    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: String, data: IGroup & IItem) => {
        return (
          <div>
            {data?.level === 1
              ? productName(
                  i18n.language,
                  data?.children?.[0]?.children?.[0]?.product?.category?.nameTh,
                  data?.children?.[0]?.children?.[0]?.product?.category?.nameEn
                )
              : data?.level === 2 || data?.level === 4
              ? t("nameList")
              : productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (product: IItem, data: IGroup & IItem) => {
        return (
          <div key={data?.id}>
            {data?.level === 1
              ? ""
              : data?.level === 2 || data?.level === 4
              ? t("type")
              : i18n.language === "th"
              ? product?.group?.nameTh || ""
              : product?.group?.nameEn || ""}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "product",
      key: "product",
      render: (_: IItem, data: IGroup & IItem) => {
        return (
          <React.Fragment>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length || "-"
              : data?.level === 2 || data?.level === 4
              ? t("amount")
              : `${data?.qty || ""} ${
                  i18n.language === "th"
                    ? data?.product?.unitTh || ""
                    : data?.product?.unitEn || ""
                }`}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "gratis",
      key: "gratis",
      render: (gratis: boolean, data: IGroup & IItem & IChild) => {
        return data?.category === "C-0001" ||
          data?.product?.categoryId === "C-0001" ||
          data?.children?.[0]?.product?.categoryId === "C-0001" ? (
          <React.Fragment>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("Gratis")
              : !gratis && data?.level === 3
              ? "NO"
              : data?.level === 3
              ? "YES"
              : ""}
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        );
      },
    },

    {
      title: t("price"),
      dataIndex: "total",
      key: "total",
      render: (total: string, data: IGroup & IItem) => {
        return (
          <React.Fragment>
            {data?.level === 1
              ? new BigNumber(total || 0).toFormat(2)
              : data?.level === 2
              ? t("price")
              : data?.level === 3
              ? new BigNumber(total || 0).toFormat(2)
              : ""}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      align: "center",
      render: (item: String, dataList: IGroup & IItem & IProduct) => {
        return (
          <React.Fragment>
            {dataList?.level === 1 ? (
              ""
            ) : dataList?.level === 2 || dataList?.level === 4 ? (
              t("details")
            ) : dataList?.level === 3 || dataList?.level === 5 ? (
              <Popover
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "th"
                          ? TextBRHTML(
                              dataList?.productDescriptionTh ||
                                dataList?.product?.propertiesTh ||
                                dataList?.product?.descriptionTh ||
                                "-"
                            )
                          : TextBRHTML(
                              dataList?.productDescriptionEn ||
                                dataList?.product?.propertiesEn ||
                                dataList?.product?.descriptionEn ||
                                "-"
                            ),
                    }}
                  />
                }
                key={dataList?.id}
              >
                <div className="text-content">
                  {item || i18n.language === "th"
                    ? dataList?.productDescriptionTh ||
                      dataList?.product?.propertiesTh ||
                      dataList?.product?.descriptionTh ||
                      "-"
                    : dataList?.productDescriptionEn ||
                      dataList?.product?.propertiesEn ||
                      dataList?.product?.descriptionEn ||
                      "-"}
                </div>
              </Popover>
            ) : null}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (item: IItem, dataList: IGroup & IItem) => {
        let color;
        if (dataList?.option) {
          color = "#77C48B";
        } else if (!dataList?.option) {
          color = "#498DCB";
        }
        return (
          <>
            {dataList?.level === 1 ? (
              ""
            ) : (dataList?.level === 2 && checkDrug(dataList?.id)) ||
              (dataList?.level === 2 && item?.room === EVENT_ROOM.PHARMACY) ? (
              t("drugLabel")
            ) : checkDrug(item?.category?.id) ||
              item?.room === EVENT_ROOM.PHARMACY ? (
              <div
                className="table-queue-status  d-flex justify-center"
                style={{ backgroundColor: color }}
              >
                {dataList?.option
                  ? t("haveDrugDetails")
                  : !dataList?.option && t("noDrugDetails")}
              </div>
            ) : (
              ""
            )}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      align: "center",
      render: (product: IItem, data: IGroup & IItem) => {
        return (
          <>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 ? (
              t("manage")
            ) : data?.level === 3 ? (
              <DeleteOutlined
                onClick={(e) => {
                  setProduct({ ...product, idProduct: data?.id });
                  onModal();
                  e.stopPropagation();
                }}
              />
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>

      {visibleInput === true && (
        <ModalUpdate
          editOrder={editOrder}
          visibleInput={visibleInput}
          onCancel={onCancel}
          handleUpDateOrderItem={handleUpDateOrderItem}
          isLoading={updateOrderItem?.isLoading}
        />
      )}

      <MoTable
        className="components-table-demo-nested table-selection table-confirm "
        pagination={false}
        locale={{
          emptyText: (
            <>
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">{t("noInformation")} </span>
                }
              >
                {medicalRecord ? (
                  <Button type="primary" onClick={createNow}>
                    {!!buttonAdd
                      ? `+ ${t(buttonAdd)}`
                      : `+ ${t("addOrder")} ${
                          keyData === "one" ? "One Day" : "Continuous"
                        }`}
                  </Button>
                ) : null}
              </Empty>
            </>
          ),
        }}
        rowClassName={(r) => {
          if (r?.evel === 1) {
            return "no-header";
          } else if (r?.evel === 2) {
            return "table-header no-header ";
          } else if (r?.evel === 3 && r?.children) {
            return "no-header";
          } else if (r?.evel === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "table-confirm-first table-confirm-level-course table-bottom--level-course-num";
            } else {
              return "table-confirm-first table-confirm-level-course";
            }
          } else if (r?.evel === 4) {
            num.current = r?.children?.length;
            return "table-header no-header table-confirm-level-course no-cursor table-top-level-course ";
          }
          return "";
        }}
        // expandRowByClick
        rowKey={"key"}
        key={"key"}
        columns={columns}
        dataSource={draftOrderList?.[`${keyData}`]?.groups || []}
        onClickRow={(e: IItem) => checkRow(e) && onClickRow(e)}
        onDoubleClick={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default TableOrderList;
