import { createContext, useContext } from "react";
import { IPhysicianAssistantContext } from "./interface";

const MainPhysicianAssistantContext = createContext<IPhysicianAssistantContext>(
  {} as IPhysicianAssistantContext
);

const MainPhysicianAssistantProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: any;
}) => {
  return (
    <MainPhysicianAssistantContext.Provider value={{ ...value }}>
      {children}
    </MainPhysicianAssistantContext.Provider>
  );
};

export const useMainPhysicianAssistant = () =>
  useContext(MainPhysicianAssistantContext);
export default MainPhysicianAssistantProvider;
