import { OPD } from "@assets/data/pathRouter";
import { useState } from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { useCreateSaveToOrder } from "@services/api/draft_order";
import { useGetDraftOrderList } from "@services/api/draft_order";
import { useCreateAppointmentOther } from "@services/api/appointments";
import { useGetOrderListOutstanding } from "@services/api/order";
import { useGetVisitsByID, useUpdateVisits } from "@services/api/visits";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import Fields from "./Fields";
import BackHome from "@components/button/BackHome";
import ModalSent from "./ModalSent";
import MainPatientByIdProvider from "@provider/patient_by_id";
import MainProductOutStandingProvider from "@provider/product_out_standing";

const ImportantSymptoms = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState({
    proceed: false,
    confirm: false,
  });
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const eventId = match.params.eventId;
  const visitsID = match.params.visitsID;
  const createAppointmentOther = useCreateAppointmentOther();
  const draftOrderList = useGetDraftOrderList(
    patientID,
    !!eventId ? eventId : 0
  );
  const { data } = useGetVisitsByID(visitsID);
  const outStanding = useGetOrderListOutstanding(patientID);
  const updateVisits = useUpdateVisits();
  const createSaveToOrder = useCreateSaveToOrder();

  const pushBookToday = () => {
    history.push(
      `${OPD.bookToday}/patient/${patientID}/visit/${visitsID}${
        !!eventId ? `/event/${eventId}` : ``
      }`
    );
  };

  const handleOkConfirm = (key: "confirm" | "proceed") => {
    createAppointmentOther.mutate(visitsID, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
      },
      onError: (err) => {
        openNotification({ method: "POST_ERROR", message: String(err) });
      },
      onSettled: () => {
        if (key === "proceed") {
          handleUpdateVisits();
        }
        if (
          draftOrderList?.data?.result?.[0]?.outType &&
          draftOrderList?.data?.result?.[0]?.outType?.groups?.length > 0 &&
          data?.patient?.preregisterProduct === true
        ) {
          handlePostSaveToOrder();
        }
        history.replace(`${OPD.title}`);
        handleCancel(key);
      },
    });
  };

  const handleUpdateVisits = () => {
    const formData = new FormData();
    formData?.append("proceed", "1");
    updateVisits.mutate(
      { data: formData, id: visitsID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const handlePostSaveToOrder = () => {
    const data =
      draftOrderList?.data?.result?.[0]?.outType?.groups?.[0]?.children?.[0]?.children?.map(
        (item) => {
          return item?.id;
        }
      );

    createSaveToOrder.mutate(
      {
        id: patientID,
        data: {
          eventId: eventId || 0,
          uuids: data,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          handleCancel("proceed");
          history.replace(`${OPD.title}`);
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  const handleCancel = (key: "confirm" | "proceed") => {
    setVisible({ ...visible, [key]: false });
  };

  return (
    <MainPatientByIdProvider value={{ patientById: data?.patient }}>
      <MainProductOutStandingProvider
        value={{ outStanding: outStanding?.data?.result }}
      >
        <div>
          <Row className="second-header " align="middle">
            <Col>
              <Row gutter={[14, 14]}>
                <BackHome history={history} />

                <Col className="header-text">
                  <div>
                    <h1>{t("medicalRecord.title")}</h1>
                  </div>
                  <span className="t-light-gray">{t("nursery")} / </span>
                  <span className="t-light-gray">{t("historyFile")} / </span>
                  <span className="t-dark-blue f-normal">
                    {t("importantSymptoms")}{" "}
                  </span>
                </Col>
              </Row>
            </Col>

            <Col style={{ marginLeft: "auto" }}>
              {outStanding?.data?.result?.[0]?.items ||
              (draftOrderList?.data?.result?.[0]?.outType &&
                !!draftOrderList?.data?.result?.[0]?.outType?.groups?.length &&
                data?.patient?.preregisterProduct === true) ? (
                <>
                  <Button
                    className="btn-create mr-05"
                    onClick={() => setVisible({ ...visible, proceed: true })}
                  >
                    {`${t("sendToProcessOrder")}`}
                  </Button>
                </>
              ) : null}
              <Button
                className="send-lab mr-05"
                onClick={() => setVisible({ ...visible, confirm: true })}
              >
                {`${t("sentToTheOrderConfirmationRoom")}`}
              </Button>

              <Button className=" reserve" onClick={pushBookToday}>
                {`${t("sendTo")}${t("doctor")}`}
              </Button>
            </Col>
          </Row>
          <div className="set-layout body-height">
            <Fields
              patientID={patientID}
              visitsByID={data}
              visitsID={visitsID}
            />
          </div>
          {visible?.proceed && (
            <ModalSent
              visible={visible?.proceed}
              handleOk={() => handleOkConfirm("proceed")}
              handleCancel={() => handleCancel("proceed")}
              title={`${t("sendToProcessOrder")}`}
              loadingButton={createAppointmentOther?.isLoading}
            />
          )}
          {visible?.confirm && (
            <ModalSent
              visible={visible?.confirm}
              handleOk={() => handleOkConfirm("confirm")}
              handleCancel={() => handleCancel("confirm")}
              title={`${t("confirmSentToTheOrderConfirmationRoom")}`}
              loadingButton={createAppointmentOther?.isLoading}
            />
          )}
        </div>
      </MainProductOutStandingProvider>
    </MainPatientByIdProvider>
  );
};

export default ImportantSymptoms;
