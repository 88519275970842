import React, { useEffect, useRef, useState } from "react";
import MainLabInterfaceGroupProvider, {
  keyLabInterface,
} from "@provider/lab_inteface_group";
import { useRouteMatch } from "react-router-dom";
import { IChild, IGroup } from "@interface/IDraftOrder.interface";
import { useTranslation } from "react-i18next";
import { ISelectedRowKeys } from "../Header";
import { useGetProductLab } from "@services/api/product";
import { IResultLabInterface } from "@interface/IProduct.interface";
import { useGetDraftOrderList } from "@services/api/draft_order";
import { DRUG_STATUS, ENUM_G6PD } from "@tools/util.enum";
import { useMainPhysicianAssistant } from "@provider/physician_assistant";
import { keyNameProductLabInterface } from "@interface/IProduct.interface";
import { Button, Card, Checkbox, Col } from "antd";
import { ISelected, ITotalAndQuantity } from "@interface/IGlobal.interface";
import { Empty, Form, FormInstance, Row } from "antd";
import { ENUM_ROOM_SIGNATURE, ORDER_TYPE } from "@tools/util.enum";
import Note from "@components/note_visit/NoteVisit";
import empty from "@assets/img/empty.svg";
import moment from "moment";
import Advice from "@components/advice/Advice";
import BigNumber from "bignumber.js";
import ViewMarker from "@components/marker/view_marker/ViewMarker";
import SymbolView from "@components/symbol/Symbol";
import ModalMarker from "@components/marker/modal_marker/ModalMarker";
import RoleContext from "@provider/RoleProvider";
import ImagePatient from "@components/image/ImagePatient";
import LabInterface from "@views/doctor_room/tabs/counsel/create_order/lab_interface/LabInterface";
import EditMedicine from "@components/medicine/Medicine";
import OrderOutType from "@components/draft_order/order_out_type/OrderOutType";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import ReturnMedicine from "@components/medicine/Medicine";
import OrderOneDayList from "./one_day/OrderOneDayList";
import RejectGroupOrder from "@components/order/reject/RejectGroupOrder";
import LabInterfaceGroup from "@views/doctor_room/tabs/counsel/create_order/lab_interface_group/LabInterfaceGroup";
import TransferComponent from "@components/transfer/Transfer";
import OrderContinuousList from "./continuous/OrderContinuousList";
import DataLabInterfaceOne from "@components/lab_interface/lab_interface_group_one/DataLabInterfaceOne";
import DataLabInterfaceTwo from "@components/lab_interface/lab_interface_group_two/DataLabInterfaceTwo";
import UpdateStatusMedicine from "@components/medicine/Medicine";
import MainMedicineDoctorRoomProvider from "@provider/medicine_doctor_room";
const labInterfaceOne = DataLabInterfaceOne();
const labInterfaceTwo = DataLabInterfaceTwo();
const data = { ...labInterfaceOne, ...labInterfaceTwo };

type Props = {
  patientID: number;
  patientList: FormInstance;
  selected: ISelected;
  totalAndQuantity: ITotalAndQuantity;
};

const Fields = ({
  patientID,
  patientList,
  selected,
  totalAndQuantity,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { appointmentById } = useMainPhysicianAssistant();
  const match: any = useRouteMatch();
  const visitId = match.params.visitID;
  const draftOrderList = useGetDraftOrderList(
    patientID,
    appointmentById?.visit?.eventId || 0
  );
  const roleList = React.useContext(RoleContext);
  const labInterfaceGroupOne = useRef([]);
  const labInterfaceGroupTwo = useRef([]);
  const [visible, setVisible] = useState({ marker: false, open: false });
  const productLabList = useGetProductLab();
  const [labInterface, setLabInterface] = useState<{
    [K in keyNameProductLabInterface]: IResultLabInterface[];
  }>({
    testCode: [],
    hematology: [],
    microbiology: [],
    coagulation: [],
    urine: [],
    biochemistry1: [],
    biochemistry2: [],
    groupData: [],
    groupTwo: [],
  });
  const SRKSessionStorage = !!sessionStorage.getItem("selectedRowKeys")
    ? sessionStorage.getItem("selectedRowKeys") || "{}"
    : "{}";

  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "CONFIRM_ORDER";
  });

  useEffect(() => {
    var setLabInterfaceList: {
      [K in keyNameProductLabInterface]?: IResultLabInterface[];
    } = {};

    keyLabInterface.forEach((key: keyNameProductLabInterface) => {
      setLabInterfaceList = {
        ...setLabInterfaceList,
        [key]: data?.[key]?.map((item) => {
          const found = productLabList?.data?.result?.[0]?.[key]?.find(
            (element) => element?.sku === item?.sku
          );
          if (found) {
            return { ...item, ...found, disabled: true };
          } else {
            return { ...item, disabled: false };
          }
        }),
      };
    });

    setLabInterface({
      ...labInterface,
      ...setLabInterfaceList,
    });

    //eslint-disable-next-line
  }, [productLabList?.data]);

  useEffect(() => {
    sessionStorage.removeItem("selectedRowKeys");
    sessionStorage.removeItem("totalAndQuantity");
    changeSelectedRowKeys();
    const draftOrder = draftOrderList?.data?.result?.[0]?.labInterface;
    const groups1: string[] =
      recursive(draftOrder?.groups1)?.flat(Infinity) || [];
    const groups2: string[] =
      recursive(draftOrder?.groups2)?.flat(Infinity) || [];
    const groups3: string[] =
      recursive(draftOrder?.groups3)?.flat(Infinity) || [];
    const groups4: string[] =
      recursive(draftOrder?.groups4)?.flat(Infinity) || [];
    const other1: string[] =
      recursive(draftOrder?.other1)?.flat(Infinity) || [];
    const other2: string[] =
      recursive(draftOrder?.other2)?.flat(Infinity) || [];

    const data = [
      ...groups1,
      ...groups2,
      ...groups3,
      ...groups4,
      ...other1,
      ...other2,
    ]?.reduce(
      (result: number, current: string) =>
        Number(new BigNumber(current)?.plus(result)),
      0
    );

    totalAndQuantity.setTotalAndQuantity({
      ...totalAndQuantity?.totalAndQuantity,
      total: data,
      oneDay: {
        normal: 0,
        total: 0,
        quantity: 0,
      },
      continuous: {
        normal: 0,
        total: 0,
        quantity: 0,
      },
      labInterface: {
        normal: data,
        total: data,
        quantity:
          [
            ...(groups1 || []),
            ...(groups2 || []),
            ...(groups3 || []),
            ...(groups4 || []),
            ...(other1 || []),
            ...(other2 || []),
          ]?.length || 0,
      },
      discountTotal: data,
    });

    //eslint-disable-next-line
  }, [draftOrderList?.data]);

  useEffect(() => {
    const marker = [
      ...(appointmentById?.visit?.visitMarker?.doctorMarker0 || []),
      ...(appointmentById?.visit?.visitMarker?.doctorMarker1 || []),
      ...(appointmentById?.visit?.visitMarker?.doctorMarker2 || []),
      ...(appointmentById?.visit?.visitMarker?.doctorMarker3 || []),
    ];
    setVisible({ ...visible, open: !!marker?.length });

    // eslint-disable-next-line
  }, [appointmentById?.visit]);

  const recursive = (obj: any) => {
    try {
      return obj?.map((item: IGroup & IChild) => {
        if (item?.children) {
          return recursive(item?.children);
        }
        return item?.total;
      });
    } catch (err) {
      return [];
    }
  };

  const changeSelectedRowKeys = () => {
    const selectedRowKeys: ISelectedRowKeys = JSON.parse(SRKSessionStorage);

    if (SRKSessionStorage !== "{}") {
      selected.setSelectedRowKeys(selectedRowKeys);
    } else {
      const labInterface = draftOrderList?.data?.result?.[0]?.labInterface;
      const idsOther1 =
        labInterface?.other1?.[0]?.children?.[0]?.children?.flatMap((item) => {
          return item?.id;
        }) || [];

      const idsOther2 =
        labInterface?.other2?.[0]?.children?.[0]?.children?.flatMap((item) => {
          return item?.id;
        }) || [];

      const idsGroups1: string[] =
        labInterface?.groups1?.[0]?.children?.[0]?.children?.flatMap((item) => {
          return item?.id;
        }) || [];

      const idsGroups2: string[] =
        labInterface?.groups2?.[0]?.children?.[0]?.children?.flatMap((item) => {
          return item?.id;
        }) || [];

      const idsGroups3: string[] =
        labInterface?.groups3?.[0]?.children?.[0]?.children?.flatMap((item) => {
          return item?.id;
        }) || [];

      const idsGroups4: string[] =
        labInterface?.groups4?.[0]?.children?.[0]?.children?.flatMap((item) => {
          return item?.id;
        }) || [];

      const optionDrug: string[] =
        draftOrderList?.data?.result?.[0]?.optionDrug?.groups
          ?.flatMap((item) => {
            return item?.children?.flatMap((parent) => {
              return parent?.children?.flatMap((child) => {
                return child;
              });
            });
          })
          ?.flatMap((item) => {
            return item?.id;
          }) || [];

      selected.setSelectedRowKeys({
        oneDay: [],
        outType: draftOrderList?.data?.result?.[0]?.outType,
        continuous: [],
        optionDrug: optionDrug,
        other1: idsOther1 || [],
        other2: idsOther2 || [],
        labInterfaceGroup: [
          ...(idsGroups1 || []),
          ...(idsGroups2 || []),
          ...(idsGroups3 || []),
          ...(idsGroups4 || []),
        ],
      });
    }
  };

  const openMaker = () => {
    setVisible({ ...visible, marker: !visible?.marker });
  };
  const viewDetail = () => {
    setVisible({ ...visible, open: !visible?.open });
  };

  return (
    <React.Fragment>
      <MainLabInterfaceGroupProvider
        value={{
          labInterfaceGroupOne: labInterfaceGroupOne,
          labInterfaceGroupTwo: labInterfaceGroupTwo,
          draftOrderList: draftOrderList?.data,
          labInterface: labInterface,
          setLabInterface: setLabInterface,
          patient: appointmentById?.patient,
          patientID: patientID,
          eventId: appointmentById?.visit?.eventId || 0,
          visitId: visitId,
        }}
      >
        <Card
          className="bg-grey m-1-doctor-room   "
          extra={
            <>
              <Row
                gutter={[14, 14]}
                className="text-row-profile ml-05"
                align="middle"
              >
                <Col>
                  <ImagePatient src={appointmentById?.patient?.image} />
                </Col>
                <Col>
                  <Row>
                    <Col xs={24} lg={24}>
                      {appointmentById
                        ? i18n.language === "th"
                          ? `${appointmentById?.patient?.firstNameTh}   ${appointmentById?.patient?.lastNameTh} `
                          : `${appointmentById?.patient?.firstNameEn}   ${appointmentById?.patient?.lastNameEn} `
                        : ``}
                    </Col>

                    <Col xs={24} lg={24}>
                      <div className="code-profile">
                        {" "}
                        HN :{" "}
                        {appointmentById
                          ? `${appointmentById?.patient?.hn}   `
                          : ``}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col style={{ marginLeft: "auto" }}>
                  <div style={{ color: "#FEB139" }}>
                    <SymbolView />
                  </div>
                  <div
                    style={{
                      color: "red",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    {appointmentById?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                      ? "G6PD Deficiency"
                      : null}
                  </div>
                </Col>
              </Row>
            </>
          }
        >
          <div className="mb-05">
            <span className=" t-blue fs-18 ">{t("fullList")} </span>
            <span className=" t-grey-light  fs-12 ">
              {`${t("orderCreationDate")}:`}{" "}
            </span>
            <span className="t-grey fs-12 ">
              {" "}
              {appointmentById
                ? `${moment(appointmentById?.date).format("DD-MM-YYYY")}`
                : ``}
            </span>
          </div>
          <Form name="basicInfoForm" form={patientList}>
            <ImportantInfo />
          </Form>
          <Advice appointmentsByID={appointmentById} />

          <Note
            title={t("remarks")}
            name="labNote"
            visitsByID={appointmentById?.visit}
            medicalRecord={medicalRecord?.[0]?.isEdit}
            status="appointment"
          />

          <OrderOutType
            patientID={patientID}
            draftOrderList={draftOrderList?.data?.result?.[0]}
            medicalRecord={medicalRecord?.[0]?.isEdit}
            eventId={appointmentById?.visit?.eventId || 0}
          />

          <OrderOneDayList
            patientID={patientID}
            draftOrderList={draftOrderList?.data?.result?.[0]?.one?.groups}
            selected={selected}
            totalAndQuantity={totalAndQuantity}
            medicalRecord={medicalRecord?.[0]?.isEdit}
            eventId={appointmentById?.visit?.eventId || 0}
          />

          <OrderContinuousList
            patientID={patientID}
            draftOrderList={
              draftOrderList?.data?.result?.[0]?.continuous?.groups
            }
            selected={selected}
            totalAndQuantity={totalAndQuantity}
            medicalRecord={medicalRecord?.[0]?.isEdit}
            eventId={appointmentById?.visit?.eventId || 0}
          />

          <RejectGroupOrder />

          <div className="mt-2 ">
            <LabInterface
              title="LabInterface Group One"
              room={ENUM_ROOM_SIGNATURE.PA_ROOM}
            />
          </div>

          <TransferComponent
            patientID={patientID}
            draftOrderList={draftOrderList?.data?.result?.[0]}
            title="LabInterface Other One"
            editRecord={medicalRecord?.[0]?.isEdit}
            type={ORDER_TYPE.LAB_INTERFACE_OTHER_ONE}
            eventId={appointmentById?.visit?.eventId || 0}
            room={ENUM_ROOM_SIGNATURE.PA_ROOM}
          />

          <div className="mt-2 ">
            <LabInterfaceGroup
              title="LabInterface Group Two"
              room={ENUM_ROOM_SIGNATURE.PA_ROOM}
            />
          </div>

          <TransferComponent
            patientID={patientID}
            draftOrderList={draftOrderList?.data?.result?.[0]}
            title="LabInterface Other Two"
            editRecord={medicalRecord?.[0]?.isEdit}
            type={ORDER_TYPE.LAB_INTERFACE_OTHER_TWO}
            eventId={appointmentById?.visit?.eventId || 0}
            room={ENUM_ROOM_SIGNATURE.PA_ROOM}
          />
          <MainMedicineDoctorRoomProvider
            value={{
              medicalRecord: medicalRecord?.[0]?.isEdit,
              title: "returnMedicine.title",
              buttonName: "editMedicineReturn",
              buttonAdd: "addMedicineReturn",
              key: "key",
              status: "return_drug",
              eventId: appointmentById?.visit?.eventId || 0,
              drugStatus: DRUG_STATUS.CONTINUOUS,
            }}
          >
            <ReturnMedicine />
          </MainMedicineDoctorRoomProvider>

          <MainMedicineDoctorRoomProvider
            value={{
              medicalRecord: medicalRecord?.[0]?.isEdit,
              title: "changeDoseMedication",
              buttonName: "editDrugLabelChangeList",
              buttonAdd: "addDrugLabelChangeList",
              key: "id",
              status: "change_drug_label",
              eventId: appointmentById?.visit?.eventId || 0,
              draftOrderList: draftOrderList?.data?.result?.[0],
              drugStatus: DRUG_STATUS.HOLDING,
            }}
          >
            <EditMedicine />
          </MainMedicineDoctorRoomProvider>

          <MainMedicineDoctorRoomProvider
            value={{
              medicalRecord: medicalRecord?.[0]?.isEdit,
              title: "drugStatusUpdate",
              buttonName: "drugStatusUpdate",
              key: "id",
              status: "change_drug_label",
              eventId: appointmentById?.visit?.eventId || 0,
              draftOrderList: draftOrderList?.data?.result?.[0],
              drugStatus: DRUG_STATUS.HOLDING,
              drug: true,
            }}
          >
            <UpdateStatusMedicine />
          </MainMedicineDoctorRoomProvider>

          <div className="mt-2 ">
            <Checkbox checked={visible?.open} onChange={viewDetail}>
              <span className="f-normal t-dark-blue">{t("physicalData")}</span>
            </Checkbox>
          </div>

          <div className={visible?.open ? "open" : "close"}>
            <Row className="header-table mt-2">
              <Col lg={18} className="f-normal col-start t-dark-blue">
                <span className="f-bold pr-05 ">{t("physicalData")}</span>
              </Col>
              {medicalRecord?.[0]?.isEdit ? (
                <Col lg={6} className="col-end">
                  {appointmentById?.visit?.visitMarker?.doctorMarker0 ||
                    appointmentById?.visit?.visitMarker?.doctorMarker1 ||
                    appointmentById?.visit?.visitMarker?.doctorMarker2 ||
                    appointmentById?.visit?.visitMarker?.doctorMarker3 ? (
                    <Button type="primary" onClick={openMaker}>
                      {t("edit")}
                    </Button>
                  ) : null}
                </Col>
              ) : null}
            </Row>
            {appointmentById?.visit?.visitMarker?.doctorMarker0 ||
              appointmentById?.visit?.visitMarker?.doctorMarker1 ||
              appointmentById?.visit?.visitMarker?.doctorMarker2 ||
              appointmentById?.visit?.visitMarker?.doctorMarker3 ? (
              <ViewMarker appointmentsByID={appointmentById} />
            ) : (
              <div className="border-note p-1">
                <Empty
                  image={empty}
                  imageStyle={{
                    height: 60,
                  }}
                  description={
                    <span className="t-grey-white">{`${t(
                      "addPhysicalPoint"
                    )}`}</span>
                  }
                >
                  {medicalRecord?.[0]?.isEdit ? (
                    <Button type="primary" onClick={openMaker}>
                      + {t("addPhysicalPoint")}
                    </Button>
                  ) : null}
                </Empty>
              </div>
            )}
          </div>
        </Card>
      </MainLabInterfaceGroupProvider>

      {visible?.marker && (
        <ModalMarker
          visible={visible?.marker}
          onModal={openMaker}
          appointmentsByID={appointmentById}
        />
      )}
    </React.Fragment>
  );
};

export default Fields;
