import React, { useEffect, useRef, useState } from "react";
import { ISignature } from "@interface/IGlobal.interface";
import { doctorroom } from "@assets/data/pathRouter";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { IResultLabInterface } from "@interface/IProduct.interface";
import { useGetDraftOrderList } from "@services/api/draft_order";
import { useUpdateAppointments } from "@services/api/appointments";
import { useGetAppointmentByID } from "@services/api/appointments";
import { KeyNameSignatureFirst } from "@interface/IGlobal.interface";
import { useCreateSignatureRoom } from "@services/api/signature_room";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Col, Drawer, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetOrderListOutstanding } from "@services/api/order";
import { keyNameProductLabInterface } from "@interface/IProduct.interface";
import { useIsMutating, useQueryClient } from "react-query";
import Modaler from "@components/modaler/Modaler";
import Affixes from "@components/affixes/Affixes";
import BackHome from "@components/button/BackHome";
import ModalInfo from "@components/signature/ModalInfo";
import RoleContext from "@provider/RoleProvider";
import ListAndHistory from "@components/list_history/ListHistory";
import FormCreateOrder from "./form/Form";
import MainPatientByIdProvider from "@provider/patient_by_id";
import MainLabInterfaceGroupProvider from "@provider/lab_inteface_group";
import MainProductOutStandingProvider from "@provider/product_out_standing";

const CreateOrder = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const roleList = React.useContext(RoleContext);
  const patientID = match.params.patientsID;
  const outStanding = useGetOrderListOutstanding(patientID);
  const createSignatureRoom = useCreateSignatureRoom();
  const labInterfaceGroupOne = useRef([]);
  const labInterfaceGroupTwo = useRef([]);
  const appointmentsID = match.params.appointmentsID;
  const appointmentByID = useGetAppointmentByID(
    !appointmentsID && appointmentsID !== 0 ? false : true,
    appointmentsID
  );
  const draftOrderList = useGetDraftOrderList(
    patientID,
    appointmentByID?.data?.result?.[0]?.visit?.eventId || 0
  );
  const updateAppointments = useUpdateAppointments();
  const [open, setOpen] = useState(false);
  const client = useQueryClient();
  const isMutatingPosts = useIsMutating(["update-appointment"]);
  const [visible, setVisible] = useState({
    modal: false,
    drawer: false,
    outType: false,
    first: false,
  });
  const [labInterface, setLabInterface] = useState<{
    [K in keyNameProductLabInterface]: IResultLabInterface[];
  }>({
    testCode: [],
    hematology: [],
    microbiology: [],
    coagulation: [],
    urine: [],
    biochemistry1: [],
    biochemistry2: [],
    groupData: [],
    groupTwo: [],
  });
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "MEDICAL";
  });

  useEffect(() => {
    if (
      appointmentByID?.data?.result?.[0]?.visit?.visitMarker?.doctorMarker0 ||
      appointmentByID?.data?.result?.[0]?.visit?.visitMarker?.doctorMarker1 ||
      appointmentByID?.data?.result?.[0]?.visit?.visitMarker?.doctorMarker2 ||
      appointmentByID?.data?.result?.[0]?.visit?.visitMarker?.doctorMarker3
    ) {
      setOpen(true);
    }

    // eslint-disable-next-line
  }, [appointmentByID?.data]);

  const confirmDraftOrder = () => {
    const { outType, one, continuous, labInterface } =
      draftOrderList?.data?.result?.[0] || {};
    if (outType?.groups?.length) {
      onModalOutType();
    } else if (
      one?.groups?.length ||
      continuous?.groups?.length ||
      labInterface?.other1?.length ||
      labInterface?.other2?.length
    ) {
      confirmVisitAndAppointments();
    } else {
      onModalCancel();
    }
  };

  const confirmVisitAndAppointments = async () => {
    await Promise.all([handleUpdateStatusAppointments()])
      .then(([_]) => {})
      .catch((err) =>
        openNotification({ method: "PUT_ERROR", message: String(err) })
      );
  };

  const updateSignature = (
    values: { date: string; title: KeyNameSignatureFirst } & ISignature
  ) => {
    const file = dataUrlToFile(
      values?.firstSignature,
      `doctorSignature${new Date().getTime()}.png`
    );
    if (file) {
      let formData = new FormData();
      formData?.append("signature", file);
      formData?.append("room", ENUM_ROOM_SIGNATURE.DOCTOR_ROOM);

      createSignatureRoom.mutate(
        { data: formData, visitId: visitID },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
            client.invalidateQueries(["get-appointment-byID", appointmentsID]);
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            setVisible({
              ...visible,
              [values?.title]: !visible[values?.title],
            });
          },
        }
      );
    } else {
      setVisible({
        ...visible,
        [values?.title]: !visible[values?.title],
      });
    }
  };

  const handleUpdateStatusAppointments = () => {
    const data = {
      status: "ADVISED",
    };
    updateAppointments.mutate(
      { data: data, id: appointmentsID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
        onSettled: () => {
          history.replace(doctorroom);
        },
      }
    );
  };

  const onModalCancel = () => {
    setVisible({ ...visible, modal: !visible.modal });
  };

  const onModalOutType = () => {
    setVisible({ ...visible, outType: !visible.outType });
  };

  const onConfirmCourse = () => {
    confirmVisitAndAppointments();

    setVisible({ ...visible, modal: !visible.modal });
  };

  const showDrawer = () => {
    setVisible({ ...visible, drawer: !visible.drawer });
  };

  return (
    <MainPatientByIdProvider
      value={{ patientById: appointmentByID?.data?.result?.[0]?.patient }}
    >
      <MainProductOutStandingProvider
        value={{ outStanding: outStanding?.data?.result }}
      >
        <MainLabInterfaceGroupProvider
          value={{
            labInterfaceGroupOne: labInterfaceGroupOne,
            labInterfaceGroupTwo: labInterfaceGroupTwo,
            draftOrderList: draftOrderList?.data,
            labInterface: labInterface,
            setLabInterface: setLabInterface,
            patient: appointmentByID?.data?.result?.[0]?.patient,
            patientID: patientID,
            eventId: appointmentByID?.data?.result?.[0]?.visit?.eventId || 0,
          }}
        >
          <div>
            <Modaler
              visible={visible.modal}
              onCancel={onModalCancel}
              onOk={onConfirmCourse}
              width="45%"
              header={
                <div>
                  <QuestionCircleOutlined className="icon-warning-cancel" />
                  {`${t("areYouSure")}`}
                </div>
              }
            >
              {t("youDidNotSelectOneDayOrContinuous")}
            </Modaler>
            <Modaler
              visible={visible.outType}
              onCancel={onModalOutType}
              onOk={onModalOutType}
              width="45%"
              header={
                <div>
                  <QuestionCircleOutlined className="icon-warning-cancel" />
                  {`${t("areYouSure")}`}
                </div>
              }
            >
              {t("selectOneDayOrContinuous")}
            </Modaler>
            <Row
              className="second-header "
              justify="space-between"
              align="middle"
            >
              <Col>
                <Row gutter={[14, 14]}>
                  <BackHome history={history} />

                  <Col className="header-text">
                    <div>
                      <h1>{t("doctorroom")}</h1>
                    </div>
                    <span className="t-light-gray">{`${t("nursery")} / ${t(
                      "counsel"
                    )} / `}</span>
                    <span className="t-dark-blue f-normal">
                      {t("createOrder")}
                    </span>
                  </Col>
                </Row>
              </Col>
              {medicalRecord?.[0]?.isEdit ? (
                <Col style={{ marginLeft: "auto" }}>
                  <Button className="cancel" onClick={history.goBack}>
                    {t("cancel")}
                  </Button>
                  {visitID && visitID !== "undefined" ? (
                    <Button
                      className="btn-create ml-05"
                      onClick={() =>
                        !!appointmentByID?.data?.result?.[0]?.visit?.isSignature
                          ?.doctorRoom
                          ? setVisible({ ...visible, first: !visible.first })
                          : confirmDraftOrder()
                      }
                      loading={isMutatingPosts ? true : false}
                    >
                      {t("save")}
                    </Button>
                  ) : null}
                </Col>
              ) : null}
            </Row>
            <div className="set-layout  body-height">
              <Row gutter={[14, 14]}>
                <Col md={24} lg={18}>
                  <FormCreateOrder
                    draftOrderList={draftOrderList?.data?.result?.[0]}
                    appointmentByID={appointmentByID?.data?.result?.[0]}
                    open={{ open: open, setOpen: setOpen }}
                    labInterface={{
                      labInterface: labInterface,
                      setLabInterface: setLabInterface,
                    }}
                  />
                </Col>

                <Col lg={6} className="card-display">
                  <ListAndHistory />
                </Col>
              </Row>
              <Affixes onClick={showDrawer} />

              <Drawer
                width={"50%"}
                closable={false}
                placement="right"
                onClose={showDrawer}
                visible={visible.drawer}
              >
                <ListAndHistory status="noCard" />
              </Drawer>
            </div>
            {visible.first && (
              <ModalInfo
                visible={visible}
                setVisible={setVisible}
                date={""}
                name="first"
                title={t("doctorSignature")}
                updateSignature={updateSignature}
                isLoading={createSignatureRoom?.isLoading}
              />
            )}
          </div>
        </MainLabInterfaceGroupProvider>
      </MainProductOutStandingProvider>
    </MainPatientByIdProvider>
  );
};

export default CreateOrder;
