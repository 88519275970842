import { IItem } from "@interface/IOrder.interface";
import { _isEmpty, handleMassageError } from "@tools/util";
import { thrownResponse } from "./auth";
import { IGetPatientList } from "@interface/IOpd.interface";
import { IGetVisits, IVisitDrug } from "../../interface/IVisits.interface";
import { useQuery, UseQueryResult } from "react-query";
import { IOrderHistory, IVisitStatus } from "@interface/IVisits.interface";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";
import { openNotification } from "@components/notification/Notification";
import { EVENT_ROOM } from "@tools/util.enum";

export const useCreateVisit = (): UseMutationResult<
  ElementaryOneMore<IGetVisits>
> => {
  return useMutation(async (data: any) => {
    const res = await axios.post(`/visit`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitList = (
  params?: any
): UseQueryResult<Elementary<IGetVisits[]>> => {
  return useQuery(
    ["get-visits-list", params],
    async () => {
      const res = await axios.get(`/visit`, { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { refetchOnWindowFocus: true }
  );
};

export const useGetVisitOrderList = (
  params?: any
): UseQueryResult<Elementary<IGetVisits[]>> => {
  return useQuery(
    ["get-visit-order-list", params],
    async () => {
      const res = await axios.get(`/visit/order`, { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { refetchOnWindowFocus: true }
  );
};

export const useGetVisitOrderHistory = (
  id: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IOrderHistory>> => {
  return useQuery(["get-visit-order-history", params], async () => {
    const res = await axios.get(`/visit/order-history/${id}`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetLAbInterfaceHistory = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetVisits[]>> => {
  return useQuery(["get-visits-lab-interface", id, params], async () => {
    const res = await axios.get(`/visit/lab-interface/${id}`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitsByID = (id?: number): UseQueryResult<IGetVisits> => {
  return useQuery(
    ["get-visitsByID", id],
    async () => {
      const res = await axios.get(`/visit/${id}`);
      if (res?.status >= 200 && res?.status < 300) {
        return res?.data?.result?.[0];
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data?.result?.[0];
      }
      thrownResponse(res);
    },
    { enabled: !_isEmpty(id) }
  );
};

export const useUpdateVisits = () => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/visit/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateVisitsDoctorSignature = () => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/visit/${id}/doctor/signature`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateVisitsPASignature = () => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/visit/${id}/pa/signature`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitsStatus = (
  id?: number
): UseQueryResult<ElementaryOneMore<IVisitStatus>> => {
  return useQuery(
    ["get-visit-status", id],
    async () => {
      const res = await axios.get(`/visit/check-visit/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !_isEmpty(id) }
  );
};

export const useHandleGetVisitReportDrug = (
  id?: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IVisitDrug>> => {
  return useQuery(["get-visit-report-drug", id, params], async () => {
    const res = await axios.get(`/visit/${id}/report-drug`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitRejectDrug = (
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visitsByID", params], async () => {
    const res = await axios.get(`/visit/reject/drugs`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else {
      return res?.data;
    }
  });
};

export const useGetVisitReportTreatment = (
  id?: number
): UseQueryResult<IVisitDrug> => {
  return useQuery(["get-visitsByID", id], async () => {
    const res = await axios.get(`/visit/${id}/report-treatment`);

    if (res?.status >= 200 && res?.status < 300) {
      const dataWorkOrderItem = res?.data?.result?.[0]?.workOrderItem?.map(
        (item: any) => {
          const dataCourseChildren = item?.items?.flatMap(
            (item: IItem) => item?.courseItems
          );

          const dataItem = item?.items?.filter(
            (item: IItem) =>
              item?.product?.category?.id === "C-0001" ||
              item?.product?.room === EVENT_ROOM.NURSE
          );
          return {
            ...item,
            items: [...(dataItem || []), ...(dataCourseChildren || [])],
          };
        }
      );

      return {
        ...res?.data?.result?.[0],
        workOrderItem: dataWorkOrderItem,
      };
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useAddMarker = (): UseMutationResult => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.post(`/visit/add/marker/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateMarker = (): UseMutationResult => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/visit/update/marker/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateSignature = (): UseMutationResult => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/visit/${id}/signature`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
