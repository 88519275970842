import { t } from "i18next";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import send from "@assets/img/icon/send.svg";
import Modaler from "@components/modaler/Modaler";

type Props = {
  visible: boolean;
  handleOk: () => void;
  handleCancel: () => void;
  title?: string;
  loadingButton?: boolean;
};

const ModalSent = ({
  visible,
  handleOk,
  handleCancel,
  title = `${t("confirm")} ${t("sendToProcessOrder")}`,
  loadingButton = false,
}: Props) => {
  return (
    <div>
      <Modaler
        loadingButton={loadingButton}
        visible={visible}
        onCancel={handleCancel}
        onOk={handleOk}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {t("areYouSure")}
          </div>
        }
      >
        <div className="send-center">
          <div className="send-container">
            <img className="send-image" src={send} alt="logo" />
          </div>
        </div>
        <div className="send-text">
          <p>{title} </p>
        </div>
      </Modaler>
    </div>
  );
};

export default ModalSent;
