import { IVisitDrug } from "@interface/IVisits.interface";
import { thrownResponse } from "./auth";
import { IGetPatientList } from "@interface/IOpd.interface";
import { useQuery, UseQueryResult } from "react-query";
import { IGetVisits, IVisitBiology } from "@interface/IVisits.interface";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import axios from "@axios/index";

export const useGetVisitPhysicalOrder = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-physical-order", id, params], async () => {
    const res = await axios.get(`/visit/${id}/physical-order`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitVitalSign = (
  id: number,
  params?: { date?: string; orderby?: string; page: number; limit: number }
): UseQueryResult<Elementary<IGetVisits[]>> => {
  return useQuery(["get-visit-vital-sign", id, params], async () => {
    const res = await axios.get(`/visit/${id}/vital-sign`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitLabResult = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-lab-result", id, params], async () => {
    const res = await axios.get(`/visit/${id}/lab-result`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitRadiologyReport = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-radiology-report", id, params], async () => {
    const res = await axios.get(`/visit/${id}/radiology-report`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitMedicalReport = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-medical-report", id, params], async () => {
    const res = await axios.get(`/visit/${id}/medical-report`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitReportDrug = (
  id: number,
  params?: any
): UseQueryResult<ElementaryOneMore<IVisitDrug>> => {
  return useQuery(["get-visit-report-drug", id, params], async () => {
    const res = await axios.get(`/visit/${id}/report-drug`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitBiology = (
  id: number,
  params?: any
): UseQueryResult<IVisitBiology[]> => {
  return useQuery(["get-visit-biology", id, params], async () => {
    const res = await axios.get(`/visit/${id}/biology`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return !!res?.data?.result?.[0]?.data?.length
        ? res?.data?.result?.[0]?.data
        : [{}];
    } else if (res?.status >= 400 && res?.status < 500) {
      return [{}];
    }
    thrownResponse(res);
  });
};

export const useGetVisitMedicalSummery = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-medical-summery", id, params], async () => {
    const res = await axios.get(`/visit/${id}/medical-summery`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitTreatment = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-treatment", id, params], async () => {
    const res = await axios.get(`/visit/${id}/treatment`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitPhysical = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-physical", id, params], async () => {
    const res = await axios.get(`/visit/${id}/physical`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitConsent = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-consent", id, params], async () => {
    const res = await axios.get(`/visit/${id}/consent`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitCorrespondence = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-visit-correspondence", id, params], async () => {
    const res = await axios.get(`/visit/${id}/correspondence`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
