import React, { useState } from "react";
import { signCourse } from "@assets/data/pathRouter";
import { ISelectedList } from "@interface/IOrder.interface";
import { ISelectPatient } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { Col, Drawer, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { useGetPatientByHistory } from "@services/api/patient";
import { useCreateAssignCourses } from "@services/api/order";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetOrderListOutstandingCourse } from "@services/api/order";
import Fields from "./Fields";
import Header from "./Header";
import Affixes from "@components/affixes/Affixes";
import RoleContext from "@provider/RoleProvider";
import ListHistory from "@components/list_history/ListHistory";
import ListHistoryCheckbox from "@components/list_history_checkbox/ListHistoryCheckbox";
import MainProductOutStandingProvider from "@provider/product_out_standing";

const SignCourseDetail = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const [visible, setVisible] = useState<boolean>(false);
  const patientByID = useGetPatientByHistory(patientID);
  const [orderList, setOrderList] = useState<ISelectedExecuteOrder[]>([]);
  const [selection, setSelection] = useState<ISelectedList>({
    course: [],
    list: [],
    oldValueList: [],
    oldValue: [],
    expandAllRows: [],
  });
  const [selectPatient, setSelectPatient] = useState<ISelectPatient>({
    id: null,
    validateStatus: "",
    errorMsg: null,
  });
  const outStanding = useGetOrderListOutstandingCourse(patientID);
  const signAssignCourse = useCreateAssignCourses();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "ASSIGN_COURSE";
  });

  const showDrawer = () => {
    setVisible(!visible);
  };

  const confirmSignCourse = () => {
    if (selectPatient?.id !== null && selectPatient?.id !== undefined) {
      const result = selection?.course?.map((item) => {
        return { uuid: item };
      });

      signAssignCourse.mutate(
        {
          data: result,
          id: selectPatient?.id,
        },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
            history.replace(signCourse);
          },
          onError: () => {
            openNotification({ method: "POST_ERROR" });
          },
        }
      );
    } else {
      setSelectPatient({
        ...selectPatient,
        validateStatus: "error",
        errorMsg: `${t("pleaseSelectACourseRecipient")}`,
      });
    }
  };

  return (
    <MainProductOutStandingProvider
      value={{ outStanding: outStanding?.data?.result }}
    >
      <React.Fragment>
        <Header
          history={history}
          confirmSignCourse={confirmSignCourse}
          medicalRecord={medicalRecord?.[0]?.isEdit}
        />
        <div className="set-layout body-height ">
          <Row gutter={[14, 14]}>
            <Col md={24} lg={18}>
              <Fields
                selectPatient={{
                  selectPatient: selectPatient,
                  setSelectPatient: setSelectPatient,
                }}
                patientByID={patientByID?.data}
                orderList={orderList}
                selection={{
                  selection: selection,
                  setSelection: setSelection,
                }}
                medicalRecord={medicalRecord?.[0]?.isEdit}
              />
            </Col>

            <Col lg={6} className="card-display">
              {medicalRecord?.[0]?.isEdit ? (
                <ListHistoryCheckbox
                  setOrderList={setOrderList}
                  status="signcourse"
                  selection={{
                    selection: selection,
                    setSelection: setSelection,
                  }}
                />
              ) : (
                <ListHistory />
              )}
            </Col>
          </Row>
          <Affixes onClick={showDrawer} />

          <Drawer
            width={"50%"}
            closable={false}
            placement="right"
            onClose={showDrawer}
            visible={visible}
          >
            {medicalRecord?.[0]?.isEdit ? (
              <ListHistoryCheckbox
                setOrderList={setOrderList}
                status="signcourse"
                selection={{
                  selection: selection,
                  setSelection: setSelection,
                }}
                noCard="noCard"
              />
            ) : (
              <ListHistory status="noCard" />
            )}
          </Drawer>
        </div>
      </React.Fragment>
    </MainProductOutStandingProvider>
  );
};

export default SignCourseDetail;
