import { FC } from "react";
import { FormInstance } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useUpdateDocumentName } from "@services/api/media_object";
import { Button, Col, Divider, Form, Input, Modal, Row } from "antd";

type Props = {
  isModalOpen: boolean;
  onManageModal: (e: boolean, upData?: boolean) => void;
  form: FormInstance;
};

const EditFileName: FC<Props> = ({ isModalOpen, onManageModal, form }) => {
  const { t } = useTranslation();
  const editName = useUpdateDocumentName();

  const onFinish = (value: { name: string; id: number; lastName: string }) => {
    editName.mutate(
      {
        id: Number(value?.id),
        data: {
          name: `${value?.name}${value?.lastName || ""}`,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
        onSettled: () => {
          onManageModal(false, true);
        },
      }
    );
  };
  return (
    <Modal
      className="manage-modal"
      title={t("editFileName")}
      centered
      visible={isModalOpen}
      onCancel={() => onManageModal(false)}
      footer={null}
    >
      <Form onFinish={onFinish} className="p-2" name="form" form={form}>
        <Form.Item name="lastName" hidden>
          <Input />
        </Form.Item>
        <Row gutter={[14, 14]} className="margin-form">
          <Col span={24}>
            <Form.Item name="name">
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
      </Form>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button
                className="cancel "
                key="back"
                onClick={() => onManageModal(false)}
              >
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => form.submit()}
                loading={editName.isLoading}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default EditFileName;
