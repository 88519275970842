import { useState } from "react";
import { Col, Row } from "antd";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ISearchSlots } from "./interface";
import { useTranslation } from "react-i18next";
import { useGetRoomList } from "@services/api/rooms";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Search from "./Search";
import BackHome from "@components/button/BackHome";
import Overview from "@components/slots/overview/Overview";

const SlotsViewAll = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const roomList = useGetRoomList({ limit: 0, orderby: ENUM_ORDERBY.ASC });
  const [search, setSearch] = useState<ISearchSlots>();

  const onFinish = (value: ISearchSlots) => {
    setSearch(value);
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("booking.anotherBooking")}</h1>
              </div>
              <span className="t-light-gray">{t("appointment")} / </span>
              <span className="t-dark-blue f-normal">{t("seeOverview")}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout body-height  ">
        <Search onFinish={onFinish} />
        <Overview
          roomList={roomList?.data?.result?.[0]?.data || []}
          slotType={0}
          search={search}
        />
      </div>
    </div>
  );
};

export default SlotsViewAll;
