import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { tabsGeneralState } from "@tools/recoil/recoil-atom";
import { createNationality } from "@assets/data/pathRouter";
import { createPrefix, SETTING } from "@assets/data/pathRouter";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { createCitizenship, createCountry } from "@assets/data/pathRouter";
import React from "react";
import TabsPrefix from "./Tabs";
import RoleContext from "@provider/RoleProvider";

const General = () => {
  const { t } = useTranslation();
  const [tabsGeneral, setTabsGeneral] = useRecoilState(tabsGeneralState);
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "GENERAL";
  });

  const obj: { [key: string]: { path: any } } = {
    1: { path: createPrefix },
    2: { path: createNationality },
    3: { path: createCitizenship },
    4: { path: createCountry },
    5: { path: SETTING.contractCompany },
    6: { path: SETTING.agent },
  };

  const handleCreateGeneral = () => {
    history.push(obj[tabsGeneral].path);
  };
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("setting")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("generalInfo.title")}</b>
          </span>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col lg={2} className="justify-col">
            <Button
              className="btn-create"
              type="primary"
              onClick={handleCreateGeneral}
            >
              <PlusOutlined className="icons-size" />{" "}
              {`${t("create")} ${t("generalInfo.title")}`}
            </Button>
          </Col>
        ) : null}
      </Row>
      <div className="set-layout ">
        <Card className="bg-grey ">
          <TabsPrefix
            tabsGeneral={tabsGeneral}
            setTabsGeneral={setTabsGeneral}
          />
        </Card>
      </div>
    </div>
  );
};

export default General;
