import { Form } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Header from "./Header";
import FormOtherInfo from "./form/Form";

const PatientOtherInfo = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const [otherInfoForm] = Form.useForm();
  const match: any = useRouteMatch();
  const pathID = match.params.id;

  return (
    <div>
      <Header otherInfoForm={otherInfoForm} history={history} />
      <FormOtherInfo
        otherInfoForm={otherInfoForm}
        history={history}
        pathID={pathID}
      />
    </div>
  );
};

export default PatientOtherInfo;
