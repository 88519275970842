import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useGetDashboard } from "@services/api/dashboard";
import { Card, Col, Row } from "antd";
import Search from "./Search";
import numeral from "numeral";
import Cashier from "./cashier/Cashier";
import Overall from "@assets/img/icon/overall.svg";
import ChartLine from "./ChartLine";
import TableList from "./table_list/TableList";
import OldCaretaker from "@assets/img/icon/OldCaretaker.svg";
import ChartDoughnut from "./ChartDoughnut";
import NewCaregivers from "@assets/img/icon/NewCaregivers.svg";
import CardTotalRoom from "./card/CardTotalRoom";
import MainDashboardContext from "@provider/dashboard";
import "dayjs/locale/th";

const Dashboard = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState();
  const dataDashboardList = useGetDashboard(search);

  return (
    <MainDashboardContext
      value={{
        ...dataDashboardList?.data,
        search: search,
        setSearch: setSearch,
      }}
    >
      <div>
        <Row className="second-header " justify="space-between" align="middle">
          <Col>
            <div>
              <h1>{t("dashboard.title")}</h1>
            </div>

            <span className="t-dark-blue f-normal">
              <b>{t("dailySchedule")}</b>
            </span>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <Search />
          </Col>
        </Row>

        <div className="set-layout ">
          <Row gutter={[14, 14]}>
            <Col md={24} lg={18}>
              <Row gutter={[15, 15]}>
                <Col lg={8} md={8}>
                  <Card
                    className="card-dashboard t-white"
                    style={{ backgroundColor: "#004C97", height: "100%" }}
                  >
                    <Row>
                      <Col
                        span={24}
                        className="align-self-center"
                        style={{ alignContent: "space-around" }}
                      >
                        <Row align="middle">
                          <Col span={12}>
                            <p>{t("newCaretaker")}</p>
                            <img
                              src={NewCaregivers}
                              alt="Overall"
                              width={40}
                              height={30}
                            />
                          </Col>

                          <Col
                            span={12}
                            className="t-white fs-24 f-bold col-end "
                          >
                            {`${numeral(
                              dataDashboardList?.data?.newPatient || 0
                            ).format("0,0")}`}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col lg={8} md={8}>
                  <Card
                    className="card-dashboard t-white"
                    style={{ backgroundColor: "#498DCB", height: "100%" }}
                  >
                    <Row>
                      <Col
                        span={24}
                        className="align-self-center"
                        style={{ alignContent: "space-around" }}
                      >
                        <Row align="middle">
                          <Col span={12}>
                            <p>{t("oldCaretaker")}</p>
                            <img
                              src={OldCaretaker}
                              alt="Overall"
                              width={25}
                              height={30}
                            />
                          </Col>

                          <Col
                            span={12}
                            className="t-white fs-24 f-bold col-end "
                          >
                            {`
                          ${numeral(
                            dataDashboardList?.data?.oldPatient || 0
                          ).format("0,0")}
                          
                          `}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col lg={8} md={8}>
                  <Card
                    className="card-dashboard t-white"
                    style={{ backgroundColor: "#77C48B", height: "100%" }}
                  >
                    <Row>
                      <Col
                        span={24}
                        className="align-self-center"
                        style={{ alignContent: "space-around" }}
                      >
                        <Row align="middle">
                          <Col span={12}>
                            <p>{t("total")}</p>
                            <img
                              src={Overall}
                              alt="Overall"
                              width={40}
                              height={30}
                            />
                          </Col>

                          <Col
                            span={12}
                            className="t-white fs-24 f-bold col-end "
                          >
                            {`
                           ${numeral(
                             dataDashboardList?.data?.sumPatient || 0
                           ).format("0,0")}
                          
                          `}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <div className="mt-15">
                <Row gutter={[15, 15]}>
                  <Col md={24} lg={16}>
                    <Card className="chart-line">
                      <ChartLine />
                    </Card>
                  </Col>
                  <Col md={24} lg={8}>
                    <Card className="chart-doughnut">
                      <ChartDoughnut />
                    </Card>
                  </Col>
                </Row>
              </div>

              <CardTotalRoom />
            </Col>
            <Col md={24} lg={6}>
              {/* {now.toString()} */}
              <Card className="cashier-card chart-doughnut">
                <Cashier />
              </Card>
            </Col>
          </Row>

          <div className="mt-15">
            <TableList />
          </div>
        </div>
      </div>
    </MainDashboardContext>
  );
};

export default Dashboard;
