import { gender } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Input, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  onFinish: (value: any) => void;
};

const Search = ({ onFinish }: Props) => {
  const { t, i18n } = useTranslation();
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col lg={9} md={6} xl={9}>
            <Form.Item name="search">
              <Input placeholder={`${t("nameSearch")} , ${t("code")}HN`} />
            </Form.Item>
          </Col>

          <Col xl={5} xxl={5}>
            <Form.Item name="gender">
              <SelectorDouble
                Source={gender}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("sex")}
                placeholder={t("all")}
              />
            </Form.Item>
          </Col>

          <Col md={3} lg={5} xl={5}>
            <Form.Item name="phone">
              <Input placeholder={t("phoneNumber")} />
            </Form.Item>
          </Col>
          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
