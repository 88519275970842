import React, { useState } from "react";
import { transferCourse } from "@assets/data/pathRouter";
import { ISelectPatient } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { Col, Drawer, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { IItem, ISelectedList } from "@interface/IOrder.interface";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { useGetPatientByHistory } from "@services/api/patient";
import { useCreateTransformCourses } from "@services/api/order";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetOrderListOutstandingCourse } from "@services/api/order";
import Fields from "./Fields";
import Header from "./Header";
import Affixes from "@components/affixes/Affixes";
import ListRecord from "@components/list_history/ListHistory";
import RoleContext from "@provider/RoleProvider";
import ListHistoryCheckbox from "@components/list_history_checkbox/ListHistoryCheckbox";
import MainProductOutStandingProvider from "@provider/product_out_standing";

const TransferCourseDetail = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const [visible, setVisible] = useState<boolean>(false);
  const patientByID = useGetPatientByHistory(patientID);
  const [orderList, setOrderList] = useState<ISelectedExecuteOrder[]>([]);
  const [selectPatient, setSelectPatient] = useState<ISelectPatient>({
    id: null,
    validateStatus: "",
    errorMsg: null,
  });
  const [selection, setSelection] = useState<ISelectedList>({
    course: [],
    list: [],
    oldValueList: [],
    oldValue: [],
    expandAllRows: [],
  });
  const outStanding = useGetOrderListOutstandingCourse(patientID);
  const transformCourse = useCreateTransformCourses();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "TRANSFER_COURSE";
  });

  const showDrawer = () => {
    setVisible(!visible);
  };

  const courseItem = (array1: any[], array2: any[]) => {
    return array1?.filter((item: IItem) => {
      return array2?.indexOf(item?.id) !== -1;
    });
  };

  const confirmTransferCourse = () => {
    if (selectPatient.id !== null && selectPatient.id !== undefined) {
      const items = orderList?.flatMap((item: ISelectedExecuteOrder) => {
        return item?.children?.[0]?.children
          ?.map((child: IItem) => {
            if (child?.children?.length > 0) {
              return child?.children?.[0]?.children?.map(
                (childChild: IItem) => {
                  return childChild;
                }
              );
            } else {
              return child;
            }
          })
          ?.flat();
      });

      const result = courseItem(items, selection?.course);

      transformCourse.mutate(
        {
          data: result?.map((item) => {
            return {
              uuid: item?.id,
              qty: item?.qtyList || item?.qtyOutstanding,
            };
          }),
          id: selectPatient?.id,
        },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
            history.replace(transferCourse);
          },
          onError: () => {
            openNotification({ method: "POST_ERROR" });
          },
        }
      );
    } else {
      setSelectPatient({
        ...selectPatient,
        validateStatus: "error",
        errorMsg: `${t("pleaseSelectACourseRecipient")}`,
      });
    }
  };

  return (
    <MainProductOutStandingProvider
      value={{ outStanding: outStanding?.data?.result }}
    >
      <React.Fragment>
        <Header
          history={history}
          confirmTransferCourse={confirmTransferCourse}
          medicalRecord={medicalRecord?.[0]?.isEdit}
        />
        <div className="set-layout body-height ">
          <Row gutter={[14, 14]}>
            <Col md={24} lg={18}>
              <Fields
                selectPatient={{
                  selectPatient: selectPatient,
                  setSelectPatient: setSelectPatient,
                }}
                patientByID={patientByID?.data}
                orderList={orderList}
                selection={{
                  selection: selection,
                  setSelection: setSelection,
                }}
                medicalRecord={medicalRecord?.[0]?.isEdit}
              />
            </Col>

            <Col lg={6} className="card-display">
              {medicalRecord?.[0]?.isEdit ? (
                <ListHistoryCheckbox
                  setOrderList={setOrderList}
                  status="transfercourse"
                  selection={{
                    selection: selection,
                    setSelection: setSelection,
                  }}
                />
              ) : (
                <ListRecord />
              )}
            </Col>
          </Row>
          <Affixes onClick={showDrawer} />

          <Drawer
            width={"50%"}
            closable={false}
            placement="right"
            onClose={showDrawer}
            visible={visible}
          >
            {medicalRecord?.[0]?.isEdit ? (
              <ListHistoryCheckbox
                setOrderList={setOrderList}
                status="transfercourse"
                selection={{
                  selection: selection,
                  setSelection: setSelection,
                }}
                noCard="noCard"
              />
            ) : (
              <ListRecord status="noCard" />
            )}
          </Drawer>
        </div>
      </React.Fragment>
    </MainProductOutStandingProvider>
  );
};

export default TransferCourseDetail;
