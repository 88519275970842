import { useState } from "react";
import { ISignature } from "@interface/IGlobal.interface";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { Button, Col, Row } from "antd";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { KeyNameSignatureFirst } from "@interface/IGlobal.interface";
import { useCreateSignatureRoom } from "@services/api/signature_room";
import { useIsMutating, useQueryClient } from "react-query";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";
import ModalInfo from "@components/signature/ModalInfo";

type Props = {
  history: RouteComponentProps["history"];
  handleCreateWorkOrderUse: () => void;
  handlePrint: () => void;
  handlePrintPhysical: () => void;
  medicalRecord: boolean;
  isSignature?: boolean;
};

const Header = ({
  history,
  handleCreateWorkOrderUse,
  handlePrint,
  handlePrintPhysical,
  medicalRecord,
  isSignature,
}: Props) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const visitId = match.params.visitID;
  const [visible, setVisible] = useState({ first: false });
  const createSignatureRoom = useCreateSignatureRoom();
  const client = useQueryClient();
  const loading = useIsMutating(["create-work-orders-use"]);

  const confirmWorkOrderStatus = () => {
    handleCreateWorkOrderUse();
  };

  const updateSignature = (
    values: {
      date: string;
      title: KeyNameSignatureFirst;
      doctorName?: string;
    } & ISignature
  ) => {
    const file = dataUrlToFile(
      values?.firstSignature,
      `physicalTherapyRoomSignature${new Date().getTime()}.png`
    );
    if (file) {
      let formData = new FormData();
      formData?.append("signature", file);
      formData?.append("room", ENUM_ROOM_SIGNATURE.PHYSICAL_THERAPY_ROOM);
      !!values?.doctorName &&
        formData?.append("doctorName", values?.doctorName);

      createSignatureRoom.mutate(
        { data: formData, visitId: visitId },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            client.invalidateQueries(["get-visitsByID", visitId]);
            setVisible({
              ...visible,
              [values?.title]: !visible[values?.title],
            });
          },
        }
      );
    } else {
      setVisible({
        ...visible,
        [values?.title]: !visible[values?.title],
      });
    }
  };

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]} align="middle">
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("physiotherapy.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-normal">
                {t("physiotherapy.title")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button className="reserve " onClick={handlePrintPhysical}>
            SOAP Note
          </Button>

          <Button className="reserve ml-05" key="back" onClick={handlePrint}>
            OPD Clinical Record
          </Button>
          {medicalRecord ? (
            <>
              <Button className="cancel ml-05 " onClick={history.goBack}>
                {t("cancel")}
              </Button>
              <Button
                className="btn-create ml-05 "
                loading={!!loading}
                onClick={() => {
                  !!isSignature
                    ? setVisible({ first: true })
                    : confirmWorkOrderStatus();
                }}
              >
                {t("confirm")}
              </Button>
            </>
          ) : null}
        </Col>
      </Row>
      {visible.first && (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="first"
          title={t("medicalAssistantSignature")}
          updateSignature={updateSignature}
          isLoading={createSignatureRoom?.isLoading}
        />
      )}
    </>
  );
};

export default Header;
