import React, { useEffect } from "react";
import { Age } from "@tools/age";
import { gender, symbol } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { useGetBranchList } from "@services/api/branch";
import { useMainTransferDetail } from "@provider/transfer_detail";
import { ENUM_G6PD, ENUM_ROLE_EN } from "@tools/util.enum";
import { Card, Col, Input, Row, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import UserContext from "@provider/UserProvider";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";

const Fields = () => {
  const { t, i18n } = useTranslation();
  const { form, patientIDByID, onFinish } = useMainTransferDetail();
  const user = React.useContext(UserContext);
  const branchList = useGetBranchList(
    user?.role === ENUM_ROLE_EN.SUPER_ADMIN ? true : false,
    {
      limit: 0,
      page: 1,
    }
  );

  useEffect(() => {
    let address: string = "";
    const mailingAddress = patientIDByID?.mailingAddress;
    const mailAddress =
      mailingAddress?.addressTh || mailingAddress?.districtTh
        ? [
            mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            mailingAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "districtTh" : "districtEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "provinceTh" : "provinceEn"
            ],
            mailingAddress?.zipcode,
            mailingAddress?.country,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const _homeAddress = patientIDByID?.homeAddress;
    const homeAddress =
      _homeAddress?.addressTh || _homeAddress?.districtTh
        ? [
            _homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            _homeAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            _homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
            _homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
            _homeAddress?.country,
            _homeAddress?.zipcode,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    if (
      patientIDByID?.mailingAddress?.addressTh ||
      patientIDByID?.mailingAddress?.districtTh
    ) {
      address = mailAddress;
    } else {
      address = homeAddress;
    }

    form.setFieldsValue({
      address: address ? address : "",
      age: patientIDByID?.dateOfBirth ? Age(patientIDByID?.dateOfBirth) : "",
      ...patientIDByID,
    });
    // eslint-disable-next-line
  }, [patientIDByID]);

  const SymbolView = () => {
    const dataSymbol = symbol?.filter((item) => {
      if (!patientIDByID?.symbol?.includes(Number(item?.id))) {
        return false;
      }
      return item;
    });

    return (
      <React.Fragment>
        {dataSymbol
          ?.map((item) =>
            i18n.language === "th" ? item?.["nameTh"] : item?.["nameEn"]
          )
          ?.join(" , ")
          ?.trim()}
      </React.Fragment>
    );
  };

  return (
    <Card
      className="bg-grey  height-body scrollbar-list "
      extra={
        <React.Fragment>
          <Row gutter={[14, 14]} className="text-row-profile">
            <Col>
              <ImagePatient src={patientIDByID?.image} />
            </Col>
            <Col>
              <Row>
                <Col xs={24} lg={24}>
                  {patientIDByID
                    ? i18n.language === "th"
                      ? `${patientIDByID?.firstNameTh}  ${patientIDByID?.lastNameTh}`
                      : `${patientIDByID?.firstNameEn}  ${patientIDByID?.lastNameEn}`
                    : " "}
                </Col>

                <Col xs={24} lg={24}>
                  <div className="code-profile">
                    HN : {patientIDByID ? patientIDByID?.hn : " "}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <div style={{ color: "gold" }}>
                <SymbolView />
              </div>
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {patientIDByID?.g6pd === ENUM_G6PD.DEFICIENCY
                  ? "G6PD Deficiency"
                  : null}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      }
    >
      <Row justify="center">
        <Form
          name="basicInfoForm"
          form={form}
          layout="horizontal"
          onFinish={onFinish}
        >
          <Col lg={23}>
            <span className="fs-15 fw-900 mt-2 ">{t("generalInfo.title")}</span>
            {user?.role === ENUM_ROLE_EN.SUPER_ADMIN ? (
              <Card className="mt-05">
                <Row>
                  <Col span={24}>
                    <Form.Item
                      name="branchId"
                      label="สาขาต้นทาง"
                      rules={[{ required: true }]}
                    >
                      <Selector
                        Source={branchList?.data?.result?.[0]?.data || []}
                        keyName={i18n.language === "Th" ? "nameTh" : "nameEn"}
                        keyValue="id"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Card>
            ) : null}

            <Card className="mt-05">
              <Row gutter={[14, 14]} className="margin-form">
                <Col md={3} lg={3} className="text-col-center">
                  {t("sex")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="gender">
                    <Selector
                      disabled={true}
                      Source={gender}
                      keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                      keyValue="id"
                    />
                  </Form.Item>
                </Col>

                <Col md={3} lg={3} className="text-col-center">
                  {t("age")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="age">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col md={3} lg={3} className="text-col-center">
                  {t("dateOfBirth")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="dateOfBirth">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col md={3} lg={3} className="text-col-center">
                  {t("telephoneNumber")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="mobile">
                    <Input disabled />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Row gutter={[0, 14]}>
                    <Col span={6} className="text-col-center">
                      {t("email")} :
                    </Col>

                    <Col span={18}>
                      <Form.Item name="email">
                        <Input disabled />
                      </Form.Item>
                    </Col>

                    <Col span={6} className="text-col-center">
                      {t("height")} :
                    </Col>

                    <Col span={18}>
                      <Form.Item name="height">
                        <Input disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Col>

                <Col md={3} lg={3} className="text-col-center">
                  {t("address")} :
                </Col>

                <Col md={9} lg={9}>
                  <Form.Item name="address">
                    <TextArea autoSize={{ minRows: 3, maxRows: 5 }} disabled />
                  </Form.Item>
                </Col>
              </Row>
            </Card>

            <ImportantInfo />

            <div className="fs-15 fw-900 mt-2  ">{t("contractCompany")}</div>

            <Card className="mt-05">
              <Row gutter={[14, 14]} className="margin-form">
                {patientIDByID?.patientInsurance?.length ? (
                  patientIDByID?.patientInsurance?.map(
                    (item, index: number) => {
                      return (
                        <Col lg={12} key={index}>
                          <Row>
                            <Col lg={4} className="text-col-center">
                              {`${index + 1}.`}
                            </Col>

                            <Col lg={20}>
                              <Input
                                disabled
                                value={
                                  i18n.language === "th"
                                    ? item?.insurance?.nameTh
                                    : item?.insurance?.nameEn
                                }
                              />
                            </Col>
                          </Row>
                        </Col>
                      );
                    }
                  )
                ) : (
                  <Col lg={24}>
                    <div className="t-grey-light">
                      {t("informationContracting")}
                    </div>
                  </Col>
                )}
              </Row>
            </Card>
          </Col>
        </Form>
      </Row>
    </Card>
  );
};

export default Fields;
