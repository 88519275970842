import { OPD } from "@assets/data/pathRouter";
import { IGetVisits } from "@interface/IVisits.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useGetVisitLabResult } from "@services/api/visit_history";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import MoTable from "@components/table/Table";

type Props = {
  currentPage: ICurrentPage;
  searchValue: any;
};

const Table = ({ currentPage, searchValue }: Props) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const match: any = useRouteMatch();
  const history = useHistory<RouteComponentProps["history"]>();
  const patientID = match.params.patientID;
  const visitsList = useGetVisitLabResult(patientID, {
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: searchValue?.sort
      ? searchValue?.sort === 1
        ? ENUM_ORDERBY.DESC
        : ENUM_ORDERBY.ASC
      : ENUM_ORDERBY.DESC,
    date: searchValue?.date
      ? moment(searchValue?.date).format("YYYY-MM-DD")
      : undefined,
  });

  useEffect(() => {
    setTotal(visitsList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [visitsList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: IGetVisits) => {
    history.push(
      `${OPD.detailLabInterface}/patient/${patientID}/visit/${event.id}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("creationDate")}`,
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <>{moment(date).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: `${t("visitCode")}  `,
      dataIndex: "id",
      key: "id",
    },

    {
      title: t("doctorName"),
      dataIndex: "appointment",
      key: "appointment",
      render: (appointment: any) => {
        const user = appointment?.[0]?.slot?.user || {};

        return (
          <>
            {user && i18n?.language === "th"
              ? [user?.firstNameTh, user?.lastNameTh]?.join(" ")?.trim()
              : [user?.firstNameEn, user?.lastNameEn]?.join(" ")?.trim()}
          </>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={visitsList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
