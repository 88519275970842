import { Table } from "antd";
import { dayjs } from "@tools/timezone";
import { IParamet } from "@interface/IGlobal.interface";
import { IGetLabInfo } from "@interface/IWorkOrder.interface";

type Props = {
  labInfoList?: IParamet<IGetLabInfo[]>;
};

const TableResult = ({ labInfoList }: Props) => {
  const columns = [
    {
      title: "No.",
      dataIndex: "no",
      key: "no",
      render: (_: any, __: any, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: "TestCode",
      dataIndex: "testCode",
      key: "testCode",
    },
    {
      title: "TestName",
      dataIndex: "testName",
      key: "testName",
    },
    {
      title: "วันเวลาที่สั่งตรวจ",
      dataIndex: "requestDateTime",
      key: "requestDateTime",
      render: (_: any) => {
        return <div>{dayjs().format("YYYYMMDDHHMMss")}</div>;
      },
    },
    {
      title: "วันเวลาที่สั่งตรวจ",
      dataIndex: "obsvervationDateTime",
      key: "obsvervationDateTime",
      render: (_: any) => {
        return <div>{dayjs().format("YYYYMMDDHHMMss")}</div>;
      },
    },
    {
      title: "วันเวลาที่สั่งตรวจ",
      dataIndex: "specimenReceiveDateTime",
      key: "specimenReceiveDateTime",
      render: (_: any) => {
        return <div>{dayjs().format("YYYYMMDDHHMMss")}</div>;
      },
    },
    {
      title: "รหัสตัวอย่างที่ส่งตรวจ",
      dataIndex: "specimenCode",
      key: "specimenCode",
    },
  ];
  return (
    <div>
      <Table
        rowKey={"key"}
        className="no-cursor"
        dataSource={labInfoList?.result?.[0]?.info || []}
        rowClassName={(r) => {
          return "no-header ";
        }}
        scroll={{ x: "calc(100vw -20%)" }}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};

export default TableResult;
