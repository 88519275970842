import { dayjs } from "@tools/timezone";
import { Button } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { IFile, IVisitFile } from "@interface/IVisitFile.interface";
import { useGetVisitOldFile } from "@services/api/visit_old_file";
import { useDeleteVisitOldFile } from "@services/api/visit_old_file";
import { FC, useEffect, useState } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import EditFileName from "@components/edit_file_name/EditFileName";

type Props = {
  id: number;
  type: string;
};

const Table: FC<Props> = ({ id, type }) => {
  const { t } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [byID, setByID] = useState<number>();
  const [total, setTotal] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data } = useGetVisitOldFile(id, type, {
    page: currentPage,
    limit: limitPage,
  });
  const deleteFile = useDeleteVisitOldFile();
  const [isModalOpen, setIsModalOpen] = useState({
    delete: false,
    update: false,
  });
  const [form] = useForm();
  const client = useQueryClient();

  useEffect(() => {
    if (!data?.result?.[0]?.total) return;
    setTotal(data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onModal = () => {
    setIsModalOpen({
      ...isModalOpen,
      delete: !isModalOpen?.delete,
    });
  };

  const onManageModal = (e: boolean, upData?: boolean) => {
    setIsModalOpen({
      ...isModalOpen,
      update: e,
    });
    if (!!upData) {
      client.invalidateQueries([
        `get-Visit-old-file`,
        id,
        type,
        {
          page: currentPage,
          limit: limitPage,
        },
      ]);
    }
  };

  const onDelete = () => {
    deleteFile.mutate(byID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
      },
      onError: (res) => {
        openNotification({ method: "DEL_ERROR", message: String(res) });
      },
      onSettled: () => {
        client.invalidateQueries([
          `get-Visit-old-file`,
          id,
          type,
          {
            page: currentPage,
            limit: limitPage,
          },
        ]);
        onModal();
      },
    });
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "10%",
      render: (_: number, __: string, index: number) => {
        return <div>{limitPage * (currentPage - 1) + index + 1}</div>;
      },
    },
    {
      title: t("creationDate"),
      dataIndex: "file",
      key: "file",
      render: (file: IFile) => {
        return <div>{dayjs(file?.createdAt).format("DD-MM-YYYY HH:mm")}</div>;
      },
    },

    {
      title: t("name"),
      dataIndex: "file",
      key: "file",
      render: (file: IFile) => {
        return <div>{file?.fileName || ""}</div>;
      },
    },

    {
      title: `${t("file")}`,
      dataIndex: "file",
      key: "file",
      render: (file: IFile) => {
        return (
          <>
            {file?.presigned ? (
              <Button
                className="btn-view"
                onClick={(e) => {
                  window.open(file?.presigned);
                  e.stopPropagation();
                }}
              >
                view
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      title: t("manage"),
      dataIndex: "data",
      key: "data",
      render: (_: string, data: IVisitFile) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ paddingRight: 10, color: "#094C97" }}>
              <EditOutlined
                onClick={() => {
                  onManageModal(!isModalOpen?.update);

                  let name = data?.file?.fileName.split(".");
                  let lastName = data?.file?.fileName?.slice(
                    data?.file?.fileName?.length -
                      Number(name?.[name?.length - 1]?.length + 1),
                    data?.file?.fileName?.length
                  );

                  form.setFieldsValue({
                    name: data?.file?.fileName?.slice(
                      0,
                      data?.file?.fileName?.length -
                        Number(name?.[name?.length - 1]?.length + 1)
                    ),
                    lastName: lastName,
                    id: data?.file?.id,
                  });
                }}
              />
            </div>
            <div style={{ color: "red" }}>
              <DeleteOutlined
                onClick={() => {
                  setByID(data?.id);
                  onModal();
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <div>
      {!!isModalOpen.delete && (
        <Modaler
          visible={isModalOpen.delete}
          onCancel={onModal}
          onOk={onDelete}
          loadingButton={deleteFile?.isLoading}
          width="45%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              {`${t("areYouSure")}`}
            </div>
          }
        >
          {`${t("doYouWantToDelete")}`}
        </Modaler>
      )}
      <MoTable
        dataSource={data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
      />
      {!!isModalOpen.update && (
        <EditFileName
          isModalOpen={isModalOpen.update}
          onManageModal={onManageModal}
          form={form}
        />
      )}
    </div>
  );
};

export default Table;
