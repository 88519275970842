import { dayjs } from "@tools/timezone";
import { IBranch } from "@interface/IBranch.interface";
import { Popover } from "antd";
import { TextBRHTML } from "@tools/util";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { useGetEventList } from "@services/api/event";
import { PRE_REGISTRATION } from "@assets/data/pathRouter";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  searchValue: any;
  currentPage: ICurrentPage;
};

const Table = ({ searchValue, currentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const [total, setTotal] = useState<number>(1);
  const eventList = useGetEventList({
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    all: true,
    ...searchValue,
  });

  useEffect(() => {
    if (!eventList?.data?.result?.[0]?.total) return;
    setTotal(eventList?.data?.result?.[0]?.total || 1);
    //   // eslint-disable-next-line
  }, [eventList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${PRE_REGISTRATION.event}/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("name")}${t("marketing.activity")}`,
      dataIndex: "name",
      key: "name",
    },

    {
      title: `${t("date")}${t("start")}`,
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: string) => {
        return <>{dayjs(startDate).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: `${t("date")}${t("end")}`,
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: string) => {
        return <>{dayjs(endDate).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: t("place"),
      dataIndex: "location",
      key: "location",
      render: (location: string) => {
        return <div className="f-normal ">{location}</div>;
      },
    },
    {
      title: t("details"),
      dataIndex: "detail",
      key: "detail",
      render: (detail: string) => {
        return (
          <Popover
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: TextBRHTML(detail || "-"),
                }}
              />
            }
          >
            <div className="text-content">{detail || "-"}</div>
          </Popover>
        );
      },
    },
    {
      title: t("branch"),
      dataIndex: "branch",
      key: "branch",
      render: (branch: IBranch) => {
        return (
          <Popover
            content={
              <div
                dangerouslySetInnerHTML={{
                  __html: TextBRHTML(
                    i18n.language === "th"
                      ? branch?.nameTh
                      : branch?.nameEn || "-"
                  ),
                }}
              />
            }
          >
            <div className="text-content">
              {i18n.language === "th" ? branch?.nameTh : branch?.nameEn || "-"}
            </div>
          </Popover>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        rowKey="id"
        dataSource={eventList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
