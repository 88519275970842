import { IResultLabInterface } from "@interface/IProduct.interface";
import { ILabInterfaceGroupContext } from "./inteface";
import { keyNameProductLabInterface } from "@interface/IProduct.interface";
import { createContext, useContext, useState } from "react";

const MainLabInterfaceGroupContext = createContext<ILabInterfaceGroupContext>(
  {} as ILabInterfaceGroupContext
);

const MainLabInterfaceGroupProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: any;
}) => {
  const [labInterface, setLabInterface] = useState<{
    [K in keyNameProductLabInterface]: IResultLabInterface[];
  }>({
    testCode: [],
    hematology: [],
    microbiology: [],
    coagulation: [],
    urine: [],
    biochemistry1: [],
    biochemistry2: [],
    groupData: [],
    groupTwo: [],
  });

  return (
    <MainLabInterfaceGroupContext.Provider
      value={{ labInterface, setLabInterface, ...value }}
    >
      {children}
    </MainLabInterfaceGroupContext.Provider>
  );
};

export const useMainLabInterfaceGroup = () =>
  useContext(MainLabInterfaceGroupContext);
export default MainLabInterfaceGroupProvider;

export const keyLabInterface: keyNameProductLabInterface[] = [
  "testCode",
  "hematology",
  "microbiology",
  "coagulation",
  "urine",
  "biochemistry1",
  "biochemistry2",
  "groupData",
  "groupTwo",
];
