import { ICardId } from "@views/profile/opd/patient_basic_info/form/interface";
import { useQuery, UseQueryResult } from "react-query";
import { ICradDevice, IResCardReader } from "@interface/ICardRender.interface";
import axios from "axios";

export const useGetCardDevice = (): UseQueryResult<ICradDevice> => {
  return useQuery(["get-card-device"], async () => {
    const res = await axios.get(`http://localhost:10000/api/device`, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res?.status >= 200 && res?.status < 300) {
      const result = res?.data?.result?.map((item: string, index: number) => {
        return { name: item, key: index };
      });
      return { ...res?.data, result: result };
    } else {
      return res?.data;
    }
  });
};

export const useGetCardReader = (
  params?: ICardId
): UseQueryResult<IResCardReader> => {
  return useQuery(
    ["get-card-read", params],
    async () => {
      const res = await axios.get(
        `http://localhost:10000/api/read/${params?.selectorCard}`
      );

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else {
        return res?.data;
      }
    },
    {
      enabled:
        !!params?.selectorCard || params?.selectorCard === 0 ? true : false,
    }
  );
};
