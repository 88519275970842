import React, { useEffect, useRef, useState } from "react";
import { Switch } from "antd";
import { IBranch } from "@interface/IBranch.interface";
import { IUserList } from "@interface/IUsers.interface";
import { onImgError } from "@tools/util";
import { USER_MANAGE } from "@assets/data/pathRouter";
import { IRoleGetList } from "@interface/IRole.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useDeleteUser, useGetUser, useUpdateUser } from "@services/api/users";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import RoleContext from "@provider/RoleProvider";

type Props = {
  searchValue: any;
  currentPage: ICurrentPage;
};

const Table = ({ searchValue, currentPage }: Props) => {
  const isFirstRender = useRef(true);
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [visible, setVisible] = useState(false);
  const [userID, setUserID] = useState<number>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const client = useQueryClient();
  const roleList = React.useContext(RoleContext);
  const history = useHistory<RouteComponentProps["history"]>();
  const updateUser = useUpdateUser();
  const deleteUser = useDeleteUser();
  const userList = useGetUser({
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    search: searchValue?.name,
    roleId: searchValue?.roleId,
    branchId: searchValue?.branchId,
    phone: searchValue?.phone,
  });
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "USER";
  });

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    setTotal(userList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [userList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") {
      setLimitPage(page);
    } else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${USER_MANAGE.updateUser}/${event.id}`);
  };

  const onChangeSwitch = (event: boolean, data: IUserList) => {
    let formData = {
      isActive: event.toString(),
    };
    updateUser.mutate(
      { id: data?.id, data: formData },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-user"]);
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    handleDeleteUser(userID);
  };

  const handleDeleteUser = (userID?: number) => {
    deleteUser.mutate(userID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        client.invalidateQueries(["get-user"]);
      },
      onError: (err) => {
        openNotification({ method: "DEL_ERROR", message: String(err) });
      },
      onSettled: () => {
        onModal();
      },
    });
  };

  const columns: any = [
    medicalRecord?.[0]?.isEdit
      ? {
          title: t("status"),
          dataIndex: "isActive",
          key: "isActive",
          align: "center",
          width: "14%",
          render: (isActive: boolean, data: IUserList) => {
            return (
              <div key={data?.id} onClick={(event) => event.stopPropagation()}>
                <Switch
                  defaultChecked
                  checked={isActive}
                  onChange={(e: boolean) => onChangeSwitch(e, data)}
                />
              </div>
            );
          },
        }
      : {},

    {
      title: t("image"),
      dataIndex: "image",
      key: "image",
      render: (image: string) => {
        return (
          <img
            src={image || onImgError()}
            className="circle "
            style={{ objectFit: "cover", width: "3vw", height: "3vw" }}
            alt="avatar"
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              currentTarget.src = onImgError();
            }}
          />
        );
      },
    },

    {
      title: `${t("name")} - ${t("lastName")}`,
      dataIndex: "firstName",
      key: "firstName",
      render: (_: string, data: any) => {
        return (
          <div className="f-normal " key={data?.id}>
            {i18n.language === "th"
              ? [data?.firstNameTh, data?.lastNameTh]?.join(" ")?.trim()
              : [data?.firstNameEn, data?.lastNameEn]?.join(" ")?.trim()}
          </div>
        );
      },
    },

    {
      title: `${t("email")}`,
      dataIndex: "email",
      key: "email",
      render: (email: string) => {
        return <div>{email || ""}</div>;
      },
    },

    {
      title: `${t("userName")}`,
      dataIndex: "username",
      key: "username",
      render: (username: any) => {
        return <div>{username || ""}</div>;
      },
    },
    {
      title: t("phoneNumber"),
      dataIndex: "phone",
      key: "phone",
      render: (phone: string) => {
        return <>{phone || "-"}</>;
      },
    },
    {
      title: t("branch"),
      dataIndex: "branch",
      key: "branch",
      render: (branch: IBranch) => {
        return (
          <>{i18n.language === "th" ? branch?.nameTh : branch?.nameEn || ""}</>
        );
      },
    },
    {
      title: t("role"),
      dataIndex: "role",
      key: "role",
      render: (role: IRoleGetList) => {
        return (
          <>
            {i18n.language === "th" ? role?.nameTh || "-" : role?.nameEn || "-"}
          </>
        );
      },
    },

    medicalRecord?.[0]?.isEdit
      ? {
          title: t("manage"),
          dataIndex: "manage",
          key: "manage",
          align: "center",
          width: "9%",
          render: (_: any, data: any) => {
            return (
              <div key={data?.key}>
                <DeleteOutlined
                  onClick={(e) => {
                    setUserID(data?.id);
                    onModal();
                    e.stopPropagation();
                  }}
                />
              </div>
            );
          },
        }
      : {},
  ];

  return (
    <>
      <Modaler
        loadingButton={deleteUser?.isLoading}
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>

      <MoTable
        dataSource={userList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
