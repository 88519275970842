import React, { FC, useState } from "react";
import { dayjs } from "@tools/timezone";
import { ENUM_G6PD } from "@tools/util.enum";
import { FormInstance } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { Card, Col, Form, Row } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Advice from "@components/advice/Advice";
import SymbolView from "@components/symbol/Symbol";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import TableRejectDrugs from "./table/Table";

interface SelectedRow {
  selectedRow: number[];
  setSelectedRow: (e: number[]) => void;
}

type Props = {
  selectedRow: SelectedRow;
  orderItemRejectDrug: any;
  patientList: FormInstance;
};

const Fields: FC<Props> = ({
  selectedRow,
  orderItemRejectDrug,
  patientList,
}) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(true);

  const viewDetail = () => {
    setOpen(!open);
  };
  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room "
        extra={
          <>
            <Row
              gutter={[14, 14]}
              className="text-row-profile ml-05"
              align="middle"
            >
              <Col>
                <Row gutter={[14, 14]}>
                  <Col>
                    <ImagePatient src={orderItemRejectDrug?.patient?.image} />
                  </Col>

                  <Col>
                    <div>
                      {i18n.language === "th"
                        ? `${orderItemRejectDrug?.patient?.firstNameTh}  ${orderItemRejectDrug?.patient?.lastNameTh}`
                        : `${orderItemRejectDrug?.patient?.firstNameEn}  ${orderItemRejectDrug?.patient?.lastNameEn}`}
                    </div>
                    <div className="code-profile">
                      HN : {`${orderItemRejectDrug?.patient?.hn}`}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col style={{ marginLeft: "auto" }}>
                <div className="t-center ">
                  <div className=" t-grey fs-18 ">{t("orderNumber")} </div>
                  <div className=" t-grey-light fs-12 ">
                    {orderItemRejectDrug?.patient?.orderNo}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <Row align="middle">
          <Col>
            <div className="mb-05">
              <span className=" t-blue fs-18 f-normal ">{t("fullList")} </span>
              <span className=" t-grey-light  fs-12 ">
                {`${t("orderCreationDate")} : `}
              </span>
              <span className="t-grey fs-12 ">
                {!!orderItemRejectDrug?.patient?.createdAt
                  ? dayjs(orderItemRejectDrug?.patient?.createdAt).format(
                      "DD-MM-YYYY"
                    )
                  : ""}
              </span>
            </div>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <div style={{ color: "#FEB139" }}>
              <SymbolView />
            </div>
            <div
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {orderItemRejectDrug?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                ? "G6PD Deficiency"
                : null}
            </div>
          </Col>
        </Row>

        <Form name="patientList" form={patientList}>
          <ImportantInfo />
        </Form>
        <Advice visitsByID={orderItemRejectDrug?.patient} />

        <div className="mt-2">
          <Row className="header-table">
            <Col lg={10} className="f-normal col-start t-dark-blue">
              <span className="f-bold mr-05 ">{t("returnMedicine.title")}</span>
              <span className="mr-05">
                {React.createElement(open ? UpOutlined : DownOutlined, {
                  onClick: viewDetail,
                })}
              </span>
            </Col>
          </Row>
          <div className={open ? "open" : "close"}>
            <TableRejectDrugs
              selectedRow={selectedRow}
              orderItemRejectDrug={orderItemRejectDrug}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default Fields;
