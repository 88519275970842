import { EVENT } from "@assets/data/pathRouter";
import { IFormEvent } from "@interface/IEvent.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useGetPromotionList } from "@services/api/promotion";
import { Card, Col, DatePicker, Select } from "antd";
import { Form, FormInstance, Input, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useCreateEvent, useUpdateEventByID } from "@services/api/event";
import TextArea from "antd/lib/input/TextArea";

type Props = {
  confirm: FormInstance;
  eventID: number;
};
const { Option } = Select;

const Fields = ({ confirm, eventID }: Props) => {
  const { RangePicker } = DatePicker;
  const { t, i18n } = useTranslation();
  const dateFormat = "DD-MM-YYYY";
  const createEvent = useCreateEvent();
  const updateEvent = useUpdateEventByID();
  const history = useHistory<RouteComponentProps["history"]>();
  const promotionList = useGetPromotionList({
    limit: 0,
    orderby: ENUM_ORDERBY.DESC,
  });

  const onFinish = (value: IFormEvent) => {
    if (eventID) {
      const data = {
        ...value,
        startDate: value.date?.[0],
        endDate: value.date?.[1],
        addPromotion: value?.addPromotion?.map((item) => {
          return { promotionId: item };
        }),
      };

      updateEvent.mutate(
        {
          id: eventID,
          data: data,
        },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });

            history.replace(`${EVENT.title}`);
          },
          onError: (res) => {
            openNotification({
              method: "PUT_SUCCESS",
              message: String(res),
            });
          },
        }
      );
    } else {
      createEvent.mutate(
        {
          ...value,
          startDate: value?.date?.[0],
          endDate: value?.date?.[1],
          addPromotion: value?.addPromotion?.map((item) => {
            return { promotionId: item };
          }),
        },
        {
          onSuccess: (res) => {
            openNotification({ method: "POST_SUCCESS" });

            history.replace(`${EVENT.title}`);
          },
          onError: (res) => {
            openNotification({
              method: "POST_ERROR",
              message: String(res),
            });
          },
        }
      );
    }
  };

  return (
    <div className="set-layout ">
      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={<b className="fs-16 t-black-light ">{t("event.title")}</b>}
      >
        <Card
          className="card-prefix card-prefix-coupon-width card-prefix-chi"
          extra={<b className="fs-14 c-white ">{t("event.manage")}</b>}
        >
          <Form onFinish={onFinish} name="prefixForm" form={confirm}>
            <Row gutter={[14, 14]}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  label={t("eventName")}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t("eventName")} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("promotion.title")}
                  name="addPromotion"
                  rules={[
                    {
                      required: true,
                      message: `${t("pleaseSelectPromotion")}`,
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    className="promotion-select"
                    allowClear
                    placeholder={t("pleaseSelectPromotion")}
                    filterOption={(input, option: any) => {
                      return (
                        option?.children?.toLowerCase()?.includes(input) ||
                        `${input}` === `${option?.value}`
                      );
                    }}
                  >
                    {promotionList?.data?.result?.[0]?.data?.map(
                      (item, idx) => (
                        <Option value={item?.id} key={idx}>
                          {i18n.language === "th" ? item?.nameTh : item?.nameEn}
                        </Option>
                      )
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  name="date"
                  label={t("date")}
                  rules={[{ required: true, message: t("pleaseSelect") }]}
                >
                  <RangePicker format={dateFormat} />
                </Form.Item>
              </Col>

              <Col md={12} lg={12}>
                <Form.Item
                  name="location"
                  label={t("place")}
                  rules={[{ required: true }]}
                >
                  <Input placeholder={t("place")} allowClear={true} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t("details")} name="detail">
                  <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </div>
  );
};

export default Fields;
