import { useTranslation } from "react-i18next";
import { Badge, Card, Tabs } from "antd";
import List from "./List";
import History from "./History";

type Props = {
  status?: string;
  heightView?: number;
};

const ListRecord = ({ status = "", heightView = 0 }: Props) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const viewHeight = () => {
    return window.innerHeight - heightView - 10;
  };

  return (
    <>
      {status === "noCard" ? (
        <Tabs className="tab-profile" defaultActiveKey={"1"}>
          <TabPane
            tab={
              <>
                {t("availableList")}
                <Badge className="ml-1 status-dot" color="#f50" />
              </>
            }
            key="1"
          >
            <List />
          </TabPane>
          <TabPane tab={t("record")} key="2">
            <History />
          </TabPane>
        </Tabs>
      ) : (
        <Card
          className="bg-grey  list-record-card  order-list-record  "
          style={
            !!heightView
              ? { height: `${viewHeight()}px`, minHeight: `${viewHeight()}px` }
              : {}
          }
        >
          <Tabs className="tab-profile" defaultActiveKey={"1"}>
            <TabPane
              tab={
                <>
                  {t("availableList")}
                  <Badge className="ml-1 status-dot" color="#f50" />
                </>
              }
              key="1"
            >
              <List />
            </TabPane>
            <TabPane tab={t("record")} key="2">
              <History />
            </TabPane>
          </Tabs>
        </Card>
      )}
    </>
  );
};

export default ListRecord;
