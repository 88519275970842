import React, { useEffect, useState } from "react";
import { INCHART } from "@assets/data/pathRouter";
import { ISelectedList } from "@interface/IOrder.interface";
import { openNotification } from "@components/notification/Notification";
import { useGetVisitsByID } from "@services/api/visits";
import { ENUM_SERVICE_GROUP } from "@tools/util.enum";
import { RouteComponentProps } from "react-router-dom";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { Col, Drawer, Form, Row } from "antd";
import { useCreateWorkOrdersUse } from "@services/api/work_order";
import { useGetWorkOrdersByPatient } from "@services/api/work_order";
import { useHistory, useRouteMatch } from "react-router-dom";
import { IItem, IOrderListAndRecord } from "@interface/IOrder.interface";
import Fields from "./Fields";
import Header from "./Header";
import Affixes from "@components/affixes/Affixes";
import ListRecord from "@components/list_history/ListHistory";
import RoleContext from "@provider/RoleProvider";
import ListHistoryCheckbox from "@components/list_history_checkbox/ListHistoryCheckbox";
import MainPatientByIdProvider from "@provider/patient_by_id";
import MainProductOutStandingProvider from "@provider/product_out_standing";

const FormInchart = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const patientID = match.params.patientID;
  const [patientList] = Form.useForm();
  const [orderList, setOrderList] = useState<ISelectedExecuteOrder[]>([]);
  const createWorkOrdersUse = useCreateWorkOrdersUse();
  const [selection, setSelection] = useState<ISelectedList>({
    course: [],
    list: [],
    oldValueList: [],
    oldValue: [],
    expandAllRows: [],
  });
  const [visible, setVisible] = useState<boolean>(false);
  const visitList = useGetVisitsByID(visitID);
  const workOrdersByPatient = useGetWorkOrdersByPatient(patientID, {
    serviceGroup: [
      ENUM_SERVICE_GROUP.MEDICAL_PROCEDURE,
      ENUM_SERVICE_GROUP.LAB_INTERFACE,
      ENUM_SERVICE_GROUP.LAB,
    ]?.join(","),
    visitId: visitID,
    status: "PARTIAL",
  });
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "INCHARGE";
  });

  useEffect(() => {
    if (!visitList?.data) {
      return;
    }
    patientList?.setFieldsValue({
      foodAllergy: visitList?.data?.patient?.foodAllergy || undefined,
      drugAllergy: visitList?.data?.patient?.drugAllergy || undefined,
    });
    // eslint-disable-next-line
  }, [visitList?.data]);

  const confirmInchart = async () => {
    await Promise.all([handlePostWorksOrderUse()])
      ?.then(([res]) => {})
      ?.catch((err) => openNotification({ method: "POST_ERROR" }));
  };

  const courseItem = (array1: any[], array2: string[]) => {
    return array1?.map((item: IItem) => {
      if ((array2?.indexOf(item?.uuid) !== -1) === true) {
        return {
          uuid: item?.uuid,
          use: true,
        };
      } else {
        return {
          uuid: item?.uuid,
          use: false,
        };
      }
    });
  };

  const handlePostWorksOrderUse = () => {
    let dataCourse: any = [];
    let result: IItem[] | any;

    const itemsList =
      workOrdersByPatient?.data?.result?.[0]?.groups?.flatMap(
        (item: IOrderListAndRecord) => item?.items
      ) || [];

    const children = orderList
      ?.flatMap((childrenItem: ISelectedExecuteOrder) => {
        return childrenItem?.children?.map((i: any) => i?.children);
      })
      ?.flat(Infinity);

    Array.from(
      new Map(
        [...(itemsList || []), ...(children || [])]?.map((item) => [
          item?.uuid,
          item,
        ])
      ).values()
    )?.filter((item: any) => {
      if (
        item?.product?.categoryId === "C-0006" ||
        item?.product?.categoryId === "C-0007"
      ) {
        result = courseItem(
          item?.children?.[0]?.children?.map((dataList: IItem) => {
            return { ...(dataList || []), orderId: item?.orderId };
          }),
          selection?.course
        );

        dataCourse.push(...result);
      }
      return false;
    });

    const itemsTreatment = itemsList?.filter(
      (item: IItem) =>
        item?.product?.categoryId === "C-0001" ||
        item?.product?.categoryId === "C-0003" ||
        item?.product?.categoryId === "C-0008"
    );
    result = itemsTreatment?.map((item: IItem) => {
      const list = children?.find(
        (itemValue: IItem) => itemValue?.uuid === item?.uuid
      );

      if (list) {
        return {
          uuid: item?.uuid,
          use: true,
        };
      } else
        return {
          uuid: item?.uuid,
          use: false,
        };
    });

    createWorkOrdersUse.mutate(
      {
        data: [...(result || []), ...(dataCourse || [])],
        id: visitID,
        workOrderId: workOrdersByPatient?.data?.result?.[0]?.id,
      },
      {
        onSuccess: () => {
          history.replace(INCHART.title);
        },
        onError: (err) => {
          openNotification({
            method: "POST_ERROR",
            message: String(err),
          });
        },
      }
    );
  };
  const showDrawer = () => {
    setVisible(!visible);
  };

  return (
    <MainPatientByIdProvider value={{ patientById: visitList?.data?.patient }}>
      <MainProductOutStandingProvider
        value={{ outStanding: workOrdersByPatient?.data?.result?.[0]?.groups }}
      >
        <React.Fragment>
          <Header
            history={history}
            confirmInchart={confirmInchart}
            medicalRecord={medicalRecord?.[0]?.isEdit}
          />
          <div className="set-layout body-height  ">
            <Row gutter={[14, 14]}>
              <Col md={24} lg={18}>
                <Fields
                  orderList={orderList || []}
                  visitsByID={visitList?.data}
                  patientList={patientList}
                  selection={{
                    selection: selection,
                    setSelection: setSelection,
                  }}
                  status="inchartBetween"
                  outStanding={workOrdersByPatient?.data?.result?.[0]?.groups}
                  setOrderList={setOrderList}
                />
              </Col>

              <Col lg={6} className="card-display">
                {medicalRecord?.[0]?.isEdit ? (
                  <ListHistoryCheckbox
                    setOrderList={setOrderList}
                    status="inchartBetween"
                    selection={{
                      selection: selection,
                      setSelection: setSelection,
                    }}
                  />
                ) : (
                  <ListRecord />
                )}
              </Col>
            </Row>

            <Affixes onClick={showDrawer} />

            <Drawer
              width={"50%"}
              closable={false}
              placement="right"
              onClose={showDrawer}
              visible={visible}
            >
              {medicalRecord?.[0]?.isEdit ? (
                <ListHistoryCheckbox
                  setOrderList={setOrderList}
                  status="inchartBetween"
                  selection={{
                    selection: selection,
                    setSelection: setSelection,
                  }}
                  noCard="noCard"
                />
              ) : (
                <ListRecord status="noCard" />
              )}
            </Drawer>
          </div>
        </React.Fragment>
      </MainProductOutStandingProvider>
    </MainPatientByIdProvider>
  );
};

export default FormInchart;
