import { FC } from "react";
import { OPD } from "@assets/data/pathRouter";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { VISIT_OLD_FILES } from "@tools/util.enum";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  title: string;
};

const Header: FC<Props> = ({ title }) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const type: VISIT_OLD_FILES = match.params.fileId;
  const history = useHistory<RouteComponentProps["history"]>();

  const handleUpload = () => {
    history.push(
      `${OPD.nursingHistory}/patient/${patientID}/history-upload-file/${type}/upload`
    );
  };
  return (
    <Row className="second-header " justify="space-between" align="middle">
      <Col>
        <Row gutter={[14, 14]}>
          <BackHome history={history} />

          <Col className="header-text">
            <div>
              <h1>{t("medicalRecord.title")}</h1>
            </div>
            <span className="t-light-gray">
              {`${t("nursery")} / ${t("historyFile")} / ${t(
                "treatmentHistory"
              )} / `}
            </span>
            <span className="t-dark-blue f-normal">{` ${title}`}</span>
          </Col>
        </Row>
      </Col>

      <Col style={{ marginLeft: "auto" }}>
        <Button className="btn-create" type="primary" onClick={handleUpload}>
          <PlusOutlined className="icons-size" />
          {`${t("upload")}`}
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
