import { dayjs } from "@tools/timezone";
import { IItem } from "@interface/IOrder.interface";
import { DRUG_STATUS } from "@tools/util.enum";
import { DownOutlined } from "@ant-design/icons";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { RangePickerProps } from "antd/lib/date-picker";
import { IWorkOrderItemDrug } from "@interface/IWorkOrderITem.interface";
import { EditBy, ChildChild } from "@interface/IWorkOrderITem.interface";
import { useEffect, useState } from "react";
import { useWorkOrderItemDrug } from "@services/api/work_order_item";
import { useMainMedicineDoctorRoom } from "@provider/medicine_doctor_room";
import { useUpdateWorkOrderItemById } from "@services/api/work_order";
import { Button, DatePicker, Dropdown, Menu, Popover } from "antd";
import moment from "moment";
import MoTable from "@components/table/Table";

const obj: {
  [k in DRUG_STATUS]: {
    name: string;
    className: string;
    key: DRUG_STATUS;
  };
} = {
  [DRUG_STATUS.CONTINUOUS]: {
    name: "Continuous",
    className: "btn-confirm-receipt",
    key: DRUG_STATUS.CONTINUOUS,
  },
  [DRUG_STATUS.DIS_CONTINUOUS]: {
    name: "Dis continuous",
    className: "btn-wait-dis",
    key: DRUG_STATUS.DIS_CONTINUOUS,
  },
  [DRUG_STATUS.HOLDING]: {
    name: "Holding",
    className: "btn-wait-receipt",
    key: DRUG_STATUS.HOLDING,
  },
};
interface SetSelectKey {
  id: number | string;
  option: string;
}

interface SelectedRow {
  selectedRow: SetSelectKey[];
  setSelectedRow: (e: SetSelectKey[]) => void;
}

type Props = {
  selectedRow: SelectedRow;
};

const TableDrug = ({ selectedRow }: Props) => {
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const client = useQueryClient();
  const updateWorkOrderItemById = useUpdateWorkOrderItemById();
  const { key, status, drugStatus, drug } = useMainMedicineDoctorRoom();
  const patientID = match.params.patientsID || match.params.patientID;
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const workOrderItemDrugList = useWorkOrderItemDrug(patientID, {
    limit: limitPage,
    page: currentPage,
    drugStatus: drugStatus,
  });

  useEffect(() => {
    if (!workOrderItemDrugList?.data) return;
    setTotal(workOrderItemDrugList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [workOrderItemDrugList?.data]);

  const onSelectChange = (selected: any, dataProduct: any) => {
    if (status === "return_drug") {
      const dataList = selected
        ?.filter((item: string | number) => typeof item !== "string")
        ?.map((item: string | number) => {
          return {
            id: item,
          };
        });
      selectedRow.setSelectedRow(dataList);
    } else {
      const dataList = dataProduct
        ?.filter((item: IItem) => item?.id?.search("children"))
        ?.map((data: IItem) => {
          return {
            id: data?.id,
            option: data?.option,
          };
        });

      selectedRow.setSelectedRow(dataList);
    }
  };

  const rowSelection = {
    selectedRowKeys: selectedRow.selectedRow?.map((item) => item?.id),
    onChange: onSelectChange,
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onChangeSelect = (
    keyValue: string,
    data: IWorkOrderItemDrug,
    time?: string
  ) => {
    if (
      keyValue !== DRUG_STATUS.HOLDING ||
      (keyValue === DRUG_STATUS.HOLDING && !!time)
    ) {
      updateWorkOrderItemById.mutate(
        {
          data: {
            drugStatus: keyValue,
            changeDate: time,
          },
          id: data?.key,
        },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
            client.invalidateQueries([
              `get-work-order-item-drugs`,
              patientID,
              {
                limit: limitPage,
                page: currentPage,
                drugStatus: drugStatus,
              },
            ]);
          },
          onError: (err) => {
            openNotification({ method: "PUT_ERROR", message: String(err) });
          },
        }
      );
    } else {
      const workOrder: any = workOrderItemDrugList?.data?.result?.[0]?.data
        ?.filter((item) => item?.key === data?.parentKey)
        ?.map((parent) => {
          return {
            ...parent,
            children: parent?.children?.map((child) => {
              return {
                ...child,
                children: child?.children?.map((children) => {
                  if (String(children?.key) === String(data?.key)) {
                    return {
                      ...children,
                      drugStatus: DRUG_STATUS.HOLDING,
                    };
                  } else {
                    return {
                      ...children,
                    };
                  }
                }),
              };
            }),
          };
        });

      const workOrderItemDrug: any =
        workOrderItemDrugList?.data?.result?.[0]?.data;
      client.setQueryData(
        [
          `get-work-order-item-drugs`,
          patientID,
          {
            limit: limitPage,
            page: currentPage,
            drugStatus: drugStatus,
          },
        ],
        {
          ...workOrderItemDrugList?.data,
          result: [
            {
              ...workOrderItemDrugList?.data?.result?.[0],
              data: Array.from(
                new Map(
                  [...(workOrderItemDrug || []), ...(workOrder || [])]?.map(
                    (item: IWorkOrderItemDrug) => [item?.key, item]
                  )
                ).values()
              ),
            },
          ],
        }
      );
    }
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < moment().add(-1, "day").endOf("day");
  };

  const columns: any[] = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: string, data: IWorkOrderItemDrug, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1 ? (
              <div>{limitPage * (currentPage - 1) + index + 1}</div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      title: t("orderNumber"),
      dataIndex: "name",
      key: "name",
      render: (
        _: string,
        data: IWorkOrderItemDrug & ChildChild,
        index: number
      ) => {
        if (data?.level === 1) {
          return <div>{data?.name}</div>;
        } else if (data?.level === 2) {
          return <div key={index}>{t("drugName")}</div>;
        } else {
          return <div key={index}>{data?.product?.nameTh}</div>;
        }
      },
    },

    {
      title: "",
      dataIndex: "editBy",
      key: "editBy",
      align: "center",
      render: (editBy: EditBy, data: IWorkOrderItemDrug, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? `${t("doctor")} ${t("name")}`
              : ` ${
                  i18n.language === "th"
                    ? [editBy?.firstNameTh, editBy?.lastNameTh]
                        ?.join(" ")
                        ?.trim()
                    : [editBy?.firstNameEn, editBy?.lastNameEn]
                        ?.join(" ")
                        ?.trim()
                } `}
          </div>
        );
      },
    },
    {
      title: t("date"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",

      render: (date: string, data: IWorkOrderItemDrug, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? data?.date || ""
              : data?.level === 2
              ? t("date")
              : dayjs(date).format("DD-MM-YYYY")}
          </div>
        );
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",
      key: "qty",
      align: "center",
      render: (qty: string, data: IWorkOrderItemDrug, index: number) => {
        return (
          <div key={index}>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length
              : data?.level === 2
              ? t("amount")
              : `${qty}`}
          </div>
        );
      },
    },

    {
      title: t("details"),
      dataIndex: "data",
      key: "data",
      align: "center",
      render: (
        _: string,
        data: IWorkOrderItemDrug & ChildChild,
        index: number
      ) => {
        const options = JSON.parse(data?.order?.orderItems[0]?.option || `{}`);
        return (
          <div key={index}>
            {data?.level === 1 ? (
              "-"
            ) : data?.level === 2 ? (
              t("details")
            ) : (
              <Popover
                content={
                  <div>
                    <div>
                      {`${t("startMedication")}  ${
                        options.dateStart
                          ? dayjs(options.dateStart).format("DD-MM-YYYY")
                          : ""
                      } `}
                    </div>
                    <div>
                      {`${t("stopMedicine")}   ${
                        options.dateStop
                          ? dayjs(options.dateStop).format("DD-MM-YYYY")
                          : ""
                      }`}
                    </div>

                    <div>
                      {`${t("medicineExp")}    ${
                        options.drugExpiration
                          ? dayjs(options.drugExpiration).format("MM-YYYY")
                          : ""
                      }`}
                    </div>
                  </div>
                }
                title={t("details")}
                trigger="click"
              >
                <Button type="primary">{t("clickMe")}</Button>
              </Popover>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      disables: drug ? drug : false,
      dataIndex: "drugStatus",
      key: "drugStatus",
      align: "center",
      render: (drugStatus: DRUG_STATUS, data: IWorkOrderItemDrug) => {
        return (
          <div>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 ? (
              t("status")
            ) : (
              <Dropdown
                overlay={
                  <Menu>
                    {Object.values(obj)?.map((item) => {
                      return (
                        <Menu.Item
                          onClick={(e) => onChangeSelect(e.key, data)}
                          key={item?.key}
                        >
                          {item?.name}
                        </Menu.Item>
                      );
                    })}
                  </Menu>
                }
                trigger={["click"]}
                placement="bottomLeft"
              >
                <Button className={obj?.[drugStatus]?.className}>
                  {obj?.[drugStatus]?.name}
                  <DownOutlined />
                </Button>
              </Dropdown>
            )}
          </div>
        );
      },
    },
    {
      title: "",
      disables: drug ? drug : false,
      dataIndex: "drugStatus",
      key: "drugStatus",
      align: "center",
      width: 200,
      render: (drugStatus: DRUG_STATUS, data: IWorkOrderItemDrug) => {
        return (
          <div>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 ? (
              t("time")
            ) : drugStatus === DRUG_STATUS.HOLDING ? (
              <DatePicker
                showTime
                format="YYYY-MM-DD HH:mm"
                disabledDate={disabledDate}
                defaultValue={
                  data?.changeDate ? moment(data?.changeDate) : undefined
                }
                onBlur={(e) =>
                  onChangeSelect(DRUG_STATUS.HOLDING, data, e?.target?.value)
                }
              />
            ) : null}
          </div>
        );
      },
    },
  ];

  const checkColumns = () => {
    const valueColumns = columns?.filter((item) => {
      return item?.disables === undefined || item?.disables === drug;
    });
    return valueColumns;
  };

  return (
    <div>
      <MoTable
        loading={workOrderItemDrugList?.isLoading}
        className="components-table-demo-nested table-selection table-confirm "
        rowSelection={
          !!drug ? undefined : { ...rowSelection, checkStrictly: false }
        }
        dataSource={workOrderItemDrugList?.data?.result?.[0]?.data?.map(
          (item) => {
            return {
              ...item,
              id: item?.key,
              children: item?.children?.map((parent) => {
                return {
                  ...parent,
                  id: parent?.key,
                  children: parent?.children?.map((chile) => {
                    return {
                      ...chile,
                      id: `${chile?.uuid}`,
                      option: chile?.option,
                      parentKey: item?.key,
                    };
                  }),
                };
              }),
            };
          }
        )}
        columns={checkColumns()}
        expandRowByClick
        showSorterTooltip
        rowKey={key}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: 900 }}
      />
    </div>
  );
};

export default TableDrug;
