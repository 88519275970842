import { FC } from "react";
import { t } from "i18next";
import { dayjs } from "@tools/timezone";
import { IProduct } from "@interface/IOrder.interface";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import logo from "@assets/img/logo/Ab.png";
import { IOption } from "@interface/IWorkOrder.interface";
import { IGetPatientList } from "@interface/IOpd.interface";

type Props = {
  refData: React.MutableRefObject<null>;
  data?: IItem[];
  visitsByID: IGetPatientList;
};
export interface IItem {
  product?: IProduct;
  option?: IOption;
}
const PrintMore: FC<Props> = ({ refData, data, visitsByID }) => {
  const { i18n } = useTranslation();

  const widthDiv = (num?: number) => {
    if (417 / Number(num || 1) > 12) {
      return `11px`;
    }
    return `${(417 / Number(num || 1)) * 1.1}px`;
  };

  const widthDivName = (num?: number) => {
    if (800 / Number(num || 1) > 12) {
      return `11px`;
    }
    return `${800 / Number(num || 1)}px`;
  };
  return (
    <div ref={refData} className="drugLabel t-black" style={{ height: "100%" }}>
      {data?.map((item: IItem, index) => {
        return (
          <div
            className="break-inside t-black"
            key={index}
            style={{ position: "relative", height: "100%" }}
          >
            {/* <div className="pr-05 pl-05 pt-05 "> */}
            <div className="pr-05 pl-05 pt-05-medicine t-black">
              <Row justify="space-between" gutter={[12, 12]}>
                <Col className="col-print-none t-black">
                  <img
                    src={logo}
                    alt="logo"
                    style={{ width: "3cm", height: "1.1cm" }}
                    className="image-print-none"
                  />
                </Col>

                <Col className="fs-14-drug">
                  <div className="fs-14-drug f-bold t-black">
                    HN : {`${visitsByID?.hn || ""}`}
                  </div>
                </Col>
              </Row>
            </div>

            <div className="pr-05 pl-05 pb-05 t-black">
              <div className="fs-14-drug f-bold t-black">
                <span className="fs-14-drug f-bold  t-black">
                  {i18n.language === "th"
                    ? `${[
                        visitsByID?.titleTh,
                        visitsByID?.firstNameTh,
                        visitsByID?.lastNameTh,
                      ]
                        ?.join(" ")
                        ?.trim()}`
                    : `${[
                        visitsByID?.titleEn,
                        visitsByID?.firstNameEn,
                        visitsByID?.lastNameEn,
                      ]
                        ?.join(" ")
                        ?.trim()}`}
                </span>
              </div>
              <div
                className="f-bold t-black   "
                style={{
                  fontSize: widthDivName(
                    `${
                      item?.option?.nameShown || item?.product?.nameTh || ""
                    }  (${
                      item?.option?.genericName || item?.product?.nameEn || ""
                    })`?.length
                  ),
                  paddingBottom: 1,
                }}
              >
                <span className="t-black">{`${
                  item?.option?.nameShown || item?.product?.nameTh || ""
                }`}</span>
                <span className="f-normal t-black">
                  {`(${
                    item?.option?.genericName || item?.product?.nameEn || ""
                  })`}
                </span>
              </div>

              <div className="fs-14-drug t-black">
                <span className="fs-14-drug f-bold t-black ">
                  {`${t("oneMealAtATime")} ${
                    item?.option?.quantityUsed || "-"
                  } ${
                    i18n.language === "th"
                      ? item?.product?.unitAltTh || ""
                      : item?.product?.unitAltEn || ""
                  } `}
                </span>
                <span className=" fs-14-drug f-bold t-black">
                  {item?.option?.eatingPattern?.map(
                    (dataEat: string, indexEat: number) => {
                      return (
                        <span
                          className="fs-14-drug f-bold t-black"
                          key={indexEat}
                        >
                          {t(`${dataEat || ""}`)}
                        </span>
                      );
                    }
                  )}
                </span>
              </div>

              <div className="fs-14-drug t-black">
                <span className="fs-14-drug f-bold t-black">
                  {`${t("aDay")} ${item?.option?.timeSpent?.length || "-"} ${t(
                    "time1"
                  )}`}
                </span>

                {item?.option?.timeSpent?.map(
                  (dataTimeSpent: string, indexTimeSpent: number) => {
                    return (
                      <span
                        className="fs-14-drug f-bold t-black"
                        key={indexTimeSpent}
                      >
                        {` ${t(dataTimeSpent || "")} `}
                      </span>
                    );
                  }
                )}
              </div>

              <div className="fs-12-drug t-black">
                <span className="fs-12-drug t-black">
                  {item?.option?.specialInstructions || ""}
                </span>
              </div>

              <div className="fs-12-drug t-black">
                <span
                  className="t-black"
                  style={{
                    fontSize: widthDiv(
                      ` ${
                        i18n.language === "th"
                          ? item?.option?.descriptionTh ||
                            item?.product?.descriptionTh
                          : item?.option?.descriptionEn ||
                            item?.product?.descriptionEn
                      }`?.length
                    ),
                  }}
                >
                  {i18n.language === "th"
                    ? item?.option?.descriptionTh ||
                      item?.product?.descriptionTh
                    : item?.option?.descriptionEn ||
                      item?.product?.descriptionEn}
                </span>
              </div>

              <Row
                justify="space-between"
                style={{
                  position: "absolute",
                  bottom: 3,
                  right: 0,
                  width: "100%",
                }}
              >
                <Col className="fs-12-drug f-bold t-black"></Col>
                <Col className="fs-12-drug f-bold t-black" span={12}>
                  <div style={{ position: "absolute", bottom: 3, right: 8 }}>
                    <span className="fs-12-drug f-bold t-black">
                      {dayjs().format("DD-MM-YYYY")}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default PrintMore;
