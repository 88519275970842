import { useEffect } from "react";
import { USER_MANAGE } from "@assets/data/pathRouter";
import { ENUM_ROLE_EN } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { IRoleFormValue } from "@interface/IRole.interface";
import { roleEn, roleTh } from "@assets/data/data";
import { openNotification } from "@components/notification/Notification";
import { useGetBranchList } from "@services/api/branch";
import { RouteComponentProps } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IPermission, ISubMenu } from "@interface/IRole.interface";
import { useGetRoleByID, useUpdateRoleByID } from "@services/api/role";
import { Checkbox, Col, Form, FormInstance, Input, Row, Switch } from "antd";
import Selector from "@components/selector/Selector";

type Props = {
  formRole: FormInstance;
  history: RouteComponentProps["history"];
  roleID: number;
  medicalRecord: boolean;
};
interface IRoleForm {
  value?: IPermission[];
  formRole: FormInstance;
  onChange?: (e?: IPermission[]) => void;
  medicalRecord: boolean;
}

const Fields = ({ formRole, history, roleID, medicalRecord }: Props) => {
  const { t, i18n } = useTranslation();
  const roleByID = useGetRoleByID(roleID);
  const updateRole = useUpdateRoleByID();
  const branchList = useGetBranchList(true, { limit: 0 });

  useEffect(() => {
    if (!roleByID?.data?.result) {
      return;
    }
    const index = roleEn?.find(
      (item) => item.id === roleByID?.data?.result?.[0]?.nameEn
    );
    formRole.resetFields();
    formRole.setFieldsValue({
      ...roleByID?.data?.result?.[0],
      nameTh: index ? roleByID?.data?.result?.[0]?.nameTh : ENUM_ROLE_EN.OTHER,
      nameEn: index ? roleByID?.data?.result?.[0]?.nameEn : ENUM_ROLE_EN.OTHER,
      nameOtherTh: roleByID?.data?.result?.[0]?.nameTh,
      nameOtherEn: roleByID?.data?.result?.[0]?.nameEn,

      permission: roleByID?.data?.result?.[0]?.permission,
    });
    // eslint-disable-next-line
  }, [roleByID?.data]);

  const onFinish = (data: IRoleFormValue) => {
    const reformed =
      data?.nameEn === ENUM_ROLE_EN.OTHER
        ? {
            ...data,
            nameTh: data?.nameOtherTh,
            nameEn: data?.nameOtherEn,
          }
        : {
            ...data,
          };
    delete reformed?.nameOtherTh;
    delete reformed?.nameOtherEn;

    updateRole.mutate(
      { id: roleID, data: reformed },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(`${USER_MANAGE?.title}`);
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };

  return (
    <div>
      {roleByID?.data?.result ? (
        <Form name="roleForm" form={formRole} onFinish={onFinish}>
          <Row justify="center" align="middle" gutter={[14, 1]}>
            <Col md={24} lg={12}>
              <Form.Item
                name="nameTh"
                label={`${t("name")} (TH)`}
                rules={[{ required: true }]}
              >
                <Selector
                  Source={roleTh}
                  keyName="name"
                  keyValue="id"
                  disabled={!medicalRecord}
                  onChange={(e) => {
                    formRole.setFieldsValue({ nameEn: e });
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={24} lg={12}>
              <Form.Item
                name="nameEn"
                label={`${t("name")} (EN)`}
                rules={[{ required: true }]}
              >
                <Selector
                  Source={roleEn}
                  disabled={!medicalRecord}
                  keyName="name"
                  keyValue="id"
                  onChange={(e) => {
                    formRole.setFieldsValue({ nameTh: e });
                  }}
                />
              </Form.Item>
            </Col>

            <Form.Item
              shouldUpdate={(prev, next) => prev?.nameEn !== next?.nameEn}
              noStyle
            >
              {(form: FormInstance) => {
                const name = form.getFieldValue("nameEn");
                if (name === ENUM_ROLE_EN.OTHER) {
                  return (
                    <>
                      <Col md={24} lg={12}>
                        <Form.Item
                          name="nameOtherTh"
                          label={`${t("nameOther")} (TH)`}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={24} lg={12}>
                        <Form.Item
                          name="nameOtherEn"
                          label={`${t("nameOther")} (EN)`}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </>
                  );
                }
              }}
            </Form.Item>

            <Col md={24} lg={24}>
              <Form.Item
                label={`${t("branch")}`}
                name="branchId"
                rules={[{ required: true }]}
              >
                <Selector
                  Source={branchList?.data?.result?.[0]?.data || []}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                  disabled={true}
                />
              </Form.Item>
            </Col>

            <Col md={15} lg={15}>
              <Form.Item name="permission" className="pt-1">
                <RoleForm formRole={formRole} medicalRecord={medicalRecord} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="fs-18">
          {i18n.language === "th" ? roleByID?.data?.TH : roleByID?.data?.EN}
        </div>
      )}
    </div>
  );
};

const RoleForm = ({ value, formRole, onChange, medicalRecord }: IRoleForm) => {
  const { t, i18n } = useTranslation();
  const onUpdateIsEdit = (e: boolean, name: string) => {
    const next = value?.map((item: IPermission) => {
      if (item?.nameEn === name) {
        if (e === true) {
          return {
            ...item,
            isEdit: e,
            subMenu: item?.subMenu?.map((subMenu) => {
              return { ...subMenu, isEdit: true };
            }),
          };
        } else {
          return {
            ...item,
            isEdit: e,
            subMenu: item?.subMenu?.map((subMenu) => {
              return { ...subMenu, isEdit: false };
            }),
          };
        }
      } else {
        return {
          ...item,
          subMenu: item?.subMenu?.map((subMenu) => {
            if (subMenu.nameEn === name) {
              return { ...subMenu, isEdit: e };
            } else {
              return subMenu;
            }
          }),
        };
      }
    });

    formRole.setFieldsValue({ ...formRole });

    onChange && onChange(next);
  };
  const onUpdate = (e: boolean, name: string) => {
    const next = value?.map((item: IPermission) => {
      if (item?.nameEn === name) {
        if (e === true) {
          return {
            ...item,
            active: e,
            isEdit: true,
            subMenu: item?.subMenu?.map((subMenu) => {
              return { ...subMenu, active: true };
            }),
          };
        } else {
          return {
            ...item,
            active: e,
            isEdit: false,
            subMenu: item?.subMenu?.map((subMenu) => {
              return { ...subMenu, active: false };
            }),
          };
        }
      } else {
        return {
          ...item,
          subMenu: item?.subMenu?.map((subMenu) => {
            if (subMenu?.nameEn === name) {
              return { ...subMenu, active: e };
            } else {
              return subMenu;
            }
          }),
        };
      }
    });

    formRole.setFieldsValue({ ...formRole });

    onChange && onChange(next);
  };

  return (
    <div>
      {value?.map((item, index) => {
        return (
          <div key={item?.nameEn}>
            <Row key={index} align="middle" className="pb-05">
              <Col span={20}>
                <Row gutter={[14, 14]} justify="center">
                  <Col span={24} className="role-col">
                    <Row className="role-col-area">
                      <Col>
                        {i18n.language === "th"
                          ? item?.nameTh || ""
                          : item?.nameEn || ""}
                      </Col>
                      <Col>
                        <Switch
                          disabled={!medicalRecord}
                          checked={item?.active}
                          onChange={(e: boolean) => onUpdate(e, item?.nameEn)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              {item?.subMenu?.length > 0 ? null : (
                <Col span={4} className="pl-1">
                  <Checkbox
                    disabled={
                      !medicalRecord ? true : item?.active ? false : true
                    }
                    checked={item?.isEdit}
                    onChange={(e: CheckboxChangeEvent) =>
                      onUpdateIsEdit(e?.target?.checked, item?.nameEn)
                    }
                  >
                    {`${t("edit")}/${t("delete")}`}
                  </Checkbox>
                </Col>
              )}
            </Row>

            {item?.subMenu?.map((subMenu: ISubMenu, i: number) => {
              return (
                <Row key={i} align="middle" className="pb-05">
                  <Col span={20}>
                    <Row key={subMenu?.nameEn} gutter={[14, 14]} justify="end">
                      <Col span={22} className="role-col">
                        <Row className="role-col-area">
                          <Col>
                            {i18n.language === "th"
                              ? subMenu?.nameTh || ""
                              : subMenu?.nameEn || ""}
                          </Col>
                          <Col>
                            <Switch
                              disabled={
                                !medicalRecord
                                  ? true
                                  : item?.active
                                  ? false
                                  : true
                              }
                              checked={subMenu?.active}
                              onChange={(e: boolean) =>
                                onUpdate(e, subMenu?.nameEn)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4} className="pl-1">
                    <Checkbox
                      checked={subMenu?.isEdit}
                      disabled={
                        !medicalRecord
                          ? true
                          : item?.active && subMenu?.active
                          ? false
                          : true
                      }
                      onChange={(e: CheckboxChangeEvent) =>
                        onUpdateIsEdit(e?.target?.checked, subMenu?.nameEn)
                      }
                    >
                      {`${t("edit")}/${t("delete")}`}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Fields;
