import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { decryptWithAES } from "@tools/util";
import { encryptWithAES } from "@tools/util";
import { useSelectBranch } from "@services/api/auth";

export interface BranchSelectedState {
  branchId: null | number;
  onChangeBranch: (v: number) => void;
}

export const BranchSelectedConext = createContext<BranchSelectedState>(
  {} as BranchSelectedState
);

export const BranchSeletedProvider: FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [branchId, setBranchID] = useState<number | null>(0);
  const { mutate: onSelectBranch } = useSelectBranch();

  useEffect(() => {
    const userinfo = decryptWithAES(localStorage.getItem("userInfo") || "{}");
    if (userinfo?.onSelect) {
      setBranchID(userinfo?.branchId);
    } else {
      setBranchID(0);
    }
  }, []);

  const onChangeBranch = (id: number) => {
    onSelectBranch(
      { branchId: id },
      {
        onSuccess: (res) => {
          const userInfo = encryptWithAES(JSON.stringify(res));
          localStorage.setItem("userInfo", userInfo || "");
          localStorage.setItem("accessToken", res?.accessToken);
          window.location.reload();
        },
        onError: (error) => {},
      }
    );
  };

  return (
    <BranchSelectedConext.Provider
      value={{
        branchId,
        onChangeBranch,
      }}
    >
      {children}
    </BranchSelectedConext.Provider>
  );
};

export const useBranchSelector = () => {
  try {
    return useContext(BranchSelectedConext);
  } catch (_) {
    throw Error(
      "Please, use component BranchSeletedProvider wrap your content."
    );
  }
};
