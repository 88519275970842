import { remarkSender } from "@assets/data/data";
import { FormInstance } from "antd/es/form";
import { useTranslation } from "react-i18next";
import { Col, Form, Input, Row, Table } from "antd";
import { FC, useEffect } from "react";
import TextArea from "antd/lib/input/TextArea";
import { useRouteMatch } from "react-router-dom";
import { useGetWarehouseSaleReturnById } from "@services/api/warehouse_sale_return";
import dayjs from "dayjs";
import BigNumber from "bignumber.js";

type Props = {
  manageBounceGoods: FormInstance;
};

const FieldsBounceGoods: FC<Props> = ({ manageBounceGoods }) => {
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const saleReturnById = match.params.id;
  const SalesReturnById = useGetWarehouseSaleReturnById(saleReturnById);

  const info: any = SalesReturnById?.data?.result?.[0];
  useEffect(() => {
    if (!info) return;
    manageBounceGoods.setFieldsValue({
      ...info,
      noOrder: info.boNumber,
      date: dayjs(info.createAt).format("DD-MM-YYYY"),
      address: info.saleOrder.prepareOrder.branch?.addressFull,
      remarkSender: remarkSender,
      netPrice: info.total,
    });
    // eslint-disable-next-line
  }, [info]);

  const Columns: any = [
    {
      title: "#",
      dataIndex: "key",
      // onCell: (d: any, _index: number) => {
      //   return {
      //     colSpan: d?.remark ? 1 : 1,
      //   };
      // },
      render: (ind: number, row: any) => {
        return row?.remark ? (
          <Row align={"middle"} className="pl-1" style={{ color: "red" }}>
            <div className="pr-1">*</div>
            <div className="pr-1">หมายเหตุ :</div>
            <div>{row?.remark}</div>
          </Row>
        ) : (
          ind
        );
      },
    },
    {
      title: "SKU",
      dataIndex: "product",
      render: (product: any) => {
        return product?.group?.sku;
      },
    },
    {
      title: "Sub SKU",
      dataIndex: "sku",
      render: (sku: string) => {
        return sku;
      },
    },
    {
      title: t("productName"),
      dataIndex: "product",
      render: (product: any) => {
        return i18n.language === "th"
          ? product?.nameTh || product?.nameEn
          : product?.nameEn || product?.nameTh;
      },
    },
    {
      title: t("price"),
      dataIndex: "unitPrice",
      render: (unitPrice: any) => {
        return unitPrice;
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",

      render: (data: any) => {
        return <span>{data}</span>;
      },
    },

    {
      title: t("unit"),
      dataIndex: "product",

      render: (data: any) => {
        return data ? data?.unit || "ชิ้น" : undefined;
      },
    },
    {
      title: `${t("totalPrice")} (฿)`,
      dataIndex: "total",

      render: (total: string) => {
        return total;
      },
    },
  ];

  return (
    <div>
      <Form form={manageBounceGoods}>
        <Row gutter={[14, 14]}>
          <Col span={12}>
            <Form.Item
              name="noOrder"
              label={t("boNumber")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="date"
              label={t("orderDate")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="remarkSender"
              label={t("remarkSender")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="address"
              label={t("orderDetails")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} className="header-card">
            {t("productDetails")}
          </Col>

          <Col span={24}>
            <Table
              rowKey="key"
              scroll={{ x: 1100 }}
              columns={Columns}
              dataSource={info?.salesReturnItems?.flatMap(
                (item: any, idx: any) => {
                  return [
                    {
                      createdAt: item?.createdAt,
                      id: item?.id,
                      sku: item?.product.sku,
                      name: item?.product.nameTh,
                      productId: item?.productId,
                      product: item?.product,
                      total: item?.total,
                      unitPrice: item?.unitPrice,
                      qty: item?.qty,
                      key: (idx + 1).toString(),
                      updatedAt: item?.updatedAt,
                    },
                    {
                      key: `remark${(idx + 1).toString()}`,
                      remark: item?.remark || "",
                    },
                  ];
                }
              )}
            />
          </Col>

          <Col span={12}>
            <Form.Item label={t("remarks")}>
              <TextArea autoSize={{ minRows: 3, maxRows: 4 }} />
            </Form.Item>
          </Col>

          <Col style={{ marginLeft: "auto" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ color: "#757783" }} className="pr-1 ">
                {t("theTotalAmount")}
              </div>
              <div
                style={{
                  background: "#F5F5F5",
                  borderRadius: 5,
                  fontSize: 16,
                  height: 42,
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="pl-1 pr-1"
              >
                <Form.Item
                  shouldUpdate={(prev, next) => prev.netPrice !== next.netPrice}
                >
                  {(form: FormInstance) => {
                    const netPrice = form.getFieldValue("netPrice");
                    return (
                      <>
                        <span>฿</span>
                        <span style={{ marginLeft: "5px" }}>
                          {new BigNumber(netPrice || 0).toFormat(2)}
                        </span>
                      </>
                    );
                  }}
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default FieldsBounceGoods;
