import { Col, Row } from "antd";
import { stepOPDState } from "@tools/recoil/recoil-atom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import BasicInfoWhite from "@assets/img/icon/OPD/basicInfoWhite.svg";
import ContractCompanyGray from "@assets/img/icon/OPD/contractCompanyGray.svg";
import ContractCompanyWhite from "@assets/img/icon/OPD/contractCompanyWhite.svg";
import OtherImportantInfoGray from "@assets/img/icon/OPD/otherImportantInfoGray.svg";
import OtherImportantInfoWhite from "@assets/img/icon/OPD/otherImportantInfoWhite.svg";

const StepOPD = () => {
  const { t } = useTranslation();
  const [stepOPD] = useRecoilState<number>(stepOPDState);
  return (
    <Row className="mt-05 mb-05" justify="start">
      {/* <Col className="stepper-wrapper "> */}
      <Col
        span={4}
        className={stepOPD === 1 ? "stepper-item " : "stepper-item completed"}
      >
        <Col span={8}>
          <Row justify="center" style={{ alignItems: "center" }}>
            <div className="step-counter">
              <img src={BasicInfoWhite} alt="basicInfoWhite" />
            </div>
          </Row>
        </Col>

        <Col span={9}>
          <div className="stepAction-t">{t("step")} 1</div>
          <div className="stepActionTopic-t">{t("basicInfo")}</div>
        </Col>
      </Col>

      <Col
        span={4}
        className={
          stepOPD === 1
            ? "stepper-item "
            : stepOPD === 3
            ? "stepper-item completed"
            : "stepper-item "
        }
      >
        <Col span={14}>
          <Row justify="center" style={{ alignItems: "center" }}>
            <div className={stepOPD === 1 ? "step-active" : "step-counter "}>
              <img
                src={
                  stepOPD === 1
                    ? OtherImportantInfoGray
                    : OtherImportantInfoWhite
                }
                alt="otherImportantInfoGray"
              />
            </div>
          </Row>
        </Col>

        <Col span={15}>
          <div
            className={
              stepOPD === 2 || stepOPD === 3 ? "stepAction-t" : "step-t"
            }
          >
            {t("step")} 2
          </div>
          <div
            className={
              stepOPD === 2 || stepOPD === 3
                ? "stepActionTopic-t"
                : "stepTopic-t"
            }
          >
            {t("otherImportantInfo")}
          </div>
        </Col>
      </Col>

      <Col span={4} className="stepper-item">
        <Col span={14}>
          <Row justify="center" style={{ alignItems: "center" }}>
            <div className={stepOPD === 3 ? " step-counter" : "step-active"}>
              <img
                src={stepOPD === 3 ? ContractCompanyWhite : ContractCompanyGray}
                alt="contractCompanyWhite"
              />
            </div>
          </Row>
        </Col>

        <Col span={15}>
          <div className={stepOPD !== 3 ? "step-t" : "stepAction-t"}>
            {t("step")} 3
          </div>
          <div className={stepOPD !== 3 ? "stepTopic-t" : "stepActionTopic-t"}>
            {t("contractingCompany")}
          </div>
        </Col>
      </Col>
      {/* </Col> */}
    </Row>
  );
};

export default StepOPD;
