import React, { useState } from "react";
import { PROMOTION } from "@assets/data/pathRouter";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Search from "./Search";
import RoleContext from "@provider/RoleProvider";
import TablePromotion from "./Table";
import MainPromotionProvider from "@provider/promotion";

const Promotion = () => {
  const [search, setSearch] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PROMOTION";
  });

  const createPromotion = () => {
    history.push(`${PROMOTION.create}`);
  };
  return (
    <MainPromotionProvider
      value={{
        search: search,
        setSearch: setSearch,
        currentPage: currentPage,
        setCurrentPage,
      }}
    >
      <div>
        <Row className="second-header " justify="space-between" align="middle">
          <Col>
            <div>
              <h1>{t("promotion.title")}</h1>
            </div>

            <span className="t-dark-blue f-normal">
              <b>{t("marketing.title")}</b>
            </span>
          </Col>
          {medicalRecord?.[0]?.isEdit ? (
            <Col lg={2} className="justify-col">
              <Button
                className="btn-create"
                type="primary"
                onClick={createPromotion}
              >
                <PlusOutlined className="icons-size" /> {t("promotion.create")}
              </Button>
            </Col>
          ) : null}
        </Row>
        <div className="set-layout ">
          <Card
            className="bg-grey "
            extra={<b className="header-card ">{t("promotion.all")}</b>}
          >
            <Search />
            <TablePromotion />
          </Card>
        </div>
      </div>
    </MainPromotionProvider>
  );
};

export default Promotion;
