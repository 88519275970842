import { t } from "i18next";
import { FC } from "react";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";

const { Option } = Select;
interface Props extends SelectProps<any> {
  Source: any[];
  keyName: string;
  keyValue: string;
}

type Prototype = {
  Translation: FC<Props>;
};

const Selector: FC<Props> & Prototype = ({
  bordered = true,
  className,
  Source = [],
  keyName,
  keyValue,
  placeholder = `${t("selectItem")}`,
  disabled = false,
  ...props
}) => {
  return (
    <Select
      bordered={bordered}
      className={className}
      showSearch
      placeholder={placeholder}
      allowClear
      disabled={disabled}
      optionFilterProp="children"
      {...props}
    >
      {Source?.map((item, idx) => (
        <Option value={item[keyValue]} key={idx}>
          {item[keyName]}
        </Option>
      ))}
    </Select>
  );
};
const Translation: FC<Props> = ({
  bordered = true,
  className,
  Source = [],
  keyName,
  keyValue,
  placeholder = `${t("selectItem")}`,
  disabled = false,
  ...props
}) => {
  return (
    <Select
      bordered={bordered}
      className={className}
      showSearch
      placeholder={placeholder}
      allowClear
      disabled={disabled}
      optionFilterProp="children"
      {...props}
      filterOption={(input, option: any) => {
        return option?.children?.toLowerCase()?.includes(input?.toLowerCase());
      }}
    >
      {Source?.map((item, idx) => (
        <Option value={item[keyValue]} key={idx}>
          {t(item[keyName])}
        </Option>
      ))}
    </Select>
  );
};

Selector.Translation = Translation;
export default Selector;
