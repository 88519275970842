import { t } from "i18next";
import { Select } from "antd";
import { SelectProps } from "antd/lib/select";

const { Option } = Select;

interface Props extends SelectProps<any> {
  Source: any[];
  keyName: string;
  keyValue: string;
}

const SelectorChildrenValue = ({
  bordered = true,
  className,
  Source = [],
  onChange,
  value,
  keyName,
  keyValue,
  placeholder = `${t("selectItem")}`,
  options,
  disabled = false,
  id,
}: Props) => {
  return (
    <Select
      id={id}
      bordered={bordered}
      className={className}
      showSearch
      placeholder={placeholder}
      onChange={onChange}
      allowClear
      value={value}
      disabled={disabled}
      optionFilterProp="children"
      {...options}
      filterOption={(input, option: any) => {
        return (
          option?.children?.toLowerCase()?.includes(input) ||
          `${input}` === `${option.value}`
        );
      }}
    >
      {Source?.map((item, idx) => (
        <Option value={item?.[keyValue]} key={idx}>
          {item?.[keyName]}
        </Option>
      ))}
    </Select>
  );
};

export default SelectorChildrenValue;
