import { thrownResponse } from "./auth";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { Elementary, IParamet } from "@interface/IGlobal.interface";
import { useQuery, UseQueryResult } from "react-query";
import { IItem, IOrderListAndRecord } from "@interface/IOrder.interface";
import { IRejectCourseOrderResponse } from "@interface/IOrder.interface";
import { reformatOutStanding, _isEmpty } from "@tools/util";
import { IGetProduct, IProductLabOther } from "@interface/IProduct.interface";
import { useMutation, UseMutationResult } from "react-query";
import { IOrder, IReject, IRejectCourse } from "@interface/IOrder.interface";
import { ICategories, IRejectCourseReport } from "@interface/IOrder.interface";
import axios from "@axios/index";

export const useGetCategories = (
  params?: any
): UseQueryResult<Elementary<ICategories[]>> => {
  return useQuery(["get-categories", params], async () => {
    const res = await axios.get(`/product-category`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetProducts = (
  id: string,
  params?: any
): UseQueryResult<Elementary<IGetProduct[]>> => {
  return useQuery(
    ["get-products", id, params],
    async () => {
      const res = await axios.get(`/product/${id}`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !id ? false : true }
  );
};

export const useGetProductLabOther = (
  params?: any
): UseQueryResult<ElementaryOneMore<IProductLabOther>> => {
  return useQuery(["get-product-lab-other", params], async () => {
    const res = await axios.get(`/product/lab-other`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetOrderList = (
  params?: any
): UseQueryResult<any | ElementaryOneMore<IOrder>> => {
  return useQuery(["get-Order-list", params], async () => {
    const res = await axios.get(`/order`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetOrderGroupsOutstanding = (
  id: number,
  params?: any
): UseQueryResult<any | ElementaryOneMore<IOrder>> => {
  return useQuery(["get-order-groups-outstanding ", id, params], async () => {
    const res = await axios.get(`/order/groups/${id}/visit/outstanding`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetOrderListOutstanding = (
  id: number
): UseQueryResult<IParamet<IOrderListAndRecord[]>> => {
  return useQuery(
    ["order-list-outstanding", id],
    async () => {
      if (!id && id !== 0) {
        return null;
      }
      const res = await axios.get(`order/groups/${id}/outstanding`);

      if (res?.status >= 200 && res?.status < 300) {
        const dataList = reformatOutStanding(res?.data?.result);

        return { ...res?.data, result: dataList };
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !_isEmpty(id) }
  );
};

export const useGetOrderListOutstandingCourse = (
  id: number
): UseQueryResult<IParamet<IOrderListAndRecord[]>> => {
  return useQuery(["order-list-outstanding-course", id], async () => {
    const res = await axios.get(`/order/groups/${id}/outstanding-course`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateOrdersDone = (): UseMutationResult<any, Error> => {
  return useMutation(["create-orders-done"], async ({ data, id }: any) => {
    const res = await axios.post(`/order/done/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetOrderRejectList = (
  id: string | number,
  params?: any
): UseQueryResult<Elementary<IReject[]>> => {
  return useQuery(
    ["order-list-outstanding-reject-list", id, params],
    async () => {
      if (!id && id !== 0) {
        return null;
      }
      const res = await axios.get(`/order/groups/${id}/reject`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !id && id !== 0 && id !== "undefined" ? false : true }
  );
};

export const useGetOrderSignature = () => {
  return useMutation(["order-reject-course"], async ({ data, id }: any) => {
    const res = await axios.put(`/order/${id}/signature`, data);
    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateOrderToVisit = (): UseMutationResult<any, Error> => {
  return useMutation(async ({ visitId, id }: any) => {
    const res = await axios.post(`/order/${id}/order-To-visit/${visitId}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateTransformCourses = (): UseMutationResult<any, Error> => {
  return useMutation(
    ["create-transform-courses"],
    async ({ data, id }: any) => {
      const res = await axios.post(`/order/${id}/transform-course`, data);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useCreateAssignCourses = (): UseMutationResult<any, Error> => {
  return useMutation(["create-assign-courses"], async ({ data, id }: any) => {
    const res = await axios.post(`/order/${id}/assign-course`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateRejectCourses = (): UseMutationResult<
  ElementaryOneMore<IRejectCourseOrderResponse>,
  Error
> => {
  return useMutation(["create-reject-courses"], async ({ data, id }: any) => {
    const res = await axios.post(`/order/${id}/reject-course`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateRejectCourses = (): UseMutationResult<
  ElementaryOneMore<IRejectCourseOrderResponse>,
  Error
> => {
  return useMutation(async ({ data, id }: any) => {
    const res = await axios.put(`/order/${id}/receipt/reject-course`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetRejectCourse = (
  id: number,
  params: any
): UseQueryResult<Elementary<IRejectCourse[]>> => {
  return useQuery(["order-reject-course", id, params], async () => {
    const res = await axios.get(`/order/${id}/reject-course`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetRejectCourseDetail = (
  id: number
): UseQueryResult<IParamet<IRejectCourseReport[]>> => {
  return useQuery(["order-reject-course", id], async () => {
    const res = await axios.get(`/order/${id}/reject-course/detail`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetOrderHistory = (
  id: number,
  params?: any
): UseQueryResult<Elementary<IItem[]>> => {
  return useQuery(["order-history", id, params], async () => {
    const res = await axios.get(`/order/groups/${id}/history`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
