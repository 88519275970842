import { t } from "i18next";
import { Age } from "@tools/age";
import { Col, Row } from "antd";
import { Elementary } from "@interface/IGlobal.interface";
import { IGroupLabInterface } from "@interface/ILab.interface";
import { Component, Fragment } from "react";
import { IFormatLabInterface } from "@interface/ILab.interface";
import { IChildTestCode, ICompare } from "@interface/ILab.interface";
import dayjs from "dayjs";
import ImageLogo from "@components/image/ImageLogo";

type Props = {
  ref: any;
  compare?: Elementary<ICompare[] & IFormatLabInterface[]>;
  i18n: string;
  startDate: string;
};

class Print extends Component<Props> {
  render() {
    return (
      <div>
        <div>
          <Row
            style={{ width: "100%" }}
            className="t-start document-border-header p-05"
          >
            <Col span={6}>
              <div className="mt-1">
                <ImageLogo
                  height="50px"
                  width="120px"
                  src={this.props?.compare?.result?.[0]?.branch}
                  alt="logo"
                />
              </div>
            </Col>
            <Col span={18}>
              <Row>
                <Col>
                  <div>Name:</div>
                </Col>
                <Col span={13} className="t-underline">
                  <div className=" f-normal" style={{ paddingLeft: 10 }}>
                    {this.props?.i18n === "th"
                      ? `${
                          this.props?.compare?.result?.[0]?.patient?.titleTh ||
                          ""
                        }${[
                          this.props?.compare?.result?.[0]?.patient
                            ?.firstNameTh,
                          this.props?.compare?.result?.[0]?.patient?.lastNameTh,
                        ]
                          ?.join(" ")
                          ?.trim()}`
                      : `${
                          this.props?.compare?.result?.[0]?.patient?.titleEn ||
                          ""
                        }${[
                          this.props?.compare?.result?.[0]?.patient
                            ?.firstNameEn,
                          this.props?.compare?.result?.[0]?.patient?.lastNameEn,
                        ]
                          ?.join(" ")
                          ?.trim()}`}
                  </div>
                </Col>

                <Col>Date:</Col>

                <Col span={6} className="t-underline">
                  <div className=" f-normal" style={{ paddingLeft: 10 }}>
                    {`${dayjs().format("DD-MM-YYYY")} `}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col className="mt-05">HN:</Col>

                <Col style={{ minWidth: 150 }} className="t-underline mt-05">
                  <div className=" f-normal" style={{ paddingLeft: 10 }}>
                    {`${this.props?.compare?.result?.[0]?.patient?.hn || ""}`}
                  </div>
                </Col>

                <Col className="mt-05">Birth Date:</Col>

                <Col style={{ minWidth: 120 }} className="t-underline mt-05">
                  <div className="f-normal" style={{ paddingLeft: 10 }}>
                    {`${
                      this.props?.compare?.result?.[0]?.patient?.dateOfBirth ||
                      ""
                    }`}
                  </div>
                </Col>

                <Col className="mt-05">Age :</Col>

                <Col span={2} className="t-underline mt-05">
                  <div className="f-normal" style={{ paddingLeft: 10 }}>
                    {Age(
                      this.props?.compare?.result?.[0]?.patient?.dateOfBirth ||
                        ""
                    )}
                  </div>
                </Col>

                <Col className="mt-05">y</Col>
              </Row>

              <Row>
                <Col className="mt-05">Physician:</Col>

                <Col
                  style={{ minWidth: 170, paddingLeft: 10 }}
                  className="t-underline mt-05"
                >
                  <div className="f-normal"></div>
                </Col>

                <Col className="mt-05">Allergies :</Col>

                <Col
                  style={{ minWidth: 170, paddingLeft: 10 }}
                  className="t-underline mt-05 t-red"
                >
                  <div className="f-normal">
                    {` ${[
                      this.props?.compare?.result?.[0]?.patient?.drugAllergy,
                      this.props?.compare?.result?.[0]?.patient?.foodAllergy,
                    ]
                      ?.join(" / ")
                      ?.trim()}`}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="t-center fs-18 f-bold mt-2 mb-1 ">
            {t("labComparison")}
          </div>
          <table width="100%">
            <thead>
              <tr
                className=" lab-i-body lab-h print-marker"
                style={{ backgroundColor: "#498dcb", color: "white" }}
              >
                <th className="print-marker-right" rowSpan={2}>
                  {t("testName")}
                </th>
                <th className="print-marker-right" rowSpan={2}>
                  {t("Reference Range")}
                </th>
                {this.props?.compare?.result?.[0]?.date?.map(
                  (_: string, index: number) => {
                    return (
                      <th
                        key={index}
                        className={
                          this.props?.compare?.result?.[0]?.date?.length ===
                          index
                            ? ""
                            : "print-marker-right"
                        }
                      >
                        {" "}
                        Date{" "}
                      </th>
                    );
                  }
                )}
              </tr>
              <tr
                style={{ backgroundColor: "#498dcb", color: "white" }}
                className=" lab-i-body lab-h print-marker"
              >
                {this.props?.compare?.result?.[0]?.date?.map(
                  (day: string, index: number) => {
                    return (
                      <th
                        key={index}
                        className={
                          this.props?.compare?.result?.[0]?.date?.length ===
                          index
                            ? ""
                            : "print-marker-right"
                        }
                      >
                        {dayjs(day).format("DD-MM-YYYY")}
                      </th>
                    );
                  }
                )}
              </tr>
            </thead>
            <tbody>
              {this.props?.compare?.result?.[0]?.data?.map(
                (item: IFormatLabInterface, index: number) => {
                  return (
                    <Fragment key={index}>
                      <tr className="print-marker-right print-marker-left">
                        {item?.group?.length === 0 ? null : (
                          <td
                            style={{
                              backgroundColor: "#B0C9E1",
                              color: "white",
                            }}
                            key={item?.testCode}
                            colSpan={12}
                            className="print-marker-right "
                          >
                            <div className="">{item?.testCode}</div>
                          </td>
                        )}
                      </tr>
                      {item?.group?.map(
                        (itemGroup: IGroupLabInterface, chidIndex: number) => {
                          return (
                            <tr key={chidIndex} className="print-marker">
                              <td className="print-marker-right ">
                                {itemGroup?.testName}
                              </td>
                              <td className="print-marker-right ">
                                {itemGroup?.normalRange
                                  ? itemGroup?.normalRange +
                                    " " +
                                    itemGroup?.unit
                                  : null}
                              </td>
                              {this.props?.compare?.result?.[0]?.date?.map(
                                (day: string, chIndex: number) => {
                                  if (itemGroup?.group?.hasOwnProperty(day)) {
                                    return (
                                      <td
                                        key={chIndex}
                                        className="print-marker-right "
                                      >
                                        {itemGroup?.group[
                                          day as keyof typeof itemGroup.group
                                        ]?.map(
                                          (
                                            item: IChildTestCode,
                                            index1: number
                                          ) => {
                                            return (
                                              <div
                                                key={index1}
                                                style={{
                                                  display: "inline-block",
                                                }}
                                              >
                                                <span
                                                  key={item?.date}
                                                  style={{
                                                    color: item?.flagLowHigh
                                                      ? "red"
                                                      : "black",
                                                    display: "inline-block",
                                                  }}
                                                >
                                                  {index1 !==
                                                  itemGroup?.group[
                                                    day as keyof typeof itemGroup.group
                                                  ]?.length -
                                                    1
                                                    ? item?.result +
                                                      item?.flagLowHigh
                                                    : item?.result +
                                                      item?.flagLowHigh}
                                                </span>
                                                {index1 !==
                                                itemGroup?.group[
                                                  day as keyof typeof itemGroup.group
                                                ]?.length -
                                                  1 ? (
                                                  <span>,&nbsp;</span>
                                                ) : (
                                                  ""
                                                )}
                                              </div>
                                            );
                                          }
                                        )}
                                      </td>
                                    );
                                  } else {
                                    return (
                                      <td
                                        className="print-marker-right "
                                        key={chIndex}
                                      ></td>
                                    );
                                  }
                                }
                              )}
                            </tr>
                          );
                        }
                      )}
                    </Fragment>
                  );
                }
              )}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default Print;
