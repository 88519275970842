import { useState } from "react";

const FloatLabel = (props: {
  children: any;
  label: string;
  value?: any;
  name?: string;
  style?: any;
}) => {
  const [focus, setFocus] = useState(false);
  const [valueBlur, setValueBlur] = useState("");
  const { children, label, value, style } = props;
  const labelClass =
    focus || (value && value?.length !== 0) || valueBlur
      ? focus
        ? "label label-float-focused"
        : "label label-float"
      : "label-custom-float";
  return (
    <div
      className="float-label"
      onBlur={(e: React.FocusEvent<HTMLInputElement>) => {
        const newValue = e?.target?.value;
        setValueBlur(newValue);
        setFocus(false);
      }}
      style={style}
      onFocus={() => setFocus(true)}
    >
      {children}
      <label className={labelClass}>{label}</label>
    </div>
  );
};

export default FloatLabel;
