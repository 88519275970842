import { useTranslation } from "react-i18next";
import { Card, Col, Row, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";

const ImportantInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="fs-15 fw-900 mt-05 f-normal ">
        {t("otherImportantInfo")}
      </div>

      <Card className="mt-05">
        <Row gutter={[14, 14]} className="margin-form">
          <Col lg={12} sm={24}>
            <div className="text-color-form">{t("drugAllergy")} :</div>
            <Form.Item name="drugAllergy" className="foodAllergy">
              <TextArea autoSize={{ minRows: 1, maxRows: 2 }} disabled />
            </Form.Item>
          </Col>

          <Col lg={12} sm={24}>
            <div className="text-color-form">{t("foodAllergy")} :</div>
            <Form.Item name="foodAllergy" className="foodAllergy">
              <TextArea autoSize={{ minRows: 1, maxRows: 2 }} disabled />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default ImportantInfo;
