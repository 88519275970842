import React, { useEffect, useRef, useState } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Form } from "antd";
import { painManagement } from "@assets/data/pathRouter";
import { useGetVisitsByID } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { useSetRecoilState } from "recoil";
import { ENUM_SERVICE_GROUP } from "@tools/util.enum";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { RouteComponentProps } from "react-router-dom";
import { useCreateWorkOrdersUse } from "@services/api/work_order";
import { useGetVisitOrderHistory } from "@services/api/visits";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetWorkOrdersOutStanding } from "@services/api/work_order";
import Fields from "./Fields";
import Header from "./Header";
import RoleContext from "@provider/RoleProvider";
import ReportPhysiotherapy from "@components/report_physiotherapy/ReportPhysiotherapy";
import ReportNotesPhysical from "@components/report_notes_physical/ReportNotesPhysical";
import MainPatientByIdProvider from "@provider/patient_by_id";
import { handleMassageError } from "@tools/util";

const FormInchart = () => {
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const visitID = match.params.visitID;
  const { data } = useGetVisitsByID(visitID);
  const history = useHistory<RouteComponentProps["history"]>();
  const [patientList] = Form.useForm();
  const printReportRef = useRef(null);
  const printReportPhysical = useRef(null);
  const setLoadingButton = useSetRecoilState<boolean>(loadingButtonState);
  const createWorkOrdersUse = useCreateWorkOrdersUse();
  const [open, setOpen] = useState({
    oneDay: true,
    continuous: true,
    soap: false,
    notes: false,
  });
  const ordersList = useGetVisitOrderHistory(visitID);
  const workOrdersOutStandingList = useGetWorkOrdersOutStanding(patientID, {
    serviceGroup: ENUM_SERVICE_GROUP.PHYSICAL_THERAPY,
    visitId: visitID,
  });

  const pageStyle = `  @page { size:A4;
    margin: 60px;}`;
  const pageStylePhysical = `  @page { size:A4;
      margin: 80px;}`;

  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PHYSICAL_THERAPY";
  });
  const [divHeight, setDivHeight] = useState<number>(
    document.getElementById("container-physical")?.clientHeight || 0
  );

  useEffect(() => {
    setLoadingButton(createWorkOrdersUse.isLoading);

    // eslint-disable-next-line
  }, [createWorkOrdersUse.isLoading]);

  useEffect(() => {
    setDivHeight(
      document.getElementById("container-physical")?.clientHeight || 0
    );
    // eslint-disable-next-line
  }, [document.getElementById("container-physical")?.clientHeight]);

  useEffect(() => {
    patientList?.setFieldsValue({
      foodAllergy: data?.patient?.foodAllergy || undefined,
      drugAllergy: data?.patient?.drugAllergy || undefined,
    });
    setOpen({ ...open, notes: !!data?.physicalNote, soap: !!data?.soapNote });

    // eslint-disable-next-line
  }, [data]);

  const uuidList = () => {
    let status = true;
    const productOneDay = workOrdersOutStandingList?.data?.one?.groups?.flatMap(
      (group) => {
        return group?.children?.flatMap((product) => {
          return product?.children?.flatMap((childProduct) => {
            if (
              childProduct?.product?.categoryId === "C-0006" ||
              childProduct?.product?.categoryId === "C-0007"
            ) {
              return childProduct?.children?.[0]?.children?.flatMap((child) => {
                if ((child.status as any) === "NEW") {
                  status = false;
                }
                return { uuid: child?.uuid, use: child?.use };
              });
            } else {
              if ((childProduct.status as any) === "NEW") {
                status = false;
              }
              return { uuid: childProduct?.uuid, use: childProduct?.use };
            }
          });
        });
      }
    );

    const productContinuous =
      workOrdersOutStandingList?.data?.continuous?.groups?.flatMap((group) => {
        return group?.children?.flatMap((product) => {
          return product?.children?.flatMap((childProduct) => {
            if (
              childProduct?.product?.categoryId === "C-0006" ||
              childProduct?.product?.categoryId === "C-0007"
            ) {
              return childProduct?.children?.[0]?.children?.flatMap((child) => {
                if ((child.status as any) === "NEW") {
                  status = false;
                }
                return { uuid: child?.uuid, use: child?.use };
              });
            } else {
              if ((childProduct.status as any) === "NEW") {
                status = false;
              }
              return { uuid: childProduct?.uuid, use: childProduct?.use };
            }
          });
        });
      });
    return {
      uuid: [...(productOneDay || []), ...(productContinuous || [])],
      isSuccess: status,
    };
  };

  const workOrderId = () => {
    let data;
    if (workOrdersOutStandingList?.data?.continuous?.groups?.[0]?.children) {
      data =
        workOrdersOutStandingList?.data?.continuous?.groups?.[0]?.children?.[0]
          ?.children?.[0]?.workOrderId;
    } else if (workOrdersOutStandingList?.data?.one?.groups?.[0]?.children) {
      data =
        workOrdersOutStandingList?.data?.one?.groups?.[0]?.children?.[0]
          ?.children?.[0]?.workOrderId;
    }
    return data ? data : undefined;
  };

  const handleCreateWorkOrderUse = () => {
    let id = workOrderId();
    let { uuid, isSuccess } = uuidList();
    if (isSuccess) {
      createWorkOrdersUse.mutate(
        {
          data: uuid,
          id: visitID,
          workOrderId: id,
        },
        {
          onSuccess: () => {
            history.replace(painManagement);
          },
          onError: (err) => {
            openNotification({
              method: "POST_ERROR",
              message: String(err),
            });
          },
        }
      );
    } else {
      openNotification({
        method: "PUT_ERROR",
        message: handleMassageError({
          TH: "กรุณายืนยันรายการที่ต้องการทำงานก่อน",
          EN: "Please confirm the items you want to work on first.",
        }),
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  const handlePrintPhysical = useReactToPrint({
    content: () => printReportPhysical.current,
    pageStyle: pageStylePhysical,
  });

  return (
    <MainPatientByIdProvider value={{ patientById: data?.patient }}>
      <>
        <Header
          history={history}
          handleCreateWorkOrderUse={handleCreateWorkOrderUse}
          handlePrint={handlePrint}
          handlePrintPhysical={handlePrintPhysical}
          medicalRecord={medicalRecord?.[0]?.isEdit}
          isSignature={data?.isSignature?.physicalTherapyRoom}
        />
        <div className="set-layout ">
          <Fields
            patientID={patientID}
            visitsByID={data}
            patientList={patientList}
            orderList={workOrdersOutStandingList?.data}
            medicalRecord={medicalRecord?.[0]?.isEdit}
            open={{ open: open, setOpen: setOpen }}
          />
        </div>

        <ReactToPrint content={() => printReportRef.current} />
        <div>
          <div style={{ display: "none" }}>
            <ReportPhysiotherapy
              refData={printReportRef}
              visitsByID={data}
              ordersList={ordersList?.data?.result?.[0]}
            />
          </div>
          <ReactToPrint content={() => printReportPhysical.current} />
          <div style={{ display: "none" }}>
            <ReportNotesPhysical
              refData={printReportPhysical}
              visitsByID={data}
              divHeight={divHeight}
            />
          </div>
        </div>
      </>
    </MainPatientByIdProvider>
  );
};

export default FormInchart;
