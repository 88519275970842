import { USER_MANAGE } from "@assets/data/pathRouter";
import { PlusOutlined } from "@ant-design/icons";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { ITabsState, tabsState } from "@tools/recoil/recoil-atom";
import { Button, Card, Col, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import React from "react";
import RoleContext from "@provider/RoleProvider";
import TabsUserManage from "./Tabs";

const obj: {
  [key: string]: { translation: string; path: string; action: string };
} = {
  1: {
    translation: "userInfo",
    path: `${USER_MANAGE.createUser}`,
    action: "createUser",
  },
  2: {
    translation: "roleInfo",
    path: `${USER_MANAGE.createRole}`,
    action: "createRole",
  },
  3: {
    translation: "roomInfo",
    path: `${USER_MANAGE.createRoom}`,
    action: "createRoom",
  },
  4: {
    translation: "doctorRoomInfo",
    path: `${USER_MANAGE.createUserRoom}`,
    action: "createDoctorRoom",
  },
};

const UserManage = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const [tab, setTab] = useRecoilState<ITabsState>(tabsState);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "USER";
  });

  const handleCreateGeneral = () => {
    const path = obj[tab.userManage].path;
    history.push(path);
  };

  return (
    <div>
      <Row className="second-header " align="middle">
        <Col>
          <div>
            <h1>{t("userManage")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t(obj[tab.userManage].translation)}</b>
          </span>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="btn-create"
              type="primary"
              onClick={handleCreateGeneral}
            >
              <PlusOutlined className="icons-size" />
              {t(obj[tab.userManage].action)}
            </Button>
          </Col>
        ) : null}
      </Row>
      <div className="set-layout ">
        <Card className="bg-grey ">
          <TabsUserManage tab={{ tab: tab, setTab: setTab }} />
        </Card>
      </div>
    </div>
  );
};

export default UserManage;
