import { IPatientByIdContext } from "./interface";
import { createContext, useContext } from "react";

const MainPatientByIdContext = createContext<IPatientByIdContext>(
  {} as IPatientByIdContext
);

const MainPatientByIdProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: IPatientByIdContext;
}) => {
  return (
    <MainPatientByIdContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </MainPatientByIdContext.Provider>
  );
};

export const useMainPatientById = () => useContext(MainPatientByIdContext);
export default MainPatientByIdProvider;
