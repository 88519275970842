import { discount } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { ISelectedRowKeys } from "@interface/IGlobal.interface";
import { ITotalAndQuantity } from "@interface/IGlobal.interface";
import { Card, Col, Divider, Row } from "antd";
import Decimal from "@tools/decimal";
import BigNumber from "bignumber.js";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  selectedRowKeys: ISelectedRowKeys;
  status?: string;
  totalAndQuantity: ITotalAndQuantity;
  className: string;
};

const OrderSummary = ({
  selectedRowKeys,
  status = "",
  totalAndQuantity,
  className = "",
}: Props) => {
  const { t } = useTranslation();

  const onChange = (select: number) => {
    totalAndQuantity?.setTotalAndQuantity({
      ...totalAndQuantity?.totalAndQuantity,
      selector: select ? select : 2,
      discountTotal: Number(
        new BigNumber(totalAndQuantity?.totalAndQuantity?.oneDay?.total || 0)
          .plus(totalAndQuantity?.totalAndQuantity?.continuous?.total || 0)
          .plus(totalAndQuantity?.totalAndQuantity?.labInterface?.total)
      ),
      discount: 0.0,
      percent: 0.0,
    });
  };

  const totalDiscount = () => {
    let data;
    if (
      totalAndQuantity?.totalAndQuantity?.oneDay?.total ||
      totalAndQuantity?.totalAndQuantity?.continuous?.total ||
      totalAndQuantity?.totalAndQuantity?.labInterface?.total
    ) {
      if (totalAndQuantity?.totalAndQuantity?.selector === 2) {
        data = Decimal(
          Number(
            new BigNumber(
              totalAndQuantity?.totalAndQuantity?.discount || 0
            ).toFixed(2)
          )
        );
      } else if (totalAndQuantity?.totalAndQuantity?.selector === 1) {
        data = Decimal(
          Number(
            new BigNumber(
              totalAndQuantity?.totalAndQuantity?.percent ||
                totalAndQuantity?.totalAndQuantity?.total
            ).toFixed(2)
          )
        );
      } else
        data = new BigNumber(totalAndQuantity?.totalAndQuantity?.total).toFixed(
          2
        );
    } else
      data = new BigNumber(totalAndQuantity?.totalAndQuantity?.total).toFixed(
        2
      );
    return data;
  };

  const total = () => {
    let data;
    if (
      selectedRowKeys?.continuous?.length ||
      selectedRowKeys?.oneDay?.length
    ) {
      data = Decimal(
        Number(
          new BigNumber(totalAndQuantity?.totalAndQuantity?.oneDay?.total)
            .plus(
              new BigNumber(
                totalAndQuantity?.totalAndQuantity?.continuous?.total
              )
            )
            .plus(totalAndQuantity?.totalAndQuantity?.labInterface?.total)
            .toFixed(2)
        )
      );
    } else {
      data = Decimal(
        Number(
          new BigNumber(0)
            .plus(
              new BigNumber(
                totalAndQuantity?.totalAndQuantity?.labInterface?.total
              )
            )
            .toFixed(2)
        )
      );
    }

    return data;
  };

  const unitPrice = () => {
    let data;
    if (
      selectedRowKeys?.continuous?.length ||
      selectedRowKeys?.oneDay?.length
    ) {
      data = Decimal(
        Number(
          new BigNumber(totalAndQuantity?.totalAndQuantity?.oneDay?.normal)
            .plus(
              new BigNumber(
                totalAndQuantity?.totalAndQuantity?.continuous?.normal
              )
            )
            .plus(totalAndQuantity?.totalAndQuantity?.labInterface?.normal)
            .toFixed(2)
        )
      );
    } else {
      data = Decimal(
        Number(
          new BigNumber(0)
            .plus(
              new BigNumber(
                totalAndQuantity?.totalAndQuantity?.labInterface?.normal
              )
            )
            .toFixed(2)
        )
      );
    }

    return data;
  };

  const grandTotal = () => {
    let data;
    if (
      selectedRowKeys?.continuous?.length ||
      selectedRowKeys?.oneDay?.length ||
      totalAndQuantity?.totalAndQuantity?.labInterface?.quantity
    ) {
      if (
        totalAndQuantity?.totalAndQuantity?.oneDay.total ||
        totalAndQuantity?.totalAndQuantity?.continuous.total ||
        totalAndQuantity?.totalAndQuantity?.labInterface.total
      ) {
        data = Decimal(
          Number(
            new BigNumber(
              totalAndQuantity?.totalAndQuantity?.discountTotal ||
                totalAndQuantity?.totalAndQuantity?.total
            ).toFixed(2)
          )
        );
      } else {
        data = new BigNumber(totalAndQuantity?.totalAndQuantity?.total).toFixed(
          2
        );
      }
    } else {
      data = new BigNumber(totalAndQuantity?.totalAndQuantity?.total).toFixed(
        2
      );
    }

    return data;
  };

  return (
    <>
      {status === "noCard" ? (
        <>
          <Row gutter={[14, 14]}>
            <Col md={12} lg={12} className="fs-18 fw-900">
              {`${t("summary")}${t("order")}`}
            </Col>
          </Row>
          <Divider className="mt-1" />
          <Row>
            <Col md={12} lg={12} className="t-blue">
              {t("fullList")}
            </Col>
            <Col md={12} lg={12} className="col-end">
              {totalAndQuantity?.totalAndQuantity?.labInterface?.quantity ||
                selectedRowKeys?.continuous?.length ||
                (selectedRowKeys?.oneDay?.length &&
                  `${new BigNumber(
                    totalAndQuantity?.totalAndQuantity?.oneDay?.quantity || 0
                  )
                    .plus(
                      new BigNumber(
                        totalAndQuantity?.totalAndQuantity?.continuous
                          ?.quantity || 0
                      )
                    )
                    .plus(
                      totalAndQuantity?.totalAndQuantity?.labInterface
                        ?.quantity || 0
                    )}`)}{" "}
              {t("list")}
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue">
              {t("total")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              {`${total()} ${t("baht")}`}
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue">
              {t("discounts")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              <SelectorDouble
                placeholder="฿"
                onChange={onChange}
                status={1}
                Source={discount}
                value={totalAndQuantity?.totalAndQuantity?.selector}
                keyName="name"
                keyValue="id"
                title={t("status")}
                totalAndQuantity={totalAndQuantity}
              />
            </Col>
            <Col md={12} lg={12} className="mt-1 t-blue">
              {`${t("total")}${t("discounts")}`}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1">
              {`${totalDiscount()} ${t("baht")}`}
            </Col>
            <Col md={24} lg={24} className=" mt-1">
              <hr className="line-horizontal" />
            </Col>

            <Col md={12} lg={12} className="mt-1 t-blue fw-900">
              {t("grandTotal")}
            </Col>
            <Col md={12} lg={12} className="col-end mt-1 fs-18 fw-900">
              {`$ {grandTotal()} ${t("baht")}`}
            </Col>
          </Row>
        </>
      ) : (
        <Card
          className={className ? className : "bg-grey  order-summarize"}
          extra={
            <>
              <Row gutter={[14, 14]}>
                <Col md={12} lg={12} className="fs-18 fw-900">
                  {`${t("summary")}${t("order")}`}
                </Col>
              </Row>
            </>
          }
        >
          <Row align="middle" gutter={[0, 12]}>
            <Col md={12} lg={12} className="t-blue">
              {t("fullList")}
            </Col>

            <Col md={12} lg={12} className="col-end">
              {(totalAndQuantity?.totalAndQuantity?.labInterface?.quantity ||
                selectedRowKeys?.continuous?.length ||
                selectedRowKeys?.oneDay?.length) &&
                `${new BigNumber(
                  totalAndQuantity?.totalAndQuantity?.oneDay?.quantity || 0
                )
                  .plus(
                    new BigNumber(
                      totalAndQuantity?.totalAndQuantity?.continuous
                        ?.quantity || 0
                    )
                  )
                  .plus(
                    totalAndQuantity?.totalAndQuantity?.labInterface
                      ?.quantity || 0
                  )}`}{" "}
              {t("list")}
            </Col>

            <Col md={12} lg={12} className=" t-blue">
              {t("totalStartingPrice")}
            </Col>
            <Col md={12} lg={12} className="col-end ">
              {` ${unitPrice()} ${t("baht")}`}
            </Col>

            <Col md={12} lg={12} className=" t-blue">
              {t("netPrice")}
            </Col>
            <Col md={12} lg={12} className="col-end ">
              {` ${total()} ${t("baht")}`}
            </Col>

            <Col md={12} lg={12} className=" t-blue">
              {t("discountedPrice")}
            </Col>
            <Col md={12} lg={12} className="col-end ">
              <SelectorDouble
                placeholder="฿"
                onChange={onChange}
                status={1}
                Source={discount}
                value={totalAndQuantity?.totalAndQuantity?.selector}
                keyName="name"
                keyValue="id"
                title={t("status")}
                totalAndQuantity={totalAndQuantity}
              />
            </Col>
            <Col md={12} lg={12} className=" t-blue">
              {`${t("total")}${t("discounts")}`}
            </Col>
            <Col md={12} lg={12} className="col-end ">
              {`${totalDiscount()} ${t("baht")}`}
            </Col>
            <Col md={24} lg={24}>
              <hr className="line-horizontal" />
            </Col>

            <Col md={12} lg={12} className=" t-blue fw-900">
              {t("grandTotal")}
            </Col>
            <Col md={12} lg={12} className="col-end  fs-18 fw-900">
              {`${grandTotal()} ${t("baht")}`}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default OrderSummary;
