import React from "react";
import { IItem } from "@interface/IOrder.interface";
import { procedure } from "@assets/data/pathRouter";
import { useGetVisitsByID } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { ENUM_SERVICE_GROUP } from "@tools/util.enum";
import { RouteComponentProps } from "react-router-dom";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetWorkOrdersOutStanding } from "@services/api/work_order";
import { useUpdateWorkOrderUpdateStatus } from "@services/api/work_order";
import Fields from "./Fields";
import Header from "./Header";
import RoleContext from "@provider/RoleProvider";
import MainPatientByIdProvider from "@provider/patient_by_id";

const FormInchart = () => {
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const { data } = useGetVisitsByID(visitID);
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const patientID = match.params.patientID;
  const updateWorkOrderUpdateStatus = useUpdateWorkOrderUpdateStatus();
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PROCEDURE";
  });
  const workOrdersOutStanding = useGetWorkOrdersOutStanding(patientID, {
    serviceGroup: ENUM_SERVICE_GROUP.MEDICAL_PROCEDURE,
    visitId: visitID,
  });

  const workOrderId = () => {
    let data: number[] = [];

    workOrdersOutStanding?.data?.one?.groups?.forEach(
      (item: ISelectedExecuteOrder) => {
        item?.children?.[0]?.children?.forEach((item: IItem) => {
          item?.workOrderId && data?.push(item?.workOrderId);
        });
      }
    );

    workOrdersOutStanding?.data?.continuous?.groups?.forEach(
      (item: ISelectedExecuteOrder) => {
        item?.children?.[0]?.children?.forEach((item: IItem) => {
          item?.workOrderId && data?.push(item?.workOrderId);
        });
      }
    );

    let uniqueData = Array.from(new Set(data));
    return uniqueData || undefined;
  };

  const handleUpdateWorkOrderStatus = () => {
    let id = workOrderId();

    updateWorkOrderUpdateStatus.mutate(
      {
        id: visitID,
        data: {
          wordOrderId: id,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(procedure);
        },
        onError: (err) => {
          openNotification({
            custom: true,
            type: "warning",
            message: String(err) || "กรุณายืนยันรายการที่ต้องการทำงานก่อน",
          });
        },
      }
    );
  };

  return (
    <MainPatientByIdProvider value={{ patientById: data?.patient }}>
      <React.Fragment>
        <Header
          history={history}
          visitID={visitID}
          isSignature={data?.isSignature?.procedure}
          handleUpdateWorkOrderStatus={handleUpdateWorkOrderStatus}
          medicalRecord={medicalRecord?.[0]?.isEdit}
        />
        <div className="set-layout ">
          <Fields
            patientID={patientID}
            visitsByID={data}
            orderList={workOrdersOutStanding?.data}
            medicalRecord={medicalRecord?.[0]?.isEdit}
          />
        </div>
      </React.Fragment>
    </MainPatientByIdProvider>
  );
};

export default FormInchart;
