import React, { useEffect, useState } from "react";
import { dayjs } from "@tools/timezone";
import { IBilling } from "@interface/IBill.interface";
import { useGetBill } from "@services/api/bill";
import { DownOutlined } from "@ant-design/icons";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Dropdown, Menu } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import MoTable from "@components/table/Table";
import Decimal from "@tools/decimal";
import RoleContext from "@provider/RoleProvider";

type Props = {
  searchValue: any;
  currentPage: number;
  setCurrentPage: (e: number) => void;
};

const Table = ({ searchValue, currentPage, setCurrentPage }: Props) => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const roleList = React.useContext(RoleContext);
  const pay = roleList?.filter((item) => {
    return item?.nameEn === "PAY";
  });
  const billList = useGetBill(patientID, {
    page: currentPage,
    limit: limitPage,
    ...searchValue,
    date: searchValue?.date
      ? dayjs(searchValue?.date).format("YYYY-MM-DD")
      : undefined,
    orderby: `${
      searchValue?.status === 2 ? ENUM_ORDERBY.ASC : ENUM_ORDERBY.DESC
    }`,
  });

  useEffect(() => {
    setTotal(billList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [billList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onChangeSelect = (value: any, dataList: IBilling) => {
    if (value === "1") {
      history.push(
        `${PAY_LIST_TAB.billCreateInvoice}/${dataList?.id}/patient/${patientID}`
      );
    } else {
    }
  };

  const onClickRow = (event: IBilling) => {
    history.push(
      `${PAY_LIST_TAB.editBilling}/${event?.id}/patient/${patientID}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "15%",
      render: (_: any, data: IBilling, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("date"),
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <>{dayjs(date).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: `${t("number")}${t("document")}`,
      dataIndex: "billNumber",
      key: "billNumber",
    },

    {
      title: t("netTotal"),
      dataIndex: "netPrice",
      key: "netPrice",
      render: (netPrice: number) => {
        return <>{Decimal(Number(netPrice))}</>;
      },
    },

    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: string, data: IBilling) => {
        return (
          <>
            {pay?.[0]?.isEdit ? (
              status === "WAITING" ? (
                <Dropdown
                  overlay={
                    <Menu>
                      <Menu.Item
                        onClick={(e) => onChangeSelect(e.key, data)}
                        key="1"
                      >
                        {`${t("create")}${t("invoice")}`}
                      </Menu.Item>
                    </Menu>
                  }
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <Button className="btn-waiting-bill">
                    {t("waitingForBilling")}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              ) : status === "OPENING" ? (
                <Button className="btn-open">{t("openedTheBill")}</Button>
              ) : null
            ) : status === "WAITING" ? (
              <Button className="btn-waiting-bill">
                {t("waitingForBilling")}
              </Button>
            ) : status === "OPENING" ? (
              <Button className="btn-open">{t("openedTheBill")}</Button>
            ) : null}
          </>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={billList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
        onDoubleClick={true}
        onClickRow={onClickRow}
      />
    </>
  );
};

export default Table;
