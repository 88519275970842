import { OPD } from "@assets/data/pathRouter";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { VISIT_OLD_FILES } from "@tools/util.enum";
import { RouteComponentProps } from "react-router-dom";
import { Button, Card, Col, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import TableCorrespondence from "./Table";
import SearchCorrespondence from "./Search";

const Correspondence = () => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<any>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const history = useHistory<RouteComponentProps["history"]>();

  const onFinish = (value: any) => {
    setSearchValue(value);
    setCurrentPage(1);
  };

  return (
    <div>
      <Card
        className="bg-grey "
        extra={
          <Row align="middle" justify="space-between">
            <Col>
              <b className="header-card ">{t("correspondence")}</b>
            </Col>
            <Col>
              <Button
                className="btn-create mr-05"
                onClick={() => {
                  history.push(
                    `${OPD.nursingHistory}/patient/${patientID}/history-upload-file/${VISIT_OLD_FILES?.CORRESPONDENCE}`
                  );
                }}
              >
                {t("file")}
              </Button>
            </Col>
          </Row>
        }
      >
        <SearchCorrespondence onFinish={onFinish} />
        <TableCorrespondence
          currentPage={{
            currentPage: currentPage,
            setCurrentPage: setCurrentPage,
          }}
          searchValue={searchValue}
        />
      </Card>
    </div>
  );
};

export default Correspondence;
