import { OPD } from "@assets/data/pathRouter";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Header from "./Header";
import ModalCompare from "@views/nurse_room/interface_lab/compare/Modal";
import CompareInterface from "@components/compare/Compare";

const Compare = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const history = useHistory<RouteComponentProps["history"]>();
  const [visible, setVisible] = useState(false);

  const onModal = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <Header history={history} />
      <div className="set-layout ">
        <CompareInterface
          title={
            <Row justify="space-between">
              <Col className="header-card ">{t("labResults")}</Col>
              <Col>
                <Button
                  className="send-lab mr-05 "
                  htmlType="submit"
                  onClick={() => {
                    history.push(`${OPD.printCompare}/patient/${patientID}`);
                  }}
                >
                  {t("print")}
                </Button>

                <Button
                  className="mr-05 reserve"
                  htmlType="reset"
                  onClick={onModal}
                >
                  {t("view")}
                </Button>
              </Col>
            </Row>
          }
        />
      </div>
      <ModalCompare
        visible={{ visible: visible, setVisible: setVisible }}
        onModal={onModal}
      />
    </div>
  );
};

export default Compare;
