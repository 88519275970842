import { EVENT } from "@assets/data/pathRouter";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import Delete from "@assets/img/icon/OPD/delete.svg";
import BackHome from "@components/button/BackHome";

type Props = {
  eventID: number;
  onModal: () => void;
};

const Header = ({ eventID, onModal }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();

  const editEvent = () => {
    history.push(`${EVENT.title}/${eventID}`);
  };

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("marketing.title")}</h1>
              </div>
              <span className="t-light-gray">{t("event.title")} / </span>
              <span className="t-dark-blue f-normal">{t("event.manage")}</span>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex justify-between">
          <Button
            className=" delete mr-05 "
            htmlType="submit"
            onClick={(e) => {
              onModal();
              e.stopPropagation();
            }}
          >
            <img className="circle editor-button" src={Delete} alt="delete" />
          </Button>
          <Button className="edit mr-05 " htmlType="submit" onClick={editEvent}>
            {t("edit")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
