export const BiologicalTherapyIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            d="M0 0H94.254V80.039H0z"
            data-name="Rectangle 22689"
          ></path>
        </clipPath>
        <clipPath id="clip-MAR_MedicationAdministration_Record">
          <path d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
      <g
        clipPath="url(#clip-MAR_MedicationAdministration_Record)"
        data-name="MAR (MedicationAdministration Record"
      >
        <g data-name="Group 27075" transform="translate(3 10)">
          <g clipPath="url(#clip-path)" data-name="Group 27074">
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M79.772 17.407H64.937V0H28.965v17.407H14.482A14.48 14.48 0 000 31.86V65.2a14.815 14.815 0 0014.482 14.836h65.29A14.815 14.815 0 0094.254 65.2V31.86a14.48 14.48 0 00-14.482-14.453M35.646 6.679h22.638v10.728H35.646zm45.249 46.314H70.138v10.728H61.6V52.993H50.836v-8.542H61.6V33.723h8.542v10.728h10.753z"
              data-name="Path 22250"
              transform="translate(0 .001)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
