import { dayjs } from "@tools/timezone";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { compareLabState } from "@tools/recoil/recoil-atom";
import { useGetLabCompare } from "@services/api/lab";
import { Button, Col, Divider, Modal, Row } from "antd";

type Props = {
  visible: { visible: boolean; setVisible: (e: boolean) => void };
  onModal: () => void;
};

const ModalCompare = ({ visible, onModal }: Props) => {
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const compareLab = useRecoilValue(compareLabState);
  const compare = useGetLabCompare(patientID, false, {
    startDate: compareLab.startDate,
    endDate: compareLab.endDate,
    orderby: ENUM_ORDERBY.DESC,
  });
  const { t } = useTranslation();
  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col className="t-blue ">
              <b> {`${t("labComparisonFile")}`}</b>
            </Col>
          </Row>
        </>
      }
      centered
      visible={visible?.visible}
      onOk={() => visible.setVisible(false)}
      onCancel={onModal}
      width="70%"
      footer={null}
    >
      <div className="scroll ml-1 mr-1 mt-1">
        <div className="scroll-compare-lab">
          <table width="120%">
            <thead>
              <tr
                className=" lab-i-body lab-h print-marker"
                style={{ backgroundColor: "#498dcb", color: "white" }}
              >
                <th rowSpan={2} className="print-marker-right">
                  {t("testCode")}
                </th>
                {/* <th rowSpan={2} className="print-marker-right">
                  {t("Reference Range")}
                </th> */}
                {compare?.data?.result?.[0]?.date?.map((_, index) => {
                  return (
                    <th key={index} className="print-marker-right">
                      Date
                    </th>
                  );
                })}
              </tr>
              <tr
                style={{ backgroundColor: "#498dcb", color: "white" }}
                className=" lab-i-body lab-h print-marker"
              >
                {compare?.data?.result?.[0]?.date?.map((day, index) => {
                  return (
                    <th key={index} className="print-marker-right">
                      {day}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {compare?.data?.result?.[0]?.data?.map((item) => {
                return item?.data?.map((child, childIndex: number) => {
                  return (
                    <tr key={childIndex} className="print-marker">
                      <td className="print-marker-right ">
                        {child?.testCode || ""}
                      </td>
                      {/* <td className="print-marker-right "></td> */}
                      {compare?.data?.result?.[0]?.date?.map((day, index) => {
                        const result = child?.result?.obx?.flatMap(
                          (resultList) => {
                            return {
                              result: resultList?.result,
                              flagLowHigh: resultList?.flagLowHigh,
                            };
                          }
                        );
                        result?.splice(0, 1);

                        if (
                          dayjs(day).format("DD-MM-YYYY") ===
                          dayjs(child?.createdAt).format("DD-MM-YYYY")
                        ) {
                          return (
                            <td key={index} className="print-marker-right ">
                              {child?.file ? (
                                <Button
                                  className="reserve-report"
                                  onClick={() => {
                                    window.open(child?.file);
                                  }}
                                >
                                  {" "}
                                  LABORATORY REPORT
                                </Button>
                              ) : (
                                ""
                              )}
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={index}
                              className="print-marker-right "
                            ></td>
                          );
                        }
                      })}
                    </tr>
                  );
                });
              })}
            </tbody>
          </table>
        </div>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onModal}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button className="btn-create " onClick={() => onModal}>
                {t("confirm1")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalCompare;
