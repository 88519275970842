import { thrownResponse } from "./auth";
import { Elementary, IParamet } from "@interface/IGlobal.interface";
import { ICreateEvent, IGetEvent } from "@interface/IEvent.interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetEventList = (
  params?: any
): UseQueryResult<Elementary<IGetEvent[]>> => {
  return useQuery(["get-event-list", params], async () => {
    const res = await axios.get(`/event`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetEventMyBranch = (
  params?: any
): UseQueryResult<Elementary<IGetEvent[]>> => {
  return useQuery(["get-event-my-branch", params], async () => {
    const res = await axios.get(`/event/my-branch`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetEventByID = (
  id: number,
  enabled: boolean,
  params?: any
): UseQueryResult<IParamet<IGetEvent[]>> => {
  return useQuery(
    ["get-event-byid", id, params],
    async () => {
      const res = await axios.get(`/event/${id}`, { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: enabled }
  );
};

export const useCreateEvent = () => {
  return useMutation(["create-event"], async (data: ICreateEvent) => {
    const res = await axios.post(`/event`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateEventByID = () => {
  return useMutation(["update-event-by-id"], async ({ id, data }: any) => {
    const res = await axios.put(`/event/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteEventByID = () => {
  return useMutation(async (id: number) => {
    const res = await axios.delete(`/event/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeletePreRegistrationByID = () => {
  return useMutation(async ({ patientId, eventId }: any) => {
    const res = await axios.delete(
      `/event/patient/${patientId}/event/${eventId}`
    );

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
