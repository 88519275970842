import { Col, Form, Input, Row } from "antd";

const DatailInput = () => {
  return (
    <div>
      <Row gutter={[14, 14]}>
        <Col md={8} lg={8}>
          <Form.Item
            name="systemName"
            label="Name System"
            rules={[{ required: true }]}
          >
            <Input placeholder="Name System" />
          </Form.Item>
        </Col>

        <Col md={8} lg={8}>
          <Form.Item name="site" label="Site" rules={[{ required: true }]}>
            <Input placeholder="Site" />
          </Form.Item>
        </Col>

        <Col md={8} lg={8}>
          <Form.Item
            name="fileDatetime"
            label="[File datetime yyyyMMddHHmmss]"
            rules={[{ required: true }]}
          >
            <Input placeholder="File datetime" />
          </Form.Item>
        </Col>

        <Col md={8} lg={8}>
          <Form.Item name="hn" label="HN" rules={[{ required: true }]}>
            <Input placeholder="HN" />
          </Form.Item>
        </Col>

        <Col md={8} lg={8}>
          <Form.Item
            name="lastname"
            label="LASTNAME"
            rules={[{ required: true }]}
          >
            <Input placeholder="LASTNAME" />
          </Form.Item>
        </Col>

        <Col md={8} lg={8}>
          <Form.Item
            name="firstname"
            label="FNAME"
            rules={[{ required: true }]}
          >
            <Input placeholder="FNAME" />
          </Form.Item>
        </Col>

        <Col md={8} lg={8}>
          <Form.Item
            name="prefixname"
            label="Title"
            rules={[{ required: true }]}
          >
            <Input placeholder="Title" />
          </Form.Item>
        </Col>

        <Col md={8} lg={8}>
          <Form.Item
            name="bod"
            label="[DOB yyyyMMdd]"
            rules={[{ required: true }]}
          >
            <Input placeholder="[DOB yyyyMMdd]" />
          </Form.Item>
        </Col>

        <Col md={8} lg={8}>
          <Form.Item name="sex" label="[SEX M F]" rules={[{ required: true }]}>
            <Input placeholder="[SEX M F]" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default DatailInput;
