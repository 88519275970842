import { IGetPrefixList } from "@interface/IPrefix.interface";
import { thrownResponse } from "./auth";
import { ISeparatePrefix } from "@interface/IOpd.interface";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetPrefixList = (
  params?: any
): UseQueryResult<Elementary<IGetPrefixList[]>> => {
  return useQuery(["get-prefix-list", params], async () => {
    const res = await axios.get("/prefix", { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetPrefixGroupType = (): UseQueryResult<
  ElementaryOneMore<ISeparatePrefix>
> => {
  return useQuery(["get-prefix-list"], async () => {
    const res = await axios.get("/prefix/group-type");

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreatePrefixMany = (): UseMutationResult => {
  return useMutation(["create-prefix-many"], async (data: any) => {
    const res = await axios.post(`/prefix/bulk`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetPrefixById = (
  id: number
): UseQueryResult<ElementaryOneMore<IGetPrefixList>> => {
  return useQuery(["get-prefix-by-id", id], async () => {
    const res = await axios.get(`prefix/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdatePrefix = (): UseMutationResult => {
  return useMutation(["update-prefix"], async ({ id, data }: any) => {
    const res = await axios.put(`/prefix/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeletePrefix = (): UseMutationResult => {
  return useMutation(async (id: any) => {
    const res = await axios.delete(`/prefix/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
