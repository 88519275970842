import { ORDER_TYPE } from "@tools/util.enum";
import { Empty, Popover } from "antd";
import { useTranslation } from "react-i18next";
import { formatDataDraft } from "@tools/util";
import empty from "@assets/img/empty.svg";
import MoTable from "@components/table/Table";

type Props = {
  patientID: string | number;
  draftOrderList?: any;
};

const TableOrderList = ({ draftOrderList }: Props) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {!data?.level ? index + 1 : data?.level === 2 ? "" : ""}
          </div>
        );
      },
    },
    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (name: any, data: any) => {
        return (
          <div key={data?.id}>
            {!data?.level
              ? name
              : data?.level === 2
              ? t("nameList")
              : data?.product?.nameTh || ""}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (product: any, data: any) => {
        return (
          <div key={data?.id}>
            {!data?.level
              ? ""
              : data?.level === 2
              ? t("type")
              : product?.group?.nameTh || ""}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "product",
      key: "product",

      render: (_: any, data: any) => {
        return (
          <>
            {!data?.level
              ? data?.children?.[0]?.children?.length || "-"
              : data?.level === 2
              ? t("amount")
              : data?.qty + "   "}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "product",
      key: "product",
      align: "center",
      render: (_: any, data: any) => {
        return (
          <>
            {!data?.level
              ? ""
              : data?.level === 2
              ? t("type")
              : data?.level === 3
              ? data?.type === ORDER_TYPE.ONE_DAY
                ? "One Day"
                : data?.type === ORDER_TYPE.CONTINUE
                ? "Continuous"
                : "-"
              : ""}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      align: "center",
      render: (item: any, dataList: any) => {
        return (
          <>
            {!dataList?.level ? (
              ""
            ) : dataList?.level === 2 ? (
              t("details")
            ) : (
              <Popover
                content={item || dataList?.product?.descriptionTh || "-"}
                key={dataList?.id}
              >
                <div className="text-content">
                  {item || dataList?.product?.descriptionTh || "-"}
                </div>
              </Popover>
            )}
          </>
        );
      },
    },
  ];

  return (
    <div>
      <MoTable
        className="components-table-demo-nested table-selection table-confirm "
        pagination={false}
        locale={{
          emptyText: (
            <>
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">{t("noInformation")}</span>
                }
              ></Empty>
            </>
          ),
        }}
        rowClassName={(r) => {
          if (!r?.level) {
            return " no-header no-cursor";
          } else if (r?.level === 1) {
            return "table-header no-header no-cursor";
          } else if (r?.level === 2) {
            return " no-header";
          }
          return "";
        }}
        expandRowByClick
        rowKey={"key"}
        key={"key"}
        columns={columns}
        dataSource={formatDataDraft(draftOrderList, "preRegister") || []}
        onDoubleClick={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default TableOrderList;
