import React from "react";
import { Button, FormInstance, Modal } from "antd";

type Props = {
  editOrder: FormInstance;
  okText?: string;
  cancelText?: string;
  // onModal?: ([key]?: any, [key2]?: any, [key3]?: any) => void;
  onCancel: () => void;
  visible?: boolean;
  children?: React.ReactNode;
  header?: any;
  width?: string;
  footer?: boolean;
  closable?: boolean;
  loadingButton?: boolean;
};

const EditOrder = ({
  editOrder,
  // onModal,
  onCancel,
  loadingButton,
  header = " ",
  visible,
  children,
  width = "50%",
  closable = true,
  okText = "ยืนยัน",
  cancelText = "ยกเลิก",
}: Props) => {
  return (
    <>
      <Modal
        title={header}
        onCancel={onCancel}
        visible={visible}
        width={width}
        centered
        closable={closable}
        footer={[
          <Button className="cancel" key="1" onClick={onCancel}>
            {cancelText}
          </Button>,
          <Button
            key="2"
            className="btn-create"
            onClick={() => editOrder.submit()}
            loading={loadingButton}
          >
            {okText}
          </Button>,
        ]}
      >
        {children}
      </Modal>
    </>
  );
};

export default EditOrder;
