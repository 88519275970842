import { IVisit } from "@interface/IGlobal.interface";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { useCreateMedia } from "@services/api/media_object";
import { openNotification } from "@components/notification/Notification";
import { useRef, useEffect, useState } from "react";
import { Button, Col, Divider } from "antd";
import { Modal, Row, Form, DatePicker, Input } from "antd";
import Signature from "@components/drawing_canvas/Signature";
import moment from "moment";

type Props = {
  visible: IVisit | any;
  setVisible: (e: IVisit | any) => void;
  date: string;
  name: string;
  updateSignature: (e: any) => void;
  title?: string;
  isLoading: boolean;
  hiddenDoctorName?: boolean;
  uploadSignature?: boolean;
};

const ModalInfo = ({
  visible,
  setVisible,
  date,
  name,
  updateSignature,
  title = "เซ็นชื่อผู้รับบริบาล",
  isLoading,
  hiddenDoctorName = true,
  uploadSignature = false,
}: Props) => {
  const { t } = useTranslation();
  const stageRef = useRef<any>(null);
  const [signatureInfo] = Form.useForm();
  const [isDrawn, setIsDrawn] = useState(false);
  const createMedia = useCreateMedia();

  useEffect(() => {
    signatureInfo.setFieldsValue({
      date: date ? moment(date) : moment(),
    });
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible({ ...visible, [`${name}`]: !visible[`${name}`] });
  };

  const onFinish = (data: any) => {
    if (isDrawn) {
      let imageData: string =
        stageRef?.current?.canvas?.drawing?.toDataURL("image/png");
      if (!!uploadSignature) {
        const file: File | undefined = dataUrlToFile(
          imageData,
          "signature.png"
        );
        signature({
          [`${name}Signature`]: file,
        });
      } else if (
        title === t("doctorSignature") ||
        title === t("medicalAssistantSignature")
      ) {
        signature({
          ...data,
          [`${name}Signature`]: imageData,
          [`${name}SignatureDate`]: data?.date,
        });
      } else {
        if (stageRef?.current) {
          const file: File | undefined = dataUrlToFile(
            imageData,
            "signature.png"
          );
          if (file) {
            let formData = new FormData();
            formData?.append("file", file);
            createMedia.mutate(formData, {
              onSuccess: (res) => {
                signature({
                  ...data,
                  [`${name}SignatureId`]: res?.result?.[0]?.id,
                  [`${name}SignatureDate`]: data?.date,
                });
              },
              onError: (error) => {
                openNotification({
                  method: "POST_ERROR",
                  message: String(error),
                });
              },
            });
          }
        } else {
          signature(data);
        }
      }
    }
  };

  const signature = (value: any) => {
    updateSignature({
      ...value,
      title: name,
    });
  };

  return (
    <Modal
      className="document-modal"
      title={
        <>
          <Row>
            <Col lg={20} className="t-black-dark f-bold ">
              <b> {title}</b>
            </Col>
            <Col lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={visible[`${name}`]}
      onOk={() => setVisible({ ...visible, [`${name}`]: !visible[`${name}`] })}
      onCancel={onCancel}
      width="50%"
      footer={null}
      destroyOnClose={true}
      // forceRender={true}
    >
      <div className="scroll ">
        <Form
          onFinish={onFinish}
          className="p-2"
          name="signatureInfo"
          form={signatureInfo}
        >
          <Row gutter={[14, 14]}>
            <Col lg={24}>
              <Form.Item label={t("date")} name="date">
                <DatePicker disabled format={"DD/MM/YYYY"} />
              </Form.Item>
            </Col>
            {!hiddenDoctorName ? (
              <Col lg={24}>
                <Form.Item label={t("doctorName")} name="doctorName">
                  <Input />
                </Form.Item>
              </Col>
            ) : null}

            <Col span={24} className="col-center">
              <Signature
                stageRef={stageRef}
                drawnState={{ isDrawn, setIsDrawn }}
              />
            </Col>
            {!isDrawn && (
              <Col span={24} className="col-center">
                <p style={{ color: "red" }}>{t("pleaseSign")}</p>
              </Col>
            )}
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[10, 10]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => signatureInfo.submit()}
                loading={!!isLoading ? true : false}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInfo;
