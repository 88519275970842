import { ORDER_TYPE } from "@tools/util.enum";

const DataLabInterfaceTwo = () => {
  const groupTwo = [
    {
      name: "PK041 + S264",
      nameProduct: "MKT-50 Male Package A : Whole Body Check up",
      color: [
        { name: 1, color: "gray" },
        { name: 3, color: "#C9A3EA" },
        { name: 2, color: "red" },
        { name: 1, color: "#56A5EC" },
        { name: 1, color: "#004C97" },
        { name: "", color: "white" },
        { name: "", color: "white" },
        { name: "", color: "white" },
      ],
      testCode: (
        <div>
          <div>CBC + BUN + Cr (Plus eGFR) + G6PD + Homocysteine + hs CRP</div>
          <div>
            FBS + Lipid Profile + HbA1C + Vit D Total + Insulin + LFT + DHEAS
          </div>
          <div>FSH + LH + Testosterone + Estradiol + SHBG +IGF1 + IGFBP3</div>
          <div>
            TFT (TSH + T3 + T4 + Free T3 + Free T4)+AFP + CEA + NSE + CA 19-9 +
            Free PSA
          </div>
          <div>
            Toxic Metals in Blood Profile 2 (Pb, Hg, Cd, Mn, Cr, Co, Ni, As, Al)
          </div>
          <div>
            Fibrinogen, FPD-D-dimer Uric acid,Phosphorus, Mg, Ca, GGT, LDH ,
            ESR, Ferritin
          </div>
        </div>
      ),
      type: ORDER_TYPE.LAB_INTERFACE_FOUR,
      sku: "L001-NH-3009",
    },

    {
      name: "PK042 + S264",
      nameProduct: "MKT-51 Female Package A : Whole Body Check up",
      color: [
        { name: 1, color: "gray" },
        { name: 3, color: "#C9A3EA" },
        { name: 2, color: "red" },
        { name: 1, color: "#56A5EC" },
        { name: 1, color: "#004C97" },
        { name: "", color: "white" },
        { name: "", color: "white" },
        { name: "", color: "white" },
      ],
      testCode: (
        <div>
          <div>CBC + BUN + Cr (Plus eGFR) + G6PD + Homocysteine + hs CRP</div>
          <div>
            FBS + Lipid Profile + HbA1C + Vit D Total + Insulin + LFT + DHEAS
          </div>
          <div>
            FSH + LH + Estradiol + Progesterone + SHBG +IGF1 + IGFBP3 + TFT(TSH
            + T3 + T4 + Free T3 + Free T4)
          </div>
          <div>
            AFP + CEA + NSE + CA 19-9 + CA 153 + CA 125 + Ferritin + Toxic
            Metals in Blood Profile 2
          </div>
          <div>
            Fibrinogen, FPD-D-dimer Uric acid,Phosphorus, Mg, Ca, GGT, LDH , ESR
          </div>
        </div>
      ),
      type: ORDER_TYPE.LAB_INTERFACE_FOUR,
      sku: "L001-NH-3010",
    },

    {
      name: "PK043 + S264",
      nameProduct: "MKT-52 Male Package B : Comprehensive Body check up (male)",
      color: [
        { name: "", color: "white" },
        { name: 3, color: "#C9A3EA" },
        { name: 2, color: "red" },
        { name: 1, color: "#56A5EC" },
        { name: 1, color: "#004C97" },
        { name: "", color: "white" },
        { name: "", color: "white" },
        { name: "", color: "white" },
      ],
      testCode: (
        <div>
          <div>CBC + BUN + Cr (Plus eGFR) + G6PD + Homocysteine + hs CRP</div>
          <div>
            LDL (Direct) + HbA1C + Vit D Total + LFT + DHEAS + TFT(TSH + T3 + T4
            + Free T3 + Free T4)
          </div>
          <div>
            AFP + CEA + NSE + CA 19-9 + Free PSA + Ferritin+Toxic Metals in
            Blood Profile 2
          </div>
          <div>FDP-D dimer, Uric Acid, GGT, LDH, ESR</div>
        </div>
      ),
      type: ORDER_TYPE.LAB_INTERFACE_FOUR,
      sku: "L001-NH-3011",
    },

    {
      name: "PK044 + S264",
      nameProduct:
        "MKT-53 Female Package B : Comprehensive body check up (Female)",
      color: [
        { name: "", color: "white" },
        { name: 3, color: "#C9A3EA" },
        { name: 2, color: "red" },
        { name: 1, color: "#56A5EC" },
        { name: 1, color: "#004C97" },
        { name: "", color: "white" },
        { name: "", color: "white" },
        { name: "", color: "white" },
      ],
      testCode: (
        <div>
          <div>CBC + BUN + Cr (Plus eGFR) + G6PD + Homocysteine + hs CRP</div>
          <div>
            LDL (Direct) + HbA1C + Vit D Total + LFT + DHEAS + TFT(TSH + T3 + T4
            + Free T3 + Free T4)
          </div>
          <div>
            AFP + CEA + NSE + CA 19-9 + CA 153 + CA 125 + Ferritin + Toxic
            Metals in Blood Profile 2
          </div>
          <div>FDP-D dimer, Uric Acid, GGT, LDH, ESR</div>
        </div>
      ),
      type: ORDER_TYPE.LAB_INTERFACE_FOUR,
      sku: "L001-NH-3012",
    },

    {
      name: "PK045 + S264",
      nameProduct: "MKT-54 Package C : Wellness check up (Male/Female)",
      color: [
        { name: 1, color: "#004C97" },
        { name: 3, color: "#C9A3EA" },
        { name: 1, color: "red" },
        { name: "", color: "white" },
      ],
      testCode: (
        <div>
          <div>CBC + BUN + Cr (Plus eGFR) + G6PD + Homocysteine + hs CRP</div>
          <div>LDL (Direct) + HbA1C + Vit D Total + LFT + DHEAS</div>
          <div>Toxic Metals in Blood Profile 2 + Uric Acid + ESR</div>
        </div>
      ),
      type: ORDER_TYPE.LAB_INTERFACE_FOUR,
      sku: "L001-NH-3013",
    },

    {
      name: "PK046 + S236 + S240 + S058",
      nameProduct: "MKT-55 Package D : Wellness check up (Male/Female)",
      color: [
        { name: 1, color: "#004C97" },
        { name: 3, color: "#C9A3EA" },
        { name: 1, color: "red" },
        { name: "", color: "white" },
      ],
      testCode: (
        <div>
          <div>CBC + BUN + Cr (Plus eGFR) + G6PD + Homocysteine + hs CRP</div>
          <div>
            LDL (Direct) + HbA1C + Vit D Total + LFT + DHEAS + Uric Acid + ESR
          </div>
          <div>Mercury, Lead, Aluminium in blood</div>
        </div>
      ),
      type: ORDER_TYPE.LAB_INTERFACE_FOUR,
      sku: "L001-NH-3014",
    },

    {
      name: "PK047 + S264 + S008",
      nameProduct: "Full lab Onco Male",
      color: [
        { name: 1, color: "gray" },
        { name: 4, color: "#C9A3EA" },
        { name: 3, color: "red" },
        { name: 1, color: "#56A5EC" },
        { name: 1, color: "#004C97" },
        { name: "", color: "white" },
        { name: "", color: "white" },
        { name: "", color: "white" },
      ],
      testCode: (
        <div>
          <div>CBC + BUN + Cr (Plus eGFR) + G6PD + Homocysteine + hs CRP</div>
          <div>
            FBS + Lipid Profile + HbA1C + Vit D Total + Insulin + LFT + DHEAS
          </div>
          <div>FSH + LH + Testosterone + Estradiol + Progesterone + TFT</div>
          <div>
            AFP + CEA + NSE + CA 19-9 + Free PSA +Toxic Metals in Blood Profile
            2 + FDP-D dimer
          </div>
          <div>
            Uric acid + Electrolytes + Phosphorus + Mg + Ca + GGT + LDH + ESR
          </div>
          <div>Ferritin + Serun Iron + MDA</div>
        </div>
      ),
      type: ORDER_TYPE.LAB_INTERFACE_FOUR,
      sku: "L001-NH-3015",
    },

    {
      name: "PK048 + S264 + S008",
      nameProduct: "Full lab Onco Female",
      color: [
        { name: 1, color: "gray" },
        { name: 4, color: "#C9A3EA" },
        { name: 3, color: "red" },
        { name: 1, color: "#56A5EC" },
        { name: 1, color: "#004C97" },
        { name: "", color: "white" },
        { name: "", color: "white" },
        { name: "", color: "white" },
      ],
      testCode: (
        <div>
          <div>CBC + BUN + Cr (Plus eGFR) + G6PD + Homocysteine + hs CRP</div>
          <div>
            FBS + Lipid Profile + HbA1C + Vit D Total + Insulin + LFT + DHEAS
          </div>
          <div>FSH + LH + Estradiol + Progesterone + SHBG + TFT</div>
          <div>
            AFP + CEA + NSE + CA 19-9 + CA 153 + CA 125 + Ferritin + Toxic
            Metals in Blood Profile 2
          </div>
          <div>
            FDP-D dimer + Uric acid + Electrolytes + Phosphorus + Mg + Ca + GGT
            + LDH + ESR
          </div>
          <div>Serum Iron + MDA</div>
        </div>
      ),
      type: ORDER_TYPE.LAB_INTERFACE_FOUR,
      sku: "L001-NH-3016",
    },
  ];
  return { groupTwo };
};

export default DataLabInterfaceTwo;
