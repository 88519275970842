import { useState } from "react";
import { IInvoice } from "@interface/IInvoice.interface";
import { BigNumber } from "bignumber.js";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { PrinterFilled } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { RouteComponentProps } from "react-router-dom";
import { FormInstance, Input, Row } from "antd";
import { Card, Col, DatePicker, Divider, Form } from "antd";
import TextArea from "antd/lib/input/TextArea";
import TableInput from "@components/table/TableInput";
import ReceiptPayment from "./ReceiptPayment";

interface IPrice {
  totalPrice: number;
  setTotalPrice: (e: number) => void;
}

type Props = {
  locationPath: string;
  invoiceIDList?: ElementaryOneMore<IInvoice>;
  invoiceForm: FormInstance;
  onFinish: (values: any) => void;
  totalPrice: IPrice;
  history: RouteComponentProps["history"];
  invoiceID: number;
  medicalRecord: boolean;
};

const Fields = ({
  locationPath,
  invoiceIDList,
  invoiceForm,
  onFinish,
  totalPrice,
  history,
  invoiceID,
  medicalRecord,
}: Props) => {
  const dateFormat = "DD-MM-YYYY";
  const { t, i18n } = useTranslation();
  const [splitPayment, setSplitPayment] = useState<any>({
    validateStatus: "",
    errorMsg: null,
  });

  const onNumberChangeSplitPayment = (value: any) => {
    if (Number(value) < 0 || !value) {
      setSplitPayment({
        validateStatus: "error",
        errorMsg: `${t("amountNotLessThan")} 0 ${t(
          "andNotMore"
        )} ${new BigNumber(totalPrice.totalPrice || 0).toFormat(2)}!`,
      });
      invoiceForm.setFieldsValue({
        price: new BigNumber(0).toFormat(2),
        receiptWorkOrderGroups:
          invoiceIDList?.result?.[0]?.bill?.order?.groups || [],
      });
    } else if (
      Number(value) >= 0 &&
      Number(value) <=
        Number(
          invoiceIDList?.result?.[0]?.outstandingฺBalance
            ? invoiceIDList?.result?.[0]?.outstandingฺBalance
            : invoiceIDList?.result?.[0]?.netPrice
        )
    ) {
      setSplitPayment({
        validateStatus: "success",
        errorMsg: null,
      });

      invoiceForm.setFieldsValue({
        price: value,
        receiptWorkOrderGroups:
          invoiceIDList?.result?.[0]?.bill?.order?.groups || [],
      });
    } else {
      setSplitPayment({
        validateStatus: "error",
        errorMsg: `${t("amountNotLessThan")} 0 ${t("andNotMore")} ${
          invoiceIDList?.result?.[0]?.outstandingฺBalance
            ? new BigNumber(totalPrice.totalPrice || 0).toFormat(2)
            : new BigNumber(totalPrice.totalPrice || 0).toFormat(2)
        } !`,
      });

      invoiceForm.setFieldsValue({
        price: invoiceIDList?.result?.[0]?.outstandingฺBalance
          ? Number(totalPrice.totalPrice).toFixed(2)
          : Number(totalPrice.totalPrice).toFixed(2),
        receiptWorkOrderGroups:
          invoiceIDList?.result?.[0]?.bill?.order?.groups || [],
      });
    }
  };

  const onOpenViewReport = () => {
    history.push(`${PAY_LIST_TAB.reportInvoice}/${invoiceID}`);
  };

  return (
    <div>
      <Card
        className="bg-grey m-1-doctor-room"
        extra={
          <div>
            <div className="fs-20 f-normal t-grey-label">
              {locationPath.includes("create-receipt")
                ? t("createAReceipt")
                : t("editInvoice")}
            </div>
            <Row align="middle">
              <Col className={"pr-1"}>
                <span className="fs-16 ">{`${t("number")} :`} </span>
                <span className="fs-16 t-blue">
                  {invoiceIDList?.result?.[0]?.invoiceNumber || ""}
                </span>
              </Col>
              {locationPath.includes("create-receipt-split-up") ? (
                <Col>
                  <span className="card-split-up"> {t("splitUp")}</span>
                </Col>
              ) : null}
            </Row>
          </div>
        }
      >
        <Card>
          <Form name="invoiceForm" form={invoiceForm} onFinish={onFinish}>
            <Row gutter={[14, 14]}>
              <Col lg={15}>
                <Row gutter={[14, 14]} align="middle">
                  <Col lg={12}>
                    <Form.Item label={t("customerName")} name="name">
                      <Input disabled />
                    </Form.Item>

                    <Form.Item label={t("address")} name="address">
                      <TextArea
                        disabled
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        placeholder=""
                      />
                    </Form.Item>

                    <Form.Item label={t("zipCode")} name="zipCode">
                      <Input disabled placeholder={t("zipCode")} />
                    </Form.Item>
                  </Col>

                  <Col lg={12}>
                    <div className="example">
                      <span className="f-bold ">{`${t("contact")} :`}</span>
                      <span className="break-inside ">
                        {i18n.language === "th"
                          ? invoiceIDList?.result?.[0]?.branch?.nameTh
                          : invoiceIDList?.result?.[0]?.branch?.nameEn}
                      </span>
                      <span className="break-inside ">
                        {[
                          invoiceIDList?.result?.[0]?.branch?.[
                            i18n.language === "th" ? "addressTh" : "addressEn"
                          ],
                          invoiceIDList?.result?.[0]?.branch?.[
                            i18n.language === "th"
                              ? "subDistrictTh"
                              : "subDistrictEn"
                          ],
                          invoiceIDList?.result?.[0]?.branch?.[
                            i18n.language === "th" ? "districtTh" : "districtEn"
                          ],
                          invoiceIDList?.result?.[0]?.branch?.[
                            i18n.language === "th" ? "provinceTh" : "provinceEn"
                          ],
                          invoiceIDList?.result?.[0]?.branch?.zipcode,
                          invoiceIDList?.result?.[0]?.branch?.country,
                        ]
                          ?.join(" ")
                          ?.trim()}
                      </span>
                    </div>

                    <div>
                      <span className="f-bold">{`${t("taxId")} :`}</span>{" "}
                      {invoiceIDList?.result?.[0]?.branch?.taxId}
                    </div>

                    <div>
                      <span className="f-bold">{`${t("phone")} :`}</span>{" "}
                      {invoiceIDList?.result?.[0]?.branch?.phone}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col lg={9}>
                {locationPath.includes("create-receipt") ? null : (
                  <Row justify="end" align="middle">
                    <Col className="col-end" span={24}>
                      <PrinterFilled
                        style={{ fontSize: "32px" }}
                        onClick={onOpenViewReport}
                      />
                    </Col>
                    <Col>{t("print")}</Col>
                  </Row>
                )}

                <div className="fs-16 f-bold">{t("theTotalAmount")}</div>
                <div className="fs-20 f-bold t-blue mt-1 ">
                  {new BigNumber(
                    invoiceIDList?.result?.[0]?.outstandingBalance ||
                      invoiceIDList?.result?.[0]?.netPrice ||
                      0
                  ).toFormat(2)}
                </div>
                <Row gutter={[14, 14]} className="mt-1">
                  <Col md={24} lg={24}>
                    <Form.Item
                      label={t("date")}
                      className="pre-register-form"
                      name="date"
                    >
                      <DatePicker disabled format={dateFormat} />
                    </Form.Item>
                  </Col>

                  <Col md={24} lg={24}>
                    <Form.Item
                      label={t("salesperson")}
                      name="sale"
                      className="pre-register-form"
                    >
                      <Input disabled={true} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Divider />
            <Row gutter={[14, 14]}>
              <Col span={24}>
                <Form.Item
                  label={t("referenceNumber")}
                  name="referenceNumber"
                  className="pre-register-form"
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item name="receiptWorkOrderGroups">
              <TableInput
                disabled={
                  locationPath.includes("create-receipt-split-up")
                    ? false
                    : true
                }
                setTotalPrice={totalPrice.setTotalPrice}
                invoiceForm={invoiceForm}
                medicalRecord={medicalRecord}
              />
            </Form.Item>

            <Row gutter={[14, 14]}>
              <Col span={15}>
                <Form.Item label={t("remarks")} name="description">
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>

              <Col span={9}>
                {locationPath.includes("create-receipt") ? (
                  <Row className="mt-1" gutter={[14, 14]}>
                    <Col span={12}>{t("includeItems")}</Col>
                    <Col span={12} className="col-end">
                      <div>{invoiceIDList?.result?.[0]?.sumQty}</div>
                    </Col>

                    <Col span={12}>{t("grandTotal")} </Col>
                    <Col span={12} className="col-end">
                      <div>
                        <Form.Item
                          shouldUpdate={(prev, next) =>
                            prev.grandTotal !== next.grandTotal
                          }
                        >
                          <div>
                            {new BigNumber(
                              invoiceIDList?.result?.[0]?.totalPrice || 0
                            ).toFormat(2)}
                          </div>
                        </Form.Item>
                      </div>
                    </Col>

                    <Col span={12}>
                      {t("discounts")}{" "}
                      {invoiceIDList?.result?.[0]?.discountPercent &&
                      invoiceIDList?.result?.[0]?.discountPercent !== 0
                        ? `${invoiceIDList?.result?.[0]?.discountPercent} %`
                        : ""}
                    </Col>
                    <Col span={12} className="col-end">
                      <div>
                        {new BigNumber(
                          invoiceIDList?.result?.[0]?.discount || 0
                        ).toFormat(2)}
                      </div>
                    </Col>
                    {locationPath.includes("create-receipt-split-up") ? (
                      <>
                        <Form.Item
                          hidden
                          name="price"
                          validateStatus={splitPayment?.validateStatus}
                          help={splitPayment?.errorMsg}
                        >
                          <Input
                            type="number"
                            onChange={(e) =>
                              onNumberChangeSplitPayment(e.target.value)
                            }
                          />
                        </Form.Item>
                      </>
                    ) : (
                      <Form.Item name="price" hidden>
                        <Input type="number" />
                      </Form.Item>
                    )}
                    <Col span={12}>{t("outstandingBalance")} </Col>
                    <Col span={12} className="col-end">
                      <Form.Item
                        shouldUpdate={(prev, next) =>
                          prev.price !== next.price ||
                          prev.grandTotal !== next.grandTotal
                        }
                      >
                        {(form: FormInstance) => {
                          const price = form.getFieldValue("price");
                          const grandTotal = form.getFieldValue("grandTotal");
                          const priceNumber =
                            Number(grandTotal) >= Number(price)
                              ? new BigNumber(
                                  invoiceIDList?.result?.[0]
                                    ?.outstandingBalance || 0
                                ).minus(price || 0 || 0)
                              : Number(price) === 0
                              ? new BigNumber(0)
                              : 0;
                          return (
                            <div>
                              {new BigNumber(priceNumber || 0).toFormat(2)}
                            </div>
                          );
                        }}
                      </Form.Item>
                    </Col>

                    <Col span={12}>{t("theTotalAmount")}</Col>

                    <Col span={12} className="col-end">
                      <Form.Item
                        shouldUpdate={(prev, next) =>
                          prev.price !== next.price ||
                          prev.grandTotal !== next.grandTotal
                        }
                      >
                        {(form: FormInstance) => {
                          const price = form.getFieldValue("price");
                          return (
                            <div>{new BigNumber(price || 0).toFormat(2)}</div>
                          );
                        }}
                      </Form.Item>
                    </Col>
                  </Row>
                ) : (
                  <Row className="mt-1" gutter={[14, 14]}>
                    <Col span={12}>{t("grandTotal")} </Col>
                    <Col span={12} className="col-end">
                      <div>
                        {/* {new BigNumber(totalPrice.totalPrice || 0).toFormat(2)}
                         */}
                        {new BigNumber(
                          invoiceIDList?.result?.[0]?.totalPrice || 0
                        ).toFormat(2)}
                      </div>
                    </Col>

                    <Col span={12}>
                      {t("discounts")}{" "}
                      {invoiceIDList?.result?.[0]?.discountPercent || 0} %
                    </Col>
                    <Col span={12} className="col-end">
                      <div>
                        {new BigNumber(
                          invoiceIDList?.result?.[0]?.discount || 0
                        ).toFormat(2)}
                      </div>
                    </Col>

                    <Col span={12}>{t("outstandingBalance")}</Col>
                    <Col span={12} className="col-end">
                      <div>
                        {new BigNumber(
                          invoiceIDList?.result?.[0]?.outstandingBalance || 0
                        ).toFormat(2)}
                      </div>
                    </Col>

                    <Col span={12}>{t("theTotalAmount")}</Col>
                    <Col span={12} className="col-end">
                      <div>
                        {new BigNumber(
                          invoiceIDList?.result?.[0]?.outstandingBalance ||
                            invoiceIDList?.result?.[0]?.netPrice ||
                            0
                        ).toFormat(2)}
                      </div>
                    </Col>
                  </Row>
                )}

                <Divider />
              </Col>
            </Row>
          </Form>
        </Card>

        <ReceiptPayment invoiceIDList={invoiceIDList} />
      </Card>
    </div>
  );
};

export default Fields;
