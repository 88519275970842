import { Form } from "antd";
import { IInvoice } from "@interface/IInvoice.interface";
import { useEffect } from "react";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useCreateInvoice } from "@services/api/invoice";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { useUpdateBillDiscount } from "@services/api/bill";
import { IChild2, IItemProduct } from "@interface/IBill.interface";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useGetBillByBill, useUpdateBill } from "@services/api/bill";
import { RouteComponentProps, useLocation } from "react-router-dom";
import React from "react";
import Fields from "./Fields";
import Header from "./Header";
import moment from "moment";
import Decimal from "@tools/decimal";
import BigNumber from "bignumber.js";
import RoleContext from "@provider/RoleProvider";
import UserContext from "@provider/UserProvider";

const CreateInvoice = () => {
  const { i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const billID = match.params.billingID;
  const location = useLocation();
  const locationPath = location.pathname;
  const client = useQueryClient();
  const billIDList = useGetBillByBill(billID, {
    option: 0,
  });
  const [billForm] = Form.useForm();
  const createInvoice = useCreateInvoice();
  const updateBill = useUpdateBill();
  const updateDiscount = useUpdateBillDiscount();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PAY";
  });
  const userList = React.useContext(UserContext);

  useEffect(() => {
    if (!billIDList?.data) return;
    const patient = billIDList?.data?.result?.[0]?.order?.patient;
    const nameTH = [
      patient?.[i18n.language === "th" ? "titleTh" : "titleEn"],
      patient?.[i18n.language === "th" ? "firstNameTh" : "firstNameEn"],
      patient?.[i18n.language === "th" ? "lastNameTh" : "lastNameEn"],
    ]
      ?.join(" ")
      ?.trim();
    const nameEN = [patient?.titleEn, patient?.firstNameEn, patient?.lastNameEn]
      ?.join(" ")
      ?.trim();
    const user =
      i18n.language === "th"
        ? [userList?.firstNameTh, userList?.lastNameTh]?.join(" ")?.trim()
        : [userList?.firstNameEn, userList?.lastNameEn]?.join(" ")?.trim();
    const mailingAddress = patient?.mailingAddress;
    const mailAddress =
      mailingAddress?.addressTh || mailingAddress?.districtTh
        ? [
            mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            mailingAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "districtTh" : "districtEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "provinceTh" : "provinceEn"
            ],
            mailingAddress?.zipcode,
            mailingAddress?.country,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const _homeAddress = patient?.homeAddress;
    const homeAddress =
      _homeAddress?.addressTh || _homeAddress?.districtTh
        ? [
            _homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            _homeAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            _homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
            _homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
            _homeAddress?.country,
            _homeAddress?.zipcode,
          ]
            ?.join(" ")
            ?.trim()
        : "";

    billForm.setFieldsValue({
      name: i18n.language === "th" ? nameTH : nameEN,
      address: mailAddress || homeAddress,
      zipCode: !!mailAddress
        ? patient?.mailingAddress?.zipcode
        : patient?.homeAddress?.zipcode,
      date: moment(),
      referenceNumber: billIDList?.data?.result?.[0]?.billNumber,
      discount: billIDList?.data?.result?.[0]?.discount
        ? billIDList?.data?.result?.[0]?.discount || 0
        : billIDList?.data?.result?.[0]?.discountPercent
        ? new BigNumber(billIDList?.data?.result?.[0]?.totalPrice)
            .dividedBy(100)
            .multipliedBy(billIDList?.data?.result?.[0]?.discountPercent)
        : 0,

      discountPercent: billIDList?.data?.result?.[0]?.discountPercent || 0,
      sale: user,
      description: billIDList?.data?.result?.[0]?.description,
      netPrice: billIDList?.data?.result?.[0]?.totalPrice,
      billByIdOrder: billIDList?.data,
    });

    // eslint-disable-next-line
  }, [billIDList?.data, i18n.language]);

  const onFinish = async (value: any) => {
    const discount = value?.billByIdOrder?.result?.[0]?.order?.groups
      ?.flatMap((item: IItemProduct) =>
        item?.children?.[0]?.children?.flatMap((child) => child)
      )
      ?.map((discount: IChild2) => {
        return {
          workOrderItemId: discount?.item?.id,
          discountPercent:
            discount?.item?.discountType === "%"
              ? discount?.item?.discountPercent
              : "0.00",

          discount:
            discount?.item?.discountType === "฿"
              ? Decimal(
                  String(discount?.item?.discount || "0.00")?.replace(",", "")
                )
              : "0.00",
        };
      });

    updateDiscount.mutate(
      { data: discount },
      {
        onSuccess: () => {},
        onError: (res: any) => {
          openNotification({ method: "PUT_ERROR", message: res.message });
        },
        onSettled: () => {
          client.invalidateQueries(["get-bill-byID"]);
          if (locationPath.includes("create-invoice")) {
            onConfirmInvoice(value);
          } else {
            onUpdateBill(value);
          }
        },
      }
    );
  };

  const onUpdateBill = (values: any) => {
    updateBill.mutate(
      {
        id: billID,
        data: {
          description: values?.description,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-bill-byID"]);
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
      }
    );
  };

  const onConfirmInvoice = (values: any) => {
    createInvoice.mutate(
      {
        data: {
          discountPercent: values?.discountPercent,
          discount: String(values?.discount || "0.00")?.replace(",", ""),
          description: values?.description,
          billId: billIDList?.data?.result?.[0]?.id,
        },
      },
      {
        onSuccess: (res: ElementaryOneMore<IInvoice>) => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(
            `${PAY_LIST_TAB.editInvoice}/${res?.result?.[0]?.id}/patient/${patientID}`
          );
        },
        onError: () => {
          openNotification({ method: "POST_ERROR" });
        },
        onSettled: () => {
          client.invalidateQueries(["get-invoice-number"]);
        },
      }
    );
  };

  return (
    <React.Fragment>
      <Header
        locationPath={locationPath}
        history={history}
        billForm={billForm}
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
      <div className="set-layout ">
        <Fields
          locationPath={locationPath}
          billIDList={billIDList?.data}
          billForm={billForm}
          onFinish={onFinish}
          history={history}
          billID={billID}
          medicalRecord={medicalRecord?.[0]?.isEdit}
        />
      </div>
    </React.Fragment>
  );
};

export default CreateInvoice;
