import { dayjs } from "@tools/timezone";
import { IItem } from "@interface/IOrder.interface";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { splitKeyFormData } from "@tools/util";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { PRE_REGISTRATIONMARK } from "@assets/data/pathRouter";
import { cardType, day, month } from "@assets/data/data";
import { useCreatePreRegister } from "@services/api/draft_order";
import { useGetDraftOrderList } from "@services/api/draft_order";
import { useDeleteFileDraftOrder } from "@services/api/draft_order";
import { useUploadFileDraftOrder } from "@services/api/draft_order";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ACCOUNT_TYPE, ENUM_ID_CARD } from "@tools/util.enum";
import { useUpdatePatientAssignEvent } from "@services/api/patient";
import { Button, Card, Col, DatePicker } from "antd";
import { Form, FormInstance, Input, Row } from "antd";
import { useContext, useEffect, useState } from "react";
import { IGetPatientList, ISeparatePrefix } from "@interface/IOpd.interface";
import { useCreatePatient, useUpdatePatient } from "@services/api/patient";
import moment from "moment";
import Modaler from "@components/modaler/Modaler";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import FileSpace from "@components/file_space/FileSpace";
import UserContext from "@provider/UserProvider";
import RoleContext from "@provider/RoleProvider";
import OrderOutType from "@components/draft_order/order_out_type/OrderOutType";
import SearchPatient from "./search/SearchPatient";
import SelectorChildrenValue from "@components/selector/SelectorChildrenValue";

type Props = {
  prefixList?: ISeparatePrefix;
  preRegister: FormInstance;
  patientID: number;
  history: RouteComponentProps["history"];
  locationPath: string;
  eventID: number;
  patientById?: IGetPatientList;
  historyPatient: boolean;
};

const Fields = ({
  prefixList,
  preRegister,
  patientID,
  history,
  locationPath,
  eventID,
  patientById,
  historyPatient,
}: Props) => {
  const { t, i18n } = useTranslation();
  const dateFormat = "DD-MM-YYYY";
  const [yearSelect, setYearSelect] = useState<any[]>([]);
  const [visible, setVisible] = useState(false);
  const draftOrderList = useGetDraftOrderList(patientID, eventID);
  const createPatient = useCreatePatient();
  const updatePatient = useUpdatePatient();
  const assignEvent = useUpdatePatientAssignEvent();
  const uploadFile = useUploadFileDraftOrder();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const roleList = useContext(RoleContext);
  const createPreRegister = useCreatePreRegister();
  const client = useQueryClient();
  const deleteFile = useDeleteFileDraftOrder();
  const userList = useContext(UserContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PREREGISTER2";
  });
  const [orderList, setOrderList] = useState<{
    order: IItem[];
  }>({
    order: [],
  });

  useEffect(() => {
    setYear();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    preRegister.setFieldsValue({
      ...patientById,
      searchPatient: patientById?.id || patientID || undefined,
      day: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("DD")
        : undefined,
      month: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("MM")
        : undefined,
      year: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("YYYY")
        : undefined,
      registrant:
        i18n.language === "th"
          ? [userList?.firstNameTh, userList?.lastNameTh]?.join(" ")?.trim()
          : [userList?.firstNameEn, userList?.lastNameEn]?.join(" ")?.trim(),
      date: patientById?.createdAt ? moment(patientById?.createdAt) : moment(),
      file: !!draftOrderList?.data?.result?.[0]?.file
        ? [
            {
              url: draftOrderList?.data?.result?.[0]?.file,
              name: draftOrderList?.data?.result?.[0]?.fileName,
            },
          ]
        : undefined,
    });
    // eslint-disable-next-line
  }, [patientById, draftOrderList?.data]);

  const setYear = () => {
    const year = new Array(100)
      ?.fill({ name: "", id: "" })
      ?.map((_: any, index: number) => {
        return { name: `${2565 - index}`, id: `${2022 - index}` };
      });
    setYearSelect(year);
  };

  const changeYear = (year: string) => {
    if (!year) {
      preRegister.setFieldsValue({
        year: null,
      });
    } else {
      preRegister.setFieldsValue({
        year: (Number(year) - 543).toString(),
      });
    }
  };

  const onCreate = (id: number, values: any) => {
    const order = orderList?.order?.map((parent) => {
      if (parent?.children1?.length) {
        const child = parent?.children1?.flatMap((children) => {
          return children?.children;
        });

        return {
          id: parent?.id,
          qty: parent?.qty,
          productDescriptionTh: parent?.productDescriptionTh,
          productDescriptionEn: parent?.productDescriptionEn,
          option: parent?.option,
          courses: child?.map((item) => {
            return {
              id: item?.id,
              option: item?.option,
            };
          }),
        };
      }
      return {
        id: parent?.id,
        qty: parent?.qty,
        productDescriptionTh: parent?.productDescriptionTh,
        productDescriptionEn: parent?.productDescriptionEn,
        option: parent?.option,
      };
    });
    createPreRegister?.mutate(
      {
        id: patientID,
        data: {
          productIds: order,
          eventId: Number(eventID),
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          if (!!values?.file) {
            uploadFilePreregister(id, values);
          } else {
            if (
              locationPath.includes("old-customer") ||
              locationPath.includes("create-draft")
            ) {
              history.replace(`${PRE_REGISTRATIONMARK.event}/${eventID}`);
            } else {
              history.replace(
                `${PRE_REGISTRATIONMARK.event}/${eventID}/create-draft/patient/${id}`
              );
            }
          }
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };

  const onFinish = (value: any) => {
    let dateOfBirth;
    if (value?.year && value?.month && value?.day) {
      dateOfBirth = dayjs(
        `${value?.year}-${value?.month}-${value?.day}`
      ).format("YYYY-MM-DD");
    } else {
      dateOfBirth = undefined;
    }
    const data = {
      ...value,
      dateOfBirth: dateOfBirth,
      preregisterProduct: true,
    };
    if (patientID) {
      update(data);
    } else {
      create(data);
    }
  };

  const update = (values: any) => {
    const data = { ...values };
    delete data?.file;

    let formData = splitKeyFormData(data);

    updatePatient.mutate(
      { id: patientID, data: formData },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          if (locationPath.includes("old-customer")) {
            updateAssignEvent(patientID, values);
          } else if (
            draftOrderList?.status === "error" &&
            !locationPath?.includes("old-customer")
          ) {
            onCreate(patientID, values);
          } else if (locationPath?.includes("/create-draft/patient")) {
            onCreate(patientID, values);
          } else if (!!values?.file) {
            uploadFilePreregister(patientID, values);
          } else {
            history.replace(`${PRE_REGISTRATIONMARK.event}/${eventID}`);
          }
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };

  const create = (values: any) => {
    createPatient.mutate(
      { ...values, accountType: ACCOUNT_TYPE.PRE_REGISTER },
      {
        onSuccess: (res) => {
          updateAssignEvent(res.result?.[0]?.id, values);
        },
        onError: (res) => {
          openNotification({ method: "POST_ERROR", message: String(res) });
        },
      }
    );
  };

  const updateAssignEvent = (id: number, values: any) => {
    assignEvent.mutate(
      { id: id, data: { eventId: eventID } },
      {
        onSuccess: () => {
          if (locationPath.includes("/create")) {
            history.replace(
              `${PRE_REGISTRATIONMARK.event}/${eventID}/create-draft/patient/${id}`
            );
          } else {
            onCreate(id, values);
          }
        },
      }
    );
  };

  const uploadFilePreregister = (id: number, value: any) => {
    let form = new FormData();

    form.append("eventId", String(eventID));
    form.append("file", value?.file?.[0]?.originFileObj);

    uploadFile.mutate(
      { id: patientID, data: form },
      {
        onSettled: () => {
          if (
            locationPath?.includes("old-customer") ||
            locationPath?.includes("create-draft")
          ) {
            history.replace(`${PRE_REGISTRATIONMARK.event}/${eventID}`);
          } else if (locationPath?.includes("/edit-by/patient")) {
            client.invalidateQueries(["get-draft-order-list", id, eventID]);
            client.invalidateQueries(["get-patient-by-id", id]);
          } else {
            history.replace(
              `${PRE_REGISTRATIONMARK.event}/${eventID}/create-draft/patient/${patientID}`
            );
          }
        },
      }
    );
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e?.fileList;
  };

  const getFileName = () => {
    return false;
  };

  const onDelete = () => {
    deleteFile.mutate(
      {
        patientId: patientID,
        eventId: eventID,
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
          client.invalidateQueries([
            "get-draft-order-list",
            patientID,
            eventID,
          ]);
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
        },
        onSettled: () => {
          setIsModalOpen(false);
        },
      }
    );
  };

  return (
    <>
      <Modaler
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>

      <Form name="preRegister " form={preRegister} onFinish={onFinish}>
        <Card
          className="card-prefix"
          extra={
            <b className="header-card t-blue fs-18">
              {t("preRegister.register")}
            </b>
          }
        >
          {!locationPath.includes("edit-by") ? (
            <Row className="mb-1">
              <Col lg={24}>
                <Button
                  className=" btn-search"
                  block
                  onClick={() => setVisible(true)}
                >
                  {t("allNursery")}
                </Button>
              </Col>
            </Row>
          ) : null}

          <Card className="mt-05">
            <Row>
              <Col span="12">
                <Row gutter={[14, 14]} className="margin-form">
                  <Col lg={6}>
                    <Form.Item label={t("prefixTh")} name="titleTh">
                      <Selector
                        placeholder=""
                        Source={prefixList?.TITLE_TH || []}
                        keyName="name"
                        keyValue="name"
                        onChange={() => {
                          document.getElementById("firstNameTh")?.focus();
                        }}
                        disabled={historyPatient}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("nameTh")} name="firstNameTh">
                      <Input
                        id="firstNameTh"
                        disabled={historyPatient}
                        onPressEnter={(e) => {
                          document.getElementById("lastNameTh")?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("lastNameTh")} name="lastNameTh">
                      <Input
                        disabled={historyPatient}
                        id="lastNameTh"
                        onPressEnter={(e) => {
                          document.getElementById("titleEn")?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={6}>
                    <Form.Item label={t("prefixEn")} name="titleEn">
                      <Selector
                        disabled={historyPatient}
                        placeholder=""
                        Source={prefixList?.TITLE_EN || []}
                        keyName="name"
                        keyValue="name"
                        id="titleEn"
                        onChange={() => {
                          document.getElementById("firstNameEn")?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("nameEn")} name="firstNameEn">
                      <Input
                        disabled={historyPatient}
                        id="firstNameEn"
                        onPressEnter={(e) => {
                          document.getElementById("lastNameEn")?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("lastNameEn")} name="lastNameEn">
                      <Input
                        disabled={historyPatient}
                        id="lastNameEn"
                        onPressEnter={(e) => {
                          document.getElementById("gender")?.focus();
                        }}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item
                      label={t("contactNumber")}
                      name="mobile"
                      rules={[
                        {
                          pattern: /^[()+\d]{9,20}$/,
                          message: t("pleaseNumber"),
                        },
                      ]}
                    >
                      <Input disabled={historyPatient} />
                    </Form.Item>
                  </Col>

                  <Col className="mt-1" span="24">
                    <div className="t-grey-form">{t("dateOfBirth")}:</div>
                    <div className="mt-form">
                      <Row gutter={[5, 14]}>
                        <Col lg={4}>
                          <Form.Item name="day">
                            <SelectorChildrenValue
                              disabled={historyPatient}
                              placeholder={t("day")}
                              Source={day}
                              keyName="name"
                              keyValue="id"
                              id="day"
                              onChange={() => {
                                document.getElementById("month")?.focus();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={10}>
                          <Form.Item name="month">
                            <SelectorChildrenValue
                              disabled={historyPatient}
                              placeholder={t("month")}
                              Source={month}
                              keyName={
                                i18n.language === "th" ? "nameTh" : "nameEn"
                              }
                              keyValue="id"
                              id="month"
                              onChange={() => {
                                document.getElementById("year")?.focus();
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={10}>
                          <Form.Item name="year">
                            <SelectorChildrenValue
                              disabled={historyPatient}
                              placeholder={t("year")}
                              Source={yearSelect}
                              keyName="id"
                              keyValue="name"
                              id="year"
                              onChange={(e) => {
                                document.getElementById("remark")?.focus();
                                changeYear(e);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col lg={10}>
                    <Form.Item
                      label={t("cardType")}
                      name="idCard"
                      rules={[{ required: true, message: t("pleaseSelect") }]}
                    >
                      <Selector
                        disabled={historyPatient}
                        onChange={(e) => {
                          document.getElementById("idCardNumber")?.focus();
                          preRegister.setFieldsValue({
                            ...preRegister,
                            idCardNumber: "",
                          });
                        }}
                        placeholder=""
                        Source={cardType}
                        keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                        keyValue="id"
                        id="idCard"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={14}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) => prev.idCard !== next.idCard}
                    >
                      {(form: FormInstance) => {
                        const idCard = form.getFieldValue("idCard");

                        if (idCard === ENUM_ID_CARD.PASSPORT) {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[{ required: true }]}
                            >
                              <Input
                                disabled={historyPatient}
                                id="idCardNumber"
                                onPressEnter={(e) => {
                                  document
                                    .getElementById("citizenship")
                                    ?.focus();
                                }}
                              />
                            </Form.Item>
                          );
                        } else if (idCard === ENUM_ID_CARD.CITIZEN) {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject(
                                        new Error(`${t("pleaseInput")}!`)
                                      );
                                    } else if (!/^(-$)|\d+$/.test(value)) {
                                      return Promise.reject(
                                        new Error(t("pleaseFillInNumbers"))
                                      );
                                    } else if (value?.length < 13) {
                                      return Promise.reject(
                                        new Error(t("moreCardNumbers"))
                                      );
                                    } else if (value?.length > 13) {
                                      return Promise.reject(
                                        new Error(t("theCardNumberLess"))
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Input
                                disabled={historyPatient}
                                id="idCardNumber"
                                onPressEnter={(e) => {
                                  document
                                    .getElementById("citizenship")
                                    ?.focus();
                                }}
                              />
                            </Form.Item>
                          );
                        } else {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[{ required: true }]}
                            >
                              <Input disabled />
                            </Form.Item>
                          );
                        }
                      }}
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item label={t("details")} name="remark">
                      <TextArea
                        disabled={historyPatient}
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        placeholder={t("moreDetails")}
                      />
                    </Form.Item>
                  </Col>

                  {patientID && (
                    <Col lg={24}>
                      <Form.Item
                        label={t("file")}
                        name="file"
                        valuePropName="fileList"
                        getValueFromEvent={getFile}
                      >
                        <FileSpace
                          disabled={historyPatient}
                          maxCount={1}
                          accept=".pdf,.png,.JPG"
                          beforeUpload={getFileName}
                          showUploadList={{
                            showDownloadIcon: true,
                          }}
                          onRemove={(e) => {
                            setIsModalOpen(!isModalOpen);

                            return false;
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span="12">
                <Row gutter={[14, 14]} className="margin-form" justify="end">
                  <Col lg={16}>
                    <Form.Item
                      className="pre-register-form"
                      label={`${t("date")} :`}
                      name="date"
                    >
                      <DatePicker
                        disabled={historyPatient}
                        format={dateFormat}
                      />
                    </Form.Item>
                  </Col>

                  <Col lg={16}>
                    <Form.Item
                      className="pre-register-form"
                      label={`${t("registrant")} :`}
                      name="registrant"
                    >
                      <Input placeholder={t("registrant")} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {patientID ? (
              <>
                <OrderOutType
                  patientID={patientID}
                  draftOrderList={draftOrderList?.data?.result?.[0]}
                  medicalRecord={medicalRecord?.[0]?.isEdit}
                  orderList={{
                    orderList: orderList,
                    setOrderList: setOrderList,
                  }}
                  draftStatus={draftOrderList?.status === "error"}
                />
              </>
            ) : null}
          </Card>
        </Card>
      </Form>
      {!!visible && (
        <SearchPatient visible={{ visible: visible, setVisible: setVisible }} />
      )}
    </>
  );
};

export default Fields;
