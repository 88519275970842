import type { RouteComponentProps } from "react-router-dom";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { ORDER_TYPE } from "@tools/util.enum";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { IDraftBillByID } from "@interface/IDraftBill.interface";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { useDeleteDraftBillById } from "@services/api/draft_bill";
import { Card, Form, FormInstance } from "antd";
import { ExclamationCircleOutlined, PrinterFilled } from "@ant-design/icons";
import { Button, Col, Input, Row, Divider, DatePicker } from "antd";
import TextArea from "antd/lib/input/TextArea";

import Modaler from "@components/modaler/Modaler";
import TableBill from "./Table";
import ProductCategory from "@components/product_category/ProductCategory";
import BigNumber from "bignumber.js";
import { useHistory } from "react-router-dom";

type Props = {
  draftBillById?: ElementaryOneMore<IDraftBillByID>;
  draftBillForm: FormInstance;
  onFinish: (values: any) => void;
  medicalRecord: boolean;
  billID: number;
};
const dateFormat = "DD-MM-YYYY";

const Fields = ({
  draftBillById,
  draftBillForm,
  onFinish,
  medicalRecord,
  billID,
}: Props) => {
  const [deleteForm] = useForm();
  const [visible, setVisible] = useState({
    oneDay: false,
    continuous: false,
    labOtherOne: false,
    labOtherTwo: false,
    delete: false,
  });
  const [product, setProduct] = useState({
    orderItemId: 0,
    addByCashier: false,
  });
  const client = useQueryClient();
  const { t, i18n } = useTranslation();
  const deleteItems = useDeleteDraftBillById();
  const history = useHistory<RouteComponentProps["history"]>();
  const createNowOneDay = () => {
    setVisible({ ...visible, oneDay: !visible.oneDay });
  };

  const createNowContinuous = () => {
    setVisible({ ...visible, continuous: !visible.continuous });
  };

  const createNowLabOtherOne = () => {
    setVisible({ ...visible, labOtherOne: !visible.labOtherOne });
  };

  const createNowLabOtherTwo = () => {
    setVisible({ ...visible, labOtherTwo: !visible.labOtherTwo });
  };

  const onModalDelete = () => {
    setVisible({ ...visible, delete: !visible.delete });
  };

  const onDelete = (value?: { remark: string }) => {
    deleteItems.mutate(
      {
        data: {
          orderItemId: product?.orderItemId,
          remark: value?.remark,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
          onModalDelete();
          client.invalidateQueries(["get-draft-bill-by-id"]);
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
          onModalDelete();
        },
      }
    );
  };
  const onOpenViewReport = () => {
    history.push(`${PAY_LIST_TAB.reportCreateBill}/${billID}`);
  };
  return (
    <Card
      className="bg-grey m-1-doctor-room"
      extra={
        <div>
          <div className="fs-20 f-normal t-grey-label">{t("createBill")}</div>
        </div>
      }
    >
      <Form name="draftBillForm" form={draftBillForm} onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col lg={15}>
            <Row gutter={[14, 14]} align="middle">
              <Col lg={12}>
                <Form.Item label={t("customerName")} name="name">
                  <Input disabled />
                </Form.Item>

                <Form.Item label={t("address")} name="address">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    placeholder=""
                  />
                </Form.Item>

                <Form.Item label={t("zipCode")} name="zipCode">
                  <Input disabled placeholder={t("zipCode")} />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <div className="example">
                  <span className="f-bold ">{`${t("contact")} :`}</span>
                  <span className="break-inside ">
                    {
                      draftBillById?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "nameTh" : "nameEn"
                      ]
                    }
                  </span>
                  <span className="break-inside ">
                    {[
                      draftBillById?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "addressTh" : "addressEn"
                      ],
                      draftBillById?.result?.[0]?.branch?.[
                        i18n.language === "th"
                          ? "subDistrictTh"
                          : "subDistrictEn"
                      ],
                      draftBillById?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "districtTh" : "districtEn"
                      ],
                      draftBillById?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "provinceTh" : "provinceEn"
                      ],
                      draftBillById?.result?.[0]?.branch?.zipcode,
                    ]
                      ?.join(" ")
                      ?.trim()}
                  </span>
                </div>

                <div>
                  <span className="f-bold">{`${t("taxId")} :`}</span>{" "}
                  {draftBillById?.result?.[0]?.branch?.taxId}
                </div>

                <div>
                  <span className="f-bold">{`${t("phone")} :`}</span>{" "}
                  {draftBillById?.result?.[0]?.branch?.phone}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={9}>
            {/* {locationPath.includes("create-invoice") ? null : ( */}
            <Row justify="end" align="middle">
              <Col className="col-end" span={24}>
                <PrinterFilled
                  onClick={onOpenViewReport}
                  style={{ fontSize: "32px" }}
                />
              </Col>
              <Col>{t("print")}</Col>
            </Row>
            {/* )} */}

            <div className="fs-16 f-bold">{t("theTotalAmount")}</div>
            <div className="fs-20 f-bold t-blue mt-1 ">
              {new BigNumber(
                draftBillById?.result?.[0]?.netPrice || 0
              ).toFormat(2)}
            </div>
            <Row gutter={[14, 14]} className="mt-1">
              <Col md={24} lg={24}>
                <Form.Item
                  label={t("date")}
                  className="pre-register-form"
                  name="date"
                >
                  <DatePicker disabled format={dateFormat} />
                </Form.Item>
              </Col>

              <Col md={24} lg={24}>
                <Form.Item
                  label={t("salesperson")}
                  name="sale"
                  className="pre-register-form"
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />
        <Form.Item name="billByIdOrder">
          <TableBill
            deleteForm={deleteForm}
            draftBillForm={draftBillForm}
            setProduct={setProduct}
            onModalDelete={onModalDelete}
            medicalRecord={medicalRecord}
          />
        </Form.Item>

        {medicalRecord ? (
          <Row gutter={[14, 14]}>
            <span className="mr-1">
              <Button type="primary" onClick={createNowOneDay}>
                {`+${t("addOrder")} oneDay`}
              </Button>
            </span>

            <span className="mr-1">
              <Button type="primary" onClick={createNowContinuous}>
                {`+${t("addOrder")} Continuous`}
              </Button>
            </span>

            <span className="mr-1">
              <Button type="primary" onClick={createNowLabOtherOne}>
                {`+${t("addOrder")} Lab other one`}
              </Button>
            </span>

            <span>
              <Button type="primary" onClick={createNowLabOtherTwo}>
                {`+${t("addOrder")} Lab other two`}
              </Button>
            </span>
          </Row>
        ) : null}
        {visible.oneDay && (
          <ProductCategory
            orderId={draftBillById?.result?.[0]?.orderId}
            visible={visible.oneDay}
            onModal={createNowOneDay}
            status="add-product-draft-bill"
            type={ORDER_TYPE.ONE_DAY}
          />
        )}
        {visible.continuous && (
          <ProductCategory
            orderId={draftBillById?.result?.[0]?.orderId}
            visible={visible.continuous}
            onModal={createNowContinuous}
            status="add-product-draft-bill"
            type={ORDER_TYPE.CONTINUE}
          />
        )}
        {visible.labOtherOne && (
          <ProductCategory
            orderId={draftBillById?.result?.[0]?.orderId}
            visible={visible.labOtherOne}
            onModal={createNowLabOtherOne}
            status="lab-other-one"
            type={ORDER_TYPE.LAB_INTERFACE_OTHER_ONE}
          />
        )}
        {visible.labOtherTwo && (
          <ProductCategory
            orderId={draftBillById?.result?.[0]?.orderId}
            visible={visible.labOtherTwo}
            onModal={createNowLabOtherTwo}
            status="lab-other-two"
            type={ORDER_TYPE.LAB_INTERFACE_OTHER_TWO}
          />
        )}
        <Row gutter={[14, 14]}>
          <Col span={15}>
            <Form.Item label={t("remarks")} name="description">
              <TextArea
                autoSize={{ minRows: 2, maxRows: 3 }}
                disabled={!medicalRecord}
              />
            </Form.Item>
          </Col>
          <Col span={9}>
            <Row className="mt-1">
              <Col span={12}>{t("totalMoney")}</Col>
              <Col span={12} className="col-end">
                <Form.Item
                  shouldUpdate={(prev, next) => prev.netPrice !== next.netPrice}
                >
                  {(form: FormInstance) => {
                    const netPrice = form.getFieldValue("netPrice");
                    return (
                      <div>{new BigNumber(netPrice || 0).toFormat(2)}</div>
                    );
                  }}
                </Form.Item>
              </Col>
            </Row>

            <Form.Item
              shouldUpdate={(prev, next) =>
                prev.discount !== next.discount ||
                prev.netPrice !== next.netPrice
              }
            >
              {(form: FormInstance) => {
                const discount = form.getFieldValue("discount");
                const netPrice = form.getFieldValue("netPrice");
                return (
                  <span>
                    <Row className="mt-1">
                      <Col span={12}>
                        {`${t("discounts")}
                       ${
                         draftBillById?.result?.[0]?.discountPercent &&
                         draftBillById?.result?.[0]?.discountPercent !== 0
                           ? `${draftBillById?.result?.[0]?.discountPercent} % `
                           : ""
                       }`}
                      </Col>
                      <Col span={12} className="col-end">
                        <div>
                          {`${new BigNumber(
                            discount ||
                              draftBillById?.result?.[0]?.discount ||
                              0
                          ).toFormat(2)}`}
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-1">
                      <Col span={12}>{t("theTotalAmount")}</Col>
                      <Col span={12} className="col-end">
                        <div>
                          {Number(discount) <= (netPrice || 0) &&
                          Number(discount) > 0
                            ? new BigNumber(netPrice || 0)
                                .minus(discount || 0)
                                .toFormat(2)
                            : new BigNumber(netPrice || 0).toFormat(2)}
                        </div>
                      </Col>
                    </Row>
                  </span>
                );
              }}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <Modaler
        loadingButton={deleteItems?.isLoading}
        visible={visible.delete}
        onCancel={onModalDelete}
        onOk={() => {
          product?.addByCashier === false ? deleteForm.submit() : onDelete();
        }}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        <div>
          {t("doYouWantToDelete")}
          {product?.addByCashier === false && (
            <Form name="deleteForm" form={deleteForm} onFinish={onDelete}>
              <Form.Item
                name="remark"
                label={t("reasonForDeletion")}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Form>
          )}
        </div>
      </Modaler>
    </Card>
  );
};

export default Fields;
