import React, { useState } from "react";
import TableInvoice from "./Table";
import SearchInvoice from "./Search";

const Invoice = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <div>
      <SearchInvoice
        setSearchValue={setSearchValue}
        setCurrentPage={setCurrentPage}
      />
      <TableInvoice
        searchValue={searchValue}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Invoice;
