export const Age = (date: string) => {
  let birthday = +new Date(date);

  // const dateOfBirth = date ? moment(date).format("YYYY") : "";
  // const newDate = moment(new Date()).format("YYYY");
  // const age = dateOfBirth ? Number(newDate) - Number(dateOfBirth) : "";

  //เเปลงเป็นเวลาไทย moment(Number(date)).add(543, "year").format('DD/MM/YYYY HH:MM')
  return ~~((Date.now() - birthday) / 31557600000);
};
