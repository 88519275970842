import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { IVisitsUpDatePhysical } from "@interface/IVisits.interface";
import { Button, Col, DatePicker, Divider } from "antd";
import { Form, FormInstance, Input, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";

interface IValue {
  note: boolean;
  nurseNote: boolean;
}

interface IVisible {
  visible: IValue;
  setVisible: (e: IValue) => void;
}

type Props = {
  visible: IVisible | any;
  name: string;
  title: string;
  onFinishUpdate: (e: IVisitsUpDatePhysical) => void;
  modalNote: FormInstance;
};

const ModalPhysicalRecord = ({
  visible,
  name,
  title,
  onFinishUpdate,
  modalNote,
}: Props) => {
  const { t } = useTranslation();
  const loadingButton = useRecoilValue(loadingButtonState);
  const dateFormat = "DD-MM-YYYY";

  const onCancel = () => {
    visible.setVisible({
      ...visible.visible,
      [`${name}`]: !visible.visible[`${name}`],
    });
  };

  const onFinish = (value: any) => {
    onFinishUpdate(value);
  };

  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col md={20} lg={20} className="t-blue ">
              <b>{title}</b>
            </Col>
            <Col md={4} lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={visible.visible}
      onOk={() => visible.setVisible(false)}
      onCancel={onCancel}
      width="70%"
      footer={null}
    >
      <div className="scroll">
        <Form
          onFinish={onFinish}
          className="p-2"
          name="modalNote"
          form={modalNote}
        >
          <Row gutter={[14, 14]} className="margin-form">
            {name === "physicalNote" ? (
              <>
                <Col md={12} lg={12}>
                  <Form.Item
                    label={`${t("physicalTherapyStartDate")}`}
                    name="date"
                  >
                    <DatePicker format={dateFormat} disabled={true} />
                  </Form.Item>
                </Col>

                <Col md={24} lg={24}>
                  <Form.Item
                    label={`${t("diagnosis")} / ${t("importantSymptoms")}`}
                    name="symptoms"
                  >
                    <TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                  </Form.Item>
                </Col>
                <Col md={24} lg={24}>
                  <Form.Item
                    label={`${t("summaryPatientProblems")}`}
                    name="summary"
                  >
                    <TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                  </Form.Item>
                </Col>
                <Col md={24} lg={24}>
                  <Form.Item label={`${t("shortTermGoals")}`} name="shortTerm">
                    <TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                  </Form.Item>
                </Col>
                <Col md={24} lg={24}>
                  <Form.Item label={`${t("longTermGoals")}`} name="longTerm">
                    <TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                  </Form.Item>
                </Col>
                <Col md={24} lg={24}>
                  <Form.Item
                    label={t("physicalTherapyTreatmentPlan")}
                    name="treatmentPlan"
                  >
                    <TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
                  </Form.Item>
                </Col>
              </>
            ) : (
              <>
                <Col md={12} lg={12}>
                  <Form.Item label="Date" name="date">
                    <DatePicker format={dateFormat} disabled={true} />
                  </Form.Item>
                </Col>

                <Col md={12} lg={12}>
                  <Form.Item
                    label={`${t("physicalTherapist")}`}
                    name="physical"
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col md={12} lg={12}>
                  <Form.Item label="Duration" name="duration">
                    <TextArea autoSize={{ minRows: 4, maxRows: 7 }} />
                  </Form.Item>
                </Col>

                <Col md={12} lg={12}>
                  <Form.Item label="S" name="s">
                    <TextArea autoSize={{ minRows: 4, maxRows: 7 }} />
                  </Form.Item>
                </Col>

                <Col md={12} lg={12}>
                  <Form.Item label="O" name="o">
                    <TextArea autoSize={{ minRows: 4, maxRows: 7 }} />
                  </Form.Item>
                </Col>

                <Col md={12} lg={12}>
                  <Form.Item label="A" name="a">
                    <TextArea autoSize={{ minRows: 4, maxRows: 7 }} />
                  </Form.Item>
                </Col>

                <Col md={12} lg={12}>
                  <Form.Item label="P" name="p">
                    <TextArea autoSize={{ minRows: 4, maxRows: 7 }} />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => modalNote.submit()}
                loading={loadingButton}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalPhysicalRecord;
