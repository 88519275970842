import { useState } from "react";
import TableOrderInfo from "./Table";
import SearchOrderInfo from "./Search";

const OrderInfo = () => {
  const [search, setSearch] = useState();
  const [currentPage, setCurrentPage] = useState<number>(1);

  const onFinish = (value: any) => {
    setSearch(value);
  };
  return (
    <>
      <SearchOrderInfo onFinish={onFinish} setCurrentPage={setCurrentPage} />
      <TableOrderInfo
        search={search}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </>
  );
};

export default OrderInfo;
