import { RouteComponentProps } from "react-router-dom";
import { useGetPatientByHistory } from "@services/api/patient";
import { useHistory, useRouteMatch } from "react-router";
import { useGetOrderListOutstanding } from "@services/api/order";
import Header from "./Header";
import FormProfile from "./form/Form";
import MainPatientByIdProvider from "@provider/patient_by_id";
import MainPatientProfileProvider from "@provider/profile/opd/patient_profile";
import MainProductOutStandingProvider from "@provider/product_out_standing";

const PatientProfile = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const patientById = useGetPatientByHistory(pathID);
  const outStanding = useGetOrderListOutstanding(pathID);

  return (
    <MainPatientByIdProvider value={{ patientById: patientById?.data }}>
      <MainProductOutStandingProvider
        value={{ outStanding: outStanding?.data?.result }}
      >
        <MainPatientProfileProvider
          value={{
            history: history,
            pathID: pathID,
          }}
        >
          <div>
            <Header />
            <FormProfile />
          </div>
        </MainPatientProfileProvider>
      </MainProductOutStandingProvider>
    </MainPatientByIdProvider>
  );
};

export default PatientProfile;
