import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { tabsPayState } from "@tools/recoil/recoil-atom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useGetPatientByHistory } from "@services/api/patient";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Card, Col, Divider, Radio, Row } from "antd";
import Invoice from "./invoice/Invoice";
import Receipt from "./receipt/Receipt";
import BackHome from "@components/button/BackHome";
import BeforeBill from "./before_bill/BeforeBill";
import BillingNotes from "./bill/BillingNotes";
import ImagePatient from "@components/image/ImagePatient";

const obj: { [key: string]: { menu: JSX.Element } } = {
  "1": { menu: <BeforeBill /> },
  "2": { menu: <BillingNotes /> },
  "3": { menu: <Invoice /> },
  "4": { menu: <Receipt /> },
};

const tabs = [
  {
    name: "quotation",
    key: "1",
  },
  {
    name: "billingNote",
    key: "2",
  },
  {
    name: "invoice",
    key: "3",
  },
  {
    name: "receipt",
    key: "4",
  },
];

const TabsPay = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const patientByID = useGetPatientByHistory(patientID);
  const [tabsPay, setTabsPay] = useRecoilState(tabsPayState);

  const tabsCallback = (key: string) => {
    setTabsPay(key);
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("pay.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} /</span>
              <span className="t-dark-blue f-bold">{t("information")}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey m-1-doctor-room "
          extra={
            <>
              <Row
                justify="center"
                gutter={[14, 14]}
                className="text-row-profile ml-05"
                align="middle"
              >
                <Col xs={4} md={4} lg={3} xl={2}>
                  <ImagePatient src={patientByID?.data?.image} />
                </Col>
                <Col>
                  <div>
                    {i18n.language === "th"
                      ? ` ${patientByID?.data?.firstNameTh || " "}    ${patientByID?.data?.lastNameTh || " "
                      }`
                      : ` ${patientByID?.data?.firstNameEn || " "}    ${patientByID?.data?.lastNameEn || " "
                      }`}
                  </div>

                  <div className="code-profile ">
                    HN : {patientByID?.data?.hn}
                  </div>
                </Col>
                <Col>
                  <span className="mr-05">{`${t("age")} :`} </span>
                  <span className="t-grey-light">
                    {" "}
                    {Age(patientByID?.data?.dateOfBirth || "")}
                  </span>
                </Col>

                <Col>
                  <span className="mr-05">{`${t("sex")} :`} </span>
                  <span className="t-grey-light">
                    {gender(patientByID?.data?.gender, i18n?.language)}
                  </span>
                </Col>

                <Col>
                  <span className="mr-05">{`${t("phoneNumber")} :`} </span>
                  <span className="t-grey-light">
                    {" "}
                    {patientByID?.data?.mobile || "-"}
                  </span>
                </Col>
              </Row>
            </>
          }
        >
          <Radio.Group
            value={tabsPay}
            onChange={(e) => tabsCallback(e?.target?.value)}
          >
            {tabs?.map((item) => {
              return (
                <Radio.Button
                  key={item?.key}
                  value={item?.key}
                  className={
                    item?.key === "1"
                      ? "first-child"
                      : item?.key === String(tabs?.length)
                        ? "last-child"
                        : "last-center"
                  }
                >
                  {t(item?.name)}
                </Radio.Button>
              );
            })}
          </Radio.Group>

          <Divider className="canvasdraw" />
          <div className="mt-1">{obj?.[tabsPay]?.menu}</div>

          {/* <Tabs
            className="tab-printdraw f-normal"
            defaultActiveKey={tabsPay}
            onChange={tabsCallback}
          >
            {tabs?.map((item) => {
              return (
                <TabPane tab={t(item?.name)} key={item?.key}>
                  <div className="mt-1">{obj?.[item?.key]?.menu}</div>
                </TabPane>
              );
            })}
          </Tabs> */}
        </Card>
      </div>
    </div>
  );
};

export default TabsPay;
