import { dayjs } from "@tools/timezone";
import { IInvoice } from "@interface/IInvoice.interface";
import { Card, Table } from "antd";
import { PAYMENT_TYPE } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import BigNumber from "bignumber.js";

type Props = {
  invoiceIDList?: ElementaryOneMore<IInvoice>;
};

const ReceiptPayment = ({ invoiceIDList }: Props) => {
  const { t } = useTranslation();
  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, data: any, index: number) => {
        return <div key={index}>{index + 1}</div>;
      },
    },
    {
      title: t("paymentDate"),
      dataIndex: "date",
      key: "index",
      render: (date: string) => {
        return dayjs(date).format("DD-MM-YYYY");
      },
    },

    {
      title: t("amountReceived"),
      dataIndex: "price",
      key: "index",
      render: (price: number) => {
        return new BigNumber(price || 0).toFormat(2);
      },
    },

    {
      title: t("paymentChannel"),
      dataIndex: "payment",
      key: "index",
      render: (payment: string) => {
        return (
          <span>
            {payment === PAYMENT_TYPE.CASH
              ? t("cash")
              : payment === PAYMENT_TYPE.TRANSFER
              ? t("transfer")
              : payment === PAYMENT_TYPE.CREDIT
              ? t("creditCard")
              : "-"}
          </span>
        );
      },
    },
  ];
  return (
    <Card className="mt-2">
      <div>{t("paymentRecord")}</div>

      <div className="mt-1 mb-1 ">
        <Table
          className="components-table-demo-nested table-selection table-confirm  "
          columns={columns}
          dataSource={invoiceIDList?.result?.[0]?.receipt || []}
          rowKey="id"
          scroll={{ x: 500 }}
          pagination={false}
        />
      </div>
    </Card>
  );
};

export default ReceiptPayment;
