import { dayjs } from "@tools/timezone";
import { useEffect } from "react";
import { useGetAppointments } from "@services/api/appointments";
interface Props {
  date: string;
  viewSwitcher?: string;
  slotType?: string;
}

const GetAppointmentTooltip = (props: Props) => {
  const appointmentList = useGetAppointments();

  useEffect(() => {
    const current = dayjs(props.date);
    let startDate = current.startOf("day").format("YYYY-MM-DD");
    let endDate = current.endOf("day").format("YYYY-MM-DD");
    if (props.viewSwitcher === "Week") {
      startDate = current.startOf("week").format("YYYY-MM-DD");
      endDate = current.endOf("week").format("YYYY-MM-DD");
    } else if (props.viewSwitcher === "Month") {
      startDate = current.startOf("month").format("YYYY-MM-DD");
      endDate = current.endOf("month").format("YYYY-MM-DD");
    }

    appointmentList.mutate({
      limit: 0,
      startDate: startDate,
      endDate: endDate,
      slotType: props.slotType,
    });
    // eslint-disable-next-line
  }, [props.date, props.viewSwitcher]);

  return appointmentList?.data?.result?.[0]?.data;
};

export default GetAppointmentTooltip;
