import ImportantInfo from "@components/historical_info/ImportantInfo";
import ImagePatient from "@components/image/ImagePatient";
import SymbolView from "@components/symbol/Symbol";
import { TextBRHTML } from "@tools/util";
import { Button, Card, Col, Form, Row } from "antd";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import Link from "@assets/img/icon/Link.svg";
import File from "@assets/img/icon/File.svg";
import { useMainPatientById } from "@provider/patient_by_id";
import moment from "moment";
import { ENUM_G6PD } from "@tools/util.enum";
import ViewImage from "@components/drawing_canvas/ViewImage";
import CardOrder from "./CardOrder";
import { useRouteMatch } from "react-router-dom";
import { useGetOrderGroupsOutstanding } from "@services/api/order";
import { useGetWorkOrderGroupsOutstanding } from "@services/api/work_order";

const FormOrderInfo = () => {
  const { t, i18n } = useTranslation();
  const [patientList] = Form.useForm();
  const { patientById, visit } = useMainPatientById();
  const match: any = useRouteMatch();
  const visitId: number = match.params.visitId;
  const orderList = useGetOrderGroupsOutstanding(visitId);
  const workOrderList = useGetWorkOrderGroupsOutstanding(visitId);
  const [visible, setVisible] = useState({
    doctorComment: false,
    leadingSymptoms: false,
  });

  const leadingSymptomsModal = () => {
    setVisible({ ...visible, leadingSymptoms: !visible?.leadingSymptoms });
  };

  const doctorCommentModal = () => {
    setVisible({ ...visible, doctorComment: !visible?.doctorComment });
  };

  return (
    <Card
      className="bg-grey "
      extra={
        <React.Fragment>
          <Row gutter={[14, 14]} className="text-row-profile" align="middle">
            <Col>
              <ImagePatient src={""} />
            </Col>
            <Col>
              <Row>
                <Col xs={24} lg={24}>
                  {patientById
                    ? i18n.language === "th"
                      ? `${patientById?.firstNameTh}   ${patientById?.lastNameTh} `
                      : `${patientById?.firstNameEn}   ${patientById?.lastNameEn} `
                    : ``}
                </Col>

                <Col xs={24} lg={24}>
                  <div className="code-profile">
                    HN : {patientById ? `${patientById?.hn}   ` : ``}
                  </div>
                </Col>
              </Row>
            </Col>
            <Col style={{ marginLeft: "auto" }}>
              <div style={{ color: "#FEB139" }}>
                <SymbolView />
              </div>
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {patientById?.g6pd === ENUM_G6PD.DEFICIENCY
                  ? "G6PD Deficiency"
                  : null}
              </div>
            </Col>
          </Row>
        </React.Fragment>
      }
    >
      <div className="mb-05">
        <span className=" t-blue fs-18 ">{t("fullList")} </span>
        <span className=" t-grey-light  fs-12 ">
          {t("orderCreationDate")} :{" "}
        </span>
        <span className="t-grey fs-12 ">
          {visit ? `${moment(visit?.createdAt).format("DD-MM-YYYY")}` : ``}
        </span>
      </div>
      <Form name="basicInfoForm" form={patientList}>
        <ImportantInfo />
      </Form>

      <Card
        className="comment-card mt-2 "
        extra={
          <Fragment>
            <Row gutter={[14, 14]} align="middle">
              <Col md={12} lg={12}>
                {`${t("basicInformation")} / ${t("importantSymptoms")}`}
              </Col>
              <Col md={12} lg={12} className="col-end link-btn">
                <Button type="link" onClick={leadingSymptomsModal}>
                  {t("seePaintings")}
                </Button>
              </Col>
            </Row>
          </Fragment>
        }
      >
        <div
          className="t-black-light"
          dangerouslySetInnerHTML={{
            __html: TextBRHTML("aaaaaa"),
          }}
        />

        {visit?.visitFile?.radiology && (
          <div className="f-normal mt-2 mb-1">Radiology Result</div>
        )}

        {visit?.visitFile?.radiology &&
          visit?.visitFile?.radiology?.map((item, index) => {
            return (
              <Row
                key={index}
                align="middle"
                className="mt-05 cursor"
                onClick={() => window.open(item?.filePath)}
              >
                <img
                  src={Link}
                  alt="Link"
                  width={13}
                  height={13}
                  style={{ marginRight: "2px" }}
                />
                <img
                  src={File}
                  alt="File"
                  width={25}
                  height={25}
                  style={{ marginRight: "5px" }}
                />
                <div>{item?.fileName || ""}</div>
              </Row>
            );
          })}
        {visit?.visitFile?.medical && (
          <div className="f-normal mt-2 mb-1">Medical Report</div>
        )}

        {visit?.visitFile?.medical &&
          visit?.visitFile?.medical?.map((item, index) => {
            return (
              <Row
                key={index}
                align="middle"
                className="mt-05 cursor"
                onClick={() => window.open(item?.filePath)}
              >
                <img
                  src={Link}
                  alt="Link"
                  width={13}
                  height={13}
                  style={{ marginRight: "2px" }}
                />
                <img
                  src={File}
                  alt="File"
                  width={25}
                  height={25}
                  style={{ marginRight: "5px" }}
                />
                <div>{item?.fileName || ""}</div>
              </Row>
            );
          })}
        {visit?.visitFile?.summary && (
          <div className="f-normal mt-2 mb-1">Summary Result</div>
        )}

        {visit?.visitFile?.summary &&
          visit?.visitFile?.summary?.map((item, index) => {
            return (
              <Row
                key={index}
                align="middle"
                className="mt-05 cursor"
                onClick={() => window.open(item?.filePath)}
              >
                <img
                  src={Link}
                  alt="Link"
                  width={13}
                  height={13}
                  style={{ marginRight: "2px" }}
                />
                <img
                  src={File}
                  alt="File"
                  width={25}
                  height={25}
                  style={{ marginRight: "5px" }}
                />
                <div>{item?.fileName || ""}</div>
              </Row>
            );
          })}
      </Card>

      <Card
        className="comment-card mt-2 "
        extra={
          <>
            <Row gutter={[14, 14]} align="middle">
              <Col md={12} lg={12}>
                {t("doctorOpinion")}
              </Col>
              {
                <Col md={12} lg={12} className="col-end link-btn">
                  <Button type="link" onClick={doctorCommentModal}>
                    {t("seePaintings")}
                  </Button>
                </Col>
              }
            </Row>
          </>
        }
      >
        {visit?.suggestion?.print && (
          <div
            className="t-black-light"
            dangerouslySetInnerHTML={{
              __html: TextBRHTML(visit?.suggestion?.print || ""),
            }}
          />
        )}
      </Card>

      <CardOrder
        orderList={
          Array.isArray(orderList?.data?.result?.[0])
            ? []
            : orderList?.data?.result
        }
        title="Order"
        disable={false}
      />

      <CardOrder
        orderList={
          Array.isArray(workOrderList?.data?.result?.[0])
            ? []
            : workOrderList?.data?.result
        }
        title="Work Order"
        disable={true}
      />
      {visible.leadingSymptoms && (
        <ViewImage
          title={`${t("basicInformation")} / ${t("importantSymptoms")}`}
          visible={visible?.leadingSymptoms}
          setModal={leadingSymptomsModal}
          drawing={visit?.data?.drawing || ""}
        />
      )}
      {visible.doctorComment && (
        <ViewImage
          visible={visible?.doctorComment}
          setModal={doctorCommentModal}
          drawing={visit?.suggestion?.drawing || ""}
        />
      )}
    </Card>
  );
};

export default FormOrderInfo;
