import { EVENT } from "@assets/data/pathRouter";
import { useHistory } from "react-router";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import React from "react";
import BackHome from "@components/button/BackHome";
import RoleContext from "@provider/RoleProvider";

type Props = {
  confirm: FormInstance;
  eventID: number;
};

const Header = ({ confirm, eventID }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const roleList = React.useContext(RoleContext);
  const create = useIsMutating(["create-event"]);
  const update = useIsMutating(["update-event-by-id"]);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "EVENT";
  });

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} goHome={`${EVENT.title}`} />

            <Col className="header-text">
              <div>
                <h1>{t("marketing.title")}</h1>
              </div>
              <span className="t-light-gray">
                {eventID
                  ? `${t("event.title")} / ${t("event.manage")} / `
                  : `${t("event.title")} / `}
              </span>
              <span className="t-dark-blue f-normal">
                {eventID ? t("edit") : "สร้าง"}
              </span>
            </Col>
          </Row>
        </Col>
        {medicalRecord?.[0]?.isEdit ? (
          <Col lg={4} className="d-flex justify-between">
            <Button
              className="mr-1 cancel"
              htmlType="reset"
              block
              onClick={history.goBack}
            >
              {t("cancel")}
            </Button>
            <Button
              className="btn-create"
              htmlType="submit"
              onClick={() => confirm.submit()}
              block
              loading={!!create || !!update}
            >
              {t("save")}
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
