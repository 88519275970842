import { dayjs } from "@tools/timezone";
import { ENUM_ID_CARD } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { cardType, month } from "@assets/data/data";
import { IGetPatientList } from "@interface/IOpd.interface";
import { openNotification } from "@components/notification/Notification";
import { PRE_REGISTRATION } from "@assets/data/pathRouter";
import { splitKeyFormData } from "@tools/util";
import { RouteComponentProps } from "react-router-dom";
import { useGetDraftOrderList } from "@services/api/draft_order";
import { useContext, useEffect } from "react";
import { useCreateNewDraftOrder } from "@services/api/draft_order";
import { useUpdatePatientAssignEvent } from "@services/api/patient";
import { useCreatePatient, useUpdatePatient } from "@services/api/patient";
import { Card, Col, DatePicker, Form, FormInstance, Input, Row } from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import FileSpace from "@components/file_space/FileSpace";
import UserContext from "@provider/UserProvider";
import RoleContext from "@provider/RoleProvider";
import OrderOutType from "@components/draft_order/order_out_type/OrderOutType";
import SelectorChildrenValue from "@components/selector/SelectorChildrenValue";

type Props = {
  preRegister: FormInstance;
  patientID: number;
  history: RouteComponentProps["history"];
  eventID: number;
  patientById?: IGetPatientList;
};

const Fields = ({
  preRegister,
  patientID,
  history,
  eventID,
  patientById,
}: Props) => {
  const { t, i18n } = useTranslation();
  const dateFormat = "DD-MM-YYYY";
  const draftOrderList = useGetDraftOrderList(patientID, eventID);
  const createPatient = useCreatePatient();
  const updatePatient = useUpdatePatient();
  const createNewDraftOrder = useCreateNewDraftOrder();
  const assignEvent = useUpdatePatientAssignEvent();
  const roleList = useContext(RoleContext);
  const userList = useContext(UserContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PREREGISTER1";
  });

  useEffect(() => {
    preRegister.setFieldsValue({
      ...patientById,
      serchPatient: patientById?.id || patientID || undefined,

      day: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("DD")
        : undefined,
      month: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("MM")
        : undefined,
      year: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("YYYY")
        : undefined,

      registrant:
        i18n.language === "th"
          ? [userList?.firstNameTh, userList?.lastNameTh]?.join(" ")?.trim()
          : [userList?.firstNameEn, userList?.lastNameEn]?.join(" ")?.trim(),
      date: patientById?.createdAt ? moment(patientById?.createdAt) : moment(),
      file: !!draftOrderList?.data?.result?.[0]?.file
        ? [
            {
              url: draftOrderList?.data?.result?.[0]?.file,
              name: draftOrderList?.data?.result?.[0]?.fileName,
            },
          ]
        : undefined,
    });
    // eslint-disable-next-line
  }, [draftOrderList?.data, patientById]);

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const getFileName = () => {
    return false;
  };

  const onFinish = (value: any) => {
    let dateOfBirth;
    if (value.year && value.month && value.day) {
      dateOfBirth = dayjs(`${value.year}-${value.month}-${value.day}`).format(
        "YYYY-MM-DD"
      );
    } else {
      dateOfBirth = undefined;
    }
    const data = {
      ...value,
      dateOfBirth: dateOfBirth,
      preregisterProduct: true,
    };
    if (patientID) {
      update(data);
    } else {
      create(data);
    }
  };

  const create = (values: any) => {
    createPatient.mutate(
      { ...values, accountType: 0 },
      {
        onSuccess: (res) => {
          updateAssignEvent(res.result?.[0]?.id);
          createNewDraft(res.result?.[0]?.id);
        },
      }
    );
  };

  const updateAssignEvent = (id: number) => {
    assignEvent.mutate({ id: id, data: { eventId: eventID } }, {});
  };

  const createNewDraft = (id: number) => {
    createNewDraftOrder.mutate(
      { data: { eventId: eventID }, id: id },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(
            `${PRE_REGISTRATION.event}/${eventID}/create-draft/patient/${id}`
          );
        },
        onError: (res) => {
          openNotification({ method: "POST_ERROR", message: String(res) });
        },
      }
    );
  };

  const update = (values: any) => {
    let formData = splitKeyFormData(values);
    updatePatient.mutate(
      { id: patientID, data: formData },
      {
        onSuccess: () => {
          updateAssignEvent(patientID);
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(`${PRE_REGISTRATION.event}/${eventID}`);
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };

  return (
    <>
      <Form name="preRegister " form={preRegister} onFinish={onFinish}>
        <Card
          className="card-prefix"
          extra={
            <b className="header-card t-blue fs-18">
              {t("preRegister.register")}
            </b>
          }
        >
          <Card className="mt-05">
            <Row>
              <Col span="12">
                <Row gutter={[14, 14]} className="margin-form">
                  <Col lg={6}>
                    <Form.Item label={t("prefixTh")} name="titleTh">
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("nameTh")} name="firstNameTh">
                      <Input disabled id="firstNameTh" />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("lastNameTh")} name="lastNameTh">
                      <Input id="lastNameTh" disabled />
                    </Form.Item>
                  </Col>

                  <Col lg={6}>
                    <Form.Item label={t("prefixEn")} name="titleEn">
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("nameEn")} name="firstNameEn">
                      <Input id="firstNameEn" disabled />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("lastNameEn")} name="lastNameEn">
                      <Input id="lastNameEn" disabled />
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item label={t("contactNumber")} name="mobile">
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col className="mt-1" span="24">
                    <div className="t-grey-form">{t("dateOfBirth")}:</div>
                    <div className="mt-form">
                      <Row gutter={[5, 14]}>
                        <Col lg={4}>
                          <Form.Item name="day">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                        <Col lg={10}>
                          <Form.Item name="month">
                            <SelectorChildrenValue
                              placeholder={t("month")}
                              Source={month}
                              keyName={
                                i18n.language === "th" ? "nameTh" : "nameEn"
                              }
                              keyValue="id"
                              id="month"
                              disabled
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={10}>
                          <Form.Item name="year">
                            <Input disabled />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col lg={10}>
                    <Form.Item
                      label={t("cardType")}
                      name="idCard"
                      rules={[{ required: true, message: t("pleaseSelect") }]}
                    >
                      <Selector
                        disabled
                        placeholder=""
                        Source={cardType}
                        keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                        keyValue="id"
                        id="idCard"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={14}>
                    <Form.Item
                      noStyle
                      shouldUpdate={(prev, next) => prev.idCard !== next.idCard}
                    >
                      {(form: FormInstance) => {
                        const idCard = form.getFieldValue("idCard");

                        if (idCard === ENUM_ID_CARD.PASSPORT) {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[{ required: true }]}
                            >
                              <Input
                                id="idCardNumber"
                                disabled
                                onPressEnter={(e) => {
                                  document
                                    .getElementById("citizenship")
                                    ?.focus();
                                }}
                              />
                            </Form.Item>
                          );
                        } else if (idCard === ENUM_ID_CARD.CITIZEN) {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject(
                                        new Error(`${t("pleaseInput")}!`)
                                      );
                                    } else if (!/^(-$)|\d+$/.test(value)) {
                                      return Promise.reject(
                                        new Error(t("pleaseFillInNumbers"))
                                      );
                                    } else if (value?.length < 13) {
                                      return Promise.reject(
                                        new Error(t("moreCardNumbers"))
                                      );
                                    } else if (value?.length > 13) {
                                      return Promise.reject(
                                        new Error(t("theCardNumberLess"))
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Input
                                id="idCardNumber"
                                disabled
                                onPressEnter={(e) => {
                                  document
                                    .getElementById("citizenship")
                                    ?.focus();
                                }}
                              />
                            </Form.Item>
                          );
                        } else {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[{ required: true }]}
                            >
                              <Input disabled />
                            </Form.Item>
                          );
                        }
                      }}
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item label={t("details")} name="remark">
                      <TextArea
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        placeholder={t("moreDetails")}
                        disabled
                      />
                    </Form.Item>
                  </Col>

                  {patientID && (
                    <Col lg={24}>
                      <Form.Item
                        label={t("file")}
                        name="file"
                        valuePropName="fileList"
                        getValueFromEvent={getFile}
                      >
                        <FileSpace
                          disabled={true}
                          maxCount={1}
                          accept=".pdf,.png,.JPG"
                          beforeUpload={getFileName}
                          showUploadList={{
                            showDownloadIcon: true,
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span="12">
                <Row gutter={[14, 14]} className="margin-form" justify="end">
                  <Col lg={16}>
                    <Form.Item
                      className="pre-register-form"
                      label={`${t("date")} :`}
                      name="date"
                    >
                      <DatePicker format={dateFormat} disabled />
                    </Form.Item>
                  </Col>

                  <Col lg={16}>
                    <Form.Item
                      className="pre-register-form"
                      label={`${t("registrant")} :`}
                      name="registrant"
                    >
                      <Input placeholder={t("registrant")} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {patientID ? (
              <>
                <OrderOutType
                  patientID={patientID}
                  draftOrderList={draftOrderList?.data?.result?.[0]}
                  status="not-edit"
                  medicalRecord={medicalRecord?.[0]?.isEdit}
                />
              </>
            ) : null}
          </Card>
        </Card>
      </Form>
    </>
  );
};

export default Fields;
