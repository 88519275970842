import { SETTING } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useProductUpload } from "@services/api/product";
import { Card, Col, Form, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Header from "./Header";
import FileSpace from "@components/file_space/FileSpace";

const FormMedicalDocument = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [confirmMedicalDocument] = Form.useForm();
  const productUpload = useProductUpload();


 

  const getFileName = () => {
    return false;
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onFinish = (value: any) => {
    const formData = new FormData();
    formData?.append("typeId", value?.typeId);
    value?.file?.forEach((item: any) => {
      formData?.append("file", item?.originFileObj);
    });

    productUpload.mutate(formData, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.replace(`${SETTING.wareHouse}`);
      },
      onError: (res) => {
        openNotification({ method: "POST_ERROR", message: String(res) });
      },
    });
  };
  return (
    <div>
      <Header confirmMedicalDocument={confirmMedicalDocument} />
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={
            <b className="fs-16 t-black-light ">{t("wareHouse.upload")}</b>
          }
        >
          <Card
            className="card-prefix card-prefix-width card-prefix-chi"
            extra={<b className="fs-14 c-white ">{t("wareHouse.upload")}</b>}
          >
            <Form
              onFinish={onFinish}
              name="confirmMedicalDocument"
              form={confirmMedicalDocument}
            >
              <Row gutter={[0, 12]}>
                <Col lg={24}>
                  <Form.Item
                    label={t("attachFile")}
                    rules={[{ required: true, message: t("selectItem") }]}
                    name="file"
                    valuePropName="fileList"
                    getValueFromEvent={getFile}
                  >
                    <FileSpace
                      accept=".xlsx"
                      beforeUpload={getFileName}
                      maxCount={1}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default FormMedicalDocument;
