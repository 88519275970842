import React, { FC, ReactElement, useRef, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { checkUpdate } from "@tools/util";
import { ICheckUpdate } from "@interface/IGlobal.interface";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { useReactToPrint } from "react-to-print";
import { openNotification } from "@components/notification/Notification";
import { IResultLabInterface } from "@interface/IProduct.interface";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { useDeleteDraftOrder } from "@services/api/draft_order";
import { useCreateNewAndProducts } from "@services/api/draft_order";
import { useMainLabInterfaceGroup } from "@provider/lab_inteface_group";
import { ExclamationCircleOutlined, PrinterOutlined } from "@ant-design/icons";
import { useUpdateWorkOrderAddProducts } from "@services/api/work_order";
import { useDeleteWorkOrdersEditProduct } from "@services/api/work_order";
import { Button, Col, Divider, Form, Input, Modal, Row, Space } from "antd";
import Modaler from "@components/modaler/Modaler";
import LabInterfaceGroupTwo from "./lab_interface_group_two/LabInterfaceGroupTwo";
import LabInterfaceGroupOne from "./lab_interface_group_one/LabInterfaceGroupOne";

type Props = {
  onClick: (e: boolean) => void;
  visible: boolean;
  title: string;
  group: string;
  room: ENUM_ROOM_SIGNATURE;
};

const ModalGroup: FC<Props> = ({ onClick, visible, title, group, room }) => {
  const match: any = useRouteMatch();
  const appointmentsID = match.params.appointmentsID;
  const [deleteForm] = useForm();
  const [isOpen, setIsOpen] = useState(false);
  const interfaceGroup: { [K: string]: { menu: ReactElement } } = {
    groupOne: { menu: <LabInterfaceGroupOne visible={visible} /> },
    groupTwo: { menu: <LabInterfaceGroupTwo visible={visible} /> },
  };
  const { t } = useTranslation();
  const updateWorkOrderAddProducts = useUpdateWorkOrderAddProducts();
  const deleteDraftOrder = useDeleteDraftOrder();
  const delWorkOrders = useDeleteWorkOrdersEditProduct();
  const createNewAndProducts = useCreateNewAndProducts();
  const client = useQueryClient();
  const {
    labInterfaceGroupOne,
    labInterfaceGroupTwo,
    draftOrderList,
    patientID,
    visitId,
    status,
    eventId,
    labInfoList,
  } = useMainLabInterfaceGroup();
  const printReportRef = useRef(null);
  const pageStyle = `  @page { 
    size:A4;
}`;
  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });
  const checkKeyProduct = () => {
    try {
      var groupOne: ICheckUpdate = {} as ICheckUpdate;
      if (group === "groupOne") {
        groupOne = checkUpdate(
          status === "interface_lab"
            ? [
                ...(labInfoList?.groups1?.[0]?.listKey || []),
                ...(labInfoList?.groups2?.[0]?.listKey || []),
                ...(labInfoList?.groups3?.[0]?.listKey || []),
              ]
            : [
                ...(draftOrderList?.result?.[0]?.labInterface?.groups1?.[0]
                  ?.listKey || []),
                ...(draftOrderList?.result?.[0]?.labInterface?.groups2?.[0]
                  ?.listKey || []),
                ...(draftOrderList?.result?.[0]?.labInterface?.groups3?.[0]
                  ?.listKey || []),
              ],
          labInterfaceGroupOne.current?.flatMap((key: IResultLabInterface) => {
            return key?.id;
          })
        ) as ICheckUpdate;
      }
      var groupTwo: ICheckUpdate = {} as ICheckUpdate;

      if (group === "groupTwo") {
        groupTwo = checkUpdate(
          status === "interface_lab"
            ? [...(labInfoList?.groups4?.[0]?.listKey || [])]
            : [
                ...(draftOrderList?.result?.[0]?.labInterface?.groups4?.[0]
                  ?.listKey || []),
              ],
          labInterfaceGroupTwo.current?.flatMap((key: IResultLabInterface) => {
            return key?.id;
          })
        ) as ICheckUpdate;
      }

      if (
        [
          ...(groupOne?.newData || []),
          ...(groupTwo?.newData || []),
          ...(groupOne?.removeData || []),
          ...(groupTwo?.removeData || []),
        ]?.length
      ) {
        newAndProducts(
          [...(groupOne?.newData || []), ...(groupTwo?.newData || [])],
          [...(groupOne?.removeData || []), ...(groupTwo?.removeData || [])]
        );
      } else {
        onClick(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const newAndProducts = (key: number[], removeData: number[]) => {
    const product = [
      ...(labInterfaceGroupOne.current || []),
      ...(labInterfaceGroupTwo.current || []),
    ]?.filter((item) => {
      return key.includes(item?.id);
    });

    if (status === "interface_lab") {
      updateWorkOrderAddProducts.mutate(
        {
          id: visitId,
          data: {
            productIds: product,
            room: room,
          },
        },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            handleDeleteDraftOrder(removeData);
          },
        }
      );
    } else {
      createNewAndProducts.mutate(
        {
          data: {
            eventId: eventId,
            productIds: product,
            room: room,
          },
          id: patientID,
        },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "PUT_ERROR", message: String(err) });
          },
          onSettled: () => {
            handleDeleteDraftOrder(removeData);
          },
        }
      );
    }
  };

  const onModal = async () => {
    setIsOpen(!isOpen);
  };

  const handleDeleteDraftOrder = async (removeData: number[]) => {
    if (status === "interface_lab") {
      let ids;
      for (const item of [
        ...(labInfoList?.groups1?.[0]?.children?.[0]?.children || []),
        ...(labInfoList?.groups2?.[0]?.children?.[0]?.children || []),
        ...(labInfoList?.groups3?.[0]?.children?.[0]?.children || []),
        ...(labInfoList?.groups4?.[0]?.children?.[0]?.children || []),
      ]) {
        if (removeData?.some((itemList) => itemList === item?.product?.id)) {
          ids = [...(ids || []), item];
        }
      }
      if (Number(ids?.findIndex((item) => item?.addByCashier === false)) > -1) {
        deleteForm.resetFields();
        deleteForm.setFieldsValue({
          id: ids?.map((item) => {
            return item?.id;
          }),
        });
        onModal();
        const uuids = ids?.map((item) => {
          return item?.uuid;
        });
        if (uuids && uuids?.length > 0) {
          deleteForm.resetFields();
          deleteForm.setFieldsValue({
            id: uuids,
          });
        }
      } else {
        deleteWorkOrders(
          ids?.map((item) => {
            return item?.uuid;
          })
        );
      }
    } else {
      let ids: string[] = [];
      for (const item of [
        ...(draftOrderList?.result?.[0]?.labInterface?.groups1?.[0]
          ?.children?.[0]?.children || []),
        ...(draftOrderList?.result?.[0]?.labInterface?.groups2?.[0]
          ?.children?.[0]?.children || []),
        ...(draftOrderList?.result?.[0]?.labInterface?.groups3?.[0]
          ?.children?.[0]?.children || []),
        ...(draftOrderList?.result?.[0]?.labInterface?.groups4?.[0]
          ?.children?.[0]?.children || []),
      ]) {
        if (removeData?.some((itemList) => itemList === item?.product?.id)) {
          ids = [...ids, item?.id];
        }
      }
      deleteDraft(ids);
    }
  };

  const deleteDraft = (id: string[]) => {
    deleteDraftOrder.mutate(
      {
        id: patientID,
        idProduct: {
          eventId: eventId,
          itemId: id,
          room: room,
        },
      },
      {
        onSuccess: () => {
          // openNotification({ method: "DEL_SUCCESS" });
          client.invalidateQueries(["get-draft-order-list"]);
          client.invalidateQueries(["get-appointment-byID", appointmentsID]);
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
        },
        onSettled: () => {
          onClick(false);
        },
      }
    );
  };

  const deleteWorkOrders = (id?: number[] | string[], remark?: string) => {
    if (!!id?.length) {
      delWorkOrders.mutate(
        {
          data: {
            uuids: id,
            room: room,
            remark: remark,
          },
          id: visitId,
        },
        {
          onSuccess: () => {
            client.invalidateQueries(["get-appointment-byID", appointmentsID]);
            client.invalidateQueries(["get-lab-info"]);
          },

          onSettled: () => {
            onClick(false);
          },
        }
      );
    } else {
      client.invalidateQueries(["get-appointment-byID", appointmentsID]);
      client.invalidateQueries(["get-lab-info"]);
      onClick(false);
    }
  };
  const onFinish = (value: { id: number[] | string[]; remark: string }) => {
    deleteWorkOrders(value?.id, value?.remark);
    onModal();
  };

  return (
    <>
      <Modal
        className="manage-modal"
        title={
          <React.Fragment>
            <Row>
              <Col md={20} lg={20} className="t-blue ">
                <b>{title}</b>
              </Col>
              <Col md={4} lg={4} className="col-end"></Col>
            </Row>
          </React.Fragment>
        }
        centered
        visible={visible}
        onOk={() => onClick(false)}
        onCancel={() => onClick(false)}
        forceRender
        width="70%"
        footer={null}
      >
        <div className="scroll ">
          {/* <ReactToPrint content={() => printReportRef.current} /> */}
          <div className="p-1">
            <PrintReport ref={printReportRef}>
              {interfaceGroup[`${group}`].menu}
            </PrintReport>
          </div>
        </div>
        <Row gutter={[14, 14]}>
          <Col span={24}>
            <Divider
              className="m-0 "
              style={{ position: "absolute", top: 0 }}
            />
            <Row
              gutter={[14, 14]}
              justify="end"
              align="middle"
              className="footer"
            >
              <Col>
                <Button
                  className="cancel "
                  key="back"
                  onClick={() => handlePrint()}
                >
                  <Space>
                    <PrinterOutlined />
                    {t("print")}
                  </Space>
                </Button>
              </Col>
              <Col>
                <Button
                  className="cancel "
                  key="back"
                  onClick={() => onClick(false)}
                >
                  {t("cancel")}
                </Button>
              </Col>
              <Col className="mr-05">
                <Button
                  className="btn-create "
                  onClick={() => checkKeyProduct()}
                  loading={
                    createNewAndProducts.isLoading ||
                    deleteDraftOrder.isLoading ||
                    updateWorkOrderAddProducts?.isLoading ||
                    delWorkOrders?.isLoading
                  }
                >
                  {t("save")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>

      <Modaler
        visible={isOpen}
        closable={false}
        loadingButton={delWorkOrders.isLoading || deleteDraftOrder?.isLoading}
        onCancel={() => {
          onModal();
        }}
        onOk={() => {
          deleteForm.submit();
        }}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        <div>
          <Form name="deleteForm" form={deleteForm} onFinish={onFinish}>
            <Form.Item
              name="remark"
              label={t("reasonForDeletion")}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
            <Form.Item noStyle name="id" hidden>
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modaler>
    </>
  );
};

class PrintReport extends React.Component {
  render() {
    return this.props.children;
  }
}
export default ModalGroup;
