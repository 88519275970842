import { Table } from "antd";
import { useRef } from "react";
import { IGroup } from "@interface/IDraftOrder.interface";
import { ORDER_TYPE } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { IOrderListAndRecord } from "@interface/IOrder.interface";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { IChild, IItem, ISelected } from "@interface/IOrder.interface";
import { productName, reformatDataOrderList } from "@tools/util";
import Decimal from "@tools/decimal";

type Props = {
  orderList: ISelectedExecuteOrder[];
  selection: ISelected;
  status?: string;
  setOrderList?: (e: ISelectedExecuteOrder[]) => void;
  outStanding?: IOrderListAndRecord[] | [];
};

const OrderList = ({
  orderList,
  selection,
  status = "",
  setOrderList,
  outStanding = [],
}: Props) => {
  const { t, i18n } = useTranslation();
  let selectionList: string[] = [];
  let statusSelectionList = 0;
  const num = useRef(0);

  const onSelectChange = (selected: any) => {
    const result = selected?.filter(
      (item: string) => !item?.includes("children")
    );
    if (status === "inchartBetween") {
      const dataList = orderList?.flatMap(
        (item) => item?.children?.[0]?.children
      );
      const dataCourse = dataList?.filter(
        (item) =>
          item?.product?.categoryId === "C-0006" ||
          item?.product?.categoryId === "C-0007"
      );

      if (dataCourse) {
        dataCourse?.forEach((item) => {
          const parent = item?.children?.[0]?.children?.filter((children) => {
            return result?.indexOf(children?.uuid) !== -1;
          });

          if (parent?.length <= 0) {
            statusSelectionList = 1;
            selectionList = selection?.selection?.list?.filter(
              (list) => list !== item?.id
            );
          }
        });
      }

      selectionList?.length > 0 || statusSelectionList === 1
        ? reformatData(result, selectionList)
        : selection.setSelection({
            ...selection.selection,
            course: result,
            oldValue: result,
          });
    } else {
      selection.setSelection({
        ...selection.selection,
        course: result,
        oldValue: result,
      });
    }
  };

  const reformatData = (course: string[], selectionList: string[]) => {
    if (setOrderList) {
      const result = reformatDataOrderList(outStanding, selectionList, status);
      setOrderList(result?.dataList);
      selection.setSelection({
        ...selection?.selection,
        course: course,
        oldValue: course,
        list: selectionList,
        oldValueList: selectionList,
        expandAllRows: result?.result,
      });
    }
  };

  const rowSelection = {
    getCheckboxProps: (record: any) => ({
      disabled: record?.use === true,
      name: record?.key,
    }),
    selectedRowKeys: selection?.selection?.course,
    onChange: onSelectChange,
  };
  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: IItem, data: IGroup & IItem, index: number) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? index + 1
              : data?.level === 2
              ? t("nameList")
              : data?.level === 3
              ? productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )
              : data?.level === 4
              ? ""
              : ""}
          </div>
        );
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: any, data: IItem & IChild & IGroup) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? i18n.language === "th"
                ? data?.groupTh
                : data?.groupEn
              : data?.level === 2
              ? `${t("amount")} / ${t("totalNumber")}`
              : data?.level === 3
              ? data?.qtyOutstanding
                ? data?.qtyOutstanding
                : `${data?.qty} / ${data?.allQty || 0}`
              : data?.level === 4
              ? t("nameList")
              : productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "name",
      key: "name",

      render: (_: string, data: IItem & IChild) => {
        return (
          <>
            {data?.level === 1 ? (
              data?.children?.[0]?.children?.length
            ) : data?.level === 2 ? (
              t("amount")
            ) : data?.level === 3 ? (
              <div>{data?.qty + "   "}</div>
            ) : data?.level === 4 ? (
              t("amount")
            ) : (
              data?.qty || ""
            )}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",

      render: (_: string, data: IItem & IChild) => {
        return (
          <>
            {data?.level === 1
              ? ``
              : data?.level === 2
              ? t("type")
              : data?.level === 3
              ? data?.orderType === ORDER_TYPE.ONE_DAY
                ? `One Day`
                : data?.orderType === ORDER_TYPE.CONTINUE
                ? `Continuous`
                : `-`
              : data?.level === 4
              ? ""
              : ""}
          </>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "net",
      key: "net",
      render: (net: string, data: IItem & IChild) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? Decimal(data?.total)
              : data?.level === 2
              ? t("price")
              : data?.level === 3 && Decimal(net)}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Table
        className="components-table-demo-nested table-selection table-confirm thead-table-row3 "
        rowClassName={(r) => {
          if (r?.level === 1 && status === "inchartBetween") {
            return "redio-rowClass";
          } else if (
            r?.level === 1 &&
            r?.children?.[0]?.children?.[0]?.children
          ) {
            return "redio-rowClass no-header";
          } else if (r?.level === 2) {
            return "table-header redio-rowClass no-header ";
          } else if (r?.level === 3 && !r?.children) {
            return "no-header redio-rowClass";
          } else if (r?.level === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "table-confirm-first table-confirm-level-course table-bottom--level-course-num";
            } else {
              return "table-confirm-first table-confirm-level-course";
            }
          } else if (r?.level === 4) {
            num.current = r?.children?.length;
            return "table-header no-header table-confirm-level-course table-top-level-course ";
          } else if (r?.level === 1 || r?.level === 3) {
            return "redio-rowClass no-header";
          }
          return "";
        }}
        rowSelection={{ ...rowSelection, checkStrictly: false }}
        expandedRowKeys={selection?.selection?.expandAllRows}
        onExpandedRowsChange={(e) =>
          selection.setSelection({
            ...selection?.selection,
            expandAllRows: e,
          })
        }
        columns={columns}
        expandRowByClick
        showSorterTooltip
        rowKey="key"
        dataSource={orderList || []}
        pagination={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default OrderList;
