import { COUPON } from "@assets/data/pathRouter";
import { useState } from "react";
import { IParamet } from "@interface/IGlobal.interface";
import { COUPON_STATUS } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { IItem, IProduct } from "@interface/IOrder.interface";
import { useGetCategories } from "@services/api/order";
import { openNotification } from "@components/notification/Notification";
import { discount, statusCoupon } from "@assets/data/data";
import { ICouponList, ICouponProduct } from "@interface/ICoupon.interface";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useCreateCoupon, useUpdateCouponByID } from "@services/api/coupon";
import { Button, Card, Checkbox, Col, DatePicker } from "antd";
import { Empty, Form, FormInstance, Input, Radio, Row, Select } from "antd";
import empty from "@assets/img/empty.svg";
import MoTable from "@components/table/Table";
import Selector from "@components/selector/Selector";
import TextArea from "antd/lib/input/TextArea";
import SelectorDouble from "@components/selector/SelectorDouble";
import ProductCategory from "@components/product_category/ProductCategory";

type Props = {
  confirm: FormInstance;
  couponByID?: IParamet<ICouponList[]>;
  couponID: number;
};

const Fields = ({ confirm, couponByID, couponID }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const { RangePicker } = DatePicker;
  const { Option } = Select;
  const [visible, setVisible] = useState<boolean>(false);
  const [orderList, setOrderList] = useState({
    key: [
      { key: [], dataList: [] },
      { key: [], dataList: [] },
      { key: [], dataList: [] },
      { key: [], dataList: [] },
      { key: [], dataList: [] },
      { key: [], dataList: [] },
    ],
    order: [],
  });
  const categoryList = useGetCategories({ limit: 0 });
  const create = useCreateCoupon();
  const update = useUpdateCouponByID();

  const onFinish = (value: any) => {
    const data =
      value.categorySelete === "product"
        ? {
            ...value,
            discountType: value?.discountType ? value?.discountType : "฿",
            startDate: value?.start?.[0],
            endDate: value?.start?.[1],
            discountPercent: value?.number,
            couponProducts: orderList?.order?.map((item: IItem) => {
              return { productId: item?.id };
            }),
          }
        : {
            ...value,
            discountType: value?.discountType ? value?.discountType : "฿",
            startDate: value?.start?.[0],
            endDate: value?.start?.[1],
            discountPercent: value?.number,
            couponProductCategory: value?.category?.map((item: string[]) => {
              return { productCategoryId: item };
            }),
          };
    if (couponByID) {
      updateCouponByID(data);
    } else {
      createCoupon(data);
    }
  };

  const createCoupon = (value: any) => {
    create.mutate(
      { data: value },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(`${COUPON.title}`);
        },
        onError: (res) => {
          openNotification({ method: "POST_ERROR", message: String(res) });
        },
      }
    );
  };

  const updateCouponByID = (value: any) => {
    update.mutate(
      { id: couponID, data: value },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(`${COUPON.title}`);
        },
        onError: (res) => {
          openNotification({ method: "POST_ERROR", message: String(res) });
        },
      }
    );
  };

  const createNow = () => {
    setVisible(true);
  };

  const onManageModal = () => {
    setVisible(!visible);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: any, data: any, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: t("nameList"),
      dataIndex: "nameTh",
      key: "nameTh",
      render: (nameTh: string, data: ICouponProduct) => {
        return <div>{nameTh || data?.product?.nameTh || "-"}</div>;
      },
    },

    {
      title: t("type"),
      dataIndex: "group",
      key: "group",
      render: (_: any, data: IProduct) => {
        return <div>{data?.group?.nameTh || "-"}</div>;
      },
    },

    {
      title: t("details"),
      dataIndex: "descriptionTh",
      key: "descriptionTh",
      render: (descriptionTh: string, _: ICouponProduct) => {
        return <div>{descriptionTh || "-"}</div>;
      },
    },
  ];

  return (
    <div className="set-layout ">
      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={<b className="fs-16 t-black-light ">{t("coupon.title")}</b>}
      >
        <Card
          className="card-prefix card-prefix-coupon-width card-prefix-chi"
          extra={<b className="fs-14 c-white ">{t("coupon.manage")}</b>}
        >
          <Form
            onFinish={onFinish}
            name="prefixForm"
            form={confirm}
            scrollToFirstError
          >
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev?.status !== next?.status}
            >
              {(form: FormInstance) => {
                const status = form.getFieldValue("status");
                const publicStatus = COUPON_STATUS.PUBLIC === status;
                const expiredStatus = COUPON_STATUS.EXPIRED === status;

                return (
                  <Row gutter={[14, 14]}>
                    <Col span={12}>
                      <Form.Item
                        name="name"
                        label={t("coupon.name")}
                        rules={[{ required: true }]}
                      >
                        <Input
                          placeholder={t("coupon.name")}
                          disabled={publicStatus || expiredStatus}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="status"
                        label={`${t("status")}`}
                        rules={[{ required: true }]}
                      >
                        <Selector.Translation
                          Source={statusCoupon?.filter((item) => {
                            return item?.id !== COUPON_STATUS.EXPIRED;
                          })}
                          keyName={"name"}
                          keyValue="id"
                          disabled={expiredStatus}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={24} lg={24}>
                      <Form.Item
                        name="categorySelete"
                        rules={[
                          { required: true, message: `${t("pleaseSelect")}` },
                        ]}
                      >
                        <Radio.Group disabled={publicStatus || expiredStatus}>
                          <Radio style={{ width: "20vw" }} value="category">
                            {t("category")}
                          </Radio>
                          <Radio value="product">{t("product")}</Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Col>

                    <Col md={24} lg={24}>
                      <Form.Item
                        shouldUpdate={(prev, next) =>
                          prev?.categorySelete !== next?.categorySelete
                        }
                      >
                        {(form: FormInstance) => {
                          const categorySelete =
                            form.getFieldValue("categorySelete");

                          if (categorySelete === "category") {
                            return (
                              <Col span={24}>
                                <Form.Item
                                  name="category"
                                  rules={[
                                    {
                                      required: true,
                                      message: `${t("pleaseSelect")}`,
                                    },
                                  ]}
                                >
                                  <Select
                                    disabled={publicStatus || expiredStatus}
                                    mode="multiple"
                                    className="promotion-select"
                                    allowClear
                                    placeholder="Please select"
                                    filterOption={(input, option: any) => {
                                      return (
                                        option.children
                                          .toLowerCase()
                                          .includes(input) ||
                                        `${input}` === `${option.value}`
                                      );
                                    }}
                                  >
                                    {categoryList?.data?.result?.[0]?.data?.map(
                                      (item, idx) => (
                                        <Option value={item?.["id"]} key={idx}>
                                          {item?.["nameTh"]}
                                        </Option>
                                      )
                                    )}
                                  </Select>
                                </Form.Item>
                              </Col>
                            );
                          } else if (categorySelete === "product") {
                            return (
                              <Row gutter={[14, 14]}>
                                <Col span={24}>
                                  <Row className="header-table  ">
                                    <Col
                                      md={14}
                                      lg={18}
                                      className="f-normal col-start t-dark-blue"
                                    >
                                      <span className="f-bold mr-05 ">
                                        {t("selectAProductList")}
                                      </span>
                                    </Col>
                                    {orderList?.order?.length > 0 ||
                                    couponByID?.result?.[0]?.couponProducts ? (
                                      <Col md={10} lg={6} className="col-end">
                                        {!publicStatus || expiredStatus ? (
                                          <Button
                                            type="primary"
                                            onClick={createNow}
                                          >
                                            {`${t("addOrder")}`}
                                          </Button>
                                        ) : null}
                                      </Col>
                                    ) : (
                                      " "
                                    )}
                                  </Row>
                                  <MoTable
                                    className="components-table-demo-nested table-selection table-confirm "
                                    pagination={false}
                                    locale={{
                                      emptyText: (
                                        <>
                                          <Empty
                                            image={empty}
                                            imageStyle={{
                                              height: 60,
                                            }}
                                            description={
                                              <span className="t-grey-white">
                                                {t("noInformation")}
                                              </span>
                                            }
                                          >
                                            {!publicStatus || expiredStatus ? (
                                              <Button
                                                type="primary"
                                                onClick={createNow}
                                              >
                                                {`+ ${t("addOrder")}`}
                                              </Button>
                                            ) : null}
                                          </Empty>
                                        </>
                                      ),
                                    }}
                                    rowClassName={(r) => {
                                      if (r?.level === 1) {
                                        return " no-header no-cursor";
                                      } else if (r?.level === 2) {
                                        return "table-header no-header no-cursor";
                                      } else if (r?.level === 3) {
                                        return " no-header";
                                      }
                                      return "";
                                    }}
                                    expandRowByClick
                                    rowKey={"id"}
                                    key={"id"}
                                    columns={columns}
                                    dataSource={
                                      orderList?.order?.length > 0
                                        ? orderList?.order
                                        : couponByID?.result?.[0]
                                            ?.couponProducts || []
                                    }
                                    // onClickRow={(e: IItem) => checkRow(e) && onClickRow(e)}
                                    onDoubleClick={false}
                                    scroll={{ x: "calc(100vw -20%)" }}
                                  />
                                </Col>
                              </Row>
                            );
                          }
                        }}
                      </Form.Item>
                    </Col>

                    <Col md={24} lg={12}>
                      <Form.Item
                        name="start"
                        label={t("promotionPeriod")}
                        rules={[
                          { required: true, message: `${t("pleaseSelect")}` },
                        ]}
                      >
                        <RangePicker
                          format="DD/MM/YYYY"
                          disabled={publicStatus || expiredStatus}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={24} lg={12}>
                      <Form.Item name="discountType" label={t("discounts")}>
                        <SelectorDouble
                          placeholder="฿"
                          status={2}
                          Source={discount}
                          value={20}
                          keyName="name"
                          keyValue="name"
                          title={t("status")}
                          disabled={publicStatus || expiredStatus}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={12}>
                      <Form.Item name="uses" valuePropName="checked" noStyle>
                        <Checkbox disabled={publicStatus || expiredStatus}>
                          {t("numberOfUses")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={12}>
                      <Form.Item name="right" valuePropName="checked" noStyle>
                        <Checkbox disabled={publicStatus || expiredStatus}>
                          {t("numberOfRightsPerson")}
                        </Checkbox>
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={12}>
                      <Form.Item
                        shouldUpdate={(prev, next) => prev?.uses !== next?.uses}
                      >
                        {(form: FormInstance) => {
                          const uses = form.getFieldValue("uses");

                          if (uses) {
                            return (
                              <Row gutter={[14, 14]}>
                                <Col span={24}>
                                  <Form.Item name="qtyUse">
                                    <Input
                                      disabled={publicStatus || expiredStatus}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            );
                          }
                        }}
                      </Form.Item>
                    </Col>

                    <Col md={12} lg={12}>
                      <Form.Item
                        shouldUpdate={(prev, next) =>
                          prev?.right !== next?.right
                        }
                      >
                        {(form: FormInstance) => {
                          const uses = form.getFieldValue("right");

                          if (uses) {
                            return (
                              <Row gutter={[14, 14]}>
                                <Col span={24}>
                                  <Form.Item name="qty">
                                    <Input
                                      disabled={publicStatus || expiredStatus}
                                    />
                                  </Form.Item>
                                </Col>
                              </Row>
                            );
                          }
                        }}
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item label={t("details")} name="detail">
                        <TextArea
                          disabled={publicStatus || expiredStatus}
                          autoSize={{ minRows: 3, maxRows: 5 }}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                );
              }}
            </Form.Item>
          </Form>
        </Card>
      </Card>

      {visible && (
        <ProductCategory
          visible={visible}
          onModal={onManageModal}
          status="coupon"
          orderList={{ orderList: orderList, setOrderList: setOrderList }}
        />
      )}
    </div>
  );
};

export default Fields;
