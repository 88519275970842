import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useMainMedicineDoctorRoom } from "@provider/medicine_doctor_room";
import { useCreateAddProductDrugsOption } from "@services/api/draft_order";
import { Button, Col, Divider, Modal, Row } from "antd";
import { useUpdateWorkOrderItemRejectDrugs } from "@services/api/work_order_item";
import TableDrug from "./TableDrug";

type Props = {
  InfoManagerModal: () => void;
  visible: boolean;
};

const ModalInfoDrug = ({ InfoManagerModal, visible }: Props) => {
  const { title, eventId, status, drug } = useMainMedicineDoctorRoom();
  const { t } = useTranslation();
  const [selectedRow, setSelectedRow] = useState<
    { id: number | string; option: string }[]
  >([]);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const patientId = match.params.patientsID || match.params.patientID;
  const client = useQueryClient();
  const updateWorkOrderItemRejectDrugs = useUpdateWorkOrderItemRejectDrugs();
  const addProductDrugsOption = useCreateAddProductDrugsOption();

  const confirm = () => {
    if (status === "return_drug") {
      handleUpdateWorkOrderItemRejectDrugs();
    } else {
      handleAddProductDrugsOption();
    }
  };

  const handleUpdateWorkOrderItemRejectDrugs = () => {
    updateWorkOrderItemRejectDrugs.mutate(
      {
        id: visitID,
        data: {
          ids: selectedRow?.map((item) => item?.id),
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries([
            "get-work-order-item-reject-drugs-format",
            visitID,
          ]);
          InfoManagerModal();
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const handleAddProductDrugsOption = () => {
    addProductDrugsOption.mutate(
      {
        id: patientId,
        data: {
          products: selectedRow?.map((item) => {
            return {
              uuid: item?.id,
              option: item?.option,
            };
          }),
          eventId: eventId,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          client.invalidateQueries([
            "get-draft-order-list",
            patientId,
            eventId,
          ]);
          InfoManagerModal();
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };
  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col lg={20} className="fs-18">
              <b className="f-normal">{t(title)}</b>
            </Col>
            <Col lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={visible}
      onOk={InfoManagerModal}
      onCancel={InfoManagerModal}
      width="70%"
      footer={null}
    >
      <Row gutter={[14, 14]}>
        <Col span={24}>
          <div className="scroll p-2">
            <TableDrug
              selectedRow={{
                selectedRow: selectedRow,
                setSelectedRow: setSelectedRow,
              }}
            />
          </div>
          <Divider className="m-0 " style={{ top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={InfoManagerModal}>
                {t("cancel")}
              </Button>
            </Col>

            <Col className="mr-1">
              {!drug && (
                <Button
                  className="btn-create "
                  onClick={confirm}
                  loading={
                    updateWorkOrderItemRejectDrugs.isLoading ||
                    addProductDrugsOption.isLoading
                  }
                >
                  {t("save")}
                </Button>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalInfoDrug;
