import { REPORT_SUMMARY } from "@assets/data/pathRouter";
import { Card, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";
import UserContext from "@provider/UserProvider";
import { ENUM_ROLE_EN } from "@tools/util.enum";
import React from "react";

const ReportSummary = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const userList = React.useContext(UserContext);
  const data = [
    {
      name: t("operatingRoomReport"),
      key: 1,
      router: REPORT_SUMMARY.operatingRoomReport,
    },
    {
      name: t("productReport"),
      key: 2,
      router: REPORT_SUMMARY.productReport,
    },
    {
      name: t("purchaseByAgeReport"),
      key: 3,
      router: REPORT_SUMMARY.purchaseReport,
    },
    {
      name: t("agentReport"),
      key: 4,
      router: REPORT_SUMMARY.agentReport,
    },
    //new
    {
      name: t("receiptReport"),
      key: 5,
      router: REPORT_SUMMARY.receiptReport,
    },
    {
      name: t("invoiceReport"),
      key: 6,
      router: REPORT_SUMMARY.invoiceReport,
    },
    {
      name: t("patientReport"),
      key: 7,
      router: REPORT_SUMMARY.patientReport,
    },
    {
      name: t("branchReport"),
      key: 8,
      router: REPORT_SUMMARY.branchReport,
    },
    {
      name: t("productPurchaseReport"),
      key: 9,
      router: REPORT_SUMMARY.productPurchaseReport,
    },
    {
      name: t("productPurchaseHnReport"),
      key: 10,
      router: REPORT_SUMMARY.productPurchaseHnReport,
    },
    {
      name: t("productProcedurePurchaseReport"),
      key: 11,
      router: REPORT_SUMMARY.productProcedurePurchaseReport,
    },
    {
      name: t("productProcedureReport"),
      key: 12,
      router: REPORT_SUMMARY.productProcedureReport,
    },
    {
      name: t("productPromotionPurchaseReport"),
      key: 13,
      router: REPORT_SUMMARY.productPromotionPurchaseReport,
    },
  ];

  const columns = [
    {
      title: `${t("sort")}`,
      dataIndex: "index",
      width: "10%",
      render: (_: string, __: string, index: number) => {
        return <div>{index + 1}</div>;
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      render: (name: string) => {
        return name;
      },
    },
  ];
  const roleList = ["SUPER_ADMIN", "SUPER_MARKETING"];
  const routerList = [REPORT_SUMMARY.productPurchaseReport];
  const dataSource = data
    .map((item, i) => {
      if (
        roleList.includes(userList?.role) &&
        routerList.find((router) => router === item.router)
      ) {
        return { ...item, key: i + 1 };
      } else if (item.router !== REPORT_SUMMARY.productPurchaseReport) {
        return { ...item, key: i + 1 };
      }
    })
    .filter((item) => item);
  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("reportSummary.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("report")}</b>
          </span>
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card ">{t("reportSummary.all")}</b>}
        >
          <MoTable
            className="table-selection"
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            onClickRow={(e) => {
              history.push(e.router);
            }}
            scroll={{ x: "calc(100vw -20%)" }}
            rowKey="key"
            key="key"
          />
        </Card>
      </div>
    </div>
  );
};

export default ReportSummary;
