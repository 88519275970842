import { FC } from "react";
import { FormInstance } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import { VISIT_OLD_FILES } from "@tools/util.enum";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  form: FormInstance;
  isLoading: boolean;
};

const Header: FC<Props> = ({ form, isLoading }) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const type: VISIT_OLD_FILES = match.params.fileId;
  const typeUpload: { [K in VISIT_OLD_FILES]: string } = {
    [VISIT_OLD_FILES?.VITAL_SIGN]: t("vitalSign"),
    [VISIT_OLD_FILES?.BIOLOGY]: t("biologicalTherapy"),
    [VISIT_OLD_FILES?.CONSENT]: t("consent"),
    [VISIT_OLD_FILES?.CORRESPONDENCE]: t("correspondence"),
    [VISIT_OLD_FILES?.LAB_RESULT]: t("labResults"),
    [VISIT_OLD_FILES?.MEDICAL_REPORT]: t("medicalReport"),
    [VISIT_OLD_FILES?.MEDICAL_SUMMARY]: t("medicalSummaryReports"),
    [VISIT_OLD_FILES?.PHYSICAL]: t("physicalTherapyRecords"),
    [VISIT_OLD_FILES?.PHYSICAL_ORDER]: t("physicianOrder"),
    [VISIT_OLD_FILES?.RADIOLOGY_REPORT]: t("radiologyReport"),
    [VISIT_OLD_FILES?.TREATMENT]: t("treatmentRecord"),
    [VISIT_OLD_FILES?.REPORT_DRUG]: t("medicationAdministration"),
  };
  const history = useHistory<RouteComponentProps["history"]>();

  return (
    <Row className="second-header " justify="space-between" align="middle">
      <Col>
        <Row gutter={[14, 14]}>
          <BackHome history={history} />

          <Col className="header-text">
            <div>
              <h1>{t("medicalRecord.title")}</h1>
            </div>
            <span className="t-light-gray">
              {`${t("nursery")} / ${t("historyFile")} / ${t(
                "treatmentHistory"
              )} / ${typeUpload?.[type]} /  `}
            </span>
            <span className="t-dark-blue f-normal">{t("uploadDocuments")}</span>
          </Col>
        </Row>
      </Col>

      <Col>
        <Button
          className="mr-1 cancel"
          htmlType="reset"
          onClick={history.goBack}
        >
          {t("cancel")}
        </Button>
        <Button
          className="btn-create"
          onClick={() => form.submit()}
          loading={isLoading}
        >
          {t("save")}
        </Button>
      </Col>
    </Row>
  );
};

export default Header;
