import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Divider, Modal, Row } from "antd";
import TableMedicalRoom from "./Table";

type Props = {
  visible: boolean;
  setModal: () => void;
};

const ModalBookingAnother: FC<Props> = ({ visible, setModal }) => {
  const { t } = useTranslation();

  return (
    <Modal
      className="manage-modal"
      title={t("appointment")}
      centered
      visible={visible}
      onOk={setModal}
      onCancel={setModal}
      width="90%"
      footer={null}
    >
      <div className="scroll">
        <div className="p-2">
          <TableMedicalRoom />
        </div>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel mr-1" key="back" onClick={setModal}>
                {t("cancel")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalBookingAnother;
