import { USER_MANAGE } from "@assets/data/pathRouter";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  formUser: FormInstance;
  userID: number;
  history: RouteComponentProps["history"];
  medicalRecord: boolean;
};

const Header = ({ formUser, userID, history, medicalRecord }: Props) => {
  const { t } = useTranslation();
  const loadingCreate = useIsMutating(["create-user"]);
  const loadingUpdate = useIsMutating(["update-user"]);
  const loadingCreateMedia = useIsMutating(["create-media"]);

  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("userManage")}</h1>
              </div>
              <span className="t-light-gray">{t("user")} / </span>
              <span className="t-dark-blue f-normal">
                {userID ? t("edit") : `${t("create")}`}
              </span>
            </Col>
          </Row>
        </Col>
        {medicalRecord ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="mr-05 cancel"
              onClick={() => history.replace(USER_MANAGE.title)}
            >
              {t("cancel")}
            </Button>
            <Button
              className="btn-create"
              onClick={() => formUser.submit()}
              loading={
                !!loadingCreate || !!loadingCreateMedia || !!loadingUpdate
              }
            >
              {t("save")}
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
