import { useHistory } from "react-router";
import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { Button, Col, FormInstance, Row } from "antd";
import BackHome from "@components/button/BackHome";

type Props = {
  confirm: FormInstance;
  promotionId: number;
};

const Header = ({ confirm, promotionId }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const promotionLoading = useIsMutating(["create-promotion"]);
  const promotionUpdate = useIsMutating(["update-promotion"]);

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("marketing.title")}</h1>
              </div>
              <span className="t-light-gray">
                {promotionId
                  ? `${t("promotion.title")} / ${t("promotion.manage")} / `
                  : `${t("promotion.title")} / `}
              </span>
              <span className="t-dark-blue f-normal">
                {promotionId ? t("edit") : t("create")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col lg={4} className="d-flex justify-between">
          <Button
            className="mr-1 cancel"
            htmlType="reset"
            block
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            htmlType="submit"
            onClick={() => confirm.submit()}
            block
            loading={!!promotionLoading || !!promotionUpdate}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
