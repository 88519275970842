import { thrownResponse } from "./auth";
import { IGetPatientList } from "../../interface/IOpd.interface";
import { IParametePatient } from "@interface/IOpd.interface";
import { IGetHistoryPatient } from "@interface/IOpd.interface";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";
import { useInfiniteQuery, UseInfiniteQueryResult } from "react-query";
import axios from "@axios/index";
import { openNotification } from "@components/notification/Notification";
import { handleMassageError } from "@tools/util";

export const useGetPatientsList = (
  params?: any,
  enabled?: boolean
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(
    ["get-patients-list", params],
    async () => {
      const res = await axios.get(`/patients`, { params: params });
      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }

      thrownResponse(res);
    },
    { enabled: enabled }
  );
};

export const useGetPatientsDropdown = (
  enabled: boolean,
  param: any
): UseInfiniteQueryResult<Elementary<IGetPatientList[]>> => {
  return useInfiniteQuery(
    ["get-patients-dropdown", param],
    async ({ pageParam }) => {
      const res = await axios.get(`/patients/dropdown`, {
        params: { page: 1, ...pageParam, limit: 20, ...param },
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data?.result?.[0];
      }
      thrownResponse(res);
    },
    {
      // staleTime: 0,
      cacheTime: 0,
      enabled: enabled,
    }
  );
};

export const useGetPatientsAllList = (
  params?: any
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-patients-all-list", params], async () => {
    const res = await axios.get(`/patients/all`, { params: params });
    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else {
      return res?.data;
    }
  });
};

export const useAssignBranch = (): UseMutationResult<
  ElementaryOneMore<IGetPatientList>
> => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.put(`/patients/${id}/assign-branch`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else {
      openNotification({
        method: "PUT_ERROR",
        message: handleMassageError(res?.data.message),
      });
    }
    thrownResponse(res);
  });
};

export const useCreatePatient = (): UseMutationResult<
  ElementaryOneMore<IGetPatientList>
> => {
  return useMutation(["create-patient"], async (data: any) => {
    const res = await axios.post(`/patients`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdatePatient = (): UseMutationResult<
  ElementaryOneMore<IGetPatientList>
> => {
  return useMutation(["update-patient"], async ({ id, data }: any) => {
    const res = await axios.put(`/patients/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
      // } else if (res?.status >= 400 && res?.status < 500) {
      //   return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetPatientById = (
  id?: number,
  data?: any
): UseQueryResult<IGetPatientList> => {
  return useQuery(["get-patient-by-id", id, data], async () => {
    if (data) {
      return;
    }
    if (!id) {
      return;
    }

    const res = await axios.get(`/patients/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data?.result?.[0];
    }
    throw new Error(res?.data?.message);
  });
};

export const useGetPatientByHistory = (
  id?: number
): UseQueryResult<IGetPatientList> => {
  return useQuery(
    ["get-patient-by-id", id],
    async () => {
      const res = await axios.get(`/patients/${id}/history`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data?.result?.[0];
      }
      throw new Error(res?.data?.message);
    },
    { enabled: !!id }
  );
};

export const useGetHistoryPreregister = (
  id?: number,
  eventId?: number
): UseQueryResult<IGetHistoryPatient> => {
  return useQuery(["get-history-preregister", id, eventId], async () => {
    if (!id && id !== 0) {
      return;
    }
    const res = await axios.get(
      `/patients/${id}/history-preregister/${eventId}`
    );
    if (res?.status >= 200 && res?.status < 300) {
      return res?.data?.result?.[0];
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
  });
};

export const useDeletePatient = (): UseMutationResult => {
  return useMutation(async (id: any) => {
    if (!id) {
      return;
    }
    const res = await axios.delete(`/patients/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdatePatientAssignEvent = () => {
  return useMutation(async ({ id, data }: any) => {
    if (!id) {
      return;
    }
    const res = await axios.put(`/patients/${id}/assign-event`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetPatientsListByEvent = (
  id: number,
  params?: IParametePatient
): UseQueryResult<Elementary<IGetPatientList[]>> => {
  return useQuery(["get-patients-list-by-event", id, params], async () => {
    if (!id) {
      return;
    }
    const res = await axios.get(`/patients/event/${id}`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const usePatientUploadFile = () => {
  return useMutation(async ({ data }: any) => {
    const res = await axios.post(`/patients/upload`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
