import React, { useEffect, useState } from "react";
import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { ISelected } from "@interface/IOrder.interface";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Card, Col, Form, FormInstance, Input, InputNumber, Row } from "antd";
import OrderList from "../order_list/OrderList";
import ImagePatient from "@components/image/ImagePatient";

type Props = {
  editPrice: FormInstance;
  rejectCourseList: (e: any) => void;
  patientByID?: IGetPatientList;
  orderList: ISelectedExecuteOrder[];
  selection: ISelected;
  medicalRecord: boolean;
};

const Fields = ({
  editPrice,
  rejectCourseList,
  patientByID,
  orderList,
  selection,
  medicalRecord,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(true);

  const viewDetail = () => {
    setOpen(!open);
  };

  useEffect(() => {
    editPrice.setFieldsValue({
      total: selection.selection.price,
    });
    // eslint-disable-next-line
  }, [selection.selection.price]);

  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room height-body  "
        extra={
          <>
            <Row
              justify="center"
              gutter={[14, 14]}
              className="text-row-profile ml-05"
              align="middle"
            >
              <Col xs={4} md={4} lg={3} xl={2}>
                <ImagePatient src={patientByID?.image} />
              </Col>
              <Col>
                <div>
                  {i18n.language === "th"
                    ? ` ${patientByID?.firstNameTh || " "}    ${
                        patientByID?.lastNameTh || " "
                      }`
                    : ` ${patientByID?.firstNameEn || " "}    ${
                        patientByID?.lastNameEn || " "
                      }`}
                </div>

                <div className="code-profile ">HN : {patientByID?.hn}</div>
              </Col>
              <Col>
                <span className="mr-05">{t("age")} : </span>
                <span className="t-grey-light">
                  {Age(patientByID?.dateOfBirth || "")}
                </span>
              </Col>

              <Col>
                <span className="mr-05">{t("sex")} : </span>
                <span className="t-grey-light">
                  {gender(patientByID?.gender, i18n?.language)}
                </span>
              </Col>

              <Col>
                <span className="mr-05">{t("phoneNumber")} : </span>
                <span className="t-grey-light">
                  {patientByID?.mobile || "-"}
                </span>
              </Col>
            </Row>
          </>
        }
      >
        <div>
          <div className="pb-2">
            <Form name="editPrice" form={editPrice} onFinish={rejectCourseList}>
              <Row gutter={[14, 14]}>
                <Col span={12}>
                  <Form.Item label={t("price")} name="total">
                    <Input disabled placeholder={t("price")} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label={t("returnPrice")}
                    name="price"
                    rules={[{ required: true }]}
                  >
                    <InputNumber
                      placeholder={t("returnPrice")}
                      disabled={!medicalRecord}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
          <Row className=" header-table ">
            <Col className="f-normal  t-dark-blue  ">
              <span className="f-bold mr-05 ">{t("confirmedOrderList")}</span>
              <span>
                {React.createElement(open ? UpOutlined : DownOutlined, {
                  onClick: viewDetail,
                })}
              </span>
            </Col>
          </Row>

          <div className={open ? "open" : "close"}>
            <OrderList orderList={orderList} selection={selection} />
          </div>
        </div>
      </Card>
    </>
  );
};

export default Fields;
