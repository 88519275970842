import { Age } from "@tools/age";
import { dayjs } from "@tools/timezone";
import { gender } from "@tools/util";
import { INTERFACE_LAB } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useGetVisitList } from "@services/api/visits";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ENUM_ORDERBY, ENUM_SERVICE_GROUP } from "@tools/util.enum";
import MoTable from "@components/table/Table";

type Props = {
  searchValue: any;
  currentPage: number;
  setCurrentPage: (e: any) => void;
};

const Table = ({ searchValue, currentPage, setCurrentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const patientList = useGetVisitList({
    page: currentPage,
    limit: limitPage,
    gender: searchValue?.gender,
    mobile: searchValue?.mobile,
    name: searchValue?.name,
    proceed: [2, 3, 4, 6],
    serviceGroups: [ENUM_SERVICE_GROUP.LAB_INTERFACE],
    menu: "INTERFACE_LAB",
    data: dayjs().format("YYYY-MM-DD"),
    orderby: ENUM_ORDERBY.DESC,
  });

  useEffect(() => {
    setTotal(patientList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [patientList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${INTERFACE_LAB.detail}/visit/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "hn",
      width: "20%",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (data: any, index: any) => {
        return (
          <div className="f-normal " key={data?.id}>
            {data?.hn}
          </div>
        );
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "name",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (data: any) => {
        const name =
          i18n.language === "th"
            ? [data?.firstNameTh, data?.lastNameTh]?.join(" ")?.trim()
            : [data?.firstNameEn, data?.lastNameEn]?.join(" ")?.trim();
        return (
          <div className="f-normal " key={data?.id}>
            {name}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "patient",
      key: "gender",

      render: (data: any) => {
        let nameGender = gender(data?.gender, i18n?.language);

        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "patient",
      key: "dateOfBirth",
      width: "20%",
      sorter: (a: any, b: any) => a.age - b.age,
      render: (data: any) => {
        return <>{Age(data?.dateOfBirth)}</>;
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "patient",
      sorter: (a: any, b: any) => a.phone - b.phone,
      key: "phone",
      render: (data: any) => {
        return <>{data?.mobile}</>;
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={patientList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
