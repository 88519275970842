import { t } from "i18next";
import { FC } from "react";
import { Age } from "@tools/age";
import { keyNameMarker } from "@interface/IAppointments.interface";
import { IOrderHistory } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { IGetVisits, ISignatures } from "@interface/IVisits.interface";
import { productName, TextBRHTML } from "@tools/util";
import { Checkbox, Col, Row, Image } from "antd";
import { ENUM_ROLE_EN, ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import React from "react";
import moment from "moment";
import ImageLogo from "@components/image/ImageLogo";

const roomSignatures = [
  {
    key: ENUM_ROOM_SIGNATURE.DOCTOR_ROOM,
    name: "doctorroom",
  },
  {
    key: ENUM_ROOM_SIGNATURE.PA_ROOM,
    name: "physicianAssistant",
  },
  {
    key: ENUM_ROOM_SIGNATURE.PROCEDURE,
    name: "procedure.room",
  },
  {
    key: ENUM_ROOM_SIGNATURE.LAB_ROOM,
    name: "lab.room",
  },
  {
    key: ENUM_ROOM_SIGNATURE.INTERFACE_LAB_ROOM,
    name: "interfaceLab.room",
  },
  {
    key: ENUM_ROOM_SIGNATURE.PHYSICAL_THERAPY_ROOM,
    name: "physiotherapy.title",
  },
  {
    key: ENUM_ROOM_SIGNATURE.DISPENSARY,
    name: "pharmacyRoom",
  },
  {
    key: ENUM_ROOM_SIGNATURE.CASHIER,
    name: "pay.title",
  },
];

type Props = {
  visitsByID?: IGetVisits;
  ordersList?: IOrderHistory;
  refData: React.MutableRefObject<null>;
};

const keyName: keyNameMarker[] = [
  "doctorMarker0",
  "doctorMarker1",
  "doctorMarker2",
  "doctorMarker3",
];

const ReportPhysiotherapy: FC<Props> = ({
  visitsByID,
  ordersList,
  refData,
}) => {
  const { i18n } = useTranslation();

  const signatures = (value?: ISignatures[]) => {
    return (
      <React.Fragment>
        {value?.length ? (
          <img alt="signature" height={40} src={value?.[0]?.signature} />
        ) : (
          "................................................................."
        )}
      </React.Fragment>
    );
  };
  // const customMarker = (value: any) => {
  //   return (
  //     <React.Fragment>
  //       <Popover
  //         getPopupContainer={(e) => e}
  //         content={
  //           <div style={{ textAlign: "start" }}>
  //             <div>{value?.text}</div>
  //           </div>
  //         }
  //       >
  //         <div
  //           style={{
  //             backgroundColor: `${value?.color}`,
  //             border: "1px solid #ffffff",
  //           }}
  //           className="custom-marker-print"
  //           data-testid="marker"
  //         ></div>
  //       </Popover>
  //     </React.Fragment>
  //   );
  // };

  return (
    <div ref={refData}>
      <Row className="t-start document-border-header p-05">
        <Col span={6}>
          <div className="mt-1">
            <ImageLogo
              height="50px"
              width="120px"
              src={visitsByID?.branch?.image}
              alt="logo"
            />
          </div>
        </Col>
        <Col span={18}>
          <Row>
            <Col>
              <div>Name:</div>
            </Col>
            <Col span={13} className="t-underline">
              <div className=" f-normal " style={{ paddingLeft: 10 }}>
                {i18n.language === "th"
                  ? `${visitsByID?.patient?.titleTh || ""}${[
                      visitsByID?.patient?.firstNameTh,
                      visitsByID?.patient?.lastNameTh,
                    ]
                      ?.join(" ")
                      ?.trim()}`
                  : `${visitsByID?.patient?.titleEn || ""}${[
                      visitsByID?.patient?.firstNameEn,
                      visitsByID?.patient?.lastNameEn,
                    ]
                      ?.join(" ")
                      ?.trim()}`}
              </div>
            </Col>

            <Col>Date:</Col>

            <Col span={6} className="t-underline">
              <div className=" f-normal " style={{ paddingLeft: 10 }}>
                {visitsByID?.date
                  ? moment(visitsByID?.date).format("DD-MM-YYYY")
                  : ""}
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-05">HN:</Col>

            <Col span={7} className="t-underline mt-05">
              <div className=" f-normal " style={{ paddingLeft: 10 }}>
                {visitsByID?.patient?.hn}
              </div>
            </Col>

            <Col className="mt-05">Birth Date:</Col>

            <Col span={7} className="t-underline mt-05">
              <div className=" f-normal " style={{ paddingLeft: 10 }}>
                {visitsByID?.patient?.dateOfBirth
                  ? moment(visitsByID?.patient?.dateOfBirth).format(
                      "DD-MM-YYYY"
                    )
                  : "-"}
              </div>
            </Col>

            <Col className="mt-05">Age :</Col>

            <Col span={2} className="t-underline mt-05">
              <div className=" f-normal " style={{ paddingLeft: 10 }}>
                {Age(visitsByID?.patient?.dateOfBirth || "")}
              </div>
            </Col>

            <Col className="mt-05">y</Col>
          </Row>

          <Row>
            <Col className="mt-05">Physician:</Col>

            <Col
              style={{ minWidth: 130, paddingLeft: 10 }}
              className="t-underline mt-05"
            >
              <div className=" f-normal ">
                {i18n.language === "th"
                  ? ` ${[
                      visitsByID?.appointment?.[0]?.slot?.user?.firstNameTh,
                      visitsByID?.appointment?.[0]?.slot?.user?.lastNameTh,
                    ]
                      ?.join(" ")
                      ?.trim()}`
                  : ` ${[
                      visitsByID?.appointment?.[0]?.slot?.user?.firstNameEn,
                      visitsByID?.appointment?.[0]?.slot?.user?.lastNameEn,
                    ]
                      ?.join(" ")
                      ?.trim()}`}
              </div>
            </Col>

            <Col className="mt-05">Allergies : </Col>

            <Col style={{ minWidth: 130 }} className="t-underline mt-05 t-red">
              <div className=" f-normal " style={{ paddingLeft: 10 }}>
                {` ${[
                  visitsByID?.patient?.drugAllergy,
                  visitsByID?.patient?.foodAllergy,
                ]
                  ?.join(" / ")
                  ?.trim()}`}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="t-center fs-20 f-bold mt-05 mb-05">
        OPD Clinical Record
      </div>

      <table
        style={{ width: "100%" }}
        className="print-marker fs-14-marker t-center"
      >
        <thead>
          <tr style={{ height: "30px" }}>
            <td
              style={{ width: "12.5%" }}
              className="print-marker-right f-bold"
            >
              BP Systolic
            </td>
            <td
              style={{ width: "12.5%" }}
              className="print-marker-right f-bold"
            >
              BP Diastolic
            </td>
            <td
              style={{ width: "12.5%" }}
              className="print-marker-right f-bold"
            >
              PR/min
            </td>
            <td
              style={{ width: "12.5%" }}
              className="print-marker-right f-bold"
            >
              RR/min
            </td>
            <td
              style={{ width: "12.5%" }}
              className="print-marker-right f-bold"
            >
              BT (c)
            </td>
            <td
              style={{ width: "12.5%" }}
              className="print-marker-right f-bold"
            >
              O<sub>2</sub>sat (%)
            </td>
            <td
              style={{ width: "12.5%" }}
              className="print-marker-right f-bold"
            >
              BW (kg)
            </td>
            <td
              style={{ width: "12.5%" }}
              className="print-marker-right f-bold"
            >
              HT (cm)
            </td>
          </tr>
          <tr style={{ height: "30px" }}>
            <td className="t-center print-marker-right text-table-top-management print-marker-top t-start">
              {visitsByID?.data?.doctorRoom?.systolic ||
                visitsByID?.data?.visit?.systolic ||
                ""}
            </td>
            <td className="t-center print-marker-right print-marker-top text-table-top-management  t-start">
              {visitsByID?.data?.doctorRoom?.diastolic ||
                visitsByID?.data?.visit?.diastolic ||
                ""}
            </td>
            <td className="t-center print-marker-right print-marker-top text-table-top-management  t-start">
              {visitsByID?.data?.doctorRoom?.rRmin ||
                visitsByID?.data?.visit?.rRmin ||
                ""}
            </td>
            <td className="t-center print-marker-right print-marker-top text-table-top-management  t-start">
              {visitsByID?.data?.doctorRoom?.rRmin ||
                visitsByID?.data?.visit?.rRmin ||
                ""}
            </td>
            <td className="t-center print-marker-right print-marker-top text-table-top-management  t-start">
              {visitsByID?.data?.doctorRoom?.bt ||
                visitsByID?.data?.visit?.bt ||
                ""}
            </td>
            <td className="t-center print-marker-right print-marker-top text-table-top-management  t-start">
              {visitsByID?.data?.doctorRoom?.o2 ||
                visitsByID?.data?.visit?.o2 ||
                ""}
            </td>
            <td className="t-center print-marker-right print-marker-top text-table-top-management  t-start">
              {visitsByID?.data?.doctorRoom?.bw ||
                visitsByID?.data?.visit?.bw ||
                ""}
            </td>
            <td className="t-center print-marker-right print-marker-top text-table-top-management  t-start">
              {visitsByID?.data?.doctorRoom?.height ||
                visitsByID?.data?.visit?.height ||
                ""}
            </td>
          </tr>
          <tr className="print-marker-top">
            <td style={{ width: "100%", textAlign: "left" }} colSpan={7}>
              <div className="f-bold" style={{ textAlign: "center" }}>
                Patient history and Physical exam :
              </div>
              <div
                style={{ textAlign: "left" }}
                dangerouslySetInnerHTML={{
                  __html: TextBRHTML(visitsByID?.suggestion?.print || ""),
                }}
              ></div>
              <div>
                {visitsByID?.suggestion?.image ? (
                  <img
                    width={600}
                    src={visitsByID?.suggestion?.image}
                    alt="ViewDrawingCanvas"
                  />
                ) : null}
              </div>
            </td>
          </tr>
        </thead>
      </table>

      <Row className=" mt-1 break-inside ">
        {keyName?.map((key, index) => {
          const image = visitsByID?.visitMarker?.[key];
          return (
            <React.Fragment key={index}>
              {image ? (
                <Col span={12} className="  col-center ">
                  <div className=" center-extents">
                    <Image src={image?.[0]?.image} preview={false} />
                  </div>
                </Col>
              ) : null}
            </React.Fragment>
          );
        })}
      </Row>
      <div className="break-inside" style={{ minHeight: "600px" }}>
        <table className="report-drug print-marker   mt-3 " width="100%">
          <thead>
            <tr className="t-center print-marker-bottom">
              <td
                className="print-marker-right f-bold"
                style={{ width: "39%" }}
              >
                ORDER
              </td>
              <td
                className="print-marker-right f-bold"
                style={{ width: "15%" }}
              >
                TYPE
              </td>
              <td
                className="print-marker-right f-bold"
                style={{ width: "10%" }}
              >
                REJECT
              </td>
              <td
                className="print-marker-right f-bold"
                style={{ width: "12%" }}
              >
                Frequency
              </td>
              <td
                className="f-bold print-marker-right  "
                style={{ width: "8%" }}
              >
                Qty
              </td>
              <td
                className="f-bold  print-marker-right"
                style={{ width: "8%" }}
              >
                Remark
              </td>
              <td className="f-bold " style={{ width: "8%" }}>
                Edit by
              </td>
            </tr>

            {ordersList?.products?.length ? (
              <React.Fragment>
                {ordersList?.products?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className="print-marker-bottom  "
                      style={item?.reject ? { color: "red" } : {}}
                    >
                      <td className="print-marker-right print-marker-left">
                        {productName(i18n.language, item?.nameTh, item?.nameEn)}
                      </td>
                      <td className="print-marker-right t-center">
                        {item?.type}
                      </td>
                      <td className="print-marker-right t-center">
                        {item?.reject === undefined
                          ? "No"
                          : !!item?.reject
                          ? "Yes"
                          : "No"}
                      </td>
                      <td className="print-marker-right t-center">
                        {item?.option?.frequency || ""}
                      </td>
                      <td className="print-marker-right t-center">
                        {item?.qty}
                      </td>
                      <td className="print-marker-right t-center">
                        {item?.option?.remark || ""}
                      </td>
                      <td>
                        {i18n.language === "th"
                          ? [
                              item?.editBy?.firstNameTh,
                              item?.editBy?.lastNameTh,
                            ]
                              ?.join(" ")
                              ?.trim()
                          : [
                              item?.editBy?.firstNameEn,
                              item?.editBy?.lastNameEn,
                            ]
                              ?.join(" ")
                              ?.trim()}
                      </td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ) : (
              <React.Fragment>
                <tr
                  style={{ height: "600px" }}
                  className="print-marker-bottom  "
                >
                  <td className="print-marker-right print-marker-left t-center"></td>
                  <td className="print-marker-right  t-center"></td>
                  <td className="print-marker-right t-center"> </td>
                  <td className="print-marker-right t-center"></td>
                  <td className="print-marker-right t-center"></td>
                  <td className="print-marker-right t-center"></td>
                </tr>
              </React.Fragment>
            )}

            <tr className="print-marker-bottom print-marker-right print-marker-left ">
              <td colSpan={4} className="t-start">
                <span className="f-bold">Diagnosis/Impression :</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: TextBRHTML(` ${visitsByID?.diagnosis || ""} `),
                  }}
                />
              </td>
            </tr>
            <tr className="print-marker-right print-marker-left ">
              <td colSpan={4} className="t-start">
                <span className="f-bold">Lab : </span>
                {ordersList?.productsLab?.map((item, index) => {
                  const data = ordersList?.productsLab?.[index + 1];
                  return (
                    <span key={index}>
                      {i18n.language === "th"
                        ? `${item?.nameTh} ${!!data ? "," : ""}  ` || " "
                        : `${item?.nameEn}  ${!!data ? "," : ""}  ` || " "}
                    </span>
                  );
                })}
              </td>
            </tr>
            <tr className=" print-marker-right print-marker-left ">
              <td colSpan={4} className="t-start">
                <span className="f-bold">Others :</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: TextBRHTML(` ${visitsByID?.other || ""}`),
                  }}
                />
              </td>
            </tr>
            <tr className="print-marker-bottom print-marker-right print-marker-left ">
              <td colSpan={4} className="t-start">
                <span className="f-bold">{t("remarks")} :</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: TextBRHTML(` ${visitsByID?.labNote || ""}`),
                  }}
                />
              </td>
            </tr>

            <tr className="print-marker-bottom print-marker-right print-marker-left ">
              <td colSpan={4} className="t-start">
                <span className="f-bold">
                  <Checkbox /> Patient and /or family agreed with care plan
                </span>
              </td>
            </tr>

            <tr className="print-marker-bottom print-marker-right print-marker-left ">
              <td colSpan={4} className="t-start">
                Physician's signature
                {signatures(
                  ordersList?.signatures?.filter((signatures) => {
                    return (
                      signatures?.room === ENUM_ROOM_SIGNATURE.DOCTOR_ROOM &&
                      signatures?.signatureBy?.role?.nameEn ===
                        ENUM_ROLE_EN.DOCTOR
                    );
                  })
                )}
                MDcode{" "}
                <span>
                  {visitsByID?.appointment?.[0]?.slot?.user?.attachment ||
                    "............................"}
                </span>
                {roomSignatures?.map((signatures, i) => {
                  return ordersList?.signatures
                    ?.filter((signaturesBy) => {
                      return signaturesBy?.room === signatures?.key;
                    })
                    ?.map((data, index) => {
                      if (
                        data?.signatureBy?.role?.nameEn ===
                          ENUM_ROLE_EN.DOCTOR &&
                        data?.room === ENUM_ROOM_SIGNATURE.DOCTOR_ROOM
                      )
                        return null;
                      return (
                        <React.Fragment key={`${i}${index}`}>
                          <div style={{ color: "red" }}>
                            {data?.signature ? (
                              <img
                                alt="signature"
                                height={40}
                                src={data?.signature}
                              />
                            ) : null}
                            {data?.doctorName && (
                              <>
                                <span>รคส. </span>
                                {i18n.language === "th"
                                  ? ` ${
                                      data?.doctorName ||
                                      [
                                        visitsByID?.appointment?.[0]?.slot?.user
                                          ?.firstNameTh,
                                        visitsByID?.appointment?.[0]?.slot?.user
                                          ?.lastNameTh,
                                      ]
                                        ?.join(" ")
                                        ?.trim()
                                    }`
                                  : ` ${
                                      data?.doctorName ||
                                      [
                                        visitsByID?.appointment?.[0]?.slot?.user
                                          ?.firstNameEn,
                                        visitsByID?.appointment?.[0]?.slot?.user
                                          ?.lastNameEn,
                                      ]
                                        ?.join(" ")
                                        ?.trim()
                                    }`}
                                <span> by </span>
                              </>
                            )}
                            <span>
                              {i18n.language === "th"
                                ? ` ${[
                                    data?.signatureBy?.firstNameTh,
                                    data?.signatureBy?.lastNameTh,
                                  ]
                                    ?.join(" ")
                                    ?.trim()}`
                                : ` ${[
                                    data?.signatureBy?.firstNameEn,
                                    data?.signatureBy?.lastNameEn,
                                  ]
                                    ?.join(" ")
                                    ?.trim()}`}
                            </span>
                            <span>{`   ( ${t(`${signatures?.name}`)} )`}</span>
                          </div>
                        </React.Fragment>
                      );
                    });
                })}
              </td>
            </tr>
          </thead>
        </table>
      </div>
    </div>
  );
};

export default ReportPhysiotherapy;
