import { t } from "i18next";
import { Age } from "@tools/age";
import { IGetVisits } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { Col, Row, Button } from "antd";
import { ISignature, IVisit } from "@interface/IGlobal.interface";
import { FC, MutableRefObject } from "react";
import React from "react";
import moment from "moment";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";

type Props = {
  refData: MutableRefObject<null>;
  visitsByID: IGetVisits | undefined;
  visible: IVisit;
  setVisible: (e: IVisit) => void;
  signature: ISignature;
};

const Print: FC<Props> = ({
  refData,
  visitsByID,
  visible,
  setVisible,
  signature,
}) => {
  const { i18n } = useTranslation();
  const userList = React.useContext(UserContext);
  const product = visitsByID?.visitPatientSignature?.map((item) => {
    return item?.product;
  });
  return (
    <div ref={refData}>
      <div className=" document ">
        <Row className="t-start document-border-header p-05">
          <Col span={6}>
            <div className="mt-1">
              <ImageLogo
                height="50px"
                width="120px"
                src={userList?.branch?.image}
                alt="logo"
              />
            </div>
          </Col>
          <Col span={18}>
            <Row>
              <Col>
                <div>Name:</div>
              </Col>
              <Col span={13} className="t-underline">
                <div className=" f-normal" style={{ paddingLeft: 10 }}>
                  {i18n.language === "th"
                    ? `${visitsByID?.patient?.titleTh || ""}${[
                        visitsByID?.patient?.firstNameTh,
                        visitsByID?.patient?.lastNameTh,
                      ]
                        ?.join(" ")
                        ?.trim()}`
                    : `${visitsByID?.patient?.titleEn || ""}${[
                        visitsByID?.patient?.firstNameEn,
                        visitsByID?.patient?.lastNameEn,
                      ]
                        ?.join(" ")
                        ?.trim()}`}
                </div>
              </Col>

              <Col>Date:</Col>

              <Col span={6} className="t-underline">
                <div className=" f-normal" style={{ paddingLeft: 10 }}>
                  {" "}
                  {`${visitsByID?.date || ""}`}{" "}
                </div>
              </Col>
            </Row>

            <Row>
              <Col className="mt-05">HN:</Col>

              <Col span={7} className="t-underline mt-05">
                <div className=" f-normal" style={{ paddingLeft: 10 }}>
                  {" "}
                  {`${visitsByID?.patient?.hn || ""}`}{" "}
                </div>
              </Col>

              <Col className="mt-05">Birth Date:</Col>

              <Col span={7} className="t-underline mt-05">
                <div className=" f-normal" style={{ paddingLeft: 10 }}>
                  {" "}
                  {`${visitsByID?.patient?.dateOfBirth || ""}`}{" "}
                </div>
              </Col>

              <Col className="mt-05">Age :</Col>

              <Col span={2} className="t-underline mt-05">
                <div className=" f-normal" style={{ paddingLeft: 10 }}>
                  {Age(visitsByID?.patient?.dateOfBirth || "")}
                </div>
              </Col>

              <Col className="mt-05">y</Col>
            </Row>

            <Row>
              <Col className="mt-05">Physician:</Col>

              <Col className="t-underline mt-05">
                <div
                  className=" f-normal"
                  style={{ paddingLeft: 10, minWidth: 100 }}
                >
                  {i18n.language === "th"
                    ? [
                        visitsByID?.appointment?.[0]?.slot?.user?.firstNameTh,
                        visitsByID?.appointment?.[0]?.slot?.user?.lastNameTh,
                      ]
                        ?.join(" ")
                        ?.trim()
                    : [
                        visitsByID?.appointment?.[0]?.slot?.user?.firstNameEn,
                        visitsByID?.appointment?.[0]?.slot?.user?.lastNameEn,
                      ]
                        ?.join(" ")
                        ?.trim()}
                </div>
              </Col>

              <Col className="mt-05">Allergies :</Col>

              <Col className="t-underline mt-05 t-red">
                <div
                  className=" f-normal"
                  style={{ paddingLeft: 10, minWidth: 100 }}
                >
                  {[
                    visitsByID?.patient?.drugAllergy,
                    visitsByID?.patient?.foodAllergy,
                  ]
                    ?.join(" / ")
                    ?.trim()}
                </div>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className="mt-2 mb-2 f-normal fs-16">{t("consentForm.title")}</div>

        <div className="t-justify t-index mt-1 break-inside">
          {t("consentForm.first")}
        </div>
        <div className=" t-justify t-index mt-05 break-inside">
          {t("consentForm.second")}
        </div>

        <div className="mt-2 t-start">
          {product?.map((item, index) => {
            return (
              <span key={index}>
                <span className="pr-05">{index + 1}.</span>
                <span className="pr-2 t-start">
                  {i18n.language === "th"
                    ? item?.nameTh || ""
                    : item?.nameEn || ""}
                </span>
              </span>
            );
          })}
        </div>

        <div className=" t-justify t-index mt-2 break-inside">
          {t("consentForm.third")}
        </div>

        <div className=" t-justify t-index mt-05 break-inside">
          {t("consentForm.fourth")}
        </div>

        <div className=" t-justify t-index mt-05 break-inside">
          {t("consentForm.fifth")}
        </div>
      </div>

      <div className="page break-inside">
        <div className=" document-footer">
          <div className="  mt-2 ">
            <Button
              className="pb-1 signature-button"
              onClick={(e) =>
                setVisible({
                  ...visible,
                  first: !visible?.first,
                })
              }
            >
              {t("signHere")}
            </Button>
          </div>
          <Row>
            <Col>
              <span> {t("consentForm.sign")} </span>
            </Col>
            <Col span={9} className="signature-patient t-underline-dotted">
              <div className=" f-normal t-center  image-signature">
                {signature?.firstSignature && (
                  <img
                    src={signature?.firstSignature || ""}
                    alt="first"
                    height={70}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row justify="space-around" className="mt-1">
            <Col span={7}></Col>
            <Col span={10}>
              <Row>
                (
                <Col className="t-underline-dotted f-normal t-center signature-end">
                  {signature?.firstName || i18n.language === "th"
                    ? [
                        visitsByID?.patient?.firstNameTh,
                        visitsByID?.patient?.lastNameTh,
                      ]
                        ?.join(" ")
                        ?.trim()
                    : [
                        visitsByID?.patient?.firstNameEn,
                        visitsByID?.patient?.lastNameEn,
                      ]
                        ?.join(" ")
                        ?.trim()}
                </Col>
                )
              </Row>
            </Col>
            <Col span={7}>
              <Row>
                <Col> {t("date")}</Col>
                <Col span={20} className="t-underline-dotted">
                  <div className="f-normal t-center">
                    {signature?.firstDate
                      ? moment(signature?.firstDate).format("DD/MM/YYYY")
                      : ""}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="  mt-2">
            <Button
              className="pb-1 signature-button"
              onClick={(e) =>
                setVisible({
                  ...visible,
                  second: !visible?.second,
                })
              }
            >
              {t("signHere")}
            </Button>
          </div>

          <Row>
            <Col>
              <span>{t("signTheContributor")} </span>
            </Col>

            <Col span={9} className="signature-user t-underline-dotted">
              <div className=" f-normal t-center  image-signature">
                {signature?.secondSignature && (
                  <img
                    src={signature?.secondSignature || ""}
                    alt="second"
                    height={70}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row justify="space-around" className="  mt-1">
            <Col span={7}></Col>
            <Col span={10}>
              <Row>
                (
                <Col className="t-underline-dotted f-normal t-center signature-end">
                  {signature?.secondName || ""}
                </Col>
                )
              </Row>
            </Col>
            <Col span={7}>
              <Row>
                <Col> {t("date")}</Col>
                <Col span={20} className="t-underline-dotted">
                  <div className="f-normal t-center">
                    {signature?.secondDate
                      ? moment(signature?.secondDate).format("DD/MM/YYYY")
                      : ""}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          <div className="  mt-2">
            <Button
              className="pb-1 signature-button"
              onClick={(e) =>
                setVisible({
                  ...visible,
                  third: !visible?.third,
                })
              }
            >
              {t("signHere")}
            </Button>
          </div>

          <Row>
            <Col>
              <span>{t("signTheWitness")}</span>
            </Col>

            <Col span={9} className="signature-witness t-underline-dotted">
              <div className=" f-normal t-center  image-signature">
                {signature?.thirdSignature && (
                  <img
                    src={signature?.thirdSignature || ""}
                    alt="third"
                    height={70}
                  />
                )}
              </div>
            </Col>
          </Row>
          <Row justify="space-around" className="  mt-1">
            <Col span={7}></Col>
            <Col span={10}>
              <Row>
                (
                <Col className="t-underline-dotted f-normal t-center signature-end">
                  {signature?.thirdName || ""}
                </Col>
                )
              </Row>
            </Col>
            <Col span={7}>
              <Row>
                <Col> {t("date")}</Col>
                <Col span={20} className="t-underline-dotted">
                  <div className="f-normal t-center">
                    {signature?.thirdDate
                      ? moment(signature?.thirdDate).format("DD/MM/YYYY")
                      : ""}
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Print;
