import { useTranslation } from "react-i18next";
import { useGetRoleList } from "@services/api/role";
import { useRecoilState } from "recoil";
import { useGetBranchList } from "@services/api/branch";
import { loadingBranchState } from "@tools/recoil/recoil-atom";
import { Button, Col, Form, Input, Row } from "antd";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  onFinish: (value: any) => void;
};

const Search = ({ onFinish }: Props) => {
  const { t, i18n } = useTranslation();
  const roleList = useGetRoleList(true, { limit: 0 });
  const [loadingBranch, setLoadingBranch] = useRecoilState(loadingBranchState);
  const branchList = useGetBranchList(loadingBranch, { limit: 0 });

  const clickBranch = () => {
    if (!!loadingBranch) return;
    setLoadingBranch(true);
  };

  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[8, 14]}>
          <Col lg={4}>
            <Form.Item name="name">
              <Input placeholder={`${t("nameSearch")}`} />
            </Form.Item>
          </Col>

          <Col xl={5} xxl={5}>
            <Form.Item name="roleId">
              <SelectorDouble
                loading={branchList?.isLoading}
                Source={
                  Array.from(
                    new Map(
                      roleList?.data?.result?.[0]?.data?.map((item) => [
                        item?.nameTh,
                        item,
                      ])
                    )?.values()
                  ) || []
                }
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("role")}
                placeholder={t("all")}
              />
            </Form.Item>
          </Col>

          <Col md={4} lg={5} xl={5}>
            <Form.Item
              name="phone"
              // rules={[
              //   {
              //     pattern: /^[+]{0}[0-9]{9,10}$/,
              //     message: t("pleaseNumber"),
              //   },
              // ]}
            >
              <Input type="number" placeholder={t("phoneNumber")} />
            </Form.Item>
          </Col>

          <Col sm={8} lg={9} xl={6}>
            <Form.Item name="branchId">
              <SelectorDouble
                Source={branchList?.data?.result?.[0]?.data || []}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                title={t("branch")}
                placeholder={t("all")}
                onClick={clickBranch}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className=" btn-search" htmlType="submit">
              {t("search")}
            </Button>
          </Col>
          <Col>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
