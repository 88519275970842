export const RadiologyIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 100 100"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path
            fill="#fff"
            d="M0 0H91.615V84.29H0z"
            data-name="Rectangle 22688"
          ></path>
        </clipPath>
        <clipPath id="clip-Radiology_report">
          <path d="M0 0H100V100H0z"></path>
        </clipPath>
      </defs>
      <g clipPath="url(#clip-Radiology_report)" data-name="Radiology report">
        <g data-name="Group 27073" transform="translate(4 8)">
          <g clipPath="url(#clip-path)" data-name="Group 27072">
            <path
              fill="#fff"
              fillRule="evenodd"
              d="M71.046 44.643l-2.529-1.78-3.964-14.05-3.621 14.05-2.5 1.78h-9.393l-5.745 21.633h-5.086L28.125 28.813l-3.59 14.051-2.9 1.78H3.966C15.138 68.774 45.794 84.29 45.794 84.29s30.654-15.516 41.829-39.647zm-51.195-5.027l5.745-21.6h5.056l10.115 37.459 3.59-14.048 2.529-1.81h9.362l5.776-21.6h5.4l5.774 21.6h16.609a46.185 46.185 0 001.808-11.893C91.615 12.238 79.691 0 65.243 0 57.687 0 50.85 3.619 45.794 8.993A25.749 25.749 0 0026.315 0C11.924 0 0 12.238 0 27.723a44.9 44.9 0 001.81 11.893z"
              data-name="Path 22249"
              transform="translate(0 .001)"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};
