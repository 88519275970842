import React, { useEffect, useRef, useState } from "react";
import { Form } from "antd";
import { dayjs } from "@tools/timezone";
import { useGetLabInfo } from "@services/api/work_order_item";
import { useGetProductLab } from "@services/api/product";
import { RouteComponentProps } from "react-router-dom";
import { IResultLabInterface } from "@interface/IProduct.interface";
import { useHistory, useRouteMatch } from "react-router-dom";
import { keyNameProductLabInterface } from "@interface/IProduct.interface";
import Fields from "./Fields";
import Header from "./Header";
import moment from "moment";
import DataLabInterfaceOne from "@components/lab_interface/lab_interface_group_one/DataLabInterfaceOne";
import DataLabInterfaceTwo from "@components/lab_interface/lab_interface_group_two/DataLabInterfaceTwo";
import MainPatientByIdProvider from "@provider/patient_by_id";
import MainLabInterfaceGroupProvider, {
  keyLabInterface,
} from "@provider/lab_inteface_group";

const labInterfaceOne = DataLabInterfaceOne();
const labInterfaceTwo = DataLabInterfaceTwo();
const data = { ...labInterfaceOne, ...labInterfaceTwo };

const FormInchart = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const [labInfo] = Form.useForm();
  const [patientList] = Form.useForm();
  const labInfoList = useGetLabInfo(visitID);
  const labInterfaceGroupOne = useRef([]);
  const labInterfaceGroupTwo = useRef([]);
  const productLabList = useGetProductLab();
  const [labInterface, setLabInterface] = useState<{
    [K in keyNameProductLabInterface]: IResultLabInterface[];
  }>({
    testCode: [],
    hematology: [],
    microbiology: [],
    coagulation: [],
    urine: [],
    biochemistry1: [],
    biochemistry2: [],
    groupData: [],
    groupTwo: [],
  });

  useEffect(() => {
    var setLabInterfaceList: {
      [K in keyNameProductLabInterface]?: IResultLabInterface[];
    } = {};

    keyLabInterface.forEach((key: keyNameProductLabInterface) => {
      setLabInterfaceList = {
        ...setLabInterfaceList,
        [key]: data?.[key]?.map((item) => {
          const found = productLabList?.data?.result?.[0]?.[key]?.find(
            (element) => element?.sku === item?.sku
          );
          if (found) {
            return { ...item, ...found, disabled: true };
          } else {
            return { ...item, disabled: false };
          }
        }),
      };
    });

    setLabInterface({
      ...labInterface,
      ...setLabInterfaceList,
    });

    //eslint-disable-next-line
  }, [productLabList?.data]);

  useEffect(() => {
    patientList?.setFieldsValue({
      foodAllergy:
        labInfoList?.data?.result?.[0]?.patient?.foodAllergy || undefined,
      drugAllergy:
        labInfoList?.data?.result?.[0]?.patient?.drugAllergy || undefined,
    });

    labInfo.setFieldsValue({
      systemName: "Echo",
      site: "NHS",
      locationCode: "251",
      payer: "2800001377",
      prefixname:
        labInfoList?.data?.result?.[0]?.patient?.titleEn ||
        labInfoList?.data?.result?.[0]?.patient?.titleTh ||
        "",
      fileDatetime: moment().format("YYYYMMDDHHmmss"),
      hn: labInfoList?.data?.result?.[0]?.patient?.hn,
      lastname:
        labInfoList?.data?.result?.[0]?.patient?.lastNameEn ||
        labInfoList?.data?.result?.[0]?.patient?.lastNameTh,
      firstname:
        labInfoList?.data?.result?.[0]?.patient?.firstNameEn ||
        labInfoList?.data?.result?.[0]?.patient?.firstNameTh,
      bod: labInfoList?.data?.result?.[0]?.patient?.dateOfBirth
        ? dayjs(labInfoList?.data?.result?.[0]?.patient?.dateOfBirth).format(
            "YYYYMMDDHHmmss"
          )
        : undefined,
      sex:
        labInfoList?.data?.result?.[0]?.patient?.gender === "MALE"
          ? "M"
          : labInfoList?.data?.result?.[0]?.patient?.gender === "FEMALE"
          ? "F"
          : undefined,
    });
    // eslint-disable-next-line
  }, [labInfoList?.data]);

  return (
    <MainLabInterfaceGroupProvider
      value={{
        labInterfaceGroupOne: labInterfaceGroupOne,
        labInterfaceGroupTwo: labInterfaceGroupTwo,
        labInterface: labInterface,
        setLabInterface: setLabInterface,
        labInfoList: labInfoList?.data?.result?.[0]?.labInterface,
        visitId: visitID,
        patient: labInfoList?.data?.result?.[0]?.patient,
        status: "interface_lab",
      }}
    >
      <MainPatientByIdProvider
        value={{ patientById: labInfoList?.data?.result?.[0]?.patient }}
      >
        <React.Fragment>
          <Header
            history={history}
            labInfo={labInfo}
            isSignature={
              labInfoList?.data?.result?.[0]?.visit?.isSignature?.labInterface
            }
          />
          <div className="set-layout ">
            <Fields
              labInfoList={labInfoList?.data}
              labInfo={labInfo}
              patientList={patientList}
            />
          </div>
        </React.Fragment>
      </MainPatientByIdProvider>
    </MainLabInterfaceGroupProvider>
  );
};

export default FormInchart;
