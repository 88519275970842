import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
  handlePrint: () => void;
};

const Header = ({ history, handlePrint }: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("preRegister.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("marketing.title")} / ${t("event.title")} /  ${t(
                  "preRegister.edit"
                )} / `}
              </span>
              <span className="t-dark-blue f-normal"> {t("report")}</span>
            </Col>
          </Row>
        </Col>

        <Col lg={10} className="d-flex justify-between justify-col">
          <Button className="reserve " key="back" onClick={handlePrint}>
            {t("print")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
