import { dayjs } from "@tools/timezone";
import { render } from "@testing-library/react";
import { Toolbar } from "@devexpress/dx-react-scheduler-material-ui";
import { WeekView } from "@devexpress/dx-react-scheduler-material-ui";
import { Resources } from "@devexpress/dx-react-scheduler-material-ui";
import { Scheduler } from "@devexpress/dx-react-scheduler-material-ui";
import { AllDayPanel } from "@devexpress/dx-react-scheduler-material-ui";
import { slotsDetail } from "@assets/data/pathRouter";
import { TodayButton } from "@devexpress/dx-react-scheduler-material-ui";
import { Appointments } from "@devexpress/dx-react-scheduler-material-ui";
import { ViewSwitcher } from "@devexpress/dx-react-scheduler-material-ui";
import { DateNavigator } from "@devexpress/dx-react-scheduler-material-ui";
import { IAppointments } from "@interface/IAppointments.interface";
import { useGetRoomList } from "@services/api/rooms";
import { useTranslation } from "react-i18next";
import { AppointmentForm } from "@devexpress/dx-react-scheduler-material-ui";
import { AppointmentModel } from "@interface/IAppointments.interface";
import { AppointmentTooltip } from "@devexpress/dx-react-scheduler-material-ui";
import { useGetUserRoomList } from "@services/api/user_room";
import { EditRecurrenceMenu } from "@devexpress/dx-react-scheduler-material-ui";
import { MonthView, DayView } from "@devexpress/dx-react-scheduler-material-ui";
import { CurrentTimeIndicator } from "@devexpress/dx-react-scheduler-material-ui";
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler";
import { QueryClient, QueryClientProvider } from "react-query";
import { useHistory, useLocation, RouteComponentProps } from "react-router-dom";
import { Button, Divider, FormInstance, Select, Tooltip } from "antd";
import Paper from "@material-ui/core/Paper";
import Group from "@assets/img/icon/group.svg";
import FormSlotsOther from "../form_other/Form";
import SearchFunctionAll from "./SearchFunctionAll";
import GetAppointmentTooltip from "../queue/useGetAppointmentTooltip";
import FormSlotsDoctorBooking from "../form_doctor_booking/Form";
import FormSlotsDoctorBookingEdit from "../form_doctor/Form";

type Props = {
  viewSwitcher: string;
  setViewSwitcher: (e: string) => void;
  CommitChanges: ({ added, changed, deleted }: any) => void;
  dataScheduler: AppointmentModel[];
  currentDateChange: string;
  changeCurrentDateChange: (currentDate: any) => void;
  patientID?: number;
  searchOnFinish: (values: any) => void;
  formSearch: FormInstance;
  slotType: string;
  status: string;
  medicalRecord: boolean;
};
const queryClient = new QueryClient({
  defaultOptions: { queries: { refetchOnWindowFocus: false, retry: 0 } },
});

const ViewPreRegister = ({
  viewSwitcher,
  setViewSwitcher,
  CommitChanges,
  dataScheduler,
  currentDateChange,
  changeCurrentDateChange,
  patientID,
  searchOnFinish,
  formSearch,
  slotType,
  status,
  medicalRecord,
}: Props) => {
  const { t, i18n } = useTranslation();
  const { Option } = Select;
  const history = useHistory<RouteComponentProps["history"]>();
  const appointmentData: any = {};
  const doctorList = useGetUserRoomList({ limit: 0 });
  const location = useLocation();
  const locationPath = location.pathname;
  const roomList = useGetRoomList({ limit: 0 });


  const resources = [
    {
      fieldName: "roomId",
      title: t("room"),
      instances: roomList?.data?.result?.[0]?.data || [],
    },
  ];

  const allDayLocalizationMessages: any = {
    "th-TH": {
      allDay: t("all"),
    },
  };

  const getAllDayMessages = (locale: any) => allDayLocalizationMessages[locale];

  const AppointmentContent = ({
    classes,
    data,
    formatDate,
    ...restProps
  }: any) => {
    return (
      <Appointments.AppointmentContent
        {...restProps}
        formatDate={formatDate}
        data={data}
      >
        <div className="f-light">
          {status === "medical-room-reservation" ? (
            <div>{i18n.language === "th" ? data?.userTh : data?.userEn}</div>
          ) : (
            <div>
              {viewSwitcher === "Month" ? (
                <span>{`${dayjs(`${data?.startDate}`).format(
                  "HH:mm A"
                )} `}</span>
              ) : null}
              <span>
                {data?.title
                  ? `${data?.title}`
                  : i18n.language === "th"
                  ? data?.patientTh
                  : data?.patientEn}
              </span>
            </div>
          )}

          <div>{data?.room?.name}</div>
          <div>{data?.info}</div>
        </div>
      </Appointments.AppointmentContent>
    );
  };

  const onFinish = (id: number | string) => {
    patientID
      ? history.push(slotsDetail + `/slot/${id}/patient/${patientID}`)
      : history.push(slotsDetail + `/slot/${id}`);
  };

  const header = ({
    children,
    appointmentData,
    classes,
    ...restProps
  }: any) => {
    const dateNew: Date = new Date();
    if (locationPath.includes("/booking/book-another-room") === true) {
      if (dateNew.getTime() < new Date(appointmentData?.endDate).getTime()) {
        return (
          <AppointmentTooltip.Header
            {...restProps}
            showOpenButton
            showDeleteButton
          ></AppointmentTooltip.Header>
        );
      } else {
        return (
          <AppointmentTooltip.Header {...restProps}></AppointmentTooltip.Header>
        );
      }
    } else {
      if (
        dateNew.getTime() < new Date(appointmentData?.endDate).getTime() &&
        medicalRecord
      ) {
        return (
          <AppointmentTooltip.Header
            {...restProps}
            showOpenButton
            showDeleteButton
          >
            <Tooltip title="">
              <Button
                className="icon-detail"
                type="primary"
                shape="circle"
                icon={
                  <img
                    className="slots-group"
                    width="20"
                    src={Group}
                    alt="group"
                  />
                }
                onClick={(e: any) => onFinish(appointmentData?.id)}
              />
            </Tooltip>
          </AppointmentTooltip.Header>
        );
      } else {
        return (
          <AppointmentTooltip.Header {...restProps}>
            <Tooltip title="">
              <Button
                className="icon-detail"
                type="primary"
                shape="circle"
                icon={
                  <img
                    className="slots-group"
                    width="20"
                    src={Group}
                    alt="group"
                  />
                }
                onClick={(e: any) => onFinish(appointmentData?.id)}
              />
            </Tooltip>
          </AppointmentTooltip.Header>
        );
      }
    }
  };

  const getAppointments = () => {
    const dataList: any = GetAppointmentTooltip({
      date: currentDateChange,
      viewSwitcher: viewSwitcher,
      slotType: slotType,
    });

    return dataList || [];
  };

  const layerBody = ({ ...restProps }: any) => {
    if (restProps?.appointmentData?.slotType !== "OTHER") {
      const result = getAppointments()?.filter(
        (word: IAppointments) => word?.slotId === restProps?.appointmentData?.id
      );

      return (
        <AppointmentTooltip.Content {...restProps}>
          <div className="appointmentTooltip">
            <span className="f-bold fs-16">{t("doctorName")} : </span>
            <span className="f-light fs-16">
              {i18n.language === "th"
                ? [
                    restProps?.appointmentData?.user?.firstNameTh,
                    restProps?.appointmentData?.user?.lastNameTh,
                  ]
                    ?.join(" ")
                    ?.trim()
                : [
                    restProps?.appointmentData?.user?.firstNameEn,
                    restProps?.appointmentData?.user?.lastNameEn,
                  ]
                    ?.join(" ")
                    ?.trim()}
            </span>
          </div>
          <div className="appointmentTooltip">
            <span className="f-bold fs-16">{t("time")} : </span>

            <span className="f-light fs-16">
              {`${dayjs(restProps?.appointmentData?.startDate).format(
                "HH:mm"
              )} - ${dayjs(restProps?.appointmentData?.endDate)?.format(
                "HH:mm"
              )}`}
            </span>
          </div>
          <Divider className="mr-0 ml-0 mt-1 mb-1" />

          {result?.length > 0 ? (
            result?.map((item: IAppointments, index: number) => {
              return (
                <div key={index} className="appointmentTooltip">
                  <span className="f-bold fs-16">{t("name")} :</span>
                  <span className="f-light fs-16">{` ${
                    i18n.language === "th"
                      ? item?.patient?.firstNameTh || ""
                      : item?.patient?.firstNameEn || ""
                  } `}</span>
                  <span className="f-bold fs-16">{t("lastName")} :</span>
                  <span className="f-light fs-16">
                    {i18n.language === "th"
                      ? `  ${item?.patient?.lastNameTh || ""}  `
                      : `  ${item?.patient?.lastNameEn || ""}  `}
                  </span>
                  <span className="f-bold fs-16">{t("phoneNumber")} :</span>
                  <span className="f-light fs-16">{` ${item?.patient?.mobile} `}</span>
                </div>
              );
            })
          ) : (
            <div className=" appointmentTooltip f-light fs-16">
              {t("theCaregiversWereNotFound")}
            </div>
          )}
        </AppointmentTooltip.Content>
      );
    } else {
      return (
        <AppointmentTooltip.Content {...restProps}>
          <div className="appointmentTooltip">
            <span className="f-bold fs-16">{t("name")} :</span>
            <span className="f-light fs-16">
              {i18n.language === "th"
                ? ` ${restProps?.appointmentData?.patient?.firstNameTh} `
                : ` ${restProps?.appointmentData?.patient?.firstNameEn} `}
            </span>
            <span className="f-bold fs-16">{t("lastName")} :</span>
            <span className="f-light fs-16">
              {" "}
              {i18n.language === "th"
                ? ` ${restProps?.appointmentData?.patient?.lastNameTh} `
                : ` ${restProps?.appointmentData?.patient?.lastNameEn} `}
            </span>
            <span className="f-bold fs-16">{t("phoneNumber")} :</span>
            <span className="f-light fs-16">{` ${
              restProps?.appointmentData?.patient?.mobile || "-"
            } `}</span>
          </div>
        </AppointmentTooltip.Content>
      );
    }
  };

  const booleanEditorComponent = ({ restProps }: any) => {
    return (
      <AppointmentForm.BooleanEditor
        {...restProps}
        value={true}
      ></AppointmentForm.BooleanEditor>
    );
  };

  const onChange = (value: any) => {
    setViewSwitcher(value);
  };

  const switcherComponent = () => {
    return (
      <div>
        <Select
          className="select-calendar"
          showSearch
          placeholder={`${t("selectDay")},${t("week")},${t("month")}`}
          onChange={onChange}
          value={viewSwitcher}
        >
          <Option value="Day">{t("day")}</Option>
          <Option value="Week">{t("week")}</Option>
          <Option value="Month">{t("month")}</Option>
        </Select>
      </div>
    );
  };

  const currentTimeIndicator = {
    shadePreviousCells: true,
    shadePreviousAppointments: true,
    updateInterval: 10000,
  };

  const FlexibleSpace = ({ restProps }: any) => {
    return (
      <Toolbar.FlexibleSpace {...restProps}>
        <SearchFunctionAll
          doctorList={
            Array.from(
              new Map(
                doctorList?.data?.result?.[0]?.data?.map((item) => [
                  item?.userId,
                  item,
                ])
              ).values()
            ) || []
          }
          roomList={roomList?.data?.result?.[0]?.data || []}
          searchOnFinish={searchOnFinish}
          formSearch={formSearch}
          status={status}
        />
      </Toolbar.FlexibleSpace>
    );
  };

  const formModal = (dataSlots: any) => {
    const dateNew = new Date();
    if (locationPath.includes("/booking/book-another-room") === true) {
      if (dateNew.getTime() < new Date(dataSlots.startDate).getTime()) {
        viewSwitcher === "Month"
          ? render(
              <QueryClientProvider client={queryClient}>
                <FormSlotsOther
                  dataSlots={dataSlots}
                  CommitChanges={CommitChanges}
           
                  doctorList={doctorList?.data?.result?.[0]?.data || []}
                  roomList={roomList?.data?.result?.[0]?.data || []}
                />
              </QueryClientProvider>
            )
          : render(
              <QueryClientProvider client={queryClient}>
                <FormSlotsOther
                  dataSlots={dataSlots}
                  CommitChanges={CommitChanges}
             
                  doctorList={doctorList?.data?.result?.[0]?.data || []}
                  roomList={roomList?.data?.result?.[0]?.data || []}
                />
              </QueryClientProvider>
            );
      }
    } else {
      if (dateNew.getTime() < new Date(dataSlots?.startDate).getTime()) {
        viewSwitcher === "Month"
          ? render(
              <QueryClientProvider client={queryClient}>
                <FormSlotsDoctorBooking
                  dataSlots={dataSlots}
                  CommitChanges={CommitChanges}
                  doctorList={doctorList?.data?.result?.[0]?.data || []}
                />
              </QueryClientProvider>
            )
          : dataSlots.id
          ? render(
              <QueryClientProvider client={queryClient}>
                <FormSlotsDoctorBookingEdit
                  dataSlots={dataSlots}
                  CommitChanges={CommitChanges}
                  doctorList={doctorList?.data?.result?.[0]?.data || []}
                />
              </QueryClientProvider>
            )
          : render(
              <QueryClientProvider client={queryClient}>
                <FormSlotsDoctorBooking
                  dataSlots={dataSlots}
                  CommitChanges={CommitChanges}
                  doctorList={doctorList?.data?.result?.[0]?.data || []}
                />
              </QueryClientProvider>
            );
      }
    }
  };

  return (
    <div style={{ backgroundColor: "white", borderRadius: "20px" }}>
      <Paper>
        <Scheduler
          locale={i18n.language === "th" ? "th-TH" : "en-US"}
          data={dataScheduler}
        >
          <ViewState
            currentDate={currentDateChange}
            currentViewName={viewSwitcher}
            onCurrentDateChange={changeCurrentDateChange}
          />
          <EditingState onCommitChanges={CommitChanges} />
          <DayView startDayHour={8} cellDuration={15} endDayHour={23} />
          <WeekView startDayHour={8} cellDuration={15} endDayHour={23} />
          <MonthView />
          <AllDayPanel messages={getAllDayMessages("th-TH")} />
          <EditRecurrenceMenu />
          <Appointments appointmentContentComponent={AppointmentContent} />
          <Resources data={resources} />
          <AppointmentTooltip
            headerComponent={header}
            contentComponent={layerBody}
          />
          {medicalRecord ? (
            <AppointmentForm
              booleanEditorComponent={booleanEditorComponent}
              appointmentData={appointmentData}
              visible={false}
              onAppointmentDataChange={(e) => formModal(e)}
            />
          ) : null}

          <Toolbar flexibleSpaceComponent={FlexibleSpace} />
          <DateNavigator />
          <TodayButton />
          <ViewSwitcher switcherComponent={switcherComponent} />
          <CurrentTimeIndicator
            shadePreviousCells={currentTimeIndicator?.shadePreviousCells}
            shadePreviousAppointments={
              currentTimeIndicator?.shadePreviousAppointments
            }
            updateInterval={currentTimeIndicator?.updateInterval}
          />
        </Scheduler>
      </Paper>
    </div>
  );
};

export default ViewPreRegister;
