import { payment } from "@assets/data/data";
import { PAYMENT_TYPE } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useUpdateReceipt } from "@services/api/receipt";
import { splitKeyFormData } from "@tools/util";
import { Divider, Form, Input } from "antd";
import { Button, Col, DatePicker } from "antd";
import { FormInstance, Modal, Row } from "antd";
import bank from "@assets/data/bank.json";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import BigNumber from "bignumber.js";
import FileSpace from "@components/file_space/FileSpace";

interface IVisible {
  visible: boolean;
  setVisible: (e: boolean) => void;
}
type Props = {
  visible: IVisible;
  receiptForm: FormInstance;
  receiptID: number | undefined;
};

const ModalSaveMoney = ({ visible, receiptForm, receiptID }: Props) => {
  const { t, i18n } = useTranslation();
  const dateFormat = "DD-MM-YYYY";
  const updateReceipt = useUpdateReceipt();
  const client = useQueryClient();

  const getFileName = () => {
    return false;
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onCancel = () => {
    visible.setVisible(!visible.visible);
  };

  const onFinish = (value: any) => {
    let formData = splitKeyFormData({ ...value, status: "COMPLETED" });

    updateReceipt.mutate(
      {
        id: receiptID,
        data: formData,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
        onSettled: () => {
          client.invalidateQueries(["get-receipt"]);
          visible.setVisible(!visible.visible);
        },
      }
    );
  };
  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col className="fs-18">
              <b className="f-normal">{t("paymentRecord")}</b>
            </Col>
          </Row>
        </>
      }
      centered
      visible={visible.visible}
      onOk={() => visible.setVisible(!visible.visible)}
      onCancel={onCancel}
      width="70%"
      footer={null}
    >
      <Form
        onFinish={onFinish}
        name="manageInfor"
        form={receiptForm}
        initialValues={{}}
      >
        <div className="scroll  ">
          <Row gutter={[14, 20]} className="pl-2 pr-2 pt-1" align="middle">
            <Col md={5} lg={4}>
              {`${t("documentNumber")} : `}
            </Col>

            <Col md={19} lg={20}>
              <Form.Item
                shouldUpdate={(prev, next) =>
                  prev.receiptNumber !== next.receiptNumber
                }
              >
                {(form: FormInstance) => {
                  const receiptNumber = form.getFieldValue("receiptNumber");
                  return <span>{receiptNumber}</span>;
                }}
              </Form.Item>
            </Col>

            <Col md={5} lg={4}>
              {`${t("amountToBePaid")} : `}
            </Col>

            <Col md={19} lg={20}>
              <Form.Item
                shouldUpdate={(prev, next) => prev.total !== next.total}
              >
                {(form: FormInstance) => {
                  const total = form.getFieldValue("total");
                  return <span>{new BigNumber(total).toFixed(2)}</span>;
                }}
              </Form.Item>
            </Col>

            <Col md={5} lg={4}>
              {`${t("paymentDate")} : `}
            </Col>

            <Col md={19} lg={20}>
              <Form.Item className="pre-register-form" name="date">
                <DatePicker disabled format={dateFormat} />
              </Form.Item>
            </Col>

            <Col md={5} lg={4}>
              {`${t("netIncome")} : `}
            </Col>

            <Col md={19} lg={20}>
              <Form.Item name="price">
                <Input type="number" min={0} />
              </Form.Item>
            </Col>

            <Col md={5} lg={4}>
              <div className="fs-18 t-blue">{t("paymentMethod")}</div>
            </Col>

            <Col md={19} lg={20}>
              <Divider
                className="m-0 "
                style={{ position: "absolute", top: 0 }}
              />
            </Col>

            <Col md={5} lg={4}>
              {`${t("paymentMethod")} : `}
            </Col>

            <Col md={19} lg={20}>
              <Form.Item name="payment">
                <Selector
                  Source={payment}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                />
              </Form.Item>
            </Col>

            <Col md={5} lg={4}></Col>

            <Col md={19} lg={20}>
              <Form.Item
                noStyle
                shouldUpdate={(prev, next) => prev.payment !== next.payment}
              >
                {(form: FormInstance) => {
                  const payment = form.getFieldValue("payment");
                  if (
                    payment === PAYMENT_TYPE.CREDIT ||
                    payment === PAYMENT_TYPE.TRANSFER
                  ) {
                    return (
                      <Form.Item name="bankName">
                        <Selector
                          Source={bank}
                          keyName="nameTh"
                          keyValue="nameTh"
                        />
                      </Form.Item>
                    );
                  } else {
                    return null;
                  }
                }}
              </Form.Item>
            </Col>

            <Col md={5} lg={4}>
              {`${t("attachFile")} : `}
            </Col>

            <Col md={19} lg={20}>
              <Form.Item
                name="image"
                valuePropName="fileList"
                getValueFromEvent={getFile}
              >
                <FileSpace
                  accept=".png,.JPG"
                  beforeUpload={getFileName}
                  form={receiptForm}
                  keyName="image"
                />
              </Form.Item>
            </Col>

            <Col md={5} lg={4}>
              <div className="fs-18 t-blue">{t("moreDetails")}</div>
            </Col>

            <Col md={19} lg={20}>
              <Divider
                className="m-0 "
                style={{ position: "absolute", top: 0 }}
              />
            </Col>

            <Col md={5} lg={4}>
              {t("remarks")} :
            </Col>

            <Col md={19} lg={20}>
              <Form.Item name="description">
                <TextArea autoSize={{ minRows: 2, maxRows: 3 }} />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>

            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => receiptForm.submit()}
                loading={updateReceipt.isLoading}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalSaveMoney;
