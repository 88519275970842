import { dayjs } from "@tools/timezone";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { NOTIFICATION } from "@assets/data/pathRouter";
import { INotification } from "@interface/INotification.interface";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useEffect, useState } from "react";
import { useDeleteNotification } from "@services/api/notification";
import { useGetNotificationTable } from "@services/api/notification";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";

type Props = {
  currentPage: ICurrentPage;
};

const TableNotification = ({ currentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const [byID, setbyID] = useState<number>();
  const [visible, setVisible] = useState(false);
  const [limitPage, setLimitPage] = useState<number>(10);
  const client = useQueryClient();
  const history = useHistory<RouteComponentProps["history"]>();
  const deleteNotification = useDeleteNotification();
  const notification = useGetNotificationTable({
    limit: limitPage,
    page: currentPage.currentPage,
    orderby: ENUM_ORDERBY.DESC,
  });

  useEffect(() => {
    setTotal(notification?.data?.result?.[0]?.total || 1);
    //eslint-disable-next-line
  }, [notification?.data]);

  const onModal = () => {
    setVisible(!visible);
  };

  const onDelete = () => {
    deleteNotification.mutate(byID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        client.invalidateQueries(["get-notification-table"]);
      },
      onError: (res) => {
        openNotification({ method: "DEL_ERROR", message: String(res) });
      },
    });
    onModal();
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClick = (value: INotification) => {
    history.push(`${NOTIFICATION.title}/${value?.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage?.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("date")}`,
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <> {date ? dayjs(date).format("DD-MM-YYYY HH:mm") : "-"}</>;
      },
    },

    {
      title: `${t("message")}`,
      dataIndex: i18n.language === "th" ? "massageTh" : "massageEn",
      key: "massage",
      render: (massage: string) => {
        return <>{massage}</>;
      },
    },

    {
      title: `${t("manage")}`,
      dataIndex: "id",
      key: "id",
      render: (id: number) => {
        return (
          <>
            <DeleteOutlined
              onClick={(e) => {
                setbyID(id);
                onModal();
                e.stopPropagation();
              }}
            />
          </>
        );
      },
    },
  ];
  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>

      <MoTable
        rowClassName={(e) => {
          if (e?.isEdit) {
            return "";
          }
          return "no-header no-cursor";
        }}
        dataSource={notification?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage?.currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
        onClickRow={(e: INotification) => e?.isEdit && onClick(e)}
      />
    </div>
  );
};

export default TableNotification;
