import React, { FC, useEffect, useState } from "react";
import { SETTING } from "@assets/data/pathRouter";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { IPrepareOrder } from "@interface/IWarehouse.interface";
import { useTranslation } from "react-i18next";
import { useGetWarehouseSaleOrder } from "@services/api/warehouse_sale_order";
import { RouteComponentProps, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import MoTable from "@components/table/Table";
import BigNumber from "bignumber.js";
import ButtonWareHouse from "../button";

type Props = {
  currentPage: ICurrentPage;
  search: any;
};

const TablePickUp: FC<Props> = ({ currentPage, search }) => {
  const { t } = useTranslation();
  const [total, setTotal] = useState<number>(1);
  const history = useHistory<RouteComponentProps["history"]>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const warehouseSaleOrder = useGetWarehouseSaleOrder({
    page: currentPage.currentPage,
    limit: limitPage,
    ...search,
    orderby: ENUM_ORDERBY.DESC,
  });

  useEffect(() => {
    setTotal(warehouseSaleOrder?.data?.result?.[0]?.total || 1);

    // eslint-disable-next-line
  }, [warehouseSaleOrder?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (e: any) => {
    history.push(`${SETTING.managePickUpGoods}/${e?.id}`);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: t("creationDate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        return <div>{dayjs(createdAt)?.format("DD-MM-YYYY")}</div>;
      },
    },

    {
      title: t("soNumber"),
      dataIndex: "soNumber",
      key: "soNumber",
      render: (soNumber: string) => {
        return <div>{soNumber}</div>;
      },
    },
    {
      title: t("branchManagement.branchName"),
      dataIndex: "prepareOrder",
      key: "prepareOrder",
      render: (prepareOrder: IPrepareOrder) => {
        return <div>{prepareOrder?.purchaseOrder?.branch?.name}</div>;
      },
    },
    {
      title: t("name"),
      dataIndex: "prepareOrder",
      key: "prepareOrder",
      render: (prepareOrder: IPrepareOrder) => {
        const branch = prepareOrder?.purchaseOrder?.branch;
        return (
          <div>{[branch?.firstName, branch?.lastName]?.join(" ")?.trim()}</div>
        );
      },
    },
    {
      title: t("phoneNum"),
      dataIndex: "prepareOrder",
      key: "prepareOrder",
      render: (prepareOrder: IPrepareOrder) => {
        const branch = prepareOrder?.purchaseOrder?.branch;
        return <div>{branch?.phone || ""}</div>;
      },
    },
    {
      title: `${t("totalPrice")} (฿)`,
      dataIndex: "total",
      key: "total",
      render: (total: string) => {
        return <div>{`${new BigNumber(total || 0).toFormat(2)}`}</div>;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (status: string) => {
        return <ButtonWareHouse status={status} text={status} />;
      },
    },
  ];

  return (
    <React.Fragment>
      <MoTable
        dataSource={warehouseSaleOrder?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </React.Fragment>
  );
};

export default TablePickUp;
