import React, { useEffect, useState } from "react";
import { INCHART } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useGetVisitsByID } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { ENUM_SERVICE_GROUP } from "@tools/util.enum";
import { RouteComponentProps } from "react-router-dom";
import { IItem, ISelectedList } from "@interface/IOrder.interface";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { Col, Drawer, Form, Row } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useGetWorkOrdersByPatient } from "@services/api/work_order";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useCreateWorkOrdersConfirm } from "@services/api/work_order";
import Fields from "./Fields";
import Header from "./Header";
import Modaler from "@components/modaler/Modaler";
import Affixes from "@components/affixes/Affixes";
import ListRecord from "@components/list_history/ListHistory";
import RoleContext from "@provider/RoleProvider";
import ListHistoryCheckbox from "@components/list_history_checkbox/ListHistoryCheckbox";
import MainPatientByIdProvider from "@provider/patient_by_id";
import MainProductOutStandingProvider from "@provider/product_out_standing";

interface visible {
  course: boolean;
  select: boolean;
  drawer: boolean;
}

const COURSE_AND_PROMOTION_CODE = ["C-0006", "C-0007"];
const TREATMENT_CODE = ["C-0001", "C-0003", "C-0008"];

const FormInchart = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match = useRouteMatch<{ patientID: string; visitID: string }>();
  const [patientList] = Form.useForm();
  const patientID = Number(match.params.patientID);
  const visitID = Number(match.params.visitID);
  const [orderList, setOrderList] = useState<ISelectedExecuteOrder[]>([]);
  const createWorkOrdersConfirm = useCreateWorkOrdersConfirm();
  const [selection, setSelection] = useState<ISelectedList>({
    course: [],
    list: [],
    oldValueList: [],
    oldValue: [],
    expandAllRows: [],
  });

  const [visible, setVisible] = useState<visible>({
    course: false,
    select: false,
    drawer: false,
  });
  const workOrdersByPatient = useGetWorkOrdersByPatient(patientID, {
    serviceGroup: [
      ENUM_SERVICE_GROUP.MEDICAL_PROCEDURE,
      ENUM_SERVICE_GROUP.LAB_INTERFACE,
      ENUM_SERVICE_GROUP.LAB,
    ]?.join(","),
    visitId: visitID,
  });

  const visitList = useGetVisitsByID(visitID);
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "INCHARGE";
  });

  useEffect(() => {
    if (!visitList?.data?.patient) {
      return;
    }

    patientList?.setFieldsValue({
      foodAllergy: visitList?.data?.patient?.foodAllergy,
      drugAllergy: visitList?.data?.patient?.drugAllergy || undefined,
    });
    // eslint-disable-next-line
  }, [visitList?.data?.patient]);

  const confirmInchart = () => {
    const result = orderList?.some(
      (item) =>
        item?.children?.[0]?.children?.[0]?.product?.categoryId === "C-0006" ||
        item?.children?.[0]?.children?.[0]?.product?.categoryId === "C-0007"
    );
    if (result) {
      if (selection?.course?.length > 0) {
        worksOrderConfirm();
      } else {
        onModalCancelCourse();
      }
    }
    if (!result) {
      orderList?.length ? worksOrderConfirm() : onModalConfirm();
    }
  };

  const courseItem = (
    array1: IItem[],
    array2: string[]
  ): {
    uuid: string;
    confirmed: boolean;
  }[] => {
    return array1?.map((item: IItem) => {
      if ((array2?.indexOf(item?.uuid) !== -1) === true) {
        return {
          uuid: item?.uuid,
          confirmed: true,
        };
      } else {
        return {
          uuid: item?.uuid,
          confirmed: false,
        };
      }
    });
  };

  const worksOrderConfirm = async () => {
    try {
      await handlePostWorksOrderConfirm();
    } catch (error) {
      console.log(error);
    }
  };

  const handlePostWorksOrderConfirm = () => {
    try {
      const selectedList = selection.list;
      let dataCourse: any = [];
      let result: IItem[] | any;
      const children = orderList
        ?.flatMap((childrenItem) => {
          return childrenItem?.children?.map((i) => i.children);
        })
        .flat();
      const dataList = children?.filter((item) => {
        if (
          COURSE_AND_PROMOTION_CODE.includes(item?.product?.categoryId || "")
        ) {
          result = courseItem(
            item?.children?.[0]?.children?.map((dataList) => {
              return { ...dataList, orderId: item?.orderId };
            }),
            selection?.course
          );
          dataCourse.push(...result);
        }
        return !COURSE_AND_PROMOTION_CODE.includes(
          item?.product?.categoryId || ""
        );
      });
      const itemsList =
        workOrdersByPatient?.data?.result?.[0]?.groups?.flatMap(
          (item) => item?.items
        ) || [];
      const itemsTreatment = itemsList?.filter((item) =>
        TREATMENT_CODE.includes(item?.product?.categoryId || "")
      );
      const courseAndPromotion = itemsList?.filter((item) =>
        COURSE_AND_PROMOTION_CODE.includes(item?.product?.categoryId || "")
      );
      const courseAndPromotionIds = courseAndPromotion.map((item) => item.id);
      const courseAndPromotionIdsUnSelected = courseAndPromotionIds.filter(
        (item) => !selectedList.includes(item)
      );
      const courseAndPromotionIdsUnSelectedUUID =
        courseAndPromotionIdsUnSelected.length
          ? courseAndPromotion
              .filter((item) => !courseAndPromotionIdsUnSelected.includes(item))
              .flatMap((item) => item.courseItems.map((val) => val.uuid))
          : [];
      result = itemsTreatment?.map((item) => {
        const list = dataList?.find(
          (itemValue) => itemValue?.uuid === item?.uuid
        );
        return {
          uuid: item?.uuid,
          confirmed: list ? true : false,
        };
      });
      const obj = {
        id:
          workOrdersByPatient?.data?.result?.[0]?.groups?.[0]?.items?.[0]
            ?.workOrderId || undefined,
        data:
          [
            ...(result || []),
            ...(dataCourse || []),
            ...courseAndPromotionIdsUnSelectedUUID.map((item) => {
              return {
                uuid: item,
                confirmed: false,
              };
            }),
          ] || undefined,
      };
      createWorkOrdersConfirm.mutate(
        {
          id:
            workOrdersByPatient?.data?.result?.[0]?.groups?.[0]?.items?.[0]
              ?.workOrderId || undefined,
          data:
            [
              ...(result || []),
              ...(dataCourse || []),
              ...courseAndPromotionIdsUnSelectedUUID
                .map((item) => {
                  return {
                    uuid: item,
                    confirmed: false,
                  };
                })
                .filter((item) => item),
            ] || undefined,
        },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
            history.replace(INCHART.title);
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

  const onModalConfirm = () => {
    setVisible({ ...visible, select: !visible?.select });
  };

  const onModalCancelCourse = () => {
    setVisible({ ...visible, course: !visible?.course });
  };

  const onConfirm = () => {
    worksOrderConfirm();
    setVisible({ ...visible, select: !visible?.select });
  };

  const onConfirmCourse = () => {
    worksOrderConfirm();
    setVisible({ ...visible, course: !visible?.course });
  };

  const showDrawer = () => {
    setVisible({ ...visible, drawer: !visible?.drawer });
  };

  return (
    <MainPatientByIdProvider value={{ patientById: visitList?.data?.patient }}>
      <MainProductOutStandingProvider
        value={{ outStanding: workOrdersByPatient?.data?.result?.[0]?.groups }}
      >
        <React.Fragment>
          <Modaler
            visible={visible?.select}
            onCancel={onModalConfirm}
            onOk={onConfirm}
            width="45%"
            header={
              <div>
                <QuestionCircleOutlined className="icon-warning-cancel" />
                {`${t("areYouSure")}`}
              </div>
            }
          >
            {t("noProductSelection")}
          </Modaler>

          <Modaler
            visible={visible.course}
            onCancel={onModalCancelCourse}
            onOk={onConfirmCourse}
            width="45%"
            header={
              <div>
                <QuestionCircleOutlined className="icon-warning-cancel" />
                {`${t("areYouSure")}`}
              </div>
            }
          >
            {t("doNotChooseProductAsCourse")}
          </Modaler>

          <Header
            history={history}
            confirmInchart={confirmInchart}
            medicalRecord={medicalRecord?.[0]?.isEdit}
          />
          <div className="set-layout body-height ">
            <Row gutter={[14, 14]}>
              <Col md={24} lg={18}>
                <Fields
                  orderList={orderList || []}
                  visitsByID={visitList?.data}
                  patientList={patientList}
                  selection={{
                    selection: selection,
                    setSelection: setSelection,
                  }}
                />
              </Col>

              <Col lg={6} className="card-display">
                {medicalRecord?.[0]?.isEdit ? (
                  <ListHistoryCheckbox
                    setOrderList={setOrderList}
                    status="inchartWait"
                    selection={{
                      selection: selection,
                      setSelection: setSelection,
                    }}
                  />
                ) : (
                  <ListRecord />
                )}
              </Col>
            </Row>

            <Affixes onClick={showDrawer} />

            <Drawer
              width={"50%"}
              closable={false}
              placement="right"
              onClose={showDrawer}
              visible={visible?.drawer}
            >
              {medicalRecord?.[0]?.isEdit ? (
                <ListHistoryCheckbox
                  setOrderList={setOrderList}
                  status="inchartWait"
                  selection={{
                    selection: selection,
                    setSelection: setSelection,
                  }}
                  noCard="noCard"
                />
              ) : (
                <ListRecord status="noCard" />
              )}
            </Drawer>
          </div>
        </React.Fragment>
      </MainProductOutStandingProvider>
    </MainPatientByIdProvider>
  );
};

export default FormInchart;
