import { OPD } from "@assets/data/pathRouter";
import { IReport } from "@interface/IVisits.interface";
import { _isEmpty } from "@tools/util";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import { useUpdateVisits } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { useEffect, useState } from "react";
import { useGetDraftOrderList } from "@services/api/draft_order";
import { Button, Col, Form, Row } from "antd";
import { useCreateNewDraftOrder } from "@services/api/draft_order";
import { useGetPatientByHistory } from "@services/api/patient";
import { useCreateVisit, useGetVisitsByID } from "@services/api/visits";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import dayjs from "dayjs";
import moment from "moment";
import BackHome from "@components/button/BackHome";
import PrintDraw from "@components/print_draw/PrintDraw";
const LeadingSymptoms = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [drawingCanvas, setDrawingCanvas] = useState({
    drawing: "",
    image: "",
  });
  const [printAndDrawForm] = Form.useForm();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const eventId = match.params.eventId;
  const patientID = match.params.patientID;
  const { data } = useGetVisitsByID(visitID);
  const createVisit = useCreateVisit();
  const updateVisits = useUpdateVisits();
  const patientIDByID = useGetPatientByHistory(patientID);
  const draftOrderList = useGetDraftOrderList(patientID, eventId || 0);
  const createNewDraftOrder = useCreateNewDraftOrder();

  useEffect(() => {
    if (!patientIDByID?.data) return;

    printAndDrawForm.setFieldsValue({
      time: dayjs().format("HH:mm"),
      height: patientIDByID?.data?.height,
    });
    // eslint-disable-next-line
  }, [patientIDByID?.data]);

  useEffect(() => {
    const radiology = data?.visitFile?.radiology?.map((item: IReport) => {
      return {
        id: item?.id,
        name: item?.fileName,
        url: item?.filePath,
        fileId: item?.fileId,
      };
    });

    const medical = data?.visitFile?.medical?.map((item: IReport) => {
      return {
        id: item?.id,
        name: item?.fileName,
        url: item?.filePath,
        fileId: item?.fileId,
      };
    });

    const summary = data?.visitFile?.summary?.map((item: IReport) => {
      return {
        id: item?.id,
        name: item?.fileName,
        url: item?.filePath,
        fileId: item?.fileId,
      };
    });

    setDrawingCanvas({ ...drawingCanvas, drawing: data?.data?.drawing || "" });

    if (
      !printAndDrawForm.getFieldValue("radiology") &&
      !printAndDrawForm.getFieldValue("summary") &&
      !printAndDrawForm.getFieldValue("medical")
    ) {
      printAndDrawForm.setFieldsValue({
        ...data?.data,
        ...data?.data?.visit,
        radiology: radiology,
        medical: medical,
        summary: summary,
      });
    }

    // eslint-disable-next-line
  }, [data]);

  const handleVisit = (values: any) => {
    if (!_isEmpty(visitID)) {
      handleUpdateVisit(values);
    } else {
      handleCreateVisit(values);
    }
  };

  const handleUpdateVisit = (values: any) => {
    // console.log(
    //   "data?.visitFile?.radiology",
    //   Array.from(
    //     new Map(
    //       [
    //         ...(data?.visitFile?.radiology || []),
    //         ...(values?.radiology || []),
    //       ]?.map((item) => [item?.id, item])
    //     ).values()
    //   )
    // );
    // console.log("values")

    let formData = new FormData();
    values?.medical?.forEach((item: any) => {
      item?.originFileObj
        ? formData?.append("medical", item?.originFileObj)
        : formData?.append("medicalId", item?.id);
    });

    values?.summary?.forEach((item: any) => {
      item?.originFileObj
        ? formData?.append("summary", item?.originFileObj)
        : formData?.append("summaryId", item?.id);
    });

    values?.radiology?.forEach((item: any) => {
      item?.originFileObj
        ? formData?.append("radiology", item?.originFileObj)
        : formData?.append("radiologyId", item?.id);
    });

    formData?.append(
      "data",
      JSON.stringify({
        print: values?.print,
        drawing: drawingCanvas?.drawing,
        visit: {
          ...values,
        },
      })
    );
    formData?.append("patientId", patientID);

    updateVisits.mutate(
      { data: formData, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.push(
            `${OPD.importantSymptoms}/patient/${patientID}/visit/${visitID}${
              !!eventId ? `/event/${eventId}` : ``
            }`
          );
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  const handleCreateVisit = async (values: any) => {
    const date = moment(new Date()).format("YYYY-MM-DD");
    const formData = new FormData();
    values?.medical?.forEach((item: any) => {
      formData?.append("medical", item?.originFileObj);
    });

    values?.summary?.forEach((item: any) => {
      formData?.append("summary", item?.originFileObj);
    });

    values?.radiology?.forEach((item: any) => {
      formData?.append("radiology", item?.originFileObj);
    });
    formData?.append("date", date.toString());
    eventId && formData?.append("eventId", eventId);
    formData?.append(
      "data",
      JSON.stringify({
        print: values?.print,
        drawing: drawingCanvas?.drawing,
        visit: {
          ...values,
        },
      })
    );

    formData?.append("patientId", patientID);
    createVisit.mutate(formData, {
      onSuccess: (res) => {
        openNotification({ method: "POST_SUCCESS" });

        if (draftOrderList?.status === "error") {
          createNewDraft(res?.result?.[0]?.slotId, res?.result?.[0]?.id);
        } else {
          if (res?.result?.[0]?.slotId && res?.result?.[0]?.slotId !== 0) {
            history.replace(
              `${OPD.title}/slots-detail/slot/${res.result?.[0]?.slotId}/patient/${patientID}/visit/${res.result?.[0]?.id}`
            );
          } else {
            history.push(
              `${OPD.importantSymptoms}/patient/${patientID}/visit/${
                res.result?.[0]?.id
              }${!!eventId ? `/event/${eventId}` : ``}`
            );
          }
        }
      },
      onError: (err) => {
        openNotification({ method: "POST_ERROR", message: String(err) });
      },
    });
  };

  const createNewDraft = (slotId: number, id: number) => {
    createNewDraftOrder.mutate(
      { data: { eventId: eventId || 0 }, id: patientID },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          if (slotId && slotId !== 0) {
            history.replace(
              `${OPD.title}/slots-detail/slot/${slotId}/patient/${patientID}/visit/${id}`
            );
          } else {
            history.push(
              `${OPD.importantSymptoms}/patient/${patientID}/visit/${id}${
                !!eventId ? `/event/${eventId}` : ``
              }`
            );
          }
        },
        onError: (res) => {
          openNotification({ method: "POST_ERROR", message: String(res) });
        },
      }
    );
  };

  return (
    <div>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("medicalRecord.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-light-gray">{t("historyFile")} / </span>
              <span className="t-dark-blue">
                {!_isEmpty(visitID)
                  ? t("editLeadingSymptoms")
                  : t("createLeadingSymptoms")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col style={{ marginLeft: "auto" }}>
          <Button
            className="mr-1 cancel"
            htmlType="reset"
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>
          <Button
            className="btn-create"
            htmlType="submit"
            loading={updateVisits?.isLoading || createVisit?.isLoading}
            onClick={() => printAndDrawForm.submit()}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
      <div className="set-layout ">
        {/* <Canvas /> */}
        <PrintDraw
          status="leading-symptoms"
          printAndDrawForm={printAndDrawForm}
          headerCard={t("createLeadingSymptoms")}
          drawingCanvas={drawingCanvas}
          setDrawingCanvas={setDrawingCanvas}
          handleCreateAndUpdate={handleVisit}
        />
      </div>
    </div>
  );
};

export default LeadingSymptoms;
