import { IAgent } from "@interface/IAgent.interface";
import { formatAgents } from "@tools/util";
import { thrownResponse } from "./auth";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetAgentsList = (params?: any): UseQueryResult<any> => {
  return useQuery(["get-agents-list", params], async () => {
    const res = await axios.get(`/agent`, { params: params });
    const agent = formatAgents(res?.data?.result?.[0]?.data);

    if (res?.status >= 200 && res?.status < 300) {
      return {
        ...res?.data,
        result: [{ ...res?.data?.result?.[0], data: agent }],
      };
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetAgentById = (
  id: number
): UseQueryResult<ElementaryOneMore<IAgent>> => {
  return useQuery(["get-agent-by-id", id], async () => {
    if (!id) {
      return null;
    } else {
      const res = await axios.get(`/agent/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    }
  });
};

export const useCreateAgentList = (): UseMutationResult => {
  return useMutation(["create-agent"], async (data: any) => {
    const res = await axios.post(`agent/bulk`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpdateAgentByID = () => {
  return useMutation(["update-agent-by-id"], async ({ id, data }: any) => {
    const res = await axios.put(`/agent/${id}`, data);

    if (res?.status === 200) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteAgentByID = () => {
  return useMutation(["delete-agent-by-id"], async (id: number) => {
    const res = await axios.delete(`/agent/${id}`);

    if (res?.status === 200) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
