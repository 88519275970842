import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useGetCategories } from "@services/api/order";
import { ENUM_ORDERBY, EVENT_ROOM } from "@tools/util.enum";
import Lab from "./lab/Lab";
import React from "react";
import Course from "./course/Course";
import Treatment from "./treatment/Treatment";
import Consignment from "./consignment/Consignment";
import Supplements from "./supplements/Supplements";
import ProductsAndServices from "./products_and_services/ProductsAndServices";
import MedicalSupplies from "./medical_supplies/MedicalSupplies";

type Props = {
  selectedRowKeys: any;
  setSelectedRowKeys: (e: any) => void;
  status?: string;
  type?: string;
};

const Fields = ({
  selectedRowKeys,
  setSelectedRowKeys,
  status,
  type,
}: Props) => {
  const { i18n } = useTranslation();
  const { TabPane } = Tabs;
  const categoriesList = useGetCategories({
    orderby: ENUM_ORDERBY.ASC,
    limit: 0,
  });

  let labList: string[] = [];
  if (
    status === "lab-other-one" ||
    status === "lab-other-two" ||
    status === "lab"
  ) {
    labList = ["C-0003"];
  } else if (status === "Drug & Supplement" || status === "drug") {
    labList = ["C-0002"];
  } else if (
    status === "manage-courses" ||
    status === EVENT_ROOM.PHYSIOTHERAPY
  ) {
    labList = ["C-0001"];
  } else if (status === "promotion") {
    labList = ["C-0001", "C-0002", "C-0003", "C-0004", "C-0005", "C-0008"];
  } else if (status === "Medical supplies") {
    labList = ["C-0008"];
  } else if (status === "Drug & Supplement & Medical supplies") {
    labList = ["C-0002", "C-0008"];
  } else if (status === EVENT_ROOM.NURSE) {
    labList = ["C-0001", "C-0008"];
  }

  const newCategoriesList =
    labList.length > 0
      ? categoriesList?.data?.result?.[0]?.data?.filter((item) =>
          labList.includes(item.id)
        ) || []
      : categoriesList?.data?.result?.[0]?.data || [];

  return (
    <React.Fragment>
      {(categoriesList?.data?.result?.[0]?.data?.length || 0) >= 1 && (
        <Tabs tabPosition="left" className="modal-scroll tab-order">
          {newCategoriesList.map((dataList: any, index: number) => (
            <TabPane
              tab={
                <div className="text-content-tab">
                  {i18n.language === "th" ? dataList?.nameTh : dataList?.nameEn}
                </div>
              }
              key={index + 1}
            >
              {dataList?.id === "C-0001" ? (
                <Treatment
                  categoriesID={dataList?.id}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  title={
                    i18n.language === "th" ? dataList?.nameTh : dataList?.nameEn
                  }
                  status={status}
                />
              ) : dataList?.id === "C-0002" ? (
                <Supplements
                  categoriesID={dataList?.id}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  title={
                    i18n.language === "th" ? dataList?.nameTh : dataList?.nameEn
                  }
                  status={status}
                />
              ) : dataList?.id === "C-0003" ? (
                <Lab
                  categoriesID={dataList?.id}
                  status={status}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  title={
                    i18n.language === "th" ? dataList?.nameTh : dataList?.nameEn
                  }
                />
              ) : dataList?.id === "C-0004" ? (
                <ProductsAndServices
                  categoriesID={dataList?.id}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  status={status}
                  title={
                    i18n.language === "th" ? dataList?.nameTh : dataList?.nameEn
                  }
                />
              ) : dataList?.id === "C-0005" ? (
                <Consignment
                  categoriesID={dataList?.id}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  title={
                    i18n.language === "th" ? dataList?.nameTh : dataList?.nameEn
                  }
                />
              ) : dataList?.id === "C-0006" || dataList?.id === "C-0007" ? (
                <Course
                  categoriesID={dataList?.id}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  title={
                    i18n.language === "th" ? dataList?.nameTh : dataList?.nameEn
                  }
                  status={type}
                />
              ) : dataList?.id === "C-0008" ? (
                <MedicalSupplies
                  categoriesID={dataList?.id}
                  selectedRowKeys={selectedRowKeys}
                  setSelectedRowKeys={setSelectedRowKeys}
                  status={
                    status === "Drug & Supplement & Medical supplies"
                      ? EVENT_ROOM.PHARMACY
                      : status
                  }
                  title={
                    i18n.language === "th" ? dataList?.nameTh : dataList?.nameEn
                  }
                />
              ) : (
                ""
              )}
            </TabPane>
          ))}
        </Tabs>
      )}
    </React.Fragment>
  );
};

export default Fields;
