import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { tabsDoctorOrderState } from "@tools/recoil/recoil-atom";
import Counsel from "./tabs/counsel/Counsel";
import OrderInfo from "./tabs/order_info/OrderInfo";

const tabsList = [
  { name: "counsel", key: "1", display: <Counsel /> },
  { name: "orderInfo", key: "2", display: <OrderInfo /> },
];

const { TabPane } = Tabs;
const TabsDoctorRoom = () => {
  const [tab, setTab] = useRecoilState(tabsDoctorOrderState);
  const { t } = useTranslation();

  const callBack = (key: string) => {
    setTab(key);
  };

  return (
    <Tabs defaultActiveKey={tab} className="tab-printdraw " onChange={callBack}>
      {tabsList?.map((item) => {
        return (
          <TabPane tab={t(`${item?.name}`)} key={item?.key} className="mt-2">
            {item?.display}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default TabsDoctorRoom;
