import { IPromotionList } from "@interface/IPromotion.interface";
import { thrownResponse } from "./auth";
import { ISearchPromotion } from "@interface/IPromotion.interface";
import { Elementary, IParamet } from "@interface/IGlobal.interface";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetPromotionList = (
  params?: ISearchPromotion
): UseQueryResult<Elementary<IPromotionList[]>> => {
  return useQuery(["get-promotion-list", params], async () => {
    const res = await axios.get(`/promotion`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetPromotionByID = (
  id: number,
  enabled?: boolean,
  params?: any
): UseQueryResult<IParamet<IPromotionList[]>> => {
  return useQuery(
    ["get-promotion-by-id", id],
    async () => {
      const res = await axios.get(`/promotion/${id}`, { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: enabled }
  );
};

export const useCreatePromotion = (): UseMutationResult => {
  return useMutation(["create-promotion"], async ({ data }: any) => {
    const res = await axios.post(`/promotion`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    thrownResponse(res);
  });
};

export const useUpdatePromotionByID = (): UseMutationResult => {
  return useMutation(["update-promotion"], async ({ id, data }: any) => {
    const res = await axios.put(`promotion/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeletePromotion = (): UseMutationResult => {
  return useMutation(["delete-promotion"], async (id: any) => {
    const res = await axios.delete(`/promotion/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    thrownResponse(res);
  });
};
