import { Col, Form, Input, Row } from "antd";

const VisitingPatients = () => {
  return (
    <div>
      <Row gutter={[14, 14]}>
        <Col md={12} lg={12}>
          <Form.Item
            name="locationCode"
            label="Location Code :"
            rules={[{ required: true }]}
          >
            <Input placeholder="Location Code :" />
          </Form.Item>
        </Col>

        <Col md={12} lg={12}>
          <Form.Item name="payer" label="Payer :" rules={[{ required: true }]}>
            <Input placeholder="Payer :" />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default VisitingPatients;
