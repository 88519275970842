import { ISearchPrefix } from "@interface/ISearch.interface";
import { useTranslation } from "react-i18next";
import { ENUM_PREFIX_TYPE } from "@tools/util.enum";
import { useGetPrefixList } from "@services/api/prefix";
import { useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import TablePrefix from "./Table";
import SelectorDouble from "@components/selector/SelectorDouble";

const bannerMenuList = [
  { name: "TH", id: ENUM_PREFIX_TYPE.TITLE_TH },
  { name: "EN", id: ENUM_PREFIX_TYPE.TITLE_EN },
];

const Prefix = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<ISearchPrefix>();
  const [total, setTotal] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const prefixList = useGetPrefixList({
    name: search?.name,
    type: search?.type
      ? [search?.type]
      : [ENUM_PREFIX_TYPE.TITLE_TH, ENUM_PREFIX_TYPE.TITLE_EN],
    page: currentPage,
    limit: limitPage,
  });

  useEffect(() => {
    setTotal(prefixList?.data?.result?.[0]?.total || 1);
    //eslint-disable-next-line
  }, [prefixList?.data]);

  const onFinish = (values: ISearchPrefix) => {
    setSearch(values);
    setCurrentPage(1);
    // handleGetCitizenship(values);
  };
  return (
    <div>
      <div className="pb-1">
        <Form onFinish={onFinish}>
          <Row gutter={[14, 14]}>
            <Col sm={7} md={7} lg={8} xs={9} xl={9} xxl={9}>
              <Form.Item name="name">
                <Input placeholder={`${t("search")}${t("namePrefix")}`} />
              </Form.Item>
            </Col>
            <Col sm={8} md={9} lg={10} xs={10} xl={10} xxl={11}>
              <Form.Item name="type">
                <SelectorDouble
                  Source={bannerMenuList}
                  keyName="name"
                  keyValue="id"
                  title={t("type")}
                />
              </Form.Item>
            </Col>

            <Col>
              <Button className="btn-search" htmlType="submit">
                {t("search")}
              </Button>
              <Button className="clear ml-05" htmlType="reset">
                {t("reset")}
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
      <TablePrefix
        prefix={prefixList?.data?.result?.[0]?.data || []}
        limitPage={limitPage}
        setLimitPage={setLimitPage}
        total={total}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      />
    </div>
  );
};

export default Prefix;
