import { Form } from "antd";
import { useRouteMatch } from "react-router-dom";
import { useGetCouponByID } from "@services/api/coupon";
import { useEffect, useState } from "react";
import { ICouponProductCategory } from "@interface/ICoupon.interface";
import moment from "moment";
import Fields from "./Fields";
import Header from "./Header";

const CouponDetail = () => {
  const [confirm] = Form.useForm();
  const match: any = useRouteMatch();
  const couponId = match.params.id;
  const couponByID = useGetCouponByID(couponId);
  const [orderList, setOrderList] = useState<any>({
    key: [
      { key: [] },
      { key: [] },
      { key: [] },
      { key: [] },
      { key: [] },
      { key: [] },
    ],
    order: [],
  });

  useEffect(() => {
    confirm.setFieldsValue({
      ...couponByID?.data?.result?.[0],
      categorySelete:
        (couponByID?.data?.result?.[0]?.couponProductCategory?.length || 0) > 0
          ? "category"
          : "product",
      start: [
        moment(couponByID?.data?.result?.[0]?.startDate),
        moment(couponByID?.data?.result?.[0]?.endDate),
      ],
      number: couponByID?.data?.result?.[0]?.discountPercent,
      right: couponByID?.data?.result?.[0]?.qty ? true : false,
      uses: couponByID?.data?.result?.[0]?.qtyUse ? true : false,
      category: couponByID?.data?.result?.[0]?.couponProductCategory.flatMap(
        (item: ICouponProductCategory) => {
          return item?.productCategory?.id;
        }
      ),
    });

    if ((couponByID?.data?.result?.[0]?.couponProducts.length || 0) > 0) {
      setOrderList({
        ...orderList,
        order: couponByID?.data?.result?.[0]?.couponProducts,
      });
    }
    // eslint-disable-next-line
  }, [couponByID?.data]);

  return (
    <div>
      <Header couponId={couponId} />
      <Fields confirm={confirm} orderList={orderList} />
    </div>
  );
};

export default CouponDetail;
