import { useEffect } from "react";
import { NOTIFICATION } from "@assets/data/pathRouter";
import { INotification } from "@interface/INotification.interface";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useSetRecoilState } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { useCreateNotification } from "@services/api/notification";
import { useUpdateNotification } from "@services/api/notification";
import { useGetNotificationByID } from "@services/api/notification";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Card, Col, DatePicker, Form, FormInstance, Input, Row } from "antd";
import moment from "moment";

type Props = {
  create: FormInstance;
  notificationID: number;
};

const Fields = ({ create, notificationID }: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const setLoadingButton = useSetRecoilState<boolean>(loadingButtonState);
  const createNotification = useCreateNotification();
  const notificationByID = useGetNotificationByID(notificationID);
  const updateNotification = useUpdateNotification();

  useEffect(() => {
    create.setFieldsValue({
      ...notificationByID?.data?.result?.[0],
      date: notificationByID?.data?.result?.[0]?.date
        ? moment(notificationByID?.data?.result?.[0]?.date)
        : undefined,
    });
    //eslint-disable-next-line
  }, [notificationByID?.data?.result?.[0]]);

  useEffect(() => {
    setLoadingButton(createNotification.isLoading);
    //eslint-disable-next-line
  }, [createNotification.isLoading]);

  const createNotified = (value: INotification) => {
    createNotification.mutate(value, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.push(`${NOTIFICATION.title}`);
      },
      onError: (res) => {
        openNotification({ method: "POST_ERROR", message: String(res) });
      },
    });
  };

  const updateNotified = (value: INotification) => {
    updateNotification.mutate(
      { data: value, id: notificationID },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.push(`${NOTIFICATION.title}`);
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };

  const onFinish = (value: INotification) => {
    if (notificationID) {
      updateNotified(value);
    } else {
      createNotified(value);
    }
  };
  return (
    <div>
      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={
          <b className="fs-16 t-black-light ">
            {notificationID
              ? `${t("editNotification")}`
              : `${t("createNotification")}`}
          </b>
        }
      >
        <Card
          className="card-prefix card-prefix-width card-prefix-chi"
          extra={
            <b className="fs-14 c-white ">
              {notificationID
                ? `${t("editNotification")}`
                : `${t("createNotification")}`}
            </b>
          }
        >
          <Form onFinish={onFinish} name="create" form={create}>
            <Row gutter={[0, 12]}>
              <Col lg={24}>
                <div className=" t-grey-light">{`${t(
                  "booking.notification"
                )} (TH)`}</div>
              </Col>
              <Col lg={24}>
                <Form.Item rules={[{ required: true }]} name="massageTh">
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={24}>
                <div className=" t-grey-light">{`${t(
                  "booking.notification"
                )} (EN)`}</div>
              </Col>
              <Col lg={24}>
                <Form.Item rules={[{ required: true }]} name="massageEn">
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={24}>
                <div className=" t-grey-light">{t("notificationDate")}</div>
              </Col>
              <Col lg={24}>
                <Form.Item
                  rules={[{ required: true, message: t("pleaseSelect") }]}
                  name="date"
                >
                  <DatePicker showTime format="YYYY-MM-DD HH:mm" />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Card>
    </div>
  );
};

export default Fields;
