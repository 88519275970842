import { ENUM_G6PD } from "@tools/util.enum";
import { TextBRHTML } from "@tools/util";
import { INTERFACE_LAB } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useGetLabInterface } from "@services/api/work_order";
import { RouteComponentProps } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Button, Card, Col, Form, Row } from "antd";
import Link from "@assets/img/icon/Link.svg";
import File from "@assets/img/icon/File.svg";
import Order from "./Order";
import moment from "moment";
import ViewImage from "@components/drawing_canvas/ViewImage";
import SymbolView from "@components/symbol/Symbol";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import MainPatientByIdProvider from "@provider/patient_by_id";

type IKeyName = "doctorComment" | "leadingSymptoms";

interface IVisible {
  doctorComment: boolean;
  leadingSymptoms: boolean;
}
type Props = {
  link: string;
};

const DetailLabInterface: FC<Props> = ({ link }) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const labInterface = useGetLabInterface(visitID);
  const [patientList] = Form.useForm();
  const history = useHistory<RouteComponentProps["history"]>();
  const patientID = match.params.patientID;
  const [visible, setVisible] = useState<IVisible>({
    doctorComment: false,
    leadingSymptoms: false,
  });

  useEffect(() => {
    patientList?.setFieldsValue({
      foodAllergy:
        labInterface?.data?.result?.[0]?.visit?.patient?.foodAllergy ||
        undefined,
      drugAllergy:
        labInterface?.data?.result?.[0]?.visit?.patient?.drugAllergy ||
        undefined,
    });
    // eslint-disable-next-line
  }, [labInterface?.data]);

  const modalSignature = (keyName: IKeyName) => {
    setVisible({ ...visible, [keyName]: !visible[keyName] });
  };

  const setModal = () => {
    setVisible({ ...visible, leadingSymptoms: false, doctorComment: false });
  };

  return (
    <MainPatientByIdProvider
      value={{ patientById: labInterface?.data?.result?.[0]?.visit?.patient }}
    >
      <div>
        <Card>
          <Row align="middle" justify="space-between">
            <Col>
              <div className="mb-05">
                <span className=" t-blue fs-18 ">{t("fullList")} </span>
                <span className=" t-grey-light  fs-12 ">
                  {t("orderCreationDate")} :{" "}
                </span>
                <span className="t-grey fs-12 ">
                  {labInterface?.data?.result?.[0]?.visit
                    ? `${moment(
                        labInterface?.data?.result?.[0]?.visit?.date
                      ).format("DD-MM-YYYY")}`
                    : ``}
                </span>
              </div>
            </Col>
            <Col>
              <Button
                className="reserve mr-05 "
                htmlType="submit"
                onClick={() => {
                  history.replace(
                    `${INTERFACE_LAB.printLabelDetail}${visitID}`
                  );
                }}
              >
                {t("printLabel")}
              </Button>
              <Button
                className="send-lab  "
                htmlType="submit"
                onClick={() => {
                  history.push(`${link}/patient/${patientID}/visit/${visitID}`);
                }}
              >
                {t("labResults")}
              </Button>
            </Col>
          </Row>

          <Row>
            <Col>
              <div style={{ color: "#FEB139" }}>
                <SymbolView />
              </div>
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {labInterface?.data?.result?.[0]?.visit?.patient?.g6pd ===
                ENUM_G6PD.DEFICIENCY
                  ? "G6PD Deficiency"
                  : null}
              </div>
            </Col>
          </Row>

          <Form name="basicInfoForm" form={patientList}>
            <ImportantInfo />
          </Form>

          <Card
            className="comment-card mt-2 "
            extra={
              <>
                <Row gutter={[14, 14]}>
                  <Col md={12} lg={12}>
                    {t("leadingSymptoms")}
                  </Col>
                  <Col md={12} lg={12} className="col-end link-btn">
                    <Button
                      type="link"
                      onClick={() => modalSignature("leadingSymptoms")}
                    >
                      {t("seePaintings")}
                    </Button>
                  </Col>
                </Row>
              </>
            }
          >
            <div
              className="t-black-light"
              dangerouslySetInnerHTML={{
                __html: TextBRHTML(
                  labInterface?.data?.result?.[0]?.visit?.data?.print || ""
                ),
              }}
            />

            {labInterface?.data?.result?.[0]?.visit?.visitFile?.radiology && (
              <div className="f-normal mt-2 mb-1">Radiology Result</div>
            )}

            {labInterface?.data?.result?.[0]?.visit?.visitFile?.radiology &&
              labInterface?.data?.result?.[0]?.visit?.visitFile?.radiology?.map(
                (item, index) => {
                  return (
                    <Row
                      key={index}
                      align="middle"
                      className="mt-05 cursor"
                      onClick={() => window.open(item?.filePath)}
                    >
                      <img
                        src={Link}
                        alt="Link"
                        width={13}
                        height={13}
                        style={{ marginRight: "2px" }}
                      />
                      <img
                        src={File}
                        alt="File"
                        width={25}
                        height={25}
                        style={{ marginRight: "5px" }}
                      />
                      <div>{item?.fileName || ""}</div>
                    </Row>
                  );
                }
              )}
            {labInterface?.data?.result?.[0]?.visit?.visitFile?.medical && (
              <div className="f-normal mt-2 mb-1">Medical Report</div>
            )}

            {labInterface?.data?.result?.[0]?.visit?.visitFile?.medical &&
              labInterface?.data?.result?.[0]?.visit?.visitFile?.medical?.map(
                (item, index) => {
                  return (
                    <Row
                      key={index}
                      align="middle"
                      className="mt-05 cursor"
                      onClick={() => window.open(item?.filePath)}
                    >
                      <img
                        src={Link}
                        alt="Link"
                        width={13}
                        height={13}
                        style={{ marginRight: "2px" }}
                      />
                      <img
                        src={File}
                        alt="File"
                        width={25}
                        height={25}
                        style={{ marginRight: "5px" }}
                      />
                      <div>{item?.fileName || ""}</div>
                    </Row>
                  );
                }
              )}
            {labInterface?.data?.result?.[0]?.visit?.visitFile?.summary && (
              <div className="f-normal mt-2 mb-1">Summary Result</div>
            )}

            {labInterface?.data?.result?.[0]?.visit?.visitFile?.summary &&
              labInterface?.data?.result?.[0]?.visit?.visitFile?.summary?.map(
                (item, index) => {
                  return (
                    <Row
                      key={index}
                      align="middle"
                      className="mt-05 cursor"
                      onClick={() => window.open(item?.filePath)}
                    >
                      <img
                        src={Link}
                        alt="Link"
                        width={13}
                        height={13}
                        style={{ marginRight: "2px" }}
                      />
                      <img
                        src={File}
                        alt="File"
                        width={25}
                        height={25}
                        style={{ marginRight: "5px" }}
                      />
                      <div>{item?.fileName || ""}</div>
                    </Row>
                  );
                }
              )}
          </Card>

          <Card
            className="comment-card mt-2 "
            extra={
              <>
                <Row gutter={[14, 14]}>
                  <Col md={12} lg={12}>
                    {t("doctorOpinion")}
                  </Col>

                  <Col md={12} lg={12} className="col-end link-btn">
                    <Button
                      type="link"
                      onClick={() => modalSignature("doctorComment")}
                    >
                      {t("seePaintings")}
                    </Button>
                  </Col>
                </Row>
              </>
            }
          >
            <div
              className="t-black-light"
              dangerouslySetInnerHTML={{
                __html: TextBRHTML(
                  labInterface?.data?.result?.[0]?.visit?.suggestion?.print ||
                    ""
                ),
              }}
            ></div>
          </Card>

          <Order labInterface={labInterface?.data?.result?.[0]?.workOrder} />
        </Card>

        {visible.leadingSymptoms && (
          <ViewImage
            visible={visible.leadingSymptoms}
            setModal={setModal}
            drawing={
              labInterface?.data?.result?.[0]?.visit?.data?.drawing || ""
            }
            title={t("leadingSymptoms")}
          />
        )}
        {visible.doctorComment && (
          <ViewImage
            visible={visible.doctorComment}
            setModal={setModal}
            drawing={
              labInterface?.data?.result?.[0]?.visit?.suggestion.drawing || ""
            }
            title={t("doctorOpinion")}
          />
        )}
      </div>
    </MainPatientByIdProvider>
  );
};

export default DetailLabInterface;
