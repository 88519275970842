import React, { FC } from "react";
import { t } from "i18next";
import { dayjs } from "@tools/timezone";
import { IGetVisits } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { Col, Row, Form, FormInstance } from "antd";
import logo from "@assets/img/logo/Ab.png";

type Props = {
  refData: React.MutableRefObject<null>;
  visitsByID?: IGetVisits;
};

const Print: FC<Props> = ({ refData, visitsByID }) => {
  const { i18n } = useTranslation();

  const widthDiv = (num?: number) => {
    if (417 / Number(num || 1) > 12) {
      return `11.8px`;
    }
    return `${(417 / Number(num || 1)) * 1.1}px`;
  };

  const widthDivName = (num?: number) => {
    if (800 / Number(num || 1) > 12) {
      return `11.8px`;
    }
    return `${800 / Number(num || 1)}px`;
  };

  return (
    <div ref={refData} className="drugLabel t-black ">
      <div className="pr-05 pl-05  ">
        <Row justify="space-between">
          <Col className="fs-12-drug f-bold t-black">
            <div className="col-print-none">
              <img
                src={logo}
                alt="logo"
                style={{ width: "3cm", height: "1.1cm" }}
                className="image-print-none"
              />
            </div>
          </Col>
          <Col className="fs-12-drug f-bold t-black">
            <div>
              <div className="fs-14-drug f-bold t-black">
                HN : {`${visitsByID?.patient?.hn}`}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="pr-05 pl-05 pb-05 ">
        <div className="fs-14-drug">
          <span className="fs-14-drug f-bold t-black">
            {i18n?.language === "th"
              ? `${[
                  visitsByID?.patient?.titleTh,
                  visitsByID?.patient?.firstNameTh,
                  visitsByID?.patient?.lastNameTh,
                ]
                  ?.join(" ")
                  ?.trim()}`
              : `${[
                  visitsByID?.patient?.titleEn,
                  visitsByID?.patient?.firstNameEn,
                  visitsByID?.patient?.lastNameEn,
                ]
                  ?.join(" ")
                  ?.trim()}`}
          </span>
        </div>

        <Form.Item
          className="fs-12-drug  f-light"
          shouldUpdate={(prev, next) =>
            prev?.nameShown !== next?.nameShown ||
            prev?.genericName !== next?.genericName
          }
        >
          {(form: FormInstance) => {
            const nameShown = form.getFieldValue("nameShown");
            const genericName = form.getFieldValue("genericName");

            return (
              <React.Fragment>
                <div
                  className="f-bold t-black "
                  style={{
                    fontSize: widthDivName(
                      `${nameShown || ""}  (${genericName || ""})`?.length
                    ),
                    paddingBottom: 1,
                  }}
                >
                  <span className="t-black">{`${nameShown || ""}  `}</span>
                  <span className="f-normal t-black">{`(${
                    genericName || ""
                  })`}</span>
                </div>
              </React.Fragment>
            );
          }}
        </Form.Item>

        <div className="fs-14-drug t-black">
          <Form.Item
            className="fs-14-drug f-bold t-black"
            shouldUpdate={(prev, next) =>
              prev?.eatingPattern !== next?.eatingPattern ||
              prev?.quantityUsed !== next?.quantityUsed
            }
          >
            {(form: FormInstance) => {
              const quantityUsed = form.getFieldValue("quantityUsed");
              const eatingPattern = form.getFieldValue("eatingPattern");
              const unitAlt =
                i18n.language === "th"
                  ? form.getFieldValue("unitAltTh")
                  : form.getFieldValue("unitAltEn");
              return (
                <>
                  <span>
                    {`${t("oneMealAtATime")} ${quantityUsed || 0} ${
                      unitAlt || ""
                    }`}
                  </span>
                  {eatingPattern?.map((item: string) => {
                    return (
                      <span key={item} className=" fs-14-drug ">
                        {` ${t(`${item}`)} `}
                      </span>
                    );
                  })}
                </>
              );
            }}
          </Form.Item>
        </div>

        <div className="fs-14-drug t-black">
          <Form.Item
            className="fs-14-drug f-bold t-black"
            shouldUpdate={(prev, next) => prev?.timeSpent !== next?.timeSpent}
          >
            {(form: FormInstance) => {
              const timeSpent = form.getFieldValue("timeSpent");

              return (
                <React.Fragment>
                  {`${t("aDay")} `}
                  <span className="fs-14-drug ">
                    {`${timeSpent?.length || 0} ${t("time1")}`}
                  </span>
                  {timeSpent?.map((item: string) => {
                    return (
                      <span key={item} className=" fs-14-drug ">
                        {` ${t(`${item}`)} `}
                      </span>
                    );
                  })}
                </React.Fragment>
              );
            }}
          </Form.Item>
        </div>

        <Form.Item
          className="fs-12-drug t-black"
          shouldUpdate={(prev, next) =>
            prev?.specialInstructions !== next?.specialInstructions
          }
          noStyle
        >
          {(form: FormInstance) => {
            const specialInstructions = form.getFieldValue(
              "specialInstructions"
            );

            return (
              <div className="fs-12-drug t-black">
                <span className="fs-12-drug  t-black">
                  {` ${specialInstructions || ""}`}
                </span>
              </div>
            );
          }}
        </Form.Item>

        <Form.Item
          className="fs-12-drug  t-black"
          shouldUpdate={(prev, next) =>
            prev?.descriptionTh !== next?.descriptionTh ||
            prev?.descriptionEn !== next?.descriptionEn
          }
          noStyle
        >
          {(form: FormInstance) => {
            const description =
              i18n.language === "th"
                ? form.getFieldValue("descriptionTh")
                : form.getFieldValue("descriptionEn");

            return (
              <div className="fs-12-drug t-black">
                <span
                  className="fs-12-drug  t-black"
                  style={{
                    fontSize: widthDiv(` ${description || ""}`?.length),
                  }}
                >
                  {` ${description || ""}`}
                </span>
              </div>
            );
          }}
        </Form.Item>

        <Row justify="space-between">
          <Col className="fs-12-drug f-bold t-black">
            {/* <div>
              <span className="fs-12-drug f-bold t-black">
                {t("drugLabelDate")}{" "}
              </span>
              <span className="fs-12-drug f-bold t-black">
                {dayjs().format("DD-MM-YYYY")}
              </span>
            </div> */}
          </Col>
          {/* <Col className="fs-12-drug f-bold t-black"> */}
          <div style={{ position: "absolute", bottom: 3, right: 8 }}>
            <span className="fs-12-drug f-bold t-black">
              {dayjs().format("DD-MM-YYYY")}
            </span>
          </div>

          {/* </Col> */}
        </Row>
      </div>
    </div>
  );
};

export default Print;
