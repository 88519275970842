import { IGetRoom } from "@interface/IRoom.interface";
import { IUserList } from "@interface/IUsers.interface";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, FormInstance, Row } from "antd";
import Selector from "../../selector/Selector";

type Props = {
  doctorList: IUserList[];
  searchOnFinish: (values: any) => void;
  formSearch: FormInstance;
  roomList: IGetRoom[];
};

const SearchQueue = ({
  doctorList,
  searchOnFinish,
  formSearch,
  roomList,
}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="search-queue">
      <Form onFinish={searchOnFinish} name="formSearch" form={formSearch}>
        <Row gutter={[14, 14]}>
          <Col lg={9}>
            <Form.Item name="roomId">
              <Selector
                placeholder={t("allRooms")}
                Source={roomList}
                keyName="name"
                keyValue="id"
              />
            </Form.Item>
          </Col>

          <Col lg={9}>
            <Form.Item name="userId">
              <Selector
                placeholder={t("allDoctorNames")}
                Source={doctorList?.map((item) => {
                  return {
                    ...item,
                    nameTh: [item?.firstNameTh, item?.lastNameTh]
                      ?.join(" ")
                      ?.trim(),
                    nameEn: [item?.firstNameEn, item?.lastNameEn]
                      ?.join(" ")
                      ?.trim(),
                  };
                })}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
              />
            </Form.Item>
          </Col>

          <Col lg={4} className="d-flex justify-between">
            <Button className="mr-05 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default SearchQueue;
