import { useIsMutating } from "react-query";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
  confirmTransferCourse: () => void;
  medicalRecord: boolean;
};

const Header = ({ history, confirmTransferCourse, medicalRecord }: Props) => {
  const { t } = useTranslation();
  const loadingCreate = useIsMutating(["create-transform-courses"]);

  const confirm = () => {
    confirmTransferCourse();
  };

  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("transferCourse.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-normal">
                {t("transferCourse.title")}
              </span>
            </Col>
          </Row>
        </Col>
        {medicalRecord ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button
              className="cancel mr-05 "
              htmlType="submit"
              onClick={history.goBack}
            >
              {t("cancel")}
            </Button>

            <Button
              className="send-lab"
              loading={!!loadingCreate}
              onClick={confirm}
            >
              {t("transferCourse.title")}
            </Button>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default Header;
