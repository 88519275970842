import { Form } from "antd";
import { useEffect } from "react";
import { IReceiptSlip } from "@interface/IReceipt.interface";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useUpdateReceipt } from "@services/api/receipt";
import { useGetReceiptByID } from "@services/api/receipt";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import React from "react";
import moment from "moment";
import Fields from "./Fields";
import Header from "./Header";
import RoleContext from "@provider/RoleProvider";

const CreateInvoice = () => {
  const { i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const client = useQueryClient();
  const receiptID = match.params.receiptID;
  const roleList = React.useContext(RoleContext);
  const receiptIDList = useGetReceiptByID(receiptID);
  const [receiptForm] = Form.useForm();
  const updateReceipt = useUpdateReceipt();
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PAY";
  });

  useEffect(() => {
    if (!receiptIDList?.data) return;
    const patient =
      receiptIDList?.data?.result?.[0]?.invoice?.bill?.order?.patient;
    const nameTH = [patient?.titleTh, patient?.firstNameTh, patient?.lastNameTh]
      ?.join(" ")
      ?.trim();
    const nameEn = [patient?.titleEn, patient?.firstNameEn, patient?.lastNameEn]
      ?.join(" ")
      ?.trim();
    const mailingAddress = patient?.mailingAddress;
    const mailAddress =
      mailingAddress?.addressTh || mailingAddress?.districtTh
        ? [
            mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            mailingAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "districtTh" : "districtEn"
            ],
            mailingAddress[
              i18n.language === "th" ? "provinceTh" : "provinceEn"
            ],
            mailingAddress?.zipcode,
            mailingAddress?.country,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const _homeAddress = patient?.homeAddress;
    const homeAddress =
      _homeAddress?.addressTh || _homeAddress?.districtTh
        ? [
            _homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
            _homeAddress[
              i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            _homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
            _homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
            _homeAddress?.country,
            _homeAddress?.zipcode,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    receiptForm.setFieldsValue({
      name: i18n.language === "th" ? nameTH : nameEn,
      address: mailAddress || homeAddress,
      zipCode: !!mailAddress
        ? patient?.mailingAddress?.zipcode
        : patient?.homeAddress?.zipcode,
      date: moment(),
      referenceNumber: receiptIDList?.data?.result?.[0]?.invoiceNumber,
      sale:
        i18n?.language === "th"
          ? [
              receiptIDList?.data?.result?.[0]?.user?.firstNameTh,
              receiptIDList?.data?.result?.[0]?.user?.lastNameTh,
            ]
              ?.join(" ")
              ?.trim()
          : [
              receiptIDList?.data?.result?.[0]?.user?.firstNameEn,
              receiptIDList?.data?.result?.[0]?.user?.lastNameEn,
            ]
              ?.join(" ")
              ?.trim(),
      imageId: receiptIDList?.data?.result?.[0]?.receiptSlip,
      description: receiptIDList?.data?.result?.[0]?.description,
    });

    // eslint-disable-next-line
  }, [receiptIDList?.dataUpdatedAt, i18n.language]);

  const onUpdateReceipt = (values: any) => {
    const imageIdOld = values?.imageId
      ?.filter((item: IReceiptSlip) => {
        return !!item?.id;
      })
      ?.flatMap((list: IReceiptSlip) => {
        return list?.imageId;
      });

    const newImage = values?.imageId?.filter((item: IReceiptSlip) => {
      return !!item?.uid;
    });

    let formData = new FormData();
    !!values?.description &&
      values?.description !== 0 &&
      formData?.append("description", values?.description);
    formData?.append("imageId", JSON.stringify([...(imageIdOld || [])]));
    newImage?.forEach((item: IReceiptSlip) => {
      !!item?.originFileObj && formData?.append("image", item?.originFileObj);
    });

    updateReceipt.mutate(
      {
        id: receiptID,
        data: formData,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
        onSettled: () => {
          client.invalidateQueries(["get-receipt-by-ID"]);
        },
      }
    );
  };

  return (
    <>
      <Header
        history={history}
        receiptForm={receiptForm}
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
      <div className="set-layout ">
        <Fields
          receiptIDList={receiptIDList?.data}
          receiptForm={receiptForm}
          onFinish={onUpdateReceipt}
          history={history}
          receiptID={receiptID}
          medicalRecord={medicalRecord?.[0]?.isEdit}
        />
      </div>
    </>
  );
};

export default CreateInvoice;
