import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Col, Row } from "antd";
import { IVisitBill } from "@interface/IGlobal.interface";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useCreateSignature } from "@services/api/draft_order";
import { EVENT_PATIENT_STATUS } from "@tools/util.enum";
import { useGetPatientByHistory } from "@services/api/patient";
import { useGetHistoryPreregister } from "@services/api/patient";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRef, useState, useEffect } from "react";
import { formatDataDraft, recursive, splitKeyFormData } from "@tools/util";
import Header from "./Header";
import ModalInfo from "@components/signature/ModalInfo";
import BigNumber from "bignumber.js";
import PrintReceipt from "../../../print_receipt/Print";

const ViewReport = () => {
  const pageStyle = `  @page { size:A4;
    margin: 40px;}`;
  const history = useHistory();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const eventId = match.params.eventID;
  const patientByID = useGetPatientByHistory(patientID);
  const draftOrderList = useGetHistoryPreregister(patientID, eventId);
  const printReportRef = useRef(null);
  const client = useQueryClient();
  const createSignature = useCreateSignature();
  const [visible, setVisible] = useState<IVisitBill>({
    first: false,
    second: false,
  });

  const [divHeight, setDivHeight] = useState(
    document.getElementById("container")?.clientHeight
  );

  useEffect(() => {
    setDivHeight(document.getElementById("container")?.clientHeight);
    // eslint-disable-next-line
  }, [document.getElementById("container")?.clientHeight]);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  const updateSignature = (values: any) => {
    const formData = splitKeyFormData({
      ...values,
      eventId: eventId,
    });
    createSignature.mutate(
      {
        id: patientID,
        data: formData,
      },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          setVisible({
            ...visible,
            [values?.title]: false,
          });

          client.invalidateQueries([
            "get-draft-order-list",
            patientID,
            eventId,
          ]);

          client.invalidateQueries([
            "get-history-preregister",
            patientID,
            eventId,
          ]);
        },
        onError: () => {
          openNotification({ method: "POST_ERROR" });
        },
      }
    );
  };

  return (
    <div>
      <Header history={history} handlePrint={handlePrint} />

      <ReactToPrint content={() => printReportRef.current} />
      <div className="set-layout ">
        <div className="document-body ">
          <Row>
            <Col lg={24} className="display-center ">
              <div className="document-bill">
                <Row>
                  <Col lg={24} className="display-center ">
                    <PrintReceipt
                      refData={printReportRef}
                      patientByID={patientByID?.data}
                      divHeight={divHeight}
                      visible={visible}
                      setVisible={setVisible}
                      draftOrderList={formatDataDraft(
                        draftOrderList?.data?.data?.groups,
                        "preregister"
                      )}
                      total={draftOrderList?.data?.data?.groups?.reduce(
                        (result: number, current) => {
                          return Number(
                            new BigNumber(current?.total).plus(result)
                          );
                        },
                        0
                      )}
                      status={EVENT_PATIENT_STATUS?.IN_PROGRESS}
                      keyValue={recursive(draftOrderList?.data?.data?.groups)}
                      draftOrderListData={draftOrderList?.data}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>

      {visible.first ? (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="first"
          updateSignature={updateSignature}
          isLoading={createSignature.isLoading}
          uploadSignature={true}
        />
      ) : visible.second ? (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="second"
          updateSignature={updateSignature}
          isLoading={createSignature.isLoading}
          uploadSignature={true}
        />
      ) : null}
    </div>
  );
};

export default ViewReport;
