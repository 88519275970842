import { Elementary } from "@interface/IGlobal.interface";
import { thrownResponse } from "./auth";
import { reformatDataLabInterface } from "@tools/util";
import { ICompare, IFormatLabInterface } from "@interface/ILab.interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useCreateLab = () => {
  return useMutation(["create-lab"], async (data: any) => {
    const res = await axios.post(`/lab`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCallbackLab = () => {
  return useMutation(async (data: any) => {
    const res = await axios.post(`/lab/callback-lab`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateRequest = () => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.post(`/lab/request/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetLabCompare = (
  id: number,
  formatInterface: boolean,
  params?: any
): UseQueryResult<Elementary<ICompare[] & IFormatLabInterface[]>> => {
  return useQuery(
    ["get-lab-compare", id, formatInterface, params],
    async () => {
      const res = await axios.get(`/lab/compare/${id}`, { params: params });
      var date: string[] = [];
      if (res?.status >= 200 && res?.status < 300) {
        if (formatInterface) {
          var dataTable: IFormatLabInterface[] = [];
          res?.data?.result?.[0]?.data?.forEach(
            (_data: IFormatLabInterface) => {
              const find = dataTable.find(
                (test: any) => test.testCode === _data?.testCode
              );
              if (!find) {
                const format = reformatDataLabInterface(_data, date);

                delete _data?.testCode;
                date = format?.date || [];

                dataTable = [
                  ...(dataTable || []),
                  format?.result || [],
                ] as IFormatLabInterface[];
              }
            }
          );

          return {
            ...res?.data,
            result: [
              { ...res?.data?.result?.[0], data: dataTable, date: date },
            ],
          };
        } else {
          res?.data?.result?.[0]?.data?.forEach(
            (_data: IFormatLabInterface) => {
              const format = reformatDataLabInterface(_data, date);
              date = format?.date || [];
            }
          );
          return {
            ...res?.data,
            result: [{ ...res?.data?.result?.[0], date: date }],
          };
        }
      } else if (res?.status >= 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useGetCompareVisit = (
  id: number,
  formatInterface: boolean,
  params?: any
): UseQueryResult<Elementary<ICompare[] & IFormatLabInterface[]>> => {
  return useQuery(
    ["get-compare-visit", id, formatInterface, params],
    async () => {
      const res = await axios.get(`/lab/compare/visit/${id}`, {
        params: params,
      });

      var date: string[] = [];
      if (res?.status >= 200 && res?.status < 300) {
        if (formatInterface) {
          var dataTable: IFormatLabInterface[] = [];
          res?.data?.result?.[0]?.data?.forEach(
            (_data: IFormatLabInterface) => {
              const find = dataTable.find(
                (test: any) => test.testCode === _data?.testCode
              );
              if (!find) {
                const format = reformatDataLabInterface(_data, date);

                delete _data?.testCode;
                date = format?.date || [];

                dataTable = [
                  ...dataTable,
                  format?.result,
                ] as IFormatLabInterface[];
              }
            }
          );

          return {
            ...res?.data,
            result: [
              { ...res?.data?.result?.[0], data: dataTable, date: date },
            ],
          };
        } else {
          res?.data?.result?.[0]?.data?.forEach(
            (_data: IFormatLabInterface) => {
              const format = reformatDataLabInterface(_data, date);
              date = format?.date || [];
            }
          );
          return {
            ...res?.data,
            result: [{ ...res?.data?.result?.[0], date: date }],
          };
        }
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};
