import { useEffect } from "react";
import { Col, Form, Row } from "antd";
import { useMainPhysicianAssistant } from "@provider/physician_assistant";
import { ISelected, ITotalAndQuantity } from "@interface/IGlobal.interface";
import Fields from "./Fields";
import OrderSummary from "./OrderSummary";
import MainPatientByIdProvider from "@provider/patient_by_id";

type Props = {
  patientID: number;
  selected: ISelected;
  totalAndQuantity: ITotalAndQuantity;
};

const FormOrderList = ({ patientID, selected, totalAndQuantity }: Props) => {
  const { appointmentById } = useMainPhysicianAssistant();
  const [patientList] = Form.useForm();

  useEffect(() => {
    patientList?.setFieldsValue({
      drugAllergy: appointmentById?.patient?.drugAllergy,
      foodAllergy: appointmentById?.patient?.foodAllergy,
    });

    // eslint-disable-next-line
  }, [appointmentById]);

  return (
    <MainPatientByIdProvider value={{ patientById: appointmentById?.patient }}>
      <div className="set-layout   ">
        <Row gutter={[14, 14]}>
          <Col md={24} lg={18}>
            <Fields
              patientID={patientID}
              patientList={patientList}
              selected={selected}
              totalAndQuantity={totalAndQuantity}
            />
          </Col>

          <Col md={24} lg={6}>
            <OrderSummary
              className="not"
              selectedRowKeys={selected.selectedRowKeys}
              totalAndQuantity={totalAndQuantity}
            />
          </Col>
        </Row>
      </div>
    </MainPatientByIdProvider>
  );
};

export default FormOrderList;
