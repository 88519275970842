import { OPD } from "@assets/data/pathRouter";
// import { dayjs } from "@tools/timezone";
import { useState } from "react";
import { ACCOUNT_TYPE } from "@tools/util.enum";
import { useCreateMedia } from "@services/api/media_object";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { Form, FormInstance } from "antd";
import { useGetInsurancesList } from "@services/api/insurances";
import { dataUrlToFile, _isEmpty } from "@tools/util";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { useUpdatePatient, useCreatePatient } from "@services/api/patient";
import moment from "moment";
import SelectionTable from "@components/table/TableSelection";

type Props = {
  companyForm: FormInstance;
  history: RouteComponentProps["history"];
  pathID: number | string;
  selectedRowKeys: number[];
  setSelectedRowKeys: (e: any) => void;
};

const Fields = ({
  history,
  companyForm,
  pathID,
  selectedRowKeys,
  setSelectedRowKeys,
}: Props) => {
  const { t, i18n } = useTranslation();
  // const newDay = dayjs();
  const match: any = useRouteMatch();
  const eventId = match.params.eventId;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total] = useState<number>(1);
  const createPatient = useCreatePatient();
  const updatePatient = useUpdatePatient();
  const createMedia = useCreateMedia();
  const insurancesList = useGetInsurancesList({
    page: currentPage,
    limit: limitPage,
  });

  const onFinish = () => {
    if (localStorage.getItem("basicInfo") !== null) {
      const basicInfo = localStorage.getItem("basicInfo");
      const basicInfoList = JSON.parse(basicInfo ? basicInfo : `{}`);

      if (basicInfoList?.image?.includes("base64")) {
        const file = dataUrlToFile(basicInfoList?.image, "profile.png");
        if (file) {
          let formData = new FormData();
          formData?.append("file", file);

          createMedia.mutate(formData, {
            onSuccess: (res) => {
              if (!_isEmpty(pathID)) {
                handleUpdatePatient(res?.result?.[0]?.id);
              } else {
                handleCreatePatient(res?.result?.[0]?.id);
              }
            },
            onError: (error) => {
              openNotification({
                method: "POST_ERROR",
                message: String(error),
              });
            },
          });
        }
      } else {
        if (!_isEmpty(pathID)) {
          handleUpdatePatient();
        } else {
          handleCreatePatient();
        }
      }
    }
  };
  // const convertNumbers = (number: number): string => {
  //   if (number < 10) {
  //     return '0' + number;
  //   }
  //   return number.toString();
  // }
  const addLeadingZero = (number: number | string): string => {
    const convertedNumber = String(number).padStart(2, '0');
    return convertedNumber;
  }

  const handleCreatePatient = (idImage?: number) => {
    let data;
    if (
      localStorage.getItem("basicInfo") !== null ||
      localStorage.getItem("otherInfo") !== null
    ) {
      const otherInfo = localStorage.getItem("otherInfo");
      const basicInfo = localStorage.getItem("basicInfo");
      const otherInfoList = JSON.parse(otherInfo ? otherInfo : `{}`);
      const basicInfoList = JSON.parse(basicInfo ? basicInfo : `{}`);
      delete basicInfoList?.image;
      let dateOfBirth: any;
      let date = `${basicInfoList?.year || moment().format("YYYY")}-${basicInfoList?.month || moment().format("MM")}-${addLeadingZero(basicInfoList?.day) || moment().format("YYYY-MM-DD")}`;
      dateOfBirth = { dateOfBirth: date };

      let dataBasicInfoList = {
        insurancesId: selectedRowKeys,
        homeAddress: {
          subdistrict: basicInfoList?.subdistrict,
          address: basicInfoList?.address,
          district: basicInfoList?.district,
          province: basicInfoList?.province,
          country: basicInfoList?.country,
          zipcode: basicInfoList?.zipcode,
        },
        mailingAddress: {
          address: basicInfoList?.addressDoc,
          subdistrict: basicInfoList?.subDistrictDoc,
          district: basicInfoList?.districtDoc,
          province: basicInfoList?.provinceDoc,
          country: basicInfoList?.countryDoc,
          zipcode: basicInfoList?.zipcodeDoc,
        },
        ...dateOfBirth,
        ...basicInfoList,
      };

      data = {
        ...otherInfoList,
        ...dataBasicInfoList,
        accountType: ACCOUNT_TYPE.REGISTER,

        firstMeetHow:
          basicInfoList?.firstMeetHow === "8"
            ? `${basicInfoList?.firstMeetHow}:${basicInfoList?.firstMeetHow1}`
            : basicInfoList?.firstMeetHow,
      };
    }
    createPatient.mutate(
      { ...data, imageId: idImage ? idImage : undefined },
      {
        onSuccess: (res) => {
          history.replace(`${OPD.profile}/patient/${res.result?.[0]?.id}`);
          openNotification({ method: "POST_SUCCESS" });
          localStorage.removeItem("basicInfo");
          localStorage.removeItem("otherInfo");
        },
        onError(error) {
          openNotification({ method: "POST_ERROR", message: String(error) });
        },
      }
    );
  };

  const handleUpdatePatient = (idImage?: number) => {
    let data;
    if (
      localStorage.getItem("basicInfo") !== null ||
      localStorage.getItem("otherInfo") !== null
    ) {
      const otherInfo = localStorage.getItem("otherInfo");
      const basicInfo = localStorage.getItem("basicInfo");
      const otherInfoList = JSON.parse(otherInfo ? otherInfo : `{}`);
      const basicInfoList = JSON.parse(basicInfo ? basicInfo : `{}`);

      delete basicInfoList?.image;

      let dateOfBirth: any;
      let date = `${basicInfoList?.year || moment().format("YYYY")}-${basicInfoList?.month || moment().format("MM")}-${addLeadingZero(basicInfoList?.day) || moment().format("YYYY-MM-DD")}`;
      dateOfBirth = { dateOfBirth: date };
      // dateOfBirth = { dateOfBirth: moment(date).format("YYYY-MM-DD") };

      let dataBasicInfoList = {
        insurancesId: selectedRowKeys,
        homeAddress: {
          subdistrict: basicInfoList?.subdistrict,
          address: basicInfoList?.address,
          district: basicInfoList?.district,
          province: basicInfoList?.province,
          country: basicInfoList?.country,
          zipcode: basicInfoList?.zipcode,
        },
        mailingAddress: {
          subdistrict: basicInfoList?.subDistrictDoc,
          address: basicInfoList?.addressDoc,
          district: basicInfoList?.districtDoc,
          province: basicInfoList?.provinceDoc,
          country: basicInfoList?.countryDoc,
          zipcode: basicInfoList?.zipcodeDoc,
        },
        ...dateOfBirth,
        ...basicInfoList,
      };

      data = {
        ...otherInfoList,
        ...dataBasicInfoList,
        accountType: ACCOUNT_TYPE.REGISTER,

        firstMeetHow:
          basicInfoList?.firstMeetHow === "8"
            ? `${basicInfoList?.firstMeetHow}:${basicInfoList?.firstMeetHow1}`
            : basicInfoList?.firstMeetHow,
      };
    }

    updatePatient.mutate(
      { data: { ...data, imageId: idImage ? idImage : undefined }, id: pathID },
      {
        onSuccess: (res) => {
          history.replace(
            `${OPD.profile}/patient/${res.result?.[0]?.id}${!!eventId ? `/event/${eventId}` : ``
            }`
          );
          openNotification({ method: "POST_SUCCESS" });
          localStorage.removeItem("basicInfo");
          localStorage.removeItem("otherInfo");
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onSelectChange = (selectedRowKeys: any) => {
    setSelectedRowKeys([...selectedRowKeys]);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const columns: any = [
    {
      title: "#",
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",

      render: (_: any, data: any, index: number) => {
        return <div key={data?.id}>{index + 1}</div>;
      },
    },
    {
      title: t("contractCompany"),
      dataIndex: "name",
      render: (_: any, data: any) => {
        return (
          <div>
            {i18n.language === "th" ? data?.nameTh || "" : data?.nameEn || ""}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <Form
        onFinish={onFinish}
        name="companyForm"
        form={companyForm}
        scrollToFirstError={true}
      >
        <SelectionTable
          dataSource={insurancesList?.data?.result?.[0]?.data || []}
          columns={columns}
          onChangePage={onChangePage}
          rowSelection={rowSelection}
          config={{
            total: total,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
        />
      </Form>
    </div>
  );
};

export default Fields;
