import React, { FC, useEffect, useState } from "react";
import { IGetVisits } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Empty, Form, Row } from "antd";
import empty from "@assets/img/empty.svg";
import VitalSign from "@components/vital_sign/VitalSign";
import RoleContext from "@provider/RoleProvider";
import ModalVitalSign from "./ModalVitalSign";

type IKeyName = "sitting1Min" | "standing1Mins" | "lyingDown" | "doctorRoom";

type Props = {
  title: string;
  visitsByID?: IGetVisits;
  keyName: IKeyName;
};

const VitalSignDetail: FC<Props> = ({ title, visitsByID, keyName }) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "MEDICAL";
  });
  const [vitalSignForm] = Form.useForm();

  useEffect(() => {
    if (!visitsByID?.data?.[keyName]) return;

    vitalSignForm.setFieldsValue({
      ...visitsByID?.data?.[keyName],
    });
    // eslint-disable-next-line
  }, [visitsByID?.data?.[keyName]]);

  const haveVisitsByID = () => {
    const data = visitsByID?.data?.[keyName];
    if (data) {
      if (Object.keys(data)?.includes("time")) {
        return true;
      }

      return false;
    }
    return false;
  };

  const onClick = () => {
    setVisible(!visible);
  };
  return (
    <div>
      <Card
        className="comment-card mt-2 "
        extra={
          <React.Fragment>
            <Row gutter={[14, 14]} align="middle">
              <Col className="f-normal t-dark-blue ">{title}</Col>
              {medicalRecord ? (
                <Col
                  style={{ marginLeft: "auto" }}
                  className=" link-btn f-normal"
                >
                  <Button type="link" onClick={onClick}>
                    {`${t("edit")} ${title}`}
                  </Button>
                </Col>
              ) : null}
            </Row>
          </React.Fragment>
        }
      >
        <div className="t-black-light">
          {haveVisitsByID() ? (
            <Form name="vitalSign" form={vitalSignForm}>
              <VitalSign medicalRecord={false} />
            </Form>
          ) : (
            <Empty
              image={empty}
              imageStyle={{
                height: 60,
              }}
              description={
                <span className="t-grey-white">
                  {t("no")} {title}
                </span>
              }
            >
              {medicalRecord?.[0]?.isEdit ? (
                <Button type="primary" onClick={onClick}>
                  {t("createNow")}
                </Button>
              ) : null}
            </Empty>
          )}
        </div>
      </Card>

      {visible && (
        <ModalVitalSign
          visible={{ visible: visible, setVisible: setVisible }}
          title={title}
          vitalSign={vitalSignForm}
          visitsByID={visitsByID}
          keyName={keyName}
        />
      )}
    </div>
  );
};

export default VitalSignDetail;
