import { OPD } from "@assets/data/pathRouter";
import { Form } from "antd";
import { ACCOUNT_TYPE, ENUM_MENU } from "@tools/util.enum";
import { openNotification } from "@components/notification/Notification";
import { useGetPatientById } from "@services/api/patient";
import { useLocation, useRouteMatch } from "react-router-dom";
import { useCreateSaveToOrderToRegister } from "@services/api/draft_order";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";
import RoleContext from "@provider/RoleProvider";
import React from "react";

const PreRegistrationDetail = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const location = useLocation();
  const match: any = useRouteMatch();
  const [preRegister] = Form.useForm();
  const patientID = match.params.patientID;
  const eventID = match.params.eventID;
  const locationPath = location.pathname;
  const patientByID = useGetPatientById(patientID);
  const saveToRegister = useCreateSaveToOrderToRegister();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === ENUM_MENU.PREREGISTER1;
  });

  const sendPatient = () => {
    saveToRegister.mutate(
      { patientId: patientID, eventId: eventID },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          if (patientByID?.data?.accountType === ACCOUNT_TYPE.REGISTER) {
            history.replace(
              `${OPD.profile}/patient/${patientID}/event/${eventID}`
            );
          } else {
            history.replace(`${OPD.basicInfo}/${patientID}/event/${eventID}`);
          }
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  return (
    <>
      <Header
        history={history}
        patientID={patientID}
        locationPath={locationPath}
        sendPatient={sendPatient}
        eventID={eventID}
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
      <div className="set-layout ">
        <Fields
          preRegister={preRegister}
          patientID={patientID}
          history={history}
          eventID={eventID}
          patientById={patientByID?.data}
        />
      </div>
    </>
  );
};

export default PreRegistrationDetail;
