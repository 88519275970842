import { downloadXLSX, handleMassageError } from "@tools/util";
import {
  IBranchReport,
  IInvoiceReport,
  IPatientReport,
  IProductProcedure,
  IProductProcedurePurchase,
  IProductPromotionPurchase,
  IProductPurchase,
  IReceiptReport,
  IReportAgent,
} from "@interface/IReport.interface";
import { thrownResponse } from "./auth";
import { openNotification } from "@components/notification/Notification";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { IPurchaseByAge, IPurchaseReport } from "@interface/IReport.interface";
import { useMutation, UseMutationResult } from "react-query";
import i18n from "i18next";
import axios from "@axios/index";

export const useGetReportCountPatientAgentExport = (
  enabled: boolean,
  params?: { search?: string; page: number; limit: number; orderby: string }
): UseQueryResult<ElementaryOneMore<IReportAgent>> => {
  return useQuery(
    ["get-report-count-patient-agent-export", params],
    async () => {
      const res = await axios.get(`/report/count-patient-agent/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "agent");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }

      openNotification({
        method: "GET_ERROR",
        message: i18n.language === "th" ? "ไม่พบข้อมูล" : "Not found",
      });
    },
    { enabled: enabled }
  );
};

export const useGetReportCountPatientAgent = (
  params?: any
): UseQueryResult<Elementary<IReportAgent[]>> => {
  return useQuery(["get-report-count-patient-agent", params], async () => {
    const res = await axios.get(`report/count-patient-agent`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data || {};
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    }

    thrownResponse(res);
  });
};

export const useGetReportPurchaseTreatment = (): UseMutationResult<
  Elementary<IPurchaseReport[]>
> => {
  return useMutation(["get-report-purchase-treatment"], async (params: any) => {
    const res = await axios.get(`/report/purchase-treatment`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportPurchaseTreatmentExport = (): UseMutationResult<
  ElementaryOneMore<IPurchaseReport>
> => {
  return useMutation(
    ["get-report-purchase-treatment-export"],
    async (params: any) => {
      const res = await axios.get(`/report/purchase-treatment/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "รายงานห้องหัตถการ");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }

      openNotification({
        method: "GET_ERROR",
        message: i18n.language === "th" ? "ไม่พบข้อมูล" : "Not found",
      });
    }
  );
};

export const useGetReportPurchaseProduct = (): UseMutationResult<
  Elementary<IPurchaseReport[]>
> => {
  return useMutation([`get-report-purchase-product`], async (params: any) => {
    const res = await axios.get(`/report/purchase-product`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportPurchaseProductExport = (): UseMutationResult<
  ElementaryOneMore<IPurchaseReport>
> => {
  return useMutation(
    ["get-report-purchase-product-export"],
    async (params: any) => {
      const res = await axios.get(`/report/purchase-product/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "สินค้า");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }
    }
  );
};

export const useGetReportPurchaseByAge = (): UseMutationResult<
  Elementary<IPurchaseByAge[]>
> => {
  return useMutation(["get-report-purchase-by-age"], async (params: any) => {
    const res = await axios.get(`/report/purchase-by-age`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportPurchaseByAgeExport = (): UseMutationResult<
  ElementaryOneMore<IPurchaseByAge>
> => {
  return useMutation(
    ["get-report-purchase-by-age-export"],
    async (params: any) => {
      const res = await axios.get(`/report/purchase-by-age/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "รายงานการซื้อตามช่วงอายุ");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }
    }
  );
};

//** new */
export const useGetReportProductPromotionPurchase = (): UseMutationResult<
  Elementary<IProductPromotionPurchase[]>
> => {
  return useMutation(
    [`get-report-product-promotion-purchase`],
    async (params: any) => {
      const res = await axios.get(`/report/product-promotion/purchase`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      } else if (res?.status > 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useGetReportProductPromotionPurchaseExport = (): UseMutationResult<
  ElementaryOneMore<IProductPromotionPurchase>
> => {
  return useMutation(
    ["get-report-product-promotion-purchase-export"],
    async (params: any) => {
      const res = await axios.get(`/report/product-promotion/purchase/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "รายงานสรุปยอดขายโปรโมชั่น");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }
    }
  );
};

export const useGetReportProductProcedure = (): UseMutationResult<
  Elementary<IProductProcedure[]>
> => {
  return useMutation([`get-report-product-procedure`], async (params: any) => {
    const res = await axios.get(`/report/product-procedure`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportProductProcedureExport = (): UseMutationResult<
  ElementaryOneMore<IProductProcedure>
> => {
  return useMutation(
    ["get-report-product-procedure-export"],
    async (params: any) => {
      const res = await axios.get(`/report/product-procedure/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "รายงานสรุปยอดรายการทรีเมนท์");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }
    }
  );
};

export const useGetReportProductProcedurePurchase = (): UseMutationResult<
  Elementary<IProductProcedurePurchase[]>
> => {
  return useMutation(
    [`get-report-product-procedure-purchase`],
    async (params: any) => {
      const res = await axios.get(`/report/product-procedure/purchase`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      } else if (res?.status > 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useGetReportProductProcedurePurchaseExport = (): UseMutationResult<
  ElementaryOneMore<IProductProcedurePurchase>
> => {
  return useMutation(
    ["get-report-product-procedure-purchase-export"],
    async (params: any) => {
      const res = await axios.get(`/report/product-procedure/purchase/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "รายงานขายเรียงตามหัตถการ");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }
    }
  );
};

export const useGetReportProductPurchase = (): UseMutationResult<
  Elementary<IProductPurchase[]>
> => {
  return useMutation([`get-report-product-purchase`], async (params: any) => {
    const res = await axios.get(`/report/product/purchase`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportProductPurchaseExport = (): UseMutationResult<
  ElementaryOneMore<IProductPurchase>
> => {
  return useMutation(
    ["get-report-product-purchase-export"],
    async (params: any) => {
      const res = await axios.get(`/report/product/purchase/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "รายงานสรุปยอดขาย");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }
    }
  );
};

export const useGetReportProductPurchaseHn = (): UseMutationResult<
  Elementary<IProductPromotionPurchase[]>
> => {
  return useMutation(
    [`get-report-product-purchase-hn`],
    async (params: any) => {
      const res = await axios.get(`/report/product/purchase/hn`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      } else if (res?.status > 400 && res?.status < 500) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useGetReportProductPurchaseHnExport = (): UseMutationResult<
  ElementaryOneMore<IProductPromotionPurchase>
> => {
  return useMutation(
    ["get-report-product-purchase-hn-export"],
    async (params: any) => {
      const res = await axios.get(`/report/product/purchase/hn/export`, {
        responseType: "blob",
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        downloadXLSX(res?.data, "รายงานขายสินค้าแยกตามHN");
        return res?.data || {};
      } else if (res?.status === 400) {
        openNotification({
          method: "GET_ERROR",
          message: handleMassageError(res?.data.message),
        });
      }
    }
  );
};

export const useGetReportBranch = (): UseMutationResult<
  Elementary<IBranchReport[]>
> => {
  return useMutation([`get-report-branch`], async (params: any) => {
    const res = await axios.get(`/report/branch`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportBranchExport = (): UseMutationResult<
  ElementaryOneMore<IBranchReport>
> => {
  return useMutation(["get-report-branch-export"], async (params: any) => {
    const res = await axios.get(`/report/branch/export`, {
      responseType: "blob",
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      downloadXLSX(res?.data, "รายงานสรุปยอดขายแต่ละสาขา");
      return res?.data || {};
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    }
  });
};

export const useGetReportPatient = (): UseMutationResult<
  Elementary<IPatientReport[]>
> => {
  return useMutation([`get-report-patient`], async (params: any) => {
    const res = await axios.get(`/report/patient`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportPatientExport = (): UseMutationResult<
  ElementaryOneMore<IPatientReport>
> => {
  return useMutation(["get-report-patient-export"], async (params: any) => {
    const res = await axios.get(`/report/patient/export`, {
      responseType: "blob",
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      downloadXLSX(res?.data, "รายงานคนไข้");
      return res?.data || {};
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    }
  });
};

export const useGetReportInvoice = (): UseMutationResult<
  Elementary<IInvoiceReport[]>
> => {
  return useMutation([`get-report-invoice`], async (params: any) => {
    const res = await axios.get(`/report/invoice`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportInvoiceExport = (): UseMutationResult<
  ElementaryOneMore<IInvoiceReport>
> => {
  return useMutation(["get-report-invoice-export"], async (params: any) => {
    const res = await axios.get(`/report/invoice/export`, {
      responseType: "blob",
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      downloadXLSX(res?.data, "รายงานสรุปค้างชำระ");
      return res?.data || {};
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    }
  });
};

export const useGetReportReceipt = (): UseMutationResult<
  Elementary<IReceiptReport[]>
> => {
  return useMutation([`get-report-receipt`], async (params: any) => {
    const res = await axios.get(`/report/receipt`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    } else if (res?.status > 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetReportReceiptExport = (): UseMutationResult<
  ElementaryOneMore<IReceiptReport>
> => {
  return useMutation(["get-report-receipt-export"], async (params: any) => {
    const res = await axios.get(`/report/receipt/export`, {
      responseType: "blob",
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      downloadXLSX(res?.data, "รายงานสรุปนำส่งเงิน-ใบเสร็จรับเงิน");
      return res?.data || {};
    } else if (res?.status === 400) {
      openNotification({
        method: "GET_ERROR",
        message: handleMassageError(res?.data.message),
      });
    }
  });
};
//** new */
