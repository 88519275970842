import { useState } from "react";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useGetEventMyBranch } from "@services/api/event";
import { Card, Col, Form, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import dayjs from "dayjs";
import BackHome from "@components/button/BackHome";
import ViewPreRegister from "@components/slots/view_pre_register/ViewPreRegister";

const View = () => {
  const [formSearch] = Form.useForm();
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [currentDateChange, setCurrentDateChange] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );
  const eventList = useGetEventMyBranch({
    page: 1,
    limit: 0,
    orderby: ENUM_ORDERBY.DESC,
  });
  const searchOnFinish = (value: any) => {};

  const changeCurrentDateChange = (currentDate: any) => {
    setCurrentDateChange(dayjs(currentDate).format("YYYY-MM-DD"));
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("preRegister.title")}</h1>
              </div>
              <span className="t-light-gray">{t("marketing.title")} / </span>
              <span className="t-dark-blue f-normal">ปฏิทิน </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout body-height view-slots-detail ">
        <Card>
          <ViewPreRegister
            dataScheduler={
              eventList?.data?.result?.[0]?.data?.map((item) => {
                return {
                  ...item,
                  startDate: item?.startDate,
                  endDate: item?.endDate,
                  title: item?.name,
                  color: `#${Math.floor(Math.random() * 16777215).toString(
                    16
                  )}`,
                };
              }) || []
            }
            currentDateChange={currentDateChange}
            changeCurrentDateChange={changeCurrentDateChange}
            searchOnFinish={searchOnFinish}
            formSearch={formSearch}
          />
        </Card>
      </div>
    </div>
  );
};

export default View;
