import { useTranslation } from "react-i18next";
import { useGetPatientById } from "@services/api/patient";
import { Card, FormInstance } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useEffect, useState } from "react";
import Fields from "./Fields";
import StepOPD from "@components/step/Step";

type Props = {
  companyForm: FormInstance;
  history: RouteComponentProps["history"];
  pathID: number;
};

const FormCompany = ({ history, companyForm, pathID }: Props) => {
  const { t } = useTranslation();
  const [selectedRowKeys, setSelectedRowKeys] = useState<number[] | []>([]);
  const patientIDByID = useGetPatientById(pathID);

  useEffect(() => {
    const result = patientIDByID?.data?.patientInsurance
      ?.map((item) => {
        return item?.insuranceId;
      })
      ?.flat(Infinity);

    setSelectedRowKeys(result || []);
    //eslint-disable-next-line
  }, [patientIDByID?.data]);

  return (
    <div className="set-layout ">
      <Card className="bg-grey m-2 from-card" extra={<StepOPD />}>
        <span className="strong h1 header-top ">{t("createKeeper")}</span>
        <div className="title-top pt-05">{`${t("contractCompany")} (${t(
          "underwrite"
        )})`}</div>

        <Fields
          companyForm={companyForm}
          history={history}
          pathID={pathID}
          selectedRowKeys={selectedRowKeys}
          setSelectedRowKeys={setSelectedRowKeys}
        />
      </Card>
    </div>
  );
};

export default FormCompany;
