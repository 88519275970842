import { general } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useUpdatePrefix } from "@services/api/prefix";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { Card, Col, Form, FormInstance, Input, Row } from "antd";

type Props = {
  prefixForm: FormInstance;
  history: RouteComponentProps["history"];
  prefixID: number;
  medicalRecord: boolean;
};

const Fields = ({ prefixForm, history, prefixID, medicalRecord }: Props) => {
  const { t } = useTranslation();
  const updatePrefix = useUpdatePrefix();

  const onFinish = (dataForm: any) => {
    handleUpdatePrefix(dataForm);
  };

  const handleUpdatePrefix = (values: any) => {
    updatePrefix.mutate(
      { id: prefixID, data: values },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.push(general);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };
  return (
    <div>
      <div className="set-layout ">
        <Card
          className="card-prefix card-prefix-hd pb-2"
          extra={
            <b className="fs-16 t-black-light ">{`${t("edit")} ${t(
              "generalInfo.title"
            )}`}</b>
          }
        >
          <Card
            className="card-prefix card-prefix-width card-prefix-chi"
            extra={
              <b className="fs-14 c-white ">{`${t("edit")} ${t(
                "generalInfo.title"
              )}`}</b>
            }
          >
            <Form onFinish={onFinish} name="prefixForm" form={prefixForm}>
              <Row gutter={[0, 12]}>
                <Col lg={24}>
                  <div className="mb-05 t-grey-light">
                    {t("generalInfo.title")}
                  </div>
                  <div className="mb-1">
                    <Form.Item name="name" rules={[{ required: true }]}>
                      <Input disabled={!medicalRecord} />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </Form>
          </Card>
        </Card>
      </div>
    </div>
  );
};

export default Fields;
