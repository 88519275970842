import { useState } from "react";
import { ISignature } from "@interface/IGlobal.interface";
import { dataUrlToFile } from "@tools/util";
import { useTranslation } from "react-i18next";
import { procedureReport } from "@assets/data/pathRouter";
import { Button, Col, Row } from "antd";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { KeyNameSignatureFirst } from "@interface/IGlobal.interface";
import { useCreateSignatureRoom } from "@services/api/signature_room";
import { useIsMutating, useQueryClient } from "react-query";
import BackHome from "@components/button/BackHome";
import ModalInfo from "@components/signature/ModalInfo";

type Props = {
  history: RouteComponentProps["history"];
  visitID: number | string;
  handleUpdateWorkOrderStatus: () => void;
  medicalRecord: boolean;
  isSignature?: boolean;
};

const Header = ({
  history,
  visitID,
  handleUpdateWorkOrderStatus,
  medicalRecord,
  isSignature,
}: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState({ first: false });
  const createSignatureRoom = useCreateSignatureRoom();
  const client = useQueryClient();
  const loading = useIsMutating(["update-work-order-update-status"]);

  const reportDrugHistory = () => {
    history.push(`${procedureReport}/visit/${visitID}`);
  };

  const updateSignature = (
    values: {
      date: string;
      title: KeyNameSignatureFirst;
      doctorName?: string;
    } & ISignature
  ) => {
    const file = dataUrlToFile(
      values?.firstSignature,
      `procedureSignature${new Date().getTime()}.png`
    );
    if (file) {
      let formData = new FormData();
      formData?.append("signature", file);
      formData?.append("room", ENUM_ROOM_SIGNATURE.PROCEDURE);
      !!values?.doctorName &&
        formData?.append("doctorName", values?.doctorName);

      createSignatureRoom.mutate(
        { data: formData, visitId: visitID },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            client.invalidateQueries(["get-visitsByID", visitID]);
            setVisible({
              ...visible,
              [values?.title]: !visible[values?.title],
            });
          },
        }
      );
    } else {
      setVisible({
        ...visible,
        [values?.title]: !visible[values?.title],
      });
    }
  };

  return (
    <>
      <Row className="second-header " align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("procedure.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-bold">{t("procedure.title")}</span>
            </Col>
          </Row>
        </Col>

        {medicalRecord ? (
          <Col style={{ marginLeft: "auto" }}>
            <Button className="cancel mr-05 " onClick={history.goBack}>
              {t("cancel")}
            </Button>
            <Button className="reserve mr-05 " onClick={reportDrugHistory}>
              {t("report")}
            </Button>

            <Button
              loading={!!loading}
              className="btn-create"
              onClick={() => {
                !!isSignature
                  ? setVisible({ first: true })
                  : handleUpdateWorkOrderStatus();
              }}
            >
              {t("confirm")}
            </Button>
          </Col>
        ) : null}
      </Row>
      {visible.first && (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={""}
          name="first"
          title={t("medicalAssistantSignature")}
          updateSignature={updateSignature}
          isLoading={createSignatureRoom?.isLoading}
        />
      )}
    </>
  );
};

export default Header;
