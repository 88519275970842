export const period = [
  {
    name: "beforeMeal",
    key: "morning",
  },
  {
    name: "beforeMeal",
    key: "daytime",
  },
  {
    name: "beforeMeal",
    key: "evening",
  },
  {
    name: "afterMeal",
    key: "morning",
  },
  {
    name: "afterMeal",
    key: "daytime",
  },
  {
    name: "afterMeal",
    key: "evening",
  },

  {
    name: "withFood",
    key: "morning",
  },
  {
    name: "withFood",
    key: "daytime",
  },
  {
    name: "withFood",
    key: "evening",
  },
];
