import { Form } from "antd";
import { _isEmpty } from "@tools/util";
import { useEffect } from "react";
import { useRouteMatch } from "react-router-dom";
import { useGetPromotionByID } from "@services/api/promotion";
import moment from "moment";
import Fields from "./Fields";
import Header from "./Header";

const CreatePromotion = () => {
  const [confirm] = Form.useForm();
  const match: any = useRouteMatch();
  const promotionId = match?.params?.id;
  const duplicateId = match?.params?.duplicateId;
  const promotionById = useGetPromotionByID(
    promotionId || duplicateId,
    !_isEmpty(promotionId || duplicateId)
  );

  useEffect(() => {
    if (!promotionById?.data) return;

    confirm.setFieldsValue({
      ...promotionById?.data?.result?.[0],
      ...promotionById?.data?.result?.[0]?.product,
      start: [
        promotionById?.data?.result?.[0]?.product?.startDate
          ? moment(promotionById?.data?.result?.[0]?.product?.startDate)
          : undefined,
        promotionById?.data?.result?.[0]?.product?.endDate
          ? moment(promotionById?.data?.result?.[0]?.product?.endDate)
          : undefined,
      ],
    });

    if (!!duplicateId) {
      confirm.setFieldsValue({
        status: undefined,
        start: undefined,
      });
    }
    // eslint-disable-next-line
  }, [promotionById?.data]);

  return (
    <div>
      <Header confirm={confirm} promotionId={promotionId} />
      <Fields
        confirm={confirm}
        promotionById={promotionById?.data}
        promotionId={promotionId}
      />
    </div>
  );
};

export default CreatePromotion;
