import { Age } from "@tools/age";
import { dayjs } from "@tools/timezone";
import { period } from "./data";
import { Col, Row } from "antd";
import { Component } from "react";
import { IVisitDrug } from "@interface/IVisits.interface";
import { eatingPattern, timeSpent } from "@tools/util";
import { DRUG_STATUS, ENUM_ROLE_EN } from "@tools/util.enum";
import { IOptionDetail, IReportDrug } from "@interface/IWorkOrder.interface";
import ImageLogo from "@components/image/ImageLogo";

type Props = {
  drugList: IVisitDrug | undefined;
  i18n: string;
};

const status: {
  [K in DRUG_STATUS]: {
    name: string;
    color?: string;
  };
} = {
  [DRUG_STATUS.CONTINUOUS]: {
    name: "Continue",
  },
  [DRUG_STATUS.DIS_CONTINUOUS]: {
    name: "Discontinue",
    color: "red",
  },
  [DRUG_STATUS.HOLDING]: {
    name: "Holding",
  },
};

class Print extends Component<Props> {
  render() {
    return (
      <>
        <table className="report-drug t-center" width="100%">
          <thead>
            <tr>
              <th
                colSpan={1}
                rowSpan={2}
                className="rd-bottom  rd-top rd-left rd-right"
              >
                <ImageLogo
                  src={this.props?.drugList?.branch}
                  alt="Logo"
                  height="50px"
                  width="120px"
                />
              </th>
              <th colSpan={23} className=" rd-top rd-right">
                <Row>
                  <Col className="mt-05">Name : </Col>

                  <Col className="t-underline mt-05">
                    <div
                      className=" f-normal t-start"
                      style={{ minWidth: 150, paddingLeft: 10 }}
                    >
                      {this.props?.i18n === "th"
                        ? `${this.props?.drugList?.patient?.titleTh || ""}${[
                            this.props?.drugList?.patient?.firstNameTh,
                            this.props?.drugList?.patient?.lastNameTh,
                          ]
                            ?.join(" ")
                            ?.trim()}`
                        : `${this.props?.drugList?.patient?.titleEn || ""}${[
                            this.props?.drugList?.patient?.firstNameEn,
                            this.props?.drugList?.patient?.lastNameEn,
                          ]
                            ?.join(" ")
                            ?.trim()}`}
                    </div>
                  </Col>

                  <Col className="mt-05">HN:</Col>

                  <Col style={{ minWidth: 100 }} className="t-underline mt-05">
                    <div className=" f-normal t-center">
                      {`${this.props?.drugList?.patient?.hn || ""}`}
                    </div>
                  </Col>
                  <Col className="mt-05 ">Date of Birth: </Col>

                  <Col style={{ minWidth: 100 }} className="t-underline mt-05">
                    <div className=" f-normal t-center">
                      {this.props?.drugList?.patient?.dateOfBirth
                        ? `${
                            this.props?.i18n === "th"
                              ? dayjs(
                                  this.props?.drugList?.patient?.dateOfBirth
                                ).format("DD-MM-BBBB") || ""
                              : dayjs(
                                  this.props?.drugList?.patient?.dateOfBirth
                                ).format("DD-MM-YYYY") || ""
                          }`
                        : null}
                    </div>
                  </Col>

                  <Col className="mt-05">Age:</Col>

                  <Col span={2} className="t-underline mt-05">
                    <div className=" f-normal t-center">
                      {`${
                        Age(this.props?.drugList?.patient?.dateOfBirth || "") ||
                        ""
                      }`}
                    </div>
                  </Col>
                  <Col className="mt-05">Allergies: </Col>

                  <Col
                    style={{ minWidth: 130 }}
                    className="t-underline mt-05 t-red"
                  >
                    <div className=" f-normal t-center">
                      {`${[
                        this.props?.drugList?.patient?.drugAllergy,
                        this.props?.drugList?.patient?.foodAllergy,
                      ]
                        ?.join(" / ")
                        ?.trim()}`}
                    </div>
                  </Col>
                </Row>
              </th>
            </tr>
            <tr>
              <th colSpan={23} className="rd-right rd-bottom rd-background ">
                Medication Supplement Log
              </th>
            </tr>

            <tr>
              <th rowSpan={2} className="rd-left rd-right  rd-bottom">
                Name of Medication Supplement
              </th>
              <th rowSpan={2} className="rd-right rd-bottom">
                Directions
              </th>
              <th colSpan={3} className="rd-right rd-bottom">
                Before
              </th>
              <th
                colSpan={3}
                className="rd-right rd-bottom rd-background-after"
              >
                After
              </th>
              <th colSpan={3} className="rd-right rd-bottom">
                With meal
              </th>
              <th
                rowSpan={2}
                style={{ width: 3 }}
                className="rd-right rd-bottom"
              >
                As Needed
              </th>
              <th
                style={{ width: 3 }}
                rowSpan={2}
                className="rd-right rd-bottom"
              >
                Bed Time
              </th>
              <th rowSpan={2} className="rd-right rd-bottom">
                Special instructions
              </th>
              <th rowSpan={2} className="rd-right rd-bottom">
                Purpose
              </th>
              <th rowSpan={2} className="rd-right rd-bottom">
                Date started
              </th>
              <th rowSpan={2} className="rd-right rd-bottom">
                Date stopped
              </th>
              <th rowSpan={2} className="rd-right rd-bottom">
                Prescribed by
              </th>
              <th rowSpan={2} className="rd-right rd-bottom">
                Status
              </th>
            </tr>
            <tr>
              <th className="rd-right rd-rightTH rd-bottom">B</th>
              <th className="rd-right rd-rightTH rd-bottom">L</th>
              <th className="rd-right rd-rightTH rd-bottom">D</th>
              <th className="rd-right rd-rightTH rd-bottom rd-background-after">
                B
              </th>
              <th className="rd-right rd-rightTH rd-bottom rd-background-after">
                L
              </th>
              <th className="rd-right rd-rightTH rd-bottom rd-background-after">
                D
              </th>
              <th className="rd-right rd-rightTH rd-bottom">B</th>
              <th className="rd-right rd-rightTH rd-bottom">L</th>
              <th className="rd-right rd-rightTH rd-bottom">D</th>
            </tr>
          </thead>
          <tbody>
            {this.props?.drugList?.workOrderItem ? (
              this.props?.drugList?.workOrderItem?.map(
                (item: IReportDrug, index: number) => {
                  const option: IOptionDetail = JSON.parse(
                    item?.option || `{}`
                  );

                  return (
                    <tr key={index}>
                      <td className="rd-left rd-right rd-bottom t-start ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {option?.nameShown || item?.product?.nameTh || ""}
                        </div>
                      </td>
                      <td className="rd-right rd-bottom ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {option?.genericName || item?.product?.nameEn || ""}
                        </div>
                      </td>
                      {period?.map((itemPeriod, index) => {
                        return (
                          <td
                            key={index}
                            className={
                              index > 2 && index < 6
                                ? "rd-right rd-bottom rd-background-after"
                                : "rd-right rd-bottom "
                            }
                          >
                            <div
                              style={
                                item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                                  ? { color: "red" }
                                  : {}
                              }
                            >
                              {eatingPattern(
                                option?.eatingPattern || [],
                                itemPeriod?.name
                              )
                                ? (timeSpent(
                                    option?.timeSpent || [],
                                    itemPeriod?.key
                                  ) &&
                                    option?.quantityUsed) ||
                                  ""
                                : ""}
                            </div>
                          </td>
                        );
                      })}

                      <td className="rd-right rd-bottom ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {timeSpent(option?.eatingPattern || [], "asNeeded")
                            ? option?.quantityUsed || ""
                            : ""}
                        </div>
                      </td>

                      <td className="rd-right rd-bottom ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {timeSpent(option?.timeSpent || [], "night")
                            ? option?.quantityUsed || ""
                            : ""}
                        </div>
                      </td>
                      <td className="rd-right rd-bottom ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {option?.specialInstructions || ""}
                        </div>
                      </td>
                      <td className="rd-right rd-bottom ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {option?.purpose || ""}
                        </div>
                      </td>
                      <td className="rd-right rd-bottom ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {option?.dateStart
                            ? dayjs(option?.dateStart).format("DD-MM-YYYY")
                            : ""}
                        </div>
                      </td>
                      <td className="rd-right rd-bottom ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {option?.dateStop
                            ? dayjs(option?.dateStop).format("DD-MM-YYYY")
                            : ""}
                        </div>
                      </td>
                      <td className="rd-right rd-bottom ">
                        <div
                          style={
                            item?.drugStatus === DRUG_STATUS.DIS_CONTINUOUS
                              ? { color: "red" }
                              : {}
                          }
                        >
                          {item?.rejectConfirmBy?.role?.nameEn ===
                            ENUM_ROLE_EN.DOCTOR ||
                          item?.editBy?.role?.nameEn === ENUM_ROLE_EN.DOCTOR
                            ? "Dr."
                            : ""}

                          {this.props?.i18n === "th"
                            ? `${[
                                item?.rejectConfirmBy?.firstNameTh,
                                item?.rejectConfirmBy?.lastNameTh,
                              ]
                                ?.join(" ")
                                ?.trim()}` ||
                              `${[
                                item?.editBy?.firstNameTh,
                                item?.editBy?.lastNameTh,
                              ]
                                ?.join(" ")
                                ?.trim()}`
                            : `${[
                                item?.rejectConfirmBy?.firstNameEn,
                                item?.rejectConfirmBy?.lastNameEn,
                              ]
                                ?.join(" ")
                                ?.trim()}` ||
                              `${[
                                item?.editBy?.firstNameEn,
                                item?.editBy?.lastNameEn,
                              ]
                                ?.join(" ")
                                ?.trim()}`}
                        </div>
                      </td>
                      <td className="rd-right rd-bottom ">
                        <div
                          style={{ color: status?.[item?.drugStatus]?.color }}
                        >
                          {status?.[item?.drugStatus]?.name}
                        </div>
                      </td>
                    </tr>
                  );
                }
              )
            ) : (
              <tr>
                <td height="25px" className="rd-left rd-right rd-bottom  "></td>
                {new Array(17)?.fill("")?.map((_, index) => {
                  return <td key={index} className="rd-right rd-bottom "></td>;
                })}
              </tr>
            )}
          </tbody>
        </table>
      </>
    );
  }
}

export default Print;
