import { createContext, useContext } from "react";
import { IMedicineDoctorRoomContext } from "./interface";

const MainMedicineDoctorRoomContext = createContext<IMedicineDoctorRoomContext>(
  {} as IMedicineDoctorRoomContext
);

const MainMedicineDoctorRoomProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: IMedicineDoctorRoomContext;
}) => {
  return (
    <MainMedicineDoctorRoomContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </MainMedicineDoctorRoomContext.Provider>
  );
};

export const useMainMedicineDoctorRoom = () =>
  useContext(MainMedicineDoctorRoomContext);
export default MainMedicineDoctorRoomProvider;
