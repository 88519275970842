import { ICouponList } from "@interface/ICoupon.interface";
import { thrownResponse } from "./auth";
import { Elementary, IParamet } from "@interface/IGlobal.interface";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

export const useGetCouponList = (
  params?: any
): UseQueryResult<Elementary<any[]>> => {
  return useQuery(["get-coupon-list", params], async () => {
    const res = await axios.get(`/coupon`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetCouponByID = (
  id: number,
  enabled?: boolean,
  params?: any
): UseQueryResult<IParamet<ICouponList[]>> => {
  return useQuery(
    ["get-coupon-by-id", id],
    async () => {
      const res = await axios.get(`/coupon/${id}`, { params: params });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: enabled }
  );
};

export const useCreateCoupon = (): UseMutationResult => {
  return useMutation(["create-coupon"], async ({ data }: any) => {
    const res = await axios.post(`/coupon`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    thrownResponse(res);
  });
};

export const useUpdateCouponByID = (): UseMutationResult => {
  return useMutation(["update-coupon-by-id"], async ({ id, data }: any) => {
    const res = await axios.put(`coupon/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteCoupon = (): UseMutationResult => {
  return useMutation(["delete-coupon"], async (id: any) => {
    const res = await axios.delete(`/coupon/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    thrownResponse(res);
  });
};
