import { Age } from "@tools/age";
import { IItem } from "@interface/IOrder.interface";
import { dayjs } from "./timezone";
import { IGetVisits } from "@interface/IVisits.interface";
import { IInChartOrderList } from "@interface/IGlobal.interface";
import i18n from "i18next";

export const LabDetail = (
  orderList: IInChartOrderList,
  visitsByID?: IGetVisits
) => {
  const oneDayList = orderList?.oneDay?.flatMap((item: any) => {
    return item?.children?.[0]?.children?.flatMap((itemList: IItem) => {
      if (!!itemList?.children) {
        return itemList?.children?.flatMap((parent) => {
          return parent?.children?.flatMap((child) => {
            return {
              workOrderId: child?.id,
              template: child?.product?.fileType,
              course: true,
            };
          });
        });
      }
      return {
        workOrderId: itemList?.id,
        template: itemList?.product?.fileType,
      };
    });
  });

  const continuousList = orderList?.continuous?.flatMap((item: any) => {
    return item?.children?.[0]?.children?.flatMap((itemList: IItem) => {
      if (!!itemList?.children) {
        return itemList?.children?.flatMap((parent) => {
          return parent?.children?.flatMap((child) => {
            return {
              workOrderId: child?.id,
              template: child?.product?.fileType,
              course: true,
            };
          });
        });
      }
      return {
        workOrderId: itemList?.id,
        template: itemList?.product?.fileType,
      };
    });
  });

  const dataLab = [...(oneDayList || []), ...(continuousList || [])]?.map(
    (item: any) => {
      const mailingAddress = visitsByID?.patient?.mailingAddress;
      const address =
        mailingAddress?.addressTh || mailingAddress?.districtTh
          ? [
              mailingAddress[
                i18n.language === "th" ? "addressTh" : "addressEn"
              ],
              mailingAddress[
                i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
              ],
              mailingAddress[
                i18n.language === "th" ? "districtTh" : "districtEn"
              ],
              mailingAddress[
                i18n.language === "th" ? "provinceTh" : "provinceEn"
              ],
              mailingAddress?.country,
              mailingAddress?.zipcode,
            ]
              ?.join(" ")
              ?.trim()
          : "";

      if (item?.template === "CDSA") {
        return {
          ...item,
          data: {
            name: `${visitsByID?.patient?.firstNameTh || undefined} ${
              visitsByID?.patient?.lastNameTh || undefined
            }`,
            sex:
              visitsByID?.patient?.gender === "MALE"
                ? "male"
                : visitsByID?.patient?.gender === "FEMALE"
                ? "female"
                : undefined,
            date_of_birth: visitsByID?.patient?.dateOfBirth || undefined,
            address: address || undefined,
          },
        };
      } else if (item?.template === "HEMEMARK") {
        return {
          ...item,
          data: {
            first_name: visitsByID?.patient?.firstNameTh || undefined,
            last_name: visitsByID?.patient?.lastNameTh || undefined,
            sex:
              visitsByID?.patient?.gender === "MALE"
                ? "male"
                : visitsByID?.patient?.gender === "FEMALE"
                ? "female"
                : undefined, //male , female
            patien_id: visitsByID?.patient?.hn || undefined,
            phone: visitsByID?.patient?.mobile || undefined,
            date_of_birth:
              dayjs(visitsByID?.patient?.dateOfBirth).format("DD-MM-YYYY") ||
              undefined,
            address: address || undefined,
            ethncity: "chinese", // chinese ,maly ,indian, others
            others_text: undefined,
          },
        };
      } else if (item?.template === "HALLMARK") {
        return {
          ...item,
          data: {
            first_name: visitsByID?.patient?.firstNameTh || undefined,
            last_name: visitsByID?.patient?.lastNameTh || undefined,
            sex:
              visitsByID?.patient?.gender === "MALE"
                ? "male"
                : visitsByID?.patient?.gender === "FEMALE"
                ? "female"
                : undefined,
            patien_id: visitsByID?.patient?.hn || undefined,
            phone: visitsByID?.patient?.phone,
            date_of_birth:
              dayjs(visitsByID?.patient?.dateOfBirth).format("DD-MM-YYYY") ||
              undefined,
            address: address || undefined,
            ethncity: "malay",
            others_text: undefined,
          },
        };
      } else if (item?.template === "ENROLLMENT") {
        let idCardNumber = undefined;
        let passportNo = undefined;
        if (visitsByID?.patient?.idCard === 0) {
          idCardNumber = visitsByID?.patient?.idCardNumber || undefined;
        } else {
          passportNo = visitsByID?.patient?.idCardNumber || undefined;
        }
        return {
          ...item,
          data: {
            name: visitsByID?.patient?.firstNameTh || undefined,
            prefix:
              String(visitsByID?.patient?.titleEn).replaceAll(".", "") ||
              undefined, // Mr ,Mrs,Ms
            date_of_birth:
              dayjs(visitsByID?.patient?.dateOfBirth).format("DD-MM-YYYY") ||
              undefined,
            age:
              Age(visitsByID?.patient?.dateOfBirth || "").toString() ||
              undefined,
            id_card_number: idCardNumber || undefined,
            passport_no: passportNo || undefined,
            mailing_address: address || undefined,
            email: visitsByID?.patient?.email || undefined,
            phone: visitsByID?.patient?.phone || undefined,
          },
        };
      } else if (item?.template === "RETRIEVAL") {
        return {
          ...item,
          data: {
            name: `${visitsByID?.patient?.firstNameEn || undefined} ${
              visitsByID?.patient?.lastNameEn || undefined
            }`,
            prefix:
              String(visitsByID?.patient?.titleEn)?.replaceAll(".", "") ||
              undefined,
            date_request: "17-18-19",
            policy_number: "123456789",
            date_of_birth:
              dayjs(visitsByID?.patient?.dateOfBirth).format("DD-MM-YYYY") ||
              undefined,
            age:
              Age(visitsByID?.patient?.dateOfBirth || "")?.toString() ||
              undefined,
          },
        };
      } else if (item?.template === "QUESTIONNAIRE") {
        return {
          ...item,
          data: {
            date: dayjs().format("DD-MM-YYYY") || undefined,
            first_name: visitsByID?.patient?.firstNameTh || undefined,
            last_name: visitsByID?.patient?.lastNameTh || undefined,
            rv_lab_id: "17",
            sex:
              visitsByID?.patient?.gender === "MALE"
                ? "male"
                : visitsByID?.patient?.gender === "FEMALE"
                ? "female"
                : undefined,
            age:
              Age(visitsByID?.patient?.dateOfBirth || "")?.toString() ||
              undefined,
          },
        };
      } else if (item?.template === "RVLAB") {
        return {
          ...item,
          data: {
            date_of_birth:
              dayjs(visitsByID?.patient?.dateOfBirth).format("DD-MM-YYYY") ||
              undefined,
            name: `${visitsByID?.patient?.firstNameTh || undefined} ${
              visitsByID?.patient?.lastNameTh || undefined
            }`,
            sex:
              visitsByID?.patient?.gender === "MALE"
                ? "male"
                : visitsByID?.patient?.gender === "FEMALE"
                ? "female"
                : undefined,
            hn: visitsByID?.patient?.hn || undefined,
            hospital_clinic: "โรงบาล",
            ordering_physician: "sleep",
            diagnosis: "covid19",
          },
        };
      } else if (item?.template === "WINCELL") {
        return {
          ...item,
          data: {
            name: `${visitsByID?.patient?.firstNameTh || undefined} ${
              visitsByID?.patient?.lastNameTh || undefined
            }`,
            age:
              Age(visitsByID?.patient?.dateOfBirth || "").toString() ||
              undefined,
            sex:
              visitsByID?.patient?.gender === "MALE"
                ? "ชาย"
                : visitsByID?.patient?.gender === "FEMALE"
                ? "หญิง"
                : undefined,
            hn: visitsByID?.patient?.hn || undefined,
            date_of_birth:
              dayjs(visitsByID?.patient?.dateOfBirth).format("DD-MM-YYYY") ||
              undefined,
            hospital_clinic: "โรงบาล",
            ordering_physician: "sleep",
            diagnosis: "covid19",
          },
        };
      } else {
        return null;
      }
    }
  );

  return dataLab;
};
