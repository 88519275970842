import { useRef, useState, useEffect } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Col, Row } from "antd";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useUpdateInvoice } from "@services/api/invoice";
import { useGetInvoiceByID } from "@services/api/invoice";
import { ISignature, IVisitBill } from "@interface/IGlobal.interface";
import { KeyNameSignatureSecond } from "@interface/IGlobal.interface";
import { useHistory, useRouteMatch } from "react-router-dom";
import Print from "../print/Print";
import Header from "./Header";
import ModalInfo from "@components/signature/ModalInfo";

const ViewReport = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const match: any = useRouteMatch();
  const invoiceID = match.params.invoiceID;
  const invoiceIDList = useGetInvoiceByID(invoiceID);
  const client = useQueryClient();
  const updateInvoice = useUpdateInvoice();

  const [visible, setVisible] = useState<IVisitBill>({
    first: false,
    second: false,
  });

  const pageStyle = `  @page { size:A4;
    margin: 40px;}`;
  const printReportRef = useRef(null);
  const [divHeight, setDivHeight] = useState(
    document.getElementById("container")?.clientHeight
  );

  useEffect(() => {
    setDivHeight(document.getElementById("container")?.clientHeight);
    // eslint-disable-next-line
  }, [document.getElementById("container")?.clientHeight]);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  const updateSignature = (
    values: { date: string; title: KeyNameSignatureSecond } & ISignature
  ) => {
    updateInvoice.mutate(
      {
        id: invoiceID,
        data: values,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries(["get-invoice-ByID"]);
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
        onSettled: () => {
          setVisible({
            ...visible,
            [values?.title]: !visible[values?.title],
          });
        },
      }
    );
  };

  return (
    <div>
      <Header history={history} handlePrint={handlePrint} />

      <ReactToPrint content={() => printReportRef.current} />
      <div className="set-layout ">
        <div className="document-body ">
          <Row>
            <Col lg={24} className="display-center ">
              <div className="document-bill">
                <Row>
                  <Col lg={24} className="display-center ">
                    <Print
                      ref={printReportRef}
                      invoiceIDList={invoiceIDList?.data}
                      divHeight={divHeight}
                      visible={visible}
                      setVisible={setVisible}
                      i18n={i18n.language}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {visible.first ? (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={invoiceIDList?.data?.result?.[0]?.date || ""}
          name="first"
          updateSignature={updateSignature}
          isLoading={updateInvoice?.isLoading}
        />
      ) : visible.second ? (
        <ModalInfo
          visible={visible}
          setVisible={setVisible}
          date={invoiceIDList?.data?.result?.[0]?.date || ""}
          name="second"
          updateSignature={updateSignature}
          isLoading={updateInvoice?.isLoading}
        />
      ) : null}
    </div>
  );
};

export default ViewReport;
