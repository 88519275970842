import { Col, Row } from "antd";
import { FC, MutableRefObject } from "react";
import ReactToPrint from "react-to-print";
import DetailReport from "./Detail";

type Props = {
  printReportRef: MutableRefObject<null>;
};

const ReportBiologicalTherapy: FC<Props> = ({ printReportRef }) => {
  return (
    <div>
      <ReactToPrint content={() => printReportRef.current} />

      <div className="document-body ">
        <Row>
          <Col lg={24} className="display-center ">
            <div className="document-bill" style={{ height: 800 }}>
              <Row>
                <Col lg={24}>
                  <DetailReport refValue={printReportRef} />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ReportBiologicalTherapy;
