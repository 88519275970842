import { Radio } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BackMarker from "./Back";
import LeftMarker from "./Left";
import FrontMarker from "./Front";
import RightMarker from "./Right";

const Fields = () => {
  const obj: { [key: string]: { menu: JSX.Element } } = {
    "0": { menu: <FrontMarker /> },
    "1": { menu: <BackMarker /> },
    "2": { menu: <RightMarker /> },
    "3": { menu: <LeftMarker /> },
  };

  const [keyTap, setKeyTap] = useState("0");
  const { t } = useTranslation();

  return (
    <div className="m-1">
      <Radio.Group
        defaultValue={keyTap}
        onChange={(e) => setKeyTap(String(e.target.value))}
      >
        {[t("front"), t("behind"), t("sideRight"), t("sideLeft")]?.map(
          (item, index) => {
            return (
              <Radio.Button value={index.toString()} key={index.toString()}>
                {item}
              </Radio.Button>
            );
          }
        )}
      </Radio.Group>

      <div>{obj?.[keyTap]?.menu}</div>
    </div>
  );
};

export default Fields;
