import { dayjs } from "@tools/timezone";
import { ICanvasDraw } from "@interface/IImageCanvas";
import { useTranslation } from "react-i18next";
import { Col, Row, Button, Drawer } from "antd";
import { FC, MutableRefObject, useLayoutEffect, useRef, useState } from "react";
import Pencil from "./Pencil";
import Affixes from "@components/affixes/Affixes";
import saveImg from "@assets/img/icon/OPD/saveImg.svg";
import backward from "@assets/img/icon/OPD/backward.svg";
import deleteAll from "@assets/img/icon/OPD/deleteAll.svg";
import CanvasDraw from "react-canvas-draw";

type Props = {
  imageCanvasRef: MutableRefObject<ICanvasDraw | null>;
  image: string;
};

const ImageCanvas: FC<Props> = ({ imageCanvasRef, image }) => {
  const { t } = useTranslation();
  const canvasRef = useRef<ICanvasDraw>(null);
  const imageRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState(1);
  const [colorSelect, setColorSelect] = useState("black");
  const [visible, setVisible] = useState(false);

  useLayoutEffect(() => {
    // let text = buff.toString('ascii');
    // setValueCanvas();
  }, []);

  // const setValueCanvas = () => {
  //   var canvas = canvasRef.current?.canvas.drawing;
  //   canvas.width = window.innerWidth / 1.5;
  //   canvas.height = window.innerHeight / 1.5;
  //   let context = canvas.getContext("2d");
  //   context.scale(2, 2);
  //   context.lineCap = "round";
  //   context.lineWidth = 50;
  //   var image = new Image();
  //   image.src = imageValue;
  //   function mainLoop() {
  //     if (image.complete) {
  //       context?.drawImage(image, 0, 0);
  //     } else {
  //       context?.fillRect(0, 0, 776, 776);
  //     }
  //     requestAnimationFrame(mainLoop);
  //   }
  //   mainLoop();

  // };

  const onChangeImage = () => {
    // let canvas = stageRef.current.canvas.drawing;
    // let context = canvas.getContext("2d");
    // let data = context.getImageData(0, 0, canvas.width, canvas.height);

    // let imageData = canvasRef?.current?.canvas.drawing.toDataURL(
    //   "image/png",
    //   1.0
    // );

    // const data = canvasRef.current?.getSaveData();

    // imageCanvasRef.current = canvasRef.current;

    imageCanvasRef.current = canvasRef?.current;

    // setImageValue(canvasRef?.current?.getSaveData() || "");
  };

  const downloadImage = async () => {
    if (!canvasRef.current) return;
    const canvasRef2 = canvasRef;
    const width = canvasRef2.current?.props.canvasWidth as number;
    const height = canvasRef2.current?.props.canvasHeight as number;
    const background = canvasRef2.current?.canvasContainer?.children?.[0];
    let drawing = canvasRef2.current?.canvas.drawing;
    let canvas = document.createElement("canvas");

    var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

    canvas.width = width;
    canvas.height = height;

    ctx.fillStyle = "#fff";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx?.drawImage(background, 0, 0);
    ctx?.drawImage(drawing, 0, 0);

    let imageData = canvas?.toDataURL("image/jpg");

    // var canvas = canvasRef.current.canvas.drawing;
    // const background = canvasRef.current.canvasContainer?.children[0];
    // var context = canvas.getContext("2d");
    // var data = context.getImageData(0, 0, canvas.width, canvas.height);
    // var compositeOperation = context.globalCompositeOperation;
    // context.globalCompositeOperation = "destination-over";
    // context.fillStyle = "#fff";
    // context.drawImage(background, 0, 0);
    // context.fillRect(0, 0, canvas.width, canvas.height);
    // var imageData = canvasRef.current.canvas.drawing.toDataURL("image/png");
    // context.clearRect(0, 0, canvas.width, canvas.height);
    // context.putImageData(data, 0, 0);
    // context.globalCompositeOperation = compositeOperation;
    const a = document.createElement("a");
    a.href = imageData;
    a.download = `canvas${dayjs(new Date())}.png`;
    a.click();
  };

  const saveData = () => {
    // colorRef.current = colorSelect;
    // setColorSelect("#00000000");
    downloadImage();
  };

  const showDrawer = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <Row
        gutter={[14, 14]}
        className="m-1"
        align="middle"
        justify="space-between"
      >
        <Col lg={18} md={16}>
          <div ref={imageRef}>
            <CanvasDraw
              onChange={onChangeImage}
              ref={canvasRef}
              immediateLoading={true}
              saveData={imageCanvasRef?.current?.getSaveData() || ""}
              imgSrc={image}
              lazyRadius={0}
              canvasWidth={776}
              canvasHeight={776}
              catenaryColor="#00000000"
              brushColor={colorSelect}
              brushRadius={size}
              hideGrid={true}
              style={{
                boxShadow:
                  "0 13px 27px -5px rgba(50, 50, 93, 0.25),    0 8px 16px -8px rgba(0, 0, 0, 0.3)",
              }}
            />
          </div>
        </Col>
        <Col className="card-display">
          <Button
            className="mr-1 canvas"
            htmlType="submit"
            onClick={() => {
              canvasRef.current?.clear();
            }}
          >
            <img className="mr-05" src={deleteAll} alt="deleteAll" />
            {t("deleteAll")}
          </Button>
          <div className="mt-05">
            <Button
              className="mr-1 canvas"
              htmlType="submit"
              onClick={() => {
                canvasRef.current?.undo();
              }}
            >
              <img src={backward} alt="backward" />
            </Button>
          </div>

          <div>
            <Pencil
              setColorSelect={setColorSelect}
              setSize={setSize}
              size={size}
              rightOutlined={true}
            />
            <Row
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: 20,
              }}
            >
              <Button
                type="dashed"
                danger
                onClick={() => {
                  saveData();
                }}
              >
                <img className="mr-05" src={saveImg} alt="saveImg" />{" "}
                {t("savePNGImage")}
              </Button>
            </Row>
          </div>
        </Col>
      </Row>
      <Affixes onClick={showDrawer} offsetBottom={100} />

      <Drawer
        width={"30%"}
        closable={false}
        placement="right"
        onClose={showDrawer}
        visible={visible}
      >
        <Row align="middle" justify="center">
          <Col span={24}>
            <Button
              className="mr-1 canvas col-center-grid"
              htmlType="submit"
              onClick={() => {
                canvasRef.current?.clear();
              }}
            >
              <img className="mr-05" src={deleteAll} alt="deleteAll" />
              {t("deleteAll")}
            </Button>
            <div className="mt-05">
              <Button
                className="mr-1 canvas col-center-grid"
                htmlType="submit"
                onClick={() => {
                  canvasRef.current?.undo();
                }}
              >
                <img src={backward} alt="backward" />
              </Button>
            </div>

            <div>
              <Pencil
                setColorSelect={setColorSelect}
                setSize={setSize}
                size={size}
                rightOutlined={true}
              />
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingTop: 20,
                }}
              >
                <Button
                  type="dashed"
                  danger
                  onClick={() => {
                    saveData();
                  }}
                >
                  <img className="mr-05" src={saveImg} alt="saveImg" />{" "}
                  {t("savePNGImage")}
                </Button>
              </Row>
            </div>
          </Col>
        </Row>
      </Drawer>
    </div>
  );
};

export default ImageCanvas;
