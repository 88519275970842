import { useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Header from "./Header";
import ModalCompare from "./Modal";
import CompareInterface from "@components/compare/Compare";

const Compare = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [visible, setVisible] = useState(false);

  const onModal = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <Header history={history} onModal={onModal} />
      <div className="set-layout ">
        <CompareInterface
          title={<b className="header-card ">{t("compareLab")}</b>}
        />
      </div>
      {visible && (
        <ModalCompare
          visible={{ visible: visible, setVisible: setVisible }}
          onModal={onModal}
        />
      )}
    </div>
  );
};

export default Compare;
