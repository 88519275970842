import { ISearch } from "../inteface";
import { useTranslation } from "react-i18next";
import { ageRange } from "@assets/data/data";
import { Button, Col, DatePicker, Form, FormInstance, Row } from "antd";
import Selector from "@components/selector/Selector";

type Props = {
  onFinish: (value: ISearch) => void;
};

const Search = ({ onFinish }: Props) => {
  const { t } = useTranslation();

  const { RangePicker } = DatePicker;
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={6} lg={8}>
            <Form.Item shouldUpdate={(prev, next) => prev.type !== next.type}>
              {(form: FormInstance) => {
                const type = form.getFieldValue("type");
                if (type === "year") {
                  return (
                    <Form.Item name="start">
                      <RangePicker picker="year" format="YYYY" />
                    </Form.Item>
                  );
                } else if (type === "month") {
                  return (
                    <Form.Item name="start">
                      <RangePicker picker="month" format="MM/YYYY" />
                    </Form.Item>
                  );
                } else if (type === "day" || !type) {
                  return (
                    <Form.Item name="start">
                      <RangePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>
          </Col>

          <Col md={5} lg={5}>
            <Form.Item name="ageRange">
              <Selector
                placeholder={t("ageRange")}
                Source={ageRange}
                keyName="name"
                keyValue="id"
              />
            </Form.Item>
          </Col>

          <Col md={8} lg={4}>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
