import { ISearch } from "./interface";
import { useState } from "react";
import { IDocumentType } from "@interface/IDocumentType.interface";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { MEDICAL_DOCUMENT } from "@assets/data/pathRouter";
import { openNotification } from "@components/notification/Notification";
import { useGetDocumentType } from "@services/api/documents_type";
import { useDeleteDocumentType } from "@services/api/documents_type";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import moment from "moment";
import Modaler from "@components/modaler/Modaler";
import MoTable from "@components/table/Table";

type Props = {
  searchValue?: ISearch;
};

const TableTypeMedicalDocument = ({ searchValue }: Props) => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const history = useHistory<RouteComponentProps["history"]>();
  const documentType = useGetDocumentType({ ...searchValue });
  const deleteDocumentType = useDeleteDocumentType();
  const [visible, setVisible] = useState<boolean>(false);
  const [typeByID, setTypeByID] = useState<number>();

  const onDelete = () => {
    handleDeleteTypeMedicalDocument(typeByID);
  };

  const onClickRow = (event: IDocumentType) => {
    history.push(`${MEDICAL_DOCUMENT.type}/${event.id}`);
  };

  const handleDeleteTypeMedicalDocument = (id: number | undefined) => {
    deleteDocumentType.mutate(id, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        client.invalidateQueries(["get-document-type"]);
      },
      onError: (res) => {
        openNotification({ method: "DEL_ERROR", message: String(res) });
      },
      onSettled: () => {
        setVisible(false);
      },
    });
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "id",
      render: (_: string, data: any, index: number) => {
        return <div key={data?.id}>{index + 1}</div>;
      },
    },

    {
      title: t("creationDate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        return <div>{moment(createdAt).format("DD-MM-YYYY")}</div>;
      },
    },

    {
      title: `${t("type")} (TH)`,
      dataIndex: "nameTh",
      key: "nameTh",
    },

    {
      title: `${t("type")} (EN)`,
      dataIndex: "nameEn",
      key: "nameTh",
    },

    {
      title: t("manage"),
      dataIndex: "id",
      key: "id",
      align: "center",
      width: "10%",
      render: (id: number) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              setTypeByID(id);
              setVisible(true);
              e.stopPropagation();
            }}
          />
        );
      },
    },
  ];
  return (
    <div>
      <Modaler
        loadingButton={deleteDocumentType?.isLoading}
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {t("areYouSure")}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>

      <MoTable
        dataSource={
          documentType?.data?.result
            ? Array.isArray(documentType?.data?.result?.[0]) === true
              ? []
              : documentType?.data?.result?.[0]?.data
            : []
        }
        columns={columns}
        pagination={false}
        scroll={{ x: "calc(100vw -20%)" }}
        onClickRow={(e: IDocumentType) => onClickRow(e)}
      />
    </div>
  );
};

export default TableTypeMedicalDocument;
