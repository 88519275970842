import { IDocumentType } from "@interface/IDocumentType.interface";
import { thrownResponse } from "./auth";
import { Elementary, IParamet } from "@interface/IGlobal.interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetDocumentType = (
  params?: any
): UseQueryResult<Elementary<IDocumentType[]>> => {
  return useQuery(["get-document-type", params], async () => {
    const res = await axios.get(`/document-type`, { params: params });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetDocumentTypeByID = (
  id: number
): UseQueryResult<IParamet<IDocumentType[]>> => {
  return useQuery(
    ["get-document-type-by-id", id],
    async () => {
      const res = await axios.get(`/document-type/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    },
    { enabled: !id ? false : true }
  );
};

export const useCreateDocumentType = () => {
  return useMutation(["create-document-type"], async (data: any) => {
    const res = await axios.post(`/document-type`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateDocumentTypeByID = () => {
  return useMutation(["update-document-type"], async ({ id, data }: any) => {
    const res = await axios.put(`/document-type/${id}`, data);

    if (res?.status === 200) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteDocumentType = () => {
  return useMutation(async (id: number | undefined) => {
    const res = await axios.delete(`/document-type/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
