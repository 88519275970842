import ReactToPrint, { useReactToPrint } from "react-to-print";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { ISignature, IVisit } from "@interface/IGlobal.interface";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { useGetVisitsByID, useUpdateSignature } from "@services/api/visits";
import Header from "./Header";
import Print from "./print/Print";
import ModalConsent from "@components/signature/ModalConsent";

const ReportPhysicianOrder = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const { data } = useGetVisitsByID(visitID);
  const updateSignature = useUpdateSignature();
  const pageStyle = `  @page { size:210mm 297mm;
    margin: 70px;}`;
  const printReportRef = useRef(null);
  const history = useHistory<RouteComponentProps["history"]>();
  const [visible, setVisible] = useState<IVisit>({
    first: false,
    second: false,
    third: false,
  });
  const [signature, setSignature] = useState<ISignature>({
    firstSignature: "",
    secondSignature: "",
    thirdSignature: "",
    firstName: "",
    secondName: "",
    thirdName: "",
    firstDate: "",
    secondDate: "",
    thirdDate: "",
    signature: 0,
  });

  useEffect(() => {
    const firstSignature = JSON.parse(
      data?.visitPatientSignature?.[0]?.firstSignature ||
        `{"firstSignature":"","firstName":""}`
    );
    const secondSignature = JSON.parse(
      data?.visitPatientSignature?.[0]?.secondSignature ||
        `{"secondSignature":"","firstName":""}`
    );
    const thirdSignature = JSON.parse(
      data?.visitPatientSignature?.[0]?.thirdSignature ||
        `{"thirdSignature":"","firstName":""}`
    );

    setSignature({
      firstSignature: firstSignature?.firstSignature || "",
      secondSignature: secondSignature?.secondSignature || "",
      thirdSignature: thirdSignature?.thirdSignature || "",
      firstName: firstSignature?.firstName || "",
      secondName: secondSignature?.secondName || "",
      thirdName: thirdSignature?.thirdName || "",
      firstDate: firstSignature?.firstDate || "",
      secondDate: secondSignature?.secondDate || "",
      thirdDate: thirdSignature?.thirdDate || "",
      signature: 0,
    });

    // eslint-disable-next-line
  }, [data]);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  const handleUpDateSignature = () => {
    updateSignature.mutate(
      {
        id: visitID,
        data: {
          firstSignature: JSON.stringify({
            firstDate: signature.firstDate,
            firstName: signature.firstName,
            firstSignature: signature.firstSignature,
          }),
          secondSignature: JSON.stringify({
            secondDate: signature.secondDate,
            firstName: signature.secondName,
            secondSignature: signature.secondSignature,
          }),
          thirdSignature: JSON.stringify({
            thirdDate: signature.thirdDate,
            firstName: signature.thirdName,
            thirdSignature: signature.thirdSignature,
          }),
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };

  return (
    <div>
      <Header
        history={history}
        handlePrint={handlePrint}
        handleUpDateSignature={handleUpDateSignature}
      />
      <ReactToPrint content={() => printReportRef.current} />
      <div className="set-layout ">
        <div className="document-body  ">
          <Row>
            <Col lg={24} className="display-center ">
              <div className="document-consent  ">
                <Row>
                  <Col lg={24}>
                    <Print
                      refData={printReportRef}
                      visitsByID={data}
                      visible={visible}
                      setVisible={setVisible}
                      signature={signature}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {visible.first ? (
        <ModalConsent
          visible={visible}
          setVisible={setVisible}
          signature={signature}
          title={`${t("sign")}${t("nursery")}`}
          setSignature={setSignature}
          name="first"
        />
      ) : visible.second ? (
        <ModalConsent
          visible={visible}
          setVisible={setVisible}
          title={`${t("sign")}${t("nursery")}`}
          signature={signature}
          setSignature={setSignature}
          name="second"
        />
      ) : (
        visible.third && (
          <ModalConsent
            visible={visible}
            title={`${t("sign")}${t("nursery")}`}
            setVisible={setVisible}
            signature={signature}
            setSignature={setSignature}
            name="third"
          />
        )
      )}
    </div>
  );
};

export default ReportPhysicianOrder;
