import { Decimal } from "decimal.js";
import { discount } from "@assets/data/data";
import { ORDER_TYPE } from "@tools/util.enum";
import { productName } from "@tools/util";
import { useTranslation } from "react-i18next";
import { useRef, useState } from "react";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { IBilling, IItemProduct } from "@interface/IBill.interface";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Col, Form, FormInstance, Input, Row, Table } from "antd";
import decimal from "@tools/decimal";
import Selector from "@components/selector/Selector";
import EditModal from "@components/modaler/EditModal";
import BigNumber from "bignumber.js";

interface IProduct {
  orderItemId: number;
  addByCashier: boolean;
}

interface IWorkOrderItem {
  id?: string | undefined;
  groupTh?: string;
  groupEn?: string;
}
type Props = {
  deleteForm: FormInstance;
  value?: ElementaryOneMore<IBilling>;
  onChange?: (event: any) => void;
  draftBillForm: FormInstance;
  setProduct: (e: IProduct) => void;
  onModalDelete: () => void;
  medicalRecord: boolean;
};

const TableDraftBill = ({
  deleteForm,
  value,
  draftBillForm,
  setProduct,
  onModalDelete,
  medicalRecord,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState(false);
  const [workOrderItem, setWorkOrderItem] = useState<IWorkOrderItem>();
  const [editDiscount] = Form.useForm();
  const num = useRef(0);

  const onClick = () => {
    setVisible(!visible);
  };

  const handleUpdateDiscountDraftBill = (valueDiscount: any) => {
    try {
      const discountValue =
        valueDiscount.discountType === 1
          ? {
              discountPercent: valueDiscount?.discountPercent || "0",
              discount: 0,
            }
          : {
              discount: Number(valueDiscount.discount || 0),
              discountPercent: 0,
            };
      let netPrice = new Decimal(0);
      const orderForm = value?.result?.[0]?.order?.groups?.map((item) => {
        if (
          workOrderItem?.groupTh === item?.name ||
          workOrderItem?.groupEn === item?.name
        ) {
          let net = new Decimal(0);
          const format = {
            ...item,

            children: item?.children?.map((client) => {
              return {
                ...client,

                children: client?.children?.map((clientOrder) => {
                  if (clientOrder?.item?.id === workOrderItem?.id) {
                    const calNet =
                      valueDiscount?.discountType === 2
                        ? new Decimal(clientOrder?.item?.total || 0).plus(
                            discountValue?.discount
                          )
                        : new Decimal(clientOrder?.item?.total || 0).plus(
                            new Decimal(clientOrder?.item?.total || 0)
                              .dividedBy(100)
                              .mul(discountValue?.discountPercent || 0)
                          );
                    net = new Decimal(net).plus(calNet);

                    return {
                      ...clientOrder,
                      item: {
                        ...clientOrder?.item,
                        discountType:
                          valueDiscount?.discountType === 1 ? `%` : `฿`,
                        ...discountValue,
                        net: calNet,
                      },
                    };
                  } else {
                    net = new Decimal(net).plus(clientOrder?.item?.net || 0);
                    return clientOrder;
                  }
                }),
              };
            }),
            net: net,
          };
          netPrice = new Decimal(netPrice).plus(net);
          return format;
        } else {
          netPrice = new Decimal(netPrice).plus(item?.net);
          return item;
        }
      });
      let discount = draftBillForm.getFieldValue("discount");
      if (netPrice !== new Decimal("0.00")) {
        const discountPercent = draftBillForm.getFieldValue("discountPercent");
        if (discountPercent) {
          discount = Decimal.mul(
            Decimal.div(new Decimal(discountPercent), new Decimal("100.00")),
            netPrice
          );
        }
      }
      const billByIdOrder = draftBillForm.getFieldValue("billByIdOrder");

      draftBillForm?.setFieldsValue({
        ...draftBillForm.getFieldsValue(),
        billByIdOrder: {
          ...billByIdOrder,
          result: [
            {
              ...billByIdOrder?.result?.[0],
              order: {
                ...billByIdOrder?.order,
                groups: orderForm,
              },
            },
          ],
        },
        discount: discount,
        netPrice: netPrice,
      });
      onClick();
    } catch (err) {
      console.log(err);
    }
  };
  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, data: IItemProduct, index: number) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? index + 1
              : data?.level === 2
              ? ""
              : data?.level === 3
              ? ""
              : data?.level === 4
              ? ""
              : data?.level === 5
              ? ""
              : index + 1}
          </div>
        );
      },
    },
    {
      title: `${t("productName")} / ${t("details")}`,
      dataIndex: "nameEn",
      key: "index",
      render: (_: any, data: IItemProduct) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? t(`${data?.name}`)
              : data?.level === 2
              ? t("productName")
              : data?.level === 3
              ? productName(
                  i18n.language,
                  data?.item?.product?.nameTh,
                  data?.item?.product?.nameEn
                )
              : data?.level === 4
              ? t("productName")
              : data?.level === 5
              ? productName(
                  i18n.language,
                  data?.item?.product?.nameTh,
                  data?.item?.product?.nameEn
                )
              : t(`${data?.name}`)}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "name",
      key: "name",

      render: (_: string, data: IItemProduct) => {
        return (
          <>
            {data?.level === 1
              ? ``
              : data?.level === 2
              ? t("type")
              : data?.level === 3
              ? data?.item?.type === ORDER_TYPE.ONE_DAY
                ? `One Day`
                : data?.item?.type === ORDER_TYPE.CONTINUE
                ? `Continuous`
                : `-`
              : ""}
          </>
        );
      },
    },
    {
      title: t("amount"),
      dataIndex: "qty",
      render: (qty: any, data: IItemProduct) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? data?.qty
              : data?.level === 2
              ? t("amount")
              : data?.level === 3
              ? data?.item?.qty
              : data?.level === 4
              ? ""
              : data?.level === 5
              ? ""
              : qty}
          </div>
        );
      },
    },

    {
      title: t("unitPrice"),
      dataIndex: "unitPrice",
      render: (_: any, data: IItemProduct) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("unitPrice")
              : data?.level === 3
              ? decimal(data?.item?.normal || 0)
              : data?.level === 4
              ? ""
              : data?.level === 5
              ? ""
              : ""}
          </div>
        );
      },
    },

    {
      title: t("totalPrice"),
      dataIndex: "total",
      render: (total: any, data: IItemProduct) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? decimal(Number(data?.total))
              : data?.level === 2
              ? t("totalPrice")
              : data?.level === 3
              ? decimal(data?.item?.total)
              : data?.level === 4
              ? t("amount")
              : data?.level === 5
              ? data?.item?.qty
              : decimal(total)}
          </div>
        );
      },
    },

    {
      title: t("discounts"),
      dataIndex: "unitPrice",
      render: (_: any, data: IItemProduct) => {
        return (
          <div key={data?.key}>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 ? (
              t("discounts")
            ) : data?.level === 3 ? (
              <div>
                {data?.item?.discountPercent
                  ? `${data?.item?.discountPercent || ""} % `
                  : `${new BigNumber(data?.item?.discount || 0).toFormat(
                      2
                    )} ฿ `}
                {medicalRecord ? (
                  <EditOutlined
                    onClick={(e) => {
                      onClick();
                      editDiscount.resetFields();
                      data?.item?.discountPercent
                        ? editDiscount.setFieldsValue({
                            price: data?.item?.total,
                            discountPercent: data?.item?.discountPercent || "",
                            discountType: 1,
                          })
                        : editDiscount.setFieldsValue({
                            price: data?.item?.total,
                            discount: data?.item?.discount || "",
                            discountType: 2,
                          });

                      setWorkOrderItem({
                        id: data?.item?.id,
                        groupEn: data?.item?.product?.category?.nameEn,
                        groupTh: data?.item?.product?.category?.nameTh,
                      });
                      e.stopPropagation();
                    }}
                  />
                ) : null}
              </div>
            ) : (data?.level === 3 &&
                data?.item?.product?.category?.id !== "C-0001") ||
              data?.item?.product?.category?.id !== "C-0006" ? (
              <div>
                {data?.item?.discountPercent
                  ? `${data?.item?.discountPercent || ""} % `
                  : data?.item?.discount
                  ? `${new BigNumber(data?.item?.discount || 0).toFormat(2)} ฿ `
                  : ""}
              </div>
            ) : data?.level === 4 ? (
              ""
            ) : data?.level === 5 ? (
              ""
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      title: t("discountedPrice"),
      dataIndex: "total",
      render: (total: any, data: IItemProduct) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? decimal(data?.net || 0)
              : data?.level === 2
              ? t("discountedPrice")
              : data?.level === 3
              ? decimal(data?.item?.net)
              : data?.level === 4
              ? ""
              : data?.level === 5
              ? ""
              : decimal(total)}
          </div>
        );
      },
    },

    medicalRecord
      ? {
          title: "",
          dataIndex: "total",
          render: (_: any, data: IItemProduct) => {
            return (
              <div key={data?.key}>
                {data?.level === 1 ? (
                  ""
                ) : data?.level === 2 ? (
                  t("manage")
                ) : data?.level === 3 ? (
                  <DeleteOutlined
                    style={{ color: "#ec8a0a" }}
                    onClick={(e) => {
                      deleteForm.resetFields();
                      onModalDelete();
                      setProduct({
                        orderItemId: data?.item?.id,
                        addByCashier: data?.item?.addByCashier,
                      });
                      e.stopPropagation();
                    }}
                  />
                ) : data?.level === 4 ? (
                  ""
                ) : data?.level === 5 ? (
                  ""
                ) : (
                  ""
                )}
              </div>
            );
          },
        }
      : {},
  ];

  return (
    <div>
      <EditModal
        visible={visible}
        onCancel={onClick}
        editOrder={editDiscount}
        width="35%"
        header={<div>{t("modifyTheNumberOfProducts")}</div>}
      >
        <Form
          onFinish={handleUpdateDiscountDraftBill}
          name="editOrderQty"
          form={editDiscount}
        >
          <Row>
            <Col md={19} lg={19}>
              <Form.Item
                shouldUpdate={(prev, next) =>
                  prev.discountType !== next.discountType
                }
              >
                {(form: FormInstance) => {
                  const discountType = form.getFieldValue("discountType");

                  if (discountType === 1) {
                    return (
                      <Form.Item
                        name="discountPercent"
                        label={t("discounts")}
                        rules={[
                          () => ({
                            validator(_, value) {
                              if (!/^(-$)|\d+$/.test(value)) {
                                return Promise.reject(
                                  new Error(t("enterOnlyNumbers"))
                                );
                              } else if (
                                !(Number(value) >= -100 && Number(value) <= 100)
                              ) {
                                return Promise.reject(
                                  new Error(
                                    `${t("APercentageOfNotLessThan")} -100 ${t(
                                      "andNotMore"
                                    )} 100!`
                                  )
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input className="order-summary" />
                      </Form.Item>
                    );
                  } else
                    return (
                      <Form.Item
                        name="discount"
                        label={t("discounts")}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (!/^(-$.$)|\d+$/.test(value)) {
                                return Promise.reject(
                                  new Error(t("enterOnlyNumbers"))
                                );
                              } else if (
                                Number(value) >= Number(-getFieldValue("price"))
                              ) {
                                return Promise.resolve();
                              } else {
                                return Promise.reject(
                                  new Error(
                                    `${t(
                                      "theNumberOfProductsIsNotLessThan"
                                    )} ${Number(-getFieldValue("price"))} ${t(
                                      "andNotMore"
                                    )} ${Number(getFieldValue("price"))}`
                                  )
                                );
                              }
                            },
                          }),
                        ]}
                      >
                        <Input className="order-summary" />
                      </Form.Item>
                    );
                }}
              </Form.Item>
            </Col>

            <Col md={5} lg={5} style={{ paddingTop: "48px" }}>
              <Form.Item
                rules={[{ required: true, message: t("pleaseSelect") }]}
                label=""
                name="discountType"
              >
                <Selector
                  bordered={false}
                  className="select-search text-select"
                  placeholder={t("selectItem")}
                  Source={discount}
                  keyName="name"
                  keyValue="id"
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </EditModal>

      <div className="mt-1 mb-1 ">
        <Table
          className="components-table-demo-nested table-selection table-confirm   table-list-children no-cursor"
          rowKey="key"
          rowClassName={(r) => {
            if (r?.level === 1) {
              return "no-header";
            } else if (r?.level === 2) {
              return "table-header no-header ";
            } else if (r?.level === 3 && r?.children) {
              return "no-header";
            } else if (r?.level === 5) {
              num.current = num?.current - 1;
              if (num?.current === 0) {
                return "table-confirm-first table-confirm-level-course table-bottom--level-course-num no-header";
              } else {
                return "table-confirm-first table-confirm-level-course no-header ";
              }
            } else if (r?.level === 4) {
              num.current = r?.children?.length;
              return "table-header no-header table-confirm-level-course table-top-level-course ";
            }
            return "no-header";
          }}
          columns={columns}
          dataSource={value?.result?.[0]?.order?.groups}
          scroll={{ x: 500 }}
          expandedRowKeys={value?.result?.[0]?.allKey || []}
          pagination={false}
        />
      </div>
    </div>
  );
};

export default TableDraftBill;
