import { Popover } from "antd";
import { TextBRHTML } from "@tools/util";
import { useTranslation } from "react-i18next";
import { APPOINTMENT_STATUS } from "@tools/util.enum";
import { useGetAppointments } from "@services/api/appointments";
import { useEffect, useState } from "react";
import MoTable from "../table/Table";

type Props = {
  slotID: number;
};

const Record = ({ slotID }: Props) => {
  const { t, i18n } = useTranslation();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const appointmentList = useGetAppointments();

  useEffect(() => {
    setTotal(appointmentList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [appointmentList?.data]);

  useEffect(() => {
    appointmentList?.mutate({
      limit: limitPage,
      page: currentPage,
      slotId: slotID,
      status: [APPOINTMENT_STATUS.CANCEL, APPOINTMENT_STATUS.CONFIRM],
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, limitPage]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const columns: any = [
    {
      title: t("queue"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: any, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "keyHN",
      width: "10%",
      sorter: (a: any, b: any) => a?.id - b?.id,
      render: (item: any) => {
        return <div key={item?.id}>{item?.hn}</div>;
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "patient",
      render: (item: any) => {
        const name =
          i18n.language === "th"
            ? [item?.firstNameTh, item?.lastNameTh]
            : [item?.firstNameEn, item?.lastNameEn]?.join(" ")?.trim();
        return (
          <div className="f-normal " key={item?.id}>
            {name}
          </div>
        );
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "patient",
      key: "mobile",
      render: (item: any) => {
        return <div key={item?.id}>{item?.mobile}</div>;
      },
    },

    {
      title: t("status"),
      dataIndex: "status",
      key: "status",

      render: (status: any) => {
        let color;
        if (status === APPOINTMENT_STATUS.ADVISED) {
          color = "#00377C";
        } else if (status === APPOINTMENT_STATUS.ADVISING) {
          color = "#77C48B";
        } else if (status === APPOINTMENT_STATUS.WAITING_FOR_TREATMENT) {
          color = "#7AC692";
        } else if (status === APPOINTMENT_STATUS.WAITING_FOR_ADVICE) {
          color = "#498DCB";
        } else if (status === APPOINTMENT_STATUS.CANCEL) {
          color = "#FF5D5D";
        } else if (status === APPOINTMENT_STATUS.BOOK_IN_ADVANCE) {
          color = "#989898";
        } else if (status === APPOINTMENT_STATUS.CONFIRM) {
          color = "#014C97";
        }

        return (
          <div
            className="table-queue-status mr-1"
            style={{ backgroundColor: color }}
          >
            {status === APPOINTMENT_STATUS.WAITING_FOR_ADVICE
              ? t("waitConsult")
              : status === APPOINTMENT_STATUS.ADVISING
              ? t("consulting")
              : status === APPOINTMENT_STATUS.WAITING_FOR_TREATMENT
              ? t("waitingForTreatment")
              : status === APPOINTMENT_STATUS.ADVISED
              ? t("consulted")
              : status === APPOINTMENT_STATUS.CANCEL
              ? t("cancel")
              : status === APPOINTMENT_STATUS.BOOK_IN_ADVANCE
              ? t("bookAdvance")
              : status === APPOINTMENT_STATUS.CONFIRM && t("เสร็จสิ้น")}
          </div>
        );
      },
    },

    {
      title: t("remarks"),
      dataIndex: "detail",
      key: "detail",
      render: (detail: any) => {
        return (
          <Popover
            content={
              <div
                dangerouslySetInnerHTML={{ __html: TextBRHTML(detail || "-") }}
              />
            }
          >
            <div className="text-content">{detail}</div>
          </Popover>
        );
      },
    },
    {
      title: t("manage"),
      dataIndex: "editBy",
      key: "editBy",
      render: (editBy: any) => {
        return (
          <div className="text-content">
            {`${
              i18n.language === "th"
                ? [editBy?.firstNameTh, editBy?.lastNameTh]?.join(" ")?.trim()
                : [editBy?.firstNameEn, editBy?.lastNameEn]?.join(" ")?.trim()
            }`}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <MoTable
        dataSource={appointmentList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default Record;
