import { remarkSender } from "@assets/data/data";
import { FormInstance } from "antd/es/form";
import { useTranslation } from "react-i18next";
import { Col, Form, Input, InputNumber, Modal, Row } from "antd";
import { FC, useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import Editable from "@components/table/Editable";
import {
  useGetGoodsReceiptBySONumber,
  useGetWarehouseSaleOrderById,
} from "@services/api/warehouse_sale_order";
import {
  RouteComponentProps,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import moment from "moment";
import { useCreateWarehouseGoodsReceipt } from "@services/api/goods-receipt";
import { openNotification } from "@components/notification/Notification";
import { ColumnsType } from "antd/lib/table";
import { SETTING } from "@assets/data/pathRouter";
import BigNumber from "bignumber.js";

type Props = {
  managePickUpGoods: FormInstance;
  visible?: boolean;
  salesReturn?: boolean;
};

const FieldsPickUpGoods: FC<Props> = ({
  managePickUpGoods,
  visible,
  salesReturn,
}) => {
  const { t, i18n } = useTranslation();
  const [form] = Form.useForm();
  const [select, setSelect] = useState<Array<any>>([]);
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const saleOrderById = match.params.id;
  const pickUpGoodsById = useGetWarehouseSaleOrderById(saleOrderById);
  const info: any = pickUpGoodsById?.data?.result?.[0];
  const goodsReceiptBySONumber = useGetGoodsReceiptBySONumber(info?.soNumber);
  const createGoodsReceipt = useCreateWarehouseGoodsReceipt();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    form.submit();
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (!info) return;
    managePickUpGoods.setFieldsValue({
      ...info,
      date: moment(info?.createdAt).format("DD/MM/YYYY"),
      soNumber: info.soNumber,
      address: info.prepareOrder.purchaseOrder.branch.addressFull,
      remarkSender: remarkSender,
      netPrice: info.total,
    });
    if (info?.status === "SENDING_COMPLETE") {
      setSelect(
        info?.saleOrderItems?.map((item: any) => {
          const gsItem =
            goodsReceiptBySONumber?.data?.result?.[0]?.goodsReceiptItems?.find(
              (gs: any) => gs?.product?.sku === item?.product?.sku
            );
          return {
            ...item,
            ...{
              ...item?.product,
              canGive: gsItem?.qty,
              remark: gsItem?.remark,
            },
          };
        })
      );
    } else {
      setSelect(
        info?.saleOrderItems?.map((item: any) => {
          return {
            ...item,
            ...item?.product,
          };
        })
      );
    }

    // eslint-disable-next-line
  }, [info, goodsReceiptBySONumber?.data]);

  const onFinish = (values: any) => {
    createGoodsReceipt.mutate(
      {
        soNumber: info.soNumber,
        poNumber: info.prepareOrder.poNumber,
        salesReturn: salesReturn,
        goodsReceiptItems: select.map((item) => {
          return {
            qty: item.canGive,
            lossQty: item.qty - item.canGive,
            remark: item.remark || "-",
            sku: item.sku,
            qtyDesired: item.qty,
          };
        }),
      },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS", message: "success" });
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
        onSettled: () => {
          history.replace(`${SETTING.wareHouse}`);
        },
      }
    );
  };

  const onFinishModal = (values: any) => {
    setSelect(
      select.map((e: any) => {
        if (values.id === e.id) {
          return { ...e, ...values };
        }
        return e;
      })
    );
  };

  const sharedOnCell = (row: any | any, index: number) => {
    if (row?.remark) {
      return { colSpan: 0 };
    }

    return {};
  };

  const Columns: ColumnsType<any> | any = [
    {
      title: "#",
      dataIndex: "key",
      onCell: (d: any, _index: number) => {
        return {
          colSpan: d?.remark ? 9 : 1,
        };
      },

      render: (ind: number, row: any | any, i: number) => {
        return i + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "group",
      onCell: sharedOnCell,
      render: (_: any, row: any) => {
        return row?.group.sku;
      },
    },
    {
      title: "Sub SKU",
      dataIndex: "sku",
      render: (sku: any) => {
        return sku;
      },
    },
    {
      title: t("productName"),
      dataIndex: "product",
      onCell: sharedOnCell,

      render: (_: any, row: any) => {
        return (
          <div>
            <div>
              {i18n.language === "th"
                ? row?.nameTh || row?.nameEn
                : row?.nameEn || row?.nameTh}
            </div>
            <div style={{ color: "#757783", fontSize: 12 }}>{row?.sku}</div>
          </div>
        );
      },
    },
    {
      title: t("desiredQuantity"),
      width: 250,
      dataIndex: "qty",
    },
    {
      title: `${t("totalPrice")} (฿)`,
      dataIndex: "total",

      render: (total: string) => {
        return total;
      },
    },

    {
      title: t("receive"),
      dataIndex: "canGive",
      require: true,
      onfocus: true,
      width: 250,
      onCell: sharedOnCell,

      render: (data: any, row: any) => {
        return (
          <Row justify="space-between" align="middle">
            <Col>
              <Row>
                <Col span={24}>{data || 0}</Col>
                <Col
                  span={24}
                  style={{
                    display: data > row.qty || row.remark ? "block" : "none",
                  }}
                >
                  <span style={{ color: "red" }}>*</span> หมายเหตุ :
                  {data > row.qty && (
                    <span
                      style={{ color: "red", marginLeft: 5 }}
                    >{`สินค้าไม่ครบจากจำนวนที่สั่งจากเดิม ${row.qty} ชิ้น แต่ได้เพียง ${data} ชิ้น`}</span>
                  )}
                  {row.remark && (
                    <span
                      style={{ color: "red", marginLeft: 5 }}
                    >{`${row.remark}`}</span>
                  )}
                </Col>
              </Row>
            </Col>
            <Col>
              {visible ? (
                <span
                  onClick={() => {
                    showModal();
                    form.setFieldsValue({
                      id: row.id,
                      qty: row.qty,
                      canGive: data || 0,
                      remark: row.remark || "",
                    });
                  }}
                  style={{
                    color: "#498dcb",
                  }}
                >
                  แก้ไข
                </span>
              ) : null}
            </Col>
          </Row>
        );
      },
    },
    {
      title: t("unit"),
      dataIndex: "product",
      render: (_: any, row: any) => {
        return row?.unit || "ชิ้น";
      },
    },
  ];

  return (
    <div>
      <Form form={managePickUpGoods} onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col span={12}>
            <Form.Item
              name="soNumber"
              label={t("soNumber")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="date"
              label={t("orderDate")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="remarkSender"
              label={t("remarkSender")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="address"
              label={t("orderDetails")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24} className="header-card">
            {t("productDetails")}
          </Col>

          <Col span={24}>
            <Editable
              rowKey="id"
              scroll={{ x: 1100 }}
              defaultColumns={Columns}
              dataSource={select}
              onSave={(value: any) => {
                setSelect(value);
              }}
            />
          </Col>

          <Col span={12}>
            <Form.Item
              label={t("remarks")}
              name="order detail"
              // rules={[{ required: true }]}
            >
              <TextArea
                autoSize={{ minRows: 3, maxRows: 4 }}
                disabled={!visible}
              />
            </Form.Item>
          </Col>

          <Col style={{ marginLeft: "auto" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ color: "#757783" }} className="pr-1 ">
                {t("theTotalAmount")}
              </div>
              <div
                style={{
                  background: "#F5F5F5",
                  borderRadius: 5,
                  fontSize: 16,
                  height: 42,
                  width: 200,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                className="pl-1 pr-1"
              >
                <Form.Item
                  shouldUpdate={(prev, next) => prev.netPrice !== next.netPrice}
                >
                  {(form: FormInstance) => {
                    const netPrice = form.getFieldValue("netPrice");
                    return (
                      <>
                        <span>฿</span>
                        <span style={{ marginLeft: "5px" }}>
                          {new BigNumber(netPrice || 0).toFormat(2)}
                        </span>
                      </>
                    );
                  }}
                </Form.Item>
              </div>
            </div>
          </Col>
        </Row>
      </Form>
      <Modal
        visible={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
        style={{
          top: -50,
        }}
      >
        <Form form={form} onFinish={onFinishModal}>
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Form.Item name="qty" label={t("desiredQuantity")}>
                <InputNumber readOnly />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                shouldUpdate={(prev, cur) => prev.canGive !== cur.canGive}
              >
                {(_form: FormInstance) => {
                  const qty = form.getFieldValue("qty");
                  return (
                    <Form.Item name="canGive" label="จำนวนที่เตรียมได้">
                      <InputNumber min={0} max={qty} />
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
            <Col span={24}>
              <Col
                span={24}
                style={{
                  display: true ? "block" : "none",
                }}
              >
                <Form.Item
                  shouldUpdate={(prev, cur) => prev.canGive !== cur.canGive}
                >
                  {(_form: FormInstance) => {
                    const canGive = form.getFieldValue("canGive");
                    const qty = form.getFieldValue("qty");
                    if (canGive >= qty) {
                      form.setFieldsValue({ remark: undefined });
                    }
                    return (
                      <Form.Item
                        name="remark"
                        rules={[
                          { required: canGive < qty, message: "หมายเหตุ!" },
                        ]}
                        label="หมายเหตุ"
                      >
                        <Input.TextArea
                          rows={4}
                          style={{
                            marginTop: 5,
                          }}
                          placeholder={"..."}
                          disabled={canGive >= qty}
                        />
                      </Form.Item>
                    );
                  }}
                </Form.Item>
              </Col>
            </Col>
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
          </Row>
        </Form>
      </Modal>
    </div>
  );
};

export default FieldsPickUpGoods;
