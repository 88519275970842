import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Col, Row } from "antd";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { compareLabState } from "@tools/recoil/recoil-atom";
import { useGetLabCompare } from "@services/api/lab";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Print from "@components/compare/print/Print";
import Header from "./Header";

const PrintCompare = () => {
  const { i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const printReportRef = useRef(null);
  const pageStyle = `  @page { size:210mm 297mm;
    margin: 40px;}`;
  const match: any = useRouteMatch();
  const compareLab = useRecoilValue(compareLabState);
  const patientID = match.params.patientID;
  const compare = useGetLabCompare(patientID, true, {
    startDate: compareLab.startDate,
    endDate: compareLab.endDate,
    orderby: ENUM_ORDERBY.DESC,
  });

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Header history={history} handlePrint={handlePrint} />
      <ReactToPrint content={() => printReportRef.current} />

      <div
        style={{ justifyContent: "center", display: "flex" }}
        className="set-layout  "
      >
        <div className="document-bill">
          <Row justify="center">
            <Col span={24}>
              <Print
                ref={printReportRef}
                compare={compare?.data}
                i18n={i18n.language}
                startDate={compareLab.startDate}
              />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default PrintCompare;
