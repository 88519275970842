import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { IGetVisits } from "@interface/IVisits.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { INTERFACE_LAB } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useGetPatientByHistory } from "@services/api/patient";
import { Button, Card, Col, Row } from "antd";
import { useGetLAbInterfaceHistory } from "@services/api/visits";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import Search from "./Search";
import moment from "moment";
import MoTable from "@components/table/Table";
import ImagePatient from "@components/image/ImagePatient";

type Props = {
  history: RouteComponentProps["history"];
};

const Fields = ({ history }: Props) => {
  const { t, i18n } = useTranslation();
  const [searchValue, setSearchValue] = useState<any>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const visitsList = useGetLAbInterfaceHistory(patientID, {
    page: currentPage,
    limit: limitPage,
    orderby: searchValue?.sort
      ? searchValue?.sort === 1
        ? ENUM_ORDERBY.DESC
        : ENUM_ORDERBY.ASC
      : ENUM_ORDERBY.DESC,

    date: searchValue?.date,
  });
  const patientByID = useGetPatientByHistory(patientID);

  useEffect(() => {
    setTotal(visitsList?.data?.result?.[0]?.total || 1);

    // eslint-disable-next-line
  }, [visitsList?.data]);

  const onFinish = (value: any) => {
    setSearchValue({
      ...value,
      date: value?.date ? moment(value?.date).format("YYYY-MM-DD") : undefined,
    });
    setCurrentPage(1);
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: IGetVisits) => {
    history.push(
      `${INTERFACE_LAB.interfaceDetails}/patient/${patientID}/visit/${event.id}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: `${t("creationDate")}`,
      dataIndex: "date",
      key: "date",
      render: (date: string) => {
        return <>{moment(date).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: `${t("visitCode")}  `,
      dataIndex: "id",
      key: "id",
    },

    {
      title: t("doctorName"),
      dataIndex: "mobile",
      key: "mobile",
      render: (mobile: string) => {
        return <>{mobile ? mobile : "-"}</>;
      },
    },
  ];

  return (
    <div>
      <Card
        extra={
          <Row justify="space-between" align="middle">
            <Col lg={19}>
              <>
                <Row
                  justify="center"
                  gutter={[14, 14]}
                  className="text-row-profile ml-05"
                  align="middle"
                >
                  <Col xs={4} md={4} lg={3} xl={2}>
                    <ImagePatient src={patientByID?.data?.image} />
                  </Col>
                  <Col>
                    <div>
                      {i18n.language === "th"
                        ? ` ${patientByID?.data?.firstNameTh || " "}    ${
                            patientByID?.data?.lastNameTh || " "
                          }`
                        : ` ${patientByID?.data?.firstNameEn || " "}    ${
                            patientByID?.data?.lastNameEn || " "
                          }`}
                    </div>

                    <div className="code-profile ">
                      HN :{patientByID?.data?.hn}
                    </div>
                  </Col>
                  <Col>
                    <span className="mr-05">{`${t("age")} :`} </span>
                    <span className="t-grey-light">
                      {Age(patientByID?.data?.dateOfBirth || "")}
                    </span>
                  </Col>

                  <Col>
                    <span className="mr-05">{`${t("sex")} :`} </span>
                    <span className="t-grey-light">
                      {gender(patientByID?.data?.gender, i18n?.language)}
                    </span>
                  </Col>

                  <Col>
                    <span className="mr-05">{`${t("phoneNumber")} :`} </span>
                    <span className="t-grey-light">
                      {patientByID?.data?.mobile || "-"}
                    </span>
                  </Col>
                </Row>
              </>
            </Col>
            <Col>
              <Button
                className="send-lab mr-05 "
                htmlType="submit"
                onClick={() => {
                  history.push(`${INTERFACE_LAB.compare}/patient/${patientID}`);
                }}
              >
                {t("compareLab")}
              </Button>
            </Col>
          </Row>
        }
      >
        <Search onFinish={onFinish} />
        <MoTable
          onClickRow={onClickRow}
          dataSource={visitsList?.data?.result?.[0]?.data}
          columns={columns}
          onChangePage={onChangePage}
          config={{
            total: total,
            pageSize: limitPage,
            currentPage: currentPage,
          }}
          scroll={{ x: "calc(100vw -20%)" }}
        />
      </Card>
    </div>
  );
};

export default Fields;
