import { IPatientProfileContext } from "./interface";
import { createContext, useContext } from "react";

const MainPatientProfileContext = createContext<IPatientProfileContext>(
  {} as IPatientProfileContext
);

const MainPatientProfileProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: IPatientProfileContext;
}) => {
  return (
    <MainPatientProfileContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </MainPatientProfileContext.Provider>
  );
};

export const useMainPatientProfile = () =>
  useContext(MainPatientProfileContext);
export default MainPatientProfileProvider;
