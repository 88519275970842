import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Col, Row } from "antd";
import Header from "./Header";
import PrintReport from "./print/Print";

const ReportConfirmOrder = () => {
  const printReportRef = useRef(null);
  const pageStyle = `  @page { size:A4;
        margin: 40px;}`;

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });
  return (
    <div>
      <Header handlePrint={handlePrint} />
      <ReactToPrint content={() => printReportRef.current} />
      <div className="set-layout ">
        <div className="document-body ">
          <Row>
            <Col lg={24} className="display-center ">
              <div className="document-bill">
                <Row>
                  <Col lg={24} className="display-center ">
                    <PrintReport refData={printReportRef} />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ReportConfirmOrder;
