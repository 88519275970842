import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import { useTranslation } from "react-i18next";
import { ISelectedRowKeys } from "@interface/IGlobal.interface";
import { dispenseMedicine } from "@assets/data/pathRouter";
import { useGetVisitsByID } from "@services/api/visits";
import { openNotification } from "@components/notification/Notification";
import { ENUM_SERVICE_GROUP } from "@tools/util.enum";
import { RouteComponentProps } from "react-router-dom";
import { useCreateWorkOrdersUse } from "@services/api/work_order";
import { useGetWorkOrdersMedicine } from "@services/api/work_order";
import { groupWorkOrderByListType } from "@tools/util";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Fields from "./Fields";
import Header from "./Header";
import Modaler from "@components/modaler/Modaler";
import RoleContext from "@provider/RoleProvider";
import MainPatientByIdProvider from "@provider/patient_by_id";

const FormInchart = () => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const visitID = match.params.visitID;
  const history = useHistory<RouteComponentProps["history"]>();
  const { data } = useGetVisitsByID(visitID);
  const createWorkOrdersUse = useCreateWorkOrdersUse();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState<ISelectedRowKeys>({
    oneDay: [],
    continuous: [],
    other1: [],
    optionDrug: [],
    other2: [],
    labInterfaceGroup: [],
  });
  const [patientList] = Form.useForm();
  const orderList = useGetWorkOrdersMedicine(
    ENUM_SERVICE_GROUP.MEDICINE,
    patientID,
    {
      visitId: visitID,
    }
  );
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "DISPENSE_MEDICINE";
  });

  useEffect(() => {
    if (!data) {
      return;
    }

    patientList?.setFieldsValue({
      foodAllergy: data?.patient?.foodAllergy || undefined,
      drugAllergy: data?.patient?.drugAllergy || undefined,
    });

    // eslint-disable-next-line
  }, [data]);

  const workOrderId = () => {
    let data;
    if (orderList?.data?.continuous?.[0]?.children) {
      data =
        orderList?.data?.continuous?.[0]?.children?.[0]?.children?.[0]
          ?.workOrderId;
    } else if (orderList?.data?.oneDay?.[0]?.children) {
      data =
        orderList?.data?.oneDay?.[0]?.children?.[0]?.children?.[0]?.workOrderId;
    } else if (orderList?.data?.optionDrugs?.[0]?.children) {
      data =
        orderList?.data?.optionDrugs?.[0]?.children?.[0]?.children?.[0]
          ?.workOrderId;
    }
    return data ? data : undefined;
  };

  const childrenOrder = (key: "oneDay" | "continuous") => {
    const selectedOrder = orderList?.data?.[key]?.flatMap((parent) => {
      return parent?.children?.flatMap((child) => {
        return child?.children?.flatMap((childLevel1) => {
          if (
            childLevel1?.product?.categoryId === "C-0006" ||
            childLevel1?.product?.categoryId === "C-0007"
          ) {
            return childLevel1?.children?.flatMap((childLevel2) => {
              return childLevel2?.children;
            });
          }

          return childLevel1;
        });
      });
    });
    return selectedOrder;
  };

  const uuidList = (check?: boolean) => {
    const dataOneDay = groupWorkOrderByListType(
      childrenOrder("oneDay") || [],
      selectedRowKeys?.oneDay
    );

    const dataContinuous = groupWorkOrderByListType(
      childrenOrder("continuous") || [],
      selectedRowKeys?.continuous
    );

    const optionDrugs = orderList?.data?.optionDrugs
      ?.flatMap((item) => {
        return item?.children?.flatMap((parent) => {
          return parent?.children?.flatMap((child1) => {
            if (
              child1?.product?.categoryId === "C-0006" ||
              child1?.product?.categoryId === "C-0007"
            ) {
              return child1?.children?.flatMap((child2) => {
                return child2?.children?.flatMap((child3) => {
                  return child3;
                });
              });
            }

            return child1;
          });
        });
      })
      ?.map((item) => {
        return {
          uuid: item?.uuid,
          use: true,
        };
      });
    if (!!check) {
      return [...(dataOneDay || []), ...(dataContinuous || [])];
    } else
      return [
        ...(dataOneDay || []),
        ...(dataContinuous || []),
        ...(optionDrugs || []),
      ];
  };

  const handleCreateWorkOrderUse = () => {
    let id = workOrderId();
    let uuid = uuidList();
    onModal(false);

    createWorkOrdersUse.mutate(
      {
        data: uuid,
        id: visitID,
        workOrderId: id,
      },
      {
        onSuccess: () => {
          history.replace(dispenseMedicine);
        },
        onError: (err) => {
          openNotification({
            method: "POST_ERROR",
            message: String(err),
          });
        },
      }
    );
  };
  const onModal = (e: boolean) => {
    setIsModalOpen(e);
  };

  const checkDrugSelection = () => {
    if (!!uuidList(true)?.filter((item) => item?.use !== false)?.length) {
      handleCreateWorkOrderUse();
    } else {
      onModal(true);
    }
  };

  return (
    <MainPatientByIdProvider value={{ patientById: data?.patient }}>
      <React.Fragment>
        <Modaler
          forceRender
          onCancel={() => onModal(false)}
          visible={isModalOpen}
          width="45%"
          footer={[
            <Button key="1" className="cancel " onClick={() => onModal(false)}>
              {t("cancel")}
            </Button>,
            <Button
              key="2"
              className="btn-create"
              onClick={() => handleCreateWorkOrderUse()}
            >
              {t("confirm")}
            </Button>,
          ]}
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-cancel" />
              {`${t("areYouSure")}`}
            </div>
          }
        >
          {t("areYouSureYouAreNotDispensingTheMedication")}
        </Modaler>
        <Header
          history={history}
          handleCreateWorkOrderUse={checkDrugSelection}
          medicalRecord={medicalRecord?.[0]?.isEdit}
          isSignature={data?.isSignature?.dispensary}
          orderList={orderList?.data}
        />
        <div className="set-layout ">
          <Fields
            visitsByID={data}
            patientList={patientList}
            orderList={orderList?.data}
            seletection={{
              selectedRowKeys: selectedRowKeys,
              setSelectedRowKeys: setSelectedRowKeys,
            }}
            medicalRecord={medicalRecord?.[0]?.isEdit}
          />
        </div>
      </React.Fragment>
    </MainPatientByIdProvider>
  );
};

export default FormInchart;
