import React, { useState } from "react";
import { IGetVisits } from "@interface/IVisits.interface";
import { TextBRHTML } from "@tools/util";
import { IAppointments } from "@interface/IAppointments.interface";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row } from "antd";
import Link from "@assets/img/icon/Link.svg";
import File from "@assets/img/icon/File.svg";
import ViewImage from "../drawing_canvas/ViewImage";

type Props = {
  appointmentsByID?: IAppointments;
  visitsByID?: IGetVisits;
};

interface IVisible {
  doctorComment: boolean;
  leadingSymptoms: boolean;
}

const Advice = ({ appointmentsByID, visitsByID }: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<IVisible>({
    doctorComment: false,
    leadingSymptoms: false,
  });

  const leadingSymptomsModal = () => {
    setVisible({ ...visible, leadingSymptoms: !visible?.leadingSymptoms });
  };

  const doctorCommentModal = () => {
    setVisible({ ...visible, doctorComment: !visible?.doctorComment });
  };

  return (
    <>
      <Card
        className="comment-card mt-2 "
        extra={
          <>
            <Row gutter={[14, 14]}>
              <Col md={12} lg={12} className="f-normal t-dark-blue col-start">
                {`${t("basicInformation")} / ${t("importantSymptoms")}`}
              </Col>
              <Col md={12} lg={12} className="col-end link-btn f-normal">
                <Button type="link" onClick={leadingSymptomsModal}>
                  {t("seePaintings")}
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <div
          className="t-black-light"
          dangerouslySetInnerHTML={{
            __html: TextBRHTML(
              appointmentsByID
                ? appointmentsByID?.visit?.data?.print || ""
                : visitsByID?.data?.print || ""
            ),
          }}
        />

        {(appointmentsByID?.visit?.visitFile?.radiology ||
          visitsByID?.visitFile?.radiology) && (
          <div className="f-normal mt-2 mb-1">Radiology Result</div>
        )}
        {visitsByID
          ? visitsByID?.visitFile?.radiology?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })
          : appointmentsByID?.visit?.visitFile?.radiology?.map(
              (item, index) => {
                return (
                  <Row
                    key={index}
                    align="middle"
                    className="mt-05 cursor"
                    onClick={() => window.open(item?.filePath)}
                  >
                    <img
                      src={Link}
                      alt="Link"
                      width={13}
                      height={13}
                      style={{ marginRight: "2px" }}
                    />
                    <img
                      src={File}
                      alt="File"
                      width={25}
                      height={25}
                      style={{ marginRight: "5px" }}
                    />
                    <div>{item?.fileName || ""}</div>
                  </Row>
                );
              }
            )}

        {(appointmentsByID?.visit?.visitFile?.medical ||
          visitsByID?.visitFile?.medical) && (
          <div className="f-normal mt-2 mb-1">Medical Report</div>
        )}

        {visitsByID
          ? visitsByID?.visitFile?.medical?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })
          : appointmentsByID?.visit?.visitFile?.medical?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })}
        {(appointmentsByID?.visit?.visitFile?.summary ||
          visitsByID?.visitFile?.summary) && (
          <div className="f-normal mt-2 mb-1">Summary Result</div>
        )}

        {visitsByID
          ? visitsByID?.visitFile?.summary?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })
          : appointmentsByID?.visit?.visitFile?.summary?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })}
      </Card>

      <Card
        className="comment-card mt-2 "
        extra={
          <>
            <Row gutter={[14, 14]}>
              <Col md={12} lg={12} className="f-normal t-dark-blue col-start">
                {t("doctorOpinion")}
              </Col>

              <Col md={12} lg={12} className="col-end link-btn f-normal">
                <Button type="link" onClick={doctorCommentModal}>
                  {t("seePaintings")}
                </Button>
              </Col>
            </Row>
          </>
        }
      >
        <div
          className="t-black-light"
          dangerouslySetInnerHTML={{
            __html: TextBRHTML(
              appointmentsByID
                ? appointmentsByID?.visit?.suggestion?.print || ""
                : visitsByID?.suggestion?.print || ""
            ),
          }}
        />
      </Card>

      {visible?.leadingSymptoms && (
        <ViewImage
          visible={visible?.leadingSymptoms}
          setModal={leadingSymptomsModal}
          drawing={
            appointmentsByID
              ? appointmentsByID?.visit?.data?.drawing || ""
              : visitsByID?.data?.drawing || ""
          }
          title={t("leadingSymptoms")}
        />
      )}
      {visible?.doctorComment && (
        <ViewImage
          visible={visible?.doctorComment}
          setModal={doctorCommentModal}
          drawing={
            appointmentsByID
              ? appointmentsByID?.visit?.suggestion?.drawing || ""
              : visitsByID?.suggestion?.drawing || ""
          }
          title={t("seePaintings")}
        />
      )}
    </>
  );
};

export default Advice;
