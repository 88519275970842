import React, { FC } from "react";
import { Age } from "@tools/age";
import { dayjs } from "@tools/timezone";
import { Col, Row } from "antd";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetVisitBiology } from "@services/api/visit_history";
import { useGetPatientByHistory } from "@services/api/patient";
import moment from "moment";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";

type Props = {
  refValue: React.RefObject<HTMLInputElement>;
};

const Detail: FC<Props> = ({ refValue }) => {
  const userList = React.useContext(UserContext);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const patient = useGetPatientByHistory(patientID);
  const { data } = useGetVisitBiology(patientID);
  const { i18n } = useTranslation();

  return (
    <div ref={refValue}>
      <Row className="t-start document-border-header p-05">
        <Col span={6}>
          <div className="mt-1">
            <ImageLogo
              height="50px"
              width="120px"
              src={userList?.branch?.image}
              alt="logo"
            />
          </div>
        </Col>
        <Col span={18}>
          <Row>
            <Col>
              <div>Name : </div>
            </Col>
            <Col span={20} className="t-underline">
              <div className=" f-normal" style={{ paddingLeft: 10 }}>
                {i18n.language === "th"
                  ? `${patient?.data?.titleTh}${[
                      patient?.data?.firstNameTh,
                      patient?.data?.lastNameTh,
                    ]
                      ?.join(" ")
                      ?.trim()}`
                  : `${patient?.data?.titleEn}${[
                      patient?.data?.firstNameEn,
                      patient?.data?.lastNameEn,
                    ]
                      ?.join(" ")
                      ?.trim()}`}
              </div>
            </Col>
          </Row>

          <Row>
            <Col className="mt-05">HN:</Col>

            <Col span={7} className="t-underline mt-05">
              <div style={{ paddingLeft: 10 }} className=" f-normal">
                {patient?.data?.hn}
              </div>
            </Col>

            <Col className="mt-05">Birth Date:</Col>

            <Col span={7} className="t-underline mt-05">
              <div style={{ paddingLeft: 10 }} className=" f-normal">
                {patient?.data?.dateOfBirth
                  ? moment(patient?.data?.dateOfBirth).format("DD-MM-YYYY")
                  : "-"}
              </div>
            </Col>

            <Col className="mt-05">Age :</Col>

            <Col span={2} className="t-underline mt-05">
              <div style={{ paddingLeft: 10 }} className=" f-normal">
                {Age(patient?.data?.dateOfBirth || "")}
              </div>
            </Col>

            <Col className="mt-05">y</Col>
          </Row>

          <Row>
            <Col className="mt-05">Allergies : </Col>

            <Col style={{ minWidth: 130 }} className="t-underline mt-05 t-red">
              <div style={{ paddingLeft: 10 }} className=" f-normal ">
                {` ${[patient?.data?.drugAllergy, patient?.data?.foodAllergy]
                  ?.join(" / ")
                  ?.trim()}`}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>

      <div className="t-center fs-20 f-bold mt-05 mb-05">
        Biological Treatment/Cells Therapy Record
      </div>

      <table width="100%" className="print-marker fs-14-marker t-center">
        <thead>
          <tr>
            <td className="print-marker-right f-bold">No.</td>
            <td className="print-marker-right f-bold">
              Biological Treatment/Cells Therapy
            </td>
            <td className="print-marker-right f-bold">Dose</td>
            <td className="print-marker-right f-bold">Date order</td>
            <td className="print-marker-right f-bold">Done (Date)</td>
            <td className="print-marker-right f-bold">Prescription by</td>
            <td className="print-marker-right f-bold">Notes</td>
          </tr>

          {data?.map((item, i) => {
            return (
              <tr key={i} className="t-start" style={{ height: 25 }}>
                <td className="print-marker-right print-marker-top t-center">
                  {item?.createdAt && i + 1}
                </td>
                <td className="print-marker-right print-marker-top">
                  {i18n.language === "th"
                    ? item?.product?.nameTh || ""
                    : item?.product?.nameEn || ""}
                </td>
                <td className="print-marker-right print-marker-top  t-center">
                  {item?.workOrderItem?.qty}
                </td>
                <td className="print-marker-right print-marker-top">
                  {item?.createdAt &&
                    dayjs(item?.createdAt).format("DD-MM-YYYY")}
                </td>
                <td className="print-marker-right print-marker-top">
                  {item?.workOrderItem?.createdAt &&
                    dayjs(item?.workOrderItem?.createdAt).format("DD-MM-YYYY")}
                </td>
                <td className="print-marker-right print-marker-top">
                  {i18n.language === "th"
                    ? [item?.editBy?.firstNameTh, item?.editBy?.lastNameTh]
                        ?.join(" ")
                        ?.trim()
                    : [item?.editBy?.firstNameEn, item?.editBy?.lastNameEn]
                        ?.join(" ")
                        ?.trim()}
                </td>
                <td className="print-marker-right print-marker-top">
                  {item?.workOrderItem?.option?.remark}
                </td>
              </tr>
            );
          })}
        </thead>
      </table>
    </div>
  );
};

export default Detail;
