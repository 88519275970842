import BackHome from "@components/button/BackHome";
import MainPatientByIdProvider from "@provider/patient_by_id";
import { useGetVisitsByID } from "@services/api/visits";
import { Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useHistory } from "react-router";
import { useRouteMatch } from "react-router-dom";
import FormOrderInfo from "./form/Form";

const OrderInfoDoctorRoom = () => {
  const match: any = useRouteMatch();
  const visitId: number = match.params.visitId;
  const { t } = useTranslation();
  const visit = useGetVisitsByID(visitId);
  const history = useHistory<RouteComponentProps["history"]>();

  return (
    <MainPatientByIdProvider
      value={{ patientById: visit?.data?.patient, visit: visit?.data }}
    >
      <div>
        <Row className="second-header " justify="space-between" align="middle">
          <Col>
            <Row gutter={[14, 14]}>
              <BackHome history={history} />

              <Col className="header-text">
                <div>
                  <h1>{t("doctorroom")}</h1>
                </div>
                <span className="t-light-gray">{`${t("nursery")} /  `}</span>
                <span className="t-dark-blue f-normal">{t("orderInfo")}</span>
              </Col>
            </Row>
          </Col>
        </Row>
        <div className="set-layout">
          <FormOrderInfo />
        </div>
      </div>
    </MainPatientByIdProvider>
  );
};

export default OrderInfoDoctorRoom;
