import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";
import { useGetPatientByHistory } from "@services/api/patient";
import ImagePatient from "@components/image/ImagePatient";
import TableReturnCourse from "../table/Table";

const Fields = () => {
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const patientByID = useGetPatientByHistory(patientID);
  return (
    <div className="set-layout ">
      <Card
        className="bg-grey m-1-doctor-room "
        extra={
          <>
            <Row
              justify="center"
              gutter={[14, 14]}
              className="text-row-profile ml-05"
              align="middle"
            >
              <Col xs={4} md={4} lg={3} xl={2}>
                <ImagePatient src={patientByID?.data?.image} />
              </Col>
              <Col>
                <div>
                  {i18n.language === "th"
                    ? ` ${patientByID?.data?.firstNameTh || " "}    ${
                        patientByID?.data?.lastNameTh || " "
                      }`
                    : ` ${patientByID?.data?.firstNameEn || " "}    ${
                        patientByID?.data?.lastNameEn || " "
                      }`}
                </div>

                <div className="code-profile ">
                  HN : {patientByID?.data?.hn}
                </div>
              </Col>
              <Col>
                <span className="mr-05">{t("age")} : </span>
                <span className="t-grey-light">
                  {Age(patientByID?.data?.dateOfBirth || "")}
                </span>
              </Col>

              <Col>
                <span className="mr-05">{t("sex")} : </span>
                <span className="t-grey-light">
                  {gender(patientByID?.data?.gender, i18n?.language)}
                </span>
              </Col>

              <Col>
                <span className="mr-05">{t("phoneNumber")} : </span>
                <span className="t-grey-light">
                  {patientByID?.data?.mobile || "-"}
                </span>
              </Col>
            </Row>
          </>
        }
      >
        <TableReturnCourse patientID={patientID} />
      </Card>
    </div>
  );
};

export default Fields;
