import { useTranslation } from "react-i18next";
import { returnMedicine } from "@assets/data/pathRouter";
import { openNotification } from "@components/notification/Notification";
import { Button, Col, Row } from "antd";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { useUpdateWorkOrderItemRejectDrugsConfirm } from "@services/api/work_order_item";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
  selectedRow: number[];
  orderItemRejectDrug: any;
};

const Header = ({ history, selectedRow = [], orderItemRejectDrug }: Props) => {
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const updateWorkOrderItemRejectDrugsConfirm =
    useUpdateWorkOrderItemRejectDrugsConfirm();

  const handleUpdateRejectDrugsCancel = () => {
    const dataList =
      orderItemRejectDrug.workOrderItems[0]?.children?.[0]?.children?.[0]?.children?.flatMap(
        (item: any) => {
          return item;
        }
      );

    const selected = dataList?.map((item: any) => {
      const list = selectedRow?.find((value: any) => value === item?.id);

      if (list) {
        return {
          id: list,
          confirm: true,
        };
      }
      return {
        id: item?.id,
        confirm: false,
      };
    });

    updateWorkOrderItemRejectDrugsConfirm.mutate(
      {
        id: visitID,
        data: selected,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(`${returnMedicine}`);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const confirm = () => {
    handleUpdateRejectDrugsCancel();
  };

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("returnMedicine.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-bold">
                {t("returnMedicine.title")}
              </span>
            </Col>
          </Row>
        </Col>

        <Col lg={10} className="d-flex justify-between justify-col">
          <Button
            className="cancel mr-05 "
            htmlType="submit"
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>

          <Button
            className="btn-create"
            htmlType="submit"
            loading={updateWorkOrderItemRejectDrugsConfirm?.isLoading}
            onClick={confirm}
          >
            {t("confirm")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
