import { Tabs } from "antd";
import { INTERFACE_LAB } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import { ITabsState, tabsState } from "@tools/recoil/recoil-atom";
import PatientList from "@components/patient_list/PatientList";
import SendInterfaceLab from "./send_interface_lab/SendInterfaceLab";

const TabsInterfaceLab = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const [tab, setTab] = useRecoilState<ITabsState>(tabsState);

  const callBack = (key: string) => {
    setTab({ ...tab, labInterface: key });
  };

  return (
    <Tabs
      defaultActiveKey={tab.labInterface}
      onChange={callBack}
      className="tab-printdraw f-normal "
    >
      <TabPane tab="interface Lab" key="1" className="mt-2">
        <SendInterfaceLab />
      </TabPane>
      <TabPane tab={t("record")} key="2" className="mt-2">
        <PatientList path={INTERFACE_LAB.listLab} />
      </TabPane>
    </Tabs>
  );
};

export default TabsInterfaceLab;
