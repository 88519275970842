import { ISelected } from "@interface/IOrder.interface";
import { useTranslation } from "react-i18next";
import { Badge, Card, Tabs } from "antd";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import List from "./List";
import React from "react";
import History from "./History";

type Props = {
  setOrderList: (e: ISelectedExecuteOrder[]) => void;
  status: string;
  selection: ISelected;
  noCard?: string;
};

const ListHistoryRadio = ({
  setOrderList,
  status,
  selection,
  noCard = "",
}: Props) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  return (
    <>
      {noCard === "noCard" ? (
        <Tabs className="tab-profile " defaultActiveKey={"1"}>
          <TabPane
            tab={
              <React.Fragment>
                {t("availableList")}
                <Badge className="ml-1 status-dot" color="#f50" />
              </React.Fragment>
            }
            key="1"
          >
            <List
              setOrderList={setOrderList}
              status={status}
              selection={selection}
              noCard={noCard}
            />
          </TabPane>
          <TabPane tab={t("record")} key="2">
            <History />
          </TabPane>
        </Tabs>
      ) : (
        <Card className="bg-grey  list-record-card  order-list-record   ">
          <Tabs className="tab-profile" defaultActiveKey={"1"}>
            <TabPane
              tab={
                <React.Fragment>
                  {t("availableList")}
                  <Badge className="ml-1 status-dot" color="#f50" />
                </React.Fragment>
              }
              key="1"
            >
              <List
                setOrderList={setOrderList}
                status={status}
                selection={selection}
              />
            </TabPane>
            <TabPane tab={t("record")} key="2">
              <History />
            </TabPane>
          </Tabs>
        </Card>
      )}
    </>
  );
};

export default ListHistoryRadio;
