import { TFunction, t } from "i18next";
import { FC } from "react";
import { Button, Col, DatePicker, Form, FormInstance, Input, Row } from "antd";
import Selector from "@components/selector/Selector";
type Props = {
  onFinish: (e: any) => void;
  translation: TFunction;
};
const Header: FC<Props> = ({ onFinish, translation }) => {
  const statusList = [
    {
      name: translation("COMPLETE"),
      value: "COMPLETE",
    },
    { name: translation("PENDING"), value: "PENDING" },
    { name: translation("CANCEL"), value: "CANCEL" },
  ];
  const { RangePicker } = DatePicker;
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={5}>
            <Form.Item shouldUpdate={(prev, next) => prev.type !== next.type}>
              {(form: FormInstance) => {
                const type = form.getFieldValue("type");
                if (type === "year") {
                  return (
                    <Form.Item name="start">
                      <RangePicker picker="year" format="YYYY" />
                    </Form.Item>
                  );
                } else if (type === "month") {
                  return (
                    <Form.Item name="start">
                      <RangePicker picker="month" format="MM/YYYY" />
                    </Form.Item>
                  );
                } else if (type === "day" || !type) {
                  return (
                    <Form.Item name="start">
                      <RangePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>
          </Col>
          <Col md={5}>
            <Form.Item name="poNumber">
              <Input placeholder="เลขใบสั่งซื้อ" />
            </Form.Item>
          </Col>
          <Col md={5}>
            <Form.Item name="branchName">
              <Input placeholder="ชื่อสาขา" />
            </Form.Item>
          </Col>
          <Col md={5}>
            <Form.Item name="status">
              <Selector
                placeholder={t("status")}
                Source={statusList || []}
                keyName="name"
                keyValue="value"
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear ml-05" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Header;
