import { IModalOrderContext } from "./interface";
import { createContext, useContext } from "react";

const MainModalOrderContext = createContext<IModalOrderContext>({} as IModalOrderContext);

const MainModalOrderProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: IModalOrderContext;
}) => {
  return (
    <MainModalOrderContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </MainModalOrderContext.Provider>
  );
};

export const useMainModalOrder = () => useContext(MainModalOrderContext);
export default MainModalOrderProvider;
