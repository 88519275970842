import React, { Fragment, useEffect } from "react";
import { Card } from "antd";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { compareLabState } from "@tools/recoil/recoil-atom";
import { useGetCompareVisit } from "@services/api/lab";
import { IGroupLabInterface } from "@interface/ILab.interface";
import { useResetRecoilState } from "recoil";
import { IChildTestCode, IFormatLabInterface } from "@interface/ILab.interface";
import dayjs from "dayjs";
import Nodata from "@assets/img/nodata.svg";

type Props = {
  title: React.ReactNode;
};

const Result = ({ title }: Props) => {
  const { t } = useTranslation();
  const resetList = useResetRecoilState(compareLabState);
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const compare = useGetCompareVisit(visitID, true);

  useEffect(() => {
    resetList();

    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Card className="bg-grey " extra={title}>
        {compare?.data ? (
          <React.Fragment>
            <div className="scroll-compare-lab">
              <table width="100%">
                <thead>
                  <tr
                    className=" lab-i-body lab-h print-marker"
                    style={{ backgroundColor: "#498dcb", color: "white" }}
                  >
                    <th className="print-marker-right" rowSpan={2}>
                      {t("testName")}
                    </th>
                    <th className="print-marker-right" rowSpan={2}>
                      {t("Reference Range")}
                    </th>
                    {compare?.data?.result?.[0]?.date?.map((_, index) => {
                      return (
                        <th
                          key={index}
                          className={
                            compare?.data?.result?.[0]?.date?.length === index
                              ? ""
                              : "print-marker-right"
                          }
                        >
                          {" "}
                          Date{" "}
                        </th>
                      );
                    })}
                  </tr>
                  <tr
                    style={{ backgroundColor: "#498dcb", color: "white" }}
                    className=" lab-i-body lab-h print-marker"
                  >
                    {compare?.data?.result?.[0]?.date?.map((day, index) => {
                      return (
                        <th
                          key={index}
                          className={
                            compare?.data?.result?.[0]?.date?.length === index
                              ? ""
                              : "print-marker-right"
                          }
                        >
                          {dayjs(day).format("DD-MM-YYYY")}
                        </th>
                      );
                    })}
                  </tr>
                </thead>
                <tbody>
                  {compare?.data?.result?.[0]?.data?.map(
                    (item: IFormatLabInterface, index: number) => {
                      return (
                        <Fragment key={index}>
                          <tr className="print-marker-right print-marker-left">
                            {item?.group?.length === 0 ? null : (
                              <td
                                style={{
                                  backgroundColor: "#B0C9E1",
                                  color: "white",
                                }}
                                key={item?.testCode}
                                colSpan={12}
                                className="print-marker-right "
                              >
                                <div className="">{item?.testCode}</div>
                              </td>
                            )}
                          </tr>
                          {item?.group?.map(
                            (
                              itemGroup: IGroupLabInterface,
                              chidIndex: number
                            ) => {
                              return (
                                <tr key={chidIndex} className="print-marker">
                                  <td className="print-marker-right ">
                                    {itemGroup?.testName}
                                  </td>
                                  <td className="print-marker-right ">
                                    {itemGroup?.normalRange
                                      ? itemGroup?.normalRange +
                                        " " +
                                        itemGroup?.unit
                                      : null}
                                  </td>
                                  {compare?.data?.result?.[0]?.date?.map(
                                    (day, chIndex: number) => {
                                      if (
                                        itemGroup?.group?.hasOwnProperty(day)
                                      ) {
                                        return (
                                          <td
                                            key={chIndex}
                                            className="print-marker-right "
                                          >
                                            {itemGroup?.group[
                                              day as keyof typeof itemGroup.group
                                            ]?.map(
                                              (
                                                item: IChildTestCode,
                                                index1: number
                                              ) => {
                                                return (
                                                  <div
                                                    key={index1}
                                                    style={{
                                                      display: "inline-block",
                                                    }}
                                                  >
                                                    <span
                                                      key={item?.date}
                                                      style={{
                                                        color: item?.flagLowHigh
                                                          ? "red"
                                                          : "black",
                                                        display: "inline-block",
                                                      }}
                                                    >
                                                      {index1 !==
                                                      itemGroup?.group[
                                                        day as keyof typeof itemGroup.group
                                                      ]?.length -
                                                        1
                                                        ? item?.result +
                                                          item?.flagLowHigh
                                                        : item?.result +
                                                          item?.flagLowHigh}
                                                    </span>
                                                    {index1 !==
                                                    itemGroup?.group[
                                                      day as keyof typeof itemGroup.group
                                                    ]?.length -
                                                      1 ? (
                                                      <span>,&nbsp;</span>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                );
                                              }
                                            )}
                                          </td>
                                        );
                                      } else {
                                        return (
                                          <td
                                            className="print-marker-right "
                                            key={chIndex}
                                          ></td>
                                        );
                                      }
                                    }
                                  )}
                                </tr>
                              );
                            }
                          )}
                        </Fragment>
                      );
                    }
                  )}
                </tbody>
              </table>
            </div>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <div>
              <img src={Nodata} alt="nodata" className="image-nodata" />
            </div>
            <div className="text-nodata f-bold">NO DATA</div>
          </React.Fragment>
        )}
      </Card>
    </div>
  );
};
export default Result;
