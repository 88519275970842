import { OPD } from "@assets/data/pathRouter";
import { Form } from "antd";
import { useGetPatientById } from "@services/api/patient";
import { useLocation, useRouteMatch } from "react-router-dom";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";

const PreRegistrationDetail = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const location = useLocation();
  const match: any = useRouteMatch();
  const [preRegister] = Form.useForm();
  const patientID = match.params.patientID;
  const eventID = match.params.eventID;
  const locationPath = location.pathname;
  const patientByID = useGetPatientById(patientID);

  const sendPatient = () => {
    if (patientByID?.data?.accountType) {
      history.replace(`${OPD.profile}/patient/${patientID}`);
    } else {
      history.replace(`${OPD.basicInfo}/${patientID}`);
    }
  };

  return (
    <>
      <Header
        history={history}
        preRegister={preRegister}
        patientID={patientID}
        locationPath={locationPath}
        sendPatient={sendPatient}
        eventID={eventID}
      />
      <div className="set-layout ">
        <Fields preRegister={preRegister} patientById={patientByID?.data} />
      </div>
    </>
  );
};

export default PreRegistrationDetail;
