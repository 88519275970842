import React, { useEffect, useState } from "react";
import { IGetVisits } from "@interface/IVisits.interface";
import { Col, Drawer, Form, Row } from "antd";
import Profile from "./Profile";
import Affixes from "@components/affixes/Affixes";
import ListRecord from "@components/list_history/ListHistory";

type Props = {
  patientID: number;
  visitsByID?: IGetVisits;
  visitsID?: number;
};

const Fields = ({ patientID, visitsByID, visitsID }: Props) => {
  const [patientList] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!visitsByID) {
      return;
    }
    patientList?.setFieldsValue({
      foodAllergy: visitsByID?.patient?.foodAllergy || undefined,
      drugAllergy: visitsByID?.patient?.drugAllergy || undefined,
    });
    // eslint-disable-next-line
  }, [visitsByID]);

  const showDrawer = () => {
    setVisible(!visible);
  };

  return (
    <React.Fragment>
      <Row gutter={[14, 14]}>
        <Col md={24} lg={18}>
          <Profile
            byID={patientID}
            visitsByID={visitsByID}
            patientList={patientList}
            visitsID={visitsID}
          />
        </Col>

        <Col lg={6} className="card-display">
          <ListRecord />
        </Col>
      </Row>

      <Affixes onClick={showDrawer} />

      <Drawer
        width={"50%"}
        closable={false}
        placement="right"
        onClose={showDrawer}
        visible={visible}
      >
        <ListRecord status="noCard" />
      </Drawer>
    </React.Fragment>
  );
};

export default Fields;
