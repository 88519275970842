import React, { useRef } from "react";
import { dayjs } from "@tools/timezone";
import { IGetVisits } from "@interface/IVisits.interface";
import { ICanvasDraw } from "@interface/IImageCanvas";
import { IAppointments } from "@interface/IAppointments.interface";
import { dataUrlToFile } from "@tools/util";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useCreateMediaMulti } from "@services/api/media_object";
import { ENUM_VIEW_MARKER_TYPE } from "@tools/util.enum";
import { useAddMarker, useUpdateMarker } from "@services/api/visits";
import { Button, Col, Divider, Modal, Row } from "antd";
import Fields from "./Fields";
import MainImageCanvasGroupProvider from "@provider/image_canvas";

interface IAddMarker {
  sequence?: number;
  imageId?: number;
  tags: ENUM_VIEW_MARKER_TYPE;
}

interface IFileCanvas {
  file: File;
  index: number;
}

type Props = {
  visible: boolean;
  onModal: () => void;
  appointmentsByID?: IAppointments | undefined;
  visitsByID?: IGetVisits;
  SOAP?: boolean;
};

const ModalMarker = ({
  visible,
  onModal,
  appointmentsByID,
  visitsByID,
  SOAP,
}: Props) => {
  const imageFrontCanvasRef = useRef<ICanvasDraw>(null);
  const imageBackCanvasRef = useRef<ICanvasDraw>(null);
  const imageLeftCanvasRef = useRef<ICanvasDraw>(null);
  const imageRightCanvasRef = useRef<ICanvasDraw>(null);
  const { t } = useTranslation();
  const createMedia = useCreateMediaMulti();
  const addMarker = useAddMarker();
  const updateMarker = useUpdateMarker();
  const match: any = useRouteMatch();
  const client = useQueryClient();
  const visitID = match.params.visitID;
  const appointmentsID = match.params.appointmentsID;

  const onConfirm = () => {
    let canvasArray: IFileCanvas[] = [];
    const nameCanvas = [
      "front_canvas",
      "back_canvas",
      "left_canvas",
      "right_canvas",
    ];

    [
      imageFrontCanvasRef,
      imageBackCanvasRef,
      imageRightCanvasRef,
      imageLeftCanvasRef,
    ]?.forEach((item, index) => {
      if (!item?.current) return;
      const canvasRef2 = item;
      const width = canvasRef2.current?.props.canvasWidth as number;
      const height = canvasRef2.current?.props.canvasHeight as number;
      const background = canvasRef2.current?.canvasContainer?.children?.[0];
      let drawing = canvasRef2.current?.canvas.drawing;
      let canvas = document.createElement("canvas");

      var ctx = canvas.getContext("2d") as CanvasRenderingContext2D;

      canvas.width = width;
      canvas.height = height;

      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx?.drawImage(background, 0, 0);
      ctx?.drawImage(drawing, 0, 0);

      let imageData = canvas?.toDataURL("image/jpg");

      canvasArray = [
        ...canvasArray,
        {
          file: dataUrlToFile(
            imageData,
            `${nameCanvas?.[index]}_${dayjs(new Date()).unix()}.png`
          ),
          index: index,
        },
      ];
    });

    saveImage(canvasArray);
  };

  const saveImage = (current: IFileCanvas[]) => {
    const appointments = appointmentsByID?.visit?.visitMarker;

    createMedia.mutate(
      { image: current, SOAP: SOAP },
      {
        onSuccess: (res) => {
          const visitMarker = visitsByID?.visitMarker;
          if (
            appointments?.doctorMarker0 ||
            appointments?.doctorMarker1 ||
            appointments?.doctorMarker2 ||
            appointments?.doctorMarker3 ||
            visitMarker?.doctorMarker0 ||
            visitMarker?.doctorMarker1 ||
            visitMarker?.doctorMarker2 ||
            visitMarker?.doctorMarker3
          ) {
            updateAddMarker(res as IAddMarker[]);
          } else {
            createAddMarker(res as IAddMarker[]);
          }
        },
      }
    );
  };

  const createAddMarker = (imageId: IAddMarker[]) => {
    addMarker.mutate(
      { data: imageId, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });

          client.invalidateQueries(["get-appointment-byID", appointmentsID]);
          client.invalidateQueries(["get-visitsByID", visitID]);

          onModal();
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };

  const updateAddMarker = (imageId: IAddMarker[]) => {
    updateMarker.mutate(
      { data: imageId, id: visitID },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });

          client.invalidateQueries(["get-visitsByID", visitID]);
          client.invalidateQueries(["get-appointment-byID", appointmentsID]);

          onModal();
        },
        onError: (err) => {
          openNotification({ method: "POST_ERROR", message: String(err) });
        },
      }
    );
  };
  return (
    <MainImageCanvasGroupProvider
      value={{
        imageFrontCanvasRef: imageFrontCanvasRef,
        imageBackCanvasRef: imageBackCanvasRef,
        imageLeftCanvasRef: imageLeftCanvasRef,
        imageRightCanvasRef: imageRightCanvasRef,
        appointmentsByID: appointmentsByID,
        visitsByID: visitsByID,
        SOAP: SOAP,
      }}
    >
      <Modal
        className="manage-modal "
        title={
          <React.Fragment>
            <div className="t-blue">{t("doctorOrders")}</div>
          </React.Fragment>
        }
        transitionName=""
        centered
        visible={visible}
        onOk={onModal}
        onCancel={onModal}
        width="90%"
        footer={null}
        forceRender={true}
      >
        <div className="scroll-canvas ">
          <Fields />
        </div>

        <Row gutter={[14, 14]}>
          <Col span={24}>
            <Divider
              className="m-0 "
              style={{ position: "absolute", top: 0 }}
            />
            <Row
              gutter={[14, 14]}
              justify="end"
              align="middle"
              className="footer"
            >
              <Col>
                <Button className=" cancel" onClick={onModal}>
                  {t("cancel")}
                </Button>
              </Col>
              <Col className="mr-05">
                <Button
                  className="btn-create"
                  htmlType="submit"
                  onClick={onConfirm}
                  loading={addMarker?.isLoading || updateMarker?.isLoading}
                >
                  {t("confirm")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Modal>
    </MainImageCanvasGroupProvider>
  );
};

export default ModalMarker;
