import { Form } from "antd";
import Fields from "./Fields";
import HeaderBranchManagement from "./Header";

const FormBranchManagement = () => {
  const [branchManageForm] = Form.useForm();
  return (
    <div>
      <HeaderBranchManagement branchManageForm={branchManageForm} />
      <div className="set-layout ">
        <Fields branchManageForm={branchManageForm} />
      </div>
    </div>
  );
};

export default FormBranchManagement;
