import React, { FC, useEffect, useState } from "react";
import { IGetVisits } from "@interface/IVisits.interface";
import { ModalPTNote } from "./ModalPTNote";
import { useTranslation } from "react-i18next";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Empty, Row } from "antd";
import empty from "@assets/img/empty.svg";

type Props = {
  title: string;
  medicalRecord: boolean;
  visitsByID?: IGetVisits;
};

const PTNote: FC<Props> = ({ title, medicalRecord, visitsByID }) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (!visitsByID?.pTNote) return;

    setOpen(!!visitsByID?.pTNote);
    // eslint-disable-next-line
  }, [visitsByID?.pTNote]);

  const viewDetailPTNote = () => {
    setOpen(!open);
  };

  const onClick = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <div className="mt-2 ">
        <Row className=" header-table " justify="space-between" align="middle">
          <Col className="f-normal  t-dark-blue  ">
            <span className="f-bold mr-05 ">{title}</span>

            <span>
              {React.createElement(open ? UpOutlined : DownOutlined, {
                onClick: viewDetailPTNote,
              })}
            </span>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            {visitsByID?.pTNote && (
              <Button type="primary" onClick={onClick}>
                {t("edit")}
              </Button>
            )}
          </Col>
        </Row>
        <div className={open ? "open" : "close"}>
          <div className="t-black-light p-1 border-note ">
            {!visitsByID?.pTNote ? (
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">
                    {t("no")} {title}
                  </span>
                }
              >
                {medicalRecord ? (
                  <Button type="primary" onClick={onClick}>
                    {t("createNow")}
                  </Button>
                ) : null}
              </Empty>
            ) : (
              <React.Fragment>
                <table width="100%" className="print-border">
                  <thead>
                    <tr className=" print-bottom text-center f-bold">
                      <td width="90%" className="print-right">
                        NOTE
                      </td>
                      <td width="10%">SIGN</td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="print-right p-1">
                        {visitsByID?.pTNote?.ptNoteList?.map((item, index) => {
                          return (
                            <React.Fragment key={index}>
                              <Row>
                                <Col>DATE : </Col>
                                <Col
                                  style={{ minWidth: 100 }}
                                  className="t-underline area-checkbox-pt-note t-center t-grey-form"
                                >
                                  {item?.date || "-"}
                                </Col>

                                <Col>CC :</Col>
                                <Col
                                  style={{ minWidth: 300 }}
                                  className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                >
                                  {item?.cc || "-"}
                                </Col>
                              </Row>

                              <Row>
                                <Col>Pain Score : Pre </Col>
                                <Col
                                  style={{ minWidth: 30 }}
                                  className="t-underline area-checkbox-pt-note t-center t-grey-form"
                                >
                                  {item?.pre || "-"}
                                </Col>
                                <Col>/10</Col>
                                <Col style={{ marginLeft: "50%" }}>Post </Col>
                                <Col
                                  style={{ minWidth: 30 }}
                                  className="t-underline area-checkbox-pt-note t-center t-grey-form"
                                >
                                  {item?.post || "-"}
                                </Col>
                                <Col>/10</Col>
                              </Row>

                              {!!item?.PMS && (
                                <Row>
                                  <Col className="area-checkbox-pt-note">
                                    <Checkbox checked={item?.PMS} />
                                  </Col>
                                  <Col> PMS : mode</Col>
                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.PMS_mode || "-"}
                                  </Col>
                                  <Col> intensity</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.PMS_intensity || "-"}
                                  </Col>
                                  <Col>Area</Col>
                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.PMS_area || "-"}
                                  </Col>
                                </Row>
                              )}

                              {!!item?.shock && (
                                <Row>
                                  <Col className="area-checkbox-pt-note ">
                                    <Checkbox checked={item?.shock} />
                                  </Col>
                                  <Col> Shock Wave :</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.shock_wave || "-"}
                                  </Col>
                                  <Col> bar</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.shock_bar || "-"}
                                  </Col>
                                  <Col>Hz Area</Col>
                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.shock_area || "-"}
                                  </Col>
                                </Row>
                              )}

                              {!!item?.HPLT && (
                                <Row>
                                  <Col className="area-checkbox-pt-note">
                                    <Checkbox checked={item?.HPLT} />
                                  </Col>
                                  <Col> HPLT :</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.HPLT_watt || "-"}
                                  </Col>
                                  <Col> watt Area</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.HPLT_area || "-"}
                                  </Col>
                                </Row>
                              )}

                              {!!item?.hypervolt && (
                                <Row>
                                  <Col className="area-checkbox-pt-note">
                                    <Checkbox checked={item?.hypervolt} />
                                  </Col>
                                  <Col> Hypervolt :</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.hypervolt_text || "-"}
                                  </Col>
                                  <Col> (area)</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.hypervolt_area || "-"}
                                  </Col>
                                </Row>
                              )}

                              {!!item?.stretching && (
                                <Row>
                                  <Col className="area-checkbox-pt-note">
                                    <Checkbox checked={item?.stretching} />
                                  </Col>
                                  <Col> Stretching :</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.stretching_text || "-"}
                                  </Col>
                                  <Col> (area)</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.stretching_area || "-"}
                                  </Col>
                                </Row>
                              )}

                              {!!item?.manual && (
                                <Row>
                                  <Col className="area-checkbox-pt-note">
                                    <Checkbox checked={item?.manual} />
                                  </Col>
                                  <Col> Manual Release :</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.manual_release || "-"}
                                  </Col>
                                  <Col> (area)</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.manual_area || "-"}
                                  </Col>
                                </Row>
                              )}
                              {!!item?.home && (
                                <Row>
                                  <Col className="area-checkbox-pt-note">
                                    <Checkbox checked={item?.home} />
                                  </Col>
                                  <Col> Home Program :</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.home_program || "-"}
                                  </Col>
                                </Row>
                              )}

                              {!!item?.mobilization && (
                                <Row>
                                  <Col className="area-checkbox-pt-note">
                                    <Checkbox checked={item?.mobilization} />
                                  </Col>
                                  <Col> Mobilization :</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.mobilization_text || "-"}
                                  </Col>
                                </Row>
                              )}
                              {!!item?.other && (
                                <Row>
                                  <Col className="area-checkbox-pt-note">
                                    <Checkbox checked={item?.other} />
                                  </Col>
                                  <Col>Other :</Col>

                                  <Col
                                    style={{ minWidth: 100 }}
                                    className="t-underline area-checkbox-pt-note pl-1 t-grey-form"
                                  >
                                    {item?.other_text || "-"}
                                  </Col>
                                </Row>
                              )}

                              {visitsByID?.pTNote?.ptNoteList?.length !==
                                index + 1 ? (
                                <Divider className="mt-1 mb-1 b-grey-white" />
                              ) : null}
                            </React.Fragment>
                          );
                        })}
                      </td>
                      <td>
                        {!!visitsByID?.pTNote?.image && (
                          <img
                            width={150}
                            src={visitsByID?.pTNote?.image}
                            alt="image_sign"
                          />
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </React.Fragment>
            )}
          </div>
        </div>
      </div>
      {visible && (
        <ModalPTNote
          visible={{ visible: visible, setVisible: setVisible }}
          title={title}
          visitsByID={visitsByID}
        />
      )}
    </div>
  );
};

export default PTNote;
