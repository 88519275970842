import React, { useState } from "react";
import TablePreRegistrationToday from "./Table";
import SearchPreRegistrationToday from "./Search";

const PreRegistrationToday = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <div>
      <SearchPreRegistrationToday
        setSearchValue={setSearchValue}
        setCurrentPage={setCurrentPage}
      />
      <TablePreRegistrationToday
        searchValue={searchValue}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </div>
  );
};

export default PreRegistrationToday;
