import { useEffect } from "react";
import { USER_MANAGE } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useGetRoomByID } from "@services/api/rooms";
import { useGetBranchList } from "@services/api/branch";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { useCreateRoom, useUpdateRoom } from "@services/api/rooms";
import { Card, Col, Form, FormInstance, Input, Row } from "antd";
import React from "react";
import Selector from "@components/selector/Selector";
import TextArea from "antd/lib/input/TextArea";
import UserContext from "@provider/UserProvider";

type Props = {
  formRoom: FormInstance;
  roomID: number;
  history: RouteComponentProps["history"];
  medicalRecord: boolean;
};

const Fields = ({ formRoom, roomID, history, medicalRecord }: Props) => {
  const { t, i18n } = useTranslation();
  const branchList = useGetBranchList(true, { limit: 0 });
  const roomByID = useGetRoomByID(roomID);
  const roomUpdate = useUpdateRoom();
  const roomCreate = useCreateRoom();
  const userList = React.useContext(UserContext);

  useEffect(() => {
    formRoom.resetFields();
    if (userList?.role !== "SUPER_ADMIN") {
      formRoom.setFieldsValue({
        ...roomByID?.data?.result?.[0],
        color: roomByID?.data?.result?.[0]?.color || "#004c97",
        branchId:
          roomByID?.data?.result?.[0]?.branchId ||
          branchList?.data?.result?.[0]?.data?.[0]?.id,
      });
    } else
      formRoom.setFieldsValue({
        ...roomByID?.data?.result?.[0],
        color: roomByID?.data?.result?.[0]?.color || "#004c97",
        branchId: roomByID?.data?.result?.[0]?.branchId || undefined,
      });

    //eslint-disable-next-line
  }, [roomByID?.data]);

  const onFinish = (value: any) => {
    if (roomID) {
      roomUpdate.mutate(
        { id: roomID, data: value },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
            history.replace(USER_MANAGE.title);
          },
          onError: (res) => {
            openNotification({
              method: "PUT_ERROR",
              message: String(res),
            });
          },
        }
      );
    } else {
      roomCreate.mutate(
        { data: value },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
            history.replace(USER_MANAGE.title);
          },
          onError: (res) => {
            openNotification({
              method: "POST_ERROR",
              message: String(res),
            });
          },
        }
      );
    }
  };
  return (
    <Card
      className="card-prefix card-prefix-width card-prefix-chi"
      extra={
        <b className="fs-14 c-white ">
          {roomID ? `${t("editRoom")}` : `${t("createRoom")}`}
        </b>
      }
    >
      <Form onFinish={onFinish} name="formRoom" form={formRoom}>
        <Row gutter={[14, 12]}>
          <Col md={24} lg={12}>
            <Form.Item
              label={`${t("code")}`}
              rules={[{ required: true }]}
              name="code"
            >
              <Input disabled={!medicalRecord} />
            </Form.Item>
          </Col>

          <Col md={24} lg={12}>
            <Form.Item
              label={`${t("name")}`}
              rules={[{ required: true }]}
              name="name"
            >
              <Input disabled={!medicalRecord} />
            </Form.Item>
          </Col>

          <Col md={24} lg={24}>
            <Form.Item label={`${t("details")}`} name="description">
              <TextArea
                autoSize={{ minRows: 2, maxRows: 3 }}
                disabled={!medicalRecord}
              />
            </Form.Item>
          </Col>

          <Col md={24} lg={12}>
            <Form.Item label={`${t("tags")}`} name="tags">
              <Input disabled={!medicalRecord} />
            </Form.Item>
          </Col>

          <Col md={24} lg={12}>
            <Form.Item label={`${t("color")}`} name="color">
              <Input type={"color"} disabled={!medicalRecord} />
            </Form.Item>
          </Col>

          <Col md={24} lg={24}>
            <Form.Item
              label={`${t("branch")}`}
              rules={[{ required: true, message: t("pleaseSelect") }]}
              name="branchId"
            >
              <Selector
                Source={branchList?.data?.result?.[0]?.data || []}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                disabled={!medicalRecord}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default Fields;
