import React, { useState } from "react";
import { OPD } from "@assets/data/pathRouter";
import { ENUM_G6PD } from "@tools/util.enum";
import { useHistory } from "react-router";
import { TextBRHTML } from "@tools/util";
import { IGetVisits } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, useRouteMatch } from "react-router-dom";
import { Button, Card, Col, Form, FormInstance, Row } from "antd";
import Link from "@assets/img/icon/Link.svg";
import File from "@assets/img/icon/File.svg";
import ViewImage from "@components/drawing_canvas/ViewImage";
import SymbolView from "@components/symbol/Symbol";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";

type Props = {
  byID: number | string;
  visitsByID?: IGetVisits;
  patientList: FormInstance;
  visitsID?: number;
};

const Profile = ({ byID, visitsByID, patientList, visitsID }: Props) => {
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const eventID = match.params.eventId;
  const [visible, setVisible] = useState<boolean>(false);
  const history = useHistory<RouteComponentProps["history"]>();

  const editPrintDraw = () => {
    history.push(
      `${OPD.leadingSymptoms}/patient/${byID}/visit/${visitsID}${
        !!eventID ? `/event/${eventID}` : ``
      }`
    );
  };

  const openModal = () => {
    setVisible(!visible);
  };

  return (
    <div>
      <Card
        className="bg-grey height-body"
        extra={
          <>
            <Row
              gutter={[14, 14]}
              className="text-row-profile"
              align="middle"
              justify="center"
            >
              <Col>
                <ImagePatient src={visitsByID?.patient?.image} />
              </Col>
              <Col>
                <Row>
                  <Col xs={24} lg={24}>
                    {i18n.language === "th"
                      ? ` ${visitsByID?.patient?.firstNameTh || " "}    ${
                          visitsByID?.patient?.lastNameTh || " "
                        }`
                      : ` ${visitsByID?.patient?.firstNameEn || " "}    ${
                          visitsByID?.patient?.lastNameEn || " "
                        }`}
                  </Col>

                  <Col xs={24} lg={24}>
                    <div className="code-profile">
                      HN : {visitsByID?.patient?.hn}
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col style={{ marginLeft: "auto" }}>
                <div style={{ color: "#FEB139" }}>
                  <SymbolView />
                </div>
                <div
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {visitsByID?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                    ? "G6PD Deficiency"
                    : null}
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <Form name="patientList" form={patientList}>
          <ImportantInfo />
        </Form>

        <Card
          className="comment-card mt-2 "
          extra={
            <React.Fragment>
              <Row gutter={[14, 14]} align="middle">
                <Col>
                  {`${t("basicInformation")} / ${t("importantSymptoms")}`}
                </Col>
                <Col style={{ marginLeft: "auto" }} className=" link-btn">
                  <Button type="link" onClick={openModal}>
                    {t("seePaintings")}
                  </Button>
                  <Button type="link" onClick={editPrintDraw}>
                    {t("edit")}
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          }
        >
          <div
            className="t-black-light"
            dangerouslySetInnerHTML={{
              __html: TextBRHTML(visitsByID?.data?.print || ""),
            }}
          ></div>
          {visitsByID?.visitFile?.radiology && (
            <div className="f-normal mt-2 mb-1">Radiology Result</div>
          )}
          {visitsByID?.visitFile?.radiology &&
            visitsByID?.visitFile?.radiology?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })}

          {visitsByID?.visitFile?.medical && (
            <div className="f-normal mt-2 mb-1">Medical Report</div>
          )}

          {visitsByID?.visitFile?.medical &&
            visitsByID?.visitFile?.medical?.map((item, index) => {
              return (
                <Row
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                  key={index}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })}
          {visitsByID?.visitFile?.summary && (
            <div className="f-normal mt-2 mb-1">Summary Result</div>
          )}

          {visitsByID?.visitFile?.summary &&
            visitsByID?.visitFile?.summary?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })}
        </Card>
      </Card>
      {visible && (
        <ViewImage
          visible={visible}
          setModal={openModal}
          drawing={visitsByID?.data?.drawing}
        />
      )}
    </div>
  );
};

export default Profile;
