import { branchIDState } from "@tools/recoil/recoil-atom";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { useGetAgentsList } from "@services/api/agent";
import { knowAbsolute, right } from "@assets/data/data";
import { Card, Col, DatePicker, Form, FormInstance, Input, Row } from "antd";
import moment from "moment";
import Selector from "@components/selector/Selector";

const AboutAbsoluteHealth = () => {
  const { t, i18n } = useTranslation();
  const { TextArea } = Input;
  const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY"];
  const branchID = useRecoilValue(branchIDState);
  const agentsList = useGetAgentsList({ branchId: branchID, limit: 0 });

  const disabledDate = (current: any) => {
    return current && current > moment().endOf("day");
  };

  return (
    <div>
      <Card className="cardTop">
        <div className="title-in-card">
          {`${t("part")} 3 ${t("about")} Absolute Health`}
        </div>
        <Row gutter={[14, 14]} className="margin-form mt-1">
          <Col lg={8}>
            <Form.Item
              label={t("rightsNursery")}
              name="privilege"
              id="privilege"
            >
              <Selector
                placeholder=""
                Source={right}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                onChange={() => {
                  document.getElementById("agentId")?.focus();
                }}
              />
            </Form.Item>
          </Col>

          <Col lg={8}>
            <Form.Item
              label={t("firstTakeCare")}
              name="firstVisit"
              id="firstVisit"
            >
              <DatePicker
                placeholder={`${t("date")}/${t("month")}/${t("AD")}`}
                disabledDate={disabledDate}
                format={dateFormatList}
                onChange={() => {
                  document.getElementById("agentId")?.focus();
                }}
              />
            </Form.Item>
          </Col>

          <Col lg={8}>
            <Form.Item label={t("recommender")} name="agentId" id="agentId">
              <Selector
                placeholder=""
                Source={agentsList?.data?.result?.[0]?.data || []}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
                onChange={() => {
                  document.getElementById("firstMeetHow")?.focus();
                }}
              />
            </Form.Item>
          </Col>

          <Col lg={12}>
            <Form.Item
              label={t("howAbsolute")}
              name="firstMeetHow"
              id="firstMeetHow"
            >
              <Selector
                placeholder=""
                Source={knowAbsolute}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="id"
              />
            </Form.Item>
          </Col>

          <Form.Item
            shouldUpdate={(prev, next) =>
              prev.firstMeetHow !== next.firstMeetHow
            }
            noStyle
          >
            {(form: FormInstance) => {
              const firstMeetHow = form.getFieldValue("firstMeetHow");

              if (firstMeetHow === "8") {
                return (
                  <Col lg={12}>
                    <Form.Item
                      label={t("howAbsolute")}
                      name="firstMeetHow1"
                      id="firstMeetHow1"
                    >
                      <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                    </Form.Item>
                  </Col>
                );
              }
            }}
          </Form.Item>

          <Col lg={12}>
            <Form.Item label={t("remarks")} name="remark" id="remark">
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default AboutAbsoluteHealth;
