import { Card, Col, Row, Slider } from "antd";
import { RightOutlined } from "@ant-design/icons";

type Props = {
  setSize: any;
  size: number;
};

const Eraser = ({ setSize, size }: Props) => {
  const onChange = (value: any) => {
    setSize(value);
  };

  return (
    <div>
      <Row>
        <Col span={2} className="ml-1">
          <RightOutlined className="eraser" />
        </Col>
        <Col span={18}>
          <Card className="mt-2">
            <Row>
              <Col span={24}>
                <Slider
                  min={1}
                  max={100}
                  onChange={onChange}
                  value={typeof size === "number" ? size : 0}
                />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default Eraser;
