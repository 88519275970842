import { Form } from "antd";
import { useEffect } from "react";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useSetRecoilState } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { IAddedSlots, IDateChange } from "@interface/ISlots.interface";
import { useCreateSlot, useDeleteSlot } from "@services/api/slots";
import { useGetSlotDateList, useUpdateSlot } from "@services/api/slots";
import dayjs from "dayjs";
import moment from "moment";
import SchedulerQueue from "@components/slots/queue/Queue";

type Props = {
  currentDateChange: IDateChange;
};

const Scheduler = ({ currentDateChange }: Props) => {
  const viewSwitcher = "Day";
  const setLoadingButton = useSetRecoilState<boolean>(loadingButtonState);
  const [formSearch] = Form.useForm();
  const client = useQueryClient();
  const createSlot = useCreateSlot();
  const updateSlot = useUpdateSlot();
  const slotDateList = useGetSlotDateList();
  const deleteSlot = useDeleteSlot();

  useEffect(() => {
    getSlotDateList();
    // eslint-disable-next-line
  }, [currentDateChange.currentDateChange]);

  useEffect(() => {
    setLoadingButton(createSlot.isLoading);

    // eslint-disable-next-line
  }, [createSlot.isLoading]);

  const getSlotDateList = (values?: any) => {
    const current = dayjs(currentDateChange.currentDateChange);
    let startDate: string = current.startOf("day").format("YYYY-MM-DD");
    let endDate = current.endOf("day").format("YYYY-MM-DD");
    slotDateList.mutate({
      slotType: "DOCTOR",
      startDate: startDate,
      endDate: endDate,
      limit: 0,
      ...values,
    });
  };

  const onFinish = (values: any) => {
    formSearch.setFieldsValue({
      ...values,
    });
    getSlotDateList(values);
  };

  const handleCreateSlots = async (added: IAddedSlots) => {
    const add = {
      ...added,
      slotType: "DOCTOR",
      startTime: moment(added.startDate).format("HH:mm:ssZ"),
      endTime: moment(added.endDate).format("HH:mm:ssZ"),
    };

    createSlot.mutate(add, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        getSlotDateList();
        client.invalidateQueries(["get-slot-room"]);
      },
      onError: () => {
        openNotification({ method: "POST_ERROR" });
      },
    });
  };

  const handleUpdateSlots = async (changed: IAddedSlots) => {
    const update = {
      ...changed,
      startTime: moment(changed.startDate).format("HH:mm:ssZ"),
      date: String(moment(changed.startDate).format("YYYY-MM-DD")),
      endTime: moment(changed.endDate).format("HH:mm:ssZ"),
    };

    updateSlot.mutate(
      { id: changed.id, data: update },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          getSlotDateList();
          client.invalidateQueries(["get-slot-room"]);
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
      }
    );
  };

  const handleDeleteSlots = async (id: number) => {
    deleteSlot.mutate(id, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
        getSlotDateList();
        client.invalidateQueries(["get-slot-room"]);
      },
      onError: (res) => {
        openNotification({ method: "DEL_ERROR", message: String(res) });
      },
    });
  };

  return (
    <div className="height-body scrollbar-list">
      <SchedulerQueue
        viewSwitcher={viewSwitcher}
        dataScheduler={slotDateList?.data?.result?.[0]?.data || []}
        handleCreateSlots={handleCreateSlots}
        handleUpdateSlots={handleUpdateSlots}
        handleDeleteSlots={handleDeleteSlots}
        searchOnFinish={onFinish}
        formSearch={formSearch}
        currentDateChange={currentDateChange}
      />
    </div>
  );
};

export default Scheduler;
