import { Form } from "antd";
import { useTranslation } from "react-i18next";
import FormGeneral from "../../form/Form";

const FormNationality = () => {
  const { t } = useTranslation();
  const [confirmNationality] = Form.useForm();
  return (
    <div>
      <FormGeneral title={t("nationality")} confirm={confirmNationality} />
    </div>
  );
};

export default FormNationality;
