import { routers } from "../routes/router";
import { Layout, Spin } from "antd";
import { Switch, Route } from "react-router-dom";
import { useIsFetching } from "react-query";
import React from "react";
import UserContext from "@provider/UserProvider";

const TheContent = () => {
  const { Content } = Layout;
  const isFetching = useIsFetching();
  const userList = React.useContext(UserContext);

  let isEdit: string[] = [];

  const removeRoute = () => {
    const parentMenu =
      userList?.permission?.flatMap((item) => {
        if (item?.isEdit) {
          isEdit = [...(isEdit || []), `${item?.nameEn}-EDIT`];
        }
        return item?.nameEn;
      }) || [];
    const childrenMenu =
      userList?.permission
        ?.map((item) => {
          return item?.subMenu?.map((subMenu) => {
            if (!!subMenu?.isEdit) {
              isEdit = [...(isEdit || []), `${subMenu?.nameEn}-EDIT`];
            }
            return subMenu?.nameEn;
          });
        })
        ?.flat(Infinity) || [];

    const newRoutes = routers?.filter((item) => {
      return (
        [
          ...parentMenu,
          ...childrenMenu,
          ...isEdit,
          "NOPAGE",
          "LOGIN",
          "",
        ]?.indexOf(item?.authMenuName) !== -1
      );
    });
    return newRoutes;
  };

  return (
    <React.Fragment>
      <Spin
        style={{ background: "rgba(0, 0, 0, 0.05)" }}
        spinning={isFetching ? true : false}
        size="large"
        tip="กำลังโหลด..."
        delay={500}
      >
        <Content style={{ margin: "0 16px" }}>
          <div>
            <Switch>
              {removeRoute()?.map((route, ind) => (
                <Route
                  exact={route?.exact}
                  key={ind}
                  path={route?.path}
                  component={route?.component}
                />
              ))}
            </Switch>
          </div>
        </Content>
      </Spin>
    </React.Fragment>
  );
};

export default TheContent;
