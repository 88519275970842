import { dayjs } from "@tools/timezone";
import { COUPON } from "@assets/data/pathRouter";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { useGetCouponList } from "@services/api/coupon";
import { useEffect, useState } from "react";
import { COUPON_STATUS, ENUM_ORDERBY } from "@tools/util.enum";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  searchValue: any;
  currentPage: ICurrentPage;
};

const Table = ({ searchValue, currentPage }: Props) => {
  const { t } = useTranslation();
  const status: {
    [K in COUPON_STATUS]: {
      name: string;
      color: string;
    };
  } = {
    [COUPON_STATUS.DRAFT]: {
      name: t("draft"),
      color: "#00377C",
    },
    [COUPON_STATUS.PUBLIC]: {
      name: t("publish"),
      color: "#77C48B",
    },
    [COUPON_STATUS.EXPIRED]: {
      name: t("expire"),
      color: "#FF5D5D",
    },
  };
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const [total, setTotal] = useState<number>(1);
  const couponList = useGetCouponList({
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.ASC,
    ...searchValue,
  });

  useEffect(() => {
    if (!couponList?.data?.result?.[0]?.total) return;
    setTotal(couponList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [couponList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${COUPON.detail}/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },
    {
      title: t("code"),
      dataIndex: "code",
      key: "code",
      render: (code: string) => {
        return <div>{code || "-"}</div>;
      },
    },

    {
      title: t("coupon.name"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("discounts"),
      dataIndex: "discountPercent",
      key: "discountPercent",
      render: (discount: string) => {
        return <div className="f-normal ">{discount}</div>;
      },
    },

    {
      title: `${t("spent")}/${t("all")}`,
      dataIndex: "qtyUse",
      key: "qtyUse",
      render: (qtyUse: string, data: any) => {
        return <div>{`${data?.qtyUse} / ${qtyUse}`}</div>;
      },
    },

    {
      title: t("startDate"),
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: string) => {
        return <>{dayjs(startDate).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: t("endDate"),
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: string) => {
        return <>{dayjs(endDate).format("DD-MM-YYYY")}</>;
      },
    },
    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      render: (data: COUPON_STATUS) => {
        return (
          <div
            className="table-queue-status mr-1"
            style={{ backgroundColor: status?.[data]?.color }}
          >
            {status?.[data]?.name}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        rowKey="id"
        dataSource={couponList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
