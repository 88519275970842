import { IVisitsUpDate } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { loadingButtonState } from "@tools/recoil/recoil-atom";
import { Button, Col, Divider, Form, FormInstance, Modal, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";

interface IValue {
  note: boolean;
  nurseNote: boolean;
}

interface IVisible {
  visible: IValue;
  setVisible: (e: IValue) => void;
}

type Props = {
  visible: IVisible | any;
  name: string;
  title: string;
  onFinishUpdate: (e: IVisitsUpDate) => void;
  modalNote: FormInstance;
};

const ModalNote = ({
  visible,
  name,
  title,
  onFinishUpdate,
  modalNote,
}: Props) => {
  const { t } = useTranslation();
  const loadingButton = useRecoilValue(loadingButtonState);

  const onCancel = () => {
    visible.setVisible({
      ...visible.visible,
      [`${name}`]: !visible.visible[`${name}`],
    });
  };

  const onFinish = (value: any) => {
    onFinishUpdate(value);
  };

  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col md={20} lg={20} className="t-blue ">
              <b>{title}</b>
            </Col>
            <Col md={4} lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={visible.visible}
      onOk={() => visible.setVisible(false)}
      onCancel={onCancel}
      width="70%"
      footer={null}
    >
      <div className="scroll">
        <Form
          onFinish={onFinish}
          className="p-2"
          name="modalNote"
          form={modalNote}
        >
          <Row gutter={[14, 14]} className="margin-form">
            <Col md={24} lg={24}>
              <Form.Item label={title} name={name} className="modal-note">
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                onClick={() => modalNote.submit()}
                loading={loadingButton}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalNote;
