import React, {
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { DatePicker, InputNumber, Row } from "antd";
import { Form, Table } from "antd";
import type { FormInstance } from "antd/es/form";
import { dayjs } from "@tools/timezone";

export const EditableContext = React.createContext<FormInstance<any> | null>(
  null
);

export interface Item {
  key: string;
  name: string;
  age: string;
  address: string;
}

export interface EditableRowProps {
  index: number;
}
export interface EditableCellProps {
  title: React.ReactNode;
  editable: boolean;
  children: React.ReactNode | any;
  dataIndex: keyof Item;
  record: Item;
  inputType?: string;
  handleSave: (record: Item) => void;
}

export type EditableTableProps = Parameters<typeof Table>[0];

export type ColumnTypes = Exclude<EditableTableProps["columns"], undefined>;

export const EditableRow: React.FC<EditableRowProps> = ({
  index,
  ...props
}) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

export const EditableCell: React.FC<EditableCellProps> = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  inputType,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef<any>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]:
        inputType === "DatePicker"
          ? record?.[dataIndex] !== undefined
            ? dayjs(record?.[dataIndex])
            : undefined
          : record?.[dataIndex],
    });
  };

  const ReCheckDate = (obj: unknown | any) => {
    const text = obj?.format("YYYY-MM-DD");
    return text;
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      const a = record as any;
      console.log("a", a.qty);
      console.log("value", values.canGive);
      console.log(values);

      let refact: any = {};
      for (const [key, value] of Object.entries(values)) {
        const checkDate =
          typeof value === "object" &&
          inputType === "DatePicker" &&
          ReCheckDate(value);
        if (checkDate) {
          refact = { key: key, value: checkDate };
        }
      }
      toggleEdit();
      handleSave({
        ...record,
        ...values,
        [refact?.key]: refact?.value,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `กรุณาป้อนข้อมูล`,
          },
        ]}
      >
        {inputType === "DatePicker" ? (
          <DatePicker
            ref={inputRef}
            format={"YYYY-MM-DD"}
            onBlur={save}
            placeholder="เลือกวันที่"
          />
        ) : inputType === "InputNumber" ? (
          <InputNumber
            min={0}
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            type="number"
            placeholder="ป้อน"
          />
        ) : (
          // <Input ref={inputRef} onPressEnter={save} onBlur={save} />
          <div></div>
        )}
      </Form.Item>
    ) : (
      <>
        <Row
          align={"middle"}
          style={{ paddingRight: 24, height: 40 }}
          onClick={toggleEdit}
        >
          {children?.[1]?.type === "div" ? (
            children
          ) : (
            <div
              style={{
                border: "1px solid #d9d9d9",
                height: 40,
                display: "flex",
                width: 200,
                borderRadius: 4,
                alignItems: "center",
                paddingLeft: 10,
              }}
            >
              {children}
            </div>
          )}
        </Row>
      </>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

export const components = {
  body: {
    row: EditableRow,
    cell: EditableCell,
  },
};

const EditTable = ({
  defaultColumns,
  dataSource,
  onSave,
  rowKey,
  scroll,
  isLoading = false,
  ...props
}:
  | {
      rowKey: string;
      defaultColumns: (ColumnTypes[number] & {
        editable?: boolean;
        dataIndex: string;
        inputType?: string;
      })[];
      dataSource: any[];
      onSave: (value?: any) => void;
      scroll?: { x?: number; y?: number };
      isLoading?: boolean;
    }
  | any) => {
  const [dataTable, setDataTable] = useState<any[]>([]);

  useEffect(() => {
    setDataTable(dataSource);
  }, [dataSource]);

  const handleSave = (row: any) => {
    const newData = [...dataTable];
    const index = newData?.findIndex(
      (item) => row?.[rowKey] === item?.[rowKey]
    );

    const item = newData[index];

    if (!!row?.deliveries) {
      newData?.splice(index, 1, {
        ...item,
        ...row,
        deliveries:
          Number(row?.deliveries) > Number(item?.qtyOutstanding) ||
          Number(row?.deliveries) < 0
            ? item?.qtyOutstanding
            : row?.deliveries,
      });
    } else {
      newData?.splice(index, 1, {
        ...item,
        ...row,
        qty: !!row?.qty ? Number(row?.qty) : undefined,
      });
    }

    onSave(newData);
    setDataTable(newData);
  };

  const columns = defaultColumns?.map(
    (col: {
      editable: boolean;
      dataIndex: any;
      title: string | ReactNode;
      inputType: string;
    }) => {
      if (!col?.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record: any) => ({
          record,
          editable: col?.editable,
          dataIndex: col?.dataIndex,
          title: col?.title,
          inputType: col?.inputType,
          handleSave,
        }),
      };
    }
  );

  return (
    <div>
      <Table
        rowKey={rowKey || ""}
        components={components}
        rowClassName={() => "editable-row"}
        // bordered
        pagination={false}
        dataSource={dataTable}
        columns={columns as ColumnTypes}
        scroll={scroll}
        {...props}
      />
    </div>
  );
};

export default EditTable;
