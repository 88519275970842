import React, { useState } from "react";
import { dayjs } from "@tools/timezone";
import { IGetVisits } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { Card, Col, Form, FormInstance, Row } from "antd";
import Advice from "@components/advice/Advice";
import ImagePatient from "@components/image/ImagePatient";
import TableLabDetail from "../table/Table";
import ImportantInfo from "@components/historical_info/ImportantInfo";

type Props = {
  visitByID?: IGetVisits;
  patientList: FormInstance;
};

const Fields = ({ visitByID, patientList }: Props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(true);

  const viewDetail = () => {
    setOpen(!open);
  };

  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room height-body  "
        extra={
          <>
            <Row gutter={[14, 14]} className="text-row-profile ml-05">
              <Col xs={4} md={4} lg={3} xl={2}>
                <ImagePatient src={visitByID?.patient?.image} />
              </Col>
              <Col xs={13} lg={13}>
                <Row>
                  <Col xs={24} lg={24}>
                    {i18n.language === "th"
                      ? `${visitByID?.patient?.firstNameTh || ""} ${
                          visitByID?.patient?.lastNameTh || ""
                        }`
                      : `${visitByID?.patient?.firstNameEn || ""} ${
                          visitByID?.patient?.lastNameEn || ""
                        }`}
                  </Col>

                  <Col xs={24} lg={24}>
                    <div className="code-profile">
                      HN : {`${visitByID?.patient?.hn || ""} `}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col sm={9} lg={9} className="col-end pr-15">
                <div className="t-center ">
                  <div className=" t-grey fs-18 ">{t("orderNumber")} </div>
                  <div className=" t-grey-light fs-12 "> </div>
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <div className="mb-05">
          <span className=" t-blue fs-18 ">{t("fullList")} </span>
          <span className=" t-grey-light  fs-12 ">
            {`${t("orderCreationDate")}:`}{" "}
          </span>
          <span className="t-grey fs-12 ">
            {dayjs(visitByID?.date).format("DD-MM-YYYY") || ""}
          </span>
        </div>
        <Form name="basicInfoForm" form={patientList}>
          <ImportantInfo />
        </Form>
        <Advice visitsByID={visitByID} />

        <div className="mt-2 ">
          <Row className=" header-table ">
            <Col className="f-normal  t-dark-blue  ">
              <span className="f-bold mr-05 ">{`${t("list")}${t(
                "doctor"
              )}`}</span>
              <span>
                {React.createElement(open ? UpOutlined : DownOutlined, {
                  onClick: viewDetail,
                })}
              </span>
            </Col>
          </Row>

          <div className={open ? "open" : "close"}>
            <TableLabDetail />
          </div>
        </div>
      </Card>
    </>
  );
};

export default Fields;
