import { Pagination, PaginationProps } from "antd";

interface Props extends PaginationProps {
  onChangePage?: (page: number, pageSize?: string) => void;
  config?: any;
}

const PaginationPage = ({ onChangePage, config }: Props) => {
  return (
    <div className=" mb-2 w-100 d-flex justify-flex-end">
      <Pagination
        onChange={(page) => onChangePage && onChangePage(page, "page")}
        total={config.total}
        current={config.currentPage}
        pageSize={config.pageSize}
        className="pagi mt-05"
        onShowSizeChange={(_current, pageSize) =>
          onChangePage && onChangePage(pageSize, "pageSize")
        }
      />
    </div>
  );
};

export default PaginationPage;
