import React, { useEffect, useState } from "react";
import { dayjs } from "@tools/timezone";
import { IUserRoom } from "@interface/IUserRoom.interface";
import { IFormSlots } from "@interface/ISlots.interface";
import { IDateSlots } from "./interface";
import { ENUM_SLOT_TYPE } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { RangePickerProps } from "antd/lib/date-picker";
import { generateUniqueId } from "@tools/util";
import { useGetUserRoomUser } from "@services/api/user_room";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { Checkbox, Col, DatePicker } from "antd";
import { MinusCircleFilled, PlusCircleFilled } from "@ant-design/icons";
import { daySelect, notificationDetail, selectDate } from "@assets/data/data";
import { Form, FormInstance, Radio, Row, TimePicker } from "antd";
import moment from "moment";
import Period from "@assets/img/icon/OPD/period.svg";
import Selector from "../../selector/Selector";
import Notification from "@assets/img/icon/OPD/notification.svg";

interface IFormField {
  label: string;
  date: string;
  startDate: string;
  endDate: string;
}

type Props = {
  dataSlots: IFormSlots;
  CommitChanges: (added: any) => void;
  slotForm: FormInstance;
  setVisible: (e: boolean) => void;
  doctorList: IUserRoom[];
};

const Fields = ({
  dataSlots,
  CommitChanges,
  slotForm,
  setVisible,
  doctorList,
}: Props) => {
  const format = "HH:mm";
  const { t, i18n } = useTranslation();
  const [disabledHours, setDisabledHours] = useState(dataSlots.startDate);
  const [fields, setFields] = useState<IFormField[]>([]);
  const [roomList, setRoomList] = useState<number | null>(null);
  const userRoomUser = useGetUserRoomUser(roomList);

  useEffect(() => {
    setVisible(true);
    const uniqueId = generateUniqueId();
    const initial: IFormField = {
      label: "field 1",
      date: `date_${uniqueId}`,
      startDate: `startDate_${uniqueId}`,
      endDate: `endDate_${uniqueId}`,
    };
    setFields([...(fields || []), initial]);

    slotForm.setFieldsValue({
      selectDate: 1,
      userId: dataSlots?.userId,
      [initial?.date]: moment(dataSlots?.startDate),
      [initial?.startDate]: moment(dataSlots?.startDate),
      [initial?.endDate]: moment(dataSlots?.endDate),
      dateWeek: moment(dataSlots?.startDate),
      startDateWeek: moment(dataSlots?.startDate),
      endDateWeek: moment(dataSlots?.endDate),
      dateMonth: moment(dataSlots?.startDate),
      startDateMonth: moment(dataSlots?.startDate),
      endDateMonth: moment(dataSlots?.endDate),
      roomId: dataSlots?.roomId && dataSlots?.roomId,
      minutesToNotify: dataSlots?.minutesToNotify,
    });

    // eslint-disable-next-line
  }, []);

  const onChangeTimePicker = (time: any, name: string, nextName?: string) => {
    const unique = name?.split("_")[1];
    var timeNext = moment(time).add(15, "minutes");
    slotForm.setFieldsValue({
      [nextName ? nextName : `endDate_${unique}`]: timeNext,
    });
    setDisabledHours(time);
  };

  const getDisabledHours = () => {
    let hours = [];
    for (let i = 0; i < moment(disabledHours).hour(); i++) {
      hours.push(i);
    }
    return hours;
  };

  const getDisabledMinutes = () => {
    let selectedHour = Number(moment(disabledHours).format("H"));

    let minutes = [];
    if (selectedHour === moment().hour()) {
      for (let i = 0; i < moment(disabledHours).minute(); i++) {
        minutes.push(i);
      }
    }
    return minutes;
  };

  const onFinish = (dataForm: any) => {
    if (dataForm?.selectDate === 1) {
      const { roomId, minutesToNotify, userId, ...rest } = dataForm;
      let obj: {
        unique: string;
        date?: any;
        startDate?: any;
        endDate?: any;
      }[] = [];
      for (const [key, value] of Object.entries(rest)) {
        const prefix = key?.split("_")[0];
        const unique = key?.split("_")[1];
        const find = obj?.some((e) => e?.unique === unique);
        if (find) {
          obj = obj?.map((item) => {
            if (item?.unique === unique) {
              return { ...item, [prefix]: value };
            }
            return item;
          });
        } else if (!!unique) {
          obj = [...obj, { unique: unique, [prefix]: value }];
        }
      }

      if (dataSlots.id != null) {
        const data = {
          ...dataForm,
          id: dataSlots?.id,
        };

        CommitChanges({ changed: data });
      } else {
        const data = {
          ...dataForm,
          slotType: dataForm?.slotType,
          minutesToNotify: dataForm?.minutesToNotify,
          data: obj,
        };

        CommitChanges({ added: data });
      }
    }
    if (dataForm?.selectDate === 2) {
      try {
        const day = dayjs(dataForm?.dateWeek);
        const startDay = day.startOf("week");

        let availableDate: IDateSlots[] = [];
        new Array(7).fill("").forEach((_, index) => {
          if (
            dataForm?.daySelect?.includes(
              startDay?.add(index, "day")?.day()
            ) === true
          ) {
            availableDate = [
              ...availableDate,
              {
                date: startDay?.add(index, "day"),
                endDate: dataForm?.endDateWeek,
                startDate: dataForm?.startDateWeek,
              },
            ];
          }
        });

        const futureDay = availableDate?.filter((item) => {
          if (item?.date?.format("YYYY-MM-DD") < dayjs().format("YYYY-MM-DD")) {
            return null;
          } else {
            return item;
          }
        });

        const data = {
          data: futureDay,
          roomId: dataForm?.roomId,
          minutesToNotify: dataForm?.minutesToNotify || 0,
          userId: dataForm?.userId,
          slotType: ENUM_SLOT_TYPE.DOCTOR,
        };

        CommitChanges({ added: data });
      } catch (err) {
        console.log(err);
      }
    }
    if (dataForm?.selectDate === 3) {
      try {
        const day = dayjs(dataForm?.dateMonth);
        const startDay = day?.startOf("month");

        let availableDate: IDateSlots[] = [];
        new Array(day.daysInMonth())?.fill("")?.forEach((_, index) => {
          if (
            dataForm?.daySelect?.includes(startDay?.add(index, "day").day()) ===
            true
          ) {
            availableDate = [
              ...availableDate,
              {
                date: startDay?.add(index, "day"),
                endDate: dataForm?.endDateMonth,
                startDate: dataForm?.startDateMonth,
              },
            ];
          }
        });
        const futureDay = availableDate?.filter((item) => {
          if (item?.date.format("YYYY-MM-DD") < dayjs().format("YYYY-MM-DD")) {
            return null;
          } else {
            return item;
          }
        });

        const data = {
          data: futureDay,
          roomId: dataForm?.roomId,
          minutesToNotify: dataForm?.minutesToNotify || 0,
          userId: dataForm?.userId,
          slotType: ENUM_SLOT_TYPE.DOCTOR,
        };

        CommitChanges({ added: data });
      } catch (err) {
        console.log(err);
      }
    }

    setVisible(false);
  };

  const addField = (index: number) => {
    const uniqueId = generateUniqueId();
    const next: IFormField[] = [
      ...fields,
      {
        label: `field ${fields?.length + 1}`,
        date: `date_${uniqueId}`,
        startDate: `startDate_${uniqueId}`,
        endDate: `endDate_${uniqueId}`,
      },
    ];
    slotForm.setFieldsValue({
      [`date_${uniqueId}`]: moment(dataSlots?.startDate).add(index, "day"),
      [`startDate_${uniqueId}`]: moment(dataSlots?.startDate),
      [`endDate_${uniqueId}`]: moment(dataSlots?.endDate),
    });
    setFields(next);
  };

  const deleteField = (_field: IFormField) => {
    const next = fields?.filter((item) => {
      return item?.date !== _field?.date;
    });

    setFields(next);
  };

  const onCheckAllChange = (e: CheckboxChangeEvent) => {
    slotForm.setFieldsValue({
      daySelect: e?.target?.checked
        ? daySelect?.flatMap((item) => {
            return item?.value;
          })
        : [],
    });
  };

  const disabledDate: RangePickerProps["disabledDate"] = (current) => {
    return current && current < moment().endOf("day");
  };

  return (
    <Form
      className="p-2"
      onFinish={onFinish}
      layout="vertical"
      name="slotForm"
      form={slotForm}
    >
      <Row gutter={[23, 18]} className="margin-form ">
        <Col md={12} lg={12}>
          <div>{t("doctorName")}</div>
          <div className="mt-1">
            <Form.Item
              name="userId"
              rules={[{ required: true, message: t("pleaseSelect") }]}
            >
              <Selector
                disabled={dataSlots.userId ? true : false}
                Source={doctorList}
                keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                keyValue="userId"
                onChange={(e) => {
                  setRoomList(e);
                  slotForm.setFieldsValue({
                    roomId: undefined,
                  });
                }}
              />
            </Form.Item>
          </div>
        </Col>

        <Col md={12} lg={12}>
          <div>{t("room")}</div>
          <div className="mt-1">
            <Form.Item
              name="roomId"
              rules={[{ required: true, message: t("pleaseSelect") }]}
            >
              <Selector
                disabled={dataSlots?.userId ? true : false}
                Source={userRoomUser?.data?.result?.[0]?.roomIds || []}
                keyName="name"
                keyValue="id"
              />
            </Form.Item>
          </div>
        </Col>

        <Col md={24} lg={24}>
          <Form.Item label={t("type")} name="selectDate" noStyle>
            <Radio.Group>
              {selectDate?.map((item) => {
                return (
                  <Radio key={item?.id} value={item?.id}>
                    {i18n.language === "th" ? item?.nameTh : item?.nameEn}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        </Col>

        <Form.Item
          noStyle
          shouldUpdate={(prev, next) => prev?.selectDate !== next?.selectDate}
        >
          {(form: FormInstance) => {
            const selectDate = form.getFieldValue("selectDate");

            if (selectDate === 1) {
              return (
                <Col md={24} lg={24}>
                  {fields?.map((item, index) => {
                    return (
                      <div key={index}>
                        <Row gutter={[23, 18]} className="mt-1">
                          <Col md={24} lg={11}>
                            <div>{t("date")}</div>
                            <div className="mt-1">
                              <Form.Item
                                name={item?.date}
                                rules={[{ required: true }]}
                              >
                                <DatePicker disabledDate={disabledDate} />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col md={9} lg={5}>
                            <div className="">{`${t("period")}`}</div>
                            <Row className="mt-1">
                              <Col md={4} lg={6} className="icon-modal">
                                <img
                                  className="img-icon-modal"
                                  src={Period}
                                  alt="Period"
                                />
                              </Col>
                              <Col md={20} lg={18}>
                                <Form.Item
                                  name={item?.startDate}
                                  rules={[
                                    {
                                      required: true,
                                      message: t("pleaseSelect"),
                                    },
                                  ]}
                                >
                                  <TimePicker
                                    format={format}
                                    onChange={(e) =>
                                      onChangeTimePicker(e, item?.startDate)
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={2} lg={1}>
                            <div className="mt-3 t-to">to</div>
                          </Col>

                          <Col md={7} lg={4} className="mt-input">
                            <Form.Item
                              name={item?.endDate}
                              rules={[
                                { required: true, message: t("pleaseSelect") },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      moment(
                                        getFieldValue(item?.startDate)
                                      ).isAfter(value) ||
                                      moment(
                                        getFieldValue(item?.startDate)
                                      ).format("HH:mm") ===
                                        moment(value).format("HH:mm")
                                    ) {
                                      slotForm.setFieldsValue({
                                        [`${item?.endDate}`]: moment(
                                          getFieldValue(item?.startDate)
                                        ).add(15, "minutes"),
                                      });
                                      return Promise.resolve();
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <TimePicker
                                format={format}
                                disabledHours={getDisabledHours}
                                disabledMinutes={getDisabledMinutes}
                              />
                            </Form.Item>
                          </Col>
                          <Col md={2} lg={1} className="minusCircle-t ">
                            {fields?.length > 1 ? (
                              <MinusCircleFilled
                                className="minusCircle"
                                onClick={(e) => deleteField(item)}
                                key={index}
                              />
                            ) : (
                              <MinusCircleFilled
                                className="minusCircle-nodelete"
                                key={index}
                              />
                            )}
                          </Col>
                          <Col className="minusCircle-t  minusCircle-l">
                            <PlusCircleFilled
                              className="plusCircle"
                              onClick={(e) => addField(fields?.length)}
                              key={index}
                            />
                          </Col>
                        </Row>
                      </div>
                    );
                  })}
                </Col>
              );
            } else if (selectDate === 2 || selectDate === 3) {
              return (
                <Col span={24}>
                  <Row gutter={[23, 18]} align="middle" className="mt-1">
                    <Col>
                      <Form.Item
                        noStyle
                        shouldUpdate={(prev, next) =>
                          prev.daySelect !== next.daySelect
                        }
                      >
                        {(form: FormInstance) => {
                          const daySelect = form.getFieldValue("daySelect");

                          if (
                            daySelect?.length !== 7 ||
                            daySelect === undefined
                          ) {
                            form.setFieldsValue({ dayAll: false });
                          } else if (daySelect?.length === 7) {
                            form.setFieldsValue({ dayAll: true });
                          }

                          return (
                            <Form.Item name="dayAll" valuePropName="checked">
                              <Checkbox
                                indeterminate={
                                  daySelect?.length === 7 ||
                                  daySelect === undefined
                                    ? false
                                    : daySelect?.length < 1
                                    ? false
                                    : true
                                }
                                value="all"
                                onChange={onCheckAllChange}
                              >
                                {t("all")}
                              </Checkbox>
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>

                    <Col sm={24} lg={19}>
                      <Form.Item name="daySelect">
                        <Checkbox.Group options={daySelect} />
                      </Form.Item>
                    </Col>
                    {selectDate === 2 ? (
                      <React.Fragment>
                        <Col md={24} lg={12}>
                          <div>{t("date")}</div>
                          <div className="mt-1">
                            <Form.Item
                              name="dateWeek"
                              rules={[{ required: true }]}
                            >
                              <DatePicker
                                picker="week"
                                format={"YYYY-MM-DD"}
                                disabledDate={disabledDate}
                              />
                            </Form.Item>
                          </div>
                        </Col>
                        <Col md={10} lg={6}>
                          <div className="">{`${t("period")}`}</div>
                          <Row className="mt-1">
                            <Col md={4} lg={6} className="icon-modal">
                              <img
                                className="img-icon-modal"
                                src={Period}
                                alt="Period"
                              />
                            </Col>
                            <Col md={20} lg={18}>
                              <Form.Item
                                name="startDateWeek"
                                rules={[
                                  {
                                    required: true,
                                    message: t("pleaseSelect"),
                                  },
                                ]}
                              >
                                <TimePicker
                                  format={format}
                                  onChange={(e) =>
                                    onChangeTimePicker(
                                      e,
                                      "startDateWeek",
                                      "endDateWeek"
                                    )
                                  }
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>

                        <Col md={2} lg={1}>
                          <div className="mt-3 t-to">to</div>
                        </Col>

                        <Col md={8} lg={5} className="mt-input">
                          <Form.Item
                            name="endDateWeek"
                            rules={[
                              { required: true, message: t("pleaseSelect") },
                              ({ getFieldValue }) => ({
                                validator(_, value) {
                                  if (
                                    moment(
                                      getFieldValue("startDateWeek")
                                    ).isAfter(value) ||
                                    moment(
                                      getFieldValue("startDateWeek")
                                    ).format("HH:mm") ===
                                      moment(value).format("HH:mm")
                                  ) {
                                    slotForm.setFieldsValue({
                                      [`endDateWeek`]: moment(
                                        getFieldValue("startDateWeek")
                                      ).add(15, "minutes"),
                                    });
                                    return Promise.resolve();
                                  } else {
                                    return Promise.resolve();
                                  }
                                },
                              }),
                            ]}
                          >
                            <TimePicker
                              format={format}
                              disabledHours={getDisabledHours}
                              disabledMinutes={getDisabledMinutes}
                            />
                          </Form.Item>
                        </Col>
                      </React.Fragment>
                    ) : (
                      selectDate === 3 && (
                        <React.Fragment>
                          <Col md={24} lg={12}>
                            <div>{t("date")}</div>
                            <div className="mt-1">
                              <Form.Item
                                name="dateMonth"
                                rules={[{ required: true }]}
                              >
                                <DatePicker
                                  picker="month"
                                  disabledDate={disabledDate}
                                />
                              </Form.Item>
                            </div>
                          </Col>
                          <Col md={10} lg={6}>
                            <div className="">{`${t("period")}`}</div>
                            <Row className="mt-1">
                              <Col md={4} lg={6} className="icon-modal">
                                <img
                                  className="img-icon-modal"
                                  src={Period}
                                  alt="Period"
                                />
                              </Col>
                              <Col md={20} lg={18}>
                                <Form.Item
                                  name="startDateMonth"
                                  rules={[
                                    {
                                      required: true,
                                      message: t("pleaseSelect"),
                                    },
                                  ]}
                                >
                                  <TimePicker
                                    format={format}
                                    onChange={(e) =>
                                      onChangeTimePicker(
                                        e,
                                        "startDateMonth",
                                        "endDateMonth"
                                      )
                                    }
                                  />
                                </Form.Item>
                              </Col>
                            </Row>
                          </Col>

                          <Col md={2} lg={1}>
                            <div className="mt-3 t-to">to</div>
                          </Col>

                          <Col md={8} lg={5} className="mt-input">
                            <Form.Item
                              name="endDateMonth"
                              rules={[
                                { required: true, message: t("pleaseSelect") },
                                ({ getFieldValue }) => ({
                                  validator(_, value) {
                                    if (
                                      moment(
                                        getFieldValue("startDateMonth")
                                      ).isAfter(value) ||
                                      moment(
                                        getFieldValue("startDateMonth")
                                      ).format("HH:mm") ===
                                        moment(value).format("HH:mm")
                                    ) {
                                      slotForm.setFieldsValue({
                                        [`endDateMonth`]: moment(
                                          getFieldValue("startDateMonth")
                                        ).add(15, "minutes"),
                                      });
                                      return Promise.resolve();
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <TimePicker
                                format={format}
                                disabledHours={getDisabledHours}
                                disabledMinutes={getDisabledMinutes}
                              />
                            </Form.Item>
                          </Col>
                        </React.Fragment>
                      )
                    )}
                  </Row>
                </Col>
              );
            }
          }}
        </Form.Item>

        <Col md={24} lg={12}>
          <div>{t("booking.notification")}</div>
          <Row gutter={[14, 14]} className="mt-1">
            <Col md={2} lg={2} className="icon-modal">
              <img
                className="img-icon-modal"
                src={Notification}
                alt="Notification"
              />
            </Col>
            <Col md={22} lg={22}>
              <Form.Item name="minutesToNotify">
                <Selector
                  Source={notificationDetail || []}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Row>
    </Form>
  );
};

export default Fields;
