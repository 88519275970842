import { IAddress } from "./address.interface";

export const address: IAddress[] = [
  {
    id: 100402,
    districtTh: "เขตบางรัก",
    districtEn: "Khet Bang Rak",
    subdistrictTh: "สีลม",
    subdistrictEn: "Si Lom",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10500,
    district_code: 1004,
    province_code: 1,
    name: "เขตบางรัก สีลม กรุงเทพมหานคร 10500",
    name_th: "เขตบางรัก สีลม กรุงเทพมหานคร 10500",
    name_en: "Khet Bang Rak, Si Lom, Bangkok, 10500",
  },
  {
    id: 104901,
    districtTh: "เขตทุ่งครุ",
    districtEn: "Khet Thung Khru",
    subdistrictTh: "บางมด",
    subdistrictEn: "Bang Mot",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10140,
    district_code: 1049,
    province_code: 1,
    name: "เขตทุ่งครุ บางมด กรุงเทพมหานคร 10140",
    name_th: "เขตทุ่งครุ บางมด กรุงเทพมหานคร 10140",
    name_en: "Khet Thung Khru, Bang Mot, Bangkok, 10140",
  },
  {
    id: 140608,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "วัดยม",
    subdistrictEn: "Wat Yom",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน วัดยม พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน วัดยม พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Wat Yom, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 141407,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "เสนา",
    subdistrictEn: "Sena",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย เสนา พระนครศรีอยุธยา 13210",
    name_th: "อุทัย เสนา พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Sena, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 320306,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "บะ",
    subdistrictEn: "Ba",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม บะ สุรินทร์ 32120",
    name_th: "ท่าตูม บะ สุรินทร์ 32120",
    name_en: "Tha Tum, Ba, Surin, 32120",
  },
  {
    id: 400112,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "ดอนช้าง",
    subdistrictEn: "Don Chang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น ดอนช้าง ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น ดอนช้าง ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Don Chang, Khon Kaen, 40000",
  },
  {
    id: 440403,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "มะค่า",
    subdistrictEn: "Makha",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย มะค่า มหาสารคาม 44150",
    name_th: "กันทรวิชัย มะค่า มหาสารคาม 44150",
    name_en: "Kantharawichai, Makha, Maha Sarakham, 44150",
  },
  {
    id: 450803,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "บัวคำ",
    subdistrictEn: "Bua Kham",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย บัวคำ ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย บัวคำ ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Bua Kham, Roi Et, 45230",
  },
  {
    id: 471303,
    districtTh: "ส่องดาว",
    districtEn: "Song Dao",
    subdistrictTh: "วัฒนา",
    subdistrictEn: "Watthana",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47190,
    district_code: 4713,
    province_code: 35,
    name: "ส่องดาว วัฒนา สกลนคร 47190",
    name_th: "ส่องดาว วัฒนา สกลนคร 47190",
    name_en: "Song Dao, Watthana, Sakon Nakhon, 47190",
  },
  {
    id: 610110,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "หนองเต่า",
    subdistrictEn: "Nong Tao",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี หนองเต่า อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี หนองเต่า อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Nong Tao, Uthai Thani, 61000",
  },
  {
    id: 630503,
    districtTh: "ท่าสองยาง",
    districtEn: "Tha Song Yang",
    subdistrictTh: "แม่สอง",
    subdistrictEn: "Mae Song",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63150,
    district_code: 6305,
    province_code: 50,
    name: "ท่าสองยาง แม่สอง ตาก 63150",
    name_th: "ท่าสองยาง แม่สอง ตาก 63150",
    name_en: "Tha Song Yang, Mae Song, Tak, 63150",
  },
  {
    id: 660603,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "ทะนง",
    subdistrictEn: "Thanong",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล ทะนง พิจิตร 66130",
    name_th: "โพทะเล ทะนง พิจิตร 66130",
    name_en: "Pho Thale, Thanong, Phichit, 66130",
  },
  {
    id: 700117,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "หลุมดิน",
    subdistrictEn: "Lum Din",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี หลุมดิน ราชบุรี 70000",
    name_th: "เมืองราชบุรี หลุมดิน ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Lum Din, Ratchaburi, 70000",
  },
  {
    id: 820503,
    districtTh: "ตะกั่วป่า",
    districtEn: "Takua Pa",
    subdistrictTh: "บางไทร",
    subdistrictEn: "Bang Sai",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82110,
    district_code: 8205,
    province_code: 65,
    name: "ตะกั่วป่า บางไทร พังงา 82110",
    name_th: "ตะกั่วป่า บางไทร พังงา 82110",
    name_en: "Takua Pa, Bang Sai, Phangnga, 82110",
  },
  {
    id: 930114,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "นาโหนด",
    subdistrictEn: "Na Not)",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง นาโหนด พัทลุง 93000",
    name_th: "เมืองพัทลุง นาโหนด พัทลุง 93000",
    name_en: "Mueang Phatthalung, Na Not), Phatthalung, 93000",
  },
  {
    id: 100101,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "พระบรมมหาราชวัง",
    subdistrictEn: "Phra Borom Maha Ratchawang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร พระบรมมหาราชวัง กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร พระบรมมหาราชวัง กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Phra Borom Maha Ratchawang, Bangkok, 10200",
  },
  {
    id: 100102,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "วังบูรพาภิรมย์",
    subdistrictEn: "Wang Burapha Phirom",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร วังบูรพาภิรมย์ กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร วังบูรพาภิรมย์ กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Wang Burapha Phirom, Bangkok, 10200",
  },
  {
    id: 100103,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "วัดราชบพิธ",
    subdistrictEn: "Wat Ratchabophit",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร วัดราชบพิธ กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร วัดราชบพิธ กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Wat Ratchabophit, Bangkok, 10200",
  },
  {
    id: 100104,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "สำราญราษฎร์",
    subdistrictEn: "Samran Rat",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร สำราญราษฎร์ กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร สำราญราษฎร์ กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Samran Rat, Bangkok, 10200",
  },
  {
    id: 100105,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "ศาลเจ้าพ่อเสือ",
    subdistrictEn: "San Chao Pho Suea",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร ศาลเจ้าพ่อเสือ กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร ศาลเจ้าพ่อเสือ กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, San Chao Pho Suea, Bangkok, 10200",
  },
  {
    id: 100106,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "เสาชิงช้า",
    subdistrictEn: "Sao Chingcha",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร เสาชิงช้า กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร เสาชิงช้า กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Sao Chingcha, Bangkok, 10200",
  },
  {
    id: 100107,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "บวรนิเวศ",
    subdistrictEn: "Bowon Niwet",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร บวรนิเวศ กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร บวรนิเวศ กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Bowon Niwet, Bangkok, 10200",
  },
  {
    id: 100108,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "ตลาดยอด",
    subdistrictEn: "Talat Yot",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร ตลาดยอด กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร ตลาดยอด กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Talat Yot, Bangkok, 10200",
  },
  {
    id: 100109,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "ชนะสงคราม",
    subdistrictEn: "Chana Songkhram",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร ชนะสงคราม กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร ชนะสงคราม กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Chana Songkhram, Bangkok, 10200",
  },
  {
    id: 100110,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "บ้านพานถม",
    subdistrictEn: "Ban Phan Thom",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร บ้านพานถม กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร บ้านพานถม กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Ban Phan Thom, Bangkok, 10200",
  },
  {
    id: 100111,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "บางขุนพรหม",
    subdistrictEn: "Bang Khun Phrom",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร บางขุนพรหม กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร บางขุนพรหม กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Bang Khun Phrom, Bangkok, 10200",
  },
  {
    id: 100112,
    districtTh: "เขตพระนคร",
    districtEn: "Khet Phra Nakhon",
    subdistrictTh: "วัดสามพระยา",
    subdistrictEn: "Wat Sam Phraya",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10200,
    district_code: 1001,
    province_code: 1,
    name: "เขตพระนคร วัดสามพระยา กรุงเทพมหานคร 10200",
    name_th: "เขตพระนคร วัดสามพระยา กรุงเทพมหานคร 10200",
    name_en: "Khet Phra Nakhon, Wat Sam Phraya, Bangkok, 10200",
  },
  {
    id: 100201,
    districtTh: "เขตดุสิต",
    districtEn: "Khet Dusit",
    subdistrictTh: "ดุสิต",
    subdistrictEn: "Dusit",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10300,
    district_code: 1002,
    province_code: 1,
    name: "เขตดุสิต ดุสิต กรุงเทพมหานคร 10300",
    name_th: "เขตดุสิต ดุสิต กรุงเทพมหานคร 10300",
    name_en: "Khet Dusit, Dusit, Bangkok, 10300",
  },
  {
    id: 100202,
    districtTh: "เขตดุสิต",
    districtEn: "Khet Dusit",
    subdistrictTh: "วชิรพยาบาล",
    subdistrictEn: "Wachiraphayaban",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10300,
    district_code: 1002,
    province_code: 1,
    name: "เขตดุสิต วชิรพยาบาล กรุงเทพมหานคร 10300",
    name_th: "เขตดุสิต วชิรพยาบาล กรุงเทพมหานคร 10300",
    name_en: "Khet Dusit, Wachiraphayaban, Bangkok, 10300",
  },
  {
    id: 100203,
    districtTh: "เขตดุสิต",
    districtEn: "Khet Dusit",
    subdistrictTh: "สวนจิตรลดา",
    subdistrictEn: "Suan Chit Lada",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10300,
    district_code: 1002,
    province_code: 1,
    name: "เขตดุสิต สวนจิตรลดา กรุงเทพมหานคร 10300",
    name_th: "เขตดุสิต สวนจิตรลดา กรุงเทพมหานคร 10300",
    name_en: "Khet Dusit, Suan Chit Lada, Bangkok, 10300",
  },
  {
    id: 100204,
    districtTh: "เขตดุสิต",
    districtEn: "Khet Dusit",
    subdistrictTh: "สี่แยกมหานาค",
    subdistrictEn: "Si Yaek Maha Nak",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10300,
    district_code: 1002,
    province_code: 1,
    name: "เขตดุสิต สี่แยกมหานาค กรุงเทพมหานคร 10300",
    name_th: "เขตดุสิต สี่แยกมหานาค กรุงเทพมหานคร 10300",
    name_en: "Khet Dusit, Si Yaek Maha Nak, Bangkok, 10300",
  },
  {
    id: 100206,
    districtTh: "เขตดุสิต",
    districtEn: "Khet Dusit",
    subdistrictTh: "ถนนนครไชยศรี",
    subdistrictEn: "Thanon Nakhon Chai Si",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10300,
    district_code: 1002,
    province_code: 1,
    name: "เขตดุสิต ถนนนครไชยศรี กรุงเทพมหานคร 10300",
    name_th: "เขตดุสิต ถนนนครไชยศรี กรุงเทพมหานคร 10300",
    name_en: "Khet Dusit, Thanon Nakhon Chai Si, Bangkok, 10300",
  },
  {
    id: 100301,
    districtTh: "เขตหนองจอก",
    districtEn: "Khet Nong Chok",
    subdistrictTh: "กระทุ่มราย",
    subdistrictEn: "Krathum Rai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10530,
    district_code: 1003,
    province_code: 1,
    name: "เขตหนองจอก กระทุ่มราย กรุงเทพมหานคร 10530",
    name_th: "เขตหนองจอก กระทุ่มราย กรุงเทพมหานคร 10530",
    name_en: "Khet Nong Chok, Krathum Rai, Bangkok, 10530",
  },
  {
    id: 100302,
    districtTh: "เขตหนองจอก",
    districtEn: "Khet Nong Chok",
    subdistrictTh: "หนองจอก",
    subdistrictEn: "Nong Chok",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10530,
    district_code: 1003,
    province_code: 1,
    name: "เขตหนองจอก หนองจอก กรุงเทพมหานคร 10530",
    name_th: "เขตหนองจอก หนองจอก กรุงเทพมหานคร 10530",
    name_en: "Khet Nong Chok, Nong Chok, Bangkok, 10530",
  },
  {
    id: 100303,
    districtTh: "เขตหนองจอก",
    districtEn: "Khet Nong Chok",
    subdistrictTh: "คลองสิบ",
    subdistrictEn: "Khlong Sip",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10530,
    district_code: 1003,
    province_code: 1,
    name: "เขตหนองจอก คลองสิบ กรุงเทพมหานคร 10530",
    name_th: "เขตหนองจอก คลองสิบ กรุงเทพมหานคร 10530",
    name_en: "Khet Nong Chok, Khlong Sip, Bangkok, 10530",
  },
  {
    id: 100304,
    districtTh: "เขตหนองจอก",
    districtEn: "Khet Nong Chok",
    subdistrictTh: "คลองสิบสอง",
    subdistrictEn: "Khlong Sip Song",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10530,
    district_code: 1003,
    province_code: 1,
    name: "เขตหนองจอก คลองสิบสอง กรุงเทพมหานคร 10530",
    name_th: "เขตหนองจอก คลองสิบสอง กรุงเทพมหานคร 10530",
    name_en: "Khet Nong Chok, Khlong Sip Song, Bangkok, 10530",
  },
  {
    id: 100305,
    districtTh: "เขตหนองจอก",
    districtEn: "Khet Nong Chok",
    subdistrictTh: "โคกแฝด",
    subdistrictEn: "Khok Faet",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10530,
    district_code: 1003,
    province_code: 1,
    name: "เขตหนองจอก โคกแฝด กรุงเทพมหานคร 10530",
    name_th: "เขตหนองจอก โคกแฝด กรุงเทพมหานคร 10530",
    name_en: "Khet Nong Chok, Khok Faet, Bangkok, 10530",
  },
  {
    id: 100306,
    districtTh: "เขตหนองจอก",
    districtEn: "Khet Nong Chok",
    subdistrictTh: "คู้ฝั่งเหนือ",
    subdistrictEn: "Khu Fang Nuea",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10530,
    district_code: 1003,
    province_code: 1,
    name: "เขตหนองจอก คู้ฝั่งเหนือ กรุงเทพมหานคร 10530",
    name_th: "เขตหนองจอก คู้ฝั่งเหนือ กรุงเทพมหานคร 10530",
    name_en: "Khet Nong Chok, Khu Fang Nuea, Bangkok, 10530",
  },
  {
    id: 100307,
    districtTh: "เขตหนองจอก",
    districtEn: "Khet Nong Chok",
    subdistrictTh: "ลำผักชี",
    subdistrictEn: "Lam Phak Chi",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10530,
    district_code: 1003,
    province_code: 1,
    name: "เขตหนองจอก ลำผักชี กรุงเทพมหานคร 10530",
    name_th: "เขตหนองจอก ลำผักชี กรุงเทพมหานคร 10530",
    name_en: "Khet Nong Chok, Lam Phak Chi, Bangkok, 10530",
  },
  {
    id: 100308,
    districtTh: "เขตหนองจอก",
    districtEn: "Khet Nong Chok",
    subdistrictTh: "ลำต้อยติ่ง",
    subdistrictEn: "Lam Toiting",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10530,
    district_code: 1003,
    province_code: 1,
    name: "เขตหนองจอก ลำต้อยติ่ง กรุงเทพมหานคร 10530",
    name_th: "เขตหนองจอก ลำต้อยติ่ง กรุงเทพมหานคร 10530",
    name_en: "Khet Nong Chok, Lam Toiting, Bangkok, 10530",
  },
  {
    id: 100401,
    districtTh: "เขตบางรัก",
    districtEn: "Khet Bang Rak",
    subdistrictTh: "มหาพฤฒาราม",
    subdistrictEn: "Maha Phruettharam",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10500,
    district_code: 1004,
    province_code: 1,
    name: "เขตบางรัก มหาพฤฒาราม กรุงเทพมหานคร 10500",
    name_th: "เขตบางรัก มหาพฤฒาราม กรุงเทพมหานคร 10500",
    name_en: "Khet Bang Rak, Maha Phruettharam, Bangkok, 10500",
  },
  {
    id: 100403,
    districtTh: "เขตบางรัก",
    districtEn: "Khet Bang Rak",
    subdistrictTh: "สุริยวงศ์",
    subdistrictEn: "Suriyawong",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10500,
    district_code: 1004,
    province_code: 1,
    name: "เขตบางรัก สุริยวงศ์ กรุงเทพมหานคร 10500",
    name_th: "เขตบางรัก สุริยวงศ์ กรุงเทพมหานคร 10500",
    name_en: "Khet Bang Rak, Suriyawong, Bangkok, 10500",
  },
  {
    id: 100404,
    districtTh: "เขตบางรัก",
    districtEn: "Khet Bang Rak",
    subdistrictTh: "บางรัก",
    subdistrictEn: "Bang Rak",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10500,
    district_code: 1004,
    province_code: 1,
    name: "เขตบางรัก บางรัก กรุงเทพมหานคร 10500",
    name_th: "เขตบางรัก บางรัก กรุงเทพมหานคร 10500",
    name_en: "Khet Bang Rak, Bang Rak, Bangkok, 10500",
  },
  {
    id: 100405,
    districtTh: "เขตบางรัก",
    districtEn: "Khet Bang Rak",
    subdistrictTh: "สี่พระยา",
    subdistrictEn: "Si Phraya",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10500,
    district_code: 1004,
    province_code: 1,
    name: "เขตบางรัก สี่พระยา กรุงเทพมหานคร 10500",
    name_th: "เขตบางรัก สี่พระยา กรุงเทพมหานคร 10500",
    name_en: "Khet Bang Rak, Si Phraya, Bangkok, 10500",
  },
  {
    id: 100502,
    districtTh: "เขตบางเขน",
    districtEn: "Khet Bang Khen",
    subdistrictTh: "อนุสาวรีย์",
    subdistrictEn: "Anusawari",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10220,
    district_code: 1005,
    province_code: 1,
    name: "เขตบางเขน อนุสาวรีย์ กรุงเทพมหานคร 10220",
    name_th: "เขตบางเขน อนุสาวรีย์ กรุงเทพมหานคร 10220",
    name_en: "Khet Bang Khen, Anusawari, Bangkok, 10220",
  },
  {
    id: 100508,
    districtTh: "เขตบางเขน",
    districtEn: "Khet Bang Khen",
    subdistrictTh: "ท่าแร้ง",
    subdistrictEn: "Tha Raeng",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10220,
    district_code: 1005,
    province_code: 1,
    name: "เขตบางเขน ท่าแร้ง กรุงเทพมหานคร 10220",
    name_th: "เขตบางเขน ท่าแร้ง กรุงเทพมหานคร 10220",
    name_en: "Khet Bang Khen, Tha Raeng, Bangkok, 10220",
  },
  {
    id: 100601,
    districtTh: "เขตบางกะปิ",
    districtEn: "Khet Bang Kapi",
    subdistrictTh: "คลองจั่น",
    subdistrictEn: "Khlong Chan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10240,
    district_code: 1006,
    province_code: 1,
    name: "เขตบางกะปิ คลองจั่น กรุงเทพมหานคร 10240",
    name_th: "เขตบางกะปิ คลองจั่น กรุงเทพมหานคร 10240",
    name_en: "Khet Bang Kapi, Khlong Chan, Bangkok, 10240",
  },
  {
    id: 100608,
    districtTh: "เขตบางกะปิ",
    districtEn: "Khet Bang Kapi",
    subdistrictTh: "หัวหมาก",
    subdistrictEn: "Hua Mak",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10240,
    district_code: 1006,
    province_code: 1,
    name: "เขตบางกะปิ หัวหมาก กรุงเทพมหานคร 10240",
    name_th: "เขตบางกะปิ หัวหมาก กรุงเทพมหานคร 10240",
    name_en: "Khet Bang Kapi, Hua Mak, Bangkok, 10240",
  },
  {
    id: 100701,
    districtTh: "เขตปทุมวัน",
    districtEn: "Khet Pathum Wan",
    subdistrictTh: "รองเมือง",
    subdistrictEn: "Rong Mueang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10330,
    district_code: 1007,
    province_code: 1,
    name: "เขตปทุมวัน รองเมือง กรุงเทพมหานคร 10330",
    name_th: "เขตปทุมวัน รองเมือง กรุงเทพมหานคร 10330",
    name_en: "Khet Pathum Wan, Rong Mueang, Bangkok, 10330",
  },
  {
    id: 100702,
    districtTh: "เขตปทุมวัน",
    districtEn: "Khet Pathum Wan",
    subdistrictTh: "วังใหม่",
    subdistrictEn: "Wang Mai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10330,
    district_code: 1007,
    province_code: 1,
    name: "เขตปทุมวัน วังใหม่ กรุงเทพมหานคร 10330",
    name_th: "เขตปทุมวัน วังใหม่ กรุงเทพมหานคร 10330",
    name_en: "Khet Pathum Wan, Wang Mai, Bangkok, 10330",
  },
  {
    id: 100703,
    districtTh: "เขตปทุมวัน",
    districtEn: "Khet Pathum Wan",
    subdistrictTh: "ปทุมวัน",
    subdistrictEn: "Pathum Wan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10330,
    district_code: 1007,
    province_code: 1,
    name: "เขตปทุมวัน ปทุมวัน กรุงเทพมหานคร 10330",
    name_th: "เขตปทุมวัน ปทุมวัน กรุงเทพมหานคร 10330",
    name_en: "Khet Pathum Wan, Pathum Wan, Bangkok, 10330",
  },
  {
    id: 100704,
    districtTh: "เขตปทุมวัน",
    districtEn: "Khet Pathum Wan",
    subdistrictTh: "ลุมพินี",
    subdistrictEn: "Lumphini",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10330,
    district_code: 1007,
    province_code: 1,
    name: "เขตปทุมวัน ลุมพินี กรุงเทพมหานคร 10330",
    name_th: "เขตปทุมวัน ลุมพินี กรุงเทพมหานคร 10330",
    name_en: "Khet Pathum Wan, Lumphini, Bangkok, 10330",
  },
  {
    id: 100801,
    districtTh: "เขตป้อมปราบศัตรูพ่าย",
    districtEn: "Khet Pom Prap Sattru Phai",
    subdistrictTh: "ป้อมปราบ",
    subdistrictEn: "Pom Prap",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10100,
    district_code: 1008,
    province_code: 1,
    name: "เขตป้อมปราบศัตรูพ่าย ป้อมปราบ กรุงเทพมหานคร 10100",
    name_th: "เขตป้อมปราบศัตรูพ่าย ป้อมปราบ กรุงเทพมหานคร 10100",
    name_en: "Khet Pom Prap Sattru Phai, Pom Prap, Bangkok, 10100",
  },
  {
    id: 100802,
    districtTh: "เขตป้อมปราบศัตรูพ่าย",
    districtEn: "Khet Pom Prap Sattru Phai",
    subdistrictTh: "วัดเทพศิรินทร์",
    subdistrictEn: "Wat Thep Sirin",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10100,
    district_code: 1008,
    province_code: 1,
    name: "เขตป้อมปราบศัตรูพ่าย วัดเทพศิรินทร์ กรุงเทพมหานคร 10100",
    name_th: "เขตป้อมปราบศัตรูพ่าย วัดเทพศิรินทร์ กรุงเทพมหานคร 10100",
    name_en: "Khet Pom Prap Sattru Phai, Wat Thep Sirin, Bangkok, 10100",
  },
  {
    id: 100803,
    districtTh: "เขตป้อมปราบศัตรูพ่าย",
    districtEn: "Khet Pom Prap Sattru Phai",
    subdistrictTh: "คลองมหานาค",
    subdistrictEn: "Khlong Maha Nak",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10100,
    district_code: 1008,
    province_code: 1,
    name: "เขตป้อมปราบศัตรูพ่าย คลองมหานาค กรุงเทพมหานคร 10100",
    name_th: "เขตป้อมปราบศัตรูพ่าย คลองมหานาค กรุงเทพมหานคร 10100",
    name_en: "Khet Pom Prap Sattru Phai, Khlong Maha Nak, Bangkok, 10100",
  },
  {
    id: 100804,
    districtTh: "เขตป้อมปราบศัตรูพ่าย",
    districtEn: "Khet Pom Prap Sattru Phai",
    subdistrictTh: "บ้านบาตร",
    subdistrictEn: "Ban Bat",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10100,
    district_code: 1008,
    province_code: 1,
    name: "เขตป้อมปราบศัตรูพ่าย บ้านบาตร กรุงเทพมหานคร 10100",
    name_th: "เขตป้อมปราบศัตรูพ่าย บ้านบาตร กรุงเทพมหานคร 10100",
    name_en: "Khet Pom Prap Sattru Phai, Ban Bat, Bangkok, 10100",
  },
  {
    id: 100805,
    districtTh: "เขตป้อมปราบศัตรูพ่าย",
    districtEn: "Khet Pom Prap Sattru Phai",
    subdistrictTh: "วัดโสมนัส",
    subdistrictEn: "Wat Sommanat",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10100,
    district_code: 1008,
    province_code: 1,
    name: "เขตป้อมปราบศัตรูพ่าย วัดโสมนัส กรุงเทพมหานคร 10100",
    name_th: "เขตป้อมปราบศัตรูพ่าย วัดโสมนัส กรุงเทพมหานคร 10100",
    name_en: "Khet Pom Prap Sattru Phai, Wat Sommanat, Bangkok, 10100",
  },
  {
    id: 100905,
    districtTh: "เขตพระโขนง",
    districtEn: "Khet Phra Khanong",
    subdistrictTh: "บางจาก",
    subdistrictEn: "Bang Chak",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10260,
    district_code: 1009,
    province_code: 1,
    name: "เขตพระโขนง บางจาก กรุงเทพมหานคร 10260",
    name_th: "เขตพระโขนง บางจาก กรุงเทพมหานคร 10260",
    name_en: "Khet Phra Khanong, Bang Chak, Bangkok, 10260",
  },
  {
    id: 101001,
    districtTh: "เขตมีนบุรี",
    districtEn: "Khet Min Buri",
    subdistrictTh: "มีนบุรี",
    subdistrictEn: "Min Buri",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10510,
    district_code: 1010,
    province_code: 1,
    name: "เขตมีนบุรี มีนบุรี กรุงเทพมหานคร 10510",
    name_th: "เขตมีนบุรี มีนบุรี กรุงเทพมหานคร 10510",
    name_en: "Khet Min Buri, Min Buri, Bangkok, 10510",
  },
  {
    id: 101002,
    districtTh: "เขตมีนบุรี",
    districtEn: "Khet Min Buri",
    subdistrictTh: "แสนแสบ",
    subdistrictEn: "Saen Saep",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10510,
    district_code: 1010,
    province_code: 1,
    name: "เขตมีนบุรี แสนแสบ กรุงเทพมหานคร 10510",
    name_th: "เขตมีนบุรี แสนแสบ กรุงเทพมหานคร 10510",
    name_en: "Khet Min Buri, Saen Saep, Bangkok, 10510",
  },
  {
    id: 101101,
    districtTh: "เขตลาดกระบัง",
    districtEn: "Khet Lat Krabang",
    subdistrictTh: "ลาดกระบัง",
    subdistrictEn: "Lat Krabang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10520,
    district_code: 1011,
    province_code: 1,
    name: "เขตลาดกระบัง ลาดกระบัง กรุงเทพมหานคร 10520",
    name_th: "เขตลาดกระบัง ลาดกระบัง กรุงเทพมหานคร 10520",
    name_en: "Khet Lat Krabang, Lat Krabang, Bangkok, 10520",
  },
  {
    id: 101102,
    districtTh: "เขตลาดกระบัง",
    districtEn: "Khet Lat Krabang",
    subdistrictTh: "คลองสองต้นนุ่น",
    subdistrictEn: "Khlong Song Ton Nun",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10520,
    district_code: 1011,
    province_code: 1,
    name: "เขตลาดกระบัง คลองสองต้นนุ่น กรุงเทพมหานคร 10520",
    name_th: "เขตลาดกระบัง คลองสองต้นนุ่น กรุงเทพมหานคร 10520",
    name_en: "Khet Lat Krabang, Khlong Song Ton Nun, Bangkok, 10520",
  },
  {
    id: 101103,
    districtTh: "เขตลาดกระบัง",
    districtEn: "Khet Lat Krabang",
    subdistrictTh: "คลองสามประเวศ",
    subdistrictEn: "Khlong Sam Prawet",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10520,
    district_code: 1011,
    province_code: 1,
    name: "เขตลาดกระบัง คลองสามประเวศ กรุงเทพมหานคร 10520",
    name_th: "เขตลาดกระบัง คลองสามประเวศ กรุงเทพมหานคร 10520",
    name_en: "Khet Lat Krabang, Khlong Sam Prawet, Bangkok, 10520",
  },
  {
    id: 101104,
    districtTh: "เขตลาดกระบัง",
    districtEn: "Khet Lat Krabang",
    subdistrictTh: "ลำปลาทิว",
    subdistrictEn: "Lam Pla Thio",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10520,
    district_code: 1011,
    province_code: 1,
    name: "เขตลาดกระบัง ลำปลาทิว กรุงเทพมหานคร 10520",
    name_th: "เขตลาดกระบัง ลำปลาทิว กรุงเทพมหานคร 10520",
    name_en: "Khet Lat Krabang, Lam Pla Thio, Bangkok, 10520",
  },
  {
    id: 101105,
    districtTh: "เขตลาดกระบัง",
    districtEn: "Khet Lat Krabang",
    subdistrictTh: "ทับยาว",
    subdistrictEn: "Thap Yao",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10520,
    district_code: 1011,
    province_code: 1,
    name: "เขตลาดกระบัง ทับยาว กรุงเทพมหานคร 10520",
    name_th: "เขตลาดกระบัง ทับยาว กรุงเทพมหานคร 10520",
    name_en: "Khet Lat Krabang, Thap Yao, Bangkok, 10520",
  },
  {
    id: 101106,
    districtTh: "เขตลาดกระบัง",
    districtEn: "Khet Lat Krabang",
    subdistrictTh: "ขุมทอง",
    subdistrictEn: "Khum Thong",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10520,
    district_code: 1011,
    province_code: 1,
    name: "เขตลาดกระบัง ขุมทอง กรุงเทพมหานคร 10520",
    name_th: "เขตลาดกระบัง ขุมทอง กรุงเทพมหานคร 10520",
    name_en: "Khet Lat Krabang, Khum Thong, Bangkok, 10520",
  },
  {
    id: 101203,
    districtTh: "เขตยานนาวา",
    districtEn: "Khet Yan Nawa",
    subdistrictTh: "ช่องนนทรี",
    subdistrictEn: "Chong Nonsi",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10120,
    district_code: 1012,
    province_code: 1,
    name: "เขตยานนาวา ช่องนนทรี กรุงเทพมหานคร 10120",
    name_th: "เขตยานนาวา ช่องนนทรี กรุงเทพมหานคร 10120",
    name_en: "Khet Yan Nawa, Chong Nonsi, Bangkok, 10120",
  },
  {
    id: 101204,
    districtTh: "เขตยานนาวา",
    districtEn: "Khet Yan Nawa",
    subdistrictTh: "บางโพงพาง",
    subdistrictEn: "Bang Phongphang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10120,
    district_code: 1012,
    province_code: 1,
    name: "เขตยานนาวา บางโพงพาง กรุงเทพมหานคร 10120",
    name_th: "เขตยานนาวา บางโพงพาง กรุงเทพมหานคร 10120",
    name_en: "Khet Yan Nawa, Bang Phongphang, Bangkok, 10120",
  },
  {
    id: 101301,
    districtTh: "เขตสัมพันธวงศ์",
    districtEn: "Khet Samphanthawong",
    subdistrictTh: "จักรวรรดิ",
    subdistrictEn: "Chakkrawat",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10100,
    district_code: 1013,
    province_code: 1,
    name: "เขตสัมพันธวงศ์ จักรวรรดิ กรุงเทพมหานคร 10100",
    name_th: "เขตสัมพันธวงศ์ จักรวรรดิ กรุงเทพมหานคร 10100",
    name_en: "Khet Samphanthawong, Chakkrawat, Bangkok, 10100",
  },
  {
    id: 101302,
    districtTh: "เขตสัมพันธวงศ์",
    districtEn: "Khet Samphanthawong",
    subdistrictTh: "สัมพันธวงศ์",
    subdistrictEn: "Samphanthawong",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10100,
    district_code: 1013,
    province_code: 1,
    name: "เขตสัมพันธวงศ์ สัมพันธวงศ์ กรุงเทพมหานคร 10100",
    name_th: "เขตสัมพันธวงศ์ สัมพันธวงศ์ กรุงเทพมหานคร 10100",
    name_en: "Khet Samphanthawong, Samphanthawong, Bangkok, 10100",
  },
  {
    id: 101303,
    districtTh: "เขตสัมพันธวงศ์",
    districtEn: "Khet Samphanthawong",
    subdistrictTh: "ตลาดน้อย",
    subdistrictEn: "Talat Noi",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10100,
    district_code: 1013,
    province_code: 1,
    name: "เขตสัมพันธวงศ์ ตลาดน้อย กรุงเทพมหานคร 10100",
    name_th: "เขตสัมพันธวงศ์ ตลาดน้อย กรุงเทพมหานคร 10100",
    name_en: "Khet Samphanthawong, Talat Noi, Bangkok, 10100",
  },
  {
    id: 101401,
    districtTh: "เขตพญาไท",
    districtEn: "Khet Phaya Thai",
    subdistrictTh: "สามเสนใน",
    subdistrictEn: "Samsen Nai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10400,
    district_code: 1014,
    province_code: 1,
    name: "เขตพญาไท สามเสนใน กรุงเทพมหานคร 10400",
    name_th: "เขตพญาไท สามเสนใน กรุงเทพมหานคร 10400",
    name_en: "Khet Phaya Thai, Samsen Nai, Bangkok, 10400",
  },
  {
    id: 101501,
    districtTh: "เขตธนบุรี",
    districtEn: "Khet Thon Buri",
    subdistrictTh: "วัดกัลยาณ์",
    subdistrictEn: "Wat Kanlaya",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1015,
    province_code: 1,
    name: "เขตธนบุรี วัดกัลยาณ์ กรุงเทพมหานคร 10600",
    name_th: "เขตธนบุรี วัดกัลยาณ์ กรุงเทพมหานคร 10600",
    name_en: "Khet Thon Buri, Wat Kanlaya, Bangkok, 10600",
  },
  {
    id: 101502,
    districtTh: "เขตธนบุรี",
    districtEn: "Khet Thon Buri",
    subdistrictTh: "หิรัญรูจี",
    subdistrictEn: "Hiran Ruchi",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1015,
    province_code: 1,
    name: "เขตธนบุรี หิรัญรูจี กรุงเทพมหานคร 10600",
    name_th: "เขตธนบุรี หิรัญรูจี กรุงเทพมหานคร 10600",
    name_en: "Khet Thon Buri, Hiran Ruchi, Bangkok, 10600",
  },
  {
    id: 101503,
    districtTh: "เขตธนบุรี",
    districtEn: "Khet Thon Buri",
    subdistrictTh: "บางยี่เรือ",
    subdistrictEn: "Bang Yi Ruea",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1015,
    province_code: 1,
    name: "เขตธนบุรี บางยี่เรือ กรุงเทพมหานคร 10600",
    name_th: "เขตธนบุรี บางยี่เรือ กรุงเทพมหานคร 10600",
    name_en: "Khet Thon Buri, Bang Yi Ruea, Bangkok, 10600",
  },
  {
    id: 101504,
    districtTh: "เขตธนบุรี",
    districtEn: "Khet Thon Buri",
    subdistrictTh: "บุคคโล",
    subdistrictEn: "Bukkhalo",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1015,
    province_code: 1,
    name: "เขตธนบุรี บุคคโล กรุงเทพมหานคร 10600",
    name_th: "เขตธนบุรี บุคคโล กรุงเทพมหานคร 10600",
    name_en: "Khet Thon Buri, Bukkhalo, Bangkok, 10600",
  },
  {
    id: 101505,
    districtTh: "เขตธนบุรี",
    districtEn: "Khet Thon Buri",
    subdistrictTh: "ตลาดพลู",
    subdistrictEn: "Talat Phlu",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1015,
    province_code: 1,
    name: "เขตธนบุรี ตลาดพลู กรุงเทพมหานคร 10600",
    name_th: "เขตธนบุรี ตลาดพลู กรุงเทพมหานคร 10600",
    name_en: "Khet Thon Buri, Talat Phlu, Bangkok, 10600",
  },
  {
    id: 101506,
    districtTh: "เขตธนบุรี",
    districtEn: "Khet Thon Buri",
    subdistrictTh: "ดาวคะนอง",
    subdistrictEn: "Dao Khanong",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1015,
    province_code: 1,
    name: "เขตธนบุรี ดาวคะนอง กรุงเทพมหานคร 10600",
    name_th: "เขตธนบุรี ดาวคะนอง กรุงเทพมหานคร 10600",
    name_en: "Khet Thon Buri, Dao Khanong, Bangkok, 10600",
  },
  {
    id: 101507,
    districtTh: "เขตธนบุรี",
    districtEn: "Khet Thon Buri",
    subdistrictTh: "สำเหร่",
    subdistrictEn: "Samre",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1015,
    province_code: 1,
    name: "เขตธนบุรี สำเหร่ กรุงเทพมหานคร 10600",
    name_th: "เขตธนบุรี สำเหร่ กรุงเทพมหานคร 10600",
    name_en: "Khet Thon Buri, Samre, Bangkok, 10600",
  },
  {
    id: 101601,
    districtTh: "เขตบางกอกใหญ่",
    districtEn: "Khet Bangkok Yai",
    subdistrictTh: "วัดอรุณ",
    subdistrictEn: "Wat Arun",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1016,
    province_code: 1,
    name: "เขตบางกอกใหญ่ วัดอรุณ กรุงเทพมหานคร 10600",
    name_th: "เขตบางกอกใหญ่ วัดอรุณ กรุงเทพมหานคร 10600",
    name_en: "Khet Bangkok Yai, Wat Arun, Bangkok, 10600",
  },
  {
    id: 101602,
    districtTh: "เขตบางกอกใหญ่",
    districtEn: "Khet Bangkok Yai",
    subdistrictTh: "วัดท่าพระ",
    subdistrictEn: "Wat Tha Phra",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1016,
    province_code: 1,
    name: "เขตบางกอกใหญ่ วัดท่าพระ กรุงเทพมหานคร 10600",
    name_th: "เขตบางกอกใหญ่ วัดท่าพระ กรุงเทพมหานคร 10600",
    name_en: "Khet Bangkok Yai, Wat Tha Phra, Bangkok, 10600",
  },
  {
    id: 101701,
    districtTh: "เขตห้วยขวาง",
    districtEn: "Khet Huai Khwang",
    subdistrictTh: "ห้วยขวาง",
    subdistrictEn: "Huai Khwang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10310,
    district_code: 1017,
    province_code: 1,
    name: "เขตห้วยขวาง ห้วยขวาง กรุงเทพมหานคร 10310",
    name_th: "เขตห้วยขวาง ห้วยขวาง กรุงเทพมหานคร 10310",
    name_en: "Khet Huai Khwang, Huai Khwang, Bangkok, 10310",
  },
  {
    id: 101702,
    districtTh: "เขตห้วยขวาง",
    districtEn: "Khet Huai Khwang",
    subdistrictTh: "บางกะปิ",
    subdistrictEn: "Bang Kapi",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10310,
    district_code: 1017,
    province_code: 1,
    name: "เขตห้วยขวาง บางกะปิ กรุงเทพมหานคร 10310",
    name_th: "เขตห้วยขวาง บางกะปิ กรุงเทพมหานคร 10310",
    name_en: "Khet Huai Khwang, Bang Kapi, Bangkok, 10310",
  },
  {
    id: 101704,
    districtTh: "เขตห้วยขวาง",
    districtEn: "Khet Huai Khwang",
    subdistrictTh: "สามเสนนอก",
    subdistrictEn: "Samsen Nok",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10310,
    district_code: 1017,
    province_code: 1,
    name: "เขตห้วยขวาง สามเสนนอก กรุงเทพมหานคร 10310",
    name_th: "เขตห้วยขวาง สามเสนนอก กรุงเทพมหานคร 10310",
    name_en: "Khet Huai Khwang, Samsen Nok, Bangkok, 10310",
  },
  {
    id: 101801,
    districtTh: "เขตคลองสาน",
    districtEn: "Khet Khlong San",
    subdistrictTh: "สมเด็จเจ้าพระยา",
    subdistrictEn: "Somdet Chao Phraya",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1018,
    province_code: 1,
    name: "เขตคลองสาน สมเด็จเจ้าพระยา กรุงเทพมหานคร 10600",
    name_th: "เขตคลองสาน สมเด็จเจ้าพระยา กรุงเทพมหานคร 10600",
    name_en: "Khet Khlong San, Somdet Chao Phraya, Bangkok, 10600",
  },
  {
    id: 101802,
    districtTh: "เขตคลองสาน",
    districtEn: "Khet Khlong San",
    subdistrictTh: "คลองสาน",
    subdistrictEn: "Khlong San",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1018,
    province_code: 1,
    name: "เขตคลองสาน คลองสาน กรุงเทพมหานคร 10600",
    name_th: "เขตคลองสาน คลองสาน กรุงเทพมหานคร 10600",
    name_en: "Khet Khlong San, Khlong San, Bangkok, 10600",
  },
  {
    id: 101803,
    districtTh: "เขตคลองสาน",
    districtEn: "Khet Khlong San",
    subdistrictTh: "บางลำภูล่าง",
    subdistrictEn: "Bang Lamphu Lang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1018,
    province_code: 1,
    name: "เขตคลองสาน บางลำภูล่าง กรุงเทพมหานคร 10600",
    name_th: "เขตคลองสาน บางลำภูล่าง กรุงเทพมหานคร 10600",
    name_en: "Khet Khlong San, Bang Lamphu Lang, Bangkok, 10600",
  },
  {
    id: 101804,
    districtTh: "เขตคลองสาน",
    districtEn: "Khet Khlong San",
    subdistrictTh: "คลองต้นไทร",
    subdistrictEn: "Khlong Ton Sai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10600,
    district_code: 1018,
    province_code: 1,
    name: "เขตคลองสาน คลองต้นไทร กรุงเทพมหานคร 10600",
    name_th: "เขตคลองสาน คลองต้นไทร กรุงเทพมหานคร 10600",
    name_en: "Khet Khlong San, Khlong Ton Sai, Bangkok, 10600",
  },
  {
    id: 101901,
    districtTh: "เขตตลิ่งชัน",
    districtEn: "Khet Taling Chan",
    subdistrictTh: "คลองชักพระ",
    subdistrictEn: "Khlong Chak Phra",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10170,
    district_code: 1019,
    province_code: 1,
    name: "เขตตลิ่งชัน คลองชักพระ กรุงเทพมหานคร 10170",
    name_th: "เขตตลิ่งชัน คลองชักพระ กรุงเทพมหานคร 10170",
    name_en: "Khet Taling Chan, Khlong Chak Phra, Bangkok, 10170",
  },
  {
    id: 101902,
    districtTh: "เขตตลิ่งชัน",
    districtEn: "Khet Taling Chan",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10170,
    district_code: 1019,
    province_code: 1,
    name: "เขตตลิ่งชัน ตลิ่งชัน กรุงเทพมหานคร 10170",
    name_th: "เขตตลิ่งชัน ตลิ่งชัน กรุงเทพมหานคร 10170",
    name_en: "Khet Taling Chan, Taling Chan, Bangkok, 10170",
  },
  {
    id: 101903,
    districtTh: "เขตตลิ่งชัน",
    districtEn: "Khet Taling Chan",
    subdistrictTh: "ฉิมพลี",
    subdistrictEn: "Chimphli",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10170,
    district_code: 1019,
    province_code: 1,
    name: "เขตตลิ่งชัน ฉิมพลี กรุงเทพมหานคร 10170",
    name_th: "เขตตลิ่งชัน ฉิมพลี กรุงเทพมหานคร 10170",
    name_en: "Khet Taling Chan, Chimphli, Bangkok, 10170",
  },
  {
    id: 101904,
    districtTh: "เขตตลิ่งชัน",
    districtEn: "Khet Taling Chan",
    subdistrictTh: "บางพรม",
    subdistrictEn: "Bang Phrom",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10170,
    district_code: 1019,
    province_code: 1,
    name: "เขตตลิ่งชัน บางพรม กรุงเทพมหานคร 10170",
    name_th: "เขตตลิ่งชัน บางพรม กรุงเทพมหานคร 10170",
    name_en: "Khet Taling Chan, Bang Phrom, Bangkok, 10170",
  },
  {
    id: 101905,
    districtTh: "เขตตลิ่งชัน",
    districtEn: "Khet Taling Chan",
    subdistrictTh: "บางระมาด",
    subdistrictEn: "Bang Ramat",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10170,
    district_code: 1019,
    province_code: 1,
    name: "เขตตลิ่งชัน บางระมาด กรุงเทพมหานคร 10170",
    name_th: "เขตตลิ่งชัน บางระมาด กรุงเทพมหานคร 10170",
    name_en: "Khet Taling Chan, Bang Ramat, Bangkok, 10170",
  },
  {
    id: 101907,
    districtTh: "เขตตลิ่งชัน",
    districtEn: "Khet Taling Chan",
    subdistrictTh: "บางเชือกหนัง",
    subdistrictEn: "Bang Chueak Nang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10170,
    district_code: 1019,
    province_code: 1,
    name: "เขตตลิ่งชัน บางเชือกหนัง กรุงเทพมหานคร 10170",
    name_th: "เขตตลิ่งชัน บางเชือกหนัง กรุงเทพมหานคร 10170",
    name_en: "Khet Taling Chan, Bang Chueak Nang, Bangkok, 10170",
  },
  {
    id: 102004,
    districtTh: "เขตบางกอกน้อย",
    districtEn: "Khet Bangkok Noi",
    subdistrictTh: "ศิริราช",
    subdistrictEn: "Siri Rat",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1020,
    province_code: 1,
    name: "เขตบางกอกน้อย ศิริราช กรุงเทพมหานคร 10700",
    name_th: "เขตบางกอกน้อย ศิริราช กรุงเทพมหานคร 10700",
    name_en: "Khet Bangkok Noi, Siri Rat, Bangkok, 10700",
  },
  {
    id: 102005,
    districtTh: "เขตบางกอกน้อย",
    districtEn: "Khet Bangkok Noi",
    subdistrictTh: "บ้านช่างหล่อ",
    subdistrictEn: "Ban Chang Lo",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1020,
    province_code: 1,
    name: "เขตบางกอกน้อย บ้านช่างหล่อ กรุงเทพมหานคร 10700",
    name_th: "เขตบางกอกน้อย บ้านช่างหล่อ กรุงเทพมหานคร 10700",
    name_en: "Khet Bangkok Noi, Ban Chang Lo, Bangkok, 10700",
  },
  {
    id: 102006,
    districtTh: "เขตบางกอกน้อย",
    districtEn: "Khet Bangkok Noi",
    subdistrictTh: "บางขุนนนท์",
    subdistrictEn: "Bang Khun Non",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1020,
    province_code: 1,
    name: "เขตบางกอกน้อย บางขุนนนท์ กรุงเทพมหานคร 10700",
    name_th: "เขตบางกอกน้อย บางขุนนนท์ กรุงเทพมหานคร 10700",
    name_en: "Khet Bangkok Noi, Bang Khun Non, Bangkok, 10700",
  },
  {
    id: 102007,
    districtTh: "เขตบางกอกน้อย",
    districtEn: "Khet Bangkok Noi",
    subdistrictTh: "บางขุนศรี",
    subdistrictEn: "Bang Khun Si",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1020,
    province_code: 1,
    name: "เขตบางกอกน้อย บางขุนศรี กรุงเทพมหานคร 10700",
    name_th: "เขตบางกอกน้อย บางขุนศรี กรุงเทพมหานคร 10700",
    name_en: "Khet Bangkok Noi, Bang Khun Si, Bangkok, 10700",
  },
  {
    id: 102009,
    districtTh: "เขตบางกอกน้อย",
    districtEn: "Khet Bangkok Noi",
    subdistrictTh: "อรุณอมรินทร์",
    subdistrictEn: "Arun Ammarin",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1020,
    province_code: 1,
    name: "เขตบางกอกน้อย อรุณอมรินทร์ กรุงเทพมหานคร 10700",
    name_th: "เขตบางกอกน้อย อรุณอมรินทร์ กรุงเทพมหานคร 10700",
    name_en: "Khet Bangkok Noi, Arun Ammarin, Bangkok, 10700",
  },
  {
    id: 102105,
    districtTh: "เขตบางขุนเทียน",
    districtEn: "Khet Bang Khun Thian",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10150,
    district_code: 1021,
    province_code: 1,
    name: "เขตบางขุนเทียน ท่าข้าม กรุงเทพมหานคร 10150",
    name_th: "เขตบางขุนเทียน ท่าข้าม กรุงเทพมหานคร 10150",
    name_en: "Khet Bang Khun Thian, Tha Kham, Bangkok, 10150",
  },
  {
    id: 102107,
    districtTh: "เขตบางขุนเทียน",
    districtEn: "Khet Bang Khun Thian",
    subdistrictTh: "แสมดำ",
    subdistrictEn: "Samae Dam",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10150,
    district_code: 1021,
    province_code: 1,
    name: "เขตบางขุนเทียน แสมดำ กรุงเทพมหานคร 10150",
    name_th: "เขตบางขุนเทียน แสมดำ กรุงเทพมหานคร 10150",
    name_en: "Khet Bang Khun Thian, Samae Dam, Bangkok, 10150",
  },
  {
    id: 102201,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "บางหว้า",
    subdistrictEn: "Bang Wa",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ บางหว้า กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ บางหว้า กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Bang Wa, Bangkok, 10160",
  },
  {
    id: 102202,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "บางด้วน",
    subdistrictEn: "Bang Duan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ บางด้วน กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ บางด้วน กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Bang Duan, Bangkok, 10160",
  },
  {
    id: 102203,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "บางแค",
    subdistrictEn: "Bang Kae",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ บางแค กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ บางแค กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Bang Kae, Bangkok, 10160",
  },
  {
    id: 102204,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "บางแคเหนือ",
    subdistrictEn: "Bang Kae Nua",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ บางแคเหนือ กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ บางแคเหนือ กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Bang Kae Nua, Bangkok, 10160",
  },
  {
    id: 102205,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "บางไผ่",
    subdistrictEn: "Bang Phai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ บางไผ่ กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ บางไผ่ กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Bang Phai, Bangkok, 10160",
  },
  {
    id: 102206,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "บางจาก",
    subdistrictEn: "Bang Chak",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ บางจาก กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ บางจาก กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Bang Chak, Bangkok, 10160",
  },
  {
    id: 102207,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "บางแวก",
    subdistrictEn: "Bang Waek",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ บางแวก กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ บางแวก กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Bang Waek, Bangkok, 10160",
  },
  {
    id: 102208,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "คลองขวาง",
    subdistrictEn: "Khlong Khwang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ คลองขวาง กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ คลองขวาง กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Khlong Khwang, Bangkok, 10160",
  },
  {
    id: 102209,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "ปากคลองภาษีเจริญ",
    subdistrictEn: "Pak Khlong Phasi Charoen",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ ปากคลองภาษีเจริญ กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ ปากคลองภาษีเจริญ กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Pak Khlong Phasi Charoen, Bangkok, 10160",
  },
  {
    id: 102210,
    districtTh: "เขตภาษีเจริญ",
    districtEn: "Khet Phasi Charoen",
    subdistrictTh: "คูหาสวรรค์",
    subdistrictEn: "Khuha Sawan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1022,
    province_code: 1,
    name: "เขตภาษีเจริญ คูหาสวรรค์ กรุงเทพมหานคร 10160",
    name_th: "เขตภาษีเจริญ คูหาสวรรค์ กรุงเทพมหานคร 10160",
    name_en: "Khet Phasi Charoen, Khuha Sawan, Bangkok, 10160",
  },
  {
    id: 102302,
    districtTh: "เขตหนองแขม",
    districtEn: "Khet Nong Khaem",
    subdistrictTh: "หนองแขม",
    subdistrictEn: "Nong Khaem",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1023,
    province_code: 1,
    name: "เขตหนองแขม หนองแขม กรุงเทพมหานคร 10160",
    name_th: "เขตหนองแขม หนองแขม กรุงเทพมหานคร 10160",
    name_en: "Khet Nong Khaem, Nong Khaem, Bangkok, 10160",
  },
  {
    id: 102303,
    districtTh: "เขตหนองแขม",
    districtEn: "Khet Nong Khaem",
    subdistrictTh: "หนองค้างพลู",
    subdistrictEn: "Nong Khang Phlu",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1023,
    province_code: 1,
    name: "เขตหนองแขม หนองค้างพลู กรุงเทพมหานคร 10160",
    name_th: "เขตหนองแขม หนองค้างพลู กรุงเทพมหานคร 10160",
    name_en: "Khet Nong Khaem, Nong Khang Phlu, Bangkok, 10160",
  },
  {
    id: 102401,
    districtTh: "เขตราษฎร์บูรณะ",
    districtEn: "Khet Rat Burana",
    subdistrictTh: "ราษฎร์บูรณะ",
    subdistrictEn: "Rat Burana",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10140,
    district_code: 1024,
    province_code: 1,
    name: "เขตราษฎร์บูรณะ ราษฎร์บูรณะ กรุงเทพมหานคร 10140",
    name_th: "เขตราษฎร์บูรณะ ราษฎร์บูรณะ กรุงเทพมหานคร 10140",
    name_en: "Khet Rat Burana, Rat Burana, Bangkok, 10140",
  },
  {
    id: 102402,
    districtTh: "เขตราษฎร์บูรณะ",
    districtEn: "Khet Rat Burana",
    subdistrictTh: "บางปะกอก",
    subdistrictEn: "Bang Pakok",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10140,
    district_code: 1024,
    province_code: 1,
    name: "เขตราษฎร์บูรณะ บางปะกอก กรุงเทพมหานคร 10140",
    name_th: "เขตราษฎร์บูรณะ บางปะกอก กรุงเทพมหานคร 10140",
    name_en: "Khet Rat Burana, Bang Pakok, Bangkok, 10140",
  },
  {
    id: 102501,
    districtTh: "เขตบางพลัด",
    districtEn: "Khet Bang Phlat",
    subdistrictTh: "บางพลัด",
    subdistrictEn: "Bang Phlat",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1025,
    province_code: 1,
    name: "เขตบางพลัด บางพลัด กรุงเทพมหานคร 10700",
    name_th: "เขตบางพลัด บางพลัด กรุงเทพมหานคร 10700",
    name_en: "Khet Bang Phlat, Bang Phlat, Bangkok, 10700",
  },
  {
    id: 102502,
    districtTh: "เขตบางพลัด",
    districtEn: "Khet Bang Phlat",
    subdistrictTh: "บางอ้อ",
    subdistrictEn: "Bang O",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1025,
    province_code: 1,
    name: "เขตบางพลัด บางอ้อ กรุงเทพมหานคร 10700",
    name_th: "เขตบางพลัด บางอ้อ กรุงเทพมหานคร 10700",
    name_en: "Khet Bang Phlat, Bang O, Bangkok, 10700",
  },
  {
    id: 102503,
    districtTh: "เขตบางพลัด",
    districtEn: "Khet Bang Phlat",
    subdistrictTh: "บางบำหรุ",
    subdistrictEn: "Bang Bamru",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1025,
    province_code: 1,
    name: "เขตบางพลัด บางบำหรุ กรุงเทพมหานคร 10700",
    name_th: "เขตบางพลัด บางบำหรุ กรุงเทพมหานคร 10700",
    name_en: "Khet Bang Phlat, Bang Bamru, Bangkok, 10700",
  },
  {
    id: 102504,
    districtTh: "เขตบางพลัด",
    districtEn: "Khet Bang Phlat",
    subdistrictTh: "บางยี่ขัน",
    subdistrictEn: "Bang Yi Khan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10700,
    district_code: 1025,
    province_code: 1,
    name: "เขตบางพลัด บางยี่ขัน กรุงเทพมหานคร 10700",
    name_th: "เขตบางพลัด บางยี่ขัน กรุงเทพมหานคร 10700",
    name_en: "Khet Bang Phlat, Bang Yi Khan, Bangkok, 10700",
  },
  {
    id: 102601,
    districtTh: "เขตดินแดง",
    districtEn: "Khet Din Daeng",
    subdistrictTh: "ดินแดง",
    subdistrictEn: "Din Daeng",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10400,
    district_code: 1026,
    province_code: 1,
    name: "เขตดินแดง ดินแดง กรุงเทพมหานคร 10400",
    name_th: "เขตดินแดง ดินแดง กรุงเทพมหานคร 10400",
    name_en: "Khet Din Daeng, Din Daeng, Bangkok, 10400",
  },
  {
    id: 102701,
    districtTh: "เขตบึงกุ่ม",
    districtEn: "Khet Bueng Kum",
    subdistrictTh: "คลองกุ่ม",
    subdistrictEn: "Khlong Kum",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10240,
    district_code: 1027,
    province_code: 1,
    name: "เขตบึงกุ่ม คลองกุ่ม กรุงเทพมหานคร 10240",
    name_th: "เขตบึงกุ่ม คลองกุ่ม กรุงเทพมหานคร 10240",
    name_en: "Khet Bueng Kum, Khlong Kum, Bangkok, 10240",
  },
  {
    id: 102702,
    districtTh: "เขตบึงกุ่ม",
    districtEn: "Khet Bueng Kum",
    subdistrictTh: "สะพานสูง",
    subdistrictEn: "Saphan Sung",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10240,
    district_code: 1027,
    province_code: 1,
    name: "เขตบึงกุ่ม สะพานสูง กรุงเทพมหานคร 10240",
    name_th: "เขตบึงกุ่ม สะพานสูง กรุงเทพมหานคร 10240",
    name_en: "Khet Bueng Kum, Saphan Sung, Bangkok, 10240",
  },
  {
    id: 102703,
    districtTh: "เขตบึงกุ่ม",
    districtEn: "Khet Bueng Kum",
    subdistrictTh: "คันนายาว",
    subdistrictEn: "Khan Na Yao",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10240,
    district_code: 1027,
    province_code: 1,
    name: "เขตบึงกุ่ม คันนายาว กรุงเทพมหานคร 10240",
    name_th: "เขตบึงกุ่ม คันนายาว กรุงเทพมหานคร 10240",
    name_en: "Khet Bueng Kum, Khan Na Yao, Bangkok, 10240",
  },
  {
    id: 102801,
    districtTh: "เขตสาทร",
    districtEn: "Khet Sathon",
    subdistrictTh: "ทุ่งวัดดอน",
    subdistrictEn: "Thung Wat Don",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10120,
    district_code: 1028,
    province_code: 1,
    name: "เขตสาทร ทุ่งวัดดอน กรุงเทพมหานคร 10120",
    name_th: "เขตสาทร ทุ่งวัดดอน กรุงเทพมหานคร 10120",
    name_en: "Khet Sathon, Thung Wat Don, Bangkok, 10120",
  },
  {
    id: 102802,
    districtTh: "เขตสาทร",
    districtEn: "Khet Sathon",
    subdistrictTh: "ยานนาวา",
    subdistrictEn: "Yan Nawa",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10120,
    district_code: 1028,
    province_code: 1,
    name: "เขตสาทร ยานนาวา กรุงเทพมหานคร 10120",
    name_th: "เขตสาทร ยานนาวา กรุงเทพมหานคร 10120",
    name_en: "Khet Sathon, Yan Nawa, Bangkok, 10120",
  },
  {
    id: 102803,
    districtTh: "เขตสาทร",
    districtEn: "Khet Sathon",
    subdistrictTh: "ทุ่งมหาเมฆ",
    subdistrictEn: "Thung Maha Mek",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10120,
    district_code: 1028,
    province_code: 1,
    name: "เขตสาทร ทุ่งมหาเมฆ กรุงเทพมหานคร 10120",
    name_th: "เขตสาทร ทุ่งมหาเมฆ กรุงเทพมหานคร 10120",
    name_en: "Khet Sathon, Thung Maha Mek, Bangkok, 10120",
  },
  {
    id: 102901,
    districtTh: "เขตบางซื่อ",
    districtEn: "Khet Bang Sue",
    subdistrictTh: "บางซื่อ",
    subdistrictEn: "Bang Sue",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10800,
    district_code: 1029,
    province_code: 1,
    name: "เขตบางซื่อ บางซื่อ กรุงเทพมหานคร 10800",
    name_th: "เขตบางซื่อ บางซื่อ กรุงเทพมหานคร 10800",
    name_en: "Khet Bang Sue, Bang Sue, Bangkok, 10800",
  },
  {
    id: 103001,
    districtTh: "เขตจตุจักร",
    districtEn: "Khet Chatuchak",
    subdistrictTh: "ลาดยาว",
    subdistrictEn: "Lat Yao",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10900,
    district_code: 1030,
    province_code: 1,
    name: "เขตจตุจักร ลาดยาว กรุงเทพมหานคร 10900",
    name_th: "เขตจตุจักร ลาดยาว กรุงเทพมหานคร 10900",
    name_en: "Khet Chatuchak, Lat Yao, Bangkok, 10900",
  },
  {
    id: 103002,
    districtTh: "เขตจตุจักร",
    districtEn: "Khet Chatuchak",
    subdistrictTh: "เสนานิคม",
    subdistrictEn: "Sena Nikhom",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10900,
    district_code: 1030,
    province_code: 1,
    name: "เขตจตุจักร เสนานิคม กรุงเทพมหานคร 10900",
    name_th: "เขตจตุจักร เสนานิคม กรุงเทพมหานคร 10900",
    name_en: "Khet Chatuchak, Sena Nikhom, Bangkok, 10900",
  },
  {
    id: 103003,
    districtTh: "เขตจตุจักร",
    districtEn: "Khet Chatuchak",
    subdistrictTh: "จันทรเกษม",
    subdistrictEn: "Chan Kasem",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10900,
    district_code: 1030,
    province_code: 1,
    name: "เขตจตุจักร จันทรเกษม กรุงเทพมหานคร 10900",
    name_th: "เขตจตุจักร จันทรเกษม กรุงเทพมหานคร 10900",
    name_en: "Khet Chatuchak, Chan Kasem, Bangkok, 10900",
  },
  {
    id: 103004,
    districtTh: "เขตจตุจักร",
    districtEn: "Khet Chatuchak",
    subdistrictTh: "จอมพล",
    subdistrictEn: "Chom Phon",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10900,
    district_code: 1030,
    province_code: 1,
    name: "เขตจตุจักร จอมพล กรุงเทพมหานคร 10900",
    name_th: "เขตจตุจักร จอมพล กรุงเทพมหานคร 10900",
    name_en: "Khet Chatuchak, Chom Phon, Bangkok, 10900",
  },
  {
    id: 103005,
    districtTh: "เขตจตุจักร",
    districtEn: "Khet Chatuchak",
    subdistrictTh: "จตุจักร",
    subdistrictEn: "Chatuchak",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10900,
    district_code: 1030,
    province_code: 1,
    name: "เขตจตุจักร จตุจักร กรุงเทพมหานคร 10900",
    name_th: "เขตจตุจักร จตุจักร กรุงเทพมหานคร 10900",
    name_en: "Khet Chatuchak, Chatuchak, Bangkok, 10900",
  },
  {
    id: 103101,
    districtTh: "เขตบางคอแหลม",
    districtEn: "Khet Bang Kho Laem",
    subdistrictTh: "บางคอแหลม",
    subdistrictEn: "Bang Kho Laem",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10120,
    district_code: 1031,
    province_code: 1,
    name: "เขตบางคอแหลม บางคอแหลม กรุงเทพมหานคร 10120",
    name_th: "เขตบางคอแหลม บางคอแหลม กรุงเทพมหานคร 10120",
    name_en: "Khet Bang Kho Laem, Bang Kho Laem, Bangkok, 10120",
  },
  {
    id: 103102,
    districtTh: "เขตบางคอแหลม",
    districtEn: "Khet Bang Kho Laem",
    subdistrictTh: "วัดพระยาไกร",
    subdistrictEn: "Wat Phraya Krai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10120,
    district_code: 1031,
    province_code: 1,
    name: "เขตบางคอแหลม วัดพระยาไกร กรุงเทพมหานคร 10120",
    name_th: "เขตบางคอแหลม วัดพระยาไกร กรุงเทพมหานคร 10120",
    name_en: "Khet Bang Kho Laem, Wat Phraya Krai, Bangkok, 10120",
  },
  {
    id: 103103,
    districtTh: "เขตบางคอแหลม",
    districtEn: "Khet Bang Kho Laem",
    subdistrictTh: "บางโคล่",
    subdistrictEn: "Bang Khlo",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10120,
    district_code: 1031,
    province_code: 1,
    name: "เขตบางคอแหลม บางโคล่ กรุงเทพมหานคร 10120",
    name_th: "เขตบางคอแหลม บางโคล่ กรุงเทพมหานคร 10120",
    name_en: "Khet Bang Kho Laem, Bang Khlo, Bangkok, 10120",
  },
  {
    id: 103201,
    districtTh: "เขตประเวศ",
    districtEn: "Khet Prawet",
    subdistrictTh: "ประเวศ",
    subdistrictEn: "Prawet",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10250,
    district_code: 1032,
    province_code: 1,
    name: "เขตประเวศ ประเวศ กรุงเทพมหานคร 10250",
    name_th: "เขตประเวศ ประเวศ กรุงเทพมหานคร 10250",
    name_en: "Khet Prawet, Prawet, Bangkok, 10250",
  },
  {
    id: 103202,
    districtTh: "เขตประเวศ",
    districtEn: "Khet Prawet",
    subdistrictTh: "หนองบอน",
    subdistrictEn: "Nong Bon",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10250,
    district_code: 1032,
    province_code: 1,
    name: "เขตประเวศ หนองบอน กรุงเทพมหานคร 10250",
    name_th: "เขตประเวศ หนองบอน กรุงเทพมหานคร 10250",
    name_en: "Khet Prawet, Nong Bon, Bangkok, 10250",
  },
  {
    id: 103203,
    districtTh: "เขตประเวศ",
    districtEn: "Khet Prawet",
    subdistrictTh: "ดอกไม้",
    subdistrictEn: "Dokmai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10250,
    district_code: 1032,
    province_code: 1,
    name: "เขตประเวศ ดอกไม้ กรุงเทพมหานคร 10250",
    name_th: "เขตประเวศ ดอกไม้ กรุงเทพมหานคร 10250",
    name_en: "Khet Prawet, Dokmai, Bangkok, 10250",
  },
  {
    id: 103204,
    districtTh: "เขตประเวศ",
    districtEn: "Khet Prawet",
    subdistrictTh: "สวนหลวง",
    subdistrictEn: "Suan Luang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10250,
    district_code: 1032,
    province_code: 1,
    name: "เขตประเวศ สวนหลวง กรุงเทพมหานคร 10250",
    name_th: "เขตประเวศ สวนหลวง กรุงเทพมหานคร 10250",
    name_en: "Khet Prawet, Suan Luang, Bangkok, 10250",
  },
  {
    id: 103301,
    districtTh: "เขตคลองเตย",
    districtEn: "Khet Khlong Toei",
    subdistrictTh: "คลองเตย",
    subdistrictEn: "Khlong Toei",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1033,
    province_code: 1,
    name: "เขตคลองเตย คลองเตย กรุงเทพมหานคร 10110",
    name_th: "เขตคลองเตย คลองเตย กรุงเทพมหานคร 10110",
    name_en: "Khet Khlong Toei, Khlong Toei, Bangkok, 10110",
  },
  {
    id: 103302,
    districtTh: "เขตคลองเตย",
    districtEn: "Khet Khlong Toei",
    subdistrictTh: "คลองตัน",
    subdistrictEn: "Khlong Tan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1033,
    province_code: 1,
    name: "เขตคลองเตย คลองตัน กรุงเทพมหานคร 10110",
    name_th: "เขตคลองเตย คลองตัน กรุงเทพมหานคร 10110",
    name_en: "Khet Khlong Toei, Khlong Tan, Bangkok, 10110",
  },
  {
    id: 103303,
    districtTh: "เขตคลองเตย",
    districtEn: "Khet Khlong Toei",
    subdistrictTh: "พระโขนง",
    subdistrictEn: "Phra Khanong",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1033,
    province_code: 1,
    name: "เขตคลองเตย พระโขนง กรุงเทพมหานคร 10110",
    name_th: "เขตคลองเตย พระโขนง กรุงเทพมหานคร 10110",
    name_en: "Khet Khlong Toei, Phra Khanong, Bangkok, 10110",
  },
  {
    id: 103304,
    districtTh: "เขตคลองเตย",
    districtEn: "Khet Khlong Toei",
    subdistrictTh: "คลองเตยเหนือ",
    subdistrictEn: "Khlong Toei Nua",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1033,
    province_code: 1,
    name: "เขตคลองเตย คลองเตยเหนือ กรุงเทพมหานคร 10110",
    name_th: "เขตคลองเตย คลองเตยเหนือ กรุงเทพมหานคร 10110",
    name_en: "Khet Khlong Toei, Khlong Toei Nua, Bangkok, 10110",
  },
  {
    id: 103305,
    districtTh: "เขตคลองเตย",
    districtEn: "Khet Khlong Toei",
    subdistrictTh: "คลองตันเหนือ",
    subdistrictEn: "Khlong Tan Nua",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1033,
    province_code: 1,
    name: "เขตคลองเตย คลองตันเหนือ กรุงเทพมหานคร 10110",
    name_th: "เขตคลองเตย คลองตันเหนือ กรุงเทพมหานคร 10110",
    name_en: "Khet Khlong Toei, Khlong Tan Nua, Bangkok, 10110",
  },
  {
    id: 103306,
    districtTh: "เขตคลองเตย",
    districtEn: "Khet Khlong Toei",
    subdistrictTh: "พระโขนงเหนือ",
    subdistrictEn: "Phra Khanong Nua",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1033,
    province_code: 1,
    name: "เขตคลองเตย พระโขนงเหนือ กรุงเทพมหานคร 10110",
    name_th: "เขตคลองเตย พระโขนงเหนือ กรุงเทพมหานคร 10110",
    name_en: "Khet Khlong Toei, Phra Khanong Nua, Bangkok, 10110",
  },
  {
    id: 103401,
    districtTh: "เขตสวนหลวง",
    districtEn: "Khet Suan Luang",
    subdistrictTh: "สวนหลวง",
    subdistrictEn: "Suan Luang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10250,
    district_code: 1034,
    province_code: 1,
    name: "เขตสวนหลวง สวนหลวง กรุงเทพมหานคร 10250",
    name_th: "เขตสวนหลวง สวนหลวง กรุงเทพมหานคร 10250",
    name_en: "Khet Suan Luang, Suan Luang, Bangkok, 10250",
  },
  {
    id: 103501,
    districtTh: "เขตจอมทอง",
    districtEn: "Khet Chom Thong",
    subdistrictTh: "บางขุนเทียน",
    subdistrictEn: "Bang Khun Thian",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10150,
    district_code: 1035,
    province_code: 1,
    name: "เขตจอมทอง บางขุนเทียน กรุงเทพมหานคร 10150",
    name_th: "เขตจอมทอง บางขุนเทียน กรุงเทพมหานคร 10150",
    name_en: "Khet Chom Thong, Bang Khun Thian, Bangkok, 10150",
  },
  {
    id: 103502,
    districtTh: "เขตจอมทอง",
    districtEn: "Khet Chom Thong",
    subdistrictTh: "บางค้อ",
    subdistrictEn: "Bang Kho",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10150,
    district_code: 1035,
    province_code: 1,
    name: "เขตจอมทอง บางค้อ กรุงเทพมหานคร 10150",
    name_th: "เขตจอมทอง บางค้อ กรุงเทพมหานคร 10150",
    name_en: "Khet Chom Thong, Bang Kho, Bangkok, 10150",
  },
  {
    id: 103503,
    districtTh: "เขตจอมทอง",
    districtEn: "Khet Chom Thong",
    subdistrictTh: "บางมด",
    subdistrictEn: "Bang Mot",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10150,
    district_code: 1035,
    province_code: 1,
    name: "เขตจอมทอง บางมด กรุงเทพมหานคร 10150",
    name_th: "เขตจอมทอง บางมด กรุงเทพมหานคร 10150",
    name_en: "Khet Chom Thong, Bang Mot, Bangkok, 10150",
  },
  {
    id: 103504,
    districtTh: "เขตจอมทอง",
    districtEn: "Khet Chom Thong",
    subdistrictTh: "จอมทอง",
    subdistrictEn: "Chom Thong",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10150,
    district_code: 1035,
    province_code: 1,
    name: "เขตจอมทอง จอมทอง กรุงเทพมหานคร 10150",
    name_th: "เขตจอมทอง จอมทอง กรุงเทพมหานคร 10150",
    name_en: "Khet Chom Thong, Chom Thong, Bangkok, 10150",
  },
  {
    id: 103602,
    districtTh: "เขตดอนเมือง",
    districtEn: "Khet Don Mueang",
    subdistrictTh: "สีกัน",
    subdistrictEn: "Si Kan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10210,
    district_code: 1036,
    province_code: 1,
    name: "เขตดอนเมือง สีกัน กรุงเทพมหานคร 10210",
    name_th: "เขตดอนเมือง สีกัน กรุงเทพมหานคร 10210",
    name_en: "Khet Don Mueang, Si Kan, Bangkok, 10210",
  },
  {
    id: 103701,
    districtTh: "เขตราชเทวี",
    districtEn: "Khet Ratchathewi",
    subdistrictTh: "ทุ่งพญาไท",
    subdistrictEn: "Thung Phaya Thai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10400,
    district_code: 1037,
    province_code: 1,
    name: "เขตราชเทวี ทุ่งพญาไท กรุงเทพมหานคร 10400",
    name_th: "เขตราชเทวี ทุ่งพญาไท กรุงเทพมหานคร 10400",
    name_en: "Khet Ratchathewi, Thung Phaya Thai, Bangkok, 10400",
  },
  {
    id: 103702,
    districtTh: "เขตราชเทวี",
    districtEn: "Khet Ratchathewi",
    subdistrictTh: "ถนนพญาไท",
    subdistrictEn: "Thanon Phaya Thai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10400,
    district_code: 1037,
    province_code: 1,
    name: "เขตราชเทวี ถนนพญาไท กรุงเทพมหานคร 10400",
    name_th: "เขตราชเทวี ถนนพญาไท กรุงเทพมหานคร 10400",
    name_en: "Khet Ratchathewi, Thanon Phaya Thai, Bangkok, 10400",
  },
  {
    id: 103703,
    districtTh: "เขตราชเทวี",
    districtEn: "Khet Ratchathewi",
    subdistrictTh: "ถนนเพชรบุรี",
    subdistrictEn: "Thanon Phetchaburi",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10400,
    district_code: 1037,
    province_code: 1,
    name: "เขตราชเทวี ถนนเพชรบุรี กรุงเทพมหานคร 10400",
    name_th: "เขตราชเทวี ถนนเพชรบุรี กรุงเทพมหานคร 10400",
    name_en: "Khet Ratchathewi, Thanon Phetchaburi, Bangkok, 10400",
  },
  {
    id: 103704,
    districtTh: "เขตราชเทวี",
    districtEn: "Khet Ratchathewi",
    subdistrictTh: "มักกะสัน",
    subdistrictEn: "Makkasan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10400,
    district_code: 1037,
    province_code: 1,
    name: "เขตราชเทวี มักกะสัน กรุงเทพมหานคร 10400",
    name_th: "เขตราชเทวี มักกะสัน กรุงเทพมหานคร 10400",
    name_en: "Khet Ratchathewi, Makkasan, Bangkok, 10400",
  },
  {
    id: 103801,
    districtTh: "เขตลาดพร้าว",
    districtEn: "Khet Lat Phrao",
    subdistrictTh: "ลาดพร้าว",
    subdistrictEn: "Lat Phrao",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10230,
    district_code: 1038,
    province_code: 1,
    name: "เขตลาดพร้าว ลาดพร้าว กรุงเทพมหานคร 10230",
    name_th: "เขตลาดพร้าว ลาดพร้าว กรุงเทพมหานคร 10230",
    name_en: "Khet Lat Phrao, Lat Phrao, Bangkok, 10230",
  },
  {
    id: 103802,
    districtTh: "เขตลาดพร้าว",
    districtEn: "Khet Lat Phrao",
    subdistrictTh: "จรเข้บัว",
    subdistrictEn: "Chorakhe Bua",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10230,
    district_code: 1038,
    province_code: 1,
    name: "เขตลาดพร้าว จรเข้บัว กรุงเทพมหานคร 10230",
    name_th: "เขตลาดพร้าว จรเข้บัว กรุงเทพมหานคร 10230",
    name_en: "Khet Lat Phrao, Chorakhe Bua, Bangkok, 10230",
  },
  {
    id: 103901,
    districtTh: "เขตวัฒนา",
    districtEn: "Khet Watthana",
    subdistrictTh: "คลองเตยเหนือ",
    subdistrictEn: "Khlong Toei Nuea",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1039,
    province_code: 1,
    name: "เขตวัฒนา คลองเตยเหนือ กรุงเทพมหานคร 10110",
    name_th: "เขตวัฒนา คลองเตยเหนือ กรุงเทพมหานคร 10110",
    name_en: "Khet Watthana, Khlong Toei Nuea, Bangkok, 10110",
  },
  {
    id: 103902,
    districtTh: "เขตวัฒนา",
    districtEn: "Khet Watthana",
    subdistrictTh: "คลองตันเหนือ",
    subdistrictEn: "Khlong Tan Nuea",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1039,
    province_code: 1,
    name: "เขตวัฒนา คลองตันเหนือ กรุงเทพมหานคร 10110",
    name_th: "เขตวัฒนา คลองตันเหนือ กรุงเทพมหานคร 10110",
    name_en: "Khet Watthana, Khlong Tan Nuea, Bangkok, 10110",
  },
  {
    id: 103903,
    districtTh: "เขตวัฒนา",
    districtEn: "Khet Watthana",
    subdistrictTh: "พระโขนงเหนือ",
    subdistrictEn: "Phra Khanong Nuea",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10110,
    district_code: 1039,
    province_code: 1,
    name: "เขตวัฒนา พระโขนงเหนือ กรุงเทพมหานคร 10110",
    name_th: "เขตวัฒนา พระโขนงเหนือ กรุงเทพมหานคร 10110",
    name_en: "Khet Watthana, Phra Khanong Nuea, Bangkok, 10110",
  },
  {
    id: 104001,
    districtTh: "เขตบางแค",
    districtEn: "Khet Bang Khae",
    subdistrictTh: "บางแค",
    subdistrictEn: "Bang Khae",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1040,
    province_code: 1,
    name: "เขตบางแค บางแค กรุงเทพมหานคร 10160",
    name_th: "เขตบางแค บางแค กรุงเทพมหานคร 10160",
    name_en: "Khet Bang Khae, Bang Khae, Bangkok, 10160",
  },
  {
    id: 104002,
    districtTh: "เขตบางแค",
    districtEn: "Khet Bang Khae",
    subdistrictTh: "บางแคเหนือ",
    subdistrictEn: "Bang Khae Nuea",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1040,
    province_code: 1,
    name: "เขตบางแค บางแคเหนือ กรุงเทพมหานคร 10160",
    name_th: "เขตบางแค บางแคเหนือ กรุงเทพมหานคร 10160",
    name_en: "Khet Bang Khae, Bang Khae Nuea, Bangkok, 10160",
  },
  {
    id: 104003,
    districtTh: "เขตบางแค",
    districtEn: "Khet Bang Khae",
    subdistrictTh: "บางไผ่",
    subdistrictEn: "Bang Phai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1040,
    province_code: 1,
    name: "เขตบางแค บางไผ่ กรุงเทพมหานคร 10160",
    name_th: "เขตบางแค บางไผ่ กรุงเทพมหานคร 10160",
    name_en: "Khet Bang Khae, Bang Phai, Bangkok, 10160",
  },
  {
    id: 104004,
    districtTh: "เขตบางแค",
    districtEn: "Khet Bang Khae",
    subdistrictTh: "หลักสอง",
    subdistrictEn: "Lak Song",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10160,
    district_code: 1040,
    province_code: 1,
    name: "เขตบางแค หลักสอง กรุงเทพมหานคร 10160",
    name_th: "เขตบางแค หลักสอง กรุงเทพมหานคร 10160",
    name_en: "Khet Bang Khae, Lak Song, Bangkok, 10160",
  },
  {
    id: 104101,
    districtTh: "เขตหลักสี่",
    districtEn: "Khet Lak Si",
    subdistrictTh: "ทุ่งสองห้อง",
    subdistrictEn: "Thung Song Hong",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10210,
    district_code: 1041,
    province_code: 1,
    name: "เขตหลักสี่ ทุ่งสองห้อง กรุงเทพมหานคร 10210",
    name_th: "เขตหลักสี่ ทุ่งสองห้อง กรุงเทพมหานคร 10210",
    name_en: "Khet Lak Si, Thung Song Hong, Bangkok, 10210",
  },
  {
    id: 104102,
    districtTh: "เขตหลักสี่",
    districtEn: "Khet Lak Si",
    subdistrictTh: "ตลาดบางเขน",
    subdistrictEn: "Talat Bang Khen",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10210,
    district_code: 1041,
    province_code: 1,
    name: "เขตหลักสี่ ตลาดบางเขน กรุงเทพมหานคร 10210",
    name_th: "เขตหลักสี่ ตลาดบางเขน กรุงเทพมหานคร 10210",
    name_en: "Khet Lak Si, Talat Bang Khen, Bangkok, 10210",
  },
  {
    id: 104201,
    districtTh: "เขตสายไหม",
    districtEn: "Khet Sai Mai",
    subdistrictTh: "สายไหม",
    subdistrictEn: "Sai Mai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10220,
    district_code: 1042,
    province_code: 1,
    name: "เขตสายไหม สายไหม กรุงเทพมหานคร 10220",
    name_th: "เขตสายไหม สายไหม กรุงเทพมหานคร 10220",
    name_en: "Khet Sai Mai, Sai Mai, Bangkok, 10220",
  },
  {
    id: 104202,
    districtTh: "เขตสายไหม",
    districtEn: "Khet Sai Mai",
    subdistrictTh: "ออเงิน",
    subdistrictEn: "O Ngoen",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10220,
    district_code: 1042,
    province_code: 1,
    name: "เขตสายไหม ออเงิน กรุงเทพมหานคร 10220",
    name_th: "เขตสายไหม ออเงิน กรุงเทพมหานคร 10220",
    name_en: "Khet Sai Mai, O Ngoen, Bangkok, 10220",
  },
  {
    id: 104203,
    districtTh: "เขตสายไหม",
    districtEn: "Khet Sai Mai",
    subdistrictTh: "คลองถนน",
    subdistrictEn: "Khlong Thanon",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10220,
    district_code: 1042,
    province_code: 1,
    name: "เขตสายไหม คลองถนน กรุงเทพมหานคร 10220",
    name_th: "เขตสายไหม คลองถนน กรุงเทพมหานคร 10220",
    name_en: "Khet Sai Mai, Khlong Thanon, Bangkok, 10220",
  },
  {
    id: 104301,
    districtTh: "เขตคันนายาว",
    districtEn: "Khet Khan Na Yao",
    subdistrictTh: "คันนายาว",
    subdistrictEn: "Khan Na Yao",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10230,
    district_code: 1043,
    province_code: 1,
    name: "เขตคันนายาว คันนายาว กรุงเทพมหานคร 10230",
    name_th: "เขตคันนายาว คันนายาว กรุงเทพมหานคร 10230",
    name_en: "Khet Khan Na Yao, Khan Na Yao, Bangkok, 10230",
  },
  {
    id: 104401,
    districtTh: "เขตสะพานสูง",
    districtEn: "Khet Saphan Sung",
    subdistrictTh: "สะพานสูง",
    subdistrictEn: "Sapan Sung",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10240,
    district_code: 1044,
    province_code: 1,
    name: "เขตสะพานสูง สะพานสูง กรุงเทพมหานคร 10240",
    name_th: "เขตสะพานสูง สะพานสูง กรุงเทพมหานคร 10240",
    name_en: "Khet Saphan Sung, Sapan Sung, Bangkok, 10240",
  },
  {
    id: 104501,
    districtTh: "เขตวังทองหลาง",
    districtEn: "Khet Wang Thonglang",
    subdistrictTh: "วังทองหลาง",
    subdistrictEn: "Wang Thonglang",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10310,
    district_code: 1045,
    province_code: 1,
    name: "เขตวังทองหลาง วังทองหลาง กรุงเทพมหานคร 10310",
    name_th: "เขตวังทองหลาง วังทองหลาง กรุงเทพมหานคร 10310",
    name_en: "Khet Wang Thonglang, Wang Thonglang, Bangkok, 10310",
  },
  {
    id: 104601,
    districtTh: "เขตคลองสามวา",
    districtEn: "Khet Khlong Sam Wa",
    subdistrictTh: "สามวาตะวันตก",
    subdistrictEn: "Sam Wa Tawantok",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10510,
    district_code: 1046,
    province_code: 1,
    name: "เขตคลองสามวา สามวาตะวันตก กรุงเทพมหานคร 10510",
    name_th: "เขตคลองสามวา สามวาตะวันตก กรุงเทพมหานคร 10510",
    name_en: "Khet Khlong Sam Wa, Sam Wa Tawantok, Bangkok, 10510",
  },
  {
    id: 104602,
    districtTh: "เขตคลองสามวา",
    districtEn: "Khet Khlong Sam Wa",
    subdistrictTh: "สามวาตะวันออก",
    subdistrictEn: "Sam Wa Tawan-ok",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10510,
    district_code: 1046,
    province_code: 1,
    name: "เขตคลองสามวา สามวาตะวันออก กรุงเทพมหานคร 10510",
    name_th: "เขตคลองสามวา สามวาตะวันออก กรุงเทพมหานคร 10510",
    name_en: "Khet Khlong Sam Wa, Sam Wa Tawan-ok, Bangkok, 10510",
  },
  {
    id: 104603,
    districtTh: "เขตคลองสามวา",
    districtEn: "Khet Khlong Sam Wa",
    subdistrictTh: "บางชัน",
    subdistrictEn: "Bang Chan",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10510,
    district_code: 1046,
    province_code: 1,
    name: "เขตคลองสามวา บางชัน กรุงเทพมหานคร 10510",
    name_th: "เขตคลองสามวา บางชัน กรุงเทพมหานคร 10510",
    name_en: "Khet Khlong Sam Wa, Bang Chan, Bangkok, 10510",
  },
  {
    id: 104604,
    districtTh: "เขตคลองสามวา",
    districtEn: "Khet Khlong Sam Wa",
    subdistrictTh: "ทรายกองดิน",
    subdistrictEn: "Sai Kong Din",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10510,
    district_code: 1046,
    province_code: 1,
    name: "เขตคลองสามวา ทรายกองดิน กรุงเทพมหานคร 10510",
    name_th: "เขตคลองสามวา ทรายกองดิน กรุงเทพมหานคร 10510",
    name_en: "Khet Khlong Sam Wa, Sai Kong Din, Bangkok, 10510",
  },
  {
    id: 104605,
    districtTh: "เขตคลองสามวา",
    districtEn: "Khet Khlong Sam Wa",
    subdistrictTh: "ทรายกองดินใต้",
    subdistrictEn: "Sai Kong Din Tai",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10510,
    district_code: 1046,
    province_code: 1,
    name: "เขตคลองสามวา ทรายกองดินใต้ กรุงเทพมหานคร 10510",
    name_th: "เขตคลองสามวา ทรายกองดินใต้ กรุงเทพมหานคร 10510",
    name_en: "Khet Khlong Sam Wa, Sai Kong Din Tai, Bangkok, 10510",
  },
  {
    id: 104701,
    districtTh: "เขตบางนา",
    districtEn: "Khet Bang Na",
    subdistrictTh: "บางนา",
    subdistrictEn: "Bang Na",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10260,
    district_code: 1047,
    province_code: 1,
    name: "เขตบางนา บางนา กรุงเทพมหานคร 10260",
    name_th: "เขตบางนา บางนา กรุงเทพมหานคร 10260",
    name_en: "Khet Bang Na, Bang Na, Bangkok, 10260",
  },
  {
    id: 104801,
    districtTh: "เขตทวีวัฒนา",
    districtEn: "Khet Thawi Watthana",
    subdistrictTh: "ทวีวัฒนา",
    subdistrictEn: "Thawi Watthana",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10170,
    district_code: 1048,
    province_code: 1,
    name: "เขตทวีวัฒนา ทวีวัฒนา กรุงเทพมหานคร 10170",
    name_th: "เขตทวีวัฒนา ทวีวัฒนา กรุงเทพมหานคร 10170",
    name_en: "Khet Thawi Watthana, Thawi Watthana, Bangkok, 10170",
  },
  {
    id: 104802,
    districtTh: "เขตทวีวัฒนา",
    districtEn: "Khet Thawi Watthana",
    subdistrictTh: "ศาลาธรรมสพน์",
    subdistrictEn: "Sala Thammasop",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10170,
    district_code: 1048,
    province_code: 1,
    name: "เขตทวีวัฒนา ศาลาธรรมสพน์ กรุงเทพมหานคร 10170",
    name_th: "เขตทวีวัฒนา ศาลาธรรมสพน์ กรุงเทพมหานคร 10170",
    name_en: "Khet Thawi Watthana, Sala Thammasop, Bangkok, 10170",
  },
  {
    id: 104902,
    districtTh: "เขตทุ่งครุ",
    districtEn: "Khet Thung Khru",
    subdistrictTh: "ทุ่งครุ",
    subdistrictEn: "Thung Khru",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10140,
    district_code: 1049,
    province_code: 1,
    name: "เขตทุ่งครุ ทุ่งครุ กรุงเทพมหานคร 10140",
    name_th: "เขตทุ่งครุ ทุ่งครุ กรุงเทพมหานคร 10140",
    name_en: "Khet Thung Khru, Thung Khru, Bangkok, 10140",
  },
  {
    id: 105001,
    districtTh: "เขตบางบอน",
    districtEn: "Khet Bang Bon",
    subdistrictTh: "บางบอน",
    subdistrictEn: "Bang Bon",
    provinceTh: "กรุงเทพมหานคร",
    provinceEn: "Bangkok",
    zipcode: 10150,
    district_code: 1050,
    province_code: 1,
    name: "เขตบางบอน บางบอน กรุงเทพมหานคร 10150",
    name_th: "เขตบางบอน บางบอน กรุงเทพมหานคร 10150",
    name_en: "Khet Bang Bon, Bang Bon, Bangkok, 10150",
  },
  {
    id: 110101,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10270,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ ปากน้ำ สมุทรปราการ 10270",
    name_th: "เมืองสมุทรปราการ ปากน้ำ สมุทรปราการ 10270",
    name_en: "Mueang Samut Prakan, Pak Nam, Samut Prakan, 10270",
  },
  {
    id: 110102,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "สำโรงเหนือ",
    subdistrictEn: "Samrong Nuea",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10270,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ สำโรงเหนือ สมุทรปราการ 10270",
    name_th: "เมืองสมุทรปราการ สำโรงเหนือ สมุทรปราการ 10270",
    name_en: "Mueang Samut Prakan, Samrong Nuea, Samut Prakan, 10270",
  },
  {
    id: 330413,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "ชำ",
    subdistrictEn: "Cham",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ ชำ ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ ชำ ศรีสะเกษ 33110",
    name_en: "Kantharalak, Cham, Si Sa Ket, 33110",
  },
  {
    id: 110103,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "บางเมือง",
    subdistrictEn: "Bang Mueang",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10270,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ บางเมือง สมุทรปราการ 10270",
    name_th: "เมืองสมุทรปราการ บางเมือง สมุทรปราการ 10270",
    name_en: "Mueang Samut Prakan, Bang Mueang, Samut Prakan, 10270",
  },
  {
    id: 110104,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "ท้ายบ้าน",
    subdistrictEn: "Thai Ban",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10280,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ ท้ายบ้าน สมุทรปราการ 10280",
    name_th: "เมืองสมุทรปราการ ท้ายบ้าน สมุทรปราการ 10280",
    name_en: "Mueang Samut Prakan, Thai Ban, Samut Prakan, 10280",
  },
  {
    id: 110108,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "บางปูใหม่",
    subdistrictEn: "Bang Pu Mai",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10280,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ บางปูใหม่ สมุทรปราการ 10280",
    name_th: "เมืองสมุทรปราการ บางปูใหม่ สมุทรปราการ 10280",
    name_en: "Mueang Samut Prakan, Bang Pu Mai, Samut Prakan, 10280",
  },
  {
    id: 110110,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "แพรกษา",
    subdistrictEn: "Phraek Sa",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10280,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ แพรกษา สมุทรปราการ 10280",
    name_th: "เมืองสมุทรปราการ แพรกษา สมุทรปราการ 10280",
    name_en: "Mueang Samut Prakan, Phraek Sa, Samut Prakan, 10280",
  },
  {
    id: 110111,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "บางโปรง",
    subdistrictEn: "Bang Prong",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10270,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ บางโปรง สมุทรปราการ 10270",
    name_th: "เมืองสมุทรปราการ บางโปรง สมุทรปราการ 10270",
    name_en: "Mueang Samut Prakan, Bang Prong, Samut Prakan, 10270",
  },
  {
    id: 110112,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "บางปู",
    subdistrictEn: "Bang Pu",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10270,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ บางปู สมุทรปราการ 10270",
    name_th: "เมืองสมุทรปราการ บางปู สมุทรปราการ 10270",
    name_en: "Mueang Samut Prakan, Bang Pu, Samut Prakan, 10270",
  },
  {
    id: 110113,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "บางด้วน",
    subdistrictEn: "Bang Duan",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10270,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ บางด้วน สมุทรปราการ 10270",
    name_th: "เมืองสมุทรปราการ บางด้วน สมุทรปราการ 10270",
    name_en: "Mueang Samut Prakan, Bang Duan, Samut Prakan, 10270",
  },
  {
    id: 110114,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "บางเมืองใหม่",
    subdistrictEn: "Bang Mueang Mai",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10270,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ บางเมืองใหม่ สมุทรปราการ 10270",
    name_th: "เมืองสมุทรปราการ บางเมืองใหม่ สมุทรปราการ 10270",
    name_en: "Mueang Samut Prakan, Bang Mueang Mai, Samut Prakan, 10270",
  },
  {
    id: 110115,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "เทพารักษ์",
    subdistrictEn: "Thepharak",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10270,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ เทพารักษ์ สมุทรปราการ 10270",
    name_th: "เมืองสมุทรปราการ เทพารักษ์ สมุทรปราการ 10270",
    name_en: "Mueang Samut Prakan, Thepharak, Samut Prakan, 10270",
  },
  {
    id: 110116,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "ท้ายบ้านใหม่",
    subdistrictEn: "Thai Ban Mai",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10280,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ ท้ายบ้านใหม่ สมุทรปราการ 10280",
    name_th: "เมืองสมุทรปราการ ท้ายบ้านใหม่ สมุทรปราการ 10280",
    name_en: "Mueang Samut Prakan, Thai Ban Mai, Samut Prakan, 10280",
  },
  {
    id: 110117,
    districtTh: "เมืองสมุทรปราการ",
    districtEn: "Mueang Samut Prakan",
    subdistrictTh: "แพรกษาใหม่",
    subdistrictEn: "Phraek Sa Mai",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10280,
    district_code: 1101,
    province_code: 2,
    name: "เมืองสมุทรปราการ แพรกษาใหม่ สมุทรปราการ 10280",
    name_th: "เมืองสมุทรปราการ แพรกษาใหม่ สมุทรปราการ 10280",
    name_en: "Mueang Samut Prakan, Phraek Sa Mai, Samut Prakan, 10280",
  },
  {
    id: 110201,
    districtTh: "บางบ่อ",
    districtEn: "Bang Bo",
    subdistrictTh: "บางบ่อ",
    subdistrictEn: "Bang Bo",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10560,
    district_code: 1102,
    province_code: 2,
    name: "บางบ่อ บางบ่อ สมุทรปราการ 10560",
    name_th: "บางบ่อ บางบ่อ สมุทรปราการ 10560",
    name_en: "Bang Bo, Bang Bo, Samut Prakan, 10560",
  },
  {
    id: 110202,
    districtTh: "บางบ่อ",
    districtEn: "Bang Bo",
    subdistrictTh: "บ้านระกาศ",
    subdistrictEn: "Ban Rakat",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10560,
    district_code: 1102,
    province_code: 2,
    name: "บางบ่อ บ้านระกาศ สมุทรปราการ 10560",
    name_th: "บางบ่อ บ้านระกาศ สมุทรปราการ 10560",
    name_en: "Bang Bo, Ban Rakat, Samut Prakan, 10560",
  },
  {
    id: 110203,
    districtTh: "บางบ่อ",
    districtEn: "Bang Bo",
    subdistrictTh: "บางพลีน้อย",
    subdistrictEn: "Bang Phli Noi",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10560,
    district_code: 1102,
    province_code: 2,
    name: "บางบ่อ บางพลีน้อย สมุทรปราการ 10560",
    name_th: "บางบ่อ บางพลีน้อย สมุทรปราการ 10560",
    name_en: "Bang Bo, Bang Phli Noi, Samut Prakan, 10560",
  },
  {
    id: 110204,
    districtTh: "บางบ่อ",
    districtEn: "Bang Bo",
    subdistrictTh: "บางเพรียง",
    subdistrictEn: "Bang Phriang",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10560,
    district_code: 1102,
    province_code: 2,
    name: "บางบ่อ บางเพรียง สมุทรปราการ 10560",
    name_th: "บางบ่อ บางเพรียง สมุทรปราการ 10560",
    name_en: "Bang Bo, Bang Phriang, Samut Prakan, 10560",
  },
  {
    id: 110205,
    districtTh: "บางบ่อ",
    districtEn: "Bang Bo",
    subdistrictTh: "คลองด่าน",
    subdistrictEn: "Khlong Dan",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10550,
    district_code: 1102,
    province_code: 2,
    name: "บางบ่อ คลองด่าน สมุทรปราการ 10550",
    name_th: "บางบ่อ คลองด่าน สมุทรปราการ 10550",
    name_en: "Bang Bo, Khlong Dan, Samut Prakan, 10550",
  },
  {
    id: 110206,
    districtTh: "บางบ่อ",
    districtEn: "Bang Bo",
    subdistrictTh: "คลองสวน",
    subdistrictEn: "Khlong Suan",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10560,
    district_code: 1102,
    province_code: 2,
    name: "บางบ่อ คลองสวน สมุทรปราการ 10560",
    name_th: "บางบ่อ คลองสวน สมุทรปราการ 10560",
    name_en: "Bang Bo, Khlong Suan, Samut Prakan, 10560",
  },
  {
    id: 110207,
    districtTh: "บางบ่อ",
    districtEn: "Bang Bo",
    subdistrictTh: "เปร็ง",
    subdistrictEn: "Preng",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10560,
    district_code: 1102,
    province_code: 2,
    name: "บางบ่อ เปร็ง สมุทรปราการ 10560",
    name_th: "บางบ่อ เปร็ง สมุทรปราการ 10560",
    name_en: "Bang Bo, Preng, Samut Prakan, 10560",
  },
  {
    id: 110208,
    districtTh: "บางบ่อ",
    districtEn: "Bang Bo",
    subdistrictTh: "คลองนิยมยาตรา",
    subdistrictEn: "Khlong Niyom Yattra",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10560,
    district_code: 1102,
    province_code: 2,
    name: "บางบ่อ คลองนิยมยาตรา สมุทรปราการ 10560",
    name_th: "บางบ่อ คลองนิยมยาตรา สมุทรปราการ 10560",
    name_en: "Bang Bo, Khlong Niyom Yattra, Samut Prakan, 10560",
  },
  {
    id: 110301,
    districtTh: "บางพลี",
    districtEn: "Bang Phli",
    subdistrictTh: "บางพลีใหญ่",
    subdistrictEn: "Bang Phli Yai",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1103,
    province_code: 2,
    name: "บางพลี บางพลีใหญ่ สมุทรปราการ 10540",
    name_th: "บางพลี บางพลีใหญ่ สมุทรปราการ 10540",
    name_en: "Bang Phli, Bang Phli Yai, Samut Prakan, 10540",
  },
  {
    id: 110302,
    districtTh: "บางพลี",
    districtEn: "Bang Phli",
    subdistrictTh: "บางแก้ว",
    subdistrictEn: "Bang Kaeo",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1103,
    province_code: 2,
    name: "บางพลี บางแก้ว สมุทรปราการ 10540",
    name_th: "บางพลี บางแก้ว สมุทรปราการ 10540",
    name_en: "Bang Phli, Bang Kaeo, Samut Prakan, 10540",
  },
  {
    id: 110303,
    districtTh: "บางพลี",
    districtEn: "Bang Phli",
    subdistrictTh: "บางปลา",
    subdistrictEn: "Bang Pla",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1103,
    province_code: 2,
    name: "บางพลี บางปลา สมุทรปราการ 10540",
    name_th: "บางพลี บางปลา สมุทรปราการ 10540",
    name_en: "Bang Phli, Bang Pla, Samut Prakan, 10540",
  },
  {
    id: 110304,
    districtTh: "บางพลี",
    districtEn: "Bang Phli",
    subdistrictTh: "บางโฉลง",
    subdistrictEn: "Bang Chalong",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1103,
    province_code: 2,
    name: "บางพลี บางโฉลง สมุทรปราการ 10540",
    name_th: "บางพลี บางโฉลง สมุทรปราการ 10540",
    name_en: "Bang Phli, Bang Chalong, Samut Prakan, 10540",
  },
  {
    id: 110308,
    districtTh: "บางพลี",
    districtEn: "Bang Phli",
    subdistrictTh: "ราชาเทวะ",
    subdistrictEn: "Racha Thewa",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1103,
    province_code: 2,
    name: "บางพลี ราชาเทวะ สมุทรปราการ 10540",
    name_th: "บางพลี ราชาเทวะ สมุทรปราการ 10540",
    name_en: "Bang Phli, Racha Thewa, Samut Prakan, 10540",
  },
  {
    id: 110309,
    districtTh: "บางพลี",
    districtEn: "Bang Phli",
    subdistrictTh: "หนองปรือ",
    subdistrictEn: "Nong Prue",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1103,
    province_code: 2,
    name: "บางพลี หนองปรือ สมุทรปราการ 10540",
    name_th: "บางพลี หนองปรือ สมุทรปราการ 10540",
    name_en: "Bang Phli, Nong Prue, Samut Prakan, 10540",
  },
  {
    id: 110401,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "ตลาด",
    subdistrictEn: "Talat",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง ตลาด สมุทรปราการ 10130",
    name_th: "พระประแดง ตลาด สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Talat, Samut Prakan, 10130",
  },
  {
    id: 110402,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางพึ่ง",
    subdistrictEn: "Bang Phueng",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางพึ่ง สมุทรปราการ 10130",
    name_th: "พระประแดง บางพึ่ง สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Phueng, Samut Prakan, 10130",
  },
  {
    id: 110403,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางจาก",
    subdistrictEn: "Bang Chak",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางจาก สมุทรปราการ 10130",
    name_th: "พระประแดง บางจาก สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Chak, Samut Prakan, 10130",
  },
  {
    id: 110404,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางครุ",
    subdistrictEn: "Bang Khru",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางครุ สมุทรปราการ 10130",
    name_th: "พระประแดง บางครุ สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Khru, Samut Prakan, 10130",
  },
  {
    id: 110405,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางหญ้าแพรก",
    subdistrictEn: "Bang Ya Phraek",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางหญ้าแพรก สมุทรปราการ 10130",
    name_th: "พระประแดง บางหญ้าแพรก สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Ya Phraek, Samut Prakan, 10130",
  },
  {
    id: 110406,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางหัวเสือ",
    subdistrictEn: "Bang Hua Suea",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางหัวเสือ สมุทรปราการ 10130",
    name_th: "พระประแดง บางหัวเสือ สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Hua Suea, Samut Prakan, 10130",
  },
  {
    id: 110407,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "สำโรงใต้",
    subdistrictEn: "Samrong Tai",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง สำโรงใต้ สมุทรปราการ 10130",
    name_th: "พระประแดง สำโรงใต้ สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Samrong Tai, Samut Prakan, 10130",
  },
  {
    id: 110408,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางยอ",
    subdistrictEn: "Bang Yo",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางยอ สมุทรปราการ 10130",
    name_th: "พระประแดง บางยอ สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Yo, Samut Prakan, 10130",
  },
  {
    id: 110409,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางกะเจ้า",
    subdistrictEn: "Bang Kachao",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางกะเจ้า สมุทรปราการ 10130",
    name_th: "พระประแดง บางกะเจ้า สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Kachao, Samut Prakan, 10130",
  },
  {
    id: 110410,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางน้ำผึ้ง",
    subdistrictEn: "Bang Namphueng",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางน้ำผึ้ง สมุทรปราการ 10130",
    name_th: "พระประแดง บางน้ำผึ้ง สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Namphueng, Samut Prakan, 10130",
  },
  {
    id: 110411,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางกระสอบ",
    subdistrictEn: "Bang Krasop",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางกระสอบ สมุทรปราการ 10130",
    name_th: "พระประแดง บางกระสอบ สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Krasop, Samut Prakan, 10130",
  },
  {
    id: 110412,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "บางกอบัว",
    subdistrictEn: "Bang Ko Bua",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง บางกอบัว สมุทรปราการ 10130",
    name_th: "พระประแดง บางกอบัว สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Bang Ko Bua, Samut Prakan, 10130",
  },
  {
    id: 110413,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "ทรงคนอง",
    subdistrictEn: "Song Khanong",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง ทรงคนอง สมุทรปราการ 10130",
    name_th: "พระประแดง ทรงคนอง สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Song Khanong, Samut Prakan, 10130",
  },
  {
    id: 110414,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง สำโรง สมุทรปราการ 10130",
    name_th: "พระประแดง สำโรง สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Samrong, Samut Prakan, 10130",
  },
  {
    id: 110415,
    districtTh: "พระประแดง",
    districtEn: "Phra Pradaeng",
    subdistrictTh: "สำโรงกลาง",
    subdistrictEn: "Samrong Klang",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10130,
    district_code: 1104,
    province_code: 2,
    name: "พระประแดง สำโรงกลาง สมุทรปราการ 10130",
    name_th: "พระประแดง สำโรงกลาง สมุทรปราการ 10130",
    name_en: "Phra Pradaeng, Samrong Klang, Samut Prakan, 10130",
  },
  {
    id: 110501,
    districtTh: "พระสมุทรเจดีย์",
    districtEn: "Phra Samut Chedi",
    subdistrictTh: "นาเกลือ",
    subdistrictEn: "Na Kluea",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10290,
    district_code: 1105,
    province_code: 2,
    name: "พระสมุทรเจดีย์ นาเกลือ สมุทรปราการ 10290",
    name_th: "พระสมุทรเจดีย์ นาเกลือ สมุทรปราการ 10290",
    name_en: "Phra Samut Chedi, Na Kluea, Samut Prakan, 10290",
  },
  {
    id: 110502,
    districtTh: "พระสมุทรเจดีย์",
    districtEn: "Phra Samut Chedi",
    subdistrictTh: "บ้านคลองสวน",
    subdistrictEn: "Ban Khlong Suan",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10290,
    district_code: 1105,
    province_code: 2,
    name: "พระสมุทรเจดีย์ บ้านคลองสวน สมุทรปราการ 10290",
    name_th: "พระสมุทรเจดีย์ บ้านคลองสวน สมุทรปราการ 10290",
    name_en: "Phra Samut Chedi, Ban Khlong Suan, Samut Prakan, 10290",
  },
  {
    id: 110503,
    districtTh: "พระสมุทรเจดีย์",
    districtEn: "Phra Samut Chedi",
    subdistrictTh: "แหลมฟ้าผ่า",
    subdistrictEn: "Laem Fa Pha",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10290,
    district_code: 1105,
    province_code: 2,
    name: "พระสมุทรเจดีย์ แหลมฟ้าผ่า สมุทรปราการ 10290",
    name_th: "พระสมุทรเจดีย์ แหลมฟ้าผ่า สมุทรปราการ 10290",
    name_en: "Phra Samut Chedi, Laem Fa Pha, Samut Prakan, 10290",
  },
  {
    id: 110504,
    districtTh: "พระสมุทรเจดีย์",
    districtEn: "Phra Samut Chedi",
    subdistrictTh: "ปากคลองบางปลากด",
    subdistrictEn: "Pak Klong Bang Pla Kot",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10290,
    district_code: 1105,
    province_code: 2,
    name: "พระสมุทรเจดีย์ ปากคลองบางปลากด สมุทรปราการ 10290",
    name_th: "พระสมุทรเจดีย์ ปากคลองบางปลากด สมุทรปราการ 10290",
    name_en: "Phra Samut Chedi, Pak Klong Bang Pla Kot, Samut Prakan, 10290",
  },
  {
    id: 110505,
    districtTh: "พระสมุทรเจดีย์",
    districtEn: "Phra Samut Chedi",
    subdistrictTh: "ในคลองบางปลากด",
    subdistrictEn: "Nai Khlong Bang Pla Kot",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10290,
    district_code: 1105,
    province_code: 2,
    name: "พระสมุทรเจดีย์ ในคลองบางปลากด สมุทรปราการ 10290",
    name_th: "พระสมุทรเจดีย์ ในคลองบางปลากด สมุทรปราการ 10290",
    name_en: "Phra Samut Chedi, Nai Khlong Bang Pla Kot, Samut Prakan, 10290",
  },
  {
    id: 110601,
    districtTh: "บางเสาธง",
    districtEn: "Bang Sao Thong",
    subdistrictTh: "บางเสาธง",
    subdistrictEn: "Bang Sao Thong",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1106,
    province_code: 2,
    name: "บางเสาธง บางเสาธง สมุทรปราการ 10540",
    name_th: "บางเสาธง บางเสาธง สมุทรปราการ 10540",
    name_en: "Bang Sao Thong, Bang Sao Thong, Samut Prakan, 10540",
  },
  {
    id: 110602,
    districtTh: "บางเสาธง",
    districtEn: "Bang Sao Thong",
    subdistrictTh: "ศีรษะจรเข้น้อย",
    subdistrictEn: "Sisa Chorakhe Noi",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1106,
    province_code: 2,
    name: "บางเสาธง ศีรษะจรเข้น้อย สมุทรปราการ 10540",
    name_th: "บางเสาธง ศีรษะจรเข้น้อย สมุทรปราการ 10540",
    name_en: "Bang Sao Thong, Sisa Chorakhe Noi, Samut Prakan, 10540",
  },
  {
    id: 110603,
    districtTh: "บางเสาธง",
    districtEn: "Bang Sao Thong",
    subdistrictTh: "ศีรษะจรเข้ใหญ่",
    subdistrictEn: "Sisa Chorakhe Yai",
    provinceTh: "สมุทรปราการ",
    provinceEn: "Samut Prakan",
    zipcode: 10540,
    district_code: 1106,
    province_code: 2,
    name: "บางเสาธง ศีรษะจรเข้ใหญ่ สมุทรปราการ 10540",
    name_th: "บางเสาธง ศีรษะจรเข้ใหญ่ สมุทรปราการ 10540",
    name_en: "Bang Sao Thong, Sisa Chorakhe Yai, Samut Prakan, 10540",
  },
  {
    id: 120101,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "สวนใหญ่",
    subdistrictEn: "Suan Yai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี สวนใหญ่ นนทบุรี 11000",
    name_th: "เมืองนนทบุรี สวนใหญ่ นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Suan Yai, Nonthaburi, 11000",
  },
  {
    id: 120102,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "ตลาดขวัญ",
    subdistrictEn: "Talat Khwan",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี ตลาดขวัญ นนทบุรี 11000",
    name_th: "เมืองนนทบุรี ตลาดขวัญ นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Talat Khwan, Nonthaburi, 11000",
  },
  {
    id: 120103,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "บางเขน",
    subdistrictEn: "Bang Khen",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี บางเขน นนทบุรี 11000",
    name_th: "เมืองนนทบุรี บางเขน นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Bang Khen, Nonthaburi, 11000",
  },
  {
    id: 120104,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "บางกระสอ",
    subdistrictEn: "Bang Kraso",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี บางกระสอ นนทบุรี 11000",
    name_th: "เมืองนนทบุรี บางกระสอ นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Bang Kraso, Nonthaburi, 11000",
  },
  {
    id: 120105,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "ท่าทราย",
    subdistrictEn: "Tha Sai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี ท่าทราย นนทบุรี 11000",
    name_th: "เมืองนนทบุรี ท่าทราย นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Tha Sai, Nonthaburi, 11000",
  },
  {
    id: 120106,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "บางไผ่",
    subdistrictEn: "Bang Phai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี บางไผ่ นนทบุรี 11000",
    name_th: "เมืองนนทบุรี บางไผ่ นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Bang Phai, Nonthaburi, 11000",
  },
  {
    id: 120107,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "บางศรีเมือง",
    subdistrictEn: "Bang Si Mueang",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี บางศรีเมือง นนทบุรี 11000",
    name_th: "เมืองนนทบุรี บางศรีเมือง นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Bang Si Mueang, Nonthaburi, 11000",
  },
  {
    id: 120108,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "บางกร่าง",
    subdistrictEn: "Bang Krang",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี บางกร่าง นนทบุรี 11000",
    name_th: "เมืองนนทบุรี บางกร่าง นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Bang Krang, Nonthaburi, 11000",
  },
  {
    id: 120109,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "ไทรม้า",
    subdistrictEn: "Sai Ma",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี ไทรม้า นนทบุรี 11000",
    name_th: "เมืองนนทบุรี ไทรม้า นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Sai Ma, Nonthaburi, 11000",
  },
  {
    id: 120110,
    districtTh: "เมืองนนทบุรี",
    districtEn: "Mueang Nonthaburi",
    subdistrictTh: "บางรักน้อย",
    subdistrictEn: "Bang Rak Noi",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11000,
    district_code: 1201,
    province_code: 3,
    name: "เมืองนนทบุรี บางรักน้อย นนทบุรี 11000",
    name_th: "เมืองนนทบุรี บางรักน้อย นนทบุรี 11000",
    name_en: "Mueang Nonthaburi, Bang Rak Noi, Nonthaburi, 11000",
  },
  {
    id: 120201,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "วัดชลอ",
    subdistrictEn: "Wat Chalo",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย วัดชลอ นนทบุรี 11130",
    name_th: "บางกรวย วัดชลอ นนทบุรี 11130",
    name_en: "Bang Kruai, Wat Chalo, Nonthaburi, 11130",
  },
  {
    id: 120202,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "บางกรวย",
    subdistrictEn: "Bang Kruai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย บางกรวย นนทบุรี 11130",
    name_th: "บางกรวย บางกรวย นนทบุรี 11130",
    name_en: "Bang Kruai, Bang Kruai, Nonthaburi, 11130",
  },
  {
    id: 120203,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "บางสีทอง",
    subdistrictEn: "Bang Si Thong",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย บางสีทอง นนทบุรี 11130",
    name_th: "บางกรวย บางสีทอง นนทบุรี 11130",
    name_en: "Bang Kruai, Bang Si Thong, Nonthaburi, 11130",
  },
  {
    id: 120204,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "บางขนุน",
    subdistrictEn: "Bang Khanun",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย บางขนุน นนทบุรี 11130",
    name_th: "บางกรวย บางขนุน นนทบุรี 11130",
    name_en: "Bang Kruai, Bang Khanun, Nonthaburi, 11130",
  },
  {
    id: 120205,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "บางขุนกอง",
    subdistrictEn: "Bang Khun Kong",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย บางขุนกอง นนทบุรี 11130",
    name_th: "บางกรวย บางขุนกอง นนทบุรี 11130",
    name_en: "Bang Kruai, Bang Khun Kong, Nonthaburi, 11130",
  },
  {
    id: 120206,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "บางคูเวียง",
    subdistrictEn: "Bang Khu Wiang",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย บางคูเวียง นนทบุรี 11130",
    name_th: "บางกรวย บางคูเวียง นนทบุรี 11130",
    name_en: "Bang Kruai, Bang Khu Wiang, Nonthaburi, 11130",
  },
  {
    id: 120207,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "มหาสวัสดิ์",
    subdistrictEn: "Maha Sawat",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย มหาสวัสดิ์ นนทบุรี 11130",
    name_th: "บางกรวย มหาสวัสดิ์ นนทบุรี 11130",
    name_en: "Bang Kruai, Maha Sawat, Nonthaburi, 11130",
  },
  {
    id: 120208,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "ปลายบาง",
    subdistrictEn: "Plai Bang",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย ปลายบาง นนทบุรี 11130",
    name_th: "บางกรวย ปลายบาง นนทบุรี 11130",
    name_en: "Bang Kruai, Plai Bang, Nonthaburi, 11130",
  },
  {
    id: 120209,
    districtTh: "บางกรวย",
    districtEn: "Bang Kruai",
    subdistrictTh: "ศาลากลาง",
    subdistrictEn: "Sala Klang",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11130,
    district_code: 1202,
    province_code: 3,
    name: "บางกรวย ศาลากลาง นนทบุรี 11130",
    name_th: "บางกรวย ศาลากลาง นนทบุรี 11130",
    name_en: "Bang Kruai, Sala Klang, Nonthaburi, 11130",
  },
  {
    id: 120301,
    districtTh: "บางใหญ่",
    districtEn: "Bang Yai",
    subdistrictTh: "บางม่วง",
    subdistrictEn: "Bang Muang",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11140,
    district_code: 1203,
    province_code: 3,
    name: "บางใหญ่ บางม่วง นนทบุรี 11140",
    name_th: "บางใหญ่ บางม่วง นนทบุรี 11140",
    name_en: "Bang Yai, Bang Muang, Nonthaburi, 11140",
  },
  {
    id: 120302,
    districtTh: "บางใหญ่",
    districtEn: "Bang Yai",
    subdistrictTh: "บางแม่นาง",
    subdistrictEn: "Bang Mae Nang",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11140,
    district_code: 1203,
    province_code: 3,
    name: "บางใหญ่ บางแม่นาง นนทบุรี 11140",
    name_th: "บางใหญ่ บางแม่นาง นนทบุรี 11140",
    name_en: "Bang Yai, Bang Mae Nang, Nonthaburi, 11140",
  },
  {
    id: 120303,
    districtTh: "บางใหญ่",
    districtEn: "Bang Yai",
    subdistrictTh: "บางเลน",
    subdistrictEn: "Bang Len",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11140,
    district_code: 1203,
    province_code: 3,
    name: "บางใหญ่ บางเลน นนทบุรี 11140",
    name_th: "บางใหญ่ บางเลน นนทบุรี 11140",
    name_en: "Bang Yai, Bang Len, Nonthaburi, 11140",
  },
  {
    id: 120304,
    districtTh: "บางใหญ่",
    districtEn: "Bang Yai",
    subdistrictTh: "เสาธงหิน",
    subdistrictEn: "Sao Thong Hin",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11140,
    district_code: 1203,
    province_code: 3,
    name: "บางใหญ่ เสาธงหิน นนทบุรี 11140",
    name_th: "บางใหญ่ เสาธงหิน นนทบุรี 11140",
    name_en: "Bang Yai, Sao Thong Hin, Nonthaburi, 11140",
  },
  {
    id: 120305,
    districtTh: "บางใหญ่",
    districtEn: "Bang Yai",
    subdistrictTh: "บางใหญ่",
    subdistrictEn: "Bang Yai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11140,
    district_code: 1203,
    province_code: 3,
    name: "บางใหญ่ บางใหญ่ นนทบุรี 11140",
    name_th: "บางใหญ่ บางใหญ่ นนทบุรี 11140",
    name_en: "Bang Yai, Bang Yai, Nonthaburi, 11140",
  },
  {
    id: 120306,
    districtTh: "บางใหญ่",
    districtEn: "Bang Yai",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11140,
    district_code: 1203,
    province_code: 3,
    name: "บางใหญ่ บ้านใหม่ นนทบุรี 11140",
    name_th: "บางใหญ่ บ้านใหม่ นนทบุรี 11140",
    name_en: "Bang Yai, Ban Mai, Nonthaburi, 11140",
  },
  {
    id: 120401,
    districtTh: "บางบัวทอง",
    districtEn: "Bang Bua Thong",
    subdistrictTh: "โสนลอย",
    subdistrictEn: "Sano Loi",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11110,
    district_code: 1204,
    province_code: 3,
    name: "บางบัวทอง โสนลอย นนทบุรี 11110",
    name_th: "บางบัวทอง โสนลอย นนทบุรี 11110",
    name_en: "Bang Bua Thong, Sano Loi, Nonthaburi, 11110",
  },
  {
    id: 120402,
    districtTh: "บางบัวทอง",
    districtEn: "Bang Bua Thong",
    subdistrictTh: "บางบัวทอง",
    subdistrictEn: "Bang Bua Thong",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11110,
    district_code: 1204,
    province_code: 3,
    name: "บางบัวทอง บางบัวทอง นนทบุรี 11110",
    name_th: "บางบัวทอง บางบัวทอง นนทบุรี 11110",
    name_en: "Bang Bua Thong, Bang Bua Thong, Nonthaburi, 11110",
  },
  {
    id: 120403,
    districtTh: "บางบัวทอง",
    districtEn: "Bang Bua Thong",
    subdistrictTh: "บางรักใหญ่",
    subdistrictEn: "Bang Rak Yai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11110,
    district_code: 1204,
    province_code: 3,
    name: "บางบัวทอง บางรักใหญ่ นนทบุรี 11110",
    name_th: "บางบัวทอง บางรักใหญ่ นนทบุรี 11110",
    name_en: "Bang Bua Thong, Bang Rak Yai, Nonthaburi, 11110",
  },
  {
    id: 120404,
    districtTh: "บางบัวทอง",
    districtEn: "Bang Bua Thong",
    subdistrictTh: "บางคูรัด",
    subdistrictEn: "Bang Khu Rat",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11110,
    district_code: 1204,
    province_code: 3,
    name: "บางบัวทอง บางคูรัด นนทบุรี 11110",
    name_th: "บางบัวทอง บางคูรัด นนทบุรี 11110",
    name_en: "Bang Bua Thong, Bang Khu Rat, Nonthaburi, 11110",
  },
  {
    id: 120405,
    districtTh: "บางบัวทอง",
    districtEn: "Bang Bua Thong",
    subdistrictTh: "ละหาร",
    subdistrictEn: "Lahan",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11110,
    district_code: 1204,
    province_code: 3,
    name: "บางบัวทอง ละหาร นนทบุรี 11110",
    name_th: "บางบัวทอง ละหาร นนทบุรี 11110",
    name_en: "Bang Bua Thong, Lahan, Nonthaburi, 11110",
  },
  {
    id: 120406,
    districtTh: "บางบัวทอง",
    districtEn: "Bang Bua Thong",
    subdistrictTh: "ลำโพ",
    subdistrictEn: "Lam Pho",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11110,
    district_code: 1204,
    province_code: 3,
    name: "บางบัวทอง ลำโพ นนทบุรี 11110",
    name_th: "บางบัวทอง ลำโพ นนทบุรี 11110",
    name_en: "Bang Bua Thong, Lam Pho, Nonthaburi, 11110",
  },
  {
    id: 120407,
    districtTh: "บางบัวทอง",
    districtEn: "Bang Bua Thong",
    subdistrictTh: "พิมลราช",
    subdistrictEn: "Phimon Rat",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11110,
    district_code: 1204,
    province_code: 3,
    name: "บางบัวทอง พิมลราช นนทบุรี 11110",
    name_th: "บางบัวทอง พิมลราช นนทบุรี 11110",
    name_en: "Bang Bua Thong, Phimon Rat, Nonthaburi, 11110",
  },
  {
    id: 120408,
    districtTh: "บางบัวทอง",
    districtEn: "Bang Bua Thong",
    subdistrictTh: "บางรักพัฒนา",
    subdistrictEn: "Bang Rak Phatthana",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11110,
    district_code: 1204,
    province_code: 3,
    name: "บางบัวทอง บางรักพัฒนา นนทบุรี 11110",
    name_th: "บางบัวทอง บางรักพัฒนา นนทบุรี 11110",
    name_en: "Bang Bua Thong, Bang Rak Phatthana, Nonthaburi, 11110",
  },
  {
    id: 120501,
    districtTh: "ไทรน้อย",
    districtEn: "Sai Noi",
    subdistrictTh: "ไทรน้อย",
    subdistrictEn: "Sai Noi",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11150,
    district_code: 1205,
    province_code: 3,
    name: "ไทรน้อย ไทรน้อย นนทบุรี 11150",
    name_th: "ไทรน้อย ไทรน้อย นนทบุรี 11150",
    name_en: "Sai Noi, Sai Noi, Nonthaburi, 11150",
  },
  {
    id: 120502,
    districtTh: "ไทรน้อย",
    districtEn: "Sai Noi",
    subdistrictTh: "ราษฎร์นิยม",
    subdistrictEn: "Rat Niyom",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11150,
    district_code: 1205,
    province_code: 3,
    name: "ไทรน้อย ราษฎร์นิยม นนทบุรี 11150",
    name_th: "ไทรน้อย ราษฎร์นิยม นนทบุรี 11150",
    name_en: "Sai Noi, Rat Niyom, Nonthaburi, 11150",
  },
  {
    id: 120503,
    districtTh: "ไทรน้อย",
    districtEn: "Sai Noi",
    subdistrictTh: "หนองเพรางาย",
    subdistrictEn: "Nong Phrao Ngai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11150,
    district_code: 1205,
    province_code: 3,
    name: "ไทรน้อย หนองเพรางาย นนทบุรี 11150",
    name_th: "ไทรน้อย หนองเพรางาย นนทบุรี 11150",
    name_en: "Sai Noi, Nong Phrao Ngai, Nonthaburi, 11150",
  },
  {
    id: 120504,
    districtTh: "ไทรน้อย",
    districtEn: "Sai Noi",
    subdistrictTh: "ไทรใหญ่",
    subdistrictEn: "Sai Yai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11150,
    district_code: 1205,
    province_code: 3,
    name: "ไทรน้อย ไทรใหญ่ นนทบุรี 11150",
    name_th: "ไทรน้อย ไทรใหญ่ นนทบุรี 11150",
    name_en: "Sai Noi, Sai Yai, Nonthaburi, 11150",
  },
  {
    id: 120505,
    districtTh: "ไทรน้อย",
    districtEn: "Sai Noi",
    subdistrictTh: "ขุนศรี",
    subdistrictEn: "Khun Si",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11150,
    district_code: 1205,
    province_code: 3,
    name: "ไทรน้อย ขุนศรี นนทบุรี 11150",
    name_th: "ไทรน้อย ขุนศรี นนทบุรี 11150",
    name_en: "Sai Noi, Khun Si, Nonthaburi, 11150",
  },
  {
    id: 120506,
    districtTh: "ไทรน้อย",
    districtEn: "Sai Noi",
    subdistrictTh: "คลองขวาง",
    subdistrictEn: "Khlong Khwang",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11150,
    district_code: 1205,
    province_code: 3,
    name: "ไทรน้อย คลองขวาง นนทบุรี 11150",
    name_th: "ไทรน้อย คลองขวาง นนทบุรี 11150",
    name_en: "Sai Noi, Khlong Khwang, Nonthaburi, 11150",
  },
  {
    id: 120507,
    districtTh: "ไทรน้อย",
    districtEn: "Sai Noi",
    subdistrictTh: "ทวีวัฒนา",
    subdistrictEn: "Thawi Watthana",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11150,
    district_code: 1205,
    province_code: 3,
    name: "ไทรน้อย ทวีวัฒนา นนทบุรี 11150",
    name_th: "ไทรน้อย ทวีวัฒนา นนทบุรี 11150",
    name_en: "Sai Noi, Thawi Watthana, Nonthaburi, 11150",
  },
  {
    id: 120601,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "ปากเกร็ด",
    subdistrictEn: "Pak Kret",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด ปากเกร็ด นนทบุรี 11120",
    name_th: "ปากเกร็ด ปากเกร็ด นนทบุรี 11120",
    name_en: "Pak Kret, Pak Kret, Nonthaburi, 11120",
  },
  {
    id: 120602,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "บางตลาด",
    subdistrictEn: "Bang Talat",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด บางตลาด นนทบุรี 11120",
    name_th: "ปากเกร็ด บางตลาด นนทบุรี 11120",
    name_en: "Pak Kret, Bang Talat, Nonthaburi, 11120",
  },
  {
    id: 120603,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด บ้านใหม่ นนทบุรี 11120",
    name_th: "ปากเกร็ด บ้านใหม่ นนทบุรี 11120",
    name_en: "Pak Kret, Ban Mai, Nonthaburi, 11120",
  },
  {
    id: 120604,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "บางพูด",
    subdistrictEn: "Bang Phut",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด บางพูด นนทบุรี 11120",
    name_th: "ปากเกร็ด บางพูด นนทบุรี 11120",
    name_en: "Pak Kret, Bang Phut, Nonthaburi, 11120",
  },
  {
    id: 120605,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "บางตะไนย์",
    subdistrictEn: "Bang Tanai",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด บางตะไนย์ นนทบุรี 11120",
    name_th: "ปากเกร็ด บางตะไนย์ นนทบุรี 11120",
    name_en: "Pak Kret, Bang Tanai, Nonthaburi, 11120",
  },
  {
    id: 120606,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "คลองพระอุดม",
    subdistrictEn: "Khlong Phra Udom",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด คลองพระอุดม นนทบุรี 11120",
    name_th: "ปากเกร็ด คลองพระอุดม นนทบุรี 11120",
    name_en: "Pak Kret, Khlong Phra Udom, Nonthaburi, 11120",
  },
  {
    id: 120607,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "ท่าอิฐ",
    subdistrictEn: "Tha It",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด ท่าอิฐ นนทบุรี 11120",
    name_th: "ปากเกร็ด ท่าอิฐ นนทบุรี 11120",
    name_en: "Pak Kret, Tha It, Nonthaburi, 11120",
  },
  {
    id: 120608,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "เกาะเกร็ด",
    subdistrictEn: "Ko Kret",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด เกาะเกร็ด นนทบุรี 11120",
    name_th: "ปากเกร็ด เกาะเกร็ด นนทบุรี 11120",
    name_en: "Pak Kret, Ko Kret, Nonthaburi, 11120",
  },
  {
    id: 120609,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "อ้อมเกร็ด",
    subdistrictEn: "Om Kret",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด อ้อมเกร็ด นนทบุรี 11120",
    name_th: "ปากเกร็ด อ้อมเกร็ด นนทบุรี 11120",
    name_en: "Pak Kret, Om Kret, Nonthaburi, 11120",
  },
  {
    id: 120610,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "คลองข่อย",
    subdistrictEn: "Khlong Khoi",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด คลองข่อย นนทบุรี 11120",
    name_th: "ปากเกร็ด คลองข่อย นนทบุรี 11120",
    name_en: "Pak Kret, Khlong Khoi, Nonthaburi, 11120",
  },
  {
    id: 120611,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "บางพลับ",
    subdistrictEn: "Bang Phlap",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด บางพลับ นนทบุรี 11120",
    name_th: "ปากเกร็ด บางพลับ นนทบุรี 11120",
    name_en: "Pak Kret, Bang Phlap, Nonthaburi, 11120",
  },
  {
    id: 120612,
    districtTh: "ปากเกร็ด",
    districtEn: "Pak Kret",
    subdistrictTh: "คลองเกลือ",
    subdistrictEn: "Khlong Kluea",
    provinceTh: "นนทบุรี",
    provinceEn: "Nonthaburi",
    zipcode: 11120,
    district_code: 1206,
    province_code: 3,
    name: "ปากเกร็ด คลองเกลือ นนทบุรี 11120",
    name_th: "ปากเกร็ด คลองเกลือ นนทบุรี 11120",
    name_en: "Pak Kret, Khlong Kluea, Nonthaburi, 11120",
  },
  {
    id: 130101,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บางปรอก",
    subdistrictEn: "Bang Parok",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บางปรอก ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บางปรอก ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Bang Parok, Pathum Thani, 12000",
  },
  {
    id: 130102,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บ้านใหม่ ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บ้านใหม่ ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Ban Mai, Pathum Thani, 12000",
  },
  {
    id: 130103,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บ้านกลาง ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บ้านกลาง ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Ban Klang, Pathum Thani, 12000",
  },
  {
    id: 130104,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บ้านฉาง",
    subdistrictEn: "Ban Chang",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บ้านฉาง ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บ้านฉาง ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Ban Chang, Pathum Thani, 12000",
  },
  {
    id: 130105,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บ้านกระแชง",
    subdistrictEn: "Ban Krachaeng",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บ้านกระแชง ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บ้านกระแชง ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Ban Krachaeng, Pathum Thani, 12000",
  },
  {
    id: 130106,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บางขะแยง",
    subdistrictEn: "Bang Khayaeng",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บางขะแยง ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บางขะแยง ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Bang Khayaeng, Pathum Thani, 12000",
  },
  {
    id: 130107,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บางคูวัด",
    subdistrictEn: "Bang Khu Wat",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บางคูวัด ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บางคูวัด ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Bang Khu Wat, Pathum Thani, 12000",
  },
  {
    id: 130108,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บางหลวง",
    subdistrictEn: "Bang Luang",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บางหลวง ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บางหลวง ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Bang Luang, Pathum Thani, 12000",
  },
  {
    id: 130109,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บางเดื่อ",
    subdistrictEn: "Bang Duea",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บางเดื่อ ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บางเดื่อ ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Bang Duea, Pathum Thani, 12000",
  },
  {
    id: 130110,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บางพูด",
    subdistrictEn: "Bang Phut",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บางพูด ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บางพูด ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Bang Phut, Pathum Thani, 12000",
  },
  {
    id: 130111,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บางพูน",
    subdistrictEn: "Bang Phun",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บางพูน ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บางพูน ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Bang Phun, Pathum Thani, 12000",
  },
  {
    id: 130112,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "บางกะดี",
    subdistrictEn: "Bang Kadi",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี บางกะดี ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี บางกะดี ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Bang Kadi, Pathum Thani, 12000",
  },
  {
    id: 130113,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "สวนพริกไทย",
    subdistrictEn: "Suan Phrikthai",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี สวนพริกไทย ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี สวนพริกไทย ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Suan Phrikthai, Pathum Thani, 12000",
  },
  {
    id: 130114,
    districtTh: "เมืองปทุมธานี",
    districtEn: "Mueang Pathum Thani",
    subdistrictTh: "หลักหก",
    subdistrictEn: "Lak Hok",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12000,
    district_code: 1301,
    province_code: 4,
    name: "เมืองปทุมธานี หลักหก ปทุมธานี 12000",
    name_th: "เมืองปทุมธานี หลักหก ปทุมธานี 12000",
    name_en: "Mueang Pathum Thani, Lak Hok, Pathum Thani, 12000",
  },
  {
    id: 130201,
    districtTh: "คลองหลวง",
    districtEn: "Khlong Luang",
    subdistrictTh: "คลองหนึ่ง",
    subdistrictEn: "Khlong Nueng",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12120,
    district_code: 1302,
    province_code: 4,
    name: "คลองหลวง คลองหนึ่ง ปทุมธานี 12120",
    name_th: "คลองหลวง คลองหนึ่ง ปทุมธานี 12120",
    name_en: "Khlong Luang, Khlong Nueng, Pathum Thani, 12120",
  },
  {
    id: 130202,
    districtTh: "คลองหลวง",
    districtEn: "Khlong Luang",
    subdistrictTh: "คลองสอง",
    subdistrictEn: "Khlong Song",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12120,
    district_code: 1302,
    province_code: 4,
    name: "คลองหลวง คลองสอง ปทุมธานี 12120",
    name_th: "คลองหลวง คลองสอง ปทุมธานี 12120",
    name_en: "Khlong Luang, Khlong Song, Pathum Thani, 12120",
  },
  {
    id: 130203,
    districtTh: "คลองหลวง",
    districtEn: "Khlong Luang",
    subdistrictTh: "คลองสาม",
    subdistrictEn: "Khlong Sam",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12120,
    district_code: 1302,
    province_code: 4,
    name: "คลองหลวง คลองสาม ปทุมธานี 12120",
    name_th: "คลองหลวง คลองสาม ปทุมธานี 12120",
    name_en: "Khlong Luang, Khlong Sam, Pathum Thani, 12120",
  },
  {
    id: 130204,
    districtTh: "คลองหลวง",
    districtEn: "Khlong Luang",
    subdistrictTh: "คลองสี่",
    subdistrictEn: "Khlong Si",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12120,
    district_code: 1302,
    province_code: 4,
    name: "คลองหลวง คลองสี่ ปทุมธานี 12120",
    name_th: "คลองหลวง คลองสี่ ปทุมธานี 12120",
    name_en: "Khlong Luang, Khlong Si, Pathum Thani, 12120",
  },
  {
    id: 130205,
    districtTh: "คลองหลวง",
    districtEn: "Khlong Luang",
    subdistrictTh: "คลองห้า",
    subdistrictEn: "Khlong Ha",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12120,
    district_code: 1302,
    province_code: 4,
    name: "คลองหลวง คลองห้า ปทุมธานี 12120",
    name_th: "คลองหลวง คลองห้า ปทุมธานี 12120",
    name_en: "Khlong Luang, Khlong Ha, Pathum Thani, 12120",
  },
  {
    id: 130206,
    districtTh: "คลองหลวง",
    districtEn: "Khlong Luang",
    subdistrictTh: "คลองหก",
    subdistrictEn: "Khlong Hok",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12120,
    district_code: 1302,
    province_code: 4,
    name: "คลองหลวง คลองหก ปทุมธานี 12120",
    name_th: "คลองหลวง คลองหก ปทุมธานี 12120",
    name_en: "Khlong Luang, Khlong Hok, Pathum Thani, 12120",
  },
  {
    id: 130207,
    districtTh: "คลองหลวง",
    districtEn: "Khlong Luang",
    subdistrictTh: "คลองเจ็ด",
    subdistrictEn: "Khlong Chet",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12120,
    district_code: 1302,
    province_code: 4,
    name: "คลองหลวง คลองเจ็ด ปทุมธานี 12120",
    name_th: "คลองหลวง คลองเจ็ด ปทุมธานี 12120",
    name_en: "Khlong Luang, Khlong Chet, Pathum Thani, 12120",
  },
  {
    id: 130301,
    districtTh: "ธัญบุรี",
    districtEn: "Thanyaburi",
    subdistrictTh: "ประชาธิปัตย์",
    subdistrictEn: "Prachathipat",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12130,
    district_code: 1303,
    province_code: 4,
    name: "ธัญบุรี ประชาธิปัตย์ ปทุมธานี 12130",
    name_th: "ธัญบุรี ประชาธิปัตย์ ปทุมธานี 12130",
    name_en: "Thanyaburi, Prachathipat, Pathum Thani, 12130",
  },
  {
    id: 130302,
    districtTh: "ธัญบุรี",
    districtEn: "Thanyaburi",
    subdistrictTh: "บึงยี่โถ",
    subdistrictEn: "Bueng Yitho",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12130,
    district_code: 1303,
    province_code: 4,
    name: "ธัญบุรี บึงยี่โถ ปทุมธานี 12130",
    name_th: "ธัญบุรี บึงยี่โถ ปทุมธานี 12130",
    name_en: "Thanyaburi, Bueng Yitho, Pathum Thani, 12130",
  },
  {
    id: 130303,
    districtTh: "ธัญบุรี",
    districtEn: "Thanyaburi",
    subdistrictTh: "รังสิต",
    subdistrictEn: "Rangsit",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12110,
    district_code: 1303,
    province_code: 4,
    name: "ธัญบุรี รังสิต ปทุมธานี 12110",
    name_th: "ธัญบุรี รังสิต ปทุมธานี 12110",
    name_en: "Thanyaburi, Rangsit, Pathum Thani, 12110",
  },
  {
    id: 130304,
    districtTh: "ธัญบุรี",
    districtEn: "Thanyaburi",
    subdistrictTh: "ลำผักกูด",
    subdistrictEn: "Lam Phak Kut",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12110,
    district_code: 1303,
    province_code: 4,
    name: "ธัญบุรี ลำผักกูด ปทุมธานี 12110",
    name_th: "ธัญบุรี ลำผักกูด ปทุมธานี 12110",
    name_en: "Thanyaburi, Lam Phak Kut, Pathum Thani, 12110",
  },
  {
    id: 130305,
    districtTh: "ธัญบุรี",
    districtEn: "Thanyaburi",
    subdistrictTh: "บึงสนั่น",
    subdistrictEn: "Bueng Sanan",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12110,
    district_code: 1303,
    province_code: 4,
    name: "ธัญบุรี บึงสนั่น ปทุมธานี 12110",
    name_th: "ธัญบุรี บึงสนั่น ปทุมธานี 12110",
    name_en: "Thanyaburi, Bueng Sanan, Pathum Thani, 12110",
  },
  {
    id: 130306,
    districtTh: "ธัญบุรี",
    districtEn: "Thanyaburi",
    subdistrictTh: "บึงน้ำรักษ์",
    subdistrictEn: "Bueng Nam Rak",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12110,
    district_code: 1303,
    province_code: 4,
    name: "ธัญบุรี บึงน้ำรักษ์ ปทุมธานี 12110",
    name_th: "ธัญบุรี บึงน้ำรักษ์ ปทุมธานี 12110",
    name_en: "Thanyaburi, Bueng Nam Rak, Pathum Thani, 12110",
  },
  {
    id: 130401,
    districtTh: "หนองเสือ",
    districtEn: "Nong Suea",
    subdistrictTh: "บึงบา",
    subdistrictEn: "Bueng Ba",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12170,
    district_code: 1304,
    province_code: 4,
    name: "หนองเสือ บึงบา ปทุมธานี 12170",
    name_th: "หนองเสือ บึงบา ปทุมธานี 12170",
    name_en: "Nong Suea, Bueng Ba, Pathum Thani, 12170",
  },
  {
    id: 130402,
    districtTh: "หนองเสือ",
    districtEn: "Nong Suea",
    subdistrictTh: "บึงบอน",
    subdistrictEn: "Bueng Bon",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12170,
    district_code: 1304,
    province_code: 4,
    name: "หนองเสือ บึงบอน ปทุมธานี 12170",
    name_th: "หนองเสือ บึงบอน ปทุมธานี 12170",
    name_en: "Nong Suea, Bueng Bon, Pathum Thani, 12170",
  },
  {
    id: 130403,
    districtTh: "หนองเสือ",
    districtEn: "Nong Suea",
    subdistrictTh: "บึงกาสาม",
    subdistrictEn: "Bueng Ka Sam",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12170,
    district_code: 1304,
    province_code: 4,
    name: "หนองเสือ บึงกาสาม ปทุมธานี 12170",
    name_th: "หนองเสือ บึงกาสาม ปทุมธานี 12170",
    name_en: "Nong Suea, Bueng Ka Sam, Pathum Thani, 12170",
  },
  {
    id: 130404,
    districtTh: "หนองเสือ",
    districtEn: "Nong Suea",
    subdistrictTh: "บึงชำอ้อ",
    subdistrictEn: "Bueng Cham O",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12170,
    district_code: 1304,
    province_code: 4,
    name: "หนองเสือ บึงชำอ้อ ปทุมธานี 12170",
    name_th: "หนองเสือ บึงชำอ้อ ปทุมธานี 12170",
    name_en: "Nong Suea, Bueng Cham O, Pathum Thani, 12170",
  },
  {
    id: 130405,
    districtTh: "หนองเสือ",
    districtEn: "Nong Suea",
    subdistrictTh: "หนองสามวัง",
    subdistrictEn: "Nong Sam Wang",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12170,
    district_code: 1304,
    province_code: 4,
    name: "หนองเสือ หนองสามวัง ปทุมธานี 12170",
    name_th: "หนองเสือ หนองสามวัง ปทุมธานี 12170",
    name_en: "Nong Suea, Nong Sam Wang, Pathum Thani, 12170",
  },
  {
    id: 130406,
    districtTh: "หนองเสือ",
    districtEn: "Nong Suea",
    subdistrictTh: "ศาลาครุ",
    subdistrictEn: "Sala Khru",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12170,
    district_code: 1304,
    province_code: 4,
    name: "หนองเสือ ศาลาครุ ปทุมธานี 12170",
    name_th: "หนองเสือ ศาลาครุ ปทุมธานี 12170",
    name_en: "Nong Suea, Sala Khru, Pathum Thani, 12170",
  },
  {
    id: 130407,
    districtTh: "หนองเสือ",
    districtEn: "Nong Suea",
    subdistrictTh: "นพรัตน์",
    subdistrictEn: "Noppharat",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12170,
    district_code: 1304,
    province_code: 4,
    name: "หนองเสือ นพรัตน์ ปทุมธานี 12170",
    name_th: "หนองเสือ นพรัตน์ ปทุมธานี 12170",
    name_en: "Nong Suea, Noppharat, Pathum Thani, 12170",
  },
  {
    id: 130501,
    districtTh: "ลาดหลุมแก้ว",
    districtEn: "Lat Lum Kaeo",
    subdistrictTh: "ระแหง",
    subdistrictEn: "Rahaeng",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12140,
    district_code: 1305,
    province_code: 4,
    name: "ลาดหลุมแก้ว ระแหง ปทุมธานี 12140",
    name_th: "ลาดหลุมแก้ว ระแหง ปทุมธานี 12140",
    name_en: "Lat Lum Kaeo, Rahaeng, Pathum Thani, 12140",
  },
  {
    id: 130502,
    districtTh: "ลาดหลุมแก้ว",
    districtEn: "Lat Lum Kaeo",
    subdistrictTh: "ลาดหลุมแก้ว",
    subdistrictEn: "Lat Lum Kaeo",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12140,
    district_code: 1305,
    province_code: 4,
    name: "ลาดหลุมแก้ว ลาดหลุมแก้ว ปทุมธานี 12140",
    name_th: "ลาดหลุมแก้ว ลาดหลุมแก้ว ปทุมธานี 12140",
    name_en: "Lat Lum Kaeo, Lat Lum Kaeo, Pathum Thani, 12140",
  },
  {
    id: 130503,
    districtTh: "ลาดหลุมแก้ว",
    districtEn: "Lat Lum Kaeo",
    subdistrictTh: "คูบางหลวง",
    subdistrictEn: "Khu Bang Luang",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12140,
    district_code: 1305,
    province_code: 4,
    name: "ลาดหลุมแก้ว คูบางหลวง ปทุมธานี 12140",
    name_th: "ลาดหลุมแก้ว คูบางหลวง ปทุมธานี 12140",
    name_en: "Lat Lum Kaeo, Khu Bang Luang, Pathum Thani, 12140",
  },
  {
    id: 130504,
    districtTh: "ลาดหลุมแก้ว",
    districtEn: "Lat Lum Kaeo",
    subdistrictTh: "คูขวาง",
    subdistrictEn: "Khu Khwang",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12140,
    district_code: 1305,
    province_code: 4,
    name: "ลาดหลุมแก้ว คูขวาง ปทุมธานี 12140",
    name_th: "ลาดหลุมแก้ว คูขวาง ปทุมธานี 12140",
    name_en: "Lat Lum Kaeo, Khu Khwang, Pathum Thani, 12140",
  },
  {
    id: 130505,
    districtTh: "ลาดหลุมแก้ว",
    districtEn: "Lat Lum Kaeo",
    subdistrictTh: "คลองพระอุดม",
    subdistrictEn: "Khlong Phra Udom",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12140,
    district_code: 1305,
    province_code: 4,
    name: "ลาดหลุมแก้ว คลองพระอุดม ปทุมธานี 12140",
    name_th: "ลาดหลุมแก้ว คลองพระอุดม ปทุมธานี 12140",
    name_en: "Lat Lum Kaeo, Khlong Phra Udom, Pathum Thani, 12140",
  },
  {
    id: 130506,
    districtTh: "ลาดหลุมแก้ว",
    districtEn: "Lat Lum Kaeo",
    subdistrictTh: "บ่อเงิน",
    subdistrictEn: "Bo Ngoen",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12140,
    district_code: 1305,
    province_code: 4,
    name: "ลาดหลุมแก้ว บ่อเงิน ปทุมธานี 12140",
    name_th: "ลาดหลุมแก้ว บ่อเงิน ปทุมธานี 12140",
    name_en: "Lat Lum Kaeo, Bo Ngoen, Pathum Thani, 12140",
  },
  {
    id: 130507,
    districtTh: "ลาดหลุมแก้ว",
    districtEn: "Lat Lum Kaeo",
    subdistrictTh: "หน้าไม้",
    subdistrictEn: "Na Mai",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12140,
    district_code: 1305,
    province_code: 4,
    name: "ลาดหลุมแก้ว หน้าไม้ ปทุมธานี 12140",
    name_th: "ลาดหลุมแก้ว หน้าไม้ ปทุมธานี 12140",
    name_en: "Lat Lum Kaeo, Na Mai, Pathum Thani, 12140",
  },
  {
    id: 130601,
    districtTh: "ลำลูกกา",
    districtEn: "Lam Luk Ka",
    subdistrictTh: "คูคต",
    subdistrictEn: "Khu Khot",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12130,
    district_code: 1306,
    province_code: 4,
    name: "ลำลูกกา คูคต ปทุมธานี 12130",
    name_th: "ลำลูกกา คูคต ปทุมธานี 12130",
    name_en: "Lam Luk Ka, Khu Khot, Pathum Thani, 12130",
  },
  {
    id: 130602,
    districtTh: "ลำลูกกา",
    districtEn: "Lam Luk Ka",
    subdistrictTh: "ลาดสวาย",
    subdistrictEn: "Lat Sawai",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12150,
    district_code: 1306,
    province_code: 4,
    name: "ลำลูกกา ลาดสวาย ปทุมธานี 12150",
    name_th: "ลำลูกกา ลาดสวาย ปทุมธานี 12150",
    name_en: "Lam Luk Ka, Lat Sawai, Pathum Thani, 12150",
  },
  {
    id: 130603,
    districtTh: "ลำลูกกา",
    districtEn: "Lam Luk Ka",
    subdistrictTh: "บึงคำพร้อย",
    subdistrictEn: "Bueng Kham Phroi",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12150,
    district_code: 1306,
    province_code: 4,
    name: "ลำลูกกา บึงคำพร้อย ปทุมธานี 12150",
    name_th: "ลำลูกกา บึงคำพร้อย ปทุมธานี 12150",
    name_en: "Lam Luk Ka, Bueng Kham Phroi, Pathum Thani, 12150",
  },
  {
    id: 130604,
    districtTh: "ลำลูกกา",
    districtEn: "Lam Luk Ka",
    subdistrictTh: "ลำลูกกา",
    subdistrictEn: "Lam Luk Ka",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12150,
    district_code: 1306,
    province_code: 4,
    name: "ลำลูกกา ลำลูกกา ปทุมธานี 12150",
    name_th: "ลำลูกกา ลำลูกกา ปทุมธานี 12150",
    name_en: "Lam Luk Ka, Lam Luk Ka, Pathum Thani, 12150",
  },
  {
    id: 130605,
    districtTh: "ลำลูกกา",
    districtEn: "Lam Luk Ka",
    subdistrictTh: "บึงทองหลาง",
    subdistrictEn: "Bueng Thonglang",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12150,
    district_code: 1306,
    province_code: 4,
    name: "ลำลูกกา บึงทองหลาง ปทุมธานี 12150",
    name_th: "ลำลูกกา บึงทองหลาง ปทุมธานี 12150",
    name_en: "Lam Luk Ka, Bueng Thonglang, Pathum Thani, 12150",
  },
  {
    id: 130606,
    districtTh: "ลำลูกกา",
    districtEn: "Lam Luk Ka",
    subdistrictTh: "ลำไทร",
    subdistrictEn: "Lam Sai",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12150,
    district_code: 1306,
    province_code: 4,
    name: "ลำลูกกา ลำไทร ปทุมธานี 12150",
    name_th: "ลำลูกกา ลำไทร ปทุมธานี 12150",
    name_en: "Lam Luk Ka, Lam Sai, Pathum Thani, 12150",
  },
  {
    id: 130607,
    districtTh: "ลำลูกกา",
    districtEn: "Lam Luk Ka",
    subdistrictTh: "บึงคอไห",
    subdistrictEn: "Bueng Kho Hai",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12150,
    district_code: 1306,
    province_code: 4,
    name: "ลำลูกกา บึงคอไห ปทุมธานี 12150",
    name_th: "ลำลูกกา บึงคอไห ปทุมธานี 12150",
    name_en: "Lam Luk Ka, Bueng Kho Hai, Pathum Thani, 12150",
  },
  {
    id: 130608,
    districtTh: "ลำลูกกา",
    districtEn: "Lam Luk Ka",
    subdistrictTh: "พืชอุดม",
    subdistrictEn: "Phuet Udom",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12150,
    district_code: 1306,
    province_code: 4,
    name: "ลำลูกกา พืชอุดม ปทุมธานี 12150",
    name_th: "ลำลูกกา พืชอุดม ปทุมธานี 12150",
    name_en: "Lam Luk Ka, Phuet Udom, Pathum Thani, 12150",
  },
  {
    id: 130701,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "บางเตย",
    subdistrictEn: "Bang Toei",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก บางเตย ปทุมธานี 12160",
    name_th: "สามโคก บางเตย ปทุมธานี 12160",
    name_en: "Sam Khok, Bang Toei, Pathum Thani, 12160",
  },
  {
    id: 130702,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "คลองควาย",
    subdistrictEn: "Khlong Khwai",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก คลองควาย ปทุมธานี 12160",
    name_th: "สามโคก คลองควาย ปทุมธานี 12160",
    name_en: "Sam Khok, Khlong Khwai, Pathum Thani, 12160",
  },
  {
    id: 130703,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "สามโคก",
    subdistrictEn: "Sam Khok",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก สามโคก ปทุมธานี 12160",
    name_th: "สามโคก สามโคก ปทุมธานี 12160",
    name_en: "Sam Khok, Sam Khok, Pathum Thani, 12160",
  },
  {
    id: 130704,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "กระแชง",
    subdistrictEn: "Krachaeng",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก กระแชง ปทุมธานี 12160",
    name_th: "สามโคก กระแชง ปทุมธานี 12160",
    name_en: "Sam Khok, Krachaeng, Pathum Thani, 12160",
  },
  {
    id: 130705,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "บางโพธิ์เหนือ",
    subdistrictEn: "Bang Pho Nuea",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก บางโพธิ์เหนือ ปทุมธานี 12160",
    name_th: "สามโคก บางโพธิ์เหนือ ปทุมธานี 12160",
    name_en: "Sam Khok, Bang Pho Nuea, Pathum Thani, 12160",
  },
  {
    id: 130706,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "เชียงรากใหญ่",
    subdistrictEn: "Chiang Rak Yai",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก เชียงรากใหญ่ ปทุมธานี 12160",
    name_th: "สามโคก เชียงรากใหญ่ ปทุมธานี 12160",
    name_en: "Sam Khok, Chiang Rak Yai, Pathum Thani, 12160",
  },
  {
    id: 130707,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "บ้านปทุม",
    subdistrictEn: "Ban Pathum",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก บ้านปทุม ปทุมธานี 12160",
    name_th: "สามโคก บ้านปทุม ปทุมธานี 12160",
    name_en: "Sam Khok, Ban Pathum, Pathum Thani, 12160",
  },
  {
    id: 130708,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "บ้านงิ้ว",
    subdistrictEn: "Ban Ngio",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก บ้านงิ้ว ปทุมธานี 12160",
    name_th: "สามโคก บ้านงิ้ว ปทุมธานี 12160",
    name_en: "Sam Khok, Ban Ngio, Pathum Thani, 12160",
  },
  {
    id: 130709,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "เชียงรากน้อย",
    subdistrictEn: "Chiang Rak Noi",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก เชียงรากน้อย ปทุมธานี 12160",
    name_th: "สามโคก เชียงรากน้อย ปทุมธานี 12160",
    name_en: "Sam Khok, Chiang Rak Noi, Pathum Thani, 12160",
  },
  {
    id: 130710,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "บางกระบือ",
    subdistrictEn: "Bang Krabue",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก บางกระบือ ปทุมธานี 12160",
    name_th: "สามโคก บางกระบือ ปทุมธานี 12160",
    name_en: "Sam Khok, Bang Krabue, Pathum Thani, 12160",
  },
  {
    id: 130711,
    districtTh: "สามโคก",
    districtEn: "Sam Khok",
    subdistrictTh: "ท้ายเกาะ",
    subdistrictEn: "Thai Ko",
    provinceTh: "ปทุมธานี",
    provinceEn: "Pathum Thani",
    zipcode: 12160,
    district_code: 1307,
    province_code: 4,
    name: "สามโคก ท้ายเกาะ ปทุมธานี 12160",
    name_th: "สามโคก ท้ายเกาะ ปทุมธานี 12160",
    name_en: "Sam Khok, Thai Ko, Pathum Thani, 12160",
  },
  {
    id: 140101,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "ประตูชัย",
    subdistrictEn: "Pratu Chai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา ประตูชัย พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา ประตูชัย พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Pratu Chai, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140102,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "กะมัง",
    subdistrictEn: "Kamang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา กะมัง พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา กะมัง พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Kamang, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140103,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "หอรัตนไชย",
    subdistrictEn: "Ho Rattanachai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา หอรัตนไชย พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา หอรัตนไชย พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Ho Rattanachai, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140104,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "หัวรอ",
    subdistrictEn: "Hua Ro",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา หัวรอ พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา หัวรอ พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Hua Ro, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140105,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "ท่าวาสุกรี",
    subdistrictEn: "Tha Wasukri",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา ท่าวาสุกรี พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา ท่าวาสุกรี พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Tha Wasukri, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140106,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "ไผ่ลิง",
    subdistrictEn: "Phai Ling",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา ไผ่ลิง พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา ไผ่ลิง พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Phai Ling, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140107,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "ปากกราน",
    subdistrictEn: "Pak Kran",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา ปากกราน พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา ปากกราน พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Pak Kran, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140108,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "ภูเขาทอง",
    subdistrictEn: "Phukhao Thong",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา ภูเขาทอง พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา ภูเขาทอง พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Phukhao Thong, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140109,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "สำเภาล่ม",
    subdistrictEn: "Samphao Lom",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา สำเภาล่ม พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา สำเภาล่ม พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Samphao Lom, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140110,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "สวนพริก",
    subdistrictEn: "Suan Phrik",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา สวนพริก พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา สวนพริก พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Suan Phrik, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140111,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "คลองตะเคียน",
    subdistrictEn: "Khlong Takhian",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา คลองตะเคียน พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา คลองตะเคียน พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Khlong Takhian, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140112,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "วัดตูม",
    subdistrictEn: "Wat Tum",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา วัดตูม พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา วัดตูม พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Wat Tum, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140113,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "หันตรา",
    subdistrictEn: "Hantra",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา หันตรา พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา หันตรา พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Hantra, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140114,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "ลุมพลี",
    subdistrictEn: "Lumphli",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา ลุมพลี พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา ลุมพลี พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Lumphli, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140115,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา บ้านใหม่ พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา บ้านใหม่ พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Ban Mai, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140116,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "บ้านเกาะ",
    subdistrictEn: "Ban Ko",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา บ้านเกาะ พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา บ้านเกาะ พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Ban Ko, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140117,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "คลองสวนพลู",
    subdistrictEn: "Khlong Suan Phlu",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา คลองสวนพลู พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา คลองสวนพลู พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Khlong Suan Phlu, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140118,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "คลองสระบัว",
    subdistrictEn: "Khlong Sa Bua",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา คลองสระบัว พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา คลองสระบัว พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Khlong Sa Bua, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140119,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "เกาะเรียน",
    subdistrictEn: "Ko Rian",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา เกาะเรียน พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา เกาะเรียน พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Ko Rian, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140120,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "บ้านป้อม",
    subdistrictEn: "Ban Pom",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา บ้านป้อม พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา บ้านป้อม พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Ban Pom, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140121,
    districtTh: "พระนครศรีอยุธยา",
    districtEn: "Phra Nakhon Si Ayutthaya",
    subdistrictTh: "บ้านรุน",
    subdistrictEn: "Ban Run",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13000,
    district_code: 1401,
    province_code: 5,
    name: "พระนครศรีอยุธยา บ้านรุน พระนครศรีอยุธยา 13000",
    name_th: "พระนครศรีอยุธยา บ้านรุน พระนครศรีอยุธยา 13000",
    name_en:
      "Phra Nakhon Si Ayutthaya, Ban Run, Phra Nakhon Si Ayutthaya, 13000",
  },
  {
    id: 140201,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "ท่าเรือ",
    subdistrictEn: "Tha Ruea",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ ท่าเรือ พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ ท่าเรือ พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Tha Ruea, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140202,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "จำปา",
    subdistrictEn: "Champa",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ จำปา พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ จำปา พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Champa, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140203,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "ท่าหลวง",
    subdistrictEn: "Tha Luang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ ท่าหลวง พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ ท่าหลวง พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Tha Luang, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140204,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "บ้านร่อม",
    subdistrictEn: "Ban Rom",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ บ้านร่อม พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ บ้านร่อม พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Ban Rom, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140205,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "ศาลาลอย",
    subdistrictEn: "Sala Loi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ ศาลาลอย พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ ศาลาลอย พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Sala Loi, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140206,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "วังแดง",
    subdistrictEn: "Wang Daeng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ วังแดง พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ วังแดง พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Wang Daeng, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140207,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "โพธิ์เอน",
    subdistrictEn: "Pho En",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ โพธิ์เอน พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ โพธิ์เอน พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Pho En, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140208,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "ปากท่า",
    subdistrictEn: "Pak Tha",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ ปากท่า พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ ปากท่า พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Pak Tha, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140209,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "หนองขนาก",
    subdistrictEn: "Nong Khanak",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ หนองขนาก พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ หนองขนาก พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Nong Khanak, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140210,
    districtTh: "ท่าเรือ",
    districtEn: "Tha Ruea",
    subdistrictTh: "ท่าเจ้าสนุก",
    subdistrictEn: "Tha Chao Sanuk",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13130,
    district_code: 1402,
    province_code: 5,
    name: "ท่าเรือ ท่าเจ้าสนุก พระนครศรีอยุธยา 13130",
    name_th: "ท่าเรือ ท่าเจ้าสนุก พระนครศรีอยุธยา 13130",
    name_en: "Tha Ruea, Tha Chao Sanuk, Phra Nakhon Si Ayutthaya, 13130",
  },
  {
    id: 140301,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "นครหลวง",
    subdistrictEn: "Nakhon Luang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง นครหลวง พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง นครหลวง พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Nakhon Luang, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140302,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง ท่าช้าง พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง ท่าช้าง พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Tha Chang, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140303,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "บ่อโพง",
    subdistrictEn: "Bo Phong",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง บ่อโพง พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง บ่อโพง พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Bo Phong, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140304,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "บ้านชุ้ง",
    subdistrictEn: "Ban Chung",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง บ้านชุ้ง พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง บ้านชุ้ง พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Ban Chung, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140305,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "ปากจั่น",
    subdistrictEn: "Pak Chan",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง ปากจั่น พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง ปากจั่น พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Pak Chan, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140306,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "บางระกำ",
    subdistrictEn: "Bang Rakam",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง บางระกำ พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง บางระกำ พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Bang Rakam, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140307,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "บางพระครู",
    subdistrictEn: "Bang Phra Khru",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง บางพระครู พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง บางพระครู พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Bang Phra Khru, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140308,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "แม่ลา",
    subdistrictEn: "Mae La",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง แม่ลา พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง แม่ลา พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Mae La, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140309,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "หนองปลิง",
    subdistrictEn: "Nong Pling",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง หนองปลิง พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง หนองปลิง พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Nong Pling, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140310,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "คลองสะแก",
    subdistrictEn: "Khlong Sakae",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง คลองสะแก พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง คลองสะแก พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Khlong Sakae, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140311,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "สามไถ",
    subdistrictEn: "Sam Thai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง สามไถ พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง สามไถ พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Sam Thai, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140312,
    districtTh: "นครหลวง",
    districtEn: "Nakhon Luang",
    subdistrictTh: "พระนอน",
    subdistrictEn: "Phra Non",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13260,
    district_code: 1403,
    province_code: 5,
    name: "นครหลวง พระนอน พระนครศรีอยุธยา 13260",
    name_th: "นครหลวง พระนอน พระนครศรีอยุธยา 13260",
    name_en: "Nakhon Luang, Phra Non, Phra Nakhon Si Ayutthaya, 13260",
  },
  {
    id: 140401,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "บางไทร",
    subdistrictEn: "Bang Sai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร บางไทร พระนครศรีอยุธยา 13190",
    name_th: "บางไทร บางไทร พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Bang Sai, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140402,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "บางพลี",
    subdistrictEn: "Bang Phli",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร บางพลี พระนครศรีอยุธยา 13190",
    name_th: "บางไทร บางพลี พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Bang Phli, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140403,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "สนามชัย",
    subdistrictEn: "Sanam Chai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร สนามชัย พระนครศรีอยุธยา 13190",
    name_th: "บางไทร สนามชัย พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Sanam Chai, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140404,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "บ้านแป้ง",
    subdistrictEn: "Ban Paeng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร บ้านแป้ง พระนครศรีอยุธยา 13190",
    name_th: "บางไทร บ้านแป้ง พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Ban Paeng, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140405,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "หน้าไม้",
    subdistrictEn: "Na Mai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร หน้าไม้ พระนครศรีอยุธยา 13190",
    name_th: "บางไทร หน้าไม้ พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Na Mai, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140406,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "บางยี่โท",
    subdistrictEn: "Bang Yi Tho",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร บางยี่โท พระนครศรีอยุธยา 13190",
    name_th: "บางไทร บางยี่โท พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Bang Yi Tho, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140407,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "แคออก",
    subdistrictEn: "Khae Ok",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร แคออก พระนครศรีอยุธยา 13190",
    name_th: "บางไทร แคออก พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Khae Ok, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140408,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "แคตก",
    subdistrictEn: "Khae Tok",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร แคตก พระนครศรีอยุธยา 13190",
    name_th: "บางไทร แคตก พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Khae Tok, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140409,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "ช่างเหล็ก",
    subdistrictEn: "Chang Lek",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร ช่างเหล็ก พระนครศรีอยุธยา 13190",
    name_th: "บางไทร ช่างเหล็ก พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Chang Lek, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140410,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "กระแชง",
    subdistrictEn: "Krachaeng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร กระแชง พระนครศรีอยุธยา 13190",
    name_th: "บางไทร กระแชง พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Krachaeng, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140411,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "บ้านกลึง",
    subdistrictEn: "Ban Klueng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร บ้านกลึง พระนครศรีอยุธยา 13190",
    name_th: "บางไทร บ้านกลึง พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Ban Klueng, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140412,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "ช้างน้อย",
    subdistrictEn: "Chang Noi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร ช้างน้อย พระนครศรีอยุธยา 13190",
    name_th: "บางไทร ช้างน้อย พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Chang Noi, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140413,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "ห่อหมก",
    subdistrictEn: "Homok",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร ห่อหมก พระนครศรีอยุธยา 13190",
    name_th: "บางไทร ห่อหมก พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Homok, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140414,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "ไผ่พระ",
    subdistrictEn: "Phai Phra",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร ไผ่พระ พระนครศรีอยุธยา 13190",
    name_th: "บางไทร ไผ่พระ พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Phai Phra, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140415,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "กกแก้วบูรพา",
    subdistrictEn: "Kok Kaeo Burapha",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร กกแก้วบูรพา พระนครศรีอยุธยา 13190",
    name_th: "บางไทร กกแก้วบูรพา พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Kok Kaeo Burapha, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140416,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "ไม้ตรา",
    subdistrictEn: "Mai Tra",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร ไม้ตรา พระนครศรีอยุธยา 13190",
    name_th: "บางไทร ไม้ตรา พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Mai Tra, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140417,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "บ้านม้า",
    subdistrictEn: "Ban Ma",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร บ้านม้า พระนครศรีอยุธยา 13190",
    name_th: "บางไทร บ้านม้า พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Ban Ma, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140418,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "บ้านเกาะ",
    subdistrictEn: "Ban Ko",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร บ้านเกาะ พระนครศรีอยุธยา 13190",
    name_th: "บางไทร บ้านเกาะ พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Ban Ko, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140419,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "ราชคราม",
    subdistrictEn: "Ratchakhram",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13290,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร ราชคราม พระนครศรีอยุธยา 13290",
    name_th: "บางไทร ราชคราม พระนครศรีอยุธยา 13290",
    name_en: "Bang Sai, Ratchakhram, Phra Nakhon Si Ayutthaya, 13290",
  },
  {
    id: 140420,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "ช้างใหญ่",
    subdistrictEn: "Chang Yai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13290,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร ช้างใหญ่ พระนครศรีอยุธยา 13290",
    name_th: "บางไทร ช้างใหญ่ พระนครศรีอยุธยา 13290",
    name_en: "Bang Sai, Chang Yai, Phra Nakhon Si Ayutthaya, 13290",
  },
  {
    id: 140421,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "โพแตง",
    subdistrictEn: "Pho Taeng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13290,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร โพแตง พระนครศรีอยุธยา 13290",
    name_th: "บางไทร โพแตง พระนครศรีอยุธยา 13290",
    name_en: "Bang Sai, Pho Taeng, Phra Nakhon Si Ayutthaya, 13290",
  },
  {
    id: 140422,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "เชียงรากน้อย",
    subdistrictEn: "Chiang Rak Noi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13290,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร เชียงรากน้อย พระนครศรีอยุธยา 13290",
    name_th: "บางไทร เชียงรากน้อย พระนครศรีอยุธยา 13290",
    name_en: "Bang Sai, Chiang Rak Noi, Phra Nakhon Si Ayutthaya, 13290",
  },
  {
    id: 140423,
    districtTh: "บางไทร",
    districtEn: "Bang Sai",
    subdistrictTh: "โคกช้าง",
    subdistrictEn: "Khok Chang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13190,
    district_code: 1404,
    province_code: 5,
    name: "บางไทร โคกช้าง พระนครศรีอยุธยา 13190",
    name_th: "บางไทร โคกช้าง พระนครศรีอยุธยา 13190",
    name_en: "Bang Sai, Khok Chang, Phra Nakhon Si Ayutthaya, 13190",
  },
  {
    id: 140501,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "บางบาล",
    subdistrictEn: "Bang Ban",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล บางบาล พระนครศรีอยุธยา 13250",
    name_th: "บางบาล บางบาล พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Bang Ban, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140502,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "วัดยม",
    subdistrictEn: "Wat Yom",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล วัดยม พระนครศรีอยุธยา 13250",
    name_th: "บางบาล วัดยม พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Wat Yom, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140503,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "ไทรน้อย",
    subdistrictEn: "Sai Noi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล ไทรน้อย พระนครศรีอยุธยา 13250",
    name_th: "บางบาล ไทรน้อย พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Sai Noi, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140504,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "สะพานไทย",
    subdistrictEn: "Saphan Thai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล สะพานไทย พระนครศรีอยุธยา 13250",
    name_th: "บางบาล สะพานไทย พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Saphan Thai, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140505,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "มหาพราหมณ์",
    subdistrictEn: "Maha Phram",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล มหาพราหมณ์ พระนครศรีอยุธยา 13250",
    name_th: "บางบาล มหาพราหมณ์ พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Maha Phram, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140506,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "กบเจา",
    subdistrictEn: "Kop Chao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล กบเจา พระนครศรีอยุธยา 13250",
    name_th: "บางบาล กบเจา พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Kop Chao, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140507,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "บ้านคลัง",
    subdistrictEn: "Ban Khlang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล บ้านคลัง พระนครศรีอยุธยา 13250",
    name_th: "บางบาล บ้านคลัง พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Ban Khlang, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140508,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "พระขาว",
    subdistrictEn: "Phra Khao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล พระขาว พระนครศรีอยุธยา 13250",
    name_th: "บางบาล พระขาว พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Phra Khao, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140509,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "น้ำเต้า",
    subdistrictEn: "Namtao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล น้ำเต้า พระนครศรีอยุธยา 13250",
    name_th: "บางบาล น้ำเต้า พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Namtao, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140510,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "ทางช้าง",
    subdistrictEn: "Thang Chang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล ทางช้าง พระนครศรีอยุธยา 13250",
    name_th: "บางบาล ทางช้าง พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Thang Chang, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140511,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "วัดตะกู",
    subdistrictEn: "Wat Taku",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล วัดตะกู พระนครศรีอยุธยา 13250",
    name_th: "บางบาล วัดตะกู พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Wat Taku, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140512,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "บางหลวง",
    subdistrictEn: "Bang Luang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล บางหลวง พระนครศรีอยุธยา 13250",
    name_th: "บางบาล บางหลวง พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Bang Luang, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140513,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "บางหลวงโดด",
    subdistrictEn: "Bang Luang Dot",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล บางหลวงโดด พระนครศรีอยุธยา 13250",
    name_th: "บางบาล บางหลวงโดด พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Bang Luang Dot, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140514,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "บางหัก",
    subdistrictEn: "Bang Hak",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล บางหัก พระนครศรีอยุธยา 13250",
    name_th: "บางบาล บางหัก พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Bang Hak, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140515,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "บางชะนี",
    subdistrictEn: "Bang Chani",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล บางชะนี พระนครศรีอยุธยา 13250",
    name_th: "บางบาล บางชะนี พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Bang Chani, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140516,
    districtTh: "บางบาล",
    districtEn: "Bang Ban",
    subdistrictTh: "บ้านกุ่ม",
    subdistrictEn: "Ban Kum",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13250,
    district_code: 1405,
    province_code: 5,
    name: "บางบาล บ้านกุ่ม พระนครศรีอยุธยา 13250",
    name_th: "บางบาล บ้านกุ่ม พระนครศรีอยุธยา 13250",
    name_en: "Bang Ban, Ban Kum, Phra Nakhon Si Ayutthaya, 13250",
  },
  {
    id: 140601,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บ้านเลน",
    subdistrictEn: "Ban Len",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บ้านเลน พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน บ้านเลน พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Ban Len, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140602,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "เชียงรากน้อย",
    subdistrictEn: "Chiang Rak Noi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13180,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน เชียงรากน้อย พระนครศรีอยุธยา 13180",
    name_th: "บางปะอิน เชียงรากน้อย พระนครศรีอยุธยา 13180",
    name_en: "Bang Pa-in, Chiang Rak Noi, Phra Nakhon Si Ayutthaya, 13180",
  },
  {
    id: 140603,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บ้านโพ",
    subdistrictEn: "Ban Pho",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บ้านโพ พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน บ้านโพ พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Ban Pho, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140604,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บ้านกรด",
    subdistrictEn: "Ban Krot",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บ้านกรด พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน บ้านกรด พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Ban Krot, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140605,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บางกระสั้น",
    subdistrictEn: "Bang Krasan",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บางกระสั้น พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน บางกระสั้น พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Bang Krasan, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140606,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "คลองจิก",
    subdistrictEn: "Khlong Chik",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน คลองจิก พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน คลองจิก พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Khlong Chik, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140607,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บ้านหว้า",
    subdistrictEn: "Ban Wa",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บ้านหว้า พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน บ้านหว้า พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Ban Wa, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140609,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บางประแดง",
    subdistrictEn: "Bang Pradaeng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บางประแดง พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน บางประแดง พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Bang Pradaeng, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140610,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "สามเรือน",
    subdistrictEn: "Sam Ruean",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน สามเรือน พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน สามเรือน พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Sam Ruean, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140611,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "เกาะเกิด",
    subdistrictEn: "Ko Koet",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน เกาะเกิด พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน เกาะเกิด พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Ko Koet, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140612,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บ้านพลับ",
    subdistrictEn: "Ban Phlap",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บ้านพลับ พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน บ้านพลับ พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Ban Phlap, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140613,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บ้านแป้ง",
    subdistrictEn: "Ban Paeng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บ้านแป้ง พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน บ้านแป้ง พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Ban Paeng, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140614,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "คุ้งลาน",
    subdistrictEn: "Khung Lan",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน คุ้งลาน พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน คุ้งลาน พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Khung Lan, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140615,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน ตลิ่งชัน พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน ตลิ่งชัน พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Taling Chan, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140616,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "บ้านสร้าง",
    subdistrictEn: "Ban Sang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน บ้านสร้าง พระนครศรีอยุธยา 13170",
    name_th: "บางปะอิน บ้านสร้าง พระนครศรีอยุธยา 13170",
    name_en: "Bang Pa-in, Ban Sang, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 140617,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "ตลาดเกรียบ",
    subdistrictEn: "Talat Kriap",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน ตลาดเกรียบ พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน ตลาดเกรียบ พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Talat Kriap, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140618,
    districtTh: "บางปะอิน",
    districtEn: "Bang Pa-in",
    subdistrictTh: "ขนอนหลวง",
    subdistrictEn: "Khanon Luang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13160,
    district_code: 1406,
    province_code: 5,
    name: "บางปะอิน ขนอนหลวง พระนครศรีอยุธยา 13160",
    name_th: "บางปะอิน ขนอนหลวง พระนครศรีอยุธยา 13160",
    name_en: "Bang Pa-in, Khanon Luang, Phra Nakhon Si Ayutthaya, 13160",
  },
  {
    id: 140701,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "บางปะหัน",
    subdistrictEn: "Bang Pahan",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน บางปะหัน พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน บางปะหัน พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Bang Pahan, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140702,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "ขยาย",
    subdistrictEn: "Khayai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน ขยาย พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน ขยาย พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Khayai, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140703,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "บางเดื่อ",
    subdistrictEn: "Bang Duea",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน บางเดื่อ พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน บางเดื่อ พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Bang Duea, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140704,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "เสาธง",
    subdistrictEn: "Sao Thong",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน เสาธง พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน เสาธง พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Sao Thong, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140705,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "ทางกลาง",
    subdistrictEn: "Thang Klang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน ทางกลาง พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน ทางกลาง พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Thang Klang, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140706,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "บางเพลิง",
    subdistrictEn: "Bang Phloeng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน บางเพลิง พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน บางเพลิง พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Bang Phloeng, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140707,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "หันสัง",
    subdistrictEn: "Hansang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน หันสัง พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน หันสัง พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Hansang, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140708,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "บางนางร้า",
    subdistrictEn: "Bang Nang Ra",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน บางนางร้า พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน บางนางร้า พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Bang Nang Ra, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140709,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "ตานิม",
    subdistrictEn: "Ta Nim",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน ตานิม พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน ตานิม พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Ta Nim, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140710,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "ทับน้ำ",
    subdistrictEn: "Thap Nam",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน ทับน้ำ พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน ทับน้ำ พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Thap Nam, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140711,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "บ้านม้า",
    subdistrictEn: "Ban Ma",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน บ้านม้า พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน บ้านม้า พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Ban Ma, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140712,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "ขวัญเมือง",
    subdistrictEn: "Khwan Mueang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน ขวัญเมือง พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน ขวัญเมือง พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Khwan Mueang, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140713,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "บ้านลี่",
    subdistrictEn: "Ban Li",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน บ้านลี่ พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน บ้านลี่ พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Ban Li, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140714,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "โพธิ์สามต้น",
    subdistrictEn: "Pho Sam Ton",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน โพธิ์สามต้น พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน โพธิ์สามต้น พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Pho Sam Ton, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140715,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "พุทเลา",
    subdistrictEn: "Phutlao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน พุทเลา พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน พุทเลา พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Phutlao, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140716,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "ตาลเอน",
    subdistrictEn: "Tan En",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน ตาลเอน พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน ตาลเอน พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Tan En, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140717,
    districtTh: "บางปะหัน",
    districtEn: "Bang Pahan",
    subdistrictTh: "บ้านขล้อ",
    subdistrictEn: "Ban Khlo",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13220,
    district_code: 1407,
    province_code: 5,
    name: "บางปะหัน บ้านขล้อ พระนครศรีอยุธยา 13220",
    name_th: "บางปะหัน บ้านขล้อ พระนครศรีอยุธยา 13220",
    name_en: "Bang Pahan, Ban Khlo, Phra Nakhon Si Ayutthaya, 13220",
  },
  {
    id: 140801,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "ผักไห่",
    subdistrictEn: "Phak Hai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ ผักไห่ พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ ผักไห่ พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Phak Hai, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140802,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "อมฤต",
    subdistrictEn: "Ammarit",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ อมฤต พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ อมฤต พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Ammarit, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140803,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "บ้านแค",
    subdistrictEn: "Ban Khae",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ บ้านแค พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ บ้านแค พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Ban Khae, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140804,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "ลาดน้ำเค็ม",
    subdistrictEn: "Lat Nam Khem",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ ลาดน้ำเค็ม พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ ลาดน้ำเค็ม พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Lat Nam Khem, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140805,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "ตาลาน",
    subdistrictEn: "Ta Lan",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ ตาลาน พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ ตาลาน พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Ta Lan, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140806,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "ท่าดินแดง",
    subdistrictEn: "Tha Din Daeng",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ ท่าดินแดง พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ ท่าดินแดง พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Tha Din Daeng, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140807,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "ดอนลาน",
    subdistrictEn: "Don Lan",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13280,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ ดอนลาน พระนครศรีอยุธยา 13280",
    name_th: "ผักไห่ ดอนลาน พระนครศรีอยุธยา 13280",
    name_en: "Phak Hai, Don Lan, Phra Nakhon Si Ayutthaya, 13280",
  },
  {
    id: 140808,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "นาคู",
    subdistrictEn: "Na Khu",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13280,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ นาคู พระนครศรีอยุธยา 13280",
    name_th: "ผักไห่ นาคู พระนครศรีอยุธยา 13280",
    name_en: "Phak Hai, Na Khu, Phra Nakhon Si Ayutthaya, 13280",
  },
  {
    id: 140809,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "กุฎี",
    subdistrictEn: "Kudi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ กุฎี พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ กุฎี พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Kudi, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140810,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "ลำตะเคียน",
    subdistrictEn: "Lam Takhian",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13280,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ ลำตะเคียน พระนครศรีอยุธยา 13280",
    name_th: "ผักไห่ ลำตะเคียน พระนครศรีอยุธยา 13280",
    name_en: "Phak Hai, Lam Takhian, Phra Nakhon Si Ayutthaya, 13280",
  },
  {
    id: 140811,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "โคกช้าง",
    subdistrictEn: "Khok Chang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ โคกช้าง พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ โคกช้าง พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Khok Chang, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140812,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "จักราช",
    subdistrictEn: "Chakkarat",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13280,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ จักราช พระนครศรีอยุธยา 13280",
    name_th: "ผักไห่ จักราช พระนครศรีอยุธยา 13280",
    name_en: "Phak Hai, Chakkarat, Phra Nakhon Si Ayutthaya, 13280",
  },
  {
    id: 140813,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "หนองน้ำใหญ่",
    subdistrictEn: "Nong Nam Yai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13280,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ หนองน้ำใหญ่ พระนครศรีอยุธยา 13280",
    name_th: "ผักไห่ หนองน้ำใหญ่ พระนครศรีอยุธยา 13280",
    name_en: "Phak Hai, Nong Nam Yai, Phra Nakhon Si Ayutthaya, 13280",
  },
  {
    id: 140814,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "ลาดชิด",
    subdistrictEn: "Lat Chit",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ ลาดชิด พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ ลาดชิด พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Lat Chit, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140815,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "หน้าโคก",
    subdistrictEn: "Na Khok",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ หน้าโคก พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ หน้าโคก พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Na Khok, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140816,
    districtTh: "ผักไห่",
    districtEn: "Phak Hai",
    subdistrictTh: "บ้านใหญ่",
    subdistrictEn: "Ban Yai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13120,
    district_code: 1408,
    province_code: 5,
    name: "ผักไห่ บ้านใหญ่ พระนครศรีอยุธยา 13120",
    name_th: "ผักไห่ บ้านใหญ่ พระนครศรีอยุธยา 13120",
    name_en: "Phak Hai, Ban Yai, Phra Nakhon Si Ayutthaya, 13120",
  },
  {
    id: 140901,
    districtTh: "ภาชี",
    districtEn: "Phachi",
    subdistrictTh: "ภาชี",
    subdistrictEn: "Phachi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13140,
    district_code: 1409,
    province_code: 5,
    name: "ภาชี ภาชี พระนครศรีอยุธยา 13140",
    name_th: "ภาชี ภาชี พระนครศรีอยุธยา 13140",
    name_en: "Phachi, Phachi, Phra Nakhon Si Ayutthaya, 13140",
  },
  {
    id: 140902,
    districtTh: "ภาชี",
    districtEn: "Phachi",
    subdistrictTh: "โคกม่วง",
    subdistrictEn: "Khok Muang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13140,
    district_code: 1409,
    province_code: 5,
    name: "ภาชี โคกม่วง พระนครศรีอยุธยา 13140",
    name_th: "ภาชี โคกม่วง พระนครศรีอยุธยา 13140",
    name_en: "Phachi, Khok Muang, Phra Nakhon Si Ayutthaya, 13140",
  },
  {
    id: 140903,
    districtTh: "ภาชี",
    districtEn: "Phachi",
    subdistrictTh: "ระโสม",
    subdistrictEn: "Rasom",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13140,
    district_code: 1409,
    province_code: 5,
    name: "ภาชี ระโสม พระนครศรีอยุธยา 13140",
    name_th: "ภาชี ระโสม พระนครศรีอยุธยา 13140",
    name_en: "Phachi, Rasom, Phra Nakhon Si Ayutthaya, 13140",
  },
  {
    id: 140904,
    districtTh: "ภาชี",
    districtEn: "Phachi",
    subdistrictTh: "หนองน้ำใส",
    subdistrictEn: "Nong Nam Sai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13140,
    district_code: 1409,
    province_code: 5,
    name: "ภาชี หนองน้ำใส พระนครศรีอยุธยา 13140",
    name_th: "ภาชี หนองน้ำใส พระนครศรีอยุธยา 13140",
    name_en: "Phachi, Nong Nam Sai, Phra Nakhon Si Ayutthaya, 13140",
  },
  {
    id: 140905,
    districtTh: "ภาชี",
    districtEn: "Phachi",
    subdistrictTh: "ดอนหญ้านาง",
    subdistrictEn: "Don Ya Nang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13140,
    district_code: 1409,
    province_code: 5,
    name: "ภาชี ดอนหญ้านาง พระนครศรีอยุธยา 13140",
    name_th: "ภาชี ดอนหญ้านาง พระนครศรีอยุธยา 13140",
    name_en: "Phachi, Don Ya Nang, Phra Nakhon Si Ayutthaya, 13140",
  },
  {
    id: 140906,
    districtTh: "ภาชี",
    districtEn: "Phachi",
    subdistrictTh: "ไผ่ล้อม",
    subdistrictEn: "Phai Lom",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13140,
    district_code: 1409,
    province_code: 5,
    name: "ภาชี ไผ่ล้อม พระนครศรีอยุธยา 13140",
    name_th: "ภาชี ไผ่ล้อม พระนครศรีอยุธยา 13140",
    name_en: "Phachi, Phai Lom, Phra Nakhon Si Ayutthaya, 13140",
  },
  {
    id: 140907,
    districtTh: "ภาชี",
    districtEn: "Phachi",
    subdistrictTh: "กระจิว",
    subdistrictEn: "Krachio",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13140,
    district_code: 1409,
    province_code: 5,
    name: "ภาชี กระจิว พระนครศรีอยุธยา 13140",
    name_th: "ภาชี กระจิว พระนครศรีอยุธยา 13140",
    name_en: "Phachi, Krachio, Phra Nakhon Si Ayutthaya, 13140",
  },
  {
    id: 140908,
    districtTh: "ภาชี",
    districtEn: "Phachi",
    subdistrictTh: "พระแก้ว",
    subdistrictEn: "Phra Kaeo",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13140,
    district_code: 1409,
    province_code: 5,
    name: "ภาชี พระแก้ว พระนครศรีอยุธยา 13140",
    name_th: "ภาชี พระแก้ว พระนครศรีอยุธยา 13140",
    name_en: "Phachi, Phra Kaeo, Phra Nakhon Si Ayutthaya, 13140",
  },
  {
    id: 141001,
    districtTh: "ลาดบัวหลวง",
    districtEn: "Lat Bua Luang",
    subdistrictTh: "ลาดบัวหลวง",
    subdistrictEn: "Lat Bua Luang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13230,
    district_code: 1410,
    province_code: 5,
    name: "ลาดบัวหลวง ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    name_th: "ลาดบัวหลวง ลาดบัวหลวง พระนครศรีอยุธยา 13230",
    name_en: "Lat Bua Luang, Lat Bua Luang, Phra Nakhon Si Ayutthaya, 13230",
  },
  {
    id: 141002,
    districtTh: "ลาดบัวหลวง",
    districtEn: "Lat Bua Luang",
    subdistrictTh: "หลักชัย",
    subdistrictEn: "Lak Chai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13230,
    district_code: 1410,
    province_code: 5,
    name: "ลาดบัวหลวง หลักชัย พระนครศรีอยุธยา 13230",
    name_th: "ลาดบัวหลวง หลักชัย พระนครศรีอยุธยา 13230",
    name_en: "Lat Bua Luang, Lak Chai, Phra Nakhon Si Ayutthaya, 13230",
  },
  {
    id: 141003,
    districtTh: "ลาดบัวหลวง",
    districtEn: "Lat Bua Luang",
    subdistrictTh: "สามเมือง",
    subdistrictEn: "Sam Mueang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13230,
    district_code: 1410,
    province_code: 5,
    name: "ลาดบัวหลวง สามเมือง พระนครศรีอยุธยา 13230",
    name_th: "ลาดบัวหลวง สามเมือง พระนครศรีอยุธยา 13230",
    name_en: "Lat Bua Luang, Sam Mueang, Phra Nakhon Si Ayutthaya, 13230",
  },
  {
    id: 141004,
    districtTh: "ลาดบัวหลวง",
    districtEn: "Lat Bua Luang",
    subdistrictTh: "พระยาบันลือ",
    subdistrictEn: "Phraya Banlue",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13230,
    district_code: 1410,
    province_code: 5,
    name: "ลาดบัวหลวง พระยาบันลือ พระนครศรีอยุธยา 13230",
    name_th: "ลาดบัวหลวง พระยาบันลือ พระนครศรีอยุธยา 13230",
    name_en: "Lat Bua Luang, Phraya Banlue, Phra Nakhon Si Ayutthaya, 13230",
  },
  {
    id: 141005,
    districtTh: "ลาดบัวหลวง",
    districtEn: "Lat Bua Luang",
    subdistrictTh: "สิงหนาท",
    subdistrictEn: "Singhanat",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13230,
    district_code: 1410,
    province_code: 5,
    name: "ลาดบัวหลวง สิงหนาท พระนครศรีอยุธยา 13230",
    name_th: "ลาดบัวหลวง สิงหนาท พระนครศรีอยุธยา 13230",
    name_en: "Lat Bua Luang, Singhanat, Phra Nakhon Si Ayutthaya, 13230",
  },
  {
    id: 141006,
    districtTh: "ลาดบัวหลวง",
    districtEn: "Lat Bua Luang",
    subdistrictTh: "คู้สลอด",
    subdistrictEn: "Khu Salot",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13230,
    district_code: 1410,
    province_code: 5,
    name: "ลาดบัวหลวง คู้สลอด พระนครศรีอยุธยา 13230",
    name_th: "ลาดบัวหลวง คู้สลอด พระนครศรีอยุธยา 13230",
    name_en: "Lat Bua Luang, Khu Salot, Phra Nakhon Si Ayutthaya, 13230",
  },
  {
    id: 141007,
    districtTh: "ลาดบัวหลวง",
    districtEn: "Lat Bua Luang",
    subdistrictTh: "คลองพระยาบันลือ",
    subdistrictEn: "Khlong Phraya Banlue",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13230,
    district_code: 1410,
    province_code: 5,
    name: "ลาดบัวหลวง คลองพระยาบันลือ พระนครศรีอยุธยา 13230",
    name_th: "ลาดบัวหลวง คลองพระยาบันลือ พระนครศรีอยุธยา 13230",
    name_en:
      "Lat Bua Luang, Khlong Phraya Banlue, Phra Nakhon Si Ayutthaya, 13230",
  },
  {
    id: 141101,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "ลำตาเสา",
    subdistrictEn: "Lam Ta Sao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย ลำตาเสา พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย ลำตาเสา พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Lam Ta Sao, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141102,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "บ่อตาโล่",
    subdistrictEn: "Bo Ta Lo",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย บ่อตาโล่ พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย บ่อตาโล่ พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Bo Ta Lo, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141103,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "วังน้อย",
    subdistrictEn: "Wang Noi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย วังน้อย พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย วังน้อย พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Wang Noi, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141104,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "ลำไทร",
    subdistrictEn: "Lam Sai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย ลำไทร พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย ลำไทร พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Lam Sai, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141105,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "สนับทึบ",
    subdistrictEn: "Sanap Thuep",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย สนับทึบ พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย สนับทึบ พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Sanap Thuep, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141106,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "พยอม",
    subdistrictEn: "Phayom",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย พยอม พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย พยอม พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Phayom, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141107,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "หันตะเภา",
    subdistrictEn: "Han Taphao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย หันตะเภา พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย หันตะเภา พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Han Taphao, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141108,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "วังจุฬา",
    subdistrictEn: "Wang Chula",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย วังจุฬา พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย วังจุฬา พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Wang Chula, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141109,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "ข้าวงาม",
    subdistrictEn: "Khao Ngam",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย ข้าวงาม พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย ข้าวงาม พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Khao Ngam, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141110,
    districtTh: "วังน้อย",
    districtEn: "Wang Noi",
    subdistrictTh: "ชะแมบ",
    subdistrictEn: "Chamaep",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13170,
    district_code: 1411,
    province_code: 5,
    name: "วังน้อย ชะแมบ พระนครศรีอยุธยา 13170",
    name_th: "วังน้อย ชะแมบ พระนครศรีอยุธยา 13170",
    name_en: "Wang Noi, Chamaep, Phra Nakhon Si Ayutthaya, 13170",
  },
  {
    id: 141201,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "เสนา",
    subdistrictEn: "Sena",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา เสนา พระนครศรีอยุธยา 13110",
    name_th: "เสนา เสนา พระนครศรีอยุธยา 13110",
    name_en: "Sena, Sena, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141202,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "บ้านแพน",
    subdistrictEn: "Ban Phaen",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา บ้านแพน พระนครศรีอยุธยา 13110",
    name_th: "เสนา บ้านแพน พระนครศรีอยุธยา 13110",
    name_en: "Sena, Ban Phaen, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141203,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "เจ้าเจ็ด",
    subdistrictEn: "Chao Chet",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา เจ้าเจ็ด พระนครศรีอยุธยา 13110",
    name_th: "เสนา เจ้าเจ็ด พระนครศรีอยุธยา 13110",
    name_en: "Sena, Chao Chet, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141204,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "สามกอ",
    subdistrictEn: "Sam Ko",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา สามกอ พระนครศรีอยุธยา 13110",
    name_th: "เสนา สามกอ พระนครศรีอยุธยา 13110",
    name_en: "Sena, Sam Ko, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141205,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "บางนมโค",
    subdistrictEn: "Bang Nom Kho",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา บางนมโค พระนครศรีอยุธยา 13110",
    name_th: "เสนา บางนมโค พระนครศรีอยุธยา 13110",
    name_en: "Sena, Bang Nom Kho, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141206,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "หัวเวียง",
    subdistrictEn: "Hua Wiang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา หัวเวียง พระนครศรีอยุธยา 13110",
    name_th: "เสนา หัวเวียง พระนครศรีอยุธยา 13110",
    name_en: "Sena, Hua Wiang, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141207,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "มารวิชัย",
    subdistrictEn: "Manrawichai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา มารวิชัย พระนครศรีอยุธยา 13110",
    name_th: "เสนา มารวิชัย พระนครศรีอยุธยา 13110",
    name_en: "Sena, Manrawichai, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141208,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "บ้านโพธิ์",
    subdistrictEn: "Ban Pho",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา บ้านโพธิ์ พระนครศรีอยุธยา 13110",
    name_th: "เสนา บ้านโพธิ์ พระนครศรีอยุธยา 13110",
    name_en: "Sena, Ban Pho, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141209,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "รางจรเข้",
    subdistrictEn: "Rang Chorakhe",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา รางจรเข้ พระนครศรีอยุธยา 13110",
    name_th: "เสนา รางจรเข้ พระนครศรีอยุธยา 13110",
    name_en: "Sena, Rang Chorakhe, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141210,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "บ้านกระทุ่ม",
    subdistrictEn: "Ban Krathum",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา บ้านกระทุ่ม พระนครศรีอยุธยา 13110",
    name_th: "เสนา บ้านกระทุ่ม พระนครศรีอยุธยา 13110",
    name_en: "Sena, Ban Krathum, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141211,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "บ้านแถว",
    subdistrictEn: "Ban Thaeo",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา บ้านแถว พระนครศรีอยุธยา 13110",
    name_th: "เสนา บ้านแถว พระนครศรีอยุธยา 13110",
    name_en: "Sena, Ban Thaeo, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141212,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "ชายนา",
    subdistrictEn: "Chai Na",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา ชายนา พระนครศรีอยุธยา 13110",
    name_th: "เสนา ชายนา พระนครศรีอยุธยา 13110",
    name_en: "Sena, Chai Na, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141213,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "สามตุ่ม",
    subdistrictEn: "Sam Tum",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา สามตุ่ม พระนครศรีอยุธยา 13110",
    name_th: "เสนา สามตุ่ม พระนครศรีอยุธยา 13110",
    name_en: "Sena, Sam Tum, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141214,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "ลาดงา",
    subdistrictEn: "Lat Nga",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา ลาดงา พระนครศรีอยุธยา 13110",
    name_th: "เสนา ลาดงา พระนครศรีอยุธยา 13110",
    name_en: "Sena, Lat Nga, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141215,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "ดอนทอง",
    subdistrictEn: "Don Thong",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา ดอนทอง พระนครศรีอยุธยา 13110",
    name_th: "เสนา ดอนทอง พระนครศรีอยุธยา 13110",
    name_en: "Sena, Don Thong, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141216,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "บ้านหลวง",
    subdistrictEn: "Ban Luang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา บ้านหลวง พระนครศรีอยุธยา 13110",
    name_th: "เสนา บ้านหลวง พระนครศรีอยุธยา 13110",
    name_en: "Sena, Ban Luang, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141217,
    districtTh: "เสนา",
    districtEn: "Sena",
    subdistrictTh: "เจ้าเสด็จ",
    subdistrictEn: "Chao Sadet",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13110,
    district_code: 1412,
    province_code: 5,
    name: "เสนา เจ้าเสด็จ พระนครศรีอยุธยา 13110",
    name_th: "เสนา เจ้าเสด็จ พระนครศรีอยุธยา 13110",
    name_en: "Sena, Chao Sadet, Phra Nakhon Si Ayutthaya, 13110",
  },
  {
    id: 141301,
    districtTh: "บางซ้าย",
    districtEn: "Bang Sai",
    subdistrictTh: "บางซ้าย",
    subdistrictEn: "Bang Sai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13270,
    district_code: 1413,
    province_code: 5,
    name: "บางซ้าย บางซ้าย พระนครศรีอยุธยา 13270",
    name_th: "บางซ้าย บางซ้าย พระนครศรีอยุธยา 13270",
    name_en: "Bang Sai, Bang Sai, Phra Nakhon Si Ayutthaya, 13270",
  },
  {
    id: 141302,
    districtTh: "บางซ้าย",
    districtEn: "Bang Sai",
    subdistrictTh: "แก้วฟ้า",
    subdistrictEn: "Kaeo Fa",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13270,
    district_code: 1413,
    province_code: 5,
    name: "บางซ้าย แก้วฟ้า พระนครศรีอยุธยา 13270",
    name_th: "บางซ้าย แก้วฟ้า พระนครศรีอยุธยา 13270",
    name_en: "Bang Sai, Kaeo Fa, Phra Nakhon Si Ayutthaya, 13270",
  },
  {
    id: 141303,
    districtTh: "บางซ้าย",
    districtEn: "Bang Sai",
    subdistrictTh: "เต่าเล่า",
    subdistrictEn: "Tao Lao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13270,
    district_code: 1413,
    province_code: 5,
    name: "บางซ้าย เต่าเล่า พระนครศรีอยุธยา 13270",
    name_th: "บางซ้าย เต่าเล่า พระนครศรีอยุธยา 13270",
    name_en: "Bang Sai, Tao Lao, Phra Nakhon Si Ayutthaya, 13270",
  },
  {
    id: 141304,
    districtTh: "บางซ้าย",
    districtEn: "Bang Sai",
    subdistrictTh: "ปลายกลัด",
    subdistrictEn: "Plai Klat",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13270,
    district_code: 1413,
    province_code: 5,
    name: "บางซ้าย ปลายกลัด พระนครศรีอยุธยา 13270",
    name_th: "บางซ้าย ปลายกลัด พระนครศรีอยุธยา 13270",
    name_en: "Bang Sai, Plai Klat, Phra Nakhon Si Ayutthaya, 13270",
  },
  {
    id: 141305,
    districtTh: "บางซ้าย",
    districtEn: "Bang Sai",
    subdistrictTh: "เทพมงคล",
    subdistrictEn: "Thep Mongkhon",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13270,
    district_code: 1413,
    province_code: 5,
    name: "บางซ้าย เทพมงคล พระนครศรีอยุธยา 13270",
    name_th: "บางซ้าย เทพมงคล พระนครศรีอยุธยา 13270",
    name_en: "Bang Sai, Thep Mongkhon, Phra Nakhon Si Ayutthaya, 13270",
  },
  {
    id: 141306,
    districtTh: "บางซ้าย",
    districtEn: "Bang Sai",
    subdistrictTh: "วังพัฒนา",
    subdistrictEn: "Wang Phatthana",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13270,
    district_code: 1413,
    province_code: 5,
    name: "บางซ้าย วังพัฒนา พระนครศรีอยุธยา 13270",
    name_th: "บางซ้าย วังพัฒนา พระนครศรีอยุธยา 13270",
    name_en: "Bang Sai, Wang Phatthana, Phra Nakhon Si Ayutthaya, 13270",
  },
  {
    id: 141401,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "คานหาม",
    subdistrictEn: "Khan Ham",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย คานหาม พระนครศรีอยุธยา 13210",
    name_th: "อุทัย คานหาม พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Khan Ham, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141402,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "บ้านช้าง",
    subdistrictEn: "Ban Chang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย บ้านช้าง พระนครศรีอยุธยา 13210",
    name_th: "อุทัย บ้านช้าง พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Ban Chang, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141403,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "สามบัณฑิต",
    subdistrictEn: "Sam Bandit",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย สามบัณฑิต พระนครศรีอยุธยา 13210",
    name_th: "อุทัย สามบัณฑิต พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Sam Bandit, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141404,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "บ้านหีบ",
    subdistrictEn: "Ban Hip",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย บ้านหีบ พระนครศรีอยุธยา 13210",
    name_th: "อุทัย บ้านหีบ พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Ban Hip, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141405,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "หนองไม้ซุง",
    subdistrictEn: "Nong Mai Sung",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย หนองไม้ซุง พระนครศรีอยุธยา 13210",
    name_th: "อุทัย หนองไม้ซุง พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Nong Mai Sung, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141406,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "อุทัย",
    subdistrictEn: "Uthai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย อุทัย พระนครศรีอยุธยา 13210",
    name_th: "อุทัย อุทัย พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Uthai, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141408,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "หนองน้ำส้ม",
    subdistrictEn: "Nong Nam Som",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย หนองน้ำส้ม พระนครศรีอยุธยา 13210",
    name_th: "อุทัย หนองน้ำส้ม พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Nong Nam Som, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141409,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "โพสาวหาญ",
    subdistrictEn: "Pho Sao Han",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย โพสาวหาญ พระนครศรีอยุธยา 13210",
    name_th: "อุทัย โพสาวหาญ พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Pho Sao Han, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141410,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "ธนู",
    subdistrictEn: "Thanu",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย ธนู พระนครศรีอยุธยา 13210",
    name_th: "อุทัย ธนู พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Thanu, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141411,
    districtTh: "อุทัย",
    districtEn: "Uthai",
    subdistrictTh: "ข้าวเม่า",
    subdistrictEn: "Khao Mao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13210,
    district_code: 1414,
    province_code: 5,
    name: "อุทัย ข้าวเม่า พระนครศรีอยุธยา 13210",
    name_th: "อุทัย ข้าวเม่า พระนครศรีอยุธยา 13210",
    name_en: "Uthai, Khao Mao, Phra Nakhon Si Ayutthaya, 13210",
  },
  {
    id: 141501,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "หัวไผ่",
    subdistrictEn: "Hua Phai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช หัวไผ่ พระนครศรีอยุธยา 13150",
    name_th: "มหาราช หัวไผ่ พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Hua Phai, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141502,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "กะทุ่ม",
    subdistrictEn: "Kathum",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช กะทุ่ม พระนครศรีอยุธยา 13150",
    name_th: "มหาราช กะทุ่ม พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Kathum, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141503,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "มหาราช",
    subdistrictEn: "Maha Rat",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช มหาราช พระนครศรีอยุธยา 13150",
    name_th: "มหาราช มหาราช พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Maha Rat, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141504,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "น้ำเต้า",
    subdistrictEn: "Namtao",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช น้ำเต้า พระนครศรีอยุธยา 13150",
    name_th: "มหาราช น้ำเต้า พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Namtao, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141505,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "บางนา",
    subdistrictEn: "Bang Na",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช บางนา พระนครศรีอยุธยา 13150",
    name_th: "มหาราช บางนา พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Bang Na, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141506,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "โรงช้าง",
    subdistrictEn: "Rong Chang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช โรงช้าง พระนครศรีอยุธยา 13150",
    name_th: "มหาราช โรงช้าง พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Rong Chang, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141507,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "เจ้าปลุก",
    subdistrictEn: "Chao Pluk",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช เจ้าปลุก พระนครศรีอยุธยา 13150",
    name_th: "มหาราช เจ้าปลุก พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Chao Pluk, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141508,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "พิตเพียน",
    subdistrictEn: "Phitphian",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช พิตเพียน พระนครศรีอยุธยา 13150",
    name_th: "มหาราช พิตเพียน พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Phitphian, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141509,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช บ้านนา พระนครศรีอยุธยา 13150",
    name_th: "มหาราช บ้านนา พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Ban Na, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141510,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "บ้านขวาง",
    subdistrictEn: "Ban Khwang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช บ้านขวาง พระนครศรีอยุธยา 13150",
    name_th: "มหาราช บ้านขวาง พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Ban Khwang, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141511,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "ท่าตอ",
    subdistrictEn: "Tha To",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช ท่าตอ พระนครศรีอยุธยา 13150",
    name_th: "มหาราช ท่าตอ พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Tha To, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141512,
    districtTh: "มหาราช",
    districtEn: "Maha Rat",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13150,
    district_code: 1415,
    province_code: 5,
    name: "มหาราช บ้านใหม่ พระนครศรีอยุธยา 13150",
    name_th: "มหาราช บ้านใหม่ พระนครศรีอยุธยา 13150",
    name_en: "Maha Rat, Ban Mai, Phra Nakhon Si Ayutthaya, 13150",
  },
  {
    id: 141601,
    districtTh: "บ้านแพรก",
    districtEn: "Ban Phraek",
    subdistrictTh: "บ้านแพรก",
    subdistrictEn: "Ban Phraek",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13240,
    district_code: 1416,
    province_code: 5,
    name: "บ้านแพรก บ้านแพรก พระนครศรีอยุธยา 13240",
    name_th: "บ้านแพรก บ้านแพรก พระนครศรีอยุธยา 13240",
    name_en: "Ban Phraek, Ban Phraek, Phra Nakhon Si Ayutthaya, 13240",
  },
  {
    id: 141602,
    districtTh: "บ้านแพรก",
    districtEn: "Ban Phraek",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13240,
    district_code: 1416,
    province_code: 5,
    name: "บ้านแพรก บ้านใหม่ พระนครศรีอยุธยา 13240",
    name_th: "บ้านแพรก บ้านใหม่ พระนครศรีอยุธยา 13240",
    name_en: "Ban Phraek, Ban Mai, Phra Nakhon Si Ayutthaya, 13240",
  },
  {
    id: 141603,
    districtTh: "บ้านแพรก",
    districtEn: "Ban Phraek",
    subdistrictTh: "สำพะเนียง",
    subdistrictEn: "Sam Phaniang",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13240,
    district_code: 1416,
    province_code: 5,
    name: "บ้านแพรก สำพะเนียง พระนครศรีอยุธยา 13240",
    name_th: "บ้านแพรก สำพะเนียง พระนครศรีอยุธยา 13240",
    name_en: "Ban Phraek, Sam Phaniang, Phra Nakhon Si Ayutthaya, 13240",
  },
  {
    id: 141604,
    districtTh: "บ้านแพรก",
    districtEn: "Ban Phraek",
    subdistrictTh: "คลองน้อย",
    subdistrictEn: "Khlong Noi",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13240,
    district_code: 1416,
    province_code: 5,
    name: "บ้านแพรก คลองน้อย พระนครศรีอยุธยา 13240",
    name_th: "บ้านแพรก คลองน้อย พระนครศรีอยุธยา 13240",
    name_en: "Ban Phraek, Khlong Noi, Phra Nakhon Si Ayutthaya, 13240",
  },
  {
    id: 141605,
    districtTh: "บ้านแพรก",
    districtEn: "Ban Phraek",
    subdistrictTh: "สองห้อง",
    subdistrictEn: "Song Hong",
    provinceTh: "พระนครศรีอยุธยา",
    provinceEn: "Phra Nakhon Si Ayutthaya",
    zipcode: 13240,
    district_code: 1416,
    province_code: 5,
    name: "บ้านแพรก สองห้อง พระนครศรีอยุธยา 13240",
    name_th: "บ้านแพรก สองห้อง พระนครศรีอยุธยา 13240",
    name_en: "Ban Phraek, Song Hong, Phra Nakhon Si Ayutthaya, 13240",
  },
  {
    id: 150101,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "ตลาดหลวง",
    subdistrictEn: "Talat Luang",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง ตลาดหลวง อ่างทอง 14000",
    name_th: "เมืองอ่างทอง ตลาดหลวง อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Talat Luang, Ang Thong, 14000",
  },
  {
    id: 150102,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "บางแก้ว",
    subdistrictEn: "Bang Kaeo",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง บางแก้ว อ่างทอง 14000",
    name_th: "เมืองอ่างทอง บางแก้ว อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Bang Kaeo, Ang Thong, 14000",
  },
  {
    id: 150103,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "ศาลาแดง",
    subdistrictEn: "Sala Daeng",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง ศาลาแดง อ่างทอง 14000",
    name_th: "เมืองอ่างทอง ศาลาแดง อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Sala Daeng, Ang Thong, 14000",
  },
  {
    id: 150104,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "ป่างิ้ว",
    subdistrictEn: "Pa Ngio",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง ป่างิ้ว อ่างทอง 14000",
    name_th: "เมืองอ่างทอง ป่างิ้ว อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Pa Ngio, Ang Thong, 14000",
  },
  {
    id: 150105,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "บ้านแห",
    subdistrictEn: "Ban Hae",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง บ้านแห อ่างทอง 14000",
    name_th: "เมืองอ่างทอง บ้านแห อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Ban Hae, Ang Thong, 14000",
  },
  {
    id: 150106,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "ตลาดกรวด",
    subdistrictEn: "Talat Kruat",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง ตลาดกรวด อ่างทอง 14000",
    name_th: "เมืองอ่างทอง ตลาดกรวด อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Talat Kruat, Ang Thong, 14000",
  },
  {
    id: 150107,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "มหาดไทย",
    subdistrictEn: "Mahatthai",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง มหาดไทย อ่างทอง 14000",
    name_th: "เมืองอ่างทอง มหาดไทย อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Mahatthai, Ang Thong, 14000",
  },
  {
    id: 150108,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "บ้านอิฐ",
    subdistrictEn: "Ban It",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง บ้านอิฐ อ่างทอง 14000",
    name_th: "เมืองอ่างทอง บ้านอิฐ อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Ban It, Ang Thong, 14000",
  },
  {
    id: 150109,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "หัวไผ่",
    subdistrictEn: "Hua Phai",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง หัวไผ่ อ่างทอง 14000",
    name_th: "เมืองอ่างทอง หัวไผ่ อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Hua Phai, Ang Thong, 14000",
  },
  {
    id: 150110,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "จำปาหล่อ",
    subdistrictEn: "Champa Lo",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง จำปาหล่อ อ่างทอง 14000",
    name_th: "เมืองอ่างทอง จำปาหล่อ อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Champa Lo, Ang Thong, 14000",
  },
  {
    id: 150111,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "โพสะ",
    subdistrictEn: "Phosa",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง โพสะ อ่างทอง 14000",
    name_th: "เมืองอ่างทอง โพสะ อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Phosa, Ang Thong, 14000",
  },
  {
    id: 150112,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "บ้านรี",
    subdistrictEn: "Ban Ri",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง บ้านรี อ่างทอง 14000",
    name_th: "เมืองอ่างทอง บ้านรี อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Ban Ri, Ang Thong, 14000",
  },
  {
    id: 150113,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "คลองวัว",
    subdistrictEn: "Khlong Wua",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง คลองวัว อ่างทอง 14000",
    name_th: "เมืองอ่างทอง คลองวัว อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Khlong Wua, Ang Thong, 14000",
  },
  {
    id: 150114,
    districtTh: "เมืองอ่างทอง",
    districtEn: "Mueang Ang Thong",
    subdistrictTh: "ย่านซื่อ",
    subdistrictEn: "Yan Sue",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14000,
    district_code: 1501,
    province_code: 6,
    name: "เมืองอ่างทอง ย่านซื่อ อ่างทอง 14000",
    name_th: "เมืองอ่างทอง ย่านซื่อ อ่างทอง 14000",
    name_en: "Mueang Ang Thong, Yan Sue, Ang Thong, 14000",
  },
  {
    id: 150201,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "จรเข้ร้อง",
    subdistrictEn: "Chorakhe Rong",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย จรเข้ร้อง อ่างทอง 14140",
    name_th: "ไชโย จรเข้ร้อง อ่างทอง 14140",
    name_en: "Chaiyo, Chorakhe Rong, Ang Thong, 14140",
  },
  {
    id: 150202,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "ไชยภูมิ",
    subdistrictEn: "Chaiyaphum",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย ไชยภูมิ อ่างทอง 14140",
    name_th: "ไชโย ไชยภูมิ อ่างทอง 14140",
    name_en: "Chaiyo, Chaiyaphum, Ang Thong, 14140",
  },
  {
    id: 150203,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "ชัยฤทธิ์",
    subdistrictEn: "Chaiyarit",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย ชัยฤทธิ์ อ่างทอง 14140",
    name_th: "ไชโย ชัยฤทธิ์ อ่างทอง 14140",
    name_en: "Chaiyo, Chaiyarit, Ang Thong, 14140",
  },
  {
    id: 150204,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "เทวราช",
    subdistrictEn: "Thewarat",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย เทวราช อ่างทอง 14140",
    name_th: "ไชโย เทวราช อ่างทอง 14140",
    name_en: "Chaiyo, Thewarat, Ang Thong, 14140",
  },
  {
    id: 150205,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "ราชสถิตย์",
    subdistrictEn: "Ratchasathit",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย ราชสถิตย์ อ่างทอง 14140",
    name_th: "ไชโย ราชสถิตย์ อ่างทอง 14140",
    name_en: "Chaiyo, Ratchasathit, Ang Thong, 14140",
  },
  {
    id: 150206,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "ไชโย",
    subdistrictEn: "Chaiyo",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย ไชโย อ่างทอง 14140",
    name_th: "ไชโย ไชโย อ่างทอง 14140",
    name_en: "Chaiyo, Chaiyo, Ang Thong, 14140",
  },
  {
    id: 150207,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "หลักฟ้า",
    subdistrictEn: "Lak Fa",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย หลักฟ้า อ่างทอง 14140",
    name_th: "ไชโย หลักฟ้า อ่างทอง 14140",
    name_en: "Chaiyo, Lak Fa, Ang Thong, 14140",
  },
  {
    id: 150208,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "ชะไว",
    subdistrictEn: "Chawai",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย ชะไว อ่างทอง 14140",
    name_th: "ไชโย ชะไว อ่างทอง 14140",
    name_en: "Chaiyo, Chawai, Ang Thong, 14140",
  },
  {
    id: 150209,
    districtTh: "ไชโย",
    districtEn: "Chaiyo",
    subdistrictTh: "ตรีณรงค์",
    subdistrictEn: "Tri Narong",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14140,
    district_code: 1502,
    province_code: 6,
    name: "ไชโย ตรีณรงค์ อ่างทอง 14140",
    name_th: "ไชโย ตรีณรงค์ อ่างทอง 14140",
    name_en: "Chaiyo, Tri Narong, Ang Thong, 14140",
  },
  {
    id: 150301,
    districtTh: "ป่าโมก",
    districtEn: "Pa Mok",
    subdistrictTh: "บางปลากด",
    subdistrictEn: "Bang Pla Kot",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14130,
    district_code: 1503,
    province_code: 6,
    name: "ป่าโมก บางปลากด อ่างทอง 14130",
    name_th: "ป่าโมก บางปลากด อ่างทอง 14130",
    name_en: "Pa Mok, Bang Pla Kot, Ang Thong, 14130",
  },
  {
    id: 150302,
    districtTh: "ป่าโมก",
    districtEn: "Pa Mok",
    subdistrictTh: "ป่าโมก",
    subdistrictEn: "Pa Mok",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14130,
    district_code: 1503,
    province_code: 6,
    name: "ป่าโมก ป่าโมก อ่างทอง 14130",
    name_th: "ป่าโมก ป่าโมก อ่างทอง 14130",
    name_en: "Pa Mok, Pa Mok, Ang Thong, 14130",
  },
  {
    id: 150303,
    districtTh: "ป่าโมก",
    districtEn: "Pa Mok",
    subdistrictTh: "สายทอง",
    subdistrictEn: "Sai Thong",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14130,
    district_code: 1503,
    province_code: 6,
    name: "ป่าโมก สายทอง อ่างทอง 14130",
    name_th: "ป่าโมก สายทอง อ่างทอง 14130",
    name_en: "Pa Mok, Sai Thong, Ang Thong, 14130",
  },
  {
    id: 150304,
    districtTh: "ป่าโมก",
    districtEn: "Pa Mok",
    subdistrictTh: "โรงช้าง",
    subdistrictEn: "Rong Chang",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14130,
    district_code: 1503,
    province_code: 6,
    name: "ป่าโมก โรงช้าง อ่างทอง 14130",
    name_th: "ป่าโมก โรงช้าง อ่างทอง 14130",
    name_en: "Pa Mok, Rong Chang, Ang Thong, 14130",
  },
  {
    id: 150305,
    districtTh: "ป่าโมก",
    districtEn: "Pa Mok",
    subdistrictTh: "บางเสด็จ",
    subdistrictEn: "Bang Sadet",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14130,
    district_code: 1503,
    province_code: 6,
    name: "ป่าโมก บางเสด็จ อ่างทอง 14130",
    name_th: "ป่าโมก บางเสด็จ อ่างทอง 14130",
    name_en: "Pa Mok, Bang Sadet, Ang Thong, 14130",
  },
  {
    id: 150306,
    districtTh: "ป่าโมก",
    districtEn: "Pa Mok",
    subdistrictTh: "นรสิงห์",
    subdistrictEn: "Norasing",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14130,
    district_code: 1503,
    province_code: 6,
    name: "ป่าโมก นรสิงห์ อ่างทอง 14130",
    name_th: "ป่าโมก นรสิงห์ อ่างทอง 14130",
    name_en: "Pa Mok, Norasing, Ang Thong, 14130",
  },
  {
    id: 150307,
    districtTh: "ป่าโมก",
    districtEn: "Pa Mok",
    subdistrictTh: "เอกราช",
    subdistrictEn: "Ekkarat",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14130,
    district_code: 1503,
    province_code: 6,
    name: "ป่าโมก เอกราช อ่างทอง 14130",
    name_th: "ป่าโมก เอกราช อ่างทอง 14130",
    name_en: "Pa Mok, Ekkarat, Ang Thong, 14130",
  },
  {
    id: 150308,
    districtTh: "ป่าโมก",
    districtEn: "Pa Mok",
    subdistrictTh: "โผงเผง",
    subdistrictEn: "Phong Pheng",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14130,
    district_code: 1503,
    province_code: 6,
    name: "ป่าโมก โผงเผง อ่างทอง 14130",
    name_th: "ป่าโมก โผงเผง อ่างทอง 14130",
    name_en: "Pa Mok, Phong Pheng, Ang Thong, 14130",
  },
  {
    id: 150401,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "อ่างแก้ว",
    subdistrictEn: "Ang Kaeo",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง อ่างแก้ว อ่างทอง 14120",
    name_th: "โพธิ์ทอง อ่างแก้ว อ่างทอง 14120",
    name_en: "Pho Thong, Ang Kaeo, Ang Thong, 14120",
  },
  {
    id: 150402,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "อินทประมูล",
    subdistrictEn: "Inthapramun",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง อินทประมูล อ่างทอง 14120",
    name_th: "โพธิ์ทอง อินทประมูล อ่างทอง 14120",
    name_en: "Pho Thong, Inthapramun, Ang Thong, 14120",
  },
  {
    id: 150403,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "บางพลับ",
    subdistrictEn: "Bang Phlap",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง บางพลับ อ่างทอง 14120",
    name_th: "โพธิ์ทอง บางพลับ อ่างทอง 14120",
    name_en: "Pho Thong, Bang Phlap, Ang Thong, 14120",
  },
  {
    id: 150404,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "หนองแม่ไก่",
    subdistrictEn: "Nong Mae Kai",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง หนองแม่ไก่ อ่างทอง 14120",
    name_th: "โพธิ์ทอง หนองแม่ไก่ อ่างทอง 14120",
    name_en: "Pho Thong, Nong Mae Kai, Ang Thong, 14120",
  },
  {
    id: 150405,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "รำมะสัก",
    subdistrictEn: "Ram Ma Sak",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง รำมะสัก อ่างทอง 14120",
    name_th: "โพธิ์ทอง รำมะสัก อ่างทอง 14120",
    name_en: "Pho Thong, Ram Ma Sak, Ang Thong, 14120",
  },
  {
    id: 150406,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "บางระกำ",
    subdistrictEn: "Bang Rakam",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง บางระกำ อ่างทอง 14120",
    name_th: "โพธิ์ทอง บางระกำ อ่างทอง 14120",
    name_en: "Pho Thong, Bang Rakam, Ang Thong, 14120",
  },
  {
    id: 150407,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "โพธิ์รังนก",
    subdistrictEn: "Pho Rang Nok",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง โพธิ์รังนก อ่างทอง 14120",
    name_th: "โพธิ์ทอง โพธิ์รังนก อ่างทอง 14120",
    name_en: "Pho Thong, Pho Rang Nok, Ang Thong, 14120",
  },
  {
    id: 150408,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "องครักษ์",
    subdistrictEn: "Ongkharak",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง องครักษ์ อ่างทอง 14120",
    name_th: "โพธิ์ทอง องครักษ์ อ่างทอง 14120",
    name_en: "Pho Thong, Ongkharak, Ang Thong, 14120",
  },
  {
    id: 150409,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "โคกพุทรา",
    subdistrictEn: "Khok Phutsa",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง โคกพุทรา อ่างทอง 14120",
    name_th: "โพธิ์ทอง โคกพุทรา อ่างทอง 14120",
    name_en: "Pho Thong, Khok Phutsa, Ang Thong, 14120",
  },
  {
    id: 150410,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "ยางช้าย",
    subdistrictEn: "Yang Chai",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง ยางช้าย อ่างทอง 14120",
    name_th: "โพธิ์ทอง ยางช้าย อ่างทอง 14120",
    name_en: "Pho Thong, Yang Chai, Ang Thong, 14120",
  },
  {
    id: 150411,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "บ่อแร่",
    subdistrictEn: "Bo Rae",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง บ่อแร่ อ่างทอง 14120",
    name_th: "โพธิ์ทอง บ่อแร่ อ่างทอง 14120",
    name_en: "Pho Thong, Bo Rae, Ang Thong, 14120",
  },
  {
    id: 150412,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "ทางพระ",
    subdistrictEn: "Thang Phra",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง ทางพระ อ่างทอง 14120",
    name_th: "โพธิ์ทอง ทางพระ อ่างทอง 14120",
    name_en: "Pho Thong, Thang Phra, Ang Thong, 14120",
  },
  {
    id: 150413,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "สามง่าม",
    subdistrictEn: "Sam Ngam",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง สามง่าม อ่างทอง 14120",
    name_th: "โพธิ์ทอง สามง่าม อ่างทอง 14120",
    name_en: "Pho Thong, Sam Ngam, Ang Thong, 14120",
  },
  {
    id: 150414,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "บางเจ้าฉ่า",
    subdistrictEn: "Bang Chao Cha",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง บางเจ้าฉ่า อ่างทอง 14120",
    name_th: "โพธิ์ทอง บางเจ้าฉ่า อ่างทอง 14120",
    name_en: "Pho Thong, Bang Chao Cha, Ang Thong, 14120",
  },
  {
    id: 150415,
    districtTh: "โพธิ์ทอง",
    districtEn: "Pho Thong",
    subdistrictTh: "คำหยาด",
    subdistrictEn: "Kham Yat",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14120,
    district_code: 1504,
    province_code: 6,
    name: "โพธิ์ทอง คำหยาด อ่างทอง 14120",
    name_th: "โพธิ์ทอง คำหยาด อ่างทอง 14120",
    name_en: "Pho Thong, Kham Yat, Ang Thong, 14120",
  },
  {
    id: 150501,
    districtTh: "แสวงหา",
    districtEn: "Sawaeng Ha",
    subdistrictTh: "แสวงหา",
    subdistrictEn: "Sawaeng Ha",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14150,
    district_code: 1505,
    province_code: 6,
    name: "แสวงหา แสวงหา อ่างทอง 14150",
    name_th: "แสวงหา แสวงหา อ่างทอง 14150",
    name_en: "Sawaeng Ha, Sawaeng Ha, Ang Thong, 14150",
  },
  {
    id: 150502,
    districtTh: "แสวงหา",
    districtEn: "Sawaeng Ha",
    subdistrictTh: "ศรีพราน",
    subdistrictEn: "Si Phran",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14150,
    district_code: 1505,
    province_code: 6,
    name: "แสวงหา ศรีพราน อ่างทอง 14150",
    name_th: "แสวงหา ศรีพราน อ่างทอง 14150",
    name_en: "Sawaeng Ha, Si Phran, Ang Thong, 14150",
  },
  {
    id: 150503,
    districtTh: "แสวงหา",
    districtEn: "Sawaeng Ha",
    subdistrictTh: "บ้านพราน",
    subdistrictEn: "Ban Phran",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14150,
    district_code: 1505,
    province_code: 6,
    name: "แสวงหา บ้านพราน อ่างทอง 14150",
    name_th: "แสวงหา บ้านพราน อ่างทอง 14150",
    name_en: "Sawaeng Ha, Ban Phran, Ang Thong, 14150",
  },
  {
    id: 150504,
    districtTh: "แสวงหา",
    districtEn: "Sawaeng Ha",
    subdistrictTh: "วังน้ำเย็น",
    subdistrictEn: "Wang Nam Yen",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14150,
    district_code: 1505,
    province_code: 6,
    name: "แสวงหา วังน้ำเย็น อ่างทอง 14150",
    name_th: "แสวงหา วังน้ำเย็น อ่างทอง 14150",
    name_en: "Sawaeng Ha, Wang Nam Yen, Ang Thong, 14150",
  },
  {
    id: 150505,
    districtTh: "แสวงหา",
    districtEn: "Sawaeng Ha",
    subdistrictTh: "สีบัวทอง",
    subdistrictEn: "Si Bua Thong",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14150,
    district_code: 1505,
    province_code: 6,
    name: "แสวงหา สีบัวทอง อ่างทอง 14150",
    name_th: "แสวงหา สีบัวทอง อ่างทอง 14150",
    name_en: "Sawaeng Ha, Si Bua Thong, Ang Thong, 14150",
  },
  {
    id: 150506,
    districtTh: "แสวงหา",
    districtEn: "Sawaeng Ha",
    subdistrictTh: "ห้วยไผ่",
    subdistrictEn: "Huai Phai",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14150,
    district_code: 1505,
    province_code: 6,
    name: "แสวงหา ห้วยไผ่ อ่างทอง 14150",
    name_th: "แสวงหา ห้วยไผ่ อ่างทอง 14150",
    name_en: "Sawaeng Ha, Huai Phai, Ang Thong, 14150",
  },
  {
    id: 150507,
    districtTh: "แสวงหา",
    districtEn: "Sawaeng Ha",
    subdistrictTh: "จำลอง",
    subdistrictEn: "Chamlong",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14150,
    district_code: 1505,
    province_code: 6,
    name: "แสวงหา จำลอง อ่างทอง 14150",
    name_th: "แสวงหา จำลอง อ่างทอง 14150",
    name_en: "Sawaeng Ha, Chamlong, Ang Thong, 14150",
  },
  {
    id: 150601,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ไผ่จำศิล",
    subdistrictEn: "Phai Cham Sin",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ไผ่จำศิล อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ไผ่จำศิล อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Phai Cham Sin, Ang Thong, 14110",
  },
  {
    id: 150602,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ศาลเจ้าโรงทอง",
    subdistrictEn: "San Chao Rong Thong",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ศาลเจ้าโรงทอง อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ศาลเจ้าโรงทอง อ่างทอง 14110",
    name_en: "Wiset Chai Chan, San Chao Rong Thong, Ang Thong, 14110",
  },
  {
    id: 150603,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ไผ่ดำพัฒนา",
    subdistrictEn: "Phai Dam Phatthana",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ไผ่ดำพัฒนา อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ไผ่ดำพัฒนา อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Phai Dam Phatthana, Ang Thong, 14110",
  },
  {
    id: 150604,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "สาวร้องไห้",
    subdistrictEn: "Sao Rong Hai",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ สาวร้องไห้ อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ สาวร้องไห้ อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Sao Rong Hai, Ang Thong, 14110",
  },
  {
    id: 150605,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ท่าช้าง อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ท่าช้าง อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Tha Chang, Ang Thong, 14110",
  },
  {
    id: 150606,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ยี่ล้น",
    subdistrictEn: "Yi Lon",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ยี่ล้น อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ยี่ล้น อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Yi Lon, Ang Thong, 14110",
  },
  {
    id: 150607,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "บางจัก",
    subdistrictEn: "Bang Chak",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ บางจัก อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ บางจัก อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Bang Chak, Ang Thong, 14110",
  },
  {
    id: 150608,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ห้วยคันแหลน",
    subdistrictEn: "Huai Khan Laen",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ห้วยคันแหลน อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ห้วยคันแหลน อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Huai Khan Laen, Ang Thong, 14110",
  },
  {
    id: 150609,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "คลองขนาก",
    subdistrictEn: "Khlong Khanak",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ คลองขนาก อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ คลองขนาก อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Khlong Khanak, Ang Thong, 14110",
  },
  {
    id: 150610,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ไผ่วง",
    subdistrictEn: "Phai Wong",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ไผ่วง อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ไผ่วง อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Phai Wong, Ang Thong, 14110",
  },
  {
    id: 150611,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "สี่ร้อย",
    subdistrictEn: "Si Roi",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ สี่ร้อย อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ สี่ร้อย อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Si Roi, Ang Thong, 14110",
  },
  {
    id: 150612,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ม่วงเตี้ย",
    subdistrictEn: "Muang Tia",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ม่วงเตี้ย อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ม่วงเตี้ย อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Muang Tia, Ang Thong, 14110",
  },
  {
    id: 150613,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "หัวตะพาน",
    subdistrictEn: "Hua Taphan",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ หัวตะพาน อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ หัวตะพาน อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Hua Taphan, Ang Thong, 14110",
  },
  {
    id: 150614,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "หลักแก้ว",
    subdistrictEn: "Lak Kaeo",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ หลักแก้ว อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ หลักแก้ว อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Lak Kaeo, Ang Thong, 14110",
  },
  {
    id: 150615,
    districtTh: "วิเศษชัยชาญ",
    districtEn: "Wiset Chai Chan",
    subdistrictTh: "ตลาดใหม่",
    subdistrictEn: "Talat Mai",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14110,
    district_code: 1506,
    province_code: 6,
    name: "วิเศษชัยชาญ ตลาดใหม่ อ่างทอง 14110",
    name_th: "วิเศษชัยชาญ ตลาดใหม่ อ่างทอง 14110",
    name_en: "Wiset Chai Chan, Talat Mai, Ang Thong, 14110",
  },
  {
    id: 150701,
    districtTh: "สามโก้",
    districtEn: "Samko",
    subdistrictTh: "สามโก้",
    subdistrictEn: "Samko",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14160,
    district_code: 1507,
    province_code: 6,
    name: "สามโก้ สามโก้ อ่างทอง 14160",
    name_th: "สามโก้ สามโก้ อ่างทอง 14160",
    name_en: "Samko, Samko, Ang Thong, 14160",
  },
  {
    id: 150702,
    districtTh: "สามโก้",
    districtEn: "Samko",
    subdistrictTh: "ราษฎรพัฒนา",
    subdistrictEn: "Ratsadon Phatthana",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14160,
    district_code: 1507,
    province_code: 6,
    name: "สามโก้ ราษฎรพัฒนา อ่างทอง 14160",
    name_th: "สามโก้ ราษฎรพัฒนา อ่างทอง 14160",
    name_en: "Samko, Ratsadon Phatthana, Ang Thong, 14160",
  },
  {
    id: 150703,
    districtTh: "สามโก้",
    districtEn: "Samko",
    subdistrictTh: "อบทม",
    subdistrictEn: "Op Thom",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14160,
    district_code: 1507,
    province_code: 6,
    name: "สามโก้ อบทม อ่างทอง 14160",
    name_th: "สามโก้ อบทม อ่างทอง 14160",
    name_en: "Samko, Op Thom, Ang Thong, 14160",
  },
  {
    id: 150704,
    districtTh: "สามโก้",
    districtEn: "Samko",
    subdistrictTh: "โพธิ์ม่วงพันธ์",
    subdistrictEn: "Pho Muang Phan",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14160,
    district_code: 1507,
    province_code: 6,
    name: "สามโก้ โพธิ์ม่วงพันธ์ อ่างทอง 14160",
    name_th: "สามโก้ โพธิ์ม่วงพันธ์ อ่างทอง 14160",
    name_en: "Samko, Pho Muang Phan, Ang Thong, 14160",
  },
  {
    id: 150705,
    districtTh: "สามโก้",
    districtEn: "Samko",
    subdistrictTh: "มงคลธรรมนิมิต",
    subdistrictEn: "Mongkhon Tham Nimit",
    provinceTh: "อ่างทอง",
    provinceEn: "Ang Thong",
    zipcode: 14160,
    district_code: 1507,
    province_code: 6,
    name: "สามโก้ มงคลธรรมนิมิต อ่างทอง 14160",
    name_th: "สามโก้ มงคลธรรมนิมิต อ่างทอง 14160",
    name_en: "Samko, Mongkhon Tham Nimit, Ang Thong, 14160",
  },
  {
    id: 160101,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ทะเลชุบศร",
    subdistrictEn: "Thale Chup Son",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ทะเลชุบศร ลพบุรี 15000",
    name_th: "เมืองลพบุรี ทะเลชุบศร ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Thale Chup Son, Loburi, 15000",
  },
  {
    id: 160102,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ท่าหิน",
    subdistrictEn: "Tha Hin",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ท่าหิน ลพบุรี 15000",
    name_th: "เมืองลพบุรี ท่าหิน ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Tha Hin, Loburi, 15000",
  },
  {
    id: 160103,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "กกโก",
    subdistrictEn: "Kok Ko",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี กกโก ลพบุรี 15000",
    name_th: "เมืองลพบุรี กกโก ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Kok Ko, Loburi, 15000",
  },
  {
    id: 160104,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "โก่งธนู",
    subdistrictEn: "Kong Thanu",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 13240,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี โก่งธนู ลพบุรี 13240",
    name_th: "เมืองลพบุรี โก่งธนู ลพบุรี 13240",
    name_en: "Mueang Lop Buri, Kong Thanu, Loburi, 13240",
  },
  {
    id: 160105,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "เขาพระงาม",
    subdistrictEn: "Khao Phra Ngam",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี เขาพระงาม ลพบุรี 15000",
    name_th: "เมืองลพบุรี เขาพระงาม ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Khao Phra Ngam, Loburi, 15000",
  },
  {
    id: 160106,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "เขาสามยอด",
    subdistrictEn: "Khao Sam Yot",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี เขาสามยอด ลพบุรี 15000",
    name_th: "เมืองลพบุรี เขาสามยอด ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Khao Sam Yot, Loburi, 15000",
  },
  {
    id: 160107,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "โคกกะเทียม",
    subdistrictEn: "Khok Kathiam",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี โคกกะเทียม ลพบุรี 15000",
    name_th: "เมืองลพบุรี โคกกะเทียม ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Khok Kathiam, Loburi, 15000",
  },
  {
    id: 160108,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "โคกลำพาน",
    subdistrictEn: "Khok Lam Phan",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี โคกลำพาน ลพบุรี 15000",
    name_th: "เมืองลพบุรี โคกลำพาน ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Khok Lam Phan, Loburi, 15000",
  },
  {
    id: 160109,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "โคกตูม",
    subdistrictEn: "Khok Tum",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15210,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี โคกตูม ลพบุรี 15210",
    name_th: "เมืองลพบุรี โคกตูม ลพบุรี 15210",
    name_en: "Mueang Lop Buri, Khok Tum, Loburi, 15210",
  },
  {
    id: 160110,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "งิ้วราย",
    subdistrictEn: "Ngio Rai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี งิ้วราย ลพบุรี 15000",
    name_th: "เมืองลพบุรี งิ้วราย ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Ngio Rai, Loburi, 15000",
  },
  {
    id: 160111,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ดอนโพธิ์",
    subdistrictEn: "Don Pho",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ดอนโพธิ์ ลพบุรี 15000",
    name_th: "เมืองลพบุรี ดอนโพธิ์ ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Don Pho, Loburi, 15000",
  },
  {
    id: 160112,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ตะลุง",
    subdistrictEn: "Talung",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ตะลุง ลพบุรี 15000",
    name_th: "เมืองลพบุรี ตะลุง ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Talung, Loburi, 15000",
  },
  {
    id: 160114,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ท่าแค",
    subdistrictEn: "Tha Khae",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ท่าแค ลพบุรี 15000",
    name_th: "เมืองลพบุรี ท่าแค ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Tha Khae, Loburi, 15000",
  },
  {
    id: 160115,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ท่าศาลา",
    subdistrictEn: "Tha Sala",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ท่าศาลา ลพบุรี 15000",
    name_th: "เมืองลพบุรี ท่าศาลา ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Tha Sala, Loburi, 15000",
  },
  {
    id: 160116,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "นิคมสร้างตนเอง",
    subdistrictEn: "Nikhom Sang Ton-eng",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี นิคมสร้างตนเอง ลพบุรี 15000",
    name_th: "เมืองลพบุรี นิคมสร้างตนเอง ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Nikhom Sang Ton-eng, Loburi, 15000",
  },
  {
    id: 160117,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "บางขันหมาก",
    subdistrictEn: "Bang Khan Mak",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี บางขันหมาก ลพบุรี 15000",
    name_th: "เมืองลพบุรี บางขันหมาก ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Bang Khan Mak, Loburi, 15000",
  },
  {
    id: 160118,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "บ้านข่อย",
    subdistrictEn: "Ban Khoi",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี บ้านข่อย ลพบุรี 15000",
    name_th: "เมืองลพบุรี บ้านข่อย ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Ban Khoi, Loburi, 15000",
  },
  {
    id: 160119,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ท้ายตลาด",
    subdistrictEn: "Thai Talat",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ท้ายตลาด ลพบุรี 15000",
    name_th: "เมืองลพบุรี ท้ายตลาด ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Thai Talat, Loburi, 15000",
  },
  {
    id: 160120,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ป่าตาล",
    subdistrictEn: "Pa Tan",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ป่าตาล ลพบุรี 15000",
    name_th: "เมืองลพบุรี ป่าตาล ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Pa Tan, Loburi, 15000",
  },
  {
    id: 160121,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "พรหมมาสตร์",
    subdistrictEn: "Phrommat",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี พรหมมาสตร์ ลพบุรี 15000",
    name_th: "เมืองลพบุรี พรหมมาสตร์ ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Phrommat, Loburi, 15000",
  },
  {
    id: 160122,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "โพธิ์เก้าต้น",
    subdistrictEn: "Pho Kao Ton",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี โพธิ์เก้าต้น ลพบุรี 15000",
    name_th: "เมืองลพบุรี โพธิ์เก้าต้น ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Pho Kao Ton, Loburi, 15000",
  },
  {
    id: 160123,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "โพธิ์ตรุ",
    subdistrictEn: "Pho Tru",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี โพธิ์ตรุ ลพบุรี 15000",
    name_th: "เมืองลพบุรี โพธิ์ตรุ ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Pho Tru, Loburi, 15000",
  },
  {
    id: 160124,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "สี่คลอง",
    subdistrictEn: "Si Khlong",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี สี่คลอง ลพบุรี 15000",
    name_th: "เมืองลพบุรี สี่คลอง ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Si Khlong, Loburi, 15000",
  },
  {
    id: 160125,
    districtTh: "เมืองลพบุรี",
    districtEn: "Mueang Lop Buri",
    subdistrictTh: "ถนนใหญ่",
    subdistrictEn: "Thanon Yai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15000,
    district_code: 1601,
    province_code: 7,
    name: "เมืองลพบุรี ถนนใหญ่ ลพบุรี 15000",
    name_th: "เมืองลพบุรี ถนนใหญ่ ลพบุรี 15000",
    name_en: "Mueang Lop Buri, Thanon Yai, Loburi, 15000",
  },
  {
    id: 160201,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "พัฒนานิคม",
    subdistrictEn: "Phatthana Nikhom",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15140,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม พัฒนานิคม ลพบุรี 15140",
    name_th: "พัฒนานิคม พัฒนานิคม ลพบุรี 15140",
    name_en: "Phatthana Nikhom, Phatthana Nikhom, Loburi, 15140",
  },
  {
    id: 160202,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "ช่องสาริกา",
    subdistrictEn: "Chong Sarika",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15220,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม ช่องสาริกา ลพบุรี 15220",
    name_th: "พัฒนานิคม ช่องสาริกา ลพบุรี 15220",
    name_en: "Phatthana Nikhom, Chong Sarika, Loburi, 15220",
  },
  {
    id: 160203,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "มะนาวหวาน",
    subdistrictEn: "Manao Wan",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15140,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม มะนาวหวาน ลพบุรี 15140",
    name_th: "พัฒนานิคม มะนาวหวาน ลพบุรี 15140",
    name_en: "Phatthana Nikhom, Manao Wan, Loburi, 15140",
  },
  {
    id: 160204,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "ดีลัง",
    subdistrictEn: "Di Lang",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15220,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม ดีลัง ลพบุรี 15220",
    name_th: "พัฒนานิคม ดีลัง ลพบุรี 15220",
    name_en: "Phatthana Nikhom, Di Lang, Loburi, 15220",
  },
  {
    id: 160205,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "โคกสลุง",
    subdistrictEn: "Khok Salung",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15140,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม โคกสลุง ลพบุรี 15140",
    name_th: "พัฒนานิคม โคกสลุง ลพบุรี 15140",
    name_en: "Phatthana Nikhom, Khok Salung, Loburi, 15140",
  },
  {
    id: 160206,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "ชอนน้อย",
    subdistrictEn: "Chon Noi",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15140,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม ชอนน้อย ลพบุรี 15140",
    name_th: "พัฒนานิคม ชอนน้อย ลพบุรี 15140",
    name_en: "Phatthana Nikhom, Chon Noi, Loburi, 15140",
  },
  {
    id: 160207,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15140,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม หนองบัว ลพบุรี 15140",
    name_th: "พัฒนานิคม หนองบัว ลพบุรี 15140",
    name_en: "Phatthana Nikhom, Nong Bua, Loburi, 15140",
  },
  {
    id: 160208,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "ห้วยขุนราม",
    subdistrictEn: "Huai Khun Ram",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 18220,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม ห้วยขุนราม ลพบุรี 18220",
    name_th: "พัฒนานิคม ห้วยขุนราม ลพบุรี 18220",
    name_en: "Phatthana Nikhom, Huai Khun Ram, Loburi, 18220",
  },
  {
    id: 160209,
    districtTh: "พัฒนานิคม",
    districtEn: "Phatthana Nikhom",
    subdistrictTh: "น้ำสุด",
    subdistrictEn: "Nam Sut",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15140,
    district_code: 1602,
    province_code: 7,
    name: "พัฒนานิคม น้ำสุด ลพบุรี 15140",
    name_th: "พัฒนานิคม น้ำสุด ลพบุรี 15140",
    name_en: "Phatthana Nikhom, Nam Sut, Loburi, 15140",
  },
  {
    id: 160301,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "โคกสำโรง",
    subdistrictEn: "Khok Samrong",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง โคกสำโรง ลพบุรี 15120",
    name_th: "โคกสำโรง โคกสำโรง ลพบุรี 15120",
    name_en: "Khok Samrong, Khok Samrong, Loburi, 15120",
  },
  {
    id: 160302,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "เกาะแก้ว",
    subdistrictEn: "Ko Kaeo",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง เกาะแก้ว ลพบุรี 15120",
    name_th: "โคกสำโรง เกาะแก้ว ลพบุรี 15120",
    name_en: "Khok Samrong, Ko Kaeo, Loburi, 15120",
  },
  {
    id: 160303,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "ถลุงเหล็ก",
    subdistrictEn: "Thalung Lek",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง ถลุงเหล็ก ลพบุรี 15120",
    name_th: "โคกสำโรง ถลุงเหล็ก ลพบุรี 15120",
    name_en: "Khok Samrong, Thalung Lek, Loburi, 15120",
  },
  {
    id: 160304,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "หลุมข้าว",
    subdistrictEn: "Lum Khao",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง หลุมข้าว ลพบุรี 15120",
    name_th: "โคกสำโรง หลุมข้าว ลพบุรี 15120",
    name_en: "Khok Samrong, Lum Khao, Loburi, 15120",
  },
  {
    id: 160305,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "ห้วยโป่ง",
    subdistrictEn: "Huai Pong",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง ห้วยโป่ง ลพบุรี 15120",
    name_th: "โคกสำโรง ห้วยโป่ง ลพบุรี 15120",
    name_en: "Khok Samrong, Huai Pong, Loburi, 15120",
  },
  {
    id: 160306,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "คลองเกตุ",
    subdistrictEn: "Khlong Ket",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง คลองเกตุ ลพบุรี 15120",
    name_th: "โคกสำโรง คลองเกตุ ลพบุรี 15120",
    name_en: "Khok Samrong, Khlong Ket, Loburi, 15120",
  },
  {
    id: 160307,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "สะแกราบ",
    subdistrictEn: "Sakae Rap",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง สะแกราบ ลพบุรี 15120",
    name_th: "โคกสำโรง สะแกราบ ลพบุรี 15120",
    name_en: "Khok Samrong, Sakae Rap, Loburi, 15120",
  },
  {
    id: 160308,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "เพนียด",
    subdistrictEn: "Phaniat",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง เพนียด ลพบุรี 15120",
    name_th: "โคกสำโรง เพนียด ลพบุรี 15120",
    name_en: "Khok Samrong, Phaniat, Loburi, 15120",
  },
  {
    id: 160309,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "วังเพลิง",
    subdistrictEn: "Wang Phloeng",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง วังเพลิง ลพบุรี 15120",
    name_th: "โคกสำโรง วังเพลิง ลพบุรี 15120",
    name_en: "Khok Samrong, Wang Phloeng, Loburi, 15120",
  },
  {
    id: 160310,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "ดงมะรุม",
    subdistrictEn: "Dong Marum",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง ดงมะรุม ลพบุรี 15120",
    name_th: "โคกสำโรง ดงมะรุม ลพบุรี 15120",
    name_en: "Khok Samrong, Dong Marum, Loburi, 15120",
  },
  {
    id: 160318,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "วังขอนขว้าง",
    subdistrictEn: "Wang Khon Khwang",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง วังขอนขว้าง ลพบุรี 15120",
    name_th: "โคกสำโรง วังขอนขว้าง ลพบุรี 15120",
    name_en: "Khok Samrong, Wang Khon Khwang, Loburi, 15120",
  },
  {
    id: 160320,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "วังจั่น",
    subdistrictEn: "Wang Chan",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง วังจั่น ลพบุรี 15120",
    name_th: "โคกสำโรง วังจั่น ลพบุรี 15120",
    name_en: "Khok Samrong, Wang Chan, Loburi, 15120",
  },
  {
    id: 160322,
    districtTh: "โคกสำโรง",
    districtEn: "Khok Samrong",
    subdistrictTh: "หนองแขม",
    subdistrictEn: "Nong Khaem",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15120,
    district_code: 1603,
    province_code: 7,
    name: "โคกสำโรง หนองแขม ลพบุรี 15120",
    name_th: "โคกสำโรง หนองแขม ลพบุรี 15120",
    name_en: "Khok Samrong, Nong Khaem, Loburi, 15120",
  },
  {
    id: 160401,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ลำนารายณ์",
    subdistrictEn: "Lam Narai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ลำนารายณ์ ลพบุรี 15130",
    name_th: "ชัยบาดาล ลำนารายณ์ ลพบุรี 15130",
    name_en: "Chai Badan, Lam Narai, Loburi, 15130",
  },
  {
    id: 160402,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ชัยนารายณ์",
    subdistrictEn: "Chai Narai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ชัยนารายณ์ ลพบุรี 15130",
    name_th: "ชัยบาดาล ชัยนารายณ์ ลพบุรี 15130",
    name_en: "Chai Badan, Chai Narai, Loburi, 15130",
  },
  {
    id: 160403,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ศิลาทิพย์",
    subdistrictEn: "Sila Thip",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ศิลาทิพย์ ลพบุรี 15130",
    name_th: "ชัยบาดาล ศิลาทิพย์ ลพบุรี 15130",
    name_en: "Chai Badan, Sila Thip, Loburi, 15130",
  },
  {
    id: 160404,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ห้วยหิน",
    subdistrictEn: "Huai Hin",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ห้วยหิน ลพบุรี 15130",
    name_th: "ชัยบาดาล ห้วยหิน ลพบุรี 15130",
    name_en: "Chai Badan, Huai Hin, Loburi, 15130",
  },
  {
    id: 160405,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ม่วงค่อม",
    subdistrictEn: "Muang Khom",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ม่วงค่อม ลพบุรี 15230",
    name_th: "ชัยบาดาล ม่วงค่อม ลพบุรี 15230",
    name_en: "Chai Badan, Muang Khom, Loburi, 15230",
  },
  {
    id: 160406,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "บัวชุม",
    subdistrictEn: "Bua Chum",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล บัวชุม ลพบุรี 15130",
    name_th: "ชัยบาดาล บัวชุม ลพบุรี 15130",
    name_en: "Chai Badan, Bua Chum, Loburi, 15130",
  },
  {
    id: 160407,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ท่าดินดำ",
    subdistrictEn: "Tha Din Dam",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ท่าดินดำ ลพบุรี 15130",
    name_th: "ชัยบาดาล ท่าดินดำ ลพบุรี 15130",
    name_en: "Chai Badan, Tha Din Dam, Loburi, 15130",
  },
  {
    id: 160408,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "มะกอกหวาน",
    subdistrictEn: "Makok Wan",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล มะกอกหวาน ลพบุรี 15230",
    name_th: "ชัยบาดาล มะกอกหวาน ลพบุรี 15230",
    name_en: "Chai Badan, Makok Wan, Loburi, 15230",
  },
  {
    id: 160409,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ซับตะเคียน",
    subdistrictEn: "Sap Takhian",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ซับตะเคียน ลพบุรี 15130",
    name_th: "ชัยบาดาล ซับตะเคียน ลพบุรี 15130",
    name_en: "Chai Badan, Sap Takhian, Loburi, 15130",
  },
  {
    id: 160410,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "นาโสม",
    subdistrictEn: "Na Som",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15190,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล นาโสม ลพบุรี 15190",
    name_th: "ชัยบาดาล นาโสม ลพบุรี 15190",
    name_en: "Chai Badan, Na Som, Loburi, 15190",
  },
  {
    id: 160411,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "หนองยายโต๊ะ",
    subdistrictEn: "Nong Yai To",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล หนองยายโต๊ะ ลพบุรี 15130",
    name_th: "ชัยบาดาล หนองยายโต๊ะ ลพบุรี 15130",
    name_en: "Chai Badan, Nong Yai To, Loburi, 15130",
  },
  {
    id: 160412,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "เกาะรัง",
    subdistrictEn: "Ko Rang",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล เกาะรัง ลพบุรี 15130",
    name_th: "ชัยบาดาล เกาะรัง ลพบุรี 15130",
    name_en: "Chai Badan, Ko Rang, Loburi, 15130",
  },
  {
    id: 160414,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ท่ามะนาว",
    subdistrictEn: "Tha Manao",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ท่ามะนาว ลพบุรี 15130",
    name_th: "ชัยบาดาล ท่ามะนาว ลพบุรี 15130",
    name_en: "Chai Badan, Tha Manao, Loburi, 15130",
  },
  {
    id: 160417,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "นิคมลำนารายณ์",
    subdistrictEn: "Nikhom Lam Narai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล นิคมลำนารายณ์ ลพบุรี 15130",
    name_th: "ชัยบาดาล นิคมลำนารายณ์ ลพบุรี 15130",
    name_en: "Chai Badan, Nikhom Lam Narai, Loburi, 15130",
  },
  {
    id: 160418,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "ชัยบาดาล",
    subdistrictEn: "Chai Badan",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล ชัยบาดาล ลพบุรี 15230",
    name_th: "ชัยบาดาล ชัยบาดาล ลพบุรี 15230",
    name_en: "Chai Badan, Chai Badan, Loburi, 15230",
  },
  {
    id: 160419,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "บ้านใหม่สามัคคี",
    subdistrictEn: "Ban Mai Samakkhi",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล บ้านใหม่สามัคคี ลพบุรี 15130",
    name_th: "ชัยบาดาล บ้านใหม่สามัคคี ลพบุรี 15130",
    name_en: "Chai Badan, Ban Mai Samakkhi, Loburi, 15130",
  },
  {
    id: 160422,
    districtTh: "ชัยบาดาล",
    districtEn: "Chai Badan",
    subdistrictTh: "เขาแหลม",
    subdistrictEn: "Khao Laem",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1604,
    province_code: 7,
    name: "ชัยบาดาล เขาแหลม ลพบุรี 15130",
    name_th: "ชัยบาดาล เขาแหลม ลพบุรี 15130",
    name_en: "Chai Badan, Khao Laem, Loburi, 15130",
  },
  {
    id: 160501,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "ท่าวุ้ง",
    subdistrictEn: "Tha Wung",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง ท่าวุ้ง ลพบุรี 15150",
    name_th: "ท่าวุ้ง ท่าวุ้ง ลพบุรี 15150",
    name_en: "Tha Wung, Tha Wung, Loburi, 15150",
  },
  {
    id: 160502,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "บางคู้",
    subdistrictEn: "Bang Khu",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง บางคู้ ลพบุรี 15150",
    name_th: "ท่าวุ้ง บางคู้ ลพบุรี 15150",
    name_en: "Tha Wung, Bang Khu, Loburi, 15150",
  },
  {
    id: 160503,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "โพตลาดแก้ว",
    subdistrictEn: "Pho Talat Kaeo",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง โพตลาดแก้ว ลพบุรี 15150",
    name_th: "ท่าวุ้ง โพตลาดแก้ว ลพบุรี 15150",
    name_en: "Tha Wung, Pho Talat Kaeo, Loburi, 15150",
  },
  {
    id: 160504,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "บางลี่",
    subdistrictEn: "Bang Li",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง บางลี่ ลพบุรี 15150",
    name_th: "ท่าวุ้ง บางลี่ ลพบุรี 15150",
    name_en: "Tha Wung, Bang Li, Loburi, 15150",
  },
  {
    id: 160505,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "บางงา",
    subdistrictEn: "Bang Nga",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง บางงา ลพบุรี 15150",
    name_th: "ท่าวุ้ง บางงา ลพบุรี 15150",
    name_en: "Tha Wung, Bang Nga, Loburi, 15150",
  },
  {
    id: 160506,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "โคกสลุด",
    subdistrictEn: "Khok Salut",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง โคกสลุด ลพบุรี 15150",
    name_th: "ท่าวุ้ง โคกสลุด ลพบุรี 15150",
    name_en: "Tha Wung, Khok Salut, Loburi, 15150",
  },
  {
    id: 160507,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "เขาสมอคอน",
    subdistrictEn: "Khao Samo Khon",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15180,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง เขาสมอคอน ลพบุรี 15180",
    name_th: "ท่าวุ้ง เขาสมอคอน ลพบุรี 15180",
    name_en: "Tha Wung, Khao Samo Khon, Loburi, 15180",
  },
  {
    id: 160508,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "หัวสำโรง",
    subdistrictEn: "Hua Samrong",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง หัวสำโรง ลพบุรี 15150",
    name_th: "ท่าวุ้ง หัวสำโรง ลพบุรี 15150",
    name_en: "Tha Wung, Hua Samrong, Loburi, 15150",
  },
  {
    id: 160509,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "ลาดสาลี่",
    subdistrictEn: "Lat Sali",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง ลาดสาลี่ ลพบุรี 15150",
    name_th: "ท่าวุ้ง ลาดสาลี่ ลพบุรี 15150",
    name_en: "Tha Wung, Lat Sali, Loburi, 15150",
  },
  {
    id: 160510,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "บ้านเบิก",
    subdistrictEn: "Ban Boek",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง บ้านเบิก ลพบุรี 15150",
    name_th: "ท่าวุ้ง บ้านเบิก ลพบุรี 15150",
    name_en: "Tha Wung, Ban Boek, Loburi, 15150",
  },
  {
    id: 160511,
    districtTh: "ท่าวุ้ง",
    districtEn: "Tha Wung",
    subdistrictTh: "มุจลินท์",
    subdistrictEn: "Mutchalin",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15150,
    district_code: 1605,
    province_code: 7,
    name: "ท่าวุ้ง มุจลินท์ ลพบุรี 15150",
    name_th: "ท่าวุ้ง มุจลินท์ ลพบุรี 15150",
    name_en: "Tha Wung, Mutchalin, Loburi, 15150",
  },
  {
    id: 160601,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "ไผ่ใหญ่",
    subdistrictEn: "Phai Yai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ ไผ่ใหญ่ ลพบุรี 15110",
    name_th: "บ้านหมี่ ไผ่ใหญ่ ลพบุรี 15110",
    name_en: "Ban Mi, Phai Yai, Loburi, 15110",
  },
  {
    id: 160602,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "บ้านทราย",
    subdistrictEn: "Ban Sai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ บ้านทราย ลพบุรี 15110",
    name_th: "บ้านหมี่ บ้านทราย ลพบุรี 15110",
    name_en: "Ban Mi, Ban Sai, Loburi, 15110",
  },
  {
    id: 160603,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "บ้านกล้วย",
    subdistrictEn: "Ban Kluai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ บ้านกล้วย ลพบุรี 15110",
    name_th: "บ้านหมี่ บ้านกล้วย ลพบุรี 15110",
    name_en: "Ban Mi, Ban Kluai, Loburi, 15110",
  },
  {
    id: 160604,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "ดงพลับ",
    subdistrictEn: "Dong Phlap",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ ดงพลับ ลพบุรี 15110",
    name_th: "บ้านหมี่ ดงพลับ ลพบุรี 15110",
    name_en: "Ban Mi, Dong Phlap, Loburi, 15110",
  },
  {
    id: 160605,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "บ้านชี",
    subdistrictEn: "Ban Chi",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15180,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ บ้านชี ลพบุรี 15180",
    name_th: "บ้านหมี่ บ้านชี ลพบุรี 15180",
    name_en: "Ban Mi, Ban Chi, Loburi, 15180",
  },
  {
    id: 160606,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "พุคา",
    subdistrictEn: "Phu Kha",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ พุคา ลพบุรี 15110",
    name_th: "บ้านหมี่ พุคา ลพบุรี 15110",
    name_en: "Ban Mi, Phu Kha, Loburi, 15110",
  },
  {
    id: 160607,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "หินปัก",
    subdistrictEn: "Hin Pak",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ หินปัก ลพบุรี 15110",
    name_th: "บ้านหมี่ หินปัก ลพบุรี 15110",
    name_en: "Ban Mi, Hin Pak, Loburi, 15110",
  },
  {
    id: 160608,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "บางพึ่ง",
    subdistrictEn: "Bang Phueng",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ บางพึ่ง ลพบุรี 15110",
    name_th: "บ้านหมี่ บางพึ่ง ลพบุรี 15110",
    name_en: "Ban Mi, Bang Phueng, Loburi, 15110",
  },
  {
    id: 160609,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "หนองทรายขาว",
    subdistrictEn: "Nong Sai Khao",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ หนองทรายขาว ลพบุรี 15110",
    name_th: "บ้านหมี่ หนองทรายขาว ลพบุรี 15110",
    name_en: "Ban Mi, Nong Sai Khao, Loburi, 15110",
  },
  {
    id: 160610,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "บางกะพี้",
    subdistrictEn: "Bang Kaphi",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ บางกะพี้ ลพบุรี 15110",
    name_th: "บ้านหมี่ บางกะพี้ ลพบุรี 15110",
    name_en: "Ban Mi, Bang Kaphi, Loburi, 15110",
  },
  {
    id: 160611,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "หนองเต่า",
    subdistrictEn: "Nong Tao",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ หนองเต่า ลพบุรี 15110",
    name_th: "บ้านหมี่ หนองเต่า ลพบุรี 15110",
    name_en: "Ban Mi, Nong Tao, Loburi, 15110",
  },
  {
    id: 160612,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ โพนทอง ลพบุรี 15110",
    name_th: "บ้านหมี่ โพนทอง ลพบุรี 15110",
    name_en: "Ban Mi, Phon Thong, Loburi, 15110",
  },
  {
    id: 160613,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "บางขาม",
    subdistrictEn: "Bang Kham",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15180,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ บางขาม ลพบุรี 15180",
    name_th: "บ้านหมี่ บางขาม ลพบุรี 15180",
    name_en: "Ban Mi, Bang Kham, Loburi, 15180",
  },
  {
    id: 160614,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "ดอนดึง",
    subdistrictEn: "Don Dueng",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ ดอนดึง ลพบุรี 15110",
    name_th: "บ้านหมี่ ดอนดึง ลพบุรี 15110",
    name_en: "Ban Mi, Don Dueng, Loburi, 15110",
  },
  {
    id: 160615,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "ชอนม่วง",
    subdistrictEn: "Chon Muang",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ ชอนม่วง ลพบุรี 15110",
    name_th: "บ้านหมี่ ชอนม่วง ลพบุรี 15110",
    name_en: "Ban Mi, Chon Muang, Loburi, 15110",
  },
  {
    id: 160616,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "หนองกระเบียน",
    subdistrictEn: "Nong Krabian",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ หนองกระเบียน ลพบุรี 15110",
    name_th: "บ้านหมี่ หนองกระเบียน ลพบุรี 15110",
    name_en: "Ban Mi, Nong Krabian, Loburi, 15110",
  },
  {
    id: 160617,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "สายห้วยแก้ว",
    subdistrictEn: "Sai Huai Kaeo",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ สายห้วยแก้ว ลพบุรี 15110",
    name_th: "บ้านหมี่ สายห้วยแก้ว ลพบุรี 15110",
    name_en: "Ban Mi, Sai Huai Kaeo, Loburi, 15110",
  },
  {
    id: 160618,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "มหาสอน",
    subdistrictEn: "Maha Son",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ มหาสอน ลพบุรี 15110",
    name_th: "บ้านหมี่ มหาสอน ลพบุรี 15110",
    name_en: "Ban Mi, Maha Son, Loburi, 15110",
  },
  {
    id: 160619,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "บ้านหมี่",
    subdistrictEn: "Ban Mi",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ บ้านหมี่ ลพบุรี 15110",
    name_th: "บ้านหมี่ บ้านหมี่ ลพบุรี 15110",
    name_en: "Ban Mi, Ban Mi, Loburi, 15110",
  },
  {
    id: 160620,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "เชียงงา",
    subdistrictEn: "Chiang Nga",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ เชียงงา ลพบุรี 15110",
    name_th: "บ้านหมี่ เชียงงา ลพบุรี 15110",
    name_en: "Ban Mi, Chiang Nga, Loburi, 15110",
  },
  {
    id: 160621,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "หนองเมือง",
    subdistrictEn: "Nong Mueang",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ หนองเมือง ลพบุรี 15110",
    name_th: "บ้านหมี่ หนองเมือง ลพบุรี 15110",
    name_en: "Ban Mi, Nong Mueang, Loburi, 15110",
  },
  {
    id: 160622,
    districtTh: "บ้านหมี่",
    districtEn: "Ban Mi",
    subdistrictTh: "สนามแจง",
    subdistrictEn: "Sanam Chaeng",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15110,
    district_code: 1606,
    province_code: 7,
    name: "บ้านหมี่ สนามแจง ลพบุรี 15110",
    name_th: "บ้านหมี่ สนามแจง ลพบุรี 15110",
    name_en: "Ban Mi, Sanam Chaeng, Loburi, 15110",
  },
  {
    id: 160701,
    districtTh: "ท่าหลวง",
    districtEn: "Tha Luang",
    subdistrictTh: "ท่าหลวง",
    subdistrictEn: "Tha Luang",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1607,
    province_code: 7,
    name: "ท่าหลวง ท่าหลวง ลพบุรี 15230",
    name_th: "ท่าหลวง ท่าหลวง ลพบุรี 15230",
    name_en: "Tha Luang, Tha Luang, Loburi, 15230",
  },
  {
    id: 160702,
    districtTh: "ท่าหลวง",
    districtEn: "Tha Luang",
    subdistrictTh: "แก่งผักกูด",
    subdistrictEn: "Kaeng Phak Kut",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1607,
    province_code: 7,
    name: "ท่าหลวง แก่งผักกูด ลพบุรี 15230",
    name_th: "ท่าหลวง แก่งผักกูด ลพบุรี 15230",
    name_en: "Tha Luang, Kaeng Phak Kut, Loburi, 15230",
  },
  {
    id: 160703,
    districtTh: "ท่าหลวง",
    districtEn: "Tha Luang",
    subdistrictTh: "ซับจำปา",
    subdistrictEn: "Sap Champa",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1607,
    province_code: 7,
    name: "ท่าหลวง ซับจำปา ลพบุรี 15230",
    name_th: "ท่าหลวง ซับจำปา ลพบุรี 15230",
    name_en: "Tha Luang, Sap Champa, Loburi, 15230",
  },
  {
    id: 160704,
    districtTh: "ท่าหลวง",
    districtEn: "Tha Luang",
    subdistrictTh: "หนองผักแว่น",
    subdistrictEn: "Nong Phak Waen",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1607,
    province_code: 7,
    name: "ท่าหลวง หนองผักแว่น ลพบุรี 15230",
    name_th: "ท่าหลวง หนองผักแว่น ลพบุรี 15230",
    name_en: "Tha Luang, Nong Phak Waen, Loburi, 15230",
  },
  {
    id: 160705,
    districtTh: "ท่าหลวง",
    districtEn: "Tha Luang",
    subdistrictTh: "ทะเลวังวัด",
    subdistrictEn: "Thale Wang Wat",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1607,
    province_code: 7,
    name: "ท่าหลวง ทะเลวังวัด ลพบุรี 15230",
    name_th: "ท่าหลวง ทะเลวังวัด ลพบุรี 15230",
    name_en: "Tha Luang, Thale Wang Wat, Loburi, 15230",
  },
  {
    id: 160706,
    districtTh: "ท่าหลวง",
    districtEn: "Tha Luang",
    subdistrictTh: "หัวลำ",
    subdistrictEn: "Hua Lam",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15230,
    district_code: 1607,
    province_code: 7,
    name: "ท่าหลวง หัวลำ ลพบุรี 15230",
    name_th: "ท่าหลวง หัวลำ ลพบุรี 15230",
    name_en: "Tha Luang, Hua Lam, Loburi, 15230",
  },
  {
    id: 160801,
    districtTh: "สระโบสถ์",
    districtEn: "Sa Bot",
    subdistrictTh: "สระโบสถ์",
    subdistrictEn: "Sa Bot",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15240,
    district_code: 1608,
    province_code: 7,
    name: "สระโบสถ์ สระโบสถ์ ลพบุรี 15240",
    name_th: "สระโบสถ์ สระโบสถ์ ลพบุรี 15240",
    name_en: "Sa Bot, Sa Bot, Loburi, 15240",
  },
  {
    id: 160802,
    districtTh: "สระโบสถ์",
    districtEn: "Sa Bot",
    subdistrictTh: "มหาโพธิ",
    subdistrictEn: "Maha Phot",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15240,
    district_code: 1608,
    province_code: 7,
    name: "สระโบสถ์ มหาโพธิ ลพบุรี 15240",
    name_th: "สระโบสถ์ มหาโพธิ ลพบุรี 15240",
    name_en: "Sa Bot, Maha Phot, Loburi, 15240",
  },
  {
    id: 160803,
    districtTh: "สระโบสถ์",
    districtEn: "Sa Bot",
    subdistrictTh: "ทุ่งท่าช้าง",
    subdistrictEn: "Thung Tha Chang",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15240,
    district_code: 1608,
    province_code: 7,
    name: "สระโบสถ์ ทุ่งท่าช้าง ลพบุรี 15240",
    name_th: "สระโบสถ์ ทุ่งท่าช้าง ลพบุรี 15240",
    name_en: "Sa Bot, Thung Tha Chang, Loburi, 15240",
  },
  {
    id: 160804,
    districtTh: "สระโบสถ์",
    districtEn: "Sa Bot",
    subdistrictTh: "ห้วยใหญ่",
    subdistrictEn: "Huai Yai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15240,
    district_code: 1608,
    province_code: 7,
    name: "สระโบสถ์ ห้วยใหญ่ ลพบุรี 15240",
    name_th: "สระโบสถ์ ห้วยใหญ่ ลพบุรี 15240",
    name_en: "Sa Bot, Huai Yai, Loburi, 15240",
  },
  {
    id: 160805,
    districtTh: "สระโบสถ์",
    districtEn: "Sa Bot",
    subdistrictTh: "นิยมชัย",
    subdistrictEn: "Niyom Chai",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15240,
    district_code: 1608,
    province_code: 7,
    name: "สระโบสถ์ นิยมชัย ลพบุรี 15240",
    name_th: "สระโบสถ์ นิยมชัย ลพบุรี 15240",
    name_en: "Sa Bot, Niyom Chai, Loburi, 15240",
  },
  {
    id: 160901,
    districtTh: "โคกเจริญ",
    districtEn: "Khok Charoen",
    subdistrictTh: "โคกเจริญ",
    subdistrictEn: "Khok Charoen",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15250,
    district_code: 1609,
    province_code: 7,
    name: "โคกเจริญ โคกเจริญ ลพบุรี 15250",
    name_th: "โคกเจริญ โคกเจริญ ลพบุรี 15250",
    name_en: "Khok Charoen, Khok Charoen, Loburi, 15250",
  },
  {
    id: 160902,
    districtTh: "โคกเจริญ",
    districtEn: "Khok Charoen",
    subdistrictTh: "ยางราก",
    subdistrictEn: "Yang Rak",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15250,
    district_code: 1609,
    province_code: 7,
    name: "โคกเจริญ ยางราก ลพบุรี 15250",
    name_th: "โคกเจริญ ยางราก ลพบุรี 15250",
    name_en: "Khok Charoen, Yang Rak, Loburi, 15250",
  },
  {
    id: 160903,
    districtTh: "โคกเจริญ",
    districtEn: "Khok Charoen",
    subdistrictTh: "หนองมะค่า",
    subdistrictEn: "Nong Makha",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15250,
    district_code: 1609,
    province_code: 7,
    name: "โคกเจริญ หนองมะค่า ลพบุรี 15250",
    name_th: "โคกเจริญ หนองมะค่า ลพบุรี 15250",
    name_en: "Khok Charoen, Nong Makha, Loburi, 15250",
  },
  {
    id: 160904,
    districtTh: "โคกเจริญ",
    districtEn: "Khok Charoen",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15250,
    district_code: 1609,
    province_code: 7,
    name: "โคกเจริญ วังทอง ลพบุรี 15250",
    name_th: "โคกเจริญ วังทอง ลพบุรี 15250",
    name_en: "Khok Charoen, Wang Thong, Loburi, 15250",
  },
  {
    id: 160905,
    districtTh: "โคกเจริญ",
    districtEn: "Khok Charoen",
    subdistrictTh: "โคกแสมสาร",
    subdistrictEn: "Khok Samae San",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15250,
    district_code: 1609,
    province_code: 7,
    name: "โคกเจริญ โคกแสมสาร ลพบุรี 15250",
    name_th: "โคกเจริญ โคกแสมสาร ลพบุรี 15250",
    name_en: "Khok Charoen, Khok Samae San, Loburi, 15250",
  },
  {
    id: 161001,
    districtTh: "ลำสนธิ",
    districtEn: "Lam Sonthi",
    subdistrictTh: "ลำสนธิ",
    subdistrictEn: "Lam Sonthi",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15190,
    district_code: 1610,
    province_code: 7,
    name: "ลำสนธิ ลำสนธิ ลพบุรี 15190",
    name_th: "ลำสนธิ ลำสนธิ ลพบุรี 15190",
    name_en: "Lam Sonthi, Lam Sonthi, Loburi, 15190",
  },
  {
    id: 161002,
    districtTh: "ลำสนธิ",
    districtEn: "Lam Sonthi",
    subdistrictTh: "ซับสมบูรณ์",
    subdistrictEn: "Sap Sombun",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15190,
    district_code: 1610,
    province_code: 7,
    name: "ลำสนธิ ซับสมบูรณ์ ลพบุรี 15190",
    name_th: "ลำสนธิ ซับสมบูรณ์ ลพบุรี 15190",
    name_en: "Lam Sonthi, Sap Sombun, Loburi, 15190",
  },
  {
    id: 161003,
    districtTh: "ลำสนธิ",
    districtEn: "Lam Sonthi",
    subdistrictTh: "หนองรี",
    subdistrictEn: "Nong Ri",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15190,
    district_code: 1610,
    province_code: 7,
    name: "ลำสนธิ หนองรี ลพบุรี 15190",
    name_th: "ลำสนธิ หนองรี ลพบุรี 15190",
    name_en: "Lam Sonthi, Nong Ri, Loburi, 15190",
  },
  {
    id: 161004,
    districtTh: "ลำสนธิ",
    districtEn: "Lam Sonthi",
    subdistrictTh: "กุดตาเพชร",
    subdistrictEn: "Kut Ta Phet",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15190,
    district_code: 1610,
    province_code: 7,
    name: "ลำสนธิ กุดตาเพชร ลพบุรี 15190",
    name_th: "ลำสนธิ กุดตาเพชร ลพบุรี 15190",
    name_en: "Lam Sonthi, Kut Ta Phet, Loburi, 15190",
  },
  {
    id: 161005,
    districtTh: "ลำสนธิ",
    districtEn: "Lam Sonthi",
    subdistrictTh: "เขารวก",
    subdistrictEn: "Khao Ruak",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15190,
    district_code: 1610,
    province_code: 7,
    name: "ลำสนธิ เขารวก ลพบุรี 15190",
    name_th: "ลำสนธิ เขารวก ลพบุรี 15190",
    name_en: "Lam Sonthi, Khao Ruak, Loburi, 15190",
  },
  {
    id: 161006,
    districtTh: "ลำสนธิ",
    districtEn: "Lam Sonthi",
    subdistrictTh: "เขาน้อย",
    subdistrictEn: "Khao Noi",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15130,
    district_code: 1610,
    province_code: 7,
    name: "ลำสนธิ เขาน้อย ลพบุรี 15130",
    name_th: "ลำสนธิ เขาน้อย ลพบุรี 15130",
    name_en: "Lam Sonthi, Khao Noi, Loburi, 15130",
  },
  {
    id: 161101,
    districtTh: "หนองม่วง",
    districtEn: "Nong Muang",
    subdistrictTh: "หนองม่วง",
    subdistrictEn: "Nong Muang",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15170,
    district_code: 1611,
    province_code: 7,
    name: "หนองม่วง หนองม่วง ลพบุรี 15170",
    name_th: "หนองม่วง หนองม่วง ลพบุรี 15170",
    name_en: "Nong Muang, Nong Muang, Loburi, 15170",
  },
  {
    id: 161102,
    districtTh: "หนองม่วง",
    districtEn: "Nong Muang",
    subdistrictTh: "บ่อทอง",
    subdistrictEn: "Bo Thong",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15170,
    district_code: 1611,
    province_code: 7,
    name: "หนองม่วง บ่อทอง ลพบุรี 15170",
    name_th: "หนองม่วง บ่อทอง ลพบุรี 15170",
    name_en: "Nong Muang, Bo Thong, Loburi, 15170",
  },
  {
    id: 161103,
    districtTh: "หนองม่วง",
    districtEn: "Nong Muang",
    subdistrictTh: "ดงดินแดง",
    subdistrictEn: "Dong Din Daeng",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15170,
    district_code: 1611,
    province_code: 7,
    name: "หนองม่วง ดงดินแดง ลพบุรี 15170",
    name_th: "หนองม่วง ดงดินแดง ลพบุรี 15170",
    name_en: "Nong Muang, Dong Din Daeng, Loburi, 15170",
  },
  {
    id: 161104,
    districtTh: "หนองม่วง",
    districtEn: "Nong Muang",
    subdistrictTh: "ชอนสมบูรณ์",
    subdistrictEn: "Chon Sombun",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15170,
    district_code: 1611,
    province_code: 7,
    name: "หนองม่วง ชอนสมบูรณ์ ลพบุรี 15170",
    name_th: "หนองม่วง ชอนสมบูรณ์ ลพบุรี 15170",
    name_en: "Nong Muang, Chon Sombun, Loburi, 15170",
  },
  {
    id: 161105,
    districtTh: "หนองม่วง",
    districtEn: "Nong Muang",
    subdistrictTh: "ยางโทน",
    subdistrictEn: "Yang Thon",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15170,
    district_code: 1611,
    province_code: 7,
    name: "หนองม่วง ยางโทน ลพบุรี 15170",
    name_th: "หนองม่วง ยางโทน ลพบุรี 15170",
    name_en: "Nong Muang, Yang Thon, Loburi, 15170",
  },
  {
    id: 161106,
    districtTh: "หนองม่วง",
    districtEn: "Nong Muang",
    subdistrictTh: "ชอนสารเดช",
    subdistrictEn: "Chon Saradet",
    provinceTh: "ลพบุรี",
    provinceEn: "Loburi",
    zipcode: 15170,
    district_code: 1611,
    province_code: 7,
    name: "หนองม่วง ชอนสารเดช ลพบุรี 15170",
    name_th: "หนองม่วง ชอนสารเดช ลพบุรี 15170",
    name_en: "Nong Muang, Chon Saradet, Loburi, 15170",
  },
  {
    id: 170101,
    districtTh: "เมืองสิงห์บุรี",
    districtEn: "Mueang Sing Buri",
    subdistrictTh: "บางพุทรา",
    subdistrictEn: "Bang Phutsa",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16000,
    district_code: 1701,
    province_code: 8,
    name: "เมืองสิงห์บุรี บางพุทรา สิงห์บุรี 16000",
    name_th: "เมืองสิงห์บุรี บางพุทรา สิงห์บุรี 16000",
    name_en: "Mueang Sing Buri, Bang Phutsa, Sing Buri, 16000",
  },
  {
    id: 170102,
    districtTh: "เมืองสิงห์บุรี",
    districtEn: "Mueang Sing Buri",
    subdistrictTh: "บางมัญ",
    subdistrictEn: "Bang Man",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16000,
    district_code: 1701,
    province_code: 8,
    name: "เมืองสิงห์บุรี บางมัญ สิงห์บุรี 16000",
    name_th: "เมืองสิงห์บุรี บางมัญ สิงห์บุรี 16000",
    name_en: "Mueang Sing Buri, Bang Man, Sing Buri, 16000",
  },
  {
    id: 170103,
    districtTh: "เมืองสิงห์บุรี",
    districtEn: "Mueang Sing Buri",
    subdistrictTh: "โพกรวม",
    subdistrictEn: "Phok Ruam",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16000,
    district_code: 1701,
    province_code: 8,
    name: "เมืองสิงห์บุรี โพกรวม สิงห์บุรี 16000",
    name_th: "เมืองสิงห์บุรี โพกรวม สิงห์บุรี 16000",
    name_en: "Mueang Sing Buri, Phok Ruam, Sing Buri, 16000",
  },
  {
    id: 170104,
    districtTh: "เมืองสิงห์บุรี",
    districtEn: "Mueang Sing Buri",
    subdistrictTh: "ม่วงหมู่",
    subdistrictEn: "Muang Mu",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16000,
    district_code: 1701,
    province_code: 8,
    name: "เมืองสิงห์บุรี ม่วงหมู่ สิงห์บุรี 16000",
    name_th: "เมืองสิงห์บุรี ม่วงหมู่ สิงห์บุรี 16000",
    name_en: "Mueang Sing Buri, Muang Mu, Sing Buri, 16000",
  },
  {
    id: 170105,
    districtTh: "เมืองสิงห์บุรี",
    districtEn: "Mueang Sing Buri",
    subdistrictTh: "หัวไผ่",
    subdistrictEn: "Hua Phai",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16000,
    district_code: 1701,
    province_code: 8,
    name: "เมืองสิงห์บุรี หัวไผ่ สิงห์บุรี 16000",
    name_th: "เมืองสิงห์บุรี หัวไผ่ สิงห์บุรี 16000",
    name_en: "Mueang Sing Buri, Hua Phai, Sing Buri, 16000",
  },
  {
    id: 170106,
    districtTh: "เมืองสิงห์บุรี",
    districtEn: "Mueang Sing Buri",
    subdistrictTh: "ต้นโพธิ์",
    subdistrictEn: "Ton Pho",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16000,
    district_code: 1701,
    province_code: 8,
    name: "เมืองสิงห์บุรี ต้นโพธิ์ สิงห์บุรี 16000",
    name_th: "เมืองสิงห์บุรี ต้นโพธิ์ สิงห์บุรี 16000",
    name_en: "Mueang Sing Buri, Ton Pho, Sing Buri, 16000",
  },
  {
    id: 170107,
    districtTh: "เมืองสิงห์บุรี",
    districtEn: "Mueang Sing Buri",
    subdistrictTh: "จักรสีห์",
    subdistrictEn: "Chaksi",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16000,
    district_code: 1701,
    province_code: 8,
    name: "เมืองสิงห์บุรี จักรสีห์ สิงห์บุรี 16000",
    name_th: "เมืองสิงห์บุรี จักรสีห์ สิงห์บุรี 16000",
    name_en: "Mueang Sing Buri, Chaksi, Sing Buri, 16000",
  },
  {
    id: 170108,
    districtTh: "เมืองสิงห์บุรี",
    districtEn: "Mueang Sing Buri",
    subdistrictTh: "บางกระบือ",
    subdistrictEn: "Bang Krabue",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16000,
    district_code: 1701,
    province_code: 8,
    name: "เมืองสิงห์บุรี บางกระบือ สิงห์บุรี 16000",
    name_th: "เมืองสิงห์บุรี บางกระบือ สิงห์บุรี 16000",
    name_en: "Mueang Sing Buri, Bang Krabue, Sing Buri, 16000",
  },
  {
    id: 170201,
    districtTh: "บางระจัน",
    districtEn: "Bang Rachan",
    subdistrictTh: "สิงห์",
    subdistrictEn: "Sing",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16130,
    district_code: 1702,
    province_code: 8,
    name: "บางระจัน สิงห์ สิงห์บุรี 16130",
    name_th: "บางระจัน สิงห์ สิงห์บุรี 16130",
    name_en: "Bang Rachan, Sing, Sing Buri, 16130",
  },
  {
    id: 170202,
    districtTh: "บางระจัน",
    districtEn: "Bang Rachan",
    subdistrictTh: "ไม้ดัด",
    subdistrictEn: "Mai Dat",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16130,
    district_code: 1702,
    province_code: 8,
    name: "บางระจัน ไม้ดัด สิงห์บุรี 16130",
    name_th: "บางระจัน ไม้ดัด สิงห์บุรี 16130",
    name_en: "Bang Rachan, Mai Dat, Sing Buri, 16130",
  },
  {
    id: 170203,
    districtTh: "บางระจัน",
    districtEn: "Bang Rachan",
    subdistrictTh: "เชิงกลัด",
    subdistrictEn: "Choeng Klat",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16130,
    district_code: 1702,
    province_code: 8,
    name: "บางระจัน เชิงกลัด สิงห์บุรี 16130",
    name_th: "บางระจัน เชิงกลัด สิงห์บุรี 16130",
    name_en: "Bang Rachan, Choeng Klat, Sing Buri, 16130",
  },
  {
    id: 170204,
    districtTh: "บางระจัน",
    districtEn: "Bang Rachan",
    subdistrictTh: "โพชนไก่",
    subdistrictEn: "Pho Chon Kai",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16130,
    district_code: 1702,
    province_code: 8,
    name: "บางระจัน โพชนไก่ สิงห์บุรี 16130",
    name_th: "บางระจัน โพชนไก่ สิงห์บุรี 16130",
    name_en: "Bang Rachan, Pho Chon Kai, Sing Buri, 16130",
  },
  {
    id: 170205,
    districtTh: "บางระจัน",
    districtEn: "Bang Rachan",
    subdistrictTh: "แม่ลา",
    subdistrictEn: "Mae La",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16130,
    district_code: 1702,
    province_code: 8,
    name: "บางระจัน แม่ลา สิงห์บุรี 16130",
    name_th: "บางระจัน แม่ลา สิงห์บุรี 16130",
    name_en: "Bang Rachan, Mae La, Sing Buri, 16130",
  },
  {
    id: 170206,
    districtTh: "บางระจัน",
    districtEn: "Bang Rachan",
    subdistrictTh: "บ้านจ่า",
    subdistrictEn: "Ban Cha",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16130,
    district_code: 1702,
    province_code: 8,
    name: "บางระจัน บ้านจ่า สิงห์บุรี 16130",
    name_th: "บางระจัน บ้านจ่า สิงห์บุรี 16130",
    name_en: "Bang Rachan, Ban Cha, Sing Buri, 16130",
  },
  {
    id: 170207,
    districtTh: "บางระจัน",
    districtEn: "Bang Rachan",
    subdistrictTh: "พักทัน",
    subdistrictEn: "Phak Than",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16130,
    district_code: 1702,
    province_code: 8,
    name: "บางระจัน พักทัน สิงห์บุรี 16130",
    name_th: "บางระจัน พักทัน สิงห์บุรี 16130",
    name_en: "Bang Rachan, Phak Than, Sing Buri, 16130",
  },
  {
    id: 170208,
    districtTh: "บางระจัน",
    districtEn: "Bang Rachan",
    subdistrictTh: "สระแจง",
    subdistrictEn: "Sa Chaeng",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16130,
    district_code: 1702,
    province_code: 8,
    name: "บางระจัน สระแจง สิงห์บุรี 16130",
    name_th: "บางระจัน สระแจง สิงห์บุรี 16130",
    name_en: "Bang Rachan, Sa Chaeng, Sing Buri, 16130",
  },
  {
    id: 170301,
    districtTh: "ค่ายบางระจัน",
    districtEn: "Khai Bang Rachan",
    subdistrictTh: "โพทะเล",
    subdistrictEn: "Pho Thale",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16150,
    district_code: 1703,
    province_code: 8,
    name: "ค่ายบางระจัน โพทะเล สิงห์บุรี 16150",
    name_th: "ค่ายบางระจัน โพทะเล สิงห์บุรี 16150",
    name_en: "Khai Bang Rachan, Pho Thale, Sing Buri, 16150",
  },
  {
    id: 170302,
    districtTh: "ค่ายบางระจัน",
    districtEn: "Khai Bang Rachan",
    subdistrictTh: "บางระจัน",
    subdistrictEn: "Bang Rachan",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16150,
    district_code: 1703,
    province_code: 8,
    name: "ค่ายบางระจัน บางระจัน สิงห์บุรี 16150",
    name_th: "ค่ายบางระจัน บางระจัน สิงห์บุรี 16150",
    name_en: "Khai Bang Rachan, Bang Rachan, Sing Buri, 16150",
  },
  {
    id: 170303,
    districtTh: "ค่ายบางระจัน",
    districtEn: "Khai Bang Rachan",
    subdistrictTh: "โพสังโฆ",
    subdistrictEn: "Pho Sangkho",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16150,
    district_code: 1703,
    province_code: 8,
    name: "ค่ายบางระจัน โพสังโฆ สิงห์บุรี 16150",
    name_th: "ค่ายบางระจัน โพสังโฆ สิงห์บุรี 16150",
    name_en: "Khai Bang Rachan, Pho Sangkho, Sing Buri, 16150",
  },
  {
    id: 170304,
    districtTh: "ค่ายบางระจัน",
    districtEn: "Khai Bang Rachan",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16150,
    district_code: 1703,
    province_code: 8,
    name: "ค่ายบางระจัน ท่าข้าม สิงห์บุรี 16150",
    name_th: "ค่ายบางระจัน ท่าข้าม สิงห์บุรี 16150",
    name_en: "Khai Bang Rachan, Tha Kham, Sing Buri, 16150",
  },
  {
    id: 170305,
    districtTh: "ค่ายบางระจัน",
    districtEn: "Khai Bang Rachan",
    subdistrictTh: "คอทราย",
    subdistrictEn: "Kho Sai",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16150,
    district_code: 1703,
    province_code: 8,
    name: "ค่ายบางระจัน คอทราย สิงห์บุรี 16150",
    name_th: "ค่ายบางระจัน คอทราย สิงห์บุรี 16150",
    name_en: "Khai Bang Rachan, Kho Sai, Sing Buri, 16150",
  },
  {
    id: 170306,
    districtTh: "ค่ายบางระจัน",
    districtEn: "Khai Bang Rachan",
    subdistrictTh: "หนองกระทุ่ม",
    subdistrictEn: "Nong Krathum",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16150,
    district_code: 1703,
    province_code: 8,
    name: "ค่ายบางระจัน หนองกระทุ่ม สิงห์บุรี 16150",
    name_th: "ค่ายบางระจัน หนองกระทุ่ม สิงห์บุรี 16150",
    name_en: "Khai Bang Rachan, Nong Krathum, Sing Buri, 16150",
  },
  {
    id: 170401,
    districtTh: "พรหมบุรี",
    districtEn: "Phrom Buri",
    subdistrictTh: "พระงาม",
    subdistrictEn: "Phra Ngam",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16120,
    district_code: 1704,
    province_code: 8,
    name: "พรหมบุรี พระงาม สิงห์บุรี 16120",
    name_th: "พรหมบุรี พระงาม สิงห์บุรี 16120",
    name_en: "Phrom Buri, Phra Ngam, Sing Buri, 16120",
  },
  {
    id: 170402,
    districtTh: "พรหมบุรี",
    districtEn: "Phrom Buri",
    subdistrictTh: "พรหมบุรี",
    subdistrictEn: "Phrom Buri",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16160,
    district_code: 1704,
    province_code: 8,
    name: "พรหมบุรี พรหมบุรี สิงห์บุรี 16160",
    name_th: "พรหมบุรี พรหมบุรี สิงห์บุรี 16160",
    name_en: "Phrom Buri, Phrom Buri, Sing Buri, 16160",
  },
  {
    id: 170403,
    districtTh: "พรหมบุรี",
    districtEn: "Phrom Buri",
    subdistrictTh: "บางน้ำเชี่ยว",
    subdistrictEn: "Bang Nam Chiao",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16120,
    district_code: 1704,
    province_code: 8,
    name: "พรหมบุรี บางน้ำเชี่ยว สิงห์บุรี 16120",
    name_th: "พรหมบุรี บางน้ำเชี่ยว สิงห์บุรี 16120",
    name_en: "Phrom Buri, Bang Nam Chiao, Sing Buri, 16120",
  },
  {
    id: 170404,
    districtTh: "พรหมบุรี",
    districtEn: "Phrom Buri",
    subdistrictTh: "บ้านหม้อ",
    subdistrictEn: "Ban Mo",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16120,
    district_code: 1704,
    province_code: 8,
    name: "พรหมบุรี บ้านหม้อ สิงห์บุรี 16120",
    name_th: "พรหมบุรี บ้านหม้อ สิงห์บุรี 16120",
    name_en: "Phrom Buri, Ban Mo, Sing Buri, 16120",
  },
  {
    id: 170405,
    districtTh: "พรหมบุรี",
    districtEn: "Phrom Buri",
    subdistrictTh: "บ้านแป้ง",
    subdistrictEn: "Ban Paeng",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16120,
    district_code: 1704,
    province_code: 8,
    name: "พรหมบุรี บ้านแป้ง สิงห์บุรี 16120",
    name_th: "พรหมบุรี บ้านแป้ง สิงห์บุรี 16120",
    name_en: "Phrom Buri, Ban Paeng, Sing Buri, 16120",
  },
  {
    id: 170406,
    districtTh: "พรหมบุรี",
    districtEn: "Phrom Buri",
    subdistrictTh: "หัวป่า",
    subdistrictEn: "Hua Pa",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16120,
    district_code: 1704,
    province_code: 8,
    name: "พรหมบุรี หัวป่า สิงห์บุรี 16120",
    name_th: "พรหมบุรี หัวป่า สิงห์บุรี 16120",
    name_en: "Phrom Buri, Hua Pa, Sing Buri, 16120",
  },
  {
    id: 170407,
    districtTh: "พรหมบุรี",
    districtEn: "Phrom Buri",
    subdistrictTh: "โรงช้าง",
    subdistrictEn: "Rong Chang",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16120,
    district_code: 1704,
    province_code: 8,
    name: "พรหมบุรี โรงช้าง สิงห์บุรี 16120",
    name_th: "พรหมบุรี โรงช้าง สิงห์บุรี 16120",
    name_en: "Phrom Buri, Rong Chang, Sing Buri, 16120",
  },
  {
    id: 170501,
    districtTh: "ท่าช้าง",
    districtEn: "Tha Chang",
    subdistrictTh: "ถอนสมอ",
    subdistrictEn: "Thon Samo",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16140,
    district_code: 1705,
    province_code: 8,
    name: "ท่าช้าง ถอนสมอ สิงห์บุรี 16140",
    name_th: "ท่าช้าง ถอนสมอ สิงห์บุรี 16140",
    name_en: "Tha Chang, Thon Samo, Sing Buri, 16140",
  },
  {
    id: 170502,
    districtTh: "ท่าช้าง",
    districtEn: "Tha Chang",
    subdistrictTh: "โพประจักษ์",
    subdistrictEn: "Pho Prachak",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16140,
    district_code: 1705,
    province_code: 8,
    name: "ท่าช้าง โพประจักษ์ สิงห์บุรี 16140",
    name_th: "ท่าช้าง โพประจักษ์ สิงห์บุรี 16140",
    name_en: "Tha Chang, Pho Prachak, Sing Buri, 16140",
  },
  {
    id: 170503,
    districtTh: "ท่าช้าง",
    districtEn: "Tha Chang",
    subdistrictTh: "วิหารขาว",
    subdistrictEn: "Wihan Khao",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16140,
    district_code: 1705,
    province_code: 8,
    name: "ท่าช้าง วิหารขาว สิงห์บุรี 16140",
    name_th: "ท่าช้าง วิหารขาว สิงห์บุรี 16140",
    name_en: "Tha Chang, Wihan Khao, Sing Buri, 16140",
  },
  {
    id: 170504,
    districtTh: "ท่าช้าง",
    districtEn: "Tha Chang",
    subdistrictTh: "พิกุลทอง",
    subdistrictEn: "Phikun Thong",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16140,
    district_code: 1705,
    province_code: 8,
    name: "ท่าช้าง พิกุลทอง สิงห์บุรี 16140",
    name_th: "ท่าช้าง พิกุลทอง สิงห์บุรี 16140",
    name_en: "Tha Chang, Phikun Thong, Sing Buri, 16140",
  },
  {
    id: 170601,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "อินทร์บุรี",
    subdistrictEn: "In Buri",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี อินทร์บุรี สิงห์บุรี 16110",
    name_th: "อินทร์บุรี อินทร์บุรี สิงห์บุรี 16110",
    name_en: "In Buri, In Buri, Sing Buri, 16110",
  },
  {
    id: 170602,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "ประศุก",
    subdistrictEn: "Prasuk",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี ประศุก สิงห์บุรี 16110",
    name_th: "อินทร์บุรี ประศุก สิงห์บุรี 16110",
    name_en: "In Buri, Prasuk, Sing Buri, 16110",
  },
  {
    id: 170603,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "ทับยา",
    subdistrictEn: "Thap Ya",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี ทับยา สิงห์บุรี 16110",
    name_th: "อินทร์บุรี ทับยา สิงห์บุรี 16110",
    name_en: "In Buri, Thap Ya, Sing Buri, 16110",
  },
  {
    id: 170604,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "งิ้วราย",
    subdistrictEn: "Ngio Rai",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี งิ้วราย สิงห์บุรี 16110",
    name_th: "อินทร์บุรี งิ้วราย สิงห์บุรี 16110",
    name_en: "In Buri, Ngio Rai, Sing Buri, 16110",
  },
  {
    id: 170605,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "ชีน้ำร้าย",
    subdistrictEn: "Chi Nam Rai",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี ชีน้ำร้าย สิงห์บุรี 16110",
    name_th: "อินทร์บุรี ชีน้ำร้าย สิงห์บุรี 16110",
    name_en: "In Buri, Chi Nam Rai, Sing Buri, 16110",
  },
  {
    id: 170606,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "ท่างาม",
    subdistrictEn: "Tha Ngam",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี ท่างาม สิงห์บุรี 16110",
    name_th: "อินทร์บุรี ท่างาม สิงห์บุรี 16110",
    name_en: "In Buri, Tha Ngam, Sing Buri, 16110",
  },
  {
    id: 170607,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "น้ำตาล",
    subdistrictEn: "Namtan",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี น้ำตาล สิงห์บุรี 16110",
    name_th: "อินทร์บุรี น้ำตาล สิงห์บุรี 16110",
    name_en: "In Buri, Namtan, Sing Buri, 16110",
  },
  {
    id: 170608,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "ทองเอน",
    subdistrictEn: "Thong En",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี ทองเอน สิงห์บุรี 16110",
    name_th: "อินทร์บุรี ทองเอน สิงห์บุรี 16110",
    name_en: "In Buri, Thong En, Sing Buri, 16110",
  },
  {
    id: 170609,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "ห้วยชัน",
    subdistrictEn: "Huai Chan",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี ห้วยชัน สิงห์บุรี 16110",
    name_th: "อินทร์บุรี ห้วยชัน สิงห์บุรี 16110",
    name_en: "In Buri, Huai Chan, Sing Buri, 16110",
  },
  {
    id: 170610,
    districtTh: "อินทร์บุรี",
    districtEn: "In Buri",
    subdistrictTh: "โพธิ์ชัย",
    subdistrictEn: "Pho Chai",
    provinceTh: "สิงห์บุรี",
    provinceEn: "Sing Buri",
    zipcode: 16110,
    district_code: 1706,
    province_code: 8,
    name: "อินทร์บุรี โพธิ์ชัย สิงห์บุรี 16110",
    name_th: "อินทร์บุรี โพธิ์ชัย สิงห์บุรี 16110",
    name_en: "In Buri, Pho Chai, Sing Buri, 16110",
  },
  {
    id: 180101,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท ในเมือง ชัยนาท 17000",
    name_th: "เมืองชัยนาท ในเมือง ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Nai Mueang, Chai Nat, 17000",
  },
  {
    id: 180102,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "บ้านกล้วย",
    subdistrictEn: "Ban Kluai",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท บ้านกล้วย ชัยนาท 17000",
    name_th: "เมืองชัยนาท บ้านกล้วย ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Ban Kluai, Chai Nat, 17000",
  },
  {
    id: 180103,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "ท่าชัย",
    subdistrictEn: "Tha Chai",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท ท่าชัย ชัยนาท 17000",
    name_th: "เมืองชัยนาท ท่าชัย ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Tha Chai, Chai Nat, 17000",
  },
  {
    id: 180104,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "ชัยนาท",
    subdistrictEn: "Chai Nat",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท ชัยนาท ชัยนาท 17000",
    name_th: "เมืองชัยนาท ชัยนาท ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Chai Nat, Chai Nat, 17000",
  },
  {
    id: 180105,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "เขาท่าพระ",
    subdistrictEn: "Khao Tha Phra",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท เขาท่าพระ ชัยนาท 17000",
    name_th: "เมืองชัยนาท เขาท่าพระ ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Khao Tha Phra, Chai Nat, 17000",
  },
  {
    id: 180106,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "หาดท่าเสา",
    subdistrictEn: "Hat Tha Sao",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท หาดท่าเสา ชัยนาท 17000",
    name_th: "เมืองชัยนาท หาดท่าเสา ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Hat Tha Sao, Chai Nat, 17000",
  },
  {
    id: 180107,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "ธรรมามูล",
    subdistrictEn: "Thammamun",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท ธรรมามูล ชัยนาท 17000",
    name_th: "เมืองชัยนาท ธรรมามูล ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Thammamun, Chai Nat, 17000",
  },
  {
    id: 180108,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "เสือโฮก",
    subdistrictEn: "Suea Hok",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท เสือโฮก ชัยนาท 17000",
    name_th: "เมืองชัยนาท เสือโฮก ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Suea Hok, Chai Nat, 17000",
  },
  {
    id: 180109,
    districtTh: "เมืองชัยนาท",
    districtEn: "Mueang Chai Nat",
    subdistrictTh: "นางลือ",
    subdistrictEn: "Nang Lue",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17000,
    district_code: 1801,
    province_code: 9,
    name: "เมืองชัยนาท นางลือ ชัยนาท 17000",
    name_th: "เมืองชัยนาท นางลือ ชัยนาท 17000",
    name_en: "Mueang Chai Nat, Nang Lue, Chai Nat, 17000",
  },
  {
    id: 180201,
    districtTh: "มโนรมย์",
    districtEn: "Manorom",
    subdistrictTh: "คุ้งสำเภา",
    subdistrictEn: "Khung Samphao",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17110,
    district_code: 1802,
    province_code: 9,
    name: "มโนรมย์ คุ้งสำเภา ชัยนาท 17110",
    name_th: "มโนรมย์ คุ้งสำเภา ชัยนาท 17110",
    name_en: "Manorom, Khung Samphao, Chai Nat, 17110",
  },
  {
    id: 180202,
    districtTh: "มโนรมย์",
    districtEn: "Manorom",
    subdistrictTh: "วัดโคก",
    subdistrictEn: "Wat Khok",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17110,
    district_code: 1802,
    province_code: 9,
    name: "มโนรมย์ วัดโคก ชัยนาท 17110",
    name_th: "มโนรมย์ วัดโคก ชัยนาท 17110",
    name_en: "Manorom, Wat Khok, Chai Nat, 17110",
  },
  {
    id: 180203,
    districtTh: "มโนรมย์",
    districtEn: "Manorom",
    subdistrictTh: "ศิลาดาน",
    subdistrictEn: "Sila Dan",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17110,
    district_code: 1802,
    province_code: 9,
    name: "มโนรมย์ ศิลาดาน ชัยนาท 17110",
    name_th: "มโนรมย์ ศิลาดาน ชัยนาท 17110",
    name_en: "Manorom, Sila Dan, Chai Nat, 17110",
  },
  {
    id: 180204,
    districtTh: "มโนรมย์",
    districtEn: "Manorom",
    subdistrictTh: "ท่าฉนวน",
    subdistrictEn: "Tha Chanuan",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17110,
    district_code: 1802,
    province_code: 9,
    name: "มโนรมย์ ท่าฉนวน ชัยนาท 17110",
    name_th: "มโนรมย์ ท่าฉนวน ชัยนาท 17110",
    name_en: "Manorom, Tha Chanuan, Chai Nat, 17110",
  },
  {
    id: 180205,
    districtTh: "มโนรมย์",
    districtEn: "Manorom",
    subdistrictTh: "หางน้ำสาคร",
    subdistrictEn: "Hang Nam Sakhon",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17170,
    district_code: 1802,
    province_code: 9,
    name: "มโนรมย์ หางน้ำสาคร ชัยนาท 17170",
    name_th: "มโนรมย์ หางน้ำสาคร ชัยนาท 17170",
    name_en: "Manorom, Hang Nam Sakhon, Chai Nat, 17170",
  },
  {
    id: 180206,
    districtTh: "มโนรมย์",
    districtEn: "Manorom",
    subdistrictTh: "ไร่พัฒนา",
    subdistrictEn: "Rai Phatthana",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17170,
    district_code: 1802,
    province_code: 9,
    name: "มโนรมย์ ไร่พัฒนา ชัยนาท 17170",
    name_th: "มโนรมย์ ไร่พัฒนา ชัยนาท 17170",
    name_en: "Manorom, Rai Phatthana, Chai Nat, 17170",
  },
  {
    id: 180207,
    districtTh: "มโนรมย์",
    districtEn: "Manorom",
    subdistrictTh: "อู่ตะเภา",
    subdistrictEn: "U Taphao",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17170,
    district_code: 1802,
    province_code: 9,
    name: "มโนรมย์ อู่ตะเภา ชัยนาท 17170",
    name_th: "มโนรมย์ อู่ตะเภา ชัยนาท 17170",
    name_en: "Manorom, U Taphao, Chai Nat, 17170",
  },
  {
    id: 180301,
    districtTh: "วัดสิงห์",
    districtEn: "Wat Sing",
    subdistrictTh: "วัดสิงห์",
    subdistrictEn: "Wat Sing",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1803,
    province_code: 9,
    name: "วัดสิงห์ วัดสิงห์ ชัยนาท 17120",
    name_th: "วัดสิงห์ วัดสิงห์ ชัยนาท 17120",
    name_en: "Wat Sing, Wat Sing, Chai Nat, 17120",
  },
  {
    id: 180302,
    districtTh: "วัดสิงห์",
    districtEn: "Wat Sing",
    subdistrictTh: "มะขามเฒ่า",
    subdistrictEn: "Makham Thao",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1803,
    province_code: 9,
    name: "วัดสิงห์ มะขามเฒ่า ชัยนาท 17120",
    name_th: "วัดสิงห์ มะขามเฒ่า ชัยนาท 17120",
    name_en: "Wat Sing, Makham Thao, Chai Nat, 17120",
  },
  {
    id: 180303,
    districtTh: "วัดสิงห์",
    districtEn: "Wat Sing",
    subdistrictTh: "หนองน้อย",
    subdistrictEn: "Nong Noi",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1803,
    province_code: 9,
    name: "วัดสิงห์ หนองน้อย ชัยนาท 17120",
    name_th: "วัดสิงห์ หนองน้อย ชัยนาท 17120",
    name_en: "Wat Sing, Nong Noi, Chai Nat, 17120",
  },
  {
    id: 180304,
    districtTh: "วัดสิงห์",
    districtEn: "Wat Sing",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1803,
    province_code: 9,
    name: "วัดสิงห์ หนองบัว ชัยนาท 17120",
    name_th: "วัดสิงห์ หนองบัว ชัยนาท 17120",
    name_en: "Wat Sing, Nong Bua, Chai Nat, 17120",
  },
  {
    id: 180306,
    districtTh: "วัดสิงห์",
    districtEn: "Wat Sing",
    subdistrictTh: "หนองขุ่น",
    subdistrictEn: "Bo Rae",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1803,
    province_code: 9,
    name: "วัดสิงห์ หนองขุ่น ชัยนาท 17120",
    name_th: "วัดสิงห์ หนองขุ่น ชัยนาท 17120",
    name_en: "Wat Sing, Bo Rae, Chai Nat, 17120",
  },
  {
    id: 180307,
    districtTh: "วัดสิงห์",
    districtEn: "Wat Sing",
    subdistrictTh: "บ่อแร่",
    subdistrictEn: "Wang Man",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1803,
    province_code: 9,
    name: "วัดสิงห์ บ่อแร่ ชัยนาท 17120",
    name_th: "วัดสิงห์ บ่อแร่ ชัยนาท 17120",
    name_en: "Wat Sing, Wang Man, Chai Nat, 17120",
  },
  {
    id: 180311,
    districtTh: "วัดสิงห์",
    districtEn: "Wat Sing",
    subdistrictTh: "วังหมัน",
    subdistrictEn: "Wang Man",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1803,
    province_code: 9,
    name: "วัดสิงห์ วังหมัน ชัยนาท 17120",
    name_th: "วัดสิงห์ วังหมัน ชัยนาท 17120",
    name_en: "Wat Sing, Wang Man, Chai Nat, 17120",
  },
  {
    id: 180401,
    districtTh: "สรรพยา",
    districtEn: "Sapphaya",
    subdistrictTh: "สรรพยา",
    subdistrictEn: "Sapphaya",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17150,
    district_code: 1804,
    province_code: 9,
    name: "สรรพยา สรรพยา ชัยนาท 17150",
    name_th: "สรรพยา สรรพยา ชัยนาท 17150",
    name_en: "Sapphaya, Sapphaya, Chai Nat, 17150",
  },
  {
    id: 180402,
    districtTh: "สรรพยา",
    districtEn: "Sapphaya",
    subdistrictTh: "ตลุก",
    subdistrictEn: "Taluk",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17150,
    district_code: 1804,
    province_code: 9,
    name: "สรรพยา ตลุก ชัยนาท 17150",
    name_th: "สรรพยา ตลุก ชัยนาท 17150",
    name_en: "Sapphaya, Taluk, Chai Nat, 17150",
  },
  {
    id: 180403,
    districtTh: "สรรพยา",
    districtEn: "Sapphaya",
    subdistrictTh: "เขาแก้ว",
    subdistrictEn: "Khao Kaeo",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17150,
    district_code: 1804,
    province_code: 9,
    name: "สรรพยา เขาแก้ว ชัยนาท 17150",
    name_th: "สรรพยา เขาแก้ว ชัยนาท 17150",
    name_en: "Sapphaya, Khao Kaeo, Chai Nat, 17150",
  },
  {
    id: 180404,
    districtTh: "สรรพยา",
    districtEn: "Sapphaya",
    subdistrictTh: "โพนางดำตก",
    subdistrictEn: "Pho Nang Dam Tok",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17150,
    district_code: 1804,
    province_code: 9,
    name: "สรรพยา โพนางดำตก ชัยนาท 17150",
    name_th: "สรรพยา โพนางดำตก ชัยนาท 17150",
    name_en: "Sapphaya, Pho Nang Dam Tok, Chai Nat, 17150",
  },
  {
    id: 180405,
    districtTh: "สรรพยา",
    districtEn: "Sapphaya",
    subdistrictTh: "โพนางดำออก",
    subdistrictEn: "Pho Nang Dam Ok",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17150,
    district_code: 1804,
    province_code: 9,
    name: "สรรพยา โพนางดำออก ชัยนาท 17150",
    name_th: "สรรพยา โพนางดำออก ชัยนาท 17150",
    name_en: "Sapphaya, Pho Nang Dam Ok, Chai Nat, 17150",
  },
  {
    id: 180406,
    districtTh: "สรรพยา",
    districtEn: "Sapphaya",
    subdistrictTh: "บางหลวง",
    subdistrictEn: "Bang Luang",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17150,
    district_code: 1804,
    province_code: 9,
    name: "สรรพยา บางหลวง ชัยนาท 17150",
    name_th: "สรรพยา บางหลวง ชัยนาท 17150",
    name_en: "Sapphaya, Bang Luang, Chai Nat, 17150",
  },
  {
    id: 180407,
    districtTh: "สรรพยา",
    districtEn: "Sapphaya",
    subdistrictTh: "หาดอาษา",
    subdistrictEn: "Hat Asa",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17150,
    district_code: 1804,
    province_code: 9,
    name: "สรรพยา หาดอาษา ชัยนาท 17150",
    name_th: "สรรพยา หาดอาษา ชัยนาท 17150",
    name_en: "Sapphaya, Hat Asa, Chai Nat, 17150",
  },
  {
    id: 180501,
    districtTh: "สรรคบุรี",
    districtEn: "Sankhaburi",
    subdistrictTh: "แพรกศรีราชา",
    subdistrictEn: "Phraek Si Racha",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17140,
    district_code: 1805,
    province_code: 9,
    name: "สรรคบุรี แพรกศรีราชา ชัยนาท 17140",
    name_th: "สรรคบุรี แพรกศรีราชา ชัยนาท 17140",
    name_en: "Sankhaburi, Phraek Si Racha, Chai Nat, 17140",
  },
  {
    id: 180502,
    districtTh: "สรรคบุรี",
    districtEn: "Sankhaburi",
    subdistrictTh: "เที่ยงแท้",
    subdistrictEn: "Thiang Thae",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17140,
    district_code: 1805,
    province_code: 9,
    name: "สรรคบุรี เที่ยงแท้ ชัยนาท 17140",
    name_th: "สรรคบุรี เที่ยงแท้ ชัยนาท 17140",
    name_en: "Sankhaburi, Thiang Thae, Chai Nat, 17140",
  },
  {
    id: 180503,
    districtTh: "สรรคบุรี",
    districtEn: "Sankhaburi",
    subdistrictTh: "ห้วยกรด",
    subdistrictEn: "Huai Krot",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17140,
    district_code: 1805,
    province_code: 9,
    name: "สรรคบุรี ห้วยกรด ชัยนาท 17140",
    name_th: "สรรคบุรี ห้วยกรด ชัยนาท 17140",
    name_en: "Sankhaburi, Huai Krot, Chai Nat, 17140",
  },
  {
    id: 180504,
    districtTh: "สรรคบุรี",
    districtEn: "Sankhaburi",
    subdistrictTh: "โพงาม",
    subdistrictEn: "Pho Ngam",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17140,
    district_code: 1805,
    province_code: 9,
    name: "สรรคบุรี โพงาม ชัยนาท 17140",
    name_th: "สรรคบุรี โพงาม ชัยนาท 17140",
    name_en: "Sankhaburi, Pho Ngam, Chai Nat, 17140",
  },
  {
    id: 180505,
    districtTh: "สรรคบุรี",
    districtEn: "Sankhaburi",
    subdistrictTh: "บางขุด",
    subdistrictEn: "Bang Khut",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17140,
    district_code: 1805,
    province_code: 9,
    name: "สรรคบุรี บางขุด ชัยนาท 17140",
    name_th: "สรรคบุรี บางขุด ชัยนาท 17140",
    name_en: "Sankhaburi, Bang Khut, Chai Nat, 17140",
  },
  {
    id: 180506,
    districtTh: "สรรคบุรี",
    districtEn: "Sankhaburi",
    subdistrictTh: "ดงคอน",
    subdistrictEn: "Dong Khon",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17140,
    district_code: 1805,
    province_code: 9,
    name: "สรรคบุรี ดงคอน ชัยนาท 17140",
    name_th: "สรรคบุรี ดงคอน ชัยนาท 17140",
    name_en: "Sankhaburi, Dong Khon, Chai Nat, 17140",
  },
  {
    id: 180507,
    districtTh: "สรรคบุรี",
    districtEn: "Sankhaburi",
    subdistrictTh: "ดอนกำ",
    subdistrictEn: "Don Kam",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17140,
    district_code: 1805,
    province_code: 9,
    name: "สรรคบุรี ดอนกำ ชัยนาท 17140",
    name_th: "สรรคบุรี ดอนกำ ชัยนาท 17140",
    name_en: "Sankhaburi, Don Kam, Chai Nat, 17140",
  },
  {
    id: 180508,
    districtTh: "สรรคบุรี",
    districtEn: "Sankhaburi",
    subdistrictTh: "ห้วยกรดพัฒนา",
    subdistrictEn: "Huai Krot Phatthana",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17140,
    district_code: 1805,
    province_code: 9,
    name: "สรรคบุรี ห้วยกรดพัฒนา ชัยนาท 17140",
    name_th: "สรรคบุรี ห้วยกรดพัฒนา ชัยนาท 17140",
    name_en: "Sankhaburi, Huai Krot Phatthana, Chai Nat, 17140",
  },
  {
    id: 180601,
    districtTh: "หันคา",
    districtEn: "Hankha",
    subdistrictTh: "หันคา",
    subdistrictEn: "Hankha",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17130,
    district_code: 1806,
    province_code: 9,
    name: "หันคา หันคา ชัยนาท 17130",
    name_th: "หันคา หันคา ชัยนาท 17130",
    name_en: "Hankha, Hankha, Chai Nat, 17130",
  },
  {
    id: 180602,
    districtTh: "หันคา",
    districtEn: "Hankha",
    subdistrictTh: "บ้านเชี่ยน",
    subdistrictEn: "Ban Chian",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17130,
    district_code: 1806,
    province_code: 9,
    name: "หันคา บ้านเชี่ยน ชัยนาท 17130",
    name_th: "หันคา บ้านเชี่ยน ชัยนาท 17130",
    name_en: "Hankha, Ban Chian, Chai Nat, 17130",
  },
  {
    id: 180605,
    districtTh: "หันคา",
    districtEn: "Hankha",
    subdistrictTh: "ไพรนกยูง",
    subdistrictEn: "Phrai Nok Yung",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17130,
    district_code: 1806,
    province_code: 9,
    name: "หันคา ไพรนกยูง ชัยนาท 17130",
    name_th: "หันคา ไพรนกยูง ชัยนาท 17130",
    name_en: "Hankha, Phrai Nok Yung, Chai Nat, 17130",
  },
  {
    id: 180606,
    districtTh: "หันคา",
    districtEn: "Hankha",
    subdistrictTh: "หนองแซง",
    subdistrictEn: "Nong Saeng",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17160,
    district_code: 1806,
    province_code: 9,
    name: "หันคา หนองแซง ชัยนาท 17160",
    name_th: "หันคา หนองแซง ชัยนาท 17160",
    name_en: "Hankha, Nong Saeng, Chai Nat, 17160",
  },
  {
    id: 180607,
    districtTh: "หันคา",
    districtEn: "Hankha",
    subdistrictTh: "ห้วยงู",
    subdistrictEn: "Huai Ngu",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17160,
    district_code: 1806,
    province_code: 9,
    name: "หันคา ห้วยงู ชัยนาท 17160",
    name_th: "หันคา ห้วยงู ชัยนาท 17160",
    name_en: "Hankha, Huai Ngu, Chai Nat, 17160",
  },
  {
    id: 180608,
    districtTh: "หันคา",
    districtEn: "Hankha",
    subdistrictTh: "วังไก่เถื่อน",
    subdistrictEn: "Wang Kai Thuean",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17130,
    district_code: 1806,
    province_code: 9,
    name: "หันคา วังไก่เถื่อน ชัยนาท 17130",
    name_th: "หันคา วังไก่เถื่อน ชัยนาท 17130",
    name_en: "Hankha, Wang Kai Thuean, Chai Nat, 17130",
  },
  {
    id: 180609,
    districtTh: "หันคา",
    districtEn: "Hankha",
    subdistrictTh: "เด่นใหญ่",
    subdistrictEn: "Den Yai",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17130,
    district_code: 1806,
    province_code: 9,
    name: "หันคา เด่นใหญ่ ชัยนาท 17130",
    name_th: "หันคา เด่นใหญ่ ชัยนาท 17130",
    name_en: "Hankha, Den Yai, Chai Nat, 17130",
  },
  {
    id: 180611,
    districtTh: "หันคา",
    districtEn: "Hankha",
    subdistrictTh: "สามง่ามท่าโบสถ์",
    subdistrictEn: "Sam Ngam Tha Bot",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17160,
    district_code: 1806,
    province_code: 9,
    name: "หันคา สามง่ามท่าโบสถ์ ชัยนาท 17160",
    name_th: "หันคา สามง่ามท่าโบสถ์ ชัยนาท 17160",
    name_en: "Hankha, Sam Ngam Tha Bot, Chai Nat, 17160",
  },
  {
    id: 180701,
    districtTh: "หนองมะโมง",
    districtEn: "Nong Mamong",
    subdistrictTh: "หนองมะโมง",
    subdistrictEn: "Nong Mamong",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1807,
    province_code: 9,
    name: "หนองมะโมง หนองมะโมง ชัยนาท 17120",
    name_th: "หนองมะโมง หนองมะโมง ชัยนาท 17120",
    name_en: "Nong Mamong, Nong Mamong, Chai Nat, 17120",
  },
  {
    id: 180702,
    districtTh: "หนองมะโมง",
    districtEn: "Nong Mamong",
    subdistrictTh: "วังตะเคียน",
    subdistrictEn: "Wang Takhian",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1807,
    province_code: 9,
    name: "หนองมะโมง วังตะเคียน ชัยนาท 17120",
    name_th: "หนองมะโมง วังตะเคียน ชัยนาท 17120",
    name_en: "Nong Mamong, Wang Takhian, Chai Nat, 17120",
  },
  {
    id: 180703,
    districtTh: "หนองมะโมง",
    districtEn: "Nong Mamong",
    subdistrictTh: "สะพานหิน",
    subdistrictEn: "Saphan Hin",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1807,
    province_code: 9,
    name: "หนองมะโมง สะพานหิน ชัยนาท 17120",
    name_th: "หนองมะโมง สะพานหิน ชัยนาท 17120",
    name_en: "Nong Mamong, Saphan Hin, Chai Nat, 17120",
  },
  {
    id: 180704,
    districtTh: "หนองมะโมง",
    districtEn: "Nong Mamong",
    subdistrictTh: "กุดจอก",
    subdistrictEn: "Kut Chok",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17120,
    district_code: 1807,
    province_code: 9,
    name: "หนองมะโมง กุดจอก ชัยนาท 17120",
    name_th: "หนองมะโมง กุดจอก ชัยนาท 17120",
    name_en: "Nong Mamong, Kut Chok, Chai Nat, 17120",
  },
  {
    id: 180801,
    districtTh: "เนินขาม",
    districtEn: "Noen Kham",
    subdistrictTh: "เนินขาม",
    subdistrictEn: "Noen Kham",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17130,
    district_code: 1808,
    province_code: 9,
    name: "เนินขาม เนินขาม ชัยนาท 17130",
    name_th: "เนินขาม เนินขาม ชัยนาท 17130",
    name_en: "Noen Kham, Noen Kham, Chai Nat, 17130",
  },
  {
    id: 180802,
    districtTh: "เนินขาม",
    districtEn: "Noen Kham",
    subdistrictTh: "กะบกเตี้ย",
    subdistrictEn: "Kabok Tia",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17130,
    district_code: 1808,
    province_code: 9,
    name: "เนินขาม กะบกเตี้ย ชัยนาท 17130",
    name_th: "เนินขาม กะบกเตี้ย ชัยนาท 17130",
    name_en: "Noen Kham, Kabok Tia, Chai Nat, 17130",
  },
  {
    id: 180803,
    districtTh: "เนินขาม",
    districtEn: "Noen Kham",
    subdistrictTh: "สุขเดือนห้า",
    subdistrictEn: "Suk Duean Ha",
    provinceTh: "ชัยนาท",
    provinceEn: "Chai Nat",
    zipcode: 17130,
    district_code: 1808,
    province_code: 9,
    name: "เนินขาม สุขเดือนห้า ชัยนาท 17130",
    name_th: "เนินขาม สุขเดือนห้า ชัยนาท 17130",
    name_en: "Noen Kham, Suk Duean Ha, Chai Nat, 17130",
  },
  {
    id: 190101,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "ปากเพรียว",
    subdistrictEn: "Pak Phriao",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี ปากเพรียว สระบุรี 18000",
    name_th: "เมืองสระบุรี ปากเพรียว สระบุรี 18000",
    name_en: "Mueang Saraburi, Pak Phriao, Saraburi, 18000",
  },
  {
    id: 190105,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "ดาวเรือง",
    subdistrictEn: "Dao Rueang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี ดาวเรือง สระบุรี 18000",
    name_th: "เมืองสระบุรี ดาวเรือง สระบุรี 18000",
    name_en: "Mueang Saraburi, Dao Rueang, Saraburi, 18000",
  },
  {
    id: 190106,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "นาโฉง",
    subdistrictEn: "Na Chong",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี นาโฉง สระบุรี 18000",
    name_th: "เมืองสระบุรี นาโฉง สระบุรี 18000",
    name_en: "Mueang Saraburi, Na Chong, Saraburi, 18000",
  },
  {
    id: 190107,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "โคกสว่าง",
    subdistrictEn: "Khok Sawang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี โคกสว่าง สระบุรี 18000",
    name_th: "เมืองสระบุรี โคกสว่าง สระบุรี 18000",
    name_en: "Mueang Saraburi, Khok Sawang, Saraburi, 18000",
  },
  {
    id: 190108,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "หนองโน",
    subdistrictEn: "Nong No",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี หนองโน สระบุรี 18000",
    name_th: "เมืองสระบุรี หนองโน สระบุรี 18000",
    name_en: "Mueang Saraburi, Nong No, Saraburi, 18000",
  },
  {
    id: 190109,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "หนองยาว",
    subdistrictEn: "Nong Yao",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี หนองยาว สระบุรี 18000",
    name_th: "เมืองสระบุรี หนองยาว สระบุรี 18000",
    name_en: "Mueang Saraburi, Nong Yao, Saraburi, 18000",
  },
  {
    id: 190110,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "ปากข้าวสาร",
    subdistrictEn: "Pak Khao San",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี ปากข้าวสาร สระบุรี 18000",
    name_th: "เมืองสระบุรี ปากข้าวสาร สระบุรี 18000",
    name_en: "Mueang Saraburi, Pak Khao San, Saraburi, 18000",
  },
  {
    id: 190111,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "หนองปลาไหล",
    subdistrictEn: "Nong Pla Lai",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี หนองปลาไหล สระบุรี 18000",
    name_th: "เมืองสระบุรี หนองปลาไหล สระบุรี 18000",
    name_en: "Mueang Saraburi, Nong Pla Lai, Saraburi, 18000",
  },
  {
    id: 190112,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "กุดนกเปล้า",
    subdistrictEn: "Kut Nok Plao",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี กุดนกเปล้า สระบุรี 18000",
    name_th: "เมืองสระบุรี กุดนกเปล้า สระบุรี 18000",
    name_en: "Mueang Saraburi, Kut Nok Plao, Saraburi, 18000",
  },
  {
    id: 190113,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี ตลิ่งชัน สระบุรี 18000",
    name_th: "เมืองสระบุรี ตลิ่งชัน สระบุรี 18000",
    name_en: "Mueang Saraburi, Taling Chan, Saraburi, 18000",
  },
  {
    id: 190114,
    districtTh: "เมืองสระบุรี",
    districtEn: "Mueang Saraburi",
    subdistrictTh: "ตะกุด",
    subdistrictEn: "Takut",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1901,
    province_code: 10,
    name: "เมืองสระบุรี ตะกุด สระบุรี 18000",
    name_th: "เมืองสระบุรี ตะกุด สระบุรี 18000",
    name_en: "Mueang Saraburi, Takut, Saraburi, 18000",
  },
  {
    id: 190201,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "แก่งคอย",
    subdistrictEn: "Kaeng Khoi",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย แก่งคอย สระบุรี 18110",
    name_th: "แก่งคอย แก่งคอย สระบุรี 18110",
    name_en: "Kaeng Khoi, Kaeng Khoi, Saraburi, 18110",
  },
  {
    id: 190202,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "ทับกวาง",
    subdistrictEn: "Thap Kwang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18260,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย ทับกวาง สระบุรี 18260",
    name_th: "แก่งคอย ทับกวาง สระบุรี 18260",
    name_en: "Kaeng Khoi, Thap Kwang, Saraburi, 18260",
  },
  {
    id: 190203,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "ตาลเดี่ยว",
    subdistrictEn: "Tan Diao",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย ตาลเดี่ยว สระบุรี 18110",
    name_th: "แก่งคอย ตาลเดี่ยว สระบุรี 18110",
    name_en: "Kaeng Khoi, Tan Diao, Saraburi, 18110",
  },
  {
    id: 190204,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "ห้วยแห้ง",
    subdistrictEn: "Huai Haeng",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย ห้วยแห้ง สระบุรี 18110",
    name_th: "แก่งคอย ห้วยแห้ง สระบุรี 18110",
    name_en: "Kaeng Khoi, Huai Haeng, Saraburi, 18110",
  },
  {
    id: 190205,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "ท่าคล้อ",
    subdistrictEn: "Tha Khlo",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย ท่าคล้อ สระบุรี 18110",
    name_th: "แก่งคอย ท่าคล้อ สระบุรี 18110",
    name_en: "Kaeng Khoi, Tha Khlo, Saraburi, 18110",
  },
  {
    id: 190206,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "หินซ้อน",
    subdistrictEn: "Hin Son",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย หินซ้อน สระบุรี 18110",
    name_th: "แก่งคอย หินซ้อน สระบุรี 18110",
    name_en: "Kaeng Khoi, Hin Son, Saraburi, 18110",
  },
  {
    id: 190207,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "บ้านธาตุ",
    subdistrictEn: "Ban That",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย บ้านธาตุ สระบุรี 18110",
    name_th: "แก่งคอย บ้านธาตุ สระบุรี 18110",
    name_en: "Kaeng Khoi, Ban That, Saraburi, 18110",
  },
  {
    id: 190208,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "บ้านป่า",
    subdistrictEn: "Ban Pa",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย บ้านป่า สระบุรี 18110",
    name_th: "แก่งคอย บ้านป่า สระบุรี 18110",
    name_en: "Kaeng Khoi, Ban Pa, Saraburi, 18110",
  },
  {
    id: 190209,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "ท่าตูม",
    subdistrictEn: "Tha Tum",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย ท่าตูม สระบุรี 18110",
    name_th: "แก่งคอย ท่าตูม สระบุรี 18110",
    name_en: "Kaeng Khoi, Tha Tum, Saraburi, 18110",
  },
  {
    id: 190210,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "ชะอม",
    subdistrictEn: "Cha-om",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย ชะอม สระบุรี 18110",
    name_th: "แก่งคอย ชะอม สระบุรี 18110",
    name_en: "Kaeng Khoi, Cha-om, Saraburi, 18110",
  },
  {
    id: 190211,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "สองคอน",
    subdistrictEn: "Song Khon",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย สองคอน สระบุรี 18110",
    name_th: "แก่งคอย สองคอน สระบุรี 18110",
    name_en: "Kaeng Khoi, Song Khon, Saraburi, 18110",
  },
  {
    id: 190212,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "เตาปูน",
    subdistrictEn: "Tao Pun",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย เตาปูน สระบุรี 18110",
    name_th: "แก่งคอย เตาปูน สระบุรี 18110",
    name_en: "Kaeng Khoi, Tao Pun, Saraburi, 18110",
  },
  {
    id: 190213,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "ชำผักแพว",
    subdistrictEn: "Cham Phak Phaeo",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย ชำผักแพว สระบุรี 18110",
    name_th: "แก่งคอย ชำผักแพว สระบุรี 18110",
    name_en: "Kaeng Khoi, Cham Phak Phaeo, Saraburi, 18110",
  },
  {
    id: 190215,
    districtTh: "แก่งคอย",
    districtEn: "Kaeng Khoi",
    subdistrictTh: "ท่ามะปราง",
    subdistrictEn: "Tha Maprang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18110,
    district_code: 1902,
    province_code: 10,
    name: "แก่งคอย ท่ามะปราง สระบุรี 18110",
    name_th: "แก่งคอย ท่ามะปราง สระบุรี 18110",
    name_en: "Kaeng Khoi, Tha Maprang, Saraburi, 18110",
  },
  {
    id: 190301,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองแค",
    subdistrictEn: "Nong Khae",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองแค สระบุรี 18140",
    name_th: "หนองแค หนองแค สระบุรี 18140",
    name_en: "Nong Khae, Nong Khae, Saraburi, 18140",
  },
  {
    id: 190302,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "กุ่มหัก",
    subdistrictEn: "Kum Hak",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค กุ่มหัก สระบุรี 18140",
    name_th: "หนองแค กุ่มหัก สระบุรี 18140",
    name_en: "Nong Khae, Kum Hak, Saraburi, 18140",
  },
  {
    id: 190303,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "คชสิทธิ์",
    subdistrictEn: "Khotchasit",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18250,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค คชสิทธิ์ สระบุรี 18250",
    name_th: "หนองแค คชสิทธิ์ สระบุรี 18250",
    name_en: "Nong Khae, Khotchasit, Saraburi, 18250",
  },
  {
    id: 190304,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "โคกตูม",
    subdistrictEn: "Khok Tum",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18250,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค โคกตูม สระบุรี 18250",
    name_th: "หนองแค โคกตูม สระบุรี 18250",
    name_en: "Nong Khae, Khok Tum, Saraburi, 18250",
  },
  {
    id: 190305,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "โคกแย้",
    subdistrictEn: "Khok Yae",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18230,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค โคกแย้ สระบุรี 18230",
    name_th: "หนองแค โคกแย้ สระบุรี 18230",
    name_en: "Nong Khae, Khok Yae, Saraburi, 18230",
  },
  {
    id: 190306,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "บัวลอย",
    subdistrictEn: "Bua Loi",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18230,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค บัวลอย สระบุรี 18230",
    name_th: "หนองแค บัวลอย สระบุรี 18230",
    name_en: "Nong Khae, Bua Loi, Saraburi, 18230",
  },
  {
    id: 190307,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "ไผ่ต่ำ",
    subdistrictEn: "Phai Tam",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค ไผ่ต่ำ สระบุรี 18140",
    name_th: "หนองแค ไผ่ต่ำ สระบุรี 18140",
    name_en: "Nong Khae, Phai Tam, Saraburi, 18140",
  },
  {
    id: 190308,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18250,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค โพนทอง สระบุรี 18250",
    name_th: "หนองแค โพนทอง สระบุรี 18250",
    name_en: "Nong Khae, Phon Thong, Saraburi, 18250",
  },
  {
    id: 190309,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "ห้วยขมิ้น",
    subdistrictEn: "Huai Khamin",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18230,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค ห้วยขมิ้น สระบุรี 18230",
    name_th: "หนองแค ห้วยขมิ้น สระบุรี 18230",
    name_en: "Nong Khae, Huai Khamin, Saraburi, 18230",
  },
  {
    id: 190310,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "ห้วยทราย",
    subdistrictEn: "Huai Sai",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18230,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค ห้วยทราย สระบุรี 18230",
    name_th: "หนองแค ห้วยทราย สระบุรี 18230",
    name_en: "Nong Khae, Huai Sai, Saraburi, 18230",
  },
  {
    id: 190311,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองไข่น้ำ",
    subdistrictEn: "Nong Khai Nam",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองไข่น้ำ สระบุรี 18140",
    name_th: "หนองแค หนองไข่น้ำ สระบุรี 18140",
    name_en: "Nong Khae, Nong Khai Nam, Saraburi, 18140",
  },
  {
    id: 190312,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองแขม",
    subdistrictEn: "Nong Khaem",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองแขม สระบุรี 18140",
    name_th: "หนองแค หนองแขม สระบุรี 18140",
    name_en: "Nong Khae, Nong Khaem, Saraburi, 18140",
  },
  {
    id: 190313,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองจิก",
    subdistrictEn: "Nong Chik",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18230,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองจิก สระบุรี 18230",
    name_th: "หนองแค หนองจิก สระบุรี 18230",
    name_en: "Nong Khae, Nong Chik, Saraburi, 18230",
  },
  {
    id: 190314,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองจรเข้",
    subdistrictEn: "Nong Chorakhe",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองจรเข้ สระบุรี 18140",
    name_th: "หนองแค หนองจรเข้ สระบุรี 18140",
    name_en: "Nong Khae, Nong Chorakhe, Saraburi, 18140",
  },
  {
    id: 190315,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองนาก",
    subdistrictEn: "Nong Nak",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18230,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองนาก สระบุรี 18230",
    name_th: "หนองแค หนองนาก สระบุรี 18230",
    name_en: "Nong Khae, Nong Nak, Saraburi, 18230",
  },
  {
    id: 190316,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองปลาหมอ",
    subdistrictEn: "Nong Pla Mo",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองปลาหมอ สระบุรี 18140",
    name_th: "หนองแค หนองปลาหมอ สระบุรี 18140",
    name_en: "Nong Khae, Nong Pla Mo, Saraburi, 18140",
  },
  {
    id: 190317,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองปลิง",
    subdistrictEn: "Nong Pling",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองปลิง สระบุรี 18140",
    name_th: "หนองแค หนองปลิง สระบุรี 18140",
    name_en: "Nong Khae, Nong Pling, Saraburi, 18140",
  },
  {
    id: 190318,
    districtTh: "หนองแค",
    districtEn: "Nong Khae",
    subdistrictTh: "หนองโรง",
    subdistrictEn: "Nong Rong",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18140,
    district_code: 1903,
    province_code: 10,
    name: "หนองแค หนองโรง สระบุรี 18140",
    name_th: "หนองแค หนองโรง สระบุรี 18140",
    name_en: "Nong Khae, Nong Rong, Saraburi, 18140",
  },
  {
    id: 190401,
    districtTh: "วิหารแดง",
    districtEn: "Wihan Daeng",
    subdistrictTh: "หนองหมู",
    subdistrictEn: "Nong Mu",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18150,
    district_code: 1904,
    province_code: 10,
    name: "วิหารแดง หนองหมู สระบุรี 18150",
    name_th: "วิหารแดง หนองหมู สระบุรี 18150",
    name_en: "Wihan Daeng, Nong Mu, Saraburi, 18150",
  },
  {
    id: 190402,
    districtTh: "วิหารแดง",
    districtEn: "Wihan Daeng",
    subdistrictTh: "บ้านลำ",
    subdistrictEn: "Ban Lam",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18150,
    district_code: 1904,
    province_code: 10,
    name: "วิหารแดง บ้านลำ สระบุรี 18150",
    name_th: "วิหารแดง บ้านลำ สระบุรี 18150",
    name_en: "Wihan Daeng, Ban Lam, Saraburi, 18150",
  },
  {
    id: 190403,
    districtTh: "วิหารแดง",
    districtEn: "Wihan Daeng",
    subdistrictTh: "คลองเรือ",
    subdistrictEn: "Khlong Ruea",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18150,
    district_code: 1904,
    province_code: 10,
    name: "วิหารแดง คลองเรือ สระบุรี 18150",
    name_th: "วิหารแดง คลองเรือ สระบุรี 18150",
    name_en: "Wihan Daeng, Khlong Ruea, Saraburi, 18150",
  },
  {
    id: 190404,
    districtTh: "วิหารแดง",
    districtEn: "Wihan Daeng",
    subdistrictTh: "วิหารแดง",
    subdistrictEn: "Wihan Daeng",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18150,
    district_code: 1904,
    province_code: 10,
    name: "วิหารแดง วิหารแดง สระบุรี 18150",
    name_th: "วิหารแดง วิหารแดง สระบุรี 18150",
    name_en: "Wihan Daeng, Wihan Daeng, Saraburi, 18150",
  },
  {
    id: 190405,
    districtTh: "วิหารแดง",
    districtEn: "Wihan Daeng",
    subdistrictTh: "หนองสรวง",
    subdistrictEn: "Nong Suang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18150,
    district_code: 1904,
    province_code: 10,
    name: "วิหารแดง หนองสรวง สระบุรี 18150",
    name_th: "วิหารแดง หนองสรวง สระบุรี 18150",
    name_en: "Wihan Daeng, Nong Suang, Saraburi, 18150",
  },
  {
    id: 190406,
    districtTh: "วิหารแดง",
    districtEn: "Wihan Daeng",
    subdistrictTh: "เจริญธรรม",
    subdistrictEn: "Charoen Tham",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18150,
    district_code: 1904,
    province_code: 10,
    name: "วิหารแดง เจริญธรรม สระบุรี 18150",
    name_th: "วิหารแดง เจริญธรรม สระบุรี 18150",
    name_en: "Wihan Daeng, Charoen Tham, Saraburi, 18150",
  },
  {
    id: 190501,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "หนองแซง",
    subdistrictEn: "Nong Saeng",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง หนองแซง สระบุรี 18170",
    name_th: "หนองแซง หนองแซง สระบุรี 18170",
    name_en: "Nong Saeng, Nong Saeng, Saraburi, 18170",
  },
  {
    id: 190502,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "หนองควายโซ",
    subdistrictEn: "Nong Khwai So",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง หนองควายโซ สระบุรี 18170",
    name_th: "หนองแซง หนองควายโซ สระบุรี 18170",
    name_en: "Nong Saeng, Nong Khwai So, Saraburi, 18170",
  },
  {
    id: 190503,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "หนองหัวโพ",
    subdistrictEn: "Nong Hua Pho",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง หนองหัวโพ สระบุรี 18170",
    name_th: "หนองแซง หนองหัวโพ สระบุรี 18170",
    name_en: "Nong Saeng, Nong Hua Pho, Saraburi, 18170",
  },
  {
    id: 190504,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "หนองสีดา",
    subdistrictEn: "Nong Sida",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง หนองสีดา สระบุรี 18170",
    name_th: "หนองแซง หนองสีดา สระบุรี 18170",
    name_en: "Nong Saeng, Nong Sida, Saraburi, 18170",
  },
  {
    id: 190505,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "หนองกบ",
    subdistrictEn: "Nong Kop",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง หนองกบ สระบุรี 18170",
    name_th: "หนองแซง หนองกบ สระบุรี 18170",
    name_en: "Nong Saeng, Nong Kop, Saraburi, 18170",
  },
  {
    id: 190506,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "ไก่เส่า",
    subdistrictEn: "Kai Sao",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง ไก่เส่า สระบุรี 18170",
    name_th: "หนองแซง ไก่เส่า สระบุรี 18170",
    name_en: "Nong Saeng, Kai Sao, Saraburi, 18170",
  },
  {
    id: 190507,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง โคกสะอาด สระบุรี 18170",
    name_th: "หนองแซง โคกสะอาด สระบุรี 18170",
    name_en: "Nong Saeng, Khok Sa-at, Saraburi, 18170",
  },
  {
    id: 190508,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "ม่วงหวาน",
    subdistrictEn: "Muang Wan",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง ม่วงหวาน สระบุรี 18170",
    name_th: "หนองแซง ม่วงหวาน สระบุรี 18170",
    name_en: "Nong Saeng, Muang Wan, Saraburi, 18170",
  },
  {
    id: 190509,
    districtTh: "หนองแซง",
    districtEn: "Nong Saeng",
    subdistrictTh: "เขาดิน",
    subdistrictEn: "Khao Din",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18170,
    district_code: 1905,
    province_code: 10,
    name: "หนองแซง เขาดิน สระบุรี 18170",
    name_th: "หนองแซง เขาดิน สระบุรี 18170",
    name_en: "Nong Saeng, Khao Din, Saraburi, 18170",
  },
  {
    id: 190601,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "บ้านหมอ",
    subdistrictEn: "Ban Mo",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18130,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ บ้านหมอ สระบุรี 18130",
    name_th: "บ้านหมอ บ้านหมอ สระบุรี 18130",
    name_en: "Ban Mo, Ban Mo, Saraburi, 18130",
  },
  {
    id: 190602,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "บางโขมด",
    subdistrictEn: "Bang Khamot",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18130,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ บางโขมด สระบุรี 18130",
    name_th: "บ้านหมอ บางโขมด สระบุรี 18130",
    name_en: "Ban Mo, Bang Khamot, Saraburi, 18130",
  },
  {
    id: 190603,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "สร่างโศก",
    subdistrictEn: "Sang Sok",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18130,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ สร่างโศก สระบุรี 18130",
    name_th: "บ้านหมอ สร่างโศก สระบุรี 18130",
    name_en: "Ban Mo, Sang Sok, Saraburi, 18130",
  },
  {
    id: 190604,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "ตลาดน้อย",
    subdistrictEn: "Talat Noi",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18130,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ ตลาดน้อย สระบุรี 18130",
    name_th: "บ้านหมอ ตลาดน้อย สระบุรี 18130",
    name_en: "Ban Mo, Talat Noi, Saraburi, 18130",
  },
  {
    id: 190605,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "หรเทพ",
    subdistrictEn: "Horathep",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18130,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ หรเทพ สระบุรี 18130",
    name_th: "บ้านหมอ หรเทพ สระบุรี 18130",
    name_en: "Ban Mo, Horathep, Saraburi, 18130",
  },
  {
    id: 190606,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "โคกใหญ่",
    subdistrictEn: "Khok Yai",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18130,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ โคกใหญ่ สระบุรี 18130",
    name_th: "บ้านหมอ โคกใหญ่ สระบุรี 18130",
    name_en: "Ban Mo, Khok Yai, Saraburi, 18130",
  },
  {
    id: 190607,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "ไผ่ขวาง",
    subdistrictEn: "Phai Khwang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18130,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ ไผ่ขวาง สระบุรี 18130",
    name_th: "บ้านหมอ ไผ่ขวาง สระบุรี 18130",
    name_en: "Ban Mo, Phai Khwang, Saraburi, 18130",
  },
  {
    id: 190608,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "บ้านครัว",
    subdistrictEn: "Ban Khrua",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18270,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ บ้านครัว สระบุรี 18270",
    name_th: "บ้านหมอ บ้านครัว สระบุรี 18270",
    name_en: "Ban Mo, Ban Khrua, Saraburi, 18270",
  },
  {
    id: 190609,
    districtTh: "บ้านหมอ",
    districtEn: "Ban Mo",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18130,
    district_code: 1906,
    province_code: 10,
    name: "บ้านหมอ หนองบัว สระบุรี 18130",
    name_th: "บ้านหมอ หนองบัว สระบุรี 18130",
    name_en: "Ban Mo, Nong Bua, Saraburi, 18130",
  },
  {
    id: 190701,
    districtTh: "ดอนพุด",
    districtEn: "Don Phut",
    subdistrictTh: "ดอนพุด",
    subdistrictEn: "Don Phut",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18210,
    district_code: 1907,
    province_code: 10,
    name: "ดอนพุด ดอนพุด สระบุรี 18210",
    name_th: "ดอนพุด ดอนพุด สระบุรี 18210",
    name_en: "Don Phut, Don Phut, Saraburi, 18210",
  },
  {
    id: 190702,
    districtTh: "ดอนพุด",
    districtEn: "Don Phut",
    subdistrictTh: "ไผ่หลิ่ว",
    subdistrictEn: "Phai Lio",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18210,
    district_code: 1907,
    province_code: 10,
    name: "ดอนพุด ไผ่หลิ่ว สระบุรี 18210",
    name_th: "ดอนพุด ไผ่หลิ่ว สระบุรี 18210",
    name_en: "Don Phut, Phai Lio, Saraburi, 18210",
  },
  {
    id: 190703,
    districtTh: "ดอนพุด",
    districtEn: "Don Phut",
    subdistrictTh: "บ้านหลวง",
    subdistrictEn: "Ban Luang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18210,
    district_code: 1907,
    province_code: 10,
    name: "ดอนพุด บ้านหลวง สระบุรี 18210",
    name_th: "ดอนพุด บ้านหลวง สระบุรี 18210",
    name_en: "Don Phut, Ban Luang, Saraburi, 18210",
  },
  {
    id: 190704,
    districtTh: "ดอนพุด",
    districtEn: "Don Phut",
    subdistrictTh: "ดงตะงาว",
    subdistrictEn: "Dong Ta-ngao",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18210,
    district_code: 1907,
    province_code: 10,
    name: "ดอนพุด ดงตะงาว สระบุรี 18210",
    name_th: "ดอนพุด ดงตะงาว สระบุรี 18210",
    name_en: "Don Phut, Dong Ta-ngao, Saraburi, 18210",
  },
  {
    id: 190801,
    districtTh: "หนองโดน",
    districtEn: "Nong Don",
    subdistrictTh: "หนองโดน",
    subdistrictEn: "Nong Don",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18190,
    district_code: 1908,
    province_code: 10,
    name: "หนองโดน หนองโดน สระบุรี 18190",
    name_th: "หนองโดน หนองโดน สระบุรี 18190",
    name_en: "Nong Don, Nong Don, Saraburi, 18190",
  },
  {
    id: 190802,
    districtTh: "หนองโดน",
    districtEn: "Nong Don",
    subdistrictTh: "บ้านกลับ",
    subdistrictEn: "Ban Klap",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18190,
    district_code: 1908,
    province_code: 10,
    name: "หนองโดน บ้านกลับ สระบุรี 18190",
    name_th: "หนองโดน บ้านกลับ สระบุรี 18190",
    name_en: "Nong Don, Ban Klap, Saraburi, 18190",
  },
  {
    id: 190803,
    districtTh: "หนองโดน",
    districtEn: "Nong Don",
    subdistrictTh: "ดอนทอง",
    subdistrictEn: "Don Thong",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18190,
    district_code: 1908,
    province_code: 10,
    name: "หนองโดน ดอนทอง สระบุรี 18190",
    name_th: "หนองโดน ดอนทอง สระบุรี 18190",
    name_en: "Nong Don, Don Thong, Saraburi, 18190",
  },
  {
    id: 190804,
    districtTh: "หนองโดน",
    districtEn: "Nong Don",
    subdistrictTh: "บ้านโปร่ง",
    subdistrictEn: "Ban Prong",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18190,
    district_code: 1908,
    province_code: 10,
    name: "หนองโดน บ้านโปร่ง สระบุรี 18190",
    name_th: "หนองโดน บ้านโปร่ง สระบุรี 18190",
    name_en: "Nong Don, Ban Prong, Saraburi, 18190",
  },
  {
    id: 190901,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "พระพุทธบาท",
    subdistrictEn: "Phra Phutthabat",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท พระพุทธบาท สระบุรี 18120",
    name_th: "พระพุทธบาท พระพุทธบาท สระบุรี 18120",
    name_en: "Phra Phutthabat, Phra Phutthabat, Saraburi, 18120",
  },
  {
    id: 190902,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "ขุนโขลน",
    subdistrictEn: "Khun Khlon",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท ขุนโขลน สระบุรี 18120",
    name_th: "พระพุทธบาท ขุนโขลน สระบุรี 18120",
    name_en: "Phra Phutthabat, Khun Khlon, Saraburi, 18120",
  },
  {
    id: 190903,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "ธารเกษม",
    subdistrictEn: "Than Kasem",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท ธารเกษม สระบุรี 18120",
    name_th: "พระพุทธบาท ธารเกษม สระบุรี 18120",
    name_en: "Phra Phutthabat, Than Kasem, Saraburi, 18120",
  },
  {
    id: 190904,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "นายาว",
    subdistrictEn: "Na Yao",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท นายาว สระบุรี 18120",
    name_th: "พระพุทธบาท นายาว สระบุรี 18120",
    name_en: "Phra Phutthabat, Na Yao, Saraburi, 18120",
  },
  {
    id: 190905,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "พุคำจาน",
    subdistrictEn: "Phu Kham Chan",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท พุคำจาน สระบุรี 18120",
    name_th: "พระพุทธบาท พุคำจาน สระบุรี 18120",
    name_en: "Phra Phutthabat, Phu Kham Chan, Saraburi, 18120",
  },
  {
    id: 190906,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "เขาวง",
    subdistrictEn: "Khao Wong",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท เขาวง สระบุรี 18120",
    name_th: "พระพุทธบาท เขาวง สระบุรี 18120",
    name_en: "Phra Phutthabat, Khao Wong, Saraburi, 18120",
  },
  {
    id: 190907,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "ห้วยป่าหวาย",
    subdistrictEn: "Huai Pa Wai",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท ห้วยป่าหวาย สระบุรี 18120",
    name_th: "พระพุทธบาท ห้วยป่าหวาย สระบุรี 18120",
    name_en: "Phra Phutthabat, Huai Pa Wai, Saraburi, 18120",
  },
  {
    id: 190908,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "พุกร่าง",
    subdistrictEn: "Phu Krang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท พุกร่าง สระบุรี 18120",
    name_th: "พระพุทธบาท พุกร่าง สระบุรี 18120",
    name_en: "Phra Phutthabat, Phu Krang, Saraburi, 18120",
  },
  {
    id: 190909,
    districtTh: "พระพุทธบาท",
    districtEn: "Phra Phutthabat",
    subdistrictTh: "หนองแก",
    subdistrictEn: "Nong Kae",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18120,
    district_code: 1909,
    province_code: 10,
    name: "พระพุทธบาท หนองแก สระบุรี 18120",
    name_th: "พระพุทธบาท หนองแก สระบุรี 18120",
    name_en: "Phra Phutthabat, Nong Kae, Saraburi, 18120",
  },
  {
    id: 191001,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "เสาไห้",
    subdistrictEn: "Sao Hai",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ เสาไห้ สระบุรี 18160",
    name_th: "เสาไห้ เสาไห้ สระบุรี 18160",
    name_en: "Sao Hai, Sao Hai, Saraburi, 18160",
  },
  {
    id: 191002,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ บ้านยาง สระบุรี 18160",
    name_th: "เสาไห้ บ้านยาง สระบุรี 18160",
    name_en: "Sao Hai, Ban Yang, Saraburi, 18160",
  },
  {
    id: 191003,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "หัวปลวก",
    subdistrictEn: "Hua Pluak",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ หัวปลวก สระบุรี 18160",
    name_th: "เสาไห้ หัวปลวก สระบุรี 18160",
    name_en: "Sao Hai, Hua Pluak, Saraburi, 18160",
  },
  {
    id: 191004,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "งิ้วงาม",
    subdistrictEn: "Ngio Ngam",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ งิ้วงาม สระบุรี 18160",
    name_th: "เสาไห้ งิ้วงาม สระบุรี 18160",
    name_en: "Sao Hai, Ngio Ngam, Saraburi, 18160",
  },
  {
    id: 191005,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "ศาลารีไทย",
    subdistrictEn: "Sala Ri Thai",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ ศาลารีไทย สระบุรี 18160",
    name_th: "เสาไห้ ศาลารีไทย สระบุรี 18160",
    name_en: "Sao Hai, Sala Ri Thai, Saraburi, 18160",
  },
  {
    id: 191006,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "ต้นตาล",
    subdistrictEn: "Ton Tan",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ ต้นตาล สระบุรี 18160",
    name_th: "เสาไห้ ต้นตาล สระบุรี 18160",
    name_en: "Sao Hai, Ton Tan, Saraburi, 18160",
  },
  {
    id: 191007,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ ท่าช้าง สระบุรี 18160",
    name_th: "เสาไห้ ท่าช้าง สระบุรี 18160",
    name_en: "Sao Hai, Tha Chang, Saraburi, 18160",
  },
  {
    id: 191008,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "พระยาทด",
    subdistrictEn: "Phraya Thot",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ พระยาทด สระบุรี 18160",
    name_th: "เสาไห้ พระยาทด สระบุรี 18160",
    name_en: "Sao Hai, Phraya Thot, Saraburi, 18160",
  },
  {
    id: 191009,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "ม่วงงาม",
    subdistrictEn: "Muang Ngam",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ ม่วงงาม สระบุรี 18160",
    name_th: "เสาไห้ ม่วงงาม สระบุรี 18160",
    name_en: "Sao Hai, Muang Ngam, Saraburi, 18160",
  },
  {
    id: 191010,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "เริงราง",
    subdistrictEn: "Roeng Rang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ เริงราง สระบุรี 18160",
    name_th: "เสาไห้ เริงราง สระบุรี 18160",
    name_en: "Sao Hai, Roeng Rang, Saraburi, 18160",
  },
  {
    id: 191011,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "เมืองเก่า",
    subdistrictEn: "Mueang Kao",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ เมืองเก่า สระบุรี 18160",
    name_th: "เสาไห้ เมืองเก่า สระบุรี 18160",
    name_en: "Sao Hai, Mueang Kao, Saraburi, 18160",
  },
  {
    id: 191012,
    districtTh: "เสาไห้",
    districtEn: "Sao Hai",
    subdistrictTh: "สวนดอกไม้",
    subdistrictEn: "Suan Dok Mai",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18160,
    district_code: 1910,
    province_code: 10,
    name: "เสาไห้ สวนดอกไม้ สระบุรี 18160",
    name_th: "เสาไห้ สวนดอกไม้ สระบุรี 18160",
    name_en: "Sao Hai, Suan Dok Mai, Saraburi, 18160",
  },
  {
    id: 191101,
    districtTh: "มวกเหล็ก",
    districtEn: "Muak Lek",
    subdistrictTh: "มวกเหล็ก",
    subdistrictEn: "Muak Lek",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18180,
    district_code: 1911,
    province_code: 10,
    name: "มวกเหล็ก มวกเหล็ก สระบุรี 18180",
    name_th: "มวกเหล็ก มวกเหล็ก สระบุรี 18180",
    name_en: "Muak Lek, Muak Lek, Saraburi, 18180",
  },
  {
    id: 191102,
    districtTh: "มวกเหล็ก",
    districtEn: "Muak Lek",
    subdistrictTh: "มิตรภาพ",
    subdistrictEn: "Mittraphap",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18180,
    district_code: 1911,
    province_code: 10,
    name: "มวกเหล็ก มิตรภาพ สระบุรี 18180",
    name_th: "มวกเหล็ก มิตรภาพ สระบุรี 18180",
    name_en: "Muak Lek, Mittraphap, Saraburi, 18180",
  },
  {
    id: 191104,
    districtTh: "มวกเหล็ก",
    districtEn: "Muak Lek",
    subdistrictTh: "หนองย่างเสือ",
    subdistrictEn: "Nong Yang Suea",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18180,
    district_code: 1911,
    province_code: 10,
    name: "มวกเหล็ก หนองย่างเสือ สระบุรี 18180",
    name_th: "มวกเหล็ก หนองย่างเสือ สระบุรี 18180",
    name_en: "Muak Lek, Nong Yang Suea, Saraburi, 18180",
  },
  {
    id: 191105,
    districtTh: "มวกเหล็ก",
    districtEn: "Muak Lek",
    subdistrictTh: "ลำสมพุง",
    subdistrictEn: "Lam Somphung",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18180,
    district_code: 1911,
    province_code: 10,
    name: "มวกเหล็ก ลำสมพุง สระบุรี 18180",
    name_th: "มวกเหล็ก ลำสมพุง สระบุรี 18180",
    name_en: "Muak Lek, Lam Somphung, Saraburi, 18180",
  },
  {
    id: 191107,
    districtTh: "มวกเหล็ก",
    districtEn: "Muak Lek",
    subdistrictTh: "ลำพญากลาง",
    subdistrictEn: "Lam Phaya Klang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18180,
    district_code: 1911,
    province_code: 10,
    name: "มวกเหล็ก ลำพญากลาง สระบุรี 18180",
    name_th: "มวกเหล็ก ลำพญากลาง สระบุรี 18180",
    name_en: "Muak Lek, Lam Phaya Klang, Saraburi, 18180",
  },
  {
    id: 191109,
    districtTh: "มวกเหล็ก",
    districtEn: "Muak Lek",
    subdistrictTh: "ซับสนุ่น",
    subdistrictEn: "Sap Sanun",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18220,
    district_code: 1911,
    province_code: 10,
    name: "มวกเหล็ก ซับสนุ่น สระบุรี 18220",
    name_th: "มวกเหล็ก ซับสนุ่น สระบุรี 18220",
    name_en: "Muak Lek, Sap Sanun, Saraburi, 18220",
  },
  {
    id: 191201,
    districtTh: "วังม่วง",
    districtEn: "Wang Muang",
    subdistrictTh: "แสลงพัน",
    subdistrictEn: "Salaeng Phan",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18220,
    district_code: 1912,
    province_code: 10,
    name: "วังม่วง แสลงพัน สระบุรี 18220",
    name_th: "วังม่วง แสลงพัน สระบุรี 18220",
    name_en: "Wang Muang, Salaeng Phan, Saraburi, 18220",
  },
  {
    id: 191202,
    districtTh: "วังม่วง",
    districtEn: "Wang Muang",
    subdistrictTh: "คำพราน",
    subdistrictEn: "Kham Phran",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18220,
    district_code: 1912,
    province_code: 10,
    name: "วังม่วง คำพราน สระบุรี 18220",
    name_th: "วังม่วง คำพราน สระบุรี 18220",
    name_en: "Wang Muang, Kham Phran, Saraburi, 18220",
  },
  {
    id: 191203,
    districtTh: "วังม่วง",
    districtEn: "Wang Muang",
    subdistrictTh: "วังม่วง",
    subdistrictEn: "Wang Muang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18220,
    district_code: 1912,
    province_code: 10,
    name: "วังม่วง วังม่วง สระบุรี 18220",
    name_th: "วังม่วง วังม่วง สระบุรี 18220",
    name_en: "Wang Muang, Wang Muang, Saraburi, 18220",
  },
  {
    id: 191301,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "เขาดินพัฒนา",
    subdistrictEn: "Khao Din Phatthana",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1913,
    province_code: 10,
    name: "เฉลิมพระเกียรติ เขาดินพัฒนา สระบุรี 18000",
    name_th: "เฉลิมพระเกียรติ เขาดินพัฒนา สระบุรี 18000",
    name_en: "Chaloem Phra Kiat, Khao Din Phatthana, Saraburi, 18000",
  },
  {
    id: 191302,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "บ้านแก้ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1913,
    province_code: 10,
    name: "เฉลิมพระเกียรติ บ้านแก้ง สระบุรี 18000",
    name_th: "เฉลิมพระเกียรติ บ้านแก้ง สระบุรี 18000",
    name_en: "Chaloem Phra Kiat, Ban Kaeng, Saraburi, 18000",
  },
  {
    id: 191303,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ผึ้งรวง",
    subdistrictEn: "Phueng Ruang",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1913,
    province_code: 10,
    name: "เฉลิมพระเกียรติ ผึ้งรวง สระบุรี 18000",
    name_th: "เฉลิมพระเกียรติ ผึ้งรวง สระบุรี 18000",
    name_en: "Chaloem Phra Kiat, Phueng Ruang, Saraburi, 18000",
  },
  {
    id: 191304,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "พุแค",
    subdistrictEn: "Phu Khae",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18240,
    district_code: 1913,
    province_code: 10,
    name: "เฉลิมพระเกียรติ พุแค สระบุรี 18240",
    name_th: "เฉลิมพระเกียรติ พุแค สระบุรี 18240",
    name_en: "Chaloem Phra Kiat, Phu Khae, Saraburi, 18240",
  },
  {
    id: 191305,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ห้วยบง",
    subdistrictEn: "Huai Bong",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18000,
    district_code: 1913,
    province_code: 10,
    name: "เฉลิมพระเกียรติ ห้วยบง สระบุรี 18000",
    name_th: "เฉลิมพระเกียรติ ห้วยบง สระบุรี 18000",
    name_en: "Chaloem Phra Kiat, Huai Bong, Saraburi, 18000",
  },
  {
    id: 191306,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "หน้าพระลาน",
    subdistrictEn: "Na Phra Lan",
    provinceTh: "สระบุรี",
    provinceEn: "Saraburi",
    zipcode: 18240,
    district_code: 1913,
    province_code: 10,
    name: "เฉลิมพระเกียรติ หน้าพระลาน สระบุรี 18240",
    name_th: "เฉลิมพระเกียรติ หน้าพระลาน สระบุรี 18240",
    name_en: "Chaloem Phra Kiat, Na Phra Lan, Saraburi, 18240",
  },
  {
    id: 200101,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "บางปลาสร้อย",
    subdistrictEn: "Bang Pla Soi",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี บางปลาสร้อย ชลบุรี 20000",
    name_th: "เมืองชลบุรี บางปลาสร้อย ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Bang Pla Soi, Chon Buri, 20000",
  },
  {
    id: 200102,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "มะขามหย่ง",
    subdistrictEn: "Makham Yong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี มะขามหย่ง ชลบุรี 20000",
    name_th: "เมืองชลบุรี มะขามหย่ง ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Makham Yong, Chon Buri, 20000",
  },
  {
    id: 200103,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "บ้านโขด",
    subdistrictEn: "Ban Khot",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี บ้านโขด ชลบุรี 20000",
    name_th: "เมืองชลบุรี บ้านโขด ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Ban Khot, Chon Buri, 20000",
  },
  {
    id: 200104,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "แสนสุข",
    subdistrictEn: "Saen Suk",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี แสนสุข ชลบุรี 20000",
    name_th: "เมืองชลบุรี แสนสุข ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Saen Suk, Chon Buri, 20000",
  },
  {
    id: 200105,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "บ้านสวน",
    subdistrictEn: "Ban Suan",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี บ้านสวน ชลบุรี 20000",
    name_th: "เมืองชลบุรี บ้านสวน ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Ban Suan, Chon Buri, 20000",
  },
  {
    id: 200106,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "หนองรี",
    subdistrictEn: "Nong Ri",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี หนองรี ชลบุรี 20000",
    name_th: "เมืองชลบุรี หนองรี ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Nong Ri, Chon Buri, 20000",
  },
  {
    id: 200107,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "นาป่า",
    subdistrictEn: "Na Pa",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี นาป่า ชลบุรี 20000",
    name_th: "เมืองชลบุรี นาป่า ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Na Pa, Chon Buri, 20000",
  },
  {
    id: 200108,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "หนองข้างคอก",
    subdistrictEn: "Nong Khang Khok",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี หนองข้างคอก ชลบุรี 20000",
    name_th: "เมืองชลบุรี หนองข้างคอก ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Nong Khang Khok, Chon Buri, 20000",
  },
  {
    id: 200109,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "ดอนหัวฬ่อ",
    subdistrictEn: "Don Hua Lo",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี ดอนหัวฬ่อ ชลบุรี 20000",
    name_th: "เมืองชลบุรี ดอนหัวฬ่อ ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Don Hua Lo, Chon Buri, 20000",
  },
  {
    id: 200110,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "หนองไม้แดง",
    subdistrictEn: "Nong Mai Daeng",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี หนองไม้แดง ชลบุรี 20000",
    name_th: "เมืองชลบุรี หนองไม้แดง ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Nong Mai Daeng, Chon Buri, 20000",
  },
  {
    id: 200111,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "บางทราย",
    subdistrictEn: "Bang Sai",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี บางทราย ชลบุรี 20000",
    name_th: "เมืองชลบุรี บางทราย ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Bang Sai, Chon Buri, 20000",
  },
  {
    id: 200112,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "คลองตำหรุ",
    subdistrictEn: "Khlong Tamru",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี คลองตำหรุ ชลบุรี 20000",
    name_th: "เมืองชลบุรี คลองตำหรุ ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Khlong Tamru, Chon Buri, 20000",
  },
  {
    id: 200113,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "เหมือง",
    subdistrictEn: "Mueang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20130,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี เหมือง ชลบุรี 20130",
    name_th: "เมืองชลบุรี เหมือง ชลบุรี 20130",
    name_en: "Mueang Chon Buri, Mueang, Chon Buri, 20130",
  },
  {
    id: 200114,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "บ้านปึก",
    subdistrictEn: "Ban Puek",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20130,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี บ้านปึก ชลบุรี 20130",
    name_th: "เมืองชลบุรี บ้านปึก ชลบุรี 20130",
    name_en: "Mueang Chon Buri, Ban Puek, Chon Buri, 20130",
  },
  {
    id: 200115,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "ห้วยกะปิ",
    subdistrictEn: "Huai Kapi",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี ห้วยกะปิ ชลบุรี 20000",
    name_th: "เมืองชลบุรี ห้วยกะปิ ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Huai Kapi, Chon Buri, 20000",
  },
  {
    id: 200116,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "เสม็ด",
    subdistrictEn: "Samet",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20130,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี เสม็ด ชลบุรี 20130",
    name_th: "เมืองชลบุรี เสม็ด ชลบุรี 20130",
    name_en: "Mueang Chon Buri, Samet, Chon Buri, 20130",
  },
  {
    id: 200117,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "อ่างศิลา",
    subdistrictEn: "Ang Sila",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี อ่างศิลา ชลบุรี 20000",
    name_th: "เมืองชลบุรี อ่างศิลา ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Ang Sila, Chon Buri, 20000",
  },
  {
    id: 200118,
    districtTh: "เมืองชลบุรี",
    districtEn: "Mueang Chon Buri",
    subdistrictTh: "สำนักบก",
    subdistrictEn: "Samnak Bok",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20000,
    district_code: 2001,
    province_code: 11,
    name: "เมืองชลบุรี สำนักบก ชลบุรี 20000",
    name_th: "เมืองชลบุรี สำนักบก ชลบุรี 20000",
    name_en: "Mueang Chon Buri, Samnak Bok, Chon Buri, 20000",
  },
  {
    id: 200201,
    districtTh: "บ้านบึง",
    districtEn: "Ban Bueng",
    subdistrictTh: "บ้านบึง",
    subdistrictEn: "Ban Bueng",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20170,
    district_code: 2002,
    province_code: 11,
    name: "บ้านบึง บ้านบึง ชลบุรี 20170",
    name_th: "บ้านบึง บ้านบึง ชลบุรี 20170",
    name_en: "Ban Bueng, Ban Bueng, Chon Buri, 20170",
  },
  {
    id: 200202,
    districtTh: "บ้านบึง",
    districtEn: "Ban Bueng",
    subdistrictTh: "คลองกิ่ว",
    subdistrictEn: "Khlong Kio",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20220,
    district_code: 2002,
    province_code: 11,
    name: "บ้านบึง คลองกิ่ว ชลบุรี 20220",
    name_th: "บ้านบึง คลองกิ่ว ชลบุรี 20220",
    name_en: "Ban Bueng, Khlong Kio, Chon Buri, 20220",
  },
  {
    id: 200203,
    districtTh: "บ้านบึง",
    districtEn: "Ban Bueng",
    subdistrictTh: "มาบไผ่",
    subdistrictEn: "Map Phai",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20170,
    district_code: 2002,
    province_code: 11,
    name: "บ้านบึง มาบไผ่ ชลบุรี 20170",
    name_th: "บ้านบึง มาบไผ่ ชลบุรี 20170",
    name_en: "Ban Bueng, Map Phai, Chon Buri, 20170",
  },
  {
    id: 200204,
    districtTh: "บ้านบึง",
    districtEn: "Ban Bueng",
    subdistrictTh: "หนองซ้ำซาก",
    subdistrictEn: "Nong Samsak",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20170,
    district_code: 2002,
    province_code: 11,
    name: "บ้านบึง หนองซ้ำซาก ชลบุรี 20170",
    name_th: "บ้านบึง หนองซ้ำซาก ชลบุรี 20170",
    name_en: "Ban Bueng, Nong Samsak, Chon Buri, 20170",
  },
  {
    id: 200205,
    districtTh: "บ้านบึง",
    districtEn: "Ban Bueng",
    subdistrictTh: "หนองบอนแดง",
    subdistrictEn: "Nong Bon Daeng",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20170,
    district_code: 2002,
    province_code: 11,
    name: "บ้านบึง หนองบอนแดง ชลบุรี 20170",
    name_th: "บ้านบึง หนองบอนแดง ชลบุรี 20170",
    name_en: "Ban Bueng, Nong Bon Daeng, Chon Buri, 20170",
  },
  {
    id: 200206,
    districtTh: "บ้านบึง",
    districtEn: "Ban Bueng",
    subdistrictTh: "หนองชาก",
    subdistrictEn: "Nong Chak",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20170,
    district_code: 2002,
    province_code: 11,
    name: "บ้านบึง หนองชาก ชลบุรี 20170",
    name_th: "บ้านบึง หนองชาก ชลบุรี 20170",
    name_en: "Ban Bueng, Nong Chak, Chon Buri, 20170",
  },
  {
    id: 200207,
    districtTh: "บ้านบึง",
    districtEn: "Ban Bueng",
    subdistrictTh: "หนองอิรุณ",
    subdistrictEn: "Nong Irun",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20220,
    district_code: 2002,
    province_code: 11,
    name: "บ้านบึง หนองอิรุณ ชลบุรี 20220",
    name_th: "บ้านบึง หนองอิรุณ ชลบุรี 20220",
    name_en: "Ban Bueng, Nong Irun, Chon Buri, 20220",
  },
  {
    id: 200208,
    districtTh: "บ้านบึง",
    districtEn: "Ban Bueng",
    subdistrictTh: "หนองไผ่แก้ว",
    subdistrictEn: "Nong Phai Kaeo",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20220,
    district_code: 2002,
    province_code: 11,
    name: "บ้านบึง หนองไผ่แก้ว ชลบุรี 20220",
    name_th: "บ้านบึง หนองไผ่แก้ว ชลบุรี 20220",
    name_en: "Ban Bueng, Nong Phai Kaeo, Chon Buri, 20220",
  },
  {
    id: 200301,
    districtTh: "หนองใหญ่",
    districtEn: "Nong Yai",
    subdistrictTh: "หนองใหญ่",
    subdistrictEn: "Nong Yai",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20190,
    district_code: 2003,
    province_code: 11,
    name: "หนองใหญ่ หนองใหญ่ ชลบุรี 20190",
    name_th: "หนองใหญ่ หนองใหญ่ ชลบุรี 20190",
    name_en: "Nong Yai, Nong Yai, Chon Buri, 20190",
  },
  {
    id: 200302,
    districtTh: "หนองใหญ่",
    districtEn: "Nong Yai",
    subdistrictTh: "คลองพลู",
    subdistrictEn: "Khlong Phlu",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20190,
    district_code: 2003,
    province_code: 11,
    name: "หนองใหญ่ คลองพลู ชลบุรี 20190",
    name_th: "หนองใหญ่ คลองพลู ชลบุรี 20190",
    name_en: "Nong Yai, Khlong Phlu, Chon Buri, 20190",
  },
  {
    id: 200303,
    districtTh: "หนองใหญ่",
    districtEn: "Nong Yai",
    subdistrictTh: "หนองเสือช้าง",
    subdistrictEn: "Nong Suea Chang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20190,
    district_code: 2003,
    province_code: 11,
    name: "หนองใหญ่ หนองเสือช้าง ชลบุรี 20190",
    name_th: "หนองใหญ่ หนองเสือช้าง ชลบุรี 20190",
    name_en: "Nong Yai, Nong Suea Chang, Chon Buri, 20190",
  },
  {
    id: 200304,
    districtTh: "หนองใหญ่",
    districtEn: "Nong Yai",
    subdistrictTh: "ห้างสูง",
    subdistrictEn: "Hang Sung",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20190,
    district_code: 2003,
    province_code: 11,
    name: "หนองใหญ่ ห้างสูง ชลบุรี 20190",
    name_th: "หนองใหญ่ ห้างสูง ชลบุรี 20190",
    name_en: "Nong Yai, Hang Sung, Chon Buri, 20190",
  },
  {
    id: 200305,
    districtTh: "หนองใหญ่",
    districtEn: "Nong Yai",
    subdistrictTh: "เขาซก",
    subdistrictEn: "Khao Sok",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20190,
    district_code: 2003,
    province_code: 11,
    name: "หนองใหญ่ เขาซก ชลบุรี 20190",
    name_th: "หนองใหญ่ เขาซก ชลบุรี 20190",
    name_en: "Nong Yai, Khao Sok, Chon Buri, 20190",
  },
  {
    id: 200401,
    districtTh: "บางละมุง",
    districtEn: "Bang Lamung",
    subdistrictTh: "บางละมุง",
    subdistrictEn: "Bang Lamung",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20150,
    district_code: 2004,
    province_code: 11,
    name: "บางละมุง บางละมุง ชลบุรี 20150",
    name_th: "บางละมุง บางละมุง ชลบุรี 20150",
    name_en: "Bang Lamung, Bang Lamung, Chon Buri, 20150",
  },
  {
    id: 200402,
    districtTh: "บางละมุง",
    districtEn: "Bang Lamung",
    subdistrictTh: "หนองปรือ",
    subdistrictEn: "Nong Prue",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20150,
    district_code: 2004,
    province_code: 11,
    name: "บางละมุง หนองปรือ ชลบุรี 20150",
    name_th: "บางละมุง หนองปรือ ชลบุรี 20150",
    name_en: "Bang Lamung, Nong Prue, Chon Buri, 20150",
  },
  {
    id: 200403,
    districtTh: "บางละมุง",
    districtEn: "Bang Lamung",
    subdistrictTh: "หนองปลาไหล",
    subdistrictEn: "Nong Pla Lai",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20150,
    district_code: 2004,
    province_code: 11,
    name: "บางละมุง หนองปลาไหล ชลบุรี 20150",
    name_th: "บางละมุง หนองปลาไหล ชลบุรี 20150",
    name_en: "Bang Lamung, Nong Pla Lai, Chon Buri, 20150",
  },
  {
    id: 200404,
    districtTh: "บางละมุง",
    districtEn: "Bang Lamung",
    subdistrictTh: "โป่ง",
    subdistrictEn: "Pong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20150,
    district_code: 2004,
    province_code: 11,
    name: "บางละมุง โป่ง ชลบุรี 20150",
    name_th: "บางละมุง โป่ง ชลบุรี 20150",
    name_en: "Bang Lamung, Pong, Chon Buri, 20150",
  },
  {
    id: 200405,
    districtTh: "บางละมุง",
    districtEn: "Bang Lamung",
    subdistrictTh: "เขาไม้แก้ว",
    subdistrictEn: "Khao Mai Kaeo",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20150,
    district_code: 2004,
    province_code: 11,
    name: "บางละมุง เขาไม้แก้ว ชลบุรี 20150",
    name_th: "บางละมุง เขาไม้แก้ว ชลบุรี 20150",
    name_en: "Bang Lamung, Khao Mai Kaeo, Chon Buri, 20150",
  },
  {
    id: 200406,
    districtTh: "บางละมุง",
    districtEn: "Bang Lamung",
    subdistrictTh: "ห้วยใหญ่",
    subdistrictEn: "Huai Yai",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20150,
    district_code: 2004,
    province_code: 11,
    name: "บางละมุง ห้วยใหญ่ ชลบุรี 20150",
    name_th: "บางละมุง ห้วยใหญ่ ชลบุรี 20150",
    name_en: "Bang Lamung, Huai Yai, Chon Buri, 20150",
  },
  {
    id: 200407,
    districtTh: "บางละมุง",
    districtEn: "Bang Lamung",
    subdistrictTh: "ตะเคียนเตี้ย",
    subdistrictEn: "Takhian Tia",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20150,
    district_code: 2004,
    province_code: 11,
    name: "บางละมุง ตะเคียนเตี้ย ชลบุรี 20150",
    name_th: "บางละมุง ตะเคียนเตี้ย ชลบุรี 20150",
    name_en: "Bang Lamung, Takhian Tia, Chon Buri, 20150",
  },
  {
    id: 200408,
    districtTh: "บางละมุง",
    districtEn: "Bang Lamung",
    subdistrictTh: "นาเกลือ",
    subdistrictEn: "Na Kluea",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20150,
    district_code: 2004,
    province_code: 11,
    name: "บางละมุง นาเกลือ ชลบุรี 20150",
    name_th: "บางละมุง นาเกลือ ชลบุรี 20150",
    name_en: "Bang Lamung, Na Kluea, Chon Buri, 20150",
  },
  {
    id: 200501,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "พานทอง",
    subdistrictEn: "Phan Thong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง พานทอง ชลบุรี 20160",
    name_th: "พานทอง พานทอง ชลบุรี 20160",
    name_en: "Phan Thong, Phan Thong, Chon Buri, 20160",
  },
  {
    id: 200502,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "หนองตำลึง",
    subdistrictEn: "Nong Tamlueng",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง หนองตำลึง ชลบุรี 20160",
    name_th: "พานทอง หนองตำลึง ชลบุรี 20160",
    name_en: "Phan Thong, Nong Tamlueng, Chon Buri, 20160",
  },
  {
    id: 200503,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "มาบโป่ง",
    subdistrictEn: "Map Pong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง มาบโป่ง ชลบุรี 20160",
    name_th: "พานทอง มาบโป่ง ชลบุรี 20160",
    name_en: "Phan Thong, Map Pong, Chon Buri, 20160",
  },
  {
    id: 200504,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "หนองกะขะ",
    subdistrictEn: "Nong Kakha",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง หนองกะขะ ชลบุรี 20160",
    name_th: "พานทอง หนองกะขะ ชลบุรี 20160",
    name_en: "Phan Thong, Nong Kakha, Chon Buri, 20160",
  },
  {
    id: 200505,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "หนองหงษ์",
    subdistrictEn: "Nong Hong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง หนองหงษ์ ชลบุรี 20160",
    name_th: "พานทอง หนองหงษ์ ชลบุรี 20160",
    name_en: "Phan Thong, Nong Hong, Chon Buri, 20160",
  },
  {
    id: 200506,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "โคกขี้หนอน",
    subdistrictEn: "Khok Khi Non",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง โคกขี้หนอน ชลบุรี 20160",
    name_th: "พานทอง โคกขี้หนอน ชลบุรี 20160",
    name_en: "Phan Thong, Khok Khi Non, Chon Buri, 20160",
  },
  {
    id: 200507,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "บ้านเก่า",
    subdistrictEn: "Ban Kao",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง บ้านเก่า ชลบุรี 20160",
    name_th: "พานทอง บ้านเก่า ชลบุรี 20160",
    name_en: "Phan Thong, Ban Kao, Chon Buri, 20160",
  },
  {
    id: 200508,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "หน้าประดู่",
    subdistrictEn: "Na Pradu",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง หน้าประดู่ ชลบุรี 20160",
    name_th: "พานทอง หน้าประดู่ ชลบุรี 20160",
    name_en: "Phan Thong, Na Pradu, Chon Buri, 20160",
  },
  {
    id: 200509,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "บางนาง",
    subdistrictEn: "Bang Nang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง บางนาง ชลบุรี 20160",
    name_th: "พานทอง บางนาง ชลบุรี 20160",
    name_en: "Phan Thong, Bang Nang, Chon Buri, 20160",
  },
  {
    id: 200510,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "เกาะลอย",
    subdistrictEn: "Ko Loi",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง เกาะลอย ชลบุรี 20160",
    name_th: "พานทอง เกาะลอย ชลบุรี 20160",
    name_en: "Phan Thong, Ko Loi, Chon Buri, 20160",
  },
  {
    id: 200511,
    districtTh: "พานทอง",
    districtEn: "Phan Thong",
    subdistrictTh: "บางหัก",
    subdistrictEn: "Bang Hak",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20160,
    district_code: 2005,
    province_code: 11,
    name: "พานทอง บางหัก ชลบุรี 20160",
    name_th: "พานทอง บางหัก ชลบุรี 20160",
    name_en: "Phan Thong, Bang Hak, Chon Buri, 20160",
  },
  {
    id: 200601,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "พนัสนิคม",
    subdistrictEn: "Phanat Nikhom",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม พนัสนิคม ชลบุรี 20140",
    name_th: "พนัสนิคม พนัสนิคม ชลบุรี 20140",
    name_en: "Phanat Nikhom, Phanat Nikhom, Chon Buri, 20140",
  },
  {
    id: 200602,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "หน้าพระธาตุ",
    subdistrictEn: "Na Phra That",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม หน้าพระธาตุ ชลบุรี 20140",
    name_th: "พนัสนิคม หน้าพระธาตุ ชลบุรี 20140",
    name_en: "Phanat Nikhom, Na Phra That, Chon Buri, 20140",
  },
  {
    id: 200603,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "วัดหลวง",
    subdistrictEn: "Wat Luang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม วัดหลวง ชลบุรี 20140",
    name_th: "พนัสนิคม วัดหลวง ชลบุรี 20140",
    name_en: "Phanat Nikhom, Wat Luang, Chon Buri, 20140",
  },
  {
    id: 200604,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "บ้านเซิด",
    subdistrictEn: "Ban Soet",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม บ้านเซิด ชลบุรี 20140",
    name_th: "พนัสนิคม บ้านเซิด ชลบุรี 20140",
    name_en: "Phanat Nikhom, Ban Soet, Chon Buri, 20140",
  },
  {
    id: 200605,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "นาเริก",
    subdistrictEn: "Na Roek",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม นาเริก ชลบุรี 20140",
    name_th: "พนัสนิคม นาเริก ชลบุรี 20140",
    name_en: "Phanat Nikhom, Na Roek, Chon Buri, 20140",
  },
  {
    id: 200606,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "หมอนนาง",
    subdistrictEn: "Mon Nang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม หมอนนาง ชลบุรี 20140",
    name_th: "พนัสนิคม หมอนนาง ชลบุรี 20140",
    name_en: "Phanat Nikhom, Mon Nang, Chon Buri, 20140",
  },
  {
    id: 200607,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "สระสี่เหลี่ยม",
    subdistrictEn: "Sa Si Liam",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม สระสี่เหลี่ยม ชลบุรี 20140",
    name_th: "พนัสนิคม สระสี่เหลี่ยม ชลบุรี 20140",
    name_en: "Phanat Nikhom, Sa Si Liam, Chon Buri, 20140",
  },
  {
    id: 200608,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "วัดโบสถ์",
    subdistrictEn: "Wat Bot",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม วัดโบสถ์ ชลบุรี 20140",
    name_th: "พนัสนิคม วัดโบสถ์ ชลบุรี 20140",
    name_en: "Phanat Nikhom, Wat Bot, Chon Buri, 20140",
  },
  {
    id: 200609,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "กุฎโง้ง",
    subdistrictEn: "Kut Ngong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม กุฎโง้ง ชลบุรี 20140",
    name_th: "พนัสนิคม กุฎโง้ง ชลบุรี 20140",
    name_en: "Phanat Nikhom, Kut Ngong, Chon Buri, 20140",
  },
  {
    id: 200610,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "หัวถนน",
    subdistrictEn: "Hua Thanon",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม หัวถนน ชลบุรี 20140",
    name_th: "พนัสนิคม หัวถนน ชลบุรี 20140",
    name_en: "Phanat Nikhom, Hua Thanon, Chon Buri, 20140",
  },
  {
    id: 200611,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม ท่าข้าม ชลบุรี 20140",
    name_th: "พนัสนิคม ท่าข้าม ชลบุรี 20140",
    name_en: "Phanat Nikhom, Tha Kham, Chon Buri, 20140",
  },
  {
    id: 200613,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "หนองปรือ",
    subdistrictEn: "Nong Prue",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม หนองปรือ ชลบุรี 20140",
    name_th: "พนัสนิคม หนองปรือ ชลบุรี 20140",
    name_en: "Phanat Nikhom, Nong Prue, Chon Buri, 20140",
  },
  {
    id: 200614,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "หนองขยาด",
    subdistrictEn: "Nong Khayat",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม หนองขยาด ชลบุรี 20140",
    name_th: "พนัสนิคม หนองขยาด ชลบุรี 20140",
    name_en: "Phanat Nikhom, Nong Khayat, Chon Buri, 20140",
  },
  {
    id: 200615,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "ทุ่งขวาง",
    subdistrictEn: "Thung Khwang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม ทุ่งขวาง ชลบุรี 20140",
    name_th: "พนัสนิคม ทุ่งขวาง ชลบุรี 20140",
    name_en: "Phanat Nikhom, Thung Khwang, Chon Buri, 20140",
  },
  {
    id: 200616,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "หนองเหียง",
    subdistrictEn: "Nong Hiang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม หนองเหียง ชลบุรี 20140",
    name_th: "พนัสนิคม หนองเหียง ชลบุรี 20140",
    name_en: "Phanat Nikhom, Nong Hiang, Chon Buri, 20140",
  },
  {
    id: 200617,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "นาวังหิน",
    subdistrictEn: "Na Wang Hin",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม นาวังหิน ชลบุรี 20140",
    name_th: "พนัสนิคม นาวังหิน ชลบุรี 20140",
    name_en: "Phanat Nikhom, Na Wang Hin, Chon Buri, 20140",
  },
  {
    id: 200618,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "บ้านช้าง",
    subdistrictEn: "Ban Chang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม บ้านช้าง ชลบุรี 20140",
    name_th: "พนัสนิคม บ้านช้าง ชลบุรี 20140",
    name_en: "Phanat Nikhom, Ban Chang, Chon Buri, 20140",
  },
  {
    id: 200620,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "โคกเพลาะ",
    subdistrictEn: "Khok Phlo",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม โคกเพลาะ ชลบุรี 20140",
    name_th: "พนัสนิคม โคกเพลาะ ชลบุรี 20140",
    name_en: "Phanat Nikhom, Khok Phlo, Chon Buri, 20140",
  },
  {
    id: 200621,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "ไร่หลักทอง",
    subdistrictEn: "Rai Lak Thong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม ไร่หลักทอง ชลบุรี 20140",
    name_th: "พนัสนิคม ไร่หลักทอง ชลบุรี 20140",
    name_en: "Phanat Nikhom, Rai Lak Thong, Chon Buri, 20140",
  },
  {
    id: 200622,
    districtTh: "พนัสนิคม",
    districtEn: "Phanat Nikhom",
    subdistrictTh: "นามะตูม",
    subdistrictEn: "Na Matum",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20140,
    district_code: 2006,
    province_code: 11,
    name: "พนัสนิคม นามะตูม ชลบุรี 20140",
    name_th: "พนัสนิคม นามะตูม ชลบุรี 20140",
    name_en: "Phanat Nikhom, Na Matum, Chon Buri, 20140",
  },
  {
    id: 200701,
    districtTh: "ศรีราชา",
    districtEn: "Si Racha",
    subdistrictTh: "ศรีราชา",
    subdistrictEn: "Si Racha",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20110,
    district_code: 2007,
    province_code: 11,
    name: "ศรีราชา ศรีราชา ชลบุรี 20110",
    name_th: "ศรีราชา ศรีราชา ชลบุรี 20110",
    name_en: "Si Racha, Si Racha, Chon Buri, 20110",
  },
  {
    id: 200702,
    districtTh: "ศรีราชา",
    districtEn: "Si Racha",
    subdistrictTh: "สุรศักดิ์",
    subdistrictEn: "Surasak",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20110,
    district_code: 2007,
    province_code: 11,
    name: "ศรีราชา สุรศักดิ์ ชลบุรี 20110",
    name_th: "ศรีราชา สุรศักดิ์ ชลบุรี 20110",
    name_en: "Si Racha, Surasak, Chon Buri, 20110",
  },
  {
    id: 200703,
    districtTh: "ศรีราชา",
    districtEn: "Si Racha",
    subdistrictTh: "ทุ่งสุขลา",
    subdistrictEn: "Thung Sukhla",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20230,
    district_code: 2007,
    province_code: 11,
    name: "ศรีราชา ทุ่งสุขลา ชลบุรี 20230",
    name_th: "ศรีราชา ทุ่งสุขลา ชลบุรี 20230",
    name_en: "Si Racha, Thung Sukhla, Chon Buri, 20230",
  },
  {
    id: 200704,
    districtTh: "ศรีราชา",
    districtEn: "Si Racha",
    subdistrictTh: "บึง",
    subdistrictEn: "Bueng",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20230,
    district_code: 2007,
    province_code: 11,
    name: "ศรีราชา บึง ชลบุรี 20230",
    name_th: "ศรีราชา บึง ชลบุรี 20230",
    name_en: "Si Racha, Bueng, Chon Buri, 20230",
  },
  {
    id: 200705,
    districtTh: "ศรีราชา",
    districtEn: "Si Racha",
    subdistrictTh: "หนองขาม",
    subdistrictEn: "Nong Kham",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20110,
    district_code: 2007,
    province_code: 11,
    name: "ศรีราชา หนองขาม ชลบุรี 20110",
    name_th: "ศรีราชา หนองขาม ชลบุรี 20110",
    name_en: "Si Racha, Nong Kham, Chon Buri, 20110",
  },
  {
    id: 200706,
    districtTh: "ศรีราชา",
    districtEn: "Si Racha",
    subdistrictTh: "เขาคันทรง",
    subdistrictEn: "Khao Khansong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20110,
    district_code: 2007,
    province_code: 11,
    name: "ศรีราชา เขาคันทรง ชลบุรี 20110",
    name_th: "ศรีราชา เขาคันทรง ชลบุรี 20110",
    name_en: "Si Racha, Khao Khansong, Chon Buri, 20110",
  },
  {
    id: 200707,
    districtTh: "ศรีราชา",
    districtEn: "Si Racha",
    subdistrictTh: "บางพระ",
    subdistrictEn: "Bang Phra",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20110,
    district_code: 2007,
    province_code: 11,
    name: "ศรีราชา บางพระ ชลบุรี 20110",
    name_th: "ศรีราชา บางพระ ชลบุรี 20110",
    name_en: "Si Racha, Bang Phra, Chon Buri, 20110",
  },
  {
    id: 200708,
    districtTh: "ศรีราชา",
    districtEn: "Si Racha",
    subdistrictTh: "บ่อวิน",
    subdistrictEn: "Bo Win",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20230,
    district_code: 2007,
    province_code: 11,
    name: "ศรีราชา บ่อวิน ชลบุรี 20230",
    name_th: "ศรีราชา บ่อวิน ชลบุรี 20230",
    name_en: "Si Racha, Bo Win, Chon Buri, 20230",
  },
  {
    id: 200801,
    districtTh: "เกาะสีชัง",
    districtEn: "Ko Sichang",
    subdistrictTh: "ท่าเทววงษ์",
    subdistrictEn: "Tha Tewatong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20120,
    district_code: 2008,
    province_code: 11,
    name: "เกาะสีชัง ท่าเทววงษ์ ชลบุรี 20120",
    name_th: "เกาะสีชัง ท่าเทววงษ์ ชลบุรี 20120",
    name_en: "Ko Sichang, Tha Tewatong, Chon Buri, 20120",
  },
  {
    id: 200901,
    districtTh: "สัตหีบ",
    districtEn: "Sattahip",
    subdistrictTh: "สัตหีบ",
    subdistrictEn: "Sattahip",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20180,
    district_code: 2009,
    province_code: 11,
    name: "สัตหีบ สัตหีบ ชลบุรี 20180",
    name_th: "สัตหีบ สัตหีบ ชลบุรี 20180",
    name_en: "Sattahip, Sattahip, Chon Buri, 20180",
  },
  {
    id: 200902,
    districtTh: "สัตหีบ",
    districtEn: "Sattahip",
    subdistrictTh: "นาจอมเทียน",
    subdistrictEn: "Na Chom Thian",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20250,
    district_code: 2009,
    province_code: 11,
    name: "สัตหีบ นาจอมเทียน ชลบุรี 20250",
    name_th: "สัตหีบ นาจอมเทียน ชลบุรี 20250",
    name_en: "Sattahip, Na Chom Thian, Chon Buri, 20250",
  },
  {
    id: 200903,
    districtTh: "สัตหีบ",
    districtEn: "Sattahip",
    subdistrictTh: "พลูตาหลวง",
    subdistrictEn: "Phlu Ta Luang",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20180,
    district_code: 2009,
    province_code: 11,
    name: "สัตหีบ พลูตาหลวง ชลบุรี 20180",
    name_th: "สัตหีบ พลูตาหลวง ชลบุรี 20180",
    name_en: "Sattahip, Phlu Ta Luang, Chon Buri, 20180",
  },
  {
    id: 200904,
    districtTh: "สัตหีบ",
    districtEn: "Sattahip",
    subdistrictTh: "บางเสร่",
    subdistrictEn: "Bang Sare",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20250,
    district_code: 2009,
    province_code: 11,
    name: "สัตหีบ บางเสร่ ชลบุรี 20250",
    name_th: "สัตหีบ บางเสร่ ชลบุรี 20250",
    name_en: "Sattahip, Bang Sare, Chon Buri, 20250",
  },
  {
    id: 200905,
    districtTh: "สัตหีบ",
    districtEn: "Sattahip",
    subdistrictTh: "แสมสาร",
    subdistrictEn: "Samaesan",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20180,
    district_code: 2009,
    province_code: 11,
    name: "สัตหีบ แสมสาร ชลบุรี 20180",
    name_th: "สัตหีบ แสมสาร ชลบุรี 20180",
    name_en: "Sattahip, Samaesan, Chon Buri, 20180",
  },
  {
    id: 201001,
    districtTh: "บ่อทอง",
    districtEn: "Bo Thong",
    subdistrictTh: "บ่อทอง",
    subdistrictEn: "Bo Thong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20270,
    district_code: 2010,
    province_code: 11,
    name: "บ่อทอง บ่อทอง ชลบุรี 20270",
    name_th: "บ่อทอง บ่อทอง ชลบุรี 20270",
    name_en: "Bo Thong, Bo Thong, Chon Buri, 20270",
  },
  {
    id: 201002,
    districtTh: "บ่อทอง",
    districtEn: "Bo Thong",
    subdistrictTh: "วัดสุวรรณ",
    subdistrictEn: "Wat Suwan",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20270,
    district_code: 2010,
    province_code: 11,
    name: "บ่อทอง วัดสุวรรณ ชลบุรี 20270",
    name_th: "บ่อทอง วัดสุวรรณ ชลบุรี 20270",
    name_en: "Bo Thong, Wat Suwan, Chon Buri, 20270",
  },
  {
    id: 201003,
    districtTh: "บ่อทอง",
    districtEn: "Bo Thong",
    subdistrictTh: "บ่อกวางทอง",
    subdistrictEn: "Bo Kwang Thong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20270,
    district_code: 2010,
    province_code: 11,
    name: "บ่อทอง บ่อกวางทอง ชลบุรี 20270",
    name_th: "บ่อทอง บ่อกวางทอง ชลบุรี 20270",
    name_en: "Bo Thong, Bo Kwang Thong, Chon Buri, 20270",
  },
  {
    id: 201004,
    districtTh: "บ่อทอง",
    districtEn: "Bo Thong",
    subdistrictTh: "ธาตุทอง",
    subdistrictEn: "That Thong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20270,
    district_code: 2010,
    province_code: 11,
    name: "บ่อทอง ธาตุทอง ชลบุรี 20270",
    name_th: "บ่อทอง ธาตุทอง ชลบุรี 20270",
    name_en: "Bo Thong, That Thong, Chon Buri, 20270",
  },
  {
    id: 201005,
    districtTh: "บ่อทอง",
    districtEn: "Bo Thong",
    subdistrictTh: "เกษตรสุวรรณ",
    subdistrictEn: "Kaset Suwan",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20270,
    district_code: 2010,
    province_code: 11,
    name: "บ่อทอง เกษตรสุวรรณ ชลบุรี 20270",
    name_th: "บ่อทอง เกษตรสุวรรณ ชลบุรี 20270",
    name_en: "Bo Thong, Kaset Suwan, Chon Buri, 20270",
  },
  {
    id: 201006,
    districtTh: "บ่อทอง",
    districtEn: "Bo Thong",
    subdistrictTh: "พลวงทอง",
    subdistrictEn: "Phluang Thong",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20270,
    district_code: 2010,
    province_code: 11,
    name: "บ่อทอง พลวงทอง ชลบุรี 20270",
    name_th: "บ่อทอง พลวงทอง ชลบุรี 20270",
    name_en: "Bo Thong, Phluang Thong, Chon Buri, 20270",
  },
  {
    id: 201101,
    districtTh: "เกาะจันทร์",
    districtEn: "Ko Chan",
    subdistrictTh: "เกาะจันทร์",
    subdistrictEn: "Ko Chan",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20240,
    district_code: 2011,
    province_code: 11,
    name: "เกาะจันทร์ เกาะจันทร์ ชลบุรี 20240",
    name_th: "เกาะจันทร์ เกาะจันทร์ ชลบุรี 20240",
    name_en: "Ko Chan, Ko Chan, Chon Buri, 20240",
  },
  {
    id: 201102,
    districtTh: "เกาะจันทร์",
    districtEn: "Ko Chan",
    subdistrictTh: "ท่าบุญมี",
    subdistrictEn: "Tha Bun Mi",
    provinceTh: "ชลบุรี",
    provinceEn: "Chon Buri",
    zipcode: 20240,
    district_code: 2011,
    province_code: 11,
    name: "เกาะจันทร์ ท่าบุญมี ชลบุรี 20240",
    name_th: "เกาะจันทร์ ท่าบุญมี ชลบุรี 20240",
    name_en: "Ko Chan, Tha Bun Mi, Chon Buri, 20240",
  },
  {
    id: 210101,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "ท่าประดู่",
    subdistrictEn: "Tha Pradu",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง ท่าประดู่ ระยอง 21000",
    name_th: "เมืองระยอง ท่าประดู่ ระยอง 21000",
    name_en: "Mueang Rayong, Tha Pradu, Rayong, 21000",
  },
  {
    id: 210102,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "เชิงเนิน",
    subdistrictEn: "Choeng Noen",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง เชิงเนิน ระยอง 21000",
    name_th: "เมืองระยอง เชิงเนิน ระยอง 21000",
    name_en: "Mueang Rayong, Choeng Noen, Rayong, 21000",
  },
  {
    id: 210103,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "ตะพง",
    subdistrictEn: "Taphong",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง ตะพง ระยอง 21000",
    name_th: "เมืองระยอง ตะพง ระยอง 21000",
    name_en: "Mueang Rayong, Taphong, Rayong, 21000",
  },
  {
    id: 210104,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง ปากน้ำ ระยอง 21000",
    name_th: "เมืองระยอง ปากน้ำ ระยอง 21000",
    name_en: "Mueang Rayong, Pak Nam, Rayong, 21000",
  },
  {
    id: 210105,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "เพ",
    subdistrictEn: "Phe",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21160,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง เพ ระยอง 21160",
    name_th: "เมืองระยอง เพ ระยอง 21160",
    name_en: "Mueang Rayong, Phe, Rayong, 21160",
  },
  {
    id: 210106,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "แกลง",
    subdistrictEn: "Klaeng",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21160,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง แกลง ระยอง 21160",
    name_th: "เมืองระยอง แกลง ระยอง 21160",
    name_en: "Mueang Rayong, Klaeng, Rayong, 21160",
  },
  {
    id: 210107,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "บ้านแลง",
    subdistrictEn: "Ban Laeng",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง บ้านแลง ระยอง 21000",
    name_th: "เมืองระยอง บ้านแลง ระยอง 21000",
    name_en: "Mueang Rayong, Ban Laeng, Rayong, 21000",
  },
  {
    id: 210108,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "นาตาขวัญ",
    subdistrictEn: "Na Ta Khwan",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง นาตาขวัญ ระยอง 21000",
    name_th: "เมืองระยอง นาตาขวัญ ระยอง 21000",
    name_en: "Mueang Rayong, Na Ta Khwan, Rayong, 21000",
  },
  {
    id: 210109,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "เนินพระ",
    subdistrictEn: "Noen Phra",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง เนินพระ ระยอง 21000",
    name_th: "เมืองระยอง เนินพระ ระยอง 21000",
    name_en: "Mueang Rayong, Noen Phra, Rayong, 21000",
  },
  {
    id: 210110,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "กะเฉด",
    subdistrictEn: "Kachet",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21100,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง กะเฉด ระยอง 21100",
    name_th: "เมืองระยอง กะเฉด ระยอง 21100",
    name_en: "Mueang Rayong, Kachet, Rayong, 21100",
  },
  {
    id: 210111,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "ทับมา",
    subdistrictEn: "Thap Ma",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง ทับมา ระยอง 21000",
    name_th: "เมืองระยอง ทับมา ระยอง 21000",
    name_en: "Mueang Rayong, Thap Ma, Rayong, 21000",
  },
  {
    id: 210112,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "น้ำคอก",
    subdistrictEn: "Nam Khok",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21000,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง น้ำคอก ระยอง 21000",
    name_th: "เมืองระยอง น้ำคอก ระยอง 21000",
    name_en: "Mueang Rayong, Nam Khok, Rayong, 21000",
  },
  {
    id: 210113,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "ห้วยโป่ง",
    subdistrictEn: "Huai Pong",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21150,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง ห้วยโป่ง ระยอง 21150",
    name_th: "เมืองระยอง ห้วยโป่ง ระยอง 21150",
    name_en: "Mueang Rayong, Huai Pong, Rayong, 21150",
  },
  {
    id: 210114,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "มาบตาพุด",
    subdistrictEn: "Map Ta Phut",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21150,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง มาบตาพุด ระยอง 21150",
    name_th: "เมืองระยอง มาบตาพุด ระยอง 21150",
    name_en: "Mueang Rayong, Map Ta Phut, Rayong, 21150",
  },
  {
    id: 210115,
    districtTh: "เมืองระยอง",
    districtEn: "Mueang Rayong",
    subdistrictTh: "สำนักทอง",
    subdistrictEn: "Samnak Thong",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21100,
    district_code: 2101,
    province_code: 12,
    name: "เมืองระยอง สำนักทอง ระยอง 21100",
    name_th: "เมืองระยอง สำนักทอง ระยอง 21100",
    name_en: "Mueang Rayong, Samnak Thong, Rayong, 21100",
  },
  {
    id: 210201,
    districtTh: "บ้านฉาง",
    districtEn: "Ban Chang",
    subdistrictTh: "สำนักท้อน",
    subdistrictEn: "Samnak Thon",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21130,
    district_code: 2102,
    province_code: 12,
    name: "บ้านฉาง สำนักท้อน ระยอง 21130",
    name_th: "บ้านฉาง สำนักท้อน ระยอง 21130",
    name_en: "Ban Chang, Samnak Thon, Rayong, 21130",
  },
  {
    id: 210202,
    districtTh: "บ้านฉาง",
    districtEn: "Ban Chang",
    subdistrictTh: "พลา",
    subdistrictEn: "Phla",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21130,
    district_code: 2102,
    province_code: 12,
    name: "บ้านฉาง พลา ระยอง 21130",
    name_th: "บ้านฉาง พลา ระยอง 21130",
    name_en: "Ban Chang, Phla, Rayong, 21130",
  },
  {
    id: 210203,
    districtTh: "บ้านฉาง",
    districtEn: "Ban Chang",
    subdistrictTh: "บ้านฉาง",
    subdistrictEn: "Ban Chang",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21130,
    district_code: 2102,
    province_code: 12,
    name: "บ้านฉาง บ้านฉาง ระยอง 21130",
    name_th: "บ้านฉาง บ้านฉาง ระยอง 21130",
    name_en: "Ban Chang, Ban Chang, Rayong, 21130",
  },
  {
    id: 210301,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "ทางเกวียน",
    subdistrictEn: "Thang Kwian",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง ทางเกวียน ระยอง 21110",
    name_th: "แกลง ทางเกวียน ระยอง 21110",
    name_en: "Klaeng, Thang Kwian, Rayong, 21110",
  },
  {
    id: 210302,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "วังหว้า",
    subdistrictEn: "Wang Wa",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง วังหว้า ระยอง 21110",
    name_th: "แกลง วังหว้า ระยอง 21110",
    name_en: "Klaeng, Wang Wa, Rayong, 21110",
  },
  {
    id: 210303,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "ชากโดน",
    subdistrictEn: "Chak Don",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง ชากโดน ระยอง 21110",
    name_th: "แกลง ชากโดน ระยอง 21110",
    name_en: "Klaeng, Chak Don, Rayong, 21110",
  },
  {
    id: 210304,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "เนินฆ้อ",
    subdistrictEn: "Noen Kho",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง เนินฆ้อ ระยอง 21110",
    name_th: "แกลง เนินฆ้อ ระยอง 21110",
    name_en: "Klaeng, Noen Kho, Rayong, 21110",
  },
  {
    id: 210305,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "กร่ำ",
    subdistrictEn: "Kram",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21190,
    district_code: 2103,
    province_code: 12,
    name: "แกลง กร่ำ ระยอง 21190",
    name_th: "แกลง กร่ำ ระยอง 21190",
    name_en: "Klaeng, Kram, Rayong, 21190",
  },
  {
    id: 210306,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "ชากพง",
    subdistrictEn: "Chak Phong",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21190,
    district_code: 2103,
    province_code: 12,
    name: "แกลง ชากพง ระยอง 21190",
    name_th: "แกลง ชากพง ระยอง 21190",
    name_en: "Klaeng, Chak Phong, Rayong, 21190",
  },
  {
    id: 210307,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "กระแสบน",
    subdistrictEn: "Krasae Bon",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง กระแสบน ระยอง 21110",
    name_th: "แกลง กระแสบน ระยอง 21110",
    name_en: "Klaeng, Krasae Bon, Rayong, 21110",
  },
  {
    id: 210308,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง บ้านนา ระยอง 21110",
    name_th: "แกลง บ้านนา ระยอง 21110",
    name_en: "Klaeng, Ban Na, Rayong, 21110",
  },
  {
    id: 210309,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "ทุ่งควายกิน",
    subdistrictEn: "Thung Khwai Kin",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง ทุ่งควายกิน ระยอง 21110",
    name_th: "แกลง ทุ่งควายกิน ระยอง 21110",
    name_en: "Klaeng, Thung Khwai Kin, Rayong, 21110",
  },
  {
    id: 210310,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "กองดิน",
    subdistrictEn: "Kong Din",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 22160,
    district_code: 2103,
    province_code: 12,
    name: "แกลง กองดิน ระยอง 22160",
    name_th: "แกลง กองดิน ระยอง 22160",
    name_en: "Klaeng, Kong Din, Rayong, 22160",
  },
  {
    id: 210311,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "คลองปูน",
    subdistrictEn: "Khlong Pun",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21170,
    district_code: 2103,
    province_code: 12,
    name: "แกลง คลองปูน ระยอง 21170",
    name_th: "แกลง คลองปูน ระยอง 21170",
    name_en: "Klaeng, Khlong Pun, Rayong, 21170",
  },
  {
    id: 210312,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "พังราด",
    subdistrictEn: "Phang Rat",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง พังราด ระยอง 21110",
    name_th: "แกลง พังราด ระยอง 21110",
    name_en: "Klaeng, Phang Rat, Rayong, 21110",
  },
  {
    id: 210313,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "ปากน้ำกระแส",
    subdistrictEn: "Pak Nam Krasae",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21170,
    district_code: 2103,
    province_code: 12,
    name: "แกลง ปากน้ำกระแส ระยอง 21170",
    name_th: "แกลง ปากน้ำกระแส ระยอง 21170",
    name_en: "Klaeng, Pak Nam Krasae, Rayong, 21170",
  },
  {
    id: 210317,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "ห้วยยาง",
    subdistrictEn: "Huai Yang",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง ห้วยยาง ระยอง 21110",
    name_th: "แกลง ห้วยยาง ระยอง 21110",
    name_en: "Klaeng, Huai Yang, Rayong, 21110",
  },
  {
    id: 210318,
    districtTh: "แกลง",
    districtEn: "Klaeng",
    subdistrictTh: "สองสลึง",
    subdistrictEn: "Song Salueng",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2103,
    province_code: 12,
    name: "แกลง สองสลึง ระยอง 21110",
    name_th: "แกลง สองสลึง ระยอง 21110",
    name_en: "Klaeng, Song Salueng, Rayong, 21110",
  },
  {
    id: 210401,
    districtTh: "วังจันทร์",
    districtEn: "Wang Chan",
    subdistrictTh: "วังจันทร์",
    subdistrictEn: "Wang Chan",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21210,
    district_code: 2104,
    province_code: 12,
    name: "วังจันทร์ วังจันทร์ ระยอง 21210",
    name_th: "วังจันทร์ วังจันทร์ ระยอง 21210",
    name_en: "Wang Chan, Wang Chan, Rayong, 21210",
  },
  {
    id: 210402,
    districtTh: "วังจันทร์",
    districtEn: "Wang Chan",
    subdistrictTh: "ชุมแสง",
    subdistrictEn: "Chum Saeng",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21210,
    district_code: 2104,
    province_code: 12,
    name: "วังจันทร์ ชุมแสง ระยอง 21210",
    name_th: "วังจันทร์ ชุมแสง ระยอง 21210",
    name_en: "Wang Chan, Chum Saeng, Rayong, 21210",
  },
  {
    id: 210403,
    districtTh: "วังจันทร์",
    districtEn: "Wang Chan",
    subdistrictTh: "ป่ายุบใน",
    subdistrictEn: "Pa Yup Nai",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21210,
    district_code: 2104,
    province_code: 12,
    name: "วังจันทร์ ป่ายุบใน ระยอง 21210",
    name_th: "วังจันทร์ ป่ายุบใน ระยอง 21210",
    name_en: "Wang Chan, Pa Yup Nai, Rayong, 21210",
  },
  {
    id: 210404,
    districtTh: "วังจันทร์",
    districtEn: "Wang Chan",
    subdistrictTh: "พลงตาเอี่ยม",
    subdistrictEn: "Phlong Ta Iam",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21210,
    district_code: 2104,
    province_code: 12,
    name: "วังจันทร์ พลงตาเอี่ยม ระยอง 21210",
    name_th: "วังจันทร์ พลงตาเอี่ยม ระยอง 21210",
    name_en: "Wang Chan, Phlong Ta Iam, Rayong, 21210",
  },
  {
    id: 210501,
    districtTh: "บ้านค่าย",
    districtEn: "Ban Khai",
    subdistrictTh: "บ้านค่าย",
    subdistrictEn: "Ban Khai",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21120,
    district_code: 2105,
    province_code: 12,
    name: "บ้านค่าย บ้านค่าย ระยอง 21120",
    name_th: "บ้านค่าย บ้านค่าย ระยอง 21120",
    name_en: "Ban Khai, Ban Khai, Rayong, 21120",
  },
  {
    id: 210502,
    districtTh: "บ้านค่าย",
    districtEn: "Ban Khai",
    subdistrictTh: "หนองละลอก",
    subdistrictEn: "Nong Lalok",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21120,
    district_code: 2105,
    province_code: 12,
    name: "บ้านค่าย หนองละลอก ระยอง 21120",
    name_th: "บ้านค่าย หนองละลอก ระยอง 21120",
    name_en: "Ban Khai, Nong Lalok, Rayong, 21120",
  },
  {
    id: 210503,
    districtTh: "บ้านค่าย",
    districtEn: "Ban Khai",
    subdistrictTh: "หนองตะพาน",
    subdistrictEn: "Nong Taphan",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21120,
    district_code: 2105,
    province_code: 12,
    name: "บ้านค่าย หนองตะพาน ระยอง 21120",
    name_th: "บ้านค่าย หนองตะพาน ระยอง 21120",
    name_en: "Ban Khai, Nong Taphan, Rayong, 21120",
  },
  {
    id: 210504,
    districtTh: "บ้านค่าย",
    districtEn: "Ban Khai",
    subdistrictTh: "ตาขัน",
    subdistrictEn: "Ta Khan",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21120,
    district_code: 2105,
    province_code: 12,
    name: "บ้านค่าย ตาขัน ระยอง 21120",
    name_th: "บ้านค่าย ตาขัน ระยอง 21120",
    name_en: "Ban Khai, Ta Khan, Rayong, 21120",
  },
  {
    id: 210505,
    districtTh: "บ้านค่าย",
    districtEn: "Ban Khai",
    subdistrictTh: "บางบุตร",
    subdistrictEn: "Bang But",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21120,
    district_code: 2105,
    province_code: 12,
    name: "บ้านค่าย บางบุตร ระยอง 21120",
    name_th: "บ้านค่าย บางบุตร ระยอง 21120",
    name_en: "Ban Khai, Bang But, Rayong, 21120",
  },
  {
    id: 210506,
    districtTh: "บ้านค่าย",
    districtEn: "Ban Khai",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21120,
    district_code: 2105,
    province_code: 12,
    name: "บ้านค่าย หนองบัว ระยอง 21120",
    name_th: "บ้านค่าย หนองบัว ระยอง 21120",
    name_en: "Ban Khai, Nong Bua, Rayong, 21120",
  },
  {
    id: 210507,
    districtTh: "บ้านค่าย",
    districtEn: "Ban Khai",
    subdistrictTh: "ชากบก",
    subdistrictEn: "Chak Bok",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21120,
    district_code: 2105,
    province_code: 12,
    name: "บ้านค่าย ชากบก ระยอง 21120",
    name_th: "บ้านค่าย ชากบก ระยอง 21120",
    name_en: "Ban Khai, Chak Bok, Rayong, 21120",
  },
  {
    id: 210601,
    districtTh: "ปลวกแดง",
    districtEn: "Pluak Daeng",
    subdistrictTh: "ปลวกแดง",
    subdistrictEn: "Pluak Daeng",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21140,
    district_code: 2106,
    province_code: 12,
    name: "ปลวกแดง ปลวกแดง ระยอง 21140",
    name_th: "ปลวกแดง ปลวกแดง ระยอง 21140",
    name_en: "Pluak Daeng, Pluak Daeng, Rayong, 21140",
  },
  {
    id: 210602,
    districtTh: "ปลวกแดง",
    districtEn: "Pluak Daeng",
    subdistrictTh: "ตาสิทธิ์",
    subdistrictEn: "Ta Sit",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21140,
    district_code: 2106,
    province_code: 12,
    name: "ปลวกแดง ตาสิทธิ์ ระยอง 21140",
    name_th: "ปลวกแดง ตาสิทธิ์ ระยอง 21140",
    name_en: "Pluak Daeng, Ta Sit, Rayong, 21140",
  },
  {
    id: 210603,
    districtTh: "ปลวกแดง",
    districtEn: "Pluak Daeng",
    subdistrictTh: "ละหาร",
    subdistrictEn: "Lahan",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21140,
    district_code: 2106,
    province_code: 12,
    name: "ปลวกแดง ละหาร ระยอง 21140",
    name_th: "ปลวกแดง ละหาร ระยอง 21140",
    name_en: "Pluak Daeng, Lahan, Rayong, 21140",
  },
  {
    id: 210604,
    districtTh: "ปลวกแดง",
    districtEn: "Pluak Daeng",
    subdistrictTh: "แม่น้ำคู้",
    subdistrictEn: "Maenam Khu",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21140,
    district_code: 2106,
    province_code: 12,
    name: "ปลวกแดง แม่น้ำคู้ ระยอง 21140",
    name_th: "ปลวกแดง แม่น้ำคู้ ระยอง 21140",
    name_en: "Pluak Daeng, Maenam Khu, Rayong, 21140",
  },
  {
    id: 210605,
    districtTh: "ปลวกแดง",
    districtEn: "Pluak Daeng",
    subdistrictTh: "มาบยางพร",
    subdistrictEn: "Map Yang Phon",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21140,
    district_code: 2106,
    province_code: 12,
    name: "ปลวกแดง มาบยางพร ระยอง 21140",
    name_th: "ปลวกแดง มาบยางพร ระยอง 21140",
    name_en: "Pluak Daeng, Map Yang Phon, Rayong, 21140",
  },
  {
    id: 210606,
    districtTh: "ปลวกแดง",
    districtEn: "Pluak Daeng",
    subdistrictTh: "หนองไร่",
    subdistrictEn: "Nong Rai",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21140,
    district_code: 2106,
    province_code: 12,
    name: "ปลวกแดง หนองไร่ ระยอง 21140",
    name_th: "ปลวกแดง หนองไร่ ระยอง 21140",
    name_en: "Pluak Daeng, Nong Rai, Rayong, 21140",
  },
  {
    id: 210701,
    districtTh: "เขาชะเมา",
    districtEn: "Khao Chamao",
    subdistrictTh: "น้ำเป็น",
    subdistrictEn: "Nam Pen",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2107,
    province_code: 12,
    name: "เขาชะเมา น้ำเป็น ระยอง 21110",
    name_th: "เขาชะเมา น้ำเป็น ระยอง 21110",
    name_en: "Khao Chamao, Nam Pen, Rayong, 21110",
  },
  {
    id: 210702,
    districtTh: "เขาชะเมา",
    districtEn: "Khao Chamao",
    subdistrictTh: "ห้วยทับมอญ",
    subdistrictEn: "Huai Thap Mon",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2107,
    province_code: 12,
    name: "เขาชะเมา ห้วยทับมอญ ระยอง 21110",
    name_th: "เขาชะเมา ห้วยทับมอญ ระยอง 21110",
    name_en: "Khao Chamao, Huai Thap Mon, Rayong, 21110",
  },
  {
    id: 210703,
    districtTh: "เขาชะเมา",
    districtEn: "Khao Chamao",
    subdistrictTh: "ชำฆ้อ",
    subdistrictEn: "Cham Kho",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2107,
    province_code: 12,
    name: "เขาชะเมา ชำฆ้อ ระยอง 21110",
    name_th: "เขาชะเมา ชำฆ้อ ระยอง 21110",
    name_en: "Khao Chamao, Cham Kho, Rayong, 21110",
  },
  {
    id: 210704,
    districtTh: "เขาชะเมา",
    districtEn: "Khao Chamao",
    subdistrictTh: "เขาน้อย",
    subdistrictEn: "Khao Noy",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21110,
    district_code: 2107,
    province_code: 12,
    name: "เขาชะเมา เขาน้อย ระยอง 21110",
    name_th: "เขาชะเมา เขาน้อย ระยอง 21110",
    name_en: "Khao Chamao, Khao Noy, Rayong, 21110",
  },
  {
    id: 210801,
    districtTh: "นิคมพัฒนา",
    districtEn: "Nikhom Phatthana",
    subdistrictTh: "นิคมพัฒนา",
    subdistrictEn: "Nikhom Phatthana",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21180,
    district_code: 2108,
    province_code: 12,
    name: "นิคมพัฒนา นิคมพัฒนา ระยอง 21180",
    name_th: "นิคมพัฒนา นิคมพัฒนา ระยอง 21180",
    name_en: "Nikhom Phatthana, Nikhom Phatthana, Rayong, 21180",
  },
  {
    id: 210802,
    districtTh: "นิคมพัฒนา",
    districtEn: "Nikhom Phatthana",
    subdistrictTh: "มาบข่า",
    subdistrictEn: "Map Kha",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21180,
    district_code: 2108,
    province_code: 12,
    name: "นิคมพัฒนา มาบข่า ระยอง 21180",
    name_th: "นิคมพัฒนา มาบข่า ระยอง 21180",
    name_en: "Nikhom Phatthana, Map Kha, Rayong, 21180",
  },
  {
    id: 210803,
    districtTh: "นิคมพัฒนา",
    districtEn: "Nikhom Phatthana",
    subdistrictTh: "พนานิคม",
    subdistrictEn: "Phana Nikhom",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21180,
    district_code: 2108,
    province_code: 12,
    name: "นิคมพัฒนา พนานิคม ระยอง 21180",
    name_th: "นิคมพัฒนา พนานิคม ระยอง 21180",
    name_en: "Nikhom Phatthana, Phana Nikhom, Rayong, 21180",
  },
  {
    id: 210804,
    districtTh: "นิคมพัฒนา",
    districtEn: "Nikhom Phatthana",
    subdistrictTh: "มะขามคู่",
    subdistrictEn: "Makham Khu",
    provinceTh: "ระยอง",
    provinceEn: "Rayong",
    zipcode: 21180,
    district_code: 2108,
    province_code: 12,
    name: "นิคมพัฒนา มะขามคู่ ระยอง 21180",
    name_th: "นิคมพัฒนา มะขามคู่ ระยอง 21180",
    name_en: "Nikhom Phatthana, Makham Khu, Rayong, 21180",
  },
  {
    id: 220101,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "ตลาด",
    subdistrictEn: "Talat",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี ตลาด จันทบุรี 22000",
    name_th: "เมืองจันทบุรี ตลาด จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Talat, Chanthaburi, 22000",
  },
  {
    id: 220102,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "วัดใหม่",
    subdistrictEn: "Wat Mai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี วัดใหม่ จันทบุรี 22000",
    name_th: "เมืองจันทบุรี วัดใหม่ จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Wat Mai, Chanthaburi, 22000",
  },
  {
    id: 220103,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "คลองนารายณ์",
    subdistrictEn: "Khlong Narai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี คลองนารายณ์ จันทบุรี 22000",
    name_th: "เมืองจันทบุรี คลองนารายณ์ จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Khlong Narai, Chanthaburi, 22000",
  },
  {
    id: 220104,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "เกาะขวาง",
    subdistrictEn: "Ko Khwang",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี เกาะขวาง จันทบุรี 22000",
    name_th: "เมืองจันทบุรี เกาะขวาง จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Ko Khwang, Chanthaburi, 22000",
  },
  {
    id: 220105,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "คมบาง",
    subdistrictEn: "Khom Bang",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี คมบาง จันทบุรี 22000",
    name_th: "เมืองจันทบุรี คมบาง จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Khom Bang, Chanthaburi, 22000",
  },
  {
    id: 220106,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี ท่าช้าง จันทบุรี 22000",
    name_th: "เมืองจันทบุรี ท่าช้าง จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Tha Chang, Chanthaburi, 22000",
  },
  {
    id: 220107,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "จันทนิมิต",
    subdistrictEn: "Chanthanimit",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี จันทนิมิต จันทบุรี 22000",
    name_th: "เมืองจันทบุรี จันทนิมิต จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Chanthanimit, Chanthaburi, 22000",
  },
  {
    id: 220108,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "บางกะจะ",
    subdistrictEn: "Bang Kacha",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี บางกะจะ จันทบุรี 22000",
    name_th: "เมืองจันทบุรี บางกะจะ จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Bang Kacha, Chanthaburi, 22000",
  },
  {
    id: 220109,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "แสลง",
    subdistrictEn: "Salaeng",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี แสลง จันทบุรี 22000",
    name_th: "เมืองจันทบุรี แสลง จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Salaeng, Chanthaburi, 22000",
  },
  {
    id: 220110,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี หนองบัว จันทบุรี 22000",
    name_th: "เมืองจันทบุรี หนองบัว จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Nong Bua, Chanthaburi, 22000",
  },
  {
    id: 220111,
    districtTh: "เมืองจันทบุรี",
    districtEn: "Mueang Chanthaburi",
    subdistrictTh: "พลับพลา",
    subdistrictEn: "Phlapphla",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22000,
    district_code: 2201,
    province_code: 13,
    name: "เมืองจันทบุรี พลับพลา จันทบุรี 22000",
    name_th: "เมืองจันทบุรี พลับพลา จันทบุรี 22000",
    name_en: "Mueang Chanthaburi, Phlapphla, Chanthaburi, 22000",
  },
  {
    id: 220201,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "ขลุง",
    subdistrictEn: "Khlung",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง ขลุง จันทบุรี 22110",
    name_th: "ขลุง ขลุง จันทบุรี 22110",
    name_en: "Khlung, Khlung, Chanthaburi, 22110",
  },
  {
    id: 220202,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "บ่อ",
    subdistrictEn: "Bo",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง บ่อ จันทบุรี 22110",
    name_th: "ขลุง บ่อ จันทบุรี 22110",
    name_en: "Khlung, Bo, Chanthaburi, 22110",
  },
  {
    id: 220203,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "เกวียนหัก",
    subdistrictEn: "Kwian Hak",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง เกวียนหัก จันทบุรี 22110",
    name_th: "ขลุง เกวียนหัก จันทบุรี 22110",
    name_en: "Khlung, Kwian Hak, Chanthaburi, 22110",
  },
  {
    id: 220204,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "ตะปอน",
    subdistrictEn: "Tapon",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง ตะปอน จันทบุรี 22110",
    name_th: "ขลุง ตะปอน จันทบุรี 22110",
    name_en: "Khlung, Tapon, Chanthaburi, 22110",
  },
  {
    id: 220205,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "บางชัน",
    subdistrictEn: "Bang Chan",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง บางชัน จันทบุรี 22110",
    name_th: "ขลุง บางชัน จันทบุรี 22110",
    name_en: "Khlung, Bang Chan, Chanthaburi, 22110",
  },
  {
    id: 220206,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "วันยาว",
    subdistrictEn: "Wan Yao",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง วันยาว จันทบุรี 22110",
    name_th: "ขลุง วันยาว จันทบุรี 22110",
    name_en: "Khlung, Wan Yao, Chanthaburi, 22110",
  },
  {
    id: 220207,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "ซึ้ง",
    subdistrictEn: "Sueng",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง ซึ้ง จันทบุรี 22110",
    name_th: "ขลุง ซึ้ง จันทบุรี 22110",
    name_en: "Khlung, Sueng, Chanthaburi, 22110",
  },
  {
    id: 220208,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "มาบไพ",
    subdistrictEn: "Map Phai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง มาบไพ จันทบุรี 22110",
    name_th: "ขลุง มาบไพ จันทบุรี 22110",
    name_en: "Khlung, Map Phai, Chanthaburi, 22110",
  },
  {
    id: 220209,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "วังสรรพรส",
    subdistrictEn: "Wang Sappharot",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง วังสรรพรส จันทบุรี 22110",
    name_th: "ขลุง วังสรรพรส จันทบุรี 22110",
    name_en: "Khlung, Wang Sappharot, Chanthaburi, 22110",
  },
  {
    id: 220210,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "ตรอกนอง",
    subdistrictEn: "Trok Nong",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง ตรอกนอง จันทบุรี 22110",
    name_th: "ขลุง ตรอกนอง จันทบุรี 22110",
    name_en: "Khlung, Trok Nong, Chanthaburi, 22110",
  },
  {
    id: 220211,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "ตกพรม",
    subdistrictEn: "Tok Phrom",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22110,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง ตกพรม จันทบุรี 22110",
    name_th: "ขลุง ตกพรม จันทบุรี 22110",
    name_en: "Khlung, Tok Phrom, Chanthaburi, 22110",
  },
  {
    id: 220212,
    districtTh: "ขลุง",
    districtEn: "Khlung",
    subdistrictTh: "บ่อเวฬุ",
    subdistrictEn: "Bo Welu",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22150,
    district_code: 2202,
    province_code: 13,
    name: "ขลุง บ่อเวฬุ จันทบุรี 22150",
    name_th: "ขลุง บ่อเวฬุ จันทบุรี 22150",
    name_en: "Khlung, Bo Welu, Chanthaburi, 22150",
  },
  {
    id: 220301,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "ท่าใหม่",
    subdistrictEn: "Tha Mai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ ท่าใหม่ จันทบุรี 22120",
    name_th: "ท่าใหม่ ท่าใหม่ จันทบุรี 22120",
    name_en: "Tha Mai, Tha Mai, Chanthaburi, 22120",
  },
  {
    id: 220302,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "ยายร้า",
    subdistrictEn: "Yai Ra",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ ยายร้า จันทบุรี 22120",
    name_th: "ท่าใหม่ ยายร้า จันทบุรี 22120",
    name_en: "Tha Mai, Yai Ra, Chanthaburi, 22120",
  },
  {
    id: 220303,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "สีพยา",
    subdistrictEn: "Si Phaya",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ สีพยา จันทบุรี 22120",
    name_th: "ท่าใหม่ สีพยา จันทบุรี 22120",
    name_en: "Tha Mai, Si Phaya, Chanthaburi, 22120",
  },
  {
    id: 220304,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "บ่อพุ",
    subdistrictEn: "Bo Phu",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ บ่อพุ จันทบุรี 22120",
    name_th: "ท่าใหม่ บ่อพุ จันทบุรี 22120",
    name_en: "Tha Mai, Bo Phu, Chanthaburi, 22120",
  },
  {
    id: 220305,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "พลอยแหวน",
    subdistrictEn: "Phloi Waen",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ พลอยแหวน จันทบุรี 22120",
    name_th: "ท่าใหม่ พลอยแหวน จันทบุรี 22120",
    name_en: "Tha Mai, Phloi Waen, Chanthaburi, 22120",
  },
  {
    id: 220306,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "เขาวัว",
    subdistrictEn: "Khao Wua",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ เขาวัว จันทบุรี 22120",
    name_th: "ท่าใหม่ เขาวัว จันทบุรี 22120",
    name_en: "Tha Mai, Khao Wua, Chanthaburi, 22120",
  },
  {
    id: 220307,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "เขาบายศรี",
    subdistrictEn: "Khao Baisi",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ เขาบายศรี จันทบุรี 22120",
    name_th: "ท่าใหม่ เขาบายศรี จันทบุรี 22120",
    name_en: "Tha Mai, Khao Baisi, Chanthaburi, 22120",
  },
  {
    id: 220308,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "สองพี่น้อง",
    subdistrictEn: "Song Phi Nong",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ สองพี่น้อง จันทบุรี 22120",
    name_th: "ท่าใหม่ สองพี่น้อง จันทบุรี 22120",
    name_en: "Tha Mai, Song Phi Nong, Chanthaburi, 22120",
  },
  {
    id: 220309,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "ทุ่งเบญจา",
    subdistrictEn: "Ramphan",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22170,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ ทุ่งเบญจา จันทบุรี 22170",
    name_th: "ท่าใหม่ ทุ่งเบญจา จันทบุรี 22170",
    name_en: "Tha Mai, Ramphan, Chanthaburi, 22170",
  },
  {
    id: 220311,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "รำพัน",
    subdistrictEn: "Ramphan",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22170,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ รำพัน จันทบุรี 22170",
    name_th: "ท่าใหม่ รำพัน จันทบุรี 22170",
    name_en: "Tha Mai, Ramphan, Chanthaburi, 22170",
  },
  {
    id: 220312,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "โขมง",
    subdistrictEn: "Khamong",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22170,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ โขมง จันทบุรี 22170",
    name_th: "ท่าใหม่ โขมง จันทบุรี 22170",
    name_en: "Tha Mai, Khamong, Chanthaburi, 22170",
  },
  {
    id: 220313,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "ตะกาดเง้า",
    subdistrictEn: "Takat Ngao",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ ตะกาดเง้า จันทบุรี 22120",
    name_th: "ท่าใหม่ ตะกาดเง้า จันทบุรี 22120",
    name_en: "Tha Mai, Takat Ngao, Chanthaburi, 22120",
  },
  {
    id: 220314,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "คลองขุด",
    subdistrictEn: "Khlong Khut",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ คลองขุด จันทบุรี 22120",
    name_th: "ท่าใหม่ คลองขุด จันทบุรี 22120",
    name_en: "Tha Mai, Khlong Khut, Chanthaburi, 22120",
  },
  {
    id: 220324,
    districtTh: "ท่าใหม่",
    districtEn: "Tha Mai",
    subdistrictTh: "เขาแก้ว",
    subdistrictEn: "Khao Kaeo",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22170,
    district_code: 2203,
    province_code: 13,
    name: "ท่าใหม่ เขาแก้ว จันทบุรี 22170",
    name_th: "ท่าใหม่ เขาแก้ว จันทบุรี 22170",
    name_en: "Tha Mai, Khao Kaeo, Chanthaburi, 22170",
  },
  {
    id: 220401,
    districtTh: "โป่งน้ำร้อน",
    districtEn: "Pong Nam Ron",
    subdistrictTh: "ทับไทร",
    subdistrictEn: "Thap Sai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22140,
    district_code: 2204,
    province_code: 13,
    name: "โป่งน้ำร้อน ทับไทร จันทบุรี 22140",
    name_th: "โป่งน้ำร้อน ทับไทร จันทบุรี 22140",
    name_en: "Pong Nam Ron, Thap Sai, Chanthaburi, 22140",
  },
  {
    id: 220402,
    districtTh: "โป่งน้ำร้อน",
    districtEn: "Pong Nam Ron",
    subdistrictTh: "โป่งน้ำร้อน",
    subdistrictEn: "Pong Nam Ron",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22140,
    district_code: 2204,
    province_code: 13,
    name: "โป่งน้ำร้อน โป่งน้ำร้อน จันทบุรี 22140",
    name_th: "โป่งน้ำร้อน โป่งน้ำร้อน จันทบุรี 22140",
    name_en: "Pong Nam Ron, Pong Nam Ron, Chanthaburi, 22140",
  },
  {
    id: 220404,
    districtTh: "โป่งน้ำร้อน",
    districtEn: "Pong Nam Ron",
    subdistrictTh: "หนองตาคง",
    subdistrictEn: "Nong Ta Khong",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22140,
    district_code: 2204,
    province_code: 13,
    name: "โป่งน้ำร้อน หนองตาคง จันทบุรี 22140",
    name_th: "โป่งน้ำร้อน หนองตาคง จันทบุรี 22140",
    name_en: "Pong Nam Ron, Nong Ta Khong, Chanthaburi, 22140",
  },
  {
    id: 220409,
    districtTh: "โป่งน้ำร้อน",
    districtEn: "Pong Nam Ron",
    subdistrictTh: "เทพนิมิต",
    subdistrictEn: "Thep Nimit",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22140,
    district_code: 2204,
    province_code: 13,
    name: "โป่งน้ำร้อน เทพนิมิต จันทบุรี 22140",
    name_th: "โป่งน้ำร้อน เทพนิมิต จันทบุรี 22140",
    name_en: "Pong Nam Ron, Thep Nimit, Chanthaburi, 22140",
  },
  {
    id: 220410,
    districtTh: "โป่งน้ำร้อน",
    districtEn: "Pong Nam Ron",
    subdistrictTh: "คลองใหญ่",
    subdistrictEn: "Khlong Yai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22140,
    district_code: 2204,
    province_code: 13,
    name: "โป่งน้ำร้อน คลองใหญ่ จันทบุรี 22140",
    name_th: "โป่งน้ำร้อน คลองใหญ่ จันทบุรี 22140",
    name_en: "Pong Nam Ron, Khlong Yai, Chanthaburi, 22140",
  },
  {
    id: 220501,
    districtTh: "มะขาม",
    districtEn: "Makham",
    subdistrictTh: "มะขาม",
    subdistrictEn: "Makham",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22150,
    district_code: 2205,
    province_code: 13,
    name: "มะขาม มะขาม จันทบุรี 22150",
    name_th: "มะขาม มะขาม จันทบุรี 22150",
    name_en: "Makham, Makham, Chanthaburi, 22150",
  },
  {
    id: 220502,
    districtTh: "มะขาม",
    districtEn: "Makham",
    subdistrictTh: "ท่าหลวง",
    subdistrictEn: "Tha Luang",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22150,
    district_code: 2205,
    province_code: 13,
    name: "มะขาม ท่าหลวง จันทบุรี 22150",
    name_th: "มะขาม ท่าหลวง จันทบุรี 22150",
    name_en: "Makham, Tha Luang, Chanthaburi, 22150",
  },
  {
    id: 220503,
    districtTh: "มะขาม",
    districtEn: "Makham",
    subdistrictTh: "ปัถวี",
    subdistrictEn: "Patthawi",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22150,
    district_code: 2205,
    province_code: 13,
    name: "มะขาม ปัถวี จันทบุรี 22150",
    name_th: "มะขาม ปัถวี จันทบุรี 22150",
    name_en: "Makham, Patthawi, Chanthaburi, 22150",
  },
  {
    id: 220504,
    districtTh: "มะขาม",
    districtEn: "Makham",
    subdistrictTh: "วังแซ้ม",
    subdistrictEn: "Wang Saem",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22150,
    district_code: 2205,
    province_code: 13,
    name: "มะขาม วังแซ้ม จันทบุรี 22150",
    name_th: "มะขาม วังแซ้ม จันทบุรี 22150",
    name_en: "Makham, Wang Saem, Chanthaburi, 22150",
  },
  {
    id: 220506,
    districtTh: "มะขาม",
    districtEn: "Makham",
    subdistrictTh: "ฉมัน",
    subdistrictEn: "Chaman",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22150,
    district_code: 2205,
    province_code: 13,
    name: "มะขาม ฉมัน จันทบุรี 22150",
    name_th: "มะขาม ฉมัน จันทบุรี 22150",
    name_en: "Makham, Chaman, Chanthaburi, 22150",
  },
  {
    id: 220508,
    districtTh: "มะขาม",
    districtEn: "Makham",
    subdistrictTh: "อ่างคีรี",
    subdistrictEn: "Ang Khiri",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22150,
    district_code: 2205,
    province_code: 13,
    name: "มะขาม อ่างคีรี จันทบุรี 22150",
    name_th: "มะขาม อ่างคีรี จันทบุรี 22150",
    name_en: "Makham, Ang Khiri, Chanthaburi, 22150",
  },
  {
    id: 220601,
    districtTh: "แหลมสิงห์",
    districtEn: "Laem Sing",
    subdistrictTh: "ปากน้ำแหลมสิงห์",
    subdistrictEn: "Pak Nam Laem Sing",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22130,
    district_code: 2206,
    province_code: 13,
    name: "แหลมสิงห์ ปากน้ำแหลมสิงห์ จันทบุรี 22130",
    name_th: "แหลมสิงห์ ปากน้ำแหลมสิงห์ จันทบุรี 22130",
    name_en: "Laem Sing, Pak Nam Laem Sing, Chanthaburi, 22130",
  },
  {
    id: 220602,
    districtTh: "แหลมสิงห์",
    districtEn: "Laem Sing",
    subdistrictTh: "เกาะเปริด",
    subdistrictEn: "Ko Proet",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22130,
    district_code: 2206,
    province_code: 13,
    name: "แหลมสิงห์ เกาะเปริด จันทบุรี 22130",
    name_th: "แหลมสิงห์ เกาะเปริด จันทบุรี 22130",
    name_en: "Laem Sing, Ko Proet, Chanthaburi, 22130",
  },
  {
    id: 220603,
    districtTh: "แหลมสิงห์",
    districtEn: "Laem Sing",
    subdistrictTh: "หนองชิ่ม",
    subdistrictEn: "Nong Chim",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22130,
    district_code: 2206,
    province_code: 13,
    name: "แหลมสิงห์ หนองชิ่ม จันทบุรี 22130",
    name_th: "แหลมสิงห์ หนองชิ่ม จันทบุรี 22130",
    name_en: "Laem Sing, Nong Chim, Chanthaburi, 22130",
  },
  {
    id: 220604,
    districtTh: "แหลมสิงห์",
    districtEn: "Laem Sing",
    subdistrictTh: "พลิ้ว",
    subdistrictEn: "Phlio",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22190,
    district_code: 2206,
    province_code: 13,
    name: "แหลมสิงห์ พลิ้ว จันทบุรี 22190",
    name_th: "แหลมสิงห์ พลิ้ว จันทบุรี 22190",
    name_en: "Laem Sing, Phlio, Chanthaburi, 22190",
  },
  {
    id: 220605,
    districtTh: "แหลมสิงห์",
    districtEn: "Laem Sing",
    subdistrictTh: "คลองน้ำเค็ม",
    subdistrictEn: "Khlong Nam Khem",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22190,
    district_code: 2206,
    province_code: 13,
    name: "แหลมสิงห์ คลองน้ำเค็ม จันทบุรี 22190",
    name_th: "แหลมสิงห์ คลองน้ำเค็ม จันทบุรี 22190",
    name_en: "Laem Sing, Khlong Nam Khem, Chanthaburi, 22190",
  },
  {
    id: 220606,
    districtTh: "แหลมสิงห์",
    districtEn: "Laem Sing",
    subdistrictTh: "บางสระเก้า",
    subdistrictEn: "Bang Sa Kao",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22190,
    district_code: 2206,
    province_code: 13,
    name: "แหลมสิงห์ บางสระเก้า จันทบุรี 22190",
    name_th: "แหลมสิงห์ บางสระเก้า จันทบุรี 22190",
    name_en: "Laem Sing, Bang Sa Kao, Chanthaburi, 22190",
  },
  {
    id: 220607,
    districtTh: "แหลมสิงห์",
    districtEn: "Laem Sing",
    subdistrictTh: "บางกะไชย",
    subdistrictEn: "Bang Kachai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22120,
    district_code: 2206,
    province_code: 13,
    name: "แหลมสิงห์ บางกะไชย จันทบุรี 22120",
    name_th: "แหลมสิงห์ บางกะไชย จันทบุรี 22120",
    name_en: "Laem Sing, Bang Kachai, Chanthaburi, 22120",
  },
  {
    id: 220701,
    districtTh: "สอยดาว",
    districtEn: "Soi Dao",
    subdistrictTh: "ปะตง",
    subdistrictEn: "Patong",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22180,
    district_code: 2207,
    province_code: 13,
    name: "สอยดาว ปะตง จันทบุรี 22180",
    name_th: "สอยดาว ปะตง จันทบุรี 22180",
    name_en: "Soi Dao, Patong, Chanthaburi, 22180",
  },
  {
    id: 220702,
    districtTh: "สอยดาว",
    districtEn: "Soi Dao",
    subdistrictTh: "ทุ่งขนาน",
    subdistrictEn: "Thung Khanan",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22180,
    district_code: 2207,
    province_code: 13,
    name: "สอยดาว ทุ่งขนาน จันทบุรี 22180",
    name_th: "สอยดาว ทุ่งขนาน จันทบุรี 22180",
    name_en: "Soi Dao, Thung Khanan, Chanthaburi, 22180",
  },
  {
    id: 220703,
    districtTh: "สอยดาว",
    districtEn: "Soi Dao",
    subdistrictTh: "ทับช้าง",
    subdistrictEn: "Thap Chang",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22180,
    district_code: 2207,
    province_code: 13,
    name: "สอยดาว ทับช้าง จันทบุรี 22180",
    name_th: "สอยดาว ทับช้าง จันทบุรี 22180",
    name_en: "Soi Dao, Thap Chang, Chanthaburi, 22180",
  },
  {
    id: 220704,
    districtTh: "สอยดาว",
    districtEn: "Soi Dao",
    subdistrictTh: "ทรายขาว",
    subdistrictEn: "Sai Khao",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22180,
    district_code: 2207,
    province_code: 13,
    name: "สอยดาว ทรายขาว จันทบุรี 22180",
    name_th: "สอยดาว ทรายขาว จันทบุรี 22180",
    name_en: "Soi Dao, Sai Khao, Chanthaburi, 22180",
  },
  {
    id: 220705,
    districtTh: "สอยดาว",
    districtEn: "Soi Dao",
    subdistrictTh: "สะตอน",
    subdistrictEn: "Saton",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22180,
    district_code: 2207,
    province_code: 13,
    name: "สอยดาว สะตอน จันทบุรี 22180",
    name_th: "สอยดาว สะตอน จันทบุรี 22180",
    name_en: "Soi Dao, Saton, Chanthaburi, 22180",
  },
  {
    id: 220801,
    districtTh: "แก่งหางแมว",
    districtEn: "Kaeng Hang Maeo",
    subdistrictTh: "แก่งหางแมว",
    subdistrictEn: "Kaeng Hang Maeo",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22160,
    district_code: 2208,
    province_code: 13,
    name: "แก่งหางแมว แก่งหางแมว จันทบุรี 22160",
    name_th: "แก่งหางแมว แก่งหางแมว จันทบุรี 22160",
    name_en: "Kaeng Hang Maeo, Kaeng Hang Maeo, Chanthaburi, 22160",
  },
  {
    id: 220802,
    districtTh: "แก่งหางแมว",
    districtEn: "Kaeng Hang Maeo",
    subdistrictTh: "ขุนซ่อง",
    subdistrictEn: "Khun Song",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22160,
    district_code: 2208,
    province_code: 13,
    name: "แก่งหางแมว ขุนซ่อง จันทบุรี 22160",
    name_th: "แก่งหางแมว ขุนซ่อง จันทบุรี 22160",
    name_en: "Kaeng Hang Maeo, Khun Song, Chanthaburi, 22160",
  },
  {
    id: 220803,
    districtTh: "แก่งหางแมว",
    districtEn: "Kaeng Hang Maeo",
    subdistrictTh: "สามพี่น้อง",
    subdistrictEn: "Sam Phi Nong",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22160,
    district_code: 2208,
    province_code: 13,
    name: "แก่งหางแมว สามพี่น้อง จันทบุรี 22160",
    name_th: "แก่งหางแมว สามพี่น้อง จันทบุรี 22160",
    name_en: "Kaeng Hang Maeo, Sam Phi Nong, Chanthaburi, 22160",
  },
  {
    id: 220804,
    districtTh: "แก่งหางแมว",
    districtEn: "Kaeng Hang Maeo",
    subdistrictTh: "พวา",
    subdistrictEn: "Phawa",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22160,
    district_code: 2208,
    province_code: 13,
    name: "แก่งหางแมว พวา จันทบุรี 22160",
    name_th: "แก่งหางแมว พวา จันทบุรี 22160",
    name_en: "Kaeng Hang Maeo, Phawa, Chanthaburi, 22160",
  },
  {
    id: 220805,
    districtTh: "แก่งหางแมว",
    districtEn: "Kaeng Hang Maeo",
    subdistrictTh: "เขาวงกต",
    subdistrictEn: "Khao Wongkot",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22160,
    district_code: 2208,
    province_code: 13,
    name: "แก่งหางแมว เขาวงกต จันทบุรี 22160",
    name_th: "แก่งหางแมว เขาวงกต จันทบุรี 22160",
    name_en: "Kaeng Hang Maeo, Khao Wongkot, Chanthaburi, 22160",
  },
  {
    id: 220901,
    districtTh: "นายายอาม",
    districtEn: "Na Yai Am",
    subdistrictTh: "นายายอาม",
    subdistrictEn: "Na Yai Am",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22160,
    district_code: 2209,
    province_code: 13,
    name: "นายายอาม นายายอาม จันทบุรี 22160",
    name_th: "นายายอาม นายายอาม จันทบุรี 22160",
    name_en: "Na Yai Am, Na Yai Am, Chanthaburi, 22160",
  },
  {
    id: 220902,
    districtTh: "นายายอาม",
    districtEn: "Na Yai Am",
    subdistrictTh: "วังโตนด",
    subdistrictEn: "Wang Tanot",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22170,
    district_code: 2209,
    province_code: 13,
    name: "นายายอาม วังโตนด จันทบุรี 22170",
    name_th: "นายายอาม วังโตนด จันทบุรี 22170",
    name_en: "Na Yai Am, Wang Tanot, Chanthaburi, 22170",
  },
  {
    id: 220903,
    districtTh: "นายายอาม",
    districtEn: "Na Yai Am",
    subdistrictTh: "กระแจะ",
    subdistrictEn: "Krachae",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22170,
    district_code: 2209,
    province_code: 13,
    name: "นายายอาม กระแจะ จันทบุรี 22170",
    name_th: "นายายอาม กระแจะ จันทบุรี 22170",
    name_en: "Na Yai Am, Krachae, Chanthaburi, 22170",
  },
  {
    id: 220904,
    districtTh: "นายายอาม",
    districtEn: "Na Yai Am",
    subdistrictTh: "สนามไชย",
    subdistrictEn: "Sanam Chai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22170,
    district_code: 2209,
    province_code: 13,
    name: "นายายอาม สนามไชย จันทบุรี 22170",
    name_th: "นายายอาม สนามไชย จันทบุรี 22170",
    name_en: "Na Yai Am, Sanam Chai, Chanthaburi, 22170",
  },
  {
    id: 220905,
    districtTh: "นายายอาม",
    districtEn: "Na Yai Am",
    subdistrictTh: "ช้างข้าม",
    subdistrictEn: "Chang Kham",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22160,
    district_code: 2209,
    province_code: 13,
    name: "นายายอาม ช้างข้าม จันทบุรี 22160",
    name_th: "นายายอาม ช้างข้าม จันทบุรี 22160",
    name_en: "Na Yai Am, Chang Kham, Chanthaburi, 22160",
  },
  {
    id: 220906,
    districtTh: "นายายอาม",
    districtEn: "Na Yai Am",
    subdistrictTh: "วังใหม่",
    subdistrictEn: "Wang Mai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22170,
    district_code: 2209,
    province_code: 13,
    name: "นายายอาม วังใหม่ จันทบุรี 22170",
    name_th: "นายายอาม วังใหม่ จันทบุรี 22170",
    name_en: "Na Yai Am, Wang Mai, Chanthaburi, 22170",
  },
  {
    id: 221001,
    districtTh: "เขาคิชฌกูฏ",
    districtEn: "Khoa Khitchakut",
    subdistrictTh: "ชากไทย",
    subdistrictEn: "Chak Thai",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22210,
    district_code: 2210,
    province_code: 13,
    name: "เขาคิชฌกูฏ ชากไทย จันทบุรี 22210",
    name_th: "เขาคิชฌกูฏ ชากไทย จันทบุรี 22210",
    name_en: "Khoa Khitchakut, Chak Thai, Chanthaburi, 22210",
  },
  {
    id: 221002,
    districtTh: "เขาคิชฌกูฏ",
    districtEn: "Khoa Khitchakut",
    subdistrictTh: "พลวง",
    subdistrictEn: "Phluang",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22210,
    district_code: 2210,
    province_code: 13,
    name: "เขาคิชฌกูฏ พลวง จันทบุรี 22210",
    name_th: "เขาคิชฌกูฏ พลวง จันทบุรี 22210",
    name_en: "Khoa Khitchakut, Phluang, Chanthaburi, 22210",
  },
  {
    id: 221003,
    districtTh: "เขาคิชฌกูฏ",
    districtEn: "Khoa Khitchakut",
    subdistrictTh: "ตะเคียนทอง",
    subdistrictEn: "Takhian Thong",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22210,
    district_code: 2210,
    province_code: 13,
    name: "เขาคิชฌกูฏ ตะเคียนทอง จันทบุรี 22210",
    name_th: "เขาคิชฌกูฏ ตะเคียนทอง จันทบุรี 22210",
    name_en: "Khoa Khitchakut, Takhian Thong, Chanthaburi, 22210",
  },
  {
    id: 221004,
    districtTh: "เขาคิชฌกูฏ",
    districtEn: "Khoa Khitchakut",
    subdistrictTh: "คลองพลู",
    subdistrictEn: "Khlong Phlu",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22210,
    district_code: 2210,
    province_code: 13,
    name: "เขาคิชฌกูฏ คลองพลู จันทบุรี 22210",
    name_th: "เขาคิชฌกูฏ คลองพลู จันทบุรี 22210",
    name_en: "Khoa Khitchakut, Khlong Phlu, Chanthaburi, 22210",
  },
  {
    id: 221005,
    districtTh: "เขาคิชฌกูฏ",
    districtEn: "Khoa Khitchakut",
    subdistrictTh: "จันทเขลม",
    subdistrictEn: "Chanthakhlem",
    provinceTh: "จันทบุรี",
    provinceEn: "Chanthaburi",
    zipcode: 22210,
    district_code: 2210,
    province_code: 13,
    name: "เขาคิชฌกูฏ จันทเขลม จันทบุรี 22210",
    name_th: "เขาคิชฌกูฏ จันทเขลม จันทบุรี 22210",
    name_en: "Khoa Khitchakut, Chanthakhlem, Chanthaburi, 22210",
  },
  {
    id: 230101,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "บางพระ",
    subdistrictEn: "Bang Phra",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด บางพระ ตราด 23000",
    name_th: "เมืองตราด บางพระ ตราด 23000",
    name_en: "Mueang Trat, Bang Phra, Trat, 23000",
  },
  {
    id: 230102,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "หนองเสม็ด",
    subdistrictEn: "Nong Samet",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด หนองเสม็ด ตราด 23000",
    name_th: "เมืองตราด หนองเสม็ด ตราด 23000",
    name_en: "Mueang Trat, Nong Samet, Trat, 23000",
  },
  {
    id: 230103,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "หนองโสน",
    subdistrictEn: "Nong Sano",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด หนองโสน ตราด 23000",
    name_th: "เมืองตราด หนองโสน ตราด 23000",
    name_en: "Mueang Trat, Nong Sano, Trat, 23000",
  },
  {
    id: 230104,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "หนองคันทรง",
    subdistrictEn: "Nong Khan Song",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด หนองคันทรง ตราด 23000",
    name_th: "เมืองตราด หนองคันทรง ตราด 23000",
    name_en: "Mueang Trat, Nong Khan Song, Trat, 23000",
  },
  {
    id: 230105,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "ห้วงน้ำขาว",
    subdistrictEn: "Huang Nam Khao",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด ห้วงน้ำขาว ตราด 23000",
    name_th: "เมืองตราด ห้วงน้ำขาว ตราด 23000",
    name_en: "Mueang Trat, Huang Nam Khao, Trat, 23000",
  },
  {
    id: 230106,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "อ่าวใหญ่",
    subdistrictEn: "Ao Yai",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด อ่าวใหญ่ ตราด 23000",
    name_th: "เมืองตราด อ่าวใหญ่ ตราด 23000",
    name_en: "Mueang Trat, Ao Yai, Trat, 23000",
  },
  {
    id: 230107,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "วังกระแจะ",
    subdistrictEn: "Wang Krachae",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด วังกระแจะ ตราด 23000",
    name_th: "เมืองตราด วังกระแจะ ตราด 23000",
    name_en: "Mueang Trat, Wang Krachae, Trat, 23000",
  },
  {
    id: 230108,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "ห้วยแร้ง",
    subdistrictEn: "Huai Raeng",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด ห้วยแร้ง ตราด 23000",
    name_th: "เมืองตราด ห้วยแร้ง ตราด 23000",
    name_en: "Mueang Trat, Huai Raeng, Trat, 23000",
  },
  {
    id: 230109,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "เนินทราย",
    subdistrictEn: "Noen Sai",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด เนินทราย ตราด 23000",
    name_th: "เมืองตราด เนินทราย ตราด 23000",
    name_en: "Mueang Trat, Noen Sai, Trat, 23000",
  },
  {
    id: 230110,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "ท่าพริก",
    subdistrictEn: "Tha Phrik",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด ท่าพริก ตราด 23000",
    name_th: "เมืองตราด ท่าพริก ตราด 23000",
    name_en: "Mueang Trat, Tha Phrik, Trat, 23000",
  },
  {
    id: 230111,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "ท่ากุ่ม",
    subdistrictEn: "Tha Kum",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด ท่ากุ่ม ตราด 23000",
    name_th: "เมืองตราด ท่ากุ่ม ตราด 23000",
    name_en: "Mueang Trat, Tha Kum, Trat, 23000",
  },
  {
    id: 230112,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "ตะกาง",
    subdistrictEn: "Takang",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด ตะกาง ตราด 23000",
    name_th: "เมืองตราด ตะกาง ตราด 23000",
    name_en: "Mueang Trat, Takang, Trat, 23000",
  },
  {
    id: 230113,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "ชำราก",
    subdistrictEn: "Chamrak",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด ชำราก ตราด 23000",
    name_th: "เมืองตราด ชำราก ตราด 23000",
    name_en: "Mueang Trat, Chamrak, Trat, 23000",
  },
  {
    id: 230114,
    districtTh: "เมืองตราด",
    districtEn: "Mueang Trat",
    subdistrictTh: "แหลมกลัด",
    subdistrictEn: "Laem Klat",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2301,
    province_code: 14,
    name: "เมืองตราด แหลมกลัด ตราด 23000",
    name_th: "เมืองตราด แหลมกลัด ตราด 23000",
    name_en: "Mueang Trat, Laem Klat, Trat, 23000",
  },
  {
    id: 230201,
    districtTh: "คลองใหญ่",
    districtEn: "Khlong Yai",
    subdistrictTh: "คลองใหญ่",
    subdistrictEn: "Khlong Yai",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23110,
    district_code: 2302,
    province_code: 14,
    name: "คลองใหญ่ คลองใหญ่ ตราด 23110",
    name_th: "คลองใหญ่ คลองใหญ่ ตราด 23110",
    name_en: "Khlong Yai, Khlong Yai, Trat, 23110",
  },
  {
    id: 230202,
    districtTh: "คลองใหญ่",
    districtEn: "Khlong Yai",
    subdistrictTh: "ไม้รูด",
    subdistrictEn: "Mai Rut",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23110,
    district_code: 2302,
    province_code: 14,
    name: "คลองใหญ่ ไม้รูด ตราด 23110",
    name_th: "คลองใหญ่ ไม้รูด ตราด 23110",
    name_en: "Khlong Yai, Mai Rut, Trat, 23110",
  },
  {
    id: 230203,
    districtTh: "คลองใหญ่",
    districtEn: "Khlong Yai",
    subdistrictTh: "หาดเล็ก",
    subdistrictEn: "Hat Lek",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23110,
    district_code: 2302,
    province_code: 14,
    name: "คลองใหญ่ หาดเล็ก ตราด 23110",
    name_th: "คลองใหญ่ หาดเล็ก ตราด 23110",
    name_en: "Khlong Yai, Hat Lek, Trat, 23110",
  },
  {
    id: 230301,
    districtTh: "เขาสมิง",
    districtEn: "Khao Saming",
    subdistrictTh: "เขาสมิง",
    subdistrictEn: "Khao Saming",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23130,
    district_code: 2303,
    province_code: 14,
    name: "เขาสมิง เขาสมิง ตราด 23130",
    name_th: "เขาสมิง เขาสมิง ตราด 23130",
    name_en: "Khao Saming, Khao Saming, Trat, 23130",
  },
  {
    id: 230302,
    districtTh: "เขาสมิง",
    districtEn: "Khao Saming",
    subdistrictTh: "แสนตุ้ง",
    subdistrictEn: "Saen Tung",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23150,
    district_code: 2303,
    province_code: 14,
    name: "เขาสมิง แสนตุ้ง ตราด 23150",
    name_th: "เขาสมิง แสนตุ้ง ตราด 23150",
    name_en: "Khao Saming, Saen Tung, Trat, 23150",
  },
  {
    id: 230303,
    districtTh: "เขาสมิง",
    districtEn: "Khao Saming",
    subdistrictTh: "วังตะเคียน",
    subdistrictEn: "Wang Takhian",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23130,
    district_code: 2303,
    province_code: 14,
    name: "เขาสมิง วังตะเคียน ตราด 23130",
    name_th: "เขาสมิง วังตะเคียน ตราด 23130",
    name_en: "Khao Saming, Wang Takhian, Trat, 23130",
  },
  {
    id: 230304,
    districtTh: "เขาสมิง",
    districtEn: "Khao Saming",
    subdistrictTh: "ท่าโสม",
    subdistrictEn: "Tha Som",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23150,
    district_code: 2303,
    province_code: 14,
    name: "เขาสมิง ท่าโสม ตราด 23150",
    name_th: "เขาสมิง ท่าโสม ตราด 23150",
    name_en: "Khao Saming, Tha Som, Trat, 23150",
  },
  {
    id: 230305,
    districtTh: "เขาสมิง",
    districtEn: "Khao Saming",
    subdistrictTh: "สะตอ",
    subdistrictEn: "Sato",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23150,
    district_code: 2303,
    province_code: 14,
    name: "เขาสมิง สะตอ ตราด 23150",
    name_th: "เขาสมิง สะตอ ตราด 23150",
    name_en: "Khao Saming, Sato, Trat, 23150",
  },
  {
    id: 230306,
    districtTh: "เขาสมิง",
    districtEn: "Khao Saming",
    subdistrictTh: "ประณีต",
    subdistrictEn: "Pranit",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23150,
    district_code: 2303,
    province_code: 14,
    name: "เขาสมิง ประณีต ตราด 23150",
    name_th: "เขาสมิง ประณีต ตราด 23150",
    name_en: "Khao Saming, Pranit, Trat, 23150",
  },
  {
    id: 230307,
    districtTh: "เขาสมิง",
    districtEn: "Khao Saming",
    subdistrictTh: "เทพนิมิต",
    subdistrictEn: "Thep Nimit",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23150,
    district_code: 2303,
    province_code: 14,
    name: "เขาสมิง เทพนิมิต ตราด 23150",
    name_th: "เขาสมิง เทพนิมิต ตราด 23150",
    name_en: "Khao Saming, Thep Nimit, Trat, 23150",
  },
  {
    id: 230308,
    districtTh: "เขาสมิง",
    districtEn: "Khao Saming",
    subdistrictTh: "ทุ่งนนทรี",
    subdistrictEn: "Thung Nonsi",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23130,
    district_code: 2303,
    province_code: 14,
    name: "เขาสมิง ทุ่งนนทรี ตราด 23130",
    name_th: "เขาสมิง ทุ่งนนทรี ตราด 23130",
    name_en: "Khao Saming, Thung Nonsi, Trat, 23130",
  },
  {
    id: 230401,
    districtTh: "บ่อไร่",
    districtEn: "Bo Rai",
    subdistrictTh: "บ่อพลอย",
    subdistrictEn: "Bo Phloi",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23140,
    district_code: 2304,
    province_code: 14,
    name: "บ่อไร่ บ่อพลอย ตราด 23140",
    name_th: "บ่อไร่ บ่อพลอย ตราด 23140",
    name_en: "Bo Rai, Bo Phloi, Trat, 23140",
  },
  {
    id: 230402,
    districtTh: "บ่อไร่",
    districtEn: "Bo Rai",
    subdistrictTh: "ช้างทูน",
    subdistrictEn: "Chang Thun",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23140,
    district_code: 2304,
    province_code: 14,
    name: "บ่อไร่ ช้างทูน ตราด 23140",
    name_th: "บ่อไร่ ช้างทูน ตราด 23140",
    name_en: "Bo Rai, Chang Thun, Trat, 23140",
  },
  {
    id: 230403,
    districtTh: "บ่อไร่",
    districtEn: "Bo Rai",
    subdistrictTh: "ด่านชุมพล",
    subdistrictEn: "Dan Chumphon",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23140,
    district_code: 2304,
    province_code: 14,
    name: "บ่อไร่ ด่านชุมพล ตราด 23140",
    name_th: "บ่อไร่ ด่านชุมพล ตราด 23140",
    name_en: "Bo Rai, Dan Chumphon, Trat, 23140",
  },
  {
    id: 230404,
    districtTh: "บ่อไร่",
    districtEn: "Bo Rai",
    subdistrictTh: "หนองบอน",
    subdistrictEn: "Nong Bon",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23140,
    district_code: 2304,
    province_code: 14,
    name: "บ่อไร่ หนองบอน ตราด 23140",
    name_th: "บ่อไร่ หนองบอน ตราด 23140",
    name_en: "Bo Rai, Nong Bon, Trat, 23140",
  },
  {
    id: 230405,
    districtTh: "บ่อไร่",
    districtEn: "Bo Rai",
    subdistrictTh: "นนทรีย์",
    subdistrictEn: "Nonsi",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23140,
    district_code: 2304,
    province_code: 14,
    name: "บ่อไร่ นนทรีย์ ตราด 23140",
    name_th: "บ่อไร่ นนทรีย์ ตราด 23140",
    name_en: "Bo Rai, Nonsi, Trat, 23140",
  },
  {
    id: 230501,
    districtTh: "แหลมงอบ",
    districtEn: "Laem Ngop",
    subdistrictTh: "แหลมงอบ",
    subdistrictEn: "Laem Ngop",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23120,
    district_code: 2305,
    province_code: 14,
    name: "แหลมงอบ แหลมงอบ ตราด 23120",
    name_th: "แหลมงอบ แหลมงอบ ตราด 23120",
    name_en: "Laem Ngop, Laem Ngop, Trat, 23120",
  },
  {
    id: 230502,
    districtTh: "แหลมงอบ",
    districtEn: "Laem Ngop",
    subdistrictTh: "น้ำเชี่ยว",
    subdistrictEn: "Nam Chiao",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23120,
    district_code: 2305,
    province_code: 14,
    name: "แหลมงอบ น้ำเชี่ยว ตราด 23120",
    name_th: "แหลมงอบ น้ำเชี่ยว ตราด 23120",
    name_en: "Laem Ngop, Nam Chiao, Trat, 23120",
  },
  {
    id: 230503,
    districtTh: "แหลมงอบ",
    districtEn: "Laem Ngop",
    subdistrictTh: "บางปิด",
    subdistrictEn: "Bang Pit",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23120,
    district_code: 2305,
    province_code: 14,
    name: "แหลมงอบ บางปิด ตราด 23120",
    name_th: "แหลมงอบ บางปิด ตราด 23120",
    name_en: "Laem Ngop, Bang Pit, Trat, 23120",
  },
  {
    id: 230507,
    districtTh: "แหลมงอบ",
    districtEn: "Laem Ngop",
    subdistrictTh: "คลองใหญ่",
    subdistrictEn: "Khlong Yai",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23120,
    district_code: 2305,
    province_code: 14,
    name: "แหลมงอบ คลองใหญ่ ตราด 23120",
    name_th: "แหลมงอบ คลองใหญ่ ตราด 23120",
    name_en: "Laem Ngop, Khlong Yai, Trat, 23120",
  },
  {
    id: 230601,
    districtTh: "เกาะกูด",
    districtEn: "Ko Kut",
    subdistrictTh: "เกาะหมาก",
    subdistrictEn: "Ko Mak",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2306,
    province_code: 14,
    name: "เกาะกูด เกาะหมาก ตราด 23000",
    name_th: "เกาะกูด เกาะหมาก ตราด 23000",
    name_en: "Ko Kut, Ko Mak, Trat, 23000",
  },
  {
    id: 230602,
    districtTh: "เกาะกูด",
    districtEn: "Ko Kut",
    subdistrictTh: "เกาะกูด",
    subdistrictEn: "Ko Kut",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23000,
    district_code: 2306,
    province_code: 14,
    name: "เกาะกูด เกาะกูด ตราด 23000",
    name_th: "เกาะกูด เกาะกูด ตราด 23000",
    name_en: "Ko Kut, Ko Kut, Trat, 23000",
  },
  {
    id: 230701,
    districtTh: "เกาะช้าง",
    districtEn: "Ko Chang",
    subdistrictTh: "เกาะช้าง",
    subdistrictEn: "Ko Chang",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23170,
    district_code: 2307,
    province_code: 14,
    name: "เกาะช้าง เกาะช้าง ตราด 23170",
    name_th: "เกาะช้าง เกาะช้าง ตราด 23170",
    name_en: "Ko Chang, Ko Chang, Trat, 23170",
  },
  {
    id: 230702,
    districtTh: "เกาะช้าง",
    districtEn: "Ko Chang",
    subdistrictTh: "เกาะช้างใต้",
    subdistrictEn: "Ko Chang Tai",
    provinceTh: "ตราด",
    provinceEn: "Trat",
    zipcode: 23170,
    district_code: 2307,
    province_code: 14,
    name: "เกาะช้าง เกาะช้างใต้ ตราด 23170",
    name_th: "เกาะช้าง เกาะช้างใต้ ตราด 23170",
    name_en: "Ko Chang, Ko Chang Tai, Trat, 23170",
  },
  {
    id: 240101,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "หน้าเมือง",
    subdistrictEn: "Na Mueang",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา หน้าเมือง ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา หน้าเมือง ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Na Mueang, Chachoengsao, 24000",
  },
  {
    id: 240102,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "ท่าไข่",
    subdistrictEn: "Tha Khai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา ท่าไข่ ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา ท่าไข่ ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Tha Khai, Chachoengsao, 24000",
  },
  {
    id: 240103,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา บ้านใหม่ ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา บ้านใหม่ ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Ban Mai, Chachoengsao, 24000",
  },
  {
    id: 240104,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "คลองนา",
    subdistrictEn: "Khlong Na",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา คลองนา ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา คลองนา ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Khlong Na, Chachoengsao, 24000",
  },
  {
    id: 240105,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "บางตีนเป็ด",
    subdistrictEn: "Khlong Na",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา บางตีนเป็ด ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา บางตีนเป็ด ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Khlong Na, Chachoengsao, 24000",
  },
  {
    id: 240106,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "บางไผ่",
    subdistrictEn: "Bang Phai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา บางไผ่ ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา บางไผ่ ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Bang Phai, Chachoengsao, 24000",
  },
  {
    id: 240107,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "คลองจุกกระเฌอ",
    subdistrictEn: "Khlong Chuk Krachoe",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา คลองจุกกระเฌอ ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา คลองจุกกระเฌอ ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Khlong Chuk Krachoe, Chachoengsao, 24000",
  },
  {
    id: 240108,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "บางแก้ว",
    subdistrictEn: "Bang Kaeo",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา บางแก้ว ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา บางแก้ว ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Bang Kaeo, Chachoengsao, 24000",
  },
  {
    id: 240109,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "บางขวัญ",
    subdistrictEn: "Bang Khwan",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา บางขวัญ ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา บางขวัญ ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Bang Khwan, Chachoengsao, 24000",
  },
  {
    id: 240110,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "คลองนครเนื่องเขต",
    subdistrictEn: "Khlong Nakhon Nueang Khet",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา คลองนครเนื่องเขต ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา คลองนครเนื่องเขต ฉะเชิงเทรา 24000",
    name_en:
      "Mueang Chachoengsao, Khlong Nakhon Nueang Khet, Chachoengsao, 24000",
  },
  {
    id: 240111,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "วังตะเคียน",
    subdistrictEn: "Wang Takhian",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา วังตะเคียน ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา วังตะเคียน ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Wang Takhian, Chachoengsao, 24000",
  },
  {
    id: 240112,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "โสธร",
    subdistrictEn: "Sothon",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา โสธร ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา โสธร ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Sothon, Chachoengsao, 24000",
  },
  {
    id: 240113,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "บางพระ",
    subdistrictEn: "Bang Phra",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา บางพระ ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา บางพระ ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Bang Phra, Chachoengsao, 24000",
  },
  {
    id: 240114,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "บางกะไห",
    subdistrictEn: "Bang Kahai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา บางกะไห ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา บางกะไห ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Bang Kahai, Chachoengsao, 24000",
  },
  {
    id: 240115,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "หนามแดง",
    subdistrictEn: "Nam Daeng",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา หนามแดง ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา หนามแดง ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Nam Daeng, Chachoengsao, 24000",
  },
  {
    id: 240116,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "คลองเปรง",
    subdistrictEn: "Khlong Preng",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา คลองเปรง ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา คลองเปรง ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Khlong Preng, Chachoengsao, 24000",
  },
  {
    id: 240117,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "คลองอุดมชลจร",
    subdistrictEn: "Khlong Udom Chonlachon",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา คลองอุดมชลจร ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา คลองอุดมชลจร ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Khlong Udom Chonlachon, Chachoengsao, 24000",
  },
  {
    id: 240118,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "คลองหลวงแพ่ง",
    subdistrictEn: "Khlong Luang Phaeng",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา คลองหลวงแพ่ง ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา คลองหลวงแพ่ง ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Khlong Luang Phaeng, Chachoengsao, 24000",
  },
  {
    id: 240119,
    districtTh: "เมืองฉะเชิงเทรา",
    districtEn: "Mueang Chachoengsao",
    subdistrictTh: "บางเตย",
    subdistrictEn: "Bang Toei",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2401,
    province_code: 15,
    name: "เมืองฉะเชิงเทรา บางเตย ฉะเชิงเทรา 24000",
    name_th: "เมืองฉะเชิงเทรา บางเตย ฉะเชิงเทรา 24000",
    name_en: "Mueang Chachoengsao, Bang Toei, Chachoengsao, 24000",
  },
  {
    id: 240201,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "บางคล้า",
    subdistrictEn: "Bang Khla",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า บางคล้า ฉะเชิงเทรา 24110",
    name_th: "บางคล้า บางคล้า ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Bang Khla, Chachoengsao, 24110",
  },
  {
    id: 240204,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "บางสวน",
    subdistrictEn: "Bang Suan",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า บางสวน ฉะเชิงเทรา 24110",
    name_th: "บางคล้า บางสวน ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Bang Suan, Chachoengsao, 24110",
  },
  {
    id: 240208,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "บางกระเจ็ด",
    subdistrictEn: "Bang Krachet",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า บางกระเจ็ด ฉะเชิงเทรา 24110",
    name_th: "บางคล้า บางกระเจ็ด ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Bang Krachet, Chachoengsao, 24110",
  },
  {
    id: 240209,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า ปากน้ำ ฉะเชิงเทรา 24110",
    name_th: "บางคล้า ปากน้ำ ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Pak Nam, Chachoengsao, 24110",
  },
  {
    id: 240210,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "ท่าทองหลาง",
    subdistrictEn: "Tha Thonglang",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า ท่าทองหลาง ฉะเชิงเทรา 24110",
    name_th: "บางคล้า ท่าทองหลาง ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Tha Thonglang, Chachoengsao, 24110",
  },
  {
    id: 240211,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "สาวชะโงก",
    subdistrictEn: "Sao Cha-ngok",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า สาวชะโงก ฉะเชิงเทรา 24110",
    name_th: "บางคล้า สาวชะโงก ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Sao Cha-ngok, Chachoengsao, 24110",
  },
  {
    id: 240212,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "เสม็ดเหนือ",
    subdistrictEn: "Samet Nuea",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า เสม็ดเหนือ ฉะเชิงเทรา 24110",
    name_th: "บางคล้า เสม็ดเหนือ ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Samet Nuea, Chachoengsao, 24110",
  },
  {
    id: 240213,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "เสม็ดใต้",
    subdistrictEn: "Samet Tai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า เสม็ดใต้ ฉะเชิงเทรา 24110",
    name_th: "บางคล้า เสม็ดใต้ ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Samet Tai, Chachoengsao, 24110",
  },
  {
    id: 240214,
    districtTh: "บางคล้า",
    districtEn: "Bang Khla",
    subdistrictTh: "หัวไทร",
    subdistrictEn: "Hua Sai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2402,
    province_code: 15,
    name: "บางคล้า หัวไทร ฉะเชิงเทรา 24110",
    name_th: "บางคล้า หัวไทร ฉะเชิงเทรา 24110",
    name_en: "Bang Khla, Hua Sai, Chachoengsao, 24110",
  },
  {
    id: 240301,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "บางน้ำเปรี้ยว",
    subdistrictEn: "Bang Nam Priao",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24150,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
    name_th: "บางน้ำเปรี้ยว บางน้ำเปรี้ยว ฉะเชิงเทรา 24150",
    name_en: "Bang Nam Priao, Bang Nam Priao, Chachoengsao, 24150",
  },
  {
    id: 240302,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "บางขนาก",
    subdistrictEn: "Bang Khanak",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24150,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว บางขนาก ฉะเชิงเทรา 24150",
    name_th: "บางน้ำเปรี้ยว บางขนาก ฉะเชิงเทรา 24150",
    name_en: "Bang Nam Priao, Bang Khanak, Chachoengsao, 24150",
  },
  {
    id: 240303,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "สิงโตทอง",
    subdistrictEn: "Singto Thong",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24150,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว สิงโตทอง ฉะเชิงเทรา 24150",
    name_th: "บางน้ำเปรี้ยว สิงโตทอง ฉะเชิงเทรา 24150",
    name_en: "Bang Nam Priao, Singto Thong, Chachoengsao, 24150",
  },
  {
    id: 240304,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "หมอนทอง",
    subdistrictEn: "Mon Thong",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24150,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว หมอนทอง ฉะเชิงเทรา 24150",
    name_th: "บางน้ำเปรี้ยว หมอนทอง ฉะเชิงเทรา 24150",
    name_en: "Bang Nam Priao, Mon Thong, Chachoengsao, 24150",
  },
  {
    id: 240305,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "บึงน้ำรักษ์",
    subdistrictEn: "Bueng Nam Rak",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24170,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว บึงน้ำรักษ์ ฉะเชิงเทรา 24170",
    name_th: "บางน้ำเปรี้ยว บึงน้ำรักษ์ ฉะเชิงเทรา 24170",
    name_en: "Bang Nam Priao, Bueng Nam Rak, Chachoengsao, 24170",
  },
  {
    id: 240306,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "ดอนเกาะกา",
    subdistrictEn: "Don Ko Ka",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24170,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว ดอนเกาะกา ฉะเชิงเทรา 24170",
    name_th: "บางน้ำเปรี้ยว ดอนเกาะกา ฉะเชิงเทรา 24170",
    name_en: "Bang Nam Priao, Don Ko Ka, Chachoengsao, 24170",
  },
  {
    id: 240307,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "โยธะกา",
    subdistrictEn: "Yothaka",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24150,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว โยธะกา ฉะเชิงเทรา 24150",
    name_th: "บางน้ำเปรี้ยว โยธะกา ฉะเชิงเทรา 24150",
    name_en: "Bang Nam Priao, Yothaka, Chachoengsao, 24150",
  },
  {
    id: 240308,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "ดอนฉิมพลี",
    subdistrictEn: "Don Chimphli",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24170,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว ดอนฉิมพลี ฉะเชิงเทรา 24170",
    name_th: "บางน้ำเปรี้ยว ดอนฉิมพลี ฉะเชิงเทรา 24170",
    name_en: "Bang Nam Priao, Don Chimphli, Chachoengsao, 24170",
  },
  {
    id: 240309,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "ศาลาแดง",
    subdistrictEn: "Sala Daeng",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว ศาลาแดง ฉะเชิงเทรา 24000",
    name_th: "บางน้ำเปรี้ยว ศาลาแดง ฉะเชิงเทรา 24000",
    name_en: "Bang Nam Priao, Sala Daeng, Chachoengsao, 24000",
  },
  {
    id: 240310,
    districtTh: "บางน้ำเปรี้ยว",
    districtEn: "Bang Nam Priao",
    subdistrictTh: "โพรงอากาศ",
    subdistrictEn: "Phrong Akat",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24150,
    district_code: 2403,
    province_code: 15,
    name: "บางน้ำเปรี้ยว โพรงอากาศ ฉะเชิงเทรา 24150",
    name_th: "บางน้ำเปรี้ยว โพรงอากาศ ฉะเชิงเทรา 24150",
    name_en: "Bang Nam Priao, Phrong Akat, Chachoengsao, 24150",
  },
  {
    id: 240401,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "บางปะกง",
    subdistrictEn: "Bang Pakong",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24130,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง บางปะกง ฉะเชิงเทรา 24130",
    name_th: "บางปะกง บางปะกง ฉะเชิงเทรา 24130",
    name_en: "Bang Pakong, Bang Pakong, Chachoengsao, 24130",
  },
  {
    id: 240402,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "ท่าสะอ้าน",
    subdistrictEn: "Tha Sa-an",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24130,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง ท่าสะอ้าน ฉะเชิงเทรา 24130",
    name_th: "บางปะกง ท่าสะอ้าน ฉะเชิงเทรา 24130",
    name_en: "Bang Pakong, Tha Sa-an, Chachoengsao, 24130",
  },
  {
    id: 240403,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "บางวัว",
    subdistrictEn: "Bang Wua",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24180,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง บางวัว ฉะเชิงเทรา 24180",
    name_th: "บางปะกง บางวัว ฉะเชิงเทรา 24180",
    name_en: "Bang Pakong, Bang Wua, Chachoengsao, 24180",
  },
  {
    id: 240404,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "บางสมัคร",
    subdistrictEn: "Bang Samak",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24180,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง บางสมัคร ฉะเชิงเทรา 24180",
    name_th: "บางปะกง บางสมัคร ฉะเชิงเทรา 24180",
    name_en: "Bang Pakong, Bang Samak, Chachoengsao, 24180",
  },
  {
    id: 240405,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "บางผึ้ง",
    subdistrictEn: "Bang Phueng",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24130,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง บางผึ้ง ฉะเชิงเทรา 24130",
    name_th: "บางปะกง บางผึ้ง ฉะเชิงเทรา 24130",
    name_en: "Bang Pakong, Bang Phueng, Chachoengsao, 24130",
  },
  {
    id: 240406,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "บางเกลือ",
    subdistrictEn: "Bang Kluea",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24180,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง บางเกลือ ฉะเชิงเทรา 24180",
    name_th: "บางปะกง บางเกลือ ฉะเชิงเทรา 24180",
    name_en: "Bang Pakong, Bang Kluea, Chachoengsao, 24180",
  },
  {
    id: 240407,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "สองคลอง",
    subdistrictEn: "Song Khlong",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24130,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง สองคลอง ฉะเชิงเทรา 24130",
    name_th: "บางปะกง สองคลอง ฉะเชิงเทรา 24130",
    name_en: "Bang Pakong, Song Khlong, Chachoengsao, 24130",
  },
  {
    id: 240408,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "หนองจอก",
    subdistrictEn: "Nong Chok",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24130,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง หนองจอก ฉะเชิงเทรา 24130",
    name_th: "บางปะกง หนองจอก ฉะเชิงเทรา 24130",
    name_en: "Bang Pakong, Nong Chok, Chachoengsao, 24130",
  },
  {
    id: 240409,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "พิมพา",
    subdistrictEn: "Phimpha",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24130,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง พิมพา ฉะเชิงเทรา 24130",
    name_th: "บางปะกง พิมพา ฉะเชิงเทรา 24130",
    name_en: "Bang Pakong, Phimpha, Chachoengsao, 24130",
  },
  {
    id: 240410,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24130,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง ท่าข้าม ฉะเชิงเทรา 24130",
    name_th: "บางปะกง ท่าข้าม ฉะเชิงเทรา 24130",
    name_en: "Bang Pakong, Tha Kham, Chachoengsao, 24130",
  },
  {
    id: 240411,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "หอมศีล",
    subdistrictEn: "Hom Sin",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24180,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง หอมศีล ฉะเชิงเทรา 24180",
    name_th: "บางปะกง หอมศีล ฉะเชิงเทรา 24180",
    name_en: "Bang Pakong, Hom Sin, Chachoengsao, 24180",
  },
  {
    id: 240412,
    districtTh: "บางปะกง",
    districtEn: "Bang Pakong",
    subdistrictTh: "เขาดิน",
    subdistrictEn: "Khao Din",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24130,
    district_code: 2404,
    province_code: 15,
    name: "บางปะกง เขาดิน ฉะเชิงเทรา 24130",
    name_th: "บางปะกง เขาดิน ฉะเชิงเทรา 24130",
    name_en: "Bang Pakong, Khao Din, Chachoengsao, 24130",
  },
  {
    id: 240501,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "บ้านโพธิ์",
    subdistrictEn: "Ban Pho",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ บ้านโพธิ์ ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Ban Pho, Chachoengsao, 24140",
  },
  {
    id: 240502,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "เกาะไร่",
    subdistrictEn: "Ko Rai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ เกาะไร่ ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ เกาะไร่ ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Ko Rai, Chachoengsao, 24140",
  },
  {
    id: 240503,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "คลองขุด",
    subdistrictEn: "Khlong Khut",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ คลองขุด ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ คลองขุด ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Khlong Khut, Chachoengsao, 24140",
  },
  {
    id: 240504,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "คลองบ้านโพธิ์",
    subdistrictEn: "Khlong Ban Pho",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ คลองบ้านโพธิ์ ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ คลองบ้านโพธิ์ ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Khlong Ban Pho, Chachoengsao, 24140",
  },
  {
    id: 240505,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "คลองประเวศ",
    subdistrictEn: "Khlong Prawet",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ คลองประเวศ ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ คลองประเวศ ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Khlong Prawet, Chachoengsao, 24140",
  },
  {
    id: 240506,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "ดอนทราย",
    subdistrictEn: "Don Sai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ ดอนทราย ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ ดอนทราย ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Don Sai, Chachoengsao, 24140",
  },
  {
    id: 240507,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "เทพราช",
    subdistrictEn: "Theppharat",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ เทพราช ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ เทพราช ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Theppharat, Chachoengsao, 24140",
  },
  {
    id: 240508,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "ท่าพลับ",
    subdistrictEn: "Tha Phlap",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ ท่าพลับ ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ ท่าพลับ ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Tha Phlap, Chachoengsao, 24140",
  },
  {
    id: 240509,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "หนองตีนนก",
    subdistrictEn: "Nong Tin Nok",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ หนองตีนนก ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ หนองตีนนก ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Nong Tin Nok, Chachoengsao, 24140",
  },
  {
    id: 240510,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ หนองบัว ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ หนองบัว ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Nong Bua, Chachoengsao, 24140",
  },
  {
    id: 240511,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "บางซ่อน",
    subdistrictEn: "Bang Son",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ บางซ่อน ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ บางซ่อน ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Bang Son, Chachoengsao, 24140",
  },
  {
    id: 240512,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "บางกรูด",
    subdistrictEn: "Bang Krut",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ บางกรูด ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ บางกรูด ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Bang Krut, Chachoengsao, 24140",
  },
  {
    id: 240513,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "แหลมประดู่",
    subdistrictEn: "Laem Pradu",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ แหลมประดู่ ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ แหลมประดู่ ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Laem Pradu, Chachoengsao, 24140",
  },
  {
    id: 240514,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "ลาดขวาง",
    subdistrictEn: "Lat Khwang",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ ลาดขวาง ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ ลาดขวาง ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Lat Khwang, Chachoengsao, 24140",
  },
  {
    id: 240515,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "สนามจันทร์",
    subdistrictEn: "Sanam Chan",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ สนามจันทร์ ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ สนามจันทร์ ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Sanam Chan, Chachoengsao, 24140",
  },
  {
    id: 240516,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "แสนภูดาษ",
    subdistrictEn: "Saen Phu Dat",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ แสนภูดาษ ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ แสนภูดาษ ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Saen Phu Dat, Chachoengsao, 24140",
  },
  {
    id: 240517,
    districtTh: "บ้านโพธิ์",
    districtEn: "Ban Pho",
    subdistrictTh: "สิบเอ็ดศอก",
    subdistrictEn: "Sip Et Sok",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24140,
    district_code: 2405,
    province_code: 15,
    name: "บ้านโพธิ์ สิบเอ็ดศอก ฉะเชิงเทรา 24140",
    name_th: "บ้านโพธิ์ สิบเอ็ดศอก ฉะเชิงเทรา 24140",
    name_en: "Ban Pho, Sip Et Sok, Chachoengsao, 24140",
  },
  {
    id: 240601,
    districtTh: "พนมสารคาม",
    districtEn: "Phanom Sarakham",
    subdistrictTh: "เกาะขนุน",
    subdistrictEn: "Ko Khanun",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2406,
    province_code: 15,
    name: "พนมสารคาม เกาะขนุน ฉะเชิงเทรา 24120",
    name_th: "พนมสารคาม เกาะขนุน ฉะเชิงเทรา 24120",
    name_en: "Phanom Sarakham, Ko Khanun, Chachoengsao, 24120",
  },
  {
    id: 240602,
    districtTh: "พนมสารคาม",
    districtEn: "Phanom Sarakham",
    subdistrictTh: "บ้านซ่อง",
    subdistrictEn: "Ban Song",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2406,
    province_code: 15,
    name: "พนมสารคาม บ้านซ่อง ฉะเชิงเทรา 24120",
    name_th: "พนมสารคาม บ้านซ่อง ฉะเชิงเทรา 24120",
    name_en: "Phanom Sarakham, Ban Song, Chachoengsao, 24120",
  },
  {
    id: 240603,
    districtTh: "พนมสารคาม",
    districtEn: "Phanom Sarakham",
    subdistrictTh: "พนมสารคาม",
    subdistrictEn: "Phanom Sarakham",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2406,
    province_code: 15,
    name: "พนมสารคาม พนมสารคาม ฉะเชิงเทรา 24120",
    name_th: "พนมสารคาม พนมสารคาม ฉะเชิงเทรา 24120",
    name_en: "Phanom Sarakham, Phanom Sarakham, Chachoengsao, 24120",
  },
  {
    id: 240604,
    districtTh: "พนมสารคาม",
    districtEn: "Phanom Sarakham",
    subdistrictTh: "เมืองเก่า",
    subdistrictEn: "Mueang Kao",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2406,
    province_code: 15,
    name: "พนมสารคาม เมืองเก่า ฉะเชิงเทรา 24120",
    name_th: "พนมสารคาม เมืองเก่า ฉะเชิงเทรา 24120",
    name_en: "Phanom Sarakham, Mueang Kao, Chachoengsao, 24120",
  },
  {
    id: 240605,
    districtTh: "พนมสารคาม",
    districtEn: "Phanom Sarakham",
    subdistrictTh: "หนองยาว",
    subdistrictEn: "Nong Yao",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2406,
    province_code: 15,
    name: "พนมสารคาม หนองยาว ฉะเชิงเทรา 24120",
    name_th: "พนมสารคาม หนองยาว ฉะเชิงเทรา 24120",
    name_en: "Phanom Sarakham, Nong Yao, Chachoengsao, 24120",
  },
  {
    id: 240606,
    districtTh: "พนมสารคาม",
    districtEn: "Phanom Sarakham",
    subdistrictTh: "ท่าถ่าน",
    subdistrictEn: "Tha Than",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2406,
    province_code: 15,
    name: "พนมสารคาม ท่าถ่าน ฉะเชิงเทรา 24120",
    name_th: "พนมสารคาม ท่าถ่าน ฉะเชิงเทรา 24120",
    name_en: "Phanom Sarakham, Tha Than, Chachoengsao, 24120",
  },
  {
    id: 240607,
    districtTh: "พนมสารคาม",
    districtEn: "Phanom Sarakham",
    subdistrictTh: "หนองแหน",
    subdistrictEn: "Nong Nae",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2406,
    province_code: 15,
    name: "พนมสารคาม หนองแหน ฉะเชิงเทรา 24120",
    name_th: "พนมสารคาม หนองแหน ฉะเชิงเทรา 24120",
    name_en: "Phanom Sarakham, Nong Nae, Chachoengsao, 24120",
  },
  {
    id: 240608,
    districtTh: "พนมสารคาม",
    districtEn: "Phanom Sarakham",
    subdistrictTh: "เขาหินซ้อน",
    subdistrictEn: "Khao Hin Son",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2406,
    province_code: 15,
    name: "พนมสารคาม เขาหินซ้อน ฉะเชิงเทรา 24120",
    name_th: "พนมสารคาม เขาหินซ้อน ฉะเชิงเทรา 24120",
    name_en: "Phanom Sarakham, Khao Hin Son, Chachoengsao, 24120",
  },
  {
    id: 240701,
    districtTh: "ราชสาส์น",
    districtEn: "Ratchasan",
    subdistrictTh: "บางคา",
    subdistrictEn: "Bang Kha",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2407,
    province_code: 15,
    name: "ราชสาส์น บางคา ฉะเชิงเทรา 24120",
    name_th: "ราชสาส์น บางคา ฉะเชิงเทรา 24120",
    name_en: "Ratchasan, Bang Kha, Chachoengsao, 24120",
  },
  {
    id: 240702,
    districtTh: "ราชสาส์น",
    districtEn: "Ratchasan",
    subdistrictTh: "เมืองใหม่",
    subdistrictEn: "Mueang Mai",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2407,
    province_code: 15,
    name: "ราชสาส์น เมืองใหม่ ฉะเชิงเทรา 24120",
    name_th: "ราชสาส์น เมืองใหม่ ฉะเชิงเทรา 24120",
    name_en: "Ratchasan, Mueang Mai, Chachoengsao, 24120",
  },
  {
    id: 240703,
    districtTh: "ราชสาส์น",
    districtEn: "Ratchasan",
    subdistrictTh: "ดงน้อย",
    subdistrictEn: "Dong Noi",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24120,
    district_code: 2407,
    province_code: 15,
    name: "ราชสาส์น ดงน้อย ฉะเชิงเทรา 24120",
    name_th: "ราชสาส์น ดงน้อย ฉะเชิงเทรา 24120",
    name_en: "Ratchasan, Dong Noi, Chachoengsao, 24120",
  },
  {
    id: 240801,
    districtTh: "สนามชัยเขต",
    districtEn: "Sanam Chai Khet",
    subdistrictTh: "คู้ยายหมี",
    subdistrictEn: "Khu Yai Mi",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24160,
    district_code: 2408,
    province_code: 15,
    name: "สนามชัยเขต คู้ยายหมี ฉะเชิงเทรา 24160",
    name_th: "สนามชัยเขต คู้ยายหมี ฉะเชิงเทรา 24160",
    name_en: "Sanam Chai Khet, Khu Yai Mi, Chachoengsao, 24160",
  },
  {
    id: 240802,
    districtTh: "สนามชัยเขต",
    districtEn: "Sanam Chai Khet",
    subdistrictTh: "ท่ากระดาน",
    subdistrictEn: "Tha Kradan",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24160,
    district_code: 2408,
    province_code: 15,
    name: "สนามชัยเขต ท่ากระดาน ฉะเชิงเทรา 24160",
    name_th: "สนามชัยเขต ท่ากระดาน ฉะเชิงเทรา 24160",
    name_en: "Sanam Chai Khet, Tha Kradan, Chachoengsao, 24160",
  },
  {
    id: 240803,
    districtTh: "สนามชัยเขต",
    districtEn: "Sanam Chai Khet",
    subdistrictTh: "ทุ่งพระยา",
    subdistrictEn: "Thung Phraya",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24160,
    district_code: 2408,
    province_code: 15,
    name: "สนามชัยเขต ทุ่งพระยา ฉะเชิงเทรา 24160",
    name_th: "สนามชัยเขต ทุ่งพระยา ฉะเชิงเทรา 24160",
    name_en: "Sanam Chai Khet, Thung Phraya, Chachoengsao, 24160",
  },
  {
    id: 240805,
    districtTh: "สนามชัยเขต",
    districtEn: "Sanam Chai Khet",
    subdistrictTh: "ลาดกระทิง",
    subdistrictEn: "Lat Krathing",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24160,
    district_code: 2408,
    province_code: 15,
    name: "สนามชัยเขต ลาดกระทิง ฉะเชิงเทรา 24160",
    name_th: "สนามชัยเขต ลาดกระทิง ฉะเชิงเทรา 24160",
    name_en: "Sanam Chai Khet, Lat Krathing, Chachoengsao, 24160",
  },
  {
    id: 240901,
    districtTh: "แปลงยาว",
    districtEn: "Plaeng Yao",
    subdistrictTh: "แปลงยาว",
    subdistrictEn: "Plaeng Yao",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24190,
    district_code: 2409,
    province_code: 15,
    name: "แปลงยาว แปลงยาว ฉะเชิงเทรา 24190",
    name_th: "แปลงยาว แปลงยาว ฉะเชิงเทรา 24190",
    name_en: "Plaeng Yao, Plaeng Yao, Chachoengsao, 24190",
  },
  {
    id: 240902,
    districtTh: "แปลงยาว",
    districtEn: "Plaeng Yao",
    subdistrictTh: "วังเย็น",
    subdistrictEn: "Wang Yen",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24190,
    district_code: 2409,
    province_code: 15,
    name: "แปลงยาว วังเย็น ฉะเชิงเทรา 24190",
    name_th: "แปลงยาว วังเย็น ฉะเชิงเทรา 24190",
    name_en: "Plaeng Yao, Wang Yen, Chachoengsao, 24190",
  },
  {
    id: 240903,
    districtTh: "แปลงยาว",
    districtEn: "Plaeng Yao",
    subdistrictTh: "หัวสำโรง",
    subdistrictEn: "Hua Samrong",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24190,
    district_code: 2409,
    province_code: 15,
    name: "แปลงยาว หัวสำโรง ฉะเชิงเทรา 24190",
    name_th: "แปลงยาว หัวสำโรง ฉะเชิงเทรา 24190",
    name_en: "Plaeng Yao, Hua Samrong, Chachoengsao, 24190",
  },
  {
    id: 240904,
    districtTh: "แปลงยาว",
    districtEn: "Plaeng Yao",
    subdistrictTh: "หนองไม้แก่น",
    subdistrictEn: "Nong Mai Kaen",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24190,
    district_code: 2409,
    province_code: 15,
    name: "แปลงยาว หนองไม้แก่น ฉะเชิงเทรา 24190",
    name_th: "แปลงยาว หนองไม้แก่น ฉะเชิงเทรา 24190",
    name_en: "Plaeng Yao, Nong Mai Kaen, Chachoengsao, 24190",
  },
  {
    id: 241001,
    districtTh: "ท่าตะเกียบ",
    districtEn: "Tha Takiap",
    subdistrictTh: "ท่าตะเกียบ",
    subdistrictEn: "Tha Takiap",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24160,
    district_code: 2410,
    province_code: 15,
    name: "ท่าตะเกียบ ท่าตะเกียบ ฉะเชิงเทรา 24160",
    name_th: "ท่าตะเกียบ ท่าตะเกียบ ฉะเชิงเทรา 24160",
    name_en: "Tha Takiap, Tha Takiap, Chachoengsao, 24160",
  },
  {
    id: 241002,
    districtTh: "ท่าตะเกียบ",
    districtEn: "Tha Takiap",
    subdistrictTh: "คลองตะเกรา",
    subdistrictEn: "Khlong Takrao",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24160,
    district_code: 2410,
    province_code: 15,
    name: "ท่าตะเกียบ คลองตะเกรา ฉะเชิงเทรา 24160",
    name_th: "ท่าตะเกียบ คลองตะเกรา ฉะเชิงเทรา 24160",
    name_en: "Tha Takiap, Khlong Takrao, Chachoengsao, 24160",
  },
  {
    id: 241101,
    districtTh: "คลองเขื่อน",
    districtEn: "Khlong Khuean",
    subdistrictTh: "ก้อนแก้ว",
    subdistrictEn: "Kon Kaeo",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2411,
    province_code: 15,
    name: "คลองเขื่อน ก้อนแก้ว ฉะเชิงเทรา 24000",
    name_th: "คลองเขื่อน ก้อนแก้ว ฉะเชิงเทรา 24000",
    name_en: "Khlong Khuean, Kon Kaeo, Chachoengsao, 24000",
  },
  {
    id: 241102,
    districtTh: "คลองเขื่อน",
    districtEn: "Khlong Khuean",
    subdistrictTh: "คลองเขื่อน",
    subdistrictEn: "Khlong Khuean",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2411,
    province_code: 15,
    name: "คลองเขื่อน คลองเขื่อน ฉะเชิงเทรา 24000",
    name_th: "คลองเขื่อน คลองเขื่อน ฉะเชิงเทรา 24000",
    name_en: "Khlong Khuean, Khlong Khuean, Chachoengsao, 24000",
  },
  {
    id: 241103,
    districtTh: "คลองเขื่อน",
    districtEn: "Khlong Khuean",
    subdistrictTh: "บางเล่า",
    subdistrictEn: "Bang Lao",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2411,
    province_code: 15,
    name: "คลองเขื่อน บางเล่า ฉะเชิงเทรา 24000",
    name_th: "คลองเขื่อน บางเล่า ฉะเชิงเทรา 24000",
    name_en: "Khlong Khuean, Bang Lao, Chachoengsao, 24000",
  },
  {
    id: 241104,
    districtTh: "คลองเขื่อน",
    districtEn: "Khlong Khuean",
    subdistrictTh: "บางโรง",
    subdistrictEn: "Bang Rong",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24000,
    district_code: 2411,
    province_code: 15,
    name: "คลองเขื่อน บางโรง ฉะเชิงเทรา 24000",
    name_th: "คลองเขื่อน บางโรง ฉะเชิงเทรา 24000",
    name_en: "Khlong Khuean, Bang Rong, Chachoengsao, 24000",
  },
  {
    id: 241105,
    districtTh: "คลองเขื่อน",
    districtEn: "Khlong Khuean",
    subdistrictTh: "บางตลาด",
    subdistrictEn: "Bang Talat",
    provinceTh: "ฉะเชิงเทรา",
    provinceEn: "Chachoengsao",
    zipcode: 24110,
    district_code: 2411,
    province_code: 15,
    name: "คลองเขื่อน บางตลาด ฉะเชิงเทรา 24110",
    name_th: "คลองเขื่อน บางตลาด ฉะเชิงเทรา 24110",
    name_en: "Khlong Khuean, Bang Talat, Chachoengsao, 24110",
  },
  {
    id: 250101,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "หน้าเมือง",
    subdistrictEn: "Na Mueang",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี หน้าเมือง ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี หน้าเมือง ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Na Mueang, Prachin Buri, 25000",
  },
  {
    id: 250102,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "รอบเมือง",
    subdistrictEn: "Na Mueang",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี รอบเมือง ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี รอบเมือง ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Na Mueang, Prachin Buri, 25000",
  },
  {
    id: 250103,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "วัดโบสถ์",
    subdistrictEn: "Wat Bot",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี วัดโบสถ์ ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี วัดโบสถ์ ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Wat Bot, Prachin Buri, 25000",
  },
  {
    id: 250104,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "บางเดชะ",
    subdistrictEn: "Bang Decha",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี บางเดชะ ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี บางเดชะ ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Bang Decha, Prachin Buri, 25000",
  },
  {
    id: 250105,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "ท่างาม",
    subdistrictEn: "Tha Ngam",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี ท่างาม ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี ท่างาม ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Tha Ngam, Prachin Buri, 25000",
  },
  {
    id: 250106,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "บางบริบูรณ์",
    subdistrictEn: "Bang Boribun",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี บางบริบูรณ์ ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี บางบริบูรณ์ ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Bang Boribun, Prachin Buri, 25000",
  },
  {
    id: 250107,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "ดงพระราม",
    subdistrictEn: "Dong Phra Ram",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี ดงพระราม ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี ดงพระราม ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Dong Phra Ram, Prachin Buri, 25000",
  },
  {
    id: 250108,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "บ้านพระ",
    subdistrictEn: "Ban Phra",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25230,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี บ้านพระ ปราจีนบุรี 25230",
    name_th: "เมืองปราจีนบุรี บ้านพระ ปราจีนบุรี 25230",
    name_en: "Mueang Prachin Buri, Ban Phra, Prachin Buri, 25230",
  },
  {
    id: 250109,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "โคกไม้ลาย",
    subdistrictEn: "Khok Mai Lai",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25230,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี โคกไม้ลาย ปราจีนบุรี 25230",
    name_th: "เมืองปราจีนบุรี โคกไม้ลาย ปราจีนบุรี 25230",
    name_en: "Mueang Prachin Buri, Khok Mai Lai, Prachin Buri, 25230",
  },
  {
    id: 250110,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "ไม้เค็ด",
    subdistrictEn: "Mai Khet",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25230,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี ไม้เค็ด ปราจีนบุรี 25230",
    name_th: "เมืองปราจีนบุรี ไม้เค็ด ปราจีนบุรี 25230",
    name_en: "Mueang Prachin Buri, Mai Khet, Prachin Buri, 25230",
  },
  {
    id: 250111,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "ดงขี้เหล็ก",
    subdistrictEn: "Dong Khilek",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี ดงขี้เหล็ก ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี ดงขี้เหล็ก ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Dong Khilek, Prachin Buri, 25000",
  },
  {
    id: 250112,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "เนินหอม",
    subdistrictEn: "Noen Hom",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25230,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี เนินหอม ปราจีนบุรี 25230",
    name_th: "เมืองปราจีนบุรี เนินหอม ปราจีนบุรี 25230",
    name_en: "Mueang Prachin Buri, Noen Hom, Prachin Buri, 25230",
  },
  {
    id: 250113,
    districtTh: "เมืองปราจีนบุรี",
    districtEn: "Mueang Prachin Buri",
    subdistrictTh: "โนนห้อม",
    subdistrictEn: "Non Hom",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25000,
    district_code: 2501,
    province_code: 16,
    name: "เมืองปราจีนบุรี โนนห้อม ปราจีนบุรี 25000",
    name_th: "เมืองปราจีนบุรี โนนห้อม ปราจีนบุรี 25000",
    name_en: "Mueang Prachin Buri, Non Hom, Prachin Buri, 25000",
  },
  {
    id: 250201,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "กบินทร์",
    subdistrictEn: "Kabin",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี กบินทร์ ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี กบินทร์ ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Kabin, Prachin Buri, 25110",
  },
  {
    id: 250202,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "เมืองเก่า",
    subdistrictEn: "Mueang Kao",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25240,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี เมืองเก่า ปราจีนบุรี 25240",
    name_th: "กบินทร์บุรี เมืองเก่า ปราจีนบุรี 25240",
    name_en: "Kabin Buri, Mueang Kao, Prachin Buri, 25240",
  },
  {
    id: 250203,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "วังดาล",
    subdistrictEn: "Wang Dan",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี วังดาล ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี วังดาล ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Wang Dan, Prachin Buri, 25110",
  },
  {
    id: 250204,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "นนทรี",
    subdistrictEn: "Nonsi",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี นนทรี ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี นนทรี ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Nonsi, Prachin Buri, 25110",
  },
  {
    id: 250205,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "ย่านรี",
    subdistrictEn: "Yan Ri",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี ย่านรี ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี ย่านรี ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Yan Ri, Prachin Buri, 25110",
  },
  {
    id: 250206,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "วังตะเคียน",
    subdistrictEn: "Wang Takhian",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี วังตะเคียน ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี วังตะเคียน ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Wang Takhian, Prachin Buri, 25110",
  },
  {
    id: 250207,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "หาดนางแก้ว",
    subdistrictEn: "Hat Nang Kaeo",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี หาดนางแก้ว ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี หาดนางแก้ว ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Hat Nang Kaeo, Prachin Buri, 25110",
  },
  {
    id: 250208,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "ลาดตะเคียน",
    subdistrictEn: "Lat Takhian",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี ลาดตะเคียน ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี ลาดตะเคียน ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Lat Takhian, Prachin Buri, 25110",
  },
  {
    id: 250209,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี บ้านนา ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี บ้านนา ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Ban Na, Prachin Buri, 25110",
  },
  {
    id: 250210,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "บ่อทอง",
    subdistrictEn: "Bo Thong",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี บ่อทอง ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี บ่อทอง ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Bo Thong, Prachin Buri, 25110",
  },
  {
    id: 250211,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "หนองกี่",
    subdistrictEn: "Nong Ki",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี หนองกี่ ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี หนองกี่ ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Nong Ki, Prachin Buri, 25110",
  },
  {
    id: 250212,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "นาแขม",
    subdistrictEn: "Na Khaem",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี นาแขม ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี นาแขม ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Na Khaem, Prachin Buri, 25110",
  },
  {
    id: 250213,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "เขาไม้แก้ว",
    subdistrictEn: "Khao Mai Kaeo",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี เขาไม้แก้ว ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี เขาไม้แก้ว ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Khao Mai Kaeo, Prachin Buri, 25110",
  },
  {
    id: 250214,
    districtTh: "กบินทร์บุรี",
    districtEn: "Kabin Buri",
    subdistrictTh: "วังท่าช้าง",
    subdistrictEn: "Wang Tha Chang",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25110,
    district_code: 2502,
    province_code: 16,
    name: "กบินทร์บุรี วังท่าช้าง ปราจีนบุรี 25110",
    name_th: "กบินทร์บุรี วังท่าช้าง ปราจีนบุรี 25110",
    name_en: "Kabin Buri, Wang Tha Chang, Prachin Buri, 25110",
  },
  {
    id: 250301,
    districtTh: "นาดี",
    districtEn: "Na Di",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25220,
    district_code: 2503,
    province_code: 16,
    name: "นาดี นาดี ปราจีนบุรี 25220",
    name_th: "นาดี นาดี ปราจีนบุรี 25220",
    name_en: "Na Di, Na Di, Prachin Buri, 25220",
  },
  {
    id: 250302,
    districtTh: "นาดี",
    districtEn: "Na Di",
    subdistrictTh: "สำพันตา",
    subdistrictEn: "Samphan Ta",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25220,
    district_code: 2503,
    province_code: 16,
    name: "นาดี สำพันตา ปราจีนบุรี 25220",
    name_th: "นาดี สำพันตา ปราจีนบุรี 25220",
    name_en: "Na Di, Samphan Ta, Prachin Buri, 25220",
  },
  {
    id: 250303,
    districtTh: "นาดี",
    districtEn: "Na Di",
    subdistrictTh: "สะพานหิน",
    subdistrictEn: "Saphan Hin",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25220,
    district_code: 2503,
    province_code: 16,
    name: "นาดี สะพานหิน ปราจีนบุรี 25220",
    name_th: "นาดี สะพานหิน ปราจีนบุรี 25220",
    name_en: "Na Di, Saphan Hin, Prachin Buri, 25220",
  },
  {
    id: 250304,
    districtTh: "นาดี",
    districtEn: "Na Di",
    subdistrictTh: "ทุ่งโพธิ์",
    subdistrictEn: "Thung Pho",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25220,
    district_code: 2503,
    province_code: 16,
    name: "นาดี ทุ่งโพธิ์ ปราจีนบุรี 25220",
    name_th: "นาดี ทุ่งโพธิ์ ปราจีนบุรี 25220",
    name_en: "Na Di, Thung Pho, Prachin Buri, 25220",
  },
  {
    id: 250305,
    districtTh: "นาดี",
    districtEn: "Na Di",
    subdistrictTh: "แก่งดินสอ",
    subdistrictEn: "Kaeng Dinso",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25220,
    district_code: 2503,
    province_code: 16,
    name: "นาดี แก่งดินสอ ปราจีนบุรี 25220",
    name_th: "นาดี แก่งดินสอ ปราจีนบุรี 25220",
    name_en: "Na Di, Kaeng Dinso, Prachin Buri, 25220",
  },
  {
    id: 250306,
    districtTh: "นาดี",
    districtEn: "Na Di",
    subdistrictTh: "บุพราหมณ์",
    subdistrictEn: "Bu Phram",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25220,
    district_code: 2503,
    province_code: 16,
    name: "นาดี บุพราหมณ์ ปราจีนบุรี 25220",
    name_th: "นาดี บุพราหมณ์ ปราจีนบุรี 25220",
    name_en: "Na Di, Bu Phram, Prachin Buri, 25220",
  },
  {
    id: 250601,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "บ้านสร้าง",
    subdistrictEn: "Ban Sang",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง บ้านสร้าง ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง บ้านสร้าง ปราจีนบุรี 25150",
    name_en: "Ban Sang, Ban Sang, Prachin Buri, 25150",
  },
  {
    id: 250602,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "บางกระเบา",
    subdistrictEn: "Bang Krabao",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง บางกระเบา ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง บางกระเบา ปราจีนบุรี 25150",
    name_en: "Ban Sang, Bang Krabao, Prachin Buri, 25150",
  },
  {
    id: 250603,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "บางเตย",
    subdistrictEn: "Bang Toei",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง บางเตย ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง บางเตย ปราจีนบุรี 25150",
    name_en: "Ban Sang, Bang Toei, Prachin Buri, 25150",
  },
  {
    id: 250604,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "บางยาง",
    subdistrictEn: "Bang Yang",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง บางยาง ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง บางยาง ปราจีนบุรี 25150",
    name_en: "Ban Sang, Bang Yang, Prachin Buri, 25150",
  },
  {
    id: 250605,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "บางแตน",
    subdistrictEn: "Bang Taen",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง บางแตน ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง บางแตน ปราจีนบุรี 25150",
    name_en: "Ban Sang, Bang Taen, Prachin Buri, 25150",
  },
  {
    id: 250606,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "บางพลวง",
    subdistrictEn: "Bang Phluang",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง บางพลวง ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง บางพลวง ปราจีนบุรี 25150",
    name_en: "Ban Sang, Bang Phluang, Prachin Buri, 25150",
  },
  {
    id: 250607,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "บางปลาร้า",
    subdistrictEn: "Bang Pla Ra",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง บางปลาร้า ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง บางปลาร้า ปราจีนบุรี 25150",
    name_en: "Ban Sang, Bang Pla Ra, Prachin Buri, 25150",
  },
  {
    id: 250608,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "บางขาม",
    subdistrictEn: "Bang Kham",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง บางขาม ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง บางขาม ปราจีนบุรี 25150",
    name_en: "Ban Sang, Bang Kham, Prachin Buri, 25150",
  },
  {
    id: 250609,
    districtTh: "บ้านสร้าง",
    districtEn: "Ban Sang",
    subdistrictTh: "กระทุ่มแพ้ว",
    subdistrictEn: "Krathum Phaeo)",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25150,
    district_code: 2506,
    province_code: 16,
    name: "บ้านสร้าง กระทุ่มแพ้ว ปราจีนบุรี 25150",
    name_th: "บ้านสร้าง กระทุ่มแพ้ว ปราจีนบุรี 25150",
    name_en: "Ban Sang, Krathum Phaeo), Prachin Buri, 25150",
  },
  {
    id: 250701,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "ประจันตคาม",
    subdistrictEn: "Prachantakham",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม ประจันตคาม ปราจีนบุรี 25130",
    name_th: "ประจันตคาม ประจันตคาม ปราจีนบุรี 25130",
    name_en: "Prachantakham, Prachantakham, Prachin Buri, 25130",
  },
  {
    id: 250702,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "เกาะลอย",
    subdistrictEn: "Ko Loi",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม เกาะลอย ปราจีนบุรี 25130",
    name_th: "ประจันตคาม เกาะลอย ปราจีนบุรี 25130",
    name_en: "Prachantakham, Ko Loi, Prachin Buri, 25130",
  },
  {
    id: 250703,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "บ้านหอย",
    subdistrictEn: "Ban Hoi",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม บ้านหอย ปราจีนบุรี 25130",
    name_th: "ประจันตคาม บ้านหอย ปราจีนบุรี 25130",
    name_en: "Prachantakham, Ban Hoi, Prachin Buri, 25130",
  },
  {
    id: 250704,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "หนองแสง",
    subdistrictEn: "Nong Saeng",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม หนองแสง ปราจีนบุรี 25130",
    name_th: "ประจันตคาม หนองแสง ปราจีนบุรี 25130",
    name_en: "Prachantakham, Nong Saeng, Prachin Buri, 25130",
  },
  {
    id: 250705,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "ดงบัง",
    subdistrictEn: "Dong Bang",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม ดงบัง ปราจีนบุรี 25130",
    name_th: "ประจันตคาม ดงบัง ปราจีนบุรี 25130",
    name_en: "Prachantakham, Dong Bang, Prachin Buri, 25130",
  },
  {
    id: 250706,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "คำโตนด",
    subdistrictEn: "Kham Tanot",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม คำโตนด ปราจีนบุรี 25130",
    name_th: "ประจันตคาม คำโตนด ปราจีนบุรี 25130",
    name_en: "Prachantakham, Kham Tanot, Prachin Buri, 25130",
  },
  {
    id: 250707,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "บุฝ้าย",
    subdistrictEn: "Bu Fai",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม บุฝ้าย ปราจีนบุรี 25130",
    name_th: "ประจันตคาม บุฝ้าย ปราจีนบุรี 25130",
    name_en: "Prachantakham, Bu Fai, Prachin Buri, 25130",
  },
  {
    id: 250708,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "หนองแก้ว",
    subdistrictEn: "Nong Kaeo",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม หนองแก้ว ปราจีนบุรี 25130",
    name_th: "ประจันตคาม หนองแก้ว ปราจีนบุรี 25130",
    name_en: "Prachantakham, Nong Kaeo, Prachin Buri, 25130",
  },
  {
    id: 250709,
    districtTh: "ประจันตคาม",
    districtEn: "Prachantakham",
    subdistrictTh: "โพธิ์งาม",
    subdistrictEn: "Pho Ngam",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25130,
    district_code: 2507,
    province_code: 16,
    name: "ประจันตคาม โพธิ์งาม ปราจีนบุรี 25130",
    name_th: "ประจันตคาม โพธิ์งาม ปราจีนบุรี 25130",
    name_en: "Prachantakham, Pho Ngam, Prachin Buri, 25130",
  },
  {
    id: 250801,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "ศรีมหาโพธิ",
    subdistrictEn: "Si Maha Phot",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ ศรีมหาโพธิ ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ ศรีมหาโพธิ ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Si Maha Phot, Prachin Buri, 25140",
  },
  {
    id: 250802,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "สัมพันธ์",
    subdistrictEn: "Samphan",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ สัมพันธ์ ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ สัมพันธ์ ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Samphan, Prachin Buri, 25140",
  },
  {
    id: 250803,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "บ้านทาม",
    subdistrictEn: "Ban Tham",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ บ้านทาม ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ บ้านทาม ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Ban Tham, Prachin Buri, 25140",
  },
  {
    id: 250804,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "ท่าตูม",
    subdistrictEn: "Tha Tum",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ ท่าตูม ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ ท่าตูม ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Tha Tum, Prachin Buri, 25140",
  },
  {
    id: 250805,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "บางกุ้ง",
    subdistrictEn: "Bang Kung",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ บางกุ้ง ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ บางกุ้ง ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Bang Kung, Prachin Buri, 25140",
  },
  {
    id: 250806,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "ดงกระทงยาม",
    subdistrictEn: "Dong Krathong Yam",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ ดงกระทงยาม ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ ดงกระทงยาม ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Dong Krathong Yam, Prachin Buri, 25140",
  },
  {
    id: 250807,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "หนองโพรง",
    subdistrictEn: "Nong Phrong",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ หนองโพรง ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ หนองโพรง ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Nong Phrong, Prachin Buri, 25140",
  },
  {
    id: 250808,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "หัวหว้า",
    subdistrictEn: "Hua Wa",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ หัวหว้า ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ หัวหว้า ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Hua Wa, Prachin Buri, 25140",
  },
  {
    id: 250809,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "หาดยาง",
    subdistrictEn: "Hat Yang",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ หาดยาง ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ หาดยาง ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Hat Yang, Prachin Buri, 25140",
  },
  {
    id: 250810,
    districtTh: "ศรีมหาโพธิ",
    districtEn: "Si Maha Phot",
    subdistrictTh: "กรอกสมบูรณ์",
    subdistrictEn: "Krok Sombun",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25140,
    district_code: 2508,
    province_code: 16,
    name: "ศรีมหาโพธิ กรอกสมบูรณ์ ปราจีนบุรี 25140",
    name_th: "ศรีมหาโพธิ กรอกสมบูรณ์ ปราจีนบุรี 25140",
    name_en: "Si Maha Phot, Krok Sombun, Prachin Buri, 25140",
  },
  {
    id: 250901,
    districtTh: "ศรีมโหสถ",
    districtEn: "Si Mahosot",
    subdistrictTh: "โคกปีบ",
    subdistrictEn: "Khok Pip",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25190,
    district_code: 2509,
    province_code: 16,
    name: "ศรีมโหสถ โคกปีบ ปราจีนบุรี 25190",
    name_th: "ศรีมโหสถ โคกปีบ ปราจีนบุรี 25190",
    name_en: "Si Mahosot, Khok Pip, Prachin Buri, 25190",
  },
  {
    id: 250902,
    districtTh: "ศรีมโหสถ",
    districtEn: "Si Mahosot",
    subdistrictTh: "โคกไทย",
    subdistrictEn: "Khok Thai",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25190,
    district_code: 2509,
    province_code: 16,
    name: "ศรีมโหสถ โคกไทย ปราจีนบุรี 25190",
    name_th: "ศรีมโหสถ โคกไทย ปราจีนบุรี 25190",
    name_en: "Si Mahosot, Khok Thai, Prachin Buri, 25190",
  },
  {
    id: 250903,
    districtTh: "ศรีมโหสถ",
    districtEn: "Si Mahosot",
    subdistrictTh: "คู้ลำพัน",
    subdistrictEn: "Khu Lam Phan",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25190,
    district_code: 2509,
    province_code: 16,
    name: "ศรีมโหสถ คู้ลำพัน ปราจีนบุรี 25190",
    name_th: "ศรีมโหสถ คู้ลำพัน ปราจีนบุรี 25190",
    name_en: "Si Mahosot, Khu Lam Phan, Prachin Buri, 25190",
  },
  {
    id: 250904,
    districtTh: "ศรีมโหสถ",
    districtEn: "Si Mahosot",
    subdistrictTh: "ไผ่ชะเลือด",
    subdistrictEn: "Phai Cha Lueat",
    provinceTh: "ปราจีนบุรี",
    provinceEn: "Prachin Buri",
    zipcode: 25190,
    district_code: 2509,
    province_code: 16,
    name: "ศรีมโหสถ ไผ่ชะเลือด ปราจีนบุรี 25190",
    name_th: "ศรีมโหสถ ไผ่ชะเลือด ปราจีนบุรี 25190",
    name_en: "Si Mahosot, Phai Cha Lueat, Prachin Buri, 25190",
  },
  {
    id: 260101,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "นครนายก",
    subdistrictEn: "Nakhon Nayok",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก นครนายก นครนายก 26000",
    name_th: "เมืองนครนายก นครนายก นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Nakhon Nayok, Nakhon Nayok, 26000",
  },
  {
    id: 260102,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก ท่าช้าง นครนายก 26000",
    name_th: "เมืองนครนายก ท่าช้าง นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Tha Chang, Nakhon Nayok, 26000",
  },
  {
    id: 260103,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "บ้านใหญ่",
    subdistrictEn: "Ban Yai",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก บ้านใหญ่ นครนายก 26000",
    name_th: "เมืองนครนายก บ้านใหญ่ นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Ban Yai, Nakhon Nayok, 26000",
  },
  {
    id: 260104,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "วังกระโจม",
    subdistrictEn: "Wang Krachom",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก วังกระโจม นครนายก 26000",
    name_th: "เมืองนครนายก วังกระโจม นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Wang Krachom, Nakhon Nayok, 26000",
  },
  {
    id: 260105,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "ท่าทราย",
    subdistrictEn: "Tha Sai",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก ท่าทราย นครนายก 26000",
    name_th: "เมืองนครนายก ท่าทราย นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Tha Sai, Nakhon Nayok, 26000",
  },
  {
    id: 260106,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "ดอนยอ",
    subdistrictEn: "Don Yo",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก ดอนยอ นครนายก 26000",
    name_th: "เมืองนครนายก ดอนยอ นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Don Yo, Nakhon Nayok, 26000",
  },
  {
    id: 260107,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "ศรีจุฬา",
    subdistrictEn: "Si Chula",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก ศรีจุฬา นครนายก 26000",
    name_th: "เมืองนครนายก ศรีจุฬา นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Si Chula, Nakhon Nayok, 26000",
  },
  {
    id: 260108,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "ดงละคร",
    subdistrictEn: "Dong Lakhon",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก ดงละคร นครนายก 26000",
    name_th: "เมืองนครนายก ดงละคร นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Dong Lakhon, Nakhon Nayok, 26000",
  },
  {
    id: 260109,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "ศรีนาวา",
    subdistrictEn: "Si Nawa",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก ศรีนาวา นครนายก 26000",
    name_th: "เมืองนครนายก ศรีนาวา นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Si Nawa, Nakhon Nayok, 26000",
  },
  {
    id: 260110,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "สาริกา",
    subdistrictEn: "Sarika",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก สาริกา นครนายก 26000",
    name_th: "เมืองนครนายก สาริกา นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Sarika, Nakhon Nayok, 26000",
  },
  {
    id: 260111,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "หินตั้ง",
    subdistrictEn: "Hin Tang",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก หินตั้ง นครนายก 26000",
    name_th: "เมืองนครนายก หินตั้ง นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Hin Tang, Nakhon Nayok, 26000",
  },
  {
    id: 260112,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "เขาพระ",
    subdistrictEn: "Khao Phra",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก เขาพระ นครนายก 26000",
    name_th: "เมืองนครนายก เขาพระ นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Khao Phra, Nakhon Nayok, 26000",
  },
  {
    id: 260113,
    districtTh: "เมืองนครนายก",
    districtEn: "Mueang Nakhon Nayok",
    subdistrictTh: "พรหมณี",
    subdistrictEn: "Phrommani",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26000,
    district_code: 2601,
    province_code: 17,
    name: "เมืองนครนายก พรหมณี นครนายก 26000",
    name_th: "เมืองนครนายก พรหมณี นครนายก 26000",
    name_en: "Mueang Nakhon Nayok, Phrommani, Nakhon Nayok, 26000",
  },
  {
    id: 260201,
    districtTh: "ปากพลี",
    districtEn: "Pak Phli",
    subdistrictTh: "เกาะหวาย",
    subdistrictEn: "Ko Wai",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26130,
    district_code: 2602,
    province_code: 17,
    name: "ปากพลี เกาะหวาย นครนายก 26130",
    name_th: "ปากพลี เกาะหวาย นครนายก 26130",
    name_en: "Pak Phli, Ko Wai, Nakhon Nayok, 26130",
  },
  {
    id: 260202,
    districtTh: "ปากพลี",
    districtEn: "Pak Phli",
    subdistrictTh: "เกาะโพธิ์",
    subdistrictEn: "Ko Pho",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26130,
    district_code: 2602,
    province_code: 17,
    name: "ปากพลี เกาะโพธิ์ นครนายก 26130",
    name_th: "ปากพลี เกาะโพธิ์ นครนายก 26130",
    name_en: "Pak Phli, Ko Pho, Nakhon Nayok, 26130",
  },
  {
    id: 260203,
    districtTh: "ปากพลี",
    districtEn: "Pak Phli",
    subdistrictTh: "ปากพลี",
    subdistrictEn: "Pak Phli",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26130,
    district_code: 2602,
    province_code: 17,
    name: "ปากพลี ปากพลี นครนายก 26130",
    name_th: "ปากพลี ปากพลี นครนายก 26130",
    name_en: "Pak Phli, Pak Phli, Nakhon Nayok, 26130",
  },
  {
    id: 260204,
    districtTh: "ปากพลี",
    districtEn: "Pak Phli",
    subdistrictTh: "โคกกรวด",
    subdistrictEn: "Khok Kruat",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26130,
    district_code: 2602,
    province_code: 17,
    name: "ปากพลี โคกกรวด นครนายก 26130",
    name_th: "ปากพลี โคกกรวด นครนายก 26130",
    name_en: "Pak Phli, Khok Kruat, Nakhon Nayok, 26130",
  },
  {
    id: 260205,
    districtTh: "ปากพลี",
    districtEn: "Pak Phli",
    subdistrictTh: "ท่าเรือ",
    subdistrictEn: "Tha Ruea",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26130,
    district_code: 2602,
    province_code: 17,
    name: "ปากพลี ท่าเรือ นครนายก 26130",
    name_th: "ปากพลี ท่าเรือ นครนายก 26130",
    name_en: "Pak Phli, Tha Ruea, Nakhon Nayok, 26130",
  },
  {
    id: 260206,
    districtTh: "ปากพลี",
    districtEn: "Pak Phli",
    subdistrictTh: "หนองแสง",
    subdistrictEn: "Nong Saeng",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26130,
    district_code: 2602,
    province_code: 17,
    name: "ปากพลี หนองแสง นครนายก 26130",
    name_th: "ปากพลี หนองแสง นครนายก 26130",
    name_en: "Pak Phli, Nong Saeng, Nakhon Nayok, 26130",
  },
  {
    id: 260207,
    districtTh: "ปากพลี",
    districtEn: "Pak Phli",
    subdistrictTh: "นาหินลาด",
    subdistrictEn: "Na Hin Lat",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26130,
    district_code: 2602,
    province_code: 17,
    name: "ปากพลี นาหินลาด นครนายก 26130",
    name_th: "ปากพลี นาหินลาด นครนายก 26130",
    name_en: "Pak Phli, Na Hin Lat, Nakhon Nayok, 26130",
  },
  {
    id: 260301,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา บ้านนา นครนายก 26110",
    name_th: "บ้านนา บ้านนา นครนายก 26110",
    name_en: "Ban Na, Ban Na, Nakhon Nayok, 26110",
  },
  {
    id: 260302,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "บ้านพร้าว",
    subdistrictEn: "Ban Phrao",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา บ้านพร้าว นครนายก 26110",
    name_th: "บ้านนา บ้านพร้าว นครนายก 26110",
    name_en: "Ban Na, Ban Phrao, Nakhon Nayok, 26110",
  },
  {
    id: 260303,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "บ้านพริก",
    subdistrictEn: "Ban Phrik",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา บ้านพริก นครนายก 26110",
    name_th: "บ้านนา บ้านพริก นครนายก 26110",
    name_en: "Ban Na, Ban Phrik, Nakhon Nayok, 26110",
  },
  {
    id: 260304,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "อาษา",
    subdistrictEn: "Asa",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา อาษา นครนายก 26110",
    name_th: "บ้านนา อาษา นครนายก 26110",
    name_en: "Ban Na, Asa, Nakhon Nayok, 26110",
  },
  {
    id: 260305,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "ทองหลาง",
    subdistrictEn: "Thonglang",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา ทองหลาง นครนายก 26110",
    name_th: "บ้านนา ทองหลาง นครนายก 26110",
    name_en: "Ban Na, Thonglang, Nakhon Nayok, 26110",
  },
  {
    id: 260306,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "บางอ้อ",
    subdistrictEn: "Bang O",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา บางอ้อ นครนายก 26110",
    name_th: "บ้านนา บางอ้อ นครนายก 26110",
    name_en: "Ban Na, Bang O, Nakhon Nayok, 26110",
  },
  {
    id: 260307,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "พิกุลออก",
    subdistrictEn: "Phikun Ok",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา พิกุลออก นครนายก 26110",
    name_th: "บ้านนา พิกุลออก นครนายก 26110",
    name_en: "Ban Na, Phikun Ok, Nakhon Nayok, 26110",
  },
  {
    id: 260308,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "ป่าขะ",
    subdistrictEn: "Pa Kha",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา ป่าขะ นครนายก 26110",
    name_th: "บ้านนา ป่าขะ นครนายก 26110",
    name_en: "Ban Na, Pa Kha, Nakhon Nayok, 26110",
  },
  {
    id: 260309,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "เขาเพิ่ม",
    subdistrictEn: "Khao Phoem",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา เขาเพิ่ม นครนายก 26110",
    name_th: "บ้านนา เขาเพิ่ม นครนายก 26110",
    name_en: "Ban Na, Khao Phoem, Nakhon Nayok, 26110",
  },
  {
    id: 260310,
    districtTh: "บ้านนา",
    districtEn: "Ban Na",
    subdistrictTh: "ศรีกะอาง",
    subdistrictEn: "Si Ka-ang",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26110,
    district_code: 2603,
    province_code: 17,
    name: "บ้านนา ศรีกะอาง นครนายก 26110",
    name_th: "บ้านนา ศรีกะอาง นครนายก 26110",
    name_en: "Ban Na, Si Ka-ang, Nakhon Nayok, 26110",
  },
  {
    id: 260401,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "พระอาจารย์",
    subdistrictEn: "Phra Achan",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ พระอาจารย์ นครนายก 26120",
    name_th: "องครักษ์ พระอาจารย์ นครนายก 26120",
    name_en: "Ongkharak, Phra Achan, Nakhon Nayok, 26120",
  },
  {
    id: 260402,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "บึงศาล",
    subdistrictEn: "Bueng San",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ บึงศาล นครนายก 26120",
    name_th: "องครักษ์ บึงศาล นครนายก 26120",
    name_en: "Ongkharak, Bueng San, Nakhon Nayok, 26120",
  },
  {
    id: 260403,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "ศีรษะกระบือ",
    subdistrictEn: "Sisa Krabue",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ ศีรษะกระบือ นครนายก 26120",
    name_th: "องครักษ์ ศีรษะกระบือ นครนายก 26120",
    name_en: "Ongkharak, Sisa Krabue, Nakhon Nayok, 26120",
  },
  {
    id: 260404,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "โพธิ์แทน",
    subdistrictEn: "Pho Thaen",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ โพธิ์แทน นครนายก 26120",
    name_th: "องครักษ์ โพธิ์แทน นครนายก 26120",
    name_en: "Ongkharak, Pho Thaen, Nakhon Nayok, 26120",
  },
  {
    id: 260405,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "บางสมบูรณ์",
    subdistrictEn: "Bang Sombun",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ บางสมบูรณ์ นครนายก 26120",
    name_th: "องครักษ์ บางสมบูรณ์ นครนายก 26120",
    name_en: "Ongkharak, Bang Sombun, Nakhon Nayok, 26120",
  },
  {
    id: 260406,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "ทรายมูล",
    subdistrictEn: "Sai Mun",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ ทรายมูล นครนายก 26120",
    name_th: "องครักษ์ ทรายมูล นครนายก 26120",
    name_en: "Ongkharak, Sai Mun, Nakhon Nayok, 26120",
  },
  {
    id: 260407,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "บางปลากด",
    subdistrictEn: "Bang Pla Kot",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ บางปลากด นครนายก 26120",
    name_th: "องครักษ์ บางปลากด นครนายก 26120",
    name_en: "Ongkharak, Bang Pla Kot, Nakhon Nayok, 26120",
  },
  {
    id: 260408,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "บางลูกเสือ",
    subdistrictEn: "Bang Luk Suea",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ บางลูกเสือ นครนายก 26120",
    name_th: "องครักษ์ บางลูกเสือ นครนายก 26120",
    name_en: "Ongkharak, Bang Luk Suea, Nakhon Nayok, 26120",
  },
  {
    id: 260409,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "องครักษ์",
    subdistrictEn: "Ongkharak",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ องครักษ์ นครนายก 26120",
    name_th: "องครักษ์ องครักษ์ นครนายก 26120",
    name_en: "Ongkharak, Ongkharak, Nakhon Nayok, 26120",
  },
  {
    id: 260410,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "ชุมพล",
    subdistrictEn: "Chumphon",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ ชุมพล นครนายก 26120",
    name_th: "องครักษ์ ชุมพล นครนายก 26120",
    name_en: "Ongkharak, Chumphon, Nakhon Nayok, 26120",
  },
  {
    id: 260411,
    districtTh: "องครักษ์",
    districtEn: "Ongkharak",
    subdistrictTh: "คลองใหญ่",
    subdistrictEn: "Khlong Yai",
    provinceTh: "นครนายก",
    provinceEn: "Nakhon Nayok",
    zipcode: 26120,
    district_code: 2604,
    province_code: 17,
    name: "องครักษ์ คลองใหญ่ นครนายก 26120",
    name_th: "องครักษ์ คลองใหญ่ นครนายก 26120",
    name_en: "Ongkharak, Khlong Yai, Nakhon Nayok, 26120",
  },
  {
    id: 270101,
    districtTh: "เมืองสระแก้ว",
    districtEn: "Mueang Sa Kaeo",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2701,
    province_code: 18,
    name: "เมืองสระแก้ว สระแก้ว สระแก้ว 27000",
    name_th: "เมืองสระแก้ว สระแก้ว สระแก้ว 27000",
    name_en: "Mueang Sa Kaeo, Sa Kaeo, Sa Kaeo, 27000",
  },
  {
    id: 270102,
    districtTh: "เมืองสระแก้ว",
    districtEn: "Mueang Sa Kaeo",
    subdistrictTh: "บ้านแก้ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2701,
    province_code: 18,
    name: "เมืองสระแก้ว บ้านแก้ง สระแก้ว 27000",
    name_th: "เมืองสระแก้ว บ้านแก้ง สระแก้ว 27000",
    name_en: "Mueang Sa Kaeo, Ban Kaeng, Sa Kaeo, 27000",
  },
  {
    id: 270103,
    districtTh: "เมืองสระแก้ว",
    districtEn: "Mueang Sa Kaeo",
    subdistrictTh: "ศาลาลำดวน",
    subdistrictEn: "Sala Lamduan",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2701,
    province_code: 18,
    name: "เมืองสระแก้ว ศาลาลำดวน สระแก้ว 27000",
    name_th: "เมืองสระแก้ว ศาลาลำดวน สระแก้ว 27000",
    name_en: "Mueang Sa Kaeo, Sala Lamduan, Sa Kaeo, 27000",
  },
  {
    id: 270104,
    districtTh: "เมืองสระแก้ว",
    districtEn: "Mueang Sa Kaeo",
    subdistrictTh: "โคกปี่ฆ้อง",
    subdistrictEn: "Khok Pi Khong",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2701,
    province_code: 18,
    name: "เมืองสระแก้ว โคกปี่ฆ้อง สระแก้ว 27000",
    name_th: "เมืองสระแก้ว โคกปี่ฆ้อง สระแก้ว 27000",
    name_en: "Mueang Sa Kaeo, Khok Pi Khong, Sa Kaeo, 27000",
  },
  {
    id: 270105,
    districtTh: "เมืองสระแก้ว",
    districtEn: "Mueang Sa Kaeo",
    subdistrictTh: "ท่าแยก",
    subdistrictEn: "Tha Yaek",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2701,
    province_code: 18,
    name: "เมืองสระแก้ว ท่าแยก สระแก้ว 27000",
    name_th: "เมืองสระแก้ว ท่าแยก สระแก้ว 27000",
    name_en: "Mueang Sa Kaeo, Tha Yaek, Sa Kaeo, 27000",
  },
  {
    id: 270106,
    districtTh: "เมืองสระแก้ว",
    districtEn: "Mueang Sa Kaeo",
    subdistrictTh: "ท่าเกษม",
    subdistrictEn: "Tha Kasem",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2701,
    province_code: 18,
    name: "เมืองสระแก้ว ท่าเกษม สระแก้ว 27000",
    name_th: "เมืองสระแก้ว ท่าเกษม สระแก้ว 27000",
    name_en: "Mueang Sa Kaeo, Tha Kasem, Sa Kaeo, 27000",
  },
  {
    id: 270108,
    districtTh: "เมืองสระแก้ว",
    districtEn: "Mueang Sa Kaeo",
    subdistrictTh: "สระขวัญ",
    subdistrictEn: "Sa Khwan",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2701,
    province_code: 18,
    name: "เมืองสระแก้ว สระขวัญ สระแก้ว 27000",
    name_th: "เมืองสระแก้ว สระขวัญ สระแก้ว 27000",
    name_en: "Mueang Sa Kaeo, Sa Khwan, Sa Kaeo, 27000",
  },
  {
    id: 270111,
    districtTh: "เมืองสระแก้ว",
    districtEn: "Mueang Sa Kaeo",
    subdistrictTh: "หนองบอน",
    subdistrictEn: "Nong Bon",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2701,
    province_code: 18,
    name: "เมืองสระแก้ว หนองบอน สระแก้ว 27000",
    name_th: "เมืองสระแก้ว หนองบอน สระแก้ว 27000",
    name_en: "Mueang Sa Kaeo, Nong Bon, Sa Kaeo, 27000",
  },
  {
    id: 270201,
    districtTh: "คลองหาด",
    districtEn: "Khlong Hat",
    subdistrictTh: "คลองหาด",
    subdistrictEn: "Khlong Hat",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27260,
    district_code: 2702,
    province_code: 18,
    name: "คลองหาด คลองหาด สระแก้ว 27260",
    name_th: "คลองหาด คลองหาด สระแก้ว 27260",
    name_en: "Khlong Hat, Khlong Hat, Sa Kaeo, 27260",
  },
  {
    id: 270202,
    districtTh: "คลองหาด",
    districtEn: "Khlong Hat",
    subdistrictTh: "ไทยอุดม",
    subdistrictEn: "Thai Udom",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27260,
    district_code: 2702,
    province_code: 18,
    name: "คลองหาด ไทยอุดม สระแก้ว 27260",
    name_th: "คลองหาด ไทยอุดม สระแก้ว 27260",
    name_en: "Khlong Hat, Thai Udom, Sa Kaeo, 27260",
  },
  {
    id: 270203,
    districtTh: "คลองหาด",
    districtEn: "Khlong Hat",
    subdistrictTh: "ซับมะกรูด",
    subdistrictEn: "Sap Makrut",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27260,
    district_code: 2702,
    province_code: 18,
    name: "คลองหาด ซับมะกรูด สระแก้ว 27260",
    name_th: "คลองหาด ซับมะกรูด สระแก้ว 27260",
    name_en: "Khlong Hat, Sap Makrut, Sa Kaeo, 27260",
  },
  {
    id: 270204,
    districtTh: "คลองหาด",
    districtEn: "Khlong Hat",
    subdistrictTh: "ไทรเดี่ยว",
    subdistrictEn: "Sai Diao",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27260,
    district_code: 2702,
    province_code: 18,
    name: "คลองหาด ไทรเดี่ยว สระแก้ว 27260",
    name_th: "คลองหาด ไทรเดี่ยว สระแก้ว 27260",
    name_en: "Khlong Hat, Sai Diao, Sa Kaeo, 27260",
  },
  {
    id: 270205,
    districtTh: "คลองหาด",
    districtEn: "Khlong Hat",
    subdistrictTh: "คลองไก่เถื่อน",
    subdistrictEn: "Khlong Kai Thuean",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27260,
    district_code: 2702,
    province_code: 18,
    name: "คลองหาด คลองไก่เถื่อน สระแก้ว 27260",
    name_th: "คลองหาด คลองไก่เถื่อน สระแก้ว 27260",
    name_en: "Khlong Hat, Khlong Kai Thuean, Sa Kaeo, 27260",
  },
  {
    id: 270206,
    districtTh: "คลองหาด",
    districtEn: "Khlong Hat",
    subdistrictTh: "เบญจขร",
    subdistrictEn: "Benchakhon",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27260,
    district_code: 2702,
    province_code: 18,
    name: "คลองหาด เบญจขร สระแก้ว 27260",
    name_th: "คลองหาด เบญจขร สระแก้ว 27260",
    name_en: "Khlong Hat, Benchakhon, Sa Kaeo, 27260",
  },
  {
    id: 270207,
    districtTh: "คลองหาด",
    districtEn: "Khlong Hat",
    subdistrictTh: "ไทรทอง",
    subdistrictEn: "Sai Thong",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27260,
    district_code: 2702,
    province_code: 18,
    name: "คลองหาด ไทรทอง สระแก้ว 27260",
    name_th: "คลองหาด ไทรทอง สระแก้ว 27260",
    name_en: "Khlong Hat, Sai Thong, Sa Kaeo, 27260",
  },
  {
    id: 270301,
    districtTh: "ตาพระยา",
    districtEn: "Ta Phraya",
    subdistrictTh: "ตาพระยา",
    subdistrictEn: "Ta Phraya",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27180,
    district_code: 2703,
    province_code: 18,
    name: "ตาพระยา ตาพระยา สระแก้ว 27180",
    name_th: "ตาพระยา ตาพระยา สระแก้ว 27180",
    name_en: "Ta Phraya, Ta Phraya, Sa Kaeo, 27180",
  },
  {
    id: 270302,
    districtTh: "ตาพระยา",
    districtEn: "Ta Phraya",
    subdistrictTh: "ทัพเสด็จ",
    subdistrictEn: "Thap Sadet",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27180,
    district_code: 2703,
    province_code: 18,
    name: "ตาพระยา ทัพเสด็จ สระแก้ว 27180",
    name_th: "ตาพระยา ทัพเสด็จ สระแก้ว 27180",
    name_en: "Ta Phraya, Thap Sadet, Sa Kaeo, 27180",
  },
  {
    id: 270306,
    districtTh: "ตาพระยา",
    districtEn: "Ta Phraya",
    subdistrictTh: "ทัพราช",
    subdistrictEn: "Thap Rat",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27180,
    district_code: 2703,
    province_code: 18,
    name: "ตาพระยา ทัพราช สระแก้ว 27180",
    name_th: "ตาพระยา ทัพราช สระแก้ว 27180",
    name_en: "Ta Phraya, Thap Rat, Sa Kaeo, 27180",
  },
  {
    id: 270307,
    districtTh: "ตาพระยา",
    districtEn: "Ta Phraya",
    subdistrictTh: "ทัพไทย",
    subdistrictEn: "Thap Thai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27180,
    district_code: 2703,
    province_code: 18,
    name: "ตาพระยา ทัพไทย สระแก้ว 27180",
    name_th: "ตาพระยา ทัพไทย สระแก้ว 27180",
    name_en: "Ta Phraya, Thap Thai, Sa Kaeo, 27180",
  },
  {
    id: 270309,
    districtTh: "ตาพระยา",
    districtEn: "Ta Phraya",
    subdistrictTh: "โคคลาน",
    subdistrictEn: "Kho Khlan",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27180,
    district_code: 2703,
    province_code: 18,
    name: "ตาพระยา โคคลาน สระแก้ว 27180",
    name_th: "ตาพระยา โคคลาน สระแก้ว 27180",
    name_en: "Ta Phraya, Kho Khlan, Sa Kaeo, 27180",
  },
  {
    id: 270401,
    districtTh: "วังน้ำเย็น",
    districtEn: "Wang Nam Yen",
    subdistrictTh: "วังน้ำเย็น",
    subdistrictEn: "Wang Nam Yen",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27210,
    district_code: 2704,
    province_code: 18,
    name: "วังน้ำเย็น วังน้ำเย็น สระแก้ว 27210",
    name_th: "วังน้ำเย็น วังน้ำเย็น สระแก้ว 27210",
    name_en: "Wang Nam Yen, Wang Nam Yen, Sa Kaeo, 27210",
  },
  {
    id: 270403,
    districtTh: "วังน้ำเย็น",
    districtEn: "Wang Nam Yen",
    subdistrictTh: "ตาหลังใน",
    subdistrictEn: "Ta Lang Nai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27210,
    district_code: 2704,
    province_code: 18,
    name: "วังน้ำเย็น ตาหลังใน สระแก้ว 27210",
    name_th: "วังน้ำเย็น ตาหลังใน สระแก้ว 27210",
    name_en: "Wang Nam Yen, Ta Lang Nai, Sa Kaeo, 27210",
  },
  {
    id: 270405,
    districtTh: "วังน้ำเย็น",
    districtEn: "Wang Nam Yen",
    subdistrictTh: "คลองหินปูน",
    subdistrictEn: "Khlong Hin Pun",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27210,
    district_code: 2704,
    province_code: 18,
    name: "วังน้ำเย็น คลองหินปูน สระแก้ว 27210",
    name_th: "วังน้ำเย็น คลองหินปูน สระแก้ว 27210",
    name_en: "Wang Nam Yen, Khlong Hin Pun, Sa Kaeo, 27210",
  },
  {
    id: 270406,
    districtTh: "วังน้ำเย็น",
    districtEn: "Wang Nam Yen",
    subdistrictTh: "ทุ่งมหาเจริญ",
    subdistrictEn: "Thung Maha Charoen",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27210,
    district_code: 2704,
    province_code: 18,
    name: "วังน้ำเย็น ทุ่งมหาเจริญ สระแก้ว 27210",
    name_th: "วังน้ำเย็น ทุ่งมหาเจริญ สระแก้ว 27210",
    name_en: "Wang Nam Yen, Thung Maha Charoen, Sa Kaeo, 27210",
  },
  {
    id: 270501,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "วัฒนานคร",
    subdistrictEn: "Watthana Nakhon",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร วัฒนานคร สระแก้ว 27160",
    name_th: "วัฒนานคร วัฒนานคร สระแก้ว 27160",
    name_en: "Watthana Nakhon, Watthana Nakhon, Sa Kaeo, 27160",
  },
  {
    id: 270502,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "ท่าเกวียน",
    subdistrictEn: "Tha Kwian",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร ท่าเกวียน สระแก้ว 27160",
    name_th: "วัฒนานคร ท่าเกวียน สระแก้ว 27160",
    name_en: "Watthana Nakhon, Tha Kwian, Sa Kaeo, 27160",
  },
  {
    id: 270503,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "ผักขะ",
    subdistrictEn: "Phak Kha",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร ผักขะ สระแก้ว 27160",
    name_th: "วัฒนานคร ผักขะ สระแก้ว 27160",
    name_en: "Watthana Nakhon, Phak Kha, Sa Kaeo, 27160",
  },
  {
    id: 270504,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "โนนหมากเค็ง",
    subdistrictEn: "Non Mak Kheng",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร โนนหมากเค็ง สระแก้ว 27160",
    name_th: "วัฒนานคร โนนหมากเค็ง สระแก้ว 27160",
    name_en: "Watthana Nakhon, Non Mak Kheng, Sa Kaeo, 27160",
  },
  {
    id: 270505,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "หนองน้ำใส",
    subdistrictEn: "Nong Nam Sai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร หนองน้ำใส สระแก้ว 27160",
    name_th: "วัฒนานคร หนองน้ำใส สระแก้ว 27160",
    name_en: "Watthana Nakhon, Nong Nam Sai, Sa Kaeo, 27160",
  },
  {
    id: 270506,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "ช่องกุ่ม",
    subdistrictEn: "Chong Kum",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร ช่องกุ่ม สระแก้ว 27160",
    name_th: "วัฒนานคร ช่องกุ่ม สระแก้ว 27160",
    name_en: "Watthana Nakhon, Chong Kum, Sa Kaeo, 27160",
  },
  {
    id: 270507,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร หนองแวง สระแก้ว 27160",
    name_th: "วัฒนานคร หนองแวง สระแก้ว 27160",
    name_en: "Watthana Nakhon, Nong Waeng, Sa Kaeo, 27160",
  },
  {
    id: 270508,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "แซร์ออ",
    subdistrictEn: "Sae-o",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร แซร์ออ สระแก้ว 27160",
    name_th: "วัฒนานคร แซร์ออ สระแก้ว 27160",
    name_en: "Watthana Nakhon, Sae-o, Sa Kaeo, 27160",
  },
  {
    id: 270509,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "หนองหมากฝ้าย",
    subdistrictEn: "Nong Mak Fai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร หนองหมากฝ้าย สระแก้ว 27160",
    name_th: "วัฒนานคร หนองหมากฝ้าย สระแก้ว 27160",
    name_en: "Watthana Nakhon, Nong Mak Fai, Sa Kaeo, 27160",
  },
  {
    id: 270510,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "หนองตะเคียนบอน",
    subdistrictEn: "Nong Takhian Bon",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร หนองตะเคียนบอน สระแก้ว 27160",
    name_th: "วัฒนานคร หนองตะเคียนบอน สระแก้ว 27160",
    name_en: "Watthana Nakhon, Nong Takhian Bon, Sa Kaeo, 27160",
  },
  {
    id: 270511,
    districtTh: "วัฒนานคร",
    districtEn: "Watthana Nakhon",
    subdistrictTh: "ห้วยโจด",
    subdistrictEn: "Huai Chot",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27160,
    district_code: 2705,
    province_code: 18,
    name: "วัฒนานคร ห้วยโจด สระแก้ว 27160",
    name_th: "วัฒนานคร ห้วยโจด สระแก้ว 27160",
    name_en: "Watthana Nakhon, Huai Chot, Sa Kaeo, 27160",
  },
  {
    id: 270601,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "อรัญประเทศ",
    subdistrictEn: "Aranprathet",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ อรัญประเทศ สระแก้ว 27120",
    name_th: "อรัญประเทศ อรัญประเทศ สระแก้ว 27120",
    name_en: "Aranyaprathet, Aranprathet, Sa Kaeo, 27120",
  },
  {
    id: 270602,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "เมืองไผ่",
    subdistrictEn: "Mueang Phai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ เมืองไผ่ สระแก้ว 27120",
    name_th: "อรัญประเทศ เมืองไผ่ สระแก้ว 27120",
    name_en: "Aranyaprathet, Mueang Phai, Sa Kaeo, 27120",
  },
  {
    id: 270603,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "หันทราย",
    subdistrictEn: "Han Sai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ หันทราย สระแก้ว 27120",
    name_th: "อรัญประเทศ หันทราย สระแก้ว 27120",
    name_en: "Aranyaprathet, Han Sai, Sa Kaeo, 27120",
  },
  {
    id: 270604,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "คลองน้ำใส",
    subdistrictEn: "Khlong Nam Sai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ คลองน้ำใส สระแก้ว 27120",
    name_th: "อรัญประเทศ คลองน้ำใส สระแก้ว 27120",
    name_en: "Aranyaprathet, Khlong Nam Sai, Sa Kaeo, 27120",
  },
  {
    id: 270605,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ ท่าข้าม สระแก้ว 27120",
    name_th: "อรัญประเทศ ท่าข้าม สระแก้ว 27120",
    name_en: "Aranyaprathet, Tha Kham, Sa Kaeo, 27120",
  },
  {
    id: 270606,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "ป่าไร่",
    subdistrictEn: "Pa Rai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ ป่าไร่ สระแก้ว 27120",
    name_th: "อรัญประเทศ ป่าไร่ สระแก้ว 27120",
    name_en: "Aranyaprathet, Pa Rai, Sa Kaeo, 27120",
  },
  {
    id: 270607,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "ทับพริก",
    subdistrictEn: "Thap Phrik",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ ทับพริก สระแก้ว 27120",
    name_th: "อรัญประเทศ ทับพริก สระแก้ว 27120",
    name_en: "Aranyaprathet, Thap Phrik, Sa Kaeo, 27120",
  },
  {
    id: 270608,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "บ้านใหม่หนองไทร",
    subdistrictEn: "Ban Mai Nong Sai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ บ้านใหม่หนองไทร สระแก้ว 27120",
    name_th: "อรัญประเทศ บ้านใหม่หนองไทร สระแก้ว 27120",
    name_en: "Aranyaprathet, Ban Mai Nong Sai, Sa Kaeo, 27120",
  },
  {
    id: 270609,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "ผ่านศึก",
    subdistrictEn: "Phan Suek",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ ผ่านศึก สระแก้ว 27120",
    name_th: "อรัญประเทศ ผ่านศึก สระแก้ว 27120",
    name_en: "Aranyaprathet, Phan Suek, Sa Kaeo, 27120",
  },
  {
    id: 270610,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "หนองสังข์",
    subdistrictEn: "Nong Sang",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ หนองสังข์ สระแก้ว 27120",
    name_th: "อรัญประเทศ หนองสังข์ สระแก้ว 27120",
    name_en: "Aranyaprathet, Nong Sang, Sa Kaeo, 27120",
  },
  {
    id: 270611,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "คลองทับจันทร์",
    subdistrictEn: "Khlong Thap Chan",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ คลองทับจันทร์ สระแก้ว 27120",
    name_th: "อรัญประเทศ คลองทับจันทร์ สระแก้ว 27120",
    name_en: "Aranyaprathet, Khlong Thap Chan, Sa Kaeo, 27120",
  },
  {
    id: 270612,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "ฟากห้วย",
    subdistrictEn: "Fak Huai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ ฟากห้วย สระแก้ว 27120",
    name_th: "อรัญประเทศ ฟากห้วย สระแก้ว 27120",
    name_en: "Aranyaprathet, Fak Huai, Sa Kaeo, 27120",
  },
  {
    id: 270613,
    districtTh: "อรัญประเทศ",
    districtEn: "Aranyaprathet",
    subdistrictTh: "บ้านด่าน",
    subdistrictEn: "Ban Dan",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2706,
    province_code: 18,
    name: "อรัญประเทศ บ้านด่าน สระแก้ว 27120",
    name_th: "อรัญประเทศ บ้านด่าน สระแก้ว 27120",
    name_en: "Aranyaprathet, Ban Dan, Sa Kaeo, 27120",
  },
  {
    id: 270701,
    districtTh: "เขาฉกรรจ์",
    districtEn: "Khao Chakan",
    subdistrictTh: "เขาฉกรรจ์",
    subdistrictEn: "Khao Chakan",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2707,
    province_code: 18,
    name: "เขาฉกรรจ์ เขาฉกรรจ์ สระแก้ว 27000",
    name_th: "เขาฉกรรจ์ เขาฉกรรจ์ สระแก้ว 27000",
    name_en: "Khao Chakan, Khao Chakan, Sa Kaeo, 27000",
  },
  {
    id: 270702,
    districtTh: "เขาฉกรรจ์",
    districtEn: "Khao Chakan",
    subdistrictTh: "หนองหว้า",
    subdistrictEn: "Nong Wa",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2707,
    province_code: 18,
    name: "เขาฉกรรจ์ หนองหว้า สระแก้ว 27000",
    name_th: "เขาฉกรรจ์ หนองหว้า สระแก้ว 27000",
    name_en: "Khao Chakan, Nong Wa, Sa Kaeo, 27000",
  },
  {
    id: 270703,
    districtTh: "เขาฉกรรจ์",
    districtEn: "Khao Chakan",
    subdistrictTh: "พระเพลิง",
    subdistrictEn: "Phra Phloeng",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2707,
    province_code: 18,
    name: "เขาฉกรรจ์ พระเพลิง สระแก้ว 27000",
    name_th: "เขาฉกรรจ์ พระเพลิง สระแก้ว 27000",
    name_en: "Khao Chakan, Phra Phloeng, Sa Kaeo, 27000",
  },
  {
    id: 270704,
    districtTh: "เขาฉกรรจ์",
    districtEn: "Khao Chakan",
    subdistrictTh: "เขาสามสิบ",
    subdistrictEn: "Khao Sam Sip",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27000,
    district_code: 2707,
    province_code: 18,
    name: "เขาฉกรรจ์ เขาสามสิบ สระแก้ว 27000",
    name_th: "เขาฉกรรจ์ เขาสามสิบ สระแก้ว 27000",
    name_en: "Khao Chakan, Khao Sam Sip, Sa Kaeo, 27000",
  },
  {
    id: 270801,
    districtTh: "โคกสูง",
    districtEn: "Khok Sung",
    subdistrictTh: "โคกสูง",
    subdistrictEn: "Khok Sung",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2708,
    province_code: 18,
    name: "โคกสูง โคกสูง สระแก้ว 27120",
    name_th: "โคกสูง โคกสูง สระแก้ว 27120",
    name_en: "Khok Sung, Khok Sung, Sa Kaeo, 27120",
  },
  {
    id: 270802,
    districtTh: "โคกสูง",
    districtEn: "Khok Sung",
    subdistrictTh: "หนองม่วง",
    subdistrictEn: "Nong Muang",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27180,
    district_code: 2708,
    province_code: 18,
    name: "โคกสูง หนองม่วง สระแก้ว 27180",
    name_th: "โคกสูง หนองม่วง สระแก้ว 27180",
    name_en: "Khok Sung, Nong Muang, Sa Kaeo, 27180",
  },
  {
    id: 270803,
    districtTh: "โคกสูง",
    districtEn: "Khok Sung",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27180,
    district_code: 2708,
    province_code: 18,
    name: "โคกสูง หนองแวง สระแก้ว 27180",
    name_th: "โคกสูง หนองแวง สระแก้ว 27180",
    name_en: "Khok Sung, Nong Waeng, Sa Kaeo, 27180",
  },
  {
    id: 270804,
    districtTh: "โคกสูง",
    districtEn: "Khok Sung",
    subdistrictTh: "โนนหมากมุ่น",
    subdistrictEn: "Non Mak Mun",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27120,
    district_code: 2708,
    province_code: 18,
    name: "โคกสูง โนนหมากมุ่น สระแก้ว 27120",
    name_th: "โคกสูง โนนหมากมุ่น สระแก้ว 27120",
    name_en: "Khok Sung, Non Mak Mun, Sa Kaeo, 27120",
  },
  {
    id: 270901,
    districtTh: "วังสมบูรณ์",
    districtEn: "Wang Sombun",
    subdistrictTh: "วังสมบูรณ์",
    subdistrictEn: "Wang Sombun",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27250,
    district_code: 2709,
    province_code: 18,
    name: "วังสมบูรณ์ วังสมบูรณ์ สระแก้ว 27250",
    name_th: "วังสมบูรณ์ วังสมบูรณ์ สระแก้ว 27250",
    name_en: "Wang Sombun, Wang Sombun, Sa Kaeo, 27250",
  },
  {
    id: 270902,
    districtTh: "วังสมบูรณ์",
    districtEn: "Wang Sombun",
    subdistrictTh: "วังใหม่",
    subdistrictEn: "Wang Mai",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27250,
    district_code: 2709,
    province_code: 18,
    name: "วังสมบูรณ์ วังใหม่ สระแก้ว 27250",
    name_th: "วังสมบูรณ์ วังใหม่ สระแก้ว 27250",
    name_en: "Wang Sombun, Wang Mai, Sa Kaeo, 27250",
  },
  {
    id: 270903,
    districtTh: "วังสมบูรณ์",
    districtEn: "Wang Sombun",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "สระแก้ว",
    provinceEn: "Sa Kaeo",
    zipcode: 27250,
    district_code: 2709,
    province_code: 18,
    name: "วังสมบูรณ์ วังทอง สระแก้ว 27250",
    name_th: "วังสมบูรณ์ วังทอง สระแก้ว 27250",
    name_en: "Wang Sombun, Wang Thong, Sa Kaeo, 27250",
  },
  {
    id: 300101,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา ในเมือง นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา ในเมือง นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Nai Mueang, Nakhon Ratchasima, 30000",
  },
  {
    id: 300102,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "โพธิ์กลาง",
    subdistrictEn: "Pho Klang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา โพธิ์กลาง นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา โพธิ์กลาง นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Pho Klang, Nakhon Ratchasima, 30000",
  },
  {
    id: 300103,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "หนองจะบก",
    subdistrictEn: "Nong Chabok",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา หนองจะบก นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา หนองจะบก นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Nong Chabok, Nakhon Ratchasima, 30000",
  },
  {
    id: 300104,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "โคกสูง",
    subdistrictEn: "Khok Sung",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30310,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา โคกสูง นครราชสีมา 30310",
    name_th: "เมืองนครราชสีมา โคกสูง นครราชสีมา 30310",
    name_en: "Mueang Nakhon Ratchasima, Khok Sung, Nakhon Ratchasima, 30310",
  },
  {
    id: 300105,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "มะเริง",
    subdistrictEn: "Maroeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา มะเริง นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา มะเริง นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Maroeng, Nakhon Ratchasima, 30000",
  },
  {
    id: 300106,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "หนองระเวียง",
    subdistrictEn: "Nong Rawiang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา หนองระเวียง นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา หนองระเวียง นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Nong Rawiang, Nakhon Ratchasima, 30000",
  },
  {
    id: 300107,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "ปรุใหญ่",
    subdistrictEn: "Pru Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา ปรุใหญ่ นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา ปรุใหญ่ นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Pru Yai, Nakhon Ratchasima, 30000",
  },
  {
    id: 300108,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "หมื่นไวย",
    subdistrictEn: "Muen Wai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา หมื่นไวย นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา หมื่นไวย นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Muen Wai, Nakhon Ratchasima, 30000",
  },
  {
    id: 300109,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "พลกรัง",
    subdistrictEn: "Phon Krang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา พลกรัง นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา พลกรัง นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Phon Krang, Nakhon Ratchasima, 30000",
  },
  {
    id: 300110,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "หนองไผ่ล้อม",
    subdistrictEn: "Nong Phai Lom",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา หนองไผ่ล้อม นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา หนองไผ่ล้อม นครราชสีมา 30000",
    name_en:
      "Mueang Nakhon Ratchasima, Nong Phai Lom, Nakhon Ratchasima, 30000",
  },
  {
    id: 300111,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "หัวทะเล",
    subdistrictEn: "Hua Thale",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา หัวทะเล นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา หัวทะเล นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Hua Thale, Nakhon Ratchasima, 30000",
  },
  {
    id: 300112,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "บ้านเกาะ",
    subdistrictEn: "Ban Ko",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา บ้านเกาะ นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา บ้านเกาะ นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Ban Ko, Nakhon Ratchasima, 30000",
  },
  {
    id: 300113,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา บ้านใหม่ นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา บ้านใหม่ นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Ban Mai, Nakhon Ratchasima, 30000",
  },
  {
    id: 300114,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "พุดซา",
    subdistrictEn: "Phutsa",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา พุดซา นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา พุดซา นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Phutsa, Nakhon Ratchasima, 30000",
  },
  {
    id: 300115,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "บ้านโพธิ์",
    subdistrictEn: "Ban Pho",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30310,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา บ้านโพธิ์ นครราชสีมา 30310",
    name_th: "เมืองนครราชสีมา บ้านโพธิ์ นครราชสีมา 30310",
    name_en: "Mueang Nakhon Ratchasima, Ban Pho, Nakhon Ratchasima, 30310",
  },
  {
    id: 300116,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "จอหอ",
    subdistrictEn: "Cho Ho",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30310,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา จอหอ นครราชสีมา 30310",
    name_th: "เมืองนครราชสีมา จอหอ นครราชสีมา 30310",
    name_en: "Mueang Nakhon Ratchasima, Cho Ho, Nakhon Ratchasima, 30310",
  },
  {
    id: 300117,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "โคกกรวด",
    subdistrictEn: "Khok Kruat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30280,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา โคกกรวด นครราชสีมา 30280",
    name_th: "เมืองนครราชสีมา โคกกรวด นครราชสีมา 30280",
    name_en: "Mueang Nakhon Ratchasima, Khok Kruat, Nakhon Ratchasima, 30280",
  },
  {
    id: 300118,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "ไชยมงคล",
    subdistrictEn: "Chai Mongkhon",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา ไชยมงคล นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา ไชยมงคล นครราชสีมา 30000",
    name_en:
      "Mueang Nakhon Ratchasima, Chai Mongkhon, Nakhon Ratchasima, 30000",
  },
  {
    id: 300119,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "หนองบัวศาลา",
    subdistrictEn: "Nong Bua Sala",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา หนองบัวศาลา นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา หนองบัวศาลา นครราชสีมา 30000",
    name_en:
      "Mueang Nakhon Ratchasima, Nong Bua Sala, Nakhon Ratchasima, 30000",
  },
  {
    id: 300120,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "สุรนารี",
    subdistrictEn: "Suranari",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา สุรนารี นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา สุรนารี นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Suranari, Nakhon Ratchasima, 30000",
  },
  {
    id: 300121,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "สีมุม",
    subdistrictEn: "Si Mum",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา สีมุม นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา สีมุม นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Si Mum, Nakhon Ratchasima, 30000",
  },
  {
    id: 300122,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "ตลาด",
    subdistrictEn: "Talat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30310,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา ตลาด นครราชสีมา 30310",
    name_th: "เมืองนครราชสีมา ตลาด นครราชสีมา 30310",
    name_en: "Mueang Nakhon Ratchasima, Talat, Nakhon Ratchasima, 30310",
  },
  {
    id: 300123,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "พะเนา",
    subdistrictEn: "Phanao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา พะเนา นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา พะเนา นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Phanao, Nakhon Ratchasima, 30000",
  },
  {
    id: 300124,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "หนองกระทุ่ม",
    subdistrictEn: "Nong Krathum",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา หนองกระทุ่ม นครราชสีมา 30000",
    name_th: "เมืองนครราชสีมา หนองกระทุ่ม นครราชสีมา 30000",
    name_en: "Mueang Nakhon Ratchasima, Nong Krathum, Nakhon Ratchasima, 30000",
  },
  {
    id: 300125,
    districtTh: "เมืองนครราชสีมา",
    districtEn: "Mueang Nakhon Ratchasima",
    subdistrictTh: "หนองไข่น้ำ",
    subdistrictEn: "Nong Khai Nam",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30310,
    district_code: 3001,
    province_code: 19,
    name: "เมืองนครราชสีมา หนองไข่น้ำ นครราชสีมา 30310",
    name_th: "เมืองนครราชสีมา หนองไข่น้ำ นครราชสีมา 30310",
    name_en:
      "Mueang Nakhon Ratchasima, Nong Khai Nam, Nakhon Ratchasima, 30310",
  },
  {
    id: 300201,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "แชะ",
    subdistrictEn: "Chae",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี แชะ นครราชสีมา 30250",
    name_th: "ครบุรี แชะ นครราชสีมา 30250",
    name_en: "Khon Buri, Chae, Nakhon Ratchasima, 30250",
  },
  {
    id: 300202,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "เฉลียง",
    subdistrictEn: "Chaliang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี เฉลียง นครราชสีมา 30250",
    name_th: "ครบุรี เฉลียง นครราชสีมา 30250",
    name_en: "Khon Buri, Chaliang, Nakhon Ratchasima, 30250",
  },
  {
    id: 300203,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "ครบุรี",
    subdistrictEn: "Khon Buri",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี ครบุรี นครราชสีมา 30250",
    name_th: "ครบุรี ครบุรี นครราชสีมา 30250",
    name_en: "Khon Buri, Khon Buri, Nakhon Ratchasima, 30250",
  },
  {
    id: 300204,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "โคกกระชาย",
    subdistrictEn: "Khok Krachai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี โคกกระชาย นครราชสีมา 30250",
    name_th: "ครบุรี โคกกระชาย นครราชสีมา 30250",
    name_en: "Khon Buri, Khok Krachai, Nakhon Ratchasima, 30250",
  },
  {
    id: 300205,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "จระเข้หิน",
    subdistrictEn: "Chorakhe Hin",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี จระเข้หิน นครราชสีมา 30250",
    name_th: "ครบุรี จระเข้หิน นครราชสีมา 30250",
    name_en: "Khon Buri, Chorakhe Hin, Nakhon Ratchasima, 30250",
  },
  {
    id: 300206,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "มาบตะโกเอน",
    subdistrictEn: "Map Tako En",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี มาบตะโกเอน นครราชสีมา 30250",
    name_th: "ครบุรี มาบตะโกเอน นครราชสีมา 30250",
    name_en: "Khon Buri, Map Tako En, Nakhon Ratchasima, 30250",
  },
  {
    id: 300207,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "อรพิมพ์",
    subdistrictEn: "Oraphim",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี อรพิมพ์ นครราชสีมา 30250",
    name_th: "ครบุรี อรพิมพ์ นครราชสีมา 30250",
    name_en: "Khon Buri, Oraphim, Nakhon Ratchasima, 30250",
  },
  {
    id: 300208,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี บ้านใหม่ นครราชสีมา 30250",
    name_th: "ครบุรี บ้านใหม่ นครราชสีมา 30250",
    name_en: "Khon Buri, Ban Mai, Nakhon Ratchasima, 30250",
  },
  {
    id: 300209,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "ลำเพียก",
    subdistrictEn: "Lam Phiak",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี ลำเพียก นครราชสีมา 30250",
    name_th: "ครบุรี ลำเพียก นครราชสีมา 30250",
    name_en: "Khon Buri, Lam Phiak, Nakhon Ratchasima, 30250",
  },
  {
    id: 300210,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "ครบุรีใต้",
    subdistrictEn: "Khon Buri Tai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี ครบุรีใต้ นครราชสีมา 30250",
    name_th: "ครบุรี ครบุรีใต้ นครราชสีมา 30250",
    name_en: "Khon Buri, Khon Buri Tai, Nakhon Ratchasima, 30250",
  },
  {
    id: 300211,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "ตะแบกบาน",
    subdistrictEn: "Tabaek Ban",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี ตะแบกบาน นครราชสีมา 30250",
    name_th: "ครบุรี ตะแบกบาน นครราชสีมา 30250",
    name_en: "Khon Buri, Tabaek Ban, Nakhon Ratchasima, 30250",
  },
  {
    id: 300212,
    districtTh: "ครบุรี",
    districtEn: "Khon Buri",
    subdistrictTh: "สระว่านพระยา",
    subdistrictEn: "Sa Wan Phraya",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30250,
    district_code: 3002,
    province_code: 19,
    name: "ครบุรี สระว่านพระยา นครราชสีมา 30250",
    name_th: "ครบุรี สระว่านพระยา นครราชสีมา 30250",
    name_en: "Khon Buri, Sa Wan Phraya, Nakhon Ratchasima, 30250",
  },
  {
    id: 300301,
    districtTh: "เสิงสาง",
    districtEn: "Soeng Sang",
    subdistrictTh: "เสิงสาง",
    subdistrictEn: "Soeng Sang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30330,
    district_code: 3003,
    province_code: 19,
    name: "เสิงสาง เสิงสาง นครราชสีมา 30330",
    name_th: "เสิงสาง เสิงสาง นครราชสีมา 30330",
    name_en: "Soeng Sang, Soeng Sang, Nakhon Ratchasima, 30330",
  },
  {
    id: 300302,
    districtTh: "เสิงสาง",
    districtEn: "Soeng Sang",
    subdistrictTh: "สระตะเคียน",
    subdistrictEn: "Sa Takhian",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30330,
    district_code: 3003,
    province_code: 19,
    name: "เสิงสาง สระตะเคียน นครราชสีมา 30330",
    name_th: "เสิงสาง สระตะเคียน นครราชสีมา 30330",
    name_en: "Soeng Sang, Sa Takhian, Nakhon Ratchasima, 30330",
  },
  {
    id: 300303,
    districtTh: "เสิงสาง",
    districtEn: "Soeng Sang",
    subdistrictTh: "โนนสมบูรณ์",
    subdistrictEn: "Non Sombun",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30330,
    district_code: 3003,
    province_code: 19,
    name: "เสิงสาง โนนสมบูรณ์ นครราชสีมา 30330",
    name_th: "เสิงสาง โนนสมบูรณ์ นครราชสีมา 30330",
    name_en: "Soeng Sang, Non Sombun, Nakhon Ratchasima, 30330",
  },
  {
    id: 300304,
    districtTh: "เสิงสาง",
    districtEn: "Soeng Sang",
    subdistrictTh: "กุดโบสถ์",
    subdistrictEn: "Kut Bot",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30330,
    district_code: 3003,
    province_code: 19,
    name: "เสิงสาง กุดโบสถ์ นครราชสีมา 30330",
    name_th: "เสิงสาง กุดโบสถ์ นครราชสีมา 30330",
    name_en: "Soeng Sang, Kut Bot, Nakhon Ratchasima, 30330",
  },
  {
    id: 300305,
    districtTh: "เสิงสาง",
    districtEn: "Soeng Sang",
    subdistrictTh: "สุขไพบูลย์",
    subdistrictEn: "Suk Phaibun",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30330,
    district_code: 3003,
    province_code: 19,
    name: "เสิงสาง สุขไพบูลย์ นครราชสีมา 30330",
    name_th: "เสิงสาง สุขไพบูลย์ นครราชสีมา 30330",
    name_en: "Soeng Sang, Suk Phaibun, Nakhon Ratchasima, 30330",
  },
  {
    id: 300306,
    districtTh: "เสิงสาง",
    districtEn: "Soeng Sang",
    subdistrictTh: "บ้านราษฎร์",
    subdistrictEn: "Ban Rat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30330,
    district_code: 3003,
    province_code: 19,
    name: "เสิงสาง บ้านราษฎร์ นครราชสีมา 30330",
    name_th: "เสิงสาง บ้านราษฎร์ นครราชสีมา 30330",
    name_en: "Soeng Sang, Ban Rat, Nakhon Ratchasima, 30330",
  },
  {
    id: 300401,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "เมืองคง",
    subdistrictEn: "Mueang Khong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง เมืองคง นครราชสีมา 30260",
    name_th: "คง เมืองคง นครราชสีมา 30260",
    name_en: "Khong, Mueang Khong, Nakhon Ratchasima, 30260",
  },
  {
    id: 300402,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "คูขาด",
    subdistrictEn: "Khu Khat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง คูขาด นครราชสีมา 30260",
    name_th: "คง คูขาด นครราชสีมา 30260",
    name_en: "Khong, Khu Khat, Nakhon Ratchasima, 30260",
  },
  {
    id: 300403,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "เทพาลัย",
    subdistrictEn: "Thephalai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง เทพาลัย นครราชสีมา 30260",
    name_th: "คง เทพาลัย นครราชสีมา 30260",
    name_en: "Khong, Thephalai, Nakhon Ratchasima, 30260",
  },
  {
    id: 300404,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "ตาจั่น",
    subdistrictEn: "Ta Chan",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง ตาจั่น นครราชสีมา 30260",
    name_th: "คง ตาจั่น นครราชสีมา 30260",
    name_en: "Khong, Ta Chan, Nakhon Ratchasima, 30260",
  },
  {
    id: 300405,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "บ้านปรางค์",
    subdistrictEn: "Ban Prang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง บ้านปรางค์ นครราชสีมา 30260",
    name_th: "คง บ้านปรางค์ นครราชสีมา 30260",
    name_en: "Khong, Ban Prang, Nakhon Ratchasima, 30260",
  },
  {
    id: 300406,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "หนองมะนาว",
    subdistrictEn: "Nong Manao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง หนองมะนาว นครราชสีมา 30260",
    name_th: "คง หนองมะนาว นครราชสีมา 30260",
    name_en: "Khong, Nong Manao, Nakhon Ratchasima, 30260",
  },
  {
    id: 300407,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง หนองบัว นครราชสีมา 30260",
    name_th: "คง หนองบัว นครราชสีมา 30260",
    name_en: "Khong, Nong Bua, Nakhon Ratchasima, 30260",
  },
  {
    id: 300408,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "โนนเต็ง",
    subdistrictEn: "Non Teng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง โนนเต็ง นครราชสีมา 30260",
    name_th: "คง โนนเต็ง นครราชสีมา 30260",
    name_en: "Khong, Non Teng, Nakhon Ratchasima, 30260",
  },
  {
    id: 300409,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "ดอนใหญ่",
    subdistrictEn: "Don Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง ดอนใหญ่ นครราชสีมา 30260",
    name_th: "คง ดอนใหญ่ นครราชสีมา 30260",
    name_en: "Khong, Don Yai, Nakhon Ratchasima, 30260",
  },
  {
    id: 300410,
    districtTh: "คง",
    districtEn: "Khong",
    subdistrictTh: "ขามสมบูรณ์",
    subdistrictEn: "Kham Sombun",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30260,
    district_code: 3004,
    province_code: 19,
    name: "คง ขามสมบูรณ์ นครราชสีมา 30260",
    name_th: "คง ขามสมบูรณ์ นครราชสีมา 30260",
    name_en: "Khong, Kham Sombun, Nakhon Ratchasima, 30260",
  },
  {
    id: 300501,
    districtTh: "บ้านเหลื่อม",
    districtEn: "Ban Lueam",
    subdistrictTh: "บ้านเหลื่อม",
    subdistrictEn: "Ban Lueam",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30350,
    district_code: 3005,
    province_code: 19,
    name: "บ้านเหลื่อม บ้านเหลื่อม นครราชสีมา 30350",
    name_th: "บ้านเหลื่อม บ้านเหลื่อม นครราชสีมา 30350",
    name_en: "Ban Lueam, Ban Lueam, Nakhon Ratchasima, 30350",
  },
  {
    id: 300502,
    districtTh: "บ้านเหลื่อม",
    districtEn: "Ban Lueam",
    subdistrictTh: "วังโพธิ์",
    subdistrictEn: "Wang Pho",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30350,
    district_code: 3005,
    province_code: 19,
    name: "บ้านเหลื่อม วังโพธิ์ นครราชสีมา 30350",
    name_th: "บ้านเหลื่อม วังโพธิ์ นครราชสีมา 30350",
    name_en: "Ban Lueam, Wang Pho, Nakhon Ratchasima, 30350",
  },
  {
    id: 300503,
    districtTh: "บ้านเหลื่อม",
    districtEn: "Ban Lueam",
    subdistrictTh: "โคกกระเบื้อง",
    subdistrictEn: "Khok Krabueang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30350,
    district_code: 3005,
    province_code: 19,
    name: "บ้านเหลื่อม โคกกระเบื้อง นครราชสีมา 30350",
    name_th: "บ้านเหลื่อม โคกกระเบื้อง นครราชสีมา 30350",
    name_en: "Ban Lueam, Khok Krabueang, Nakhon Ratchasima, 30350",
  },
  {
    id: 300504,
    districtTh: "บ้านเหลื่อม",
    districtEn: "Ban Lueam",
    subdistrictTh: "ช่อระกา",
    subdistrictEn: "Cho Raka",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30350,
    district_code: 3005,
    province_code: 19,
    name: "บ้านเหลื่อม ช่อระกา นครราชสีมา 30350",
    name_th: "บ้านเหลื่อม ช่อระกา นครราชสีมา 30350",
    name_en: "Ban Lueam, Cho Raka, Nakhon Ratchasima, 30350",
  },
  {
    id: 300601,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "จักราช",
    subdistrictEn: "Chakkarat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช จักราช นครราชสีมา 30230",
    name_th: "จักราช จักราช นครราชสีมา 30230",
    name_en: "Chakkarat, Chakkarat, Nakhon Ratchasima, 30230",
  },
  {
    id: 300602,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช ท่าช้าง นครราชสีมา 30230",
    name_th: "จักราช ท่าช้าง นครราชสีมา 30230",
    name_en: "Chakkarat, Tha Chang, Nakhon Ratchasima, 30230",
  },
  {
    id: 300603,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "ทองหลาง",
    subdistrictEn: "Thonglang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช ทองหลาง นครราชสีมา 30230",
    name_th: "จักราช ทองหลาง นครราชสีมา 30230",
    name_en: "Chakkarat, Thonglang, Nakhon Ratchasima, 30230",
  },
  {
    id: 300604,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "สีสุก",
    subdistrictEn: "Si Suk",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช สีสุก นครราชสีมา 30230",
    name_th: "จักราช สีสุก นครราชสีมา 30230",
    name_en: "Chakkarat, Si Suk, Nakhon Ratchasima, 30230",
  },
  {
    id: 300605,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "หนองขาม",
    subdistrictEn: "Nong Kham",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช หนองขาม นครราชสีมา 30230",
    name_th: "จักราช หนองขาม นครราชสีมา 30230",
    name_en: "Chakkarat, Nong Kham, Nakhon Ratchasima, 30230",
  },
  {
    id: 300606,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "หนองงูเหลือม",
    subdistrictEn: "Nong Ngu Luam",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช หนองงูเหลือม นครราชสีมา 30230",
    name_th: "จักราช หนองงูเหลือม นครราชสีมา 30230",
    name_en: "Chakkarat, Nong Ngu Luam, Nakhon Ratchasima, 30230",
  },
  {
    id: 300607,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "หนองพลวง",
    subdistrictEn: "Nong Phluang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช หนองพลวง นครราชสีมา 30230",
    name_th: "จักราช หนองพลวง นครราชสีมา 30230",
    name_en: "Chakkarat, Nong Phluang, Nakhon Ratchasima, 30230",
  },
  {
    id: 300608,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "หนองยาง",
    subdistrictEn: "Nong Yang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช หนองยาง นครราชสีมา 30230",
    name_th: "จักราช หนองยาง นครราชสีมา 30230",
    name_en: "Chakkarat, Nong Yang, Nakhon Ratchasima, 30230",
  },
  {
    id: 300609,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "พระพุทธ",
    subdistrictEn: "Phra Phut",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช พระพุทธ นครราชสีมา 30230",
    name_th: "จักราช พระพุทธ นครราชสีมา 30230",
    name_en: "Chakkarat, Phra Phut, Nakhon Ratchasima, 30230",
  },
  {
    id: 300610,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "ศรีละกอ",
    subdistrictEn: "Si Lako",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช ศรีละกอ นครราชสีมา 30230",
    name_th: "จักราช ศรีละกอ นครราชสีมา 30230",
    name_en: "Chakkarat, Si Lako, Nakhon Ratchasima, 30230",
  },
  {
    id: 300611,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "คลองเมือง",
    subdistrictEn: "Khlong Mueang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช คลองเมือง นครราชสีมา 30230",
    name_th: "จักราช คลองเมือง นครราชสีมา 30230",
    name_en: "Chakkarat, Khlong Mueang, Nakhon Ratchasima, 30230",
  },
  {
    id: 300612,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "ช้างทอง",
    subdistrictEn: "Chang Thong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช ช้างทอง นครราชสีมา 30230",
    name_th: "จักราช ช้างทอง นครราชสีมา 30230",
    name_en: "Chakkarat, Chang Thong, Nakhon Ratchasima, 30230",
  },
  {
    id: 300613,
    districtTh: "จักราช",
    districtEn: "Chakkarat",
    subdistrictTh: "หินโคน",
    subdistrictEn: "Hin Khon",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3006,
    province_code: 19,
    name: "จักราช หินโคน นครราชสีมา 30230",
    name_th: "จักราช หินโคน นครราชสีมา 30230",
    name_en: "Chakkarat, Hin Khon, Nakhon Ratchasima, 30230",
  },
  {
    id: 300701,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "กระโทก",
    subdistrictEn: "Krathok",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย กระโทก นครราชสีมา 30190",
    name_th: "โชคชัย กระโทก นครราชสีมา 30190",
    name_en: "Chok Chai, Krathok, Nakhon Ratchasima, 30190",
  },
  {
    id: 300702,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "พลับพลา",
    subdistrictEn: "Phlapphla",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย พลับพลา นครราชสีมา 30190",
    name_th: "โชคชัย พลับพลา นครราชสีมา 30190",
    name_en: "Chok Chai, Phlapphla, Nakhon Ratchasima, 30190",
  },
  {
    id: 300703,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "ท่าอ่าง",
    subdistrictEn: "Tha Ang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย ท่าอ่าง นครราชสีมา 30190",
    name_th: "โชคชัย ท่าอ่าง นครราชสีมา 30190",
    name_en: "Chok Chai, Tha Ang, Nakhon Ratchasima, 30190",
  },
  {
    id: 300704,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "ทุ่งอรุณ",
    subdistrictEn: "Thung Arun",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย ทุ่งอรุณ นครราชสีมา 30190",
    name_th: "โชคชัย ทุ่งอรุณ นครราชสีมา 30190",
    name_en: "Chok Chai, Thung Arun, Nakhon Ratchasima, 30190",
  },
  {
    id: 300705,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "ท่าลาดขาว",
    subdistrictEn: "Tha Lat Khao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย ท่าลาดขาว นครราชสีมา 30190",
    name_th: "โชคชัย ท่าลาดขาว นครราชสีมา 30190",
    name_en: "Chok Chai, Tha Lat Khao, Nakhon Ratchasima, 30190",
  },
  {
    id: 300706,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "ท่าจะหลุง",
    subdistrictEn: "Tha Chalung",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย ท่าจะหลุง นครราชสีมา 30190",
    name_th: "โชคชัย ท่าจะหลุง นครราชสีมา 30190",
    name_en: "Chok Chai, Tha Chalung, Nakhon Ratchasima, 30190",
  },
  {
    id: 300707,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "ท่าเยี่ยม",
    subdistrictEn: "Tha Yiam",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย ท่าเยี่ยม นครราชสีมา 30190",
    name_th: "โชคชัย ท่าเยี่ยม นครราชสีมา 30190",
    name_en: "Chok Chai, Tha Yiam, Nakhon Ratchasima, 30190",
  },
  {
    id: 300708,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "โชคชัย",
    subdistrictEn: "Chok Chai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย โชคชัย นครราชสีมา 30190",
    name_th: "โชคชัย โชคชัย นครราชสีมา 30190",
    name_en: "Chok Chai, Chok Chai, Nakhon Ratchasima, 30190",
  },
  {
    id: 300709,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "ละลมใหม่พัฒนา",
    subdistrictEn: "Lalom Mai Phatthana",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย ละลมใหม่พัฒนา นครราชสีมา 30190",
    name_th: "โชคชัย ละลมใหม่พัฒนา นครราชสีมา 30190",
    name_en: "Chok Chai, Lalom Mai Phatthana, Nakhon Ratchasima, 30190",
  },
  {
    id: 300710,
    districtTh: "โชคชัย",
    districtEn: "Chok Chai",
    subdistrictTh: "ด่านเกวียน",
    subdistrictEn: "Dan Kwian",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30190,
    district_code: 3007,
    province_code: 19,
    name: "โชคชัย ด่านเกวียน นครราชสีมา 30190",
    name_th: "โชคชัย ด่านเกวียน นครราชสีมา 30190",
    name_en: "Chok Chai, Dan Kwian, Nakhon Ratchasima, 30190",
  },
  {
    id: 300801,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "กุดพิมาน",
    subdistrictEn: "Kut Phiman",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด กุดพิมาน นครราชสีมา 30210",
    name_th: "ด่านขุนทด กุดพิมาน นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Kut Phiman, Nakhon Ratchasima, 30210",
  },
  {
    id: 300802,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "ด่านขุนทด",
    subdistrictEn: "Dan Khun Thot",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด ด่านขุนทด นครราชสีมา 30210",
    name_th: "ด่านขุนทด ด่านขุนทด นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Dan Khun Thot, Nakhon Ratchasima, 30210",
  },
  {
    id: 300803,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "ด่านนอก",
    subdistrictEn: "Dan Nok",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด ด่านนอก นครราชสีมา 30210",
    name_th: "ด่านขุนทด ด่านนอก นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Dan Nok, Nakhon Ratchasima, 30210",
  },
  {
    id: 300804,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "ด่านใน",
    subdistrictEn: "Dan Nai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด ด่านใน นครราชสีมา 30210",
    name_th: "ด่านขุนทด ด่านใน นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Dan Nai, Nakhon Ratchasima, 30210",
  },
  {
    id: 300805,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "ตะเคียน",
    subdistrictEn: "Takhian",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด ตะเคียน นครราชสีมา 30210",
    name_th: "ด่านขุนทด ตะเคียน นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Takhian, Nakhon Ratchasima, 30210",
  },
  {
    id: 300806,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "บ้านเก่า",
    subdistrictEn: "Ban Kao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด บ้านเก่า นครราชสีมา 30210",
    name_th: "ด่านขุนทด บ้านเก่า นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Ban Kao, Nakhon Ratchasima, 30210",
  },
  {
    id: 300807,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "บ้านแปรง",
    subdistrictEn: "Ban Praeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 36220,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด บ้านแปรง นครราชสีมา 36220",
    name_th: "ด่านขุนทด บ้านแปรง นครราชสีมา 36220",
    name_en: "Dan Khun Thot, Ban Praeng, Nakhon Ratchasima, 36220",
  },
  {
    id: 300808,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "พันชนะ",
    subdistrictEn: "Phan Chana",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด พันชนะ นครราชสีมา 30210",
    name_th: "ด่านขุนทด พันชนะ นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Phan Chana, Nakhon Ratchasima, 30210",
  },
  {
    id: 300809,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "สระจรเข้",
    subdistrictEn: "Sa Chorakhe",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด สระจรเข้ นครราชสีมา 30210",
    name_th: "ด่านขุนทด สระจรเข้ นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Sa Chorakhe, Nakhon Ratchasima, 30210",
  },
  {
    id: 300810,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "หนองกราด",
    subdistrictEn: "Nong Krat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด หนองกราด นครราชสีมา 30210",
    name_th: "ด่านขุนทด หนองกราด นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Nong Krat, Nakhon Ratchasima, 30210",
  },
  {
    id: 300811,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "หนองบัวตะเกียด",
    subdistrictEn: "Nong Bua Takiat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด หนองบัวตะเกียด นครราชสีมา 30210",
    name_th: "ด่านขุนทด หนองบัวตะเกียด นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Nong Bua Takiat, Nakhon Ratchasima, 30210",
  },
  {
    id: 300812,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "หนองบัวละคร",
    subdistrictEn: "Nong Bua Lakhon",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด หนองบัวละคร นครราชสีมา 30210",
    name_th: "ด่านขุนทด หนองบัวละคร นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Nong Bua Lakhon, Nakhon Ratchasima, 30210",
  },
  {
    id: 300813,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "หินดาด",
    subdistrictEn: "Hin Dat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด หินดาด นครราชสีมา 30210",
    name_th: "ด่านขุนทด หินดาด นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Hin Dat, Nakhon Ratchasima, 30210",
  },
  {
    id: 300815,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "ห้วยบง",
    subdistrictEn: "Huai Bong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด ห้วยบง นครราชสีมา 30210",
    name_th: "ด่านขุนทด ห้วยบง นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Huai Bong, Nakhon Ratchasima, 30210",
  },
  {
    id: 300817,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "โนนเมืองพัฒนา",
    subdistrictEn: "Non Mueang Phatthana",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด โนนเมืองพัฒนา นครราชสีมา 30210",
    name_th: "ด่านขุนทด โนนเมืองพัฒนา นครราชสีมา 30210",
    name_en: "Dan Khun Thot, Non Mueang Phatthana, Nakhon Ratchasima, 30210",
  },
  {
    id: 300818,
    districtTh: "ด่านขุนทด",
    districtEn: "Dan Khun Thot",
    subdistrictTh: "หนองไทร",
    subdistrictEn: "Nong Sai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 36220,
    district_code: 3008,
    province_code: 19,
    name: "ด่านขุนทด หนองไทร นครราชสีมา 36220",
    name_th: "ด่านขุนทด หนองไทร นครราชสีมา 36220",
    name_en: "Dan Khun Thot, Nong Sai, Nakhon Ratchasima, 36220",
  },
  {
    id: 300901,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "โนนไทย",
    subdistrictEn: "Non Thai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย โนนไทย นครราชสีมา 30220",
    name_th: "โนนไทย โนนไทย นครราชสีมา 30220",
    name_en: "Non Thai, Non Thai, Nakhon Ratchasima, 30220",
  },
  {
    id: 300902,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "ด่านจาก",
    subdistrictEn: "Dan Chak",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย ด่านจาก นครราชสีมา 30220",
    name_th: "โนนไทย ด่านจาก นครราชสีมา 30220",
    name_en: "Non Thai, Dan Chak, Nakhon Ratchasima, 30220",
  },
  {
    id: 300903,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "กำปัง",
    subdistrictEn: "Kampang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย กำปัง นครราชสีมา 30220",
    name_th: "โนนไทย กำปัง นครราชสีมา 30220",
    name_en: "Non Thai, Kampang, Nakhon Ratchasima, 30220",
  },
  {
    id: 300904,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย สำโรง นครราชสีมา 30220",
    name_th: "โนนไทย สำโรง นครราชสีมา 30220",
    name_en: "Non Thai, Samrong, Nakhon Ratchasima, 30220",
  },
  {
    id: 300905,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "ค้างพลู",
    subdistrictEn: "Khang Phlu",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย ค้างพลู นครราชสีมา 30220",
    name_th: "โนนไทย ค้างพลู นครราชสีมา 30220",
    name_en: "Non Thai, Khang Phlu, Nakhon Ratchasima, 30220",
  },
  {
    id: 300906,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "บ้านวัง",
    subdistrictEn: "Ban Wang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย บ้านวัง นครราชสีมา 30220",
    name_th: "โนนไทย บ้านวัง นครราชสีมา 30220",
    name_en: "Non Thai, Ban Wang, Nakhon Ratchasima, 30220",
  },
  {
    id: 300907,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "บัลลังก์",
    subdistrictEn: "Banlang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย บัลลังก์ นครราชสีมา 30220",
    name_th: "โนนไทย บัลลังก์ นครราชสีมา 30220",
    name_en: "Non Thai, Banlang, Nakhon Ratchasima, 30220",
  },
  {
    id: 300908,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "สายออ",
    subdistrictEn: "Sai O",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย สายออ นครราชสีมา 30220",
    name_th: "โนนไทย สายออ นครราชสีมา 30220",
    name_en: "Non Thai, Sai O, Nakhon Ratchasima, 30220",
  },
  {
    id: 300909,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "ถนนโพธิ์",
    subdistrictEn: "Thanon Pho",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย ถนนโพธิ์ นครราชสีมา 30220",
    name_th: "โนนไทย ถนนโพธิ์ นครราชสีมา 30220",
    name_en: "Non Thai, Thanon Pho, Nakhon Ratchasima, 30220",
  },
  {
    id: 300910,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "พังเทียม",
    subdistrictEn: "Phung Theam",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย พังเทียม นครราชสีมา 30220",
    name_th: "โนนไทย พังเทียม นครราชสีมา 30220",
    name_en: "Non Thai, Phung Theam, Nakhon Ratchasima, 30220",
  },
  {
    id: 300911,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "สระพระ",
    subdistrictEn: "Sra Pra",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย สระพระ นครราชสีมา 30220",
    name_th: "โนนไทย สระพระ นครราชสีมา 30220",
    name_en: "Non Thai, Sra Pra, Nakhon Ratchasima, 30220",
  },
  {
    id: 300912,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "ทัพรั้ง",
    subdistrictEn: "Tup Rang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย ทัพรั้ง นครราชสีมา 30220",
    name_th: "โนนไทย ทัพรั้ง นครราชสีมา 30220",
    name_en: "Non Thai, Tup Rang, Nakhon Ratchasima, 30220",
  },
  {
    id: 300913,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "หนองหอย",
    subdistrictEn: "Nong Hoi",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย หนองหอย นครราชสีมา 30220",
    name_th: "โนนไทย หนองหอย นครราชสีมา 30220",
    name_en: "Non Thai, Nong Hoi, Nakhon Ratchasima, 30220",
  },
  {
    id: 300914,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "มะค่า",
    subdistrictEn: "Makha",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย มะค่า นครราชสีมา 30220",
    name_th: "โนนไทย มะค่า นครราชสีมา 30220",
    name_en: "Non Thai, Makha, Nakhon Ratchasima, 30220",
  },
  {
    id: 300915,
    districtTh: "โนนไทย",
    districtEn: "Non Thai",
    subdistrictTh: "มาบกราด",
    subdistrictEn: "Mab Krad",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3009,
    province_code: 19,
    name: "โนนไทย มาบกราด นครราชสีมา 30220",
    name_th: "โนนไทย มาบกราด นครราชสีมา 30220",
    name_en: "Non Thai, Mab Krad, Nakhon Ratchasima, 30220",
  },
  {
    id: 301001,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "โนนสูง",
    subdistrictEn: "Non Sung",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง โนนสูง นครราชสีมา 30160",
    name_th: "โนนสูง โนนสูง นครราชสีมา 30160",
    name_en: "Non Sung, Non Sung, Nakhon Ratchasima, 30160",
  },
  {
    id: 301002,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "ใหม่",
    subdistrictEn: "Mai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง ใหม่ นครราชสีมา 30160",
    name_th: "โนนสูง ใหม่ นครราชสีมา 30160",
    name_en: "Non Sung, Mai, Nakhon Ratchasima, 30160",
  },
  {
    id: 301003,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "โตนด",
    subdistrictEn: "Tanot",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง โตนด นครราชสีมา 30160",
    name_th: "โนนสูง โตนด นครราชสีมา 30160",
    name_en: "Non Sung, Tanot, Nakhon Ratchasima, 30160",
  },
  {
    id: 301004,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "บิง",
    subdistrictEn: "Bing",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง บิง นครราชสีมา 30160",
    name_th: "โนนสูง บิง นครราชสีมา 30160",
    name_en: "Non Sung, Bing, Nakhon Ratchasima, 30160",
  },
  {
    id: 301005,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "ดอนชมพู",
    subdistrictEn: "Don Chomphu",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง ดอนชมพู นครราชสีมา 30160",
    name_th: "โนนสูง ดอนชมพู นครราชสีมา 30160",
    name_en: "Non Sung, Don Chomphu, Nakhon Ratchasima, 30160",
  },
  {
    id: 301006,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "ธารปราสาท",
    subdistrictEn: "Than Prasat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง ธารปราสาท นครราชสีมา 30240",
    name_th: "โนนสูง ธารปราสาท นครราชสีมา 30240",
    name_en: "Non Sung, Than Prasat, Nakhon Ratchasima, 30240",
  },
  {
    id: 301007,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "หลุมข้าว",
    subdistrictEn: "Lum Khao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง หลุมข้าว นครราชสีมา 30160",
    name_th: "โนนสูง หลุมข้าว นครราชสีมา 30160",
    name_en: "Non Sung, Lum Khao, Nakhon Ratchasima, 30160",
  },
  {
    id: 301008,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "มะค่า",
    subdistrictEn: "Makha",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง มะค่า นครราชสีมา 30160",
    name_th: "โนนสูง มะค่า นครราชสีมา 30160",
    name_en: "Non Sung, Makha, Nakhon Ratchasima, 30160",
  },
  {
    id: 301009,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "พลสงคราม",
    subdistrictEn: "Phon Songkhram",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง พลสงคราม นครราชสีมา 30160",
    name_th: "โนนสูง พลสงคราม นครราชสีมา 30160",
    name_en: "Non Sung, Phon Songkhram, Nakhon Ratchasima, 30160",
  },
  {
    id: 301010,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "จันอัด",
    subdistrictEn: "Chan-at",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง จันอัด นครราชสีมา 30160",
    name_th: "โนนสูง จันอัด นครราชสีมา 30160",
    name_en: "Non Sung, Chan-at, Nakhon Ratchasima, 30160",
  },
  {
    id: 301011,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "ขามเฒ่า",
    subdistrictEn: "Kham Thao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง ขามเฒ่า นครราชสีมา 30160",
    name_th: "โนนสูง ขามเฒ่า นครราชสีมา 30160",
    name_en: "Non Sung, Kham Thao, Nakhon Ratchasima, 30160",
  },
  {
    id: 301012,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "ด่านคล้า",
    subdistrictEn: "Dan Khla",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง ด่านคล้า นครราชสีมา 30160",
    name_th: "โนนสูง ด่านคล้า นครราชสีมา 30160",
    name_en: "Non Sung, Dan Khla, Nakhon Ratchasima, 30160",
  },
  {
    id: 301013,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "ลำคอหงษ์",
    subdistrictEn: "Lam Kho Hong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง ลำคอหงษ์ นครราชสีมา 30160",
    name_th: "โนนสูง ลำคอหงษ์ นครราชสีมา 30160",
    name_en: "Non Sung, Lam Kho Hong, Nakhon Ratchasima, 30160",
  },
  {
    id: 301014,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "เมืองปราสาท",
    subdistrictEn: "Mueang Prasat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง เมืองปราสาท นครราชสีมา 30160",
    name_th: "โนนสูง เมืองปราสาท นครราชสีมา 30160",
    name_en: "Non Sung, Mueang Prasat, Nakhon Ratchasima, 30160",
  },
  {
    id: 301015,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "ดอนหวาย",
    subdistrictEn: "Don Wai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง ดอนหวาย นครราชสีมา 30160",
    name_th: "โนนสูง ดอนหวาย นครราชสีมา 30160",
    name_en: "Non Sung, Don Wai, Nakhon Ratchasima, 30160",
  },
  {
    id: 301016,
    districtTh: "โนนสูง",
    districtEn: "Non Sung",
    subdistrictTh: "ลำมูล",
    subdistrictEn: "Lam Mun",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30160,
    district_code: 3010,
    province_code: 19,
    name: "โนนสูง ลำมูล นครราชสีมา 30160",
    name_th: "โนนสูง ลำมูล นครราชสีมา 30160",
    name_en: "Non Sung, Lam Mun, Nakhon Ratchasima, 30160",
  },
  {
    id: 301101,
    districtTh: "ขามสะแกแสง",
    districtEn: "Kham Sakaesaeng",
    subdistrictTh: "ขามสะแกแสง",
    subdistrictEn: "Kham Sakaesaeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30290,
    district_code: 3011,
    province_code: 19,
    name: "ขามสะแกแสง ขามสะแกแสง นครราชสีมา 30290",
    name_th: "ขามสะแกแสง ขามสะแกแสง นครราชสีมา 30290",
    name_en: "Kham Sakaesaeng, Kham Sakaesaeng, Nakhon Ratchasima, 30290",
  },
  {
    id: 301102,
    districtTh: "ขามสะแกแสง",
    districtEn: "Kham Sakaesaeng",
    subdistrictTh: "โนนเมือง",
    subdistrictEn: "Non Mueang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30290,
    district_code: 3011,
    province_code: 19,
    name: "ขามสะแกแสง โนนเมือง นครราชสีมา 30290",
    name_th: "ขามสะแกแสง โนนเมือง นครราชสีมา 30290",
    name_en: "Kham Sakaesaeng, Non Mueang, Nakhon Ratchasima, 30290",
  },
  {
    id: 301103,
    districtTh: "ขามสะแกแสง",
    districtEn: "Kham Sakaesaeng",
    subdistrictTh: "เมืองนาท",
    subdistrictEn: "Mueang Nat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30290,
    district_code: 3011,
    province_code: 19,
    name: "ขามสะแกแสง เมืองนาท นครราชสีมา 30290",
    name_th: "ขามสะแกแสง เมืองนาท นครราชสีมา 30290",
    name_en: "Kham Sakaesaeng, Mueang Nat, Nakhon Ratchasima, 30290",
  },
  {
    id: 301104,
    districtTh: "ขามสะแกแสง",
    districtEn: "Kham Sakaesaeng",
    subdistrictTh: "ชีวึก",
    subdistrictEn: "Chiwuek",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30290,
    district_code: 3011,
    province_code: 19,
    name: "ขามสะแกแสง ชีวึก นครราชสีมา 30290",
    name_th: "ขามสะแกแสง ชีวึก นครราชสีมา 30290",
    name_en: "Kham Sakaesaeng, Chiwuek, Nakhon Ratchasima, 30290",
  },
  {
    id: 301105,
    districtTh: "ขามสะแกแสง",
    districtEn: "Kham Sakaesaeng",
    subdistrictTh: "พะงาด",
    subdistrictEn: "Pha-ngat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30290,
    district_code: 3011,
    province_code: 19,
    name: "ขามสะแกแสง พะงาด นครราชสีมา 30290",
    name_th: "ขามสะแกแสง พะงาด นครราชสีมา 30290",
    name_en: "Kham Sakaesaeng, Pha-ngat, Nakhon Ratchasima, 30290",
  },
  {
    id: 301106,
    districtTh: "ขามสะแกแสง",
    districtEn: "Kham Sakaesaeng",
    subdistrictTh: "หนองหัวฟาน",
    subdistrictEn: "Nong Hua Fan",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30290,
    district_code: 3011,
    province_code: 19,
    name: "ขามสะแกแสง หนองหัวฟาน นครราชสีมา 30290",
    name_th: "ขามสะแกแสง หนองหัวฟาน นครราชสีมา 30290",
    name_en: "Kham Sakaesaeng, Nong Hua Fan, Nakhon Ratchasima, 30290",
  },
  {
    id: 301107,
    districtTh: "ขามสะแกแสง",
    districtEn: "Kham Sakaesaeng",
    subdistrictTh: "เมืองเกษตร",
    subdistrictEn: "Mueang Kaset",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30290,
    district_code: 3011,
    province_code: 19,
    name: "ขามสะแกแสง เมืองเกษตร นครราชสีมา 30290",
    name_th: "ขามสะแกแสง เมืองเกษตร นครราชสีมา 30290",
    name_en: "Kham Sakaesaeng, Mueang Kaset, Nakhon Ratchasima, 30290",
  },
  {
    id: 301201,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "บัวใหญ่",
    subdistrictEn: "Bua Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ บัวใหญ่ นครราชสีมา 30120",
    name_th: "บัวใหญ่ บัวใหญ่ นครราชสีมา 30120",
    name_en: "Bua Yai, Bua Yai, Nakhon Ratchasima, 30120",
  },
  {
    id: 301203,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "ห้วยยาง",
    subdistrictEn: "Huai Yang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ ห้วยยาง นครราชสีมา 30120",
    name_th: "บัวใหญ่ ห้วยยาง นครราชสีมา 30120",
    name_en: "Bua Yai, Huai Yang, Nakhon Ratchasima, 30120",
  },
  {
    id: 301204,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "เสมาใหญ่",
    subdistrictEn: "Sema Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ เสมาใหญ่ นครราชสีมา 30120",
    name_th: "บัวใหญ่ เสมาใหญ่ นครราชสีมา 30120",
    name_en: "Bua Yai, Sema Yai, Nakhon Ratchasima, 30120",
  },
  {
    id: 301206,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "ดอนตะหนิน",
    subdistrictEn: "Don Tanin",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ ดอนตะหนิน นครราชสีมา 30120",
    name_th: "บัวใหญ่ ดอนตะหนิน นครราชสีมา 30120",
    name_en: "Bua Yai, Don Tanin, Nakhon Ratchasima, 30120",
  },
  {
    id: 301207,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "หนองบัวสะอาด",
    subdistrictEn: "Nong Bua Sa-at",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ หนองบัวสะอาด นครราชสีมา 30120",
    name_th: "บัวใหญ่ หนองบัวสะอาด นครราชสีมา 30120",
    name_en: "Bua Yai, Nong Bua Sa-at, Nakhon Ratchasima, 30120",
  },
  {
    id: 301208,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "โนนทองหลาง",
    subdistrictEn: "Non Thonglang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ โนนทองหลาง นครราชสีมา 30120",
    name_th: "บัวใหญ่ โนนทองหลาง นครราชสีมา 30120",
    name_en: "Bua Yai, Non Thonglang, Nakhon Ratchasima, 30120",
  },
  {
    id: 301209,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "หนองหว้า",
    subdistrictEn: "Nong Wha",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ หนองหว้า นครราชสีมา 30120",
    name_th: "บัวใหญ่ หนองหว้า นครราชสีมา 30120",
    name_en: "Bua Yai, Nong Wha, Nakhon Ratchasima, 30120",
  },
  {
    id: 301210,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "บัวลาย",
    subdistrictEn: "Bua Lai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ บัวลาย นครราชสีมา 30120",
    name_th: "บัวใหญ่ บัวลาย นครราชสีมา 30120",
    name_en: "Bua Yai, Bua Lai, Nakhon Ratchasima, 30120",
  },
  {
    id: 301211,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "สีดา",
    subdistrictEn: "Sri Da",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ สีดา นครราชสีมา 30120",
    name_th: "บัวใหญ่ สีดา นครราชสีมา 30120",
    name_en: "Bua Yai, Sri Da, Nakhon Ratchasima, 30120",
  },
  {
    id: 301212,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Pon Thong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ โพนทอง นครราชสีมา 30120",
    name_th: "บัวใหญ่ โพนทอง นครราชสีมา 30120",
    name_en: "Bua Yai, Pon Thong, Nakhon Ratchasima, 30120",
  },
  {
    id: 301214,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "กุดจอก",
    subdistrictEn: "Kut Chok",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ กุดจอก นครราชสีมา 30120",
    name_th: "บัวใหญ่ กุดจอก นครราชสีมา 30120",
    name_en: "Bua Yai, Kut Chok, Nakhon Ratchasima, 30120",
  },
  {
    id: 301215,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "ด่านช้าง",
    subdistrictEn: "Dan Chang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ ด่านช้าง นครราชสีมา 30120",
    name_th: "บัวใหญ่ ด่านช้าง นครราชสีมา 30120",
    name_en: "Bua Yai, Dan Chang, Nakhon Ratchasima, 30120",
  },
  {
    id: 301216,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "โนนจาน",
    subdistrictEn: "Non Jan",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ โนนจาน นครราชสีมา 30120",
    name_th: "บัวใหญ่ โนนจาน นครราชสีมา 30120",
    name_en: "Bua Yai, Non Jan, Nakhon Ratchasima, 30120",
  },
  {
    id: 301218,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "สามเมือง",
    subdistrictEn: "Sam Muang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ สามเมือง นครราชสีมา 30120",
    name_th: "บัวใหญ่ สามเมือง นครราชสีมา 30120",
    name_en: "Bua Yai, Sam Muang, Nakhon Ratchasima, 30120",
  },
  {
    id: 301220,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "ขุนทอง",
    subdistrictEn: "Khun Thong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ ขุนทอง นครราชสีมา 30120",
    name_th: "บัวใหญ่ ขุนทอง นครราชสีมา 30120",
    name_en: "Bua Yai, Khun Thong, Nakhon Ratchasima, 30120",
  },
  {
    id: 301221,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "หนองตาดใหญ่",
    subdistrictEn: "Nong Tad Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ หนองตาดใหญ่ นครราชสีมา 30120",
    name_th: "บัวใหญ่ หนองตาดใหญ่ นครราชสีมา 30120",
    name_en: "Bua Yai, Nong Tad Yai, Nakhon Ratchasima, 30120",
  },
  {
    id: 301222,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "เมืองพะไล",
    subdistrictEn: "Mueang Pa Lai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ เมืองพะไล นครราชสีมา 30120",
    name_th: "บัวใหญ่ เมืองพะไล นครราชสีมา 30120",
    name_en: "Bua Yai, Mueang Pa Lai, Nakhon Ratchasima, 30120",
  },
  {
    id: 301223,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "โนนประดู่",
    subdistrictEn: "Non Pradoo",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ โนนประดู่ นครราชสีมา 30120",
    name_th: "บัวใหญ่ โนนประดู่ นครราชสีมา 30120",
    name_en: "Bua Yai, Non Pradoo, Nakhon Ratchasima, 30120",
  },
  {
    id: 301224,
    districtTh: "บัวใหญ่",
    districtEn: "Bua Yai",
    subdistrictTh: "หนองแจ้งใหญ่",
    subdistrictEn: "Nong Chaeng Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3012,
    province_code: 19,
    name: "บัวใหญ่ หนองแจ้งใหญ่ นครราชสีมา 30120",
    name_th: "บัวใหญ่ หนองแจ้งใหญ่ นครราชสีมา 30120",
    name_en: "Bua Yai, Nong Chaeng Yai, Nakhon Ratchasima, 30120",
  },
  {
    id: 301301,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "ประทาย",
    subdistrictEn: "Prathai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย ประทาย นครราชสีมา 30180",
    name_th: "ประทาย ประทาย นครราชสีมา 30180",
    name_en: "Prathai, Prathai, Nakhon Ratchasima, 30180",
  },
  {
    id: 301303,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "กระทุ่มราย",
    subdistrictEn: "Krathum Rai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย กระทุ่มราย นครราชสีมา 30180",
    name_th: "ประทาย กระทุ่มราย นครราชสีมา 30180",
    name_en: "Prathai, Krathum Rai, Nakhon Ratchasima, 30180",
  },
  {
    id: 301304,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "วังไม้แดง",
    subdistrictEn: "Wang Mai Daeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย วังไม้แดง นครราชสีมา 30180",
    name_th: "ประทาย วังไม้แดง นครราชสีมา 30180",
    name_en: "Prathai, Wang Mai Daeng, Nakhon Ratchasima, 30180",
  },
  {
    id: 301306,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "ตลาดไทร",
    subdistrictEn: "Talat Sai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย ตลาดไทร นครราชสีมา 30180",
    name_th: "ประทาย ตลาดไทร นครราชสีมา 30180",
    name_en: "Prathai, Talat Sai, Nakhon Ratchasima, 30180",
  },
  {
    id: 301307,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "หนองพลวง",
    subdistrictEn: "Nong Phluang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย หนองพลวง นครราชสีมา 30180",
    name_th: "ประทาย หนองพลวง นครราชสีมา 30180",
    name_en: "Prathai, Nong Phluang, Nakhon Ratchasima, 30180",
  },
  {
    id: 301308,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "หนองค่าย",
    subdistrictEn: "Nong Khai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย หนองค่าย นครราชสีมา 30180",
    name_th: "ประทาย หนองค่าย นครราชสีมา 30180",
    name_en: "Prathai, Nong Khai, Nakhon Ratchasima, 30180",
  },
  {
    id: 301309,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "หันห้วยทราย",
    subdistrictEn: "Han Huai Sai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย หันห้วยทราย นครราชสีมา 30180",
    name_th: "ประทาย หันห้วยทราย นครราชสีมา 30180",
    name_en: "Prathai, Han Huai Sai, Nakhon Ratchasima, 30180",
  },
  {
    id: 301310,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "ดอนมัน",
    subdistrictEn: "Don Man",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย ดอนมัน นครราชสีมา 30180",
    name_th: "ประทาย ดอนมัน นครราชสีมา 30180",
    name_en: "Prathai, Don Man, Nakhon Ratchasima, 30180",
  },
  {
    id: 301313,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "นางรำ",
    subdistrictEn: "Nang Ram",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย นางรำ นครราชสีมา 30180",
    name_th: "ประทาย นางรำ นครราชสีมา 30180",
    name_en: "Prathai, Nang Ram, Nakhon Ratchasima, 30180",
  },
  {
    id: 301314,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "โนนเพ็ด",
    subdistrictEn: "Non Phet",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย โนนเพ็ด นครราชสีมา 30180",
    name_th: "ประทาย โนนเพ็ด นครราชสีมา 30180",
    name_en: "Prathai, Non Phet, Nakhon Ratchasima, 30180",
  },
  {
    id: 301315,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "ทุ่งสว่าง",
    subdistrictEn: "Thung Sawang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย ทุ่งสว่าง นครราชสีมา 30180",
    name_th: "ประทาย ทุ่งสว่าง นครราชสีมา 30180",
    name_en: "Prathai, Thung Sawang, Nakhon Ratchasima, 30180",
  },
  {
    id: 301317,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "โคกกลาง",
    subdistrictEn: "Khok Klang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย โคกกลาง นครราชสีมา 30180",
    name_th: "ประทาย โคกกลาง นครราชสีมา 30180",
    name_en: "Prathai, Khok Klang, Nakhon Ratchasima, 30180",
  },
  {
    id: 301318,
    districtTh: "ประทาย",
    districtEn: "Prathai",
    subdistrictTh: "เมืองโดน",
    subdistrictEn: "Mueang Don",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30180,
    district_code: 3013,
    province_code: 19,
    name: "ประทาย เมืองโดน นครราชสีมา 30180",
    name_th: "ประทาย เมืองโดน นครราชสีมา 30180",
    name_en: "Prathai, Mueang Don, Nakhon Ratchasima, 30180",
  },
  {
    id: 301401,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "เมืองปัก",
    subdistrictEn: "Mueang Pak",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย เมืองปัก นครราชสีมา 30150",
    name_th: "ปักธงชัย เมืองปัก นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Mueang Pak, Nakhon Ratchasima, 30150",
  },
  {
    id: 301402,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "ตะคุ",
    subdistrictEn: "Takhu",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย ตะคุ นครราชสีมา 30150",
    name_th: "ปักธงชัย ตะคุ นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Takhu, Nakhon Ratchasima, 30150",
  },
  {
    id: 301403,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "โคกไทย",
    subdistrictEn: "Khok Thai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย โคกไทย นครราชสีมา 30150",
    name_th: "ปักธงชัย โคกไทย นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Khok Thai, Nakhon Ratchasima, 30150",
  },
  {
    id: 301404,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย สำโรง นครราชสีมา 30150",
    name_th: "ปักธงชัย สำโรง นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Samrong, Nakhon Ratchasima, 30150",
  },
  {
    id: 301405,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "ตะขบ",
    subdistrictEn: "Takhop",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย ตะขบ นครราชสีมา 30150",
    name_th: "ปักธงชัย ตะขบ นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Takhop, Nakhon Ratchasima, 30150",
  },
  {
    id: 301406,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "นกออก",
    subdistrictEn: "Nok Ok",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย นกออก นครราชสีมา 30150",
    name_th: "ปักธงชัย นกออก นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Nok Ok, Nakhon Ratchasima, 30150",
  },
  {
    id: 301407,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "ดอน",
    subdistrictEn: "Don",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย ดอน นครราชสีมา 30150",
    name_th: "ปักธงชัย ดอน นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Don, Nakhon Ratchasima, 30150",
  },
  {
    id: 301409,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "ตูม",
    subdistrictEn: "Tum",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย ตูม นครราชสีมา 30150",
    name_th: "ปักธงชัย ตูม นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Tum, Nakhon Ratchasima, 30150",
  },
  {
    id: 301410,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "งิ้ว",
    subdistrictEn: "Ngio",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย งิ้ว นครราชสีมา 30150",
    name_th: "ปักธงชัย งิ้ว นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Ngio, Nakhon Ratchasima, 30150",
  },
  {
    id: 301411,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "สะแกราช",
    subdistrictEn: "Sakae Rat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย สะแกราช นครราชสีมา 30150",
    name_th: "ปักธงชัย สะแกราช นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Sakae Rat, Nakhon Ratchasima, 30150",
  },
  {
    id: 301412,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "ลำนางแก้ว",
    subdistrictEn: "Lam Nang Kaeo",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย ลำนางแก้ว นครราชสีมา 30150",
    name_th: "ปักธงชัย ลำนางแก้ว นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Lam Nang Kaeo, Nakhon Ratchasima, 30150",
  },
  {
    id: 301416,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "ภูหลวง",
    subdistrictEn: "Phu Luang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย ภูหลวง นครราชสีมา 30150",
    name_th: "ปักธงชัย ภูหลวง นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Phu Luang, Nakhon Ratchasima, 30150",
  },
  {
    id: 301417,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "ธงชัยเหนือ",
    subdistrictEn: "Thong Chai Nuea",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย ธงชัยเหนือ นครราชสีมา 30150",
    name_th: "ปักธงชัย ธงชัยเหนือ นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Thong Chai Nuea, Nakhon Ratchasima, 30150",
  },
  {
    id: 301418,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "สุขเกษม",
    subdistrictEn: "Suk Kasem",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย สุขเกษม นครราชสีมา 30150",
    name_th: "ปักธงชัย สุขเกษม นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Suk Kasem, Nakhon Ratchasima, 30150",
  },
  {
    id: 301419,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "เกษมทรัพย์",
    subdistrictEn: "Kasem Sap",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย เกษมทรัพย์ นครราชสีมา 30150",
    name_th: "ปักธงชัย เกษมทรัพย์ นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Kasem Sap, Nakhon Ratchasima, 30150",
  },
  {
    id: 301420,
    districtTh: "ปักธงชัย",
    districtEn: "Pak Thong Chai",
    subdistrictTh: "บ่อปลาทอง",
    subdistrictEn: "Bo Pla Thong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3014,
    province_code: 19,
    name: "ปักธงชัย บ่อปลาทอง นครราชสีมา 30150",
    name_th: "ปักธงชัย บ่อปลาทอง นครราชสีมา 30150",
    name_en: "Pak Thong Chai, Bo Pla Thong, Nakhon Ratchasima, 30150",
  },
  {
    id: 301501,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย ในเมือง นครราชสีมา 30110",
    name_th: "พิมาย ในเมือง นครราชสีมา 30110",
    name_en: "Phimai, Nai Mueang, Nakhon Ratchasima, 30110",
  },
  {
    id: 301502,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "สัมฤทธิ์",
    subdistrictEn: "Samrit",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย สัมฤทธิ์ นครราชสีมา 30110",
    name_th: "พิมาย สัมฤทธิ์ นครราชสีมา 30110",
    name_en: "Phimai, Samrit, Nakhon Ratchasima, 30110",
  },
  {
    id: 301503,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "โบสถ์",
    subdistrictEn: "Bot",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย โบสถ์ นครราชสีมา 30110",
    name_th: "พิมาย โบสถ์ นครราชสีมา 30110",
    name_en: "Phimai, Bot, Nakhon Ratchasima, 30110",
  },
  {
    id: 301504,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "กระเบื้องใหญ่",
    subdistrictEn: "Krabueang Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย กระเบื้องใหญ่ นครราชสีมา 30110",
    name_th: "พิมาย กระเบื้องใหญ่ นครราชสีมา 30110",
    name_en: "Phimai, Krabueang Yai, Nakhon Ratchasima, 30110",
  },
  {
    id: 301505,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "ท่าหลวง",
    subdistrictEn: "Tha Luang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย ท่าหลวง นครราชสีมา 30110",
    name_th: "พิมาย ท่าหลวง นครราชสีมา 30110",
    name_en: "Phimai, Tha Luang, Nakhon Ratchasima, 30110",
  },
  {
    id: 301506,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "รังกาใหญ่",
    subdistrictEn: "Rang Ka Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย รังกาใหญ่ นครราชสีมา 30110",
    name_th: "พิมาย รังกาใหญ่ นครราชสีมา 30110",
    name_en: "Phimai, Rang Ka Yai, Nakhon Ratchasima, 30110",
  },
  {
    id: 301507,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "ชีวาน",
    subdistrictEn: "Chiwan",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย ชีวาน นครราชสีมา 30110",
    name_th: "พิมาย ชีวาน นครราชสีมา 30110",
    name_en: "Phimai, Chiwan, Nakhon Ratchasima, 30110",
  },
  {
    id: 301508,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "นิคมสร้างตนเอง",
    subdistrictEn: "Nikhom Sang Ton-eng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย นิคมสร้างตนเอง นครราชสีมา 30110",
    name_th: "พิมาย นิคมสร้างตนเอง นครราชสีมา 30110",
    name_en: "Phimai, Nikhom Sang Ton-eng, Nakhon Ratchasima, 30110",
  },
  {
    id: 301509,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "กระชอน",
    subdistrictEn: "Krachon",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย กระชอน นครราชสีมา 30110",
    name_th: "พิมาย กระชอน นครราชสีมา 30110",
    name_en: "Phimai, Krachon, Nakhon Ratchasima, 30110",
  },
  {
    id: 301510,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "ดงใหญ่",
    subdistrictEn: "Dong Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย ดงใหญ่ นครราชสีมา 30110",
    name_th: "พิมาย ดงใหญ่ นครราชสีมา 30110",
    name_en: "Phimai, Dong Yai, Nakhon Ratchasima, 30110",
  },
  {
    id: 301511,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "ธารละหลอด",
    subdistrictEn: "Than Lalot",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย ธารละหลอด นครราชสีมา 30110",
    name_th: "พิมาย ธารละหลอด นครราชสีมา 30110",
    name_en: "Phimai, Than Lalot, Nakhon Ratchasima, 30110",
  },
  {
    id: 301512,
    districtTh: "พิมาย",
    districtEn: "Phimai",
    subdistrictTh: "หนองระเวียง",
    subdistrictEn: "Nong Rawiang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30110,
    district_code: 3015,
    province_code: 19,
    name: "พิมาย หนองระเวียง นครราชสีมา 30110",
    name_th: "พิมาย หนองระเวียง นครราชสีมา 30110",
    name_en: "Phimai, Nong Rawiang, Nakhon Ratchasima, 30110",
  },
  {
    id: 301601,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "ห้วยแถลง",
    subdistrictEn: "Huai Thalaeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง ห้วยแถลง นครราชสีมา 30240",
    name_th: "ห้วยแถลง ห้วยแถลง นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Huai Thalaeng, Nakhon Ratchasima, 30240",
  },
  {
    id: 301602,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "ทับสวาย",
    subdistrictEn: "Thap Sawai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง ทับสวาย นครราชสีมา 30240",
    name_th: "ห้วยแถลง ทับสวาย นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Thap Sawai, Nakhon Ratchasima, 30240",
  },
  {
    id: 301603,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "เมืองพลับพลา",
    subdistrictEn: "Mueang Phlapphla",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง เมืองพลับพลา นครราชสีมา 30240",
    name_th: "ห้วยแถลง เมืองพลับพลา นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Mueang Phlapphla, Nakhon Ratchasima, 30240",
  },
  {
    id: 301604,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "หลุ่งตะเคียน",
    subdistrictEn: "Lung Takhian",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง หลุ่งตะเคียน นครราชสีมา 30240",
    name_th: "ห้วยแถลง หลุ่งตะเคียน นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Lung Takhian, Nakhon Ratchasima, 30240",
  },
  {
    id: 301605,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "หินดาด",
    subdistrictEn: "Hin Dat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง หินดาด นครราชสีมา 30240",
    name_th: "ห้วยแถลง หินดาด นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Hin Dat, Nakhon Ratchasima, 30240",
  },
  {
    id: 301606,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "งิ้ว",
    subdistrictEn: "Ngio",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง งิ้ว นครราชสีมา 30240",
    name_th: "ห้วยแถลง งิ้ว นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Ngio, Nakhon Ratchasima, 30240",
  },
  {
    id: 301607,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "กงรถ",
    subdistrictEn: "Kong Rot",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง กงรถ นครราชสีมา 30240",
    name_th: "ห้วยแถลง กงรถ นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Kong Rot, Nakhon Ratchasima, 30240",
  },
  {
    id: 301608,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "หลุ่งประดู่",
    subdistrictEn: "Lung Pradu",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง หลุ่งประดู่ นครราชสีมา 30240",
    name_th: "ห้วยแถลง หลุ่งประดู่ นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Lung Pradu, Nakhon Ratchasima, 30240",
  },
  {
    id: 301609,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "ตะโก",
    subdistrictEn: "Tako",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง ตะโก นครราชสีมา 30240",
    name_th: "ห้วยแถลง ตะโก นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Tako, Nakhon Ratchasima, 30240",
  },
  {
    id: 301610,
    districtTh: "ห้วยแถลง",
    districtEn: "Huai Thalaeng",
    subdistrictTh: "ห้วยแคน",
    subdistrictEn: "Huai Khaen",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30240,
    district_code: 3016,
    province_code: 19,
    name: "ห้วยแถลง ห้วยแคน นครราชสีมา 30240",
    name_th: "ห้วยแถลง ห้วยแคน นครราชสีมา 30240",
    name_en: "Huai Thalaeng, Huai Khaen, Nakhon Ratchasima, 30240",
  },
  {
    id: 301701,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "ชุมพวง",
    subdistrictEn: "Chum Phuang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง ชุมพวง นครราชสีมา 30270",
    name_th: "ชุมพวง ชุมพวง นครราชสีมา 30270",
    name_en: "Chum Phuang, Chum Phuang, Nakhon Ratchasima, 30270",
  },
  {
    id: 301702,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "ประสุข",
    subdistrictEn: "Prasuk",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง ประสุข นครราชสีมา 30270",
    name_th: "ชุมพวง ประสุข นครราชสีมา 30270",
    name_en: "Chum Phuang, Prasuk, Nakhon Ratchasima, 30270",
  },
  {
    id: 301703,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "ท่าลาด",
    subdistrictEn: "Tha Lat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง ท่าลาด นครราชสีมา 30270",
    name_th: "ชุมพวง ท่าลาด นครราชสีมา 30270",
    name_en: "Chum Phuang, Tha Lat, Nakhon Ratchasima, 30270",
  },
  {
    id: 301704,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "สาหร่าย",
    subdistrictEn: "Sarai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง สาหร่าย นครราชสีมา 30270",
    name_th: "ชุมพวง สาหร่าย นครราชสีมา 30270",
    name_en: "Chum Phuang, Sarai, Nakhon Ratchasima, 30270",
  },
  {
    id: 301705,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "ตลาดไทร",
    subdistrictEn: "Talat Sai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง ตลาดไทร นครราชสีมา 30270",
    name_th: "ชุมพวง ตลาดไทร นครราชสีมา 30270",
    name_en: "Chum Phuang, Talat Sai, Nakhon Ratchasima, 30270",
  },
  {
    id: 301706,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "ช่องแมว",
    subdistrictEn: "Chong Maew",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง ช่องแมว นครราชสีมา 30270",
    name_th: "ชุมพวง ช่องแมว นครราชสีมา 30270",
    name_en: "Chum Phuang, Chong Maew, Nakhon Ratchasima, 30270",
  },
  {
    id: 301707,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "ขุย",
    subdistrictEn: "Kui",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง ขุย นครราชสีมา 30270",
    name_th: "ชุมพวง ขุย นครราชสีมา 30270",
    name_en: "Chum Phuang, Kui, Nakhon Ratchasima, 30270",
  },
  {
    id: 301710,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "โนนรัง",
    subdistrictEn: "Non Rang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง โนนรัง นครราชสีมา 30270",
    name_th: "ชุมพวง โนนรัง นครราชสีมา 30270",
    name_en: "Chum Phuang, Non Rang, Nakhon Ratchasima, 30270",
  },
  {
    id: 301711,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง บ้านยาง นครราชสีมา 30270",
    name_th: "ชุมพวง บ้านยาง นครราชสีมา 30270",
    name_en: "Chum Phuang, Ban Yang, Nakhon Ratchasima, 30270",
  },
  {
    id: 301714,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "หนองหลัก",
    subdistrictEn: "Nong Lak",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง หนองหลัก นครราชสีมา 30270",
    name_th: "ชุมพวง หนองหลัก นครราชสีมา 30270",
    name_en: "Chum Phuang, Nong Lak, Nakhon Ratchasima, 30270",
  },
  {
    id: 301715,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "ไพล",
    subdistrictEn: "Plai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง ไพล นครราชสีมา 30270",
    name_th: "ชุมพวง ไพล นครราชสีมา 30270",
    name_en: "Chum Phuang, Plai, Nakhon Ratchasima, 30270",
  },
  {
    id: 301716,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "โนนตูม",
    subdistrictEn: "Non Tum",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง โนนตูม นครราชสีมา 30270",
    name_th: "ชุมพวง โนนตูม นครราชสีมา 30270",
    name_en: "Chum Phuang, Non Tum, Nakhon Ratchasima, 30270",
  },
  {
    id: 301717,
    districtTh: "ชุมพวง",
    districtEn: "Chum Phuang",
    subdistrictTh: "โนนยอ",
    subdistrictEn: "Non Yo",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3017,
    province_code: 19,
    name: "ชุมพวง โนนยอ นครราชสีมา 30270",
    name_th: "ชุมพวง โนนยอ นครราชสีมา 30270",
    name_en: "Chum Phuang, Non Yo, Nakhon Ratchasima, 30270",
  },
  {
    id: 301801,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "สูงเนิน",
    subdistrictEn: "Sung Noen",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30170,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน สูงเนิน นครราชสีมา 30170",
    name_th: "สูงเนิน สูงเนิน นครราชสีมา 30170",
    name_en: "Sung Noen, Sung Noen, Nakhon Ratchasima, 30170",
  },
  {
    id: 301802,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "เสมา",
    subdistrictEn: "Sema",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30170,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน เสมา นครราชสีมา 30170",
    name_th: "สูงเนิน เสมา นครราชสีมา 30170",
    name_en: "Sung Noen, Sema, Nakhon Ratchasima, 30170",
  },
  {
    id: 301803,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "โคราช",
    subdistrictEn: "Khorat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30170,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน โคราช นครราชสีมา 30170",
    name_th: "สูงเนิน โคราช นครราชสีมา 30170",
    name_en: "Sung Noen, Khorat, Nakhon Ratchasima, 30170",
  },
  {
    id: 301804,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "บุ่งขี้เหล็ก",
    subdistrictEn: "Bung Khilek",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30170,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน บุ่งขี้เหล็ก นครราชสีมา 30170",
    name_th: "สูงเนิน บุ่งขี้เหล็ก นครราชสีมา 30170",
    name_en: "Sung Noen, Bung Khilek, Nakhon Ratchasima, 30170",
  },
  {
    id: 301805,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "โนนค่า",
    subdistrictEn: "Non Kha",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30170,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน โนนค่า นครราชสีมา 30170",
    name_th: "สูงเนิน โนนค่า นครราชสีมา 30170",
    name_en: "Sung Noen, Non Kha, Nakhon Ratchasima, 30170",
  },
  {
    id: 301806,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "โค้งยาง",
    subdistrictEn: "Khong Yang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30170,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน โค้งยาง นครราชสีมา 30170",
    name_th: "สูงเนิน โค้งยาง นครราชสีมา 30170",
    name_en: "Sung Noen, Khong Yang, Nakhon Ratchasima, 30170",
  },
  {
    id: 301807,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "มะเกลือเก่า",
    subdistrictEn: "Makluea Kao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30170,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน มะเกลือเก่า นครราชสีมา 30170",
    name_th: "สูงเนิน มะเกลือเก่า นครราชสีมา 30170",
    name_en: "Sung Noen, Makluea Kao, Nakhon Ratchasima, 30170",
  },
  {
    id: 301808,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "มะเกลือใหม่",
    subdistrictEn: "Makluea Mai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30170,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน มะเกลือใหม่ นครราชสีมา 30170",
    name_th: "สูงเนิน มะเกลือใหม่ นครราชสีมา 30170",
    name_en: "Sung Noen, Makluea Mai, Nakhon Ratchasima, 30170",
  },
  {
    id: 301809,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "นากลาง",
    subdistrictEn: "Na Klang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30380,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน นากลาง นครราชสีมา 30380",
    name_th: "สูงเนิน นากลาง นครราชสีมา 30380",
    name_en: "Sung Noen, Na Klang, Nakhon Ratchasima, 30380",
  },
  {
    id: 301810,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "หนองตะไก้",
    subdistrictEn: "Nong Takai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30380,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน หนองตะไก้ นครราชสีมา 30380",
    name_th: "สูงเนิน หนองตะไก้ นครราชสีมา 30380",
    name_en: "Sung Noen, Nong Takai, Nakhon Ratchasima, 30380",
  },
  {
    id: 301811,
    districtTh: "สูงเนิน",
    districtEn: "Sung Noen",
    subdistrictTh: "กุดจิก",
    subdistrictEn: "Kut Chik",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30380,
    district_code: 3018,
    province_code: 19,
    name: "สูงเนิน กุดจิก นครราชสีมา 30380",
    name_th: "สูงเนิน กุดจิก นครราชสีมา 30380",
    name_en: "Sung Noen, Kut Chik, Nakhon Ratchasima, 30380",
  },
  {
    id: 301901,
    districtTh: "ขามทะเลสอ",
    districtEn: "Kham Thale So",
    subdistrictTh: "ขามทะเลสอ",
    subdistrictEn: "Kham Thale So",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30280,
    district_code: 3019,
    province_code: 19,
    name: "ขามทะเลสอ ขามทะเลสอ นครราชสีมา 30280",
    name_th: "ขามทะเลสอ ขามทะเลสอ นครราชสีมา 30280",
    name_en: "Kham Thale So, Kham Thale So, Nakhon Ratchasima, 30280",
  },
  {
    id: 301902,
    districtTh: "ขามทะเลสอ",
    districtEn: "Kham Thale So",
    subdistrictTh: "โป่งแดง",
    subdistrictEn: "Pong Daeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30280,
    district_code: 3019,
    province_code: 19,
    name: "ขามทะเลสอ โป่งแดง นครราชสีมา 30280",
    name_th: "ขามทะเลสอ โป่งแดง นครราชสีมา 30280",
    name_en: "Kham Thale So, Pong Daeng, Nakhon Ratchasima, 30280",
  },
  {
    id: 301903,
    districtTh: "ขามทะเลสอ",
    districtEn: "Kham Thale So",
    subdistrictTh: "พันดุง",
    subdistrictEn: "Phan Dung",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30280,
    district_code: 3019,
    province_code: 19,
    name: "ขามทะเลสอ พันดุง นครราชสีมา 30280",
    name_th: "ขามทะเลสอ พันดุง นครราชสีมา 30280",
    name_en: "Kham Thale So, Phan Dung, Nakhon Ratchasima, 30280",
  },
  {
    id: 301904,
    districtTh: "ขามทะเลสอ",
    districtEn: "Kham Thale So",
    subdistrictTh: "หนองสรวง",
    subdistrictEn: "Nong Suang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30280,
    district_code: 3019,
    province_code: 19,
    name: "ขามทะเลสอ หนองสรวง นครราชสีมา 30280",
    name_th: "ขามทะเลสอ หนองสรวง นครราชสีมา 30280",
    name_en: "Kham Thale So, Nong Suang, Nakhon Ratchasima, 30280",
  },
  {
    id: 301905,
    districtTh: "ขามทะเลสอ",
    districtEn: "Kham Thale So",
    subdistrictTh: "บึงอ้อ",
    subdistrictEn: "Bueng O",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30280,
    district_code: 3019,
    province_code: 19,
    name: "ขามทะเลสอ บึงอ้อ นครราชสีมา 30280",
    name_th: "ขามทะเลสอ บึงอ้อ นครราชสีมา 30280",
    name_en: "Kham Thale So, Bueng O, Nakhon Ratchasima, 30280",
  },
  {
    id: 302001,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "สีคิ้ว",
    subdistrictEn: "Sikhio",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว สีคิ้ว นครราชสีมา 30140",
    name_th: "สีคิ้ว สีคิ้ว นครราชสีมา 30140",
    name_en: "Sikhio, Sikhio, Nakhon Ratchasima, 30140",
  },
  {
    id: 302002,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "บ้านหัน",
    subdistrictEn: "Ban Han",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว บ้านหัน นครราชสีมา 30140",
    name_th: "สีคิ้ว บ้านหัน นครราชสีมา 30140",
    name_en: "Sikhio, Ban Han, Nakhon Ratchasima, 30140",
  },
  {
    id: 302003,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "กฤษณา",
    subdistrictEn: "Kritsana",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว กฤษณา นครราชสีมา 30140",
    name_th: "สีคิ้ว กฤษณา นครราชสีมา 30140",
    name_en: "Sikhio, Kritsana, Nakhon Ratchasima, 30140",
  },
  {
    id: 302004,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "ลาดบัวขาว",
    subdistrictEn: "Lat Bua Khao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30340,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว ลาดบัวขาว นครราชสีมา 30340",
    name_th: "สีคิ้ว ลาดบัวขาว นครราชสีมา 30340",
    name_en: "Sikhio, Lat Bua Khao, Nakhon Ratchasima, 30340",
  },
  {
    id: 302005,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "หนองหญ้าขาว",
    subdistrictEn: "Nong Ya Khao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว หนองหญ้าขาว นครราชสีมา 30140",
    name_th: "สีคิ้ว หนองหญ้าขาว นครราชสีมา 30140",
    name_en: "Sikhio, Nong Ya Khao, Nakhon Ratchasima, 30140",
  },
  {
    id: 302006,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "กุดน้อย",
    subdistrictEn: "Kut Noi",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว กุดน้อย นครราชสีมา 30140",
    name_th: "สีคิ้ว กุดน้อย นครราชสีมา 30140",
    name_en: "Sikhio, Kut Noi, Nakhon Ratchasima, 30140",
  },
  {
    id: 302007,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "หนองน้ำใส",
    subdistrictEn: "Nong Nam Sai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว หนองน้ำใส นครราชสีมา 30140",
    name_th: "สีคิ้ว หนองน้ำใส นครราชสีมา 30140",
    name_en: "Sikhio, Nong Nam Sai, Nakhon Ratchasima, 30140",
  },
  {
    id: 302008,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "วังโรงใหญ่",
    subdistrictEn: "Wang Rong Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว วังโรงใหญ่ นครราชสีมา 30140",
    name_th: "สีคิ้ว วังโรงใหญ่ นครราชสีมา 30140",
    name_en: "Sikhio, Wang Rong Yai, Nakhon Ratchasima, 30140",
  },
  {
    id: 302009,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "มิตรภาพ",
    subdistrictEn: "Mittraphap",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว มิตรภาพ นครราชสีมา 30140",
    name_th: "สีคิ้ว มิตรภาพ นครราชสีมา 30140",
    name_en: "Sikhio, Mittraphap, Nakhon Ratchasima, 30140",
  },
  {
    id: 302010,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "คลองไผ่",
    subdistrictEn: "Khlong Phai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30340,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว คลองไผ่ นครราชสีมา 30340",
    name_th: "สีคิ้ว คลองไผ่ นครราชสีมา 30340",
    name_en: "Sikhio, Khlong Phai, Nakhon Ratchasima, 30340",
  },
  {
    id: 302011,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "ดอนเมือง",
    subdistrictEn: "Don Mueang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว ดอนเมือง นครราชสีมา 30140",
    name_th: "สีคิ้ว ดอนเมือง นครราชสีมา 30140",
    name_en: "Sikhio, Don Mueang, Nakhon Ratchasima, 30140",
  },
  {
    id: 302012,
    districtTh: "สีคิ้ว",
    districtEn: "Sikhio",
    subdistrictTh: "หนองบัวน้อย",
    subdistrictEn: "Nong Bua Noi",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30140,
    district_code: 3020,
    province_code: 19,
    name: "สีคิ้ว หนองบัวน้อย นครราชสีมา 30140",
    name_th: "สีคิ้ว หนองบัวน้อย นครราชสีมา 30140",
    name_en: "Sikhio, Nong Bua Noi, Nakhon Ratchasima, 30140",
  },
  {
    id: 302101,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "ปากช่อง",
    subdistrictEn: "Pak Chong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง ปากช่อง นครราชสีมา 30130",
    name_th: "ปากช่อง ปากช่อง นครราชสีมา 30130",
    name_en: "Pak Chong, Pak Chong, Nakhon Ratchasima, 30130",
  },
  {
    id: 302102,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "กลางดง",
    subdistrictEn: "Klang Dong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30320,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง กลางดง นครราชสีมา 30320",
    name_th: "ปากช่อง กลางดง นครราชสีมา 30320",
    name_en: "Pak Chong, Klang Dong, Nakhon Ratchasima, 30320",
  },
  {
    id: 302103,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "จันทึก",
    subdistrictEn: "Chanthuek",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง จันทึก นครราชสีมา 30130",
    name_th: "ปากช่อง จันทึก นครราชสีมา 30130",
    name_en: "Pak Chong, Chanthuek, Nakhon Ratchasima, 30130",
  },
  {
    id: 302104,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "วังกะทะ",
    subdistrictEn: "Wang Katha",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง วังกะทะ นครราชสีมา 30130",
    name_th: "ปากช่อง วังกะทะ นครราชสีมา 30130",
    name_en: "Pak Chong, Wang Katha, Nakhon Ratchasima, 30130",
  },
  {
    id: 302105,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "หมูสี",
    subdistrictEn: "Mu Si",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง หมูสี นครราชสีมา 30130",
    name_th: "ปากช่อง หมูสี นครราชสีมา 30130",
    name_en: "Pak Chong, Mu Si, Nakhon Ratchasima, 30130",
  },
  {
    id: 302106,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "หนองสาหร่าย",
    subdistrictEn: "Nong Sarai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง หนองสาหร่าย นครราชสีมา 30130",
    name_th: "ปากช่อง หนองสาหร่าย นครราชสีมา 30130",
    name_en: "Pak Chong, Nong Sarai, Nakhon Ratchasima, 30130",
  },
  {
    id: 302107,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "ขนงพระ",
    subdistrictEn: "Khanong Phra",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง ขนงพระ นครราชสีมา 30130",
    name_th: "ปากช่อง ขนงพระ นครราชสีมา 30130",
    name_en: "Pak Chong, Khanong Phra, Nakhon Ratchasima, 30130",
  },
  {
    id: 302108,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "โป่งตาลอง",
    subdistrictEn: "Pong Talong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง โป่งตาลอง นครราชสีมา 30130",
    name_th: "ปากช่อง โป่งตาลอง นครราชสีมา 30130",
    name_en: "Pak Chong, Pong Talong, Nakhon Ratchasima, 30130",
  },
  {
    id: 302109,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "คลองม่วง",
    subdistrictEn: "Khlong Muang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง คลองม่วง นครราชสีมา 30130",
    name_th: "ปากช่อง คลองม่วง นครราชสีมา 30130",
    name_en: "Pak Chong, Khlong Muang, Nakhon Ratchasima, 30130",
  },
  {
    id: 302110,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "หนองน้ำแดง",
    subdistrictEn: "Nong Nam Daeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง หนองน้ำแดง นครราชสีมา 30130",
    name_th: "ปากช่อง หนองน้ำแดง นครราชสีมา 30130",
    name_en: "Pak Chong, Nong Nam Daeng, Nakhon Ratchasima, 30130",
  },
  {
    id: 302111,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "วังไทร",
    subdistrictEn: "Wang Sai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30130,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง วังไทร นครราชสีมา 30130",
    name_th: "ปากช่อง วังไทร นครราชสีมา 30130",
    name_en: "Pak Chong, Wang Sai, Nakhon Ratchasima, 30130",
  },
  {
    id: 302112,
    districtTh: "ปากช่อง",
    districtEn: "Pak Chong",
    subdistrictTh: "พญาเย็น",
    subdistrictEn: "Phaya Yen",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30320,
    district_code: 3021,
    province_code: 19,
    name: "ปากช่อง พญาเย็น นครราชสีมา 30320",
    name_th: "ปากช่อง พญาเย็น นครราชสีมา 30320",
    name_en: "Pak Chong, Phaya Yen, Nakhon Ratchasima, 30320",
  },
  {
    id: 302201,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "หนองบุนนาก",
    subdistrictEn: "Nong Bunnak",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก หนองบุนนาก นครราชสีมา 30410",
    name_th: "หนองบุญมาก หนองบุนนาก นครราชสีมา 30410",
    name_en: "Nong Bunnak, Nong Bunnak, Nakhon Ratchasima, 30410",
  },
  {
    id: 302202,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "สารภี",
    subdistrictEn: "Saraphi",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก สารภี นครราชสีมา 30410",
    name_th: "หนองบุญมาก สารภี นครราชสีมา 30410",
    name_en: "Nong Bunnak, Saraphi, Nakhon Ratchasima, 30410",
  },
  {
    id: 302203,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "ไทยเจริญ",
    subdistrictEn: "Thai Charoen",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก ไทยเจริญ นครราชสีมา 30410",
    name_th: "หนองบุญมาก ไทยเจริญ นครราชสีมา 30410",
    name_en: "Nong Bunnak, Thai Charoen, Nakhon Ratchasima, 30410",
  },
  {
    id: 302204,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "หนองหัวแรต",
    subdistrictEn: "Nong Hua Raet",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก หนองหัวแรต นครราชสีมา 30410",
    name_th: "หนองบุญมาก หนองหัวแรต นครราชสีมา 30410",
    name_en: "Nong Bunnak, Nong Hua Raet, Nakhon Ratchasima, 30410",
  },
  {
    id: 302205,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "แหลมทอง",
    subdistrictEn: "Laem Thong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก แหลมทอง นครราชสีมา 30410",
    name_th: "หนองบุญมาก แหลมทอง นครราชสีมา 30410",
    name_en: "Nong Bunnak, Laem Thong, Nakhon Ratchasima, 30410",
  },
  {
    id: 302206,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "หนองตะไก้",
    subdistrictEn: "Nong Takai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก หนองตะไก้ นครราชสีมา 30410",
    name_th: "หนองบุญมาก หนองตะไก้ นครราชสีมา 30410",
    name_en: "Nong Bunnak, Nong Takai, Nakhon Ratchasima, 30410",
  },
  {
    id: 302207,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "ลุงเขว้า",
    subdistrictEn: "Lung Khwao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก ลุงเขว้า นครราชสีมา 30410",
    name_th: "หนองบุญมาก ลุงเขว้า นครราชสีมา 30410",
    name_en: "Nong Bunnak, Lung Khwao, Nakhon Ratchasima, 30410",
  },
  {
    id: 302208,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "หนองไม้ไผ่",
    subdistrictEn: "Nong Mai Phai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก หนองไม้ไผ่ นครราชสีมา 30410",
    name_th: "หนองบุญมาก หนองไม้ไผ่ นครราชสีมา 30410",
    name_en: "Nong Bunnak, Nong Mai Phai, Nakhon Ratchasima, 30410",
  },
  {
    id: 302209,
    districtTh: "หนองบุญมาก",
    districtEn: "Nong Bunnak",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30410,
    district_code: 3022,
    province_code: 19,
    name: "หนองบุญมาก บ้านใหม่ นครราชสีมา 30410",
    name_th: "หนองบุญมาก บ้านใหม่ นครราชสีมา 30410",
    name_en: "Nong Bunnak, Ban Mai, Nakhon Ratchasima, 30410",
  },
  {
    id: 302301,
    districtTh: "แก้งสนามนาง",
    districtEn: "Kaeng Sanam Nang",
    subdistrictTh: "แก้งสนามนาง",
    subdistrictEn: "Kaeng Sanam Nang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30440,
    district_code: 3023,
    province_code: 19,
    name: "แก้งสนามนาง แก้งสนามนาง นครราชสีมา 30440",
    name_th: "แก้งสนามนาง แก้งสนามนาง นครราชสีมา 30440",
    name_en: "Kaeng Sanam Nang, Kaeng Sanam Nang, Nakhon Ratchasima, 30440",
  },
  {
    id: 302302,
    districtTh: "แก้งสนามนาง",
    districtEn: "Kaeng Sanam Nang",
    subdistrictTh: "โนนสำราญ",
    subdistrictEn: "Non Samran",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30440,
    district_code: 3023,
    province_code: 19,
    name: "แก้งสนามนาง โนนสำราญ นครราชสีมา 30440",
    name_th: "แก้งสนามนาง โนนสำราญ นครราชสีมา 30440",
    name_en: "Kaeng Sanam Nang, Non Samran, Nakhon Ratchasima, 30440",
  },
  {
    id: 302303,
    districtTh: "แก้งสนามนาง",
    districtEn: "Kaeng Sanam Nang",
    subdistrictTh: "บึงพะไล",
    subdistrictEn: "Bueng Phalai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30440,
    district_code: 3023,
    province_code: 19,
    name: "แก้งสนามนาง บึงพะไล นครราชสีมา 30440",
    name_th: "แก้งสนามนาง บึงพะไล นครราชสีมา 30440",
    name_en: "Kaeng Sanam Nang, Bueng Phalai, Nakhon Ratchasima, 30440",
  },
  {
    id: 302304,
    districtTh: "แก้งสนามนาง",
    districtEn: "Kaeng Sanam Nang",
    subdistrictTh: "สีสุก",
    subdistrictEn: "Si Suk",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30440,
    district_code: 3023,
    province_code: 19,
    name: "แก้งสนามนาง สีสุก นครราชสีมา 30440",
    name_th: "แก้งสนามนาง สีสุก นครราชสีมา 30440",
    name_en: "Kaeng Sanam Nang, Si Suk, Nakhon Ratchasima, 30440",
  },
  {
    id: 302305,
    districtTh: "แก้งสนามนาง",
    districtEn: "Kaeng Sanam Nang",
    subdistrictTh: "บึงสำโรง",
    subdistrictEn: "Bueng Samrong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30440,
    district_code: 3023,
    province_code: 19,
    name: "แก้งสนามนาง บึงสำโรง นครราชสีมา 30440",
    name_th: "แก้งสนามนาง บึงสำโรง นครราชสีมา 30440",
    name_en: "Kaeng Sanam Nang, Bueng Samrong, Nakhon Ratchasima, 30440",
  },
  {
    id: 302401,
    districtTh: "โนนแดง",
    districtEn: "Non Daeng",
    subdistrictTh: "โนนแดง",
    subdistrictEn: "Non Daeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30360,
    district_code: 3024,
    province_code: 19,
    name: "โนนแดง โนนแดง นครราชสีมา 30360",
    name_th: "โนนแดง โนนแดง นครราชสีมา 30360",
    name_en: "Non Daeng, Non Daeng, Nakhon Ratchasima, 30360",
  },
  {
    id: 302402,
    districtTh: "โนนแดง",
    districtEn: "Non Daeng",
    subdistrictTh: "โนนตาเถร",
    subdistrictEn: "Non Ta Then",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30360,
    district_code: 3024,
    province_code: 19,
    name: "โนนแดง โนนตาเถร นครราชสีมา 30360",
    name_th: "โนนแดง โนนตาเถร นครราชสีมา 30360",
    name_en: "Non Daeng, Non Ta Then, Nakhon Ratchasima, 30360",
  },
  {
    id: 302403,
    districtTh: "โนนแดง",
    districtEn: "Non Daeng",
    subdistrictTh: "สำพะเนียง",
    subdistrictEn: "Samphaniang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30360,
    district_code: 3024,
    province_code: 19,
    name: "โนนแดง สำพะเนียง นครราชสีมา 30360",
    name_th: "โนนแดง สำพะเนียง นครราชสีมา 30360",
    name_en: "Non Daeng, Samphaniang, Nakhon Ratchasima, 30360",
  },
  {
    id: 302404,
    districtTh: "โนนแดง",
    districtEn: "Non Daeng",
    subdistrictTh: "วังหิน",
    subdistrictEn: "Wang Hin",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30360,
    district_code: 3024,
    province_code: 19,
    name: "โนนแดง วังหิน นครราชสีมา 30360",
    name_th: "โนนแดง วังหิน นครราชสีมา 30360",
    name_en: "Non Daeng, Wang Hin, Nakhon Ratchasima, 30360",
  },
  {
    id: 302405,
    districtTh: "โนนแดง",
    districtEn: "Non Daeng",
    subdistrictTh: "ดอนยาวใหญ่",
    subdistrictEn: "Don Yao Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30360,
    district_code: 3024,
    province_code: 19,
    name: "โนนแดง ดอนยาวใหญ่ นครราชสีมา 30360",
    name_th: "โนนแดง ดอนยาวใหญ่ นครราชสีมา 30360",
    name_en: "Non Daeng, Don Yao Yai, Nakhon Ratchasima, 30360",
  },
  {
    id: 302501,
    districtTh: "วังน้ำเขียว",
    districtEn: "Wang Nam Khiao",
    subdistrictTh: "วังน้ำเขียว",
    subdistrictEn: "Wang Nam Khiao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30370,
    district_code: 3025,
    province_code: 19,
    name: "วังน้ำเขียว วังน้ำเขียว นครราชสีมา 30370",
    name_th: "วังน้ำเขียว วังน้ำเขียว นครราชสีมา 30370",
    name_en: "Wang Nam Khiao, Wang Nam Khiao, Nakhon Ratchasima, 30370",
  },
  {
    id: 302502,
    districtTh: "วังน้ำเขียว",
    districtEn: "Wang Nam Khiao",
    subdistrictTh: "วังหมี",
    subdistrictEn: "Wang Mi",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30370,
    district_code: 3025,
    province_code: 19,
    name: "วังน้ำเขียว วังหมี นครราชสีมา 30370",
    name_th: "วังน้ำเขียว วังหมี นครราชสีมา 30370",
    name_en: "Wang Nam Khiao, Wang Mi, Nakhon Ratchasima, 30370",
  },
  {
    id: 302503,
    districtTh: "วังน้ำเขียว",
    districtEn: "Wang Nam Khiao",
    subdistrictTh: "ระเริง",
    subdistrictEn: "Raroeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30150,
    district_code: 3025,
    province_code: 19,
    name: "วังน้ำเขียว ระเริง นครราชสีมา 30150",
    name_th: "วังน้ำเขียว ระเริง นครราชสีมา 30150",
    name_en: "Wang Nam Khiao, Raroeng, Nakhon Ratchasima, 30150",
  },
  {
    id: 302504,
    districtTh: "วังน้ำเขียว",
    districtEn: "Wang Nam Khiao",
    subdistrictTh: "อุดมทรัพย์",
    subdistrictEn: "Udom Sap",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30370,
    district_code: 3025,
    province_code: 19,
    name: "วังน้ำเขียว อุดมทรัพย์ นครราชสีมา 30370",
    name_th: "วังน้ำเขียว อุดมทรัพย์ นครราชสีมา 30370",
    name_en: "Wang Nam Khiao, Udom Sap, Nakhon Ratchasima, 30370",
  },
  {
    id: 302505,
    districtTh: "วังน้ำเขียว",
    districtEn: "Wang Nam Khiao",
    subdistrictTh: "ไทยสามัคคี",
    subdistrictEn: "Thai Samakkhi",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30370,
    district_code: 3025,
    province_code: 19,
    name: "วังน้ำเขียว ไทยสามัคคี นครราชสีมา 30370",
    name_th: "วังน้ำเขียว ไทยสามัคคี นครราชสีมา 30370",
    name_en: "Wang Nam Khiao, Thai Samakkhi, Nakhon Ratchasima, 30370",
  },
  {
    id: 302601,
    districtTh: "เทพารักษ์",
    districtEn: "Thepharak",
    subdistrictTh: "สำนักตะคร้อ",
    subdistrictEn: "Samnak Takhro",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3026,
    province_code: 19,
    name: "เทพารักษ์ สำนักตะคร้อ นครราชสีมา 30210",
    name_th: "เทพารักษ์ สำนักตะคร้อ นครราชสีมา 30210",
    name_en: "Thepharak, Samnak Takhro, Nakhon Ratchasima, 30210",
  },
  {
    id: 302602,
    districtTh: "เทพารักษ์",
    districtEn: "Thepharak",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3026,
    province_code: 19,
    name: "เทพารักษ์ หนองแวง นครราชสีมา 30210",
    name_th: "เทพารักษ์ หนองแวง นครราชสีมา 30210",
    name_en: "Thepharak, Nong Waeng, Nakhon Ratchasima, 30210",
  },
  {
    id: 302603,
    districtTh: "เทพารักษ์",
    districtEn: "Thepharak",
    subdistrictTh: "บึงปรือ",
    subdistrictEn: "Bueng Prue",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3026,
    province_code: 19,
    name: "เทพารักษ์ บึงปรือ นครราชสีมา 30210",
    name_th: "เทพารักษ์ บึงปรือ นครราชสีมา 30210",
    name_en: "Thepharak, Bueng Prue, Nakhon Ratchasima, 30210",
  },
  {
    id: 302604,
    districtTh: "เทพารักษ์",
    districtEn: "Thepharak",
    subdistrictTh: "วังยายทอง",
    subdistrictEn: "Wang Yai Thong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30210,
    district_code: 3026,
    province_code: 19,
    name: "เทพารักษ์ วังยายทอง นครราชสีมา 30210",
    name_th: "เทพารักษ์ วังยายทอง นครราชสีมา 30210",
    name_en: "Thepharak, Wang Yai Thong, Nakhon Ratchasima, 30210",
  },
  {
    id: 302701,
    districtTh: "เมืองยาง",
    districtEn: "Mueang Yang",
    subdistrictTh: "เมืองยาง",
    subdistrictEn: "Mueang Yang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3027,
    province_code: 19,
    name: "เมืองยาง เมืองยาง นครราชสีมา 30270",
    name_th: "เมืองยาง เมืองยาง นครราชสีมา 30270",
    name_en: "Mueang Yang, Mueang Yang, Nakhon Ratchasima, 30270",
  },
  {
    id: 302702,
    districtTh: "เมืองยาง",
    districtEn: "Mueang Yang",
    subdistrictTh: "กระเบื้องนอก",
    subdistrictEn: "Krabueang Nok",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3027,
    province_code: 19,
    name: "เมืองยาง กระเบื้องนอก นครราชสีมา 30270",
    name_th: "เมืองยาง กระเบื้องนอก นครราชสีมา 30270",
    name_en: "Mueang Yang, Krabueang Nok, Nakhon Ratchasima, 30270",
  },
  {
    id: 302703,
    districtTh: "เมืองยาง",
    districtEn: "Mueang Yang",
    subdistrictTh: "ละหานปลาค้าว",
    subdistrictEn: "Lahan Pla Khao",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3027,
    province_code: 19,
    name: "เมืองยาง ละหานปลาค้าว นครราชสีมา 30270",
    name_th: "เมืองยาง ละหานปลาค้าว นครราชสีมา 30270",
    name_en: "Mueang Yang, Lahan Pla Khao, Nakhon Ratchasima, 30270",
  },
  {
    id: 302704,
    districtTh: "เมืองยาง",
    districtEn: "Mueang Yang",
    subdistrictTh: "โนนอุดม",
    subdistrictEn: "Non Udom",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3027,
    province_code: 19,
    name: "เมืองยาง โนนอุดม นครราชสีมา 30270",
    name_th: "เมืองยาง โนนอุดม นครราชสีมา 30270",
    name_en: "Mueang Yang, Non Udom, Nakhon Ratchasima, 30270",
  },
  {
    id: 302801,
    districtTh: "พระทองคำ",
    districtEn: "Phra Thong Kham",
    subdistrictTh: "สระพระ",
    subdistrictEn: "Sa Phra",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3028,
    province_code: 19,
    name: "พระทองคำ สระพระ นครราชสีมา 30220",
    name_th: "พระทองคำ สระพระ นครราชสีมา 30220",
    name_en: "Phra Thong Kham, Sa Phra, Nakhon Ratchasima, 30220",
  },
  {
    id: 302802,
    districtTh: "พระทองคำ",
    districtEn: "Phra Thong Kham",
    subdistrictTh: "มาบกราด",
    subdistrictEn: "Map Krat",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3028,
    province_code: 19,
    name: "พระทองคำ มาบกราด นครราชสีมา 30220",
    name_th: "พระทองคำ มาบกราด นครราชสีมา 30220",
    name_en: "Phra Thong Kham, Map Krat, Nakhon Ratchasima, 30220",
  },
  {
    id: 302803,
    districtTh: "พระทองคำ",
    districtEn: "Phra Thong Kham",
    subdistrictTh: "พังเทียม",
    subdistrictEn: "Phang Thiam",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3028,
    province_code: 19,
    name: "พระทองคำ พังเทียม นครราชสีมา 30220",
    name_th: "พระทองคำ พังเทียม นครราชสีมา 30220",
    name_en: "Phra Thong Kham, Phang Thiam, Nakhon Ratchasima, 30220",
  },
  {
    id: 302804,
    districtTh: "พระทองคำ",
    districtEn: "Phra Thong Kham",
    subdistrictTh: "ทัพรั้ง",
    subdistrictEn: "Thap Rang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3028,
    province_code: 19,
    name: "พระทองคำ ทัพรั้ง นครราชสีมา 30220",
    name_th: "พระทองคำ ทัพรั้ง นครราชสีมา 30220",
    name_en: "Phra Thong Kham, Thap Rang, Nakhon Ratchasima, 30220",
  },
  {
    id: 302805,
    districtTh: "พระทองคำ",
    districtEn: "Phra Thong Kham",
    subdistrictTh: "หนองหอย",
    subdistrictEn: "Nong Hoi",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30220,
    district_code: 3028,
    province_code: 19,
    name: "พระทองคำ หนองหอย นครราชสีมา 30220",
    name_th: "พระทองคำ หนองหอย นครราชสีมา 30220",
    name_en: "Phra Thong Kham, Nong Hoi, Nakhon Ratchasima, 30220",
  },
  {
    id: 302901,
    districtTh: "ลำทะเมนชัย",
    districtEn: "Lam Thamenchai",
    subdistrictTh: "ขุย",
    subdistrictEn: "Khui",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3029,
    province_code: 19,
    name: "ลำทะเมนชัย ขุย นครราชสีมา 30270",
    name_th: "ลำทะเมนชัย ขุย นครราชสีมา 30270",
    name_en: "Lam Thamenchai, Khui, Nakhon Ratchasima, 30270",
  },
  {
    id: 302902,
    districtTh: "ลำทะเมนชัย",
    districtEn: "Lam Thamenchai",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3029,
    province_code: 19,
    name: "ลำทะเมนชัย บ้านยาง นครราชสีมา 30270",
    name_th: "ลำทะเมนชัย บ้านยาง นครราชสีมา 30270",
    name_en: "Lam Thamenchai, Ban Yang, Nakhon Ratchasima, 30270",
  },
  {
    id: 302903,
    districtTh: "ลำทะเมนชัย",
    districtEn: "Lam Thamenchai",
    subdistrictTh: "ช่องแมว",
    subdistrictEn: "Chong Maeo",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3029,
    province_code: 19,
    name: "ลำทะเมนชัย ช่องแมว นครราชสีมา 30270",
    name_th: "ลำทะเมนชัย ช่องแมว นครราชสีมา 30270",
    name_en: "Lam Thamenchai, Chong Maeo, Nakhon Ratchasima, 30270",
  },
  {
    id: 302904,
    districtTh: "ลำทะเมนชัย",
    districtEn: "Lam Thamenchai",
    subdistrictTh: "ไพล",
    subdistrictEn: "Phlai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30270,
    district_code: 3029,
    province_code: 19,
    name: "ลำทะเมนชัย ไพล นครราชสีมา 30270",
    name_th: "ลำทะเมนชัย ไพล นครราชสีมา 30270",
    name_en: "Lam Thamenchai, Phlai, Nakhon Ratchasima, 30270",
  },
  {
    id: 303001,
    districtTh: "บัวลาย",
    districtEn: "Bua Lai",
    subdistrictTh: "เมืองพะไล",
    subdistrictEn: "Mueang Phalai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3030,
    province_code: 19,
    name: "บัวลาย เมืองพะไล นครราชสีมา 30120",
    name_th: "บัวลาย เมืองพะไล นครราชสีมา 30120",
    name_en: "Bua Lai, Mueang Phalai, Nakhon Ratchasima, 30120",
  },
  {
    id: 303002,
    districtTh: "บัวลาย",
    districtEn: "Bua Lai",
    subdistrictTh: "โนนจาน",
    subdistrictEn: "Non Chan",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3030,
    province_code: 19,
    name: "บัวลาย โนนจาน นครราชสีมา 30120",
    name_th: "บัวลาย โนนจาน นครราชสีมา 30120",
    name_en: "Bua Lai, Non Chan, Nakhon Ratchasima, 30120",
  },
  {
    id: 303003,
    districtTh: "บัวลาย",
    districtEn: "Bua Lai",
    subdistrictTh: "บัวลาย",
    subdistrictEn: "Bua Lai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3030,
    province_code: 19,
    name: "บัวลาย บัวลาย นครราชสีมา 30120",
    name_th: "บัวลาย บัวลาย นครราชสีมา 30120",
    name_en: "Bua Lai, Bua Lai, Nakhon Ratchasima, 30120",
  },
  {
    id: 303004,
    districtTh: "บัวลาย",
    districtEn: "Bua Lai",
    subdistrictTh: "หนองหว้า",
    subdistrictEn: "Nong Wa",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30120,
    district_code: 3030,
    province_code: 19,
    name: "บัวลาย หนองหว้า นครราชสีมา 30120",
    name_th: "บัวลาย หนองหว้า นครราชสีมา 30120",
    name_en: "Bua Lai, Nong Wa, Nakhon Ratchasima, 30120",
  },
  {
    id: 303101,
    districtTh: "สีดา",
    districtEn: "Sida",
    subdistrictTh: "สีดา",
    subdistrictEn: "Sida",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30430,
    district_code: 3031,
    province_code: 19,
    name: "สีดา สีดา นครราชสีมา 30430",
    name_th: "สีดา สีดา นครราชสีมา 30430",
    name_en: "Sida, Sida, Nakhon Ratchasima, 30430",
  },
  {
    id: 303102,
    districtTh: "สีดา",
    districtEn: "Sida",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30430,
    district_code: 3031,
    province_code: 19,
    name: "สีดา โพนทอง นครราชสีมา 30430",
    name_th: "สีดา โพนทอง นครราชสีมา 30430",
    name_en: "Sida, Phon Thong, Nakhon Ratchasima, 30430",
  },
  {
    id: 303103,
    districtTh: "สีดา",
    districtEn: "Sida",
    subdistrictTh: "โนนประดู่",
    subdistrictEn: "Non Pradu",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30430,
    district_code: 3031,
    province_code: 19,
    name: "สีดา โนนประดู่ นครราชสีมา 30430",
    name_th: "สีดา โนนประดู่ นครราชสีมา 30430",
    name_en: "Sida, Non Pradu, Nakhon Ratchasima, 30430",
  },
  {
    id: 303104,
    districtTh: "สีดา",
    districtEn: "Sida",
    subdistrictTh: "สามเมือง",
    subdistrictEn: "Sam Mueang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30430,
    district_code: 3031,
    province_code: 19,
    name: "สีดา สามเมือง นครราชสีมา 30430",
    name_th: "สีดา สามเมือง นครราชสีมา 30430",
    name_en: "Sida, Sam Mueang, Nakhon Ratchasima, 30430",
  },
  {
    id: 303105,
    districtTh: "สีดา",
    districtEn: "Sida",
    subdistrictTh: "หนองตาดใหญ่",
    subdistrictEn: "Nong Tat Yai",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30430,
    district_code: 3031,
    province_code: 19,
    name: "สีดา หนองตาดใหญ่ นครราชสีมา 30430",
    name_th: "สีดา หนองตาดใหญ่ นครราชสีมา 30430",
    name_en: "Sida, Nong Tat Yai, Nakhon Ratchasima, 30430",
  },
  {
    id: 303201,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ช้างทอง",
    subdistrictEn: "Chang Thong",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3032,
    province_code: 19,
    name: "เฉลิมพระเกียรติ ช้างทอง นครราชสีมา 30230",
    name_th: "เฉลิมพระเกียรติ ช้างทอง นครราชสีมา 30230",
    name_en: "Chaloem Phra Kiat, Chang Thong, Nakhon Ratchasima, 30230",
  },
  {
    id: 303202,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3032,
    province_code: 19,
    name: "เฉลิมพระเกียรติ ท่าช้าง นครราชสีมา 30230",
    name_th: "เฉลิมพระเกียรติ ท่าช้าง นครราชสีมา 30230",
    name_en: "Chaloem Phra Kiat, Tha Chang, Nakhon Ratchasima, 30230",
  },
  {
    id: 303203,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "พระพุทธ",
    subdistrictEn: "Phra Phut",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3032,
    province_code: 19,
    name: "เฉลิมพระเกียรติ พระพุทธ นครราชสีมา 30230",
    name_th: "เฉลิมพระเกียรติ พระพุทธ นครราชสีมา 30230",
    name_en: "Chaloem Phra Kiat, Phra Phut, Nakhon Ratchasima, 30230",
  },
  {
    id: 303204,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "หนองงูเหลือม",
    subdistrictEn: "Nong Ngu Lueam",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30000,
    district_code: 3032,
    province_code: 19,
    name: "เฉลิมพระเกียรติ หนองงูเหลือม นครราชสีมา 30000",
    name_th: "เฉลิมพระเกียรติ หนองงูเหลือม นครราชสีมา 30000",
    name_en: "Chaloem Phra Kiat, Nong Ngu Lueam, Nakhon Ratchasima, 30000",
  },
  {
    id: 303205,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "หนองยาง",
    subdistrictEn: "Nong Yang",
    provinceTh: "นครราชสีมา",
    provinceEn: "Nakhon Ratchasima",
    zipcode: 30230,
    district_code: 3032,
    province_code: 19,
    name: "เฉลิมพระเกียรติ หนองยาง นครราชสีมา 30230",
    name_th: "เฉลิมพระเกียรติ หนองยาง นครราชสีมา 30230",
    name_en: "Chaloem Phra Kiat, Nong Yang, Nakhon Ratchasima, 30230",
  },
  {
    id: 310101,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ ในเมือง บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ ในเมือง บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Nai Mueang, Buri Ram, 31000",
  },
  {
    id: 310102,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "อิสาณ",
    subdistrictEn: "Isan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ อิสาณ บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ อิสาณ บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Isan, Buri Ram, 31000",
  },
  {
    id: 310103,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "เสม็ด",
    subdistrictEn: "Samet",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ เสม็ด บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ เสม็ด บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Samet, Buri Ram, 31000",
  },
  {
    id: 310104,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "บ้านบัว",
    subdistrictEn: "Ban Bua",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ บ้านบัว บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ บ้านบัว บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Ban Bua, Buri Ram, 31000",
  },
  {
    id: 310105,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "สะแกโพรง",
    subdistrictEn: "Sakae Phrong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ สะแกโพรง บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ สะแกโพรง บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Sakae Phrong, Buri Ram, 31000",
  },
  {
    id: 310106,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "สวายจีก",
    subdistrictEn: "Sawai Chik",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ สวายจีก บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ สวายจีก บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Sawai Chik, Buri Ram, 31000",
  },
  {
    id: 310108,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ บ้านยาง บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ บ้านยาง บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Ban Yang, Buri Ram, 31000",
  },
  {
    id: 310112,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "พระครู",
    subdistrictEn: "Phra Khru",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ พระครู บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ พระครู บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Phra Khru, Buri Ram, 31000",
  },
  {
    id: 310113,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "ถลุงเหล็ก",
    subdistrictEn: "Thalung Lek",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ ถลุงเหล็ก บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ ถลุงเหล็ก บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Thalung Lek, Buri Ram, 31000",
  },
  {
    id: 310114,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "หนองตาด",
    subdistrictEn: "Nong Tat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ หนองตาด บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ หนองตาด บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Nong Tat, Buri Ram, 31000",
  },
  {
    id: 310117,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "ลุมปุ๊ก",
    subdistrictEn: "Lumpuk",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ ลุมปุ๊ก บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ ลุมปุ๊ก บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Lumpuk, Buri Ram, 31000",
  },
  {
    id: 310118,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "สองห้อง",
    subdistrictEn: "Song Hong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ สองห้อง บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ สองห้อง บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Song Hong, Buri Ram, 31000",
  },
  {
    id: 310119,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "บัวทอง",
    subdistrictEn: "Bua Thong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ บัวทอง บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ บัวทอง บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Bua Thong, Buri Ram, 31000",
  },
  {
    id: 310120,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "ชุมเห็ด",
    subdistrictEn: "Chum Het",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ ชุมเห็ด บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ ชุมเห็ด บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Chum Het, Buri Ram, 31000",
  },
  {
    id: 310122,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "หลักเขต",
    subdistrictEn: "Lak Khet",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ หลักเขต บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ หลักเขต บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Lak Khet, Buri Ram, 31000",
  },
  {
    id: 310125,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "สะแกซำ",
    subdistrictEn: "Sakae Sam",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ สะแกซำ บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ สะแกซำ บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Sakae Sam, Buri Ram, 31000",
  },
  {
    id: 310126,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "กลันทา",
    subdistrictEn: "Kalantha",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ กลันทา บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ กลันทา บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Kalantha, Buri Ram, 31000",
  },
  {
    id: 310127,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "กระสัง",
    subdistrictEn: "Krasang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ กระสัง บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ กระสัง บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Krasang, Buri Ram, 31000",
  },
  {
    id: 310128,
    districtTh: "เมืองบุรีรัมย์",
    districtEn: "Mueang Buri Ram",
    subdistrictTh: "เมืองฝาง",
    subdistrictEn: "Mueang Fang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3101,
    province_code: 20,
    name: "เมืองบุรีรัมย์ เมืองฝาง บุรีรัมย์ 31000",
    name_th: "เมืองบุรีรัมย์ เมืองฝาง บุรีรัมย์ 31000",
    name_en: "Mueang Buri Ram, Mueang Fang, Buri Ram, 31000",
  },
  {
    id: 310201,
    districtTh: "คูเมือง",
    districtEn: "Khu Mueang",
    subdistrictTh: "คูเมือง",
    subdistrictEn: "Khu Mueang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31190,
    district_code: 3102,
    province_code: 20,
    name: "คูเมือง คูเมือง บุรีรัมย์ 31190",
    name_th: "คูเมือง คูเมือง บุรีรัมย์ 31190",
    name_en: "Khu Mueang, Khu Mueang, Buri Ram, 31190",
  },
  {
    id: 310202,
    districtTh: "คูเมือง",
    districtEn: "Khu Mueang",
    subdistrictTh: "ปะเคียบ",
    subdistrictEn: "Pakhiap",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31190,
    district_code: 3102,
    province_code: 20,
    name: "คูเมือง ปะเคียบ บุรีรัมย์ 31190",
    name_th: "คูเมือง ปะเคียบ บุรีรัมย์ 31190",
    name_en: "Khu Mueang, Pakhiap, Buri Ram, 31190",
  },
  {
    id: 310203,
    districtTh: "คูเมือง",
    districtEn: "Khu Mueang",
    subdistrictTh: "บ้านแพ",
    subdistrictEn: "Ban Phae",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31190,
    district_code: 3102,
    province_code: 20,
    name: "คูเมือง บ้านแพ บุรีรัมย์ 31190",
    name_th: "คูเมือง บ้านแพ บุรีรัมย์ 31190",
    name_en: "Khu Mueang, Ban Phae, Buri Ram, 31190",
  },
  {
    id: 310204,
    districtTh: "คูเมือง",
    districtEn: "Khu Mueang",
    subdistrictTh: "พรสำราญ",
    subdistrictEn: "Phon Samran",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31190,
    district_code: 3102,
    province_code: 20,
    name: "คูเมือง พรสำราญ บุรีรัมย์ 31190",
    name_th: "คูเมือง พรสำราญ บุรีรัมย์ 31190",
    name_en: "Khu Mueang, Phon Samran, Buri Ram, 31190",
  },
  {
    id: 310205,
    districtTh: "คูเมือง",
    districtEn: "Khu Mueang",
    subdistrictTh: "หินเหล็กไฟ",
    subdistrictEn: "Hin Lek Fai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31190,
    district_code: 3102,
    province_code: 20,
    name: "คูเมือง หินเหล็กไฟ บุรีรัมย์ 31190",
    name_th: "คูเมือง หินเหล็กไฟ บุรีรัมย์ 31190",
    name_en: "Khu Mueang, Hin Lek Fai, Buri Ram, 31190",
  },
  {
    id: 310206,
    districtTh: "คูเมือง",
    districtEn: "Khu Mueang",
    subdistrictTh: "ตูมใหญ่",
    subdistrictEn: "Tum Yai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31190,
    district_code: 3102,
    province_code: 20,
    name: "คูเมือง ตูมใหญ่ บุรีรัมย์ 31190",
    name_th: "คูเมือง ตูมใหญ่ บุรีรัมย์ 31190",
    name_en: "Khu Mueang, Tum Yai, Buri Ram, 31190",
  },
  {
    id: 310207,
    districtTh: "คูเมือง",
    districtEn: "Khu Mueang",
    subdistrictTh: "หนองขมาร",
    subdistrictEn: "Nong Khaman",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31190,
    district_code: 3102,
    province_code: 20,
    name: "คูเมือง หนองขมาร บุรีรัมย์ 31190",
    name_th: "คูเมือง หนองขมาร บุรีรัมย์ 31190",
    name_en: "Khu Mueang, Nong Khaman, Buri Ram, 31190",
  },
  {
    id: 310301,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "กระสัง",
    subdistrictEn: "Krasang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง กระสัง บุรีรัมย์ 31160",
    name_th: "กระสัง กระสัง บุรีรัมย์ 31160",
    name_en: "Krasang, Krasang, Buri Ram, 31160",
  },
  {
    id: 310302,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "ลำดวน",
    subdistrictEn: "Lamduan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง ลำดวน บุรีรัมย์ 31160",
    name_th: "กระสัง ลำดวน บุรีรัมย์ 31160",
    name_en: "Krasang, Lamduan, Buri Ram, 31160",
  },
  {
    id: 310303,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "สองชั้น",
    subdistrictEn: "Song Chan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง สองชั้น บุรีรัมย์ 31160",
    name_th: "กระสัง สองชั้น บุรีรัมย์ 31160",
    name_en: "Krasang, Song Chan, Buri Ram, 31160",
  },
  {
    id: 310304,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "สูงเนิน",
    subdistrictEn: "Sung Noen",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง สูงเนิน บุรีรัมย์ 31160",
    name_th: "กระสัง สูงเนิน บุรีรัมย์ 31160",
    name_en: "Krasang, Sung Noen, Buri Ram, 31160",
  },
  {
    id: 310305,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "หนองเต็ง",
    subdistrictEn: "Nong Teng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง หนองเต็ง บุรีรัมย์ 31160",
    name_th: "กระสัง หนองเต็ง บุรีรัมย์ 31160",
    name_en: "Krasang, Nong Teng, Buri Ram, 31160",
  },
  {
    id: 310306,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "เมืองไผ่",
    subdistrictEn: "Mueang Phai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง เมืองไผ่ บุรีรัมย์ 31160",
    name_th: "กระสัง เมืองไผ่ บุรีรัมย์ 31160",
    name_en: "Krasang, Mueang Phai, Buri Ram, 31160",
  },
  {
    id: 310307,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "ชุมแสง",
    subdistrictEn: "Chum Saeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง ชุมแสง บุรีรัมย์ 31160",
    name_th: "กระสัง ชุมแสง บุรีรัมย์ 31160",
    name_en: "Krasang, Chum Saeng, Buri Ram, 31160",
  },
  {
    id: 310308,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "บ้านปรือ",
    subdistrictEn: "Ban Prue",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง บ้านปรือ บุรีรัมย์ 31160",
    name_th: "กระสัง บ้านปรือ บุรีรัมย์ 31160",
    name_en: "Krasang, Ban Prue, Buri Ram, 31160",
  },
  {
    id: 310309,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "ห้วยสำราญ",
    subdistrictEn: "Huai Samran",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง ห้วยสำราญ บุรีรัมย์ 31160",
    name_th: "กระสัง ห้วยสำราญ บุรีรัมย์ 31160",
    name_en: "Krasang, Huai Samran, Buri Ram, 31160",
  },
  {
    id: 310310,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "กันทรารมย์",
    subdistrictEn: "Kanthararom",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง กันทรารมย์ บุรีรัมย์ 31160",
    name_th: "กระสัง กันทรารมย์ บุรีรัมย์ 31160",
    name_en: "Krasang, Kanthararom, Buri Ram, 31160",
  },
  {
    id: 310311,
    districtTh: "กระสัง",
    districtEn: "Krasang",
    subdistrictTh: "ศรีภูมิ",
    subdistrictEn: "Si Phum",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31160,
    district_code: 3103,
    province_code: 20,
    name: "กระสัง ศรีภูมิ บุรีรัมย์ 31160",
    name_th: "กระสัง ศรีภูมิ บุรีรัมย์ 31160",
    name_en: "Krasang, Si Phum, Buri Ram, 31160",
  },
  {
    id: 310401,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "นางรอง",
    subdistrictEn: "Nang Rong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง นางรอง บุรีรัมย์ 31110",
    name_th: "นางรอง นางรอง บุรีรัมย์ 31110",
    name_en: "Nang Rong, Nang Rong, Buri Ram, 31110",
  },
  {
    id: 310403,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "สะเดา",
    subdistrictEn: "Sadao",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง สะเดา บุรีรัมย์ 31110",
    name_th: "นางรอง สะเดา บุรีรัมย์ 31110",
    name_en: "Nang Rong, Sadao, Buri Ram, 31110",
  },
  {
    id: 310405,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "ชุมแสง",
    subdistrictEn: "Chum Saeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง ชุมแสง บุรีรัมย์ 31110",
    name_th: "นางรอง ชุมแสง บุรีรัมย์ 31110",
    name_en: "Nang Rong, Chum Saeng, Buri Ram, 31110",
  },
  {
    id: 310406,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "หนองโบสถ์",
    subdistrictEn: "Nong Bot",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง หนองโบสถ์ บุรีรัมย์ 31110",
    name_th: "นางรอง หนองโบสถ์ บุรีรัมย์ 31110",
    name_en: "Nang Rong, Nong Bot, Buri Ram, 31110",
  },
  {
    id: 310408,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "หนองกง",
    subdistrictEn: "Nong Kong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง หนองกง บุรีรัมย์ 31110",
    name_th: "นางรอง หนองกง บุรีรัมย์ 31110",
    name_en: "Nang Rong, Nong Kong, Buri Ram, 31110",
  },
  {
    id: 310413,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "ถนนหัก",
    subdistrictEn: "Thanon Hak",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง ถนนหัก บุรีรัมย์ 31110",
    name_th: "นางรอง ถนนหัก บุรีรัมย์ 31110",
    name_en: "Nang Rong, Thanon Hak, Buri Ram, 31110",
  },
  {
    id: 310414,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "หนองไทร",
    subdistrictEn: "Nong Sai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง หนองไทร บุรีรัมย์ 31110",
    name_th: "นางรอง หนองไทร บุรีรัมย์ 31110",
    name_en: "Nang Rong, Nong Sai, Buri Ram, 31110",
  },
  {
    id: 310415,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "ก้านเหลือง",
    subdistrictEn: "Kan Lueang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง ก้านเหลือง บุรีรัมย์ 31110",
    name_th: "นางรอง ก้านเหลือง บุรีรัมย์ 31110",
    name_en: "Nang Rong, Kan Lueang, Buri Ram, 31110",
  },
  {
    id: 310416,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "บ้านสิงห์",
    subdistrictEn: "Ban Sing",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง บ้านสิงห์ บุรีรัมย์ 31110",
    name_th: "นางรอง บ้านสิงห์ บุรีรัมย์ 31110",
    name_en: "Nang Rong, Ban Sing, Buri Ram, 31110",
  },
  {
    id: 310417,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "ลำไทรโยง",
    subdistrictEn: "Lam Sai Yong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง ลำไทรโยง บุรีรัมย์ 31110",
    name_th: "นางรอง ลำไทรโยง บุรีรัมย์ 31110",
    name_en: "Nang Rong, Lam Sai Yong, Buri Ram, 31110",
  },
  {
    id: 310418,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "ทรัพย์พระยา",
    subdistrictEn: "Sap Phraya",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง ทรัพย์พระยา บุรีรัมย์ 31110",
    name_th: "นางรอง ทรัพย์พระยา บุรีรัมย์ 31110",
    name_en: "Nang Rong, Sap Phraya, Buri Ram, 31110",
  },
  {
    id: 310424,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "หนองยายพิมพ์",
    subdistrictEn: "Nong Yai Phim",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง หนองยายพิมพ์ บุรีรัมย์ 31110",
    name_th: "นางรอง หนองยายพิมพ์ บุรีรัมย์ 31110",
    name_en: "Nang Rong, Nong Yai Phim, Buri Ram, 31110",
  },
  {
    id: 310425,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "หัวถนน",
    subdistrictEn: "Hua Thanon",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง หัวถนน บุรีรัมย์ 31110",
    name_th: "นางรอง หัวถนน บุรีรัมย์ 31110",
    name_en: "Nang Rong, Hua Thanon, Buri Ram, 31110",
  },
  {
    id: 310426,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "ทุ่งแสงทอง",
    subdistrictEn: "Thung Saeng Thong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง ทุ่งแสงทอง บุรีรัมย์ 31110",
    name_th: "นางรอง ทุ่งแสงทอง บุรีรัมย์ 31110",
    name_en: "Nang Rong, Thung Saeng Thong, Buri Ram, 31110",
  },
  {
    id: 310427,
    districtTh: "นางรอง",
    districtEn: "Nang Rong",
    subdistrictTh: "หนองโสน",
    subdistrictEn: "Nong Sano",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3104,
    province_code: 20,
    name: "นางรอง หนองโสน บุรีรัมย์ 31110",
    name_th: "นางรอง หนองโสน บุรีรัมย์ 31110",
    name_en: "Nang Rong, Nong Sano, Buri Ram, 31110",
  },
  {
    id: 310501,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "หนองกี่",
    subdistrictEn: "Nong Ki",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ หนองกี่ บุรีรัมย์ 31210",
    name_th: "หนองกี่ หนองกี่ บุรีรัมย์ 31210",
    name_en: "Nong Ki, Nong Ki, Buri Ram, 31210",
  },
  {
    id: 310502,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "เย้ยปราสาท",
    subdistrictEn: "Yoei Prasat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ เย้ยปราสาท บุรีรัมย์ 31210",
    name_th: "หนองกี่ เย้ยปราสาท บุรีรัมย์ 31210",
    name_en: "Nong Ki, Yoei Prasat, Buri Ram, 31210",
  },
  {
    id: 310503,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "เมืองไผ่",
    subdistrictEn: "Mueang Phai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ เมืองไผ่ บุรีรัมย์ 31210",
    name_th: "หนองกี่ เมืองไผ่ บุรีรัมย์ 31210",
    name_en: "Nong Ki, Mueang Phai, Buri Ram, 31210",
  },
  {
    id: 310504,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "ดอนอะราง",
    subdistrictEn: "Don Arang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ ดอนอะราง บุรีรัมย์ 31210",
    name_th: "หนองกี่ ดอนอะราง บุรีรัมย์ 31210",
    name_en: "Nong Ki, Don Arang, Buri Ram, 31210",
  },
  {
    id: 310505,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "โคกสว่าง",
    subdistrictEn: "Khok Sawang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ โคกสว่าง บุรีรัมย์ 31210",
    name_th: "หนองกี่ โคกสว่าง บุรีรัมย์ 31210",
    name_en: "Nong Ki, Khok Sawang, Buri Ram, 31210",
  },
  {
    id: 310506,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "ทุ่งกระตาดพัฒนา",
    subdistrictEn: "Thung Kratat Phatthana",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ ทุ่งกระตาดพัฒนา บุรีรัมย์ 31210",
    name_th: "หนองกี่ ทุ่งกระตาดพัฒนา บุรีรัมย์ 31210",
    name_en: "Nong Ki, Thung Kratat Phatthana, Buri Ram, 31210",
  },
  {
    id: 310507,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "ทุ่งกระเต็น",
    subdistrictEn: "Thung Kraten",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ ทุ่งกระเต็น บุรีรัมย์ 31210",
    name_th: "หนองกี่ ทุ่งกระเต็น บุรีรัมย์ 31210",
    name_en: "Nong Ki, Thung Kraten, Buri Ram, 31210",
  },
  {
    id: 310508,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "ท่าโพธิ์ชัย",
    subdistrictEn: "Tha Pho Chai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ ท่าโพธิ์ชัย บุรีรัมย์ 31210",
    name_th: "หนองกี่ ท่าโพธิ์ชัย บุรีรัมย์ 31210",
    name_en: "Nong Ki, Tha Pho Chai, Buri Ram, 31210",
  },
  {
    id: 310509,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "โคกสูง",
    subdistrictEn: "Khok Sung",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ โคกสูง บุรีรัมย์ 31210",
    name_th: "หนองกี่ โคกสูง บุรีรัมย์ 31210",
    name_en: "Nong Ki, Khok Sung, Buri Ram, 31210",
  },
  {
    id: 310510,
    districtTh: "หนองกี่",
    districtEn: "Nong Ki",
    subdistrictTh: "บุกระสัง",
    subdistrictEn: "Bu Krasang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31210,
    district_code: 3105,
    province_code: 20,
    name: "หนองกี่ บุกระสัง บุรีรัมย์ 31210",
    name_th: "หนองกี่ บุกระสัง บุรีรัมย์ 31210",
    name_en: "Nong Ki, Bu Krasang, Buri Ram, 31210",
  },
  {
    id: 310601,
    districtTh: "ละหานทราย",
    districtEn: "Lahan Sai",
    subdistrictTh: "ละหานทราย",
    subdistrictEn: "Lahan Sai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31170,
    district_code: 3106,
    province_code: 20,
    name: "ละหานทราย ละหานทราย บุรีรัมย์ 31170",
    name_th: "ละหานทราย ละหานทราย บุรีรัมย์ 31170",
    name_en: "Lahan Sai, Lahan Sai, Buri Ram, 31170",
  },
  {
    id: 310603,
    districtTh: "ละหานทราย",
    districtEn: "Lahan Sai",
    subdistrictTh: "ตาจง",
    subdistrictEn: "Ta Chong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31170,
    district_code: 3106,
    province_code: 20,
    name: "ละหานทราย ตาจง บุรีรัมย์ 31170",
    name_th: "ละหานทราย ตาจง บุรีรัมย์ 31170",
    name_en: "Lahan Sai, Ta Chong, Buri Ram, 31170",
  },
  {
    id: 310604,
    districtTh: "ละหานทราย",
    districtEn: "Lahan Sai",
    subdistrictTh: "สำโรงใหม่",
    subdistrictEn: "Samrong Mai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31170,
    district_code: 3106,
    province_code: 20,
    name: "ละหานทราย สำโรงใหม่ บุรีรัมย์ 31170",
    name_th: "ละหานทราย สำโรงใหม่ บุรีรัมย์ 31170",
    name_en: "Lahan Sai, Samrong Mai, Buri Ram, 31170",
  },
  {
    id: 310607,
    districtTh: "ละหานทราย",
    districtEn: "Lahan Sai",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31170,
    district_code: 3106,
    province_code: 20,
    name: "ละหานทราย หนองแวง บุรีรัมย์ 31170",
    name_th: "ละหานทราย หนองแวง บุรีรัมย์ 31170",
    name_en: "Lahan Sai, Nong Waeng, Buri Ram, 31170",
  },
  {
    id: 310610,
    districtTh: "ละหานทราย",
    districtEn: "Lahan Sai",
    subdistrictTh: "หนองตะครอง",
    subdistrictEn: "Nong Trakhrong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31170,
    district_code: 3106,
    province_code: 20,
    name: "ละหานทราย หนองตะครอง บุรีรัมย์ 31170",
    name_th: "ละหานทราย หนองตะครอง บุรีรัมย์ 31170",
    name_en: "Lahan Sai, Nong Trakhrong, Buri Ram, 31170",
  },
  {
    id: 310611,
    districtTh: "ละหานทราย",
    districtEn: "Lahan Sai",
    subdistrictTh: "โคกว่าน",
    subdistrictEn: "Khok Wan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31170,
    district_code: 3106,
    province_code: 20,
    name: "ละหานทราย โคกว่าน บุรีรัมย์ 31170",
    name_th: "ละหานทราย โคกว่าน บุรีรัมย์ 31170",
    name_en: "Lahan Sai, Khok Wan, Buri Ram, 31170",
  },
  {
    id: 310701,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "ประโคนชัย",
    subdistrictEn: "Prakhon Chai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย ประโคนชัย บุรีรัมย์ 31140",
    name_th: "ประโคนชัย ประโคนชัย บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Prakhon Chai, Buri Ram, 31140",
  },
  {
    id: 310702,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "แสลงโทน",
    subdistrictEn: "Salaeng Thon",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย แสลงโทน บุรีรัมย์ 31140",
    name_th: "ประโคนชัย แสลงโทน บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Salaeng Thon, Buri Ram, 31140",
  },
  {
    id: 310703,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "บ้านไทร",
    subdistrictEn: "Ban Sai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย บ้านไทร บุรีรัมย์ 31140",
    name_th: "ประโคนชัย บ้านไทร บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Ban Sai, Buri Ram, 31140",
  },
  {
    id: 310705,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "ละเวี้ย",
    subdistrictEn: "Lawia",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย ละเวี้ย บุรีรัมย์ 31140",
    name_th: "ประโคนชัย ละเวี้ย บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Lawia, Buri Ram, 31140",
  },
  {
    id: 310706,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "จรเข้มาก",
    subdistrictEn: "Chorakhe Mak",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย จรเข้มาก บุรีรัมย์ 31140",
    name_th: "ประโคนชัย จรเข้มาก บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Chorakhe Mak, Buri Ram, 31140",
  },
  {
    id: 310707,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "ปังกู",
    subdistrictEn: "Pang Ku",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย ปังกู บุรีรัมย์ 31140",
    name_th: "ประโคนชัย ปังกู บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Pang Ku, Buri Ram, 31140",
  },
  {
    id: 310708,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "โคกย่าง",
    subdistrictEn: "Khok Yang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย โคกย่าง บุรีรัมย์ 31140",
    name_th: "ประโคนชัย โคกย่าง บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Khok Yang, Buri Ram, 31140",
  },
  {
    id: 310710,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "โคกม้า",
    subdistrictEn: "Khok Ma",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย โคกม้า บุรีรัมย์ 31140",
    name_th: "ประโคนชัย โคกม้า บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Khok Ma, Buri Ram, 31140",
  },
  {
    id: 310713,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "ไพศาล",
    subdistrictEn: "Phaisan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย ไพศาล บุรีรัมย์ 31140",
    name_th: "ประโคนชัย ไพศาล บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Phaisan, Buri Ram, 31140",
  },
  {
    id: 310714,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "ตะโกตาพิ",
    subdistrictEn: "Tako Taphi",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย ตะโกตาพิ บุรีรัมย์ 31140",
    name_th: "ประโคนชัย ตะโกตาพิ บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Tako Taphi, Buri Ram, 31140",
  },
  {
    id: 310715,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "เขาคอก",
    subdistrictEn: "Khao Khok",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย เขาคอก บุรีรัมย์ 31140",
    name_th: "ประโคนชัย เขาคอก บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Khao Khok, Buri Ram, 31140",
  },
  {
    id: 310716,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "หนองบอน",
    subdistrictEn: "Nong Bon",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย หนองบอน บุรีรัมย์ 31140",
    name_th: "ประโคนชัย หนองบอน บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Nong Bon, Buri Ram, 31140",
  },
  {
    id: 310718,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "โคกมะขาม",
    subdistrictEn: "Khok Makham",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย โคกมะขาม บุรีรัมย์ 31140",
    name_th: "ประโคนชัย โคกมะขาม บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Khok Makham, Buri Ram, 31140",
  },
  {
    id: 310719,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "โคกตูม",
    subdistrictEn: "Khok Tum",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย โคกตูม บุรีรัมย์ 31140",
    name_th: "ประโคนชัย โคกตูม บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Khok Tum, Buri Ram, 31140",
  },
  {
    id: 310720,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "ประทัดบุ",
    subdistrictEn: "Prathat Bu",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย ประทัดบุ บุรีรัมย์ 31140",
    name_th: "ประโคนชัย ประทัดบุ บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Prathat Bu, Buri Ram, 31140",
  },
  {
    id: 310721,
    districtTh: "ประโคนชัย",
    districtEn: "Prakhon Chai",
    subdistrictTh: "สี่เหลี่ยม",
    subdistrictEn: "Si Liam",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31140,
    district_code: 3107,
    province_code: 20,
    name: "ประโคนชัย สี่เหลี่ยม บุรีรัมย์ 31140",
    name_th: "ประโคนชัย สี่เหลี่ยม บุรีรัมย์ 31140",
    name_en: "Prakhon Chai, Si Liam, Buri Ram, 31140",
  },
  {
    id: 310801,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "บ้านกรวด",
    subdistrictEn: "Ban Kruat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด บ้านกรวด บุรีรัมย์ 31180",
    name_th: "บ้านกรวด บ้านกรวด บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Ban Kruat, Buri Ram, 31180",
  },
  {
    id: 310802,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "โนนเจริญ",
    subdistrictEn: "Non Charoen",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด โนนเจริญ บุรีรัมย์ 31180",
    name_th: "บ้านกรวด โนนเจริญ บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Non Charoen, Buri Ram, 31180",
  },
  {
    id: 310803,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "หนองไม้งาม",
    subdistrictEn: "Nong Mai Ngam",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด หนองไม้งาม บุรีรัมย์ 31180",
    name_th: "บ้านกรวด หนองไม้งาม บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Nong Mai Ngam, Buri Ram, 31180",
  },
  {
    id: 310804,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "ปราสาท",
    subdistrictEn: "Prasat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด ปราสาท บุรีรัมย์ 31180",
    name_th: "บ้านกรวด ปราสาท บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Prasat, Buri Ram, 31180",
  },
  {
    id: 310805,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "สายตะกู",
    subdistrictEn: "Sai Taku",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด สายตะกู บุรีรัมย์ 31180",
    name_th: "บ้านกรวด สายตะกู บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Sai Taku, Buri Ram, 31180",
  },
  {
    id: 310806,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "หินลาด",
    subdistrictEn: "Hin Lat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด หินลาด บุรีรัมย์ 31180",
    name_th: "บ้านกรวด หินลาด บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Hin Lat, Buri Ram, 31180",
  },
  {
    id: 310807,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "บึงเจริญ",
    subdistrictEn: "Bueng Charoen",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด บึงเจริญ บุรีรัมย์ 31180",
    name_th: "บ้านกรวด บึงเจริญ บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Bueng Charoen, Buri Ram, 31180",
  },
  {
    id: 310808,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "จันทบเพชร",
    subdistrictEn: "Chanthop Phet",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด จันทบเพชร บุรีรัมย์ 31180",
    name_th: "บ้านกรวด จันทบเพชร บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Chanthop Phet, Buri Ram, 31180",
  },
  {
    id: 310809,
    districtTh: "บ้านกรวด",
    districtEn: "Ban Kruat",
    subdistrictTh: "เขาดินเหนือ",
    subdistrictEn: "Khao Din Nuea",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31180,
    district_code: 3108,
    province_code: 20,
    name: "บ้านกรวด เขาดินเหนือ บุรีรัมย์ 31180",
    name_th: "บ้านกรวด เขาดินเหนือ บุรีรัมย์ 31180",
    name_en: "Ban Kruat, Khao Din Nuea, Buri Ram, 31180",
  },
  {
    id: 310901,
    districtTh: "พุทไธสง",
    districtEn: "Phutthaisong",
    subdistrictTh: "พุทไธสง",
    subdistrictEn: "Phutthaisong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3109,
    province_code: 20,
    name: "พุทไธสง พุทไธสง บุรีรัมย์ 31120",
    name_th: "พุทไธสง พุทไธสง บุรีรัมย์ 31120",
    name_en: "Phutthaisong, Phutthaisong, Buri Ram, 31120",
  },
  {
    id: 310902,
    districtTh: "พุทไธสง",
    districtEn: "Phutthaisong",
    subdistrictTh: "มะเฟือง",
    subdistrictEn: "Mafueang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3109,
    province_code: 20,
    name: "พุทไธสง มะเฟือง บุรีรัมย์ 31120",
    name_th: "พุทไธสง มะเฟือง บุรีรัมย์ 31120",
    name_en: "Phutthaisong, Mafueang, Buri Ram, 31120",
  },
  {
    id: 310903,
    districtTh: "พุทไธสง",
    districtEn: "Phutthaisong",
    subdistrictTh: "บ้านจาน",
    subdistrictEn: "Ban Chan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3109,
    province_code: 20,
    name: "พุทไธสง บ้านจาน บุรีรัมย์ 31120",
    name_th: "พุทไธสง บ้านจาน บุรีรัมย์ 31120",
    name_en: "Phutthaisong, Ban Chan, Buri Ram, 31120",
  },
  {
    id: 310906,
    districtTh: "พุทไธสง",
    districtEn: "Phutthaisong",
    subdistrictTh: "บ้านเป้า",
    subdistrictEn: "Ban Pao",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3109,
    province_code: 20,
    name: "พุทไธสง บ้านเป้า บุรีรัมย์ 31120",
    name_th: "พุทไธสง บ้านเป้า บุรีรัมย์ 31120",
    name_en: "Phutthaisong, Ban Pao, Buri Ram, 31120",
  },
  {
    id: 310907,
    districtTh: "พุทไธสง",
    districtEn: "Phutthaisong",
    subdistrictTh: "บ้านแวง",
    subdistrictEn: "Ban Waeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3109,
    province_code: 20,
    name: "พุทไธสง บ้านแวง บุรีรัมย์ 31120",
    name_th: "พุทไธสง บ้านแวง บุรีรัมย์ 31120",
    name_en: "Phutthaisong, Ban Waeng, Buri Ram, 31120",
  },
  {
    id: 310909,
    districtTh: "พุทไธสง",
    districtEn: "Phutthaisong",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3109,
    province_code: 20,
    name: "พุทไธสง บ้านยาง บุรีรัมย์ 31120",
    name_th: "พุทไธสง บ้านยาง บุรีรัมย์ 31120",
    name_en: "Phutthaisong, Ban Yang, Buri Ram, 31120",
  },
  {
    id: 310910,
    districtTh: "พุทไธสง",
    districtEn: "Phutthaisong",
    subdistrictTh: "หายโศก",
    subdistrictEn: "Hai Sok",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3109,
    province_code: 20,
    name: "พุทไธสง หายโศก บุรีรัมย์ 31120",
    name_th: "พุทไธสง หายโศก บุรีรัมย์ 31120",
    name_en: "Phutthaisong, Hai Sok, Buri Ram, 31120",
  },
  {
    id: 311001,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "ลำปลายมาศ",
    subdistrictEn: "Lam Plai Mat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ ลำปลายมาศ บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ ลำปลายมาศ บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Lam Plai Mat, Buri Ram, 31130",
  },
  {
    id: 311002,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "หนองคู",
    subdistrictEn: "Nong Khu",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ หนองคู บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ หนองคู บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Nong Khu, Buri Ram, 31130",
  },
  {
    id: 311003,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "แสลงพัน",
    subdistrictEn: "Salaeng Phan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ แสลงพัน บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ แสลงพัน บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Salaeng Phan, Buri Ram, 31130",
  },
  {
    id: 311004,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "ทะเมนชัย",
    subdistrictEn: "Thamen Chai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ ทะเมนชัย บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ ทะเมนชัย บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Thamen Chai, Buri Ram, 31130",
  },
  {
    id: 311005,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "ตลาดโพธิ์",
    subdistrictEn: "Talat Pho",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ ตลาดโพธิ์ บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ ตลาดโพธิ์ บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Talat Pho, Buri Ram, 31130",
  },
  {
    id: 311006,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "หนองกะทิง",
    subdistrictEn: "Nong Kathing",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ หนองกะทิง บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ หนองกะทิง บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Nong Kathing, Buri Ram, 31130",
  },
  {
    id: 311007,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "โคกกลาง",
    subdistrictEn: "Khok Klang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ โคกกลาง บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ โคกกลาง บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Khok Klang, Buri Ram, 31130",
  },
  {
    id: 311008,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ โคกสะอาด บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ โคกสะอาด บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Khok Sa-at, Buri Ram, 31130",
  },
  {
    id: 311009,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "เมืองแฝก",
    subdistrictEn: "Mueang Faek",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ เมืองแฝก บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ เมืองแฝก บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Mueang Faek, Buri Ram, 31130",
  },
  {
    id: 311010,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ บ้านยาง บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ บ้านยาง บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Ban Yang, Buri Ram, 31130",
  },
  {
    id: 311011,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "ผไทรินทร์",
    subdistrictEn: "Phathairin",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ ผไทรินทร์ บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ ผไทรินทร์ บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Phathairin, Buri Ram, 31130",
  },
  {
    id: 311012,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "โคกล่าม",
    subdistrictEn: "Khok Lam",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ โคกล่าม บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ โคกล่าม บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Khok Lam, Buri Ram, 31130",
  },
  {
    id: 311013,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "หินโคน",
    subdistrictEn: "Hin Khon",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ หินโคน บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ หินโคน บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Hin Khon, Buri Ram, 31130",
  },
  {
    id: 311014,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "หนองบัวโคก",
    subdistrictEn: "Nong Bua Khok",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ หนองบัวโคก บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ หนองบัวโคก บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Nong Bua Khok, Buri Ram, 31130",
  },
  {
    id: 311015,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "บุโพธิ์",
    subdistrictEn: "Bu Pho",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ บุโพธิ์ บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ บุโพธิ์ บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Bu Pho, Buri Ram, 31130",
  },
  {
    id: 311016,
    districtTh: "ลำปลายมาศ",
    districtEn: "Lam Plai Mat",
    subdistrictTh: "หนองโดน",
    subdistrictEn: "Nong Don",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31130,
    district_code: 3110,
    province_code: 20,
    name: "ลำปลายมาศ หนองโดน บุรีรัมย์ 31130",
    name_th: "ลำปลายมาศ หนองโดน บุรีรัมย์ 31130",
    name_en: "Lam Plai Mat, Nong Don, Buri Ram, 31130",
  },
  {
    id: 311101,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "สตึก",
    subdistrictEn: "Satuek",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก สตึก บุรีรัมย์ 31150",
    name_th: "สตึก สตึก บุรีรัมย์ 31150",
    name_en: "Satuek, Satuek, Buri Ram, 31150",
  },
  {
    id: 311102,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "นิคม",
    subdistrictEn: "Nikhom",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก นิคม บุรีรัมย์ 31150",
    name_th: "สตึก นิคม บุรีรัมย์ 31150",
    name_en: "Satuek, Nikhom, Buri Ram, 31150",
  },
  {
    id: 311103,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "ทุ่งวัง",
    subdistrictEn: "Thung Wang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก ทุ่งวัง บุรีรัมย์ 31150",
    name_th: "สตึก ทุ่งวัง บุรีรัมย์ 31150",
    name_en: "Satuek, Thung Wang, Buri Ram, 31150",
  },
  {
    id: 311104,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "เมืองแก",
    subdistrictEn: "Mueang Kae",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก เมืองแก บุรีรัมย์ 31150",
    name_th: "สตึก เมืองแก บุรีรัมย์ 31150",
    name_en: "Satuek, Mueang Kae, Buri Ram, 31150",
  },
  {
    id: 311105,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "หนองใหญ่",
    subdistrictEn: "Nong Yai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก หนองใหญ่ บุรีรัมย์ 31150",
    name_th: "สตึก หนองใหญ่ บุรีรัมย์ 31150",
    name_en: "Satuek, Nong Yai, Buri Ram, 31150",
  },
  {
    id: 311106,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "ร่อนทอง",
    subdistrictEn: "Ron Thong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก ร่อนทอง บุรีรัมย์ 31150",
    name_th: "สตึก ร่อนทอง บุรีรัมย์ 31150",
    name_en: "Satuek, Ron Thong, Buri Ram, 31150",
  },
  {
    id: 311109,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "ดอนมนต์",
    subdistrictEn: "Don Mon",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก ดอนมนต์ บุรีรัมย์ 31150",
    name_th: "สตึก ดอนมนต์ บุรีรัมย์ 31150",
    name_en: "Satuek, Don Mon, Buri Ram, 31150",
  },
  {
    id: 311110,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "ชุมแสง",
    subdistrictEn: "Chum Saeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก ชุมแสง บุรีรัมย์ 31150",
    name_th: "สตึก ชุมแสง บุรีรัมย์ 31150",
    name_en: "Satuek, Chum Saeng, Buri Ram, 31150",
  },
  {
    id: 311111,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "ท่าม่วง",
    subdistrictEn: "Tha Muang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก ท่าม่วง บุรีรัมย์ 31150",
    name_th: "สตึก ท่าม่วง บุรีรัมย์ 31150",
    name_en: "Satuek, Tha Muang, Buri Ram, 31150",
  },
  {
    id: 311112,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "สะแก",
    subdistrictEn: "Sakae",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก สะแก บุรีรัมย์ 31150",
    name_th: "สตึก สะแก บุรีรัมย์ 31150",
    name_en: "Satuek, Sakae, Buri Ram, 31150",
  },
  {
    id: 311114,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "สนามชัย",
    subdistrictEn: "Sanam Chai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก สนามชัย บุรีรัมย์ 31150",
    name_th: "สตึก สนามชัย บุรีรัมย์ 31150",
    name_en: "Satuek, Sanam Chai, Buri Ram, 31150",
  },
  {
    id: 311115,
    districtTh: "สตึก",
    districtEn: "Satuek",
    subdistrictTh: "กระสัง",
    subdistrictEn: "Krasang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3111,
    province_code: 20,
    name: "สตึก กระสัง บุรีรัมย์ 31150",
    name_th: "สตึก กระสัง บุรีรัมย์ 31150",
    name_en: "Satuek, Krasang, Buri Ram, 31150",
  },
  {
    id: 311201,
    districtTh: "ปะคำ",
    districtEn: "Pakham",
    subdistrictTh: "ปะคำ",
    subdistrictEn: "Pakham",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31220,
    district_code: 3112,
    province_code: 20,
    name: "ปะคำ ปะคำ บุรีรัมย์ 31220",
    name_th: "ปะคำ ปะคำ บุรีรัมย์ 31220",
    name_en: "Pakham, Pakham, Buri Ram, 31220",
  },
  {
    id: 311202,
    districtTh: "ปะคำ",
    districtEn: "Pakham",
    subdistrictTh: "ไทยเจริญ",
    subdistrictEn: "Thai Charoen",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31220,
    district_code: 3112,
    province_code: 20,
    name: "ปะคำ ไทยเจริญ บุรีรัมย์ 31220",
    name_th: "ปะคำ ไทยเจริญ บุรีรัมย์ 31220",
    name_en: "Pakham, Thai Charoen, Buri Ram, 31220",
  },
  {
    id: 311203,
    districtTh: "ปะคำ",
    districtEn: "Pakham",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31220,
    district_code: 3112,
    province_code: 20,
    name: "ปะคำ หนองบัว บุรีรัมย์ 31220",
    name_th: "ปะคำ หนองบัว บุรีรัมย์ 31220",
    name_en: "Pakham, Nong Bua, Buri Ram, 31220",
  },
  {
    id: 311204,
    districtTh: "ปะคำ",
    districtEn: "Pakham",
    subdistrictTh: "โคกมะม่วง",
    subdistrictEn: "Khok Mamuang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31220,
    district_code: 3112,
    province_code: 20,
    name: "ปะคำ โคกมะม่วง บุรีรัมย์ 31220",
    name_th: "ปะคำ โคกมะม่วง บุรีรัมย์ 31220",
    name_en: "Pakham, Khok Mamuang, Buri Ram, 31220",
  },
  {
    id: 311205,
    districtTh: "ปะคำ",
    districtEn: "Pakham",
    subdistrictTh: "หูทำนบ",
    subdistrictEn: "Hu Thamnop",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31220,
    district_code: 3112,
    province_code: 20,
    name: "ปะคำ หูทำนบ บุรีรัมย์ 31220",
    name_th: "ปะคำ หูทำนบ บุรีรัมย์ 31220",
    name_en: "Pakham, Hu Thamnop, Buri Ram, 31220",
  },
  {
    id: 311301,
    districtTh: "นาโพธิ์",
    districtEn: "Na Pho",
    subdistrictTh: "นาโพธิ์",
    subdistrictEn: "Na Pho",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31230,
    district_code: 3113,
    province_code: 20,
    name: "นาโพธิ์ นาโพธิ์ บุรีรัมย์ 31230",
    name_th: "นาโพธิ์ นาโพธิ์ บุรีรัมย์ 31230",
    name_en: "Na Pho, Na Pho, Buri Ram, 31230",
  },
  {
    id: 311302,
    districtTh: "นาโพธิ์",
    districtEn: "Na Pho",
    subdistrictTh: "บ้านคู",
    subdistrictEn: "Ban Khu",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31230,
    district_code: 3113,
    province_code: 20,
    name: "นาโพธิ์ บ้านคู บุรีรัมย์ 31230",
    name_th: "นาโพธิ์ บ้านคู บุรีรัมย์ 31230",
    name_en: "Na Pho, Ban Khu, Buri Ram, 31230",
  },
  {
    id: 311303,
    districtTh: "นาโพธิ์",
    districtEn: "Na Pho",
    subdistrictTh: "บ้านดู่",
    subdistrictEn: "Ban Du",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31230,
    district_code: 3113,
    province_code: 20,
    name: "นาโพธิ์ บ้านดู่ บุรีรัมย์ 31230",
    name_th: "นาโพธิ์ บ้านดู่ บุรีรัมย์ 31230",
    name_en: "Na Pho, Ban Du, Buri Ram, 31230",
  },
  {
    id: 311304,
    districtTh: "นาโพธิ์",
    districtEn: "Na Pho",
    subdistrictTh: "ดอนกอก",
    subdistrictEn: "Don Kok",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31230,
    district_code: 3113,
    province_code: 20,
    name: "นาโพธิ์ ดอนกอก บุรีรัมย์ 31230",
    name_th: "นาโพธิ์ ดอนกอก บุรีรัมย์ 31230",
    name_en: "Na Pho, Don Kok, Buri Ram, 31230",
  },
  {
    id: 311305,
    districtTh: "นาโพธิ์",
    districtEn: "Na Pho",
    subdistrictTh: "ศรีสว่าง",
    subdistrictEn: "Si Sawang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31230,
    district_code: 3113,
    province_code: 20,
    name: "นาโพธิ์ ศรีสว่าง บุรีรัมย์ 31230",
    name_th: "นาโพธิ์ ศรีสว่าง บุรีรัมย์ 31230",
    name_en: "Na Pho, Si Sawang, Buri Ram, 31230",
  },
  {
    id: 311401,
    districtTh: "หนองหงส์",
    districtEn: "Nong Hong",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31240,
    district_code: 3114,
    province_code: 20,
    name: "หนองหงส์ สระแก้ว บุรีรัมย์ 31240",
    name_th: "หนองหงส์ สระแก้ว บุรีรัมย์ 31240",
    name_en: "Nong Hong, Sa Kaeo, Buri Ram, 31240",
  },
  {
    id: 311402,
    districtTh: "หนองหงส์",
    districtEn: "Nong Hong",
    subdistrictTh: "ห้วยหิน",
    subdistrictEn: "Huai Hin",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31240,
    district_code: 3114,
    province_code: 20,
    name: "หนองหงส์ ห้วยหิน บุรีรัมย์ 31240",
    name_th: "หนองหงส์ ห้วยหิน บุรีรัมย์ 31240",
    name_en: "Nong Hong, Huai Hin, Buri Ram, 31240",
  },
  {
    id: 311403,
    districtTh: "หนองหงส์",
    districtEn: "Nong Hong",
    subdistrictTh: "ไทยสามัคคี",
    subdistrictEn: "Thai Samakkhi",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31240,
    district_code: 3114,
    province_code: 20,
    name: "หนองหงส์ ไทยสามัคคี บุรีรัมย์ 31240",
    name_th: "หนองหงส์ ไทยสามัคคี บุรีรัมย์ 31240",
    name_en: "Nong Hong, Thai Samakkhi, Buri Ram, 31240",
  },
  {
    id: 311404,
    districtTh: "หนองหงส์",
    districtEn: "Nong Hong",
    subdistrictTh: "หนองชัยศรี",
    subdistrictEn: "Nong Chai Si",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31240,
    district_code: 3114,
    province_code: 20,
    name: "หนองหงส์ หนองชัยศรี บุรีรัมย์ 31240",
    name_th: "หนองหงส์ หนองชัยศรี บุรีรัมย์ 31240",
    name_en: "Nong Hong, Nong Chai Si, Buri Ram, 31240",
  },
  {
    id: 311405,
    districtTh: "หนองหงส์",
    districtEn: "Nong Hong",
    subdistrictTh: "เสาเดียว",
    subdistrictEn: "Sao Diao",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31240,
    district_code: 3114,
    province_code: 20,
    name: "หนองหงส์ เสาเดียว บุรีรัมย์ 31240",
    name_th: "หนองหงส์ เสาเดียว บุรีรัมย์ 31240",
    name_en: "Nong Hong, Sao Diao, Buri Ram, 31240",
  },
  {
    id: 311406,
    districtTh: "หนองหงส์",
    districtEn: "Nong Hong",
    subdistrictTh: "เมืองฝ้าย",
    subdistrictEn: "Mueang Fai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31240,
    district_code: 3114,
    province_code: 20,
    name: "หนองหงส์ เมืองฝ้าย บุรีรัมย์ 31240",
    name_th: "หนองหงส์ เมืองฝ้าย บุรีรัมย์ 31240",
    name_en: "Nong Hong, Mueang Fai, Buri Ram, 31240",
  },
  {
    id: 311407,
    districtTh: "หนองหงส์",
    districtEn: "Nong Hong",
    subdistrictTh: "สระทอง",
    subdistrictEn: "Sa Thong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31240,
    district_code: 3114,
    province_code: 20,
    name: "หนองหงส์ สระทอง บุรีรัมย์ 31240",
    name_th: "หนองหงส์ สระทอง บุรีรัมย์ 31240",
    name_en: "Nong Hong, Sa Thong, Buri Ram, 31240",
  },
  {
    id: 311501,
    districtTh: "พลับพลาชัย",
    districtEn: "Phlapphla Chai",
    subdistrictTh: "จันดุม",
    subdistrictEn: "Chan Dum",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31250,
    district_code: 3115,
    province_code: 20,
    name: "พลับพลาชัย จันดุม บุรีรัมย์ 31250",
    name_th: "พลับพลาชัย จันดุม บุรีรัมย์ 31250",
    name_en: "Phlapphla Chai, Chan Dum, Buri Ram, 31250",
  },
  {
    id: 311502,
    districtTh: "พลับพลาชัย",
    districtEn: "Phlapphla Chai",
    subdistrictTh: "โคกขมิ้น",
    subdistrictEn: "Khok Khamin",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31250,
    district_code: 3115,
    province_code: 20,
    name: "พลับพลาชัย โคกขมิ้น บุรีรัมย์ 31250",
    name_th: "พลับพลาชัย โคกขมิ้น บุรีรัมย์ 31250",
    name_en: "Phlapphla Chai, Khok Khamin, Buri Ram, 31250",
  },
  {
    id: 311503,
    districtTh: "พลับพลาชัย",
    districtEn: "Phlapphla Chai",
    subdistrictTh: "ป่าชัน",
    subdistrictEn: "Pa Chan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31250,
    district_code: 3115,
    province_code: 20,
    name: "พลับพลาชัย ป่าชัน บุรีรัมย์ 31250",
    name_th: "พลับพลาชัย ป่าชัน บุรีรัมย์ 31250",
    name_en: "Phlapphla Chai, Pa Chan, Buri Ram, 31250",
  },
  {
    id: 311504,
    districtTh: "พลับพลาชัย",
    districtEn: "Phlapphla Chai",
    subdistrictTh: "สะเดา",
    subdistrictEn: "Sadao",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31250,
    district_code: 3115,
    province_code: 20,
    name: "พลับพลาชัย สะเดา บุรีรัมย์ 31250",
    name_th: "พลับพลาชัย สะเดา บุรีรัมย์ 31250",
    name_en: "Phlapphla Chai, Sadao, Buri Ram, 31250",
  },
  {
    id: 311505,
    districtTh: "พลับพลาชัย",
    districtEn: "Phlapphla Chai",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31250,
    district_code: 3115,
    province_code: 20,
    name: "พลับพลาชัย สำโรง บุรีรัมย์ 31250",
    name_th: "พลับพลาชัย สำโรง บุรีรัมย์ 31250",
    name_en: "Phlapphla Chai, Samrong, Buri Ram, 31250",
  },
  {
    id: 311601,
    districtTh: "ห้วยราช",
    districtEn: "Huai Rat",
    subdistrictTh: "ห้วยราช",
    subdistrictEn: "Huai Rat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3116,
    province_code: 20,
    name: "ห้วยราช ห้วยราช บุรีรัมย์ 31000",
    name_th: "ห้วยราช ห้วยราช บุรีรัมย์ 31000",
    name_en: "Huai Rat, Huai Rat, Buri Ram, 31000",
  },
  {
    id: 311602,
    districtTh: "ห้วยราช",
    districtEn: "Huai Rat",
    subdistrictTh: "สามแวง",
    subdistrictEn: "Sam Waeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3116,
    province_code: 20,
    name: "ห้วยราช สามแวง บุรีรัมย์ 31000",
    name_th: "ห้วยราช สามแวง บุรีรัมย์ 31000",
    name_en: "Huai Rat, Sam Waeng, Buri Ram, 31000",
  },
  {
    id: 311603,
    districtTh: "ห้วยราช",
    districtEn: "Huai Rat",
    subdistrictTh: "ตาเสา",
    subdistrictEn: "Ta Sao",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3116,
    province_code: 20,
    name: "ห้วยราช ตาเสา บุรีรัมย์ 31000",
    name_th: "ห้วยราช ตาเสา บุรีรัมย์ 31000",
    name_en: "Huai Rat, Ta Sao, Buri Ram, 31000",
  },
  {
    id: 311604,
    districtTh: "ห้วยราช",
    districtEn: "Huai Rat",
    subdistrictTh: "บ้านตะโก",
    subdistrictEn: "Ban Tako",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3116,
    province_code: 20,
    name: "ห้วยราช บ้านตะโก บุรีรัมย์ 31000",
    name_th: "ห้วยราช บ้านตะโก บุรีรัมย์ 31000",
    name_en: "Huai Rat, Ban Tako, Buri Ram, 31000",
  },
  {
    id: 311605,
    districtTh: "ห้วยราช",
    districtEn: "Huai Rat",
    subdistrictTh: "สนวน",
    subdistrictEn: "Sanuan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3116,
    province_code: 20,
    name: "ห้วยราช สนวน บุรีรัมย์ 31000",
    name_th: "ห้วยราช สนวน บุรีรัมย์ 31000",
    name_en: "Huai Rat, Sanuan, Buri Ram, 31000",
  },
  {
    id: 311606,
    districtTh: "ห้วยราช",
    districtEn: "Huai Rat",
    subdistrictTh: "โคกเหล็ก",
    subdistrictEn: "Khok Lek",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3116,
    province_code: 20,
    name: "ห้วยราช โคกเหล็ก บุรีรัมย์ 31000",
    name_th: "ห้วยราช โคกเหล็ก บุรีรัมย์ 31000",
    name_en: "Huai Rat, Khok Lek, Buri Ram, 31000",
  },
  {
    id: 311607,
    districtTh: "ห้วยราช",
    districtEn: "Huai Rat",
    subdistrictTh: "เมืองโพธิ์",
    subdistrictEn: "Mueang Pho",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3116,
    province_code: 20,
    name: "ห้วยราช เมืองโพธิ์ บุรีรัมย์ 31000",
    name_th: "ห้วยราช เมืองโพธิ์ บุรีรัมย์ 31000",
    name_en: "Huai Rat, Mueang Pho, Buri Ram, 31000",
  },
  {
    id: 311608,
    districtTh: "ห้วยราช",
    districtEn: "Huai Rat",
    subdistrictTh: "ห้วยราชา",
    subdistrictEn: "Huai Racha",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3116,
    province_code: 20,
    name: "ห้วยราช ห้วยราชา บุรีรัมย์ 31000",
    name_th: "ห้วยราช ห้วยราชา บุรีรัมย์ 31000",
    name_en: "Huai Rat, Huai Racha, Buri Ram, 31000",
  },
  {
    id: 311701,
    districtTh: "โนนสุวรรณ",
    districtEn: "Non Suwan",
    subdistrictTh: "โนนสุวรรณ",
    subdistrictEn: "Non Suwan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3117,
    province_code: 20,
    name: "โนนสุวรรณ โนนสุวรรณ บุรีรัมย์ 31110",
    name_th: "โนนสุวรรณ โนนสุวรรณ บุรีรัมย์ 31110",
    name_en: "Non Suwan, Non Suwan, Buri Ram, 31110",
  },
  {
    id: 311702,
    districtTh: "โนนสุวรรณ",
    districtEn: "Non Suwan",
    subdistrictTh: "ทุ่งจังหัน",
    subdistrictEn: "Thung Changhan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3117,
    province_code: 20,
    name: "โนนสุวรรณ ทุ่งจังหัน บุรีรัมย์ 31110",
    name_th: "โนนสุวรรณ ทุ่งจังหัน บุรีรัมย์ 31110",
    name_en: "Non Suwan, Thung Changhan, Buri Ram, 31110",
  },
  {
    id: 311703,
    districtTh: "โนนสุวรรณ",
    districtEn: "Non Suwan",
    subdistrictTh: "โกรกแก้ว",
    subdistrictEn: "Krok Kaeo",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3117,
    province_code: 20,
    name: "โนนสุวรรณ โกรกแก้ว บุรีรัมย์ 31110",
    name_th: "โนนสุวรรณ โกรกแก้ว บุรีรัมย์ 31110",
    name_en: "Non Suwan, Krok Kaeo, Buri Ram, 31110",
  },
  {
    id: 311704,
    districtTh: "โนนสุวรรณ",
    districtEn: "Non Suwan",
    subdistrictTh: "ดงอีจาน",
    subdistrictEn: "Dong I Chan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3117,
    province_code: 20,
    name: "โนนสุวรรณ ดงอีจาน บุรีรัมย์ 31110",
    name_th: "โนนสุวรรณ ดงอีจาน บุรีรัมย์ 31110",
    name_en: "Non Suwan, Dong I Chan, Buri Ram, 31110",
  },
  {
    id: 311801,
    districtTh: "ชำนิ",
    districtEn: "Chamni",
    subdistrictTh: "ชำนิ",
    subdistrictEn: "Chamni",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3118,
    province_code: 20,
    name: "ชำนิ ชำนิ บุรีรัมย์ 31110",
    name_th: "ชำนิ ชำนิ บุรีรัมย์ 31110",
    name_en: "Chamni, Chamni, Buri Ram, 31110",
  },
  {
    id: 311802,
    districtTh: "ชำนิ",
    districtEn: "Chamni",
    subdistrictTh: "หนองปล่อง",
    subdistrictEn: "Nong Plong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3118,
    province_code: 20,
    name: "ชำนิ หนองปล่อง บุรีรัมย์ 31110",
    name_th: "ชำนิ หนองปล่อง บุรีรัมย์ 31110",
    name_en: "Chamni, Nong Plong, Buri Ram, 31110",
  },
  {
    id: 311803,
    districtTh: "ชำนิ",
    districtEn: "Chamni",
    subdistrictTh: "เมืองยาง",
    subdistrictEn: "Mueang Yang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3118,
    province_code: 20,
    name: "ชำนิ เมืองยาง บุรีรัมย์ 31110",
    name_th: "ชำนิ เมืองยาง บุรีรัมย์ 31110",
    name_en: "Chamni, Mueang Yang, Buri Ram, 31110",
  },
  {
    id: 311804,
    districtTh: "ชำนิ",
    districtEn: "Chamni",
    subdistrictTh: "ช่อผกา",
    subdistrictEn: "Cho Phaka",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3118,
    province_code: 20,
    name: "ชำนิ ช่อผกา บุรีรัมย์ 31110",
    name_th: "ชำนิ ช่อผกา บุรีรัมย์ 31110",
    name_en: "Chamni, Cho Phaka, Buri Ram, 31110",
  },
  {
    id: 311805,
    districtTh: "ชำนิ",
    districtEn: "Chamni",
    subdistrictTh: "ละลวด",
    subdistrictEn: "Laluat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3118,
    province_code: 20,
    name: "ชำนิ ละลวด บุรีรัมย์ 31110",
    name_th: "ชำนิ ละลวด บุรีรัมย์ 31110",
    name_en: "Chamni, Laluat, Buri Ram, 31110",
  },
  {
    id: 311806,
    districtTh: "ชำนิ",
    districtEn: "Chamni",
    subdistrictTh: "โคกสนวน",
    subdistrictEn: "Khok Sanuan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3118,
    province_code: 20,
    name: "ชำนิ โคกสนวน บุรีรัมย์ 31110",
    name_th: "ชำนิ โคกสนวน บุรีรัมย์ 31110",
    name_en: "Chamni, Khok Sanuan, Buri Ram, 31110",
  },
  {
    id: 311901,
    districtTh: "บ้านใหม่ไชยพจน์",
    districtEn: "Ban Mai Chaiyaphot",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3119,
    province_code: 20,
    name: "บ้านใหม่ไชยพจน์ หนองแวง บุรีรัมย์ 31120",
    name_th: "บ้านใหม่ไชยพจน์ หนองแวง บุรีรัมย์ 31120",
    name_en: "Ban Mai Chaiyaphot, Nong Waeng, Buri Ram, 31120",
  },
  {
    id: 311902,
    districtTh: "บ้านใหม่ไชยพจน์",
    districtEn: "Ban Mai Chaiyaphot",
    subdistrictTh: "ทองหลาง",
    subdistrictEn: "Thonglang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3119,
    province_code: 20,
    name: "บ้านใหม่ไชยพจน์ ทองหลาง บุรีรัมย์ 31120",
    name_th: "บ้านใหม่ไชยพจน์ ทองหลาง บุรีรัมย์ 31120",
    name_en: "Ban Mai Chaiyaphot, Thonglang, Buri Ram, 31120",
  },
  {
    id: 311903,
    districtTh: "บ้านใหม่ไชยพจน์",
    districtEn: "Ban Mai Chaiyaphot",
    subdistrictTh: "แดงใหญ่",
    subdistrictEn: "Daeng Yai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3119,
    province_code: 20,
    name: "บ้านใหม่ไชยพจน์ แดงใหญ่ บุรีรัมย์ 31120",
    name_th: "บ้านใหม่ไชยพจน์ แดงใหญ่ บุรีรัมย์ 31120",
    name_en: "Ban Mai Chaiyaphot, Daeng Yai, Buri Ram, 31120",
  },
  {
    id: 311904,
    districtTh: "บ้านใหม่ไชยพจน์",
    districtEn: "Ban Mai Chaiyaphot",
    subdistrictTh: "กู่สวนแตง",
    subdistrictEn: "Ku Suan Taeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3119,
    province_code: 20,
    name: "บ้านใหม่ไชยพจน์ กู่สวนแตง บุรีรัมย์ 31120",
    name_th: "บ้านใหม่ไชยพจน์ กู่สวนแตง บุรีรัมย์ 31120",
    name_en: "Ban Mai Chaiyaphot, Ku Suan Taeng, Buri Ram, 31120",
  },
  {
    id: 311905,
    districtTh: "บ้านใหม่ไชยพจน์",
    districtEn: "Ban Mai Chaiyaphot",
    subdistrictTh: "หนองเยือง",
    subdistrictEn: "Nong Yueang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31120,
    district_code: 3119,
    province_code: 20,
    name: "บ้านใหม่ไชยพจน์ หนองเยือง บุรีรัมย์ 31120",
    name_th: "บ้านใหม่ไชยพจน์ หนองเยือง บุรีรัมย์ 31120",
    name_en: "Ban Mai Chaiyaphot, Nong Yueang, Buri Ram, 31120",
  },
  {
    id: 312001,
    districtTh: "โนนดินแดง",
    districtEn: "Din Daeng",
    subdistrictTh: "โนนดินแดง",
    subdistrictEn: "Non Din Daeng",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31260,
    district_code: 3120,
    province_code: 20,
    name: "โนนดินแดง โนนดินแดง บุรีรัมย์ 31260",
    name_th: "โนนดินแดง โนนดินแดง บุรีรัมย์ 31260",
    name_en: "Din Daeng, Non Din Daeng, Buri Ram, 31260",
  },
  {
    id: 312002,
    districtTh: "โนนดินแดง",
    districtEn: "Din Daeng",
    subdistrictTh: "ส้มป่อย",
    subdistrictEn: "Som Poi",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31260,
    district_code: 3120,
    province_code: 20,
    name: "โนนดินแดง ส้มป่อย บุรีรัมย์ 31260",
    name_th: "โนนดินแดง ส้มป่อย บุรีรัมย์ 31260",
    name_en: "Din Daeng, Som Poi, Buri Ram, 31260",
  },
  {
    id: 312003,
    districtTh: "โนนดินแดง",
    districtEn: "Din Daeng",
    subdistrictTh: "ลำนางรอง",
    subdistrictEn: "Lam Nang Rong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31260,
    district_code: 3120,
    province_code: 20,
    name: "โนนดินแดง ลำนางรอง บุรีรัมย์ 31260",
    name_th: "โนนดินแดง ลำนางรอง บุรีรัมย์ 31260",
    name_en: "Din Daeng, Lam Nang Rong, Buri Ram, 31260",
  },
  {
    id: 312101,
    districtTh: "บ้านด่าน",
    districtEn: "Ban Dan",
    subdistrictTh: "บ้านด่าน",
    subdistrictEn: "Ban Dan",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3121,
    province_code: 20,
    name: "บ้านด่าน บ้านด่าน บุรีรัมย์ 31000",
    name_th: "บ้านด่าน บ้านด่าน บุรีรัมย์ 31000",
    name_en: "Ban Dan, Ban Dan, Buri Ram, 31000",
  },
  {
    id: 312102,
    districtTh: "บ้านด่าน",
    districtEn: "Ban Dan",
    subdistrictTh: "ปราสาท",
    subdistrictEn: "Prasat",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3121,
    province_code: 20,
    name: "บ้านด่าน ปราสาท บุรีรัมย์ 31000",
    name_th: "บ้านด่าน ปราสาท บุรีรัมย์ 31000",
    name_en: "Ban Dan, Prasat, Buri Ram, 31000",
  },
  {
    id: 312103,
    districtTh: "บ้านด่าน",
    districtEn: "Ban Dan",
    subdistrictTh: "วังเหนือ",
    subdistrictEn: "Wang Nuea",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3121,
    province_code: 20,
    name: "บ้านด่าน วังเหนือ บุรีรัมย์ 31000",
    name_th: "บ้านด่าน วังเหนือ บุรีรัมย์ 31000",
    name_en: "Ban Dan, Wang Nuea, Buri Ram, 31000",
  },
  {
    id: 312104,
    districtTh: "บ้านด่าน",
    districtEn: "Ban Dan",
    subdistrictTh: "โนนขวาง",
    subdistrictEn: "Non Khwang",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31000,
    district_code: 3121,
    province_code: 20,
    name: "บ้านด่าน โนนขวาง บุรีรัมย์ 31000",
    name_th: "บ้านด่าน โนนขวาง บุรีรัมย์ 31000",
    name_en: "Ban Dan, Non Khwang, Buri Ram, 31000",
  },
  {
    id: 312201,
    districtTh: "แคนดง",
    districtEn: "Khaen Dong",
    subdistrictTh: "แคนดง",
    subdistrictEn: "Khaen Dong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3122,
    province_code: 20,
    name: "แคนดง แคนดง บุรีรัมย์ 31150",
    name_th: "แคนดง แคนดง บุรีรัมย์ 31150",
    name_en: "Khaen Dong, Khaen Dong, Buri Ram, 31150",
  },
  {
    id: 312202,
    districtTh: "แคนดง",
    districtEn: "Khaen Dong",
    subdistrictTh: "ดงพลอง",
    subdistrictEn: "Dong Phlong",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3122,
    province_code: 20,
    name: "แคนดง ดงพลอง บุรีรัมย์ 31150",
    name_th: "แคนดง ดงพลอง บุรีรัมย์ 31150",
    name_en: "Khaen Dong, Dong Phlong, Buri Ram, 31150",
  },
  {
    id: 312203,
    districtTh: "แคนดง",
    districtEn: "Khaen Dong",
    subdistrictTh: "สระบัว",
    subdistrictEn: "Sa Bua",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3122,
    province_code: 20,
    name: "แคนดง สระบัว บุรีรัมย์ 31150",
    name_th: "แคนดง สระบัว บุรีรัมย์ 31150",
    name_en: "Khaen Dong, Sa Bua, Buri Ram, 31150",
  },
  {
    id: 312204,
    districtTh: "แคนดง",
    districtEn: "Khaen Dong",
    subdistrictTh: "หัวฝาย",
    subdistrictEn: "Hua Fai",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31150,
    district_code: 3122,
    province_code: 20,
    name: "แคนดง หัวฝาย บุรีรัมย์ 31150",
    name_th: "แคนดง หัวฝาย บุรีรัมย์ 31150",
    name_en: "Khaen Dong, Hua Fai, Buri Ram, 31150",
  },
  {
    id: 312301,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "เจริญสุข",
    subdistrictEn: "Charoen Suk",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3123,
    province_code: 20,
    name: "เฉลิมพระเกียรติ เจริญสุข บุรีรัมย์ 31110",
    name_th: "เฉลิมพระเกียรติ เจริญสุข บุรีรัมย์ 31110",
    name_en: "Chaloem Phra Kiat, Charoen Suk, Buri Ram, 31110",
  },
  {
    id: 312302,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ตาเป๊ก",
    subdistrictEn: "Ta Pek",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3123,
    province_code: 20,
    name: "เฉลิมพระเกียรติ ตาเป๊ก บุรีรัมย์ 31110",
    name_th: "เฉลิมพระเกียรติ ตาเป๊ก บุรีรัมย์ 31110",
    name_en: "Chaloem Phra Kiat, Ta Pek, Buri Ram, 31110",
  },
  {
    id: 312303,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "อีสานเขต",
    subdistrictEn: "Isan Khet",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31110,
    district_code: 3123,
    province_code: 20,
    name: "เฉลิมพระเกียรติ อีสานเขต บุรีรัมย์ 31110",
    name_th: "เฉลิมพระเกียรติ อีสานเขต บุรีรัมย์ 31110",
    name_en: "Chaloem Phra Kiat, Isan Khet, Buri Ram, 31110",
  },
  {
    id: 312304,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ถาวร",
    subdistrictEn: "Thawon",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31170,
    district_code: 3123,
    province_code: 20,
    name: "เฉลิมพระเกียรติ ถาวร บุรีรัมย์ 31170",
    name_th: "เฉลิมพระเกียรติ ถาวร บุรีรัมย์ 31170",
    name_en: "Chaloem Phra Kiat, Thawon, Buri Ram, 31170",
  },
  {
    id: 312305,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ยายแย้มวัฒนา",
    subdistrictEn: "Yai Yaem Watthana",
    provinceTh: "บุรีรัมย์",
    provinceEn: "Buri Ram",
    zipcode: 31170,
    district_code: 3123,
    province_code: 20,
    name: "เฉลิมพระเกียรติ ยายแย้มวัฒนา บุรีรัมย์ 31170",
    name_th: "เฉลิมพระเกียรติ ยายแย้มวัฒนา บุรีรัมย์ 31170",
    name_en: "Chaloem Phra Kiat, Yai Yaem Watthana, Buri Ram, 31170",
  },
  {
    id: 320101,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ ในเมือง สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ ในเมือง สุรินทร์ 32000",
    name_en: "Mueang Surin, Nai Mueang, Surin, 32000",
  },
  {
    id: 320102,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "ตั้งใจ",
    subdistrictEn: "Tang Chai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ ตั้งใจ สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ ตั้งใจ สุรินทร์ 32000",
    name_en: "Mueang Surin, Tang Chai, Surin, 32000",
  },
  {
    id: 320103,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "เพี้ยราม",
    subdistrictEn: "Phia Ram",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ เพี้ยราม สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ เพี้ยราม สุรินทร์ 32000",
    name_en: "Mueang Surin, Phia Ram, Surin, 32000",
  },
  {
    id: 320104,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ นาดี สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ นาดี สุรินทร์ 32000",
    name_en: "Mueang Surin, Na Di, Surin, 32000",
  },
  {
    id: 320105,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "ท่าสว่าง",
    subdistrictEn: "Tha Sawang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ ท่าสว่าง สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ ท่าสว่าง สุรินทร์ 32000",
    name_en: "Mueang Surin, Tha Sawang, Surin, 32000",
  },
  {
    id: 320106,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "สลักได",
    subdistrictEn: "Salakdai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ สลักได สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ สลักได สุรินทร์ 32000",
    name_en: "Mueang Surin, Salakdai, Surin, 32000",
  },
  {
    id: 320107,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "ตาอ็อง",
    subdistrictEn: "Ta Ong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ ตาอ็อง สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ ตาอ็อง สุรินทร์ 32000",
    name_en: "Mueang Surin, Ta Ong, Surin, 32000",
  },
  {
    id: 320109,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ สำโรง สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ สำโรง สุรินทร์ 32000",
    name_en: "Mueang Surin, Samrong, Surin, 32000",
  },
  {
    id: 320110,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "แกใหญ่",
    subdistrictEn: "Kae Yai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ แกใหญ่ สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ แกใหญ่ สุรินทร์ 32000",
    name_en: "Mueang Surin, Kae Yai, Surin, 32000",
  },
  {
    id: 320111,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "นอกเมือง",
    subdistrictEn: "Nok Mueang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ นอกเมือง สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ นอกเมือง สุรินทร์ 32000",
    name_en: "Mueang Surin, Nok Mueang, Surin, 32000",
  },
  {
    id: 320112,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "คอโค",
    subdistrictEn: "Kho Kho",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ คอโค สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ คอโค สุรินทร์ 32000",
    name_en: "Mueang Surin, Kho Kho, Surin, 32000",
  },
  {
    id: 320113,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "สวาย",
    subdistrictEn: "Sawai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ สวาย สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ สวาย สุรินทร์ 32000",
    name_en: "Mueang Surin, Sawai, Surin, 32000",
  },
  {
    id: 320114,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "เฉนียง",
    subdistrictEn: "Chaniang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ เฉนียง สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ เฉนียง สุรินทร์ 32000",
    name_en: "Mueang Surin, Chaniang, Surin, 32000",
  },
  {
    id: 320116,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "เทนมีย์",
    subdistrictEn: "Thenmi",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ เทนมีย์ สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ เทนมีย์ สุรินทร์ 32000",
    name_en: "Mueang Surin, Thenmi, Surin, 32000",
  },
  {
    id: 320118,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "นาบัว",
    subdistrictEn: "Na Bua",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ นาบัว สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ นาบัว สุรินทร์ 32000",
    name_en: "Mueang Surin, Na Bua, Surin, 32000",
  },
  {
    id: 320119,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "เมืองที",
    subdistrictEn: "Mueang Thi",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ เมืองที สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ เมืองที สุรินทร์ 32000",
    name_en: "Mueang Surin, Mueang Thi, Surin, 32000",
  },
  {
    id: 320120,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "ราม",
    subdistrictEn: "Ram",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ ราม สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ ราม สุรินทร์ 32000",
    name_en: "Mueang Surin, Ram, Surin, 32000",
  },
  {
    id: 320121,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "บุฤาษี",
    subdistrictEn: "Bu Ruesi",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ บุฤาษี สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ บุฤาษี สุรินทร์ 32000",
    name_en: "Mueang Surin, Bu Ruesi, Surin, 32000",
  },
  {
    id: 320122,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "ตระแสง",
    subdistrictEn: "Trasaeng",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ ตระแสง สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ ตระแสง สุรินทร์ 32000",
    name_en: "Mueang Surin, Trasaeng, Surin, 32000",
  },
  {
    id: 320125,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "แสลงพันธ์",
    subdistrictEn: "Salaeng Phan",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ แสลงพันธ์ สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ แสลงพันธ์ สุรินทร์ 32000",
    name_en: "Mueang Surin, Salaeng Phan, Surin, 32000",
  },
  {
    id: 320126,
    districtTh: "เมืองสุรินทร์",
    districtEn: "Mueang Surin",
    subdistrictTh: "กาเกาะ",
    subdistrictEn: "Ka Ko",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3201,
    province_code: 21,
    name: "เมืองสุรินทร์ กาเกาะ สุรินทร์ 32000",
    name_th: "เมืองสุรินทร์ กาเกาะ สุรินทร์ 32000",
    name_en: "Mueang Surin, Ka Ko, Surin, 32000",
  },
  {
    id: 320201,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "ชุมพลบุรี",
    subdistrictEn: "Chumphon Buri",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี ชุมพลบุรี สุรินทร์ 32190",
    name_th: "ชุมพลบุรี ชุมพลบุรี สุรินทร์ 32190",
    name_en: "Chumphon Buri, Chumphon Buri, Surin, 32190",
  },
  {
    id: 320202,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "นาหนองไผ่",
    subdistrictEn: "Na Nong Phai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี นาหนองไผ่ สุรินทร์ 32190",
    name_th: "ชุมพลบุรี นาหนองไผ่ สุรินทร์ 32190",
    name_en: "Chumphon Buri, Na Nong Phai, Surin, 32190",
  },
  {
    id: 320203,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "ไพรขลา",
    subdistrictEn: "Phrai Khla",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี ไพรขลา สุรินทร์ 32190",
    name_th: "ชุมพลบุรี ไพรขลา สุรินทร์ 32190",
    name_en: "Chumphon Buri, Phrai Khla, Surin, 32190",
  },
  {
    id: 320204,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "ศรีณรงค์",
    subdistrictEn: "Si Narong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี ศรีณรงค์ สุรินทร์ 32190",
    name_th: "ชุมพลบุรี ศรีณรงค์ สุรินทร์ 32190",
    name_en: "Chumphon Buri, Si Narong, Surin, 32190",
  },
  {
    id: 320205,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "ยะวึก",
    subdistrictEn: "Yawuek",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี ยะวึก สุรินทร์ 32190",
    name_th: "ชุมพลบุรี ยะวึก สุรินทร์ 32190",
    name_en: "Chumphon Buri, Yawuek, Surin, 32190",
  },
  {
    id: 320206,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "เมืองบัว",
    subdistrictEn: "Mueang Bua",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี เมืองบัว สุรินทร์ 32190",
    name_th: "ชุมพลบุรี เมืองบัว สุรินทร์ 32190",
    name_en: "Chumphon Buri, Mueang Bua, Surin, 32190",
  },
  {
    id: 320207,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "สระขุด",
    subdistrictEn: "Sa Khut",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี สระขุด สุรินทร์ 32190",
    name_th: "ชุมพลบุรี สระขุด สุรินทร์ 32190",
    name_en: "Chumphon Buri, Sa Khut, Surin, 32190",
  },
  {
    id: 320208,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "กระเบื้อง",
    subdistrictEn: "Krabueang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี กระเบื้อง สุรินทร์ 32190",
    name_th: "ชุมพลบุรี กระเบื้อง สุรินทร์ 32190",
    name_en: "Chumphon Buri, Krabueang, Surin, 32190",
  },
  {
    id: 320209,
    districtTh: "ชุมพลบุรี",
    districtEn: "Chumphon Buri",
    subdistrictTh: "หนองเรือ",
    subdistrictEn: "Nong Ruea",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32190,
    district_code: 3202,
    province_code: 21,
    name: "ชุมพลบุรี หนองเรือ สุรินทร์ 32190",
    name_th: "ชุมพลบุรี หนองเรือ สุรินทร์ 32190",
    name_en: "Chumphon Buri, Nong Ruea, Surin, 32190",
  },
  {
    id: 320301,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "ท่าตูม",
    subdistrictEn: "Tha Tum",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม ท่าตูม สุรินทร์ 32120",
    name_th: "ท่าตูม ท่าตูม สุรินทร์ 32120",
    name_en: "Tha Tum, Tha Tum, Surin, 32120",
  },
  {
    id: 320302,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "กระโพ",
    subdistrictEn: "Krapho",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม กระโพ สุรินทร์ 32120",
    name_th: "ท่าตูม กระโพ สุรินทร์ 32120",
    name_en: "Tha Tum, Krapho, Surin, 32120",
  },
  {
    id: 320303,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "พรมเทพ",
    subdistrictEn: "Phrom Thep",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม พรมเทพ สุรินทร์ 32120",
    name_th: "ท่าตูม พรมเทพ สุรินทร์ 32120",
    name_en: "Tha Tum, Phrom Thep, Surin, 32120",
  },
  {
    id: 320304,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "โพนครก",
    subdistrictEn: "Phon Khrok",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม โพนครก สุรินทร์ 32120",
    name_th: "ท่าตูม โพนครก สุรินทร์ 32120",
    name_en: "Tha Tum, Phon Khrok, Surin, 32120",
  },
  {
    id: 320305,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "เมืองแก",
    subdistrictEn: "Mueang Kae",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม เมืองแก สุรินทร์ 32120",
    name_th: "ท่าตูม เมืองแก สุรินทร์ 32120",
    name_en: "Tha Tum, Mueang Kae, Surin, 32120",
  },
  {
    id: 320307,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม หนองบัว สุรินทร์ 32120",
    name_th: "ท่าตูม หนองบัว สุรินทร์ 32120",
    name_en: "Tha Tum, Nong Bua, Surin, 32120",
  },
  {
    id: 320308,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "บัวโคก",
    subdistrictEn: "Bua Khok",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม บัวโคก สุรินทร์ 32120",
    name_th: "ท่าตูม บัวโคก สุรินทร์ 32120",
    name_en: "Tha Tum, Bua Khok, Surin, 32120",
  },
  {
    id: 320309,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "หนองเมธี",
    subdistrictEn: "Nong Methi",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม หนองเมธี สุรินทร์ 32120",
    name_th: "ท่าตูม หนองเมธี สุรินทร์ 32120",
    name_en: "Tha Tum, Nong Methi, Surin, 32120",
  },
  {
    id: 320310,
    districtTh: "ท่าตูม",
    districtEn: "Tha Tum",
    subdistrictTh: "ทุ่งกุลา",
    subdistrictEn: "Thung Kula",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32120,
    district_code: 3203,
    province_code: 21,
    name: "ท่าตูม ทุ่งกุลา สุรินทร์ 32120",
    name_th: "ท่าตูม ทุ่งกุลา สุรินทร์ 32120",
    name_en: "Tha Tum, Thung Kula, Surin, 32120",
  },
  {
    id: 320401,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "จอมพระ",
    subdistrictEn: "Chom Phra",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ จอมพระ สุรินทร์ 32180",
    name_th: "จอมพระ จอมพระ สุรินทร์ 32180",
    name_en: "Chom Phra, Chom Phra, Surin, 32180",
  },
  {
    id: 320402,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "เมืองลีง",
    subdistrictEn: "Mueang Ling",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ เมืองลีง สุรินทร์ 32180",
    name_th: "จอมพระ เมืองลีง สุรินทร์ 32180",
    name_en: "Chom Phra, Mueang Ling, Surin, 32180",
  },
  {
    id: 320403,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "กระหาด",
    subdistrictEn: "Krahat",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ กระหาด สุรินทร์ 32180",
    name_th: "จอมพระ กระหาด สุรินทร์ 32180",
    name_en: "Chom Phra, Krahat, Surin, 32180",
  },
  {
    id: 320404,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "บุแกรง",
    subdistrictEn: "Bu Kraeng",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ บุแกรง สุรินทร์ 32180",
    name_th: "จอมพระ บุแกรง สุรินทร์ 32180",
    name_en: "Chom Phra, Bu Kraeng, Surin, 32180",
  },
  {
    id: 320405,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "หนองสนิท",
    subdistrictEn: "Nong Sanit",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ หนองสนิท สุรินทร์ 32180",
    name_th: "จอมพระ หนองสนิท สุรินทร์ 32180",
    name_en: "Chom Phra, Nong Sanit, Surin, 32180",
  },
  {
    id: 320406,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "บ้านผือ",
    subdistrictEn: "Ban Phue",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ บ้านผือ สุรินทร์ 32180",
    name_th: "จอมพระ บ้านผือ สุรินทร์ 32180",
    name_en: "Chom Phra, Ban Phue, Surin, 32180",
  },
  {
    id: 320407,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "ลุ่มระวี",
    subdistrictEn: "Lum Rawi",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ ลุ่มระวี สุรินทร์ 32180",
    name_th: "จอมพระ ลุ่มระวี สุรินทร์ 32180",
    name_en: "Chom Phra, Lum Rawi, Surin, 32180",
  },
  {
    id: 320408,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "ชุมแสง",
    subdistrictEn: "Chum Saeng",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ ชุมแสง สุรินทร์ 32180",
    name_th: "จอมพระ ชุมแสง สุรินทร์ 32180",
    name_en: "Chom Phra, Chum Saeng, Surin, 32180",
  },
  {
    id: 320409,
    districtTh: "จอมพระ",
    districtEn: "Chom Phra",
    subdistrictTh: "เป็นสุข",
    subdistrictEn: "Pen Suk",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32180,
    district_code: 3204,
    province_code: 21,
    name: "จอมพระ เป็นสุข สุรินทร์ 32180",
    name_th: "จอมพระ เป็นสุข สุรินทร์ 32180",
    name_en: "Chom Phra, Pen Suk, Surin, 32180",
  },
  {
    id: 320501,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "กังแอน",
    subdistrictEn: "Kang-aen",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท กังแอน สุรินทร์ 32140",
    name_th: "ปราสาท กังแอน สุรินทร์ 32140",
    name_en: "Prasat, Kang-aen, Surin, 32140",
  },
  {
    id: 320502,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "ทมอ",
    subdistrictEn: "Thamo",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท ทมอ สุรินทร์ 32140",
    name_th: "ปราสาท ทมอ สุรินทร์ 32140",
    name_en: "Prasat, Thamo, Surin, 32140",
  },
  {
    id: 320503,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "ไพล",
    subdistrictEn: "Phlai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท ไพล สุรินทร์ 32140",
    name_th: "ปราสาท ไพล สุรินทร์ 32140",
    name_en: "Prasat, Phlai, Surin, 32140",
  },
  {
    id: 320504,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "ปรือ",
    subdistrictEn: "Prue",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท ปรือ สุรินทร์ 32140",
    name_th: "ปราสาท ปรือ สุรินทร์ 32140",
    name_en: "Prasat, Prue, Surin, 32140",
  },
  {
    id: 320505,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "ทุ่งมน",
    subdistrictEn: "Thung Mon",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท ทุ่งมน สุรินทร์ 32140",
    name_th: "ปราสาท ทุ่งมน สุรินทร์ 32140",
    name_en: "Prasat, Thung Mon, Surin, 32140",
  },
  {
    id: 320506,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "ตาเบา",
    subdistrictEn: "Ta Bao",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท ตาเบา สุรินทร์ 32140",
    name_th: "ปราสาท ตาเบา สุรินทร์ 32140",
    name_en: "Prasat, Ta Bao, Surin, 32140",
  },
  {
    id: 320507,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "หนองใหญ่",
    subdistrictEn: "Nong Yai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท หนองใหญ่ สุรินทร์ 32140",
    name_th: "ปราสาท หนองใหญ่ สุรินทร์ 32140",
    name_en: "Prasat, Nong Yai, Surin, 32140",
  },
  {
    id: 320508,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "โคกยาง",
    subdistrictEn: "Khok Yang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท โคกยาง สุรินทร์ 32140",
    name_th: "ปราสาท โคกยาง สุรินทร์ 32140",
    name_en: "Prasat, Khok Yang, Surin, 32140",
  },
  {
    id: 320509,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท โคกสะอาด สุรินทร์ 32140",
    name_th: "ปราสาท โคกสะอาด สุรินทร์ 32140",
    name_en: "Prasat, Khok Sa-at, Surin, 32140",
  },
  {
    id: 320510,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "บ้านไทร",
    subdistrictEn: "Ban Sai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท บ้านไทร สุรินทร์ 32140",
    name_th: "ปราสาท บ้านไทร สุรินทร์ 32140",
    name_en: "Prasat, Ban Sai, Surin, 32140",
  },
  {
    id: 320511,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "โชคนาสาม",
    subdistrictEn: "Chok Na Sam",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท โชคนาสาม สุรินทร์ 32140",
    name_th: "ปราสาท โชคนาสาม สุรินทร์ 32140",
    name_en: "Prasat, Chok Na Sam, Surin, 32140",
  },
  {
    id: 320512,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "เชื้อเพลิง",
    subdistrictEn: "Chuea Phloeng",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท เชื้อเพลิง สุรินทร์ 32140",
    name_th: "ปราสาท เชื้อเพลิง สุรินทร์ 32140",
    name_en: "Prasat, Chuea Phloeng, Surin, 32140",
  },
  {
    id: 320513,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "ปราสาททนง",
    subdistrictEn: "Prasat Thanong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท ปราสาททนง สุรินทร์ 32140",
    name_th: "ปราสาท ปราสาททนง สุรินทร์ 32140",
    name_en: "Prasat, Prasat Thanong, Surin, 32140",
  },
  {
    id: 320514,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "ตานี",
    subdistrictEn: "Tani",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท ตานี สุรินทร์ 32140",
    name_th: "ปราสาท ตานี สุรินทร์ 32140",
    name_en: "Prasat, Tani, Surin, 32140",
  },
  {
    id: 320515,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "บ้านพลวง",
    subdistrictEn: "Ban Phluang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท บ้านพลวง สุรินทร์ 32140",
    name_th: "ปราสาท บ้านพลวง สุรินทร์ 32140",
    name_en: "Prasat, Ban Phluang, Surin, 32140",
  },
  {
    id: 320516,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "กันตวจระมวล",
    subdistrictEn: "Kantuat Ramuan",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท กันตวจระมวล สุรินทร์ 32140",
    name_th: "ปราสาท กันตวจระมวล สุรินทร์ 32140",
    name_en: "Prasat, Kantuat Ramuan, Surin, 32140",
  },
  {
    id: 320517,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "สมุด",
    subdistrictEn: "Samut",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท สมุด สุรินทร์ 32140",
    name_th: "ปราสาท สมุด สุรินทร์ 32140",
    name_en: "Prasat, Samut, Surin, 32140",
  },
  {
    id: 320518,
    districtTh: "ปราสาท",
    districtEn: "Prasat",
    subdistrictTh: "ประทัดบุ",
    subdistrictEn: "Prathat Bu",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3205,
    province_code: 21,
    name: "ปราสาท ประทัดบุ สุรินทร์ 32140",
    name_th: "ปราสาท ประทัดบุ สุรินทร์ 32140",
    name_en: "Prasat, Prathat Bu, Surin, 32140",
  },
  {
    id: 320601,
    districtTh: "กาบเชิง",
    districtEn: "Kap Choeng",
    subdistrictTh: "กาบเชิง",
    subdistrictEn: "Kap Choeng",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32210,
    district_code: 3206,
    province_code: 21,
    name: "กาบเชิง กาบเชิง สุรินทร์ 32210",
    name_th: "กาบเชิง กาบเชิง สุรินทร์ 32210",
    name_en: "Kap Choeng, Kap Choeng, Surin, 32210",
  },
  {
    id: 320604,
    districtTh: "กาบเชิง",
    districtEn: "Kap Choeng",
    subdistrictTh: "คูตัน",
    subdistrictEn: "Khu Tan",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32210,
    district_code: 3206,
    province_code: 21,
    name: "กาบเชิง คูตัน สุรินทร์ 32210",
    name_th: "กาบเชิง คูตัน สุรินทร์ 32210",
    name_en: "Kap Choeng, Khu Tan, Surin, 32210",
  },
  {
    id: 320605,
    districtTh: "กาบเชิง",
    districtEn: "Kap Choeng",
    subdistrictTh: "ด่าน",
    subdistrictEn: "Dan",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32210,
    district_code: 3206,
    province_code: 21,
    name: "กาบเชิง ด่าน สุรินทร์ 32210",
    name_th: "กาบเชิง ด่าน สุรินทร์ 32210",
    name_en: "Kap Choeng, Dan, Surin, 32210",
  },
  {
    id: 320606,
    districtTh: "กาบเชิง",
    districtEn: "Kap Choeng",
    subdistrictTh: "แนงมุด",
    subdistrictEn: "Naeng Mut",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32210,
    district_code: 3206,
    province_code: 21,
    name: "กาบเชิง แนงมุด สุรินทร์ 32210",
    name_th: "กาบเชิง แนงมุด สุรินทร์ 32210",
    name_en: "Kap Choeng, Naeng Mut, Surin, 32210",
  },
  {
    id: 320607,
    districtTh: "กาบเชิง",
    districtEn: "Kap Choeng",
    subdistrictTh: "โคกตะเคียน",
    subdistrictEn: "Khok Takhian",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32210,
    district_code: 3206,
    province_code: 21,
    name: "กาบเชิง โคกตะเคียน สุรินทร์ 32210",
    name_th: "กาบเชิง โคกตะเคียน สุรินทร์ 32210",
    name_en: "Kap Choeng, Khok Takhian, Surin, 32210",
  },
  {
    id: 320610,
    districtTh: "กาบเชิง",
    districtEn: "Kap Choeng",
    subdistrictTh: "ตะเคียน",
    subdistrictEn: "Takhian",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32210,
    district_code: 3206,
    province_code: 21,
    name: "กาบเชิง ตะเคียน สุรินทร์ 32210",
    name_th: "กาบเชิง ตะเคียน สุรินทร์ 32210",
    name_en: "Kap Choeng, Takhian, Surin, 32210",
  },
  {
    id: 320701,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "รัตนบุรี",
    subdistrictEn: "Rattanaburi",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี รัตนบุรี สุรินทร์ 32130",
    name_th: "รัตนบุรี รัตนบุรี สุรินทร์ 32130",
    name_en: "Rattanaburi, Rattanaburi, Surin, 32130",
  },
  {
    id: 320702,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "ธาตุ",
    subdistrictEn: "That",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี ธาตุ สุรินทร์ 32130",
    name_th: "รัตนบุรี ธาตุ สุรินทร์ 32130",
    name_en: "Rattanaburi, That, Surin, 32130",
  },
  {
    id: 320703,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "แก",
    subdistrictEn: "Kae",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี แก สุรินทร์ 32130",
    name_th: "รัตนบุรี แก สุรินทร์ 32130",
    name_en: "Rattanaburi, Kae, Surin, 32130",
  },
  {
    id: 320704,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "ดอนแรด",
    subdistrictEn: "Don Raet",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี ดอนแรด สุรินทร์ 32130",
    name_th: "รัตนบุรี ดอนแรด สุรินทร์ 32130",
    name_en: "Rattanaburi, Don Raet, Surin, 32130",
  },
  {
    id: 320705,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "หนองบัวทอง",
    subdistrictEn: "Nong Bua Thong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี หนองบัวทอง สุรินทร์ 32130",
    name_th: "รัตนบุรี หนองบัวทอง สุรินทร์ 32130",
    name_en: "Rattanaburi, Nong Bua Thong, Surin, 32130",
  },
  {
    id: 320706,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "หนองบัวบาน",
    subdistrictEn: "Nong Bua Ban",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี หนองบัวบาน สุรินทร์ 32130",
    name_th: "รัตนบุรี หนองบัวบาน สุรินทร์ 32130",
    name_en: "Rattanaburi, Nong Bua Ban, Surin, 32130",
  },
  {
    id: 320709,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "ไผ่",
    subdistrictEn: "Phai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี ไผ่ สุรินทร์ 32130",
    name_th: "รัตนบุรี ไผ่ สุรินทร์ 32130",
    name_en: "Rattanaburi, Phai, Surin, 32130",
  },
  {
    id: 320711,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "เบิด",
    subdistrictEn: "Boet",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี เบิด สุรินทร์ 32130",
    name_th: "รัตนบุรี เบิด สุรินทร์ 32130",
    name_en: "Rattanaburi, Boet, Surin, 32130",
  },
  {
    id: 320713,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "น้ำเขียว",
    subdistrictEn: "Nam Khiao",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี น้ำเขียว สุรินทร์ 32130",
    name_th: "รัตนบุรี น้ำเขียว สุรินทร์ 32130",
    name_en: "Rattanaburi, Nam Khiao, Surin, 32130",
  },
  {
    id: 320714,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "กุดขาคีม",
    subdistrictEn: "Kut Kha Khim",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี กุดขาคีม สุรินทร์ 32130",
    name_th: "รัตนบุรี กุดขาคีม สุรินทร์ 32130",
    name_en: "Rattanaburi, Kut Kha Khim, Surin, 32130",
  },
  {
    id: 320715,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "ยางสว่าง",
    subdistrictEn: "Yang Sawang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี ยางสว่าง สุรินทร์ 32130",
    name_th: "รัตนบุรี ยางสว่าง สุรินทร์ 32130",
    name_en: "Rattanaburi, Yang Sawang, Surin, 32130",
  },
  {
    id: 320716,
    districtTh: "รัตนบุรี",
    districtEn: "Rattanaburi",
    subdistrictTh: "ทับใหญ่",
    subdistrictEn: "Thap Ya",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3207,
    province_code: 21,
    name: "รัตนบุรี ทับใหญ่ สุรินทร์ 32130",
    name_th: "รัตนบุรี ทับใหญ่ สุรินทร์ 32130",
    name_en: "Rattanaburi, Thap Ya, Surin, 32130",
  },
  {
    id: 320801,
    districtTh: "สนม",
    districtEn: "Sanom",
    subdistrictTh: "สนม",
    subdistrictEn: "Sanom",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32160,
    district_code: 3208,
    province_code: 21,
    name: "สนม สนม สุรินทร์ 32160",
    name_th: "สนม สนม สุรินทร์ 32160",
    name_en: "Sanom, Sanom, Surin, 32160",
  },
  {
    id: 320802,
    districtTh: "สนม",
    districtEn: "Sanom",
    subdistrictTh: "โพนโก",
    subdistrictEn: "Phon Ko",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32160,
    district_code: 3208,
    province_code: 21,
    name: "สนม โพนโก สุรินทร์ 32160",
    name_th: "สนม โพนโก สุรินทร์ 32160",
    name_en: "Sanom, Phon Ko, Surin, 32160",
  },
  {
    id: 320803,
    districtTh: "สนม",
    districtEn: "Sanom",
    subdistrictTh: "หนองระฆัง",
    subdistrictEn: "Nong Rakhang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32160,
    district_code: 3208,
    province_code: 21,
    name: "สนม หนองระฆัง สุรินทร์ 32160",
    name_th: "สนม หนองระฆัง สุรินทร์ 32160",
    name_en: "Sanom, Nong Rakhang, Surin, 32160",
  },
  {
    id: 320804,
    districtTh: "สนม",
    districtEn: "Sanom",
    subdistrictTh: "นานวน",
    subdistrictEn: "Na Nuan",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32160,
    district_code: 3208,
    province_code: 21,
    name: "สนม นานวน สุรินทร์ 32160",
    name_th: "สนม นานวน สุรินทร์ 32160",
    name_en: "Sanom, Na Nuan, Surin, 32160",
  },
  {
    id: 320805,
    districtTh: "สนม",
    districtEn: "Sanom",
    subdistrictTh: "แคน",
    subdistrictEn: "Khaen",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32160,
    district_code: 3208,
    province_code: 21,
    name: "สนม แคน สุรินทร์ 32160",
    name_th: "สนม แคน สุรินทร์ 32160",
    name_en: "Sanom, Khaen, Surin, 32160",
  },
  {
    id: 320806,
    districtTh: "สนม",
    districtEn: "Sanom",
    subdistrictTh: "หัวงัว",
    subdistrictEn: "Hua Ngua",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32160,
    district_code: 3208,
    province_code: 21,
    name: "สนม หัวงัว สุรินทร์ 32160",
    name_th: "สนม หัวงัว สุรินทร์ 32160",
    name_en: "Sanom, Hua Ngua, Surin, 32160",
  },
  {
    id: 320807,
    districtTh: "สนม",
    districtEn: "Sanom",
    subdistrictTh: "หนองอียอ",
    subdistrictEn: "Nong I Yo)",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32160,
    district_code: 3208,
    province_code: 21,
    name: "สนม หนองอียอ สุรินทร์ 32160",
    name_th: "สนม หนองอียอ สุรินทร์ 32160",
    name_en: "Sanom, Nong I Yo), Surin, 32160",
  },
  {
    id: 320901,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "ระแงง",
    subdistrictEn: "Ra-ngaeng",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ ระแงง สุรินทร์ 32110",
    name_th: "ศีขรภูมิ ระแงง สุรินทร์ 32110",
    name_en: "Sikhoraphum, Ra-ngaeng, Surin, 32110",
  },
  {
    id: 320902,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "ตรึม",
    subdistrictEn: "Truem",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ ตรึม สุรินทร์ 32110",
    name_th: "ศีขรภูมิ ตรึม สุรินทร์ 32110",
    name_en: "Sikhoraphum, Truem, Surin, 32110",
  },
  {
    id: 320903,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "จารพัต",
    subdistrictEn: "Charaphat",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ จารพัต สุรินทร์ 32110",
    name_th: "ศีขรภูมิ จารพัต สุรินทร์ 32110",
    name_en: "Sikhoraphum, Charaphat, Surin, 32110",
  },
  {
    id: 320904,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "ยาง",
    subdistrictEn: "Yang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ ยาง สุรินทร์ 32110",
    name_th: "ศีขรภูมิ ยาง สุรินทร์ 32110",
    name_en: "Sikhoraphum, Yang, Surin, 32110",
  },
  {
    id: 320905,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "แตล",
    subdistrictEn: "Taen",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ แตล สุรินทร์ 32110",
    name_th: "ศีขรภูมิ แตล สุรินทร์ 32110",
    name_en: "Sikhoraphum, Taen, Surin, 32110",
  },
  {
    id: 320906,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ หนองบัว สุรินทร์ 32110",
    name_th: "ศีขรภูมิ หนองบัว สุรินทร์ 32110",
    name_en: "Sikhoraphum, Nong Bua, Surin, 32110",
  },
  {
    id: 320907,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "คาละแมะ",
    subdistrictEn: "Khalamae",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ คาละแมะ สุรินทร์ 32110",
    name_th: "ศีขรภูมิ คาละแมะ สุรินทร์ 32110",
    name_en: "Sikhoraphum, Khalamae, Surin, 32110",
  },
  {
    id: 320908,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "หนองเหล็ก",
    subdistrictEn: "Nong Lek",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ หนองเหล็ก สุรินทร์ 32110",
    name_th: "ศีขรภูมิ หนองเหล็ก สุรินทร์ 32110",
    name_en: "Sikhoraphum, Nong Lek, Surin, 32110",
  },
  {
    id: 320909,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "หนองขวาว",
    subdistrictEn: "Nong Khwao",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ หนองขวาว สุรินทร์ 32110",
    name_th: "ศีขรภูมิ หนองขวาว สุรินทร์ 32110",
    name_en: "Sikhoraphum, Nong Khwao, Surin, 32110",
  },
  {
    id: 320910,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "ช่างปี่",
    subdistrictEn: "Chang Pi",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ ช่างปี่ สุรินทร์ 32110",
    name_th: "ศีขรภูมิ ช่างปี่ สุรินทร์ 32110",
    name_en: "Sikhoraphum, Chang Pi, Surin, 32110",
  },
  {
    id: 320911,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "กุดหวาย",
    subdistrictEn: "Kut Wai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ กุดหวาย สุรินทร์ 32110",
    name_th: "ศีขรภูมิ กุดหวาย สุรินทร์ 32110",
    name_en: "Sikhoraphum, Kut Wai, Surin, 32110",
  },
  {
    id: 320912,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "ขวาวใหญ่",
    subdistrictEn: "Khwao Yai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ ขวาวใหญ่ สุรินทร์ 32110",
    name_th: "ศีขรภูมิ ขวาวใหญ่ สุรินทร์ 32110",
    name_en: "Sikhoraphum, Khwao Yai, Surin, 32110",
  },
  {
    id: 320913,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "นารุ่ง",
    subdistrictEn: "Na Rung",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ นารุ่ง สุรินทร์ 32110",
    name_th: "ศีขรภูมิ นารุ่ง สุรินทร์ 32110",
    name_en: "Sikhoraphum, Na Rung, Surin, 32110",
  },
  {
    id: 320914,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "ตรมไพร",
    subdistrictEn: "Trom Phrai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ ตรมไพร สุรินทร์ 32110",
    name_th: "ศีขรภูมิ ตรมไพร สุรินทร์ 32110",
    name_en: "Sikhoraphum, Trom Phrai, Surin, 32110",
  },
  {
    id: 320915,
    districtTh: "ศีขรภูมิ",
    districtEn: "Sikhoraphum",
    subdistrictTh: "ผักไหม",
    subdistrictEn: "Phak Mai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32110,
    district_code: 3209,
    province_code: 21,
    name: "ศีขรภูมิ ผักไหม สุรินทร์ 32110",
    name_th: "ศีขรภูมิ ผักไหม สุรินทร์ 32110",
    name_en: "Sikhoraphum, Phak Mai, Surin, 32110",
  },
  {
    id: 321001,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "สังขะ",
    subdistrictEn: "Sangkha",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ สังขะ สุรินทร์ 32150",
    name_th: "สังขะ สังขะ สุรินทร์ 32150",
    name_en: "Sangkha, Sangkha, Surin, 32150",
  },
  {
    id: 321002,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "ขอนแตก",
    subdistrictEn: "Khon Taek",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ ขอนแตก สุรินทร์ 32150",
    name_th: "สังขะ ขอนแตก สุรินทร์ 32150",
    name_en: "Sangkha, Khon Taek, Surin, 32150",
  },
  {
    id: 321006,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "ดม",
    subdistrictEn: "Dom",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ ดม สุรินทร์ 32150",
    name_th: "สังขะ ดม สุรินทร์ 32150",
    name_en: "Sangkha, Dom, Surin, 32150",
  },
  {
    id: 321007,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "พระแก้ว",
    subdistrictEn: "Phra Kaeo",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ พระแก้ว สุรินทร์ 32150",
    name_th: "สังขะ พระแก้ว สุรินทร์ 32150",
    name_en: "Sangkha, Phra Kaeo, Surin, 32150",
  },
  {
    id: 321008,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "บ้านจารย์",
    subdistrictEn: "Ban Chan",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ บ้านจารย์ สุรินทร์ 32150",
    name_th: "สังขะ บ้านจารย์ สุรินทร์ 32150",
    name_en: "Sangkha, Ban Chan, Surin, 32150",
  },
  {
    id: 321009,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "กระเทียม",
    subdistrictEn: "Krathiam",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ กระเทียม สุรินทร์ 32150",
    name_th: "สังขะ กระเทียม สุรินทร์ 32150",
    name_en: "Sangkha, Krathiam, Surin, 32150",
  },
  {
    id: 321010,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "สะกาด",
    subdistrictEn: "Sakat",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ สะกาด สุรินทร์ 32150",
    name_th: "สังขะ สะกาด สุรินทร์ 32150",
    name_en: "Sangkha, Sakat, Surin, 32150",
  },
  {
    id: 321011,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "ตาตุม",
    subdistrictEn: "Ta Tum",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ ตาตุม สุรินทร์ 32150",
    name_th: "สังขะ ตาตุม สุรินทร์ 32150",
    name_en: "Sangkha, Ta Tum, Surin, 32150",
  },
  {
    id: 321012,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "ทับทัน",
    subdistrictEn: "Thap Than",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ ทับทัน สุรินทร์ 32150",
    name_th: "สังขะ ทับทัน สุรินทร์ 32150",
    name_en: "Sangkha, Thap Than, Surin, 32150",
  },
  {
    id: 321013,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "ตาคง",
    subdistrictEn: "Ta Khong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ ตาคง สุรินทร์ 32150",
    name_th: "สังขะ ตาคง สุรินทร์ 32150",
    name_en: "Sangkha, Ta Khong, Surin, 32150",
  },
  {
    id: 321015,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "บ้านชบ",
    subdistrictEn: "Ban Chop",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ บ้านชบ สุรินทร์ 32150",
    name_th: "สังขะ บ้านชบ สุรินทร์ 32150",
    name_en: "Sangkha, Ban Chop, Surin, 32150",
  },
  {
    id: 321017,
    districtTh: "สังขะ",
    districtEn: "Sangkha",
    subdistrictTh: "เทพรักษา",
    subdistrictEn: "Thep Raksa",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3210,
    province_code: 21,
    name: "สังขะ เทพรักษา สุรินทร์ 32150",
    name_th: "สังขะ เทพรักษา สุรินทร์ 32150",
    name_en: "Sangkha, Thep Raksa, Surin, 32150",
  },
  {
    id: 321101,
    districtTh: "ลำดวน",
    districtEn: "Lamduan",
    subdistrictTh: "ลำดวน",
    subdistrictEn: "Lamduan",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32220,
    district_code: 3211,
    province_code: 21,
    name: "ลำดวน ลำดวน สุรินทร์ 32220",
    name_th: "ลำดวน ลำดวน สุรินทร์ 32220",
    name_en: "Lamduan, Lamduan, Surin, 32220",
  },
  {
    id: 321102,
    districtTh: "ลำดวน",
    districtEn: "Lamduan",
    subdistrictTh: "โชคเหนือ",
    subdistrictEn: "Chok Nuea",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32220,
    district_code: 3211,
    province_code: 21,
    name: "ลำดวน โชคเหนือ สุรินทร์ 32220",
    name_th: "ลำดวน โชคเหนือ สุรินทร์ 32220",
    name_en: "Lamduan, Chok Nuea, Surin, 32220",
  },
  {
    id: 321103,
    districtTh: "ลำดวน",
    districtEn: "Lamduan",
    subdistrictTh: "อู่โลก",
    subdistrictEn: "U Lok",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32220,
    district_code: 3211,
    province_code: 21,
    name: "ลำดวน อู่โลก สุรินทร์ 32220",
    name_th: "ลำดวน อู่โลก สุรินทร์ 32220",
    name_en: "Lamduan, U Lok, Surin, 32220",
  },
  {
    id: 321104,
    districtTh: "ลำดวน",
    districtEn: "Lamduan",
    subdistrictTh: "ตรำดม",
    subdistrictEn: "Tram Dom",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32220,
    district_code: 3211,
    province_code: 21,
    name: "ลำดวน ตรำดม สุรินทร์ 32220",
    name_th: "ลำดวน ตรำดม สุรินทร์ 32220",
    name_en: "Lamduan, Tram Dom, Surin, 32220",
  },
  {
    id: 321105,
    districtTh: "ลำดวน",
    districtEn: "Lamduan",
    subdistrictTh: "ตระเปียงเตีย",
    subdistrictEn: "Trapiang Tia",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32220,
    district_code: 3211,
    province_code: 21,
    name: "ลำดวน ตระเปียงเตีย สุรินทร์ 32220",
    name_th: "ลำดวน ตระเปียงเตีย สุรินทร์ 32220",
    name_en: "Lamduan, Trapiang Tia, Surin, 32220",
  },
  {
    id: 321201,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "สำโรงทาบ",
    subdistrictEn: "Samrong Thap",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ สำโรงทาบ สุรินทร์ 32170",
    name_th: "สำโรงทาบ สำโรงทาบ สุรินทร์ 32170",
    name_en: "Samrong Thap, Samrong Thap, Surin, 32170",
  },
  {
    id: 321202,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "หนองไผ่ล้อม",
    subdistrictEn: "Nong Phai Lom",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ หนองไผ่ล้อม สุรินทร์ 32170",
    name_th: "สำโรงทาบ หนองไผ่ล้อม สุรินทร์ 32170",
    name_en: "Samrong Thap, Nong Phai Lom, Surin, 32170",
  },
  {
    id: 321203,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "กระออม",
    subdistrictEn: "Kra-om",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ กระออม สุรินทร์ 32170",
    name_th: "สำโรงทาบ กระออม สุรินทร์ 32170",
    name_en: "Samrong Thap, Kra-om, Surin, 32170",
  },
  {
    id: 321204,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "หนองฮะ",
    subdistrictEn: "Nong Ha",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ หนองฮะ สุรินทร์ 32170",
    name_th: "สำโรงทาบ หนองฮะ สุรินทร์ 32170",
    name_en: "Samrong Thap, Nong Ha, Surin, 32170",
  },
  {
    id: 321205,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "ศรีสุข",
    subdistrictEn: "Si Suk",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ ศรีสุข สุรินทร์ 32170",
    name_th: "สำโรงทาบ ศรีสุข สุรินทร์ 32170",
    name_en: "Samrong Thap, Si Suk, Surin, 32170",
  },
  {
    id: 321206,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "เกาะแก้ว",
    subdistrictEn: "Ko Kaeo",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ เกาะแก้ว สุรินทร์ 32170",
    name_th: "สำโรงทาบ เกาะแก้ว สุรินทร์ 32170",
    name_en: "Samrong Thap, Ko Kaeo, Surin, 32170",
  },
  {
    id: 321207,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "หมื่นศรี",
    subdistrictEn: "Muen Si",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ หมื่นศรี สุรินทร์ 32170",
    name_th: "สำโรงทาบ หมื่นศรี สุรินทร์ 32170",
    name_en: "Samrong Thap, Muen Si, Surin, 32170",
  },
  {
    id: 321208,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "เสม็จ",
    subdistrictEn: "Samet",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ เสม็จ สุรินทร์ 32170",
    name_th: "สำโรงทาบ เสม็จ สุรินทร์ 32170",
    name_en: "Samrong Thap, Samet, Surin, 32170",
  },
  {
    id: 321209,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "สะโน",
    subdistrictEn: "Sano",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ สะโน สุรินทร์ 32170",
    name_th: "สำโรงทาบ สะโน สุรินทร์ 32170",
    name_en: "Samrong Thap, Sano, Surin, 32170",
  },
  {
    id: 321210,
    districtTh: "สำโรงทาบ",
    districtEn: "Samrong Thap",
    subdistrictTh: "ประดู่",
    subdistrictEn: "Pradu",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32170,
    district_code: 3212,
    province_code: 21,
    name: "สำโรงทาบ ประดู่ สุรินทร์ 32170",
    name_th: "สำโรงทาบ ประดู่ สุรินทร์ 32170",
    name_en: "Samrong Thap, Pradu, Surin, 32170",
  },
  {
    id: 321301,
    districtTh: "บัวเชด",
    districtEn: "Buachet",
    subdistrictTh: "บัวเชด",
    subdistrictEn: "Buachet",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32230,
    district_code: 3213,
    province_code: 21,
    name: "บัวเชด บัวเชด สุรินทร์ 32230",
    name_th: "บัวเชด บัวเชด สุรินทร์ 32230",
    name_en: "Buachet, Buachet, Surin, 32230",
  },
  {
    id: 321302,
    districtTh: "บัวเชด",
    districtEn: "Buachet",
    subdistrictTh: "สะเดา",
    subdistrictEn: "Sadao",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32230,
    district_code: 3213,
    province_code: 21,
    name: "บัวเชด สะเดา สุรินทร์ 32230",
    name_th: "บัวเชด สะเดา สุรินทร์ 32230",
    name_en: "Buachet, Sadao, Surin, 32230",
  },
  {
    id: 321303,
    districtTh: "บัวเชด",
    districtEn: "Buachet",
    subdistrictTh: "จรัส",
    subdistrictEn: "Charat",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32230,
    district_code: 3213,
    province_code: 21,
    name: "บัวเชด จรัส สุรินทร์ 32230",
    name_th: "บัวเชด จรัส สุรินทร์ 32230",
    name_en: "Buachet, Charat, Surin, 32230",
  },
  {
    id: 321304,
    districtTh: "บัวเชด",
    districtEn: "Buachet",
    subdistrictTh: "ตาวัง",
    subdistrictEn: "Ta Wang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32230,
    district_code: 3213,
    province_code: 21,
    name: "บัวเชด ตาวัง สุรินทร์ 32230",
    name_th: "บัวเชด ตาวัง สุรินทร์ 32230",
    name_en: "Buachet, Ta Wang, Surin, 32230",
  },
  {
    id: 321305,
    districtTh: "บัวเชด",
    districtEn: "Buachet",
    subdistrictTh: "อาโพน",
    subdistrictEn: "A Phon",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32230,
    district_code: 3213,
    province_code: 21,
    name: "บัวเชด อาโพน สุรินทร์ 32230",
    name_th: "บัวเชด อาโพน สุรินทร์ 32230",
    name_en: "Buachet, A Phon, Surin, 32230",
  },
  {
    id: 321306,
    districtTh: "บัวเชด",
    districtEn: "Buachet",
    subdistrictTh: "สำเภาลูน",
    subdistrictEn: "Samphao Lun",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32230,
    district_code: 3213,
    province_code: 21,
    name: "บัวเชด สำเภาลูน สุรินทร์ 32230",
    name_th: "บัวเชด สำเภาลูน สุรินทร์ 32230",
    name_en: "Buachet, Samphao Lun, Surin, 32230",
  },
  {
    id: 321401,
    districtTh: "พนมดงรัก",
    districtEn: "Phanom Dong Rak",
    subdistrictTh: "บักได",
    subdistrictEn: "Bakdai",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3214,
    province_code: 21,
    name: "พนมดงรัก บักได สุรินทร์ 32140",
    name_th: "พนมดงรัก บักได สุรินทร์ 32140",
    name_en: "Phanom Dong Rak, Bakdai, Surin, 32140",
  },
  {
    id: 321402,
    districtTh: "พนมดงรัก",
    districtEn: "Phanom Dong Rak",
    subdistrictTh: "โคกกลาง",
    subdistrictEn: "Khok Klang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3214,
    province_code: 21,
    name: "พนมดงรัก โคกกลาง สุรินทร์ 32140",
    name_th: "พนมดงรัก โคกกลาง สุรินทร์ 32140",
    name_en: "Phanom Dong Rak, Khok Klang, Surin, 32140",
  },
  {
    id: 321403,
    districtTh: "พนมดงรัก",
    districtEn: "Phanom Dong Rak",
    subdistrictTh: "จีกแดก",
    subdistrictEn: "Chik Daek",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3214,
    province_code: 21,
    name: "พนมดงรัก จีกแดก สุรินทร์ 32140",
    name_th: "พนมดงรัก จีกแดก สุรินทร์ 32140",
    name_en: "Phanom Dong Rak, Chik Daek, Surin, 32140",
  },
  {
    id: 321404,
    districtTh: "พนมดงรัก",
    districtEn: "Phanom Dong Rak",
    subdistrictTh: "ตาเมียง",
    subdistrictEn: "Ta Miang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32140,
    district_code: 3214,
    province_code: 21,
    name: "พนมดงรัก ตาเมียง สุรินทร์ 32140",
    name_th: "พนมดงรัก ตาเมียง สุรินทร์ 32140",
    name_en: "Phanom Dong Rak, Ta Miang, Surin, 32140",
  },
  {
    id: 321501,
    districtTh: "ศรีณรงค์",
    districtEn: "Si Narong",
    subdistrictTh: "ณรงค์",
    subdistrictEn: "Narong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3215,
    province_code: 21,
    name: "ศรีณรงค์ ณรงค์ สุรินทร์ 32150",
    name_th: "ศรีณรงค์ ณรงค์ สุรินทร์ 32150",
    name_en: "Si Narong, Narong, Surin, 32150",
  },
  {
    id: 321502,
    districtTh: "ศรีณรงค์",
    districtEn: "Si Narong",
    subdistrictTh: "แจนแวน",
    subdistrictEn: "Chaenwaen",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3215,
    province_code: 21,
    name: "ศรีณรงค์ แจนแวน สุรินทร์ 32150",
    name_th: "ศรีณรงค์ แจนแวน สุรินทร์ 32150",
    name_en: "Si Narong, Chaenwaen, Surin, 32150",
  },
  {
    id: 321503,
    districtTh: "ศรีณรงค์",
    districtEn: "Si Narong",
    subdistrictTh: "ตรวจ",
    subdistrictEn: "Truat",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3215,
    province_code: 21,
    name: "ศรีณรงค์ ตรวจ สุรินทร์ 32150",
    name_th: "ศรีณรงค์ ตรวจ สุรินทร์ 32150",
    name_en: "Si Narong, Truat, Surin, 32150",
  },
  {
    id: 321504,
    districtTh: "ศรีณรงค์",
    districtEn: "Si Narong",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3215,
    province_code: 21,
    name: "ศรีณรงค์ หนองแวง สุรินทร์ 32150",
    name_th: "ศรีณรงค์ หนองแวง สุรินทร์ 32150",
    name_en: "Si Narong, Nong Waeng, Surin, 32150",
  },
  {
    id: 321505,
    districtTh: "ศรีณรงค์",
    districtEn: "Si Narong",
    subdistrictTh: "ศรีสุข",
    subdistrictEn: "Si Suk",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32150,
    district_code: 3215,
    province_code: 21,
    name: "ศรีณรงค์ ศรีสุข สุรินทร์ 32150",
    name_th: "ศรีณรงค์ ศรีสุข สุรินทร์ 32150",
    name_en: "Si Narong, Si Suk, Surin, 32150",
  },
  {
    id: 321601,
    districtTh: "เขวาสินรินทร์",
    districtEn: "Khwao Sinarin",
    subdistrictTh: "เขวาสินรินทร์",
    subdistrictEn: "Khwao Sinarin",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3216,
    province_code: 21,
    name: "เขวาสินรินทร์ เขวาสินรินทร์ สุรินทร์ 32000",
    name_th: "เขวาสินรินทร์ เขวาสินรินทร์ สุรินทร์ 32000",
    name_en: "Khwao Sinarin, Khwao Sinarin, Surin, 32000",
  },
  {
    id: 321602,
    districtTh: "เขวาสินรินทร์",
    districtEn: "Khwao Sinarin",
    subdistrictTh: "บึง",
    subdistrictEn: "Bueng",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3216,
    province_code: 21,
    name: "เขวาสินรินทร์ บึง สุรินทร์ 32000",
    name_th: "เขวาสินรินทร์ บึง สุรินทร์ 32000",
    name_en: "Khwao Sinarin, Bueng, Surin, 32000",
  },
  {
    id: 321603,
    districtTh: "เขวาสินรินทร์",
    districtEn: "Khwao Sinarin",
    subdistrictTh: "ตากูก",
    subdistrictEn: "Ta Kuk",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3216,
    province_code: 21,
    name: "เขวาสินรินทร์ ตากูก สุรินทร์ 32000",
    name_th: "เขวาสินรินทร์ ตากูก สุรินทร์ 32000",
    name_en: "Khwao Sinarin, Ta Kuk, Surin, 32000",
  },
  {
    id: 321604,
    districtTh: "เขวาสินรินทร์",
    districtEn: "Khwao Sinarin",
    subdistrictTh: "ปราสาททอง",
    subdistrictEn: "Prasat Thong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3216,
    province_code: 21,
    name: "เขวาสินรินทร์ ปราสาททอง สุรินทร์ 32000",
    name_th: "เขวาสินรินทร์ ปราสาททอง สุรินทร์ 32000",
    name_en: "Khwao Sinarin, Prasat Thong, Surin, 32000",
  },
  {
    id: 321605,
    districtTh: "เขวาสินรินทร์",
    districtEn: "Khwao Sinarin",
    subdistrictTh: "บ้านแร่",
    subdistrictEn: "Ban Rae",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32000,
    district_code: 3216,
    province_code: 21,
    name: "เขวาสินรินทร์ บ้านแร่ สุรินทร์ 32000",
    name_th: "เขวาสินรินทร์ บ้านแร่ สุรินทร์ 32000",
    name_en: "Khwao Sinarin, Ban Rae, Surin, 32000",
  },
  {
    id: 321701,
    districtTh: "โนนนารายณ์",
    districtEn: "Non Narai",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3217,
    province_code: 21,
    name: "โนนนารายณ์ หนองหลวง สุรินทร์ 32130",
    name_th: "โนนนารายณ์ หนองหลวง สุรินทร์ 32130",
    name_en: "Non Narai, Nong Luang, Surin, 32130",
  },
  {
    id: 321702,
    districtTh: "โนนนารายณ์",
    districtEn: "Non Narai",
    subdistrictTh: "คำผง",
    subdistrictEn: "Kham Phong",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3217,
    province_code: 21,
    name: "โนนนารายณ์ คำผง สุรินทร์ 32130",
    name_th: "โนนนารายณ์ คำผง สุรินทร์ 32130",
    name_en: "Non Narai, Kham Phong, Surin, 32130",
  },
  {
    id: 321703,
    districtTh: "โนนนารายณ์",
    districtEn: "Non Narai",
    subdistrictTh: "โนน",
    subdistrictEn: "Non",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3217,
    province_code: 21,
    name: "โนนนารายณ์ โนน สุรินทร์ 32130",
    name_th: "โนนนารายณ์ โนน สุรินทร์ 32130",
    name_en: "Non Narai, Non, Surin, 32130",
  },
  {
    id: 321704,
    districtTh: "โนนนารายณ์",
    districtEn: "Non Narai",
    subdistrictTh: "ระเวียง",
    subdistrictEn: "Rawiang",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3217,
    province_code: 21,
    name: "โนนนารายณ์ ระเวียง สุรินทร์ 32130",
    name_th: "โนนนารายณ์ ระเวียง สุรินทร์ 32130",
    name_en: "Non Narai, Rawiang, Surin, 32130",
  },
  {
    id: 321705,
    districtTh: "โนนนารายณ์",
    districtEn: "Non Narai",
    subdistrictTh: "หนองเทพ",
    subdistrictEn: "Nong Thep",
    provinceTh: "สุรินทร์",
    provinceEn: "Surin",
    zipcode: 32130,
    district_code: 3217,
    province_code: 21,
    name: "โนนนารายณ์ หนองเทพ สุรินทร์ 32130",
    name_th: "โนนนารายณ์ หนองเทพ สุรินทร์ 32130",
    name_en: "Non Narai, Nong Thep, Surin, 32130",
  },
  {
    id: 330101,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "เมืองเหนือ",
    subdistrictEn: "Mueang Nuea",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ เมืองเหนือ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ เมืองเหนือ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Mueang Nuea, Si Sa Ket, 33000",
  },
  {
    id: 330102,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "เมืองใต้",
    subdistrictEn: "Mueang Tai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ เมืองใต้ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ เมืองใต้ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Mueang Tai, Si Sa Ket, 33000",
  },
  {
    id: 330103,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "คูซอด",
    subdistrictEn: "Khu Sot",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ คูซอด ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ คูซอด ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Khu Sot, Si Sa Ket, 33000",
  },
  {
    id: 330104,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "ซำ",
    subdistrictEn: "Sam",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ ซำ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ ซำ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Sam, Si Sa Ket, 33000",
  },
  {
    id: 330105,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "จาน",
    subdistrictEn: "Chan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ จาน ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ จาน ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Chan, Si Sa Ket, 33000",
  },
  {
    id: 330106,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "ตะดอบ",
    subdistrictEn: "Tadop",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ ตะดอบ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ ตะดอบ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Tadop, Si Sa Ket, 33000",
  },
  {
    id: 330107,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "หนองครก",
    subdistrictEn: "Nong Khrok",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ หนองครก ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ หนองครก ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Nong Khrok, Si Sa Ket, 33000",
  },
  {
    id: 330111,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "โพนข่า",
    subdistrictEn: "Phon Kha",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ โพนข่า ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ โพนข่า ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Phon Kha, Si Sa Ket, 33000",
  },
  {
    id: 330112,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "โพนค้อ",
    subdistrictEn: "Phon Kho",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ โพนค้อ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ โพนค้อ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Phon Kho, Si Sa Ket, 33000",
  },
  {
    id: 330115,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "โพนเขวา",
    subdistrictEn: "Phon Khwao",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ โพนเขวา ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ โพนเขวา ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Phon Khwao, Si Sa Ket, 33000",
  },
  {
    id: 330116,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "หญ้าปล้อง",
    subdistrictEn: "Ya Plong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ หญ้าปล้อง ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ หญ้าปล้อง ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Ya Plong, Si Sa Ket, 33000",
  },
  {
    id: 330118,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "ทุ่ม",
    subdistrictEn: "Thum",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ ทุ่ม ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ ทุ่ม ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Thum, Si Sa Ket, 33000",
  },
  {
    id: 330119,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "หนองไฮ",
    subdistrictEn: "Nong Hai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ หนองไฮ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ หนองไฮ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Nong Hai, Si Sa Ket, 33000",
  },
  {
    id: 330121,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "หนองแก้ว",
    subdistrictEn: "Nong Kaeo",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ หนองแก้ว ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ หนองแก้ว ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Nong Kaeo, Si Sa Ket, 33000",
  },
  {
    id: 330122,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "น้ำคำ",
    subdistrictEn: "Nam Kham",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ น้ำคำ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ น้ำคำ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Nam Kham, Si Sa Ket, 33000",
  },
  {
    id: 330123,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "โพธิ์",
    subdistrictEn: "Pho",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ โพธิ์ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ โพธิ์ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Pho, Si Sa Ket, 33000",
  },
  {
    id: 330124,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "หมากเขียบ",
    subdistrictEn: "Mak Khiap",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ หมากเขียบ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ หมากเขียบ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Mak Khiap, Si Sa Ket, 33000",
  },
  {
    id: 330127,
    districtTh: "เมืองศรีสะเกษ",
    districtEn: "Mueang Si Sa Ket",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33000,
    district_code: 3301,
    province_code: 22,
    name: "เมืองศรีสะเกษ หนองไผ่ ศรีสะเกษ 33000",
    name_th: "เมืองศรีสะเกษ หนองไผ่ ศรีสะเกษ 33000",
    name_en: "Mueang Si Sa Ket, Nong Phai, Si Sa Ket, 33000",
  },
  {
    id: 330201,
    districtTh: "ยางชุมน้อย",
    districtEn: "Yang Chum Noi",
    subdistrictTh: "ยางชุมน้อย",
    subdistrictEn: "Yang Chum Noi",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33190,
    district_code: 3302,
    province_code: 22,
    name: "ยางชุมน้อย ยางชุมน้อย ศรีสะเกษ 33190",
    name_th: "ยางชุมน้อย ยางชุมน้อย ศรีสะเกษ 33190",
    name_en: "Yang Chum Noi, Yang Chum Noi, Si Sa Ket, 33190",
  },
  {
    id: 330202,
    districtTh: "ยางชุมน้อย",
    districtEn: "Yang Chum Noi",
    subdistrictTh: "ลิ้นฟ้า",
    subdistrictEn: "Lin Fa",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33190,
    district_code: 3302,
    province_code: 22,
    name: "ยางชุมน้อย ลิ้นฟ้า ศรีสะเกษ 33190",
    name_th: "ยางชุมน้อย ลิ้นฟ้า ศรีสะเกษ 33190",
    name_en: "Yang Chum Noi, Lin Fa, Si Sa Ket, 33190",
  },
  {
    id: 330203,
    districtTh: "ยางชุมน้อย",
    districtEn: "Yang Chum Noi",
    subdistrictTh: "คอนกาม",
    subdistrictEn: "Khon Kam",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33190,
    district_code: 3302,
    province_code: 22,
    name: "ยางชุมน้อย คอนกาม ศรีสะเกษ 33190",
    name_th: "ยางชุมน้อย คอนกาม ศรีสะเกษ 33190",
    name_en: "Yang Chum Noi, Khon Kam, Si Sa Ket, 33190",
  },
  {
    id: 330204,
    districtTh: "ยางชุมน้อย",
    districtEn: "Yang Chum Noi",
    subdistrictTh: "โนนคูณ",
    subdistrictEn: "Non Khun",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33190,
    district_code: 3302,
    province_code: 22,
    name: "ยางชุมน้อย โนนคูณ ศรีสะเกษ 33190",
    name_th: "ยางชุมน้อย โนนคูณ ศรีสะเกษ 33190",
    name_en: "Yang Chum Noi, Non Khun, Si Sa Ket, 33190",
  },
  {
    id: 330205,
    districtTh: "ยางชุมน้อย",
    districtEn: "Yang Chum Noi",
    subdistrictTh: "กุดเมืองฮาม",
    subdistrictEn: "Kut Mueang Ham",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33190,
    district_code: 3302,
    province_code: 22,
    name: "ยางชุมน้อย กุดเมืองฮาม ศรีสะเกษ 33190",
    name_th: "ยางชุมน้อย กุดเมืองฮาม ศรีสะเกษ 33190",
    name_en: "Yang Chum Noi, Kut Mueang Ham, Si Sa Ket, 33190",
  },
  {
    id: 330206,
    districtTh: "ยางชุมน้อย",
    districtEn: "Yang Chum Noi",
    subdistrictTh: "บึงบอน",
    subdistrictEn: "Bueng Bon",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33190,
    district_code: 3302,
    province_code: 22,
    name: "ยางชุมน้อย บึงบอน ศรีสะเกษ 33190",
    name_th: "ยางชุมน้อย บึงบอน ศรีสะเกษ 33190",
    name_en: "Yang Chum Noi, Bueng Bon, Si Sa Ket, 33190",
  },
  {
    id: 330207,
    districtTh: "ยางชุมน้อย",
    districtEn: "Yang Chum Noi",
    subdistrictTh: "ยางชุมใหญ่",
    subdistrictEn: "Yang Chum Yai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33190,
    district_code: 3302,
    province_code: 22,
    name: "ยางชุมน้อย ยางชุมใหญ่ ศรีสะเกษ 33190",
    name_th: "ยางชุมน้อย ยางชุมใหญ่ ศรีสะเกษ 33190",
    name_en: "Yang Chum Noi, Yang Chum Yai, Si Sa Ket, 33190",
  },
  {
    id: 330301,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "ดูน",
    subdistrictEn: "Dun",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ ดูน ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ ดูน ศรีสะเกษ 33130",
    name_en: "Kanthararom, Dun, Si Sa Ket, 33130",
  },
  {
    id: 330302,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "โนนสัง",
    subdistrictEn: "Non Sang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ โนนสัง ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ โนนสัง ศรีสะเกษ 33130",
    name_en: "Kanthararom, Non Sang, Si Sa Ket, 33130",
  },
  {
    id: 330303,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "หนองหัวช้าง",
    subdistrictEn: "Nong Hua Chang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ หนองหัวช้าง ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ หนองหัวช้าง ศรีสะเกษ 33130",
    name_en: "Kanthararom, Nong Hua Chang, Si Sa Ket, 33130",
  },
  {
    id: 330304,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "ยาง",
    subdistrictEn: "Yang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ ยาง ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ ยาง ศรีสะเกษ 33130",
    name_en: "Kanthararom, Yang, Si Sa Ket, 33130",
  },
  {
    id: 330305,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ หนองแวง ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ หนองแวง ศรีสะเกษ 33130",
    name_en: "Kanthararom, Nong Waeng, Si Sa Ket, 33130",
  },
  {
    id: 330306,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "หนองแก้ว",
    subdistrictEn: "Nong Kaeo",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ หนองแก้ว ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ หนองแก้ว ศรีสะเกษ 33130",
    name_en: "Kanthararom, Nong Kaeo, Si Sa Ket, 33130",
  },
  {
    id: 330307,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "ทาม",
    subdistrictEn: "Tham",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ ทาม ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ ทาม ศรีสะเกษ 33130",
    name_en: "Kanthararom, Tham, Si Sa Ket, 33130",
  },
  {
    id: 330308,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "ละทาย",
    subdistrictEn: "Lathai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ ละทาย ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ ละทาย ศรีสะเกษ 33130",
    name_en: "Kanthararom, Lathai, Si Sa Ket, 33130",
  },
  {
    id: 330309,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "เมืองน้อย",
    subdistrictEn: "Mueang Noi",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ เมืองน้อย ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ เมืองน้อย ศรีสะเกษ 33130",
    name_en: "Kanthararom, Mueang Noi, Si Sa Ket, 33130",
  },
  {
    id: 330310,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "อีปาด",
    subdistrictEn: "I Pat",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ อีปาด ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ อีปาด ศรีสะเกษ 33130",
    name_en: "Kanthararom, I Pat, Si Sa Ket, 33130",
  },
  {
    id: 330311,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "บัวน้อย",
    subdistrictEn: "Bua Noi",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ บัวน้อย ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ บัวน้อย ศรีสะเกษ 33130",
    name_en: "Kanthararom, Bua Noi, Si Sa Ket, 33130",
  },
  {
    id: 330312,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ หนองบัว ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ หนองบัว ศรีสะเกษ 33130",
    name_en: "Kanthararom, Nong Bua, Si Sa Ket, 33130",
  },
  {
    id: 330313,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "ดู่",
    subdistrictEn: "Du",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ ดู่ ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ ดู่ ศรีสะเกษ 33130",
    name_en: "Kanthararom, Du, Si Sa Ket, 33130",
  },
  {
    id: 330314,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "ผักแพว",
    subdistrictEn: "Phak Phaeo",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ ผักแพว ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ ผักแพว ศรีสะเกษ 33130",
    name_en: "Kanthararom, Phak Phaeo, Si Sa Ket, 33130",
  },
  {
    id: 330315,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "จาน",
    subdistrictEn: "Chan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ จาน ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ จาน ศรีสะเกษ 33130",
    name_en: "Kanthararom, Chan, Si Sa Ket, 33130",
  },
  {
    id: 330320,
    districtTh: "กันทรารมย์",
    districtEn: "Kanthararom",
    subdistrictTh: "คำเนียม",
    subdistrictEn: "Kham Niam",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3303,
    province_code: 22,
    name: "กันทรารมย์ คำเนียม ศรีสะเกษ 33130",
    name_th: "กันทรารมย์ คำเนียม ศรีสะเกษ 33130",
    name_en: "Kanthararom, Kham Niam, Si Sa Ket, 33130",
  },
  {
    id: 330401,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "บึงมะลู",
    subdistrictEn: "Bueng Malu",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ บึงมะลู ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ บึงมะลู ศรีสะเกษ 33110",
    name_en: "Kantharalak, Bueng Malu, Si Sa Ket, 33110",
  },
  {
    id: 330402,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "กุดเสลา",
    subdistrictEn: "Kut Salao",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ กุดเสลา ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ กุดเสลา ศรีสะเกษ 33110",
    name_en: "Kantharalak, Kut Salao, Si Sa Ket, 33110",
  },
  {
    id: 330403,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "เมือง",
    subdistrictEn: "Mueang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ เมือง ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ เมือง ศรีสะเกษ 33110",
    name_en: "Kantharalak, Mueang, Si Sa Ket, 33110",
  },
  {
    id: 330405,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "สังเม็ก",
    subdistrictEn: "Sang Mek",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ สังเม็ก ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ สังเม็ก ศรีสะเกษ 33110",
    name_en: "Kantharalak, Sang Mek, Si Sa Ket, 33110",
  },
  {
    id: 330406,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "น้ำอ้อม",
    subdistrictEn: "Nam Om",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ น้ำอ้อม ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ น้ำอ้อม ศรีสะเกษ 33110",
    name_en: "Kantharalak, Nam Om, Si Sa Ket, 33110",
  },
  {
    id: 330407,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "ละลาย",
    subdistrictEn: "Lalai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ ละลาย ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ ละลาย ศรีสะเกษ 33110",
    name_en: "Kantharalak, Lalai, Si Sa Ket, 33110",
  },
  {
    id: 330408,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "รุง",
    subdistrictEn: "Rung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ รุง ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ รุง ศรีสะเกษ 33110",
    name_en: "Kantharalak, Rung, Si Sa Ket, 33110",
  },
  {
    id: 330409,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "ตระกาจ",
    subdistrictEn: "Trakat",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ ตระกาจ ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ ตระกาจ ศรีสะเกษ 33110",
    name_en: "Kantharalak, Trakat, Si Sa Ket, 33110",
  },
  {
    id: 330411,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "จานใหญ่",
    subdistrictEn: "Chan Yai)",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ จานใหญ่ ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ จานใหญ่ ศรีสะเกษ 33110",
    name_en: "Kantharalak, Chan Yai), Si Sa Ket, 33110",
  },
  {
    id: 330412,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "ภูเงิน",
    subdistrictEn: "Phu Ngoen",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ ภูเงิน ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ ภูเงิน ศรีสะเกษ 33110",
    name_en: "Kantharalak, Phu Ngoen, Si Sa Ket, 33110",
  },
  {
    id: 330414,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "กระแชง",
    subdistrictEn: "Krachaeng",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ กระแชง ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ กระแชง ศรีสะเกษ 33110",
    name_en: "Kantharalak, Krachaeng, Si Sa Ket, 33110",
  },
  {
    id: 330415,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "โนนสำราญ",
    subdistrictEn: "Non Samran",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ โนนสำราญ ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ โนนสำราญ ศรีสะเกษ 33110",
    name_en: "Kantharalak, Non Samran, Si Sa Ket, 33110",
  },
  {
    id: 330416,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "หนองหญ้าลาด",
    subdistrictEn: "Nong Ya Lat",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ หนองหญ้าลาด ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ หนองหญ้าลาด ศรีสะเกษ 33110",
    name_en: "Kantharalak, Nong Ya Lat, Si Sa Ket, 33110",
  },
  {
    id: 330419,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "เสาธงชัย",
    subdistrictEn: "Sao Thong Chai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ เสาธงชัย ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ เสาธงชัย ศรีสะเกษ 33110",
    name_en: "Kantharalak, Sao Thong Chai, Si Sa Ket, 33110",
  },
  {
    id: 330420,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "ขนุน",
    subdistrictEn: "Khanun",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ ขนุน ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ ขนุน ศรีสะเกษ 33110",
    name_en: "Kantharalak, Khanun, Si Sa Ket, 33110",
  },
  {
    id: 330421,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "สวนกล้วย",
    subdistrictEn: "Suan Kluai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ สวนกล้วย ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ สวนกล้วย ศรีสะเกษ 33110",
    name_en: "Kantharalak, Suan Kluai, Si Sa Ket, 33110",
  },
  {
    id: 330423,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "เวียงเหนือ",
    subdistrictEn: "Wiang Nuea",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ เวียงเหนือ ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ เวียงเหนือ ศรีสะเกษ 33110",
    name_en: "Kantharalak, Wiang Nuea, Si Sa Ket, 33110",
  },
  {
    id: 330424,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "ทุ่งใหญ่",
    subdistrictEn: "Thung Yai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ ทุ่งใหญ่ ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ ทุ่งใหญ่ ศรีสะเกษ 33110",
    name_en: "Kantharalak, Thung Yai, Si Sa Ket, 33110",
  },
  {
    id: 330425,
    districtTh: "กันทรลักษ์",
    districtEn: "Kantharalak",
    subdistrictTh: "ภูผาหมอก",
    subdistrictEn: "Phu Pha Mok",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3304,
    province_code: 22,
    name: "กันทรลักษ์ ภูผาหมอก ศรีสะเกษ 33110",
    name_th: "กันทรลักษ์ ภูผาหมอก ศรีสะเกษ 33110",
    name_en: "Kantharalak, Phu Pha Mok, Si Sa Ket, 33110",
  },
  {
    id: 330501,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "กันทรารมย์",
    subdistrictEn: "Kanthararom",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ กันทรารมย์ ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ กันทรารมย์ ศรีสะเกษ 33140",
    name_en: "Khukhan, Kanthararom, Si Sa Ket, 33140",
  },
  {
    id: 330502,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "จะกง",
    subdistrictEn: "Chakong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ จะกง ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ จะกง ศรีสะเกษ 33140",
    name_en: "Khukhan, Chakong, Si Sa Ket, 33140",
  },
  {
    id: 330503,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ใจดี",
    subdistrictEn: "Chai Di",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ใจดี ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ใจดี ศรีสะเกษ 33140",
    name_en: "Khukhan, Chai Di, Si Sa Ket, 33140",
  },
  {
    id: 330504,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ดองกำเม็ด",
    subdistrictEn: "Dong Kammet",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ดองกำเม็ด ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ดองกำเม็ด ศรีสะเกษ 33140",
    name_en: "Khukhan, Dong Kammet, Si Sa Ket, 33140",
  },
  {
    id: 330505,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "โสน",
    subdistrictEn: "Sano",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ โสน ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ โสน ศรีสะเกษ 33140",
    name_en: "Khukhan, Sano, Si Sa Ket, 33140",
  },
  {
    id: 330506,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ปรือใหญ่",
    subdistrictEn: "Prue Yai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ปรือใหญ่ ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ปรือใหญ่ ศรีสะเกษ 33140",
    name_en: "Khukhan, Prue Yai, Si Sa Ket, 33140",
  },
  {
    id: 330507,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "สะเดาใหญ่",
    subdistrictEn: "Sadao Yai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ สะเดาใหญ่ ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ สะเดาใหญ่ ศรีสะเกษ 33140",
    name_en: "Khukhan, Sadao Yai, Si Sa Ket, 33140",
  },
  {
    id: 330508,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ตาอุด",
    subdistrictEn: "Ta Ut",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ตาอุด ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ตาอุด ศรีสะเกษ 33140",
    name_en: "Khukhan, Ta Ut, Si Sa Ket, 33140",
  },
  {
    id: 330509,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ห้วยเหนือ",
    subdistrictEn: "Huai Nuea",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ห้วยเหนือ ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ห้วยเหนือ ศรีสะเกษ 33140",
    name_en: "Khukhan, Huai Nuea, Si Sa Ket, 33140",
  },
  {
    id: 330510,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ห้วยใต้",
    subdistrictEn: "Huai Tai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ห้วยใต้ ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ห้วยใต้ ศรีสะเกษ 33140",
    name_en: "Khukhan, Huai Tai, Si Sa Ket, 33140",
  },
  {
    id: 330511,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "หัวเสือ",
    subdistrictEn: "Hua Suea",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ หัวเสือ ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ หัวเสือ ศรีสะเกษ 33140",
    name_en: "Khukhan, Hua Suea, Si Sa Ket, 33140",
  },
  {
    id: 330513,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ตะเคียน",
    subdistrictEn: "Takhian",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ตะเคียน ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ตะเคียน ศรีสะเกษ 33140",
    name_en: "Khukhan, Takhian, Si Sa Ket, 33140",
  },
  {
    id: 330515,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "นิคมพัฒนา",
    subdistrictEn: "Nikhom Phatthana",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ นิคมพัฒนา ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ นิคมพัฒนา ศรีสะเกษ 33140",
    name_en: "Khukhan, Nikhom Phatthana, Si Sa Ket, 33140",
  },
  {
    id: 330517,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "โคกเพชร",
    subdistrictEn: "Khok Phet",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ โคกเพชร ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ โคกเพชร ศรีสะเกษ 33140",
    name_en: "Khukhan, Khok Phet, Si Sa Ket, 33140",
  },
  {
    id: 330518,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ปราสาท",
    subdistrictEn: "Prasat",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ปราสาท ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ปราสาท ศรีสะเกษ 33140",
    name_en: "Khukhan, Prasat, Si Sa Ket, 33140",
  },
  {
    id: 330521,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "สำโรงตาเจ็น",
    subdistrictEn: "Samrong Ta Chen",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ สำโรงตาเจ็น ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ สำโรงตาเจ็น ศรีสะเกษ 33140",
    name_en: "Khukhan, Samrong Ta Chen, Si Sa Ket, 33140",
  },
  {
    id: 330522,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ห้วยสำราญ",
    subdistrictEn: "Huai Samran",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ห้วยสำราญ ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ห้วยสำราญ ศรีสะเกษ 33140",
    name_en: "Khukhan, Huai Samran, Si Sa Ket, 33140",
  },
  {
    id: 330524,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "กฤษณา",
    subdistrictEn: "Kritsana",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ กฤษณา ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ กฤษณา ศรีสะเกษ 33140",
    name_en: "Khukhan, Kritsana, Si Sa Ket, 33140",
  },
  {
    id: 330525,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ลมศักดิ์",
    subdistrictEn: "Lom Sak",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ลมศักดิ์ ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ลมศักดิ์ ศรีสะเกษ 33140",
    name_en: "Khukhan, Lom Sak, Si Sa Ket, 33140",
  },
  {
    id: 330526,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "หนองฉลอง",
    subdistrictEn: "Nong Chalong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ หนองฉลอง ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ หนองฉลอง ศรีสะเกษ 33140",
    name_en: "Khukhan, Nong Chalong, Si Sa Ket, 33140",
  },
  {
    id: 330527,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ศรีตระกูล",
    subdistrictEn: "Si Trakun",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ศรีตระกูล ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ศรีตระกูล ศรีสะเกษ 33140",
    name_en: "Khukhan, Si Trakun, Si Sa Ket, 33140",
  },
  {
    id: 330528,
    districtTh: "ขุขันธ์",
    districtEn: "Khukhan",
    subdistrictTh: "ศรีสะอาด",
    subdistrictEn: "Si Sa-at",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3305,
    province_code: 22,
    name: "ขุขันธ์ ศรีสะอาด ศรีสะเกษ 33140",
    name_th: "ขุขันธ์ ศรีสะอาด ศรีสะเกษ 33140",
    name_en: "Khukhan, Si Sa-at, Si Sa Ket, 33140",
  },
  {
    id: 330601,
    districtTh: "ไพรบึง",
    districtEn: "Phrai Bueng",
    subdistrictTh: "ไพรบึง",
    subdistrictEn: "Phrai Bueng",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33180,
    district_code: 3306,
    province_code: 22,
    name: "ไพรบึง ไพรบึง ศรีสะเกษ 33180",
    name_th: "ไพรบึง ไพรบึง ศรีสะเกษ 33180",
    name_en: "Phrai Bueng, Phrai Bueng, Si Sa Ket, 33180",
  },
  {
    id: 330602,
    districtTh: "ไพรบึง",
    districtEn: "Phrai Bueng",
    subdistrictTh: "ดินแดง",
    subdistrictEn: "Din Daeng",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33180,
    district_code: 3306,
    province_code: 22,
    name: "ไพรบึง ดินแดง ศรีสะเกษ 33180",
    name_th: "ไพรบึง ดินแดง ศรีสะเกษ 33180",
    name_en: "Phrai Bueng, Din Daeng, Si Sa Ket, 33180",
  },
  {
    id: 330603,
    districtTh: "ไพรบึง",
    districtEn: "Phrai Bueng",
    subdistrictTh: "ปราสาทเยอ",
    subdistrictEn: "Prasat Yoe",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33180,
    district_code: 3306,
    province_code: 22,
    name: "ไพรบึง ปราสาทเยอ ศรีสะเกษ 33180",
    name_th: "ไพรบึง ปราสาทเยอ ศรีสะเกษ 33180",
    name_en: "Phrai Bueng, Prasat Yoe, Si Sa Ket, 33180",
  },
  {
    id: 330604,
    districtTh: "ไพรบึง",
    districtEn: "Phrai Bueng",
    subdistrictTh: "สำโรงพลัน",
    subdistrictEn: "Samrong Phlan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33180,
    district_code: 3306,
    province_code: 22,
    name: "ไพรบึง สำโรงพลัน ศรีสะเกษ 33180",
    name_th: "ไพรบึง สำโรงพลัน ศรีสะเกษ 33180",
    name_en: "Phrai Bueng, Samrong Phlan, Si Sa Ket, 33180",
  },
  {
    id: 330605,
    districtTh: "ไพรบึง",
    districtEn: "Phrai Bueng",
    subdistrictTh: "สุขสวัสดิ์",
    subdistrictEn: "Suk Sawat",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33180,
    district_code: 3306,
    province_code: 22,
    name: "ไพรบึง สุขสวัสดิ์ ศรีสะเกษ 33180",
    name_th: "ไพรบึง สุขสวัสดิ์ ศรีสะเกษ 33180",
    name_en: "Phrai Bueng, Suk Sawat, Si Sa Ket, 33180",
  },
  {
    id: 330606,
    districtTh: "ไพรบึง",
    districtEn: "Phrai Bueng",
    subdistrictTh: "โนนปูน",
    subdistrictEn: "Non Pun",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33180,
    district_code: 3306,
    province_code: 22,
    name: "ไพรบึง โนนปูน ศรีสะเกษ 33180",
    name_th: "ไพรบึง โนนปูน ศรีสะเกษ 33180",
    name_en: "Phrai Bueng, Non Pun, Si Sa Ket, 33180",
  },
  {
    id: 330701,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "พิมาย",
    subdistrictEn: "Phimai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ พิมาย ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ พิมาย ศรีสะเกษ 33170",
    name_en: "Prang Ku, Phimai, Si Sa Ket, 33170",
  },
  {
    id: 330702,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "กู่",
    subdistrictEn: "Ku",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ กู่ ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ กู่ ศรีสะเกษ 33170",
    name_en: "Prang Ku, Ku, Si Sa Ket, 33170",
  },
  {
    id: 330703,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "หนองเชียงทูน",
    subdistrictEn: "Nong Chiang Thun",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ หนองเชียงทูน ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ หนองเชียงทูน ศรีสะเกษ 33170",
    name_en: "Prang Ku, Nong Chiang Thun, Si Sa Ket, 33170",
  },
  {
    id: 330704,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "ตูม",
    subdistrictEn: "Tum",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ ตูม ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ ตูม ศรีสะเกษ 33170",
    name_en: "Prang Ku, Tum, Si Sa Ket, 33170",
  },
  {
    id: 330705,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "สมอ",
    subdistrictEn: "Samo",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ สมอ ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ สมอ ศรีสะเกษ 33170",
    name_en: "Prang Ku, Samo, Si Sa Ket, 33170",
  },
  {
    id: 330706,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "โพธิ์ศรี",
    subdistrictEn: "Pho Si",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ โพธิ์ศรี ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ โพธิ์ศรี ศรีสะเกษ 33170",
    name_en: "Prang Ku, Pho Si, Si Sa Ket, 33170",
  },
  {
    id: 330707,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "สำโรงปราสาท",
    subdistrictEn: "Samrong Prasat",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ สำโรงปราสาท ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ สำโรงปราสาท ศรีสะเกษ 33170",
    name_en: "Prang Ku, Samrong Prasat, Si Sa Ket, 33170",
  },
  {
    id: 330708,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "ดู่",
    subdistrictEn: "Du",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ ดู่ ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ ดู่ ศรีสะเกษ 33170",
    name_en: "Prang Ku, Du, Si Sa Ket, 33170",
  },
  {
    id: 330709,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "สวาย",
    subdistrictEn: "Sawai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ สวาย ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ สวาย ศรีสะเกษ 33170",
    name_en: "Prang Ku, Sawai, Si Sa Ket, 33170",
  },
  {
    id: 330710,
    districtTh: "ปรางค์กู่",
    districtEn: "Prang Ku",
    subdistrictTh: "พิมายเหนือ",
    subdistrictEn: "Phimai Nuea",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33170,
    district_code: 3307,
    province_code: 22,
    name: "ปรางค์กู่ พิมายเหนือ ศรีสะเกษ 33170",
    name_th: "ปรางค์กู่ พิมายเหนือ ศรีสะเกษ 33170",
    name_en: "Prang Ku, Phimai Nuea, Si Sa Ket, 33170",
  },
  {
    id: 330801,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "สิ",
    subdistrictEn: "Si",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ สิ ศรีสะเกษ 33150",
    name_th: "ขุนหาญ สิ ศรีสะเกษ 33150",
    name_en: "Khun Han, Si, Si Sa Ket, 33150",
  },
  {
    id: 330802,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "บักดอง",
    subdistrictEn: "Bak Dong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ บักดอง ศรีสะเกษ 33150",
    name_th: "ขุนหาญ บักดอง ศรีสะเกษ 33150",
    name_en: "Khun Han, Bak Dong, Si Sa Ket, 33150",
  },
  {
    id: 330803,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "พราน",
    subdistrictEn: "Phran",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ พราน ศรีสะเกษ 33150",
    name_th: "ขุนหาญ พราน ศรีสะเกษ 33150",
    name_en: "Khun Han, Phran, Si Sa Ket, 33150",
  },
  {
    id: 330804,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "โพธิ์วงศ์",
    subdistrictEn: "Pho Wong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ โพธิ์วงศ์ ศรีสะเกษ 33150",
    name_th: "ขุนหาญ โพธิ์วงศ์ ศรีสะเกษ 33150",
    name_en: "Khun Han, Pho Wong, Si Sa Ket, 33150",
  },
  {
    id: 330805,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "ไพร",
    subdistrictEn: "Phrai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ ไพร ศรีสะเกษ 33150",
    name_th: "ขุนหาญ ไพร ศรีสะเกษ 33150",
    name_en: "Khun Han, Phrai, Si Sa Ket, 33150",
  },
  {
    id: 330806,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "กระหวัน",
    subdistrictEn: "Krawan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ กระหวัน ศรีสะเกษ 33150",
    name_th: "ขุนหาญ กระหวัน ศรีสะเกษ 33150",
    name_en: "Khun Han, Krawan, Si Sa Ket, 33150",
  },
  {
    id: 330807,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "ขุนหาญ",
    subdistrictEn: "Khun Han",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ ขุนหาญ ศรีสะเกษ 33150",
    name_th: "ขุนหาญ ขุนหาญ ศรีสะเกษ 33150",
    name_en: "Khun Han, Khun Han, Si Sa Ket, 33150",
  },
  {
    id: 330808,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "โนนสูง",
    subdistrictEn: "Non Sung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ โนนสูง ศรีสะเกษ 33150",
    name_th: "ขุนหาญ โนนสูง ศรีสะเกษ 33150",
    name_en: "Khun Han, Non Sung, Si Sa Ket, 33150",
  },
  {
    id: 330809,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "กันทรอม",
    subdistrictEn: "Kanthrom",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ กันทรอม ศรีสะเกษ 33150",
    name_th: "ขุนหาญ กันทรอม ศรีสะเกษ 33150",
    name_en: "Khun Han, Kanthrom, Si Sa Ket, 33150",
  },
  {
    id: 330810,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "ภูฝ้าย",
    subdistrictEn: "Phu Fai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ ภูฝ้าย ศรีสะเกษ 33150",
    name_th: "ขุนหาญ ภูฝ้าย ศรีสะเกษ 33150",
    name_en: "Khun Han, Phu Fai, Si Sa Ket, 33150",
  },
  {
    id: 330811,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "โพธิ์กระสังข์",
    subdistrictEn: "Pho Krasang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ โพธิ์กระสังข์ ศรีสะเกษ 33150",
    name_th: "ขุนหาญ โพธิ์กระสังข์ ศรีสะเกษ 33150",
    name_en: "Khun Han, Pho Krasang, Si Sa Ket, 33150",
  },
  {
    id: 330812,
    districtTh: "ขุนหาญ",
    districtEn: "Khun Han",
    subdistrictTh: "ห้วยจันทร์",
    subdistrictEn: "Huai Chan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33150,
    district_code: 3308,
    province_code: 22,
    name: "ขุนหาญ ห้วยจันทร์ ศรีสะเกษ 33150",
    name_th: "ขุนหาญ ห้วยจันทร์ ศรีสะเกษ 33150",
    name_en: "Khun Han, Huai Chan, Si Sa Ket, 33150",
  },
  {
    id: 330901,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "เมืองคง",
    subdistrictEn: "Mueang Khong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล เมืองคง ศรีสะเกษ 33160",
    name_th: "ราษีไศล เมืองคง ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Mueang Khong, Si Sa Ket, 33160",
  },
  {
    id: 330902,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "เมืองแคน",
    subdistrictEn: "Muang Khaen",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล เมืองแคน ศรีสะเกษ 33160",
    name_th: "ราษีไศล เมืองแคน ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Muang Khaen, Si Sa Ket, 33160",
  },
  {
    id: 330903,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "หนองแค",
    subdistrictEn: "Nong Khae",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล หนองแค ศรีสะเกษ 33160",
    name_th: "ราษีไศล หนองแค ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Nong Khae, Si Sa Ket, 33160",
  },
  {
    id: 330906,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "จิกสังข์ทอง",
    subdistrictEn: "Chik Sang Thong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล จิกสังข์ทอง ศรีสะเกษ 33160",
    name_th: "ราษีไศล จิกสังข์ทอง ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Chik Sang Thong, Si Sa Ket, 33160",
  },
  {
    id: 330907,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "ด่าน",
    subdistrictEn: "Dan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล ด่าน ศรีสะเกษ 33160",
    name_th: "ราษีไศล ด่าน ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Dan, Si Sa Ket, 33160",
  },
  {
    id: 330908,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "ดู่",
    subdistrictEn: "Du",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล ดู่ ศรีสะเกษ 33160",
    name_th: "ราษีไศล ดู่ ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Du, Si Sa Ket, 33160",
  },
  {
    id: 330909,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "หนองอึ่ง",
    subdistrictEn: "Nong Ueng",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล หนองอึ่ง ศรีสะเกษ 33160",
    name_th: "ราษีไศล หนองอึ่ง ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Nong Ueng, Si Sa Ket, 33160",
  },
  {
    id: 330910,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "บัวหุ่ง",
    subdistrictEn: "Bua Hung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล บัวหุ่ง ศรีสะเกษ 33160",
    name_th: "ราษีไศล บัวหุ่ง ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Bua Hung, Si Sa Ket, 33160",
  },
  {
    id: 330911,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "ไผ่",
    subdistrictEn: "Phai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล ไผ่ ศรีสะเกษ 33160",
    name_th: "ราษีไศล ไผ่ ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Phai, Si Sa Ket, 33160",
  },
  {
    id: 330912,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "ส้มป่อย",
    subdistrictEn: "Som Poi",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล ส้มป่อย ศรีสะเกษ 33160",
    name_th: "ราษีไศล ส้มป่อย ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Som Poi, Si Sa Ket, 33160",
  },
  {
    id: 330913,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "หนองหมี",
    subdistrictEn: "Nong Mi",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล หนองหมี ศรีสะเกษ 33160",
    name_th: "ราษีไศล หนองหมี ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Nong Mi, Si Sa Ket, 33160",
  },
  {
    id: 330914,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "หว้านคำ",
    subdistrictEn: "Wan Kham",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล หว้านคำ ศรีสะเกษ 33160",
    name_th: "ราษีไศล หว้านคำ ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Wan Kham, Si Sa Ket, 33160",
  },
  {
    id: 330915,
    districtTh: "ราษีไศล",
    districtEn: "Rasi Salai",
    subdistrictTh: "สร้างปี่",
    subdistrictEn: "Sang Pi",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3309,
    province_code: 22,
    name: "ราษีไศล สร้างปี่ ศรีสะเกษ 33160",
    name_th: "ราษีไศล สร้างปี่ ศรีสะเกษ 33160",
    name_en: "Rasi Salai, Sang Pi, Si Sa Ket, 33160",
  },
  {
    id: 331001,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "กำแพง",
    subdistrictEn: "Kamphaeng",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย กำแพง ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย กำแพง ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Kamphaeng, Si Sa Ket, 33120",
  },
  {
    id: 331002,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "อี่หล่ำ",
    subdistrictEn: "I Lam",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย อี่หล่ำ ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย อี่หล่ำ ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, I Lam, Si Sa Ket, 33120",
  },
  {
    id: 331003,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "ก้านเหลือง",
    subdistrictEn: "Kan Lueang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย ก้านเหลือง ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย ก้านเหลือง ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Kan Lueang, Si Sa Ket, 33120",
  },
  {
    id: 331004,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "ทุ่งไชย",
    subdistrictEn: "Thung Chai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย ทุ่งไชย ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย ทุ่งไชย ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Thung Chai, Si Sa Ket, 33120",
  },
  {
    id: 331005,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย สำโรง ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย สำโรง ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Samrong, Si Sa Ket, 33120",
  },
  {
    id: 331006,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "แขม",
    subdistrictEn: "Khaem",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย แขม ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย แขม ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Khaem, Si Sa Ket, 33120",
  },
  {
    id: 331007,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "หนองไฮ",
    subdistrictEn: "Nong Hai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย หนองไฮ ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย หนองไฮ ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Nong Hai, Si Sa Ket, 33120",
  },
  {
    id: 331008,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "ขะยูง",
    subdistrictEn: "Khayung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย ขะยูง ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย ขะยูง ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Khayung, Si Sa Ket, 33120",
  },
  {
    id: 331010,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "ตาเกษ",
    subdistrictEn: "Ta Ket",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย ตาเกษ ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย ตาเกษ ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Ta Ket, Si Sa Ket, 33120",
  },
  {
    id: 331011,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "หัวช้าง",
    subdistrictEn: "Hua Chang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย หัวช้าง ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย หัวช้าง ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Hua Chang, Si Sa Ket, 33120",
  },
  {
    id: 331012,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "รังแร้ง",
    subdistrictEn: "Rang Raeng",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย รังแร้ง ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย รังแร้ง ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Rang Raeng, Si Sa Ket, 33120",
  },
  {
    id: 331014,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "แต้",
    subdistrictEn: "Tae",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย แต้ ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย แต้ ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Tae, Si Sa Ket, 33120",
  },
  {
    id: 331015,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "แข้",
    subdistrictEn: "Khae",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย แข้ ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย แข้ ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Khae, Si Sa Ket, 33120",
  },
  {
    id: 331016,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "โพธิ์ชัย",
    subdistrictEn: "Pho Chai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย โพธิ์ชัย ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย โพธิ์ชัย ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Pho Chai, Si Sa Ket, 33120",
  },
  {
    id: 331017,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "ปะอาว",
    subdistrictEn: "Pa Ao",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย ปะอาว ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย ปะอาว ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Pa Ao, Si Sa Ket, 33120",
  },
  {
    id: 331018,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "หนองห้าง",
    subdistrictEn: "Nong Hang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย หนองห้าง ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย หนองห้าง ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Nong Hang, Si Sa Ket, 33120",
  },
  {
    id: 331022,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "สระกำแพงใหญ่",
    subdistrictEn: "Sa Kamphaeng Yai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย สระกำแพงใหญ่ ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย สระกำแพงใหญ่ ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Sa Kamphaeng Yai, Si Sa Ket, 33120",
  },
  {
    id: 331024,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "โคกหล่าม",
    subdistrictEn: "Khok Lam",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย โคกหล่าม ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย โคกหล่าม ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Khok Lam, Si Sa Ket, 33120",
  },
  {
    id: 331025,
    districtTh: "อุทุมพรพิสัย",
    districtEn: "Uthumphon Phisai",
    subdistrictTh: "โคกจาน",
    subdistrictEn: "Khok Chan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3310,
    province_code: 22,
    name: "อุทุมพรพิสัย โคกจาน ศรีสะเกษ 33120",
    name_th: "อุทุมพรพิสัย โคกจาน ศรีสะเกษ 33120",
    name_en: "Uthumphon Phisai, Khok Chan, Si Sa Ket, 33120",
  },
  {
    id: 331101,
    districtTh: "บึงบูรพ์",
    districtEn: "Bueng Bun",
    subdistrictTh: "เป๊าะ",
    subdistrictEn: "Po",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33220,
    district_code: 3311,
    province_code: 22,
    name: "บึงบูรพ์ เป๊าะ ศรีสะเกษ 33220",
    name_th: "บึงบูรพ์ เป๊าะ ศรีสะเกษ 33220",
    name_en: "Bueng Bun, Po, Si Sa Ket, 33220",
  },
  {
    id: 331102,
    districtTh: "บึงบูรพ์",
    districtEn: "Bueng Bun",
    subdistrictTh: "บึงบูรพ์",
    subdistrictEn: "Bueng Bun",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33220,
    district_code: 3311,
    province_code: 22,
    name: "บึงบูรพ์ บึงบูรพ์ ศรีสะเกษ 33220",
    name_th: "บึงบูรพ์ บึงบูรพ์ ศรีสะเกษ 33220",
    name_en: "Bueng Bun, Bueng Bun, Si Sa Ket, 33220",
  },
  {
    id: 331201,
    districtTh: "ห้วยทับทัน",
    districtEn: "Huai Thap Than",
    subdistrictTh: "ห้วยทับทัน",
    subdistrictEn: "Huai Thap Than",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33210,
    district_code: 3312,
    province_code: 22,
    name: "ห้วยทับทัน ห้วยทับทัน ศรีสะเกษ 33210",
    name_th: "ห้วยทับทัน ห้วยทับทัน ศรีสะเกษ 33210",
    name_en: "Huai Thap Than, Huai Thap Than, Si Sa Ket, 33210",
  },
  {
    id: 331202,
    districtTh: "ห้วยทับทัน",
    districtEn: "Huai Thap Than",
    subdistrictTh: "เมืองหลวง",
    subdistrictEn: "Mueang Luang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33210,
    district_code: 3312,
    province_code: 22,
    name: "ห้วยทับทัน เมืองหลวง ศรีสะเกษ 33210",
    name_th: "ห้วยทับทัน เมืองหลวง ศรีสะเกษ 33210",
    name_en: "Huai Thap Than, Mueang Luang, Si Sa Ket, 33210",
  },
  {
    id: 331203,
    districtTh: "ห้วยทับทัน",
    districtEn: "Huai Thap Than",
    subdistrictTh: "กล้วยกว้าง",
    subdistrictEn: "Kluai Kwang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33210,
    district_code: 3312,
    province_code: 22,
    name: "ห้วยทับทัน กล้วยกว้าง ศรีสะเกษ 33210",
    name_th: "ห้วยทับทัน กล้วยกว้าง ศรีสะเกษ 33210",
    name_en: "Huai Thap Than, Kluai Kwang, Si Sa Ket, 33210",
  },
  {
    id: 331204,
    districtTh: "ห้วยทับทัน",
    districtEn: "Huai Thap Than",
    subdistrictTh: "ผักไหม",
    subdistrictEn: "Phak Mai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33210,
    district_code: 3312,
    province_code: 22,
    name: "ห้วยทับทัน ผักไหม ศรีสะเกษ 33210",
    name_th: "ห้วยทับทัน ผักไหม ศรีสะเกษ 33210",
    name_en: "Huai Thap Than, Phak Mai, Si Sa Ket, 33210",
  },
  {
    id: 331205,
    districtTh: "ห้วยทับทัน",
    districtEn: "Huai Thap Than",
    subdistrictTh: "จานแสนไชย",
    subdistrictEn: "Chan Saen Chai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33210,
    district_code: 3312,
    province_code: 22,
    name: "ห้วยทับทัน จานแสนไชย ศรีสะเกษ 33210",
    name_th: "ห้วยทับทัน จานแสนไชย ศรีสะเกษ 33210",
    name_en: "Huai Thap Than, Chan Saen Chai, Si Sa Ket, 33210",
  },
  {
    id: 331206,
    districtTh: "ห้วยทับทัน",
    districtEn: "Huai Thap Than",
    subdistrictTh: "ปราสาท",
    subdistrictEn: "Prasat",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33210,
    district_code: 3312,
    province_code: 22,
    name: "ห้วยทับทัน ปราสาท ศรีสะเกษ 33210",
    name_th: "ห้วยทับทัน ปราสาท ศรีสะเกษ 33210",
    name_en: "Huai Thap Than, Prasat, Si Sa Ket, 33210",
  },
  {
    id: 331301,
    districtTh: "โนนคูณ",
    districtEn: "Non Khun",
    subdistrictTh: "โนนค้อ",
    subdistrictEn: "Non Kho",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33250,
    district_code: 3313,
    province_code: 22,
    name: "โนนคูณ โนนค้อ ศรีสะเกษ 33250",
    name_th: "โนนคูณ โนนค้อ ศรีสะเกษ 33250",
    name_en: "Non Khun, Non Kho, Si Sa Ket, 33250",
  },
  {
    id: 331302,
    districtTh: "โนนคูณ",
    districtEn: "Non Khun",
    subdistrictTh: "บก",
    subdistrictEn: "Bok",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33250,
    district_code: 3313,
    province_code: 22,
    name: "โนนคูณ บก ศรีสะเกษ 33250",
    name_th: "โนนคูณ บก ศรีสะเกษ 33250",
    name_en: "Non Khun, Bok, Si Sa Ket, 33250",
  },
  {
    id: 331303,
    districtTh: "โนนคูณ",
    districtEn: "Non Khun",
    subdistrictTh: "โพธิ์",
    subdistrictEn: "Pho",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33250,
    district_code: 3313,
    province_code: 22,
    name: "โนนคูณ โพธิ์ ศรีสะเกษ 33250",
    name_th: "โนนคูณ โพธิ์ ศรีสะเกษ 33250",
    name_en: "Non Khun, Pho, Si Sa Ket, 33250",
  },
  {
    id: 331304,
    districtTh: "โนนคูณ",
    districtEn: "Non Khun",
    subdistrictTh: "หนองกุง",
    subdistrictEn: "Nong Kung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33250,
    district_code: 3313,
    province_code: 22,
    name: "โนนคูณ หนองกุง ศรีสะเกษ 33250",
    name_th: "โนนคูณ หนองกุง ศรีสะเกษ 33250",
    name_en: "Non Khun, Nong Kung, Si Sa Ket, 33250",
  },
  {
    id: 331305,
    districtTh: "โนนคูณ",
    districtEn: "Non Khun",
    subdistrictTh: "เหล่ากวาง",
    subdistrictEn: "Lao Kwang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33250,
    district_code: 3313,
    province_code: 22,
    name: "โนนคูณ เหล่ากวาง ศรีสะเกษ 33250",
    name_th: "โนนคูณ เหล่ากวาง ศรีสะเกษ 33250",
    name_en: "Non Khun, Lao Kwang, Si Sa Ket, 33250",
  },
  {
    id: 331401,
    districtTh: "ศรีรัตนะ",
    districtEn: "Si Rattana",
    subdistrictTh: "ศรีแก้ว",
    subdistrictEn: "Si Kaeo",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33240,
    district_code: 3314,
    province_code: 22,
    name: "ศรีรัตนะ ศรีแก้ว ศรีสะเกษ 33240",
    name_th: "ศรีรัตนะ ศรีแก้ว ศรีสะเกษ 33240",
    name_en: "Si Rattana, Si Kaeo, Si Sa Ket, 33240",
  },
  {
    id: 331402,
    districtTh: "ศรีรัตนะ",
    districtEn: "Si Rattana",
    subdistrictTh: "พิงพวย",
    subdistrictEn: "Phing Phuai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33240,
    district_code: 3314,
    province_code: 22,
    name: "ศรีรัตนะ พิงพวย ศรีสะเกษ 33240",
    name_th: "ศรีรัตนะ พิงพวย ศรีสะเกษ 33240",
    name_en: "Si Rattana, Phing Phuai, Si Sa Ket, 33240",
  },
  {
    id: 331403,
    districtTh: "ศรีรัตนะ",
    districtEn: "Si Rattana",
    subdistrictTh: "สระเยาว์",
    subdistrictEn: "Sa Yao",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33240,
    district_code: 3314,
    province_code: 22,
    name: "ศรีรัตนะ สระเยาว์ ศรีสะเกษ 33240",
    name_th: "ศรีรัตนะ สระเยาว์ ศรีสะเกษ 33240",
    name_en: "Si Rattana, Sa Yao, Si Sa Ket, 33240",
  },
  {
    id: 331404,
    districtTh: "ศรีรัตนะ",
    districtEn: "Si Rattana",
    subdistrictTh: "ตูม",
    subdistrictEn: "Tum",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33240,
    district_code: 3314,
    province_code: 22,
    name: "ศรีรัตนะ ตูม ศรีสะเกษ 33240",
    name_th: "ศรีรัตนะ ตูม ศรีสะเกษ 33240",
    name_en: "Si Rattana, Tum, Si Sa Ket, 33240",
  },
  {
    id: 331405,
    districtTh: "ศรีรัตนะ",
    districtEn: "Si Rattana",
    subdistrictTh: "เสื่องข้าว",
    subdistrictEn: "Sueang Khao",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33240,
    district_code: 3314,
    province_code: 22,
    name: "ศรีรัตนะ เสื่องข้าว ศรีสะเกษ 33240",
    name_th: "ศรีรัตนะ เสื่องข้าว ศรีสะเกษ 33240",
    name_en: "Si Rattana, Sueang Khao, Si Sa Ket, 33240",
  },
  {
    id: 331406,
    districtTh: "ศรีรัตนะ",
    districtEn: "Si Rattana",
    subdistrictTh: "ศรีโนนงาม",
    subdistrictEn: "Si Non Ngam",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33240,
    district_code: 3314,
    province_code: 22,
    name: "ศรีรัตนะ ศรีโนนงาม ศรีสะเกษ 33240",
    name_th: "ศรีรัตนะ ศรีโนนงาม ศรีสะเกษ 33240",
    name_en: "Si Rattana, Si Non Ngam, Si Sa Ket, 33240",
  },
  {
    id: 331407,
    districtTh: "ศรีรัตนะ",
    districtEn: "Si Rattana",
    subdistrictTh: "สะพุง",
    subdistrictEn: "Saphung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33240,
    district_code: 3314,
    province_code: 22,
    name: "ศรีรัตนะ สะพุง ศรีสะเกษ 33240",
    name_th: "ศรีรัตนะ สะพุง ศรีสะเกษ 33240",
    name_en: "Si Rattana, Saphung, Si Sa Ket, 33240",
  },
  {
    id: 331501,
    districtTh: "น้ำเกลี้ยง",
    districtEn: "Si Rattana",
    subdistrictTh: "น้ำเกลี้ยง",
    subdistrictEn: "Nam Kliang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3315,
    province_code: 22,
    name: "น้ำเกลี้ยง น้ำเกลี้ยง ศรีสะเกษ 33130",
    name_th: "น้ำเกลี้ยง น้ำเกลี้ยง ศรีสะเกษ 33130",
    name_en: "Si Rattana, Nam Kliang, Si Sa Ket, 33130",
  },
  {
    id: 331502,
    districtTh: "น้ำเกลี้ยง",
    districtEn: "Si Rattana",
    subdistrictTh: "ละเอาะ",
    subdistrictEn: "La-o",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3315,
    province_code: 22,
    name: "น้ำเกลี้ยง ละเอาะ ศรีสะเกษ 33130",
    name_th: "น้ำเกลี้ยง ละเอาะ ศรีสะเกษ 33130",
    name_en: "Si Rattana, La-o, Si Sa Ket, 33130",
  },
  {
    id: 331503,
    districtTh: "น้ำเกลี้ยง",
    districtEn: "Si Rattana",
    subdistrictTh: "ตองปิด",
    subdistrictEn: "Tong Pit",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3315,
    province_code: 22,
    name: "น้ำเกลี้ยง ตองปิด ศรีสะเกษ 33130",
    name_th: "น้ำเกลี้ยง ตองปิด ศรีสะเกษ 33130",
    name_en: "Si Rattana, Tong Pit, Si Sa Ket, 33130",
  },
  {
    id: 331504,
    districtTh: "น้ำเกลี้ยง",
    districtEn: "Si Rattana",
    subdistrictTh: "เขิน",
    subdistrictEn: "Khoen",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3315,
    province_code: 22,
    name: "น้ำเกลี้ยง เขิน ศรีสะเกษ 33130",
    name_th: "น้ำเกลี้ยง เขิน ศรีสะเกษ 33130",
    name_en: "Si Rattana, Khoen, Si Sa Ket, 33130",
  },
  {
    id: 331505,
    districtTh: "น้ำเกลี้ยง",
    districtEn: "Si Rattana",
    subdistrictTh: "รุ่งระวี",
    subdistrictEn: "Rung Rawi",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3315,
    province_code: 22,
    name: "น้ำเกลี้ยง รุ่งระวี ศรีสะเกษ 33130",
    name_th: "น้ำเกลี้ยง รุ่งระวี ศรีสะเกษ 33130",
    name_en: "Si Rattana, Rung Rawi, Si Sa Ket, 33130",
  },
  {
    id: 331506,
    districtTh: "น้ำเกลี้ยง",
    districtEn: "Si Rattana",
    subdistrictTh: "คูบ",
    subdistrictEn: "Khup",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33130,
    district_code: 3315,
    province_code: 22,
    name: "น้ำเกลี้ยง คูบ ศรีสะเกษ 33130",
    name_th: "น้ำเกลี้ยง คูบ ศรีสะเกษ 33130",
    name_en: "Si Rattana, Khup, Si Sa Ket, 33130",
  },
  {
    id: 331601,
    districtTh: "วังหิน",
    districtEn: "Wang Hin",
    subdistrictTh: "บุสูง",
    subdistrictEn: "Bu Sung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33270,
    district_code: 3316,
    province_code: 22,
    name: "วังหิน บุสูง ศรีสะเกษ 33270",
    name_th: "วังหิน บุสูง ศรีสะเกษ 33270",
    name_en: "Wang Hin, Bu Sung, Si Sa Ket, 33270",
  },
  {
    id: 331602,
    districtTh: "วังหิน",
    districtEn: "Wang Hin",
    subdistrictTh: "ธาตุ",
    subdistrictEn: "That",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33270,
    district_code: 3316,
    province_code: 22,
    name: "วังหิน ธาตุ ศรีสะเกษ 33270",
    name_th: "วังหิน ธาตุ ศรีสะเกษ 33270",
    name_en: "Wang Hin, That, Si Sa Ket, 33270",
  },
  {
    id: 331603,
    districtTh: "วังหิน",
    districtEn: "Wang Hin",
    subdistrictTh: "ดวนใหญ่",
    subdistrictEn: "Duan Yai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33270,
    district_code: 3316,
    province_code: 22,
    name: "วังหิน ดวนใหญ่ ศรีสะเกษ 33270",
    name_th: "วังหิน ดวนใหญ่ ศรีสะเกษ 33270",
    name_en: "Wang Hin, Duan Yai, Si Sa Ket, 33270",
  },
  {
    id: 331604,
    districtTh: "วังหิน",
    districtEn: "Wang Hin",
    subdistrictTh: "บ่อแก้ว",
    subdistrictEn: "Bo Kaeo",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33270,
    district_code: 3316,
    province_code: 22,
    name: "วังหิน บ่อแก้ว ศรีสะเกษ 33270",
    name_th: "วังหิน บ่อแก้ว ศรีสะเกษ 33270",
    name_en: "Wang Hin, Bo Kaeo, Si Sa Ket, 33270",
  },
  {
    id: 331605,
    districtTh: "วังหิน",
    districtEn: "Wang Hin",
    subdistrictTh: "ศรีสำราญ",
    subdistrictEn: "Si Samran",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33270,
    district_code: 3316,
    province_code: 22,
    name: "วังหิน ศรีสำราญ ศรีสะเกษ 33270",
    name_th: "วังหิน ศรีสำราญ ศรีสะเกษ 33270",
    name_en: "Wang Hin, Si Samran, Si Sa Ket, 33270",
  },
  {
    id: 331606,
    districtTh: "วังหิน",
    districtEn: "Wang Hin",
    subdistrictTh: "ทุ่งสว่าง",
    subdistrictEn: "Thung Sawang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33270,
    district_code: 3316,
    province_code: 22,
    name: "วังหิน ทุ่งสว่าง ศรีสะเกษ 33270",
    name_th: "วังหิน ทุ่งสว่าง ศรีสะเกษ 33270",
    name_en: "Wang Hin, Thung Sawang, Si Sa Ket, 33270",
  },
  {
    id: 331607,
    districtTh: "วังหิน",
    districtEn: "Wang Hin",
    subdistrictTh: "วังหิน",
    subdistrictEn: "Wang Hin",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33270,
    district_code: 3316,
    province_code: 22,
    name: "วังหิน วังหิน ศรีสะเกษ 33270",
    name_th: "วังหิน วังหิน ศรีสะเกษ 33270",
    name_en: "Wang Hin, Wang Hin, Si Sa Ket, 33270",
  },
  {
    id: 331608,
    districtTh: "วังหิน",
    districtEn: "Wang Hin",
    subdistrictTh: "โพนยาง",
    subdistrictEn: "Phon Yang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33270,
    district_code: 3316,
    province_code: 22,
    name: "วังหิน โพนยาง ศรีสะเกษ 33270",
    name_th: "วังหิน โพนยาง ศรีสะเกษ 33270",
    name_en: "Wang Hin, Phon Yang, Si Sa Ket, 33270",
  },
  {
    id: 331701,
    districtTh: "ภูสิงห์",
    districtEn: "Phu Sing",
    subdistrictTh: "โคกตาล",
    subdistrictEn: "Khok Tan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3317,
    province_code: 22,
    name: "ภูสิงห์ โคกตาล ศรีสะเกษ 33140",
    name_th: "ภูสิงห์ โคกตาล ศรีสะเกษ 33140",
    name_en: "Phu Sing, Khok Tan, Si Sa Ket, 33140",
  },
  {
    id: 331702,
    districtTh: "ภูสิงห์",
    districtEn: "Phu Sing",
    subdistrictTh: "ห้วยตามอญ",
    subdistrictEn: "Huai Ta Mon",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3317,
    province_code: 22,
    name: "ภูสิงห์ ห้วยตามอญ ศรีสะเกษ 33140",
    name_th: "ภูสิงห์ ห้วยตามอญ ศรีสะเกษ 33140",
    name_en: "Phu Sing, Huai Ta Mon, Si Sa Ket, 33140",
  },
  {
    id: 331703,
    districtTh: "ภูสิงห์",
    districtEn: "Phu Sing",
    subdistrictTh: "ห้วยตึ๊กชู",
    subdistrictEn: "Huai Tuekchu",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3317,
    province_code: 22,
    name: "ภูสิงห์ ห้วยตึ๊กชู ศรีสะเกษ 33140",
    name_th: "ภูสิงห์ ห้วยตึ๊กชู ศรีสะเกษ 33140",
    name_en: "Phu Sing, Huai Tuekchu, Si Sa Ket, 33140",
  },
  {
    id: 331704,
    districtTh: "ภูสิงห์",
    districtEn: "Phu Sing",
    subdistrictTh: "ละลม",
    subdistrictEn: "Lalom",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3317,
    province_code: 22,
    name: "ภูสิงห์ ละลม ศรีสะเกษ 33140",
    name_th: "ภูสิงห์ ละลม ศรีสะเกษ 33140",
    name_en: "Phu Sing, Lalom, Si Sa Ket, 33140",
  },
  {
    id: 331705,
    districtTh: "ภูสิงห์",
    districtEn: "Phu Sing",
    subdistrictTh: "ตะเคียนราม",
    subdistrictEn: "Takhian Ram",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3317,
    province_code: 22,
    name: "ภูสิงห์ ตะเคียนราม ศรีสะเกษ 33140",
    name_th: "ภูสิงห์ ตะเคียนราม ศรีสะเกษ 33140",
    name_en: "Phu Sing, Takhian Ram, Si Sa Ket, 33140",
  },
  {
    id: 331706,
    districtTh: "ภูสิงห์",
    districtEn: "Phu Sing",
    subdistrictTh: "ดงรัก",
    subdistrictEn: "Dong Rak",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3317,
    province_code: 22,
    name: "ภูสิงห์ ดงรัก ศรีสะเกษ 33140",
    name_th: "ภูสิงห์ ดงรัก ศรีสะเกษ 33140",
    name_en: "Phu Sing, Dong Rak, Si Sa Ket, 33140",
  },
  {
    id: 331707,
    districtTh: "ภูสิงห์",
    districtEn: "Phu Sing",
    subdistrictTh: "ไพรพัฒนา",
    subdistrictEn: "Phrai Phatthana",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33140,
    district_code: 3317,
    province_code: 22,
    name: "ภูสิงห์ ไพรพัฒนา ศรีสะเกษ 33140",
    name_th: "ภูสิงห์ ไพรพัฒนา ศรีสะเกษ 33140",
    name_en: "Phu Sing, Phrai Phatthana, Si Sa Ket, 33140",
  },
  {
    id: 331801,
    districtTh: "เมืองจันทร์",
    districtEn: "Mueang Chan",
    subdistrictTh: "เมืองจันทร์",
    subdistrictEn: "Mueang Chan",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3318,
    province_code: 22,
    name: "เมืองจันทร์ เมืองจันทร์ ศรีสะเกษ 33120",
    name_th: "เมืองจันทร์ เมืองจันทร์ ศรีสะเกษ 33120",
    name_en: "Mueang Chan, Mueang Chan, Si Sa Ket, 33120",
  },
  {
    id: 331802,
    districtTh: "เมืองจันทร์",
    districtEn: "Mueang Chan",
    subdistrictTh: "ตาโกน",
    subdistrictEn: "Takon",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3318,
    province_code: 22,
    name: "เมืองจันทร์ ตาโกน ศรีสะเกษ 33120",
    name_th: "เมืองจันทร์ ตาโกน ศรีสะเกษ 33120",
    name_en: "Mueang Chan, Takon, Si Sa Ket, 33120",
  },
  {
    id: 331803,
    districtTh: "เมืองจันทร์",
    districtEn: "Mueang Chan",
    subdistrictTh: "หนองใหญ่",
    subdistrictEn: "Nong Yai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3318,
    province_code: 22,
    name: "เมืองจันทร์ หนองใหญ่ ศรีสะเกษ 33120",
    name_th: "เมืองจันทร์ หนองใหญ่ ศรีสะเกษ 33120",
    name_en: "Mueang Chan, Nong Yai, Si Sa Ket, 33120",
  },
  {
    id: 331901,
    districtTh: "เบญจลักษ์",
    districtEn: "Benchalak",
    subdistrictTh: "เสียว",
    subdistrictEn: "Siao",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3319,
    province_code: 22,
    name: "เบญจลักษ์ เสียว ศรีสะเกษ 33110",
    name_th: "เบญจลักษ์ เสียว ศรีสะเกษ 33110",
    name_en: "Benchalak, Siao, Si Sa Ket, 33110",
  },
  {
    id: 331902,
    districtTh: "เบญจลักษ์",
    districtEn: "Benchalak",
    subdistrictTh: "หนองหว้า",
    subdistrictEn: "Nong Wa",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3319,
    province_code: 22,
    name: "เบญจลักษ์ หนองหว้า ศรีสะเกษ 33110",
    name_th: "เบญจลักษ์ หนองหว้า ศรีสะเกษ 33110",
    name_en: "Benchalak, Nong Wa, Si Sa Ket, 33110",
  },
  {
    id: 331903,
    districtTh: "เบญจลักษ์",
    districtEn: "Benchalak",
    subdistrictTh: "หนองงูเหลือม",
    subdistrictEn: "Nong Ngu Lueam",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3319,
    province_code: 22,
    name: "เบญจลักษ์ หนองงูเหลือม ศรีสะเกษ 33110",
    name_th: "เบญจลักษ์ หนองงูเหลือม ศรีสะเกษ 33110",
    name_en: "Benchalak, Nong Ngu Lueam, Si Sa Ket, 33110",
  },
  {
    id: 331904,
    districtTh: "เบญจลักษ์",
    districtEn: "Benchalak",
    subdistrictTh: "หนองฮาง",
    subdistrictEn: "Nong Hang",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3319,
    province_code: 22,
    name: "เบญจลักษ์ หนองฮาง ศรีสะเกษ 33110",
    name_th: "เบญจลักษ์ หนองฮาง ศรีสะเกษ 33110",
    name_en: "Benchalak, Nong Hang, Si Sa Ket, 33110",
  },
  {
    id: 331905,
    districtTh: "เบญจลักษ์",
    districtEn: "Benchalak",
    subdistrictTh: "ท่าคล้อ",
    subdistrictEn: "Tha Khlo",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33110,
    district_code: 3319,
    province_code: 22,
    name: "เบญจลักษ์ ท่าคล้อ ศรีสะเกษ 33110",
    name_th: "เบญจลักษ์ ท่าคล้อ ศรีสะเกษ 33110",
    name_en: "Benchalak, Tha Khlo, Si Sa Ket, 33110",
  },
  {
    id: 332001,
    districtTh: "พยุห์",
    districtEn: "Phayu",
    subdistrictTh: "พยุห์",
    subdistrictEn: "Phayu",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33230,
    district_code: 3320,
    province_code: 22,
    name: "พยุห์ พยุห์ ศรีสะเกษ 33230",
    name_th: "พยุห์ พยุห์ ศรีสะเกษ 33230",
    name_en: "Phayu, Phayu, Si Sa Ket, 33230",
  },
  {
    id: 332002,
    districtTh: "พยุห์",
    districtEn: "Phayu",
    subdistrictTh: "พรหมสวัสดิ์",
    subdistrictEn: "Phrom Sawat",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33230,
    district_code: 3320,
    province_code: 22,
    name: "พยุห์ พรหมสวัสดิ์ ศรีสะเกษ 33230",
    name_th: "พยุห์ พรหมสวัสดิ์ ศรีสะเกษ 33230",
    name_en: "Phayu, Phrom Sawat, Si Sa Ket, 33230",
  },
  {
    id: 332003,
    districtTh: "พยุห์",
    districtEn: "Phayu",
    subdistrictTh: "ตำแย",
    subdistrictEn: "Tamyae",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33230,
    district_code: 3320,
    province_code: 22,
    name: "พยุห์ ตำแย ศรีสะเกษ 33230",
    name_th: "พยุห์ ตำแย ศรีสะเกษ 33230",
    name_en: "Phayu, Tamyae, Si Sa Ket, 33230",
  },
  {
    id: 332004,
    districtTh: "พยุห์",
    districtEn: "Phayu",
    subdistrictTh: "โนนเพ็ก",
    subdistrictEn: "Non Phek",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33230,
    district_code: 3320,
    province_code: 22,
    name: "พยุห์ โนนเพ็ก ศรีสะเกษ 33230",
    name_th: "พยุห์ โนนเพ็ก ศรีสะเกษ 33230",
    name_en: "Phayu, Non Phek, Si Sa Ket, 33230",
  },
  {
    id: 332005,
    districtTh: "พยุห์",
    districtEn: "Phayu",
    subdistrictTh: "หนองค้า",
    subdistrictEn: "Nong Kha",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33230,
    district_code: 3320,
    province_code: 22,
    name: "พยุห์ หนองค้า ศรีสะเกษ 33230",
    name_th: "พยุห์ หนองค้า ศรีสะเกษ 33230",
    name_en: "Phayu, Nong Kha, Si Sa Ket, 33230",
  },
  {
    id: 332101,
    districtTh: "โพธิ์ศรีสุวรรณ",
    districtEn: "Pho Si Suwan",
    subdistrictTh: "โดด",
    subdistrictEn: "Dot",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3321,
    province_code: 22,
    name: "โพธิ์ศรีสุวรรณ โดด ศรีสะเกษ 33120",
    name_th: "โพธิ์ศรีสุวรรณ โดด ศรีสะเกษ 33120",
    name_en: "Pho Si Suwan, Dot, Si Sa Ket, 33120",
  },
  {
    id: 332102,
    districtTh: "โพธิ์ศรีสุวรรณ",
    districtEn: "Pho Si Suwan",
    subdistrictTh: "เสียว",
    subdistrictEn: "Siao",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3321,
    province_code: 22,
    name: "โพธิ์ศรีสุวรรณ เสียว ศรีสะเกษ 33120",
    name_th: "โพธิ์ศรีสุวรรณ เสียว ศรีสะเกษ 33120",
    name_en: "Pho Si Suwan, Siao, Si Sa Ket, 33120",
  },
  {
    id: 332103,
    districtTh: "โพธิ์ศรีสุวรรณ",
    districtEn: "Pho Si Suwan",
    subdistrictTh: "หนองม้า",
    subdistrictEn: "Nong Ma",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3321,
    province_code: 22,
    name: "โพธิ์ศรีสุวรรณ หนองม้า ศรีสะเกษ 33120",
    name_th: "โพธิ์ศรีสุวรรณ หนองม้า ศรีสะเกษ 33120",
    name_en: "Pho Si Suwan, Nong Ma, Si Sa Ket, 33120",
  },
  {
    id: 332104,
    districtTh: "โพธิ์ศรีสุวรรณ",
    districtEn: "Pho Si Suwan",
    subdistrictTh: "ผือใหญ่",
    subdistrictEn: "Phue Yai",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3321,
    province_code: 22,
    name: "โพธิ์ศรีสุวรรณ ผือใหญ่ ศรีสะเกษ 33120",
    name_th: "โพธิ์ศรีสุวรรณ ผือใหญ่ ศรีสะเกษ 33120",
    name_en: "Pho Si Suwan, Phue Yai, Si Sa Ket, 33120",
  },
  {
    id: 332105,
    districtTh: "โพธิ์ศรีสุวรรณ",
    districtEn: "Pho Si Suwan",
    subdistrictTh: "อีเซ",
    subdistrictEn: "I Se",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33120,
    district_code: 3321,
    province_code: 22,
    name: "โพธิ์ศรีสุวรรณ อีเซ ศรีสะเกษ 33120",
    name_th: "โพธิ์ศรีสุวรรณ อีเซ ศรีสะเกษ 33120",
    name_en: "Pho Si Suwan, I Se, Si Sa Ket, 33120",
  },
  {
    id: 332201,
    districtTh: "ศิลาลาด",
    districtEn: "Sila Lat",
    subdistrictTh: "กุง",
    subdistrictEn: "Kung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3322,
    province_code: 22,
    name: "ศิลาลาด กุง ศรีสะเกษ 33160",
    name_th: "ศิลาลาด กุง ศรีสะเกษ 33160",
    name_en: "Sila Lat, Kung, Si Sa Ket, 33160",
  },
  {
    id: 332202,
    districtTh: "ศิลาลาด",
    districtEn: "Sila Lat",
    subdistrictTh: "คลีกลิ้ง",
    subdistrictEn: "Kleek Ling",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3322,
    province_code: 22,
    name: "ศิลาลาด คลีกลิ้ง ศรีสะเกษ 33160",
    name_th: "ศิลาลาด คลีกลิ้ง ศรีสะเกษ 33160",
    name_en: "Sila Lat, Kleek Ling, Si Sa Ket, 33160",
  },
  {
    id: 332203,
    districtTh: "ศิลาลาด",
    districtEn: "Sila Lat",
    subdistrictTh: "หนองบัวดง",
    subdistrictEn: "Nong Bua Dong",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3322,
    province_code: 22,
    name: "ศิลาลาด หนองบัวดง ศรีสะเกษ 33160",
    name_th: "ศิลาลาด หนองบัวดง ศรีสะเกษ 33160",
    name_en: "Sila Lat, Nong Bua Dong, Si Sa Ket, 33160",
  },
  {
    id: 332204,
    districtTh: "ศิลาลาด",
    districtEn: "Sila Lat",
    subdistrictTh: "โจดม่วง",
    subdistrictEn: "Jod Maung",
    provinceTh: "ศรีสะเกษ",
    provinceEn: "Si Sa Ket",
    zipcode: 33160,
    district_code: 3322,
    province_code: 22,
    name: "ศิลาลาด โจดม่วง ศรีสะเกษ 33160",
    name_th: "ศิลาลาด โจดม่วง ศรีสะเกษ 33160",
    name_en: "Sila Lat, Jod Maung, Si Sa Ket, 33160",
  },
  {
    id: 340101,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี ในเมือง อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี ในเมือง อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Nai Mueang, Ubon Ratchathani, 34000",
  },
  {
    id: 340104,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "หัวเรือ",
    subdistrictEn: "Hua Ruea",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี หัวเรือ อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี หัวเรือ อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Hua Ruea, Ubon Ratchathani, 34000",
  },
  {
    id: 340105,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "หนองขอน",
    subdistrictEn: "Nong Khon",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี หนองขอน อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี หนองขอน อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Nong Khon, Ubon Ratchathani, 34000",
  },
  {
    id: 340107,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "ปทุม",
    subdistrictEn: "Pathum",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี ปทุม อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี ปทุม อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Pathum, Ubon Ratchathani, 34000",
  },
  {
    id: 340108,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "ขามใหญ่",
    subdistrictEn: "Kham Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี ขามใหญ่ อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี ขามใหญ่ อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Kham Yai, Ubon Ratchathani, 34000",
  },
  {
    id: 340109,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "แจระแม",
    subdistrictEn: "Chaeramae",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี แจระแม อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี แจระแม อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Chaeramae, Ubon Ratchathani, 34000",
  },
  {
    id: 340111,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "หนองบ่อ",
    subdistrictEn: "Nong Bo",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี หนองบ่อ อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี หนองบ่อ อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Nong Bo, Ubon Ratchathani, 34000",
  },
  {
    id: 340112,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "ไร่น้อย",
    subdistrictEn: "Rai Noi",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี ไร่น้อย อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี ไร่น้อย อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Rai Noi, Ubon Ratchathani, 34000",
  },
  {
    id: 340113,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "กระโสบ",
    subdistrictEn: "Krasop",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี กระโสบ อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี กระโสบ อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Krasop, Ubon Ratchathani, 34000",
  },
  {
    id: 340116,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "กุดลาด",
    subdistrictEn: "Kut Lat",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี กุดลาด อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี กุดลาด อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Kut Lat, Ubon Ratchathani, 34000",
  },
  {
    id: 340119,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "ขี้เหล็ก",
    subdistrictEn: "Khilek",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี ขี้เหล็ก อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี ขี้เหล็ก อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Khilek, Ubon Ratchathani, 34000",
  },
  {
    id: 340120,
    districtTh: "เมืองอุบลราชธานี",
    districtEn: "Mueang Ubon Ratchathani",
    subdistrictTh: "ปะอาว",
    subdistrictEn: "Pa-ao",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3401,
    province_code: 23,
    name: "เมืองอุบลราชธานี ปะอาว อุบลราชธานี 34000",
    name_th: "เมืองอุบลราชธานี ปะอาว อุบลราชธานี 34000",
    name_en: "Mueang Ubon Ratchathani, Pa-ao, Ubon Ratchathani, 34000",
  },
  {
    id: 340201,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "นาคำ",
    subdistrictEn: "Na Kham",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ นาคำ อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ นาคำ อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Na Kham, Ubon Ratchathani, 34250",
  },
  {
    id: 340202,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "แก้งกอก",
    subdistrictEn: "Kaeng Kok",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ แก้งกอก อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ แก้งกอก อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Kaeng Kok, Ubon Ratchathani, 34250",
  },
  {
    id: 340203,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "เอือดใหญ่",
    subdistrictEn: "Ueat Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ เอือดใหญ่ อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ เอือดใหญ่ อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Ueat Yai, Ubon Ratchathani, 34250",
  },
  {
    id: 340204,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "วาริน",
    subdistrictEn: "Warin",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ วาริน อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ วาริน อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Warin, Ubon Ratchathani, 34250",
  },
  {
    id: 340205,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "ลาดควาย",
    subdistrictEn: "Lat Khwai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ ลาดควาย อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ ลาดควาย อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Lat Khwai, Ubon Ratchathani, 34250",
  },
  {
    id: 340206,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "สงยาง",
    subdistrictEn: "Song Yang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ สงยาง อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ สงยาง อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Song Yang, Ubon Ratchathani, 34250",
  },
  {
    id: 340207,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "ตะบ่าย",
    subdistrictEn: "Ta Bai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ ตะบ่าย อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ ตะบ่าย อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Ta Bai, Ubon Ratchathani, 34250",
  },
  {
    id: 340208,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "คำไหล",
    subdistrictEn: "Kham Lai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ คำไหล อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ คำไหล อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Kham Lai, Ubon Ratchathani, 34250",
  },
  {
    id: 340209,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "หนามแท่ง",
    subdistrictEn: "Nam Thaeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ หนามแท่ง อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ หนามแท่ง อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Nam Thaeng, Ubon Ratchathani, 34250",
  },
  {
    id: 340210,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "นาเลิน",
    subdistrictEn: "Na Loen",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ นาเลิน อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ นาเลิน อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Na Loen, Ubon Ratchathani, 34250",
  },
  {
    id: 340211,
    districtTh: "ศรีเมืองใหม่",
    districtEn: "Si Mueang Mai",
    subdistrictTh: "ดอนใหญ่",
    subdistrictEn: "Don Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34250,
    district_code: 3402,
    province_code: 23,
    name: "ศรีเมืองใหม่ ดอนใหญ่ อุบลราชธานี 34250",
    name_th: "ศรีเมืองใหม่ ดอนใหญ่ อุบลราชธานี 34250",
    name_en: "Si Mueang Mai, Don Yai, Ubon Ratchathani, 34250",
  },
  {
    id: 340301,
    districtTh: "โขงเจียม",
    districtEn: "Khong Chiam",
    subdistrictTh: "โขงเจียม",
    subdistrictEn: "Khong Chiam",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34220,
    district_code: 3403,
    province_code: 23,
    name: "โขงเจียม โขงเจียม อุบลราชธานี 34220",
    name_th: "โขงเจียม โขงเจียม อุบลราชธานี 34220",
    name_en: "Khong Chiam, Khong Chiam, Ubon Ratchathani, 34220",
  },
  {
    id: 340302,
    districtTh: "โขงเจียม",
    districtEn: "Khong Chiam",
    subdistrictTh: "ห้วยยาง",
    subdistrictEn: "Huai Yang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34220,
    district_code: 3403,
    province_code: 23,
    name: "โขงเจียม ห้วยยาง อุบลราชธานี 34220",
    name_th: "โขงเจียม ห้วยยาง อุบลราชธานี 34220",
    name_en: "Khong Chiam, Huai Yang, Ubon Ratchathani, 34220",
  },
  {
    id: 340303,
    districtTh: "โขงเจียม",
    districtEn: "Khong Chiam",
    subdistrictTh: "นาโพธิ์กลาง",
    subdistrictEn: "Na Pho Klang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34220,
    district_code: 3403,
    province_code: 23,
    name: "โขงเจียม นาโพธิ์กลาง อุบลราชธานี 34220",
    name_th: "โขงเจียม นาโพธิ์กลาง อุบลราชธานี 34220",
    name_en: "Khong Chiam, Na Pho Klang, Ubon Ratchathani, 34220",
  },
  {
    id: 340304,
    districtTh: "โขงเจียม",
    districtEn: "Khong Chiam",
    subdistrictTh: "หนองแสงใหญ่",
    subdistrictEn: "Nong Saeng Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34220,
    district_code: 3403,
    province_code: 23,
    name: "โขงเจียม หนองแสงใหญ่ อุบลราชธานี 34220",
    name_th: "โขงเจียม หนองแสงใหญ่ อุบลราชธานี 34220",
    name_en: "Khong Chiam, Nong Saeng Yai, Ubon Ratchathani, 34220",
  },
  {
    id: 340305,
    districtTh: "โขงเจียม",
    districtEn: "Khong Chiam",
    subdistrictTh: "ห้วยไผ่",
    subdistrictEn: "Huai Phai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34220,
    district_code: 3403,
    province_code: 23,
    name: "โขงเจียม ห้วยไผ่ อุบลราชธานี 34220",
    name_th: "โขงเจียม ห้วยไผ่ อุบลราชธานี 34220",
    name_en: "Khong Chiam, Huai Phai, Ubon Ratchathani, 34220",
  },
  {
    id: 340306,
    districtTh: "โขงเจียม",
    districtEn: "Khong Chiam",
    subdistrictTh: "คำเขื่อนแก้ว",
    subdistrictEn: "Kham Khuen Kaew",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34220,
    district_code: 3403,
    province_code: 23,
    name: "โขงเจียม คำเขื่อนแก้ว อุบลราชธานี 34220",
    name_th: "โขงเจียม คำเขื่อนแก้ว อุบลราชธานี 34220",
    name_en: "Khong Chiam, Kham Khuen Kaew, Ubon Ratchathani, 34220",
  },
  {
    id: 340401,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "เขื่องใน",
    subdistrictEn: "Khueang Nai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน เขื่องใน อุบลราชธานี 34150",
    name_th: "เขื่องใน เขื่องใน อุบลราชธานี 34150",
    name_en: "Khueang Nai, Khueang Nai, Ubon Ratchathani, 34150",
  },
  {
    id: 340402,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "สร้างถ่อ",
    subdistrictEn: "Sang Tho",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน สร้างถ่อ อุบลราชธานี 34150",
    name_th: "เขื่องใน สร้างถ่อ อุบลราชธานี 34150",
    name_en: "Khueang Nai, Sang Tho, Ubon Ratchathani, 34150",
  },
  {
    id: 340403,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "ค้อทอง",
    subdistrictEn: "Kho Thong",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน ค้อทอง อุบลราชธานี 34150",
    name_th: "เขื่องใน ค้อทอง อุบลราชธานี 34150",
    name_en: "Khueang Nai, Kho Thong, Ubon Ratchathani, 34150",
  },
  {
    id: 340404,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "ก่อเอ้",
    subdistrictEn: "Ko E",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน ก่อเอ้ อุบลราชธานี 34150",
    name_th: "เขื่องใน ก่อเอ้ อุบลราชธานี 34150",
    name_en: "Khueang Nai, Ko E, Ubon Ratchathani, 34150",
  },
  {
    id: 340405,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "หัวดอน",
    subdistrictEn: "Hua Don",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน หัวดอน อุบลราชธานี 34150",
    name_th: "เขื่องใน หัวดอน อุบลราชธานี 34150",
    name_en: "Khueang Nai, Hua Don, Ubon Ratchathani, 34150",
  },
  {
    id: 340406,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "ชีทวน",
    subdistrictEn: "Chi Thuan",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน ชีทวน อุบลราชธานี 34150",
    name_th: "เขื่องใน ชีทวน อุบลราชธานี 34150",
    name_en: "Khueang Nai, Chi Thuan, Ubon Ratchathani, 34150",
  },
  {
    id: 340407,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "ท่าไห",
    subdistrictEn: "Tha Hai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน ท่าไห อุบลราชธานี 34150",
    name_th: "เขื่องใน ท่าไห อุบลราชธานี 34150",
    name_en: "Khueang Nai, Tha Hai, Ubon Ratchathani, 34150",
  },
  {
    id: 340408,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "นาคำใหญ่",
    subdistrictEn: "Na Kham Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน นาคำใหญ่ อุบลราชธานี 34150",
    name_th: "เขื่องใน นาคำใหญ่ อุบลราชธานี 34150",
    name_en: "Khueang Nai, Na Kham Yai, Ubon Ratchathani, 34150",
  },
  {
    id: 340409,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "แดงหม้อ",
    subdistrictEn: "Daeng Mo",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน แดงหม้อ อุบลราชธานี 34150",
    name_th: "เขื่องใน แดงหม้อ อุบลราชธานี 34150",
    name_en: "Khueang Nai, Daeng Mo, Ubon Ratchathani, 34150",
  },
  {
    id: 340410,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "ธาตุน้อย",
    subdistrictEn: "That Noi",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน ธาตุน้อย อุบลราชธานี 34150",
    name_th: "เขื่องใน ธาตุน้อย อุบลราชธานี 34150",
    name_en: "Khueang Nai, That Noi, Ubon Ratchathani, 34150",
  },
  {
    id: 340411,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "บ้านไทย",
    subdistrictEn: "Ban Thai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34320,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน บ้านไทย อุบลราชธานี 34320",
    name_th: "เขื่องใน บ้านไทย อุบลราชธานี 34320",
    name_en: "Khueang Nai, Ban Thai, Ubon Ratchathani, 34320",
  },
  {
    id: 340412,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "บ้านกอก",
    subdistrictEn: "Ban Kok",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34320,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน บ้านกอก อุบลราชธานี 34320",
    name_th: "เขื่องใน บ้านกอก อุบลราชธานี 34320",
    name_en: "Khueang Nai, Ban Kok, Ubon Ratchathani, 34320",
  },
  {
    id: 340413,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "กลางใหญ่",
    subdistrictEn: "Klang Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34320,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน กลางใหญ่ อุบลราชธานี 34320",
    name_th: "เขื่องใน กลางใหญ่ อุบลราชธานี 34320",
    name_en: "Khueang Nai, Klang Yai, Ubon Ratchathani, 34320",
  },
  {
    id: 340414,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "โนนรัง",
    subdistrictEn: "Non Rang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34320,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน โนนรัง อุบลราชธานี 34320",
    name_th: "เขื่องใน โนนรัง อุบลราชธานี 34320",
    name_en: "Khueang Nai, Non Rang, Ubon Ratchathani, 34320",
  },
  {
    id: 340415,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "ยางขี้นก",
    subdistrictEn: "Yang Khi Nok",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน ยางขี้นก อุบลราชธานี 34150",
    name_th: "เขื่องใน ยางขี้นก อุบลราชธานี 34150",
    name_en: "Khueang Nai, Yang Khi Nok, Ubon Ratchathani, 34150",
  },
  {
    id: 340416,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "ศรีสุข",
    subdistrictEn: "Si Suk",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน ศรีสุข อุบลราชธานี 34150",
    name_th: "เขื่องใน ศรีสุข อุบลราชธานี 34150",
    name_en: "Khueang Nai, Si Suk, Ubon Ratchathani, 34150",
  },
  {
    id: 340417,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "สหธาตุ",
    subdistrictEn: "Sahathat",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน สหธาตุ อุบลราชธานี 34150",
    name_th: "เขื่องใน สหธาตุ อุบลราชธานี 34150",
    name_en: "Khueang Nai, Sahathat, Ubon Ratchathani, 34150",
  },
  {
    id: 340418,
    districtTh: "เขื่องใน",
    districtEn: "Khueang Nai",
    subdistrictTh: "หนองเหล่า",
    subdistrictEn: "Nong Lao",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34150,
    district_code: 3404,
    province_code: 23,
    name: "เขื่องใน หนองเหล่า อุบลราชธานี 34150",
    name_th: "เขื่องใน หนองเหล่า อุบลราชธานี 34150",
    name_en: "Khueang Nai, Nong Lao, Ubon Ratchathani, 34150",
  },
  {
    id: 340501,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "เขมราฐ",
    subdistrictEn: "Khemarat",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ เขมราฐ อุบลราชธานี 34170",
    name_th: "เขมราฐ เขมราฐ อุบลราชธานี 34170",
    name_en: "Khemarat, Khemarat, Ubon Ratchathani, 34170",
  },
  {
    id: 340503,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "ขามป้อม",
    subdistrictEn: "Kham Pom",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ ขามป้อม อุบลราชธานี 34170",
    name_th: "เขมราฐ ขามป้อม อุบลราชธานี 34170",
    name_en: "Khemarat, Kham Pom, Ubon Ratchathani, 34170",
  },
  {
    id: 340504,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "เจียด",
    subdistrictEn: "Chiat",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ เจียด อุบลราชธานี 34170",
    name_th: "เขมราฐ เจียด อุบลราชธานี 34170",
    name_en: "Khemarat, Chiat, Ubon Ratchathani, 34170",
  },
  {
    id: 340507,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "หนองผือ",
    subdistrictEn: "Nong Phue",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ หนองผือ อุบลราชธานี 34170",
    name_th: "เขมราฐ หนองผือ อุบลราชธานี 34170",
    name_en: "Khemarat, Nong Phue, Ubon Ratchathani, 34170",
  },
  {
    id: 340508,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "นาแวง",
    subdistrictEn: "Na Waeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ นาแวง อุบลราชธานี 34170",
    name_th: "เขมราฐ นาแวง อุบลราชธานี 34170",
    name_en: "Khemarat, Na Waeng, Ubon Ratchathani, 34170",
  },
  {
    id: 340510,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "แก้งเหนือ",
    subdistrictEn: "Kaeng Nuea",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ แก้งเหนือ อุบลราชธานี 34170",
    name_th: "เขมราฐ แก้งเหนือ อุบลราชธานี 34170",
    name_en: "Khemarat, Kaeng Nuea, Ubon Ratchathani, 34170",
  },
  {
    id: 340511,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "หนองนกทา",
    subdistrictEn: "Nong Nok Tha",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ หนองนกทา อุบลราชธานี 34170",
    name_th: "เขมราฐ หนองนกทา อุบลราชธานี 34170",
    name_en: "Khemarat, Nong Nok Tha, Ubon Ratchathani, 34170",
  },
  {
    id: 340512,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "หนองสิม",
    subdistrictEn: "Nong Sim",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ หนองสิม อุบลราชธานี 34170",
    name_th: "เขมราฐ หนองสิม อุบลราชธานี 34170",
    name_en: "Khemarat, Nong Sim, Ubon Ratchathani, 34170",
  },
  {
    id: 340513,
    districtTh: "เขมราฐ",
    districtEn: "Khemarat",
    subdistrictTh: "หัวนา",
    subdistrictEn: "Hua Na",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3405,
    province_code: 23,
    name: "เขมราฐ หัวนา อุบลราชธานี 34170",
    name_th: "เขมราฐ หัวนา อุบลราชธานี 34170",
    name_en: "Khemarat, Hua Na, Ubon Ratchathani, 34170",
  },
  {
    id: 340701,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "เมืองเดช",
    subdistrictEn: "Mueang Det",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม เมืองเดช อุบลราชธานี 34160",
    name_th: "เดชอุดม เมืองเดช อุบลราชธานี 34160",
    name_en: "Det Udom, Mueang Det, Ubon Ratchathani, 34160",
  },
  {
    id: 340702,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "นาส่วง",
    subdistrictEn: "Na Suang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม นาส่วง อุบลราชธานี 34160",
    name_th: "เดชอุดม นาส่วง อุบลราชธานี 34160",
    name_en: "Det Udom, Na Suang, Ubon Ratchathani, 34160",
  },
  {
    id: 340704,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "นาเจริญ",
    subdistrictEn: "Na Charoen",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม นาเจริญ อุบลราชธานี 34160",
    name_th: "เดชอุดม นาเจริญ อุบลราชธานี 34160",
    name_en: "Det Udom, Na Charoen, Ubon Ratchathani, 34160",
  },
  {
    id: 340706,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "ทุ่งเทิง",
    subdistrictEn: "Thung Thoeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม ทุ่งเทิง อุบลราชธานี 34160",
    name_th: "เดชอุดม ทุ่งเทิง อุบลราชธานี 34160",
    name_en: "Det Udom, Thung Thoeng, Ubon Ratchathani, 34160",
  },
  {
    id: 340708,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "สมสะอาด",
    subdistrictEn: "Som Sa-at",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม สมสะอาด อุบลราชธานี 34160",
    name_th: "เดชอุดม สมสะอาด อุบลราชธานี 34160",
    name_en: "Det Udom, Som Sa-at, Ubon Ratchathani, 34160",
  },
  {
    id: 340709,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "กุดประทาย",
    subdistrictEn: "Kut Prathai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม กุดประทาย อุบลราชธานี 34160",
    name_th: "เดชอุดม กุดประทาย อุบลราชธานี 34160",
    name_en: "Det Udom, Kut Prathai, Ubon Ratchathani, 34160",
  },
  {
    id: 340710,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "ตบหู",
    subdistrictEn: "Top Hu",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม ตบหู อุบลราชธานี 34160",
    name_th: "เดชอุดม ตบหู อุบลราชธานี 34160",
    name_en: "Det Udom, Top Hu, Ubon Ratchathani, 34160",
  },
  {
    id: 340711,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "กลาง",
    subdistrictEn: "Klang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม กลาง อุบลราชธานี 34160",
    name_th: "เดชอุดม กลาง อุบลราชธานี 34160",
    name_en: "Det Udom, Klang, Ubon Ratchathani, 34160",
  },
  {
    id: 340712,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "แก้ง",
    subdistrictEn: "Kaeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม แก้ง อุบลราชธานี 34160",
    name_th: "เดชอุดม แก้ง อุบลราชธานี 34160",
    name_en: "Det Udom, Kaeng, Ubon Ratchathani, 34160",
  },
  {
    id: 340713,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "ท่าโพธิ์ศรี",
    subdistrictEn: "Tha Pho Si",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม ท่าโพธิ์ศรี อุบลราชธานี 34160",
    name_th: "เดชอุดม ท่าโพธิ์ศรี อุบลราชธานี 34160",
    name_en: "Det Udom, Tha Pho Si, Ubon Ratchathani, 34160",
  },
  {
    id: 340715,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "บัวงาม",
    subdistrictEn: "Bua Ngam",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม บัวงาม อุบลราชธานี 34160",
    name_th: "เดชอุดม บัวงาม อุบลราชธานี 34160",
    name_en: "Det Udom, Bua Ngam, Ubon Ratchathani, 34160",
  },
  {
    id: 340716,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "คำครั่ง",
    subdistrictEn: "Kham Khrang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม คำครั่ง อุบลราชธานี 34160",
    name_th: "เดชอุดม คำครั่ง อุบลราชธานี 34160",
    name_en: "Det Udom, Kham Khrang, Ubon Ratchathani, 34160",
  },
  {
    id: 340717,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "นากระแซง",
    subdistrictEn: "Na Krasaeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม นากระแซง อุบลราชธานี 34160",
    name_th: "เดชอุดม นากระแซง อุบลราชธานี 34160",
    name_en: "Det Udom, Na Krasaeng, Ubon Ratchathani, 34160",
  },
  {
    id: 340720,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "โพนงาม",
    subdistrictEn: "Phon Ngam",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม โพนงาม อุบลราชธานี 34160",
    name_th: "เดชอุดม โพนงาม อุบลราชธานี 34160",
    name_en: "Det Udom, Phon Ngam, Ubon Ratchathani, 34160",
  },
  {
    id: 340721,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "ป่าโมง",
    subdistrictEn: "Pa Mong",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม ป่าโมง อุบลราชธานี 34160",
    name_th: "เดชอุดม ป่าโมง อุบลราชธานี 34160",
    name_en: "Det Udom, Pa Mong, Ubon Ratchathani, 34160",
  },
  {
    id: 340723,
    districtTh: "เดชอุดม",
    districtEn: "Det Udom",
    subdistrictTh: "โนนสมบูรณ์",
    subdistrictEn: "Non Sombun",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3407,
    province_code: 23,
    name: "เดชอุดม โนนสมบูรณ์ อุบลราชธานี 34160",
    name_th: "เดชอุดม โนนสมบูรณ์ อุบลราชธานี 34160",
    name_en: "Det Udom, Non Sombun, Ubon Ratchathani, 34160",
  },
  {
    id: 340801,
    districtTh: "นาจะหลวย",
    districtEn: "Na Chaluai",
    subdistrictTh: "นาจะหลวย",
    subdistrictEn: "Na Chaluai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34280,
    district_code: 3408,
    province_code: 23,
    name: "นาจะหลวย นาจะหลวย อุบลราชธานี 34280",
    name_th: "นาจะหลวย นาจะหลวย อุบลราชธานี 34280",
    name_en: "Na Chaluai, Na Chaluai, Ubon Ratchathani, 34280",
  },
  {
    id: 340802,
    districtTh: "นาจะหลวย",
    districtEn: "Na Chaluai",
    subdistrictTh: "โนนสมบูรณ์",
    subdistrictEn: "Non Sombun",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34280,
    district_code: 3408,
    province_code: 23,
    name: "นาจะหลวย โนนสมบูรณ์ อุบลราชธานี 34280",
    name_th: "นาจะหลวย โนนสมบูรณ์ อุบลราชธานี 34280",
    name_en: "Na Chaluai, Non Sombun, Ubon Ratchathani, 34280",
  },
  {
    id: 340803,
    districtTh: "นาจะหลวย",
    districtEn: "Na Chaluai",
    subdistrictTh: "พรสวรรค์",
    subdistrictEn: "Phon Sawan",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34280,
    district_code: 3408,
    province_code: 23,
    name: "นาจะหลวย พรสวรรค์ อุบลราชธานี 34280",
    name_th: "นาจะหลวย พรสวรรค์ อุบลราชธานี 34280",
    name_en: "Na Chaluai, Phon Sawan, Ubon Ratchathani, 34280",
  },
  {
    id: 340804,
    districtTh: "นาจะหลวย",
    districtEn: "Na Chaluai",
    subdistrictTh: "บ้านตูม",
    subdistrictEn: "Ban Tum",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34280,
    district_code: 3408,
    province_code: 23,
    name: "นาจะหลวย บ้านตูม อุบลราชธานี 34280",
    name_th: "นาจะหลวย บ้านตูม อุบลราชธานี 34280",
    name_en: "Na Chaluai, Ban Tum, Ubon Ratchathani, 34280",
  },
  {
    id: 340805,
    districtTh: "นาจะหลวย",
    districtEn: "Na Chaluai",
    subdistrictTh: "โสกแสง",
    subdistrictEn: "Sok Saeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34280,
    district_code: 3408,
    province_code: 23,
    name: "นาจะหลวย โสกแสง อุบลราชธานี 34280",
    name_th: "นาจะหลวย โสกแสง อุบลราชธานี 34280",
    name_en: "Na Chaluai, Sok Saeng, Ubon Ratchathani, 34280",
  },
  {
    id: 340806,
    districtTh: "นาจะหลวย",
    districtEn: "Na Chaluai",
    subdistrictTh: "โนนสวรรค์",
    subdistrictEn: "Non Sawan",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34280,
    district_code: 3408,
    province_code: 23,
    name: "นาจะหลวย โนนสวรรค์ อุบลราชธานี 34280",
    name_th: "นาจะหลวย โนนสวรรค์ อุบลราชธานี 34280",
    name_en: "Na Chaluai, Non Sawan, Ubon Ratchathani, 34280",
  },
  {
    id: 340901,
    districtTh: "น้ำยืน",
    districtEn: "Nam Yuen",
    subdistrictTh: "โซง",
    subdistrictEn: "Song",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3409,
    province_code: 23,
    name: "น้ำยืน โซง อุบลราชธานี 34260",
    name_th: "น้ำยืน โซง อุบลราชธานี 34260",
    name_en: "Nam Yuen, Song, Ubon Ratchathani, 34260",
  },
  {
    id: 340903,
    districtTh: "น้ำยืน",
    districtEn: "Nam Yuen",
    subdistrictTh: "ยาง",
    subdistrictEn: "Yang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3409,
    province_code: 23,
    name: "น้ำยืน ยาง อุบลราชธานี 34260",
    name_th: "น้ำยืน ยาง อุบลราชธานี 34260",
    name_en: "Nam Yuen, Yang, Ubon Ratchathani, 34260",
  },
  {
    id: 340904,
    districtTh: "น้ำยืน",
    districtEn: "Nam Yuen",
    subdistrictTh: "โดมประดิษฐ์",
    subdistrictEn: "Dom Pradit",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3409,
    province_code: 23,
    name: "น้ำยืน โดมประดิษฐ์ อุบลราชธานี 34260",
    name_th: "น้ำยืน โดมประดิษฐ์ อุบลราชธานี 34260",
    name_en: "Nam Yuen, Dom Pradit, Ubon Ratchathani, 34260",
  },
  {
    id: 340906,
    districtTh: "น้ำยืน",
    districtEn: "Nam Yuen",
    subdistrictTh: "บุเปือย",
    subdistrictEn: "Bu Pueai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3409,
    province_code: 23,
    name: "น้ำยืน บุเปือย อุบลราชธานี 34260",
    name_th: "น้ำยืน บุเปือย อุบลราชธานี 34260",
    name_en: "Nam Yuen, Bu Pueai, Ubon Ratchathani, 34260",
  },
  {
    id: 340907,
    districtTh: "น้ำยืน",
    districtEn: "Nam Yuen",
    subdistrictTh: "สีวิเชียร",
    subdistrictEn: "Si Wichian",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3409,
    province_code: 23,
    name: "น้ำยืน สีวิเชียร อุบลราชธานี 34260",
    name_th: "น้ำยืน สีวิเชียร อุบลราชธานี 34260",
    name_en: "Nam Yuen, Si Wichian, Ubon Ratchathani, 34260",
  },
  {
    id: 340909,
    districtTh: "น้ำยืน",
    districtEn: "Nam Yuen",
    subdistrictTh: "ยางใหญ่",
    subdistrictEn: "Yang Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3409,
    province_code: 23,
    name: "น้ำยืน ยางใหญ่ อุบลราชธานี 34260",
    name_th: "น้ำยืน ยางใหญ่ อุบลราชธานี 34260",
    name_en: "Nam Yuen, Yang Yai, Ubon Ratchathani, 34260",
  },
  {
    id: 340911,
    districtTh: "น้ำยืน",
    districtEn: "Nam Yuen",
    subdistrictTh: "เก่าขาม",
    subdistrictEn: "Kao Kham",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3409,
    province_code: 23,
    name: "น้ำยืน เก่าขาม อุบลราชธานี 34260",
    name_th: "น้ำยืน เก่าขาม อุบลราชธานี 34260",
    name_en: "Nam Yuen, Kao Kham, Ubon Ratchathani, 34260",
  },
  {
    id: 341001,
    districtTh: "บุณฑริก",
    districtEn: "Buntharik",
    subdistrictTh: "โพนงาม",
    subdistrictEn: "Phon Ngam",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34230,
    district_code: 3410,
    province_code: 23,
    name: "บุณฑริก โพนงาม อุบลราชธานี 34230",
    name_th: "บุณฑริก โพนงาม อุบลราชธานี 34230",
    name_en: "Buntharik, Phon Ngam, Ubon Ratchathani, 34230",
  },
  {
    id: 341002,
    districtTh: "บุณฑริก",
    districtEn: "Buntharik",
    subdistrictTh: "ห้วยข่า",
    subdistrictEn: "Huai Kha",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34230,
    district_code: 3410,
    province_code: 23,
    name: "บุณฑริก ห้วยข่า อุบลราชธานี 34230",
    name_th: "บุณฑริก ห้วยข่า อุบลราชธานี 34230",
    name_en: "Buntharik, Huai Kha, Ubon Ratchathani, 34230",
  },
  {
    id: 341003,
    districtTh: "บุณฑริก",
    districtEn: "Buntharik",
    subdistrictTh: "คอแลน",
    subdistrictEn: "Kho Laen",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34230,
    district_code: 3410,
    province_code: 23,
    name: "บุณฑริก คอแลน อุบลราชธานี 34230",
    name_th: "บุณฑริก คอแลน อุบลราชธานี 34230",
    name_en: "Buntharik, Kho Laen, Ubon Ratchathani, 34230",
  },
  {
    id: 341004,
    districtTh: "บุณฑริก",
    districtEn: "Buntharik",
    subdistrictTh: "นาโพธิ์",
    subdistrictEn: "Na Pho",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34230,
    district_code: 3410,
    province_code: 23,
    name: "บุณฑริก นาโพธิ์ อุบลราชธานี 34230",
    name_th: "บุณฑริก นาโพธิ์ อุบลราชธานี 34230",
    name_en: "Buntharik, Na Pho, Ubon Ratchathani, 34230",
  },
  {
    id: 341005,
    districtTh: "บุณฑริก",
    districtEn: "Buntharik",
    subdistrictTh: "หนองสะโน",
    subdistrictEn: "Nong Sano",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34230,
    district_code: 3410,
    province_code: 23,
    name: "บุณฑริก หนองสะโน อุบลราชธานี 34230",
    name_th: "บุณฑริก หนองสะโน อุบลราชธานี 34230",
    name_en: "Buntharik, Nong Sano, Ubon Ratchathani, 34230",
  },
  {
    id: 341006,
    districtTh: "บุณฑริก",
    districtEn: "Buntharik",
    subdistrictTh: "โนนค้อ",
    subdistrictEn: "Non Kho",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34230,
    district_code: 3410,
    province_code: 23,
    name: "บุณฑริก โนนค้อ อุบลราชธานี 34230",
    name_th: "บุณฑริก โนนค้อ อุบลราชธานี 34230",
    name_en: "Buntharik, Non Kho, Ubon Ratchathani, 34230",
  },
  {
    id: 341007,
    districtTh: "บุณฑริก",
    districtEn: "Buntharik",
    subdistrictTh: "บัวงาม",
    subdistrictEn: "Bua Ngam",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34230,
    district_code: 3410,
    province_code: 23,
    name: "บุณฑริก บัวงาม อุบลราชธานี 34230",
    name_th: "บุณฑริก บัวงาม อุบลราชธานี 34230",
    name_en: "Buntharik, Bua Ngam, Ubon Ratchathani, 34230",
  },
  {
    id: 341008,
    districtTh: "บุณฑริก",
    districtEn: "Buntharik",
    subdistrictTh: "บ้านแมด",
    subdistrictEn: "Ban Maet",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34230,
    district_code: 3410,
    province_code: 23,
    name: "บุณฑริก บ้านแมด อุบลราชธานี 34230",
    name_th: "บุณฑริก บ้านแมด อุบลราชธานี 34230",
    name_en: "Buntharik, Ban Maet, Ubon Ratchathani, 34230",
  },
  {
    id: 341101,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "ขุหลุ",
    subdistrictEn: "Khulu",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล ขุหลุ อุบลราชธานี 34130",
    name_th: "ตระการพืชผล ขุหลุ อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Khulu, Ubon Ratchathani, 34130",
  },
  {
    id: 341102,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "กระเดียน",
    subdistrictEn: "Kradian",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล กระเดียน อุบลราชธานี 34130",
    name_th: "ตระการพืชผล กระเดียน อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Kradian, Ubon Ratchathani, 34130",
  },
  {
    id: 341103,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "เกษม",
    subdistrictEn: "Kasem",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล เกษม อุบลราชธานี 34130",
    name_th: "ตระการพืชผล เกษม อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Kasem, Ubon Ratchathani, 34130",
  },
  {
    id: 341104,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "กุศกร",
    subdistrictEn: "Kutsakon",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล กุศกร อุบลราชธานี 34130",
    name_th: "ตระการพืชผล กุศกร อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Kutsakon, Ubon Ratchathani, 34130",
  },
  {
    id: 341105,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "ขามเปี้ย",
    subdistrictEn: "Kham Pia",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล ขามเปี้ย อุบลราชธานี 34130",
    name_th: "ตระการพืชผล ขามเปี้ย อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Kham Pia, Ubon Ratchathani, 34130",
  },
  {
    id: 341106,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "คอนสาย",
    subdistrictEn: "Khon Sai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล คอนสาย อุบลราชธานี 34130",
    name_th: "ตระการพืชผล คอนสาย อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Khon Sai, Ubon Ratchathani, 34130",
  },
  {
    id: 341107,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "โคกจาน",
    subdistrictEn: "Khok Chan",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล โคกจาน อุบลราชธานี 34130",
    name_th: "ตระการพืชผล โคกจาน อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Khok Chan, Ubon Ratchathani, 34130",
  },
  {
    id: 341108,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "นาพิน",
    subdistrictEn: "Na Phin",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล นาพิน อุบลราชธานี 34130",
    name_th: "ตระการพืชผล นาพิน อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Na Phin, Ubon Ratchathani, 34130",
  },
  {
    id: 341109,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "นาสะไม",
    subdistrictEn: "Na Samai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล นาสะไม อุบลราชธานี 34130",
    name_th: "ตระการพืชผล นาสะไม อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Na Samai, Ubon Ratchathani, 34130",
  },
  {
    id: 341110,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "โนนกุง",
    subdistrictEn: "Non Kung",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล โนนกุง อุบลราชธานี 34130",
    name_th: "ตระการพืชผล โนนกุง อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Non Kung, Ubon Ratchathani, 34130",
  },
  {
    id: 341111,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "ตระการ",
    subdistrictEn: "Trakan",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล ตระการ อุบลราชธานี 34130",
    name_th: "ตระการพืชผล ตระการ อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Trakan, Ubon Ratchathani, 34130",
  },
  {
    id: 341112,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "ตากแดด",
    subdistrictEn: "Tak Daet",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล ตากแดด อุบลราชธานี 34130",
    name_th: "ตระการพืชผล ตากแดด อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Tak Daet, Ubon Ratchathani, 34130",
  },
  {
    id: 341113,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "ไหล่ทุ่ง",
    subdistrictEn: "Lai Thung",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล ไหล่ทุ่ง อุบลราชธานี 34130",
    name_th: "ตระการพืชผล ไหล่ทุ่ง อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Lai Thung, Ubon Ratchathani, 34130",
  },
  {
    id: 341114,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "เป้า",
    subdistrictEn: "Pao",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล เป้า อุบลราชธานี 34130",
    name_th: "ตระการพืชผล เป้า อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Pao, Ubon Ratchathani, 34130",
  },
  {
    id: 341115,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "เซเป็ด",
    subdistrictEn: "Se Pet",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล เซเป็ด อุบลราชธานี 34130",
    name_th: "ตระการพืชผล เซเป็ด อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Se Pet, Ubon Ratchathani, 34130",
  },
  {
    id: 341116,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "สะพือ",
    subdistrictEn: "Saphue",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล สะพือ อุบลราชธานี 34130",
    name_th: "ตระการพืชผล สะพือ อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Saphue, Ubon Ratchathani, 34130",
  },
  {
    id: 341117,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "หนองเต่า",
    subdistrictEn: "Nong Tao",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล หนองเต่า อุบลราชธานี 34130",
    name_th: "ตระการพืชผล หนองเต่า อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Nong Tao, Ubon Ratchathani, 34130",
  },
  {
    id: 341118,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "ถ้ำแข้",
    subdistrictEn: "Tham Khae",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล ถ้ำแข้ อุบลราชธานี 34130",
    name_th: "ตระการพืชผล ถ้ำแข้ อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Tham Khae, Ubon Ratchathani, 34130",
  },
  {
    id: 341119,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "ท่าหลวง",
    subdistrictEn: "Tha Luang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล ท่าหลวง อุบลราชธานี 34130",
    name_th: "ตระการพืชผล ท่าหลวง อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Tha Luang, Ubon Ratchathani, 34130",
  },
  {
    id: 341120,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "ห้วยฝ้ายพัฒนา",
    subdistrictEn: "Huai Fai Phatthana",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล ห้วยฝ้ายพัฒนา อุบลราชธานี 34130",
    name_th: "ตระการพืชผล ห้วยฝ้ายพัฒนา อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Huai Fai Phatthana, Ubon Ratchathani, 34130",
  },
  {
    id: 341121,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "กุดยาลวน",
    subdistrictEn: "Kut Ya Luan",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล กุดยาลวน อุบลราชธานี 34130",
    name_th: "ตระการพืชผล กุดยาลวน อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Kut Ya Luan, Ubon Ratchathani, 34130",
  },
  {
    id: 341122,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "บ้านแดง",
    subdistrictEn: "Ban Daeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล บ้านแดง อุบลราชธานี 34130",
    name_th: "ตระการพืชผล บ้านแดง อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Ban Daeng, Ubon Ratchathani, 34130",
  },
  {
    id: 341123,
    districtTh: "ตระการพืชผล",
    districtEn: "Trakan Phuet Phon",
    subdistrictTh: "คำเจริญ",
    subdistrictEn: "Kham Charoen",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34130,
    district_code: 3411,
    province_code: 23,
    name: "ตระการพืชผล คำเจริญ อุบลราชธานี 34130",
    name_th: "ตระการพืชผล คำเจริญ อุบลราชธานี 34130",
    name_en: "Trakan Phuet Phon, Kham Charoen, Ubon Ratchathani, 34130",
  },
  {
    id: 341201,
    districtTh: "กุดข้าวปุ้น",
    districtEn: "Kut Khaopun",
    subdistrictTh: "ข้าวปุ้น",
    subdistrictEn: "Khaopun",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34270,
    district_code: 3412,
    province_code: 23,
    name: "กุดข้าวปุ้น ข้าวปุ้น อุบลราชธานี 34270",
    name_th: "กุดข้าวปุ้น ข้าวปุ้น อุบลราชธานี 34270",
    name_en: "Kut Khaopun, Khaopun, Ubon Ratchathani, 34270",
  },
  {
    id: 341202,
    districtTh: "กุดข้าวปุ้น",
    districtEn: "Kut Khaopun",
    subdistrictTh: "โนนสวาง",
    subdistrictEn: "Non Sawang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34270,
    district_code: 3412,
    province_code: 23,
    name: "กุดข้าวปุ้น โนนสวาง อุบลราชธานี 34270",
    name_th: "กุดข้าวปุ้น โนนสวาง อุบลราชธานี 34270",
    name_en: "Kut Khaopun, Non Sawang, Ubon Ratchathani, 34270",
  },
  {
    id: 341203,
    districtTh: "กุดข้าวปุ้น",
    districtEn: "Kut Khaopun",
    subdistrictTh: "แก่งเค็ง",
    subdistrictEn: "Kaeng Kheng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34270,
    district_code: 3412,
    province_code: 23,
    name: "กุดข้าวปุ้น แก่งเค็ง อุบลราชธานี 34270",
    name_th: "กุดข้าวปุ้น แก่งเค็ง อุบลราชธานี 34270",
    name_en: "Kut Khaopun, Kaeng Kheng, Ubon Ratchathani, 34270",
  },
  {
    id: 341204,
    districtTh: "กุดข้าวปุ้น",
    districtEn: "Kut Khaopun",
    subdistrictTh: "กาบิน",
    subdistrictEn: "Ka Bin",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34270,
    district_code: 3412,
    province_code: 23,
    name: "กุดข้าวปุ้น กาบิน อุบลราชธานี 34270",
    name_th: "กุดข้าวปุ้น กาบิน อุบลราชธานี 34270",
    name_en: "Kut Khaopun, Ka Bin, Ubon Ratchathani, 34270",
  },
  {
    id: 341205,
    districtTh: "กุดข้าวปุ้น",
    districtEn: "Kut Khaopun",
    subdistrictTh: "หนองทันน้ำ",
    subdistrictEn: "Nong Than Nam",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34270,
    district_code: 3412,
    province_code: 23,
    name: "กุดข้าวปุ้น หนองทันน้ำ อุบลราชธานี 34270",
    name_th: "กุดข้าวปุ้น หนองทันน้ำ อุบลราชธานี 34270",
    name_en: "Kut Khaopun, Nong Than Nam, Ubon Ratchathani, 34270",
  },
  {
    id: 341401,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "ม่วงสามสิบ",
    subdistrictEn: "Muang Sam Sip",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ ม่วงสามสิบ อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ ม่วงสามสิบ อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Muang Sam Sip, Ubon Ratchathani, 34140",
  },
  {
    id: 341402,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "เหล่าบก",
    subdistrictEn: "Lao Bok",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ เหล่าบก อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ เหล่าบก อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Lao Bok, Ubon Ratchathani, 34140",
  },
  {
    id: 341403,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "ดุมใหญ่",
    subdistrictEn: "Dum Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ ดุมใหญ่ อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ ดุมใหญ่ อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Dum Yai, Ubon Ratchathani, 34140",
  },
  {
    id: 341404,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "หนองช้างใหญ่",
    subdistrictEn: "Non Chang Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ หนองช้างใหญ่ อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ หนองช้างใหญ่ อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Non Chang Yai, Ubon Ratchathani, 34140",
  },
  {
    id: 341405,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "หนองเมือง",
    subdistrictEn: "Nong Mueang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ หนองเมือง อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ หนองเมือง อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Nong Mueang, Ubon Ratchathani, 34140",
  },
  {
    id: 341406,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "เตย",
    subdistrictEn: "Toei",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ เตย อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ เตย อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Toei, Ubon Ratchathani, 34140",
  },
  {
    id: 341407,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "ยางสักกระโพหลุ่ม",
    subdistrictEn: "Yang Sak Krapho Lum",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ ยางสักกระโพหลุ่ม อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ ยางสักกระโพหลุ่ม อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Yang Sak Krapho Lum, Ubon Ratchathani, 34140",
  },
  {
    id: 341408,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "หนองไข่นก",
    subdistrictEn: "Nong Khai Nok",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ หนองไข่นก อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ หนองไข่นก อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Nong Khai Nok, Ubon Ratchathani, 34140",
  },
  {
    id: 341409,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "หนองเหล่า",
    subdistrictEn: "Nong Lao",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ หนองเหล่า อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ หนองเหล่า อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Nong Lao, Ubon Ratchathani, 34140",
  },
  {
    id: 341410,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "หนองฮาง",
    subdistrictEn: "Nong Hang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ หนองฮาง อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ หนองฮาง อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Nong Hang, Ubon Ratchathani, 34140",
  },
  {
    id: 341411,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "ยางโยภาพ",
    subdistrictEn: "Yang Yo Phap",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ ยางโยภาพ อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ ยางโยภาพ อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Yang Yo Phap, Ubon Ratchathani, 34140",
  },
  {
    id: 341412,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "ไผ่ใหญ่",
    subdistrictEn: "Phai Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ ไผ่ใหญ่ อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ ไผ่ใหญ่ อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Phai Yai, Ubon Ratchathani, 34140",
  },
  {
    id: 341413,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "นาเลิง",
    subdistrictEn: "Na Loeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ นาเลิง อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ นาเลิง อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Na Loeng, Ubon Ratchathani, 34140",
  },
  {
    id: 341414,
    districtTh: "ม่วงสามสิบ",
    districtEn: "Muang Sam Sip",
    subdistrictTh: "โพนแพง",
    subdistrictEn: "Phon Phaeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34140,
    district_code: 3414,
    province_code: 23,
    name: "ม่วงสามสิบ โพนแพง อุบลราชธานี 34140",
    name_th: "ม่วงสามสิบ โพนแพง อุบลราชธานี 34140",
    name_en: "Muang Sam Sip, Phon Phaeng, Ubon Ratchathani, 34140",
  },
  {
    id: 341501,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "วารินชำราบ",
    subdistrictEn: "Warin Chamrap",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ วารินชำราบ อุบลราชธานี 34190",
    name_th: "วารินชำราบ วารินชำราบ อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Warin Chamrap, Ubon Ratchathani, 34190",
  },
  {
    id: 341502,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "ธาตุ",
    subdistrictEn: "That",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ ธาตุ อุบลราชธานี 34190",
    name_th: "วารินชำราบ ธาตุ อุบลราชธานี 34190",
    name_en: "Warin Chamrap, That, Ubon Ratchathani, 34190",
  },
  {
    id: 341504,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "ท่าลาด",
    subdistrictEn: "Tha Lat",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34310,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ ท่าลาด อุบลราชธานี 34310",
    name_th: "วารินชำราบ ท่าลาด อุบลราชธานี 34310",
    name_en: "Warin Chamrap, Tha Lat, Ubon Ratchathani, 34310",
  },
  {
    id: 341505,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "โนนโหนน",
    subdistrictEn: "Non Non",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ โนนโหนน อุบลราชธานี 34190",
    name_th: "วารินชำราบ โนนโหนน อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Non Non, Ubon Ratchathani, 34190",
  },
  {
    id: 341507,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "คูเมือง",
    subdistrictEn: "Khu Mueang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ คูเมือง อุบลราชธานี 34190",
    name_th: "วารินชำราบ คูเมือง อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Khu Mueang, Ubon Ratchathani, 34190",
  },
  {
    id: 341508,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "สระสมิง",
    subdistrictEn: "Sa Saming",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ สระสมิง อุบลราชธานี 34190",
    name_th: "วารินชำราบ สระสมิง อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Sa Saming, Ubon Ratchathani, 34190",
  },
  {
    id: 341510,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "คำน้ำแซบ",
    subdistrictEn: "Kham Nam Saep",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ คำน้ำแซบ อุบลราชธานี 34190",
    name_th: "วารินชำราบ คำน้ำแซบ อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Kham Nam Saep, Ubon Ratchathani, 34190",
  },
  {
    id: 341511,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "บุ่งหวาย",
    subdistrictEn: "Bung Wai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34310,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ บุ่งหวาย อุบลราชธานี 34310",
    name_th: "วารินชำราบ บุ่งหวาย อุบลราชธานี 34310",
    name_en: "Warin Chamrap, Bung Wai, Ubon Ratchathani, 34310",
  },
  {
    id: 341515,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "คำขวาง",
    subdistrictEn: "Kham Khwang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ คำขวาง อุบลราชธานี 34190",
    name_th: "วารินชำราบ คำขวาง อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Kham Khwang, Ubon Ratchathani, 34190",
  },
  {
    id: 341516,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "โพธิ์ใหญ่",
    subdistrictEn: "Pho Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ โพธิ์ใหญ่ อุบลราชธานี 34190",
    name_th: "วารินชำราบ โพธิ์ใหญ่ อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Pho Yai, Ubon Ratchathani, 34190",
  },
  {
    id: 341518,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "แสนสุข",
    subdistrictEn: "Saen Suk",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ แสนสุข อุบลราชธานี 34190",
    name_th: "วารินชำราบ แสนสุข อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Saen Suk, Ubon Ratchathani, 34190",
  },
  {
    id: 341520,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "หนองกินเพล",
    subdistrictEn: "Nong Kin Phen",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ หนองกินเพล อุบลราชธานี 34190",
    name_th: "วารินชำราบ หนองกินเพล อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Nong Kin Phen, Ubon Ratchathani, 34190",
  },
  {
    id: 341521,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "โนนผึ้ง",
    subdistrictEn: "Non Phueng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ โนนผึ้ง อุบลราชธานี 34190",
    name_th: "วารินชำราบ โนนผึ้ง อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Non Phueng, Ubon Ratchathani, 34190",
  },
  {
    id: 341522,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "เมืองศรีไค",
    subdistrictEn: "Mueang Si Khai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ เมืองศรีไค อุบลราชธานี 34190",
    name_th: "วารินชำราบ เมืองศรีไค อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Mueang Si Khai, Ubon Ratchathani, 34190",
  },
  {
    id: 341524,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "ห้วยขะยูง",
    subdistrictEn: "Huai Khayung",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34310,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ ห้วยขะยูง อุบลราชธานี 34310",
    name_th: "วารินชำราบ ห้วยขะยูง อุบลราชธานี 34310",
    name_en: "Warin Chamrap, Huai Khayung, Ubon Ratchathani, 34310",
  },
  {
    id: 341526,
    districtTh: "วารินชำราบ",
    districtEn: "Warin Chamrap",
    subdistrictTh: "บุ่งไหม",
    subdistrictEn: "Bung Mai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3415,
    province_code: 23,
    name: "วารินชำราบ บุ่งไหม อุบลราชธานี 34190",
    name_th: "วารินชำราบ บุ่งไหม อุบลราชธานี 34190",
    name_en: "Warin Chamrap, Bung Mai, Ubon Ratchathani, 34190",
  },
  {
    id: 341901,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "พิบูล",
    subdistrictEn: "Phibun",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร พิบูล อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร พิบูล อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Phibun, Ubon Ratchathani, 34110",
  },
  {
    id: 341902,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "กุดชมภู",
    subdistrictEn: "Kut Chom Phu",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร กุดชมภู อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร กุดชมภู อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Kut Chom Phu, Ubon Ratchathani, 34110",
  },
  {
    id: 341904,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "ดอนจิก",
    subdistrictEn: "Don Chik",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร ดอนจิก อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร ดอนจิก อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Don Chik, Ubon Ratchathani, 34110",
  },
  {
    id: 341905,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "ทรายมูล",
    subdistrictEn: "Sai Mun",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร ทรายมูล อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร ทรายมูล อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Sai Mun, Ubon Ratchathani, 34110",
  },
  {
    id: 341906,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "นาโพธิ์",
    subdistrictEn: "Na Pho",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร นาโพธิ์ อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร นาโพธิ์ อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Na Pho, Ubon Ratchathani, 34110",
  },
  {
    id: 341907,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "โนนกลาง",
    subdistrictEn: "Non Klang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร โนนกลาง อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร โนนกลาง อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Non Klang, Ubon Ratchathani, 34110",
  },
  {
    id: 341909,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "โพธิ์ไทร",
    subdistrictEn: "Pho Sai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร โพธิ์ไทร อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร โพธิ์ไทร อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Pho Sai, Ubon Ratchathani, 34110",
  },
  {
    id: 341910,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "โพธิ์ศรี",
    subdistrictEn: "Pho Si",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร โพธิ์ศรี อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร โพธิ์ศรี อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Pho Si, Ubon Ratchathani, 34110",
  },
  {
    id: 341911,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "ระเว",
    subdistrictEn: "Rawe",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร ระเว อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร ระเว อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Rawe, Ubon Ratchathani, 34110",
  },
  {
    id: 341912,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "ไร่ใต้",
    subdistrictEn: "Rai Tai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร ไร่ใต้ อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร ไร่ใต้ อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Rai Tai, Ubon Ratchathani, 34110",
  },
  {
    id: 341913,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "หนองบัวฮี",
    subdistrictEn: "Nong Bua Hi",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร หนองบัวฮี อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร หนองบัวฮี อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Nong Bua Hi, Ubon Ratchathani, 34110",
  },
  {
    id: 341914,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "อ่างศิลา",
    subdistrictEn: "Ang Sila",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร อ่างศิลา อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร อ่างศิลา อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Ang Sila, Ubon Ratchathani, 34110",
  },
  {
    id: 341918,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "โนนกาหลง",
    subdistrictEn: "Non Kalong",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร โนนกาหลง อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร โนนกาหลง อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Non Kalong, Ubon Ratchathani, 34110",
  },
  {
    id: 341919,
    districtTh: "พิบูลมังสาหาร",
    districtEn: "Phibun Mangsahan",
    subdistrictTh: "บ้านแขม",
    subdistrictEn: "Ban Khaem",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34110,
    district_code: 3419,
    province_code: 23,
    name: "พิบูลมังสาหาร บ้านแขม อุบลราชธานี 34110",
    name_th: "พิบูลมังสาหาร บ้านแขม อุบลราชธานี 34110",
    name_en: "Phibun Mangsahan, Ban Khaem, Ubon Ratchathani, 34110",
  },
  {
    id: 342001,
    districtTh: "ตาลสุม",
    districtEn: "Tan Sum",
    subdistrictTh: "ตาลสุม",
    subdistrictEn: "Tan Sum",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34330,
    district_code: 3420,
    province_code: 23,
    name: "ตาลสุม ตาลสุม อุบลราชธานี 34330",
    name_th: "ตาลสุม ตาลสุม อุบลราชธานี 34330",
    name_en: "Tan Sum, Tan Sum, Ubon Ratchathani, 34330",
  },
  {
    id: 342002,
    districtTh: "ตาลสุม",
    districtEn: "Tan Sum",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34330,
    district_code: 3420,
    province_code: 23,
    name: "ตาลสุม สำโรง อุบลราชธานี 34330",
    name_th: "ตาลสุม สำโรง อุบลราชธานี 34330",
    name_en: "Tan Sum, Samrong, Ubon Ratchathani, 34330",
  },
  {
    id: 342003,
    districtTh: "ตาลสุม",
    districtEn: "Tan Sum",
    subdistrictTh: "จิกเทิง",
    subdistrictEn: "Chik Thoeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34330,
    district_code: 3420,
    province_code: 23,
    name: "ตาลสุม จิกเทิง อุบลราชธานี 34330",
    name_th: "ตาลสุม จิกเทิง อุบลราชธานี 34330",
    name_en: "Tan Sum, Chik Thoeng, Ubon Ratchathani, 34330",
  },
  {
    id: 342004,
    districtTh: "ตาลสุม",
    districtEn: "Tan Sum",
    subdistrictTh: "หนองกุง",
    subdistrictEn: "Nong Kung",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34330,
    district_code: 3420,
    province_code: 23,
    name: "ตาลสุม หนองกุง อุบลราชธานี 34330",
    name_th: "ตาลสุม หนองกุง อุบลราชธานี 34330",
    name_en: "Tan Sum, Nong Kung, Ubon Ratchathani, 34330",
  },
  {
    id: 342005,
    districtTh: "ตาลสุม",
    districtEn: "Tan Sum",
    subdistrictTh: "นาคาย",
    subdistrictEn: "Na Khai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34330,
    district_code: 3420,
    province_code: 23,
    name: "ตาลสุม นาคาย อุบลราชธานี 34330",
    name_th: "ตาลสุม นาคาย อุบลราชธานี 34330",
    name_en: "Tan Sum, Na Khai, Ubon Ratchathani, 34330",
  },
  {
    id: 342006,
    districtTh: "ตาลสุม",
    districtEn: "Tan Sum",
    subdistrictTh: "คำหว้า",
    subdistrictEn: "Kham Wa",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34330,
    district_code: 3420,
    province_code: 23,
    name: "ตาลสุม คำหว้า อุบลราชธานี 34330",
    name_th: "ตาลสุม คำหว้า อุบลราชธานี 34330",
    name_en: "Tan Sum, Kham Wa, Ubon Ratchathani, 34330",
  },
  {
    id: 342101,
    districtTh: "โพธิ์ไทร",
    districtEn: "Pho Sai",
    subdistrictTh: "โพธิ์ไทร",
    subdistrictEn: "Pho Sai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34340,
    district_code: 3421,
    province_code: 23,
    name: "โพธิ์ไทร โพธิ์ไทร อุบลราชธานี 34340",
    name_th: "โพธิ์ไทร โพธิ์ไทร อุบลราชธานี 34340",
    name_en: "Pho Sai, Pho Sai, Ubon Ratchathani, 34340",
  },
  {
    id: 342102,
    districtTh: "โพธิ์ไทร",
    districtEn: "Pho Sai",
    subdistrictTh: "ม่วงใหญ่",
    subdistrictEn: "Muang Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34340,
    district_code: 3421,
    province_code: 23,
    name: "โพธิ์ไทร ม่วงใหญ่ อุบลราชธานี 34340",
    name_th: "โพธิ์ไทร ม่วงใหญ่ อุบลราชธานี 34340",
    name_en: "Pho Sai, Muang Yai, Ubon Ratchathani, 34340",
  },
  {
    id: 342103,
    districtTh: "โพธิ์ไทร",
    districtEn: "Pho Sai",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Sam Rong",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34340,
    district_code: 3421,
    province_code: 23,
    name: "โพธิ์ไทร สำโรง อุบลราชธานี 34340",
    name_th: "โพธิ์ไทร สำโรง อุบลราชธานี 34340",
    name_en: "Pho Sai, Sam Rong, Ubon Ratchathani, 34340",
  },
  {
    id: 342104,
    districtTh: "โพธิ์ไทร",
    districtEn: "Pho Sai",
    subdistrictTh: "สองคอน",
    subdistrictEn: "Song Khon",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34340,
    district_code: 3421,
    province_code: 23,
    name: "โพธิ์ไทร สองคอน อุบลราชธานี 34340",
    name_th: "โพธิ์ไทร สองคอน อุบลราชธานี 34340",
    name_en: "Pho Sai, Song Khon, Ubon Ratchathani, 34340",
  },
  {
    id: 342105,
    districtTh: "โพธิ์ไทร",
    districtEn: "Pho Sai",
    subdistrictTh: "สารภี",
    subdistrictEn: "Saraphi",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34340,
    district_code: 3421,
    province_code: 23,
    name: "โพธิ์ไทร สารภี อุบลราชธานี 34340",
    name_th: "โพธิ์ไทร สารภี อุบลราชธานี 34340",
    name_en: "Pho Sai, Saraphi, Ubon Ratchathani, 34340",
  },
  {
    id: 342106,
    districtTh: "โพธิ์ไทร",
    districtEn: "Pho Sai",
    subdistrictTh: "เหล่างาม",
    subdistrictEn: "Lao Ngam",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34340,
    district_code: 3421,
    province_code: 23,
    name: "โพธิ์ไทร เหล่างาม อุบลราชธานี 34340",
    name_th: "โพธิ์ไทร เหล่างาม อุบลราชธานี 34340",
    name_en: "Pho Sai, Lao Ngam, Ubon Ratchathani, 34340",
  },
  {
    id: 342201,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง สำโรง อุบลราชธานี 34360",
    name_th: "สำโรง สำโรง อุบลราชธานี 34360",
    name_en: "Samrong, Samrong, Ubon Ratchathani, 34360",
  },
  {
    id: 342202,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "โคกก่อง",
    subdistrictEn: "Khok Kong",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง โคกก่อง อุบลราชธานี 34360",
    name_th: "สำโรง โคกก่อง อุบลราชธานี 34360",
    name_en: "Samrong, Khok Kong, Ubon Ratchathani, 34360",
  },
  {
    id: 342203,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "หนองไฮ",
    subdistrictEn: "Nong Hai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง หนองไฮ อุบลราชธานี 34360",
    name_th: "สำโรง หนองไฮ อุบลราชธานี 34360",
    name_en: "Samrong, Nong Hai, Ubon Ratchathani, 34360",
  },
  {
    id: 342204,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "ค้อน้อย",
    subdistrictEn: "Kho Noi",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง ค้อน้อย อุบลราชธานี 34360",
    name_th: "สำโรง ค้อน้อย อุบลราชธานี 34360",
    name_en: "Samrong, Kho Noi, Ubon Ratchathani, 34360",
  },
  {
    id: 342205,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "โนนกาเล็น",
    subdistrictEn: "Non Ka Len",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง โนนกาเล็น อุบลราชธานี 34360",
    name_th: "สำโรง โนนกาเล็น อุบลราชธานี 34360",
    name_en: "Samrong, Non Ka Len, Ubon Ratchathani, 34360",
  },
  {
    id: 342206,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "โคกสว่าง",
    subdistrictEn: "Khok Sawang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง โคกสว่าง อุบลราชธานี 34360",
    name_th: "สำโรง โคกสว่าง อุบลราชธานี 34360",
    name_en: "Samrong, Khok Sawang, Ubon Ratchathani, 34360",
  },
  {
    id: 342207,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "โนนกลาง",
    subdistrictEn: "Non Klang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง โนนกลาง อุบลราชธานี 34360",
    name_th: "สำโรง โนนกลาง อุบลราชธานี 34360",
    name_en: "Samrong, Non Klang, Ubon Ratchathani, 34360",
  },
  {
    id: 342208,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "บอน",
    subdistrictEn: "Bon",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง บอน อุบลราชธานี 34360",
    name_th: "สำโรง บอน อุบลราชธานี 34360",
    name_en: "Samrong, Bon, Ubon Ratchathani, 34360",
  },
  {
    id: 342209,
    districtTh: "สำโรง",
    districtEn: "Samrong",
    subdistrictTh: "ขามป้อม",
    subdistrictEn: "Kham Pom",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34360,
    district_code: 3422,
    province_code: 23,
    name: "สำโรง ขามป้อม อุบลราชธานี 34360",
    name_th: "สำโรง ขามป้อม อุบลราชธานี 34360",
    name_en: "Samrong, Kham Pom, Ubon Ratchathani, 34360",
  },
  {
    id: 342401,
    districtTh: "ดอนมดแดง",
    districtEn: "Don Mot Daeng",
    subdistrictTh: "ดอนมดแดง",
    subdistrictEn: "Don Mot Daeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3424,
    province_code: 23,
    name: "ดอนมดแดง ดอนมดแดง อุบลราชธานี 34000",
    name_th: "ดอนมดแดง ดอนมดแดง อุบลราชธานี 34000",
    name_en: "Don Mot Daeng, Don Mot Daeng, Ubon Ratchathani, 34000",
  },
  {
    id: 342402,
    districtTh: "ดอนมดแดง",
    districtEn: "Don Mot Daeng",
    subdistrictTh: "เหล่าแดง",
    subdistrictEn: "Lao Daeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3424,
    province_code: 23,
    name: "ดอนมดแดง เหล่าแดง อุบลราชธานี 34000",
    name_th: "ดอนมดแดง เหล่าแดง อุบลราชธานี 34000",
    name_en: "Don Mot Daeng, Lao Daeng, Ubon Ratchathani, 34000",
  },
  {
    id: 342403,
    districtTh: "ดอนมดแดง",
    districtEn: "Don Mot Daeng",
    subdistrictTh: "ท่าเมือง",
    subdistrictEn: "Tha Mueang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3424,
    province_code: 23,
    name: "ดอนมดแดง ท่าเมือง อุบลราชธานี 34000",
    name_th: "ดอนมดแดง ท่าเมือง อุบลราชธานี 34000",
    name_en: "Don Mot Daeng, Tha Mueang, Ubon Ratchathani, 34000",
  },
  {
    id: 342404,
    districtTh: "ดอนมดแดง",
    districtEn: "Don Mot Daeng",
    subdistrictTh: "คำไฮใหญ่",
    subdistrictEn: "Kham Hai Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3424,
    province_code: 23,
    name: "ดอนมดแดง คำไฮใหญ่ อุบลราชธานี 34000",
    name_th: "ดอนมดแดง คำไฮใหญ่ อุบลราชธานี 34000",
    name_en: "Don Mot Daeng, Kham Hai Yai, Ubon Ratchathani, 34000",
  },
  {
    id: 342501,
    districtTh: "สิรินธร",
    districtEn: "Sirindhorn",
    subdistrictTh: "คันไร่",
    subdistrictEn: "Khan Rai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34350,
    district_code: 3425,
    province_code: 23,
    name: "สิรินธร คันไร่ อุบลราชธานี 34350",
    name_th: "สิรินธร คันไร่ อุบลราชธานี 34350",
    name_en: "Sirindhorn, Khan Rai, Ubon Ratchathani, 34350",
  },
  {
    id: 342502,
    districtTh: "สิรินธร",
    districtEn: "Sirindhorn",
    subdistrictTh: "ช่องเม็ก",
    subdistrictEn: "Chong Mek",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34350,
    district_code: 3425,
    province_code: 23,
    name: "สิรินธร ช่องเม็ก อุบลราชธานี 34350",
    name_th: "สิรินธร ช่องเม็ก อุบลราชธานี 34350",
    name_en: "Sirindhorn, Chong Mek, Ubon Ratchathani, 34350",
  },
  {
    id: 342503,
    districtTh: "สิรินธร",
    districtEn: "Sirindhorn",
    subdistrictTh: "โนนก่อ",
    subdistrictEn: "Non Ko",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34350,
    district_code: 3425,
    province_code: 23,
    name: "สิรินธร โนนก่อ อุบลราชธานี 34350",
    name_th: "สิรินธร โนนก่อ อุบลราชธานี 34350",
    name_en: "Sirindhorn, Non Ko, Ubon Ratchathani, 34350",
  },
  {
    id: 342504,
    districtTh: "สิรินธร",
    districtEn: "Sirindhorn",
    subdistrictTh: "นิคมสร้างตนเองลำโดมน้อย",
    subdistrictEn: "Nikhom Sang Ton Eng Lam Dom Noi",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34350,
    district_code: 3425,
    province_code: 23,
    name: "สิรินธร นิคมสร้างตนเองลำโดมน้อย อุบลราชธานี 34350",
    name_th: "สิรินธร นิคมสร้างตนเองลำโดมน้อย อุบลราชธานี 34350",
    name_en:
      "Sirindhorn, Nikhom Sang Ton Eng Lam Dom Noi, Ubon Ratchathani, 34350",
  },
  {
    id: 342505,
    districtTh: "สิรินธร",
    districtEn: "Sirindhorn",
    subdistrictTh: "ฝางคำ",
    subdistrictEn: "Fang Kham",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34350,
    district_code: 3425,
    province_code: 23,
    name: "สิรินธร ฝางคำ อุบลราชธานี 34350",
    name_th: "สิรินธร ฝางคำ อุบลราชธานี 34350",
    name_en: "Sirindhorn, Fang Kham, Ubon Ratchathani, 34350",
  },
  {
    id: 342506,
    districtTh: "สิรินธร",
    districtEn: "Sirindhorn",
    subdistrictTh: "คำเขื่อนแก้ว",
    subdistrictEn: "Kham Khuean Kaeo",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34350,
    district_code: 3425,
    province_code: 23,
    name: "สิรินธร คำเขื่อนแก้ว อุบลราชธานี 34350",
    name_th: "สิรินธร คำเขื่อนแก้ว อุบลราชธานี 34350",
    name_en: "Sirindhorn, Kham Khuean Kaeo, Ubon Ratchathani, 34350",
  },
  {
    id: 342602,
    districtTh: "ทุ่งศรีอุดม",
    districtEn: "Thung Si Udom",
    subdistrictTh: "หนองอ้ม",
    subdistrictEn: "Nong Om",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3426,
    province_code: 23,
    name: "ทุ่งศรีอุดม หนองอ้ม อุบลราชธานี 34160",
    name_th: "ทุ่งศรีอุดม หนองอ้ม อุบลราชธานี 34160",
    name_en: "Thung Si Udom, Nong Om, Ubon Ratchathani, 34160",
  },
  {
    id: 342603,
    districtTh: "ทุ่งศรีอุดม",
    districtEn: "Thung Si Udom",
    subdistrictTh: "นาเกษม",
    subdistrictEn: "Na Kasem",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3426,
    province_code: 23,
    name: "ทุ่งศรีอุดม นาเกษม อุบลราชธานี 34160",
    name_th: "ทุ่งศรีอุดม นาเกษม อุบลราชธานี 34160",
    name_en: "Thung Si Udom, Na Kasem, Ubon Ratchathani, 34160",
  },
  {
    id: 342604,
    districtTh: "ทุ่งศรีอุดม",
    districtEn: "Thung Si Udom",
    subdistrictTh: "กุดเรือ",
    subdistrictEn: "Kut Ruea",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3426,
    province_code: 23,
    name: "ทุ่งศรีอุดม กุดเรือ อุบลราชธานี 34160",
    name_th: "ทุ่งศรีอุดม กุดเรือ อุบลราชธานี 34160",
    name_en: "Thung Si Udom, Kut Ruea, Ubon Ratchathani, 34160",
  },
  {
    id: 342605,
    districtTh: "ทุ่งศรีอุดม",
    districtEn: "Thung Si Udom",
    subdistrictTh: "โคกชำแระ",
    subdistrictEn: "Khok Chamrae",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3426,
    province_code: 23,
    name: "ทุ่งศรีอุดม โคกชำแระ อุบลราชธานี 34160",
    name_th: "ทุ่งศรีอุดม โคกชำแระ อุบลราชธานี 34160",
    name_en: "Thung Si Udom, Khok Chamrae, Ubon Ratchathani, 34160",
  },
  {
    id: 342606,
    districtTh: "ทุ่งศรีอุดม",
    districtEn: "Thung Si Udom",
    subdistrictTh: "นาห่อม",
    subdistrictEn: "Na Hom",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3426,
    province_code: 23,
    name: "ทุ่งศรีอุดม นาห่อม อุบลราชธานี 34160",
    name_th: "ทุ่งศรีอุดม นาห่อม อุบลราชธานี 34160",
    name_en: "Thung Si Udom, Na Hom, Ubon Ratchathani, 34160",
  },
  {
    id: 342901,
    districtTh: "นาเยีย",
    districtEn: "Na Yia",
    subdistrictTh: "นาเยีย",
    subdistrictEn: "Na Yia",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3429,
    province_code: 23,
    name: "นาเยีย นาเยีย อุบลราชธานี 34160",
    name_th: "นาเยีย นาเยีย อุบลราชธานี 34160",
    name_en: "Na Yia, Na Yia, Ubon Ratchathani, 34160",
  },
  {
    id: 342902,
    districtTh: "นาเยีย",
    districtEn: "Na Yia",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3429,
    province_code: 23,
    name: "นาเยีย นาดี อุบลราชธานี 34160",
    name_th: "นาเยีย นาดี อุบลราชธานี 34160",
    name_en: "Na Yia, Na Di, Ubon Ratchathani, 34160",
  },
  {
    id: 342903,
    districtTh: "นาเยีย",
    districtEn: "Na Yia",
    subdistrictTh: "นาเรือง",
    subdistrictEn: "Na Rueang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34160,
    district_code: 3429,
    province_code: 23,
    name: "นาเยีย นาเรือง อุบลราชธานี 34160",
    name_th: "นาเยีย นาเรือง อุบลราชธานี 34160",
    name_en: "Na Yia, Na Rueang, Ubon Ratchathani, 34160",
  },
  {
    id: 343001,
    districtTh: "นาตาล",
    districtEn: "Na Tan",
    subdistrictTh: "นาตาล",
    subdistrictEn: "Na Tan",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3430,
    province_code: 23,
    name: "นาตาล นาตาล อุบลราชธานี 34170",
    name_th: "นาตาล นาตาล อุบลราชธานี 34170",
    name_en: "Na Tan, Na Tan, Ubon Ratchathani, 34170",
  },
  {
    id: 343002,
    districtTh: "นาตาล",
    districtEn: "Na Tan",
    subdistrictTh: "พะลาน",
    subdistrictEn: "Phalan",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3430,
    province_code: 23,
    name: "นาตาล พะลาน อุบลราชธานี 34170",
    name_th: "นาตาล พะลาน อุบลราชธานี 34170",
    name_en: "Na Tan, Phalan, Ubon Ratchathani, 34170",
  },
  {
    id: 343003,
    districtTh: "นาตาล",
    districtEn: "Na Tan",
    subdistrictTh: "กองโพน",
    subdistrictEn: "Kong Phon",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3430,
    province_code: 23,
    name: "นาตาล กองโพน อุบลราชธานี 34170",
    name_th: "นาตาล กองโพน อุบลราชธานี 34170",
    name_en: "Na Tan, Kong Phon, Ubon Ratchathani, 34170",
  },
  {
    id: 343004,
    districtTh: "นาตาล",
    districtEn: "Na Tan",
    subdistrictTh: "พังเคน",
    subdistrictEn: "Phang Khen",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34170,
    district_code: 3430,
    province_code: 23,
    name: "นาตาล พังเคน อุบลราชธานี 34170",
    name_th: "นาตาล พังเคน อุบลราชธานี 34170",
    name_en: "Na Tan, Phang Khen, Ubon Ratchathani, 34170",
  },
  {
    id: 343101,
    districtTh: "เหล่าเสือโก้ก",
    districtEn: "Lao Suea Kok",
    subdistrictTh: "เหล่าเสือโก้ก",
    subdistrictEn: "Lao Suea Kok",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3431,
    province_code: 23,
    name: "เหล่าเสือโก้ก เหล่าเสือโก้ก อุบลราชธานี 34000",
    name_th: "เหล่าเสือโก้ก เหล่าเสือโก้ก อุบลราชธานี 34000",
    name_en: "Lao Suea Kok, Lao Suea Kok, Ubon Ratchathani, 34000",
  },
  {
    id: 343102,
    districtTh: "เหล่าเสือโก้ก",
    districtEn: "Lao Suea Kok",
    subdistrictTh: "โพนเมือง",
    subdistrictEn: "Phon Mueang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3431,
    province_code: 23,
    name: "เหล่าเสือโก้ก โพนเมือง อุบลราชธานี 34000",
    name_th: "เหล่าเสือโก้ก โพนเมือง อุบลราชธานี 34000",
    name_en: "Lao Suea Kok, Phon Mueang, Ubon Ratchathani, 34000",
  },
  {
    id: 343103,
    districtTh: "เหล่าเสือโก้ก",
    districtEn: "Lao Suea Kok",
    subdistrictTh: "แพงใหญ่",
    subdistrictEn: "Phaeng Yai",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3431,
    province_code: 23,
    name: "เหล่าเสือโก้ก แพงใหญ่ อุบลราชธานี 34000",
    name_th: "เหล่าเสือโก้ก แพงใหญ่ อุบลราชธานี 34000",
    name_en: "Lao Suea Kok, Phaeng Yai, Ubon Ratchathani, 34000",
  },
  {
    id: 343104,
    districtTh: "เหล่าเสือโก้ก",
    districtEn: "Lao Suea Kok",
    subdistrictTh: "หนองบก",
    subdistrictEn: "Nong Bok",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34000,
    district_code: 3431,
    province_code: 23,
    name: "เหล่าเสือโก้ก หนองบก อุบลราชธานี 34000",
    name_th: "เหล่าเสือโก้ก หนองบก อุบลราชธานี 34000",
    name_en: "Lao Suea Kok, Nong Bok, Ubon Ratchathani, 34000",
  },
  {
    id: 343201,
    districtTh: "สว่างวีระวงศ์",
    districtEn: "Sawang Wirawong",
    subdistrictTh: "แก่งโดม",
    subdistrictEn: "Kaeng Dom",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3432,
    province_code: 23,
    name: "สว่างวีระวงศ์ แก่งโดม อุบลราชธานี 34190",
    name_th: "สว่างวีระวงศ์ แก่งโดม อุบลราชธานี 34190",
    name_en: "Sawang Wirawong, Kaeng Dom, Ubon Ratchathani, 34190",
  },
  {
    id: 343202,
    districtTh: "สว่างวีระวงศ์",
    districtEn: "Sawang Wirawong",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3432,
    province_code: 23,
    name: "สว่างวีระวงศ์ ท่าช้าง อุบลราชธานี 34190",
    name_th: "สว่างวีระวงศ์ ท่าช้าง อุบลราชธานี 34190",
    name_en: "Sawang Wirawong, Tha Chang, Ubon Ratchathani, 34190",
  },
  {
    id: 343203,
    districtTh: "สว่างวีระวงศ์",
    districtEn: "Sawang Wirawong",
    subdistrictTh: "บุ่งมะแลง",
    subdistrictEn: "Bung Malaeng",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3432,
    province_code: 23,
    name: "สว่างวีระวงศ์ บุ่งมะแลง อุบลราชธานี 34190",
    name_th: "สว่างวีระวงศ์ บุ่งมะแลง อุบลราชธานี 34190",
    name_en: "Sawang Wirawong, Bung Malaeng, Ubon Ratchathani, 34190",
  },
  {
    id: 343204,
    districtTh: "สว่างวีระวงศ์",
    districtEn: "Sawang Wirawong",
    subdistrictTh: "สว่าง",
    subdistrictEn: "Sawang",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34190,
    district_code: 3432,
    province_code: 23,
    name: "สว่างวีระวงศ์ สว่าง อุบลราชธานี 34190",
    name_th: "สว่างวีระวงศ์ สว่าง อุบลราชธานี 34190",
    name_en: "Sawang Wirawong, Sawang, Ubon Ratchathani, 34190",
  },
  {
    id: 343301,
    districtTh: "น้ำขุ่น",
    districtEn: "Nam Khun",
    subdistrictTh: "ตาเกา",
    subdistrictEn: "Ta Kao",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3433,
    province_code: 23,
    name: "น้ำขุ่น ตาเกา อุบลราชธานี 34260",
    name_th: "น้ำขุ่น ตาเกา อุบลราชธานี 34260",
    name_en: "Nam Khun, Ta Kao, Ubon Ratchathani, 34260",
  },
  {
    id: 343302,
    districtTh: "น้ำขุ่น",
    districtEn: "Nam Khun",
    subdistrictTh: "ไพบูลย์",
    subdistrictEn: "Phaibun",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3433,
    province_code: 23,
    name: "น้ำขุ่น ไพบูลย์ อุบลราชธานี 34260",
    name_th: "น้ำขุ่น ไพบูลย์ อุบลราชธานี 34260",
    name_en: "Nam Khun, Phaibun, Ubon Ratchathani, 34260",
  },
  {
    id: 343303,
    districtTh: "น้ำขุ่น",
    districtEn: "Nam Khun",
    subdistrictTh: "ขี้เหล็ก",
    subdistrictEn: "Khilek",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3433,
    province_code: 23,
    name: "น้ำขุ่น ขี้เหล็ก อุบลราชธานี 34260",
    name_th: "น้ำขุ่น ขี้เหล็ก อุบลราชธานี 34260",
    name_en: "Nam Khun, Khilek, Ubon Ratchathani, 34260",
  },
  {
    id: 343304,
    districtTh: "น้ำขุ่น",
    districtEn: "Nam Khun",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "อุบลราชธานี",
    provinceEn: "Ubon Ratchathani",
    zipcode: 34260,
    district_code: 3433,
    province_code: 23,
    name: "น้ำขุ่น โคกสะอาด อุบลราชธานี 34260",
    name_th: "น้ำขุ่น โคกสะอาด อุบลราชธานี 34260",
    name_en: "Nam Khun, Khok Sa-at, Ubon Ratchathani, 34260",
  },
  {
    id: 350101,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร ในเมือง ยโสธร 35000",
    name_th: "เมืองยโสธร ในเมือง ยโสธร 35000",
    name_en: "Mueang Yasothon, Nai Mueang, Yasothon, 35000",
  },
  {
    id: 350102,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "น้ำคำใหญ่",
    subdistrictEn: "Nam Kham Yai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร น้ำคำใหญ่ ยโสธร 35000",
    name_th: "เมืองยโสธร น้ำคำใหญ่ ยโสธร 35000",
    name_en: "Mueang Yasothon, Nam Kham Yai, Yasothon, 35000",
  },
  {
    id: 350103,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "ตาดทอง",
    subdistrictEn: "Tat Thong",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร ตาดทอง ยโสธร 35000",
    name_th: "เมืองยโสธร ตาดทอง ยโสธร 35000",
    name_en: "Mueang Yasothon, Tat Thong, Yasothon, 35000",
  },
  {
    id: 350104,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "สำราญ",
    subdistrictEn: "Samran",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร สำราญ ยโสธร 35000",
    name_th: "เมืองยโสธร สำราญ ยโสธร 35000",
    name_en: "Mueang Yasothon, Samran, Yasothon, 35000",
  },
  {
    id: 350105,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "ค้อเหนือ",
    subdistrictEn: "Kho Nuea",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร ค้อเหนือ ยโสธร 35000",
    name_th: "เมืองยโสธร ค้อเหนือ ยโสธร 35000",
    name_en: "Mueang Yasothon, Kho Nuea, Yasothon, 35000",
  },
  {
    id: 350106,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "ดู่ทุ่ง",
    subdistrictEn: "Du Thung",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร ดู่ทุ่ง ยโสธร 35000",
    name_th: "เมืองยโสธร ดู่ทุ่ง ยโสธร 35000",
    name_en: "Mueang Yasothon, Du Thung, Yasothon, 35000",
  },
  {
    id: 350107,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "เดิด",
    subdistrictEn: "Doet",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร เดิด ยโสธร 35000",
    name_th: "เมืองยโสธร เดิด ยโสธร 35000",
    name_en: "Mueang Yasothon, Doet, Yasothon, 35000",
  },
  {
    id: 350108,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "ขั้นไดใหญ่",
    subdistrictEn: "Khandai Yai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร ขั้นไดใหญ่ ยโสธร 35000",
    name_th: "เมืองยโสธร ขั้นไดใหญ่ ยโสธร 35000",
    name_en: "Mueang Yasothon, Khandai Yai, Yasothon, 35000",
  },
  {
    id: 350109,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "ทุ่งแต้",
    subdistrictEn: "Thung Tae",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร ทุ่งแต้ ยโสธร 35000",
    name_th: "เมืองยโสธร ทุ่งแต้ ยโสธร 35000",
    name_en: "Mueang Yasothon, Thung Tae, Yasothon, 35000",
  },
  {
    id: 350110,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "สิงห์",
    subdistrictEn: "Sing",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร สิงห์ ยโสธร 35000",
    name_th: "เมืองยโสธร สิงห์ ยโสธร 35000",
    name_en: "Mueang Yasothon, Sing, Yasothon, 35000",
  },
  {
    id: 350111,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "นาสะไมย์",
    subdistrictEn: "Na Samai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร นาสะไมย์ ยโสธร 35000",
    name_th: "เมืองยโสธร นาสะไมย์ ยโสธร 35000",
    name_en: "Mueang Yasothon, Na Samai, Yasothon, 35000",
  },
  {
    id: 350112,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "เขื่องคำ",
    subdistrictEn: "Khueang Kham",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร เขื่องคำ ยโสธร 35000",
    name_th: "เมืองยโสธร เขื่องคำ ยโสธร 35000",
    name_en: "Mueang Yasothon, Khueang Kham, Yasothon, 35000",
  },
  {
    id: 350113,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "หนองหิน",
    subdistrictEn: "Nong Hin",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร หนองหิน ยโสธร 35000",
    name_th: "เมืองยโสธร หนองหิน ยโสธร 35000",
    name_en: "Mueang Yasothon, Nong Hin, Yasothon, 35000",
  },
  {
    id: 350114,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "หนองคู",
    subdistrictEn: "Nong Khu",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร หนองคู ยโสธร 35000",
    name_th: "เมืองยโสธร หนองคู ยโสธร 35000",
    name_en: "Mueang Yasothon, Nong Khu, Yasothon, 35000",
  },
  {
    id: 350115,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "ขุมเงิน",
    subdistrictEn: "Khum Ngoen",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร ขุมเงิน ยโสธร 35000",
    name_th: "เมืองยโสธร ขุมเงิน ยโสธร 35000",
    name_en: "Mueang Yasothon, Khum Ngoen, Yasothon, 35000",
  },
  {
    id: 350116,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "ทุ่งนางโอก",
    subdistrictEn: "Thung Nang Ok",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร ทุ่งนางโอก ยโสธร 35000",
    name_th: "เมืองยโสธร ทุ่งนางโอก ยโสธร 35000",
    name_en: "Mueang Yasothon, Thung Nang Ok, Yasothon, 35000",
  },
  {
    id: 350117,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "หนองเรือ",
    subdistrictEn: "Nong Ruea",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร หนองเรือ ยโสธร 35000",
    name_th: "เมืองยโสธร หนองเรือ ยโสธร 35000",
    name_en: "Mueang Yasothon, Nong Ruea, Yasothon, 35000",
  },
  {
    id: 350118,
    districtTh: "เมืองยโสธร",
    districtEn: "Mueang Yasothon",
    subdistrictTh: "หนองเป็ด",
    subdistrictEn: "Nong Pet",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35000,
    district_code: 3501,
    province_code: 24,
    name: "เมืองยโสธร หนองเป็ด ยโสธร 35000",
    name_th: "เมืองยโสธร หนองเป็ด ยโสธร 35000",
    name_en: "Mueang Yasothon, Nong Pet, Yasothon, 35000",
  },
  {
    id: 350201,
    districtTh: "ทรายมูล",
    districtEn: "Sai Mun",
    subdistrictTh: "ทรายมูล",
    subdistrictEn: "Sai Mun",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35170,
    district_code: 3502,
    province_code: 24,
    name: "ทรายมูล ทรายมูล ยโสธร 35170",
    name_th: "ทรายมูล ทรายมูล ยโสธร 35170",
    name_en: "Sai Mun, Sai Mun, Yasothon, 35170",
  },
  {
    id: 350202,
    districtTh: "ทรายมูล",
    districtEn: "Sai Mun",
    subdistrictTh: "ดู่ลาด",
    subdistrictEn: "Du Lat",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35170,
    district_code: 3502,
    province_code: 24,
    name: "ทรายมูล ดู่ลาด ยโสธร 35170",
    name_th: "ทรายมูล ดู่ลาด ยโสธร 35170",
    name_en: "Sai Mun, Du Lat, Yasothon, 35170",
  },
  {
    id: 350203,
    districtTh: "ทรายมูล",
    districtEn: "Sai Mun",
    subdistrictTh: "ดงมะไฟ",
    subdistrictEn: "Dong Mafai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35170,
    district_code: 3502,
    province_code: 24,
    name: "ทรายมูล ดงมะไฟ ยโสธร 35170",
    name_th: "ทรายมูล ดงมะไฟ ยโสธร 35170",
    name_en: "Sai Mun, Dong Mafai, Yasothon, 35170",
  },
  {
    id: 350204,
    districtTh: "ทรายมูล",
    districtEn: "Sai Mun",
    subdistrictTh: "นาเวียง",
    subdistrictEn: "Na Wiang",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35170,
    district_code: 3502,
    province_code: 24,
    name: "ทรายมูล นาเวียง ยโสธร 35170",
    name_th: "ทรายมูล นาเวียง ยโสธร 35170",
    name_en: "Sai Mun, Na Wiang, Yasothon, 35170",
  },
  {
    id: 350205,
    districtTh: "ทรายมูล",
    districtEn: "Sai Mun",
    subdistrictTh: "ไผ่",
    subdistrictEn: "Phai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35170,
    district_code: 3502,
    province_code: 24,
    name: "ทรายมูล ไผ่ ยโสธร 35170",
    name_th: "ทรายมูล ไผ่ ยโสธร 35170",
    name_en: "Sai Mun, Phai, Yasothon, 35170",
  },
  {
    id: 350301,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "กุดชุม",
    subdistrictEn: "Kut Chum",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม กุดชุม ยโสธร 35140",
    name_th: "กุดชุม กุดชุม ยโสธร 35140",
    name_en: "Kut Chum, Kut Chum, Yasothon, 35140",
  },
  {
    id: 350302,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "โนนเปือย",
    subdistrictEn: "Non Pueai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม โนนเปือย ยโสธร 35140",
    name_th: "กุดชุม โนนเปือย ยโสธร 35140",
    name_en: "Kut Chum, Non Pueai, Yasothon, 35140",
  },
  {
    id: 350303,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "กำแมด",
    subdistrictEn: "Kammaet",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม กำแมด ยโสธร 35140",
    name_th: "กุดชุม กำแมด ยโสธร 35140",
    name_en: "Kut Chum, Kammaet, Yasothon, 35140",
  },
  {
    id: 350304,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "นาโส่",
    subdistrictEn: "Na So",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม นาโส่ ยโสธร 35140",
    name_th: "กุดชุม นาโส่ ยโสธร 35140",
    name_en: "Kut Chum, Na So, Yasothon, 35140",
  },
  {
    id: 350305,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "ห้วยแก้ง",
    subdistrictEn: "Huai Kaeng",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม ห้วยแก้ง ยโสธร 35140",
    name_th: "กุดชุม ห้วยแก้ง ยโสธร 35140",
    name_en: "Kut Chum, Huai Kaeng, Yasothon, 35140",
  },
  {
    id: 350306,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "หนองหมี",
    subdistrictEn: "Nong Mi",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม หนองหมี ยโสธร 35140",
    name_th: "กุดชุม หนองหมี ยโสธร 35140",
    name_en: "Kut Chum, Nong Mi, Yasothon, 35140",
  },
  {
    id: 350307,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "โพนงาม",
    subdistrictEn: "Phon Ngam",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม โพนงาม ยโสธร 35140",
    name_th: "กุดชุม โพนงาม ยโสธร 35140",
    name_en: "Kut Chum, Phon Ngam, Yasothon, 35140",
  },
  {
    id: 350308,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "คำน้ำสร้าง",
    subdistrictEn: "Kham Nam Sang",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม คำน้ำสร้าง ยโสธร 35140",
    name_th: "กุดชุม คำน้ำสร้าง ยโสธร 35140",
    name_en: "Kut Chum, Kham Nam Sang, Yasothon, 35140",
  },
  {
    id: 350309,
    districtTh: "กุดชุม",
    districtEn: "Kut Chum",
    subdistrictTh: "หนองแหน",
    subdistrictEn: "Nong Nae",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35140,
    district_code: 3503,
    province_code: 24,
    name: "กุดชุม หนองแหน ยโสธร 35140",
    name_th: "กุดชุม หนองแหน ยโสธร 35140",
    name_en: "Kut Chum, Nong Nae, Yasothon, 35140",
  },
  {
    id: 350401,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "ลุมพุก",
    subdistrictEn: "Lumphuk",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว ลุมพุก ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว ลุมพุก ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Lumphuk, Yasothon, 35110",
  },
  {
    id: 350402,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "ย่อ",
    subdistrictEn: "Yo",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว ย่อ ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว ย่อ ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Yo, Yasothon, 35110",
  },
  {
    id: 350403,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "สงเปือย",
    subdistrictEn: "Song Pueai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว สงเปือย ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว สงเปือย ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Song Pueai, Yasothon, 35110",
  },
  {
    id: 350404,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "โพนทัน",
    subdistrictEn: "Phon Than",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว โพนทัน ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว โพนทัน ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Phon Than, Yasothon, 35110",
  },
  {
    id: 350405,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "ทุ่งมน",
    subdistrictEn: "Thung Mon",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว ทุ่งมน ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว ทุ่งมน ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Thung Mon, Yasothon, 35110",
  },
  {
    id: 350406,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "นาคำ",
    subdistrictEn: "Na Kham",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35180,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว นาคำ ยโสธร 35180",
    name_th: "คำเขื่อนแก้ว นาคำ ยโสธร 35180",
    name_en: "Kham Khuean Kaeo, Na Kham, Yasothon, 35180",
  },
  {
    id: 350407,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "ดงแคนใหญ่",
    subdistrictEn: "Dong Khaen Yai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35180,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว ดงแคนใหญ่ ยโสธร 35180",
    name_th: "คำเขื่อนแก้ว ดงแคนใหญ่ ยโสธร 35180",
    name_en: "Kham Khuean Kaeo, Dong Khaen Yai, Yasothon, 35180",
  },
  {
    id: 350408,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "กู่จาน",
    subdistrictEn: "Ku Chan",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว กู่จาน ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว กู่จาน ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Ku Chan, Yasothon, 35110",
  },
  {
    id: 350409,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "นาแก",
    subdistrictEn: "Na Kae",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35180,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว นาแก ยโสธร 35180",
    name_th: "คำเขื่อนแก้ว นาแก ยโสธร 35180",
    name_en: "Kham Khuean Kaeo, Na Kae, Yasothon, 35180",
  },
  {
    id: 350410,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "กุดกุง",
    subdistrictEn: "Kut Kung",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว กุดกุง ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว กุดกุง ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Kut Kung, Yasothon, 35110",
  },
  {
    id: 350411,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "เหล่าไฮ",
    subdistrictEn: "Lao Hai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว เหล่าไฮ ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว เหล่าไฮ ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Lao Hai, Yasothon, 35110",
  },
  {
    id: 350412,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "แคนน้อย",
    subdistrictEn: "Khaen Noi",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35180,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว แคนน้อย ยโสธร 35180",
    name_th: "คำเขื่อนแก้ว แคนน้อย ยโสธร 35180",
    name_en: "Kham Khuean Kaeo, Khaen Noi, Yasothon, 35180",
  },
  {
    id: 350413,
    districtTh: "คำเขื่อนแก้ว",
    districtEn: "Kham Khuean Kaeo",
    subdistrictTh: "ดงเจริญ",
    subdistrictEn: "Dong Charoen",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35110,
    district_code: 3504,
    province_code: 24,
    name: "คำเขื่อนแก้ว ดงเจริญ ยโสธร 35110",
    name_th: "คำเขื่อนแก้ว ดงเจริญ ยโสธร 35110",
    name_en: "Kham Khuean Kaeo, Dong Charoen, Yasothon, 35110",
  },
  {
    id: 350501,
    districtTh: "ป่าติ้ว",
    districtEn: "Pa Tio",
    subdistrictTh: "โพธิ์ไทร",
    subdistrictEn: "Pho Sai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35150,
    district_code: 3505,
    province_code: 24,
    name: "ป่าติ้ว โพธิ์ไทร ยโสธร 35150",
    name_th: "ป่าติ้ว โพธิ์ไทร ยโสธร 35150",
    name_en: "Pa Tio, Pho Sai, Yasothon, 35150",
  },
  {
    id: 350502,
    districtTh: "ป่าติ้ว",
    districtEn: "Pa Tio",
    subdistrictTh: "กระจาย",
    subdistrictEn: "Krachai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35150,
    district_code: 3505,
    province_code: 24,
    name: "ป่าติ้ว กระจาย ยโสธร 35150",
    name_th: "ป่าติ้ว กระจาย ยโสธร 35150",
    name_en: "Pa Tio, Krachai, Yasothon, 35150",
  },
  {
    id: 350503,
    districtTh: "ป่าติ้ว",
    districtEn: "Pa Tio",
    subdistrictTh: "โคกนาโก",
    subdistrictEn: "Khok Na Ko",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35150,
    district_code: 3505,
    province_code: 24,
    name: "ป่าติ้ว โคกนาโก ยโสธร 35150",
    name_th: "ป่าติ้ว โคกนาโก ยโสธร 35150",
    name_en: "Pa Tio, Khok Na Ko, Yasothon, 35150",
  },
  {
    id: 350504,
    districtTh: "ป่าติ้ว",
    districtEn: "Pa Tio",
    subdistrictTh: "เชียงเพ็ง",
    subdistrictEn: "Chiang Pheng",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35150,
    district_code: 3505,
    province_code: 24,
    name: "ป่าติ้ว เชียงเพ็ง ยโสธร 35150",
    name_th: "ป่าติ้ว เชียงเพ็ง ยโสธร 35150",
    name_en: "Pa Tio, Chiang Pheng, Yasothon, 35150",
  },
  {
    id: 350505,
    districtTh: "ป่าติ้ว",
    districtEn: "Pa Tio",
    subdistrictTh: "ศรีฐาน",
    subdistrictEn: "Si Than",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35150,
    district_code: 3505,
    province_code: 24,
    name: "ป่าติ้ว ศรีฐาน ยโสธร 35150",
    name_th: "ป่าติ้ว ศรีฐาน ยโสธร 35150",
    name_en: "Pa Tio, Si Than, Yasothon, 35150",
  },
  {
    id: 350601,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "ฟ้าหยาด",
    subdistrictEn: "Fa Yat",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย ฟ้าหยาด ยโสธร 35130",
    name_th: "มหาชนะชัย ฟ้าหยาด ยโสธร 35130",
    name_en: "Maha Chana Chai, Fa Yat, Yasothon, 35130",
  },
  {
    id: 350602,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "หัวเมือง",
    subdistrictEn: "Hua Mueang",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย หัวเมือง ยโสธร 35130",
    name_th: "มหาชนะชัย หัวเมือง ยโสธร 35130",
    name_en: "Maha Chana Chai, Hua Mueang, Yasothon, 35130",
  },
  {
    id: 350603,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "คูเมือง",
    subdistrictEn: "Khu Mueang",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย คูเมือง ยโสธร 35130",
    name_th: "มหาชนะชัย คูเมือง ยโสธร 35130",
    name_en: "Maha Chana Chai, Khu Mueang, Yasothon, 35130",
  },
  {
    id: 350604,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "ผือฮี",
    subdistrictEn: "Phue Hi",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย ผือฮี ยโสธร 35130",
    name_th: "มหาชนะชัย ผือฮี ยโสธร 35130",
    name_en: "Maha Chana Chai, Phue Hi, Yasothon, 35130",
  },
  {
    id: 350605,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "บากเรือ",
    subdistrictEn: "Bak Ruea",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย บากเรือ ยโสธร 35130",
    name_th: "มหาชนะชัย บากเรือ ยโสธร 35130",
    name_en: "Maha Chana Chai, Bak Ruea, Yasothon, 35130",
  },
  {
    id: 350606,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "ม่วง",
    subdistrictEn: "Muang",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย ม่วง ยโสธร 35130",
    name_th: "มหาชนะชัย ม่วง ยโสธร 35130",
    name_en: "Maha Chana Chai, Muang, Yasothon, 35130",
  },
  {
    id: 350607,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "โนนทราย",
    subdistrictEn: "Non Sai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย โนนทราย ยโสธร 35130",
    name_th: "มหาชนะชัย โนนทราย ยโสธร 35130",
    name_en: "Maha Chana Chai, Non Sai, Yasothon, 35130",
  },
  {
    id: 350608,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "บึงแก",
    subdistrictEn: "Bueng Kae",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย บึงแก ยโสธร 35130",
    name_th: "มหาชนะชัย บึงแก ยโสธร 35130",
    name_en: "Maha Chana Chai, Bueng Kae, Yasothon, 35130",
  },
  {
    id: 350609,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "พระเสาร์",
    subdistrictEn: "Phra Sao",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย พระเสาร์ ยโสธร 35130",
    name_th: "มหาชนะชัย พระเสาร์ ยโสธร 35130",
    name_en: "Maha Chana Chai, Phra Sao, Yasothon, 35130",
  },
  {
    id: 350610,
    districtTh: "มหาชนะชัย",
    districtEn: "Maha Chana Chai",
    subdistrictTh: "สงยาง",
    subdistrictEn: "Song Yang",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35130,
    district_code: 3506,
    province_code: 24,
    name: "มหาชนะชัย สงยาง ยโสธร 35130",
    name_th: "มหาชนะชัย สงยาง ยโสธร 35130",
    name_en: "Maha Chana Chai, Song Yang, Yasothon, 35130",
  },
  {
    id: 350701,
    districtTh: "ค้อวัง",
    districtEn: "Kho Wang",
    subdistrictTh: "ฟ้าห่วน",
    subdistrictEn: "Fa Huan",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35160,
    district_code: 3507,
    province_code: 24,
    name: "ค้อวัง ฟ้าห่วน ยโสธร 35160",
    name_th: "ค้อวัง ฟ้าห่วน ยโสธร 35160",
    name_en: "Kho Wang, Fa Huan, Yasothon, 35160",
  },
  {
    id: 350702,
    districtTh: "ค้อวัง",
    districtEn: "Kho Wang",
    subdistrictTh: "กุดน้ำใส",
    subdistrictEn: "Kut Nam Sai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35160,
    district_code: 3507,
    province_code: 24,
    name: "ค้อวัง กุดน้ำใส ยโสธร 35160",
    name_th: "ค้อวัง กุดน้ำใส ยโสธร 35160",
    name_en: "Kho Wang, Kut Nam Sai, Yasothon, 35160",
  },
  {
    id: 350703,
    districtTh: "ค้อวัง",
    districtEn: "Kho Wang",
    subdistrictTh: "น้ำอ้อม",
    subdistrictEn: "Nam Om",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35160,
    district_code: 3507,
    province_code: 24,
    name: "ค้อวัง น้ำอ้อม ยโสธร 35160",
    name_th: "ค้อวัง น้ำอ้อม ยโสธร 35160",
    name_en: "Kho Wang, Nam Om, Yasothon, 35160",
  },
  {
    id: 350704,
    districtTh: "ค้อวัง",
    districtEn: "Kho Wang",
    subdistrictTh: "ค้อวัง",
    subdistrictEn: "Kho Wang",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35160,
    district_code: 3507,
    province_code: 24,
    name: "ค้อวัง ค้อวัง ยโสธร 35160",
    name_th: "ค้อวัง ค้อวัง ยโสธร 35160",
    name_en: "Kho Wang, Kho Wang, Yasothon, 35160",
  },
  {
    id: 350802,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "บุ่งค้า",
    subdistrictEn: "Bung Kha",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา บุ่งค้า ยโสธร 35120",
    name_th: "เลิงนกทา บุ่งค้า ยโสธร 35120",
    name_en: "Loeng Nok Tha, Bung Kha, Yasothon, 35120",
  },
  {
    id: 350803,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "สวาท",
    subdistrictEn: "Sawat",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา สวาท ยโสธร 35120",
    name_th: "เลิงนกทา สวาท ยโสธร 35120",
    name_en: "Loeng Nok Tha, Sawat, Yasothon, 35120",
  },
  {
    id: 350805,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "ห้องแซง",
    subdistrictEn: "Hong Saeng",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา ห้องแซง ยโสธร 35120",
    name_th: "เลิงนกทา ห้องแซง ยโสธร 35120",
    name_en: "Loeng Nok Tha, Hong Saeng, Yasothon, 35120",
  },
  {
    id: 350806,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "สามัคคี",
    subdistrictEn: "Samakkhi",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา สามัคคี ยโสธร 35120",
    name_th: "เลิงนกทา สามัคคี ยโสธร 35120",
    name_en: "Loeng Nok Tha, Samakkhi, Yasothon, 35120",
  },
  {
    id: 350807,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "กุดเชียงหมี",
    subdistrictEn: "Kut Chiang Mi",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา กุดเชียงหมี ยโสธร 35120",
    name_th: "เลิงนกทา กุดเชียงหมี ยโสธร 35120",
    name_en: "Loeng Nok Tha, Kut Chiang Mi, Yasothon, 35120",
  },
  {
    id: 350810,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "สามแยก",
    subdistrictEn: "Sam Yaek",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา สามแยก ยโสธร 35120",
    name_th: "เลิงนกทา สามแยก ยโสธร 35120",
    name_en: "Loeng Nok Tha, Sam Yaek, Yasothon, 35120",
  },
  {
    id: 350811,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "กุดแห่",
    subdistrictEn: "Kut Hae",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา กุดแห่ ยโสธร 35120",
    name_th: "เลิงนกทา กุดแห่ ยโสธร 35120",
    name_en: "Loeng Nok Tha, Kut Hae, Yasothon, 35120",
  },
  {
    id: 350812,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "โคกสำราญ",
    subdistrictEn: "Khok Samran",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา โคกสำราญ ยโสธร 35120",
    name_th: "เลิงนกทา โคกสำราญ ยโสธร 35120",
    name_en: "Loeng Nok Tha, Khok Samran, Yasothon, 35120",
  },
  {
    id: 350813,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "สร้างมิ่ง",
    subdistrictEn: "Sang Ming",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา สร้างมิ่ง ยโสธร 35120",
    name_th: "เลิงนกทา สร้างมิ่ง ยโสธร 35120",
    name_en: "Loeng Nok Tha, Sang Ming, Yasothon, 35120",
  },
  {
    id: 350814,
    districtTh: "เลิงนกทา",
    districtEn: "Loeng Nok Tha",
    subdistrictTh: "ศรีแก้ว",
    subdistrictEn: "Si Kaeo",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3508,
    province_code: 24,
    name: "เลิงนกทา ศรีแก้ว ยโสธร 35120",
    name_th: "เลิงนกทา ศรีแก้ว ยโสธร 35120",
    name_en: "Loeng Nok Tha, Si Kaeo, Yasothon, 35120",
  },
  {
    id: 350901,
    districtTh: "ไทยเจริญ",
    districtEn: "Thai Charoen",
    subdistrictTh: "ไทยเจริญ",
    subdistrictEn: "Thai Charoen",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3509,
    province_code: 24,
    name: "ไทยเจริญ ไทยเจริญ ยโสธร 35120",
    name_th: "ไทยเจริญ ไทยเจริญ ยโสธร 35120",
    name_en: "Thai Charoen, Thai Charoen, Yasothon, 35120",
  },
  {
    id: 350902,
    districtTh: "ไทยเจริญ",
    districtEn: "Thai Charoen",
    subdistrictTh: "น้ำคำ",
    subdistrictEn: "Nam Kham",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3509,
    province_code: 24,
    name: "ไทยเจริญ น้ำคำ ยโสธร 35120",
    name_th: "ไทยเจริญ น้ำคำ ยโสธร 35120",
    name_en: "Thai Charoen, Nam Kham, Yasothon, 35120",
  },
  {
    id: 350903,
    districtTh: "ไทยเจริญ",
    districtEn: "Thai Charoen",
    subdistrictTh: "ส้มผ่อ",
    subdistrictEn: "Som Pho",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3509,
    province_code: 24,
    name: "ไทยเจริญ ส้มผ่อ ยโสธร 35120",
    name_th: "ไทยเจริญ ส้มผ่อ ยโสธร 35120",
    name_en: "Thai Charoen, Som Pho, Yasothon, 35120",
  },
  {
    id: 350904,
    districtTh: "ไทยเจริญ",
    districtEn: "Thai Charoen",
    subdistrictTh: "คำเตย",
    subdistrictEn: "Kham Toei",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3509,
    province_code: 24,
    name: "ไทยเจริญ คำเตย ยโสธร 35120",
    name_th: "ไทยเจริญ คำเตย ยโสธร 35120",
    name_en: "Thai Charoen, Kham Toei, Yasothon, 35120",
  },
  {
    id: 350905,
    districtTh: "ไทยเจริญ",
    districtEn: "Thai Charoen",
    subdistrictTh: "คำไผ่",
    subdistrictEn: "Kham Phai",
    provinceTh: "ยโสธร",
    provinceEn: "Yasothon",
    zipcode: 35120,
    district_code: 3509,
    province_code: 24,
    name: "ไทยเจริญ คำไผ่ ยโสธร 35120",
    name_th: "ไทยเจริญ คำไผ่ ยโสธร 35120",
    name_en: "Thai Charoen, Kham Phai, Yasothon, 35120",
  },
  {
    id: 360101,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ ในเมือง ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ ในเมือง ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Nai Mueang, Chaiyaphum, 36000",
  },
  {
    id: 360102,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "รอบเมือง",
    subdistrictEn: "Rop Mueang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ รอบเมือง ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ รอบเมือง ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Rop Mueang, Chaiyaphum, 36000",
  },
  {
    id: 360103,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ โพนทอง ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ โพนทอง ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Phon Thong, Chaiyaphum, 36000",
  },
  {
    id: 360104,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "นาฝาย",
    subdistrictEn: "Na Fai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ นาฝาย ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ นาฝาย ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Na Fai, Chaiyaphum, 36000",
  },
  {
    id: 360105,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "บ้านค่าย",
    subdistrictEn: "Ban Khai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36240,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ บ้านค่าย ชัยภูมิ 36240",
    name_th: "เมืองชัยภูมิ บ้านค่าย ชัยภูมิ 36240",
    name_en: "Mueang Chaiyaphum, Ban Khai, Chaiyaphum, 36240",
  },
  {
    id: 360106,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "กุดตุ้ม",
    subdistrictEn: "Kut Tum",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ กุดตุ้ม ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ กุดตุ้ม ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Kut Tum, Chaiyaphum, 36000",
  },
  {
    id: 360107,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "ชีลอง",
    subdistrictEn: "Chi Long",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ ชีลอง ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ ชีลอง ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Chi Long, Chaiyaphum, 36000",
  },
  {
    id: 360108,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "บ้านเล่า",
    subdistrictEn: "Ban Lao",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ บ้านเล่า ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ บ้านเล่า ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Ban Lao, Chaiyaphum, 36000",
  },
  {
    id: 360109,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "นาเสียว",
    subdistrictEn: "Na Siao",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ นาเสียว ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ นาเสียว ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Na Siao, Chaiyaphum, 36000",
  },
  {
    id: 360110,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "หนองนาแซง",
    subdistrictEn: "Nong Na Saeng",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ หนองนาแซง ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ หนองนาแซง ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Nong Na Saeng, Chaiyaphum, 36000",
  },
  {
    id: 360111,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "ลาดใหญ่",
    subdistrictEn: "Lat Yai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ ลาดใหญ่ ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ ลาดใหญ่ ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Lat Yai, Chaiyaphum, 36000",
  },
  {
    id: 360112,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36240,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ หนองไผ่ ชัยภูมิ 36240",
    name_th: "เมืองชัยภูมิ หนองไผ่ ชัยภูมิ 36240",
    name_en: "Mueang Chaiyaphum, Nong Phai, Chaiyaphum, 36240",
  },
  {
    id: 360113,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "ท่าหินโงม",
    subdistrictEn: "Tha Hin Ngom",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ ท่าหินโงม ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ ท่าหินโงม ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Tha Hin Ngom, Chaiyaphum, 36000",
  },
  {
    id: 360114,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "ห้วยต้อน",
    subdistrictEn: "Huai Ton",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ ห้วยต้อน ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ ห้วยต้อน ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Huai Ton, Chaiyaphum, 36000",
  },
  {
    id: 360115,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "ห้วยบง",
    subdistrictEn: "Huai Bong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ ห้วยบง ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ ห้วยบง ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Huai Bong, Chaiyaphum, 36000",
  },
  {
    id: 360116,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "โนนสำราญ",
    subdistrictEn: "Non Samran",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36240,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ โนนสำราญ ชัยภูมิ 36240",
    name_th: "เมืองชัยภูมิ โนนสำราญ ชัยภูมิ 36240",
    name_en: "Mueang Chaiyaphum, Non Samran, Chaiyaphum, 36240",
  },
  {
    id: 360117,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "โคกสูง",
    subdistrictEn: "Khok Sung",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ โคกสูง ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ โคกสูง ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Khok Sung, Chaiyaphum, 36000",
  },
  {
    id: 360118,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "บุ่งคล้า",
    subdistrictEn: "Bung Khla",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ บุ่งคล้า ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ บุ่งคล้า ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Bung Khla, Chaiyaphum, 36000",
  },
  {
    id: 360119,
    districtTh: "เมืองชัยภูมิ",
    districtEn: "Mueang Chaiyaphum",
    subdistrictTh: "ซับสีทอง",
    subdistrictEn: "Sap Si Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36000,
    district_code: 3601,
    province_code: 25,
    name: "เมืองชัยภูมิ ซับสีทอง ชัยภูมิ 36000",
    name_th: "เมืองชัยภูมิ ซับสีทอง ชัยภูมิ 36000",
    name_en: "Mueang Chaiyaphum, Sap Si Thong, Chaiyaphum, 36000",
  },
  {
    id: 360201,
    districtTh: "บ้านเขว้า",
    districtEn: "Ban Khwao",
    subdistrictTh: "บ้านเขว้า",
    subdistrictEn: "Ban Khwao",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36170,
    district_code: 3602,
    province_code: 25,
    name: "บ้านเขว้า บ้านเขว้า ชัยภูมิ 36170",
    name_th: "บ้านเขว้า บ้านเขว้า ชัยภูมิ 36170",
    name_en: "Ban Khwao, Ban Khwao, Chaiyaphum, 36170",
  },
  {
    id: 360202,
    districtTh: "บ้านเขว้า",
    districtEn: "Ban Khwao",
    subdistrictTh: "ตลาดแร้ง",
    subdistrictEn: "Talat Raeng",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36170,
    district_code: 3602,
    province_code: 25,
    name: "บ้านเขว้า ตลาดแร้ง ชัยภูมิ 36170",
    name_th: "บ้านเขว้า ตลาดแร้ง ชัยภูมิ 36170",
    name_en: "Ban Khwao, Talat Raeng, Chaiyaphum, 36170",
  },
  {
    id: 360203,
    districtTh: "บ้านเขว้า",
    districtEn: "Ban Khwao",
    subdistrictTh: "ลุ่มลำชี",
    subdistrictEn: "Lum Lam Chi",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36170,
    district_code: 3602,
    province_code: 25,
    name: "บ้านเขว้า ลุ่มลำชี ชัยภูมิ 36170",
    name_th: "บ้านเขว้า ลุ่มลำชี ชัยภูมิ 36170",
    name_en: "Ban Khwao, Lum Lam Chi, Chaiyaphum, 36170",
  },
  {
    id: 360204,
    districtTh: "บ้านเขว้า",
    districtEn: "Ban Khwao",
    subdistrictTh: "ชีบน",
    subdistrictEn: "Chi Bon",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36170,
    district_code: 3602,
    province_code: 25,
    name: "บ้านเขว้า ชีบน ชัยภูมิ 36170",
    name_th: "บ้านเขว้า ชีบน ชัยภูมิ 36170",
    name_en: "Ban Khwao, Chi Bon, Chaiyaphum, 36170",
  },
  {
    id: 360205,
    districtTh: "บ้านเขว้า",
    districtEn: "Ban Khwao",
    subdistrictTh: "ภูแลนคา",
    subdistrictEn: "Phu Laen Kha",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36170,
    district_code: 3602,
    province_code: 25,
    name: "บ้านเขว้า ภูแลนคา ชัยภูมิ 36170",
    name_th: "บ้านเขว้า ภูแลนคา ชัยภูมิ 36170",
    name_en: "Ban Khwao, Phu Laen Kha, Chaiyaphum, 36170",
  },
  {
    id: 360206,
    districtTh: "บ้านเขว้า",
    districtEn: "Ban Khwao",
    subdistrictTh: "โนนแดง",
    subdistrictEn: "Non Dang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36170,
    district_code: 3602,
    province_code: 25,
    name: "บ้านเขว้า โนนแดง ชัยภูมิ 36170",
    name_th: "บ้านเขว้า โนนแดง ชัยภูมิ 36170",
    name_en: "Ban Khwao, Non Dang, Chaiyaphum, 36170",
  },
  {
    id: 360301,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "คอนสวรรค์",
    subdistrictEn: "Khon Sawan",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ คอนสวรรค์ ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ คอนสวรรค์ ชัยภูมิ 36140",
    name_en: "Khon Sawan, Khon Sawan, Chaiyaphum, 36140",
  },
  {
    id: 360302,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "ยางหวาย",
    subdistrictEn: "Yang Wai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ ยางหวาย ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ ยางหวาย ชัยภูมิ 36140",
    name_en: "Khon Sawan, Yang Wai, Chaiyaphum, 36140",
  },
  {
    id: 360303,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "ช่องสามหมอ",
    subdistrictEn: "Chong Sam Mo)",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ ช่องสามหมอ ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ ช่องสามหมอ ชัยภูมิ 36140",
    name_en: "Khon Sawan, Chong Sam Mo), Chaiyaphum, 36140",
  },
  {
    id: 360304,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "โนนสะอาด",
    subdistrictEn: "Non Sa-at",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ โนนสะอาด ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ โนนสะอาด ชัยภูมิ 36140",
    name_en: "Khon Sawan, Non Sa-at, Chaiyaphum, 36140",
  },
  {
    id: 360305,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "ห้วยไร่",
    subdistrictEn: "Huai Rai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ ห้วยไร่ ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ ห้วยไร่ ชัยภูมิ 36140",
    name_en: "Khon Sawan, Huai Rai, Chaiyaphum, 36140",
  },
  {
    id: 360306,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "บ้านโสก",
    subdistrictEn: "Ban Sok",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ บ้านโสก ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ บ้านโสก ชัยภูมิ 36140",
    name_en: "Khon Sawan, Ban Sok, Chaiyaphum, 36140",
  },
  {
    id: 360307,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "โคกมั่งงอย",
    subdistrictEn: "Khok Mang Ngoi",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ โคกมั่งงอย ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ โคกมั่งงอย ชัยภูมิ 36140",
    name_en: "Khon Sawan, Khok Mang Ngoi, Chaiyaphum, 36140",
  },
  {
    id: 360308,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "หนองขาม",
    subdistrictEn: "Nong Kham",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ หนองขาม ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ หนองขาม ชัยภูมิ 36140",
    name_en: "Khon Sawan, Nong Kham, Chaiyaphum, 36140",
  },
  {
    id: 360309,
    districtTh: "คอนสวรรค์",
    districtEn: "Khon Sawan",
    subdistrictTh: "ศรีสำราญ",
    subdistrictEn: "Si Samran",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36140,
    district_code: 3603,
    province_code: 25,
    name: "คอนสวรรค์ ศรีสำราญ ชัยภูมิ 36140",
    name_th: "คอนสวรรค์ ศรีสำราญ ชัยภูมิ 36140",
    name_en: "Khon Sawan, Si Samran, Chaiyaphum, 36140",
  },
  {
    id: 360401,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ บ้านยาง ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ บ้านยาง ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Ban Yang, Chaiyaphum, 36120",
  },
  {
    id: 360402,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "บ้านหัน",
    subdistrictEn: "Ban Han",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ บ้านหัน ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ บ้านหัน ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Ban Han, Chaiyaphum, 36120",
  },
  {
    id: 360403,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "บ้านเดื่อ",
    subdistrictEn: "Ban Duea",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ บ้านเดื่อ ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ บ้านเดื่อ ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Ban Duea, Chaiyaphum, 36120",
  },
  {
    id: 360404,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "บ้านเป้า",
    subdistrictEn: "Ban Pao",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ บ้านเป้า ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ บ้านเป้า ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Ban Pao, Chaiyaphum, 36120",
  },
  {
    id: 360405,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "กุดเลาะ",
    subdistrictEn: "Kut Lo",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ กุดเลาะ ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ กุดเลาะ ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Kut Lo, Chaiyaphum, 36120",
  },
  {
    id: 360406,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "โนนกอก",
    subdistrictEn: "Non Kok",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ โนนกอก ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ โนนกอก ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Non Kok, Chaiyaphum, 36120",
  },
  {
    id: 360407,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "สระโพนทอง",
    subdistrictEn: "Sa Phon Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ สระโพนทอง ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ สระโพนทอง ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Sa Phon Thong, Chaiyaphum, 36120",
  },
  {
    id: 360408,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "หนองข่า",
    subdistrictEn: "Nong Kha",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ หนองข่า ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ หนองข่า ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Nong Kha, Chaiyaphum, 36120",
  },
  {
    id: 360409,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "หนองโพนงาม",
    subdistrictEn: "Nong Phon Ngam",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ หนองโพนงาม ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ หนองโพนงาม ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Nong Phon Ngam, Chaiyaphum, 36120",
  },
  {
    id: 360410,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "บ้านบัว",
    subdistrictEn: "Ban Bua",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ บ้านบัว ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ บ้านบัว ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Ban Bua, Chaiyaphum, 36120",
  },
  {
    id: 360412,
    districtTh: "เกษตรสมบูรณ์",
    districtEn: "Kaset Sombun",
    subdistrictTh: "โนนทอง",
    subdistrictEn: "Non Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36120,
    district_code: 3604,
    province_code: 25,
    name: "เกษตรสมบูรณ์ โนนทอง ชัยภูมิ 36120",
    name_th: "เกษตรสมบูรณ์ โนนทอง ชัยภูมิ 36120",
    name_en: "Kaset Sombun, Non Thong, Chaiyaphum, 36120",
  },
  {
    id: 360501,
    districtTh: "หนองบัวแดง",
    districtEn: "Nong Bua Daeng",
    subdistrictTh: "หนองบัวแดง",
    subdistrictEn: "Nong Bua Daeng",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36210,
    district_code: 3605,
    province_code: 25,
    name: "หนองบัวแดง หนองบัวแดง ชัยภูมิ 36210",
    name_th: "หนองบัวแดง หนองบัวแดง ชัยภูมิ 36210",
    name_en: "Nong Bua Daeng, Nong Bua Daeng, Chaiyaphum, 36210",
  },
  {
    id: 360502,
    districtTh: "หนองบัวแดง",
    districtEn: "Nong Bua Daeng",
    subdistrictTh: "กุดชุมแสง",
    subdistrictEn: "Kut Chum Saeng",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36210,
    district_code: 3605,
    province_code: 25,
    name: "หนองบัวแดง กุดชุมแสง ชัยภูมิ 36210",
    name_th: "หนองบัวแดง กุดชุมแสง ชัยภูมิ 36210",
    name_en: "Nong Bua Daeng, Kut Chum Saeng, Chaiyaphum, 36210",
  },
  {
    id: 360503,
    districtTh: "หนองบัวแดง",
    districtEn: "Nong Bua Daeng",
    subdistrictTh: "ถ้ำวัวแดง",
    subdistrictEn: "Tham Wua Daeng",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36210,
    district_code: 3605,
    province_code: 25,
    name: "หนองบัวแดง ถ้ำวัวแดง ชัยภูมิ 36210",
    name_th: "หนองบัวแดง ถ้ำวัวแดง ชัยภูมิ 36210",
    name_en: "Nong Bua Daeng, Tham Wua Daeng, Chaiyaphum, 36210",
  },
  {
    id: 360504,
    districtTh: "หนองบัวแดง",
    districtEn: "Nong Bua Daeng",
    subdistrictTh: "นางแดด",
    subdistrictEn: "Nang Daet",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36210,
    district_code: 3605,
    province_code: 25,
    name: "หนองบัวแดง นางแดด ชัยภูมิ 36210",
    name_th: "หนองบัวแดง นางแดด ชัยภูมิ 36210",
    name_en: "Nong Bua Daeng, Nang Daet, Chaiyaphum, 36210",
  },
  {
    id: 360507,
    districtTh: "หนองบัวแดง",
    districtEn: "Nong Bua Daeng",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36210,
    district_code: 3605,
    province_code: 25,
    name: "หนองบัวแดง หนองแวง ชัยภูมิ 36210",
    name_th: "หนองบัวแดง หนองแวง ชัยภูมิ 36210",
    name_en: "Nong Bua Daeng, Nong Waeng, Chaiyaphum, 36210",
  },
  {
    id: 360508,
    districtTh: "หนองบัวแดง",
    districtEn: "Nong Bua Daeng",
    subdistrictTh: "คูเมือง",
    subdistrictEn: "Khu Mueang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36210,
    district_code: 3605,
    province_code: 25,
    name: "หนองบัวแดง คูเมือง ชัยภูมิ 36210",
    name_th: "หนองบัวแดง คูเมือง ชัยภูมิ 36210",
    name_en: "Nong Bua Daeng, Khu Mueang, Chaiyaphum, 36210",
  },
  {
    id: 360509,
    districtTh: "หนองบัวแดง",
    districtEn: "Nong Bua Daeng",
    subdistrictTh: "ท่าใหญ่",
    subdistrictEn: "Tha Yai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36210,
    district_code: 3605,
    province_code: 25,
    name: "หนองบัวแดง ท่าใหญ่ ชัยภูมิ 36210",
    name_th: "หนองบัวแดง ท่าใหญ่ ชัยภูมิ 36210",
    name_en: "Nong Bua Daeng, Tha Yai, Chaiyaphum, 36210",
  },
  {
    id: 360511,
    districtTh: "หนองบัวแดง",
    districtEn: "Nong Bua Daeng",
    subdistrictTh: "วังชมภู",
    subdistrictEn: "Wang Chomphu",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36210,
    district_code: 3605,
    province_code: 25,
    name: "หนองบัวแดง วังชมภู ชัยภูมิ 36210",
    name_th: "หนองบัวแดง วังชมภู ชัยภูมิ 36210",
    name_en: "Nong Bua Daeng, Wang Chomphu, Chaiyaphum, 36210",
  },
  {
    id: 360601,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "บ้านกอก",
    subdistrictEn: "Ban Kok",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส บ้านกอก ชัยภูมิ 36130",
    name_th: "จัตุรัส บ้านกอก ชัยภูมิ 36130",
    name_en: "Chatturat, Ban Kok, Chaiyaphum, 36130",
  },
  {
    id: 360602,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "หนองบัวบาน",
    subdistrictEn: "Nong Bua Ban",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส หนองบัวบาน ชัยภูมิ 36130",
    name_th: "จัตุรัส หนองบัวบาน ชัยภูมิ 36130",
    name_en: "Chatturat, Nong Bua Ban, Chaiyaphum, 36130",
  },
  {
    id: 360603,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "บ้านขาม",
    subdistrictEn: "Ban Kham",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส บ้านขาม ชัยภูมิ 36130",
    name_th: "จัตุรัส บ้านขาม ชัยภูมิ 36130",
    name_en: "Chatturat, Ban Kham, Chaiyaphum, 36130",
  },
  {
    id: 360605,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "กุดน้ำใส",
    subdistrictEn: "Kut Nam Sai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส กุดน้ำใส ชัยภูมิ 36130",
    name_th: "จัตุรัส กุดน้ำใส ชัยภูมิ 36130",
    name_en: "Chatturat, Kut Nam Sai, Chaiyaphum, 36130",
  },
  {
    id: 360606,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "หนองโดน",
    subdistrictEn: "Nong Don",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส หนองโดน ชัยภูมิ 36130",
    name_th: "จัตุรัส หนองโดน ชัยภูมิ 36130",
    name_en: "Chatturat, Nong Don, Chaiyaphum, 36130",
  },
  {
    id: 360607,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "ละหาน",
    subdistrictEn: "Lahan",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส ละหาน ชัยภูมิ 36130",
    name_th: "จัตุรัส ละหาน ชัยภูมิ 36130",
    name_en: "Chatturat, Lahan, Chaiyaphum, 36130",
  },
  {
    id: 360610,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "หนองบัวใหญ่",
    subdistrictEn: "Nong Bua Yai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส หนองบัวใหญ่ ชัยภูมิ 36130",
    name_th: "จัตุรัส หนองบัวใหญ่ ชัยภูมิ 36130",
    name_en: "Chatturat, Nong Bua Yai, Chaiyaphum, 36130",
  },
  {
    id: 360611,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "หนองบัวโคก",
    subdistrictEn: "Nong Bua Khok",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36220,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส หนองบัวโคก ชัยภูมิ 36220",
    name_th: "จัตุรัส หนองบัวโคก ชัยภูมิ 36220",
    name_en: "Chatturat, Nong Bua Khok, Chaiyaphum, 36220",
  },
  {
    id: 360613,
    districtTh: "จัตุรัส",
    districtEn: "Chatturat",
    subdistrictTh: "ส้มป่อย",
    subdistrictEn: "Sompoi",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3606,
    province_code: 25,
    name: "จัตุรัส ส้มป่อย ชัยภูมิ 36130",
    name_th: "จัตุรัส ส้มป่อย ชัยภูมิ 36130",
    name_en: "Chatturat, Sompoi, Chaiyaphum, 36130",
  },
  {
    id: 360701,
    districtTh: "บำเหน็จณรงค์",
    districtEn: "Bamnet Narong",
    subdistrictTh: "บ้านชวน",
    subdistrictEn: "Ban Chuan",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36160,
    district_code: 3607,
    province_code: 25,
    name: "บำเหน็จณรงค์ บ้านชวน ชัยภูมิ 36160",
    name_th: "บำเหน็จณรงค์ บ้านชวน ชัยภูมิ 36160",
    name_en: "Bamnet Narong, Ban Chuan, Chaiyaphum, 36160",
  },
  {
    id: 360702,
    districtTh: "บำเหน็จณรงค์",
    districtEn: "Bamnet Narong",
    subdistrictTh: "บ้านเพชร",
    subdistrictEn: "Ban Phet",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36160,
    district_code: 3607,
    province_code: 25,
    name: "บำเหน็จณรงค์ บ้านเพชร ชัยภูมิ 36160",
    name_th: "บำเหน็จณรงค์ บ้านเพชร ชัยภูมิ 36160",
    name_en: "Bamnet Narong, Ban Phet, Chaiyaphum, 36160",
  },
  {
    id: 360703,
    districtTh: "บำเหน็จณรงค์",
    districtEn: "Bamnet Narong",
    subdistrictTh: "บ้านตาล",
    subdistrictEn: "Ban Tan",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36220,
    district_code: 3607,
    province_code: 25,
    name: "บำเหน็จณรงค์ บ้านตาล ชัยภูมิ 36220",
    name_th: "บำเหน็จณรงค์ บ้านตาล ชัยภูมิ 36220",
    name_en: "Bamnet Narong, Ban Tan, Chaiyaphum, 36220",
  },
  {
    id: 360704,
    districtTh: "บำเหน็จณรงค์",
    districtEn: "Bamnet Narong",
    subdistrictTh: "หัวทะเล",
    subdistrictEn: "Hua Thale",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36220,
    district_code: 3607,
    province_code: 25,
    name: "บำเหน็จณรงค์ หัวทะเล ชัยภูมิ 36220",
    name_th: "บำเหน็จณรงค์ หัวทะเล ชัยภูมิ 36220",
    name_en: "Bamnet Narong, Hua Thale, Chaiyaphum, 36220",
  },
  {
    id: 360705,
    districtTh: "บำเหน็จณรงค์",
    districtEn: "Bamnet Narong",
    subdistrictTh: "โคกเริงรมย์",
    subdistrictEn: "Khok Roeng Rom",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36160,
    district_code: 3607,
    province_code: 25,
    name: "บำเหน็จณรงค์ โคกเริงรมย์ ชัยภูมิ 36160",
    name_th: "บำเหน็จณรงค์ โคกเริงรมย์ ชัยภูมิ 36160",
    name_en: "Bamnet Narong, Khok Roeng Rom, Chaiyaphum, 36160",
  },
  {
    id: 360706,
    districtTh: "บำเหน็จณรงค์",
    districtEn: "Bamnet Narong",
    subdistrictTh: "เกาะมะนาว",
    subdistrictEn: "Ko Manao",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36160,
    district_code: 3607,
    province_code: 25,
    name: "บำเหน็จณรงค์ เกาะมะนาว ชัยภูมิ 36160",
    name_th: "บำเหน็จณรงค์ เกาะมะนาว ชัยภูมิ 36160",
    name_en: "Bamnet Narong, Ko Manao, Chaiyaphum, 36160",
  },
  {
    id: 360707,
    districtTh: "บำเหน็จณรงค์",
    districtEn: "Bamnet Narong",
    subdistrictTh: "โคกเพชรพัฒนา",
    subdistrictEn: "Khok Phet Phatthana",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36160,
    district_code: 3607,
    province_code: 25,
    name: "บำเหน็จณรงค์ โคกเพชรพัฒนา ชัยภูมิ 36160",
    name_th: "บำเหน็จณรงค์ โคกเพชรพัฒนา ชัยภูมิ 36160",
    name_en: "Bamnet Narong, Khok Phet Phatthana, Chaiyaphum, 36160",
  },
  {
    id: 360801,
    districtTh: "หนองบัวระเหว",
    districtEn: "Nong Bua Rawe",
    subdistrictTh: "หนองบัวระเหว",
    subdistrictEn: "Nong Bua Rawe",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36250,
    district_code: 3608,
    province_code: 25,
    name: "หนองบัวระเหว หนองบัวระเหว ชัยภูมิ 36250",
    name_th: "หนองบัวระเหว หนองบัวระเหว ชัยภูมิ 36250",
    name_en: "Nong Bua Rawe, Nong Bua Rawe, Chaiyaphum, 36250",
  },
  {
    id: 360802,
    districtTh: "หนองบัวระเหว",
    districtEn: "Nong Bua Rawe",
    subdistrictTh: "วังตะเฆ่",
    subdistrictEn: "Wang Takhe",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36250,
    district_code: 3608,
    province_code: 25,
    name: "หนองบัวระเหว วังตะเฆ่ ชัยภูมิ 36250",
    name_th: "หนองบัวระเหว วังตะเฆ่ ชัยภูมิ 36250",
    name_en: "Nong Bua Rawe, Wang Takhe, Chaiyaphum, 36250",
  },
  {
    id: 360803,
    districtTh: "หนองบัวระเหว",
    districtEn: "Nong Bua Rawe",
    subdistrictTh: "ห้วยแย้",
    subdistrictEn: "Huai Yae",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36250,
    district_code: 3608,
    province_code: 25,
    name: "หนองบัวระเหว ห้วยแย้ ชัยภูมิ 36250",
    name_th: "หนองบัวระเหว ห้วยแย้ ชัยภูมิ 36250",
    name_en: "Nong Bua Rawe, Huai Yae, Chaiyaphum, 36250",
  },
  {
    id: 360804,
    districtTh: "หนองบัวระเหว",
    districtEn: "Nong Bua Rawe",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36250,
    district_code: 3608,
    province_code: 25,
    name: "หนองบัวระเหว โคกสะอาด ชัยภูมิ 36250",
    name_th: "หนองบัวระเหว โคกสะอาด ชัยภูมิ 36250",
    name_en: "Nong Bua Rawe, Khok Sa-at, Chaiyaphum, 36250",
  },
  {
    id: 360805,
    districtTh: "หนองบัวระเหว",
    districtEn: "Nong Bua Rawe",
    subdistrictTh: "โสกปลาดุก",
    subdistrictEn: "Sok Pla Duk",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36250,
    district_code: 3608,
    province_code: 25,
    name: "หนองบัวระเหว โสกปลาดุก ชัยภูมิ 36250",
    name_th: "หนองบัวระเหว โสกปลาดุก ชัยภูมิ 36250",
    name_en: "Nong Bua Rawe, Sok Pla Duk, Chaiyaphum, 36250",
  },
  {
    id: 360901,
    districtTh: "เทพสถิต",
    districtEn: "Thep Sathit",
    subdistrictTh: "วะตะแบก",
    subdistrictEn: "Wa Tabaek",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36230,
    district_code: 3609,
    province_code: 25,
    name: "เทพสถิต วะตะแบก ชัยภูมิ 36230",
    name_th: "เทพสถิต วะตะแบก ชัยภูมิ 36230",
    name_en: "Thep Sathit, Wa Tabaek, Chaiyaphum, 36230",
  },
  {
    id: 360902,
    districtTh: "เทพสถิต",
    districtEn: "Thep Sathit",
    subdistrictTh: "ห้วยยายจิ๋ว",
    subdistrictEn: "Huai Yai Chio",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36230,
    district_code: 3609,
    province_code: 25,
    name: "เทพสถิต ห้วยยายจิ๋ว ชัยภูมิ 36230",
    name_th: "เทพสถิต ห้วยยายจิ๋ว ชัยภูมิ 36230",
    name_en: "Thep Sathit, Huai Yai Chio, Chaiyaphum, 36230",
  },
  {
    id: 360903,
    districtTh: "เทพสถิต",
    districtEn: "Thep Sathit",
    subdistrictTh: "นายางกลัก",
    subdistrictEn: "Na Yang Klak",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36230,
    district_code: 3609,
    province_code: 25,
    name: "เทพสถิต นายางกลัก ชัยภูมิ 36230",
    name_th: "เทพสถิต นายางกลัก ชัยภูมิ 36230",
    name_en: "Thep Sathit, Na Yang Klak, Chaiyaphum, 36230",
  },
  {
    id: 360904,
    districtTh: "เทพสถิต",
    districtEn: "Thep Sathit",
    subdistrictTh: "บ้านไร่",
    subdistrictEn: "Ban Rai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36230,
    district_code: 3609,
    province_code: 25,
    name: "เทพสถิต บ้านไร่ ชัยภูมิ 36230",
    name_th: "เทพสถิต บ้านไร่ ชัยภูมิ 36230",
    name_en: "Thep Sathit, Ban Rai, Chaiyaphum, 36230",
  },
  {
    id: 360905,
    districtTh: "เทพสถิต",
    districtEn: "Thep Sathit",
    subdistrictTh: "โป่งนก",
    subdistrictEn: "Pong Nok",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36230,
    district_code: 3609,
    province_code: 25,
    name: "เทพสถิต โป่งนก ชัยภูมิ 36230",
    name_th: "เทพสถิต โป่งนก ชัยภูมิ 36230",
    name_en: "Thep Sathit, Pong Nok, Chaiyaphum, 36230",
  },
  {
    id: 361001,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "ผักปัง",
    subdistrictEn: "Phak Pang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว ผักปัง ชัยภูมิ 36110",
    name_th: "ภูเขียว ผักปัง ชัยภูมิ 36110",
    name_en: "Phu Khiao, Phak Pang, Chaiyaphum, 36110",
  },
  {
    id: 361002,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "กวางโจน",
    subdistrictEn: "Kwang Chon",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว กวางโจน ชัยภูมิ 36110",
    name_th: "ภูเขียว กวางโจน ชัยภูมิ 36110",
    name_en: "Phu Khiao, Kwang Chon, Chaiyaphum, 36110",
  },
  {
    id: 361003,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "หนองคอนไทย",
    subdistrictEn: "Nong Khon Thai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว หนองคอนไทย ชัยภูมิ 36110",
    name_th: "ภูเขียว หนองคอนไทย ชัยภูมิ 36110",
    name_en: "Phu Khiao, Nong Khon Thai, Chaiyaphum, 36110",
  },
  {
    id: 361004,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "บ้านแก้ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว บ้านแก้ง ชัยภูมิ 36110",
    name_th: "ภูเขียว บ้านแก้ง ชัยภูมิ 36110",
    name_en: "Phu Khiao, Ban Kaeng, Chaiyaphum, 36110",
  },
  {
    id: 361005,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "กุดยม",
    subdistrictEn: "Kut Yom",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว กุดยม ชัยภูมิ 36110",
    name_th: "ภูเขียว กุดยม ชัยภูมิ 36110",
    name_en: "Phu Khiao, Kut Yom, Chaiyaphum, 36110",
  },
  {
    id: 361006,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "บ้านเพชร",
    subdistrictEn: "Ban Phet",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว บ้านเพชร ชัยภูมิ 36110",
    name_th: "ภูเขียว บ้านเพชร ชัยภูมิ 36110",
    name_en: "Phu Khiao, Ban Phet, Chaiyaphum, 36110",
  },
  {
    id: 361007,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว โคกสะอาด ชัยภูมิ 36110",
    name_th: "ภูเขียว โคกสะอาด ชัยภูมิ 36110",
    name_en: "Phu Khiao, Khok Sa-at, Chaiyaphum, 36110",
  },
  {
    id: 361008,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "หนองตูม",
    subdistrictEn: "Nong Tum",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว หนองตูม ชัยภูมิ 36110",
    name_th: "ภูเขียว หนองตูม ชัยภูมิ 36110",
    name_en: "Phu Khiao, Nong Tum, Chaiyaphum, 36110",
  },
  {
    id: 361009,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "โอโล",
    subdistrictEn: "Olo",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว โอโล ชัยภูมิ 36110",
    name_th: "ภูเขียว โอโล ชัยภูมิ 36110",
    name_en: "Phu Khiao, Olo, Chaiyaphum, 36110",
  },
  {
    id: 361010,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "ธาตุทอง",
    subdistrictEn: "That Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว ธาตุทอง ชัยภูมิ 36110",
    name_th: "ภูเขียว ธาตุทอง ชัยภูมิ 36110",
    name_en: "Phu Khiao, That Thong, Chaiyaphum, 36110",
  },
  {
    id: 361011,
    districtTh: "ภูเขียว",
    districtEn: "Phu Khiao",
    subdistrictTh: "บ้านดอน",
    subdistrictEn: "Ban Don",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36110,
    district_code: 3610,
    province_code: 25,
    name: "ภูเขียว บ้านดอน ชัยภูมิ 36110",
    name_th: "ภูเขียว บ้านดอน ชัยภูมิ 36110",
    name_en: "Phu Khiao, Ban Don, Chaiyaphum, 36110",
  },
  {
    id: 361101,
    districtTh: "บ้านแท่น",
    districtEn: "Ban Thaen",
    subdistrictTh: "บ้านแท่น",
    subdistrictEn: "Ban Thaen",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36190,
    district_code: 3611,
    province_code: 25,
    name: "บ้านแท่น บ้านแท่น ชัยภูมิ 36190",
    name_th: "บ้านแท่น บ้านแท่น ชัยภูมิ 36190",
    name_en: "Ban Thaen, Ban Thaen, Chaiyaphum, 36190",
  },
  {
    id: 361102,
    districtTh: "บ้านแท่น",
    districtEn: "Ban Thaen",
    subdistrictTh: "สามสวน",
    subdistrictEn: "Sam Suan",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36190,
    district_code: 3611,
    province_code: 25,
    name: "บ้านแท่น สามสวน ชัยภูมิ 36190",
    name_th: "บ้านแท่น สามสวน ชัยภูมิ 36190",
    name_en: "Ban Thaen, Sam Suan, Chaiyaphum, 36190",
  },
  {
    id: 361103,
    districtTh: "บ้านแท่น",
    districtEn: "Ban Thaen",
    subdistrictTh: "สระพัง",
    subdistrictEn: "Sa Phang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36190,
    district_code: 3611,
    province_code: 25,
    name: "บ้านแท่น สระพัง ชัยภูมิ 36190",
    name_th: "บ้านแท่น สระพัง ชัยภูมิ 36190",
    name_en: "Ban Thaen, Sa Phang, Chaiyaphum, 36190",
  },
  {
    id: 361104,
    districtTh: "บ้านแท่น",
    districtEn: "Ban Thaen",
    subdistrictTh: "บ้านเต่า",
    subdistrictEn: "Ban Tao",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36190,
    district_code: 3611,
    province_code: 25,
    name: "บ้านแท่น บ้านเต่า ชัยภูมิ 36190",
    name_th: "บ้านแท่น บ้านเต่า ชัยภูมิ 36190",
    name_en: "Ban Thaen, Ban Tao, Chaiyaphum, 36190",
  },
  {
    id: 361105,
    districtTh: "บ้านแท่น",
    districtEn: "Ban Thaen",
    subdistrictTh: "หนองคู",
    subdistrictEn: "Nong Khu",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36190,
    district_code: 3611,
    province_code: 25,
    name: "บ้านแท่น หนองคู ชัยภูมิ 36190",
    name_th: "บ้านแท่น หนองคู ชัยภูมิ 36190",
    name_en: "Ban Thaen, Nong Khu, Chaiyaphum, 36190",
  },
  {
    id: 361201,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "ช่องสามหมอ",
    subdistrictEn: "Chong Sam Mo",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ ช่องสามหมอ ชัยภูมิ 36150",
    name_th: "แก้งคร้อ ช่องสามหมอ ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Chong Sam Mo, Chaiyaphum, 36150",
  },
  {
    id: 361202,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "หนองขาม",
    subdistrictEn: "Nong Kham",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ หนองขาม ชัยภูมิ 36150",
    name_th: "แก้งคร้อ หนองขาม ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Nong Kham, Chaiyaphum, 36150",
  },
  {
    id: 361203,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "นาหนองทุ่ม",
    subdistrictEn: "Na Nong Thum",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ นาหนองทุ่ม ชัยภูมิ 36150",
    name_th: "แก้งคร้อ นาหนองทุ่ม ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Na Nong Thum, Chaiyaphum, 36150",
  },
  {
    id: 361204,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "บ้านแก้ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ บ้านแก้ง ชัยภูมิ 36150",
    name_th: "แก้งคร้อ บ้านแก้ง ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Ban Kaeng, Chaiyaphum, 36150",
  },
  {
    id: 361205,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "หนองสังข์",
    subdistrictEn: "Nong Sang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ หนองสังข์ ชัยภูมิ 36150",
    name_th: "แก้งคร้อ หนองสังข์ ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Nong Sang, Chaiyaphum, 36150",
  },
  {
    id: 361206,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "หลุบคา",
    subdistrictEn: "Lup Kha",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ หลุบคา ชัยภูมิ 36150",
    name_th: "แก้งคร้อ หลุบคา ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Lup Kha, Chaiyaphum, 36150",
  },
  {
    id: 361207,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "โคกกุง",
    subdistrictEn: "Khok Kung",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ โคกกุง ชัยภูมิ 36150",
    name_th: "แก้งคร้อ โคกกุง ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Khok Kung, Chaiyaphum, 36150",
  },
  {
    id: 361208,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "เก่าย่าดี",
    subdistrictEn: "Kao Ya Di",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ เก่าย่าดี ชัยภูมิ 36150",
    name_th: "แก้งคร้อ เก่าย่าดี ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Kao Ya Di, Chaiyaphum, 36150",
  },
  {
    id: 361209,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "ท่ามะไฟหวาน",
    subdistrictEn: "Tha Mafai Wan",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ ท่ามะไฟหวาน ชัยภูมิ 36150",
    name_th: "แก้งคร้อ ท่ามะไฟหวาน ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Tha Mafai Wan, Chaiyaphum, 36150",
  },
  {
    id: 361210,
    districtTh: "แก้งคร้อ",
    districtEn: "Kaeng Khro",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai)",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36150,
    district_code: 3612,
    province_code: 25,
    name: "แก้งคร้อ หนองไผ่ ชัยภูมิ 36150",
    name_th: "แก้งคร้อ หนองไผ่ ชัยภูมิ 36150",
    name_en: "Kaeng Khro, Nong Phai), Chaiyaphum, 36150",
  },
  {
    id: 361301,
    districtTh: "คอนสาร",
    districtEn: "Khon San",
    subdistrictTh: "คอนสาร",
    subdistrictEn: "Khon San",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36180,
    district_code: 3613,
    province_code: 25,
    name: "คอนสาร คอนสาร ชัยภูมิ 36180",
    name_th: "คอนสาร คอนสาร ชัยภูมิ 36180",
    name_en: "Khon San, Khon San, Chaiyaphum, 36180",
  },
  {
    id: 361302,
    districtTh: "คอนสาร",
    districtEn: "Khon San",
    subdistrictTh: "ทุ่งพระ",
    subdistrictEn: "Thung Phra",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36180,
    district_code: 3613,
    province_code: 25,
    name: "คอนสาร ทุ่งพระ ชัยภูมิ 36180",
    name_th: "คอนสาร ทุ่งพระ ชัยภูมิ 36180",
    name_en: "Khon San, Thung Phra, Chaiyaphum, 36180",
  },
  {
    id: 361303,
    districtTh: "คอนสาร",
    districtEn: "Khon San",
    subdistrictTh: "โนนคูณ",
    subdistrictEn: "Non Khun",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36180,
    district_code: 3613,
    province_code: 25,
    name: "คอนสาร โนนคูณ ชัยภูมิ 36180",
    name_th: "คอนสาร โนนคูณ ชัยภูมิ 36180",
    name_en: "Khon San, Non Khun, Chaiyaphum, 36180",
  },
  {
    id: 361304,
    districtTh: "คอนสาร",
    districtEn: "Khon San",
    subdistrictTh: "ห้วยยาง",
    subdistrictEn: "Huai Yang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36180,
    district_code: 3613,
    province_code: 25,
    name: "คอนสาร ห้วยยาง ชัยภูมิ 36180",
    name_th: "คอนสาร ห้วยยาง ชัยภูมิ 36180",
    name_en: "Khon San, Huai Yang, Chaiyaphum, 36180",
  },
  {
    id: 361305,
    districtTh: "คอนสาร",
    districtEn: "Khon San",
    subdistrictTh: "ทุ่งลุยลาย",
    subdistrictEn: "Thung Luilai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36180,
    district_code: 3613,
    province_code: 25,
    name: "คอนสาร ทุ่งลุยลาย ชัยภูมิ 36180",
    name_th: "คอนสาร ทุ่งลุยลาย ชัยภูมิ 36180",
    name_en: "Khon San, Thung Luilai, Chaiyaphum, 36180",
  },
  {
    id: 361306,
    districtTh: "คอนสาร",
    districtEn: "Khon San",
    subdistrictTh: "ดงบัง",
    subdistrictEn: "Dong Bang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36180,
    district_code: 3613,
    province_code: 25,
    name: "คอนสาร ดงบัง ชัยภูมิ 36180",
    name_th: "คอนสาร ดงบัง ชัยภูมิ 36180",
    name_en: "Khon San, Dong Bang, Chaiyaphum, 36180",
  },
  {
    id: 361307,
    districtTh: "คอนสาร",
    districtEn: "Khon San",
    subdistrictTh: "ทุ่งนาเลา",
    subdistrictEn: "Thung Na Lao",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36180,
    district_code: 3613,
    province_code: 25,
    name: "คอนสาร ทุ่งนาเลา ชัยภูมิ 36180",
    name_th: "คอนสาร ทุ่งนาเลา ชัยภูมิ 36180",
    name_en: "Khon San, Thung Na Lao, Chaiyaphum, 36180",
  },
  {
    id: 361308,
    districtTh: "คอนสาร",
    districtEn: "Khon San",
    subdistrictTh: "ดงกลาง",
    subdistrictEn: "Dong Klang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36180,
    district_code: 3613,
    province_code: 25,
    name: "คอนสาร ดงกลาง ชัยภูมิ 36180",
    name_th: "คอนสาร ดงกลาง ชัยภูมิ 36180",
    name_en: "Khon San, Dong Klang, Chaiyaphum, 36180",
  },
  {
    id: 361401,
    districtTh: "ภักดีชุมพล",
    districtEn: "Phakdi Chumphon",
    subdistrictTh: "บ้านเจียง",
    subdistrictEn: "Chao Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36260,
    district_code: 3614,
    province_code: 25,
    name: "ภักดีชุมพล บ้านเจียง ชัยภูมิ 36260",
    name_th: "ภักดีชุมพล บ้านเจียง ชัยภูมิ 36260",
    name_en: "Phakdi Chumphon, Chao Thong, Chaiyaphum, 36260",
  },
  {
    id: 361402,
    districtTh: "ภักดีชุมพล",
    districtEn: "Phakdi Chumphon",
    subdistrictTh: "เจาทอง",
    subdistrictEn: "Ban Chiang",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36260,
    district_code: 3614,
    province_code: 25,
    name: "ภักดีชุมพล เจาทอง ชัยภูมิ 36260",
    name_th: "ภักดีชุมพล เจาทอง ชัยภูมิ 36260",
    name_en: "Phakdi Chumphon, Ban Chiang, Chaiyaphum, 36260",
  },
  {
    id: 361403,
    districtTh: "ภักดีชุมพล",
    districtEn: "Phakdi Chumphon",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36260,
    district_code: 3614,
    province_code: 25,
    name: "ภักดีชุมพล วังทอง ชัยภูมิ 36260",
    name_th: "ภักดีชุมพล วังทอง ชัยภูมิ 36260",
    name_en: "Phakdi Chumphon, Wang Thong, Chaiyaphum, 36260",
  },
  {
    id: 361404,
    districtTh: "ภักดีชุมพล",
    districtEn: "Phakdi Chumphon",
    subdistrictTh: "แหลมทอง",
    subdistrictEn: "Laem Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36260,
    district_code: 3614,
    province_code: 25,
    name: "ภักดีชุมพล แหลมทอง ชัยภูมิ 36260",
    name_th: "ภักดีชุมพล แหลมทอง ชัยภูมิ 36260",
    name_en: "Phakdi Chumphon, Laem Thong, Chaiyaphum, 36260",
  },
  {
    id: 361501,
    districtTh: "เนินสง่า",
    districtEn: "Noen Sa-nga",
    subdistrictTh: "หนองฉิม",
    subdistrictEn: "Nong Chim",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3615,
    province_code: 25,
    name: "เนินสง่า หนองฉิม ชัยภูมิ 36130",
    name_th: "เนินสง่า หนองฉิม ชัยภูมิ 36130",
    name_en: "Noen Sa-nga, Nong Chim, Chaiyaphum, 36130",
  },
  {
    id: 361502,
    districtTh: "เนินสง่า",
    districtEn: "Noen Sa-nga",
    subdistrictTh: "ตาเนิน",
    subdistrictEn: "Ta Noen",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3615,
    province_code: 25,
    name: "เนินสง่า ตาเนิน ชัยภูมิ 36130",
    name_th: "เนินสง่า ตาเนิน ชัยภูมิ 36130",
    name_en: "Noen Sa-nga, Ta Noen, Chaiyaphum, 36130",
  },
  {
    id: 361503,
    districtTh: "เนินสง่า",
    districtEn: "Noen Sa-nga",
    subdistrictTh: "กะฮาด",
    subdistrictEn: "Kahat",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3615,
    province_code: 25,
    name: "เนินสง่า กะฮาด ชัยภูมิ 36130",
    name_th: "เนินสง่า กะฮาด ชัยภูมิ 36130",
    name_en: "Noen Sa-nga, Kahat, Chaiyaphum, 36130",
  },
  {
    id: 361504,
    districtTh: "เนินสง่า",
    districtEn: "Noen Sa-nga",
    subdistrictTh: "รังงาม",
    subdistrictEn: "Rang Ngam",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3615,
    province_code: 25,
    name: "เนินสง่า รังงาม ชัยภูมิ 36130",
    name_th: "เนินสง่า รังงาม ชัยภูมิ 36130",
    name_en: "Noen Sa-nga, Rang Ngam, Chaiyaphum, 36130",
  },
  {
    id: 361601,
    districtTh: "ซับใหญ่",
    districtEn: "Sap Yai",
    subdistrictTh: "ซับใหญ่",
    subdistrictEn: "Sap Yai",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3616,
    province_code: 25,
    name: "ซับใหญ่ ซับใหญ่ ชัยภูมิ 36130",
    name_th: "ซับใหญ่ ซับใหญ่ ชัยภูมิ 36130",
    name_en: "Sap Yai, Sap Yai, Chaiyaphum, 36130",
  },
  {
    id: 361602,
    districtTh: "ซับใหญ่",
    districtEn: "Sap Yai",
    subdistrictTh: "ท่ากูบ",
    subdistrictEn: "Tha Kup",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3616,
    province_code: 25,
    name: "ซับใหญ่ ท่ากูบ ชัยภูมิ 36130",
    name_th: "ซับใหญ่ ท่ากูบ ชัยภูมิ 36130",
    name_en: "Sap Yai, Tha Kup, Chaiyaphum, 36130",
  },
  {
    id: 361603,
    districtTh: "ซับใหญ่",
    districtEn: "Sap Yai",
    subdistrictTh: "ตะโกทอง",
    subdistrictEn: "Tako Thong",
    provinceTh: "ชัยภูมิ",
    provinceEn: "Chaiyaphum",
    zipcode: 36130,
    district_code: 3616,
    province_code: 25,
    name: "ซับใหญ่ ตะโกทอง ชัยภูมิ 36130",
    name_th: "ซับใหญ่ ตะโกทอง ชัยภูมิ 36130",
    name_en: "Sap Yai, Tako Thong, Chaiyaphum, 36130",
  },
  {
    id: 370101,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "บุ่ง",
    subdistrictEn: "Bung",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ บุ่ง อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ บุ่ง อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Bung, Amnat Charoen, 37000",
  },
  {
    id: 370102,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "ไก่คำ",
    subdistrictEn: "Kai Kham",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ ไก่คำ อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ ไก่คำ อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Kai Kham, Amnat Charoen, 37000",
  },
  {
    id: 370103,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "นาจิก",
    subdistrictEn: "Na Chik",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ นาจิก อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ นาจิก อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Na Chik, Amnat Charoen, 37000",
  },
  {
    id: 370104,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "ปลาค้าว",
    subdistrictEn: "Pla Khao",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ ปลาค้าว อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ ปลาค้าว อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Pla Khao, Amnat Charoen, 37000",
  },
  {
    id: 370105,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "เหล่าพรวน",
    subdistrictEn: "Lao Pruan",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ เหล่าพรวน อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ เหล่าพรวน อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Lao Pruan, Amnat Charoen, 37000",
  },
  {
    id: 370106,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "สร้างนกทา",
    subdistrictEn: "Sang Nok Tha",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ สร้างนกทา อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ สร้างนกทา อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Sang Nok Tha, Amnat Charoen, 37000",
  },
  {
    id: 370107,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "คึมใหญ่",
    subdistrictEn: "Khuem Yai",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ คึมใหญ่ อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ คึมใหญ่ อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Khuem Yai, Amnat Charoen, 37000",
  },
  {
    id: 370108,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "นาผือ",
    subdistrictEn: "Na Phue",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ นาผือ อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ นาผือ อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Na Phue, Amnat Charoen, 37000",
  },
  {
    id: 370109,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "น้ำปลีก",
    subdistrictEn: "Nam Plik",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ น้ำปลีก อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ น้ำปลีก อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Nam Plik, Amnat Charoen, 37000",
  },
  {
    id: 370110,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "นาวัง",
    subdistrictEn: "Na Wang",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ นาวัง อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ นาวัง อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Na Wang, Amnat Charoen, 37000",
  },
  {
    id: 370111,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "นาหมอม้า",
    subdistrictEn: "Na Mo Ma",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ นาหมอม้า อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ นาหมอม้า อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Na Mo Ma, Amnat Charoen, 37000",
  },
  {
    id: 370112,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "โนนโพธิ์",
    subdistrictEn: "Non Pho",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ โนนโพธิ์ อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ โนนโพธิ์ อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Non Pho, Amnat Charoen, 37000",
  },
  {
    id: 370113,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "โนนหนามแท่ง",
    subdistrictEn: "Non Nam Thaeng",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ โนนหนามแท่ง อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ โนนหนามแท่ง อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Non Nam Thaeng, Amnat Charoen, 37000",
  },
  {
    id: 370114,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "ห้วยไร่",
    subdistrictEn: "Huai Rai",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ ห้วยไร่ อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ ห้วยไร่ อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Huai Rai, Amnat Charoen, 37000",
  },
  {
    id: 370115,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "หนองมะแซว",
    subdistrictEn: "Nong Masaeo",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ หนองมะแซว อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ หนองมะแซว อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Nong Masaeo, Amnat Charoen, 37000",
  },
  {
    id: 370116,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "กุดปลาดุก",
    subdistrictEn: "Kut Pla Duk",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ กุดปลาดุก อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ กุดปลาดุก อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Kut Pla Duk, Amnat Charoen, 37000",
  },
  {
    id: 370117,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "ดอนเมย",
    subdistrictEn: "Don Moei",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ ดอนเมย อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ ดอนเมย อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Don Moei, Amnat Charoen, 37000",
  },
  {
    id: 370118,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "นายม",
    subdistrictEn: "Na Yom",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ นายม อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ นายม อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Na Yom, Amnat Charoen, 37000",
  },
  {
    id: 370119,
    districtTh: "เมืองอำนาจเจริญ",
    districtEn: "Mueang Amnat Charoen",
    subdistrictTh: "นาแต้",
    subdistrictEn: "Na Tae",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3701,
    province_code: 26,
    name: "เมืองอำนาจเจริญ นาแต้ อำนาจเจริญ 37000",
    name_th: "เมืองอำนาจเจริญ นาแต้ อำนาจเจริญ 37000",
    name_en: "Mueang Amnat Charoen, Na Tae, Amnat Charoen, 37000",
  },
  {
    id: 370201,
    districtTh: "ชานุมาน",
    districtEn: "Chanuman",
    subdistrictTh: "ชานุมาน",
    subdistrictEn: "Chanuman",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37210,
    district_code: 3702,
    province_code: 26,
    name: "ชานุมาน ชานุมาน อำนาจเจริญ 37210",
    name_th: "ชานุมาน ชานุมาน อำนาจเจริญ 37210",
    name_en: "Chanuman, Chanuman, Amnat Charoen, 37210",
  },
  {
    id: 370202,
    districtTh: "ชานุมาน",
    districtEn: "Chanuman",
    subdistrictTh: "โคกสาร",
    subdistrictEn: "Khok San",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37210,
    district_code: 3702,
    province_code: 26,
    name: "ชานุมาน โคกสาร อำนาจเจริญ 37210",
    name_th: "ชานุมาน โคกสาร อำนาจเจริญ 37210",
    name_en: "Chanuman, Khok San, Amnat Charoen, 37210",
  },
  {
    id: 370203,
    districtTh: "ชานุมาน",
    districtEn: "Chanuman",
    subdistrictTh: "คำเขื่อนแก้ว",
    subdistrictEn: "Kham Khuean Kaeo",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37210,
    district_code: 3702,
    province_code: 26,
    name: "ชานุมาน คำเขื่อนแก้ว อำนาจเจริญ 37210",
    name_th: "ชานุมาน คำเขื่อนแก้ว อำนาจเจริญ 37210",
    name_en: "Chanuman, Kham Khuean Kaeo, Amnat Charoen, 37210",
  },
  {
    id: 370204,
    districtTh: "ชานุมาน",
    districtEn: "Chanuman",
    subdistrictTh: "โคกก่ง",
    subdistrictEn: "Khok Kong",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37210,
    district_code: 3702,
    province_code: 26,
    name: "ชานุมาน โคกก่ง อำนาจเจริญ 37210",
    name_th: "ชานุมาน โคกก่ง อำนาจเจริญ 37210",
    name_en: "Chanuman, Khok Kong, Amnat Charoen, 37210",
  },
  {
    id: 370205,
    districtTh: "ชานุมาน",
    districtEn: "Chanuman",
    subdistrictTh: "ป่าก่อ",
    subdistrictEn: "Pa Ko",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37210,
    district_code: 3702,
    province_code: 26,
    name: "ชานุมาน ป่าก่อ อำนาจเจริญ 37210",
    name_th: "ชานุมาน ป่าก่อ อำนาจเจริญ 37210",
    name_en: "Chanuman, Pa Ko, Amnat Charoen, 37210",
  },
  {
    id: 370301,
    districtTh: "ปทุมราชวงศา",
    districtEn: "Pathum Ratchawongsa",
    subdistrictTh: "หนองข่า",
    subdistrictEn: "Nong Kha",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37110,
    district_code: 3703,
    province_code: 26,
    name: "ปทุมราชวงศา หนองข่า อำนาจเจริญ 37110",
    name_th: "ปทุมราชวงศา หนองข่า อำนาจเจริญ 37110",
    name_en: "Pathum Ratchawongsa, Nong Kha, Amnat Charoen, 37110",
  },
  {
    id: 370302,
    districtTh: "ปทุมราชวงศา",
    districtEn: "Pathum Ratchawongsa",
    subdistrictTh: "คำโพน",
    subdistrictEn: "Kham Phon",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37110,
    district_code: 3703,
    province_code: 26,
    name: "ปทุมราชวงศา คำโพน อำนาจเจริญ 37110",
    name_th: "ปทุมราชวงศา คำโพน อำนาจเจริญ 37110",
    name_en: "Pathum Ratchawongsa, Kham Phon, Amnat Charoen, 37110",
  },
  {
    id: 370303,
    districtTh: "ปทุมราชวงศา",
    districtEn: "Pathum Ratchawongsa",
    subdistrictTh: "นาหว้า",
    subdistrictEn: "Na Wa",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37110,
    district_code: 3703,
    province_code: 26,
    name: "ปทุมราชวงศา นาหว้า อำนาจเจริญ 37110",
    name_th: "ปทุมราชวงศา นาหว้า อำนาจเจริญ 37110",
    name_en: "Pathum Ratchawongsa, Na Wa, Amnat Charoen, 37110",
  },
  {
    id: 370304,
    districtTh: "ปทุมราชวงศา",
    districtEn: "Pathum Ratchawongsa",
    subdistrictTh: "ลือ",
    subdistrictEn: "Lue",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37110,
    district_code: 3703,
    province_code: 26,
    name: "ปทุมราชวงศา ลือ อำนาจเจริญ 37110",
    name_th: "ปทุมราชวงศา ลือ อำนาจเจริญ 37110",
    name_en: "Pathum Ratchawongsa, Lue, Amnat Charoen, 37110",
  },
  {
    id: 370305,
    districtTh: "ปทุมราชวงศา",
    districtEn: "Pathum Ratchawongsa",
    subdistrictTh: "ห้วย",
    subdistrictEn: "Huai",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37110,
    district_code: 3703,
    province_code: 26,
    name: "ปทุมราชวงศา ห้วย อำนาจเจริญ 37110",
    name_th: "ปทุมราชวงศา ห้วย อำนาจเจริญ 37110",
    name_en: "Pathum Ratchawongsa, Huai, Amnat Charoen, 37110",
  },
  {
    id: 370306,
    districtTh: "ปทุมราชวงศา",
    districtEn: "Pathum Ratchawongsa",
    subdistrictTh: "โนนงาม",
    subdistrictEn: "Non Ngam",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37110,
    district_code: 3703,
    province_code: 26,
    name: "ปทุมราชวงศา โนนงาม อำนาจเจริญ 37110",
    name_th: "ปทุมราชวงศา โนนงาม อำนาจเจริญ 37110",
    name_en: "Pathum Ratchawongsa, Non Ngam, Amnat Charoen, 37110",
  },
  {
    id: 370307,
    districtTh: "ปทุมราชวงศา",
    districtEn: "Pathum Ratchawongsa",
    subdistrictTh: "นาป่าแซง",
    subdistrictEn: "Na Pa Saeng",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37110,
    district_code: 3703,
    province_code: 26,
    name: "ปทุมราชวงศา นาป่าแซง อำนาจเจริญ 37110",
    name_th: "ปทุมราชวงศา นาป่าแซง อำนาจเจริญ 37110",
    name_en: "Pathum Ratchawongsa, Na Pa Saeng, Amnat Charoen, 37110",
  },
  {
    id: 370401,
    districtTh: "พนา",
    districtEn: "Phana",
    subdistrictTh: "พนา",
    subdistrictEn: "Phana",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37180,
    district_code: 3704,
    province_code: 26,
    name: "พนา พนา อำนาจเจริญ 37180",
    name_th: "พนา พนา อำนาจเจริญ 37180",
    name_en: "Phana, Phana, Amnat Charoen, 37180",
  },
  {
    id: 370402,
    districtTh: "พนา",
    districtEn: "Phana",
    subdistrictTh: "จานลาน",
    subdistrictEn: "Chan Lan",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37180,
    district_code: 3704,
    province_code: 26,
    name: "พนา จานลาน อำนาจเจริญ 37180",
    name_th: "พนา จานลาน อำนาจเจริญ 37180",
    name_en: "Phana, Chan Lan, Amnat Charoen, 37180",
  },
  {
    id: 370403,
    districtTh: "พนา",
    districtEn: "Phana",
    subdistrictTh: "ไม้กลอน",
    subdistrictEn: "Mai Klon",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37180,
    district_code: 3704,
    province_code: 26,
    name: "พนา ไม้กลอน อำนาจเจริญ 37180",
    name_th: "พนา ไม้กลอน อำนาจเจริญ 37180",
    name_en: "Phana, Mai Klon, Amnat Charoen, 37180",
  },
  {
    id: 370404,
    districtTh: "พนา",
    districtEn: "Phana",
    subdistrictTh: "พระเหลา",
    subdistrictEn: "Phra Lao",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37180,
    district_code: 3704,
    province_code: 26,
    name: "พนา พระเหลา อำนาจเจริญ 37180",
    name_th: "พนา พระเหลา อำนาจเจริญ 37180",
    name_en: "Phana, Phra Lao, Amnat Charoen, 37180",
  },
  {
    id: 370501,
    districtTh: "เสนางคนิคม",
    districtEn: "Senangkhanikhom",
    subdistrictTh: "เสนางคนิคม",
    subdistrictEn: "Senangkhanikhom",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37290,
    district_code: 3705,
    province_code: 26,
    name: "เสนางคนิคม เสนางคนิคม อำนาจเจริญ 37290",
    name_th: "เสนางคนิคม เสนางคนิคม อำนาจเจริญ 37290",
    name_en: "Senangkhanikhom, Senangkhanikhom, Amnat Charoen, 37290",
  },
  {
    id: 370502,
    districtTh: "เสนางคนิคม",
    districtEn: "Senangkhanikhom",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37290,
    district_code: 3705,
    province_code: 26,
    name: "เสนางคนิคม โพนทอง อำนาจเจริญ 37290",
    name_th: "เสนางคนิคม โพนทอง อำนาจเจริญ 37290",
    name_en: "Senangkhanikhom, Phon Thong, Amnat Charoen, 37290",
  },
  {
    id: 370503,
    districtTh: "เสนางคนิคม",
    districtEn: "Senangkhanikhom",
    subdistrictTh: "ไร่สีสุก",
    subdistrictEn: "Rai Si Suk",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37290,
    district_code: 3705,
    province_code: 26,
    name: "เสนางคนิคม ไร่สีสุก อำนาจเจริญ 37290",
    name_th: "เสนางคนิคม ไร่สีสุก อำนาจเจริญ 37290",
    name_en: "Senangkhanikhom, Rai Si Suk, Amnat Charoen, 37290",
  },
  {
    id: 370504,
    districtTh: "เสนางคนิคม",
    districtEn: "Senangkhanikhom",
    subdistrictTh: "นาเวียง",
    subdistrictEn: "Na Wiang",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37290,
    district_code: 3705,
    province_code: 26,
    name: "เสนางคนิคม นาเวียง อำนาจเจริญ 37290",
    name_th: "เสนางคนิคม นาเวียง อำนาจเจริญ 37290",
    name_en: "Senangkhanikhom, Na Wiang, Amnat Charoen, 37290",
  },
  {
    id: 370505,
    districtTh: "เสนางคนิคม",
    districtEn: "Senangkhanikhom",
    subdistrictTh: "หนองไฮ",
    subdistrictEn: "Nong Hai",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37290,
    district_code: 3705,
    province_code: 26,
    name: "เสนางคนิคม หนองไฮ อำนาจเจริญ 37290",
    name_th: "เสนางคนิคม หนองไฮ อำนาจเจริญ 37290",
    name_en: "Senangkhanikhom, Nong Hai, Amnat Charoen, 37290",
  },
  {
    id: 370506,
    districtTh: "เสนางคนิคม",
    districtEn: "Senangkhanikhom",
    subdistrictTh: "หนองสามสี",
    subdistrictEn: "Nong Sam Si",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37290,
    district_code: 3705,
    province_code: 26,
    name: "เสนางคนิคม หนองสามสี อำนาจเจริญ 37290",
    name_th: "เสนางคนิคม หนองสามสี อำนาจเจริญ 37290",
    name_en: "Senangkhanikhom, Nong Sam Si, Amnat Charoen, 37290",
  },
  {
    id: 370601,
    districtTh: "หัวตะพาน",
    districtEn: "Hua Taphan",
    subdistrictTh: "หัวตะพาน",
    subdistrictEn: "Hua Taphan",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37240,
    district_code: 3706,
    province_code: 26,
    name: "หัวตะพาน หัวตะพาน อำนาจเจริญ 37240",
    name_th: "หัวตะพาน หัวตะพาน อำนาจเจริญ 37240",
    name_en: "Hua Taphan, Hua Taphan, Amnat Charoen, 37240",
  },
  {
    id: 370602,
    districtTh: "หัวตะพาน",
    districtEn: "Hua Taphan",
    subdistrictTh: "คำพระ",
    subdistrictEn: "Kham Phra",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37240,
    district_code: 3706,
    province_code: 26,
    name: "หัวตะพาน คำพระ อำนาจเจริญ 37240",
    name_th: "หัวตะพาน คำพระ อำนาจเจริญ 37240",
    name_en: "Hua Taphan, Kham Phra, Amnat Charoen, 37240",
  },
  {
    id: 370603,
    districtTh: "หัวตะพาน",
    districtEn: "Hua Taphan",
    subdistrictTh: "เค็งใหญ่",
    subdistrictEn: "Kheng Yai",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37240,
    district_code: 3706,
    province_code: 26,
    name: "หัวตะพาน เค็งใหญ่ อำนาจเจริญ 37240",
    name_th: "หัวตะพาน เค็งใหญ่ อำนาจเจริญ 37240",
    name_en: "Hua Taphan, Kheng Yai, Amnat Charoen, 37240",
  },
  {
    id: 370604,
    districtTh: "หัวตะพาน",
    districtEn: "Hua Taphan",
    subdistrictTh: "หนองแก้ว",
    subdistrictEn: "Nong Kaeo",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37240,
    district_code: 3706,
    province_code: 26,
    name: "หัวตะพาน หนองแก้ว อำนาจเจริญ 37240",
    name_th: "หัวตะพาน หนองแก้ว อำนาจเจริญ 37240",
    name_en: "Hua Taphan, Nong Kaeo, Amnat Charoen, 37240",
  },
  {
    id: 370605,
    districtTh: "หัวตะพาน",
    districtEn: "Hua Taphan",
    subdistrictTh: "โพนเมืองน้อย",
    subdistrictEn: "Phon Mueang Noi",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37240,
    district_code: 3706,
    province_code: 26,
    name: "หัวตะพาน โพนเมืองน้อย อำนาจเจริญ 37240",
    name_th: "หัวตะพาน โพนเมืองน้อย อำนาจเจริญ 37240",
    name_en: "Hua Taphan, Phon Mueang Noi, Amnat Charoen, 37240",
  },
  {
    id: 370606,
    districtTh: "หัวตะพาน",
    districtEn: "Hua Taphan",
    subdistrictTh: "สร้างถ่อน้อย",
    subdistrictEn: "Sang Tho Noi",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37240,
    district_code: 3706,
    province_code: 26,
    name: "หัวตะพาน สร้างถ่อน้อย อำนาจเจริญ 37240",
    name_th: "หัวตะพาน สร้างถ่อน้อย อำนาจเจริญ 37240",
    name_en: "Hua Taphan, Sang Tho Noi, Amnat Charoen, 37240",
  },
  {
    id: 370607,
    districtTh: "หัวตะพาน",
    districtEn: "Hua Taphan",
    subdistrictTh: "จิกดู่",
    subdistrictEn: "Chik Du",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37240,
    district_code: 3706,
    province_code: 26,
    name: "หัวตะพาน จิกดู่ อำนาจเจริญ 37240",
    name_th: "หัวตะพาน จิกดู่ อำนาจเจริญ 37240",
    name_en: "Hua Taphan, Chik Du, Amnat Charoen, 37240",
  },
  {
    id: 370608,
    districtTh: "หัวตะพาน",
    districtEn: "Hua Taphan",
    subdistrictTh: "รัตนวารี",
    subdistrictEn: "Rattanawari",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37240,
    district_code: 3706,
    province_code: 26,
    name: "หัวตะพาน รัตนวารี อำนาจเจริญ 37240",
    name_th: "หัวตะพาน รัตนวารี อำนาจเจริญ 37240",
    name_en: "Hua Taphan, Rattanawari, Amnat Charoen, 37240",
  },
  {
    id: 370701,
    districtTh: "ลืออำนาจ",
    districtEn: "Lue Amnat",
    subdistrictTh: "อำนาจ",
    subdistrictEn: "Amnat",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3707,
    province_code: 26,
    name: "ลืออำนาจ อำนาจ อำนาจเจริญ 37000",
    name_th: "ลืออำนาจ อำนาจ อำนาจเจริญ 37000",
    name_en: "Lue Amnat, Amnat, Amnat Charoen, 37000",
  },
  {
    id: 370702,
    districtTh: "ลืออำนาจ",
    districtEn: "Lue Amnat",
    subdistrictTh: "ดงมะยาง",
    subdistrictEn: "Dong Mayang",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3707,
    province_code: 26,
    name: "ลืออำนาจ ดงมะยาง อำนาจเจริญ 37000",
    name_th: "ลืออำนาจ ดงมะยาง อำนาจเจริญ 37000",
    name_en: "Lue Amnat, Dong Mayang, Amnat Charoen, 37000",
  },
  {
    id: 370703,
    districtTh: "ลืออำนาจ",
    districtEn: "Lue Amnat",
    subdistrictTh: "เปือย",
    subdistrictEn: "Pueai",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3707,
    province_code: 26,
    name: "ลืออำนาจ เปือย อำนาจเจริญ 37000",
    name_th: "ลืออำนาจ เปือย อำนาจเจริญ 37000",
    name_en: "Lue Amnat, Pueai, Amnat Charoen, 37000",
  },
  {
    id: 370704,
    districtTh: "ลืออำนาจ",
    districtEn: "Lue Amnat",
    subdistrictTh: "ดงบัง",
    subdistrictEn: "Dong Bang",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3707,
    province_code: 26,
    name: "ลืออำนาจ ดงบัง อำนาจเจริญ 37000",
    name_th: "ลืออำนาจ ดงบัง อำนาจเจริญ 37000",
    name_en: "Lue Amnat, Dong Bang, Amnat Charoen, 37000",
  },
  {
    id: 370705,
    districtTh: "ลืออำนาจ",
    districtEn: "Lue Amnat",
    subdistrictTh: "ไร่ขี",
    subdistrictEn: "Rai Khi",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3707,
    province_code: 26,
    name: "ลืออำนาจ ไร่ขี อำนาจเจริญ 37000",
    name_th: "ลืออำนาจ ไร่ขี อำนาจเจริญ 37000",
    name_en: "Lue Amnat, Rai Khi, Amnat Charoen, 37000",
  },
  {
    id: 370706,
    districtTh: "ลืออำนาจ",
    districtEn: "Lue Amnat",
    subdistrictTh: "แมด",
    subdistrictEn: "Maet",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3707,
    province_code: 26,
    name: "ลืออำนาจ แมด อำนาจเจริญ 37000",
    name_th: "ลืออำนาจ แมด อำนาจเจริญ 37000",
    name_en: "Lue Amnat, Maet, Amnat Charoen, 37000",
  },
  {
    id: 370707,
    districtTh: "ลืออำนาจ",
    districtEn: "Lue Amnat",
    subdistrictTh: "โคกกลาง",
    subdistrictEn: "Khok Klang",
    provinceTh: "อำนาจเจริญ",
    provinceEn: "Amnat Charoen",
    zipcode: 37000,
    district_code: 3707,
    province_code: 26,
    name: "ลืออำนาจ โคกกลาง อำนาจเจริญ 37000",
    name_th: "ลืออำนาจ โคกกลาง อำนาจเจริญ 37000",
    name_en: "Lue Amnat, Khok Klang, Amnat Charoen, 37000",
  },
  {
    id: 390101,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู หนองบัว หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู หนองบัว หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Nong Bua, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390102,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "หนองภัยศูนย์",
    subdistrictEn: "Nong Phai Sun",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู หนองภัยศูนย์ หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู หนองภัยศูนย์ หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Nong Phai Sun, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390103,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "โพธิ์ชัย",
    subdistrictEn: "Pho Chai",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู โพธิ์ชัย หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู โพธิ์ชัย หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Pho Chai, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390104,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "หนองสวรรค์",
    subdistrictEn: "Nong Sawan",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู หนองสวรรค์ หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู หนองสวรรค์ หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Nong Sawan, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390105,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "หัวนา",
    subdistrictEn: "Hua Na",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู หัวนา หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู หัวนา หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Hua Na, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390106,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "บ้านขาม",
    subdistrictEn: "Ban Kham",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู บ้านขาม หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู บ้านขาม หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Ban Kham, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390107,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "นามะเฟือง",
    subdistrictEn: "Na Mafueang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู นามะเฟือง หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู นามะเฟือง หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Na Mafueang, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390108,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "บ้านพร้าว",
    subdistrictEn: "Ban Phrao",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู บ้านพร้าว หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู บ้านพร้าว หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Ban Phrao, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390109,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "โนนขมิ้น",
    subdistrictEn: "Non Khamin",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู โนนขมิ้น หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู โนนขมิ้น หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Non Khamin, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390110,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "ลำภู",
    subdistrictEn: "Lam Phu",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู ลำภู หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู ลำภู หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Lam Phu, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390111,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "กุดจิก",
    subdistrictEn: "Kut Chik",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู กุดจิก หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู กุดจิก หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Kut Chik, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390112,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "โนนทัน",
    subdistrictEn: "Non Than",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู โนนทัน หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู โนนทัน หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Non Than, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390113,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "นาคำไฮ",
    subdistrictEn: "Na Kham Hai",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู นาคำไฮ หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู นาคำไฮ หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Na Kham Hai, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390114,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "ป่าไม้งาม",
    subdistrictEn: "Pa Mai Ngam",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู ป่าไม้งาม หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู ป่าไม้งาม หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Pa Mai Ngam, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390115,
    districtTh: "เมืองหนองบัวลำภู",
    districtEn: "Mueang Nong Bua Lam Phu",
    subdistrictTh: "หนองหว้า",
    subdistrictEn: "Nong Wa",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39000,
    district_code: 3901,
    province_code: 27,
    name: "เมืองหนองบัวลำภู หนองหว้า หนองบัวลำภู 39000",
    name_th: "เมืองหนองบัวลำภู หนองหว้า หนองบัวลำภู 39000",
    name_en: "Mueang Nong Bua Lam Phu, Nong Wa, Nong Bua Lam Phu, 39000",
  },
  {
    id: 390201,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "นากลาง",
    subdistrictEn: "Na Klang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง นากลาง หนองบัวลำภู 39170",
    name_th: "นากลาง นากลาง หนองบัวลำภู 39170",
    name_en: "Na Klang, Na Klang, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390202,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "ด่านช้าง",
    subdistrictEn: "Dan Chang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง ด่านช้าง หนองบัวลำภู 39170",
    name_th: "นากลาง ด่านช้าง หนองบัวลำภู 39170",
    name_en: "Na Klang, Dan Chang, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390205,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "กุดดินจี่",
    subdistrictEn: "Kut Din Chi",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39350,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง กุดดินจี่ หนองบัวลำภู 39350",
    name_th: "นากลาง กุดดินจี่ หนองบัวลำภู 39350",
    name_en: "Na Klang, Kut Din Chi, Nong Bua Lam Phu, 39350",
  },
  {
    id: 390206,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "ฝั่งแดง",
    subdistrictEn: "Fang Daeng",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง ฝั่งแดง หนองบัวลำภู 39170",
    name_th: "นากลาง ฝั่งแดง หนองบัวลำภู 39170",
    name_en: "Na Klang, Fang Daeng, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390207,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "เก่ากลอย",
    subdistrictEn: "Kao Kloi",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39350,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง เก่ากลอย หนองบัวลำภู 39350",
    name_th: "นากลาง เก่ากลอย หนองบัวลำภู 39350",
    name_en: "Na Klang, Kao Kloi, Nong Bua Lam Phu, 39350",
  },
  {
    id: 390209,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "โนนเมือง",
    subdistrictEn: "Non Mueang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง โนนเมือง หนองบัวลำภู 39170",
    name_th: "นากลาง โนนเมือง หนองบัวลำภู 39170",
    name_en: "Na Klang, Non Mueang, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390210,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "อุทัยสวรรค์",
    subdistrictEn: "Uthai Sawan",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง อุทัยสวรรค์ หนองบัวลำภู 39170",
    name_th: "นากลาง อุทัยสวรรค์ หนองบัวลำภู 39170",
    name_en: "Na Klang, Uthai Sawan, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390211,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "ดงสวรรค์",
    subdistrictEn: "Dong Sawan",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39350,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง ดงสวรรค์ หนองบัวลำภู 39350",
    name_th: "นากลาง ดงสวรรค์ หนองบัวลำภู 39350",
    name_en: "Na Klang, Dong Sawan, Nong Bua Lam Phu, 39350",
  },
  {
    id: 390213,
    districtTh: "นากลาง",
    districtEn: "Na Klang",
    subdistrictTh: "กุดแห่",
    subdistrictEn: "Kut Hae",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3902,
    province_code: 27,
    name: "นากลาง กุดแห่ หนองบัวลำภู 39170",
    name_th: "นากลาง กุดแห่ หนองบัวลำภู 39170",
    name_en: "Na Klang, Kut Hae, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390301,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "โนนสัง",
    subdistrictEn: "Non Sang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง โนนสัง หนองบัวลำภู 39140",
    name_th: "โนนสัง โนนสัง หนองบัวลำภู 39140",
    name_en: "Non Sang, Non Sang, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390302,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "บ้านถิ่น",
    subdistrictEn: "Ban Thin",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง บ้านถิ่น หนองบัวลำภู 39140",
    name_th: "โนนสัง บ้านถิ่น หนองบัวลำภู 39140",
    name_en: "Non Sang, Ban Thin, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390303,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "หนองเรือ",
    subdistrictEn: "Nong Ruea",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง หนองเรือ หนองบัวลำภู 39140",
    name_th: "โนนสัง หนองเรือ หนองบัวลำภู 39140",
    name_en: "Non Sang, Nong Ruea, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390304,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "กุดดู่",
    subdistrictEn: "Kut Du",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง กุดดู่ หนองบัวลำภู 39140",
    name_th: "โนนสัง กุดดู่ หนองบัวลำภู 39140",
    name_en: "Non Sang, Kut Du, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390305,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "บ้านค้อ",
    subdistrictEn: "Ban Kho",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง บ้านค้อ หนองบัวลำภู 39140",
    name_th: "โนนสัง บ้านค้อ หนองบัวลำภู 39140",
    name_en: "Non Sang, Ban Kho, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390306,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "โนนเมือง",
    subdistrictEn: "Non Mueang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง โนนเมือง หนองบัวลำภู 39140",
    name_th: "โนนสัง โนนเมือง หนองบัวลำภู 39140",
    name_en: "Non Sang, Non Mueang, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390307,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "โคกใหญ่",
    subdistrictEn: "Khok Yai",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง โคกใหญ่ หนองบัวลำภู 39140",
    name_th: "โนนสัง โคกใหญ่ หนองบัวลำภู 39140",
    name_en: "Non Sang, Khok Yai, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390308,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "โคกม่วง",
    subdistrictEn: "Khok Muang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง โคกม่วง หนองบัวลำภู 39140",
    name_th: "โนนสัง โคกม่วง หนองบัวลำภู 39140",
    name_en: "Non Sang, Khok Muang, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390309,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "นิคมพัฒนา",
    subdistrictEn: "Nikhom Phatthana",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง นิคมพัฒนา หนองบัวลำภู 39140",
    name_th: "โนนสัง นิคมพัฒนา หนองบัวลำภู 39140",
    name_en: "Non Sang, Nikhom Phatthana, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390310,
    districtTh: "โนนสัง",
    districtEn: "Non Sang",
    subdistrictTh: "ปางกู่",
    subdistrictEn: "Pang Ku",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39140,
    district_code: 3903,
    province_code: 27,
    name: "โนนสัง ปางกู่ หนองบัวลำภู 39140",
    name_th: "โนนสัง ปางกู่ หนองบัวลำภู 39140",
    name_en: "Non Sang, Pang Ku, Nong Bua Lam Phu, 39140",
  },
  {
    id: 390401,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "เมืองใหม่",
    subdistrictEn: "Mueang Mai",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง เมืองใหม่ หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง เมืองใหม่ หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Mueang Mai, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390402,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "ศรีบุญเรือง",
    subdistrictEn: "Si Bun Rueang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง ศรีบุญเรือง หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง ศรีบุญเรือง หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Si Bun Rueang, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390403,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "หนองบัวใต้",
    subdistrictEn: "Nong Bua Tai",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง หนองบัวใต้ หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง หนองบัวใต้ หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Nong Bua Tai, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390404,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "กุดสะเทียน",
    subdistrictEn: "Kut Sathian",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง กุดสะเทียน หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง กุดสะเทียน หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Kut Sathian, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390405,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "นากอก",
    subdistrictEn: "Na Kok",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง นากอก หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง นากอก หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Na Kok, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390406,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "โนนสะอาด",
    subdistrictEn: "Non Sa-at",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง โนนสะอาด หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง โนนสะอาด หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Non Sa-at, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390407,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "ยางหล่อ",
    subdistrictEn: "Yang Lo",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง ยางหล่อ หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง ยางหล่อ หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Yang Lo, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390408,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "โนนม่วง",
    subdistrictEn: "Non Muang",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง โนนม่วง หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง โนนม่วง หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Non Muang, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390409,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "หนองกุงแก้ว",
    subdistrictEn: "Nong Kung Kaeo",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง หนองกุงแก้ว หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง หนองกุงแก้ว หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Nong Kung Kaeo, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390410,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "หนองแก",
    subdistrictEn: "Nong Kae",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง หนองแก หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง หนองแก หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Nong Kae, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390411,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "ทรายทอง",
    subdistrictEn: "Sai Thong",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง ทรายทอง หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง ทรายทอง หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Sai Thong, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390412,
    districtTh: "ศรีบุญเรือง",
    districtEn: "Si Bun Rueang",
    subdistrictTh: "หันนางาม",
    subdistrictEn: "Han Na Ngam",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39180,
    district_code: 3904,
    province_code: 27,
    name: "ศรีบุญเรือง หันนางาม หนองบัวลำภู 39180",
    name_th: "ศรีบุญเรือง หันนางาม หนองบัวลำภู 39180",
    name_en: "Si Bun Rueang, Han Na Ngam, Nong Bua Lam Phu, 39180",
  },
  {
    id: 390501,
    districtTh: "สุวรรณคูหา",
    districtEn: "Suwannakhuha",
    subdistrictTh: "นาสี",
    subdistrictEn: "Nasi",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39270,
    district_code: 3905,
    province_code: 27,
    name: "สุวรรณคูหา นาสี หนองบัวลำภู 39270",
    name_th: "สุวรรณคูหา นาสี หนองบัวลำภู 39270",
    name_en: "Suwannakhuha, Nasi, Nong Bua Lam Phu, 39270",
  },
  {
    id: 390502,
    districtTh: "สุวรรณคูหา",
    districtEn: "Suwannakhuha",
    subdistrictTh: "บ้านโคก",
    subdistrictEn: "Ban Khok",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39270,
    district_code: 3905,
    province_code: 27,
    name: "สุวรรณคูหา บ้านโคก หนองบัวลำภู 39270",
    name_th: "สุวรรณคูหา บ้านโคก หนองบัวลำภู 39270",
    name_en: "Suwannakhuha, Ban Khok, Nong Bua Lam Phu, 39270",
  },
  {
    id: 390503,
    districtTh: "สุวรรณคูหา",
    districtEn: "Suwannakhuha",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39270,
    district_code: 3905,
    province_code: 27,
    name: "สุวรรณคูหา นาดี หนองบัวลำภู 39270",
    name_th: "สุวรรณคูหา นาดี หนองบัวลำภู 39270",
    name_en: "Suwannakhuha, Na Di, Nong Bua Lam Phu, 39270",
  },
  {
    id: 390504,
    districtTh: "สุวรรณคูหา",
    districtEn: "Suwannakhuha",
    subdistrictTh: "นาด่าน",
    subdistrictEn: "Na Dan",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39270,
    district_code: 3905,
    province_code: 27,
    name: "สุวรรณคูหา นาด่าน หนองบัวลำภู 39270",
    name_th: "สุวรรณคูหา นาด่าน หนองบัวลำภู 39270",
    name_en: "Suwannakhuha, Na Dan, Nong Bua Lam Phu, 39270",
  },
  {
    id: 390505,
    districtTh: "สุวรรณคูหา",
    districtEn: "Suwannakhuha",
    subdistrictTh: "ดงมะไฟ",
    subdistrictEn: "Dong Mafai",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39270,
    district_code: 3905,
    province_code: 27,
    name: "สุวรรณคูหา ดงมะไฟ หนองบัวลำภู 39270",
    name_th: "สุวรรณคูหา ดงมะไฟ หนองบัวลำภู 39270",
    name_en: "Suwannakhuha, Dong Mafai, Nong Bua Lam Phu, 39270",
  },
  {
    id: 390506,
    districtTh: "สุวรรณคูหา",
    districtEn: "Suwannakhuha",
    subdistrictTh: "สุวรรณคูหา",
    subdistrictEn: "Suwannakhuha",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39270,
    district_code: 3905,
    province_code: 27,
    name: "สุวรรณคูหา สุวรรณคูหา หนองบัวลำภู 39270",
    name_th: "สุวรรณคูหา สุวรรณคูหา หนองบัวลำภู 39270",
    name_en: "Suwannakhuha, Suwannakhuha, Nong Bua Lam Phu, 39270",
  },
  {
    id: 390507,
    districtTh: "สุวรรณคูหา",
    districtEn: "Suwannakhuha",
    subdistrictTh: "บุญทัน",
    subdistrictEn: "Bun Than",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39270,
    district_code: 3905,
    province_code: 27,
    name: "สุวรรณคูหา บุญทัน หนองบัวลำภู 39270",
    name_th: "สุวรรณคูหา บุญทัน หนองบัวลำภู 39270",
    name_en: "Suwannakhuha, Bun Than, Nong Bua Lam Phu, 39270",
  },
  {
    id: 390508,
    districtTh: "สุวรรณคูหา",
    districtEn: "Suwannakhuha",
    subdistrictTh: "กุดผึ้ง",
    subdistrictEn: "Kut Phueng",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39270,
    district_code: 3905,
    province_code: 27,
    name: "สุวรรณคูหา กุดผึ้ง หนองบัวลำภู 39270",
    name_th: "สุวรรณคูหา กุดผึ้ง หนองบัวลำภู 39270",
    name_en: "Suwannakhuha, Kut Phueng, Nong Bua Lam Phu, 39270",
  },
  {
    id: 390601,
    districtTh: "นาวัง",
    districtEn: "Na Wang",
    subdistrictTh: "นาเหล่า",
    subdistrictEn: "Na Lao",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3906,
    province_code: 27,
    name: "นาวัง นาเหล่า หนองบัวลำภู 39170",
    name_th: "นาวัง นาเหล่า หนองบัวลำภู 39170",
    name_en: "Na Wang, Na Lao, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390602,
    districtTh: "นาวัง",
    districtEn: "Na Wang",
    subdistrictTh: "นาแก",
    subdistrictEn: "Na Kae",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3906,
    province_code: 27,
    name: "นาวัง นาแก หนองบัวลำภู 39170",
    name_th: "นาวัง นาแก หนองบัวลำภู 39170",
    name_en: "Na Wang, Na Kae, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390603,
    districtTh: "นาวัง",
    districtEn: "Na Wang",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3906,
    province_code: 27,
    name: "นาวัง วังทอง หนองบัวลำภู 39170",
    name_th: "นาวัง วังทอง หนองบัวลำภู 39170",
    name_en: "Na Wang, Wang Thong, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390604,
    districtTh: "นาวัง",
    districtEn: "Na Wang",
    subdistrictTh: "วังปลาป้อม",
    subdistrictEn: "Wang Pla Pom",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3906,
    province_code: 27,
    name: "นาวัง วังปลาป้อม หนองบัวลำภู 39170",
    name_th: "นาวัง วังปลาป้อม หนองบัวลำภู 39170",
    name_en: "Na Wang, Wang Pla Pom, Nong Bua Lam Phu, 39170",
  },
  {
    id: 390605,
    districtTh: "นาวัง",
    districtEn: "Na Wang",
    subdistrictTh: "เทพคีรี",
    subdistrictEn: "Thep Khiri",
    provinceTh: "หนองบัวลำภู",
    provinceEn: "Nong Bua Lam Phu",
    zipcode: 39170,
    district_code: 3906,
    province_code: 27,
    name: "นาวัง เทพคีรี หนองบัวลำภู 39170",
    name_th: "นาวัง เทพคีรี หนองบัวลำภู 39170",
    name_en: "Na Wang, Thep Khiri, Nong Bua Lam Phu, 39170",
  },
  {
    id: 400101,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น ในเมือง ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น ในเมือง ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Nai Mueang, Khon Kaen, 40000",
  },
  {
    id: 400102,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "สำราญ",
    subdistrictEn: "Samran",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น สำราญ ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น สำราญ ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Samran, Khon Kaen, 40000",
  },
  {
    id: 400103,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "โคกสี",
    subdistrictEn: "Khok Si",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น โคกสี ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น โคกสี ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Khok Si, Khon Kaen, 40000",
  },
  {
    id: 400104,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "ท่าพระ",
    subdistrictEn: "Tha Phra",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40260,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น ท่าพระ ขอนแก่น 40260",
    name_th: "เมืองขอนแก่น ท่าพระ ขอนแก่น 40260",
    name_en: "Mueang Khon Kaen, Tha Phra, Khon Kaen, 40260",
  },
  {
    id: 400105,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "บ้านทุ่ม",
    subdistrictEn: "Ban Thum",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น บ้านทุ่ม ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น บ้านทุ่ม ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Ban Thum, Khon Kaen, 40000",
  },
  {
    id: 400106,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "เมืองเก่า",
    subdistrictEn: "Mueang Kao",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น เมืองเก่า ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น เมืองเก่า ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Mueang Kao, Khon Kaen, 40000",
  },
  {
    id: 400107,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "พระลับ",
    subdistrictEn: "Phra Lap",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น พระลับ ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น พระลับ ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Phra Lap, Khon Kaen, 40000",
  },
  {
    id: 400108,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "สาวะถี",
    subdistrictEn: "Sawathi",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น สาวะถี ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น สาวะถี ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Sawathi, Khon Kaen, 40000",
  },
  {
    id: 400109,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "บ้านหว้า",
    subdistrictEn: "Ban Wa",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น บ้านหว้า ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น บ้านหว้า ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Ban Wa, Khon Kaen, 40000",
  },
  {
    id: 400110,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "บ้านค้อ",
    subdistrictEn: "Ban Kho",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น บ้านค้อ ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น บ้านค้อ ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Ban Kho, Khon Kaen, 40000",
  },
  {
    id: 400111,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "แดงใหญ่",
    subdistrictEn: "Daeng Yai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น แดงใหญ่ ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น แดงใหญ่ ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Daeng Yai, Khon Kaen, 40000",
  },
  {
    id: 400113,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "ดอนหัน",
    subdistrictEn: "Don Han",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40260,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น ดอนหัน ขอนแก่น 40260",
    name_th: "เมืองขอนแก่น ดอนหัน ขอนแก่น 40260",
    name_en: "Mueang Khon Kaen, Don Han, Khon Kaen, 40260",
  },
  {
    id: 400114,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "ศิลา",
    subdistrictEn: "Sila",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น ศิลา ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น ศิลา ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Sila, Khon Kaen, 40000",
  },
  {
    id: 400115,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "บ้านเป็ด",
    subdistrictEn: "Ban Pet",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น บ้านเป็ด ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น บ้านเป็ด ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Ban Pet, Khon Kaen, 40000",
  },
  {
    id: 400116,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "หนองตูม",
    subdistrictEn: "Nong Tum",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น หนองตูม ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น หนองตูม ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Nong Tum, Khon Kaen, 40000",
  },
  {
    id: 400117,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "บึงเนียม",
    subdistrictEn: "Bueng Niam",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น บึงเนียม ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น บึงเนียม ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Bueng Niam, Khon Kaen, 40000",
  },
  {
    id: 400118,
    districtTh: "เมืองขอนแก่น",
    districtEn: "Mueang Khon Kaen",
    subdistrictTh: "โนนท่อน",
    subdistrictEn: "Non Thon",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40000,
    district_code: 4001,
    province_code: 28,
    name: "เมืองขอนแก่น โนนท่อน ขอนแก่น 40000",
    name_th: "เมืองขอนแก่น โนนท่อน ขอนแก่น 40000",
    name_en: "Mueang Khon Kaen, Non Thon, Khon Kaen, 40000",
  },
  {
    id: 400201,
    districtTh: "บ้านฝาง",
    districtEn: "Ban Fang",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40270,
    district_code: 4002,
    province_code: 28,
    name: "บ้านฝาง หนองบัว ขอนแก่น 40270",
    name_th: "บ้านฝาง หนองบัว ขอนแก่น 40270",
    name_en: "Ban Fang, Nong Bua, Khon Kaen, 40270",
  },
  {
    id: 400202,
    districtTh: "บ้านฝาง",
    districtEn: "Ban Fang",
    subdistrictTh: "ป่าหวายนั่ง",
    subdistrictEn: "Pa Wai Nang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40270,
    district_code: 4002,
    province_code: 28,
    name: "บ้านฝาง ป่าหวายนั่ง ขอนแก่น 40270",
    name_th: "บ้านฝาง ป่าหวายนั่ง ขอนแก่น 40270",
    name_en: "Ban Fang, Pa Wai Nang, Khon Kaen, 40270",
  },
  {
    id: 400203,
    districtTh: "บ้านฝาง",
    districtEn: "Ban Fang",
    subdistrictTh: "โนนฆ้อง",
    subdistrictEn: "Non Khong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40270,
    district_code: 4002,
    province_code: 28,
    name: "บ้านฝาง โนนฆ้อง ขอนแก่น 40270",
    name_th: "บ้านฝาง โนนฆ้อง ขอนแก่น 40270",
    name_en: "Ban Fang, Non Khong, Khon Kaen, 40270",
  },
  {
    id: 400204,
    districtTh: "บ้านฝาง",
    districtEn: "Ban Fang",
    subdistrictTh: "บ้านเหล่า",
    subdistrictEn: "Ban Lao",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40270,
    district_code: 4002,
    province_code: 28,
    name: "บ้านฝาง บ้านเหล่า ขอนแก่น 40270",
    name_th: "บ้านฝาง บ้านเหล่า ขอนแก่น 40270",
    name_en: "Ban Fang, Ban Lao, Khon Kaen, 40270",
  },
  {
    id: 400205,
    districtTh: "บ้านฝาง",
    districtEn: "Ban Fang",
    subdistrictTh: "ป่ามะนาว",
    subdistrictEn: "Pa Manao",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40270,
    district_code: 4002,
    province_code: 28,
    name: "บ้านฝาง ป่ามะนาว ขอนแก่น 40270",
    name_th: "บ้านฝาง ป่ามะนาว ขอนแก่น 40270",
    name_en: "Ban Fang, Pa Manao, Khon Kaen, 40270",
  },
  {
    id: 400206,
    districtTh: "บ้านฝาง",
    districtEn: "Ban Fang",
    subdistrictTh: "บ้านฝาง",
    subdistrictEn: "Ban Fang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40270,
    district_code: 4002,
    province_code: 28,
    name: "บ้านฝาง บ้านฝาง ขอนแก่น 40270",
    name_th: "บ้านฝาง บ้านฝาง ขอนแก่น 40270",
    name_en: "Ban Fang, Ban Fang, Khon Kaen, 40270",
  },
  {
    id: 400207,
    districtTh: "บ้านฝาง",
    districtEn: "Ban Fang",
    subdistrictTh: "โคกงาม",
    subdistrictEn: "Khok Ngam",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40270,
    district_code: 4002,
    province_code: 28,
    name: "บ้านฝาง โคกงาม ขอนแก่น 40270",
    name_th: "บ้านฝาง โคกงาม ขอนแก่น 40270",
    name_en: "Ban Fang, Khok Ngam, Khon Kaen, 40270",
  },
  {
    id: 400301,
    districtTh: "พระยืน",
    districtEn: "Phra Yuen",
    subdistrictTh: "พระยืน",
    subdistrictEn: "Phra Yuen",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40320,
    district_code: 4003,
    province_code: 28,
    name: "พระยืน พระยืน ขอนแก่น 40320",
    name_th: "พระยืน พระยืน ขอนแก่น 40320",
    name_en: "Phra Yuen, Phra Yuen, Khon Kaen, 40320",
  },
  {
    id: 400302,
    districtTh: "พระยืน",
    districtEn: "Phra Yuen",
    subdistrictTh: "พระบุ",
    subdistrictEn: "Phra Bu",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40320,
    district_code: 4003,
    province_code: 28,
    name: "พระยืน พระบุ ขอนแก่น 40320",
    name_th: "พระยืน พระบุ ขอนแก่น 40320",
    name_en: "Phra Yuen, Phra Bu, Khon Kaen, 40320",
  },
  {
    id: 400303,
    districtTh: "พระยืน",
    districtEn: "Phra Yuen",
    subdistrictTh: "บ้านโต้น",
    subdistrictEn: "Ban Ton",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40320,
    district_code: 4003,
    province_code: 28,
    name: "พระยืน บ้านโต้น ขอนแก่น 40320",
    name_th: "พระยืน บ้านโต้น ขอนแก่น 40320",
    name_en: "Phra Yuen, Ban Ton, Khon Kaen, 40320",
  },
  {
    id: 400304,
    districtTh: "พระยืน",
    districtEn: "Phra Yuen",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40320,
    district_code: 4003,
    province_code: 28,
    name: "พระยืน หนองแวง ขอนแก่น 40320",
    name_th: "พระยืน หนองแวง ขอนแก่น 40320",
    name_en: "Phra Yuen, Nong Waeng, Khon Kaen, 40320",
  },
  {
    id: 400305,
    districtTh: "พระยืน",
    districtEn: "Phra Yuen",
    subdistrictTh: "ขามป้อม",
    subdistrictEn: "Kham Pom",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40320,
    district_code: 4003,
    province_code: 28,
    name: "พระยืน ขามป้อม ขอนแก่น 40320",
    name_th: "พระยืน ขามป้อม ขอนแก่น 40320",
    name_en: "Phra Yuen, Kham Pom, Khon Kaen, 40320",
  },
  {
    id: 400401,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "หนองเรือ",
    subdistrictEn: "Nong Ruea",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40210,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ หนองเรือ ขอนแก่น 40210",
    name_th: "หนองเรือ หนองเรือ ขอนแก่น 40210",
    name_en: "Nong Ruea, Nong Ruea, Khon Kaen, 40210",
  },
  {
    id: 400402,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "บ้านเม็ง",
    subdistrictEn: "Ban Meng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40210,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ บ้านเม็ง ขอนแก่น 40210",
    name_th: "หนองเรือ บ้านเม็ง ขอนแก่น 40210",
    name_en: "Nong Ruea, Ban Meng, Khon Kaen, 40210",
  },
  {
    id: 400403,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "บ้านกง",
    subdistrictEn: "Ban Kong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40240,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ บ้านกง ขอนแก่น 40240",
    name_th: "หนองเรือ บ้านกง ขอนแก่น 40240",
    name_en: "Nong Ruea, Ban Kong, Khon Kaen, 40240",
  },
  {
    id: 400404,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "ยางคำ",
    subdistrictEn: "Yang Kham",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40240,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ ยางคำ ขอนแก่น 40240",
    name_th: "หนองเรือ ยางคำ ขอนแก่น 40240",
    name_en: "Nong Ruea, Yang Kham, Khon Kaen, 40240",
  },
  {
    id: 400405,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "จระเข้",
    subdistrictEn: "Chorakhe",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40240,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ จระเข้ ขอนแก่น 40240",
    name_th: "หนองเรือ จระเข้ ขอนแก่น 40240",
    name_en: "Nong Ruea, Chorakhe, Khon Kaen, 40240",
  },
  {
    id: 400406,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "โนนทอง",
    subdistrictEn: "Non Thong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40210,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ โนนทอง ขอนแก่น 40210",
    name_th: "หนองเรือ โนนทอง ขอนแก่น 40210",
    name_en: "Nong Ruea, Non Thong, Khon Kaen, 40210",
  },
  {
    id: 400407,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "กุดกว้าง",
    subdistrictEn: "Kut Kwang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40210,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ กุดกว้าง ขอนแก่น 40210",
    name_th: "หนองเรือ กุดกว้าง ขอนแก่น 40210",
    name_en: "Nong Ruea, Kut Kwang, Khon Kaen, 40210",
  },
  {
    id: 400408,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "โนนทัน",
    subdistrictEn: "Non Than",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40210,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ โนนทัน ขอนแก่น 40210",
    name_th: "หนองเรือ โนนทัน ขอนแก่น 40210",
    name_en: "Nong Ruea, Non Than, Khon Kaen, 40210",
  },
  {
    id: 400409,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "โนนสะอาด",
    subdistrictEn: "Non Sa-at",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40210,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ โนนสะอาด ขอนแก่น 40210",
    name_th: "หนองเรือ โนนสะอาด ขอนแก่น 40210",
    name_en: "Nong Ruea, Non Sa-at, Khon Kaen, 40210",
  },
  {
    id: 400410,
    districtTh: "หนองเรือ",
    districtEn: "Nong Ruea",
    subdistrictTh: "บ้านผือ",
    subdistrictEn: "Ban Phue",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40240,
    district_code: 4004,
    province_code: 28,
    name: "หนองเรือ บ้านผือ ขอนแก่น 40240",
    name_th: "หนองเรือ บ้านผือ ขอนแก่น 40240",
    name_en: "Nong Ruea, Ban Phue, Khon Kaen, 40240",
  },
  {
    id: 400501,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "ชุมแพ",
    subdistrictEn: "Chum Phae",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40130,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ ชุมแพ ขอนแก่น 40130",
    name_th: "ชุมแพ ชุมแพ ขอนแก่น 40130",
    name_en: "Chum Phae, Chum Phae, Khon Kaen, 40130",
  },
  {
    id: 400502,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "โนนหัน",
    subdistrictEn: "Non Han",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40290,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ โนนหัน ขอนแก่น 40290",
    name_th: "ชุมแพ โนนหัน ขอนแก่น 40290",
    name_en: "Chum Phae, Non Han, Khon Kaen, 40290",
  },
  {
    id: 400503,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "นาหนองทุ่ม",
    subdistrictEn: "Na Nong Thum",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40290,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ นาหนองทุ่ม ขอนแก่น 40290",
    name_th: "ชุมแพ นาหนองทุ่ม ขอนแก่น 40290",
    name_en: "Chum Phae, Na Nong Thum, Khon Kaen, 40290",
  },
  {
    id: 400504,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "โนนอุดม",
    subdistrictEn: "Non Udom",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40130,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ โนนอุดม ขอนแก่น 40130",
    name_th: "ชุมแพ โนนอุดม ขอนแก่น 40130",
    name_en: "Chum Phae, Non Udom, Khon Kaen, 40130",
  },
  {
    id: 400505,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "ขัวเรียง",
    subdistrictEn: "Khua Riang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40130,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ ขัวเรียง ขอนแก่น 40130",
    name_th: "ชุมแพ ขัวเรียง ขอนแก่น 40130",
    name_en: "Chum Phae, Khua Riang, Khon Kaen, 40130",
  },
  {
    id: 400506,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40130,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ หนองไผ่ ขอนแก่น 40130",
    name_th: "ชุมแพ หนองไผ่ ขอนแก่น 40130",
    name_en: "Chum Phae, Nong Phai, Khon Kaen, 40130",
  },
  {
    id: 400507,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "ไชยสอ",
    subdistrictEn: "Chai So",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40130,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ ไชยสอ ขอนแก่น 40130",
    name_th: "ชุมแพ ไชยสอ ขอนแก่น 40130",
    name_en: "Chum Phae, Chai So, Khon Kaen, 40130",
  },
  {
    id: 400508,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "วังหินลาด",
    subdistrictEn: "Wang Hin Lat",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40130,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ วังหินลาด ขอนแก่น 40130",
    name_th: "ชุมแพ วังหินลาด ขอนแก่น 40130",
    name_en: "Chum Phae, Wang Hin Lat, Khon Kaen, 40130",
  },
  {
    id: 400509,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "นาเพียง",
    subdistrictEn: "Na Phiang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40130,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ นาเพียง ขอนแก่น 40130",
    name_th: "ชุมแพ นาเพียง ขอนแก่น 40130",
    name_en: "Chum Phae, Na Phiang, Khon Kaen, 40130",
  },
  {
    id: 400510,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "หนองเขียด",
    subdistrictEn: "Nong Khiat",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40290,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ หนองเขียด ขอนแก่น 40290",
    name_th: "ชุมแพ หนองเขียด ขอนแก่น 40290",
    name_en: "Chum Phae, Nong Khiat, Khon Kaen, 40290",
  },
  {
    id: 400511,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "หนองเสาเล้า",
    subdistrictEn: "Nong Sao Lao",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40130,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ หนองเสาเล้า ขอนแก่น 40130",
    name_th: "ชุมแพ หนองเสาเล้า ขอนแก่น 40130",
    name_en: "Chum Phae, Nong Sao Lao, Khon Kaen, 40130",
  },
  {
    id: 400512,
    districtTh: "ชุมแพ",
    districtEn: "Chum Phae",
    subdistrictTh: "โนนสะอาด",
    subdistrictEn: "Non Sa-at",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40290,
    district_code: 4005,
    province_code: 28,
    name: "ชุมแพ โนนสะอาด ขอนแก่น 40290",
    name_th: "ชุมแพ โนนสะอาด ขอนแก่น 40290",
    name_en: "Chum Phae, Non Sa-at, Khon Kaen, 40290",
  },
  {
    id: 400601,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "สีชมพู",
    subdistrictEn: "Si Chomphu",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู สีชมพู ขอนแก่น 40220",
    name_th: "สีชมพู สีชมพู ขอนแก่น 40220",
    name_en: "Si Chomphu, Si Chomphu, Khon Kaen, 40220",
  },
  {
    id: 400602,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "ศรีสุข",
    subdistrictEn: "Si Suk",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู ศรีสุข ขอนแก่น 40220",
    name_th: "สีชมพู ศรีสุข ขอนแก่น 40220",
    name_en: "Si Chomphu, Si Suk, Khon Kaen, 40220",
  },
  {
    id: 400603,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "นาจาน",
    subdistrictEn: "Na Chan",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู นาจาน ขอนแก่น 40220",
    name_th: "สีชมพู นาจาน ขอนแก่น 40220",
    name_en: "Si Chomphu, Na Chan, Khon Kaen, 40220",
  },
  {
    id: 400604,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "วังเพิ่ม",
    subdistrictEn: "Wang Phoem",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู วังเพิ่ม ขอนแก่น 40220",
    name_th: "สีชมพู วังเพิ่ม ขอนแก่น 40220",
    name_en: "Si Chomphu, Wang Phoem, Khon Kaen, 40220",
  },
  {
    id: 400605,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "ซำยาง",
    subdistrictEn: "Sam Yang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู ซำยาง ขอนแก่น 40220",
    name_th: "สีชมพู ซำยาง ขอนแก่น 40220",
    name_en: "Si Chomphu, Sam Yang, Khon Kaen, 40220",
  },
  {
    id: 400606,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "หนองแดง",
    subdistrictEn: "Nong Daeng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู หนองแดง ขอนแก่น 40220",
    name_th: "สีชมพู หนองแดง ขอนแก่น 40220",
    name_en: "Si Chomphu, Nong Daeng, Khon Kaen, 40220",
  },
  {
    id: 400607,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "ดงลาน",
    subdistrictEn: "Dong Lan",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู ดงลาน ขอนแก่น 40220",
    name_th: "สีชมพู ดงลาน ขอนแก่น 40220",
    name_en: "Si Chomphu, Dong Lan, Khon Kaen, 40220",
  },
  {
    id: 400608,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "บริบูรณ์",
    subdistrictEn: "Boribun",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู บริบูรณ์ ขอนแก่น 40220",
    name_th: "สีชมพู บริบูรณ์ ขอนแก่น 40220",
    name_en: "Si Chomphu, Boribun, Khon Kaen, 40220",
  },
  {
    id: 400609,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู บ้านใหม่ ขอนแก่น 40220",
    name_th: "สีชมพู บ้านใหม่ ขอนแก่น 40220",
    name_en: "Si Chomphu, Ban Mai, Khon Kaen, 40220",
  },
  {
    id: 400610,
    districtTh: "สีชมพู",
    districtEn: "Si Chomphu",
    subdistrictTh: "ภูห่าน",
    subdistrictEn: "Phu Han",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40220,
    district_code: 4006,
    province_code: 28,
    name: "สีชมพู ภูห่าน ขอนแก่น 40220",
    name_th: "สีชมพู ภูห่าน ขอนแก่น 40220",
    name_en: "Si Chomphu, Phu Han, Khon Kaen, 40220",
  },
  {
    id: 400701,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "น้ำพอง",
    subdistrictEn: "Nam Phong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง น้ำพอง ขอนแก่น 40140",
    name_th: "น้ำพอง น้ำพอง ขอนแก่น 40140",
    name_en: "Nam Phong, Nam Phong, Khon Kaen, 40140",
  },
  {
    id: 400702,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "วังชัย",
    subdistrictEn: "Wang Chai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง วังชัย ขอนแก่น 40140",
    name_th: "น้ำพอง วังชัย ขอนแก่น 40140",
    name_en: "Nam Phong, Wang Chai, Khon Kaen, 40140",
  },
  {
    id: 400703,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "หนองกุง",
    subdistrictEn: "Nong Kung",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง หนองกุง ขอนแก่น 40140",
    name_th: "น้ำพอง หนองกุง ขอนแก่น 40140",
    name_en: "Nam Phong, Nong Kung, Khon Kaen, 40140",
  },
  {
    id: 400704,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "บัวใหญ่",
    subdistrictEn: "Bua Yai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง บัวใหญ่ ขอนแก่น 40140",
    name_th: "น้ำพอง บัวใหญ่ ขอนแก่น 40140",
    name_en: "Nam Phong, Bua Yai, Khon Kaen, 40140",
  },
  {
    id: 400705,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "สะอาด",
    subdistrictEn: "Sa-at",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40310,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง สะอาด ขอนแก่น 40310",
    name_th: "น้ำพอง สะอาด ขอนแก่น 40310",
    name_en: "Nam Phong, Sa-at, Khon Kaen, 40310",
  },
  {
    id: 400706,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "ม่วงหวาน",
    subdistrictEn: "Muang Wan",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40310,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง ม่วงหวาน ขอนแก่น 40310",
    name_th: "น้ำพอง ม่วงหวาน ขอนแก่น 40310",
    name_en: "Nam Phong, Muang Wan, Khon Kaen, 40310",
  },
  {
    id: 400707,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "บ้านขาม",
    subdistrictEn: "Ban Kham",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง บ้านขาม ขอนแก่น 40140",
    name_th: "น้ำพอง บ้านขาม ขอนแก่น 40140",
    name_en: "Nam Phong, Ban Kham, Khon Kaen, 40140",
  },
  {
    id: 400708,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "บัวเงิน",
    subdistrictEn: "Bua Ngoen",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง บัวเงิน ขอนแก่น 40140",
    name_th: "น้ำพอง บัวเงิน ขอนแก่น 40140",
    name_en: "Nam Phong, Bua Ngoen, Khon Kaen, 40140",
  },
  {
    id: 400709,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "ทรายมูล",
    subdistrictEn: "Sai Mun",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง ทรายมูล ขอนแก่น 40140",
    name_th: "น้ำพอง ทรายมูล ขอนแก่น 40140",
    name_en: "Nam Phong, Sai Mun, Khon Kaen, 40140",
  },
  {
    id: 400710,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "ท่ากระเสริม",
    subdistrictEn: "Tha Krasoem",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง ท่ากระเสริม ขอนแก่น 40140",
    name_th: "น้ำพอง ท่ากระเสริม ขอนแก่น 40140",
    name_en: "Nam Phong, Tha Krasoem, Khon Kaen, 40140",
  },
  {
    id: 400711,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "พังทุย",
    subdistrictEn: "Phang Thui",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง พังทุย ขอนแก่น 40140",
    name_th: "น้ำพอง พังทุย ขอนแก่น 40140",
    name_en: "Nam Phong, Phang Thui, Khon Kaen, 40140",
  },
  {
    id: 400712,
    districtTh: "น้ำพอง",
    districtEn: "Nam Phong",
    subdistrictTh: "กุดน้ำใส",
    subdistrictEn: "Kut Nam Sai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40140,
    district_code: 4007,
    province_code: 28,
    name: "น้ำพอง กุดน้ำใส ขอนแก่น 40140",
    name_th: "น้ำพอง กุดน้ำใส ขอนแก่น 40140",
    name_en: "Nam Phong, Kut Nam Sai, Khon Kaen, 40140",
  },
  {
    id: 400801,
    districtTh: "อุบลรัตน์",
    districtEn: "Ubolratana",
    subdistrictTh: "โคกสูง",
    subdistrictEn: "Khok Sung",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40250,
    district_code: 4008,
    province_code: 28,
    name: "อุบลรัตน์ โคกสูง ขอนแก่น 40250",
    name_th: "อุบลรัตน์ โคกสูง ขอนแก่น 40250",
    name_en: "Ubolratana, Khok Sung, Khon Kaen, 40250",
  },
  {
    id: 400802,
    districtTh: "อุบลรัตน์",
    districtEn: "Ubolratana",
    subdistrictTh: "บ้านดง",
    subdistrictEn: "Ban Dong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40250,
    district_code: 4008,
    province_code: 28,
    name: "อุบลรัตน์ บ้านดง ขอนแก่น 40250",
    name_th: "อุบลรัตน์ บ้านดง ขอนแก่น 40250",
    name_en: "Ubolratana, Ban Dong, Khon Kaen, 40250",
  },
  {
    id: 400803,
    districtTh: "อุบลรัตน์",
    districtEn: "Ubolratana",
    subdistrictTh: "เขื่อนอุบลรัตน์",
    subdistrictEn: "Khuean Ubolratana",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40250,
    district_code: 4008,
    province_code: 28,
    name: "อุบลรัตน์ เขื่อนอุบลรัตน์ ขอนแก่น 40250",
    name_th: "อุบลรัตน์ เขื่อนอุบลรัตน์ ขอนแก่น 40250",
    name_en: "Ubolratana, Khuean Ubolratana, Khon Kaen, 40250",
  },
  {
    id: 400804,
    districtTh: "อุบลรัตน์",
    districtEn: "Ubolratana",
    subdistrictTh: "นาคำ",
    subdistrictEn: "Na Kham",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40250,
    district_code: 4008,
    province_code: 28,
    name: "อุบลรัตน์ นาคำ ขอนแก่น 40250",
    name_th: "อุบลรัตน์ นาคำ ขอนแก่น 40250",
    name_en: "Ubolratana, Na Kham, Khon Kaen, 40250",
  },
  {
    id: 400805,
    districtTh: "อุบลรัตน์",
    districtEn: "Ubolratana",
    subdistrictTh: "ศรีสุขสำราญ",
    subdistrictEn: "Si Suk Samran",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40250,
    district_code: 4008,
    province_code: 28,
    name: "อุบลรัตน์ ศรีสุขสำราญ ขอนแก่น 40250",
    name_th: "อุบลรัตน์ ศรีสุขสำราญ ขอนแก่น 40250",
    name_en: "Ubolratana, Si Suk Samran, Khon Kaen, 40250",
  },
  {
    id: 400806,
    districtTh: "อุบลรัตน์",
    districtEn: "Ubolratana",
    subdistrictTh: "ทุ่งโป่ง",
    subdistrictEn: "Thung Pong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40250,
    district_code: 4008,
    province_code: 28,
    name: "อุบลรัตน์ ทุ่งโป่ง ขอนแก่น 40250",
    name_th: "อุบลรัตน์ ทุ่งโป่ง ขอนแก่น 40250",
    name_en: "Ubolratana, Thung Pong, Khon Kaen, 40250",
  },
  {
    id: 400901,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "หนองโก",
    subdistrictEn: "Nong Ko",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน หนองโก ขอนแก่น 40170",
    name_th: "กระนวน หนองโก ขอนแก่น 40170",
    name_en: "Kranuan, Nong Ko, Khon Kaen, 40170",
  },
  {
    id: 400902,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "หนองกุงใหญ่",
    subdistrictEn: "Nong Kung Yai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน หนองกุงใหญ่ ขอนแก่น 40170",
    name_th: "กระนวน หนองกุงใหญ่ ขอนแก่น 40170",
    name_en: "Kranuan, Nong Kung Yai, Khon Kaen, 40170",
  },
  {
    id: 400905,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "ห้วยโจด",
    subdistrictEn: "Huai Chot",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน ห้วยโจด ขอนแก่น 40170",
    name_th: "กระนวน ห้วยโจด ขอนแก่น 40170",
    name_en: "Kranuan, Huai Chot, Khon Kaen, 40170",
  },
  {
    id: 400906,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "ห้วยยาง",
    subdistrictEn: "Huai Yang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน ห้วยยาง ขอนแก่น 40170",
    name_th: "กระนวน ห้วยยาง ขอนแก่น 40170",
    name_en: "Kranuan, Huai Yang, Khon Kaen, 40170",
  },
  {
    id: 400907,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "บ้านฝาง",
    subdistrictEn: "Ban Fang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน บ้านฝาง ขอนแก่น 40170",
    name_th: "กระนวน บ้านฝาง ขอนแก่น 40170",
    name_en: "Kranuan, Ban Fang, Khon Kaen, 40170",
  },
  {
    id: 400909,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "ดูนสาด",
    subdistrictEn: "Dun Sat",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน ดูนสาด ขอนแก่น 40170",
    name_th: "กระนวน ดูนสาด ขอนแก่น 40170",
    name_en: "Kranuan, Dun Sat, Khon Kaen, 40170",
  },
  {
    id: 400910,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "หนองโน",
    subdistrictEn: "Nong No",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน หนองโน ขอนแก่น 40170",
    name_th: "กระนวน หนองโน ขอนแก่น 40170",
    name_en: "Kranuan, Nong No, Khon Kaen, 40170",
  },
  {
    id: 400911,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "น้ำอ้อม",
    subdistrictEn: "Nam Om",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน น้ำอ้อม ขอนแก่น 40170",
    name_th: "กระนวน น้ำอ้อม ขอนแก่น 40170",
    name_en: "Kranuan, Nam Om, Khon Kaen, 40170",
  },
  {
    id: 400912,
    districtTh: "กระนวน",
    districtEn: "Kranuan",
    subdistrictTh: "หัวนาคำ",
    subdistrictEn: "Hua Na Kham",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4009,
    province_code: 28,
    name: "กระนวน หัวนาคำ ขอนแก่น 40170",
    name_th: "กระนวน หัวนาคำ ขอนแก่น 40170",
    name_en: "Kranuan, Hua Na Kham, Khon Kaen, 40170",
  },
  {
    id: 401001,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "บ้านไผ่",
    subdistrictEn: "Ban Phai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ บ้านไผ่ ขอนแก่น 40110",
    name_th: "บ้านไผ่ บ้านไผ่ ขอนแก่น 40110",
    name_en: "Ban Phai, Ban Phai, Khon Kaen, 40110",
  },
  {
    id: 401002,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ ในเมือง ขอนแก่น 40110",
    name_th: "บ้านไผ่ ในเมือง ขอนแก่น 40110",
    name_en: "Ban Phai, Nai Mueang, Khon Kaen, 40110",
  },
  {
    id: 401005,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "เมืองเพีย",
    subdistrictEn: "Mueang Phia",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ เมืองเพีย ขอนแก่น 40110",
    name_th: "บ้านไผ่ เมืองเพีย ขอนแก่น 40110",
    name_en: "Ban Phai, Mueang Phia, Khon Kaen, 40110",
  },
  {
    id: 401009,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "บ้านลาน",
    subdistrictEn: "Ban Lan",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ บ้านลาน ขอนแก่น 40110",
    name_th: "บ้านไผ่ บ้านลาน ขอนแก่น 40110",
    name_en: "Ban Phai, Ban Lan, Khon Kaen, 40110",
  },
  {
    id: 401010,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "แคนเหนือ",
    subdistrictEn: "Khaen Nuea",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ แคนเหนือ ขอนแก่น 40110",
    name_th: "บ้านไผ่ แคนเหนือ ขอนแก่น 40110",
    name_en: "Ban Phai, Khaen Nuea, Khon Kaen, 40110",
  },
  {
    id: 401011,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "ภูเหล็ก",
    subdistrictEn: "Phu Lek",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ ภูเหล็ก ขอนแก่น 40110",
    name_th: "บ้านไผ่ ภูเหล็ก ขอนแก่น 40110",
    name_en: "Ban Phai, Phu Lek, Khon Kaen, 40110",
  },
  {
    id: 401013,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "ป่าปอ",
    subdistrictEn: "Pa Po",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ ป่าปอ ขอนแก่น 40110",
    name_th: "บ้านไผ่ ป่าปอ ขอนแก่น 40110",
    name_en: "Ban Phai, Pa Po, Khon Kaen, 40110",
  },
  {
    id: 401014,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "หินตั้ง",
    subdistrictEn: "Hin Tang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ หินตั้ง ขอนแก่น 40110",
    name_th: "บ้านไผ่ หินตั้ง ขอนแก่น 40110",
    name_en: "Ban Phai, Hin Tang, Khon Kaen, 40110",
  },
  {
    id: 401016,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "หนองน้ำใส",
    subdistrictEn: "Nong Nam Sai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ หนองน้ำใส ขอนแก่น 40110",
    name_th: "บ้านไผ่ หนองน้ำใส ขอนแก่น 40110",
    name_en: "Ban Phai, Nong Nam Sai, Khon Kaen, 40110",
  },
  {
    id: 401017,
    districtTh: "บ้านไผ่",
    districtEn: "Ban Phai",
    subdistrictTh: "หัวหนอง",
    subdistrictEn: "Hua Nong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4010,
    province_code: 28,
    name: "บ้านไผ่ หัวหนอง ขอนแก่น 40110",
    name_th: "บ้านไผ่ หัวหนอง ขอนแก่น 40110",
    name_en: "Ban Phai, Hua Nong, Khon Kaen, 40110",
  },
  {
    id: 401101,
    districtTh: "เปือยน้อย",
    districtEn: "Pueai Noi",
    subdistrictTh: "เปือยน้อย",
    subdistrictEn: "Pueai Noi",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40340,
    district_code: 4011,
    province_code: 28,
    name: "เปือยน้อย เปือยน้อย ขอนแก่น 40340",
    name_th: "เปือยน้อย เปือยน้อย ขอนแก่น 40340",
    name_en: "Pueai Noi, Pueai Noi, Khon Kaen, 40340",
  },
  {
    id: 401102,
    districtTh: "เปือยน้อย",
    districtEn: "Pueai Noi",
    subdistrictTh: "วังม่วง",
    subdistrictEn: "Wang Muang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40340,
    district_code: 4011,
    province_code: 28,
    name: "เปือยน้อย วังม่วง ขอนแก่น 40340",
    name_th: "เปือยน้อย วังม่วง ขอนแก่น 40340",
    name_en: "Pueai Noi, Wang Muang, Khon Kaen, 40340",
  },
  {
    id: 401103,
    districtTh: "เปือยน้อย",
    districtEn: "Pueai Noi",
    subdistrictTh: "ขามป้อม",
    subdistrictEn: "Kham Pom",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40340,
    district_code: 4011,
    province_code: 28,
    name: "เปือยน้อย ขามป้อม ขอนแก่น 40340",
    name_th: "เปือยน้อย ขามป้อม ขอนแก่น 40340",
    name_en: "Pueai Noi, Kham Pom, Khon Kaen, 40340",
  },
  {
    id: 401104,
    districtTh: "เปือยน้อย",
    districtEn: "Pueai Noi",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40340,
    district_code: 4011,
    province_code: 28,
    name: "เปือยน้อย สระแก้ว ขอนแก่น 40340",
    name_th: "เปือยน้อย สระแก้ว ขอนแก่น 40340",
    name_en: "Pueai Noi, Sa Kaeo, Khon Kaen, 40340",
  },
  {
    id: 401201,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "เมืองพล",
    subdistrictEn: "Mueang Phon",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล เมืองพล ขอนแก่น 40120",
    name_th: "พล เมืองพล ขอนแก่น 40120",
    name_en: "Phon, Mueang Phon, Khon Kaen, 40120",
  },
  {
    id: 401203,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "โจดหนองแก",
    subdistrictEn: "Chot Nong Kae",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล โจดหนองแก ขอนแก่น 40120",
    name_th: "พล โจดหนองแก ขอนแก่น 40120",
    name_en: "Phon, Chot Nong Kae, Khon Kaen, 40120",
  },
  {
    id: 401204,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "เก่างิ้ว",
    subdistrictEn: "Kao Ngio",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล เก่างิ้ว ขอนแก่น 40120",
    name_th: "พล เก่างิ้ว ขอนแก่น 40120",
    name_en: "Phon, Kao Ngio, Khon Kaen, 40120",
  },
  {
    id: 401205,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "หนองมะเขือ",
    subdistrictEn: "Nong Makhuea",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล หนองมะเขือ ขอนแก่น 40120",
    name_th: "พล หนองมะเขือ ขอนแก่น 40120",
    name_en: "Phon, Nong Makhuea, Khon Kaen, 40120",
  },
  {
    id: 401206,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "หนองแวงโสกพระ",
    subdistrictEn: "Nong Waeng Sok Phra",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล หนองแวงโสกพระ ขอนแก่น 40120",
    name_th: "พล หนองแวงโสกพระ ขอนแก่น 40120",
    name_en: "Phon, Nong Waeng Sok Phra, Khon Kaen, 40120",
  },
  {
    id: 401207,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "เพ็กใหญ่",
    subdistrictEn: "Phek Yai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล เพ็กใหญ่ ขอนแก่น 40120",
    name_th: "พล เพ็กใหญ่ ขอนแก่น 40120",
    name_en: "Phon, Phek Yai, Khon Kaen, 40120",
  },
  {
    id: 401208,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "โคกสง่า",
    subdistrictEn: "Khok Sa-nga",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล โคกสง่า ขอนแก่น 40120",
    name_th: "พล โคกสง่า ขอนแก่น 40120",
    name_en: "Phon, Khok Sa-nga, Khon Kaen, 40120",
  },
  {
    id: 401209,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "หนองแวงนางเบ้า",
    subdistrictEn: "Nong Waeng Nang Bao",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล หนองแวงนางเบ้า ขอนแก่น 40120",
    name_th: "พล หนองแวงนางเบ้า ขอนแก่น 40120",
    name_en: "Phon, Nong Waeng Nang Bao, Khon Kaen, 40120",
  },
  {
    id: 401210,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "ลอมคอม",
    subdistrictEn: "Lom Khom",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล ลอมคอม ขอนแก่น 40120",
    name_th: "พล ลอมคอม ขอนแก่น 40120",
    name_en: "Phon, Lom Khom, Khon Kaen, 40120",
  },
  {
    id: 401211,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "โนนข่า",
    subdistrictEn: "Non Kha",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล โนนข่า ขอนแก่น 40120",
    name_th: "พล โนนข่า ขอนแก่น 40120",
    name_en: "Phon, Non Kha, Khon Kaen, 40120",
  },
  {
    id: 401212,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "โสกนกเต็น",
    subdistrictEn: "Sok Nok Ten",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล โสกนกเต็น ขอนแก่น 40120",
    name_th: "พล โสกนกเต็น ขอนแก่น 40120",
    name_en: "Phon, Sok Nok Ten, Khon Kaen, 40120",
  },
  {
    id: 401213,
    districtTh: "พล",
    districtEn: "Phon",
    subdistrictTh: "หัวทุ่ง",
    subdistrictEn: "Hua Thung",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40120,
    district_code: 4012,
    province_code: 28,
    name: "พล หัวทุ่ง ขอนแก่น 40120",
    name_th: "พล หัวทุ่ง ขอนแก่น 40120",
    name_en: "Phon, Hua Thung, Khon Kaen, 40120",
  },
  {
    id: 401301,
    districtTh: "แวงใหญ่",
    districtEn: "Waeng Yai",
    subdistrictTh: "คอนฉิม",
    subdistrictEn: "Khon Chim",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40330,
    district_code: 4013,
    province_code: 28,
    name: "แวงใหญ่ คอนฉิม ขอนแก่น 40330",
    name_th: "แวงใหญ่ คอนฉิม ขอนแก่น 40330",
    name_en: "Waeng Yai, Khon Chim, Khon Kaen, 40330",
  },
  {
    id: 401302,
    districtTh: "แวงใหญ่",
    districtEn: "Waeng Yai",
    subdistrictTh: "ใหม่นาเพียง",
    subdistrictEn: "Mai Na Phiang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40330,
    district_code: 4013,
    province_code: 28,
    name: "แวงใหญ่ ใหม่นาเพียง ขอนแก่น 40330",
    name_th: "แวงใหญ่ ใหม่นาเพียง ขอนแก่น 40330",
    name_en: "Waeng Yai, Mai Na Phiang, Khon Kaen, 40330",
  },
  {
    id: 401303,
    districtTh: "แวงใหญ่",
    districtEn: "Waeng Yai",
    subdistrictTh: "โนนทอง",
    subdistrictEn: "Non Thong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40330,
    district_code: 4013,
    province_code: 28,
    name: "แวงใหญ่ โนนทอง ขอนแก่น 40330",
    name_th: "แวงใหญ่ โนนทอง ขอนแก่น 40330",
    name_en: "Waeng Yai, Non Thong, Khon Kaen, 40330",
  },
  {
    id: 401304,
    districtTh: "แวงใหญ่",
    districtEn: "Waeng Yai",
    subdistrictTh: "แวงใหญ่",
    subdistrictEn: "Waeng Yai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40330,
    district_code: 4013,
    province_code: 28,
    name: "แวงใหญ่ แวงใหญ่ ขอนแก่น 40330",
    name_th: "แวงใหญ่ แวงใหญ่ ขอนแก่น 40330",
    name_en: "Waeng Yai, Waeng Yai, Khon Kaen, 40330",
  },
  {
    id: 401305,
    districtTh: "แวงใหญ่",
    districtEn: "Waeng Yai",
    subdistrictTh: "โนนสะอาด",
    subdistrictEn: "Non Sa-at",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40330,
    district_code: 4013,
    province_code: 28,
    name: "แวงใหญ่ โนนสะอาด ขอนแก่น 40330",
    name_th: "แวงใหญ่ โนนสะอาด ขอนแก่น 40330",
    name_en: "Waeng Yai, Non Sa-at, Khon Kaen, 40330",
  },
  {
    id: 401401,
    districtTh: "แวงน้อย",
    districtEn: "Waeng Noi",
    subdistrictTh: "แวงน้อย",
    subdistrictEn: "Waeng Noi",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40230,
    district_code: 4014,
    province_code: 28,
    name: "แวงน้อย แวงน้อย ขอนแก่น 40230",
    name_th: "แวงน้อย แวงน้อย ขอนแก่น 40230",
    name_en: "Waeng Noi, Waeng Noi, Khon Kaen, 40230",
  },
  {
    id: 401402,
    districtTh: "แวงน้อย",
    districtEn: "Waeng Noi",
    subdistrictTh: "ก้านเหลือง",
    subdistrictEn: "Kan Lueang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40230,
    district_code: 4014,
    province_code: 28,
    name: "แวงน้อย ก้านเหลือง ขอนแก่น 40230",
    name_th: "แวงน้อย ก้านเหลือง ขอนแก่น 40230",
    name_en: "Waeng Noi, Kan Lueang, Khon Kaen, 40230",
  },
  {
    id: 401403,
    districtTh: "แวงน้อย",
    districtEn: "Waeng Noi",
    subdistrictTh: "ท่านางแนว",
    subdistrictEn: "Tha Nang Naeo",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40230,
    district_code: 4014,
    province_code: 28,
    name: "แวงน้อย ท่านางแนว ขอนแก่น 40230",
    name_th: "แวงน้อย ท่านางแนว ขอนแก่น 40230",
    name_en: "Waeng Noi, Tha Nang Naeo, Khon Kaen, 40230",
  },
  {
    id: 401404,
    districtTh: "แวงน้อย",
    districtEn: "Waeng Noi",
    subdistrictTh: "ละหานนา",
    subdistrictEn: "Lahan Na",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40230,
    district_code: 4014,
    province_code: 28,
    name: "แวงน้อย ละหานนา ขอนแก่น 40230",
    name_th: "แวงน้อย ละหานนา ขอนแก่น 40230",
    name_en: "Waeng Noi, Lahan Na, Khon Kaen, 40230",
  },
  {
    id: 401405,
    districtTh: "แวงน้อย",
    districtEn: "Waeng Noi",
    subdistrictTh: "ท่าวัด",
    subdistrictEn: "Tha Wat",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40230,
    district_code: 4014,
    province_code: 28,
    name: "แวงน้อย ท่าวัด ขอนแก่น 40230",
    name_th: "แวงน้อย ท่าวัด ขอนแก่น 40230",
    name_en: "Waeng Noi, Tha Wat, Khon Kaen, 40230",
  },
  {
    id: 401406,
    districtTh: "แวงน้อย",
    districtEn: "Waeng Noi",
    subdistrictTh: "ทางขวาง",
    subdistrictEn: "Thang Khwang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40230,
    district_code: 4014,
    province_code: 28,
    name: "แวงน้อย ทางขวาง ขอนแก่น 40230",
    name_th: "แวงน้อย ทางขวาง ขอนแก่น 40230",
    name_en: "Waeng Noi, Thang Khwang, Khon Kaen, 40230",
  },
  {
    id: 401501,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "หนองสองห้อง",
    subdistrictEn: "Nong Song Hong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง หนองสองห้อง ขอนแก่น 40190",
    name_th: "หนองสองห้อง หนองสองห้อง ขอนแก่น 40190",
    name_en: "Nong Song Hong, Nong Song Hong, Khon Kaen, 40190",
  },
  {
    id: 401502,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "คึมชาด",
    subdistrictEn: "Khuemchat",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง คึมชาด ขอนแก่น 40190",
    name_th: "หนองสองห้อง คึมชาด ขอนแก่น 40190",
    name_en: "Nong Song Hong, Khuemchat, Khon Kaen, 40190",
  },
  {
    id: 401503,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "โนนธาตุ",
    subdistrictEn: "Non That",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง โนนธาตุ ขอนแก่น 40190",
    name_th: "หนองสองห้อง โนนธาตุ ขอนแก่น 40190",
    name_en: "Nong Song Hong, Non That, Khon Kaen, 40190",
  },
  {
    id: 401504,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "ตะกั่วป่า",
    subdistrictEn: "Takua Pa",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง ตะกั่วป่า ขอนแก่น 40190",
    name_th: "หนองสองห้อง ตะกั่วป่า ขอนแก่น 40190",
    name_en: "Nong Song Hong, Takua Pa, Khon Kaen, 40190",
  },
  {
    id: 401505,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง สำโรง ขอนแก่น 40190",
    name_th: "หนองสองห้อง สำโรง ขอนแก่น 40190",
    name_en: "Nong Song Hong, Samrong, Khon Kaen, 40190",
  },
  {
    id: 401506,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "หนองเม็ก",
    subdistrictEn: "Nong Mek",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง หนองเม็ก ขอนแก่น 40190",
    name_th: "หนองสองห้อง หนองเม็ก ขอนแก่น 40190",
    name_en: "Nong Song Hong, Nong Mek, Khon Kaen, 40190",
  },
  {
    id: 401507,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "ดอนดู่",
    subdistrictEn: "Don Du",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง ดอนดู่ ขอนแก่น 40190",
    name_th: "หนองสองห้อง ดอนดู่ ขอนแก่น 40190",
    name_en: "Nong Song Hong, Don Du, Khon Kaen, 40190",
  },
  {
    id: 401508,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "ดงเค็ง",
    subdistrictEn: "Dong Kheng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง ดงเค็ง ขอนแก่น 40190",
    name_th: "หนองสองห้อง ดงเค็ง ขอนแก่น 40190",
    name_en: "Nong Song Hong, Dong Kheng, Khon Kaen, 40190",
  },
  {
    id: 401509,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "หันโจด",
    subdistrictEn: "Han Chot",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง หันโจด ขอนแก่น 40190",
    name_th: "หนองสองห้อง หันโจด ขอนแก่น 40190",
    name_en: "Nong Song Hong, Han Chot, Khon Kaen, 40190",
  },
  {
    id: 401510,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "ดอนดั่ง",
    subdistrictEn: "Don Dang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง ดอนดั่ง ขอนแก่น 40190",
    name_th: "หนองสองห้อง ดอนดั่ง ขอนแก่น 40190",
    name_en: "Nong Song Hong, Don Dang, Khon Kaen, 40190",
  },
  {
    id: 401511,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "วังหิน",
    subdistrictEn: "Wang Hin",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง วังหิน ขอนแก่น 40190",
    name_th: "หนองสองห้อง วังหิน ขอนแก่น 40190",
    name_en: "Nong Song Hong, Wang Hin, Khon Kaen, 40190",
  },
  {
    id: 401512,
    districtTh: "หนองสองห้อง",
    districtEn: "Nong Song Hong",
    subdistrictTh: "หนองไผ่ล้อม",
    subdistrictEn: "Nong Phai Lom",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40190,
    district_code: 4015,
    province_code: 28,
    name: "หนองสองห้อง หนองไผ่ล้อม ขอนแก่น 40190",
    name_th: "หนองสองห้อง หนองไผ่ล้อม ขอนแก่น 40190",
    name_en: "Nong Song Hong, Nong Phai Lom, Khon Kaen, 40190",
  },
  {
    id: 401601,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "บ้านเรือ",
    subdistrictEn: "Ban Ruea",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง บ้านเรือ ขอนแก่น 40150",
    name_th: "ภูเวียง บ้านเรือ ขอนแก่น 40150",
    name_en: "Phu Wiang, Ban Ruea, Khon Kaen, 40150",
  },
  {
    id: 401604,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "หว้าทอง",
    subdistrictEn: "Wa Thong",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง หว้าทอง ขอนแก่น 40150",
    name_th: "ภูเวียง หว้าทอง ขอนแก่น 40150",
    name_en: "Phu Wiang, Wa Thong, Khon Kaen, 40150",
  },
  {
    id: 401605,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "กุดขอนแก่น",
    subdistrictEn: "Kut Khon Kaen",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง กุดขอนแก่น ขอนแก่น 40150",
    name_th: "ภูเวียง กุดขอนแก่น ขอนแก่น 40150",
    name_en: "Phu Wiang, Kut Khon Kaen, Khon Kaen, 40150",
  },
  {
    id: 401606,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "นาชุมแสง",
    subdistrictEn: "Na Chum Saeng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง นาชุมแสง ขอนแก่น 40150",
    name_th: "ภูเวียง นาชุมแสง ขอนแก่น 40150",
    name_en: "Phu Wiang, Na Chum Saeng, Khon Kaen, 40150",
  },
  {
    id: 401607,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "นาหว้า",
    subdistrictEn: "Na Wa",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง นาหว้า ขอนแก่น 40150",
    name_th: "ภูเวียง นาหว้า ขอนแก่น 40150",
    name_en: "Phu Wiang, Na Wa, Khon Kaen, 40150",
  },
  {
    id: 401610,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "หนองกุงธนสาร",
    subdistrictEn: "Nong Kung Thanasan",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง หนองกุงธนสาร ขอนแก่น 40150",
    name_th: "ภูเวียง หนองกุงธนสาร ขอนแก่น 40150",
    name_en: "Phu Wiang, Nong Kung Thanasan, Khon Kaen, 40150",
  },
  {
    id: 401612,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "หนองกุงเซิน",
    subdistrictEn: "Nong Kung Soen",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง หนองกุงเซิน ขอนแก่น 40150",
    name_th: "ภูเวียง หนองกุงเซิน ขอนแก่น 40150",
    name_en: "Phu Wiang, Nong Kung Soen, Khon Kaen, 40150",
  },
  {
    id: 401613,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "สงเปือย",
    subdistrictEn: "Song Pueai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง สงเปือย ขอนแก่น 40150",
    name_th: "ภูเวียง สงเปือย ขอนแก่น 40150",
    name_en: "Phu Wiang, Song Pueai, Khon Kaen, 40150",
  },
  {
    id: 401614,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "ทุ่งชมพู",
    subdistrictEn: "Thung Chomphu",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง ทุ่งชมพู ขอนแก่น 40150",
    name_th: "ภูเวียง ทุ่งชมพู ขอนแก่น 40150",
    name_en: "Phu Wiang, Thung Chomphu, Khon Kaen, 40150",
  },
  {
    id: 401616,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "ดินดำ",
    subdistrictEn: "Din Dam",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง ดินดำ ขอนแก่น 40150",
    name_th: "ภูเวียง ดินดำ ขอนแก่น 40150",
    name_en: "Phu Wiang, Din Dam, Khon Kaen, 40150",
  },
  {
    id: 401617,
    districtTh: "ภูเวียง",
    districtEn: "Phu Wiang",
    subdistrictTh: "ภูเวียง",
    subdistrictEn: "Phu Wiang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4016,
    province_code: 28,
    name: "ภูเวียง ภูเวียง ขอนแก่น 40150",
    name_th: "ภูเวียง ภูเวียง ขอนแก่น 40150",
    name_en: "Phu Wiang, Phu Wiang, Khon Kaen, 40150",
  },
  {
    id: 401701,
    districtTh: "มัญจาคีรี",
    districtEn: "Mancha Khiri",
    subdistrictTh: "กุดเค้า",
    subdistrictEn: "Kut Khao",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4017,
    province_code: 28,
    name: "มัญจาคีรี กุดเค้า ขอนแก่น 40160",
    name_th: "มัญจาคีรี กุดเค้า ขอนแก่น 40160",
    name_en: "Mancha Khiri, Kut Khao, Khon Kaen, 40160",
  },
  {
    id: 401702,
    districtTh: "มัญจาคีรี",
    districtEn: "Mancha Khiri",
    subdistrictTh: "สวนหม่อน",
    subdistrictEn: "Suan Mon",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4017,
    province_code: 28,
    name: "มัญจาคีรี สวนหม่อน ขอนแก่น 40160",
    name_th: "มัญจาคีรี สวนหม่อน ขอนแก่น 40160",
    name_en: "Mancha Khiri, Suan Mon, Khon Kaen, 40160",
  },
  {
    id: 401703,
    districtTh: "มัญจาคีรี",
    districtEn: "Mancha Khiri",
    subdistrictTh: "หนองแปน",
    subdistrictEn: "Nong Paen",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4017,
    province_code: 28,
    name: "มัญจาคีรี หนองแปน ขอนแก่น 40160",
    name_th: "มัญจาคีรี หนองแปน ขอนแก่น 40160",
    name_en: "Mancha Khiri, Nong Paen, Khon Kaen, 40160",
  },
  {
    id: 401704,
    districtTh: "มัญจาคีรี",
    districtEn: "Mancha Khiri",
    subdistrictTh: "โพนเพ็ก",
    subdistrictEn: "Phon Phek",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4017,
    province_code: 28,
    name: "มัญจาคีรี โพนเพ็ก ขอนแก่น 40160",
    name_th: "มัญจาคีรี โพนเพ็ก ขอนแก่น 40160",
    name_en: "Mancha Khiri, Phon Phek, Khon Kaen, 40160",
  },
  {
    id: 401705,
    districtTh: "มัญจาคีรี",
    districtEn: "Mancha Khiri",
    subdistrictTh: "คำแคน",
    subdistrictEn: "Kham Khaen",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4017,
    province_code: 28,
    name: "มัญจาคีรี คำแคน ขอนแก่น 40160",
    name_th: "มัญจาคีรี คำแคน ขอนแก่น 40160",
    name_en: "Mancha Khiri, Kham Khaen, Khon Kaen, 40160",
  },
  {
    id: 401706,
    districtTh: "มัญจาคีรี",
    districtEn: "Mancha Khiri",
    subdistrictTh: "นาข่า",
    subdistrictEn: "Na Kha",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4017,
    province_code: 28,
    name: "มัญจาคีรี นาข่า ขอนแก่น 40160",
    name_th: "มัญจาคีรี นาข่า ขอนแก่น 40160",
    name_en: "Mancha Khiri, Na Kha, Khon Kaen, 40160",
  },
  {
    id: 401707,
    districtTh: "มัญจาคีรี",
    districtEn: "Mancha Khiri",
    subdistrictTh: "นางาม",
    subdistrictEn: "Na Ngam",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4017,
    province_code: 28,
    name: "มัญจาคีรี นางาม ขอนแก่น 40160",
    name_th: "มัญจาคีรี นางาม ขอนแก่น 40160",
    name_en: "Mancha Khiri, Na Ngam, Khon Kaen, 40160",
  },
  {
    id: 401710,
    districtTh: "มัญจาคีรี",
    districtEn: "Mancha Khiri",
    subdistrictTh: "ท่าศาลา",
    subdistrictEn: "Tha Sala",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4017,
    province_code: 28,
    name: "มัญจาคีรี ท่าศาลา ขอนแก่น 40160",
    name_th: "มัญจาคีรี ท่าศาลา ขอนแก่น 40160",
    name_en: "Mancha Khiri, Tha Sala, Khon Kaen, 40160",
  },
  {
    id: 401801,
    districtTh: "ชนบท",
    districtEn: "Chonnabot",
    subdistrictTh: "ชนบท",
    subdistrictEn: "Chonnabot",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40180,
    district_code: 4018,
    province_code: 28,
    name: "ชนบท ชนบท ขอนแก่น 40180",
    name_th: "ชนบท ชนบท ขอนแก่น 40180",
    name_en: "Chonnabot, Chonnabot, Khon Kaen, 40180",
  },
  {
    id: 401802,
    districtTh: "ชนบท",
    districtEn: "Chonnabot",
    subdistrictTh: "กุดเพียขอม",
    subdistrictEn: "Kut Phia Khom",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40180,
    district_code: 4018,
    province_code: 28,
    name: "ชนบท กุดเพียขอม ขอนแก่น 40180",
    name_th: "ชนบท กุดเพียขอม ขอนแก่น 40180",
    name_en: "Chonnabot, Kut Phia Khom, Khon Kaen, 40180",
  },
  {
    id: 401803,
    districtTh: "ชนบท",
    districtEn: "Chonnabot",
    subdistrictTh: "วังแสง",
    subdistrictEn: "Wang Saeng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40180,
    district_code: 4018,
    province_code: 28,
    name: "ชนบท วังแสง ขอนแก่น 40180",
    name_th: "ชนบท วังแสง ขอนแก่น 40180",
    name_en: "Chonnabot, Wang Saeng, Khon Kaen, 40180",
  },
  {
    id: 401804,
    districtTh: "ชนบท",
    districtEn: "Chonnabot",
    subdistrictTh: "ห้วยแก",
    subdistrictEn: "Huai Kae",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40180,
    district_code: 4018,
    province_code: 28,
    name: "ชนบท ห้วยแก ขอนแก่น 40180",
    name_th: "ชนบท ห้วยแก ขอนแก่น 40180",
    name_en: "Chonnabot, Huai Kae, Khon Kaen, 40180",
  },
  {
    id: 401805,
    districtTh: "ชนบท",
    districtEn: "Chonnabot",
    subdistrictTh: "บ้านแท่น",
    subdistrictEn: "Ban Thaen",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40180,
    district_code: 4018,
    province_code: 28,
    name: "ชนบท บ้านแท่น ขอนแก่น 40180",
    name_th: "ชนบท บ้านแท่น ขอนแก่น 40180",
    name_en: "Chonnabot, Ban Thaen, Khon Kaen, 40180",
  },
  {
    id: 401806,
    districtTh: "ชนบท",
    districtEn: "Chonnabot",
    subdistrictTh: "ศรีบุญเรือง",
    subdistrictEn: "Si Bun Rueang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40180,
    district_code: 4018,
    province_code: 28,
    name: "ชนบท ศรีบุญเรือง ขอนแก่น 40180",
    name_th: "ชนบท ศรีบุญเรือง ขอนแก่น 40180",
    name_en: "Chonnabot, Si Bun Rueang, Khon Kaen, 40180",
  },
  {
    id: 401807,
    districtTh: "ชนบท",
    districtEn: "Chonnabot",
    subdistrictTh: "โนนพะยอม",
    subdistrictEn: "Non Phayom",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40180,
    district_code: 4018,
    province_code: 28,
    name: "ชนบท โนนพะยอม ขอนแก่น 40180",
    name_th: "ชนบท โนนพะยอม ขอนแก่น 40180",
    name_en: "Chonnabot, Non Phayom, Khon Kaen, 40180",
  },
  {
    id: 401808,
    districtTh: "ชนบท",
    districtEn: "Chonnabot",
    subdistrictTh: "ปอแดง",
    subdistrictEn: "Po Daeng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40180,
    district_code: 4018,
    province_code: 28,
    name: "ชนบท ปอแดง ขอนแก่น 40180",
    name_th: "ชนบท ปอแดง ขอนแก่น 40180",
    name_en: "Chonnabot, Po Daeng, Khon Kaen, 40180",
  },
  {
    id: 401901,
    districtTh: "เขาสวนกวาง",
    districtEn: "Khao Suan Kwang",
    subdistrictTh: "เขาสวนกวาง",
    subdistrictEn: "Khao Suan Kwang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40280,
    district_code: 4019,
    province_code: 28,
    name: "เขาสวนกวาง เขาสวนกวาง ขอนแก่น 40280",
    name_th: "เขาสวนกวาง เขาสวนกวาง ขอนแก่น 40280",
    name_en: "Khao Suan Kwang, Khao Suan Kwang, Khon Kaen, 40280",
  },
  {
    id: 401902,
    districtTh: "เขาสวนกวาง",
    districtEn: "Khao Suan Kwang",
    subdistrictTh: "ดงเมืองแอม",
    subdistrictEn: "Dong Mueang Aem",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40280,
    district_code: 4019,
    province_code: 28,
    name: "เขาสวนกวาง ดงเมืองแอม ขอนแก่น 40280",
    name_th: "เขาสวนกวาง ดงเมืองแอม ขอนแก่น 40280",
    name_en: "Khao Suan Kwang, Dong Mueang Aem, Khon Kaen, 40280",
  },
  {
    id: 401903,
    districtTh: "เขาสวนกวาง",
    districtEn: "Khao Suan Kwang",
    subdistrictTh: "นางิ้ว",
    subdistrictEn: "Na Ngio",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40280,
    district_code: 4019,
    province_code: 28,
    name: "เขาสวนกวาง นางิ้ว ขอนแก่น 40280",
    name_th: "เขาสวนกวาง นางิ้ว ขอนแก่น 40280",
    name_en: "Khao Suan Kwang, Na Ngio, Khon Kaen, 40280",
  },
  {
    id: 401904,
    districtTh: "เขาสวนกวาง",
    districtEn: "Khao Suan Kwang",
    subdistrictTh: "โนนสมบูรณ์",
    subdistrictEn: "Non Sombun",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40280,
    district_code: 4019,
    province_code: 28,
    name: "เขาสวนกวาง โนนสมบูรณ์ ขอนแก่น 40280",
    name_th: "เขาสวนกวาง โนนสมบูรณ์ ขอนแก่น 40280",
    name_en: "Khao Suan Kwang, Non Sombun, Khon Kaen, 40280",
  },
  {
    id: 401905,
    districtTh: "เขาสวนกวาง",
    districtEn: "Khao Suan Kwang",
    subdistrictTh: "คำม่วง",
    subdistrictEn: "Kham Muang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40280,
    district_code: 4019,
    province_code: 28,
    name: "เขาสวนกวาง คำม่วง ขอนแก่น 40280",
    name_th: "เขาสวนกวาง คำม่วง ขอนแก่น 40280",
    name_en: "Khao Suan Kwang, Kham Muang, Khon Kaen, 40280",
  },
  {
    id: 402001,
    districtTh: "ภูผาม่าน",
    districtEn: "Phu Pha Man",
    subdistrictTh: "โนนคอม",
    subdistrictEn: "Non Khom",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40350,
    district_code: 4020,
    province_code: 28,
    name: "ภูผาม่าน โนนคอม ขอนแก่น 40350",
    name_th: "ภูผาม่าน โนนคอม ขอนแก่น 40350",
    name_en: "Phu Pha Man, Non Khom, Khon Kaen, 40350",
  },
  {
    id: 402002,
    districtTh: "ภูผาม่าน",
    districtEn: "Phu Pha Man",
    subdistrictTh: "นาฝาย",
    subdistrictEn: "Na Fai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40350,
    district_code: 4020,
    province_code: 28,
    name: "ภูผาม่าน นาฝาย ขอนแก่น 40350",
    name_th: "ภูผาม่าน นาฝาย ขอนแก่น 40350",
    name_en: "Phu Pha Man, Na Fai, Khon Kaen, 40350",
  },
  {
    id: 402003,
    districtTh: "ภูผาม่าน",
    districtEn: "Phu Pha Man",
    subdistrictTh: "ภูผาม่าน",
    subdistrictEn: "Phu Pha Man",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40350,
    district_code: 4020,
    province_code: 28,
    name: "ภูผาม่าน ภูผาม่าน ขอนแก่น 40350",
    name_th: "ภูผาม่าน ภูผาม่าน ขอนแก่น 40350",
    name_en: "Phu Pha Man, Phu Pha Man, Khon Kaen, 40350",
  },
  {
    id: 402004,
    districtTh: "ภูผาม่าน",
    districtEn: "Phu Pha Man",
    subdistrictTh: "วังสวาบ",
    subdistrictEn: "Wang Sawap",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40350,
    district_code: 4020,
    province_code: 28,
    name: "ภูผาม่าน วังสวาบ ขอนแก่น 40350",
    name_th: "ภูผาม่าน วังสวาบ ขอนแก่น 40350",
    name_en: "Phu Pha Man, Wang Sawap, Khon Kaen, 40350",
  },
  {
    id: 402005,
    districtTh: "ภูผาม่าน",
    districtEn: "Phu Pha Man",
    subdistrictTh: "ห้วยม่วง",
    subdistrictEn: "Huai Muang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40350,
    district_code: 4020,
    province_code: 28,
    name: "ภูผาม่าน ห้วยม่วง ขอนแก่น 40350",
    name_th: "ภูผาม่าน ห้วยม่วง ขอนแก่น 40350",
    name_en: "Phu Pha Man, Huai Muang, Khon Kaen, 40350",
  },
  {
    id: 402101,
    districtTh: "ซำสูง",
    districtEn: "Sam Sung",
    subdistrictTh: "กระนวน",
    subdistrictEn: "Kranuan",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4021,
    province_code: 28,
    name: "ซำสูง กระนวน ขอนแก่น 40170",
    name_th: "ซำสูง กระนวน ขอนแก่น 40170",
    name_en: "Sam Sung, Kranuan, Khon Kaen, 40170",
  },
  {
    id: 402102,
    districtTh: "ซำสูง",
    districtEn: "Sam Sung",
    subdistrictTh: "คำแมด",
    subdistrictEn: "Kham Maet",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4021,
    province_code: 28,
    name: "ซำสูง คำแมด ขอนแก่น 40170",
    name_th: "ซำสูง คำแมด ขอนแก่น 40170",
    name_en: "Sam Sung, Kham Maet, Khon Kaen, 40170",
  },
  {
    id: 402103,
    districtTh: "ซำสูง",
    districtEn: "Sam Sung",
    subdistrictTh: "บ้านโนน",
    subdistrictEn: "Ban Non",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4021,
    province_code: 28,
    name: "ซำสูง บ้านโนน ขอนแก่น 40170",
    name_th: "ซำสูง บ้านโนน ขอนแก่น 40170",
    name_en: "Sam Sung, Ban Non, Khon Kaen, 40170",
  },
  {
    id: 402104,
    districtTh: "ซำสูง",
    districtEn: "Sam Sung",
    subdistrictTh: "คูคำ",
    subdistrictEn: "Khu Kham",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4021,
    province_code: 28,
    name: "ซำสูง คูคำ ขอนแก่น 40170",
    name_th: "ซำสูง คูคำ ขอนแก่น 40170",
    name_en: "Sam Sung, Khu Kham, Khon Kaen, 40170",
  },
  {
    id: 402105,
    districtTh: "ซำสูง",
    districtEn: "Sam Sung",
    subdistrictTh: "ห้วยเตย",
    subdistrictEn: "Huai Toei",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40170,
    district_code: 4021,
    province_code: 28,
    name: "ซำสูง ห้วยเตย ขอนแก่น 40170",
    name_th: "ซำสูง ห้วยเตย ขอนแก่น 40170",
    name_en: "Sam Sung, Huai Toei, Khon Kaen, 40170",
  },
  {
    id: 402201,
    districtTh: "โคกโพธิ์ไชย",
    districtEn: "Khok Pho Chai",
    subdistrictTh: "บ้านโคก",
    subdistrictEn: "Ban Khok",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4022,
    province_code: 28,
    name: "โคกโพธิ์ไชย บ้านโคก ขอนแก่น 40160",
    name_th: "โคกโพธิ์ไชย บ้านโคก ขอนแก่น 40160",
    name_en: "Khok Pho Chai, Ban Khok, Khon Kaen, 40160",
  },
  {
    id: 402202,
    districtTh: "โคกโพธิ์ไชย",
    districtEn: "Khok Pho Chai",
    subdistrictTh: "โพธิ์ไชย",
    subdistrictEn: "Pho Chai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4022,
    province_code: 28,
    name: "โคกโพธิ์ไชย โพธิ์ไชย ขอนแก่น 40160",
    name_th: "โคกโพธิ์ไชย โพธิ์ไชย ขอนแก่น 40160",
    name_en: "Khok Pho Chai, Pho Chai, Khon Kaen, 40160",
  },
  {
    id: 402203,
    districtTh: "โคกโพธิ์ไชย",
    districtEn: "Khok Pho Chai",
    subdistrictTh: "ซับสมบูรณ์",
    subdistrictEn: "Sap Sombun",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4022,
    province_code: 28,
    name: "โคกโพธิ์ไชย ซับสมบูรณ์ ขอนแก่น 40160",
    name_th: "โคกโพธิ์ไชย ซับสมบูรณ์ ขอนแก่น 40160",
    name_en: "Khok Pho Chai, Sap Sombun, Khon Kaen, 40160",
  },
  {
    id: 402204,
    districtTh: "โคกโพธิ์ไชย",
    districtEn: "Khok Pho Chai",
    subdistrictTh: "นาแพง",
    subdistrictEn: "Na Phaeng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40160,
    district_code: 4022,
    province_code: 28,
    name: "โคกโพธิ์ไชย นาแพง ขอนแก่น 40160",
    name_th: "โคกโพธิ์ไชย นาแพง ขอนแก่น 40160",
    name_en: "Khok Pho Chai, Na Phaeng, Khon Kaen, 40160",
  },
  {
    id: 402301,
    districtTh: "หนองนาคำ",
    districtEn: "Nong Na Kham",
    subdistrictTh: "กุดธาตุ",
    subdistrictEn: "Kut That",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4023,
    province_code: 28,
    name: "หนองนาคำ กุดธาตุ ขอนแก่น 40150",
    name_th: "หนองนาคำ กุดธาตุ ขอนแก่น 40150",
    name_en: "Nong Na Kham, Kut That, Khon Kaen, 40150",
  },
  {
    id: 402302,
    districtTh: "หนองนาคำ",
    districtEn: "Nong Na Kham",
    subdistrictTh: "บ้านโคก",
    subdistrictEn: "Ban Khok",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4023,
    province_code: 28,
    name: "หนองนาคำ บ้านโคก ขอนแก่น 40150",
    name_th: "หนองนาคำ บ้านโคก ขอนแก่น 40150",
    name_en: "Nong Na Kham, Ban Khok, Khon Kaen, 40150",
  },
  {
    id: 402303,
    districtTh: "หนองนาคำ",
    districtEn: "Nong Na Kham",
    subdistrictTh: "ขนวน",
    subdistrictEn: "Khanuan",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40150,
    district_code: 4023,
    province_code: 28,
    name: "หนองนาคำ ขนวน ขอนแก่น 40150",
    name_th: "หนองนาคำ ขนวน ขอนแก่น 40150",
    name_en: "Nong Na Kham, Khanuan, Khon Kaen, 40150",
  },
  {
    id: 402401,
    districtTh: "บ้านแฮด",
    districtEn: "Ban Haet",
    subdistrictTh: "บ้านแฮด",
    subdistrictEn: "Ban Haet",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4024,
    province_code: 28,
    name: "บ้านแฮด บ้านแฮด ขอนแก่น 40110",
    name_th: "บ้านแฮด บ้านแฮด ขอนแก่น 40110",
    name_en: "Ban Haet, Ban Haet, Khon Kaen, 40110",
  },
  {
    id: 402402,
    districtTh: "บ้านแฮด",
    districtEn: "Ban Haet",
    subdistrictTh: "โคกสำราญ",
    subdistrictEn: "Khok Samran",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4024,
    province_code: 28,
    name: "บ้านแฮด โคกสำราญ ขอนแก่น 40110",
    name_th: "บ้านแฮด โคกสำราญ ขอนแก่น 40110",
    name_en: "Ban Haet, Khok Samran, Khon Kaen, 40110",
  },
  {
    id: 402403,
    districtTh: "บ้านแฮด",
    districtEn: "Ban Haet",
    subdistrictTh: "โนนสมบูรณ์",
    subdistrictEn: "Non Sombun",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4024,
    province_code: 28,
    name: "บ้านแฮด โนนสมบูรณ์ ขอนแก่น 40110",
    name_th: "บ้านแฮด โนนสมบูรณ์ ขอนแก่น 40110",
    name_en: "Ban Haet, Non Sombun, Khon Kaen, 40110",
  },
  {
    id: 402404,
    districtTh: "บ้านแฮด",
    districtEn: "Ban Haet",
    subdistrictTh: "หนองแซง",
    subdistrictEn: "Nong Saeng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4024,
    province_code: 28,
    name: "บ้านแฮด หนองแซง ขอนแก่น 40110",
    name_th: "บ้านแฮด หนองแซง ขอนแก่น 40110",
    name_en: "Ban Haet, Nong Saeng, Khon Kaen, 40110",
  },
  {
    id: 402501,
    districtTh: "โนนศิลา",
    districtEn: "Non Sila",
    subdistrictTh: "โนนศิลา",
    subdistrictEn: "Non Sila",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4025,
    province_code: 28,
    name: "โนนศิลา โนนศิลา ขอนแก่น 40110",
    name_th: "โนนศิลา โนนศิลา ขอนแก่น 40110",
    name_en: "Non Sila, Non Sila, Khon Kaen, 40110",
  },
  {
    id: 402502,
    districtTh: "โนนศิลา",
    districtEn: "Non Sila",
    subdistrictTh: "หนองปลาหมอ",
    subdistrictEn: "Nong Pla Mo",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4025,
    province_code: 28,
    name: "โนนศิลา หนองปลาหมอ ขอนแก่น 40110",
    name_th: "โนนศิลา หนองปลาหมอ ขอนแก่น 40110",
    name_en: "Non Sila, Nong Pla Mo, Khon Kaen, 40110",
  },
  {
    id: 402503,
    districtTh: "โนนศิลา",
    districtEn: "Non Sila",
    subdistrictTh: "บ้านหัน",
    subdistrictEn: "Ban Han",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4025,
    province_code: 28,
    name: "โนนศิลา บ้านหัน ขอนแก่น 40110",
    name_th: "โนนศิลา บ้านหัน ขอนแก่น 40110",
    name_en: "Non Sila, Ban Han, Khon Kaen, 40110",
  },
  {
    id: 402504,
    districtTh: "โนนศิลา",
    districtEn: "Non Sila",
    subdistrictTh: "เปือยใหญ่",
    subdistrictEn: "Pueai Yai",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4025,
    province_code: 28,
    name: "โนนศิลา เปือยใหญ่ ขอนแก่น 40110",
    name_th: "โนนศิลา เปือยใหญ่ ขอนแก่น 40110",
    name_en: "Non Sila, Pueai Yai, Khon Kaen, 40110",
  },
  {
    id: 402505,
    districtTh: "โนนศิลา",
    districtEn: "Non Sila",
    subdistrictTh: "โนนแดง",
    subdistrictEn: "Non Daeng",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 40110,
    district_code: 4025,
    province_code: 28,
    name: "โนนศิลา โนนแดง ขอนแก่น 40110",
    name_th: "โนนศิลา โนนแดง ขอนแก่น 40110",
    name_en: "Non Sila, Non Daeng, Khon Kaen, 40110",
  },
  {
    id: 402901,
    districtTh: "เวียงเก่า",
    districtEn: "Wiang Kao",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 0,
    district_code: 4029,
    province_code: 28,
    name: "เวียงเก่า ในเมือง ขอนแก่น 0",
    name_th: "เวียงเก่า ในเมือง ขอนแก่น 0",
    name_en: "Wiang Kao, Nai Mueang, Khon Kaen, 0",
  },
  {
    id: 402902,
    districtTh: "เวียงเก่า",
    districtEn: "Wiang Kao",
    subdistrictTh: "เมืองเก่าพัฒนา",
    subdistrictEn: "Mueang Kao Phatthana",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 0,
    district_code: 4029,
    province_code: 28,
    name: "เวียงเก่า เมืองเก่าพัฒนา ขอนแก่น 0",
    name_th: "เวียงเก่า เมืองเก่าพัฒนา ขอนแก่น 0",
    name_en: "Wiang Kao, Mueang Kao Phatthana, Khon Kaen, 0",
  },
  {
    id: 402903,
    districtTh: "เวียงเก่า",
    districtEn: "Wiang Kao",
    subdistrictTh: "เขาน้อย",
    subdistrictEn: "Khao Noi",
    provinceTh: "ขอนแก่น",
    provinceEn: "Khon Kaen",
    zipcode: 0,
    district_code: 4029,
    province_code: 28,
    name: "เวียงเก่า เขาน้อย ขอนแก่น 0",
    name_th: "เวียงเก่า เขาน้อย ขอนแก่น 0",
    name_en: "Wiang Kao, Khao Noi, Khon Kaen, 0",
  },
  {
    id: 410101,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "หมากแข้ง",
    subdistrictEn: "Mak Khaeng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี หมากแข้ง อุดรธานี 41000",
    name_th: "เมืองอุดรธานี หมากแข้ง อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Mak Khaeng, Udon Thani, 41000",
  },
  {
    id: 410102,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "นิคมสงเคราะห์",
    subdistrictEn: "Nikhom Songkhro",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี นิคมสงเคราะห์ อุดรธานี 41000",
    name_th: "เมืองอุดรธานี นิคมสงเคราะห์ อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Nikhom Songkhro, Udon Thani, 41000",
  },
  {
    id: 410103,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "บ้านขาว",
    subdistrictEn: "Ban Khao",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี บ้านขาว อุดรธานี 41000",
    name_th: "เมืองอุดรธานี บ้านขาว อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Ban Khao, Udon Thani, 41000",
  },
  {
    id: 410104,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี หนองบัว อุดรธานี 41000",
    name_th: "เมืองอุดรธานี หนองบัว อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Nong Bua, Udon Thani, 41000",
  },
  {
    id: 410105,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "บ้านตาด",
    subdistrictEn: "Ban Tat",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี บ้านตาด อุดรธานี 41000",
    name_th: "เมืองอุดรธานี บ้านตาด อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Ban Tat, Udon Thani, 41000",
  },
  {
    id: 410106,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "โนนสูง",
    subdistrictEn: "Non Sung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41330,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี โนนสูง อุดรธานี 41330",
    name_th: "เมืองอุดรธานี โนนสูง อุดรธานี 41330",
    name_en: "Mueang Udon Thani, Non Sung, Udon Thani, 41330",
  },
  {
    id: 410107,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "หมูม่น",
    subdistrictEn: "Mu Mon",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี หมูม่น อุดรธานี 41000",
    name_th: "เมืองอุดรธานี หมูม่น อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Mu Mon, Udon Thani, 41000",
  },
  {
    id: 410108,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "เชียงยืน",
    subdistrictEn: "Chiang Yuen",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี เชียงยืน อุดรธานี 41000",
    name_th: "เมืองอุดรธานี เชียงยืน อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Chiang Yuen, Udon Thani, 41000",
  },
  {
    id: 410109,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "หนองนาคำ",
    subdistrictEn: "Nong Na Kham",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี หนองนาคำ อุดรธานี 41000",
    name_th: "เมืองอุดรธานี หนองนาคำ อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Nong Na Kham, Udon Thani, 41000",
  },
  {
    id: 410110,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "กุดสระ",
    subdistrictEn: "Kut Sa",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี กุดสระ อุดรธานี 41000",
    name_th: "เมืองอุดรธานี กุดสระ อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Kut Sa, Udon Thani, 41000",
  },
  {
    id: 410111,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี นาดี อุดรธานี 41000",
    name_th: "เมืองอุดรธานี นาดี อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Na Di, Udon Thani, 41000",
  },
  {
    id: 410112,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "บ้านเลื่อม",
    subdistrictEn: "Ban Lueam",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี บ้านเลื่อม อุดรธานี 41000",
    name_th: "เมืองอุดรธานี บ้านเลื่อม อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Ban Lueam, Udon Thani, 41000",
  },
  {
    id: 410113,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "เชียงพิณ",
    subdistrictEn: "Chiang Phin",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี เชียงพิณ อุดรธานี 41000",
    name_th: "เมืองอุดรธานี เชียงพิณ อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Chiang Phin, Udon Thani, 41000",
  },
  {
    id: 410114,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "สามพร้าว",
    subdistrictEn: "Sam Phrao",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี สามพร้าว อุดรธานี 41000",
    name_th: "เมืองอุดรธานี สามพร้าว อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Sam Phrao, Udon Thani, 41000",
  },
  {
    id: 410115,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "หนองไฮ",
    subdistrictEn: "Nong Hai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี หนองไฮ อุดรธานี 41000",
    name_th: "เมืองอุดรธานี หนองไฮ อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Nong Hai, Udon Thani, 41000",
  },
  {
    id: 410116,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "นาข่า",
    subdistrictEn: "Na Kha",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี นาข่า อุดรธานี 41000",
    name_th: "เมืองอุดรธานี นาข่า อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Na Kha, Udon Thani, 41000",
  },
  {
    id: 410117,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "บ้านจั่น",
    subdistrictEn: "Ban Chan",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี บ้านจั่น อุดรธานี 41000",
    name_th: "เมืองอุดรธานี บ้านจั่น อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Ban Chan, Udon Thani, 41000",
  },
  {
    id: 410118,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "หนองขอนกว้าง",
    subdistrictEn: "Nong Khon Kwang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี หนองขอนกว้าง อุดรธานี 41000",
    name_th: "เมืองอุดรธานี หนองขอนกว้าง อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Nong Khon Kwang, Udon Thani, 41000",
  },
  {
    id: 410119,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี โคกสะอาด อุดรธานี 41000",
    name_th: "เมืองอุดรธานี โคกสะอาด อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Khok Sa-at, Udon Thani, 41000",
  },
  {
    id: 410120,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "นากว้าง",
    subdistrictEn: "Na Kwang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41000,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี นากว้าง อุดรธานี 41000",
    name_th: "เมืองอุดรธานี นากว้าง อุดรธานี 41000",
    name_en: "Mueang Udon Thani, Na Kwang, Udon Thani, 41000",
  },
  {
    id: 410121,
    districtTh: "เมืองอุดรธานี",
    districtEn: "Mueang Udon Thani",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41330,
    district_code: 4101,
    province_code: 29,
    name: "เมืองอุดรธานี หนองไผ่ อุดรธานี 41330",
    name_th: "เมืองอุดรธานี หนองไผ่ อุดรธานี 41330",
    name_en: "Mueang Udon Thani, Nong Phai, Udon Thani, 41330",
  },
  {
    id: 410201,
    districtTh: "กุดจับ",
    districtEn: "Kut Chap",
    subdistrictTh: "กุดจับ",
    subdistrictEn: "Kut Chap",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41250,
    district_code: 4102,
    province_code: 29,
    name: "กุดจับ กุดจับ อุดรธานี 41250",
    name_th: "กุดจับ กุดจับ อุดรธานี 41250",
    name_en: "Kut Chap, Kut Chap, Udon Thani, 41250",
  },
  {
    id: 410202,
    districtTh: "กุดจับ",
    districtEn: "Kut Chap",
    subdistrictTh: "ปะโค",
    subdistrictEn: "Pakho",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41250,
    district_code: 4102,
    province_code: 29,
    name: "กุดจับ ปะโค อุดรธานี 41250",
    name_th: "กุดจับ ปะโค อุดรธานี 41250",
    name_en: "Kut Chap, Pakho, Udon Thani, 41250",
  },
  {
    id: 410203,
    districtTh: "กุดจับ",
    districtEn: "Kut Chap",
    subdistrictTh: "ขอนยูง",
    subdistrictEn: "Khon Yung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41250,
    district_code: 4102,
    province_code: 29,
    name: "กุดจับ ขอนยูง อุดรธานี 41250",
    name_th: "กุดจับ ขอนยูง อุดรธานี 41250",
    name_en: "Kut Chap, Khon Yung, Udon Thani, 41250",
  },
  {
    id: 410204,
    districtTh: "กุดจับ",
    districtEn: "Kut Chap",
    subdistrictTh: "เชียงเพ็ง",
    subdistrictEn: "Chiang Pheng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41250,
    district_code: 4102,
    province_code: 29,
    name: "กุดจับ เชียงเพ็ง อุดรธานี 41250",
    name_th: "กุดจับ เชียงเพ็ง อุดรธานี 41250",
    name_en: "Kut Chap, Chiang Pheng, Udon Thani, 41250",
  },
  {
    id: 410205,
    districtTh: "กุดจับ",
    districtEn: "Kut Chap",
    subdistrictTh: "สร้างก่อ",
    subdistrictEn: "Sang Ko",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41250,
    district_code: 4102,
    province_code: 29,
    name: "กุดจับ สร้างก่อ อุดรธานี 41250",
    name_th: "กุดจับ สร้างก่อ อุดรธานี 41250",
    name_en: "Kut Chap, Sang Ko, Udon Thani, 41250",
  },
  {
    id: 410206,
    districtTh: "กุดจับ",
    districtEn: "Kut Chap",
    subdistrictTh: "เมืองเพีย",
    subdistrictEn: "Mueang Phia",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41250,
    district_code: 4102,
    province_code: 29,
    name: "กุดจับ เมืองเพีย อุดรธานี 41250",
    name_th: "กุดจับ เมืองเพีย อุดรธานี 41250",
    name_en: "Kut Chap, Mueang Phia, Udon Thani, 41250",
  },
  {
    id: 410207,
    districtTh: "กุดจับ",
    districtEn: "Kut Chap",
    subdistrictTh: "ตาลเลียน",
    subdistrictEn: "Tan Lian",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41250,
    district_code: 4102,
    province_code: 29,
    name: "กุดจับ ตาลเลียน อุดรธานี 41250",
    name_th: "กุดจับ ตาลเลียน อุดรธานี 41250",
    name_en: "Kut Chap, Tan Lian, Udon Thani, 41250",
  },
  {
    id: 410301,
    districtTh: "หนองวัวซอ",
    districtEn: "Nong Wua So",
    subdistrictTh: "หมากหญ้า",
    subdistrictEn: "Mak Ya",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41360,
    district_code: 4103,
    province_code: 29,
    name: "หนองวัวซอ หมากหญ้า อุดรธานี 41360",
    name_th: "หนองวัวซอ หมากหญ้า อุดรธานี 41360",
    name_en: "Nong Wua So, Mak Ya, Udon Thani, 41360",
  },
  {
    id: 410302,
    districtTh: "หนองวัวซอ",
    districtEn: "Nong Wua So",
    subdistrictTh: "หนองอ้อ",
    subdistrictEn: "Nong O",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41220,
    district_code: 4103,
    province_code: 29,
    name: "หนองวัวซอ หนองอ้อ อุดรธานี 41220",
    name_th: "หนองวัวซอ หนองอ้อ อุดรธานี 41220",
    name_en: "Nong Wua So, Nong O, Udon Thani, 41220",
  },
  {
    id: 410303,
    districtTh: "หนองวัวซอ",
    districtEn: "Nong Wua So",
    subdistrictTh: "อูบมุง",
    subdistrictEn: "Up Mung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41220,
    district_code: 4103,
    province_code: 29,
    name: "หนองวัวซอ อูบมุง อุดรธานี 41220",
    name_th: "หนองวัวซอ อูบมุง อุดรธานี 41220",
    name_en: "Nong Wua So, Up Mung, Udon Thani, 41220",
  },
  {
    id: 410304,
    districtTh: "หนองวัวซอ",
    districtEn: "Nong Wua So",
    subdistrictTh: "กุดหมากไฟ",
    subdistrictEn: "Kut Mak Fai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41220,
    district_code: 4103,
    province_code: 29,
    name: "หนองวัวซอ กุดหมากไฟ อุดรธานี 41220",
    name_th: "หนองวัวซอ กุดหมากไฟ อุดรธานี 41220",
    name_en: "Nong Wua So, Kut Mak Fai, Udon Thani, 41220",
  },
  {
    id: 410305,
    districtTh: "หนองวัวซอ",
    districtEn: "Nong Wua So",
    subdistrictTh: "น้ำพ่น",
    subdistrictEn: "Nam Phon",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41360,
    district_code: 4103,
    province_code: 29,
    name: "หนองวัวซอ น้ำพ่น อุดรธานี 41360",
    name_th: "หนองวัวซอ น้ำพ่น อุดรธานี 41360",
    name_en: "Nong Wua So, Nam Phon, Udon Thani, 41360",
  },
  {
    id: 410306,
    districtTh: "หนองวัวซอ",
    districtEn: "Nong Wua So",
    subdistrictTh: "หนองบัวบาน",
    subdistrictEn: "Nong Bua Ban",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41360,
    district_code: 4103,
    province_code: 29,
    name: "หนองวัวซอ หนองบัวบาน อุดรธานี 41360",
    name_th: "หนองวัวซอ หนองบัวบาน อุดรธานี 41360",
    name_en: "Nong Wua So, Nong Bua Ban, Udon Thani, 41360",
  },
  {
    id: 410307,
    districtTh: "หนองวัวซอ",
    districtEn: "Nong Wua So",
    subdistrictTh: "โนนหวาย",
    subdistrictEn: "Non Wai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41220,
    district_code: 4103,
    province_code: 29,
    name: "หนองวัวซอ โนนหวาย อุดรธานี 41220",
    name_th: "หนองวัวซอ โนนหวาย อุดรธานี 41220",
    name_en: "Nong Wua So, Non Wai, Udon Thani, 41220",
  },
  {
    id: 410308,
    districtTh: "หนองวัวซอ",
    districtEn: "Nong Wua So",
    subdistrictTh: "หนองวัวซอ",
    subdistrictEn: "Nong Wua So",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41360,
    district_code: 4103,
    province_code: 29,
    name: "หนองวัวซอ หนองวัวซอ อุดรธานี 41360",
    name_th: "หนองวัวซอ หนองวัวซอ อุดรธานี 41360",
    name_en: "Nong Wua So, Nong Wua So, Udon Thani, 41360",
  },
  {
    id: 410401,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "ตูมใต้",
    subdistrictEn: "Tum Tai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี ตูมใต้ อุดรธานี 41110",
    name_th: "กุมภวาปี ตูมใต้ อุดรธานี 41110",
    name_en: "Kumphawapi, Tum Tai, Udon Thani, 41110",
  },
  {
    id: 410402,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "พันดอน",
    subdistrictEn: "Phan Don",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41370,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี พันดอน อุดรธานี 41370",
    name_th: "กุมภวาปี พันดอน อุดรธานี 41370",
    name_en: "Kumphawapi, Phan Don, Udon Thani, 41370",
  },
  {
    id: 410403,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "เวียงคำ",
    subdistrictEn: "Wiang Kham",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี เวียงคำ อุดรธานี 41110",
    name_th: "กุมภวาปี เวียงคำ อุดรธานี 41110",
    name_en: "Kumphawapi, Wiang Kham, Udon Thani, 41110",
  },
  {
    id: 410404,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "แชแล",
    subdistrictEn: "Chaelae",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี แชแล อุดรธานี 41110",
    name_th: "กุมภวาปี แชแล อุดรธานี 41110",
    name_en: "Kumphawapi, Chaelae, Udon Thani, 41110",
  },
  {
    id: 410406,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "เชียงแหว",
    subdistrictEn: "Chiang Wae",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี เชียงแหว อุดรธานี 41110",
    name_th: "กุมภวาปี เชียงแหว อุดรธานี 41110",
    name_en: "Kumphawapi, Chiang Wae, Udon Thani, 41110",
  },
  {
    id: 410407,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "ห้วยเกิ้ง",
    subdistrictEn: "Huai Koeng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี ห้วยเกิ้ง อุดรธานี 41110",
    name_th: "กุมภวาปี ห้วยเกิ้ง อุดรธานี 41110",
    name_en: "Kumphawapi, Huai Koeng, Udon Thani, 41110",
  },
  {
    id: 410409,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "เสอเพลอ",
    subdistrictEn: "Soephloe",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41370,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี เสอเพลอ อุดรธานี 41370",
    name_th: "กุมภวาปี เสอเพลอ อุดรธานี 41370",
    name_en: "Kumphawapi, Soephloe, Udon Thani, 41370",
  },
  {
    id: 410410,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "สีออ",
    subdistrictEn: "Si O",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี สีออ อุดรธานี 41110",
    name_th: "กุมภวาปี สีออ อุดรธานี 41110",
    name_en: "Kumphawapi, Si O, Udon Thani, 41110",
  },
  {
    id: 410411,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "ปะโค",
    subdistrictEn: "Pa Kho",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41370,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี ปะโค อุดรธานี 41370",
    name_th: "กุมภวาปี ปะโค อุดรธานี 41370",
    name_en: "Kumphawapi, Pa Kho, Udon Thani, 41370",
  },
  {
    id: 410413,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "ผาสุก",
    subdistrictEn: "Phasuk",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41370,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี ผาสุก อุดรธานี 41370",
    name_th: "กุมภวาปี ผาสุก อุดรธานี 41370",
    name_en: "Kumphawapi, Phasuk, Udon Thani, 41370",
  },
  {
    id: 410414,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "ท่าลี่",
    subdistrictEn: "Tha Li",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี ท่าลี่ อุดรธานี 41110",
    name_th: "กุมภวาปี ท่าลี่ อุดรธานี 41110",
    name_en: "Kumphawapi, Tha Li, Udon Thani, 41110",
  },
  {
    id: 410415,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "กุมภวาปี",
    subdistrictEn: "Kumphawapi",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี กุมภวาปี อุดรธานี 41110",
    name_th: "กุมภวาปี กุมภวาปี อุดรธานี 41110",
    name_en: "Kumphawapi, Kumphawapi, Udon Thani, 41110",
  },
  {
    id: 410416,
    districtTh: "กุมภวาปี",
    districtEn: "Kumphawapi",
    subdistrictTh: "หนองหว้า",
    subdistrictEn: "Nong Wa",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4104,
    province_code: 29,
    name: "กุมภวาปี หนองหว้า อุดรธานี 41110",
    name_th: "กุมภวาปี หนองหว้า อุดรธานี 41110",
    name_en: "Kumphawapi, Nong Wa, Udon Thani, 41110",
  },
  {
    id: 410501,
    districtTh: "โนนสะอาด",
    districtEn: "Non Sa-at",
    subdistrictTh: "โนนสะอาด",
    subdistrictEn: "Non Sa-at",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41240,
    district_code: 4105,
    province_code: 29,
    name: "โนนสะอาด โนนสะอาด อุดรธานี 41240",
    name_th: "โนนสะอาด โนนสะอาด อุดรธานี 41240",
    name_en: "Non Sa-at, Non Sa-at, Udon Thani, 41240",
  },
  {
    id: 410502,
    districtTh: "โนนสะอาด",
    districtEn: "Non Sa-at",
    subdistrictTh: "บุ่งแก้ว",
    subdistrictEn: "Bung Kaeo",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41240,
    district_code: 4105,
    province_code: 29,
    name: "โนนสะอาด บุ่งแก้ว อุดรธานี 41240",
    name_th: "โนนสะอาด บุ่งแก้ว อุดรธานี 41240",
    name_en: "Non Sa-at, Bung Kaeo, Udon Thani, 41240",
  },
  {
    id: 410503,
    districtTh: "โนนสะอาด",
    districtEn: "Non Sa-at",
    subdistrictTh: "โพธิ์ศรีสำราญ",
    subdistrictEn: "Pho Si Samran",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41240,
    district_code: 4105,
    province_code: 29,
    name: "โนนสะอาด โพธิ์ศรีสำราญ อุดรธานี 41240",
    name_th: "โนนสะอาด โพธิ์ศรีสำราญ อุดรธานี 41240",
    name_en: "Non Sa-at, Pho Si Samran, Udon Thani, 41240",
  },
  {
    id: 410504,
    districtTh: "โนนสะอาด",
    districtEn: "Non Sa-at",
    subdistrictTh: "ทมนางาม",
    subdistrictEn: "Thom Na Ngam",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41240,
    district_code: 4105,
    province_code: 29,
    name: "โนนสะอาด ทมนางาม อุดรธานี 41240",
    name_th: "โนนสะอาด ทมนางาม อุดรธานี 41240",
    name_en: "Non Sa-at, Thom Na Ngam, Udon Thani, 41240",
  },
  {
    id: 410505,
    districtTh: "โนนสะอาด",
    districtEn: "Non Sa-at",
    subdistrictTh: "หนองกุงศรี",
    subdistrictEn: "Nong Kung Si",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41240,
    district_code: 4105,
    province_code: 29,
    name: "โนนสะอาด หนองกุงศรี อุดรธานี 41240",
    name_th: "โนนสะอาด หนองกุงศรี อุดรธานี 41240",
    name_en: "Non Sa-at, Nong Kung Si, Udon Thani, 41240",
  },
  {
    id: 410506,
    districtTh: "โนนสะอาด",
    districtEn: "Non Sa-at",
    subdistrictTh: "โคกกลาง",
    subdistrictEn: "Khok Klang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41240,
    district_code: 4105,
    province_code: 29,
    name: "โนนสะอาด โคกกลาง อุดรธานี 41240",
    name_th: "โนนสะอาด โคกกลาง อุดรธานี 41240",
    name_en: "Non Sa-at, Khok Klang, Udon Thani, 41240",
  },
  {
    id: 410601,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "หนองหาน",
    subdistrictEn: "Nong Han",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน หนองหาน อุดรธานี 41130",
    name_th: "หนองหาน หนองหาน อุดรธานี 41130",
    name_en: "Nong Han, Nong Han, Udon Thani, 41130",
  },
  {
    id: 410602,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "หนองเม็ก",
    subdistrictEn: "Nong Mek",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน หนองเม็ก อุดรธานี 41130",
    name_th: "หนองหาน หนองเม็ก อุดรธานี 41130",
    name_en: "Nong Han, Nong Mek, Udon Thani, 41130",
  },
  {
    id: 410605,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "พังงู",
    subdistrictEn: "Phang Ngu",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน พังงู อุดรธานี 41130",
    name_th: "หนองหาน พังงู อุดรธานี 41130",
    name_en: "Nong Han, Phang Ngu, Udon Thani, 41130",
  },
  {
    id: 410606,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "สะแบง",
    subdistrictEn: "Sabaeng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน สะแบง อุดรธานี 41130",
    name_th: "หนองหาน สะแบง อุดรธานี 41130",
    name_en: "Nong Han, Sabaeng, Udon Thani, 41130",
  },
  {
    id: 410607,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "สร้อยพร้าว",
    subdistrictEn: "Soi Phrao",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน สร้อยพร้าว อุดรธานี 41130",
    name_th: "หนองหาน สร้อยพร้าว อุดรธานี 41130",
    name_en: "Nong Han, Soi Phrao, Udon Thani, 41130",
  },
  {
    id: 410609,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "บ้านเชียง",
    subdistrictEn: "Ban Chiang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41320,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน บ้านเชียง อุดรธานี 41320",
    name_th: "หนองหาน บ้านเชียง อุดรธานี 41320",
    name_en: "Nong Han, Ban Chiang, Udon Thani, 41320",
  },
  {
    id: 410610,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "บ้านยา",
    subdistrictEn: "Ban Ya",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41320,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน บ้านยา อุดรธานี 41320",
    name_th: "หนองหาน บ้านยา อุดรธานี 41320",
    name_en: "Nong Han, Ban Ya, Udon Thani, 41320",
  },
  {
    id: 410611,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "โพนงาม",
    subdistrictEn: "Phon Ngam",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน โพนงาม อุดรธานี 41130",
    name_th: "หนองหาน โพนงาม อุดรธานี 41130",
    name_en: "Nong Han, Phon Ngam, Udon Thani, 41130",
  },
  {
    id: 410612,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "ผักตบ",
    subdistrictEn: "Phak Top",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน ผักตบ อุดรธานี 41130",
    name_th: "หนองหาน ผักตบ อุดรธานี 41130",
    name_en: "Nong Han, Phak Top, Udon Thani, 41130",
  },
  {
    id: 410614,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน หนองไผ่ อุดรธานี 41130",
    name_th: "หนองหาน หนองไผ่ อุดรธานี 41130",
    name_en: "Nong Han, Nong Phai, Udon Thani, 41130",
  },
  {
    id: 410617,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "ดอนหายโศก",
    subdistrictEn: "Don Hai Sok",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน ดอนหายโศก อุดรธานี 41130",
    name_th: "หนองหาน ดอนหายโศก อุดรธานี 41130",
    name_en: "Nong Han, Don Hai Sok, Udon Thani, 41130",
  },
  {
    id: 410618,
    districtTh: "หนองหาน",
    districtEn: "Nong Han",
    subdistrictTh: "หนองสระปลา",
    subdistrictEn: "Nong Sa Pla)",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41320,
    district_code: 4106,
    province_code: 29,
    name: "หนองหาน หนองสระปลา อุดรธานี 41320",
    name_th: "หนองหาน หนองสระปลา อุดรธานี 41320",
    name_en: "Nong Han, Nong Sa Pla), Udon Thani, 41320",
  },
  {
    id: 410701,
    districtTh: "ทุ่งฝน",
    districtEn: "Thung Fon",
    subdistrictTh: "ทุ่งฝน",
    subdistrictEn: "Thung Fon",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41310,
    district_code: 4107,
    province_code: 29,
    name: "ทุ่งฝน ทุ่งฝน อุดรธานี 41310",
    name_th: "ทุ่งฝน ทุ่งฝน อุดรธานี 41310",
    name_en: "Thung Fon, Thung Fon, Udon Thani, 41310",
  },
  {
    id: 410702,
    districtTh: "ทุ่งฝน",
    districtEn: "Thung Fon",
    subdistrictTh: "ทุ่งใหญ่",
    subdistrictEn: "Thung Yai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41310,
    district_code: 4107,
    province_code: 29,
    name: "ทุ่งฝน ทุ่งใหญ่ อุดรธานี 41310",
    name_th: "ทุ่งฝน ทุ่งใหญ่ อุดรธานี 41310",
    name_en: "Thung Fon, Thung Yai, Udon Thani, 41310",
  },
  {
    id: 410703,
    districtTh: "ทุ่งฝน",
    districtEn: "Thung Fon",
    subdistrictTh: "นาชุมแสง",
    subdistrictEn: "Na Chum Saeng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41310,
    district_code: 4107,
    province_code: 29,
    name: "ทุ่งฝน นาชุมแสง อุดรธานี 41310",
    name_th: "ทุ่งฝน นาชุมแสง อุดรธานี 41310",
    name_en: "Thung Fon, Na Chum Saeng, Udon Thani, 41310",
  },
  {
    id: 410704,
    districtTh: "ทุ่งฝน",
    districtEn: "Thung Fon",
    subdistrictTh: "นาทม",
    subdistrictEn: "Na Thom",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41310,
    district_code: 4107,
    province_code: 29,
    name: "ทุ่งฝน นาทม อุดรธานี 41310",
    name_th: "ทุ่งฝน นาทม อุดรธานี 41310",
    name_en: "Thung Fon, Na Thom, Udon Thani, 41310",
  },
  {
    id: 410801,
    districtTh: "ไชยวาน",
    districtEn: "Chai Wan",
    subdistrictTh: "ไชยวาน",
    subdistrictEn: "Chai Wan",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41290,
    district_code: 4108,
    province_code: 29,
    name: "ไชยวาน ไชยวาน อุดรธานี 41290",
    name_th: "ไชยวาน ไชยวาน อุดรธานี 41290",
    name_en: "Chai Wan, Chai Wan, Udon Thani, 41290",
  },
  {
    id: 410802,
    districtTh: "ไชยวาน",
    districtEn: "Chai Wan",
    subdistrictTh: "หนองหลัก",
    subdistrictEn: "Nong Lak",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41290,
    district_code: 4108,
    province_code: 29,
    name: "ไชยวาน หนองหลัก อุดรธานี 41290",
    name_th: "ไชยวาน หนองหลัก อุดรธานี 41290",
    name_en: "Chai Wan, Nong Lak, Udon Thani, 41290",
  },
  {
    id: 410803,
    districtTh: "ไชยวาน",
    districtEn: "Chai Wan",
    subdistrictTh: "คำเลาะ",
    subdistrictEn: "Kham Lo",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41290,
    district_code: 4108,
    province_code: 29,
    name: "ไชยวาน คำเลาะ อุดรธานี 41290",
    name_th: "ไชยวาน คำเลาะ อุดรธานี 41290",
    name_en: "Chai Wan, Kham Lo, Udon Thani, 41290",
  },
  {
    id: 410804,
    districtTh: "ไชยวาน",
    districtEn: "Chai Wan",
    subdistrictTh: "โพนสูง",
    subdistrictEn: "Phon Sung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41290,
    district_code: 4108,
    province_code: 29,
    name: "ไชยวาน โพนสูง อุดรธานี 41290",
    name_th: "ไชยวาน โพนสูง อุดรธานี 41290",
    name_en: "Chai Wan, Phon Sung, Udon Thani, 41290",
  },
  {
    id: 410901,
    districtTh: "ศรีธาตุ",
    districtEn: "Si That",
    subdistrictTh: "ศรีธาตุ",
    subdistrictEn: "Si That",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41230,
    district_code: 4109,
    province_code: 29,
    name: "ศรีธาตุ ศรีธาตุ อุดรธานี 41230",
    name_th: "ศรีธาตุ ศรีธาตุ อุดรธานี 41230",
    name_en: "Si That, Si That, Udon Thani, 41230",
  },
  {
    id: 410902,
    districtTh: "ศรีธาตุ",
    districtEn: "Si That",
    subdistrictTh: "จำปี",
    subdistrictEn: "Champi",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41230,
    district_code: 4109,
    province_code: 29,
    name: "ศรีธาตุ จำปี อุดรธานี 41230",
    name_th: "ศรีธาตุ จำปี อุดรธานี 41230",
    name_en: "Si That, Champi, Udon Thani, 41230",
  },
  {
    id: 410903,
    districtTh: "ศรีธาตุ",
    districtEn: "Si That",
    subdistrictTh: "บ้านโปร่ง",
    subdistrictEn: "Ban Prong",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41230,
    district_code: 4109,
    province_code: 29,
    name: "ศรีธาตุ บ้านโปร่ง อุดรธานี 41230",
    name_th: "ศรีธาตุ บ้านโปร่ง อุดรธานี 41230",
    name_en: "Si That, Ban Prong, Udon Thani, 41230",
  },
  {
    id: 410904,
    districtTh: "ศรีธาตุ",
    districtEn: "Si That",
    subdistrictTh: "หัวนาคำ",
    subdistrictEn: "Hua Na Kham",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41230,
    district_code: 4109,
    province_code: 29,
    name: "ศรีธาตุ หัวนาคำ อุดรธานี 41230",
    name_th: "ศรีธาตุ หัวนาคำ อุดรธานี 41230",
    name_en: "Si That, Hua Na Kham, Udon Thani, 41230",
  },
  {
    id: 410905,
    districtTh: "ศรีธาตุ",
    districtEn: "Si That",
    subdistrictTh: "หนองนกเขียน",
    subdistrictEn: "Nong Nok Khian",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41230,
    district_code: 4109,
    province_code: 29,
    name: "ศรีธาตุ หนองนกเขียน อุดรธานี 41230",
    name_th: "ศรีธาตุ หนองนกเขียน อุดรธานี 41230",
    name_en: "Si That, Nong Nok Khian, Udon Thani, 41230",
  },
  {
    id: 410906,
    districtTh: "ศรีธาตุ",
    districtEn: "Si That",
    subdistrictTh: "นายูง",
    subdistrictEn: "Na Yung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41230,
    district_code: 4109,
    province_code: 29,
    name: "ศรีธาตุ นายูง อุดรธานี 41230",
    name_th: "ศรีธาตุ นายูง อุดรธานี 41230",
    name_en: "Si That, Na Yung, Udon Thani, 41230",
  },
  {
    id: 410907,
    districtTh: "ศรีธาตุ",
    districtEn: "Si That",
    subdistrictTh: "ตาดทอง",
    subdistrictEn: "Tat Thong",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41230,
    district_code: 4109,
    province_code: 29,
    name: "ศรีธาตุ ตาดทอง อุดรธานี 41230",
    name_th: "ศรีธาตุ ตาดทอง อุดรธานี 41230",
    name_en: "Si That, Tat Thong, Udon Thani, 41230",
  },
  {
    id: 411001,
    districtTh: "วังสามหมอ",
    districtEn: "Wang Sam Mo",
    subdistrictTh: "หนองกุงทับม้า",
    subdistrictEn: "Nong Kung Thap Ma",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41280,
    district_code: 4110,
    province_code: 29,
    name: "วังสามหมอ หนองกุงทับม้า อุดรธานี 41280",
    name_th: "วังสามหมอ หนองกุงทับม้า อุดรธานี 41280",
    name_en: "Wang Sam Mo, Nong Kung Thap Ma, Udon Thani, 41280",
  },
  {
    id: 411002,
    districtTh: "วังสามหมอ",
    districtEn: "Wang Sam Mo",
    subdistrictTh: "หนองหญ้าไซ",
    subdistrictEn: "Nong Ya Sai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41280,
    district_code: 4110,
    province_code: 29,
    name: "วังสามหมอ หนองหญ้าไซ อุดรธานี 41280",
    name_th: "วังสามหมอ หนองหญ้าไซ อุดรธานี 41280",
    name_en: "Wang Sam Mo, Nong Ya Sai, Udon Thani, 41280",
  },
  {
    id: 411003,
    districtTh: "วังสามหมอ",
    districtEn: "Wang Sam Mo",
    subdistrictTh: "บะยาว",
    subdistrictEn: "Ba Yao",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41280,
    district_code: 4110,
    province_code: 29,
    name: "วังสามหมอ บะยาว อุดรธานี 41280",
    name_th: "วังสามหมอ บะยาว อุดรธานี 41280",
    name_en: "Wang Sam Mo, Ba Yao, Udon Thani, 41280",
  },
  {
    id: 411004,
    districtTh: "วังสามหมอ",
    districtEn: "Wang Sam Mo",
    subdistrictTh: "ผาสุก",
    subdistrictEn: "Phasuk",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41280,
    district_code: 4110,
    province_code: 29,
    name: "วังสามหมอ ผาสุก อุดรธานี 41280",
    name_th: "วังสามหมอ ผาสุก อุดรธานี 41280",
    name_en: "Wang Sam Mo, Phasuk, Udon Thani, 41280",
  },
  {
    id: 411005,
    districtTh: "วังสามหมอ",
    districtEn: "Wang Sam Mo",
    subdistrictTh: "คำโคกสูง",
    subdistrictEn: "Kham Khok Sung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41280,
    district_code: 4110,
    province_code: 29,
    name: "วังสามหมอ คำโคกสูง อุดรธานี 41280",
    name_th: "วังสามหมอ คำโคกสูง อุดรธานี 41280",
    name_en: "Wang Sam Mo, Kham Khok Sung, Udon Thani, 41280",
  },
  {
    id: 411006,
    districtTh: "วังสามหมอ",
    districtEn: "Wang Sam Mo",
    subdistrictTh: "วังสามหมอ",
    subdistrictEn: "Wang Sam Mo",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41280,
    district_code: 4110,
    province_code: 29,
    name: "วังสามหมอ วังสามหมอ อุดรธานี 41280",
    name_th: "วังสามหมอ วังสามหมอ อุดรธานี 41280",
    name_en: "Wang Sam Mo, Wang Sam Mo, Udon Thani, 41280",
  },
  {
    id: 411101,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "ศรีสุทโธ",
    subdistrictEn: "Si Suttho",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง ศรีสุทโธ อุดรธานี 41190",
    name_th: "บ้านดุง ศรีสุทโธ อุดรธานี 41190",
    name_en: "Ban Dung, Si Suttho, Udon Thani, 41190",
  },
  {
    id: 411102,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "บ้านดุง",
    subdistrictEn: "Ban Dung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง บ้านดุง อุดรธานี 41190",
    name_th: "บ้านดุง บ้านดุง อุดรธานี 41190",
    name_en: "Ban Dung, Ban Dung, Udon Thani, 41190",
  },
  {
    id: 411103,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "ดงเย็น",
    subdistrictEn: "Dong Yen",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง ดงเย็น อุดรธานี 41190",
    name_th: "บ้านดุง ดงเย็น อุดรธานี 41190",
    name_en: "Ban Dung, Dong Yen, Udon Thani, 41190",
  },
  {
    id: 411104,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "โพนสูง",
    subdistrictEn: "Phon Sung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง โพนสูง อุดรธานี 41190",
    name_th: "บ้านดุง โพนสูง อุดรธานี 41190",
    name_en: "Ban Dung, Phon Sung, Udon Thani, 41190",
  },
  {
    id: 411105,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "อ้อมกอ",
    subdistrictEn: "Om Ko",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง อ้อมกอ อุดรธานี 41190",
    name_th: "บ้านดุง อ้อมกอ อุดรธานี 41190",
    name_en: "Ban Dung, Om Ko, Udon Thani, 41190",
  },
  {
    id: 411106,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "บ้านจันทน์",
    subdistrictEn: "Ban Chan",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง บ้านจันทน์ อุดรธานี 41190",
    name_th: "บ้านดุง บ้านจันทน์ อุดรธานี 41190",
    name_en: "Ban Dung, Ban Chan, Udon Thani, 41190",
  },
  {
    id: 411107,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "บ้านชัย",
    subdistrictEn: "Ban Chai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง บ้านชัย อุดรธานี 41190",
    name_th: "บ้านดุง บ้านชัย อุดรธานี 41190",
    name_en: "Ban Dung, Ban Chai, Udon Thani, 41190",
  },
  {
    id: 411108,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "นาไหม",
    subdistrictEn: "Na Mai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง นาไหม อุดรธานี 41190",
    name_th: "บ้านดุง นาไหม อุดรธานี 41190",
    name_en: "Ban Dung, Na Mai, Udon Thani, 41190",
  },
  {
    id: 411109,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "ถ่อนนาลับ",
    subdistrictEn: "Thon Na Lap",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง ถ่อนนาลับ อุดรธานี 41190",
    name_th: "บ้านดุง ถ่อนนาลับ อุดรธานี 41190",
    name_en: "Ban Dung, Thon Na Lap, Udon Thani, 41190",
  },
  {
    id: 411110,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง วังทอง อุดรธานี 41190",
    name_th: "บ้านดุง วังทอง อุดรธานี 41190",
    name_en: "Ban Dung, Wang Thong, Udon Thani, 41190",
  },
  {
    id: 411111,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "บ้านม่วง",
    subdistrictEn: "Ban Muang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง บ้านม่วง อุดรธานี 41190",
    name_th: "บ้านดุง บ้านม่วง อุดรธานี 41190",
    name_en: "Ban Dung, Ban Muang, Udon Thani, 41190",
  },
  {
    id: 411112,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "บ้านตาด",
    subdistrictEn: "Ban Tat",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง บ้านตาด อุดรธานี 41190",
    name_th: "บ้านดุง บ้านตาด อุดรธานี 41190",
    name_en: "Ban Dung, Ban Tat, Udon Thani, 41190",
  },
  {
    id: 411113,
    districtTh: "บ้านดุง",
    districtEn: "Ban Dung",
    subdistrictTh: "นาคำ",
    subdistrictEn: "Na Kham",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41190,
    district_code: 4111,
    province_code: 29,
    name: "บ้านดุง นาคำ อุดรธานี 41190",
    name_th: "บ้านดุง นาคำ อุดรธานี 41190",
    name_en: "Ban Dung, Na Kham, Udon Thani, 41190",
  },
  {
    id: 411701,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "บ้านผือ",
    subdistrictEn: "Ban Phue",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ บ้านผือ อุดรธานี 41160",
    name_th: "บ้านผือ บ้านผือ อุดรธานี 41160",
    name_en: "Ban Phue, Ban Phue, Udon Thani, 41160",
  },
  {
    id: 411702,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "หายโศก",
    subdistrictEn: "Hai Sok",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ หายโศก อุดรธานี 41160",
    name_th: "บ้านผือ หายโศก อุดรธานี 41160",
    name_en: "Ban Phue, Hai Sok, Udon Thani, 41160",
  },
  {
    id: 411703,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "เขือน้ำ",
    subdistrictEn: "Khuea Nam",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ เขือน้ำ อุดรธานี 41160",
    name_th: "บ้านผือ เขือน้ำ อุดรธานี 41160",
    name_en: "Ban Phue, Khuea Nam, Udon Thani, 41160",
  },
  {
    id: 411704,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "คำบง",
    subdistrictEn: "Kham Bong",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ คำบง อุดรธานี 41160",
    name_th: "บ้านผือ คำบง อุดรธานี 41160",
    name_en: "Ban Phue, Kham Bong, Udon Thani, 41160",
  },
  {
    id: 411705,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "โนนทอง",
    subdistrictEn: "Non Thong",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ โนนทอง อุดรธานี 41160",
    name_th: "บ้านผือ โนนทอง อุดรธานี 41160",
    name_en: "Ban Phue, Non Thong, Udon Thani, 41160",
  },
  {
    id: 411706,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "ข้าวสาร",
    subdistrictEn: "Khao San",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ ข้าวสาร อุดรธานี 41160",
    name_th: "บ้านผือ ข้าวสาร อุดรธานี 41160",
    name_en: "Ban Phue, Khao San, Udon Thani, 41160",
  },
  {
    id: 411707,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "จำปาโมง",
    subdistrictEn: "Champa Mong",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ จำปาโมง อุดรธานี 41160",
    name_th: "บ้านผือ จำปาโมง อุดรธานี 41160",
    name_en: "Ban Phue, Champa Mong, Udon Thani, 41160",
  },
  {
    id: 411708,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "กลางใหญ่",
    subdistrictEn: "Klang Yai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ กลางใหญ่ อุดรธานี 41160",
    name_th: "บ้านผือ กลางใหญ่ อุดรธานี 41160",
    name_en: "Ban Phue, Klang Yai, Udon Thani, 41160",
  },
  {
    id: 411709,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "เมืองพาน",
    subdistrictEn: "Mueang Phan",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ เมืองพาน อุดรธานี 41160",
    name_th: "บ้านผือ เมืองพาน อุดรธานี 41160",
    name_en: "Ban Phue, Mueang Phan, Udon Thani, 41160",
  },
  {
    id: 411710,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "คำด้วง",
    subdistrictEn: "Kham Duang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ คำด้วง อุดรธานี 41160",
    name_th: "บ้านผือ คำด้วง อุดรธานี 41160",
    name_en: "Ban Phue, Kham Duang, Udon Thani, 41160",
  },
  {
    id: 411711,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "หนองหัวคู",
    subdistrictEn: "Nong Hua Khu",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ หนองหัวคู อุดรธานี 41160",
    name_th: "บ้านผือ หนองหัวคู อุดรธานี 41160",
    name_en: "Ban Phue, Nong Hua Khu, Udon Thani, 41160",
  },
  {
    id: 411712,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "บ้านค้อ",
    subdistrictEn: "Ban Kho",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ บ้านค้อ อุดรธานี 41160",
    name_th: "บ้านผือ บ้านค้อ อุดรธานี 41160",
    name_en: "Ban Phue, Ban Kho, Udon Thani, 41160",
  },
  {
    id: 411713,
    districtTh: "บ้านผือ",
    districtEn: "Ban Phue",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41160,
    district_code: 4117,
    province_code: 29,
    name: "บ้านผือ หนองแวง อุดรธานี 41160",
    name_th: "บ้านผือ หนองแวง อุดรธานี 41160",
    name_en: "Ban Phue, Nong Waeng, Udon Thani, 41160",
  },
  {
    id: 411801,
    districtTh: "น้ำโสม",
    districtEn: "Nam Som",
    subdistrictTh: "นางัว",
    subdistrictEn: "Na Ngua",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41210,
    district_code: 4118,
    province_code: 29,
    name: "น้ำโสม นางัว อุดรธานี 41210",
    name_th: "น้ำโสม นางัว อุดรธานี 41210",
    name_en: "Nam Som, Na Ngua, Udon Thani, 41210",
  },
  {
    id: 411802,
    districtTh: "น้ำโสม",
    districtEn: "Nam Som",
    subdistrictTh: "น้ำโสม",
    subdistrictEn: "Nam Som",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41210,
    district_code: 4118,
    province_code: 29,
    name: "น้ำโสม น้ำโสม อุดรธานี 41210",
    name_th: "น้ำโสม น้ำโสม อุดรธานี 41210",
    name_en: "Nam Som, Nam Som, Udon Thani, 41210",
  },
  {
    id: 411805,
    districtTh: "น้ำโสม",
    districtEn: "Nam Som",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41210,
    district_code: 4118,
    province_code: 29,
    name: "น้ำโสม หนองแวง อุดรธานี 41210",
    name_th: "น้ำโสม หนองแวง อุดรธานี 41210",
    name_en: "Nam Som, Nong Waeng, Udon Thani, 41210",
  },
  {
    id: 411806,
    districtTh: "น้ำโสม",
    districtEn: "Nam Som",
    subdistrictTh: "บ้านหยวก",
    subdistrictEn: "Ban Yuak",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41210,
    district_code: 4118,
    province_code: 29,
    name: "น้ำโสม บ้านหยวก อุดรธานี 41210",
    name_th: "น้ำโสม บ้านหยวก อุดรธานี 41210",
    name_en: "Nam Som, Ban Yuak, Udon Thani, 41210",
  },
  {
    id: 411807,
    districtTh: "น้ำโสม",
    districtEn: "Nam Som",
    subdistrictTh: "โสมเยี่ยม",
    subdistrictEn: "Som Yiam",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41210,
    district_code: 4118,
    province_code: 29,
    name: "น้ำโสม โสมเยี่ยม อุดรธานี 41210",
    name_th: "น้ำโสม โสมเยี่ยม อุดรธานี 41210",
    name_en: "Nam Som, Som Yiam, Udon Thani, 41210",
  },
  {
    id: 411810,
    districtTh: "น้ำโสม",
    districtEn: "Nam Som",
    subdistrictTh: "ศรีสำราญ",
    subdistrictEn: "Si Samran",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41210,
    district_code: 4118,
    province_code: 29,
    name: "น้ำโสม ศรีสำราญ อุดรธานี 41210",
    name_th: "น้ำโสม ศรีสำราญ อุดรธานี 41210",
    name_en: "Nam Som, Si Samran, Udon Thani, 41210",
  },
  {
    id: 411812,
    districtTh: "น้ำโสม",
    districtEn: "Nam Som",
    subdistrictTh: "สามัคคี",
    subdistrictEn: "Samakkhi",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41210,
    district_code: 4118,
    province_code: 29,
    name: "น้ำโสม สามัคคี อุดรธานี 41210",
    name_th: "น้ำโสม สามัคคี อุดรธานี 41210",
    name_en: "Nam Som, Samakkhi, Udon Thani, 41210",
  },
  {
    id: 411901,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "เพ็ญ",
    subdistrictEn: "Phen",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ เพ็ญ อุดรธานี 41150",
    name_th: "เพ็ญ เพ็ญ อุดรธานี 41150",
    name_en: "Phen, Phen, Udon Thani, 41150",
  },
  {
    id: 411902,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "บ้านธาตุ",
    subdistrictEn: "Ban That",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ บ้านธาตุ อุดรธานี 41150",
    name_th: "เพ็ญ บ้านธาตุ อุดรธานี 41150",
    name_en: "Phen, Ban That, Udon Thani, 41150",
  },
  {
    id: 411903,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "นาพู่",
    subdistrictEn: "Na Phu",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ นาพู่ อุดรธานี 41150",
    name_th: "เพ็ญ นาพู่ อุดรธานี 41150",
    name_en: "Phen, Na Phu, Udon Thani, 41150",
  },
  {
    id: 411904,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "เชียงหวาง",
    subdistrictEn: "Chiang Wang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ เชียงหวาง อุดรธานี 41150",
    name_th: "เพ็ญ เชียงหวาง อุดรธานี 41150",
    name_en: "Phen, Chiang Wang, Udon Thani, 41150",
  },
  {
    id: 411905,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "สุมเส้า",
    subdistrictEn: "Sum Sao",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ สุมเส้า อุดรธานี 41150",
    name_th: "เพ็ญ สุมเส้า อุดรธานี 41150",
    name_en: "Phen, Sum Sao, Udon Thani, 41150",
  },
  {
    id: 411906,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "นาบัว",
    subdistrictEn: "Na Bua",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ นาบัว อุดรธานี 41150",
    name_th: "เพ็ญ นาบัว อุดรธานี 41150",
    name_en: "Phen, Na Bua, Udon Thani, 41150",
  },
  {
    id: 411907,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "บ้านเหล่า",
    subdistrictEn: "Ban Lao",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ บ้านเหล่า อุดรธานี 41150",
    name_th: "เพ็ญ บ้านเหล่า อุดรธานี 41150",
    name_en: "Phen, Ban Lao, Udon Thani, 41150",
  },
  {
    id: 411908,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "จอมศรี",
    subdistrictEn: "Chom Si",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ จอมศรี อุดรธานี 41150",
    name_th: "เพ็ญ จอมศรี อุดรธานี 41150",
    name_en: "Phen, Chom Si, Udon Thani, 41150",
  },
  {
    id: 411909,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "เตาไห",
    subdistrictEn: "Tao Hai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ เตาไห อุดรธานี 41150",
    name_th: "เพ็ญ เตาไห อุดรธานี 41150",
    name_en: "Phen, Tao Hai, Udon Thani, 41150",
  },
  {
    id: 411910,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "โคกกลาง",
    subdistrictEn: "Khok Klang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ โคกกลาง อุดรธานี 41150",
    name_th: "เพ็ญ โคกกลาง อุดรธานี 41150",
    name_en: "Phen, Khok Klang, Udon Thani, 41150",
  },
  {
    id: 411911,
    districtTh: "เพ็ญ",
    districtEn: "Phen",
    subdistrictTh: "สร้างแป้น",
    subdistrictEn: "Sang Paen",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41150,
    district_code: 4119,
    province_code: 29,
    name: "เพ็ญ สร้างแป้น อุดรธานี 41150",
    name_th: "เพ็ญ สร้างแป้น อุดรธานี 41150",
    name_en: "Phen, Sang Paen, Udon Thani, 41150",
  },
  {
    id: 412001,
    districtTh: "สร้างคอม",
    districtEn: "Sang Khom",
    subdistrictTh: "สร้างคอม",
    subdistrictEn: "Sang Khom",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41260,
    district_code: 4120,
    province_code: 29,
    name: "สร้างคอม สร้างคอม อุดรธานี 41260",
    name_th: "สร้างคอม สร้างคอม อุดรธานี 41260",
    name_en: "Sang Khom, Sang Khom, Udon Thani, 41260",
  },
  {
    id: 412002,
    districtTh: "สร้างคอม",
    districtEn: "Sang Khom",
    subdistrictTh: "เชียงดา",
    subdistrictEn: "Chiang Da",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41260,
    district_code: 4120,
    province_code: 29,
    name: "สร้างคอม เชียงดา อุดรธานี 41260",
    name_th: "สร้างคอม เชียงดา อุดรธานี 41260",
    name_en: "Sang Khom, Chiang Da, Udon Thani, 41260",
  },
  {
    id: 412003,
    districtTh: "สร้างคอม",
    districtEn: "Sang Khom",
    subdistrictTh: "บ้านยวด",
    subdistrictEn: "Ban Yuat",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41260,
    district_code: 4120,
    province_code: 29,
    name: "สร้างคอม บ้านยวด อุดรธานี 41260",
    name_th: "สร้างคอม บ้านยวด อุดรธานี 41260",
    name_en: "Sang Khom, Ban Yuat, Udon Thani, 41260",
  },
  {
    id: 412004,
    districtTh: "สร้างคอม",
    districtEn: "Sang Khom",
    subdistrictTh: "บ้านโคก",
    subdistrictEn: "Ban Khok",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41260,
    district_code: 4120,
    province_code: 29,
    name: "สร้างคอม บ้านโคก อุดรธานี 41260",
    name_th: "สร้างคอม บ้านโคก อุดรธานี 41260",
    name_en: "Sang Khom, Ban Khok, Udon Thani, 41260",
  },
  {
    id: 412005,
    districtTh: "สร้างคอม",
    districtEn: "Sang Khom",
    subdistrictTh: "นาสะอาด",
    subdistrictEn: "Na Sa-at",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41260,
    district_code: 4120,
    province_code: 29,
    name: "สร้างคอม นาสะอาด อุดรธานี 41260",
    name_th: "สร้างคอม นาสะอาด อุดรธานี 41260",
    name_en: "Sang Khom, Na Sa-at, Udon Thani, 41260",
  },
  {
    id: 412006,
    districtTh: "สร้างคอม",
    districtEn: "Sang Khom",
    subdistrictTh: "บ้านหินโงม",
    subdistrictEn: "Ban Hin Ngom",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41260,
    district_code: 4120,
    province_code: 29,
    name: "สร้างคอม บ้านหินโงม อุดรธานี 41260",
    name_th: "สร้างคอม บ้านหินโงม อุดรธานี 41260",
    name_en: "Sang Khom, Ban Hin Ngom, Udon Thani, 41260",
  },
  {
    id: 412101,
    districtTh: "หนองแสง",
    districtEn: "Nong Saeng",
    subdistrictTh: "หนองแสง",
    subdistrictEn: "Nong Saeng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41340,
    district_code: 4121,
    province_code: 29,
    name: "หนองแสง หนองแสง อุดรธานี 41340",
    name_th: "หนองแสง หนองแสง อุดรธานี 41340",
    name_en: "Nong Saeng, Nong Saeng, Udon Thani, 41340",
  },
  {
    id: 412102,
    districtTh: "หนองแสง",
    districtEn: "Nong Saeng",
    subdistrictTh: "แสงสว่าง",
    subdistrictEn: "Saeng Sawang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41340,
    district_code: 4121,
    province_code: 29,
    name: "หนองแสง แสงสว่าง อุดรธานี 41340",
    name_th: "หนองแสง แสงสว่าง อุดรธานี 41340",
    name_en: "Nong Saeng, Saeng Sawang, Udon Thani, 41340",
  },
  {
    id: 412103,
    districtTh: "หนองแสง",
    districtEn: "Nong Saeng",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41340,
    district_code: 4121,
    province_code: 29,
    name: "หนองแสง นาดี อุดรธานี 41340",
    name_th: "หนองแสง นาดี อุดรธานี 41340",
    name_en: "Nong Saeng, Na Di, Udon Thani, 41340",
  },
  {
    id: 412104,
    districtTh: "หนองแสง",
    districtEn: "Nong Saeng",
    subdistrictTh: "ทับกุง",
    subdistrictEn: "Thap Kung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41340,
    district_code: 4121,
    province_code: 29,
    name: "หนองแสง ทับกุง อุดรธานี 41340",
    name_th: "หนองแสง ทับกุง อุดรธานี 41340",
    name_en: "Nong Saeng, Thap Kung, Udon Thani, 41340",
  },
  {
    id: 412201,
    districtTh: "นายูง",
    districtEn: "Na Yung",
    subdistrictTh: "นายูง",
    subdistrictEn: "Na Yung",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41380,
    district_code: 4122,
    province_code: 29,
    name: "นายูง นายูง อุดรธานี 41380",
    name_th: "นายูง นายูง อุดรธานี 41380",
    name_en: "Na Yung, Na Yung, Udon Thani, 41380",
  },
  {
    id: 412202,
    districtTh: "นายูง",
    districtEn: "Na Yung",
    subdistrictTh: "บ้านก้อง",
    subdistrictEn: "Ban Kong",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41380,
    district_code: 4122,
    province_code: 29,
    name: "นายูง บ้านก้อง อุดรธานี 41380",
    name_th: "นายูง บ้านก้อง อุดรธานี 41380",
    name_en: "Na Yung, Ban Kong, Udon Thani, 41380",
  },
  {
    id: 412203,
    districtTh: "นายูง",
    districtEn: "Na Yung",
    subdistrictTh: "นาแค",
    subdistrictEn: "Na Khae",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41380,
    district_code: 4122,
    province_code: 29,
    name: "นายูง นาแค อุดรธานี 41380",
    name_th: "นายูง นาแค อุดรธานี 41380",
    name_en: "Na Yung, Na Khae, Udon Thani, 41380",
  },
  {
    id: 412204,
    districtTh: "นายูง",
    districtEn: "Na Yung",
    subdistrictTh: "โนนทอง",
    subdistrictEn: "Non Thong",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41380,
    district_code: 4122,
    province_code: 29,
    name: "นายูง โนนทอง อุดรธานี 41380",
    name_th: "นายูง โนนทอง อุดรธานี 41380",
    name_en: "Na Yung, Non Thong, Udon Thani, 41380",
  },
  {
    id: 412301,
    districtTh: "พิบูลย์รักษ์",
    districtEn: "Phibun Rak",
    subdistrictTh: "บ้านแดง",
    subdistrictEn: "Ban Daeng",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4123,
    province_code: 29,
    name: "พิบูลย์รักษ์ บ้านแดง อุดรธานี 41130",
    name_th: "พิบูลย์รักษ์ บ้านแดง อุดรธานี 41130",
    name_en: "Phibun Rak, Ban Daeng, Udon Thani, 41130",
  },
  {
    id: 412302,
    districtTh: "พิบูลย์รักษ์",
    districtEn: "Phibun Rak",
    subdistrictTh: "นาทราย",
    subdistrictEn: "Na Sai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4123,
    province_code: 29,
    name: "พิบูลย์รักษ์ นาทราย อุดรธานี 41130",
    name_th: "พิบูลย์รักษ์ นาทราย อุดรธานี 41130",
    name_en: "Phibun Rak, Na Sai, Udon Thani, 41130",
  },
  {
    id: 412303,
    districtTh: "พิบูลย์รักษ์",
    districtEn: "Phibun Rak",
    subdistrictTh: "ดอนกลอย",
    subdistrictEn: "Don Kloi",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4123,
    province_code: 29,
    name: "พิบูลย์รักษ์ ดอนกลอย อุดรธานี 41130",
    name_th: "พิบูลย์รักษ์ ดอนกลอย อุดรธานี 41130",
    name_en: "Phibun Rak, Don Kloi, Udon Thani, 41130",
  },
  {
    id: 412401,
    districtTh: "กู่แก้ว",
    districtEn: "Ku Kaeo",
    subdistrictTh: "บ้านจีต",
    subdistrictEn: "Ban Chit",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4124,
    province_code: 29,
    name: "กู่แก้ว บ้านจีต อุดรธานี 41130",
    name_th: "กู่แก้ว บ้านจีต อุดรธานี 41130",
    name_en: "Ku Kaeo, Ban Chit, Udon Thani, 41130",
  },
  {
    id: 412402,
    districtTh: "กู่แก้ว",
    districtEn: "Ku Kaeo",
    subdistrictTh: "โนนทองอินทร์",
    subdistrictEn: "Non Thong In",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4124,
    province_code: 29,
    name: "กู่แก้ว โนนทองอินทร์ อุดรธานี 41130",
    name_th: "กู่แก้ว โนนทองอินทร์ อุดรธานี 41130",
    name_en: "Ku Kaeo, Non Thong In, Udon Thani, 41130",
  },
  {
    id: 412403,
    districtTh: "กู่แก้ว",
    districtEn: "Ku Kaeo",
    subdistrictTh: "ค้อใหญ่",
    subdistrictEn: "Kho Yai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4124,
    province_code: 29,
    name: "กู่แก้ว ค้อใหญ่ อุดรธานี 41130",
    name_th: "กู่แก้ว ค้อใหญ่ อุดรธานี 41130",
    name_en: "Ku Kaeo, Kho Yai, Udon Thani, 41130",
  },
  {
    id: 412404,
    districtTh: "กู่แก้ว",
    districtEn: "Ku Kaeo",
    subdistrictTh: "คอนสาย",
    subdistrictEn: "Khon Sai",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41130,
    district_code: 4124,
    province_code: 29,
    name: "กู่แก้ว คอนสาย อุดรธานี 41130",
    name_th: "กู่แก้ว คอนสาย อุดรธานี 41130",
    name_en: "Ku Kaeo, Khon Sai, Udon Thani, 41130",
  },
  {
    id: 412501,
    districtTh: "ประจักษ์ศิลปาคม",
    districtEn: "rachak-sinlapakhom",
    subdistrictTh: "นาม่วง",
    subdistrictEn: "Na Muang",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4125,
    province_code: 29,
    name: "ประจักษ์ศิลปาคม นาม่วง อุดรธานี 41110",
    name_th: "ประจักษ์ศิลปาคม นาม่วง อุดรธานี 41110",
    name_en: "rachak-sinlapakhom, Na Muang, Udon Thani, 41110",
  },
  {
    id: 412502,
    districtTh: "ประจักษ์ศิลปาคม",
    districtEn: "rachak-sinlapakhom",
    subdistrictTh: "ห้วยสามพาด",
    subdistrictEn: "Huai Sam Phat",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4125,
    province_code: 29,
    name: "ประจักษ์ศิลปาคม ห้วยสามพาด อุดรธานี 41110",
    name_th: "ประจักษ์ศิลปาคม ห้วยสามพาด อุดรธานี 41110",
    name_en: "rachak-sinlapakhom, Huai Sam Phat, Udon Thani, 41110",
  },
  {
    id: 412503,
    districtTh: "ประจักษ์ศิลปาคม",
    districtEn: "rachak-sinlapakhom",
    subdistrictTh: "อุ่มจาน",
    subdistrictEn: "Um Chan",
    provinceTh: "อุดรธานี",
    provinceEn: "Udon Thani",
    zipcode: 41110,
    district_code: 4125,
    province_code: 29,
    name: "ประจักษ์ศิลปาคม อุ่มจาน อุดรธานี 41110",
    name_th: "ประจักษ์ศิลปาคม อุ่มจาน อุดรธานี 41110",
    name_en: "rachak-sinlapakhom, Um Chan, Udon Thani, 41110",
  },
  {
    id: 420101,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "กุดป่อง",
    subdistrictEn: "Kut Pong",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย กุดป่อง เลย 42000",
    name_th: "เมืองเลย กุดป่อง เลย 42000",
    name_en: "Mueang Loei, Kut Pong, Loei, 42000",
  },
  {
    id: 420102,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "เมือง",
    subdistrictEn: "Mueang",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย เมือง เลย 42000",
    name_th: "เมืองเลย เมือง เลย 42000",
    name_en: "Mueang Loei, Mueang, Loei, 42000",
  },
  {
    id: 420103,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "นาอ้อ",
    subdistrictEn: "Na O",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42100,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย นาอ้อ เลย 42100",
    name_th: "เมืองเลย นาอ้อ เลย 42100",
    name_en: "Mueang Loei, Na O, Loei, 42100",
  },
  {
    id: 420104,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "กกดู่",
    subdistrictEn: "Kok Du",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย กกดู่ เลย 42000",
    name_th: "เมืองเลย กกดู่ เลย 42000",
    name_en: "Mueang Loei, Kok Du, Loei, 42000",
  },
  {
    id: 420105,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "น้ำหมาน",
    subdistrictEn: "Nam Man",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย น้ำหมาน เลย 42000",
    name_th: "เมืองเลย น้ำหมาน เลย 42000",
    name_en: "Mueang Loei, Nam Man, Loei, 42000",
  },
  {
    id: 420106,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "เสี้ยว",
    subdistrictEn: "Siao",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย เสี้ยว เลย 42000",
    name_th: "เมืองเลย เสี้ยว เลย 42000",
    name_en: "Mueang Loei, Siao, Loei, 42000",
  },
  {
    id: 420107,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "นาอาน",
    subdistrictEn: "Na An",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย นาอาน เลย 42000",
    name_th: "เมืองเลย นาอาน เลย 42000",
    name_en: "Mueang Loei, Na An, Loei, 42000",
  },
  {
    id: 420108,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "นาโป่ง",
    subdistrictEn: "Na Pong",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย นาโป่ง เลย 42000",
    name_th: "เมืองเลย นาโป่ง เลย 42000",
    name_en: "Mueang Loei, Na Pong, Loei, 42000",
  },
  {
    id: 420109,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "นาดินดำ",
    subdistrictEn: "Na Din Dam",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย นาดินดำ เลย 42000",
    name_th: "เมืองเลย นาดินดำ เลย 42000",
    name_en: "Mueang Loei, Na Din Dam, Loei, 42000",
  },
  {
    id: 420110,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "น้ำสวย",
    subdistrictEn: "Nam Suai",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย น้ำสวย เลย 42000",
    name_th: "เมืองเลย น้ำสวย เลย 42000",
    name_en: "Mueang Loei, Nam Suai, Loei, 42000",
  },
  {
    id: 420111,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "ชัยพฤกษ์",
    subdistrictEn: "Chaiyaphruek",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย ชัยพฤกษ์ เลย 42000",
    name_th: "เมืองเลย ชัยพฤกษ์ เลย 42000",
    name_en: "Mueang Loei, Chaiyaphruek, Loei, 42000",
  },
  {
    id: 420112,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "นาแขม",
    subdistrictEn: "Na Khaem",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย นาแขม เลย 42000",
    name_th: "เมืองเลย นาแขม เลย 42000",
    name_en: "Mueang Loei, Na Khaem, Loei, 42000",
  },
  {
    id: 420113,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "ศรีสองรัก",
    subdistrictEn: "Si Song Rak",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42100,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย ศรีสองรัก เลย 42100",
    name_th: "เมืองเลย ศรีสองรัก เลย 42100",
    name_en: "Mueang Loei, Si Song Rak, Loei, 42100",
  },
  {
    id: 420114,
    districtTh: "เมืองเลย",
    districtEn: "Mueang Loei",
    subdistrictTh: "กกทอง",
    subdistrictEn: "Kok Thong",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42000,
    district_code: 4201,
    province_code: 30,
    name: "เมืองเลย กกทอง เลย 42000",
    name_th: "เมืองเลย กกทอง เลย 42000",
    name_en: "Mueang Loei, Kok Thong, Loei, 42000",
  },
  {
    id: 420201,
    districtTh: "นาด้วง",
    districtEn: "Na Duang",
    subdistrictTh: "นาด้วง",
    subdistrictEn: "Na Duang",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42210,
    district_code: 4202,
    province_code: 30,
    name: "นาด้วง นาด้วง เลย 42210",
    name_th: "นาด้วง นาด้วง เลย 42210",
    name_en: "Na Duang, Na Duang, Loei, 42210",
  },
  {
    id: 420202,
    districtTh: "นาด้วง",
    districtEn: "Na Duang",
    subdistrictTh: "นาดอกคำ",
    subdistrictEn: "Na Dok Kham",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42210,
    district_code: 4202,
    province_code: 30,
    name: "นาด้วง นาดอกคำ เลย 42210",
    name_th: "นาด้วง นาดอกคำ เลย 42210",
    name_en: "Na Duang, Na Dok Kham, Loei, 42210",
  },
  {
    id: 420203,
    districtTh: "นาด้วง",
    districtEn: "Na Duang",
    subdistrictTh: "ท่าสะอาด",
    subdistrictEn: "Tha Sa-at",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42210,
    district_code: 4202,
    province_code: 30,
    name: "นาด้วง ท่าสะอาด เลย 42210",
    name_th: "นาด้วง ท่าสะอาด เลย 42210",
    name_en: "Na Duang, Tha Sa-at, Loei, 42210",
  },
  {
    id: 420204,
    districtTh: "นาด้วง",
    districtEn: "Na Duang",
    subdistrictTh: "ท่าสวรรค์",
    subdistrictEn: "Tha Sawan",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42210,
    district_code: 4202,
    province_code: 30,
    name: "นาด้วง ท่าสวรรค์ เลย 42210",
    name_th: "นาด้วง ท่าสวรรค์ เลย 42210",
    name_en: "Na Duang, Tha Sawan, Loei, 42210",
  },
  {
    id: 420301,
    districtTh: "เชียงคาน",
    districtEn: "Chiang Khan",
    subdistrictTh: "เชียงคาน",
    subdistrictEn: "Chiang Khan",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42110,
    district_code: 4203,
    province_code: 30,
    name: "เชียงคาน เชียงคาน เลย 42110",
    name_th: "เชียงคาน เชียงคาน เลย 42110",
    name_en: "Chiang Khan, Chiang Khan, Loei, 42110",
  },
  {
    id: 420302,
    districtTh: "เชียงคาน",
    districtEn: "Chiang Khan",
    subdistrictTh: "ธาตุ",
    subdistrictEn: "That",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42110,
    district_code: 4203,
    province_code: 30,
    name: "เชียงคาน ธาตุ เลย 42110",
    name_th: "เชียงคาน ธาตุ เลย 42110",
    name_en: "Chiang Khan, That, Loei, 42110",
  },
  {
    id: 420303,
    districtTh: "เชียงคาน",
    districtEn: "Chiang Khan",
    subdistrictTh: "นาซ่าว",
    subdistrictEn: "Na Sao",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42110,
    district_code: 4203,
    province_code: 30,
    name: "เชียงคาน นาซ่าว เลย 42110",
    name_th: "เชียงคาน นาซ่าว เลย 42110",
    name_en: "Chiang Khan, Na Sao, Loei, 42110",
  },
  {
    id: 420304,
    districtTh: "เชียงคาน",
    districtEn: "Chiang Khan",
    subdistrictTh: "เขาแก้ว",
    subdistrictEn: "Khao Kaeo",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42110,
    district_code: 4203,
    province_code: 30,
    name: "เชียงคาน เขาแก้ว เลย 42110",
    name_th: "เชียงคาน เขาแก้ว เลย 42110",
    name_en: "Chiang Khan, Khao Kaeo, Loei, 42110",
  },
  {
    id: 420305,
    districtTh: "เชียงคาน",
    districtEn: "Chiang Khan",
    subdistrictTh: "ปากตม",
    subdistrictEn: "Pak Tom",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42110,
    district_code: 4203,
    province_code: 30,
    name: "เชียงคาน ปากตม เลย 42110",
    name_th: "เชียงคาน ปากตม เลย 42110",
    name_en: "Chiang Khan, Pak Tom, Loei, 42110",
  },
  {
    id: 420306,
    districtTh: "เชียงคาน",
    districtEn: "Chiang Khan",
    subdistrictTh: "บุฮม",
    subdistrictEn: "Bu Hom",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42110,
    district_code: 4203,
    province_code: 30,
    name: "เชียงคาน บุฮม เลย 42110",
    name_th: "เชียงคาน บุฮม เลย 42110",
    name_en: "Chiang Khan, Bu Hom, Loei, 42110",
  },
  {
    id: 420307,
    districtTh: "เชียงคาน",
    districtEn: "Chiang Khan",
    subdistrictTh: "จอมศรี",
    subdistrictEn: "Chom Si",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42110,
    district_code: 4203,
    province_code: 30,
    name: "เชียงคาน จอมศรี เลย 42110",
    name_th: "เชียงคาน จอมศรี เลย 42110",
    name_en: "Chiang Khan, Chom Si, Loei, 42110",
  },
  {
    id: 420308,
    districtTh: "เชียงคาน",
    districtEn: "Chiang Khan",
    subdistrictTh: "หาดทรายขาว",
    subdistrictEn: "Hat Sai Khao",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42110,
    district_code: 4203,
    province_code: 30,
    name: "เชียงคาน หาดทรายขาว เลย 42110",
    name_th: "เชียงคาน หาดทรายขาว เลย 42110",
    name_en: "Chiang Khan, Hat Sai Khao, Loei, 42110",
  },
  {
    id: 420401,
    districtTh: "ปากชม",
    districtEn: "Pak Chom",
    subdistrictTh: "ปากชม",
    subdistrictEn: "Pak Chom",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42150,
    district_code: 4204,
    province_code: 30,
    name: "ปากชม ปากชม เลย 42150",
    name_th: "ปากชม ปากชม เลย 42150",
    name_en: "Pak Chom, Pak Chom, Loei, 42150",
  },
  {
    id: 420402,
    districtTh: "ปากชม",
    districtEn: "Pak Chom",
    subdistrictTh: "เชียงกลม",
    subdistrictEn: "Chiang Klom",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42150,
    district_code: 4204,
    province_code: 30,
    name: "ปากชม เชียงกลม เลย 42150",
    name_th: "ปากชม เชียงกลม เลย 42150",
    name_en: "Pak Chom, Chiang Klom, Loei, 42150",
  },
  {
    id: 420403,
    districtTh: "ปากชม",
    districtEn: "Pak Chom",
    subdistrictTh: "หาดคัมภีร์",
    subdistrictEn: "Hat Khamphi",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42150,
    district_code: 4204,
    province_code: 30,
    name: "ปากชม หาดคัมภีร์ เลย 42150",
    name_th: "ปากชม หาดคัมภีร์ เลย 42150",
    name_en: "Pak Chom, Hat Khamphi, Loei, 42150",
  },
  {
    id: 420404,
    districtTh: "ปากชม",
    districtEn: "Pak Chom",
    subdistrictTh: "ห้วยบ่อซืน",
    subdistrictEn: "Huai Bo Suen",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42150,
    district_code: 4204,
    province_code: 30,
    name: "ปากชม ห้วยบ่อซืน เลย 42150",
    name_th: "ปากชม ห้วยบ่อซืน เลย 42150",
    name_en: "Pak Chom, Huai Bo Suen, Loei, 42150",
  },
  {
    id: 420405,
    districtTh: "ปากชม",
    districtEn: "Pak Chom",
    subdistrictTh: "ห้วยพิชัย",
    subdistrictEn: "Huai Phichai",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42150,
    district_code: 4204,
    province_code: 30,
    name: "ปากชม ห้วยพิชัย เลย 42150",
    name_th: "ปากชม ห้วยพิชัย เลย 42150",
    name_en: "Pak Chom, Huai Phichai, Loei, 42150",
  },
  {
    id: 420406,
    districtTh: "ปากชม",
    districtEn: "Pak Chom",
    subdistrictTh: "ชมเจริญ",
    subdistrictEn: "Chom Charoen",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42150,
    district_code: 4204,
    province_code: 30,
    name: "ปากชม ชมเจริญ เลย 42150",
    name_th: "ปากชม ชมเจริญ เลย 42150",
    name_en: "Pak Chom, Chom Charoen, Loei, 42150",
  },
  {
    id: 420501,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "ด่านซ้าย",
    subdistrictEn: "Dan Sai",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย ด่านซ้าย เลย 42120",
    name_th: "ด่านซ้าย ด่านซ้าย เลย 42120",
    name_en: "Dan Sai, Dan Sai, Loei, 42120",
  },
  {
    id: 420502,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "ปากหมัน",
    subdistrictEn: "Pak Man",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย ปากหมัน เลย 42120",
    name_th: "ด่านซ้าย ปากหมัน เลย 42120",
    name_en: "Dan Sai, Pak Man, Loei, 42120",
  },
  {
    id: 420503,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย นาดี เลย 42120",
    name_th: "ด่านซ้าย นาดี เลย 42120",
    name_en: "Dan Sai, Na Di, Loei, 42120",
  },
  {
    id: 420504,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "โคกงาม",
    subdistrictEn: "Khok Ngam",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย โคกงาม เลย 42120",
    name_th: "ด่านซ้าย โคกงาม เลย 42120",
    name_en: "Dan Sai, Khok Ngam, Loei, 42120",
  },
  {
    id: 420505,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "โพนสูง",
    subdistrictEn: "Phon Sung",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย โพนสูง เลย 42120",
    name_th: "ด่านซ้าย โพนสูง เลย 42120",
    name_en: "Dan Sai, Phon Sung, Loei, 42120",
  },
  {
    id: 420506,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "อิปุ่ม",
    subdistrictEn: "Ipum",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย อิปุ่ม เลย 42120",
    name_th: "ด่านซ้าย อิปุ่ม เลย 42120",
    name_en: "Dan Sai, Ipum, Loei, 42120",
  },
  {
    id: 420507,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "กกสะทอน",
    subdistrictEn: "Kok Sathon",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย กกสะทอน เลย 42120",
    name_th: "ด่านซ้าย กกสะทอน เลย 42120",
    name_en: "Dan Sai, Kok Sathon, Loei, 42120",
  },
  {
    id: 420508,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "โป่ง",
    subdistrictEn: "Pong",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย โป่ง เลย 42120",
    name_th: "ด่านซ้าย โป่ง เลย 42120",
    name_en: "Dan Sai, Pong, Loei, 42120",
  },
  {
    id: 420509,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "วังยาว",
    subdistrictEn: "Wang Yao",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย วังยาว เลย 42120",
    name_th: "ด่านซ้าย วังยาว เลย 42120",
    name_en: "Dan Sai, Wang Yao, Loei, 42120",
  },
  {
    id: 420510,
    districtTh: "ด่านซ้าย",
    districtEn: "Dan Sai",
    subdistrictTh: "นาหอ",
    subdistrictEn: "Na Ho",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42120,
    district_code: 4205,
    province_code: 30,
    name: "ด่านซ้าย นาหอ เลย 42120",
    name_th: "ด่านซ้าย นาหอ เลย 42120",
    name_en: "Dan Sai, Na Ho, Loei, 42120",
  },
  {
    id: 420601,
    districtTh: "นาแห้ว",
    districtEn: "Na Haeo",
    subdistrictTh: "นาแห้ว",
    subdistrictEn: "Na Haeo",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42170,
    district_code: 4206,
    province_code: 30,
    name: "นาแห้ว นาแห้ว เลย 42170",
    name_th: "นาแห้ว นาแห้ว เลย 42170",
    name_en: "Na Haeo, Na Haeo, Loei, 42170",
  },
  {
    id: 420602,
    districtTh: "นาแห้ว",
    districtEn: "Na Haeo",
    subdistrictTh: "แสงภา",
    subdistrictEn: "Saeng Pha",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42170,
    district_code: 4206,
    province_code: 30,
    name: "นาแห้ว แสงภา เลย 42170",
    name_th: "นาแห้ว แสงภา เลย 42170",
    name_en: "Na Haeo, Saeng Pha, Loei, 42170",
  },
  {
    id: 420603,
    districtTh: "นาแห้ว",
    districtEn: "Na Haeo",
    subdistrictTh: "นาพึง",
    subdistrictEn: "Na Phueng",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42170,
    district_code: 4206,
    province_code: 30,
    name: "นาแห้ว นาพึง เลย 42170",
    name_th: "นาแห้ว นาพึง เลย 42170",
    name_en: "Na Haeo, Na Phueng, Loei, 42170",
  },
  {
    id: 420604,
    districtTh: "นาแห้ว",
    districtEn: "Na Haeo",
    subdistrictTh: "นามาลา",
    subdistrictEn: "Na Ma La",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42170,
    district_code: 4206,
    province_code: 30,
    name: "นาแห้ว นามาลา เลย 42170",
    name_th: "นาแห้ว นามาลา เลย 42170",
    name_en: "Na Haeo, Na Ma La, Loei, 42170",
  },
  {
    id: 420605,
    districtTh: "นาแห้ว",
    districtEn: "Na Haeo",
    subdistrictTh: "เหล่ากอหก",
    subdistrictEn: "Lao Ko Hok",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42170,
    district_code: 4206,
    province_code: 30,
    name: "นาแห้ว เหล่ากอหก เลย 42170",
    name_th: "นาแห้ว เหล่ากอหก เลย 42170",
    name_en: "Na Haeo, Lao Ko Hok, Loei, 42170",
  },
  {
    id: 420701,
    districtTh: "ภูเรือ",
    districtEn: "Phu Ruea",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42160,
    district_code: 4207,
    province_code: 30,
    name: "ภูเรือ หนองบัว เลย 42160",
    name_th: "ภูเรือ หนองบัว เลย 42160",
    name_en: "Phu Ruea, Nong Bua, Loei, 42160",
  },
  {
    id: 420702,
    districtTh: "ภูเรือ",
    districtEn: "Phu Ruea",
    subdistrictTh: "ท่าศาลา",
    subdistrictEn: "Tha Sala",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42160,
    district_code: 4207,
    province_code: 30,
    name: "ภูเรือ ท่าศาลา เลย 42160",
    name_th: "ภูเรือ ท่าศาลา เลย 42160",
    name_en: "Phu Ruea, Tha Sala, Loei, 42160",
  },
  {
    id: 420703,
    districtTh: "ภูเรือ",
    districtEn: "Phu Ruea",
    subdistrictTh: "ร่องจิก",
    subdistrictEn: "Rong Chik",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42160,
    district_code: 4207,
    province_code: 30,
    name: "ภูเรือ ร่องจิก เลย 42160",
    name_th: "ภูเรือ ร่องจิก เลย 42160",
    name_en: "Phu Ruea, Rong Chik, Loei, 42160",
  },
  {
    id: 420704,
    districtTh: "ภูเรือ",
    districtEn: "Phu Ruea",
    subdistrictTh: "ปลาบ่า",
    subdistrictEn: "Pla Ba",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42160,
    district_code: 4207,
    province_code: 30,
    name: "ภูเรือ ปลาบ่า เลย 42160",
    name_th: "ภูเรือ ปลาบ่า เลย 42160",
    name_en: "Phu Ruea, Pla Ba, Loei, 42160",
  },
  {
    id: 420705,
    districtTh: "ภูเรือ",
    districtEn: "Phu Ruea",
    subdistrictTh: "ลาดค่าง",
    subdistrictEn: "Lat Khang",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42160,
    district_code: 4207,
    province_code: 30,
    name: "ภูเรือ ลาดค่าง เลย 42160",
    name_th: "ภูเรือ ลาดค่าง เลย 42160",
    name_en: "Phu Ruea, Lat Khang, Loei, 42160",
  },
  {
    id: 420706,
    districtTh: "ภูเรือ",
    districtEn: "Phu Ruea",
    subdistrictTh: "สานตม",
    subdistrictEn: "San Tom",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42160,
    district_code: 4207,
    province_code: 30,
    name: "ภูเรือ สานตม เลย 42160",
    name_th: "ภูเรือ สานตม เลย 42160",
    name_en: "Phu Ruea, San Tom, Loei, 42160",
  },
  {
    id: 420801,
    districtTh: "ท่าลี่",
    districtEn: "Tha Li",
    subdistrictTh: "ท่าลี่",
    subdistrictEn: "Tha Li",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42140,
    district_code: 4208,
    province_code: 30,
    name: "ท่าลี่ ท่าลี่ เลย 42140",
    name_th: "ท่าลี่ ท่าลี่ เลย 42140",
    name_en: "Tha Li, Tha Li, Loei, 42140",
  },
  {
    id: 420802,
    districtTh: "ท่าลี่",
    districtEn: "Tha Li",
    subdistrictTh: "หนองผือ",
    subdistrictEn: "Nong Phue",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42140,
    district_code: 4208,
    province_code: 30,
    name: "ท่าลี่ หนองผือ เลย 42140",
    name_th: "ท่าลี่ หนองผือ เลย 42140",
    name_en: "Tha Li, Nong Phue, Loei, 42140",
  },
  {
    id: 420803,
    districtTh: "ท่าลี่",
    districtEn: "Tha Li",
    subdistrictTh: "อาฮี",
    subdistrictEn: "A Hi",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42140,
    district_code: 4208,
    province_code: 30,
    name: "ท่าลี่ อาฮี เลย 42140",
    name_th: "ท่าลี่ อาฮี เลย 42140",
    name_en: "Tha Li, A Hi, Loei, 42140",
  },
  {
    id: 420804,
    districtTh: "ท่าลี่",
    districtEn: "Tha Li",
    subdistrictTh: "น้ำแคม",
    subdistrictEn: "Nam Khaem",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42140,
    district_code: 4208,
    province_code: 30,
    name: "ท่าลี่ น้ำแคม เลย 42140",
    name_th: "ท่าลี่ น้ำแคม เลย 42140",
    name_en: "Tha Li, Nam Khaem, Loei, 42140",
  },
  {
    id: 420805,
    districtTh: "ท่าลี่",
    districtEn: "Tha Li",
    subdistrictTh: "โคกใหญ่",
    subdistrictEn: "Khok Yai",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42140,
    district_code: 4208,
    province_code: 30,
    name: "ท่าลี่ โคกใหญ่ เลย 42140",
    name_th: "ท่าลี่ โคกใหญ่ เลย 42140",
    name_en: "Tha Li, Khok Yai, Loei, 42140",
  },
  {
    id: 420806,
    districtTh: "ท่าลี่",
    districtEn: "Tha Li",
    subdistrictTh: "น้ำทูน",
    subdistrictEn: "Nam Thun",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42140,
    district_code: 4208,
    province_code: 30,
    name: "ท่าลี่ น้ำทูน เลย 42140",
    name_th: "ท่าลี่ น้ำทูน เลย 42140",
    name_en: "Tha Li, Nam Thun, Loei, 42140",
  },
  {
    id: 420901,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "วังสะพุง",
    subdistrictEn: "Wang Saphung",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง วังสะพุง เลย 42130",
    name_th: "วังสะพุง วังสะพุง เลย 42130",
    name_en: "Wang Saphung, Wang Saphung, Loei, 42130",
  },
  {
    id: 420902,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "ทรายขาว",
    subdistrictEn: "Sai Khao",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง ทรายขาว เลย 42130",
    name_th: "วังสะพุง ทรายขาว เลย 42130",
    name_en: "Wang Saphung, Sai Khao, Loei, 42130",
  },
  {
    id: 420903,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "หนองหญ้าปล้อง",
    subdistrictEn: "Nong Ya Plong",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง หนองหญ้าปล้อง เลย 42130",
    name_th: "วังสะพุง หนองหญ้าปล้อง เลย 42130",
    name_en: "Wang Saphung, Nong Ya Plong, Loei, 42130",
  },
  {
    id: 420904,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "หนองงิ้ว",
    subdistrictEn: "Nong Ngio",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง หนองงิ้ว เลย 42130",
    name_th: "วังสะพุง หนองงิ้ว เลย 42130",
    name_en: "Wang Saphung, Nong Ngio, Loei, 42130",
  },
  {
    id: 420905,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "ปากปวน",
    subdistrictEn: "Pak Puan",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง ปากปวน เลย 42130",
    name_th: "วังสะพุง ปากปวน เลย 42130",
    name_en: "Wang Saphung, Pak Puan, Loei, 42130",
  },
  {
    id: 420906,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "ผาน้อย",
    subdistrictEn: "Pha Noi",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง ผาน้อย เลย 42130",
    name_th: "วังสะพุง ผาน้อย เลย 42130",
    name_en: "Wang Saphung, Pha Noi, Loei, 42130",
  },
  {
    id: 420910,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "ผาบิ้ง",
    subdistrictEn: "Pha Bing",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง ผาบิ้ง เลย 42130",
    name_th: "วังสะพุง ผาบิ้ง เลย 42130",
    name_en: "Wang Saphung, Pha Bing, Loei, 42130",
  },
  {
    id: 420911,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "เขาหลวง",
    subdistrictEn: "Khao Luang",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง เขาหลวง เลย 42130",
    name_th: "วังสะพุง เขาหลวง เลย 42130",
    name_en: "Wang Saphung, Khao Luang, Loei, 42130",
  },
  {
    id: 420912,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "โคกขมิ้น",
    subdistrictEn: "Khok Khamin",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง โคกขมิ้น เลย 42130",
    name_th: "วังสะพุง โคกขมิ้น เลย 42130",
    name_en: "Wang Saphung, Khok Khamin, Loei, 42130",
  },
  {
    id: 420913,
    districtTh: "วังสะพุง",
    districtEn: "Wang Saphung",
    subdistrictTh: "ศรีสงคราม",
    subdistrictEn: "Si Songkhram",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42130,
    district_code: 4209,
    province_code: 30,
    name: "วังสะพุง ศรีสงคราม เลย 42130",
    name_th: "วังสะพุง ศรีสงคราม เลย 42130",
    name_en: "Wang Saphung, Si Songkhram, Loei, 42130",
  },
  {
    id: 421001,
    districtTh: "ภูกระดึง",
    districtEn: "Phu Kradueng",
    subdistrictTh: "ศรีฐาน",
    subdistrictEn: "Si Than",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42180,
    district_code: 4210,
    province_code: 30,
    name: "ภูกระดึง ศรีฐาน เลย 42180",
    name_th: "ภูกระดึง ศรีฐาน เลย 42180",
    name_en: "Phu Kradueng, Si Than, Loei, 42180",
  },
  {
    id: 421005,
    districtTh: "ภูกระดึง",
    districtEn: "Phu Kradueng",
    subdistrictTh: "ผานกเค้า",
    subdistrictEn: "Pha Nok Khao",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42180,
    district_code: 4210,
    province_code: 30,
    name: "ภูกระดึง ผานกเค้า เลย 42180",
    name_th: "ภูกระดึง ผานกเค้า เลย 42180",
    name_en: "Phu Kradueng, Pha Nok Khao, Loei, 42180",
  },
  {
    id: 421007,
    districtTh: "ภูกระดึง",
    districtEn: "Phu Kradueng",
    subdistrictTh: "ภูกระดึง",
    subdistrictEn: "Phu Kradueng",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42180,
    district_code: 4210,
    province_code: 30,
    name: "ภูกระดึง ภูกระดึง เลย 42180",
    name_th: "ภูกระดึง ภูกระดึง เลย 42180",
    name_en: "Phu Kradueng, Phu Kradueng, Loei, 42180",
  },
  {
    id: 421010,
    districtTh: "ภูกระดึง",
    districtEn: "Phu Kradueng",
    subdistrictTh: "ห้วยส้ม",
    subdistrictEn: "Huai Som",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42180,
    district_code: 4210,
    province_code: 30,
    name: "ภูกระดึง ห้วยส้ม เลย 42180",
    name_th: "ภูกระดึง ห้วยส้ม เลย 42180",
    name_en: "Phu Kradueng, Huai Som, Loei, 42180",
  },
  {
    id: 421101,
    districtTh: "ภูหลวง",
    districtEn: "Phu Luang",
    subdistrictTh: "ภูหอ",
    subdistrictEn: "Phu Ho",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42230,
    district_code: 4211,
    province_code: 30,
    name: "ภูหลวง ภูหอ เลย 42230",
    name_th: "ภูหลวง ภูหอ เลย 42230",
    name_en: "Phu Luang, Phu Ho, Loei, 42230",
  },
  {
    id: 421102,
    districtTh: "ภูหลวง",
    districtEn: "Phu Luang",
    subdistrictTh: "หนองคัน",
    subdistrictEn: "Nong Khan",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42230,
    district_code: 4211,
    province_code: 30,
    name: "ภูหลวง หนองคัน เลย 42230",
    name_th: "ภูหลวง หนองคัน เลย 42230",
    name_en: "Phu Luang, Nong Khan, Loei, 42230",
  },
  {
    id: 421104,
    districtTh: "ภูหลวง",
    districtEn: "Phu Luang",
    subdistrictTh: "ห้วยสีเสียด",
    subdistrictEn: "Huai Sisiat",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42230,
    district_code: 4211,
    province_code: 30,
    name: "ภูหลวง ห้วยสีเสียด เลย 42230",
    name_th: "ภูหลวง ห้วยสีเสียด เลย 42230",
    name_en: "Phu Luang, Huai Sisiat, Loei, 42230",
  },
  {
    id: 421105,
    districtTh: "ภูหลวง",
    districtEn: "Phu Luang",
    subdistrictTh: "เลยวังไสย์",
    subdistrictEn: "Loei Wang Sai",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42230,
    district_code: 4211,
    province_code: 30,
    name: "ภูหลวง เลยวังไสย์ เลย 42230",
    name_th: "ภูหลวง เลยวังไสย์ เลย 42230",
    name_en: "Phu Luang, Loei Wang Sai, Loei, 42230",
  },
  {
    id: 421106,
    districtTh: "ภูหลวง",
    districtEn: "Phu Luang",
    subdistrictTh: "แก่งศรีภูมิ",
    subdistrictEn: "Kaeng Si Phum",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42230,
    district_code: 4211,
    province_code: 30,
    name: "ภูหลวง แก่งศรีภูมิ เลย 42230",
    name_th: "ภูหลวง แก่งศรีภูมิ เลย 42230",
    name_en: "Phu Luang, Kaeng Si Phum, Loei, 42230",
  },
  {
    id: 421201,
    districtTh: "ผาขาว",
    districtEn: "Pha Khao",
    subdistrictTh: "ผาขาว",
    subdistrictEn: "Pha Khao",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42240,
    district_code: 4212,
    province_code: 30,
    name: "ผาขาว ผาขาว เลย 42240",
    name_th: "ผาขาว ผาขาว เลย 42240",
    name_en: "Pha Khao, Pha Khao, Loei, 42240",
  },
  {
    id: 421202,
    districtTh: "ผาขาว",
    districtEn: "Pha Khao",
    subdistrictTh: "ท่าช้างคล้อง",
    subdistrictEn: "Tha Chang Khlong",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42240,
    district_code: 4212,
    province_code: 30,
    name: "ผาขาว ท่าช้างคล้อง เลย 42240",
    name_th: "ผาขาว ท่าช้างคล้อง เลย 42240",
    name_en: "Pha Khao, Tha Chang Khlong, Loei, 42240",
  },
  {
    id: 421203,
    districtTh: "ผาขาว",
    districtEn: "Pha Khao",
    subdistrictTh: "โนนปอแดง",
    subdistrictEn: "Non Po Daeng",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42240,
    district_code: 4212,
    province_code: 30,
    name: "ผาขาว โนนปอแดง เลย 42240",
    name_th: "ผาขาว โนนปอแดง เลย 42240",
    name_en: "Pha Khao, Non Po Daeng, Loei, 42240",
  },
  {
    id: 421204,
    districtTh: "ผาขาว",
    districtEn: "Pha Khao",
    subdistrictTh: "โนนป่าซาง",
    subdistrictEn: "Non Pa Sang",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42240,
    district_code: 4212,
    province_code: 30,
    name: "ผาขาว โนนป่าซาง เลย 42240",
    name_th: "ผาขาว โนนป่าซาง เลย 42240",
    name_en: "Pha Khao, Non Pa Sang, Loei, 42240",
  },
  {
    id: 421205,
    districtTh: "ผาขาว",
    districtEn: "Pha Khao",
    subdistrictTh: "บ้านเพิ่ม",
    subdistrictEn: "Ban Phoem",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42240,
    district_code: 4212,
    province_code: 30,
    name: "ผาขาว บ้านเพิ่ม เลย 42240",
    name_th: "ผาขาว บ้านเพิ่ม เลย 42240",
    name_en: "Pha Khao, Ban Phoem, Loei, 42240",
  },
  {
    id: 421301,
    districtTh: "เอราวัณ",
    districtEn: "Erawan",
    subdistrictTh: "เอราวัณ",
    subdistrictEn: "Erawan",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42220,
    district_code: 4213,
    province_code: 30,
    name: "เอราวัณ เอราวัณ เลย 42220",
    name_th: "เอราวัณ เอราวัณ เลย 42220",
    name_en: "Erawan, Erawan, Loei, 42220",
  },
  {
    id: 421302,
    districtTh: "เอราวัณ",
    districtEn: "Erawan",
    subdistrictTh: "ผาอินทร์แปลง",
    subdistrictEn: "Pha In Plaeng",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42220,
    district_code: 4213,
    province_code: 30,
    name: "เอราวัณ ผาอินทร์แปลง เลย 42220",
    name_th: "เอราวัณ ผาอินทร์แปลง เลย 42220",
    name_en: "Erawan, Pha In Plaeng, Loei, 42220",
  },
  {
    id: 421303,
    districtTh: "เอราวัณ",
    districtEn: "Erawan",
    subdistrictTh: "ผาสามยอด",
    subdistrictEn: "Pha Sam Yot",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42220,
    district_code: 4213,
    province_code: 30,
    name: "เอราวัณ ผาสามยอด เลย 42220",
    name_th: "เอราวัณ ผาสามยอด เลย 42220",
    name_en: "Erawan, Pha Sam Yot, Loei, 42220",
  },
  {
    id: 421304,
    districtTh: "เอราวัณ",
    districtEn: "Erawan",
    subdistrictTh: "ทรัพย์ไพวัลย์",
    subdistrictEn: "Sap Phaiwan",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42220,
    district_code: 4213,
    province_code: 30,
    name: "เอราวัณ ทรัพย์ไพวัลย์ เลย 42220",
    name_th: "เอราวัณ ทรัพย์ไพวัลย์ เลย 42220",
    name_en: "Erawan, Sap Phaiwan, Loei, 42220",
  },
  {
    id: 421401,
    districtTh: "หนองหิน",
    districtEn: "Nong Hin",
    subdistrictTh: "หนองหิน",
    subdistrictEn: "Nong Hin",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42190,
    district_code: 4214,
    province_code: 30,
    name: "หนองหิน หนองหิน เลย 42190",
    name_th: "หนองหิน หนองหิน เลย 42190",
    name_en: "Nong Hin, Nong Hin, Loei, 42190",
  },
  {
    id: 421402,
    districtTh: "หนองหิน",
    districtEn: "Nong Hin",
    subdistrictTh: "ตาดข่า",
    subdistrictEn: "Tat Kha",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42190,
    district_code: 4214,
    province_code: 30,
    name: "หนองหิน ตาดข่า เลย 42190",
    name_th: "หนองหิน ตาดข่า เลย 42190",
    name_en: "Nong Hin, Tat Kha, Loei, 42190",
  },
  {
    id: 421403,
    districtTh: "หนองหิน",
    districtEn: "Nong Hin",
    subdistrictTh: "ปวนพุ",
    subdistrictEn: "Puan Phu",
    provinceTh: "เลย",
    provinceEn: "Loei",
    zipcode: 42190,
    district_code: 4214,
    province_code: 30,
    name: "หนองหิน ปวนพุ เลย 42190",
    name_th: "หนองหิน ปวนพุ เลย 42190",
    name_en: "Nong Hin, Puan Phu, Loei, 42190",
  },
  {
    id: 430101,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย ในเมือง หนองคาย 43000",
    name_th: "เมืองหนองคาย ในเมือง หนองคาย 43000",
    name_en: "Mueang Nong Khai, Nai Mueang, Nong Khai, 43000",
  },
  {
    id: 430102,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "มีชัย",
    subdistrictEn: "Mi Chai",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย มีชัย หนองคาย 43000",
    name_th: "เมืองหนองคาย มีชัย หนองคาย 43000",
    name_en: "Mueang Nong Khai, Mi Chai, Nong Khai, 43000",
  },
  {
    id: 430103,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "โพธิ์ชัย",
    subdistrictEn: "Pho Chai",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย โพธิ์ชัย หนองคาย 43000",
    name_th: "เมืองหนองคาย โพธิ์ชัย หนองคาย 43000",
    name_en: "Mueang Nong Khai, Pho Chai, Nong Khai, 43000",
  },
  {
    id: 430104,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "กวนวัน",
    subdistrictEn: "Kuan Wan",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย กวนวัน หนองคาย 43000",
    name_th: "เมืองหนองคาย กวนวัน หนองคาย 43000",
    name_en: "Mueang Nong Khai, Kuan Wan, Nong Khai, 43000",
  },
  {
    id: 430105,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "เวียงคุก",
    subdistrictEn: "Wiang Khuk",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย เวียงคุก หนองคาย 43000",
    name_th: "เมืองหนองคาย เวียงคุก หนองคาย 43000",
    name_en: "Mueang Nong Khai, Wiang Khuk, Nong Khai, 43000",
  },
  {
    id: 430106,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "วัดธาตุ",
    subdistrictEn: "Wat That",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย วัดธาตุ หนองคาย 43000",
    name_th: "เมืองหนองคาย วัดธาตุ หนองคาย 43000",
    name_en: "Mueang Nong Khai, Wat That, Nong Khai, 43000",
  },
  {
    id: 430107,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "หาดคำ",
    subdistrictEn: "Hat Kham",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย หาดคำ หนองคาย 43000",
    name_th: "เมืองหนองคาย หาดคำ หนองคาย 43000",
    name_en: "Mueang Nong Khai, Hat Kham, Nong Khai, 43000",
  },
  {
    id: 430108,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "หินโงม",
    subdistrictEn: "Hin Ngom",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย หินโงม หนองคาย 43000",
    name_th: "เมืองหนองคาย หินโงม หนองคาย 43000",
    name_en: "Mueang Nong Khai, Hin Ngom, Nong Khai, 43000",
  },
  {
    id: 430109,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "บ้านเดื่อ",
    subdistrictEn: "Ban Duea",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย บ้านเดื่อ หนองคาย 43000",
    name_th: "เมืองหนองคาย บ้านเดื่อ หนองคาย 43000",
    name_en: "Mueang Nong Khai, Ban Duea, Nong Khai, 43000",
  },
  {
    id: 430110,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "ค่ายบกหวาน",
    subdistrictEn: "Khai Bok Wan",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43100,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย ค่ายบกหวาน หนองคาย 43100",
    name_th: "เมืองหนองคาย ค่ายบกหวาน หนองคาย 43100",
    name_en: "Mueang Nong Khai, Khai Bok Wan, Nong Khai, 43100",
  },
  {
    id: 430111,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "สองห้อง",
    subdistrictEn: "Song Hong",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43100,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย สองห้อง หนองคาย 43100",
    name_th: "เมืองหนองคาย สองห้อง หนองคาย 43100",
    name_en: "Mueang Nong Khai, Song Hong, Nong Khai, 43100",
  },
  {
    id: 430113,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "พระธาตุบังพวน",
    subdistrictEn: "Phra That Bang Phuan",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43100,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย พระธาตุบังพวน หนองคาย 43100",
    name_th: "เมืองหนองคาย พระธาตุบังพวน หนองคาย 43100",
    name_en: "Mueang Nong Khai, Phra That Bang Phuan, Nong Khai, 43100",
  },
  {
    id: 430116,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "หนองกอมเกาะ",
    subdistrictEn: "Nong Kom Ko",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย หนองกอมเกาะ หนองคาย 43000",
    name_th: "เมืองหนองคาย หนองกอมเกาะ หนองคาย 43000",
    name_en: "Mueang Nong Khai, Nong Kom Ko, Nong Khai, 43000",
  },
  {
    id: 430117,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "ปะโค",
    subdistrictEn: "Pa Kho",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย ปะโค หนองคาย 43000",
    name_th: "เมืองหนองคาย ปะโค หนองคาย 43000",
    name_en: "Mueang Nong Khai, Pa Kho, Nong Khai, 43000",
  },
  {
    id: 430118,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "เมืองหมี",
    subdistrictEn: "Mueang Mi",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย เมืองหมี หนองคาย 43000",
    name_th: "เมืองหนองคาย เมืองหมี หนองคาย 43000",
    name_en: "Mueang Nong Khai, Mueang Mi, Nong Khai, 43000",
  },
  {
    id: 430119,
    districtTh: "เมืองหนองคาย",
    districtEn: "Mueang Nong Khai",
    subdistrictTh: "สีกาย",
    subdistrictEn: "Si Kai",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43000,
    district_code: 4301,
    province_code: 31,
    name: "เมืองหนองคาย สีกาย หนองคาย 43000",
    name_th: "เมืองหนองคาย สีกาย หนองคาย 43000",
    name_en: "Mueang Nong Khai, Si Kai, Nong Khai, 43000",
  },
  {
    id: 430201,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "ท่าบ่อ",
    subdistrictEn: "Tha Bo",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ ท่าบ่อ หนองคาย 43110",
    name_th: "ท่าบ่อ ท่าบ่อ หนองคาย 43110",
    name_en: "Tha Bo, Tha Bo, Nong Khai, 43110",
  },
  {
    id: 430202,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "น้ำโมง",
    subdistrictEn: "Nam Mong",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ น้ำโมง หนองคาย 43110",
    name_th: "ท่าบ่อ น้ำโมง หนองคาย 43110",
    name_en: "Tha Bo, Nam Mong, Nong Khai, 43110",
  },
  {
    id: 430203,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "กองนาง",
    subdistrictEn: "Kong Nang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ กองนาง หนองคาย 43110",
    name_th: "ท่าบ่อ กองนาง หนองคาย 43110",
    name_en: "Tha Bo, Kong Nang, Nong Khai, 43110",
  },
  {
    id: 430204,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "โคกคอน",
    subdistrictEn: "Khok Khon",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ โคกคอน หนองคาย 43110",
    name_th: "ท่าบ่อ โคกคอน หนองคาย 43110",
    name_en: "Tha Bo, Khok Khon, Nong Khai, 43110",
  },
  {
    id: 430205,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "บ้านเดื่อ",
    subdistrictEn: "Ban Duea",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ บ้านเดื่อ หนองคาย 43110",
    name_th: "ท่าบ่อ บ้านเดื่อ หนองคาย 43110",
    name_en: "Tha Bo, Ban Duea, Nong Khai, 43110",
  },
  {
    id: 430206,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "บ้านถ่อน",
    subdistrictEn: "Ban Thon",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ บ้านถ่อน หนองคาย 43110",
    name_th: "ท่าบ่อ บ้านถ่อน หนองคาย 43110",
    name_en: "Tha Bo, Ban Thon, Nong Khai, 43110",
  },
  {
    id: 430207,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "บ้านว่าน",
    subdistrictEn: "Ban Wan",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ บ้านว่าน หนองคาย 43110",
    name_th: "ท่าบ่อ บ้านว่าน หนองคาย 43110",
    name_en: "Tha Bo, Ban Wan, Nong Khai, 43110",
  },
  {
    id: 430208,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "นาข่า",
    subdistrictEn: "Na Kha",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ นาข่า หนองคาย 43110",
    name_th: "ท่าบ่อ นาข่า หนองคาย 43110",
    name_en: "Tha Bo, Na Kha, Nong Khai, 43110",
  },
  {
    id: 430209,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "โพนสา",
    subdistrictEn: "Phon Sa",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ โพนสา หนองคาย 43110",
    name_th: "ท่าบ่อ โพนสา หนองคาย 43110",
    name_en: "Tha Bo, Phon Sa, Nong Khai, 43110",
  },
  {
    id: 430210,
    districtTh: "ท่าบ่อ",
    districtEn: "Tha Bo",
    subdistrictTh: "หนองนาง",
    subdistrictEn: "Nong Nang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43110,
    district_code: 4302,
    province_code: 31,
    name: "ท่าบ่อ หนองนาง หนองคาย 43110",
    name_th: "ท่าบ่อ หนองนาง หนองคาย 43110",
    name_en: "Tha Bo, Nong Nang, Nong Khai, 43110",
  },
  {
    id: 430501,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "จุมพล",
    subdistrictEn: "Chumphon",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย จุมพล หนองคาย 43120",
    name_th: "โพนพิสัย จุมพล หนองคาย 43120",
    name_en: "Phon Phisai, Chumphon, Nong Khai, 43120",
  },
  {
    id: 430502,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "วัดหลวง",
    subdistrictEn: "Wat Luang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย วัดหลวง หนองคาย 43120",
    name_th: "โพนพิสัย วัดหลวง หนองคาย 43120",
    name_en: "Phon Phisai, Wat Luang, Nong Khai, 43120",
  },
  {
    id: 430503,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "กุดบง",
    subdistrictEn: "Kut Bong",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย กุดบง หนองคาย 43120",
    name_th: "โพนพิสัย กุดบง หนองคาย 43120",
    name_en: "Phon Phisai, Kut Bong, Nong Khai, 43120",
  },
  {
    id: 430504,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "ชุมช้าง",
    subdistrictEn: "Chum Chang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย ชุมช้าง หนองคาย 43120",
    name_th: "โพนพิสัย ชุมช้าง หนองคาย 43120",
    name_en: "Phon Phisai, Chum Chang, Nong Khai, 43120",
  },
  {
    id: 430506,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "ทุ่งหลวง",
    subdistrictEn: "Thung Luang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย ทุ่งหลวง หนองคาย 43120",
    name_th: "โพนพิสัย ทุ่งหลวง หนองคาย 43120",
    name_en: "Phon Phisai, Thung Luang, Nong Khai, 43120",
  },
  {
    id: 430507,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "เหล่าต่างคำ",
    subdistrictEn: "Lao Tang Kham",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย เหล่าต่างคำ หนองคาย 43120",
    name_th: "โพนพิสัย เหล่าต่างคำ หนองคาย 43120",
    name_en: "Phon Phisai, Lao Tang Kham, Nong Khai, 43120",
  },
  {
    id: 430508,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "นาหนัง",
    subdistrictEn: "Na Nang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย นาหนัง หนองคาย 43120",
    name_th: "โพนพิสัย นาหนัง หนองคาย 43120",
    name_en: "Phon Phisai, Na Nang, Nong Khai, 43120",
  },
  {
    id: 430509,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "เซิม",
    subdistrictEn: "Soem",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย เซิม หนองคาย 43120",
    name_th: "โพนพิสัย เซิม หนองคาย 43120",
    name_en: "Phon Phisai, Soem, Nong Khai, 43120",
  },
  {
    id: 430513,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "บ้านโพธิ์",
    subdistrictEn: "Ban Pho",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย บ้านโพธิ์ หนองคาย 43120",
    name_th: "โพนพิสัย บ้านโพธิ์ หนองคาย 43120",
    name_en: "Phon Phisai, Ban Pho, Nong Khai, 43120",
  },
  {
    id: 430521,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "บ้านผือ",
    subdistrictEn: "Ban Phue",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย บ้านผือ หนองคาย 43120",
    name_th: "โพนพิสัย บ้านผือ หนองคาย 43120",
    name_en: "Phon Phisai, Ban Phue, Nong Khai, 43120",
  },
  {
    id: 430522,
    districtTh: "โพนพิสัย",
    districtEn: "Phon Phisai",
    subdistrictTh: "สร้างนางขาว",
    subdistrictEn: "Sang Nang Khao",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4305,
    province_code: 31,
    name: "โพนพิสัย สร้างนางขาว หนองคาย 43120",
    name_th: "โพนพิสัย สร้างนางขาว หนองคาย 43120",
    name_en: "Phon Phisai, Sang Nang Khao, Nong Khai, 43120",
  },
  {
    id: 430701,
    districtTh: "ศรีเชียงใหม่",
    districtEn: "Si Chiang Mai",
    subdistrictTh: "พานพร้าว",
    subdistrictEn: "Phan Phrao",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43130,
    district_code: 4307,
    province_code: 31,
    name: "ศรีเชียงใหม่ พานพร้าว หนองคาย 43130",
    name_th: "ศรีเชียงใหม่ พานพร้าว หนองคาย 43130",
    name_en: "Si Chiang Mai, Phan Phrao, Nong Khai, 43130",
  },
  {
    id: 430703,
    districtTh: "ศรีเชียงใหม่",
    districtEn: "Si Chiang Mai",
    subdistrictTh: "บ้านหม้อ",
    subdistrictEn: "Ban Mo",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43130,
    district_code: 4307,
    province_code: 31,
    name: "ศรีเชียงใหม่ บ้านหม้อ หนองคาย 43130",
    name_th: "ศรีเชียงใหม่ บ้านหม้อ หนองคาย 43130",
    name_en: "Si Chiang Mai, Ban Mo, Nong Khai, 43130",
  },
  {
    id: 430704,
    districtTh: "ศรีเชียงใหม่",
    districtEn: "Si Chiang Mai",
    subdistrictTh: "พระพุทธบาท",
    subdistrictEn: "Phra Phutthabat",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43130,
    district_code: 4307,
    province_code: 31,
    name: "ศรีเชียงใหม่ พระพุทธบาท หนองคาย 43130",
    name_th: "ศรีเชียงใหม่ พระพุทธบาท หนองคาย 43130",
    name_en: "Si Chiang Mai, Phra Phutthabat, Nong Khai, 43130",
  },
  {
    id: 430705,
    districtTh: "ศรีเชียงใหม่",
    districtEn: "Si Chiang Mai",
    subdistrictTh: "หนองปลาปาก",
    subdistrictEn: "Nong Pla Pak",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43130,
    district_code: 4307,
    province_code: 31,
    name: "ศรีเชียงใหม่ หนองปลาปาก หนองคาย 43130",
    name_th: "ศรีเชียงใหม่ หนองปลาปาก หนองคาย 43130",
    name_en: "Si Chiang Mai, Nong Pla Pak, Nong Khai, 43130",
  },
  {
    id: 430801,
    districtTh: "สังคม",
    districtEn: "Sangkhom",
    subdistrictTh: "แก้งไก่",
    subdistrictEn: "Kaeng Kai",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43160,
    district_code: 4308,
    province_code: 31,
    name: "สังคม แก้งไก่ หนองคาย 43160",
    name_th: "สังคม แก้งไก่ หนองคาย 43160",
    name_en: "Sangkhom, Kaeng Kai, Nong Khai, 43160",
  },
  {
    id: 430802,
    districtTh: "สังคม",
    districtEn: "Sangkhom",
    subdistrictTh: "ผาตั้ง",
    subdistrictEn: "Pha Tang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43160,
    district_code: 4308,
    province_code: 31,
    name: "สังคม ผาตั้ง หนองคาย 43160",
    name_th: "สังคม ผาตั้ง หนองคาย 43160",
    name_en: "Sangkhom, Pha Tang, Nong Khai, 43160",
  },
  {
    id: 430803,
    districtTh: "สังคม",
    districtEn: "Sangkhom",
    subdistrictTh: "บ้านม่วง",
    subdistrictEn: "Ban Muang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43160,
    district_code: 4308,
    province_code: 31,
    name: "สังคม บ้านม่วง หนองคาย 43160",
    name_th: "สังคม บ้านม่วง หนองคาย 43160",
    name_en: "Sangkhom, Ban Muang, Nong Khai, 43160",
  },
  {
    id: 430804,
    districtTh: "สังคม",
    districtEn: "Sangkhom",
    subdistrictTh: "นางิ้ว",
    subdistrictEn: "Na Ngio",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43160,
    district_code: 4308,
    province_code: 31,
    name: "สังคม นางิ้ว หนองคาย 43160",
    name_th: "สังคม นางิ้ว หนองคาย 43160",
    name_en: "Sangkhom, Na Ngio, Nong Khai, 43160",
  },
  {
    id: 430805,
    districtTh: "สังคม",
    districtEn: "Sangkhom",
    subdistrictTh: "สังคม",
    subdistrictEn: "Sangkhom",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43160,
    district_code: 4308,
    province_code: 31,
    name: "สังคม สังคม หนองคาย 43160",
    name_th: "สังคม สังคม หนองคาย 43160",
    name_en: "Sangkhom, Sangkhom, Nong Khai, 43160",
  },
  {
    id: 431401,
    districtTh: "สระใคร",
    districtEn: "Sakhrai",
    subdistrictTh: "สระใคร",
    subdistrictEn: "Sakhrai",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43100,
    district_code: 4314,
    province_code: 31,
    name: "สระใคร สระใคร หนองคาย 43100",
    name_th: "สระใคร สระใคร หนองคาย 43100",
    name_en: "Sakhrai, Sakhrai, Nong Khai, 43100",
  },
  {
    id: 431402,
    districtTh: "สระใคร",
    districtEn: "Sakhrai",
    subdistrictTh: "คอกช้าง",
    subdistrictEn: "Khok Chang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43100,
    district_code: 4314,
    province_code: 31,
    name: "สระใคร คอกช้าง หนองคาย 43100",
    name_th: "สระใคร คอกช้าง หนองคาย 43100",
    name_en: "Sakhrai, Khok Chang, Nong Khai, 43100",
  },
  {
    id: 431403,
    districtTh: "สระใคร",
    districtEn: "Sakhrai",
    subdistrictTh: "บ้านฝาง",
    subdistrictEn: "Ban Fang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43100,
    district_code: 4314,
    province_code: 31,
    name: "สระใคร บ้านฝาง หนองคาย 43100",
    name_th: "สระใคร บ้านฝาง หนองคาย 43100",
    name_en: "Sakhrai, Ban Fang, Nong Khai, 43100",
  },
  {
    id: 431501,
    districtTh: "เฝ้าไร่",
    districtEn: "Fao Rai",
    subdistrictTh: "เฝ้าไร่",
    subdistrictEn: "Fao Rai",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4315,
    province_code: 31,
    name: "เฝ้าไร่ เฝ้าไร่ หนองคาย 43120",
    name_th: "เฝ้าไร่ เฝ้าไร่ หนองคาย 43120",
    name_en: "Fao Rai, Fao Rai, Nong Khai, 43120",
  },
  {
    id: 431502,
    districtTh: "เฝ้าไร่",
    districtEn: "Fao Rai",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4315,
    province_code: 31,
    name: "เฝ้าไร่ นาดี หนองคาย 43120",
    name_th: "เฝ้าไร่ นาดี หนองคาย 43120",
    name_en: "Fao Rai, Na Di, Nong Khai, 43120",
  },
  {
    id: 431503,
    districtTh: "เฝ้าไร่",
    districtEn: "Fao Rai",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4315,
    province_code: 31,
    name: "เฝ้าไร่ หนองหลวง หนองคาย 43120",
    name_th: "เฝ้าไร่ หนองหลวง หนองคาย 43120",
    name_en: "Fao Rai, Nong Luang, Nong Khai, 43120",
  },
  {
    id: 431504,
    districtTh: "เฝ้าไร่",
    districtEn: "Fao Rai",
    subdistrictTh: "วังหลวง",
    subdistrictEn: "Wang Luang",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4315,
    province_code: 31,
    name: "เฝ้าไร่ วังหลวง หนองคาย 43120",
    name_th: "เฝ้าไร่ วังหลวง หนองคาย 43120",
    name_en: "Fao Rai, Wang Luang, Nong Khai, 43120",
  },
  {
    id: 431505,
    districtTh: "เฝ้าไร่",
    districtEn: "Fao Rai",
    subdistrictTh: "อุดมพร",
    subdistrictEn: "Udom Phon",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4315,
    province_code: 31,
    name: "เฝ้าไร่ อุดมพร หนองคาย 43120",
    name_th: "เฝ้าไร่ อุดมพร หนองคาย 43120",
    name_en: "Fao Rai, Udom Phon, Nong Khai, 43120",
  },
  {
    id: 431601,
    districtTh: "รัตนวาปี",
    districtEn: "Rattanawapi",
    subdistrictTh: "รัตนวาปี",
    subdistrictEn: "Rattanawapi",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4316,
    province_code: 31,
    name: "รัตนวาปี รัตนวาปี หนองคาย 43120",
    name_th: "รัตนวาปี รัตนวาปี หนองคาย 43120",
    name_en: "Rattanawapi, Rattanawapi, Nong Khai, 43120",
  },
  {
    id: 431602,
    districtTh: "รัตนวาปี",
    districtEn: "Rattanawapi",
    subdistrictTh: "นาทับไฮ",
    subdistrictEn: "Na Thap Hai",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4316,
    province_code: 31,
    name: "รัตนวาปี นาทับไฮ หนองคาย 43120",
    name_th: "รัตนวาปี นาทับไฮ หนองคาย 43120",
    name_en: "Rattanawapi, Na Thap Hai, Nong Khai, 43120",
  },
  {
    id: 431603,
    districtTh: "รัตนวาปี",
    districtEn: "Rattanawapi",
    subdistrictTh: "บ้านต้อน",
    subdistrictEn: "Ban Ton",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4316,
    province_code: 31,
    name: "รัตนวาปี บ้านต้อน หนองคาย 43120",
    name_th: "รัตนวาปี บ้านต้อน หนองคาย 43120",
    name_en: "Rattanawapi, Ban Ton, Nong Khai, 43120",
  },
  {
    id: 431604,
    districtTh: "รัตนวาปี",
    districtEn: "Rattanawapi",
    subdistrictTh: "พระบาทนาสิงห์",
    subdistrictEn: "Phra Bat Na Sing",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4316,
    province_code: 31,
    name: "รัตนวาปี พระบาทนาสิงห์ หนองคาย 43120",
    name_th: "รัตนวาปี พระบาทนาสิงห์ หนองคาย 43120",
    name_en: "Rattanawapi, Phra Bat Na Sing, Nong Khai, 43120",
  },
  {
    id: 431605,
    districtTh: "รัตนวาปี",
    districtEn: "Rattanawapi",
    subdistrictTh: "โพนแพง",
    subdistrictEn: "Phon Phaeng",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43120,
    district_code: 4316,
    province_code: 31,
    name: "รัตนวาปี โพนแพง หนองคาย 43120",
    name_th: "รัตนวาปี โพนแพง หนองคาย 43120",
    name_en: "Rattanawapi, Phon Phaeng, Nong Khai, 43120",
  },
  {
    id: 431701,
    districtTh: "โพธิ์ตาก",
    districtEn: "Pho Tak",
    subdistrictTh: "โพธิ์ตาก",
    subdistrictEn: "Pho Tak",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43130,
    district_code: 4317,
    province_code: 31,
    name: "โพธิ์ตาก โพธิ์ตาก หนองคาย 43130",
    name_th: "โพธิ์ตาก โพธิ์ตาก หนองคาย 43130",
    name_en: "Pho Tak, Pho Tak, Nong Khai, 43130",
  },
  {
    id: 431702,
    districtTh: "โพธิ์ตาก",
    districtEn: "Pho Tak",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43130,
    district_code: 4317,
    province_code: 31,
    name: "โพธิ์ตาก โพนทอง หนองคาย 43130",
    name_th: "โพธิ์ตาก โพนทอง หนองคาย 43130",
    name_en: "Pho Tak, Phon Thong, Nong Khai, 43130",
  },
  {
    id: 431703,
    districtTh: "โพธิ์ตาก",
    districtEn: "Pho Tak",
    subdistrictTh: "ด่านศรีสุข",
    subdistrictEn: "Dan Si Suk",
    provinceTh: "หนองคาย",
    provinceEn: "Nong Khai",
    zipcode: 43130,
    district_code: 4317,
    province_code: 31,
    name: "โพธิ์ตาก ด่านศรีสุข หนองคาย 43130",
    name_th: "โพธิ์ตาก ด่านศรีสุข หนองคาย 43130",
    name_en: "Pho Tak, Dan Si Suk, Nong Khai, 43130",
  },
  {
    id: 440101,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "ตลาด",
    subdistrictEn: "Talat",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม ตลาด มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม ตลาด มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Talat, Maha Sarakham, 44000",
  },
  {
    id: 440102,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "เขวา",
    subdistrictEn: "Khwao",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม เขวา มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม เขวา มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Khwao, Maha Sarakham, 44000",
  },
  {
    id: 440103,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "ท่าตูม",
    subdistrictEn: "Tha Tum",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม ท่าตูม มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม ท่าตูม มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Tha Tum, Maha Sarakham, 44000",
  },
  {
    id: 440104,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "แวงน่าง",
    subdistrictEn: "Waeng Nang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม แวงน่าง มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม แวงน่าง มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Waeng Nang, Maha Sarakham, 44000",
  },
  {
    id: 440105,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "โคกก่อ",
    subdistrictEn: "Khok Ko",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม โคกก่อ มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม โคกก่อ มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Khok Ko, Maha Sarakham, 44000",
  },
  {
    id: 440106,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "ดอนหว่าน",
    subdistrictEn: "Don Wan",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม ดอนหว่าน มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม ดอนหว่าน มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Don Wan, Maha Sarakham, 44000",
  },
  {
    id: 440107,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "เกิ้ง",
    subdistrictEn: "Koeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม เกิ้ง มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม เกิ้ง มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Koeng, Maha Sarakham, 44000",
  },
  {
    id: 440108,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "แก่งเลิงจาน",
    subdistrictEn: "Kaeng Loeng Chan",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม แก่งเลิงจาน มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม แก่งเลิงจาน มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Kaeng Loeng Chan, Maha Sarakham, 44000",
  },
  {
    id: 440109,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "ท่าสองคอน",
    subdistrictEn: "Tha Song Khon",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม ท่าสองคอน มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม ท่าสองคอน มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Tha Song Khon, Maha Sarakham, 44000",
  },
  {
    id: 440110,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "ลาดพัฒนา",
    subdistrictEn: "Lat Phatthana",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม ลาดพัฒนา มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม ลาดพัฒนา มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Lat Phatthana, Maha Sarakham, 44000",
  },
  {
    id: 440111,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "หนองปลิง",
    subdistrictEn: "Nong Pling",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม หนองปลิง มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม หนองปลิง มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Nong Pling, Maha Sarakham, 44000",
  },
  {
    id: 440112,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "ห้วยแอ่ง",
    subdistrictEn: "Huai Aeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม ห้วยแอ่ง มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม ห้วยแอ่ง มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Huai Aeng, Maha Sarakham, 44000",
  },
  {
    id: 440113,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "หนองโน",
    subdistrictEn: "Nong No",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม หนองโน มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม หนองโน มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Nong No, Maha Sarakham, 44000",
  },
  {
    id: 440114,
    districtTh: "เมืองมหาสารคาม",
    districtEn: "Mueang Maha Sarakham",
    subdistrictTh: "บัวค้อ",
    subdistrictEn: "Bua Kho",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44000,
    district_code: 4401,
    province_code: 32,
    name: "เมืองมหาสารคาม บัวค้อ มหาสารคาม 44000",
    name_th: "เมืองมหาสารคาม บัวค้อ มหาสารคาม 44000",
    name_en: "Mueang Maha Sarakham, Bua Kho, Maha Sarakham, 44000",
  },
  {
    id: 440201,
    districtTh: "แกดำ",
    districtEn: "Kae Dam",
    subdistrictTh: "แกดำ",
    subdistrictEn: "Kae Dam",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44190,
    district_code: 4402,
    province_code: 32,
    name: "แกดำ แกดำ มหาสารคาม 44190",
    name_th: "แกดำ แกดำ มหาสารคาม 44190",
    name_en: "Kae Dam, Kae Dam, Maha Sarakham, 44190",
  },
  {
    id: 440202,
    districtTh: "แกดำ",
    districtEn: "Kae Dam",
    subdistrictTh: "วังแสง",
    subdistrictEn: "Wang Saeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44190,
    district_code: 4402,
    province_code: 32,
    name: "แกดำ วังแสง มหาสารคาม 44190",
    name_th: "แกดำ วังแสง มหาสารคาม 44190",
    name_en: "Kae Dam, Wang Saeng, Maha Sarakham, 44190",
  },
  {
    id: 440203,
    districtTh: "แกดำ",
    districtEn: "Kae Dam",
    subdistrictTh: "มิตรภาพ",
    subdistrictEn: "Mittraphap",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44190,
    district_code: 4402,
    province_code: 32,
    name: "แกดำ มิตรภาพ มหาสารคาม 44190",
    name_th: "แกดำ มิตรภาพ มหาสารคาม 44190",
    name_en: "Kae Dam, Mittraphap, Maha Sarakham, 44190",
  },
  {
    id: 440204,
    districtTh: "แกดำ",
    districtEn: "Kae Dam",
    subdistrictTh: "หนองกุง",
    subdistrictEn: "Nong Kung",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44190,
    district_code: 4402,
    province_code: 32,
    name: "แกดำ หนองกุง มหาสารคาม 44190",
    name_th: "แกดำ หนองกุง มหาสารคาม 44190",
    name_en: "Kae Dam, Nong Kung, Maha Sarakham, 44190",
  },
  {
    id: 440205,
    districtTh: "แกดำ",
    districtEn: "Kae Dam",
    subdistrictTh: "โนนภิบาล",
    subdistrictEn: "Non Phiban",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44190,
    district_code: 4402,
    province_code: 32,
    name: "แกดำ โนนภิบาล มหาสารคาม 44190",
    name_th: "แกดำ โนนภิบาล มหาสารคาม 44190",
    name_en: "Kae Dam, Non Phiban, Maha Sarakham, 44190",
  },
  {
    id: 440301,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "หัวขวาง",
    subdistrictEn: "Hua Khwang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย หัวขวาง มหาสารคาม 44140",
    name_th: "โกสุมพิสัย หัวขวาง มหาสารคาม 44140",
    name_en: "Kosum Phisai, Hua Khwang, Maha Sarakham, 44140",
  },
  {
    id: 440302,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "ยางน้อย",
    subdistrictEn: "Yang Noi",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย ยางน้อย มหาสารคาม 44140",
    name_th: "โกสุมพิสัย ยางน้อย มหาสารคาม 44140",
    name_en: "Kosum Phisai, Yang Noi, Maha Sarakham, 44140",
  },
  {
    id: 440303,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "วังยาว",
    subdistrictEn: "Wang Yao",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย วังยาว มหาสารคาม 44140",
    name_th: "โกสุมพิสัย วังยาว มหาสารคาม 44140",
    name_en: "Kosum Phisai, Wang Yao, Maha Sarakham, 44140",
  },
  {
    id: 440304,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "เขวาไร่",
    subdistrictEn: "Khwao Rai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย เขวาไร่ มหาสารคาม 44140",
    name_th: "โกสุมพิสัย เขวาไร่ มหาสารคาม 44140",
    name_en: "Kosum Phisai, Khwao Rai, Maha Sarakham, 44140",
  },
  {
    id: 440305,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "แพง",
    subdistrictEn: "Phaeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย แพง มหาสารคาม 44140",
    name_th: "โกสุมพิสัย แพง มหาสารคาม 44140",
    name_en: "Kosum Phisai, Phaeng, Maha Sarakham, 44140",
  },
  {
    id: 440306,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "แก้งแก",
    subdistrictEn: "Kaeng Kae",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย แก้งแก มหาสารคาม 44140",
    name_th: "โกสุมพิสัย แก้งแก มหาสารคาม 44140",
    name_en: "Kosum Phisai, Kaeng Kae, Maha Sarakham, 44140",
  },
  {
    id: 440307,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "หนองเหล็ก",
    subdistrictEn: "Nong Lek",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย หนองเหล็ก มหาสารคาม 44140",
    name_th: "โกสุมพิสัย หนองเหล็ก มหาสารคาม 44140",
    name_en: "Kosum Phisai, Nong Lek, Maha Sarakham, 44140",
  },
  {
    id: 440308,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย หนองบัว มหาสารคาม 44140",
    name_th: "โกสุมพิสัย หนองบัว มหาสารคาม 44140",
    name_en: "Kosum Phisai, Nong Bua, Maha Sarakham, 44140",
  },
  {
    id: 440309,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "เหล่า",
    subdistrictEn: "Lao",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย เหล่า มหาสารคาม 44140",
    name_th: "โกสุมพิสัย เหล่า มหาสารคาม 44140",
    name_en: "Kosum Phisai, Lao, Maha Sarakham, 44140",
  },
  {
    id: 440310,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "เขื่อน",
    subdistrictEn: "Khuean",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย เขื่อน มหาสารคาม 44140",
    name_th: "โกสุมพิสัย เขื่อน มหาสารคาม 44140",
    name_en: "Kosum Phisai, Khuean, Maha Sarakham, 44140",
  },
  {
    id: 440311,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "หนองบอน",
    subdistrictEn: "Nong Bua",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย หนองบอน มหาสารคาม 44140",
    name_th: "โกสุมพิสัย หนองบอน มหาสารคาม 44140",
    name_en: "Kosum Phisai, Nong Bua, Maha Sarakham, 44140",
  },
  {
    id: 440312,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "โพนงาม",
    subdistrictEn: "Phon Ngam",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย โพนงาม มหาสารคาม 44140",
    name_th: "โกสุมพิสัย โพนงาม มหาสารคาม 44140",
    name_en: "Kosum Phisai, Phon Ngam, Maha Sarakham, 44140",
  },
  {
    id: 440313,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "ยางท่าแจ้ง",
    subdistrictEn: "Yang Tha Chaeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย ยางท่าแจ้ง มหาสารคาม 44140",
    name_th: "โกสุมพิสัย ยางท่าแจ้ง มหาสารคาม 44140",
    name_en: "Kosum Phisai, Yang Tha Chaeng, Maha Sarakham, 44140",
  },
  {
    id: 440314,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "แห่ใต้",
    subdistrictEn: "Hae Tai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย แห่ใต้ มหาสารคาม 44140",
    name_th: "โกสุมพิสัย แห่ใต้ มหาสารคาม 44140",
    name_en: "Kosum Phisai, Hae Tai, Maha Sarakham, 44140",
  },
  {
    id: 440315,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "หนองกุงสวรรค์",
    subdistrictEn: "Nong Kung Sawan",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย หนองกุงสวรรค์ มหาสารคาม 44140",
    name_th: "โกสุมพิสัย หนองกุงสวรรค์ มหาสารคาม 44140",
    name_en: "Kosum Phisai, Nong Kung Sawan, Maha Sarakham, 44140",
  },
  {
    id: 440316,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "เลิงใต้",
    subdistrictEn: "Loeng Tai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย เลิงใต้ มหาสารคาม 44140",
    name_th: "โกสุมพิสัย เลิงใต้ มหาสารคาม 44140",
    name_en: "Kosum Phisai, Loeng Tai, Maha Sarakham, 44140",
  },
  {
    id: 440317,
    districtTh: "โกสุมพิสัย",
    districtEn: "Kosum Phisai",
    subdistrictTh: "ดอนกลาง",
    subdistrictEn: "Don Klang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44140,
    district_code: 4403,
    province_code: 32,
    name: "โกสุมพิสัย ดอนกลาง มหาสารคาม 44140",
    name_th: "โกสุมพิสัย ดอนกลาง มหาสารคาม 44140",
    name_en: "Kosum Phisai, Don Klang, Maha Sarakham, 44140",
  },
  {
    id: 440401,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "โคกพระ",
    subdistrictEn: "Khok Phra",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย โคกพระ มหาสารคาม 44150",
    name_th: "กันทรวิชัย โคกพระ มหาสารคาม 44150",
    name_en: "Kantharawichai, Khok Phra, Maha Sarakham, 44150",
  },
  {
    id: 440402,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "คันธารราษฎร์",
    subdistrictEn: "Khanthararat",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย คันธารราษฎร์ มหาสารคาม 44150",
    name_th: "กันทรวิชัย คันธารราษฎร์ มหาสารคาม 44150",
    name_en: "Kantharawichai, Khanthararat, Maha Sarakham, 44150",
  },
  {
    id: 440404,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "ท่าขอนยาง",
    subdistrictEn: "Tha Khon Yang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย ท่าขอนยาง มหาสารคาม 44150",
    name_th: "กันทรวิชัย ท่าขอนยาง มหาสารคาม 44150",
    name_en: "Kantharawichai, Tha Khon Yang, Maha Sarakham, 44150",
  },
  {
    id: 440405,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "นาสีนวน",
    subdistrictEn: "Na Si Nuan",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย นาสีนวน มหาสารคาม 44150",
    name_th: "กันทรวิชัย นาสีนวน มหาสารคาม 44150",
    name_en: "Kantharawichai, Na Si Nuan, Maha Sarakham, 44150",
  },
  {
    id: 440406,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "ขามเรียง",
    subdistrictEn: "Kham Riang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย ขามเรียง มหาสารคาม 44150",
    name_th: "กันทรวิชัย ขามเรียง มหาสารคาม 44150",
    name_en: "Kantharawichai, Kham Riang, Maha Sarakham, 44150",
  },
  {
    id: 440407,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "เขวาใหญ่",
    subdistrictEn: "Khwao Yai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย เขวาใหญ่ มหาสารคาม 44150",
    name_th: "กันทรวิชัย เขวาใหญ่ มหาสารคาม 44150",
    name_en: "Kantharawichai, Khwao Yai, Maha Sarakham, 44150",
  },
  {
    id: 440408,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "ศรีสุข",
    subdistrictEn: "Si Suk",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย ศรีสุข มหาสารคาม 44150",
    name_th: "กันทรวิชัย ศรีสุข มหาสารคาม 44150",
    name_en: "Kantharawichai, Si Suk, Maha Sarakham, 44150",
  },
  {
    id: 440409,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "กุดใส้จ่อ",
    subdistrictEn: "Kut Sai Cho",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย กุดใส้จ่อ มหาสารคาม 44150",
    name_th: "กันทรวิชัย กุดใส้จ่อ มหาสารคาม 44150",
    name_en: "Kantharawichai, Kut Sai Cho, Maha Sarakham, 44150",
  },
  {
    id: 440410,
    districtTh: "กันทรวิชัย",
    districtEn: "Kantharawichai",
    subdistrictTh: "ขามเฒ่าพัฒนา",
    subdistrictEn: "Kham Thao Phatthana",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44150,
    district_code: 4404,
    province_code: 32,
    name: "กันทรวิชัย ขามเฒ่าพัฒนา มหาสารคาม 44150",
    name_th: "กันทรวิชัย ขามเฒ่าพัฒนา มหาสารคาม 44150",
    name_en: "Kantharawichai, Kham Thao Phatthana, Maha Sarakham, 44150",
  },
  {
    id: 440501,
    districtTh: "เชียงยืน",
    districtEn: "Kantharawichai",
    subdistrictTh: "เชียงยืน",
    subdistrictEn: "Chiang Yuen",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4405,
    province_code: 32,
    name: "เชียงยืน เชียงยืน มหาสารคาม 44160",
    name_th: "เชียงยืน เชียงยืน มหาสารคาม 44160",
    name_en: "Kantharawichai, Chiang Yuen, Maha Sarakham, 44160",
  },
  {
    id: 440503,
    districtTh: "เชียงยืน",
    districtEn: "Kantharawichai",
    subdistrictTh: "หนองซอน",
    subdistrictEn: "Nong Son",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4405,
    province_code: 32,
    name: "เชียงยืน หนองซอน มหาสารคาม 44160",
    name_th: "เชียงยืน หนองซอน มหาสารคาม 44160",
    name_en: "Kantharawichai, Nong Son, Maha Sarakham, 44160",
  },
  {
    id: 440505,
    districtTh: "เชียงยืน",
    districtEn: "Kantharawichai",
    subdistrictTh: "ดอนเงิน",
    subdistrictEn: "Don Ngoen",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4405,
    province_code: 32,
    name: "เชียงยืน ดอนเงิน มหาสารคาม 44160",
    name_th: "เชียงยืน ดอนเงิน มหาสารคาม 44160",
    name_en: "Kantharawichai, Don Ngoen, Maha Sarakham, 44160",
  },
  {
    id: 440506,
    districtTh: "เชียงยืน",
    districtEn: "Kantharawichai",
    subdistrictTh: "กู่ทอง",
    subdistrictEn: "Ku Thong",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4405,
    province_code: 32,
    name: "เชียงยืน กู่ทอง มหาสารคาม 44160",
    name_th: "เชียงยืน กู่ทอง มหาสารคาม 44160",
    name_en: "Kantharawichai, Ku Thong, Maha Sarakham, 44160",
  },
  {
    id: 440507,
    districtTh: "เชียงยืน",
    districtEn: "Kantharawichai",
    subdistrictTh: "นาทอง",
    subdistrictEn: "Na Thong",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4405,
    province_code: 32,
    name: "เชียงยืน นาทอง มหาสารคาม 44160",
    name_th: "เชียงยืน นาทอง มหาสารคาม 44160",
    name_en: "Kantharawichai, Na Thong, Maha Sarakham, 44160",
  },
  {
    id: 440508,
    districtTh: "เชียงยืน",
    districtEn: "Kantharawichai",
    subdistrictTh: "เสือเฒ่า",
    subdistrictEn: "Suea Thao",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4405,
    province_code: 32,
    name: "เชียงยืน เสือเฒ่า มหาสารคาม 44160",
    name_th: "เชียงยืน เสือเฒ่า มหาสารคาม 44160",
    name_en: "Kantharawichai, Suea Thao, Maha Sarakham, 44160",
  },
  {
    id: 440511,
    districtTh: "เชียงยืน",
    districtEn: "Kantharawichai",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4405,
    province_code: 32,
    name: "เชียงยืน โพนทอง มหาสารคาม 44160",
    name_th: "เชียงยืน โพนทอง มหาสารคาม 44160",
    name_en: "Kantharawichai, Phon Thong, Maha Sarakham, 44160",
  },
  {
    id: 440512,
    districtTh: "เชียงยืน",
    districtEn: "Kantharawichai",
    subdistrictTh: "เหล่าบัวบาน",
    subdistrictEn: "Lao Bua Ban",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4405,
    province_code: 32,
    name: "เชียงยืน เหล่าบัวบาน มหาสารคาม 44160",
    name_th: "เชียงยืน เหล่าบัวบาน มหาสารคาม 44160",
    name_en: "Kantharawichai, Lao Bua Ban, Maha Sarakham, 44160",
  },
  {
    id: 440601,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "บรบือ",
    subdistrictEn: "Borabue",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ บรบือ มหาสารคาม 44130",
    name_th: "บรบือ บรบือ มหาสารคาม 44130",
    name_en: "Borabue, Borabue, Maha Sarakham, 44130",
  },
  {
    id: 440602,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "บ่อใหญ่",
    subdistrictEn: "Bo Yai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ บ่อใหญ่ มหาสารคาม 44130",
    name_th: "บรบือ บ่อใหญ่ มหาสารคาม 44130",
    name_en: "Borabue, Bo Yai, Maha Sarakham, 44130",
  },
  {
    id: 440604,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "วังไชย",
    subdistrictEn: "Wang Chai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ วังไชย มหาสารคาม 44130",
    name_th: "บรบือ วังไชย มหาสารคาม 44130",
    name_en: "Borabue, Wang Chai, Maha Sarakham, 44130",
  },
  {
    id: 440605,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "หนองม่วง",
    subdistrictEn: "Nong Muang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ หนองม่วง มหาสารคาม 44130",
    name_th: "บรบือ หนองม่วง มหาสารคาม 44130",
    name_en: "Borabue, Nong Muang, Maha Sarakham, 44130",
  },
  {
    id: 440606,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "กำพี้",
    subdistrictEn: "Kamphi",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ กำพี้ มหาสารคาม 44130",
    name_th: "บรบือ กำพี้ มหาสารคาม 44130",
    name_en: "Borabue, Kamphi, Maha Sarakham, 44130",
  },
  {
    id: 440607,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "โนนราษี",
    subdistrictEn: "Non Rasi",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ โนนราษี มหาสารคาม 44130",
    name_th: "บรบือ โนนราษี มหาสารคาม 44130",
    name_en: "Borabue, Non Rasi, Maha Sarakham, 44130",
  },
  {
    id: 440608,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "โนนแดง",
    subdistrictEn: "Non Daeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ โนนแดง มหาสารคาม 44130",
    name_th: "บรบือ โนนแดง มหาสารคาม 44130",
    name_en: "Borabue, Non Daeng, Maha Sarakham, 44130",
  },
  {
    id: 440610,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "หนองจิก",
    subdistrictEn: "Nong Chik",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ หนองจิก มหาสารคาม 44130",
    name_th: "บรบือ หนองจิก มหาสารคาม 44130",
    name_en: "Borabue, Nong Chik, Maha Sarakham, 44130",
  },
  {
    id: 440611,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "บัวมาศ",
    subdistrictEn: "Bua Mat",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ บัวมาศ มหาสารคาม 44130",
    name_th: "บรบือ บัวมาศ มหาสารคาม 44130",
    name_en: "Borabue, Bua Mat, Maha Sarakham, 44130",
  },
  {
    id: 440613,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "หนองคูขาด",
    subdistrictEn: "Nong Khu Khat",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ หนองคูขาด มหาสารคาม 44130",
    name_th: "บรบือ หนองคูขาด มหาสารคาม 44130",
    name_en: "Borabue, Nong Khu Khat, Maha Sarakham, 44130",
  },
  {
    id: 440615,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "วังใหม่",
    subdistrictEn: "Wang Mai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ วังใหม่ มหาสารคาม 44130",
    name_th: "บรบือ วังใหม่ มหาสารคาม 44130",
    name_en: "Borabue, Wang Mai, Maha Sarakham, 44130",
  },
  {
    id: 440616,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "ยาง",
    subdistrictEn: "Yang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ ยาง มหาสารคาม 44130",
    name_th: "บรบือ ยาง มหาสารคาม 44130",
    name_en: "Borabue, Yang, Maha Sarakham, 44130",
  },
  {
    id: 440618,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "หนองสิม",
    subdistrictEn: "Nong Sim",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ หนองสิม มหาสารคาม 44130",
    name_th: "บรบือ หนองสิม มหาสารคาม 44130",
    name_en: "Borabue, Nong Sim, Maha Sarakham, 44130",
  },
  {
    id: 440619,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "หนองโก",
    subdistrictEn: "Nong Ko",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ หนองโก มหาสารคาม 44130",
    name_th: "บรบือ หนองโก มหาสารคาม 44130",
    name_en: "Borabue, Nong Ko, Maha Sarakham, 44130",
  },
  {
    id: 440620,
    districtTh: "บรบือ",
    districtEn: "Borabue",
    subdistrictTh: "ดอนงัว",
    subdistrictEn: "Don Ngua",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4406,
    province_code: 32,
    name: "บรบือ ดอนงัว มหาสารคาม 44130",
    name_th: "บรบือ ดอนงัว มหาสารคาม 44130",
    name_en: "Borabue, Don Ngua, Maha Sarakham, 44130",
  },
  {
    id: 440701,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "นาเชือก",
    subdistrictEn: "Na Chueak",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก นาเชือก มหาสารคาม 44170",
    name_th: "นาเชือก นาเชือก มหาสารคาม 44170",
    name_en: "Na Chueak, Na Chueak, Maha Sarakham, 44170",
  },
  {
    id: 440702,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "สำโรง",
    subdistrictEn: "Samrong",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก สำโรง มหาสารคาม 44170",
    name_th: "นาเชือก สำโรง มหาสารคาม 44170",
    name_en: "Na Chueak, Samrong, Maha Sarakham, 44170",
  },
  {
    id: 440703,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "หนองแดง",
    subdistrictEn: "Nong Daeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก หนองแดง มหาสารคาม 44170",
    name_th: "นาเชือก หนองแดง มหาสารคาม 44170",
    name_en: "Na Chueak, Nong Daeng, Maha Sarakham, 44170",
  },
  {
    id: 440704,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "เขวาไร่",
    subdistrictEn: "Khwao Rai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก เขวาไร่ มหาสารคาม 44170",
    name_th: "นาเชือก เขวาไร่ มหาสารคาม 44170",
    name_en: "Na Chueak, Khwao Rai, Maha Sarakham, 44170",
  },
  {
    id: 440705,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "หนองโพธิ์",
    subdistrictEn: "Nong Pho",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก หนองโพธิ์ มหาสารคาม 44170",
    name_th: "นาเชือก หนองโพธิ์ มหาสารคาม 44170",
    name_en: "Na Chueak, Nong Pho, Maha Sarakham, 44170",
  },
  {
    id: 440706,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "ปอพาน",
    subdistrictEn: "Po Phan",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก ปอพาน มหาสารคาม 44170",
    name_th: "นาเชือก ปอพาน มหาสารคาม 44170",
    name_en: "Na Chueak, Po Phan, Maha Sarakham, 44170",
  },
  {
    id: 440707,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "หนองเม็ก",
    subdistrictEn: "Nong Mek",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก หนองเม็ก มหาสารคาม 44170",
    name_th: "นาเชือก หนองเม็ก มหาสารคาม 44170",
    name_en: "Na Chueak, Nong Mek, Maha Sarakham, 44170",
  },
  {
    id: 440708,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "หนองเรือ",
    subdistrictEn: "Nong Ruea",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก หนองเรือ มหาสารคาม 44170",
    name_th: "นาเชือก หนองเรือ มหาสารคาม 44170",
    name_en: "Na Chueak, Nong Ruea, Maha Sarakham, 44170",
  },
  {
    id: 440709,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "หนองกุง",
    subdistrictEn: "Nong Kung",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก หนองกุง มหาสารคาม 44170",
    name_th: "นาเชือก หนองกุง มหาสารคาม 44170",
    name_en: "Na Chueak, Nong Kung, Maha Sarakham, 44170",
  },
  {
    id: 440710,
    districtTh: "นาเชือก",
    districtEn: "Na Chueak",
    subdistrictTh: "สันป่าตอง",
    subdistrictEn: "San Pa Ton",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44170,
    district_code: 4407,
    province_code: 32,
    name: "นาเชือก สันป่าตอง มหาสารคาม 44170",
    name_th: "นาเชือก สันป่าตอง มหาสารคาม 44170",
    name_en: "Na Chueak, San Pa Ton, Maha Sarakham, 44170",
  },
  {
    id: 440801,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "ปะหลาน",
    subdistrictEn: "Palan",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย ปะหลาน มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย ปะหลาน มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Palan, Maha Sarakham, 44110",
  },
  {
    id: 440802,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "ก้ามปู",
    subdistrictEn: "Kam Pu",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย ก้ามปู มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย ก้ามปู มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Kam Pu, Maha Sarakham, 44110",
  },
  {
    id: 440803,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "เวียงสะอาด",
    subdistrictEn: "Wiang Sa-at",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย เวียงสะอาด มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย เวียงสะอาด มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Wiang Sa-at, Maha Sarakham, 44110",
  },
  {
    id: 440804,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "เม็กดำ",
    subdistrictEn: "Mek Dam",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย เม็กดำ มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย เม็กดำ มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Mek Dam, Maha Sarakham, 44110",
  },
  {
    id: 440805,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "นาสีนวล",
    subdistrictEn: "Na Si Nuan",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย นาสีนวล มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย นาสีนวล มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Na Si Nuan, Maha Sarakham, 44110",
  },
  {
    id: 440809,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "ราษฎร์เจริญ",
    subdistrictEn: "Rat Charoen",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย ราษฎร์เจริญ มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย ราษฎร์เจริญ มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Rat Charoen, Maha Sarakham, 44110",
  },
  {
    id: 440810,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "หนองบัวแก้ว",
    subdistrictEn: "Nong Bua Kaeo",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย หนองบัวแก้ว มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย หนองบัวแก้ว มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Nong Bua Kaeo, Maha Sarakham, 44110",
  },
  {
    id: 440812,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "เมืองเตา",
    subdistrictEn: "Mueang Tao",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย เมืองเตา มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย เมืองเตา มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Mueang Tao, Maha Sarakham, 44110",
  },
  {
    id: 440815,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "ลานสะแก",
    subdistrictEn: "Lan Sakae",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย ลานสะแก มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย ลานสะแก มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Lan Sakae, Maha Sarakham, 44110",
  },
  {
    id: 440816,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "เวียงชัย",
    subdistrictEn: "Wiang Chai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย เวียงชัย มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย เวียงชัย มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Wiang Chai, Maha Sarakham, 44110",
  },
  {
    id: 440817,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย หนองบัว มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย หนองบัว มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Nong Bua, Maha Sarakham, 44110",
  },
  {
    id: 440818,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "ราษฎร์พัฒนา",
    subdistrictEn: "Rat Phatthana",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย ราษฎร์พัฒนา มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย ราษฎร์พัฒนา มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Rat Phatthana, Maha Sarakham, 44110",
  },
  {
    id: 440819,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "เมืองเสือ",
    subdistrictEn: "Mueang Suea",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย เมืองเสือ มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย เมืองเสือ มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Mueang Suea, Maha Sarakham, 44110",
  },
  {
    id: 440820,
    districtTh: "พยัคฆภูมิพิสัย",
    districtEn: "Phayakkhaphum Phisai",
    subdistrictTh: "ภารแอ่น",
    subdistrictEn: "Phan Aen",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44110,
    district_code: 4408,
    province_code: 32,
    name: "พยัคฆภูมิพิสัย ภารแอ่น มหาสารคาม 44110",
    name_th: "พยัคฆภูมิพิสัย ภารแอ่น มหาสารคาม 44110",
    name_en: "Phayakkhaphum Phisai, Phan Aen, Maha Sarakham, 44110",
  },
  {
    id: 440901,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "หนองแสง",
    subdistrictEn: "Nong Saeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม หนองแสง มหาสารคาม 44120",
    name_th: "วาปีปทุม หนองแสง มหาสารคาม 44120",
    name_en: "Wapi Pathum, Nong Saeng, Maha Sarakham, 44120",
  },
  {
    id: 440902,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "ขามป้อม",
    subdistrictEn: "Kham Pom",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม ขามป้อม มหาสารคาม 44120",
    name_th: "วาปีปทุม ขามป้อม มหาสารคาม 44120",
    name_en: "Wapi Pathum, Kham Pom, Maha Sarakham, 44120",
  },
  {
    id: 440903,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "เสือโก้ก",
    subdistrictEn: "Suea Kok",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม เสือโก้ก มหาสารคาม 44120",
    name_th: "วาปีปทุม เสือโก้ก มหาสารคาม 44120",
    name_en: "Wapi Pathum, Suea Kok, Maha Sarakham, 44120",
  },
  {
    id: 440904,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "ดงใหญ่",
    subdistrictEn: "Dong Yai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม ดงใหญ่ มหาสารคาม 44120",
    name_th: "วาปีปทุม ดงใหญ่ มหาสารคาม 44120",
    name_en: "Wapi Pathum, Dong Yai, Maha Sarakham, 44120",
  },
  {
    id: 440905,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "โพธิ์ชัย",
    subdistrictEn: "Pho Chai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม โพธิ์ชัย มหาสารคาม 44120",
    name_th: "วาปีปทุม โพธิ์ชัย มหาสารคาม 44120",
    name_en: "Wapi Pathum, Pho Chai, Maha Sarakham, 44120",
  },
  {
    id: 440906,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "หัวเรือ",
    subdistrictEn: "Hua Ruea",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม หัวเรือ มหาสารคาม 44120",
    name_th: "วาปีปทุม หัวเรือ มหาสารคาม 44120",
    name_en: "Wapi Pathum, Hua Ruea, Maha Sarakham, 44120",
  },
  {
    id: 440907,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "แคน",
    subdistrictEn: "Khaen",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม แคน มหาสารคาม 44120",
    name_th: "วาปีปทุม แคน มหาสารคาม 44120",
    name_en: "Wapi Pathum, Khaen, Maha Sarakham, 44120",
  },
  {
    id: 440908,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "งัวบา",
    subdistrictEn: "Ngua Ba",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม งัวบา มหาสารคาม 44120",
    name_th: "วาปีปทุม งัวบา มหาสารคาม 44120",
    name_en: "Wapi Pathum, Ngua Ba, Maha Sarakham, 44120",
  },
  {
    id: 440909,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "นาข่า",
    subdistrictEn: "Na Kha",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม นาข่า มหาสารคาม 44120",
    name_th: "วาปีปทุม นาข่า มหาสารคาม 44120",
    name_en: "Wapi Pathum, Na Kha, Maha Sarakham, 44120",
  },
  {
    id: 440910,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "บ้านหวาย",
    subdistrictEn: "Ban Wai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม บ้านหวาย มหาสารคาม 44120",
    name_th: "วาปีปทุม บ้านหวาย มหาสารคาม 44120",
    name_en: "Wapi Pathum, Ban Wai, Maha Sarakham, 44120",
  },
  {
    id: 440911,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "หนองไฮ",
    subdistrictEn: "Nong Hai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม หนองไฮ มหาสารคาม 44120",
    name_th: "วาปีปทุม หนองไฮ มหาสารคาม 44120",
    name_en: "Wapi Pathum, Nong Hai, Maha Sarakham, 44120",
  },
  {
    id: 440912,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "ประชาพัฒนา",
    subdistrictEn: "Pracha Phatthana",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม ประชาพัฒนา มหาสารคาม 44120",
    name_th: "วาปีปทุม ประชาพัฒนา มหาสารคาม 44120",
    name_en: "Wapi Pathum, Pracha Phatthana, Maha Sarakham, 44120",
  },
  {
    id: 440913,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "หนองทุ่ม",
    subdistrictEn: "Nong Thum",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม หนองทุ่ม มหาสารคาม 44120",
    name_th: "วาปีปทุม หนองทุ่ม มหาสารคาม 44120",
    name_en: "Wapi Pathum, Nong Thum, Maha Sarakham, 44120",
  },
  {
    id: 440914,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "หนองแสน",
    subdistrictEn: "Nong Saen",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม หนองแสน มหาสารคาม 44120",
    name_th: "วาปีปทุม หนองแสน มหาสารคาม 44120",
    name_en: "Wapi Pathum, Nong Saen, Maha Sarakham, 44120",
  },
  {
    id: 440915,
    districtTh: "วาปีปทุม",
    districtEn: "Wapi Pathum",
    subdistrictTh: "โคกสีทองหลาง",
    subdistrictEn: "Khok Si Thonglang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44120,
    district_code: 4409,
    province_code: 32,
    name: "วาปีปทุม โคกสีทองหลาง มหาสารคาม 44120",
    name_th: "วาปีปทุม โคกสีทองหลาง มหาสารคาม 44120",
    name_en: "Wapi Pathum, Khok Si Thonglang, Maha Sarakham, 44120",
  },
  {
    id: 441001,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "นาดูน",
    subdistrictEn: "Na Dun",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน นาดูน มหาสารคาม 44180",
    name_th: "นาดูน นาดูน มหาสารคาม 44180",
    name_en: "Na Dun, Na Dun, Maha Sarakham, 44180",
  },
  {
    id: 441002,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน หนองไผ่ มหาสารคาม 44180",
    name_th: "นาดูน หนองไผ่ มหาสารคาม 44180",
    name_en: "Na Dun, Nong Phai, Maha Sarakham, 44180",
  },
  {
    id: 441003,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "หนองคู",
    subdistrictEn: "Nong Khu",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน หนองคู มหาสารคาม 44180",
    name_th: "นาดูน หนองคู มหาสารคาม 44180",
    name_en: "Na Dun, Nong Khu, Maha Sarakham, 44180",
  },
  {
    id: 441004,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "ดงบัง",
    subdistrictEn: "Dong Bang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน ดงบัง มหาสารคาม 44180",
    name_th: "นาดูน ดงบัง มหาสารคาม 44180",
    name_en: "Na Dun, Dong Bang, Maha Sarakham, 44180",
  },
  {
    id: 441005,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "ดงดวน",
    subdistrictEn: "Dong Duan",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน ดงดวน มหาสารคาม 44180",
    name_th: "นาดูน ดงดวน มหาสารคาม 44180",
    name_en: "Na Dun, Dong Duan, Maha Sarakham, 44180",
  },
  {
    id: 441006,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "หัวดง",
    subdistrictEn: "Hua Dong",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน หัวดง มหาสารคาม 44180",
    name_th: "นาดูน หัวดง มหาสารคาม 44180",
    name_en: "Na Dun, Hua Dong, Maha Sarakham, 44180",
  },
  {
    id: 441007,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "ดงยาง",
    subdistrictEn: "Dong Yang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน ดงยาง มหาสารคาม 44180",
    name_th: "นาดูน ดงยาง มหาสารคาม 44180",
    name_en: "Na Dun, Dong Yang, Maha Sarakham, 44180",
  },
  {
    id: 441008,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "กู่สันตรัตน์",
    subdistrictEn: "Ku Santarat",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน กู่สันตรัตน์ มหาสารคาม 44180",
    name_th: "นาดูน กู่สันตรัตน์ มหาสารคาม 44180",
    name_en: "Na Dun, Ku Santarat, Maha Sarakham, 44180",
  },
  {
    id: 441009,
    districtTh: "นาดูน",
    districtEn: "Na Dun",
    subdistrictTh: "พระธาตุ",
    subdistrictEn: "Phra That",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44180,
    district_code: 4410,
    province_code: 32,
    name: "นาดูน พระธาตุ มหาสารคาม 44180",
    name_th: "นาดูน พระธาตุ มหาสารคาม 44180",
    name_en: "Na Dun, Phra That, Maha Sarakham, 44180",
  },
  {
    id: 441101,
    districtTh: "ยางสีสุราช",
    districtEn: "Yang Sisurat",
    subdistrictTh: "ยางสีสุราช",
    subdistrictEn: "Yang Sisurat",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44210,
    district_code: 4411,
    province_code: 32,
    name: "ยางสีสุราช ยางสีสุราช มหาสารคาม 44210",
    name_th: "ยางสีสุราช ยางสีสุราช มหาสารคาม 44210",
    name_en: "Yang Sisurat, Yang Sisurat, Maha Sarakham, 44210",
  },
  {
    id: 441102,
    districtTh: "ยางสีสุราช",
    districtEn: "Yang Sisurat",
    subdistrictTh: "นาภู",
    subdistrictEn: "Na Phu",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44210,
    district_code: 4411,
    province_code: 32,
    name: "ยางสีสุราช นาภู มหาสารคาม 44210",
    name_th: "ยางสีสุราช นาภู มหาสารคาม 44210",
    name_en: "Yang Sisurat, Na Phu, Maha Sarakham, 44210",
  },
  {
    id: 441103,
    districtTh: "ยางสีสุราช",
    districtEn: "Yang Sisurat",
    subdistrictTh: "แวงดง",
    subdistrictEn: "Waeng Dong",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44210,
    district_code: 4411,
    province_code: 32,
    name: "ยางสีสุราช แวงดง มหาสารคาม 44210",
    name_th: "ยางสีสุราช แวงดง มหาสารคาม 44210",
    name_en: "Yang Sisurat, Waeng Dong, Maha Sarakham, 44210",
  },
  {
    id: 441104,
    districtTh: "ยางสีสุราช",
    districtEn: "Yang Sisurat",
    subdistrictTh: "บ้านกู่",
    subdistrictEn: "Ban Ku",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44210,
    district_code: 4411,
    province_code: 32,
    name: "ยางสีสุราช บ้านกู่ มหาสารคาม 44210",
    name_th: "ยางสีสุราช บ้านกู่ มหาสารคาม 44210",
    name_en: "Yang Sisurat, Ban Ku, Maha Sarakham, 44210",
  },
  {
    id: 441105,
    districtTh: "ยางสีสุราช",
    districtEn: "Yang Sisurat",
    subdistrictTh: "ดงเมือง",
    subdistrictEn: "Dong Mueang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44210,
    district_code: 4411,
    province_code: 32,
    name: "ยางสีสุราช ดงเมือง มหาสารคาม 44210",
    name_th: "ยางสีสุราช ดงเมือง มหาสารคาม 44210",
    name_en: "Yang Sisurat, Dong Mueang, Maha Sarakham, 44210",
  },
  {
    id: 441106,
    districtTh: "ยางสีสุราช",
    districtEn: "Yang Sisurat",
    subdistrictTh: "ขามเรียน",
    subdistrictEn: "Sang Saeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44210,
    district_code: 4411,
    province_code: 32,
    name: "ยางสีสุราช ขามเรียน มหาสารคาม 44210",
    name_th: "ยางสีสุราช ขามเรียน มหาสารคาม 44210",
    name_en: "Yang Sisurat, Sang Saeng, Maha Sarakham, 44210",
  },
  {
    id: 441107,
    districtTh: "ยางสีสุราช",
    districtEn: "Yang Sisurat",
    subdistrictTh: "หนองบัวสันตุ",
    subdistrictEn: "Nong Bua Santu",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44210,
    district_code: 4411,
    province_code: 32,
    name: "ยางสีสุราช หนองบัวสันตุ มหาสารคาม 44210",
    name_th: "ยางสีสุราช หนองบัวสันตุ มหาสารคาม 44210",
    name_en: "Yang Sisurat, Nong Bua Santu, Maha Sarakham, 44210",
  },
  {
    id: 441201,
    districtTh: "กุดรัง",
    districtEn: "Kut Rang",
    subdistrictTh: "กุดรัง",
    subdistrictEn: "Kud Rang",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4412,
    province_code: 32,
    name: "กุดรัง กุดรัง มหาสารคาม 44130",
    name_th: "กุดรัง กุดรัง มหาสารคาม 44130",
    name_en: "Kut Rang, Kud Rang, Maha Sarakham, 44130",
  },
  {
    id: 441202,
    districtTh: "กุดรัง",
    districtEn: "Kut Rang",
    subdistrictTh: "นาโพธิ์",
    subdistrictEn: "Na Pho",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4412,
    province_code: 32,
    name: "กุดรัง นาโพธิ์ มหาสารคาม 44130",
    name_th: "กุดรัง นาโพธิ์ มหาสารคาม 44130",
    name_en: "Kut Rang, Na Pho, Maha Sarakham, 44130",
  },
  {
    id: 441203,
    districtTh: "กุดรัง",
    districtEn: "Kut Rang",
    subdistrictTh: "เลิงแฝก",
    subdistrictEn: "Loeng Faek",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4412,
    province_code: 32,
    name: "กุดรัง เลิงแฝก มหาสารคาม 44130",
    name_th: "กุดรัง เลิงแฝก มหาสารคาม 44130",
    name_en: "Kut Rang, Loeng Faek, Maha Sarakham, 44130",
  },
  {
    id: 441204,
    districtTh: "กุดรัง",
    districtEn: "Kut Rang",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4412,
    province_code: 32,
    name: "กุดรัง หนองแวง มหาสารคาม 44130",
    name_th: "กุดรัง หนองแวง มหาสารคาม 44130",
    name_en: "Kut Rang, Nong Waeng, Maha Sarakham, 44130",
  },
  {
    id: 441205,
    districtTh: "กุดรัง",
    districtEn: "Kut Rang",
    subdistrictTh: "ห้วยเตย",
    subdistrictEn: "Huai Toei",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44130,
    district_code: 4412,
    province_code: 32,
    name: "กุดรัง ห้วยเตย มหาสารคาม 44130",
    name_th: "กุดรัง ห้วยเตย มหาสารคาม 44130",
    name_en: "Kut Rang, Huai Toei, Maha Sarakham, 44130",
  },
  {
    id: 441301,
    districtTh: "ชื่นชม",
    districtEn: "Chuen Chom",
    subdistrictTh: "ชื่นชม",
    subdistrictEn: "Chuen Chom",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4413,
    province_code: 32,
    name: "ชื่นชม ชื่นชม มหาสารคาม 44160",
    name_th: "ชื่นชม ชื่นชม มหาสารคาม 44160",
    name_en: "Chuen Chom, Chuen Chom, Maha Sarakham, 44160",
  },
  {
    id: 441302,
    districtTh: "ชื่นชม",
    districtEn: "Chuen Chom",
    subdistrictTh: "กุดปลาดุก",
    subdistrictEn: "Kut Pla Duk",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4413,
    province_code: 32,
    name: "ชื่นชม กุดปลาดุก มหาสารคาม 44160",
    name_th: "ชื่นชม กุดปลาดุก มหาสารคาม 44160",
    name_en: "Chuen Chom, Kut Pla Duk, Maha Sarakham, 44160",
  },
  {
    id: 441303,
    districtTh: "ชื่นชม",
    districtEn: "Chuen Chom",
    subdistrictTh: "เหล่าดอกไม้",
    subdistrictEn: "Lao Dok Mai",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4413,
    province_code: 32,
    name: "ชื่นชม เหล่าดอกไม้ มหาสารคาม 44160",
    name_th: "ชื่นชม เหล่าดอกไม้ มหาสารคาม 44160",
    name_en: "Chuen Chom, Lao Dok Mai, Maha Sarakham, 44160",
  },
  {
    id: 441304,
    districtTh: "ชื่นชม",
    districtEn: "Chuen Chom",
    subdistrictTh: "หนองกุง",
    subdistrictEn: "Nong Kung",
    provinceTh: "มหาสารคาม",
    provinceEn: "Maha Sarakham",
    zipcode: 44160,
    district_code: 4413,
    province_code: 32,
    name: "ชื่นชม หนองกุง มหาสารคาม 44160",
    name_th: "ชื่นชม หนองกุง มหาสารคาม 44160",
    name_en: "Chuen Chom, Nong Kung, Maha Sarakham, 44160",
  },
  {
    id: 450101,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด ในเมือง ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด ในเมือง ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Nai Mueang, Roi Et, 45000",
  },
  {
    id: 450102,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "รอบเมือง",
    subdistrictEn: "Rop Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด รอบเมือง ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด รอบเมือง ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Rop Mueang, Roi Et, 45000",
  },
  {
    id: 450103,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "เหนือเมือง",
    subdistrictEn: "Nuea Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด เหนือเมือง ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด เหนือเมือง ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Nuea Mueang, Roi Et, 45000",
  },
  {
    id: 450104,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "ขอนแก่น",
    subdistrictEn: "Khon Kaen",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด ขอนแก่น ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด ขอนแก่น ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Khon Kaen, Roi Et, 45000",
  },
  {
    id: 450105,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "นาโพธิ์",
    subdistrictEn: "Na Pho",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด นาโพธิ์ ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด นาโพธิ์ ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Na Pho, Roi Et, 45000",
  },
  {
    id: 450106,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "สะอาดสมบูรณ์",
    subdistrictEn: "Sa-at Sombun",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด สะอาดสมบูรณ์ ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด สะอาดสมบูรณ์ ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Sa-at Sombun, Roi Et, 45000",
  },
  {
    id: 450108,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "สีแก้ว",
    subdistrictEn: "Si Kaeo",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด สีแก้ว ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด สีแก้ว ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Si Kaeo, Roi Et, 45000",
  },
  {
    id: 450109,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "ปอภาร  (ปอพาน)",
    subdistrictEn: "Po Phan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด ปอภาร  (ปอพาน) ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด ปอภาร  (ปอพาน) ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Po Phan, Roi Et, 45000",
  },
  {
    id: 450110,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "โนนรัง",
    subdistrictEn: "Non Rang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด โนนรัง ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด โนนรัง ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Non Rang, Roi Et, 45000",
  },
  {
    id: 450117,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "หนองแก้ว",
    subdistrictEn: "Nong Kaeo",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด หนองแก้ว ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด หนองแก้ว ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Nong Kaeo, Roi Et, 45000",
  },
  {
    id: 450118,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด หนองแวง ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด หนองแวง ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Nong Waeng, Roi Et, 45000",
  },
  {
    id: 450120,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "ดงลาน",
    subdistrictEn: "Dong Lan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด ดงลาน ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด ดงลาน ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Dong Lan, Roi Et, 45000",
  },
  {
    id: 450123,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "แคนใหญ่",
    subdistrictEn: "Khaen Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด แคนใหญ่ ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด แคนใหญ่ ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Khaen Yai, Roi Et, 45000",
  },
  {
    id: 450124,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "โนนตาล",
    subdistrictEn: "Non Tan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด โนนตาล ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด โนนตาล ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Non Tan, Roi Et, 45000",
  },
  {
    id: 450125,
    districtTh: "เมืองร้อยเอ็ด",
    districtEn: "Mueang Roi Et",
    subdistrictTh: "เมืองทอง",
    subdistrictEn: "Mueang Thong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4501,
    province_code: 33,
    name: "เมืองร้อยเอ็ด เมืองทอง ร้อยเอ็ด 45000",
    name_th: "เมืองร้อยเอ็ด เมืองทอง ร้อยเอ็ด 45000",
    name_en: "Mueang Roi Et, Mueang Thong, Roi Et, 45000",
  },
  {
    id: 450201,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "เกษตรวิสัย",
    subdistrictEn: "Kaset Wisai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย เกษตรวิสัย ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย เกษตรวิสัย ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Kaset Wisai, Roi Et, 45150",
  },
  {
    id: 450202,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "เมืองบัว",
    subdistrictEn: "Mueang Bua",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย เมืองบัว ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย เมืองบัว ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Mueang Bua, Roi Et, 45150",
  },
  {
    id: 450203,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "เหล่าหลวง",
    subdistrictEn: "Lao Luang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย เหล่าหลวง ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย เหล่าหลวง ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Lao Luang, Roi Et, 45150",
  },
  {
    id: 450204,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "สิงห์โคก",
    subdistrictEn: "Sing Khok",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย สิงห์โคก ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย สิงห์โคก ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Sing Khok, Roi Et, 45150",
  },
  {
    id: 450205,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "ดงครั่งใหญ่",
    subdistrictEn: "Dong Khrang Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย ดงครั่งใหญ่ ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย ดงครั่งใหญ่ ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Dong Khrang Yai, Roi Et, 45150",
  },
  {
    id: 450206,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "บ้านฝาง",
    subdistrictEn: "Ban Fang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย บ้านฝาง ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย บ้านฝาง ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Ban Fang, Roi Et, 45150",
  },
  {
    id: 450207,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย หนองแวง ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย หนองแวง ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Nong Waeng, Roi Et, 45150",
  },
  {
    id: 450208,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "กำแพง",
    subdistrictEn: "Kamphaeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย กำแพง ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย กำแพง ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Kamphaeng, Roi Et, 45150",
  },
  {
    id: 450209,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "กู่กาสิงห์",
    subdistrictEn: "Ku Ka Sing",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย กู่กาสิงห์ ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย กู่กาสิงห์ ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Ku Ka Sing, Roi Et, 45150",
  },
  {
    id: 450210,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "น้ำอ้อม",
    subdistrictEn: "Nam Om",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย น้ำอ้อม ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย น้ำอ้อม ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Nam Om, Roi Et, 45150",
  },
  {
    id: 450211,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "โนนสว่าง",
    subdistrictEn: "Non Sawang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย โนนสว่าง ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย โนนสว่าง ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Non Sawang, Roi Et, 45150",
  },
  {
    id: 450212,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "ทุ่งทอง",
    subdistrictEn: "Thung Thong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย ทุ่งทอง ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย ทุ่งทอง ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Thung Thong, Roi Et, 45150",
  },
  {
    id: 450213,
    districtTh: "เกษตรวิสัย",
    districtEn: "Kaset Wisai",
    subdistrictTh: "ดงครั่งน้อย",
    subdistrictEn: "Dong Khrang Noi",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45150,
    district_code: 4502,
    province_code: 33,
    name: "เกษตรวิสัย ดงครั่งน้อย ร้อยเอ็ด 45150",
    name_th: "เกษตรวิสัย ดงครั่งน้อย ร้อยเอ็ด 45150",
    name_en: "Kaset Wisai, Dong Khrang Noi, Roi Et, 45150",
  },
  {
    id: 450301,
    districtTh: "ปทุมรัตต์",
    districtEn: "Pathum Rat",
    subdistrictTh: "บัวแดง",
    subdistrictEn: "Bua Daeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45190,
    district_code: 4503,
    province_code: 33,
    name: "ปทุมรัตต์ บัวแดง ร้อยเอ็ด 45190",
    name_th: "ปทุมรัตต์ บัวแดง ร้อยเอ็ด 45190",
    name_en: "Pathum Rat, Bua Daeng, Roi Et, 45190",
  },
  {
    id: 450302,
    districtTh: "ปทุมรัตต์",
    districtEn: "Pathum Rat",
    subdistrictTh: "ดอกล้ำ",
    subdistrictEn: "Dok Lam",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45190,
    district_code: 4503,
    province_code: 33,
    name: "ปทุมรัตต์ ดอกล้ำ ร้อยเอ็ด 45190",
    name_th: "ปทุมรัตต์ ดอกล้ำ ร้อยเอ็ด 45190",
    name_en: "Pathum Rat, Dok Lam, Roi Et, 45190",
  },
  {
    id: 450303,
    districtTh: "ปทุมรัตต์",
    districtEn: "Pathum Rat",
    subdistrictTh: "หนองแคน",
    subdistrictEn: "Nong Khaen",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45190,
    district_code: 4503,
    province_code: 33,
    name: "ปทุมรัตต์ หนองแคน ร้อยเอ็ด 45190",
    name_th: "ปทุมรัตต์ หนองแคน ร้อยเอ็ด 45190",
    name_en: "Pathum Rat, Nong Khaen, Roi Et, 45190",
  },
  {
    id: 450304,
    districtTh: "ปทุมรัตต์",
    districtEn: "Pathum Rat",
    subdistrictTh: "โพนสูง",
    subdistrictEn: "Phon Sung",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45190,
    district_code: 4503,
    province_code: 33,
    name: "ปทุมรัตต์ โพนสูง ร้อยเอ็ด 45190",
    name_th: "ปทุมรัตต์ โพนสูง ร้อยเอ็ด 45190",
    name_en: "Pathum Rat, Phon Sung, Roi Et, 45190",
  },
  {
    id: 450305,
    districtTh: "ปทุมรัตต์",
    districtEn: "Pathum Rat",
    subdistrictTh: "โนนสวรรค์",
    subdistrictEn: "Non Sawan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45190,
    district_code: 4503,
    province_code: 33,
    name: "ปทุมรัตต์ โนนสวรรค์ ร้อยเอ็ด 45190",
    name_th: "ปทุมรัตต์ โนนสวรรค์ ร้อยเอ็ด 45190",
    name_en: "Pathum Rat, Non Sawan, Roi Et, 45190",
  },
  {
    id: 450306,
    districtTh: "ปทุมรัตต์",
    districtEn: "Pathum Rat",
    subdistrictTh: "สระบัว",
    subdistrictEn: "Sa Bua",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45190,
    district_code: 4503,
    province_code: 33,
    name: "ปทุมรัตต์ สระบัว ร้อยเอ็ด 45190",
    name_th: "ปทุมรัตต์ สระบัว ร้อยเอ็ด 45190",
    name_en: "Pathum Rat, Sa Bua, Roi Et, 45190",
  },
  {
    id: 450307,
    districtTh: "ปทุมรัตต์",
    districtEn: "Pathum Rat",
    subdistrictTh: "โนนสง่า",
    subdistrictEn: "Non Sa-nga",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45190,
    district_code: 4503,
    province_code: 33,
    name: "ปทุมรัตต์ โนนสง่า ร้อยเอ็ด 45190",
    name_th: "ปทุมรัตต์ โนนสง่า ร้อยเอ็ด 45190",
    name_en: "Pathum Rat, Non Sa-nga, Roi Et, 45190",
  },
  {
    id: 450308,
    districtTh: "ปทุมรัตต์",
    districtEn: "Pathum Rat",
    subdistrictTh: "ขี้เหล็ก",
    subdistrictEn: "Khilek",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45190,
    district_code: 4503,
    province_code: 33,
    name: "ปทุมรัตต์ ขี้เหล็ก ร้อยเอ็ด 45190",
    name_th: "ปทุมรัตต์ ขี้เหล็ก ร้อยเอ็ด 45190",
    name_en: "Pathum Rat, Khilek, Roi Et, 45190",
  },
  {
    id: 450401,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "หัวช้าง",
    subdistrictEn: "Hua Chang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน หัวช้าง ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน หัวช้าง ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Hua Chang, Roi Et, 45180",
  },
  {
    id: 450402,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "หนองผือ",
    subdistrictEn: "Nong Phue",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน หนองผือ ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน หนองผือ ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Nong Phue, Roi Et, 45180",
  },
  {
    id: 450403,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "เมืองหงส์",
    subdistrictEn: "Mueang Hong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน เมืองหงส์ ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน เมืองหงส์ ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Mueang Hong, Roi Et, 45180",
  },
  {
    id: 450404,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "โคกล่าม",
    subdistrictEn: "Khok Lam",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน โคกล่าม ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน โคกล่าม ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Khok Lam, Roi Et, 45180",
  },
  {
    id: 450405,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "น้ำใส",
    subdistrictEn: "Nam Sai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน น้ำใส ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน น้ำใส ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Nam Sai, Roi Et, 45180",
  },
  {
    id: 450406,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "ดงแดง",
    subdistrictEn: "Dong Daeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน ดงแดง ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน ดงแดง ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Dong Daeng, Roi Et, 45180",
  },
  {
    id: 450407,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "ดงกลาง",
    subdistrictEn: "Dong Klang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน ดงกลาง ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน ดงกลาง ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Dong Klang, Roi Et, 45180",
  },
  {
    id: 450408,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "ป่าสังข์",
    subdistrictEn: "Pa Sang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน ป่าสังข์ ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน ป่าสังข์ ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Pa Sang, Roi Et, 45180",
  },
  {
    id: 450409,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "อีง่อง",
    subdistrictEn: "I Ngong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน อีง่อง ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน อีง่อง ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, I Ngong, Roi Et, 45180",
  },
  {
    id: 450410,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "ลิ้นฟ้า",
    subdistrictEn: "Lin Fa",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน ลิ้นฟ้า ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน ลิ้นฟ้า ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Lin Fa, Roi Et, 45180",
  },
  {
    id: 450411,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "ดู่น้อย",
    subdistrictEn: "Du Noi",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน ดู่น้อย ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน ดู่น้อย ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Du Noi, Roi Et, 45180",
  },
  {
    id: 450412,
    districtTh: "จตุรพักตรพิมาน",
    districtEn: "Chaturaphak Phiman",
    subdistrictTh: "ศรีโคตร",
    subdistrictEn: "Si Khot",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45180,
    district_code: 4504,
    province_code: 33,
    name: "จตุรพักตรพิมาน ศรีโคตร ร้อยเอ็ด 45180",
    name_th: "จตุรพักตรพิมาน ศรีโคตร ร้อยเอ็ด 45180",
    name_en: "Chaturaphak Phiman, Si Khot, Roi Et, 45180",
  },
  {
    id: 450501,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "นิเวศน์",
    subdistrictEn: "Niwet",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี นิเวศน์ ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี นิเวศน์ ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Niwet, Roi Et, 45170",
  },
  {
    id: 450502,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "ธงธานี",
    subdistrictEn: "Thong Thani",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี ธงธานี ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี ธงธานี ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Thong Thani, Roi Et, 45170",
  },
  {
    id: 450503,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี หนองไผ่ ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี หนองไผ่ ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Nong Phai, Roi Et, 45170",
  },
  {
    id: 450504,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "ธวัชบุรี",
    subdistrictEn: "Thawat Buri",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี ธวัชบุรี ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี ธวัชบุรี ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Thawat Buri, Roi Et, 45170",
  },
  {
    id: 450506,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "อุ่มเม้า",
    subdistrictEn: "Um Mao",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี อุ่มเม้า ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี อุ่มเม้า ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Um Mao, Roi Et, 45170",
  },
  {
    id: 450507,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "มะอึ",
    subdistrictEn: "Ma-ue",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี มะอึ ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี มะอึ ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Ma-ue, Roi Et, 45170",
  },
  {
    id: 450510,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "เขวาทุ่ง",
    subdistrictEn: "Khwao Thung",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี เขวาทุ่ง ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี เขวาทุ่ง ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Khwao Thung, Roi Et, 45170",
  },
  {
    id: 450515,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "ไพศาล",
    subdistrictEn: "Phaisan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี ไพศาล ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี ไพศาล ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Phaisan, Roi Et, 45170",
  },
  {
    id: 450517,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "เมืองน้อย",
    subdistrictEn: "Mueang Noi",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี เมืองน้อย ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี เมืองน้อย ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Mueang Noi, Roi Et, 45170",
  },
  {
    id: 450520,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "บึงนคร",
    subdistrictEn: "Bueng Nakhon",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี บึงนคร ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี บึงนคร ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Bueng Nakhon, Roi Et, 45170",
  },
  {
    id: 450522,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "ราชธานี",
    subdistrictEn: "Ratchathani",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี ราชธานี ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี ราชธานี ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Ratchathani, Roi Et, 45170",
  },
  {
    id: 450524,
    districtTh: "ธวัชบุรี",
    districtEn: "Thawat Buri",
    subdistrictTh: "หนองพอก",
    subdistrictEn: "Nong Phok",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4505,
    province_code: 33,
    name: "ธวัชบุรี หนองพอก ร้อยเอ็ด 45170",
    name_th: "ธวัชบุรี หนองพอก ร้อยเอ็ด 45170",
    name_en: "Thawat Buri, Nong Phok, Roi Et, 45170",
  },
  {
    id: 450601,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "พนมไพร",
    subdistrictEn: "Phanom Phrai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร พนมไพร ร้อยเอ็ด 45140",
    name_th: "พนมไพร พนมไพร ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Phanom Phrai, Roi Et, 45140",
  },
  {
    id: 450602,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "แสนสุข",
    subdistrictEn: "Saen Suk",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร แสนสุข ร้อยเอ็ด 45140",
    name_th: "พนมไพร แสนสุข ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Saen Suk, Roi Et, 45140",
  },
  {
    id: 450603,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "กุดน้ำใส",
    subdistrictEn: "Kut Nam Sai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร กุดน้ำใส ร้อยเอ็ด 45140",
    name_th: "พนมไพร กุดน้ำใส ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Kut Nam Sai, Roi Et, 45140",
  },
  {
    id: 450604,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "หนองทัพไทย",
    subdistrictEn: "Nong Thap Thai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร หนองทัพไทย ร้อยเอ็ด 45140",
    name_th: "พนมไพร หนองทัพไทย ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Nong Thap Thai, Roi Et, 45140",
  },
  {
    id: 450605,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "โพธิ์ใหญ่",
    subdistrictEn: "Pho Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร โพธิ์ใหญ่ ร้อยเอ็ด 45140",
    name_th: "พนมไพร โพธิ์ใหญ่ ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Pho Yai, Roi Et, 45140",
  },
  {
    id: 450606,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "วารีสวัสดิ์",
    subdistrictEn: "Wari Sawat",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร วารีสวัสดิ์ ร้อยเอ็ด 45140",
    name_th: "พนมไพร วารีสวัสดิ์ ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Wari Sawat, Roi Et, 45140",
  },
  {
    id: 450607,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "โคกสว่าง",
    subdistrictEn: "Khok Sawang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร โคกสว่าง ร้อยเอ็ด 45140",
    name_th: "พนมไพร โคกสว่าง ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Khok Sawang, Roi Et, 45140",
  },
  {
    id: 450611,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "โพธิ์ชัย",
    subdistrictEn: "Pho Chai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร โพธิ์ชัย ร้อยเอ็ด 45140",
    name_th: "พนมไพร โพธิ์ชัย ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Pho Chai, Roi Et, 45140",
  },
  {
    id: 450612,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "นานวล",
    subdistrictEn: "Na Nuan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร นานวล ร้อยเอ็ด 45140",
    name_th: "พนมไพร นานวล ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Na Nuan, Roi Et, 45140",
  },
  {
    id: 450613,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "คำไฮ",
    subdistrictEn: "Kham Hai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร คำไฮ ร้อยเอ็ด 45140",
    name_th: "พนมไพร คำไฮ ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Kham Hai, Roi Et, 45140",
  },
  {
    id: 450614,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร สระแก้ว ร้อยเอ็ด 45140",
    name_th: "พนมไพร สระแก้ว ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Sa Kaeo, Roi Et, 45140",
  },
  {
    id: 450615,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "ค้อใหญ่",
    subdistrictEn: "Kho Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร ค้อใหญ่ ร้อยเอ็ด 45140",
    name_th: "พนมไพร ค้อใหญ่ ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Kho Yai, Roi Et, 45140",
  },
  {
    id: 450617,
    districtTh: "พนมไพร",
    districtEn: "Phanom Phrai",
    subdistrictTh: "ชานุวรรณ",
    subdistrictEn: "Chanuwan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4506,
    province_code: 33,
    name: "พนมไพร ชานุวรรณ ร้อยเอ็ด 45140",
    name_th: "พนมไพร ชานุวรรณ ร้อยเอ็ด 45140",
    name_en: "Phanom Phrai, Chanuwan, Roi Et, 45140",
  },
  {
    id: 450701,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "แวง",
    subdistrictEn: "Waeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง แวง ร้อยเอ็ด 45110",
    name_th: "โพนทอง แวง ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Waeng, Roi Et, 45110",
  },
  {
    id: 450702,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "โคกกกม่วง",
    subdistrictEn: "Khok Kok Muang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง โคกกกม่วง ร้อยเอ็ด 45110",
    name_th: "โพนทอง โคกกกม่วง ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Khok Kok Muang, Roi Et, 45110",
  },
  {
    id: 450703,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "นาอุดม",
    subdistrictEn: "Na Udom",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง นาอุดม ร้อยเอ็ด 45110",
    name_th: "โพนทอง นาอุดม ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Na Udom, Roi Et, 45110",
  },
  {
    id: 450704,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "สว่าง",
    subdistrictEn: "Sawang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง สว่าง ร้อยเอ็ด 45110",
    name_th: "โพนทอง สว่าง ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Sawang, Roi Et, 45110",
  },
  {
    id: 450705,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "หนองใหญ่",
    subdistrictEn: "Nong Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง หนองใหญ่ ร้อยเอ็ด 45110",
    name_th: "โพนทอง หนองใหญ่ ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Nong Yai, Roi Et, 45110",
  },
  {
    id: 450706,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "โพธิ์ทอง",
    subdistrictEn: "Pho Thong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง โพธิ์ทอง ร้อยเอ็ด 45110",
    name_th: "โพนทอง โพธิ์ทอง ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Pho Thong, Roi Et, 45110",
  },
  {
    id: 450707,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "โนนชัยศรี",
    subdistrictEn: "Non Chai Si",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง โนนชัยศรี ร้อยเอ็ด 45110",
    name_th: "โพนทอง โนนชัยศรี ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Non Chai Si, Roi Et, 45110",
  },
  {
    id: 450708,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "โพธิ์ศรีสว่าง",
    subdistrictEn: "Pho Si Sawang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง โพธิ์ศรีสว่าง ร้อยเอ็ด 45110",
    name_th: "โพนทอง โพธิ์ศรีสว่าง ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Pho Si Sawang, Roi Et, 45110",
  },
  {
    id: 450709,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "อุ่มเม่า",
    subdistrictEn: "Um Mao",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง อุ่มเม่า ร้อยเอ็ด 45110",
    name_th: "โพนทอง อุ่มเม่า ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Um Mao, Roi Et, 45110",
  },
  {
    id: 450710,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "คำนาดี",
    subdistrictEn: "Kham Na Di",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง คำนาดี ร้อยเอ็ด 45110",
    name_th: "โพนทอง คำนาดี ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Kham Na Di, Roi Et, 45110",
  },
  {
    id: 450711,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "พรมสวรรค์",
    subdistrictEn: "Phrom Sawan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง พรมสวรรค์ ร้อยเอ็ด 45110",
    name_th: "โพนทอง พรมสวรรค์ ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Phrom Sawan, Roi Et, 45110",
  },
  {
    id: 450712,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "สระนกแก้ว",
    subdistrictEn: "Sa Nok Kaeo",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง สระนกแก้ว ร้อยเอ็ด 45110",
    name_th: "โพนทอง สระนกแก้ว ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Sa Nok Kaeo, Roi Et, 45110",
  },
  {
    id: 450713,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "วังสามัคคี",
    subdistrictEn: "Wang Samakkhi",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง วังสามัคคี ร้อยเอ็ด 45110",
    name_th: "โพนทอง วังสามัคคี ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Wang Samakkhi, Roi Et, 45110",
  },
  {
    id: 450714,
    districtTh: "โพนทอง",
    districtEn: "Phon Thong",
    subdistrictTh: "โคกสูง",
    subdistrictEn: "Khok Sung",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45110,
    district_code: 4507,
    province_code: 33,
    name: "โพนทอง โคกสูง ร้อยเอ็ด 45110",
    name_th: "โพนทอง โคกสูง ร้อยเอ็ด 45110",
    name_en: "Phon Thong, Khok Sung, Roi Et, 45110",
  },
  {
    id: 450801,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "ขามเปี้ย",
    subdistrictEn: "Kham Pia",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย ขามเปี้ย ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย ขามเปี้ย ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Kham Pia, Roi Et, 45230",
  },
  {
    id: 450802,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "เชียงใหม่",
    subdistrictEn: "Chiang Mai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย เชียงใหม่ ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย เชียงใหม่ ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Chiang Mai, Roi Et, 45230",
  },
  {
    id: 450804,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "อัคคะคำ",
    subdistrictEn: "Akkha Kham",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย อัคคะคำ ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย อัคคะคำ ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Akkha Kham, Roi Et, 45230",
  },
  {
    id: 450805,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "สะอาด",
    subdistrictEn: "Sa-at",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย สะอาด ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย สะอาด ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Sa-at, Roi Et, 45230",
  },
  {
    id: 450806,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "คำพอุง",
    subdistrictEn: "Kham Pha-ung",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย คำพอุง ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย คำพอุง ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Kham Pha-ung, Roi Et, 45230",
  },
  {
    id: 450807,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "หนองตาไก้",
    subdistrictEn: "Nong Ta Kai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย หนองตาไก้ ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย หนองตาไก้ ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Nong Ta Kai, Roi Et, 45230",
  },
  {
    id: 450808,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "ดอนโอง",
    subdistrictEn: "Don Ong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย ดอนโอง ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย ดอนโอง ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Don Ong, Roi Et, 45230",
  },
  {
    id: 450809,
    districtTh: "โพธิ์ชัย",
    districtEn: "Pho Chai",
    subdistrictTh: "โพธิ์ศรี",
    subdistrictEn: "Pho Si",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45230,
    district_code: 4508,
    province_code: 33,
    name: "โพธิ์ชัย โพธิ์ศรี ร้อยเอ็ด 45230",
    name_th: "โพธิ์ชัย โพธิ์ศรี ร้อยเอ็ด 45230",
    name_en: "Pho Chai, Pho Si, Roi Et, 45230",
  },
  {
    id: 450901,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "หนองพอก",
    subdistrictEn: "Nong Phok",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก หนองพอก ร้อยเอ็ด 45210",
    name_th: "หนองพอก หนองพอก ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Nong Phok, Roi Et, 45210",
  },
  {
    id: 450902,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "บึงงาม",
    subdistrictEn: "Bueng Ngam",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก บึงงาม ร้อยเอ็ด 45210",
    name_th: "หนองพอก บึงงาม ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Bueng Ngam, Roi Et, 45210",
  },
  {
    id: 450903,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "ภูเขาทอง",
    subdistrictEn: "Phukhao Thong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก ภูเขาทอง ร้อยเอ็ด 45210",
    name_th: "หนองพอก ภูเขาทอง ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Phukhao Thong, Roi Et, 45210",
  },
  {
    id: 450904,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "กกโพธิ์",
    subdistrictEn: "Kok Pho",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก กกโพธิ์ ร้อยเอ็ด 45210",
    name_th: "หนองพอก กกโพธิ์ ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Kok Pho, Roi Et, 45210",
  },
  {
    id: 450905,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "โคกสว่าง",
    subdistrictEn: "Khok Sawang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก โคกสว่าง ร้อยเอ็ด 45210",
    name_th: "หนองพอก โคกสว่าง ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Khok Sawang, Roi Et, 45210",
  },
  {
    id: 450906,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "หนองขุ่นใหญ่",
    subdistrictEn: "Nong Khun Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก หนองขุ่นใหญ่ ร้อยเอ็ด 45210",
    name_th: "หนองพอก หนองขุ่นใหญ่ ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Nong Khun Yai, Roi Et, 45210",
  },
  {
    id: 450907,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "รอบเมือง",
    subdistrictEn: "Rop Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก รอบเมือง ร้อยเอ็ด 45210",
    name_th: "หนองพอก รอบเมือง ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Rop Mueang, Roi Et, 45210",
  },
  {
    id: 450908,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "ผาน้ำย้อย",
    subdistrictEn: "Pha Nam Yoi",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก ผาน้ำย้อย ร้อยเอ็ด 45210",
    name_th: "หนองพอก ผาน้ำย้อย ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Pha Nam Yoi, Roi Et, 45210",
  },
  {
    id: 450909,
    districtTh: "หนองพอก",
    districtEn: "Nong Phok",
    subdistrictTh: "ท่าสีดา",
    subdistrictEn: "Ta See Da",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45210,
    district_code: 4509,
    province_code: 33,
    name: "หนองพอก ท่าสีดา ร้อยเอ็ด 45210",
    name_th: "หนองพอก ท่าสีดา ร้อยเอ็ด 45210",
    name_en: "Nong Phok, Ta See Da, Roi Et, 45210",
  },
  {
    id: 451001,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "กลาง",
    subdistrictEn: "Klang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ กลาง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ กลาง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Klang, Roi Et, 45120",
  },
  {
    id: 451002,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "นางาม",
    subdistrictEn: "Na Ngam",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ นางาม ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ นางาม ร้อยเอ็ด 45120",
    name_en: "Selaphum, Na Ngam, Roi Et, 45120",
  },
  {
    id: 451003,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "เมืองไพร",
    subdistrictEn: "Mueang Phrai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ เมืองไพร ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ เมืองไพร ร้อยเอ็ด 45120",
    name_en: "Selaphum, Mueang Phrai, Roi Et, 45120",
  },
  {
    id: 451004,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "นาแซง",
    subdistrictEn: "Na Saeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ นาแซง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ นาแซง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Na Saeng, Roi Et, 45120",
  },
  {
    id: 451005,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "นาเมือง",
    subdistrictEn: "Na Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ นาเมือง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ นาเมือง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Na Mueang, Roi Et, 45120",
  },
  {
    id: 451006,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "วังหลวง",
    subdistrictEn: "Wang Luang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ วังหลวง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ วังหลวง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Wang Luang, Roi Et, 45120",
  },
  {
    id: 451007,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "ท่าม่วง",
    subdistrictEn: "Tha Muang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ ท่าม่วง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ ท่าม่วง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Tha Muang, Roi Et, 45120",
  },
  {
    id: 451008,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "ขวาว",
    subdistrictEn: "Khwao",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ ขวาว ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ ขวาว ร้อยเอ็ด 45120",
    name_en: "Selaphum, Khwao, Roi Et, 45120",
  },
  {
    id: 451009,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "โพธิ์ทอง",
    subdistrictEn: "Pho Thong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ โพธิ์ทอง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ โพธิ์ทอง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Pho Thong, Roi Et, 45120",
  },
  {
    id: 451010,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "ภูเงิน",
    subdistrictEn: "Phu Ngoen",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ ภูเงิน ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ ภูเงิน ร้อยเอ็ด 45120",
    name_en: "Selaphum, Phu Ngoen, Roi Et, 45120",
  },
  {
    id: 451011,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "เกาะแก้ว",
    subdistrictEn: "Ko Kaeo",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ เกาะแก้ว ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ เกาะแก้ว ร้อยเอ็ด 45120",
    name_en: "Selaphum, Ko Kaeo, Roi Et, 45120",
  },
  {
    id: 451012,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "นาเลิง",
    subdistrictEn: "Na Loeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ นาเลิง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ นาเลิง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Na Loeng, Roi Et, 45120",
  },
  {
    id: 451013,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "เหล่าน้อย",
    subdistrictEn: "Lao Noi",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ เหล่าน้อย ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ เหล่าน้อย ร้อยเอ็ด 45120",
    name_en: "Selaphum, Lao Noi, Roi Et, 45120",
  },
  {
    id: 451014,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "ศรีวิลัย",
    subdistrictEn: "Si Wilai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ ศรีวิลัย ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ ศรีวิลัย ร้อยเอ็ด 45120",
    name_en: "Selaphum, Si Wilai, Roi Et, 45120",
  },
  {
    id: 451015,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ หนองหลวง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ หนองหลวง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Nong Luang, Roi Et, 45120",
  },
  {
    id: 451016,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "พรสวรรค์",
    subdistrictEn: "Phon Sawan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ พรสวรรค์ ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ พรสวรรค์ ร้อยเอ็ด 45120",
    name_en: "Selaphum, Phon Sawan, Roi Et, 45120",
  },
  {
    id: 451017,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "ขวัญเมือง",
    subdistrictEn: "Khwan Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ ขวัญเมือง ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ ขวัญเมือง ร้อยเอ็ด 45120",
    name_en: "Selaphum, Khwan Mueang, Roi Et, 45120",
  },
  {
    id: 451018,
    districtTh: "เสลภูมิ",
    districtEn: "Selaphum",
    subdistrictTh: "บึงเกลือ",
    subdistrictEn: "Bueng Kluea",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45120,
    district_code: 4510,
    province_code: 33,
    name: "เสลภูมิ บึงเกลือ ร้อยเอ็ด 45120",
    name_th: "เสลภูมิ บึงเกลือ ร้อยเอ็ด 45120",
    name_en: "Selaphum, Bueng Kluea, Roi Et, 45120",
  },
  {
    id: 451101,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "สระคู",
    subdistrictEn: "Sa Khu",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ สระคู ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ สระคู ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Sa Khu, Roi Et, 45130",
  },
  {
    id: 451102,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "ดอกไม้",
    subdistrictEn: "Dok Mai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ ดอกไม้ ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ ดอกไม้ ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Dok Mai, Roi Et, 45130",
  },
  {
    id: 451103,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "นาใหญ่",
    subdistrictEn: "Na Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ นาใหญ่ ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ นาใหญ่ ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Na Yai, Roi Et, 45130",
  },
  {
    id: 451104,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "หินกอง",
    subdistrictEn: "Hin Kong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ หินกอง ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ หินกอง ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Hin Kong, Roi Et, 45130",
  },
  {
    id: 451105,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "เมืองทุ่ง",
    subdistrictEn: "Mueang Thung",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ เมืองทุ่ง ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ เมืองทุ่ง ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Mueang Thung, Roi Et, 45130",
  },
  {
    id: 451106,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "หัวโทน",
    subdistrictEn: "Hua Thon",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ หัวโทน ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ หัวโทน ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Hua Thon, Roi Et, 45130",
  },
  {
    id: 451107,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "บ่อพันขัน",
    subdistrictEn: "Bo Phan Khan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ บ่อพันขัน ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ บ่อพันขัน ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Bo Phan Khan, Roi Et, 45130",
  },
  {
    id: 451108,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "ทุ่งหลวง",
    subdistrictEn: "Thung Luang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ ทุ่งหลวง ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ ทุ่งหลวง ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Thung Luang, Roi Et, 45130",
  },
  {
    id: 451109,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "หัวช้าง",
    subdistrictEn: "Hua Chang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ หัวช้าง ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ หัวช้าง ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Hua Chang, Roi Et, 45130",
  },
  {
    id: 451110,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "น้ำคำ",
    subdistrictEn: "Nam Kham",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ น้ำคำ ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ น้ำคำ ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Nam Kham, Roi Et, 45130",
  },
  {
    id: 451111,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "ห้วยหินลาด",
    subdistrictEn: "Huai Hin Lat",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ ห้วยหินลาด ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ ห้วยหินลาด ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Huai Hin Lat, Roi Et, 45130",
  },
  {
    id: 451112,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "ช้างเผือก",
    subdistrictEn: "Chang Phueak",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ ช้างเผือก ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ ช้างเผือก ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Chang Phueak, Roi Et, 45130",
  },
  {
    id: 451113,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "ทุ่งกุลา",
    subdistrictEn: "Thung Kula",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ ทุ่งกุลา ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ ทุ่งกุลา ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Thung Kula, Roi Et, 45130",
  },
  {
    id: 451114,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "ทุ่งศรีเมือง",
    subdistrictEn: "Thung Si Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ ทุ่งศรีเมือง ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ ทุ่งศรีเมือง ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Thung Si Mueang, Roi Et, 45130",
  },
  {
    id: 451115,
    districtTh: "สุวรรณภูมิ",
    districtEn: "Suwannaphum",
    subdistrictTh: "จำปาขัน",
    subdistrictEn: "Champa Khan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45130,
    district_code: 4511,
    province_code: 33,
    name: "สุวรรณภูมิ จำปาขัน ร้อยเอ็ด 45130",
    name_th: "สุวรรณภูมิ จำปาขัน ร้อยเอ็ด 45130",
    name_en: "Suwannaphum, Champa Khan, Roi Et, 45130",
  },
  {
    id: 451201,
    districtTh: "เมืองสรวง",
    districtEn: "Mueang Suang",
    subdistrictTh: "หนองผือ",
    subdistrictEn: "Nong Phue",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45220,
    district_code: 4512,
    province_code: 33,
    name: "เมืองสรวง หนองผือ ร้อยเอ็ด 45220",
    name_th: "เมืองสรวง หนองผือ ร้อยเอ็ด 45220",
    name_en: "Mueang Suang, Nong Phue, Roi Et, 45220",
  },
  {
    id: 451202,
    districtTh: "เมืองสรวง",
    districtEn: "Mueang Suang",
    subdistrictTh: "หนองหิน",
    subdistrictEn: "Nong Hin",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45220,
    district_code: 4512,
    province_code: 33,
    name: "เมืองสรวง หนองหิน ร้อยเอ็ด 45220",
    name_th: "เมืองสรวง หนองหิน ร้อยเอ็ด 45220",
    name_en: "Mueang Suang, Nong Hin, Roi Et, 45220",
  },
  {
    id: 451203,
    districtTh: "เมืองสรวง",
    districtEn: "Mueang Suang",
    subdistrictTh: "คูเมือง",
    subdistrictEn: "Khu Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45220,
    district_code: 4512,
    province_code: 33,
    name: "เมืองสรวง คูเมือง ร้อยเอ็ด 45220",
    name_th: "เมืองสรวง คูเมือง ร้อยเอ็ด 45220",
    name_en: "Mueang Suang, Khu Mueang, Roi Et, 45220",
  },
  {
    id: 451204,
    districtTh: "เมืองสรวง",
    districtEn: "Mueang Suang",
    subdistrictTh: "กกกุง",
    subdistrictEn: "Kok Kung",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45220,
    district_code: 4512,
    province_code: 33,
    name: "เมืองสรวง กกกุง ร้อยเอ็ด 45220",
    name_th: "เมืองสรวง กกกุง ร้อยเอ็ด 45220",
    name_en: "Mueang Suang, Kok Kung, Roi Et, 45220",
  },
  {
    id: 451205,
    districtTh: "เมืองสรวง",
    districtEn: "Mueang Suang",
    subdistrictTh: "เมืองสรวง",
    subdistrictEn: "Mueang Suang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45220,
    district_code: 4512,
    province_code: 33,
    name: "เมืองสรวง เมืองสรวง ร้อยเอ็ด 45220",
    name_th: "เมืองสรวง เมืองสรวง ร้อยเอ็ด 45220",
    name_en: "Mueang Suang, Mueang Suang, Roi Et, 45220",
  },
  {
    id: 451301,
    districtTh: "โพนทราย",
    districtEn: "Phon Sai",
    subdistrictTh: "โพนทราย",
    subdistrictEn: "Phon Sai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45240,
    district_code: 4513,
    province_code: 33,
    name: "โพนทราย โพนทราย ร้อยเอ็ด 45240",
    name_th: "โพนทราย โพนทราย ร้อยเอ็ด 45240",
    name_en: "Phon Sai, Phon Sai, Roi Et, 45240",
  },
  {
    id: 451302,
    districtTh: "โพนทราย",
    districtEn: "Phon Sai",
    subdistrictTh: "สามขา",
    subdistrictEn: "Sam Kha",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45240,
    district_code: 4513,
    province_code: 33,
    name: "โพนทราย สามขา ร้อยเอ็ด 45240",
    name_th: "โพนทราย สามขา ร้อยเอ็ด 45240",
    name_en: "Phon Sai, Sam Kha, Roi Et, 45240",
  },
  {
    id: 451303,
    districtTh: "โพนทราย",
    districtEn: "Phon Sai",
    subdistrictTh: "ศรีสว่าง",
    subdistrictEn: "Si Sawang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45240,
    district_code: 4513,
    province_code: 33,
    name: "โพนทราย ศรีสว่าง ร้อยเอ็ด 45240",
    name_th: "โพนทราย ศรีสว่าง ร้อยเอ็ด 45240",
    name_en: "Phon Sai, Si Sawang, Roi Et, 45240",
  },
  {
    id: 451304,
    districtTh: "โพนทราย",
    districtEn: "Phon Sai",
    subdistrictTh: "ยางคำ",
    subdistrictEn: "Yang Kham",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45240,
    district_code: 4513,
    province_code: 33,
    name: "โพนทราย ยางคำ ร้อยเอ็ด 45240",
    name_th: "โพนทราย ยางคำ ร้อยเอ็ด 45240",
    name_en: "Phon Sai, Yang Kham, Roi Et, 45240",
  },
  {
    id: 451305,
    districtTh: "โพนทราย",
    districtEn: "Phon Sai",
    subdistrictTh: "ท่าหาดยาว",
    subdistrictEn: "Tha Hat Yao",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45240,
    district_code: 4513,
    province_code: 33,
    name: "โพนทราย ท่าหาดยาว ร้อยเอ็ด 45240",
    name_th: "โพนทราย ท่าหาดยาว ร้อยเอ็ด 45240",
    name_en: "Phon Sai, Tha Hat Yao, Roi Et, 45240",
  },
  {
    id: 451401,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "อาจสามารถ",
    subdistrictEn: "At Samat",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ อาจสามารถ ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ อาจสามารถ ร้อยเอ็ด 45160",
    name_en: "At Samat, At Samat, Roi Et, 45160",
  },
  {
    id: 451402,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "โพนเมือง",
    subdistrictEn: "Phon Mueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ โพนเมือง ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ โพนเมือง ร้อยเอ็ด 45160",
    name_en: "At Samat, Phon Mueang, Roi Et, 45160",
  },
  {
    id: 451403,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "บ้านแจ้ง",
    subdistrictEn: "Ban Chaeng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ บ้านแจ้ง ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ บ้านแจ้ง ร้อยเอ็ด 45160",
    name_en: "At Samat, Ban Chaeng, Roi Et, 45160",
  },
  {
    id: 451404,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "หน่อม",
    subdistrictEn: "Nom",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ หน่อม ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ หน่อม ร้อยเอ็ด 45160",
    name_en: "At Samat, Nom, Roi Et, 45160",
  },
  {
    id: 451405,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "หนองหมื่นถ่าน",
    subdistrictEn: "Nong Muen Than",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ หนองหมื่นถ่าน ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ หนองหมื่นถ่าน ร้อยเอ็ด 45160",
    name_en: "At Samat, Nong Muen Than, Roi Et, 45160",
  },
  {
    id: 451406,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "หนองขาม",
    subdistrictEn: "Nong Kham",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ หนองขาม ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ หนองขาม ร้อยเอ็ด 45160",
    name_en: "At Samat, Nong Kham, Roi Et, 45160",
  },
  {
    id: 451407,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "โหรา",
    subdistrictEn: "Hora",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ โหรา ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ โหรา ร้อยเอ็ด 45160",
    name_en: "At Samat, Hora, Roi Et, 45160",
  },
  {
    id: 451408,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ หนองบัว ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ หนองบัว ร้อยเอ็ด 45160",
    name_en: "At Samat, Nong Bua, Roi Et, 45160",
  },
  {
    id: 451409,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "ขี้เหล็ก",
    subdistrictEn: "Khilek",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ ขี้เหล็ก ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ ขี้เหล็ก ร้อยเอ็ด 45160",
    name_en: "At Samat, Khilek, Roi Et, 45160",
  },
  {
    id: 451410,
    districtTh: "อาจสามารถ",
    districtEn: "At Samat",
    subdistrictTh: "บ้านดู่",
    subdistrictEn: "Ban Du",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45160,
    district_code: 4514,
    province_code: 33,
    name: "อาจสามารถ บ้านดู่ ร้อยเอ็ด 45160",
    name_th: "อาจสามารถ บ้านดู่ ร้อยเอ็ด 45160",
    name_en: "At Samat, Ban Du, Roi Et, 45160",
  },
  {
    id: 451501,
    districtTh: "เมยวดี",
    districtEn: "Moei Wadi",
    subdistrictTh: "เมยวดี",
    subdistrictEn: "Moei Wadi",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45250,
    district_code: 4515,
    province_code: 33,
    name: "เมยวดี เมยวดี ร้อยเอ็ด 45250",
    name_th: "เมยวดี เมยวดี ร้อยเอ็ด 45250",
    name_en: "Moei Wadi, Moei Wadi, Roi Et, 45250",
  },
  {
    id: 451502,
    districtTh: "เมยวดี",
    districtEn: "Moei Wadi",
    subdistrictTh: "ชุมพร",
    subdistrictEn: "Chumphon",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45250,
    district_code: 4515,
    province_code: 33,
    name: "เมยวดี ชุมพร ร้อยเอ็ด 45250",
    name_th: "เมยวดี ชุมพร ร้อยเอ็ด 45250",
    name_en: "Moei Wadi, Chumphon, Roi Et, 45250",
  },
  {
    id: 451503,
    districtTh: "เมยวดี",
    districtEn: "Moei Wadi",
    subdistrictTh: "บุ่งเลิศ",
    subdistrictEn: "Bung Loet",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45250,
    district_code: 4515,
    province_code: 33,
    name: "เมยวดี บุ่งเลิศ ร้อยเอ็ด 45250",
    name_th: "เมยวดี บุ่งเลิศ ร้อยเอ็ด 45250",
    name_en: "Moei Wadi, Bung Loet, Roi Et, 45250",
  },
  {
    id: 451504,
    districtTh: "เมยวดี",
    districtEn: "Moei Wadi",
    subdistrictTh: "ชมสะอาด",
    subdistrictEn: "Chom Sa-at",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45250,
    district_code: 4515,
    province_code: 33,
    name: "เมยวดี ชมสะอาด ร้อยเอ็ด 45250",
    name_th: "เมยวดี ชมสะอาด ร้อยเอ็ด 45250",
    name_en: "Moei Wadi, Chom Sa-at, Roi Et, 45250",
  },
  {
    id: 451601,
    districtTh: "ศรีสมเด็จ",
    districtEn: "Si Somdet",
    subdistrictTh: "โพธิ์ทอง",
    subdistrictEn: "Pho Thong",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4516,
    province_code: 33,
    name: "ศรีสมเด็จ โพธิ์ทอง ร้อยเอ็ด 45000",
    name_th: "ศรีสมเด็จ โพธิ์ทอง ร้อยเอ็ด 45000",
    name_en: "Si Somdet, Pho Thong, Roi Et, 45000",
  },
  {
    id: 451602,
    districtTh: "ศรีสมเด็จ",
    districtEn: "Si Somdet",
    subdistrictTh: "ศรีสมเด็จ",
    subdistrictEn: "Si Somdet",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4516,
    province_code: 33,
    name: "ศรีสมเด็จ ศรีสมเด็จ ร้อยเอ็ด 45000",
    name_th: "ศรีสมเด็จ ศรีสมเด็จ ร้อยเอ็ด 45000",
    name_en: "Si Somdet, Si Somdet, Roi Et, 45000",
  },
  {
    id: 451603,
    districtTh: "ศรีสมเด็จ",
    districtEn: "Si Somdet",
    subdistrictTh: "เมืองเปลือย",
    subdistrictEn: "Mueang Plueai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4516,
    province_code: 33,
    name: "ศรีสมเด็จ เมืองเปลือย ร้อยเอ็ด 45000",
    name_th: "ศรีสมเด็จ เมืองเปลือย ร้อยเอ็ด 45000",
    name_en: "Si Somdet, Mueang Plueai, Roi Et, 45000",
  },
  {
    id: 451604,
    districtTh: "ศรีสมเด็จ",
    districtEn: "Si Somdet",
    subdistrictTh: "หนองใหญ่",
    subdistrictEn: "Nong Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4516,
    province_code: 33,
    name: "ศรีสมเด็จ หนองใหญ่ ร้อยเอ็ด 45000",
    name_th: "ศรีสมเด็จ หนองใหญ่ ร้อยเอ็ด 45000",
    name_en: "Si Somdet, Nong Yai, Roi Et, 45000",
  },
  {
    id: 451605,
    districtTh: "ศรีสมเด็จ",
    districtEn: "Si Somdet",
    subdistrictTh: "สวนจิก",
    subdistrictEn: "Suan Chik",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45280,
    district_code: 4516,
    province_code: 33,
    name: "ศรีสมเด็จ สวนจิก ร้อยเอ็ด 45280",
    name_th: "ศรีสมเด็จ สวนจิก ร้อยเอ็ด 45280",
    name_en: "Si Somdet, Suan Chik, Roi Et, 45280",
  },
  {
    id: 451606,
    districtTh: "ศรีสมเด็จ",
    districtEn: "Si Somdet",
    subdistrictTh: "โพธิ์สัย",
    subdistrictEn: "Pho Sai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45280,
    district_code: 4516,
    province_code: 33,
    name: "ศรีสมเด็จ โพธิ์สัย ร้อยเอ็ด 45280",
    name_th: "ศรีสมเด็จ โพธิ์สัย ร้อยเอ็ด 45280",
    name_en: "Si Somdet, Pho Sai, Roi Et, 45280",
  },
  {
    id: 451607,
    districtTh: "ศรีสมเด็จ",
    districtEn: "Si Somdet",
    subdistrictTh: "หนองแวงควง",
    subdistrictEn: "Nong Waeng Khuang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4516,
    province_code: 33,
    name: "ศรีสมเด็จ หนองแวงควง ร้อยเอ็ด 45000",
    name_th: "ศรีสมเด็จ หนองแวงควง ร้อยเอ็ด 45000",
    name_en: "Si Somdet, Nong Waeng Khuang, Roi Et, 45000",
  },
  {
    id: 451608,
    districtTh: "ศรีสมเด็จ",
    districtEn: "Si Somdet",
    subdistrictTh: "บ้านบาก",
    subdistrictEn: "Ban Bak",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4516,
    province_code: 33,
    name: "ศรีสมเด็จ บ้านบาก ร้อยเอ็ด 45000",
    name_th: "ศรีสมเด็จ บ้านบาก ร้อยเอ็ด 45000",
    name_en: "Si Somdet, Ban Bak, Roi Et, 45000",
  },
  {
    id: 451701,
    districtTh: "จังหาร",
    districtEn: "Changhan",
    subdistrictTh: "ดินดำ",
    subdistrictEn: "Din Dam",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4517,
    province_code: 33,
    name: "จังหาร ดินดำ ร้อยเอ็ด 45000",
    name_th: "จังหาร ดินดำ ร้อยเอ็ด 45000",
    name_en: "Changhan, Din Dam, Roi Et, 45000",
  },
  {
    id: 451702,
    districtTh: "จังหาร",
    districtEn: "Changhan",
    subdistrictTh: "ปาฝา",
    subdistrictEn: "Pa Fa",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4517,
    province_code: 33,
    name: "จังหาร ปาฝา ร้อยเอ็ด 45000",
    name_th: "จังหาร ปาฝา ร้อยเอ็ด 45000",
    name_en: "Changhan, Pa Fa, Roi Et, 45000",
  },
  {
    id: 451703,
    districtTh: "จังหาร",
    districtEn: "Changhan",
    subdistrictTh: "ม่วงลาด",
    subdistrictEn: "Muang Lat",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4517,
    province_code: 33,
    name: "จังหาร ม่วงลาด ร้อยเอ็ด 45000",
    name_th: "จังหาร ม่วงลาด ร้อยเอ็ด 45000",
    name_en: "Changhan, Muang Lat, Roi Et, 45000",
  },
  {
    id: 451704,
    districtTh: "จังหาร",
    districtEn: "Changhan",
    subdistrictTh: "จังหาร",
    subdistrictEn: "Changhan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4517,
    province_code: 33,
    name: "จังหาร จังหาร ร้อยเอ็ด 45000",
    name_th: "จังหาร จังหาร ร้อยเอ็ด 45000",
    name_en: "Changhan, Changhan, Roi Et, 45000",
  },
  {
    id: 451705,
    districtTh: "จังหาร",
    districtEn: "Changhan",
    subdistrictTh: "ดงสิงห์",
    subdistrictEn: "Dong Sing",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4517,
    province_code: 33,
    name: "จังหาร ดงสิงห์ ร้อยเอ็ด 45000",
    name_th: "จังหาร ดงสิงห์ ร้อยเอ็ด 45000",
    name_en: "Changhan, Dong Sing, Roi Et, 45000",
  },
  {
    id: 451706,
    districtTh: "จังหาร",
    districtEn: "Changhan",
    subdistrictTh: "ยางใหญ่",
    subdistrictEn: "Yang Yai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4517,
    province_code: 33,
    name: "จังหาร ยางใหญ่ ร้อยเอ็ด 45000",
    name_th: "จังหาร ยางใหญ่ ร้อยเอ็ด 45000",
    name_en: "Changhan, Yang Yai, Roi Et, 45000",
  },
  {
    id: 451707,
    districtTh: "จังหาร",
    districtEn: "Changhan",
    subdistrictTh: "ผักแว่น",
    subdistrictEn: "Phak Waen",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4517,
    province_code: 33,
    name: "จังหาร ผักแว่น ร้อยเอ็ด 45000",
    name_th: "จังหาร ผักแว่น ร้อยเอ็ด 45000",
    name_en: "Changhan, Phak Waen, Roi Et, 45000",
  },
  {
    id: 451708,
    districtTh: "จังหาร",
    districtEn: "Changhan",
    subdistrictTh: "แสนชาติ",
    subdistrictEn: "Saen Chat",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4517,
    province_code: 33,
    name: "จังหาร แสนชาติ ร้อยเอ็ด 45000",
    name_th: "จังหาร แสนชาติ ร้อยเอ็ด 45000",
    name_en: "Changhan, Saen Chat, Roi Et, 45000",
  },
  {
    id: 451801,
    districtTh: "เชียงขวัญ",
    districtEn: "Chiang Khwan",
    subdistrictTh: "เชียงขวัญ",
    subdistrictEn: "Chiang Khwan",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4518,
    province_code: 33,
    name: "เชียงขวัญ เชียงขวัญ ร้อยเอ็ด 45000",
    name_th: "เชียงขวัญ เชียงขวัญ ร้อยเอ็ด 45000",
    name_en: "Chiang Khwan, Chiang Khwan, Roi Et, 45000",
  },
  {
    id: 451802,
    districtTh: "เชียงขวัญ",
    districtEn: "Chiang Khwan",
    subdistrictTh: "พลับพลา",
    subdistrictEn: "Phlapphla",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4518,
    province_code: 33,
    name: "เชียงขวัญ พลับพลา ร้อยเอ็ด 45170",
    name_th: "เชียงขวัญ พลับพลา ร้อยเอ็ด 45170",
    name_en: "Chiang Khwan, Phlapphla, Roi Et, 45170",
  },
  {
    id: 451803,
    districtTh: "เชียงขวัญ",
    districtEn: "Chiang Khwan",
    subdistrictTh: "พระธาตุ",
    subdistrictEn: "Phra That",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4518,
    province_code: 33,
    name: "เชียงขวัญ พระธาตุ ร้อยเอ็ด 45000",
    name_th: "เชียงขวัญ พระธาตุ ร้อยเอ็ด 45000",
    name_en: "Chiang Khwan, Phra That, Roi Et, 45000",
  },
  {
    id: 451804,
    districtTh: "เชียงขวัญ",
    districtEn: "Chiang Khwan",
    subdistrictTh: "พระเจ้า",
    subdistrictEn: "Phra Chao",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4518,
    province_code: 33,
    name: "เชียงขวัญ พระเจ้า ร้อยเอ็ด 45000",
    name_th: "เชียงขวัญ พระเจ้า ร้อยเอ็ด 45000",
    name_en: "Chiang Khwan, Phra Chao, Roi Et, 45000",
  },
  {
    id: 451805,
    districtTh: "เชียงขวัญ",
    districtEn: "Chiang Khwan",
    subdistrictTh: "หมูม้น",
    subdistrictEn: "Mu Mon",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4518,
    province_code: 33,
    name: "เชียงขวัญ หมูม้น ร้อยเอ็ด 45170",
    name_th: "เชียงขวัญ หมูม้น ร้อยเอ็ด 45170",
    name_en: "Chiang Khwan, Mu Mon, Roi Et, 45170",
  },
  {
    id: 451806,
    districtTh: "เชียงขวัญ",
    districtEn: "Chiang Khwan",
    subdistrictTh: "บ้านเขือง",
    subdistrictEn: "Ban Khueang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45000,
    district_code: 4518,
    province_code: 33,
    name: "เชียงขวัญ บ้านเขือง ร้อยเอ็ด 45000",
    name_th: "เชียงขวัญ บ้านเขือง ร้อยเอ็ด 45000",
    name_en: "Chiang Khwan, Ban Khueang, Roi Et, 45000",
  },
  {
    id: 451901,
    districtTh: "หนองฮี",
    districtEn: "Nong Hi",
    subdistrictTh: "หนองฮี",
    subdistrictEn: "Nong Hi",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4519,
    province_code: 33,
    name: "หนองฮี หนองฮี ร้อยเอ็ด 45140",
    name_th: "หนองฮี หนองฮี ร้อยเอ็ด 45140",
    name_en: "Nong Hi, Nong Hi, Roi Et, 45140",
  },
  {
    id: 451902,
    districtTh: "หนองฮี",
    districtEn: "Nong Hi",
    subdistrictTh: "สาวแห",
    subdistrictEn: "Sao Hae",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4519,
    province_code: 33,
    name: "หนองฮี สาวแห ร้อยเอ็ด 45140",
    name_th: "หนองฮี สาวแห ร้อยเอ็ด 45140",
    name_en: "Nong Hi, Sao Hae, Roi Et, 45140",
  },
  {
    id: 451903,
    districtTh: "หนองฮี",
    districtEn: "Nong Hi",
    subdistrictTh: "ดูกอึ่ง",
    subdistrictEn: "Duk Ueng",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4519,
    province_code: 33,
    name: "หนองฮี ดูกอึ่ง ร้อยเอ็ด 45140",
    name_th: "หนองฮี ดูกอึ่ง ร้อยเอ็ด 45140",
    name_en: "Nong Hi, Duk Ueng, Roi Et, 45140",
  },
  {
    id: 451904,
    districtTh: "หนองฮี",
    districtEn: "Nong Hi",
    subdistrictTh: "เด่นราษฎร์",
    subdistrictEn: "Den Rat",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45140,
    district_code: 4519,
    province_code: 33,
    name: "หนองฮี เด่นราษฎร์ ร้อยเอ็ด 45140",
    name_th: "หนองฮี เด่นราษฎร์ ร้อยเอ็ด 45140",
    name_en: "Nong Hi, Den Rat, Roi Et, 45140",
  },
  {
    id: 452001,
    districtTh: "ทุ่งเขาหลวง",
    districtEn: "Thung Khao Luangกิ่",
    subdistrictTh: "ทุ่งเขาหลวง",
    subdistrictEn: "Thung Khao Luang",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4520,
    province_code: 33,
    name: "ทุ่งเขาหลวง ทุ่งเขาหลวง ร้อยเอ็ด 45170",
    name_th: "ทุ่งเขาหลวง ทุ่งเขาหลวง ร้อยเอ็ด 45170",
    name_en: "Thung Khao Luangกิ่, Thung Khao Luang, Roi Et, 45170",
  },
  {
    id: 452002,
    districtTh: "ทุ่งเขาหลวง",
    districtEn: "Thung Khao Luangกิ่",
    subdistrictTh: "เทอดไทย",
    subdistrictEn: "Thoet Thai",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4520,
    province_code: 33,
    name: "ทุ่งเขาหลวง เทอดไทย ร้อยเอ็ด 45170",
    name_th: "ทุ่งเขาหลวง เทอดไทย ร้อยเอ็ด 45170",
    name_en: "Thung Khao Luangกิ่, Thoet Thai, Roi Et, 45170",
  },
  {
    id: 452003,
    districtTh: "ทุ่งเขาหลวง",
    districtEn: "Thung Khao Luangกิ่",
    subdistrictTh: "บึงงาม",
    subdistrictEn: "Bueng Ngam",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4520,
    province_code: 33,
    name: "ทุ่งเขาหลวง บึงงาม ร้อยเอ็ด 45170",
    name_th: "ทุ่งเขาหลวง บึงงาม ร้อยเอ็ด 45170",
    name_en: "Thung Khao Luangกิ่, Bueng Ngam, Roi Et, 45170",
  },
  {
    id: 452004,
    districtTh: "ทุ่งเขาหลวง",
    districtEn: "Thung Khao Luangกิ่",
    subdistrictTh: "มะบ้า",
    subdistrictEn: "Maba",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4520,
    province_code: 33,
    name: "ทุ่งเขาหลวง มะบ้า ร้อยเอ็ด 45170",
    name_th: "ทุ่งเขาหลวง มะบ้า ร้อยเอ็ด 45170",
    name_en: "Thung Khao Luangกิ่, Maba, Roi Et, 45170",
  },
  {
    id: 452005,
    districtTh: "ทุ่งเขาหลวง",
    districtEn: "Thung Khao Luangกิ่",
    subdistrictTh: "เหล่า",
    subdistrictEn: "Lao",
    provinceTh: "ร้อยเอ็ด",
    provinceEn: "Roi Et",
    zipcode: 45170,
    district_code: 4520,
    province_code: 33,
    name: "ทุ่งเขาหลวง เหล่า ร้อยเอ็ด 45170",
    name_th: "ทุ่งเขาหลวง เหล่า ร้อยเอ็ด 45170",
    name_en: "Thung Khao Luangกิ่, Lao, Roi Et, 45170",
  },
  {
    id: 460101,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "กาฬสินธุ์",
    subdistrictEn: "Kalasin",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ กาฬสินธุ์ กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ กาฬสินธุ์ กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Kalasin, Kalasin, 46000",
  },
  {
    id: 460102,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "เหนือ",
    subdistrictEn: "Nuea",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ เหนือ กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ เหนือ กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Nuea, Kalasin, 46000",
  },
  {
    id: 460103,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "หลุบ",
    subdistrictEn: "Lup",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ หลุบ กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ หลุบ กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Lup, Kalasin, 46000",
  },
  {
    id: 460104,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "ไผ่",
    subdistrictEn: "Phai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ ไผ่ กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ ไผ่ กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Phai, Kalasin, 46000",
  },
  {
    id: 460105,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "ลำปาว",
    subdistrictEn: "Lam Pao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ ลำปาว กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ ลำปาว กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Lam Pao, Kalasin, 46000",
  },
  {
    id: 460106,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "ลำพาน",
    subdistrictEn: "Lam Phan",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ ลำพาน กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ ลำพาน กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Lam Phan, Kalasin, 46000",
  },
  {
    id: 460107,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "เชียงเครือ",
    subdistrictEn: "Chiang Khruea",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ เชียงเครือ กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ เชียงเครือ กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Chiang Khruea, Kalasin, 46000",
  },
  {
    id: 460108,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "บึงวิชัย",
    subdistrictEn: "Bueng Wichai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ บึงวิชัย กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ บึงวิชัย กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Bueng Wichai, Kalasin, 46000",
  },
  {
    id: 460109,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "ห้วยโพธิ์",
    subdistrictEn: "Huai Pho",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ ห้วยโพธิ์ กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ ห้วยโพธิ์ กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Huai Pho, Kalasin, 46000",
  },
  {
    id: 460111,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "ภูปอ",
    subdistrictEn: "Phu Po",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ ภูปอ กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ ภูปอ กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Phu Po, Kalasin, 46000",
  },
  {
    id: 460113,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "ภูดิน",
    subdistrictEn: "Phu Din",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ ภูดิน กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ ภูดิน กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Phu Din, Kalasin, 46000",
  },
  {
    id: 460115,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "หนองกุง",
    subdistrictEn: "Nong Kung",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ หนองกุง กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ หนองกุง กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Nong Kung, Kalasin, 46000",
  },
  {
    id: 460116,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "กลางหมื่น",
    subdistrictEn: "Klang Muen",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ กลางหมื่น กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ กลางหมื่น กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Klang Muen, Kalasin, 46000",
  },
  {
    id: 460117,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "ขมิ้น",
    subdistrictEn: "Khamin",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ ขมิ้น กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ ขมิ้น กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Khamin, Kalasin, 46000",
  },
  {
    id: 460119,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ โพนทอง กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ โพนทอง กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Phon Thong, Kalasin, 46000",
  },
  {
    id: 460120,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "นาจารย์",
    subdistrictEn: "Na Chan",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ นาจารย์ กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ นาจารย์ กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Na Chan, Kalasin, 46000",
  },
  {
    id: 460121,
    districtTh: "เมืองกาฬสินธุ์",
    districtEn: "Mueang Kalasin",
    subdistrictTh: "ลำคลอง",
    subdistrictEn: "Lam Khlong",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4601,
    province_code: 34,
    name: "เมืองกาฬสินธุ์ ลำคลอง กาฬสินธุ์ 46000",
    name_th: "เมืองกาฬสินธุ์ ลำคลอง กาฬสินธุ์ 46000",
    name_en: "Mueang Kalasin, Lam Khlong, Kalasin, 46000",
  },
  {
    id: 460201,
    districtTh: "นามน",
    districtEn: "Na Mon",
    subdistrictTh: "นามน",
    subdistrictEn: "Na Mon",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46230,
    district_code: 4602,
    province_code: 34,
    name: "นามน นามน กาฬสินธุ์ 46230",
    name_th: "นามน นามน กาฬสินธุ์ 46230",
    name_en: "Na Mon, Na Mon, Kalasin, 46230",
  },
  {
    id: 460202,
    districtTh: "นามน",
    districtEn: "Na Mon",
    subdistrictTh: "ยอดแกง",
    subdistrictEn: "Yot Kaeng",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46230,
    district_code: 4602,
    province_code: 34,
    name: "นามน ยอดแกง กาฬสินธุ์ 46230",
    name_th: "นามน ยอดแกง กาฬสินธุ์ 46230",
    name_en: "Na Mon, Yot Kaeng, Kalasin, 46230",
  },
  {
    id: 460203,
    districtTh: "นามน",
    districtEn: "Na Mon",
    subdistrictTh: "สงเปลือย",
    subdistrictEn: "Song Plueai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46230,
    district_code: 4602,
    province_code: 34,
    name: "นามน สงเปลือย กาฬสินธุ์ 46230",
    name_th: "นามน สงเปลือย กาฬสินธุ์ 46230",
    name_en: "Na Mon, Song Plueai, Kalasin, 46230",
  },
  {
    id: 460204,
    districtTh: "นามน",
    districtEn: "Na Mon",
    subdistrictTh: "หลักเหลี่ยม",
    subdistrictEn: "Lak Liam",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46230,
    district_code: 4602,
    province_code: 34,
    name: "นามน หลักเหลี่ยม กาฬสินธุ์ 46230",
    name_th: "นามน หลักเหลี่ยม กาฬสินธุ์ 46230",
    name_en: "Na Mon, Lak Liam, Kalasin, 46230",
  },
  {
    id: 460205,
    districtTh: "นามน",
    districtEn: "Na Mon",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46230,
    district_code: 4602,
    province_code: 34,
    name: "นามน หนองบัว กาฬสินธุ์ 46230",
    name_th: "นามน หนองบัว กาฬสินธุ์ 46230",
    name_en: "Na Mon, Nong Bua, Kalasin, 46230",
  },
  {
    id: 460301,
    districtTh: "กมลาไสย",
    districtEn: "Kamalasai",
    subdistrictTh: "กมลาไสย",
    subdistrictEn: "Kamalasai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4603,
    province_code: 34,
    name: "กมลาไสย กมลาไสย กาฬสินธุ์ 46130",
    name_th: "กมลาไสย กมลาไสย กาฬสินธุ์ 46130",
    name_en: "Kamalasai, Kamalasai, Kalasin, 46130",
  },
  {
    id: 460302,
    districtTh: "กมลาไสย",
    districtEn: "Kamalasai",
    subdistrictTh: "หลักเมือง",
    subdistrictEn: "Lak Mueang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4603,
    province_code: 34,
    name: "กมลาไสย หลักเมือง กาฬสินธุ์ 46130",
    name_th: "กมลาไสย หลักเมือง กาฬสินธุ์ 46130",
    name_en: "Kamalasai, Lak Mueang, Kalasin, 46130",
  },
  {
    id: 460303,
    districtTh: "กมลาไสย",
    districtEn: "Kamalasai",
    subdistrictTh: "โพนงาม",
    subdistrictEn: "Phon Ngam",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4603,
    province_code: 34,
    name: "กมลาไสย โพนงาม กาฬสินธุ์ 46130",
    name_th: "กมลาไสย โพนงาม กาฬสินธุ์ 46130",
    name_en: "Kamalasai, Phon Ngam, Kalasin, 46130",
  },
  {
    id: 460304,
    districtTh: "กมลาไสย",
    districtEn: "Kamalasai",
    subdistrictTh: "ดงลิง",
    subdistrictEn: "Dong Ling",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4603,
    province_code: 34,
    name: "กมลาไสย ดงลิง กาฬสินธุ์ 46130",
    name_th: "กมลาไสย ดงลิง กาฬสินธุ์ 46130",
    name_en: "Kamalasai, Dong Ling, Kalasin, 46130",
  },
  {
    id: 460305,
    districtTh: "กมลาไสย",
    districtEn: "Kamalasai",
    subdistrictTh: "ธัญญา",
    subdistrictEn: "Thanya",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4603,
    province_code: 34,
    name: "กมลาไสย ธัญญา กาฬสินธุ์ 46130",
    name_th: "กมลาไสย ธัญญา กาฬสินธุ์ 46130",
    name_en: "Kamalasai, Thanya, Kalasin, 46130",
  },
  {
    id: 460308,
    districtTh: "กมลาไสย",
    districtEn: "Kamalasai",
    subdistrictTh: "หนองแปน",
    subdistrictEn: "Nong Paen",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4603,
    province_code: 34,
    name: "กมลาไสย หนองแปน กาฬสินธุ์ 46130",
    name_th: "กมลาไสย หนองแปน กาฬสินธุ์ 46130",
    name_en: "Kamalasai, Nong Paen, Kalasin, 46130",
  },
  {
    id: 460310,
    districtTh: "กมลาไสย",
    districtEn: "Kamalasai",
    subdistrictTh: "เจ้าท่า",
    subdistrictEn: "Chao Tha",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4603,
    province_code: 34,
    name: "กมลาไสย เจ้าท่า กาฬสินธุ์ 46130",
    name_th: "กมลาไสย เจ้าท่า กาฬสินธุ์ 46130",
    name_en: "Kamalasai, Chao Tha, Kalasin, 46130",
  },
  {
    id: 460311,
    districtTh: "กมลาไสย",
    districtEn: "Kamalasai",
    subdistrictTh: "โคกสมบูรณ์",
    subdistrictEn: "Khok Sombun",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4603,
    province_code: 34,
    name: "กมลาไสย โคกสมบูรณ์ กาฬสินธุ์ 46130",
    name_th: "กมลาไสย โคกสมบูรณ์ กาฬสินธุ์ 46130",
    name_en: "Kamalasai, Khok Sombun, Kalasin, 46130",
  },
  {
    id: 460401,
    districtTh: "ร่องคำ",
    districtEn: "Rong Kham",
    subdistrictTh: "ร่องคำ",
    subdistrictEn: "Rong Kham",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46210,
    district_code: 4604,
    province_code: 34,
    name: "ร่องคำ ร่องคำ กาฬสินธุ์ 46210",
    name_th: "ร่องคำ ร่องคำ กาฬสินธุ์ 46210",
    name_en: "Rong Kham, Rong Kham, Kalasin, 46210",
  },
  {
    id: 460402,
    districtTh: "ร่องคำ",
    districtEn: "Rong Kham",
    subdistrictTh: "สามัคคี",
    subdistrictEn: "Samakkhi",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46210,
    district_code: 4604,
    province_code: 34,
    name: "ร่องคำ สามัคคี กาฬสินธุ์ 46210",
    name_th: "ร่องคำ สามัคคี กาฬสินธุ์ 46210",
    name_en: "Rong Kham, Samakkhi, Kalasin, 46210",
  },
  {
    id: 460403,
    districtTh: "ร่องคำ",
    districtEn: "Rong Kham",
    subdistrictTh: "เหล่าอ้อย",
    subdistrictEn: "Lao Oi",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46210,
    district_code: 4604,
    province_code: 34,
    name: "ร่องคำ เหล่าอ้อย กาฬสินธุ์ 46210",
    name_th: "ร่องคำ เหล่าอ้อย กาฬสินธุ์ 46210",
    name_en: "Rong Kham, Lao Oi, Kalasin, 46210",
  },
  {
    id: 460501,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "บัวขาว",
    subdistrictEn: "Bua Khao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ บัวขาว กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ บัวขาว กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Bua Khao, Kalasin, 46110",
  },
  {
    id: 460502,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "แจนแลน",
    subdistrictEn: "Chaen Laen",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ แจนแลน กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ แจนแลน กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Chaen Laen, Kalasin, 46110",
  },
  {
    id: 460503,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "เหล่าใหญ่",
    subdistrictEn: "Lao Yai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ เหล่าใหญ่ กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ เหล่าใหญ่ กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Lao Yai, Kalasin, 46110",
  },
  {
    id: 460504,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "จุมจัง",
    subdistrictEn: "Chum Chang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ จุมจัง กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ จุมจัง กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Chum Chang, Kalasin, 46110",
  },
  {
    id: 460505,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "เหล่าไฮงาม",
    subdistrictEn: "Lao Hai Ngam",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ เหล่าไฮงาม กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ เหล่าไฮงาม กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Lao Hai Ngam, Kalasin, 46110",
  },
  {
    id: 460506,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "กุดหว้า",
    subdistrictEn: "Kut Wa",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ กุดหว้า กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ กุดหว้า กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Kut Wa, Kalasin, 46110",
  },
  {
    id: 460507,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "สามขา",
    subdistrictEn: "Sam Kha",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ สามขา กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ สามขา กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Sam Kha, Kalasin, 46110",
  },
  {
    id: 460508,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "นาขาม",
    subdistrictEn: "Na Kham",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ นาขาม กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ นาขาม กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Na Kham, Kalasin, 46110",
  },
  {
    id: 460509,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "หนองห้าง",
    subdistrictEn: "Nong Hang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ หนองห้าง กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ หนองห้าง กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Nong Hang, Kalasin, 46110",
  },
  {
    id: 460510,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "นาโก",
    subdistrictEn: "Na Ko",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ นาโก กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ นาโก กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Na Ko, Kalasin, 46110",
  },
  {
    id: 460511,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "สมสะอาด",
    subdistrictEn: "Som Sa-at",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ สมสะอาด กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ สมสะอาด กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Som Sa-at, Kalasin, 46110",
  },
  {
    id: 460512,
    districtTh: "กุฉินารายณ์",
    districtEn: "Kuchinarai",
    subdistrictTh: "กุดค้าว",
    subdistrictEn: "Kut Khao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46110,
    district_code: 4605,
    province_code: 34,
    name: "กุฉินารายณ์ กุดค้าว กาฬสินธุ์ 46110",
    name_th: "กุฉินารายณ์ กุดค้าว กาฬสินธุ์ 46110",
    name_en: "Kuchinarai, Kut Khao, Kalasin, 46110",
  },
  {
    id: 460601,
    districtTh: "เขาวง",
    districtEn: "Khao Wong",
    subdistrictTh: "คุ้มเก่า",
    subdistrictEn: "Khum Kao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4606,
    province_code: 34,
    name: "เขาวง คุ้มเก่า กาฬสินธุ์ 46160",
    name_th: "เขาวง คุ้มเก่า กาฬสินธุ์ 46160",
    name_en: "Khao Wong, Khum Kao, Kalasin, 46160",
  },
  {
    id: 460602,
    districtTh: "เขาวง",
    districtEn: "Khao Wong",
    subdistrictTh: "สงเปลือย",
    subdistrictEn: "Song Plueai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4606,
    province_code: 34,
    name: "เขาวง สงเปลือย กาฬสินธุ์ 46160",
    name_th: "เขาวง สงเปลือย กาฬสินธุ์ 46160",
    name_en: "Khao Wong, Song Plueai, Kalasin, 46160",
  },
  {
    id: 460603,
    districtTh: "เขาวง",
    districtEn: "Khao Wong",
    subdistrictTh: "หนองผือ",
    subdistrictEn: "Nong Phue",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4606,
    province_code: 34,
    name: "เขาวง หนองผือ กาฬสินธุ์ 46160",
    name_th: "เขาวง หนองผือ กาฬสินธุ์ 46160",
    name_en: "Khao Wong, Nong Phue, Kalasin, 46160",
  },
  {
    id: 460606,
    districtTh: "เขาวง",
    districtEn: "Khao Wong",
    subdistrictTh: "กุดสิมคุ้มใหม่",
    subdistrictEn: "Kut Sim Khum Mai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4606,
    province_code: 34,
    name: "เขาวง กุดสิมคุ้มใหม่ กาฬสินธุ์ 46160",
    name_th: "เขาวง กุดสิมคุ้มใหม่ กาฬสินธุ์ 46160",
    name_en: "Khao Wong, Kut Sim Khum Mai, Kalasin, 46160",
  },
  {
    id: 460608,
    districtTh: "เขาวง",
    districtEn: "Khao Wong",
    subdistrictTh: "สระพังทอง",
    subdistrictEn: "Saphang Thong",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4606,
    province_code: 34,
    name: "เขาวง สระพังทอง กาฬสินธุ์ 46160",
    name_th: "เขาวง สระพังทอง กาฬสินธุ์ 46160",
    name_en: "Khao Wong, Saphang Thong, Kalasin, 46160",
  },
  {
    id: 460611,
    districtTh: "เขาวง",
    districtEn: "Khao Wong",
    subdistrictTh: "กุดปลาค้าว",
    subdistrictEn: "Kut Pla Khao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4606,
    province_code: 34,
    name: "เขาวง กุดปลาค้าว กาฬสินธุ์ 46160",
    name_th: "เขาวง กุดปลาค้าว กาฬสินธุ์ 46160",
    name_en: "Khao Wong, Kut Pla Khao, Kalasin, 46160",
  },
  {
    id: 460701,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "ยางตลาด",
    subdistrictEn: "Yang Talat",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด ยางตลาด กาฬสินธุ์ 46120",
    name_th: "ยางตลาด ยางตลาด กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Yang Talat, Kalasin, 46120",
  },
  {
    id: 460702,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "หัวงัว",
    subdistrictEn: "Hua Ngua",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด หัวงัว กาฬสินธุ์ 46120",
    name_th: "ยางตลาด หัวงัว กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Hua Ngua, Kalasin, 46120",
  },
  {
    id: 460703,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "อุ่มเม่า",
    subdistrictEn: "Um Mao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด อุ่มเม่า กาฬสินธุ์ 46120",
    name_th: "ยางตลาด อุ่มเม่า กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Um Mao, Kalasin, 46120",
  },
  {
    id: 460704,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "บัวบาน",
    subdistrictEn: "Bua Ban",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด บัวบาน กาฬสินธุ์ 46120",
    name_th: "ยางตลาด บัวบาน กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Bua Ban, Kalasin, 46120",
  },
  {
    id: 460705,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "เว่อ",
    subdistrictEn: "Woe",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด เว่อ กาฬสินธุ์ 46120",
    name_th: "ยางตลาด เว่อ กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Woe, Kalasin, 46120",
  },
  {
    id: 460706,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "อิตื้อ",
    subdistrictEn: "Itue",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด อิตื้อ กาฬสินธุ์ 46120",
    name_th: "ยางตลาด อิตื้อ กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Itue, Kalasin, 46120",
  },
  {
    id: 460707,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "หัวนาคำ",
    subdistrictEn: "Hua Na Kham",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด หัวนาคำ กาฬสินธุ์ 46120",
    name_th: "ยางตลาด หัวนาคำ กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Hua Na Kham, Kalasin, 46120",
  },
  {
    id: 460708,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "หนองอิเฒ่า",
    subdistrictEn: "Nong I Thao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด หนองอิเฒ่า กาฬสินธุ์ 46120",
    name_th: "ยางตลาด หนองอิเฒ่า กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Nong I Thao, Kalasin, 46120",
  },
  {
    id: 460709,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "ดอนสมบูรณ์",
    subdistrictEn: "Don Sombun",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด ดอนสมบูรณ์ กาฬสินธุ์ 46120",
    name_th: "ยางตลาด ดอนสมบูรณ์ กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Don Sombun, Kalasin, 46120",
  },
  {
    id: 460710,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "นาเชือก",
    subdistrictEn: "Na Chueak",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด นาเชือก กาฬสินธุ์ 46120",
    name_th: "ยางตลาด นาเชือก กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Na Chueak, Kalasin, 46120",
  },
  {
    id: 460711,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "คลองขาม",
    subdistrictEn: "Khlong Kham",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด คลองขาม กาฬสินธุ์ 46120",
    name_th: "ยางตลาด คลองขาม กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Khlong Kham, Kalasin, 46120",
  },
  {
    id: 460712,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "เขาพระนอน",
    subdistrictEn: "Khao Phra Non",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด เขาพระนอน กาฬสินธุ์ 46120",
    name_th: "ยางตลาด เขาพระนอน กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Khao Phra Non, Kalasin, 46120",
  },
  {
    id: 460713,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด นาดี กาฬสินธุ์ 46120",
    name_th: "ยางตลาด นาดี กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Na Di, Kalasin, 46120",
  },
  {
    id: 460714,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "โนนสูง",
    subdistrictEn: "Non Sung",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด โนนสูง กาฬสินธุ์ 46120",
    name_th: "ยางตลาด โนนสูง กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Non Sung, Kalasin, 46120",
  },
  {
    id: 460715,
    districtTh: "ยางตลาด",
    districtEn: "Yang Talat",
    subdistrictTh: "หนองตอกแป้น",
    subdistrictEn: "Nong Tok Paen",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46120,
    district_code: 4607,
    province_code: 34,
    name: "ยางตลาด หนองตอกแป้น กาฬสินธุ์ 46120",
    name_th: "ยางตลาด หนองตอกแป้น กาฬสินธุ์ 46120",
    name_en: "Yang Talat, Nong Tok Paen, Kalasin, 46120",
  },
  {
    id: 460801,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "ห้วยเม็ก",
    subdistrictEn: "Huai Mek",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก ห้วยเม็ก กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก ห้วยเม็ก กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Huai Mek, Kalasin, 46170",
  },
  {
    id: 460802,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "คำใหญ่",
    subdistrictEn: "Kham Yai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก คำใหญ่ กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก คำใหญ่ กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Kham Yai, Kalasin, 46170",
  },
  {
    id: 460803,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "กุดโดน",
    subdistrictEn: "Kut Don",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก กุดโดน กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก กุดโดน กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Kut Don, Kalasin, 46170",
  },
  {
    id: 460804,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "บึงนาเรียง",
    subdistrictEn: "Bueng Na Riang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก บึงนาเรียง กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก บึงนาเรียง กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Bueng Na Riang, Kalasin, 46170",
  },
  {
    id: 460805,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "หัวหิน",
    subdistrictEn: "Hua Hin",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก หัวหิน กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก หัวหิน กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Hua Hin, Kalasin, 46170",
  },
  {
    id: 460806,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "พิมูล",
    subdistrictEn: "Phimun",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก พิมูล กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก พิมูล กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Phimun, Kalasin, 46170",
  },
  {
    id: 460807,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "คำเหมือดแก้ว",
    subdistrictEn: "Kham Mueat Kaeo",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก คำเหมือดแก้ว กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก คำเหมือดแก้ว กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Kham Mueat Kaeo, Kalasin, 46170",
  },
  {
    id: 460808,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "โนนสะอาด",
    subdistrictEn: "Non Sa-at",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก โนนสะอาด กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก โนนสะอาด กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Non Sa-at, Kalasin, 46170",
  },
  {
    id: 460809,
    districtTh: "ห้วยเม็ก",
    districtEn: "Huai Mek",
    subdistrictTh: "ทรายทอง",
    subdistrictEn: "Sai Thong",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46170,
    district_code: 4608,
    province_code: 34,
    name: "ห้วยเม็ก ทรายทอง กาฬสินธุ์ 46170",
    name_th: "ห้วยเม็ก ทรายทอง กาฬสินธุ์ 46170",
    name_en: "Huai Mek, Sai Thong, Kalasin, 46170",
  },
  {
    id: 460901,
    districtTh: "สหัสขันธ์",
    districtEn: "Sahatsakhan",
    subdistrictTh: "ภูสิงห์",
    subdistrictEn: "Phu Sing",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46140,
    district_code: 4609,
    province_code: 34,
    name: "สหัสขันธ์ ภูสิงห์ กาฬสินธุ์ 46140",
    name_th: "สหัสขันธ์ ภูสิงห์ กาฬสินธุ์ 46140",
    name_en: "Sahatsakhan, Phu Sing, Kalasin, 46140",
  },
  {
    id: 460902,
    districtTh: "สหัสขันธ์",
    districtEn: "Sahatsakhan",
    subdistrictTh: "สหัสขันธ์",
    subdistrictEn: "Sahatsakhan",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46140,
    district_code: 4609,
    province_code: 34,
    name: "สหัสขันธ์ สหัสขันธ์ กาฬสินธุ์ 46140",
    name_th: "สหัสขันธ์ สหัสขันธ์ กาฬสินธุ์ 46140",
    name_en: "Sahatsakhan, Sahatsakhan, Kalasin, 46140",
  },
  {
    id: 460903,
    districtTh: "สหัสขันธ์",
    districtEn: "Sahatsakhan",
    subdistrictTh: "นามะเขือ",
    subdistrictEn: "Na Makhuea",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46140,
    district_code: 4609,
    province_code: 34,
    name: "สหัสขันธ์ นามะเขือ กาฬสินธุ์ 46140",
    name_th: "สหัสขันธ์ นามะเขือ กาฬสินธุ์ 46140",
    name_en: "Sahatsakhan, Na Makhuea, Kalasin, 46140",
  },
  {
    id: 460904,
    districtTh: "สหัสขันธ์",
    districtEn: "Sahatsakhan",
    subdistrictTh: "โนนศิลา",
    subdistrictEn: "Non Sila",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46140,
    district_code: 4609,
    province_code: 34,
    name: "สหัสขันธ์ โนนศิลา กาฬสินธุ์ 46140",
    name_th: "สหัสขันธ์ โนนศิลา กาฬสินธุ์ 46140",
    name_en: "Sahatsakhan, Non Sila, Kalasin, 46140",
  },
  {
    id: 460905,
    districtTh: "สหัสขันธ์",
    districtEn: "Sahatsakhan",
    subdistrictTh: "นิคม",
    subdistrictEn: "Nikhom",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46140,
    district_code: 4609,
    province_code: 34,
    name: "สหัสขันธ์ นิคม กาฬสินธุ์ 46140",
    name_th: "สหัสขันธ์ นิคม กาฬสินธุ์ 46140",
    name_en: "Sahatsakhan, Nikhom, Kalasin, 46140",
  },
  {
    id: 460906,
    districtTh: "สหัสขันธ์",
    districtEn: "Sahatsakhan",
    subdistrictTh: "โนนแหลมทอง",
    subdistrictEn: "Non Laem Thong",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46140,
    district_code: 4609,
    province_code: 34,
    name: "สหัสขันธ์ โนนแหลมทอง กาฬสินธุ์ 46140",
    name_th: "สหัสขันธ์ โนนแหลมทอง กาฬสินธุ์ 46140",
    name_en: "Sahatsakhan, Non Laem Thong, Kalasin, 46140",
  },
  {
    id: 460907,
    districtTh: "สหัสขันธ์",
    districtEn: "Sahatsakhan",
    subdistrictTh: "โนนบุรี",
    subdistrictEn: "Non Buri",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46140,
    district_code: 4609,
    province_code: 34,
    name: "สหัสขันธ์ โนนบุรี กาฬสินธุ์ 46140",
    name_th: "สหัสขันธ์ โนนบุรี กาฬสินธุ์ 46140",
    name_en: "Sahatsakhan, Non Buri, Kalasin, 46140",
  },
  {
    id: 460908,
    districtTh: "สหัสขันธ์",
    districtEn: "Sahatsakhan",
    subdistrictTh: "โนนน้ำเกลี้ยง",
    subdistrictEn: "Non Nam Kliang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46140,
    district_code: 4609,
    province_code: 34,
    name: "สหัสขันธ์ โนนน้ำเกลี้ยง กาฬสินธุ์ 46140",
    name_th: "สหัสขันธ์ โนนน้ำเกลี้ยง กาฬสินธุ์ 46140",
    name_en: "Sahatsakhan, Non Nam Kliang, Kalasin, 46140",
  },
  {
    id: 461001,
    districtTh: "คำม่วง",
    districtEn: "Kham Muang",
    subdistrictTh: "ทุ่งคลอง",
    subdistrictEn: "Thung Khlong",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4610,
    province_code: 34,
    name: "คำม่วง ทุ่งคลอง กาฬสินธุ์ 46180",
    name_th: "คำม่วง ทุ่งคลอง กาฬสินธุ์ 46180",
    name_en: "Kham Muang, Thung Khlong, Kalasin, 46180",
  },
  {
    id: 461002,
    districtTh: "คำม่วง",
    districtEn: "Kham Muang",
    subdistrictTh: "โพน",
    subdistrictEn: "Phon",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4610,
    province_code: 34,
    name: "คำม่วง โพน กาฬสินธุ์ 46180",
    name_th: "คำม่วง โพน กาฬสินธุ์ 46180",
    name_en: "Kham Muang, Phon, Kalasin, 46180",
  },
  {
    id: 461005,
    districtTh: "คำม่วง",
    districtEn: "Kham Muang",
    subdistrictTh: "ดินจี่",
    subdistrictEn: "Din Chi",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4610,
    province_code: 34,
    name: "คำม่วง ดินจี่ กาฬสินธุ์ 46180",
    name_th: "คำม่วง ดินจี่ กาฬสินธุ์ 46180",
    name_en: "Kham Muang, Din Chi, Kalasin, 46180",
  },
  {
    id: 461006,
    districtTh: "คำม่วง",
    districtEn: "Kham Muang",
    subdistrictTh: "นาบอน",
    subdistrictEn: "Na Bon",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4610,
    province_code: 34,
    name: "คำม่วง นาบอน กาฬสินธุ์ 46180",
    name_th: "คำม่วง นาบอน กาฬสินธุ์ 46180",
    name_en: "Kham Muang, Na Bon, Kalasin, 46180",
  },
  {
    id: 461007,
    districtTh: "คำม่วง",
    districtEn: "Kham Muang",
    subdistrictTh: "นาทัน",
    subdistrictEn: "Na Than",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4610,
    province_code: 34,
    name: "คำม่วง นาทัน กาฬสินธุ์ 46180",
    name_th: "คำม่วง นาทัน กาฬสินธุ์ 46180",
    name_en: "Kham Muang, Na Than, Kalasin, 46180",
  },
  {
    id: 461009,
    districtTh: "คำม่วง",
    districtEn: "Kham Muang",
    subdistrictTh: "เนินยาง",
    subdistrictEn: "Noen Yang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4610,
    province_code: 34,
    name: "คำม่วง เนินยาง กาฬสินธุ์ 46180",
    name_th: "คำม่วง เนินยาง กาฬสินธุ์ 46180",
    name_en: "Kham Muang, Noen Yang, Kalasin, 46180",
  },
  {
    id: 461101,
    districtTh: "ท่าคันโท",
    districtEn: "Tha Khantho",
    subdistrictTh: "ท่าคันโท",
    subdistrictEn: "Tha Khantho",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46190,
    district_code: 4611,
    province_code: 34,
    name: "ท่าคันโท ท่าคันโท กาฬสินธุ์ 46190",
    name_th: "ท่าคันโท ท่าคันโท กาฬสินธุ์ 46190",
    name_en: "Tha Khantho, Tha Khantho, Kalasin, 46190",
  },
  {
    id: 461102,
    districtTh: "ท่าคันโท",
    districtEn: "Tha Khantho",
    subdistrictTh: "กุงเก่า",
    subdistrictEn: "Kung Kao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46190,
    district_code: 4611,
    province_code: 34,
    name: "ท่าคันโท กุงเก่า กาฬสินธุ์ 46190",
    name_th: "ท่าคันโท กุงเก่า กาฬสินธุ์ 46190",
    name_en: "Tha Khantho, Kung Kao, Kalasin, 46190",
  },
  {
    id: 461103,
    districtTh: "ท่าคันโท",
    districtEn: "Tha Khantho",
    subdistrictTh: "ยางอู้ม",
    subdistrictEn: "Yang Um",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46190,
    district_code: 4611,
    province_code: 34,
    name: "ท่าคันโท ยางอู้ม กาฬสินธุ์ 46190",
    name_th: "ท่าคันโท ยางอู้ม กาฬสินธุ์ 46190",
    name_en: "Tha Khantho, Yang Um, Kalasin, 46190",
  },
  {
    id: 461104,
    districtTh: "ท่าคันโท",
    districtEn: "Tha Khantho",
    subdistrictTh: "กุดจิก",
    subdistrictEn: "Kut Chik",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46190,
    district_code: 4611,
    province_code: 34,
    name: "ท่าคันโท กุดจิก กาฬสินธุ์ 46190",
    name_th: "ท่าคันโท กุดจิก กาฬสินธุ์ 46190",
    name_en: "Tha Khantho, Kut Chik, Kalasin, 46190",
  },
  {
    id: 461105,
    districtTh: "ท่าคันโท",
    districtEn: "Tha Khantho",
    subdistrictTh: "นาตาล",
    subdistrictEn: "Na Tan",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46190,
    district_code: 4611,
    province_code: 34,
    name: "ท่าคันโท นาตาล กาฬสินธุ์ 46190",
    name_th: "ท่าคันโท นาตาล กาฬสินธุ์ 46190",
    name_en: "Tha Khantho, Na Tan, Kalasin, 46190",
  },
  {
    id: 461106,
    districtTh: "ท่าคันโท",
    districtEn: "Tha Khantho",
    subdistrictTh: "ดงสมบูรณ์",
    subdistrictEn: "Dong Sombun",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46190,
    district_code: 4611,
    province_code: 34,
    name: "ท่าคันโท ดงสมบูรณ์ กาฬสินธุ์ 46190",
    name_th: "ท่าคันโท ดงสมบูรณ์ กาฬสินธุ์ 46190",
    name_en: "Tha Khantho, Dong Sombun, Kalasin, 46190",
  },
  {
    id: 461201,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "หนองกุงศรี",
    subdistrictEn: "Nong Kung Si",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี หนองกุงศรี กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี หนองกุงศรี กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Nong Kung Si, Kalasin, 46220",
  },
  {
    id: 461202,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี หนองบัว กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี หนองบัว กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Nong Bua, Kalasin, 46220",
  },
  {
    id: 461203,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "โคกเครือ",
    subdistrictEn: "Khok Khruea",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี โคกเครือ กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี โคกเครือ กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Khok Khruea, Kalasin, 46220",
  },
  {
    id: 461204,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "หนองสรวง",
    subdistrictEn: "Nong Suang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี หนองสรวง กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี หนองสรวง กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Nong Suang, Kalasin, 46220",
  },
  {
    id: 461205,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "เสาเล้า",
    subdistrictEn: "Sao Lao",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี เสาเล้า กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี เสาเล้า กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Sao Lao, Kalasin, 46220",
  },
  {
    id: 461206,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "หนองใหญ่",
    subdistrictEn: "Nong Yai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี หนองใหญ่ กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี หนองใหญ่ กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Nong Yai, Kalasin, 46220",
  },
  {
    id: 461207,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "ดงมูล",
    subdistrictEn: "Dong Mun",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี ดงมูล กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี ดงมูล กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Dong Mun, Kalasin, 46220",
  },
  {
    id: 461208,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "ลำหนองแสน",
    subdistrictEn: "Lam Nong Saen",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี ลำหนองแสน กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี ลำหนองแสน กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Lam Nong Saen, Kalasin, 46220",
  },
  {
    id: 461209,
    districtTh: "หนองกุงศรี",
    districtEn: "Nong Kung Si",
    subdistrictTh: "หนองหิน",
    subdistrictEn: "Nong Hin",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46220,
    district_code: 4612,
    province_code: 34,
    name: "หนองกุงศรี หนองหิน กาฬสินธุ์ 46220",
    name_th: "หนองกุงศรี หนองหิน กาฬสินธุ์ 46220",
    name_en: "Nong Kung Si, Nong Hin, Kalasin, 46220",
  },
  {
    id: 461301,
    districtTh: "สมเด็จ",
    districtEn: "Somdet",
    subdistrictTh: "สมเด็จ",
    subdistrictEn: "Somdet",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46150,
    district_code: 4613,
    province_code: 34,
    name: "สมเด็จ สมเด็จ กาฬสินธุ์ 46150",
    name_th: "สมเด็จ สมเด็จ กาฬสินธุ์ 46150",
    name_en: "Somdet, Somdet, Kalasin, 46150",
  },
  {
    id: 461302,
    districtTh: "สมเด็จ",
    districtEn: "Somdet",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46150,
    district_code: 4613,
    province_code: 34,
    name: "สมเด็จ หนองแวง กาฬสินธุ์ 46150",
    name_th: "สมเด็จ หนองแวง กาฬสินธุ์ 46150",
    name_en: "Somdet, Nong Waeng, Kalasin, 46150",
  },
  {
    id: 461303,
    districtTh: "สมเด็จ",
    districtEn: "Somdet",
    subdistrictTh: "แซงบาดาล",
    subdistrictEn: "Saeng Badan",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46150,
    district_code: 4613,
    province_code: 34,
    name: "สมเด็จ แซงบาดาล กาฬสินธุ์ 46150",
    name_th: "สมเด็จ แซงบาดาล กาฬสินธุ์ 46150",
    name_en: "Somdet, Saeng Badan, Kalasin, 46150",
  },
  {
    id: 461304,
    districtTh: "สมเด็จ",
    districtEn: "Somdet",
    subdistrictTh: "มหาไชย",
    subdistrictEn: "Maha Chai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46150,
    district_code: 4613,
    province_code: 34,
    name: "สมเด็จ มหาไชย กาฬสินธุ์ 46150",
    name_th: "สมเด็จ มหาไชย กาฬสินธุ์ 46150",
    name_en: "Somdet, Maha Chai, Kalasin, 46150",
  },
  {
    id: 461305,
    districtTh: "สมเด็จ",
    districtEn: "Somdet",
    subdistrictTh: "หมูม่น",
    subdistrictEn: "Mu Mon",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46150,
    district_code: 4613,
    province_code: 34,
    name: "สมเด็จ หมูม่น กาฬสินธุ์ 46150",
    name_th: "สมเด็จ หมูม่น กาฬสินธุ์ 46150",
    name_en: "Somdet, Mu Mon, Kalasin, 46150",
  },
  {
    id: 461306,
    districtTh: "สมเด็จ",
    districtEn: "Somdet",
    subdistrictTh: "ผาเสวย",
    subdistrictEn: "Pha Sawoei",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46150,
    district_code: 4613,
    province_code: 34,
    name: "สมเด็จ ผาเสวย กาฬสินธุ์ 46150",
    name_th: "สมเด็จ ผาเสวย กาฬสินธุ์ 46150",
    name_en: "Somdet, Pha Sawoei, Kalasin, 46150",
  },
  {
    id: 461307,
    districtTh: "สมเด็จ",
    districtEn: "Somdet",
    subdistrictTh: "ศรีสมเด็จ",
    subdistrictEn: "Si Somdet",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46150,
    district_code: 4613,
    province_code: 34,
    name: "สมเด็จ ศรีสมเด็จ กาฬสินธุ์ 46150",
    name_th: "สมเด็จ ศรีสมเด็จ กาฬสินธุ์ 46150",
    name_en: "Somdet, Si Somdet, Kalasin, 46150",
  },
  {
    id: 461308,
    districtTh: "สมเด็จ",
    districtEn: "Somdet",
    subdistrictTh: "ลำห้วยหลัว",
    subdistrictEn: "Lam Huai Lua",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46150,
    district_code: 4613,
    province_code: 34,
    name: "สมเด็จ ลำห้วยหลัว กาฬสินธุ์ 46150",
    name_th: "สมเด็จ ลำห้วยหลัว กาฬสินธุ์ 46150",
    name_en: "Somdet, Lam Huai Lua, Kalasin, 46150",
  },
  {
    id: 461401,
    districtTh: "ห้วยผึ้ง",
    districtEn: "Huai Phueng",
    subdistrictTh: "คำบง",
    subdistrictEn: "Kham Bong",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46240,
    district_code: 4614,
    province_code: 34,
    name: "ห้วยผึ้ง คำบง กาฬสินธุ์ 46240",
    name_th: "ห้วยผึ้ง คำบง กาฬสินธุ์ 46240",
    name_en: "Huai Phueng, Kham Bong, Kalasin, 46240",
  },
  {
    id: 461402,
    districtTh: "ห้วยผึ้ง",
    districtEn: "Huai Phueng",
    subdistrictTh: "ไค้นุ่น",
    subdistrictEn: "Khai Nun",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46240,
    district_code: 4614,
    province_code: 34,
    name: "ห้วยผึ้ง ไค้นุ่น กาฬสินธุ์ 46240",
    name_th: "ห้วยผึ้ง ไค้นุ่น กาฬสินธุ์ 46240",
    name_en: "Huai Phueng, Khai Nun, Kalasin, 46240",
  },
  {
    id: 461403,
    districtTh: "ห้วยผึ้ง",
    districtEn: "Huai Phueng",
    subdistrictTh: "นิคมห้วยผึ้ง",
    subdistrictEn: "Nikhom Huai Phueng",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46240,
    district_code: 4614,
    province_code: 34,
    name: "ห้วยผึ้ง นิคมห้วยผึ้ง กาฬสินธุ์ 46240",
    name_th: "ห้วยผึ้ง นิคมห้วยผึ้ง กาฬสินธุ์ 46240",
    name_en: "Huai Phueng, Nikhom Huai Phueng, Kalasin, 46240",
  },
  {
    id: 461404,
    districtTh: "ห้วยผึ้ง",
    districtEn: "Huai Phueng",
    subdistrictTh: "หนองอีบุตร",
    subdistrictEn: "Nong I But",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46240,
    district_code: 4614,
    province_code: 34,
    name: "ห้วยผึ้ง หนองอีบุตร กาฬสินธุ์ 46240",
    name_th: "ห้วยผึ้ง หนองอีบุตร กาฬสินธุ์ 46240",
    name_en: "Huai Phueng, Nong I But, Kalasin, 46240",
  },
  {
    id: 461501,
    districtTh: "สามชัย",
    districtEn: "Sam Chai",
    subdistrictTh: "สำราญ",
    subdistrictEn: "Samran",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4615,
    province_code: 34,
    name: "สามชัย สำราญ กาฬสินธุ์ 46180",
    name_th: "สามชัย สำราญ กาฬสินธุ์ 46180",
    name_en: "Sam Chai, Samran, Kalasin, 46180",
  },
  {
    id: 461502,
    districtTh: "สามชัย",
    districtEn: "Sam Chai",
    subdistrictTh: "สำราญใต้",
    subdistrictEn: "Samran Tai",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4615,
    province_code: 34,
    name: "สามชัย สำราญใต้ กาฬสินธุ์ 46180",
    name_th: "สามชัย สำราญใต้ กาฬสินธุ์ 46180",
    name_en: "Sam Chai, Samran Tai, Kalasin, 46180",
  },
  {
    id: 461503,
    districtTh: "สามชัย",
    districtEn: "Sam Chai",
    subdistrictTh: "คำสร้างเที่ยง",
    subdistrictEn: "Kham Sang Thiang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4615,
    province_code: 34,
    name: "สามชัย คำสร้างเที่ยง กาฬสินธุ์ 46180",
    name_th: "สามชัย คำสร้างเที่ยง กาฬสินธุ์ 46180",
    name_en: "Sam Chai, Kham Sang Thiang, Kalasin, 46180",
  },
  {
    id: 461504,
    districtTh: "สามชัย",
    districtEn: "Sam Chai",
    subdistrictTh: "หนองช้าง",
    subdistrictEn: "Nong Chang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46180,
    district_code: 4615,
    province_code: 34,
    name: "สามชัย หนองช้าง กาฬสินธุ์ 46180",
    name_th: "สามชัย หนองช้าง กาฬสินธุ์ 46180",
    name_en: "Sam Chai, Nong Chang, Kalasin, 46180",
  },
  {
    id: 461601,
    districtTh: "นาคู",
    districtEn: "Na Khu",
    subdistrictTh: "นาคู",
    subdistrictEn: "Na Khu",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4616,
    province_code: 34,
    name: "นาคู นาคู กาฬสินธุ์ 46160",
    name_th: "นาคู นาคู กาฬสินธุ์ 46160",
    name_en: "Na Khu, Na Khu, Kalasin, 46160",
  },
  {
    id: 461602,
    districtTh: "นาคู",
    districtEn: "Na Khu",
    subdistrictTh: "สายนาวัง",
    subdistrictEn: "Sai Na Wang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4616,
    province_code: 34,
    name: "นาคู สายนาวัง กาฬสินธุ์ 46160",
    name_th: "นาคู สายนาวัง กาฬสินธุ์ 46160",
    name_en: "Na Khu, Sai Na Wang, Kalasin, 46160",
  },
  {
    id: 461603,
    districtTh: "นาคู",
    districtEn: "Na Khu",
    subdistrictTh: "โนนนาจาน",
    subdistrictEn: "Non Na Chan",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4616,
    province_code: 34,
    name: "นาคู โนนนาจาน กาฬสินธุ์ 46160",
    name_th: "นาคู โนนนาจาน กาฬสินธุ์ 46160",
    name_en: "Na Khu, Non Na Chan, Kalasin, 46160",
  },
  {
    id: 461604,
    districtTh: "นาคู",
    districtEn: "Na Khu",
    subdistrictTh: "บ่อแก้ว",
    subdistrictEn: "Bo Kaeo",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4616,
    province_code: 34,
    name: "นาคู บ่อแก้ว กาฬสินธุ์ 46160",
    name_th: "นาคู บ่อแก้ว กาฬสินธุ์ 46160",
    name_en: "Na Khu, Bo Kaeo, Kalasin, 46160",
  },
  {
    id: 461605,
    districtTh: "นาคู",
    districtEn: "Na Khu",
    subdistrictTh: "ภูแล่นช้าง",
    subdistrictEn: "Phu Laen Chang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46160,
    district_code: 4616,
    province_code: 34,
    name: "นาคู ภูแล่นช้าง กาฬสินธุ์ 46160",
    name_th: "นาคู ภูแล่นช้าง กาฬสินธุ์ 46160",
    name_en: "Na Khu, Phu Laen Chang, Kalasin, 46160",
  },
  {
    id: 461701,
    districtTh: "ดอนจาน",
    districtEn: "Don Chan",
    subdistrictTh: "ดอนจาน",
    subdistrictEn: "Don Chan",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4617,
    province_code: 34,
    name: "ดอนจาน ดอนจาน กาฬสินธุ์ 46000",
    name_th: "ดอนจาน ดอนจาน กาฬสินธุ์ 46000",
    name_en: "Don Chan, Don Chan, Kalasin, 46000",
  },
  {
    id: 461702,
    districtTh: "ดอนจาน",
    districtEn: "Don Chan",
    subdistrictTh: "สะอาดไชยศรี",
    subdistrictEn: "Sa-at Chai Si",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4617,
    province_code: 34,
    name: "ดอนจาน สะอาดไชยศรี กาฬสินธุ์ 46000",
    name_th: "ดอนจาน สะอาดไชยศรี กาฬสินธุ์ 46000",
    name_en: "Don Chan, Sa-at Chai Si, Kalasin, 46000",
  },
  {
    id: 461703,
    districtTh: "ดอนจาน",
    districtEn: "Don Chan",
    subdistrictTh: "ดงพยุง",
    subdistrictEn: "Dong Phayung",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4617,
    province_code: 34,
    name: "ดอนจาน ดงพยุง กาฬสินธุ์ 46000",
    name_th: "ดอนจาน ดงพยุง กาฬสินธุ์ 46000",
    name_en: "Don Chan, Dong Phayung, Kalasin, 46000",
  },
  {
    id: 461704,
    districtTh: "ดอนจาน",
    districtEn: "Don Chan",
    subdistrictTh: "ม่วงนา",
    subdistrictEn: "Muang Na",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4617,
    province_code: 34,
    name: "ดอนจาน ม่วงนา กาฬสินธุ์ 46000",
    name_th: "ดอนจาน ม่วงนา กาฬสินธุ์ 46000",
    name_en: "Don Chan, Muang Na, Kalasin, 46000",
  },
  {
    id: 461705,
    districtTh: "ดอนจาน",
    districtEn: "Don Chan",
    subdistrictTh: "นาจำปา",
    subdistrictEn: "Na Champa",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46000,
    district_code: 4617,
    province_code: 34,
    name: "ดอนจาน นาจำปา กาฬสินธุ์ 46000",
    name_th: "ดอนจาน นาจำปา กาฬสินธุ์ 46000",
    name_en: "Don Chan, Na Champa, Kalasin, 46000",
  },
  {
    id: 461801,
    districtTh: "ฆ้องชัย",
    districtEn: "Khong Chai",
    subdistrictTh: "ฆ้องชัยพัฒนา",
    subdistrictEn: "Khong Chai Phatthana",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4618,
    province_code: 34,
    name: "ฆ้องชัย ฆ้องชัยพัฒนา กาฬสินธุ์ 46130",
    name_th: "ฆ้องชัย ฆ้องชัยพัฒนา กาฬสินธุ์ 46130",
    name_en: "Khong Chai, Khong Chai Phatthana, Kalasin, 46130",
  },
  {
    id: 461802,
    districtTh: "ฆ้องชัย",
    districtEn: "Khong Chai",
    subdistrictTh: "เหล่ากลาง",
    subdistrictEn: "Lao Klang",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4618,
    province_code: 34,
    name: "ฆ้องชัย เหล่ากลาง กาฬสินธุ์ 46130",
    name_th: "ฆ้องชัย เหล่ากลาง กาฬสินธุ์ 46130",
    name_en: "Khong Chai, Lao Klang, Kalasin, 46130",
  },
  {
    id: 461803,
    districtTh: "ฆ้องชัย",
    districtEn: "Khong Chai",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4618,
    province_code: 34,
    name: "ฆ้องชัย โคกสะอาด กาฬสินธุ์ 46130",
    name_th: "ฆ้องชัย โคกสะอาด กาฬสินธุ์ 46130",
    name_en: "Khong Chai, Khok Sa-at, Kalasin, 46130",
  },
  {
    id: 461804,
    districtTh: "ฆ้องชัย",
    districtEn: "Khong Chai",
    subdistrictTh: "โนนศิลาเลิง",
    subdistrictEn: "Non Sila Loeng",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4618,
    province_code: 34,
    name: "ฆ้องชัย โนนศิลาเลิง กาฬสินธุ์ 46130",
    name_th: "ฆ้องชัย โนนศิลาเลิง กาฬสินธุ์ 46130",
    name_en: "Khong Chai, Non Sila Loeng, Kalasin, 46130",
  },
  {
    id: 461805,
    districtTh: "ฆ้องชัย",
    districtEn: "Khong Chai",
    subdistrictTh: "ลำชี",
    subdistrictEn: "Lam Chi",
    provinceTh: "กาฬสินธุ์",
    provinceEn: "Kalasin",
    zipcode: 46130,
    district_code: 4618,
    province_code: 34,
    name: "ฆ้องชัย ลำชี กาฬสินธุ์ 46130",
    name_th: "ฆ้องชัย ลำชี กาฬสินธุ์ 46130",
    name_en: "Khong Chai, Lam Chi, Kalasin, 46130",
  },
  {
    id: 470101,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ธาตุเชิงชุม",
    subdistrictEn: "That Choeng Chum",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ธาตุเชิงชุม สกลนคร 47000",
    name_th: "เมืองสกลนคร ธาตุเชิงชุม สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, That Choeng Chum, Sakon Nakhon, 47000",
  },
  {
    id: 470102,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ขมิ้น",
    subdistrictEn: "Khamin",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47220,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ขมิ้น สกลนคร 47220",
    name_th: "เมืองสกลนคร ขมิ้น สกลนคร 47220",
    name_en: "Mueang Sakon Nakhon, Khamin, Sakon Nakhon, 47220",
  },
  {
    id: 470103,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "งิ้วด่อน",
    subdistrictEn: "Ngio Don",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร งิ้วด่อน สกลนคร 47000",
    name_th: "เมืองสกลนคร งิ้วด่อน สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Ngio Don, Sakon Nakhon, 47000",
  },
  {
    id: 470104,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "โนนหอม",
    subdistrictEn: "Non Hom",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร โนนหอม สกลนคร 47000",
    name_th: "เมืองสกลนคร โนนหอม สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Non Hom, Sakon Nakhon, 47000",
  },
  {
    id: 470106,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "เชียงเครือ",
    subdistrictEn: "Chiang Khruea",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร เชียงเครือ สกลนคร 47000",
    name_th: "เมืองสกลนคร เชียงเครือ สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Chiang Khruea, Sakon Nakhon, 47000",
  },
  {
    id: 470107,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ท่าแร่",
    subdistrictEn: "Tha Rae",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ท่าแร่ สกลนคร 47000",
    name_th: "เมืองสกลนคร ท่าแร่ สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Tha Rae, Sakon Nakhon, 47000",
  },
  {
    id: 470109,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ม่วงลาย",
    subdistrictEn: "Muang Lai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ม่วงลาย สกลนคร 47000",
    name_th: "เมืองสกลนคร ม่วงลาย สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Muang Lai, Sakon Nakhon, 47000",
  },
  {
    id: 470111,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ดงชน",
    subdistrictEn: "Dong Chon",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ดงชน สกลนคร 47000",
    name_th: "เมืองสกลนคร ดงชน สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Dong Chon, Sakon Nakhon, 47000",
  },
  {
    id: 470112,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ห้วยยาง",
    subdistrictEn: "Huai Yang",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ห้วยยาง สกลนคร 47000",
    name_th: "เมืองสกลนคร ห้วยยาง สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Huai Yang, Sakon Nakhon, 47000",
  },
  {
    id: 470113,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "พังขว้าง",
    subdistrictEn: "Phang Khwang",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร พังขว้าง สกลนคร 47000",
    name_th: "เมืองสกลนคร พังขว้าง สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Phang Khwang, Sakon Nakhon, 47000",
  },
  {
    id: 470115,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ดงมะไฟ",
    subdistrictEn: "Dong Mafai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ดงมะไฟ สกลนคร 47000",
    name_th: "เมืองสกลนคร ดงมะไฟ สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Dong Mafai, Sakon Nakhon, 47000",
  },
  {
    id: 470116,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ธาตุนาเวง",
    subdistrictEn: "That Na Weng",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ธาตุนาเวง สกลนคร 47000",
    name_th: "เมืองสกลนคร ธาตุนาเวง สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, That Na Weng, Sakon Nakhon, 47000",
  },
  {
    id: 470117,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "เหล่าปอแดง",
    subdistrictEn: "Lao Po Daeng",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร เหล่าปอแดง สกลนคร 47000",
    name_th: "เมืองสกลนคร เหล่าปอแดง สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Lao Po Daeng, Sakon Nakhon, 47000",
  },
  {
    id: 470118,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "หนองลาด",
    subdistrictEn: "Nong Lat",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47220,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร หนองลาด สกลนคร 47220",
    name_th: "เมืองสกลนคร หนองลาด สกลนคร 47220",
    name_en: "Mueang Sakon Nakhon, Nong Lat, Sakon Nakhon, 47220",
  },
  {
    id: 470120,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "ฮางโฮง",
    subdistrictEn: "Hang Hong",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร ฮางโฮง สกลนคร 47000",
    name_th: "เมืองสกลนคร ฮางโฮง สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Hang Hong, Sakon Nakhon, 47000",
  },
  {
    id: 470121,
    districtTh: "เมืองสกลนคร",
    districtEn: "Mueang Sakon Nakhon",
    subdistrictTh: "โคกก่อง",
    subdistrictEn: "Khok Kong",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47000,
    district_code: 4701,
    province_code: 35,
    name: "เมืองสกลนคร โคกก่อง สกลนคร 47000",
    name_th: "เมืองสกลนคร โคกก่อง สกลนคร 47000",
    name_en: "Mueang Sakon Nakhon, Khok Kong, Sakon Nakhon, 47000",
  },
  {
    id: 470201,
    districtTh: "กุสุมาลย์",
    districtEn: "Kusuman",
    subdistrictTh: "กุสุมาลย์",
    subdistrictEn: "Kusuman",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47210,
    district_code: 4702,
    province_code: 35,
    name: "กุสุมาลย์ กุสุมาลย์ สกลนคร 47210",
    name_th: "กุสุมาลย์ กุสุมาลย์ สกลนคร 47210",
    name_en: "Kusuman, Kusuman, Sakon Nakhon, 47210",
  },
  {
    id: 470202,
    districtTh: "กุสุมาลย์",
    districtEn: "Kusuman",
    subdistrictTh: "นาโพธิ์",
    subdistrictEn: "Na Pho",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47210,
    district_code: 4702,
    province_code: 35,
    name: "กุสุมาลย์ นาโพธิ์ สกลนคร 47210",
    name_th: "กุสุมาลย์ นาโพธิ์ สกลนคร 47210",
    name_en: "Kusuman, Na Pho, Sakon Nakhon, 47210",
  },
  {
    id: 470203,
    districtTh: "กุสุมาลย์",
    districtEn: "Kusuman",
    subdistrictTh: "นาเพียง",
    subdistrictEn: "Na Phiang",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47230,
    district_code: 4702,
    province_code: 35,
    name: "กุสุมาลย์ นาเพียง สกลนคร 47230",
    name_th: "กุสุมาลย์ นาเพียง สกลนคร 47230",
    name_en: "Kusuman, Na Phiang, Sakon Nakhon, 47230",
  },
  {
    id: 470204,
    districtTh: "กุสุมาลย์",
    districtEn: "Kusuman",
    subdistrictTh: "โพธิไพศาล",
    subdistrictEn: "Phothi Phaisan",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47210,
    district_code: 4702,
    province_code: 35,
    name: "กุสุมาลย์ โพธิไพศาล สกลนคร 47210",
    name_th: "กุสุมาลย์ โพธิไพศาล สกลนคร 47210",
    name_en: "Kusuman, Phothi Phaisan, Sakon Nakhon, 47210",
  },
  {
    id: 470205,
    districtTh: "กุสุมาลย์",
    districtEn: "Kusuman",
    subdistrictTh: "อุ่มจาน",
    subdistrictEn: "Um Chan",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47230,
    district_code: 4702,
    province_code: 35,
    name: "กุสุมาลย์ อุ่มจาน สกลนคร 47230",
    name_th: "กุสุมาลย์ อุ่มจาน สกลนคร 47230",
    name_en: "Kusuman, Um Chan, Sakon Nakhon, 47230",
  },
  {
    id: 470301,
    districtTh: "กุดบาก",
    districtEn: "Kut Bak",
    subdistrictTh: "กุดบาก",
    subdistrictEn: "Kut Bak",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47180,
    district_code: 4703,
    province_code: 35,
    name: "กุดบาก กุดบาก สกลนคร 47180",
    name_th: "กุดบาก กุดบาก สกลนคร 47180",
    name_en: "Kut Bak, Kut Bak, Sakon Nakhon, 47180",
  },
  {
    id: 470303,
    districtTh: "กุดบาก",
    districtEn: "Kut Bak",
    subdistrictTh: "นาม่อง",
    subdistrictEn: "Na Mong",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47180,
    district_code: 4703,
    province_code: 35,
    name: "กุดบาก นาม่อง สกลนคร 47180",
    name_th: "กุดบาก นาม่อง สกลนคร 47180",
    name_en: "Kut Bak, Na Mong, Sakon Nakhon, 47180",
  },
  {
    id: 470305,
    districtTh: "กุดบาก",
    districtEn: "Kut Bak",
    subdistrictTh: "กุดไห",
    subdistrictEn: "Kut Hai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47180,
    district_code: 4703,
    province_code: 35,
    name: "กุดบาก กุดไห สกลนคร 47180",
    name_th: "กุดบาก กุดไห สกลนคร 47180",
    name_en: "Kut Bak, Kut Hai, Sakon Nakhon, 47180",
  },
  {
    id: 470401,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "พรรณา",
    subdistrictEn: "Phanna",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47130,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม พรรณา สกลนคร 47130",
    name_th: "พรรณานิคม พรรณา สกลนคร 47130",
    name_en: "Phanna Nikhom, Phanna, Sakon Nakhon, 47130",
  },
  {
    id: 470402,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "วังยาง",
    subdistrictEn: "Wang Yang",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47130,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม วังยาง สกลนคร 47130",
    name_th: "พรรณานิคม วังยาง สกลนคร 47130",
    name_en: "Phanna Nikhom, Wang Yang, Sakon Nakhon, 47130",
  },
  {
    id: 470403,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "พอกน้อย",
    subdistrictEn: "Phok Noi",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47220,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม พอกน้อย สกลนคร 47220",
    name_th: "พรรณานิคม พอกน้อย สกลนคร 47220",
    name_en: "Phanna Nikhom, Phok Noi, Sakon Nakhon, 47220",
  },
  {
    id: 470404,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "นาหัวบ่อ",
    subdistrictEn: "Na Hua Bo",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47220,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม นาหัวบ่อ สกลนคร 47220",
    name_th: "พรรณานิคม นาหัวบ่อ สกลนคร 47220",
    name_en: "Phanna Nikhom, Na Hua Bo, Sakon Nakhon, 47220",
  },
  {
    id: 470405,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "ไร่",
    subdistrictEn: "Rai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47130,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม ไร่ สกลนคร 47130",
    name_th: "พรรณานิคม ไร่ สกลนคร 47130",
    name_en: "Phanna Nikhom, Rai, Sakon Nakhon, 47130",
  },
  {
    id: 470406,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "ช้างมิ่ง",
    subdistrictEn: "Chang Ming",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47130,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม ช้างมิ่ง สกลนคร 47130",
    name_th: "พรรณานิคม ช้างมิ่ง สกลนคร 47130",
    name_en: "Phanna Nikhom, Chang Ming, Sakon Nakhon, 47130",
  },
  {
    id: 470407,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "นาใน",
    subdistrictEn: "Na Nai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47130,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม นาใน สกลนคร 47130",
    name_th: "พรรณานิคม นาใน สกลนคร 47130",
    name_en: "Phanna Nikhom, Na Nai, Sakon Nakhon, 47130",
  },
  {
    id: 470408,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "สว่าง",
    subdistrictEn: "Sawang",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47130,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม สว่าง สกลนคร 47130",
    name_th: "พรรณานิคม สว่าง สกลนคร 47130",
    name_en: "Phanna Nikhom, Sawang, Sakon Nakhon, 47130",
  },
  {
    id: 470409,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "บะฮี",
    subdistrictEn: "Ba Hi",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47130,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม บะฮี สกลนคร 47130",
    name_th: "พรรณานิคม บะฮี สกลนคร 47130",
    name_en: "Phanna Nikhom, Ba Hi, Sakon Nakhon, 47130",
  },
  {
    id: 470410,
    districtTh: "พรรณานิคม",
    districtEn: "Phanna Nikhom",
    subdistrictTh: "เชิงชุม",
    subdistrictEn: "Choeng Chum",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47130,
    district_code: 4704,
    province_code: 35,
    name: "พรรณานิคม เชิงชุม สกลนคร 47130",
    name_th: "พรรณานิคม เชิงชุม สกลนคร 47130",
    name_en: "Phanna Nikhom, Choeng Chum, Sakon Nakhon, 47130",
  },
  {
    id: 470501,
    districtTh: "พังโคน",
    districtEn: "Phang Khon",
    subdistrictTh: "พังโคน",
    subdistrictEn: "Phang Khon",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47160,
    district_code: 4705,
    province_code: 35,
    name: "พังโคน พังโคน สกลนคร 47160",
    name_th: "พังโคน พังโคน สกลนคร 47160",
    name_en: "Phang Khon, Phang Khon, Sakon Nakhon, 47160",
  },
  {
    id: 470502,
    districtTh: "พังโคน",
    districtEn: "Phang Khon",
    subdistrictTh: "ม่วงไข่",
    subdistrictEn: "Muang Khai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47160,
    district_code: 4705,
    province_code: 35,
    name: "พังโคน ม่วงไข่ สกลนคร 47160",
    name_th: "พังโคน ม่วงไข่ สกลนคร 47160",
    name_en: "Phang Khon, Muang Khai, Sakon Nakhon, 47160",
  },
  {
    id: 470503,
    districtTh: "พังโคน",
    districtEn: "Phang Khon",
    subdistrictTh: "แร่",
    subdistrictEn: "Rae",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47160,
    district_code: 4705,
    province_code: 35,
    name: "พังโคน แร่ สกลนคร 47160",
    name_th: "พังโคน แร่ สกลนคร 47160",
    name_en: "Phang Khon, Rae, Sakon Nakhon, 47160",
  },
  {
    id: 470504,
    districtTh: "พังโคน",
    districtEn: "Phang Khon",
    subdistrictTh: "ไฮหย่อง",
    subdistrictEn: "Hai Yong",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47160,
    district_code: 4705,
    province_code: 35,
    name: "พังโคน ไฮหย่อง สกลนคร 47160",
    name_th: "พังโคน ไฮหย่อง สกลนคร 47160",
    name_en: "Phang Khon, Hai Yong, Sakon Nakhon, 47160",
  },
  {
    id: 470505,
    districtTh: "พังโคน",
    districtEn: "Phang Khon",
    subdistrictTh: "ต้นผึ้ง",
    subdistrictEn: "Ton Phueng",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47160,
    district_code: 4705,
    province_code: 35,
    name: "พังโคน ต้นผึ้ง สกลนคร 47160",
    name_th: "พังโคน ต้นผึ้ง สกลนคร 47160",
    name_en: "Phang Khon, Ton Phueng, Sakon Nakhon, 47160",
  },
  {
    id: 470601,
    districtTh: "วาริชภูมิ",
    districtEn: "Waritchaphum",
    subdistrictTh: "วาริชภูมิ",
    subdistrictEn: "Waritchaphum",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47150,
    district_code: 4706,
    province_code: 35,
    name: "วาริชภูมิ วาริชภูมิ สกลนคร 47150",
    name_th: "วาริชภูมิ วาริชภูมิ สกลนคร 47150",
    name_en: "Waritchaphum, Waritchaphum, Sakon Nakhon, 47150",
  },
  {
    id: 470602,
    districtTh: "วาริชภูมิ",
    districtEn: "Waritchaphum",
    subdistrictTh: "ปลาโหล",
    subdistrictEn: "Pla Lo",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47150,
    district_code: 4706,
    province_code: 35,
    name: "วาริชภูมิ ปลาโหล สกลนคร 47150",
    name_th: "วาริชภูมิ ปลาโหล สกลนคร 47150",
    name_en: "Waritchaphum, Pla Lo, Sakon Nakhon, 47150",
  },
  {
    id: 470603,
    districtTh: "วาริชภูมิ",
    districtEn: "Waritchaphum",
    subdistrictTh: "หนองลาด",
    subdistrictEn: "Nong Lat",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47150,
    district_code: 4706,
    province_code: 35,
    name: "วาริชภูมิ หนองลาด สกลนคร 47150",
    name_th: "วาริชภูมิ หนองลาด สกลนคร 47150",
    name_en: "Waritchaphum, Nong Lat, Sakon Nakhon, 47150",
  },
  {
    id: 470604,
    districtTh: "วาริชภูมิ",
    districtEn: "Waritchaphum",
    subdistrictTh: "คำบ่อ",
    subdistrictEn: "Kham Bo",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47150,
    district_code: 4706,
    province_code: 35,
    name: "วาริชภูมิ คำบ่อ สกลนคร 47150",
    name_th: "วาริชภูมิ คำบ่อ สกลนคร 47150",
    name_en: "Waritchaphum, Kham Bo, Sakon Nakhon, 47150",
  },
  {
    id: 470605,
    districtTh: "วาริชภูมิ",
    districtEn: "Waritchaphum",
    subdistrictTh: "ค้อเขียว",
    subdistrictEn: "Kho Khiao",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47150,
    district_code: 4706,
    province_code: 35,
    name: "วาริชภูมิ ค้อเขียว สกลนคร 47150",
    name_th: "วาริชภูมิ ค้อเขียว สกลนคร 47150",
    name_en: "Waritchaphum, Kho Khiao, Sakon Nakhon, 47150",
  },
  {
    id: 470701,
    districtTh: "นิคมน้ำอูน",
    districtEn: "Nikhom Nam Un",
    subdistrictTh: "นิคมน้ำอูน",
    subdistrictEn: "Nikhom Nam Un",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47270,
    district_code: 4707,
    province_code: 35,
    name: "นิคมน้ำอูน นิคมน้ำอูน สกลนคร 47270",
    name_th: "นิคมน้ำอูน นิคมน้ำอูน สกลนคร 47270",
    name_en: "Nikhom Nam Un, Nikhom Nam Un, Sakon Nakhon, 47270",
  },
  {
    id: 470702,
    districtTh: "นิคมน้ำอูน",
    districtEn: "Nikhom Nam Un",
    subdistrictTh: "หนองปลิง",
    subdistrictEn: "Nong Pling",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47270,
    district_code: 4707,
    province_code: 35,
    name: "นิคมน้ำอูน หนองปลิง สกลนคร 47270",
    name_th: "นิคมน้ำอูน หนองปลิง สกลนคร 47270",
    name_en: "Nikhom Nam Un, Nong Pling, Sakon Nakhon, 47270",
  },
  {
    id: 470703,
    districtTh: "นิคมน้ำอูน",
    districtEn: "Nikhom Nam Un",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47270,
    district_code: 4707,
    province_code: 35,
    name: "นิคมน้ำอูน หนองบัว สกลนคร 47270",
    name_th: "นิคมน้ำอูน หนองบัว สกลนคร 47270",
    name_en: "Nikhom Nam Un, Nong Bua, Sakon Nakhon, 47270",
  },
  {
    id: 470704,
    districtTh: "นิคมน้ำอูน",
    districtEn: "Nikhom Nam Un",
    subdistrictTh: "สุวรรณคาม",
    subdistrictEn: "*Suwannakarm",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47270,
    district_code: 4707,
    province_code: 35,
    name: "นิคมน้ำอูน สุวรรณคาม สกลนคร 47270",
    name_th: "นิคมน้ำอูน สุวรรณคาม สกลนคร 47270",
    name_en: "Nikhom Nam Un, *Suwannakarm, Sakon Nakhon, 47270",
  },
  {
    id: 470801,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "วานรนิวาส",
    subdistrictEn: "Wanon Niwat",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส วานรนิวาส สกลนคร 47120",
    name_th: "วานรนิวาส วานรนิวาส สกลนคร 47120",
    name_en: "Wanon Niwat, Wanon Niwat, Sakon Nakhon, 47120",
  },
  {
    id: 470802,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "เดื่อศรีคันไชย",
    subdistrictEn: "Duea Si Khan Chai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส เดื่อศรีคันไชย สกลนคร 47120",
    name_th: "วานรนิวาส เดื่อศรีคันไชย สกลนคร 47120",
    name_en: "Wanon Niwat, Duea Si Khan Chai, Sakon Nakhon, 47120",
  },
  {
    id: 470803,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "ขัวก่าย",
    subdistrictEn: "Khua Kai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส ขัวก่าย สกลนคร 47120",
    name_th: "วานรนิวาส ขัวก่าย สกลนคร 47120",
    name_en: "Wanon Niwat, Khua Kai, Sakon Nakhon, 47120",
  },
  {
    id: 470804,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "หนองสนม",
    subdistrictEn: "Nong Sanom",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส หนองสนม สกลนคร 47120",
    name_th: "วานรนิวาส หนองสนม สกลนคร 47120",
    name_en: "Wanon Niwat, Nong Sanom, Sakon Nakhon, 47120",
  },
  {
    id: 470805,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "คูสะคาม",
    subdistrictEn: "Khu Sakham",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส คูสะคาม สกลนคร 47120",
    name_th: "วานรนิวาส คูสะคาม สกลนคร 47120",
    name_en: "Wanon Niwat, Khu Sakham, Sakon Nakhon, 47120",
  },
  {
    id: 470806,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "ธาตุ",
    subdistrictEn: "That",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส ธาตุ สกลนคร 47120",
    name_th: "วานรนิวาส ธาตุ สกลนคร 47120",
    name_en: "Wanon Niwat, That, Sakon Nakhon, 47120",
  },
  {
    id: 470807,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส หนองแวง สกลนคร 47120",
    name_th: "วานรนิวาส หนองแวง สกลนคร 47120",
    name_en: "Wanon Niwat, Nong Waeng, Sakon Nakhon, 47120",
  },
  {
    id: 470808,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "ศรีวิชัย",
    subdistrictEn: "Si Wichai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส ศรีวิชัย สกลนคร 47120",
    name_th: "วานรนิวาส ศรีวิชัย สกลนคร 47120",
    name_en: "Wanon Niwat, Si Wichai, Sakon Nakhon, 47120",
  },
  {
    id: 470809,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "นาซอ",
    subdistrictEn: "Na So",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส นาซอ สกลนคร 47120",
    name_th: "วานรนิวาส นาซอ สกลนคร 47120",
    name_en: "Wanon Niwat, Na So, Sakon Nakhon, 47120",
  },
  {
    id: 470810,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "อินทร์แปลง",
    subdistrictEn: "In Plaeng",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส อินทร์แปลง สกลนคร 47120",
    name_th: "วานรนิวาส อินทร์แปลง สกลนคร 47120",
    name_en: "Wanon Niwat, In Plaeng, Sakon Nakhon, 47120",
  },
  {
    id: 470811,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "นาคำ",
    subdistrictEn: "Na Kham",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส นาคำ สกลนคร 47120",
    name_th: "วานรนิวาส นาคำ สกลนคร 47120",
    name_en: "Wanon Niwat, Na Kham, Sakon Nakhon, 47120",
  },
  {
    id: 470812,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "คอนสวรรค์",
    subdistrictEn: "Khon Sawan",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส คอนสวรรค์ สกลนคร 47120",
    name_th: "วานรนิวาส คอนสวรรค์ สกลนคร 47120",
    name_en: "Wanon Niwat, Khon Sawan, Sakon Nakhon, 47120",
  },
  {
    id: 470813,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "กุดเรือคำ",
    subdistrictEn: "Kut Ruea Kham",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส กุดเรือคำ สกลนคร 47120",
    name_th: "วานรนิวาส กุดเรือคำ สกลนคร 47120",
    name_en: "Wanon Niwat, Kut Ruea Kham, Sakon Nakhon, 47120",
  },
  {
    id: 470814,
    districtTh: "วานรนิวาส",
    districtEn: "Wanon Niwat",
    subdistrictTh: "หนองแวงใต้",
    subdistrictEn: "Nong Waeng Tai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47120,
    district_code: 4708,
    province_code: 35,
    name: "วานรนิวาส หนองแวงใต้ สกลนคร 47120",
    name_th: "วานรนิวาส หนองแวงใต้ สกลนคร 47120",
    name_en: "Wanon Niwat, Nong Waeng Tai, Sakon Nakhon, 47120",
  },
  {
    id: 470901,
    districtTh: "คำตากล้า",
    districtEn: "Kham Ta Kla",
    subdistrictTh: "คำตากล้า",
    subdistrictEn: "Kham Ta Kla",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47250,
    district_code: 4709,
    province_code: 35,
    name: "คำตากล้า คำตากล้า สกลนคร 47250",
    name_th: "คำตากล้า คำตากล้า สกลนคร 47250",
    name_en: "Kham Ta Kla, Kham Ta Kla, Sakon Nakhon, 47250",
  },
  {
    id: 470902,
    districtTh: "คำตากล้า",
    districtEn: "Kham Ta Kla",
    subdistrictTh: "หนองบัวสิม",
    subdistrictEn: "Nong Bua Sim",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47250,
    district_code: 4709,
    province_code: 35,
    name: "คำตากล้า หนองบัวสิม สกลนคร 47250",
    name_th: "คำตากล้า หนองบัวสิม สกลนคร 47250",
    name_en: "Kham Ta Kla, Nong Bua Sim, Sakon Nakhon, 47250",
  },
  {
    id: 470903,
    districtTh: "คำตากล้า",
    districtEn: "Kham Ta Kla",
    subdistrictTh: "นาแต้",
    subdistrictEn: "Na Tae",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47250,
    district_code: 4709,
    province_code: 35,
    name: "คำตากล้า นาแต้ สกลนคร 47250",
    name_th: "คำตากล้า นาแต้ สกลนคร 47250",
    name_en: "Kham Ta Kla, Na Tae, Sakon Nakhon, 47250",
  },
  {
    id: 470904,
    districtTh: "คำตากล้า",
    districtEn: "Kham Ta Kla",
    subdistrictTh: "แพด",
    subdistrictEn: "Phaet",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47250,
    district_code: 4709,
    province_code: 35,
    name: "คำตากล้า แพด สกลนคร 47250",
    name_th: "คำตากล้า แพด สกลนคร 47250",
    name_en: "Kham Ta Kla, Phaet, Sakon Nakhon, 47250",
  },
  {
    id: 471001,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "ม่วง",
    subdistrictEn: "Muang",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง ม่วง สกลนคร 47140",
    name_th: "บ้านม่วง ม่วง สกลนคร 47140",
    name_en: "Ban Muang, Muang, Sakon Nakhon, 47140",
  },
  {
    id: 471002,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "มาย",
    subdistrictEn: "Mai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง มาย สกลนคร 47140",
    name_th: "บ้านม่วง มาย สกลนคร 47140",
    name_en: "Ban Muang, Mai, Sakon Nakhon, 47140",
  },
  {
    id: 471003,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "ดงหม้อทอง",
    subdistrictEn: "Dong Mo Thong",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง ดงหม้อทอง สกลนคร 47140",
    name_th: "บ้านม่วง ดงหม้อทอง สกลนคร 47140",
    name_en: "Ban Muang, Dong Mo Thong, Sakon Nakhon, 47140",
  },
  {
    id: 471004,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "ดงเหนือ",
    subdistrictEn: "Dong Nuea",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง ดงเหนือ สกลนคร 47140",
    name_th: "บ้านม่วง ดงเหนือ สกลนคร 47140",
    name_en: "Ban Muang, Dong Nuea, Sakon Nakhon, 47140",
  },
  {
    id: 471005,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "ดงหม้อทองใต้",
    subdistrictEn: "Dong Mo Thong Tai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง ดงหม้อทองใต้ สกลนคร 47140",
    name_th: "บ้านม่วง ดงหม้อทองใต้ สกลนคร 47140",
    name_en: "Ban Muang, Dong Mo Thong Tai, Sakon Nakhon, 47140",
  },
  {
    id: 471006,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "ห้วยหลัว",
    subdistrictEn: "Huai Lua",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง ห้วยหลัว สกลนคร 47140",
    name_th: "บ้านม่วง ห้วยหลัว สกลนคร 47140",
    name_en: "Ban Muang, Huai Lua, Sakon Nakhon, 47140",
  },
  {
    id: 471007,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "โนนสะอาด",
    subdistrictEn: "Non Sa-at",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง โนนสะอาด สกลนคร 47140",
    name_th: "บ้านม่วง โนนสะอาด สกลนคร 47140",
    name_en: "Ban Muang, Non Sa-at, Sakon Nakhon, 47140",
  },
  {
    id: 471008,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "หนองกวั่ง",
    subdistrictEn: "Nong Kwang",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง หนองกวั่ง สกลนคร 47140",
    name_th: "บ้านม่วง หนองกวั่ง สกลนคร 47140",
    name_en: "Ban Muang, Nong Kwang, Sakon Nakhon, 47140",
  },
  {
    id: 471009,
    districtTh: "บ้านม่วง",
    districtEn: "Ban Muang",
    subdistrictTh: "บ่อแก้ว",
    subdistrictEn: "Bo Kaeo",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47140,
    district_code: 4710,
    province_code: 35,
    name: "บ้านม่วง บ่อแก้ว สกลนคร 47140",
    name_th: "บ้านม่วง บ่อแก้ว สกลนคร 47140",
    name_en: "Ban Muang, Bo Kaeo, Sakon Nakhon, 47140",
  },
  {
    id: 471101,
    districtTh: "อากาศอำนวย",
    districtEn: "Akat Amnuai",
    subdistrictTh: "อากาศ",
    subdistrictEn: "Akat",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47170,
    district_code: 4711,
    province_code: 35,
    name: "อากาศอำนวย อากาศ สกลนคร 47170",
    name_th: "อากาศอำนวย อากาศ สกลนคร 47170",
    name_en: "Akat Amnuai, Akat, Sakon Nakhon, 47170",
  },
  {
    id: 471102,
    districtTh: "อากาศอำนวย",
    districtEn: "Akat Amnuai",
    subdistrictTh: "โพนแพง",
    subdistrictEn: "Phon Phaeng",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47170,
    district_code: 4711,
    province_code: 35,
    name: "อากาศอำนวย โพนแพง สกลนคร 47170",
    name_th: "อากาศอำนวย โพนแพง สกลนคร 47170",
    name_en: "Akat Amnuai, Phon Phaeng, Sakon Nakhon, 47170",
  },
  {
    id: 471103,
    districtTh: "อากาศอำนวย",
    districtEn: "Akat Amnuai",
    subdistrictTh: "วาใหญ่",
    subdistrictEn: "Wa Yai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47170,
    district_code: 4711,
    province_code: 35,
    name: "อากาศอำนวย วาใหญ่ สกลนคร 47170",
    name_th: "อากาศอำนวย วาใหญ่ สกลนคร 47170",
    name_en: "Akat Amnuai, Wa Yai, Sakon Nakhon, 47170",
  },
  {
    id: 471104,
    districtTh: "อากาศอำนวย",
    districtEn: "Akat Amnuai",
    subdistrictTh: "โพนงาม",
    subdistrictEn: "Phon Ngam",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47170,
    district_code: 4711,
    province_code: 35,
    name: "อากาศอำนวย โพนงาม สกลนคร 47170",
    name_th: "อากาศอำนวย โพนงาม สกลนคร 47170",
    name_en: "Akat Amnuai, Phon Ngam, Sakon Nakhon, 47170",
  },
  {
    id: 471105,
    districtTh: "อากาศอำนวย",
    districtEn: "Akat Amnuai",
    subdistrictTh: "ท่าก้อน",
    subdistrictEn: "Tha Kon",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47170,
    district_code: 4711,
    province_code: 35,
    name: "อากาศอำนวย ท่าก้อน สกลนคร 47170",
    name_th: "อากาศอำนวย ท่าก้อน สกลนคร 47170",
    name_en: "Akat Amnuai, Tha Kon, Sakon Nakhon, 47170",
  },
  {
    id: 471106,
    districtTh: "อากาศอำนวย",
    districtEn: "Akat Amnuai",
    subdistrictTh: "นาฮี",
    subdistrictEn: "Na Hi",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47170,
    district_code: 4711,
    province_code: 35,
    name: "อากาศอำนวย นาฮี สกลนคร 47170",
    name_th: "อากาศอำนวย นาฮี สกลนคร 47170",
    name_en: "Akat Amnuai, Na Hi, Sakon Nakhon, 47170",
  },
  {
    id: 471107,
    districtTh: "อากาศอำนวย",
    districtEn: "Akat Amnuai",
    subdistrictTh: "บะหว้า",
    subdistrictEn: "Ba Wa",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47170,
    district_code: 4711,
    province_code: 35,
    name: "อากาศอำนวย บะหว้า สกลนคร 47170",
    name_th: "อากาศอำนวย บะหว้า สกลนคร 47170",
    name_en: "Akat Amnuai, Ba Wa, Sakon Nakhon, 47170",
  },
  {
    id: 471108,
    districtTh: "อากาศอำนวย",
    districtEn: "Akat Amnuai",
    subdistrictTh: "สามัคคีพัฒนา",
    subdistrictEn: "Samakkhi Phatthana",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47170,
    district_code: 4711,
    province_code: 35,
    name: "อากาศอำนวย สามัคคีพัฒนา สกลนคร 47170",
    name_th: "อากาศอำนวย สามัคคีพัฒนา สกลนคร 47170",
    name_en: "Akat Amnuai, Samakkhi Phatthana, Sakon Nakhon, 47170",
  },
  {
    id: 471201,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "สว่างแดนดิน",
    subdistrictEn: "Sawang Daen Din",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน สว่างแดนดิน สกลนคร 47110",
    name_th: "สว่างแดนดิน สว่างแดนดิน สกลนคร 47110",
    name_en: "Sawang Daen Din, Sawang Daen Din, Sakon Nakhon, 47110",
  },
  {
    id: 471203,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "คำสะอาด",
    subdistrictEn: "Kham Sa-at",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน คำสะอาด สกลนคร 47110",
    name_th: "สว่างแดนดิน คำสะอาด สกลนคร 47110",
    name_en: "Sawang Daen Din, Kham Sa-at, Sakon Nakhon, 47110",
  },
  {
    id: 471204,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "บ้านต้าย",
    subdistrictEn: "Ban Tai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน บ้านต้าย สกลนคร 47110",
    name_th: "สว่างแดนดิน บ้านต้าย สกลนคร 47110",
    name_en: "Sawang Daen Din, Ban Tai, Sakon Nakhon, 47110",
  },
  {
    id: 471206,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "บงเหนือ",
    subdistrictEn: "Bong Nuea",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน บงเหนือ สกลนคร 47110",
    name_th: "สว่างแดนดิน บงเหนือ สกลนคร 47110",
    name_en: "Sawang Daen Din, Bong Nuea, Sakon Nakhon, 47110",
  },
  {
    id: 471207,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "โพนสูง",
    subdistrictEn: "Phon Sung",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน โพนสูง สกลนคร 47110",
    name_th: "สว่างแดนดิน โพนสูง สกลนคร 47110",
    name_en: "Sawang Daen Din, Phon Sung, Sakon Nakhon, 47110",
  },
  {
    id: 471208,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "โคกสี",
    subdistrictEn: "Khok Si",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน โคกสี สกลนคร 47110",
    name_th: "สว่างแดนดิน โคกสี สกลนคร 47110",
    name_en: "Sawang Daen Din, Khok Si, Sakon Nakhon, 47110",
  },
  {
    id: 471210,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน หนองหลวง สกลนคร 47110",
    name_th: "สว่างแดนดิน หนองหลวง สกลนคร 47110",
    name_en: "Sawang Daen Din, Nong Luang, Sakon Nakhon, 47110",
  },
  {
    id: 471211,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "บงใต้",
    subdistrictEn: "Bong Tai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน บงใต้ สกลนคร 47110",
    name_th: "สว่างแดนดิน บงใต้ สกลนคร 47110",
    name_en: "Sawang Daen Din, Bong Tai, Sakon Nakhon, 47110",
  },
  {
    id: 471212,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "ค้อใต้",
    subdistrictEn: "Kho Tai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน ค้อใต้ สกลนคร 47110",
    name_th: "สว่างแดนดิน ค้อใต้ สกลนคร 47110",
    name_en: "Sawang Daen Din, Kho Tai, Sakon Nakhon, 47110",
  },
  {
    id: 471213,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "พันนา",
    subdistrictEn: "Phan Na",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47240,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน พันนา สกลนคร 47240",
    name_th: "สว่างแดนดิน พันนา สกลนคร 47240",
    name_en: "Sawang Daen Din, Phan Na, Sakon Nakhon, 47240",
  },
  {
    id: 471214,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "แวง",
    subdistrictEn: "Waeng",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47240,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน แวง สกลนคร 47240",
    name_th: "สว่างแดนดิน แวง สกลนคร 47240",
    name_en: "Sawang Daen Din, Waeng, Sakon Nakhon, 47240",
  },
  {
    id: 471215,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "ทรายมูล",
    subdistrictEn: "Sai Mun",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน ทรายมูล สกลนคร 47110",
    name_th: "สว่างแดนดิน ทรายมูล สกลนคร 47110",
    name_en: "Sawang Daen Din, Sai Mun, Sakon Nakhon, 47110",
  },
  {
    id: 471216,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "ตาลโกน",
    subdistrictEn: "Tan Kon",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47240,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน ตาลโกน สกลนคร 47240",
    name_th: "สว่างแดนดิน ตาลโกน สกลนคร 47240",
    name_en: "Sawang Daen Din, Tan Kon, Sakon Nakhon, 47240",
  },
  {
    id: 471217,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "ตาลเนิ้ง",
    subdistrictEn: "Tan Noeng",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47240,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน ตาลเนิ้ง สกลนคร 47240",
    name_th: "สว่างแดนดิน ตาลเนิ้ง สกลนคร 47240",
    name_en: "Sawang Daen Din, Tan Noeng, Sakon Nakhon, 47240",
  },
  {
    id: 471220,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "ธาตุทอง",
    subdistrictEn: "That Thong",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47240,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน ธาตุทอง สกลนคร 47240",
    name_th: "สว่างแดนดิน ธาตุทอง สกลนคร 47240",
    name_en: "Sawang Daen Din, That Thong, Sakon Nakhon, 47240",
  },
  {
    id: 471221,
    districtTh: "สว่างแดนดิน",
    districtEn: "Sawang Daen Din",
    subdistrictTh: "บ้านถ่อน",
    subdistrictEn: "Ban Thon",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47110,
    district_code: 4712,
    province_code: 35,
    name: "สว่างแดนดิน บ้านถ่อน สกลนคร 47110",
    name_th: "สว่างแดนดิน บ้านถ่อน สกลนคร 47110",
    name_en: "Sawang Daen Din, Ban Thon, Sakon Nakhon, 47110",
  },
  {
    id: 471301,
    districtTh: "ส่องดาว",
    districtEn: "Song Dao",
    subdistrictTh: "ส่องดาว",
    subdistrictEn: "Song Dao",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47190,
    district_code: 4713,
    province_code: 35,
    name: "ส่องดาว ส่องดาว สกลนคร 47190",
    name_th: "ส่องดาว ส่องดาว สกลนคร 47190",
    name_en: "Song Dao, Song Dao, Sakon Nakhon, 47190",
  },
  {
    id: 471302,
    districtTh: "ส่องดาว",
    districtEn: "Song Dao",
    subdistrictTh: "ท่าศิลา",
    subdistrictEn: "Tha Sila",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47190,
    district_code: 4713,
    province_code: 35,
    name: "ส่องดาว ท่าศิลา สกลนคร 47190",
    name_th: "ส่องดาว ท่าศิลา สกลนคร 47190",
    name_en: "Song Dao, Tha Sila, Sakon Nakhon, 47190",
  },
  {
    id: 471304,
    districtTh: "ส่องดาว",
    districtEn: "Song Dao",
    subdistrictTh: "ปทุมวาปี",
    subdistrictEn: "Pathum Wapi",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47190,
    district_code: 4713,
    province_code: 35,
    name: "ส่องดาว ปทุมวาปี สกลนคร 47190",
    name_th: "ส่องดาว ปทุมวาปี สกลนคร 47190",
    name_en: "Song Dao, Pathum Wapi, Sakon Nakhon, 47190",
  },
  {
    id: 471401,
    districtTh: "เต่างอย",
    districtEn: "Tao Ngoi",
    subdistrictTh: "เต่างอย",
    subdistrictEn: "Tao Ngoi",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47260,
    district_code: 4714,
    province_code: 35,
    name: "เต่างอย เต่างอย สกลนคร 47260",
    name_th: "เต่างอย เต่างอย สกลนคร 47260",
    name_en: "Tao Ngoi, Tao Ngoi, Sakon Nakhon, 47260",
  },
  {
    id: 471402,
    districtTh: "เต่างอย",
    districtEn: "Tao Ngoi",
    subdistrictTh: "บึงทวาย",
    subdistrictEn: "Bueng Thawai",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47260,
    district_code: 4714,
    province_code: 35,
    name: "เต่างอย บึงทวาย สกลนคร 47260",
    name_th: "เต่างอย บึงทวาย สกลนคร 47260",
    name_en: "Tao Ngoi, Bueng Thawai, Sakon Nakhon, 47260",
  },
  {
    id: 471403,
    districtTh: "เต่างอย",
    districtEn: "Tao Ngoi",
    subdistrictTh: "นาตาล",
    subdistrictEn: "Na Tan",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47260,
    district_code: 4714,
    province_code: 35,
    name: "เต่างอย นาตาล สกลนคร 47260",
    name_th: "เต่างอย นาตาล สกลนคร 47260",
    name_en: "Tao Ngoi, Na Tan, Sakon Nakhon, 47260",
  },
  {
    id: 471404,
    districtTh: "เต่างอย",
    districtEn: "Tao Ngoi",
    subdistrictTh: "จันทร์เพ็ญ",
    subdistrictEn: "Chan Phen",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47260,
    district_code: 4714,
    province_code: 35,
    name: "เต่างอย จันทร์เพ็ญ สกลนคร 47260",
    name_th: "เต่างอย จันทร์เพ็ญ สกลนคร 47260",
    name_en: "Tao Ngoi, Chan Phen, Sakon Nakhon, 47260",
  },
  {
    id: 471501,
    districtTh: "โคกศรีสุพรรณ",
    districtEn: "Khok Si Suphan",
    subdistrictTh: "ตองโขบ",
    subdistrictEn: "Tong Khop",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47280,
    district_code: 4715,
    province_code: 35,
    name: "โคกศรีสุพรรณ ตองโขบ สกลนคร 47280",
    name_th: "โคกศรีสุพรรณ ตองโขบ สกลนคร 47280",
    name_en: "Khok Si Suphan, Tong Khop, Sakon Nakhon, 47280",
  },
  {
    id: 471502,
    districtTh: "โคกศรีสุพรรณ",
    districtEn: "Khok Si Suphan",
    subdistrictTh: "เหล่าโพนค้อ",
    subdistrictEn: "Lao Phon Kho",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47280,
    district_code: 4715,
    province_code: 35,
    name: "โคกศรีสุพรรณ เหล่าโพนค้อ สกลนคร 47280",
    name_th: "โคกศรีสุพรรณ เหล่าโพนค้อ สกลนคร 47280",
    name_en: "Khok Si Suphan, Lao Phon Kho, Sakon Nakhon, 47280",
  },
  {
    id: 471503,
    districtTh: "โคกศรีสุพรรณ",
    districtEn: "Khok Si Suphan",
    subdistrictTh: "ด่านม่วงคำ",
    subdistrictEn: "Dan Muang Kham",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47280,
    district_code: 4715,
    province_code: 35,
    name: "โคกศรีสุพรรณ ด่านม่วงคำ สกลนคร 47280",
    name_th: "โคกศรีสุพรรณ ด่านม่วงคำ สกลนคร 47280",
    name_en: "Khok Si Suphan, Dan Muang Kham, Sakon Nakhon, 47280",
  },
  {
    id: 471504,
    districtTh: "โคกศรีสุพรรณ",
    districtEn: "Khok Si Suphan",
    subdistrictTh: "แมดนาท่ม",
    subdistrictEn: "Maet Na Thom",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47280,
    district_code: 4715,
    province_code: 35,
    name: "โคกศรีสุพรรณ แมดนาท่ม สกลนคร 47280",
    name_th: "โคกศรีสุพรรณ แมดนาท่ม สกลนคร 47280",
    name_en: "Khok Si Suphan, Maet Na Thom, Sakon Nakhon, 47280",
  },
  {
    id: 471601,
    districtTh: "เจริญศิลป์",
    districtEn: "Charoen Sin",
    subdistrictTh: "บ้านเหล่า",
    subdistrictEn: "Ban Lao",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47290,
    district_code: 4716,
    province_code: 35,
    name: "เจริญศิลป์ บ้านเหล่า สกลนคร 47290",
    name_th: "เจริญศิลป์ บ้านเหล่า สกลนคร 47290",
    name_en: "Charoen Sin, Ban Lao, Sakon Nakhon, 47290",
  },
  {
    id: 471602,
    districtTh: "เจริญศิลป์",
    districtEn: "Charoen Sin",
    subdistrictTh: "เจริญศิลป์",
    subdistrictEn: "Charoen Sin",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47290,
    district_code: 4716,
    province_code: 35,
    name: "เจริญศิลป์ เจริญศิลป์ สกลนคร 47290",
    name_th: "เจริญศิลป์ เจริญศิลป์ สกลนคร 47290",
    name_en: "Charoen Sin, Charoen Sin, Sakon Nakhon, 47290",
  },
  {
    id: 471603,
    districtTh: "เจริญศิลป์",
    districtEn: "Charoen Sin",
    subdistrictTh: "ทุ่งแก",
    subdistrictEn: "Thung Kae",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47290,
    district_code: 4716,
    province_code: 35,
    name: "เจริญศิลป์ ทุ่งแก สกลนคร 47290",
    name_th: "เจริญศิลป์ ทุ่งแก สกลนคร 47290",
    name_en: "Charoen Sin, Thung Kae, Sakon Nakhon, 47290",
  },
  {
    id: 471604,
    districtTh: "เจริญศิลป์",
    districtEn: "Charoen Sin",
    subdistrictTh: "โคกศิลา",
    subdistrictEn: "Khok Sila",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47290,
    district_code: 4716,
    province_code: 35,
    name: "เจริญศิลป์ โคกศิลา สกลนคร 47290",
    name_th: "เจริญศิลป์ โคกศิลา สกลนคร 47290",
    name_en: "Charoen Sin, Khok Sila, Sakon Nakhon, 47290",
  },
  {
    id: 471605,
    districtTh: "เจริญศิลป์",
    districtEn: "Charoen Sin",
    subdistrictTh: "หนองแปน",
    subdistrictEn: "Nong Paen",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47290,
    district_code: 4716,
    province_code: 35,
    name: "เจริญศิลป์ หนองแปน สกลนคร 47290",
    name_th: "เจริญศิลป์ หนองแปน สกลนคร 47290",
    name_en: "Charoen Sin, Nong Paen, Sakon Nakhon, 47290",
  },
  {
    id: 471701,
    districtTh: "โพนนาแก้ว",
    districtEn: "Phon Na Kaeo",
    subdistrictTh: "บ้านโพน",
    subdistrictEn: "Ban Phon",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47230,
    district_code: 4717,
    province_code: 35,
    name: "โพนนาแก้ว บ้านโพน สกลนคร 47230",
    name_th: "โพนนาแก้ว บ้านโพน สกลนคร 47230",
    name_en: "Phon Na Kaeo, Ban Phon, Sakon Nakhon, 47230",
  },
  {
    id: 471702,
    districtTh: "โพนนาแก้ว",
    districtEn: "Phon Na Kaeo",
    subdistrictTh: "นาแก้ว",
    subdistrictEn: "Na Kaeo",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47230,
    district_code: 4717,
    province_code: 35,
    name: "โพนนาแก้ว นาแก้ว สกลนคร 47230",
    name_th: "โพนนาแก้ว นาแก้ว สกลนคร 47230",
    name_en: "Phon Na Kaeo, Na Kaeo, Sakon Nakhon, 47230",
  },
  {
    id: 471703,
    districtTh: "โพนนาแก้ว",
    districtEn: "Phon Na Kaeo",
    subdistrictTh: "นาตงวัฒนา",
    subdistrictEn: "Na Tong Watthana",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47230,
    district_code: 4717,
    province_code: 35,
    name: "โพนนาแก้ว นาตงวัฒนา สกลนคร 47230",
    name_th: "โพนนาแก้ว นาตงวัฒนา สกลนคร 47230",
    name_en: "Phon Na Kaeo, Na Tong Watthana, Sakon Nakhon, 47230",
  },
  {
    id: 471704,
    districtTh: "โพนนาแก้ว",
    districtEn: "Phon Na Kaeo",
    subdistrictTh: "บ้านแป้น",
    subdistrictEn: "Ban Paen",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47230,
    district_code: 4717,
    province_code: 35,
    name: "โพนนาแก้ว บ้านแป้น สกลนคร 47230",
    name_th: "โพนนาแก้ว บ้านแป้น สกลนคร 47230",
    name_en: "Phon Na Kaeo, Ban Paen, Sakon Nakhon, 47230",
  },
  {
    id: 471705,
    districtTh: "โพนนาแก้ว",
    districtEn: "Phon Na Kaeo",
    subdistrictTh: "เชียงสือ",
    subdistrictEn: "Chiang Sue",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47230,
    district_code: 4717,
    province_code: 35,
    name: "โพนนาแก้ว เชียงสือ สกลนคร 47230",
    name_th: "โพนนาแก้ว เชียงสือ สกลนคร 47230",
    name_en: "Phon Na Kaeo, Chiang Sue, Sakon Nakhon, 47230",
  },
  {
    id: 471801,
    districtTh: "ภูพาน",
    districtEn: "Phu Phan",
    subdistrictTh: "สร้างค้อ",
    subdistrictEn: "Sang Kho",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47180,
    district_code: 4718,
    province_code: 35,
    name: "ภูพาน สร้างค้อ สกลนคร 47180",
    name_th: "ภูพาน สร้างค้อ สกลนคร 47180",
    name_en: "Phu Phan, Sang Kho, Sakon Nakhon, 47180",
  },
  {
    id: 471802,
    districtTh: "ภูพาน",
    districtEn: "Phu Phan",
    subdistrictTh: "หลุบเลา",
    subdistrictEn: "Lup Lao",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47180,
    district_code: 4718,
    province_code: 35,
    name: "ภูพาน หลุบเลา สกลนคร 47180",
    name_th: "ภูพาน หลุบเลา สกลนคร 47180",
    name_en: "Phu Phan, Lup Lao, Sakon Nakhon, 47180",
  },
  {
    id: 471803,
    districtTh: "ภูพาน",
    districtEn: "Phu Phan",
    subdistrictTh: "โคกภู",
    subdistrictEn: "Khok Phu",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47180,
    district_code: 4718,
    province_code: 35,
    name: "ภูพาน โคกภู สกลนคร 47180",
    name_th: "ภูพาน โคกภู สกลนคร 47180",
    name_en: "Phu Phan, Khok Phu, Sakon Nakhon, 47180",
  },
  {
    id: 471804,
    districtTh: "ภูพาน",
    districtEn: "Phu Phan",
    subdistrictTh: "กกปลาซิว",
    subdistrictEn: "Kok Pla Sio",
    provinceTh: "สกลนคร",
    provinceEn: "Sakon Nakhon",
    zipcode: 47180,
    district_code: 4718,
    province_code: 35,
    name: "ภูพาน กกปลาซิว สกลนคร 47180",
    name_th: "ภูพาน กกปลาซิว สกลนคร 47180",
    name_en: "Phu Phan, Kok Pla Sio, Sakon Nakhon, 47180",
  },
  {
    id: 480101,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม ในเมือง นครพนม 48000",
    name_th: "เมืองนครพนม ในเมือง นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Nai Mueang, Nakhon Phanom, 48000",
  },
  {
    id: 480102,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "หนองแสง",
    subdistrictEn: "Nong Saeng",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม หนองแสง นครพนม 48000",
    name_th: "เมืองนครพนม หนองแสง นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Nong Saeng, Nakhon Phanom, 48000",
  },
  {
    id: 480103,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "นาทราย",
    subdistrictEn: "Na Sai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม นาทราย นครพนม 48000",
    name_th: "เมืองนครพนม นาทราย นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Na Sai, Nakhon Phanom, 48000",
  },
  {
    id: 480104,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "นาราชควาย",
    subdistrictEn: "Na Rat Khwai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม นาราชควาย นครพนม 48000",
    name_th: "เมืองนครพนม นาราชควาย นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Na Rat Khwai, Nakhon Phanom, 48000",
  },
  {
    id: 480105,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "กุรุคุ",
    subdistrictEn: "Kurukhu",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม กุรุคุ นครพนม 48000",
    name_th: "เมืองนครพนม กุรุคุ นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Kurukhu, Nakhon Phanom, 48000",
  },
  {
    id: 480106,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "บ้านผึ้ง",
    subdistrictEn: "Ban Phueng",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม บ้านผึ้ง นครพนม 48000",
    name_th: "เมืองนครพนม บ้านผึ้ง นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Ban Phueng, Nakhon Phanom, 48000",
  },
  {
    id: 480107,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "อาจสามารถ",
    subdistrictEn: "At Samat",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม อาจสามารถ นครพนม 48000",
    name_th: "เมืองนครพนม อาจสามารถ นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, At Samat, Nakhon Phanom, 48000",
  },
  {
    id: 480108,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "ขามเฒ่า",
    subdistrictEn: "Kham Thao",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม ขามเฒ่า นครพนม 48000",
    name_th: "เมืองนครพนม ขามเฒ่า นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Kham Thao, Nakhon Phanom, 48000",
  },
  {
    id: 480109,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม บ้านกลาง นครพนม 48000",
    name_th: "เมืองนครพนม บ้านกลาง นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Ban Klang, Nakhon Phanom, 48000",
  },
  {
    id: 480110,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "ท่าค้อ",
    subdistrictEn: "Tha Kho",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม ท่าค้อ นครพนม 48000",
    name_th: "เมืองนครพนม ท่าค้อ นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Tha Kho, Nakhon Phanom, 48000",
  },
  {
    id: 480111,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "คำเตย",
    subdistrictEn: "Kham Toei",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม คำเตย นครพนม 48000",
    name_th: "เมืองนครพนม คำเตย นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Kham Toei, Nakhon Phanom, 48000",
  },
  {
    id: 480112,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "หนองญาติ",
    subdistrictEn: "Nong Yat",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม หนองญาติ นครพนม 48000",
    name_th: "เมืองนครพนม หนองญาติ นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Nong Yat, Nakhon Phanom, 48000",
  },
  {
    id: 480113,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "ดงขวาง",
    subdistrictEn: "Dong Khwang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม ดงขวาง นครพนม 48000",
    name_th: "เมืองนครพนม ดงขวาง นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Dong Khwang, Nakhon Phanom, 48000",
  },
  {
    id: 480114,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "วังตามัว",
    subdistrictEn: "Wang Ta Mua",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม วังตามัว นครพนม 48000",
    name_th: "เมืองนครพนม วังตามัว นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Wang Ta Mua, Nakhon Phanom, 48000",
  },
  {
    id: 480115,
    districtTh: "เมืองนครพนม",
    districtEn: "Mueang Nakhon Phanom",
    subdistrictTh: "โพธิ์ตาก",
    subdistrictEn: "Pho Tak",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48000,
    district_code: 4801,
    province_code: 36,
    name: "เมืองนครพนม โพธิ์ตาก นครพนม 48000",
    name_th: "เมืองนครพนม โพธิ์ตาก นครพนม 48000",
    name_en: "Mueang Nakhon Phanom, Pho Tak, Nakhon Phanom, 48000",
  },
  {
    id: 480201,
    districtTh: "ปลาปาก",
    districtEn: "Pla Pak",
    subdistrictTh: "ปลาปาก",
    subdistrictEn: "Pla Pak",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48160,
    district_code: 4802,
    province_code: 36,
    name: "ปลาปาก ปลาปาก นครพนม 48160",
    name_th: "ปลาปาก ปลาปาก นครพนม 48160",
    name_en: "Pla Pak, Pla Pak, Nakhon Phanom, 48160",
  },
  {
    id: 480202,
    districtTh: "ปลาปาก",
    districtEn: "Pla Pak",
    subdistrictTh: "หนองฮี",
    subdistrictEn: "Nong Hi",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48160,
    district_code: 4802,
    province_code: 36,
    name: "ปลาปาก หนองฮี นครพนม 48160",
    name_th: "ปลาปาก หนองฮี นครพนม 48160",
    name_en: "Pla Pak, Nong Hi, Nakhon Phanom, 48160",
  },
  {
    id: 480203,
    districtTh: "ปลาปาก",
    districtEn: "Pla Pak",
    subdistrictTh: "กุตาไก้",
    subdistrictEn: "Kutakai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48160,
    district_code: 4802,
    province_code: 36,
    name: "ปลาปาก กุตาไก้ นครพนม 48160",
    name_th: "ปลาปาก กุตาไก้ นครพนม 48160",
    name_en: "Pla Pak, Kutakai, Nakhon Phanom, 48160",
  },
  {
    id: 480204,
    districtTh: "ปลาปาก",
    districtEn: "Pla Pak",
    subdistrictTh: "โคกสว่าง",
    subdistrictEn: "Khok Sawan",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48160,
    district_code: 4802,
    province_code: 36,
    name: "ปลาปาก โคกสว่าง นครพนม 48160",
    name_th: "ปลาปาก โคกสว่าง นครพนม 48160",
    name_en: "Pla Pak, Khok Sawan, Nakhon Phanom, 48160",
  },
  {
    id: 480205,
    districtTh: "ปลาปาก",
    districtEn: "Pla Pak",
    subdistrictTh: "โคกสูง",
    subdistrictEn: "Khok Sung",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48160,
    district_code: 4802,
    province_code: 36,
    name: "ปลาปาก โคกสูง นครพนม 48160",
    name_th: "ปลาปาก โคกสูง นครพนม 48160",
    name_en: "Pla Pak, Khok Sung, Nakhon Phanom, 48160",
  },
  {
    id: 480206,
    districtTh: "ปลาปาก",
    districtEn: "Pla Pak",
    subdistrictTh: "มหาชัย",
    subdistrictEn: "Maha Chai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48160,
    district_code: 4802,
    province_code: 36,
    name: "ปลาปาก มหาชัย นครพนม 48160",
    name_th: "ปลาปาก มหาชัย นครพนม 48160",
    name_en: "Pla Pak, Maha Chai, Nakhon Phanom, 48160",
  },
  {
    id: 480207,
    districtTh: "ปลาปาก",
    districtEn: "Pla Pak",
    subdistrictTh: "นามะเขือ",
    subdistrictEn: "Na Makhuea",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48160,
    district_code: 4802,
    province_code: 36,
    name: "ปลาปาก นามะเขือ นครพนม 48160",
    name_th: "ปลาปาก นามะเขือ นครพนม 48160",
    name_en: "Pla Pak, Na Makhuea, Nakhon Phanom, 48160",
  },
  {
    id: 480208,
    districtTh: "ปลาปาก",
    districtEn: "Pla Pak",
    subdistrictTh: "หนองเทาใหญ่",
    subdistrictEn: "Nong Thao Yai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48160,
    district_code: 4802,
    province_code: 36,
    name: "ปลาปาก หนองเทาใหญ่ นครพนม 48160",
    name_th: "ปลาปาก หนองเทาใหญ่ นครพนม 48160",
    name_en: "Pla Pak, Nong Thao Yai, Nakhon Phanom, 48160",
  },
  {
    id: 480301,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "ท่าอุเทน",
    subdistrictEn: "Tha Uthen",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน ท่าอุเทน นครพนม 48120",
    name_th: "ท่าอุเทน ท่าอุเทน นครพนม 48120",
    name_en: "Tha Uthen, Tha Uthen, Nakhon Phanom, 48120",
  },
  {
    id: 480302,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "โนนตาล",
    subdistrictEn: "Non Tan",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน โนนตาล นครพนม 48120",
    name_th: "ท่าอุเทน โนนตาล นครพนม 48120",
    name_en: "Tha Uthen, Non Tan, Nakhon Phanom, 48120",
  },
  {
    id: 480303,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "ท่าจำปา",
    subdistrictEn: "Tha Champa",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน ท่าจำปา นครพนม 48120",
    name_th: "ท่าอุเทน ท่าจำปา นครพนม 48120",
    name_en: "Tha Uthen, Tha Champa, Nakhon Phanom, 48120",
  },
  {
    id: 480304,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "ไชยบุรี",
    subdistrictEn: "Chai Buri",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน ไชยบุรี นครพนม 48120",
    name_th: "ท่าอุเทน ไชยบุรี นครพนม 48120",
    name_en: "Tha Uthen, Chai Buri, Nakhon Phanom, 48120",
  },
  {
    id: 480305,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "พนอม",
    subdistrictEn: "Phanom",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน พนอม นครพนม 48120",
    name_th: "ท่าอุเทน พนอม นครพนม 48120",
    name_en: "Tha Uthen, Phanom, Nakhon Phanom, 48120",
  },
  {
    id: 480306,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "พะทาย",
    subdistrictEn: "Phathai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน พะทาย นครพนม 48120",
    name_th: "ท่าอุเทน พะทาย นครพนม 48120",
    name_en: "Tha Uthen, Phathai, Nakhon Phanom, 48120",
  },
  {
    id: 480311,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "เวินพระบาท",
    subdistrictEn: "Woen Phra Bat",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน เวินพระบาท นครพนม 48120",
    name_th: "ท่าอุเทน เวินพระบาท นครพนม 48120",
    name_en: "Tha Uthen, Woen Phra Bat, Nakhon Phanom, 48120",
  },
  {
    id: 480312,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "รามราช",
    subdistrictEn: "Ram Rat",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน รามราช นครพนม 48120",
    name_th: "ท่าอุเทน รามราช นครพนม 48120",
    name_en: "Tha Uthen, Ram Rat, Nakhon Phanom, 48120",
  },
  {
    id: 480314,
    districtTh: "ท่าอุเทน",
    districtEn: "Tha Uthen",
    subdistrictTh: "หนองเทา",
    subdistrictEn: "Nong Thao",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48120,
    district_code: 4803,
    province_code: 36,
    name: "ท่าอุเทน หนองเทา นครพนม 48120",
    name_th: "ท่าอุเทน หนองเทา นครพนม 48120",
    name_en: "Tha Uthen, Nong Thao, Nakhon Phanom, 48120",
  },
  {
    id: 480401,
    districtTh: "บ้านแพง",
    districtEn: "Ban Phaeng",
    subdistrictTh: "บ้านแพง",
    subdistrictEn: "Ban Phaeng",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4804,
    province_code: 36,
    name: "บ้านแพง บ้านแพง นครพนม 48140",
    name_th: "บ้านแพง บ้านแพง นครพนม 48140",
    name_en: "Ban Phaeng, Ban Phaeng, Nakhon Phanom, 48140",
  },
  {
    id: 480402,
    districtTh: "บ้านแพง",
    districtEn: "Ban Phaeng",
    subdistrictTh: "ไผ่ล้อม",
    subdistrictEn: "Phai Lom",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4804,
    province_code: 36,
    name: "บ้านแพง ไผ่ล้อม นครพนม 48140",
    name_th: "บ้านแพง ไผ่ล้อม นครพนม 48140",
    name_en: "Ban Phaeng, Phai Lom, Nakhon Phanom, 48140",
  },
  {
    id: 480403,
    districtTh: "บ้านแพง",
    districtEn: "Ban Phaeng",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4804,
    province_code: 36,
    name: "บ้านแพง โพนทอง นครพนม 48140",
    name_th: "บ้านแพง โพนทอง นครพนม 48140",
    name_en: "Ban Phaeng, Phon Thong, Nakhon Phanom, 48140",
  },
  {
    id: 480404,
    districtTh: "บ้านแพง",
    districtEn: "Ban Phaeng",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4804,
    province_code: 36,
    name: "บ้านแพง หนองแวง นครพนม 48140",
    name_th: "บ้านแพง หนองแวง นครพนม 48140",
    name_en: "Ban Phaeng, Nong Waeng, Nakhon Phanom, 48140",
  },
  {
    id: 480408,
    districtTh: "บ้านแพง",
    districtEn: "Ban Phaeng",
    subdistrictTh: "นางัว",
    subdistrictEn: "Na Ngua",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4804,
    province_code: 36,
    name: "บ้านแพง นางัว นครพนม 48140",
    name_th: "บ้านแพง นางัว นครพนม 48140",
    name_en: "Ban Phaeng, Na Ngua, Nakhon Phanom, 48140",
  },
  {
    id: 480409,
    districtTh: "บ้านแพง",
    districtEn: "Ban Phaeng",
    subdistrictTh: "นาเข",
    subdistrictEn: "Na Khe",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4804,
    province_code: 36,
    name: "บ้านแพง นาเข นครพนม 48140",
    name_th: "บ้านแพง นาเข นครพนม 48140",
    name_en: "Ban Phaeng, Na Khe, Nakhon Phanom, 48140",
  },
  {
    id: 480501,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "ธาตุพนม",
    subdistrictEn: "That Phanom",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม ธาตุพนม นครพนม 48110",
    name_th: "ธาตุพนม ธาตุพนม นครพนม 48110",
    name_en: "That Phanom, That Phanom, Nakhon Phanom, 48110",
  },
  {
    id: 480502,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "ฝั่งแดง",
    subdistrictEn: "Fang Daeng",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม ฝั่งแดง นครพนม 48110",
    name_th: "ธาตุพนม ฝั่งแดง นครพนม 48110",
    name_en: "That Phanom, Fang Daeng, Nakhon Phanom, 48110",
  },
  {
    id: 480503,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "โพนแพง",
    subdistrictEn: "Phon Phaeng",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม โพนแพง นครพนม 48110",
    name_th: "ธาตุพนม โพนแพง นครพนม 48110",
    name_en: "That Phanom, Phon Phaeng, Nakhon Phanom, 48110",
  },
  {
    id: 480504,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "พระกลางทุ่ง",
    subdistrictEn: "Phra Klang Thung",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม พระกลางทุ่ง นครพนม 48110",
    name_th: "ธาตุพนม พระกลางทุ่ง นครพนม 48110",
    name_en: "That Phanom, Phra Klang Thung, Nakhon Phanom, 48110",
  },
  {
    id: 480505,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "นาถ่อน",
    subdistrictEn: "Na Thon",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม นาถ่อน นครพนม 48110",
    name_th: "ธาตุพนม นาถ่อน นครพนม 48110",
    name_en: "That Phanom, Na Thon, Nakhon Phanom, 48110",
  },
  {
    id: 480506,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "แสนพัน",
    subdistrictEn: "Saen Phan",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม แสนพัน นครพนม 48110",
    name_th: "ธาตุพนม แสนพัน นครพนม 48110",
    name_en: "That Phanom, Saen Phan, Nakhon Phanom, 48110",
  },
  {
    id: 480507,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "ดอนนางหงส์",
    subdistrictEn: "Don Nang Hong",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม ดอนนางหงส์ นครพนม 48110",
    name_th: "ธาตุพนม ดอนนางหงส์ นครพนม 48110",
    name_en: "That Phanom, Don Nang Hong, Nakhon Phanom, 48110",
  },
  {
    id: 480508,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "น้ำก่ำ",
    subdistrictEn: "Nam Kam",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม น้ำก่ำ นครพนม 48110",
    name_th: "ธาตุพนม น้ำก่ำ นครพนม 48110",
    name_en: "That Phanom, Nam Kam, Nakhon Phanom, 48110",
  },
  {
    id: 480509,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "อุ่มเหม้า",
    subdistrictEn: "Um Mao",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม อุ่มเหม้า นครพนม 48110",
    name_th: "ธาตุพนม อุ่มเหม้า นครพนม 48110",
    name_en: "That Phanom, Um Mao, Nakhon Phanom, 48110",
  },
  {
    id: 480510,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "นาหนาด",
    subdistrictEn: "Na Nat",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม นาหนาด นครพนม 48110",
    name_th: "ธาตุพนม นาหนาด นครพนม 48110",
    name_en: "That Phanom, Na Nat, Nakhon Phanom, 48110",
  },
  {
    id: 480511,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "กุดฉิม",
    subdistrictEn: "Kut Chim",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม กุดฉิม นครพนม 48110",
    name_th: "ธาตุพนม กุดฉิม นครพนม 48110",
    name_en: "That Phanom, Kut Chim, Nakhon Phanom, 48110",
  },
  {
    id: 480512,
    districtTh: "ธาตุพนม",
    districtEn: "That Phanom",
    subdistrictTh: "ธาตุพนมเหนือ",
    subdistrictEn: "That Phanom Nuea",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48110,
    district_code: 4805,
    province_code: 36,
    name: "ธาตุพนม ธาตุพนมเหนือ นครพนม 48110",
    name_th: "ธาตุพนม ธาตุพนมเหนือ นครพนม 48110",
    name_en: "That Phanom, That Phanom Nuea, Nakhon Phanom, 48110",
  },
  {
    id: 480601,
    districtTh: "เรณูนคร",
    districtEn: "Renu Nakhon",
    subdistrictTh: "เรณู",
    subdistrictEn: "Renu",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48170,
    district_code: 4806,
    province_code: 36,
    name: "เรณูนคร เรณู นครพนม 48170",
    name_th: "เรณูนคร เรณู นครพนม 48170",
    name_en: "Renu Nakhon, Renu, Nakhon Phanom, 48170",
  },
  {
    id: 480602,
    districtTh: "เรณูนคร",
    districtEn: "Renu Nakhon",
    subdistrictTh: "โพนทอง",
    subdistrictEn: "Phon Thong",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48170,
    district_code: 4806,
    province_code: 36,
    name: "เรณูนคร โพนทอง นครพนม 48170",
    name_th: "เรณูนคร โพนทอง นครพนม 48170",
    name_en: "Renu Nakhon, Phon Thong, Nakhon Phanom, 48170",
  },
  {
    id: 480603,
    districtTh: "เรณูนคร",
    districtEn: "Renu Nakhon",
    subdistrictTh: "ท่าลาด",
    subdistrictEn: "Tha Lat",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48170,
    district_code: 4806,
    province_code: 36,
    name: "เรณูนคร ท่าลาด นครพนม 48170",
    name_th: "เรณูนคร ท่าลาด นครพนม 48170",
    name_en: "Renu Nakhon, Tha Lat, Nakhon Phanom, 48170",
  },
  {
    id: 480604,
    districtTh: "เรณูนคร",
    districtEn: "Renu Nakhon",
    subdistrictTh: "นางาม",
    subdistrictEn: "Na Ngam",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48170,
    district_code: 4806,
    province_code: 36,
    name: "เรณูนคร นางาม นครพนม 48170",
    name_th: "เรณูนคร นางาม นครพนม 48170",
    name_en: "Renu Nakhon, Na Ngam, Nakhon Phanom, 48170",
  },
  {
    id: 480605,
    districtTh: "เรณูนคร",
    districtEn: "Renu Nakhon",
    subdistrictTh: "โคกหินแฮ่",
    subdistrictEn: "Khok Hin Hae",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48170,
    district_code: 4806,
    province_code: 36,
    name: "เรณูนคร โคกหินแฮ่ นครพนม 48170",
    name_th: "เรณูนคร โคกหินแฮ่ นครพนม 48170",
    name_en: "Renu Nakhon, Khok Hin Hae, Nakhon Phanom, 48170",
  },
  {
    id: 480607,
    districtTh: "เรณูนคร",
    districtEn: "Renu Nakhon",
    subdistrictTh: "หนองย่างชิ้น",
    subdistrictEn: "Nong Yang Chin",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48170,
    district_code: 4806,
    province_code: 36,
    name: "เรณูนคร หนองย่างชิ้น นครพนม 48170",
    name_th: "เรณูนคร หนองย่างชิ้น นครพนม 48170",
    name_en: "Renu Nakhon, Nong Yang Chin, Nakhon Phanom, 48170",
  },
  {
    id: 480608,
    districtTh: "เรณูนคร",
    districtEn: "Renu Nakhon",
    subdistrictTh: "เรณูใต้",
    subdistrictEn: "Renu Tai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48170,
    district_code: 4806,
    province_code: 36,
    name: "เรณูนคร เรณูใต้ นครพนม 48170",
    name_th: "เรณูนคร เรณูใต้ นครพนม 48170",
    name_en: "Renu Nakhon, Renu Tai, Nakhon Phanom, 48170",
  },
  {
    id: 480609,
    districtTh: "เรณูนคร",
    districtEn: "Renu Nakhon",
    subdistrictTh: "นาขาม",
    subdistrictEn: "Na Kham",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48170,
    district_code: 4806,
    province_code: 36,
    name: "เรณูนคร นาขาม นครพนม 48170",
    name_th: "เรณูนคร นาขาม นครพนม 48170",
    name_en: "Renu Nakhon, Na Kham, Nakhon Phanom, 48170",
  },
  {
    id: 480701,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "นาแก",
    subdistrictEn: "Na Kae",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก นาแก นครพนม 48130",
    name_th: "นาแก นาแก นครพนม 48130",
    name_en: "Na Kae, Na Kae, Nakhon Phanom, 48130",
  },
  {
    id: 480702,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "พระซอง",
    subdistrictEn: "Phra Song",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก พระซอง นครพนม 48130",
    name_th: "นาแก พระซอง นครพนม 48130",
    name_en: "Na Kae, Phra Song, Nakhon Phanom, 48130",
  },
  {
    id: 480703,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "หนองสังข์",
    subdistrictEn: "Nong Sang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก หนองสังข์ นครพนม 48130",
    name_th: "นาแก หนองสังข์ นครพนม 48130",
    name_en: "Na Kae, Nong Sang, Nakhon Phanom, 48130",
  },
  {
    id: 480704,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "นาคู่",
    subdistrictEn: "Na Khu",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก นาคู่ นครพนม 48130",
    name_th: "นาแก นาคู่ นครพนม 48130",
    name_en: "Na Kae, Na Khu, Nakhon Phanom, 48130",
  },
  {
    id: 480705,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "พิมาน",
    subdistrictEn: "Phiman",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก พิมาน นครพนม 48130",
    name_th: "นาแก พิมาน นครพนม 48130",
    name_en: "Na Kae, Phiman, Nakhon Phanom, 48130",
  },
  {
    id: 480706,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "พุ่มแก",
    subdistrictEn: "Phum Kae",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก พุ่มแก นครพนม 48130",
    name_th: "นาแก พุ่มแก นครพนม 48130",
    name_en: "Na Kae, Phum Kae, Nakhon Phanom, 48130",
  },
  {
    id: 480707,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "ก้านเหลือง",
    subdistrictEn: "Kan Lueang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก ก้านเหลือง นครพนม 48130",
    name_th: "นาแก ก้านเหลือง นครพนม 48130",
    name_en: "Na Kae, Kan Lueang, Nakhon Phanom, 48130",
  },
  {
    id: 480708,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "หนองบ่อ",
    subdistrictEn: "Nong Bo",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก หนองบ่อ นครพนม 48130",
    name_th: "นาแก หนองบ่อ นครพนม 48130",
    name_en: "Na Kae, Nong Bo, Nakhon Phanom, 48130",
  },
  {
    id: 480709,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "นาเลียง",
    subdistrictEn: "Na Liang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก นาเลียง นครพนม 48130",
    name_th: "นาแก นาเลียง นครพนม 48130",
    name_en: "Na Kae, Na Liang, Nakhon Phanom, 48130",
  },
  {
    id: 480712,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "บ้านแก้ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก บ้านแก้ง นครพนม 48130",
    name_th: "นาแก บ้านแก้ง นครพนม 48130",
    name_en: "Na Kae, Ban Kaeng, Nakhon Phanom, 48130",
  },
  {
    id: 480713,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "คำพี้",
    subdistrictEn: "Kham Phi",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก คำพี้ นครพนม 48130",
    name_th: "นาแก คำพี้ นครพนม 48130",
    name_en: "Na Kae, Kham Phi, Nakhon Phanom, 48130",
  },
  {
    id: 480715,
    districtTh: "นาแก",
    districtEn: "Na Kae",
    subdistrictTh: "สีชมพู",
    subdistrictEn: "Si Chomphu",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4807,
    province_code: 36,
    name: "นาแก สีชมพู นครพนม 48130",
    name_th: "นาแก สีชมพู นครพนม 48130",
    name_en: "Na Kae, Si Chomphu, Nakhon Phanom, 48130",
  },
  {
    id: 480801,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "ศรีสงคราม",
    subdistrictEn: "Si Songkhram",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม ศรีสงคราม นครพนม 48150",
    name_th: "ศรีสงคราม ศรีสงคราม นครพนม 48150",
    name_en: "Si Songkhram, Si Songkhram, Nakhon Phanom, 48150",
  },
  {
    id: 480802,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "นาเดื่อ",
    subdistrictEn: "Na Duea",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม นาเดื่อ นครพนม 48150",
    name_th: "ศรีสงคราม นาเดื่อ นครพนม 48150",
    name_en: "Si Songkhram, Na Duea, Nakhon Phanom, 48150",
  },
  {
    id: 480803,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "บ้านเอื้อง",
    subdistrictEn: "Ban Ueang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม บ้านเอื้อง นครพนม 48150",
    name_th: "ศรีสงคราม บ้านเอื้อง นครพนม 48150",
    name_en: "Si Songkhram, Ban Ueang, Nakhon Phanom, 48150",
  },
  {
    id: 480804,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "สามผง",
    subdistrictEn: "Sam Phong",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม สามผง นครพนม 48150",
    name_th: "ศรีสงคราม สามผง นครพนม 48150",
    name_en: "Si Songkhram, Sam Phong, Nakhon Phanom, 48150",
  },
  {
    id: 480805,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "ท่าบ่อสงคราม",
    subdistrictEn: "Tha Bo Songkhram",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม ท่าบ่อสงคราม นครพนม 48150",
    name_th: "ศรีสงคราม ท่าบ่อสงคราม นครพนม 48150",
    name_en: "Si Songkhram, Tha Bo Songkhram, Nakhon Phanom, 48150",
  },
  {
    id: 480806,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "บ้านข่า",
    subdistrictEn: "Ban Kha",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม บ้านข่า นครพนม 48150",
    name_th: "ศรีสงคราม บ้านข่า นครพนม 48150",
    name_en: "Si Songkhram, Ban Kha, Nakhon Phanom, 48150",
  },
  {
    id: 480807,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "นาคำ",
    subdistrictEn: "Na Kham",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม นาคำ นครพนม 48150",
    name_th: "ศรีสงคราม นาคำ นครพนม 48150",
    name_en: "Si Songkhram, Na Kham, Nakhon Phanom, 48150",
  },
  {
    id: 480808,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "โพนสว่าง",
    subdistrictEn: "Phon Sawang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม โพนสว่าง นครพนม 48150",
    name_th: "ศรีสงคราม โพนสว่าง นครพนม 48150",
    name_en: "Si Songkhram, Phon Sawang, Nakhon Phanom, 48150",
  },
  {
    id: 480809,
    districtTh: "ศรีสงคราม",
    districtEn: "Si Songkhram",
    subdistrictTh: "หาดแพง",
    subdistrictEn: "Hat Phaeng",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48150,
    district_code: 4808,
    province_code: 36,
    name: "ศรีสงคราม หาดแพง นครพนม 48150",
    name_th: "ศรีสงคราม หาดแพง นครพนม 48150",
    name_en: "Si Songkhram, Hat Phaeng, Nakhon Phanom, 48150",
  },
  {
    id: 480901,
    districtTh: "นาหว้า",
    districtEn: "Na Wa",
    subdistrictTh: "นาหว้า",
    subdistrictEn: "Na Wa",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48180,
    district_code: 4809,
    province_code: 36,
    name: "นาหว้า นาหว้า นครพนม 48180",
    name_th: "นาหว้า นาหว้า นครพนม 48180",
    name_en: "Na Wa, Na Wa, Nakhon Phanom, 48180",
  },
  {
    id: 480902,
    districtTh: "นาหว้า",
    districtEn: "Na Wa",
    subdistrictTh: "นางัว",
    subdistrictEn: "Na Ngua",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48180,
    district_code: 4809,
    province_code: 36,
    name: "นาหว้า นางัว นครพนม 48180",
    name_th: "นาหว้า นางัว นครพนม 48180",
    name_en: "Na Wa, Na Ngua, Nakhon Phanom, 48180",
  },
  {
    id: 480903,
    districtTh: "นาหว้า",
    districtEn: "Na Wa",
    subdistrictTh: "บ้านเสียว",
    subdistrictEn: "Ban Siao",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48180,
    district_code: 4809,
    province_code: 36,
    name: "นาหว้า บ้านเสียว นครพนม 48180",
    name_th: "นาหว้า บ้านเสียว นครพนม 48180",
    name_en: "Na Wa, Ban Siao, Nakhon Phanom, 48180",
  },
  {
    id: 480904,
    districtTh: "นาหว้า",
    districtEn: "Na Wa",
    subdistrictTh: "นาคูณใหญ่",
    subdistrictEn: "Na Khun Yai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48180,
    district_code: 4809,
    province_code: 36,
    name: "นาหว้า นาคูณใหญ่ นครพนม 48180",
    name_th: "นาหว้า นาคูณใหญ่ นครพนม 48180",
    name_en: "Na Wa, Na Khun Yai, Nakhon Phanom, 48180",
  },
  {
    id: 480905,
    districtTh: "นาหว้า",
    districtEn: "Na Wa",
    subdistrictTh: "เหล่าพัฒนา",
    subdistrictEn: "Lao Phatthana",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48180,
    district_code: 4809,
    province_code: 36,
    name: "นาหว้า เหล่าพัฒนา นครพนม 48180",
    name_th: "นาหว้า เหล่าพัฒนา นครพนม 48180",
    name_en: "Na Wa, Lao Phatthana, Nakhon Phanom, 48180",
  },
  {
    id: 480906,
    districtTh: "นาหว้า",
    districtEn: "Na Wa",
    subdistrictTh: "ท่าเรือ",
    subdistrictEn: "Tha Ruea",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48180,
    district_code: 4809,
    province_code: 36,
    name: "นาหว้า ท่าเรือ นครพนม 48180",
    name_th: "นาหว้า ท่าเรือ นครพนม 48180",
    name_en: "Na Wa, Tha Ruea, Nakhon Phanom, 48180",
  },
  {
    id: 481001,
    districtTh: "โพนสวรรค์",
    districtEn: "Phon Sawan",
    subdistrictTh: "โพนสวรรค์",
    subdistrictEn: "Phon Sawan",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48190,
    district_code: 4810,
    province_code: 36,
    name: "โพนสวรรค์ โพนสวรรค์ นครพนม 48190",
    name_th: "โพนสวรรค์ โพนสวรรค์ นครพนม 48190",
    name_en: "Phon Sawan, Phon Sawan, Nakhon Phanom, 48190",
  },
  {
    id: 481002,
    districtTh: "โพนสวรรค์",
    districtEn: "Phon Sawan",
    subdistrictTh: "นาหัวบ่อ",
    subdistrictEn: "Na Hua Bo",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48190,
    district_code: 4810,
    province_code: 36,
    name: "โพนสวรรค์ นาหัวบ่อ นครพนม 48190",
    name_th: "โพนสวรรค์ นาหัวบ่อ นครพนม 48190",
    name_en: "Phon Sawan, Na Hua Bo, Nakhon Phanom, 48190",
  },
  {
    id: 481003,
    districtTh: "โพนสวรรค์",
    districtEn: "Phon Sawan",
    subdistrictTh: "นาขมิ้น",
    subdistrictEn: "Na Khamin",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48190,
    district_code: 4810,
    province_code: 36,
    name: "โพนสวรรค์ นาขมิ้น นครพนม 48190",
    name_th: "โพนสวรรค์ นาขมิ้น นครพนม 48190",
    name_en: "Phon Sawan, Na Khamin, Nakhon Phanom, 48190",
  },
  {
    id: 481004,
    districtTh: "โพนสวรรค์",
    districtEn: "Phon Sawan",
    subdistrictTh: "โพนบก",
    subdistrictEn: "Phon Bok",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48190,
    district_code: 4810,
    province_code: 36,
    name: "โพนสวรรค์ โพนบก นครพนม 48190",
    name_th: "โพนสวรรค์ โพนบก นครพนม 48190",
    name_en: "Phon Sawan, Phon Bok, Nakhon Phanom, 48190",
  },
  {
    id: 481005,
    districtTh: "โพนสวรรค์",
    districtEn: "Phon Sawan",
    subdistrictTh: "บ้านค้อ",
    subdistrictEn: "Ban Kho",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48190,
    district_code: 4810,
    province_code: 36,
    name: "โพนสวรรค์ บ้านค้อ นครพนม 48190",
    name_th: "โพนสวรรค์ บ้านค้อ นครพนม 48190",
    name_en: "Phon Sawan, Ban Kho, Nakhon Phanom, 48190",
  },
  {
    id: 481006,
    districtTh: "โพนสวรรค์",
    districtEn: "Phon Sawan",
    subdistrictTh: "โพนจาน",
    subdistrictEn: "Phon Chan",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48190,
    district_code: 4810,
    province_code: 36,
    name: "โพนสวรรค์ โพนจาน นครพนม 48190",
    name_th: "โพนสวรรค์ โพนจาน นครพนม 48190",
    name_en: "Phon Sawan, Phon Chan, Nakhon Phanom, 48190",
  },
  {
    id: 481007,
    districtTh: "โพนสวรรค์",
    districtEn: "Phon Sawan",
    subdistrictTh: "นาใน",
    subdistrictEn: "Na Nai",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48190,
    district_code: 4810,
    province_code: 36,
    name: "โพนสวรรค์ นาใน นครพนม 48190",
    name_th: "โพนสวรรค์ นาใน นครพนม 48190",
    name_en: "Phon Sawan, Na Nai, Nakhon Phanom, 48190",
  },
  {
    id: 481101,
    districtTh: "นาทม",
    districtEn: "Na Thom",
    subdistrictTh: "นาทม",
    subdistrictEn: "Na Thom",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4811,
    province_code: 36,
    name: "นาทม นาทม นครพนม 48140",
    name_th: "นาทม นาทม นครพนม 48140",
    name_en: "Na Thom, Na Thom, Nakhon Phanom, 48140",
  },
  {
    id: 481102,
    districtTh: "นาทม",
    districtEn: "Na Thom",
    subdistrictTh: "หนองซน",
    subdistrictEn: "Nong Son",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4811,
    province_code: 36,
    name: "นาทม หนองซน นครพนม 48140",
    name_th: "นาทม หนองซน นครพนม 48140",
    name_en: "Na Thom, Nong Son, Nakhon Phanom, 48140",
  },
  {
    id: 481103,
    districtTh: "นาทม",
    districtEn: "Na Thom",
    subdistrictTh: "ดอนเตย",
    subdistrictEn: "Don Toei",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48140,
    district_code: 4811,
    province_code: 36,
    name: "นาทม ดอนเตย นครพนม 48140",
    name_th: "นาทม ดอนเตย นครพนม 48140",
    name_en: "Na Thom, Don Toei, Nakhon Phanom, 48140",
  },
  {
    id: 481201,
    districtTh: "วังยาง",
    districtEn: "Wang Yang",
    subdistrictTh: "วังยาง",
    subdistrictEn: "Wang Yang",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4812,
    province_code: 36,
    name: "วังยาง วังยาง นครพนม 48130",
    name_th: "วังยาง วังยาง นครพนม 48130",
    name_en: "Wang Yang, Wang Yang, Nakhon Phanom, 48130",
  },
  {
    id: 481202,
    districtTh: "วังยาง",
    districtEn: "Wang Yang",
    subdistrictTh: "โคกสี",
    subdistrictEn: "Khok Si",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4812,
    province_code: 36,
    name: "วังยาง โคกสี นครพนม 48130",
    name_th: "วังยาง โคกสี นครพนม 48130",
    name_en: "Wang Yang, Khok Si, Nakhon Phanom, 48130",
  },
  {
    id: 481203,
    districtTh: "วังยาง",
    districtEn: "Wang Yang",
    subdistrictTh: "ยอดชาด",
    subdistrictEn: "Yot Chat",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4812,
    province_code: 36,
    name: "วังยาง ยอดชาด นครพนม 48130",
    name_th: "วังยาง ยอดชาด นครพนม 48130",
    name_en: "Wang Yang, Yot Chat, Nakhon Phanom, 48130",
  },
  {
    id: 481204,
    districtTh: "วังยาง",
    districtEn: "Wang Yang",
    subdistrictTh: "หนองโพธิ์",
    subdistrictEn: "Nong Pho",
    provinceTh: "นครพนม",
    provinceEn: "Nakhon Phanom",
    zipcode: 48130,
    district_code: 4812,
    province_code: 36,
    name: "วังยาง หนองโพธิ์ นครพนม 48130",
    name_th: "วังยาง หนองโพธิ์ นครพนม 48130",
    name_en: "Wang Yang, Nong Pho, Nakhon Phanom, 48130",
  },
  {
    id: 490101,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "มุกดาหาร",
    subdistrictEn: "Mukdahan",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร มุกดาหาร มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร มุกดาหาร มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Mukdahan, Mukdahan, 49000",
  },
  {
    id: 490102,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "ศรีบุญเรือง",
    subdistrictEn: "Si Bun Rueang",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร ศรีบุญเรือง มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร ศรีบุญเรือง มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Si Bun Rueang, Mukdahan, 49000",
  },
  {
    id: 490103,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "บ้านโคก",
    subdistrictEn: "Ban Khok",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร บ้านโคก มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร บ้านโคก มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Ban Khok, Mukdahan, 49000",
  },
  {
    id: 490104,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "บางทรายใหญ่",
    subdistrictEn: "Bang Sai Yai",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร บางทรายใหญ่ มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร บางทรายใหญ่ มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Bang Sai Yai, Mukdahan, 49000",
  },
  {
    id: 490105,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "โพนทราย",
    subdistrictEn: "Phon Sai",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร โพนทราย มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร โพนทราย มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Phon Sai, Mukdahan, 49000",
  },
  {
    id: 490106,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "ผึ่งแดด",
    subdistrictEn: "Phueng Daet",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร ผึ่งแดด มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร ผึ่งแดด มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Phueng Daet, Mukdahan, 49000",
  },
  {
    id: 490107,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "นาโสก",
    subdistrictEn: "Na Sok",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร นาโสก มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร นาโสก มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Na Sok, Mukdahan, 49000",
  },
  {
    id: 490108,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "นาสีนวน",
    subdistrictEn: "Na Si Nuan",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร นาสีนวน มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร นาสีนวน มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Na Si Nuan, Mukdahan, 49000",
  },
  {
    id: 490109,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "คำป่าหลาย",
    subdistrictEn: "Kham Pa Lai",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร คำป่าหลาย มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร คำป่าหลาย มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Kham Pa Lai, Mukdahan, 49000",
  },
  {
    id: 490110,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "คำอาฮวน",
    subdistrictEn: "Kham Ahuan",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร คำอาฮวน มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร คำอาฮวน มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Kham Ahuan, Mukdahan, 49000",
  },
  {
    id: 490111,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "ดงเย็น",
    subdistrictEn: "Dong Yen",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร ดงเย็น มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร ดงเย็น มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Dong Yen, Mukdahan, 49000",
  },
  {
    id: 490112,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "ดงมอน",
    subdistrictEn: "Dong Mon",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร ดงมอน มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร ดงมอน มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Dong Mon, Mukdahan, 49000",
  },
  {
    id: 490113,
    districtTh: "เมืองมุกดาหาร",
    districtEn: "Mueang Mukdahan",
    subdistrictTh: "กุดแข้",
    subdistrictEn: "Kut Khae",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49000,
    district_code: 4901,
    province_code: 37,
    name: "เมืองมุกดาหาร กุดแข้ มุกดาหาร 49000",
    name_th: "เมืองมุกดาหาร กุดแข้ มุกดาหาร 49000",
    name_en: "Mueang Mukdahan, Kut Khae, Mukdahan, 49000",
  },
  {
    id: 490201,
    districtTh: "นิคมคำสร้อย",
    districtEn: "Nikhom Kham Soi",
    subdistrictTh: "นิคมคำสร้อย",
    subdistrictEn: "Nikhom Kham Soi",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49130,
    district_code: 4902,
    province_code: 37,
    name: "นิคมคำสร้อย นิคมคำสร้อย มุกดาหาร 49130",
    name_th: "นิคมคำสร้อย นิคมคำสร้อย มุกดาหาร 49130",
    name_en: "Nikhom Kham Soi, Nikhom Kham Soi, Mukdahan, 49130",
  },
  {
    id: 490202,
    districtTh: "นิคมคำสร้อย",
    districtEn: "Nikhom Kham Soi",
    subdistrictTh: "นากอก",
    subdistrictEn: "Na Kok",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49130,
    district_code: 4902,
    province_code: 37,
    name: "นิคมคำสร้อย นากอก มุกดาหาร 49130",
    name_th: "นิคมคำสร้อย นากอก มุกดาหาร 49130",
    name_en: "Nikhom Kham Soi, Na Kok, Mukdahan, 49130",
  },
  {
    id: 490203,
    districtTh: "นิคมคำสร้อย",
    districtEn: "Nikhom Kham Soi",
    subdistrictTh: "หนองแวง",
    subdistrictEn: "Nong Waeng",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49130,
    district_code: 4902,
    province_code: 37,
    name: "นิคมคำสร้อย หนองแวง มุกดาหาร 49130",
    name_th: "นิคมคำสร้อย หนองแวง มุกดาหาร 49130",
    name_en: "Nikhom Kham Soi, Nong Waeng, Mukdahan, 49130",
  },
  {
    id: 490204,
    districtTh: "นิคมคำสร้อย",
    districtEn: "Nikhom Kham Soi",
    subdistrictTh: "กกแดง",
    subdistrictEn: "Kok Daeng",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49130,
    district_code: 4902,
    province_code: 37,
    name: "นิคมคำสร้อย กกแดง มุกดาหาร 49130",
    name_th: "นิคมคำสร้อย กกแดง มุกดาหาร 49130",
    name_en: "Nikhom Kham Soi, Kok Daeng, Mukdahan, 49130",
  },
  {
    id: 490205,
    districtTh: "นิคมคำสร้อย",
    districtEn: "Nikhom Kham Soi",
    subdistrictTh: "นาอุดม",
    subdistrictEn: "Na Udom",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49130,
    district_code: 4902,
    province_code: 37,
    name: "นิคมคำสร้อย นาอุดม มุกดาหาร 49130",
    name_th: "นิคมคำสร้อย นาอุดม มุกดาหาร 49130",
    name_en: "Nikhom Kham Soi, Na Udom, Mukdahan, 49130",
  },
  {
    id: 490206,
    districtTh: "นิคมคำสร้อย",
    districtEn: "Nikhom Kham Soi",
    subdistrictTh: "โชคชัย",
    subdistrictEn: "Chok Chai",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49130,
    district_code: 4902,
    province_code: 37,
    name: "นิคมคำสร้อย โชคชัย มุกดาหาร 49130",
    name_th: "นิคมคำสร้อย โชคชัย มุกดาหาร 49130",
    name_en: "Nikhom Kham Soi, Chok Chai, Mukdahan, 49130",
  },
  {
    id: 490207,
    districtTh: "นิคมคำสร้อย",
    districtEn: "Nikhom Kham Soi",
    subdistrictTh: "ร่มเกล้า",
    subdistrictEn: "Rom Klao",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49130,
    district_code: 4902,
    province_code: 37,
    name: "นิคมคำสร้อย ร่มเกล้า มุกดาหาร 49130",
    name_th: "นิคมคำสร้อย ร่มเกล้า มุกดาหาร 49130",
    name_en: "Nikhom Kham Soi, Rom Klao, Mukdahan, 49130",
  },
  {
    id: 490301,
    districtTh: "ดอนตาล",
    districtEn: "Don Tan",
    subdistrictTh: "ดอนตาล",
    subdistrictEn: "Don Tan",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49120,
    district_code: 4903,
    province_code: 37,
    name: "ดอนตาล ดอนตาล มุกดาหาร 49120",
    name_th: "ดอนตาล ดอนตาล มุกดาหาร 49120",
    name_en: "Don Tan, Don Tan, Mukdahan, 49120",
  },
  {
    id: 490302,
    districtTh: "ดอนตาล",
    districtEn: "Don Tan",
    subdistrictTh: "โพธิ์ไทร",
    subdistrictEn: "Pho Sai",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49120,
    district_code: 4903,
    province_code: 37,
    name: "ดอนตาล โพธิ์ไทร มุกดาหาร 49120",
    name_th: "ดอนตาล โพธิ์ไทร มุกดาหาร 49120",
    name_en: "Don Tan, Pho Sai, Mukdahan, 49120",
  },
  {
    id: 490303,
    districtTh: "ดอนตาล",
    districtEn: "Don Tan",
    subdistrictTh: "ป่าไร่",
    subdistrictEn: "Pa Rai",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49120,
    district_code: 4903,
    province_code: 37,
    name: "ดอนตาล ป่าไร่ มุกดาหาร 49120",
    name_th: "ดอนตาล ป่าไร่ มุกดาหาร 49120",
    name_en: "Don Tan, Pa Rai, Mukdahan, 49120",
  },
  {
    id: 490304,
    districtTh: "ดอนตาล",
    districtEn: "Don Tan",
    subdistrictTh: "เหล่าหมี",
    subdistrictEn: "Lao Mi",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49120,
    district_code: 4903,
    province_code: 37,
    name: "ดอนตาล เหล่าหมี มุกดาหาร 49120",
    name_th: "ดอนตาล เหล่าหมี มุกดาหาร 49120",
    name_en: "Don Tan, Lao Mi, Mukdahan, 49120",
  },
  {
    id: 490305,
    districtTh: "ดอนตาล",
    districtEn: "Don Tan",
    subdistrictTh: "บ้านบาก",
    subdistrictEn: "Ban Bak",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49120,
    district_code: 4903,
    province_code: 37,
    name: "ดอนตาล บ้านบาก มุกดาหาร 49120",
    name_th: "ดอนตาล บ้านบาก มุกดาหาร 49120",
    name_en: "Don Tan, Ban Bak, Mukdahan, 49120",
  },
  {
    id: 490306,
    districtTh: "ดอนตาล",
    districtEn: "Don Tan",
    subdistrictTh: "นาสะเม็ง",
    subdistrictEn: "Na Sameng",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49120,
    district_code: 4903,
    province_code: 37,
    name: "ดอนตาล นาสะเม็ง มุกดาหาร 49120",
    name_th: "ดอนตาล นาสะเม็ง มุกดาหาร 49120",
    name_en: "Don Tan, Na Sameng, Mukdahan, 49120",
  },
  {
    id: 490307,
    districtTh: "ดอนตาล",
    districtEn: "Don Tan",
    subdistrictTh: "บ้านแก้ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49120,
    district_code: 4903,
    province_code: 37,
    name: "ดอนตาล บ้านแก้ง มุกดาหาร 49120",
    name_th: "ดอนตาล บ้านแก้ง มุกดาหาร 49120",
    name_en: "Don Tan, Ban Kaeng, Mukdahan, 49120",
  },
  {
    id: 490401,
    districtTh: "ดงหลวง",
    districtEn: "Dong Luang",
    subdistrictTh: "ดงหลวง",
    subdistrictEn: "Dong Luang",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49140,
    district_code: 4904,
    province_code: 37,
    name: "ดงหลวง ดงหลวง มุกดาหาร 49140",
    name_th: "ดงหลวง ดงหลวง มุกดาหาร 49140",
    name_en: "Dong Luang, Dong Luang, Mukdahan, 49140",
  },
  {
    id: 490402,
    districtTh: "ดงหลวง",
    districtEn: "Dong Luang",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49140,
    district_code: 4904,
    province_code: 37,
    name: "ดงหลวง หนองบัว มุกดาหาร 49140",
    name_th: "ดงหลวง หนองบัว มุกดาหาร 49140",
    name_en: "Dong Luang, Nong Bua, Mukdahan, 49140",
  },
  {
    id: 490403,
    districtTh: "ดงหลวง",
    districtEn: "Dong Luang",
    subdistrictTh: "กกตูม",
    subdistrictEn: "Kok Tum",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49140,
    district_code: 4904,
    province_code: 37,
    name: "ดงหลวง กกตูม มุกดาหาร 49140",
    name_th: "ดงหลวง กกตูม มุกดาหาร 49140",
    name_en: "Dong Luang, Kok Tum, Mukdahan, 49140",
  },
  {
    id: 490404,
    districtTh: "ดงหลวง",
    districtEn: "Dong Luang",
    subdistrictTh: "หนองแคน",
    subdistrictEn: "Nong Khaen",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49140,
    district_code: 4904,
    province_code: 37,
    name: "ดงหลวง หนองแคน มุกดาหาร 49140",
    name_th: "ดงหลวง หนองแคน มุกดาหาร 49140",
    name_en: "Dong Luang, Nong Khaen, Mukdahan, 49140",
  },
  {
    id: 490405,
    districtTh: "ดงหลวง",
    districtEn: "Dong Luang",
    subdistrictTh: "ชะโนดน้อย",
    subdistrictEn: "Chanot Noi",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49140,
    district_code: 4904,
    province_code: 37,
    name: "ดงหลวง ชะโนดน้อย มุกดาหาร 49140",
    name_th: "ดงหลวง ชะโนดน้อย มุกดาหาร 49140",
    name_en: "Dong Luang, Chanot Noi, Mukdahan, 49140",
  },
  {
    id: 490406,
    districtTh: "ดงหลวง",
    districtEn: "Dong Luang",
    subdistrictTh: "พังแดง",
    subdistrictEn: "Phang Daeng",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49140,
    district_code: 4904,
    province_code: 37,
    name: "ดงหลวง พังแดง มุกดาหาร 49140",
    name_th: "ดงหลวง พังแดง มุกดาหาร 49140",
    name_en: "Dong Luang, Phang Daeng, Mukdahan, 49140",
  },
  {
    id: 490503,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "บ้านซ่ง",
    subdistrictEn: "Ban Song",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี บ้านซ่ง มุกดาหาร 49110",
    name_th: "คำชะอี บ้านซ่ง มุกดาหาร 49110",
    name_en: "Khamcha-i, Ban Song, Mukdahan, 49110",
  },
  {
    id: 490504,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "คำชะอี",
    subdistrictEn: "Khamcha-i",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี คำชะอี มุกดาหาร 49110",
    name_th: "คำชะอี คำชะอี มุกดาหาร 49110",
    name_en: "Khamcha-i, Khamcha-i, Mukdahan, 49110",
  },
  {
    id: 490505,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "หนองเอี่ยน",
    subdistrictEn: "(Nong Ian",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี หนองเอี่ยน มุกดาหาร 49110",
    name_th: "คำชะอี หนองเอี่ยน มุกดาหาร 49110",
    name_en: "Khamcha-i, (Nong Ian, Mukdahan, 49110",
  },
  {
    id: 490506,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "บ้านค้อ",
    subdistrictEn: "Ban Kho",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี บ้านค้อ มุกดาหาร 49110",
    name_th: "คำชะอี บ้านค้อ มุกดาหาร 49110",
    name_en: "Khamcha-i, Ban Kho, Mukdahan, 49110",
  },
  {
    id: 490507,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "บ้านเหล่า",
    subdistrictEn: "Ban Lao",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี บ้านเหล่า มุกดาหาร 49110",
    name_th: "คำชะอี บ้านเหล่า มุกดาหาร 49110",
    name_en: "Khamcha-i, Ban Lao, Mukdahan, 49110",
  },
  {
    id: 490508,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "โพนงาม",
    subdistrictEn: "Phon Ngam",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี โพนงาม มุกดาหาร 49110",
    name_th: "คำชะอี โพนงาม มุกดาหาร 49110",
    name_en: "Khamcha-i, Phon Ngam, Mukdahan, 49110",
  },
  {
    id: 490511,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "เหล่าสร้างถ่อ",
    subdistrictEn: "Lao Sang Tho",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี เหล่าสร้างถ่อ มุกดาหาร 49110",
    name_th: "คำชะอี เหล่าสร้างถ่อ มุกดาหาร 49110",
    name_en: "Khamcha-i, Lao Sang Tho, Mukdahan, 49110",
  },
  {
    id: 490512,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "คำบก",
    subdistrictEn: "Kham Bok",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี คำบก มุกดาหาร 49110",
    name_th: "คำชะอี คำบก มุกดาหาร 49110",
    name_en: "Khamcha-i, Kham Bok, Mukdahan, 49110",
  },
  {
    id: 490514,
    districtTh: "คำชะอี",
    districtEn: "Khamcha-i",
    subdistrictTh: "น้ำเที่ยง",
    subdistrictEn: "Nam Thiang",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49110,
    district_code: 4905,
    province_code: 37,
    name: "คำชะอี น้ำเที่ยง มุกดาหาร 49110",
    name_th: "คำชะอี น้ำเที่ยง มุกดาหาร 49110",
    name_en: "Khamcha-i, Nam Thiang, Mukdahan, 49110",
  },
  {
    id: 490601,
    districtTh: "หว้านใหญ่",
    districtEn: "Wan Yai",
    subdistrictTh: "หว้านใหญ่",
    subdistrictEn: "Wan Yai",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49150,
    district_code: 4906,
    province_code: 37,
    name: "หว้านใหญ่ หว้านใหญ่ มุกดาหาร 49150",
    name_th: "หว้านใหญ่ หว้านใหญ่ มุกดาหาร 49150",
    name_en: "Wan Yai, Wan Yai, Mukdahan, 49150",
  },
  {
    id: 490602,
    districtTh: "หว้านใหญ่",
    districtEn: "Wan Yai",
    subdistrictTh: "ป่งขาม",
    subdistrictEn: "Pong Kham",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49150,
    district_code: 4906,
    province_code: 37,
    name: "หว้านใหญ่ ป่งขาม มุกดาหาร 49150",
    name_th: "หว้านใหญ่ ป่งขาม มุกดาหาร 49150",
    name_en: "Wan Yai, Pong Kham, Mukdahan, 49150",
  },
  {
    id: 490603,
    districtTh: "หว้านใหญ่",
    districtEn: "Wan Yai",
    subdistrictTh: "บางทรายน้อย",
    subdistrictEn: "Bang Sai Noi",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49150,
    district_code: 4906,
    province_code: 37,
    name: "หว้านใหญ่ บางทรายน้อย มุกดาหาร 49150",
    name_th: "หว้านใหญ่ บางทรายน้อย มุกดาหาร 49150",
    name_en: "Wan Yai, Bang Sai Noi, Mukdahan, 49150",
  },
  {
    id: 490604,
    districtTh: "หว้านใหญ่",
    districtEn: "Wan Yai",
    subdistrictTh: "ชะโนด",
    subdistrictEn: "Chanot",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49150,
    district_code: 4906,
    province_code: 37,
    name: "หว้านใหญ่ ชะโนด มุกดาหาร 49150",
    name_th: "หว้านใหญ่ ชะโนด มุกดาหาร 49150",
    name_en: "Wan Yai, Chanot, Mukdahan, 49150",
  },
  {
    id: 490605,
    districtTh: "หว้านใหญ่",
    districtEn: "Wan Yai",
    subdistrictTh: "ดงหมู",
    subdistrictEn: "Dong Mu",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49150,
    district_code: 4906,
    province_code: 37,
    name: "หว้านใหญ่ ดงหมู มุกดาหาร 49150",
    name_th: "หว้านใหญ่ ดงหมู มุกดาหาร 49150",
    name_en: "Wan Yai, Dong Mu, Mukdahan, 49150",
  },
  {
    id: 490701,
    districtTh: "หนองสูง",
    districtEn: "Nong Sung",
    subdistrictTh: "หนองสูง",
    subdistrictEn: "Nong Sung",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49160,
    district_code: 4907,
    province_code: 37,
    name: "หนองสูง หนองสูง มุกดาหาร 49160",
    name_th: "หนองสูง หนองสูง มุกดาหาร 49160",
    name_en: "Nong Sung, Nong Sung, Mukdahan, 49160",
  },
  {
    id: 490702,
    districtTh: "หนองสูง",
    districtEn: "Nong Sung",
    subdistrictTh: "โนนยาง",
    subdistrictEn: "Non Yang",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49160,
    district_code: 4907,
    province_code: 37,
    name: "หนองสูง โนนยาง มุกดาหาร 49160",
    name_th: "หนองสูง โนนยาง มุกดาหาร 49160",
    name_en: "Nong Sung, Non Yang, Mukdahan, 49160",
  },
  {
    id: 490703,
    districtTh: "หนองสูง",
    districtEn: "Nong Sung",
    subdistrictTh: "ภูวง",
    subdistrictEn: "Phu Wong",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49160,
    district_code: 4907,
    province_code: 37,
    name: "หนองสูง ภูวง มุกดาหาร 49160",
    name_th: "หนองสูง ภูวง มุกดาหาร 49160",
    name_en: "Nong Sung, Phu Wong, Mukdahan, 49160",
  },
  {
    id: 490704,
    districtTh: "หนองสูง",
    districtEn: "Nong Sung",
    subdistrictTh: "บ้านเป้า",
    subdistrictEn: "Ban Pao",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49160,
    district_code: 4907,
    province_code: 37,
    name: "หนองสูง บ้านเป้า มุกดาหาร 49160",
    name_th: "หนองสูง บ้านเป้า มุกดาหาร 49160",
    name_en: "Nong Sung, Ban Pao, Mukdahan, 49160",
  },
  {
    id: 490705,
    districtTh: "หนองสูง",
    districtEn: "Nong Sung",
    subdistrictTh: "หนองสูงใต้",
    subdistrictEn: "Nong Sung Tai",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49160,
    district_code: 4907,
    province_code: 37,
    name: "หนองสูง หนองสูงใต้ มุกดาหาร 49160",
    name_th: "หนองสูง หนองสูงใต้ มุกดาหาร 49160",
    name_en: "Nong Sung, Nong Sung Tai, Mukdahan, 49160",
  },
  {
    id: 490706,
    districtTh: "หนองสูง",
    districtEn: "Nong Sung",
    subdistrictTh: "หนองสูงเหนือ",
    subdistrictEn: "Nong Sung Nuea",
    provinceTh: "มุกดาหาร",
    provinceEn: "Mukdahan",
    zipcode: 49160,
    district_code: 4907,
    province_code: 37,
    name: "หนองสูง หนองสูงเหนือ มุกดาหาร 49160",
    name_th: "หนองสูง หนองสูงเหนือ มุกดาหาร 49160",
    name_en: "Nong Sung, Nong Sung Nuea, Mukdahan, 49160",
  },
  {
    id: 500101,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "ศรีภูมิ",
    subdistrictEn: "Si Phum",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50200,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ ศรีภูมิ เชียงใหม่ 50200",
    name_th: "เมืองเชียงใหม่ ศรีภูมิ เชียงใหม่ 50200",
    name_en: "Mueang Chiang Mai, Si Phum, Chiang Mai, 50200",
  },
  {
    id: 500102,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "พระสิงห์",
    subdistrictEn: "Phra Sing",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50200,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ พระสิงห์ เชียงใหม่ 50200",
    name_th: "เมืองเชียงใหม่ พระสิงห์ เชียงใหม่ 50200",
    name_en: "Mueang Chiang Mai, Phra Sing, Chiang Mai, 50200",
  },
  {
    id: 500103,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "หายยา",
    subdistrictEn: "Haiya",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50100,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ หายยา เชียงใหม่ 50100",
    name_th: "เมืองเชียงใหม่ หายยา เชียงใหม่ 50100",
    name_en: "Mueang Chiang Mai, Haiya, Chiang Mai, 50100",
  },
  {
    id: 500104,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "ช้างม่อย",
    subdistrictEn: "Chang Moi",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50300,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ ช้างม่อย เชียงใหม่ 50300",
    name_th: "เมืองเชียงใหม่ ช้างม่อย เชียงใหม่ 50300",
    name_en: "Mueang Chiang Mai, Chang Moi, Chiang Mai, 50300",
  },
  {
    id: 500105,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "ช้างคลาน",
    subdistrictEn: "Chang Khlan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50100,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ ช้างคลาน เชียงใหม่ 50100",
    name_th: "เมืองเชียงใหม่ ช้างคลาน เชียงใหม่ 50100",
    name_en: "Mueang Chiang Mai, Chang Khlan, Chiang Mai, 50100",
  },
  {
    id: 500106,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "วัดเกต",
    subdistrictEn: "Wat Ket",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50000,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ วัดเกต เชียงใหม่ 50000",
    name_th: "เมืองเชียงใหม่ วัดเกต เชียงใหม่ 50000",
    name_en: "Mueang Chiang Mai, Wat Ket, Chiang Mai, 50000",
  },
  {
    id: 500107,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "ช้างเผือก",
    subdistrictEn: "Chang Phueak",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50300,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ ช้างเผือก เชียงใหม่ 50300",
    name_th: "เมืองเชียงใหม่ ช้างเผือก เชียงใหม่ 50300",
    name_en: "Mueang Chiang Mai, Chang Phueak, Chiang Mai, 50300",
  },
  {
    id: 500108,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "สุเทพ",
    subdistrictEn: "Suthep",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50200,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ สุเทพ เชียงใหม่ 50200",
    name_th: "เมืองเชียงใหม่ สุเทพ เชียงใหม่ 50200",
    name_en: "Mueang Chiang Mai, Suthep, Chiang Mai, 50200",
  },
  {
    id: 500109,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "แม่เหียะ",
    subdistrictEn: "Mae Hia",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50100,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ แม่เหียะ เชียงใหม่ 50100",
    name_th: "เมืองเชียงใหม่ แม่เหียะ เชียงใหม่ 50100",
    name_en: "Mueang Chiang Mai, Mae Hia, Chiang Mai, 50100",
  },
  {
    id: 500110,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "ป่าแดด",
    subdistrictEn: "Pa Daet",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50100,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ ป่าแดด เชียงใหม่ 50100",
    name_th: "เมืองเชียงใหม่ ป่าแดด เชียงใหม่ 50100",
    name_en: "Mueang Chiang Mai, Pa Daet, Chiang Mai, 50100",
  },
  {
    id: 500111,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "หนองหอย",
    subdistrictEn: "Nong Hoi",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50000,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ หนองหอย เชียงใหม่ 50000",
    name_th: "เมืองเชียงใหม่ หนองหอย เชียงใหม่ 50000",
    name_en: "Mueang Chiang Mai, Nong Hoi, Chiang Mai, 50000",
  },
  {
    id: 500112,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "ท่าศาลา",
    subdistrictEn: "Tha Sala",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50000,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ ท่าศาลา เชียงใหม่ 50000",
    name_th: "เมืองเชียงใหม่ ท่าศาลา เชียงใหม่ 50000",
    name_en: "Mueang Chiang Mai, Tha Sala, Chiang Mai, 50000",
  },
  {
    id: 500113,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "หนองป่าครั่ง",
    subdistrictEn: "Nong Pa Khrang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50000,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ หนองป่าครั่ง เชียงใหม่ 50000",
    name_th: "เมืองเชียงใหม่ หนองป่าครั่ง เชียงใหม่ 50000",
    name_en: "Mueang Chiang Mai, Nong Pa Khrang, Chiang Mai, 50000",
  },
  {
    id: 500114,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "ฟ้าฮ่าม",
    subdistrictEn: "Fa Ham",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50000,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ ฟ้าฮ่าม เชียงใหม่ 50000",
    name_th: "เมืองเชียงใหม่ ฟ้าฮ่าม เชียงใหม่ 50000",
    name_en: "Mueang Chiang Mai, Fa Ham, Chiang Mai, 50000",
  },
  {
    id: 500115,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "ป่าตัน",
    subdistrictEn: "Pa Tan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50300,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ ป่าตัน เชียงใหม่ 50300",
    name_th: "เมืองเชียงใหม่ ป่าตัน เชียงใหม่ 50300",
    name_en: "Mueang Chiang Mai, Pa Tan, Chiang Mai, 50300",
  },
  {
    id: 500116,
    districtTh: "เมืองเชียงใหม่",
    districtEn: "Mueang Chiang Mai",
    subdistrictTh: "สันผีเสื้อ",
    subdistrictEn: "San Phi Suea",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50300,
    district_code: 5001,
    province_code: 38,
    name: "เมืองเชียงใหม่ สันผีเสื้อ เชียงใหม่ 50300",
    name_th: "เมืองเชียงใหม่ สันผีเสื้อ เชียงใหม่ 50300",
    name_en: "Mueang Chiang Mai, San Phi Suea, Chiang Mai, 50300",
  },
  {
    id: 500203,
    districtTh: "จอมทอง",
    districtEn: "Chom Thong",
    subdistrictTh: "บ้านหลวง",
    subdistrictEn: "Ban Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50160,
    district_code: 5002,
    province_code: 38,
    name: "จอมทอง บ้านหลวง เชียงใหม่ 50160",
    name_th: "จอมทอง บ้านหลวง เชียงใหม่ 50160",
    name_en: "Chom Thong, Ban Luang, Chiang Mai, 50160",
  },
  {
    id: 500204,
    districtTh: "จอมทอง",
    districtEn: "Chom Thong",
    subdistrictTh: "ข่วงเปา",
    subdistrictEn: "Khuang Pao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50160,
    district_code: 5002,
    province_code: 38,
    name: "จอมทอง ข่วงเปา เชียงใหม่ 50160",
    name_th: "จอมทอง ข่วงเปา เชียงใหม่ 50160",
    name_en: "Chom Thong, Khuang Pao, Chiang Mai, 50160",
  },
  {
    id: 500205,
    districtTh: "จอมทอง",
    districtEn: "Chom Thong",
    subdistrictTh: "สบเตี๊ยะ",
    subdistrictEn: "Sop Tia",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50160,
    district_code: 5002,
    province_code: 38,
    name: "จอมทอง สบเตี๊ยะ เชียงใหม่ 50160",
    name_th: "จอมทอง สบเตี๊ยะ เชียงใหม่ 50160",
    name_en: "Chom Thong, Sop Tia, Chiang Mai, 50160",
  },
  {
    id: 500206,
    districtTh: "จอมทอง",
    districtEn: "Chom Thong",
    subdistrictTh: "บ้านแปะ",
    subdistrictEn: "Ban Pae",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50240,
    district_code: 5002,
    province_code: 38,
    name: "จอมทอง บ้านแปะ เชียงใหม่ 50240",
    name_th: "จอมทอง บ้านแปะ เชียงใหม่ 50240",
    name_en: "Chom Thong, Ban Pae, Chiang Mai, 50240",
  },
  {
    id: 500207,
    districtTh: "จอมทอง",
    districtEn: "Chom Thong",
    subdistrictTh: "ดอยแก้ว",
    subdistrictEn: "Doi Kaeo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50160,
    district_code: 5002,
    province_code: 38,
    name: "จอมทอง ดอยแก้ว เชียงใหม่ 50160",
    name_th: "จอมทอง ดอยแก้ว เชียงใหม่ 50160",
    name_en: "Chom Thong, Doi Kaeo, Chiang Mai, 50160",
  },
  {
    id: 500209,
    districtTh: "จอมทอง",
    districtEn: "Chom Thong",
    subdistrictTh: "แม่สอย",
    subdistrictEn: "Mae Soi",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50240,
    district_code: 5002,
    province_code: 38,
    name: "จอมทอง แม่สอย เชียงใหม่ 50240",
    name_th: "จอมทอง แม่สอย เชียงใหม่ 50240",
    name_en: "Chom Thong, Mae Soi, Chiang Mai, 50240",
  },
  {
    id: 500301,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "ช่างเคิ่ง",
    subdistrictEn: "Chang Khoeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50270,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม ช่างเคิ่ง เชียงใหม่ 50270",
    name_th: "แม่แจ่ม ช่างเคิ่ง เชียงใหม่ 50270",
    name_en: "Mae Chaem, Chang Khoeng, Chiang Mai, 50270",
  },
  {
    id: 500302,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "ท่าผา",
    subdistrictEn: "Tha Pha",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50270,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม ท่าผา เชียงใหม่ 50270",
    name_th: "แม่แจ่ม ท่าผา เชียงใหม่ 50270",
    name_en: "Mae Chaem, Tha Pha, Chiang Mai, 50270",
  },
  {
    id: 500303,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "บ้านทับ",
    subdistrictEn: "Ban Thap",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50270,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม บ้านทับ เชียงใหม่ 50270",
    name_th: "แม่แจ่ม บ้านทับ เชียงใหม่ 50270",
    name_en: "Mae Chaem, Ban Thap, Chiang Mai, 50270",
  },
  {
    id: 500304,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "แม่ศึก",
    subdistrictEn: "Mae Suek",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50270,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม แม่ศึก เชียงใหม่ 50270",
    name_th: "แม่แจ่ม แม่ศึก เชียงใหม่ 50270",
    name_en: "Mae Chaem, Mae Suek, Chiang Mai, 50270",
  },
  {
    id: 500305,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "แม่นาจร",
    subdistrictEn: "Mae Na Chon",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50270,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม แม่นาจร เชียงใหม่ 50270",
    name_th: "แม่แจ่ม แม่นาจร เชียงใหม่ 50270",
    name_en: "Mae Chaem, Mae Na Chon, Chiang Mai, 50270",
  },
  {
    id: 500306,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "บ้านจันทร์",
    subdistrictEn: "Ban Chan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 58130,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม บ้านจันทร์ เชียงใหม่ 58130",
    name_th: "แม่แจ่ม บ้านจันทร์ เชียงใหม่ 58130",
    name_en: "Mae Chaem, Ban Chan, Chiang Mai, 58130",
  },
  {
    id: 500307,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "ปางหินฝน",
    subdistrictEn: "Pang Hin Fon",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50270,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม ปางหินฝน เชียงใหม่ 50270",
    name_th: "แม่แจ่ม ปางหินฝน เชียงใหม่ 50270",
    name_en: "Mae Chaem, Pang Hin Fon, Chiang Mai, 50270",
  },
  {
    id: 500308,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "กองแขก",
    subdistrictEn: "Kong Khaek",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50270,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม กองแขก เชียงใหม่ 50270",
    name_th: "แม่แจ่ม กองแขก เชียงใหม่ 50270",
    name_en: "Mae Chaem, Kong Khaek, Chiang Mai, 50270",
  },
  {
    id: 500309,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "แม่แดด",
    subdistrictEn: "Mae Dad",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 58130,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม แม่แดด เชียงใหม่ 58130",
    name_th: "แม่แจ่ม แม่แดด เชียงใหม่ 58130",
    name_en: "Mae Chaem, Mae Dad, Chiang Mai, 58130",
  },
  {
    id: 500310,
    districtTh: "แม่แจ่ม",
    districtEn: "Mae Chaem",
    subdistrictTh: "แจ่มหลวง",
    subdistrictEn: "Chaem Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 58130,
    district_code: 5003,
    province_code: 38,
    name: "แม่แจ่ม แจ่มหลวง เชียงใหม่ 58130",
    name_th: "แม่แจ่ม แจ่มหลวง เชียงใหม่ 58130",
    name_en: "Mae Chaem, Chaem Luang, Chiang Mai, 58130",
  },
  {
    id: 500401,
    districtTh: "เชียงดาว",
    districtEn: "Chiang Dao",
    subdistrictTh: "เชียงดาว",
    subdistrictEn: "Chiang Dao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50170,
    district_code: 5004,
    province_code: 38,
    name: "เชียงดาว เชียงดาว เชียงใหม่ 50170",
    name_th: "เชียงดาว เชียงดาว เชียงใหม่ 50170",
    name_en: "Chiang Dao, Chiang Dao, Chiang Mai, 50170",
  },
  {
    id: 500402,
    districtTh: "เชียงดาว",
    districtEn: "Chiang Dao",
    subdistrictTh: "เมืองนะ",
    subdistrictEn: "Mueang Na",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50170,
    district_code: 5004,
    province_code: 38,
    name: "เชียงดาว เมืองนะ เชียงใหม่ 50170",
    name_th: "เชียงดาว เมืองนะ เชียงใหม่ 50170",
    name_en: "Chiang Dao, Mueang Na, Chiang Mai, 50170",
  },
  {
    id: 500403,
    districtTh: "เชียงดาว",
    districtEn: "Chiang Dao",
    subdistrictTh: "เมืองงาย",
    subdistrictEn: "Mueang Ngai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50170,
    district_code: 5004,
    province_code: 38,
    name: "เชียงดาว เมืองงาย เชียงใหม่ 50170",
    name_th: "เชียงดาว เมืองงาย เชียงใหม่ 50170",
    name_en: "Chiang Dao, Mueang Ngai, Chiang Mai, 50170",
  },
  {
    id: 500404,
    districtTh: "เชียงดาว",
    districtEn: "Chiang Dao",
    subdistrictTh: "แม่นะ",
    subdistrictEn: "Mae Na",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50170,
    district_code: 5004,
    province_code: 38,
    name: "เชียงดาว แม่นะ เชียงใหม่ 50170",
    name_th: "เชียงดาว แม่นะ เชียงใหม่ 50170",
    name_en: "Chiang Dao, Mae Na, Chiang Mai, 50170",
  },
  {
    id: 500405,
    districtTh: "เชียงดาว",
    districtEn: "Chiang Dao",
    subdistrictTh: "เมืองคอง",
    subdistrictEn: "Mueang Khong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50170,
    district_code: 5004,
    province_code: 38,
    name: "เชียงดาว เมืองคอง เชียงใหม่ 50170",
    name_th: "เชียงดาว เมืองคอง เชียงใหม่ 50170",
    name_en: "Chiang Dao, Mueang Khong, Chiang Mai, 50170",
  },
  {
    id: 500406,
    districtTh: "เชียงดาว",
    districtEn: "Chiang Dao",
    subdistrictTh: "ปิงโค้ง",
    subdistrictEn: "Ping Khong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50170,
    district_code: 5004,
    province_code: 38,
    name: "เชียงดาว ปิงโค้ง เชียงใหม่ 50170",
    name_th: "เชียงดาว ปิงโค้ง เชียงใหม่ 50170",
    name_en: "Chiang Dao, Ping Khong, Chiang Mai, 50170",
  },
  {
    id: 500407,
    districtTh: "เชียงดาว",
    districtEn: "Chiang Dao",
    subdistrictTh: "ทุ่งข้าวพวง",
    subdistrictEn: "Thung Khao Phuang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50170,
    district_code: 5004,
    province_code: 38,
    name: "เชียงดาว ทุ่งข้าวพวง เชียงใหม่ 50170",
    name_th: "เชียงดาว ทุ่งข้าวพวง เชียงใหม่ 50170",
    name_en: "Chiang Dao, Thung Khao Phuang, Chiang Mai, 50170",
  },
  {
    id: 500501,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "เชิงดอย",
    subdistrictEn: "Choeng Doi",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด เชิงดอย เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด เชิงดอย เชียงใหม่ 50220",
    name_en: "Doi Saket, Choeng Doi, Chiang Mai, 50220",
  },
  {
    id: 500502,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "สันปูเลย",
    subdistrictEn: "San Pu Loei",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด สันปูเลย เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด สันปูเลย เชียงใหม่ 50220",
    name_en: "Doi Saket, San Pu Loei, Chiang Mai, 50220",
  },
  {
    id: 500503,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "ลวงเหนือ",
    subdistrictEn: "Luang Nuea",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด ลวงเหนือ เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด ลวงเหนือ เชียงใหม่ 50220",
    name_en: "Doi Saket, Luang Nuea, Chiang Mai, 50220",
  },
  {
    id: 500504,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "ป่าป้อง",
    subdistrictEn: "Pa Pong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด ป่าป้อง เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด ป่าป้อง เชียงใหม่ 50220",
    name_en: "Doi Saket, Pa Pong, Chiang Mai, 50220",
  },
  {
    id: 500505,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "สง่าบ้าน",
    subdistrictEn: "Sa-nga Ban",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด สง่าบ้าน เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด สง่าบ้าน เชียงใหม่ 50220",
    name_en: "Doi Saket, Sa-nga Ban, Chiang Mai, 50220",
  },
  {
    id: 500506,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "ป่าลาน",
    subdistrictEn: "Pa Lan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด ป่าลาน เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด ป่าลาน เชียงใหม่ 50220",
    name_en: "Doi Saket, Pa Lan, Chiang Mai, 50220",
  },
  {
    id: 500507,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "ตลาดขวัญ",
    subdistrictEn: "Talat Khwan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด ตลาดขวัญ เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด ตลาดขวัญ เชียงใหม่ 50220",
    name_en: "Doi Saket, Talat Khwan, Chiang Mai, 50220",
  },
  {
    id: 500508,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "สำราญราษฎร์",
    subdistrictEn: "Samran Rat",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด สำราญราษฎร์ เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด สำราญราษฎร์ เชียงใหม่ 50220",
    name_en: "Doi Saket, Samran Rat, Chiang Mai, 50220",
  },
  {
    id: 500509,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "แม่คือ",
    subdistrictEn: "Mae Khue",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด แม่คือ เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด แม่คือ เชียงใหม่ 50220",
    name_en: "Doi Saket, Mae Khue, Chiang Mai, 50220",
  },
  {
    id: 500510,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "ตลาดใหญ่",
    subdistrictEn: "Talat Yai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด ตลาดใหญ่ เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด ตลาดใหญ่ เชียงใหม่ 50220",
    name_en: "Doi Saket, Talat Yai, Chiang Mai, 50220",
  },
  {
    id: 500511,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "แม่ฮ้อยเงิน",
    subdistrictEn: "Mae Hoi Ngoen",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด แม่ฮ้อยเงิน เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด แม่ฮ้อยเงิน เชียงใหม่ 50220",
    name_en: "Doi Saket, Mae Hoi Ngoen, Chiang Mai, 50220",
  },
  {
    id: 500512,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "แม่โป่ง",
    subdistrictEn: "Mae Pong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด แม่โป่ง เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด แม่โป่ง เชียงใหม่ 50220",
    name_en: "Doi Saket, Mae Pong, Chiang Mai, 50220",
  },
  {
    id: 500513,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "ป่าเมี่ยง",
    subdistrictEn: "Pa Miang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด ป่าเมี่ยง เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด ป่าเมี่ยง เชียงใหม่ 50220",
    name_en: "Doi Saket, Pa Miang, Chiang Mai, 50220",
  },
  {
    id: 500514,
    districtTh: "ดอยสะเก็ด",
    districtEn: "Doi Saket",
    subdistrictTh: "เทพเสด็จ",
    subdistrictEn: "Thep Sadet",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50220,
    district_code: 5005,
    province_code: 38,
    name: "ดอยสะเก็ด เทพเสด็จ เชียงใหม่ 50220",
    name_th: "ดอยสะเก็ด เทพเสด็จ เชียงใหม่ 50220",
    name_en: "Doi Saket, Thep Sadet, Chiang Mai, 50220",
  },
  {
    id: 500601,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "สันมหาพน",
    subdistrictEn: "San Maha Phon",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง สันมหาพน เชียงใหม่ 50150",
    name_th: "แม่แตง สันมหาพน เชียงใหม่ 50150",
    name_en: "Mae Taeng, San Maha Phon, Chiang Mai, 50150",
  },
  {
    id: 500602,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "แม่แตง",
    subdistrictEn: "Mae Taeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง แม่แตง เชียงใหม่ 50150",
    name_th: "แม่แตง แม่แตง เชียงใหม่ 50150",
    name_en: "Mae Taeng, Mae Taeng, Chiang Mai, 50150",
  },
  {
    id: 500603,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "ขี้เหล็ก",
    subdistrictEn: "Khilek",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง ขี้เหล็ก เชียงใหม่ 50150",
    name_th: "แม่แตง ขี้เหล็ก เชียงใหม่ 50150",
    name_en: "Mae Taeng, Khilek, Chiang Mai, 50150",
  },
  {
    id: 500604,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "ช่อแล",
    subdistrictEn: "Cho Lae",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง ช่อแล เชียงใหม่ 50150",
    name_th: "แม่แตง ช่อแล เชียงใหม่ 50150",
    name_en: "Mae Taeng, Cho Lae, Chiang Mai, 50150",
  },
  {
    id: 500605,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "แม่หอพระ",
    subdistrictEn: "Mae Ho Phra",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง แม่หอพระ เชียงใหม่ 50150",
    name_th: "แม่แตง แม่หอพระ เชียงใหม่ 50150",
    name_en: "Mae Taeng, Mae Ho Phra, Chiang Mai, 50150",
  },
  {
    id: 500606,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "สบเปิง",
    subdistrictEn: "Sop Poeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง สบเปิง เชียงใหม่ 50150",
    name_th: "แม่แตง สบเปิง เชียงใหม่ 50150",
    name_en: "Mae Taeng, Sop Poeng, Chiang Mai, 50150",
  },
  {
    id: 500607,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "บ้านเป้า",
    subdistrictEn: "Ban Pao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง บ้านเป้า เชียงใหม่ 50150",
    name_th: "แม่แตง บ้านเป้า เชียงใหม่ 50150",
    name_en: "Mae Taeng, Ban Pao, Chiang Mai, 50150",
  },
  {
    id: 500608,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "สันป่ายาง",
    subdistrictEn: "San Pa Yang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50330,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง สันป่ายาง เชียงใหม่ 50330",
    name_th: "แม่แตง สันป่ายาง เชียงใหม่ 50330",
    name_en: "Mae Taeng, San Pa Yang, Chiang Mai, 50330",
  },
  {
    id: 500609,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "ป่าแป๋",
    subdistrictEn: "Pa Pae",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง ป่าแป๋ เชียงใหม่ 50150",
    name_th: "แม่แตง ป่าแป๋ เชียงใหม่ 50150",
    name_en: "Mae Taeng, Pa Pae, Chiang Mai, 50150",
  },
  {
    id: 500610,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "เมืองก๋าย",
    subdistrictEn: "Mueang Kai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง เมืองก๋าย เชียงใหม่ 50150",
    name_th: "แม่แตง เมืองก๋าย เชียงใหม่ 50150",
    name_en: "Mae Taeng, Mueang Kai, Chiang Mai, 50150",
  },
  {
    id: 500611,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "บ้านช้าง",
    subdistrictEn: "Ban Chang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง บ้านช้าง เชียงใหม่ 50150",
    name_th: "แม่แตง บ้านช้าง เชียงใหม่ 50150",
    name_en: "Mae Taeng, Ban Chang, Chiang Mai, 50150",
  },
  {
    id: 500612,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "กื้ดช้าง",
    subdistrictEn: "Kuet Chang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง กื้ดช้าง เชียงใหม่ 50150",
    name_th: "แม่แตง กื้ดช้าง เชียงใหม่ 50150",
    name_en: "Mae Taeng, Kuet Chang, Chiang Mai, 50150",
  },
  {
    id: 500613,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "อินทขิล",
    subdistrictEn: "Inthakhin",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง อินทขิล เชียงใหม่ 50150",
    name_th: "แม่แตง อินทขิล เชียงใหม่ 50150",
    name_en: "Mae Taeng, Inthakhin, Chiang Mai, 50150",
  },
  {
    id: 500614,
    districtTh: "แม่แตง",
    districtEn: "Mae Taeng",
    subdistrictTh: "สมก๋าย",
    subdistrictEn: "Som Kai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50150,
    district_code: 5006,
    province_code: 38,
    name: "แม่แตง สมก๋าย เชียงใหม่ 50150",
    name_th: "แม่แตง สมก๋าย เชียงใหม่ 50150",
    name_en: "Mae Taeng, Som Kai, Chiang Mai, 50150",
  },
  {
    id: 500701,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "ริมใต้",
    subdistrictEn: "Rim Tai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม ริมใต้ เชียงใหม่ 50180",
    name_th: "แม่ริม ริมใต้ เชียงใหม่ 50180",
    name_en: "Mae Rim, Rim Tai, Chiang Mai, 50180",
  },
  {
    id: 500702,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "ริมเหนือ",
    subdistrictEn: "Rim Nuea",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม ริมเหนือ เชียงใหม่ 50180",
    name_th: "แม่ริม ริมเหนือ เชียงใหม่ 50180",
    name_en: "Mae Rim, Rim Nuea, Chiang Mai, 50180",
  },
  {
    id: 500703,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "สันโป่ง",
    subdistrictEn: "San Pong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม สันโป่ง เชียงใหม่ 50180",
    name_th: "แม่ริม สันโป่ง เชียงใหม่ 50180",
    name_en: "Mae Rim, San Pong, Chiang Mai, 50180",
  },
  {
    id: 500704,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "ขี้เหล็ก",
    subdistrictEn: "Khilek",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม ขี้เหล็ก เชียงใหม่ 50180",
    name_th: "แม่ริม ขี้เหล็ก เชียงใหม่ 50180",
    name_en: "Mae Rim, Khilek, Chiang Mai, 50180",
  },
  {
    id: 500705,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "สะลวง",
    subdistrictEn: "Saluang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50330,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม สะลวง เชียงใหม่ 50330",
    name_th: "แม่ริม สะลวง เชียงใหม่ 50330",
    name_en: "Mae Rim, Saluang, Chiang Mai, 50330",
  },
  {
    id: 500706,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "ห้วยทราย",
    subdistrictEn: "Huai Sai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม ห้วยทราย เชียงใหม่ 50180",
    name_th: "แม่ริม ห้วยทราย เชียงใหม่ 50180",
    name_en: "Mae Rim, Huai Sai, Chiang Mai, 50180",
  },
  {
    id: 500707,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "แม่แรม",
    subdistrictEn: "Mae Raem",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม แม่แรม เชียงใหม่ 50180",
    name_th: "แม่ริม แม่แรม เชียงใหม่ 50180",
    name_en: "Mae Rim, Mae Raem, Chiang Mai, 50180",
  },
  {
    id: 500708,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "โป่งแยง",
    subdistrictEn: "Pong Yaeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม โป่งแยง เชียงใหม่ 50180",
    name_th: "แม่ริม โป่งแยง เชียงใหม่ 50180",
    name_en: "Mae Rim, Pong Yaeng, Chiang Mai, 50180",
  },
  {
    id: 500709,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "แม่สา",
    subdistrictEn: "Mae Sa",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม แม่สา เชียงใหม่ 50180",
    name_th: "แม่ริม แม่สา เชียงใหม่ 50180",
    name_en: "Mae Rim, Mae Sa, Chiang Mai, 50180",
  },
  {
    id: 500710,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "ดอนแก้ว",
    subdistrictEn: "Don Kaeo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม ดอนแก้ว เชียงใหม่ 50180",
    name_th: "แม่ริม ดอนแก้ว เชียงใหม่ 50180",
    name_en: "Mae Rim, Don Kaeo, Chiang Mai, 50180",
  },
  {
    id: 500711,
    districtTh: "แม่ริม",
    districtEn: "Mae Rim",
    subdistrictTh: "เหมืองแก้ว",
    subdistrictEn: "Mueang Kaeo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50180,
    district_code: 5007,
    province_code: 38,
    name: "แม่ริม เหมืองแก้ว เชียงใหม่ 50180",
    name_th: "แม่ริม เหมืองแก้ว เชียงใหม่ 50180",
    name_en: "Mae Rim, Mueang Kaeo, Chiang Mai, 50180",
  },
  {
    id: 500801,
    districtTh: "สะเมิง",
    districtEn: "Samoeng",
    subdistrictTh: "สะเมิงใต้",
    subdistrictEn: "Samoeng Tai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50250,
    district_code: 5008,
    province_code: 38,
    name: "สะเมิง สะเมิงใต้ เชียงใหม่ 50250",
    name_th: "สะเมิง สะเมิงใต้ เชียงใหม่ 50250",
    name_en: "Samoeng, Samoeng Tai, Chiang Mai, 50250",
  },
  {
    id: 500802,
    districtTh: "สะเมิง",
    districtEn: "Samoeng",
    subdistrictTh: "สะเมิงเหนือ",
    subdistrictEn: "Samoeng Nuea",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50250,
    district_code: 5008,
    province_code: 38,
    name: "สะเมิง สะเมิงเหนือ เชียงใหม่ 50250",
    name_th: "สะเมิง สะเมิงเหนือ เชียงใหม่ 50250",
    name_en: "Samoeng, Samoeng Nuea, Chiang Mai, 50250",
  },
  {
    id: 500803,
    districtTh: "สะเมิง",
    districtEn: "Samoeng",
    subdistrictTh: "แม่สาบ",
    subdistrictEn: "Mae Sap",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50250,
    district_code: 5008,
    province_code: 38,
    name: "สะเมิง แม่สาบ เชียงใหม่ 50250",
    name_th: "สะเมิง แม่สาบ เชียงใหม่ 50250",
    name_en: "Samoeng, Mae Sap, Chiang Mai, 50250",
  },
  {
    id: 500804,
    districtTh: "สะเมิง",
    districtEn: "Samoeng",
    subdistrictTh: "บ่อแก้ว",
    subdistrictEn: "Bo Kaeo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50250,
    district_code: 5008,
    province_code: 38,
    name: "สะเมิง บ่อแก้ว เชียงใหม่ 50250",
    name_th: "สะเมิง บ่อแก้ว เชียงใหม่ 50250",
    name_en: "Samoeng, Bo Kaeo, Chiang Mai, 50250",
  },
  {
    id: 500805,
    districtTh: "สะเมิง",
    districtEn: "Samoeng",
    subdistrictTh: "ยั้งเมิน",
    subdistrictEn: "Yang Moen",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50250,
    district_code: 5008,
    province_code: 38,
    name: "สะเมิง ยั้งเมิน เชียงใหม่ 50250",
    name_th: "สะเมิง ยั้งเมิน เชียงใหม่ 50250",
    name_en: "Samoeng, Yang Moen, Chiang Mai, 50250",
  },
  {
    id: 500901,
    districtTh: "ฝาง",
    districtEn: "Fang",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50110,
    district_code: 5009,
    province_code: 38,
    name: "ฝาง เวียง เชียงใหม่ 50110",
    name_th: "ฝาง เวียง เชียงใหม่ 50110",
    name_en: "Fang, Wiang, Chiang Mai, 50110",
  },
  {
    id: 500903,
    districtTh: "ฝาง",
    districtEn: "Fang",
    subdistrictTh: "ม่อนปิ่น",
    subdistrictEn: "Mon Pin",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50110,
    district_code: 5009,
    province_code: 38,
    name: "ฝาง ม่อนปิ่น เชียงใหม่ 50110",
    name_th: "ฝาง ม่อนปิ่น เชียงใหม่ 50110",
    name_en: "Fang, Mon Pin, Chiang Mai, 50110",
  },
  {
    id: 500904,
    districtTh: "ฝาง",
    districtEn: "Fang",
    subdistrictTh: "แม่งอน",
    subdistrictEn: "Mae Ngon",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50320,
    district_code: 5009,
    province_code: 38,
    name: "ฝาง แม่งอน เชียงใหม่ 50320",
    name_th: "ฝาง แม่งอน เชียงใหม่ 50320",
    name_en: "Fang, Mae Ngon, Chiang Mai, 50320",
  },
  {
    id: 500905,
    districtTh: "ฝาง",
    districtEn: "Fang",
    subdistrictTh: "แม่สูน",
    subdistrictEn: "Mae Sun",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50110,
    district_code: 5009,
    province_code: 38,
    name: "ฝาง แม่สูน เชียงใหม่ 50110",
    name_th: "ฝาง แม่สูน เชียงใหม่ 50110",
    name_en: "Fang, Mae Sun, Chiang Mai, 50110",
  },
  {
    id: 500906,
    districtTh: "ฝาง",
    districtEn: "Fang",
    subdistrictTh: "สันทราย",
    subdistrictEn: "San Sai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50110,
    district_code: 5009,
    province_code: 38,
    name: "ฝาง สันทราย เชียงใหม่ 50110",
    name_th: "ฝาง สันทราย เชียงใหม่ 50110",
    name_en: "Fang, San Sai, Chiang Mai, 50110",
  },
  {
    id: 500910,
    districtTh: "ฝาง",
    districtEn: "Fang",
    subdistrictTh: "แม่คะ",
    subdistrictEn: "Mae Kha",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50110,
    district_code: 5009,
    province_code: 38,
    name: "ฝาง แม่คะ เชียงใหม่ 50110",
    name_th: "ฝาง แม่คะ เชียงใหม่ 50110",
    name_en: "Fang, Mae Kha, Chiang Mai, 50110",
  },
  {
    id: 500911,
    districtTh: "ฝาง",
    districtEn: "Fang",
    subdistrictTh: "แม่ข่า",
    subdistrictEn: "Mae Kha",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50320,
    district_code: 5009,
    province_code: 38,
    name: "ฝาง แม่ข่า เชียงใหม่ 50320",
    name_th: "ฝาง แม่ข่า เชียงใหม่ 50320",
    name_en: "Fang, Mae Kha, Chiang Mai, 50320",
  },
  {
    id: 500912,
    districtTh: "ฝาง",
    districtEn: "Fang",
    subdistrictTh: "โป่งน้ำร้อน",
    subdistrictEn: "Pong Nam Ron",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50110,
    district_code: 5009,
    province_code: 38,
    name: "ฝาง โป่งน้ำร้อน เชียงใหม่ 50110",
    name_th: "ฝาง โป่งน้ำร้อน เชียงใหม่ 50110",
    name_en: "Fang, Pong Nam Ron, Chiang Mai, 50110",
  },
  {
    id: 501001,
    districtTh: "แม่อาย",
    districtEn: "Mae Ai",
    subdistrictTh: "แม่อาย",
    subdistrictEn: "Mae Ai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50280,
    district_code: 5010,
    province_code: 38,
    name: "แม่อาย แม่อาย เชียงใหม่ 50280",
    name_th: "แม่อาย แม่อาย เชียงใหม่ 50280",
    name_en: "Mae Ai, Mae Ai, Chiang Mai, 50280",
  },
  {
    id: 501002,
    districtTh: "แม่อาย",
    districtEn: "Mae Ai",
    subdistrictTh: "แม่สาว",
    subdistrictEn: "Mae Sao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50280,
    district_code: 5010,
    province_code: 38,
    name: "แม่อาย แม่สาว เชียงใหม่ 50280",
    name_th: "แม่อาย แม่สาว เชียงใหม่ 50280",
    name_en: "Mae Ai, Mae Sao, Chiang Mai, 50280",
  },
  {
    id: 501003,
    districtTh: "แม่อาย",
    districtEn: "Mae Ai",
    subdistrictTh: "สันต้นหมื้อ",
    subdistrictEn: "San Ton Mue",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50280,
    district_code: 5010,
    province_code: 38,
    name: "แม่อาย สันต้นหมื้อ เชียงใหม่ 50280",
    name_th: "แม่อาย สันต้นหมื้อ เชียงใหม่ 50280",
    name_en: "Mae Ai, San Ton Mue, Chiang Mai, 50280",
  },
  {
    id: 501004,
    districtTh: "แม่อาย",
    districtEn: "Mae Ai",
    subdistrictTh: "แม่นาวาง",
    subdistrictEn: "Mae Na Wang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50280,
    district_code: 5010,
    province_code: 38,
    name: "แม่อาย แม่นาวาง เชียงใหม่ 50280",
    name_th: "แม่อาย แม่นาวาง เชียงใหม่ 50280",
    name_en: "Mae Ai, Mae Na Wang, Chiang Mai, 50280",
  },
  {
    id: 501005,
    districtTh: "แม่อาย",
    districtEn: "Mae Ai",
    subdistrictTh: "ท่าตอน",
    subdistrictEn: "Tha Ton",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50280,
    district_code: 5010,
    province_code: 38,
    name: "แม่อาย ท่าตอน เชียงใหม่ 50280",
    name_th: "แม่อาย ท่าตอน เชียงใหม่ 50280",
    name_en: "Mae Ai, Tha Ton, Chiang Mai, 50280",
  },
  {
    id: 501006,
    districtTh: "แม่อาย",
    districtEn: "Mae Ai",
    subdistrictTh: "บ้านหลวง",
    subdistrictEn: "Ban Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50280,
    district_code: 5010,
    province_code: 38,
    name: "แม่อาย บ้านหลวง เชียงใหม่ 50280",
    name_th: "แม่อาย บ้านหลวง เชียงใหม่ 50280",
    name_en: "Mae Ai, Ban Luang, Chiang Mai, 50280",
  },
  {
    id: 501007,
    districtTh: "แม่อาย",
    districtEn: "Mae Ai",
    subdistrictTh: "มะลิกา",
    subdistrictEn: "Malika",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50280,
    district_code: 5010,
    province_code: 38,
    name: "แม่อาย มะลิกา เชียงใหม่ 50280",
    name_th: "แม่อาย มะลิกา เชียงใหม่ 50280",
    name_en: "Mae Ai, Malika, Chiang Mai, 50280",
  },
  {
    id: 501101,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว เวียง เชียงใหม่ 50190",
    name_th: "พร้าว เวียง เชียงใหม่ 50190",
    name_en: "Phrao, Wiang, Chiang Mai, 50190",
  },
  {
    id: 501102,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "ทุ่งหลวง",
    subdistrictEn: "Thung Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว ทุ่งหลวง เชียงใหม่ 50190",
    name_th: "พร้าว ทุ่งหลวง เชียงใหม่ 50190",
    name_en: "Phrao, Thung Luang, Chiang Mai, 50190",
  },
  {
    id: 501103,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "ป่าตุ้ม",
    subdistrictEn: "Pa Tum",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว ป่าตุ้ม เชียงใหม่ 50190",
    name_th: "พร้าว ป่าตุ้ม เชียงใหม่ 50190",
    name_en: "Phrao, Pa Tum, Chiang Mai, 50190",
  },
  {
    id: 501104,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "ป่าไหน่",
    subdistrictEn: "Pa Nai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว ป่าไหน่ เชียงใหม่ 50190",
    name_th: "พร้าว ป่าไหน่ เชียงใหม่ 50190",
    name_en: "Phrao, Pa Nai, Chiang Mai, 50190",
  },
  {
    id: 501105,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "สันทราย",
    subdistrictEn: "San Sai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว สันทราย เชียงใหม่ 50190",
    name_th: "พร้าว สันทราย เชียงใหม่ 50190",
    name_en: "Phrao, San Sai, Chiang Mai, 50190",
  },
  {
    id: 501106,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "บ้านโป่ง",
    subdistrictEn: "Ban Pong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว บ้านโป่ง เชียงใหม่ 50190",
    name_th: "พร้าว บ้านโป่ง เชียงใหม่ 50190",
    name_en: "Phrao, Ban Pong, Chiang Mai, 50190",
  },
  {
    id: 501107,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "น้ำแพร่",
    subdistrictEn: "Nam Phrae",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว น้ำแพร่ เชียงใหม่ 50190",
    name_th: "พร้าว น้ำแพร่ เชียงใหม่ 50190",
    name_en: "Phrao, Nam Phrae, Chiang Mai, 50190",
  },
  {
    id: 501108,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "เขื่อนผาก",
    subdistrictEn: "Khuean Phak",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว เขื่อนผาก เชียงใหม่ 50190",
    name_th: "พร้าว เขื่อนผาก เชียงใหม่ 50190",
    name_en: "Phrao, Khuean Phak, Chiang Mai, 50190",
  },
  {
    id: 501109,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "แม่แวน",
    subdistrictEn: "Mae Waen",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว แม่แวน เชียงใหม่ 50190",
    name_th: "พร้าว แม่แวน เชียงใหม่ 50190",
    name_en: "Phrao, Mae Waen, Chiang Mai, 50190",
  },
  {
    id: 501110,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "แม่ปั๋ง",
    subdistrictEn: "Mae Pang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว แม่ปั๋ง เชียงใหม่ 50190",
    name_th: "พร้าว แม่ปั๋ง เชียงใหม่ 50190",
    name_en: "Phrao, Mae Pang, Chiang Mai, 50190",
  },
  {
    id: 501111,
    districtTh: "พร้าว",
    districtEn: "Phrao",
    subdistrictTh: "โหล่งขอด",
    subdistrictEn: "Long Khot",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50190,
    district_code: 5011,
    province_code: 38,
    name: "พร้าว โหล่งขอด เชียงใหม่ 50190",
    name_th: "พร้าว โหล่งขอด เชียงใหม่ 50190",
    name_en: "Phrao, Long Khot, Chiang Mai, 50190",
  },
  {
    id: 501201,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "ยุหว่า",
    subdistrictEn: "Yu Wa",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง ยุหว่า เชียงใหม่ 50120",
    name_th: "สันป่าตอง ยุหว่า เชียงใหม่ 50120",
    name_en: "San Pa Tong, Yu Wa, Chiang Mai, 50120",
  },
  {
    id: 501202,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "สันกลาง",
    subdistrictEn: "San Klang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง สันกลาง เชียงใหม่ 50120",
    name_th: "สันป่าตอง สันกลาง เชียงใหม่ 50120",
    name_en: "San Pa Tong, San Klang, Chiang Mai, 50120",
  },
  {
    id: 501203,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "ท่าวังพร้าว",
    subdistrictEn: "Tha Wang Phrao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง ท่าวังพร้าว เชียงใหม่ 50120",
    name_th: "สันป่าตอง ท่าวังพร้าว เชียงใหม่ 50120",
    name_en: "San Pa Tong, Tha Wang Phrao, Chiang Mai, 50120",
  },
  {
    id: 501204,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "มะขามหลวง",
    subdistrictEn: "Makham Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง มะขามหลวง เชียงใหม่ 50120",
    name_th: "สันป่าตอง มะขามหลวง เชียงใหม่ 50120",
    name_en: "San Pa Tong, Makham Luang, Chiang Mai, 50120",
  },
  {
    id: 501205,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "แม่ก๊า",
    subdistrictEn: "Mae Ka",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง แม่ก๊า เชียงใหม่ 50120",
    name_th: "สันป่าตอง แม่ก๊า เชียงใหม่ 50120",
    name_en: "San Pa Tong, Mae Ka, Chiang Mai, 50120",
  },
  {
    id: 501206,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "บ้านแม",
    subdistrictEn: "Ban Mae",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง บ้านแม เชียงใหม่ 50120",
    name_th: "สันป่าตอง บ้านแม เชียงใหม่ 50120",
    name_en: "San Pa Tong, Ban Mae, Chiang Mai, 50120",
  },
  {
    id: 501207,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง บ้านกลาง เชียงใหม่ 50120",
    name_th: "สันป่าตอง บ้านกลาง เชียงใหม่ 50120",
    name_en: "San Pa Tong, Ban Klang, Chiang Mai, 50120",
  },
  {
    id: 501208,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "ทุ่งสะโตก",
    subdistrictEn: "Thung Satok",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง ทุ่งสะโตก เชียงใหม่ 50120",
    name_th: "สันป่าตอง ทุ่งสะโตก เชียงใหม่ 50120",
    name_en: "San Pa Tong, Thung Satok, Chiang Mai, 50120",
  },
  {
    id: 501210,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "ทุ่งต้อม",
    subdistrictEn: "Thung Tom",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง ทุ่งต้อม เชียงใหม่ 50120",
    name_th: "สันป่าตอง ทุ่งต้อม เชียงใหม่ 50120",
    name_en: "San Pa Tong, Thung Tom, Chiang Mai, 50120",
  },
  {
    id: 501214,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "น้ำบ่อหลวง",
    subdistrictEn: "Nam Bo Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง น้ำบ่อหลวง เชียงใหม่ 50120",
    name_th: "สันป่าตอง น้ำบ่อหลวง เชียงใหม่ 50120",
    name_en: "San Pa Tong, Nam Bo Luang, Chiang Mai, 50120",
  },
  {
    id: 501215,
    districtTh: "สันป่าตอง",
    districtEn: "San Pa Tong",
    subdistrictTh: "มะขุนหวาน",
    subdistrictEn: "Makhun Wan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50120,
    district_code: 5012,
    province_code: 38,
    name: "สันป่าตอง มะขุนหวาน เชียงใหม่ 50120",
    name_th: "สันป่าตอง มะขุนหวาน เชียงใหม่ 50120",
    name_en: "San Pa Tong, Makhun Wan, Chiang Mai, 50120",
  },
  {
    id: 501301,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "สันกำแพง",
    subdistrictEn: "San Kamphaeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง สันกำแพง เชียงใหม่ 50130",
    name_th: "สันกำแพง สันกำแพง เชียงใหม่ 50130",
    name_en: "San Kamphaeng, San Kamphaeng, Chiang Mai, 50130",
  },
  {
    id: 501302,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "ทรายมูล",
    subdistrictEn: "Sai Mun",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง ทรายมูล เชียงใหม่ 50130",
    name_th: "สันกำแพง ทรายมูล เชียงใหม่ 50130",
    name_en: "San Kamphaeng, Sai Mun, Chiang Mai, 50130",
  },
  {
    id: 501303,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "ร้องวัวแดง",
    subdistrictEn: "Rong Wua Daeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง ร้องวัวแดง เชียงใหม่ 50130",
    name_th: "สันกำแพง ร้องวัวแดง เชียงใหม่ 50130",
    name_en: "San Kamphaeng, Rong Wua Daeng, Chiang Mai, 50130",
  },
  {
    id: 501304,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "บวกค้าง",
    subdistrictEn: "Buak Khang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง บวกค้าง เชียงใหม่ 50130",
    name_th: "สันกำแพง บวกค้าง เชียงใหม่ 50130",
    name_en: "San Kamphaeng, Buak Khang, Chiang Mai, 50130",
  },
  {
    id: 501305,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "แช่ช้าง",
    subdistrictEn: "Chae Chang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง แช่ช้าง เชียงใหม่ 50130",
    name_th: "สันกำแพง แช่ช้าง เชียงใหม่ 50130",
    name_en: "San Kamphaeng, Chae Chang, Chiang Mai, 50130",
  },
  {
    id: 501306,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "ออนใต้",
    subdistrictEn: "On Tai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง ออนใต้ เชียงใหม่ 50130",
    name_th: "สันกำแพง ออนใต้ เชียงใหม่ 50130",
    name_en: "San Kamphaeng, On Tai, Chiang Mai, 50130",
  },
  {
    id: 501310,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "แม่ปูคา",
    subdistrictEn: "Mae Pu Kha",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง แม่ปูคา เชียงใหม่ 50130",
    name_th: "สันกำแพง แม่ปูคา เชียงใหม่ 50130",
    name_en: "San Kamphaeng, Mae Pu Kha, Chiang Mai, 50130",
  },
  {
    id: 501311,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "ห้วยทราย",
    subdistrictEn: "Huai Sai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง ห้วยทราย เชียงใหม่ 50130",
    name_th: "สันกำแพง ห้วยทราย เชียงใหม่ 50130",
    name_en: "San Kamphaeng, Huai Sai, Chiang Mai, 50130",
  },
  {
    id: 501312,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "ต้นเปา",
    subdistrictEn: "Ton Pao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง ต้นเปา เชียงใหม่ 50130",
    name_th: "สันกำแพง ต้นเปา เชียงใหม่ 50130",
    name_en: "San Kamphaeng, Ton Pao, Chiang Mai, 50130",
  },
  {
    id: 501313,
    districtTh: "สันกำแพง",
    districtEn: "San Kamphaeng",
    subdistrictTh: "สันกลาง",
    subdistrictEn: "San Klang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5013,
    province_code: 38,
    name: "สันกำแพง สันกลาง เชียงใหม่ 50130",
    name_th: "สันกำแพง สันกลาง เชียงใหม่ 50130",
    name_en: "San Kamphaeng, San Klang, Chiang Mai, 50130",
  },
  {
    id: 501401,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "สันทรายหลวง",
    subdistrictEn: "San Sai Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย สันทรายหลวง เชียงใหม่ 50210",
    name_th: "สันทราย สันทรายหลวง เชียงใหม่ 50210",
    name_en: "San Sai, San Sai Luang, Chiang Mai, 50210",
  },
  {
    id: 501402,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "สันทรายน้อย",
    subdistrictEn: "San Sai Noi",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย สันทรายน้อย เชียงใหม่ 50210",
    name_th: "สันทราย สันทรายน้อย เชียงใหม่ 50210",
    name_en: "San Sai, San Sai Noi, Chiang Mai, 50210",
  },
  {
    id: 501403,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "สันพระเนตร",
    subdistrictEn: "San Phranet",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย สันพระเนตร เชียงใหม่ 50210",
    name_th: "สันทราย สันพระเนตร เชียงใหม่ 50210",
    name_en: "San Sai, San Phranet, Chiang Mai, 50210",
  },
  {
    id: 501404,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "สันนาเม็ง",
    subdistrictEn: "San Na Meng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย สันนาเม็ง เชียงใหม่ 50210",
    name_th: "สันทราย สันนาเม็ง เชียงใหม่ 50210",
    name_en: "San Sai, San Na Meng, Chiang Mai, 50210",
  },
  {
    id: 501405,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "สันป่าเปา",
    subdistrictEn: "San Pa Pao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย สันป่าเปา เชียงใหม่ 50210",
    name_th: "สันทราย สันป่าเปา เชียงใหม่ 50210",
    name_en: "San Sai, San Pa Pao, Chiang Mai, 50210",
  },
  {
    id: 501406,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "หนองแหย่ง",
    subdistrictEn: "Nong Yaeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย หนองแหย่ง เชียงใหม่ 50210",
    name_th: "สันทราย หนองแหย่ง เชียงใหม่ 50210",
    name_en: "San Sai, Nong Yaeng, Chiang Mai, 50210",
  },
  {
    id: 501407,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "หนองจ๊อม",
    subdistrictEn: "Nong Chom",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย หนองจ๊อม เชียงใหม่ 50210",
    name_th: "สันทราย หนองจ๊อม เชียงใหม่ 50210",
    name_en: "San Sai, Nong Chom, Chiang Mai, 50210",
  },
  {
    id: 501408,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "หนองหาร",
    subdistrictEn: "Nong Han",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50290,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย หนองหาร เชียงใหม่ 50290",
    name_th: "สันทราย หนองหาร เชียงใหม่ 50290",
    name_en: "San Sai, Nong Han, Chiang Mai, 50290",
  },
  {
    id: 501409,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "แม่แฝก",
    subdistrictEn: "Mae Faek",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50290,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย แม่แฝก เชียงใหม่ 50290",
    name_th: "สันทราย แม่แฝก เชียงใหม่ 50290",
    name_en: "San Sai, Mae Faek, Chiang Mai, 50290",
  },
  {
    id: 501410,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "แม่แฝกใหม่",
    subdistrictEn: "Mae Faek Mai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50290,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย แม่แฝกใหม่ เชียงใหม่ 50290",
    name_th: "สันทราย แม่แฝกใหม่ เชียงใหม่ 50290",
    name_en: "San Sai, Mae Faek Mai, Chiang Mai, 50290",
  },
  {
    id: 501411,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "เมืองเล็น",
    subdistrictEn: "Mueang Len",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย เมืองเล็น เชียงใหม่ 50210",
    name_th: "สันทราย เมืองเล็น เชียงใหม่ 50210",
    name_en: "San Sai, Mueang Len, Chiang Mai, 50210",
  },
  {
    id: 501412,
    districtTh: "สันทราย",
    districtEn: "San Sai",
    subdistrictTh: "ป่าไผ่",
    subdistrictEn: "Pa Phai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50210,
    district_code: 5014,
    province_code: 38,
    name: "สันทราย ป่าไผ่ เชียงใหม่ 50210",
    name_th: "สันทราย ป่าไผ่ เชียงใหม่ 50210",
    name_en: "San Sai, Pa Phai, Chiang Mai, 50210",
  },
  {
    id: 501501,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "หางดง",
    subdistrictEn: "Hang Dong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง หางดง เชียงใหม่ 50230",
    name_th: "หางดง หางดง เชียงใหม่ 50230",
    name_en: "Hang Dong, Hang Dong, Chiang Mai, 50230",
  },
  {
    id: 501502,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "หนองแก๋ว",
    subdistrictEn: "Nong Kaeo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง หนองแก๋ว เชียงใหม่ 50230",
    name_th: "หางดง หนองแก๋ว เชียงใหม่ 50230",
    name_en: "Hang Dong, Nong Kaeo, Chiang Mai, 50230",
  },
  {
    id: 501503,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "หารแก้ว",
    subdistrictEn: "Han Kaeo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง หารแก้ว เชียงใหม่ 50230",
    name_th: "หางดง หารแก้ว เชียงใหม่ 50230",
    name_en: "Hang Dong, Han Kaeo, Chiang Mai, 50230",
  },
  {
    id: 501504,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "หนองตอง",
    subdistrictEn: "Nong Tong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50340,
    district_code: 5015,
    province_code: 38,
    name: "หางดง หนองตอง เชียงใหม่ 50340",
    name_th: "หางดง หนองตอง เชียงใหม่ 50340",
    name_en: "Hang Dong, Nong Tong, Chiang Mai, 50340",
  },
  {
    id: 501505,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "ขุนคง",
    subdistrictEn: "Khun Khong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง ขุนคง เชียงใหม่ 50230",
    name_th: "หางดง ขุนคง เชียงใหม่ 50230",
    name_en: "Hang Dong, Khun Khong, Chiang Mai, 50230",
  },
  {
    id: 501506,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "สบแม่ข่า",
    subdistrictEn: "Sop Mae Kha",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง สบแม่ข่า เชียงใหม่ 50230",
    name_th: "หางดง สบแม่ข่า เชียงใหม่ 50230",
    name_en: "Hang Dong, Sop Mae Kha, Chiang Mai, 50230",
  },
  {
    id: 501507,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "บ้านแหวน",
    subdistrictEn: "Ban Waen",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง บ้านแหวน เชียงใหม่ 50230",
    name_th: "หางดง บ้านแหวน เชียงใหม่ 50230",
    name_en: "Hang Dong, Ban Waen, Chiang Mai, 50230",
  },
  {
    id: 501508,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "สันผักหวาน",
    subdistrictEn: "San Phak Wan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง สันผักหวาน เชียงใหม่ 50230",
    name_th: "หางดง สันผักหวาน เชียงใหม่ 50230",
    name_en: "Hang Dong, San Phak Wan, Chiang Mai, 50230",
  },
  {
    id: 501509,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "หนองควาย",
    subdistrictEn: "Nong Khwai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง หนองควาย เชียงใหม่ 50230",
    name_th: "หางดง หนองควาย เชียงใหม่ 50230",
    name_en: "Hang Dong, Nong Khwai, Chiang Mai, 50230",
  },
  {
    id: 501510,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "บ้านปง",
    subdistrictEn: "Ban Pong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง บ้านปง เชียงใหม่ 50230",
    name_th: "หางดง บ้านปง เชียงใหม่ 50230",
    name_en: "Hang Dong, Ban Pong, Chiang Mai, 50230",
  },
  {
    id: 501511,
    districtTh: "หางดง",
    districtEn: "Hang Dong",
    subdistrictTh: "น้ำแพร่",
    subdistrictEn: "Nam Phrae",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50230,
    district_code: 5015,
    province_code: 38,
    name: "หางดง น้ำแพร่ เชียงใหม่ 50230",
    name_th: "หางดง น้ำแพร่ เชียงใหม่ 50230",
    name_en: "Hang Dong, Nam Phrae, Chiang Mai, 50230",
  },
  {
    id: 501601,
    districtTh: "ฮอด",
    districtEn: "Hot",
    subdistrictTh: "หางดง",
    subdistrictEn: "Hang Dong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50240,
    district_code: 5016,
    province_code: 38,
    name: "ฮอด หางดง เชียงใหม่ 50240",
    name_th: "ฮอด หางดง เชียงใหม่ 50240",
    name_en: "Hot, Hang Dong, Chiang Mai, 50240",
  },
  {
    id: 501602,
    districtTh: "ฮอด",
    districtEn: "Hot",
    subdistrictTh: "ฮอด",
    subdistrictEn: "Hot",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50240,
    district_code: 5016,
    province_code: 38,
    name: "ฮอด ฮอด เชียงใหม่ 50240",
    name_th: "ฮอด ฮอด เชียงใหม่ 50240",
    name_en: "Hot, Hot, Chiang Mai, 50240",
  },
  {
    id: 501603,
    districtTh: "ฮอด",
    districtEn: "Hot",
    subdistrictTh: "บ้านตาล",
    subdistrictEn: "Ban Tan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50240,
    district_code: 5016,
    province_code: 38,
    name: "ฮอด บ้านตาล เชียงใหม่ 50240",
    name_th: "ฮอด บ้านตาล เชียงใหม่ 50240",
    name_en: "Hot, Ban Tan, Chiang Mai, 50240",
  },
  {
    id: 501604,
    districtTh: "ฮอด",
    districtEn: "Hot",
    subdistrictTh: "บ่อหลวง",
    subdistrictEn: "Bo Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50240,
    district_code: 5016,
    province_code: 38,
    name: "ฮอด บ่อหลวง เชียงใหม่ 50240",
    name_th: "ฮอด บ่อหลวง เชียงใหม่ 50240",
    name_en: "Hot, Bo Luang, Chiang Mai, 50240",
  },
  {
    id: 501605,
    districtTh: "ฮอด",
    districtEn: "Hot",
    subdistrictTh: "บ่อสลี",
    subdistrictEn: "Bo Sali",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50240,
    district_code: 5016,
    province_code: 38,
    name: "ฮอด บ่อสลี เชียงใหม่ 50240",
    name_th: "ฮอด บ่อสลี เชียงใหม่ 50240",
    name_en: "Hot, Bo Sali, Chiang Mai, 50240",
  },
  {
    id: 501606,
    districtTh: "ฮอด",
    districtEn: "Hot",
    subdistrictTh: "นาคอเรือ",
    subdistrictEn: "Na Kho Ruea",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50240,
    district_code: 5016,
    province_code: 38,
    name: "ฮอด นาคอเรือ เชียงใหม่ 50240",
    name_th: "ฮอด นาคอเรือ เชียงใหม่ 50240",
    name_en: "Hot, Na Kho Ruea, Chiang Mai, 50240",
  },
  {
    id: 501701,
    districtTh: "ดอยเต่า",
    districtEn: "Doi Tao",
    subdistrictTh: "ดอยเต่า",
    subdistrictEn: "Doi Tao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50260,
    district_code: 5017,
    province_code: 38,
    name: "ดอยเต่า ดอยเต่า เชียงใหม่ 50260",
    name_th: "ดอยเต่า ดอยเต่า เชียงใหม่ 50260",
    name_en: "Doi Tao, Doi Tao, Chiang Mai, 50260",
  },
  {
    id: 501702,
    districtTh: "ดอยเต่า",
    districtEn: "Doi Tao",
    subdistrictTh: "ท่าเดื่อ",
    subdistrictEn: "Tha Duea",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50260,
    district_code: 5017,
    province_code: 38,
    name: "ดอยเต่า ท่าเดื่อ เชียงใหม่ 50260",
    name_th: "ดอยเต่า ท่าเดื่อ เชียงใหม่ 50260",
    name_en: "Doi Tao, Tha Duea, Chiang Mai, 50260",
  },
  {
    id: 501703,
    districtTh: "ดอยเต่า",
    districtEn: "Doi Tao",
    subdistrictTh: "มืดกา",
    subdistrictEn: "Muet Ka",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50260,
    district_code: 5017,
    province_code: 38,
    name: "ดอยเต่า มืดกา เชียงใหม่ 50260",
    name_th: "ดอยเต่า มืดกา เชียงใหม่ 50260",
    name_en: "Doi Tao, Muet Ka, Chiang Mai, 50260",
  },
  {
    id: 501704,
    districtTh: "ดอยเต่า",
    districtEn: "Doi Tao",
    subdistrictTh: "บ้านแอ่น",
    subdistrictEn: "Ban Aen",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50260,
    district_code: 5017,
    province_code: 38,
    name: "ดอยเต่า บ้านแอ่น เชียงใหม่ 50260",
    name_th: "ดอยเต่า บ้านแอ่น เชียงใหม่ 50260",
    name_en: "Doi Tao, Ban Aen, Chiang Mai, 50260",
  },
  {
    id: 501705,
    districtTh: "ดอยเต่า",
    districtEn: "Doi Tao",
    subdistrictTh: "บงตัน",
    subdistrictEn: "Bong Tan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50260,
    district_code: 5017,
    province_code: 38,
    name: "ดอยเต่า บงตัน เชียงใหม่ 50260",
    name_th: "ดอยเต่า บงตัน เชียงใหม่ 50260",
    name_en: "Doi Tao, Bong Tan, Chiang Mai, 50260",
  },
  {
    id: 501706,
    districtTh: "ดอยเต่า",
    districtEn: "Doi Tao",
    subdistrictTh: "โปงทุ่ง",
    subdistrictEn: "Pong Thung",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50260,
    district_code: 5017,
    province_code: 38,
    name: "ดอยเต่า โปงทุ่ง เชียงใหม่ 50260",
    name_th: "ดอยเต่า โปงทุ่ง เชียงใหม่ 50260",
    name_en: "Doi Tao, Pong Thung, Chiang Mai, 50260",
  },
  {
    id: 501801,
    districtTh: "อมก๋อย",
    districtEn: "Omkoi",
    subdistrictTh: "อมก๋อย",
    subdistrictEn: "Omkoi",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50310,
    district_code: 5018,
    province_code: 38,
    name: "อมก๋อย อมก๋อย เชียงใหม่ 50310",
    name_th: "อมก๋อย อมก๋อย เชียงใหม่ 50310",
    name_en: "Omkoi, Omkoi, Chiang Mai, 50310",
  },
  {
    id: 501802,
    districtTh: "อมก๋อย",
    districtEn: "Omkoi",
    subdistrictTh: "ยางเปียง",
    subdistrictEn: "Yang Piang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50310,
    district_code: 5018,
    province_code: 38,
    name: "อมก๋อย ยางเปียง เชียงใหม่ 50310",
    name_th: "อมก๋อย ยางเปียง เชียงใหม่ 50310",
    name_en: "Omkoi, Yang Piang, Chiang Mai, 50310",
  },
  {
    id: 501803,
    districtTh: "อมก๋อย",
    districtEn: "Omkoi",
    subdistrictTh: "แม่ตื่น",
    subdistrictEn: "Mae Tuen",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50310,
    district_code: 5018,
    province_code: 38,
    name: "อมก๋อย แม่ตื่น เชียงใหม่ 50310",
    name_th: "อมก๋อย แม่ตื่น เชียงใหม่ 50310",
    name_en: "Omkoi, Mae Tuen, Chiang Mai, 50310",
  },
  {
    id: 501804,
    districtTh: "อมก๋อย",
    districtEn: "Omkoi",
    subdistrictTh: "ม่อนจอง",
    subdistrictEn: "Mon Chong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50310,
    district_code: 5018,
    province_code: 38,
    name: "อมก๋อย ม่อนจอง เชียงใหม่ 50310",
    name_th: "อมก๋อย ม่อนจอง เชียงใหม่ 50310",
    name_en: "Omkoi, Mon Chong, Chiang Mai, 50310",
  },
  {
    id: 501805,
    districtTh: "อมก๋อย",
    districtEn: "Omkoi",
    subdistrictTh: "สบโขง",
    subdistrictEn: "Sop Khong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50310,
    district_code: 5018,
    province_code: 38,
    name: "อมก๋อย สบโขง เชียงใหม่ 50310",
    name_th: "อมก๋อย สบโขง เชียงใหม่ 50310",
    name_en: "Omkoi, Sop Khong, Chiang Mai, 50310",
  },
  {
    id: 501806,
    districtTh: "อมก๋อย",
    districtEn: "Omkoi",
    subdistrictTh: "นาเกียน",
    subdistrictEn: "Na Kian",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50310,
    district_code: 5018,
    province_code: 38,
    name: "อมก๋อย นาเกียน เชียงใหม่ 50310",
    name_th: "อมก๋อย นาเกียน เชียงใหม่ 50310",
    name_en: "Omkoi, Na Kian, Chiang Mai, 50310",
  },
  {
    id: 501901,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "ยางเนิ้ง",
    subdistrictEn: "Yang Noeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี ยางเนิ้ง เชียงใหม่ 50140",
    name_th: "สารภี ยางเนิ้ง เชียงใหม่ 50140",
    name_en: "Saraphi, Yang Noeng, Chiang Mai, 50140",
  },
  {
    id: 501902,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "สารภี",
    subdistrictEn: "Saraphi",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี สารภี เชียงใหม่ 50140",
    name_th: "สารภี สารภี เชียงใหม่ 50140",
    name_en: "Saraphi, Saraphi, Chiang Mai, 50140",
  },
  {
    id: 501903,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "ชมภู",
    subdistrictEn: "Chom Phu",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี ชมภู เชียงใหม่ 50140",
    name_th: "สารภี ชมภู เชียงใหม่ 50140",
    name_en: "Saraphi, Chom Phu, Chiang Mai, 50140",
  },
  {
    id: 501904,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "ไชยสถาน",
    subdistrictEn: "Chai Sathan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี ไชยสถาน เชียงใหม่ 50140",
    name_th: "สารภี ไชยสถาน เชียงใหม่ 50140",
    name_en: "Saraphi, Chai Sathan, Chiang Mai, 50140",
  },
  {
    id: 501905,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "ขัวมุง",
    subdistrictEn: "Khua Mung",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี ขัวมุง เชียงใหม่ 50140",
    name_th: "สารภี ขัวมุง เชียงใหม่ 50140",
    name_en: "Saraphi, Khua Mung, Chiang Mai, 50140",
  },
  {
    id: 501906,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "หนองแฝก",
    subdistrictEn: "Nong Faek",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี หนองแฝก เชียงใหม่ 50140",
    name_th: "สารภี หนองแฝก เชียงใหม่ 50140",
    name_en: "Saraphi, Nong Faek, Chiang Mai, 50140",
  },
  {
    id: 501907,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "หนองผึ้ง",
    subdistrictEn: "Nong Phueng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี หนองผึ้ง เชียงใหม่ 50140",
    name_th: "สารภี หนองผึ้ง เชียงใหม่ 50140",
    name_en: "Saraphi, Nong Phueng, Chiang Mai, 50140",
  },
  {
    id: 501908,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "ท่ากว้าง",
    subdistrictEn: "Tha Kwang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี ท่ากว้าง เชียงใหม่ 50140",
    name_th: "สารภี ท่ากว้าง เชียงใหม่ 50140",
    name_en: "Saraphi, Tha Kwang, Chiang Mai, 50140",
  },
  {
    id: 501909,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "ดอนแก้ว",
    subdistrictEn: "Don Kaeo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี ดอนแก้ว เชียงใหม่ 50140",
    name_th: "สารภี ดอนแก้ว เชียงใหม่ 50140",
    name_en: "Saraphi, Don Kaeo, Chiang Mai, 50140",
  },
  {
    id: 501910,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "ท่าวังตาล",
    subdistrictEn: "Tha Wang Tan",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี ท่าวังตาล เชียงใหม่ 50140",
    name_th: "สารภี ท่าวังตาล เชียงใหม่ 50140",
    name_en: "Saraphi, Tha Wang Tan, Chiang Mai, 50140",
  },
  {
    id: 501911,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "สันทราย",
    subdistrictEn: "San Sai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี สันทราย เชียงใหม่ 50140",
    name_th: "สารภี สันทราย เชียงใหม่ 50140",
    name_en: "Saraphi, San Sai, Chiang Mai, 50140",
  },
  {
    id: 501912,
    districtTh: "สารภี",
    districtEn: "Saraphi",
    subdistrictTh: "ป่าบง",
    subdistrictEn: "Pa Bong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50140,
    district_code: 5019,
    province_code: 38,
    name: "สารภี ป่าบง เชียงใหม่ 50140",
    name_th: "สารภี ป่าบง เชียงใหม่ 50140",
    name_en: "Saraphi, Pa Bong, Chiang Mai, 50140",
  },
  {
    id: 502001,
    districtTh: "เวียงแหง",
    districtEn: "Wiang Haeng",
    subdistrictTh: "เมืองแหง",
    subdistrictEn: "Mueang Haeng",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50350,
    district_code: 5020,
    province_code: 38,
    name: "เวียงแหง เมืองแหง เชียงใหม่ 50350",
    name_th: "เวียงแหง เมืองแหง เชียงใหม่ 50350",
    name_en: "Wiang Haeng, Mueang Haeng, Chiang Mai, 50350",
  },
  {
    id: 502002,
    districtTh: "เวียงแหง",
    districtEn: "Wiang Haeng",
    subdistrictTh: "เปียงหลวง",
    subdistrictEn: "Piang Luang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50350,
    district_code: 5020,
    province_code: 38,
    name: "เวียงแหง เปียงหลวง เชียงใหม่ 50350",
    name_th: "เวียงแหง เปียงหลวง เชียงใหม่ 50350",
    name_en: "Wiang Haeng, Piang Luang, Chiang Mai, 50350",
  },
  {
    id: 502003,
    districtTh: "เวียงแหง",
    districtEn: "Wiang Haeng",
    subdistrictTh: "แสนไห",
    subdistrictEn: "Saen Hai",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50350,
    district_code: 5020,
    province_code: 38,
    name: "เวียงแหง แสนไห เชียงใหม่ 50350",
    name_th: "เวียงแหง แสนไห เชียงใหม่ 50350",
    name_en: "Wiang Haeng, Saen Hai, Chiang Mai, 50350",
  },
  {
    id: 502101,
    districtTh: "ไชยปราการ",
    districtEn: "Chai Prakan",
    subdistrictTh: "ปงตำ",
    subdistrictEn: "Pong Tam",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50320,
    district_code: 5021,
    province_code: 38,
    name: "ไชยปราการ ปงตำ เชียงใหม่ 50320",
    name_th: "ไชยปราการ ปงตำ เชียงใหม่ 50320",
    name_en: "Chai Prakan, Pong Tam, Chiang Mai, 50320",
  },
  {
    id: 502102,
    districtTh: "ไชยปราการ",
    districtEn: "Chai Prakan",
    subdistrictTh: "ศรีดงเย็น",
    subdistrictEn: "Si Dong Yen",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50320,
    district_code: 5021,
    province_code: 38,
    name: "ไชยปราการ ศรีดงเย็น เชียงใหม่ 50320",
    name_th: "ไชยปราการ ศรีดงเย็น เชียงใหม่ 50320",
    name_en: "Chai Prakan, Si Dong Yen, Chiang Mai, 50320",
  },
  {
    id: 502103,
    districtTh: "ไชยปราการ",
    districtEn: "Chai Prakan",
    subdistrictTh: "แม่ทะลบ",
    subdistrictEn: "Mae Thalop",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50320,
    district_code: 5021,
    province_code: 38,
    name: "ไชยปราการ แม่ทะลบ เชียงใหม่ 50320",
    name_th: "ไชยปราการ แม่ทะลบ เชียงใหม่ 50320",
    name_en: "Chai Prakan, Mae Thalop, Chiang Mai, 50320",
  },
  {
    id: 502104,
    districtTh: "ไชยปราการ",
    districtEn: "Chai Prakan",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50320,
    district_code: 5021,
    province_code: 38,
    name: "ไชยปราการ หนองบัว เชียงใหม่ 50320",
    name_th: "ไชยปราการ หนองบัว เชียงใหม่ 50320",
    name_en: "Chai Prakan, Nong Bua, Chiang Mai, 50320",
  },
  {
    id: 502201,
    districtTh: "แม่วาง",
    districtEn: "Mae Wang",
    subdistrictTh: "บ้านกาด",
    subdistrictEn: "Ban Kat",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50360,
    district_code: 5022,
    province_code: 38,
    name: "แม่วาง บ้านกาด เชียงใหม่ 50360",
    name_th: "แม่วาง บ้านกาด เชียงใหม่ 50360",
    name_en: "Mae Wang, Ban Kat, Chiang Mai, 50360",
  },
  {
    id: 502202,
    districtTh: "แม่วาง",
    districtEn: "Mae Wang",
    subdistrictTh: "ทุ่งปี้",
    subdistrictEn: "Thung Pi",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50360,
    district_code: 5022,
    province_code: 38,
    name: "แม่วาง ทุ่งปี้ เชียงใหม่ 50360",
    name_th: "แม่วาง ทุ่งปี้ เชียงใหม่ 50360",
    name_en: "Mae Wang, Thung Pi, Chiang Mai, 50360",
  },
  {
    id: 502203,
    districtTh: "แม่วาง",
    districtEn: "Mae Wang",
    subdistrictTh: "ทุ่งรวงทอง",
    subdistrictEn: "Thung Ruang Thong",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50360,
    district_code: 5022,
    province_code: 38,
    name: "แม่วาง ทุ่งรวงทอง เชียงใหม่ 50360",
    name_th: "แม่วาง ทุ่งรวงทอง เชียงใหม่ 50360",
    name_en: "Mae Wang, Thung Ruang Thong, Chiang Mai, 50360",
  },
  {
    id: 502204,
    districtTh: "แม่วาง",
    districtEn: "Mae Wang",
    subdistrictTh: "แม่วิน",
    subdistrictEn: "Mae Win",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50360,
    district_code: 5022,
    province_code: 38,
    name: "แม่วาง แม่วิน เชียงใหม่ 50360",
    name_th: "แม่วาง แม่วิน เชียงใหม่ 50360",
    name_en: "Mae Wang, Mae Win, Chiang Mai, 50360",
  },
  {
    id: 502205,
    districtTh: "แม่วาง",
    districtEn: "Mae Wang",
    subdistrictTh: "ดอนเปา",
    subdistrictEn: "Don Pao",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50360,
    district_code: 5022,
    province_code: 38,
    name: "แม่วาง ดอนเปา เชียงใหม่ 50360",
    name_th: "แม่วาง ดอนเปา เชียงใหม่ 50360",
    name_en: "Mae Wang, Don Pao, Chiang Mai, 50360",
  },
  {
    id: 502301,
    districtTh: "แม่ออน",
    districtEn: "Mae On",
    subdistrictTh: "ออนเหนือ",
    subdistrictEn: "On Nuea",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5023,
    province_code: 38,
    name: "แม่ออน ออนเหนือ เชียงใหม่ 50130",
    name_th: "แม่ออน ออนเหนือ เชียงใหม่ 50130",
    name_en: "Mae On, On Nuea, Chiang Mai, 50130",
  },
  {
    id: 502302,
    districtTh: "แม่ออน",
    districtEn: "Mae On",
    subdistrictTh: "ออนกลาง",
    subdistrictEn: "On Klang",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5023,
    province_code: 38,
    name: "แม่ออน ออนกลาง เชียงใหม่ 50130",
    name_th: "แม่ออน ออนกลาง เชียงใหม่ 50130",
    name_en: "Mae On, On Klang, Chiang Mai, 50130",
  },
  {
    id: 502303,
    districtTh: "แม่ออน",
    districtEn: "Mae On",
    subdistrictTh: "บ้านสหกรณ์",
    subdistrictEn: "Ban Sahakon",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5023,
    province_code: 38,
    name: "แม่ออน บ้านสหกรณ์ เชียงใหม่ 50130",
    name_th: "แม่ออน บ้านสหกรณ์ เชียงใหม่ 50130",
    name_en: "Mae On, Ban Sahakon, Chiang Mai, 50130",
  },
  {
    id: 502304,
    districtTh: "แม่ออน",
    districtEn: "Mae On",
    subdistrictTh: "ห้วยแก้ว",
    subdistrictEn: "Huai Kaeo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5023,
    province_code: 38,
    name: "แม่ออน ห้วยแก้ว เชียงใหม่ 50130",
    name_th: "แม่ออน ห้วยแก้ว เชียงใหม่ 50130",
    name_en: "Mae On, Huai Kaeo, Chiang Mai, 50130",
  },
  {
    id: 502305,
    districtTh: "แม่ออน",
    districtEn: "Mae On",
    subdistrictTh: "แม่ทา",
    subdistrictEn: "Mae Tha",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5023,
    province_code: 38,
    name: "แม่ออน แม่ทา เชียงใหม่ 50130",
    name_th: "แม่ออน แม่ทา เชียงใหม่ 50130",
    name_en: "Mae On, Mae Tha, Chiang Mai, 50130",
  },
  {
    id: 502306,
    districtTh: "แม่ออน",
    districtEn: "Mae On",
    subdistrictTh: "ทาเหนือ",
    subdistrictEn: "Tha Nuea",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50130,
    district_code: 5023,
    province_code: 38,
    name: "แม่ออน ทาเหนือ เชียงใหม่ 50130",
    name_th: "แม่ออน ทาเหนือ เชียงใหม่ 50130",
    name_en: "Mae On, Tha Nuea, Chiang Mai, 50130",
  },
  {
    id: 502401,
    districtTh: "ดอยหล่อ",
    districtEn: "Doi Lo",
    subdistrictTh: "ดอยหล่อ",
    subdistrictEn: "Doi Lo",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50160,
    district_code: 5024,
    province_code: 38,
    name: "ดอยหล่อ ดอยหล่อ เชียงใหม่ 50160",
    name_th: "ดอยหล่อ ดอยหล่อ เชียงใหม่ 50160",
    name_en: "Doi Lo, Doi Lo, Chiang Mai, 50160",
  },
  {
    id: 502402,
    districtTh: "ดอยหล่อ",
    districtEn: "Doi Lo",
    subdistrictTh: "สองแคว",
    subdistrictEn: "Song Khwae",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50160,
    district_code: 5024,
    province_code: 38,
    name: "ดอยหล่อ สองแคว เชียงใหม่ 50160",
    name_th: "ดอยหล่อ สองแคว เชียงใหม่ 50160",
    name_en: "Doi Lo, Song Khwae, Chiang Mai, 50160",
  },
  {
    id: 502403,
    districtTh: "ดอยหล่อ",
    districtEn: "Doi Lo",
    subdistrictTh: "ยางคราม",
    subdistrictEn: "Yang Khram",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50160,
    district_code: 5024,
    province_code: 38,
    name: "ดอยหล่อ ยางคราม เชียงใหม่ 50160",
    name_th: "ดอยหล่อ ยางคราม เชียงใหม่ 50160",
    name_en: "Doi Lo, Yang Khram, Chiang Mai, 50160",
  },
  {
    id: 502404,
    districtTh: "ดอยหล่อ",
    districtEn: "Doi Lo",
    subdistrictTh: "สันติสุข",
    subdistrictEn: "Santi Suk",
    provinceTh: "เชียงใหม่",
    provinceEn: "Chiang Mai",
    zipcode: 50160,
    district_code: 5024,
    province_code: 38,
    name: "ดอยหล่อ สันติสุข เชียงใหม่ 50160",
    name_th: "ดอยหล่อ สันติสุข เชียงใหม่ 50160",
    name_en: "Doi Lo, Santi Suk, Chiang Mai, 50160",
  },
  {
    id: 510101,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน ในเมือง ลำพูน 51000",
    name_th: "เมืองลำพูน ในเมือง ลำพูน 51000",
    name_en: "Mueang Lamphun, Nai Mueang, Lamphun, 51000",
  },
  {
    id: 510102,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "เหมืองง่า",
    subdistrictEn: "Mueang Nga",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน เหมืองง่า ลำพูน 51000",
    name_th: "เมืองลำพูน เหมืองง่า ลำพูน 51000",
    name_en: "Mueang Lamphun, Mueang Nga, Lamphun, 51000",
  },
  {
    id: 510103,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "อุโมงค์",
    subdistrictEn: "Umong",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51150,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน อุโมงค์ ลำพูน 51150",
    name_th: "เมืองลำพูน อุโมงค์ ลำพูน 51150",
    name_en: "Mueang Lamphun, Umong, Lamphun, 51150",
  },
  {
    id: 510104,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "หนองช้างคืน",
    subdistrictEn: "Nong Chang Khuen",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51150,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน หนองช้างคืน ลำพูน 51150",
    name_th: "เมืองลำพูน หนองช้างคืน ลำพูน 51150",
    name_en: "Mueang Lamphun, Nong Chang Khuen, Lamphun, 51150",
  },
  {
    id: 510105,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "ประตูป่า",
    subdistrictEn: "Pratu Pa",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน ประตูป่า ลำพูน 51000",
    name_th: "เมืองลำพูน ประตูป่า ลำพูน 51000",
    name_en: "Mueang Lamphun, Pratu Pa, Lamphun, 51000",
  },
  {
    id: 510106,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "ริมปิง",
    subdistrictEn: "Rim Ping",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน ริมปิง ลำพูน 51000",
    name_th: "เมืองลำพูน ริมปิง ลำพูน 51000",
    name_en: "Mueang Lamphun, Rim Ping, Lamphun, 51000",
  },
  {
    id: 510107,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "ต้นธง",
    subdistrictEn: "Ton Thong",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน ต้นธง ลำพูน 51000",
    name_th: "เมืองลำพูน ต้นธง ลำพูน 51000",
    name_en: "Mueang Lamphun, Ton Thong, Lamphun, 51000",
  },
  {
    id: 510108,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "บ้านแป้น",
    subdistrictEn: "Ban Paen",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน บ้านแป้น ลำพูน 51000",
    name_th: "เมืองลำพูน บ้านแป้น ลำพูน 51000",
    name_en: "Mueang Lamphun, Ban Paen, Lamphun, 51000",
  },
  {
    id: 510109,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "เหมืองจี้",
    subdistrictEn: "Mueang Chi",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน เหมืองจี้ ลำพูน 51000",
    name_th: "เมืองลำพูน เหมืองจี้ ลำพูน 51000",
    name_en: "Mueang Lamphun, Mueang Chi, Lamphun, 51000",
  },
  {
    id: 510110,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "ป่าสัก",
    subdistrictEn: "Pa Sak",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน ป่าสัก ลำพูน 51000",
    name_th: "เมืองลำพูน ป่าสัก ลำพูน 51000",
    name_en: "Mueang Lamphun, Pa Sak, Lamphun, 51000",
  },
  {
    id: 510111,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "เวียงยอง",
    subdistrictEn: "Wiang Yong",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน เวียงยอง ลำพูน 51000",
    name_th: "เมืองลำพูน เวียงยอง ลำพูน 51000",
    name_en: "Mueang Lamphun, Wiang Yong, Lamphun, 51000",
  },
  {
    id: 510112,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน บ้านกลาง ลำพูน 51000",
    name_th: "เมืองลำพูน บ้านกลาง ลำพูน 51000",
    name_en: "Mueang Lamphun, Ban Klang, Lamphun, 51000",
  },
  {
    id: 510113,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "มะเขือแจ้",
    subdistrictEn: "Makhuea Chae",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน มะเขือแจ้ ลำพูน 51000",
    name_th: "เมืองลำพูน มะเขือแจ้ ลำพูน 51000",
    name_en: "Mueang Lamphun, Makhuea Chae, Lamphun, 51000",
  },
  {
    id: 510116,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "ศรีบัวบาน",
    subdistrictEn: "Si Bua Ban",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน ศรีบัวบาน ลำพูน 51000",
    name_th: "เมืองลำพูน ศรีบัวบาน ลำพูน 51000",
    name_en: "Mueang Lamphun, Si Bua Ban, Lamphun, 51000",
  },
  {
    id: 510117,
    districtTh: "เมืองลำพูน",
    districtEn: "Mueang Lamphun",
    subdistrictTh: "หนองหนาม",
    subdistrictEn: "Nong Nam",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51000,
    district_code: 5101,
    province_code: 39,
    name: "เมืองลำพูน หนองหนาม ลำพูน 51000",
    name_th: "เมืองลำพูน หนองหนาม ลำพูน 51000",
    name_en: "Mueang Lamphun, Nong Nam, Lamphun, 51000",
  },
  {
    id: 510201,
    districtTh: "แม่ทา",
    districtEn: "Mae Tha",
    subdistrictTh: "ทาปลาดุก",
    subdistrictEn: "Tha Pla Duk",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51140,
    district_code: 5102,
    province_code: 39,
    name: "แม่ทา ทาปลาดุก ลำพูน 51140",
    name_th: "แม่ทา ทาปลาดุก ลำพูน 51140",
    name_en: "Mae Tha, Tha Pla Duk, Lamphun, 51140",
  },
  {
    id: 510202,
    districtTh: "แม่ทา",
    districtEn: "Mae Tha",
    subdistrictTh: "ทาสบเส้า",
    subdistrictEn: "Tha Sop Sao",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51140,
    district_code: 5102,
    province_code: 39,
    name: "แม่ทา ทาสบเส้า ลำพูน 51140",
    name_th: "แม่ทา ทาสบเส้า ลำพูน 51140",
    name_en: "Mae Tha, Tha Sop Sao, Lamphun, 51140",
  },
  {
    id: 510203,
    districtTh: "แม่ทา",
    districtEn: "Mae Tha",
    subdistrictTh: "ทากาศ",
    subdistrictEn: "Tha Kat",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51170,
    district_code: 5102,
    province_code: 39,
    name: "แม่ทา ทากาศ ลำพูน 51170",
    name_th: "แม่ทา ทากาศ ลำพูน 51170",
    name_en: "Mae Tha, Tha Kat, Lamphun, 51170",
  },
  {
    id: 510204,
    districtTh: "แม่ทา",
    districtEn: "Mae Tha",
    subdistrictTh: "ทาขุมเงิน",
    subdistrictEn: "Tha Khum Ngoen",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51170,
    district_code: 5102,
    province_code: 39,
    name: "แม่ทา ทาขุมเงิน ลำพูน 51170",
    name_th: "แม่ทา ทาขุมเงิน ลำพูน 51170",
    name_en: "Mae Tha, Tha Khum Ngoen, Lamphun, 51170",
  },
  {
    id: 510205,
    districtTh: "แม่ทา",
    districtEn: "Mae Tha",
    subdistrictTh: "ทาทุ่งหลวง",
    subdistrictEn: "Tha Thung Luang",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51170,
    district_code: 5102,
    province_code: 39,
    name: "แม่ทา ทาทุ่งหลวง ลำพูน 51170",
    name_th: "แม่ทา ทาทุ่งหลวง ลำพูน 51170",
    name_en: "Mae Tha, Tha Thung Luang, Lamphun, 51170",
  },
  {
    id: 510206,
    districtTh: "แม่ทา",
    districtEn: "Mae Tha",
    subdistrictTh: "ทาแม่ลอบ",
    subdistrictEn: "Tha Mae Lop",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51170,
    district_code: 5102,
    province_code: 39,
    name: "แม่ทา ทาแม่ลอบ ลำพูน 51170",
    name_th: "แม่ทา ทาแม่ลอบ ลำพูน 51170",
    name_en: "Mae Tha, Tha Mae Lop, Lamphun, 51170",
  },
  {
    id: 510301,
    districtTh: "บ้านโฮ่ง",
    districtEn: "Ban Hong",
    subdistrictTh: "บ้านโฮ่ง",
    subdistrictEn: "Ban Hong",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51130,
    district_code: 5103,
    province_code: 39,
    name: "บ้านโฮ่ง บ้านโฮ่ง ลำพูน 51130",
    name_th: "บ้านโฮ่ง บ้านโฮ่ง ลำพูน 51130",
    name_en: "Ban Hong, Ban Hong, Lamphun, 51130",
  },
  {
    id: 510302,
    districtTh: "บ้านโฮ่ง",
    districtEn: "Ban Hong",
    subdistrictTh: "ป่าพลู",
    subdistrictEn: "Pa Phlu",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51130,
    district_code: 5103,
    province_code: 39,
    name: "บ้านโฮ่ง ป่าพลู ลำพูน 51130",
    name_th: "บ้านโฮ่ง ป่าพลู ลำพูน 51130",
    name_en: "Ban Hong, Pa Phlu, Lamphun, 51130",
  },
  {
    id: 510303,
    districtTh: "บ้านโฮ่ง",
    districtEn: "Ban Hong",
    subdistrictTh: "เหล่ายาว",
    subdistrictEn: "Lao Yao",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51130,
    district_code: 5103,
    province_code: 39,
    name: "บ้านโฮ่ง เหล่ายาว ลำพูน 51130",
    name_th: "บ้านโฮ่ง เหล่ายาว ลำพูน 51130",
    name_en: "Ban Hong, Lao Yao, Lamphun, 51130",
  },
  {
    id: 510304,
    districtTh: "บ้านโฮ่ง",
    districtEn: "Ban Hong",
    subdistrictTh: "ศรีเตี้ย",
    subdistrictEn: "Si Tia",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51130,
    district_code: 5103,
    province_code: 39,
    name: "บ้านโฮ่ง ศรีเตี้ย ลำพูน 51130",
    name_th: "บ้านโฮ่ง ศรีเตี้ย ลำพูน 51130",
    name_en: "Ban Hong, Si Tia, Lamphun, 51130",
  },
  {
    id: 510305,
    districtTh: "บ้านโฮ่ง",
    districtEn: "Ban Hong",
    subdistrictTh: "หนองปลาสะวาย",
    subdistrictEn: "Nong Pla Sawai",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51130,
    district_code: 5103,
    province_code: 39,
    name: "บ้านโฮ่ง หนองปลาสะวาย ลำพูน 51130",
    name_th: "บ้านโฮ่ง หนองปลาสะวาย ลำพูน 51130",
    name_en: "Ban Hong, Nong Pla Sawai, Lamphun, 51130",
  },
  {
    id: 510401,
    districtTh: "ลี้",
    districtEn: "Li",
    subdistrictTh: "ลี้",
    subdistrictEn: "Li",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51110,
    district_code: 5104,
    province_code: 39,
    name: "ลี้ ลี้ ลำพูน 51110",
    name_th: "ลี้ ลี้ ลำพูน 51110",
    name_en: "Li, Li, Lamphun, 51110",
  },
  {
    id: 510402,
    districtTh: "ลี้",
    districtEn: "Li",
    subdistrictTh: "แม่ตืน",
    subdistrictEn: "Mae Tuen",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51110,
    district_code: 5104,
    province_code: 39,
    name: "ลี้ แม่ตืน ลำพูน 51110",
    name_th: "ลี้ แม่ตืน ลำพูน 51110",
    name_en: "Li, Mae Tuen, Lamphun, 51110",
  },
  {
    id: 510403,
    districtTh: "ลี้",
    districtEn: "Li",
    subdistrictTh: "นาทราย",
    subdistrictEn: "Na Sai",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51110,
    district_code: 5104,
    province_code: 39,
    name: "ลี้ นาทราย ลำพูน 51110",
    name_th: "ลี้ นาทราย ลำพูน 51110",
    name_en: "Li, Na Sai, Lamphun, 51110",
  },
  {
    id: 510404,
    districtTh: "ลี้",
    districtEn: "Li",
    subdistrictTh: "ดงดำ",
    subdistrictEn: "Dong Dam",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51110,
    district_code: 5104,
    province_code: 39,
    name: "ลี้ ดงดำ ลำพูน 51110",
    name_th: "ลี้ ดงดำ ลำพูน 51110",
    name_en: "Li, Dong Dam, Lamphun, 51110",
  },
  {
    id: 510405,
    districtTh: "ลี้",
    districtEn: "Li",
    subdistrictTh: "ก้อ",
    subdistrictEn: "Ko",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51110,
    district_code: 5104,
    province_code: 39,
    name: "ลี้ ก้อ ลำพูน 51110",
    name_th: "ลี้ ก้อ ลำพูน 51110",
    name_en: "Li, Ko, Lamphun, 51110",
  },
  {
    id: 510406,
    districtTh: "ลี้",
    districtEn: "Li",
    subdistrictTh: "แม่ลาน",
    subdistrictEn: "Mae Lan",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51110,
    district_code: 5104,
    province_code: 39,
    name: "ลี้ แม่ลาน ลำพูน 51110",
    name_th: "ลี้ แม่ลาน ลำพูน 51110",
    name_en: "Li, Mae Lan, Lamphun, 51110",
  },
  {
    id: 510408,
    districtTh: "ลี้",
    districtEn: "Li",
    subdistrictTh: "ป่าไผ่",
    subdistrictEn: "Pa Phai",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51110,
    district_code: 5104,
    province_code: 39,
    name: "ลี้ ป่าไผ่ ลำพูน 51110",
    name_th: "ลี้ ป่าไผ่ ลำพูน 51110",
    name_en: "Li, Pa Phai, Lamphun, 51110",
  },
  {
    id: 510409,
    districtTh: "ลี้",
    districtEn: "Li",
    subdistrictTh: "ศรีวิชัย",
    subdistrictEn: "Si Wichai",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51110,
    district_code: 5104,
    province_code: 39,
    name: "ลี้ ศรีวิชัย ลำพูน 51110",
    name_th: "ลี้ ศรีวิชัย ลำพูน 51110",
    name_en: "Li, Si Wichai, Lamphun, 51110",
  },
  {
    id: 510501,
    districtTh: "ทุ่งหัวช้าง",
    districtEn: "Thung Hua Chang",
    subdistrictTh: "ทุ่งหัวช้าง",
    subdistrictEn: "Thung Hua Chang",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51160,
    district_code: 5105,
    province_code: 39,
    name: "ทุ่งหัวช้าง ทุ่งหัวช้าง ลำพูน 51160",
    name_th: "ทุ่งหัวช้าง ทุ่งหัวช้าง ลำพูน 51160",
    name_en: "Thung Hua Chang, Thung Hua Chang, Lamphun, 51160",
  },
  {
    id: 510502,
    districtTh: "ทุ่งหัวช้าง",
    districtEn: "Thung Hua Chang",
    subdistrictTh: "บ้านปวง",
    subdistrictEn: "Ban Puang",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51160,
    district_code: 5105,
    province_code: 39,
    name: "ทุ่งหัวช้าง บ้านปวง ลำพูน 51160",
    name_th: "ทุ่งหัวช้าง บ้านปวง ลำพูน 51160",
    name_en: "Thung Hua Chang, Ban Puang, Lamphun, 51160",
  },
  {
    id: 510503,
    districtTh: "ทุ่งหัวช้าง",
    districtEn: "Thung Hua Chang",
    subdistrictTh: "ตะเคียนปม",
    subdistrictEn: "Takhian Pom",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51160,
    district_code: 5105,
    province_code: 39,
    name: "ทุ่งหัวช้าง ตะเคียนปม ลำพูน 51160",
    name_th: "ทุ่งหัวช้าง ตะเคียนปม ลำพูน 51160",
    name_en: "Thung Hua Chang, Takhian Pom, Lamphun, 51160",
  },
  {
    id: 510601,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "ปากบ่อง",
    subdistrictEn: "Pak Bong",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง ปากบ่อง ลำพูน 51120",
    name_th: "ป่าซาง ปากบ่อง ลำพูน 51120",
    name_en: "Pa Sang, Pak Bong, Lamphun, 51120",
  },
  {
    id: 510602,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "ป่าซาง",
    subdistrictEn: "Pa Sang",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง ป่าซาง ลำพูน 51120",
    name_th: "ป่าซาง ป่าซาง ลำพูน 51120",
    name_en: "Pa Sang, Pa Sang, Lamphun, 51120",
  },
  {
    id: 510603,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "แม่แรง",
    subdistrictEn: "Mae Raeng",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง แม่แรง ลำพูน 51120",
    name_th: "ป่าซาง แม่แรง ลำพูน 51120",
    name_en: "Pa Sang, Mae Raeng, Lamphun, 51120",
  },
  {
    id: 510604,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "ม่วงน้อย",
    subdistrictEn: "Muang Noi",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง ม่วงน้อย ลำพูน 51120",
    name_th: "ป่าซาง ม่วงน้อย ลำพูน 51120",
    name_en: "Pa Sang, Muang Noi, Lamphun, 51120",
  },
  {
    id: 510605,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "บ้านเรือน",
    subdistrictEn: "Ban Ruean",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง บ้านเรือน ลำพูน 51120",
    name_th: "ป่าซาง บ้านเรือน ลำพูน 51120",
    name_en: "Pa Sang, Ban Ruean, Lamphun, 51120",
  },
  {
    id: 510606,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "มะกอก",
    subdistrictEn: "Makok",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง มะกอก ลำพูน 51120",
    name_th: "ป่าซาง มะกอก ลำพูน 51120",
    name_en: "Pa Sang, Makok, Lamphun, 51120",
  },
  {
    id: 510607,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "ท่าตุ้ม",
    subdistrictEn: "Tha Tum",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง ท่าตุ้ม ลำพูน 51120",
    name_th: "ป่าซาง ท่าตุ้ม ลำพูน 51120",
    name_en: "Pa Sang, Tha Tum, Lamphun, 51120",
  },
  {
    id: 510608,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "น้ำดิบ",
    subdistrictEn: "Nam Dip",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง น้ำดิบ ลำพูน 51120",
    name_th: "ป่าซาง น้ำดิบ ลำพูน 51120",
    name_en: "Pa Sang, Nam Dip, Lamphun, 51120",
  },
  {
    id: 510611,
    districtTh: "ป่าซาง",
    districtEn: "Pa Sang",
    subdistrictTh: "นครเจดีย์",
    subdistrictEn: "Nakhon Chedi",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5106,
    province_code: 39,
    name: "ป่าซาง นครเจดีย์ ลำพูน 51120",
    name_th: "ป่าซาง นครเจดีย์ ลำพูน 51120",
    name_en: "Pa Sang, Nakhon Chedi, Lamphun, 51120",
  },
  {
    id: 510701,
    districtTh: "บ้านธิ",
    districtEn: "Ban Thi",
    subdistrictTh: "บ้านธิ",
    subdistrictEn: "Ban Thi",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51180,
    district_code: 5107,
    province_code: 39,
    name: "บ้านธิ บ้านธิ ลำพูน 51180",
    name_th: "บ้านธิ บ้านธิ ลำพูน 51180",
    name_en: "Ban Thi, Ban Thi, Lamphun, 51180",
  },
  {
    id: 510702,
    districtTh: "บ้านธิ",
    districtEn: "Ban Thi",
    subdistrictTh: "ห้วยยาบ",
    subdistrictEn: "Huai Yap",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51180,
    district_code: 5107,
    province_code: 39,
    name: "บ้านธิ ห้วยยาบ ลำพูน 51180",
    name_th: "บ้านธิ ห้วยยาบ ลำพูน 51180",
    name_en: "Ban Thi, Huai Yap, Lamphun, 51180",
  },
  {
    id: 510801,
    districtTh: "เวียงหนองล่อง",
    districtEn: "Wiang Nong Long",
    subdistrictTh: "หนองล่อง",
    subdistrictEn: "Nong Long",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5108,
    province_code: 39,
    name: "เวียงหนองล่อง หนองล่อง ลำพูน 51120",
    name_th: "เวียงหนองล่อง หนองล่อง ลำพูน 51120",
    name_en: "Wiang Nong Long, Nong Long, Lamphun, 51120",
  },
  {
    id: 510802,
    districtTh: "เวียงหนองล่อง",
    districtEn: "Wiang Nong Long",
    subdistrictTh: "หนองยวง",
    subdistrictEn: "Nong Yuang",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5108,
    province_code: 39,
    name: "เวียงหนองล่อง หนองยวง ลำพูน 51120",
    name_th: "เวียงหนองล่อง หนองยวง ลำพูน 51120",
    name_en: "Wiang Nong Long, Nong Yuang, Lamphun, 51120",
  },
  {
    id: 510803,
    districtTh: "เวียงหนองล่อง",
    districtEn: "Wiang Nong Long",
    subdistrictTh: "วังผาง",
    subdistrictEn: "Wang Phang",
    provinceTh: "ลำพูน",
    provinceEn: "Lamphun",
    zipcode: 51120,
    district_code: 5108,
    province_code: 39,
    name: "เวียงหนองล่อง วังผาง ลำพูน 51120",
    name_th: "เวียงหนองล่อง วังผาง ลำพูน 51120",
    name_en: "Wiang Nong Long, Wang Phang, Lamphun, 51120",
  },
  {
    id: 520101,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "เวียงเหนือ",
    subdistrictEn: "Wiang Nuea",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง เวียงเหนือ ลำปาง 52000",
    name_th: "เมืองลำปาง เวียงเหนือ ลำปาง 52000",
    name_en: "Mueang Lampang, Wiang Nuea, Lampang, 52000",
  },
  {
    id: 520102,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "หัวเวียง",
    subdistrictEn: "Hua Wiang",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง หัวเวียง ลำปาง 52000",
    name_th: "เมืองลำปาง หัวเวียง ลำปาง 52000",
    name_en: "Mueang Lampang, Hua Wiang, Lampang, 52000",
  },
  {
    id: 520103,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "สวนดอก",
    subdistrictEn: "Suan Dok",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52100,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง สวนดอก ลำปาง 52100",
    name_th: "เมืองลำปาง สวนดอก ลำปาง 52100",
    name_en: "Mueang Lampang, Suan Dok, Lampang, 52100",
  },
  {
    id: 520104,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "สบตุ๋ย",
    subdistrictEn: "Sop Tui",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52100,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง สบตุ๋ย ลำปาง 52100",
    name_th: "เมืองลำปาง สบตุ๋ย ลำปาง 52100",
    name_en: "Mueang Lampang, Sop Tui, Lampang, 52100",
  },
  {
    id: 520105,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "พระบาท",
    subdistrictEn: "Phra Bat",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง พระบาท ลำปาง 52000",
    name_th: "เมืองลำปาง พระบาท ลำปาง 52000",
    name_en: "Mueang Lampang, Phra Bat, Lampang, 52000",
  },
  {
    id: 520106,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "ชมพู",
    subdistrictEn: "Chomphu",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52100,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง ชมพู ลำปาง 52100",
    name_th: "เมืองลำปาง ชมพู ลำปาง 52100",
    name_en: "Mueang Lampang, Chomphu, Lampang, 52100",
  },
  {
    id: 520107,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "กล้วยแพะ",
    subdistrictEn: "Kluai Phae",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง กล้วยแพะ ลำปาง 52000",
    name_th: "เมืองลำปาง กล้วยแพะ ลำปาง 52000",
    name_en: "Mueang Lampang, Kluai Phae, Lampang, 52000",
  },
  {
    id: 520108,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "ปงแสนทอง",
    subdistrictEn: "Pong Saen Thong",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52100,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง ปงแสนทอง ลำปาง 52100",
    name_th: "เมืองลำปาง ปงแสนทอง ลำปาง 52100",
    name_en: "Mueang Lampang, Pong Saen Thong, Lampang, 52100",
  },
  {
    id: 520109,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "บ้านแลง",
    subdistrictEn: "Ban Laeng",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง บ้านแลง ลำปาง 52000",
    name_th: "เมืองลำปาง บ้านแลง ลำปาง 52000",
    name_en: "Mueang Lampang, Ban Laeng, Lampang, 52000",
  },
  {
    id: 520110,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "บ้านเสด็จ",
    subdistrictEn: "Ban Sadet",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง บ้านเสด็จ ลำปาง 52000",
    name_th: "เมืองลำปาง บ้านเสด็จ ลำปาง 52000",
    name_en: "Mueang Lampang, Ban Sadet, Lampang, 52000",
  },
  {
    id: 520111,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "พิชัย",
    subdistrictEn: "Phichai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง พิชัย ลำปาง 52000",
    name_th: "เมืองลำปาง พิชัย ลำปาง 52000",
    name_en: "Mueang Lampang, Phichai, Lampang, 52000",
  },
  {
    id: 520112,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "ทุ่งฝาย",
    subdistrictEn: "Thung Fai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง ทุ่งฝาย ลำปาง 52000",
    name_th: "เมืองลำปาง ทุ่งฝาย ลำปาง 52000",
    name_en: "Mueang Lampang, Thung Fai, Lampang, 52000",
  },
  {
    id: 520113,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "บ้านเอื้อม",
    subdistrictEn: "Ban Ueam",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52100,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง บ้านเอื้อม ลำปาง 52100",
    name_th: "เมืองลำปาง บ้านเอื้อม ลำปาง 52100",
    name_en: "Mueang Lampang, Ban Ueam, Lampang, 52100",
  },
  {
    id: 520114,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "บ้านเป้า",
    subdistrictEn: "Ban Pao",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52100,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง บ้านเป้า ลำปาง 52100",
    name_th: "เมืองลำปาง บ้านเป้า ลำปาง 52100",
    name_en: "Mueang Lampang, Ban Pao, Lampang, 52100",
  },
  {
    id: 520115,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "บ้านค่า",
    subdistrictEn: "Ban Kha",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52100,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง บ้านค่า ลำปาง 52100",
    name_th: "เมืองลำปาง บ้านค่า ลำปาง 52100",
    name_en: "Mueang Lampang, Ban Kha, Lampang, 52100",
  },
  {
    id: 520116,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "บ่อแฮ้ว",
    subdistrictEn: "Bo Haeo",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52100,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง บ่อแฮ้ว ลำปาง 52100",
    name_th: "เมืองลำปาง บ่อแฮ้ว ลำปาง 52100",
    name_en: "Mueang Lampang, Bo Haeo, Lampang, 52100",
  },
  {
    id: 520117,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "ต้นธงชัย",
    subdistrictEn: "Ton Thong Chai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง ต้นธงชัย ลำปาง 52000",
    name_th: "เมืองลำปาง ต้นธงชัย ลำปาง 52000",
    name_en: "Mueang Lampang, Ton Thong Chai, Lampang, 52000",
  },
  {
    id: 520118,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "นิคมพัฒนา",
    subdistrictEn: "Nikhom Phatthana",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง นิคมพัฒนา ลำปาง 52000",
    name_th: "เมืองลำปาง นิคมพัฒนา ลำปาง 52000",
    name_en: "Mueang Lampang, Nikhom Phatthana, Lampang, 52000",
  },
  {
    id: 520119,
    districtTh: "เมืองลำปาง",
    districtEn: "Mueang Lampang",
    subdistrictTh: "บุญนาคพัฒนา",
    subdistrictEn: "Bunnak Phatthana",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52000,
    district_code: 5201,
    province_code: 40,
    name: "เมืองลำปาง บุญนาคพัฒนา ลำปาง 52000",
    name_th: "เมืองลำปาง บุญนาคพัฒนา ลำปาง 52000",
    name_en: "Mueang Lampang, Bunnak Phatthana, Lampang, 52000",
  },
  {
    id: 520201,
    districtTh: "แม่เมาะ",
    districtEn: "Mae Mo",
    subdistrictTh: "บ้านดง",
    subdistrictEn: "Ban Dong",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52220,
    district_code: 5202,
    province_code: 40,
    name: "แม่เมาะ บ้านดง ลำปาง 52220",
    name_th: "แม่เมาะ บ้านดง ลำปาง 52220",
    name_en: "Mae Mo, Ban Dong, Lampang, 52220",
  },
  {
    id: 520202,
    districtTh: "แม่เมาะ",
    districtEn: "Mae Mo",
    subdistrictTh: "นาสัก",
    subdistrictEn: "Na Sak",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52220,
    district_code: 5202,
    province_code: 40,
    name: "แม่เมาะ นาสัก ลำปาง 52220",
    name_th: "แม่เมาะ นาสัก ลำปาง 52220",
    name_en: "Mae Mo, Na Sak, Lampang, 52220",
  },
  {
    id: 520203,
    districtTh: "แม่เมาะ",
    districtEn: "Mae Mo",
    subdistrictTh: "จางเหนือ",
    subdistrictEn: "Chang Nuea",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52220,
    district_code: 5202,
    province_code: 40,
    name: "แม่เมาะ จางเหนือ ลำปาง 52220",
    name_th: "แม่เมาะ จางเหนือ ลำปาง 52220",
    name_en: "Mae Mo, Chang Nuea, Lampang, 52220",
  },
  {
    id: 520204,
    districtTh: "แม่เมาะ",
    districtEn: "Mae Mo",
    subdistrictTh: "แม่เมาะ",
    subdistrictEn: "Mae Mo",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52220,
    district_code: 5202,
    province_code: 40,
    name: "แม่เมาะ แม่เมาะ ลำปาง 52220",
    name_th: "แม่เมาะ แม่เมาะ ลำปาง 52220",
    name_en: "Mae Mo, Mae Mo, Lampang, 52220",
  },
  {
    id: 520205,
    districtTh: "แม่เมาะ",
    districtEn: "Mae Mo",
    subdistrictTh: "สบป้าด",
    subdistrictEn: "Sop Pat",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52220,
    district_code: 5202,
    province_code: 40,
    name: "แม่เมาะ สบป้าด ลำปาง 52220",
    name_th: "แม่เมาะ สบป้าด ลำปาง 52220",
    name_en: "Mae Mo, Sop Pat, Lampang, 52220",
  },
  {
    id: 520301,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "ลำปางหลวง",
    subdistrictEn: "Lampang Luang",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา ลำปางหลวง ลำปาง 52130",
    name_th: "เกาะคา ลำปางหลวง ลำปาง 52130",
    name_en: "Ko Kha, Lampang Luang, Lampang, 52130",
  },
  {
    id: 520302,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "นาแก้ว",
    subdistrictEn: "Na Kaeo",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา นาแก้ว ลำปาง 52130",
    name_th: "เกาะคา นาแก้ว ลำปาง 52130",
    name_en: "Ko Kha, Na Kaeo, Lampang, 52130",
  },
  {
    id: 520303,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "ไหล่หิน",
    subdistrictEn: "Lai Hin",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา ไหล่หิน ลำปาง 52130",
    name_th: "เกาะคา ไหล่หิน ลำปาง 52130",
    name_en: "Ko Kha, Lai Hin, Lampang, 52130",
  },
  {
    id: 520304,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "วังพร้าว",
    subdistrictEn: "Wang Phrao",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา วังพร้าว ลำปาง 52130",
    name_th: "เกาะคา วังพร้าว ลำปาง 52130",
    name_en: "Ko Kha, Wang Phrao, Lampang, 52130",
  },
  {
    id: 520305,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "ศาลา",
    subdistrictEn: "Sala",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา ศาลา ลำปาง 52130",
    name_th: "เกาะคา ศาลา ลำปาง 52130",
    name_en: "Ko Kha, Sala, Lampang, 52130",
  },
  {
    id: 520306,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "เกาะคา",
    subdistrictEn: "Ko Kha",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา เกาะคา ลำปาง 52130",
    name_th: "เกาะคา เกาะคา ลำปาง 52130",
    name_en: "Ko Kha, Ko Kha, Lampang, 52130",
  },
  {
    id: 520307,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "นาแส่ง",
    subdistrictEn: "Na Saeng",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา นาแส่ง ลำปาง 52130",
    name_th: "เกาะคา นาแส่ง ลำปาง 52130",
    name_en: "Ko Kha, Na Saeng, Lampang, 52130",
  },
  {
    id: 520308,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "ท่าผา",
    subdistrictEn: "Tha Pha",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา ท่าผา ลำปาง 52130",
    name_th: "เกาะคา ท่าผา ลำปาง 52130",
    name_en: "Ko Kha, Tha Pha, Lampang, 52130",
  },
  {
    id: 520309,
    districtTh: "เกาะคา",
    districtEn: "Ko Kha",
    subdistrictTh: "ใหม่พัฒนา",
    subdistrictEn: "Mai Phatthana",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52130,
    district_code: 5203,
    province_code: 40,
    name: "เกาะคา ใหม่พัฒนา ลำปาง 52130",
    name_th: "เกาะคา ใหม่พัฒนา ลำปาง 52130",
    name_en: "Ko Kha, Mai Phatthana, Lampang, 52130",
  },
  {
    id: 520401,
    districtTh: "เสริมงาม",
    districtEn: "Soem Ngam",
    subdistrictTh: "ทุ่งงาม",
    subdistrictEn: "Thung Ngam",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52210,
    district_code: 5204,
    province_code: 40,
    name: "เสริมงาม ทุ่งงาม ลำปาง 52210",
    name_th: "เสริมงาม ทุ่งงาม ลำปาง 52210",
    name_en: "Soem Ngam, Thung Ngam, Lampang, 52210",
  },
  {
    id: 520402,
    districtTh: "เสริมงาม",
    districtEn: "Soem Ngam",
    subdistrictTh: "เสริมขวา",
    subdistrictEn: "Soem Khwa",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52210,
    district_code: 5204,
    province_code: 40,
    name: "เสริมงาม เสริมขวา ลำปาง 52210",
    name_th: "เสริมงาม เสริมขวา ลำปาง 52210",
    name_en: "Soem Ngam, Soem Khwa, Lampang, 52210",
  },
  {
    id: 520403,
    districtTh: "เสริมงาม",
    districtEn: "Soem Ngam",
    subdistrictTh: "เสริมซ้าย",
    subdistrictEn: "Soem Sai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52210,
    district_code: 5204,
    province_code: 40,
    name: "เสริมงาม เสริมซ้าย ลำปาง 52210",
    name_th: "เสริมงาม เสริมซ้าย ลำปาง 52210",
    name_en: "Soem Ngam, Soem Sai, Lampang, 52210",
  },
  {
    id: 520404,
    districtTh: "เสริมงาม",
    districtEn: "Soem Ngam",
    subdistrictTh: "เสริมกลาง",
    subdistrictEn: "Soem Klang",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52210,
    district_code: 5204,
    province_code: 40,
    name: "เสริมงาม เสริมกลาง ลำปาง 52210",
    name_th: "เสริมงาม เสริมกลาง ลำปาง 52210",
    name_en: "Soem Ngam, Soem Klang, Lampang, 52210",
  },
  {
    id: 520501,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "หลวงเหนือ",
    subdistrictEn: "Luang Nuea",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว หลวงเหนือ ลำปาง 52110",
    name_th: "งาว หลวงเหนือ ลำปาง 52110",
    name_en: "Ngao, Luang Nuea, Lampang, 52110",
  },
  {
    id: 520502,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "หลวงใต้",
    subdistrictEn: "Luang Tai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว หลวงใต้ ลำปาง 52110",
    name_th: "งาว หลวงใต้ ลำปาง 52110",
    name_en: "Ngao, Luang Tai, Lampang, 52110",
  },
  {
    id: 520503,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "บ้านโป่ง",
    subdistrictEn: "Ban Pong",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว บ้านโป่ง ลำปาง 52110",
    name_th: "งาว บ้านโป่ง ลำปาง 52110",
    name_en: "Ngao, Ban Pong, Lampang, 52110",
  },
  {
    id: 520504,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "บ้านร้อง",
    subdistrictEn: "Ban Rong",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว บ้านร้อง ลำปาง 52110",
    name_th: "งาว บ้านร้อง ลำปาง 52110",
    name_en: "Ngao, Ban Rong, Lampang, 52110",
  },
  {
    id: 520505,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "ปงเตา",
    subdistrictEn: "Pong Tao",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว ปงเตา ลำปาง 52110",
    name_th: "งาว ปงเตา ลำปาง 52110",
    name_en: "Ngao, Pong Tao, Lampang, 52110",
  },
  {
    id: 520506,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "นาแก",
    subdistrictEn: "Na Kae",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว นาแก ลำปาง 52110",
    name_th: "งาว นาแก ลำปาง 52110",
    name_en: "Ngao, Na Kae, Lampang, 52110",
  },
  {
    id: 520507,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "บ้านอ้อน",
    subdistrictEn: "Ban On",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว บ้านอ้อน ลำปาง 52110",
    name_th: "งาว บ้านอ้อน ลำปาง 52110",
    name_en: "Ngao, Ban On, Lampang, 52110",
  },
  {
    id: 520508,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "บ้านแหง",
    subdistrictEn: "Ban Haeng",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว บ้านแหง ลำปาง 52110",
    name_th: "งาว บ้านแหง ลำปาง 52110",
    name_en: "Ngao, Ban Haeng, Lampang, 52110",
  },
  {
    id: 520509,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "บ้านหวด",
    subdistrictEn: "Ban Huat",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว บ้านหวด ลำปาง 52110",
    name_th: "งาว บ้านหวด ลำปาง 52110",
    name_en: "Ngao, Ban Huat, Lampang, 52110",
  },
  {
    id: 520510,
    districtTh: "งาว",
    districtEn: "Ngao",
    subdistrictTh: "แม่ตีบ",
    subdistrictEn: "Mae Tip",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52110,
    district_code: 5205,
    province_code: 40,
    name: "งาว แม่ตีบ ลำปาง 52110",
    name_th: "งาว แม่ตีบ ลำปาง 52110",
    name_en: "Ngao, Mae Tip, Lampang, 52110",
  },
  {
    id: 520601,
    districtTh: "แจ้ห่ม",
    districtEn: "Chae Hom",
    subdistrictTh: "แจ้ห่ม",
    subdistrictEn: "Chae Hom",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52120,
    district_code: 5206,
    province_code: 40,
    name: "แจ้ห่ม แจ้ห่ม ลำปาง 52120",
    name_th: "แจ้ห่ม แจ้ห่ม ลำปาง 52120",
    name_en: "Chae Hom, Chae Hom, Lampang, 52120",
  },
  {
    id: 520602,
    districtTh: "แจ้ห่ม",
    districtEn: "Chae Hom",
    subdistrictTh: "บ้านสา",
    subdistrictEn: "Ban Sa",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52120,
    district_code: 5206,
    province_code: 40,
    name: "แจ้ห่ม บ้านสา ลำปาง 52120",
    name_th: "แจ้ห่ม บ้านสา ลำปาง 52120",
    name_en: "Chae Hom, Ban Sa, Lampang, 52120",
  },
  {
    id: 520603,
    districtTh: "แจ้ห่ม",
    districtEn: "Chae Hom",
    subdistrictTh: "ปงดอน",
    subdistrictEn: "Pong Don",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52120,
    district_code: 5206,
    province_code: 40,
    name: "แจ้ห่ม ปงดอน ลำปาง 52120",
    name_th: "แจ้ห่ม ปงดอน ลำปาง 52120",
    name_en: "Chae Hom, Pong Don, Lampang, 52120",
  },
  {
    id: 520604,
    districtTh: "แจ้ห่ม",
    districtEn: "Chae Hom",
    subdistrictTh: "แม่สุก",
    subdistrictEn: "Mae Suk",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52120,
    district_code: 5206,
    province_code: 40,
    name: "แจ้ห่ม แม่สุก ลำปาง 52120",
    name_th: "แจ้ห่ม แม่สุก ลำปาง 52120",
    name_en: "Chae Hom, Mae Suk, Lampang, 52120",
  },
  {
    id: 520605,
    districtTh: "แจ้ห่ม",
    districtEn: "Chae Hom",
    subdistrictTh: "เมืองมาย",
    subdistrictEn: "Mueang Mai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52120,
    district_code: 5206,
    province_code: 40,
    name: "แจ้ห่ม เมืองมาย ลำปาง 52120",
    name_th: "แจ้ห่ม เมืองมาย ลำปาง 52120",
    name_en: "Chae Hom, Mueang Mai, Lampang, 52120",
  },
  {
    id: 520606,
    districtTh: "แจ้ห่ม",
    districtEn: "Chae Hom",
    subdistrictTh: "ทุ่งผึ้ง",
    subdistrictEn: "Thung Phueng",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52120,
    district_code: 5206,
    province_code: 40,
    name: "แจ้ห่ม ทุ่งผึ้ง ลำปาง 52120",
    name_th: "แจ้ห่ม ทุ่งผึ้ง ลำปาง 52120",
    name_en: "Chae Hom, Thung Phueng, Lampang, 52120",
  },
  {
    id: 520607,
    districtTh: "แจ้ห่ม",
    districtEn: "Chae Hom",
    subdistrictTh: "วิเชตนคร",
    subdistrictEn: "Wichet Nakhon",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52120,
    district_code: 5206,
    province_code: 40,
    name: "แจ้ห่ม วิเชตนคร ลำปาง 52120",
    name_th: "แจ้ห่ม วิเชตนคร ลำปาง 52120",
    name_en: "Chae Hom, Wichet Nakhon, Lampang, 52120",
  },
  {
    id: 520701,
    districtTh: "วังเหนือ",
    districtEn: "Wang Nuea",
    subdistrictTh: "ทุ่งฮั้ว",
    subdistrictEn: "Thung Hua",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52140,
    district_code: 5207,
    province_code: 40,
    name: "วังเหนือ ทุ่งฮั้ว ลำปาง 52140",
    name_th: "วังเหนือ ทุ่งฮั้ว ลำปาง 52140",
    name_en: "Wang Nuea, Thung Hua, Lampang, 52140",
  },
  {
    id: 520702,
    districtTh: "วังเหนือ",
    districtEn: "Wang Nuea",
    subdistrictTh: "วังเหนือ",
    subdistrictEn: "Wang Nuea",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52140,
    district_code: 5207,
    province_code: 40,
    name: "วังเหนือ วังเหนือ ลำปาง 52140",
    name_th: "วังเหนือ วังเหนือ ลำปาง 52140",
    name_en: "Wang Nuea, Wang Nuea, Lampang, 52140",
  },
  {
    id: 520703,
    districtTh: "วังเหนือ",
    districtEn: "Wang Nuea",
    subdistrictTh: "วังใต้",
    subdistrictEn: "Wang Tai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52140,
    district_code: 5207,
    province_code: 40,
    name: "วังเหนือ วังใต้ ลำปาง 52140",
    name_th: "วังเหนือ วังใต้ ลำปาง 52140",
    name_en: "Wang Nuea, Wang Tai, Lampang, 52140",
  },
  {
    id: 520704,
    districtTh: "วังเหนือ",
    districtEn: "Wang Nuea",
    subdistrictTh: "ร่องเคาะ",
    subdistrictEn: "Rong Kho",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52140,
    district_code: 5207,
    province_code: 40,
    name: "วังเหนือ ร่องเคาะ ลำปาง 52140",
    name_th: "วังเหนือ ร่องเคาะ ลำปาง 52140",
    name_en: "Wang Nuea, Rong Kho, Lampang, 52140",
  },
  {
    id: 520705,
    districtTh: "วังเหนือ",
    districtEn: "Wang Nuea",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52140,
    district_code: 5207,
    province_code: 40,
    name: "วังเหนือ วังทอง ลำปาง 52140",
    name_th: "วังเหนือ วังทอง ลำปาง 52140",
    name_en: "Wang Nuea, Wang Thong, Lampang, 52140",
  },
  {
    id: 520706,
    districtTh: "วังเหนือ",
    districtEn: "Wang Nuea",
    subdistrictTh: "วังซ้าย",
    subdistrictEn: "Wang Sai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52140,
    district_code: 5207,
    province_code: 40,
    name: "วังเหนือ วังซ้าย ลำปาง 52140",
    name_th: "วังเหนือ วังซ้าย ลำปาง 52140",
    name_en: "Wang Nuea, Wang Sai, Lampang, 52140",
  },
  {
    id: 520707,
    districtTh: "วังเหนือ",
    districtEn: "Wang Nuea",
    subdistrictTh: "วังแก้ว",
    subdistrictEn: "Wang Kaeo",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52140,
    district_code: 5207,
    province_code: 40,
    name: "วังเหนือ วังแก้ว ลำปาง 52140",
    name_th: "วังเหนือ วังแก้ว ลำปาง 52140",
    name_en: "Wang Nuea, Wang Kaeo, Lampang, 52140",
  },
  {
    id: 520708,
    districtTh: "วังเหนือ",
    districtEn: "Wang Nuea",
    subdistrictTh: "วังทรายคำ",
    subdistrictEn: "Wang Sai Kham",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52140,
    district_code: 5207,
    province_code: 40,
    name: "วังเหนือ วังทรายคำ ลำปาง 52140",
    name_th: "วังเหนือ วังทรายคำ ลำปาง 52140",
    name_en: "Wang Nuea, Wang Sai Kham, Lampang, 52140",
  },
  {
    id: 520801,
    districtTh: "เถิน",
    districtEn: "Thoen",
    subdistrictTh: "ล้อมแรด",
    subdistrictEn: "Lom Raet",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52160,
    district_code: 5208,
    province_code: 40,
    name: "เถิน ล้อมแรด ลำปาง 52160",
    name_th: "เถิน ล้อมแรด ลำปาง 52160",
    name_en: "Thoen, Lom Raet, Lampang, 52160",
  },
  {
    id: 520802,
    districtTh: "เถิน",
    districtEn: "Thoen",
    subdistrictTh: "แม่วะ",
    subdistrictEn: "Mae Wa",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52230,
    district_code: 5208,
    province_code: 40,
    name: "เถิน แม่วะ ลำปาง 52230",
    name_th: "เถิน แม่วะ ลำปาง 52230",
    name_en: "Thoen, Mae Wa, Lampang, 52230",
  },
  {
    id: 520803,
    districtTh: "เถิน",
    districtEn: "Thoen",
    subdistrictTh: "แม่ปะ",
    subdistrictEn: "Mae Pa",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52160,
    district_code: 5208,
    province_code: 40,
    name: "เถิน แม่ปะ ลำปาง 52160",
    name_th: "เถิน แม่ปะ ลำปาง 52160",
    name_en: "Thoen, Mae Pa, Lampang, 52160",
  },
  {
    id: 520804,
    districtTh: "เถิน",
    districtEn: "Thoen",
    subdistrictTh: "แม่มอก",
    subdistrictEn: "Mae Mok",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52160,
    district_code: 5208,
    province_code: 40,
    name: "เถิน แม่มอก ลำปาง 52160",
    name_th: "เถิน แม่มอก ลำปาง 52160",
    name_en: "Thoen, Mae Mok, Lampang, 52160",
  },
  {
    id: 520805,
    districtTh: "เถิน",
    districtEn: "Thoen",
    subdistrictTh: "เวียงมอก",
    subdistrictEn: "Wiang Mok",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52160,
    district_code: 5208,
    province_code: 40,
    name: "เถิน เวียงมอก ลำปาง 52160",
    name_th: "เถิน เวียงมอก ลำปาง 52160",
    name_en: "Thoen, Wiang Mok, Lampang, 52160",
  },
  {
    id: 520806,
    districtTh: "เถิน",
    districtEn: "Thoen",
    subdistrictTh: "นาโป่ง",
    subdistrictEn: "Na Pong",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52160,
    district_code: 5208,
    province_code: 40,
    name: "เถิน นาโป่ง ลำปาง 52160",
    name_th: "เถิน นาโป่ง ลำปาง 52160",
    name_en: "Thoen, Na Pong, Lampang, 52160",
  },
  {
    id: 520807,
    districtTh: "เถิน",
    districtEn: "Thoen",
    subdistrictTh: "แม่ถอด",
    subdistrictEn: "Mae Thot",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52160,
    district_code: 5208,
    province_code: 40,
    name: "เถิน แม่ถอด ลำปาง 52160",
    name_th: "เถิน แม่ถอด ลำปาง 52160",
    name_en: "Thoen, Mae Thot, Lampang, 52160",
  },
  {
    id: 520808,
    districtTh: "เถิน",
    districtEn: "Thoen",
    subdistrictTh: "เถินบุรี",
    subdistrictEn: "Thoen Buri",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52160,
    district_code: 5208,
    province_code: 40,
    name: "เถิน เถินบุรี ลำปาง 52160",
    name_th: "เถิน เถินบุรี ลำปาง 52160",
    name_en: "Thoen, Thoen Buri, Lampang, 52160",
  },
  {
    id: 520901,
    districtTh: "แม่พริก",
    districtEn: "Mae Phrik",
    subdistrictTh: "แม่พริก",
    subdistrictEn: "Mae Phrik",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52180,
    district_code: 5209,
    province_code: 40,
    name: "แม่พริก แม่พริก ลำปาง 52180",
    name_th: "แม่พริก แม่พริก ลำปาง 52180",
    name_en: "Mae Phrik, Mae Phrik, Lampang, 52180",
  },
  {
    id: 520902,
    districtTh: "แม่พริก",
    districtEn: "Mae Phrik",
    subdistrictTh: "ผาปัง",
    subdistrictEn: "Pha Pang",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52180,
    district_code: 5209,
    province_code: 40,
    name: "แม่พริก ผาปัง ลำปาง 52180",
    name_th: "แม่พริก ผาปัง ลำปาง 52180",
    name_en: "Mae Phrik, Pha Pang, Lampang, 52180",
  },
  {
    id: 520903,
    districtTh: "แม่พริก",
    districtEn: "Mae Phrik",
    subdistrictTh: "แม่ปุ",
    subdistrictEn: "Mae Pu",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52180,
    district_code: 5209,
    province_code: 40,
    name: "แม่พริก แม่ปุ ลำปาง 52180",
    name_th: "แม่พริก แม่ปุ ลำปาง 52180",
    name_en: "Mae Phrik, Mae Pu, Lampang, 52180",
  },
  {
    id: 520904,
    districtTh: "แม่พริก",
    districtEn: "Mae Phrik",
    subdistrictTh: "พระบาทวังตวง",
    subdistrictEn: "Phra Bat Wang Tuang",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52180,
    district_code: 5209,
    province_code: 40,
    name: "แม่พริก พระบาทวังตวง ลำปาง 52180",
    name_th: "แม่พริก พระบาทวังตวง ลำปาง 52180",
    name_en: "Mae Phrik, Phra Bat Wang Tuang, Lampang, 52180",
  },
  {
    id: 521001,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "แม่ทะ",
    subdistrictEn: "Mae Tha",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ แม่ทะ ลำปาง 52150",
    name_th: "แม่ทะ แม่ทะ ลำปาง 52150",
    name_en: "Mae Tha, Mae Tha, Lampang, 52150",
  },
  {
    id: 521002,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "นาครัว",
    subdistrictEn: "Na Khrua",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ นาครัว ลำปาง 52150",
    name_th: "แม่ทะ นาครัว ลำปาง 52150",
    name_en: "Mae Tha, Na Khrua, Lampang, 52150",
  },
  {
    id: 521003,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "ป่าตัน",
    subdistrictEn: "Pa Tan",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ ป่าตัน ลำปาง 52150",
    name_th: "แม่ทะ ป่าตัน ลำปาง 52150",
    name_en: "Mae Tha, Pa Tan, Lampang, 52150",
  },
  {
    id: 521004,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "บ้านกิ่ว",
    subdistrictEn: "Ban Kio",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ บ้านกิ่ว ลำปาง 52150",
    name_th: "แม่ทะ บ้านกิ่ว ลำปาง 52150",
    name_en: "Mae Tha, Ban Kio, Lampang, 52150",
  },
  {
    id: 521005,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "บ้านบอม",
    subdistrictEn: "Ban Bom",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ บ้านบอม ลำปาง 52150",
    name_th: "แม่ทะ บ้านบอม ลำปาง 52150",
    name_en: "Mae Tha, Ban Bom, Lampang, 52150",
  },
  {
    id: 521006,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "น้ำโจ้",
    subdistrictEn: "Nam Cho",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ น้ำโจ้ ลำปาง 52150",
    name_th: "แม่ทะ น้ำโจ้ ลำปาง 52150",
    name_en: "Mae Tha, Nam Cho, Lampang, 52150",
  },
  {
    id: 521007,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "ดอนไฟ",
    subdistrictEn: "Don Fai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ ดอนไฟ ลำปาง 52150",
    name_th: "แม่ทะ ดอนไฟ ลำปาง 52150",
    name_en: "Mae Tha, Don Fai, Lampang, 52150",
  },
  {
    id: 521008,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "หัวเสือ",
    subdistrictEn: "Hua Suea",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ หัวเสือ ลำปาง 52150",
    name_th: "แม่ทะ หัวเสือ ลำปาง 52150",
    name_en: "Mae Tha, Hua Suea, Lampang, 52150",
  },
  {
    id: 521010,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "วังเงิน",
    subdistrictEn: "Wang Ngoen",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ วังเงิน ลำปาง 52150",
    name_th: "แม่ทะ วังเงิน ลำปาง 52150",
    name_en: "Mae Tha, Wang Ngoen, Lampang, 52150",
  },
  {
    id: 521011,
    districtTh: "แม่ทะ",
    districtEn: "Mae Tha",
    subdistrictTh: "สันดอนแก้ว",
    subdistrictEn: "San Don Kaeo",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52150,
    district_code: 5210,
    province_code: 40,
    name: "แม่ทะ สันดอนแก้ว ลำปาง 52150",
    name_th: "แม่ทะ สันดอนแก้ว ลำปาง 52150",
    name_en: "Mae Tha, San Don Kaeo, Lampang, 52150",
  },
  {
    id: 521101,
    districtTh: "สบปราบ",
    districtEn: "Sop Prap",
    subdistrictTh: "สบปราบ",
    subdistrictEn: "Sop Prap",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52170,
    district_code: 5211,
    province_code: 40,
    name: "สบปราบ สบปราบ ลำปาง 52170",
    name_th: "สบปราบ สบปราบ ลำปาง 52170",
    name_en: "Sop Prap, Sop Prap, Lampang, 52170",
  },
  {
    id: 521102,
    districtTh: "สบปราบ",
    districtEn: "Sop Prap",
    subdistrictTh: "สมัย",
    subdistrictEn: "Samai",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52170,
    district_code: 5211,
    province_code: 40,
    name: "สบปราบ สมัย ลำปาง 52170",
    name_th: "สบปราบ สมัย ลำปาง 52170",
    name_en: "Sop Prap, Samai, Lampang, 52170",
  },
  {
    id: 521103,
    districtTh: "สบปราบ",
    districtEn: "Sop Prap",
    subdistrictTh: "แม่กัวะ",
    subdistrictEn: "Mae Kua",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52170,
    district_code: 5211,
    province_code: 40,
    name: "สบปราบ แม่กัวะ ลำปาง 52170",
    name_th: "สบปราบ แม่กัวะ ลำปาง 52170",
    name_en: "Sop Prap, Mae Kua, Lampang, 52170",
  },
  {
    id: 521104,
    districtTh: "สบปราบ",
    districtEn: "Sop Prap",
    subdistrictTh: "นายาง",
    subdistrictEn: "Na Yang",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52170,
    district_code: 5211,
    province_code: 40,
    name: "สบปราบ นายาง ลำปาง 52170",
    name_th: "สบปราบ นายาง ลำปาง 52170",
    name_en: "Sop Prap, Na Yang, Lampang, 52170",
  },
  {
    id: 521201,
    districtTh: "ห้างฉัตร",
    districtEn: "Hang Chat",
    subdistrictTh: "ห้างฉัตร",
    subdistrictEn: "Hang Chat",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52190,
    district_code: 5212,
    province_code: 40,
    name: "ห้างฉัตร ห้างฉัตร ลำปาง 52190",
    name_th: "ห้างฉัตร ห้างฉัตร ลำปาง 52190",
    name_en: "Hang Chat, Hang Chat, Lampang, 52190",
  },
  {
    id: 521202,
    districtTh: "ห้างฉัตร",
    districtEn: "Hang Chat",
    subdistrictTh: "หนองหล่ม",
    subdistrictEn: "Nong Lom",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52190,
    district_code: 5212,
    province_code: 40,
    name: "ห้างฉัตร หนองหล่ม ลำปาง 52190",
    name_th: "ห้างฉัตร หนองหล่ม ลำปาง 52190",
    name_en: "Hang Chat, Nong Lom, Lampang, 52190",
  },
  {
    id: 521203,
    districtTh: "ห้างฉัตร",
    districtEn: "Hang Chat",
    subdistrictTh: "เมืองยาว",
    subdistrictEn: "Mueang Yao",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52190,
    district_code: 5212,
    province_code: 40,
    name: "ห้างฉัตร เมืองยาว ลำปาง 52190",
    name_th: "ห้างฉัตร เมืองยาว ลำปาง 52190",
    name_en: "Hang Chat, Mueang Yao, Lampang, 52190",
  },
  {
    id: 521204,
    districtTh: "ห้างฉัตร",
    districtEn: "Hang Chat",
    subdistrictTh: "ปงยางคก",
    subdistrictEn: "Pong Yang Khok",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52190,
    district_code: 5212,
    province_code: 40,
    name: "ห้างฉัตร ปงยางคก ลำปาง 52190",
    name_th: "ห้างฉัตร ปงยางคก ลำปาง 52190",
    name_en: "Hang Chat, Pong Yang Khok, Lampang, 52190",
  },
  {
    id: 521205,
    districtTh: "ห้างฉัตร",
    districtEn: "Hang Chat",
    subdistrictTh: "เวียงตาล",
    subdistrictEn: "Wiang Tan",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52190,
    district_code: 5212,
    province_code: 40,
    name: "ห้างฉัตร เวียงตาล ลำปาง 52190",
    name_th: "ห้างฉัตร เวียงตาล ลำปาง 52190",
    name_en: "Hang Chat, Wiang Tan, Lampang, 52190",
  },
  {
    id: 521206,
    districtTh: "ห้างฉัตร",
    districtEn: "Hang Chat",
    subdistrictTh: "แม่สัน",
    subdistrictEn: "Mae San",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52190,
    district_code: 5212,
    province_code: 40,
    name: "ห้างฉัตร แม่สัน ลำปาง 52190",
    name_th: "ห้างฉัตร แม่สัน ลำปาง 52190",
    name_en: "Hang Chat, Mae San, Lampang, 52190",
  },
  {
    id: 521207,
    districtTh: "ห้างฉัตร",
    districtEn: "Hang Chat",
    subdistrictTh: "วอแก้ว",
    subdistrictEn: "Wo Kaeo",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52190,
    district_code: 5212,
    province_code: 40,
    name: "ห้างฉัตร วอแก้ว ลำปาง 52190",
    name_th: "ห้างฉัตร วอแก้ว ลำปาง 52190",
    name_en: "Hang Chat, Wo Kaeo, Lampang, 52190",
  },
  {
    id: 521301,
    districtTh: "เมืองปาน",
    districtEn: "Mueang Pan",
    subdistrictTh: "เมืองปาน",
    subdistrictEn: "Mueang Pan",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52240,
    district_code: 5213,
    province_code: 40,
    name: "เมืองปาน เมืองปาน ลำปาง 52240",
    name_th: "เมืองปาน เมืองปาน ลำปาง 52240",
    name_en: "Mueang Pan, Mueang Pan, Lampang, 52240",
  },
  {
    id: 521302,
    districtTh: "เมืองปาน",
    districtEn: "Mueang Pan",
    subdistrictTh: "บ้านขอ",
    subdistrictEn: "Ban Kho",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52240,
    district_code: 5213,
    province_code: 40,
    name: "เมืองปาน บ้านขอ ลำปาง 52240",
    name_th: "เมืองปาน บ้านขอ ลำปาง 52240",
    name_en: "Mueang Pan, Ban Kho, Lampang, 52240",
  },
  {
    id: 521303,
    districtTh: "เมืองปาน",
    districtEn: "Mueang Pan",
    subdistrictTh: "ทุ่งกว๋าว",
    subdistrictEn: "Thung Kwao",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52240,
    district_code: 5213,
    province_code: 40,
    name: "เมืองปาน ทุ่งกว๋าว ลำปาง 52240",
    name_th: "เมืองปาน ทุ่งกว๋าว ลำปาง 52240",
    name_en: "Mueang Pan, Thung Kwao, Lampang, 52240",
  },
  {
    id: 521304,
    districtTh: "เมืองปาน",
    districtEn: "Mueang Pan",
    subdistrictTh: "แจ้ซ้อน",
    subdistrictEn: "Chae Son",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52240,
    district_code: 5213,
    province_code: 40,
    name: "เมืองปาน แจ้ซ้อน ลำปาง 52240",
    name_th: "เมืองปาน แจ้ซ้อน ลำปาง 52240",
    name_en: "Mueang Pan, Chae Son, Lampang, 52240",
  },
  {
    id: 521305,
    districtTh: "เมืองปาน",
    districtEn: "Mueang Pan",
    subdistrictTh: "หัวเมือง",
    subdistrictEn: "Hua Mueang",
    provinceTh: "ลำปาง",
    provinceEn: "Lampang",
    zipcode: 52240,
    district_code: 5213,
    province_code: 40,
    name: "เมืองปาน หัวเมือง ลำปาง 52240",
    name_th: "เมืองปาน หัวเมือง ลำปาง 52240",
    name_en: "Mueang Pan, Hua Mueang, Lampang, 52240",
  },
  {
    id: 530101,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "ท่าอิฐ",
    subdistrictEn: "Tha It",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ ท่าอิฐ อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ ท่าอิฐ อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Tha It, Uttaradit, 53000",
  },
  {
    id: 530102,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "ท่าเสา",
    subdistrictEn: "Tha Sao",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ ท่าเสา อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ ท่าเสา อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Tha Sao, Uttaradit, 53000",
  },
  {
    id: 530103,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "บ้านเกาะ",
    subdistrictEn: "Ban Ko",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ บ้านเกาะ อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ บ้านเกาะ อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Ban Ko, Uttaradit, 53000",
  },
  {
    id: 530104,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "ป่าเซ่า",
    subdistrictEn: "Pa Sao",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ ป่าเซ่า อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ ป่าเซ่า อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Pa Sao, Uttaradit, 53000",
  },
  {
    id: 530105,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "คุ้งตะเภา",
    subdistrictEn: "Khung Taphao",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ คุ้งตะเภา อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ คุ้งตะเภา อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Khung Taphao, Uttaradit, 53000",
  },
  {
    id: 530106,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "วังกะพี้",
    subdistrictEn: "Wang Kaphi",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53170,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ วังกะพี้ อุตรดิตถ์ 53170",
    name_th: "เมืองอุตรดิตถ์ วังกะพี้ อุตรดิตถ์ 53170",
    name_en: "Mueang Uttaradit, Wang Kaphi, Uttaradit, 53170",
  },
  {
    id: 530107,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "หาดกรวด",
    subdistrictEn: "Hat Kruat",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ หาดกรวด อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ หาดกรวด อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Hat Kruat, Uttaradit, 53000",
  },
  {
    id: 530108,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "น้ำริด",
    subdistrictEn: "Nam Rit",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ น้ำริด อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ น้ำริด อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Nam Rit, Uttaradit, 53000",
  },
  {
    id: 530109,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "งิ้วงาม",
    subdistrictEn: "Ngio Ngam",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ งิ้วงาม อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ งิ้วงาม อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Ngio Ngam, Uttaradit, 53000",
  },
  {
    id: 530110,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "บ้านด่านนาขาม",
    subdistrictEn: "Ban Dan Na Kham",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ บ้านด่านนาขาม อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ บ้านด่านนาขาม อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Ban Dan Na Kham, Uttaradit, 53000",
  },
  {
    id: 530111,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "บ้านด่าน",
    subdistrictEn: "Ban Dan",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ บ้านด่าน อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ บ้านด่าน อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Ban Dan, Uttaradit, 53000",
  },
  {
    id: 530112,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "ผาจุก",
    subdistrictEn: "Pha Chuk",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ ผาจุก อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ ผาจุก อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Pha Chuk, Uttaradit, 53000",
  },
  {
    id: 530113,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "วังดิน",
    subdistrictEn: "Wang Din",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ วังดิน อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ วังดิน อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Wang Din, Uttaradit, 53000",
  },
  {
    id: 530114,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "แสนตอ",
    subdistrictEn: "Saen To",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ แสนตอ อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ แสนตอ อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Saen To, Uttaradit, 53000",
  },
  {
    id: 530115,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "หาดงิ้ว",
    subdistrictEn: "Hat Ngio",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ หาดงิ้ว อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ หาดงิ้ว อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Hat Ngio, Uttaradit, 53000",
  },
  {
    id: 530116,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "ขุนฝาง",
    subdistrictEn: "Khun Fang",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ ขุนฝาง อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ ขุนฝาง อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Khun Fang, Uttaradit, 53000",
  },
  {
    id: 530117,
    districtTh: "เมืองอุตรดิตถ์",
    districtEn: "Mueang Uttaradit",
    subdistrictTh: "ถ้ำฉลอง",
    subdistrictEn: "Tham Chalong",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53000,
    district_code: 5301,
    province_code: 41,
    name: "เมืองอุตรดิตถ์ ถ้ำฉลอง อุตรดิตถ์ 53000",
    name_th: "เมืองอุตรดิตถ์ ถ้ำฉลอง อุตรดิตถ์ 53000",
    name_en: "Mueang Uttaradit, Tham Chalong, Uttaradit, 53000",
  },
  {
    id: 530201,
    districtTh: "ตรอน",
    districtEn: "Tron",
    subdistrictTh: "วังแดง",
    subdistrictEn: "Wang Daeng",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53140,
    district_code: 5302,
    province_code: 41,
    name: "ตรอน วังแดง อุตรดิตถ์ 53140",
    name_th: "ตรอน วังแดง อุตรดิตถ์ 53140",
    name_en: "Tron, Wang Daeng, Uttaradit, 53140",
  },
  {
    id: 530202,
    districtTh: "ตรอน",
    districtEn: "Tron",
    subdistrictTh: "บ้านแก่ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53140,
    district_code: 5302,
    province_code: 41,
    name: "ตรอน บ้านแก่ง อุตรดิตถ์ 53140",
    name_th: "ตรอน บ้านแก่ง อุตรดิตถ์ 53140",
    name_en: "Tron, Ban Kaeng, Uttaradit, 53140",
  },
  {
    id: 530203,
    districtTh: "ตรอน",
    districtEn: "Tron",
    subdistrictTh: "หาดสองแคว",
    subdistrictEn: "Hat Song Khwae",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53140,
    district_code: 5302,
    province_code: 41,
    name: "ตรอน หาดสองแคว อุตรดิตถ์ 53140",
    name_th: "ตรอน หาดสองแคว อุตรดิตถ์ 53140",
    name_en: "Tron, Hat Song Khwae, Uttaradit, 53140",
  },
  {
    id: 530204,
    districtTh: "ตรอน",
    districtEn: "Tron",
    subdistrictTh: "น้ำอ่าง",
    subdistrictEn: "Nam Ang",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53140,
    district_code: 5302,
    province_code: 41,
    name: "ตรอน น้ำอ่าง อุตรดิตถ์ 53140",
    name_th: "ตรอน น้ำอ่าง อุตรดิตถ์ 53140",
    name_en: "Tron, Nam Ang, Uttaradit, 53140",
  },
  {
    id: 530205,
    districtTh: "ตรอน",
    districtEn: "Tron",
    subdistrictTh: "ข่อยสูง",
    subdistrictEn: "Khoi Sung",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53140,
    district_code: 5302,
    province_code: 41,
    name: "ตรอน ข่อยสูง อุตรดิตถ์ 53140",
    name_th: "ตรอน ข่อยสูง อุตรดิตถ์ 53140",
    name_en: "Tron, Khoi Sung, Uttaradit, 53140",
  },
  {
    id: 530301,
    districtTh: "ท่าปลา",
    districtEn: "Tha Pla",
    subdistrictTh: "ท่าปลา",
    subdistrictEn: "Tha Pla",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53150,
    district_code: 5303,
    province_code: 41,
    name: "ท่าปลา ท่าปลา อุตรดิตถ์ 53150",
    name_th: "ท่าปลา ท่าปลา อุตรดิตถ์ 53150",
    name_en: "Tha Pla, Tha Pla, Uttaradit, 53150",
  },
  {
    id: 530302,
    districtTh: "ท่าปลา",
    districtEn: "Tha Pla",
    subdistrictTh: "หาดล้า",
    subdistrictEn: "Hat La",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53150,
    district_code: 5303,
    province_code: 41,
    name: "ท่าปลา หาดล้า อุตรดิตถ์ 53150",
    name_th: "ท่าปลา หาดล้า อุตรดิตถ์ 53150",
    name_en: "Tha Pla, Hat La, Uttaradit, 53150",
  },
  {
    id: 530303,
    districtTh: "ท่าปลา",
    districtEn: "Tha Pla",
    subdistrictTh: "ผาเลือด",
    subdistrictEn: "Pha Lueat",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53190,
    district_code: 5303,
    province_code: 41,
    name: "ท่าปลา ผาเลือด อุตรดิตถ์ 53190",
    name_th: "ท่าปลา ผาเลือด อุตรดิตถ์ 53190",
    name_en: "Tha Pla, Pha Lueat, Uttaradit, 53190",
  },
  {
    id: 530304,
    districtTh: "ท่าปลา",
    districtEn: "Tha Pla",
    subdistrictTh: "จริม",
    subdistrictEn: "Charim",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53150,
    district_code: 5303,
    province_code: 41,
    name: "ท่าปลา จริม อุตรดิตถ์ 53150",
    name_th: "ท่าปลา จริม อุตรดิตถ์ 53150",
    name_en: "Tha Pla, Charim, Uttaradit, 53150",
  },
  {
    id: 530305,
    districtTh: "ท่าปลา",
    districtEn: "Tha Pla",
    subdistrictTh: "น้ำหมัน",
    subdistrictEn: "Nam Man",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53150,
    district_code: 5303,
    province_code: 41,
    name: "ท่าปลา น้ำหมัน อุตรดิตถ์ 53150",
    name_th: "ท่าปลา น้ำหมัน อุตรดิตถ์ 53150",
    name_en: "Tha Pla, Nam Man, Uttaradit, 53150",
  },
  {
    id: 530306,
    districtTh: "ท่าปลา",
    districtEn: "Tha Pla",
    subdistrictTh: "ท่าแฝก",
    subdistrictEn: "Tha Faek",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53110,
    district_code: 5303,
    province_code: 41,
    name: "ท่าปลา ท่าแฝก อุตรดิตถ์ 53110",
    name_th: "ท่าปลา ท่าแฝก อุตรดิตถ์ 53110",
    name_en: "Tha Pla, Tha Faek, Uttaradit, 53110",
  },
  {
    id: 530307,
    districtTh: "ท่าปลา",
    districtEn: "Tha Pla",
    subdistrictTh: "นางพญา",
    subdistrictEn: "Nang Phaya",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53150,
    district_code: 5303,
    province_code: 41,
    name: "ท่าปลา นางพญา อุตรดิตถ์ 53150",
    name_th: "ท่าปลา นางพญา อุตรดิตถ์ 53150",
    name_en: "Tha Pla, Nang Phaya, Uttaradit, 53150",
  },
  {
    id: 530308,
    districtTh: "ท่าปลา",
    districtEn: "Tha Pla",
    subdistrictTh: "ร่วมจิต",
    subdistrictEn: "Ruam Chit",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53190,
    district_code: 5303,
    province_code: 41,
    name: "ท่าปลา ร่วมจิต อุตรดิตถ์ 53190",
    name_th: "ท่าปลา ร่วมจิต อุตรดิตถ์ 53190",
    name_en: "Tha Pla, Ruam Chit, Uttaradit, 53190",
  },
  {
    id: 530401,
    districtTh: "น้ำปาด",
    districtEn: "Nam Pat",
    subdistrictTh: "แสนตอ",
    subdistrictEn: "Saen To",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53110,
    district_code: 5304,
    province_code: 41,
    name: "น้ำปาด แสนตอ อุตรดิตถ์ 53110",
    name_th: "น้ำปาด แสนตอ อุตรดิตถ์ 53110",
    name_en: "Nam Pat, Saen To, Uttaradit, 53110",
  },
  {
    id: 530402,
    districtTh: "น้ำปาด",
    districtEn: "Nam Pat",
    subdistrictTh: "บ้านฝาย",
    subdistrictEn: "Ban Fai",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53110,
    district_code: 5304,
    province_code: 41,
    name: "น้ำปาด บ้านฝาย อุตรดิตถ์ 53110",
    name_th: "น้ำปาด บ้านฝาย อุตรดิตถ์ 53110",
    name_en: "Nam Pat, Ban Fai, Uttaradit, 53110",
  },
  {
    id: 530403,
    districtTh: "น้ำปาด",
    districtEn: "Nam Pat",
    subdistrictTh: "เด่นเหล็ก",
    subdistrictEn: "Den Lek",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53110,
    district_code: 5304,
    province_code: 41,
    name: "น้ำปาด เด่นเหล็ก อุตรดิตถ์ 53110",
    name_th: "น้ำปาด เด่นเหล็ก อุตรดิตถ์ 53110",
    name_en: "Nam Pat, Den Lek, Uttaradit, 53110",
  },
  {
    id: 530404,
    districtTh: "น้ำปาด",
    districtEn: "Nam Pat",
    subdistrictTh: "น้ำไคร้",
    subdistrictEn: "Nam Khrai",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53110,
    district_code: 5304,
    province_code: 41,
    name: "น้ำปาด น้ำไคร้ อุตรดิตถ์ 53110",
    name_th: "น้ำปาด น้ำไคร้ อุตรดิตถ์ 53110",
    name_en: "Nam Pat, Nam Khrai, Uttaradit, 53110",
  },
  {
    id: 530405,
    districtTh: "น้ำปาด",
    districtEn: "Nam Pat",
    subdistrictTh: "น้ำไผ่",
    subdistrictEn: "Nam Phai",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53110,
    district_code: 5304,
    province_code: 41,
    name: "น้ำปาด น้ำไผ่ อุตรดิตถ์ 53110",
    name_th: "น้ำปาด น้ำไผ่ อุตรดิตถ์ 53110",
    name_en: "Nam Pat, Nam Phai, Uttaradit, 53110",
  },
  {
    id: 530406,
    districtTh: "น้ำปาด",
    districtEn: "Nam Pat",
    subdistrictTh: "ห้วยมุ่น",
    subdistrictEn: "Huai Mun",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53110,
    district_code: 5304,
    province_code: 41,
    name: "น้ำปาด ห้วยมุ่น อุตรดิตถ์ 53110",
    name_th: "น้ำปาด ห้วยมุ่น อุตรดิตถ์ 53110",
    name_en: "Nam Pat, Huai Mun, Uttaradit, 53110",
  },
  {
    id: 530501,
    districtTh: "ฟากท่า",
    districtEn: "Fak Tha",
    subdistrictTh: "ฟากท่า",
    subdistrictEn: "Fak Tha",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53160,
    district_code: 5305,
    province_code: 41,
    name: "ฟากท่า ฟากท่า อุตรดิตถ์ 53160",
    name_th: "ฟากท่า ฟากท่า อุตรดิตถ์ 53160",
    name_en: "Fak Tha, Fak Tha, Uttaradit, 53160",
  },
  {
    id: 530502,
    districtTh: "ฟากท่า",
    districtEn: "Fak Tha",
    subdistrictTh: "สองคอน",
    subdistrictEn: "Song Khon",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53160,
    district_code: 5305,
    province_code: 41,
    name: "ฟากท่า สองคอน อุตรดิตถ์ 53160",
    name_th: "ฟากท่า สองคอน อุตรดิตถ์ 53160",
    name_en: "Fak Tha, Song Khon, Uttaradit, 53160",
  },
  {
    id: 530503,
    districtTh: "ฟากท่า",
    districtEn: "Fak Tha",
    subdistrictTh: "บ้านเสี้ยว",
    subdistrictEn: "Ban Siao",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53160,
    district_code: 5305,
    province_code: 41,
    name: "ฟากท่า บ้านเสี้ยว อุตรดิตถ์ 53160",
    name_th: "ฟากท่า บ้านเสี้ยว อุตรดิตถ์ 53160",
    name_en: "Fak Tha, Ban Siao, Uttaradit, 53160",
  },
  {
    id: 530504,
    districtTh: "ฟากท่า",
    districtEn: "Fak Tha",
    subdistrictTh: "สองห้อง",
    subdistrictEn: "Song Hong",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53160,
    district_code: 5305,
    province_code: 41,
    name: "ฟากท่า สองห้อง อุตรดิตถ์ 53160",
    name_th: "ฟากท่า สองห้อง อุตรดิตถ์ 53160",
    name_en: "Fak Tha, Song Hong, Uttaradit, 53160",
  },
  {
    id: 530601,
    districtTh: "บ้านโคก",
    districtEn: "Ban Khok",
    subdistrictTh: "ม่วงเจ็ดต้น",
    subdistrictEn: "Muang Chet Ton",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53180,
    district_code: 5306,
    province_code: 41,
    name: "บ้านโคก ม่วงเจ็ดต้น อุตรดิตถ์ 53180",
    name_th: "บ้านโคก ม่วงเจ็ดต้น อุตรดิตถ์ 53180",
    name_en: "Ban Khok, Muang Chet Ton, Uttaradit, 53180",
  },
  {
    id: 530602,
    districtTh: "บ้านโคก",
    districtEn: "Ban Khok",
    subdistrictTh: "บ้านโคก",
    subdistrictEn: "Ban Khok",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53180,
    district_code: 5306,
    province_code: 41,
    name: "บ้านโคก บ้านโคก อุตรดิตถ์ 53180",
    name_th: "บ้านโคก บ้านโคก อุตรดิตถ์ 53180",
    name_en: "Ban Khok, Ban Khok, Uttaradit, 53180",
  },
  {
    id: 530603,
    districtTh: "บ้านโคก",
    districtEn: "Ban Khok",
    subdistrictTh: "นาขุม",
    subdistrictEn: "Na Khum",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53180,
    district_code: 5306,
    province_code: 41,
    name: "บ้านโคก นาขุม อุตรดิตถ์ 53180",
    name_th: "บ้านโคก นาขุม อุตรดิตถ์ 53180",
    name_en: "Ban Khok, Na Khum, Uttaradit, 53180",
  },
  {
    id: 530604,
    districtTh: "บ้านโคก",
    districtEn: "Ban Khok",
    subdistrictTh: "บ่อเบี้ย",
    subdistrictEn: "Bo Bia",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53180,
    district_code: 5306,
    province_code: 41,
    name: "บ้านโคก บ่อเบี้ย อุตรดิตถ์ 53180",
    name_th: "บ้านโคก บ่อเบี้ย อุตรดิตถ์ 53180",
    name_en: "Ban Khok, Bo Bia, Uttaradit, 53180",
  },
  {
    id: 530701,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย ในเมือง อุตรดิตถ์ 53120",
    name_th: "พิชัย ในเมือง อุตรดิตถ์ 53120",
    name_en: "Phichai, Nai Mueang, Uttaradit, 53120",
  },
  {
    id: 530702,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "บ้านดารา",
    subdistrictEn: "Ban Dara",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53220,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย บ้านดารา อุตรดิตถ์ 53220",
    name_th: "พิชัย บ้านดารา อุตรดิตถ์ 53220",
    name_en: "Phichai, Ban Dara, Uttaradit, 53220",
  },
  {
    id: 530703,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "ไร่อ้อย",
    subdistrictEn: "Rai Oi",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย ไร่อ้อย อุตรดิตถ์ 53120",
    name_th: "พิชัย ไร่อ้อย อุตรดิตถ์ 53120",
    name_en: "Phichai, Rai Oi, Uttaradit, 53120",
  },
  {
    id: 530704,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "ท่าสัก",
    subdistrictEn: "Tha Sak",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53220,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย ท่าสัก อุตรดิตถ์ 53220",
    name_th: "พิชัย ท่าสัก อุตรดิตถ์ 53220",
    name_en: "Phichai, Tha Sak, Uttaradit, 53220",
  },
  {
    id: 530705,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "คอรุม",
    subdistrictEn: "Kho Rum",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย คอรุม อุตรดิตถ์ 53120",
    name_th: "พิชัย คอรุม อุตรดิตถ์ 53120",
    name_en: "Phichai, Kho Rum, Uttaradit, 53120",
  },
  {
    id: 530706,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "บ้านหม้อ",
    subdistrictEn: "Ban Mo",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย บ้านหม้อ อุตรดิตถ์ 53120",
    name_th: "พิชัย บ้านหม้อ อุตรดิตถ์ 53120",
    name_en: "Phichai, Ban Mo, Uttaradit, 53120",
  },
  {
    id: 530707,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "ท่ามะเฟือง",
    subdistrictEn: "Tha Mafueang",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย ท่ามะเฟือง อุตรดิตถ์ 53120",
    name_th: "พิชัย ท่ามะเฟือง อุตรดิตถ์ 53120",
    name_en: "Phichai, Tha Mafueang, Uttaradit, 53120",
  },
  {
    id: 530708,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "บ้านโคน",
    subdistrictEn: "Ban Khon",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย บ้านโคน อุตรดิตถ์ 53120",
    name_th: "พิชัย บ้านโคน อุตรดิตถ์ 53120",
    name_en: "Phichai, Ban Khon, Uttaradit, 53120",
  },
  {
    id: 530709,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "พญาแมน",
    subdistrictEn: "Phaya Maen",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย พญาแมน อุตรดิตถ์ 53120",
    name_th: "พิชัย พญาแมน อุตรดิตถ์ 53120",
    name_en: "Phichai, Phaya Maen, Uttaradit, 53120",
  },
  {
    id: 530710,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "นาอิน",
    subdistrictEn: "Na In",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย นาอิน อุตรดิตถ์ 53120",
    name_th: "พิชัย นาอิน อุตรดิตถ์ 53120",
    name_en: "Phichai, Na In, Uttaradit, 53120",
  },
  {
    id: 530711,
    districtTh: "พิชัย",
    districtEn: "Phichai",
    subdistrictTh: "นายาง",
    subdistrictEn: "Na Yang",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53120,
    district_code: 5307,
    province_code: 41,
    name: "พิชัย นายาง อุตรดิตถ์ 53120",
    name_th: "พิชัย นายาง อุตรดิตถ์ 53120",
    name_en: "Phichai, Na Yang, Uttaradit, 53120",
  },
  {
    id: 530801,
    districtTh: "ลับแล",
    districtEn: "Laplae",
    subdistrictTh: "ศรีพนมมาศ",
    subdistrictEn: "Si Phanom Mat",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53130,
    district_code: 5308,
    province_code: 41,
    name: "ลับแล ศรีพนมมาศ อุตรดิตถ์ 53130",
    name_th: "ลับแล ศรีพนมมาศ อุตรดิตถ์ 53130",
    name_en: "Laplae, Si Phanom Mat, Uttaradit, 53130",
  },
  {
    id: 530802,
    districtTh: "ลับแล",
    districtEn: "Laplae",
    subdistrictTh: "แม่พูล",
    subdistrictEn: "Mae Phun",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53130,
    district_code: 5308,
    province_code: 41,
    name: "ลับแล แม่พูล อุตรดิตถ์ 53130",
    name_th: "ลับแล แม่พูล อุตรดิตถ์ 53130",
    name_en: "Laplae, Mae Phun, Uttaradit, 53130",
  },
  {
    id: 530803,
    districtTh: "ลับแล",
    districtEn: "Laplae",
    subdistrictTh: "นานกกก",
    subdistrictEn: "Na Nok Kok",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53130,
    district_code: 5308,
    province_code: 41,
    name: "ลับแล นานกกก อุตรดิตถ์ 53130",
    name_th: "ลับแล นานกกก อุตรดิตถ์ 53130",
    name_en: "Laplae, Na Nok Kok, Uttaradit, 53130",
  },
  {
    id: 530804,
    districtTh: "ลับแล",
    districtEn: "Laplae",
    subdistrictTh: "ฝายหลวง",
    subdistrictEn: "Fai Luang",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53130,
    district_code: 5308,
    province_code: 41,
    name: "ลับแล ฝายหลวง อุตรดิตถ์ 53130",
    name_th: "ลับแล ฝายหลวง อุตรดิตถ์ 53130",
    name_en: "Laplae, Fai Luang, Uttaradit, 53130",
  },
  {
    id: 530805,
    districtTh: "ลับแล",
    districtEn: "Laplae",
    subdistrictTh: "ชัยจุมพล",
    subdistrictEn: "Chai Chumphon",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53130,
    district_code: 5308,
    province_code: 41,
    name: "ลับแล ชัยจุมพล อุตรดิตถ์ 53130",
    name_th: "ลับแล ชัยจุมพล อุตรดิตถ์ 53130",
    name_en: "Laplae, Chai Chumphon, Uttaradit, 53130",
  },
  {
    id: 530806,
    districtTh: "ลับแล",
    districtEn: "Laplae",
    subdistrictTh: "ไผ่ล้อม",
    subdistrictEn: "Phai Lom",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53210,
    district_code: 5308,
    province_code: 41,
    name: "ลับแล ไผ่ล้อม อุตรดิตถ์ 53210",
    name_th: "ลับแล ไผ่ล้อม อุตรดิตถ์ 53210",
    name_en: "Laplae, Phai Lom, Uttaradit, 53210",
  },
  {
    id: 530807,
    districtTh: "ลับแล",
    districtEn: "Laplae",
    subdistrictTh: "ทุ่งยั้ง",
    subdistrictEn: "Thung Yang",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53210,
    district_code: 5308,
    province_code: 41,
    name: "ลับแล ทุ่งยั้ง อุตรดิตถ์ 53210",
    name_th: "ลับแล ทุ่งยั้ง อุตรดิตถ์ 53210",
    name_en: "Laplae, Thung Yang, Uttaradit, 53210",
  },
  {
    id: 530808,
    districtTh: "ลับแล",
    districtEn: "Laplae",
    subdistrictTh: "ด่านแม่คำมัน",
    subdistrictEn: "Dan Mae Kham Man",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53210,
    district_code: 5308,
    province_code: 41,
    name: "ลับแล ด่านแม่คำมัน อุตรดิตถ์ 53210",
    name_th: "ลับแล ด่านแม่คำมัน อุตรดิตถ์ 53210",
    name_en: "Laplae, Dan Mae Kham Man, Uttaradit, 53210",
  },
  {
    id: 530901,
    districtTh: "ทองแสนขัน",
    districtEn: "Thong Saen Khan",
    subdistrictTh: "ผักขวง",
    subdistrictEn: "Phak Khuang",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53230,
    district_code: 5309,
    province_code: 41,
    name: "ทองแสนขัน ผักขวง อุตรดิตถ์ 53230",
    name_th: "ทองแสนขัน ผักขวง อุตรดิตถ์ 53230",
    name_en: "Thong Saen Khan, Phak Khuang, Uttaradit, 53230",
  },
  {
    id: 530902,
    districtTh: "ทองแสนขัน",
    districtEn: "Thong Saen Khan",
    subdistrictTh: "บ่อทอง",
    subdistrictEn: "Bo Thong",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53230,
    district_code: 5309,
    province_code: 41,
    name: "ทองแสนขัน บ่อทอง อุตรดิตถ์ 53230",
    name_th: "ทองแสนขัน บ่อทอง อุตรดิตถ์ 53230",
    name_en: "Thong Saen Khan, Bo Thong, Uttaradit, 53230",
  },
  {
    id: 530903,
    districtTh: "ทองแสนขัน",
    districtEn: "Thong Saen Khan",
    subdistrictTh: "ป่าคาย",
    subdistrictEn: "Pa Khai",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53230,
    district_code: 5309,
    province_code: 41,
    name: "ทองแสนขัน ป่าคาย อุตรดิตถ์ 53230",
    name_th: "ทองแสนขัน ป่าคาย อุตรดิตถ์ 53230",
    name_en: "Thong Saen Khan, Pa Khai, Uttaradit, 53230",
  },
  {
    id: 530904,
    districtTh: "ทองแสนขัน",
    districtEn: "Thong Saen Khan",
    subdistrictTh: "น้ำพี้",
    subdistrictEn: "Nam Phi",
    provinceTh: "อุตรดิตถ์",
    provinceEn: "Uttaradit",
    zipcode: 53230,
    district_code: 5309,
    province_code: 41,
    name: "ทองแสนขัน น้ำพี้ อุตรดิตถ์ 53230",
    name_th: "ทองแสนขัน น้ำพี้ อุตรดิตถ์ 53230",
    name_en: "Thong Saen Khan, Nam Phi, Uttaradit, 53230",
  },
  {
    id: 540101,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ในเวียง",
    subdistrictEn: "Nai Wiang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ในเวียง แพร่ 54000",
    name_th: "เมืองแพร่ ในเวียง แพร่ 54000",
    name_en: "Mueang Phrae, Nai Wiang, Phrae, 54000",
  },
  {
    id: 540102,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "นาจักร",
    subdistrictEn: "Na Chak",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ นาจักร แพร่ 54000",
    name_th: "เมืองแพร่ นาจักร แพร่ 54000",
    name_en: "Mueang Phrae, Na Chak, Phrae, 54000",
  },
  {
    id: 540103,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "น้ำชำ",
    subdistrictEn: "Nam Cham",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ น้ำชำ แพร่ 54000",
    name_th: "เมืองแพร่ น้ำชำ แพร่ 54000",
    name_en: "Mueang Phrae, Nam Cham, Phrae, 54000",
  },
  {
    id: 540104,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ป่าแดง",
    subdistrictEn: "Pa Daeng",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ป่าแดง แพร่ 54000",
    name_th: "เมืองแพร่ ป่าแดง แพร่ 54000",
    name_en: "Mueang Phrae, Pa Daeng, Phrae, 54000",
  },
  {
    id: 540105,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ทุ่งโฮ้ง",
    subdistrictEn: "Thung Hong",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ทุ่งโฮ้ง แพร่ 54000",
    name_th: "เมืองแพร่ ทุ่งโฮ้ง แพร่ 54000",
    name_en: "Mueang Phrae, Thung Hong, Phrae, 54000",
  },
  {
    id: 540106,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "เหมืองหม้อ",
    subdistrictEn: "Mueang Mo",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ เหมืองหม้อ แพร่ 54000",
    name_th: "เมืองแพร่ เหมืองหม้อ แพร่ 54000",
    name_en: "Mueang Phrae, Mueang Mo, Phrae, 54000",
  },
  {
    id: 540107,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "วังธง",
    subdistrictEn: "Wang Thong",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ วังธง แพร่ 54000",
    name_th: "เมืองแพร่ วังธง แพร่ 54000",
    name_en: "Mueang Phrae, Wang Thong, Phrae, 54000",
  },
  {
    id: 540108,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "แม่หล่าย",
    subdistrictEn: "Mae Lai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ แม่หล่าย แพร่ 54000",
    name_th: "เมืองแพร่ แม่หล่าย แพร่ 54000",
    name_en: "Mueang Phrae, Mae Lai, Phrae, 54000",
  },
  {
    id: 540109,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ห้วยม้า",
    subdistrictEn: "Huai Ma",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ห้วยม้า แพร่ 54000",
    name_th: "เมืองแพร่ ห้วยม้า แพร่ 54000",
    name_en: "Mueang Phrae, Huai Ma, Phrae, 54000",
  },
  {
    id: 540110,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ป่าแมต",
    subdistrictEn: "Pa Maet",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ป่าแมต แพร่ 54000",
    name_th: "เมืองแพร่ ป่าแมต แพร่ 54000",
    name_en: "Mueang Phrae, Pa Maet, Phrae, 54000",
  },
  {
    id: 540111,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "บ้านถิ่น",
    subdistrictEn: "Ban Thin",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ บ้านถิ่น แพร่ 54000",
    name_th: "เมืองแพร่ บ้านถิ่น แพร่ 54000",
    name_en: "Mueang Phrae, Ban Thin, Phrae, 54000",
  },
  {
    id: 540112,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "สวนเขื่อน",
    subdistrictEn: "Suan Khuean",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ สวนเขื่อน แพร่ 54000",
    name_th: "เมืองแพร่ สวนเขื่อน แพร่ 54000",
    name_en: "Mueang Phrae, Suan Khuean, Phrae, 54000",
  },
  {
    id: 540113,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "วังหงส์",
    subdistrictEn: "Wang Hong",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ วังหงส์ แพร่ 54000",
    name_th: "เมืองแพร่ วังหงส์ แพร่ 54000",
    name_en: "Mueang Phrae, Wang Hong, Phrae, 54000",
  },
  {
    id: 540114,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "แม่คำมี",
    subdistrictEn: "Mae Kham Mi",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ แม่คำมี แพร่ 54000",
    name_th: "เมืองแพร่ แม่คำมี แพร่ 54000",
    name_en: "Mueang Phrae, Mae Kham Mi, Phrae, 54000",
  },
  {
    id: 540115,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ทุ่งกวาว",
    subdistrictEn: "Thung Kwao",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ทุ่งกวาว แพร่ 54000",
    name_th: "เมืองแพร่ ทุ่งกวาว แพร่ 54000",
    name_en: "Mueang Phrae, Thung Kwao, Phrae, 54000",
  },
  {
    id: 540116,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ท่าข้าม แพร่ 54000",
    name_th: "เมืองแพร่ ท่าข้าม แพร่ 54000",
    name_en: "Mueang Phrae, Tha Kham, Phrae, 54000",
  },
  {
    id: 540117,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "แม่ยม",
    subdistrictEn: "Mae Yom",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ แม่ยม แพร่ 54000",
    name_th: "เมืองแพร่ แม่ยม แพร่ 54000",
    name_en: "Mueang Phrae, Mae Yom, Phrae, 54000",
  },
  {
    id: 540118,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ช่อแฮ",
    subdistrictEn: "Cho Hae",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ช่อแฮ แพร่ 54000",
    name_th: "เมืองแพร่ ช่อแฮ แพร่ 54000",
    name_en: "Mueang Phrae, Cho Hae, Phrae, 54000",
  },
  {
    id: 540119,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "ร่องฟอง",
    subdistrictEn: "Rong Fong",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ ร่องฟอง แพร่ 54000",
    name_th: "เมืองแพร่ ร่องฟอง แพร่ 54000",
    name_en: "Mueang Phrae, Rong Fong, Phrae, 54000",
  },
  {
    id: 540120,
    districtTh: "เมืองแพร่",
    districtEn: "Mueang Phrae",
    subdistrictTh: "กาญจนา",
    subdistrictEn: "Kanchana",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5401,
    province_code: 42,
    name: "เมืองแพร่ กาญจนา แพร่ 54000",
    name_th: "เมืองแพร่ กาญจนา แพร่ 54000",
    name_en: "Mueang Phrae, Kanchana, Phrae, 54000",
  },
  {
    id: 540201,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "ร้องกวาง",
    subdistrictEn: "Rong Kwang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง ร้องกวาง แพร่ 54140",
    name_th: "ร้องกวาง ร้องกวาง แพร่ 54140",
    name_en: "Rong Kwang, Rong Kwang, Phrae, 54140",
  },
  {
    id: 540204,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "ร้องเข็ม",
    subdistrictEn: "Rong Khem",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง ร้องเข็ม แพร่ 54140",
    name_th: "ร้องกวาง ร้องเข็ม แพร่ 54140",
    name_en: "Rong Kwang, Rong Khem, Phrae, 54140",
  },
  {
    id: 540205,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "น้ำเลา",
    subdistrictEn: "Nam Lao",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง น้ำเลา แพร่ 54140",
    name_th: "ร้องกวาง น้ำเลา แพร่ 54140",
    name_en: "Rong Kwang, Nam Lao, Phrae, 54140",
  },
  {
    id: 540206,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "บ้านเวียง",
    subdistrictEn: "Ban Wiang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง บ้านเวียง แพร่ 54140",
    name_th: "ร้องกวาง บ้านเวียง แพร่ 54140",
    name_en: "Rong Kwang, Ban Wiang, Phrae, 54140",
  },
  {
    id: 540207,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "ทุ่งศรี",
    subdistrictEn: "Thung Si",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง ทุ่งศรี แพร่ 54140",
    name_th: "ร้องกวาง ทุ่งศรี แพร่ 54140",
    name_en: "Rong Kwang, Thung Si, Phrae, 54140",
  },
  {
    id: 540208,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "แม่ยางตาล",
    subdistrictEn: "Mae Yang Tan",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง แม่ยางตาล แพร่ 54140",
    name_th: "ร้องกวาง แม่ยางตาล แพร่ 54140",
    name_en: "Rong Kwang, Mae Yang Tan, Phrae, 54140",
  },
  {
    id: 540209,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "แม่ยางฮ่อ",
    subdistrictEn: "Mae Yang Ho",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง แม่ยางฮ่อ แพร่ 54140",
    name_th: "ร้องกวาง แม่ยางฮ่อ แพร่ 54140",
    name_en: "Rong Kwang, Mae Yang Ho, Phrae, 54140",
  },
  {
    id: 540210,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "ไผ่โทน",
    subdistrictEn: "Phai Thon",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง ไผ่โทน แพร่ 54140",
    name_th: "ร้องกวาง ไผ่โทน แพร่ 54140",
    name_en: "Rong Kwang, Phai Thon, Phrae, 54140",
  },
  {
    id: 540213,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "ห้วยโรง",
    subdistrictEn: "Huai Rong",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง ห้วยโรง แพร่ 54140",
    name_th: "ร้องกวาง ห้วยโรง แพร่ 54140",
    name_en: "Rong Kwang, Huai Rong, Phrae, 54140",
  },
  {
    id: 540214,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "แม่ทราย",
    subdistrictEn: "Mae Sai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง แม่ทราย แพร่ 54140",
    name_th: "ร้องกวาง แม่ทราย แพร่ 54140",
    name_en: "Rong Kwang, Mae Sai, Phrae, 54140",
  },
  {
    id: 540215,
    districtTh: "ร้องกวาง",
    districtEn: "Rong Kwang",
    subdistrictTh: "แม่ยางร้อง",
    subdistrictEn: "Mae Yang Rong",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54140,
    district_code: 5402,
    province_code: 42,
    name: "ร้องกวาง แม่ยางร้อง แพร่ 54140",
    name_th: "ร้องกวาง แม่ยางร้อง แพร่ 54140",
    name_en: "Rong Kwang, Mae Yang Rong, Phrae, 54140",
  },
  {
    id: 540301,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "ห้วยอ้อ",
    subdistrictEn: "Huai O",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง ห้วยอ้อ แพร่ 54150",
    name_th: "ลอง ห้วยอ้อ แพร่ 54150",
    name_en: "Long, Huai O, Phrae, 54150",
  },
  {
    id: 540302,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "บ้านปิน",
    subdistrictEn: "Ban Pin",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง บ้านปิน แพร่ 54150",
    name_th: "ลอง บ้านปิน แพร่ 54150",
    name_en: "Long, Ban Pin, Phrae, 54150",
  },
  {
    id: 540303,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "ต้าผามอก",
    subdistrictEn: "Ta Pha Mok",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง ต้าผามอก แพร่ 54150",
    name_th: "ลอง ต้าผามอก แพร่ 54150",
    name_en: "Long, Ta Pha Mok, Phrae, 54150",
  },
  {
    id: 540304,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "เวียงต้า",
    subdistrictEn: "Wiang Ta",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง เวียงต้า แพร่ 54150",
    name_th: "ลอง เวียงต้า แพร่ 54150",
    name_en: "Long, Wiang Ta, Phrae, 54150",
  },
  {
    id: 540305,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "ปากกาง",
    subdistrictEn: "Pak Kang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง ปากกาง แพร่ 54150",
    name_th: "ลอง ปากกาง แพร่ 54150",
    name_en: "Long, Pak Kang, Phrae, 54150",
  },
  {
    id: 540306,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "หัวทุ่ง",
    subdistrictEn: "Hua Thung",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง หัวทุ่ง แพร่ 54150",
    name_th: "ลอง หัวทุ่ง แพร่ 54150",
    name_en: "Long, Hua Thung, Phrae, 54150",
  },
  {
    id: 540307,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "ทุ่งแล้ง",
    subdistrictEn: "Thung Laeng",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง ทุ่งแล้ง แพร่ 54150",
    name_th: "ลอง ทุ่งแล้ง แพร่ 54150",
    name_en: "Long, Thung Laeng, Phrae, 54150",
  },
  {
    id: 540308,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "บ่อเหล็กลอง",
    subdistrictEn: "Bo Lek Long",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง บ่อเหล็กลอง แพร่ 54150",
    name_th: "ลอง บ่อเหล็กลอง แพร่ 54150",
    name_en: "Long, Bo Lek Long, Phrae, 54150",
  },
  {
    id: 540309,
    districtTh: "ลอง",
    districtEn: "Long",
    subdistrictTh: "แม่ปาน",
    subdistrictEn: "Mae Pan",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54150,
    district_code: 5403,
    province_code: 42,
    name: "ลอง แม่ปาน แพร่ 54150",
    name_th: "ลอง แม่ปาน แพร่ 54150",
    name_en: "Long, Mae Pan, Phrae, 54150",
  },
  {
    id: 540401,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "สูงเม่น",
    subdistrictEn: "Sung Men",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น สูงเม่น แพร่ 54130",
    name_th: "สูงเม่น สูงเม่น แพร่ 54130",
    name_en: "Sung Men, Sung Men, Phrae, 54130",
  },
  {
    id: 540402,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "น้ำชำ",
    subdistrictEn: "Nam Cham",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น น้ำชำ แพร่ 54130",
    name_th: "สูงเม่น น้ำชำ แพร่ 54130",
    name_en: "Sung Men, Nam Cham, Phrae, 54130",
  },
  {
    id: 540403,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "หัวฝาย",
    subdistrictEn: "Hua Fai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น หัวฝาย แพร่ 54130",
    name_th: "สูงเม่น หัวฝาย แพร่ 54130",
    name_en: "Sung Men, Hua Fai, Phrae, 54130",
  },
  {
    id: 540404,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "ดอนมูล",
    subdistrictEn: "Don Mun",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น ดอนมูล แพร่ 54130",
    name_th: "สูงเม่น ดอนมูล แพร่ 54130",
    name_en: "Sung Men, Don Mun, Phrae, 54130",
  },
  {
    id: 540405,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "บ้านเหล่า",
    subdistrictEn: "Ban Lao",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น บ้านเหล่า แพร่ 54130",
    name_th: "สูงเม่น บ้านเหล่า แพร่ 54130",
    name_en: "Sung Men, Ban Lao, Phrae, 54130",
  },
  {
    id: 540406,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "บ้านกวาง",
    subdistrictEn: "Ban Kwang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น บ้านกวาง แพร่ 54130",
    name_th: "สูงเม่น บ้านกวาง แพร่ 54130",
    name_en: "Sung Men, Ban Kwang, Phrae, 54130",
  },
  {
    id: 540407,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "บ้านปง",
    subdistrictEn: "Ban Pong",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น บ้านปง แพร่ 54130",
    name_th: "สูงเม่น บ้านปง แพร่ 54130",
    name_en: "Sung Men, Ban Pong, Phrae, 54130",
  },
  {
    id: 540408,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "บ้านกาศ",
    subdistrictEn: "Ban Kat",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น บ้านกาศ แพร่ 54130",
    name_th: "สูงเม่น บ้านกาศ แพร่ 54130",
    name_en: "Sung Men, Ban Kat, Phrae, 54130",
  },
  {
    id: 540409,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "ร่องกาศ",
    subdistrictEn: "Rong Kat",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น ร่องกาศ แพร่ 54130",
    name_th: "สูงเม่น ร่องกาศ แพร่ 54130",
    name_en: "Sung Men, Rong Kat, Phrae, 54130",
  },
  {
    id: 540410,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "สบสาย",
    subdistrictEn: "Sop Sai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น สบสาย แพร่ 54130",
    name_th: "สูงเม่น สบสาย แพร่ 54130",
    name_en: "Sung Men, Sop Sai, Phrae, 54130",
  },
  {
    id: 540411,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "เวียงทอง",
    subdistrictEn: "Wiang Thong",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54000,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น เวียงทอง แพร่ 54000",
    name_th: "สูงเม่น เวียงทอง แพร่ 54000",
    name_en: "Sung Men, Wiang Thong, Phrae, 54000",
  },
  {
    id: 540412,
    districtTh: "สูงเม่น",
    districtEn: "Sung Men",
    subdistrictTh: "พระหลวง",
    subdistrictEn: "Phra Luang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54130,
    district_code: 5404,
    province_code: 42,
    name: "สูงเม่น พระหลวง แพร่ 54130",
    name_th: "สูงเม่น พระหลวง แพร่ 54130",
    name_en: "Sung Men, Phra Luang, Phrae, 54130",
  },
  {
    id: 540501,
    districtTh: "เด่นชัย",
    districtEn: "Den Chai",
    subdistrictTh: "เด่นชัย",
    subdistrictEn: "Den Chai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54110,
    district_code: 5405,
    province_code: 42,
    name: "เด่นชัย เด่นชัย แพร่ 54110",
    name_th: "เด่นชัย เด่นชัย แพร่ 54110",
    name_en: "Den Chai, Den Chai, Phrae, 54110",
  },
  {
    id: 540502,
    districtTh: "เด่นชัย",
    districtEn: "Den Chai",
    subdistrictTh: "แม่จั๊วะ",
    subdistrictEn: "Mae Chua",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54110,
    district_code: 5405,
    province_code: 42,
    name: "เด่นชัย แม่จั๊วะ แพร่ 54110",
    name_th: "เด่นชัย แม่จั๊วะ แพร่ 54110",
    name_en: "Den Chai, Mae Chua, Phrae, 54110",
  },
  {
    id: 540503,
    districtTh: "เด่นชัย",
    districtEn: "Den Chai",
    subdistrictTh: "ไทรย้อย",
    subdistrictEn: "Sai Yoi",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54110,
    district_code: 5405,
    province_code: 42,
    name: "เด่นชัย ไทรย้อย แพร่ 54110",
    name_th: "เด่นชัย ไทรย้อย แพร่ 54110",
    name_en: "Den Chai, Sai Yoi, Phrae, 54110",
  },
  {
    id: 540504,
    districtTh: "เด่นชัย",
    districtEn: "Den Chai",
    subdistrictTh: "ห้วยไร่",
    subdistrictEn: "Huai Rai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54110,
    district_code: 5405,
    province_code: 42,
    name: "เด่นชัย ห้วยไร่ แพร่ 54110",
    name_th: "เด่นชัย ห้วยไร่ แพร่ 54110",
    name_en: "Den Chai, Huai Rai, Phrae, 54110",
  },
  {
    id: 540505,
    districtTh: "เด่นชัย",
    districtEn: "Den Chai",
    subdistrictTh: "ปงป่าหวาย",
    subdistrictEn: "Pong Pa Wai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54110,
    district_code: 5405,
    province_code: 42,
    name: "เด่นชัย ปงป่าหวาย แพร่ 54110",
    name_th: "เด่นชัย ปงป่าหวาย แพร่ 54110",
    name_en: "Den Chai, Pong Pa Wai, Phrae, 54110",
  },
  {
    id: 540601,
    districtTh: "สอง",
    districtEn: "Song",
    subdistrictTh: "บ้านหนุน",
    subdistrictEn: "Ban Nun",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54120,
    district_code: 5406,
    province_code: 42,
    name: "สอง บ้านหนุน แพร่ 54120",
    name_th: "สอง บ้านหนุน แพร่ 54120",
    name_en: "Song, Ban Nun, Phrae, 54120",
  },
  {
    id: 540602,
    districtTh: "สอง",
    districtEn: "Song",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54120,
    district_code: 5406,
    province_code: 42,
    name: "สอง บ้านกลาง แพร่ 54120",
    name_th: "สอง บ้านกลาง แพร่ 54120",
    name_en: "Song, Ban Klang, Phrae, 54120",
  },
  {
    id: 540603,
    districtTh: "สอง",
    districtEn: "Song",
    subdistrictTh: "ห้วยหม้าย",
    subdistrictEn: "Huai Mai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54120,
    district_code: 5406,
    province_code: 42,
    name: "สอง ห้วยหม้าย แพร่ 54120",
    name_th: "สอง ห้วยหม้าย แพร่ 54120",
    name_en: "Song, Huai Mai, Phrae, 54120",
  },
  {
    id: 540604,
    districtTh: "สอง",
    districtEn: "Song",
    subdistrictTh: "เตาปูน",
    subdistrictEn: "Tao Pun",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54120,
    district_code: 5406,
    province_code: 42,
    name: "สอง เตาปูน แพร่ 54120",
    name_th: "สอง เตาปูน แพร่ 54120",
    name_en: "Song, Tao Pun, Phrae, 54120",
  },
  {
    id: 540605,
    districtTh: "สอง",
    districtEn: "Song",
    subdistrictTh: "หัวเมือง",
    subdistrictEn: "Hua Mueang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54120,
    district_code: 5406,
    province_code: 42,
    name: "สอง หัวเมือง แพร่ 54120",
    name_th: "สอง หัวเมือง แพร่ 54120",
    name_en: "Song, Hua Mueang, Phrae, 54120",
  },
  {
    id: 540606,
    districtTh: "สอง",
    districtEn: "Song",
    subdistrictTh: "สะเอียบ",
    subdistrictEn: "Sa-iap",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54120,
    district_code: 5406,
    province_code: 42,
    name: "สอง สะเอียบ แพร่ 54120",
    name_th: "สอง สะเอียบ แพร่ 54120",
    name_en: "Song, Sa-iap, Phrae, 54120",
  },
  {
    id: 540607,
    districtTh: "สอง",
    districtEn: "Song",
    subdistrictTh: "แดนชุมพล",
    subdistrictEn: "Daen Chumphon",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54120,
    district_code: 5406,
    province_code: 42,
    name: "สอง แดนชุมพล แพร่ 54120",
    name_th: "สอง แดนชุมพล แพร่ 54120",
    name_en: "Song, Daen Chumphon, Phrae, 54120",
  },
  {
    id: 540608,
    districtTh: "สอง",
    districtEn: "Song",
    subdistrictTh: "ทุ่งน้าว",
    subdistrictEn: "Thung Nao",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54120,
    district_code: 5406,
    province_code: 42,
    name: "สอง ทุ่งน้าว แพร่ 54120",
    name_th: "สอง ทุ่งน้าว แพร่ 54120",
    name_en: "Song, Thung Nao, Phrae, 54120",
  },
  {
    id: 540701,
    districtTh: "วังชิ้น",
    districtEn: "Wang Chin",
    subdistrictTh: "วังชิ้น",
    subdistrictEn: "Wang Chin",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54160,
    district_code: 5407,
    province_code: 42,
    name: "วังชิ้น วังชิ้น แพร่ 54160",
    name_th: "วังชิ้น วังชิ้น แพร่ 54160",
    name_en: "Wang Chin, Wang Chin, Phrae, 54160",
  },
  {
    id: 540702,
    districtTh: "วังชิ้น",
    districtEn: "Wang Chin",
    subdistrictTh: "สรอย",
    subdistrictEn: "Saroi",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54160,
    district_code: 5407,
    province_code: 42,
    name: "วังชิ้น สรอย แพร่ 54160",
    name_th: "วังชิ้น สรอย แพร่ 54160",
    name_en: "Wang Chin, Saroi, Phrae, 54160",
  },
  {
    id: 540703,
    districtTh: "วังชิ้น",
    districtEn: "Wang Chin",
    subdistrictTh: "แม่ป้าก",
    subdistrictEn: "Mae Pak",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54160,
    district_code: 5407,
    province_code: 42,
    name: "วังชิ้น แม่ป้าก แพร่ 54160",
    name_th: "วังชิ้น แม่ป้าก แพร่ 54160",
    name_en: "Wang Chin, Mae Pak, Phrae, 54160",
  },
  {
    id: 540704,
    districtTh: "วังชิ้น",
    districtEn: "Wang Chin",
    subdistrictTh: "นาพูน",
    subdistrictEn: "Na Phun",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54160,
    district_code: 5407,
    province_code: 42,
    name: "วังชิ้น นาพูน แพร่ 54160",
    name_th: "วังชิ้น นาพูน แพร่ 54160",
    name_en: "Wang Chin, Na Phun, Phrae, 54160",
  },
  {
    id: 540705,
    districtTh: "วังชิ้น",
    districtEn: "Wang Chin",
    subdistrictTh: "แม่พุง",
    subdistrictEn: "Mae Phung",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54160,
    district_code: 5407,
    province_code: 42,
    name: "วังชิ้น แม่พุง แพร่ 54160",
    name_th: "วังชิ้น แม่พุง แพร่ 54160",
    name_en: "Wang Chin, Mae Phung, Phrae, 54160",
  },
  {
    id: 540706,
    districtTh: "วังชิ้น",
    districtEn: "Wang Chin",
    subdistrictTh: "ป่าสัก",
    subdistrictEn: "Pa Sak",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54160,
    district_code: 5407,
    province_code: 42,
    name: "วังชิ้น ป่าสัก แพร่ 54160",
    name_th: "วังชิ้น ป่าสัก แพร่ 54160",
    name_en: "Wang Chin, Pa Sak, Phrae, 54160",
  },
  {
    id: 540707,
    districtTh: "วังชิ้น",
    districtEn: "Wang Chin",
    subdistrictTh: "แม่เกิ๋ง",
    subdistrictEn: "Mae Koeng",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54160,
    district_code: 5407,
    province_code: 42,
    name: "วังชิ้น แม่เกิ๋ง แพร่ 54160",
    name_th: "วังชิ้น แม่เกิ๋ง แพร่ 54160",
    name_en: "Wang Chin, Mae Koeng, Phrae, 54160",
  },
  {
    id: 540801,
    districtTh: "หนองม่วงไข่",
    districtEn: "Nong Muang Khai",
    subdistrictTh: "แม่คำมี",
    subdistrictEn: "Mae Kham Mi",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54170,
    district_code: 5408,
    province_code: 42,
    name: "หนองม่วงไข่ แม่คำมี แพร่ 54170",
    name_th: "หนองม่วงไข่ แม่คำมี แพร่ 54170",
    name_en: "Nong Muang Khai, Mae Kham Mi, Phrae, 54170",
  },
  {
    id: 540802,
    districtTh: "หนองม่วงไข่",
    districtEn: "Nong Muang Khai",
    subdistrictTh: "หนองม่วงไข่",
    subdistrictEn: "Nong Muang Khai",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54170,
    district_code: 5408,
    province_code: 42,
    name: "หนองม่วงไข่ หนองม่วงไข่ แพร่ 54170",
    name_th: "หนองม่วงไข่ หนองม่วงไข่ แพร่ 54170",
    name_en: "Nong Muang Khai, Nong Muang Khai, Phrae, 54170",
  },
  {
    id: 540803,
    districtTh: "หนองม่วงไข่",
    districtEn: "Nong Muang Khai",
    subdistrictTh: "น้ำรัด",
    subdistrictEn: "Nam Rat",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54170,
    district_code: 5408,
    province_code: 42,
    name: "หนองม่วงไข่ น้ำรัด แพร่ 54170",
    name_th: "หนองม่วงไข่ น้ำรัด แพร่ 54170",
    name_en: "Nong Muang Khai, Nam Rat, Phrae, 54170",
  },
  {
    id: 540804,
    districtTh: "หนองม่วงไข่",
    districtEn: "Nong Muang Khai",
    subdistrictTh: "วังหลวง",
    subdistrictEn: "Wang Luang",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54170,
    district_code: 5408,
    province_code: 42,
    name: "หนองม่วงไข่ วังหลวง แพร่ 54170",
    name_th: "หนองม่วงไข่ วังหลวง แพร่ 54170",
    name_en: "Nong Muang Khai, Wang Luang, Phrae, 54170",
  },
  {
    id: 540805,
    districtTh: "หนองม่วงไข่",
    districtEn: "Nong Muang Khai",
    subdistrictTh: "ตำหนักธรรม",
    subdistrictEn: "Tamnak Tham",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54170,
    district_code: 5408,
    province_code: 42,
    name: "หนองม่วงไข่ ตำหนักธรรม แพร่ 54170",
    name_th: "หนองม่วงไข่ ตำหนักธรรม แพร่ 54170",
    name_en: "Nong Muang Khai, Tamnak Tham, Phrae, 54170",
  },
  {
    id: 540806,
    districtTh: "หนองม่วงไข่",
    districtEn: "Nong Muang Khai",
    subdistrictTh: "ทุ่งแค้ว",
    subdistrictEn: "Thung Khaeo",
    provinceTh: "แพร่",
    provinceEn: "Phrae",
    zipcode: 54170,
    district_code: 5408,
    province_code: 42,
    name: "หนองม่วงไข่ ทุ่งแค้ว แพร่ 54170",
    name_th: "หนองม่วงไข่ ทุ่งแค้ว แพร่ 54170",
    name_en: "Nong Muang Khai, Thung Khaeo, Phrae, 54170",
  },
  {
    id: 550101,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "ในเวียง",
    subdistrictEn: "Nai Wiang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน ในเวียง น่าน 55000",
    name_th: "เมืองน่าน ในเวียง น่าน 55000",
    name_en: "Mueang Nan, Nai Wiang, Nan, 55000",
  },
  {
    id: 550102,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "บ่อ",
    subdistrictEn: "Bo",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน บ่อ น่าน 55000",
    name_th: "เมืองน่าน บ่อ น่าน 55000",
    name_en: "Mueang Nan, Bo, Nan, 55000",
  },
  {
    id: 550103,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "ผาสิงห์",
    subdistrictEn: "Pha Sing",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน ผาสิงห์ น่าน 55000",
    name_th: "เมืองน่าน ผาสิงห์ น่าน 55000",
    name_en: "Mueang Nan, Pha Sing, Nan, 55000",
  },
  {
    id: 550104,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "ไชยสถาน",
    subdistrictEn: "Chai Sathan",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน ไชยสถาน น่าน 55000",
    name_th: "เมืองน่าน ไชยสถาน น่าน 55000",
    name_en: "Mueang Nan, Chai Sathan, Nan, 55000",
  },
  {
    id: 550105,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "ถืมตอง",
    subdistrictEn: "Thuem Tong",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน ถืมตอง น่าน 55000",
    name_th: "เมืองน่าน ถืมตอง น่าน 55000",
    name_en: "Mueang Nan, Thuem Tong, Nan, 55000",
  },
  {
    id: 550106,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "เรือง",
    subdistrictEn: "Rueang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน เรือง น่าน 55000",
    name_th: "เมืองน่าน เรือง น่าน 55000",
    name_en: "Mueang Nan, Rueang, Nan, 55000",
  },
  {
    id: 550107,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "นาซาว",
    subdistrictEn: "Na Sao",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน นาซาว น่าน 55000",
    name_th: "เมืองน่าน นาซาว น่าน 55000",
    name_en: "Mueang Nan, Na Sao, Nan, 55000",
  },
  {
    id: 550108,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "ดู่ใต้",
    subdistrictEn: "Du Tai",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน ดู่ใต้ น่าน 55000",
    name_th: "เมืองน่าน ดู่ใต้ น่าน 55000",
    name_en: "Mueang Nan, Du Tai, Nan, 55000",
  },
  {
    id: 550109,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "กองควาย",
    subdistrictEn: "Kong Khwai",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน กองควาย น่าน 55000",
    name_th: "เมืองน่าน กองควาย น่าน 55000",
    name_en: "Mueang Nan, Kong Khwai, Nan, 55000",
  },
  {
    id: 550116,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "สวก",
    subdistrictEn: "Suak",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน สวก น่าน 55000",
    name_th: "เมืองน่าน สวก น่าน 55000",
    name_en: "Mueang Nan, Suak, Nan, 55000",
  },
  {
    id: 550117,
    districtTh: "เมืองน่าน",
    districtEn: "Mueang Nan",
    subdistrictTh: "สะเนียน",
    subdistrictEn: "Sanian",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5501,
    province_code: 43,
    name: "เมืองน่าน สะเนียน น่าน 55000",
    name_th: "เมืองน่าน สะเนียน น่าน 55000",
    name_en: "Mueang Nan, Sanian, Nan, 55000",
  },
  {
    id: 550202,
    districtTh: "แม่จริม",
    districtEn: "Mae Charim",
    subdistrictTh: "หนองแดง",
    subdistrictEn: "Nong Daeng",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55170,
    district_code: 5502,
    province_code: 43,
    name: "แม่จริม หนองแดง น่าน 55170",
    name_th: "แม่จริม หนองแดง น่าน 55170",
    name_en: "Mae Charim, Nong Daeng, Nan, 55170",
  },
  {
    id: 550203,
    districtTh: "แม่จริม",
    districtEn: "Mae Charim",
    subdistrictTh: "หมอเมือง",
    subdistrictEn: "Mo Mueang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55170,
    district_code: 5502,
    province_code: 43,
    name: "แม่จริม หมอเมือง น่าน 55170",
    name_th: "แม่จริม หมอเมือง น่าน 55170",
    name_en: "Mae Charim, Mo Mueang, Nan, 55170",
  },
  {
    id: 550204,
    districtTh: "แม่จริม",
    districtEn: "Mae Charim",
    subdistrictTh: "น้ำพาง",
    subdistrictEn: "Nam Phang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55170,
    district_code: 5502,
    province_code: 43,
    name: "แม่จริม น้ำพาง น่าน 55170",
    name_th: "แม่จริม น้ำพาง น่าน 55170",
    name_en: "Mae Charim, Nam Phang, Nan, 55170",
  },
  {
    id: 550205,
    districtTh: "แม่จริม",
    districtEn: "Mae Charim",
    subdistrictTh: "น้ำปาย",
    subdistrictEn: "Nam Pai",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55170,
    district_code: 5502,
    province_code: 43,
    name: "แม่จริม น้ำปาย น่าน 55170",
    name_th: "แม่จริม น้ำปาย น่าน 55170",
    name_en: "Mae Charim, Nam Pai, Nan, 55170",
  },
  {
    id: 550206,
    districtTh: "แม่จริม",
    districtEn: "Mae Charim",
    subdistrictTh: "แม่จริม",
    subdistrictEn: "Mae Charim",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55170,
    district_code: 5502,
    province_code: 43,
    name: "แม่จริม แม่จริม น่าน 55170",
    name_th: "แม่จริม แม่จริม น่าน 55170",
    name_en: "Mae Charim, Mae Charim, Nan, 55170",
  },
  {
    id: 550301,
    districtTh: "บ้านหลวง",
    districtEn: "Ban Luang",
    subdistrictTh: "บ้านฟ้า",
    subdistrictEn: "Ban Fa",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55190,
    district_code: 5503,
    province_code: 43,
    name: "บ้านหลวง บ้านฟ้า น่าน 55190",
    name_th: "บ้านหลวง บ้านฟ้า น่าน 55190",
    name_en: "Ban Luang, Ban Fa, Nan, 55190",
  },
  {
    id: 550302,
    districtTh: "บ้านหลวง",
    districtEn: "Ban Luang",
    subdistrictTh: "ป่าคาหลวง",
    subdistrictEn: "Pa Kha Luang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55190,
    district_code: 5503,
    province_code: 43,
    name: "บ้านหลวง ป่าคาหลวง น่าน 55190",
    name_th: "บ้านหลวง ป่าคาหลวง น่าน 55190",
    name_en: "Ban Luang, Pa Kha Luang, Nan, 55190",
  },
  {
    id: 550303,
    districtTh: "บ้านหลวง",
    districtEn: "Ban Luang",
    subdistrictTh: "สวด",
    subdistrictEn: "Suat",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55190,
    district_code: 5503,
    province_code: 43,
    name: "บ้านหลวง สวด น่าน 55190",
    name_th: "บ้านหลวง สวด น่าน 55190",
    name_en: "Ban Luang, Suat, Nan, 55190",
  },
  {
    id: 550304,
    districtTh: "บ้านหลวง",
    districtEn: "Ban Luang",
    subdistrictTh: "บ้านพี้",
    subdistrictEn: "Ban Phi",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55190,
    district_code: 5503,
    province_code: 43,
    name: "บ้านหลวง บ้านพี้ น่าน 55190",
    name_th: "บ้านหลวง บ้านพี้ น่าน 55190",
    name_en: "Ban Luang, Ban Phi, Nan, 55190",
  },
  {
    id: 550401,
    districtTh: "นาน้อย",
    districtEn: "Na Noi",
    subdistrictTh: "นาน้อย",
    subdistrictEn: "Na Noi",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55150,
    district_code: 5504,
    province_code: 43,
    name: "นาน้อย นาน้อย น่าน 55150",
    name_th: "นาน้อย นาน้อย น่าน 55150",
    name_en: "Na Noi, Na Noi, Nan, 55150",
  },
  {
    id: 550402,
    districtTh: "นาน้อย",
    districtEn: "Na Noi",
    subdistrictTh: "เชียงของ",
    subdistrictEn: "Chiang Khong",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55150,
    district_code: 5504,
    province_code: 43,
    name: "นาน้อย เชียงของ น่าน 55150",
    name_th: "นาน้อย เชียงของ น่าน 55150",
    name_en: "Na Noi, Chiang Khong, Nan, 55150",
  },
  {
    id: 550403,
    districtTh: "นาน้อย",
    districtEn: "Na Noi",
    subdistrictTh: "ศรีษะเกษ",
    subdistrictEn: "Sisaket",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55150,
    district_code: 5504,
    province_code: 43,
    name: "นาน้อย ศรีษะเกษ น่าน 55150",
    name_th: "นาน้อย ศรีษะเกษ น่าน 55150",
    name_en: "Na Noi, Sisaket, Nan, 55150",
  },
  {
    id: 550404,
    districtTh: "นาน้อย",
    districtEn: "Na Noi",
    subdistrictTh: "สถาน",
    subdistrictEn: "Sathan",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55150,
    district_code: 5504,
    province_code: 43,
    name: "นาน้อย สถาน น่าน 55150",
    name_th: "นาน้อย สถาน น่าน 55150",
    name_en: "Na Noi, Sathan, Nan, 55150",
  },
  {
    id: 550405,
    districtTh: "นาน้อย",
    districtEn: "Na Noi",
    subdistrictTh: "สันทะ",
    subdistrictEn: "Santha",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55150,
    district_code: 5504,
    province_code: 43,
    name: "นาน้อย สันทะ น่าน 55150",
    name_th: "นาน้อย สันทะ น่าน 55150",
    name_en: "Na Noi, Santha, Nan, 55150",
  },
  {
    id: 550406,
    districtTh: "นาน้อย",
    districtEn: "Na Noi",
    subdistrictTh: "บัวใหญ่",
    subdistrictEn: "Bua Yai",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55150,
    district_code: 5504,
    province_code: 43,
    name: "นาน้อย บัวใหญ่ น่าน 55150",
    name_th: "นาน้อย บัวใหญ่ น่าน 55150",
    name_en: "Na Noi, Bua Yai, Nan, 55150",
  },
  {
    id: 550407,
    districtTh: "นาน้อย",
    districtEn: "Na Noi",
    subdistrictTh: "น้ำตก",
    subdistrictEn: "Nam Tok",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55150,
    district_code: 5504,
    province_code: 43,
    name: "นาน้อย น้ำตก น่าน 55150",
    name_th: "นาน้อย น้ำตก น่าน 55150",
    name_en: "Na Noi, Nam Tok, Nan, 55150",
  },
  {
    id: 550501,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "ปัว",
    subdistrictEn: "Pua",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว ปัว น่าน 55120",
    name_th: "ปัว ปัว น่าน 55120",
    name_en: "Pua, Pua, Nan, 55120",
  },
  {
    id: 550502,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "แงง",
    subdistrictEn: "Ngaeng",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว แงง น่าน 55120",
    name_th: "ปัว แงง น่าน 55120",
    name_en: "Pua, Ngaeng, Nan, 55120",
  },
  {
    id: 550503,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "สถาน",
    subdistrictEn: "Sathan",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว สถาน น่าน 55120",
    name_th: "ปัว สถาน น่าน 55120",
    name_en: "Pua, Sathan, Nan, 55120",
  },
  {
    id: 550504,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "ศิลาแลง",
    subdistrictEn: "Sila Laeng",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว ศิลาแลง น่าน 55120",
    name_th: "ปัว ศิลาแลง น่าน 55120",
    name_en: "Pua, Sila Laeng, Nan, 55120",
  },
  {
    id: 550505,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "ศิลาเพชร",
    subdistrictEn: "Sila Phet",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว ศิลาเพชร น่าน 55120",
    name_th: "ปัว ศิลาเพชร น่าน 55120",
    name_en: "Pua, Sila Phet, Nan, 55120",
  },
  {
    id: 550506,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "อวน",
    subdistrictEn: "Uan",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว อวน น่าน 55120",
    name_th: "ปัว อวน น่าน 55120",
    name_en: "Pua, Uan, Nan, 55120",
  },
  {
    id: 550509,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "ไชยวัฒนา",
    subdistrictEn: "Chai Watthana",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว ไชยวัฒนา น่าน 55120",
    name_th: "ปัว ไชยวัฒนา น่าน 55120",
    name_en: "Pua, Chai Watthana, Nan, 55120",
  },
  {
    id: 550510,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "เจดีย์ชัย",
    subdistrictEn: "Chedi Chai",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว เจดีย์ชัย น่าน 55120",
    name_th: "ปัว เจดีย์ชัย น่าน 55120",
    name_en: "Pua, Chedi Chai, Nan, 55120",
  },
  {
    id: 550511,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "ภูคา",
    subdistrictEn: "Phu Kha",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว ภูคา น่าน 55120",
    name_th: "ปัว ภูคา น่าน 55120",
    name_en: "Pua, Phu Kha, Nan, 55120",
  },
  {
    id: 550512,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "สกาด",
    subdistrictEn: "Sakat",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว สกาด น่าน 55120",
    name_th: "ปัว สกาด น่าน 55120",
    name_en: "Pua, Sakat, Nan, 55120",
  },
  {
    id: 550513,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "ป่ากลาง",
    subdistrictEn: "Pa Klang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว ป่ากลาง น่าน 55120",
    name_th: "ปัว ป่ากลาง น่าน 55120",
    name_en: "Pua, Pa Klang, Nan, 55120",
  },
  {
    id: 550514,
    districtTh: "ปัว",
    districtEn: "Pua",
    subdistrictTh: "วรนคร",
    subdistrictEn: "Woranakhon",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55120,
    district_code: 5505,
    province_code: 43,
    name: "ปัว วรนคร น่าน 55120",
    name_th: "ปัว วรนคร น่าน 55120",
    name_en: "Pua, Woranakhon, Nan, 55120",
  },
  {
    id: 550601,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "ริม",
    subdistrictEn: "Rim",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา ริม น่าน 55140",
    name_th: "ท่าวังผา ริม น่าน 55140",
    name_en: "Tha Wang Pha, Rim, Nan, 55140",
  },
  {
    id: 550602,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "ป่าคา",
    subdistrictEn: "Pa Kha",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา ป่าคา น่าน 55140",
    name_th: "ท่าวังผา ป่าคา น่าน 55140",
    name_en: "Tha Wang Pha, Pa Kha, Nan, 55140",
  },
  {
    id: 550603,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "ผาตอ",
    subdistrictEn: "Pha To",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา ผาตอ น่าน 55140",
    name_th: "ท่าวังผา ผาตอ น่าน 55140",
    name_en: "Tha Wang Pha, Pha To, Nan, 55140",
  },
  {
    id: 550604,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "ยม",
    subdistrictEn: "Yom",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา ยม น่าน 55140",
    name_th: "ท่าวังผา ยม น่าน 55140",
    name_en: "Tha Wang Pha, Yom, Nan, 55140",
  },
  {
    id: 550605,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "ตาลชุม",
    subdistrictEn: "Tan Chum",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา ตาลชุม น่าน 55140",
    name_th: "ท่าวังผา ตาลชุม น่าน 55140",
    name_en: "Tha Wang Pha, Tan Chum, Nan, 55140",
  },
  {
    id: 550606,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "ศรีภูมิ",
    subdistrictEn: "Si Phum",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา ศรีภูมิ น่าน 55140",
    name_th: "ท่าวังผา ศรีภูมิ น่าน 55140",
    name_en: "Tha Wang Pha, Si Phum, Nan, 55140",
  },
  {
    id: 550607,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "จอมพระ",
    subdistrictEn: "Chom Phra",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา จอมพระ น่าน 55140",
    name_th: "ท่าวังผา จอมพระ น่าน 55140",
    name_en: "Tha Wang Pha, Chom Phra, Nan, 55140",
  },
  {
    id: 550608,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "แสนทอง",
    subdistrictEn: "Saen Thong",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา แสนทอง น่าน 55140",
    name_th: "ท่าวังผา แสนทอง น่าน 55140",
    name_en: "Tha Wang Pha, Saen Thong, Nan, 55140",
  },
  {
    id: 550609,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "ท่าวังผา",
    subdistrictEn: "Tha Wang Pha",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา ท่าวังผา น่าน 55140",
    name_th: "ท่าวังผา ท่าวังผา น่าน 55140",
    name_en: "Tha Wang Pha, Tha Wang Pha, Nan, 55140",
  },
  {
    id: 550610,
    districtTh: "ท่าวังผา",
    districtEn: "Tha Wang Pha",
    subdistrictTh: "ผาทอง",
    subdistrictEn: "Pha Thong",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55140,
    district_code: 5506,
    province_code: 43,
    name: "ท่าวังผา ผาทอง น่าน 55140",
    name_th: "ท่าวังผา ผาทอง น่าน 55140",
    name_en: "Tha Wang Pha, Pha Thong, Nan, 55140",
  },
  {
    id: 550701,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "กลางเวียง",
    subdistrictEn: "Klang Wiang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา กลางเวียง น่าน 55110",
    name_th: "เวียงสา กลางเวียง น่าน 55110",
    name_en: "Wiang Sa, Klang Wiang, Nan, 55110",
  },
  {
    id: 550702,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "ขึ่ง",
    subdistrictEn: "Khueng",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา ขึ่ง น่าน 55110",
    name_th: "เวียงสา ขึ่ง น่าน 55110",
    name_en: "Wiang Sa, Khueng, Nan, 55110",
  },
  {
    id: 550703,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "ไหล่น่าน",
    subdistrictEn: "Lai Nan",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา ไหล่น่าน น่าน 55110",
    name_th: "เวียงสา ไหล่น่าน น่าน 55110",
    name_en: "Wiang Sa, Lai Nan, Nan, 55110",
  },
  {
    id: 550704,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "ตาลชุม",
    subdistrictEn: "Tan Chum",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา ตาลชุม น่าน 55110",
    name_th: "เวียงสา ตาลชุม น่าน 55110",
    name_en: "Wiang Sa, Tan Chum, Nan, 55110",
  },
  {
    id: 550705,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "นาเหลือง",
    subdistrictEn: "Na Lueang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา นาเหลือง น่าน 55110",
    name_th: "เวียงสา นาเหลือง น่าน 55110",
    name_en: "Wiang Sa, Na Lueang, Nan, 55110",
  },
  {
    id: 550706,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "ส้าน",
    subdistrictEn: "San",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา ส้าน น่าน 55110",
    name_th: "เวียงสา ส้าน น่าน 55110",
    name_en: "Wiang Sa, San, Nan, 55110",
  },
  {
    id: 550707,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "น้ำมวบ",
    subdistrictEn: "Nam Muap",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา น้ำมวบ น่าน 55110",
    name_th: "เวียงสา น้ำมวบ น่าน 55110",
    name_en: "Wiang Sa, Nam Muap, Nan, 55110",
  },
  {
    id: 550708,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "น้ำปั้ว",
    subdistrictEn: "Nam Pua",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา น้ำปั้ว น่าน 55110",
    name_th: "เวียงสา น้ำปั้ว น่าน 55110",
    name_en: "Wiang Sa, Nam Pua, Nan, 55110",
  },
  {
    id: 550709,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "ยาบหัวนา",
    subdistrictEn: "Yap Hua Na",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา ยาบหัวนา น่าน 55110",
    name_th: "เวียงสา ยาบหัวนา น่าน 55110",
    name_en: "Wiang Sa, Yap Hua Na, Nan, 55110",
  },
  {
    id: 550710,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "ปงสนุก",
    subdistrictEn: "Pong Sanuk",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา ปงสนุก น่าน 55110",
    name_th: "เวียงสา ปงสนุก น่าน 55110",
    name_en: "Wiang Sa, Pong Sanuk, Nan, 55110",
  },
  {
    id: 550711,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "อ่ายนาไลย",
    subdistrictEn: "Ai Na Lai",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา อ่ายนาไลย น่าน 55110",
    name_th: "เวียงสา อ่ายนาไลย น่าน 55110",
    name_en: "Wiang Sa, Ai Na Lai, Nan, 55110",
  },
  {
    id: 550712,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "ส้านนาหนองใหม่",
    subdistrictEn: "San Na Nong Mai",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา ส้านนาหนองใหม่ น่าน 55110",
    name_th: "เวียงสา ส้านนาหนองใหม่ น่าน 55110",
    name_en: "Wiang Sa, San Na Nong Mai, Nan, 55110",
  },
  {
    id: 550713,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "แม่ขะนิง",
    subdistrictEn: "Mae Khaning",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา แม่ขะนิง น่าน 55110",
    name_th: "เวียงสา แม่ขะนิง น่าน 55110",
    name_en: "Wiang Sa, Mae Khaning, Nan, 55110",
  },
  {
    id: 550714,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "แม่สาคร",
    subdistrictEn: "Mae Sakhon",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา แม่สาคร น่าน 55110",
    name_th: "เวียงสา แม่สาคร น่าน 55110",
    name_en: "Wiang Sa, Mae Sakhon, Nan, 55110",
  },
  {
    id: 550715,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "จอมจันทร์",
    subdistrictEn: "Chom Chan",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา จอมจันทร์ น่าน 55110",
    name_th: "เวียงสา จอมจันทร์ น่าน 55110",
    name_en: "Wiang Sa, Chom Chan, Nan, 55110",
  },
  {
    id: 550716,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "แม่สา",
    subdistrictEn: "Mae Sa",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา แม่สา น่าน 55110",
    name_th: "เวียงสา แม่สา น่าน 55110",
    name_en: "Wiang Sa, Mae Sa, Nan, 55110",
  },
  {
    id: 550717,
    districtTh: "เวียงสา",
    districtEn: "Wiang Sa",
    subdistrictTh: "ทุ่งศรีทอง",
    subdistrictEn: "Thung Si Thong",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55110,
    district_code: 5507,
    province_code: 43,
    name: "เวียงสา ทุ่งศรีทอง น่าน 55110",
    name_th: "เวียงสา ทุ่งศรีทอง น่าน 55110",
    name_en: "Wiang Sa, Thung Si Thong, Nan, 55110",
  },
  {
    id: 550801,
    districtTh: "ทุ่งช้าง",
    districtEn: "Thung Chang",
    subdistrictTh: "ปอน",
    subdistrictEn: "Pon",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55130,
    district_code: 5508,
    province_code: 43,
    name: "ทุ่งช้าง ปอน น่าน 55130",
    name_th: "ทุ่งช้าง ปอน น่าน 55130",
    name_en: "Thung Chang, Pon, Nan, 55130",
  },
  {
    id: 550802,
    districtTh: "ทุ่งช้าง",
    districtEn: "Thung Chang",
    subdistrictTh: "งอบ",
    subdistrictEn: "Ngop",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55130,
    district_code: 5508,
    province_code: 43,
    name: "ทุ่งช้าง งอบ น่าน 55130",
    name_th: "ทุ่งช้าง งอบ น่าน 55130",
    name_en: "Thung Chang, Ngop, Nan, 55130",
  },
  {
    id: 550803,
    districtTh: "ทุ่งช้าง",
    districtEn: "Thung Chang",
    subdistrictTh: "และ",
    subdistrictEn: "Lae",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55130,
    district_code: 5508,
    province_code: 43,
    name: "ทุ่งช้าง และ น่าน 55130",
    name_th: "ทุ่งช้าง และ น่าน 55130",
    name_en: "Thung Chang, Lae, Nan, 55130",
  },
  {
    id: 550804,
    districtTh: "ทุ่งช้าง",
    districtEn: "Thung Chang",
    subdistrictTh: "ทุ่งช้าง",
    subdistrictEn: "Thung Chang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55130,
    district_code: 5508,
    province_code: 43,
    name: "ทุ่งช้าง ทุ่งช้าง น่าน 55130",
    name_th: "ทุ่งช้าง ทุ่งช้าง น่าน 55130",
    name_en: "Thung Chang, Thung Chang, Nan, 55130",
  },
  {
    id: 550901,
    districtTh: "เชียงกลาง",
    districtEn: "Chiang Klang",
    subdistrictTh: "เชียงกลาง",
    subdistrictEn: "Chiang Klang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5509,
    province_code: 43,
    name: "เชียงกลาง เชียงกลาง น่าน 55160",
    name_th: "เชียงกลาง เชียงกลาง น่าน 55160",
    name_en: "Chiang Klang, Chiang Klang, Nan, 55160",
  },
  {
    id: 550902,
    districtTh: "เชียงกลาง",
    districtEn: "Chiang Klang",
    subdistrictTh: "เปือ",
    subdistrictEn: "Puea",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5509,
    province_code: 43,
    name: "เชียงกลาง เปือ น่าน 55160",
    name_th: "เชียงกลาง เปือ น่าน 55160",
    name_en: "Chiang Klang, Puea, Nan, 55160",
  },
  {
    id: 550903,
    districtTh: "เชียงกลาง",
    districtEn: "Chiang Klang",
    subdistrictTh: "เชียงคาน",
    subdistrictEn: "Chiang Khan",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5509,
    province_code: 43,
    name: "เชียงกลาง เชียงคาน น่าน 55160",
    name_th: "เชียงกลาง เชียงคาน น่าน 55160",
    name_en: "Chiang Klang, Chiang Khan, Nan, 55160",
  },
  {
    id: 550904,
    districtTh: "เชียงกลาง",
    districtEn: "Chiang Klang",
    subdistrictTh: "พระธาตุ",
    subdistrictEn: "Phra That",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5509,
    province_code: 43,
    name: "เชียงกลาง พระธาตุ น่าน 55160",
    name_th: "เชียงกลาง พระธาตุ น่าน 55160",
    name_en: "Chiang Klang, Phra That, Nan, 55160",
  },
  {
    id: 550908,
    districtTh: "เชียงกลาง",
    districtEn: "Chiang Klang",
    subdistrictTh: "พญาแก้ว",
    subdistrictEn: "Phaya Kaeo",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5509,
    province_code: 43,
    name: "เชียงกลาง พญาแก้ว น่าน 55160",
    name_th: "เชียงกลาง พญาแก้ว น่าน 55160",
    name_en: "Chiang Klang, Phaya Kaeo, Nan, 55160",
  },
  {
    id: 550909,
    districtTh: "เชียงกลาง",
    districtEn: "Chiang Klang",
    subdistrictTh: "พระพุทธบาท",
    subdistrictEn: "Phra Phutthabat",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5509,
    province_code: 43,
    name: "เชียงกลาง พระพุทธบาท น่าน 55160",
    name_th: "เชียงกลาง พระพุทธบาท น่าน 55160",
    name_en: "Chiang Klang, Phra Phutthabat, Nan, 55160",
  },
  {
    id: 551001,
    districtTh: "นาหมื่น",
    districtEn: "Na Muen",
    subdistrictTh: "นาทะนุง",
    subdistrictEn: "Na Thanung",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55180,
    district_code: 5510,
    province_code: 43,
    name: "นาหมื่น นาทะนุง น่าน 55180",
    name_th: "นาหมื่น นาทะนุง น่าน 55180",
    name_en: "Na Muen, Na Thanung, Nan, 55180",
  },
  {
    id: 551002,
    districtTh: "นาหมื่น",
    districtEn: "Na Muen",
    subdistrictTh: "บ่อแก้ว",
    subdistrictEn: "Bo Kaeo",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55180,
    district_code: 5510,
    province_code: 43,
    name: "นาหมื่น บ่อแก้ว น่าน 55180",
    name_th: "นาหมื่น บ่อแก้ว น่าน 55180",
    name_en: "Na Muen, Bo Kaeo, Nan, 55180",
  },
  {
    id: 551003,
    districtTh: "นาหมื่น",
    districtEn: "Na Muen",
    subdistrictTh: "เมืองลี",
    subdistrictEn: "Mueang Li",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55180,
    district_code: 5510,
    province_code: 43,
    name: "นาหมื่น เมืองลี น่าน 55180",
    name_th: "นาหมื่น เมืองลี น่าน 55180",
    name_en: "Na Muen, Mueang Li, Nan, 55180",
  },
  {
    id: 551004,
    districtTh: "นาหมื่น",
    districtEn: "Na Muen",
    subdistrictTh: "ปิงหลวง",
    subdistrictEn: "Ping Luang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55180,
    district_code: 5510,
    province_code: 43,
    name: "นาหมื่น ปิงหลวง น่าน 55180",
    name_th: "นาหมื่น ปิงหลวง น่าน 55180",
    name_en: "Na Muen, Ping Luang, Nan, 55180",
  },
  {
    id: 551101,
    districtTh: "สันติสุข",
    districtEn: "Santi Suk",
    subdistrictTh: "ดู่พงษ์",
    subdistrictEn: "Du Phong",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55210,
    district_code: 5511,
    province_code: 43,
    name: "สันติสุข ดู่พงษ์ น่าน 55210",
    name_th: "สันติสุข ดู่พงษ์ น่าน 55210",
    name_en: "Santi Suk, Du Phong, Nan, 55210",
  },
  {
    id: 551102,
    districtTh: "สันติสุข",
    districtEn: "Santi Suk",
    subdistrictTh: "ป่าแลวหลวง",
    subdistrictEn: "Pa Laeo Luang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55210,
    district_code: 5511,
    province_code: 43,
    name: "สันติสุข ป่าแลวหลวง น่าน 55210",
    name_th: "สันติสุข ป่าแลวหลวง น่าน 55210",
    name_en: "Santi Suk, Pa Laeo Luang, Nan, 55210",
  },
  {
    id: 551103,
    districtTh: "สันติสุข",
    districtEn: "Santi Suk",
    subdistrictTh: "พงษ์",
    subdistrictEn: "Phong",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55210,
    district_code: 5511,
    province_code: 43,
    name: "สันติสุข พงษ์ น่าน 55210",
    name_th: "สันติสุข พงษ์ น่าน 55210",
    name_en: "Santi Suk, Phong, Nan, 55210",
  },
  {
    id: 551201,
    districtTh: "บ่อเกลือ",
    districtEn: "Bo Kluea",
    subdistrictTh: "บ่อเกลือเหนือ",
    subdistrictEn: "Bo Kluea Nuea",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55220,
    district_code: 5512,
    province_code: 43,
    name: "บ่อเกลือ บ่อเกลือเหนือ น่าน 55220",
    name_th: "บ่อเกลือ บ่อเกลือเหนือ น่าน 55220",
    name_en: "Bo Kluea, Bo Kluea Nuea, Nan, 55220",
  },
  {
    id: 551202,
    districtTh: "บ่อเกลือ",
    districtEn: "Bo Kluea",
    subdistrictTh: "บ่อเกลือใต้",
    subdistrictEn: "Bo Kluea Tai",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55220,
    district_code: 5512,
    province_code: 43,
    name: "บ่อเกลือ บ่อเกลือใต้ น่าน 55220",
    name_th: "บ่อเกลือ บ่อเกลือใต้ น่าน 55220",
    name_en: "Bo Kluea, Bo Kluea Tai, Nan, 55220",
  },
  {
    id: 551204,
    districtTh: "บ่อเกลือ",
    districtEn: "Bo Kluea",
    subdistrictTh: "ภูฟ้า",
    subdistrictEn: "Phu Fa",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55220,
    district_code: 5512,
    province_code: 43,
    name: "บ่อเกลือ ภูฟ้า น่าน 55220",
    name_th: "บ่อเกลือ ภูฟ้า น่าน 55220",
    name_en: "Bo Kluea, Phu Fa, Nan, 55220",
  },
  {
    id: 551205,
    districtTh: "บ่อเกลือ",
    districtEn: "Bo Kluea",
    subdistrictTh: "ดงพญา",
    subdistrictEn: "Dong Phaya",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55220,
    district_code: 5512,
    province_code: 43,
    name: "บ่อเกลือ ดงพญา น่าน 55220",
    name_th: "บ่อเกลือ ดงพญา น่าน 55220",
    name_en: "Bo Kluea, Dong Phaya, Nan, 55220",
  },
  {
    id: 551301,
    districtTh: "สองแคว",
    districtEn: "Song Khwae",
    subdistrictTh: "นาไร่หลวง",
    subdistrictEn: "Na Rai Luang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5513,
    province_code: 43,
    name: "สองแคว นาไร่หลวง น่าน 55160",
    name_th: "สองแคว นาไร่หลวง น่าน 55160",
    name_en: "Song Khwae, Na Rai Luang, Nan, 55160",
  },
  {
    id: 551302,
    districtTh: "สองแคว",
    districtEn: "Song Khwae",
    subdistrictTh: "ชนแดน",
    subdistrictEn: "Chon Daen",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5513,
    province_code: 43,
    name: "สองแคว ชนแดน น่าน 55160",
    name_th: "สองแคว ชนแดน น่าน 55160",
    name_en: "Song Khwae, Chon Daen, Nan, 55160",
  },
  {
    id: 551303,
    districtTh: "สองแคว",
    districtEn: "Song Khwae",
    subdistrictTh: "ยอด",
    subdistrictEn: "Yot",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55160,
    district_code: 5513,
    province_code: 43,
    name: "สองแคว ยอด น่าน 55160",
    name_th: "สองแคว ยอด น่าน 55160",
    name_en: "Song Khwae, Yot, Nan, 55160",
  },
  {
    id: 551401,
    districtTh: "ภูเพียง",
    districtEn: "Phu Phiang",
    subdistrictTh: "ม่วงตึ๊ด",
    subdistrictEn: "Muang Tuet",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5514,
    province_code: 43,
    name: "ภูเพียง ม่วงตึ๊ด น่าน 55000",
    name_th: "ภูเพียง ม่วงตึ๊ด น่าน 55000",
    name_en: "Phu Phiang, Muang Tuet, Nan, 55000",
  },
  {
    id: 551402,
    districtTh: "ภูเพียง",
    districtEn: "Phu Phiang",
    subdistrictTh: "นาปัง",
    subdistrictEn: "Na Pang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5514,
    province_code: 43,
    name: "ภูเพียง นาปัง น่าน 55000",
    name_th: "ภูเพียง นาปัง น่าน 55000",
    name_en: "Phu Phiang, Na Pang, Nan, 55000",
  },
  {
    id: 551403,
    districtTh: "ภูเพียง",
    districtEn: "Phu Phiang",
    subdistrictTh: "น้ำแก่น",
    subdistrictEn: "Nam Kaen",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5514,
    province_code: 43,
    name: "ภูเพียง น้ำแก่น น่าน 55000",
    name_th: "ภูเพียง น้ำแก่น น่าน 55000",
    name_en: "Phu Phiang, Nam Kaen, Nan, 55000",
  },
  {
    id: 551404,
    districtTh: "ภูเพียง",
    districtEn: "Phu Phiang",
    subdistrictTh: "น้ำเกี๋ยน",
    subdistrictEn: "Nam Kian",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5514,
    province_code: 43,
    name: "ภูเพียง น้ำเกี๋ยน น่าน 55000",
    name_th: "ภูเพียง น้ำเกี๋ยน น่าน 55000",
    name_en: "Phu Phiang, Nam Kian, Nan, 55000",
  },
  {
    id: 551405,
    districtTh: "ภูเพียง",
    districtEn: "Phu Phiang",
    subdistrictTh: "เมืองจัง",
    subdistrictEn: "Mueang Chang",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5514,
    province_code: 43,
    name: "ภูเพียง เมืองจัง น่าน 55000",
    name_th: "ภูเพียง เมืองจัง น่าน 55000",
    name_en: "Phu Phiang, Mueang Chang, Nan, 55000",
  },
  {
    id: 551406,
    districtTh: "ภูเพียง",
    districtEn: "Phu Phiang",
    subdistrictTh: "ท่าน้าว",
    subdistrictEn: "Tha Nao",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5514,
    province_code: 43,
    name: "ภูเพียง ท่าน้าว น่าน 55000",
    name_th: "ภูเพียง ท่าน้าว น่าน 55000",
    name_en: "Phu Phiang, Tha Nao, Nan, 55000",
  },
  {
    id: 551407,
    districtTh: "ภูเพียง",
    districtEn: "Phu Phiang",
    subdistrictTh: "ฝายแก้ว",
    subdistrictEn: "Fai Kaeo",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55000,
    district_code: 5514,
    province_code: 43,
    name: "ภูเพียง ฝายแก้ว น่าน 55000",
    name_th: "ภูเพียง ฝายแก้ว น่าน 55000",
    name_en: "Phu Phiang, Fai Kaeo, Nan, 55000",
  },
  {
    id: 551501,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ห้วยโก๋น",
    subdistrictEn: "Huai Kon",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55130,
    district_code: 5515,
    province_code: 43,
    name: "เฉลิมพระเกียรติ ห้วยโก๋น น่าน 55130",
    name_th: "เฉลิมพระเกียรติ ห้วยโก๋น น่าน 55130",
    name_en: "Chaloem Phra Kiat, Huai Kon, Nan, 55130",
  },
  {
    id: 551502,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ขุนน่าน",
    subdistrictEn: "Khun Nan",
    provinceTh: "น่าน",
    provinceEn: "Nan",
    zipcode: 55130,
    district_code: 5515,
    province_code: 43,
    name: "เฉลิมพระเกียรติ ขุนน่าน น่าน 55130",
    name_th: "เฉลิมพระเกียรติ ขุนน่าน น่าน 55130",
    name_en: "Chaloem Phra Kiat, Khun Nan, Nan, 55130",
  },
  {
    id: 560101,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา เวียง พะเยา 56000",
    name_th: "เมืองพะเยา เวียง พะเยา 56000",
    name_en: "Mueang Phayao, Wiang, Phayao, 56000",
  },
  {
    id: 560102,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "แม่ต๋ำ",
    subdistrictEn: "Mae Tam",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา แม่ต๋ำ พะเยา 56000",
    name_th: "เมืองพะเยา แม่ต๋ำ พะเยา 56000",
    name_en: "Mueang Phayao, Mae Tam, Phayao, 56000",
  },
  {
    id: 560104,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "แม่นาเรือ",
    subdistrictEn: "Mae Na Ruea",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา แม่นาเรือ พะเยา 56000",
    name_th: "เมืองพะเยา แม่นาเรือ พะเยา 56000",
    name_en: "Mueang Phayao, Mae Na Ruea, Phayao, 56000",
  },
  {
    id: 560105,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "บ้านตุ่น",
    subdistrictEn: "Ban Tun",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา บ้านตุ่น พะเยา 56000",
    name_th: "เมืองพะเยา บ้านตุ่น พะเยา 56000",
    name_en: "Mueang Phayao, Ban Tun, Phayao, 56000",
  },
  {
    id: 560106,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "บ้านต๊ำ",
    subdistrictEn: "Ban Tam",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา บ้านต๊ำ พะเยา 56000",
    name_th: "เมืองพะเยา บ้านต๊ำ พะเยา 56000",
    name_en: "Mueang Phayao, Ban Tam, Phayao, 56000",
  },
  {
    id: 560107,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "บ้านต๋อม",
    subdistrictEn: "Ban Tom",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา บ้านต๋อม พะเยา 56000",
    name_th: "เมืองพะเยา บ้านต๋อม พะเยา 56000",
    name_en: "Mueang Phayao, Ban Tom, Phayao, 56000",
  },
  {
    id: 560108,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "แม่ปืม",
    subdistrictEn: "Mae Puem",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา แม่ปืม พะเยา 56000",
    name_th: "เมืองพะเยา แม่ปืม พะเยา 56000",
    name_en: "Mueang Phayao, Mae Puem, Phayao, 56000",
  },
  {
    id: 560110,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "แม่กา",
    subdistrictEn: "Mae Ka",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา แม่กา พะเยา 56000",
    name_th: "เมืองพะเยา แม่กา พะเยา 56000",
    name_en: "Mueang Phayao, Mae Ka, Phayao, 56000",
  },
  {
    id: 560111,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา บ้านใหม่ พะเยา 56000",
    name_th: "เมืองพะเยา บ้านใหม่ พะเยา 56000",
    name_en: "Mueang Phayao, Ban Mai, Phayao, 56000",
  },
  {
    id: 560112,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "จำป่าหวาย",
    subdistrictEn: "Cham Pa Wai",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา จำป่าหวาย พะเยา 56000",
    name_th: "เมืองพะเยา จำป่าหวาย พะเยา 56000",
    name_en: "Mueang Phayao, Cham Pa Wai, Phayao, 56000",
  },
  {
    id: 560113,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "ท่าวังทอง",
    subdistrictEn: "Tha Wang Thong",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา ท่าวังทอง พะเยา 56000",
    name_th: "เมืองพะเยา ท่าวังทอง พะเยา 56000",
    name_en: "Mueang Phayao, Tha Wang Thong, Phayao, 56000",
  },
  {
    id: 560114,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "แม่ใส",
    subdistrictEn: "Mae Sai",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา แม่ใส พะเยา 56000",
    name_th: "เมืองพะเยา แม่ใส พะเยา 56000",
    name_en: "Mueang Phayao, Mae Sai, Phayao, 56000",
  },
  {
    id: 560115,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "บ้านสาง",
    subdistrictEn: "Ban Sang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา บ้านสาง พะเยา 56000",
    name_th: "เมืองพะเยา บ้านสาง พะเยา 56000",
    name_en: "Mueang Phayao, Ban Sang, Phayao, 56000",
  },
  {
    id: 560116,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "ท่าจำปี",
    subdistrictEn: "Tha Champi",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา ท่าจำปี พะเยา 56000",
    name_th: "เมืองพะเยา ท่าจำปี พะเยา 56000",
    name_en: "Mueang Phayao, Tha Champi, Phayao, 56000",
  },
  {
    id: 560118,
    districtTh: "เมืองพะเยา",
    districtEn: "Mueang Phayao",
    subdistrictTh: "สันป่าม่วง",
    subdistrictEn: "San Pa Muang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5601,
    province_code: 44,
    name: "เมืองพะเยา สันป่าม่วง พะเยา 56000",
    name_th: "เมืองพะเยา สันป่าม่วง พะเยา 56000",
    name_en: "Mueang Phayao, San Pa Muang, Phayao, 56000",
  },
  {
    id: 560201,
    districtTh: "จุน",
    districtEn: "Chun",
    subdistrictTh: "ห้วยข้าวก่ำ",
    subdistrictEn: "Huai Khao Kam",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56150,
    district_code: 5602,
    province_code: 44,
    name: "จุน ห้วยข้าวก่ำ พะเยา 56150",
    name_th: "จุน ห้วยข้าวก่ำ พะเยา 56150",
    name_en: "Chun, Huai Khao Kam, Phayao, 56150",
  },
  {
    id: 560202,
    districtTh: "จุน",
    districtEn: "Chun",
    subdistrictTh: "จุน",
    subdistrictEn: "Chun",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56150,
    district_code: 5602,
    province_code: 44,
    name: "จุน จุน พะเยา 56150",
    name_th: "จุน จุน พะเยา 56150",
    name_en: "Chun, Chun, Phayao, 56150",
  },
  {
    id: 560203,
    districtTh: "จุน",
    districtEn: "Chun",
    subdistrictTh: "ลอ",
    subdistrictEn: "Lo",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56150,
    district_code: 5602,
    province_code: 44,
    name: "จุน ลอ พะเยา 56150",
    name_th: "จุน ลอ พะเยา 56150",
    name_en: "Chun, Lo, Phayao, 56150",
  },
  {
    id: 560204,
    districtTh: "จุน",
    districtEn: "Chun",
    subdistrictTh: "หงส์หิน",
    subdistrictEn: "Hong Hin",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56150,
    district_code: 5602,
    province_code: 44,
    name: "จุน หงส์หิน พะเยา 56150",
    name_th: "จุน หงส์หิน พะเยา 56150",
    name_en: "Chun, Hong Hin, Phayao, 56150",
  },
  {
    id: 560205,
    districtTh: "จุน",
    districtEn: "Chun",
    subdistrictTh: "ทุ่งรวงทอง",
    subdistrictEn: "Thung Ruang Thong",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56150,
    district_code: 5602,
    province_code: 44,
    name: "จุน ทุ่งรวงทอง พะเยา 56150",
    name_th: "จุน ทุ่งรวงทอง พะเยา 56150",
    name_en: "Chun, Thung Ruang Thong, Phayao, 56150",
  },
  {
    id: 560206,
    districtTh: "จุน",
    districtEn: "Chun",
    subdistrictTh: "ห้วยยางขาม",
    subdistrictEn: "Huai Yang Kham",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56150,
    district_code: 5602,
    province_code: 44,
    name: "จุน ห้วยยางขาม พะเยา 56150",
    name_th: "จุน ห้วยยางขาม พะเยา 56150",
    name_en: "Chun, Huai Yang Kham, Phayao, 56150",
  },
  {
    id: 560207,
    districtTh: "จุน",
    districtEn: "Chun",
    subdistrictTh: "พระธาตุขิงแกง",
    subdistrictEn: "Phra That Khing Kaeng",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56150,
    district_code: 5602,
    province_code: 44,
    name: "จุน พระธาตุขิงแกง พะเยา 56150",
    name_th: "จุน พระธาตุขิงแกง พะเยา 56150",
    name_en: "Chun, Phra That Khing Kaeng, Phayao, 56150",
  },
  {
    id: 560301,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "หย่วน",
    subdistrictEn: "Yuan",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ หย่วน พะเยา 56110",
    name_th: "เชียงคำ หย่วน พะเยา 56110",
    name_en: "Chiang Kham, Yuan, Phayao, 56110",
  },
  {
    id: 560306,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "น้ำแวน",
    subdistrictEn: "Nam Waen",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ น้ำแวน พะเยา 56110",
    name_th: "เชียงคำ น้ำแวน พะเยา 56110",
    name_en: "Chiang Kham, Nam Waen, Phayao, 56110",
  },
  {
    id: 560307,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ เวียง พะเยา 56110",
    name_th: "เชียงคำ เวียง พะเยา 56110",
    name_en: "Chiang Kham, Wiang, Phayao, 56110",
  },
  {
    id: 560308,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "ฝายกวาง",
    subdistrictEn: "Fai Kwang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ ฝายกวาง พะเยา 56110",
    name_th: "เชียงคำ ฝายกวาง พะเยา 56110",
    name_en: "Chiang Kham, Fai Kwang, Phayao, 56110",
  },
  {
    id: 560309,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "เจดีย์คำ",
    subdistrictEn: "Chedi Kham",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ เจดีย์คำ พะเยา 56110",
    name_th: "เชียงคำ เจดีย์คำ พะเยา 56110",
    name_en: "Chiang Kham, Chedi Kham, Phayao, 56110",
  },
  {
    id: 560310,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "ร่มเย็น",
    subdistrictEn: "Rom Yen",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ ร่มเย็น พะเยา 56110",
    name_th: "เชียงคำ ร่มเย็น พะเยา 56110",
    name_en: "Chiang Kham, Rom Yen, Phayao, 56110",
  },
  {
    id: 560311,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "เชียงบาน",
    subdistrictEn: "Chiang Ban",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ เชียงบาน พะเยา 56110",
    name_th: "เชียงคำ เชียงบาน พะเยา 56110",
    name_en: "Chiang Kham, Chiang Ban, Phayao, 56110",
  },
  {
    id: 560312,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "แม่ลาว",
    subdistrictEn: "Mae Lao",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ แม่ลาว พะเยา 56110",
    name_th: "เชียงคำ แม่ลาว พะเยา 56110",
    name_en: "Chiang Kham, Mae Lao, Phayao, 56110",
  },
  {
    id: 560313,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "อ่างทอง",
    subdistrictEn: "Ang Thong",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ อ่างทอง พะเยา 56110",
    name_th: "เชียงคำ อ่างทอง พะเยา 56110",
    name_en: "Chiang Kham, Ang Thong, Phayao, 56110",
  },
  {
    id: 560314,
    districtTh: "เชียงคำ",
    districtEn: "Chiang Kham",
    subdistrictTh: "ทุ่งผาสุข",
    subdistrictEn: "Thung Pha Suk",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5603,
    province_code: 44,
    name: "เชียงคำ ทุ่งผาสุข พะเยา 56110",
    name_th: "เชียงคำ ทุ่งผาสุข พะเยา 56110",
    name_en: "Chiang Kham, Thung Pha Suk, Phayao, 56110",
  },
  {
    id: 560401,
    districtTh: "เชียงม่วน",
    districtEn: "Chiang Muan",
    subdistrictTh: "เชียงม่วน",
    subdistrictEn: "Chiang Muan",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56160,
    district_code: 5604,
    province_code: 44,
    name: "เชียงม่วน เชียงม่วน พะเยา 56160",
    name_th: "เชียงม่วน เชียงม่วน พะเยา 56160",
    name_en: "Chiang Muan, Chiang Muan, Phayao, 56160",
  },
  {
    id: 560402,
    districtTh: "เชียงม่วน",
    districtEn: "Chiang Muan",
    subdistrictTh: "บ้านมาง",
    subdistrictEn: "Ban Mang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56160,
    district_code: 5604,
    province_code: 44,
    name: "เชียงม่วน บ้านมาง พะเยา 56160",
    name_th: "เชียงม่วน บ้านมาง พะเยา 56160",
    name_en: "Chiang Muan, Ban Mang, Phayao, 56160",
  },
  {
    id: 560403,
    districtTh: "เชียงม่วน",
    districtEn: "Chiang Muan",
    subdistrictTh: "สระ",
    subdistrictEn: "Sa",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56160,
    district_code: 5604,
    province_code: 44,
    name: "เชียงม่วน สระ พะเยา 56160",
    name_th: "เชียงม่วน สระ พะเยา 56160",
    name_en: "Chiang Muan, Sa, Phayao, 56160",
  },
  {
    id: 560501,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "ดอกคำใต้",
    subdistrictEn: "Dok Khamtai",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ ดอกคำใต้ พะเยา 56120",
    name_th: "ดอกคำใต้ ดอกคำใต้ พะเยา 56120",
    name_en: "Dok Khamtai, Dok Khamtai, Phayao, 56120",
  },
  {
    id: 560502,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "ดอนศรีชุม",
    subdistrictEn: "Don Si Chum",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ ดอนศรีชุม พะเยา 56120",
    name_th: "ดอกคำใต้ ดอนศรีชุม พะเยา 56120",
    name_en: "Dok Khamtai, Don Si Chum, Phayao, 56120",
  },
  {
    id: 560503,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "บ้านถ้ำ",
    subdistrictEn: "Ban Tham",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ บ้านถ้ำ พะเยา 56120",
    name_th: "ดอกคำใต้ บ้านถ้ำ พะเยา 56120",
    name_en: "Dok Khamtai, Ban Tham, Phayao, 56120",
  },
  {
    id: 560504,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "บ้านปิน",
    subdistrictEn: "Ban Pin",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ บ้านปิน พะเยา 56120",
    name_th: "ดอกคำใต้ บ้านปิน พะเยา 56120",
    name_en: "Dok Khamtai, Ban Pin, Phayao, 56120",
  },
  {
    id: 560505,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "ห้วยลาน",
    subdistrictEn: "Huai Lan",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ ห้วยลาน พะเยา 56120",
    name_th: "ดอกคำใต้ ห้วยลาน พะเยา 56120",
    name_en: "Dok Khamtai, Huai Lan, Phayao, 56120",
  },
  {
    id: 560506,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "สันโค้ง",
    subdistrictEn: "San Khong",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ สันโค้ง พะเยา 56120",
    name_th: "ดอกคำใต้ สันโค้ง พะเยา 56120",
    name_en: "Dok Khamtai, San Khong, Phayao, 56120",
  },
  {
    id: 560507,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "ป่าซาง",
    subdistrictEn: "Pa Sang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ ป่าซาง พะเยา 56120",
    name_th: "ดอกคำใต้ ป่าซาง พะเยา 56120",
    name_en: "Dok Khamtai, Pa Sang, Phayao, 56120",
  },
  {
    id: 560508,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "หนองหล่ม",
    subdistrictEn: "Nong Lom",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ หนองหล่ม พะเยา 56120",
    name_th: "ดอกคำใต้ หนองหล่ม พะเยา 56120",
    name_en: "Dok Khamtai, Nong Lom, Phayao, 56120",
  },
  {
    id: 560509,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "ดงสุวรรณ",
    subdistrictEn: "Dong Suwan",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ ดงสุวรรณ พะเยา 56120",
    name_th: "ดอกคำใต้ ดงสุวรรณ พะเยา 56120",
    name_en: "Dok Khamtai, Dong Suwan, Phayao, 56120",
  },
  {
    id: 560510,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "บุญเกิด",
    subdistrictEn: "Bun Koet",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ บุญเกิด พะเยา 56120",
    name_th: "ดอกคำใต้ บุญเกิด พะเยา 56120",
    name_en: "Dok Khamtai, Bun Koet, Phayao, 56120",
  },
  {
    id: 560511,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "สว่างอารมณ์",
    subdistrictEn: "Sawang Arom",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ สว่างอารมณ์ พะเยา 56120",
    name_th: "ดอกคำใต้ สว่างอารมณ์ พะเยา 56120",
    name_en: "Dok Khamtai, Sawang Arom, Phayao, 56120",
  },
  {
    id: 560512,
    districtTh: "ดอกคำใต้",
    districtEn: "Dok Khamtai",
    subdistrictTh: "คือเวียง",
    subdistrictEn: "Khue Wiang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56120,
    district_code: 5605,
    province_code: 44,
    name: "ดอกคำใต้ คือเวียง พะเยา 56120",
    name_th: "ดอกคำใต้ คือเวียง พะเยา 56120",
    name_en: "Dok Khamtai, Khue Wiang, Phayao, 56120",
  },
  {
    id: 560601,
    districtTh: "ปง",
    districtEn: "Pong",
    subdistrictTh: "ปง",
    subdistrictEn: "Pong",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56140,
    district_code: 5606,
    province_code: 44,
    name: "ปง ปง พะเยา 56140",
    name_th: "ปง ปง พะเยา 56140",
    name_en: "Pong, Pong, Phayao, 56140",
  },
  {
    id: 560602,
    districtTh: "ปง",
    districtEn: "Pong",
    subdistrictTh: "ควร",
    subdistrictEn: "Khuan",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56140,
    district_code: 5606,
    province_code: 44,
    name: "ปง ควร พะเยา 56140",
    name_th: "ปง ควร พะเยา 56140",
    name_en: "Pong, Khuan, Phayao, 56140",
  },
  {
    id: 560603,
    districtTh: "ปง",
    districtEn: "Pong",
    subdistrictTh: "ออย",
    subdistrictEn: "Oi",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56140,
    district_code: 5606,
    province_code: 44,
    name: "ปง ออย พะเยา 56140",
    name_th: "ปง ออย พะเยา 56140",
    name_en: "Pong, Oi, Phayao, 56140",
  },
  {
    id: 560604,
    districtTh: "ปง",
    districtEn: "Pong",
    subdistrictTh: "งิม",
    subdistrictEn: "Ngim",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56140,
    district_code: 5606,
    province_code: 44,
    name: "ปง งิม พะเยา 56140",
    name_th: "ปง งิม พะเยา 56140",
    name_en: "Pong, Ngim, Phayao, 56140",
  },
  {
    id: 560605,
    districtTh: "ปง",
    districtEn: "Pong",
    subdistrictTh: "ผาช้างน้อย",
    subdistrictEn: "Pha Chang Noi",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56140,
    district_code: 5606,
    province_code: 44,
    name: "ปง ผาช้างน้อย พะเยา 56140",
    name_th: "ปง ผาช้างน้อย พะเยา 56140",
    name_en: "Pong, Pha Chang Noi, Phayao, 56140",
  },
  {
    id: 560606,
    districtTh: "ปง",
    districtEn: "Pong",
    subdistrictTh: "นาปรัง",
    subdistrictEn: "Na Prang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56140,
    district_code: 5606,
    province_code: 44,
    name: "ปง นาปรัง พะเยา 56140",
    name_th: "ปง นาปรัง พะเยา 56140",
    name_en: "Pong, Na Prang, Phayao, 56140",
  },
  {
    id: 560607,
    districtTh: "ปง",
    districtEn: "Pong",
    subdistrictTh: "ขุนควร",
    subdistrictEn: "Khun Khuan",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56140,
    district_code: 5606,
    province_code: 44,
    name: "ปง ขุนควร พะเยา 56140",
    name_th: "ปง ขุนควร พะเยา 56140",
    name_en: "Pong, Khun Khuan, Phayao, 56140",
  },
  {
    id: 560701,
    districtTh: "แม่ใจ",
    districtEn: "Mae Chai",
    subdistrictTh: "แม่ใจ",
    subdistrictEn: "Mae Chai",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56130,
    district_code: 5607,
    province_code: 44,
    name: "แม่ใจ แม่ใจ พะเยา 56130",
    name_th: "แม่ใจ แม่ใจ พะเยา 56130",
    name_en: "Mae Chai, Mae Chai, Phayao, 56130",
  },
  {
    id: 560702,
    districtTh: "แม่ใจ",
    districtEn: "Mae Chai",
    subdistrictTh: "ศรีถ้อย",
    subdistrictEn: "Si Thoi",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56130,
    district_code: 5607,
    province_code: 44,
    name: "แม่ใจ ศรีถ้อย พะเยา 56130",
    name_th: "แม่ใจ ศรีถ้อย พะเยา 56130",
    name_en: "Mae Chai, Si Thoi, Phayao, 56130",
  },
  {
    id: 560703,
    districtTh: "แม่ใจ",
    districtEn: "Mae Chai",
    subdistrictTh: "แม่สุก",
    subdistrictEn: "Mae Suk",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56130,
    district_code: 5607,
    province_code: 44,
    name: "แม่ใจ แม่สุก พะเยา 56130",
    name_th: "แม่ใจ แม่สุก พะเยา 56130",
    name_en: "Mae Chai, Mae Suk, Phayao, 56130",
  },
  {
    id: 560704,
    districtTh: "แม่ใจ",
    districtEn: "Mae Chai",
    subdistrictTh: "ป่าแฝก",
    subdistrictEn: "Pa Faek",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56130,
    district_code: 5607,
    province_code: 44,
    name: "แม่ใจ ป่าแฝก พะเยา 56130",
    name_th: "แม่ใจ ป่าแฝก พะเยา 56130",
    name_en: "Mae Chai, Pa Faek, Phayao, 56130",
  },
  {
    id: 560705,
    districtTh: "แม่ใจ",
    districtEn: "Mae Chai",
    subdistrictTh: "บ้านเหล่า",
    subdistrictEn: "Ban Lao",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56130,
    district_code: 5607,
    province_code: 44,
    name: "แม่ใจ บ้านเหล่า พะเยา 56130",
    name_th: "แม่ใจ บ้านเหล่า พะเยา 56130",
    name_en: "Mae Chai, Ban Lao, Phayao, 56130",
  },
  {
    id: 560706,
    districtTh: "แม่ใจ",
    districtEn: "Mae Chai",
    subdistrictTh: "เจริญราษฎร์",
    subdistrictEn: "Charoen Rat",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56130,
    district_code: 5607,
    province_code: 44,
    name: "แม่ใจ เจริญราษฎร์ พะเยา 56130",
    name_th: "แม่ใจ เจริญราษฎร์ พะเยา 56130",
    name_en: "Mae Chai, Charoen Rat, Phayao, 56130",
  },
  {
    id: 560801,
    districtTh: "ภูซาง",
    districtEn: "Phu Sang",
    subdistrictTh: "ภูซาง",
    subdistrictEn: "Phu Sang",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5608,
    province_code: 44,
    name: "ภูซาง ภูซาง พะเยา 56110",
    name_th: "ภูซาง ภูซาง พะเยา 56110",
    name_en: "Phu Sang, Phu Sang, Phayao, 56110",
  },
  {
    id: 560802,
    districtTh: "ภูซาง",
    districtEn: "Phu Sang",
    subdistrictTh: "ป่าสัก",
    subdistrictEn: "Pa Sak",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5608,
    province_code: 44,
    name: "ภูซาง ป่าสัก พะเยา 56110",
    name_th: "ภูซาง ป่าสัก พะเยา 56110",
    name_en: "Phu Sang, Pa Sak, Phayao, 56110",
  },
  {
    id: 560803,
    districtTh: "ภูซาง",
    districtEn: "Phu Sang",
    subdistrictTh: "ทุ่งกล้วย",
    subdistrictEn: "Thung Kluai",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5608,
    province_code: 44,
    name: "ภูซาง ทุ่งกล้วย พะเยา 56110",
    name_th: "ภูซาง ทุ่งกล้วย พะเยา 56110",
    name_en: "Phu Sang, Thung Kluai, Phayao, 56110",
  },
  {
    id: 560804,
    districtTh: "ภูซาง",
    districtEn: "Phu Sang",
    subdistrictTh: "เชียงแรง",
    subdistrictEn: "Chiang Raeng",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5608,
    province_code: 44,
    name: "ภูซาง เชียงแรง พะเยา 56110",
    name_th: "ภูซาง เชียงแรง พะเยา 56110",
    name_en: "Phu Sang, Chiang Raeng, Phayao, 56110",
  },
  {
    id: 560805,
    districtTh: "ภูซาง",
    districtEn: "Phu Sang",
    subdistrictTh: "สบบง",
    subdistrictEn: "Sop Bong",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56110,
    district_code: 5608,
    province_code: 44,
    name: "ภูซาง สบบง พะเยา 56110",
    name_th: "ภูซาง สบบง พะเยา 56110",
    name_en: "Phu Sang, Sop Bong, Phayao, 56110",
  },
  {
    id: 560901,
    districtTh: "ภูกามยาว",
    districtEn: "Phu Kamyao",
    subdistrictTh: "ห้วยแก้ว",
    subdistrictEn: "Huai Kaeo",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5609,
    province_code: 44,
    name: "ภูกามยาว ห้วยแก้ว พะเยา 56000",
    name_th: "ภูกามยาว ห้วยแก้ว พะเยา 56000",
    name_en: "Phu Kamyao, Huai Kaeo, Phayao, 56000",
  },
  {
    id: 560902,
    districtTh: "ภูกามยาว",
    districtEn: "Phu Kamyao",
    subdistrictTh: "ดงเจน",
    subdistrictEn: "Dong Chen",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5609,
    province_code: 44,
    name: "ภูกามยาว ดงเจน พะเยา 56000",
    name_th: "ภูกามยาว ดงเจน พะเยา 56000",
    name_en: "Phu Kamyao, Dong Chen, Phayao, 56000",
  },
  {
    id: 560903,
    districtTh: "ภูกามยาว",
    districtEn: "Phu Kamyao",
    subdistrictTh: "แม่อิง",
    subdistrictEn: "Mae Ing",
    provinceTh: "พะเยา",
    provinceEn: "Phayao",
    zipcode: 56000,
    district_code: 5609,
    province_code: 44,
    name: "ภูกามยาว แม่อิง พะเยา 56000",
    name_th: "ภูกามยาว แม่อิง พะเยา 56000",
    name_en: "Phu Kamyao, Mae Ing, Phayao, 56000",
  },
  {
    id: 570101,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย เวียง เชียงราย 57000",
    name_th: "เมืองเชียงราย เวียง เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Wiang, Chiang Rai, 57000",
  },
  {
    id: 570102,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "รอบเวียง",
    subdistrictEn: "Rop Wiang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย รอบเวียง เชียงราย 57000",
    name_th: "เมืองเชียงราย รอบเวียง เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Rop Wiang, Chiang Rai, 57000",
  },
  {
    id: 570103,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "บ้านดู่",
    subdistrictEn: "Ban Du",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57100,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย บ้านดู่ เชียงราย 57100",
    name_th: "เมืองเชียงราย บ้านดู่ เชียงราย 57100",
    name_en: "Mueang Chiang Rai, Ban Du, Chiang Rai, 57100",
  },
  {
    id: 570104,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "นางแล",
    subdistrictEn: "Nang Lae",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57100,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย นางแล เชียงราย 57100",
    name_th: "เมืองเชียงราย นางแล เชียงราย 57100",
    name_en: "Mueang Chiang Rai, Nang Lae, Chiang Rai, 57100",
  },
  {
    id: 570105,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "แม่ข้าวต้ม",
    subdistrictEn: "Mae Khao Tom",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57100,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย แม่ข้าวต้ม เชียงราย 57100",
    name_th: "เมืองเชียงราย แม่ข้าวต้ม เชียงราย 57100",
    name_en: "Mueang Chiang Rai, Mae Khao Tom, Chiang Rai, 57100",
  },
  {
    id: 570106,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "แม่ยาว",
    subdistrictEn: "Mae Yao",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57100,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย แม่ยาว เชียงราย 57100",
    name_th: "เมืองเชียงราย แม่ยาว เชียงราย 57100",
    name_en: "Mueang Chiang Rai, Mae Yao, Chiang Rai, 57100",
  },
  {
    id: 570107,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "สันทราย",
    subdistrictEn: "San Sai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย สันทราย เชียงราย 57000",
    name_th: "เมืองเชียงราย สันทราย เชียงราย 57000",
    name_en: "Mueang Chiang Rai, San Sai, Chiang Rai, 57000",
  },
  {
    id: 570111,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "แม่กรณ์",
    subdistrictEn: "Mae Kon",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย แม่กรณ์ เชียงราย 57000",
    name_th: "เมืองเชียงราย แม่กรณ์ เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Mae Kon, Chiang Rai, 57000",
  },
  {
    id: 570112,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "ห้วยชมภู",
    subdistrictEn: "Huai Chomphu",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย ห้วยชมภู เชียงราย 57000",
    name_th: "เมืองเชียงราย ห้วยชมภู เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Huai Chomphu, Chiang Rai, 57000",
  },
  {
    id: 570113,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "ห้วยสัก",
    subdistrictEn: "Huai Sak",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย ห้วยสัก เชียงราย 57000",
    name_th: "เมืองเชียงราย ห้วยสัก เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Huai Sak, Chiang Rai, 57000",
  },
  {
    id: 570114,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "ริมกก",
    subdistrictEn: "Rim Kok",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57100,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย ริมกก เชียงราย 57100",
    name_th: "เมืองเชียงราย ริมกก เชียงราย 57100",
    name_en: "Mueang Chiang Rai, Rim Kok, Chiang Rai, 57100",
  },
  {
    id: 570115,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "ดอยลาน",
    subdistrictEn: "Doi Lan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย ดอยลาน เชียงราย 57000",
    name_th: "เมืองเชียงราย ดอยลาน เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Doi Lan, Chiang Rai, 57000",
  },
  {
    id: 570116,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "ป่าอ้อดอนชัย",
    subdistrictEn: "Pa O Don Chai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย ป่าอ้อดอนชัย เชียงราย 57000",
    name_th: "เมืองเชียงราย ป่าอ้อดอนชัย เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Pa O Don Chai, Chiang Rai, 57000",
  },
  {
    id: 570118,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "ท่าสาย",
    subdistrictEn: "Tha Sai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย ท่าสาย เชียงราย 57000",
    name_th: "เมืองเชียงราย ท่าสาย เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Tha Sai, Chiang Rai, 57000",
  },
  {
    id: 570120,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "ดอยฮาง",
    subdistrictEn: "Doi Hang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย ดอยฮาง เชียงราย 57000",
    name_th: "เมืองเชียงราย ดอยฮาง เชียงราย 57000",
    name_en: "Mueang Chiang Rai, Doi Hang, Chiang Rai, 57000",
  },
  {
    id: 570121,
    districtTh: "เมืองเชียงราย",
    districtEn: "Mueang Chiang Rai",
    subdistrictTh: "ท่าสุด",
    subdistrictEn: "Tha Sut",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57100,
    district_code: 5701,
    province_code: 45,
    name: "เมืองเชียงราย ท่าสุด เชียงราย 57100",
    name_th: "เมืองเชียงราย ท่าสุด เชียงราย 57100",
    name_en: "Mueang Chiang Rai, Tha Sut, Chiang Rai, 57100",
  },
  {
    id: 570202,
    districtTh: "เวียงชัย",
    districtEn: "Wiang Chai",
    subdistrictTh: "เวียงชัย",
    subdistrictEn: "Wiang Chai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57210,
    district_code: 5702,
    province_code: 45,
    name: "เวียงชัย เวียงชัย เชียงราย 57210",
    name_th: "เวียงชัย เวียงชัย เชียงราย 57210",
    name_en: "Wiang Chai, Wiang Chai, Chiang Rai, 57210",
  },
  {
    id: 570203,
    districtTh: "เวียงชัย",
    districtEn: "Wiang Chai",
    subdistrictTh: "ผางาม",
    subdistrictEn: "Pha Ngam",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57210,
    district_code: 5702,
    province_code: 45,
    name: "เวียงชัย ผางาม เชียงราย 57210",
    name_th: "เวียงชัย ผางาม เชียงราย 57210",
    name_en: "Wiang Chai, Pha Ngam, Chiang Rai, 57210",
  },
  {
    id: 570204,
    districtTh: "เวียงชัย",
    districtEn: "Wiang Chai",
    subdistrictTh: "เวียงเหนือ",
    subdistrictEn: "Wiang Nuea",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57210,
    district_code: 5702,
    province_code: 45,
    name: "เวียงชัย เวียงเหนือ เชียงราย 57210",
    name_th: "เวียงชัย เวียงเหนือ เชียงราย 57210",
    name_en: "Wiang Chai, Wiang Nuea, Chiang Rai, 57210",
  },
  {
    id: 570206,
    districtTh: "เวียงชัย",
    districtEn: "Wiang Chai",
    subdistrictTh: "ดอนศิลา",
    subdistrictEn: "Don Sila",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57210,
    district_code: 5702,
    province_code: 45,
    name: "เวียงชัย ดอนศิลา เชียงราย 57210",
    name_th: "เวียงชัย ดอนศิลา เชียงราย 57210",
    name_en: "Wiang Chai, Don Sila, Chiang Rai, 57210",
  },
  {
    id: 570208,
    districtTh: "เวียงชัย",
    districtEn: "Wiang Chai",
    subdistrictTh: "เมืองชุม",
    subdistrictEn: "Mueang Chum",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57210,
    district_code: 5702,
    province_code: 45,
    name: "เวียงชัย เมืองชุม เชียงราย 57210",
    name_th: "เวียงชัย เมืองชุม เชียงราย 57210",
    name_en: "Wiang Chai, Mueang Chum, Chiang Rai, 57210",
  },
  {
    id: 570301,
    districtTh: "เชียงของ",
    districtEn: "Chiang Khong",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57140,
    district_code: 5703,
    province_code: 45,
    name: "เชียงของ เวียง เชียงราย 57140",
    name_th: "เชียงของ เวียง เชียงราย 57140",
    name_en: "Chiang Khong, Wiang, Chiang Rai, 57140",
  },
  {
    id: 570302,
    districtTh: "เชียงของ",
    districtEn: "Chiang Khong",
    subdistrictTh: "สถาน",
    subdistrictEn: "Sathan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57140,
    district_code: 5703,
    province_code: 45,
    name: "เชียงของ สถาน เชียงราย 57140",
    name_th: "เชียงของ สถาน เชียงราย 57140",
    name_en: "Chiang Khong, Sathan, Chiang Rai, 57140",
  },
  {
    id: 570303,
    districtTh: "เชียงของ",
    districtEn: "Chiang Khong",
    subdistrictTh: "ครึ่ง",
    subdistrictEn: "Khrueng",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57140,
    district_code: 5703,
    province_code: 45,
    name: "เชียงของ ครึ่ง เชียงราย 57140",
    name_th: "เชียงของ ครึ่ง เชียงราย 57140",
    name_en: "Chiang Khong, Khrueng, Chiang Rai, 57140",
  },
  {
    id: 570304,
    districtTh: "เชียงของ",
    districtEn: "Chiang Khong",
    subdistrictTh: "บุญเรือง",
    subdistrictEn: "Bun Rueang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57140,
    district_code: 5703,
    province_code: 45,
    name: "เชียงของ บุญเรือง เชียงราย 57140",
    name_th: "เชียงของ บุญเรือง เชียงราย 57140",
    name_en: "Chiang Khong, Bun Rueang, Chiang Rai, 57140",
  },
  {
    id: 570305,
    districtTh: "เชียงของ",
    districtEn: "Chiang Khong",
    subdistrictTh: "ห้วยซ้อ",
    subdistrictEn: "Huai So",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57140,
    district_code: 5703,
    province_code: 45,
    name: "เชียงของ ห้วยซ้อ เชียงราย 57140",
    name_th: "เชียงของ ห้วยซ้อ เชียงราย 57140",
    name_en: "Chiang Khong, Huai So, Chiang Rai, 57140",
  },
  {
    id: 570308,
    districtTh: "เชียงของ",
    districtEn: "Chiang Khong",
    subdistrictTh: "ศรีดอนชัย",
    subdistrictEn: "Si Don Chai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57230,
    district_code: 5703,
    province_code: 45,
    name: "เชียงของ ศรีดอนชัย เชียงราย 57230",
    name_th: "เชียงของ ศรีดอนชัย เชียงราย 57230",
    name_en: "Chiang Khong, Si Don Chai, Chiang Rai, 57230",
  },
  {
    id: 570310,
    districtTh: "เชียงของ",
    districtEn: "Chiang Khong",
    subdistrictTh: "ริมโขง",
    subdistrictEn: "Rim Khong",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57140,
    district_code: 5703,
    province_code: 45,
    name: "เชียงของ ริมโขง เชียงราย 57140",
    name_th: "เชียงของ ริมโขง เชียงราย 57140",
    name_en: "Chiang Khong, Rim Khong, Chiang Rai, 57140",
  },
  {
    id: 570401,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57160,
    district_code: 5704,
    province_code: 45,
    name: "เทิง เวียง เชียงราย 57160",
    name_th: "เทิง เวียง เชียงราย 57160",
    name_en: "Thoeng, Wiang, Chiang Rai, 57160",
  },
  {
    id: 570402,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "งิ้ว",
    subdistrictEn: "Ngio",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57160,
    district_code: 5704,
    province_code: 45,
    name: "เทิง งิ้ว เชียงราย 57160",
    name_th: "เทิง งิ้ว เชียงราย 57160",
    name_en: "Thoeng, Ngio, Chiang Rai, 57160",
  },
  {
    id: 570403,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "ปล้อง",
    subdistrictEn: "Plong",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57230,
    district_code: 5704,
    province_code: 45,
    name: "เทิง ปล้อง เชียงราย 57230",
    name_th: "เทิง ปล้อง เชียงราย 57230",
    name_en: "Thoeng, Plong, Chiang Rai, 57230",
  },
  {
    id: 570404,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "แม่ลอย",
    subdistrictEn: "Mae Loi",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57230,
    district_code: 5704,
    province_code: 45,
    name: "เทิง แม่ลอย เชียงราย 57230",
    name_th: "เทิง แม่ลอย เชียงราย 57230",
    name_en: "Thoeng, Mae Loi, Chiang Rai, 57230",
  },
  {
    id: 570405,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "เชียงเคี่ยน",
    subdistrictEn: "Chiang Khian",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57230,
    district_code: 5704,
    province_code: 45,
    name: "เทิง เชียงเคี่ยน เชียงราย 57230",
    name_th: "เทิง เชียงเคี่ยน เชียงราย 57230",
    name_en: "Thoeng, Chiang Khian, Chiang Rai, 57230",
  },
  {
    id: 570409,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "ตับเต่า",
    subdistrictEn: "Tap Tao",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57160,
    district_code: 5704,
    province_code: 45,
    name: "เทิง ตับเต่า เชียงราย 57160",
    name_th: "เทิง ตับเต่า เชียงราย 57160",
    name_en: "Thoeng, Tap Tao, Chiang Rai, 57160",
  },
  {
    id: 570410,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "หงาว",
    subdistrictEn: "Ngao",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57160,
    district_code: 5704,
    province_code: 45,
    name: "เทิง หงาว เชียงราย 57160",
    name_th: "เทิง หงาว เชียงราย 57160",
    name_en: "Thoeng, Ngao, Chiang Rai, 57160",
  },
  {
    id: 570411,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "สันทรายงาม",
    subdistrictEn: "San Sai Ngam",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57160,
    district_code: 5704,
    province_code: 45,
    name: "เทิง สันทรายงาม เชียงราย 57160",
    name_th: "เทิง สันทรายงาม เชียงราย 57160",
    name_en: "Thoeng, San Sai Ngam, Chiang Rai, 57160",
  },
  {
    id: 570412,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "ศรีดอนไชย",
    subdistrictEn: "Si Don Chai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57160,
    district_code: 5704,
    province_code: 45,
    name: "เทิง ศรีดอนไชย เชียงราย 57160",
    name_th: "เทิง ศรีดอนไชย เชียงราย 57160",
    name_en: "Thoeng, Si Don Chai, Chiang Rai, 57160",
  },
  {
    id: 570413,
    districtTh: "เทิง",
    districtEn: "Thoeng",
    subdistrictTh: "หนองแรด",
    subdistrictEn: "Nong Raet",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57160,
    district_code: 5704,
    province_code: 45,
    name: "เทิง หนองแรด เชียงราย 57160",
    name_th: "เทิง หนองแรด เชียงราย 57160",
    name_en: "Thoeng, Nong Raet, Chiang Rai, 57160",
  },
  {
    id: 570501,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "สันมะเค็ด",
    subdistrictEn: "San Makhet",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน สันมะเค็ด เชียงราย 57120",
    name_th: "พาน สันมะเค็ด เชียงราย 57120",
    name_en: "Phan, San Makhet, Chiang Rai, 57120",
  },
  {
    id: 570502,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "แม่อ้อ",
    subdistrictEn: "Mae O",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน แม่อ้อ เชียงราย 57120",
    name_th: "พาน แม่อ้อ เชียงราย 57120",
    name_en: "Phan, Mae O, Chiang Rai, 57120",
  },
  {
    id: 570503,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "ธารทอง",
    subdistrictEn: "Than Thong",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57250,
    district_code: 5705,
    province_code: 45,
    name: "พาน ธารทอง เชียงราย 57250",
    name_th: "พาน ธารทอง เชียงราย 57250",
    name_en: "Phan, Than Thong, Chiang Rai, 57250",
  },
  {
    id: 570504,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "สันติสุข",
    subdistrictEn: "Santi Suk",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน สันติสุข เชียงราย 57120",
    name_th: "พาน สันติสุข เชียงราย 57120",
    name_en: "Phan, Santi Suk, Chiang Rai, 57120",
  },
  {
    id: 570505,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "ดอยงาม",
    subdistrictEn: "Doi Ngam",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน ดอยงาม เชียงราย 57120",
    name_th: "พาน ดอยงาม เชียงราย 57120",
    name_en: "Phan, Doi Ngam, Chiang Rai, 57120",
  },
  {
    id: 570506,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "หัวง้ม",
    subdistrictEn: "Hua Ngom",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน หัวง้ม เชียงราย 57120",
    name_th: "พาน หัวง้ม เชียงราย 57120",
    name_en: "Phan, Hua Ngom, Chiang Rai, 57120",
  },
  {
    id: 570507,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "เจริญเมือง",
    subdistrictEn: "Charoen Mueang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน เจริญเมือง เชียงราย 57120",
    name_th: "พาน เจริญเมือง เชียงราย 57120",
    name_en: "Phan, Charoen Mueang, Chiang Rai, 57120",
  },
  {
    id: 570508,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "ป่าหุ่ง",
    subdistrictEn: "Pa Hung",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน ป่าหุ่ง เชียงราย 57120",
    name_th: "พาน ป่าหุ่ง เชียงราย 57120",
    name_en: "Phan, Pa Hung, Chiang Rai, 57120",
  },
  {
    id: 570509,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "ม่วงคำ",
    subdistrictEn: "Muang Kham",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน ม่วงคำ เชียงราย 57120",
    name_th: "พาน ม่วงคำ เชียงราย 57120",
    name_en: "Phan, Muang Kham, Chiang Rai, 57120",
  },
  {
    id: 570510,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "ทรายขาว",
    subdistrictEn: "Sai Khao",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน ทรายขาว เชียงราย 57120",
    name_th: "พาน ทรายขาว เชียงราย 57120",
    name_en: "Phan, Sai Khao, Chiang Rai, 57120",
  },
  {
    id: 570511,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "สันกลาง",
    subdistrictEn: "San Klang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน สันกลาง เชียงราย 57120",
    name_th: "พาน สันกลาง เชียงราย 57120",
    name_en: "Phan, San Klang, Chiang Rai, 57120",
  },
  {
    id: 570512,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "แม่เย็น",
    subdistrictEn: "Mae Yen",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57280,
    district_code: 5705,
    province_code: 45,
    name: "พาน แม่เย็น เชียงราย 57280",
    name_th: "พาน แม่เย็น เชียงราย 57280",
    name_en: "Phan, Mae Yen, Chiang Rai, 57280",
  },
  {
    id: 570513,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "เมืองพาน",
    subdistrictEn: "Mueang Phan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน เมืองพาน เชียงราย 57120",
    name_th: "พาน เมืองพาน เชียงราย 57120",
    name_en: "Phan, Mueang Phan, Chiang Rai, 57120",
  },
  {
    id: 570514,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "ทานตะวัน",
    subdistrictEn: "Than Tawan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57280,
    district_code: 5705,
    province_code: 45,
    name: "พาน ทานตะวัน เชียงราย 57280",
    name_th: "พาน ทานตะวัน เชียงราย 57280",
    name_en: "Phan, Than Tawan, Chiang Rai, 57280",
  },
  {
    id: 570515,
    districtTh: "พาน",
    districtEn: "Phan",
    subdistrictTh: "เวียงห้าว",
    subdistrictEn: "Wiang Hao",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57120,
    district_code: 5705,
    province_code: 45,
    name: "พาน เวียงห้าว เชียงราย 57120",
    name_th: "พาน เวียงห้าว เชียงราย 57120",
    name_en: "Phan, Wiang Hao, Chiang Rai, 57120",
  },
  {
    id: 570601,
    districtTh: "ป่าแดด",
    districtEn: "Pa Daet",
    subdistrictTh: "ป่าแดด",
    subdistrictEn: "Pa Daet",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57190,
    district_code: 5706,
    province_code: 45,
    name: "ป่าแดด ป่าแดด เชียงราย 57190",
    name_th: "ป่าแดด ป่าแดด เชียงราย 57190",
    name_en: "Pa Daet, Pa Daet, Chiang Rai, 57190",
  },
  {
    id: 570602,
    districtTh: "ป่าแดด",
    districtEn: "Pa Daet",
    subdistrictTh: "ป่าแงะ",
    subdistrictEn: "Pa Ngae",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57190,
    district_code: 5706,
    province_code: 45,
    name: "ป่าแดด ป่าแงะ เชียงราย 57190",
    name_th: "ป่าแดด ป่าแงะ เชียงราย 57190",
    name_en: "Pa Daet, Pa Ngae, Chiang Rai, 57190",
  },
  {
    id: 570603,
    districtTh: "ป่าแดด",
    districtEn: "Pa Daet",
    subdistrictTh: "สันมะค่า",
    subdistrictEn: "San Makha",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57190,
    district_code: 5706,
    province_code: 45,
    name: "ป่าแดด สันมะค่า เชียงราย 57190",
    name_th: "ป่าแดด สันมะค่า เชียงราย 57190",
    name_en: "Pa Daet, San Makha, Chiang Rai, 57190",
  },
  {
    id: 570605,
    districtTh: "ป่าแดด",
    districtEn: "Pa Daet",
    subdistrictTh: "โรงช้าง",
    subdistrictEn: "Rong Chang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57190,
    district_code: 5706,
    province_code: 45,
    name: "ป่าแดด โรงช้าง เชียงราย 57190",
    name_th: "ป่าแดด โรงช้าง เชียงราย 57190",
    name_en: "Pa Daet, Rong Chang, Chiang Rai, 57190",
  },
  {
    id: 570606,
    districtTh: "ป่าแดด",
    districtEn: "Pa Daet",
    subdistrictTh: "ศรีโพธิ์เงิน",
    subdistrictEn: "Si Pho Ngoen",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57190,
    district_code: 5706,
    province_code: 45,
    name: "ป่าแดด ศรีโพธิ์เงิน เชียงราย 57190",
    name_th: "ป่าแดด ศรีโพธิ์เงิน เชียงราย 57190",
    name_en: "Pa Daet, Si Pho Ngoen, Chiang Rai, 57190",
  },
  {
    id: 570701,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "แม่จัน",
    subdistrictEn: "Mae Chan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน แม่จัน เชียงราย 57110",
    name_th: "แม่จัน แม่จัน เชียงราย 57110",
    name_en: "Mae Chan, Mae Chan, Chiang Rai, 57110",
  },
  {
    id: 570702,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "จันจว้า",
    subdistrictEn: "Chan Chwa",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57270,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน จันจว้า เชียงราย 57270",
    name_th: "แม่จัน จันจว้า เชียงราย 57270",
    name_en: "Mae Chan, Chan Chwa, Chiang Rai, 57270",
  },
  {
    id: 570703,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "แม่คำ",
    subdistrictEn: "Mae Kham",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57240,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน แม่คำ เชียงราย 57240",
    name_th: "แม่จัน แม่คำ เชียงราย 57240",
    name_en: "Mae Chan, Mae Kham, Chiang Rai, 57240",
  },
  {
    id: 570704,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "ป่าซาง",
    subdistrictEn: "Pa Sang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน ป่าซาง เชียงราย 57110",
    name_th: "แม่จัน ป่าซาง เชียงราย 57110",
    name_en: "Mae Chan, Pa Sang, Chiang Rai, 57110",
  },
  {
    id: 570705,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "สันทราย",
    subdistrictEn: "San Sai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน สันทราย เชียงราย 57110",
    name_th: "แม่จัน สันทราย เชียงราย 57110",
    name_en: "Mae Chan, San Sai, Chiang Rai, 57110",
  },
  {
    id: 570706,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "ท่าข้าวเปลือก",
    subdistrictEn: "Tha Khao Plueak",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน ท่าข้าวเปลือก เชียงราย 57110",
    name_th: "แม่จัน ท่าข้าวเปลือก เชียงราย 57110",
    name_en: "Mae Chan, Tha Khao Plueak, Chiang Rai, 57110",
  },
  {
    id: 570708,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "ป่าตึง",
    subdistrictEn: "Pa Tueng",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน ป่าตึง เชียงราย 57110",
    name_th: "แม่จัน ป่าตึง เชียงราย 57110",
    name_en: "Mae Chan, Pa Tueng, Chiang Rai, 57110",
  },
  {
    id: 570710,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "แม่ไร่",
    subdistrictEn: "Mae Rai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57240,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน แม่ไร่ เชียงราย 57240",
    name_th: "แม่จัน แม่ไร่ เชียงราย 57240",
    name_en: "Mae Chan, Mae Rai, Chiang Rai, 57240",
  },
  {
    id: 570711,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "ศรีค้ำ",
    subdistrictEn: "Si Kham",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน ศรีค้ำ เชียงราย 57110",
    name_th: "แม่จัน ศรีค้ำ เชียงราย 57110",
    name_en: "Mae Chan, Si Kham, Chiang Rai, 57110",
  },
  {
    id: 570712,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "จันจว้าใต้",
    subdistrictEn: "Chan Chwa Tai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57270,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน จันจว้าใต้ เชียงราย 57270",
    name_th: "แม่จัน จันจว้าใต้ เชียงราย 57270",
    name_en: "Mae Chan, Chan Chwa Tai, Chiang Rai, 57270",
  },
  {
    id: 570713,
    districtTh: "แม่จัน",
    districtEn: "Mae Chan",
    subdistrictTh: "จอมสวรรค์",
    subdistrictEn: "Chom Sawan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5707,
    province_code: 45,
    name: "แม่จัน จอมสวรรค์ เชียงราย 57110",
    name_th: "แม่จัน จอมสวรรค์ เชียงราย 57110",
    name_en: "Mae Chan, Chom Sawan, Chiang Rai, 57110",
  },
  {
    id: 570801,
    districtTh: "เชียงแสน",
    districtEn: "Chiang Saen",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57150,
    district_code: 5708,
    province_code: 45,
    name: "เชียงแสน เวียง เชียงราย 57150",
    name_th: "เชียงแสน เวียง เชียงราย 57150",
    name_en: "Chiang Saen, Wiang, Chiang Rai, 57150",
  },
  {
    id: 570802,
    districtTh: "เชียงแสน",
    districtEn: "Chiang Saen",
    subdistrictTh: "ป่าสัก",
    subdistrictEn: "Pa Sak",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57150,
    district_code: 5708,
    province_code: 45,
    name: "เชียงแสน ป่าสัก เชียงราย 57150",
    name_th: "เชียงแสน ป่าสัก เชียงราย 57150",
    name_en: "Chiang Saen, Pa Sak, Chiang Rai, 57150",
  },
  {
    id: 570803,
    districtTh: "เชียงแสน",
    districtEn: "Chiang Saen",
    subdistrictTh: "บ้านแซว",
    subdistrictEn: "Ban Saeo",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57150,
    district_code: 5708,
    province_code: 45,
    name: "เชียงแสน บ้านแซว เชียงราย 57150",
    name_th: "เชียงแสน บ้านแซว เชียงราย 57150",
    name_en: "Chiang Saen, Ban Saeo, Chiang Rai, 57150",
  },
  {
    id: 570804,
    districtTh: "เชียงแสน",
    districtEn: "Chiang Saen",
    subdistrictTh: "ศรีดอนมูล",
    subdistrictEn: "Si Don Mun",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57150,
    district_code: 5708,
    province_code: 45,
    name: "เชียงแสน ศรีดอนมูล เชียงราย 57150",
    name_th: "เชียงแสน ศรีดอนมูล เชียงราย 57150",
    name_en: "Chiang Saen, Si Don Mun, Chiang Rai, 57150",
  },
  {
    id: 570805,
    districtTh: "เชียงแสน",
    districtEn: "Chiang Saen",
    subdistrictTh: "แม่เงิน",
    subdistrictEn: "Mae Ngoen",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57150,
    district_code: 5708,
    province_code: 45,
    name: "เชียงแสน แม่เงิน เชียงราย 57150",
    name_th: "เชียงแสน แม่เงิน เชียงราย 57150",
    name_en: "Chiang Saen, Mae Ngoen, Chiang Rai, 57150",
  },
  {
    id: 570806,
    districtTh: "เชียงแสน",
    districtEn: "Chiang Saen",
    subdistrictTh: "โยนก",
    subdistrictEn: "Yonok",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57150,
    district_code: 5708,
    province_code: 45,
    name: "เชียงแสน โยนก เชียงราย 57150",
    name_th: "เชียงแสน โยนก เชียงราย 57150",
    name_en: "Chiang Saen, Yonok, Chiang Rai, 57150",
  },
  {
    id: 570901,
    districtTh: "แม่สาย",
    districtEn: "Mae Sai",
    subdistrictTh: "แม่สาย",
    subdistrictEn: "Mae Sai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57130,
    district_code: 5709,
    province_code: 45,
    name: "แม่สาย แม่สาย เชียงราย 57130",
    name_th: "แม่สาย แม่สาย เชียงราย 57130",
    name_en: "Mae Sai, Mae Sai, Chiang Rai, 57130",
  },
  {
    id: 570902,
    districtTh: "แม่สาย",
    districtEn: "Mae Sai",
    subdistrictTh: "ห้วยไคร้",
    subdistrictEn: "Huai Khrai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57220,
    district_code: 5709,
    province_code: 45,
    name: "แม่สาย ห้วยไคร้ เชียงราย 57220",
    name_th: "แม่สาย ห้วยไคร้ เชียงราย 57220",
    name_en: "Mae Sai, Huai Khrai, Chiang Rai, 57220",
  },
  {
    id: 570903,
    districtTh: "แม่สาย",
    districtEn: "Mae Sai",
    subdistrictTh: "เกาะช้าง",
    subdistrictEn: "Ko Chang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57130,
    district_code: 5709,
    province_code: 45,
    name: "แม่สาย เกาะช้าง เชียงราย 57130",
    name_th: "แม่สาย เกาะช้าง เชียงราย 57130",
    name_en: "Mae Sai, Ko Chang, Chiang Rai, 57130",
  },
  {
    id: 570904,
    districtTh: "แม่สาย",
    districtEn: "Mae Sai",
    subdistrictTh: "โป่งผา",
    subdistrictEn: "Pong Pha",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57130,
    district_code: 5709,
    province_code: 45,
    name: "แม่สาย โป่งผา เชียงราย 57130",
    name_th: "แม่สาย โป่งผา เชียงราย 57130",
    name_en: "Mae Sai, Pong Pha, Chiang Rai, 57130",
  },
  {
    id: 570905,
    districtTh: "แม่สาย",
    districtEn: "Mae Sai",
    subdistrictTh: "ศรีเมืองชุม",
    subdistrictEn: "Si Mueang Chum",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57130,
    district_code: 5709,
    province_code: 45,
    name: "แม่สาย ศรีเมืองชุม เชียงราย 57130",
    name_th: "แม่สาย ศรีเมืองชุม เชียงราย 57130",
    name_en: "Mae Sai, Si Mueang Chum, Chiang Rai, 57130",
  },
  {
    id: 570906,
    districtTh: "แม่สาย",
    districtEn: "Mae Sai",
    subdistrictTh: "เวียงพางคำ",
    subdistrictEn: "Wiang Phang Kham",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57130,
    district_code: 5709,
    province_code: 45,
    name: "แม่สาย เวียงพางคำ เชียงราย 57130",
    name_th: "แม่สาย เวียงพางคำ เชียงราย 57130",
    name_en: "Mae Sai, Wiang Phang Kham, Chiang Rai, 57130",
  },
  {
    id: 570908,
    districtTh: "แม่สาย",
    districtEn: "Mae Sai",
    subdistrictTh: "บ้านด้าย",
    subdistrictEn: "Ban Dai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57220,
    district_code: 5709,
    province_code: 45,
    name: "แม่สาย บ้านด้าย เชียงราย 57220",
    name_th: "แม่สาย บ้านด้าย เชียงราย 57220",
    name_en: "Mae Sai, Ban Dai, Chiang Rai, 57220",
  },
  {
    id: 570909,
    districtTh: "แม่สาย",
    districtEn: "Mae Sai",
    subdistrictTh: "โป่งงาม",
    subdistrictEn: "Pong Ngam",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57130,
    district_code: 5709,
    province_code: 45,
    name: "แม่สาย โป่งงาม เชียงราย 57130",
    name_th: "แม่สาย โป่งงาม เชียงราย 57130",
    name_en: "Mae Sai, Pong Ngam, Chiang Rai, 57130",
  },
  {
    id: 571001,
    districtTh: "แม่สรวย",
    districtEn: "Mae Suai",
    subdistrictTh: "แม่สรวย",
    subdistrictEn: "Mae Suai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57180,
    district_code: 5710,
    province_code: 45,
    name: "แม่สรวย แม่สรวย เชียงราย 57180",
    name_th: "แม่สรวย แม่สรวย เชียงราย 57180",
    name_en: "Mae Suai, Mae Suai, Chiang Rai, 57180",
  },
  {
    id: 571002,
    districtTh: "แม่สรวย",
    districtEn: "Mae Suai",
    subdistrictTh: "ป่าแดด",
    subdistrictEn: "Pa Daet",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57180,
    district_code: 5710,
    province_code: 45,
    name: "แม่สรวย ป่าแดด เชียงราย 57180",
    name_th: "แม่สรวย ป่าแดด เชียงราย 57180",
    name_en: "Mae Suai, Pa Daet, Chiang Rai, 57180",
  },
  {
    id: 571003,
    districtTh: "แม่สรวย",
    districtEn: "Mae Suai",
    subdistrictTh: "แม่พริก",
    subdistrictEn: "Mae Phrik",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57180,
    district_code: 5710,
    province_code: 45,
    name: "แม่สรวย แม่พริก เชียงราย 57180",
    name_th: "แม่สรวย แม่พริก เชียงราย 57180",
    name_en: "Mae Suai, Mae Phrik, Chiang Rai, 57180",
  },
  {
    id: 571004,
    districtTh: "แม่สรวย",
    districtEn: "Mae Suai",
    subdistrictTh: "ศรีถ้อย",
    subdistrictEn: "Si Thoi",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57180,
    district_code: 5710,
    province_code: 45,
    name: "แม่สรวย ศรีถ้อย เชียงราย 57180",
    name_th: "แม่สรวย ศรีถ้อย เชียงราย 57180",
    name_en: "Mae Suai, Si Thoi, Chiang Rai, 57180",
  },
  {
    id: 571005,
    districtTh: "แม่สรวย",
    districtEn: "Mae Suai",
    subdistrictTh: "ท่าก๊อ",
    subdistrictEn: "Tha Ko",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57180,
    district_code: 5710,
    province_code: 45,
    name: "แม่สรวย ท่าก๊อ เชียงราย 57180",
    name_th: "แม่สรวย ท่าก๊อ เชียงราย 57180",
    name_en: "Mae Suai, Tha Ko, Chiang Rai, 57180",
  },
  {
    id: 571006,
    districtTh: "แม่สรวย",
    districtEn: "Mae Suai",
    subdistrictTh: "วาวี",
    subdistrictEn: "Wawi",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57180,
    district_code: 5710,
    province_code: 45,
    name: "แม่สรวย วาวี เชียงราย 57180",
    name_th: "แม่สรวย วาวี เชียงราย 57180",
    name_en: "Mae Suai, Wawi, Chiang Rai, 57180",
  },
  {
    id: 571007,
    districtTh: "แม่สรวย",
    districtEn: "Mae Suai",
    subdistrictTh: "เจดีย์หลวง",
    subdistrictEn: "Chedi Luang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57180,
    district_code: 5710,
    province_code: 45,
    name: "แม่สรวย เจดีย์หลวง เชียงราย 57180",
    name_th: "แม่สรวย เจดีย์หลวง เชียงราย 57180",
    name_en: "Mae Suai, Chedi Luang, Chiang Rai, 57180",
  },
  {
    id: 571101,
    districtTh: "เวียงป่าเป้า",
    districtEn: "Wiang Pa Pao",
    subdistrictTh: "สันสลี",
    subdistrictEn: "San Sali",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57170,
    district_code: 5711,
    province_code: 45,
    name: "เวียงป่าเป้า สันสลี เชียงราย 57170",
    name_th: "เวียงป่าเป้า สันสลี เชียงราย 57170",
    name_en: "Wiang Pa Pao, San Sali, Chiang Rai, 57170",
  },
  {
    id: 571102,
    districtTh: "เวียงป่าเป้า",
    districtEn: "Wiang Pa Pao",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57170,
    district_code: 5711,
    province_code: 45,
    name: "เวียงป่าเป้า เวียง เชียงราย 57170",
    name_th: "เวียงป่าเป้า เวียง เชียงราย 57170",
    name_en: "Wiang Pa Pao, Wiang, Chiang Rai, 57170",
  },
  {
    id: 571103,
    districtTh: "เวียงป่าเป้า",
    districtEn: "Wiang Pa Pao",
    subdistrictTh: "บ้านโป่ง",
    subdistrictEn: "Ban Pong",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57170,
    district_code: 5711,
    province_code: 45,
    name: "เวียงป่าเป้า บ้านโป่ง เชียงราย 57170",
    name_th: "เวียงป่าเป้า บ้านโป่ง เชียงราย 57170",
    name_en: "Wiang Pa Pao, Ban Pong, Chiang Rai, 57170",
  },
  {
    id: 571104,
    districtTh: "เวียงป่าเป้า",
    districtEn: "Wiang Pa Pao",
    subdistrictTh: "ป่างิ้ว",
    subdistrictEn: "Pa Ngio",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57170,
    district_code: 5711,
    province_code: 45,
    name: "เวียงป่าเป้า ป่างิ้ว เชียงราย 57170",
    name_th: "เวียงป่าเป้า ป่างิ้ว เชียงราย 57170",
    name_en: "Wiang Pa Pao, Pa Ngio, Chiang Rai, 57170",
  },
  {
    id: 571105,
    districtTh: "เวียงป่าเป้า",
    districtEn: "Wiang Pa Pao",
    subdistrictTh: "เวียงกาหลง",
    subdistrictEn: "Wiang Kalong",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57260,
    district_code: 5711,
    province_code: 45,
    name: "เวียงป่าเป้า เวียงกาหลง เชียงราย 57260",
    name_th: "เวียงป่าเป้า เวียงกาหลง เชียงราย 57260",
    name_en: "Wiang Pa Pao, Wiang Kalong, Chiang Rai, 57260",
  },
  {
    id: 571106,
    districtTh: "เวียงป่าเป้า",
    districtEn: "Wiang Pa Pao",
    subdistrictTh: "แม่เจดีย์",
    subdistrictEn: "Mae Chedi",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57260,
    district_code: 5711,
    province_code: 45,
    name: "เวียงป่าเป้า แม่เจดีย์ เชียงราย 57260",
    name_th: "เวียงป่าเป้า แม่เจดีย์ เชียงราย 57260",
    name_en: "Wiang Pa Pao, Mae Chedi, Chiang Rai, 57260",
  },
  {
    id: 571107,
    districtTh: "เวียงป่าเป้า",
    districtEn: "Wiang Pa Pao",
    subdistrictTh: "แม่เจดีย์ใหม่",
    subdistrictEn: "Mae Chedi Mai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57260,
    district_code: 5711,
    province_code: 45,
    name: "เวียงป่าเป้า แม่เจดีย์ใหม่ เชียงราย 57260",
    name_th: "เวียงป่าเป้า แม่เจดีย์ใหม่ เชียงราย 57260",
    name_en: "Wiang Pa Pao, Mae Chedi Mai, Chiang Rai, 57260",
  },
  {
    id: 571201,
    districtTh: "พญาเม็งราย",
    districtEn: "Phaya Mengrai",
    subdistrictTh: "แม่เปา",
    subdistrictEn: "Mae Pao",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57290,
    district_code: 5712,
    province_code: 45,
    name: "พญาเม็งราย แม่เปา เชียงราย 57290",
    name_th: "พญาเม็งราย แม่เปา เชียงราย 57290",
    name_en: "Phaya Mengrai, Mae Pao, Chiang Rai, 57290",
  },
  {
    id: 571202,
    districtTh: "พญาเม็งราย",
    districtEn: "Phaya Mengrai",
    subdistrictTh: "แม่ต๋ำ",
    subdistrictEn: "Mae Tam",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57290,
    district_code: 5712,
    province_code: 45,
    name: "พญาเม็งราย แม่ต๋ำ เชียงราย 57290",
    name_th: "พญาเม็งราย แม่ต๋ำ เชียงราย 57290",
    name_en: "Phaya Mengrai, Mae Tam, Chiang Rai, 57290",
  },
  {
    id: 571203,
    districtTh: "พญาเม็งราย",
    districtEn: "Phaya Mengrai",
    subdistrictTh: "ไม้ยา",
    subdistrictEn: "Mai Ya",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57290,
    district_code: 5712,
    province_code: 45,
    name: "พญาเม็งราย ไม้ยา เชียงราย 57290",
    name_th: "พญาเม็งราย ไม้ยา เชียงราย 57290",
    name_en: "Phaya Mengrai, Mai Ya, Chiang Rai, 57290",
  },
  {
    id: 571204,
    districtTh: "พญาเม็งราย",
    districtEn: "Phaya Mengrai",
    subdistrictTh: "เม็งราย",
    subdistrictEn: "Mengrai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57290,
    district_code: 5712,
    province_code: 45,
    name: "พญาเม็งราย เม็งราย เชียงราย 57290",
    name_th: "พญาเม็งราย เม็งราย เชียงราย 57290",
    name_en: "Phaya Mengrai, Mengrai, Chiang Rai, 57290",
  },
  {
    id: 571205,
    districtTh: "พญาเม็งราย",
    districtEn: "Phaya Mengrai",
    subdistrictTh: "ตาดควัน",
    subdistrictEn: "Tat Khwan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57290,
    district_code: 5712,
    province_code: 45,
    name: "พญาเม็งราย ตาดควัน เชียงราย 57290",
    name_th: "พญาเม็งราย ตาดควัน เชียงราย 57290",
    name_en: "Phaya Mengrai, Tat Khwan, Chiang Rai, 57290",
  },
  {
    id: 571301,
    districtTh: "เวียงแก่น",
    districtEn: "Wiang Kaen",
    subdistrictTh: "ม่วงยาย",
    subdistrictEn: "Muang Yai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57310,
    district_code: 5713,
    province_code: 45,
    name: "เวียงแก่น ม่วงยาย เชียงราย 57310",
    name_th: "เวียงแก่น ม่วงยาย เชียงราย 57310",
    name_en: "Wiang Kaen, Muang Yai, Chiang Rai, 57310",
  },
  {
    id: 571302,
    districtTh: "เวียงแก่น",
    districtEn: "Wiang Kaen",
    subdistrictTh: "ปอ",
    subdistrictEn: "Por",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57310,
    district_code: 5713,
    province_code: 45,
    name: "เวียงแก่น ปอ เชียงราย 57310",
    name_th: "เวียงแก่น ปอ เชียงราย 57310",
    name_en: "Wiang Kaen, Por, Chiang Rai, 57310",
  },
  {
    id: 571303,
    districtTh: "เวียงแก่น",
    districtEn: "Wiang Kaen",
    subdistrictTh: "หล่ายงาว",
    subdistrictEn: "Lai Ngao",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57310,
    district_code: 5713,
    province_code: 45,
    name: "เวียงแก่น หล่ายงาว เชียงราย 57310",
    name_th: "เวียงแก่น หล่ายงาว เชียงราย 57310",
    name_en: "Wiang Kaen, Lai Ngao, Chiang Rai, 57310",
  },
  {
    id: 571304,
    districtTh: "เวียงแก่น",
    districtEn: "Wiang Kaen",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57310,
    district_code: 5713,
    province_code: 45,
    name: "เวียงแก่น ท่าข้าม เชียงราย 57310",
    name_th: "เวียงแก่น ท่าข้าม เชียงราย 57310",
    name_en: "Wiang Kaen, Tha Kham, Chiang Rai, 57310",
  },
  {
    id: 571401,
    districtTh: "ขุนตาล",
    districtEn: "Khun Tan",
    subdistrictTh: "ต้า",
    subdistrictEn: "Ta",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57340,
    district_code: 5714,
    province_code: 45,
    name: "ขุนตาล ต้า เชียงราย 57340",
    name_th: "ขุนตาล ต้า เชียงราย 57340",
    name_en: "Khun Tan, Ta, Chiang Rai, 57340",
  },
  {
    id: 571402,
    districtTh: "ขุนตาล",
    districtEn: "Khun Tan",
    subdistrictTh: "ป่าตาล",
    subdistrictEn: "Pa Tan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57340,
    district_code: 5714,
    province_code: 45,
    name: "ขุนตาล ป่าตาล เชียงราย 57340",
    name_th: "ขุนตาล ป่าตาล เชียงราย 57340",
    name_en: "Khun Tan, Pa Tan, Chiang Rai, 57340",
  },
  {
    id: 571403,
    districtTh: "ขุนตาล",
    districtEn: "Khun Tan",
    subdistrictTh: "ยางฮอม",
    subdistrictEn: "Yang Hom",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57340,
    district_code: 5714,
    province_code: 45,
    name: "ขุนตาล ยางฮอม เชียงราย 57340",
    name_th: "ขุนตาล ยางฮอม เชียงราย 57340",
    name_en: "Khun Tan, Yang Hom, Chiang Rai, 57340",
  },
  {
    id: 571501,
    districtTh: "แม่ฟ้าหลวง",
    districtEn: "Mae Fa Luang",
    subdistrictTh: "เทอดไทย",
    subdistrictEn: "Thoet Thai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57240,
    district_code: 5715,
    province_code: 45,
    name: "แม่ฟ้าหลวง เทอดไทย เชียงราย 57240",
    name_th: "แม่ฟ้าหลวง เทอดไทย เชียงราย 57240",
    name_en: "Mae Fa Luang, Thoet Thai, Chiang Rai, 57240",
  },
  {
    id: 571502,
    districtTh: "แม่ฟ้าหลวง",
    districtEn: "Mae Fa Luang",
    subdistrictTh: "แม่สลองใน",
    subdistrictEn: "Mae Salong Nai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5715,
    province_code: 45,
    name: "แม่ฟ้าหลวง แม่สลองใน เชียงราย 57110",
    name_th: "แม่ฟ้าหลวง แม่สลองใน เชียงราย 57110",
    name_en: "Mae Fa Luang, Mae Salong Nai, Chiang Rai, 57110",
  },
  {
    id: 571503,
    districtTh: "แม่ฟ้าหลวง",
    districtEn: "Mae Fa Luang",
    subdistrictTh: "แม่สลองนอก",
    subdistrictEn: "Mae Salong Nok",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5715,
    province_code: 45,
    name: "แม่ฟ้าหลวง แม่สลองนอก เชียงราย 57110",
    name_th: "แม่ฟ้าหลวง แม่สลองนอก เชียงราย 57110",
    name_en: "Mae Fa Luang, Mae Salong Nok, Chiang Rai, 57110",
  },
  {
    id: 571504,
    districtTh: "แม่ฟ้าหลวง",
    districtEn: "Mae Fa Luang",
    subdistrictTh: "แม่ฟ้าหลวง",
    subdistrictEn: "Mae Fa Luang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57240,
    district_code: 5715,
    province_code: 45,
    name: "แม่ฟ้าหลวง แม่ฟ้าหลวง เชียงราย 57240",
    name_th: "แม่ฟ้าหลวง แม่ฟ้าหลวง เชียงราย 57240",
    name_en: "Mae Fa Luang, Mae Fa Luang, Chiang Rai, 57240",
  },
  {
    id: 571601,
    districtTh: "แม่ลาว",
    districtEn: "Mae Lao",
    subdistrictTh: "ดงมะดะ",
    subdistrictEn: "Dong Mada",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57250,
    district_code: 5716,
    province_code: 45,
    name: "แม่ลาว ดงมะดะ เชียงราย 57250",
    name_th: "แม่ลาว ดงมะดะ เชียงราย 57250",
    name_en: "Mae Lao, Dong Mada, Chiang Rai, 57250",
  },
  {
    id: 571602,
    districtTh: "แม่ลาว",
    districtEn: "Mae Lao",
    subdistrictTh: "จอมหมอกแก้ว",
    subdistrictEn: "Chom Mok Kaeo",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57250,
    district_code: 5716,
    province_code: 45,
    name: "แม่ลาว จอมหมอกแก้ว เชียงราย 57250",
    name_th: "แม่ลาว จอมหมอกแก้ว เชียงราย 57250",
    name_en: "Mae Lao, Chom Mok Kaeo, Chiang Rai, 57250",
  },
  {
    id: 571603,
    districtTh: "แม่ลาว",
    districtEn: "Mae Lao",
    subdistrictTh: "บัวสลี",
    subdistrictEn: "Bua Sali",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57250,
    district_code: 5716,
    province_code: 45,
    name: "แม่ลาว บัวสลี เชียงราย 57250",
    name_th: "แม่ลาว บัวสลี เชียงราย 57250",
    name_en: "Mae Lao, Bua Sali, Chiang Rai, 57250",
  },
  {
    id: 571604,
    districtTh: "แม่ลาว",
    districtEn: "Mae Lao",
    subdistrictTh: "ป่าก่อดำ",
    subdistrictEn: "Pa Ko Dam",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57250,
    district_code: 5716,
    province_code: 45,
    name: "แม่ลาว ป่าก่อดำ เชียงราย 57250",
    name_th: "แม่ลาว ป่าก่อดำ เชียงราย 57250",
    name_en: "Mae Lao, Pa Ko Dam, Chiang Rai, 57250",
  },
  {
    id: 571605,
    districtTh: "แม่ลาว",
    districtEn: "Mae Lao",
    subdistrictTh: "โป่งแพร่",
    subdistrictEn: "Pong Phrae",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57000,
    district_code: 5716,
    province_code: 45,
    name: "แม่ลาว โป่งแพร่ เชียงราย 57000",
    name_th: "แม่ลาว โป่งแพร่ เชียงราย 57000",
    name_en: "Mae Lao, Pong Phrae, Chiang Rai, 57000",
  },
  {
    id: 571701,
    districtTh: "เวียงเชียงรุ้ง",
    districtEn: "Wiang Chiang Rung",
    subdistrictTh: "ทุ่งก่อ",
    subdistrictEn: "Thung Ko",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57210,
    district_code: 5717,
    province_code: 45,
    name: "เวียงเชียงรุ้ง ทุ่งก่อ เชียงราย 57210",
    name_th: "เวียงเชียงรุ้ง ทุ่งก่อ เชียงราย 57210",
    name_en: "Wiang Chiang Rung, Thung Ko, Chiang Rai, 57210",
  },
  {
    id: 571702,
    districtTh: "เวียงเชียงรุ้ง",
    districtEn: "Wiang Chiang Rung",
    subdistrictTh: "ดงมหาวัน",
    subdistrictEn: "Dong Maha Wan",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57210,
    district_code: 5717,
    province_code: 45,
    name: "เวียงเชียงรุ้ง ดงมหาวัน เชียงราย 57210",
    name_th: "เวียงเชียงรุ้ง ดงมหาวัน เชียงราย 57210",
    name_en: "Wiang Chiang Rung, Dong Maha Wan, Chiang Rai, 57210",
  },
  {
    id: 571703,
    districtTh: "เวียงเชียงรุ้ง",
    districtEn: "Wiang Chiang Rung",
    subdistrictTh: "ป่าซาง",
    subdistrictEn: "Pa Sang",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57210,
    district_code: 5717,
    province_code: 45,
    name: "เวียงเชียงรุ้ง ป่าซาง เชียงราย 57210",
    name_th: "เวียงเชียงรุ้ง ป่าซาง เชียงราย 57210",
    name_en: "Wiang Chiang Rung, Pa Sang, Chiang Rai, 57210",
  },
  {
    id: 571801,
    districtTh: "ดอยหลวง",
    districtEn: "Doi Luang",
    subdistrictTh: "ปงน้อย",
    subdistrictEn: "Pong Noi",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5718,
    province_code: 45,
    name: "ดอยหลวง ปงน้อย เชียงราย 57110",
    name_th: "ดอยหลวง ปงน้อย เชียงราย 57110",
    name_en: "Doi Luang, Pong Noi, Chiang Rai, 57110",
  },
  {
    id: 571802,
    districtTh: "ดอยหลวง",
    districtEn: "Doi Luang",
    subdistrictTh: "โชคชัย",
    subdistrictEn: "Chok Chai",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5718,
    province_code: 45,
    name: "ดอยหลวง โชคชัย เชียงราย 57110",
    name_th: "ดอยหลวง โชคชัย เชียงราย 57110",
    name_en: "Doi Luang, Chok Chai, Chiang Rai, 57110",
  },
  {
    id: 571803,
    districtTh: "ดอยหลวง",
    districtEn: "Doi Luang",
    subdistrictTh: "หนองป่าก่อ",
    subdistrictEn: "Nong Pa Ko",
    provinceTh: "เชียงราย",
    provinceEn: "Chiang Rai",
    zipcode: 57110,
    district_code: 5718,
    province_code: 45,
    name: "ดอยหลวง หนองป่าก่อ เชียงราย 57110",
    name_th: "ดอยหลวง หนองป่าก่อ เชียงราย 57110",
    name_en: "Doi Luang, Nong Pa Ko, Chiang Rai, 57110",
  },
  {
    id: 580101,
    districtTh: "เมืองแม่ฮ่องสอน",
    districtEn: "Mueang Mae Hong Son",
    subdistrictTh: "จองคำ",
    subdistrictEn: "Chong Kham",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58000,
    district_code: 5801,
    province_code: 46,
    name: "เมืองแม่ฮ่องสอน จองคำ แม่ฮ่องสอน 58000",
    name_th: "เมืองแม่ฮ่องสอน จองคำ แม่ฮ่องสอน 58000",
    name_en: "Mueang Mae Hong Son, Chong Kham, Mae Hong Son, 58000",
  },
  {
    id: 580102,
    districtTh: "เมืองแม่ฮ่องสอน",
    districtEn: "Mueang Mae Hong Son",
    subdistrictTh: "ห้วยโป่ง",
    subdistrictEn: "Huai Pong",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58000,
    district_code: 5801,
    province_code: 46,
    name: "เมืองแม่ฮ่องสอน ห้วยโป่ง แม่ฮ่องสอน 58000",
    name_th: "เมืองแม่ฮ่องสอน ห้วยโป่ง แม่ฮ่องสอน 58000",
    name_en: "Mueang Mae Hong Son, Huai Pong, Mae Hong Son, 58000",
  },
  {
    id: 580103,
    districtTh: "เมืองแม่ฮ่องสอน",
    districtEn: "Mueang Mae Hong Son",
    subdistrictTh: "ผาบ่อง",
    subdistrictEn: "Pha Bong",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58000,
    district_code: 5801,
    province_code: 46,
    name: "เมืองแม่ฮ่องสอน ผาบ่อง แม่ฮ่องสอน 58000",
    name_th: "เมืองแม่ฮ่องสอน ผาบ่อง แม่ฮ่องสอน 58000",
    name_en: "Mueang Mae Hong Son, Pha Bong, Mae Hong Son, 58000",
  },
  {
    id: 580104,
    districtTh: "เมืองแม่ฮ่องสอน",
    districtEn: "Mueang Mae Hong Son",
    subdistrictTh: "ปางหมู",
    subdistrictEn: "Pang Mu",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58000,
    district_code: 5801,
    province_code: 46,
    name: "เมืองแม่ฮ่องสอน ปางหมู แม่ฮ่องสอน 58000",
    name_th: "เมืองแม่ฮ่องสอน ปางหมู แม่ฮ่องสอน 58000",
    name_en: "Mueang Mae Hong Son, Pang Mu, Mae Hong Son, 58000",
  },
  {
    id: 580105,
    districtTh: "เมืองแม่ฮ่องสอน",
    districtEn: "Mueang Mae Hong Son",
    subdistrictTh: "หมอกจำแป่",
    subdistrictEn: "Mok Champae",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58000,
    district_code: 5801,
    province_code: 46,
    name: "เมืองแม่ฮ่องสอน หมอกจำแป่ แม่ฮ่องสอน 58000",
    name_th: "เมืองแม่ฮ่องสอน หมอกจำแป่ แม่ฮ่องสอน 58000",
    name_en: "Mueang Mae Hong Son, Mok Champae, Mae Hong Son, 58000",
  },
  {
    id: 580106,
    districtTh: "เมืองแม่ฮ่องสอน",
    districtEn: "Mueang Mae Hong Son",
    subdistrictTh: "ห้วยผา",
    subdistrictEn: "Huai Pha",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58000,
    district_code: 5801,
    province_code: 46,
    name: "เมืองแม่ฮ่องสอน ห้วยผา แม่ฮ่องสอน 58000",
    name_th: "เมืองแม่ฮ่องสอน ห้วยผา แม่ฮ่องสอน 58000",
    name_en: "Mueang Mae Hong Son, Huai Pha, Mae Hong Son, 58000",
  },
  {
    id: 580109,
    districtTh: "เมืองแม่ฮ่องสอน",
    districtEn: "Mueang Mae Hong Son",
    subdistrictTh: "ห้วยปูลิง",
    subdistrictEn: "Huai Pu Ling",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58000,
    district_code: 5801,
    province_code: 46,
    name: "เมืองแม่ฮ่องสอน ห้วยปูลิง แม่ฮ่องสอน 58000",
    name_th: "เมืองแม่ฮ่องสอน ห้วยปูลิง แม่ฮ่องสอน 58000",
    name_en: "Mueang Mae Hong Son, Huai Pu Ling, Mae Hong Son, 58000",
  },
  {
    id: 580201,
    districtTh: "ขุนยวม",
    districtEn: "Khun Yuam",
    subdistrictTh: "ขุนยวม",
    subdistrictEn: "Khun Yuam",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58140,
    district_code: 5802,
    province_code: 46,
    name: "ขุนยวม ขุนยวม แม่ฮ่องสอน 58140",
    name_th: "ขุนยวม ขุนยวม แม่ฮ่องสอน 58140",
    name_en: "Khun Yuam, Khun Yuam, Mae Hong Son, 58140",
  },
  {
    id: 580202,
    districtTh: "ขุนยวม",
    districtEn: "Khun Yuam",
    subdistrictTh: "แม่เงา",
    subdistrictEn: "Mae Ngao",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58140,
    district_code: 5802,
    province_code: 46,
    name: "ขุนยวม แม่เงา แม่ฮ่องสอน 58140",
    name_th: "ขุนยวม แม่เงา แม่ฮ่องสอน 58140",
    name_en: "Khun Yuam, Mae Ngao, Mae Hong Son, 58140",
  },
  {
    id: 580203,
    districtTh: "ขุนยวม",
    districtEn: "Khun Yuam",
    subdistrictTh: "เมืองปอน",
    subdistrictEn: "Mueang Pon",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58140,
    district_code: 5802,
    province_code: 46,
    name: "ขุนยวม เมืองปอน แม่ฮ่องสอน 58140",
    name_th: "ขุนยวม เมืองปอน แม่ฮ่องสอน 58140",
    name_en: "Khun Yuam, Mueang Pon, Mae Hong Son, 58140",
  },
  {
    id: 580204,
    districtTh: "ขุนยวม",
    districtEn: "Khun Yuam",
    subdistrictTh: "แม่ยวมน้อย",
    subdistrictEn: "Mae Yuam Noi",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58140,
    district_code: 5802,
    province_code: 46,
    name: "ขุนยวม แม่ยวมน้อย แม่ฮ่องสอน 58140",
    name_th: "ขุนยวม แม่ยวมน้อย แม่ฮ่องสอน 58140",
    name_en: "Khun Yuam, Mae Yuam Noi, Mae Hong Son, 58140",
  },
  {
    id: 580205,
    districtTh: "ขุนยวม",
    districtEn: "Khun Yuam",
    subdistrictTh: "แม่กิ๊",
    subdistrictEn: "Mae Ki",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58140,
    district_code: 5802,
    province_code: 46,
    name: "ขุนยวม แม่กิ๊ แม่ฮ่องสอน 58140",
    name_th: "ขุนยวม แม่กิ๊ แม่ฮ่องสอน 58140",
    name_en: "Khun Yuam, Mae Ki, Mae Hong Son, 58140",
  },
  {
    id: 580206,
    districtTh: "ขุนยวม",
    districtEn: "Khun Yuam",
    subdistrictTh: "แม่อูคอ",
    subdistrictEn: "Mae Uo Kor",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58140,
    district_code: 5802,
    province_code: 46,
    name: "ขุนยวม แม่อูคอ แม่ฮ่องสอน 58140",
    name_th: "ขุนยวม แม่อูคอ แม่ฮ่องสอน 58140",
    name_en: "Khun Yuam, Mae Uo Kor, Mae Hong Son, 58140",
  },
  {
    id: 580301,
    districtTh: "ปาย",
    districtEn: "Pai",
    subdistrictTh: "เวียงใต้",
    subdistrictEn: "Wiang Tai",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58130,
    district_code: 5803,
    province_code: 46,
    name: "ปาย เวียงใต้ แม่ฮ่องสอน 58130",
    name_th: "ปาย เวียงใต้ แม่ฮ่องสอน 58130",
    name_en: "Pai, Wiang Tai, Mae Hong Son, 58130",
  },
  {
    id: 580302,
    districtTh: "ปาย",
    districtEn: "Pai",
    subdistrictTh: "เวียงเหนือ",
    subdistrictEn: "Wiang Nuea",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58130,
    district_code: 5803,
    province_code: 46,
    name: "ปาย เวียงเหนือ แม่ฮ่องสอน 58130",
    name_th: "ปาย เวียงเหนือ แม่ฮ่องสอน 58130",
    name_en: "Pai, Wiang Nuea, Mae Hong Son, 58130",
  },
  {
    id: 580303,
    districtTh: "ปาย",
    districtEn: "Pai",
    subdistrictTh: "แม่นาเติง",
    subdistrictEn: "Mae Na Toeng",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58130,
    district_code: 5803,
    province_code: 46,
    name: "ปาย แม่นาเติง แม่ฮ่องสอน 58130",
    name_th: "ปาย แม่นาเติง แม่ฮ่องสอน 58130",
    name_en: "Pai, Mae Na Toeng, Mae Hong Son, 58130",
  },
  {
    id: 580304,
    districtTh: "ปาย",
    districtEn: "Pai",
    subdistrictTh: "แม่ฮี้",
    subdistrictEn: "Mae Hi",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58130,
    district_code: 5803,
    province_code: 46,
    name: "ปาย แม่ฮี้ แม่ฮ่องสอน 58130",
    name_th: "ปาย แม่ฮี้ แม่ฮ่องสอน 58130",
    name_en: "Pai, Mae Hi, Mae Hong Son, 58130",
  },
  {
    id: 580305,
    districtTh: "ปาย",
    districtEn: "Pai",
    subdistrictTh: "ทุ่งยาว",
    subdistrictEn: "Thung Yao",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58130,
    district_code: 5803,
    province_code: 46,
    name: "ปาย ทุ่งยาว แม่ฮ่องสอน 58130",
    name_th: "ปาย ทุ่งยาว แม่ฮ่องสอน 58130",
    name_en: "Pai, Thung Yao, Mae Hong Son, 58130",
  },
  {
    id: 580306,
    districtTh: "ปาย",
    districtEn: "Pai",
    subdistrictTh: "เมืองแปง",
    subdistrictEn: "Mueang Paeng",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58130,
    district_code: 5803,
    province_code: 46,
    name: "ปาย เมืองแปง แม่ฮ่องสอน 58130",
    name_th: "ปาย เมืองแปง แม่ฮ่องสอน 58130",
    name_en: "Pai, Mueang Paeng, Mae Hong Son, 58130",
  },
  {
    id: 580307,
    districtTh: "ปาย",
    districtEn: "Pai",
    subdistrictTh: "โป่งสา",
    subdistrictEn: "Pong Sa",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58130,
    district_code: 5803,
    province_code: 46,
    name: "ปาย โป่งสา แม่ฮ่องสอน 58130",
    name_th: "ปาย โป่งสา แม่ฮ่องสอน 58130",
    name_en: "Pai, Pong Sa, Mae Hong Son, 58130",
  },
  {
    id: 580401,
    districtTh: "แม่สะเรียง",
    districtEn: "Mae Sariang",
    subdistrictTh: "บ้านกาศ",
    subdistrictEn: "Ban Kat",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5804,
    province_code: 46,
    name: "แม่สะเรียง บ้านกาศ แม่ฮ่องสอน 58110",
    name_th: "แม่สะเรียง บ้านกาศ แม่ฮ่องสอน 58110",
    name_en: "Mae Sariang, Ban Kat, Mae Hong Son, 58110",
  },
  {
    id: 580402,
    districtTh: "แม่สะเรียง",
    districtEn: "Mae Sariang",
    subdistrictTh: "แม่สะเรียง",
    subdistrictEn: "Mae Sariang",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5804,
    province_code: 46,
    name: "แม่สะเรียง แม่สะเรียง แม่ฮ่องสอน 58110",
    name_th: "แม่สะเรียง แม่สะเรียง แม่ฮ่องสอน 58110",
    name_en: "Mae Sariang, Mae Sariang, Mae Hong Son, 58110",
  },
  {
    id: 580403,
    districtTh: "แม่สะเรียง",
    districtEn: "Mae Sariang",
    subdistrictTh: "แม่คง",
    subdistrictEn: "Mae Khong",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5804,
    province_code: 46,
    name: "แม่สะเรียง แม่คง แม่ฮ่องสอน 58110",
    name_th: "แม่สะเรียง แม่คง แม่ฮ่องสอน 58110",
    name_en: "Mae Sariang, Mae Khong, Mae Hong Son, 58110",
  },
  {
    id: 580404,
    districtTh: "แม่สะเรียง",
    districtEn: "Mae Sariang",
    subdistrictTh: "แม่เหาะ",
    subdistrictEn: "Mae Ho",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5804,
    province_code: 46,
    name: "แม่สะเรียง แม่เหาะ แม่ฮ่องสอน 58110",
    name_th: "แม่สะเรียง แม่เหาะ แม่ฮ่องสอน 58110",
    name_en: "Mae Sariang, Mae Ho, Mae Hong Son, 58110",
  },
  {
    id: 580405,
    districtTh: "แม่สะเรียง",
    districtEn: "Mae Sariang",
    subdistrictTh: "แม่ยวม",
    subdistrictEn: "Mae Yuam",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5804,
    province_code: 46,
    name: "แม่สะเรียง แม่ยวม แม่ฮ่องสอน 58110",
    name_th: "แม่สะเรียง แม่ยวม แม่ฮ่องสอน 58110",
    name_en: "Mae Sariang, Mae Yuam, Mae Hong Son, 58110",
  },
  {
    id: 580406,
    districtTh: "แม่สะเรียง",
    districtEn: "Mae Sariang",
    subdistrictTh: "เสาหิน",
    subdistrictEn: "Sao Hin",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5804,
    province_code: 46,
    name: "แม่สะเรียง เสาหิน แม่ฮ่องสอน 58110",
    name_th: "แม่สะเรียง เสาหิน แม่ฮ่องสอน 58110",
    name_en: "Mae Sariang, Sao Hin, Mae Hong Son, 58110",
  },
  {
    id: 580408,
    districtTh: "แม่สะเรียง",
    districtEn: "Mae Sariang",
    subdistrictTh: "ป่าแป๋",
    subdistrictEn: "Pa Pae",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5804,
    province_code: 46,
    name: "แม่สะเรียง ป่าแป๋ แม่ฮ่องสอน 58110",
    name_th: "แม่สะเรียง ป่าแป๋ แม่ฮ่องสอน 58110",
    name_en: "Mae Sariang, Pa Pae, Mae Hong Son, 58110",
  },
  {
    id: 580501,
    districtTh: "แม่ลาน้อย",
    districtEn: "Mae La Noi",
    subdistrictTh: "แม่ลาน้อย",
    subdistrictEn: "Mae La Noi",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58120,
    district_code: 5805,
    province_code: 46,
    name: "แม่ลาน้อย แม่ลาน้อย แม่ฮ่องสอน 58120",
    name_th: "แม่ลาน้อย แม่ลาน้อย แม่ฮ่องสอน 58120",
    name_en: "Mae La Noi, Mae La Noi, Mae Hong Son, 58120",
  },
  {
    id: 580502,
    districtTh: "แม่ลาน้อย",
    districtEn: "Mae La Noi",
    subdistrictTh: "แม่ลาหลวง",
    subdistrictEn: "Mae La Luang",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58120,
    district_code: 5805,
    province_code: 46,
    name: "แม่ลาน้อย แม่ลาหลวง แม่ฮ่องสอน 58120",
    name_th: "แม่ลาน้อย แม่ลาหลวง แม่ฮ่องสอน 58120",
    name_en: "Mae La Noi, Mae La Luang, Mae Hong Son, 58120",
  },
  {
    id: 580503,
    districtTh: "แม่ลาน้อย",
    districtEn: "Mae La Noi",
    subdistrictTh: "ท่าผาปุ้ม",
    subdistrictEn: "Tha Pha Pum",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58120,
    district_code: 5805,
    province_code: 46,
    name: "แม่ลาน้อย ท่าผาปุ้ม แม่ฮ่องสอน 58120",
    name_th: "แม่ลาน้อย ท่าผาปุ้ม แม่ฮ่องสอน 58120",
    name_en: "Mae La Noi, Tha Pha Pum, Mae Hong Son, 58120",
  },
  {
    id: 580504,
    districtTh: "แม่ลาน้อย",
    districtEn: "Mae La Noi",
    subdistrictTh: "แม่โถ",
    subdistrictEn: "Mae Tho",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58120,
    district_code: 5805,
    province_code: 46,
    name: "แม่ลาน้อย แม่โถ แม่ฮ่องสอน 58120",
    name_th: "แม่ลาน้อย แม่โถ แม่ฮ่องสอน 58120",
    name_en: "Mae La Noi, Mae Tho, Mae Hong Son, 58120",
  },
  {
    id: 580505,
    districtTh: "แม่ลาน้อย",
    districtEn: "Mae La Noi",
    subdistrictTh: "ห้วยห้อม",
    subdistrictEn: "Huai Hom",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58120,
    district_code: 5805,
    province_code: 46,
    name: "แม่ลาน้อย ห้วยห้อม แม่ฮ่องสอน 58120",
    name_th: "แม่ลาน้อย ห้วยห้อม แม่ฮ่องสอน 58120",
    name_en: "Mae La Noi, Huai Hom, Mae Hong Son, 58120",
  },
  {
    id: 580506,
    districtTh: "แม่ลาน้อย",
    districtEn: "Mae La Noi",
    subdistrictTh: "แม่นาจาง",
    subdistrictEn: "Mae Na Chang",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58120,
    district_code: 5805,
    province_code: 46,
    name: "แม่ลาน้อย แม่นาจาง แม่ฮ่องสอน 58120",
    name_th: "แม่ลาน้อย แม่นาจาง แม่ฮ่องสอน 58120",
    name_en: "Mae La Noi, Mae Na Chang, Mae Hong Son, 58120",
  },
  {
    id: 580507,
    districtTh: "แม่ลาน้อย",
    districtEn: "Mae La Noi",
    subdistrictTh: "สันติคีรี",
    subdistrictEn: "Santi Khiri",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58120,
    district_code: 5805,
    province_code: 46,
    name: "แม่ลาน้อย สันติคีรี แม่ฮ่องสอน 58120",
    name_th: "แม่ลาน้อย สันติคีรี แม่ฮ่องสอน 58120",
    name_en: "Mae La Noi, Santi Khiri, Mae Hong Son, 58120",
  },
  {
    id: 580508,
    districtTh: "แม่ลาน้อย",
    districtEn: "Mae La Noi",
    subdistrictTh: "ขุนแม่ลาน้อย",
    subdistrictEn: "Khun Mae La Noi",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58120,
    district_code: 5805,
    province_code: 46,
    name: "แม่ลาน้อย ขุนแม่ลาน้อย แม่ฮ่องสอน 58120",
    name_th: "แม่ลาน้อย ขุนแม่ลาน้อย แม่ฮ่องสอน 58120",
    name_en: "Mae La Noi, Khun Mae La Noi, Mae Hong Son, 58120",
  },
  {
    id: 580601,
    districtTh: "สบเมย",
    districtEn: "Sop Moei",
    subdistrictTh: "สบเมย",
    subdistrictEn: "Sop Moei",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5806,
    province_code: 46,
    name: "สบเมย สบเมย แม่ฮ่องสอน 58110",
    name_th: "สบเมย สบเมย แม่ฮ่องสอน 58110",
    name_en: "Sop Moei, Sop Moei, Mae Hong Son, 58110",
  },
  {
    id: 580602,
    districtTh: "สบเมย",
    districtEn: "Sop Moei",
    subdistrictTh: "แม่คะตวน",
    subdistrictEn: "Mae Khatuan",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5806,
    province_code: 46,
    name: "สบเมย แม่คะตวน แม่ฮ่องสอน 58110",
    name_th: "สบเมย แม่คะตวน แม่ฮ่องสอน 58110",
    name_en: "Sop Moei, Mae Khatuan, Mae Hong Son, 58110",
  },
  {
    id: 580603,
    districtTh: "สบเมย",
    districtEn: "Sop Moei",
    subdistrictTh: "กองก๋อย",
    subdistrictEn: "Kong Koi",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5806,
    province_code: 46,
    name: "สบเมย กองก๋อย แม่ฮ่องสอน 58110",
    name_th: "สบเมย กองก๋อย แม่ฮ่องสอน 58110",
    name_en: "Sop Moei, Kong Koi, Mae Hong Son, 58110",
  },
  {
    id: 580604,
    districtTh: "สบเมย",
    districtEn: "Sop Moei",
    subdistrictTh: "แม่สวด",
    subdistrictEn: "Mae Suat",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5806,
    province_code: 46,
    name: "สบเมย แม่สวด แม่ฮ่องสอน 58110",
    name_th: "สบเมย แม่สวด แม่ฮ่องสอน 58110",
    name_en: "Sop Moei, Mae Suat, Mae Hong Son, 58110",
  },
  {
    id: 580605,
    districtTh: "สบเมย",
    districtEn: "Sop Moei",
    subdistrictTh: "ป่าโปง",
    subdistrictEn: "Pa Pong",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5806,
    province_code: 46,
    name: "สบเมย ป่าโปง แม่ฮ่องสอน 58110",
    name_th: "สบเมย ป่าโปง แม่ฮ่องสอน 58110",
    name_en: "Sop Moei, Pa Pong, Mae Hong Son, 58110",
  },
  {
    id: 580606,
    districtTh: "สบเมย",
    districtEn: "Sop Moei",
    subdistrictTh: "แม่สามแลบ",
    subdistrictEn: "Mae Sam Laep",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58110,
    district_code: 5806,
    province_code: 46,
    name: "สบเมย แม่สามแลบ แม่ฮ่องสอน 58110",
    name_th: "สบเมย แม่สามแลบ แม่ฮ่องสอน 58110",
    name_en: "Sop Moei, Mae Sam Laep, Mae Hong Son, 58110",
  },
  {
    id: 580701,
    districtTh: "ปางมะผ้า",
    districtEn: "Pang Mapha",
    subdistrictTh: "สบป่อง",
    subdistrictEn: "Sop Pong",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58150,
    district_code: 5807,
    province_code: 46,
    name: "ปางมะผ้า สบป่อง แม่ฮ่องสอน 58150",
    name_th: "ปางมะผ้า สบป่อง แม่ฮ่องสอน 58150",
    name_en: "Pang Mapha, Sop Pong, Mae Hong Son, 58150",
  },
  {
    id: 580702,
    districtTh: "ปางมะผ้า",
    districtEn: "Pang Mapha",
    subdistrictTh: "ปางมะผ้า",
    subdistrictEn: "Pang Mapha",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58150,
    district_code: 5807,
    province_code: 46,
    name: "ปางมะผ้า ปางมะผ้า แม่ฮ่องสอน 58150",
    name_th: "ปางมะผ้า ปางมะผ้า แม่ฮ่องสอน 58150",
    name_en: "Pang Mapha, Pang Mapha, Mae Hong Son, 58150",
  },
  {
    id: 580703,
    districtTh: "ปางมะผ้า",
    districtEn: "Pang Mapha",
    subdistrictTh: "ถ้ำลอด",
    subdistrictEn: "Tham Lot",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58150,
    district_code: 5807,
    province_code: 46,
    name: "ปางมะผ้า ถ้ำลอด แม่ฮ่องสอน 58150",
    name_th: "ปางมะผ้า ถ้ำลอด แม่ฮ่องสอน 58150",
    name_en: "Pang Mapha, Tham Lot, Mae Hong Son, 58150",
  },
  {
    id: 580704,
    districtTh: "ปางมะผ้า",
    districtEn: "Pang Mapha",
    subdistrictTh: "นาปู่ป้อม",
    subdistrictEn: "Na Pu Pom",
    provinceTh: "แม่ฮ่องสอน",
    provinceEn: "Mae Hong Son",
    zipcode: 58150,
    district_code: 5807,
    province_code: 46,
    name: "ปางมะผ้า นาปู่ป้อม แม่ฮ่องสอน 58150",
    name_th: "ปางมะผ้า นาปู่ป้อม แม่ฮ่องสอน 58150",
    name_en: "Pang Mapha, Na Pu Pom, Mae Hong Son, 58150",
  },
  {
    id: 600101,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "ปากน้ำโพ",
    subdistrictEn: "Paknam Pho",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ ปากน้ำโพ นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ ปากน้ำโพ นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Paknam Pho, Nakhon Sawan, 60000",
  },
  {
    id: 600102,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "กลางแดด",
    subdistrictEn: "Klang Daet",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ กลางแดด นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ กลางแดด นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Klang Daet, Nakhon Sawan, 60000",
  },
  {
    id: 600103,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "เกรียงไกร",
    subdistrictEn: "Kriangkrai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ เกรียงไกร นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ เกรียงไกร นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Kriangkrai, Nakhon Sawan, 60000",
  },
  {
    id: 600104,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "แควใหญ่",
    subdistrictEn: "Khwae Yai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ แควใหญ่ นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ แควใหญ่ นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Khwae Yai, Nakhon Sawan, 60000",
  },
  {
    id: 600105,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "ตะเคียนเลื่อน",
    subdistrictEn: "Takhian Luean",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ ตะเคียนเลื่อน นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ ตะเคียนเลื่อน นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Takhian Luean, Nakhon Sawan, 60000",
  },
  {
    id: 600106,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "นครสวรรค์ตก",
    subdistrictEn: "Nakhon Sawan Tok",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ นครสวรรค์ตก นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ นครสวรรค์ตก นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Nakhon Sawan Tok, Nakhon Sawan, 60000",
  },
  {
    id: 600107,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "นครสวรรค์ออก",
    subdistrictEn: "Nakhon Sawan Ok",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ นครสวรรค์ออก นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ นครสวรรค์ออก นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Nakhon Sawan Ok, Nakhon Sawan, 60000",
  },
  {
    id: 600108,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "บางพระหลวง",
    subdistrictEn: "Bang Phra Luang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ บางพระหลวง นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ บางพระหลวง นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Bang Phra Luang, Nakhon Sawan, 60000",
  },
  {
    id: 600109,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "บางม่วง",
    subdistrictEn: "Bang Muang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ บางม่วง นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ บางม่วง นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Bang Muang, Nakhon Sawan, 60000",
  },
  {
    id: 600110,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "บ้านมะเกลือ",
    subdistrictEn: "Ban Makluea",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ บ้านมะเกลือ นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ บ้านมะเกลือ นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Ban Makluea, Nakhon Sawan, 60000",
  },
  {
    id: 600111,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "บ้านแก่ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ บ้านแก่ง นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ บ้านแก่ง นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Ban Kaeng, Nakhon Sawan, 60000",
  },
  {
    id: 600112,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "พระนอน",
    subdistrictEn: "Phra Non",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ พระนอน นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ พระนอน นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Phra Non, Nakhon Sawan, 60000",
  },
  {
    id: 600113,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "วัดไทร",
    subdistrictEn: "Wat Sai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ วัดไทร นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ วัดไทร นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Wat Sai, Nakhon Sawan, 60000",
  },
  {
    id: 600114,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "หนองกรด",
    subdistrictEn: "Nong Krot",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60240,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ หนองกรด นครสวรรค์ 60240",
    name_th: "เมืองนครสวรรค์ หนองกรด นครสวรรค์ 60240",
    name_en: "Mueang Nakhon Sawan, Nong Krot, Nakhon Sawan, 60240",
  },
  {
    id: 600115,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "หนองกระโดน",
    subdistrictEn: "Nong Kradon",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60240,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ หนองกระโดน นครสวรรค์ 60240",
    name_th: "เมืองนครสวรรค์ หนองกระโดน นครสวรรค์ 60240",
    name_en: "Mueang Nakhon Sawan, Nong Kradon, Nakhon Sawan, 60240",
  },
  {
    id: 600116,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "หนองปลิง",
    subdistrictEn: "Nong Pling",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ หนองปลิง นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ หนองปลิง นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Nong Pling, Nakhon Sawan, 60000",
  },
  {
    id: 600117,
    districtTh: "เมืองนครสวรรค์",
    districtEn: "Mueang Nakhon Sawan",
    subdistrictTh: "บึงเสนาท",
    subdistrictEn: "Bueng Senat",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60000,
    district_code: 6001,
    province_code: 47,
    name: "เมืองนครสวรรค์ บึงเสนาท นครสวรรค์ 60000",
    name_th: "เมืองนครสวรรค์ บึงเสนาท นครสวรรค์ 60000",
    name_en: "Mueang Nakhon Sawan, Bueng Senat, Nakhon Sawan, 60000",
  },
  {
    id: 600201,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "โกรกพระ",
    subdistrictEn: "Krok Phra",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ โกรกพระ นครสวรรค์ 60170",
    name_th: "โกรกพระ โกรกพระ นครสวรรค์ 60170",
    name_en: "Krok Phra, Krok Phra, Nakhon Sawan, 60170",
  },
  {
    id: 600202,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "ยางตาล",
    subdistrictEn: "Yang Tan",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ ยางตาล นครสวรรค์ 60170",
    name_th: "โกรกพระ ยางตาล นครสวรรค์ 60170",
    name_en: "Krok Phra, Yang Tan, Nakhon Sawan, 60170",
  },
  {
    id: 600203,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "บางมะฝ่อ",
    subdistrictEn: "Bang Mafo",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ บางมะฝ่อ นครสวรรค์ 60170",
    name_th: "โกรกพระ บางมะฝ่อ นครสวรรค์ 60170",
    name_en: "Krok Phra, Bang Mafo, Nakhon Sawan, 60170",
  },
  {
    id: 600204,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "บางประมุง",
    subdistrictEn: "Bang Pramung",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ บางประมุง นครสวรรค์ 60170",
    name_th: "โกรกพระ บางประมุง นครสวรรค์ 60170",
    name_en: "Krok Phra, Bang Pramung, Nakhon Sawan, 60170",
  },
  {
    id: 600205,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "นากลาง",
    subdistrictEn: "Na Klang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ นากลาง นครสวรรค์ 60170",
    name_th: "โกรกพระ นากลาง นครสวรรค์ 60170",
    name_en: "Krok Phra, Na Klang, Nakhon Sawan, 60170",
  },
  {
    id: 600206,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "ศาลาแดง",
    subdistrictEn: "Sala Daeng",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ ศาลาแดง นครสวรรค์ 60170",
    name_th: "โกรกพระ ศาลาแดง นครสวรรค์ 60170",
    name_en: "Krok Phra, Sala Daeng, Nakhon Sawan, 60170",
  },
  {
    id: 600207,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "เนินกว้าว",
    subdistrictEn: "Noen Kwao",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ เนินกว้าว นครสวรรค์ 60170",
    name_th: "โกรกพระ เนินกว้าว นครสวรรค์ 60170",
    name_en: "Krok Phra, Noen Kwao, Nakhon Sawan, 60170",
  },
  {
    id: 600208,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "เนินศาลา",
    subdistrictEn: "Noen Sala",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ เนินศาลา นครสวรรค์ 60170",
    name_th: "โกรกพระ เนินศาลา นครสวรรค์ 60170",
    name_en: "Krok Phra, Noen Sala, Nakhon Sawan, 60170",
  },
  {
    id: 600209,
    districtTh: "โกรกพระ",
    districtEn: "Krok Phra",
    subdistrictTh: "หาดสูง",
    subdistrictEn: "Hat Sung",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60170,
    district_code: 6002,
    province_code: 47,
    name: "โกรกพระ หาดสูง นครสวรรค์ 60170",
    name_th: "โกรกพระ หาดสูง นครสวรรค์ 60170",
    name_en: "Krok Phra, Hat Sung, Nakhon Sawan, 60170",
  },
  {
    id: 600301,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "ชุมแสง",
    subdistrictEn: "Chum Saeng",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง ชุมแสง นครสวรรค์ 60120",
    name_th: "ชุมแสง ชุมแสง นครสวรรค์ 60120",
    name_en: "Chum Saeng, Chum Saeng, Nakhon Sawan, 60120",
  },
  {
    id: 600302,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "ทับกฤช",
    subdistrictEn: "Thap Krit",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60250,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง ทับกฤช นครสวรรค์ 60250",
    name_th: "ชุมแสง ทับกฤช นครสวรรค์ 60250",
    name_en: "Chum Saeng, Thap Krit, Nakhon Sawan, 60250",
  },
  {
    id: 600303,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "พิกุล",
    subdistrictEn: "Phikun",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง พิกุล นครสวรรค์ 60120",
    name_th: "ชุมแสง พิกุล นครสวรรค์ 60120",
    name_en: "Chum Saeng, Phikun, Nakhon Sawan, 60120",
  },
  {
    id: 600304,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "เกยไชย",
    subdistrictEn: "Koei Chai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง เกยไชย นครสวรรค์ 60120",
    name_th: "ชุมแสง เกยไชย นครสวรรค์ 60120",
    name_en: "Chum Saeng, Koei Chai, Nakhon Sawan, 60120",
  },
  {
    id: 600305,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "ท่าไม้",
    subdistrictEn: "Tha Mai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง ท่าไม้ นครสวรรค์ 60120",
    name_th: "ชุมแสง ท่าไม้ นครสวรรค์ 60120",
    name_en: "Chum Saeng, Tha Mai, Nakhon Sawan, 60120",
  },
  {
    id: 600306,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "บางเคียน",
    subdistrictEn: "Bang Khian",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง บางเคียน นครสวรรค์ 60120",
    name_th: "ชุมแสง บางเคียน นครสวรรค์ 60120",
    name_en: "Chum Saeng, Bang Khian, Nakhon Sawan, 60120",
  },
  {
    id: 600307,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "หนองกระเจา",
    subdistrictEn: "Nong Krachao",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง หนองกระเจา นครสวรรค์ 60120",
    name_th: "ชุมแสง หนองกระเจา นครสวรรค์ 60120",
    name_en: "Chum Saeng, Nong Krachao, Nakhon Sawan, 60120",
  },
  {
    id: 600308,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "พันลาน",
    subdistrictEn: "Phan Lan",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60250,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง พันลาน นครสวรรค์ 60250",
    name_th: "ชุมแสง พันลาน นครสวรรค์ 60250",
    name_en: "Chum Saeng, Phan Lan, Nakhon Sawan, 60250",
  },
  {
    id: 600309,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "โคกหม้อ",
    subdistrictEn: "Khok Mo",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง โคกหม้อ นครสวรรค์ 60120",
    name_th: "ชุมแสง โคกหม้อ นครสวรรค์ 60120",
    name_en: "Chum Saeng, Khok Mo, Nakhon Sawan, 60120",
  },
  {
    id: 600310,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "ไผ่สิงห์",
    subdistrictEn: "Phai Sing",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง ไผ่สิงห์ นครสวรรค์ 60120",
    name_th: "ชุมแสง ไผ่สิงห์ นครสวรรค์ 60120",
    name_en: "Chum Saeng, Phai Sing, Nakhon Sawan, 60120",
  },
  {
    id: 600311,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "ฆะมัง",
    subdistrictEn: "Khamang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60120,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง ฆะมัง นครสวรรค์ 60120",
    name_th: "ชุมแสง ฆะมัง นครสวรรค์ 60120",
    name_en: "Chum Saeng, Khamang, Nakhon Sawan, 60120",
  },
  {
    id: 600312,
    districtTh: "ชุมแสง",
    districtEn: "Chum Saeng",
    subdistrictTh: "ทับกฤชใต้",
    subdistrictEn: "Thap Krit Tai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60250,
    district_code: 6003,
    province_code: 47,
    name: "ชุมแสง ทับกฤชใต้ นครสวรรค์ 60250",
    name_th: "ชุมแสง ทับกฤชใต้ นครสวรรค์ 60250",
    name_en: "Chum Saeng, Thap Krit Tai, Nakhon Sawan, 60250",
  },
  {
    id: 600401,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว หนองบัว นครสวรรค์ 60110",
    name_th: "หนองบัว หนองบัว นครสวรรค์ 60110",
    name_en: "Nong Bua, Nong Bua, Nakhon Sawan, 60110",
  },
  {
    id: 600402,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "หนองกลับ",
    subdistrictEn: "Nong Klap",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว หนองกลับ นครสวรรค์ 60110",
    name_th: "หนองบัว หนองกลับ นครสวรรค์ 60110",
    name_en: "Nong Bua, Nong Klap, Nakhon Sawan, 60110",
  },
  {
    id: 600403,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "ธารทหาร",
    subdistrictEn: "Than Thahan",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว ธารทหาร นครสวรรค์ 60110",
    name_th: "หนองบัว ธารทหาร นครสวรรค์ 60110",
    name_en: "Nong Bua, Than Thahan, Nakhon Sawan, 60110",
  },
  {
    id: 600404,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "ห้วยร่วม",
    subdistrictEn: "Huai Ruam",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว ห้วยร่วม นครสวรรค์ 60110",
    name_th: "หนองบัว ห้วยร่วม นครสวรรค์ 60110",
    name_en: "Nong Bua, Huai Ruam, Nakhon Sawan, 60110",
  },
  {
    id: 600405,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "ห้วยถั่วใต้",
    subdistrictEn: "Huai Thua Tai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว ห้วยถั่วใต้ นครสวรรค์ 60110",
    name_th: "หนองบัว ห้วยถั่วใต้ นครสวรรค์ 60110",
    name_en: "Nong Bua, Huai Thua Tai, Nakhon Sawan, 60110",
  },
  {
    id: 600406,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "ห้วยถั่วเหนือ",
    subdistrictEn: "Huai Thua Nuea",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว ห้วยถั่วเหนือ นครสวรรค์ 60110",
    name_th: "หนองบัว ห้วยถั่วเหนือ นครสวรรค์ 60110",
    name_en: "Nong Bua, Huai Thua Nuea, Nakhon Sawan, 60110",
  },
  {
    id: 600407,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "ห้วยใหญ่",
    subdistrictEn: "Huai Yai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว ห้วยใหญ่ นครสวรรค์ 60110",
    name_th: "หนองบัว ห้วยใหญ่ นครสวรรค์ 60110",
    name_en: "Nong Bua, Huai Yai, Nakhon Sawan, 60110",
  },
  {
    id: 600408,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "ทุ่งทอง",
    subdistrictEn: "Thung Thong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว ทุ่งทอง นครสวรรค์ 60110",
    name_th: "หนองบัว ทุ่งทอง นครสวรรค์ 60110",
    name_en: "Nong Bua, Thung Thong, Nakhon Sawan, 60110",
  },
  {
    id: 600409,
    districtTh: "หนองบัว",
    districtEn: "Nong Bua",
    subdistrictTh: "วังบ่อ",
    subdistrictEn: "Wang Bo",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60110,
    district_code: 6004,
    province_code: 47,
    name: "หนองบัว วังบ่อ นครสวรรค์ 60110",
    name_th: "หนองบัว วังบ่อ นครสวรรค์ 60110",
    name_en: "Nong Bua, Wang Bo, Nakhon Sawan, 60110",
  },
  {
    id: 600501,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "ท่างิ้ว",
    subdistrictEn: "Tha Ngio",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย ท่างิ้ว นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย ท่างิ้ว นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Tha Ngio, Nakhon Sawan, 60180",
  },
  {
    id: 600502,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "บางตาหงาย",
    subdistrictEn: "Bang Ta Ngai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย บางตาหงาย นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย บางตาหงาย นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Bang Ta Ngai, Nakhon Sawan, 60180",
  },
  {
    id: 600503,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "หูกวาง",
    subdistrictEn: "Hukwang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย หูกวาง นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย หูกวาง นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Hukwang, Nakhon Sawan, 60180",
  },
  {
    id: 600504,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "อ่างทอง",
    subdistrictEn: "Ang Thong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย อ่างทอง นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย อ่างทอง นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Ang Thong, Nakhon Sawan, 60180",
  },
  {
    id: 600505,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "บ้านแดน",
    subdistrictEn: "Ban Daen",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย บ้านแดน นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย บ้านแดน นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Ban Daen, Nakhon Sawan, 60180",
  },
  {
    id: 600506,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "บางแก้ว",
    subdistrictEn: "Bang Kaeo",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย บางแก้ว นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย บางแก้ว นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Bang Kaeo, Nakhon Sawan, 60180",
  },
  {
    id: 600507,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "ตาขีด",
    subdistrictEn: "Ta Khit",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย ตาขีด นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย ตาขีด นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Ta Khit, Nakhon Sawan, 60180",
  },
  {
    id: 600508,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "ตาสัง",
    subdistrictEn: "Ta Sang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย ตาสัง นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย ตาสัง นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Ta Sang, Nakhon Sawan, 60180",
  },
  {
    id: 600509,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "ด่านช้าง",
    subdistrictEn: "Dan Chang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย ด่านช้าง นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย ด่านช้าง นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Dan Chang, Nakhon Sawan, 60180",
  },
  {
    id: 600510,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "หนองกรด",
    subdistrictEn: "Nong Krot",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย หนองกรด นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย หนองกรด นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Nong Krot, Nakhon Sawan, 60180",
  },
  {
    id: 600511,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "หนองตางู",
    subdistrictEn: "Nong Ta Ngu",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย หนองตางู นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย หนองตางู นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Nong Ta Ngu, Nakhon Sawan, 60180",
  },
  {
    id: 600512,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "บึงปลาทู",
    subdistrictEn: "Bueng Pla Thu",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย บึงปลาทู นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย บึงปลาทู นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Bueng Pla Thu, Nakhon Sawan, 60180",
  },
  {
    id: 600513,
    districtTh: "บรรพตพิสัย",
    districtEn: "Banphot Phisai",
    subdistrictTh: "เจริญผล",
    subdistrictEn: "Charoen Phon",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60180,
    district_code: 6005,
    province_code: 47,
    name: "บรรพตพิสัย เจริญผล นครสวรรค์ 60180",
    name_th: "บรรพตพิสัย เจริญผล นครสวรรค์ 60180",
    name_en: "Banphot Phisai, Charoen Phon, Nakhon Sawan, 60180",
  },
  {
    id: 600601,
    districtTh: "เก้าเลี้ยว",
    districtEn: "Kao Liao",
    subdistrictTh: "มหาโพธิ",
    subdistrictEn: "Maha Phot",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60230,
    district_code: 6006,
    province_code: 47,
    name: "เก้าเลี้ยว มหาโพธิ นครสวรรค์ 60230",
    name_th: "เก้าเลี้ยว มหาโพธิ นครสวรรค์ 60230",
    name_en: "Kao Liao, Maha Phot, Nakhon Sawan, 60230",
  },
  {
    id: 600602,
    districtTh: "เก้าเลี้ยว",
    districtEn: "Kao Liao",
    subdistrictTh: "เก้าเลี้ยว",
    subdistrictEn: "Kao Liao",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60230,
    district_code: 6006,
    province_code: 47,
    name: "เก้าเลี้ยว เก้าเลี้ยว นครสวรรค์ 60230",
    name_th: "เก้าเลี้ยว เก้าเลี้ยว นครสวรรค์ 60230",
    name_en: "Kao Liao, Kao Liao, Nakhon Sawan, 60230",
  },
  {
    id: 600603,
    districtTh: "เก้าเลี้ยว",
    districtEn: "Kao Liao",
    subdistrictTh: "หนองเต่า",
    subdistrictEn: "Nong Tao",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60230,
    district_code: 6006,
    province_code: 47,
    name: "เก้าเลี้ยว หนองเต่า นครสวรรค์ 60230",
    name_th: "เก้าเลี้ยว หนองเต่า นครสวรรค์ 60230",
    name_en: "Kao Liao, Nong Tao, Nakhon Sawan, 60230",
  },
  {
    id: 600604,
    districtTh: "เก้าเลี้ยว",
    districtEn: "Kao Liao",
    subdistrictTh: "เขาดิน",
    subdistrictEn: "Khao Din",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60230,
    district_code: 6006,
    province_code: 47,
    name: "เก้าเลี้ยว เขาดิน นครสวรรค์ 60230",
    name_th: "เก้าเลี้ยว เขาดิน นครสวรรค์ 60230",
    name_en: "Kao Liao, Khao Din, Nakhon Sawan, 60230",
  },
  {
    id: 600605,
    districtTh: "เก้าเลี้ยว",
    districtEn: "Kao Liao",
    subdistrictTh: "หัวดง",
    subdistrictEn: "Hua Dong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60230,
    district_code: 6006,
    province_code: 47,
    name: "เก้าเลี้ยว หัวดง นครสวรรค์ 60230",
    name_th: "เก้าเลี้ยว หัวดง นครสวรรค์ 60230",
    name_en: "Kao Liao, Hua Dong, Nakhon Sawan, 60230",
  },
  {
    id: 600701,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "ตาคลี",
    subdistrictEn: "Takhli",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60140,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี ตาคลี นครสวรรค์ 60140",
    name_th: "ตาคลี ตาคลี นครสวรรค์ 60140",
    name_en: "Takhli, Takhli, Nakhon Sawan, 60140",
  },
  {
    id: 600702,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "ช่องแค",
    subdistrictEn: "Chong Khae",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60210,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี ช่องแค นครสวรรค์ 60210",
    name_th: "ตาคลี ช่องแค นครสวรรค์ 60210",
    name_en: "Takhli, Chong Khae, Nakhon Sawan, 60210",
  },
  {
    id: 600703,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "จันเสน",
    subdistrictEn: "Chan Sen",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60260,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี จันเสน นครสวรรค์ 60260",
    name_th: "ตาคลี จันเสน นครสวรรค์ 60260",
    name_en: "Takhli, Chan Sen, Nakhon Sawan, 60260",
  },
  {
    id: 600704,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "ห้วยหอม",
    subdistrictEn: "Huai Hom",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60210,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี ห้วยหอม นครสวรรค์ 60210",
    name_th: "ตาคลี ห้วยหอม นครสวรรค์ 60210",
    name_en: "Takhli, Huai Hom, Nakhon Sawan, 60210",
  },
  {
    id: 600705,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "หัวหวาย",
    subdistrictEn: "Hua Wai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60140,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี หัวหวาย นครสวรรค์ 60140",
    name_th: "ตาคลี หัวหวาย นครสวรรค์ 60140",
    name_en: "Takhli, Hua Wai, Nakhon Sawan, 60140",
  },
  {
    id: 600706,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "หนองโพ",
    subdistrictEn: "Nong Pho",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60140,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี หนองโพ นครสวรรค์ 60140",
    name_th: "ตาคลี หนองโพ นครสวรรค์ 60140",
    name_en: "Takhli, Nong Pho, Nakhon Sawan, 60140",
  },
  {
    id: 600707,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "หนองหม้อ",
    subdistrictEn: "Nong Mo",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60140,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี หนองหม้อ นครสวรรค์ 60140",
    name_th: "ตาคลี หนองหม้อ นครสวรรค์ 60140",
    name_en: "Takhli, Nong Mo, Nakhon Sawan, 60140",
  },
  {
    id: 600708,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "สร้อยทอง",
    subdistrictEn: "Soi Thong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60210,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี สร้อยทอง นครสวรรค์ 60210",
    name_th: "ตาคลี สร้อยทอง นครสวรรค์ 60210",
    name_en: "Takhli, Soi Thong, Nakhon Sawan, 60210",
  },
  {
    id: 600709,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "ลาดทิพรส",
    subdistrictEn: "Lat Thippharot",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60260,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี ลาดทิพรส นครสวรรค์ 60260",
    name_th: "ตาคลี ลาดทิพรส นครสวรรค์ 60260",
    name_en: "Takhli, Lat Thippharot, Nakhon Sawan, 60260",
  },
  {
    id: 600710,
    districtTh: "ตาคลี",
    districtEn: "Takhli",
    subdistrictTh: "พรหมนิมิต",
    subdistrictEn: "Phrom Nimit",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60210,
    district_code: 6007,
    province_code: 47,
    name: "ตาคลี พรหมนิมิต นครสวรรค์ 60210",
    name_th: "ตาคลี พรหมนิมิต นครสวรรค์ 60210",
    name_en: "Takhli, Phrom Nimit, Nakhon Sawan, 60210",
  },
  {
    id: 600801,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "ท่าตะโก",
    subdistrictEn: "Tha Tako",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก ท่าตะโก นครสวรรค์ 60160",
    name_th: "ท่าตะโก ท่าตะโก นครสวรรค์ 60160",
    name_en: "Takhli, Tha Tako, Nakhon Sawan, 60160",
  },
  {
    id: 600802,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "พนมรอก",
    subdistrictEn: "Phanom Rok",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก พนมรอก นครสวรรค์ 60160",
    name_th: "ท่าตะโก พนมรอก นครสวรรค์ 60160",
    name_en: "Takhli, Phanom Rok, Nakhon Sawan, 60160",
  },
  {
    id: 600803,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "หัวถนน",
    subdistrictEn: "Hua Thanon",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก หัวถนน นครสวรรค์ 60160",
    name_th: "ท่าตะโก หัวถนน นครสวรรค์ 60160",
    name_en: "Takhli, Hua Thanon, Nakhon Sawan, 60160",
  },
  {
    id: 600804,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "สายลำโพง",
    subdistrictEn: "Sai Lamphong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก สายลำโพง นครสวรรค์ 60160",
    name_th: "ท่าตะโก สายลำโพง นครสวรรค์ 60160",
    name_en: "Takhli, Sai Lamphong, Nakhon Sawan, 60160",
  },
  {
    id: 600805,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "วังมหากร",
    subdistrictEn: "Wang Mahakon",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก วังมหากร นครสวรรค์ 60160",
    name_th: "ท่าตะโก วังมหากร นครสวรรค์ 60160",
    name_en: "Takhli, Wang Mahakon, Nakhon Sawan, 60160",
  },
  {
    id: 600806,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "ดอนคา",
    subdistrictEn: "Don Kha",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก ดอนคา นครสวรรค์ 60160",
    name_th: "ท่าตะโก ดอนคา นครสวรรค์ 60160",
    name_en: "Takhli, Don Kha, Nakhon Sawan, 60160",
  },
  {
    id: 600807,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "ทำนบ",
    subdistrictEn: "Thamnop",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก ทำนบ นครสวรรค์ 60160",
    name_th: "ท่าตะโก ทำนบ นครสวรรค์ 60160",
    name_en: "Takhli, Thamnop, Nakhon Sawan, 60160",
  },
  {
    id: 600808,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "วังใหญ่",
    subdistrictEn: "Wang Yai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก วังใหญ่ นครสวรรค์ 60160",
    name_th: "ท่าตะโก วังใหญ่ นครสวรรค์ 60160",
    name_en: "Takhli, Wang Yai, Nakhon Sawan, 60160",
  },
  {
    id: 600809,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "พนมเศษ",
    subdistrictEn: "Phanom Set",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก พนมเศษ นครสวรรค์ 60160",
    name_th: "ท่าตะโก พนมเศษ นครสวรรค์ 60160",
    name_en: "Takhli, Phanom Set, Nakhon Sawan, 60160",
  },
  {
    id: 600810,
    districtTh: "ท่าตะโก",
    districtEn: "Takhli",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60160,
    district_code: 6008,
    province_code: 47,
    name: "ท่าตะโก หนองหลวง นครสวรรค์ 60160",
    name_th: "ท่าตะโก หนองหลวง นครสวรรค์ 60160",
    name_en: "Takhli, Nong Luang, Nakhon Sawan, 60160",
  },
  {
    id: 600901,
    districtTh: "ไพศาลี",
    districtEn: "Phaisali",
    subdistrictTh: "โคกเดื่อ",
    subdistrictEn: "Khok Duea",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60220,
    district_code: 6009,
    province_code: 47,
    name: "ไพศาลี โคกเดื่อ นครสวรรค์ 60220",
    name_th: "ไพศาลี โคกเดื่อ นครสวรรค์ 60220",
    name_en: "Phaisali, Khok Duea, Nakhon Sawan, 60220",
  },
  {
    id: 600902,
    districtTh: "ไพศาลี",
    districtEn: "Phaisali",
    subdistrictTh: "สำโรงชัย",
    subdistrictEn: "Samrong Chai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60220,
    district_code: 6009,
    province_code: 47,
    name: "ไพศาลี สำโรงชัย นครสวรรค์ 60220",
    name_th: "ไพศาลี สำโรงชัย นครสวรรค์ 60220",
    name_en: "Phaisali, Samrong Chai, Nakhon Sawan, 60220",
  },
  {
    id: 600903,
    districtTh: "ไพศาลี",
    districtEn: "Phaisali",
    subdistrictTh: "วังน้ำลัด",
    subdistrictEn: "Wang Nam Lat",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60220,
    district_code: 6009,
    province_code: 47,
    name: "ไพศาลี วังน้ำลัด นครสวรรค์ 60220",
    name_th: "ไพศาลี วังน้ำลัด นครสวรรค์ 60220",
    name_en: "Phaisali, Wang Nam Lat, Nakhon Sawan, 60220",
  },
  {
    id: 600904,
    districtTh: "ไพศาลี",
    districtEn: "Phaisali",
    subdistrictTh: "ตะคร้อ",
    subdistrictEn: "Takhro",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60220,
    district_code: 6009,
    province_code: 47,
    name: "ไพศาลี ตะคร้อ นครสวรรค์ 60220",
    name_th: "ไพศาลี ตะคร้อ นครสวรรค์ 60220",
    name_en: "Phaisali, Takhro, Nakhon Sawan, 60220",
  },
  {
    id: 600905,
    districtTh: "ไพศาลี",
    districtEn: "Phaisali",
    subdistrictTh: "โพธิ์ประสาท",
    subdistrictEn: "Pho Prasat",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60220,
    district_code: 6009,
    province_code: 47,
    name: "ไพศาลี โพธิ์ประสาท นครสวรรค์ 60220",
    name_th: "ไพศาลี โพธิ์ประสาท นครสวรรค์ 60220",
    name_en: "Phaisali, Pho Prasat, Nakhon Sawan, 60220",
  },
  {
    id: 600906,
    districtTh: "ไพศาลี",
    districtEn: "Phaisali",
    subdistrictTh: "วังข่อย",
    subdistrictEn: "Wang Khoi",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60220,
    district_code: 6009,
    province_code: 47,
    name: "ไพศาลี วังข่อย นครสวรรค์ 60220",
    name_th: "ไพศาลี วังข่อย นครสวรรค์ 60220",
    name_en: "Phaisali, Wang Khoi, Nakhon Sawan, 60220",
  },
  {
    id: 600907,
    districtTh: "ไพศาลี",
    districtEn: "Phaisali",
    subdistrictTh: "นาขอม",
    subdistrictEn: "Na Khom",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60220,
    district_code: 6009,
    province_code: 47,
    name: "ไพศาลี นาขอม นครสวรรค์ 60220",
    name_th: "ไพศาลี นาขอม นครสวรรค์ 60220",
    name_en: "Phaisali, Na Khom, Nakhon Sawan, 60220",
  },
  {
    id: 600908,
    districtTh: "ไพศาลี",
    districtEn: "Phaisali",
    subdistrictTh: "ไพศาลี",
    subdistrictEn: "Phaisali",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60220,
    district_code: 6009,
    province_code: 47,
    name: "ไพศาลี ไพศาลี นครสวรรค์ 60220",
    name_th: "ไพศาลี ไพศาลี นครสวรรค์ 60220",
    name_en: "Phaisali, Phaisali, Nakhon Sawan, 60220",
  },
  {
    id: 601001,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "พยุหะ",
    subdistrictEn: "Phayuha",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี พยุหะ นครสวรรค์ 60130",
    name_th: "พยุหะคีรี พยุหะ นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Phayuha, Nakhon Sawan, 60130",
  },
  {
    id: 601002,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "เนินมะกอก",
    subdistrictEn: "Noen Makok",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี เนินมะกอก นครสวรรค์ 60130",
    name_th: "พยุหะคีรี เนินมะกอก นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Noen Makok, Nakhon Sawan, 60130",
  },
  {
    id: 601003,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "นิคมเขาบ่อแก้ว",
    subdistrictEn: "Nikhom Khao Bo Kaeo",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี นิคมเขาบ่อแก้ว นครสวรรค์ 60130",
    name_th: "พยุหะคีรี นิคมเขาบ่อแก้ว นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Nikhom Khao Bo Kaeo, Nakhon Sawan, 60130",
  },
  {
    id: 601004,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "ม่วงหัก",
    subdistrictEn: "Muang Hak",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี ม่วงหัก นครสวรรค์ 60130",
    name_th: "พยุหะคีรี ม่วงหัก นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Muang Hak, Nakhon Sawan, 60130",
  },
  {
    id: 601005,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "ยางขาว",
    subdistrictEn: "Yang Khao",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี ยางขาว นครสวรรค์ 60130",
    name_th: "พยุหะคีรี ยางขาว นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Yang Khao, Nakhon Sawan, 60130",
  },
  {
    id: 601006,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "ย่านมัทรี",
    subdistrictEn: "Yan Matsi",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี ย่านมัทรี นครสวรรค์ 60130",
    name_th: "พยุหะคีรี ย่านมัทรี นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Yan Matsi, Nakhon Sawan, 60130",
  },
  {
    id: 601007,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "เขาทอง",
    subdistrictEn: "Khao Thong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี เขาทอง นครสวรรค์ 60130",
    name_th: "พยุหะคีรี เขาทอง นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Khao Thong, Nakhon Sawan, 60130",
  },
  {
    id: 601008,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "ท่าน้ำอ้อย",
    subdistrictEn: "Tha Nam Oi",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี ท่าน้ำอ้อย นครสวรรค์ 60130",
    name_th: "พยุหะคีรี ท่าน้ำอ้อย นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Tha Nam Oi, Nakhon Sawan, 60130",
  },
  {
    id: 601009,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "น้ำทรง",
    subdistrictEn: "Nam Song",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี น้ำทรง นครสวรรค์ 60130",
    name_th: "พยุหะคีรี น้ำทรง นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Nam Song, Nakhon Sawan, 60130",
  },
  {
    id: 601010,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "เขากะลา",
    subdistrictEn: "Khao Kala",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี เขากะลา นครสวรรค์ 60130",
    name_th: "พยุหะคีรี เขากะลา นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Khao Kala, Nakhon Sawan, 60130",
  },
  {
    id: 601011,
    districtTh: "พยุหะคีรี",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "สระทะเล",
    subdistrictEn: "Sa Thale",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60130,
    district_code: 6010,
    province_code: 47,
    name: "พยุหะคีรี สระทะเล นครสวรรค์ 60130",
    name_th: "พยุหะคีรี สระทะเล นครสวรรค์ 60130",
    name_en: "Phayuha Khiri, Sa Thale, Nakhon Sawan, 60130",
  },
  {
    id: 601101,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "ลาดยาว",
    subdistrictEn: "Lat Yao",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว ลาดยาว นครสวรรค์ 60150",
    name_th: "ลาดยาว ลาดยาว นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Lat Yao, Nakhon Sawan, 60150",
  },
  {
    id: 601102,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "ห้วยน้ำหอม",
    subdistrictEn: "Huai Nam Hom",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว ห้วยน้ำหอม นครสวรรค์ 60150",
    name_th: "ลาดยาว ห้วยน้ำหอม นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Huai Nam Hom, Nakhon Sawan, 60150",
  },
  {
    id: 601103,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "วังม้า",
    subdistrictEn: "Wang Ma",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว วังม้า นครสวรรค์ 60150",
    name_th: "ลาดยาว วังม้า นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Wang Ma, Nakhon Sawan, 60150",
  },
  {
    id: 601104,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "วังเมือง",
    subdistrictEn: "Wang Mueang",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว วังเมือง นครสวรรค์ 60150",
    name_th: "ลาดยาว วังเมือง นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Wang Mueang, Nakhon Sawan, 60150",
  },
  {
    id: 601105,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "สร้อยละคร",
    subdistrictEn: "Soi Lakhon",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว สร้อยละคร นครสวรรค์ 60150",
    name_th: "ลาดยาว สร้อยละคร นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Soi Lakhon, Nakhon Sawan, 60150",
  },
  {
    id: 601106,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "มาบแก",
    subdistrictEn: "Map Kae",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว มาบแก นครสวรรค์ 60150",
    name_th: "ลาดยาว มาบแก นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Map Kae, Nakhon Sawan, 60150",
  },
  {
    id: 601107,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "หนองยาว",
    subdistrictEn: "Nong Yao",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว หนองยาว นครสวรรค์ 60150",
    name_th: "ลาดยาว หนองยาว นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Nong Yao, Nakhon Sawan, 60150",
  },
  {
    id: 601108,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "หนองนมวัว",
    subdistrictEn: "Nong Nom Wua",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว หนองนมวัว นครสวรรค์ 60150",
    name_th: "ลาดยาว หนองนมวัว นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Nong Nom Wua, Nakhon Sawan, 60150",
  },
  {
    id: 601109,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "บ้านไร่",
    subdistrictEn: "Ban Rai",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว บ้านไร่ นครสวรรค์ 60150",
    name_th: "ลาดยาว บ้านไร่ นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Ban Rai, Nakhon Sawan, 60150",
  },
  {
    id: 601110,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "เนินขี้เหล็ก",
    subdistrictEn: "Noen Khilek",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว เนินขี้เหล็ก นครสวรรค์ 60150",
    name_th: "ลาดยาว เนินขี้เหล็ก นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Noen Khilek, Nakhon Sawan, 60150",
  },
  {
    id: 601116,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "ศาลเจ้าไก่ต่อ",
    subdistrictEn: "San Chao Kai To",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว ศาลเจ้าไก่ต่อ นครสวรรค์ 60150",
    name_th: "ลาดยาว ศาลเจ้าไก่ต่อ นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, San Chao Kai To, Nakhon Sawan, 60150",
  },
  {
    id: 601117,
    districtTh: "ลาดยาว",
    districtEn: "Phayuha Khiri",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6011,
    province_code: 47,
    name: "ลาดยาว สระแก้ว นครสวรรค์ 60150",
    name_th: "ลาดยาว สระแก้ว นครสวรรค์ 60150",
    name_en: "Phayuha Khiri, Sa Kaeo, Nakhon Sawan, 60150",
  },
  {
    id: 601201,
    districtTh: "ตากฟ้า",
    districtEn: "Tak Fa",
    subdistrictTh: "ตากฟ้า",
    subdistrictEn: "Tak Fa",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60190,
    district_code: 6012,
    province_code: 47,
    name: "ตากฟ้า ตากฟ้า นครสวรรค์ 60190",
    name_th: "ตากฟ้า ตากฟ้า นครสวรรค์ 60190",
    name_en: "Tak Fa, Tak Fa, Nakhon Sawan, 60190",
  },
  {
    id: 601202,
    districtTh: "ตากฟ้า",
    districtEn: "Tak Fa",
    subdistrictTh: "ลำพยนต์",
    subdistrictEn: "Lam Phayon",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60190,
    district_code: 6012,
    province_code: 47,
    name: "ตากฟ้า ลำพยนต์ นครสวรรค์ 60190",
    name_th: "ตากฟ้า ลำพยนต์ นครสวรรค์ 60190",
    name_en: "Tak Fa, Lam Phayon, Nakhon Sawan, 60190",
  },
  {
    id: 601203,
    districtTh: "ตากฟ้า",
    districtEn: "Tak Fa",
    subdistrictTh: "สุขสำราญ",
    subdistrictEn: "Suk Samran",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60190,
    district_code: 6012,
    province_code: 47,
    name: "ตากฟ้า สุขสำราญ นครสวรรค์ 60190",
    name_th: "ตากฟ้า สุขสำราญ นครสวรรค์ 60190",
    name_en: "Tak Fa, Suk Samran, Nakhon Sawan, 60190",
  },
  {
    id: 601204,
    districtTh: "ตากฟ้า",
    districtEn: "Tak Fa",
    subdistrictTh: "หนองพิกุล",
    subdistrictEn: "Nong Phikun",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60190,
    district_code: 6012,
    province_code: 47,
    name: "ตากฟ้า หนองพิกุล นครสวรรค์ 60190",
    name_th: "ตากฟ้า หนองพิกุล นครสวรรค์ 60190",
    name_en: "Tak Fa, Nong Phikun, Nakhon Sawan, 60190",
  },
  {
    id: 601205,
    districtTh: "ตากฟ้า",
    districtEn: "Tak Fa",
    subdistrictTh: "พุนกยูง",
    subdistrictEn: "Phu Nok Yung",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60190,
    district_code: 6012,
    province_code: 47,
    name: "ตากฟ้า พุนกยูง นครสวรรค์ 60190",
    name_th: "ตากฟ้า พุนกยูง นครสวรรค์ 60190",
    name_en: "Tak Fa, Phu Nok Yung, Nakhon Sawan, 60190",
  },
  {
    id: 601206,
    districtTh: "ตากฟ้า",
    districtEn: "Tak Fa",
    subdistrictTh: "อุดมธัญญา",
    subdistrictEn: "Udom Thanya",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60190,
    district_code: 6012,
    province_code: 47,
    name: "ตากฟ้า อุดมธัญญา นครสวรรค์ 60190",
    name_th: "ตากฟ้า อุดมธัญญา นครสวรรค์ 60190",
    name_en: "Tak Fa, Udom Thanya, Nakhon Sawan, 60190",
  },
  {
    id: 601207,
    districtTh: "ตากฟ้า",
    districtEn: "Tak Fa",
    subdistrictTh: "เขาชายธง",
    subdistrictEn: "Khao Chai Thong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60190,
    district_code: 6012,
    province_code: 47,
    name: "ตากฟ้า เขาชายธง นครสวรรค์ 60190",
    name_th: "ตากฟ้า เขาชายธง นครสวรรค์ 60190",
    name_en: "Tak Fa, Khao Chai Thong, Nakhon Sawan, 60190",
  },
  {
    id: 601301,
    districtTh: "แม่วงก์",
    districtEn: "Mae Wong",
    subdistrictTh: "แม่วงก์",
    subdistrictEn: "Mae Wong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6013,
    province_code: 47,
    name: "แม่วงก์ แม่วงก์ นครสวรรค์ 60150",
    name_th: "แม่วงก์ แม่วงก์ นครสวรรค์ 60150",
    name_en: "Mae Wong, Mae Wong, Nakhon Sawan, 60150",
  },
  {
    id: 601303,
    districtTh: "แม่วงก์",
    districtEn: "Mae Wong",
    subdistrictTh: "แม่เล่ย์",
    subdistrictEn: "Mae Le",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6013,
    province_code: 47,
    name: "แม่วงก์ แม่เล่ย์ นครสวรรค์ 60150",
    name_th: "แม่วงก์ แม่เล่ย์ นครสวรรค์ 60150",
    name_en: "Mae Wong, Mae Le, Nakhon Sawan, 60150",
  },
  {
    id: 601304,
    districtTh: "แม่วงก์",
    districtEn: "Mae Wong",
    subdistrictTh: "วังซ่าน",
    subdistrictEn: "Wang San",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6013,
    province_code: 47,
    name: "แม่วงก์ วังซ่าน นครสวรรค์ 60150",
    name_th: "แม่วงก์ วังซ่าน นครสวรรค์ 60150",
    name_en: "Mae Wong, Wang San, Nakhon Sawan, 60150",
  },
  {
    id: 601305,
    districtTh: "แม่วงก์",
    districtEn: "Mae Wong",
    subdistrictTh: "เขาชนกัน",
    subdistrictEn: "Khao Chon Kan",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6013,
    province_code: 47,
    name: "แม่วงก์ เขาชนกัน นครสวรรค์ 60150",
    name_th: "แม่วงก์ เขาชนกัน นครสวรรค์ 60150",
    name_en: "Mae Wong, Khao Chon Kan, Nakhon Sawan, 60150",
  },
  {
    id: 601401,
    districtTh: "แม่เปิน",
    districtEn: "Mae Poen",
    subdistrictTh: "แม่เปิน",
    subdistrictEn: "Mae Poen)",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6014,
    province_code: 47,
    name: "แม่เปิน แม่เปิน นครสวรรค์ 60150",
    name_th: "แม่เปิน แม่เปิน นครสวรรค์ 60150",
    name_en: "Mae Poen, Mae Poen), Nakhon Sawan, 60150",
  },
  {
    id: 601501,
    districtTh: "ชุมตาบง",
    districtEn: "Chum Ta Bong",
    subdistrictTh: "ชุมตาบง",
    subdistrictEn: "Chum Ta Bong",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6015,
    province_code: 47,
    name: "ชุมตาบง ชุมตาบง นครสวรรค์ 60150",
    name_th: "ชุมตาบง ชุมตาบง นครสวรรค์ 60150",
    name_en: "Chum Ta Bong, Chum Ta Bong, Nakhon Sawan, 60150",
  },
  {
    id: 601502,
    districtTh: "ชุมตาบง",
    districtEn: "Chum Ta Bong",
    subdistrictTh: "ปางสวรรค์",
    subdistrictEn: "Pang Sawan",
    provinceTh: "นครสวรรค์",
    provinceEn: "Nakhon Sawan",
    zipcode: 60150,
    district_code: 6015,
    province_code: 47,
    name: "ชุมตาบง ปางสวรรค์ นครสวรรค์ 60150",
    name_th: "ชุมตาบง ปางสวรรค์ นครสวรรค์ 60150",
    name_en: "Chum Ta Bong, Pang Sawan, Nakhon Sawan, 60150",
  },
  {
    id: 610101,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "อุทัยใหม่",
    subdistrictEn: "Uthai Mai",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี อุทัยใหม่ อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี อุทัยใหม่ อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Uthai Mai, Uthai Thani, 61000",
  },
  {
    id: 610102,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "น้ำซึม",
    subdistrictEn: "Nam Suem",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี น้ำซึม อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี น้ำซึม อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Nam Suem, Uthai Thani, 61000",
  },
  {
    id: 610103,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "สะแกกรัง",
    subdistrictEn: "Sakae Krang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี สะแกกรัง อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี สะแกกรัง อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Sakae Krang, Uthai Thani, 61000",
  },
  {
    id: 610104,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "ดอนขวาง",
    subdistrictEn: "Don Khwang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี ดอนขวาง อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี ดอนขวาง อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Don Khwang, Uthai Thani, 61000",
  },
  {
    id: 610105,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "หาดทนง",
    subdistrictEn: "Hat Thanong",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี หาดทนง อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี หาดทนง อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Hat Thanong, Uthai Thani, 61000",
  },
  {
    id: 610106,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "เกาะเทโพ",
    subdistrictEn: "Ko Thepho",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี เกาะเทโพ อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี เกาะเทโพ อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Ko Thepho, Uthai Thani, 61000",
  },
  {
    id: 610107,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "ท่าซุง",
    subdistrictEn: "Tha Sung",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี ท่าซุง อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี ท่าซุง อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Tha Sung, Uthai Thani, 61000",
  },
  {
    id: 610108,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "หนองแก",
    subdistrictEn: "Nong Kae",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี หนองแก อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี หนองแก อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Nong Kae, Uthai Thani, 61000",
  },
  {
    id: 610109,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "โนนเหล็ก",
    subdistrictEn: "Non Lek",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี โนนเหล็ก อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี โนนเหล็ก อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Non Lek, Uthai Thani, 61000",
  },
  {
    id: 610111,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "หนองไผ่แบน",
    subdistrictEn: "Nong Phai Baen",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี หนองไผ่แบน อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี หนองไผ่แบน อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Nong Phai Baen, Uthai Thani, 61000",
  },
  {
    id: 610112,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "หนองพังค่า",
    subdistrictEn: "Nong Phang Kha",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี หนองพังค่า อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี หนองพังค่า อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Nong Phang Kha, Uthai Thani, 61000",
  },
  {
    id: 610113,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "ทุ่งใหญ่",
    subdistrictEn: "Thung Yai",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี ทุ่งใหญ่ อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี ทุ่งใหญ่ อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Thung Yai, Uthai Thani, 61000",
  },
  {
    id: 610114,
    districtTh: "เมืองอุทัยธานี",
    districtEn: "Mueang Uthai Thani",
    subdistrictTh: "เนินแจง",
    subdistrictEn: "Noen Chaeng",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61000,
    district_code: 6101,
    province_code: 48,
    name: "เมืองอุทัยธานี เนินแจง อุทัยธานี 61000",
    name_th: "เมืองอุทัยธานี เนินแจง อุทัยธานี 61000",
    name_en: "Mueang Uthai Thani, Noen Chaeng, Uthai Thani, 61000",
  },
  {
    id: 610201,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "ทัพทัน",
    subdistrictEn: "Thap Than",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน ทัพทัน อุทัยธานี 61120",
    name_th: "ทัพทัน ทัพทัน อุทัยธานี 61120",
    name_en: "Thap Than, Thap Than, Uthai Thani, 61120",
  },
  {
    id: 610202,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "ทุ่งนาไทย",
    subdistrictEn: "Thung Na Thai",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน ทุ่งนาไทย อุทัยธานี 61120",
    name_th: "ทัพทัน ทุ่งนาไทย อุทัยธานี 61120",
    name_en: "Thap Than, Thung Na Thai, Uthai Thani, 61120",
  },
  {
    id: 610203,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "เขาขี้ฝอย",
    subdistrictEn: "Khao Khi Foi",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน เขาขี้ฝอย อุทัยธานี 61120",
    name_th: "ทัพทัน เขาขี้ฝอย อุทัยธานี 61120",
    name_en: "Thap Than, Khao Khi Foi, Uthai Thani, 61120",
  },
  {
    id: 610204,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "หนองหญ้าปล้อง",
    subdistrictEn: "Nong Ya Plong",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน หนองหญ้าปล้อง อุทัยธานี 61120",
    name_th: "ทัพทัน หนองหญ้าปล้อง อุทัยธานี 61120",
    name_en: "Thap Than, Nong Ya Plong, Uthai Thani, 61120",
  },
  {
    id: 610205,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "โคกหม้อ",
    subdistrictEn: "Khok Mo",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน โคกหม้อ อุทัยธานี 61120",
    name_th: "ทัพทัน โคกหม้อ อุทัยธานี 61120",
    name_en: "Thap Than, Khok Mo, Uthai Thani, 61120",
  },
  {
    id: 610206,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "หนองยายดา",
    subdistrictEn: "Nong Yai Da",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน หนองยายดา อุทัยธานี 61120",
    name_th: "ทัพทัน หนองยายดา อุทัยธานี 61120",
    name_en: "Thap Than, Nong Yai Da, Uthai Thani, 61120",
  },
  {
    id: 610207,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "หนองกลางดง",
    subdistrictEn: "Nong Klang Dong",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน หนองกลางดง อุทัยธานี 61120",
    name_th: "ทัพทัน หนองกลางดง อุทัยธานี 61120",
    name_en: "Thap Than, Nong Klang Dong, Uthai Thani, 61120",
  },
  {
    id: 610208,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "หนองกระทุ่ม",
    subdistrictEn: "Nong Krathum",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน หนองกระทุ่ม อุทัยธานี 61120",
    name_th: "ทัพทัน หนองกระทุ่ม อุทัยธานี 61120",
    name_en: "Thap Than, Nong Krathum, Uthai Thani, 61120",
  },
  {
    id: 610209,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "หนองสระ",
    subdistrictEn: "Nong Sa",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน หนองสระ อุทัยธานี 61120",
    name_th: "ทัพทัน หนองสระ อุทัยธานี 61120",
    name_en: "Thap Than, Nong Sa, Uthai Thani, 61120",
  },
  {
    id: 610210,
    districtTh: "ทัพทัน",
    districtEn: "Thap Than",
    subdistrictTh: "ตลุกดู่",
    subdistrictEn: "Taluk Du",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61120,
    district_code: 6102,
    province_code: 48,
    name: "ทัพทัน ตลุกดู่ อุทัยธานี 61120",
    name_th: "ทัพทัน ตลุกดู่ อุทัยธานี 61120",
    name_en: "Thap Than, Taluk Du, Uthai Thani, 61120",
  },
  {
    id: 610301,
    districtTh: "สว่างอารมณ์",
    districtEn: "Sawang Arom",
    subdistrictTh: "สว่างอารมณ์",
    subdistrictEn: "Sawang Arom",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61150,
    district_code: 6103,
    province_code: 48,
    name: "สว่างอารมณ์ สว่างอารมณ์ อุทัยธานี 61150",
    name_th: "สว่างอารมณ์ สว่างอารมณ์ อุทัยธานี 61150",
    name_en: "Sawang Arom, Sawang Arom, Uthai Thani, 61150",
  },
  {
    id: 610302,
    districtTh: "สว่างอารมณ์",
    districtEn: "Sawang Arom",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61150,
    district_code: 6103,
    province_code: 48,
    name: "สว่างอารมณ์ หนองหลวง อุทัยธานี 61150",
    name_th: "สว่างอารมณ์ หนองหลวง อุทัยธานี 61150",
    name_en: "Sawang Arom, Nong Luang, Uthai Thani, 61150",
  },
  {
    id: 610303,
    districtTh: "สว่างอารมณ์",
    districtEn: "Sawang Arom",
    subdistrictTh: "พลวงสองนาง",
    subdistrictEn: "Phluang Song Nang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61150,
    district_code: 6103,
    province_code: 48,
    name: "สว่างอารมณ์ พลวงสองนาง อุทัยธานี 61150",
    name_th: "สว่างอารมณ์ พลวงสองนาง อุทัยธานี 61150",
    name_en: "Sawang Arom, Phluang Song Nang, Uthai Thani, 61150",
  },
  {
    id: 610304,
    districtTh: "สว่างอารมณ์",
    districtEn: "Sawang Arom",
    subdistrictTh: "ไผ่เขียว",
    subdistrictEn: "Phai Khiao",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61150,
    district_code: 6103,
    province_code: 48,
    name: "สว่างอารมณ์ ไผ่เขียว อุทัยธานี 61150",
    name_th: "สว่างอารมณ์ ไผ่เขียว อุทัยธานี 61150",
    name_en: "Sawang Arom, Phai Khiao, Uthai Thani, 61150",
  },
  {
    id: 610305,
    districtTh: "สว่างอารมณ์",
    districtEn: "Sawang Arom",
    subdistrictTh: "บ่อยาง",
    subdistrictEn: "Bor Yang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61150,
    district_code: 6103,
    province_code: 48,
    name: "สว่างอารมณ์ บ่อยาง อุทัยธานี 61150",
    name_th: "สว่างอารมณ์ บ่อยาง อุทัยธานี 61150",
    name_en: "Sawang Arom, Bor Yang, Uthai Thani, 61150",
  },
  {
    id: 610401,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "หนองฉาง",
    subdistrictEn: "Nong Chang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง หนองฉาง อุทัยธานี 61110",
    name_th: "หนองฉาง หนองฉาง อุทัยธานี 61110",
    name_en: "Nong Chang, Nong Chang, Uthai Thani, 61110",
  },
  {
    id: 610402,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "หนองยาง",
    subdistrictEn: "Nong Yang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง หนองยาง อุทัยธานี 61110",
    name_th: "หนองฉาง หนองยาง อุทัยธานี 61110",
    name_en: "Nong Chang, Nong Yang, Uthai Thani, 61110",
  },
  {
    id: 610403,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "หนองนางนวล",
    subdistrictEn: "Nong Nang Nuan",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง หนองนางนวล อุทัยธานี 61110",
    name_th: "หนองฉาง หนองนางนวล อุทัยธานี 61110",
    name_en: "Nong Chang, Nong Nang Nuan, Uthai Thani, 61110",
  },
  {
    id: 610404,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "หนองสรวง",
    subdistrictEn: "Nong Suang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง หนองสรวง อุทัยธานี 61110",
    name_th: "หนองฉาง หนองสรวง อุทัยธานี 61110",
    name_en: "Nong Chang, Nong Suang, Uthai Thani, 61110",
  },
  {
    id: 610405,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "บ้านเก่า",
    subdistrictEn: "Ban Kao",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง บ้านเก่า อุทัยธานี 61110",
    name_th: "หนองฉาง บ้านเก่า อุทัยธานี 61110",
    name_en: "Nong Chang, Ban Kao, Uthai Thani, 61110",
  },
  {
    id: 610406,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "อุทัยเก่า",
    subdistrictEn: "Uthai Kao",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง อุทัยเก่า อุทัยธานี 61110",
    name_th: "หนองฉาง อุทัยเก่า อุทัยธานี 61110",
    name_en: "Nong Chang, Uthai Kao, Uthai Thani, 61110",
  },
  {
    id: 610407,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "ทุ่งโพ",
    subdistrictEn: "Thung Pho",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง ทุ่งโพ อุทัยธานี 61110",
    name_th: "หนองฉาง ทุ่งโพ อุทัยธานี 61110",
    name_en: "Nong Chang, Thung Pho, Uthai Thani, 61110",
  },
  {
    id: 610408,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "ทุ่งพง",
    subdistrictEn: "Thung Phong",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง ทุ่งพง อุทัยธานี 61110",
    name_th: "หนองฉาง ทุ่งพง อุทัยธานี 61110",
    name_en: "Nong Chang, Thung Phong, Uthai Thani, 61110",
  },
  {
    id: 610409,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "เขาบางแกรก",
    subdistrictEn: "Khao Bang Kraek",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61170,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง เขาบางแกรก อุทัยธานี 61170",
    name_th: "หนองฉาง เขาบางแกรก อุทัยธานี 61170",
    name_en: "Nong Chang, Khao Bang Kraek, Uthai Thani, 61170",
  },
  {
    id: 610410,
    districtTh: "หนองฉาง",
    districtEn: "Nong Chang",
    subdistrictTh: "เขากวางทอง",
    subdistrictEn: "Khao Kwang Thong",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61110,
    district_code: 6104,
    province_code: 48,
    name: "หนองฉาง เขากวางทอง อุทัยธานี 61110",
    name_th: "หนองฉาง เขากวางทอง อุทัยธานี 61110",
    name_en: "Nong Chang, Khao Kwang Thong, Uthai Thani, 61110",
  },
  {
    id: 610501,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "หนองขาหย่าง",
    subdistrictEn: "Nong Khayang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง หนองขาหย่าง อุทัยธานี 61130",
    name_th: "หนองขาหย่าง หนองขาหย่าง อุทัยธานี 61130",
    name_en: "Nong Khayang, Nong Khayang, Uthai Thani, 61130",
  },
  {
    id: 610502,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง หนองไผ่ อุทัยธานี 61130",
    name_th: "หนองขาหย่าง หนองไผ่ อุทัยธานี 61130",
    name_en: "Nong Khayang, Nong Phai, Uthai Thani, 61130",
  },
  {
    id: 610503,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "ดอนกลอย",
    subdistrictEn: "Don Kloi",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง ดอนกลอย อุทัยธานี 61130",
    name_th: "หนองขาหย่าง ดอนกลอย อุทัยธานี 61130",
    name_en: "Nong Khayang, Don Kloi, Uthai Thani, 61130",
  },
  {
    id: 610504,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "ห้วยรอบ",
    subdistrictEn: "Huai Rop",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง ห้วยรอบ อุทัยธานี 61130",
    name_th: "หนองขาหย่าง ห้วยรอบ อุทัยธานี 61130",
    name_en: "Nong Khayang, Huai Rop, Uthai Thani, 61130",
  },
  {
    id: 610505,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "ทุ่งพึ่ง",
    subdistrictEn: "Thung Phueng",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง ทุ่งพึ่ง อุทัยธานี 61130",
    name_th: "หนองขาหย่าง ทุ่งพึ่ง อุทัยธานี 61130",
    name_en: "Nong Khayang, Thung Phueng, Uthai Thani, 61130",
  },
  {
    id: 610506,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "ท่าโพ",
    subdistrictEn: "Tha Pho",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง ท่าโพ อุทัยธานี 61130",
    name_th: "หนองขาหย่าง ท่าโพ อุทัยธานี 61130",
    name_en: "Nong Khayang, Tha Pho, Uthai Thani, 61130",
  },
  {
    id: 610507,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "หมกแถว",
    subdistrictEn: "Mok Thaeo",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง หมกแถว อุทัยธานี 61130",
    name_th: "หนองขาหย่าง หมกแถว อุทัยธานี 61130",
    name_en: "Nong Khayang, Mok Thaeo, Uthai Thani, 61130",
  },
  {
    id: 610508,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "หลุมเข้า",
    subdistrictEn: "Lum Khao",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง หลุมเข้า อุทัยธานี 61130",
    name_th: "หนองขาหย่าง หลุมเข้า อุทัยธานี 61130",
    name_en: "Nong Khayang, Lum Khao, Uthai Thani, 61130",
  },
  {
    id: 610509,
    districtTh: "หนองขาหย่าง",
    districtEn: "Nong Khayang",
    subdistrictTh: "ดงขวาง",
    subdistrictEn: "Dong Kwang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61130,
    district_code: 6105,
    province_code: 48,
    name: "หนองขาหย่าง ดงขวาง อุทัยธานี 61130",
    name_th: "หนองขาหย่าง ดงขวาง อุทัยธานี 61130",
    name_en: "Nong Khayang, Dong Kwang, Uthai Thani, 61130",
  },
  {
    id: 610601,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "บ้านไร่",
    subdistrictEn: "Ban Rai",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61140,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ บ้านไร่ อุทัยธานี 61140",
    name_th: "บ้านไร่ บ้านไร่ อุทัยธานี 61140",
    name_en: "Ban Rai, Ban Rai, Uthai Thani, 61140",
  },
  {
    id: 610602,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "ทัพหลวง",
    subdistrictEn: "Thap Luang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61140,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ ทัพหลวง อุทัยธานี 61140",
    name_th: "บ้านไร่ ทัพหลวง อุทัยธานี 61140",
    name_en: "Ban Rai, Thap Luang, Uthai Thani, 61140",
  },
  {
    id: 610603,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "ห้วยแห้ง",
    subdistrictEn: "Huai Haeng",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61140,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ ห้วยแห้ง อุทัยธานี 61140",
    name_th: "บ้านไร่ ห้วยแห้ง อุทัยธานี 61140",
    name_en: "Ban Rai, Huai Haeng, Uthai Thani, 61140",
  },
  {
    id: 610604,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "คอกควาย",
    subdistrictEn: "Khok Khwai",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61140,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ คอกควาย อุทัยธานี 61140",
    name_th: "บ้านไร่ คอกควาย อุทัยธานี 61140",
    name_en: "Ban Rai, Khok Khwai, Uthai Thani, 61140",
  },
  {
    id: 610605,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "วังหิน",
    subdistrictEn: "Wang Hin",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61180,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ วังหิน อุทัยธานี 61180",
    name_th: "บ้านไร่ วังหิน อุทัยธานี 61180",
    name_en: "Ban Rai, Wang Hin, Uthai Thani, 61180",
  },
  {
    id: 610606,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "เมืองการุ้ง",
    subdistrictEn: "Mueang Ka Rung",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61180,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ เมืองการุ้ง อุทัยธานี 61180",
    name_th: "บ้านไร่ เมืองการุ้ง อุทัยธานี 61180",
    name_en: "Ban Rai, Mueang Ka Rung, Uthai Thani, 61180",
  },
  {
    id: 610607,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "แก่นมะกรูด",
    subdistrictEn: "Kaen Makrut",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61140,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ แก่นมะกรูด อุทัยธานี 61140",
    name_th: "บ้านไร่ แก่นมะกรูด อุทัยธานี 61140",
    name_en: "Ban Rai, Kaen Makrut, Uthai Thani, 61140",
  },
  {
    id: 610609,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "หนองจอก",
    subdistrictEn: "Nong Chok",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61180,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ หนองจอก อุทัยธานี 61180",
    name_th: "บ้านไร่ หนองจอก อุทัยธานี 61180",
    name_en: "Ban Rai, Nong Chok, Uthai Thani, 61180",
  },
  {
    id: 610610,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "หูช้าง",
    subdistrictEn: "Hu Chang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61180,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ หูช้าง อุทัยธานี 61180",
    name_th: "บ้านไร่ หูช้าง อุทัยธานี 61180",
    name_en: "Ban Rai, Hu Chang, Uthai Thani, 61180",
  },
  {
    id: 610611,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "บ้านบึง",
    subdistrictEn: "Ban Bueng",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61140,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ บ้านบึง อุทัยธานี 61140",
    name_th: "บ้านไร่ บ้านบึง อุทัยธานี 61140",
    name_en: "Ban Rai, Ban Bueng, Uthai Thani, 61140",
  },
  {
    id: 610612,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "บ้านใหม่คลองเคียน",
    subdistrictEn: "Ban Mai Khlong Khian",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61180,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ บ้านใหม่คลองเคียน อุทัยธานี 61180",
    name_th: "บ้านไร่ บ้านใหม่คลองเคียน อุทัยธานี 61180",
    name_en: "Ban Rai, Ban Mai Khlong Khian, Uthai Thani, 61180",
  },
  {
    id: 610613,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "หนองบ่มกล้วย",
    subdistrictEn: "Nong Bom Kluai",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61180,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ หนองบ่มกล้วย อุทัยธานี 61180",
    name_th: "บ้านไร่ หนองบ่มกล้วย อุทัยธานี 61180",
    name_en: "Ban Rai, Nong Bom Kluai, Uthai Thani, 61180",
  },
  {
    id: 610614,
    districtTh: "บ้านไร่",
    districtEn: "Ban Rai",
    subdistrictTh: "เจ้าวัด",
    subdistrictEn: "Chao Wat",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61140,
    district_code: 6106,
    province_code: 48,
    name: "บ้านไร่ เจ้าวัด อุทัยธานี 61140",
    name_th: "บ้านไร่ เจ้าวัด อุทัยธานี 61140",
    name_en: "Ban Rai, Chao Wat, Uthai Thani, 61140",
  },
  {
    id: 610701,
    districtTh: "ลานสัก",
    districtEn: "Lan Sak",
    subdistrictTh: "ลานสัก",
    subdistrictEn: "Lan Sak",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61160,
    district_code: 6107,
    province_code: 48,
    name: "ลานสัก ลานสัก อุทัยธานี 61160",
    name_th: "ลานสัก ลานสัก อุทัยธานี 61160",
    name_en: "Lan Sak, Lan Sak, Uthai Thani, 61160",
  },
  {
    id: 610702,
    districtTh: "ลานสัก",
    districtEn: "Lan Sak",
    subdistrictTh: "ประดู่ยืน",
    subdistrictEn: "Pradu Yuen",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61160,
    district_code: 6107,
    province_code: 48,
    name: "ลานสัก ประดู่ยืน อุทัยธานี 61160",
    name_th: "ลานสัก ประดู่ยืน อุทัยธานี 61160",
    name_en: "Lan Sak, Pradu Yuen, Uthai Thani, 61160",
  },
  {
    id: 610703,
    districtTh: "ลานสัก",
    districtEn: "Lan Sak",
    subdistrictTh: "ป่าอ้อ",
    subdistrictEn: "Pa O",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61160,
    district_code: 6107,
    province_code: 48,
    name: "ลานสัก ป่าอ้อ อุทัยธานี 61160",
    name_th: "ลานสัก ป่าอ้อ อุทัยธานี 61160",
    name_en: "Lan Sak, Pa O, Uthai Thani, 61160",
  },
  {
    id: 610704,
    districtTh: "ลานสัก",
    districtEn: "Lan Sak",
    subdistrictTh: "ระบำ",
    subdistrictEn: "Rabam",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61160,
    district_code: 6107,
    province_code: 48,
    name: "ลานสัก ระบำ อุทัยธานี 61160",
    name_th: "ลานสัก ระบำ อุทัยธานี 61160",
    name_en: "Lan Sak, Rabam, Uthai Thani, 61160",
  },
  {
    id: 610705,
    districtTh: "ลานสัก",
    districtEn: "Lan Sak",
    subdistrictTh: "น้ำรอบ",
    subdistrictEn: "Nam Rop",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61160,
    district_code: 6107,
    province_code: 48,
    name: "ลานสัก น้ำรอบ อุทัยธานี 61160",
    name_th: "ลานสัก น้ำรอบ อุทัยธานี 61160",
    name_en: "Lan Sak, Nam Rop, Uthai Thani, 61160",
  },
  {
    id: 610706,
    districtTh: "ลานสัก",
    districtEn: "Lan Sak",
    subdistrictTh: "ทุ่งนางาม",
    subdistrictEn: "Thung Na Ngam",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61160,
    district_code: 6107,
    province_code: 48,
    name: "ลานสัก ทุ่งนางาม อุทัยธานี 61160",
    name_th: "ลานสัก ทุ่งนางาม อุทัยธานี 61160",
    name_en: "Lan Sak, Thung Na Ngam, Uthai Thani, 61160",
  },
  {
    id: 610801,
    districtTh: "ห้วยคต",
    districtEn: "Huai Khot",
    subdistrictTh: "สุขฤทัย",
    subdistrictEn: "Suk Ruethai",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61170,
    district_code: 6108,
    province_code: 48,
    name: "ห้วยคต สุขฤทัย อุทัยธานี 61170",
    name_th: "ห้วยคต สุขฤทัย อุทัยธานี 61170",
    name_en: "Huai Khot, Suk Ruethai, Uthai Thani, 61170",
  },
  {
    id: 610802,
    districtTh: "ห้วยคต",
    districtEn: "Huai Khot",
    subdistrictTh: "ทองหลาง",
    subdistrictEn: "Thonglang",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61170,
    district_code: 6108,
    province_code: 48,
    name: "ห้วยคต ทองหลาง อุทัยธานี 61170",
    name_th: "ห้วยคต ทองหลาง อุทัยธานี 61170",
    name_en: "Huai Khot, Thonglang, Uthai Thani, 61170",
  },
  {
    id: 610803,
    districtTh: "ห้วยคต",
    districtEn: "Huai Khot",
    subdistrictTh: "ห้วยคต",
    subdistrictEn: "Huai Khot",
    provinceTh: "อุทัยธานี",
    provinceEn: "Uthai Thani",
    zipcode: 61170,
    district_code: 6108,
    province_code: 48,
    name: "ห้วยคต ห้วยคต อุทัยธานี 61170",
    name_th: "ห้วยคต ห้วยคต อุทัยธานี 61170",
    name_en: "Huai Khot, Huai Khot, Uthai Thani, 61170",
  },
  {
    id: 620101,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร ในเมือง กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร ในเมือง กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Nai Mueang, Kamphaeng Phet, 62000",
  },
  {
    id: 620102,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "ไตรตรึงษ์",
    subdistrictEn: "Trai Trueng",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62160,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร ไตรตรึงษ์ กำแพงเพชร 62160",
    name_th: "เมืองกำแพงเพชร ไตรตรึงษ์ กำแพงเพชร 62160",
    name_en: "Mueang Kamphaeng Phet, Trai Trueng, Kamphaeng Phet, 62160",
  },
  {
    id: 620103,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "อ่างทอง",
    subdistrictEn: "Ang Thong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร อ่างทอง กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร อ่างทอง กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Ang Thong, Kamphaeng Phet, 62000",
  },
  {
    id: 620104,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "นาบ่อคำ",
    subdistrictEn: "Na Bo Kham",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร นาบ่อคำ กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร นาบ่อคำ กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Na Bo Kham, Kamphaeng Phet, 62000",
  },
  {
    id: 620105,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "นครชุม",
    subdistrictEn: "Nakhon Chum",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร นครชุม กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร นครชุม กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Nakhon Chum, Kamphaeng Phet, 62000",
  },
  {
    id: 620106,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "ทรงธรรม",
    subdistrictEn: "Song Tham",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร ทรงธรรม กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร ทรงธรรม กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Song Tham, Kamphaeng Phet, 62000",
  },
  {
    id: 620107,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "ลานดอกไม้",
    subdistrictEn: "Lan Dokmai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร ลานดอกไม้ กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร ลานดอกไม้ กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Lan Dokmai, Kamphaeng Phet, 62000",
  },
  {
    id: 620110,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "หนองปลิง",
    subdistrictEn: "Nong Pling",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร หนองปลิง กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร หนองปลิง กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Nong Pling, Kamphaeng Phet, 62000",
  },
  {
    id: 620111,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "คณฑี",
    subdistrictEn: "Khonthi",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร คณฑี กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร คณฑี กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Khonthi, Kamphaeng Phet, 62000",
  },
  {
    id: 620112,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "นิคมทุ่งโพธิ์ทะเล",
    subdistrictEn: "Nikhom Thung Pho Thale",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร นิคมทุ่งโพธิ์ทะเล กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร นิคมทุ่งโพธิ์ทะเล กำแพงเพชร 62000",
    name_en:
      "Mueang Kamphaeng Phet, Nikhom Thung Pho Thale, Kamphaeng Phet, 62000",
  },
  {
    id: 620113,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "เทพนคร",
    subdistrictEn: "Thep Nakhon",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร เทพนคร กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร เทพนคร กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Thep Nakhon, Kamphaeng Phet, 62000",
  },
  {
    id: 620114,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร วังทอง กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร วังทอง กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Wang Thong, Kamphaeng Phet, 62000",
  },
  {
    id: 620115,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "ท่าขุนราม",
    subdistrictEn: "Tha Khun Ram",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร ท่าขุนราม กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร ท่าขุนราม กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Tha Khun Ram, Kamphaeng Phet, 62000",
  },
  {
    id: 620117,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "คลองแม่ลาย",
    subdistrictEn: "Khlong Mae Lai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร คลองแม่ลาย กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร คลองแม่ลาย กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Khlong Mae Lai, Kamphaeng Phet, 62000",
  },
  {
    id: 620118,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "ธำมรงค์",
    subdistrictEn: "Thammarong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62160,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร ธำมรงค์ กำแพงเพชร 62160",
    name_th: "เมืองกำแพงเพชร ธำมรงค์ กำแพงเพชร 62160",
    name_en: "Mueang Kamphaeng Phet, Thammarong, Kamphaeng Phet, 62160",
  },
  {
    id: 620119,
    districtTh: "เมืองกำแพงเพชร",
    districtEn: "Mueang Kamphaeng Phet",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6201,
    province_code: 49,
    name: "เมืองกำแพงเพชร สระแก้ว กำแพงเพชร 62000",
    name_th: "เมืองกำแพงเพชร สระแก้ว กำแพงเพชร 62000",
    name_en: "Mueang Kamphaeng Phet, Sa Kaeo, Kamphaeng Phet, 62000",
  },
  {
    id: 620201,
    districtTh: "ไทรงาม",
    districtEn: "Sai Ngam",
    subdistrictTh: "ไทรงาม",
    subdistrictEn: "Sai Ngam",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62150,
    district_code: 6202,
    province_code: 49,
    name: "ไทรงาม ไทรงาม กำแพงเพชร 62150",
    name_th: "ไทรงาม ไทรงาม กำแพงเพชร 62150",
    name_en: "Sai Ngam, Sai Ngam, Kamphaeng Phet, 62150",
  },
  {
    id: 620202,
    districtTh: "ไทรงาม",
    districtEn: "Sai Ngam",
    subdistrictTh: "หนองคล้า",
    subdistrictEn: "Nong Khla",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62150,
    district_code: 6202,
    province_code: 49,
    name: "ไทรงาม หนองคล้า กำแพงเพชร 62150",
    name_th: "ไทรงาม หนองคล้า กำแพงเพชร 62150",
    name_en: "Sai Ngam, Nong Khla, Kamphaeng Phet, 62150",
  },
  {
    id: 620203,
    districtTh: "ไทรงาม",
    districtEn: "Sai Ngam",
    subdistrictTh: "หนองทอง",
    subdistrictEn: "Nong Thong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62150,
    district_code: 6202,
    province_code: 49,
    name: "ไทรงาม หนองทอง กำแพงเพชร 62150",
    name_th: "ไทรงาม หนองทอง กำแพงเพชร 62150",
    name_en: "Sai Ngam, Nong Thong, Kamphaeng Phet, 62150",
  },
  {
    id: 620204,
    districtTh: "ไทรงาม",
    districtEn: "Sai Ngam",
    subdistrictTh: "หนองไม้กอง",
    subdistrictEn: "Nong Mai Kong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62150,
    district_code: 6202,
    province_code: 49,
    name: "ไทรงาม หนองไม้กอง กำแพงเพชร 62150",
    name_th: "ไทรงาม หนองไม้กอง กำแพงเพชร 62150",
    name_en: "Sai Ngam, Nong Mai Kong, Kamphaeng Phet, 62150",
  },
  {
    id: 620205,
    districtTh: "ไทรงาม",
    districtEn: "Sai Ngam",
    subdistrictTh: "มหาชัย",
    subdistrictEn: "Maha Chai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62150,
    district_code: 6202,
    province_code: 49,
    name: "ไทรงาม มหาชัย กำแพงเพชร 62150",
    name_th: "ไทรงาม มหาชัย กำแพงเพชร 62150",
    name_en: "Sai Ngam, Maha Chai, Kamphaeng Phet, 62150",
  },
  {
    id: 620206,
    districtTh: "ไทรงาม",
    districtEn: "Sai Ngam",
    subdistrictTh: "พานทอง",
    subdistrictEn: "Phan Thong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62150,
    district_code: 6202,
    province_code: 49,
    name: "ไทรงาม พานทอง กำแพงเพชร 62150",
    name_th: "ไทรงาม พานทอง กำแพงเพชร 62150",
    name_en: "Sai Ngam, Phan Thong, Kamphaeng Phet, 62150",
  },
  {
    id: 620207,
    districtTh: "ไทรงาม",
    districtEn: "Sai Ngam",
    subdistrictTh: "หนองแม่แตง",
    subdistrictEn: "Nong Mae Taeng",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62150,
    district_code: 6202,
    province_code: 49,
    name: "ไทรงาม หนองแม่แตง กำแพงเพชร 62150",
    name_th: "ไทรงาม หนองแม่แตง กำแพงเพชร 62150",
    name_en: "Sai Ngam, Nong Mae Taeng, Kamphaeng Phet, 62150",
  },
  {
    id: 620301,
    districtTh: "คลองลาน",
    districtEn: "Khlong Lan",
    subdistrictTh: "คลองน้ำไหล",
    subdistrictEn: "Khlong Nam Lai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62180,
    district_code: 6203,
    province_code: 49,
    name: "คลองลาน คลองน้ำไหล กำแพงเพชร 62180",
    name_th: "คลองลาน คลองน้ำไหล กำแพงเพชร 62180",
    name_en: "Khlong Lan, Khlong Nam Lai, Kamphaeng Phet, 62180",
  },
  {
    id: 620302,
    districtTh: "คลองลาน",
    districtEn: "Khlong Lan",
    subdistrictTh: "โป่งน้ำร้อน",
    subdistrictEn: "Pong Nam Ron",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62180,
    district_code: 6203,
    province_code: 49,
    name: "คลองลาน โป่งน้ำร้อน กำแพงเพชร 62180",
    name_th: "คลองลาน โป่งน้ำร้อน กำแพงเพชร 62180",
    name_en: "Khlong Lan, Pong Nam Ron, Kamphaeng Phet, 62180",
  },
  {
    id: 620303,
    districtTh: "คลองลาน",
    districtEn: "Khlong Lan",
    subdistrictTh: "คลองลานพัฒนา",
    subdistrictEn: "Khlong Lan Phatthana",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62180,
    district_code: 6203,
    province_code: 49,
    name: "คลองลาน คลองลานพัฒนา กำแพงเพชร 62180",
    name_th: "คลองลาน คลองลานพัฒนา กำแพงเพชร 62180",
    name_en: "Khlong Lan, Khlong Lan Phatthana, Kamphaeng Phet, 62180",
  },
  {
    id: 620304,
    districtTh: "คลองลาน",
    districtEn: "Khlong Lan",
    subdistrictTh: "สักงาม",
    subdistrictEn: "Sak Ngam",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62180,
    district_code: 6203,
    province_code: 49,
    name: "คลองลาน สักงาม กำแพงเพชร 62180",
    name_th: "คลองลาน สักงาม กำแพงเพชร 62180",
    name_en: "Khlong Lan, Sak Ngam, Kamphaeng Phet, 62180",
  },
  {
    id: 620403,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "ยางสูง",
    subdistrictEn: "Yang Sung",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62130,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี ยางสูง กำแพงเพชร 62130",
    name_th: "ขาณุวรลักษบุรี ยางสูง กำแพงเพชร 62130",
    name_en: "Khanu Woralaksaburi, Yang Sung, Kamphaeng Phet, 62130",
  },
  {
    id: 620404,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "ป่าพุทรา",
    subdistrictEn: "Pa Phutsa",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62130,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี ป่าพุทรา กำแพงเพชร 62130",
    name_th: "ขาณุวรลักษบุรี ป่าพุทรา กำแพงเพชร 62130",
    name_en: "Khanu Woralaksaburi, Pa Phutsa, Kamphaeng Phet, 62130",
  },
  {
    id: 620405,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "แสนตอ",
    subdistrictEn: "Saen To",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62130,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี แสนตอ กำแพงเพชร 62130",
    name_th: "ขาณุวรลักษบุรี แสนตอ กำแพงเพชร 62130",
    name_en: "Khanu Woralaksaburi, Saen To, Kamphaeng Phet, 62130",
  },
  {
    id: 620406,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "สลกบาตร",
    subdistrictEn: "Salok Bat",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62140,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี สลกบาตร กำแพงเพชร 62140",
    name_th: "ขาณุวรลักษบุรี สลกบาตร กำแพงเพชร 62140",
    name_en: "Khanu Woralaksaburi, Salok Bat, Kamphaeng Phet, 62140",
  },
  {
    id: 620407,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "บ่อถ้ำ",
    subdistrictEn: "Bo Tham",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62140,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี บ่อถ้ำ กำแพงเพชร 62140",
    name_th: "ขาณุวรลักษบุรี บ่อถ้ำ กำแพงเพชร 62140",
    name_en: "Khanu Woralaksaburi, Bo Tham, Kamphaeng Phet, 62140",
  },
  {
    id: 620408,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "ดอนแตง",
    subdistrictEn: "Don Taeng",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62140,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี ดอนแตง กำแพงเพชร 62140",
    name_th: "ขาณุวรลักษบุรี ดอนแตง กำแพงเพชร 62140",
    name_en: "Khanu Woralaksaburi, Don Taeng, Kamphaeng Phet, 62140",
  },
  {
    id: 620409,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "วังชะพลู",
    subdistrictEn: "Wang Chaphlu",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62140,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี วังชะพลู กำแพงเพชร 62140",
    name_th: "ขาณุวรลักษบุรี วังชะพลู กำแพงเพชร 62140",
    name_en: "Khanu Woralaksaburi, Wang Chaphlu, Kamphaeng Phet, 62140",
  },
  {
    id: 620410,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "โค้งไผ่",
    subdistrictEn: "Khong Phai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62140,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี โค้งไผ่ กำแพงเพชร 62140",
    name_th: "ขาณุวรลักษบุรี โค้งไผ่ กำแพงเพชร 62140",
    name_en: "Khanu Woralaksaburi, Khong Phai, Kamphaeng Phet, 62140",
  },
  {
    id: 620411,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "ปางมะค่า",
    subdistrictEn: "Pang Makha",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62140,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี ปางมะค่า กำแพงเพชร 62140",
    name_th: "ขาณุวรลักษบุรี ปางมะค่า กำแพงเพชร 62140",
    name_en: "Khanu Woralaksaburi, Pang Makha, Kamphaeng Phet, 62140",
  },
  {
    id: 620412,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "วังหามแห",
    subdistrictEn: "Wang Ham Hae",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62140,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี วังหามแห กำแพงเพชร 62140",
    name_th: "ขาณุวรลักษบุรี วังหามแห กำแพงเพชร 62140",
    name_en: "Khanu Woralaksaburi, Wang Ham Hae, Kamphaeng Phet, 62140",
  },
  {
    id: 620413,
    districtTh: "ขาณุวรลักษบุรี",
    districtEn: "Khanu Woralaksaburi",
    subdistrictTh: "เกาะตาล",
    subdistrictEn: "Ko Tan",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62130,
    district_code: 6204,
    province_code: 49,
    name: "ขาณุวรลักษบุรี เกาะตาล กำแพงเพชร 62130",
    name_th: "ขาณุวรลักษบุรี เกาะตาล กำแพงเพชร 62130",
    name_en: "Khanu Woralaksaburi, Ko Tan, Kamphaeng Phet, 62130",
  },
  {
    id: 620501,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "คลองขลุง",
    subdistrictEn: "Khlong Khlung",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง คลองขลุง กำแพงเพชร 62120",
    name_th: "คลองขลุง คลองขลุง กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Khlong Khlung, Kamphaeng Phet, 62120",
  },
  {
    id: 620502,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "ท่ามะเขือ",
    subdistrictEn: "Tha Makhuea",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง ท่ามะเขือ กำแพงเพชร 62120",
    name_th: "คลองขลุง ท่ามะเขือ กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Tha Makhuea, Kamphaeng Phet, 62120",
  },
  {
    id: 620504,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "ท่าพุทรา",
    subdistrictEn: "Tha Phutsa",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง ท่าพุทรา กำแพงเพชร 62120",
    name_th: "คลองขลุง ท่าพุทรา กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Tha Phutsa, Kamphaeng Phet, 62120",
  },
  {
    id: 620505,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "แม่ลาด",
    subdistrictEn: "Mae Lat",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง แม่ลาด กำแพงเพชร 62120",
    name_th: "คลองขลุง แม่ลาด กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Mae Lat, Kamphaeng Phet, 62120",
  },
  {
    id: 620506,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "วังยาง",
    subdistrictEn: "Wang Yang",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง วังยาง กำแพงเพชร 62120",
    name_th: "คลองขลุง วังยาง กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Wang Yang, Kamphaeng Phet, 62120",
  },
  {
    id: 620507,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "วังแขม",
    subdistrictEn: "Wang Khaem",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง วังแขม กำแพงเพชร 62120",
    name_th: "คลองขลุง วังแขม กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Wang Khaem, Kamphaeng Phet, 62120",
  },
  {
    id: 620508,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "หัวถนน",
    subdistrictEn: "Hua Thanon",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง หัวถนน กำแพงเพชร 62120",
    name_th: "คลองขลุง หัวถนน กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Hua Thanon, Kamphaeng Phet, 62120",
  },
  {
    id: 620509,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "วังไทร",
    subdistrictEn: "Wang Sai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง วังไทร กำแพงเพชร 62120",
    name_th: "คลองขลุง วังไทร กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Wang Sai, Kamphaeng Phet, 62120",
  },
  {
    id: 620513,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "วังบัว",
    subdistrictEn: "Wang Bua",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง วังบัว กำแพงเพชร 62120",
    name_th: "คลองขลุง วังบัว กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Wang Bua, Kamphaeng Phet, 62120",
  },
  {
    id: 620516,
    districtTh: "คลองขลุง",
    districtEn: "Khlong Khlung",
    subdistrictTh: "คลองสมบูรณ์",
    subdistrictEn: "Khlong Sombun",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6205,
    province_code: 49,
    name: "คลองขลุง คลองสมบูรณ์ กำแพงเพชร 62120",
    name_th: "คลองขลุง คลองสมบูรณ์ กำแพงเพชร 62120",
    name_en: "Khlong Khlung, Khlong Sombun, Kamphaeng Phet, 62120",
  },
  {
    id: 620601,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "พรานกระต่าย",
    subdistrictEn: "Phran Kratai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย พรานกระต่าย กำแพงเพชร 62110",
    name_th: "พรานกระต่าย พรานกระต่าย กำแพงเพชร 62110",
    name_en: "Phran Kratai, Phran Kratai, Kamphaeng Phet, 62110",
  },
  {
    id: 620602,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "หนองหัววัว",
    subdistrictEn: "Nong Hua Wua",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย หนองหัววัว กำแพงเพชร 62110",
    name_th: "พรานกระต่าย หนองหัววัว กำแพงเพชร 62110",
    name_en: "Phran Kratai, Nong Hua Wua, Kamphaeng Phet, 62110",
  },
  {
    id: 620603,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "ท่าไม้",
    subdistrictEn: "Tha Mai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย ท่าไม้ กำแพงเพชร 62110",
    name_th: "พรานกระต่าย ท่าไม้ กำแพงเพชร 62110",
    name_en: "Phran Kratai, Tha Mai, Kamphaeng Phet, 62110",
  },
  {
    id: 620604,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "วังควง",
    subdistrictEn: "Wang Khuang",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย วังควง กำแพงเพชร 62110",
    name_th: "พรานกระต่าย วังควง กำแพงเพชร 62110",
    name_en: "Phran Kratai, Wang Khuang, Kamphaeng Phet, 62110",
  },
  {
    id: 620605,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "วังตะแบก",
    subdistrictEn: "Wang Tabaek",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย วังตะแบก กำแพงเพชร 62110",
    name_th: "พรานกระต่าย วังตะแบก กำแพงเพชร 62110",
    name_en: "Phran Kratai, Wang Tabaek, Kamphaeng Phet, 62110",
  },
  {
    id: 620606,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "เขาคีริส",
    subdistrictEn: "Khao Khirit",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย เขาคีริส กำแพงเพชร 62110",
    name_th: "พรานกระต่าย เขาคีริส กำแพงเพชร 62110",
    name_en: "Phran Kratai, Khao Khirit, Kamphaeng Phet, 62110",
  },
  {
    id: 620607,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "คุยบ้านโอง",
    subdistrictEn: "Khui Ban Ong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย คุยบ้านโอง กำแพงเพชร 62110",
    name_th: "พรานกระต่าย คุยบ้านโอง กำแพงเพชร 62110",
    name_en: "Phran Kratai, Khui Ban Ong, Kamphaeng Phet, 62110",
  },
  {
    id: 620608,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "คลองพิไกร",
    subdistrictEn: "Khlong Phikrai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย คลองพิไกร กำแพงเพชร 62110",
    name_th: "พรานกระต่าย คลองพิไกร กำแพงเพชร 62110",
    name_en: "Phran Kratai, Khlong Phikrai, Kamphaeng Phet, 62110",
  },
  {
    id: 620609,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "ถ้ำกระต่ายทอง",
    subdistrictEn: "Tham Kratai Thong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย ถ้ำกระต่ายทอง กำแพงเพชร 62110",
    name_th: "พรานกระต่าย ถ้ำกระต่ายทอง กำแพงเพชร 62110",
    name_en: "Phran Kratai, Tham Kratai Thong, Kamphaeng Phet, 62110",
  },
  {
    id: 620610,
    districtTh: "พรานกระต่าย",
    districtEn: "Phran Kratai",
    subdistrictTh: "ห้วยยั้ง",
    subdistrictEn: "Huai Yang",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62110,
    district_code: 6206,
    province_code: 49,
    name: "พรานกระต่าย ห้วยยั้ง กำแพงเพชร 62110",
    name_th: "พรานกระต่าย ห้วยยั้ง กำแพงเพชร 62110",
    name_en: "Phran Kratai, Huai Yang, Kamphaeng Phet, 62110",
  },
  {
    id: 620701,
    districtTh: "ลานกระบือ",
    districtEn: "Lan Krabue",
    subdistrictTh: "ลานกระบือ",
    subdistrictEn: "Lan Krabue",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62170,
    district_code: 6207,
    province_code: 49,
    name: "ลานกระบือ ลานกระบือ กำแพงเพชร 62170",
    name_th: "ลานกระบือ ลานกระบือ กำแพงเพชร 62170",
    name_en: "Lan Krabue, Lan Krabue, Kamphaeng Phet, 62170",
  },
  {
    id: 620702,
    districtTh: "ลานกระบือ",
    districtEn: "Lan Krabue",
    subdistrictTh: "ช่องลม",
    subdistrictEn: "Chong Lom",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62170,
    district_code: 6207,
    province_code: 49,
    name: "ลานกระบือ ช่องลม กำแพงเพชร 62170",
    name_th: "ลานกระบือ ช่องลม กำแพงเพชร 62170",
    name_en: "Lan Krabue, Chong Lom, Kamphaeng Phet, 62170",
  },
  {
    id: 620703,
    districtTh: "ลานกระบือ",
    districtEn: "Lan Krabue",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62170,
    district_code: 6207,
    province_code: 49,
    name: "ลานกระบือ หนองหลวง กำแพงเพชร 62170",
    name_th: "ลานกระบือ หนองหลวง กำแพงเพชร 62170",
    name_en: "Lan Krabue, Nong Luang, Kamphaeng Phet, 62170",
  },
  {
    id: 620704,
    districtTh: "ลานกระบือ",
    districtEn: "Lan Krabue",
    subdistrictTh: "โนนพลวง",
    subdistrictEn: "Non Phluang",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62170,
    district_code: 6207,
    province_code: 49,
    name: "ลานกระบือ โนนพลวง กำแพงเพชร 62170",
    name_th: "ลานกระบือ โนนพลวง กำแพงเพชร 62170",
    name_en: "Lan Krabue, Non Phluang, Kamphaeng Phet, 62170",
  },
  {
    id: 620705,
    districtTh: "ลานกระบือ",
    districtEn: "Lan Krabue",
    subdistrictTh: "ประชาสุขสันต์",
    subdistrictEn: "Pracha Suk San",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62170,
    district_code: 6207,
    province_code: 49,
    name: "ลานกระบือ ประชาสุขสันต์ กำแพงเพชร 62170",
    name_th: "ลานกระบือ ประชาสุขสันต์ กำแพงเพชร 62170",
    name_en: "Lan Krabue, Pracha Suk San, Kamphaeng Phet, 62170",
  },
  {
    id: 620706,
    districtTh: "ลานกระบือ",
    districtEn: "Lan Krabue",
    subdistrictTh: "บึงทับแรต",
    subdistrictEn: "Bueng Thap Raet",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62170,
    district_code: 6207,
    province_code: 49,
    name: "ลานกระบือ บึงทับแรต กำแพงเพชร 62170",
    name_th: "ลานกระบือ บึงทับแรต กำแพงเพชร 62170",
    name_en: "Lan Krabue, Bueng Thap Raet, Kamphaeng Phet, 62170",
  },
  {
    id: 620707,
    districtTh: "ลานกระบือ",
    districtEn: "Lan Krabue",
    subdistrictTh: "จันทิมา",
    subdistrictEn: "Chanthima",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62170,
    district_code: 6207,
    province_code: 49,
    name: "ลานกระบือ จันทิมา กำแพงเพชร 62170",
    name_th: "ลานกระบือ จันทิมา กำแพงเพชร 62170",
    name_en: "Lan Krabue, Chanthima, Kamphaeng Phet, 62170",
  },
  {
    id: 620801,
    districtTh: "ทรายทองวัฒนา",
    districtEn: "Sai Thong Watthana",
    subdistrictTh: "ทุ่งทราย",
    subdistrictEn: "Thung Sai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62190,
    district_code: 6208,
    province_code: 49,
    name: "ทรายทองวัฒนา ทุ่งทราย กำแพงเพชร 62190",
    name_th: "ทรายทองวัฒนา ทุ่งทราย กำแพงเพชร 62190",
    name_en: "Sai Thong Watthana, Thung Sai, Kamphaeng Phet, 62190",
  },
  {
    id: 620802,
    districtTh: "ทรายทองวัฒนา",
    districtEn: "Sai Thong Watthana",
    subdistrictTh: "ทุ่งทอง",
    subdistrictEn: "Thung Thong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62190,
    district_code: 6208,
    province_code: 49,
    name: "ทรายทองวัฒนา ทุ่งทอง กำแพงเพชร 62190",
    name_th: "ทรายทองวัฒนา ทุ่งทอง กำแพงเพชร 62190",
    name_en: "Sai Thong Watthana, Thung Thong, Kamphaeng Phet, 62190",
  },
  {
    id: 620803,
    districtTh: "ทรายทองวัฒนา",
    districtEn: "Sai Thong Watthana",
    subdistrictTh: "ถาวรวัฒนา",
    subdistrictEn: "Thawon Watthana",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62190,
    district_code: 6208,
    province_code: 49,
    name: "ทรายทองวัฒนา ถาวรวัฒนา กำแพงเพชร 62190",
    name_th: "ทรายทองวัฒนา ถาวรวัฒนา กำแพงเพชร 62190",
    name_en: "Sai Thong Watthana, Thawon Watthana, Kamphaeng Phet, 62190",
  },
  {
    id: 620901,
    districtTh: "ปางศิลาทอง",
    districtEn: "Pang Sila Thong",
    subdistrictTh: "โพธิ์ทอง",
    subdistrictEn: "Pho Thong",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6209,
    province_code: 49,
    name: "ปางศิลาทอง โพธิ์ทอง กำแพงเพชร 62120",
    name_th: "ปางศิลาทอง โพธิ์ทอง กำแพงเพชร 62120",
    name_en: "Pang Sila Thong, Pho Thong, Kamphaeng Phet, 62120",
  },
  {
    id: 620902,
    districtTh: "ปางศิลาทอง",
    districtEn: "Pang Sila Thong",
    subdistrictTh: "หินดาต",
    subdistrictEn: "Hin Dat",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6209,
    province_code: 49,
    name: "ปางศิลาทอง หินดาต กำแพงเพชร 62120",
    name_th: "ปางศิลาทอง หินดาต กำแพงเพชร 62120",
    name_en: "Pang Sila Thong, Hin Dat, Kamphaeng Phet, 62120",
  },
  {
    id: 620903,
    districtTh: "ปางศิลาทอง",
    districtEn: "Pang Sila Thong",
    subdistrictTh: "ปางตาไว",
    subdistrictEn: "Pang Ta Wai",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62120,
    district_code: 6209,
    province_code: 49,
    name: "ปางศิลาทอง ปางตาไว กำแพงเพชร 62120",
    name_th: "ปางศิลาทอง ปางตาไว กำแพงเพชร 62120",
    name_en: "Pang Sila Thong, Pang Ta Wai, Kamphaeng Phet, 62120",
  },
  {
    id: 621001,
    districtTh: "บึงสามัคคี",
    districtEn: "Bueng Samakkhi",
    subdistrictTh: "บึงสามัคคี",
    subdistrictEn: "Bueng Samakkhi",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62210,
    district_code: 6210,
    province_code: 49,
    name: "บึงสามัคคี บึงสามัคคี กำแพงเพชร 62210",
    name_th: "บึงสามัคคี บึงสามัคคี กำแพงเพชร 62210",
    name_en: "Bueng Samakkhi, Bueng Samakkhi, Kamphaeng Phet, 62210",
  },
  {
    id: 621002,
    districtTh: "บึงสามัคคี",
    districtEn: "Bueng Samakkhi",
    subdistrictTh: "วังชะโอน",
    subdistrictEn: "Wang Cha-on",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62210,
    district_code: 6210,
    province_code: 49,
    name: "บึงสามัคคี วังชะโอน กำแพงเพชร 62210",
    name_th: "บึงสามัคคี วังชะโอน กำแพงเพชร 62210",
    name_en: "Bueng Samakkhi, Wang Cha-on, Kamphaeng Phet, 62210",
  },
  {
    id: 621003,
    districtTh: "บึงสามัคคี",
    districtEn: "Bueng Samakkhi",
    subdistrictTh: "ระหาน",
    subdistrictEn: "Rahan",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62210,
    district_code: 6210,
    province_code: 49,
    name: "บึงสามัคคี ระหาน กำแพงเพชร 62210",
    name_th: "บึงสามัคคี ระหาน กำแพงเพชร 62210",
    name_en: "Bueng Samakkhi, Rahan, Kamphaeng Phet, 62210",
  },
  {
    id: 621004,
    districtTh: "บึงสามัคคี",
    districtEn: "Bueng Samakkhi",
    subdistrictTh: "เทพนิมิต",
    subdistrictEn: "Thep Nimit",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62210,
    district_code: 6210,
    province_code: 49,
    name: "บึงสามัคคี เทพนิมิต กำแพงเพชร 62210",
    name_th: "บึงสามัคคี เทพนิมิต กำแพงเพชร 62210",
    name_en: "Bueng Samakkhi, Thep Nimit, Kamphaeng Phet, 62210",
  },
  {
    id: 621101,
    districtTh: "โกสัมพีนคร",
    districtEn: "Kosamphi Nakhon",
    subdistrictTh: "โกสัมพี",
    subdistrictEn: "Kosamphi",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6211,
    province_code: 49,
    name: "โกสัมพีนคร โกสัมพี กำแพงเพชร 62000",
    name_th: "โกสัมพีนคร โกสัมพี กำแพงเพชร 62000",
    name_en: "Kosamphi Nakhon, Kosamphi, Kamphaeng Phet, 62000",
  },
  {
    id: 621102,
    districtTh: "โกสัมพีนคร",
    districtEn: "Kosamphi Nakhon",
    subdistrictTh: "เพชรชมภู",
    subdistrictEn: "Phet Chomphu",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6211,
    province_code: 49,
    name: "โกสัมพีนคร เพชรชมภู กำแพงเพชร 62000",
    name_th: "โกสัมพีนคร เพชรชมภู กำแพงเพชร 62000",
    name_en: "Kosamphi Nakhon, Phet Chomphu, Kamphaeng Phet, 62000",
  },
  {
    id: 621103,
    districtTh: "โกสัมพีนคร",
    districtEn: "Kosamphi Nakhon",
    subdistrictTh: "ลานดอกไม้ตก",
    subdistrictEn: "Lan Dokmai Tok",
    provinceTh: "กำแพงเพชร",
    provinceEn: "Kamphaeng Phet",
    zipcode: 62000,
    district_code: 6211,
    province_code: 49,
    name: "โกสัมพีนคร ลานดอกไม้ตก กำแพงเพชร 62000",
    name_th: "โกสัมพีนคร ลานดอกไม้ตก กำแพงเพชร 62000",
    name_en: "Kosamphi Nakhon, Lan Dokmai Tok, Kamphaeng Phet, 62000",
  },
  {
    id: 630101,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "ระแหง",
    subdistrictEn: "Rahaeng",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก ระแหง ตาก 63000",
    name_th: "เมืองตาก ระแหง ตาก 63000",
    name_en: "Mueang Tak, Rahaeng, Tak, 63000",
  },
  {
    id: 630102,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก หนองหลวง ตาก 63000",
    name_th: "เมืองตาก หนองหลวง ตาก 63000",
    name_en: "Mueang Tak, Nong Luang, Tak, 63000",
  },
  {
    id: 630103,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "เชียงเงิน",
    subdistrictEn: "Chiang Ngoen",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก เชียงเงิน ตาก 63000",
    name_th: "เมืองตาก เชียงเงิน ตาก 63000",
    name_en: "Mueang Tak, Chiang Ngoen, Tak, 63000",
  },
  {
    id: 630104,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "หัวเดียด",
    subdistrictEn: "Hua Diat",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก หัวเดียด ตาก 63000",
    name_th: "เมืองตาก หัวเดียด ตาก 63000",
    name_en: "Mueang Tak, Hua Diat, Tak, 63000",
  },
  {
    id: 630105,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "หนองบัวเหนือ",
    subdistrictEn: "Nong Bua Nuea",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก หนองบัวเหนือ ตาก 63000",
    name_th: "เมืองตาก หนองบัวเหนือ ตาก 63000",
    name_en: "Mueang Tak, Nong Bua Nuea, Tak, 63000",
  },
  {
    id: 630106,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "ไม้งาม",
    subdistrictEn: "Mai Ngam",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก ไม้งาม ตาก 63000",
    name_th: "เมืองตาก ไม้งาม ตาก 63000",
    name_en: "Mueang Tak, Mai Ngam, Tak, 63000",
  },
  {
    id: 630107,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "โป่งแดง",
    subdistrictEn: "Pong Daeng",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก โป่งแดง ตาก 63000",
    name_th: "เมืองตาก โป่งแดง ตาก 63000",
    name_en: "Mueang Tak, Pong Daeng, Tak, 63000",
  },
  {
    id: 630108,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "น้ำรึม",
    subdistrictEn: "Nam Ruem",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก น้ำรึม ตาก 63000",
    name_th: "เมืองตาก น้ำรึม ตาก 63000",
    name_en: "Mueang Tak, Nam Ruem, Tak, 63000",
  },
  {
    id: 630109,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "วังหิน",
    subdistrictEn: "Wang Hin",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก วังหิน ตาก 63000",
    name_th: "เมืองตาก วังหิน ตาก 63000",
    name_en: "Mueang Tak, Wang Hin, Tak, 63000",
  },
  {
    id: 630111,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "แม่ท้อ",
    subdistrictEn: "Mae Tho",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก แม่ท้อ ตาก 63000",
    name_th: "เมืองตาก แม่ท้อ ตาก 63000",
    name_en: "Mueang Tak, Mae Tho, Tak, 63000",
  },
  {
    id: 630112,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "ป่ามะม่วง",
    subdistrictEn: "Pa Mamuang",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก ป่ามะม่วง ตาก 63000",
    name_th: "เมืองตาก ป่ามะม่วง ตาก 63000",
    name_en: "Mueang Tak, Pa Mamuang, Tak, 63000",
  },
  {
    id: 630113,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "หนองบัวใต้",
    subdistrictEn: "Nong Bua Tai",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก หนองบัวใต้ ตาก 63000",
    name_th: "เมืองตาก หนองบัวใต้ ตาก 63000",
    name_en: "Mueang Tak, Nong Bua Tai, Tak, 63000",
  },
  {
    id: 630114,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "วังประจบ",
    subdistrictEn: "Wang Prachop",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก วังประจบ ตาก 63000",
    name_th: "เมืองตาก วังประจบ ตาก 63000",
    name_en: "Mueang Tak, Wang Prachop, Tak, 63000",
  },
  {
    id: 630115,
    districtTh: "เมืองตาก",
    districtEn: "Mueang Tak",
    subdistrictTh: "ตลุกกลางทุ่ง",
    subdistrictEn: "Taluk Klang Thung",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6301,
    province_code: 50,
    name: "เมืองตาก ตลุกกลางทุ่ง ตาก 63000",
    name_th: "เมืองตาก ตลุกกลางทุ่ง ตาก 63000",
    name_en: "Mueang Tak, Taluk Klang Thung, Tak, 63000",
  },
  {
    id: 630201,
    districtTh: "บ้านตาก",
    districtEn: "Ban Tak",
    subdistrictTh: "ตากออก",
    subdistrictEn: "Tak Ok",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63120,
    district_code: 6302,
    province_code: 50,
    name: "บ้านตาก ตากออก ตาก 63120",
    name_th: "บ้านตาก ตากออก ตาก 63120",
    name_en: "Ban Tak, Tak Ok, Tak, 63120",
  },
  {
    id: 630202,
    districtTh: "บ้านตาก",
    districtEn: "Ban Tak",
    subdistrictTh: "สมอโคน",
    subdistrictEn: "Samo Khon",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63120,
    district_code: 6302,
    province_code: 50,
    name: "บ้านตาก สมอโคน ตาก 63120",
    name_th: "บ้านตาก สมอโคน ตาก 63120",
    name_en: "Ban Tak, Samo Khon, Tak, 63120",
  },
  {
    id: 630203,
    districtTh: "บ้านตาก",
    districtEn: "Ban Tak",
    subdistrictTh: "แม่สลิด",
    subdistrictEn: "Mae Salit",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63120,
    district_code: 6302,
    province_code: 50,
    name: "บ้านตาก แม่สลิด ตาก 63120",
    name_th: "บ้านตาก แม่สลิด ตาก 63120",
    name_en: "Ban Tak, Mae Salit, Tak, 63120",
  },
  {
    id: 630204,
    districtTh: "บ้านตาก",
    districtEn: "Ban Tak",
    subdistrictTh: "ตากตก",
    subdistrictEn: "Tak Tok",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63120,
    district_code: 6302,
    province_code: 50,
    name: "บ้านตาก ตากตก ตาก 63120",
    name_th: "บ้านตาก ตากตก ตาก 63120",
    name_en: "Ban Tak, Tak Tok, Tak, 63120",
  },
  {
    id: 630205,
    districtTh: "บ้านตาก",
    districtEn: "Ban Tak",
    subdistrictTh: "เกาะตะเภา",
    subdistrictEn: "Ko Taphao",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63120,
    district_code: 6302,
    province_code: 50,
    name: "บ้านตาก เกาะตะเภา ตาก 63120",
    name_th: "บ้านตาก เกาะตะเภา ตาก 63120",
    name_en: "Ban Tak, Ko Taphao, Tak, 63120",
  },
  {
    id: 630206,
    districtTh: "บ้านตาก",
    districtEn: "Ban Tak",
    subdistrictTh: "ทุ่งกระเชาะ",
    subdistrictEn: "Thung Kracho",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63120,
    district_code: 6302,
    province_code: 50,
    name: "บ้านตาก ทุ่งกระเชาะ ตาก 63120",
    name_th: "บ้านตาก ทุ่งกระเชาะ ตาก 63120",
    name_en: "Ban Tak, Thung Kracho, Tak, 63120",
  },
  {
    id: 630207,
    districtTh: "บ้านตาก",
    districtEn: "Ban Tak",
    subdistrictTh: "ท้องฟ้า",
    subdistrictEn: "Thong Fa",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63120,
    district_code: 6302,
    province_code: 50,
    name: "บ้านตาก ท้องฟ้า ตาก 63120",
    name_th: "บ้านตาก ท้องฟ้า ตาก 63120",
    name_en: "Ban Tak, Thong Fa, Tak, 63120",
  },
  {
    id: 630301,
    districtTh: "สามเงา",
    districtEn: "Sam Ngao",
    subdistrictTh: "สามเงา",
    subdistrictEn: "Sam Ngao",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63130,
    district_code: 6303,
    province_code: 50,
    name: "สามเงา สามเงา ตาก 63130",
    name_th: "สามเงา สามเงา ตาก 63130",
    name_en: "Sam Ngao, Sam Ngao, Tak, 63130",
  },
  {
    id: 630302,
    districtTh: "สามเงา",
    districtEn: "Sam Ngao",
    subdistrictTh: "วังหมัน",
    subdistrictEn: "Wang Man",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63130,
    district_code: 6303,
    province_code: 50,
    name: "สามเงา วังหมัน ตาก 63130",
    name_th: "สามเงา วังหมัน ตาก 63130",
    name_en: "Sam Ngao, Wang Man, Tak, 63130",
  },
  {
    id: 630303,
    districtTh: "สามเงา",
    districtEn: "Sam Ngao",
    subdistrictTh: "ยกกระบัตร",
    subdistrictEn: "Yokkrabat",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63130,
    district_code: 6303,
    province_code: 50,
    name: "สามเงา ยกกระบัตร ตาก 63130",
    name_th: "สามเงา ยกกระบัตร ตาก 63130",
    name_en: "Sam Ngao, Yokkrabat, Tak, 63130",
  },
  {
    id: 630304,
    districtTh: "สามเงา",
    districtEn: "Sam Ngao",
    subdistrictTh: "ย่านรี",
    subdistrictEn: "Yan Ri",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63130,
    district_code: 6303,
    province_code: 50,
    name: "สามเงา ย่านรี ตาก 63130",
    name_th: "สามเงา ย่านรี ตาก 63130",
    name_en: "Sam Ngao, Yan Ri, Tak, 63130",
  },
  {
    id: 630305,
    districtTh: "สามเงา",
    districtEn: "Sam Ngao",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63130,
    district_code: 6303,
    province_code: 50,
    name: "สามเงา บ้านนา ตาก 63130",
    name_th: "สามเงา บ้านนา ตาก 63130",
    name_en: "Sam Ngao, Ban Na, Tak, 63130",
  },
  {
    id: 630306,
    districtTh: "สามเงา",
    districtEn: "Sam Ngao",
    subdistrictTh: "วังจันทร์",
    subdistrictEn: "Wang Chan",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63130,
    district_code: 6303,
    province_code: 50,
    name: "สามเงา วังจันทร์ ตาก 63130",
    name_th: "สามเงา วังจันทร์ ตาก 63130",
    name_en: "Sam Ngao, Wang Chan, Tak, 63130",
  },
  {
    id: 630401,
    districtTh: "แม่ระมาด",
    districtEn: "Mae Ramat",
    subdistrictTh: "แม่ระมาด",
    subdistrictEn: "Mae Ramat",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63140,
    district_code: 6304,
    province_code: 50,
    name: "แม่ระมาด แม่ระมาด ตาก 63140",
    name_th: "แม่ระมาด แม่ระมาด ตาก 63140",
    name_en: "Mae Ramat, Mae Ramat, Tak, 63140",
  },
  {
    id: 630402,
    districtTh: "แม่ระมาด",
    districtEn: "Mae Ramat",
    subdistrictTh: "แม่จะเรา",
    subdistrictEn: "Mae Charao",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63140,
    district_code: 6304,
    province_code: 50,
    name: "แม่ระมาด แม่จะเรา ตาก 63140",
    name_th: "แม่ระมาด แม่จะเรา ตาก 63140",
    name_en: "Mae Ramat, Mae Charao, Tak, 63140",
  },
  {
    id: 630403,
    districtTh: "แม่ระมาด",
    districtEn: "Mae Ramat",
    subdistrictTh: "ขะเนจื้อ",
    subdistrictEn: "Khane Chue",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63140,
    district_code: 6304,
    province_code: 50,
    name: "แม่ระมาด ขะเนจื้อ ตาก 63140",
    name_th: "แม่ระมาด ขะเนจื้อ ตาก 63140",
    name_en: "Mae Ramat, Khane Chue, Tak, 63140",
  },
  {
    id: 630404,
    districtTh: "แม่ระมาด",
    districtEn: "Mae Ramat",
    subdistrictTh: "แม่ตื่น",
    subdistrictEn: "Mae Tuen",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63140,
    district_code: 6304,
    province_code: 50,
    name: "แม่ระมาด แม่ตื่น ตาก 63140",
    name_th: "แม่ระมาด แม่ตื่น ตาก 63140",
    name_en: "Mae Ramat, Mae Tuen, Tak, 63140",
  },
  {
    id: 630405,
    districtTh: "แม่ระมาด",
    districtEn: "Mae Ramat",
    subdistrictTh: "สามหมื่น",
    subdistrictEn: "Sam Muen",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63140,
    district_code: 6304,
    province_code: 50,
    name: "แม่ระมาด สามหมื่น ตาก 63140",
    name_th: "แม่ระมาด สามหมื่น ตาก 63140",
    name_en: "Mae Ramat, Sam Muen, Tak, 63140",
  },
  {
    id: 630406,
    districtTh: "แม่ระมาด",
    districtEn: "Mae Ramat",
    subdistrictTh: "พระธาตุ",
    subdistrictEn: "Phra That",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63140,
    district_code: 6304,
    province_code: 50,
    name: "แม่ระมาด พระธาตุ ตาก 63140",
    name_th: "แม่ระมาด พระธาตุ ตาก 63140",
    name_en: "Mae Ramat, Phra That, Tak, 63140",
  },
  {
    id: 630501,
    districtTh: "ท่าสองยาง",
    districtEn: "Tha Song Yang",
    subdistrictTh: "ท่าสองยาง",
    subdistrictEn: "Tha Song Yang",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63150,
    district_code: 6305,
    province_code: 50,
    name: "ท่าสองยาง ท่าสองยาง ตาก 63150",
    name_th: "ท่าสองยาง ท่าสองยาง ตาก 63150",
    name_en: "Tha Song Yang, Tha Song Yang, Tak, 63150",
  },
  {
    id: 630502,
    districtTh: "ท่าสองยาง",
    districtEn: "Tha Song Yang",
    subdistrictTh: "แม่ต้าน",
    subdistrictEn: "Mae Tan",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63150,
    district_code: 6305,
    province_code: 50,
    name: "ท่าสองยาง แม่ต้าน ตาก 63150",
    name_th: "ท่าสองยาง แม่ต้าน ตาก 63150",
    name_en: "Tha Song Yang, Mae Tan, Tak, 63150",
  },
  {
    id: 630504,
    districtTh: "ท่าสองยาง",
    districtEn: "Tha Song Yang",
    subdistrictTh: "แม่หละ",
    subdistrictEn: "Mae La",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63150,
    district_code: 6305,
    province_code: 50,
    name: "ท่าสองยาง แม่หละ ตาก 63150",
    name_th: "ท่าสองยาง แม่หละ ตาก 63150",
    name_en: "Tha Song Yang, Mae La, Tak, 63150",
  },
  {
    id: 630505,
    districtTh: "ท่าสองยาง",
    districtEn: "Tha Song Yang",
    subdistrictTh: "แม่วะหลวง",
    subdistrictEn: "Mae Wa Luang",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63150,
    district_code: 6305,
    province_code: 50,
    name: "ท่าสองยาง แม่วะหลวง ตาก 63150",
    name_th: "ท่าสองยาง แม่วะหลวง ตาก 63150",
    name_en: "Tha Song Yang, Mae Wa Luang, Tak, 63150",
  },
  {
    id: 630506,
    districtTh: "ท่าสองยาง",
    districtEn: "Tha Song Yang",
    subdistrictTh: "แม่อุสุ",
    subdistrictEn: "Mae Usu",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63150,
    district_code: 6305,
    province_code: 50,
    name: "ท่าสองยาง แม่อุสุ ตาก 63150",
    name_th: "ท่าสองยาง แม่อุสุ ตาก 63150",
    name_en: "Tha Song Yang, Mae Usu, Tak, 63150",
  },
  {
    id: 630601,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "แม่สอด",
    subdistrictEn: "Mae Sot",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด แม่สอด ตาก 63110",
    name_th: "แม่สอด แม่สอด ตาก 63110",
    name_en: "Mae Sot, Mae Sot, Tak, 63110",
  },
  {
    id: 630602,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "แม่กุ",
    subdistrictEn: "Mae Ku",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด แม่กุ ตาก 63110",
    name_th: "แม่สอด แม่กุ ตาก 63110",
    name_en: "Mae Sot, Mae Ku, Tak, 63110",
  },
  {
    id: 630603,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "พะวอ",
    subdistrictEn: "Phawo",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด พะวอ ตาก 63110",
    name_th: "แม่สอด พะวอ ตาก 63110",
    name_en: "Mae Sot, Phawo, Tak, 63110",
  },
  {
    id: 630604,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "แม่ตาว",
    subdistrictEn: "Mae Tao",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด แม่ตาว ตาก 63110",
    name_th: "แม่สอด แม่ตาว ตาก 63110",
    name_en: "Mae Sot, Mae Tao, Tak, 63110",
  },
  {
    id: 630605,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "แม่กาษา",
    subdistrictEn: "Mae Kasa",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด แม่กาษา ตาก 63110",
    name_th: "แม่สอด แม่กาษา ตาก 63110",
    name_en: "Mae Sot, Mae Kasa, Tak, 63110",
  },
  {
    id: 630606,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "ท่าสายลวด",
    subdistrictEn: "Tha Sai Luat",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด ท่าสายลวด ตาก 63110",
    name_th: "แม่สอด ท่าสายลวด ตาก 63110",
    name_en: "Mae Sot, Tha Sai Luat, Tak, 63110",
  },
  {
    id: 630607,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "แม่ปะ",
    subdistrictEn: "Mae Pa",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด แม่ปะ ตาก 63110",
    name_th: "แม่สอด แม่ปะ ตาก 63110",
    name_en: "Mae Sot, Mae Pa, Tak, 63110",
  },
  {
    id: 630608,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "มหาวัน",
    subdistrictEn: "Mahawan",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด มหาวัน ตาก 63110",
    name_th: "แม่สอด มหาวัน ตาก 63110",
    name_en: "Mae Sot, Mahawan, Tak, 63110",
  },
  {
    id: 630609,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "ด่านแม่ละเมา",
    subdistrictEn: "Dan Mae Lamao",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด ด่านแม่ละเมา ตาก 63110",
    name_th: "แม่สอด ด่านแม่ละเมา ตาก 63110",
    name_en: "Mae Sot, Dan Mae Lamao, Tak, 63110",
  },
  {
    id: 630610,
    districtTh: "แม่สอด",
    districtEn: "Mae Sot",
    subdistrictTh: "พระธาตุผาแดง",
    subdistrictEn: "Phra That Pha Daeng",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63110,
    district_code: 6306,
    province_code: 50,
    name: "แม่สอด พระธาตุผาแดง ตาก 63110",
    name_th: "แม่สอด พระธาตุผาแดง ตาก 63110",
    name_en: "Mae Sot, Phra That Pha Daeng, Tak, 63110",
  },
  {
    id: 630701,
    districtTh: "พบพระ",
    districtEn: "Phop Phra",
    subdistrictTh: "พบพระ",
    subdistrictEn: "Phop Phra",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63160,
    district_code: 6307,
    province_code: 50,
    name: "พบพระ พบพระ ตาก 63160",
    name_th: "พบพระ พบพระ ตาก 63160",
    name_en: "Phop Phra, Phop Phra, Tak, 63160",
  },
  {
    id: 630702,
    districtTh: "พบพระ",
    districtEn: "Phop Phra",
    subdistrictTh: "ช่องแคบ",
    subdistrictEn: "Chong Khaep",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63160,
    district_code: 6307,
    province_code: 50,
    name: "พบพระ ช่องแคบ ตาก 63160",
    name_th: "พบพระ ช่องแคบ ตาก 63160",
    name_en: "Phop Phra, Chong Khaep, Tak, 63160",
  },
  {
    id: 630703,
    districtTh: "พบพระ",
    districtEn: "Phop Phra",
    subdistrictTh: "คีรีราษฎร์",
    subdistrictEn: "Khiri Rat",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63160,
    district_code: 6307,
    province_code: 50,
    name: "พบพระ คีรีราษฎร์ ตาก 63160",
    name_th: "พบพระ คีรีราษฎร์ ตาก 63160",
    name_en: "Phop Phra, Khiri Rat, Tak, 63160",
  },
  {
    id: 630704,
    districtTh: "พบพระ",
    districtEn: "Phop Phra",
    subdistrictTh: "วาเล่ย์",
    subdistrictEn: "Wale",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63160,
    district_code: 6307,
    province_code: 50,
    name: "พบพระ วาเล่ย์ ตาก 63160",
    name_th: "พบพระ วาเล่ย์ ตาก 63160",
    name_en: "Phop Phra, Wale, Tak, 63160",
  },
  {
    id: 630705,
    districtTh: "พบพระ",
    districtEn: "Phop Phra",
    subdistrictTh: "รวมไทยพัฒนา",
    subdistrictEn: "Ruam Thai Phatthana",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63160,
    district_code: 6307,
    province_code: 50,
    name: "พบพระ รวมไทยพัฒนา ตาก 63160",
    name_th: "พบพระ รวมไทยพัฒนา ตาก 63160",
    name_en: "Phop Phra, Ruam Thai Phatthana, Tak, 63160",
  },
  {
    id: 630801,
    districtTh: "อุ้มผาง",
    districtEn: "Umphang",
    subdistrictTh: "อุ้มผาง",
    subdistrictEn: "Umphang",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63170,
    district_code: 6308,
    province_code: 50,
    name: "อุ้มผาง อุ้มผาง ตาก 63170",
    name_th: "อุ้มผาง อุ้มผาง ตาก 63170",
    name_en: "Umphang, Umphang, Tak, 63170",
  },
  {
    id: 630802,
    districtTh: "อุ้มผาง",
    districtEn: "Umphang",
    subdistrictTh: "หนองหลวง",
    subdistrictEn: "Nong Luang",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63170,
    district_code: 6308,
    province_code: 50,
    name: "อุ้มผาง หนองหลวง ตาก 63170",
    name_th: "อุ้มผาง หนองหลวง ตาก 63170",
    name_en: "Umphang, Nong Luang, Tak, 63170",
  },
  {
    id: 630803,
    districtTh: "อุ้มผาง",
    districtEn: "Umphang",
    subdistrictTh: "โมโกร",
    subdistrictEn: "Mokro",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63170,
    district_code: 6308,
    province_code: 50,
    name: "อุ้มผาง โมโกร ตาก 63170",
    name_th: "อุ้มผาง โมโกร ตาก 63170",
    name_en: "Umphang, Mokro, Tak, 63170",
  },
  {
    id: 630804,
    districtTh: "อุ้มผาง",
    districtEn: "Umphang",
    subdistrictTh: "แม่จัน",
    subdistrictEn: "Mae Chan",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63170,
    district_code: 6308,
    province_code: 50,
    name: "อุ้มผาง แม่จัน ตาก 63170",
    name_th: "อุ้มผาง แม่จัน ตาก 63170",
    name_en: "Umphang, Mae Chan, Tak, 63170",
  },
  {
    id: 630805,
    districtTh: "อุ้มผาง",
    districtEn: "Umphang",
    subdistrictTh: "แม่ละมุ้ง",
    subdistrictEn: "Mae Lamung",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63170,
    district_code: 6308,
    province_code: 50,
    name: "อุ้มผาง แม่ละมุ้ง ตาก 63170",
    name_th: "อุ้มผาง แม่ละมุ้ง ตาก 63170",
    name_en: "Umphang, Mae Lamung, Tak, 63170",
  },
  {
    id: 630806,
    districtTh: "อุ้มผาง",
    districtEn: "Umphang",
    subdistrictTh: "แม่กลอง",
    subdistrictEn: "Mae Klong",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63170,
    district_code: 6308,
    province_code: 50,
    name: "อุ้มผาง แม่กลอง ตาก 63170",
    name_th: "อุ้มผาง แม่กลอง ตาก 63170",
    name_en: "Umphang, Mae Klong, Tak, 63170",
  },
  {
    id: 630901,
    districtTh: "วังเจ้า",
    districtEn: "Wang Chao",
    subdistrictTh: "เชียงทอง",
    subdistrictEn: "Chiang Thong",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6309,
    province_code: 50,
    name: "วังเจ้า เชียงทอง ตาก 63000",
    name_th: "วังเจ้า เชียงทอง ตาก 63000",
    name_en: "Wang Chao, Chiang Thong, Tak, 63000",
  },
  {
    id: 630902,
    districtTh: "วังเจ้า",
    districtEn: "Wang Chao",
    subdistrictTh: "นาโบสถ์",
    subdistrictEn: "Na Bot",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6309,
    province_code: 50,
    name: "วังเจ้า นาโบสถ์ ตาก 63000",
    name_th: "วังเจ้า นาโบสถ์ ตาก 63000",
    name_en: "Wang Chao, Na Bot, Tak, 63000",
  },
  {
    id: 630903,
    districtTh: "วังเจ้า",
    districtEn: "Wang Chao",
    subdistrictTh: "ประดาง",
    subdistrictEn: "Pradang",
    provinceTh: "ตาก",
    provinceEn: "Tak",
    zipcode: 63000,
    district_code: 6309,
    province_code: 50,
    name: "วังเจ้า ประดาง ตาก 63000",
    name_th: "วังเจ้า ประดาง ตาก 63000",
    name_en: "Wang Chao, Pradang, Tak, 63000",
  },
  {
    id: 640101,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "ธานี",
    subdistrictEn: "Thani",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64000,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย ธานี สุโขทัย 64000",
    name_th: "เมืองสุโขทัย ธานี สุโขทัย 64000",
    name_en: "Mueang Sukhothai, Thani, Sukhothai, 64000",
  },
  {
    id: 640102,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "บ้านสวน",
    subdistrictEn: "Ban Suan",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64220,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย บ้านสวน สุโขทัย 64220",
    name_th: "เมืองสุโขทัย บ้านสวน สุโขทัย 64220",
    name_en: "Mueang Sukhothai, Ban Suan, Sukhothai, 64220",
  },
  {
    id: 640103,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "เมืองเก่า",
    subdistrictEn: "Mueang Kao",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64210,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย เมืองเก่า สุโขทัย 64210",
    name_th: "เมืองสุโขทัย เมืองเก่า สุโขทัย 64210",
    name_en: "Mueang Sukhothai, Mueang Kao, Sukhothai, 64210",
  },
  {
    id: 640104,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "ปากแคว",
    subdistrictEn: "Pak Khwae",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64000,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย ปากแคว สุโขทัย 64000",
    name_th: "เมืองสุโขทัย ปากแคว สุโขทัย 64000",
    name_en: "Mueang Sukhothai, Pak Khwae, Sukhothai, 64000",
  },
  {
    id: 640105,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "ยางซ้าย",
    subdistrictEn: "Yang Sai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64000,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย ยางซ้าย สุโขทัย 64000",
    name_th: "เมืองสุโขทัย ยางซ้าย สุโขทัย 64000",
    name_en: "Mueang Sukhothai, Yang Sai, Sukhothai, 64000",
  },
  {
    id: 640106,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "บ้านกล้วย",
    subdistrictEn: "Ban Kluai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64000,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย บ้านกล้วย สุโขทัย 64000",
    name_th: "เมืองสุโขทัย บ้านกล้วย สุโขทัย 64000",
    name_en: "Mueang Sukhothai, Ban Kluai, Sukhothai, 64000",
  },
  {
    id: 640107,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "บ้านหลุม",
    subdistrictEn: "Ban Lum",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64000,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย บ้านหลุม สุโขทัย 64000",
    name_th: "เมืองสุโขทัย บ้านหลุม สุโขทัย 64000",
    name_en: "Mueang Sukhothai, Ban Lum, Sukhothai, 64000",
  },
  {
    id: 640108,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "ตาลเตี้ย",
    subdistrictEn: "Tan Tia",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64220,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย ตาลเตี้ย สุโขทัย 64220",
    name_th: "เมืองสุโขทัย ตาลเตี้ย สุโขทัย 64220",
    name_en: "Mueang Sukhothai, Tan Tia, Sukhothai, 64220",
  },
  {
    id: 640109,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "ปากพระ",
    subdistrictEn: "Pak Phra",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64000,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย ปากพระ สุโขทัย 64000",
    name_th: "เมืองสุโขทัย ปากพระ สุโขทัย 64000",
    name_en: "Mueang Sukhothai, Pak Phra, Sukhothai, 64000",
  },
  {
    id: 640110,
    districtTh: "เมืองสุโขทัย",
    districtEn: "Mueang Sukhothai",
    subdistrictTh: "วังทองแดง",
    subdistrictEn: "Wang Thongdaeng",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64210,
    district_code: 6401,
    province_code: 51,
    name: "เมืองสุโขทัย วังทองแดง สุโขทัย 64210",
    name_th: "เมืองสุโขทัย วังทองแดง สุโขทัย 64210",
    name_en: "Mueang Sukhothai, Wang Thongdaeng, Sukhothai, 64210",
  },
  {
    id: 640201,
    districtTh: "บ้านด่านลานหอย",
    districtEn: "Ban Dan Lan Hoi",
    subdistrictTh: "ลานหอย",
    subdistrictEn: "Lan Hoi",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64140,
    district_code: 6402,
    province_code: 51,
    name: "บ้านด่านลานหอย ลานหอย สุโขทัย 64140",
    name_th: "บ้านด่านลานหอย ลานหอย สุโขทัย 64140",
    name_en: "Ban Dan Lan Hoi, Lan Hoi, Sukhothai, 64140",
  },
  {
    id: 640202,
    districtTh: "บ้านด่านลานหอย",
    districtEn: "Ban Dan Lan Hoi",
    subdistrictTh: "บ้านด่าน",
    subdistrictEn: "Ban Dan",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64140,
    district_code: 6402,
    province_code: 51,
    name: "บ้านด่านลานหอย บ้านด่าน สุโขทัย 64140",
    name_th: "บ้านด่านลานหอย บ้านด่าน สุโขทัย 64140",
    name_en: "Ban Dan Lan Hoi, Ban Dan, Sukhothai, 64140",
  },
  {
    id: 640203,
    districtTh: "บ้านด่านลานหอย",
    districtEn: "Ban Dan Lan Hoi",
    subdistrictTh: "วังตะคร้อ",
    subdistrictEn: "Wang Takhro",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64140,
    district_code: 6402,
    province_code: 51,
    name: "บ้านด่านลานหอย วังตะคร้อ สุโขทัย 64140",
    name_th: "บ้านด่านลานหอย วังตะคร้อ สุโขทัย 64140",
    name_en: "Ban Dan Lan Hoi, Wang Takhro, Sukhothai, 64140",
  },
  {
    id: 640204,
    districtTh: "บ้านด่านลานหอย",
    districtEn: "Ban Dan Lan Hoi",
    subdistrictTh: "วังน้ำขาว",
    subdistrictEn: "Wang Nam Khao",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64140,
    district_code: 6402,
    province_code: 51,
    name: "บ้านด่านลานหอย วังน้ำขาว สุโขทัย 64140",
    name_th: "บ้านด่านลานหอย วังน้ำขาว สุโขทัย 64140",
    name_en: "Ban Dan Lan Hoi, Wang Nam Khao, Sukhothai, 64140",
  },
  {
    id: 640205,
    districtTh: "บ้านด่านลานหอย",
    districtEn: "Ban Dan Lan Hoi",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64140,
    district_code: 6402,
    province_code: 51,
    name: "บ้านด่านลานหอย ตลิ่งชัน สุโขทัย 64140",
    name_th: "บ้านด่านลานหอย ตลิ่งชัน สุโขทัย 64140",
    name_en: "Ban Dan Lan Hoi, Taling Chan, Sukhothai, 64140",
  },
  {
    id: 640206,
    districtTh: "บ้านด่านลานหอย",
    districtEn: "Ban Dan Lan Hoi",
    subdistrictTh: "หนองหญ้าปล้อง",
    subdistrictEn: "Nong Ya Plong",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64140,
    district_code: 6402,
    province_code: 51,
    name: "บ้านด่านลานหอย หนองหญ้าปล้อง สุโขทัย 64140",
    name_th: "บ้านด่านลานหอย หนองหญ้าปล้อง สุโขทัย 64140",
    name_en: "Ban Dan Lan Hoi, Nong Ya Plong, Sukhothai, 64140",
  },
  {
    id: 640207,
    districtTh: "บ้านด่านลานหอย",
    districtEn: "Ban Dan Lan Hoi",
    subdistrictTh: "วังลึก",
    subdistrictEn: "Wang Luek",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64140,
    district_code: 6402,
    province_code: 51,
    name: "บ้านด่านลานหอย วังลึก สุโขทัย 64140",
    name_th: "บ้านด่านลานหอย วังลึก สุโขทัย 64140",
    name_en: "Ban Dan Lan Hoi, Wang Luek, Sukhothai, 64140",
  },
  {
    id: 640301,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "โตนด",
    subdistrictEn: "Tanot",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ โตนด สุโขทัย 64160",
    name_th: "คีรีมาศ โตนด สุโขทัย 64160",
    name_en: "Khiri Mat, Tanot, Sukhothai, 64160",
  },
  {
    id: 640302,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "ทุ่งหลวง",
    subdistrictEn: "Thung Luang",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ ทุ่งหลวง สุโขทัย 64160",
    name_th: "คีรีมาศ ทุ่งหลวง สุโขทัย 64160",
    name_en: "Khiri Mat, Thung Luang, Sukhothai, 64160",
  },
  {
    id: 640303,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "บ้านป้อม",
    subdistrictEn: "Ban Pom",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ บ้านป้อม สุโขทัย 64160",
    name_th: "คีรีมาศ บ้านป้อม สุโขทัย 64160",
    name_en: "Khiri Mat, Ban Pom, Sukhothai, 64160",
  },
  {
    id: 640304,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "สามพวง",
    subdistrictEn: "Sam Phuang",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ สามพวง สุโขทัย 64160",
    name_th: "คีรีมาศ สามพวง สุโขทัย 64160",
    name_en: "Khiri Mat, Sam Phuang, Sukhothai, 64160",
  },
  {
    id: 640305,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "ศรีคีรีมาศ",
    subdistrictEn: "Si Khiri Mat",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ ศรีคีรีมาศ สุโขทัย 64160",
    name_th: "คีรีมาศ ศรีคีรีมาศ สุโขทัย 64160",
    name_en: "Khiri Mat, Si Khiri Mat, Sukhothai, 64160",
  },
  {
    id: 640306,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "หนองจิก",
    subdistrictEn: "Nong Chik",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ หนองจิก สุโขทัย 64160",
    name_th: "คีรีมาศ หนองจิก สุโขทัย 64160",
    name_en: "Khiri Mat, Nong Chik, Sukhothai, 64160",
  },
  {
    id: 640307,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "นาเชิงคีรี",
    subdistrictEn: "Na Choeng Khiri",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ นาเชิงคีรี สุโขทัย 64160",
    name_th: "คีรีมาศ นาเชิงคีรี สุโขทัย 64160",
    name_en: "Khiri Mat, Na Choeng Khiri, Sukhothai, 64160",
  },
  {
    id: 640308,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "หนองกระดิ่ง",
    subdistrictEn: "Nong Krading",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ หนองกระดิ่ง สุโขทัย 64160",
    name_th: "คีรีมาศ หนองกระดิ่ง สุโขทัย 64160",
    name_en: "Khiri Mat, Nong Krading, Sukhothai, 64160",
  },
  {
    id: 640309,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "บ้านน้ำพุ",
    subdistrictEn: "Ban Nam Phu",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ บ้านน้ำพุ สุโขทัย 64160",
    name_th: "คีรีมาศ บ้านน้ำพุ สุโขทัย 64160",
    name_en: "Khiri Mat, Ban Nam Phu, Sukhothai, 64160",
  },
  {
    id: 640310,
    districtTh: "คีรีมาศ",
    districtEn: "Khiri Mat",
    subdistrictTh: "ทุ่งยางเมือง",
    subdistrictEn: "Thung Yang Mueang",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64160,
    district_code: 6403,
    province_code: 51,
    name: "คีรีมาศ ทุ่งยางเมือง สุโขทัย 64160",
    name_th: "คีรีมาศ ทุ่งยางเมือง สุโขทัย 64160",
    name_en: "Khiri Mat, Thung Yang Mueang, Sukhothai, 64160",
  },
  {
    id: 640401,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "กง",
    subdistrictEn: "Kong",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ กง สุโขทัย 64170",
    name_th: "กงไกรลาศ กง สุโขทัย 64170",
    name_en: "Kong Krailat, Kong, Sukhothai, 64170",
  },
  {
    id: 640402,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "บ้านกร่าง",
    subdistrictEn: "Ban Krang",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ บ้านกร่าง สุโขทัย 64170",
    name_th: "กงไกรลาศ บ้านกร่าง สุโขทัย 64170",
    name_en: "Kong Krailat, Ban Krang, Sukhothai, 64170",
  },
  {
    id: 640403,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "ไกรนอก",
    subdistrictEn: "Krai Nok",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ ไกรนอก สุโขทัย 64170",
    name_th: "กงไกรลาศ ไกรนอก สุโขทัย 64170",
    name_en: "Kong Krailat, Krai Nok, Sukhothai, 64170",
  },
  {
    id: 640404,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "ไกรกลาง",
    subdistrictEn: "Krai Klang",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ ไกรกลาง สุโขทัย 64170",
    name_th: "กงไกรลาศ ไกรกลาง สุโขทัย 64170",
    name_en: "Kong Krailat, Krai Klang, Sukhothai, 64170",
  },
  {
    id: 640405,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "ไกรใน",
    subdistrictEn: "Krai Nai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ ไกรใน สุโขทัย 64170",
    name_th: "กงไกรลาศ ไกรใน สุโขทัย 64170",
    name_en: "Kong Krailat, Krai Nai, Sukhothai, 64170",
  },
  {
    id: 640406,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "ดงเดือย",
    subdistrictEn: "Dong Dueai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ ดงเดือย สุโขทัย 64170",
    name_th: "กงไกรลาศ ดงเดือย สุโขทัย 64170",
    name_en: "Kong Krailat, Dong Dueai, Sukhothai, 64170",
  },
  {
    id: 640407,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "ป่าแฝก",
    subdistrictEn: "Pa Faek",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ ป่าแฝก สุโขทัย 64170",
    name_th: "กงไกรลาศ ป่าแฝก สุโขทัย 64170",
    name_en: "Kong Krailat, Pa Faek, Sukhothai, 64170",
  },
  {
    id: 640408,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "กกแรต",
    subdistrictEn: "Kok Raet",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ กกแรต สุโขทัย 64170",
    name_th: "กงไกรลาศ กกแรต สุโขทัย 64170",
    name_en: "Kong Krailat, Kok Raet, Sukhothai, 64170",
  },
  {
    id: 640409,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "ท่าฉนวน",
    subdistrictEn: "Tha Chanuan",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ ท่าฉนวน สุโขทัย 64170",
    name_th: "กงไกรลาศ ท่าฉนวน สุโขทัย 64170",
    name_en: "Kong Krailat, Tha Chanuan, Sukhothai, 64170",
  },
  {
    id: 640410,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "หนองตูม",
    subdistrictEn: "Nong Tum",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ หนองตูม สุโขทัย 64170",
    name_th: "กงไกรลาศ หนองตูม สุโขทัย 64170",
    name_en: "Kong Krailat, Nong Tum, Sukhothai, 64170",
  },
  {
    id: 640411,
    districtTh: "กงไกรลาศ",
    districtEn: "Kong Krailat",
    subdistrictTh: "บ้านใหม่สุขเกษม",
    subdistrictEn: "Ban Mai Suk Kasem",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64170,
    district_code: 6404,
    province_code: 51,
    name: "กงไกรลาศ บ้านใหม่สุขเกษม สุโขทัย 64170",
    name_th: "กงไกรลาศ บ้านใหม่สุขเกษม สุโขทัย 64170",
    name_en: "Kong Krailat, Ban Mai Suk Kasem, Sukhothai, 64170",
  },
  {
    id: 640501,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "หาดเสี้ยว",
    subdistrictEn: "Hat Siao",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย หาดเสี้ยว สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย หาดเสี้ยว สุโขทัย 64130",
    name_en: "Si Satchanalai, Hat Siao, Sukhothai, 64130",
  },
  {
    id: 640502,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "ป่างิ้ว",
    subdistrictEn: "Pa Ngio",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย ป่างิ้ว สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย ป่างิ้ว สุโขทัย 64130",
    name_en: "Si Satchanalai, Pa Ngio, Sukhothai, 64130",
  },
  {
    id: 640503,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "แม่สำ",
    subdistrictEn: "Mae Sam",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย แม่สำ สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย แม่สำ สุโขทัย 64130",
    name_en: "Si Satchanalai, Mae Sam, Sukhothai, 64130",
  },
  {
    id: 640504,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "แม่สิน",
    subdistrictEn: "Mae Sin",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย แม่สิน สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย แม่สิน สุโขทัย 64130",
    name_en: "Si Satchanalai, Mae Sin, Sukhothai, 64130",
  },
  {
    id: 640505,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "บ้านตึก",
    subdistrictEn: "Ban Tuek",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย บ้านตึก สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย บ้านตึก สุโขทัย 64130",
    name_en: "Si Satchanalai, Ban Tuek, Sukhothai, 64130",
  },
  {
    id: 640506,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "หนองอ้อ",
    subdistrictEn: "Nong O",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย หนองอ้อ สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย หนองอ้อ สุโขทัย 64130",
    name_en: "Si Satchanalai, Nong O, Sukhothai, 64130",
  },
  {
    id: 640507,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "ท่าชัย",
    subdistrictEn: "Tha Chai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64190,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย ท่าชัย สุโขทัย 64190",
    name_th: "ศรีสัชนาลัย ท่าชัย สุโขทัย 64190",
    name_en: "Si Satchanalai, Tha Chai, Sukhothai, 64190",
  },
  {
    id: 640508,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "ศรีสัชนาลัย",
    subdistrictEn: "Si Satchanalai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64190,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย ศรีสัชนาลัย สุโขทัย 64190",
    name_th: "ศรีสัชนาลัย ศรีสัชนาลัย สุโขทัย 64190",
    name_en: "Si Satchanalai, Si Satchanalai, Sukhothai, 64190",
  },
  {
    id: 640509,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "ดงคู่",
    subdistrictEn: "Dong Khu",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย ดงคู่ สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย ดงคู่ สุโขทัย 64130",
    name_en: "Si Satchanalai, Dong Khu, Sukhothai, 64130",
  },
  {
    id: 640510,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "บ้านแก่ง",
    subdistrictEn: "Ban Kaeng",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย บ้านแก่ง สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย บ้านแก่ง สุโขทัย 64130",
    name_en: "Si Satchanalai, Ban Kaeng, Sukhothai, 64130",
  },
  {
    id: 640511,
    districtTh: "ศรีสัชนาลัย",
    districtEn: "Si Satchanalai",
    subdistrictTh: "สารจิตร",
    subdistrictEn: "San Chit",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64130,
    district_code: 6405,
    province_code: 51,
    name: "ศรีสัชนาลัย สารจิตร สุโขทัย 64130",
    name_th: "ศรีสัชนาลัย สารจิตร สุโขทัย 64130",
    name_en: "Si Satchanalai, San Chit, Sukhothai, 64130",
  },
  {
    id: 640601,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "คลองตาล",
    subdistrictEn: "Khlong Tan",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง คลองตาล สุโขทัย 64120",
    name_th: "ศรีสำโรง คลองตาล สุโขทัย 64120",
    name_en: "Si Samrong, Khlong Tan, Sukhothai, 64120",
  },
  {
    id: 640602,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "วังลึก",
    subdistrictEn: "Wang Luek",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง วังลึก สุโขทัย 64120",
    name_th: "ศรีสำโรง วังลึก สุโขทัย 64120",
    name_en: "Si Samrong, Wang Luek, Sukhothai, 64120",
  },
  {
    id: 640603,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "สามเรือน",
    subdistrictEn: "Sam Ruean",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง สามเรือน สุโขทัย 64120",
    name_th: "ศรีสำโรง สามเรือน สุโขทัย 64120",
    name_en: "Si Samrong, Sam Ruean, Sukhothai, 64120",
  },
  {
    id: 640604,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง บ้านนา สุโขทัย 64120",
    name_th: "ศรีสำโรง บ้านนา สุโขทัย 64120",
    name_en: "Si Samrong, Ban Na, Sukhothai, 64120",
  },
  {
    id: 640605,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง วังทอง สุโขทัย 64120",
    name_th: "ศรีสำโรง วังทอง สุโขทัย 64120",
    name_en: "Si Samrong, Wang Thong, Sukhothai, 64120",
  },
  {
    id: 640606,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "นาขุนไกร",
    subdistrictEn: "Na Khun Krai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง นาขุนไกร สุโขทัย 64120",
    name_th: "ศรีสำโรง นาขุนไกร สุโขทัย 64120",
    name_en: "Si Samrong, Na Khun Krai, Sukhothai, 64120",
  },
  {
    id: 640607,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "เกาะตาเลี้ยง",
    subdistrictEn: "Ko Ta Liang",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง เกาะตาเลี้ยง สุโขทัย 64120",
    name_th: "ศรีสำโรง เกาะตาเลี้ยง สุโขทัย 64120",
    name_en: "Si Samrong, Ko Ta Liang, Sukhothai, 64120",
  },
  {
    id: 640608,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "วัดเกาะ",
    subdistrictEn: "Wat Ko",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง วัดเกาะ สุโขทัย 64120",
    name_th: "ศรีสำโรง วัดเกาะ สุโขทัย 64120",
    name_en: "Si Samrong, Wat Ko, Sukhothai, 64120",
  },
  {
    id: 640609,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "บ้านไร่",
    subdistrictEn: "Ban Rai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง บ้านไร่ สุโขทัย 64120",
    name_th: "ศรีสำโรง บ้านไร่ สุโขทัย 64120",
    name_en: "Si Samrong, Ban Rai, Sukhothai, 64120",
  },
  {
    id: 640610,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "ทับผึ้ง",
    subdistrictEn: "Thap Phueng",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง ทับผึ้ง สุโขทัย 64120",
    name_th: "ศรีสำโรง ทับผึ้ง สุโขทัย 64120",
    name_en: "Si Samrong, Thap Phueng, Sukhothai, 64120",
  },
  {
    id: 640611,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "บ้านซ่าน",
    subdistrictEn: "Ban San",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง บ้านซ่าน สุโขทัย 64120",
    name_th: "ศรีสำโรง บ้านซ่าน สุโขทัย 64120",
    name_en: "Si Samrong, Ban San, Sukhothai, 64120",
  },
  {
    id: 640612,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "วังใหญ่",
    subdistrictEn: "Wang Yai",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง วังใหญ่ สุโขทัย 64120",
    name_th: "ศรีสำโรง วังใหญ่ สุโขทัย 64120",
    name_en: "Si Samrong, Wang Yai, Sukhothai, 64120",
  },
  {
    id: 640613,
    districtTh: "ศรีสำโรง",
    districtEn: "Si Samrong",
    subdistrictTh: "ราวต้นจันทร์",
    subdistrictEn: "Rao Ton Chan",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64120,
    district_code: 6406,
    province_code: 51,
    name: "ศรีสำโรง ราวต้นจันทร์ สุโขทัย 64120",
    name_th: "ศรีสำโรง ราวต้นจันทร์ สุโขทัย 64120",
    name_en: "Si Samrong, Rao Ton Chan, Sukhothai, 64120",
  },
  {
    id: 640701,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "เมืองสวรรคโลก",
    subdistrictEn: "Mueang Sawankhalok",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก เมืองสวรรคโลก สุโขทัย 64110",
    name_th: "สวรรคโลก เมืองสวรรคโลก สุโขทัย 64110",
    name_en: "Sawankhalok, Mueang Sawankhalok, Sukhothai, 64110",
  },
  {
    id: 640702,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก ในเมือง สุโขทัย 64110",
    name_th: "สวรรคโลก ในเมือง สุโขทัย 64110",
    name_en: "Sawankhalok, Nai Mueang, Sukhothai, 64110",
  },
  {
    id: 640703,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "คลองกระจง",
    subdistrictEn: "Khlong Krachong",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก คลองกระจง สุโขทัย 64110",
    name_th: "สวรรคโลก คลองกระจง สุโขทัย 64110",
    name_en: "Sawankhalok, Khlong Krachong, Sukhothai, 64110",
  },
  {
    id: 640704,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "วังพิณพาทย์",
    subdistrictEn: "Wang Phinphat",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก วังพิณพาทย์ สุโขทัย 64110",
    name_th: "สวรรคโลก วังพิณพาทย์ สุโขทัย 64110",
    name_en: "Sawankhalok, Wang Phinphat, Sukhothai, 64110",
  },
  {
    id: 640705,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "วังไม้ขอน",
    subdistrictEn: "Wang Mai Khon",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก วังไม้ขอน สุโขทัย 64110",
    name_th: "สวรรคโลก วังไม้ขอน สุโขทัย 64110",
    name_en: "Sawankhalok, Wang Mai Khon, Sukhothai, 64110",
  },
  {
    id: 640706,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "ย่านยาว",
    subdistrictEn: "Yan Yao",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก ย่านยาว สุโขทัย 64110",
    name_th: "สวรรคโลก ย่านยาว สุโขทัย 64110",
    name_en: "Sawankhalok, Yan Yao, Sukhothai, 64110",
  },
  {
    id: 640707,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "นาทุ่ง",
    subdistrictEn: "Na Thung",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก นาทุ่ง สุโขทัย 64110",
    name_th: "สวรรคโลก นาทุ่ง สุโขทัย 64110",
    name_en: "Sawankhalok, Na Thung, Sukhothai, 64110",
  },
  {
    id: 640708,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "คลองยาง",
    subdistrictEn: "Khlong Yao",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก คลองยาง สุโขทัย 64110",
    name_th: "สวรรคโลก คลองยาง สุโขทัย 64110",
    name_en: "Sawankhalok, Khlong Yao, Sukhothai, 64110",
  },
  {
    id: 640709,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "เมืองบางยม",
    subdistrictEn: "Mueang Bang Yom",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก เมืองบางยม สุโขทัย 64110",
    name_th: "สวรรคโลก เมืองบางยม สุโขทัย 64110",
    name_en: "Sawankhalok, Mueang Bang Yom, Sukhothai, 64110",
  },
  {
    id: 640710,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "ท่าทอง",
    subdistrictEn: "Tha Thong",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก ท่าทอง สุโขทัย 64110",
    name_th: "สวรรคโลก ท่าทอง สุโขทัย 64110",
    name_en: "Sawankhalok, Tha Thong, Sukhothai, 64110",
  },
  {
    id: 640711,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก ปากน้ำ สุโขทัย 64110",
    name_th: "สวรรคโลก ปากน้ำ สุโขทัย 64110",
    name_en: "Sawankhalok, Pak Nam, Sukhothai, 64110",
  },
  {
    id: 640712,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "ป่ากุมเกาะ",
    subdistrictEn: "Pa Kum Ko",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก ป่ากุมเกาะ สุโขทัย 64110",
    name_th: "สวรรคโลก ป่ากุมเกาะ สุโขทัย 64110",
    name_en: "Sawankhalok, Pa Kum Ko, Sukhothai, 64110",
  },
  {
    id: 640713,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "เมืองบางขลัง",
    subdistrictEn: "Mueang Bang Khlang",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก เมืองบางขลัง สุโขทัย 64110",
    name_th: "สวรรคโลก เมืองบางขลัง สุโขทัย 64110",
    name_en: "Sawankhalok, Mueang Bang Khlang, Sukhothai, 64110",
  },
  {
    id: 640714,
    districtTh: "สวรรคโลก",
    districtEn: "Sawankhalok",
    subdistrictTh: "หนองกลับ",
    subdistrictEn: "Nong Klap",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64110,
    district_code: 6407,
    province_code: 51,
    name: "สวรรคโลก หนองกลับ สุโขทัย 64110",
    name_th: "สวรรคโลก หนองกลับ สุโขทัย 64110",
    name_en: "Sawankhalok, Nong Klap, Sukhothai, 64110",
  },
  {
    id: 640801,
    districtTh: "ศรีนคร",
    districtEn: "Si Nakhon",
    subdistrictTh: "ศรีนคร",
    subdistrictEn: "Si Nakhon",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64180,
    district_code: 6408,
    province_code: 51,
    name: "ศรีนคร ศรีนคร สุโขทัย 64180",
    name_th: "ศรีนคร ศรีนคร สุโขทัย 64180",
    name_en: "Si Nakhon, Si Nakhon, Sukhothai, 64180",
  },
  {
    id: 640802,
    districtTh: "ศรีนคร",
    districtEn: "Si Nakhon",
    subdistrictTh: "นครเดิฐ",
    subdistrictEn: "Nakhon Doet",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64180,
    district_code: 6408,
    province_code: 51,
    name: "ศรีนคร นครเดิฐ สุโขทัย 64180",
    name_th: "ศรีนคร นครเดิฐ สุโขทัย 64180",
    name_en: "Si Nakhon, Nakhon Doet, Sukhothai, 64180",
  },
  {
    id: 640803,
    districtTh: "ศรีนคร",
    districtEn: "Si Nakhon",
    subdistrictTh: "น้ำขุม",
    subdistrictEn: "Nam Khum",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64180,
    district_code: 6408,
    province_code: 51,
    name: "ศรีนคร น้ำขุม สุโขทัย 64180",
    name_th: "ศรีนคร น้ำขุม สุโขทัย 64180",
    name_en: "Si Nakhon, Nam Khum, Sukhothai, 64180",
  },
  {
    id: 640804,
    districtTh: "ศรีนคร",
    districtEn: "Si Nakhon",
    subdistrictTh: "คลองมะพลับ",
    subdistrictEn: "Khlong Maphlap",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64180,
    district_code: 6408,
    province_code: 51,
    name: "ศรีนคร คลองมะพลับ สุโขทัย 64180",
    name_th: "ศรีนคร คลองมะพลับ สุโขทัย 64180",
    name_en: "Si Nakhon, Khlong Maphlap, Sukhothai, 64180",
  },
  {
    id: 640805,
    districtTh: "ศรีนคร",
    districtEn: "Si Nakhon",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64180,
    district_code: 6408,
    province_code: 51,
    name: "ศรีนคร หนองบัว สุโขทัย 64180",
    name_th: "ศรีนคร หนองบัว สุโขทัย 64180",
    name_en: "Si Nakhon, Nong Bua, Sukhothai, 64180",
  },
  {
    id: 640901,
    districtTh: "ทุ่งเสลี่ยม",
    districtEn: "Thung Saliam",
    subdistrictTh: "บ้านใหม่ไชยมงคล",
    subdistrictEn: "Ban Mai Chai Mongkhon",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64230,
    district_code: 6409,
    province_code: 51,
    name: "ทุ่งเสลี่ยม บ้านใหม่ไชยมงคล สุโขทัย 64230",
    name_th: "ทุ่งเสลี่ยม บ้านใหม่ไชยมงคล สุโขทัย 64230",
    name_en: "Thung Saliam, Ban Mai Chai Mongkhon, Sukhothai, 64230",
  },
  {
    id: 640902,
    districtTh: "ทุ่งเสลี่ยม",
    districtEn: "Thung Saliam",
    subdistrictTh: "ไทยชนะศึก",
    subdistrictEn: "Thai Chana Suek",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64150,
    district_code: 6409,
    province_code: 51,
    name: "ทุ่งเสลี่ยม ไทยชนะศึก สุโขทัย 64150",
    name_th: "ทุ่งเสลี่ยม ไทยชนะศึก สุโขทัย 64150",
    name_en: "Thung Saliam, Thai Chana Suek, Sukhothai, 64150",
  },
  {
    id: 640903,
    districtTh: "ทุ่งเสลี่ยม",
    districtEn: "Thung Saliam",
    subdistrictTh: "ทุ่งเสลี่ยม",
    subdistrictEn: "Thung Saliam",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64150,
    district_code: 6409,
    province_code: 51,
    name: "ทุ่งเสลี่ยม ทุ่งเสลี่ยม สุโขทัย 64150",
    name_th: "ทุ่งเสลี่ยม ทุ่งเสลี่ยม สุโขทัย 64150",
    name_en: "Thung Saliam, Thung Saliam, Sukhothai, 64150",
  },
  {
    id: 640904,
    districtTh: "ทุ่งเสลี่ยม",
    districtEn: "Thung Saliam",
    subdistrictTh: "กลางดง",
    subdistrictEn: "Klang Dong",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64150,
    district_code: 6409,
    province_code: 51,
    name: "ทุ่งเสลี่ยม กลางดง สุโขทัย 64150",
    name_th: "ทุ่งเสลี่ยม กลางดง สุโขทัย 64150",
    name_en: "Thung Saliam, Klang Dong, Sukhothai, 64150",
  },
  {
    id: 640905,
    districtTh: "ทุ่งเสลี่ยม",
    districtEn: "Thung Saliam",
    subdistrictTh: "เขาแก้วศรีสมบูรณ์",
    subdistrictEn: "Khaokaw Si Somboon",
    provinceTh: "สุโขทัย",
    provinceEn: "Sukhothai",
    zipcode: 64230,
    district_code: 6409,
    province_code: 51,
    name: "ทุ่งเสลี่ยม เขาแก้วศรีสมบูรณ์ สุโขทัย 64230",
    name_th: "ทุ่งเสลี่ยม เขาแก้วศรีสมบูรณ์ สุโขทัย 64230",
    name_en: "Thung Saliam, Khaokaw Si Somboon, Sukhothai, 64230",
  },
  {
    id: 650101,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก ในเมือง พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก ในเมือง พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Nai Mueang, Phitsanulok, 65000",
  },
  {
    id: 650102,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "วังน้ำคู้",
    subdistrictEn: "Wang Nam Khu",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65230,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก วังน้ำคู้ พิษณุโลก 65230",
    name_th: "เมืองพิษณุโลก วังน้ำคู้ พิษณุโลก 65230",
    name_en: "Mueang Phitsanulok, Wang Nam Khu, Phitsanulok, 65230",
  },
  {
    id: 650103,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "วัดจันทร์",
    subdistrictEn: "Wat Chan",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก วัดจันทร์ พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก วัดจันทร์ พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Wat Chan, Phitsanulok, 65000",
  },
  {
    id: 650104,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "วัดพริก",
    subdistrictEn: "Wat Phrik",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65230,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก วัดพริก พิษณุโลก 65230",
    name_th: "เมืองพิษณุโลก วัดพริก พิษณุโลก 65230",
    name_en: "Mueang Phitsanulok, Wat Phrik, Phitsanulok, 65230",
  },
  {
    id: 650105,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "ท่าทอง",
    subdistrictEn: "Tha Thong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก ท่าทอง พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก ท่าทอง พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Tha Thong, Phitsanulok, 65000",
  },
  {
    id: 650106,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "ท่าโพธิ์",
    subdistrictEn: "Tha Pho",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก ท่าโพธิ์ พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก ท่าโพธิ์ พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Tha Pho, Phitsanulok, 65000",
  },
  {
    id: 650107,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "สมอแข",
    subdistrictEn: "Samo Khae",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก สมอแข พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก สมอแข พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Samo Khae, Phitsanulok, 65000",
  },
  {
    id: 650108,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "ดอนทอง",
    subdistrictEn: "Don Thong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก ดอนทอง พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก ดอนทอง พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Don Thong, Phitsanulok, 65000",
  },
  {
    id: 650109,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "บ้านป่า",
    subdistrictEn: "Ban Pa",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก บ้านป่า พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก บ้านป่า พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Ban Pa, Phitsanulok, 65000",
  },
  {
    id: 650110,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "ปากโทก",
    subdistrictEn: "Pak Thok",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก ปากโทก พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก ปากโทก พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Pak Thok, Phitsanulok, 65000",
  },
  {
    id: 650111,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "หัวรอ",
    subdistrictEn: "Hua Ro",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก หัวรอ พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก หัวรอ พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Hua Ro, Phitsanulok, 65000",
  },
  {
    id: 650112,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "จอมทอง",
    subdistrictEn: "Chom Thong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก จอมทอง พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก จอมทอง พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Chom Thong, Phitsanulok, 65000",
  },
  {
    id: 650113,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "บ้านกร่าง",
    subdistrictEn: "Ban Krang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก บ้านกร่าง พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก บ้านกร่าง พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Ban Krang, Phitsanulok, 65000",
  },
  {
    id: 650114,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "บ้านคลอง",
    subdistrictEn: "Ban Khlong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก บ้านคลอง พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก บ้านคลอง พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Ban Khlong, Phitsanulok, 65000",
  },
  {
    id: 650115,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "พลายชุมพล",
    subdistrictEn: "Phlai Chumphon",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก พลายชุมพล พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก พลายชุมพล พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Phlai Chumphon, Phitsanulok, 65000",
  },
  {
    id: 650116,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "มะขามสูง",
    subdistrictEn: "Makham Sung",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก มะขามสูง พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก มะขามสูง พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Makham Sung, Phitsanulok, 65000",
  },
  {
    id: 650117,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "อรัญญิก",
    subdistrictEn: "Aranyik",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก อรัญญิก พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก อรัญญิก พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Aranyik, Phitsanulok, 65000",
  },
  {
    id: 650118,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "บึงพระ",
    subdistrictEn: "Bueng Phra",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก บึงพระ พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก บึงพระ พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Bueng Phra, Phitsanulok, 65000",
  },
  {
    id: 650119,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "ไผ่ขอดอน",
    subdistrictEn: "Phai Kho Don",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65000,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก ไผ่ขอดอน พิษณุโลก 65000",
    name_th: "เมืองพิษณุโลก ไผ่ขอดอน พิษณุโลก 65000",
    name_en: "Mueang Phitsanulok, Phai Kho Don, Phitsanulok, 65000",
  },
  {
    id: 650120,
    districtTh: "เมืองพิษณุโลก",
    districtEn: "Mueang Phitsanulok",
    subdistrictTh: "งิ้วงาม",
    subdistrictEn: "Ngio Ngam",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65230,
    district_code: 6501,
    province_code: 52,
    name: "เมืองพิษณุโลก งิ้วงาม พิษณุโลก 65230",
    name_th: "เมืองพิษณุโลก งิ้วงาม พิษณุโลก 65230",
    name_en: "Mueang Phitsanulok, Ngio Ngam, Phitsanulok, 65230",
  },
  {
    id: 650201,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "นครไทย",
    subdistrictEn: "Nakhon Thai",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย นครไทย พิษณุโลก 65120",
    name_th: "นครไทย นครไทย พิษณุโลก 65120",
    name_en: "Nakhon Thai, Nakhon Thai, Phitsanulok, 65120",
  },
  {
    id: 650202,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "หนองกะท้าว",
    subdistrictEn: "Nong Kathao",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย หนองกะท้าว พิษณุโลก 65120",
    name_th: "นครไทย หนองกะท้าว พิษณุโลก 65120",
    name_en: "Nakhon Thai, Nong Kathao, Phitsanulok, 65120",
  },
  {
    id: 650203,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "บ้านแยง",
    subdistrictEn: "Ban Yaeng",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย บ้านแยง พิษณุโลก 65120",
    name_th: "นครไทย บ้านแยง พิษณุโลก 65120",
    name_en: "Nakhon Thai, Ban Yaeng, Phitsanulok, 65120",
  },
  {
    id: 650204,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "เนินเพิ่ม",
    subdistrictEn: "Noen Phoem",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย เนินเพิ่ม พิษณุโลก 65120",
    name_th: "นครไทย เนินเพิ่ม พิษณุโลก 65120",
    name_en: "Nakhon Thai, Noen Phoem, Phitsanulok, 65120",
  },
  {
    id: 650205,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "นาบัว",
    subdistrictEn: "Na Bua",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย นาบัว พิษณุโลก 65120",
    name_th: "นครไทย นาบัว พิษณุโลก 65120",
    name_en: "Nakhon Thai, Na Bua, Phitsanulok, 65120",
  },
  {
    id: 650206,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "นครชุม",
    subdistrictEn: "Nakhon Chum",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย นครชุม พิษณุโลก 65120",
    name_th: "นครไทย นครชุม พิษณุโลก 65120",
    name_en: "Nakhon Thai, Nakhon Chum, Phitsanulok, 65120",
  },
  {
    id: 650207,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "น้ำกุ่ม",
    subdistrictEn: "Nam Kum",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย น้ำกุ่ม พิษณุโลก 65120",
    name_th: "นครไทย น้ำกุ่ม พิษณุโลก 65120",
    name_en: "Nakhon Thai, Nam Kum, Phitsanulok, 65120",
  },
  {
    id: 650208,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "ยางโกลน",
    subdistrictEn: "Yang Klon",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย ยางโกลน พิษณุโลก 65120",
    name_th: "นครไทย ยางโกลน พิษณุโลก 65120",
    name_en: "Nakhon Thai, Yang Klon, Phitsanulok, 65120",
  },
  {
    id: 650209,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "บ่อโพธิ์",
    subdistrictEn: "Bo Pho",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย บ่อโพธิ์ พิษณุโลก 65120",
    name_th: "นครไทย บ่อโพธิ์ พิษณุโลก 65120",
    name_en: "Nakhon Thai, Bo Pho, Phitsanulok, 65120",
  },
  {
    id: 650210,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "บ้านพร้าว",
    subdistrictEn: "Ban Phrao",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย บ้านพร้าว พิษณุโลก 65120",
    name_th: "นครไทย บ้านพร้าว พิษณุโลก 65120",
    name_en: "Nakhon Thai, Ban Phrao, Phitsanulok, 65120",
  },
  {
    id: 650211,
    districtTh: "นครไทย",
    districtEn: "Nakhon Thai",
    subdistrictTh: "ห้วยเฮี้ย",
    subdistrictEn: "Huai Hia",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65120,
    district_code: 6502,
    province_code: 52,
    name: "นครไทย ห้วยเฮี้ย พิษณุโลก 65120",
    name_th: "นครไทย ห้วยเฮี้ย พิษณุโลก 65120",
    name_en: "Nakhon Thai, Huai Hia, Phitsanulok, 65120",
  },
  {
    id: 650301,
    districtTh: "ชาติตระการ",
    districtEn: "Chat Trakan",
    subdistrictTh: "ป่าแดง",
    subdistrictEn: "Pa Daeng",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65170,
    district_code: 6503,
    province_code: 52,
    name: "ชาติตระการ ป่าแดง พิษณุโลก 65170",
    name_th: "ชาติตระการ ป่าแดง พิษณุโลก 65170",
    name_en: "Chat Trakan, Pa Daeng, Phitsanulok, 65170",
  },
  {
    id: 650302,
    districtTh: "ชาติตระการ",
    districtEn: "Chat Trakan",
    subdistrictTh: "ชาติตระการ",
    subdistrictEn: "Chat Trakan",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65170,
    district_code: 6503,
    province_code: 52,
    name: "ชาติตระการ ชาติตระการ พิษณุโลก 65170",
    name_th: "ชาติตระการ ชาติตระการ พิษณุโลก 65170",
    name_en: "Chat Trakan, Chat Trakan, Phitsanulok, 65170",
  },
  {
    id: 650303,
    districtTh: "ชาติตระการ",
    districtEn: "Chat Trakan",
    subdistrictTh: "สวนเมี่ยง",
    subdistrictEn: "Suan Miang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65170,
    district_code: 6503,
    province_code: 52,
    name: "ชาติตระการ สวนเมี่ยง พิษณุโลก 65170",
    name_th: "ชาติตระการ สวนเมี่ยง พิษณุโลก 65170",
    name_en: "Chat Trakan, Suan Miang, Phitsanulok, 65170",
  },
  {
    id: 650304,
    districtTh: "ชาติตระการ",
    districtEn: "Chat Trakan",
    subdistrictTh: "บ้านดง",
    subdistrictEn: "Ban Dong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65170,
    district_code: 6503,
    province_code: 52,
    name: "ชาติตระการ บ้านดง พิษณุโลก 65170",
    name_th: "ชาติตระการ บ้านดง พิษณุโลก 65170",
    name_en: "Chat Trakan, Ban Dong, Phitsanulok, 65170",
  },
  {
    id: 650305,
    districtTh: "ชาติตระการ",
    districtEn: "Chat Trakan",
    subdistrictTh: "บ่อภาค",
    subdistrictEn: "Bo Phak",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65170,
    district_code: 6503,
    province_code: 52,
    name: "ชาติตระการ บ่อภาค พิษณุโลก 65170",
    name_th: "ชาติตระการ บ่อภาค พิษณุโลก 65170",
    name_en: "Chat Trakan, Bo Phak, Phitsanulok, 65170",
  },
  {
    id: 650306,
    districtTh: "ชาติตระการ",
    districtEn: "Chat Trakan",
    subdistrictTh: "ท่าสะแก",
    subdistrictEn: "Tha Sakae",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65170,
    district_code: 6503,
    province_code: 52,
    name: "ชาติตระการ ท่าสะแก พิษณุโลก 65170",
    name_th: "ชาติตระการ ท่าสะแก พิษณุโลก 65170",
    name_en: "Chat Trakan, Tha Sakae, Phitsanulok, 65170",
  },
  {
    id: 650401,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "บางระกำ",
    subdistrictEn: "Bang Rakam",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ บางระกำ พิษณุโลก 65140",
    name_th: "บางระกำ บางระกำ พิษณุโลก 65140",
    name_en: "Bang Rakam, Bang Rakam, Phitsanulok, 65140",
  },
  {
    id: 650402,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "ปลักแรด",
    subdistrictEn: "Plak Raet",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ ปลักแรด พิษณุโลก 65140",
    name_th: "บางระกำ ปลักแรด พิษณุโลก 65140",
    name_en: "Bang Rakam, Plak Raet, Phitsanulok, 65140",
  },
  {
    id: 650403,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "พันเสา",
    subdistrictEn: "Phan Sao",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ พันเสา พิษณุโลก 65140",
    name_th: "บางระกำ พันเสา พิษณุโลก 65140",
    name_en: "Bang Rakam, Phan Sao, Phitsanulok, 65140",
  },
  {
    id: 650404,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "วังอิทก",
    subdistrictEn: "Wang Ithok",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ วังอิทก พิษณุโลก 65140",
    name_th: "บางระกำ วังอิทก พิษณุโลก 65140",
    name_en: "Bang Rakam, Wang Ithok, Phitsanulok, 65140",
  },
  {
    id: 650405,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "บึงกอก",
    subdistrictEn: "Bueng Kok",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ บึงกอก พิษณุโลก 65140",
    name_th: "บางระกำ บึงกอก พิษณุโลก 65140",
    name_en: "Bang Rakam, Bueng Kok, Phitsanulok, 65140",
  },
  {
    id: 650406,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "หนองกุลา",
    subdistrictEn: "Nong Kula",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ หนองกุลา พิษณุโลก 65140",
    name_th: "บางระกำ หนองกุลา พิษณุโลก 65140",
    name_en: "Bang Rakam, Nong Kula, Phitsanulok, 65140",
  },
  {
    id: 650407,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "ชุมแสงสงคราม",
    subdistrictEn: "Chum Saeng Songkhram",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65240,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ ชุมแสงสงคราม พิษณุโลก 65240",
    name_th: "บางระกำ ชุมแสงสงคราม พิษณุโลก 65240",
    name_en: "Bang Rakam, Chum Saeng Songkhram, Phitsanulok, 65240",
  },
  {
    id: 650408,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "นิคมพัฒนา",
    subdistrictEn: "Nikhom Phatthana",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ นิคมพัฒนา พิษณุโลก 65140",
    name_th: "บางระกำ นิคมพัฒนา พิษณุโลก 65140",
    name_en: "Bang Rakam, Nikhom Phatthana, Phitsanulok, 65140",
  },
  {
    id: 650409,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "บ่อทอง",
    subdistrictEn: "Bo Thong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ บ่อทอง พิษณุโลก 65140",
    name_th: "บางระกำ บ่อทอง พิษณุโลก 65140",
    name_en: "Bang Rakam, Bo Thong, Phitsanulok, 65140",
  },
  {
    id: 650410,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "ท่านางงาม",
    subdistrictEn: "Tha Nang Ngam",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65140,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ ท่านางงาม พิษณุโลก 65140",
    name_th: "บางระกำ ท่านางงาม พิษณุโลก 65140",
    name_en: "Bang Rakam, Tha Nang Ngam, Phitsanulok, 65140",
  },
  {
    id: 650411,
    districtTh: "บางระกำ",
    districtEn: "Bang Rakam",
    subdistrictTh: "คุยม่วง",
    subdistrictEn: "Khui Muang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65240,
    district_code: 6504,
    province_code: 52,
    name: "บางระกำ คุยม่วง พิษณุโลก 65240",
    name_th: "บางระกำ คุยม่วง พิษณุโลก 65240",
    name_en: "Bang Rakam, Khui Muang, Phitsanulok, 65240",
  },
  {
    id: 650501,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "บางกระทุ่ม",
    subdistrictEn: "Bang Krathum",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65110,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม บางกระทุ่ม พิษณุโลก 65110",
    name_th: "บางกระทุ่ม บางกระทุ่ม พิษณุโลก 65110",
    name_en: "Bang Krathum, Bang Krathum, Phitsanulok, 65110",
  },
  {
    id: 650502,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "บ้านไร่",
    subdistrictEn: "Ban Rai",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65110,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม บ้านไร่ พิษณุโลก 65110",
    name_th: "บางกระทุ่ม บ้านไร่ พิษณุโลก 65110",
    name_en: "Bang Krathum, Ban Rai, Phitsanulok, 65110",
  },
  {
    id: 650503,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "โคกสลุด",
    subdistrictEn: "Khok Salut",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65110,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม โคกสลุด พิษณุโลก 65110",
    name_th: "บางกระทุ่ม โคกสลุด พิษณุโลก 65110",
    name_en: "Bang Krathum, Khok Salut, Phitsanulok, 65110",
  },
  {
    id: 650504,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "สนามคลี",
    subdistrictEn: "Sanam Khli",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65110,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม สนามคลี พิษณุโลก 65110",
    name_th: "บางกระทุ่ม สนามคลี พิษณุโลก 65110",
    name_en: "Bang Krathum, Sanam Khli, Phitsanulok, 65110",
  },
  {
    id: 650505,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "ท่าตาล",
    subdistrictEn: "Tha Tan",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65110,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม ท่าตาล พิษณุโลก 65110",
    name_th: "บางกระทุ่ม ท่าตาล พิษณุโลก 65110",
    name_en: "Bang Krathum, Tha Tan, Phitsanulok, 65110",
  },
  {
    id: 650506,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "ไผ่ล้อม",
    subdistrictEn: "Phai Lom",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65110,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม ไผ่ล้อม พิษณุโลก 65110",
    name_th: "บางกระทุ่ม ไผ่ล้อม พิษณุโลก 65110",
    name_en: "Bang Krathum, Phai Lom, Phitsanulok, 65110",
  },
  {
    id: 650507,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "นครป่าหมาก",
    subdistrictEn: "Nakhon Pa Mak",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65110,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม นครป่าหมาก พิษณุโลก 65110",
    name_th: "บางกระทุ่ม นครป่าหมาก พิษณุโลก 65110",
    name_en: "Bang Krathum, Nakhon Pa Mak, Phitsanulok, 65110",
  },
  {
    id: 650508,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "เนินกุ่ม",
    subdistrictEn: "Noen Kum",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65210,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม เนินกุ่ม พิษณุโลก 65210",
    name_th: "บางกระทุ่ม เนินกุ่ม พิษณุโลก 65210",
    name_en: "Bang Krathum, Noen Kum, Phitsanulok, 65210",
  },
  {
    id: 650509,
    districtTh: "บางกระทุ่ม",
    districtEn: "Bang Krathum",
    subdistrictTh: "วัดตายม",
    subdistrictEn: "Wat Ta Yom",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65210,
    district_code: 6505,
    province_code: 52,
    name: "บางกระทุ่ม วัดตายม พิษณุโลก 65210",
    name_th: "บางกระทุ่ม วัดตายม พิษณุโลก 65210",
    name_en: "Bang Krathum, Wat Ta Yom, Phitsanulok, 65210",
  },
  {
    id: 650601,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "พรหมพิราม",
    subdistrictEn: "Phrom Phiram",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65150,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม พรหมพิราม พิษณุโลก 65150",
    name_th: "พรหมพิราม พรหมพิราม พิษณุโลก 65150",
    name_en: "Phrom Phiram, Phrom Phiram, Phitsanulok, 65150",
  },
  {
    id: 650602,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65150,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม ท่าช้าง พิษณุโลก 65150",
    name_th: "พรหมพิราม ท่าช้าง พิษณุโลก 65150",
    name_en: "Phrom Phiram, Tha Chang, Phitsanulok, 65150",
  },
  {
    id: 650603,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "วงฆ้อง",
    subdistrictEn: "Wong Khong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65180,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม วงฆ้อง พิษณุโลก 65180",
    name_th: "พรหมพิราม วงฆ้อง พิษณุโลก 65180",
    name_en: "Phrom Phiram, Wong Khong, Phitsanulok, 65180",
  },
  {
    id: 650604,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "มะตูม",
    subdistrictEn: "Matum",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65150,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม มะตูม พิษณุโลก 65150",
    name_th: "พรหมพิราม มะตูม พิษณุโลก 65150",
    name_en: "Phrom Phiram, Matum, Phitsanulok, 65150",
  },
  {
    id: 650605,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "หอกลอง",
    subdistrictEn: "Ho Klong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65150,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม หอกลอง พิษณุโลก 65150",
    name_th: "พรหมพิราม หอกลอง พิษณุโลก 65150",
    name_en: "Phrom Phiram, Ho Klong, Phitsanulok, 65150",
  },
  {
    id: 650606,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "ศรีภิรมย์",
    subdistrictEn: "Si Phirom",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65180,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม ศรีภิรมย์ พิษณุโลก 65180",
    name_th: "พรหมพิราม ศรีภิรมย์ พิษณุโลก 65180",
    name_en: "Phrom Phiram, Si Phirom, Phitsanulok, 65180",
  },
  {
    id: 650607,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "ตลุกเทียม",
    subdistrictEn: "Taluk Thiam",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65180,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม ตลุกเทียม พิษณุโลก 65180",
    name_th: "พรหมพิราม ตลุกเทียม พิษณุโลก 65180",
    name_en: "Phrom Phiram, Taluk Thiam, Phitsanulok, 65180",
  },
  {
    id: 650608,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "วังวน",
    subdistrictEn: "Wang Won",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65150,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม วังวน พิษณุโลก 65150",
    name_th: "พรหมพิราม วังวน พิษณุโลก 65150",
    name_en: "Phrom Phiram, Wang Won, Phitsanulok, 65150",
  },
  {
    id: 650609,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "หนองแขม",
    subdistrictEn: "Nong Khaem",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65150,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม หนองแขม พิษณุโลก 65150",
    name_th: "พรหมพิราม หนองแขม พิษณุโลก 65150",
    name_en: "Phrom Phiram, Nong Khaem, Phitsanulok, 65150",
  },
  {
    id: 650610,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "มะต้อง",
    subdistrictEn: "Matong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65180,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม มะต้อง พิษณุโลก 65180",
    name_th: "พรหมพิราม มะต้อง พิษณุโลก 65180",
    name_en: "Phrom Phiram, Matong, Phitsanulok, 65180",
  },
  {
    id: 650611,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "ทับยายเชียง",
    subdistrictEn: "Thap Yai Chiang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65150,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม ทับยายเชียง พิษณุโลก 65150",
    name_th: "พรหมพิราม ทับยายเชียง พิษณุโลก 65150",
    name_en: "Phrom Phiram, Thap Yai Chiang, Phitsanulok, 65150",
  },
  {
    id: 650612,
    districtTh: "พรหมพิราม",
    districtEn: "Phrom Phiram",
    subdistrictTh: "ดงประคำ",
    subdistrictEn: "Dong Prakham",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65180,
    district_code: 6506,
    province_code: 52,
    name: "พรหมพิราม ดงประคำ พิษณุโลก 65180",
    name_th: "พรหมพิราม ดงประคำ พิษณุโลก 65180",
    name_en: "Phrom Phiram, Dong Prakham, Phitsanulok, 65180",
  },
  {
    id: 650701,
    districtTh: "วัดโบสถ์",
    districtEn: "Wat Bot",
    subdistrictTh: "วัดโบสถ์",
    subdistrictEn: "Wat Bot",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65160,
    district_code: 6507,
    province_code: 52,
    name: "วัดโบสถ์ วัดโบสถ์ พิษณุโลก 65160",
    name_th: "วัดโบสถ์ วัดโบสถ์ พิษณุโลก 65160",
    name_en: "Wat Bot, Wat Bot, Phitsanulok, 65160",
  },
  {
    id: 650702,
    districtTh: "วัดโบสถ์",
    districtEn: "Wat Bot",
    subdistrictTh: "ท่างาม",
    subdistrictEn: "Tha Ngam",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65160,
    district_code: 6507,
    province_code: 52,
    name: "วัดโบสถ์ ท่างาม พิษณุโลก 65160",
    name_th: "วัดโบสถ์ ท่างาม พิษณุโลก 65160",
    name_en: "Wat Bot, Tha Ngam, Phitsanulok, 65160",
  },
  {
    id: 650703,
    districtTh: "วัดโบสถ์",
    districtEn: "Wat Bot",
    subdistrictTh: "ท้อแท้",
    subdistrictEn: "Thothae",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65160,
    district_code: 6507,
    province_code: 52,
    name: "วัดโบสถ์ ท้อแท้ พิษณุโลก 65160",
    name_th: "วัดโบสถ์ ท้อแท้ พิษณุโลก 65160",
    name_en: "Wat Bot, Thothae, Phitsanulok, 65160",
  },
  {
    id: 650704,
    districtTh: "วัดโบสถ์",
    districtEn: "Wat Bot",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65160,
    district_code: 6507,
    province_code: 52,
    name: "วัดโบสถ์ บ้านยาง พิษณุโลก 65160",
    name_th: "วัดโบสถ์ บ้านยาง พิษณุโลก 65160",
    name_en: "Wat Bot, Ban Yang, Phitsanulok, 65160",
  },
  {
    id: 650705,
    districtTh: "วัดโบสถ์",
    districtEn: "Wat Bot",
    subdistrictTh: "หินลาด",
    subdistrictEn: "Hin Lat",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65160,
    district_code: 6507,
    province_code: 52,
    name: "วัดโบสถ์ หินลาด พิษณุโลก 65160",
    name_th: "วัดโบสถ์ หินลาด พิษณุโลก 65160",
    name_en: "Wat Bot, Hin Lat, Phitsanulok, 65160",
  },
  {
    id: 650706,
    districtTh: "วัดโบสถ์",
    districtEn: "Wat Bot",
    subdistrictTh: "คันโช้ง",
    subdistrictEn: "Khan Chong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65160,
    district_code: 6507,
    province_code: 52,
    name: "วัดโบสถ์ คันโช้ง พิษณุโลก 65160",
    name_th: "วัดโบสถ์ คันโช้ง พิษณุโลก 65160",
    name_en: "Wat Bot, Khan Chong, Phitsanulok, 65160",
  },
  {
    id: 650801,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "วังทอง",
    subdistrictEn: "Wang Thong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง วังทอง พิษณุโลก 65130",
    name_th: "วังทอง วังทอง พิษณุโลก 65130",
    name_en: "Wang Thong, Wang Thong, Phitsanulok, 65130",
  },
  {
    id: 650802,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "พันชาลี",
    subdistrictEn: "Phan Chali",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง พันชาลี พิษณุโลก 65130",
    name_th: "วังทอง พันชาลี พิษณุโลก 65130",
    name_en: "Wang Thong, Phan Chali, Phitsanulok, 65130",
  },
  {
    id: 650803,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "แม่ระกา",
    subdistrictEn: "Mae Raka",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง แม่ระกา พิษณุโลก 65130",
    name_th: "วังทอง แม่ระกา พิษณุโลก 65130",
    name_en: "Wang Thong, Mae Raka, Phitsanulok, 65130",
  },
  {
    id: 650804,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65220,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง บ้านกลาง พิษณุโลก 65220",
    name_th: "วังทอง บ้านกลาง พิษณุโลก 65220",
    name_en: "Wang Thong, Ban Klang, Phitsanulok, 65220",
  },
  {
    id: 650805,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "วังพิกุล",
    subdistrictEn: "Wang Phikun",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง วังพิกุล พิษณุโลก 65130",
    name_th: "วังทอง วังพิกุล พิษณุโลก 65130",
    name_en: "Wang Thong, Wang Phikun, Phitsanulok, 65130",
  },
  {
    id: 650806,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "แก่งโสภา",
    subdistrictEn: "Kaeng Sopha",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65220,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง แก่งโสภา พิษณุโลก 65220",
    name_th: "วังทอง แก่งโสภา พิษณุโลก 65220",
    name_en: "Wang Thong, Kaeng Sopha, Phitsanulok, 65220",
  },
  {
    id: 650807,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "ท่าหมื่นราม",
    subdistrictEn: "Tha Muen Ram",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง ท่าหมื่นราม พิษณุโลก 65130",
    name_th: "วังทอง ท่าหมื่นราม พิษณุโลก 65130",
    name_en: "Wang Thong, Tha Muen Ram, Phitsanulok, 65130",
  },
  {
    id: 650808,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "วังนกแอ่น",
    subdistrictEn: "Wang Nok Aen",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง วังนกแอ่น พิษณุโลก 65130",
    name_th: "วังทอง วังนกแอ่น พิษณุโลก 65130",
    name_en: "Wang Thong, Wang Nok Aen, Phitsanulok, 65130",
  },
  {
    id: 650809,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "หนองพระ",
    subdistrictEn: "Nong Phra",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง หนองพระ พิษณุโลก 65130",
    name_th: "วังทอง หนองพระ พิษณุโลก 65130",
    name_en: "Wang Thong, Nong Phra, Phitsanulok, 65130",
  },
  {
    id: 650810,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "ชัยนาม",
    subdistrictEn: "Chaiyanam",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง ชัยนาม พิษณุโลก 65130",
    name_th: "วังทอง ชัยนาม พิษณุโลก 65130",
    name_en: "Wang Thong, Chaiyanam, Phitsanulok, 65130",
  },
  {
    id: 650811,
    districtTh: "วังทอง",
    districtEn: "Wang Thong",
    subdistrictTh: "ดินทอง",
    subdistrictEn: "Din Thong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65130,
    district_code: 6508,
    province_code: 52,
    name: "วังทอง ดินทอง พิษณุโลก 65130",
    name_th: "วังทอง ดินทอง พิษณุโลก 65130",
    name_en: "Wang Thong, Din Thong, Phitsanulok, 65130",
  },
  {
    id: 650901,
    districtTh: "เนินมะปราง",
    districtEn: "Noen Maprang",
    subdistrictTh: "ชมพู",
    subdistrictEn: "Chomphu",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65190,
    district_code: 6509,
    province_code: 52,
    name: "เนินมะปราง ชมพู พิษณุโลก 65190",
    name_th: "เนินมะปราง ชมพู พิษณุโลก 65190",
    name_en: "Noen Maprang, Chomphu, Phitsanulok, 65190",
  },
  {
    id: 650902,
    districtTh: "เนินมะปราง",
    districtEn: "Noen Maprang",
    subdistrictTh: "บ้านมุง",
    subdistrictEn: "Ban Mung",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65190,
    district_code: 6509,
    province_code: 52,
    name: "เนินมะปราง บ้านมุง พิษณุโลก 65190",
    name_th: "เนินมะปราง บ้านมุง พิษณุโลก 65190",
    name_en: "Noen Maprang, Ban Mung, Phitsanulok, 65190",
  },
  {
    id: 650903,
    districtTh: "เนินมะปราง",
    districtEn: "Noen Maprang",
    subdistrictTh: "ไทรย้อย",
    subdistrictEn: "Sai Yoi",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65190,
    district_code: 6509,
    province_code: 52,
    name: "เนินมะปราง ไทรย้อย พิษณุโลก 65190",
    name_th: "เนินมะปราง ไทรย้อย พิษณุโลก 65190",
    name_en: "Noen Maprang, Sai Yoi, Phitsanulok, 65190",
  },
  {
    id: 650904,
    districtTh: "เนินมะปราง",
    districtEn: "Noen Maprang",
    subdistrictTh: "วังโพรง",
    subdistrictEn: "Wang Phrong",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65190,
    district_code: 6509,
    province_code: 52,
    name: "เนินมะปราง วังโพรง พิษณุโลก 65190",
    name_th: "เนินมะปราง วังโพรง พิษณุโลก 65190",
    name_en: "Noen Maprang, Wang Phrong, Phitsanulok, 65190",
  },
  {
    id: 650905,
    districtTh: "เนินมะปราง",
    districtEn: "Noen Maprang",
    subdistrictTh: "บ้านน้อยซุ้มขี้เหล็ก",
    subdistrictEn: "Ban Noi Sum Khilek",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65190,
    district_code: 6509,
    province_code: 52,
    name: "เนินมะปราง บ้านน้อยซุ้มขี้เหล็ก พิษณุโลก 65190",
    name_th: "เนินมะปราง บ้านน้อยซุ้มขี้เหล็ก พิษณุโลก 65190",
    name_en: "Noen Maprang, Ban Noi Sum Khilek, Phitsanulok, 65190",
  },
  {
    id: 650906,
    districtTh: "เนินมะปราง",
    districtEn: "Noen Maprang",
    subdistrictTh: "เนินมะปราง",
    subdistrictEn: "Noen Maprang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65190,
    district_code: 6509,
    province_code: 52,
    name: "เนินมะปราง เนินมะปราง พิษณุโลก 65190",
    name_th: "เนินมะปราง เนินมะปราง พิษณุโลก 65190",
    name_en: "Noen Maprang, Noen Maprang, Phitsanulok, 65190",
  },
  {
    id: 650907,
    districtTh: "เนินมะปราง",
    districtEn: "Noen Maprang",
    subdistrictTh: "วังยาง",
    subdistrictEn: "Wang Yang",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65190,
    district_code: 6509,
    province_code: 52,
    name: "เนินมะปราง วังยาง พิษณุโลก 65190",
    name_th: "เนินมะปราง วังยาง พิษณุโลก 65190",
    name_en: "Noen Maprang, Wang Yang, Phitsanulok, 65190",
  },
  {
    id: 650908,
    districtTh: "เนินมะปราง",
    districtEn: "Noen Maprang",
    subdistrictTh: "โคกแหลม",
    subdistrictEn: "Khok Laem",
    provinceTh: "พิษณุโลก",
    provinceEn: "Phitsanulok",
    zipcode: 65190,
    district_code: 6509,
    province_code: 52,
    name: "เนินมะปราง โคกแหลม พิษณุโลก 65190",
    name_th: "เนินมะปราง โคกแหลม พิษณุโลก 65190",
    name_en: "Noen Maprang, Khok Laem, Phitsanulok, 65190",
  },
  {
    id: 660101,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ในเมือง พิจิตร 66000",
    name_th: "เมืองพิจิตร ในเมือง พิจิตร 66000",
    name_en: "Mueang Phichit, Nai Mueang, Phichit, 66000",
  },
  {
    id: 660102,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ไผ่ขวาง",
    subdistrictEn: "Phai Khwang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ไผ่ขวาง พิจิตร 66000",
    name_th: "เมืองพิจิตร ไผ่ขวาง พิจิตร 66000",
    name_en: "Mueang Phichit, Phai Khwang, Phichit, 66000",
  },
  {
    id: 660103,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ย่านยาว",
    subdistrictEn: "Yan Yao",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ย่านยาว พิจิตร 66000",
    name_th: "เมืองพิจิตร ย่านยาว พิจิตร 66000",
    name_en: "Mueang Phichit, Yan Yao, Phichit, 66000",
  },
  {
    id: 660104,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ท่าฬ่อ",
    subdistrictEn: "Tha Lo",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ท่าฬ่อ พิจิตร 66000",
    name_th: "เมืองพิจิตร ท่าฬ่อ พิจิตร 66000",
    name_en: "Mueang Phichit, Tha Lo, Phichit, 66000",
  },
  {
    id: 660105,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ปากทาง",
    subdistrictEn: "Pak Thang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ปากทาง พิจิตร 66000",
    name_th: "เมืองพิจิตร ปากทาง พิจิตร 66000",
    name_en: "Mueang Phichit, Pak Thang, Phichit, 66000",
  },
  {
    id: 660106,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "คลองคะเชนทร์",
    subdistrictEn: "Khlong Khachen",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร คลองคะเชนทร์ พิจิตร 66000",
    name_th: "เมืองพิจิตร คลองคะเชนทร์ พิจิตร 66000",
    name_en: "Mueang Phichit, Khlong Khachen, Phichit, 66000",
  },
  {
    id: 660107,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "โรงช้าง",
    subdistrictEn: "Rong Chang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร โรงช้าง พิจิตร 66000",
    name_th: "เมืองพิจิตร โรงช้าง พิจิตร 66000",
    name_en: "Mueang Phichit, Rong Chang, Phichit, 66000",
  },
  {
    id: 660108,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "เมืองเก่า",
    subdistrictEn: "Mueang Kao",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร เมืองเก่า พิจิตร 66000",
    name_th: "เมืองพิจิตร เมืองเก่า พิจิตร 66000",
    name_en: "Mueang Phichit, Mueang Kao, Phichit, 66000",
  },
  {
    id: 660109,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ท่าหลวง",
    subdistrictEn: "Tha Luang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ท่าหลวง พิจิตร 66000",
    name_th: "เมืองพิจิตร ท่าหลวง พิจิตร 66000",
    name_en: "Mueang Phichit, Tha Luang, Phichit, 66000",
  },
  {
    id: 660110,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "บ้านบุ่ง",
    subdistrictEn: "Ban Bung",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร บ้านบุ่ง พิจิตร 66000",
    name_th: "เมืองพิจิตร บ้านบุ่ง พิจิตร 66000",
    name_en: "Mueang Phichit, Ban Bung, Phichit, 66000",
  },
  {
    id: 660111,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ฆะมัง",
    subdistrictEn: "Khamang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ฆะมัง พิจิตร 66000",
    name_th: "เมืองพิจิตร ฆะมัง พิจิตร 66000",
    name_en: "Mueang Phichit, Khamang, Phichit, 66000",
  },
  {
    id: 660112,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ดงป่าคำ",
    subdistrictEn: "Dong Pa Kham",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66170,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ดงป่าคำ พิจิตร 66170",
    name_th: "เมืองพิจิตร ดงป่าคำ พิจิตร 66170",
    name_en: "Mueang Phichit, Dong Pa Kham, Phichit, 66170",
  },
  {
    id: 660113,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "หัวดง",
    subdistrictEn: "Hua Dong",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66170,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร หัวดง พิจิตร 66170",
    name_th: "เมืองพิจิตร หัวดง พิจิตร 66170",
    name_en: "Mueang Phichit, Hua Dong, Phichit, 66170",
  },
  {
    id: 660115,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ป่ามะคาบ",
    subdistrictEn: "Pa Makhap",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ป่ามะคาบ พิจิตร 66000",
    name_th: "เมืองพิจิตร ป่ามะคาบ พิจิตร 66000",
    name_en: "Mueang Phichit, Pa Makhap, Phichit, 66000",
  },
  {
    id: 660119,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "สายคำโห้",
    subdistrictEn: "Sai Kham Ho",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66000,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร สายคำโห้ พิจิตร 66000",
    name_th: "เมืองพิจิตร สายคำโห้ พิจิตร 66000",
    name_en: "Mueang Phichit, Sai Kham Ho, Phichit, 66000",
  },
  {
    id: 660120,
    districtTh: "เมืองพิจิตร",
    districtEn: "Mueang Phichit",
    subdistrictTh: "ดงกลาง",
    subdistrictEn: "Dong Klang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66170,
    district_code: 6601,
    province_code: 53,
    name: "เมืองพิจิตร ดงกลาง พิจิตร 66170",
    name_th: "เมืองพิจิตร ดงกลาง พิจิตร 66170",
    name_en: "Mueang Phichit, Dong Klang, Phichit, 66170",
  },
  {
    id: 660201,
    districtTh: "วังทรายพูน",
    districtEn: "Wang Sai Phun",
    subdistrictTh: "วังทรายพูน",
    subdistrictEn: "Wang Sai Phun",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66180,
    district_code: 6602,
    province_code: 53,
    name: "วังทรายพูน วังทรายพูน พิจิตร 66180",
    name_th: "วังทรายพูน วังทรายพูน พิจิตร 66180",
    name_en: "Wang Sai Phun, Wang Sai Phun, Phichit, 66180",
  },
  {
    id: 660202,
    districtTh: "วังทรายพูน",
    districtEn: "Wang Sai Phun",
    subdistrictTh: "หนองปลาไหล",
    subdistrictEn: "Nong Pla Lai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66180,
    district_code: 6602,
    province_code: 53,
    name: "วังทรายพูน หนองปลาไหล พิจิตร 66180",
    name_th: "วังทรายพูน หนองปลาไหล พิจิตร 66180",
    name_en: "Wang Sai Phun, Nong Pla Lai, Phichit, 66180",
  },
  {
    id: 660203,
    districtTh: "วังทรายพูน",
    districtEn: "Wang Sai Phun",
    subdistrictTh: "หนองพระ",
    subdistrictEn: "Nong Phra",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66180,
    district_code: 6602,
    province_code: 53,
    name: "วังทรายพูน หนองพระ พิจิตร 66180",
    name_th: "วังทรายพูน หนองพระ พิจิตร 66180",
    name_en: "Wang Sai Phun, Nong Phra, Phichit, 66180",
  },
  {
    id: 660204,
    districtTh: "วังทรายพูน",
    districtEn: "Wang Sai Phun",
    subdistrictTh: "หนองปล้อง",
    subdistrictEn: "Nong Plong",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66180,
    district_code: 6602,
    province_code: 53,
    name: "วังทรายพูน หนองปล้อง พิจิตร 66180",
    name_th: "วังทรายพูน หนองปล้อง พิจิตร 66180",
    name_en: "Wang Sai Phun, Nong Plong, Phichit, 66180",
  },
  {
    id: 660301,
    districtTh: "โพธิ์ประทับช้าง",
    districtEn: "Pho Prathap Chang",
    subdistrictTh: "โพธิ์ประทับช้าง",
    subdistrictEn: "Pho Prathap Chang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66190,
    district_code: 6603,
    province_code: 53,
    name: "โพธิ์ประทับช้าง โพธิ์ประทับช้าง พิจิตร 66190",
    name_th: "โพธิ์ประทับช้าง โพธิ์ประทับช้าง พิจิตร 66190",
    name_en: "Pho Prathap Chang, Pho Prathap Chang, Phichit, 66190",
  },
  {
    id: 660302,
    districtTh: "โพธิ์ประทับช้าง",
    districtEn: "Pho Prathap Chang",
    subdistrictTh: "ไผ่ท่าโพ",
    subdistrictEn: "Phai Tha Pho",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66190,
    district_code: 6603,
    province_code: 53,
    name: "โพธิ์ประทับช้าง ไผ่ท่าโพ พิจิตร 66190",
    name_th: "โพธิ์ประทับช้าง ไผ่ท่าโพ พิจิตร 66190",
    name_en: "Pho Prathap Chang, Phai Tha Pho, Phichit, 66190",
  },
  {
    id: 660303,
    districtTh: "โพธิ์ประทับช้าง",
    districtEn: "Pho Prathap Chang",
    subdistrictTh: "วังจิก",
    subdistrictEn: "Wang Chik",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66190,
    district_code: 6603,
    province_code: 53,
    name: "โพธิ์ประทับช้าง วังจิก พิจิตร 66190",
    name_th: "โพธิ์ประทับช้าง วังจิก พิจิตร 66190",
    name_en: "Pho Prathap Chang, Wang Chik, Phichit, 66190",
  },
  {
    id: 660304,
    districtTh: "โพธิ์ประทับช้าง",
    districtEn: "Pho Prathap Chang",
    subdistrictTh: "ไผ่รอบ",
    subdistrictEn: "Phai Rop",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66190,
    district_code: 6603,
    province_code: 53,
    name: "โพธิ์ประทับช้าง ไผ่รอบ พิจิตร 66190",
    name_th: "โพธิ์ประทับช้าง ไผ่รอบ พิจิตร 66190",
    name_en: "Pho Prathap Chang, Phai Rop, Phichit, 66190",
  },
  {
    id: 660305,
    districtTh: "โพธิ์ประทับช้าง",
    districtEn: "Pho Prathap Chang",
    subdistrictTh: "ดงเสือเหลือง",
    subdistrictEn: "Dong Suea Lueang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66190,
    district_code: 6603,
    province_code: 53,
    name: "โพธิ์ประทับช้าง ดงเสือเหลือง พิจิตร 66190",
    name_th: "โพธิ์ประทับช้าง ดงเสือเหลือง พิจิตร 66190",
    name_en: "Pho Prathap Chang, Dong Suea Lueang, Phichit, 66190",
  },
  {
    id: 660306,
    districtTh: "โพธิ์ประทับช้าง",
    districtEn: "Pho Prathap Chang",
    subdistrictTh: "เนินสว่าง",
    subdistrictEn: "Noen Sawang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66190,
    district_code: 6603,
    province_code: 53,
    name: "โพธิ์ประทับช้าง เนินสว่าง พิจิตร 66190",
    name_th: "โพธิ์ประทับช้าง เนินสว่าง พิจิตร 66190",
    name_en: "Pho Prathap Chang, Noen Sawang, Phichit, 66190",
  },
  {
    id: 660307,
    districtTh: "โพธิ์ประทับช้าง",
    districtEn: "Pho Prathap Chang",
    subdistrictTh: "ทุ่งใหญ่",
    subdistrictEn: "Thung Yai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66190,
    district_code: 6603,
    province_code: 53,
    name: "โพธิ์ประทับช้าง ทุ่งใหญ่ พิจิตร 66190",
    name_th: "โพธิ์ประทับช้าง ทุ่งใหญ่ พิจิตร 66190",
    name_en: "Pho Prathap Chang, Thung Yai, Phichit, 66190",
  },
  {
    id: 660401,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "ตะพานหิน",
    subdistrictEn: "Taphan Hin",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน ตะพานหิน พิจิตร 66110",
    name_th: "ตะพานหิน ตะพานหิน พิจิตร 66110",
    name_en: "Taphan Hin, Taphan Hin, Phichit, 66110",
  },
  {
    id: 660402,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "งิ้วราย",
    subdistrictEn: "Ngio Rai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน งิ้วราย พิจิตร 66110",
    name_th: "ตะพานหิน งิ้วราย พิจิตร 66110",
    name_en: "Taphan Hin, Ngio Rai, Phichit, 66110",
  },
  {
    id: 660403,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "ห้วยเกตุ",
    subdistrictEn: "Huai Ket",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน ห้วยเกตุ พิจิตร 66110",
    name_th: "ตะพานหิน ห้วยเกตุ พิจิตร 66110",
    name_en: "Taphan Hin, Huai Ket, Phichit, 66110",
  },
  {
    id: 660404,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "ไทรโรงโขน",
    subdistrictEn: "Sai Rong Khon",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน ไทรโรงโขน พิจิตร 66110",
    name_th: "ตะพานหิน ไทรโรงโขน พิจิตร 66110",
    name_en: "Taphan Hin, Sai Rong Khon, Phichit, 66110",
  },
  {
    id: 660405,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "หนองพยอม",
    subdistrictEn: "Nong Phayom",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน หนองพยอม พิจิตร 66110",
    name_th: "ตะพานหิน หนองพยอม พิจิตร 66110",
    name_en: "Taphan Hin, Nong Phayom, Phichit, 66110",
  },
  {
    id: 660406,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "ทุ่งโพธิ์",
    subdistrictEn: "Tung Pho",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66150,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน ทุ่งโพธิ์ พิจิตร 66150",
    name_th: "ตะพานหิน ทุ่งโพธิ์ พิจิตร 66150",
    name_en: "Taphan Hin, Tung Pho, Phichit, 66150",
  },
  {
    id: 660407,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "ดงตะขบ",
    subdistrictEn: "Dong Takhop",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน ดงตะขบ พิจิตร 66110",
    name_th: "ตะพานหิน ดงตะขบ พิจิตร 66110",
    name_en: "Taphan Hin, Dong Takhop, Phichit, 66110",
  },
  {
    id: 660408,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "คลองคูณ",
    subdistrictEn: "Khlong Khun",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน คลองคูณ พิจิตร 66110",
    name_th: "ตะพานหิน คลองคูณ พิจิตร 66110",
    name_en: "Taphan Hin, Khlong Khun, Phichit, 66110",
  },
  {
    id: 660409,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "วังสำโรง",
    subdistrictEn: "Wang Samrong",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน วังสำโรง พิจิตร 66110",
    name_th: "ตะพานหิน วังสำโรง พิจิตร 66110",
    name_en: "Taphan Hin, Wang Samrong, Phichit, 66110",
  },
  {
    id: 660410,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "วังหว้า",
    subdistrictEn: "Wang Wa",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน วังหว้า พิจิตร 66110",
    name_th: "ตะพานหิน วังหว้า พิจิตร 66110",
    name_en: "Taphan Hin, Wang Wa, Phichit, 66110",
  },
  {
    id: 660411,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "วังหลุม",
    subdistrictEn: "Wang Lum",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66150,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน วังหลุม พิจิตร 66150",
    name_th: "ตะพานหิน วังหลุม พิจิตร 66150",
    name_en: "Taphan Hin, Wang Lum, Phichit, 66150",
  },
  {
    id: 660412,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "ทับหมัน",
    subdistrictEn: "Thap Man",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน ทับหมัน พิจิตร 66110",
    name_th: "ตะพานหิน ทับหมัน พิจิตร 66110",
    name_en: "Taphan Hin, Thap Man, Phichit, 66110",
  },
  {
    id: 660413,
    districtTh: "ตะพานหิน",
    districtEn: "Taphan Hin",
    subdistrictTh: "ไผ่หลวง",
    subdistrictEn: "Phai Luang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66110,
    district_code: 6604,
    province_code: 53,
    name: "ตะพานหิน ไผ่หลวง พิจิตร 66110",
    name_th: "ตะพานหิน ไผ่หลวง พิจิตร 66110",
    name_en: "Taphan Hin, Phai Luang, Phichit, 66110",
  },
  {
    id: 660501,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "บางมูลนาก",
    subdistrictEn: "Bang Mun Nak",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก บางมูลนาก พิจิตร 66120",
    name_th: "บางมูลนาก บางมูลนาก พิจิตร 66120",
    name_en: "Bang Mun Nak, Bang Mun Nak, Phichit, 66120",
  },
  {
    id: 660502,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "บางไผ่",
    subdistrictEn: "Bang Phai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก บางไผ่ พิจิตร 66120",
    name_th: "บางมูลนาก บางไผ่ พิจิตร 66120",
    name_en: "Bang Mun Nak, Bang Phai, Phichit, 66120",
  },
  {
    id: 660503,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "หอไกร",
    subdistrictEn: "Ho Krai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก หอไกร พิจิตร 66120",
    name_th: "บางมูลนาก หอไกร พิจิตร 66120",
    name_en: "Bang Mun Nak, Ho Krai, Phichit, 66120",
  },
  {
    id: 660504,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "เนินมะกอก",
    subdistrictEn: "Noen Makok",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก เนินมะกอก พิจิตร 66120",
    name_th: "บางมูลนาก เนินมะกอก พิจิตร 66120",
    name_en: "Bang Mun Nak, Noen Makok, Phichit, 66120",
  },
  {
    id: 660505,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "วังสำโรง",
    subdistrictEn: "Wang Samrong",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก วังสำโรง พิจิตร 66120",
    name_th: "บางมูลนาก วังสำโรง พิจิตร 66120",
    name_en: "Bang Mun Nak, Wang Samrong, Phichit, 66120",
  },
  {
    id: 660506,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "ภูมิ",
    subdistrictEn: "Phum",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก ภูมิ พิจิตร 66120",
    name_th: "บางมูลนาก ภูมิ พิจิตร 66120",
    name_en: "Bang Mun Nak, Phum, Phichit, 66120",
  },
  {
    id: 660507,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "วังกรด",
    subdistrictEn: "Wang Krot",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก วังกรด พิจิตร 66120",
    name_th: "บางมูลนาก วังกรด พิจิตร 66120",
    name_en: "Bang Mun Nak, Wang Krot, Phichit, 66120",
  },
  {
    id: 660508,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "ห้วยเขน",
    subdistrictEn: "Huai Khen",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก ห้วยเขน พิจิตร 66120",
    name_th: "บางมูลนาก ห้วยเขน พิจิตร 66120",
    name_en: "Bang Mun Nak, Huai Khen, Phichit, 66120",
  },
  {
    id: 660509,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "วังตะกู",
    subdistrictEn: "Wang Taku",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66210,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก วังตะกู พิจิตร 66210",
    name_th: "บางมูลนาก วังตะกู พิจิตร 66210",
    name_en: "Bang Mun Nak, Wang Taku, Phichit, 66210",
  },
  {
    id: 660514,
    districtTh: "บางมูลนาก",
    districtEn: "Bang Mun Nak",
    subdistrictTh: "ลำประดา",
    subdistrictEn: "Lam Prad",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66120,
    district_code: 6605,
    province_code: 53,
    name: "บางมูลนาก ลำประดา พิจิตร 66120",
    name_th: "บางมูลนาก ลำประดา พิจิตร 66120",
    name_en: "Bang Mun Nak, Lam Prad, Phichit, 66120",
  },
  {
    id: 660601,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "โพทะเล",
    subdistrictEn: "Pho Thale",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล โพทะเล พิจิตร 66130",
    name_th: "โพทะเล โพทะเล พิจิตร 66130",
    name_en: "Pho Thale, Pho Thale, Phichit, 66130",
  },
  {
    id: 660602,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "ท้ายน้ำ",
    subdistrictEn: "Thai Nam",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล ท้ายน้ำ พิจิตร 66130",
    name_th: "โพทะเล ท้ายน้ำ พิจิตร 66130",
    name_en: "Pho Thale, Thai Nam, Phichit, 66130",
  },
  {
    id: 660604,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "ท่าบัว",
    subdistrictEn: "Tha Bua",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล ท่าบัว พิจิตร 66130",
    name_th: "โพทะเล ท่าบัว พิจิตร 66130",
    name_en: "Pho Thale, Tha Bua, Phichit, 66130",
  },
  {
    id: 660605,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "ทุ่งน้อย",
    subdistrictEn: "Thung Noi",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล ทุ่งน้อย พิจิตร 66130",
    name_th: "โพทะเล ทุ่งน้อย พิจิตร 66130",
    name_en: "Pho Thale, Thung Noi, Phichit, 66130",
  },
  {
    id: 660606,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "ท่าขมิ้น",
    subdistrictEn: "Tha Khamin",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล ท่าขมิ้น พิจิตร 66130",
    name_th: "โพทะเล ท่าขมิ้น พิจิตร 66130",
    name_en: "Pho Thale, Tha Khamin, Phichit, 66130",
  },
  {
    id: 660607,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "ท่าเสา",
    subdistrictEn: "Tha Sao",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล ท่าเสา พิจิตร 66130",
    name_th: "โพทะเล ท่าเสา พิจิตร 66130",
    name_en: "Pho Thale, Tha Sao, Phichit, 66130",
  },
  {
    id: 660608,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "บางคลาน",
    subdistrictEn: "Bang Khlan",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล บางคลาน พิจิตร 66130",
    name_th: "โพทะเล บางคลาน พิจิตร 66130",
    name_en: "Pho Thale, Bang Khlan, Phichit, 66130",
  },
  {
    id: 660611,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "ท่านั่ง",
    subdistrictEn: "Tha Nang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล ท่านั่ง พิจิตร 66130",
    name_th: "โพทะเล ท่านั่ง พิจิตร 66130",
    name_en: "Pho Thale, Tha Nang, Phichit, 66130",
  },
  {
    id: 660612,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "บ้านน้อย",
    subdistrictEn: "Ban Noi",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล บ้านน้อย พิจิตร 66130",
    name_th: "โพทะเล บ้านน้อย พิจิตร 66130",
    name_en: "Pho Thale, Ban Noi, Phichit, 66130",
  },
  {
    id: 660613,
    districtTh: "โพทะเล",
    districtEn: "Pho Thale",
    subdistrictTh: "วัดขวาง",
    subdistrictEn: "Wat Khwang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6606,
    province_code: 53,
    name: "โพทะเล วัดขวาง พิจิตร 66130",
    name_th: "โพทะเล วัดขวาง พิจิตร 66130",
    name_en: "Pho Thale, Wat Khwang, Phichit, 66130",
  },
  {
    id: 660701,
    districtTh: "สามง่าม",
    districtEn: "Sam Ngam",
    subdistrictTh: "สามง่าม",
    subdistrictEn: "Sam Ngam",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66140,
    district_code: 6607,
    province_code: 53,
    name: "สามง่าม สามง่าม พิจิตร 66140",
    name_th: "สามง่าม สามง่าม พิจิตร 66140",
    name_en: "Sam Ngam, Sam Ngam, Phichit, 66140",
  },
  {
    id: 660702,
    districtTh: "สามง่าม",
    districtEn: "Sam Ngam",
    subdistrictTh: "กำแพงดิน",
    subdistrictEn: "Kamphaeng Din",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66140,
    district_code: 6607,
    province_code: 53,
    name: "สามง่าม กำแพงดิน พิจิตร 66140",
    name_th: "สามง่าม กำแพงดิน พิจิตร 66140",
    name_en: "Sam Ngam, Kamphaeng Din, Phichit, 66140",
  },
  {
    id: 660703,
    districtTh: "สามง่าม",
    districtEn: "Sam Ngam",
    subdistrictTh: "รังนก",
    subdistrictEn: "Rang Nok",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66140,
    district_code: 6607,
    province_code: 53,
    name: "สามง่าม รังนก พิจิตร 66140",
    name_th: "สามง่าม รังนก พิจิตร 66140",
    name_en: "Sam Ngam, Rang Nok, Phichit, 66140",
  },
  {
    id: 660706,
    districtTh: "สามง่าม",
    districtEn: "Sam Ngam",
    subdistrictTh: "เนินปอ",
    subdistrictEn: "Noen Po",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66140,
    district_code: 6607,
    province_code: 53,
    name: "สามง่าม เนินปอ พิจิตร 66140",
    name_th: "สามง่าม เนินปอ พิจิตร 66140",
    name_en: "Sam Ngam, Noen Po, Phichit, 66140",
  },
  {
    id: 660707,
    districtTh: "สามง่าม",
    districtEn: "Sam Ngam",
    subdistrictTh: "หนองโสน",
    subdistrictEn: "Nong Sano",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66140,
    district_code: 6607,
    province_code: 53,
    name: "สามง่าม หนองโสน พิจิตร 66140",
    name_th: "สามง่าม หนองโสน พิจิตร 66140",
    name_en: "Sam Ngam, Nong Sano, Phichit, 66140",
  },
  {
    id: 660801,
    districtTh: "ทับคล้อ",
    districtEn: "Tap Khlo",
    subdistrictTh: "ทับคล้อ",
    subdistrictEn: "Thap Khlo",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66150,
    district_code: 6608,
    province_code: 53,
    name: "ทับคล้อ ทับคล้อ พิจิตร 66150",
    name_th: "ทับคล้อ ทับคล้อ พิจิตร 66150",
    name_en: "Tap Khlo, Thap Khlo, Phichit, 66150",
  },
  {
    id: 660802,
    districtTh: "ทับคล้อ",
    districtEn: "Tap Khlo",
    subdistrictTh: "เขาทราย",
    subdistrictEn: "Khao Sai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66230,
    district_code: 6608,
    province_code: 53,
    name: "ทับคล้อ เขาทราย พิจิตร 66230",
    name_th: "ทับคล้อ เขาทราย พิจิตร 66230",
    name_en: "Tap Khlo, Khao Sai, Phichit, 66230",
  },
  {
    id: 660803,
    districtTh: "ทับคล้อ",
    districtEn: "Tap Khlo",
    subdistrictTh: "เขาเจ็ดลูก",
    subdistrictEn: "Khao Chet Luk",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66230,
    district_code: 6608,
    province_code: 53,
    name: "ทับคล้อ เขาเจ็ดลูก พิจิตร 66230",
    name_th: "ทับคล้อ เขาเจ็ดลูก พิจิตร 66230",
    name_en: "Tap Khlo, Khao Chet Luk, Phichit, 66230",
  },
  {
    id: 660804,
    districtTh: "ทับคล้อ",
    districtEn: "Tap Khlo",
    subdistrictTh: "ท้ายทุ่ง",
    subdistrictEn: "Tai Toong",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66150,
    district_code: 6608,
    province_code: 53,
    name: "ทับคล้อ ท้ายทุ่ง พิจิตร 66150",
    name_th: "ทับคล้อ ท้ายทุ่ง พิจิตร 66150",
    name_en: "Tap Khlo, Tai Toong, Phichit, 66150",
  },
  {
    id: 660901,
    districtTh: "สากเหล็ก",
    districtEn: "Sak Lek",
    subdistrictTh: "สากเหล็ก",
    subdistrictEn: "Sak Lek",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66160,
    district_code: 6609,
    province_code: 53,
    name: "สากเหล็ก สากเหล็ก พิจิตร 66160",
    name_th: "สากเหล็ก สากเหล็ก พิจิตร 66160",
    name_en: "Sak Lek, Sak Lek, Phichit, 66160",
  },
  {
    id: 660902,
    districtTh: "สากเหล็ก",
    districtEn: "Sak Lek",
    subdistrictTh: "ท่าเยี่ยม",
    subdistrictEn: "Tha Yiam",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66160,
    district_code: 6609,
    province_code: 53,
    name: "สากเหล็ก ท่าเยี่ยม พิจิตร 66160",
    name_th: "สากเหล็ก ท่าเยี่ยม พิจิตร 66160",
    name_en: "Sak Lek, Tha Yiam, Phichit, 66160",
  },
  {
    id: 660903,
    districtTh: "สากเหล็ก",
    districtEn: "Sak Lek",
    subdistrictTh: "คลองทราย",
    subdistrictEn: "Khlong Sai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66160,
    district_code: 6609,
    province_code: 53,
    name: "สากเหล็ก คลองทราย พิจิตร 66160",
    name_th: "สากเหล็ก คลองทราย พิจิตร 66160",
    name_en: "Sak Lek, Khlong Sai, Phichit, 66160",
  },
  {
    id: 660904,
    districtTh: "สากเหล็ก",
    districtEn: "Sak Lek",
    subdistrictTh: "หนองหญ้าไทร",
    subdistrictEn: "Nong Ya Sai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66160,
    district_code: 6609,
    province_code: 53,
    name: "สากเหล็ก หนองหญ้าไทร พิจิตร 66160",
    name_th: "สากเหล็ก หนองหญ้าไทร พิจิตร 66160",
    name_en: "Sak Lek, Nong Ya Sai, Phichit, 66160",
  },
  {
    id: 660905,
    districtTh: "สากเหล็ก",
    districtEn: "Sak Lek",
    subdistrictTh: "วังทับไทร",
    subdistrictEn: "Wang Thap Sai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66160,
    district_code: 6609,
    province_code: 53,
    name: "สากเหล็ก วังทับไทร พิจิตร 66160",
    name_th: "สากเหล็ก วังทับไทร พิจิตร 66160",
    name_en: "Sak Lek, Wang Thap Sai, Phichit, 66160",
  },
  {
    id: 661001,
    districtTh: "บึงนาราง",
    districtEn: "Bueng Na Rang",
    subdistrictTh: "ห้วยแก้ว",
    subdistrictEn: "Huai Kaeo",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6610,
    province_code: 53,
    name: "บึงนาราง ห้วยแก้ว พิจิตร 66130",
    name_th: "บึงนาราง ห้วยแก้ว พิจิตร 66130",
    name_en: "Bueng Na Rang, Huai Kaeo, Phichit, 66130",
  },
  {
    id: 661002,
    districtTh: "บึงนาราง",
    districtEn: "Bueng Na Rang",
    subdistrictTh: "โพธิ์ไทรงาม",
    subdistrictEn: "Pho Sai Ngam",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6610,
    province_code: 53,
    name: "บึงนาราง โพธิ์ไทรงาม พิจิตร 66130",
    name_th: "บึงนาราง โพธิ์ไทรงาม พิจิตร 66130",
    name_en: "Bueng Na Rang, Pho Sai Ngam, Phichit, 66130",
  },
  {
    id: 661003,
    districtTh: "บึงนาราง",
    districtEn: "Bueng Na Rang",
    subdistrictTh: "แหลมรัง",
    subdistrictEn: "Laem Rang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6610,
    province_code: 53,
    name: "บึงนาราง แหลมรัง พิจิตร 66130",
    name_th: "บึงนาราง แหลมรัง พิจิตร 66130",
    name_en: "Bueng Na Rang, Laem Rang, Phichit, 66130",
  },
  {
    id: 661004,
    districtTh: "บึงนาราง",
    districtEn: "Bueng Na Rang",
    subdistrictTh: "บางลาย",
    subdistrictEn: "Bang Lai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6610,
    province_code: 53,
    name: "บึงนาราง บางลาย พิจิตร 66130",
    name_th: "บึงนาราง บางลาย พิจิตร 66130",
    name_en: "Bueng Na Rang, Bang Lai, Phichit, 66130",
  },
  {
    id: 661005,
    districtTh: "บึงนาราง",
    districtEn: "Bueng Na Rang",
    subdistrictTh: "บึงนาราง",
    subdistrictEn: "Bueng Na Rang",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66130,
    district_code: 6610,
    province_code: 53,
    name: "บึงนาราง บึงนาราง พิจิตร 66130",
    name_th: "บึงนาราง บึงนาราง พิจิตร 66130",
    name_en: "Bueng Na Rang, Bueng Na Rang, Phichit, 66130",
  },
  {
    id: 661101,
    districtTh: "ดงเจริญ",
    districtEn: "Dong Charoen",
    subdistrictTh: "วังงิ้วใต้",
    subdistrictEn: "Wang Ngio Tai",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66210,
    district_code: 6611,
    province_code: 53,
    name: "ดงเจริญ วังงิ้วใต้ พิจิตร 66210",
    name_th: "ดงเจริญ วังงิ้วใต้ พิจิตร 66210",
    name_en: "Dong Charoen, Wang Ngio Tai, Phichit, 66210",
  },
  {
    id: 661102,
    districtTh: "ดงเจริญ",
    districtEn: "Dong Charoen",
    subdistrictTh: "วังงิ้ว",
    subdistrictEn: "Wang Ngio",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66210,
    district_code: 6611,
    province_code: 53,
    name: "ดงเจริญ วังงิ้ว พิจิตร 66210",
    name_th: "ดงเจริญ วังงิ้ว พิจิตร 66210",
    name_en: "Dong Charoen, Wang Ngio, Phichit, 66210",
  },
  {
    id: 661103,
    districtTh: "ดงเจริญ",
    districtEn: "Dong Charoen",
    subdistrictTh: "ห้วยร่วม",
    subdistrictEn: "Huai Ruam",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66210,
    district_code: 6611,
    province_code: 53,
    name: "ดงเจริญ ห้วยร่วม พิจิตร 66210",
    name_th: "ดงเจริญ ห้วยร่วม พิจิตร 66210",
    name_en: "Dong Charoen, Huai Ruam, Phichit, 66210",
  },
  {
    id: 661104,
    districtTh: "ดงเจริญ",
    districtEn: "Dong Charoen",
    subdistrictTh: "ห้วยพุก",
    subdistrictEn: "Huai Phuk",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66210,
    district_code: 6611,
    province_code: 53,
    name: "ดงเจริญ ห้วยพุก พิจิตร 66210",
    name_th: "ดงเจริญ ห้วยพุก พิจิตร 66210",
    name_en: "Dong Charoen, Huai Phuk, Phichit, 66210",
  },
  {
    id: 661105,
    districtTh: "ดงเจริญ",
    districtEn: "Dong Charoen",
    subdistrictTh: "สำนักขุนเณร",
    subdistrictEn: "Samnak Khun Nen",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66210,
    district_code: 6611,
    province_code: 53,
    name: "ดงเจริญ สำนักขุนเณร พิจิตร 66210",
    name_th: "ดงเจริญ สำนักขุนเณร พิจิตร 66210",
    name_en: "Dong Charoen, Samnak Khun Nen, Phichit, 66210",
  },
  {
    id: 661201,
    districtTh: "วชิรบารมี",
    districtEn: "Wachirabarami",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66140,
    district_code: 6612,
    province_code: 53,
    name: "วชิรบารมี บ้านนา พิจิตร 66140",
    name_th: "วชิรบารมี บ้านนา พิจิตร 66140",
    name_en: "Wachirabarami, Ban Na, Phichit, 66140",
  },
  {
    id: 661202,
    districtTh: "วชิรบารมี",
    districtEn: "Wachirabarami",
    subdistrictTh: "บึงบัว",
    subdistrictEn: "Bueng Bua",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66140,
    district_code: 6612,
    province_code: 53,
    name: "วชิรบารมี บึงบัว พิจิตร 66140",
    name_th: "วชิรบารมี บึงบัว พิจิตร 66140",
    name_en: "Wachirabarami, Bueng Bua, Phichit, 66140",
  },
  {
    id: 661203,
    districtTh: "วชิรบารมี",
    districtEn: "Wachirabarami",
    subdistrictTh: "วังโมกข์",
    subdistrictEn: "Wang Mok",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66140,
    district_code: 6612,
    province_code: 53,
    name: "วชิรบารมี วังโมกข์ พิจิตร 66140",
    name_th: "วชิรบารมี วังโมกข์ พิจิตร 66140",
    name_en: "Wachirabarami, Wang Mok, Phichit, 66140",
  },
  {
    id: 661204,
    districtTh: "วชิรบารมี",
    districtEn: "Wachirabarami",
    subdistrictTh: "หนองหลุม",
    subdistrictEn: "Nong Lum",
    provinceTh: "พิจิตร",
    provinceEn: "Phichit",
    zipcode: 66220,
    district_code: 6612,
    province_code: 53,
    name: "วชิรบารมี หนองหลุม พิจิตร 66220",
    name_th: "วชิรบารมี หนองหลุม พิจิตร 66220",
    name_en: "Wachirabarami, Nong Lum, Phichit, 66220",
  },
  {
    id: 670101,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ในเมือง เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ ในเมือง เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Nai Mueang, Phetchabun, 67000",
  },
  {
    id: 670102,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ตะเบาะ",
    subdistrictEn: "Tabo",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ตะเบาะ เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ ตะเบาะ เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Tabo, Phetchabun, 67000",
  },
  {
    id: 670103,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "บ้านโตก",
    subdistrictEn: "Ban Tok",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ บ้านโตก เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ บ้านโตก เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Ban Tok, Phetchabun, 67000",
  },
  {
    id: 670104,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "สะเดียง",
    subdistrictEn: "Sadiang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ สะเดียง เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ สะเดียง เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Sadiang, Phetchabun, 67000",
  },
  {
    id: 670105,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ป่าเลา",
    subdistrictEn: "Pa Lao",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ป่าเลา เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ ป่าเลา เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Pa Lao, Phetchabun, 67000",
  },
  {
    id: 670106,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "นางั่ว",
    subdistrictEn: "Na Ngua",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ นางั่ว เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ นางั่ว เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Na Ngua, Phetchabun, 67000",
  },
  {
    id: 670107,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ท่าพล",
    subdistrictEn: "Tha Phon",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67250,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ท่าพล เพชรบูรณ์ 67250",
    name_th: "เมืองเพชรบูรณ์ ท่าพล เพชรบูรณ์ 67250",
    name_en: "Mueang Phetchabun, Tha Phon, Phetchabun, 67250",
  },
  {
    id: 670108,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ดงมูลเหล็ก",
    subdistrictEn: "Dong Mun Lek",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ดงมูลเหล็ก เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ ดงมูลเหล็ก เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Dong Mun Lek, Phetchabun, 67000",
  },
  {
    id: 670109,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "บ้านโคก",
    subdistrictEn: "Ban Khok",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ บ้านโคก เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ บ้านโคก เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Ban Khok, Phetchabun, 67000",
  },
  {
    id: 670110,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ชอนไพร",
    subdistrictEn: "Chon Phrai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ชอนไพร เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ ชอนไพร เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Chon Phrai, Phetchabun, 67000",
  },
  {
    id: 670111,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "นาป่า",
    subdistrictEn: "Na Pa",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ นาป่า เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ นาป่า เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Na Pa, Phetchabun, 67000",
  },
  {
    id: 670112,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "นายม",
    subdistrictEn: "Na Yom",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67210,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ นายม เพชรบูรณ์ 67210",
    name_th: "เมืองเพชรบูรณ์ นายม เพชรบูรณ์ 67210",
    name_en: "Mueang Phetchabun, Na Yom, Phetchabun, 67210",
  },
  {
    id: 670113,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "วังชมภู",
    subdistrictEn: "Wang Chomphu",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67210,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ วังชมภู เพชรบูรณ์ 67210",
    name_th: "เมืองเพชรบูรณ์ วังชมภู เพชรบูรณ์ 67210",
    name_en: "Mueang Phetchabun, Wang Chomphu, Phetchabun, 67210",
  },
  {
    id: 670114,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "น้ำร้อน",
    subdistrictEn: "Nam Ron",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ น้ำร้อน เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ น้ำร้อน เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Nam Ron, Phetchabun, 67000",
  },
  {
    id: 670115,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ห้วยสะแก",
    subdistrictEn: "Huai Sakae",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67210,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ห้วยสะแก เพชรบูรณ์ 67210",
    name_th: "เมืองเพชรบูรณ์ ห้วยสะแก เพชรบูรณ์ 67210",
    name_en: "Mueang Phetchabun, Huai Sakae, Phetchabun, 67210",
  },
  {
    id: 670116,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ห้วยใหญ่",
    subdistrictEn: "Huai Yai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67000,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ห้วยใหญ่ เพชรบูรณ์ 67000",
    name_th: "เมืองเพชรบูรณ์ ห้วยใหญ่ เพชรบูรณ์ 67000",
    name_en: "Mueang Phetchabun, Huai Yai, Phetchabun, 67000",
  },
  {
    id: 670117,
    districtTh: "เมืองเพชรบูรณ์",
    districtEn: "Mueang Phetchabun",
    subdistrictTh: "ระวิง",
    subdistrictEn: "Rawing",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67210,
    district_code: 6701,
    province_code: 54,
    name: "เมืองเพชรบูรณ์ ระวิง เพชรบูรณ์ 67210",
    name_th: "เมืองเพชรบูรณ์ ระวิง เพชรบูรณ์ 67210",
    name_en: "Mueang Phetchabun, Rawing, Phetchabun, 67210",
  },
  {
    id: 670201,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "ชนแดน",
    subdistrictEn: "Chon Daen",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67150,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน ชนแดน เพชรบูรณ์ 67150",
    name_th: "ชนแดน ชนแดน เพชรบูรณ์ 67150",
    name_en: "Chon Daen, Chon Daen, Phetchabun, 67150",
  },
  {
    id: 670202,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "ดงขุย",
    subdistrictEn: "Dong Khui",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67190,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน ดงขุย เพชรบูรณ์ 67190",
    name_th: "ชนแดน ดงขุย เพชรบูรณ์ 67190",
    name_en: "Chon Daen, Dong Khui, Phetchabun, 67190",
  },
  {
    id: 670203,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67150,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน ท่าข้าม เพชรบูรณ์ 67150",
    name_th: "ชนแดน ท่าข้าม เพชรบูรณ์ 67150",
    name_en: "Chon Daen, Tha Kham, Phetchabun, 67150",
  },
  {
    id: 670204,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "พุทธบาท",
    subdistrictEn: "Phutthabat",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67150,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน พุทธบาท เพชรบูรณ์ 67150",
    name_th: "ชนแดน พุทธบาท เพชรบูรณ์ 67150",
    name_en: "Chon Daen, Phutthabat, Phetchabun, 67150",
  },
  {
    id: 670205,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "ลาดแค",
    subdistrictEn: "Lat Khae",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67150,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน ลาดแค เพชรบูรณ์ 67150",
    name_th: "ชนแดน ลาดแค เพชรบูรณ์ 67150",
    name_en: "Chon Daen, Lat Khae, Phetchabun, 67150",
  },
  {
    id: 670206,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "บ้านกล้วย",
    subdistrictEn: "Ban Kluai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67190,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน บ้านกล้วย เพชรบูรณ์ 67190",
    name_th: "ชนแดน บ้านกล้วย เพชรบูรณ์ 67190",
    name_en: "Chon Daen, Ban Kluai, Phetchabun, 67190",
  },
  {
    id: 670208,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "ซับพุทรา",
    subdistrictEn: "Sap Phutsa",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67150,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน ซับพุทรา เพชรบูรณ์ 67150",
    name_th: "ชนแดน ซับพุทรา เพชรบูรณ์ 67150",
    name_en: "Chon Daen, Sap Phutsa, Phetchabun, 67150",
  },
  {
    id: 670209,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "ตะกุดไร",
    subdistrictEn: "Takut Rai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67190,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน ตะกุดไร เพชรบูรณ์ 67190",
    name_th: "ชนแดน ตะกุดไร เพชรบูรณ์ 67190",
    name_en: "Chon Daen, Takut Rai, Phetchabun, 67190",
  },
  {
    id: 670210,
    districtTh: "ชนแดน",
    districtEn: "Chon Daen",
    subdistrictTh: "ศาลาลาย",
    subdistrictEn: "Sala Lai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67150,
    district_code: 6702,
    province_code: 54,
    name: "ชนแดน ศาลาลาย เพชรบูรณ์ 67150",
    name_th: "ชนแดน ศาลาลาย เพชรบูรณ์ 67150",
    name_en: "Chon Daen, Sala Lai, Phetchabun, 67150",
  },
  {
    id: 670301,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "หล่มสัก",
    subdistrictEn: "Lom Sak",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก หล่มสัก เพชรบูรณ์ 67110",
    name_th: "หล่มสัก หล่มสัก เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Lom Sak, Phetchabun, 67110",
  },
  {
    id: 670302,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "วัดป่า",
    subdistrictEn: "Wat Pa",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก วัดป่า เพชรบูรณ์ 67110",
    name_th: "หล่มสัก วัดป่า เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Wat Pa, Phetchabun, 67110",
  },
  {
    id: 670303,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "ตาลเดี่ยว",
    subdistrictEn: "Tan Diao",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก ตาลเดี่ยว เพชรบูรณ์ 67110",
    name_th: "หล่มสัก ตาลเดี่ยว เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Tan Diao, Phetchabun, 67110",
  },
  {
    id: 670304,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "ฝายนาแซง",
    subdistrictEn: "Fai Na Saeng",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก ฝายนาแซง เพชรบูรณ์ 67110",
    name_th: "หล่มสัก ฝายนาแซง เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Fai Na Saeng, Phetchabun, 67110",
  },
  {
    id: 670305,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "หนองสว่าง",
    subdistrictEn: "Nong Sawang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก หนองสว่าง เพชรบูรณ์ 67110",
    name_th: "หล่มสัก หนองสว่าง เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Nong Sawang, Phetchabun, 67110",
  },
  {
    id: 670306,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "น้ำเฮี้ย",
    subdistrictEn: "Nam Hia",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก น้ำเฮี้ย เพชรบูรณ์ 67110",
    name_th: "หล่มสัก น้ำเฮี้ย เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Nam Hia, Phetchabun, 67110",
  },
  {
    id: 670307,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "สักหลง",
    subdistrictEn: "Sak Long",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก สักหลง เพชรบูรณ์ 67110",
    name_th: "หล่มสัก สักหลง เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Sak Long, Phetchabun, 67110",
  },
  {
    id: 670308,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "ท่าอิบุญ",
    subdistrictEn: "Tha Ibun",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก ท่าอิบุญ เพชรบูรณ์ 67110",
    name_th: "หล่มสัก ท่าอิบุญ เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Tha Ibun, Phetchabun, 67110",
  },
  {
    id: 670309,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "บ้านโสก",
    subdistrictEn: "Ban Sok",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก บ้านโสก เพชรบูรณ์ 67110",
    name_th: "หล่มสัก บ้านโสก เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Ban Sok, Phetchabun, 67110",
  },
  {
    id: 670310,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "บ้านติ้ว",
    subdistrictEn: "Ban Tio",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก บ้านติ้ว เพชรบูรณ์ 67110",
    name_th: "หล่มสัก บ้านติ้ว เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Ban Tio, Phetchabun, 67110",
  },
  {
    id: 670311,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "ห้วยไร่",
    subdistrictEn: "Huai Rai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก ห้วยไร่ เพชรบูรณ์ 67110",
    name_th: "หล่มสัก ห้วยไร่ เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Huai Rai, Phetchabun, 67110",
  },
  {
    id: 670312,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "น้ำก้อ",
    subdistrictEn: "Nam Ko",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก น้ำก้อ เพชรบูรณ์ 67110",
    name_th: "หล่มสัก น้ำก้อ เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Nam Ko, Phetchabun, 67110",
  },
  {
    id: 670313,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "ปากช่อง",
    subdistrictEn: "Pak Chong",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก ปากช่อง เพชรบูรณ์ 67110",
    name_th: "หล่มสัก ปากช่อง เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Pak Chong, Phetchabun, 67110",
  },
  {
    id: 670314,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "น้ำชุน",
    subdistrictEn: "Nam Chun",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก น้ำชุน เพชรบูรณ์ 67110",
    name_th: "หล่มสัก น้ำชุน เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Nam Chun, Phetchabun, 67110",
  },
  {
    id: 670315,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "หนองไขว่",
    subdistrictEn: "Nong Khwai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก หนองไขว่ เพชรบูรณ์ 67110",
    name_th: "หล่มสัก หนองไขว่ เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Nong Khwai, Phetchabun, 67110",
  },
  {
    id: 670316,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "ลานบ่า",
    subdistrictEn: "Lan Ba",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก ลานบ่า เพชรบูรณ์ 67110",
    name_th: "หล่มสัก ลานบ่า เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Lan Ba, Phetchabun, 67110",
  },
  {
    id: 670317,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "บุ่งคล้า",
    subdistrictEn: "Bung Khla",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก บุ่งคล้า เพชรบูรณ์ 67110",
    name_th: "หล่มสัก บุ่งคล้า เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Bung Khla, Phetchabun, 67110",
  },
  {
    id: 670318,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "บุ่งน้ำเต้า",
    subdistrictEn: "Bung Namtao",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก บุ่งน้ำเต้า เพชรบูรณ์ 67110",
    name_th: "หล่มสัก บุ่งน้ำเต้า เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Bung Namtao, Phetchabun, 67110",
  },
  {
    id: 670319,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก บ้านกลาง เพชรบูรณ์ 67110",
    name_th: "หล่มสัก บ้านกลาง เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Ban Klang, Phetchabun, 67110",
  },
  {
    id: 670320,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "ช้างตะลูด",
    subdistrictEn: "Chang Talut",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก ช้างตะลูด เพชรบูรณ์ 67110",
    name_th: "หล่มสัก ช้างตะลูด เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Chang Talut, Phetchabun, 67110",
  },
  {
    id: 670321,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "บ้านไร่",
    subdistrictEn: "Ban Rai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก บ้านไร่ เพชรบูรณ์ 67110",
    name_th: "หล่มสัก บ้านไร่ เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Ban Rai, Phetchabun, 67110",
  },
  {
    id: 670322,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "ปากดุก",
    subdistrictEn: "Pak Duk",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก ปากดุก เพชรบูรณ์ 67110",
    name_th: "หล่มสัก ปากดุก เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Pak Duk, Phetchabun, 67110",
  },
  {
    id: 670323,
    districtTh: "หล่มสัก",
    districtEn: "Lom Sak",
    subdistrictTh: "บ้านหวาย",
    subdistrictEn: "Ban Wai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67110,
    district_code: 6703,
    province_code: 54,
    name: "หล่มสัก บ้านหวาย เพชรบูรณ์ 67110",
    name_th: "หล่มสัก บ้านหวาย เพชรบูรณ์ 67110",
    name_en: "Lom Sak, Ban Wai, Phetchabun, 67110",
  },
  {
    id: 670401,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "หล่มเก่า",
    subdistrictEn: "Lom Kao",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า หล่มเก่า เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า หล่มเก่า เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Lom Kao, Phetchabun, 67120",
  },
  {
    id: 670402,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "นาซำ",
    subdistrictEn: "Na Sam",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า นาซำ เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า นาซำ เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Na Sam, Phetchabun, 67120",
  },
  {
    id: 670403,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "หินฮาว",
    subdistrictEn: "Hin Hao",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า หินฮาว เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า หินฮาว เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Hin Hao, Phetchabun, 67120",
  },
  {
    id: 670404,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "บ้านเนิน",
    subdistrictEn: "Ban Noen",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า บ้านเนิน เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า บ้านเนิน เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Ban Noen, Phetchabun, 67120",
  },
  {
    id: 670405,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "ศิลา",
    subdistrictEn: "Sila",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า ศิลา เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า ศิลา เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Sila, Phetchabun, 67120",
  },
  {
    id: 670406,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "นาแซง",
    subdistrictEn: "Na Saeng",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า นาแซง เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า นาแซง เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Na Saeng, Phetchabun, 67120",
  },
  {
    id: 670407,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "วังบาล",
    subdistrictEn: "Wang Ban",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า วังบาล เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า วังบาล เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Wang Ban, Phetchabun, 67120",
  },
  {
    id: 670408,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "นาเกาะ",
    subdistrictEn: "Na Ko",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า นาเกาะ เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า นาเกาะ เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Na Ko, Phetchabun, 67120",
  },
  {
    id: 670409,
    districtTh: "หล่มเก่า",
    districtEn: "Lom Kao",
    subdistrictTh: "ตาดกลอย",
    subdistrictEn: "Tat Kloi",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67120,
    district_code: 6704,
    province_code: 54,
    name: "หล่มเก่า ตาดกลอย เพชรบูรณ์ 67120",
    name_th: "หล่มเก่า ตาดกลอย เพชรบูรณ์ 67120",
    name_en: "Lom Kao, Tat Kloi, Phetchabun, 67120",
  },
  {
    id: 670501,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "ท่าโรง",
    subdistrictEn: "Tha Rong",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67130,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี ท่าโรง เพชรบูรณ์ 67130",
    name_th: "วิเชียรบุรี ท่าโรง เพชรบูรณ์ 67130",
    name_en: "Wichian Buri, Tha Rong, Phetchabun, 67130",
  },
  {
    id: 670502,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "สระประดู่",
    subdistrictEn: "Sa Pradu",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67130,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี สระประดู่ เพชรบูรณ์ 67130",
    name_th: "วิเชียรบุรี สระประดู่ เพชรบูรณ์ 67130",
    name_en: "Wichian Buri, Sa Pradu, Phetchabun, 67130",
  },
  {
    id: 670503,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "สามแยก",
    subdistrictEn: "Sam Yaek",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67130,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี สามแยก เพชรบูรณ์ 67130",
    name_th: "วิเชียรบุรี สามแยก เพชรบูรณ์ 67130",
    name_en: "Wichian Buri, Sam Yaek, Phetchabun, 67130",
  },
  {
    id: 670504,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "โคกปรง",
    subdistrictEn: "Khok Prong",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67130,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี โคกปรง เพชรบูรณ์ 67130",
    name_th: "วิเชียรบุรี โคกปรง เพชรบูรณ์ 67130",
    name_en: "Wichian Buri, Khok Prong, Phetchabun, 67130",
  },
  {
    id: 670505,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "น้ำร้อน",
    subdistrictEn: "Nam Ron",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67130,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี น้ำร้อน เพชรบูรณ์ 67130",
    name_th: "วิเชียรบุรี น้ำร้อน เพชรบูรณ์ 67130",
    name_en: "Wichian Buri, Nam Ron, Phetchabun, 67130",
  },
  {
    id: 670506,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "บ่อรัง",
    subdistrictEn: "Bo Rang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67130,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี บ่อรัง เพชรบูรณ์ 67130",
    name_th: "วิเชียรบุรี บ่อรัง เพชรบูรณ์ 67130",
    name_en: "Wichian Buri, Bo Rang, Phetchabun, 67130",
  },
  {
    id: 670507,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "พุเตย",
    subdistrictEn: "Phu Toei",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67180,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี พุเตย เพชรบูรณ์ 67180",
    name_th: "วิเชียรบุรี พุเตย เพชรบูรณ์ 67180",
    name_en: "Wichian Buri, Phu Toei, Phetchabun, 67180",
  },
  {
    id: 670508,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "พุขาม",
    subdistrictEn: "Phu Kham",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67180,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี พุขาม เพชรบูรณ์ 67180",
    name_th: "วิเชียรบุรี พุขาม เพชรบูรณ์ 67180",
    name_en: "Wichian Buri, Phu Kham, Phetchabun, 67180",
  },
  {
    id: 670509,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "ภูน้ำหยด",
    subdistrictEn: "Phu Nam Yot",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67180,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี ภูน้ำหยด เพชรบูรณ์ 67180",
    name_th: "วิเชียรบุรี ภูน้ำหยด เพชรบูรณ์ 67180",
    name_en: "Wichian Buri, Phu Nam Yot, Phetchabun, 67180",
  },
  {
    id: 670510,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "ซับสมบูรณ์",
    subdistrictEn: "Sap Sombun",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67180,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี ซับสมบูรณ์ เพชรบูรณ์ 67180",
    name_th: "วิเชียรบุรี ซับสมบูรณ์ เพชรบูรณ์ 67180",
    name_en: "Wichian Buri, Sap Sombun, Phetchabun, 67180",
  },
  {
    id: 670511,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "บึงกระจับ",
    subdistrictEn: "Bueng Krachap",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67130,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี บึงกระจับ เพชรบูรณ์ 67130",
    name_th: "วิเชียรบุรี บึงกระจับ เพชรบูรณ์ 67130",
    name_en: "Wichian Buri, Bueng Krachap, Phetchabun, 67130",
  },
  {
    id: 670512,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "วังใหญ่",
    subdistrictEn: "Wang Yai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67180,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี วังใหญ่ เพชรบูรณ์ 67180",
    name_th: "วิเชียรบุรี วังใหญ่ เพชรบูรณ์ 67180",
    name_en: "Wichian Buri, Wang Yai, Phetchabun, 67180",
  },
  {
    id: 670513,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "ยางสาว",
    subdistrictEn: "Yang Sao",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67130,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี ยางสาว เพชรบูรณ์ 67130",
    name_th: "วิเชียรบุรี ยางสาว เพชรบูรณ์ 67130",
    name_en: "Wichian Buri, Yang Sao, Phetchabun, 67130",
  },
  {
    id: 670514,
    districtTh: "วิเชียรบุรี",
    districtEn: "Wichian Buri",
    subdistrictTh: "ซับน้อย",
    subdistrictEn: "Sap Noi",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67180,
    district_code: 6705,
    province_code: 54,
    name: "วิเชียรบุรี ซับน้อย เพชรบูรณ์ 67180",
    name_th: "วิเชียรบุรี ซับน้อย เพชรบูรณ์ 67180",
    name_en: "Wichian Buri, Sap Noi, Phetchabun, 67180",
  },
  {
    id: 670601,
    districtTh: "ศรีเทพ",
    districtEn: "Si Thep",
    subdistrictTh: "ศรีเทพ",
    subdistrictEn: "Si Thep",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67170,
    district_code: 6706,
    province_code: 54,
    name: "ศรีเทพ ศรีเทพ เพชรบูรณ์ 67170",
    name_th: "ศรีเทพ ศรีเทพ เพชรบูรณ์ 67170",
    name_en: "Si Thep, Si Thep, Phetchabun, 67170",
  },
  {
    id: 670602,
    districtTh: "ศรีเทพ",
    districtEn: "Si Thep",
    subdistrictTh: "สระกรวด",
    subdistrictEn: "Sa Kruat",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67170,
    district_code: 6706,
    province_code: 54,
    name: "ศรีเทพ สระกรวด เพชรบูรณ์ 67170",
    name_th: "ศรีเทพ สระกรวด เพชรบูรณ์ 67170",
    name_en: "Si Thep, Sa Kruat, Phetchabun, 67170",
  },
  {
    id: 670603,
    districtTh: "ศรีเทพ",
    districtEn: "Si Thep",
    subdistrictTh: "คลองกระจัง",
    subdistrictEn: "Khlong Krachang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67170,
    district_code: 6706,
    province_code: 54,
    name: "ศรีเทพ คลองกระจัง เพชรบูรณ์ 67170",
    name_th: "ศรีเทพ คลองกระจัง เพชรบูรณ์ 67170",
    name_en: "Si Thep, Khlong Krachang, Phetchabun, 67170",
  },
  {
    id: 670604,
    districtTh: "ศรีเทพ",
    districtEn: "Si Thep",
    subdistrictTh: "นาสนุ่น",
    subdistrictEn: "Na Sanun",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67170,
    district_code: 6706,
    province_code: 54,
    name: "ศรีเทพ นาสนุ่น เพชรบูรณ์ 67170",
    name_th: "ศรีเทพ นาสนุ่น เพชรบูรณ์ 67170",
    name_en: "Si Thep, Na Sanun, Phetchabun, 67170",
  },
  {
    id: 670605,
    districtTh: "ศรีเทพ",
    districtEn: "Si Thep",
    subdistrictTh: "โคกสะอาด",
    subdistrictEn: "Khok Sa-at",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67170,
    district_code: 6706,
    province_code: 54,
    name: "ศรีเทพ โคกสะอาด เพชรบูรณ์ 67170",
    name_th: "ศรีเทพ โคกสะอาด เพชรบูรณ์ 67170",
    name_en: "Si Thep, Khok Sa-at, Phetchabun, 67170",
  },
  {
    id: 670606,
    districtTh: "ศรีเทพ",
    districtEn: "Si Thep",
    subdistrictTh: "หนองย่างทอย",
    subdistrictEn: "Nong Yang Thoi",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67170,
    district_code: 6706,
    province_code: 54,
    name: "ศรีเทพ หนองย่างทอย เพชรบูรณ์ 67170",
    name_th: "ศรีเทพ หนองย่างทอย เพชรบูรณ์ 67170",
    name_en: "Si Thep, Nong Yang Thoi, Phetchabun, 67170",
  },
  {
    id: 670607,
    districtTh: "ศรีเทพ",
    districtEn: "Si Thep",
    subdistrictTh: "ประดู่งาม",
    subdistrictEn: "Pradu Ngam",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67170,
    district_code: 6706,
    province_code: 54,
    name: "ศรีเทพ ประดู่งาม เพชรบูรณ์ 67170",
    name_th: "ศรีเทพ ประดู่งาม เพชรบูรณ์ 67170",
    name_en: "Si Thep, Pradu Ngam, Phetchabun, 67170",
  },
  {
    id: 670701,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "กองทูล",
    subdistrictEn: "Kong Thun",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ กองทูล เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ กองทูล เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Kong Thun, Phetchabun, 67140",
  },
  {
    id: 670702,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "นาเฉลียง",
    subdistrictEn: "Na Chaliang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67220,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ นาเฉลียง เพชรบูรณ์ 67220",
    name_th: "หนองไผ่ นาเฉลียง เพชรบูรณ์ 67220",
    name_en: "Nong Phai, Na Chaliang, Phetchabun, 67220",
  },
  {
    id: 670703,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "บ้านโภชน์",
    subdistrictEn: "Ban Phot",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ บ้านโภชน์ เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ บ้านโภชน์ เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Ban Phot, Phetchabun, 67140",
  },
  {
    id: 670704,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "ท่าแดง",
    subdistrictEn: "Tha Daeng",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ ท่าแดง เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ ท่าแดง เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Tha Daeng, Phetchabun, 67140",
  },
  {
    id: 670705,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "เพชรละคร",
    subdistrictEn: "Phet Lakhon",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ เพชรละคร เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ เพชรละคร เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Phet Lakhon, Phetchabun, 67140",
  },
  {
    id: 670706,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "บ่อไทย",
    subdistrictEn: "Bo Thai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ บ่อไทย เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ บ่อไทย เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Bo Thai, Phetchabun, 67140",
  },
  {
    id: 670707,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "ห้วยโป่ง",
    subdistrictEn: "Huai Pong",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67220,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ ห้วยโป่ง เพชรบูรณ์ 67220",
    name_th: "หนองไผ่ ห้วยโป่ง เพชรบูรณ์ 67220",
    name_en: "Nong Phai, Huai Pong, Phetchabun, 67220",
  },
  {
    id: 670708,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "วังท่าดี",
    subdistrictEn: "Wang Tha Di",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ วังท่าดี เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ วังท่าดี เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Wang Tha Di, Phetchabun, 67140",
  },
  {
    id: 670709,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "บัววัฒนา",
    subdistrictEn: "Bua Watthana",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ บัววัฒนา เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ บัววัฒนา เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Bua Watthana, Phetchabun, 67140",
  },
  {
    id: 670710,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ หนองไผ่ เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ หนองไผ่ เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Nong Phai, Phetchabun, 67140",
  },
  {
    id: 670711,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "วังโบสถ์",
    subdistrictEn: "Wang Bot",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ วังโบสถ์ เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ วังโบสถ์ เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Wang Bot, Phetchabun, 67140",
  },
  {
    id: 670712,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "ยางงาม",
    subdistrictEn: "Yang Ngam",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67220,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ ยางงาม เพชรบูรณ์ 67220",
    name_th: "หนองไผ่ ยางงาม เพชรบูรณ์ 67220",
    name_en: "Nong Phai, Yang Ngam, Phetchabun, 67220",
  },
  {
    id: 670713,
    districtTh: "หนองไผ่",
    districtEn: "Nong Phai",
    subdistrictTh: "ท่าด้วง",
    subdistrictEn: "Tha Duang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67140,
    district_code: 6707,
    province_code: 54,
    name: "หนองไผ่ ท่าด้วง เพชรบูรณ์ 67140",
    name_th: "หนองไผ่ ท่าด้วง เพชรบูรณ์ 67140",
    name_en: "Nong Phai, Tha Duang, Phetchabun, 67140",
  },
  {
    id: 670801,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "ซับสมอทอด",
    subdistrictEn: "Sap Samo Thot",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67160,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน ซับสมอทอด เพชรบูรณ์ 67160",
    name_th: "บึงสามพัน ซับสมอทอด เพชรบูรณ์ 67160",
    name_en: "Bueng Sam Phan, Sap Samo Thot, Phetchabun, 67160",
  },
  {
    id: 670802,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "ซับไม้แดง",
    subdistrictEn: "Sap Mai Daeng",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67160,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน ซับไม้แดง เพชรบูรณ์ 67160",
    name_th: "บึงสามพัน ซับไม้แดง เพชรบูรณ์ 67160",
    name_en: "Bueng Sam Phan, Sap Mai Daeng, Phetchabun, 67160",
  },
  {
    id: 670803,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "หนองแจง",
    subdistrictEn: "Nong Chaeng",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67160,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน หนองแจง เพชรบูรณ์ 67160",
    name_th: "บึงสามพัน หนองแจง เพชรบูรณ์ 67160",
    name_en: "Bueng Sam Phan, Nong Chaeng, Phetchabun, 67160",
  },
  {
    id: 670804,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "กันจุ",
    subdistrictEn: "Kan Chu",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67160,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน กันจุ เพชรบูรณ์ 67160",
    name_th: "บึงสามพัน กันจุ เพชรบูรณ์ 67160",
    name_en: "Bueng Sam Phan, Kan Chu, Phetchabun, 67160",
  },
  {
    id: 670805,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "วังพิกุล",
    subdistrictEn: "Wang Phikun",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67230,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน วังพิกุล เพชรบูรณ์ 67230",
    name_th: "บึงสามพัน วังพิกุล เพชรบูรณ์ 67230",
    name_en: "Bueng Sam Phan, Wang Phikun, Phetchabun, 67230",
  },
  {
    id: 670806,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "พญาวัง",
    subdistrictEn: "Phaya Wang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67160,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน พญาวัง เพชรบูรณ์ 67160",
    name_th: "บึงสามพัน พญาวัง เพชรบูรณ์ 67160",
    name_en: "Bueng Sam Phan, Phaya Wang, Phetchabun, 67160",
  },
  {
    id: 670807,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "ศรีมงคล",
    subdistrictEn: "Si Mongkhon",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67160,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน ศรีมงคล เพชรบูรณ์ 67160",
    name_th: "บึงสามพัน ศรีมงคล เพชรบูรณ์ 67160",
    name_en: "Bueng Sam Phan, Si Mongkhon, Phetchabun, 67160",
  },
  {
    id: 670808,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67160,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน สระแก้ว เพชรบูรณ์ 67160",
    name_th: "บึงสามพัน สระแก้ว เพชรบูรณ์ 67160",
    name_en: "Bueng Sam Phan, Sa Kaeo, Phetchabun, 67160",
  },
  {
    id: 670809,
    districtTh: "บึงสามพัน",
    districtEn: "Bueng Sam Phan",
    subdistrictTh: "บึงสามพัน",
    subdistrictEn: "Bueng Sam Phan",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67160,
    district_code: 6708,
    province_code: 54,
    name: "บึงสามพัน บึงสามพัน เพชรบูรณ์ 67160",
    name_th: "บึงสามพัน บึงสามพัน เพชรบูรณ์ 67160",
    name_en: "Bueng Sam Phan, Bueng Sam Phan, Phetchabun, 67160",
  },
  {
    id: 670901,
    districtTh: "น้ำหนาว",
    districtEn: "Nam Nao",
    subdistrictTh: "น้ำหนาว",
    subdistrictEn: "Nam Nao",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67260,
    district_code: 6709,
    province_code: 54,
    name: "น้ำหนาว น้ำหนาว เพชรบูรณ์ 67260",
    name_th: "น้ำหนาว น้ำหนาว เพชรบูรณ์ 67260",
    name_en: "Nam Nao, Nam Nao, Phetchabun, 67260",
  },
  {
    id: 670902,
    districtTh: "น้ำหนาว",
    districtEn: "Nam Nao",
    subdistrictTh: "หลักด่าน",
    subdistrictEn: "Lak Dan",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67260,
    district_code: 6709,
    province_code: 54,
    name: "น้ำหนาว หลักด่าน เพชรบูรณ์ 67260",
    name_th: "น้ำหนาว หลักด่าน เพชรบูรณ์ 67260",
    name_en: "Nam Nao, Lak Dan, Phetchabun, 67260",
  },
  {
    id: 670903,
    districtTh: "น้ำหนาว",
    districtEn: "Nam Nao",
    subdistrictTh: "วังกวาง",
    subdistrictEn: "Wang Kwang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67260,
    district_code: 6709,
    province_code: 54,
    name: "น้ำหนาว วังกวาง เพชรบูรณ์ 67260",
    name_th: "น้ำหนาว วังกวาง เพชรบูรณ์ 67260",
    name_en: "Nam Nao, Wang Kwang, Phetchabun, 67260",
  },
  {
    id: 670904,
    districtTh: "น้ำหนาว",
    districtEn: "Nam Nao",
    subdistrictTh: "โคกมน",
    subdistrictEn: "Khok Mon",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67260,
    district_code: 6709,
    province_code: 54,
    name: "น้ำหนาว โคกมน เพชรบูรณ์ 67260",
    name_th: "น้ำหนาว โคกมน เพชรบูรณ์ 67260",
    name_en: "Nam Nao, Khok Mon, Phetchabun, 67260",
  },
  {
    id: 671001,
    districtTh: "วังโป่ง",
    districtEn: "Wang Pong",
    subdistrictTh: "วังโป่ง",
    subdistrictEn: "Wang Pong",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67240,
    district_code: 6710,
    province_code: 54,
    name: "วังโป่ง วังโป่ง เพชรบูรณ์ 67240",
    name_th: "วังโป่ง วังโป่ง เพชรบูรณ์ 67240",
    name_en: "Wang Pong, Wang Pong, Phetchabun, 67240",
  },
  {
    id: 671002,
    districtTh: "วังโป่ง",
    districtEn: "Wang Pong",
    subdistrictTh: "ท้ายดง",
    subdistrictEn: "Thai Dong",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67240,
    district_code: 6710,
    province_code: 54,
    name: "วังโป่ง ท้ายดง เพชรบูรณ์ 67240",
    name_th: "วังโป่ง ท้ายดง เพชรบูรณ์ 67240",
    name_en: "Wang Pong, Thai Dong, Phetchabun, 67240",
  },
  {
    id: 671003,
    districtTh: "วังโป่ง",
    districtEn: "Wang Pong",
    subdistrictTh: "ซับเปิบ",
    subdistrictEn: "Sap Poep",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67240,
    district_code: 6710,
    province_code: 54,
    name: "วังโป่ง ซับเปิบ เพชรบูรณ์ 67240",
    name_th: "วังโป่ง ซับเปิบ เพชรบูรณ์ 67240",
    name_en: "Wang Pong, Sap Poep, Phetchabun, 67240",
  },
  {
    id: 671004,
    districtTh: "วังโป่ง",
    districtEn: "Wang Pong",
    subdistrictTh: "วังหิน",
    subdistrictEn: "Wang Hin",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67240,
    district_code: 6710,
    province_code: 54,
    name: "วังโป่ง วังหิน เพชรบูรณ์ 67240",
    name_th: "วังโป่ง วังหิน เพชรบูรณ์ 67240",
    name_en: "Wang Pong, Wang Hin, Phetchabun, 67240",
  },
  {
    id: 671005,
    districtTh: "วังโป่ง",
    districtEn: "Wang Pong",
    subdistrictTh: "วังศาล",
    subdistrictEn: "Wang San",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67240,
    district_code: 6710,
    province_code: 54,
    name: "วังโป่ง วังศาล เพชรบูรณ์ 67240",
    name_th: "วังโป่ง วังศาล เพชรบูรณ์ 67240",
    name_en: "Wang Pong, Wang San, Phetchabun, 67240",
  },
  {
    id: 671101,
    districtTh: "เขาค้อ",
    districtEn: "Khao Kho",
    subdistrictTh: "ทุ่งสมอ",
    subdistrictEn: "Khao Kho",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67270,
    district_code: 6711,
    province_code: 54,
    name: "เขาค้อ ทุ่งสมอ เพชรบูรณ์ 67270",
    name_th: "เขาค้อ ทุ่งสมอ เพชรบูรณ์ 67270",
    name_en: "Khao Kho, Khao Kho, Phetchabun, 67270",
  },
  {
    id: 671102,
    districtTh: "เขาค้อ",
    districtEn: "Khao Kho",
    subdistrictTh: "แคมป์สน",
    subdistrictEn: "Khaem Son",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67280,
    district_code: 6711,
    province_code: 54,
    name: "เขาค้อ แคมป์สน เพชรบูรณ์ 67280",
    name_th: "เขาค้อ แคมป์สน เพชรบูรณ์ 67280",
    name_en: "Khao Kho, Khaem Son, Phetchabun, 67280",
  },
  {
    id: 671103,
    districtTh: "เขาค้อ",
    districtEn: "Khao Kho",
    subdistrictTh: "เขาค้อ",
    subdistrictEn: "Thung Samo",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67270,
    district_code: 6711,
    province_code: 54,
    name: "เขาค้อ เขาค้อ เพชรบูรณ์ 67270",
    name_th: "เขาค้อ เขาค้อ เพชรบูรณ์ 67270",
    name_en: "Khao Kho, Thung Samo, Phetchabun, 67270",
  },
  {
    id: 671104,
    districtTh: "เขาค้อ",
    districtEn: "Khao Kho",
    subdistrictTh: "ริมสีม่วง",
    subdistrictEn: "Rim Si Muang",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67270,
    district_code: 6711,
    province_code: 54,
    name: "เขาค้อ ริมสีม่วง เพชรบูรณ์ 67270",
    name_th: "เขาค้อ ริมสีม่วง เพชรบูรณ์ 67270",
    name_en: "Khao Kho, Rim Si Muang, Phetchabun, 67270",
  },
  {
    id: 671105,
    districtTh: "เขาค้อ",
    districtEn: "Khao Kho",
    subdistrictTh: "สะเดาะพง",
    subdistrictEn: "Sado Phong",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67270,
    district_code: 6711,
    province_code: 54,
    name: "เขาค้อ สะเดาะพง เพชรบูรณ์ 67270",
    name_th: "เขาค้อ สะเดาะพง เพชรบูรณ์ 67270",
    name_en: "Khao Kho, Sado Phong, Phetchabun, 67270",
  },
  {
    id: 671106,
    districtTh: "เขาค้อ",
    districtEn: "Khao Kho",
    subdistrictTh: "หนองแม่นา",
    subdistrictEn: "Nong Mae Na",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67270,
    district_code: 6711,
    province_code: 54,
    name: "เขาค้อ หนองแม่นา เพชรบูรณ์ 67270",
    name_th: "เขาค้อ หนองแม่นา เพชรบูรณ์ 67270",
    name_en: "Khao Kho, Nong Mae Na, Phetchabun, 67270",
  },
  {
    id: 671107,
    districtTh: "เขาค้อ",
    districtEn: "Khao Kho",
    subdistrictTh: "เข็กน้อย",
    subdistrictEn: "Khek Noi",
    provinceTh: "เพชรบูรณ์",
    provinceEn: "Phetchabun",
    zipcode: 67280,
    district_code: 6711,
    province_code: 54,
    name: "เขาค้อ เข็กน้อย เพชรบูรณ์ 67280",
    name_th: "เขาค้อ เข็กน้อย เพชรบูรณ์ 67280",
    name_en: "Khao Kho, Khek Noi, Phetchabun, 67280",
  },
  {
    id: 700101,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "หน้าเมือง",
    subdistrictEn: "Na Mueang",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี หน้าเมือง ราชบุรี 70000",
    name_th: "เมืองราชบุรี หน้าเมือง ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Na Mueang, Ratchaburi, 70000",
  },
  {
    id: 700102,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "เจดีย์หัก",
    subdistrictEn: "Chedi Hak",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี เจดีย์หัก ราชบุรี 70000",
    name_th: "เมืองราชบุรี เจดีย์หัก ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Chedi Hak, Ratchaburi, 70000",
  },
  {
    id: 700103,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "ดอนตะโก",
    subdistrictEn: "Don Tako",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี ดอนตะโก ราชบุรี 70000",
    name_th: "เมืองราชบุรี ดอนตะโก ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Don Tako, Ratchaburi, 70000",
  },
  {
    id: 700104,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "หนองกลางนา",
    subdistrictEn: "Nong Klang Na",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี หนองกลางนา ราชบุรี 70000",
    name_th: "เมืองราชบุรี หนองกลางนา ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Nong Klang Na, Ratchaburi, 70000",
  },
  {
    id: 700105,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "ห้วยไผ่",
    subdistrictEn: "Huai Phai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี ห้วยไผ่ ราชบุรี 70000",
    name_th: "เมืองราชบุรี ห้วยไผ่ ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Huai Phai, Ratchaburi, 70000",
  },
  {
    id: 700106,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "คุ้งน้ำวน",
    subdistrictEn: "Khung Nam Won",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี คุ้งน้ำวน ราชบุรี 70000",
    name_th: "เมืองราชบุรี คุ้งน้ำวน ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Khung Nam Won, Ratchaburi, 70000",
  },
  {
    id: 700107,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "คุ้งกระถิน",
    subdistrictEn: "Khung Krathin",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี คุ้งกระถิน ราชบุรี 70000",
    name_th: "เมืองราชบุรี คุ้งกระถิน ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Khung Krathin, Ratchaburi, 70000",
  },
  {
    id: 700108,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "อ่างทอง",
    subdistrictEn: "Ang Thong",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี อ่างทอง ราชบุรี 70000",
    name_th: "เมืองราชบุรี อ่างทอง ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Ang Thong, Ratchaburi, 70000",
  },
  {
    id: 700109,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "โคกหม้อ",
    subdistrictEn: "Khok Mo",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี โคกหม้อ ราชบุรี 70000",
    name_th: "เมืองราชบุรี โคกหม้อ ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Khok Mo, Ratchaburi, 70000",
  },
  {
    id: 700110,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "สามเรือน",
    subdistrictEn: "Sam Ruean",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี สามเรือน ราชบุรี 70000",
    name_th: "เมืองราชบุรี สามเรือน ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Sam Ruean, Ratchaburi, 70000",
  },
  {
    id: 700111,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "พิกุลทอง",
    subdistrictEn: "Phikun Thong",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี พิกุลทอง ราชบุรี 70000",
    name_th: "เมืองราชบุรี พิกุลทอง ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Phikun Thong, Ratchaburi, 70000",
  },
  {
    id: 700112,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "น้ำพุ",
    subdistrictEn: "Nam Phu",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี น้ำพุ ราชบุรี 70000",
    name_th: "เมืองราชบุรี น้ำพุ ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Nam Phu, Ratchaburi, 70000",
  },
  {
    id: 700113,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "ดอนแร่",
    subdistrictEn: "Don Rae",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี ดอนแร่ ราชบุรี 70000",
    name_th: "เมืองราชบุรี ดอนแร่ ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Don Rae, Ratchaburi, 70000",
  },
  {
    id: 700114,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "หินกอง",
    subdistrictEn: "Hin Kong",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี หินกอง ราชบุรี 70000",
    name_th: "เมืองราชบุรี หินกอง ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Hin Kong, Ratchaburi, 70000",
  },
  {
    id: 700115,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "เขาแร้ง",
    subdistrictEn: "Khao Raeng",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี เขาแร้ง ราชบุรี 70000",
    name_th: "เมืองราชบุรี เขาแร้ง ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Khao Raeng, Ratchaburi, 70000",
  },
  {
    id: 700116,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "เกาะพลับพลา",
    subdistrictEn: "Ko Phlapphla",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี เกาะพลับพลา ราชบุรี 70000",
    name_th: "เมืองราชบุรี เกาะพลับพลา ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Ko Phlapphla, Ratchaburi, 70000",
  },
  {
    id: 700118,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "บางป่า",
    subdistrictEn: "Bang Pa",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี บางป่า ราชบุรี 70000",
    name_th: "เมืองราชบุรี บางป่า ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Bang Pa, Ratchaburi, 70000",
  },
  {
    id: 700119,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "พงสวาย",
    subdistrictEn: "Phong Sawai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี พงสวาย ราชบุรี 70000",
    name_th: "เมืองราชบุรี พงสวาย ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Phong Sawai, Ratchaburi, 70000",
  },
  {
    id: 700120,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "คูบัว",
    subdistrictEn: "Khu Bua",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี คูบัว ราชบุรี 70000",
    name_th: "เมืองราชบุรี คูบัว ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Khu Bua, Ratchaburi, 70000",
  },
  {
    id: 700121,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "ท่าราบ",
    subdistrictEn: "Tha Rap",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี ท่าราบ ราชบุรี 70000",
    name_th: "เมืองราชบุรี ท่าราบ ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Tha Rap, Ratchaburi, 70000",
  },
  {
    id: 700122,
    districtTh: "เมืองราชบุรี",
    districtEn: "Mueang Ratchaburi",
    subdistrictTh: "บ้านไร่",
    subdistrictEn: "Ban Rai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70000,
    district_code: 7001,
    province_code: 55,
    name: "เมืองราชบุรี บ้านไร่ ราชบุรี 70000",
    name_th: "เมืองราชบุรี บ้านไร่ ราชบุรี 70000",
    name_en: "Mueang Ratchaburi, Ban Rai, Ratchaburi, 70000",
  },
  {
    id: 700201,
    districtTh: "จอมบึง",
    districtEn: "Chom Bueng",
    subdistrictTh: "จอมบึง",
    subdistrictEn: "Chom Bueng",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70150,
    district_code: 7002,
    province_code: 55,
    name: "จอมบึง จอมบึง ราชบุรี 70150",
    name_th: "จอมบึง จอมบึง ราชบุรี 70150",
    name_en: "Chom Bueng, Chom Bueng, Ratchaburi, 70150",
  },
  {
    id: 700202,
    districtTh: "จอมบึง",
    districtEn: "Chom Bueng",
    subdistrictTh: "ปากช่อง",
    subdistrictEn: "Pak Chong",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70150,
    district_code: 7002,
    province_code: 55,
    name: "จอมบึง ปากช่อง ราชบุรี 70150",
    name_th: "จอมบึง ปากช่อง ราชบุรี 70150",
    name_en: "Chom Bueng, Pak Chong, Ratchaburi, 70150",
  },
  {
    id: 700203,
    districtTh: "จอมบึง",
    districtEn: "Chom Bueng",
    subdistrictTh: "เบิกไพร",
    subdistrictEn: "Boek Phrai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70150,
    district_code: 7002,
    province_code: 55,
    name: "จอมบึง เบิกไพร ราชบุรี 70150",
    name_th: "จอมบึง เบิกไพร ราชบุรี 70150",
    name_en: "Chom Bueng, Boek Phrai, Ratchaburi, 70150",
  },
  {
    id: 700204,
    districtTh: "จอมบึง",
    districtEn: "Chom Bueng",
    subdistrictTh: "ด่านทับตะโก",
    subdistrictEn: "Dan Thap Tako",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70150,
    district_code: 7002,
    province_code: 55,
    name: "จอมบึง ด่านทับตะโก ราชบุรี 70150",
    name_th: "จอมบึง ด่านทับตะโก ราชบุรี 70150",
    name_en: "Chom Bueng, Dan Thap Tako, Ratchaburi, 70150",
  },
  {
    id: 700205,
    districtTh: "จอมบึง",
    districtEn: "Chom Bueng",
    subdistrictTh: "แก้มอ้น",
    subdistrictEn: "Kaem On",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70150,
    district_code: 7002,
    province_code: 55,
    name: "จอมบึง แก้มอ้น ราชบุรี 70150",
    name_th: "จอมบึง แก้มอ้น ราชบุรี 70150",
    name_en: "Chom Bueng, Kaem On, Ratchaburi, 70150",
  },
  {
    id: 700206,
    districtTh: "จอมบึง",
    districtEn: "Chom Bueng",
    subdistrictTh: "รางบัว",
    subdistrictEn: "Rang Bua",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70150,
    district_code: 7002,
    province_code: 55,
    name: "จอมบึง รางบัว ราชบุรี 70150",
    name_th: "จอมบึง รางบัว ราชบุรี 70150",
    name_en: "Chom Bueng, Rang Bua, Ratchaburi, 70150",
  },
  {
    id: 700301,
    districtTh: "สวนผึ้ง",
    districtEn: "Suan Phueng",
    subdistrictTh: "สวนผึ้ง",
    subdistrictEn: "Suan Phueng",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70180,
    district_code: 7003,
    province_code: 55,
    name: "สวนผึ้ง สวนผึ้ง ราชบุรี 70180",
    name_th: "สวนผึ้ง สวนผึ้ง ราชบุรี 70180",
    name_en: "Suan Phueng, Suan Phueng, Ratchaburi, 70180",
  },
  {
    id: 700302,
    districtTh: "สวนผึ้ง",
    districtEn: "Suan Phueng",
    subdistrictTh: "ป่าหวาย",
    subdistrictEn: "Pa Wai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70180,
    district_code: 7003,
    province_code: 55,
    name: "สวนผึ้ง ป่าหวาย ราชบุรี 70180",
    name_th: "สวนผึ้ง ป่าหวาย ราชบุรี 70180",
    name_en: "Suan Phueng, Pa Wai, Ratchaburi, 70180",
  },
  {
    id: 700304,
    districtTh: "สวนผึ้ง",
    districtEn: "Suan Phueng",
    subdistrictTh: "ท่าเคย",
    subdistrictEn: "Tha Khoei",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70180,
    district_code: 7003,
    province_code: 55,
    name: "สวนผึ้ง ท่าเคย ราชบุรี 70180",
    name_th: "สวนผึ้ง ท่าเคย ราชบุรี 70180",
    name_en: "Suan Phueng, Tha Khoei, Ratchaburi, 70180",
  },
  {
    id: 700307,
    districtTh: "สวนผึ้ง",
    districtEn: "Suan Phueng",
    subdistrictTh: "ตะนาวศรี",
    subdistrictEn: "Tanao Si",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70180,
    district_code: 7003,
    province_code: 55,
    name: "สวนผึ้ง ตะนาวศรี ราชบุรี 70180",
    name_th: "สวนผึ้ง ตะนาวศรี ราชบุรี 70180",
    name_en: "Suan Phueng, Tanao Si, Ratchaburi, 70180",
  },
  {
    id: 700401,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ดำเนินสะดวก",
    subdistrictEn: "Damnoen Saduak",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ดำเนินสะดวก ราชบุรี 70130",
    name_th: "ดำเนินสะดวก ดำเนินสะดวก ราชบุรี 70130",
    name_en: "Damnoen Saduak, Damnoen Saduak, Ratchaburi, 70130",
  },
  {
    id: 700402,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ประสาทสิทธิ์",
    subdistrictEn: "Prasat Sit",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70210,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ประสาทสิทธิ์ ราชบุรี 70210",
    name_th: "ดำเนินสะดวก ประสาทสิทธิ์ ราชบุรี 70210",
    name_en: "Damnoen Saduak, Prasat Sit, Ratchaburi, 70210",
  },
  {
    id: 700403,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ศรีสุราษฎร์",
    subdistrictEn: "Si Surat",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ศรีสุราษฎร์ ราชบุรี 70130",
    name_th: "ดำเนินสะดวก ศรีสุราษฎร์ ราชบุรี 70130",
    name_en: "Damnoen Saduak, Si Surat, Ratchaburi, 70130",
  },
  {
    id: 700404,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ตาหลวง",
    subdistrictEn: "Ta Luang",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ตาหลวง ราชบุรี 70130",
    name_th: "ดำเนินสะดวก ตาหลวง ราชบุรี 70130",
    name_en: "Damnoen Saduak, Ta Luang, Ratchaburi, 70130",
  },
  {
    id: 700405,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ดอนกรวย",
    subdistrictEn: "Don Kruai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ดอนกรวย ราชบุรี 70130",
    name_th: "ดำเนินสะดวก ดอนกรวย ราชบุรี 70130",
    name_en: "Damnoen Saduak, Don Kruai, Ratchaburi, 70130",
  },
  {
    id: 700406,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ดอนคลัง",
    subdistrictEn: "Don Khlang",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ดอนคลัง ราชบุรี 70130",
    name_th: "ดำเนินสะดวก ดอนคลัง ราชบุรี 70130",
    name_en: "Damnoen Saduak, Don Khlang, Ratchaburi, 70130",
  },
  {
    id: 700407,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "บัวงาม",
    subdistrictEn: "Bua Ngam",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70210,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก บัวงาม ราชบุรี 70210",
    name_th: "ดำเนินสะดวก บัวงาม ราชบุรี 70210",
    name_en: "Damnoen Saduak, Bua Ngam, Ratchaburi, 70210",
  },
  {
    id: 700408,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "บ้านไร่",
    subdistrictEn: "Ban Rai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก บ้านไร่ ราชบุรี 70130",
    name_th: "ดำเนินสะดวก บ้านไร่ ราชบุรี 70130",
    name_en: "Damnoen Saduak, Ban Rai, Ratchaburi, 70130",
  },
  {
    id: 700409,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "แพงพวย",
    subdistrictEn: "Phaengphuai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก แพงพวย ราชบุรี 70130",
    name_th: "ดำเนินสะดวก แพงพวย ราชบุรี 70130",
    name_en: "Damnoen Saduak, Phaengphuai, Ratchaburi, 70130",
  },
  {
    id: 700410,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "สี่หมื่น",
    subdistrictEn: "Si Muen",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก สี่หมื่น ราชบุรี 70130",
    name_th: "ดำเนินสะดวก สี่หมื่น ราชบุรี 70130",
    name_en: "Damnoen Saduak, Si Muen, Ratchaburi, 70130",
  },
  {
    id: 700411,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ท่านัด",
    subdistrictEn: "Tha Nat",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ท่านัด ราชบุรี 70130",
    name_th: "ดำเนินสะดวก ท่านัด ราชบุรี 70130",
    name_en: "Damnoen Saduak, Tha Nat, Ratchaburi, 70130",
  },
  {
    id: 700412,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ขุนพิทักษ์",
    subdistrictEn: "Khun Phithak",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ขุนพิทักษ์ ราชบุรี 70130",
    name_th: "ดำเนินสะดวก ขุนพิทักษ์ ราชบุรี 70130",
    name_en: "Damnoen Saduak, Khun Phithak, Ratchaburi, 70130",
  },
  {
    id: 700413,
    districtTh: "ดำเนินสะดวก",
    districtEn: "Damnoen Saduak",
    subdistrictTh: "ดอนไผ่",
    subdistrictEn: "Don Phai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70130,
    district_code: 7004,
    province_code: 55,
    name: "ดำเนินสะดวก ดอนไผ่ ราชบุรี 70130",
    name_th: "ดำเนินสะดวก ดอนไผ่ ราชบุรี 70130",
    name_en: "Damnoen Saduak, Don Phai, Ratchaburi, 70130",
  },
  {
    id: 700501,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "บ้านโป่ง",
    subdistrictEn: "Ban Pong",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง บ้านโป่ง ราชบุรี 70110",
    name_th: "บ้านโป่ง บ้านโป่ง ราชบุรี 70110",
    name_en: "Ban Pong, Ban Pong, Ratchaburi, 70110",
  },
  {
    id: 700502,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "ท่าผา",
    subdistrictEn: "Tha Pha",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง ท่าผา ราชบุรี 70110",
    name_th: "บ้านโป่ง ท่าผา ราชบุรี 70110",
    name_en: "Ban Pong, Tha Pha, Ratchaburi, 70110",
  },
  {
    id: 700503,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "กรับใหญ่",
    subdistrictEn: "Krap Yai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70190,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง กรับใหญ่ ราชบุรี 70190",
    name_th: "บ้านโป่ง กรับใหญ่ ราชบุรี 70190",
    name_en: "Ban Pong, Krap Yai, Ratchaburi, 70190",
  },
  {
    id: 700504,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "ปากแรต",
    subdistrictEn: "Pak Raet",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง ปากแรต ราชบุรี 70110",
    name_th: "บ้านโป่ง ปากแรต ราชบุรี 70110",
    name_en: "Ban Pong, Pak Raet, Ratchaburi, 70110",
  },
  {
    id: 700505,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "หนองกบ",
    subdistrictEn: "Nong Kop",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง หนองกบ ราชบุรี 70110",
    name_th: "บ้านโป่ง หนองกบ ราชบุรี 70110",
    name_en: "Ban Pong, Nong Kop, Ratchaburi, 70110",
  },
  {
    id: 700506,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "หนองอ้อ",
    subdistrictEn: "Nong O",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง หนองอ้อ ราชบุรี 70110",
    name_th: "บ้านโป่ง หนองอ้อ ราชบุรี 70110",
    name_en: "Ban Pong, Nong O, Ratchaburi, 70110",
  },
  {
    id: 700507,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "ดอนกระเบื้อง",
    subdistrictEn: "Don Krabueang",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง ดอนกระเบื้อง ราชบุรี 70110",
    name_th: "บ้านโป่ง ดอนกระเบื้อง ราชบุรี 70110",
    name_en: "Ban Pong, Don Krabueang, Ratchaburi, 70110",
  },
  {
    id: 700508,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "สวนกล้วย",
    subdistrictEn: "Suan Kluai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง สวนกล้วย ราชบุรี 70110",
    name_th: "บ้านโป่ง สวนกล้วย ราชบุรี 70110",
    name_en: "Ban Pong, Suan Kluai, Ratchaburi, 70110",
  },
  {
    id: 700509,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "นครชุมน์",
    subdistrictEn: "Nakhon Chum",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง นครชุมน์ ราชบุรี 70110",
    name_th: "บ้านโป่ง นครชุมน์ ราชบุรี 70110",
    name_en: "Ban Pong, Nakhon Chum, Ratchaburi, 70110",
  },
  {
    id: 700510,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "บ้านม่วง",
    subdistrictEn: "Ban Muang",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง บ้านม่วง ราชบุรี 70110",
    name_th: "บ้านโป่ง บ้านม่วง ราชบุรี 70110",
    name_en: "Ban Pong, Ban Muang, Ratchaburi, 70110",
  },
  {
    id: 700511,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "คุ้งพยอม",
    subdistrictEn: "Khung Phayom",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง คุ้งพยอม ราชบุรี 70110",
    name_th: "บ้านโป่ง คุ้งพยอม ราชบุรี 70110",
    name_en: "Ban Pong, Khung Phayom, Ratchaburi, 70110",
  },
  {
    id: 700512,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "หนองปลาหมอ",
    subdistrictEn: "Nong Pla Mo",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง หนองปลาหมอ ราชบุรี 70110",
    name_th: "บ้านโป่ง หนองปลาหมอ ราชบุรี 70110",
    name_en: "Ban Pong, Nong Pla Mo, Ratchaburi, 70110",
  },
  {
    id: 700513,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "เขาขลุง",
    subdistrictEn: "Khao Khlung",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง เขาขลุง ราชบุรี 70110",
    name_th: "บ้านโป่ง เขาขลุง ราชบุรี 70110",
    name_en: "Ban Pong, Khao Khlung, Ratchaburi, 70110",
  },
  {
    id: 700514,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "เบิกไพร",
    subdistrictEn: "Boek Phrai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง เบิกไพร ราชบุรี 70110",
    name_th: "บ้านโป่ง เบิกไพร ราชบุรี 70110",
    name_en: "Ban Pong, Boek Phrai, Ratchaburi, 70110",
  },
  {
    id: 700515,
    districtTh: "บ้านโป่ง",
    districtEn: "Ban Pong",
    subdistrictTh: "ลาดบัวขาว",
    subdistrictEn: "Lat Bua Khao",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70110,
    district_code: 7005,
    province_code: 55,
    name: "บ้านโป่ง ลาดบัวขาว ราชบุรี 70110",
    name_th: "บ้านโป่ง ลาดบัวขาว ราชบุรี 70110",
    name_en: "Ban Pong, Lat Bua Khao, Ratchaburi, 70110",
  },
  {
    id: 700601,
    districtTh: "บางแพ",
    districtEn: "Bang Phae",
    subdistrictTh: "บางแพ",
    subdistrictEn: "Bang Phae",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70160,
    district_code: 7006,
    province_code: 55,
    name: "บางแพ บางแพ ราชบุรี 70160",
    name_th: "บางแพ บางแพ ราชบุรี 70160",
    name_en: "Bang Phae, Bang Phae, Ratchaburi, 70160",
  },
  {
    id: 700602,
    districtTh: "บางแพ",
    districtEn: "Bang Phae",
    subdistrictTh: "วังเย็น",
    subdistrictEn: "Wang Yen",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70160,
    district_code: 7006,
    province_code: 55,
    name: "บางแพ วังเย็น ราชบุรี 70160",
    name_th: "บางแพ วังเย็น ราชบุรี 70160",
    name_en: "Bang Phae, Wang Yen, Ratchaburi, 70160",
  },
  {
    id: 700603,
    districtTh: "บางแพ",
    districtEn: "Bang Phae",
    subdistrictTh: "หัวโพ",
    subdistrictEn: "Hua Pho",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70160,
    district_code: 7006,
    province_code: 55,
    name: "บางแพ หัวโพ ราชบุรี 70160",
    name_th: "บางแพ หัวโพ ราชบุรี 70160",
    name_en: "Bang Phae, Hua Pho, Ratchaburi, 70160",
  },
  {
    id: 700604,
    districtTh: "บางแพ",
    districtEn: "Bang Phae",
    subdistrictTh: "วัดแก้ว",
    subdistrictEn: "Wat Kaeo",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70160,
    district_code: 7006,
    province_code: 55,
    name: "บางแพ วัดแก้ว ราชบุรี 70160",
    name_th: "บางแพ วัดแก้ว ราชบุรี 70160",
    name_en: "Bang Phae, Wat Kaeo, Ratchaburi, 70160",
  },
  {
    id: 700605,
    districtTh: "บางแพ",
    districtEn: "Bang Phae",
    subdistrictTh: "ดอนใหญ่",
    subdistrictEn: "Don Yai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70160,
    district_code: 7006,
    province_code: 55,
    name: "บางแพ ดอนใหญ่ ราชบุรี 70160",
    name_th: "บางแพ ดอนใหญ่ ราชบุรี 70160",
    name_en: "Bang Phae, Don Yai, Ratchaburi, 70160",
  },
  {
    id: 700606,
    districtTh: "บางแพ",
    districtEn: "Bang Phae",
    subdistrictTh: "ดอนคา",
    subdistrictEn: "Don Kha",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70160,
    district_code: 7006,
    province_code: 55,
    name: "บางแพ ดอนคา ราชบุรี 70160",
    name_th: "บางแพ ดอนคา ราชบุรี 70160",
    name_en: "Bang Phae, Don Kha, Ratchaburi, 70160",
  },
  {
    id: 700607,
    districtTh: "บางแพ",
    districtEn: "Bang Phae",
    subdistrictTh: "โพหัก",
    subdistrictEn: "Pho Hak",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70160,
    district_code: 7006,
    province_code: 55,
    name: "บางแพ โพหัก ราชบุรี 70160",
    name_th: "บางแพ โพหัก ราชบุรี 70160",
    name_en: "Bang Phae, Pho Hak, Ratchaburi, 70160",
  },
  {
    id: 700701,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "โพธาราม",
    subdistrictEn: "Photharam",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม โพธาราม ราชบุรี 70120",
    name_th: "โพธาราม โพธาราม ราชบุรี 70120",
    name_en: "Photharam, Photharam, Ratchaburi, 70120",
  },
  {
    id: 700702,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "ดอนกระเบื้อง",
    subdistrictEn: "Don Krabueang",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม ดอนกระเบื้อง ราชบุรี 70120",
    name_th: "โพธาราม ดอนกระเบื้อง ราชบุรี 70120",
    name_en: "Photharam, Don Krabueang, Ratchaburi, 70120",
  },
  {
    id: 700703,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "หนองโพ",
    subdistrictEn: "Nong Pho",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม หนองโพ ราชบุรี 70120",
    name_th: "โพธาราม หนองโพ ราชบุรี 70120",
    name_en: "Photharam, Nong Pho, Ratchaburi, 70120",
  },
  {
    id: 700704,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "บ้านเลือก",
    subdistrictEn: "Ban Lueak",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม บ้านเลือก ราชบุรี 70120",
    name_th: "โพธาราม บ้านเลือก ราชบุรี 70120",
    name_en: "Photharam, Ban Lueak, Ratchaburi, 70120",
  },
  {
    id: 700705,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "คลองตาคต",
    subdistrictEn: "Khlong Ta Khot",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม คลองตาคต ราชบุรี 70120",
    name_th: "โพธาราม คลองตาคต ราชบุรี 70120",
    name_en: "Photharam, Khlong Ta Khot, Ratchaburi, 70120",
  },
  {
    id: 700706,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "บ้านฆ้อง",
    subdistrictEn: "Ban Khong",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม บ้านฆ้อง ราชบุรี 70120",
    name_th: "โพธาราม บ้านฆ้อง ราชบุรี 70120",
    name_en: "Photharam, Ban Khong, Ratchaburi, 70120",
  },
  {
    id: 700707,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "บ้านสิงห์",
    subdistrictEn: "Ban Sing",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม บ้านสิงห์ ราชบุรี 70120",
    name_th: "โพธาราม บ้านสิงห์ ราชบุรี 70120",
    name_en: "Photharam, Ban Sing, Ratchaburi, 70120",
  },
  {
    id: 700708,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "ดอนทราย",
    subdistrictEn: "Don Sai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม ดอนทราย ราชบุรี 70120",
    name_th: "โพธาราม ดอนทราย ราชบุรี 70120",
    name_en: "Photharam, Don Sai, Ratchaburi, 70120",
  },
  {
    id: 700709,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "เจ็ดเสมียน",
    subdistrictEn: "Chet Samian",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม เจ็ดเสมียน ราชบุรี 70120",
    name_th: "โพธาราม เจ็ดเสมียน ราชบุรี 70120",
    name_en: "Photharam, Chet Samian, Ratchaburi, 70120",
  },
  {
    id: 700710,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "คลองข่อย",
    subdistrictEn: "Khlong Khoi",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม คลองข่อย ราชบุรี 70120",
    name_th: "โพธาราม คลองข่อย ราชบุรี 70120",
    name_en: "Photharam, Khlong Khoi, Ratchaburi, 70120",
  },
  {
    id: 700711,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "ชำแระ",
    subdistrictEn: "Chamrae",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม ชำแระ ราชบุรี 70120",
    name_th: "โพธาราม ชำแระ ราชบุรี 70120",
    name_en: "Photharam, Chamrae, Ratchaburi, 70120",
  },
  {
    id: 700712,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "สร้อยฟ้า",
    subdistrictEn: "Soi Fa",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม สร้อยฟ้า ราชบุรี 70120",
    name_th: "โพธาราม สร้อยฟ้า ราชบุรี 70120",
    name_en: "Photharam, Soi Fa, Ratchaburi, 70120",
  },
  {
    id: 700713,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "ท่าชุมพล",
    subdistrictEn: "Tha Chumphon",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม ท่าชุมพล ราชบุรี 70120",
    name_th: "โพธาราม ท่าชุมพล ราชบุรี 70120",
    name_en: "Photharam, Tha Chumphon, Ratchaburi, 70120",
  },
  {
    id: 700714,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "บางโตนด",
    subdistrictEn: "Bang Tanot",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม บางโตนด ราชบุรี 70120",
    name_th: "โพธาราม บางโตนด ราชบุรี 70120",
    name_en: "Photharam, Bang Tanot, Ratchaburi, 70120",
  },
  {
    id: 700715,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "เตาปูน",
    subdistrictEn: "Tao Pun",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม เตาปูน ราชบุรี 70120",
    name_th: "โพธาราม เตาปูน ราชบุรี 70120",
    name_en: "Photharam, Tao Pun, Ratchaburi, 70120",
  },
  {
    id: 700716,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "นางแก้ว",
    subdistrictEn: "Nang Kaeo",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม นางแก้ว ราชบุรี 70120",
    name_th: "โพธาราม นางแก้ว ราชบุรี 70120",
    name_en: "Photharam, Nang Kaeo, Ratchaburi, 70120",
  },
  {
    id: 700717,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "ธรรมเสน",
    subdistrictEn: "Thammasen",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม ธรรมเสน ราชบุรี 70120",
    name_th: "โพธาราม ธรรมเสน ราชบุรี 70120",
    name_en: "Photharam, Thammasen, Ratchaburi, 70120",
  },
  {
    id: 700718,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "เขาชะงุ้ม",
    subdistrictEn: "Khao Cha-ngum",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม เขาชะงุ้ม ราชบุรี 70120",
    name_th: "โพธาราม เขาชะงุ้ม ราชบุรี 70120",
    name_en: "Photharam, Khao Cha-ngum, Ratchaburi, 70120",
  },
  {
    id: 700719,
    districtTh: "โพธาราม",
    districtEn: "Photharam",
    subdistrictTh: "หนองกวาง",
    subdistrictEn: "Nong Kwang",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70120,
    district_code: 7007,
    province_code: 55,
    name: "โพธาราม หนองกวาง ราชบุรี 70120",
    name_th: "โพธาราม หนองกวาง ราชบุรี 70120",
    name_en: "Photharam, Nong Kwang, Ratchaburi, 70120",
  },
  {
    id: 700801,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "ทุ่งหลวง",
    subdistrictEn: "Thung Luang",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ ทุ่งหลวง ราชบุรี 70140",
    name_th: "ปากท่อ ทุ่งหลวง ราชบุรี 70140",
    name_en: "Pak Tho, Thung Luang, Ratchaburi, 70140",
  },
  {
    id: 700802,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "วังมะนาว",
    subdistrictEn: "Wang Manao",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ วังมะนาว ราชบุรี 70140",
    name_th: "ปากท่อ วังมะนาว ราชบุรี 70140",
    name_en: "Pak Tho, Wang Manao, Ratchaburi, 70140",
  },
  {
    id: 700803,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "ดอนทราย",
    subdistrictEn: "Don Sai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ ดอนทราย ราชบุรี 70140",
    name_th: "ปากท่อ ดอนทราย ราชบุรี 70140",
    name_en: "Pak Tho, Don Sai, Ratchaburi, 70140",
  },
  {
    id: 700804,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "หนองกระทุ่ม",
    subdistrictEn: "Nong Krathum",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ หนองกระทุ่ม ราชบุรี 70140",
    name_th: "ปากท่อ หนองกระทุ่ม ราชบุรี 70140",
    name_en: "Pak Tho, Nong Krathum, Ratchaburi, 70140",
  },
  {
    id: 700805,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "ปากท่อ",
    subdistrictEn: "Pak Tho",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ ปากท่อ ราชบุรี 70140",
    name_th: "ปากท่อ ปากท่อ ราชบุรี 70140",
    name_en: "Pak Tho, Pak Tho, Ratchaburi, 70140",
  },
  {
    id: 700806,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "ป่าไก่",
    subdistrictEn: "Pa Kai",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ ป่าไก่ ราชบุรี 70140",
    name_th: "ปากท่อ ป่าไก่ ราชบุรี 70140",
    name_en: "Pak Tho, Pa Kai, Ratchaburi, 70140",
  },
  {
    id: 700807,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "วัดยางงาม",
    subdistrictEn: "Wat Yang Ngam",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ วัดยางงาม ราชบุรี 70140",
    name_th: "ปากท่อ วัดยางงาม ราชบุรี 70140",
    name_en: "Pak Tho, Wat Yang Ngam, Ratchaburi, 70140",
  },
  {
    id: 700808,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "อ่างหิน",
    subdistrictEn: "Ang Hin",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ อ่างหิน ราชบุรี 70140",
    name_th: "ปากท่อ อ่างหิน ราชบุรี 70140",
    name_en: "Pak Tho, Ang Hin, Ratchaburi, 70140",
  },
  {
    id: 700809,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "บ่อกระดาน",
    subdistrictEn: "Bo Kradan",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ บ่อกระดาน ราชบุรี 70140",
    name_th: "ปากท่อ บ่อกระดาน ราชบุรี 70140",
    name_en: "Pak Tho, Bo Kradan, Ratchaburi, 70140",
  },
  {
    id: 700810,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "ยางหัก",
    subdistrictEn: "Yang Hak",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ ยางหัก ราชบุรี 70140",
    name_th: "ปากท่อ ยางหัก ราชบุรี 70140",
    name_en: "Pak Tho, Yang Hak, Ratchaburi, 70140",
  },
  {
    id: 700811,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "วันดาว",
    subdistrictEn: "Wan Dao",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ วันดาว ราชบุรี 70140",
    name_th: "ปากท่อ วันดาว ราชบุรี 70140",
    name_en: "Pak Tho, Wan Dao, Ratchaburi, 70140",
  },
  {
    id: 700812,
    districtTh: "ปากท่อ",
    districtEn: "Pak Tho",
    subdistrictTh: "ห้วยยางโทน",
    subdistrictEn: "Huai Yang Thon",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70140,
    district_code: 7008,
    province_code: 55,
    name: "ปากท่อ ห้วยยางโทน ราชบุรี 70140",
    name_th: "ปากท่อ ห้วยยางโทน ราชบุรี 70140",
    name_en: "Pak Tho, Huai Yang Thon, Ratchaburi, 70140",
  },
  {
    id: 700901,
    districtTh: "วัดเพลง",
    districtEn: "Wat Phleng",
    subdistrictTh: "เกาะศาลพระ",
    subdistrictEn: "Ko San Phra",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70170,
    district_code: 7009,
    province_code: 55,
    name: "วัดเพลง เกาะศาลพระ ราชบุรี 70170",
    name_th: "วัดเพลง เกาะศาลพระ ราชบุรี 70170",
    name_en: "Wat Phleng, Ko San Phra, Ratchaburi, 70170",
  },
  {
    id: 700902,
    districtTh: "วัดเพลง",
    districtEn: "Wat Phleng",
    subdistrictTh: "จอมประทัด",
    subdistrictEn: "Chom Prathat",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70170,
    district_code: 7009,
    province_code: 55,
    name: "วัดเพลง จอมประทัด ราชบุรี 70170",
    name_th: "วัดเพลง จอมประทัด ราชบุรี 70170",
    name_en: "Wat Phleng, Chom Prathat, Ratchaburi, 70170",
  },
  {
    id: 700903,
    districtTh: "วัดเพลง",
    districtEn: "Wat Phleng",
    subdistrictTh: "วัดเพลง",
    subdistrictEn: "Wat Pleng",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70170,
    district_code: 7009,
    province_code: 55,
    name: "วัดเพลง วัดเพลง ราชบุรี 70170",
    name_th: "วัดเพลง วัดเพลง ราชบุรี 70170",
    name_en: "Wat Phleng, Wat Pleng, Ratchaburi, 70170",
  },
  {
    id: 701001,
    districtTh: "บ้านคา",
    districtEn: "Ban Kha",
    subdistrictTh: "บ้านคา",
    subdistrictEn: "Ban Kha",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70180,
    district_code: 7010,
    province_code: 55,
    name: "บ้านคา บ้านคา ราชบุรี 70180",
    name_th: "บ้านคา บ้านคา ราชบุรี 70180",
    name_en: "Ban Kha, Ban Kha, Ratchaburi, 70180",
  },
  {
    id: 701002,
    districtTh: "บ้านคา",
    districtEn: "Ban Kha",
    subdistrictTh: "บ้านบึง",
    subdistrictEn: "Ban Bueng",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70180,
    district_code: 7010,
    province_code: 55,
    name: "บ้านคา บ้านบึง ราชบุรี 70180",
    name_th: "บ้านคา บ้านบึง ราชบุรี 70180",
    name_en: "Ban Kha, Ban Bueng, Ratchaburi, 70180",
  },
  {
    id: 701003,
    districtTh: "บ้านคา",
    districtEn: "Ban Kha",
    subdistrictTh: "หนองพันจันทร์",
    subdistrictEn: "Nong Phan Chan",
    provinceTh: "ราชบุรี",
    provinceEn: "Ratchaburi",
    zipcode: 70180,
    district_code: 7010,
    province_code: 55,
    name: "บ้านคา หนองพันจันทร์ ราชบุรี 70180",
    name_th: "บ้านคา หนองพันจันทร์ ราชบุรี 70180",
    name_en: "Ban Kha, Nong Phan Chan, Ratchaburi, 70180",
  },
  {
    id: 710101,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "บ้านเหนือ",
    subdistrictEn: "Ban Nuea",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี บ้านเหนือ กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี บ้านเหนือ กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Ban Nuea, Kanchanaburi, 71000",
  },
  {
    id: 710102,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "บ้านใต้",
    subdistrictEn: "Ban Tai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี บ้านใต้ กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี บ้านใต้ กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Ban Tai, Kanchanaburi, 71000",
  },
  {
    id: 710103,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "ปากแพรก",
    subdistrictEn: "Pak Phraek",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี ปากแพรก กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี ปากแพรก กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Pak Phraek, Kanchanaburi, 71000",
  },
  {
    id: 710104,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "ท่ามะขาม",
    subdistrictEn: "Tha Makham",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี ท่ามะขาม กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี ท่ามะขาม กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Tha Makham, Kanchanaburi, 71000",
  },
  {
    id: 710105,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "แก่งเสี้ยน",
    subdistrictEn: "Kaeng Sian",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี แก่งเสี้ยน กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี แก่งเสี้ยน กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Kaeng Sian, Kanchanaburi, 71000",
  },
  {
    id: 710106,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71190,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี หนองบัว กาญจนบุรี 71190",
    name_th: "เมืองกาญจนบุรี หนองบัว กาญจนบุรี 71190",
    name_en: "Mueang Kanchanaburi, Nong Bua, Kanchanaburi, 71190",
  },
  {
    id: 710107,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "ลาดหญ้า",
    subdistrictEn: "Lat Ya",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71190,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี ลาดหญ้า กาญจนบุรี 71190",
    name_th: "เมืองกาญจนบุรี ลาดหญ้า กาญจนบุรี 71190",
    name_en: "Mueang Kanchanaburi, Lat Ya, Kanchanaburi, 71190",
  },
  {
    id: 710108,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "วังด้ง",
    subdistrictEn: "Wang Dong",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71190,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี วังด้ง กาญจนบุรี 71190",
    name_th: "เมืองกาญจนบุรี วังด้ง กาญจนบุรี 71190",
    name_en: "Mueang Kanchanaburi, Wang Dong, Kanchanaburi, 71190",
  },
  {
    id: 710109,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "ช่องสะเดา",
    subdistrictEn: "Chong Sadao",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71190,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี ช่องสะเดา กาญจนบุรี 71190",
    name_th: "เมืองกาญจนบุรี ช่องสะเดา กาญจนบุรี 71190",
    name_en: "Mueang Kanchanaburi, Chong Sadao, Kanchanaburi, 71190",
  },
  {
    id: 710110,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "หนองหญ้า",
    subdistrictEn: "Nong Ya",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี หนองหญ้า กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี หนองหญ้า กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Nong Ya, Kanchanaburi, 71000",
  },
  {
    id: 710111,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "เกาะสำโรง",
    subdistrictEn: "Ko Samrong",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี เกาะสำโรง กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี เกาะสำโรง กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Ko Samrong, Kanchanaburi, 71000",
  },
  {
    id: 710113,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "บ้านเก่า",
    subdistrictEn: "Ban Kao",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี บ้านเก่า กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี บ้านเก่า กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Ban Kao, Kanchanaburi, 71000",
  },
  {
    id: 710116,
    districtTh: "เมืองกาญจนบุรี",
    districtEn: "Mueang Kanchanaburi",
    subdistrictTh: "วังเย็น",
    subdistrictEn: "Wang Yen",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71000,
    district_code: 7101,
    province_code: 56,
    name: "เมืองกาญจนบุรี วังเย็น กาญจนบุรี 71000",
    name_th: "เมืองกาญจนบุรี วังเย็น กาญจนบุรี 71000",
    name_en: "Mueang Kanchanaburi, Wang Yen, Kanchanaburi, 71000",
  },
  {
    id: 710201,
    districtTh: "ไทรโยค",
    districtEn: "Sai Yok",
    subdistrictTh: "ลุ่มสุ่ม",
    subdistrictEn: "Lum Sum",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71150,
    district_code: 7102,
    province_code: 56,
    name: "ไทรโยค ลุ่มสุ่ม กาญจนบุรี 71150",
    name_th: "ไทรโยค ลุ่มสุ่ม กาญจนบุรี 71150",
    name_en: "Sai Yok, Lum Sum, Kanchanaburi, 71150",
  },
  {
    id: 710202,
    districtTh: "ไทรโยค",
    districtEn: "Sai Yok",
    subdistrictTh: "ท่าเสา",
    subdistrictEn: "Tha Sao",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71150,
    district_code: 7102,
    province_code: 56,
    name: "ไทรโยค ท่าเสา กาญจนบุรี 71150",
    name_th: "ไทรโยค ท่าเสา กาญจนบุรี 71150",
    name_en: "Sai Yok, Tha Sao, Kanchanaburi, 71150",
  },
  {
    id: 710203,
    districtTh: "ไทรโยค",
    districtEn: "Sai Yok",
    subdistrictTh: "สิงห์",
    subdistrictEn: "Sing",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71150,
    district_code: 7102,
    province_code: 56,
    name: "ไทรโยค สิงห์ กาญจนบุรี 71150",
    name_th: "ไทรโยค สิงห์ กาญจนบุรี 71150",
    name_en: "Sai Yok, Sing, Kanchanaburi, 71150",
  },
  {
    id: 710204,
    districtTh: "ไทรโยค",
    districtEn: "Sai Yok",
    subdistrictTh: "ไทรโยค",
    subdistrictEn: "Sai Yok",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71150,
    district_code: 7102,
    province_code: 56,
    name: "ไทรโยค ไทรโยค กาญจนบุรี 71150",
    name_th: "ไทรโยค ไทรโยค กาญจนบุรี 71150",
    name_en: "Sai Yok, Sai Yok, Kanchanaburi, 71150",
  },
  {
    id: 710205,
    districtTh: "ไทรโยค",
    districtEn: "Sai Yok",
    subdistrictTh: "วังกระแจะ",
    subdistrictEn: "Wang Krachae",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71150,
    district_code: 7102,
    province_code: 56,
    name: "ไทรโยค วังกระแจะ กาญจนบุรี 71150",
    name_th: "ไทรโยค วังกระแจะ กาญจนบุรี 71150",
    name_en: "Sai Yok, Wang Krachae, Kanchanaburi, 71150",
  },
  {
    id: 710206,
    districtTh: "ไทรโยค",
    districtEn: "Sai Yok",
    subdistrictTh: "ศรีมงคล",
    subdistrictEn: "Si Mongkhon",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71150,
    district_code: 7102,
    province_code: 56,
    name: "ไทรโยค ศรีมงคล กาญจนบุรี 71150",
    name_th: "ไทรโยค ศรีมงคล กาญจนบุรี 71150",
    name_en: "Sai Yok, Si Mongkhon, Kanchanaburi, 71150",
  },
  {
    id: 710207,
    districtTh: "ไทรโยค",
    districtEn: "Sai Yok",
    subdistrictTh: "บ้องตี้",
    subdistrictEn: "Bongti",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71150,
    district_code: 7102,
    province_code: 56,
    name: "ไทรโยค บ้องตี้ กาญจนบุรี 71150",
    name_th: "ไทรโยค บ้องตี้ กาญจนบุรี 71150",
    name_en: "Sai Yok, Bongti, Kanchanaburi, 71150",
  },
  {
    id: 710301,
    districtTh: "บ่อพลอย",
    districtEn: "Bo Phloi",
    subdistrictTh: "บ่อพลอย",
    subdistrictEn: "Bo Phloi",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71160,
    district_code: 7103,
    province_code: 56,
    name: "บ่อพลอย บ่อพลอย กาญจนบุรี 71160",
    name_th: "บ่อพลอย บ่อพลอย กาญจนบุรี 71160",
    name_en: "Bo Phloi, Bo Phloi, Kanchanaburi, 71160",
  },
  {
    id: 710302,
    districtTh: "บ่อพลอย",
    districtEn: "Bo Phloi",
    subdistrictTh: "หนองกุ่ม",
    subdistrictEn: "Nong Kum",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71160,
    district_code: 7103,
    province_code: 56,
    name: "บ่อพลอย หนองกุ่ม กาญจนบุรี 71160",
    name_th: "บ่อพลอย หนองกุ่ม กาญจนบุรี 71160",
    name_en: "Bo Phloi, Nong Kum, Kanchanaburi, 71160",
  },
  {
    id: 710303,
    districtTh: "บ่อพลอย",
    districtEn: "Bo Phloi",
    subdistrictTh: "หนองรี",
    subdistrictEn: "Nong Ri",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71220,
    district_code: 7103,
    province_code: 56,
    name: "บ่อพลอย หนองรี กาญจนบุรี 71220",
    name_th: "บ่อพลอย หนองรี กาญจนบุรี 71220",
    name_en: "Bo Phloi, Nong Ri, Kanchanaburi, 71220",
  },
  {
    id: 710305,
    districtTh: "บ่อพลอย",
    districtEn: "Bo Phloi",
    subdistrictTh: "หลุมรัง",
    subdistrictEn: "Lum Rang",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71160,
    district_code: 7103,
    province_code: 56,
    name: "บ่อพลอย หลุมรัง กาญจนบุรี 71160",
    name_th: "บ่อพลอย หลุมรัง กาญจนบุรี 71160",
    name_en: "Bo Phloi, Lum Rang, Kanchanaburi, 71160",
  },
  {
    id: 710308,
    districtTh: "บ่อพลอย",
    districtEn: "Bo Phloi",
    subdistrictTh: "ช่องด่าน",
    subdistrictEn: "Chong Dan",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71160,
    district_code: 7103,
    province_code: 56,
    name: "บ่อพลอย ช่องด่าน กาญจนบุรี 71160",
    name_th: "บ่อพลอย ช่องด่าน กาญจนบุรี 71160",
    name_en: "Bo Phloi, Chong Dan, Kanchanaburi, 71160",
  },
  {
    id: 710309,
    districtTh: "บ่อพลอย",
    districtEn: "Bo Phloi",
    subdistrictTh: "หนองกร่าง",
    subdistrictEn: "Nong Krang",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71220,
    district_code: 7103,
    province_code: 56,
    name: "บ่อพลอย หนองกร่าง กาญจนบุรี 71220",
    name_th: "บ่อพลอย หนองกร่าง กาญจนบุรี 71220",
    name_en: "Bo Phloi, Nong Krang, Kanchanaburi, 71220",
  },
  {
    id: 710401,
    districtTh: "ศรีสวัสดิ์",
    districtEn: "Si Sawat",
    subdistrictTh: "นาสวน",
    subdistrictEn: "Na Suan",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71250,
    district_code: 7104,
    province_code: 56,
    name: "ศรีสวัสดิ์ นาสวน กาญจนบุรี 71250",
    name_th: "ศรีสวัสดิ์ นาสวน กาญจนบุรี 71250",
    name_en: "Si Sawat, Na Suan, Kanchanaburi, 71250",
  },
  {
    id: 710402,
    districtTh: "ศรีสวัสดิ์",
    districtEn: "Si Sawat",
    subdistrictTh: "ด่านแม่แฉลบ",
    subdistrictEn: "Dan Mae Chalaep",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71250,
    district_code: 7104,
    province_code: 56,
    name: "ศรีสวัสดิ์ ด่านแม่แฉลบ กาญจนบุรี 71250",
    name_th: "ศรีสวัสดิ์ ด่านแม่แฉลบ กาญจนบุรี 71250",
    name_en: "Si Sawat, Dan Mae Chalaep, Kanchanaburi, 71250",
  },
  {
    id: 710403,
    districtTh: "ศรีสวัสดิ์",
    districtEn: "Si Sawat",
    subdistrictTh: "หนองเป็ด",
    subdistrictEn: "Nong Pet",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71250,
    district_code: 7104,
    province_code: 56,
    name: "ศรีสวัสดิ์ หนองเป็ด กาญจนบุรี 71250",
    name_th: "ศรีสวัสดิ์ หนองเป็ด กาญจนบุรี 71250",
    name_en: "Si Sawat, Nong Pet, Kanchanaburi, 71250",
  },
  {
    id: 710404,
    districtTh: "ศรีสวัสดิ์",
    districtEn: "Si Sawat",
    subdistrictTh: "ท่ากระดาน",
    subdistrictEn: "Tha Kradan",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71250,
    district_code: 7104,
    province_code: 56,
    name: "ศรีสวัสดิ์ ท่ากระดาน กาญจนบุรี 71250",
    name_th: "ศรีสวัสดิ์ ท่ากระดาน กาญจนบุรี 71250",
    name_en: "Si Sawat, Tha Kradan, Kanchanaburi, 71250",
  },
  {
    id: 710405,
    districtTh: "ศรีสวัสดิ์",
    districtEn: "Si Sawat",
    subdistrictTh: "เขาโจด",
    subdistrictEn: "Khao Chot",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71220,
    district_code: 7104,
    province_code: 56,
    name: "ศรีสวัสดิ์ เขาโจด กาญจนบุรี 71220",
    name_th: "ศรีสวัสดิ์ เขาโจด กาญจนบุรี 71220",
    name_en: "Si Sawat, Khao Chot, Kanchanaburi, 71220",
  },
  {
    id: 710406,
    districtTh: "ศรีสวัสดิ์",
    districtEn: "Si Sawat",
    subdistrictTh: "แม่กระบุง",
    subdistrictEn: "Mae Krabung",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71250,
    district_code: 7104,
    province_code: 56,
    name: "ศรีสวัสดิ์ แม่กระบุง กาญจนบุรี 71250",
    name_th: "ศรีสวัสดิ์ แม่กระบุง กาญจนบุรี 71250",
    name_en: "Si Sawat, Mae Krabung, Kanchanaburi, 71250",
  },
  {
    id: 710501,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "พงตึก",
    subdistrictEn: "Phong Tuek",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา พงตึก กาญจนบุรี 71120",
    name_th: "ท่ามะกา พงตึก กาญจนบุรี 71120",
    name_en: "Tha Maka, Phong Tuek, Kanchanaburi, 71120",
  },
  {
    id: 710502,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "ยางม่วง",
    subdistrictEn: "Yang Muang",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา ยางม่วง กาญจนบุรี 71120",
    name_th: "ท่ามะกา ยางม่วง กาญจนบุรี 71120",
    name_en: "Tha Maka, Yang Muang, Kanchanaburi, 71120",
  },
  {
    id: 710503,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "ดอนชะเอม",
    subdistrictEn: "Don Cha-em",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71130,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา ดอนชะเอม กาญจนบุรี 71130",
    name_th: "ท่ามะกา ดอนชะเอม กาญจนบุรี 71130",
    name_en: "Tha Maka, Don Cha-em, Kanchanaburi, 71130",
  },
  {
    id: 710504,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "ท่าไม้",
    subdistrictEn: "Tha Mai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา ท่าไม้ กาญจนบุรี 71120",
    name_th: "ท่ามะกา ท่าไม้ กาญจนบุรี 71120",
    name_en: "Tha Maka, Tha Mai, Kanchanaburi, 71120",
  },
  {
    id: 710505,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "ตะคร้ำเอน",
    subdistrictEn: "Takhram En",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71130,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา ตะคร้ำเอน กาญจนบุรี 71130",
    name_th: "ท่ามะกา ตะคร้ำเอน กาญจนบุรี 71130",
    name_en: "Tha Maka, Takhram En, Kanchanaburi, 71130",
  },
  {
    id: 710506,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "ท่ามะกา",
    subdistrictEn: "Tha Maka",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา ท่ามะกา กาญจนบุรี 71120",
    name_th: "ท่ามะกา ท่ามะกา กาญจนบุรี 71120",
    name_en: "Tha Maka, Tha Maka, Kanchanaburi, 71120",
  },
  {
    id: 710507,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "ท่าเรือ",
    subdistrictEn: "Tha Ruea",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71130,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา ท่าเรือ กาญจนบุรี 71130",
    name_th: "ท่ามะกา ท่าเรือ กาญจนบุรี 71130",
    name_en: "Tha Maka, Tha Ruea, Kanchanaburi, 71130",
  },
  {
    id: 710508,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "โคกตะบอง",
    subdistrictEn: "Khok Tabong",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา โคกตะบอง กาญจนบุรี 71120",
    name_th: "ท่ามะกา โคกตะบอง กาญจนบุรี 71120",
    name_en: "Tha Maka, Khok Tabong, Kanchanaburi, 71120",
  },
  {
    id: 710509,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "ดอนขมิ้น",
    subdistrictEn: "Don Khamin",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา ดอนขมิ้น กาญจนบุรี 71120",
    name_th: "ท่ามะกา ดอนขมิ้น กาญจนบุรี 71120",
    name_en: "Tha Maka, Don Khamin, Kanchanaburi, 71120",
  },
  {
    id: 710510,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "อุโลกสี่หมื่น",
    subdistrictEn: "Ulok Si Muen",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71130,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา อุโลกสี่หมื่น กาญจนบุรี 71130",
    name_th: "ท่ามะกา อุโลกสี่หมื่น กาญจนบุรี 71130",
    name_en: "Tha Maka, Ulok Si Muen, Kanchanaburi, 71130",
  },
  {
    id: 710511,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "เขาสามสิบหาบ",
    subdistrictEn: "Khao Samsip Hap",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา เขาสามสิบหาบ กาญจนบุรี 71120",
    name_th: "ท่ามะกา เขาสามสิบหาบ กาญจนบุรี 71120",
    name_en: "Tha Maka, Khao Samsip Hap, Kanchanaburi, 71120",
  },
  {
    id: 710512,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "พระแท่น",
    subdistrictEn: "Phra Thaen",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71130,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา พระแท่น กาญจนบุรี 71130",
    name_th: "ท่ามะกา พระแท่น กาญจนบุรี 71130",
    name_en: "Tha Maka, Phra Thaen, Kanchanaburi, 71130",
  },
  {
    id: 710513,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "หวายเหนียว",
    subdistrictEn: "Wai Niao",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา หวายเหนียว กาญจนบุรี 71120",
    name_th: "ท่ามะกา หวายเหนียว กาญจนบุรี 71120",
    name_en: "Tha Maka, Wai Niao, Kanchanaburi, 71120",
  },
  {
    id: 710514,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "แสนตอ",
    subdistrictEn: "Saen To",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71130,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา แสนตอ กาญจนบุรี 71130",
    name_th: "ท่ามะกา แสนตอ กาญจนบุรี 71130",
    name_en: "Tha Maka, Saen To, Kanchanaburi, 71130",
  },
  {
    id: 710515,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "สนามแย้",
    subdistrictEn: "Sanam Yae",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 70190,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา สนามแย้ กาญจนบุรี 70190",
    name_th: "ท่ามะกา สนามแย้ กาญจนบุรี 70190",
    name_en: "Tha Maka, Sanam Yae, Kanchanaburi, 70190",
  },
  {
    id: 710516,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "ท่าเสา",
    subdistrictEn: "Tha Sao",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71120,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา ท่าเสา กาญจนบุรี 71120",
    name_th: "ท่ามะกา ท่าเสา กาญจนบุรี 71120",
    name_en: "Tha Maka, Tha Sao, Kanchanaburi, 71120",
  },
  {
    id: 710517,
    districtTh: "ท่ามะกา",
    districtEn: "Tha Maka",
    subdistrictTh: "หนองลาน",
    subdistrictEn: "Nong Lan",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71130,
    district_code: 7105,
    province_code: 56,
    name: "ท่ามะกา หนองลาน กาญจนบุรี 71130",
    name_th: "ท่ามะกา หนองลาน กาญจนบุรี 71130",
    name_en: "Tha Maka, Nong Lan, Kanchanaburi, 71130",
  },
  {
    id: 710601,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "ท่าม่วง",
    subdistrictEn: "Tha Muang",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง ท่าม่วง กาญจนบุรี 71110",
    name_th: "ท่าม่วง ท่าม่วง กาญจนบุรี 71110",
    name_en: "Tha Muang, Tha Muang, Kanchanaburi, 71110",
  },
  {
    id: 710602,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "วังขนาย",
    subdistrictEn: "Wang Khanai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง วังขนาย กาญจนบุรี 71110",
    name_th: "ท่าม่วง วังขนาย กาญจนบุรี 71110",
    name_en: "Tha Muang, Wang Khanai, Kanchanaburi, 71110",
  },
  {
    id: 710603,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "วังศาลา",
    subdistrictEn: "Wang Sala",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง วังศาลา กาญจนบุรี 71110",
    name_th: "ท่าม่วง วังศาลา กาญจนบุรี 71110",
    name_en: "Tha Muang, Wang Sala, Kanchanaburi, 71110",
  },
  {
    id: 710604,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "ท่าล้อ",
    subdistrictEn: "Tha Lo",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง ท่าล้อ กาญจนบุรี 71110",
    name_th: "ท่าม่วง ท่าล้อ กาญจนบุรี 71110",
    name_en: "Tha Muang, Tha Lo, Kanchanaburi, 71110",
  },
  {
    id: 710605,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "หนองขาว",
    subdistrictEn: "Nong Khao",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง หนองขาว กาญจนบุรี 71110",
    name_th: "ท่าม่วง หนองขาว กาญจนบุรี 71110",
    name_en: "Tha Muang, Nong Khao, Kanchanaburi, 71110",
  },
  {
    id: 710606,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "ทุ่งทอง",
    subdistrictEn: "Thung Thong",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง ทุ่งทอง กาญจนบุรี 71110",
    name_th: "ท่าม่วง ทุ่งทอง กาญจนบุรี 71110",
    name_en: "Tha Muang, Thung Thong, Kanchanaburi, 71110",
  },
  {
    id: 710607,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "เขาน้อย",
    subdistrictEn: "Khao Noi",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง เขาน้อย กาญจนบุรี 71110",
    name_th: "ท่าม่วง เขาน้อย กาญจนบุรี 71110",
    name_en: "Tha Muang, Khao Noi, Kanchanaburi, 71110",
  },
  {
    id: 710608,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "ม่วงชุม",
    subdistrictEn: "Muang Chum",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง ม่วงชุม กาญจนบุรี 71110",
    name_th: "ท่าม่วง ม่วงชุม กาญจนบุรี 71110",
    name_en: "Tha Muang, Muang Chum, Kanchanaburi, 71110",
  },
  {
    id: 710609,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง บ้านใหม่ กาญจนบุรี 71110",
    name_th: "ท่าม่วง บ้านใหม่ กาญจนบุรี 71110",
    name_en: "Tha Muang, Ban Mai, Kanchanaburi, 71110",
  },
  {
    id: 710610,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "พังตรุ",
    subdistrictEn: "Phang Tru",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง พังตรุ กาญจนบุรี 71110",
    name_th: "ท่าม่วง พังตรุ กาญจนบุรี 71110",
    name_en: "Tha Muang, Phang Tru, Kanchanaburi, 71110",
  },
  {
    id: 710611,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "ท่าตะคร้อ",
    subdistrictEn: "Tha Takhro",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71130,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง ท่าตะคร้อ กาญจนบุรี 71130",
    name_th: "ท่าม่วง ท่าตะคร้อ กาญจนบุรี 71130",
    name_en: "Tha Muang, Tha Takhro, Kanchanaburi, 71130",
  },
  {
    id: 710612,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "รางสาลี่",
    subdistrictEn: "Rang Sali",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง รางสาลี่ กาญจนบุรี 71110",
    name_th: "ท่าม่วง รางสาลี่ กาญจนบุรี 71110",
    name_en: "Tha Muang, Rang Sali, Kanchanaburi, 71110",
  },
  {
    id: 710613,
    districtTh: "ท่าม่วง",
    districtEn: "Tha Muang",
    subdistrictTh: "หนองตากยา",
    subdistrictEn: "Nong Tak Ya",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71110,
    district_code: 7106,
    province_code: 56,
    name: "ท่าม่วง หนองตากยา กาญจนบุรี 71110",
    name_th: "ท่าม่วง หนองตากยา กาญจนบุรี 71110",
    name_en: "Tha Muang, Nong Tak Ya, Kanchanaburi, 71110",
  },
  {
    id: 710701,
    districtTh: "ทองผาภูมิ",
    districtEn: "Pha Phum",
    subdistrictTh: "ท่าขนุน",
    subdistrictEn: "Tha Khanun",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71180,
    district_code: 7107,
    province_code: 56,
    name: "ทองผาภูมิ ท่าขนุน กาญจนบุรี 71180",
    name_th: "ทองผาภูมิ ท่าขนุน กาญจนบุรี 71180",
    name_en: "Pha Phum, Tha Khanun, Kanchanaburi, 71180",
  },
  {
    id: 710702,
    districtTh: "ทองผาภูมิ",
    districtEn: "Pha Phum",
    subdistrictTh: "ปิล๊อก",
    subdistrictEn: "Pilok",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71180,
    district_code: 7107,
    province_code: 56,
    name: "ทองผาภูมิ ปิล๊อก กาญจนบุรี 71180",
    name_th: "ทองผาภูมิ ปิล๊อก กาญจนบุรี 71180",
    name_en: "Pha Phum, Pilok, Kanchanaburi, 71180",
  },
  {
    id: 710703,
    districtTh: "ทองผาภูมิ",
    districtEn: "Pha Phum",
    subdistrictTh: "หินดาด",
    subdistrictEn: "Hin Dat",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71180,
    district_code: 7107,
    province_code: 56,
    name: "ทองผาภูมิ หินดาด กาญจนบุรี 71180",
    name_th: "ทองผาภูมิ หินดาด กาญจนบุรี 71180",
    name_en: "Pha Phum, Hin Dat, Kanchanaburi, 71180",
  },
  {
    id: 710704,
    districtTh: "ทองผาภูมิ",
    districtEn: "Pha Phum",
    subdistrictTh: "ลิ่นถิ่น",
    subdistrictEn: "Linthin",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71180,
    district_code: 7107,
    province_code: 56,
    name: "ทองผาภูมิ ลิ่นถิ่น กาญจนบุรี 71180",
    name_th: "ทองผาภูมิ ลิ่นถิ่น กาญจนบุรี 71180",
    name_en: "Pha Phum, Linthin, Kanchanaburi, 71180",
  },
  {
    id: 710705,
    districtTh: "ทองผาภูมิ",
    districtEn: "Pha Phum",
    subdistrictTh: "ชะแล",
    subdistrictEn: "Chalae",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71180,
    district_code: 7107,
    province_code: 56,
    name: "ทองผาภูมิ ชะแล กาญจนบุรี 71180",
    name_th: "ทองผาภูมิ ชะแล กาญจนบุรี 71180",
    name_en: "Pha Phum, Chalae, Kanchanaburi, 71180",
  },
  {
    id: 710706,
    districtTh: "ทองผาภูมิ",
    districtEn: "Pha Phum",
    subdistrictTh: "ห้วยเขย่ง",
    subdistrictEn: "Huai Khayeng",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71180,
    district_code: 7107,
    province_code: 56,
    name: "ทองผาภูมิ ห้วยเขย่ง กาญจนบุรี 71180",
    name_th: "ทองผาภูมิ ห้วยเขย่ง กาญจนบุรี 71180",
    name_en: "Pha Phum, Huai Khayeng, Kanchanaburi, 71180",
  },
  {
    id: 710707,
    districtTh: "ทองผาภูมิ",
    districtEn: "Pha Phum",
    subdistrictTh: "สหกรณ์นิคม",
    subdistrictEn: "Sahakon Nikhom",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71180,
    district_code: 7107,
    province_code: 56,
    name: "ทองผาภูมิ สหกรณ์นิคม กาญจนบุรี 71180",
    name_th: "ทองผาภูมิ สหกรณ์นิคม กาญจนบุรี 71180",
    name_en: "Pha Phum, Sahakon Nikhom, Kanchanaburi, 71180",
  },
  {
    id: 710801,
    districtTh: "สังขละบุรี",
    districtEn: "Sangkhla Buri",
    subdistrictTh: "หนองลู",
    subdistrictEn: "Nong Lu",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71240,
    district_code: 7108,
    province_code: 56,
    name: "สังขละบุรี หนองลู กาญจนบุรี 71240",
    name_th: "สังขละบุรี หนองลู กาญจนบุรี 71240",
    name_en: "Sangkhla Buri, Nong Lu, Kanchanaburi, 71240",
  },
  {
    id: 710802,
    districtTh: "สังขละบุรี",
    districtEn: "Sangkhla Buri",
    subdistrictTh: "ปรังเผล",
    subdistrictEn: "Prangphle",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71240,
    district_code: 7108,
    province_code: 56,
    name: "สังขละบุรี ปรังเผล กาญจนบุรี 71240",
    name_th: "สังขละบุรี ปรังเผล กาญจนบุรี 71240",
    name_en: "Sangkhla Buri, Prangphle, Kanchanaburi, 71240",
  },
  {
    id: 710803,
    districtTh: "สังขละบุรี",
    districtEn: "Sangkhla Buri",
    subdistrictTh: "ไล่โว่",
    subdistrictEn: "Lai Wo",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71240,
    district_code: 7108,
    province_code: 56,
    name: "สังขละบุรี ไล่โว่ กาญจนบุรี 71240",
    name_th: "สังขละบุรี ไล่โว่ กาญจนบุรี 71240",
    name_en: "Sangkhla Buri, Lai Wo, Kanchanaburi, 71240",
  },
  {
    id: 710901,
    districtTh: "พนมทวน",
    districtEn: "Phanom Thuan",
    subdistrictTh: "พนมทวน",
    subdistrictEn: "Phanom Thuan",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71140,
    district_code: 7109,
    province_code: 56,
    name: "พนมทวน พนมทวน กาญจนบุรี 71140",
    name_th: "พนมทวน พนมทวน กาญจนบุรี 71140",
    name_en: "Phanom Thuan, Phanom Thuan, Kanchanaburi, 71140",
  },
  {
    id: 710902,
    districtTh: "พนมทวน",
    districtEn: "Phanom Thuan",
    subdistrictTh: "หนองโรง",
    subdistrictEn: "Nong Rong",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71140,
    district_code: 7109,
    province_code: 56,
    name: "พนมทวน หนองโรง กาญจนบุรี 71140",
    name_th: "พนมทวน หนองโรง กาญจนบุรี 71140",
    name_en: "Phanom Thuan, Nong Rong, Kanchanaburi, 71140",
  },
  {
    id: 710903,
    districtTh: "พนมทวน",
    districtEn: "Phanom Thuan",
    subdistrictTh: "ทุ่งสมอ",
    subdistrictEn: "Thung Samo",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71140,
    district_code: 7109,
    province_code: 56,
    name: "พนมทวน ทุ่งสมอ กาญจนบุรี 71140",
    name_th: "พนมทวน ทุ่งสมอ กาญจนบุรี 71140",
    name_en: "Phanom Thuan, Thung Samo, Kanchanaburi, 71140",
  },
  {
    id: 710904,
    districtTh: "พนมทวน",
    districtEn: "Phanom Thuan",
    subdistrictTh: "ดอนเจดีย์",
    subdistrictEn: "Don Chedi",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71140,
    district_code: 7109,
    province_code: 56,
    name: "พนมทวน ดอนเจดีย์ กาญจนบุรี 71140",
    name_th: "พนมทวน ดอนเจดีย์ กาญจนบุรี 71140",
    name_en: "Phanom Thuan, Don Chedi, Kanchanaburi, 71140",
  },
  {
    id: 710905,
    districtTh: "พนมทวน",
    districtEn: "Phanom Thuan",
    subdistrictTh: "พังตรุ",
    subdistrictEn: "Phang Tru",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71140,
    district_code: 7109,
    province_code: 56,
    name: "พนมทวน พังตรุ กาญจนบุรี 71140",
    name_th: "พนมทวน พังตรุ กาญจนบุรี 71140",
    name_en: "Phanom Thuan, Phang Tru, Kanchanaburi, 71140",
  },
  {
    id: 710906,
    districtTh: "พนมทวน",
    districtEn: "Phanom Thuan",
    subdistrictTh: "รางหวาย",
    subdistrictEn: "Rang Wai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71170,
    district_code: 7109,
    province_code: 56,
    name: "พนมทวน รางหวาย กาญจนบุรี 71170",
    name_th: "พนมทวน รางหวาย กาญจนบุรี 71170",
    name_en: "Phanom Thuan, Rang Wai, Kanchanaburi, 71170",
  },
  {
    id: 710911,
    districtTh: "พนมทวน",
    districtEn: "Phanom Thuan",
    subdistrictTh: "หนองสาหร่าย",
    subdistrictEn: "Nong Sarai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71140,
    district_code: 7109,
    province_code: 56,
    name: "พนมทวน หนองสาหร่าย กาญจนบุรี 71140",
    name_th: "พนมทวน หนองสาหร่าย กาญจนบุรี 71140",
    name_en: "Phanom Thuan, Nong Sarai, Kanchanaburi, 71140",
  },
  {
    id: 710912,
    districtTh: "พนมทวน",
    districtEn: "Phanom Thuan",
    subdistrictTh: "ดอนตาเพชร",
    subdistrictEn: "Don Ta Phet",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71140,
    district_code: 7109,
    province_code: 56,
    name: "พนมทวน ดอนตาเพชร กาญจนบุรี 71140",
    name_th: "พนมทวน ดอนตาเพชร กาญจนบุรี 71140",
    name_en: "Phanom Thuan, Don Ta Phet, Kanchanaburi, 71140",
  },
  {
    id: 711001,
    districtTh: "เลาขวัญ",
    districtEn: "Lao Khwan",
    subdistrictTh: "เลาขวัญ",
    subdistrictEn: "Lao Khwan",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71210,
    district_code: 7110,
    province_code: 56,
    name: "เลาขวัญ เลาขวัญ กาญจนบุรี 71210",
    name_th: "เลาขวัญ เลาขวัญ กาญจนบุรี 71210",
    name_en: "Lao Khwan, Lao Khwan, Kanchanaburi, 71210",
  },
  {
    id: 711002,
    districtTh: "เลาขวัญ",
    districtEn: "Lao Khwan",
    subdistrictTh: "หนองโสน",
    subdistrictEn: "Nong Sano",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71210,
    district_code: 7110,
    province_code: 56,
    name: "เลาขวัญ หนองโสน กาญจนบุรี 71210",
    name_th: "เลาขวัญ หนองโสน กาญจนบุรี 71210",
    name_en: "Lao Khwan, Nong Sano, Kanchanaburi, 71210",
  },
  {
    id: 711003,
    districtTh: "เลาขวัญ",
    districtEn: "Lao Khwan",
    subdistrictTh: "หนองประดู่",
    subdistrictEn: "Nong Pradu",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71210,
    district_code: 7110,
    province_code: 56,
    name: "เลาขวัญ หนองประดู่ กาญจนบุรี 71210",
    name_th: "เลาขวัญ หนองประดู่ กาญจนบุรี 71210",
    name_en: "Lao Khwan, Nong Pradu, Kanchanaburi, 71210",
  },
  {
    id: 711004,
    districtTh: "เลาขวัญ",
    districtEn: "Lao Khwan",
    subdistrictTh: "หนองปลิง",
    subdistrictEn: "Nong Pling",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71210,
    district_code: 7110,
    province_code: 56,
    name: "เลาขวัญ หนองปลิง กาญจนบุรี 71210",
    name_th: "เลาขวัญ หนองปลิง กาญจนบุรี 71210",
    name_en: "Lao Khwan, Nong Pling, Kanchanaburi, 71210",
  },
  {
    id: 711005,
    districtTh: "เลาขวัญ",
    districtEn: "Lao Khwan",
    subdistrictTh: "หนองนกแก้ว",
    subdistrictEn: "Nong Nok Kaeo",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71210,
    district_code: 7110,
    province_code: 56,
    name: "เลาขวัญ หนองนกแก้ว กาญจนบุรี 71210",
    name_th: "เลาขวัญ หนองนกแก้ว กาญจนบุรี 71210",
    name_en: "Lao Khwan, Nong Nok Kaeo, Kanchanaburi, 71210",
  },
  {
    id: 711006,
    districtTh: "เลาขวัญ",
    districtEn: "Lao Khwan",
    subdistrictTh: "ทุ่งกระบ่ำ",
    subdistrictEn: "Thung Krabam",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71210,
    district_code: 7110,
    province_code: 56,
    name: "เลาขวัญ ทุ่งกระบ่ำ กาญจนบุรี 71210",
    name_th: "เลาขวัญ ทุ่งกระบ่ำ กาญจนบุรี 71210",
    name_en: "Lao Khwan, Thung Krabam, Kanchanaburi, 71210",
  },
  {
    id: 711007,
    districtTh: "เลาขวัญ",
    districtEn: "Lao Khwan",
    subdistrictTh: "หนองฝ้าย",
    subdistrictEn: "Nong Fai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71210,
    district_code: 7110,
    province_code: 56,
    name: "เลาขวัญ หนองฝ้าย กาญจนบุรี 71210",
    name_th: "เลาขวัญ หนองฝ้าย กาญจนบุรี 71210",
    name_en: "Lao Khwan, Nong Fai, Kanchanaburi, 71210",
  },
  {
    id: 711101,
    districtTh: "ด่านมะขามเตี้ย",
    districtEn: "Dan Makham Tia",
    subdistrictTh: "ด่านมะขามเตี้ย",
    subdistrictEn: "Dan Makham Tia",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71260,
    district_code: 7111,
    province_code: 56,
    name: "ด่านมะขามเตี้ย ด่านมะขามเตี้ย กาญจนบุรี 71260",
    name_th: "ด่านมะขามเตี้ย ด่านมะขามเตี้ย กาญจนบุรี 71260",
    name_en: "Dan Makham Tia, Dan Makham Tia, Kanchanaburi, 71260",
  },
  {
    id: 711102,
    districtTh: "ด่านมะขามเตี้ย",
    districtEn: "Dan Makham Tia",
    subdistrictTh: "กลอนโด",
    subdistrictEn: "Klondo",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71260,
    district_code: 7111,
    province_code: 56,
    name: "ด่านมะขามเตี้ย กลอนโด กาญจนบุรี 71260",
    name_th: "ด่านมะขามเตี้ย กลอนโด กาญจนบุรี 71260",
    name_en: "Dan Makham Tia, Klondo, Kanchanaburi, 71260",
  },
  {
    id: 711103,
    districtTh: "ด่านมะขามเตี้ย",
    districtEn: "Dan Makham Tia",
    subdistrictTh: "จรเข้เผือก",
    subdistrictEn: "Chorakhe Phueak",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71260,
    district_code: 7111,
    province_code: 56,
    name: "ด่านมะขามเตี้ย จรเข้เผือก กาญจนบุรี 71260",
    name_th: "ด่านมะขามเตี้ย จรเข้เผือก กาญจนบุรี 71260",
    name_en: "Dan Makham Tia, Chorakhe Phueak, Kanchanaburi, 71260",
  },
  {
    id: 711104,
    districtTh: "ด่านมะขามเตี้ย",
    districtEn: "Dan Makham Tia",
    subdistrictTh: "หนองไผ่",
    subdistrictEn: "Nong Phai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71260,
    district_code: 7111,
    province_code: 56,
    name: "ด่านมะขามเตี้ย หนองไผ่ กาญจนบุรี 71260",
    name_th: "ด่านมะขามเตี้ย หนองไผ่ กาญจนบุรี 71260",
    name_en: "Dan Makham Tia, Nong Phai, Kanchanaburi, 71260",
  },
  {
    id: 711201,
    districtTh: "หนองปรือ",
    districtEn: "Nong Prue",
    subdistrictTh: "หนองปรือ",
    subdistrictEn: "Nong Prue",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71220,
    district_code: 7112,
    province_code: 56,
    name: "หนองปรือ หนองปรือ กาญจนบุรี 71220",
    name_th: "หนองปรือ หนองปรือ กาญจนบุรี 71220",
    name_en: "Nong Prue, Nong Prue, Kanchanaburi, 71220",
  },
  {
    id: 711202,
    districtTh: "หนองปรือ",
    districtEn: "Nong Prue",
    subdistrictTh: "หนองปลาไหล",
    subdistrictEn: "Nong Pla Lai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71220,
    district_code: 7112,
    province_code: 56,
    name: "หนองปรือ หนองปลาไหล กาญจนบุรี 71220",
    name_th: "หนองปรือ หนองปลาไหล กาญจนบุรี 71220",
    name_en: "Nong Prue, Nong Pla Lai, Kanchanaburi, 71220",
  },
  {
    id: 711203,
    districtTh: "หนองปรือ",
    districtEn: "Nong Prue",
    subdistrictTh: "สมเด็จเจริญ",
    subdistrictEn: "Somdet Charoen",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71220,
    district_code: 7112,
    province_code: 56,
    name: "หนองปรือ สมเด็จเจริญ กาญจนบุรี 71220",
    name_th: "หนองปรือ สมเด็จเจริญ กาญจนบุรี 71220",
    name_en: "Nong Prue, Somdet Charoen, Kanchanaburi, 71220",
  },
  {
    id: 711301,
    districtTh: "ห้วยกระเจา",
    districtEn: "Huai Krachao",
    subdistrictTh: "ห้วยกระเจา",
    subdistrictEn: "Huai Krachao",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71170,
    district_code: 7113,
    province_code: 56,
    name: "ห้วยกระเจา ห้วยกระเจา กาญจนบุรี 71170",
    name_th: "ห้วยกระเจา ห้วยกระเจา กาญจนบุรี 71170",
    name_en: "Huai Krachao, Huai Krachao, Kanchanaburi, 71170",
  },
  {
    id: 711302,
    districtTh: "ห้วยกระเจา",
    districtEn: "Huai Krachao",
    subdistrictTh: "วังไผ่",
    subdistrictEn: "Wang Phai",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71170,
    district_code: 7113,
    province_code: 56,
    name: "ห้วยกระเจา วังไผ่ กาญจนบุรี 71170",
    name_th: "ห้วยกระเจา วังไผ่ กาญจนบุรี 71170",
    name_en: "Huai Krachao, Wang Phai, Kanchanaburi, 71170",
  },
  {
    id: 711303,
    districtTh: "ห้วยกระเจา",
    districtEn: "Huai Krachao",
    subdistrictTh: "ดอนแสลบ",
    subdistrictEn: "Don Salaep",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71170,
    district_code: 7113,
    province_code: 56,
    name: "ห้วยกระเจา ดอนแสลบ กาญจนบุรี 71170",
    name_th: "ห้วยกระเจา ดอนแสลบ กาญจนบุรี 71170",
    name_en: "Huai Krachao, Don Salaep, Kanchanaburi, 71170",
  },
  {
    id: 711304,
    districtTh: "ห้วยกระเจา",
    districtEn: "Huai Krachao",
    subdistrictTh: "สระลงเรือ",
    subdistrictEn: "Sa Long Ruea",
    provinceTh: "กาญจนบุรี",
    provinceEn: "Kanchanaburi",
    zipcode: 71170,
    district_code: 7113,
    province_code: 56,
    name: "ห้วยกระเจา สระลงเรือ กาญจนบุรี 71170",
    name_th: "ห้วยกระเจา สระลงเรือ กาญจนบุรี 71170",
    name_en: "Huai Krachao, Sa Long Ruea, Kanchanaburi, 71170",
  },
  {
    id: 720101,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ท่าพี่เลี้ยง",
    subdistrictEn: "Tha Phi Liang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ท่าพี่เลี้ยง สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี ท่าพี่เลี้ยง สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Tha Phi Liang, Suphan Buri, 72000",
  },
  {
    id: 720102,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "รั้วใหญ่",
    subdistrictEn: "Rua Yai",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี รั้วใหญ่ สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี รั้วใหญ่ สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Rua Yai, Suphan Buri, 72000",
  },
  {
    id: 720103,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ทับตีเหล็ก",
    subdistrictEn: "Thap Ti Lek",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ทับตีเหล็ก สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี ทับตีเหล็ก สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Thap Ti Lek, Suphan Buri, 72000",
  },
  {
    id: 720104,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ท่าระหัด",
    subdistrictEn: "Tha Rahat",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ท่าระหัด สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี ท่าระหัด สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Tha Rahat, Suphan Buri, 72000",
  },
  {
    id: 720105,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ไผ่ขวาง",
    subdistrictEn: "Phai Kwang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ไผ่ขวาง สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี ไผ่ขวาง สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Phai Kwang, Suphan Buri, 72000",
  },
  {
    id: 720106,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "โคกโคเฒ่า",
    subdistrictEn: "Khok Kho Thao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี โคกโคเฒ่า สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี โคกโคเฒ่า สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Khok Kho Thao, Suphan Buri, 72000",
  },
  {
    id: 720107,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ดอนตาล",
    subdistrictEn: "Don Tan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ดอนตาล สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี ดอนตาล สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Don Tan, Suphan Buri, 72000",
  },
  {
    id: 720108,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ดอนมะสังข์",
    subdistrictEn: "Don Masang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ดอนมะสังข์ สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี ดอนมะสังข์ สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Don Masang, Suphan Buri, 72000",
  },
  {
    id: 720109,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "พิหารแดง",
    subdistrictEn: "Phihan Daeng",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี พิหารแดง สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี พิหารแดง สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Phihan Daeng, Suphan Buri, 72000",
  },
  {
    id: 720110,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ดอนกำยาน",
    subdistrictEn: "Don Kamyan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ดอนกำยาน สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี ดอนกำยาน สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Don Kamyan, Suphan Buri, 72000",
  },
  {
    id: 720111,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ดอนโพธิ์ทอง",
    subdistrictEn: "Don Pho Thong",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ดอนโพธิ์ทอง สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี ดอนโพธิ์ทอง สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Don Pho Thong, Suphan Buri, 72000",
  },
  {
    id: 720112,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "บ้านโพธิ์",
    subdistrictEn: "Ban Pho",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี บ้านโพธิ์ สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี บ้านโพธิ์ สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Ban Pho, Suphan Buri, 72000",
  },
  {
    id: 720113,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72230,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี สระแก้ว สุพรรณบุรี 72230",
    name_th: "เมืองสุพรรณบุรี สระแก้ว สุพรรณบุรี 72230",
    name_en: "Mueang Suphan Buri, Sa Kaeo, Suphan Buri, 72230",
  },
  {
    id: 720114,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72230,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ตลิ่งชัน สุพรรณบุรี 72230",
    name_th: "เมืองสุพรรณบุรี ตลิ่งชัน สุพรรณบุรี 72230",
    name_en: "Mueang Suphan Buri, Taling Chan, Suphan Buri, 72230",
  },
  {
    id: 720115,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "บางกุ้ง",
    subdistrictEn: "Bang Kung",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72210,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี บางกุ้ง สุพรรณบุรี 72210",
    name_th: "เมืองสุพรรณบุรี บางกุ้ง สุพรรณบุรี 72210",
    name_en: "Mueang Suphan Buri, Bang Kung, Suphan Buri, 72210",
  },
  {
    id: 720116,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "ศาลาขาว",
    subdistrictEn: "Sala Khao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72210,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี ศาลาขาว สุพรรณบุรี 72210",
    name_th: "เมืองสุพรรณบุรี ศาลาขาว สุพรรณบุรี 72210",
    name_en: "Mueang Suphan Buri, Sala Khao, Suphan Buri, 72210",
  },
  {
    id: 720117,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "สวนแตง",
    subdistrictEn: "Suan Taeng",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72210,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี สวนแตง สุพรรณบุรี 72210",
    name_th: "เมืองสุพรรณบุรี สวนแตง สุพรรณบุรี 72210",
    name_en: "Mueang Suphan Buri, Suan Taeng, Suphan Buri, 72210",
  },
  {
    id: 720118,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "สนามชัย",
    subdistrictEn: "Sanam Chai",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี สนามชัย สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี สนามชัย สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Sanam Chai, Suphan Buri, 72000",
  },
  {
    id: 720119,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "โพธิ์พระยา",
    subdistrictEn: "Pho Phraya",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72000,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี โพธิ์พระยา สุพรรณบุรี 72000",
    name_th: "เมืองสุพรรณบุรี โพธิ์พระยา สุพรรณบุรี 72000",
    name_en: "Mueang Suphan Buri, Pho Phraya, Suphan Buri, 72000",
  },
  {
    id: 720120,
    districtTh: "เมืองสุพรรณบุรี",
    districtEn: "Mueang Suphan Buri",
    subdistrictTh: "สนามคลี",
    subdistrictEn: "Sanam Klee",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72230,
    district_code: 7201,
    province_code: 57,
    name: "เมืองสุพรรณบุรี สนามคลี สุพรรณบุรี 72230",
    name_th: "เมืองสุพรรณบุรี สนามคลี สุพรรณบุรี 72230",
    name_en: "Mueang Suphan Buri, Sanam Klee, Suphan Buri, 72230",
  },
  {
    id: 720201,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "เขาพระ",
    subdistrictEn: "Khao Phra",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช เขาพระ สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช เขาพระ สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Khao Phra, Suphan Buri, 72120",
  },
  {
    id: 720202,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "เดิมบาง",
    subdistrictEn: "Doem Bang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช เดิมบาง สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช เดิมบาง สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Doem Bang, Suphan Buri, 72120",
  },
  {
    id: 720203,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "นางบวช",
    subdistrictEn: "Nang Buat",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช นางบวช สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช นางบวช สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Nang Buat, Suphan Buri, 72120",
  },
  {
    id: 720204,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "เขาดิน",
    subdistrictEn: "Khao Din",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช เขาดิน สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช เขาดิน สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Khao Din, Suphan Buri, 72120",
  },
  {
    id: 720205,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช ปากน้ำ สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช ปากน้ำ สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Pak Nam, Suphan Buri, 72120",
  },
  {
    id: 720206,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "ทุ่งคลี",
    subdistrictEn: "Thung Khli",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช ทุ่งคลี สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช ทุ่งคลี สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Thung Khli, Suphan Buri, 72120",
  },
  {
    id: 720207,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "โคกช้าง",
    subdistrictEn: "Khok Chang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช โคกช้าง สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช โคกช้าง สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Khok Chang, Suphan Buri, 72120",
  },
  {
    id: 720208,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "หัวเขา",
    subdistrictEn: "Hua Khao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช หัวเขา สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช หัวเขา สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Hua Khao, Suphan Buri, 72120",
  },
  {
    id: 720209,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "หัวนา",
    subdistrictEn: "Hua Na",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช หัวนา สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช หัวนา สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Hua Na, Suphan Buri, 72120",
  },
  {
    id: 720210,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "บ่อกรุ",
    subdistrictEn: "Bo Kru",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช บ่อกรุ สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช บ่อกรุ สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Bo Kru, Suphan Buri, 72120",
  },
  {
    id: 720211,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "วังศรีราช",
    subdistrictEn: "Wang Si Rat",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช วังศรีราช สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช วังศรีราช สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Wang Si Rat, Suphan Buri, 72120",
  },
  {
    id: 720212,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "ป่าสะแก",
    subdistrictEn: "Pa Sakae",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช ป่าสะแก สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช ป่าสะแก สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Pa Sakae, Suphan Buri, 72120",
  },
  {
    id: 720213,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "ยางนอน",
    subdistrictEn: "Yang Non",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช ยางนอน สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช ยางนอน สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Yang Non, Suphan Buri, 72120",
  },
  {
    id: 720214,
    districtTh: "เดิมบางนางบวช",
    districtEn: "Doem Bang Nang Buat",
    subdistrictTh: "หนองกระทุ่ม",
    subdistrictEn: "Nong Krathum",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72120,
    district_code: 7202,
    province_code: 57,
    name: "เดิมบางนางบวช หนองกระทุ่ม สุพรรณบุรี 72120",
    name_th: "เดิมบางนางบวช หนองกระทุ่ม สุพรรณบุรี 72120",
    name_en: "Doem Bang Nang Buat, Nong Krathum, Suphan Buri, 72120",
  },
  {
    id: 720301,
    districtTh: "ด่านช้าง",
    districtEn: "Dan Chang",
    subdistrictTh: "หนองมะค่าโมง",
    subdistrictEn: "Nong Makha Mong",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72180,
    district_code: 7203,
    province_code: 57,
    name: "ด่านช้าง หนองมะค่าโมง สุพรรณบุรี 72180",
    name_th: "ด่านช้าง หนองมะค่าโมง สุพรรณบุรี 72180",
    name_en: "Dan Chang, Nong Makha Mong, Suphan Buri, 72180",
  },
  {
    id: 720302,
    districtTh: "ด่านช้าง",
    districtEn: "Dan Chang",
    subdistrictTh: "ด่านช้าง",
    subdistrictEn: "Dan Chang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72180,
    district_code: 7203,
    province_code: 57,
    name: "ด่านช้าง ด่านช้าง สุพรรณบุรี 72180",
    name_th: "ด่านช้าง ด่านช้าง สุพรรณบุรี 72180",
    name_en: "Dan Chang, Dan Chang, Suphan Buri, 72180",
  },
  {
    id: 720303,
    districtTh: "ด่านช้าง",
    districtEn: "Dan Chang",
    subdistrictTh: "ห้วยขมิ้น",
    subdistrictEn: "Huai Khamin",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72180,
    district_code: 7203,
    province_code: 57,
    name: "ด่านช้าง ห้วยขมิ้น สุพรรณบุรี 72180",
    name_th: "ด่านช้าง ห้วยขมิ้น สุพรรณบุรี 72180",
    name_en: "Dan Chang, Huai Khamin, Suphan Buri, 72180",
  },
  {
    id: 720304,
    districtTh: "ด่านช้าง",
    districtEn: "Dan Chang",
    subdistrictTh: "องค์พระ",
    subdistrictEn: "Ong Phra",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72180,
    district_code: 7203,
    province_code: 57,
    name: "ด่านช้าง องค์พระ สุพรรณบุรี 72180",
    name_th: "ด่านช้าง องค์พระ สุพรรณบุรี 72180",
    name_en: "Dan Chang, Ong Phra, Suphan Buri, 72180",
  },
  {
    id: 720305,
    districtTh: "ด่านช้าง",
    districtEn: "Dan Chang",
    subdistrictTh: "วังคัน",
    subdistrictEn: "Wang Khan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72180,
    district_code: 7203,
    province_code: 57,
    name: "ด่านช้าง วังคัน สุพรรณบุรี 72180",
    name_th: "ด่านช้าง วังคัน สุพรรณบุรี 72180",
    name_en: "Dan Chang, Wang Khan, Suphan Buri, 72180",
  },
  {
    id: 720306,
    districtTh: "ด่านช้าง",
    districtEn: "Dan Chang",
    subdistrictTh: "นิคมกระเสียว",
    subdistrictEn: "Nikhom Krasiao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72180,
    district_code: 7203,
    province_code: 57,
    name: "ด่านช้าง นิคมกระเสียว สุพรรณบุรี 72180",
    name_th: "ด่านช้าง นิคมกระเสียว สุพรรณบุรี 72180",
    name_en: "Dan Chang, Nikhom Krasiao, Suphan Buri, 72180",
  },
  {
    id: 720307,
    districtTh: "ด่านช้าง",
    districtEn: "Dan Chang",
    subdistrictTh: "วังยาว",
    subdistrictEn: "Wang Yao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72180,
    district_code: 7203,
    province_code: 57,
    name: "ด่านช้าง วังยาว สุพรรณบุรี 72180",
    name_th: "ด่านช้าง วังยาว สุพรรณบุรี 72180",
    name_en: "Dan Chang, Wang Yao, Suphan Buri, 72180",
  },
  {
    id: 720401,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "โคกคราม",
    subdistrictEn: "Khok Khram",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า โคกคราม สุพรรณบุรี 72150",
    name_th: "บางปลาม้า โคกคราม สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Khok Khram, Suphan Buri, 72150",
  },
  {
    id: 720402,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "บางปลาม้า",
    subdistrictEn: "Bang Pla Ma",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า บางปลาม้า สุพรรณบุรี 72150",
    name_th: "บางปลาม้า บางปลาม้า สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Bang Pla Ma, Suphan Buri, 72150",
  },
  {
    id: 720403,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "ตะค่า",
    subdistrictEn: "Takha",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า ตะค่า สุพรรณบุรี 72150",
    name_th: "บางปลาม้า ตะค่า สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Takha, Suphan Buri, 72150",
  },
  {
    id: 720404,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "บางใหญ่",
    subdistrictEn: "Bang Yai",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า บางใหญ่ สุพรรณบุรี 72150",
    name_th: "บางปลาม้า บางใหญ่ สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Bang Yai, Suphan Buri, 72150",
  },
  {
    id: 720405,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "กฤษณา",
    subdistrictEn: "Kritsana",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า กฤษณา สุพรรณบุรี 72150",
    name_th: "บางปลาม้า กฤษณา สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Kritsana, Suphan Buri, 72150",
  },
  {
    id: 720406,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "สาลี",
    subdistrictEn: "Sali",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า สาลี สุพรรณบุรี 72150",
    name_th: "บางปลาม้า สาลี สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Sali, Suphan Buri, 72150",
  },
  {
    id: 720407,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "ไผ่กองดิน",
    subdistrictEn: "Phai Kong Din",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า ไผ่กองดิน สุพรรณบุรี 72150",
    name_th: "บางปลาม้า ไผ่กองดิน สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Phai Kong Din, Suphan Buri, 72150",
  },
  {
    id: 720408,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "องครักษ์",
    subdistrictEn: "Ongkharak",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า องครักษ์ สุพรรณบุรี 72150",
    name_th: "บางปลาม้า องครักษ์ สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Ongkharak, Suphan Buri, 72150",
  },
  {
    id: 720409,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "จรเข้ใหญ่",
    subdistrictEn: "Chorakhe Yai",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า จรเข้ใหญ่ สุพรรณบุรี 72150",
    name_th: "บางปลาม้า จรเข้ใหญ่ สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Chorakhe Yai, Suphan Buri, 72150",
  },
  {
    id: 720410,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "บ้านแหลม",
    subdistrictEn: "Ban Laem",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า บ้านแหลม สุพรรณบุรี 72150",
    name_th: "บางปลาม้า บ้านแหลม สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Ban Laem, Suphan Buri, 72150",
  },
  {
    id: 720411,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "มะขามล้ม",
    subdistrictEn: "Makham Lom",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า มะขามล้ม สุพรรณบุรี 72150",
    name_th: "บางปลาม้า มะขามล้ม สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Makham Lom, Suphan Buri, 72150",
  },
  {
    id: 720412,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "วังน้ำเย็น",
    subdistrictEn: "Wang Nam Yen",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า วังน้ำเย็น สุพรรณบุรี 72150",
    name_th: "บางปลาม้า วังน้ำเย็น สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Wang Nam Yen, Suphan Buri, 72150",
  },
  {
    id: 720413,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "วัดโบสถ์",
    subdistrictEn: "Wat Bot",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า วัดโบสถ์ สุพรรณบุรี 72150",
    name_th: "บางปลาม้า วัดโบสถ์ สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Wat Bot, Suphan Buri, 72150",
  },
  {
    id: 720414,
    districtTh: "บางปลาม้า",
    districtEn: "Bang Pla Ma",
    subdistrictTh: "วัดดาว",
    subdistrictEn: "Wad Daw",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72150,
    district_code: 7204,
    province_code: 57,
    name: "บางปลาม้า วัดดาว สุพรรณบุรี 72150",
    name_th: "บางปลาม้า วัดดาว สุพรรณบุรี 72150",
    name_en: "Bang Pla Ma, Wad Daw, Suphan Buri, 72150",
  },
  {
    id: 720501,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "ศรีประจันต์",
    subdistrictEn: "Si Prachan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ ศรีประจันต์ สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ ศรีประจันต์ สุพรรณบุรี 72140",
    name_en: "Si Prachan, Si Prachan, Suphan Buri, 72140",
  },
  {
    id: 720502,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "บ้านกร่าง",
    subdistrictEn: "Ban Krang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ บ้านกร่าง สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ บ้านกร่าง สุพรรณบุรี 72140",
    name_en: "Si Prachan, Ban Krang, Suphan Buri, 72140",
  },
  {
    id: 720503,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "มดแดง",
    subdistrictEn: "Mot Daeng",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ มดแดง สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ มดแดง สุพรรณบุรี 72140",
    name_en: "Si Prachan, Mot Daeng, Suphan Buri, 72140",
  },
  {
    id: 720504,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "บางงาม",
    subdistrictEn: "Bang Ngam",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ บางงาม สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ บางงาม สุพรรณบุรี 72140",
    name_en: "Si Prachan, Bang Ngam, Suphan Buri, 72140",
  },
  {
    id: 720505,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "ดอนปรู",
    subdistrictEn: "Don Pru",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ ดอนปรู สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ ดอนปรู สุพรรณบุรี 72140",
    name_en: "Si Prachan, Don Pru, Suphan Buri, 72140",
  },
  {
    id: 720506,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "ปลายนา",
    subdistrictEn: "Plai Na",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ ปลายนา สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ ปลายนา สุพรรณบุรี 72140",
    name_en: "Si Prachan, Plai Na, Suphan Buri, 72140",
  },
  {
    id: 720507,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "วังหว้า",
    subdistrictEn: "Wang Wa",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ วังหว้า สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ วังหว้า สุพรรณบุรี 72140",
    name_en: "Si Prachan, Wang Wa, Suphan Buri, 72140",
  },
  {
    id: 720508,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "วังน้ำซับ",
    subdistrictEn: "Wang Nam Sap",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ วังน้ำซับ สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ วังน้ำซับ สุพรรณบุรี 72140",
    name_en: "Si Prachan, Wang Nam Sap, Suphan Buri, 72140",
  },
  {
    id: 720509,
    districtTh: "ศรีประจันต์",
    districtEn: "Si Prachan",
    subdistrictTh: "วังยาง",
    subdistrictEn: "Wang Yang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72140,
    district_code: 7205,
    province_code: 57,
    name: "ศรีประจันต์ วังยาง สุพรรณบุรี 72140",
    name_th: "ศรีประจันต์ วังยาง สุพรรณบุรี 72140",
    name_en: "Si Prachan, Wang Yang, Suphan Buri, 72140",
  },
  {
    id: 720601,
    districtTh: "ดอนเจดีย์",
    districtEn: "Don Chedi",
    subdistrictTh: "ดอนเจดีย์",
    subdistrictEn: "Don Chedi",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72170,
    district_code: 7206,
    province_code: 57,
    name: "ดอนเจดีย์ ดอนเจดีย์ สุพรรณบุรี 72170",
    name_th: "ดอนเจดีย์ ดอนเจดีย์ สุพรรณบุรี 72170",
    name_en: "Don Chedi, Don Chedi, Suphan Buri, 72170",
  },
  {
    id: 720602,
    districtTh: "ดอนเจดีย์",
    districtEn: "Don Chedi",
    subdistrictTh: "หนองสาหร่าย",
    subdistrictEn: "Nong Sarai",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72170,
    district_code: 7206,
    province_code: 57,
    name: "ดอนเจดีย์ หนองสาหร่าย สุพรรณบุรี 72170",
    name_th: "ดอนเจดีย์ หนองสาหร่าย สุพรรณบุรี 72170",
    name_en: "Don Chedi, Nong Sarai, Suphan Buri, 72170",
  },
  {
    id: 720603,
    districtTh: "ดอนเจดีย์",
    districtEn: "Don Chedi",
    subdistrictTh: "ไร่รถ",
    subdistrictEn: "Rai Rot",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72170,
    district_code: 7206,
    province_code: 57,
    name: "ดอนเจดีย์ ไร่รถ สุพรรณบุรี 72170",
    name_th: "ดอนเจดีย์ ไร่รถ สุพรรณบุรี 72170",
    name_en: "Don Chedi, Rai Rot, Suphan Buri, 72170",
  },
  {
    id: 720604,
    districtTh: "ดอนเจดีย์",
    districtEn: "Don Chedi",
    subdistrictTh: "สระกระโจม",
    subdistrictEn: "Sa Krachom",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72250,
    district_code: 7206,
    province_code: 57,
    name: "ดอนเจดีย์ สระกระโจม สุพรรณบุรี 72250",
    name_th: "ดอนเจดีย์ สระกระโจม สุพรรณบุรี 72250",
    name_en: "Don Chedi, Sa Krachom, Suphan Buri, 72250",
  },
  {
    id: 720605,
    districtTh: "ดอนเจดีย์",
    districtEn: "Don Chedi",
    subdistrictTh: "ทะเลบก",
    subdistrictEn: "Talae Bok",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72250,
    district_code: 7206,
    province_code: 57,
    name: "ดอนเจดีย์ ทะเลบก สุพรรณบุรี 72250",
    name_th: "ดอนเจดีย์ ทะเลบก สุพรรณบุรี 72250",
    name_en: "Don Chedi, Talae Bok, Suphan Buri, 72250",
  },
  {
    id: 720701,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "สองพี่น้อง",
    subdistrictEn: "Song Phi Nong",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง สองพี่น้อง สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง สองพี่น้อง สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Song Phi Nong, Suphan Buri, 72110",
  },
  {
    id: 720702,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "บางเลน",
    subdistrictEn: "Bang Len",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง บางเลน สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง บางเลน สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Bang Len, Suphan Buri, 72110",
  },
  {
    id: 720703,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "บางตาเถร",
    subdistrictEn: "Bang Ta Then",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง บางตาเถร สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง บางตาเถร สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Bang Ta Then, Suphan Buri, 72110",
  },
  {
    id: 720704,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "บางตะเคียน",
    subdistrictEn: "Bang Takhian",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง บางตะเคียน สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง บางตะเคียน สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Bang Takhian, Suphan Buri, 72110",
  },
  {
    id: 720705,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "บ้านกุ่ม",
    subdistrictEn: "Ban Kum",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง บ้านกุ่ม สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง บ้านกุ่ม สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Ban Kum, Suphan Buri, 72110",
  },
  {
    id: 720706,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "หัวโพธิ์",
    subdistrictEn: "Hua Pho",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง หัวโพธิ์ สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง หัวโพธิ์ สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Hua Pho, Suphan Buri, 72110",
  },
  {
    id: 720707,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "บางพลับ",
    subdistrictEn: "Bang Phlap",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง บางพลับ สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง บางพลับ สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Bang Phlap, Suphan Buri, 72110",
  },
  {
    id: 720708,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "เนินพระปรางค์",
    subdistrictEn: "Noen Phra Prang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง เนินพระปรางค์ สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง เนินพระปรางค์ สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Noen Phra Prang, Suphan Buri, 72110",
  },
  {
    id: 720709,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "บ้านช้าง",
    subdistrictEn: "Ban Chang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง บ้านช้าง สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง บ้านช้าง สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Ban Chang, Suphan Buri, 72110",
  },
  {
    id: 720710,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "ต้นตาล",
    subdistrictEn: "Ton Tan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง ต้นตาล สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง ต้นตาล สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Ton Tan, Suphan Buri, 72110",
  },
  {
    id: 720711,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "ศรีสำราญ",
    subdistrictEn: "Si Samran",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง ศรีสำราญ สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง ศรีสำราญ สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Si Samran, Suphan Buri, 72110",
  },
  {
    id: 720712,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "ทุ่งคอก",
    subdistrictEn: "Thung Khok",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72190,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง ทุ่งคอก สุพรรณบุรี 72190",
    name_th: "สองพี่น้อง ทุ่งคอก สุพรรณบุรี 72190",
    name_en: "Song Phi Nong, Thung Khok, Suphan Buri, 72190",
  },
  {
    id: 720713,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "หนองบ่อ",
    subdistrictEn: "Nong Bo",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง หนองบ่อ สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง หนองบ่อ สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Nong Bo, Suphan Buri, 72110",
  },
  {
    id: 720714,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "บ่อสุพรรณ",
    subdistrictEn: "Bo Suphan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72190,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง บ่อสุพรรณ สุพรรณบุรี 72190",
    name_th: "สองพี่น้อง บ่อสุพรรณ สุพรรณบุรี 72190",
    name_en: "Song Phi Nong, Bo Suphan, Suphan Buri, 72190",
  },
  {
    id: 720715,
    districtTh: "สองพี่น้อง",
    districtEn: "Song Phi Nong",
    subdistrictTh: "ดอนมะนาว",
    subdistrictEn: "Don Manao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72110,
    district_code: 7207,
    province_code: 57,
    name: "สองพี่น้อง ดอนมะนาว สุพรรณบุรี 72110",
    name_th: "สองพี่น้อง ดอนมะนาว สุพรรณบุรี 72110",
    name_en: "Song Phi Nong, Don Manao, Suphan Buri, 72110",
  },
  {
    id: 720801,
    districtTh: "สามชุก",
    districtEn: "Sam Chuk",
    subdistrictTh: "ย่านยาว",
    subdistrictEn: "Yan Yao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72130,
    district_code: 7208,
    province_code: 57,
    name: "สามชุก ย่านยาว สุพรรณบุรี 72130",
    name_th: "สามชุก ย่านยาว สุพรรณบุรี 72130",
    name_en: "Sam Chuk, Yan Yao, Suphan Buri, 72130",
  },
  {
    id: 720802,
    districtTh: "สามชุก",
    districtEn: "Sam Chuk",
    subdistrictTh: "วังลึก",
    subdistrictEn: "Wang Luek",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72130,
    district_code: 7208,
    province_code: 57,
    name: "สามชุก วังลึก สุพรรณบุรี 72130",
    name_th: "สามชุก วังลึก สุพรรณบุรี 72130",
    name_en: "Sam Chuk, Wang Luek, Suphan Buri, 72130",
  },
  {
    id: 720803,
    districtTh: "สามชุก",
    districtEn: "Sam Chuk",
    subdistrictTh: "สามชุก",
    subdistrictEn: "Sam Chuk",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72130,
    district_code: 7208,
    province_code: 57,
    name: "สามชุก สามชุก สุพรรณบุรี 72130",
    name_th: "สามชุก สามชุก สุพรรณบุรี 72130",
    name_en: "Sam Chuk, Sam Chuk, Suphan Buri, 72130",
  },
  {
    id: 720804,
    districtTh: "สามชุก",
    districtEn: "Sam Chuk",
    subdistrictTh: "หนองผักนาก",
    subdistrictEn: "Nong Phak Nak",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72130,
    district_code: 7208,
    province_code: 57,
    name: "สามชุก หนองผักนาก สุพรรณบุรี 72130",
    name_th: "สามชุก หนองผักนาก สุพรรณบุรี 72130",
    name_en: "Sam Chuk, Nong Phak Nak, Suphan Buri, 72130",
  },
  {
    id: 720805,
    districtTh: "สามชุก",
    districtEn: "Sam Chuk",
    subdistrictTh: "บ้านสระ",
    subdistrictEn: "Ban Sa",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72130,
    district_code: 7208,
    province_code: 57,
    name: "สามชุก บ้านสระ สุพรรณบุรี 72130",
    name_th: "สามชุก บ้านสระ สุพรรณบุรี 72130",
    name_en: "Sam Chuk, Ban Sa, Suphan Buri, 72130",
  },
  {
    id: 720806,
    districtTh: "สามชุก",
    districtEn: "Sam Chuk",
    subdistrictTh: "หนองสะเดา",
    subdistrictEn: "Nong Sadao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72130,
    district_code: 7208,
    province_code: 57,
    name: "สามชุก หนองสะเดา สุพรรณบุรี 72130",
    name_th: "สามชุก หนองสะเดา สุพรรณบุรี 72130",
    name_en: "Sam Chuk, Nong Sadao, Suphan Buri, 72130",
  },
  {
    id: 720807,
    districtTh: "สามชุก",
    districtEn: "Sam Chuk",
    subdistrictTh: "กระเสียว",
    subdistrictEn: "Krasiao",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72130,
    district_code: 7208,
    province_code: 57,
    name: "สามชุก กระเสียว สุพรรณบุรี 72130",
    name_th: "สามชุก กระเสียว สุพรรณบุรี 72130",
    name_en: "Sam Chuk, Krasiao, Suphan Buri, 72130",
  },
  {
    id: 720901,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "อู่ทอง",
    subdistrictEn: "U Thong",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง อู่ทอง สุพรรณบุรี 72160",
    name_th: "อู่ทอง อู่ทอง สุพรรณบุรี 72160",
    name_en: "U Thong, U Thong, Suphan Buri, 72160",
  },
  {
    id: 720902,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "สระยายโสม",
    subdistrictEn: "Sa Yai Som",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72220,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง สระยายโสม สุพรรณบุรี 72220",
    name_th: "อู่ทอง สระยายโสม สุพรรณบุรี 72220",
    name_en: "U Thong, Sa Yai Som, Suphan Buri, 72220",
  },
  {
    id: 720903,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "จรเข้สามพัน",
    subdistrictEn: "Chorakhe Sam Phan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง จรเข้สามพัน สุพรรณบุรี 72160",
    name_th: "อู่ทอง จรเข้สามพัน สุพรรณบุรี 72160",
    name_en: "U Thong, Chorakhe Sam Phan, Suphan Buri, 72160",
  },
  {
    id: 720904,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "บ้านดอน",
    subdistrictEn: "Ban Don",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง บ้านดอน สุพรรณบุรี 72160",
    name_th: "อู่ทอง บ้านดอน สุพรรณบุรี 72160",
    name_en: "U Thong, Ban Don, Suphan Buri, 72160",
  },
  {
    id: 720905,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "ยุ้งทะลาย",
    subdistrictEn: "Yung Thalai",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง ยุ้งทะลาย สุพรรณบุรี 72160",
    name_th: "อู่ทอง ยุ้งทะลาย สุพรรณบุรี 72160",
    name_en: "U Thong, Yung Thalai, Suphan Buri, 72160",
  },
  {
    id: 720906,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "ดอนมะเกลือ",
    subdistrictEn: "Don Makluea",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72220,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง ดอนมะเกลือ สุพรรณบุรี 72220",
    name_th: "อู่ทอง ดอนมะเกลือ สุพรรณบุรี 72220",
    name_en: "U Thong, Don Makluea, Suphan Buri, 72220",
  },
  {
    id: 720907,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "หนองโอ่ง",
    subdistrictEn: "Nong Ong",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง หนองโอ่ง สุพรรณบุรี 72160",
    name_th: "อู่ทอง หนองโอ่ง สุพรรณบุรี 72160",
    name_en: "U Thong, Nong Ong, Suphan Buri, 72160",
  },
  {
    id: 720908,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "ดอนคา",
    subdistrictEn: "Don Kha",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง ดอนคา สุพรรณบุรี 72160",
    name_th: "อู่ทอง ดอนคา สุพรรณบุรี 72160",
    name_en: "U Thong, Don Kha, Suphan Buri, 72160",
  },
  {
    id: 720909,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "พลับพลาไชย",
    subdistrictEn: "Phlapphla Chai",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง พลับพลาไชย สุพรรณบุรี 72160",
    name_th: "อู่ทอง พลับพลาไชย สุพรรณบุรี 72160",
    name_en: "U Thong, Phlapphla Chai, Suphan Buri, 72160",
  },
  {
    id: 720910,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "บ้านโข้ง",
    subdistrictEn: "Ban Khong",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง บ้านโข้ง สุพรรณบุรี 72160",
    name_th: "อู่ทอง บ้านโข้ง สุพรรณบุรี 72160",
    name_en: "U Thong, Ban Khong, Suphan Buri, 72160",
  },
  {
    id: 720911,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "เจดีย์",
    subdistrictEn: "Chedi",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง เจดีย์ สุพรรณบุรี 72160",
    name_th: "อู่ทอง เจดีย์ สุพรรณบุรี 72160",
    name_en: "U Thong, Chedi, Suphan Buri, 72160",
  },
  {
    id: 720912,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "สระพังลาน",
    subdistrictEn: "Sa Phang Lan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72220,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง สระพังลาน สุพรรณบุรี 72220",
    name_th: "อู่ทอง สระพังลาน สุพรรณบุรี 72220",
    name_en: "U Thong, Sa Phang Lan, Suphan Buri, 72220",
  },
  {
    id: 720913,
    districtTh: "อู่ทอง",
    districtEn: "U Thong",
    subdistrictTh: "กระจัน",
    subdistrictEn: "Krachan",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72160,
    district_code: 7209,
    province_code: 57,
    name: "อู่ทอง กระจัน สุพรรณบุรี 72160",
    name_th: "อู่ทอง กระจัน สุพรรณบุรี 72160",
    name_en: "U Thong, Krachan, Suphan Buri, 72160",
  },
  {
    id: 721001,
    districtTh: "หนองหญ้าไซ",
    districtEn: "Nong Ya Sai",
    subdistrictTh: "หนองหญ้าไซ",
    subdistrictEn: "Nong Ya Sai",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72240,
    district_code: 7210,
    province_code: 57,
    name: "หนองหญ้าไซ หนองหญ้าไซ สุพรรณบุรี 72240",
    name_th: "หนองหญ้าไซ หนองหญ้าไซ สุพรรณบุรี 72240",
    name_en: "Nong Ya Sai, Nong Ya Sai, Suphan Buri, 72240",
  },
  {
    id: 721002,
    districtTh: "หนองหญ้าไซ",
    districtEn: "Nong Ya Sai",
    subdistrictTh: "หนองราชวัตร",
    subdistrictEn: "Nong Ratchawat",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72240,
    district_code: 7210,
    province_code: 57,
    name: "หนองหญ้าไซ หนองราชวัตร สุพรรณบุรี 72240",
    name_th: "หนองหญ้าไซ หนองราชวัตร สุพรรณบุรี 72240",
    name_en: "Nong Ya Sai, Nong Ratchawat, Suphan Buri, 72240",
  },
  {
    id: 721003,
    districtTh: "หนองหญ้าไซ",
    districtEn: "Nong Ya Sai",
    subdistrictTh: "หนองโพธิ์",
    subdistrictEn: "Nong Pho",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72240,
    district_code: 7210,
    province_code: 57,
    name: "หนองหญ้าไซ หนองโพธิ์ สุพรรณบุรี 72240",
    name_th: "หนองหญ้าไซ หนองโพธิ์ สุพรรณบุรี 72240",
    name_en: "Nong Ya Sai, Nong Pho, Suphan Buri, 72240",
  },
  {
    id: 721004,
    districtTh: "หนองหญ้าไซ",
    districtEn: "Nong Ya Sai",
    subdistrictTh: "แจงงาม",
    subdistrictEn: "Chaeng Ngam",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72240,
    district_code: 7210,
    province_code: 57,
    name: "หนองหญ้าไซ แจงงาม สุพรรณบุรี 72240",
    name_th: "หนองหญ้าไซ แจงงาม สุพรรณบุรี 72240",
    name_en: "Nong Ya Sai, Chaeng Ngam, Suphan Buri, 72240",
  },
  {
    id: 721005,
    districtTh: "หนองหญ้าไซ",
    districtEn: "Nong Ya Sai",
    subdistrictTh: "หนองขาม",
    subdistrictEn: "Nong Kham",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72240,
    district_code: 7210,
    province_code: 57,
    name: "หนองหญ้าไซ หนองขาม สุพรรณบุรี 72240",
    name_th: "หนองหญ้าไซ หนองขาม สุพรรณบุรี 72240",
    name_en: "Nong Ya Sai, Nong Kham, Suphan Buri, 72240",
  },
  {
    id: 721006,
    districtTh: "หนองหญ้าไซ",
    districtEn: "Nong Ya Sai",
    subdistrictTh: "ทัพหลวง",
    subdistrictEn: "Thap Luang",
    provinceTh: "สุพรรณบุรี",
    provinceEn: "Suphan Buri",
    zipcode: 72240,
    district_code: 7210,
    province_code: 57,
    name: "หนองหญ้าไซ ทัพหลวง สุพรรณบุรี 72240",
    name_th: "หนองหญ้าไซ ทัพหลวง สุพรรณบุรี 72240",
    name_en: "Nong Ya Sai, Thap Luang, Suphan Buri, 72240",
  },
  {
    id: 730101,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "พระปฐมเจดีย์",
    subdistrictEn: "Phra Pathom Chedi",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม พระปฐมเจดีย์ นครปฐม 73000",
    name_th: "เมืองนครปฐม พระปฐมเจดีย์ นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Phra Pathom Chedi, Nakhon Pathom, 73000",
  },
  {
    id: 730102,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "บางแขม",
    subdistrictEn: "Bang Khaem",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม บางแขม นครปฐม 73000",
    name_th: "เมืองนครปฐม บางแขม นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Bang Khaem, Nakhon Pathom, 73000",
  },
  {
    id: 730103,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "พระประโทน",
    subdistrictEn: "Phra Prathon",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม พระประโทน นครปฐม 73000",
    name_th: "เมืองนครปฐม พระประโทน นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Phra Prathon, Nakhon Pathom, 73000",
  },
  {
    id: 730104,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "ธรรมศาลา",
    subdistrictEn: "Thammasala",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม ธรรมศาลา นครปฐม 73000",
    name_th: "เมืองนครปฐม ธรรมศาลา นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Thammasala, Nakhon Pathom, 73000",
  },
  {
    id: 730105,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "ตาก้อง",
    subdistrictEn: "Ta Kong",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม ตาก้อง นครปฐม 73000",
    name_th: "เมืองนครปฐม ตาก้อง นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Ta Kong, Nakhon Pathom, 73000",
  },
  {
    id: 730106,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "มาบแค",
    subdistrictEn: "Map Khae",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม มาบแค นครปฐม 73000",
    name_th: "เมืองนครปฐม มาบแค นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Map Khae, Nakhon Pathom, 73000",
  },
  {
    id: 730107,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "สนามจันทร์",
    subdistrictEn: "Sanam Chan",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม สนามจันทร์ นครปฐม 73000",
    name_th: "เมืองนครปฐม สนามจันทร์ นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Sanam Chan, Nakhon Pathom, 73000",
  },
  {
    id: 730108,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "ดอนยายหอม",
    subdistrictEn: "Don Yai Hom",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม ดอนยายหอม นครปฐม 73000",
    name_th: "เมืองนครปฐม ดอนยายหอม นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Don Yai Hom, Nakhon Pathom, 73000",
  },
  {
    id: 730109,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "ถนนขาด",
    subdistrictEn: "Thanon Khat",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม ถนนขาด นครปฐม 73000",
    name_th: "เมืองนครปฐม ถนนขาด นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Thanon Khat, Nakhon Pathom, 73000",
  },
  {
    id: 730110,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "บ่อพลับ",
    subdistrictEn: "Bo Phlap",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม บ่อพลับ นครปฐม 73000",
    name_th: "เมืองนครปฐม บ่อพลับ นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Bo Phlap, Nakhon Pathom, 73000",
  },
  {
    id: 730111,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "นครปฐม",
    subdistrictEn: "Nakhon Pathom",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม นครปฐม นครปฐม 73000",
    name_th: "เมืองนครปฐม นครปฐม นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Nakhon Pathom, Nakhon Pathom, 73000",
  },
  {
    id: 730112,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "วังตะกู",
    subdistrictEn: "Wang Taku",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม วังตะกู นครปฐม 73000",
    name_th: "เมืองนครปฐม วังตะกู นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Wang Taku, Nakhon Pathom, 73000",
  },
  {
    id: 730113,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "หนองปากโลง",
    subdistrictEn: "Nong Pak Long",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม หนองปากโลง นครปฐม 73000",
    name_th: "เมืองนครปฐม หนองปากโลง นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Nong Pak Long, Nakhon Pathom, 73000",
  },
  {
    id: 730114,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "สามควายเผือก",
    subdistrictEn: "Sam Khwai Phueak",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม สามควายเผือก นครปฐม 73000",
    name_th: "เมืองนครปฐม สามควายเผือก นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Sam Khwai Phueak, Nakhon Pathom, 73000",
  },
  {
    id: 730115,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "ทุ่งน้อย",
    subdistrictEn: "Thung Noi",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม ทุ่งน้อย นครปฐม 73000",
    name_th: "เมืองนครปฐม ทุ่งน้อย นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Thung Noi, Nakhon Pathom, 73000",
  },
  {
    id: 730116,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "หนองดินแดง",
    subdistrictEn: "Nong Din Daeng",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม หนองดินแดง นครปฐม 73000",
    name_th: "เมืองนครปฐม หนองดินแดง นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Nong Din Daeng, Nakhon Pathom, 73000",
  },
  {
    id: 730117,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "วังเย็น",
    subdistrictEn: "Wang Yen",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม วังเย็น นครปฐม 73000",
    name_th: "เมืองนครปฐม วังเย็น นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Wang Yen, Nakhon Pathom, 73000",
  },
  {
    id: 730118,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "โพรงมะเดื่อ",
    subdistrictEn: "Phrong Maduea",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม โพรงมะเดื่อ นครปฐม 73000",
    name_th: "เมืองนครปฐม โพรงมะเดื่อ นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Phrong Maduea, Nakhon Pathom, 73000",
  },
  {
    id: 730119,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "ลำพยา",
    subdistrictEn: "Lam Phaya",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม ลำพยา นครปฐม 73000",
    name_th: "เมืองนครปฐม ลำพยา นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Lam Phaya, Nakhon Pathom, 73000",
  },
  {
    id: 730120,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "สระกะเทียม",
    subdistrictEn: "Sa Kathiam",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม สระกะเทียม นครปฐม 73000",
    name_th: "เมืองนครปฐม สระกะเทียม นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Sa Kathiam, Nakhon Pathom, 73000",
  },
  {
    id: 730121,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "สวนป่าน",
    subdistrictEn: "Suan Pan",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม สวนป่าน นครปฐม 73000",
    name_th: "เมืองนครปฐม สวนป่าน นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Suan Pan, Nakhon Pathom, 73000",
  },
  {
    id: 730122,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "ห้วยจรเข้",
    subdistrictEn: "Huai Chorakhe",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม ห้วยจรเข้ นครปฐม 73000",
    name_th: "เมืองนครปฐม ห้วยจรเข้ นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Huai Chorakhe, Nakhon Pathom, 73000",
  },
  {
    id: 730123,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "ทัพหลวง",
    subdistrictEn: "Thap Luang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม ทัพหลวง นครปฐม 73000",
    name_th: "เมืองนครปฐม ทัพหลวง นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Thap Luang, Nakhon Pathom, 73000",
  },
  {
    id: 730124,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "หนองงูเหลือม",
    subdistrictEn: "Nong Ngulueam",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม หนองงูเหลือม นครปฐม 73000",
    name_th: "เมืองนครปฐม หนองงูเหลือม นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Nong Ngulueam, Nakhon Pathom, 73000",
  },
  {
    id: 730125,
    districtTh: "เมืองนครปฐม",
    districtEn: "Mueang Nakhon Pathom",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73000,
    district_code: 7301,
    province_code: 58,
    name: "เมืองนครปฐม บ้านยาง นครปฐม 73000",
    name_th: "เมืองนครปฐม บ้านยาง นครปฐม 73000",
    name_en: "Mueang Nakhon Pathom, Ban Yang, Nakhon Pathom, 73000",
  },
  {
    id: 730201,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "ทุ่งกระพังโหม",
    subdistrictEn: "Thung Kraphanghom",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน ทุ่งกระพังโหม นครปฐม 73140",
    name_th: "กำแพงแสน ทุ่งกระพังโหม นครปฐม 73140",
    name_en: "Kamphaeng Saen, Thung Kraphanghom, Nakhon Pathom, 73140",
  },
  {
    id: 730202,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "กระตีบ",
    subdistrictEn: "Kratip",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73180,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน กระตีบ นครปฐม 73180",
    name_th: "กำแพงแสน กระตีบ นครปฐม 73180",
    name_en: "Kamphaeng Saen, Kratip, Nakhon Pathom, 73180",
  },
  {
    id: 730203,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "ทุ่งลูกนก",
    subdistrictEn: "Thung Luk Nok",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน ทุ่งลูกนก นครปฐม 73140",
    name_th: "กำแพงแสน ทุ่งลูกนก นครปฐม 73140",
    name_en: "Kamphaeng Saen, Thung Luk Nok, Nakhon Pathom, 73140",
  },
  {
    id: 730204,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "ห้วยขวาง",
    subdistrictEn: "Huai Khwang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน ห้วยขวาง นครปฐม 73140",
    name_th: "กำแพงแสน ห้วยขวาง นครปฐม 73140",
    name_en: "Kamphaeng Saen, Huai Khwang, Nakhon Pathom, 73140",
  },
  {
    id: 730205,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "ทุ่งขวาง",
    subdistrictEn: "Thung Khwang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน ทุ่งขวาง นครปฐม 73140",
    name_th: "กำแพงแสน ทุ่งขวาง นครปฐม 73140",
    name_en: "Kamphaeng Saen, Thung Khwang, Nakhon Pathom, 73140",
  },
  {
    id: 730206,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "สระสี่มุม",
    subdistrictEn: "Sa Si Mum",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน สระสี่มุม นครปฐม 73140",
    name_th: "กำแพงแสน สระสี่มุม นครปฐม 73140",
    name_en: "Kamphaeng Saen, Sa Si Mum, Nakhon Pathom, 73140",
  },
  {
    id: 730207,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "ทุ่งบัว",
    subdistrictEn: "Thung Bua",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน ทุ่งบัว นครปฐม 73140",
    name_th: "กำแพงแสน ทุ่งบัว นครปฐม 73140",
    name_en: "Kamphaeng Saen, Thung Bua, Nakhon Pathom, 73140",
  },
  {
    id: 730208,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "ดอนข่อย",
    subdistrictEn: "Don Khoi",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน ดอนข่อย นครปฐม 73140",
    name_th: "กำแพงแสน ดอนข่อย นครปฐม 73140",
    name_en: "Kamphaeng Saen, Don Khoi, Nakhon Pathom, 73140",
  },
  {
    id: 730209,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "สระพัฒนา",
    subdistrictEn: "Sa Phatthana",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73180,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน สระพัฒนา นครปฐม 73180",
    name_th: "กำแพงแสน สระพัฒนา นครปฐม 73180",
    name_en: "Kamphaeng Saen, Sa Phatthana, Nakhon Pathom, 73180",
  },
  {
    id: 730210,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "ห้วยหมอนทอง",
    subdistrictEn: "Huai Mon Thong",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน ห้วยหมอนทอง นครปฐม 73140",
    name_th: "กำแพงแสน ห้วยหมอนทอง นครปฐม 73140",
    name_en: "Kamphaeng Saen, Huai Mon Thong, Nakhon Pathom, 73140",
  },
  {
    id: 730211,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "ห้วยม่วง",
    subdistrictEn: "Huai Muang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73180,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน ห้วยม่วง นครปฐม 73180",
    name_th: "กำแพงแสน ห้วยม่วง นครปฐม 73180",
    name_en: "Kamphaeng Saen, Huai Muang, Nakhon Pathom, 73180",
  },
  {
    id: 730212,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "กำแพงแสน",
    subdistrictEn: "Kamphaeng Saen",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน กำแพงแสน นครปฐม 73140",
    name_th: "กำแพงแสน กำแพงแสน นครปฐม 73140",
    name_en: "Kamphaeng Saen, Kamphaeng Saen, Nakhon Pathom, 73140",
  },
  {
    id: 730213,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "รางพิกุล",
    subdistrictEn: "Rang Phikun",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน รางพิกุล นครปฐม 73140",
    name_th: "กำแพงแสน รางพิกุล นครปฐม 73140",
    name_en: "Kamphaeng Saen, Rang Phikun, Nakhon Pathom, 73140",
  },
  {
    id: 730214,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "หนองกระทุ่ม",
    subdistrictEn: "Nong Krathum",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน หนองกระทุ่ม นครปฐม 73140",
    name_th: "กำแพงแสน หนองกระทุ่ม นครปฐม 73140",
    name_en: "Kamphaeng Saen, Nong Krathum, Nakhon Pathom, 73140",
  },
  {
    id: 730215,
    districtTh: "กำแพงแสน",
    districtEn: "Kamphaeng Saen",
    subdistrictTh: "วังน้ำเขียว",
    subdistrictEn: "Wang Nam Khiao",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73140,
    district_code: 7302,
    province_code: 58,
    name: "กำแพงแสน วังน้ำเขียว นครปฐม 73140",
    name_th: "กำแพงแสน วังน้ำเขียว นครปฐม 73140",
    name_en: "Kamphaeng Saen, Wang Nam Khiao, Nakhon Pathom, 73140",
  },
  {
    id: 730301,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "นครชัยศรี",
    subdistrictEn: "Nakhon Chai Si",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี นครชัยศรี นครปฐม 73120",
    name_th: "นครชัยศรี นครชัยศรี นครปฐม 73120",
    name_en: "Nakhon Chai Si, Nakhon Chai Si, Nakhon Pathom, 73120",
  },
  {
    id: 730302,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "บางกระเบา",
    subdistrictEn: "Bang Krabao",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี บางกระเบา นครปฐม 73120",
    name_th: "นครชัยศรี บางกระเบา นครปฐม 73120",
    name_en: "Nakhon Chai Si, Bang Krabao, Nakhon Pathom, 73120",
  },
  {
    id: 730303,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "วัดแค",
    subdistrictEn: "Wat Khae",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี วัดแค นครปฐม 73120",
    name_th: "นครชัยศรี วัดแค นครปฐม 73120",
    name_en: "Nakhon Chai Si, Wat Khae, Nakhon Pathom, 73120",
  },
  {
    id: 730304,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ท่าตำหนัก",
    subdistrictEn: "Tha Tamnak",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ท่าตำหนัก นครปฐม 73120",
    name_th: "นครชัยศรี ท่าตำหนัก นครปฐม 73120",
    name_en: "Nakhon Chai Si, Tha Tamnak, Nakhon Pathom, 73120",
  },
  {
    id: 730305,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "บางแก้ว",
    subdistrictEn: "Bang Kaeo",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี บางแก้ว นครปฐม 73120",
    name_th: "นครชัยศรี บางแก้ว นครปฐม 73120",
    name_en: "Nakhon Chai Si, Bang Kaeo, Nakhon Pathom, 73120",
  },
  {
    id: 730306,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ท่ากระชับ",
    subdistrictEn: "Tha Krachap",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ท่ากระชับ นครปฐม 73120",
    name_th: "นครชัยศรี ท่ากระชับ นครปฐม 73120",
    name_en: "Nakhon Chai Si, Tha Krachap, Nakhon Pathom, 73120",
  },
  {
    id: 730307,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ขุนแก้ว",
    subdistrictEn: "Khun Kaeo",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ขุนแก้ว นครปฐม 73120",
    name_th: "นครชัยศรี ขุนแก้ว นครปฐม 73120",
    name_en: "Nakhon Chai Si, Khun Kaeo, Nakhon Pathom, 73120",
  },
  {
    id: 730308,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ท่าพระยา",
    subdistrictEn: "Tha Phraya",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ท่าพระยา นครปฐม 73120",
    name_th: "นครชัยศรี ท่าพระยา นครปฐม 73120",
    name_en: "Nakhon Chai Si, Tha Phraya, Nakhon Pathom, 73120",
  },
  {
    id: 730309,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "พะเนียด",
    subdistrictEn: "Phaniat",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี พะเนียด นครปฐม 73120",
    name_th: "นครชัยศรี พะเนียด นครปฐม 73120",
    name_en: "Nakhon Chai Si, Phaniat, Nakhon Pathom, 73120",
  },
  {
    id: 730310,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "บางระกำ",
    subdistrictEn: "Bang Rakam",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี บางระกำ นครปฐม 73120",
    name_th: "นครชัยศรี บางระกำ นครปฐม 73120",
    name_en: "Nakhon Chai Si, Bang Rakam, Nakhon Pathom, 73120",
  },
  {
    id: 730311,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "โคกพระเจดีย์",
    subdistrictEn: "Khok Phra Chedi",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี โคกพระเจดีย์ นครปฐม 73120",
    name_th: "นครชัยศรี โคกพระเจดีย์ นครปฐม 73120",
    name_en: "Nakhon Chai Si, Khok Phra Chedi, Nakhon Pathom, 73120",
  },
  {
    id: 730312,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ศรีษะทอง",
    subdistrictEn: "Sisa Thong",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ศรีษะทอง นครปฐม 73120",
    name_th: "นครชัยศรี ศรีษะทอง นครปฐม 73120",
    name_en: "Nakhon Chai Si, Sisa Thong, Nakhon Pathom, 73120",
  },
  {
    id: 730313,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "แหลมบัว",
    subdistrictEn: "Laem Bua",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี แหลมบัว นครปฐม 73120",
    name_th: "นครชัยศรี แหลมบัว นครปฐม 73120",
    name_en: "Nakhon Chai Si, Laem Bua, Nakhon Pathom, 73120",
  },
  {
    id: 730314,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ศรีมหาโพธิ์",
    subdistrictEn: "Si Maha Pho",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ศรีมหาโพธิ์ นครปฐม 73120",
    name_th: "นครชัยศรี ศรีมหาโพธิ์ นครปฐม 73120",
    name_en: "Nakhon Chai Si, Si Maha Pho, Nakhon Pathom, 73120",
  },
  {
    id: 730315,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "สัมปทวน",
    subdistrictEn: "Sampathuan",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี สัมปทวน นครปฐม 73120",
    name_th: "นครชัยศรี สัมปทวน นครปฐม 73120",
    name_en: "Nakhon Chai Si, Sampathuan, Nakhon Pathom, 73120",
  },
  {
    id: 730316,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "วัดสำโรง",
    subdistrictEn: "Wat Samrong",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี วัดสำโรง นครปฐม 73120",
    name_th: "นครชัยศรี วัดสำโรง นครปฐม 73120",
    name_en: "Nakhon Chai Si, Wat Samrong, Nakhon Pathom, 73120",
  },
  {
    id: 730317,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ดอนแฝก",
    subdistrictEn: "Don Faek",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ดอนแฝก นครปฐม 73120",
    name_th: "นครชัยศรี ดอนแฝก นครปฐม 73120",
    name_en: "Nakhon Chai Si, Don Faek, Nakhon Pathom, 73120",
  },
  {
    id: 730318,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ห้วยพลู",
    subdistrictEn: "Huai Phlu",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ห้วยพลู นครปฐม 73120",
    name_th: "นครชัยศรี ห้วยพลู นครปฐม 73120",
    name_en: "Nakhon Chai Si, Huai Phlu, Nakhon Pathom, 73120",
  },
  {
    id: 730319,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "วัดละมุด",
    subdistrictEn: "Wat Lamut",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี วัดละมุด นครปฐม 73120",
    name_th: "นครชัยศรี วัดละมุด นครปฐม 73120",
    name_en: "Nakhon Chai Si, Wat Lamut, Nakhon Pathom, 73120",
  },
  {
    id: 730320,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "บางพระ",
    subdistrictEn: "Bang Phra",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี บางพระ นครปฐม 73120",
    name_th: "นครชัยศรี บางพระ นครปฐม 73120",
    name_en: "Nakhon Chai Si, Bang Phra, Nakhon Pathom, 73120",
  },
  {
    id: 730321,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "บางแก้วฟ้า",
    subdistrictEn: "Bang Kaeo Fa",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี บางแก้วฟ้า นครปฐม 73120",
    name_th: "นครชัยศรี บางแก้วฟ้า นครปฐม 73120",
    name_en: "Nakhon Chai Si, Bang Kaeo Fa, Nakhon Pathom, 73120",
  },
  {
    id: 730322,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ลานตากฟ้า",
    subdistrictEn: "Lan Tak Fa",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ลานตากฟ้า นครปฐม 73120",
    name_th: "นครชัยศรี ลานตากฟ้า นครปฐม 73120",
    name_en: "Nakhon Chai Si, Lan Tak Fa, Nakhon Pathom, 73120",
  },
  {
    id: 730323,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "งิ้วราย",
    subdistrictEn: "Ngio Rai",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี งิ้วราย นครปฐม 73120",
    name_th: "นครชัยศรี งิ้วราย นครปฐม 73120",
    name_en: "Nakhon Chai Si, Ngio Rai, Nakhon Pathom, 73120",
  },
  {
    id: 730324,
    districtTh: "นครชัยศรี",
    districtEn: "Nakhon Chai Si",
    subdistrictTh: "ไทยาวาส",
    subdistrictEn: "Thaiyawat",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73120,
    district_code: 7303,
    province_code: 58,
    name: "นครชัยศรี ไทยาวาส นครปฐม 73120",
    name_th: "นครชัยศรี ไทยาวาส นครปฐม 73120",
    name_en: "Nakhon Chai Si, Thaiyawat, Nakhon Pathom, 73120",
  },
  {
    id: 730401,
    districtTh: "ดอนตูม",
    districtEn: "Don Tum",
    subdistrictTh: "สามง่าม",
    subdistrictEn: "Sam Ngam",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73150,
    district_code: 7304,
    province_code: 58,
    name: "ดอนตูม สามง่าม นครปฐม 73150",
    name_th: "ดอนตูม สามง่าม นครปฐม 73150",
    name_en: "Don Tum, Sam Ngam, Nakhon Pathom, 73150",
  },
  {
    id: 730402,
    districtTh: "ดอนตูม",
    districtEn: "Don Tum",
    subdistrictTh: "ห้วยพระ",
    subdistrictEn: "Huai Phra",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73150,
    district_code: 7304,
    province_code: 58,
    name: "ดอนตูม ห้วยพระ นครปฐม 73150",
    name_th: "ดอนตูม ห้วยพระ นครปฐม 73150",
    name_en: "Don Tum, Huai Phra, Nakhon Pathom, 73150",
  },
  {
    id: 730403,
    districtTh: "ดอนตูม",
    districtEn: "Don Tum",
    subdistrictTh: "ลำเหย",
    subdistrictEn: "Lam Hoei",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73150,
    district_code: 7304,
    province_code: 58,
    name: "ดอนตูม ลำเหย นครปฐม 73150",
    name_th: "ดอนตูม ลำเหย นครปฐม 73150",
    name_en: "Don Tum, Lam Hoei, Nakhon Pathom, 73150",
  },
  {
    id: 730404,
    districtTh: "ดอนตูม",
    districtEn: "Don Tum",
    subdistrictTh: "ดอนพุทรา",
    subdistrictEn: "Don Phutsa",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73150,
    district_code: 7304,
    province_code: 58,
    name: "ดอนตูม ดอนพุทรา นครปฐม 73150",
    name_th: "ดอนตูม ดอนพุทรา นครปฐม 73150",
    name_en: "Don Tum, Don Phutsa, Nakhon Pathom, 73150",
  },
  {
    id: 730405,
    districtTh: "ดอนตูม",
    districtEn: "Don Tum",
    subdistrictTh: "บ้านหลวง",
    subdistrictEn: "Ban Luang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73150,
    district_code: 7304,
    province_code: 58,
    name: "ดอนตูม บ้านหลวง นครปฐม 73150",
    name_th: "ดอนตูม บ้านหลวง นครปฐม 73150",
    name_en: "Don Tum, Ban Luang, Nakhon Pathom, 73150",
  },
  {
    id: 730406,
    districtTh: "ดอนตูม",
    districtEn: "Don Tum",
    subdistrictTh: "ดอนรวก",
    subdistrictEn: "Don Ruak",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73150,
    district_code: 7304,
    province_code: 58,
    name: "ดอนตูม ดอนรวก นครปฐม 73150",
    name_th: "ดอนตูม ดอนรวก นครปฐม 73150",
    name_en: "Don Tum, Don Ruak, Nakhon Pathom, 73150",
  },
  {
    id: 730407,
    districtTh: "ดอนตูม",
    districtEn: "Don Tum",
    subdistrictTh: "ห้วยด้วน",
    subdistrictEn: "Huai Duan",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73150,
    district_code: 7304,
    province_code: 58,
    name: "ดอนตูม ห้วยด้วน นครปฐม 73150",
    name_th: "ดอนตูม ห้วยด้วน นครปฐม 73150",
    name_en: "Don Tum, Huai Duan, Nakhon Pathom, 73150",
  },
  {
    id: 730408,
    districtTh: "ดอนตูม",
    districtEn: "Don Tum",
    subdistrictTh: "ลำลูกบัว",
    subdistrictEn: "Lam Luk Bua",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73150,
    district_code: 7304,
    province_code: 58,
    name: "ดอนตูม ลำลูกบัว นครปฐม 73150",
    name_th: "ดอนตูม ลำลูกบัว นครปฐม 73150",
    name_en: "Don Tum, Lam Luk Bua, Nakhon Pathom, 73150",
  },
  {
    id: 730501,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "บางเลน",
    subdistrictEn: "Bang Len",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน บางเลน นครปฐม 73130",
    name_th: "บางเลน บางเลน นครปฐม 73130",
    name_en: "Bang Len, Bang Len, Nakhon Pathom, 73130",
  },
  {
    id: 730502,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "บางปลา",
    subdistrictEn: "Bang Pla",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน บางปลา นครปฐม 73130",
    name_th: "บางเลน บางปลา นครปฐม 73130",
    name_en: "Bang Len, Bang Pla, Nakhon Pathom, 73130",
  },
  {
    id: 730503,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "บางหลวง",
    subdistrictEn: "Bang Luang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73190,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน บางหลวง นครปฐม 73190",
    name_th: "บางเลน บางหลวง นครปฐม 73190",
    name_en: "Bang Len, Bang Luang, Nakhon Pathom, 73190",
  },
  {
    id: 730504,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "บางภาษี",
    subdistrictEn: "Bang Phasi",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน บางภาษี นครปฐม 73130",
    name_th: "บางเลน บางภาษี นครปฐม 73130",
    name_en: "Bang Len, Bang Phasi, Nakhon Pathom, 73130",
  },
  {
    id: 730505,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "บางระกำ",
    subdistrictEn: "Bang Rakam",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน บางระกำ นครปฐม 73130",
    name_th: "บางเลน บางระกำ นครปฐม 73130",
    name_en: "Bang Len, Bang Rakam, Nakhon Pathom, 73130",
  },
  {
    id: 730506,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "บางไทรป่า",
    subdistrictEn: "Bang Sai Pa",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน บางไทรป่า นครปฐม 73130",
    name_th: "บางเลน บางไทรป่า นครปฐม 73130",
    name_en: "Bang Len, Bang Sai Pa, Nakhon Pathom, 73130",
  },
  {
    id: 730507,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "หินมูล",
    subdistrictEn: "Hin Mun",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73190,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน หินมูล นครปฐม 73190",
    name_th: "บางเลน หินมูล นครปฐม 73190",
    name_en: "Bang Len, Hin Mun, Nakhon Pathom, 73190",
  },
  {
    id: 730508,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "ไทรงาม",
    subdistrictEn: "Sai Ngam",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน ไทรงาม นครปฐม 73130",
    name_th: "บางเลน ไทรงาม นครปฐม 73130",
    name_en: "Bang Len, Sai Ngam, Nakhon Pathom, 73130",
  },
  {
    id: 730509,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "ดอนตูม",
    subdistrictEn: "Don Tum",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน ดอนตูม นครปฐม 73130",
    name_th: "บางเลน ดอนตูม นครปฐม 73130",
    name_en: "Bang Len, Don Tum, Nakhon Pathom, 73130",
  },
  {
    id: 730510,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "นิลเพชร",
    subdistrictEn: "Ninlaphet",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน นิลเพชร นครปฐม 73130",
    name_th: "บางเลน นิลเพชร นครปฐม 73130",
    name_en: "Bang Len, Ninlaphet, Nakhon Pathom, 73130",
  },
  {
    id: 730511,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "บัวปากท่า",
    subdistrictEn: "Bua Pak Tha",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน บัวปากท่า นครปฐม 73130",
    name_th: "บางเลน บัวปากท่า นครปฐม 73130",
    name_en: "Bang Len, Bua Pak Tha, Nakhon Pathom, 73130",
  },
  {
    id: 730512,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "คลองนกกระทุง",
    subdistrictEn: "Khlong Nok Krathung",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน คลองนกกระทุง นครปฐม 73130",
    name_th: "บางเลน คลองนกกระทุง นครปฐม 73130",
    name_en: "Bang Len, Khlong Nok Krathung, Nakhon Pathom, 73130",
  },
  {
    id: 730513,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "นราภิรมย์",
    subdistrictEn: "Naraphirom",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน นราภิรมย์ นครปฐม 73130",
    name_th: "บางเลน นราภิรมย์ นครปฐม 73130",
    name_en: "Bang Len, Naraphirom, Nakhon Pathom, 73130",
  },
  {
    id: 730514,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "ลำพญา",
    subdistrictEn: "Lam Phaya",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน ลำพญา นครปฐม 73130",
    name_th: "บางเลน ลำพญา นครปฐม 73130",
    name_en: "Bang Len, Lam Phaya, Nakhon Pathom, 73130",
  },
  {
    id: 730515,
    districtTh: "บางเลน",
    districtEn: "Bang Len",
    subdistrictTh: "ไผ่หูช้าง",
    subdistrictEn: "Phai Hu Chang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73130,
    district_code: 7305,
    province_code: 58,
    name: "บางเลน ไผ่หูช้าง นครปฐม 73130",
    name_th: "บางเลน ไผ่หูช้าง นครปฐม 73130",
    name_en: "Bang Len, Phai Hu Chang, Nakhon Pathom, 73130",
  },
  {
    id: 730601,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน ท่าข้าม นครปฐม 73110",
    name_th: "สามพราน ท่าข้าม นครปฐม 73110",
    name_en: "Sam Phran, Tha Kham, Nakhon Pathom, 73110",
  },
  {
    id: 730602,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "ทรงคนอง",
    subdistrictEn: "Song Khanong",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73210,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน ทรงคนอง นครปฐม 73210",
    name_th: "สามพราน ทรงคนอง นครปฐม 73210",
    name_en: "Sam Phran, Song Khanong, Nakhon Pathom, 73210",
  },
  {
    id: 730603,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "หอมเกร็ด",
    subdistrictEn: "Hom Kret",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน หอมเกร็ด นครปฐม 73110",
    name_th: "สามพราน หอมเกร็ด นครปฐม 73110",
    name_en: "Sam Phran, Hom Kret, Nakhon Pathom, 73110",
  },
  {
    id: 730604,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "บางกระทึก",
    subdistrictEn: "Bang Krathuek",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73210,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน บางกระทึก นครปฐม 73210",
    name_th: "สามพราน บางกระทึก นครปฐม 73210",
    name_en: "Sam Phran, Bang Krathuek, Nakhon Pathom, 73210",
  },
  {
    id: 730605,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "บางเตย",
    subdistrictEn: "Bang Toei",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73210,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน บางเตย นครปฐม 73210",
    name_th: "สามพราน บางเตย นครปฐม 73210",
    name_en: "Sam Phran, Bang Toei, Nakhon Pathom, 73210",
  },
  {
    id: 730606,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "สามพราน",
    subdistrictEn: "Sam Phran",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน สามพราน นครปฐม 73110",
    name_th: "สามพราน สามพราน นครปฐม 73110",
    name_en: "Sam Phran, Sam Phran, Nakhon Pathom, 73110",
  },
  {
    id: 730607,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "บางช้าง",
    subdistrictEn: "Bang Chang",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน บางช้าง นครปฐม 73110",
    name_th: "สามพราน บางช้าง นครปฐม 73110",
    name_en: "Sam Phran, Bang Chang, Nakhon Pathom, 73110",
  },
  {
    id: 730608,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "ไร่ขิง",
    subdistrictEn: "Rai Khing",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73210,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน ไร่ขิง นครปฐม 73210",
    name_th: "สามพราน ไร่ขิง นครปฐม 73210",
    name_en: "Sam Phran, Rai Khing, Nakhon Pathom, 73210",
  },
  {
    id: 730609,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "ท่าตลาด",
    subdistrictEn: "Tha Talat",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน ท่าตลาด นครปฐม 73110",
    name_th: "สามพราน ท่าตลาด นครปฐม 73110",
    name_en: "Sam Phran, Tha Talat, Nakhon Pathom, 73110",
  },
  {
    id: 730610,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "กระทุ่มล้ม",
    subdistrictEn: "Krathum Lom",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73220,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน กระทุ่มล้ม นครปฐม 73220",
    name_th: "สามพราน กระทุ่มล้ม นครปฐม 73220",
    name_en: "Sam Phran, Krathum Lom, Nakhon Pathom, 73220",
  },
  {
    id: 730611,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "คลองใหม่",
    subdistrictEn: "Khlong Mai",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน คลองใหม่ นครปฐม 73110",
    name_th: "สามพราน คลองใหม่ นครปฐม 73110",
    name_en: "Sam Phran, Khlong Mai, Nakhon Pathom, 73110",
  },
  {
    id: 730612,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "ตลาดจินดา",
    subdistrictEn: "Talat Chinda",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน ตลาดจินดา นครปฐม 73110",
    name_th: "สามพราน ตลาดจินดา นครปฐม 73110",
    name_en: "Sam Phran, Talat Chinda, Nakhon Pathom, 73110",
  },
  {
    id: 730613,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "คลองจินดา",
    subdistrictEn: "Khlong Chinda",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน คลองจินดา นครปฐม 73110",
    name_th: "สามพราน คลองจินดา นครปฐม 73110",
    name_en: "Sam Phran, Khlong Chinda, Nakhon Pathom, 73110",
  },
  {
    id: 730614,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "ยายชา",
    subdistrictEn: "Yai Cha",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน ยายชา นครปฐม 73110",
    name_th: "สามพราน ยายชา นครปฐม 73110",
    name_en: "Sam Phran, Yai Cha, Nakhon Pathom, 73110",
  },
  {
    id: 730615,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73110,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน บ้านใหม่ นครปฐม 73110",
    name_th: "สามพราน บ้านใหม่ นครปฐม 73110",
    name_en: "Sam Phran, Ban Mai, Nakhon Pathom, 73110",
  },
  {
    id: 730616,
    districtTh: "สามพราน",
    districtEn: "Sam Phran",
    subdistrictTh: "อ้อมใหญ่",
    subdistrictEn: "Om Yai",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73160,
    district_code: 7306,
    province_code: 58,
    name: "สามพราน อ้อมใหญ่ นครปฐม 73160",
    name_th: "สามพราน อ้อมใหญ่ นครปฐม 73160",
    name_en: "Sam Phran, Om Yai, Nakhon Pathom, 73160",
  },
  {
    id: 730701,
    districtTh: "พุทธมณฑล",
    districtEn: "Phutthamonthon",
    subdistrictTh: "ศาลายา",
    subdistrictEn: "Sala Ya",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73170,
    district_code: 7307,
    province_code: 58,
    name: "พุทธมณฑล ศาลายา นครปฐม 73170",
    name_th: "พุทธมณฑล ศาลายา นครปฐม 73170",
    name_en: "Phutthamonthon, Sala Ya, Nakhon Pathom, 73170",
  },
  {
    id: 730702,
    districtTh: "พุทธมณฑล",
    districtEn: "Phutthamonthon",
    subdistrictTh: "คลองโยง",
    subdistrictEn: "Khlong Yong",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73170,
    district_code: 7307,
    province_code: 58,
    name: "พุทธมณฑล คลองโยง นครปฐม 73170",
    name_th: "พุทธมณฑล คลองโยง นครปฐม 73170",
    name_en: "Phutthamonthon, Khlong Yong, Nakhon Pathom, 73170",
  },
  {
    id: 730703,
    districtTh: "พุทธมณฑล",
    districtEn: "Phutthamonthon",
    subdistrictTh: "มหาสวัสดิ์",
    subdistrictEn: "Maha Sawat",
    provinceTh: "นครปฐม",
    provinceEn: "Nakhon Pathom",
    zipcode: 73170,
    district_code: 7307,
    province_code: 58,
    name: "พุทธมณฑล มหาสวัสดิ์ นครปฐม 73170",
    name_th: "พุทธมณฑล มหาสวัสดิ์ นครปฐม 73170",
    name_en: "Phutthamonthon, Maha Sawat, Nakhon Pathom, 73170",
  },
  {
    id: 740101,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "มหาชัย",
    subdistrictEn: "Maha Chai",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร มหาชัย สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร มหาชัย สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Maha Chai, Samut Sakhon, 74000",
  },
  {
    id: 740102,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "ท่าฉลอม",
    subdistrictEn: "Tha Chalom",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร ท่าฉลอม สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร ท่าฉลอม สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Tha Chalom, Samut Sakhon, 74000",
  },
  {
    id: 740103,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "โกรกกราก",
    subdistrictEn: "Krok Krak",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร โกรกกราก สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร โกรกกราก สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Krok Krak, Samut Sakhon, 74000",
  },
  {
    id: 740104,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "บ้านบ่อ",
    subdistrictEn: "Ban Bo",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร บ้านบ่อ สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร บ้านบ่อ สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Ban Bo, Samut Sakhon, 74000",
  },
  {
    id: 740105,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "บางโทรัด",
    subdistrictEn: "Bang Tho Rat",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร บางโทรัด สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร บางโทรัด สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Bang Tho Rat, Samut Sakhon, 74000",
  },
  {
    id: 740106,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "กาหลง",
    subdistrictEn: "Ka Long",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร กาหลง สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร กาหลง สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Ka Long, Samut Sakhon, 74000",
  },
  {
    id: 740107,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "นาโคก",
    subdistrictEn: "Na Khok",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร นาโคก สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร นาโคก สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Na Khok, Samut Sakhon, 74000",
  },
  {
    id: 740108,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "ท่าจีน",
    subdistrictEn: "Tha Chin",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร ท่าจีน สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร ท่าจีน สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Tha Chin, Samut Sakhon, 74000",
  },
  {
    id: 740109,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "นาดี",
    subdistrictEn: "Na Di",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร นาดี สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร นาดี สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Na Di, Samut Sakhon, 74000",
  },
  {
    id: 740110,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "ท่าทราย",
    subdistrictEn: "Tha Sai",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร ท่าทราย สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร ท่าทราย สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Tha Sai, Samut Sakhon, 74000",
  },
  {
    id: 740111,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "คอกกระบือ",
    subdistrictEn: "Khok Krabue",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร คอกกระบือ สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร คอกกระบือ สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Khok Krabue, Samut Sakhon, 74000",
  },
  {
    id: 740112,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "บางน้ำจืด",
    subdistrictEn: "Bang Nam Chuet",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร บางน้ำจืด สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร บางน้ำจืด สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Bang Nam Chuet, Samut Sakhon, 74000",
  },
  {
    id: 740113,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "พันท้ายนรสิงห์",
    subdistrictEn: "Phan Thai Norasing",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร พันท้ายนรสิงห์ สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร พันท้ายนรสิงห์ สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Phan Thai Norasing, Samut Sakhon, 74000",
  },
  {
    id: 740114,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "โคกขาม",
    subdistrictEn: "Khok Kham",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร โคกขาม สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร โคกขาม สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Khok Kham, Samut Sakhon, 74000",
  },
  {
    id: 740115,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "บ้านเกาะ",
    subdistrictEn: "Ban Ko",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร บ้านเกาะ สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร บ้านเกาะ สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Ban Ko, Samut Sakhon, 74000",
  },
  {
    id: 740116,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "บางกระเจ้า",
    subdistrictEn: "Bang Krachao",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร บางกระเจ้า สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร บางกระเจ้า สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Bang Krachao, Samut Sakhon, 74000",
  },
  {
    id: 740117,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "บางหญ้าแพรก",
    subdistrictEn: "Bang Ya Phraek",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร บางหญ้าแพรก สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร บางหญ้าแพรก สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Bang Ya Phraek, Samut Sakhon, 74000",
  },
  {
    id: 740118,
    districtTh: "เมืองสมุทรสาคร",
    districtEn: "Mueang Samut Sakhon",
    subdistrictTh: "ชัยมงคล",
    subdistrictEn: "Chai Mongkon",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74000,
    district_code: 7401,
    province_code: 59,
    name: "เมืองสมุทรสาคร ชัยมงคล สมุทรสาคร 74000",
    name_th: "เมืองสมุทรสาคร ชัยมงคล สมุทรสาคร 74000",
    name_en: "Mueang Samut Sakhon, Chai Mongkon, Samut Sakhon, 74000",
  },
  {
    id: 740201,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "ตลาดกระทุ่มแบน",
    subdistrictEn: "Talat Krathum Baen",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน ตลาดกระทุ่มแบน สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน ตลาดกระทุ่มแบน สมุทรสาคร 74110",
    name_en: "Krathum Baen, Talat Krathum Baen, Samut Sakhon, 74110",
  },
  {
    id: 740202,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "อ้อมน้อย",
    subdistrictEn: "Om Noi",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74130,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน อ้อมน้อย สมุทรสาคร 74130",
    name_th: "กระทุ่มแบน อ้อมน้อย สมุทรสาคร 74130",
    name_en: "Krathum Baen, Om Noi, Samut Sakhon, 74130",
  },
  {
    id: 740203,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "ท่าไม้",
    subdistrictEn: "Tha Mai",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน ท่าไม้ สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน ท่าไม้ สมุทรสาคร 74110",
    name_en: "Krathum Baen, Tha Mai, Samut Sakhon, 74110",
  },
  {
    id: 740204,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "สวนหลวง",
    subdistrictEn: "Suan Luang",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน สวนหลวง สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน สวนหลวง สมุทรสาคร 74110",
    name_en: "Krathum Baen, Suan Luang, Samut Sakhon, 74110",
  },
  {
    id: 740205,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "บางยาง",
    subdistrictEn: "Bang Yang",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน บางยาง สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน บางยาง สมุทรสาคร 74110",
    name_en: "Krathum Baen, Bang Yang, Samut Sakhon, 74110",
  },
  {
    id: 740206,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "คลองมะเดื่อ",
    subdistrictEn: "Khlong Maduea",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน คลองมะเดื่อ สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน คลองมะเดื่อ สมุทรสาคร 74110",
    name_en: "Krathum Baen, Khlong Maduea, Samut Sakhon, 74110",
  },
  {
    id: 740207,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "หนองนกไข่",
    subdistrictEn: "Nong Nok Khai",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน หนองนกไข่ สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน หนองนกไข่ สมุทรสาคร 74110",
    name_en: "Krathum Baen, Nong Nok Khai, Samut Sakhon, 74110",
  },
  {
    id: 740208,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "ดอนไก่ดี",
    subdistrictEn: "Don Kai Di",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน ดอนไก่ดี สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน ดอนไก่ดี สมุทรสาคร 74110",
    name_en: "Krathum Baen, Don Kai Di, Samut Sakhon, 74110",
  },
  {
    id: 740209,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "แคราย",
    subdistrictEn: "Khae Rai",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน แคราย สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน แคราย สมุทรสาคร 74110",
    name_en: "Krathum Baen, Khae Rai, Samut Sakhon, 74110",
  },
  {
    id: 740210,
    districtTh: "กระทุ่มแบน",
    districtEn: "Krathum Baen",
    subdistrictTh: "ท่าเสา",
    subdistrictEn: "Tha Sao",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74110,
    district_code: 7402,
    province_code: 59,
    name: "กระทุ่มแบน ท่าเสา สมุทรสาคร 74110",
    name_th: "กระทุ่มแบน ท่าเสา สมุทรสาคร 74110",
    name_en: "Krathum Baen, Tha Sao, Samut Sakhon, 74110",
  },
  {
    id: 740301,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "บ้านแพ้ว",
    subdistrictEn: "Ban Phaeo",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว บ้านแพ้ว สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว บ้านแพ้ว สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Ban Phaeo, Samut Sakhon, 74120",
  },
  {
    id: 740302,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "หลักสาม",
    subdistrictEn: "Lak Sam",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว หลักสาม สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว หลักสาม สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Lak Sam, Samut Sakhon, 74120",
  },
  {
    id: 740303,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "ยกกระบัตร",
    subdistrictEn: "Yokkrabat",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว ยกกระบัตร สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว ยกกระบัตร สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Yokkrabat, Samut Sakhon, 74120",
  },
  {
    id: 740304,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "โรงเข้",
    subdistrictEn: "Rong Khe",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว โรงเข้ สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว โรงเข้ สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Rong Khe, Samut Sakhon, 74120",
  },
  {
    id: 740305,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "หนองสองห้อง",
    subdistrictEn: "Nong Song Hong",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว หนองสองห้อง สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว หนองสองห้อง สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Nong Song Hong, Samut Sakhon, 74120",
  },
  {
    id: 740306,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว หนองบัว สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว หนองบัว สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Nong Bua, Samut Sakhon, 74120",
  },
  {
    id: 740307,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "หลักสอง",
    subdistrictEn: "Lak Song",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว หลักสอง สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว หลักสอง สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Lak Song, Samut Sakhon, 74120",
  },
  {
    id: 740308,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "เจ็ดริ้ว",
    subdistrictEn: "Chet Rio",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว เจ็ดริ้ว สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว เจ็ดริ้ว สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Chet Rio, Samut Sakhon, 74120",
  },
  {
    id: 740309,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "คลองตัน",
    subdistrictEn: "Khlong Tan",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว คลองตัน สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว คลองตัน สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Khlong Tan, Samut Sakhon, 74120",
  },
  {
    id: 740310,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "อำแพง",
    subdistrictEn: "Amphaeng",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว อำแพง สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว อำแพง สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Amphaeng, Samut Sakhon, 74120",
  },
  {
    id: 740311,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "สวนส้ม",
    subdistrictEn: "Suan Som",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว สวนส้ม สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว สวนส้ม สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Suan Som, Samut Sakhon, 74120",
  },
  {
    id: 740312,
    districtTh: "บ้านแพ้ว",
    districtEn: "Ban Phaeo",
    subdistrictTh: "เกษตรพัฒนา",
    subdistrictEn: "Kaset Phatthana",
    provinceTh: "สมุทรสาคร",
    provinceEn: "Samut Sakhon",
    zipcode: 74120,
    district_code: 7403,
    province_code: 59,
    name: "บ้านแพ้ว เกษตรพัฒนา สมุทรสาคร 74120",
    name_th: "บ้านแพ้ว เกษตรพัฒนา สมุทรสาคร 74120",
    name_en: "Ban Phaeo, Kaset Phatthana, Samut Sakhon, 74120",
  },
  {
    id: 750101,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "แม่กลอง",
    subdistrictEn: "Mae Klong",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม แม่กลอง สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม แม่กลอง สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Mae Klong, Samut Songkhram, 75000",
  },
  {
    id: 750102,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "บางขันแตก",
    subdistrictEn: "Bang Khan Taek",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม บางขันแตก สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม บางขันแตก สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Bang Khan Taek, Samut Songkhram, 75000",
  },
  {
    id: 750103,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "ลาดใหญ่",
    subdistrictEn: "Lat Yai",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม ลาดใหญ่ สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม ลาดใหญ่ สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Lat Yai, Samut Songkhram, 75000",
  },
  {
    id: 750104,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "บ้านปรก",
    subdistrictEn: "Ban Prok",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม บ้านปรก สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม บ้านปรก สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Ban Prok, Samut Songkhram, 75000",
  },
  {
    id: 750105,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "บางแก้ว",
    subdistrictEn: "Bang Kaeo",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม บางแก้ว สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม บางแก้ว สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Bang Kaeo, Samut Songkhram, 75000",
  },
  {
    id: 750106,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "ท้ายหาด",
    subdistrictEn: "Thai Hat",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม ท้ายหาด สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม ท้ายหาด สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Thai Hat, Samut Songkhram, 75000",
  },
  {
    id: 750107,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "แหลมใหญ่",
    subdistrictEn: "Laem Yai",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม แหลมใหญ่ สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม แหลมใหญ่ สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Laem Yai, Samut Songkhram, 75000",
  },
  {
    id: 750108,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "คลองเขิน",
    subdistrictEn: "Khlong Khoen",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม คลองเขิน สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม คลองเขิน สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Khlong Khoen, Samut Songkhram, 75000",
  },
  {
    id: 750109,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "คลองโคน",
    subdistrictEn: "Khlong Khon",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม คลองโคน สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม คลองโคน สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Khlong Khon, Samut Songkhram, 75000",
  },
  {
    id: 750110,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "นางตะเคียน",
    subdistrictEn: "Nang Takhian",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม นางตะเคียน สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม นางตะเคียน สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Nang Takhian, Samut Songkhram, 75000",
  },
  {
    id: 750111,
    districtTh: "เมืองสมุทรสงคราม",
    districtEn: "Mueang Samut Songkhram",
    subdistrictTh: "บางจะเกร็ง",
    subdistrictEn: "Bang Chakreng",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75000,
    district_code: 7501,
    province_code: 60,
    name: "เมืองสมุทรสงคราม บางจะเกร็ง สมุทรสงคราม 75000",
    name_th: "เมืองสมุทรสงคราม บางจะเกร็ง สมุทรสงคราม 75000",
    name_en: "Mueang Samut Songkhram, Bang Chakreng, Samut Songkhram, 75000",
  },
  {
    id: 750201,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "กระดังงา",
    subdistrictEn: "Kradangnga",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที กระดังงา สมุทรสงคราม 75120",
    name_th: "บางคนที กระดังงา สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Kradangnga, Samut Songkhram, 75120",
  },
  {
    id: 750202,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "บางสะแก",
    subdistrictEn: "Bang Sakae",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที บางสะแก สมุทรสงคราม 75120",
    name_th: "บางคนที บางสะแก สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Bang Sakae, Samut Songkhram, 75120",
  },
  {
    id: 750203,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "บางยี่รงค์",
    subdistrictEn: "Bang Yi Rong",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที บางยี่รงค์ สมุทรสงคราม 75120",
    name_th: "บางคนที บางยี่รงค์ สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Bang Yi Rong, Samut Songkhram, 75120",
  },
  {
    id: 750204,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "โรงหีบ",
    subdistrictEn: "Rong Hip",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที โรงหีบ สมุทรสงคราม 75120",
    name_th: "บางคนที โรงหีบ สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Rong Hip, Samut Songkhram, 75120",
  },
  {
    id: 750205,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "บางคนที",
    subdistrictEn: "Bang Khonthi",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที บางคนที สมุทรสงคราม 75120",
    name_th: "บางคนที บางคนที สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Bang Khonthi, Samut Songkhram, 75120",
  },
  {
    id: 750206,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "ดอนมะโนรา",
    subdistrictEn: "Don Manora",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที ดอนมะโนรา สมุทรสงคราม 75120",
    name_th: "บางคนที ดอนมะโนรา สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Don Manora, Samut Songkhram, 75120",
  },
  {
    id: 750207,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "บางพรม",
    subdistrictEn: "Bang Phrom",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที บางพรม สมุทรสงคราม 75120",
    name_th: "บางคนที บางพรม สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Bang Phrom, Samut Songkhram, 75120",
  },
  {
    id: 750208,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "บางกุ้ง",
    subdistrictEn: "Bang Kung",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที บางกุ้ง สมุทรสงคราม 75120",
    name_th: "บางคนที บางกุ้ง สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Bang Kung, Samut Songkhram, 75120",
  },
  {
    id: 750209,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "จอมปลวก",
    subdistrictEn: "Chom Pluak",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที จอมปลวก สมุทรสงคราม 75120",
    name_th: "บางคนที จอมปลวก สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Chom Pluak, Samut Songkhram, 75120",
  },
  {
    id: 750210,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "บางนกแขวก",
    subdistrictEn: "Bang Nok Khwaek",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที บางนกแขวก สมุทรสงคราม 75120",
    name_th: "บางคนที บางนกแขวก สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Bang Nok Khwaek, Samut Songkhram, 75120",
  },
  {
    id: 750211,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "ยายแพง",
    subdistrictEn: "Yai Phaeng",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที ยายแพง สมุทรสงคราม 75120",
    name_th: "บางคนที ยายแพง สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Yai Phaeng, Samut Songkhram, 75120",
  },
  {
    id: 750212,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "บางกระบือ",
    subdistrictEn: "Bang Krabue",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที บางกระบือ สมุทรสงคราม 75120",
    name_th: "บางคนที บางกระบือ สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Bang Krabue, Samut Songkhram, 75120",
  },
  {
    id: 750213,
    districtTh: "บางคนที",
    districtEn: "Bang Khonthi",
    subdistrictTh: "บ้านปราโมทย์",
    subdistrictEn: "Ban Pramot",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75120,
    district_code: 7502,
    province_code: 60,
    name: "บางคนที บ้านปราโมทย์ สมุทรสงคราม 75120",
    name_th: "บางคนที บ้านปราโมทย์ สมุทรสงคราม 75120",
    name_en: "Bang Khonthi, Ban Pramot, Samut Songkhram, 75120",
  },
  {
    id: 750301,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "อัมพวา",
    subdistrictEn: "Amphawa",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา อัมพวา สมุทรสงคราม 75110",
    name_th: "อัมพวา อัมพวา สมุทรสงคราม 75110",
    name_en: "Amphawa, Amphawa, Samut Songkhram, 75110",
  },
  {
    id: 750302,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "สวนหลวง",
    subdistrictEn: "Suan Luang",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา สวนหลวง สมุทรสงคราม 75110",
    name_th: "อัมพวา สวนหลวง สมุทรสงคราม 75110",
    name_en: "Amphawa, Suan Luang, Samut Songkhram, 75110",
  },
  {
    id: 750303,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "ท่าคา",
    subdistrictEn: "Tha Kha",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา ท่าคา สมุทรสงคราม 75110",
    name_th: "อัมพวา ท่าคา สมุทรสงคราม 75110",
    name_en: "Amphawa, Tha Kha, Samut Songkhram, 75110",
  },
  {
    id: 750304,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "วัดประดู่",
    subdistrictEn: "Wat Pradu",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา วัดประดู่ สมุทรสงคราม 75110",
    name_th: "อัมพวา วัดประดู่ สมุทรสงคราม 75110",
    name_en: "Amphawa, Wat Pradu, Samut Songkhram, 75110",
  },
  {
    id: 750305,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "เหมืองใหม่",
    subdistrictEn: "Mueang Mai",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา เหมืองใหม่ สมุทรสงคราม 75110",
    name_th: "อัมพวา เหมืองใหม่ สมุทรสงคราม 75110",
    name_en: "Amphawa, Mueang Mai, Samut Songkhram, 75110",
  },
  {
    id: 750306,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "บางช้าง",
    subdistrictEn: "Bang Chang",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา บางช้าง สมุทรสงคราม 75110",
    name_th: "อัมพวา บางช้าง สมุทรสงคราม 75110",
    name_en: "Amphawa, Bang Chang, Samut Songkhram, 75110",
  },
  {
    id: 750307,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "แควอ้อม",
    subdistrictEn: "Khwae Om",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา แควอ้อม สมุทรสงคราม 75110",
    name_th: "อัมพวา แควอ้อม สมุทรสงคราม 75110",
    name_en: "Amphawa, Khwae Om, Samut Songkhram, 75110",
  },
  {
    id: 750308,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "ปลายโพงพาง",
    subdistrictEn: "Plai Phongphang",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา ปลายโพงพาง สมุทรสงคราม 75110",
    name_th: "อัมพวา ปลายโพงพาง สมุทรสงคราม 75110",
    name_en: "Amphawa, Plai Phongphang, Samut Songkhram, 75110",
  },
  {
    id: 750309,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "บางแค",
    subdistrictEn: "Bang Khae",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา บางแค สมุทรสงคราม 75110",
    name_th: "อัมพวา บางแค สมุทรสงคราม 75110",
    name_en: "Amphawa, Bang Khae, Samut Songkhram, 75110",
  },
  {
    id: 750310,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "แพรกหนามแดง",
    subdistrictEn: "Phraek Nam Daeng",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา แพรกหนามแดง สมุทรสงคราม 75110",
    name_th: "อัมพวา แพรกหนามแดง สมุทรสงคราม 75110",
    name_en: "Amphawa, Phraek Nam Daeng, Samut Songkhram, 75110",
  },
  {
    id: 750311,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "ยี่สาร",
    subdistrictEn: "Yi San",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา ยี่สาร สมุทรสงคราม 75110",
    name_th: "อัมพวา ยี่สาร สมุทรสงคราม 75110",
    name_en: "Amphawa, Yi San, Samut Songkhram, 75110",
  },
  {
    id: 750312,
    districtTh: "อัมพวา",
    districtEn: "Amphawa",
    subdistrictTh: "บางนางลี่",
    subdistrictEn: "Bang Nang Lee",
    provinceTh: "สมุทรสงคราม",
    provinceEn: "Samut Songkhram",
    zipcode: 75110,
    district_code: 7503,
    province_code: 60,
    name: "อัมพวา บางนางลี่ สมุทรสงคราม 75110",
    name_th: "อัมพวา บางนางลี่ สมุทรสงคราม 75110",
    name_en: "Amphawa, Bang Nang Lee, Samut Songkhram, 75110",
  },
  {
    id: 760101,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "ท่าราบ",
    subdistrictEn: "Tha Rap",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี ท่าราบ เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี ท่าราบ เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Tha Rap, Phetchaburi, 76000",
  },
  {
    id: 760102,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "คลองกระแชง",
    subdistrictEn: "Khlong Krachaeng",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี คลองกระแชง เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี คลองกระแชง เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Khlong Krachaeng, Phetchaburi, 76000",
  },
  {
    id: 760103,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "บางจาน",
    subdistrictEn: "Bang Chan",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี บางจาน เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี บางจาน เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Bang Chan, Phetchaburi, 76000",
  },
  {
    id: 760104,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "นาพันสาม",
    subdistrictEn: "Na Phan Sam",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี นาพันสาม เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี นาพันสาม เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Na Phan Sam, Phetchaburi, 76000",
  },
  {
    id: 760105,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "ธงชัย",
    subdistrictEn: "Thong Chai",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี ธงชัย เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี ธงชัย เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Thong Chai, Phetchaburi, 76000",
  },
  {
    id: 760106,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "บ้านกุ่ม",
    subdistrictEn: "Ban Kum",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี บ้านกุ่ม เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี บ้านกุ่ม เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Ban Kum, Phetchaburi, 76000",
  },
  {
    id: 760107,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "หนองโสน",
    subdistrictEn: "Nong Sano",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี หนองโสน เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี หนองโสน เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Nong Sano, Phetchaburi, 76000",
  },
  {
    id: 760108,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "ไร่ส้ม",
    subdistrictEn: "Rai Som",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี ไร่ส้ม เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี ไร่ส้ม เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Rai Som, Phetchaburi, 76000",
  },
  {
    id: 760109,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "เวียงคอย",
    subdistrictEn: "Wiang Khoi",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี เวียงคอย เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี เวียงคอย เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Wiang Khoi, Phetchaburi, 76000",
  },
  {
    id: 760110,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "บางจาก",
    subdistrictEn: "Bang Chak",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี บางจาก เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี บางจาก เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Bang Chak, Phetchaburi, 76000",
  },
  {
    id: 760111,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "บ้านหม้อ",
    subdistrictEn: "Ban Mo",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี บ้านหม้อ เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี บ้านหม้อ เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Ban Mo, Phetchaburi, 76000",
  },
  {
    id: 760112,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "ต้นมะม่วง",
    subdistrictEn: "Ton Mamuang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี ต้นมะม่วง เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี ต้นมะม่วง เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Ton Mamuang, Phetchaburi, 76000",
  },
  {
    id: 760113,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "ช่องสะแก",
    subdistrictEn: "Chong Sakae",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี ช่องสะแก เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี ช่องสะแก เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Chong Sakae, Phetchaburi, 76000",
  },
  {
    id: 760114,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "นาวุ้ง",
    subdistrictEn: "Na Wung",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี นาวุ้ง เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี นาวุ้ง เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Na Wung, Phetchaburi, 76000",
  },
  {
    id: 760115,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "สำมะโรง",
    subdistrictEn: "Sam Marong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี สำมะโรง เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี สำมะโรง เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Sam Marong, Phetchaburi, 76000",
  },
  {
    id: 760116,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "โพพระ",
    subdistrictEn: "Pho Phra",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี โพพระ เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี โพพระ เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Pho Phra, Phetchaburi, 76000",
  },
  {
    id: 760117,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "หาดเจ้าสำราญ",
    subdistrictEn: "Hat Chao Samran",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76100,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี หาดเจ้าสำราญ เพชรบุรี 76100",
    name_th: "เมืองเพชรบุรี หาดเจ้าสำราญ เพชรบุรี 76100",
    name_en: "Mueang Phetchaburi, Hat Chao Samran, Phetchaburi, 76100",
  },
  {
    id: 760118,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "หัวสะพาน",
    subdistrictEn: "Hua Saphan",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี หัวสะพาน เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี หัวสะพาน เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Hua Saphan, Phetchaburi, 76000",
  },
  {
    id: 760119,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "ต้นมะพร้าว",
    subdistrictEn: "Ton Maphrao",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี ต้นมะพร้าว เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี ต้นมะพร้าว เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Ton Maphrao, Phetchaburi, 76000",
  },
  {
    id: 760120,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "วังตะโก",
    subdistrictEn: "Wang Tako",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี วังตะโก เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี วังตะโก เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Wang Tako, Phetchaburi, 76000",
  },
  {
    id: 760121,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "โพไร่หวาน",
    subdistrictEn: "Pho Rai Wan",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี โพไร่หวาน เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี โพไร่หวาน เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Pho Rai Wan, Phetchaburi, 76000",
  },
  {
    id: 760122,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "ดอนยาง",
    subdistrictEn: "Don Yang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี ดอนยาง เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี ดอนยาง เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Don Yang, Phetchaburi, 76000",
  },
  {
    id: 760123,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "หนองขนาน",
    subdistrictEn: "Nong Khanan",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี หนองขนาน เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี หนองขนาน เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Nong Khanan, Phetchaburi, 76000",
  },
  {
    id: 760124,
    districtTh: "เมืองเพชรบุรี",
    districtEn: "Mueang Phetchaburi",
    subdistrictTh: "หนองพลับ",
    subdistrictEn: "Nong Phlap",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76000,
    district_code: 7601,
    province_code: 61,
    name: "เมืองเพชรบุรี หนองพลับ เพชรบุรี 76000",
    name_th: "เมืองเพชรบุรี หนองพลับ เพชรบุรี 76000",
    name_en: "Mueang Phetchaburi, Nong Phlap, Phetchaburi, 76000",
  },
  {
    id: 760201,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "เขาย้อย",
    subdistrictEn: "Khao Yoi",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย เขาย้อย เพชรบุรี 76140",
    name_th: "เขาย้อย เขาย้อย เพชรบุรี 76140",
    name_en: "Khao Yoi, Khao Yoi, Phetchaburi, 76140",
  },
  {
    id: 760202,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "สระพัง",
    subdistrictEn: "Sa Phang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย สระพัง เพชรบุรี 76140",
    name_th: "เขาย้อย สระพัง เพชรบุรี 76140",
    name_en: "Khao Yoi, Sa Phang, Phetchaburi, 76140",
  },
  {
    id: 760203,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "บางเค็ม",
    subdistrictEn: "Bang Khem",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย บางเค็ม เพชรบุรี 76140",
    name_th: "เขาย้อย บางเค็ม เพชรบุรี 76140",
    name_en: "Khao Yoi, Bang Khem, Phetchaburi, 76140",
  },
  {
    id: 760204,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "ทับคาง",
    subdistrictEn: "Thap Khang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย ทับคาง เพชรบุรี 76140",
    name_th: "เขาย้อย ทับคาง เพชรบุรี 76140",
    name_en: "Khao Yoi, Thap Khang, Phetchaburi, 76140",
  },
  {
    id: 760205,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "หนองปลาไหล",
    subdistrictEn: "Nong Pla Lai",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย หนองปลาไหล เพชรบุรี 76140",
    name_th: "เขาย้อย หนองปลาไหล เพชรบุรี 76140",
    name_en: "Khao Yoi, Nong Pla Lai, Phetchaburi, 76140",
  },
  {
    id: 760206,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "หนองปรง",
    subdistrictEn: "Nong Prong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย หนองปรง เพชรบุรี 76140",
    name_th: "เขาย้อย หนองปรง เพชรบุรี 76140",
    name_en: "Khao Yoi, Nong Prong, Phetchaburi, 76140",
  },
  {
    id: 760207,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "หนองชุมพล",
    subdistrictEn: "Nong Chumphon",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย หนองชุมพล เพชรบุรี 76140",
    name_th: "เขาย้อย หนองชุมพล เพชรบุรี 76140",
    name_en: "Khao Yoi, Nong Chumphon, Phetchaburi, 76140",
  },
  {
    id: 760208,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "ห้วยโรง",
    subdistrictEn: "Huai Rong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย ห้วยโรง เพชรบุรี 76140",
    name_th: "เขาย้อย ห้วยโรง เพชรบุรี 76140",
    name_en: "Khao Yoi, Huai Rong, Phetchaburi, 76140",
  },
  {
    id: 760209,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "ห้วยท่าช้าง",
    subdistrictEn: "Huai Tha Chang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย ห้วยท่าช้าง เพชรบุรี 76140",
    name_th: "เขาย้อย ห้วยท่าช้าง เพชรบุรี 76140",
    name_en: "Khao Yoi, Huai Tha Chang, Phetchaburi, 76140",
  },
  {
    id: 760210,
    districtTh: "เขาย้อย",
    districtEn: "Khao Yoi",
    subdistrictTh: "หนองชุมพลเหนือ",
    subdistrictEn: "Nong Chumphon Nuea",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76140,
    district_code: 7602,
    province_code: 61,
    name: "เขาย้อย หนองชุมพลเหนือ เพชรบุรี 76140",
    name_th: "เขาย้อย หนองชุมพลเหนือ เพชรบุรี 76140",
    name_en: "Khao Yoi, Nong Chumphon Nuea, Phetchaburi, 76140",
  },
  {
    id: 760301,
    districtTh: "หนองหญ้าปล้อง",
    districtEn: "Nong Ya Plong",
    subdistrictTh: "หนองหญ้าปล้อง",
    subdistrictEn: "Nong Ya Plong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76160,
    district_code: 7603,
    province_code: 61,
    name: "หนองหญ้าปล้อง หนองหญ้าปล้อง เพชรบุรี 76160",
    name_th: "หนองหญ้าปล้อง หนองหญ้าปล้อง เพชรบุรี 76160",
    name_en: "Nong Ya Plong, Nong Ya Plong, Phetchaburi, 76160",
  },
  {
    id: 760302,
    districtTh: "หนองหญ้าปล้อง",
    districtEn: "Nong Ya Plong",
    subdistrictTh: "ยางน้ำกลัดเหนือ",
    subdistrictEn: "Yang Nam Klat Nuea",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76160,
    district_code: 7603,
    province_code: 61,
    name: "หนองหญ้าปล้อง ยางน้ำกลัดเหนือ เพชรบุรี 76160",
    name_th: "หนองหญ้าปล้อง ยางน้ำกลัดเหนือ เพชรบุรี 76160",
    name_en: "Nong Ya Plong, Yang Nam Klat Nuea, Phetchaburi, 76160",
  },
  {
    id: 760303,
    districtTh: "หนองหญ้าปล้อง",
    districtEn: "Nong Ya Plong",
    subdistrictTh: "ยางน้ำกลัดใต้",
    subdistrictEn: "Yang Nam Klat Tai",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76160,
    district_code: 7603,
    province_code: 61,
    name: "หนองหญ้าปล้อง ยางน้ำกลัดใต้ เพชรบุรี 76160",
    name_th: "หนองหญ้าปล้อง ยางน้ำกลัดใต้ เพชรบุรี 76160",
    name_en: "Nong Ya Plong, Yang Nam Klat Tai, Phetchaburi, 76160",
  },
  {
    id: 760304,
    districtTh: "หนองหญ้าปล้อง",
    districtEn: "Nong Ya Plong",
    subdistrictTh: "ท่าตะคร้อ",
    subdistrictEn: "Tha Takror",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76160,
    district_code: 7603,
    province_code: 61,
    name: "หนองหญ้าปล้อง ท่าตะคร้อ เพชรบุรี 76160",
    name_th: "หนองหญ้าปล้อง ท่าตะคร้อ เพชรบุรี 76160",
    name_en: "Nong Ya Plong, Tha Takror, Phetchaburi, 76160",
  },
  {
    id: 760401,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "ชะอำ",
    subdistrictEn: "Cha-am",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ ชะอำ เพชรบุรี 76120",
    name_th: "ชะอำ ชะอำ เพชรบุรี 76120",
    name_en: "Cha-am, Cha-am, Phetchaburi, 76120",
  },
  {
    id: 760402,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "บางเก่า",
    subdistrictEn: "Bang Kao",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ บางเก่า เพชรบุรี 76120",
    name_th: "ชะอำ บางเก่า เพชรบุรี 76120",
    name_en: "Cha-am, Bang Kao, Phetchaburi, 76120",
  },
  {
    id: 760403,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "นายาง",
    subdistrictEn: "Na Yang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ นายาง เพชรบุรี 76120",
    name_th: "ชะอำ นายาง เพชรบุรี 76120",
    name_en: "Cha-am, Na Yang, Phetchaburi, 76120",
  },
  {
    id: 760404,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "เขาใหญ่",
    subdistrictEn: "Khao Yai",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ เขาใหญ่ เพชรบุรี 76120",
    name_th: "ชะอำ เขาใหญ่ เพชรบุรี 76120",
    name_en: "Cha-am, Khao Yai, Phetchaburi, 76120",
  },
  {
    id: 760405,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "หนองศาลา",
    subdistrictEn: "Nong Sala",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ หนองศาลา เพชรบุรี 76120",
    name_th: "ชะอำ หนองศาลา เพชรบุรี 76120",
    name_en: "Cha-am, Nong Sala, Phetchaburi, 76120",
  },
  {
    id: 760406,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "ห้วยทรายเหนือ",
    subdistrictEn: "Huai Sai Nuea",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ ห้วยทรายเหนือ เพชรบุรี 76120",
    name_th: "ชะอำ ห้วยทรายเหนือ เพชรบุรี 76120",
    name_en: "Cha-am, Huai Sai Nuea, Phetchaburi, 76120",
  },
  {
    id: 760407,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "ไร่ใหม่พัฒนา",
    subdistrictEn: "Rai Mai Phatthana",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ ไร่ใหม่พัฒนา เพชรบุรี 76120",
    name_th: "ชะอำ ไร่ใหม่พัฒนา เพชรบุรี 76120",
    name_en: "Cha-am, Rai Mai Phatthana, Phetchaburi, 76120",
  },
  {
    id: 760408,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "สามพระยา",
    subdistrictEn: "Sam Phraya",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ สามพระยา เพชรบุรี 76120",
    name_th: "ชะอำ สามพระยา เพชรบุรี 76120",
    name_en: "Cha-am, Sam Phraya, Phetchaburi, 76120",
  },
  {
    id: 760409,
    districtTh: "ชะอำ",
    districtEn: "Cha-am",
    subdistrictTh: "ดอนขุนห้วย",
    subdistrictEn: "Don Khun Huai",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76120,
    district_code: 7604,
    province_code: 61,
    name: "ชะอำ ดอนขุนห้วย เพชรบุรี 76120",
    name_th: "ชะอำ ดอนขุนห้วย เพชรบุรี 76120",
    name_en: "Cha-am, Don Khun Huai, Phetchaburi, 76120",
  },
  {
    id: 760501,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "ท่ายาง",
    subdistrictEn: "Tha Yang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง ท่ายาง เพชรบุรี 76130",
    name_th: "ท่ายาง ท่ายาง เพชรบุรี 76130",
    name_en: "Tha Yang, Tha Yang, Phetchaburi, 76130",
  },
  {
    id: 760502,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "ท่าคอย",
    subdistrictEn: "Tha Khoi",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง ท่าคอย เพชรบุรี 76130",
    name_th: "ท่ายาง ท่าคอย เพชรบุรี 76130",
    name_en: "Tha Yang, Tha Khoi, Phetchaburi, 76130",
  },
  {
    id: 760503,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "ยางหย่อง",
    subdistrictEn: "Yang Yong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง ยางหย่อง เพชรบุรี 76130",
    name_th: "ท่ายาง ยางหย่อง เพชรบุรี 76130",
    name_en: "Tha Yang, Yang Yong, Phetchaburi, 76130",
  },
  {
    id: 760504,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "หนองจอก",
    subdistrictEn: "Nong Chok",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง หนองจอก เพชรบุรี 76130",
    name_th: "ท่ายาง หนองจอก เพชรบุรี 76130",
    name_en: "Tha Yang, Nong Chok, Phetchaburi, 76130",
  },
  {
    id: 760505,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "มาบปลาเค้า",
    subdistrictEn: "Map Pla Khao",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง มาบปลาเค้า เพชรบุรี 76130",
    name_th: "ท่ายาง มาบปลาเค้า เพชรบุรี 76130",
    name_en: "Tha Yang, Map Pla Khao, Phetchaburi, 76130",
  },
  {
    id: 760506,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "ท่าไม้รวก",
    subdistrictEn: "Tha Mai Ruak",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง ท่าไม้รวก เพชรบุรี 76130",
    name_th: "ท่ายาง ท่าไม้รวก เพชรบุรี 76130",
    name_en: "Tha Yang, Tha Mai Ruak, Phetchaburi, 76130",
  },
  {
    id: 760507,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "วังไคร้",
    subdistrictEn: "Wang Khrai",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง วังไคร้ เพชรบุรี 76130",
    name_th: "ท่ายาง วังไคร้ เพชรบุรี 76130",
    name_en: "Tha Yang, Wang Khrai, Phetchaburi, 76130",
  },
  {
    id: 760511,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "กลัดหลวง",
    subdistrictEn: "Klat Luang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง กลัดหลวง เพชรบุรี 76130",
    name_th: "ท่ายาง กลัดหลวง เพชรบุรี 76130",
    name_en: "Tha Yang, Klat Luang, Phetchaburi, 76130",
  },
  {
    id: 760512,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "ปึกเตียน",
    subdistrictEn: "Puek Tian",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง ปึกเตียน เพชรบุรี 76130",
    name_th: "ท่ายาง ปึกเตียน เพชรบุรี 76130",
    name_en: "Tha Yang, Puek Tian, Phetchaburi, 76130",
  },
  {
    id: 760513,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "เขากระปุก",
    subdistrictEn: "Khao Krapuk",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง เขากระปุก เพชรบุรี 76130",
    name_th: "ท่ายาง เขากระปุก เพชรบุรี 76130",
    name_en: "Tha Yang, Khao Krapuk, Phetchaburi, 76130",
  },
  {
    id: 760514,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "ท่าแลง",
    subdistrictEn: "Tha Laeng",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง ท่าแลง เพชรบุรี 76130",
    name_th: "ท่ายาง ท่าแลง เพชรบุรี 76130",
    name_en: "Tha Yang, Tha Laeng, Phetchaburi, 76130",
  },
  {
    id: 760515,
    districtTh: "ท่ายาง",
    districtEn: "Tha Yang",
    subdistrictTh: "บ้านในดง",
    subdistrictEn: "Ban Nai Dong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76130,
    district_code: 7605,
    province_code: 61,
    name: "ท่ายาง บ้านในดง เพชรบุรี 76130",
    name_th: "ท่ายาง บ้านในดง เพชรบุรี 76130",
    name_en: "Tha Yang, Ban Nai Dong, Phetchaburi, 76130",
  },
  {
    id: 760601,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "บ้านลาด",
    subdistrictEn: "Ban Lat",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด บ้านลาด เพชรบุรี 76150",
    name_th: "บ้านลาด บ้านลาด เพชรบุรี 76150",
    name_en: "Ban Lat, Ban Lat, Phetchaburi, 76150",
  },
  {
    id: 760602,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "บ้านหาด",
    subdistrictEn: "Ban Hat",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด บ้านหาด เพชรบุรี 76150",
    name_th: "บ้านลาด บ้านหาด เพชรบุรี 76150",
    name_en: "Ban Lat, Ban Hat, Phetchaburi, 76150",
  },
  {
    id: 760603,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "บ้านทาน",
    subdistrictEn: "Ban Than",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด บ้านทาน เพชรบุรี 76150",
    name_th: "บ้านลาด บ้านทาน เพชรบุรี 76150",
    name_en: "Ban Lat, Ban Than, Phetchaburi, 76150",
  },
  {
    id: 760604,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ตำหรุ",
    subdistrictEn: "Tamru",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ตำหรุ เพชรบุรี 76150",
    name_th: "บ้านลาด ตำหรุ เพชรบุรี 76150",
    name_en: "Ban Lat, Tamru, Phetchaburi, 76150",
  },
  {
    id: 760605,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "สมอพลือ",
    subdistrictEn: "Samo Phlue",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด สมอพลือ เพชรบุรี 76150",
    name_th: "บ้านลาด สมอพลือ เพชรบุรี 76150",
    name_en: "Ban Lat, Samo Phlue, Phetchaburi, 76150",
  },
  {
    id: 760606,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ไร่มะขาม",
    subdistrictEn: "Rai Makham",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ไร่มะขาม เพชรบุรี 76150",
    name_th: "บ้านลาด ไร่มะขาม เพชรบุรี 76150",
    name_en: "Ban Lat, Rai Makham, Phetchaburi, 76150",
  },
  {
    id: 760607,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ท่าเสน",
    subdistrictEn: "Tha Sen",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ท่าเสน เพชรบุรี 76150",
    name_th: "บ้านลาด ท่าเสน เพชรบุรี 76150",
    name_en: "Ban Lat, Tha Sen, Phetchaburi, 76150",
  },
  {
    id: 760608,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "หนองกระเจ็ด",
    subdistrictEn: "Nong Krachet",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด หนองกระเจ็ด เพชรบุรี 76150",
    name_th: "บ้านลาด หนองกระเจ็ด เพชรบุรี 76150",
    name_en: "Ban Lat, Nong Krachet, Phetchaburi, 76150",
  },
  {
    id: 760609,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "หนองกะปุ",
    subdistrictEn: "Nong Kapu",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด หนองกะปุ เพชรบุรี 76150",
    name_th: "บ้านลาด หนองกะปุ เพชรบุรี 76150",
    name_en: "Ban Lat, Nong Kapu, Phetchaburi, 76150",
  },
  {
    id: 760610,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ลาดโพธิ์",
    subdistrictEn: "Lat Pho",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ลาดโพธิ์ เพชรบุรี 76150",
    name_th: "บ้านลาด ลาดโพธิ์ เพชรบุรี 76150",
    name_en: "Ban Lat, Lat Pho, Phetchaburi, 76150",
  },
  {
    id: 760611,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "สะพานไกร",
    subdistrictEn: "Saphan Krai",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด สะพานไกร เพชรบุรี 76150",
    name_th: "บ้านลาด สะพานไกร เพชรบุรี 76150",
    name_en: "Ban Lat, Saphan Krai, Phetchaburi, 76150",
  },
  {
    id: 760612,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ไร่โคก",
    subdistrictEn: "Rai Khok",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ไร่โคก เพชรบุรี 76150",
    name_th: "บ้านลาด ไร่โคก เพชรบุรี 76150",
    name_en: "Ban Lat, Rai Khok, Phetchaburi, 76150",
  },
  {
    id: 760613,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "โรงเข้",
    subdistrictEn: "Rong Khe",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด โรงเข้ เพชรบุรี 76150",
    name_th: "บ้านลาด โรงเข้ เพชรบุรี 76150",
    name_en: "Ban Lat, Rong Khe, Phetchaburi, 76150",
  },
  {
    id: 760614,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ไร่สะท้อน",
    subdistrictEn: "Rai Sathon",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ไร่สะท้อน เพชรบุรี 76150",
    name_th: "บ้านลาด ไร่สะท้อน เพชรบุรี 76150",
    name_en: "Ban Lat, Rai Sathon, Phetchaburi, 76150",
  },
  {
    id: 760615,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ห้วยข้อง",
    subdistrictEn: "Huai Khong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ห้วยข้อง เพชรบุรี 76150",
    name_th: "บ้านลาด ห้วยข้อง เพชรบุรี 76150",
    name_en: "Ban Lat, Huai Khong, Phetchaburi, 76150",
  },
  {
    id: 760616,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ท่าช้าง เพชรบุรี 76150",
    name_th: "บ้านลาด ท่าช้าง เพชรบุรี 76150",
    name_en: "Ban Lat, Tha Chang, Phetchaburi, 76150",
  },
  {
    id: 760617,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ถ้ำรงค์",
    subdistrictEn: "Tham Rong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ถ้ำรงค์ เพชรบุรี 76150",
    name_th: "บ้านลาด ถ้ำรงค์ เพชรบุรี 76150",
    name_en: "Ban Lat, Tham Rong, Phetchaburi, 76150",
  },
  {
    id: 760618,
    districtTh: "บ้านลาด",
    districtEn: "Ban Lat",
    subdistrictTh: "ห้วยลึก",
    subdistrictEn: "Huay Lueg",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76150,
    district_code: 7606,
    province_code: 61,
    name: "บ้านลาด ห้วยลึก เพชรบุรี 76150",
    name_th: "บ้านลาด ห้วยลึก เพชรบุรี 76150",
    name_en: "Ban Lat, Huay Lueg, Phetchaburi, 76150",
  },
  {
    id: 760701,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "บ้านแหลม",
    subdistrictEn: "Ban Laem",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม บ้านแหลม เพชรบุรี 76110",
    name_th: "บ้านแหลม บ้านแหลม เพชรบุรี 76110",
    name_en: "Ban Laem, Ban Laem, Phetchaburi, 76110",
  },
  {
    id: 760702,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "บางขุนไทร",
    subdistrictEn: "Bang Khun Sai",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม บางขุนไทร เพชรบุรี 76110",
    name_th: "บ้านแหลม บางขุนไทร เพชรบุรี 76110",
    name_en: "Ban Laem, Bang Khun Sai, Phetchaburi, 76110",
  },
  {
    id: 760703,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "ปากทะเล",
    subdistrictEn: "Pak Thale",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม ปากทะเล เพชรบุรี 76110",
    name_th: "บ้านแหลม ปากทะเล เพชรบุรี 76110",
    name_en: "Ban Laem, Pak Thale, Phetchaburi, 76110",
  },
  {
    id: 760704,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "บางแก้ว",
    subdistrictEn: "Bang Kaeo",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม บางแก้ว เพชรบุรี 76110",
    name_th: "บ้านแหลม บางแก้ว เพชรบุรี 76110",
    name_en: "Ban Laem, Bang Kaeo, Phetchaburi, 76110",
  },
  {
    id: 760705,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "แหลมผักเบี้ย",
    subdistrictEn: "Laem Phak Bia",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76100,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม แหลมผักเบี้ย เพชรบุรี 76100",
    name_th: "บ้านแหลม แหลมผักเบี้ย เพชรบุรี 76100",
    name_en: "Ban Laem, Laem Phak Bia, Phetchaburi, 76100",
  },
  {
    id: 760706,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "บางตะบูน",
    subdistrictEn: "Bang Tabun",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม บางตะบูน เพชรบุรี 76110",
    name_th: "บ้านแหลม บางตะบูน เพชรบุรี 76110",
    name_en: "Ban Laem, Bang Tabun, Phetchaburi, 76110",
  },
  {
    id: 760707,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "บางตะบูนออก",
    subdistrictEn: "Bang Tabun Ok",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม บางตะบูนออก เพชรบุรี 76110",
    name_th: "บ้านแหลม บางตะบูนออก เพชรบุรี 76110",
    name_en: "Ban Laem, Bang Tabun Ok, Phetchaburi, 76110",
  },
  {
    id: 760708,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "บางครก",
    subdistrictEn: "Bang Khrok",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม บางครก เพชรบุรี 76110",
    name_th: "บ้านแหลม บางครก เพชรบุรี 76110",
    name_en: "Ban Laem, Bang Khrok, Phetchaburi, 76110",
  },
  {
    id: 760709,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "ท่าแร้ง",
    subdistrictEn: "Tha Raeng",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม ท่าแร้ง เพชรบุรี 76110",
    name_th: "บ้านแหลม ท่าแร้ง เพชรบุรี 76110",
    name_en: "Ban Laem, Tha Raeng, Phetchaburi, 76110",
  },
  {
    id: 760710,
    districtTh: "บ้านแหลม",
    districtEn: "Ban Laem",
    subdistrictTh: "ท่าแร้งออก",
    subdistrictEn: "Tha Raeng Ok",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76110,
    district_code: 7607,
    province_code: 61,
    name: "บ้านแหลม ท่าแร้งออก เพชรบุรี 76110",
    name_th: "บ้านแหลม ท่าแร้งออก เพชรบุรี 76110",
    name_en: "Ban Laem, Tha Raeng Ok, Phetchaburi, 76110",
  },
  {
    id: 760801,
    districtTh: "แก่งกระจาน",
    districtEn: "Kaeng Krachan",
    subdistrictTh: "แก่งกระจาน",
    subdistrictEn: "Kaeng Krachan",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76170,
    district_code: 7608,
    province_code: 61,
    name: "แก่งกระจาน แก่งกระจาน เพชรบุรี 76170",
    name_th: "แก่งกระจาน แก่งกระจาน เพชรบุรี 76170",
    name_en: "Kaeng Krachan, Kaeng Krachan, Phetchaburi, 76170",
  },
  {
    id: 760802,
    districtTh: "แก่งกระจาน",
    districtEn: "Kaeng Krachan",
    subdistrictTh: "สองพี่น้อง",
    subdistrictEn: "Song Phi Nong",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76170,
    district_code: 7608,
    province_code: 61,
    name: "แก่งกระจาน สองพี่น้อง เพชรบุรี 76170",
    name_th: "แก่งกระจาน สองพี่น้อง เพชรบุรี 76170",
    name_en: "Kaeng Krachan, Song Phi Nong, Phetchaburi, 76170",
  },
  {
    id: 760803,
    districtTh: "แก่งกระจาน",
    districtEn: "Kaeng Krachan",
    subdistrictTh: "วังจันทร์",
    subdistrictEn: "Wang Chan",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76170,
    district_code: 7608,
    province_code: 61,
    name: "แก่งกระจาน วังจันทร์ เพชรบุรี 76170",
    name_th: "แก่งกระจาน วังจันทร์ เพชรบุรี 76170",
    name_en: "Kaeng Krachan, Wang Chan, Phetchaburi, 76170",
  },
  {
    id: 760804,
    districtTh: "แก่งกระจาน",
    districtEn: "Kaeng Krachan",
    subdistrictTh: "ป่าเด็ง",
    subdistrictEn: "Pa Deng",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76170,
    district_code: 7608,
    province_code: 61,
    name: "แก่งกระจาน ป่าเด็ง เพชรบุรี 76170",
    name_th: "แก่งกระจาน ป่าเด็ง เพชรบุรี 76170",
    name_en: "Kaeng Krachan, Pa Deng, Phetchaburi, 76170",
  },
  {
    id: 760805,
    districtTh: "แก่งกระจาน",
    districtEn: "Kaeng Krachan",
    subdistrictTh: "พุสวรรค์",
    subdistrictEn: "Phu Sawan",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76170,
    district_code: 7608,
    province_code: 61,
    name: "แก่งกระจาน พุสวรรค์ เพชรบุรี 76170",
    name_th: "แก่งกระจาน พุสวรรค์ เพชรบุรี 76170",
    name_en: "Kaeng Krachan, Phu Sawan, Phetchaburi, 76170",
  },
  {
    id: 760806,
    districtTh: "แก่งกระจาน",
    districtEn: "Kaeng Krachan",
    subdistrictTh: "ห้วยแม่เพรียง",
    subdistrictEn: "Huai Mae Phriang",
    provinceTh: "เพชรบุรี",
    provinceEn: "Phetchaburi",
    zipcode: 76170,
    district_code: 7608,
    province_code: 61,
    name: "แก่งกระจาน ห้วยแม่เพรียง เพชรบุรี 76170",
    name_th: "แก่งกระจาน ห้วยแม่เพรียง เพชรบุรี 76170",
    name_en: "Kaeng Krachan, Huai Mae Phriang, Phetchaburi, 76170",
  },
  {
    id: 770101,
    districtTh: "เมืองประจวบคีรีขันธ์",
    districtEn: "Mueang Prachuap Khiri Khan",
    subdistrictTh: "ประจวบคีรีขันธ์",
    subdistrictEn: "Prachuap Khiri Khan",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77000,
    district_code: 7701,
    province_code: 62,
    name: "เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
    name_th: "เมืองประจวบคีรีขันธ์ ประจวบคีรีขันธ์ ประจวบคีรีขันธ์ 77000",
    name_en:
      "Mueang Prachuap Khiri Khan, Prachuap Khiri Khan, Prachuap Khiri Khan, 77000",
  },
  {
    id: 770102,
    districtTh: "เมืองประจวบคีรีขันธ์",
    districtEn: "Mueang Prachuap Khiri Khan",
    subdistrictTh: "เกาะหลัก",
    subdistrictEn: "Ko Lak",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77000,
    district_code: 7701,
    province_code: 62,
    name: "เมืองประจวบคีรีขันธ์ เกาะหลัก ประจวบคีรีขันธ์ 77000",
    name_th: "เมืองประจวบคีรีขันธ์ เกาะหลัก ประจวบคีรีขันธ์ 77000",
    name_en: "Mueang Prachuap Khiri Khan, Ko Lak, Prachuap Khiri Khan, 77000",
  },
  {
    id: 770103,
    districtTh: "เมืองประจวบคีรีขันธ์",
    districtEn: "Mueang Prachuap Khiri Khan",
    subdistrictTh: "คลองวาฬ",
    subdistrictEn: "Khlong Wan",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77000,
    district_code: 7701,
    province_code: 62,
    name: "เมืองประจวบคีรีขันธ์ คลองวาฬ ประจวบคีรีขันธ์ 77000",
    name_th: "เมืองประจวบคีรีขันธ์ คลองวาฬ ประจวบคีรีขันธ์ 77000",
    name_en:
      "Mueang Prachuap Khiri Khan, Khlong Wan, Prachuap Khiri Khan, 77000",
  },
  {
    id: 770104,
    districtTh: "เมืองประจวบคีรีขันธ์",
    districtEn: "Mueang Prachuap Khiri Khan",
    subdistrictTh: "ห้วยทราย",
    subdistrictEn: "Huai Sai",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77000,
    district_code: 7701,
    province_code: 62,
    name: "เมืองประจวบคีรีขันธ์ ห้วยทราย ประจวบคีรีขันธ์ 77000",
    name_th: "เมืองประจวบคีรีขันธ์ ห้วยทราย ประจวบคีรีขันธ์ 77000",
    name_en: "Mueang Prachuap Khiri Khan, Huai Sai, Prachuap Khiri Khan, 77000",
  },
  {
    id: 770105,
    districtTh: "เมืองประจวบคีรีขันธ์",
    districtEn: "Mueang Prachuap Khiri Khan",
    subdistrictTh: "อ่าวน้อย",
    subdistrictEn: "Ao Noi",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77000,
    district_code: 7701,
    province_code: 62,
    name: "เมืองประจวบคีรีขันธ์ อ่าวน้อย ประจวบคีรีขันธ์ 77000",
    name_th: "เมืองประจวบคีรีขันธ์ อ่าวน้อย ประจวบคีรีขันธ์ 77000",
    name_en: "Mueang Prachuap Khiri Khan, Ao Noi, Prachuap Khiri Khan, 77000",
  },
  {
    id: 770106,
    districtTh: "เมืองประจวบคีรีขันธ์",
    districtEn: "Mueang Prachuap Khiri Khan",
    subdistrictTh: "บ่อนอก",
    subdistrictEn: "Bo Nok",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77210,
    district_code: 7701,
    province_code: 62,
    name: "เมืองประจวบคีรีขันธ์ บ่อนอก ประจวบคีรีขันธ์ 77210",
    name_th: "เมืองประจวบคีรีขันธ์ บ่อนอก ประจวบคีรีขันธ์ 77210",
    name_en: "Mueang Prachuap Khiri Khan, Bo Nok, Prachuap Khiri Khan, 77210",
  },
  {
    id: 770201,
    districtTh: "กุยบุรี",
    districtEn: "Kui Buri",
    subdistrictTh: "กุยบุรี",
    subdistrictEn: "Kui Buri",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77150,
    district_code: 7702,
    province_code: 62,
    name: "กุยบุรี กุยบุรี ประจวบคีรีขันธ์ 77150",
    name_th: "กุยบุรี กุยบุรี ประจวบคีรีขันธ์ 77150",
    name_en: "Kui Buri, Kui Buri, Prachuap Khiri Khan, 77150",
  },
  {
    id: 770202,
    districtTh: "กุยบุรี",
    districtEn: "Kui Buri",
    subdistrictTh: "กุยเหนือ",
    subdistrictEn: "Kui Nuea",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77150,
    district_code: 7702,
    province_code: 62,
    name: "กุยบุรี กุยเหนือ ประจวบคีรีขันธ์ 77150",
    name_th: "กุยบุรี กุยเหนือ ประจวบคีรีขันธ์ 77150",
    name_en: "Kui Buri, Kui Nuea, Prachuap Khiri Khan, 77150",
  },
  {
    id: 770203,
    districtTh: "กุยบุรี",
    districtEn: "Kui Buri",
    subdistrictTh: "เขาแดง",
    subdistrictEn: "Khao Daeng",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77150,
    district_code: 7702,
    province_code: 62,
    name: "กุยบุรี เขาแดง ประจวบคีรีขันธ์ 77150",
    name_th: "กุยบุรี เขาแดง ประจวบคีรีขันธ์ 77150",
    name_en: "Kui Buri, Khao Daeng, Prachuap Khiri Khan, 77150",
  },
  {
    id: 770204,
    districtTh: "กุยบุรี",
    districtEn: "Kui Buri",
    subdistrictTh: "ดอนยายหนู",
    subdistrictEn: "Don Yai Nu",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77150,
    district_code: 7702,
    province_code: 62,
    name: "กุยบุรี ดอนยายหนู ประจวบคีรีขันธ์ 77150",
    name_th: "กุยบุรี ดอนยายหนู ประจวบคีรีขันธ์ 77150",
    name_en: "Kui Buri, Don Yai Nu, Prachuap Khiri Khan, 77150",
  },
  {
    id: 770206,
    districtTh: "กุยบุรี",
    districtEn: "Kui Buri",
    subdistrictTh: "สามกระทาย",
    subdistrictEn: "Sam Krathai",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77150,
    district_code: 7702,
    province_code: 62,
    name: "กุยบุรี สามกระทาย ประจวบคีรีขันธ์ 77150",
    name_th: "กุยบุรี สามกระทาย ประจวบคีรีขันธ์ 77150",
    name_en: "Kui Buri, Sam Krathai, Prachuap Khiri Khan, 77150",
  },
  {
    id: 770207,
    districtTh: "กุยบุรี",
    districtEn: "Kui Buri",
    subdistrictTh: "หาดขาม",
    subdistrictEn: "Hat Kham",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77150,
    district_code: 7702,
    province_code: 62,
    name: "กุยบุรี หาดขาม ประจวบคีรีขันธ์ 77150",
    name_th: "กุยบุรี หาดขาม ประจวบคีรีขันธ์ 77150",
    name_en: "Kui Buri, Hat Kham, Prachuap Khiri Khan, 77150",
  },
  {
    id: 770301,
    districtTh: "ทับสะแก",
    districtEn: "Thap Sakae",
    subdistrictTh: "ทับสะแก",
    subdistrictEn: "Thap Sakae",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77130,
    district_code: 7703,
    province_code: 62,
    name: "ทับสะแก ทับสะแก ประจวบคีรีขันธ์ 77130",
    name_th: "ทับสะแก ทับสะแก ประจวบคีรีขันธ์ 77130",
    name_en: "Thap Sakae, Thap Sakae, Prachuap Khiri Khan, 77130",
  },
  {
    id: 770302,
    districtTh: "ทับสะแก",
    districtEn: "Thap Sakae",
    subdistrictTh: "อ่างทอง",
    subdistrictEn: "Ang Thong",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77130,
    district_code: 7703,
    province_code: 62,
    name: "ทับสะแก อ่างทอง ประจวบคีรีขันธ์ 77130",
    name_th: "ทับสะแก อ่างทอง ประจวบคีรีขันธ์ 77130",
    name_en: "Thap Sakae, Ang Thong, Prachuap Khiri Khan, 77130",
  },
  {
    id: 770303,
    districtTh: "ทับสะแก",
    districtEn: "Thap Sakae",
    subdistrictTh: "นาหูกวาง",
    subdistrictEn: "Na Hukwang",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77130,
    district_code: 7703,
    province_code: 62,
    name: "ทับสะแก นาหูกวาง ประจวบคีรีขันธ์ 77130",
    name_th: "ทับสะแก นาหูกวาง ประจวบคีรีขันธ์ 77130",
    name_en: "Thap Sakae, Na Hukwang, Prachuap Khiri Khan, 77130",
  },
  {
    id: 770304,
    districtTh: "ทับสะแก",
    districtEn: "Thap Sakae",
    subdistrictTh: "เขาล้าน",
    subdistrictEn: "Khao Lan",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77130,
    district_code: 7703,
    province_code: 62,
    name: "ทับสะแก เขาล้าน ประจวบคีรีขันธ์ 77130",
    name_th: "ทับสะแก เขาล้าน ประจวบคีรีขันธ์ 77130",
    name_en: "Thap Sakae, Khao Lan, Prachuap Khiri Khan, 77130",
  },
  {
    id: 770305,
    districtTh: "ทับสะแก",
    districtEn: "Thap Sakae",
    subdistrictTh: "ห้วยยาง",
    subdistrictEn: "Huai Yang",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77130,
    district_code: 7703,
    province_code: 62,
    name: "ทับสะแก ห้วยยาง ประจวบคีรีขันธ์ 77130",
    name_th: "ทับสะแก ห้วยยาง ประจวบคีรีขันธ์ 77130",
    name_en: "Thap Sakae, Huai Yang, Prachuap Khiri Khan, 77130",
  },
  {
    id: 770306,
    districtTh: "ทับสะแก",
    districtEn: "Thap Sakae",
    subdistrictTh: "แสงอรุณ",
    subdistrictEn: "Saeng Arun",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77130,
    district_code: 7703,
    province_code: 62,
    name: "ทับสะแก แสงอรุณ ประจวบคีรีขันธ์ 77130",
    name_th: "ทับสะแก แสงอรุณ ประจวบคีรีขันธ์ 77130",
    name_en: "Thap Sakae, Saeng Arun, Prachuap Khiri Khan, 77130",
  },
  {
    id: 770401,
    districtTh: "บางสะพาน",
    districtEn: "Bang Saphan",
    subdistrictTh: "กำเนิดนพคุณ",
    subdistrictEn: "Kamnoet Nopphakhun",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77140,
    district_code: 7704,
    province_code: 62,
    name: "บางสะพาน กำเนิดนพคุณ ประจวบคีรีขันธ์ 77140",
    name_th: "บางสะพาน กำเนิดนพคุณ ประจวบคีรีขันธ์ 77140",
    name_en: "Bang Saphan, Kamnoet Nopphakhun, Prachuap Khiri Khan, 77140",
  },
  {
    id: 770402,
    districtTh: "บางสะพาน",
    districtEn: "Bang Saphan",
    subdistrictTh: "พงศ์ประศาสน์",
    subdistrictEn: "Phong Prasat",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77140,
    district_code: 7704,
    province_code: 62,
    name: "บางสะพาน พงศ์ประศาสน์ ประจวบคีรีขันธ์ 77140",
    name_th: "บางสะพาน พงศ์ประศาสน์ ประจวบคีรีขันธ์ 77140",
    name_en: "Bang Saphan, Phong Prasat, Prachuap Khiri Khan, 77140",
  },
  {
    id: 770403,
    districtTh: "บางสะพาน",
    districtEn: "Bang Saphan",
    subdistrictTh: "ร่อนทอง",
    subdistrictEn: "Ron Thong",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77230,
    district_code: 7704,
    province_code: 62,
    name: "บางสะพาน ร่อนทอง ประจวบคีรีขันธ์ 77230",
    name_th: "บางสะพาน ร่อนทอง ประจวบคีรีขันธ์ 77230",
    name_en: "Bang Saphan, Ron Thong, Prachuap Khiri Khan, 77230",
  },
  {
    id: 770404,
    districtTh: "บางสะพาน",
    districtEn: "Bang Saphan",
    subdistrictTh: "ธงชัย",
    subdistrictEn: "Thong Chai",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77190,
    district_code: 7704,
    province_code: 62,
    name: "บางสะพาน ธงชัย ประจวบคีรีขันธ์ 77190",
    name_th: "บางสะพาน ธงชัย ประจวบคีรีขันธ์ 77190",
    name_en: "Bang Saphan, Thong Chai, Prachuap Khiri Khan, 77190",
  },
  {
    id: 770405,
    districtTh: "บางสะพาน",
    districtEn: "Bang Saphan",
    subdistrictTh: "ชัยเกษม",
    subdistrictEn: "Chai Kasem",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77190,
    district_code: 7704,
    province_code: 62,
    name: "บางสะพาน ชัยเกษม ประจวบคีรีขันธ์ 77190",
    name_th: "บางสะพาน ชัยเกษม ประจวบคีรีขันธ์ 77190",
    name_en: "Bang Saphan, Chai Kasem, Prachuap Khiri Khan, 77190",
  },
  {
    id: 770406,
    districtTh: "บางสะพาน",
    districtEn: "Bang Saphan",
    subdistrictTh: "ทองมงคล",
    subdistrictEn: "Thong Mongkhon",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77230,
    district_code: 7704,
    province_code: 62,
    name: "บางสะพาน ทองมงคล ประจวบคีรีขันธ์ 77230",
    name_th: "บางสะพาน ทองมงคล ประจวบคีรีขันธ์ 77230",
    name_en: "Bang Saphan, Thong Mongkhon, Prachuap Khiri Khan, 77230",
  },
  {
    id: 770407,
    districtTh: "บางสะพาน",
    districtEn: "Bang Saphan",
    subdistrictTh: "แม่รำพึง",
    subdistrictEn: "Mae Ramphueng",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77140,
    district_code: 7704,
    province_code: 62,
    name: "บางสะพาน แม่รำพึง ประจวบคีรีขันธ์ 77140",
    name_th: "บางสะพาน แม่รำพึง ประจวบคีรีขันธ์ 77140",
    name_en: "Bang Saphan, Mae Ramphueng, Prachuap Khiri Khan, 77140",
  },
  {
    id: 770501,
    districtTh: "บางสะพานน้อย",
    districtEn: "Bang Saphan Noi",
    subdistrictTh: "ปากแพรก",
    subdistrictEn: "Pak Phraek",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77170,
    district_code: 7705,
    province_code: 62,
    name: "บางสะพานน้อย ปากแพรก ประจวบคีรีขันธ์ 77170",
    name_th: "บางสะพานน้อย ปากแพรก ประจวบคีรีขันธ์ 77170",
    name_en: "Bang Saphan Noi, Pak Phraek, Prachuap Khiri Khan, 77170",
  },
  {
    id: 770502,
    districtTh: "บางสะพานน้อย",
    districtEn: "Bang Saphan Noi",
    subdistrictTh: "บางสะพาน",
    subdistrictEn: "Bang Saphan",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77170,
    district_code: 7705,
    province_code: 62,
    name: "บางสะพานน้อย บางสะพาน ประจวบคีรีขันธ์ 77170",
    name_th: "บางสะพานน้อย บางสะพาน ประจวบคีรีขันธ์ 77170",
    name_en: "Bang Saphan Noi, Bang Saphan, Prachuap Khiri Khan, 77170",
  },
  {
    id: 770503,
    districtTh: "บางสะพานน้อย",
    districtEn: "Bang Saphan Noi",
    subdistrictTh: "ทรายทอง",
    subdistrictEn: "Sai Thong",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77170,
    district_code: 7705,
    province_code: 62,
    name: "บางสะพานน้อย ทรายทอง ประจวบคีรีขันธ์ 77170",
    name_th: "บางสะพานน้อย ทรายทอง ประจวบคีรีขันธ์ 77170",
    name_en: "Bang Saphan Noi, Sai Thong, Prachuap Khiri Khan, 77170",
  },
  {
    id: 770504,
    districtTh: "บางสะพานน้อย",
    districtEn: "Bang Saphan Noi",
    subdistrictTh: "ช้างแรก",
    subdistrictEn: "Chang Raek",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77170,
    district_code: 7705,
    province_code: 62,
    name: "บางสะพานน้อย ช้างแรก ประจวบคีรีขันธ์ 77170",
    name_th: "บางสะพานน้อย ช้างแรก ประจวบคีรีขันธ์ 77170",
    name_en: "Bang Saphan Noi, Chang Raek, Prachuap Khiri Khan, 77170",
  },
  {
    id: 770505,
    districtTh: "บางสะพานน้อย",
    districtEn: "Bang Saphan Noi",
    subdistrictTh: "ไชยราช",
    subdistrictEn: "Chaiyarat",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77170,
    district_code: 7705,
    province_code: 62,
    name: "บางสะพานน้อย ไชยราช ประจวบคีรีขันธ์ 77170",
    name_th: "บางสะพานน้อย ไชยราช ประจวบคีรีขันธ์ 77170",
    name_en: "Bang Saphan Noi, Chaiyarat, Prachuap Khiri Khan, 77170",
  },
  {
    id: 770601,
    districtTh: "ปราณบุรี",
    districtEn: "Pran Buri",
    subdistrictTh: "ปราณบุรี",
    subdistrictEn: "Pran Buri",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77120,
    district_code: 7706,
    province_code: 62,
    name: "ปราณบุรี ปราณบุรี ประจวบคีรีขันธ์ 77120",
    name_th: "ปราณบุรี ปราณบุรี ประจวบคีรีขันธ์ 77120",
    name_en: "Pran Buri, Pran Buri, Prachuap Khiri Khan, 77120",
  },
  {
    id: 770602,
    districtTh: "ปราณบุรี",
    districtEn: "Pran Buri",
    subdistrictTh: "เขาน้อย",
    subdistrictEn: "Khao Noi",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77120,
    district_code: 7706,
    province_code: 62,
    name: "ปราณบุรี เขาน้อย ประจวบคีรีขันธ์ 77120",
    name_th: "ปราณบุรี เขาน้อย ประจวบคีรีขันธ์ 77120",
    name_en: "Pran Buri, Khao Noi, Prachuap Khiri Khan, 77120",
  },
  {
    id: 770604,
    districtTh: "ปราณบุรี",
    districtEn: "Pran Buri",
    subdistrictTh: "ปากน้ำปราณ",
    subdistrictEn: "Pak Nam Pran",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77220,
    district_code: 7706,
    province_code: 62,
    name: "ปราณบุรี ปากน้ำปราณ ประจวบคีรีขันธ์ 77220",
    name_th: "ปราณบุรี ปากน้ำปราณ ประจวบคีรีขันธ์ 77220",
    name_en: "Pran Buri, Pak Nam Pran, Prachuap Khiri Khan, 77220",
  },
  {
    id: 770607,
    districtTh: "ปราณบุรี",
    districtEn: "Pran Buri",
    subdistrictTh: "หนองตาแต้ม",
    subdistrictEn: "Nong Ta Taem",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77120,
    district_code: 7706,
    province_code: 62,
    name: "ปราณบุรี หนองตาแต้ม ประจวบคีรีขันธ์ 77120",
    name_th: "ปราณบุรี หนองตาแต้ม ประจวบคีรีขันธ์ 77120",
    name_en: "Pran Buri, Nong Ta Taem, Prachuap Khiri Khan, 77120",
  },
  {
    id: 770608,
    districtTh: "ปราณบุรี",
    districtEn: "Pran Buri",
    subdistrictTh: "วังก์พง",
    subdistrictEn: "Wang Phong",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77120,
    district_code: 7706,
    province_code: 62,
    name: "ปราณบุรี วังก์พง ประจวบคีรีขันธ์ 77120",
    name_th: "ปราณบุรี วังก์พง ประจวบคีรีขันธ์ 77120",
    name_en: "Pran Buri, Wang Phong, Prachuap Khiri Khan, 77120",
  },
  {
    id: 770609,
    districtTh: "ปราณบุรี",
    districtEn: "Pran Buri",
    subdistrictTh: "เขาจ้าว",
    subdistrictEn: "Khao Chao",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77120,
    district_code: 7706,
    province_code: 62,
    name: "ปราณบุรี เขาจ้าว ประจวบคีรีขันธ์ 77120",
    name_th: "ปราณบุรี เขาจ้าว ประจวบคีรีขันธ์ 77120",
    name_en: "Pran Buri, Khao Chao, Prachuap Khiri Khan, 77120",
  },
  {
    id: 770701,
    districtTh: "หัวหิน",
    districtEn: "Hua Hin",
    subdistrictTh: "หัวหิน",
    subdistrictEn: "Hua Hin",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77110,
    district_code: 7707,
    province_code: 62,
    name: "หัวหิน หัวหิน ประจวบคีรีขันธ์ 77110",
    name_th: "หัวหิน หัวหิน ประจวบคีรีขันธ์ 77110",
    name_en: "Hua Hin, Hua Hin, Prachuap Khiri Khan, 77110",
  },
  {
    id: 770702,
    districtTh: "หัวหิน",
    districtEn: "Hua Hin",
    subdistrictTh: "หนองแก",
    subdistrictEn: "Nong Kae",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77110,
    district_code: 7707,
    province_code: 62,
    name: "หัวหิน หนองแก ประจวบคีรีขันธ์ 77110",
    name_th: "หัวหิน หนองแก ประจวบคีรีขันธ์ 77110",
    name_en: "Hua Hin, Nong Kae, Prachuap Khiri Khan, 77110",
  },
  {
    id: 770703,
    districtTh: "หัวหิน",
    districtEn: "Hua Hin",
    subdistrictTh: "หินเหล็กไฟ",
    subdistrictEn: "Hin Lek Fai",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77110,
    district_code: 7707,
    province_code: 62,
    name: "หัวหิน หินเหล็กไฟ ประจวบคีรีขันธ์ 77110",
    name_th: "หัวหิน หินเหล็กไฟ ประจวบคีรีขันธ์ 77110",
    name_en: "Hua Hin, Hin Lek Fai, Prachuap Khiri Khan, 77110",
  },
  {
    id: 770704,
    districtTh: "หัวหิน",
    districtEn: "Hua Hin",
    subdistrictTh: "หนองพลับ",
    subdistrictEn: "Nong Phlap",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77110,
    district_code: 7707,
    province_code: 62,
    name: "หัวหิน หนองพลับ ประจวบคีรีขันธ์ 77110",
    name_th: "หัวหิน หนองพลับ ประจวบคีรีขันธ์ 77110",
    name_en: "Hua Hin, Nong Phlap, Prachuap Khiri Khan, 77110",
  },
  {
    id: 770705,
    districtTh: "หัวหิน",
    districtEn: "Hua Hin",
    subdistrictTh: "ทับใต้",
    subdistrictEn: "Thap Tai",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77110,
    district_code: 7707,
    province_code: 62,
    name: "หัวหิน ทับใต้ ประจวบคีรีขันธ์ 77110",
    name_th: "หัวหิน ทับใต้ ประจวบคีรีขันธ์ 77110",
    name_en: "Hua Hin, Thap Tai, Prachuap Khiri Khan, 77110",
  },
  {
    id: 770706,
    districtTh: "หัวหิน",
    districtEn: "Hua Hin",
    subdistrictTh: "ห้วยสัตว์ใหญ่",
    subdistrictEn: "Huai Sat Yai",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77110,
    district_code: 7707,
    province_code: 62,
    name: "หัวหิน ห้วยสัตว์ใหญ่ ประจวบคีรีขันธ์ 77110",
    name_th: "หัวหิน ห้วยสัตว์ใหญ่ ประจวบคีรีขันธ์ 77110",
    name_en: "Hua Hin, Huai Sat Yai, Prachuap Khiri Khan, 77110",
  },
  {
    id: 770707,
    districtTh: "หัวหิน",
    districtEn: "Hua Hin",
    subdistrictTh: "บึงนคร",
    subdistrictEn: "Bueng Nakhon",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77110,
    district_code: 7707,
    province_code: 62,
    name: "หัวหิน บึงนคร ประจวบคีรีขันธ์ 77110",
    name_th: "หัวหิน บึงนคร ประจวบคีรีขันธ์ 77110",
    name_en: "Hua Hin, Bueng Nakhon, Prachuap Khiri Khan, 77110",
  },
  {
    id: 770801,
    districtTh: "สามร้อยยอด",
    districtEn: "Sam Roi Yot",
    subdistrictTh: "สามร้อยยอด",
    subdistrictEn: "Sam Roi Yot",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77120,
    district_code: 7708,
    province_code: 62,
    name: "สามร้อยยอด สามร้อยยอด ประจวบคีรีขันธ์ 77120",
    name_th: "สามร้อยยอด สามร้อยยอด ประจวบคีรีขันธ์ 77120",
    name_en: "Sam Roi Yot, Sam Roi Yot, Prachuap Khiri Khan, 77120",
  },
  {
    id: 770802,
    districtTh: "สามร้อยยอด",
    districtEn: "Sam Roi Yot",
    subdistrictTh: "ศิลาลอย",
    subdistrictEn: "Sila Loi",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77180,
    district_code: 7708,
    province_code: 62,
    name: "สามร้อยยอด ศิลาลอย ประจวบคีรีขันธ์ 77180",
    name_th: "สามร้อยยอด ศิลาลอย ประจวบคีรีขันธ์ 77180",
    name_en: "Sam Roi Yot, Sila Loi, Prachuap Khiri Khan, 77180",
  },
  {
    id: 770803,
    districtTh: "สามร้อยยอด",
    districtEn: "Sam Roi Yot",
    subdistrictTh: "ไร่เก่า",
    subdistrictEn: "Rai Kao",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77180,
    district_code: 7708,
    province_code: 62,
    name: "สามร้อยยอด ไร่เก่า ประจวบคีรีขันธ์ 77180",
    name_th: "สามร้อยยอด ไร่เก่า ประจวบคีรีขันธ์ 77180",
    name_en: "Sam Roi Yot, Rai Kao, Prachuap Khiri Khan, 77180",
  },
  {
    id: 770804,
    districtTh: "สามร้อยยอด",
    districtEn: "Sam Roi Yot",
    subdistrictTh: "ศาลาลัย",
    subdistrictEn: "Salalai",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77180,
    district_code: 7708,
    province_code: 62,
    name: "สามร้อยยอด ศาลาลัย ประจวบคีรีขันธ์ 77180",
    name_th: "สามร้อยยอด ศาลาลัย ประจวบคีรีขันธ์ 77180",
    name_en: "Sam Roi Yot, Salalai, Prachuap Khiri Khan, 77180",
  },
  {
    id: 770805,
    districtTh: "สามร้อยยอด",
    districtEn: "Sam Roi Yot",
    subdistrictTh: "ไร่ใหม่",
    subdistrictEn: "Rai Mai",
    provinceTh: "ประจวบคีรีขันธ์",
    provinceEn: "Prachuap Khiri Khan",
    zipcode: 77180,
    district_code: 7708,
    province_code: 62,
    name: "สามร้อยยอด ไร่ใหม่ ประจวบคีรีขันธ์ 77180",
    name_th: "สามร้อยยอด ไร่ใหม่ ประจวบคีรีขันธ์ 77180",
    name_en: "Sam Roi Yot, Rai Mai, Prachuap Khiri Khan, 77180",
  },
  {
    id: 800101,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ในเมือง",
    subdistrictEn: "Nai Mueang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ในเมือง นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช ในเมือง นครศรีธรรมราช 80000",
    name_en:
      "Mueang Nakhon Si Thammarat, Nai Mueang, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800102,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ท่าวัง",
    subdistrictEn: "Tha Wang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ท่าวัง นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช ท่าวัง นครศรีธรรมราช 80000",
    name_en: "Mueang Nakhon Si Thammarat, Tha Wang, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800103,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "คลัง",
    subdistrictEn: "Khlang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช คลัง นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช คลัง นครศรีธรรมราช 80000",
    name_en: "Mueang Nakhon Si Thammarat, Khlang, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800106,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ท่าไร่",
    subdistrictEn: "Tha Rai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ท่าไร่ นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช ท่าไร่ นครศรีธรรมราช 80000",
    name_en: "Mueang Nakhon Si Thammarat, Tha Rai, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800107,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ปากนคร",
    subdistrictEn: "Pak Nakhon",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ปากนคร นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช ปากนคร นครศรีธรรมราช 80000",
    name_en:
      "Mueang Nakhon Si Thammarat, Pak Nakhon, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800108,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "นาทราย",
    subdistrictEn: "Na Sai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80280,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช นาทราย นครศรีธรรมราช 80280",
    name_th: "เมืองนครศรีธรรมราช นาทราย นครศรีธรรมราช 80280",
    name_en: "Mueang Nakhon Si Thammarat, Na Sai, Nakhon Si Thammarat, 80280",
  },
  {
    id: 800112,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "กำแพงเซา",
    subdistrictEn: "Kamphaeng Sao",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80280,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช กำแพงเซา นครศรีธรรมราช 80280",
    name_th: "เมืองนครศรีธรรมราช กำแพงเซา นครศรีธรรมราช 80280",
    name_en:
      "Mueang Nakhon Si Thammarat, Kamphaeng Sao, Nakhon Si Thammarat, 80280",
  },
  {
    id: 800113,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ไชยมนตรี",
    subdistrictEn: "Chai Montri",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ไชยมนตรี นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช ไชยมนตรี นครศรีธรรมราช 80000",
    name_en:
      "Mueang Nakhon Si Thammarat, Chai Montri, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800114,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "มะม่วงสองต้น",
    subdistrictEn: "Mamuang Song Ton",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช มะม่วงสองต้น นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช มะม่วงสองต้น นครศรีธรรมราช 80000",
    name_en:
      "Mueang Nakhon Si Thammarat, Mamuang Song Ton, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800115,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "นาเคียน",
    subdistrictEn: "Na Khian",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช นาเคียน นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช นาเคียน นครศรีธรรมราช 80000",
    name_en: "Mueang Nakhon Si Thammarat, Na Khian, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800116,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ท่างิ้ว",
    subdistrictEn: "Tha Ngio",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80280,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ท่างิ้ว นครศรีธรรมราช 80280",
    name_th: "เมืองนครศรีธรรมราช ท่างิ้ว นครศรีธรรมราช 80280",
    name_en: "Mueang Nakhon Si Thammarat, Tha Ngio, Nakhon Si Thammarat, 80280",
  },
  {
    id: 800118,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "โพธิ์เสด็จ",
    subdistrictEn: "Pho Sadet",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช โพธิ์เสด็จ นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช โพธิ์เสด็จ นครศรีธรรมราช 80000",
    name_en:
      "Mueang Nakhon Si Thammarat, Pho Sadet, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800119,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "บางจาก",
    subdistrictEn: "Bang Chak",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80330,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช บางจาก นครศรีธรรมราช 80330",
    name_th: "เมืองนครศรีธรรมราช บางจาก นครศรีธรรมราช 80330",
    name_en:
      "Mueang Nakhon Si Thammarat, Bang Chak, Nakhon Si Thammarat, 80330",
  },
  {
    id: 800120,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ปากพูน",
    subdistrictEn: "Pak Phun",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ปากพูน นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช ปากพูน นครศรีธรรมราช 80000",
    name_en: "Mueang Nakhon Si Thammarat, Pak Phun, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800121,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ท่าซัก",
    subdistrictEn: "Tha Sak",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ท่าซัก นครศรีธรรมราช 80000",
    name_th: "เมืองนครศรีธรรมราช ท่าซัก นครศรีธรรมราช 80000",
    name_en: "Mueang Nakhon Si Thammarat, Tha Sak, Nakhon Si Thammarat, 80000",
  },
  {
    id: 800122,
    districtTh: "เมืองนครศรีธรรมราช",
    districtEn: "Mueang Nakhon Si Thammarat",
    subdistrictTh: "ท่าเรือ",
    subdistrictEn: "Tha Ruea",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80290,
    district_code: 8001,
    province_code: 63,
    name: "เมืองนครศรีธรรมราช ท่าเรือ นครศรีธรรมราช 80290",
    name_th: "เมืองนครศรีธรรมราช ท่าเรือ นครศรีธรรมราช 80290",
    name_en: "Mueang Nakhon Si Thammarat, Tha Ruea, Nakhon Si Thammarat, 80290",
  },
  {
    id: 800201,
    districtTh: "พรหมคีรี",
    districtEn: "Phrom Khiri",
    subdistrictTh: "พรหมโลก",
    subdistrictEn: "Phrommalok",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80320,
    district_code: 8002,
    province_code: 63,
    name: "พรหมคีรี พรหมโลก นครศรีธรรมราช 80320",
    name_th: "พรหมคีรี พรหมโลก นครศรีธรรมราช 80320",
    name_en: "Phrom Khiri, Phrommalok, Nakhon Si Thammarat, 80320",
  },
  {
    id: 800202,
    districtTh: "พรหมคีรี",
    districtEn: "Phrom Khiri",
    subdistrictTh: "บ้านเกาะ",
    subdistrictEn: "Ban Ko",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80320,
    district_code: 8002,
    province_code: 63,
    name: "พรหมคีรี บ้านเกาะ นครศรีธรรมราช 80320",
    name_th: "พรหมคีรี บ้านเกาะ นครศรีธรรมราช 80320",
    name_en: "Phrom Khiri, Ban Ko, Nakhon Si Thammarat, 80320",
  },
  {
    id: 800203,
    districtTh: "พรหมคีรี",
    districtEn: "Phrom Khiri",
    subdistrictTh: "อินคีรี",
    subdistrictEn: "In Khiri",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80320,
    district_code: 8002,
    province_code: 63,
    name: "พรหมคีรี อินคีรี นครศรีธรรมราช 80320",
    name_th: "พรหมคีรี อินคีรี นครศรีธรรมราช 80320",
    name_en: "Phrom Khiri, In Khiri, Nakhon Si Thammarat, 80320",
  },
  {
    id: 800204,
    districtTh: "พรหมคีรี",
    districtEn: "Phrom Khiri",
    subdistrictTh: "ทอนหงส์",
    subdistrictEn: "Thon Hong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80320,
    district_code: 8002,
    province_code: 63,
    name: "พรหมคีรี ทอนหงส์ นครศรีธรรมราช 80320",
    name_th: "พรหมคีรี ทอนหงส์ นครศรีธรรมราช 80320",
    name_en: "Phrom Khiri, Thon Hong, Nakhon Si Thammarat, 80320",
  },
  {
    id: 800205,
    districtTh: "พรหมคีรี",
    districtEn: "Phrom Khiri",
    subdistrictTh: "นาเรียง",
    subdistrictEn: "Na Reang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80320,
    district_code: 8002,
    province_code: 63,
    name: "พรหมคีรี นาเรียง นครศรีธรรมราช 80320",
    name_th: "พรหมคีรี นาเรียง นครศรีธรรมราช 80320",
    name_en: "Phrom Khiri, Na Reang, Nakhon Si Thammarat, 80320",
  },
  {
    id: 800301,
    districtTh: "ลานสกา",
    districtEn: "Lan Saka",
    subdistrictTh: "เขาแก้ว",
    subdistrictEn: "Khao Kaeo",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80230,
    district_code: 8003,
    province_code: 63,
    name: "ลานสกา เขาแก้ว นครศรีธรรมราช 80230",
    name_th: "ลานสกา เขาแก้ว นครศรีธรรมราช 80230",
    name_en: "Lan Saka, Khao Kaeo, Nakhon Si Thammarat, 80230",
  },
  {
    id: 800302,
    districtTh: "ลานสกา",
    districtEn: "Lan Saka",
    subdistrictTh: "ลานสกา",
    subdistrictEn: "Lan Saka",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80230,
    district_code: 8003,
    province_code: 63,
    name: "ลานสกา ลานสกา นครศรีธรรมราช 80230",
    name_th: "ลานสกา ลานสกา นครศรีธรรมราช 80230",
    name_en: "Lan Saka, Lan Saka, Nakhon Si Thammarat, 80230",
  },
  {
    id: 800303,
    districtTh: "ลานสกา",
    districtEn: "Lan Saka",
    subdistrictTh: "ท่าดี",
    subdistrictEn: "Tha Di",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80230,
    district_code: 8003,
    province_code: 63,
    name: "ลานสกา ท่าดี นครศรีธรรมราช 80230",
    name_th: "ลานสกา ท่าดี นครศรีธรรมราช 80230",
    name_en: "Lan Saka, Tha Di, Nakhon Si Thammarat, 80230",
  },
  {
    id: 800304,
    districtTh: "ลานสกา",
    districtEn: "Lan Saka",
    subdistrictTh: "กำโลน",
    subdistrictEn: "Kamlon",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80230,
    district_code: 8003,
    province_code: 63,
    name: "ลานสกา กำโลน นครศรีธรรมราช 80230",
    name_th: "ลานสกา กำโลน นครศรีธรรมราช 80230",
    name_en: "Lan Saka, Kamlon, Nakhon Si Thammarat, 80230",
  },
  {
    id: 800305,
    districtTh: "ลานสกา",
    districtEn: "Lan Saka",
    subdistrictTh: "ขุนทะเล",
    subdistrictEn: "Khun Thale",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80230,
    district_code: 8003,
    province_code: 63,
    name: "ลานสกา ขุนทะเล นครศรีธรรมราช 80230",
    name_th: "ลานสกา ขุนทะเล นครศรีธรรมราช 80230",
    name_en: "Lan Saka, Khun Thale, Nakhon Si Thammarat, 80230",
  },
  {
    id: 800401,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "ฉวาง",
    subdistrictEn: "Chawang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80150,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง ฉวาง นครศรีธรรมราช 80150",
    name_th: "ฉวาง ฉวาง นครศรีธรรมราช 80150",
    name_en: "Chawang, Chawang, Nakhon Si Thammarat, 80150",
  },
  {
    id: 800403,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "ละอาย",
    subdistrictEn: "La-ai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80250,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง ละอาย นครศรีธรรมราช 80250",
    name_th: "ฉวาง ละอาย นครศรีธรรมราช 80250",
    name_en: "Chawang, La-ai, Nakhon Si Thammarat, 80250",
  },
  {
    id: 800404,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "นาแว",
    subdistrictEn: "Na Wae",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80260,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง นาแว นครศรีธรรมราช 80260",
    name_th: "ฉวาง นาแว นครศรีธรรมราช 80260",
    name_en: "Chawang, Na Wae, Nakhon Si Thammarat, 80260",
  },
  {
    id: 800405,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "ไม้เรียง",
    subdistrictEn: "Mai Riang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80150,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง ไม้เรียง นครศรีธรรมราช 80150",
    name_th: "ฉวาง ไม้เรียง นครศรีธรรมราช 80150",
    name_en: "Chawang, Mai Riang, Nakhon Si Thammarat, 80150",
  },
  {
    id: 800406,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "กะเปียด",
    subdistrictEn: "Kapiat",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80260,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง กะเปียด นครศรีธรรมราช 80260",
    name_th: "ฉวาง กะเปียด นครศรีธรรมราช 80260",
    name_en: "Chawang, Kapiat, Nakhon Si Thammarat, 80260",
  },
  {
    id: 800407,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "นากะชะ",
    subdistrictEn: "Na Kacha",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80150,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง นากะชะ นครศรีธรรมราช 80150",
    name_th: "ฉวาง นากะชะ นครศรีธรรมราช 80150",
    name_en: "Chawang, Na Kacha, Nakhon Si Thammarat, 80150",
  },
  {
    id: 800409,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "ห้วยปริก",
    subdistrictEn: "Huai Prik",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80260,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง ห้วยปริก นครศรีธรรมราช 80260",
    name_th: "ฉวาง ห้วยปริก นครศรีธรรมราช 80260",
    name_en: "Chawang, Huai Prik, Nakhon Si Thammarat, 80260",
  },
  {
    id: 800410,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "ไสหร้า",
    subdistrictEn: "Saira",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80150,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง ไสหร้า นครศรีธรรมราช 80150",
    name_th: "ฉวาง ไสหร้า นครศรีธรรมราช 80150",
    name_en: "Chawang, Saira, Nakhon Si Thammarat, 80150",
  },
  {
    id: 800415,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "นาเขลียง",
    subdistrictEn: "Na Khliang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80260,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง นาเขลียง นครศรีธรรมราช 80260",
    name_th: "ฉวาง นาเขลียง นครศรีธรรมราช 80260",
    name_en: "Chawang, Na Khliang, Nakhon Si Thammarat, 80260",
  },
  {
    id: 800416,
    districtTh: "ฉวาง",
    districtEn: "Chawang",
    subdistrictTh: "จันดี",
    subdistrictEn: "Chan Di",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80250,
    district_code: 8004,
    province_code: 63,
    name: "ฉวาง จันดี นครศรีธรรมราช 80250",
    name_th: "ฉวาง จันดี นครศรีธรรมราช 80250",
    name_en: "Chawang, Chan Di, Nakhon Si Thammarat, 80250",
  },
  {
    id: 800501,
    districtTh: "พิปูน",
    districtEn: "Phipun",
    subdistrictTh: "พิปูน",
    subdistrictEn: "Phipun",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80270,
    district_code: 8005,
    province_code: 63,
    name: "พิปูน พิปูน นครศรีธรรมราช 80270",
    name_th: "พิปูน พิปูน นครศรีธรรมราช 80270",
    name_en: "Phipun, Phipun, Nakhon Si Thammarat, 80270",
  },
  {
    id: 800502,
    districtTh: "พิปูน",
    districtEn: "Phipun",
    subdistrictTh: "กะทูน",
    subdistrictEn: "Kathun",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80270,
    district_code: 8005,
    province_code: 63,
    name: "พิปูน กะทูน นครศรีธรรมราช 80270",
    name_th: "พิปูน กะทูน นครศรีธรรมราช 80270",
    name_en: "Phipun, Kathun, Nakhon Si Thammarat, 80270",
  },
  {
    id: 800503,
    districtTh: "พิปูน",
    districtEn: "Phipun",
    subdistrictTh: "เขาพระ",
    subdistrictEn: "Khao Phra",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80270,
    district_code: 8005,
    province_code: 63,
    name: "พิปูน เขาพระ นครศรีธรรมราช 80270",
    name_th: "พิปูน เขาพระ นครศรีธรรมราช 80270",
    name_en: "Phipun, Khao Phra, Nakhon Si Thammarat, 80270",
  },
  {
    id: 800504,
    districtTh: "พิปูน",
    districtEn: "Phipun",
    subdistrictTh: "ยางค้อม",
    subdistrictEn: "Yang Khom",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80270,
    district_code: 8005,
    province_code: 63,
    name: "พิปูน ยางค้อม นครศรีธรรมราช 80270",
    name_th: "พิปูน ยางค้อม นครศรีธรรมราช 80270",
    name_en: "Phipun, Yang Khom, Nakhon Si Thammarat, 80270",
  },
  {
    id: 800505,
    districtTh: "พิปูน",
    districtEn: "Phipun",
    subdistrictTh: "ควนกลาง",
    subdistrictEn: "Khuan Klang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80270,
    district_code: 8005,
    province_code: 63,
    name: "พิปูน ควนกลาง นครศรีธรรมราช 80270",
    name_th: "พิปูน ควนกลาง นครศรีธรรมราช 80270",
    name_en: "Phipun, Khuan Klang, Nakhon Si Thammarat, 80270",
  },
  {
    id: 800601,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "เชียรใหญ่",
    subdistrictEn: "Chian Yai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ เชียรใหญ่ นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ เชียรใหญ่ นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Chian Yai, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800603,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "ท่าขนาน",
    subdistrictEn: "Tha Khanan",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ ท่าขนาน นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ ท่าขนาน นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Tha Khanan, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800604,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ บ้านกลาง นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ บ้านกลาง นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Ban Klang, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800605,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "บ้านเนิน",
    subdistrictEn: "Ban Noen",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ บ้านเนิน นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ บ้านเนิน นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Ban Noen, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800606,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "ไสหมาก",
    subdistrictEn: "Sai Mak",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ ไสหมาก นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ ไสหมาก นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Sai Mak, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800607,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "ท้องลำเจียก",
    subdistrictEn: "Thong Lamchiak",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ ท้องลำเจียก นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ ท้องลำเจียก นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Thong Lamchiak, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800610,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "เสือหึง",
    subdistrictEn: "Suea Hueng",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ เสือหึง นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ เสือหึง นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Suea Hueng, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800611,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "การะเกด",
    subdistrictEn: "Karaket",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ การะเกด นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ การะเกด นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Karaket, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800612,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "เขาพระบาท",
    subdistrictEn: "Khao Phra Bat",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ เขาพระบาท นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ เขาพระบาท นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Khao Phra Bat, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800613,
    districtTh: "เชียรใหญ่",
    districtEn: "Chian Yai",
    subdistrictTh: "แม่เจ้าอยู่หัว",
    subdistrictEn: "Mae Chao Yu Hua",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8006,
    province_code: 63,
    name: "เชียรใหญ่ แม่เจ้าอยู่หัว นครศรีธรรมราช 80190",
    name_th: "เชียรใหญ่ แม่เจ้าอยู่หัว นครศรีธรรมราช 80190",
    name_en: "Chian Yai, Mae Chao Yu Hua, Nakhon Si Thammarat, 80190",
  },
  {
    id: 800701,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "ชะอวด",
    subdistrictEn: "Cha-uat",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด ชะอวด นครศรีธรรมราช 80180",
    name_th: "ชะอวด ชะอวด นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Cha-uat, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800702,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "ท่าเสม็ด",
    subdistrictEn: "Tha Samet",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด ท่าเสม็ด นครศรีธรรมราช 80180",
    name_th: "ชะอวด ท่าเสม็ด นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Tha Samet, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800703,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "ท่าประจะ",
    subdistrictEn: "Tha Pracha",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด ท่าประจะ นครศรีธรรมราช 80180",
    name_th: "ชะอวด ท่าประจะ นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Tha Pracha, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800704,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "เคร็ง",
    subdistrictEn: "Khreng",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด เคร็ง นครศรีธรรมราช 80180",
    name_th: "ชะอวด เคร็ง นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Khreng, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800705,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "วังอ่าง",
    subdistrictEn: "Wang Ang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด วังอ่าง นครศรีธรรมราช 80180",
    name_th: "ชะอวด วังอ่าง นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Wang Ang, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800706,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "บ้านตูล",
    subdistrictEn: "Ban Tun",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด บ้านตูล นครศรีธรรมราช 80180",
    name_th: "ชะอวด บ้านตูล นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Ban Tun, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800707,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "ขอนหาด",
    subdistrictEn: "Khon Hat",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด ขอนหาด นครศรีธรรมราช 80180",
    name_th: "ชะอวด ขอนหาด นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Khon Hat, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800708,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "เกาะขันธ์",
    subdistrictEn: "Khuan Nong Hong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด เกาะขันธ์ นครศรีธรรมราช 80180",
    name_th: "ชะอวด เกาะขันธ์ นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Khuan Nong Hong, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800709,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "ควนหนองหงษ์",
    subdistrictEn: "Khao Phra Thong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด ควนหนองหงษ์ นครศรีธรรมราช 80180",
    name_th: "ชะอวด ควนหนองหงษ์ นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Khao Phra Thong, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800710,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "เขาพระทอง",
    subdistrictEn: "Nang Long",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด เขาพระทอง นครศรีธรรมราช 80180",
    name_th: "ชะอวด เขาพระทอง นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Nang Long, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800711,
    districtTh: "ชะอวด",
    districtEn: "Cha-uat",
    subdistrictTh: "นางหลง",
    subdistrictEn: "Nang Long",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8007,
    province_code: 63,
    name: "ชะอวด นางหลง นครศรีธรรมราช 80180",
    name_th: "ชะอวด นางหลง นครศรีธรรมราช 80180",
    name_en: "Cha-uat, Nang Long, Nakhon Si Thammarat, 80180",
  },
  {
    id: 800801,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "ท่าศาลา",
    subdistrictEn: "Tha Sala",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา ท่าศาลา นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา ท่าศาลา นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Tha Sala, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800802,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "กลาย",
    subdistrictEn: "Klai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา กลาย นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา กลาย นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Klai, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800803,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "ท่าขึ้น",
    subdistrictEn: "Tha Khuen",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา ท่าขึ้น นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา ท่าขึ้น นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Tha Khuen, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800804,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "หัวตะพาน",
    subdistrictEn: "Hua Taphan",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา หัวตะพาน นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา หัวตะพาน นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Hua Taphan, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800806,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "สระแก้ว",
    subdistrictEn: "Sa Kaeo",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา สระแก้ว นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา สระแก้ว นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Sa Kaeo, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800807,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "โมคลาน",
    subdistrictEn: "Mokkhalan",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา โมคลาน นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา โมคลาน นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Mokkhalan, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800809,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "ไทยบุรี",
    subdistrictEn: "Thai buri",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา ไทยบุรี นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา ไทยบุรี นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Thai buri, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800810,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "ดอนตะโก",
    subdistrictEn: "Don tako",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา ดอนตะโก นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา ดอนตะโก นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Don tako, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800811,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา ตลิ่งชัน นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา ตลิ่งชัน นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Taling Chan, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800813,
    districtTh: "ท่าศาลา",
    districtEn: "Tha Sala",
    subdistrictTh: "โพธิ์ทอง",
    subdistrictEn: "Pho Thong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8008,
    province_code: 63,
    name: "ท่าศาลา โพธิ์ทอง นครศรีธรรมราช 80160",
    name_th: "ท่าศาลา โพธิ์ทอง นครศรีธรรมราช 80160",
    name_en: "Tha Sala, Pho Thong, Nakhon Si Thammarat, 80160",
  },
  {
    id: 800901,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "ปากแพรก",
    subdistrictEn: "Pak Phraek",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง ปากแพรก นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง ปากแพรก นครศรีธรรมราช 80110",
    name_en: "Thung Song, Pak Phraek, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800902,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "ชะมาย",
    subdistrictEn: "Chamai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง ชะมาย นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง ชะมาย นครศรีธรรมราช 80110",
    name_en: "Thung Song, Chamai, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800903,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "หนองหงส์",
    subdistrictEn: "Nong Hong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง หนองหงส์ นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง หนองหงส์ นครศรีธรรมราช 80110",
    name_en: "Thung Song, Nong Hong, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800904,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "ควนกรด",
    subdistrictEn: "Khuan Krot",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง ควนกรด นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง ควนกรด นครศรีธรรมราช 80110",
    name_en: "Thung Song, Khuan Krot, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800905,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "นาไม้ไผ่",
    subdistrictEn: "Na Mai Phai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง นาไม้ไผ่ นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง นาไม้ไผ่ นครศรีธรรมราช 80110",
    name_en: "Thung Song, Na Mai Phai, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800906,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "นาหลวงเสน",
    subdistrictEn: "Na Luang Sen",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง นาหลวงเสน นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง นาหลวงเสน นครศรีธรรมราช 80110",
    name_en: "Thung Song, Na Luang Sen, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800907,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "เขาโร",
    subdistrictEn: "Khao Ro",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง เขาโร นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง เขาโร นครศรีธรรมราช 80110",
    name_en: "Thung Song, Khao Ro, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800908,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "กะปาง",
    subdistrictEn: "Kapang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80310,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง กะปาง นครศรีธรรมราช 80310",
    name_th: "ทุ่งสง กะปาง นครศรีธรรมราช 80310",
    name_en: "Thung Song, Kapang, Nakhon Si Thammarat, 80310",
  },
  {
    id: 800909,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "ที่วัง",
    subdistrictEn: "Thi Wang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง ที่วัง นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง ที่วัง นครศรีธรรมราช 80110",
    name_en: "Thung Song, Thi Wang, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800910,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "น้ำตก",
    subdistrictEn: "Namtok",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง น้ำตก นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง น้ำตก นครศรีธรรมราช 80110",
    name_en: "Thung Song, Namtok, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800911,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "ถ้ำใหญ่",
    subdistrictEn: "Tham Yai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง ถ้ำใหญ่ นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง ถ้ำใหญ่ นครศรีธรรมราช 80110",
    name_en: "Thung Song, Tham Yai, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800912,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "นาโพธิ์",
    subdistrictEn: "Na Pho",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง นาโพธิ์ นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง นาโพธิ์ นครศรีธรรมราช 80110",
    name_en: "Thung Song, Na Pho, Nakhon Si Thammarat, 80110",
  },
  {
    id: 800913,
    districtTh: "ทุ่งสง",
    districtEn: "Thung Song",
    subdistrictTh: "เขาขาว",
    subdistrictEn: "Khao Khao",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80110,
    district_code: 8009,
    province_code: 63,
    name: "ทุ่งสง เขาขาว นครศรีธรรมราช 80110",
    name_th: "ทุ่งสง เขาขาว นครศรีธรรมราช 80110",
    name_en: "Thung Song, Khao Khao, Nakhon Si Thammarat, 80110",
  },
  {
    id: 801001,
    districtTh: "นาบอน",
    districtEn: "Na Bon",
    subdistrictTh: "นาบอน",
    subdistrictEn: "Na Bon",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80220,
    district_code: 8010,
    province_code: 63,
    name: "นาบอน นาบอน นครศรีธรรมราช 80220",
    name_th: "นาบอน นาบอน นครศรีธรรมราช 80220",
    name_en: "Na Bon, Na Bon, Nakhon Si Thammarat, 80220",
  },
  {
    id: 801002,
    districtTh: "นาบอน",
    districtEn: "Na Bon",
    subdistrictTh: "ทุ่งสง",
    subdistrictEn: "Thung Song",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80220,
    district_code: 8010,
    province_code: 63,
    name: "นาบอน ทุ่งสง นครศรีธรรมราช 80220",
    name_th: "นาบอน ทุ่งสง นครศรีธรรมราช 80220",
    name_en: "Na Bon, Thung Song, Nakhon Si Thammarat, 80220",
  },
  {
    id: 801003,
    districtTh: "นาบอน",
    districtEn: "Na Bon",
    subdistrictTh: "แก้วแสน",
    subdistrictEn: "Kaeo Saen",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80220,
    district_code: 8010,
    province_code: 63,
    name: "นาบอน แก้วแสน นครศรีธรรมราช 80220",
    name_th: "นาบอน แก้วแสน นครศรีธรรมราช 80220",
    name_en: "Na Bon, Kaeo Saen, Nakhon Si Thammarat, 80220",
  },
  {
    id: 801101,
    districtTh: "ทุ่งใหญ่",
    districtEn: "Thung Yai",
    subdistrictTh: "ท่ายาง",
    subdistrictEn: "Tha Yang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80240,
    district_code: 8011,
    province_code: 63,
    name: "ทุ่งใหญ่ ท่ายาง นครศรีธรรมราช 80240",
    name_th: "ทุ่งใหญ่ ท่ายาง นครศรีธรรมราช 80240",
    name_en: "Thung Yai, Tha Yang, Nakhon Si Thammarat, 80240",
  },
  {
    id: 801102,
    districtTh: "ทุ่งใหญ่",
    districtEn: "Thung Yai",
    subdistrictTh: "ทุ่งสัง",
    subdistrictEn: "Thung Sang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80240,
    district_code: 8011,
    province_code: 63,
    name: "ทุ่งใหญ่ ทุ่งสัง นครศรีธรรมราช 80240",
    name_th: "ทุ่งใหญ่ ทุ่งสัง นครศรีธรรมราช 80240",
    name_en: "Thung Yai, Thung Sang, Nakhon Si Thammarat, 80240",
  },
  {
    id: 801103,
    districtTh: "ทุ่งใหญ่",
    districtEn: "Thung Yai",
    subdistrictTh: "ทุ่งใหญ่",
    subdistrictEn: "Thung Yai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80240,
    district_code: 8011,
    province_code: 63,
    name: "ทุ่งใหญ่ ทุ่งใหญ่ นครศรีธรรมราช 80240",
    name_th: "ทุ่งใหญ่ ทุ่งใหญ่ นครศรีธรรมราช 80240",
    name_en: "Thung Yai, Thung Yai, Nakhon Si Thammarat, 80240",
  },
  {
    id: 801104,
    districtTh: "ทุ่งใหญ่",
    districtEn: "Thung Yai",
    subdistrictTh: "กุแหระ",
    subdistrictEn: "Kurae",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80240,
    district_code: 8011,
    province_code: 63,
    name: "ทุ่งใหญ่ กุแหระ นครศรีธรรมราช 80240",
    name_th: "ทุ่งใหญ่ กุแหระ นครศรีธรรมราช 80240",
    name_en: "Thung Yai, Kurae, Nakhon Si Thammarat, 80240",
  },
  {
    id: 801105,
    districtTh: "ทุ่งใหญ่",
    districtEn: "Thung Yai",
    subdistrictTh: "ปริก",
    subdistrictEn: "Prik",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80240,
    district_code: 8011,
    province_code: 63,
    name: "ทุ่งใหญ่ ปริก นครศรีธรรมราช 80240",
    name_th: "ทุ่งใหญ่ ปริก นครศรีธรรมราช 80240",
    name_en: "Thung Yai, Prik, Nakhon Si Thammarat, 80240",
  },
  {
    id: 801106,
    districtTh: "ทุ่งใหญ่",
    districtEn: "Thung Yai",
    subdistrictTh: "บางรูป",
    subdistrictEn: "Bang Rup",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80240,
    district_code: 8011,
    province_code: 63,
    name: "ทุ่งใหญ่ บางรูป นครศรีธรรมราช 80240",
    name_th: "ทุ่งใหญ่ บางรูป นครศรีธรรมราช 80240",
    name_en: "Thung Yai, Bang Rup, Nakhon Si Thammarat, 80240",
  },
  {
    id: 801107,
    districtTh: "ทุ่งใหญ่",
    districtEn: "Thung Yai",
    subdistrictTh: "กรุงหยัน",
    subdistrictEn: "Krung Yan",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80240,
    district_code: 8011,
    province_code: 63,
    name: "ทุ่งใหญ่ กรุงหยัน นครศรีธรรมราช 80240",
    name_th: "ทุ่งใหญ่ กรุงหยัน นครศรีธรรมราช 80240",
    name_en: "Thung Yai, Krung Yan, Nakhon Si Thammarat, 80240",
  },
  {
    id: 801201,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "ปากพนัง",
    subdistrictEn: "Pak Phanang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง ปากพนัง นครศรีธรรมราช 80140",
    name_th: "ปากพนัง ปากพนัง นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Pak Phanang, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801202,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "คลองน้อย",
    subdistrictEn: "Khlong Noi",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80330,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง คลองน้อย นครศรีธรรมราช 80330",
    name_th: "ปากพนัง คลองน้อย นครศรีธรรมราช 80330",
    name_en: "Pak Phanang, Khlong Noi, Nakhon Si Thammarat, 80330",
  },
  {
    id: 801203,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "ป่าระกำ",
    subdistrictEn: "Pa Rakam",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง ป่าระกำ นครศรีธรรมราช 80140",
    name_th: "ปากพนัง ป่าระกำ นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Pa Rakam, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801204,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "ชะเมา",
    subdistrictEn: "Chamao",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80330,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง ชะเมา นครศรีธรรมราช 80330",
    name_th: "ปากพนัง ชะเมา นครศรีธรรมราช 80330",
    name_en: "Pak Phanang, Chamao, Nakhon Si Thammarat, 80330",
  },
  {
    id: 801205,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "คลองกระบือ",
    subdistrictEn: "Khlong Krabue",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง คลองกระบือ นครศรีธรรมราช 80140",
    name_th: "ปากพนัง คลองกระบือ นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Khlong Krabue, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801206,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "เกาะทวด",
    subdistrictEn: "Ko Thuat",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80330,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง เกาะทวด นครศรีธรรมราช 80330",
    name_th: "ปากพนัง เกาะทวด นครศรีธรรมราช 80330",
    name_en: "Pak Phanang, Ko Thuat, Nakhon Si Thammarat, 80330",
  },
  {
    id: 801207,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง บ้านใหม่ นครศรีธรรมราช 80140",
    name_th: "ปากพนัง บ้านใหม่ นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Ban Mai, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801208,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "หูล่อง",
    subdistrictEn: "Hu Long",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง หูล่อง นครศรีธรรมราช 80140",
    name_th: "ปากพนัง หูล่อง นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Hu Long, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801209,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "แหลมตะลุมพุก",
    subdistrictEn: "Laem Talumphuk",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง แหลมตะลุมพุก นครศรีธรรมราช 80140",
    name_th: "ปากพนัง แหลมตะลุมพุก นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Laem Talumphuk, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801210,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "ปากพนังฝั่งตะวันตก",
    subdistrictEn: "Pak Phanang Fang Tawantok",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง ปากพนังฝั่งตะวันตก นครศรีธรรมราช 80140",
    name_th: "ปากพนัง ปากพนังฝั่งตะวันตก นครศรีธรรมราช 80140",
    name_en:
      "Pak Phanang, Pak Phanang Fang Tawantok, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801211,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "บางศาลา",
    subdistrictEn: "Bang Sala",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง บางศาลา นครศรีธรรมราช 80140",
    name_th: "ปากพนัง บางศาลา นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Bang Sala, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801212,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "บางพระ",
    subdistrictEn: "Bang Phra",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง บางพระ นครศรีธรรมราช 80140",
    name_th: "ปากพนัง บางพระ นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Bang Phra, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801213,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "บางตะพง",
    subdistrictEn: "Bang Taphong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง บางตะพง นครศรีธรรมราช 80140",
    name_th: "ปากพนัง บางตะพง นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Bang Taphong, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801214,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "ปากพนังฝั่งตะวันออก",
    subdistrictEn: "Pak Phanang Fang Tawan-ok",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง ปากพนังฝั่งตะวันออก นครศรีธรรมราช 80140",
    name_th: "ปากพนัง ปากพนังฝั่งตะวันออก นครศรีธรรมราช 80140",
    name_en:
      "Pak Phanang, Pak Phanang Fang Tawan-ok, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801215,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "บ้านเพิง",
    subdistrictEn: "Ban Phoeng",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง บ้านเพิง นครศรีธรรมราช 80140",
    name_th: "ปากพนัง บ้านเพิง นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Ban Phoeng, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801216,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "ท่าพยา",
    subdistrictEn: "Tha Phaya",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง ท่าพยา นครศรีธรรมราช 80140",
    name_th: "ปากพนัง ท่าพยา นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Tha Phaya, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801217,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "ปากแพรก",
    subdistrictEn: "Pak Phraek",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง ปากแพรก นครศรีธรรมราช 80140",
    name_th: "ปากพนัง ปากแพรก นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Pak Phraek, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801218,
    districtTh: "ปากพนัง",
    districtEn: "Pak Phanang",
    subdistrictTh: "ขนาบนาก",
    subdistrictEn: "Khanap Nak",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80140,
    district_code: 8012,
    province_code: 63,
    name: "ปากพนัง ขนาบนาก นครศรีธรรมราช 80140",
    name_th: "ปากพนัง ขนาบนาก นครศรีธรรมราช 80140",
    name_en: "Pak Phanang, Khanap Nak, Nakhon Si Thammarat, 80140",
  },
  {
    id: 801301,
    districtTh: "ร่อนพิบูลย์",
    districtEn: "Ron Phibun",
    subdistrictTh: "ร่อนพิบูลย์",
    subdistrictEn: "Ron Phibun",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80130,
    district_code: 8013,
    province_code: 63,
    name: "ร่อนพิบูลย์ ร่อนพิบูลย์ นครศรีธรรมราช 80130",
    name_th: "ร่อนพิบูลย์ ร่อนพิบูลย์ นครศรีธรรมราช 80130",
    name_en: "Ron Phibun, Ron Phibun, Nakhon Si Thammarat, 80130",
  },
  {
    id: 801302,
    districtTh: "ร่อนพิบูลย์",
    districtEn: "Ron Phibun",
    subdistrictTh: "หินตก",
    subdistrictEn: "Hin Tok",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80350,
    district_code: 8013,
    province_code: 63,
    name: "ร่อนพิบูลย์ หินตก นครศรีธรรมราช 80350",
    name_th: "ร่อนพิบูลย์ หินตก นครศรีธรรมราช 80350",
    name_en: "Ron Phibun, Hin Tok, Nakhon Si Thammarat, 80350",
  },
  {
    id: 801303,
    districtTh: "ร่อนพิบูลย์",
    districtEn: "Ron Phibun",
    subdistrictTh: "เสาธง",
    subdistrictEn: "Sao Thong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80350,
    district_code: 8013,
    province_code: 63,
    name: "ร่อนพิบูลย์ เสาธง นครศรีธรรมราช 80350",
    name_th: "ร่อนพิบูลย์ เสาธง นครศรีธรรมราช 80350",
    name_en: "Ron Phibun, Sao Thong, Nakhon Si Thammarat, 80350",
  },
  {
    id: 801304,
    districtTh: "ร่อนพิบูลย์",
    districtEn: "Ron Phibun",
    subdistrictTh: "ควนเกย",
    subdistrictEn: "Khuan Koei",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80130,
    district_code: 8013,
    province_code: 63,
    name: "ร่อนพิบูลย์ ควนเกย นครศรีธรรมราช 80130",
    name_th: "ร่อนพิบูลย์ ควนเกย นครศรีธรรมราช 80130",
    name_en: "Ron Phibun, Khuan Koei, Nakhon Si Thammarat, 80130",
  },
  {
    id: 801305,
    districtTh: "ร่อนพิบูลย์",
    districtEn: "Ron Phibun",
    subdistrictTh: "ควนพัง",
    subdistrictEn: "Khuan Phang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80130,
    district_code: 8013,
    province_code: 63,
    name: "ร่อนพิบูลย์ ควนพัง นครศรีธรรมราช 80130",
    name_th: "ร่อนพิบูลย์ ควนพัง นครศรีธรรมราช 80130",
    name_en: "Ron Phibun, Khuan Phang, Nakhon Si Thammarat, 80130",
  },
  {
    id: 801306,
    districtTh: "ร่อนพิบูลย์",
    districtEn: "Ron Phibun",
    subdistrictTh: "ควนชุม",
    subdistrictEn: "Khuan Chum",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80130,
    district_code: 8013,
    province_code: 63,
    name: "ร่อนพิบูลย์ ควนชุม นครศรีธรรมราช 80130",
    name_th: "ร่อนพิบูลย์ ควนชุม นครศรีธรรมราช 80130",
    name_en: "Ron Phibun, Khuan Chum, Nakhon Si Thammarat, 80130",
  },
  {
    id: 801401,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "สิชล",
    subdistrictEn: "Sichon",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80120,
    district_code: 8014,
    province_code: 63,
    name: "สิชล สิชล นครศรีธรรมราช 80120",
    name_th: "สิชล สิชล นครศรีธรรมราช 80120",
    name_en: "Sichon, Sichon, Nakhon Si Thammarat, 80120",
  },
  {
    id: 801402,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "ทุ่งปรัง",
    subdistrictEn: "Thung Prang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80120,
    district_code: 8014,
    province_code: 63,
    name: "สิชล ทุ่งปรัง นครศรีธรรมราช 80120",
    name_th: "สิชล ทุ่งปรัง นครศรีธรรมราช 80120",
    name_en: "Sichon, Thung Prang, Nakhon Si Thammarat, 80120",
  },
  {
    id: 801403,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "ฉลอง",
    subdistrictEn: "Chalong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80120,
    district_code: 8014,
    province_code: 63,
    name: "สิชล ฉลอง นครศรีธรรมราช 80120",
    name_th: "สิชล ฉลอง นครศรีธรรมราช 80120",
    name_en: "Sichon, Chalong, Nakhon Si Thammarat, 80120",
  },
  {
    id: 801404,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "เสาเภา",
    subdistrictEn: "Sao Phao",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80340,
    district_code: 8014,
    province_code: 63,
    name: "สิชล เสาเภา นครศรีธรรมราช 80340",
    name_th: "สิชล เสาเภา นครศรีธรรมราช 80340",
    name_en: "Sichon, Sao Phao, Nakhon Si Thammarat, 80340",
  },
  {
    id: 801405,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "เปลี่ยน",
    subdistrictEn: "Plian",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80120,
    district_code: 8014,
    province_code: 63,
    name: "สิชล เปลี่ยน นครศรีธรรมราช 80120",
    name_th: "สิชล เปลี่ยน นครศรีธรรมราช 80120",
    name_en: "Sichon, Plian, Nakhon Si Thammarat, 80120",
  },
  {
    id: 801406,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "สี่ขีด",
    subdistrictEn: "Si Khit",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80120,
    district_code: 8014,
    province_code: 63,
    name: "สิชล สี่ขีด นครศรีธรรมราช 80120",
    name_th: "สิชล สี่ขีด นครศรีธรรมราช 80120",
    name_en: "Sichon, Si Khit, Nakhon Si Thammarat, 80120",
  },
  {
    id: 801407,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "เทพราช",
    subdistrictEn: "Theppharat",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80340,
    district_code: 8014,
    province_code: 63,
    name: "สิชล เทพราช นครศรีธรรมราช 80340",
    name_th: "สิชล เทพราช นครศรีธรรมราช 80340",
    name_en: "Sichon, Theppharat, Nakhon Si Thammarat, 80340",
  },
  {
    id: 801408,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "เขาน้อย",
    subdistrictEn: "Khao Noi",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80120,
    district_code: 8014,
    province_code: 63,
    name: "สิชล เขาน้อย นครศรีธรรมราช 80120",
    name_th: "สิชล เขาน้อย นครศรีธรรมราช 80120",
    name_en: "Sichon, Khao Noi, Nakhon Si Thammarat, 80120",
  },
  {
    id: 801409,
    districtTh: "สิชล",
    districtEn: "Sichon",
    subdistrictTh: "ทุ่งใส",
    subdistrictEn: "Thung Sai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80120,
    district_code: 8014,
    province_code: 63,
    name: "สิชล ทุ่งใส นครศรีธรรมราช 80120",
    name_th: "สิชล ทุ่งใส นครศรีธรรมราช 80120",
    name_en: "Sichon, Thung Sai, Nakhon Si Thammarat, 80120",
  },
  {
    id: 801501,
    districtTh: "ขนอม",
    districtEn: "Khanom",
    subdistrictTh: "ขนอม",
    subdistrictEn: "Khanom",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80210,
    district_code: 8015,
    province_code: 63,
    name: "ขนอม ขนอม นครศรีธรรมราช 80210",
    name_th: "ขนอม ขนอม นครศรีธรรมราช 80210",
    name_en: "Khanom, Khanom, Nakhon Si Thammarat, 80210",
  },
  {
    id: 801502,
    districtTh: "ขนอม",
    districtEn: "Khanom",
    subdistrictTh: "ควนทอง",
    subdistrictEn: "Khuan Thong",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80210,
    district_code: 8015,
    province_code: 63,
    name: "ขนอม ควนทอง นครศรีธรรมราช 80210",
    name_th: "ขนอม ควนทอง นครศรีธรรมราช 80210",
    name_en: "Khanom, Khuan Thong, Nakhon Si Thammarat, 80210",
  },
  {
    id: 801503,
    districtTh: "ขนอม",
    districtEn: "Khanom",
    subdistrictTh: "ท้องเนียน",
    subdistrictEn: "Thong Nian",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80210,
    district_code: 8015,
    province_code: 63,
    name: "ขนอม ท้องเนียน นครศรีธรรมราช 80210",
    name_th: "ขนอม ท้องเนียน นครศรีธรรมราช 80210",
    name_en: "Khanom, Thong Nian, Nakhon Si Thammarat, 80210",
  },
  {
    id: 801601,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "หัวไทร",
    subdistrictEn: "Hua Sai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร หัวไทร นครศรีธรรมราช 80170",
    name_th: "หัวไทร หัวไทร นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Hua Sai, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801602,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "หน้าสตน",
    subdistrictEn: "Na Saton",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร หน้าสตน นครศรีธรรมราช 80170",
    name_th: "หัวไทร หน้าสตน นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Na Saton, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801603,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "ทรายขาว",
    subdistrictEn: "Sai Khao",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร ทรายขาว นครศรีธรรมราช 80170",
    name_th: "หัวไทร ทรายขาว นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Sai Khao, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801604,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "แหลม",
    subdistrictEn: "Laem",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร แหลม นครศรีธรรมราช 80170",
    name_th: "หัวไทร แหลม นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Laem, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801605,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "เขาพังไกร",
    subdistrictEn: "Khao Phang Krai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร เขาพังไกร นครศรีธรรมราช 80170",
    name_th: "หัวไทร เขาพังไกร นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Khao Phang Krai, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801606,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "บ้านราม",
    subdistrictEn: "Ban Ram",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร บ้านราม นครศรีธรรมราช 80170",
    name_th: "หัวไทร บ้านราม นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Ban Ram, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801607,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "บางนบ",
    subdistrictEn: "Bang Nop",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร บางนบ นครศรีธรรมราช 80170",
    name_th: "หัวไทร บางนบ นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Bang Nop, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801608,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "ท่าซอม",
    subdistrictEn: "Tha Som",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร ท่าซอม นครศรีธรรมราช 80170",
    name_th: "หัวไทร ท่าซอม นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Tha Som, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801609,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "ควนชะลิก",
    subdistrictEn: "Khuan Chalik",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร ควนชะลิก นครศรีธรรมราช 80170",
    name_th: "หัวไทร ควนชะลิก นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Khuan Chalik, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801610,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "รามแก้ว",
    subdistrictEn: "Ram Kaeo",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร รามแก้ว นครศรีธรรมราช 80170",
    name_th: "หัวไทร รามแก้ว นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Ram Kaeo, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801611,
    districtTh: "หัวไทร",
    districtEn: "Hua Sai",
    subdistrictTh: "เกาะเพชร",
    subdistrictEn: "Ko Phet",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80170,
    district_code: 8016,
    province_code: 63,
    name: "หัวไทร เกาะเพชร นครศรีธรรมราช 80170",
    name_th: "หัวไทร เกาะเพชร นครศรีธรรมราช 80170",
    name_en: "Hua Sai, Ko Phet, Nakhon Si Thammarat, 80170",
  },
  {
    id: 801701,
    districtTh: "บางขัน",
    districtEn: "Bang Khan",
    subdistrictTh: "บางขัน",
    subdistrictEn: "Bang Khan",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80360,
    district_code: 8017,
    province_code: 63,
    name: "บางขัน บางขัน นครศรีธรรมราช 80360",
    name_th: "บางขัน บางขัน นครศรีธรรมราช 80360",
    name_en: "Bang Khan, Bang Khan, Nakhon Si Thammarat, 80360",
  },
  {
    id: 801702,
    districtTh: "บางขัน",
    districtEn: "Bang Khan",
    subdistrictTh: "บ้านลำนาว",
    subdistrictEn: "Ban Lamnao",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80360,
    district_code: 8017,
    province_code: 63,
    name: "บางขัน บ้านลำนาว นครศรีธรรมราช 80360",
    name_th: "บางขัน บ้านลำนาว นครศรีธรรมราช 80360",
    name_en: "Bang Khan, Ban Lamnao, Nakhon Si Thammarat, 80360",
  },
  {
    id: 801703,
    districtTh: "บางขัน",
    districtEn: "Bang Khan",
    subdistrictTh: "วังหิน",
    subdistrictEn: "Wang Hin",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80360,
    district_code: 8017,
    province_code: 63,
    name: "บางขัน วังหิน นครศรีธรรมราช 80360",
    name_th: "บางขัน วังหิน นครศรีธรรมราช 80360",
    name_en: "Bang Khan, Wang Hin, Nakhon Si Thammarat, 80360",
  },
  {
    id: 801704,
    districtTh: "บางขัน",
    districtEn: "Bang Khan",
    subdistrictTh: "บ้านนิคม",
    subdistrictEn: "Ban Nikhom",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80360,
    district_code: 8017,
    province_code: 63,
    name: "บางขัน บ้านนิคม นครศรีธรรมราช 80360",
    name_th: "บางขัน บ้านนิคม นครศรีธรรมราช 80360",
    name_en: "Bang Khan, Ban Nikhom, Nakhon Si Thammarat, 80360",
  },
  {
    id: 801801,
    districtTh: "ถ้ำพรรณรา",
    districtEn: "Tham Phannara",
    subdistrictTh: "ถ้ำพรรณรา",
    subdistrictEn: "Tham Phannara",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80260,
    district_code: 8018,
    province_code: 63,
    name: "ถ้ำพรรณรา ถ้ำพรรณรา นครศรีธรรมราช 80260",
    name_th: "ถ้ำพรรณรา ถ้ำพรรณรา นครศรีธรรมราช 80260",
    name_en: "Tham Phannara, Tham Phannara, Nakhon Si Thammarat, 80260",
  },
  {
    id: 801802,
    districtTh: "ถ้ำพรรณรา",
    districtEn: "Tham Phannara",
    subdistrictTh: "คลองเส",
    subdistrictEn: "Khlong Se",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80260,
    district_code: 8018,
    province_code: 63,
    name: "ถ้ำพรรณรา คลองเส นครศรีธรรมราช 80260",
    name_th: "ถ้ำพรรณรา คลองเส นครศรีธรรมราช 80260",
    name_en: "Tham Phannara, Khlong Se, Nakhon Si Thammarat, 80260",
  },
  {
    id: 801803,
    districtTh: "ถ้ำพรรณรา",
    districtEn: "Tham Phannara",
    subdistrictTh: "ดุสิต",
    subdistrictEn: "Dusit",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80260,
    district_code: 8018,
    province_code: 63,
    name: "ถ้ำพรรณรา ดุสิต นครศรีธรรมราช 80260",
    name_th: "ถ้ำพรรณรา ดุสิต นครศรีธรรมราช 80260",
    name_en: "Tham Phannara, Dusit, Nakhon Si Thammarat, 80260",
  },
  {
    id: 801901,
    districtTh: "จุฬาภรณ์",
    districtEn: "Chulabhorn",
    subdistrictTh: "บ้านควนมุด",
    subdistrictEn: "Ban Khuan Mut",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8019,
    province_code: 63,
    name: "จุฬาภรณ์ บ้านควนมุด นครศรีธรรมราช 80180",
    name_th: "จุฬาภรณ์ บ้านควนมุด นครศรีธรรมราช 80180",
    name_en: "Chulabhorn, Ban Khuan Mut, Nakhon Si Thammarat, 80180",
  },
  {
    id: 801902,
    districtTh: "จุฬาภรณ์",
    districtEn: "Chulabhorn",
    subdistrictTh: "บ้านชะอวด",
    subdistrictEn: "Ban Cha-uat",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80180,
    district_code: 8019,
    province_code: 63,
    name: "จุฬาภรณ์ บ้านชะอวด นครศรีธรรมราช 80180",
    name_th: "จุฬาภรณ์ บ้านชะอวด นครศรีธรรมราช 80180",
    name_en: "Chulabhorn, Ban Cha-uat, Nakhon Si Thammarat, 80180",
  },
  {
    id: 801903,
    districtTh: "จุฬาภรณ์",
    districtEn: "Chulabhorn",
    subdistrictTh: "ควนหนองคว้า",
    subdistrictEn: "Khuan Nong Khwa",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80130,
    district_code: 8019,
    province_code: 63,
    name: "จุฬาภรณ์ ควนหนองคว้า นครศรีธรรมราช 80130",
    name_th: "จุฬาภรณ์ ควนหนองคว้า นครศรีธรรมราช 80130",
    name_en: "Chulabhorn, Khuan Nong Khwa, Nakhon Si Thammarat, 80130",
  },
  {
    id: 801904,
    districtTh: "จุฬาภรณ์",
    districtEn: "Chulabhorn",
    subdistrictTh: "ทุ่งโพธิ์",
    subdistrictEn: "Thung Pho",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80130,
    district_code: 8019,
    province_code: 63,
    name: "จุฬาภรณ์ ทุ่งโพธิ์ นครศรีธรรมราช 80130",
    name_th: "จุฬาภรณ์ ทุ่งโพธิ์ นครศรีธรรมราช 80130",
    name_en: "Chulabhorn, Thung Pho, Nakhon Si Thammarat, 80130",
  },
  {
    id: 801905,
    districtTh: "จุฬาภรณ์",
    districtEn: "Chulabhorn",
    subdistrictTh: "นาหมอบุญ",
    subdistrictEn: "Na Mo Bun",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80130,
    district_code: 8019,
    province_code: 63,
    name: "จุฬาภรณ์ นาหมอบุญ นครศรีธรรมราช 80130",
    name_th: "จุฬาภรณ์ นาหมอบุญ นครศรีธรรมราช 80130",
    name_en: "Chulabhorn, Na Mo Bun, Nakhon Si Thammarat, 80130",
  },
  {
    id: 801906,
    districtTh: "จุฬาภรณ์",
    districtEn: "Chulabhorn",
    subdistrictTh: "สามตำบล",
    subdistrictEn: "Sam Tambon",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80130,
    district_code: 8019,
    province_code: 63,
    name: "จุฬาภรณ์ สามตำบล นครศรีธรรมราช 80130",
    name_th: "จุฬาภรณ์ สามตำบล นครศรีธรรมราช 80130",
    name_en: "Chulabhorn, Sam Tambon, Nakhon Si Thammarat, 80130",
  },
  {
    id: 802001,
    districtTh: "พระพรหม",
    districtEn: "Phra Phrom",
    subdistrictTh: "นาพรุ",
    subdistrictEn: "Na Phru",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8020,
    province_code: 63,
    name: "พระพรหม นาพรุ นครศรีธรรมราช 80000",
    name_th: "พระพรหม นาพรุ นครศรีธรรมราช 80000",
    name_en: "Phra Phrom, Na Phru, Nakhon Si Thammarat, 80000",
  },
  {
    id: 802002,
    districtTh: "พระพรหม",
    districtEn: "Phra Phrom",
    subdistrictTh: "นาสาร",
    subdistrictEn: "Na San",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8020,
    province_code: 63,
    name: "พระพรหม นาสาร นครศรีธรรมราช 80000",
    name_th: "พระพรหม นาสาร นครศรีธรรมราช 80000",
    name_en: "Phra Phrom, Na San, Nakhon Si Thammarat, 80000",
  },
  {
    id: 802003,
    districtTh: "พระพรหม",
    districtEn: "Phra Phrom",
    subdistrictTh: "ท้ายสำเภา",
    subdistrictEn: "Thai Samphao",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8020,
    province_code: 63,
    name: "พระพรหม ท้ายสำเภา นครศรีธรรมราช 80000",
    name_th: "พระพรหม ท้ายสำเภา นครศรีธรรมราช 80000",
    name_en: "Phra Phrom, Thai Samphao, Nakhon Si Thammarat, 80000",
  },
  {
    id: 802004,
    districtTh: "พระพรหม",
    districtEn: "Phra Phrom",
    subdistrictTh: "ช้างซ้าย",
    subdistrictEn: "Chang Sai",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80000,
    district_code: 8020,
    province_code: 63,
    name: "พระพรหม ช้างซ้าย นครศรีธรรมราช 80000",
    name_th: "พระพรหม ช้างซ้าย นครศรีธรรมราช 80000",
    name_en: "Phra Phrom, Chang Sai, Nakhon Si Thammarat, 80000",
  },
  {
    id: 802101,
    districtTh: "นบพิตำ",
    districtEn: "Nopphitam",
    subdistrictTh: "นบพิตำ",
    subdistrictEn: "Nopphitam",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8021,
    province_code: 63,
    name: "นบพิตำ นบพิตำ นครศรีธรรมราช 80160",
    name_th: "นบพิตำ นบพิตำ นครศรีธรรมราช 80160",
    name_en: "Nopphitam, Nopphitam, Nakhon Si Thammarat, 80160",
  },
  {
    id: 802102,
    districtTh: "นบพิตำ",
    districtEn: "Nopphitam",
    subdistrictTh: "กรุงชิง",
    subdistrictEn: "Krung Ching",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8021,
    province_code: 63,
    name: "นบพิตำ กรุงชิง นครศรีธรรมราช 80160",
    name_th: "นบพิตำ กรุงชิง นครศรีธรรมราช 80160",
    name_en: "Nopphitam, Krung Ching, Nakhon Si Thammarat, 80160",
  },
  {
    id: 802103,
    districtTh: "นบพิตำ",
    districtEn: "Nopphitam",
    subdistrictTh: "กะหรอ",
    subdistrictEn: "Karo",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8021,
    province_code: 63,
    name: "นบพิตำ กะหรอ นครศรีธรรมราช 80160",
    name_th: "นบพิตำ กะหรอ นครศรีธรรมราช 80160",
    name_en: "Nopphitam, Karo, Nakhon Si Thammarat, 80160",
  },
  {
    id: 802104,
    districtTh: "นบพิตำ",
    districtEn: "Nopphitam",
    subdistrictTh: "นาเหรง",
    subdistrictEn: "Na Reng",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80160,
    district_code: 8021,
    province_code: 63,
    name: "นบพิตำ นาเหรง นครศรีธรรมราช 80160",
    name_th: "นบพิตำ นาเหรง นครศรีธรรมราช 80160",
    name_en: "Nopphitam, Na Reng, Nakhon Si Thammarat, 80160",
  },
  {
    id: 802201,
    districtTh: "ช้างกลาง",
    districtEn: "Chang Klang",
    subdistrictTh: "ช้างกลาง",
    subdistrictEn: "Chang Klang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80250,
    district_code: 8022,
    province_code: 63,
    name: "ช้างกลาง ช้างกลาง นครศรีธรรมราช 80250",
    name_th: "ช้างกลาง ช้างกลาง นครศรีธรรมราช 80250",
    name_en: "Chang Klang, Chang Klang, Nakhon Si Thammarat, 80250",
  },
  {
    id: 802202,
    districtTh: "ช้างกลาง",
    districtEn: "Chang Klang",
    subdistrictTh: "หลักช้าง",
    subdistrictEn: "Lak Chang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80250,
    district_code: 8022,
    province_code: 63,
    name: "ช้างกลาง หลักช้าง นครศรีธรรมราช 80250",
    name_th: "ช้างกลาง หลักช้าง นครศรีธรรมราช 80250",
    name_en: "Chang Klang, Lak Chang, Nakhon Si Thammarat, 80250",
  },
  {
    id: 802203,
    districtTh: "ช้างกลาง",
    districtEn: "Chang Klang",
    subdistrictTh: "สวนขัน",
    subdistrictEn: "Suan Kan",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80250,
    district_code: 8022,
    province_code: 63,
    name: "ช้างกลาง สวนขัน นครศรีธรรมราช 80250",
    name_th: "ช้างกลาง สวนขัน นครศรีธรรมราช 80250",
    name_en: "Chang Klang, Suan Kan, Nakhon Si Thammarat, 80250",
  },
  {
    id: 802301,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "เชียรเขา",
    subdistrictEn: "Chian Khao",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8023,
    province_code: 63,
    name: "เฉลิมพระเกียรติ เชียรเขา นครศรีธรรมราช 80190",
    name_th: "เฉลิมพระเกียรติ เชียรเขา นครศรีธรรมราช 80190",
    name_en: "Chaloem Phra Kiat, Chian Khao, Nakhon Si Thammarat, 80190",
  },
  {
    id: 802302,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ดอนตรอ",
    subdistrictEn: "Don Tro",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80290,
    district_code: 8023,
    province_code: 63,
    name: "เฉลิมพระเกียรติ ดอนตรอ นครศรีธรรมราช 80290",
    name_th: "เฉลิมพระเกียรติ ดอนตรอ นครศรีธรรมราช 80290",
    name_en: "Chaloem Phra Kiat, Don Tro, Nakhon Si Thammarat, 80290",
  },
  {
    id: 802303,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "สวนหลวง",
    subdistrictEn: "Suan Luang",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8023,
    province_code: 63,
    name: "เฉลิมพระเกียรติ สวนหลวง นครศรีธรรมราช 80190",
    name_th: "เฉลิมพระเกียรติ สวนหลวง นครศรีธรรมราช 80190",
    name_en: "Chaloem Phra Kiat, Suan Luang, Nakhon Si Thammarat, 80190",
  },
  {
    id: 802304,
    districtTh: "เฉลิมพระเกียรติ",
    districtEn: "Chaloem Phra Kiat",
    subdistrictTh: "ทางพูน",
    subdistrictEn: "Thang Phun",
    provinceTh: "นครศรีธรรมราช",
    provinceEn: "Nakhon Si Thammarat",
    zipcode: 80190,
    district_code: 8023,
    province_code: 63,
    name: "เฉลิมพระเกียรติ ทางพูน นครศรีธรรมราช 80190",
    name_th: "เฉลิมพระเกียรติ ทางพูน นครศรีธรรมราช 80190",
    name_en: "Chaloem Phra Kiat, Thang Phun, Nakhon Si Thammarat, 80190",
  },
  {
    id: 810101,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ ปากน้ำ กระบี่ 81000",
    name_th: "เมืองกระบี่ ปากน้ำ กระบี่ 81000",
    name_en: "Mueang Krabi, Pak Nam, Krabi, 81000",
  },
  {
    id: 810102,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "กระบี่ใหญ่",
    subdistrictEn: "Krabi Yai",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ กระบี่ใหญ่ กระบี่ 81000",
    name_th: "เมืองกระบี่ กระบี่ใหญ่ กระบี่ 81000",
    name_en: "Mueang Krabi, Krabi Yai, Krabi, 81000",
  },
  {
    id: 810103,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "กระบี่น้อย",
    subdistrictEn: "Krabi Noi",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ กระบี่น้อย กระบี่ 81000",
    name_th: "เมืองกระบี่ กระบี่น้อย กระบี่ 81000",
    name_en: "Mueang Krabi, Krabi Noi, Krabi, 81000",
  },
  {
    id: 810105,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "เขาคราม",
    subdistrictEn: "Khao Khram",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ เขาคราม กระบี่ 81000",
    name_th: "เมืองกระบี่ เขาคราม กระบี่ 81000",
    name_en: "Mueang Krabi, Khao Khram, Krabi, 81000",
  },
  {
    id: 810106,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "เขาทอง",
    subdistrictEn: "Khao Thong",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ เขาทอง กระบี่ 81000",
    name_th: "เมืองกระบี่ เขาทอง กระบี่ 81000",
    name_en: "Mueang Krabi, Khao Thong, Krabi, 81000",
  },
  {
    id: 810111,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "ทับปริก",
    subdistrictEn: "Thap Prik",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ ทับปริก กระบี่ 81000",
    name_th: "เมืองกระบี่ ทับปริก กระบี่ 81000",
    name_en: "Mueang Krabi, Thap Prik, Krabi, 81000",
  },
  {
    id: 810115,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "ไสไทย",
    subdistrictEn: "Sai Thai",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ ไสไทย กระบี่ 81000",
    name_th: "เมืองกระบี่ ไสไทย กระบี่ 81000",
    name_en: "Mueang Krabi, Sai Thai, Krabi, 81000",
  },
  {
    id: 810116,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "อ่าวนาง",
    subdistrictEn: "Ao Nang",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ อ่าวนาง กระบี่ 81000",
    name_th: "เมืองกระบี่ อ่าวนาง กระบี่ 81000",
    name_en: "Mueang Krabi, Ao Nang, Krabi, 81000",
  },
  {
    id: 810117,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "หนองทะเล",
    subdistrictEn: "Nong Thale",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ หนองทะเล กระบี่ 81000",
    name_th: "เมืองกระบี่ หนองทะเล กระบี่ 81000",
    name_en: "Mueang Krabi, Nong Thale, Krabi, 81000",
  },
  {
    id: 810118,
    districtTh: "เมืองกระบี่",
    districtEn: "Mueang Krabi",
    subdistrictTh: "คลองประสงค์",
    subdistrictEn: "Khlong Prasong",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81000,
    district_code: 8101,
    province_code: 64,
    name: "เมืองกระบี่ คลองประสงค์ กระบี่ 81000",
    name_th: "เมืองกระบี่ คลองประสงค์ กระบี่ 81000",
    name_en: "Mueang Krabi, Khlong Prasong, Krabi, 81000",
  },
  {
    id: 810201,
    districtTh: "เขาพนม",
    districtEn: "Khao Phanom",
    subdistrictTh: "เขาพนม",
    subdistrictEn: "Khao Phanom",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81140,
    district_code: 8102,
    province_code: 64,
    name: "เขาพนม เขาพนม กระบี่ 81140",
    name_th: "เขาพนม เขาพนม กระบี่ 81140",
    name_en: "Khao Phanom, Khao Phanom, Krabi, 81140",
  },
  {
    id: 810202,
    districtTh: "เขาพนม",
    districtEn: "Khao Phanom",
    subdistrictTh: "เขาดิน",
    subdistrictEn: "Khao Din",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81140,
    district_code: 8102,
    province_code: 64,
    name: "เขาพนม เขาดิน กระบี่ 81140",
    name_th: "เขาพนม เขาดิน กระบี่ 81140",
    name_en: "Khao Phanom, Khao Din, Krabi, 81140",
  },
  {
    id: 810203,
    districtTh: "เขาพนม",
    districtEn: "Khao Phanom",
    subdistrictTh: "สินปุน",
    subdistrictEn: "Sin Pun",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 80240,
    district_code: 8102,
    province_code: 64,
    name: "เขาพนม สินปุน กระบี่ 80240",
    name_th: "เขาพนม สินปุน กระบี่ 80240",
    name_en: "Khao Phanom, Sin Pun, Krabi, 80240",
  },
  {
    id: 810204,
    districtTh: "เขาพนม",
    districtEn: "Khao Phanom",
    subdistrictTh: "พรุเตียว",
    subdistrictEn: "Phru Tiao",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81140,
    district_code: 8102,
    province_code: 64,
    name: "เขาพนม พรุเตียว กระบี่ 81140",
    name_th: "เขาพนม พรุเตียว กระบี่ 81140",
    name_en: "Khao Phanom, Phru Tiao, Krabi, 81140",
  },
  {
    id: 810205,
    districtTh: "เขาพนม",
    districtEn: "Khao Phanom",
    subdistrictTh: "หน้าเขา",
    subdistrictEn: "Na Khao",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81140,
    district_code: 8102,
    province_code: 64,
    name: "เขาพนม หน้าเขา กระบี่ 81140",
    name_th: "เขาพนม หน้าเขา กระบี่ 81140",
    name_en: "Khao Phanom, Na Khao, Krabi, 81140",
  },
  {
    id: 810206,
    districtTh: "เขาพนม",
    districtEn: "Khao Phanom",
    subdistrictTh: "โคกหาร",
    subdistrictEn: "Khok Han",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 80240,
    district_code: 8102,
    province_code: 64,
    name: "เขาพนม โคกหาร กระบี่ 80240",
    name_th: "เขาพนม โคกหาร กระบี่ 80240",
    name_en: "Khao Phanom, Khok Han, Krabi, 80240",
  },
  {
    id: 810301,
    districtTh: "เกาะลันตา",
    districtEn: "Ko Lanta",
    subdistrictTh: "เกาะลันตาใหญ่",
    subdistrictEn: "Ko Lanta Yai",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81150,
    district_code: 8103,
    province_code: 64,
    name: "เกาะลันตา เกาะลันตาใหญ่ กระบี่ 81150",
    name_th: "เกาะลันตา เกาะลันตาใหญ่ กระบี่ 81150",
    name_en: "Ko Lanta, Ko Lanta Yai, Krabi, 81150",
  },
  {
    id: 810302,
    districtTh: "เกาะลันตา",
    districtEn: "Ko Lanta",
    subdistrictTh: "เกาะลันตาน้อย",
    subdistrictEn: "Ko Lanta Noi",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81150,
    district_code: 8103,
    province_code: 64,
    name: "เกาะลันตา เกาะลันตาน้อย กระบี่ 81150",
    name_th: "เกาะลันตา เกาะลันตาน้อย กระบี่ 81150",
    name_en: "Ko Lanta, Ko Lanta Noi, Krabi, 81150",
  },
  {
    id: 810303,
    districtTh: "เกาะลันตา",
    districtEn: "Ko Lanta",
    subdistrictTh: "เกาะกลาง",
    subdistrictEn: "Ko Klang",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8103,
    province_code: 64,
    name: "เกาะลันตา เกาะกลาง กระบี่ 81120",
    name_th: "เกาะลันตา เกาะกลาง กระบี่ 81120",
    name_en: "Ko Lanta, Ko Klang, Krabi, 81120",
  },
  {
    id: 810304,
    districtTh: "เกาะลันตา",
    districtEn: "Ko Lanta",
    subdistrictTh: "คลองยาง",
    subdistrictEn: "Khlong Yang",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8103,
    province_code: 64,
    name: "เกาะลันตา คลองยาง กระบี่ 81120",
    name_th: "เกาะลันตา คลองยาง กระบี่ 81120",
    name_en: "Ko Lanta, Khlong Yang, Krabi, 81120",
  },
  {
    id: 810305,
    districtTh: "เกาะลันตา",
    districtEn: "Ko Lanta",
    subdistrictTh: "ศาลาด่าน",
    subdistrictEn: "Sala Dan",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81150,
    district_code: 8103,
    province_code: 64,
    name: "เกาะลันตา ศาลาด่าน กระบี่ 81150",
    name_th: "เกาะลันตา ศาลาด่าน กระบี่ 81150",
    name_en: "Ko Lanta, Sala Dan, Krabi, 81150",
  },
  {
    id: 810401,
    districtTh: "คลองท่อม",
    districtEn: "Khlong Thom",
    subdistrictTh: "คลองท่อมใต้",
    subdistrictEn: "Khlong Thom Tai",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8104,
    province_code: 64,
    name: "คลองท่อม คลองท่อมใต้ กระบี่ 81120",
    name_th: "คลองท่อม คลองท่อมใต้ กระบี่ 81120",
    name_en: "Khlong Thom, Khlong Thom Tai, Krabi, 81120",
  },
  {
    id: 810402,
    districtTh: "คลองท่อม",
    districtEn: "Khlong Thom",
    subdistrictTh: "คลองท่อมเหนือ",
    subdistrictEn: "Khlong Thom Nuea",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8104,
    province_code: 64,
    name: "คลองท่อม คลองท่อมเหนือ กระบี่ 81120",
    name_th: "คลองท่อม คลองท่อมเหนือ กระบี่ 81120",
    name_en: "Khlong Thom, Khlong Thom Nuea, Krabi, 81120",
  },
  {
    id: 810403,
    districtTh: "คลองท่อม",
    districtEn: "Khlong Thom",
    subdistrictTh: "คลองพน",
    subdistrictEn: "Khlong Phon",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81170,
    district_code: 8104,
    province_code: 64,
    name: "คลองท่อม คลองพน กระบี่ 81170",
    name_th: "คลองท่อม คลองพน กระบี่ 81170",
    name_en: "Khlong Thom, Khlong Phon, Krabi, 81170",
  },
  {
    id: 810404,
    districtTh: "คลองท่อม",
    districtEn: "Khlong Thom",
    subdistrictTh: "ทรายขาว",
    subdistrictEn: "Sai Khao",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81170,
    district_code: 8104,
    province_code: 64,
    name: "คลองท่อม ทรายขาว กระบี่ 81170",
    name_th: "คลองท่อม ทรายขาว กระบี่ 81170",
    name_en: "Khlong Thom, Sai Khao, Krabi, 81170",
  },
  {
    id: 810405,
    districtTh: "คลองท่อม",
    districtEn: "Khlong Thom",
    subdistrictTh: "ห้วยน้ำขาว",
    subdistrictEn: "Huai Nam Khao",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8104,
    province_code: 64,
    name: "คลองท่อม ห้วยน้ำขาว กระบี่ 81120",
    name_th: "คลองท่อม ห้วยน้ำขาว กระบี่ 81120",
    name_en: "Khlong Thom, Huai Nam Khao, Krabi, 81120",
  },
  {
    id: 810406,
    districtTh: "คลองท่อม",
    districtEn: "Khlong Thom",
    subdistrictTh: "พรุดินนา",
    subdistrictEn: "Phru Din Na",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8104,
    province_code: 64,
    name: "คลองท่อม พรุดินนา กระบี่ 81120",
    name_th: "คลองท่อม พรุดินนา กระบี่ 81120",
    name_en: "Khlong Thom, Phru Din Na, Krabi, 81120",
  },
  {
    id: 810407,
    districtTh: "คลองท่อม",
    districtEn: "Khlong Thom",
    subdistrictTh: "เพหลา",
    subdistrictEn: "Phela",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8104,
    province_code: 64,
    name: "คลองท่อม เพหลา กระบี่ 81120",
    name_th: "คลองท่อม เพหลา กระบี่ 81120",
    name_en: "Khlong Thom, Phela, Krabi, 81120",
  },
  {
    id: 810501,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "อ่าวลึกใต้",
    subdistrictEn: "Ao Luek Tai",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก อ่าวลึกใต้ กระบี่ 81110",
    name_th: "อ่าวลึก อ่าวลึกใต้ กระบี่ 81110",
    name_en: "Ao Luek, Ao Luek Tai, Krabi, 81110",
  },
  {
    id: 810502,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "แหลมสัก",
    subdistrictEn: "Laem Sak",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก แหลมสัก กระบี่ 81110",
    name_th: "อ่าวลึก แหลมสัก กระบี่ 81110",
    name_en: "Ao Luek, Laem Sak, Krabi, 81110",
  },
  {
    id: 810503,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "นาเหนือ",
    subdistrictEn: "Na Nuea",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก นาเหนือ กระบี่ 81110",
    name_th: "อ่าวลึก นาเหนือ กระบี่ 81110",
    name_en: "Ao Luek, Na Nuea, Krabi, 81110",
  },
  {
    id: 810504,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "คลองหิน",
    subdistrictEn: "Khlong Hin",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก คลองหิน กระบี่ 81110",
    name_th: "อ่าวลึก คลองหิน กระบี่ 81110",
    name_en: "Ao Luek, Khlong Hin, Krabi, 81110",
  },
  {
    id: 810505,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "อ่าวลึกน้อย",
    subdistrictEn: "Ao Luek Noi",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก อ่าวลึกน้อย กระบี่ 81110",
    name_th: "อ่าวลึก อ่าวลึกน้อย กระบี่ 81110",
    name_en: "Ao Luek, Ao Luek Noi, Krabi, 81110",
  },
  {
    id: 810506,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "อ่าวลึกเหนือ",
    subdistrictEn: "Ao Luek Nuea",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก อ่าวลึกเหนือ กระบี่ 81110",
    name_th: "อ่าวลึก อ่าวลึกเหนือ กระบี่ 81110",
    name_en: "Ao Luek, Ao Luek Nuea, Krabi, 81110",
  },
  {
    id: 810507,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "เขาใหญ่",
    subdistrictEn: "Khao Yai",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก เขาใหญ่ กระบี่ 81110",
    name_th: "อ่าวลึก เขาใหญ่ กระบี่ 81110",
    name_en: "Ao Luek, Khao Yai, Krabi, 81110",
  },
  {
    id: 810508,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "คลองยา",
    subdistrictEn: "Khlong Ya",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก คลองยา กระบี่ 81110",
    name_th: "อ่าวลึก คลองยา กระบี่ 81110",
    name_en: "Ao Luek, Khlong Ya, Krabi, 81110",
  },
  {
    id: 810509,
    districtTh: "อ่าวลึก",
    districtEn: "Ao Luek",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81110,
    district_code: 8105,
    province_code: 64,
    name: "อ่าวลึก บ้านกลาง กระบี่ 81110",
    name_th: "อ่าวลึก บ้านกลาง กระบี่ 81110",
    name_en: "Ao Luek, Ban Klang, Krabi, 81110",
  },
  {
    id: 810601,
    districtTh: "ปลายพระยา",
    districtEn: "Plai Phraya",
    subdistrictTh: "ปลายพระยา",
    subdistrictEn: "Plai Phraya",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81160,
    district_code: 8106,
    province_code: 64,
    name: "ปลายพระยา ปลายพระยา กระบี่ 81160",
    name_th: "ปลายพระยา ปลายพระยา กระบี่ 81160",
    name_en: "Plai Phraya, Plai Phraya, Krabi, 81160",
  },
  {
    id: 810602,
    districtTh: "ปลายพระยา",
    districtEn: "Plai Phraya",
    subdistrictTh: "เขาเขน",
    subdistrictEn: "Khao Khen",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81160,
    district_code: 8106,
    province_code: 64,
    name: "ปลายพระยา เขาเขน กระบี่ 81160",
    name_th: "ปลายพระยา เขาเขน กระบี่ 81160",
    name_en: "Plai Phraya, Khao Khen, Krabi, 81160",
  },
  {
    id: 810603,
    districtTh: "ปลายพระยา",
    districtEn: "Plai Phraya",
    subdistrictTh: "เขาต่อ",
    subdistrictEn: "Khao To",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81160,
    district_code: 8106,
    province_code: 64,
    name: "ปลายพระยา เขาต่อ กระบี่ 81160",
    name_th: "ปลายพระยา เขาต่อ กระบี่ 81160",
    name_en: "Plai Phraya, Khao To, Krabi, 81160",
  },
  {
    id: 810604,
    districtTh: "ปลายพระยา",
    districtEn: "Plai Phraya",
    subdistrictTh: "คีรีวง",
    subdistrictEn: "Khiri Wong",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81160,
    district_code: 8106,
    province_code: 64,
    name: "ปลายพระยา คีรีวง กระบี่ 81160",
    name_th: "ปลายพระยา คีรีวง กระบี่ 81160",
    name_en: "Plai Phraya, Khiri Wong, Krabi, 81160",
  },
  {
    id: 810701,
    districtTh: "ลำทับ",
    districtEn: "Lam Thap",
    subdistrictTh: "ลำทับ",
    subdistrictEn: "Lam Thap",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8107,
    province_code: 64,
    name: "ลำทับ ลำทับ กระบี่ 81120",
    name_th: "ลำทับ ลำทับ กระบี่ 81120",
    name_en: "Lam Thap, Lam Thap, Krabi, 81120",
  },
  {
    id: 810702,
    districtTh: "ลำทับ",
    districtEn: "Lam Thap",
    subdistrictTh: "ดินอุดม",
    subdistrictEn: "Din Udom",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8107,
    province_code: 64,
    name: "ลำทับ ดินอุดม กระบี่ 81120",
    name_th: "ลำทับ ดินอุดม กระบี่ 81120",
    name_en: "Lam Thap, Din Udom, Krabi, 81120",
  },
  {
    id: 810703,
    districtTh: "ลำทับ",
    districtEn: "Lam Thap",
    subdistrictTh: "ทุ่งไทรทอง",
    subdistrictEn: "Thung Sai Thong",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8107,
    province_code: 64,
    name: "ลำทับ ทุ่งไทรทอง กระบี่ 81120",
    name_th: "ลำทับ ทุ่งไทรทอง กระบี่ 81120",
    name_en: "Lam Thap, Thung Sai Thong, Krabi, 81120",
  },
  {
    id: 810704,
    districtTh: "ลำทับ",
    districtEn: "Lam Thap",
    subdistrictTh: "ดินแดง",
    subdistrictEn: "Din Daeng",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81120,
    district_code: 8107,
    province_code: 64,
    name: "ลำทับ ดินแดง กระบี่ 81120",
    name_th: "ลำทับ ดินแดง กระบี่ 81120",
    name_en: "Lam Thap, Din Daeng, Krabi, 81120",
  },
  {
    id: 810801,
    districtTh: "เหนือคลอง",
    districtEn: "Nuea Khlong",
    subdistrictTh: "เหนือคลอง",
    subdistrictEn: "Nuea Khlong",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81130,
    district_code: 8108,
    province_code: 64,
    name: "เหนือคลอง เหนือคลอง กระบี่ 81130",
    name_th: "เหนือคลอง เหนือคลอง กระบี่ 81130",
    name_en: "Nuea Khlong, Nuea Khlong, Krabi, 81130",
  },
  {
    id: 810802,
    districtTh: "เหนือคลอง",
    districtEn: "Nuea Khlong",
    subdistrictTh: "เกาะศรีบอยา",
    subdistrictEn: "Ko Si Boya",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81130,
    district_code: 8108,
    province_code: 64,
    name: "เหนือคลอง เกาะศรีบอยา กระบี่ 81130",
    name_th: "เหนือคลอง เกาะศรีบอยา กระบี่ 81130",
    name_en: "Nuea Khlong, Ko Si Boya, Krabi, 81130",
  },
  {
    id: 810803,
    districtTh: "เหนือคลอง",
    districtEn: "Nuea Khlong",
    subdistrictTh: "คลองขนาน",
    subdistrictEn: "Khlong Khanan",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81130,
    district_code: 8108,
    province_code: 64,
    name: "เหนือคลอง คลองขนาน กระบี่ 81130",
    name_th: "เหนือคลอง คลองขนาน กระบี่ 81130",
    name_en: "Nuea Khlong, Khlong Khanan, Krabi, 81130",
  },
  {
    id: 810804,
    districtTh: "เหนือคลอง",
    districtEn: "Nuea Khlong",
    subdistrictTh: "คลองเขม้า",
    subdistrictEn: "Khlong Khamao",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81130,
    district_code: 8108,
    province_code: 64,
    name: "เหนือคลอง คลองเขม้า กระบี่ 81130",
    name_th: "เหนือคลอง คลองเขม้า กระบี่ 81130",
    name_en: "Nuea Khlong, Khlong Khamao, Krabi, 81130",
  },
  {
    id: 810805,
    districtTh: "เหนือคลอง",
    districtEn: "Nuea Khlong",
    subdistrictTh: "โคกยาง",
    subdistrictEn: "Khok Yang",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81130,
    district_code: 8108,
    province_code: 64,
    name: "เหนือคลอง โคกยาง กระบี่ 81130",
    name_th: "เหนือคลอง โคกยาง กระบี่ 81130",
    name_en: "Nuea Khlong, Khok Yang, Krabi, 81130",
  },
  {
    id: 810806,
    districtTh: "เหนือคลอง",
    districtEn: "Nuea Khlong",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81130,
    district_code: 8108,
    province_code: 64,
    name: "เหนือคลอง ตลิ่งชัน กระบี่ 81130",
    name_th: "เหนือคลอง ตลิ่งชัน กระบี่ 81130",
    name_en: "Nuea Khlong, Taling Chan, Krabi, 81130",
  },
  {
    id: 810807,
    districtTh: "เหนือคลอง",
    districtEn: "Nuea Khlong",
    subdistrictTh: "ปกาสัย",
    subdistrictEn: "Pakasai",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81130,
    district_code: 8108,
    province_code: 64,
    name: "เหนือคลอง ปกาสัย กระบี่ 81130",
    name_th: "เหนือคลอง ปกาสัย กระบี่ 81130",
    name_en: "Nuea Khlong, Pakasai, Krabi, 81130",
  },
  {
    id: 810808,
    districtTh: "เหนือคลอง",
    districtEn: "Nuea Khlong",
    subdistrictTh: "ห้วยยูง",
    subdistrictEn: "Huai Yung",
    provinceTh: "กระบี่",
    provinceEn: "Krabi",
    zipcode: 81130,
    district_code: 8108,
    province_code: 64,
    name: "เหนือคลอง ห้วยยูง กระบี่ 81130",
    name_th: "เหนือคลอง ห้วยยูง กระบี่ 81130",
    name_en: "Nuea Khlong, Huai Yung, Krabi, 81130",
  },
  {
    id: 820101,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "ท้ายช้าง",
    subdistrictEn: "Thai Chang",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา ท้ายช้าง พังงา 82000",
    name_th: "เมืองพังงา ท้ายช้าง พังงา 82000",
    name_en: "Mueang Phang-nga, Thai Chang, Phangnga, 82000",
  },
  {
    id: 820102,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "นบปริง",
    subdistrictEn: "Nop Pring",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา นบปริง พังงา 82000",
    name_th: "เมืองพังงา นบปริง พังงา 82000",
    name_en: "Mueang Phang-nga, Nop Pring, Phangnga, 82000",
  },
  {
    id: 820103,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "ถ้ำน้ำผุด",
    subdistrictEn: "Tham Nam Phut",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา ถ้ำน้ำผุด พังงา 82000",
    name_th: "เมืองพังงา ถ้ำน้ำผุด พังงา 82000",
    name_en: "Mueang Phang-nga, Tham Nam Phut, Phangnga, 82000",
  },
  {
    id: 820104,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "บางเตย",
    subdistrictEn: "Bang Toei",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา บางเตย พังงา 82000",
    name_th: "เมืองพังงา บางเตย พังงา 82000",
    name_en: "Mueang Phang-nga, Bang Toei, Phangnga, 82000",
  },
  {
    id: 820105,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "ตากแดด",
    subdistrictEn: "Tak Daet",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา ตากแดด พังงา 82000",
    name_th: "เมืองพังงา ตากแดด พังงา 82000",
    name_en: "Mueang Phang-nga, Tak Daet, Phangnga, 82000",
  },
  {
    id: 820106,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "สองแพรก",
    subdistrictEn: "Song Phraek",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา สองแพรก พังงา 82000",
    name_th: "เมืองพังงา สองแพรก พังงา 82000",
    name_en: "Mueang Phang-nga, Song Phraek, Phangnga, 82000",
  },
  {
    id: 820107,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "ทุ่งคาโงก",
    subdistrictEn: "Thung Kha Ngok",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา ทุ่งคาโงก พังงา 82000",
    name_th: "เมืองพังงา ทุ่งคาโงก พังงา 82000",
    name_en: "Mueang Phang-nga, Thung Kha Ngok, Phangnga, 82000",
  },
  {
    id: 820108,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "เกาะปันหยี",
    subdistrictEn: "Ko Panyi",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา เกาะปันหยี พังงา 82000",
    name_th: "เมืองพังงา เกาะปันหยี พังงา 82000",
    name_en: "Mueang Phang-nga, Ko Panyi, Phangnga, 82000",
  },
  {
    id: 820109,
    districtTh: "เมืองพังงา",
    districtEn: "Mueang Phang-nga",
    subdistrictTh: "ป่ากอ",
    subdistrictEn: "Pa Ko",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82000,
    district_code: 8201,
    province_code: 65,
    name: "เมืองพังงา ป่ากอ พังงา 82000",
    name_th: "เมืองพังงา ป่ากอ พังงา 82000",
    name_en: "Mueang Phang-nga, Pa Ko, Phangnga, 82000",
  },
  {
    id: 820201,
    districtTh: "เกาะยาว",
    districtEn: "Ko Yao",
    subdistrictTh: "เกาะยาวน้อย",
    subdistrictEn: "Ko Yao Noi",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82160,
    district_code: 8202,
    province_code: 65,
    name: "เกาะยาว เกาะยาวน้อย พังงา 82160",
    name_th: "เกาะยาว เกาะยาวน้อย พังงา 82160",
    name_en: "Ko Yao, Ko Yao Noi, Phangnga, 82160",
  },
  {
    id: 820202,
    districtTh: "เกาะยาว",
    districtEn: "Ko Yao",
    subdistrictTh: "เกาะยาวใหญ่",
    subdistrictEn: "Ko Yao Yai",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82160,
    district_code: 8202,
    province_code: 65,
    name: "เกาะยาว เกาะยาวใหญ่ พังงา 82160",
    name_th: "เกาะยาว เกาะยาวใหญ่ พังงา 82160",
    name_en: "Ko Yao, Ko Yao Yai, Phangnga, 82160",
  },
  {
    id: 820203,
    districtTh: "เกาะยาว",
    districtEn: "Ko Yao",
    subdistrictTh: "พรุใน",
    subdistrictEn: "Pru Nai",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 83000,
    district_code: 8202,
    province_code: 65,
    name: "เกาะยาว พรุใน พังงา 83000",
    name_th: "เกาะยาว พรุใน พังงา 83000",
    name_en: "Ko Yao, Pru Nai, Phangnga, 83000",
  },
  {
    id: 820301,
    districtTh: "กะปง",
    districtEn: "Kapong",
    subdistrictTh: "กะปง",
    subdistrictEn: "Kapong",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82170,
    district_code: 8203,
    province_code: 65,
    name: "กะปง กะปง พังงา 82170",
    name_th: "กะปง กะปง พังงา 82170",
    name_en: "Kapong, Kapong, Phangnga, 82170",
  },
  {
    id: 820302,
    districtTh: "กะปง",
    districtEn: "Kapong",
    subdistrictTh: "ท่านา",
    subdistrictEn: "Tha Na",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82170,
    district_code: 8203,
    province_code: 65,
    name: "กะปง ท่านา พังงา 82170",
    name_th: "กะปง ท่านา พังงา 82170",
    name_en: "Kapong, Tha Na, Phangnga, 82170",
  },
  {
    id: 820303,
    districtTh: "กะปง",
    districtEn: "Kapong",
    subdistrictTh: "เหมาะ",
    subdistrictEn: "Mo",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82170,
    district_code: 8203,
    province_code: 65,
    name: "กะปง เหมาะ พังงา 82170",
    name_th: "กะปง เหมาะ พังงา 82170",
    name_en: "Kapong, Mo, Phangnga, 82170",
  },
  {
    id: 820304,
    districtTh: "กะปง",
    districtEn: "Kapong",
    subdistrictTh: "เหล",
    subdistrictEn: "Le",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82170,
    district_code: 8203,
    province_code: 65,
    name: "กะปง เหล พังงา 82170",
    name_th: "กะปง เหล พังงา 82170",
    name_en: "Kapong, Le, Phangnga, 82170",
  },
  {
    id: 820305,
    districtTh: "กะปง",
    districtEn: "Kapong",
    subdistrictTh: "รมณีย์",
    subdistrictEn: "Rommani",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82170,
    district_code: 8203,
    province_code: 65,
    name: "กะปง รมณีย์ พังงา 82170",
    name_th: "กะปง รมณีย์ พังงา 82170",
    name_en: "Kapong, Rommani, Phangnga, 82170",
  },
  {
    id: 820401,
    districtTh: "ตะกั่วทุ่ง",
    districtEn: "Takua Thung",
    subdistrictTh: "ถ้ำ",
    subdistrictEn: "Tham",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82130,
    district_code: 8204,
    province_code: 65,
    name: "ตะกั่วทุ่ง ถ้ำ พังงา 82130",
    name_th: "ตะกั่วทุ่ง ถ้ำ พังงา 82130",
    name_en: "Takua Thung, Tham, Phangnga, 82130",
  },
  {
    id: 820402,
    districtTh: "ตะกั่วทุ่ง",
    districtEn: "Takua Thung",
    subdistrictTh: "กระโสม",
    subdistrictEn: "Krasom",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82130,
    district_code: 8204,
    province_code: 65,
    name: "ตะกั่วทุ่ง กระโสม พังงา 82130",
    name_th: "ตะกั่วทุ่ง กระโสม พังงา 82130",
    name_en: "Takua Thung, Krasom, Phangnga, 82130",
  },
  {
    id: 820403,
    districtTh: "ตะกั่วทุ่ง",
    districtEn: "Takua Thung",
    subdistrictTh: "กะไหล",
    subdistrictEn: "Kalai",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82130,
    district_code: 8204,
    province_code: 65,
    name: "ตะกั่วทุ่ง กะไหล พังงา 82130",
    name_th: "ตะกั่วทุ่ง กะไหล พังงา 82130",
    name_en: "Takua Thung, Kalai, Phangnga, 82130",
  },
  {
    id: 820404,
    districtTh: "ตะกั่วทุ่ง",
    districtEn: "Takua Thung",
    subdistrictTh: "ท่าอยู่",
    subdistrictEn: "Tha Yu",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82130,
    district_code: 8204,
    province_code: 65,
    name: "ตะกั่วทุ่ง ท่าอยู่ พังงา 82130",
    name_th: "ตะกั่วทุ่ง ท่าอยู่ พังงา 82130",
    name_en: "Takua Thung, Tha Yu, Phangnga, 82130",
  },
  {
    id: 820405,
    districtTh: "ตะกั่วทุ่ง",
    districtEn: "Takua Thung",
    subdistrictTh: "หล่อยูง",
    subdistrictEn: "Lo Yung",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82140,
    district_code: 8204,
    province_code: 65,
    name: "ตะกั่วทุ่ง หล่อยูง พังงา 82140",
    name_th: "ตะกั่วทุ่ง หล่อยูง พังงา 82140",
    name_en: "Takua Thung, Lo Yung, Phangnga, 82140",
  },
  {
    id: 820406,
    districtTh: "ตะกั่วทุ่ง",
    districtEn: "Takua Thung",
    subdistrictTh: "โคกกลอย",
    subdistrictEn: "Khok Kloi",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82140,
    district_code: 8204,
    province_code: 65,
    name: "ตะกั่วทุ่ง โคกกลอย พังงา 82140",
    name_th: "ตะกั่วทุ่ง โคกกลอย พังงา 82140",
    name_en: "Takua Thung, Khok Kloi, Phangnga, 82140",
  },
  {
    id: 820407,
    districtTh: "ตะกั่วทุ่ง",
    districtEn: "Takua Thung",
    subdistrictTh: "คลองเคียน",
    subdistrictEn: "Khlong Khian",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82130,
    district_code: 8204,
    province_code: 65,
    name: "ตะกั่วทุ่ง คลองเคียน พังงา 82130",
    name_th: "ตะกั่วทุ่ง คลองเคียน พังงา 82130",
    name_en: "Takua Thung, Khlong Khian, Phangnga, 82130",
  },
  {
    id: 820501,
    districtTh: "ตะกั่วป่า",
    districtEn: "Takua Pa",
    subdistrictTh: "ตะกั่วป่า",
    subdistrictEn: "Takua Pa",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82110,
    district_code: 8205,
    province_code: 65,
    name: "ตะกั่วป่า ตะกั่วป่า พังงา 82110",
    name_th: "ตะกั่วป่า ตะกั่วป่า พังงา 82110",
    name_en: "Takua Pa, Takua Pa, Phangnga, 82110",
  },
  {
    id: 820502,
    districtTh: "ตะกั่วป่า",
    districtEn: "Takua Pa",
    subdistrictTh: "บางนายสี",
    subdistrictEn: "Bang Nai Si",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82110,
    district_code: 8205,
    province_code: 65,
    name: "ตะกั่วป่า บางนายสี พังงา 82110",
    name_th: "ตะกั่วป่า บางนายสี พังงา 82110",
    name_en: "Takua Pa, Bang Nai Si, Phangnga, 82110",
  },
  {
    id: 820504,
    districtTh: "ตะกั่วป่า",
    districtEn: "Takua Pa",
    subdistrictTh: "บางม่วง",
    subdistrictEn: "Bang Muang",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82110,
    district_code: 8205,
    province_code: 65,
    name: "ตะกั่วป่า บางม่วง พังงา 82110",
    name_th: "ตะกั่วป่า บางม่วง พังงา 82110",
    name_en: "Takua Pa, Bang Muang, Phangnga, 82110",
  },
  {
    id: 820505,
    districtTh: "ตะกั่วป่า",
    districtEn: "Takua Pa",
    subdistrictTh: "ตำตัว",
    subdistrictEn: "Tam Tua",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82110,
    district_code: 8205,
    province_code: 65,
    name: "ตะกั่วป่า ตำตัว พังงา 82110",
    name_th: "ตะกั่วป่า ตำตัว พังงา 82110",
    name_en: "Takua Pa, Tam Tua, Phangnga, 82110",
  },
  {
    id: 820506,
    districtTh: "ตะกั่วป่า",
    districtEn: "Takua Pa",
    subdistrictTh: "โคกเคียน",
    subdistrictEn: "Khok Khian",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82110,
    district_code: 8205,
    province_code: 65,
    name: "ตะกั่วป่า โคกเคียน พังงา 82110",
    name_th: "ตะกั่วป่า โคกเคียน พังงา 82110",
    name_en: "Takua Pa, Khok Khian, Phangnga, 82110",
  },
  {
    id: 820507,
    districtTh: "ตะกั่วป่า",
    districtEn: "Takua Pa",
    subdistrictTh: "คึกคัก",
    subdistrictEn: "Khuekkhak",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82190,
    district_code: 8205,
    province_code: 65,
    name: "ตะกั่วป่า คึกคัก พังงา 82190",
    name_th: "ตะกั่วป่า คึกคัก พังงา 82190",
    name_en: "Takua Pa, Khuekkhak, Phangnga, 82190",
  },
  {
    id: 820508,
    districtTh: "ตะกั่วป่า",
    districtEn: "Takua Pa",
    subdistrictTh: "เกาะคอเขา",
    subdistrictEn: "Ko Kho Khao",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82190,
    district_code: 8205,
    province_code: 65,
    name: "ตะกั่วป่า เกาะคอเขา พังงา 82190",
    name_th: "ตะกั่วป่า เกาะคอเขา พังงา 82190",
    name_en: "Takua Pa, Ko Kho Khao, Phangnga, 82190",
  },
  {
    id: 820601,
    districtTh: "คุระบุรี",
    districtEn: "Khura Buri",
    subdistrictTh: "คุระ",
    subdistrictEn: "Khura",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82150,
    district_code: 8206,
    province_code: 65,
    name: "คุระบุรี คุระ พังงา 82150",
    name_th: "คุระบุรี คุระ พังงา 82150",
    name_en: "Khura Buri, Khura, Phangnga, 82150",
  },
  {
    id: 820602,
    districtTh: "คุระบุรี",
    districtEn: "Khura Buri",
    subdistrictTh: "บางวัน",
    subdistrictEn: "Bang Wan",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82150,
    district_code: 8206,
    province_code: 65,
    name: "คุระบุรี บางวัน พังงา 82150",
    name_th: "คุระบุรี บางวัน พังงา 82150",
    name_en: "Khura Buri, Bang Wan, Phangnga, 82150",
  },
  {
    id: 820603,
    districtTh: "คุระบุรี",
    districtEn: "Khura Buri",
    subdistrictTh: "เกาะพระทอง",
    subdistrictEn: "Ko Phra Thong",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82150,
    district_code: 8206,
    province_code: 65,
    name: "คุระบุรี เกาะพระทอง พังงา 82150",
    name_th: "คุระบุรี เกาะพระทอง พังงา 82150",
    name_en: "Khura Buri, Ko Phra Thong, Phangnga, 82150",
  },
  {
    id: 820605,
    districtTh: "คุระบุรี",
    districtEn: "Khura Buri",
    subdistrictTh: "แม่นางขาว",
    subdistrictEn: "Mae Nang Khao",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82150,
    district_code: 8206,
    province_code: 65,
    name: "คุระบุรี แม่นางขาว พังงา 82150",
    name_th: "คุระบุรี แม่นางขาว พังงา 82150",
    name_en: "Khura Buri, Mae Nang Khao, Phangnga, 82150",
  },
  {
    id: 820701,
    districtTh: "ทับปุด",
    districtEn: "Thap Put",
    subdistrictTh: "ทับปุด",
    subdistrictEn: "Thap Put",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82180,
    district_code: 8207,
    province_code: 65,
    name: "ทับปุด ทับปุด พังงา 82180",
    name_th: "ทับปุด ทับปุด พังงา 82180",
    name_en: "Thap Put, Thap Put, Phangnga, 82180",
  },
  {
    id: 820702,
    districtTh: "ทับปุด",
    districtEn: "Thap Put",
    subdistrictTh: "มะรุ่ย",
    subdistrictEn: "Marui",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82180,
    district_code: 8207,
    province_code: 65,
    name: "ทับปุด มะรุ่ย พังงา 82180",
    name_th: "ทับปุด มะรุ่ย พังงา 82180",
    name_en: "Thap Put, Marui, Phangnga, 82180",
  },
  {
    id: 820703,
    districtTh: "ทับปุด",
    districtEn: "Thap Put",
    subdistrictTh: "บ่อแสน",
    subdistrictEn: "Bo Saen",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82180,
    district_code: 8207,
    province_code: 65,
    name: "ทับปุด บ่อแสน พังงา 82180",
    name_th: "ทับปุด บ่อแสน พังงา 82180",
    name_en: "Thap Put, Bo Saen, Phangnga, 82180",
  },
  {
    id: 820704,
    districtTh: "ทับปุด",
    districtEn: "Thap Put",
    subdistrictTh: "ถ้ำทองหลาง",
    subdistrictEn: "Tham Thonglang",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82180,
    district_code: 8207,
    province_code: 65,
    name: "ทับปุด ถ้ำทองหลาง พังงา 82180",
    name_th: "ทับปุด ถ้ำทองหลาง พังงา 82180",
    name_en: "Thap Put, Tham Thonglang, Phangnga, 82180",
  },
  {
    id: 820705,
    districtTh: "ทับปุด",
    districtEn: "Thap Put",
    subdistrictTh: "โคกเจริญ",
    subdistrictEn: "Khok Charoen",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82180,
    district_code: 8207,
    province_code: 65,
    name: "ทับปุด โคกเจริญ พังงา 82180",
    name_th: "ทับปุด โคกเจริญ พังงา 82180",
    name_en: "Thap Put, Khok Charoen, Phangnga, 82180",
  },
  {
    id: 820706,
    districtTh: "ทับปุด",
    districtEn: "Thap Put",
    subdistrictTh: "บางเหรียง",
    subdistrictEn: "Bang Riang",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82180,
    district_code: 8207,
    province_code: 65,
    name: "ทับปุด บางเหรียง พังงา 82180",
    name_th: "ทับปุด บางเหรียง พังงา 82180",
    name_en: "Thap Put, Bang Riang, Phangnga, 82180",
  },
  {
    id: 820801,
    districtTh: "ท้ายเหมือง",
    districtEn: "Thai Mueang",
    subdistrictTh: "ท้ายเหมือง",
    subdistrictEn: "Thai Mueang",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82120,
    district_code: 8208,
    province_code: 65,
    name: "ท้ายเหมือง ท้ายเหมือง พังงา 82120",
    name_th: "ท้ายเหมือง ท้ายเหมือง พังงา 82120",
    name_en: "Thai Mueang, Thai Mueang, Phangnga, 82120",
  },
  {
    id: 820802,
    districtTh: "ท้ายเหมือง",
    districtEn: "Thai Mueang",
    subdistrictTh: "นาเตย",
    subdistrictEn: "Na Toei",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82120,
    district_code: 8208,
    province_code: 65,
    name: "ท้ายเหมือง นาเตย พังงา 82120",
    name_th: "ท้ายเหมือง นาเตย พังงา 82120",
    name_en: "Thai Mueang, Na Toei, Phangnga, 82120",
  },
  {
    id: 820803,
    districtTh: "ท้ายเหมือง",
    districtEn: "Thai Mueang",
    subdistrictTh: "บางทอง",
    subdistrictEn: "Bang Thong",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82120,
    district_code: 8208,
    province_code: 65,
    name: "ท้ายเหมือง บางทอง พังงา 82120",
    name_th: "ท้ายเหมือง บางทอง พังงา 82120",
    name_en: "Thai Mueang, Bang Thong, Phangnga, 82120",
  },
  {
    id: 820804,
    districtTh: "ท้ายเหมือง",
    districtEn: "Thai Mueang",
    subdistrictTh: "ทุ่งมะพร้าว",
    subdistrictEn: "Thung Maphrao",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82120,
    district_code: 8208,
    province_code: 65,
    name: "ท้ายเหมือง ทุ่งมะพร้าว พังงา 82120",
    name_th: "ท้ายเหมือง ทุ่งมะพร้าว พังงา 82120",
    name_en: "Thai Mueang, Thung Maphrao, Phangnga, 82120",
  },
  {
    id: 820805,
    districtTh: "ท้ายเหมือง",
    districtEn: "Thai Mueang",
    subdistrictTh: "ลำภี",
    subdistrictEn: "Lam Phi",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82120,
    district_code: 8208,
    province_code: 65,
    name: "ท้ายเหมือง ลำภี พังงา 82120",
    name_th: "ท้ายเหมือง ลำภี พังงา 82120",
    name_en: "Thai Mueang, Lam Phi, Phangnga, 82120",
  },
  {
    id: 820806,
    districtTh: "ท้ายเหมือง",
    districtEn: "Thai Mueang",
    subdistrictTh: "ลำแก่น",
    subdistrictEn: "Lam Kaen",
    provinceTh: "พังงา",
    provinceEn: "Phangnga",
    zipcode: 82120,
    district_code: 8208,
    province_code: 65,
    name: "ท้ายเหมือง ลำแก่น พังงา 82120",
    name_th: "ท้ายเหมือง ลำแก่น พังงา 82120",
    name_en: "Thai Mueang, Lam Kaen, Phangnga, 82120",
  },
  {
    id: 830101,
    districtTh: "เมืองภูเก็ต",
    districtEn: "Mueang Phuket",
    subdistrictTh: "ตลาดใหญ่",
    subdistrictEn: "Talat Yai",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83000,
    district_code: 8301,
    province_code: 66,
    name: "เมืองภูเก็ต ตลาดใหญ่ ภูเก็ต 83000",
    name_th: "เมืองภูเก็ต ตลาดใหญ่ ภูเก็ต 83000",
    name_en: "Mueang Phuket, Talat Yai, Phuket, 83000",
  },
  {
    id: 830102,
    districtTh: "เมืองภูเก็ต",
    districtEn: "Mueang Phuket",
    subdistrictTh: "ตลาดเหนือ",
    subdistrictEn: "Talat Nuea",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83000,
    district_code: 8301,
    province_code: 66,
    name: "เมืองภูเก็ต ตลาดเหนือ ภูเก็ต 83000",
    name_th: "เมืองภูเก็ต ตลาดเหนือ ภูเก็ต 83000",
    name_en: "Mueang Phuket, Talat Nuea, Phuket, 83000",
  },
  {
    id: 830103,
    districtTh: "เมืองภูเก็ต",
    districtEn: "Mueang Phuket",
    subdistrictTh: "เกาะแก้ว",
    subdistrictEn: "Ko Kaeo",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83000,
    district_code: 8301,
    province_code: 66,
    name: "เมืองภูเก็ต เกาะแก้ว ภูเก็ต 83000",
    name_th: "เมืองภูเก็ต เกาะแก้ว ภูเก็ต 83000",
    name_en: "Mueang Phuket, Ko Kaeo, Phuket, 83000",
  },
  {
    id: 830104,
    districtTh: "เมืองภูเก็ต",
    districtEn: "Mueang Phuket",
    subdistrictTh: "รัษฎา",
    subdistrictEn: "Ratsada",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83000,
    district_code: 8301,
    province_code: 66,
    name: "เมืองภูเก็ต รัษฎา ภูเก็ต 83000",
    name_th: "เมืองภูเก็ต รัษฎา ภูเก็ต 83000",
    name_en: "Mueang Phuket, Ratsada, Phuket, 83000",
  },
  {
    id: 830105,
    districtTh: "เมืองภูเก็ต",
    districtEn: "Mueang Phuket",
    subdistrictTh: "วิชิต",
    subdistrictEn: "Wichit",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83000,
    district_code: 8301,
    province_code: 66,
    name: "เมืองภูเก็ต วิชิต ภูเก็ต 83000",
    name_th: "เมืองภูเก็ต วิชิต ภูเก็ต 83000",
    name_en: "Mueang Phuket, Wichit, Phuket, 83000",
  },
  {
    id: 830106,
    districtTh: "เมืองภูเก็ต",
    districtEn: "Mueang Phuket",
    subdistrictTh: "ฉลอง",
    subdistrictEn: "Chalong",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83130,
    district_code: 8301,
    province_code: 66,
    name: "เมืองภูเก็ต ฉลอง ภูเก็ต 83130",
    name_th: "เมืองภูเก็ต ฉลอง ภูเก็ต 83130",
    name_en: "Mueang Phuket, Chalong, Phuket, 83130",
  },
  {
    id: 830107,
    districtTh: "เมืองภูเก็ต",
    districtEn: "Mueang Phuket",
    subdistrictTh: "ราไวย์",
    subdistrictEn: "Rawai",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83130,
    district_code: 8301,
    province_code: 66,
    name: "เมืองภูเก็ต ราไวย์ ภูเก็ต 83130",
    name_th: "เมืองภูเก็ต ราไวย์ ภูเก็ต 83130",
    name_en: "Mueang Phuket, Rawai, Phuket, 83130",
  },
  {
    id: 830108,
    districtTh: "เมืองภูเก็ต",
    districtEn: "Mueang Phuket",
    subdistrictTh: "กะรน",
    subdistrictEn: "Karon",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83100,
    district_code: 8301,
    province_code: 66,
    name: "เมืองภูเก็ต กะรน ภูเก็ต 83100",
    name_th: "เมืองภูเก็ต กะรน ภูเก็ต 83100",
    name_en: "Mueang Phuket, Karon, Phuket, 83100",
  },
  {
    id: 830201,
    districtTh: "กะทู้",
    districtEn: "Kathu",
    subdistrictTh: "กะทู้",
    subdistrictEn: "Kathu",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83120,
    district_code: 8302,
    province_code: 66,
    name: "กะทู้ กะทู้ ภูเก็ต 83120",
    name_th: "กะทู้ กะทู้ ภูเก็ต 83120",
    name_en: "Kathu, Kathu, Phuket, 83120",
  },
  {
    id: 830202,
    districtTh: "กะทู้",
    districtEn: "Kathu",
    subdistrictTh: "ป่าตอง",
    subdistrictEn: "Pa Tong",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83150,
    district_code: 8302,
    province_code: 66,
    name: "กะทู้ ป่าตอง ภูเก็ต 83150",
    name_th: "กะทู้ ป่าตอง ภูเก็ต 83150",
    name_en: "Kathu, Pa Tong, Phuket, 83150",
  },
  {
    id: 830203,
    districtTh: "กะทู้",
    districtEn: "Kathu",
    subdistrictTh: "กมลา",
    subdistrictEn: "Kamala",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83150,
    district_code: 8302,
    province_code: 66,
    name: "กะทู้ กมลา ภูเก็ต 83150",
    name_th: "กะทู้ กมลา ภูเก็ต 83150",
    name_en: "Kathu, Kamala, Phuket, 83150",
  },
  {
    id: 830301,
    districtTh: "ถลาง",
    districtEn: "Thalang",
    subdistrictTh: "เทพกระษัตรี",
    subdistrictEn: "Thep Krasattri",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83110,
    district_code: 8303,
    province_code: 66,
    name: "ถลาง เทพกระษัตรี ภูเก็ต 83110",
    name_th: "ถลาง เทพกระษัตรี ภูเก็ต 83110",
    name_en: "Thalang, Thep Krasattri, Phuket, 83110",
  },
  {
    id: 830302,
    districtTh: "ถลาง",
    districtEn: "Thalang",
    subdistrictTh: "ศรีสุนทร",
    subdistrictEn: "Si Sunthon",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83110,
    district_code: 8303,
    province_code: 66,
    name: "ถลาง ศรีสุนทร ภูเก็ต 83110",
    name_th: "ถลาง ศรีสุนทร ภูเก็ต 83110",
    name_en: "Thalang, Si Sunthon, Phuket, 83110",
  },
  {
    id: 830303,
    districtTh: "ถลาง",
    districtEn: "Thalang",
    subdistrictTh: "เชิงทะเล",
    subdistrictEn: "Choeng Thale",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83110,
    district_code: 8303,
    province_code: 66,
    name: "ถลาง เชิงทะเล ภูเก็ต 83110",
    name_th: "ถลาง เชิงทะเล ภูเก็ต 83110",
    name_en: "Thalang, Choeng Thale, Phuket, 83110",
  },
  {
    id: 830304,
    districtTh: "ถลาง",
    districtEn: "Thalang",
    subdistrictTh: "ป่าคลอก",
    subdistrictEn: "Pa Khlok",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83110,
    district_code: 8303,
    province_code: 66,
    name: "ถลาง ป่าคลอก ภูเก็ต 83110",
    name_th: "ถลาง ป่าคลอก ภูเก็ต 83110",
    name_en: "Thalang, Pa Khlok, Phuket, 83110",
  },
  {
    id: 830305,
    districtTh: "ถลาง",
    districtEn: "Thalang",
    subdistrictTh: "ไม้ขาว",
    subdistrictEn: "Mai Khao",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83110,
    district_code: 8303,
    province_code: 66,
    name: "ถลาง ไม้ขาว ภูเก็ต 83110",
    name_th: "ถลาง ไม้ขาว ภูเก็ต 83110",
    name_en: "Thalang, Mai Khao, Phuket, 83110",
  },
  {
    id: 830306,
    districtTh: "ถลาง",
    districtEn: "Thalang",
    subdistrictTh: "สาคู",
    subdistrictEn: "Sakhu",
    provinceTh: "ภูเก็ต",
    provinceEn: "Phuket",
    zipcode: 83110,
    district_code: 8303,
    province_code: 66,
    name: "ถลาง สาคู ภูเก็ต 83110",
    name_th: "ถลาง สาคู ภูเก็ต 83110",
    name_en: "Thalang, Sakhu, Phuket, 83110",
  },
  {
    id: 840101,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "ตลาด",
    subdistrictEn: "Talat",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี ตลาด สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี ตลาด สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Talat, Surat Thani, 84000",
  },
  {
    id: 840102,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "มะขามเตี้ย",
    subdistrictEn: "Makham Tia",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี มะขามเตี้ย สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี มะขามเตี้ย สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Makham Tia, Surat Thani, 84000",
  },
  {
    id: 840103,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "วัดประดู่",
    subdistrictEn: "Wat Pradu",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี วัดประดู่ สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี วัดประดู่ สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Wat Pradu, Surat Thani, 84000",
  },
  {
    id: 840104,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "ขุนทะเล",
    subdistrictEn: "Khun Thale",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84100,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี ขุนทะเล สุราษฎร์ธานี 84100",
    name_th: "เมืองสุราษฎร์ธานี ขุนทะเล สุราษฎร์ธานี 84100",
    name_en: "Mueang Surat Thani, Khun Thale, Surat Thani, 84100",
  },
  {
    id: 840105,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "บางใบไม้",
    subdistrictEn: "Bang Bai Mai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี บางใบไม้ สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี บางใบไม้ สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Bang Bai Mai, Surat Thani, 84000",
  },
  {
    id: 840106,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "บางชนะ",
    subdistrictEn: "Bang Chana",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี บางชนะ สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี บางชนะ สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Bang Chana, Surat Thani, 84000",
  },
  {
    id: 840107,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "คลองน้อย",
    subdistrictEn: "Khlong Noi",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี คลองน้อย สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี คลองน้อย สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Khlong Noi, Surat Thani, 84000",
  },
  {
    id: 840108,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "บางไทร",
    subdistrictEn: "Bang Sai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี บางไทร สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี บางไทร สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Bang Sai, Surat Thani, 84000",
  },
  {
    id: 840109,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "บางโพธิ์",
    subdistrictEn: "Bang Pho",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี บางโพธิ์ สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี บางโพธิ์ สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Bang Pho, Surat Thani, 84000",
  },
  {
    id: 840110,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "บางกุ้ง",
    subdistrictEn: "Bang Kung",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี บางกุ้ง สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี บางกุ้ง สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Bang Kung, Surat Thani, 84000",
  },
  {
    id: 840111,
    districtTh: "เมืองสุราษฎร์ธานี",
    districtEn: "Mueang Surat Thani",
    subdistrictTh: "คลองฉนาก",
    subdistrictEn: "Khlong Chanak",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84000,
    district_code: 8401,
    province_code: 67,
    name: "เมืองสุราษฎร์ธานี คลองฉนาก สุราษฎร์ธานี 84000",
    name_th: "เมืองสุราษฎร์ธานี คลองฉนาก สุราษฎร์ธานี 84000",
    name_en: "Mueang Surat Thani, Khlong Chanak, Surat Thani, 84000",
  },
  {
    id: 840201,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ท่าทองใหม่",
    subdistrictEn: "Tha Thong",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84290,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ท่าทองใหม่ สุราษฎร์ธานี 84290",
    name_th: "กาญจนดิษฐ์ ท่าทองใหม่ สุราษฎร์ธานี 84290",
    name_en: "Kanchanadit, Tha Thong, Surat Thani, 84290",
  },
  {
    id: 840202,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ท่าทอง",
    subdistrictEn: "Tha Thong Mai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ท่าทอง สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ ท่าทอง สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Tha Thong Mai, Surat Thani, 84160",
  },
  {
    id: 840203,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "กะแดะ",
    subdistrictEn: "Kadae",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ กะแดะ สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ กะแดะ สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Kadae, Surat Thani, 84160",
  },
  {
    id: 840204,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ทุ่งกง",
    subdistrictEn: "Thung Kong",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84290,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ทุ่งกง สุราษฎร์ธานี 84290",
    name_th: "กาญจนดิษฐ์ ทุ่งกง สุราษฎร์ธานี 84290",
    name_en: "Kanchanadit, Thung Kong, Surat Thani, 84290",
  },
  {
    id: 840205,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "กรูด",
    subdistrictEn: "Krut",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ กรูด สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ กรูด สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Krut, Surat Thani, 84160",
  },
  {
    id: 840206,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ช้างซ้าย",
    subdistrictEn: "Chang Sai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ช้างซ้าย สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ ช้างซ้าย สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Chang Sai, Surat Thani, 84160",
  },
  {
    id: 840207,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "พลายวาส",
    subdistrictEn: "Phlai Wat",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ พลายวาส สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ พลายวาส สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Phlai Wat, Surat Thani, 84160",
  },
  {
    id: 840208,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ป่าร่อน",
    subdistrictEn: "Pa Ron",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ป่าร่อน สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ ป่าร่อน สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Pa Ron, Surat Thani, 84160",
  },
  {
    id: 840209,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ตะเคียนทอง",
    subdistrictEn: "Takhian Thong",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ตะเคียนทอง สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ ตะเคียนทอง สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Takhian Thong, Surat Thani, 84160",
  },
  {
    id: 840210,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ช้างขวา",
    subdistrictEn: "Chang Khwa",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ช้างขวา สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ ช้างขวา สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Chang Khwa, Surat Thani, 84160",
  },
  {
    id: 840211,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ท่าอุแท",
    subdistrictEn: "Tha Uthae",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ท่าอุแท สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ ท่าอุแท สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Tha Uthae, Surat Thani, 84160",
  },
  {
    id: 840212,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "ทุ่งรัง",
    subdistrictEn: "Thung Rung",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84290,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ ทุ่งรัง สุราษฎร์ธานี 84290",
    name_th: "กาญจนดิษฐ์ ทุ่งรัง สุราษฎร์ธานี 84290",
    name_en: "Kanchanadit, Thung Rung, Surat Thani, 84290",
  },
  {
    id: 840213,
    districtTh: "กาญจนดิษฐ์",
    districtEn: "Kanchanadit",
    subdistrictTh: "คลองสระ",
    subdistrictEn: "Khlong Sa",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8402,
    province_code: 67,
    name: "กาญจนดิษฐ์ คลองสระ สุราษฎร์ธานี 84160",
    name_th: "กาญจนดิษฐ์ คลองสระ สุราษฎร์ธานี 84160",
    name_en: "Kanchanadit, Khlong Sa, Surat Thani, 84160",
  },
  {
    id: 840301,
    districtTh: "ดอนสัก",
    districtEn: "Don Sak",
    subdistrictTh: "ดอนสัก",
    subdistrictEn: "Don Sak",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84220,
    district_code: 8403,
    province_code: 67,
    name: "ดอนสัก ดอนสัก สุราษฎร์ธานี 84220",
    name_th: "ดอนสัก ดอนสัก สุราษฎร์ธานี 84220",
    name_en: "Don Sak, Don Sak, Surat Thani, 84220",
  },
  {
    id: 840302,
    districtTh: "ดอนสัก",
    districtEn: "Don Sak",
    subdistrictTh: "ชลคราม",
    subdistrictEn: "Chonlakhram",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84160,
    district_code: 8403,
    province_code: 67,
    name: "ดอนสัก ชลคราม สุราษฎร์ธานี 84160",
    name_th: "ดอนสัก ชลคราม สุราษฎร์ธานี 84160",
    name_en: "Don Sak, Chonlakhram, Surat Thani, 84160",
  },
  {
    id: 840303,
    districtTh: "ดอนสัก",
    districtEn: "Don Sak",
    subdistrictTh: "ไชยคราม",
    subdistrictEn: "Chaiyakhram",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84220,
    district_code: 8403,
    province_code: 67,
    name: "ดอนสัก ไชยคราม สุราษฎร์ธานี 84220",
    name_th: "ดอนสัก ไชยคราม สุราษฎร์ธานี 84220",
    name_en: "Don Sak, Chaiyakhram, Surat Thani, 84220",
  },
  {
    id: 840304,
    districtTh: "ดอนสัก",
    districtEn: "Don Sak",
    subdistrictTh: "ปากแพรก",
    subdistrictEn: "Pak Phraek",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84340,
    district_code: 8403,
    province_code: 67,
    name: "ดอนสัก ปากแพรก สุราษฎร์ธานี 84340",
    name_th: "ดอนสัก ปากแพรก สุราษฎร์ธานี 84340",
    name_en: "Don Sak, Pak Phraek, Surat Thani, 84340",
  },
  {
    id: 840401,
    districtTh: "เกาะสมุย",
    districtEn: "Ko Samui",
    subdistrictTh: "อ่างทอง",
    subdistrictEn: "Ang Thong",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84140,
    district_code: 8404,
    province_code: 67,
    name: "เกาะสมุย อ่างทอง สุราษฎร์ธานี 84140",
    name_th: "เกาะสมุย อ่างทอง สุราษฎร์ธานี 84140",
    name_en: "Ko Samui, Ang Thong, Surat Thani, 84140",
  },
  {
    id: 840402,
    districtTh: "เกาะสมุย",
    districtEn: "Ko Samui",
    subdistrictTh: "ลิปะน้อย",
    subdistrictEn: "Lipa Noi",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84140,
    district_code: 8404,
    province_code: 67,
    name: "เกาะสมุย ลิปะน้อย สุราษฎร์ธานี 84140",
    name_th: "เกาะสมุย ลิปะน้อย สุราษฎร์ธานี 84140",
    name_en: "Ko Samui, Lipa Noi, Surat Thani, 84140",
  },
  {
    id: 840403,
    districtTh: "เกาะสมุย",
    districtEn: "Ko Samui",
    subdistrictTh: "ตลิ่งงาม",
    subdistrictEn: "Taling Ngam",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84140,
    district_code: 8404,
    province_code: 67,
    name: "เกาะสมุย ตลิ่งงาม สุราษฎร์ธานี 84140",
    name_th: "เกาะสมุย ตลิ่งงาม สุราษฎร์ธานี 84140",
    name_en: "Ko Samui, Taling Ngam, Surat Thani, 84140",
  },
  {
    id: 840404,
    districtTh: "เกาะสมุย",
    districtEn: "Ko Samui",
    subdistrictTh: "หน้าเมือง",
    subdistrictEn: "Na Mueang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84140,
    district_code: 8404,
    province_code: 67,
    name: "เกาะสมุย หน้าเมือง สุราษฎร์ธานี 84140",
    name_th: "เกาะสมุย หน้าเมือง สุราษฎร์ธานี 84140",
    name_en: "Ko Samui, Na Mueang, Surat Thani, 84140",
  },
  {
    id: 840405,
    districtTh: "เกาะสมุย",
    districtEn: "Ko Samui",
    subdistrictTh: "มะเร็ต",
    subdistrictEn: "Maret",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84310,
    district_code: 8404,
    province_code: 67,
    name: "เกาะสมุย มะเร็ต สุราษฎร์ธานี 84310",
    name_th: "เกาะสมุย มะเร็ต สุราษฎร์ธานี 84310",
    name_en: "Ko Samui, Maret, Surat Thani, 84310",
  },
  {
    id: 840406,
    districtTh: "เกาะสมุย",
    districtEn: "Ko Samui",
    subdistrictTh: "บ่อผุด",
    subdistrictEn: "Bo Phut",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84320,
    district_code: 8404,
    province_code: 67,
    name: "เกาะสมุย บ่อผุด สุราษฎร์ธานี 84320",
    name_th: "เกาะสมุย บ่อผุด สุราษฎร์ธานี 84320",
    name_en: "Ko Samui, Bo Phut, Surat Thani, 84320",
  },
  {
    id: 840407,
    districtTh: "เกาะสมุย",
    districtEn: "Ko Samui",
    subdistrictTh: "แม่น้ำ",
    subdistrictEn: "Mae Nam",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84330,
    district_code: 8404,
    province_code: 67,
    name: "เกาะสมุย แม่น้ำ สุราษฎร์ธานี 84330",
    name_th: "เกาะสมุย แม่น้ำ สุราษฎร์ธานี 84330",
    name_en: "Ko Samui, Mae Nam, Surat Thani, 84330",
  },
  {
    id: 840501,
    districtTh: "เกาะพะงัน",
    districtEn: "Ko Pha-ngan",
    subdistrictTh: "เกาะพะงัน",
    subdistrictEn: "Ko Pha-ngan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84280,
    district_code: 8405,
    province_code: 67,
    name: "เกาะพะงัน เกาะพะงัน สุราษฎร์ธานี 84280",
    name_th: "เกาะพะงัน เกาะพะงัน สุราษฎร์ธานี 84280",
    name_en: "Ko Pha-ngan, Ko Pha-ngan, Surat Thani, 84280",
  },
  {
    id: 840502,
    districtTh: "เกาะพะงัน",
    districtEn: "Ko Pha-ngan",
    subdistrictTh: "บ้านใต้",
    subdistrictEn: "Ban Tai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84280,
    district_code: 8405,
    province_code: 67,
    name: "เกาะพะงัน บ้านใต้ สุราษฎร์ธานี 84280",
    name_th: "เกาะพะงัน บ้านใต้ สุราษฎร์ธานี 84280",
    name_en: "Ko Pha-ngan, Ban Tai, Surat Thani, 84280",
  },
  {
    id: 840503,
    districtTh: "เกาะพะงัน",
    districtEn: "Ko Pha-ngan",
    subdistrictTh: "เกาะเต่า",
    subdistrictEn: "Koh Tao",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84280,
    district_code: 8405,
    province_code: 67,
    name: "เกาะพะงัน เกาะเต่า สุราษฎร์ธานี 84280",
    name_th: "เกาะพะงัน เกาะเต่า สุราษฎร์ธานี 84280",
    name_en: "Ko Pha-ngan, Koh Tao, Surat Thani, 84280",
  },
  {
    id: 840601,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "ตลาดไชยา",
    subdistrictEn: "Talat Chaiya",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา ตลาดไชยา สุราษฎร์ธานี 84110",
    name_th: "ไชยา ตลาดไชยา สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Talat Chaiya, Surat Thani, 84110",
  },
  {
    id: 840602,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "พุมเรียง",
    subdistrictEn: "Phumriang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา พุมเรียง สุราษฎร์ธานี 84110",
    name_th: "ไชยา พุมเรียง สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Phumriang, Surat Thani, 84110",
  },
  {
    id: 840603,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "เลม็ด",
    subdistrictEn: "Lamet",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา เลม็ด สุราษฎร์ธานี 84110",
    name_th: "ไชยา เลม็ด สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Lamet, Surat Thani, 84110",
  },
  {
    id: 840604,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "เวียง",
    subdistrictEn: "Wiang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา เวียง สุราษฎร์ธานี 84110",
    name_th: "ไชยา เวียง สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Wiang, Surat Thani, 84110",
  },
  {
    id: 840605,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "ทุ่ง",
    subdistrictEn: "Thung",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา ทุ่ง สุราษฎร์ธานี 84110",
    name_th: "ไชยา ทุ่ง สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Thung, Surat Thani, 84110",
  },
  {
    id: 840606,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "ป่าเว",
    subdistrictEn: "Pa We",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา ป่าเว สุราษฎร์ธานี 84110",
    name_th: "ไชยา ป่าเว สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Pa We, Surat Thani, 84110",
  },
  {
    id: 840607,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "ตะกรบ",
    subdistrictEn: "Takrop",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา ตะกรบ สุราษฎร์ธานี 84110",
    name_th: "ไชยา ตะกรบ สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Takrop, Surat Thani, 84110",
  },
  {
    id: 840608,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "โมถ่าย",
    subdistrictEn: "Mo Thai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา โมถ่าย สุราษฎร์ธานี 84110",
    name_th: "ไชยา โมถ่าย สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Mo Thai, Surat Thani, 84110",
  },
  {
    id: 840609,
    districtTh: "ไชยา",
    districtEn: "Chaiya",
    subdistrictTh: "ปากหมาก",
    subdistrictEn: "Pak Mak",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84110,
    district_code: 8406,
    province_code: 67,
    name: "ไชยา ปากหมาก สุราษฎร์ธานี 84110",
    name_th: "ไชยา ปากหมาก สุราษฎร์ธานี 84110",
    name_en: "Chaiya, Pak Mak, Surat Thani, 84110",
  },
  {
    id: 840701,
    districtTh: "ท่าชนะ",
    districtEn: "Tha Chana",
    subdistrictTh: "ท่าชนะ",
    subdistrictEn: "Tha Chana",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84170,
    district_code: 8407,
    province_code: 67,
    name: "ท่าชนะ ท่าชนะ สุราษฎร์ธานี 84170",
    name_th: "ท่าชนะ ท่าชนะ สุราษฎร์ธานี 84170",
    name_en: "Tha Chana, Tha Chana, Surat Thani, 84170",
  },
  {
    id: 840702,
    districtTh: "ท่าชนะ",
    districtEn: "Tha Chana",
    subdistrictTh: "สมอทอง",
    subdistrictEn: "Samo Thong",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84170,
    district_code: 8407,
    province_code: 67,
    name: "ท่าชนะ สมอทอง สุราษฎร์ธานี 84170",
    name_th: "ท่าชนะ สมอทอง สุราษฎร์ธานี 84170",
    name_en: "Tha Chana, Samo Thong, Surat Thani, 84170",
  },
  {
    id: 840703,
    districtTh: "ท่าชนะ",
    districtEn: "Tha Chana",
    subdistrictTh: "ประสงค์",
    subdistrictEn: "Prasong",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84170,
    district_code: 8407,
    province_code: 67,
    name: "ท่าชนะ ประสงค์ สุราษฎร์ธานี 84170",
    name_th: "ท่าชนะ ประสงค์ สุราษฎร์ธานี 84170",
    name_en: "Tha Chana, Prasong, Surat Thani, 84170",
  },
  {
    id: 840704,
    districtTh: "ท่าชนะ",
    districtEn: "Tha Chana",
    subdistrictTh: "คันธุลี",
    subdistrictEn: "Khan Thuli",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84170,
    district_code: 8407,
    province_code: 67,
    name: "ท่าชนะ คันธุลี สุราษฎร์ธานี 84170",
    name_th: "ท่าชนะ คันธุลี สุราษฎร์ธานี 84170",
    name_en: "Tha Chana, Khan Thuli, Surat Thani, 84170",
  },
  {
    id: 840705,
    districtTh: "ท่าชนะ",
    districtEn: "Tha Chana",
    subdistrictTh: "วัง",
    subdistrictEn: "Wang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84170,
    district_code: 8407,
    province_code: 67,
    name: "ท่าชนะ วัง สุราษฎร์ธานี 84170",
    name_th: "ท่าชนะ วัง สุราษฎร์ธานี 84170",
    name_en: "Tha Chana, Wang, Surat Thani, 84170",
  },
  {
    id: 840706,
    districtTh: "ท่าชนะ",
    districtEn: "Tha Chana",
    subdistrictTh: "คลองพา",
    subdistrictEn: "Khlong Pha",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84170,
    district_code: 8407,
    province_code: 67,
    name: "ท่าชนะ คลองพา สุราษฎร์ธานี 84170",
    name_th: "ท่าชนะ คลองพา สุราษฎร์ธานี 84170",
    name_en: "Tha Chana, Khlong Pha, Surat Thani, 84170",
  },
  {
    id: 840801,
    districtTh: "คีรีรัฐนิคม",
    districtEn: "Khiri Rat Nikhom",
    subdistrictTh: "ท่าขนอน",
    subdistrictEn: "Tha Khanon",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8408,
    province_code: 67,
    name: "คีรีรัฐนิคม ท่าขนอน สุราษฎร์ธานี 84180",
    name_th: "คีรีรัฐนิคม ท่าขนอน สุราษฎร์ธานี 84180",
    name_en: "Khiri Rat Nikhom, Tha Khanon, Surat Thani, 84180",
  },
  {
    id: 840802,
    districtTh: "คีรีรัฐนิคม",
    districtEn: "Khiri Rat Nikhom",
    subdistrictTh: "บ้านยาง",
    subdistrictEn: "Ban Yang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8408,
    province_code: 67,
    name: "คีรีรัฐนิคม บ้านยาง สุราษฎร์ธานี 84180",
    name_th: "คีรีรัฐนิคม บ้านยาง สุราษฎร์ธานี 84180",
    name_en: "Khiri Rat Nikhom, Ban Yang, Surat Thani, 84180",
  },
  {
    id: 840803,
    districtTh: "คีรีรัฐนิคม",
    districtEn: "Khiri Rat Nikhom",
    subdistrictTh: "น้ำหัก",
    subdistrictEn: "Nam Hak",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8408,
    province_code: 67,
    name: "คีรีรัฐนิคม น้ำหัก สุราษฎร์ธานี 84180",
    name_th: "คีรีรัฐนิคม น้ำหัก สุราษฎร์ธานี 84180",
    name_en: "Khiri Rat Nikhom, Nam Hak, Surat Thani, 84180",
  },
  {
    id: 840806,
    districtTh: "คีรีรัฐนิคม",
    districtEn: "Khiri Rat Nikhom",
    subdistrictTh: "กะเปา",
    subdistrictEn: "Kapao",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8408,
    province_code: 67,
    name: "คีรีรัฐนิคม กะเปา สุราษฎร์ธานี 84180",
    name_th: "คีรีรัฐนิคม กะเปา สุราษฎร์ธานี 84180",
    name_en: "Khiri Rat Nikhom, Kapao, Surat Thani, 84180",
  },
  {
    id: 840807,
    districtTh: "คีรีรัฐนิคม",
    districtEn: "Khiri Rat Nikhom",
    subdistrictTh: "ท่ากระดาน",
    subdistrictEn: "Tha Kradan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8408,
    province_code: 67,
    name: "คีรีรัฐนิคม ท่ากระดาน สุราษฎร์ธานี 84180",
    name_th: "คีรีรัฐนิคม ท่ากระดาน สุราษฎร์ธานี 84180",
    name_en: "Khiri Rat Nikhom, Tha Kradan, Surat Thani, 84180",
  },
  {
    id: 840808,
    districtTh: "คีรีรัฐนิคม",
    districtEn: "Khiri Rat Nikhom",
    subdistrictTh: "ย่านยาว",
    subdistrictEn: "Yan Yao",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8408,
    province_code: 67,
    name: "คีรีรัฐนิคม ย่านยาว สุราษฎร์ธานี 84180",
    name_th: "คีรีรัฐนิคม ย่านยาว สุราษฎร์ธานี 84180",
    name_en: "Khiri Rat Nikhom, Yan Yao, Surat Thani, 84180",
  },
  {
    id: 840809,
    districtTh: "คีรีรัฐนิคม",
    districtEn: "Khiri Rat Nikhom",
    subdistrictTh: "ถ้ำสิงขร",
    subdistrictEn: "Tham Singkhon",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8408,
    province_code: 67,
    name: "คีรีรัฐนิคม ถ้ำสิงขร สุราษฎร์ธานี 84180",
    name_th: "คีรีรัฐนิคม ถ้ำสิงขร สุราษฎร์ธานี 84180",
    name_en: "Khiri Rat Nikhom, Tham Singkhon, Surat Thani, 84180",
  },
  {
    id: 840810,
    districtTh: "คีรีรัฐนิคม",
    districtEn: "Khiri Rat Nikhom",
    subdistrictTh: "บ้านทำเนียบ",
    subdistrictEn: "Ban Thamniap",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8408,
    province_code: 67,
    name: "คีรีรัฐนิคม บ้านทำเนียบ สุราษฎร์ธานี 84180",
    name_th: "คีรีรัฐนิคม บ้านทำเนียบ สุราษฎร์ธานี 84180",
    name_en: "Khiri Rat Nikhom, Ban Thamniap, Surat Thani, 84180",
  },
  {
    id: 840901,
    districtTh: "บ้านตาขุน",
    districtEn: "Ban Ta Khun",
    subdistrictTh: "เขาวง",
    subdistrictEn: "Khao Wong",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84230,
    district_code: 8409,
    province_code: 67,
    name: "บ้านตาขุน เขาวง สุราษฎร์ธานี 84230",
    name_th: "บ้านตาขุน เขาวง สุราษฎร์ธานี 84230",
    name_en: "Ban Ta Khun, Khao Wong, Surat Thani, 84230",
  },
  {
    id: 840902,
    districtTh: "บ้านตาขุน",
    districtEn: "Ban Ta Khun",
    subdistrictTh: "พระแสง",
    subdistrictEn: "Phasaeng",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84230,
    district_code: 8409,
    province_code: 67,
    name: "บ้านตาขุน พระแสง สุราษฎร์ธานี 84230",
    name_th: "บ้านตาขุน พระแสง สุราษฎร์ธานี 84230",
    name_en: "Ban Ta Khun, Phasaeng, Surat Thani, 84230",
  },
  {
    id: 840903,
    districtTh: "บ้านตาขุน",
    districtEn: "Ban Ta Khun",
    subdistrictTh: "พรุไทย",
    subdistrictEn: "Phru Thai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84230,
    district_code: 8409,
    province_code: 67,
    name: "บ้านตาขุน พรุไทย สุราษฎร์ธานี 84230",
    name_th: "บ้านตาขุน พรุไทย สุราษฎร์ธานี 84230",
    name_en: "Ban Ta Khun, Phru Thai, Surat Thani, 84230",
  },
  {
    id: 840904,
    districtTh: "บ้านตาขุน",
    districtEn: "Ban Ta Khun",
    subdistrictTh: "เขาพัง",
    subdistrictEn: "Khao Phang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84230,
    district_code: 8409,
    province_code: 67,
    name: "บ้านตาขุน เขาพัง สุราษฎร์ธานี 84230",
    name_th: "บ้านตาขุน เขาพัง สุราษฎร์ธานี 84230",
    name_en: "Ban Ta Khun, Khao Phang, Surat Thani, 84230",
  },
  {
    id: 841001,
    districtTh: "พนม",
    districtEn: "Phanom",
    subdistrictTh: "พนม",
    subdistrictEn: "Phanom",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84250,
    district_code: 8410,
    province_code: 67,
    name: "พนม พนม สุราษฎร์ธานี 84250",
    name_th: "พนม พนม สุราษฎร์ธานี 84250",
    name_en: "Phanom, Phanom, Surat Thani, 84250",
  },
  {
    id: 841002,
    districtTh: "พนม",
    districtEn: "Phanom",
    subdistrictTh: "ต้นยวน",
    subdistrictEn: "Ton Yuan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84250,
    district_code: 8410,
    province_code: 67,
    name: "พนม ต้นยวน สุราษฎร์ธานี 84250",
    name_th: "พนม ต้นยวน สุราษฎร์ธานี 84250",
    name_en: "Phanom, Ton Yuan, Surat Thani, 84250",
  },
  {
    id: 841003,
    districtTh: "พนม",
    districtEn: "Phanom",
    subdistrictTh: "คลองศก",
    subdistrictEn: "Khlong Sok",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84250,
    district_code: 8410,
    province_code: 67,
    name: "พนม คลองศก สุราษฎร์ธานี 84250",
    name_th: "พนม คลองศก สุราษฎร์ธานี 84250",
    name_en: "Phanom, Khlong Sok, Surat Thani, 84250",
  },
  {
    id: 841004,
    districtTh: "พนม",
    districtEn: "Phanom",
    subdistrictTh: "พลูเถื่อน",
    subdistrictEn: "Phlu Thuean",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84250,
    district_code: 8410,
    province_code: 67,
    name: "พนม พลูเถื่อน สุราษฎร์ธานี 84250",
    name_th: "พนม พลูเถื่อน สุราษฎร์ธานี 84250",
    name_en: "Phanom, Phlu Thuean, Surat Thani, 84250",
  },
  {
    id: 841005,
    districtTh: "พนม",
    districtEn: "Phanom",
    subdistrictTh: "พังกาญจน์",
    subdistrictEn: "Phang Kan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84250,
    district_code: 8410,
    province_code: 67,
    name: "พนม พังกาญจน์ สุราษฎร์ธานี 84250",
    name_th: "พนม พังกาญจน์ สุราษฎร์ธานี 84250",
    name_en: "Phanom, Phang Kan, Surat Thani, 84250",
  },
  {
    id: 841006,
    districtTh: "พนม",
    districtEn: "Phanom",
    subdistrictTh: "คลองชะอุ่น",
    subdistrictEn: "Khlong Cha-un",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84250,
    district_code: 8410,
    province_code: 67,
    name: "พนม คลองชะอุ่น สุราษฎร์ธานี 84250",
    name_th: "พนม คลองชะอุ่น สุราษฎร์ธานี 84250",
    name_en: "Phanom, Khlong Cha-un, Surat Thani, 84250",
  },
  {
    id: 841101,
    districtTh: "ท่าฉาง",
    districtEn: "Tha Chang",
    subdistrictTh: "ท่าฉาง",
    subdistrictEn: "Tha Chang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84150,
    district_code: 8411,
    province_code: 67,
    name: "ท่าฉาง ท่าฉาง สุราษฎร์ธานี 84150",
    name_th: "ท่าฉาง ท่าฉาง สุราษฎร์ธานี 84150",
    name_en: "Tha Chang, Tha Chang, Surat Thani, 84150",
  },
  {
    id: 841102,
    districtTh: "ท่าฉาง",
    districtEn: "Tha Chang",
    subdistrictTh: "ท่าเคย",
    subdistrictEn: "Tha Khoei",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84150,
    district_code: 8411,
    province_code: 67,
    name: "ท่าฉาง ท่าเคย สุราษฎร์ธานี 84150",
    name_th: "ท่าฉาง ท่าเคย สุราษฎร์ธานี 84150",
    name_en: "Tha Chang, Tha Khoei, Surat Thani, 84150",
  },
  {
    id: 841103,
    districtTh: "ท่าฉาง",
    districtEn: "Tha Chang",
    subdistrictTh: "คลองไทร",
    subdistrictEn: "Khlong Sai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84150,
    district_code: 8411,
    province_code: 67,
    name: "ท่าฉาง คลองไทร สุราษฎร์ธานี 84150",
    name_th: "ท่าฉาง คลองไทร สุราษฎร์ธานี 84150",
    name_en: "Tha Chang, Khlong Sai, Surat Thani, 84150",
  },
  {
    id: 841104,
    districtTh: "ท่าฉาง",
    districtEn: "Tha Chang",
    subdistrictTh: "เขาถ่าน",
    subdistrictEn: "Khao Than",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84150,
    district_code: 8411,
    province_code: 67,
    name: "ท่าฉาง เขาถ่าน สุราษฎร์ธานี 84150",
    name_th: "ท่าฉาง เขาถ่าน สุราษฎร์ธานี 84150",
    name_en: "Tha Chang, Khao Than, Surat Thani, 84150",
  },
  {
    id: 841105,
    districtTh: "ท่าฉาง",
    districtEn: "Tha Chang",
    subdistrictTh: "เสวียด",
    subdistrictEn: "Sawiat",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84150,
    district_code: 8411,
    province_code: 67,
    name: "ท่าฉาง เสวียด สุราษฎร์ธานี 84150",
    name_th: "ท่าฉาง เสวียด สุราษฎร์ธานี 84150",
    name_en: "Tha Chang, Sawiat, Surat Thani, 84150",
  },
  {
    id: 841106,
    districtTh: "ท่าฉาง",
    districtEn: "Tha Chang",
    subdistrictTh: "ปากฉลุย",
    subdistrictEn: "Pak Chalui",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84150,
    district_code: 8411,
    province_code: 67,
    name: "ท่าฉาง ปากฉลุย สุราษฎร์ธานี 84150",
    name_th: "ท่าฉาง ปากฉลุย สุราษฎร์ธานี 84150",
    name_en: "Tha Chang, Pak Chalui, Surat Thani, 84150",
  },
  {
    id: 841201,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "นาสาร",
    subdistrictEn: "Na San",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร นาสาร สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร นาสาร สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Na San, Surat Thani, 84120",
  },
  {
    id: 841202,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "พรุพี",
    subdistrictEn: "Phru Phi",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84270,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร พรุพี สุราษฎร์ธานี 84270",
    name_th: "บ้านนาสาร พรุพี สุราษฎร์ธานี 84270",
    name_en: "Ban Na San, Phru Phi, Surat Thani, 84270",
  },
  {
    id: 841203,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "ทุ่งเตา",
    subdistrictEn: "Thung Tao",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร ทุ่งเตา สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร ทุ่งเตา สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Thung Tao, Surat Thani, 84120",
  },
  {
    id: 841204,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "ลำพูน",
    subdistrictEn: "Lamphun",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร ลำพูน สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร ลำพูน สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Lamphun, Surat Thani, 84120",
  },
  {
    id: 841205,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "ท่าชี",
    subdistrictEn: "Tha Chi",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร ท่าชี สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร ท่าชี สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Tha Chi, Surat Thani, 84120",
  },
  {
    id: 841206,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "ควนศรี",
    subdistrictEn: "Khuan Si",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84270,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร ควนศรี สุราษฎร์ธานี 84270",
    name_th: "บ้านนาสาร ควนศรี สุราษฎร์ธานี 84270",
    name_en: "Ban Na San, Khuan Si, Surat Thani, 84270",
  },
  {
    id: 841207,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "ควนสุบรรณ",
    subdistrictEn: "Khuan Suban",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร ควนสุบรรณ สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร ควนสุบรรณ สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Khuan Suban, Surat Thani, 84120",
  },
  {
    id: 841208,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "คลองปราบ",
    subdistrictEn: "Khlong Prap",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร คลองปราบ สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร คลองปราบ สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Khlong Prap, Surat Thani, 84120",
  },
  {
    id: 841209,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "น้ำพุ",
    subdistrictEn: "Nam Phu",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร น้ำพุ สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร น้ำพุ สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Nam Phu, Surat Thani, 84120",
  },
  {
    id: 841210,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "ทุ่งเตาใหม่",
    subdistrictEn: "Thung Tao Mai)",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร ทุ่งเตาใหม่ สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร ทุ่งเตาใหม่ สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Thung Tao Mai), Surat Thani, 84120",
  },
  {
    id: 841211,
    districtTh: "บ้านนาสาร",
    districtEn: "Ban Na San",
    subdistrictTh: "เพิ่มพูนทรัพย์",
    subdistrictEn: "Phoem Phun Sap",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84120,
    district_code: 8412,
    province_code: 67,
    name: "บ้านนาสาร เพิ่มพูนทรัพย์ สุราษฎร์ธานี 84120",
    name_th: "บ้านนาสาร เพิ่มพูนทรัพย์ สุราษฎร์ธานี 84120",
    name_en: "Ban Na San, Phoem Phun Sap, Surat Thani, 84120",
  },
  {
    id: 841301,
    districtTh: "บ้านนาเดิม",
    districtEn: "Ban Na Doem",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84240,
    district_code: 8413,
    province_code: 67,
    name: "บ้านนาเดิม บ้านนา สุราษฎร์ธานี 84240",
    name_th: "บ้านนาเดิม บ้านนา สุราษฎร์ธานี 84240",
    name_en: "Ban Na Doem, Ban Na, Surat Thani, 84240",
  },
  {
    id: 841302,
    districtTh: "บ้านนาเดิม",
    districtEn: "Ban Na Doem",
    subdistrictTh: "ท่าเรือ",
    subdistrictEn: "Tha Ruea",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84240,
    district_code: 8413,
    province_code: 67,
    name: "บ้านนาเดิม ท่าเรือ สุราษฎร์ธานี 84240",
    name_th: "บ้านนาเดิม ท่าเรือ สุราษฎร์ธานี 84240",
    name_en: "Ban Na Doem, Tha Ruea, Surat Thani, 84240",
  },
  {
    id: 841303,
    districtTh: "บ้านนาเดิม",
    districtEn: "Ban Na Doem",
    subdistrictTh: "ทรัพย์ทวี",
    subdistrictEn: "Sap Thawi",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84240,
    district_code: 8413,
    province_code: 67,
    name: "บ้านนาเดิม ทรัพย์ทวี สุราษฎร์ธานี 84240",
    name_th: "บ้านนาเดิม ทรัพย์ทวี สุราษฎร์ธานี 84240",
    name_en: "Ban Na Doem, Sap Thawi, Surat Thani, 84240",
  },
  {
    id: 841304,
    districtTh: "บ้านนาเดิม",
    districtEn: "Ban Na Doem",
    subdistrictTh: "นาใต้",
    subdistrictEn: "Na Tai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84240,
    district_code: 8413,
    province_code: 67,
    name: "บ้านนาเดิม นาใต้ สุราษฎร์ธานี 84240",
    name_th: "บ้านนาเดิม นาใต้ สุราษฎร์ธานี 84240",
    name_en: "Ban Na Doem, Na Tai, Surat Thani, 84240",
  },
  {
    id: 841401,
    districtTh: "เคียนซา",
    districtEn: "Khian Sa",
    subdistrictTh: "เคียนซา",
    subdistrictEn: "Khian Sa",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84260,
    district_code: 8414,
    province_code: 67,
    name: "เคียนซา เคียนซา สุราษฎร์ธานี 84260",
    name_th: "เคียนซา เคียนซา สุราษฎร์ธานี 84260",
    name_en: "Khian Sa, Khian Sa, Surat Thani, 84260",
  },
  {
    id: 841402,
    districtTh: "เคียนซา",
    districtEn: "Khian Sa",
    subdistrictTh: "พ่วงพรมคร",
    subdistrictEn: "Phuang Phromkhon",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84210,
    district_code: 8414,
    province_code: 67,
    name: "เคียนซา พ่วงพรมคร สุราษฎร์ธานี 84210",
    name_th: "เคียนซา พ่วงพรมคร สุราษฎร์ธานี 84210",
    name_en: "Khian Sa, Phuang Phromkhon, Surat Thani, 84210",
  },
  {
    id: 841403,
    districtTh: "เคียนซา",
    districtEn: "Khian Sa",
    subdistrictTh: "เขาตอก",
    subdistrictEn: "Khao Tok",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84260,
    district_code: 8414,
    province_code: 67,
    name: "เคียนซา เขาตอก สุราษฎร์ธานี 84260",
    name_th: "เคียนซา เขาตอก สุราษฎร์ธานี 84260",
    name_en: "Khian Sa, Khao Tok, Surat Thani, 84260",
  },
  {
    id: 841404,
    districtTh: "เคียนซา",
    districtEn: "Khian Sa",
    subdistrictTh: "อรัญคามวารี",
    subdistrictEn: "Aranyakham Wari",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84260,
    district_code: 8414,
    province_code: 67,
    name: "เคียนซา อรัญคามวารี สุราษฎร์ธานี 84260",
    name_th: "เคียนซา อรัญคามวารี สุราษฎร์ธานี 84260",
    name_en: "Khian Sa, Aranyakham Wari, Surat Thani, 84260",
  },
  {
    id: 841405,
    districtTh: "เคียนซา",
    districtEn: "Khian Sa",
    subdistrictTh: "บ้านเสด็จ",
    subdistrictEn: "Ban Sadet",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84260,
    district_code: 8414,
    province_code: 67,
    name: "เคียนซา บ้านเสด็จ สุราษฎร์ธานี 84260",
    name_th: "เคียนซา บ้านเสด็จ สุราษฎร์ธานี 84260",
    name_en: "Khian Sa, Ban Sadet, Surat Thani, 84260",
  },
  {
    id: 841501,
    districtTh: "เวียงสระ",
    districtEn: "Wiang Sa",
    subdistrictTh: "เวียงสระ",
    subdistrictEn: "Wiang Sa",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84190,
    district_code: 8415,
    province_code: 67,
    name: "เวียงสระ เวียงสระ สุราษฎร์ธานี 84190",
    name_th: "เวียงสระ เวียงสระ สุราษฎร์ธานี 84190",
    name_en: "Wiang Sa, Wiang Sa, Surat Thani, 84190",
  },
  {
    id: 841502,
    districtTh: "เวียงสระ",
    districtEn: "Wiang Sa",
    subdistrictTh: "บ้านส้อง",
    subdistrictEn: "Ban Song",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84190,
    district_code: 8415,
    province_code: 67,
    name: "เวียงสระ บ้านส้อง สุราษฎร์ธานี 84190",
    name_th: "เวียงสระ บ้านส้อง สุราษฎร์ธานี 84190",
    name_en: "Wiang Sa, Ban Song, Surat Thani, 84190",
  },
  {
    id: 841503,
    districtTh: "เวียงสระ",
    districtEn: "Wiang Sa",
    subdistrictTh: "คลองฉนวน",
    subdistrictEn: "Khlong Chanuan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84190,
    district_code: 8415,
    province_code: 67,
    name: "เวียงสระ คลองฉนวน สุราษฎร์ธานี 84190",
    name_th: "เวียงสระ คลองฉนวน สุราษฎร์ธานี 84190",
    name_en: "Wiang Sa, Khlong Chanuan, Surat Thani, 84190",
  },
  {
    id: 841504,
    districtTh: "เวียงสระ",
    districtEn: "Wiang Sa",
    subdistrictTh: "ทุ่งหลวง",
    subdistrictEn: "Thung Luang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84190,
    district_code: 8415,
    province_code: 67,
    name: "เวียงสระ ทุ่งหลวง สุราษฎร์ธานี 84190",
    name_th: "เวียงสระ ทุ่งหลวง สุราษฎร์ธานี 84190",
    name_en: "Wiang Sa, Thung Luang, Surat Thani, 84190",
  },
  {
    id: 841505,
    districtTh: "เวียงสระ",
    districtEn: "Wiang Sa",
    subdistrictTh: "เขานิพันธ์",
    subdistrictEn: "*Khao Niphan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84190,
    district_code: 8415,
    province_code: 67,
    name: "เวียงสระ เขานิพันธ์ สุราษฎร์ธานี 84190",
    name_th: "เวียงสระ เขานิพันธ์ สุราษฎร์ธานี 84190",
    name_en: "Wiang Sa, *Khao Niphan, Surat Thani, 84190",
  },
  {
    id: 841601,
    districtTh: "พระแสง",
    districtEn: "Phrasaeng",
    subdistrictTh: "อิปัน",
    subdistrictEn: "Ipan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84210,
    district_code: 8416,
    province_code: 67,
    name: "พระแสง อิปัน สุราษฎร์ธานี 84210",
    name_th: "พระแสง อิปัน สุราษฎร์ธานี 84210",
    name_en: "Phrasaeng, Ipan, Surat Thani, 84210",
  },
  {
    id: 841602,
    districtTh: "พระแสง",
    districtEn: "Phrasaeng",
    subdistrictTh: "สินปุน",
    subdistrictEn: "Sin Pun",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84210,
    district_code: 8416,
    province_code: 67,
    name: "พระแสง สินปุน สุราษฎร์ธานี 84210",
    name_th: "พระแสง สินปุน สุราษฎร์ธานี 84210",
    name_en: "Phrasaeng, Sin Pun, Surat Thani, 84210",
  },
  {
    id: 841603,
    districtTh: "พระแสง",
    districtEn: "Phrasaeng",
    subdistrictTh: "บางสวรรค์",
    subdistrictEn: "Bang Sawan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84210,
    district_code: 8416,
    province_code: 67,
    name: "พระแสง บางสวรรค์ สุราษฎร์ธานี 84210",
    name_th: "พระแสง บางสวรรค์ สุราษฎร์ธานี 84210",
    name_en: "Phrasaeng, Bang Sawan, Surat Thani, 84210",
  },
  {
    id: 841604,
    districtTh: "พระแสง",
    districtEn: "Phrasaeng",
    subdistrictTh: "ไทรขึง",
    subdistrictEn: "Sai Khueng",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84210,
    district_code: 8416,
    province_code: 67,
    name: "พระแสง ไทรขึง สุราษฎร์ธานี 84210",
    name_th: "พระแสง ไทรขึง สุราษฎร์ธานี 84210",
    name_en: "Phrasaeng, Sai Khueng, Surat Thani, 84210",
  },
  {
    id: 841605,
    districtTh: "พระแสง",
    districtEn: "Phrasaeng",
    subdistrictTh: "สินเจริญ",
    subdistrictEn: "Sin Charoen",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84210,
    district_code: 8416,
    province_code: 67,
    name: "พระแสง สินเจริญ สุราษฎร์ธานี 84210",
    name_th: "พระแสง สินเจริญ สุราษฎร์ธานี 84210",
    name_en: "Phrasaeng, Sin Charoen, Surat Thani, 84210",
  },
  {
    id: 841606,
    districtTh: "พระแสง",
    districtEn: "Phrasaeng",
    subdistrictTh: "ไทรโสภา",
    subdistrictEn: "Sai Sopha",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84210,
    district_code: 8416,
    province_code: 67,
    name: "พระแสง ไทรโสภา สุราษฎร์ธานี 84210",
    name_th: "พระแสง ไทรโสภา สุราษฎร์ธานี 84210",
    name_en: "Phrasaeng, Sai Sopha, Surat Thani, 84210",
  },
  {
    id: 841607,
    districtTh: "พระแสง",
    districtEn: "Phrasaeng",
    subdistrictTh: "สาคู",
    subdistrictEn: "Sakhu",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84210,
    district_code: 8416,
    province_code: 67,
    name: "พระแสง สาคู สุราษฎร์ธานี 84210",
    name_th: "พระแสง สาคู สุราษฎร์ธานี 84210",
    name_en: "Phrasaeng, Sakhu, Surat Thani, 84210",
  },
  {
    id: 841701,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน ท่าข้าม สุราษฎร์ธานี 84130",
    name_th: "พุนพิน ท่าข้าม สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Tha Kham, Surat Thani, 84130",
  },
  {
    id: 841702,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "ท่าสะท้อน",
    subdistrictEn: "Tha Sathon",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน ท่าสะท้อน สุราษฎร์ธานี 84130",
    name_th: "พุนพิน ท่าสะท้อน สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Tha Sathon, Surat Thani, 84130",
  },
  {
    id: 841703,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "ลีเล็ด",
    subdistrictEn: "Lilet",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน ลีเล็ด สุราษฎร์ธานี 84130",
    name_th: "พุนพิน ลีเล็ด สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Lilet, Surat Thani, 84130",
  },
  {
    id: 841704,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "บางมะเดื่อ",
    subdistrictEn: "Bang Maduea",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน บางมะเดื่อ สุราษฎร์ธานี 84130",
    name_th: "พุนพิน บางมะเดื่อ สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Bang Maduea, Surat Thani, 84130",
  },
  {
    id: 841705,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "บางเดือน",
    subdistrictEn: "Bang Duean)",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน บางเดือน สุราษฎร์ธานี 84130",
    name_th: "พุนพิน บางเดือน สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Bang Duean), Surat Thani, 84130",
  },
  {
    id: 841706,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "ท่าโรงช้าง",
    subdistrictEn: "Tha Rong Chang",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน ท่าโรงช้าง สุราษฎร์ธานี 84130",
    name_th: "พุนพิน ท่าโรงช้าง สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Tha Rong Chang, Surat Thani, 84130",
  },
  {
    id: 841707,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "กรูด",
    subdistrictEn: "Krut",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน กรูด สุราษฎร์ธานี 84130",
    name_th: "พุนพิน กรูด สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Krut, Surat Thani, 84130",
  },
  {
    id: 841708,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "พุนพิน",
    subdistrictEn: "Phunphin",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน พุนพิน สุราษฎร์ธานี 84130",
    name_th: "พุนพิน พุนพิน สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Phunphin, Surat Thani, 84130",
  },
  {
    id: 841709,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "บางงอน",
    subdistrictEn: "Bang Ngon",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน บางงอน สุราษฎร์ธานี 84130",
    name_th: "พุนพิน บางงอน สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Bang Ngon, Surat Thani, 84130",
  },
  {
    id: 841710,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "ศรีวิชัย",
    subdistrictEn: "Si Wichai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน ศรีวิชัย สุราษฎร์ธานี 84130",
    name_th: "พุนพิน ศรีวิชัย สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Si Wichai, Surat Thani, 84130",
  },
  {
    id: 841711,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "น้ำรอบ",
    subdistrictEn: "Nam Rop",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน น้ำรอบ สุราษฎร์ธานี 84130",
    name_th: "พุนพิน น้ำรอบ สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Nam Rop, Surat Thani, 84130",
  },
  {
    id: 841712,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "มะลวน",
    subdistrictEn: "Maluan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน มะลวน สุราษฎร์ธานี 84130",
    name_th: "พุนพิน มะลวน สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Maluan, Surat Thani, 84130",
  },
  {
    id: 841713,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "หัวเตย",
    subdistrictEn: "Hua Toei",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน หัวเตย สุราษฎร์ธานี 84130",
    name_th: "พุนพิน หัวเตย สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Hua Toei, Surat Thani, 84130",
  },
  {
    id: 841714,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "หนองไทร",
    subdistrictEn: "Nong Sai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน หนองไทร สุราษฎร์ธานี 84130",
    name_th: "พุนพิน หนองไทร สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Nong Sai, Surat Thani, 84130",
  },
  {
    id: 841715,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "เขาหัวควาย",
    subdistrictEn: "Khao Hua Khwai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน เขาหัวควาย สุราษฎร์ธานี 84130",
    name_th: "พุนพิน เขาหัวควาย สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Khao Hua Khwai, Surat Thani, 84130",
  },
  {
    id: 841716,
    districtTh: "พุนพิน",
    districtEn: "Phunphin",
    subdistrictTh: "ตะปาน",
    subdistrictEn: "Tapan",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84130,
    district_code: 8417,
    province_code: 67,
    name: "พุนพิน ตะปาน สุราษฎร์ธานี 84130",
    name_th: "พุนพิน ตะปาน สุราษฎร์ธานี 84130",
    name_en: "Phunphin, Tapan, Surat Thani, 84130",
  },
  {
    id: 841801,
    districtTh: "ชัยบุรี",
    districtEn: "Chai Buri",
    subdistrictTh: "สองแพรก",
    subdistrictEn: "Song Phraek",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84350,
    district_code: 8418,
    province_code: 67,
    name: "ชัยบุรี สองแพรก สุราษฎร์ธานี 84350",
    name_th: "ชัยบุรี สองแพรก สุราษฎร์ธานี 84350",
    name_en: "Chai Buri, Song Phraek, Surat Thani, 84350",
  },
  {
    id: 841802,
    districtTh: "ชัยบุรี",
    districtEn: "Chai Buri",
    subdistrictTh: "ชัยบุรี",
    subdistrictEn: "Chai Buri",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84350,
    district_code: 8418,
    province_code: 67,
    name: "ชัยบุรี ชัยบุรี สุราษฎร์ธานี 84350",
    name_th: "ชัยบุรี ชัยบุรี สุราษฎร์ธานี 84350",
    name_en: "Chai Buri, Chai Buri, Surat Thani, 84350",
  },
  {
    id: 841803,
    districtTh: "ชัยบุรี",
    districtEn: "Chai Buri",
    subdistrictTh: "คลองน้อย",
    subdistrictEn: "Khlong Noi",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84350,
    district_code: 8418,
    province_code: 67,
    name: "ชัยบุรี คลองน้อย สุราษฎร์ธานี 84350",
    name_th: "ชัยบุรี คลองน้อย สุราษฎร์ธานี 84350",
    name_en: "Chai Buri, Khlong Noi, Surat Thani, 84350",
  },
  {
    id: 841804,
    districtTh: "ชัยบุรี",
    districtEn: "Chai Buri",
    subdistrictTh: "ไทรทอง",
    subdistrictEn: "Sai Thong",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84350,
    district_code: 8418,
    province_code: 67,
    name: "ชัยบุรี ไทรทอง สุราษฎร์ธานี 84350",
    name_th: "ชัยบุรี ไทรทอง สุราษฎร์ธานี 84350",
    name_en: "Chai Buri, Sai Thong, Surat Thani, 84350",
  },
  {
    id: 841901,
    districtTh: "วิภาวดี",
    districtEn: "Vibhavadi",
    subdistrictTh: "ตะกุกใต้",
    subdistrictEn: "Takuk Tai",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8419,
    province_code: 67,
    name: "วิภาวดี ตะกุกใต้ สุราษฎร์ธานี 84180",
    name_th: "วิภาวดี ตะกุกใต้ สุราษฎร์ธานี 84180",
    name_en: "Vibhavadi, Takuk Tai, Surat Thani, 84180",
  },
  {
    id: 841902,
    districtTh: "วิภาวดี",
    districtEn: "Vibhavadi",
    subdistrictTh: "ตะกุกเหนือ",
    subdistrictEn: "Takuk Nuea",
    provinceTh: "สุราษฎร์ธานี",
    provinceEn: "Surat Thani",
    zipcode: 84180,
    district_code: 8419,
    province_code: 67,
    name: "วิภาวดี ตะกุกเหนือ สุราษฎร์ธานี 84180",
    name_th: "วิภาวดี ตะกุกเหนือ สุราษฎร์ธานี 84180",
    name_en: "Vibhavadi, Takuk Nuea, Surat Thani, 84180",
  },
  {
    id: 850101,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "เขานิเวศน์",
    subdistrictEn: "Khao Niwet",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85000,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง เขานิเวศน์ ระนอง 85000",
    name_th: "เมืองระนอง เขานิเวศน์ ระนอง 85000",
    name_en: "Mueang Ranong, Khao Niwet, Ranong, 85000",
  },
  {
    id: 850102,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "ราชกรูด",
    subdistrictEn: "Ratchakrut",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85000,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง ราชกรูด ระนอง 85000",
    name_th: "เมืองระนอง ราชกรูด ระนอง 85000",
    name_en: "Mueang Ranong, Ratchakrut, Ranong, 85000",
  },
  {
    id: 850103,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "หงาว",
    subdistrictEn: "Ngao",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85000,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง หงาว ระนอง 85000",
    name_th: "เมืองระนอง หงาว ระนอง 85000",
    name_en: "Mueang Ranong, Ngao, Ranong, 85000",
  },
  {
    id: 850104,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "บางริ้น",
    subdistrictEn: "Bang Rin",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85000,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง บางริ้น ระนอง 85000",
    name_th: "เมืองระนอง บางริ้น ระนอง 85000",
    name_en: "Mueang Ranong, Bang Rin, Ranong, 85000",
  },
  {
    id: 850105,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85000,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง ปากน้ำ ระนอง 85000",
    name_th: "เมืองระนอง ปากน้ำ ระนอง 85000",
    name_en: "Mueang Ranong, Pak Nam, Ranong, 85000",
  },
  {
    id: 850106,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "บางนอน",
    subdistrictEn: "Bang Non",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85000,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง บางนอน ระนอง 85000",
    name_th: "เมืองระนอง บางนอน ระนอง 85000",
    name_en: "Mueang Ranong, Bang Non, Ranong, 85000",
  },
  {
    id: 850107,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "หาดส้มแป้น",
    subdistrictEn: "Hat Som Paen",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85000,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง หาดส้มแป้น ระนอง 85000",
    name_th: "เมืองระนอง หาดส้มแป้น ระนอง 85000",
    name_en: "Mueang Ranong, Hat Som Paen, Ranong, 85000",
  },
  {
    id: 850108,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "ทรายแดง",
    subdistrictEn: "Sai Daeng",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85130,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง ทรายแดง ระนอง 85130",
    name_th: "เมืองระนอง ทรายแดง ระนอง 85130",
    name_en: "Mueang Ranong, Sai Daeng, Ranong, 85130",
  },
  {
    id: 850109,
    districtTh: "เมืองระนอง",
    districtEn: "Mueang Ranong",
    subdistrictTh: "เกาะพยาม",
    subdistrictEn: "Ko Phayam",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85000,
    district_code: 8501,
    province_code: 68,
    name: "เมืองระนอง เกาะพยาม ระนอง 85000",
    name_th: "เมืองระนอง เกาะพยาม ระนอง 85000",
    name_en: "Mueang Ranong, Ko Phayam, Ranong, 85000",
  },
  {
    id: 850201,
    districtTh: "ละอุ่น",
    districtEn: "La-un",
    subdistrictTh: "ละอุ่นใต้",
    subdistrictEn: "La-un Tai",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85130,
    district_code: 8502,
    province_code: 68,
    name: "ละอุ่น ละอุ่นใต้ ระนอง 85130",
    name_th: "ละอุ่น ละอุ่นใต้ ระนอง 85130",
    name_en: "La-un, La-un Tai, Ranong, 85130",
  },
  {
    id: 850202,
    districtTh: "ละอุ่น",
    districtEn: "La-un",
    subdistrictTh: "ละอุ่นเหนือ",
    subdistrictEn: "La-un Nuea",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85130,
    district_code: 8502,
    province_code: 68,
    name: "ละอุ่น ละอุ่นเหนือ ระนอง 85130",
    name_th: "ละอุ่น ละอุ่นเหนือ ระนอง 85130",
    name_en: "La-un, La-un Nuea, Ranong, 85130",
  },
  {
    id: 850203,
    districtTh: "ละอุ่น",
    districtEn: "La-un",
    subdistrictTh: "บางพระใต้",
    subdistrictEn: "Bang Phra Tai",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85130,
    district_code: 8502,
    province_code: 68,
    name: "ละอุ่น บางพระใต้ ระนอง 85130",
    name_th: "ละอุ่น บางพระใต้ ระนอง 85130",
    name_en: "La-un, Bang Phra Tai, Ranong, 85130",
  },
  {
    id: 850204,
    districtTh: "ละอุ่น",
    districtEn: "La-un",
    subdistrictTh: "บางพระเหนือ",
    subdistrictEn: "Bang Phra Nuea",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85130,
    district_code: 8502,
    province_code: 68,
    name: "ละอุ่น บางพระเหนือ ระนอง 85130",
    name_th: "ละอุ่น บางพระเหนือ ระนอง 85130",
    name_en: "La-un, Bang Phra Nuea, Ranong, 85130",
  },
  {
    id: 850205,
    districtTh: "ละอุ่น",
    districtEn: "La-un",
    subdistrictTh: "บางแก้ว",
    subdistrictEn: "Bang Kaeo",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85130,
    district_code: 8502,
    province_code: 68,
    name: "ละอุ่น บางแก้ว ระนอง 85130",
    name_th: "ละอุ่น บางแก้ว ระนอง 85130",
    name_en: "La-un, Bang Kaeo, Ranong, 85130",
  },
  {
    id: 850206,
    districtTh: "ละอุ่น",
    districtEn: "La-un",
    subdistrictTh: "ในวงเหนือ",
    subdistrictEn: "Nai Wong Nuea",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85130,
    district_code: 8502,
    province_code: 68,
    name: "ละอุ่น ในวงเหนือ ระนอง 85130",
    name_th: "ละอุ่น ในวงเหนือ ระนอง 85130",
    name_en: "La-un, Nai Wong Nuea, Ranong, 85130",
  },
  {
    id: 850207,
    districtTh: "ละอุ่น",
    districtEn: "La-un",
    subdistrictTh: "ในวงใต้",
    subdistrictEn: "Nai Wong Tai",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85130,
    district_code: 8502,
    province_code: 68,
    name: "ละอุ่น ในวงใต้ ระนอง 85130",
    name_th: "ละอุ่น ในวงใต้ ระนอง 85130",
    name_en: "La-un, Nai Wong Tai, Ranong, 85130",
  },
  {
    id: 850301,
    districtTh: "กะเปอร์",
    districtEn: "Kapoe",
    subdistrictTh: "ม่วงกลวง",
    subdistrictEn: "Muang Kluang",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85120,
    district_code: 8503,
    province_code: 68,
    name: "กะเปอร์ ม่วงกลวง ระนอง 85120",
    name_th: "กะเปอร์ ม่วงกลวง ระนอง 85120",
    name_en: "Kapoe, Muang Kluang, Ranong, 85120",
  },
  {
    id: 850302,
    districtTh: "กะเปอร์",
    districtEn: "Kapoe",
    subdistrictTh: "กะเปอร์",
    subdistrictEn: "Kapoe",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85120,
    district_code: 8503,
    province_code: 68,
    name: "กะเปอร์ กะเปอร์ ระนอง 85120",
    name_th: "กะเปอร์ กะเปอร์ ระนอง 85120",
    name_en: "Kapoe, Kapoe, Ranong, 85120",
  },
  {
    id: 850303,
    districtTh: "กะเปอร์",
    districtEn: "Kapoe",
    subdistrictTh: "เชี่ยวเหลียง",
    subdistrictEn: "Chiao Liang",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85120,
    district_code: 8503,
    province_code: 68,
    name: "กะเปอร์ เชี่ยวเหลียง ระนอง 85120",
    name_th: "กะเปอร์ เชี่ยวเหลียง ระนอง 85120",
    name_en: "Kapoe, Chiao Liang, Ranong, 85120",
  },
  {
    id: 850304,
    districtTh: "กะเปอร์",
    districtEn: "Kapoe",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85120,
    district_code: 8503,
    province_code: 68,
    name: "กะเปอร์ บ้านนา ระนอง 85120",
    name_th: "กะเปอร์ บ้านนา ระนอง 85120",
    name_en: "Kapoe, Ban Na, Ranong, 85120",
  },
  {
    id: 850305,
    districtTh: "กะเปอร์",
    districtEn: "Kapoe",
    subdistrictTh: "บางหิน",
    subdistrictEn: "Bang Hin",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85120,
    district_code: 8503,
    province_code: 68,
    name: "กะเปอร์ บางหิน ระนอง 85120",
    name_th: "กะเปอร์ บางหิน ระนอง 85120",
    name_en: "Kapoe, Bang Hin, Ranong, 85120",
  },
  {
    id: 850401,
    districtTh: "กระบุรี",
    districtEn: "Kra Buri",
    subdistrictTh: "น้ำจืด",
    subdistrictEn: "Nam Chuet",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85110,
    district_code: 8504,
    province_code: 68,
    name: "กระบุรี น้ำจืด ระนอง 85110",
    name_th: "กระบุรี น้ำจืด ระนอง 85110",
    name_en: "Kra Buri, Nam Chuet, Ranong, 85110",
  },
  {
    id: 850402,
    districtTh: "กระบุรี",
    districtEn: "Kra Buri",
    subdistrictTh: "น้ำจืดน้อย",
    subdistrictEn: "Nam Chuet Noi",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85110,
    district_code: 8504,
    province_code: 68,
    name: "กระบุรี น้ำจืดน้อย ระนอง 85110",
    name_th: "กระบุรี น้ำจืดน้อย ระนอง 85110",
    name_en: "Kra Buri, Nam Chuet Noi, Ranong, 85110",
  },
  {
    id: 850403,
    districtTh: "กระบุรี",
    districtEn: "Kra Buri",
    subdistrictTh: "มะมุ",
    subdistrictEn: "Mamu",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85110,
    district_code: 8504,
    province_code: 68,
    name: "กระบุรี มะมุ ระนอง 85110",
    name_th: "กระบุรี มะมุ ระนอง 85110",
    name_en: "Kra Buri, Mamu, Ranong, 85110",
  },
  {
    id: 850404,
    districtTh: "กระบุรี",
    districtEn: "Kra Buri",
    subdistrictTh: "ปากจั่น",
    subdistrictEn: "Pak Chan",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85110,
    district_code: 8504,
    province_code: 68,
    name: "กระบุรี ปากจั่น ระนอง 85110",
    name_th: "กระบุรี ปากจั่น ระนอง 85110",
    name_en: "Kra Buri, Pak Chan, Ranong, 85110",
  },
  {
    id: 850405,
    districtTh: "กระบุรี",
    districtEn: "Kra Buri",
    subdistrictTh: "ลำเลียง",
    subdistrictEn: "Lamliang",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85110,
    district_code: 8504,
    province_code: 68,
    name: "กระบุรี ลำเลียง ระนอง 85110",
    name_th: "กระบุรี ลำเลียง ระนอง 85110",
    name_en: "Kra Buri, Lamliang, Ranong, 85110",
  },
  {
    id: 850406,
    districtTh: "กระบุรี",
    districtEn: "Kra Buri",
    subdistrictTh: "จ.ป.ร.",
    subdistrictEn: "Choporo",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85110,
    district_code: 8504,
    province_code: 68,
    name: "กระบุรี จ.ป.ร. ระนอง 85110",
    name_th: "กระบุรี จ.ป.ร. ระนอง 85110",
    name_en: "Kra Buri, Choporo, Ranong, 85110",
  },
  {
    id: 850407,
    districtTh: "กระบุรี",
    districtEn: "Kra Buri",
    subdistrictTh: "บางใหญ่",
    subdistrictEn: "Bang Yai",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85110,
    district_code: 8504,
    province_code: 68,
    name: "กระบุรี บางใหญ่ ระนอง 85110",
    name_th: "กระบุรี บางใหญ่ ระนอง 85110",
    name_en: "Kra Buri, Bang Yai, Ranong, 85110",
  },
  {
    id: 850501,
    districtTh: "สุขสำราญ",
    districtEn: "Suk Samran",
    subdistrictTh: "นาคา",
    subdistrictEn: "Nakha",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85120,
    district_code: 8505,
    province_code: 68,
    name: "สุขสำราญ นาคา ระนอง 85120",
    name_th: "สุขสำราญ นาคา ระนอง 85120",
    name_en: "Suk Samran, Nakha, Ranong, 85120",
  },
  {
    id: 850502,
    districtTh: "สุขสำราญ",
    districtEn: "Suk Samran",
    subdistrictTh: "กำพวน",
    subdistrictEn: "Kamphuan",
    provinceTh: "ระนอง",
    provinceEn: "Ranong",
    zipcode: 85120,
    district_code: 8505,
    province_code: 68,
    name: "สุขสำราญ กำพวน ระนอง 85120",
    name_th: "สุขสำราญ กำพวน ระนอง 85120",
    name_en: "Suk Samran, Kamphuan, Ranong, 85120",
  },
  {
    id: 860101,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "ท่าตะเภา",
    subdistrictEn: "Tha Taphao",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร ท่าตะเภา ชุมพร 86000",
    name_th: "เมืองชุมพร ท่าตะเภา ชุมพร 86000",
    name_en: "Mueang Chumphon, Tha Taphao, Chumphon, 86000",
  },
  {
    id: 860102,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86120,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร ปากน้ำ ชุมพร 86120",
    name_th: "เมืองชุมพร ปากน้ำ ชุมพร 86120",
    name_en: "Mueang Chumphon, Pak Nam, Chumphon, 86120",
  },
  {
    id: 860103,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "ท่ายาง",
    subdistrictEn: "Tha Yang",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร ท่ายาง ชุมพร 86000",
    name_th: "เมืองชุมพร ท่ายาง ชุมพร 86000",
    name_en: "Mueang Chumphon, Tha Yang, Chumphon, 86000",
  },
  {
    id: 860104,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "บางหมาก",
    subdistrictEn: "Bang Mak",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร บางหมาก ชุมพร 86000",
    name_th: "เมืองชุมพร บางหมาก ชุมพร 86000",
    name_en: "Mueang Chumphon, Bang Mak, Chumphon, 86000",
  },
  {
    id: 860105,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "นาทุ่ง",
    subdistrictEn: "Na Thung",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร นาทุ่ง ชุมพร 86000",
    name_th: "เมืองชุมพร นาทุ่ง ชุมพร 86000",
    name_en: "Mueang Chumphon, Na Thung, Chumphon, 86000",
  },
  {
    id: 860106,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "นาชะอัง",
    subdistrictEn: "Na Cha-ang",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร นาชะอัง ชุมพร 86000",
    name_th: "เมืองชุมพร นาชะอัง ชุมพร 86000",
    name_en: "Mueang Chumphon, Na Cha-ang, Chumphon, 86000",
  },
  {
    id: 860107,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "ตากแดด",
    subdistrictEn: "Tak Daet",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร ตากแดด ชุมพร 86000",
    name_th: "เมืองชุมพร ตากแดด ชุมพร 86000",
    name_en: "Mueang Chumphon, Tak Daet, Chumphon, 86000",
  },
  {
    id: 860108,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "บางลึก",
    subdistrictEn: "Bang Luek",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร บางลึก ชุมพร 86000",
    name_th: "เมืองชุมพร บางลึก ชุมพร 86000",
    name_en: "Mueang Chumphon, Bang Luek, Chumphon, 86000",
  },
  {
    id: 860109,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "หาดพันไกร",
    subdistrictEn: "Hat Phan Krai",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร หาดพันไกร ชุมพร 86000",
    name_th: "เมืองชุมพร หาดพันไกร ชุมพร 86000",
    name_en: "Mueang Chumphon, Hat Phan Krai, Chumphon, 86000",
  },
  {
    id: 860110,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "วังไผ่",
    subdistrictEn: "Wang Phai",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร วังไผ่ ชุมพร 86000",
    name_th: "เมืองชุมพร วังไผ่ ชุมพร 86000",
    name_en: "Mueang Chumphon, Wang Phai, Chumphon, 86000",
  },
  {
    id: 860111,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "วังใหม่",
    subdistrictEn: "Wang Mai",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86190,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร วังใหม่ ชุมพร 86190",
    name_th: "เมืองชุมพร วังใหม่ ชุมพร 86190",
    name_en: "Mueang Chumphon, Wang Mai, Chumphon, 86190",
  },
  {
    id: 860112,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86190,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร บ้านนา ชุมพร 86190",
    name_th: "เมืองชุมพร บ้านนา ชุมพร 86190",
    name_en: "Mueang Chumphon, Ban Na, Chumphon, 86190",
  },
  {
    id: 860113,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "ขุนกระทิง",
    subdistrictEn: "Khun Krathing",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86000,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร ขุนกระทิง ชุมพร 86000",
    name_th: "เมืองชุมพร ขุนกระทิง ชุมพร 86000",
    name_en: "Mueang Chumphon, Khun Krathing, Chumphon, 86000",
  },
  {
    id: 860114,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "ทุ่งคา",
    subdistrictEn: "Thung Kha",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86100,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร ทุ่งคา ชุมพร 86100",
    name_th: "เมืองชุมพร ทุ่งคา ชุมพร 86100",
    name_en: "Mueang Chumphon, Thung Kha, Chumphon, 86100",
  },
  {
    id: 860115,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "วิสัยเหนือ",
    subdistrictEn: "Wisai Nuea",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86100,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร วิสัยเหนือ ชุมพร 86100",
    name_th: "เมืองชุมพร วิสัยเหนือ ชุมพร 86100",
    name_en: "Mueang Chumphon, Wisai Nuea, Chumphon, 86100",
  },
  {
    id: 860116,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "หาดทรายรี",
    subdistrictEn: "Hat Sai Ri",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86120,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร หาดทรายรี ชุมพร 86120",
    name_th: "เมืองชุมพร หาดทรายรี ชุมพร 86120",
    name_en: "Mueang Chumphon, Hat Sai Ri, Chumphon, 86120",
  },
  {
    id: 860117,
    districtTh: "เมืองชุมพร",
    districtEn: "Mueang Chumphon",
    subdistrictTh: "ถ้ำสิงห์",
    subdistrictEn: "Tham Sing",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86100,
    district_code: 8601,
    province_code: 69,
    name: "เมืองชุมพร ถ้ำสิงห์ ชุมพร 86100",
    name_th: "เมืองชุมพร ถ้ำสิงห์ ชุมพร 86100",
    name_en: "Mueang Chumphon, Tham Sing, Chumphon, 86100",
  },
  {
    id: 860201,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "ท่าแซะ",
    subdistrictEn: "Tha Sae",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86140,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ ท่าแซะ ชุมพร 86140",
    name_th: "ท่าแซะ ท่าแซะ ชุมพร 86140",
    name_en: "Tha Sae, Tha Sae, Chumphon, 86140",
  },
  {
    id: 860202,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "คุริง",
    subdistrictEn: "Khuring",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86140,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ คุริง ชุมพร 86140",
    name_th: "ท่าแซะ คุริง ชุมพร 86140",
    name_en: "Tha Sae, Khuring, Chumphon, 86140",
  },
  {
    id: 860203,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "สลุย",
    subdistrictEn: "Salui",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86140,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ สลุย ชุมพร 86140",
    name_th: "ท่าแซะ สลุย ชุมพร 86140",
    name_en: "Tha Sae, Salui, Chumphon, 86140",
  },
  {
    id: 860204,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "นากระตาม",
    subdistrictEn: "Na Kratam",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86140,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ นากระตาม ชุมพร 86140",
    name_th: "ท่าแซะ นากระตาม ชุมพร 86140",
    name_en: "Tha Sae, Na Kratam, Chumphon, 86140",
  },
  {
    id: 860205,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "รับร่อ",
    subdistrictEn: "Rap Ro",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86190,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ รับร่อ ชุมพร 86190",
    name_th: "ท่าแซะ รับร่อ ชุมพร 86190",
    name_en: "Tha Sae, Rap Ro, Chumphon, 86190",
  },
  {
    id: 860206,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86140,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ ท่าข้าม ชุมพร 86140",
    name_th: "ท่าแซะ ท่าข้าม ชุมพร 86140",
    name_en: "Tha Sae, Tha Kham, Chumphon, 86140",
  },
  {
    id: 860207,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "หงษ์เจริญ",
    subdistrictEn: "Hong Charoen",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86140,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ หงษ์เจริญ ชุมพร 86140",
    name_th: "ท่าแซะ หงษ์เจริญ ชุมพร 86140",
    name_en: "Tha Sae, Hong Charoen, Chumphon, 86140",
  },
  {
    id: 860208,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "หินแก้ว",
    subdistrictEn: "Hin Kaeo",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86190,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ หินแก้ว ชุมพร 86190",
    name_th: "ท่าแซะ หินแก้ว ชุมพร 86190",
    name_en: "Tha Sae, Hin Kaeo, Chumphon, 86190",
  },
  {
    id: 860209,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "ทรัพย์อนันต์",
    subdistrictEn: "Sap Anan",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86140,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ ทรัพย์อนันต์ ชุมพร 86140",
    name_th: "ท่าแซะ ทรัพย์อนันต์ ชุมพร 86140",
    name_en: "Tha Sae, Sap Anan, Chumphon, 86140",
  },
  {
    id: 860210,
    districtTh: "ท่าแซะ",
    districtEn: "Tha Sae",
    subdistrictTh: "สองพี่น้อง",
    subdistrictEn: "Song Phi Nong",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86140,
    district_code: 8602,
    province_code: 69,
    name: "ท่าแซะ สองพี่น้อง ชุมพร 86140",
    name_th: "ท่าแซะ สองพี่น้อง ชุมพร 86140",
    name_en: "Tha Sae, Song Phi Nong, Chumphon, 86140",
  },
  {
    id: 860301,
    districtTh: "ปะทิว",
    districtEn: "Pathio",
    subdistrictTh: "บางสน",
    subdistrictEn: "Bang Song",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86160,
    district_code: 8603,
    province_code: 69,
    name: "ปะทิว บางสน ชุมพร 86160",
    name_th: "ปะทิว บางสน ชุมพร 86160",
    name_en: "Pathio, Bang Song, Chumphon, 86160",
  },
  {
    id: 860302,
    districtTh: "ปะทิว",
    districtEn: "Pathio",
    subdistrictTh: "ทะเลทรัพย์",
    subdistrictEn: "Thale Sap",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86160,
    district_code: 8603,
    province_code: 69,
    name: "ปะทิว ทะเลทรัพย์ ชุมพร 86160",
    name_th: "ปะทิว ทะเลทรัพย์ ชุมพร 86160",
    name_en: "Pathio, Thale Sap, Chumphon, 86160",
  },
  {
    id: 860303,
    districtTh: "ปะทิว",
    districtEn: "Pathio",
    subdistrictTh: "สะพลี",
    subdistrictEn: "Saphli",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86230,
    district_code: 8603,
    province_code: 69,
    name: "ปะทิว สะพลี ชุมพร 86230",
    name_th: "ปะทิว สะพลี ชุมพร 86230",
    name_en: "Pathio, Saphli, Chumphon, 86230",
  },
  {
    id: 860304,
    districtTh: "ปะทิว",
    districtEn: "Pathio",
    subdistrictTh: "ชุมโค",
    subdistrictEn: "Chum Kho",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86160,
    district_code: 8603,
    province_code: 69,
    name: "ปะทิว ชุมโค ชุมพร 86160",
    name_th: "ปะทิว ชุมโค ชุมพร 86160",
    name_en: "Pathio, Chum Kho, Chumphon, 86160",
  },
  {
    id: 860305,
    districtTh: "ปะทิว",
    districtEn: "Pathio",
    subdistrictTh: "ดอนยาง",
    subdistrictEn: "Don Yang",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86210,
    district_code: 8603,
    province_code: 69,
    name: "ปะทิว ดอนยาง ชุมพร 86210",
    name_th: "ปะทิว ดอนยาง ชุมพร 86210",
    name_en: "Pathio, Don Yang, Chumphon, 86210",
  },
  {
    id: 860306,
    districtTh: "ปะทิว",
    districtEn: "Pathio",
    subdistrictTh: "ปากคลอง",
    subdistrictEn: "Pak Khlong",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86210,
    district_code: 8603,
    province_code: 69,
    name: "ปะทิว ปากคลอง ชุมพร 86210",
    name_th: "ปะทิว ปากคลอง ชุมพร 86210",
    name_en: "Pathio, Pak Khlong, Chumphon, 86210",
  },
  {
    id: 860307,
    districtTh: "ปะทิว",
    districtEn: "Pathio",
    subdistrictTh: "เขาไชยราช",
    subdistrictEn: "Khao Chai Rat",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86210,
    district_code: 8603,
    province_code: 69,
    name: "ปะทิว เขาไชยราช ชุมพร 86210",
    name_th: "ปะทิว เขาไชยราช ชุมพร 86210",
    name_en: "Pathio, Khao Chai Rat, Chumphon, 86210",
  },
  {
    id: 860401,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "หลังสวน",
    subdistrictEn: "Lang Suan",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน หลังสวน ชุมพร 86110",
    name_th: "หลังสวน หลังสวน ชุมพร 86110",
    name_en: "Lang Suan, Lang Suan, Chumphon, 86110",
  },
  {
    id: 860402,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "ขันเงิน",
    subdistrictEn: "Khan Ngoen",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน ขันเงิน ชุมพร 86110",
    name_th: "หลังสวน ขันเงิน ชุมพร 86110",
    name_en: "Lang Suan, Khan Ngoen, Chumphon, 86110",
  },
  {
    id: 860403,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "ท่ามะพลา",
    subdistrictEn: "Tha Maphla",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน ท่ามะพลา ชุมพร 86110",
    name_th: "หลังสวน ท่ามะพลา ชุมพร 86110",
    name_en: "Lang Suan, Tha Maphla, Chumphon, 86110",
  },
  {
    id: 860404,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "นาขา",
    subdistrictEn: "Na Kha",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน นาขา ชุมพร 86110",
    name_th: "หลังสวน นาขา ชุมพร 86110",
    name_en: "Lang Suan, Na Kha, Chumphon, 86110",
  },
  {
    id: 860405,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "นาพญา",
    subdistrictEn: "Na Phaya",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน นาพญา ชุมพร 86110",
    name_th: "หลังสวน นาพญา ชุมพร 86110",
    name_en: "Lang Suan, Na Phaya, Chumphon, 86110",
  },
  {
    id: 860406,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "บ้านควน",
    subdistrictEn: "Ban Khuan",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน บ้านควน ชุมพร 86110",
    name_th: "หลังสวน บ้านควน ชุมพร 86110",
    name_en: "Lang Suan, Ban Khuan, Chumphon, 86110",
  },
  {
    id: 860407,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "บางมะพร้าว",
    subdistrictEn: "Bang Maphrao",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน บางมะพร้าว ชุมพร 86110",
    name_th: "หลังสวน บางมะพร้าว ชุมพร 86110",
    name_en: "Lang Suan, Bang Maphrao, Chumphon, 86110",
  },
  {
    id: 860408,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "บางน้ำจืด",
    subdistrictEn: "Bang Nam Chuet",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86150,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน บางน้ำจืด ชุมพร 86150",
    name_th: "หลังสวน บางน้ำจืด ชุมพร 86150",
    name_en: "Lang Suan, Bang Nam Chuet, Chumphon, 86150",
  },
  {
    id: 860409,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86150,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน ปากน้ำ ชุมพร 86150",
    name_th: "หลังสวน ปากน้ำ ชุมพร 86150",
    name_en: "Lang Suan, Pak Nam, Chumphon, 86150",
  },
  {
    id: 860410,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "พ้อแดง",
    subdistrictEn: "Pho Daeng",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน พ้อแดง ชุมพร 86110",
    name_th: "หลังสวน พ้อแดง ชุมพร 86110",
    name_en: "Lang Suan, Pho Daeng, Chumphon, 86110",
  },
  {
    id: 860411,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "แหลมทราย",
    subdistrictEn: "Laem Sai",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน แหลมทราย ชุมพร 86110",
    name_th: "หลังสวน แหลมทราย ชุมพร 86110",
    name_en: "Lang Suan, Laem Sai, Chumphon, 86110",
  },
  {
    id: 860412,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "วังตะกอ",
    subdistrictEn: "Wang Tako",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน วังตะกอ ชุมพร 86110",
    name_th: "หลังสวน วังตะกอ ชุมพร 86110",
    name_en: "Lang Suan, Wang Tako, Chumphon, 86110",
  },
  {
    id: 860413,
    districtTh: "หลังสวน",
    districtEn: "Lang Suan",
    subdistrictTh: "หาดยาย",
    subdistrictEn: "Hat Yai",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86110,
    district_code: 8604,
    province_code: 69,
    name: "หลังสวน หาดยาย ชุมพร 86110",
    name_th: "หลังสวน หาดยาย ชุมพร 86110",
    name_en: "Lang Suan, Hat Yai, Chumphon, 86110",
  },
  {
    id: 860501,
    districtTh: "ละแม",
    districtEn: "Lamae",
    subdistrictTh: "ละแม",
    subdistrictEn: "Lamae",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86170,
    district_code: 8605,
    province_code: 69,
    name: "ละแม ละแม ชุมพร 86170",
    name_th: "ละแม ละแม ชุมพร 86170",
    name_en: "Lamae, Lamae, Chumphon, 86170",
  },
  {
    id: 860502,
    districtTh: "ละแม",
    districtEn: "Lamae",
    subdistrictTh: "ทุ่งหลวง",
    subdistrictEn: "Thung Luang",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86170,
    district_code: 8605,
    province_code: 69,
    name: "ละแม ทุ่งหลวง ชุมพร 86170",
    name_th: "ละแม ทุ่งหลวง ชุมพร 86170",
    name_en: "Lamae, Thung Luang, Chumphon, 86170",
  },
  {
    id: 860503,
    districtTh: "ละแม",
    districtEn: "Lamae",
    subdistrictTh: "สวนแตง",
    subdistrictEn: "Suan Taeng",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86170,
    district_code: 8605,
    province_code: 69,
    name: "ละแม สวนแตง ชุมพร 86170",
    name_th: "ละแม สวนแตง ชุมพร 86170",
    name_en: "Lamae, Suan Taeng, Chumphon, 86170",
  },
  {
    id: 860504,
    districtTh: "ละแม",
    districtEn: "Lamae",
    subdistrictTh: "ทุ่งคาวัด",
    subdistrictEn: "Thung Kha Wat",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86170,
    district_code: 8605,
    province_code: 69,
    name: "ละแม ทุ่งคาวัด ชุมพร 86170",
    name_th: "ละแม ทุ่งคาวัด ชุมพร 86170",
    name_en: "Lamae, Thung Kha Wat, Chumphon, 86170",
  },
  {
    id: 860601,
    districtTh: "พะโต๊ะ",
    districtEn: "Phato",
    subdistrictTh: "พะโต๊ะ",
    subdistrictEn: "Phato",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86180,
    district_code: 8606,
    province_code: 69,
    name: "พะโต๊ะ พะโต๊ะ ชุมพร 86180",
    name_th: "พะโต๊ะ พะโต๊ะ ชุมพร 86180",
    name_en: "Phato, Phato, Chumphon, 86180",
  },
  {
    id: 860602,
    districtTh: "พะโต๊ะ",
    districtEn: "Phato",
    subdistrictTh: "ปากทรง",
    subdistrictEn: "Pak Song",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86180,
    district_code: 8606,
    province_code: 69,
    name: "พะโต๊ะ ปากทรง ชุมพร 86180",
    name_th: "พะโต๊ะ ปากทรง ชุมพร 86180",
    name_en: "Phato, Pak Song, Chumphon, 86180",
  },
  {
    id: 860603,
    districtTh: "พะโต๊ะ",
    districtEn: "Phato",
    subdistrictTh: "ปังหวาน",
    subdistrictEn: "Pang Wan",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86180,
    district_code: 8606,
    province_code: 69,
    name: "พะโต๊ะ ปังหวาน ชุมพร 86180",
    name_th: "พะโต๊ะ ปังหวาน ชุมพร 86180",
    name_en: "Phato, Pang Wan, Chumphon, 86180",
  },
  {
    id: 860604,
    districtTh: "พะโต๊ะ",
    districtEn: "Phato",
    subdistrictTh: "พระรักษ์",
    subdistrictEn: "Phra Rak",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86180,
    district_code: 8606,
    province_code: 69,
    name: "พะโต๊ะ พระรักษ์ ชุมพร 86180",
    name_th: "พะโต๊ะ พระรักษ์ ชุมพร 86180",
    name_en: "Phato, Phra Rak, Chumphon, 86180",
  },
  {
    id: 860701,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "นาโพธิ์",
    subdistrictEn: "Na Pho",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี นาโพธิ์ ชุมพร 86130",
    name_th: "สวี นาโพธิ์ ชุมพร 86130",
    name_en: "Sawi, Na Pho, Chumphon, 86130",
  },
  {
    id: 860702,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "สวี",
    subdistrictEn: "Sawi",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี สวี ชุมพร 86130",
    name_th: "สวี สวี ชุมพร 86130",
    name_en: "Sawi, Sawi, Chumphon, 86130",
  },
  {
    id: 860703,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "ทุ่งระยะ",
    subdistrictEn: "Thung Raya",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี ทุ่งระยะ ชุมพร 86130",
    name_th: "สวี ทุ่งระยะ ชุมพร 86130",
    name_en: "Sawi, Thung Raya, Chumphon, 86130",
  },
  {
    id: 860704,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "ท่าหิน",
    subdistrictEn: "Tha Hin",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี ท่าหิน ชุมพร 86130",
    name_th: "สวี ท่าหิน ชุมพร 86130",
    name_en: "Sawi, Tha Hin, Chumphon, 86130",
  },
  {
    id: 860705,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "ปากแพรก",
    subdistrictEn: "Pak Phraek",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี ปากแพรก ชุมพร 86130",
    name_th: "สวี ปากแพรก ชุมพร 86130",
    name_en: "Sawi, Pak Phraek, Chumphon, 86130",
  },
  {
    id: 860706,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "ด่านสวี",
    subdistrictEn: "Dan Sawi",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี ด่านสวี ชุมพร 86130",
    name_th: "สวี ด่านสวี ชุมพร 86130",
    name_en: "Sawi, Dan Sawi, Chumphon, 86130",
  },
  {
    id: 860707,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "ครน",
    subdistrictEn: "Khron",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี ครน ชุมพร 86130",
    name_th: "สวี ครน ชุมพร 86130",
    name_en: "Sawi, Khron, Chumphon, 86130",
  },
  {
    id: 860708,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "วิสัยใต้",
    subdistrictEn: "Wisai Tai",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี วิสัยใต้ ชุมพร 86130",
    name_th: "สวี วิสัยใต้ ชุมพร 86130",
    name_en: "Sawi, Wisai Tai, Chumphon, 86130",
  },
  {
    id: 860709,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "นาสัก",
    subdistrictEn: "Na Sak",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี นาสัก ชุมพร 86130",
    name_th: "สวี นาสัก ชุมพร 86130",
    name_en: "Sawi, Na Sak, Chumphon, 86130",
  },
  {
    id: 860710,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "เขาทะลุ",
    subdistrictEn: "Khao Thalu",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี เขาทะลุ ชุมพร 86130",
    name_th: "สวี เขาทะลุ ชุมพร 86130",
    name_en: "Sawi, Khao Thalu, Chumphon, 86130",
  },
  {
    id: 860711,
    districtTh: "สวี",
    districtEn: "Sawi",
    subdistrictTh: "เขาค่าย",
    subdistrictEn: "Khao Khai",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86130,
    district_code: 8607,
    province_code: 69,
    name: "สวี เขาค่าย ชุมพร 86130",
    name_th: "สวี เขาค่าย ชุมพร 86130",
    name_en: "Sawi, Khao Khai, Chumphon, 86130",
  },
  {
    id: 860801,
    districtTh: "ทุ่งตะโก",
    districtEn: "Thung Tako",
    subdistrictTh: "ปากตะโก",
    subdistrictEn: "Pak Tako",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86220,
    district_code: 8608,
    province_code: 69,
    name: "ทุ่งตะโก ปากตะโก ชุมพร 86220",
    name_th: "ทุ่งตะโก ปากตะโก ชุมพร 86220",
    name_en: "Thung Tako, Pak Tako, Chumphon, 86220",
  },
  {
    id: 860802,
    districtTh: "ทุ่งตะโก",
    districtEn: "Thung Tako",
    subdistrictTh: "ทุ่งตะไคร",
    subdistrictEn: "Thung Takhrai",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86220,
    district_code: 8608,
    province_code: 69,
    name: "ทุ่งตะโก ทุ่งตะไคร ชุมพร 86220",
    name_th: "ทุ่งตะโก ทุ่งตะไคร ชุมพร 86220",
    name_en: "Thung Tako, Thung Takhrai, Chumphon, 86220",
  },
  {
    id: 860803,
    districtTh: "ทุ่งตะโก",
    districtEn: "Thung Tako",
    subdistrictTh: "ตะโก",
    subdistrictEn: "Tako",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86220,
    district_code: 8608,
    province_code: 69,
    name: "ทุ่งตะโก ตะโก ชุมพร 86220",
    name_th: "ทุ่งตะโก ตะโก ชุมพร 86220",
    name_en: "Thung Tako, Tako, Chumphon, 86220",
  },
  {
    id: 860804,
    districtTh: "ทุ่งตะโก",
    districtEn: "Thung Tako",
    subdistrictTh: "ช่องไม้แก้ว",
    subdistrictEn: "Chong Mai Kaeo",
    provinceTh: "ชุมพร",
    provinceEn: "Chumphon",
    zipcode: 86220,
    district_code: 8608,
    province_code: 69,
    name: "ทุ่งตะโก ช่องไม้แก้ว ชุมพร 86220",
    name_th: "ทุ่งตะโก ช่องไม้แก้ว ชุมพร 86220",
    name_en: "Thung Tako, Chong Mai Kaeo, Chumphon, 86220",
  },
  {
    id: 900101,
    districtTh: "เมืองสงขลา",
    districtEn: "Mueang Songkhla",
    subdistrictTh: "บ่อยาง",
    subdistrictEn: "Bo Yang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90000,
    district_code: 9001,
    province_code: 70,
    name: "เมืองสงขลา บ่อยาง สงขลา 90000",
    name_th: "เมืองสงขลา บ่อยาง สงขลา 90000",
    name_en: "Mueang Songkhla, Bo Yang, Songkhla, 90000",
  },
  {
    id: 900102,
    districtTh: "เมืองสงขลา",
    districtEn: "Mueang Songkhla",
    subdistrictTh: "เขารูปช้าง",
    subdistrictEn: "Khao Rup Chang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90000,
    district_code: 9001,
    province_code: 70,
    name: "เมืองสงขลา เขารูปช้าง สงขลา 90000",
    name_th: "เมืองสงขลา เขารูปช้าง สงขลา 90000",
    name_en: "Mueang Songkhla, Khao Rup Chang, Songkhla, 90000",
  },
  {
    id: 900103,
    districtTh: "เมืองสงขลา",
    districtEn: "Mueang Songkhla",
    subdistrictTh: "เกาะแต้ว",
    subdistrictEn: "Ko Taeo",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90000,
    district_code: 9001,
    province_code: 70,
    name: "เมืองสงขลา เกาะแต้ว สงขลา 90000",
    name_th: "เมืองสงขลา เกาะแต้ว สงขลา 90000",
    name_en: "Mueang Songkhla, Ko Taeo, Songkhla, 90000",
  },
  {
    id: 900104,
    districtTh: "เมืองสงขลา",
    districtEn: "Mueang Songkhla",
    subdistrictTh: "พะวง",
    subdistrictEn: "Phawong",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90100,
    district_code: 9001,
    province_code: 70,
    name: "เมืองสงขลา พะวง สงขลา 90100",
    name_th: "เมืองสงขลา พะวง สงขลา 90100",
    name_en: "Mueang Songkhla, Phawong, Songkhla, 90100",
  },
  {
    id: 900105,
    districtTh: "เมืองสงขลา",
    districtEn: "Mueang Songkhla",
    subdistrictTh: "ทุ่งหวัง",
    subdistrictEn: "Thung Wang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90000,
    district_code: 9001,
    province_code: 70,
    name: "เมืองสงขลา ทุ่งหวัง สงขลา 90000",
    name_th: "เมืองสงขลา ทุ่งหวัง สงขลา 90000",
    name_en: "Mueang Songkhla, Thung Wang, Songkhla, 90000",
  },
  {
    id: 900106,
    districtTh: "เมืองสงขลา",
    districtEn: "Mueang Songkhla",
    subdistrictTh: "เกาะยอ",
    subdistrictEn: "Ko Yo",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90100,
    district_code: 9001,
    province_code: 70,
    name: "เมืองสงขลา เกาะยอ สงขลา 90100",
    name_th: "เมืองสงขลา เกาะยอ สงขลา 90100",
    name_en: "Mueang Songkhla, Ko Yo, Songkhla, 90100",
  },
  {
    id: 900201,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "จะทิ้งพระ",
    subdistrictEn: "Chathing Phra",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ จะทิ้งพระ สงขลา 90190",
    name_th: "สทิงพระ จะทิ้งพระ สงขลา 90190",
    name_en: "Sathing Phra, Chathing Phra, Songkhla, 90190",
  },
  {
    id: 900202,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "กระดังงา",
    subdistrictEn: "Kradangnga",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ กระดังงา สงขลา 90190",
    name_th: "สทิงพระ กระดังงา สงขลา 90190",
    name_en: "Sathing Phra, Kradangnga, Songkhla, 90190",
  },
  {
    id: 900203,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "สนามชัย",
    subdistrictEn: "Sanam Chai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ สนามชัย สงขลา 90190",
    name_th: "สทิงพระ สนามชัย สงขลา 90190",
    name_en: "Sathing Phra, Sanam Chai, Songkhla, 90190",
  },
  {
    id: 900204,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "ดีหลวง",
    subdistrictEn: "Di Luang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ ดีหลวง สงขลา 90190",
    name_th: "สทิงพระ ดีหลวง สงขลา 90190",
    name_en: "Sathing Phra, Di Luang, Songkhla, 90190",
  },
  {
    id: 900205,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "ชุมพล",
    subdistrictEn: "Chumphon",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ ชุมพล สงขลา 90190",
    name_th: "สทิงพระ ชุมพล สงขลา 90190",
    name_en: "Sathing Phra, Chumphon, Songkhla, 90190",
  },
  {
    id: 900206,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "คลองรี",
    subdistrictEn: "Khlong Ri",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ คลองรี สงขลา 90190",
    name_th: "สทิงพระ คลองรี สงขลา 90190",
    name_en: "Sathing Phra, Khlong Ri, Songkhla, 90190",
  },
  {
    id: 900207,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "คูขุด",
    subdistrictEn: "Khu Khut",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ คูขุด สงขลา 90190",
    name_th: "สทิงพระ คูขุด สงขลา 90190",
    name_en: "Sathing Phra, Khu Khut, Songkhla, 90190",
  },
  {
    id: 900208,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "ท่าหิน",
    subdistrictEn: "Tha Hin",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ ท่าหิน สงขลา 90190",
    name_th: "สทิงพระ ท่าหิน สงขลา 90190",
    name_en: "Sathing Phra, Tha Hin, Songkhla, 90190",
  },
  {
    id: 900209,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "วัดจันทร์",
    subdistrictEn: "Wat Chan",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ วัดจันทร์ สงขลา 90190",
    name_th: "สทิงพระ วัดจันทร์ สงขลา 90190",
    name_en: "Sathing Phra, Wat Chan, Songkhla, 90190",
  },
  {
    id: 900210,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "บ่อแดง",
    subdistrictEn: "Bo Daeng",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ บ่อแดง สงขลา 90190",
    name_th: "สทิงพระ บ่อแดง สงขลา 90190",
    name_en: "Sathing Phra, Bo Daeng, Songkhla, 90190",
  },
  {
    id: 900211,
    districtTh: "สทิงพระ",
    districtEn: "Sathing Phra",
    subdistrictTh: "บ่อดาน",
    subdistrictEn: "Bor Dan",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90190,
    district_code: 9002,
    province_code: 70,
    name: "สทิงพระ บ่อดาน สงขลา 90190",
    name_th: "สทิงพระ บ่อดาน สงขลา 90190",
    name_en: "Sathing Phra, Bor Dan, Songkhla, 90190",
  },
  {
    id: 900301,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ บ้านนา สงขลา 90130",
    name_th: "จะนะ บ้านนา สงขลา 90130",
    name_en: "Chana, Ban Na, Songkhla, 90130",
  },
  {
    id: 900302,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "ป่าชิง",
    subdistrictEn: "Pa Ching",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ ป่าชิง สงขลา 90130",
    name_th: "จะนะ ป่าชิง สงขลา 90130",
    name_en: "Chana, Pa Ching, Songkhla, 90130",
  },
  {
    id: 900303,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "สะพานไม้แก่น",
    subdistrictEn: "Saphan Mai Kaen",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ สะพานไม้แก่น สงขลา 90130",
    name_th: "จะนะ สะพานไม้แก่น สงขลา 90130",
    name_en: "Chana, Saphan Mai Kaen, Songkhla, 90130",
  },
  {
    id: 900304,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "สะกอม",
    subdistrictEn: "Sakom",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ สะกอม สงขลา 90130",
    name_th: "จะนะ สะกอม สงขลา 90130",
    name_en: "Chana, Sakom, Songkhla, 90130",
  },
  {
    id: 900305,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "นาหว้า",
    subdistrictEn: "Na Wa",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ นาหว้า สงขลา 90130",
    name_th: "จะนะ นาหว้า สงขลา 90130",
    name_en: "Chana, Na Wa, Songkhla, 90130",
  },
  {
    id: 900306,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "นาทับ",
    subdistrictEn: "Na Thap",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ นาทับ สงขลา 90130",
    name_th: "จะนะ นาทับ สงขลา 90130",
    name_en: "Chana, Na Thap, Songkhla, 90130",
  },
  {
    id: 900307,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "น้ำขาว",
    subdistrictEn: "Nam Khao",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ น้ำขาว สงขลา 90130",
    name_th: "จะนะ น้ำขาว สงขลา 90130",
    name_en: "Chana, Nam Khao, Songkhla, 90130",
  },
  {
    id: 900308,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "ขุนตัดหวาย",
    subdistrictEn: "Khun Tat Wai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ ขุนตัดหวาย สงขลา 90130",
    name_th: "จะนะ ขุนตัดหวาย สงขลา 90130",
    name_en: "Chana, Khun Tat Wai, Songkhla, 90130",
  },
  {
    id: 900309,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "ท่าหมอไทร",
    subdistrictEn: "Tha Mo Sai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ ท่าหมอไทร สงขลา 90130",
    name_th: "จะนะ ท่าหมอไทร สงขลา 90130",
    name_en: "Chana, Tha Mo Sai, Songkhla, 90130",
  },
  {
    id: 900310,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "จะโหนง",
    subdistrictEn: "Chanong",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ จะโหนง สงขลา 90130",
    name_th: "จะนะ จะโหนง สงขลา 90130",
    name_en: "Chana, Chanong, Songkhla, 90130",
  },
  {
    id: 900311,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "คู",
    subdistrictEn: "Khu",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ คู สงขลา 90130",
    name_th: "จะนะ คู สงขลา 90130",
    name_en: "Chana, Khu, Songkhla, 90130",
  },
  {
    id: 900312,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "แค",
    subdistrictEn: "Khae",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ แค สงขลา 90130",
    name_th: "จะนะ แค สงขลา 90130",
    name_en: "Chana, Khae, Songkhla, 90130",
  },
  {
    id: 900313,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "คลองเปียะ",
    subdistrictEn: "Khlong Pia",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ คลองเปียะ สงขลา 90130",
    name_th: "จะนะ คลองเปียะ สงขลา 90130",
    name_en: "Chana, Khlong Pia, Songkhla, 90130",
  },
  {
    id: 900314,
    districtTh: "จะนะ",
    districtEn: "Chana",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90130,
    district_code: 9003,
    province_code: 70,
    name: "จะนะ ตลิ่งชัน สงขลา 90130",
    name_th: "จะนะ ตลิ่งชัน สงขลา 90130",
    name_en: "Chana, Taling Chan, Songkhla, 90130",
  },
  {
    id: 900401,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "นาทวี",
    subdistrictEn: "Na Thawi",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี นาทวี สงขลา 90160",
    name_th: "นาทวี นาทวี สงขลา 90160",
    name_en: "Na Thawi, Na Thawi, Songkhla, 90160",
  },
  {
    id: 900402,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "ฉาง",
    subdistrictEn: "Chang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี ฉาง สงขลา 90160",
    name_th: "นาทวี ฉาง สงขลา 90160",
    name_en: "Na Thawi, Chang, Songkhla, 90160",
  },
  {
    id: 900403,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "นาหมอศรี",
    subdistrictEn: "Na Mo Si",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี นาหมอศรี สงขลา 90160",
    name_th: "นาทวี นาหมอศรี สงขลา 90160",
    name_en: "Na Thawi, Na Mo Si, Songkhla, 90160",
  },
  {
    id: 900404,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "คลองทราย",
    subdistrictEn: "Khlong Sai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี คลองทราย สงขลา 90160",
    name_th: "นาทวี คลองทราย สงขลา 90160",
    name_en: "Na Thawi, Khlong Sai, Songkhla, 90160",
  },
  {
    id: 900405,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "ปลักหนู",
    subdistrictEn: "Plak Nu",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี ปลักหนู สงขลา 90160",
    name_th: "นาทวี ปลักหนู สงขลา 90160",
    name_en: "Na Thawi, Plak Nu, Songkhla, 90160",
  },
  {
    id: 900406,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "ท่าประดู่",
    subdistrictEn: "Tha Pradu",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี ท่าประดู่ สงขลา 90160",
    name_th: "นาทวี ท่าประดู่ สงขลา 90160",
    name_en: "Na Thawi, Tha Pradu, Songkhla, 90160",
  },
  {
    id: 900407,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "สะท้อน",
    subdistrictEn: "Sathon",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี สะท้อน สงขลา 90160",
    name_th: "นาทวี สะท้อน สงขลา 90160",
    name_en: "Na Thawi, Sathon, Songkhla, 90160",
  },
  {
    id: 900408,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "ทับช้าง",
    subdistrictEn: "Thap Chang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี ทับช้าง สงขลา 90160",
    name_th: "นาทวี ทับช้าง สงขลา 90160",
    name_en: "Na Thawi, Thap Chang, Songkhla, 90160",
  },
  {
    id: 900409,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "ประกอบ",
    subdistrictEn: "Prakop",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี ประกอบ สงขลา 90160",
    name_th: "นาทวี ประกอบ สงขลา 90160",
    name_en: "Na Thawi, Prakop, Songkhla, 90160",
  },
  {
    id: 900410,
    districtTh: "นาทวี",
    districtEn: "Na Thawi",
    subdistrictTh: "คลองกวาง",
    subdistrictEn: "Khlong Kwang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90160,
    district_code: 9004,
    province_code: 70,
    name: "นาทวี คลองกวาง สงขลา 90160",
    name_th: "นาทวี คลองกวาง สงขลา 90160",
    name_en: "Na Thawi, Khlong Kwang, Songkhla, 90160",
  },
  {
    id: 900501,
    districtTh: "เทพา",
    districtEn: "Thepha",
    subdistrictTh: "เทพา",
    subdistrictEn: "Thepha",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90150,
    district_code: 9005,
    province_code: 70,
    name: "เทพา เทพา สงขลา 90150",
    name_th: "เทพา เทพา สงขลา 90150",
    name_en: "Thepha, Thepha, Songkhla, 90150",
  },
  {
    id: 900502,
    districtTh: "เทพา",
    districtEn: "Thepha",
    subdistrictTh: "ปากบาง",
    subdistrictEn: "Pak Bang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90150,
    district_code: 9005,
    province_code: 70,
    name: "เทพา ปากบาง สงขลา 90150",
    name_th: "เทพา ปากบาง สงขลา 90150",
    name_en: "Thepha, Pak Bang, Songkhla, 90150",
  },
  {
    id: 900503,
    districtTh: "เทพา",
    districtEn: "Thepha",
    subdistrictTh: "เกาะสะบ้า",
    subdistrictEn: "Ko Saba",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90150,
    district_code: 9005,
    province_code: 70,
    name: "เทพา เกาะสะบ้า สงขลา 90150",
    name_th: "เทพา เกาะสะบ้า สงขลา 90150",
    name_en: "Thepha, Ko Saba, Songkhla, 90150",
  },
  {
    id: 900504,
    districtTh: "เทพา",
    districtEn: "Thepha",
    subdistrictTh: "ลำไพล",
    subdistrictEn: "Lam Phlai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90260,
    district_code: 9005,
    province_code: 70,
    name: "เทพา ลำไพล สงขลา 90260",
    name_th: "เทพา ลำไพล สงขลา 90260",
    name_en: "Thepha, Lam Phlai, Songkhla, 90260",
  },
  {
    id: 900505,
    districtTh: "เทพา",
    districtEn: "Thepha",
    subdistrictTh: "ท่าม่วง",
    subdistrictEn: "Tha Muang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90260,
    district_code: 9005,
    province_code: 70,
    name: "เทพา ท่าม่วง สงขลา 90260",
    name_th: "เทพา ท่าม่วง สงขลา 90260",
    name_en: "Thepha, Tha Muang, Songkhla, 90260",
  },
  {
    id: 900506,
    districtTh: "เทพา",
    districtEn: "Thepha",
    subdistrictTh: "วังใหญ่",
    subdistrictEn: "Wang Yai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90260,
    district_code: 9005,
    province_code: 70,
    name: "เทพา วังใหญ่ สงขลา 90260",
    name_th: "เทพา วังใหญ่ สงขลา 90260",
    name_en: "Thepha, Wang Yai, Songkhla, 90260",
  },
  {
    id: 900507,
    districtTh: "เทพา",
    districtEn: "Thepha",
    subdistrictTh: "สะกอม",
    subdistrictEn: "Sakom",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90150,
    district_code: 9005,
    province_code: 70,
    name: "เทพา สะกอม สงขลา 90150",
    name_th: "เทพา สะกอม สงขลา 90150",
    name_en: "Thepha, Sakom, Songkhla, 90150",
  },
  {
    id: 900601,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "สะบ้าย้อย",
    subdistrictEn: "Saba Yoi",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย สะบ้าย้อย สงขลา 90210",
    name_th: "สะบ้าย้อย สะบ้าย้อย สงขลา 90210",
    name_en: "Saba Yoi, Saba Yoi, Songkhla, 90210",
  },
  {
    id: 900602,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "ทุ่งพอ",
    subdistrictEn: "Thung Pho",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย ทุ่งพอ สงขลา 90210",
    name_th: "สะบ้าย้อย ทุ่งพอ สงขลา 90210",
    name_en: "Saba Yoi, Thung Pho, Songkhla, 90210",
  },
  {
    id: 900603,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "เปียน",
    subdistrictEn: "Pian",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย เปียน สงขลา 90210",
    name_th: "สะบ้าย้อย เปียน สงขลา 90210",
    name_en: "Saba Yoi, Pian, Songkhla, 90210",
  },
  {
    id: 900604,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "บ้านโหนด",
    subdistrictEn: "Ban Not",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย บ้านโหนด สงขลา 90210",
    name_th: "สะบ้าย้อย บ้านโหนด สงขลา 90210",
    name_en: "Saba Yoi, Ban Not, Songkhla, 90210",
  },
  {
    id: 900605,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "จะแหน",
    subdistrictEn: "Chanae",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย จะแหน สงขลา 90210",
    name_th: "สะบ้าย้อย จะแหน สงขลา 90210",
    name_en: "Saba Yoi, Chanae, Songkhla, 90210",
  },
  {
    id: 900606,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "คูหา",
    subdistrictEn: "Khuha",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย คูหา สงขลา 90210",
    name_th: "สะบ้าย้อย คูหา สงขลา 90210",
    name_en: "Saba Yoi, Khuha, Songkhla, 90210",
  },
  {
    id: 900607,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "เขาแดง",
    subdistrictEn: "Khao Daeng",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย เขาแดง สงขลา 90210",
    name_th: "สะบ้าย้อย เขาแดง สงขลา 90210",
    name_en: "Saba Yoi, Khao Daeng, Songkhla, 90210",
  },
  {
    id: 900608,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "บาโหย",
    subdistrictEn: "Ba Hoi",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย บาโหย สงขลา 90210",
    name_th: "สะบ้าย้อย บาโหย สงขลา 90210",
    name_en: "Saba Yoi, Ba Hoi, Songkhla, 90210",
  },
  {
    id: 900609,
    districtTh: "สะบ้าย้อย",
    districtEn: "Saba Yoi",
    subdistrictTh: "ธารคีรี",
    subdistrictEn: "Than Khiri",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90210,
    district_code: 9006,
    province_code: 70,
    name: "สะบ้าย้อย ธารคีรี สงขลา 90210",
    name_th: "สะบ้าย้อย ธารคีรี สงขลา 90210",
    name_en: "Saba Yoi, Than Khiri, Songkhla, 90210",
  },
  {
    id: 900701,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "ระโนด",
    subdistrictEn: "Ranot",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด ระโนด สงขลา 90140",
    name_th: "ระโนด ระโนด สงขลา 90140",
    name_en: "Ranot, Ranot, Songkhla, 90140",
  },
  {
    id: 900702,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "คลองแดน",
    subdistrictEn: "Khlong Daen",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด คลองแดน สงขลา 90140",
    name_th: "ระโนด คลองแดน สงขลา 90140",
    name_en: "Ranot, Khlong Daen, Songkhla, 90140",
  },
  {
    id: 900703,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "ตะเครียะ",
    subdistrictEn: "Takhria",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด ตะเครียะ สงขลา 90140",
    name_th: "ระโนด ตะเครียะ สงขลา 90140",
    name_en: "Ranot, Takhria, Songkhla, 90140",
  },
  {
    id: 900704,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "ท่าบอน",
    subdistrictEn: "Tha Bon",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด ท่าบอน สงขลา 90140",
    name_th: "ระโนด ท่าบอน สงขลา 90140",
    name_en: "Ranot, Tha Bon, Songkhla, 90140",
  },
  {
    id: 900705,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "บ้านใหม่",
    subdistrictEn: "Ban Mai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด บ้านใหม่ สงขลา 90140",
    name_th: "ระโนด บ้านใหม่ สงขลา 90140",
    name_en: "Ranot, Ban Mai, Songkhla, 90140",
  },
  {
    id: 900706,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "บ่อตรุ",
    subdistrictEn: "Bo Tru",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด บ่อตรุ สงขลา 90140",
    name_th: "ระโนด บ่อตรุ สงขลา 90140",
    name_en: "Ranot, Bo Tru, Songkhla, 90140",
  },
  {
    id: 900707,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "ปากแตระ",
    subdistrictEn: "Pak Trae",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด ปากแตระ สงขลา 90140",
    name_th: "ระโนด ปากแตระ สงขลา 90140",
    name_en: "Ranot, Pak Trae, Songkhla, 90140",
  },
  {
    id: 900708,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "พังยาง",
    subdistrictEn: "Phang Yang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด พังยาง สงขลา 90140",
    name_th: "ระโนด พังยาง สงขลา 90140",
    name_en: "Ranot, Phang Yang, Songkhla, 90140",
  },
  {
    id: 900709,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "ระวะ",
    subdistrictEn: "Rawa",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด ระวะ สงขลา 90140",
    name_th: "ระโนด ระวะ สงขลา 90140",
    name_en: "Ranot, Rawa, Songkhla, 90140",
  },
  {
    id: 900710,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "วัดสน",
    subdistrictEn: "Wat Son",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด วัดสน สงขลา 90140",
    name_th: "ระโนด วัดสน สงขลา 90140",
    name_en: "Ranot, Wat Son, Songkhla, 90140",
  },
  {
    id: 900711,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "บ้านขาว",
    subdistrictEn: "Ban Khao",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด บ้านขาว สงขลา 90140",
    name_th: "ระโนด บ้านขาว สงขลา 90140",
    name_en: "Ranot, Ban Khao, Songkhla, 90140",
  },
  {
    id: 900712,
    districtTh: "ระโนด",
    districtEn: "Ranot",
    subdistrictTh: "แดนสงวน",
    subdistrictEn: "Daen Sa-nguan",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90140,
    district_code: 9007,
    province_code: 70,
    name: "ระโนด แดนสงวน สงขลา 90140",
    name_th: "ระโนด แดนสงวน สงขลา 90140",
    name_en: "Ranot, Daen Sa-nguan, Songkhla, 90140",
  },
  {
    id: 900801,
    districtTh: "กระแสสินธุ์",
    districtEn: "Krasae Sin",
    subdistrictTh: "เกาะใหญ่",
    subdistrictEn: "Ko Yai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90270,
    district_code: 9008,
    province_code: 70,
    name: "กระแสสินธุ์ เกาะใหญ่ สงขลา 90270",
    name_th: "กระแสสินธุ์ เกาะใหญ่ สงขลา 90270",
    name_en: "Krasae Sin, Ko Yai, Songkhla, 90270",
  },
  {
    id: 900802,
    districtTh: "กระแสสินธุ์",
    districtEn: "Krasae Sin",
    subdistrictTh: "โรง",
    subdistrictEn: "Rong",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90270,
    district_code: 9008,
    province_code: 70,
    name: "กระแสสินธุ์ โรง สงขลา 90270",
    name_th: "กระแสสินธุ์ โรง สงขลา 90270",
    name_en: "Krasae Sin, Rong, Songkhla, 90270",
  },
  {
    id: 900803,
    districtTh: "กระแสสินธุ์",
    districtEn: "Krasae Sin",
    subdistrictTh: "เชิงแส",
    subdistrictEn: "Choeng Sae",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90270,
    district_code: 9008,
    province_code: 70,
    name: "กระแสสินธุ์ เชิงแส สงขลา 90270",
    name_th: "กระแสสินธุ์ เชิงแส สงขลา 90270",
    name_en: "Krasae Sin, Choeng Sae, Songkhla, 90270",
  },
  {
    id: 900804,
    districtTh: "กระแสสินธุ์",
    districtEn: "Krasae Sin",
    subdistrictTh: "กระแสสินธุ์",
    subdistrictEn: "Krasae Sin",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90270,
    district_code: 9008,
    province_code: 70,
    name: "กระแสสินธุ์ กระแสสินธุ์ สงขลา 90270",
    name_th: "กระแสสินธุ์ กระแสสินธุ์ สงขลา 90270",
    name_en: "Krasae Sin, Krasae Sin, Songkhla, 90270",
  },
  {
    id: 900901,
    districtTh: "รัตภูมิ",
    districtEn: "Rattaphum",
    subdistrictTh: "กำแพงเพชร",
    subdistrictEn: "Kamphaeng Phet",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90180,
    district_code: 9009,
    province_code: 70,
    name: "รัตภูมิ กำแพงเพชร สงขลา 90180",
    name_th: "รัตภูมิ กำแพงเพชร สงขลา 90180",
    name_en: "Rattaphum, Kamphaeng Phet, Songkhla, 90180",
  },
  {
    id: 900902,
    districtTh: "รัตภูมิ",
    districtEn: "Rattaphum",
    subdistrictTh: "ท่าชะมวง",
    subdistrictEn: "Tha Chamuang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90180,
    district_code: 9009,
    province_code: 70,
    name: "รัตภูมิ ท่าชะมวง สงขลา 90180",
    name_th: "รัตภูมิ ท่าชะมวง สงขลา 90180",
    name_en: "Rattaphum, Tha Chamuang, Songkhla, 90180",
  },
  {
    id: 900903,
    districtTh: "รัตภูมิ",
    districtEn: "Rattaphum",
    subdistrictTh: "คูหาใต้",
    subdistrictEn: "Khuha Tai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90180,
    district_code: 9009,
    province_code: 70,
    name: "รัตภูมิ คูหาใต้ สงขลา 90180",
    name_th: "รัตภูมิ คูหาใต้ สงขลา 90180",
    name_en: "Rattaphum, Khuha Tai, Songkhla, 90180",
  },
  {
    id: 900904,
    districtTh: "รัตภูมิ",
    districtEn: "Rattaphum",
    subdistrictTh: "ควนรู",
    subdistrictEn: "Khuan Ru",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90180,
    district_code: 9009,
    province_code: 70,
    name: "รัตภูมิ ควนรู สงขลา 90180",
    name_th: "รัตภูมิ ควนรู สงขลา 90180",
    name_en: "Rattaphum, Khuan Ru, Songkhla, 90180",
  },
  {
    id: 900909,
    districtTh: "รัตภูมิ",
    districtEn: "Rattaphum",
    subdistrictTh: "เขาพระ",
    subdistrictEn: "Khao Phra",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90180,
    district_code: 9009,
    province_code: 70,
    name: "รัตภูมิ เขาพระ สงขลา 90180",
    name_th: "รัตภูมิ เขาพระ สงขลา 90180",
    name_en: "Rattaphum, Khao Phra, Songkhla, 90180",
  },
  {
    id: 901001,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "สะเดา",
    subdistrictEn: "Sadao",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90120,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา สะเดา สงขลา 90120",
    name_th: "สะเดา สะเดา สงขลา 90120",
    name_en: "Sadao, Sadao, Songkhla, 90120",
  },
  {
    id: 901002,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "ปริก",
    subdistrictEn: "Prik",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90120,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา ปริก สงขลา 90120",
    name_th: "สะเดา ปริก สงขลา 90120",
    name_en: "Sadao, Prik, Songkhla, 90120",
  },
  {
    id: 901003,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "พังลา",
    subdistrictEn: "Phang La",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90170,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา พังลา สงขลา 90170",
    name_th: "สะเดา พังลา สงขลา 90170",
    name_en: "Sadao, Phang La, Songkhla, 90170",
  },
  {
    id: 901004,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "สำนักแต้ว",
    subdistrictEn: "Samnak Taeo",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90120,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา สำนักแต้ว สงขลา 90120",
    name_th: "สะเดา สำนักแต้ว สงขลา 90120",
    name_en: "Sadao, Samnak Taeo, Songkhla, 90120",
  },
  {
    id: 901005,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "ทุ่งหมอ",
    subdistrictEn: "Thung Mo",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90240,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา ทุ่งหมอ สงขลา 90240",
    name_th: "สะเดา ทุ่งหมอ สงขลา 90240",
    name_en: "Sadao, Thung Mo, Songkhla, 90240",
  },
  {
    id: 901006,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "ท่าโพธิ์",
    subdistrictEn: "Tha Pho",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90170,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา ท่าโพธิ์ สงขลา 90170",
    name_th: "สะเดา ท่าโพธิ์ สงขลา 90170",
    name_en: "Sadao, Tha Pho, Songkhla, 90170",
  },
  {
    id: 901007,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "ปาดังเบซาร์",
    subdistrictEn: "Padang Besa",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90240,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา ปาดังเบซาร์ สงขลา 90240",
    name_th: "สะเดา ปาดังเบซาร์ สงขลา 90240",
    name_en: "Sadao, Padang Besa, Songkhla, 90240",
  },
  {
    id: 901008,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "สำนักขาม",
    subdistrictEn: "Samnak Kham",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90320,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา สำนักขาม สงขลา 90320",
    name_th: "สะเดา สำนักขาม สงขลา 90320",
    name_en: "Sadao, Samnak Kham, Songkhla, 90320",
  },
  {
    id: 901009,
    districtTh: "สะเดา",
    districtEn: "Sadao",
    subdistrictTh: "เขามีเกียรติ",
    subdistrictEn: "Khao Mi Kiat",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90170,
    district_code: 9010,
    province_code: 70,
    name: "สะเดา เขามีเกียรติ สงขลา 90170",
    name_th: "สะเดา เขามีเกียรติ สงขลา 90170",
    name_en: "Sadao, Khao Mi Kiat, Songkhla, 90170",
  },
  {
    id: 901101,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "หาดใหญ่",
    subdistrictEn: "Hat Yai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ หาดใหญ่ สงขลา 90110",
    name_th: "หาดใหญ่ หาดใหญ่ สงขลา 90110",
    name_en: "Hat Yai, Hat Yai, Songkhla, 90110",
  },
  {
    id: 901102,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "ควนลัง",
    subdistrictEn: "Khuan Lang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ ควนลัง สงขลา 90110",
    name_th: "หาดใหญ่ ควนลัง สงขลา 90110",
    name_en: "Hat Yai, Khuan Lang, Songkhla, 90110",
  },
  {
    id: 901103,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "คูเต่า",
    subdistrictEn: "Khu Tao",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ คูเต่า สงขลา 90110",
    name_th: "หาดใหญ่ คูเต่า สงขลา 90110",
    name_en: "Hat Yai, Khu Tao, Songkhla, 90110",
  },
  {
    id: 901104,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "คอหงส์",
    subdistrictEn: "Kho Hong",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ คอหงส์ สงขลา 90110",
    name_th: "หาดใหญ่ คอหงส์ สงขลา 90110",
    name_en: "Hat Yai, Kho Hong, Songkhla, 90110",
  },
  {
    id: 901105,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "คลองแห",
    subdistrictEn: "Khlong Hae",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ คลองแห สงขลา 90110",
    name_th: "หาดใหญ่ คลองแห สงขลา 90110",
    name_en: "Hat Yai, Khlong Hae, Songkhla, 90110",
  },
  {
    id: 901107,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "คลองอู่ตะเภา",
    subdistrictEn: "Khlong U Taphao",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ คลองอู่ตะเภา สงขลา 90110",
    name_th: "หาดใหญ่ คลองอู่ตะเภา สงขลา 90110",
    name_en: "Hat Yai, Khlong U Taphao, Songkhla, 90110",
  },
  {
    id: 901108,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "ฉลุง",
    subdistrictEn: "Chalung",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ ฉลุง สงขลา 90110",
    name_th: "หาดใหญ่ ฉลุง สงขลา 90110",
    name_en: "Hat Yai, Chalung, Songkhla, 90110",
  },
  {
    id: 901111,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "ทุ่งใหญ่",
    subdistrictEn: "Thung Yai",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ ทุ่งใหญ่ สงขลา 90110",
    name_th: "หาดใหญ่ ทุ่งใหญ่ สงขลา 90110",
    name_en: "Hat Yai, Thung Yai, Songkhla, 90110",
  },
  {
    id: 901112,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "ทุ่งตำเสา",
    subdistrictEn: "Thung Tamsao",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ ทุ่งตำเสา สงขลา 90110",
    name_th: "หาดใหญ่ ทุ่งตำเสา สงขลา 90110",
    name_en: "Hat Yai, Thung Tamsao, Songkhla, 90110",
  },
  {
    id: 901113,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ ท่าข้าม สงขลา 90110",
    name_th: "หาดใหญ่ ท่าข้าม สงขลา 90110",
    name_en: "Hat Yai, Tha Kham, Songkhla, 90110",
  },
  {
    id: 901114,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "น้ำน้อย",
    subdistrictEn: "Nam Noi",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ น้ำน้อย สงขลา 90110",
    name_th: "หาดใหญ่ น้ำน้อย สงขลา 90110",
    name_en: "Hat Yai, Nam Noi, Songkhla, 90110",
  },
  {
    id: 901116,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "บ้านพรุ",
    subdistrictEn: "Ban Phru",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90250,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ บ้านพรุ สงขลา 90250",
    name_th: "หาดใหญ่ บ้านพรุ สงขลา 90250",
    name_en: "Hat Yai, Ban Phru, Songkhla, 90250",
  },
  {
    id: 901118,
    districtTh: "หาดใหญ่",
    districtEn: "Hat Yai",
    subdistrictTh: "พะตง",
    subdistrictEn: "Phatong",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90230,
    district_code: 9011,
    province_code: 70,
    name: "หาดใหญ่ พะตง สงขลา 90230",
    name_th: "หาดใหญ่ พะตง สงขลา 90230",
    name_en: "Hat Yai, Phatong, Songkhla, 90230",
  },
  {
    id: 901201,
    districtTh: "นาหม่อม",
    districtEn: "Na Mom",
    subdistrictTh: "นาหม่อม",
    subdistrictEn: "Na Mom",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90310,
    district_code: 9012,
    province_code: 70,
    name: "นาหม่อม นาหม่อม สงขลา 90310",
    name_th: "นาหม่อม นาหม่อม สงขลา 90310",
    name_en: "Na Mom, Na Mom, Songkhla, 90310",
  },
  {
    id: 901202,
    districtTh: "นาหม่อม",
    districtEn: "Na Mom",
    subdistrictTh: "พิจิตร",
    subdistrictEn: "Phichit",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90310,
    district_code: 9012,
    province_code: 70,
    name: "นาหม่อม พิจิตร สงขลา 90310",
    name_th: "นาหม่อม พิจิตร สงขลา 90310",
    name_en: "Na Mom, Phichit, Songkhla, 90310",
  },
  {
    id: 901203,
    districtTh: "นาหม่อม",
    districtEn: "Na Mom",
    subdistrictTh: "ทุ่งขมิ้น",
    subdistrictEn: "Thung Khamin",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90310,
    district_code: 9012,
    province_code: 70,
    name: "นาหม่อม ทุ่งขมิ้น สงขลา 90310",
    name_th: "นาหม่อม ทุ่งขมิ้น สงขลา 90310",
    name_en: "Na Mom, Thung Khamin, Songkhla, 90310",
  },
  {
    id: 901204,
    districtTh: "นาหม่อม",
    districtEn: "Na Mom",
    subdistrictTh: "คลองหรัง",
    subdistrictEn: "Khlong Rhang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90310,
    district_code: 9012,
    province_code: 70,
    name: "นาหม่อม คลองหรัง สงขลา 90310",
    name_th: "นาหม่อม คลองหรัง สงขลา 90310",
    name_en: "Na Mom, Khlong Rhang, Songkhla, 90310",
  },
  {
    id: 901301,
    districtTh: "ควนเนียง",
    districtEn: "Khuan Niang",
    subdistrictTh: "รัตภูมิ",
    subdistrictEn: "Rattaphum",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90220,
    district_code: 9013,
    province_code: 70,
    name: "ควนเนียง รัตภูมิ สงขลา 90220",
    name_th: "ควนเนียง รัตภูมิ สงขลา 90220",
    name_en: "Khuan Niang, Rattaphum, Songkhla, 90220",
  },
  {
    id: 901302,
    districtTh: "ควนเนียง",
    districtEn: "Khuan Niang",
    subdistrictTh: "ควนโส",
    subdistrictEn: "Khuan So",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90220,
    district_code: 9013,
    province_code: 70,
    name: "ควนเนียง ควนโส สงขลา 90220",
    name_th: "ควนเนียง ควนโส สงขลา 90220",
    name_en: "Khuan Niang, Khuan So, Songkhla, 90220",
  },
  {
    id: 901303,
    districtTh: "ควนเนียง",
    districtEn: "Khuan Niang",
    subdistrictTh: "ห้วยลึก",
    subdistrictEn: "Huai Luek",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90220,
    district_code: 9013,
    province_code: 70,
    name: "ควนเนียง ห้วยลึก สงขลา 90220",
    name_th: "ควนเนียง ห้วยลึก สงขลา 90220",
    name_en: "Khuan Niang, Huai Luek, Songkhla, 90220",
  },
  {
    id: 901304,
    districtTh: "ควนเนียง",
    districtEn: "Khuan Niang",
    subdistrictTh: "บางเหรียง",
    subdistrictEn: "Bang Rieang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90220,
    district_code: 9013,
    province_code: 70,
    name: "ควนเนียง บางเหรียง สงขลา 90220",
    name_th: "ควนเนียง บางเหรียง สงขลา 90220",
    name_en: "Khuan Niang, Bang Rieang, Songkhla, 90220",
  },
  {
    id: 901401,
    districtTh: "บางกล่ำ",
    districtEn: "Bang Klam",
    subdistrictTh: "บางกล่ำ",
    subdistrictEn: "Bang Klam",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9014,
    province_code: 70,
    name: "บางกล่ำ บางกล่ำ สงขลา 90110",
    name_th: "บางกล่ำ บางกล่ำ สงขลา 90110",
    name_en: "Bang Klam, Bang Klam, Songkhla, 90110",
  },
  {
    id: 901402,
    districtTh: "บางกล่ำ",
    districtEn: "Bang Klam",
    subdistrictTh: "ท่าช้าง",
    subdistrictEn: "Tha Chang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9014,
    province_code: 70,
    name: "บางกล่ำ ท่าช้าง สงขลา 90110",
    name_th: "บางกล่ำ ท่าช้าง สงขลา 90110",
    name_en: "Bang Klam, Tha Chang, Songkhla, 90110",
  },
  {
    id: 901403,
    districtTh: "บางกล่ำ",
    districtEn: "Bang Klam",
    subdistrictTh: "แม่ทอม",
    subdistrictEn: "Mae Thom",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9014,
    province_code: 70,
    name: "บางกล่ำ แม่ทอม สงขลา 90110",
    name_th: "บางกล่ำ แม่ทอม สงขลา 90110",
    name_en: "Bang Klam, Mae Thom, Songkhla, 90110",
  },
  {
    id: 901404,
    districtTh: "บางกล่ำ",
    districtEn: "Bang Klam",
    subdistrictTh: "บ้านหาร",
    subdistrictEn: "Ban Han",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90110,
    district_code: 9014,
    province_code: 70,
    name: "บางกล่ำ บ้านหาร สงขลา 90110",
    name_th: "บางกล่ำ บ้านหาร สงขลา 90110",
    name_en: "Bang Klam, Ban Han, Songkhla, 90110",
  },
  {
    id: 901501,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "ชิงโค",
    subdistrictEn: "Ching Kho",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90280,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร ชิงโค สงขลา 90280",
    name_th: "สิงหนคร ชิงโค สงขลา 90280",
    name_en: "Singhanakhon, Ching Kho, Songkhla, 90280",
  },
  {
    id: 901502,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "สทิงหม้อ",
    subdistrictEn: "Sathing Mo",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90280,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร สทิงหม้อ สงขลา 90280",
    name_th: "สิงหนคร สทิงหม้อ สงขลา 90280",
    name_en: "Singhanakhon, Sathing Mo, Songkhla, 90280",
  },
  {
    id: 901503,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "ทำนบ",
    subdistrictEn: "Thamnop",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90280,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร ทำนบ สงขลา 90280",
    name_th: "สิงหนคร ทำนบ สงขลา 90280",
    name_en: "Singhanakhon, Thamnop, Songkhla, 90280",
  },
  {
    id: 901504,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "รำแดง",
    subdistrictEn: "Ram Daeng",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90330,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร รำแดง สงขลา 90330",
    name_th: "สิงหนคร รำแดง สงขลา 90330",
    name_en: "Singhanakhon, Ram Daeng, Songkhla, 90330",
  },
  {
    id: 901505,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "วัดขนุน",
    subdistrictEn: "Wat Khanun",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90330,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร วัดขนุน สงขลา 90330",
    name_th: "สิงหนคร วัดขนุน สงขลา 90330",
    name_en: "Singhanakhon, Wat Khanun, Songkhla, 90330",
  },
  {
    id: 901506,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "ชะแล้",
    subdistrictEn: "Chalae",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90330,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร ชะแล้ สงขลา 90330",
    name_th: "สิงหนคร ชะแล้ สงขลา 90330",
    name_en: "Singhanakhon, Chalae, Songkhla, 90330",
  },
  {
    id: 901507,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "ปากรอ",
    subdistrictEn: "Pak Ro",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90330,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร ปากรอ สงขลา 90330",
    name_th: "สิงหนคร ปากรอ สงขลา 90330",
    name_en: "Singhanakhon, Pak Ro, Songkhla, 90330",
  },
  {
    id: 901508,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "ป่าขาด",
    subdistrictEn: "Pa Khat",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90330,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร ป่าขาด สงขลา 90330",
    name_th: "สิงหนคร ป่าขาด สงขลา 90330",
    name_en: "Singhanakhon, Pa Khat, Songkhla, 90330",
  },
  {
    id: 901509,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "หัวเขา",
    subdistrictEn: "Hua Khao",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90280,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร หัวเขา สงขลา 90280",
    name_th: "สิงหนคร หัวเขา สงขลา 90280",
    name_en: "Singhanakhon, Hua Khao, Songkhla, 90280",
  },
  {
    id: 901510,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "บางเขียด",
    subdistrictEn: "Bang Khiat",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90330,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร บางเขียด สงขลา 90330",
    name_th: "สิงหนคร บางเขียด สงขลา 90330",
    name_en: "Singhanakhon, Bang Khiat, Songkhla, 90330",
  },
  {
    id: 901511,
    districtTh: "สิงหนคร",
    districtEn: "Singhanakhon",
    subdistrictTh: "ม่วงงาม",
    subdistrictEn: "Muang Ngam",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90330,
    district_code: 9015,
    province_code: 70,
    name: "สิงหนคร ม่วงงาม สงขลา 90330",
    name_th: "สิงหนคร ม่วงงาม สงขลา 90330",
    name_en: "Singhanakhon, Muang Ngam, Songkhla, 90330",
  },
  {
    id: 901601,
    districtTh: "คลองหอยโข่ง",
    districtEn: "Khlong Hoi Khong",
    subdistrictTh: "คลองหอยโข่ง",
    subdistrictEn: "Khlong Hoi Khong",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90230,
    district_code: 9016,
    province_code: 70,
    name: "คลองหอยโข่ง คลองหอยโข่ง สงขลา 90230",
    name_th: "คลองหอยโข่ง คลองหอยโข่ง สงขลา 90230",
    name_en: "Khlong Hoi Khong, Khlong Hoi Khong, Songkhla, 90230",
  },
  {
    id: 901602,
    districtTh: "คลองหอยโข่ง",
    districtEn: "Khlong Hoi Khong",
    subdistrictTh: "ทุ่งลาน",
    subdistrictEn: "Thung Lan",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90230,
    district_code: 9016,
    province_code: 70,
    name: "คลองหอยโข่ง ทุ่งลาน สงขลา 90230",
    name_th: "คลองหอยโข่ง ทุ่งลาน สงขลา 90230",
    name_en: "Khlong Hoi Khong, Thung Lan, Songkhla, 90230",
  },
  {
    id: 901603,
    districtTh: "คลองหอยโข่ง",
    districtEn: "Khlong Hoi Khong",
    subdistrictTh: "โคกม่วง",
    subdistrictEn: "Khok Muang",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90230,
    district_code: 9016,
    province_code: 70,
    name: "คลองหอยโข่ง โคกม่วง สงขลา 90230",
    name_th: "คลองหอยโข่ง โคกม่วง สงขลา 90230",
    name_en: "Khlong Hoi Khong, Khok Muang, Songkhla, 90230",
  },
  {
    id: 901604,
    districtTh: "คลองหอยโข่ง",
    districtEn: "Khlong Hoi Khong",
    subdistrictTh: "คลองหลา",
    subdistrictEn: "Khlong La",
    provinceTh: "สงขลา",
    provinceEn: "Songkhla",
    zipcode: 90115,
    district_code: 9016,
    province_code: 70,
    name: "คลองหอยโข่ง คลองหลา สงขลา 90115",
    name_th: "คลองหอยโข่ง คลองหลา สงขลา 90115",
    name_en: "Khlong Hoi Khong, Khlong La, Songkhla, 90115",
  },
  {
    id: 910101,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "พิมาน",
    subdistrictEn: "Phiman",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91000,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล พิมาน สตูล 91000",
    name_th: "เมืองสตูล พิมาน สตูล 91000",
    name_en: "Mueang Satun, Phiman, Satun, 91000",
  },
  {
    id: 910102,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "คลองขุด",
    subdistrictEn: "Khlong Khut",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91000,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล คลองขุด สตูล 91000",
    name_th: "เมืองสตูล คลองขุด สตูล 91000",
    name_en: "Mueang Satun, Khlong Khut, Satun, 91000",
  },
  {
    id: 910103,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "ควนขัน",
    subdistrictEn: "Khuan Khan",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91000,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล ควนขัน สตูล 91000",
    name_th: "เมืองสตูล ควนขัน สตูล 91000",
    name_en: "Mueang Satun, Khuan Khan, Satun, 91000",
  },
  {
    id: 910104,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "บ้านควน",
    subdistrictEn: "Ban Khuan",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91140,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล บ้านควน สตูล 91140",
    name_th: "เมืองสตูล บ้านควน สตูล 91140",
    name_en: "Mueang Satun, Ban Khuan, Satun, 91140",
  },
  {
    id: 910105,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "ฉลุง",
    subdistrictEn: "Chalung",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91140,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล ฉลุง สตูล 91140",
    name_th: "เมืองสตูล ฉลุง สตูล 91140",
    name_en: "Mueang Satun, Chalung, Satun, 91140",
  },
  {
    id: 910106,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "เกาะสาหร่าย",
    subdistrictEn: "Ko Sarai",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91000,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล เกาะสาหร่าย สตูล 91000",
    name_th: "เมืองสตูล เกาะสาหร่าย สตูล 91000",
    name_en: "Mueang Satun, Ko Sarai, Satun, 91000",
  },
  {
    id: 910107,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "ตันหยงโป",
    subdistrictEn: "Tanyong Po",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91000,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล ตันหยงโป สตูล 91000",
    name_th: "เมืองสตูล ตันหยงโป สตูล 91000",
    name_en: "Mueang Satun, Tanyong Po, Satun, 91000",
  },
  {
    id: 910108,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "เจ๊ะบิลัง",
    subdistrictEn: "Che Bilang",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91000,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล เจ๊ะบิลัง สตูล 91000",
    name_th: "เมืองสตูล เจ๊ะบิลัง สตูล 91000",
    name_en: "Mueang Satun, Che Bilang, Satun, 91000",
  },
  {
    id: 910109,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "ตำมะลัง",
    subdistrictEn: "Tam Malang",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91000,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล ตำมะลัง สตูล 91000",
    name_th: "เมืองสตูล ตำมะลัง สตูล 91000",
    name_en: "Mueang Satun, Tam Malang, Satun, 91000",
  },
  {
    id: 910110,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "ปูยู",
    subdistrictEn: "Puyu",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91000,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล ปูยู สตูล 91000",
    name_th: "เมืองสตูล ปูยู สตูล 91000",
    name_en: "Mueang Satun, Puyu, Satun, 91000",
  },
  {
    id: 910111,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "ควนโพธิ์",
    subdistrictEn: "Khuan Pho",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91140,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล ควนโพธิ์ สตูล 91140",
    name_th: "เมืองสตูล ควนโพธิ์ สตูล 91140",
    name_en: "Mueang Satun, Khuan Pho, Satun, 91140",
  },
  {
    id: 910112,
    districtTh: "เมืองสตูล",
    districtEn: "Mueang Satun",
    subdistrictTh: "เกตรี",
    subdistrictEn: "Ketri",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91140,
    district_code: 9101,
    province_code: 71,
    name: "เมืองสตูล เกตรี สตูล 91140",
    name_th: "เมืองสตูล เกตรี สตูล 91140",
    name_en: "Mueang Satun, Ketri, Satun, 91140",
  },
  {
    id: 910201,
    districtTh: "ควนโดน",
    districtEn: "Khuan Don",
    subdistrictTh: "ควนโดน",
    subdistrictEn: "Khuan Don",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91160,
    district_code: 9102,
    province_code: 71,
    name: "ควนโดน ควนโดน สตูล 91160",
    name_th: "ควนโดน ควนโดน สตูล 91160",
    name_en: "Khuan Don, Khuan Don, Satun, 91160",
  },
  {
    id: 910202,
    districtTh: "ควนโดน",
    districtEn: "Khuan Don",
    subdistrictTh: "ควนสตอ",
    subdistrictEn: "Khuan Sato",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91160,
    district_code: 9102,
    province_code: 71,
    name: "ควนโดน ควนสตอ สตูล 91160",
    name_th: "ควนโดน ควนสตอ สตูล 91160",
    name_en: "Khuan Don, Khuan Sato, Satun, 91160",
  },
  {
    id: 910203,
    districtTh: "ควนโดน",
    districtEn: "Khuan Don",
    subdistrictTh: "ย่านซื่อ",
    subdistrictEn: "Yan Sue",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91160,
    district_code: 9102,
    province_code: 71,
    name: "ควนโดน ย่านซื่อ สตูล 91160",
    name_th: "ควนโดน ย่านซื่อ สตูล 91160",
    name_en: "Khuan Don, Yan Sue, Satun, 91160",
  },
  {
    id: 910204,
    districtTh: "ควนโดน",
    districtEn: "Khuan Don",
    subdistrictTh: "วังประจัน",
    subdistrictEn: "Wang Prachan",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91160,
    district_code: 9102,
    province_code: 71,
    name: "ควนโดน วังประจัน สตูล 91160",
    name_th: "ควนโดน วังประจัน สตูล 91160",
    name_en: "Khuan Don, Wang Prachan, Satun, 91160",
  },
  {
    id: 910301,
    districtTh: "ควนกาหลง",
    districtEn: "Khuan Kalong",
    subdistrictTh: "ทุ่งนุ้ย",
    subdistrictEn: "Thung Nui",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91130,
    district_code: 9103,
    province_code: 71,
    name: "ควนกาหลง ทุ่งนุ้ย สตูล 91130",
    name_th: "ควนกาหลง ทุ่งนุ้ย สตูล 91130",
    name_en: "Khuan Kalong, Thung Nui, Satun, 91130",
  },
  {
    id: 910302,
    districtTh: "ควนกาหลง",
    districtEn: "Khuan Kalong",
    subdistrictTh: "ควนกาหลง",
    subdistrictEn: "Khuan Kalong",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91130,
    district_code: 9103,
    province_code: 71,
    name: "ควนกาหลง ควนกาหลง สตูล 91130",
    name_th: "ควนกาหลง ควนกาหลง สตูล 91130",
    name_en: "Khuan Kalong, Khuan Kalong, Satun, 91130",
  },
  {
    id: 910303,
    districtTh: "ควนกาหลง",
    districtEn: "Khuan Kalong",
    subdistrictTh: "อุใดเจริญ",
    subdistrictEn: "Udai Charoen",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91130,
    district_code: 9103,
    province_code: 71,
    name: "ควนกาหลง อุใดเจริญ สตูล 91130",
    name_th: "ควนกาหลง อุใดเจริญ สตูล 91130",
    name_en: "Khuan Kalong, Udai Charoen, Satun, 91130",
  },
  {
    id: 910401,
    districtTh: "ท่าแพ",
    districtEn: "Tha Phae",
    subdistrictTh: "ท่าแพ",
    subdistrictEn: "Tha Phae",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91150,
    district_code: 9104,
    province_code: 71,
    name: "ท่าแพ ท่าแพ สตูล 91150",
    name_th: "ท่าแพ ท่าแพ สตูล 91150",
    name_en: "Tha Phae, Tha Phae, Satun, 91150",
  },
  {
    id: 910402,
    districtTh: "ท่าแพ",
    districtEn: "Tha Phae",
    subdistrictTh: "แป-ระ",
    subdistrictEn: "Paera",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91150,
    district_code: 9104,
    province_code: 71,
    name: "ท่าแพ แป-ระ สตูล 91150",
    name_th: "ท่าแพ แป-ระ สตูล 91150",
    name_en: "Tha Phae, Paera, Satun, 91150",
  },
  {
    id: 910403,
    districtTh: "ท่าแพ",
    districtEn: "Tha Phae",
    subdistrictTh: "สาคร",
    subdistrictEn: "Sakhon",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91150,
    district_code: 9104,
    province_code: 71,
    name: "ท่าแพ สาคร สตูล 91150",
    name_th: "ท่าแพ สาคร สตูล 91150",
    name_en: "Tha Phae, Sakhon, Satun, 91150",
  },
  {
    id: 910404,
    districtTh: "ท่าแพ",
    districtEn: "Tha Phae",
    subdistrictTh: "ท่าเรือ",
    subdistrictEn: "Tha Rua",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91150,
    district_code: 9104,
    province_code: 71,
    name: "ท่าแพ ท่าเรือ สตูล 91150",
    name_th: "ท่าแพ ท่าเรือ สตูล 91150",
    name_en: "Tha Phae, Tha Rua, Satun, 91150",
  },
  {
    id: 910501,
    districtTh: "ละงู",
    districtEn: "La-ngu",
    subdistrictTh: "กำแพง",
    subdistrictEn: "Kamphaeng",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91110,
    district_code: 9105,
    province_code: 71,
    name: "ละงู กำแพง สตูล 91110",
    name_th: "ละงู กำแพง สตูล 91110",
    name_en: "La-ngu, Kamphaeng, Satun, 91110",
  },
  {
    id: 910502,
    districtTh: "ละงู",
    districtEn: "La-ngu",
    subdistrictTh: "ละงู",
    subdistrictEn: "La-ngu",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91110,
    district_code: 9105,
    province_code: 71,
    name: "ละงู ละงู สตูล 91110",
    name_th: "ละงู ละงู สตูล 91110",
    name_en: "La-ngu, La-ngu, Satun, 91110",
  },
  {
    id: 910503,
    districtTh: "ละงู",
    districtEn: "La-ngu",
    subdistrictTh: "เขาขาว",
    subdistrictEn: "Khao Khao",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91110,
    district_code: 9105,
    province_code: 71,
    name: "ละงู เขาขาว สตูล 91110",
    name_th: "ละงู เขาขาว สตูล 91110",
    name_en: "La-ngu, Khao Khao, Satun, 91110",
  },
  {
    id: 910504,
    districtTh: "ละงู",
    districtEn: "La-ngu",
    subdistrictTh: "ปากน้ำ",
    subdistrictEn: "Pak Nam",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91110,
    district_code: 9105,
    province_code: 71,
    name: "ละงู ปากน้ำ สตูล 91110",
    name_th: "ละงู ปากน้ำ สตูล 91110",
    name_en: "La-ngu, Pak Nam, Satun, 91110",
  },
  {
    id: 910505,
    districtTh: "ละงู",
    districtEn: "La-ngu",
    subdistrictTh: "น้ำผุด",
    subdistrictEn: "Nam Phut",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91110,
    district_code: 9105,
    province_code: 71,
    name: "ละงู น้ำผุด สตูล 91110",
    name_th: "ละงู น้ำผุด สตูล 91110",
    name_en: "La-ngu, Nam Phut, Satun, 91110",
  },
  {
    id: 910506,
    districtTh: "ละงู",
    districtEn: "La-ngu",
    subdistrictTh: "แหลมสน",
    subdistrictEn: "Laem Son",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91110,
    district_code: 9105,
    province_code: 71,
    name: "ละงู แหลมสน สตูล 91110",
    name_th: "ละงู แหลมสน สตูล 91110",
    name_en: "La-ngu, Laem Son, Satun, 91110",
  },
  {
    id: 910601,
    districtTh: "ทุ่งหว้า",
    districtEn: "Thung Wa",
    subdistrictTh: "ทุ่งหว้า",
    subdistrictEn: "Thung Wa",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91120,
    district_code: 9106,
    province_code: 71,
    name: "ทุ่งหว้า ทุ่งหว้า สตูล 91120",
    name_th: "ทุ่งหว้า ทุ่งหว้า สตูล 91120",
    name_en: "Thung Wa, Thung Wa, Satun, 91120",
  },
  {
    id: 910602,
    districtTh: "ทุ่งหว้า",
    districtEn: "Thung Wa",
    subdistrictTh: "นาทอน",
    subdistrictEn: "Na Thon",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91120,
    district_code: 9106,
    province_code: 71,
    name: "ทุ่งหว้า นาทอน สตูล 91120",
    name_th: "ทุ่งหว้า นาทอน สตูล 91120",
    name_en: "Thung Wa, Na Thon, Satun, 91120",
  },
  {
    id: 910603,
    districtTh: "ทุ่งหว้า",
    districtEn: "Thung Wa",
    subdistrictTh: "ขอนคลาน",
    subdistrictEn: "Khon Khlan",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91120,
    district_code: 9106,
    province_code: 71,
    name: "ทุ่งหว้า ขอนคลาน สตูล 91120",
    name_th: "ทุ่งหว้า ขอนคลาน สตูล 91120",
    name_en: "Thung Wa, Khon Khlan, Satun, 91120",
  },
  {
    id: 910604,
    districtTh: "ทุ่งหว้า",
    districtEn: "Thung Wa",
    subdistrictTh: "ทุ่งบุหลัง",
    subdistrictEn: "Thung Bulang",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91120,
    district_code: 9106,
    province_code: 71,
    name: "ทุ่งหว้า ทุ่งบุหลัง สตูล 91120",
    name_th: "ทุ่งหว้า ทุ่งบุหลัง สตูล 91120",
    name_en: "Thung Wa, Thung Bulang, Satun, 91120",
  },
  {
    id: 910605,
    districtTh: "ทุ่งหว้า",
    districtEn: "Thung Wa",
    subdistrictTh: "ป่าแก่บ่อหิน",
    subdistrictEn: "Pa Kae Bo Hin",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91120,
    district_code: 9106,
    province_code: 71,
    name: "ทุ่งหว้า ป่าแก่บ่อหิน สตูล 91120",
    name_th: "ทุ่งหว้า ป่าแก่บ่อหิน สตูล 91120",
    name_en: "Thung Wa, Pa Kae Bo Hin, Satun, 91120",
  },
  {
    id: 910701,
    districtTh: "มะนัง",
    districtEn: "Manang",
    subdistrictTh: "ปาล์มพัฒนา",
    subdistrictEn: "Palm Phatthana",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91130,
    district_code: 9107,
    province_code: 71,
    name: "มะนัง ปาล์มพัฒนา สตูล 91130",
    name_th: "มะนัง ปาล์มพัฒนา สตูล 91130",
    name_en: "Manang, Palm Phatthana, Satun, 91130",
  },
  {
    id: 910702,
    districtTh: "มะนัง",
    districtEn: "Manang",
    subdistrictTh: "นิคมพัฒนา",
    subdistrictEn: "Nikhom Phatthana",
    provinceTh: "สตูล",
    provinceEn: "Satun",
    zipcode: 91130,
    district_code: 9107,
    province_code: 71,
    name: "มะนัง นิคมพัฒนา สตูล 91130",
    name_th: "มะนัง นิคมพัฒนา สตูล 91130",
    name_en: "Manang, Nikhom Phatthana, Satun, 91130",
  },
  {
    id: 920101,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "ทับเที่ยง",
    subdistrictEn: "Thap Thiang",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง ทับเที่ยง ตรัง 92000",
    name_th: "เมืองตรัง ทับเที่ยง ตรัง 92000",
    name_en: "Mueang Trang, Thap Thiang, Trang, 92000",
  },
  {
    id: 920104,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "นาพละ",
    subdistrictEn: "Na Phala",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง นาพละ ตรัง 92000",
    name_th: "เมืองตรัง นาพละ ตรัง 92000",
    name_en: "Mueang Trang, Na Phala, Trang, 92000",
  },
  {
    id: 920105,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "บ้านควน",
    subdistrictEn: "Ban Khuan",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง บ้านควน ตรัง 92000",
    name_th: "เมืองตรัง บ้านควน ตรัง 92000",
    name_en: "Mueang Trang, Ban Khuan, Trang, 92000",
  },
  {
    id: 920106,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "นาบินหลา",
    subdistrictEn: "Na Bin La",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง นาบินหลา ตรัง 92000",
    name_th: "เมืองตรัง นาบินหลา ตรัง 92000",
    name_en: "Mueang Trang, Na Bin La, Trang, 92000",
  },
  {
    id: 920107,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "ควนปริง",
    subdistrictEn: "Khuan Pring",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง ควนปริง ตรัง 92000",
    name_th: "เมืองตรัง ควนปริง ตรัง 92000",
    name_en: "Mueang Trang, Khuan Pring, Trang, 92000",
  },
  {
    id: 920108,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "นาโยงใต้",
    subdistrictEn: "Na Yong Tai",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92170,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง นาโยงใต้ ตรัง 92170",
    name_th: "เมืองตรัง นาโยงใต้ ตรัง 92170",
    name_en: "Mueang Trang, Na Yong Tai, Trang, 92170",
  },
  {
    id: 920109,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "บางรัก",
    subdistrictEn: "Bang Rak",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง บางรัก ตรัง 92000",
    name_th: "เมืองตรัง บางรัก ตรัง 92000",
    name_en: "Mueang Trang, Bang Rak, Trang, 92000",
  },
  {
    id: 920110,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "โคกหล่อ",
    subdistrictEn: "Khok Lo",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง โคกหล่อ ตรัง 92000",
    name_th: "เมืองตรัง โคกหล่อ ตรัง 92000",
    name_en: "Mueang Trang, Khok Lo, Trang, 92000",
  },
  {
    id: 920113,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "นาโต๊ะหมิง",
    subdistrictEn: "Na To Ming",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง นาโต๊ะหมิง ตรัง 92000",
    name_th: "เมืองตรัง นาโต๊ะหมิง ตรัง 92000",
    name_en: "Mueang Trang, Na To Ming, Trang, 92000",
  },
  {
    id: 920114,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "หนองตรุด",
    subdistrictEn: "Nong Trut",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง หนองตรุด ตรัง 92000",
    name_th: "เมืองตรัง หนองตรุด ตรัง 92000",
    name_en: "Mueang Trang, Nong Trut, Trang, 92000",
  },
  {
    id: 920115,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "น้ำผุด",
    subdistrictEn: "Nam Phut",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง น้ำผุด ตรัง 92000",
    name_th: "เมืองตรัง น้ำผุด ตรัง 92000",
    name_en: "Mueang Trang, Nam Phut, Trang, 92000",
  },
  {
    id: 920117,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "นาตาล่วง",
    subdistrictEn: "Na Ta Luang",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง นาตาล่วง ตรัง 92000",
    name_th: "เมืองตรัง นาตาล่วง ตรัง 92000",
    name_en: "Mueang Trang, Na Ta Luang, Trang, 92000",
  },
  {
    id: 920118,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "บ้านโพธิ์",
    subdistrictEn: "Ban Pho",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง บ้านโพธิ์ ตรัง 92000",
    name_th: "เมืองตรัง บ้านโพธิ์ ตรัง 92000",
    name_en: "Mueang Trang, Ban Pho, Trang, 92000",
  },
  {
    id: 920119,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "นาท่ามเหนือ",
    subdistrictEn: "Na Tham Nuea",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92190,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง นาท่ามเหนือ ตรัง 92190",
    name_th: "เมืองตรัง นาท่ามเหนือ ตรัง 92190",
    name_en: "Mueang Trang, Na Tham Nuea, Trang, 92190",
  },
  {
    id: 920120,
    districtTh: "เมืองตรัง",
    districtEn: "Mueang Trang",
    subdistrictTh: "นาท่ามใต้",
    subdistrictEn: "Na Tham Tai",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92190,
    district_code: 9201,
    province_code: 72,
    name: "เมืองตรัง นาท่ามใต้ ตรัง 92190",
    name_th: "เมืองตรัง นาท่ามใต้ ตรัง 92190",
    name_en: "Mueang Trang, Na Tham Tai, Trang, 92190",
  },
  {
    id: 920201,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "กันตัง",
    subdistrictEn: "Kantang",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง กันตัง ตรัง 92110",
    name_th: "กันตัง กันตัง ตรัง 92110",
    name_en: "Kantang, Kantang, Trang, 92110",
  },
  {
    id: 920202,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "ควนธานี",
    subdistrictEn: "Khuan Thani",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง ควนธานี ตรัง 92110",
    name_th: "กันตัง ควนธานี ตรัง 92110",
    name_en: "Kantang, Khuan Thani, Trang, 92110",
  },
  {
    id: 920203,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "บางหมาก",
    subdistrictEn: "Bang Mak",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง บางหมาก ตรัง 92110",
    name_th: "กันตัง บางหมาก ตรัง 92110",
    name_en: "Kantang, Bang Mak, Trang, 92110",
  },
  {
    id: 920204,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "บางเป้า",
    subdistrictEn: "Bang Pao",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง บางเป้า ตรัง 92110",
    name_th: "กันตัง บางเป้า ตรัง 92110",
    name_en: "Kantang, Bang Pao, Trang, 92110",
  },
  {
    id: 920205,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "วังวน",
    subdistrictEn: "Wang Won",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง วังวน ตรัง 92110",
    name_th: "กันตัง วังวน ตรัง 92110",
    name_en: "Kantang, Wang Won, Trang, 92110",
  },
  {
    id: 920206,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "กันตังใต้",
    subdistrictEn: "Kantang Tai",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง กันตังใต้ ตรัง 92110",
    name_th: "กันตัง กันตังใต้ ตรัง 92110",
    name_en: "Kantang, Kantang Tai, Trang, 92110",
  },
  {
    id: 920207,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "โคกยาง",
    subdistrictEn: "Khok Yang",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง โคกยาง ตรัง 92110",
    name_th: "กันตัง โคกยาง ตรัง 92110",
    name_en: "Kantang, Khok Yang, Trang, 92110",
  },
  {
    id: 920208,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "คลองลุ",
    subdistrictEn: "Khlong Lu",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง คลองลุ ตรัง 92110",
    name_th: "กันตัง คลองลุ ตรัง 92110",
    name_en: "Kantang, Khlong Lu, Trang, 92110",
  },
  {
    id: 920209,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "ย่านซื่อ",
    subdistrictEn: "Yan Sue",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง ย่านซื่อ ตรัง 92110",
    name_th: "กันตัง ย่านซื่อ ตรัง 92110",
    name_en: "Kantang, Yan Sue, Trang, 92110",
  },
  {
    id: 920210,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "บ่อน้ำร้อน",
    subdistrictEn: "Bo Nam Ron",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง บ่อน้ำร้อน ตรัง 92110",
    name_th: "กันตัง บ่อน้ำร้อน ตรัง 92110",
    name_en: "Kantang, Bo Nam Ron, Trang, 92110",
  },
  {
    id: 920211,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "บางสัก",
    subdistrictEn: "Bang Sak",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง บางสัก ตรัง 92110",
    name_th: "กันตัง บางสัก ตรัง 92110",
    name_en: "Kantang, Bang Sak, Trang, 92110",
  },
  {
    id: 920212,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "นาเกลือ",
    subdistrictEn: "Na Kluea",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง นาเกลือ ตรัง 92110",
    name_th: "กันตัง นาเกลือ ตรัง 92110",
    name_en: "Kantang, Na Kluea, Trang, 92110",
  },
  {
    id: 920213,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "เกาะลิบง",
    subdistrictEn: "Ko Libong",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง เกาะลิบง ตรัง 92110",
    name_th: "กันตัง เกาะลิบง ตรัง 92110",
    name_en: "Kantang, Ko Libong, Trang, 92110",
  },
  {
    id: 920214,
    districtTh: "กันตัง",
    districtEn: "Kantang",
    subdistrictTh: "คลองชีล้อม",
    subdistrictEn: "Khlong Chi Lom",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92110,
    district_code: 9202,
    province_code: 72,
    name: "กันตัง คลองชีล้อม ตรัง 92110",
    name_th: "กันตัง คลองชีล้อม ตรัง 92110",
    name_en: "Kantang, Khlong Chi Lom, Trang, 92110",
  },
  {
    id: 920301,
    districtTh: "ย่านตาขาว",
    districtEn: "Yan Ta Khao",
    subdistrictTh: "ย่านตาขาว",
    subdistrictEn: "Yan Ta Khao",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9203,
    province_code: 72,
    name: "ย่านตาขาว ย่านตาขาว ตรัง 92140",
    name_th: "ย่านตาขาว ย่านตาขาว ตรัง 92140",
    name_en: "Yan Ta Khao, Yan Ta Khao, Trang, 92140",
  },
  {
    id: 920302,
    districtTh: "ย่านตาขาว",
    districtEn: "Yan Ta Khao",
    subdistrictTh: "หนองบ่อ",
    subdistrictEn: "Nong Bo",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9203,
    province_code: 72,
    name: "ย่านตาขาว หนองบ่อ ตรัง 92140",
    name_th: "ย่านตาขาว หนองบ่อ ตรัง 92140",
    name_en: "Yan Ta Khao, Nong Bo, Trang, 92140",
  },
  {
    id: 920303,
    districtTh: "ย่านตาขาว",
    districtEn: "Yan Ta Khao",
    subdistrictTh: "นาชุมเห็ด",
    subdistrictEn: "Na Chum Het",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9203,
    province_code: 72,
    name: "ย่านตาขาว นาชุมเห็ด ตรัง 92140",
    name_th: "ย่านตาขาว นาชุมเห็ด ตรัง 92140",
    name_en: "Yan Ta Khao, Na Chum Het, Trang, 92140",
  },
  {
    id: 920304,
    districtTh: "ย่านตาขาว",
    districtEn: "Yan Ta Khao",
    subdistrictTh: "ในควน",
    subdistrictEn: "Nai Khuan",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9203,
    province_code: 72,
    name: "ย่านตาขาว ในควน ตรัง 92140",
    name_th: "ย่านตาขาว ในควน ตรัง 92140",
    name_en: "Yan Ta Khao, Nai Khuan, Trang, 92140",
  },
  {
    id: 920305,
    districtTh: "ย่านตาขาว",
    districtEn: "Yan Ta Khao",
    subdistrictTh: "โพรงจระเข้",
    subdistrictEn: "Phrong Chorakhe",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9203,
    province_code: 72,
    name: "ย่านตาขาว โพรงจระเข้ ตรัง 92140",
    name_th: "ย่านตาขาว โพรงจระเข้ ตรัง 92140",
    name_en: "Yan Ta Khao, Phrong Chorakhe, Trang, 92140",
  },
  {
    id: 920306,
    districtTh: "ย่านตาขาว",
    districtEn: "Yan Ta Khao",
    subdistrictTh: "ทุ่งกระบือ",
    subdistrictEn: "Thung Krabue",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9203,
    province_code: 72,
    name: "ย่านตาขาว ทุ่งกระบือ ตรัง 92140",
    name_th: "ย่านตาขาว ทุ่งกระบือ ตรัง 92140",
    name_en: "Yan Ta Khao, Thung Krabue, Trang, 92140",
  },
  {
    id: 920307,
    districtTh: "ย่านตาขาว",
    districtEn: "Yan Ta Khao",
    subdistrictTh: "ทุ่งค่าย",
    subdistrictEn: "Thung Khai",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9203,
    province_code: 72,
    name: "ย่านตาขาว ทุ่งค่าย ตรัง 92140",
    name_th: "ย่านตาขาว ทุ่งค่าย ตรัง 92140",
    name_en: "Yan Ta Khao, Thung Khai, Trang, 92140",
  },
  {
    id: 920308,
    districtTh: "ย่านตาขาว",
    districtEn: "Yan Ta Khao",
    subdistrictTh: "เกาะเปียะ",
    subdistrictEn: "Ko Pia",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9203,
    province_code: 72,
    name: "ย่านตาขาว เกาะเปียะ ตรัง 92140",
    name_th: "ย่านตาขาว เกาะเปียะ ตรัง 92140",
    name_en: "Yan Ta Khao, Ko Pia, Trang, 92140",
  },
  {
    id: 920401,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92120,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน ท่าข้าม ตรัง 92120",
    name_th: "ปะเหลียน ท่าข้าม ตรัง 92120",
    name_en: "Palian, Tha Kham, Trang, 92120",
  },
  {
    id: 920402,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "ทุ่งยาว",
    subdistrictEn: "Thung Yao",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92180,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน ทุ่งยาว ตรัง 92180",
    name_th: "ปะเหลียน ทุ่งยาว ตรัง 92180",
    name_en: "Palian, Thung Yao, Trang, 92180",
  },
  {
    id: 920403,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "ปะเหลียน",
    subdistrictEn: "Palian",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92180,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน ปะเหลียน ตรัง 92180",
    name_th: "ปะเหลียน ปะเหลียน ตรัง 92180",
    name_en: "Palian, Palian, Trang, 92180",
  },
  {
    id: 920404,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "บางด้วน",
    subdistrictEn: "Bang Duan",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน บางด้วน ตรัง 92140",
    name_th: "ปะเหลียน บางด้วน ตรัง 92140",
    name_en: "Palian, Bang Duan, Trang, 92140",
  },
  {
    id: 920407,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน บ้านนา ตรัง 92140",
    name_th: "ปะเหลียน บ้านนา ตรัง 92140",
    name_en: "Palian, Ban Na, Trang, 92140",
  },
  {
    id: 920409,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "สุโสะ",
    subdistrictEn: "Suso",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92120,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน สุโสะ ตรัง 92120",
    name_th: "ปะเหลียน สุโสะ ตรัง 92120",
    name_en: "Palian, Suso, Trang, 92120",
  },
  {
    id: 920410,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "ลิพัง",
    subdistrictEn: "Liphang",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92180,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน ลิพัง ตรัง 92180",
    name_th: "ปะเหลียน ลิพัง ตรัง 92180",
    name_en: "Palian, Liphang, Trang, 92180",
  },
  {
    id: 920411,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "เกาะสุกร",
    subdistrictEn: "Ko Sukon",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92120,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน เกาะสุกร ตรัง 92120",
    name_th: "ปะเหลียน เกาะสุกร ตรัง 92120",
    name_en: "Palian, Ko Sukon, Trang, 92120",
  },
  {
    id: 920412,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "ท่าพญา",
    subdistrictEn: "Tha Phaya",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92140,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน ท่าพญา ตรัง 92140",
    name_th: "ปะเหลียน ท่าพญา ตรัง 92140",
    name_en: "Palian, Tha Phaya, Trang, 92140",
  },
  {
    id: 920413,
    districtTh: "ปะเหลียน",
    districtEn: "Palian",
    subdistrictTh: "แหลมสอม",
    subdistrictEn: "Laem Som",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92180,
    district_code: 9204,
    province_code: 72,
    name: "ปะเหลียน แหลมสอม ตรัง 92180",
    name_th: "ปะเหลียน แหลมสอม ตรัง 92180",
    name_en: "Palian, Laem Som, Trang, 92180",
  },
  {
    id: 920501,
    districtTh: "สิเกา",
    districtEn: "Sikao",
    subdistrictTh: "บ่อหิน",
    subdistrictEn: "Bo Hin",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92150,
    district_code: 9205,
    province_code: 72,
    name: "สิเกา บ่อหิน ตรัง 92150",
    name_th: "สิเกา บ่อหิน ตรัง 92150",
    name_en: "Sikao, Bo Hin, Trang, 92150",
  },
  {
    id: 920502,
    districtTh: "สิเกา",
    districtEn: "Sikao",
    subdistrictTh: "เขาไม้แก้ว",
    subdistrictEn: "Khao Mai Kaeo",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92150,
    district_code: 9205,
    province_code: 72,
    name: "สิเกา เขาไม้แก้ว ตรัง 92150",
    name_th: "สิเกา เขาไม้แก้ว ตรัง 92150",
    name_en: "Sikao, Khao Mai Kaeo, Trang, 92150",
  },
  {
    id: 920503,
    districtTh: "สิเกา",
    districtEn: "Sikao",
    subdistrictTh: "กะลาเส",
    subdistrictEn: "Kalase",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92150,
    district_code: 9205,
    province_code: 72,
    name: "สิเกา กะลาเส ตรัง 92150",
    name_th: "สิเกา กะลาเส ตรัง 92150",
    name_en: "Sikao, Kalase, Trang, 92150",
  },
  {
    id: 920504,
    districtTh: "สิเกา",
    districtEn: "Sikao",
    subdistrictTh: "ไม้ฝาด",
    subdistrictEn: "Mai Fat",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92150,
    district_code: 9205,
    province_code: 72,
    name: "สิเกา ไม้ฝาด ตรัง 92150",
    name_th: "สิเกา ไม้ฝาด ตรัง 92150",
    name_en: "Sikao, Mai Fat, Trang, 92150",
  },
  {
    id: 920505,
    districtTh: "สิเกา",
    districtEn: "Sikao",
    subdistrictTh: "นาเมืองเพชร",
    subdistrictEn: "Na Mueang Phet",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9205,
    province_code: 72,
    name: "สิเกา นาเมืองเพชร ตรัง 92000",
    name_th: "สิเกา นาเมืองเพชร ตรัง 92000",
    name_en: "Sikao, Na Mueang Phet, Trang, 92000",
  },
  {
    id: 920601,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "ห้วยยอด",
    subdistrictEn: "Huai Yot",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด ห้วยยอด ตรัง 92130",
    name_th: "ห้วยยอด ห้วยยอด ตรัง 92130",
    name_en: "Huai Yot, Huai Yot, Trang, 92130",
  },
  {
    id: 920602,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "หนองช้างแล่น",
    subdistrictEn: "Nong Chang Laen",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด หนองช้างแล่น ตรัง 92130",
    name_th: "ห้วยยอด หนองช้างแล่น ตรัง 92130",
    name_en: "Huai Yot, Nong Chang Laen, Trang, 92130",
  },
  {
    id: 920605,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "บางดี",
    subdistrictEn: "Bang Di",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92210,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด บางดี ตรัง 92210",
    name_th: "ห้วยยอด บางดี ตรัง 92210",
    name_en: "Huai Yot, Bang Di, Trang, 92210",
  },
  {
    id: 920606,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "บางกุ้ง",
    subdistrictEn: "Bang Kung",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92210,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด บางกุ้ง ตรัง 92210",
    name_th: "ห้วยยอด บางกุ้ง ตรัง 92210",
    name_en: "Huai Yot, Bang Kung, Trang, 92210",
  },
  {
    id: 920607,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "เขากอบ",
    subdistrictEn: "Khao Kop",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด เขากอบ ตรัง 92130",
    name_th: "ห้วยยอด เขากอบ ตรัง 92130",
    name_en: "Huai Yot, Khao Kop, Trang, 92130",
  },
  {
    id: 920608,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "เขาขาว",
    subdistrictEn: "Khao Khao",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด เขาขาว ตรัง 92130",
    name_th: "ห้วยยอด เขาขาว ตรัง 92130",
    name_en: "Huai Yot, Khao Khao, Trang, 92130",
  },
  {
    id: 920609,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "เขาปูน",
    subdistrictEn: "Khao Pun",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด เขาปูน ตรัง 92130",
    name_th: "ห้วยยอด เขาปูน ตรัง 92130",
    name_en: "Huai Yot, Khao Pun, Trang, 92130",
  },
  {
    id: 920610,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "ปากแจ่ม",
    subdistrictEn: "Pak Chaem",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92190,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด ปากแจ่ม ตรัง 92190",
    name_th: "ห้วยยอด ปากแจ่ม ตรัง 92190",
    name_en: "Huai Yot, Pak Chaem, Trang, 92190",
  },
  {
    id: 920611,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "ปากคม",
    subdistrictEn: "Pak Khom",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด ปากคม ตรัง 92130",
    name_th: "ห้วยยอด ปากคม ตรัง 92130",
    name_en: "Huai Yot, Pak Khom, Trang, 92130",
  },
  {
    id: 920614,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "ท่างิ้ว",
    subdistrictEn: "Tha Ngio",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด ท่างิ้ว ตรัง 92130",
    name_th: "ห้วยยอด ท่างิ้ว ตรัง 92130",
    name_en: "Huai Yot, Tha Ngio, Trang, 92130",
  },
  {
    id: 920615,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "ลำภูรา",
    subdistrictEn: "Lamphu Ra",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92190,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด ลำภูรา ตรัง 92190",
    name_th: "ห้วยยอด ลำภูรา ตรัง 92190",
    name_en: "Huai Yot, Lamphu Ra, Trang, 92190",
  },
  {
    id: 920616,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "นาวง",
    subdistrictEn: "Na Wong",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92210,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด นาวง ตรัง 92210",
    name_th: "ห้วยยอด นาวง ตรัง 92210",
    name_en: "Huai Yot, Na Wong, Trang, 92210",
  },
  {
    id: 920617,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "ห้วยนาง",
    subdistrictEn: "Huai Nang",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด ห้วยนาง ตรัง 92130",
    name_th: "ห้วยยอด ห้วยนาง ตรัง 92130",
    name_en: "Huai Yot, Huai Nang, Trang, 92130",
  },
  {
    id: 920619,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "ในเตา",
    subdistrictEn: "Nai Tao",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด ในเตา ตรัง 92130",
    name_th: "ห้วยยอด ในเตา ตรัง 92130",
    name_en: "Huai Yot, Nai Tao, Trang, 92130",
  },
  {
    id: 920620,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "ทุ่งต่อ",
    subdistrictEn: "Thung To",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด ทุ่งต่อ ตรัง 92130",
    name_th: "ห้วยยอด ทุ่งต่อ ตรัง 92130",
    name_en: "Huai Yot, Thung To, Trang, 92130",
  },
  {
    id: 920621,
    districtTh: "ห้วยยอด",
    districtEn: "Huai Yot",
    subdistrictTh: "วังคีรี",
    subdistrictEn: "Wang Khiri",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92210,
    district_code: 9206,
    province_code: 72,
    name: "ห้วยยอด วังคีรี ตรัง 92210",
    name_th: "ห้วยยอด วังคีรี ตรัง 92210",
    name_en: "Huai Yot, Wang Khiri, Trang, 92210",
  },
  {
    id: 920701,
    districtTh: "วังวิเศษ",
    districtEn: "Wang Wiset",
    subdistrictTh: "เขาวิเศษ",
    subdistrictEn: "Khao Wiset",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92220,
    district_code: 9207,
    province_code: 72,
    name: "วังวิเศษ เขาวิเศษ ตรัง 92220",
    name_th: "วังวิเศษ เขาวิเศษ ตรัง 92220",
    name_en: "Wang Wiset, Khao Wiset, Trang, 92220",
  },
  {
    id: 920702,
    districtTh: "วังวิเศษ",
    districtEn: "Wang Wiset",
    subdistrictTh: "วังมะปราง",
    subdistrictEn: "Wang Maprang",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92220,
    district_code: 9207,
    province_code: 72,
    name: "วังวิเศษ วังมะปราง ตรัง 92220",
    name_th: "วังวิเศษ วังมะปราง ตรัง 92220",
    name_en: "Wang Wiset, Wang Maprang, Trang, 92220",
  },
  {
    id: 920703,
    districtTh: "วังวิเศษ",
    districtEn: "Wang Wiset",
    subdistrictTh: "อ่าวตง",
    subdistrictEn: "Ao Tong",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92220,
    district_code: 9207,
    province_code: 72,
    name: "วังวิเศษ อ่าวตง ตรัง 92220",
    name_th: "วังวิเศษ อ่าวตง ตรัง 92220",
    name_en: "Wang Wiset, Ao Tong, Trang, 92220",
  },
  {
    id: 920704,
    districtTh: "วังวิเศษ",
    districtEn: "Wang Wiset",
    subdistrictTh: "ท่าสะบ้า",
    subdistrictEn: "Tha Saba",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92000,
    district_code: 9207,
    province_code: 72,
    name: "วังวิเศษ ท่าสะบ้า ตรัง 92000",
    name_th: "วังวิเศษ ท่าสะบ้า ตรัง 92000",
    name_en: "Wang Wiset, Tha Saba, Trang, 92000",
  },
  {
    id: 920705,
    districtTh: "วังวิเศษ",
    districtEn: "Wang Wiset",
    subdistrictTh: "วังมะปรางเหนือ",
    subdistrictEn: "Wang Maprang Nuea",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92220,
    district_code: 9207,
    province_code: 72,
    name: "วังวิเศษ วังมะปรางเหนือ ตรัง 92220",
    name_th: "วังวิเศษ วังมะปรางเหนือ ตรัง 92220",
    name_en: "Wang Wiset, Wang Maprang Nuea, Trang, 92220",
  },
  {
    id: 920801,
    districtTh: "นาโยง",
    districtEn: "Na Yong",
    subdistrictTh: "นาโยงเหนือ",
    subdistrictEn: "Na Yong Nuea",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92170,
    district_code: 9208,
    province_code: 72,
    name: "นาโยง นาโยงเหนือ ตรัง 92170",
    name_th: "นาโยง นาโยงเหนือ ตรัง 92170",
    name_en: "Na Yong, Na Yong Nuea, Trang, 92170",
  },
  {
    id: 920802,
    districtTh: "นาโยง",
    districtEn: "Na Yong",
    subdistrictTh: "ช่อง",
    subdistrictEn: "Chong",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92170,
    district_code: 9208,
    province_code: 72,
    name: "นาโยง ช่อง ตรัง 92170",
    name_th: "นาโยง ช่อง ตรัง 92170",
    name_en: "Na Yong, Chong, Trang, 92170",
  },
  {
    id: 920803,
    districtTh: "นาโยง",
    districtEn: "Na Yong",
    subdistrictTh: "ละมอ",
    subdistrictEn: "Lamo",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92170,
    district_code: 9208,
    province_code: 72,
    name: "นาโยง ละมอ ตรัง 92170",
    name_th: "นาโยง ละมอ ตรัง 92170",
    name_en: "Na Yong, Lamo, Trang, 92170",
  },
  {
    id: 920804,
    districtTh: "นาโยง",
    districtEn: "Na Yong",
    subdistrictTh: "โคกสะบ้า",
    subdistrictEn: "Khok Saba",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92170,
    district_code: 9208,
    province_code: 72,
    name: "นาโยง โคกสะบ้า ตรัง 92170",
    name_th: "นาโยง โคกสะบ้า ตรัง 92170",
    name_en: "Na Yong, Khok Saba, Trang, 92170",
  },
  {
    id: 920805,
    districtTh: "นาโยง",
    districtEn: "Na Yong",
    subdistrictTh: "นาหมื่นศรี",
    subdistrictEn: "Na Muen Si",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92170,
    district_code: 9208,
    province_code: 72,
    name: "นาโยง นาหมื่นศรี ตรัง 92170",
    name_th: "นาโยง นาหมื่นศรี ตรัง 92170",
    name_en: "Na Yong, Na Muen Si, Trang, 92170",
  },
  {
    id: 920806,
    districtTh: "นาโยง",
    districtEn: "Na Yong",
    subdistrictTh: "นาข้าวเสีย",
    subdistrictEn: "Na Khao Sia",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92170,
    district_code: 9208,
    province_code: 72,
    name: "นาโยง นาข้าวเสีย ตรัง 92170",
    name_th: "นาโยง นาข้าวเสีย ตรัง 92170",
    name_en: "Na Yong, Na Khao Sia, Trang, 92170",
  },
  {
    id: 920901,
    districtTh: "รัษฎา",
    districtEn: "Ratsada",
    subdistrictTh: "ควนเมา",
    subdistrictEn: "Khuan Mao",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92160,
    district_code: 9209,
    province_code: 72,
    name: "รัษฎา ควนเมา ตรัง 92160",
    name_th: "รัษฎา ควนเมา ตรัง 92160",
    name_en: "Ratsada, Khuan Mao, Trang, 92160",
  },
  {
    id: 920902,
    districtTh: "รัษฎา",
    districtEn: "Ratsada",
    subdistrictTh: "คลองปาง",
    subdistrictEn: "Khlong Pang",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92160,
    district_code: 9209,
    province_code: 72,
    name: "รัษฎา คลองปาง ตรัง 92160",
    name_th: "รัษฎา คลองปาง ตรัง 92160",
    name_en: "Ratsada, Khlong Pang, Trang, 92160",
  },
  {
    id: 920903,
    districtTh: "รัษฎา",
    districtEn: "Ratsada",
    subdistrictTh: "หนองบัว",
    subdistrictEn: "Nong Bua",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92160,
    district_code: 9209,
    province_code: 72,
    name: "รัษฎา หนองบัว ตรัง 92160",
    name_th: "รัษฎา หนองบัว ตรัง 92160",
    name_en: "Ratsada, Nong Bua, Trang, 92160",
  },
  {
    id: 920904,
    districtTh: "รัษฎา",
    districtEn: "Ratsada",
    subdistrictTh: "หนองปรือ",
    subdistrictEn: "Nong Prue",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92130,
    district_code: 9209,
    province_code: 72,
    name: "รัษฎา หนองปรือ ตรัง 92130",
    name_th: "รัษฎา หนองปรือ ตรัง 92130",
    name_en: "Ratsada, Nong Prue, Trang, 92130",
  },
  {
    id: 920905,
    districtTh: "รัษฎา",
    districtEn: "Ratsada",
    subdistrictTh: "เขาไพร",
    subdistrictEn: "Khao Phrai",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92160,
    district_code: 9209,
    province_code: 72,
    name: "รัษฎา เขาไพร ตรัง 92160",
    name_th: "รัษฎา เขาไพร ตรัง 92160",
    name_en: "Ratsada, Khao Phrai, Trang, 92160",
  },
  {
    id: 921001,
    districtTh: "หาดสำราญ",
    districtEn: "Hat Samran",
    subdistrictTh: "หาดสำราญ",
    subdistrictEn: "Hat Samran",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92120,
    district_code: 9210,
    province_code: 72,
    name: "หาดสำราญ หาดสำราญ ตรัง 92120",
    name_th: "หาดสำราญ หาดสำราญ ตรัง 92120",
    name_en: "Hat Samran, Hat Samran, Trang, 92120",
  },
  {
    id: 921002,
    districtTh: "หาดสำราญ",
    districtEn: "Hat Samran",
    subdistrictTh: "บ้าหวี",
    subdistrictEn: "Ba Wi",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92120,
    district_code: 9210,
    province_code: 72,
    name: "หาดสำราญ บ้าหวี ตรัง 92120",
    name_th: "หาดสำราญ บ้าหวี ตรัง 92120",
    name_en: "Hat Samran, Ba Wi, Trang, 92120",
  },
  {
    id: 921003,
    districtTh: "หาดสำราญ",
    districtEn: "Hat Samran",
    subdistrictTh: "ตะเสะ",
    subdistrictEn: "Ta Se",
    provinceTh: "ตรัง",
    provinceEn: "Trang",
    zipcode: 92120,
    district_code: 9210,
    province_code: 72,
    name: "หาดสำราญ ตะเสะ ตรัง 92120",
    name_th: "หาดสำราญ ตะเสะ ตรัง 92120",
    name_en: "Hat Samran, Ta Se, Trang, 92120",
  },
  {
    id: 930101,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "คูหาสวรรค์",
    subdistrictEn: "Khuha Sawan",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง คูหาสวรรค์ พัทลุง 93000",
    name_th: "เมืองพัทลุง คูหาสวรรค์ พัทลุง 93000",
    name_en: "Mueang Phatthalung, Khuha Sawan, Phatthalung, 93000",
  },
  {
    id: 930103,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "เขาเจียก",
    subdistrictEn: "Khao Chiak",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง เขาเจียก พัทลุง 93000",
    name_th: "เมืองพัทลุง เขาเจียก พัทลุง 93000",
    name_en: "Mueang Phatthalung, Khao Chiak, Phatthalung, 93000",
  },
  {
    id: 930104,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "ท่ามิหรำ",
    subdistrictEn: "Tha Miram",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง ท่ามิหรำ พัทลุง 93000",
    name_th: "เมืองพัทลุง ท่ามิหรำ พัทลุง 93000",
    name_en: "Mueang Phatthalung, Tha Miram, Phatthalung, 93000",
  },
  {
    id: 930105,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "โคกชะงาย",
    subdistrictEn: "Khok Cha-ngai",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง โคกชะงาย พัทลุง 93000",
    name_th: "เมืองพัทลุง โคกชะงาย พัทลุง 93000",
    name_en: "Mueang Phatthalung, Khok Cha-ngai, Phatthalung, 93000",
  },
  {
    id: 930106,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "นาท่อม",
    subdistrictEn: "Na Thom",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง นาท่อม พัทลุง 93000",
    name_th: "เมืองพัทลุง นาท่อม พัทลุง 93000",
    name_en: "Mueang Phatthalung, Na Thom, Phatthalung, 93000",
  },
  {
    id: 930107,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "ปรางหมู่",
    subdistrictEn: "Prang Mu",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง ปรางหมู่ พัทลุง 93000",
    name_th: "เมืองพัทลุง ปรางหมู่ พัทลุง 93000",
    name_en: "Mueang Phatthalung, Prang Mu, Phatthalung, 93000",
  },
  {
    id: 930108,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "ท่าแค",
    subdistrictEn: "Tha Khae",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง ท่าแค พัทลุง 93000",
    name_th: "เมืองพัทลุง ท่าแค พัทลุง 93000",
    name_en: "Mueang Phatthalung, Tha Khae, Phatthalung, 93000",
  },
  {
    id: 930109,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "ลำปำ",
    subdistrictEn: "Lampam",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง ลำปำ พัทลุง 93000",
    name_th: "เมืองพัทลุง ลำปำ พัทลุง 93000",
    name_en: "Mueang Phatthalung, Lampam, Phatthalung, 93000",
  },
  {
    id: 930110,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "ตำนาน",
    subdistrictEn: "Tamnan",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง ตำนาน พัทลุง 93000",
    name_th: "เมืองพัทลุง ตำนาน พัทลุง 93000",
    name_en: "Mueang Phatthalung, Tamnan, Phatthalung, 93000",
  },
  {
    id: 930111,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "ควนมะพร้าว",
    subdistrictEn: "Khuan Maphrao",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง ควนมะพร้าว พัทลุง 93000",
    name_th: "เมืองพัทลุง ควนมะพร้าว พัทลุง 93000",
    name_en: "Mueang Phatthalung, Khuan Maphrao, Phatthalung, 93000",
  },
  {
    id: 930112,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "ร่มเมือง",
    subdistrictEn: "Rom Mueang",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง ร่มเมือง พัทลุง 93000",
    name_th: "เมืองพัทลุง ร่มเมือง พัทลุง 93000",
    name_en: "Mueang Phatthalung, Rom Mueang, Phatthalung, 93000",
  },
  {
    id: 930113,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "ชัยบุรี",
    subdistrictEn: "Chai Buri",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง ชัยบุรี พัทลุง 93000",
    name_th: "เมืองพัทลุง ชัยบุรี พัทลุง 93000",
    name_en: "Mueang Phatthalung, Chai Buri, Phatthalung, 93000",
  },
  {
    id: 930115,
    districtTh: "เมืองพัทลุง",
    districtEn: "Mueang Phatthalung",
    subdistrictTh: "พญาขัน",
    subdistrictEn: "Phaya Khan",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9301,
    province_code: 73,
    name: "เมืองพัทลุง พญาขัน พัทลุง 93000",
    name_th: "เมืองพัทลุง พญาขัน พัทลุง 93000",
    name_en: "Mueang Phatthalung, Phaya Khan, Phatthalung, 93000",
  },
  {
    id: 930201,
    districtTh: "กงหรา",
    districtEn: "Kong Ra",
    subdistrictTh: "กงหรา",
    subdistrictEn: "Kong Ra",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93180,
    district_code: 9302,
    province_code: 73,
    name: "กงหรา กงหรา พัทลุง 93180",
    name_th: "กงหรา กงหรา พัทลุง 93180",
    name_en: "Kong Ra, Kong Ra, Phatthalung, 93180",
  },
  {
    id: 930202,
    districtTh: "กงหรา",
    districtEn: "Kong Ra",
    subdistrictTh: "ชะรัด",
    subdistrictEn: "Charat",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9302,
    province_code: 73,
    name: "กงหรา ชะรัด พัทลุง 93000",
    name_th: "กงหรา ชะรัด พัทลุง 93000",
    name_en: "Kong Ra, Charat, Phatthalung, 93000",
  },
  {
    id: 930203,
    districtTh: "กงหรา",
    districtEn: "Kong Ra",
    subdistrictTh: "คลองเฉลิม",
    subdistrictEn: "Khlong Chaloem",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93180,
    district_code: 9302,
    province_code: 73,
    name: "กงหรา คลองเฉลิม พัทลุง 93180",
    name_th: "กงหรา คลองเฉลิม พัทลุง 93180",
    name_en: "Kong Ra, Khlong Chaloem, Phatthalung, 93180",
  },
  {
    id: 930204,
    districtTh: "กงหรา",
    districtEn: "Kong Ra",
    subdistrictTh: "คลองทรายขาว",
    subdistrictEn: "Khlong Sai Khao",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93180,
    district_code: 9302,
    province_code: 73,
    name: "กงหรา คลองทรายขาว พัทลุง 93180",
    name_th: "กงหรา คลองทรายขาว พัทลุง 93180",
    name_en: "Kong Ra, Khlong Sai Khao, Phatthalung, 93180",
  },
  {
    id: 930205,
    districtTh: "กงหรา",
    districtEn: "Kong Ra",
    subdistrictTh: "สมหวัง",
    subdistrictEn: "Som Wang",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9302,
    province_code: 73,
    name: "กงหรา สมหวัง พัทลุง 93000",
    name_th: "กงหรา สมหวัง พัทลุง 93000",
    name_en: "Kong Ra, Som Wang, Phatthalung, 93000",
  },
  {
    id: 930301,
    districtTh: "เขาชัยสน",
    districtEn: "Khao Chaison",
    subdistrictTh: "เขาชัยสน",
    subdistrictEn: "Khao Chaison",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93130,
    district_code: 9303,
    province_code: 73,
    name: "เขาชัยสน เขาชัยสน พัทลุง 93130",
    name_th: "เขาชัยสน เขาชัยสน พัทลุง 93130",
    name_en: "Khao Chaison, Khao Chaison, Phatthalung, 93130",
  },
  {
    id: 930302,
    districtTh: "เขาชัยสน",
    districtEn: "Khao Chaison",
    subdistrictTh: "ควนขนุน",
    subdistrictEn: "Khuan Khanun",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93130,
    district_code: 9303,
    province_code: 73,
    name: "เขาชัยสน ควนขนุน พัทลุง 93130",
    name_th: "เขาชัยสน ควนขนุน พัทลุง 93130",
    name_en: "Khao Chaison, Khuan Khanun, Phatthalung, 93130",
  },
  {
    id: 930305,
    districtTh: "เขาชัยสน",
    districtEn: "Khao Chaison",
    subdistrictTh: "จองถนน",
    subdistrictEn: "Chong Thanon",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93130,
    district_code: 9303,
    province_code: 73,
    name: "เขาชัยสน จองถนน พัทลุง 93130",
    name_th: "เขาชัยสน จองถนน พัทลุง 93130",
    name_en: "Khao Chaison, Chong Thanon, Phatthalung, 93130",
  },
  {
    id: 930306,
    districtTh: "เขาชัยสน",
    districtEn: "Khao Chaison",
    subdistrictTh: "หานโพธิ์",
    subdistrictEn: "Han Pho",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93130,
    district_code: 9303,
    province_code: 73,
    name: "เขาชัยสน หานโพธิ์ พัทลุง 93130",
    name_th: "เขาชัยสน หานโพธิ์ พัทลุง 93130",
    name_en: "Khao Chaison, Han Pho, Phatthalung, 93130",
  },
  {
    id: 930307,
    districtTh: "เขาชัยสน",
    districtEn: "Khao Chaison",
    subdistrictTh: "โคกม่วง",
    subdistrictEn: "Khok Muang",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93130,
    district_code: 9303,
    province_code: 73,
    name: "เขาชัยสน โคกม่วง พัทลุง 93130",
    name_th: "เขาชัยสน โคกม่วง พัทลุง 93130",
    name_en: "Khao Chaison, Khok Muang, Phatthalung, 93130",
  },
  {
    id: 930401,
    districtTh: "ตะโหมด",
    districtEn: "Tamot",
    subdistrictTh: "แม่ขรี",
    subdistrictEn: "Mae Khari",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93160,
    district_code: 9304,
    province_code: 73,
    name: "ตะโหมด แม่ขรี พัทลุง 93160",
    name_th: "ตะโหมด แม่ขรี พัทลุง 93160",
    name_en: "Tamot, Mae Khari, Phatthalung, 93160",
  },
  {
    id: 930402,
    districtTh: "ตะโหมด",
    districtEn: "Tamot",
    subdistrictTh: "ตะโหมด",
    subdistrictEn: "Tamod",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93160,
    district_code: 9304,
    province_code: 73,
    name: "ตะโหมด ตะโหมด พัทลุง 93160",
    name_th: "ตะโหมด ตะโหมด พัทลุง 93160",
    name_en: "Tamot, Tamod, Phatthalung, 93160",
  },
  {
    id: 930403,
    districtTh: "ตะโหมด",
    districtEn: "Tamot",
    subdistrictTh: "คลองใหญ่",
    subdistrictEn: "Khlong Yai",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93160,
    district_code: 9304,
    province_code: 73,
    name: "ตะโหมด คลองใหญ่ พัทลุง 93160",
    name_th: "ตะโหมด คลองใหญ่ พัทลุง 93160",
    name_en: "Tamot, Khlong Yai, Phatthalung, 93160",
  },
  {
    id: 930501,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "ควนขนุน",
    subdistrictEn: "Khuan Khanun",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน ควนขนุน พัทลุง 93110",
    name_th: "ควนขนุน ควนขนุน พัทลุง 93110",
    name_en: "Khuan Khanun, Khuan Khanun, Phatthalung, 93110",
  },
  {
    id: 930502,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "ทะเลน้อย",
    subdistrictEn: "Thale Noi",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93150,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน ทะเลน้อย พัทลุง 93150",
    name_th: "ควนขนุน ทะเลน้อย พัทลุง 93150",
    name_en: "Khuan Khanun, Thale Noi, Phatthalung, 93150",
  },
  {
    id: 930504,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "นาขยาด",
    subdistrictEn: "Na Khayat",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน นาขยาด พัทลุง 93110",
    name_th: "ควนขนุน นาขยาด พัทลุง 93110",
    name_en: "Khuan Khanun, Na Khayat, Phatthalung, 93110",
  },
  {
    id: 930505,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "พนมวังก์",
    subdistrictEn: "Phanom Wang",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน พนมวังก์ พัทลุง 93110",
    name_th: "ควนขนุน พนมวังก์ พัทลุง 93110",
    name_en: "Khuan Khanun, Phanom Wang, Phatthalung, 93110",
  },
  {
    id: 930506,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "แหลมโตนด",
    subdistrictEn: "Laem Tanot",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน แหลมโตนด พัทลุง 93110",
    name_th: "ควนขนุน แหลมโตนด พัทลุง 93110",
    name_en: "Khuan Khanun, Laem Tanot, Phatthalung, 93110",
  },
  {
    id: 930508,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "ปันแต",
    subdistrictEn: "Pan Tae",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน ปันแต พัทลุง 93110",
    name_th: "ควนขนุน ปันแต พัทลุง 93110",
    name_en: "Khuan Khanun, Pan Tae, Phatthalung, 93110",
  },
  {
    id: 930509,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "โตนดด้วน",
    subdistrictEn: "Tanot Duan",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน โตนดด้วน พัทลุง 93110",
    name_th: "ควนขนุน โตนดด้วน พัทลุง 93110",
    name_en: "Khuan Khanun, Tanot Duan, Phatthalung, 93110",
  },
  {
    id: 930510,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "ดอนทราย",
    subdistrictEn: "Don Sai",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน ดอนทราย พัทลุง 93110",
    name_th: "ควนขนุน ดอนทราย พัทลุง 93110",
    name_en: "Khuan Khanun, Don Sai, Phatthalung, 93110",
  },
  {
    id: 930511,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "มะกอกเหนือ",
    subdistrictEn: "Makok Nuea",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93150,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน มะกอกเหนือ พัทลุง 93150",
    name_th: "ควนขนุน มะกอกเหนือ พัทลุง 93150",
    name_en: "Khuan Khanun, Makok Nuea, Phatthalung, 93150",
  },
  {
    id: 930512,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "พนางตุง",
    subdistrictEn: "Phanang Tung",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93150,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน พนางตุง พัทลุง 93150",
    name_th: "ควนขนุน พนางตุง พัทลุง 93150",
    name_en: "Khuan Khanun, Phanang Tung, Phatthalung, 93150",
  },
  {
    id: 930513,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "ชะมวง",
    subdistrictEn: "Chamuang",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน ชะมวง พัทลุง 93110",
    name_th: "ควนขนุน ชะมวง พัทลุง 93110",
    name_en: "Khuan Khanun, Chamuang, Phatthalung, 93110",
  },
  {
    id: 930516,
    districtTh: "ควนขนุน",
    districtEn: "Khuan Khanun",
    subdistrictTh: "แพรกหา",
    subdistrictEn: "Phraek Ha",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9305,
    province_code: 73,
    name: "ควนขนุน แพรกหา พัทลุง 93110",
    name_th: "ควนขนุน แพรกหา พัทลุง 93110",
    name_en: "Khuan Khanun, Phraek Ha, Phatthalung, 93110",
  },
  {
    id: 930601,
    districtTh: "ปากพะยูน",
    districtEn: "Pak Phayun",
    subdistrictTh: "ปากพะยูน",
    subdistrictEn: "Pak Phayun",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93120,
    district_code: 9306,
    province_code: 73,
    name: "ปากพะยูน ปากพะยูน พัทลุง 93120",
    name_th: "ปากพะยูน ปากพะยูน พัทลุง 93120",
    name_en: "Pak Phayun, Pak Phayun, Phatthalung, 93120",
  },
  {
    id: 930602,
    districtTh: "ปากพะยูน",
    districtEn: "Pak Phayun",
    subdistrictTh: "ดอนประดู่",
    subdistrictEn: "Don Pradu",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93120,
    district_code: 9306,
    province_code: 73,
    name: "ปากพะยูน ดอนประดู่ พัทลุง 93120",
    name_th: "ปากพะยูน ดอนประดู่ พัทลุง 93120",
    name_en: "Pak Phayun, Don Pradu, Phatthalung, 93120",
  },
  {
    id: 930603,
    districtTh: "ปากพะยูน",
    districtEn: "Pak Phayun",
    subdistrictTh: "เกาะนางคำ",
    subdistrictEn: "Ko Nang Kham",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93120,
    district_code: 9306,
    province_code: 73,
    name: "ปากพะยูน เกาะนางคำ พัทลุง 93120",
    name_th: "ปากพะยูน เกาะนางคำ พัทลุง 93120",
    name_en: "Pak Phayun, Ko Nang Kham, Phatthalung, 93120",
  },
  {
    id: 930604,
    districtTh: "ปากพะยูน",
    districtEn: "Pak Phayun",
    subdistrictTh: "เกาะหมาก",
    subdistrictEn: "Ko Mak",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93120,
    district_code: 9306,
    province_code: 73,
    name: "ปากพะยูน เกาะหมาก พัทลุง 93120",
    name_th: "ปากพะยูน เกาะหมาก พัทลุง 93120",
    name_en: "Pak Phayun, Ko Mak, Phatthalung, 93120",
  },
  {
    id: 930605,
    districtTh: "ปากพะยูน",
    districtEn: "Pak Phayun",
    subdistrictTh: "ฝาละมี",
    subdistrictEn: "Falami",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93120,
    district_code: 9306,
    province_code: 73,
    name: "ปากพะยูน ฝาละมี พัทลุง 93120",
    name_th: "ปากพะยูน ฝาละมี พัทลุง 93120",
    name_en: "Pak Phayun, Falami, Phatthalung, 93120",
  },
  {
    id: 930606,
    districtTh: "ปากพะยูน",
    districtEn: "Pak Phayun",
    subdistrictTh: "หารเทา",
    subdistrictEn: "Han Thao",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93120,
    district_code: 9306,
    province_code: 73,
    name: "ปากพะยูน หารเทา พัทลุง 93120",
    name_th: "ปากพะยูน หารเทา พัทลุง 93120",
    name_en: "Pak Phayun, Han Thao, Phatthalung, 93120",
  },
  {
    id: 930607,
    districtTh: "ปากพะยูน",
    districtEn: "Pak Phayun",
    subdistrictTh: "ดอนทราย",
    subdistrictEn: "Don Sai",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93120,
    district_code: 9306,
    province_code: 73,
    name: "ปากพะยูน ดอนทราย พัทลุง 93120",
    name_th: "ปากพะยูน ดอนทราย พัทลุง 93120",
    name_en: "Pak Phayun, Don Sai, Phatthalung, 93120",
  },
  {
    id: 930701,
    districtTh: "ศรีบรรพต",
    districtEn: "Si Banphot",
    subdistrictTh: "เขาย่า",
    subdistrictEn: "Khao Ya",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93190,
    district_code: 9307,
    province_code: 73,
    name: "ศรีบรรพต เขาย่า พัทลุง 93190",
    name_th: "ศรีบรรพต เขาย่า พัทลุง 93190",
    name_en: "Si Banphot, Khao Ya, Phatthalung, 93190",
  },
  {
    id: 930702,
    districtTh: "ศรีบรรพต",
    districtEn: "Si Banphot",
    subdistrictTh: "เขาปู่",
    subdistrictEn: "Khao Pu",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93190,
    district_code: 9307,
    province_code: 73,
    name: "ศรีบรรพต เขาปู่ พัทลุง 93190",
    name_th: "ศรีบรรพต เขาปู่ พัทลุง 93190",
    name_en: "Si Banphot, Khao Pu, Phatthalung, 93190",
  },
  {
    id: 930703,
    districtTh: "ศรีบรรพต",
    districtEn: "Si Banphot",
    subdistrictTh: "ตะแพน",
    subdistrictEn: "Taphaen",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93190,
    district_code: 9307,
    province_code: 73,
    name: "ศรีบรรพต ตะแพน พัทลุง 93190",
    name_th: "ศรีบรรพต ตะแพน พัทลุง 93190",
    name_en: "Si Banphot, Taphaen, Phatthalung, 93190",
  },
  {
    id: 930801,
    districtTh: "ป่าบอน",
    districtEn: "Pa Bon",
    subdistrictTh: "ป่าบอน",
    subdistrictEn: "Pa Bon",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93170,
    district_code: 9308,
    province_code: 73,
    name: "ป่าบอน ป่าบอน พัทลุง 93170",
    name_th: "ป่าบอน ป่าบอน พัทลุง 93170",
    name_en: "Pa Bon, Pa Bon, Phatthalung, 93170",
  },
  {
    id: 930802,
    districtTh: "ป่าบอน",
    districtEn: "Pa Bon",
    subdistrictTh: "โคกทราย",
    subdistrictEn: "Khok Sai",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93170,
    district_code: 9308,
    province_code: 73,
    name: "ป่าบอน โคกทราย พัทลุง 93170",
    name_th: "ป่าบอน โคกทราย พัทลุง 93170",
    name_en: "Pa Bon, Khok Sai, Phatthalung, 93170",
  },
  {
    id: 930803,
    districtTh: "ป่าบอน",
    districtEn: "Pa Bon",
    subdistrictTh: "หนองธง",
    subdistrictEn: "Nong Thong",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93170,
    district_code: 9308,
    province_code: 73,
    name: "ป่าบอน หนองธง พัทลุง 93170",
    name_th: "ป่าบอน หนองธง พัทลุง 93170",
    name_en: "Pa Bon, Nong Thong, Phatthalung, 93170",
  },
  {
    id: 930804,
    districtTh: "ป่าบอน",
    districtEn: "Pa Bon",
    subdistrictTh: "ทุ่งนารี",
    subdistrictEn: "Thung Nari",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93170,
    district_code: 9308,
    province_code: 73,
    name: "ป่าบอน ทุ่งนารี พัทลุง 93170",
    name_th: "ป่าบอน ทุ่งนารี พัทลุง 93170",
    name_en: "Pa Bon, Thung Nari, Phatthalung, 93170",
  },
  {
    id: 930806,
    districtTh: "ป่าบอน",
    districtEn: "Pa Bon",
    subdistrictTh: "วังใหม่",
    subdistrictEn: "Wang Mai",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93170,
    district_code: 9308,
    province_code: 73,
    name: "ป่าบอน วังใหม่ พัทลุง 93170",
    name_th: "ป่าบอน วังใหม่ พัทลุง 93170",
    name_en: "Pa Bon, Wang Mai, Phatthalung, 93170",
  },
  {
    id: 930901,
    districtTh: "บางแก้ว",
    districtEn: "Bang Kaeo",
    subdistrictTh: "ท่ามะเดื่อ",
    subdistrictEn: "Tha Maduea",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93140,
    district_code: 9309,
    province_code: 73,
    name: "บางแก้ว ท่ามะเดื่อ พัทลุง 93140",
    name_th: "บางแก้ว ท่ามะเดื่อ พัทลุง 93140",
    name_en: "Bang Kaeo, Tha Maduea, Phatthalung, 93140",
  },
  {
    id: 930902,
    districtTh: "บางแก้ว",
    districtEn: "Bang Kaeo",
    subdistrictTh: "นาปะขอ",
    subdistrictEn: "Na Pakho",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93140,
    district_code: 9309,
    province_code: 73,
    name: "บางแก้ว นาปะขอ พัทลุง 93140",
    name_th: "บางแก้ว นาปะขอ พัทลุง 93140",
    name_en: "Bang Kaeo, Na Pakho, Phatthalung, 93140",
  },
  {
    id: 930903,
    districtTh: "บางแก้ว",
    districtEn: "Bang Kaeo",
    subdistrictTh: "โคกสัก",
    subdistrictEn: "Khok Sak",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93140,
    district_code: 9309,
    province_code: 73,
    name: "บางแก้ว โคกสัก พัทลุง 93140",
    name_th: "บางแก้ว โคกสัก พัทลุง 93140",
    name_en: "Bang Kaeo, Khok Sak, Phatthalung, 93140",
  },
  {
    id: 931001,
    districtTh: "ป่าพะยอม",
    districtEn: "Pa Phayom",
    subdistrictTh: "ป่าพะยอม",
    subdistrictEn: "Pa Phayom",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9310,
    province_code: 73,
    name: "ป่าพะยอม ป่าพะยอม พัทลุง 93110",
    name_th: "ป่าพะยอม ป่าพะยอม พัทลุง 93110",
    name_en: "Pa Phayom, Pa Phayom, Phatthalung, 93110",
  },
  {
    id: 931002,
    districtTh: "ป่าพะยอม",
    districtEn: "Pa Phayom",
    subdistrictTh: "ลานข่อย",
    subdistrictEn: "Lan Khoi",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9310,
    province_code: 73,
    name: "ป่าพะยอม ลานข่อย พัทลุง 93110",
    name_th: "ป่าพะยอม ลานข่อย พัทลุง 93110",
    name_en: "Pa Phayom, Lan Khoi, Phatthalung, 93110",
  },
  {
    id: 931003,
    districtTh: "ป่าพะยอม",
    districtEn: "Pa Phayom",
    subdistrictTh: "เกาะเต่า",
    subdistrictEn: "Ko Tao",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9310,
    province_code: 73,
    name: "ป่าพะยอม เกาะเต่า พัทลุง 93110",
    name_th: "ป่าพะยอม เกาะเต่า พัทลุง 93110",
    name_en: "Pa Phayom, Ko Tao, Phatthalung, 93110",
  },
  {
    id: 931004,
    districtTh: "ป่าพะยอม",
    districtEn: "Pa Phayom",
    subdistrictTh: "บ้านพร้าว",
    subdistrictEn: "Ban Phrao",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93110,
    district_code: 9310,
    province_code: 73,
    name: "ป่าพะยอม บ้านพร้าว พัทลุง 93110",
    name_th: "ป่าพะยอม บ้านพร้าว พัทลุง 93110",
    name_en: "Pa Phayom, Ban Phrao, Phatthalung, 93110",
  },
  {
    id: 931101,
    districtTh: "ศรีนครินทร์",
    districtEn: "Srinagarindra",
    subdistrictTh: "ชุมพล",
    subdistrictEn: "Chumphon",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9311,
    province_code: 73,
    name: "ศรีนครินทร์ ชุมพล พัทลุง 93000",
    name_th: "ศรีนครินทร์ ชุมพล พัทลุง 93000",
    name_en: "Srinagarindra, Chumphon, Phatthalung, 93000",
  },
  {
    id: 931102,
    districtTh: "ศรีนครินทร์",
    districtEn: "Srinagarindra",
    subdistrictTh: "บ้านนา",
    subdistrictEn: "Ban Na",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9311,
    province_code: 73,
    name: "ศรีนครินทร์ บ้านนา พัทลุง 93000",
    name_th: "ศรีนครินทร์ บ้านนา พัทลุง 93000",
    name_en: "Srinagarindra, Ban Na, Phatthalung, 93000",
  },
  {
    id: 931103,
    districtTh: "ศรีนครินทร์",
    districtEn: "Srinagarindra",
    subdistrictTh: "อ่างทอง",
    subdistrictEn: "Ang Thong",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9311,
    province_code: 73,
    name: "ศรีนครินทร์ อ่างทอง พัทลุง 93000",
    name_th: "ศรีนครินทร์ อ่างทอง พัทลุง 93000",
    name_en: "Srinagarindra, Ang Thong, Phatthalung, 93000",
  },
  {
    id: 931104,
    districtTh: "ศรีนครินทร์",
    districtEn: "Srinagarindra",
    subdistrictTh: "ลำสินธุ์",
    subdistrictEn: "Lam Sin",
    provinceTh: "พัทลุง",
    provinceEn: "Phatthalung",
    zipcode: 93000,
    district_code: 9311,
    province_code: 73,
    name: "ศรีนครินทร์ ลำสินธุ์ พัทลุง 93000",
    name_th: "ศรีนครินทร์ ลำสินธุ์ พัทลุง 93000",
    name_en: "Srinagarindra, Lam Sin, Phatthalung, 93000",
  },
  {
    id: 940101,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "สะบารัง",
    subdistrictEn: "Sabarang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี สะบารัง ปัตตานี 94000",
    name_th: "เมืองปัตตานี สะบารัง ปัตตานี 94000",
    name_en: "Mueang Pattani, Sabarang, Pattani, 94000",
  },
  {
    id: 940102,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "อาเนาะรู",
    subdistrictEn: "Ano Ru",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี อาเนาะรู ปัตตานี 94000",
    name_th: "เมืองปัตตานี อาเนาะรู ปัตตานี 94000",
    name_en: "Mueang Pattani, Ano Ru, Pattani, 94000",
  },
  {
    id: 940103,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "จะบังติกอ",
    subdistrictEn: "Chabang Tiko",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี จะบังติกอ ปัตตานี 94000",
    name_th: "เมืองปัตตานี จะบังติกอ ปัตตานี 94000",
    name_en: "Mueang Pattani, Chabang Tiko, Pattani, 94000",
  },
  {
    id: 940104,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "บานา",
    subdistrictEn: "Bana",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี บานา ปัตตานี 94000",
    name_th: "เมืองปัตตานี บานา ปัตตานี 94000",
    name_en: "Mueang Pattani, Bana, Pattani, 94000",
  },
  {
    id: 940105,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "ตันหยงลุโละ",
    subdistrictEn: "Tanyong Lulo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี ตันหยงลุโละ ปัตตานี 94000",
    name_th: "เมืองปัตตานี ตันหยงลุโละ ปัตตานี 94000",
    name_en: "Mueang Pattani, Tanyong Lulo, Pattani, 94000",
  },
  {
    id: 940106,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "คลองมานิง",
    subdistrictEn: "Khlong Maning",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี คลองมานิง ปัตตานี 94000",
    name_th: "เมืองปัตตานี คลองมานิง ปัตตานี 94000",
    name_en: "Mueang Pattani, Khlong Maning, Pattani, 94000",
  },
  {
    id: 940107,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "กะมิยอ",
    subdistrictEn: "Kamiyo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี กะมิยอ ปัตตานี 94000",
    name_th: "เมืองปัตตานี กะมิยอ ปัตตานี 94000",
    name_en: "Mueang Pattani, Kamiyo, Pattani, 94000",
  },
  {
    id: 940108,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "บาราโหม",
    subdistrictEn: "Barahom",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี บาราโหม ปัตตานี 94000",
    name_th: "เมืองปัตตานี บาราโหม ปัตตานี 94000",
    name_en: "Mueang Pattani, Barahom, Pattani, 94000",
  },
  {
    id: 940109,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "ปะกาฮะรัง",
    subdistrictEn: "Paka Harang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี ปะกาฮะรัง ปัตตานี 94000",
    name_th: "เมืองปัตตานี ปะกาฮะรัง ปัตตานี 94000",
    name_en: "Mueang Pattani, Paka Harang, Pattani, 94000",
  },
  {
    id: 940110,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "รูสะมิแล",
    subdistrictEn: "Ru Samilae",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี รูสะมิแล ปัตตานี 94000",
    name_th: "เมืองปัตตานี รูสะมิแล ปัตตานี 94000",
    name_en: "Mueang Pattani, Ru Samilae, Pattani, 94000",
  },
  {
    id: 940111,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "ตะลุโบะ",
    subdistrictEn: "Talubo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี ตะลุโบะ ปัตตานี 94000",
    name_th: "เมืองปัตตานี ตะลุโบะ ปัตตานี 94000",
    name_en: "Mueang Pattani, Talubo, Pattani, 94000",
  },
  {
    id: 940112,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "บาราเฮาะ",
    subdistrictEn: "Baraho",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี บาราเฮาะ ปัตตานี 94000",
    name_th: "เมืองปัตตานี บาราเฮาะ ปัตตานี 94000",
    name_en: "Mueang Pattani, Baraho, Pattani, 94000",
  },
  {
    id: 940113,
    districtTh: "เมืองปัตตานี",
    districtEn: "Mueang Pattani",
    subdistrictTh: "ปุยุด",
    subdistrictEn: "Puyut",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94000,
    district_code: 9401,
    province_code: 74,
    name: "เมืองปัตตานี ปุยุด ปัตตานี 94000",
    name_th: "เมืองปัตตานี ปุยุด ปัตตานี 94000",
    name_en: "Mueang Pattani, Puyut, Pattani, 94000",
  },
  {
    id: 940201,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "โคกโพธิ์",
    subdistrictEn: "Khok Pho",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94120,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ โคกโพธิ์ ปัตตานี 94120",
    name_th: "โคกโพธิ์ โคกโพธิ์ ปัตตานี 94120",
    name_en: "Khok Pho, Khok Pho, Pattani, 94120",
  },
  {
    id: 940202,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "มะกรูด",
    subdistrictEn: "Makrut",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94120,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ มะกรูด ปัตตานี 94120",
    name_th: "โคกโพธิ์ มะกรูด ปัตตานี 94120",
    name_en: "Khok Pho, Makrut, Pattani, 94120",
  },
  {
    id: 940203,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "บางโกระ",
    subdistrictEn: "Bang Kro",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94120,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ บางโกระ ปัตตานี 94120",
    name_th: "โคกโพธิ์ บางโกระ ปัตตานี 94120",
    name_en: "Khok Pho, Bang Kro, Pattani, 94120",
  },
  {
    id: 940204,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "ป่าบอน",
    subdistrictEn: "Pa Bon",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94120,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ ป่าบอน ปัตตานี 94120",
    name_th: "โคกโพธิ์ ป่าบอน ปัตตานี 94120",
    name_en: "Khok Pho, Pa Bon, Pattani, 94120",
  },
  {
    id: 940205,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "ทรายขาว",
    subdistrictEn: "Sai Khao",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94120,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ ทรายขาว ปัตตานี 94120",
    name_th: "โคกโพธิ์ ทรายขาว ปัตตานี 94120",
    name_en: "Khok Pho, Sai Khao, Pattani, 94120",
  },
  {
    id: 940206,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "นาประดู่",
    subdistrictEn: "Na Pradu",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94180,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ นาประดู่ ปัตตานี 94180",
    name_th: "โคกโพธิ์ นาประดู่ ปัตตานี 94180",
    name_en: "Khok Pho, Na Pradu, Pattani, 94180",
  },
  {
    id: 940207,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "ปากล่อ",
    subdistrictEn: "Pak Lo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94180,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ ปากล่อ ปัตตานี 94180",
    name_th: "โคกโพธิ์ ปากล่อ ปัตตานี 94180",
    name_en: "Khok Pho, Pak Lo, Pattani, 94180",
  },
  {
    id: 940208,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "ทุ่งพลา",
    subdistrictEn: "Thung Phala",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94180,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ ทุ่งพลา ปัตตานี 94180",
    name_th: "โคกโพธิ์ ทุ่งพลา ปัตตานี 94180",
    name_en: "Khok Pho, Thung Phala, Pattani, 94180",
  },
  {
    id: 940211,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "ท่าเรือ",
    subdistrictEn: "Tha Ruea",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94120,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ ท่าเรือ ปัตตานี 94120",
    name_th: "โคกโพธิ์ ท่าเรือ ปัตตานี 94120",
    name_en: "Khok Pho, Tha Ruea, Pattani, 94120",
  },
  {
    id: 940213,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "นาเกตุ",
    subdistrictEn: "Na Ket",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94120,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ นาเกตุ ปัตตานี 94120",
    name_th: "โคกโพธิ์ นาเกตุ ปัตตานี 94120",
    name_en: "Khok Pho, Na Ket, Pattani, 94120",
  },
  {
    id: 940214,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "ควนโนรี",
    subdistrictEn: "Khuan Nori",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94180,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ ควนโนรี ปัตตานี 94180",
    name_th: "โคกโพธิ์ ควนโนรี ปัตตานี 94180",
    name_en: "Khok Pho, Khuan Nori, Pattani, 94180",
  },
  {
    id: 940215,
    districtTh: "โคกโพธิ์",
    districtEn: "Khok Pho",
    subdistrictTh: "ช้างให้ตก",
    subdistrictEn: "Chang Hai Tok",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94120,
    district_code: 9402,
    province_code: 74,
    name: "โคกโพธิ์ ช้างให้ตก ปัตตานี 94120",
    name_th: "โคกโพธิ์ ช้างให้ตก ปัตตานี 94120",
    name_en: "Khok Pho, Chang Hai Tok, Pattani, 94120",
  },
  {
    id: 940301,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "เกาะเปาะ",
    subdistrictEn: "Ko Po",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก เกาะเปาะ ปัตตานี 94170",
    name_th: "หนองจิก เกาะเปาะ ปัตตานี 94170",
    name_en: "Nong Chik, Ko Po, Pattani, 94170",
  },
  {
    id: 940302,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "คอลอตันหยง",
    subdistrictEn: "Kholo Tanyong",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก คอลอตันหยง ปัตตานี 94170",
    name_th: "หนองจิก คอลอตันหยง ปัตตานี 94170",
    name_en: "Nong Chik, Kholo Tanyong, Pattani, 94170",
  },
  {
    id: 940303,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "ดอนรัก",
    subdistrictEn: "Don Rak",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก ดอนรัก ปัตตานี 94170",
    name_th: "หนองจิก ดอนรัก ปัตตานี 94170",
    name_en: "Nong Chik, Don Rak, Pattani, 94170",
  },
  {
    id: 940304,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "ดาโต๊ะ",
    subdistrictEn: "Dato",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก ดาโต๊ะ ปัตตานี 94170",
    name_th: "หนองจิก ดาโต๊ะ ปัตตานี 94170",
    name_en: "Nong Chik, Dato, Pattani, 94170",
  },
  {
    id: 940305,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "ตุยง",
    subdistrictEn: "Tuyong",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก ตุยง ปัตตานี 94170",
    name_th: "หนองจิก ตุยง ปัตตานี 94170",
    name_en: "Nong Chik, Tuyong, Pattani, 94170",
  },
  {
    id: 940306,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "ท่ากำชำ",
    subdistrictEn: "Tha Kamcham",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก ท่ากำชำ ปัตตานี 94170",
    name_th: "หนองจิก ท่ากำชำ ปัตตานี 94170",
    name_en: "Nong Chik, Tha Kamcham, Pattani, 94170",
  },
  {
    id: 940307,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "บ่อทอง",
    subdistrictEn: "Bo Thong",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก บ่อทอง ปัตตานี 94170",
    name_th: "หนองจิก บ่อทอง ปัตตานี 94170",
    name_en: "Nong Chik, Bo Thong, Pattani, 94170",
  },
  {
    id: 940308,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "บางเขา",
    subdistrictEn: "Bang Khao",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก บางเขา ปัตตานี 94170",
    name_th: "หนองจิก บางเขา ปัตตานี 94170",
    name_en: "Nong Chik, Bang Khao, Pattani, 94170",
  },
  {
    id: 940309,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "บางตาวา",
    subdistrictEn: "Bang Tawa",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก บางตาวา ปัตตานี 94170",
    name_th: "หนองจิก บางตาวา ปัตตานี 94170",
    name_en: "Nong Chik, Bang Tawa, Pattani, 94170",
  },
  {
    id: 940310,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "ปุโละปุโย",
    subdistrictEn: "Pulo Puyo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก ปุโละปุโย ปัตตานี 94170",
    name_th: "หนองจิก ปุโละปุโย ปัตตานี 94170",
    name_en: "Nong Chik, Pulo Puyo, Pattani, 94170",
  },
  {
    id: 940311,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "ยาบี",
    subdistrictEn: "Yabi",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก ยาบี ปัตตานี 94170",
    name_th: "หนองจิก ยาบี ปัตตานี 94170",
    name_en: "Nong Chik, Yabi, Pattani, 94170",
  },
  {
    id: 940312,
    districtTh: "หนองจิก",
    districtEn: "Nong Chik",
    subdistrictTh: "ลิปะสะโง",
    subdistrictEn: "Lipa Sa-ngo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94170,
    district_code: 9403,
    province_code: 74,
    name: "หนองจิก ลิปะสะโง ปัตตานี 94170",
    name_th: "หนองจิก ลิปะสะโง ปัตตานี 94170",
    name_en: "Nong Chik, Lipa Sa-ngo, Pattani, 94170",
  },
  {
    id: 940401,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "ปะนาเระ",
    subdistrictEn: "Panare",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ ปะนาเระ ปัตตานี 94130",
    name_th: "ปะนาเระ ปะนาเระ ปัตตานี 94130",
    name_en: "Panare, Panare, Pattani, 94130",
  },
  {
    id: 940402,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "ท่าข้าม",
    subdistrictEn: "Tha Kham",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ ท่าข้าม ปัตตานี 94130",
    name_th: "ปะนาเระ ท่าข้าม ปัตตานี 94130",
    name_en: "Panare, Tha Kham, Pattani, 94130",
  },
  {
    id: 940403,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "บ้านนอก",
    subdistrictEn: "Ban Nok",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ บ้านนอก ปัตตานี 94130",
    name_th: "ปะนาเระ บ้านนอก ปัตตานี 94130",
    name_en: "Panare, Ban Nok, Pattani, 94130",
  },
  {
    id: 940404,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "ดอน",
    subdistrictEn: "Don",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ ดอน ปัตตานี 94130",
    name_th: "ปะนาเระ ดอน ปัตตานี 94130",
    name_en: "Panare, Don, Pattani, 94130",
  },
  {
    id: 940405,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "ควน",
    subdistrictEn: "Khuan",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94190,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ ควน ปัตตานี 94190",
    name_th: "ปะนาเระ ควน ปัตตานี 94190",
    name_en: "Panare, Khuan, Pattani, 94190",
  },
  {
    id: 940406,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "ท่าน้ำ",
    subdistrictEn: "Tha Nam",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ ท่าน้ำ ปัตตานี 94130",
    name_th: "ปะนาเระ ท่าน้ำ ปัตตานี 94130",
    name_en: "Panare, Tha Nam, Pattani, 94130",
  },
  {
    id: 940407,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "คอกกระบือ",
    subdistrictEn: "Khok Krabue",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ คอกกระบือ ปัตตานี 94130",
    name_th: "ปะนาเระ คอกกระบือ ปัตตานี 94130",
    name_en: "Panare, Khok Krabue, Pattani, 94130",
  },
  {
    id: 940408,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "พ่อมิ่ง",
    subdistrictEn: "Pho Ming",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ พ่อมิ่ง ปัตตานี 94130",
    name_th: "ปะนาเระ พ่อมิ่ง ปัตตานี 94130",
    name_en: "Panare, Pho Ming, Pattani, 94130",
  },
  {
    id: 940409,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "บ้านกลาง",
    subdistrictEn: "Ban Klang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ บ้านกลาง ปัตตานี 94130",
    name_th: "ปะนาเระ บ้านกลาง ปัตตานี 94130",
    name_en: "Panare, Ban Klang, Pattani, 94130",
  },
  {
    id: 940410,
    districtTh: "ปะนาเระ",
    districtEn: "Panare",
    subdistrictTh: "บ้านน้ำบ่อ",
    subdistrictEn: "Ban Nam Bo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94130,
    district_code: 9404,
    province_code: 74,
    name: "ปะนาเระ บ้านน้ำบ่อ ปัตตานี 94130",
    name_th: "ปะนาเระ บ้านน้ำบ่อ ปัตตานี 94130",
    name_en: "Panare, Ban Nam Bo, Pattani, 94130",
  },
  {
    id: 940501,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "มายอ",
    subdistrictEn: "Mayo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ มายอ ปัตตานี 94140",
    name_th: "มายอ มายอ ปัตตานี 94140",
    name_en: "Mayo, Mayo, Pattani, 94140",
  },
  {
    id: 940502,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "ถนน",
    subdistrictEn: "Thanon",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ ถนน ปัตตานี 94140",
    name_th: "มายอ ถนน ปัตตานี 94140",
    name_en: "Mayo, Thanon, Pattani, 94140",
  },
  {
    id: 940503,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "ตรัง",
    subdistrictEn: "Trang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ ตรัง ปัตตานี 94140",
    name_th: "มายอ ตรัง ปัตตานี 94140",
    name_en: "Mayo, Trang, Pattani, 94140",
  },
  {
    id: 940504,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "กระหวะ",
    subdistrictEn: "Krawa",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ กระหวะ ปัตตานี 94140",
    name_th: "มายอ กระหวะ ปัตตานี 94140",
    name_en: "Mayo, Krawa, Pattani, 94140",
  },
  {
    id: 940505,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "ลุโบะยิไร",
    subdistrictEn: "Lubo Yirai",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ ลุโบะยิไร ปัตตานี 94140",
    name_th: "มายอ ลุโบะยิไร ปัตตานี 94140",
    name_en: "Mayo, Lubo Yirai, Pattani, 94140",
  },
  {
    id: 940506,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "ลางา",
    subdistrictEn: "La-nga",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94190,
    district_code: 9405,
    province_code: 74,
    name: "มายอ ลางา ปัตตานี 94190",
    name_th: "มายอ ลางา ปัตตานี 94190",
    name_en: "Mayo, La-nga, Pattani, 94190",
  },
  {
    id: 940507,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "กระเสาะ",
    subdistrictEn: "Kra So",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ กระเสาะ ปัตตานี 94140",
    name_th: "มายอ กระเสาะ ปัตตานี 94140",
    name_en: "Mayo, Kra So, Pattani, 94140",
  },
  {
    id: 940508,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "เกาะจัน",
    subdistrictEn: "Ko Chan",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ เกาะจัน ปัตตานี 94140",
    name_th: "มายอ เกาะจัน ปัตตานี 94140",
    name_en: "Mayo, Ko Chan, Pattani, 94140",
  },
  {
    id: 940509,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "ปะโด",
    subdistrictEn: "Pado",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ ปะโด ปัตตานี 94140",
    name_th: "มายอ ปะโด ปัตตานี 94140",
    name_en: "Mayo, Pado, Pattani, 94140",
  },
  {
    id: 940510,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "สาคอบน",
    subdistrictEn: "Sakho Bon",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ สาคอบน ปัตตานี 94140",
    name_th: "มายอ สาคอบน ปัตตานี 94140",
    name_en: "Mayo, Sakho Bon, Pattani, 94140",
  },
  {
    id: 940511,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "สาคอใต้",
    subdistrictEn: "Sakho Tai",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ สาคอใต้ ปัตตานี 94140",
    name_th: "มายอ สาคอใต้ ปัตตานี 94140",
    name_en: "Mayo, Sakho Tai, Pattani, 94140",
  },
  {
    id: 940512,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "สะกำ",
    subdistrictEn: "Sakam",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ สะกำ ปัตตานี 94140",
    name_th: "มายอ สะกำ ปัตตานี 94140",
    name_en: "Mayo, Sakam, Pattani, 94140",
  },
  {
    id: 940513,
    districtTh: "มายอ",
    districtEn: "Mayo",
    subdistrictTh: "ปานัน",
    subdistrictEn: "Panan",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9405,
    province_code: 74,
    name: "มายอ ปานัน ปัตตานี 94140",
    name_th: "มายอ ปานัน ปัตตานี 94140",
    name_en: "Mayo, Panan, Pattani, 94140",
  },
  {
    id: 940601,
    districtTh: "ทุ่งยางแดง",
    districtEn: "Thung Yang Daeng",
    subdistrictTh: "ตะโละแมะนา",
    subdistrictEn: "Talo Mae Na",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9406,
    province_code: 74,
    name: "ทุ่งยางแดง ตะโละแมะนา ปัตตานี 94140",
    name_th: "ทุ่งยางแดง ตะโละแมะนา ปัตตานี 94140",
    name_en: "Thung Yang Daeng, Talo Mae Na, Pattani, 94140",
  },
  {
    id: 940602,
    districtTh: "ทุ่งยางแดง",
    districtEn: "Thung Yang Daeng",
    subdistrictTh: "พิเทน",
    subdistrictEn: "Phithen",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9406,
    province_code: 74,
    name: "ทุ่งยางแดง พิเทน ปัตตานี 94140",
    name_th: "ทุ่งยางแดง พิเทน ปัตตานี 94140",
    name_en: "Thung Yang Daeng, Phithen, Pattani, 94140",
  },
  {
    id: 940603,
    districtTh: "ทุ่งยางแดง",
    districtEn: "Thung Yang Daeng",
    subdistrictTh: "น้ำดำ",
    subdistrictEn: "Nam Dam",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9406,
    province_code: 74,
    name: "ทุ่งยางแดง น้ำดำ ปัตตานี 94140",
    name_th: "ทุ่งยางแดง น้ำดำ ปัตตานี 94140",
    name_en: "Thung Yang Daeng, Nam Dam, Pattani, 94140",
  },
  {
    id: 940604,
    districtTh: "ทุ่งยางแดง",
    districtEn: "Thung Yang Daeng",
    subdistrictTh: "ปากู",
    subdistrictEn: "Paku",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94140,
    district_code: 9406,
    province_code: 74,
    name: "ทุ่งยางแดง ปากู ปัตตานี 94140",
    name_th: "ทุ่งยางแดง ปากู ปัตตานี 94140",
    name_en: "Thung Yang Daeng, Paku, Pattani, 94140",
  },
  {
    id: 940701,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "ตะลุบัน",
    subdistrictEn: "Taluban",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี ตะลุบัน ปัตตานี 94110",
    name_th: "สายบุรี ตะลุบัน ปัตตานี 94110",
    name_en: "Sai Buri, Taluban, Pattani, 94110",
  },
  {
    id: 940702,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "ตะบิ้ง",
    subdistrictEn: "Tabing",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี ตะบิ้ง ปัตตานี 94110",
    name_th: "สายบุรี ตะบิ้ง ปัตตานี 94110",
    name_en: "Sai Buri, Tabing, Pattani, 94110",
  },
  {
    id: 940703,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "ปะเสยะวอ",
    subdistrictEn: "Pase Yawo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี ปะเสยะวอ ปัตตานี 94110",
    name_th: "สายบุรี ปะเสยะวอ ปัตตานี 94110",
    name_en: "Sai Buri, Pase Yawo, Pattani, 94110",
  },
  {
    id: 940704,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "บางเก่า",
    subdistrictEn: "Bang Kao",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี บางเก่า ปัตตานี 94110",
    name_th: "สายบุรี บางเก่า ปัตตานี 94110",
    name_en: "Sai Buri, Bang Kao, Pattani, 94110",
  },
  {
    id: 940705,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "บือเระ",
    subdistrictEn: "Bue Re",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี บือเระ ปัตตานี 94110",
    name_th: "สายบุรี บือเระ ปัตตานี 94110",
    name_en: "Sai Buri, Bue Re, Pattani, 94110",
  },
  {
    id: 940706,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "เตราะบอน",
    subdistrictEn: "Tro Bon",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี เตราะบอน ปัตตานี 94110",
    name_th: "สายบุรี เตราะบอน ปัตตานี 94110",
    name_en: "Sai Buri, Tro Bon, Pattani, 94110",
  },
  {
    id: 940707,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "กะดุนง",
    subdistrictEn: "Kadunong",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี กะดุนง ปัตตานี 94110",
    name_th: "สายบุรี กะดุนง ปัตตานี 94110",
    name_en: "Sai Buri, Kadunong, Pattani, 94110",
  },
  {
    id: 940708,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "ละหาร",
    subdistrictEn: "Lahan",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี ละหาร ปัตตานี 94110",
    name_th: "สายบุรี ละหาร ปัตตานี 94110",
    name_en: "Sai Buri, Lahan, Pattani, 94110",
  },
  {
    id: 940709,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "มะนังดาลำ",
    subdistrictEn: "Manang Dalam",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี มะนังดาลำ ปัตตานี 94110",
    name_th: "สายบุรี มะนังดาลำ ปัตตานี 94110",
    name_en: "Sai Buri, Manang Dalam, Pattani, 94110",
  },
  {
    id: 940710,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "แป้น",
    subdistrictEn: "Paen",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94110,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี แป้น ปัตตานี 94110",
    name_th: "สายบุรี แป้น ปัตตานี 94110",
    name_en: "Sai Buri, Paen, Pattani, 94110",
  },
  {
    id: 940711,
    districtTh: "สายบุรี",
    districtEn: "Sai Buri",
    subdistrictTh: "ทุ่งคล้า",
    subdistrictEn: "Thung Khla",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94190,
    district_code: 9407,
    province_code: 74,
    name: "สายบุรี ทุ่งคล้า ปัตตานี 94190",
    name_th: "สายบุรี ทุ่งคล้า ปัตตานี 94190",
    name_en: "Sai Buri, Thung Khla, Pattani, 94190",
  },
  {
    id: 940801,
    districtTh: "ไม้แก่น",
    districtEn: "Mai Kaen",
    subdistrictTh: "ไทรทอง",
    subdistrictEn: "Sai Thong",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94220,
    district_code: 9408,
    province_code: 74,
    name: "ไม้แก่น ไทรทอง ปัตตานี 94220",
    name_th: "ไม้แก่น ไทรทอง ปัตตานี 94220",
    name_en: "Mai Kaen, Sai Thong, Pattani, 94220",
  },
  {
    id: 940802,
    districtTh: "ไม้แก่น",
    districtEn: "Mai Kaen",
    subdistrictTh: "ไม้แก่น",
    subdistrictEn: "Mai Kaen",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94220,
    district_code: 9408,
    province_code: 74,
    name: "ไม้แก่น ไม้แก่น ปัตตานี 94220",
    name_th: "ไม้แก่น ไม้แก่น ปัตตานี 94220",
    name_en: "Mai Kaen, Mai Kaen, Pattani, 94220",
  },
  {
    id: 940803,
    districtTh: "ไม้แก่น",
    districtEn: "Mai Kaen",
    subdistrictTh: "ตะโละไกรทอง",
    subdistrictEn: "Talo Krai Thong",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94220,
    district_code: 9408,
    province_code: 74,
    name: "ไม้แก่น ตะโละไกรทอง ปัตตานี 94220",
    name_th: "ไม้แก่น ตะโละไกรทอง ปัตตานี 94220",
    name_en: "Mai Kaen, Talo Krai Thong, Pattani, 94220",
  },
  {
    id: 940804,
    districtTh: "ไม้แก่น",
    districtEn: "Mai Kaen",
    subdistrictTh: "ดอนทราย",
    subdistrictEn: "Don Sai",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94220,
    district_code: 9408,
    province_code: 74,
    name: "ไม้แก่น ดอนทราย ปัตตานี 94220",
    name_th: "ไม้แก่น ดอนทราย ปัตตานี 94220",
    name_en: "Mai Kaen, Don Sai, Pattani, 94220",
  },
  {
    id: 940901,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ตะโละ",
    subdistrictEn: "Talo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ตะโละ ปัตตานี 94150",
    name_th: "ยะหริ่ง ตะโละ ปัตตานี 94150",
    name_en: "Yaring, Talo, Pattani, 94150",
  },
  {
    id: 940902,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ตะโละกาโปร์",
    subdistrictEn: "Talo Kapo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ตะโละกาโปร์ ปัตตานี 94150",
    name_th: "ยะหริ่ง ตะโละกาโปร์ ปัตตานี 94150",
    name_en: "Yaring, Talo Kapo, Pattani, 94150",
  },
  {
    id: 940903,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ตันหยงดาลอ",
    subdistrictEn: "Tanyong Dalo",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ตันหยงดาลอ ปัตตานี 94150",
    name_th: "ยะหริ่ง ตันหยงดาลอ ปัตตานี 94150",
    name_en: "Yaring, Tanyong Dalo, Pattani, 94150",
  },
  {
    id: 940904,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ตันหยงจึงงา",
    subdistrictEn: "Tanyong Chueng-nga",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94190,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ตันหยงจึงงา ปัตตานี 94190",
    name_th: "ยะหริ่ง ตันหยงจึงงา ปัตตานี 94190",
    name_en: "Yaring, Tanyong Chueng-nga, Pattani, 94190",
  },
  {
    id: 940905,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ตอหลัง",
    subdistrictEn: "Tolang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ตอหลัง ปัตตานี 94150",
    name_th: "ยะหริ่ง ตอหลัง ปัตตานี 94150",
    name_en: "Yaring, Tolang, Pattani, 94150",
  },
  {
    id: 940906,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ตาแกะ",
    subdistrictEn: "Ta Kae",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ตาแกะ ปัตตานี 94150",
    name_th: "ยะหริ่ง ตาแกะ ปัตตานี 94150",
    name_en: "Yaring, Ta Kae, Pattani, 94150",
  },
  {
    id: 940907,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ตาลีอายร์",
    subdistrictEn: "Tali-ai",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ตาลีอายร์ ปัตตานี 94150",
    name_th: "ยะหริ่ง ตาลีอายร์ ปัตตานี 94150",
    name_en: "Yaring, Tali-ai, Pattani, 94150",
  },
  {
    id: 940908,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ยามู",
    subdistrictEn: "Yamu",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ยามู ปัตตานี 94150",
    name_th: "ยะหริ่ง ยามู ปัตตานี 94150",
    name_en: "Yaring, Yamu, Pattani, 94150",
  },
  {
    id: 940909,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "บางปู",
    subdistrictEn: "Bang Pu",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง บางปู ปัตตานี 94150",
    name_th: "ยะหริ่ง บางปู ปัตตานี 94150",
    name_en: "Yaring, Bang Pu, Pattani, 94150",
  },
  {
    id: 940910,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "หนองแรต",
    subdistrictEn: "Nong Raet",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง หนองแรต ปัตตานี 94150",
    name_th: "ยะหริ่ง หนองแรต ปัตตานี 94150",
    name_en: "Yaring, Nong Raet, Pattani, 94150",
  },
  {
    id: 940911,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ปิยามุมัง",
    subdistrictEn: "Piya Mumang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ปิยามุมัง ปัตตานี 94150",
    name_th: "ยะหริ่ง ปิยามุมัง ปัตตานี 94150",
    name_en: "Yaring, Piya Mumang, Pattani, 94150",
  },
  {
    id: 940912,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ปุลากง",
    subdistrictEn: "Pula Kong",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ปุลากง ปัตตานี 94150",
    name_th: "ยะหริ่ง ปุลากง ปัตตานี 94150",
    name_en: "Yaring, Pula Kong, Pattani, 94150",
  },
  {
    id: 940913,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "บาโลย",
    subdistrictEn: "Baloi",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94190,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง บาโลย ปัตตานี 94190",
    name_th: "ยะหริ่ง บาโลย ปัตตานี 94190",
    name_en: "Yaring, Baloi, Pattani, 94190",
  },
  {
    id: 940914,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "สาบัน",
    subdistrictEn: "Saban",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง สาบัน ปัตตานี 94150",
    name_th: "ยะหริ่ง สาบัน ปัตตานี 94150",
    name_en: "Yaring, Saban, Pattani, 94150",
  },
  {
    id: 940915,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "มะนังยง",
    subdistrictEn: "Manang Yong",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง มะนังยง ปัตตานี 94150",
    name_th: "ยะหริ่ง มะนังยง ปัตตานี 94150",
    name_en: "Yaring, Manang Yong, Pattani, 94150",
  },
  {
    id: 940916,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "ราตาปันยัง",
    subdistrictEn: "Rata Panyang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง ราตาปันยัง ปัตตานี 94150",
    name_th: "ยะหริ่ง ราตาปันยัง ปัตตานี 94150",
    name_en: "Yaring, Rata Panyang, Pattani, 94150",
  },
  {
    id: 940917,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "จะรัง",
    subdistrictEn: "Charang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง จะรัง ปัตตานี 94150",
    name_th: "ยะหริ่ง จะรัง ปัตตานี 94150",
    name_en: "Yaring, Charang, Pattani, 94150",
  },
  {
    id: 940918,
    districtTh: "ยะหริ่ง",
    districtEn: "Yaring",
    subdistrictTh: "แหลมโพธิ์",
    subdistrictEn: "Laem Pho",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94150,
    district_code: 9409,
    province_code: 74,
    name: "ยะหริ่ง แหลมโพธิ์ ปัตตานี 94150",
    name_th: "ยะหริ่ง แหลมโพธิ์ ปัตตานี 94150",
    name_en: "Yaring, Laem Pho, Pattani, 94150",
  },
  {
    id: 941001,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "ยะรัง",
    subdistrictEn: "Yarang",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง ยะรัง ปัตตานี 94160",
    name_th: "ยะรัง ยะรัง ปัตตานี 94160",
    name_en: "Yarang, Yarang, Pattani, 94160",
  },
  {
    id: 941002,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "สะดาวา",
    subdistrictEn: "Sadawa",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง สะดาวา ปัตตานี 94160",
    name_th: "ยะรัง สะดาวา ปัตตานี 94160",
    name_en: "Yarang, Sadawa, Pattani, 94160",
  },
  {
    id: 941003,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "ประจัน",
    subdistrictEn: "Prachan",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง ประจัน ปัตตานี 94160",
    name_th: "ยะรัง ประจัน ปัตตานี 94160",
    name_en: "Yarang, Prachan, Pattani, 94160",
  },
  {
    id: 941004,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "สะนอ",
    subdistrictEn: "Sano",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง สะนอ ปัตตานี 94160",
    name_th: "ยะรัง สะนอ ปัตตานี 94160",
    name_en: "Yarang, Sano, Pattani, 94160",
  },
  {
    id: 941005,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "ระแว้ง",
    subdistrictEn: "Rawaeng",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง ระแว้ง ปัตตานี 94160",
    name_th: "ยะรัง ระแว้ง ปัตตานี 94160",
    name_en: "Yarang, Rawaeng, Pattani, 94160",
  },
  {
    id: 941006,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "ปิตูมุดี",
    subdistrictEn: "Pitu Mudi",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง ปิตูมุดี ปัตตานี 94160",
    name_th: "ยะรัง ปิตูมุดี ปัตตานี 94160",
    name_en: "Yarang, Pitu Mudi, Pattani, 94160",
  },
  {
    id: 941007,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "วัด",
    subdistrictEn: "Wat",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง วัด ปัตตานี 94160",
    name_th: "ยะรัง วัด ปัตตานี 94160",
    name_en: "Yarang, Wat, Pattani, 94160",
  },
  {
    id: 941008,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "กระโด",
    subdistrictEn: "Krado",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง กระโด ปัตตานี 94160",
    name_th: "ยะรัง กระโด ปัตตานี 94160",
    name_en: "Yarang, Krado, Pattani, 94160",
  },
  {
    id: 941009,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "คลองใหม่",
    subdistrictEn: "Khlong Mai",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง คลองใหม่ ปัตตานี 94160",
    name_th: "ยะรัง คลองใหม่ ปัตตานี 94160",
    name_en: "Yarang, Khlong Mai, Pattani, 94160",
  },
  {
    id: 941010,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "เมาะมาวี",
    subdistrictEn: "Mo Mawi",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง เมาะมาวี ปัตตานี 94160",
    name_th: "ยะรัง เมาะมาวี ปัตตานี 94160",
    name_en: "Yarang, Mo Mawi, Pattani, 94160",
  },
  {
    id: 941011,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "กอลำ",
    subdistrictEn: "Kolam",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง กอลำ ปัตตานี 94160",
    name_th: "ยะรัง กอลำ ปัตตานี 94160",
    name_en: "Yarang, Kolam, Pattani, 94160",
  },
  {
    id: 941012,
    districtTh: "ยะรัง",
    districtEn: "Yarang",
    subdistrictTh: "เขาตูม",
    subdistrictEn: "Khao Tum",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94160,
    district_code: 9410,
    province_code: 74,
    name: "ยะรัง เขาตูม ปัตตานี 94160",
    name_th: "ยะรัง เขาตูม ปัตตานี 94160",
    name_en: "Yarang, Khao Tum, Pattani, 94160",
  },
  {
    id: 941101,
    districtTh: "กะพ้อ",
    districtEn: "Kapho",
    subdistrictTh: "กะรุบี",
    subdistrictEn: "Karubi",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94230,
    district_code: 9411,
    province_code: 74,
    name: "กะพ้อ กะรุบี ปัตตานี 94230",
    name_th: "กะพ้อ กะรุบี ปัตตานี 94230",
    name_en: "Kapho, Karubi, Pattani, 94230",
  },
  {
    id: 941102,
    districtTh: "กะพ้อ",
    districtEn: "Kapho",
    subdistrictTh: "ตะโละดือรามัน",
    subdistrictEn: "Talo Due Raman",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94230,
    district_code: 9411,
    province_code: 74,
    name: "กะพ้อ ตะโละดือรามัน ปัตตานี 94230",
    name_th: "กะพ้อ ตะโละดือรามัน ปัตตานี 94230",
    name_en: "Kapho, Talo Due Raman, Pattani, 94230",
  },
  {
    id: 941103,
    districtTh: "กะพ้อ",
    districtEn: "Kapho",
    subdistrictTh: "ปล่องหอย",
    subdistrictEn: "Plong Hoi",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94230,
    district_code: 9411,
    province_code: 74,
    name: "กะพ้อ ปล่องหอย ปัตตานี 94230",
    name_th: "กะพ้อ ปล่องหอย ปัตตานี 94230",
    name_en: "Kapho, Plong Hoi, Pattani, 94230",
  },
  {
    id: 941201,
    districtTh: "แม่ลาน",
    districtEn: "Mae Lan",
    subdistrictTh: "แม่ลาน",
    subdistrictEn: "Mae Lan",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94180,
    district_code: 9412,
    province_code: 74,
    name: "แม่ลาน แม่ลาน ปัตตานี 94180",
    name_th: "แม่ลาน แม่ลาน ปัตตานี 94180",
    name_en: "Mae Lan, Mae Lan, Pattani, 94180",
  },
  {
    id: 941202,
    districtTh: "แม่ลาน",
    districtEn: "Mae Lan",
    subdistrictTh: "ม่วงเตี้ย",
    subdistrictEn: "Muang Tia",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94180,
    district_code: 9412,
    province_code: 74,
    name: "แม่ลาน ม่วงเตี้ย ปัตตานี 94180",
    name_th: "แม่ลาน ม่วงเตี้ย ปัตตานี 94180",
    name_en: "Mae Lan, Muang Tia, Pattani, 94180",
  },
  {
    id: 941203,
    districtTh: "แม่ลาน",
    districtEn: "Mae Lan",
    subdistrictTh: "ป่าไร่",
    subdistrictEn: "Pa Rai",
    provinceTh: "ปัตตานี",
    provinceEn: "Pattani",
    zipcode: 94180,
    district_code: 9412,
    province_code: 74,
    name: "แม่ลาน ป่าไร่ ปัตตานี 94180",
    name_th: "แม่ลาน ป่าไร่ ปัตตานี 94180",
    name_en: "Mae Lan, Pa Rai, Pattani, 94180",
  },
  {
    id: 950101,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "สะเตง",
    subdistrictEn: "Sateng",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา สะเตง ยะลา 95000",
    name_th: "เมืองยะลา สะเตง ยะลา 95000",
    name_en: "Mueang Yala, Sateng, Yala, 95000",
  },
  {
    id: 950102,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "บุดี",
    subdistrictEn: "Budi",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา บุดี ยะลา 95000",
    name_th: "เมืองยะลา บุดี ยะลา 95000",
    name_en: "Mueang Yala, Budi, Yala, 95000",
  },
  {
    id: 950103,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "ยุโป",
    subdistrictEn: "Yopo",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา ยุโป ยะลา 95000",
    name_th: "เมืองยะลา ยุโป ยะลา 95000",
    name_en: "Mueang Yala, Yopo, Yala, 95000",
  },
  {
    id: 950104,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "ลิดล",
    subdistrictEn: "Lidon",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95160,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา ลิดล ยะลา 95160",
    name_th: "เมืองยะลา ลิดล ยะลา 95160",
    name_en: "Mueang Yala, Lidon, Yala, 95160",
  },
  {
    id: 950106,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "ยะลา",
    subdistrictEn: "Yala",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา ยะลา ยะลา 95000",
    name_th: "เมืองยะลา ยะลา ยะลา 95000",
    name_en: "Mueang Yala, Yala, Yala, 95000",
  },
  {
    id: 950108,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "ท่าสาป",
    subdistrictEn: "Tha Sap",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา ท่าสาป ยะลา 95000",
    name_th: "เมืองยะลา ท่าสาป ยะลา 95000",
    name_en: "Mueang Yala, Tha Sap, Yala, 95000",
  },
  {
    id: 950109,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "ลำใหม่",
    subdistrictEn: "Lam Mai",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95160,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา ลำใหม่ ยะลา 95160",
    name_th: "เมืองยะลา ลำใหม่ ยะลา 95160",
    name_en: "Mueang Yala, Lam Mai, Yala, 95160",
  },
  {
    id: 950110,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "หน้าถ้ำ",
    subdistrictEn: "Na Tham",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา หน้าถ้ำ ยะลา 95000",
    name_th: "เมืองยะลา หน้าถ้ำ ยะลา 95000",
    name_en: "Mueang Yala, Na Tham, Yala, 95000",
  },
  {
    id: 950111,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "ลำพะยา",
    subdistrictEn: "Lam Phaya",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95160,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา ลำพะยา ยะลา 95160",
    name_th: "เมืองยะลา ลำพะยา ยะลา 95160",
    name_en: "Mueang Yala, Lam Phaya, Yala, 95160",
  },
  {
    id: 950112,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "เปาะเส้ง",
    subdistrictEn: "Po Seng",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา เปาะเส้ง ยะลา 95000",
    name_th: "เมืองยะลา เปาะเส้ง ยะลา 95000",
    name_en: "Mueang Yala, Po Seng, Yala, 95000",
  },
  {
    id: 950114,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "พร่อน",
    subdistrictEn: "Phron",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95160,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา พร่อน ยะลา 95160",
    name_th: "เมืองยะลา พร่อน ยะลา 95160",
    name_en: "Mueang Yala, Phron, Yala, 95160",
  },
  {
    id: 950115,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "บันนังสาเรง",
    subdistrictEn: "Bannang Sareng",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา บันนังสาเรง ยะลา 95000",
    name_th: "เมืองยะลา บันนังสาเรง ยะลา 95000",
    name_en: "Mueang Yala, Bannang Sareng, Yala, 95000",
  },
  {
    id: 950116,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "สะเตงนอก",
    subdistrictEn: "Sateng Nok",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา สะเตงนอก ยะลา 95000",
    name_th: "เมืองยะลา สะเตงนอก ยะลา 95000",
    name_en: "Mueang Yala, Sateng Nok, Yala, 95000",
  },
  {
    id: 950118,
    districtTh: "เมืองยะลา",
    districtEn: "Mueang Yala",
    subdistrictTh: "ตาเซะ",
    subdistrictEn: "Ta Se",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9501,
    province_code: 75,
    name: "เมืองยะลา ตาเซะ ยะลา 95000",
    name_th: "เมืองยะลา ตาเซะ ยะลา 95000",
    name_en: "Mueang Yala, Ta Se, Yala, 95000",
  },
  {
    id: 950201,
    districtTh: "เบตง",
    districtEn: "Betong",
    subdistrictTh: "เบตง",
    subdistrictEn: "Betong",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95110,
    district_code: 9502,
    province_code: 75,
    name: "เบตง เบตง ยะลา 95110",
    name_th: "เบตง เบตง ยะลา 95110",
    name_en: "Betong, Betong, Yala, 95110",
  },
  {
    id: 950202,
    districtTh: "เบตง",
    districtEn: "Betong",
    subdistrictTh: "ยะรม",
    subdistrictEn: "Yarom",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95110,
    district_code: 9502,
    province_code: 75,
    name: "เบตง ยะรม ยะลา 95110",
    name_th: "เบตง ยะรม ยะลา 95110",
    name_en: "Betong, Yarom, Yala, 95110",
  },
  {
    id: 950203,
    districtTh: "เบตง",
    districtEn: "Betong",
    subdistrictTh: "ตาเนาะแมเราะ",
    subdistrictEn: "Tano Maero",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95110,
    district_code: 9502,
    province_code: 75,
    name: "เบตง ตาเนาะแมเราะ ยะลา 95110",
    name_th: "เบตง ตาเนาะแมเราะ ยะลา 95110",
    name_en: "Betong, Tano Maero, Yala, 95110",
  },
  {
    id: 950204,
    districtTh: "เบตง",
    districtEn: "Betong",
    subdistrictTh: "อัยเยอร์เวง",
    subdistrictEn: "Aiyoe Weng",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95110,
    district_code: 9502,
    province_code: 75,
    name: "เบตง อัยเยอร์เวง ยะลา 95110",
    name_th: "เบตง อัยเยอร์เวง ยะลา 95110",
    name_en: "Betong, Aiyoe Weng, Yala, 95110",
  },
  {
    id: 950205,
    districtTh: "เบตง",
    districtEn: "Betong",
    subdistrictTh: "ธารน้ำทิพย์",
    subdistrictEn: "Than Nam Thip",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95110,
    district_code: 9502,
    province_code: 75,
    name: "เบตง ธารน้ำทิพย์ ยะลา 95110",
    name_th: "เบตง ธารน้ำทิพย์ ยะลา 95110",
    name_en: "Betong, Than Nam Thip, Yala, 95110",
  },
  {
    id: 950301,
    districtTh: "บันนังสตา",
    districtEn: "Bannang Sata",
    subdistrictTh: "บันนังสตา",
    subdistrictEn: "Bannang Sata",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95130,
    district_code: 9503,
    province_code: 75,
    name: "บันนังสตา บันนังสตา ยะลา 95130",
    name_th: "บันนังสตา บันนังสตา ยะลา 95130",
    name_en: "Bannang Sata, Bannang Sata, Yala, 95130",
  },
  {
    id: 950302,
    districtTh: "บันนังสตา",
    districtEn: "Bannang Sata",
    subdistrictTh: "บาเจาะ",
    subdistrictEn: "Bacho",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95130,
    district_code: 9503,
    province_code: 75,
    name: "บันนังสตา บาเจาะ ยะลา 95130",
    name_th: "บันนังสตา บาเจาะ ยะลา 95130",
    name_en: "Bannang Sata, Bacho, Yala, 95130",
  },
  {
    id: 950303,
    districtTh: "บันนังสตา",
    districtEn: "Bannang Sata",
    subdistrictTh: "ตาเนาะปูเต๊ะ",
    subdistrictEn: "Tano Pute",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95130,
    district_code: 9503,
    province_code: 75,
    name: "บันนังสตา ตาเนาะปูเต๊ะ ยะลา 95130",
    name_th: "บันนังสตา ตาเนาะปูเต๊ะ ยะลา 95130",
    name_en: "Bannang Sata, Tano Pute, Yala, 95130",
  },
  {
    id: 950304,
    districtTh: "บันนังสตา",
    districtEn: "Bannang Sata",
    subdistrictTh: "ถ้ำทะลุ",
    subdistrictEn: "Tham Thalu",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95130,
    district_code: 9503,
    province_code: 75,
    name: "บันนังสตา ถ้ำทะลุ ยะลา 95130",
    name_th: "บันนังสตา ถ้ำทะลุ ยะลา 95130",
    name_en: "Bannang Sata, Tham Thalu, Yala, 95130",
  },
  {
    id: 950305,
    districtTh: "บันนังสตา",
    districtEn: "Bannang Sata",
    subdistrictTh: "ตลิ่งชัน",
    subdistrictEn: "Taling Chan",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95130,
    district_code: 9503,
    province_code: 75,
    name: "บันนังสตา ตลิ่งชัน ยะลา 95130",
    name_th: "บันนังสตา ตลิ่งชัน ยะลา 95130",
    name_en: "Bannang Sata, Taling Chan, Yala, 95130",
  },
  {
    id: 950306,
    districtTh: "บันนังสตา",
    districtEn: "Bannang Sata",
    subdistrictTh: "เขื่อนบางลาง",
    subdistrictEn: "Khuean Bang Lang",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95130,
    district_code: 9503,
    province_code: 75,
    name: "บันนังสตา เขื่อนบางลาง ยะลา 95130",
    name_th: "บันนังสตา เขื่อนบางลาง ยะลา 95130",
    name_en: "Bannang Sata, Khuean Bang Lang, Yala, 95130",
  },
  {
    id: 950401,
    districtTh: "ธารโต",
    districtEn: "Than To",
    subdistrictTh: "ธารโต",
    subdistrictEn: "Than To",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95150,
    district_code: 9504,
    province_code: 75,
    name: "ธารโต ธารโต ยะลา 95150",
    name_th: "ธารโต ธารโต ยะลา 95150",
    name_en: "Than To, Than To, Yala, 95150",
  },
  {
    id: 950402,
    districtTh: "ธารโต",
    districtEn: "Than To",
    subdistrictTh: "บ้านแหร",
    subdistrictEn: "Ban Rae",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95150,
    district_code: 9504,
    province_code: 75,
    name: "ธารโต บ้านแหร ยะลา 95150",
    name_th: "ธารโต บ้านแหร ยะลา 95150",
    name_en: "Than To, Ban Rae, Yala, 95150",
  },
  {
    id: 950403,
    districtTh: "ธารโต",
    districtEn: "Than To",
    subdistrictTh: "แม่หวาด",
    subdistrictEn: "Mae Wat",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95170,
    district_code: 9504,
    province_code: 75,
    name: "ธารโต แม่หวาด ยะลา 95170",
    name_th: "ธารโต แม่หวาด ยะลา 95170",
    name_en: "Than To, Mae Wat, Yala, 95170",
  },
  {
    id: 950404,
    districtTh: "ธารโต",
    districtEn: "Than To",
    subdistrictTh: "คีรีเขต",
    subdistrictEn: "Khiri Khet",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95150,
    district_code: 9504,
    province_code: 75,
    name: "ธารโต คีรีเขต ยะลา 95150",
    name_th: "ธารโต คีรีเขต ยะลา 95150",
    name_en: "Than To, Khiri Khet, Yala, 95150",
  },
  {
    id: 950501,
    districtTh: "ยะหา",
    districtEn: "Yaha",
    subdistrictTh: "ยะหา",
    subdistrictEn: "Yaha",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9505,
    province_code: 75,
    name: "ยะหา ยะหา ยะลา 95120",
    name_th: "ยะหา ยะหา ยะลา 95120",
    name_en: "Yaha, Yaha, Yala, 95120",
  },
  {
    id: 950502,
    districtTh: "ยะหา",
    districtEn: "Yaha",
    subdistrictTh: "ละแอ",
    subdistrictEn: "La-ae",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9505,
    province_code: 75,
    name: "ยะหา ละแอ ยะลา 95120",
    name_th: "ยะหา ละแอ ยะลา 95120",
    name_en: "Yaha, La-ae, Yala, 95120",
  },
  {
    id: 950503,
    districtTh: "ยะหา",
    districtEn: "Yaha",
    subdistrictTh: "ปะแต",
    subdistrictEn: "Patae",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9505,
    province_code: 75,
    name: "ยะหา ปะแต ยะลา 95120",
    name_th: "ยะหา ปะแต ยะลา 95120",
    name_en: "Yaha, Patae, Yala, 95120",
  },
  {
    id: 950504,
    districtTh: "ยะหา",
    districtEn: "Yaha",
    subdistrictTh: "บาโร๊ะ",
    subdistrictEn: "Baro",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9505,
    province_code: 75,
    name: "ยะหา บาโร๊ะ ยะลา 95120",
    name_th: "ยะหา บาโร๊ะ ยะลา 95120",
    name_en: "Yaha, Baro, Yala, 95120",
  },
  {
    id: 950506,
    districtTh: "ยะหา",
    districtEn: "Yaha",
    subdistrictTh: "ตาชี",
    subdistrictEn: "Ta Chi",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9505,
    province_code: 75,
    name: "ยะหา ตาชี ยะลา 95120",
    name_th: "ยะหา ตาชี ยะลา 95120",
    name_en: "Yaha, Ta Chi, Yala, 95120",
  },
  {
    id: 950507,
    districtTh: "ยะหา",
    districtEn: "Yaha",
    subdistrictTh: "บาโงยซิแน",
    subdistrictEn: "Ba-ngoi Sinae",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9505,
    province_code: 75,
    name: "ยะหา บาโงยซิแน ยะลา 95120",
    name_th: "ยะหา บาโงยซิแน ยะลา 95120",
    name_en: "Yaha, Ba-ngoi Sinae, Yala, 95120",
  },
  {
    id: 950508,
    districtTh: "ยะหา",
    districtEn: "Yaha",
    subdistrictTh: "กาตอง",
    subdistrictEn: "Ka Tong",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9505,
    province_code: 75,
    name: "ยะหา กาตอง ยะลา 95120",
    name_th: "ยะหา กาตอง ยะลา 95120",
    name_en: "Yaha, Ka Tong, Yala, 95120",
  },
  {
    id: 950601,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "กายูบอเกาะ",
    subdistrictEn: "Kayu Boko",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน กายูบอเกาะ ยะลา 95140",
    name_th: "รามัน กายูบอเกาะ ยะลา 95140",
    name_en: "Raman, Kayu Boko, Yala, 95140",
  },
  {
    id: 950602,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "กาลูปัง",
    subdistrictEn: "Kalupang",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน กาลูปัง ยะลา 95140",
    name_th: "รามัน กาลูปัง ยะลา 95140",
    name_en: "Raman, Kalupang, Yala, 95140",
  },
  {
    id: 950603,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "กาลอ",
    subdistrictEn: "Kalo",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน กาลอ ยะลา 95140",
    name_th: "รามัน กาลอ ยะลา 95140",
    name_en: "Raman, Kalo, Yala, 95140",
  },
  {
    id: 950604,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "กอตอตือร๊ะ",
    subdistrictEn: "Koto Tuera",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน กอตอตือร๊ะ ยะลา 95140",
    name_th: "รามัน กอตอตือร๊ะ ยะลา 95140",
    name_en: "Raman, Koto Tuera, Yala, 95140",
  },
  {
    id: 950605,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "โกตาบารู",
    subdistrictEn: "Kota Baru",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน โกตาบารู ยะลา 95140",
    name_th: "รามัน โกตาบารู ยะลา 95140",
    name_en: "Raman, Kota Baru, Yala, 95140",
  },
  {
    id: 950606,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "เกะรอ",
    subdistrictEn: "Kero",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน เกะรอ ยะลา 95140",
    name_th: "รามัน เกะรอ ยะลา 95140",
    name_en: "Raman, Kero, Yala, 95140",
  },
  {
    id: 950607,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "จะกว๊ะ",
    subdistrictEn: "Cha-kwa",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน จะกว๊ะ ยะลา 95140",
    name_th: "รามัน จะกว๊ะ ยะลา 95140",
    name_en: "Raman, Cha-kwa, Yala, 95140",
  },
  {
    id: 950608,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "ท่าธง",
    subdistrictEn: "Tha Thong",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน ท่าธง ยะลา 95140",
    name_th: "รามัน ท่าธง ยะลา 95140",
    name_en: "Raman, Tha Thong, Yala, 95140",
  },
  {
    id: 950609,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "เนินงาม",
    subdistrictEn: "Noen Ngam",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน เนินงาม ยะลา 95140",
    name_th: "รามัน เนินงาม ยะลา 95140",
    name_en: "Raman, Noen Ngam, Yala, 95140",
  },
  {
    id: 950610,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "บาลอ",
    subdistrictEn: "Balo",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน บาลอ ยะลา 95140",
    name_th: "รามัน บาลอ ยะลา 95140",
    name_en: "Raman, Balo, Yala, 95140",
  },
  {
    id: 950611,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "บาโงย",
    subdistrictEn: "Ba-ngoi",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน บาโงย ยะลา 95140",
    name_th: "รามัน บาโงย ยะลา 95140",
    name_en: "Raman, Ba-ngoi, Yala, 95140",
  },
  {
    id: 950612,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "บือมัง",
    subdistrictEn: "Buemang",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน บือมัง ยะลา 95140",
    name_th: "รามัน บือมัง ยะลา 95140",
    name_en: "Raman, Buemang, Yala, 95140",
  },
  {
    id: 950613,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "ยะต๊ะ",
    subdistrictEn: "Yata",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน ยะต๊ะ ยะลา 95140",
    name_th: "รามัน ยะต๊ะ ยะลา 95140",
    name_en: "Raman, Yata, Yala, 95140",
  },
  {
    id: 950614,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "วังพญา",
    subdistrictEn: "Wang Phaya",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน วังพญา ยะลา 95140",
    name_th: "รามัน วังพญา ยะลา 95140",
    name_en: "Raman, Wang Phaya, Yala, 95140",
  },
  {
    id: 950615,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "อาซ่อง",
    subdistrictEn: "Asong",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน อาซ่อง ยะลา 95140",
    name_th: "รามัน อาซ่อง ยะลา 95140",
    name_en: "Raman, Asong, Yala, 95140",
  },
  {
    id: 950616,
    districtTh: "รามัน",
    districtEn: "Raman",
    subdistrictTh: "ตะโล๊ะหะลอ",
    subdistrictEn: "Talo Halo",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95140,
    district_code: 9506,
    province_code: 75,
    name: "รามัน ตะโล๊ะหะลอ ยะลา 95140",
    name_th: "รามัน ตะโล๊ะหะลอ ยะลา 95140",
    name_en: "Raman, Talo Halo, Yala, 95140",
  },
  {
    id: 950701,
    districtTh: "กาบัง",
    districtEn: "Kabang",
    subdistrictTh: "กาบัง",
    subdistrictEn: "Kabang",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9507,
    province_code: 75,
    name: "กาบัง กาบัง ยะลา 95120",
    name_th: "กาบัง กาบัง ยะลา 95120",
    name_en: "Kabang, Kabang, Yala, 95120",
  },
  {
    id: 950702,
    districtTh: "กาบัง",
    districtEn: "Kabang",
    subdistrictTh: "บาละ",
    subdistrictEn: "Bala",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95120,
    district_code: 9507,
    province_code: 75,
    name: "กาบัง บาละ ยะลา 95120",
    name_th: "กาบัง บาละ ยะลา 95120",
    name_en: "Kabang, Bala, Yala, 95120",
  },
  {
    id: 950801,
    districtTh: "กรงปินัง",
    districtEn: "Krong Pinang",
    subdistrictTh: "กรงปินัง",
    subdistrictEn: "Krong Pinang",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9508,
    province_code: 75,
    name: "กรงปินัง กรงปินัง ยะลา 95000",
    name_th: "กรงปินัง กรงปินัง ยะลา 95000",
    name_en: "Krong Pinang, Krong Pinang, Yala, 95000",
  },
  {
    id: 950802,
    districtTh: "กรงปินัง",
    districtEn: "Krong Pinang",
    subdistrictTh: "สะเอะ",
    subdistrictEn: "Sa-e",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9508,
    province_code: 75,
    name: "กรงปินัง สะเอะ ยะลา 95000",
    name_th: "กรงปินัง สะเอะ ยะลา 95000",
    name_en: "Krong Pinang, Sa-e, Yala, 95000",
  },
  {
    id: 950803,
    districtTh: "กรงปินัง",
    districtEn: "Krong Pinang",
    subdistrictTh: "ห้วยกระทิง",
    subdistrictEn: "Huai Krathing",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9508,
    province_code: 75,
    name: "กรงปินัง ห้วยกระทิง ยะลา 95000",
    name_th: "กรงปินัง ห้วยกระทิง ยะลา 95000",
    name_en: "Krong Pinang, Huai Krathing, Yala, 95000",
  },
  {
    id: 950804,
    districtTh: "กรงปินัง",
    districtEn: "Krong Pinang",
    subdistrictTh: "ปุโรง",
    subdistrictEn: "Purong",
    provinceTh: "ยะลา",
    provinceEn: "Yala",
    zipcode: 95000,
    district_code: 9508,
    province_code: 75,
    name: "กรงปินัง ปุโรง ยะลา 95000",
    name_th: "กรงปินัง ปุโรง ยะลา 95000",
    name_en: "Krong Pinang, Purong, Yala, 95000",
  },
  {
    id: 960101,
    districtTh: "เมืองนราธิวาส",
    districtEn: "Mueang Narathiwat",
    subdistrictTh: "บางนาค",
    subdistrictEn: "Bang Nak",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96000,
    district_code: 9601,
    province_code: 76,
    name: "เมืองนราธิวาส บางนาค นราธิวาส 96000",
    name_th: "เมืองนราธิวาส บางนาค นราธิวาส 96000",
    name_en: "Mueang Narathiwat, Bang Nak, Narathiwat, 96000",
  },
  {
    id: 960102,
    districtTh: "เมืองนราธิวาส",
    districtEn: "Mueang Narathiwat",
    subdistrictTh: "ลำภู",
    subdistrictEn: "Lam Phu",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96000,
    district_code: 9601,
    province_code: 76,
    name: "เมืองนราธิวาส ลำภู นราธิวาส 96000",
    name_th: "เมืองนราธิวาส ลำภู นราธิวาส 96000",
    name_en: "Mueang Narathiwat, Lam Phu, Narathiwat, 96000",
  },
  {
    id: 960103,
    districtTh: "เมืองนราธิวาส",
    districtEn: "Mueang Narathiwat",
    subdistrictTh: "มะนังตายอ",
    subdistrictEn: "Manang Tayo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96000,
    district_code: 9601,
    province_code: 76,
    name: "เมืองนราธิวาส มะนังตายอ นราธิวาส 96000",
    name_th: "เมืองนราธิวาส มะนังตายอ นราธิวาส 96000",
    name_en: "Mueang Narathiwat, Manang Tayo, Narathiwat, 96000",
  },
  {
    id: 960104,
    districtTh: "เมืองนราธิวาส",
    districtEn: "Mueang Narathiwat",
    subdistrictTh: "บางปอ",
    subdistrictEn: "Bang Po",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96000,
    district_code: 9601,
    province_code: 76,
    name: "เมืองนราธิวาส บางปอ นราธิวาส 96000",
    name_th: "เมืองนราธิวาส บางปอ นราธิวาส 96000",
    name_en: "Mueang Narathiwat, Bang Po, Narathiwat, 96000",
  },
  {
    id: 960105,
    districtTh: "เมืองนราธิวาส",
    districtEn: "Mueang Narathiwat",
    subdistrictTh: "กะลุวอ",
    subdistrictEn: "Kaluwo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96000,
    district_code: 9601,
    province_code: 76,
    name: "เมืองนราธิวาส กะลุวอ นราธิวาส 96000",
    name_th: "เมืองนราธิวาส กะลุวอ นราธิวาส 96000",
    name_en: "Mueang Narathiwat, Kaluwo, Narathiwat, 96000",
  },
  {
    id: 960106,
    districtTh: "เมืองนราธิวาส",
    districtEn: "Mueang Narathiwat",
    subdistrictTh: "กะลุวอเหนือ",
    subdistrictEn: "Kaluwo Nuea",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96000,
    district_code: 9601,
    province_code: 76,
    name: "เมืองนราธิวาส กะลุวอเหนือ นราธิวาส 96000",
    name_th: "เมืองนราธิวาส กะลุวอเหนือ นราธิวาส 96000",
    name_en: "Mueang Narathiwat, Kaluwo Nuea, Narathiwat, 96000",
  },
  {
    id: 960107,
    districtTh: "เมืองนราธิวาส",
    districtEn: "Mueang Narathiwat",
    subdistrictTh: "โคกเคียน",
    subdistrictEn: "Khok Khian",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96000,
    district_code: 9601,
    province_code: 76,
    name: "เมืองนราธิวาส โคกเคียน นราธิวาส 96000",
    name_th: "เมืองนราธิวาส โคกเคียน นราธิวาส 96000",
    name_en: "Mueang Narathiwat, Khok Khian, Narathiwat, 96000",
  },
  {
    id: 960201,
    districtTh: "ตากใบ",
    districtEn: "Tak Bai",
    subdistrictTh: "เจ๊ะเห",
    subdistrictEn: "Chehe",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96110,
    district_code: 9602,
    province_code: 76,
    name: "ตากใบ เจ๊ะเห นราธิวาส 96110",
    name_th: "ตากใบ เจ๊ะเห นราธิวาส 96110",
    name_en: "Tak Bai, Chehe, Narathiwat, 96110",
  },
  {
    id: 960202,
    districtTh: "ตากใบ",
    districtEn: "Tak Bai",
    subdistrictTh: "ไพรวัน",
    subdistrictEn: "Phrai Wan",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96110,
    district_code: 9602,
    province_code: 76,
    name: "ตากใบ ไพรวัน นราธิวาส 96110",
    name_th: "ตากใบ ไพรวัน นราธิวาส 96110",
    name_en: "Tak Bai, Phrai Wan, Narathiwat, 96110",
  },
  {
    id: 960203,
    districtTh: "ตากใบ",
    districtEn: "Tak Bai",
    subdistrictTh: "พร่อน",
    subdistrictEn: "Phron",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96110,
    district_code: 9602,
    province_code: 76,
    name: "ตากใบ พร่อน นราธิวาส 96110",
    name_th: "ตากใบ พร่อน นราธิวาส 96110",
    name_en: "Tak Bai, Phron, Narathiwat, 96110",
  },
  {
    id: 960204,
    districtTh: "ตากใบ",
    districtEn: "Tak Bai",
    subdistrictTh: "ศาลาใหม่",
    subdistrictEn: "Sala Mai",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96110,
    district_code: 9602,
    province_code: 76,
    name: "ตากใบ ศาลาใหม่ นราธิวาส 96110",
    name_th: "ตากใบ ศาลาใหม่ นราธิวาส 96110",
    name_en: "Tak Bai, Sala Mai, Narathiwat, 96110",
  },
  {
    id: 960205,
    districtTh: "ตากใบ",
    districtEn: "Tak Bai",
    subdistrictTh: "บางขุนทอง",
    subdistrictEn: "Bang Khun Thong",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96110,
    district_code: 9602,
    province_code: 76,
    name: "ตากใบ บางขุนทอง นราธิวาส 96110",
    name_th: "ตากใบ บางขุนทอง นราธิวาส 96110",
    name_en: "Tak Bai, Bang Khun Thong, Narathiwat, 96110",
  },
  {
    id: 960206,
    districtTh: "ตากใบ",
    districtEn: "Tak Bai",
    subdistrictTh: "เกาะสะท้อน",
    subdistrictEn: "Ko Sathon",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96110,
    district_code: 9602,
    province_code: 76,
    name: "ตากใบ เกาะสะท้อน นราธิวาส 96110",
    name_th: "ตากใบ เกาะสะท้อน นราธิวาส 96110",
    name_en: "Tak Bai, Ko Sathon, Narathiwat, 96110",
  },
  {
    id: 960207,
    districtTh: "ตากใบ",
    districtEn: "Tak Bai",
    subdistrictTh: "นานาค",
    subdistrictEn: "Na Nak",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96110,
    district_code: 9602,
    province_code: 76,
    name: "ตากใบ นานาค นราธิวาส 96110",
    name_th: "ตากใบ นานาค นราธิวาส 96110",
    name_en: "Tak Bai, Na Nak, Narathiwat, 96110",
  },
  {
    id: 960208,
    districtTh: "ตากใบ",
    districtEn: "Tak Bai",
    subdistrictTh: "โฆษิต",
    subdistrictEn: "Khosit",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96110,
    district_code: 9602,
    province_code: 76,
    name: "ตากใบ โฆษิต นราธิวาส 96110",
    name_th: "ตากใบ โฆษิต นราธิวาส 96110",
    name_en: "Tak Bai, Khosit, Narathiwat, 96110",
  },
  {
    id: 960301,
    districtTh: "บาเจาะ",
    districtEn: "Bacho",
    subdistrictTh: "บาเจาะ",
    subdistrictEn: "Bacho",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96170,
    district_code: 9603,
    province_code: 76,
    name: "บาเจาะ บาเจาะ นราธิวาส 96170",
    name_th: "บาเจาะ บาเจาะ นราธิวาส 96170",
    name_en: "Bacho, Bacho, Narathiwat, 96170",
  },
  {
    id: 960302,
    districtTh: "บาเจาะ",
    districtEn: "Bacho",
    subdistrictTh: "ลุโบะสาวอ",
    subdistrictEn: "Lubo Sawo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96170,
    district_code: 9603,
    province_code: 76,
    name: "บาเจาะ ลุโบะสาวอ นราธิวาส 96170",
    name_th: "บาเจาะ ลุโบะสาวอ นราธิวาส 96170",
    name_en: "Bacho, Lubo Sawo, Narathiwat, 96170",
  },
  {
    id: 960303,
    districtTh: "บาเจาะ",
    districtEn: "Bacho",
    subdistrictTh: "กาเยาะมาตี",
    subdistrictEn: "Kayo Mati",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96170,
    district_code: 9603,
    province_code: 76,
    name: "บาเจาะ กาเยาะมาตี นราธิวาส 96170",
    name_th: "บาเจาะ กาเยาะมาตี นราธิวาส 96170",
    name_en: "Bacho, Kayo Mati, Narathiwat, 96170",
  },
  {
    id: 960304,
    districtTh: "บาเจาะ",
    districtEn: "Bacho",
    subdistrictTh: "ปะลุกาสาเมาะ",
    subdistrictEn: "Paluka Samo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96170,
    district_code: 9603,
    province_code: 76,
    name: "บาเจาะ ปะลุกาสาเมาะ นราธิวาส 96170",
    name_th: "บาเจาะ ปะลุกาสาเมาะ นราธิวาส 96170",
    name_en: "Bacho, Paluka Samo, Narathiwat, 96170",
  },
  {
    id: 960305,
    districtTh: "บาเจาะ",
    districtEn: "Bacho",
    subdistrictTh: "บาเระเหนือ",
    subdistrictEn: "Bare Nuea",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96170,
    district_code: 9603,
    province_code: 76,
    name: "บาเจาะ บาเระเหนือ นราธิวาส 96170",
    name_th: "บาเจาะ บาเระเหนือ นราธิวาส 96170",
    name_en: "Bacho, Bare Nuea, Narathiwat, 96170",
  },
  {
    id: 960306,
    districtTh: "บาเจาะ",
    districtEn: "Bacho",
    subdistrictTh: "บาเระใต้",
    subdistrictEn: "Ba Re Tai",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96170,
    district_code: 9603,
    province_code: 76,
    name: "บาเจาะ บาเระใต้ นราธิวาส 96170",
    name_th: "บาเจาะ บาเระใต้ นราธิวาส 96170",
    name_en: "Bacho, Ba Re Tai, Narathiwat, 96170",
  },
  {
    id: 960401,
    districtTh: "ยี่งอ",
    districtEn: "Yi-ngo",
    subdistrictTh: "ยี่งอ",
    subdistrictEn: "Yi-ngo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96180,
    district_code: 9604,
    province_code: 76,
    name: "ยี่งอ ยี่งอ นราธิวาส 96180",
    name_th: "ยี่งอ ยี่งอ นราธิวาส 96180",
    name_en: "Yi-ngo, Yi-ngo, Narathiwat, 96180",
  },
  {
    id: 960402,
    districtTh: "ยี่งอ",
    districtEn: "Yi-ngo",
    subdistrictTh: "ละหาร",
    subdistrictEn: "Lahan",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96180,
    district_code: 9604,
    province_code: 76,
    name: "ยี่งอ ละหาร นราธิวาส 96180",
    name_th: "ยี่งอ ละหาร นราธิวาส 96180",
    name_en: "Yi-ngo, Lahan, Narathiwat, 96180",
  },
  {
    id: 960403,
    districtTh: "ยี่งอ",
    districtEn: "Yi-ngo",
    subdistrictTh: "จอเบาะ",
    subdistrictEn: "Chobo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96180,
    district_code: 9604,
    province_code: 76,
    name: "ยี่งอ จอเบาะ นราธิวาส 96180",
    name_th: "ยี่งอ จอเบาะ นราธิวาส 96180",
    name_en: "Yi-ngo, Chobo, Narathiwat, 96180",
  },
  {
    id: 960404,
    districtTh: "ยี่งอ",
    districtEn: "Yi-ngo",
    subdistrictTh: "ลุโบะบายะ",
    subdistrictEn: "Lubo Baya",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96180,
    district_code: 9604,
    province_code: 76,
    name: "ยี่งอ ลุโบะบายะ นราธิวาส 96180",
    name_th: "ยี่งอ ลุโบะบายะ นราธิวาส 96180",
    name_en: "Yi-ngo, Lubo Baya, Narathiwat, 96180",
  },
  {
    id: 960405,
    districtTh: "ยี่งอ",
    districtEn: "Yi-ngo",
    subdistrictTh: "ลุโบะบือซา",
    subdistrictEn: "Lubo Buesa",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96180,
    district_code: 9604,
    province_code: 76,
    name: "ยี่งอ ลุโบะบือซา นราธิวาส 96180",
    name_th: "ยี่งอ ลุโบะบือซา นราธิวาส 96180",
    name_en: "Yi-ngo, Lubo Buesa, Narathiwat, 96180",
  },
  {
    id: 960406,
    districtTh: "ยี่งอ",
    districtEn: "Yi-ngo",
    subdistrictTh: "ตะปอเยาะ",
    subdistrictEn: "Tapoyo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96180,
    district_code: 9604,
    province_code: 76,
    name: "ยี่งอ ตะปอเยาะ นราธิวาส 96180",
    name_th: "ยี่งอ ตะปอเยาะ นราธิวาส 96180",
    name_en: "Yi-ngo, Tapoyo, Narathiwat, 96180",
  },
  {
    id: 960501,
    districtTh: "ระแงะ",
    districtEn: "Ra-ngae",
    subdistrictTh: "ตันหยงมัส",
    subdistrictEn: "Tanyong Mat",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9605,
    province_code: 76,
    name: "ระแงะ ตันหยงมัส นราธิวาส 96130",
    name_th: "ระแงะ ตันหยงมัส นราธิวาส 96130",
    name_en: "Ra-ngae, Tanyong Mat, Narathiwat, 96130",
  },
  {
    id: 960502,
    districtTh: "ระแงะ",
    districtEn: "Ra-ngae",
    subdistrictTh: "ตันหยงลิมอ",
    subdistrictEn: "Tanyong Limo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9605,
    province_code: 76,
    name: "ระแงะ ตันหยงลิมอ นราธิวาส 96130",
    name_th: "ระแงะ ตันหยงลิมอ นราธิวาส 96130",
    name_en: "Ra-ngae, Tanyong Limo, Narathiwat, 96130",
  },
  {
    id: 960506,
    districtTh: "ระแงะ",
    districtEn: "Ra-ngae",
    subdistrictTh: "บองอ",
    subdistrictEn: "Bo-ngo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96220,
    district_code: 9605,
    province_code: 76,
    name: "ระแงะ บองอ นราธิวาส 96220",
    name_th: "ระแงะ บองอ นราธิวาส 96220",
    name_en: "Ra-ngae, Bo-ngo, Narathiwat, 96220",
  },
  {
    id: 960507,
    districtTh: "ระแงะ",
    districtEn: "Ra-ngae",
    subdistrictTh: "กาลิซา",
    subdistrictEn: "Kalisa",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9605,
    province_code: 76,
    name: "ระแงะ กาลิซา นราธิวาส 96130",
    name_th: "ระแงะ กาลิซา นราธิวาส 96130",
    name_en: "Ra-ngae, Kalisa, Narathiwat, 96130",
  },
  {
    id: 960508,
    districtTh: "ระแงะ",
    districtEn: "Ra-ngae",
    subdistrictTh: "บาโงสะโต",
    subdistrictEn: "Ba-ngo Sato",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9605,
    province_code: 76,
    name: "ระแงะ บาโงสะโต นราธิวาส 96130",
    name_th: "ระแงะ บาโงสะโต นราธิวาส 96130",
    name_en: "Ra-ngae, Ba-ngo Sato, Narathiwat, 96130",
  },
  {
    id: 960509,
    districtTh: "ระแงะ",
    districtEn: "Ra-ngae",
    subdistrictTh: "เฉลิม",
    subdistrictEn: "Chaloem",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9605,
    province_code: 76,
    name: "ระแงะ เฉลิม นราธิวาส 96130",
    name_th: "ระแงะ เฉลิม นราธิวาส 96130",
    name_en: "Ra-ngae, Chaloem, Narathiwat, 96130",
  },
  {
    id: 960510,
    districtTh: "ระแงะ",
    districtEn: "Ra-ngae",
    subdistrictTh: "มะรือโบตก",
    subdistrictEn: "Maruebo Tok",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9605,
    province_code: 76,
    name: "ระแงะ มะรือโบตก นราธิวาส 96130",
    name_th: "ระแงะ มะรือโบตก นราธิวาส 96130",
    name_en: "Ra-ngae, Maruebo Tok, Narathiwat, 96130",
  },
  {
    id: 960601,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "รือเสาะ",
    subdistrictEn: "Rueso",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ รือเสาะ นราธิวาส 96150",
    name_th: "รือเสาะ รือเสาะ นราธิวาส 96150",
    name_en: "Rueso, Rueso, Narathiwat, 96150",
  },
  {
    id: 960602,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "สาวอ",
    subdistrictEn: "Sawo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ สาวอ นราธิวาส 96150",
    name_th: "รือเสาะ สาวอ นราธิวาส 96150",
    name_en: "Rueso, Sawo, Narathiwat, 96150",
  },
  {
    id: 960603,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "เรียง",
    subdistrictEn: "Riang",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ เรียง นราธิวาส 96150",
    name_th: "รือเสาะ เรียง นราธิวาส 96150",
    name_en: "Rueso, Riang, Narathiwat, 96150",
  },
  {
    id: 960604,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "สามัคคี",
    subdistrictEn: "Samakkhi",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ สามัคคี นราธิวาส 96150",
    name_th: "รือเสาะ สามัคคี นราธิวาส 96150",
    name_en: "Rueso, Samakkhi, Narathiwat, 96150",
  },
  {
    id: 960605,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "บาตง",
    subdistrictEn: "Batong",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ บาตง นราธิวาส 96150",
    name_th: "รือเสาะ บาตง นราธิวาส 96150",
    name_en: "Rueso, Batong, Narathiwat, 96150",
  },
  {
    id: 960606,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "ลาโละ",
    subdistrictEn: "Lalo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ ลาโละ นราธิวาส 96150",
    name_th: "รือเสาะ ลาโละ นราธิวาส 96150",
    name_en: "Rueso, Lalo, Narathiwat, 96150",
  },
  {
    id: 960607,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "รือเสาะออก",
    subdistrictEn: "Rueso Ok",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ รือเสาะออก นราธิวาส 96150",
    name_th: "รือเสาะ รือเสาะออก นราธิวาส 96150",
    name_en: "Rueso, Rueso Ok, Narathiwat, 96150",
  },
  {
    id: 960608,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "โคกสะตอ",
    subdistrictEn: "Khok Sato",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ โคกสะตอ นราธิวาส 96150",
    name_th: "รือเสาะ โคกสะตอ นราธิวาส 96150",
    name_en: "Rueso, Khok Sato, Narathiwat, 96150",
  },
  {
    id: 960609,
    districtTh: "รือเสาะ",
    districtEn: "Rueso",
    subdistrictTh: "สุวารี",
    subdistrictEn: "Suwari",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96150,
    district_code: 9606,
    province_code: 76,
    name: "รือเสาะ สุวารี นราธิวาส 96150",
    name_th: "รือเสาะ สุวารี นราธิวาส 96150",
    name_en: "Rueso, Suwari, Narathiwat, 96150",
  },
  {
    id: 960701,
    districtTh: "ศรีสาคร",
    districtEn: "Si Sakhon",
    subdistrictTh: "ซากอ",
    subdistrictEn: "Sako",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96210,
    district_code: 9607,
    province_code: 76,
    name: "ศรีสาคร ซากอ นราธิวาส 96210",
    name_th: "ศรีสาคร ซากอ นราธิวาส 96210",
    name_en: "Si Sakhon, Sako, Narathiwat, 96210",
  },
  {
    id: 960702,
    districtTh: "ศรีสาคร",
    districtEn: "Si Sakhon",
    subdistrictTh: "ตะมะยูง",
    subdistrictEn: "Tamayung",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96210,
    district_code: 9607,
    province_code: 76,
    name: "ศรีสาคร ตะมะยูง นราธิวาส 96210",
    name_th: "ศรีสาคร ตะมะยูง นราธิวาส 96210",
    name_en: "Si Sakhon, Tamayung, Narathiwat, 96210",
  },
  {
    id: 960703,
    districtTh: "ศรีสาคร",
    districtEn: "Si Sakhon",
    subdistrictTh: "ศรีสาคร",
    subdistrictEn: "Si Sakhon",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96210,
    district_code: 9607,
    province_code: 76,
    name: "ศรีสาคร ศรีสาคร นราธิวาส 96210",
    name_th: "ศรีสาคร ศรีสาคร นราธิวาส 96210",
    name_en: "Si Sakhon, Si Sakhon, Narathiwat, 96210",
  },
  {
    id: 960704,
    districtTh: "ศรีสาคร",
    districtEn: "Si Sakhon",
    subdistrictTh: "เชิงคีรี",
    subdistrictEn: "Choeng Khiri",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96210,
    district_code: 9607,
    province_code: 76,
    name: "ศรีสาคร เชิงคีรี นราธิวาส 96210",
    name_th: "ศรีสาคร เชิงคีรี นราธิวาส 96210",
    name_en: "Si Sakhon, Choeng Khiri, Narathiwat, 96210",
  },
  {
    id: 960705,
    districtTh: "ศรีสาคร",
    districtEn: "Si Sakhon",
    subdistrictTh: "กาหลง",
    subdistrictEn: "Kalong",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96210,
    district_code: 9607,
    province_code: 76,
    name: "ศรีสาคร กาหลง นราธิวาส 96210",
    name_th: "ศรีสาคร กาหลง นราธิวาส 96210",
    name_en: "Si Sakhon, Kalong, Narathiwat, 96210",
  },
  {
    id: 960706,
    districtTh: "ศรีสาคร",
    districtEn: "Si Sakhon",
    subdistrictTh: "ศรีบรรพต",
    subdistrictEn: "Si Banphot",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96210,
    district_code: 9607,
    province_code: 76,
    name: "ศรีสาคร ศรีบรรพต นราธิวาส 96210",
    name_th: "ศรีสาคร ศรีบรรพต นราธิวาส 96210",
    name_en: "Si Sakhon, Si Banphot, Narathiwat, 96210",
  },
  {
    id: 960801,
    districtTh: "แว้ง",
    districtEn: "Waeng",
    subdistrictTh: "แว้ง",
    subdistrictEn: "Waeng",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96160,
    district_code: 9608,
    province_code: 76,
    name: "แว้ง แว้ง นราธิวาส 96160",
    name_th: "แว้ง แว้ง นราธิวาส 96160",
    name_en: "Waeng, Waeng, Narathiwat, 96160",
  },
  {
    id: 960802,
    districtTh: "แว้ง",
    districtEn: "Waeng",
    subdistrictTh: "กายูคละ",
    subdistrictEn: "Kayu Khla",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96160,
    district_code: 9608,
    province_code: 76,
    name: "แว้ง กายูคละ นราธิวาส 96160",
    name_th: "แว้ง กายูคละ นราธิวาส 96160",
    name_en: "Waeng, Kayu Khla, Narathiwat, 96160",
  },
  {
    id: 960803,
    districtTh: "แว้ง",
    districtEn: "Waeng",
    subdistrictTh: "ฆอเลาะ",
    subdistrictEn: "Kholo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96160,
    district_code: 9608,
    province_code: 76,
    name: "แว้ง ฆอเลาะ นราธิวาส 96160",
    name_th: "แว้ง ฆอเลาะ นราธิวาส 96160",
    name_en: "Waeng, Kholo, Narathiwat, 96160",
  },
  {
    id: 960804,
    districtTh: "แว้ง",
    districtEn: "Waeng",
    subdistrictTh: "โละจูด",
    subdistrictEn: "Lochut",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96160,
    district_code: 9608,
    province_code: 76,
    name: "แว้ง โละจูด นราธิวาส 96160",
    name_th: "แว้ง โละจูด นราธิวาส 96160",
    name_en: "Waeng, Lochut, Narathiwat, 96160",
  },
  {
    id: 960805,
    districtTh: "แว้ง",
    districtEn: "Waeng",
    subdistrictTh: "แม่ดง",
    subdistrictEn: "Mae Dong",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96160,
    district_code: 9608,
    province_code: 76,
    name: "แว้ง แม่ดง นราธิวาส 96160",
    name_th: "แว้ง แม่ดง นราธิวาส 96160",
    name_en: "Waeng, Mae Dong, Narathiwat, 96160",
  },
  {
    id: 960806,
    districtTh: "แว้ง",
    districtEn: "Waeng",
    subdistrictTh: "เอราวัณ",
    subdistrictEn: "Erawan",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96160,
    district_code: 9608,
    province_code: 76,
    name: "แว้ง เอราวัณ นราธิวาส 96160",
    name_th: "แว้ง เอราวัณ นราธิวาส 96160",
    name_en: "Waeng, Erawan, Narathiwat, 96160",
  },
  {
    id: 960901,
    districtTh: "สุคิริน",
    districtEn: "Sukhirin",
    subdistrictTh: "มาโมง",
    subdistrictEn: "Mamong",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96190,
    district_code: 9609,
    province_code: 76,
    name: "สุคิริน มาโมง นราธิวาส 96190",
    name_th: "สุคิริน มาโมง นราธิวาส 96190",
    name_en: "Sukhirin, Mamong, Narathiwat, 96190",
  },
  {
    id: 960902,
    districtTh: "สุคิริน",
    districtEn: "Sukhirin",
    subdistrictTh: "สุคิริน",
    subdistrictEn: "Sukhirin",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96190,
    district_code: 9609,
    province_code: 76,
    name: "สุคิริน สุคิริน นราธิวาส 96190",
    name_th: "สุคิริน สุคิริน นราธิวาส 96190",
    name_en: "Sukhirin, Sukhirin, Narathiwat, 96190",
  },
  {
    id: 960903,
    districtTh: "สุคิริน",
    districtEn: "Sukhirin",
    subdistrictTh: "เกียร์",
    subdistrictEn: "Kia",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96190,
    district_code: 9609,
    province_code: 76,
    name: "สุคิริน เกียร์ นราธิวาส 96190",
    name_th: "สุคิริน เกียร์ นราธิวาส 96190",
    name_en: "Sukhirin, Kia, Narathiwat, 96190",
  },
  {
    id: 960904,
    districtTh: "สุคิริน",
    districtEn: "Sukhirin",
    subdistrictTh: "ภูเขาทอง",
    subdistrictEn: "Phukhao Thong",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96190,
    district_code: 9609,
    province_code: 76,
    name: "สุคิริน ภูเขาทอง นราธิวาส 96190",
    name_th: "สุคิริน ภูเขาทอง นราธิวาส 96190",
    name_en: "Sukhirin, Phukhao Thong, Narathiwat, 96190",
  },
  {
    id: 960905,
    districtTh: "สุคิริน",
    districtEn: "Sukhirin",
    subdistrictTh: "ร่มไทร",
    subdistrictEn: "Rom Sai",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96190,
    district_code: 9609,
    province_code: 76,
    name: "สุคิริน ร่มไทร นราธิวาส 96190",
    name_th: "สุคิริน ร่มไทร นราธิวาส 96190",
    name_en: "Sukhirin, Rom Sai, Narathiwat, 96190",
  },
  {
    id: 961001,
    districtTh: "สุไหงโก-ลก",
    districtEn: "Su-ngai Kolok",
    subdistrictTh: "สุไหงโก-ลก",
    subdistrictEn: "Su-ngai Kolok",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96120,
    district_code: 9610,
    province_code: 76,
    name: "สุไหงโก-ลก สุไหงโก-ลก นราธิวาส 96120",
    name_th: "สุไหงโก-ลก สุไหงโก-ลก นราธิวาส 96120",
    name_en: "Su-ngai Kolok, Su-ngai Kolok, Narathiwat, 96120",
  },
  {
    id: 961002,
    districtTh: "สุไหงโก-ลก",
    districtEn: "Su-ngai Kolok",
    subdistrictTh: "ปาเสมัส",
    subdistrictEn: "Pase Mat",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96120,
    district_code: 9610,
    province_code: 76,
    name: "สุไหงโก-ลก ปาเสมัส นราธิวาส 96120",
    name_th: "สุไหงโก-ลก ปาเสมัส นราธิวาส 96120",
    name_en: "Su-ngai Kolok, Pase Mat, Narathiwat, 96120",
  },
  {
    id: 961003,
    districtTh: "สุไหงโก-ลก",
    districtEn: "Su-ngai Kolok",
    subdistrictTh: "มูโนะ",
    subdistrictEn: "Muno",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96120,
    district_code: 9610,
    province_code: 76,
    name: "สุไหงโก-ลก มูโนะ นราธิวาส 96120",
    name_th: "สุไหงโก-ลก มูโนะ นราธิวาส 96120",
    name_en: "Su-ngai Kolok, Muno, Narathiwat, 96120",
  },
  {
    id: 961004,
    districtTh: "สุไหงโก-ลก",
    districtEn: "Su-ngai Kolok",
    subdistrictTh: "ปูโยะ",
    subdistrictEn: "Puyo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96120,
    district_code: 9610,
    province_code: 76,
    name: "สุไหงโก-ลก ปูโยะ นราธิวาส 96120",
    name_th: "สุไหงโก-ลก ปูโยะ นราธิวาส 96120",
    name_en: "Su-ngai Kolok, Puyo, Narathiwat, 96120",
  },
  {
    id: 961101,
    districtTh: "สุไหงปาดี",
    districtEn: "Su-ngai Padi",
    subdistrictTh: "ปะลุรู",
    subdistrictEn: "Paluru",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96140,
    district_code: 9611,
    province_code: 76,
    name: "สุไหงปาดี ปะลุรู นราธิวาส 96140",
    name_th: "สุไหงปาดี ปะลุรู นราธิวาส 96140",
    name_en: "Su-ngai Padi, Paluru, Narathiwat, 96140",
  },
  {
    id: 961102,
    districtTh: "สุไหงปาดี",
    districtEn: "Su-ngai Padi",
    subdistrictTh: "สุไหงปาดี",
    subdistrictEn: "Su-ngai Padi",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96140,
    district_code: 9611,
    province_code: 76,
    name: "สุไหงปาดี สุไหงปาดี นราธิวาส 96140",
    name_th: "สุไหงปาดี สุไหงปาดี นราธิวาส 96140",
    name_en: "Su-ngai Padi, Su-ngai Padi, Narathiwat, 96140",
  },
  {
    id: 961103,
    districtTh: "สุไหงปาดี",
    districtEn: "Su-ngai Padi",
    subdistrictTh: "โต๊ะเด็ง",
    subdistrictEn: "To Deng",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96140,
    district_code: 9611,
    province_code: 76,
    name: "สุไหงปาดี โต๊ะเด็ง นราธิวาส 96140",
    name_th: "สุไหงปาดี โต๊ะเด็ง นราธิวาส 96140",
    name_en: "Su-ngai Padi, To Deng, Narathiwat, 96140",
  },
  {
    id: 961104,
    districtTh: "สุไหงปาดี",
    districtEn: "Su-ngai Padi",
    subdistrictTh: "สากอ",
    subdistrictEn: "Sako",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96140,
    district_code: 9611,
    province_code: 76,
    name: "สุไหงปาดี สากอ นราธิวาส 96140",
    name_th: "สุไหงปาดี สากอ นราธิวาส 96140",
    name_en: "Su-ngai Padi, Sako, Narathiwat, 96140",
  },
  {
    id: 961105,
    districtTh: "สุไหงปาดี",
    districtEn: "Su-ngai Padi",
    subdistrictTh: "ริโก๋",
    subdistrictEn: "Riko",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96140,
    district_code: 9611,
    province_code: 76,
    name: "สุไหงปาดี ริโก๋ นราธิวาส 96140",
    name_th: "สุไหงปาดี ริโก๋ นราธิวาส 96140",
    name_en: "Su-ngai Padi, Riko, Narathiwat, 96140",
  },
  {
    id: 961106,
    districtTh: "สุไหงปาดี",
    districtEn: "Su-ngai Padi",
    subdistrictTh: "กาวะ",
    subdistrictEn: "Ka Wa",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96140,
    district_code: 9611,
    province_code: 76,
    name: "สุไหงปาดี กาวะ นราธิวาส 96140",
    name_th: "สุไหงปาดี กาวะ นราธิวาส 96140",
    name_en: "Su-ngai Padi, Ka Wa, Narathiwat, 96140",
  },
  {
    id: 961201,
    districtTh: "จะแนะ",
    districtEn: "Chanae",
    subdistrictTh: "จะแนะ",
    subdistrictEn: "Chanae",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96220,
    district_code: 9612,
    province_code: 76,
    name: "จะแนะ จะแนะ นราธิวาส 96220",
    name_th: "จะแนะ จะแนะ นราธิวาส 96220",
    name_en: "Chanae, Chanae, Narathiwat, 96220",
  },
  {
    id: 961202,
    districtTh: "จะแนะ",
    districtEn: "Chanae",
    subdistrictTh: "ดุซงญอ",
    subdistrictEn: "Dusong Yo",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96220,
    district_code: 9612,
    province_code: 76,
    name: "จะแนะ ดุซงญอ นราธิวาส 96220",
    name_th: "จะแนะ ดุซงญอ นราธิวาส 96220",
    name_en: "Chanae, Dusong Yo, Narathiwat, 96220",
  },
  {
    id: 961203,
    districtTh: "จะแนะ",
    districtEn: "Chanae",
    subdistrictTh: "ผดุงมาตร",
    subdistrictEn: "Phadung Mat",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96220,
    district_code: 9612,
    province_code: 76,
    name: "จะแนะ ผดุงมาตร นราธิวาส 96220",
    name_th: "จะแนะ ผดุงมาตร นราธิวาส 96220",
    name_en: "Chanae, Phadung Mat, Narathiwat, 96220",
  },
  {
    id: 961204,
    districtTh: "จะแนะ",
    districtEn: "Chanae",
    subdistrictTh: "ช้างเผือก",
    subdistrictEn: "Chang Phueak",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96220,
    district_code: 9612,
    province_code: 76,
    name: "จะแนะ ช้างเผือก นราธิวาส 96220",
    name_th: "จะแนะ ช้างเผือก นราธิวาส 96220",
    name_en: "Chanae, Chang Phueak, Narathiwat, 96220",
  },
  {
    id: 961301,
    districtTh: "เจาะไอร้อง",
    districtEn: "Cho-airong",
    subdistrictTh: "จวบ",
    subdistrictEn: "Chuap",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9613,
    province_code: 76,
    name: "เจาะไอร้อง จวบ นราธิวาส 96130",
    name_th: "เจาะไอร้อง จวบ นราธิวาส 96130",
    name_en: "Cho-airong, Chuap, Narathiwat, 96130",
  },
  {
    id: 961302,
    districtTh: "เจาะไอร้อง",
    districtEn: "Cho-airong",
    subdistrictTh: "บูกิต",
    subdistrictEn: "Bukit",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9613,
    province_code: 76,
    name: "เจาะไอร้อง บูกิต นราธิวาส 96130",
    name_th: "เจาะไอร้อง บูกิต นราธิวาส 96130",
    name_en: "Cho-airong, Bukit, Narathiwat, 96130",
  },
  {
    id: 961303,
    districtTh: "เจาะไอร้อง",
    districtEn: "Cho-airong",
    subdistrictTh: "มะรือโบออก",
    subdistrictEn: "Maruebo Ok",
    provinceTh: "นราธิวาส",
    provinceEn: "Narathiwat",
    zipcode: 96130,
    district_code: 9613,
    province_code: 76,
    name: "เจาะไอร้อง มะรือโบออก นราธิวาส 96130",
    name_th: "เจาะไอร้อง มะรือโบออก นราธิวาส 96130",
    name_en: "Cho-airong, Maruebo Ok, Narathiwat, 96130",
  },
  {
    id: 380101,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "คำนาดี",
    subdistrictEn: "Kham Na Di",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ คำนาดี บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ คำนาดี บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Kham Na Di, buogkan, 38000",
  },
  {
    id: 380102,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "บึงโขงหลง",
    subdistrictEn: "Bueng Khong Long",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ บึงโขงหลง บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ บึงโขงหลง บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Bueng Khong Long, buogkan, 38000",
  },
  {
    id: 380103,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "ไคสี",
    subdistrictEn: "Khai Si",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ ไคสี บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ ไคสี บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Khai Si, buogkan, 38000",
  },
  {
    id: 380104,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "ชัยพร",
    subdistrictEn: "Chaiyaphon",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ ชัยพร บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ ชัยพร บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Chaiyaphon, buogkan, 38000",
  },
  {
    id: 380105,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "นาสวรรค์",
    subdistrictEn: "Na Sawan",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ นาสวรรค์ บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ นาสวรรค์ บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Na Sawan, buogkan, 38000",
  },
  {
    id: 380106,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "โนนสมบูรณ์",
    subdistrictEn: "Non Sombun",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ โนนสมบูรณ์ บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ โนนสมบูรณ์ บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Non Sombun, buogkan, 38000",
  },
  {
    id: 380107,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "บึงกาฬ",
    subdistrictEn: "Bueng Kan",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ บึงกาฬ บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ บึงกาฬ บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Bueng Kan, buogkan, 38000",
  },
  {
    id: 380108,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "โป่งเปื่อย",
    subdistrictEn: "Pong Pueai",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ โป่งเปื่อย บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ โป่งเปื่อย บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Pong Pueai, buogkan, 38000",
  },
  {
    id: 380109,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "วิศิษฐ์",
    subdistrictEn: "Wisit",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ วิศิษฐ์ บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ วิศิษฐ์ บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Wisit, buogkan, 38000",
  },
  {
    id: 380110,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "หนองเข็ง",
    subdistrictEn: "Nong Keng",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ หนองเข็ง บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ หนองเข็ง บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Nong Keng, buogkan, 38000",
  },
  {
    id: 380111,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "หนองเลิง",
    subdistrictEn: "Nong Loeng",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ หนองเลิง บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ หนองเลิง บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Nong Loeng, buogkan, 38000",
  },
  {
    id: 380112,
    districtTh: "เมืองบึงกาฬ",
    districtEn: "Mueang Bueng Kan",
    subdistrictTh: "หอคำ",
    subdistrictEn: "Ho Kham",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3801,
    province_code: 77,
    name: "เมืองบึงกาฬ หอคำ บึงกาฬ 38000",
    name_th: "เมืองบึงกาฬ หอคำ บึงกาฬ 38000",
    name_en: "Mueang Bueng Kan, Ho Kham, buogkan, 38000",
  },
  {
    id: 380201,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "ซาง",
    subdistrictEn: "Sang",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา ซาง บึงกาฬ 38150",
    name_th: "เซกา ซาง บึงกาฬ 38150",
    name_en: "Seka, Sang, buogkan, 38150",
  },
  {
    id: 380202,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "เซกา",
    subdistrictEn: "Seka",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา เซกา บึงกาฬ 38150",
    name_th: "เซกา เซกา บึงกาฬ 38150",
    name_en: "Seka, Seka, buogkan, 38150",
  },
  {
    id: 380203,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "ท่ากกแดง",
    subdistrictEn: "Tha Kok Daeng",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา ท่ากกแดง บึงกาฬ 38150",
    name_th: "เซกา ท่ากกแดง บึงกาฬ 38150",
    name_en: "Seka, Tha Kok Daeng, buogkan, 38150",
  },
  {
    id: 380204,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "ท่าสะอาด",
    subdistrictEn: "Tha Sa-at",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา ท่าสะอาด บึงกาฬ 38150",
    name_th: "เซกา ท่าสะอาด บึงกาฬ 38150",
    name_en: "Seka, Tha Sa-at, buogkan, 38150",
  },
  {
    id: 380205,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "น้ำจั้น",
    subdistrictEn: "Nam Chan",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา น้ำจั้น บึงกาฬ 38150",
    name_th: "เซกา น้ำจั้น บึงกาฬ 38150",
    name_en: "Seka, Nam Chan, buogkan, 38150",
  },
  {
    id: 380206,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "บ้านต้อง",
    subdistrictEn: "Ban Tong",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา บ้านต้อง บึงกาฬ 38150",
    name_th: "เซกา บ้านต้อง บึงกาฬ 38150",
    name_en: "Seka, Ban Tong, buogkan, 38150",
  },
  {
    id: 380207,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "ป่งไฮ",
    subdistrictEn: "Pong Hai",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา ป่งไฮ บึงกาฬ 38150",
    name_th: "เซกา ป่งไฮ บึงกาฬ 38150",
    name_en: "Seka, Pong Hai, buogkan, 38150",
  },
  {
    id: 380208,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "โสกก่าม",
    subdistrictEn: "Sok Kam",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา โสกก่าม บึงกาฬ 38150",
    name_th: "เซกา โสกก่าม บึงกาฬ 38150",
    name_en: "Seka, Sok Kam, buogkan, 38150",
  },
  {
    id: 380209,
    districtTh: "เซกา",
    districtEn: "Seka",
    subdistrictTh: "หนองทุ่ม",
    subdistrictEn: "Nong Thum",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38150,
    district_code: 3802,
    province_code: 77,
    name: "เซกา หนองทุ่ม บึงกาฬ 38150",
    name_th: "เซกา หนองทุ่ม บึงกาฬ 38150",
    name_en: "Seka, Nong Thum, buogkan, 38150",
  },
  {
    id: 380301,
    districtTh: "โซ่พิสัย",
    districtEn: "So Phisai",
    subdistrictTh: "คำแก้ว",
    subdistrictEn: "Kham Kaeo",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38170,
    district_code: 3803,
    province_code: 77,
    name: "โซ่พิสัย คำแก้ว บึงกาฬ 38170",
    name_th: "โซ่พิสัย คำแก้ว บึงกาฬ 38170",
    name_en: "So Phisai, Kham Kaeo, buogkan, 38170",
  },
  {
    id: 380302,
    districtTh: "โซ่พิสัย",
    districtEn: "So Phisai",
    subdistrictTh: "โซ่",
    subdistrictEn: "So",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38170,
    district_code: 3803,
    province_code: 77,
    name: "โซ่พิสัย โซ่ บึงกาฬ 38170",
    name_th: "โซ่พิสัย โซ่ บึงกาฬ 38170",
    name_en: "So Phisai, So, buogkan, 38170",
  },
  {
    id: 380303,
    districtTh: "โซ่พิสัย",
    districtEn: "So Phisai",
    subdistrictTh: "ถ้ำเจริญ",
    subdistrictEn: "Tham Charoen",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38170,
    district_code: 3803,
    province_code: 77,
    name: "โซ่พิสัย ถ้ำเจริญ บึงกาฬ 38170",
    name_th: "โซ่พิสัย ถ้ำเจริญ บึงกาฬ 38170",
    name_en: "So Phisai, Tham Charoen, buogkan, 38170",
  },
  {
    id: 380304,
    districtTh: "โซ่พิสัย",
    districtEn: "So Phisai",
    subdistrictTh: "บัวตูม",
    subdistrictEn: "Bua Tum",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38170,
    district_code: 3803,
    province_code: 77,
    name: "โซ่พิสัย บัวตูม บึงกาฬ 38170",
    name_th: "โซ่พิสัย บัวตูม บึงกาฬ 38170",
    name_en: "So Phisai, Bua Tum, buogkan, 38170",
  },
  {
    id: 380305,
    districtTh: "โซ่พิสัย",
    districtEn: "So Phisai",
    subdistrictTh: "ศรีชมภู",
    subdistrictEn: "Si Chomphu",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38170,
    district_code: 3803,
    province_code: 77,
    name: "โซ่พิสัย ศรีชมภู บึงกาฬ 38170",
    name_th: "โซ่พิสัย ศรีชมภู บึงกาฬ 38170",
    name_en: "So Phisai, Si Chomphu, buogkan, 38170",
  },
  {
    id: 380306,
    districtTh: "โซ่พิสัย",
    districtEn: "So Phisai",
    subdistrictTh: "หนองพันทา",
    subdistrictEn: "Nong Phan Tha",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38170,
    district_code: 3803,
    province_code: 77,
    name: "โซ่พิสัย หนองพันทา บึงกาฬ 38170",
    name_th: "โซ่พิสัย หนองพันทา บึงกาฬ 38170",
    name_en: "So Phisai, Nong Phan Tha, buogkan, 38170",
  },
  {
    id: 380307,
    districtTh: "โซ่พิสัย",
    districtEn: "So Phisai",
    subdistrictTh: "เหล่าทอง",
    subdistrictEn: "Lao Thong",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38170,
    district_code: 3803,
    province_code: 77,
    name: "โซ่พิสัย เหล่าทอง บึงกาฬ 38170",
    name_th: "โซ่พิสัย เหล่าทอง บึงกาฬ 38170",
    name_en: "So Phisai, Lao Thong, buogkan, 38170",
  },
  {
    id: 380401,
    districtTh: "พรเจริญ",
    districtEn: "Phon Charoen",
    subdistrictTh: "ดอนหญ้านาง",
    subdistrictEn: "Don Ya Nang",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38180,
    district_code: 3804,
    province_code: 77,
    name: "พรเจริญ ดอนหญ้านาง บึงกาฬ 38180",
    name_th: "พรเจริญ ดอนหญ้านาง บึงกาฬ 38180",
    name_en: "Phon Charoen, Don Ya Nang, buogkan, 38180",
  },
  {
    id: 380402,
    districtTh: "พรเจริญ",
    districtEn: "Phon Charoen",
    subdistrictTh: "ป่าแฝก",
    subdistrictEn: "Pa Faek",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38180,
    district_code: 3804,
    province_code: 77,
    name: "พรเจริญ ป่าแฝก บึงกาฬ 38180",
    name_th: "พรเจริญ ป่าแฝก บึงกาฬ 38180",
    name_en: "Phon Charoen, Pa Faek, buogkan, 38180",
  },
  {
    id: 380403,
    districtTh: "พรเจริญ",
    districtEn: "Phon Charoen",
    subdistrictTh: "พรเจริญ",
    subdistrictEn: "Phon Charoen",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38180,
    district_code: 3804,
    province_code: 77,
    name: "พรเจริญ พรเจริญ บึงกาฬ 38180",
    name_th: "พรเจริญ พรเจริญ บึงกาฬ 38180",
    name_en: "Phon Charoen, Phon Charoen, buogkan, 38180",
  },
  {
    id: 380404,
    districtTh: "พรเจริญ",
    districtEn: "Phon Charoen",
    subdistrictTh: "วังชมภู",
    subdistrictEn: "Wang Chomphu",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38180,
    district_code: 3804,
    province_code: 77,
    name: "พรเจริญ วังชมภู บึงกาฬ 38180",
    name_th: "พรเจริญ วังชมภู บึงกาฬ 38180",
    name_en: "Phon Charoen, Wang Chomphu, buogkan, 38180",
  },
  {
    id: 380405,
    districtTh: "พรเจริญ",
    districtEn: "Phon Charoen",
    subdistrictTh: "ศรีชมภู",
    subdistrictEn: "Si Chomphu",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38180,
    district_code: 3804,
    province_code: 77,
    name: "พรเจริญ ศรีชมภู บึงกาฬ 38180",
    name_th: "พรเจริญ ศรีชมภู บึงกาฬ 38180",
    name_en: "Phon Charoen, Si Chomphu, buogkan, 38180",
  },
  {
    id: 380406,
    districtTh: "พรเจริญ",
    districtEn: "Phon Charoen",
    subdistrictTh: "ศรีสำราญ",
    subdistrictEn: "Si Samran",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38180,
    district_code: 3804,
    province_code: 77,
    name: "พรเจริญ ศรีสำราญ บึงกาฬ 38180",
    name_th: "พรเจริญ ศรีสำราญ บึงกาฬ 38180",
    name_en: "Phon Charoen, Si Samran, buogkan, 38180",
  },
  {
    id: 380407,
    districtTh: "พรเจริญ",
    districtEn: "Phon Charoen",
    subdistrictTh: "หนองหัวช้าง",
    subdistrictEn: "Nong Hua Chang",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38180,
    district_code: 3804,
    province_code: 77,
    name: "พรเจริญ หนองหัวช้าง บึงกาฬ 38180",
    name_th: "พรเจริญ หนองหัวช้าง บึงกาฬ 38180",
    name_en: "Phon Charoen, Nong Hua Chang, buogkan, 38180",
  },
  {
    id: 380501,
    districtTh: "ศรีวิไล",
    districtEn: "Si Wilai",
    subdistrictTh: "ชุมภูพร",
    subdistrictEn: "Chumphu Phon",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38210,
    district_code: 3805,
    province_code: 77,
    name: "ศรีวิไล ชุมภูพร บึงกาฬ 38210",
    name_th: "ศรีวิไล ชุมภูพร บึงกาฬ 38210",
    name_en: "Si Wilai, Chumphu Phon, buogkan, 38210",
  },
  {
    id: 380502,
    districtTh: "ศรีวิไล",
    districtEn: "Si Wilai",
    subdistrictTh: "นาสะแบง",
    subdistrictEn: "Na Sabaeng",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38210,
    district_code: 3805,
    province_code: 77,
    name: "ศรีวิไล นาสะแบง บึงกาฬ 38210",
    name_th: "ศรีวิไล นาสะแบง บึงกาฬ 38210",
    name_en: "Si Wilai, Na Sabaeng, buogkan, 38210",
  },
  {
    id: 380503,
    districtTh: "ศรีวิไล",
    districtEn: "Si Wilai",
    subdistrictTh: "นาสิงห์",
    subdistrictEn: "Na Sing",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38210,
    district_code: 3805,
    province_code: 77,
    name: "ศรีวิไล นาสิงห์ บึงกาฬ 38210",
    name_th: "ศรีวิไล นาสิงห์ บึงกาฬ 38210",
    name_en: "Si Wilai, Na Sing, buogkan, 38210",
  },
  {
    id: 380504,
    districtTh: "ศรีวิไล",
    districtEn: "Si Wilai",
    subdistrictTh: "นาแสง",
    subdistrictEn: "Na Saeng",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38210,
    district_code: 3805,
    province_code: 77,
    name: "ศรีวิไล นาแสง บึงกาฬ 38210",
    name_th: "ศรีวิไล นาแสง บึงกาฬ 38210",
    name_en: "Si Wilai, Na Saeng, buogkan, 38210",
  },
  {
    id: 380505,
    districtTh: "ศรีวิไล",
    districtEn: "Si Wilai",
    subdistrictTh: "ศรีวิไล",
    subdistrictEn: "Si Wilai",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38210,
    district_code: 3805,
    province_code: 77,
    name: "ศรีวิไล ศรีวิไล บึงกาฬ 38210",
    name_th: "ศรีวิไล ศรีวิไล บึงกาฬ 38210",
    name_en: "Si Wilai, Si Wilai, buogkan, 38210",
  },
  {
    id: 380601,
    districtTh: "บึงโขงหลง",
    districtEn: "Bueng Khong Long",
    subdistrictTh: "ดงบัง",
    subdistrictEn: "Dong Bang",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38220,
    district_code: 3806,
    province_code: 77,
    name: "บึงโขงหลง ดงบัง บึงกาฬ 38220",
    name_th: "บึงโขงหลง ดงบัง บึงกาฬ 38220",
    name_en: "Bueng Khong Long, Dong Bang, buogkan, 38220",
  },
  {
    id: 380602,
    districtTh: "บึงโขงหลง",
    districtEn: "Bueng Khong Long",
    subdistrictTh: "ท่าดอกคำ",
    subdistrictEn: "Tha Dok Kham",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38220,
    district_code: 3806,
    province_code: 77,
    name: "บึงโขงหลง ท่าดอกคำ บึงกาฬ 38220",
    name_th: "บึงโขงหลง ท่าดอกคำ บึงกาฬ 38220",
    name_en: "Bueng Khong Long, Tha Dok Kham, buogkan, 38220",
  },
  {
    id: 380603,
    districtTh: "บึงโขงหลง",
    districtEn: "Bueng Khong Long",
    subdistrictTh: "บึงโขงหลง",
    subdistrictEn: "Bueng Khong Long",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38220,
    district_code: 3806,
    province_code: 77,
    name: "บึงโขงหลง บึงโขงหลง บึงกาฬ 38220",
    name_th: "บึงโขงหลง บึงโขงหลง บึงกาฬ 38220",
    name_en: "Bueng Khong Long, Bueng Khong Long, buogkan, 38220",
  },
  {
    id: 380604,
    districtTh: "บึงโขงหลง",
    districtEn: "Bueng Khong Long",
    subdistrictTh: "โพธิ์หมากแข้ง",
    subdistrictEn: "Pho Mak Khaeng",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38220,
    district_code: 3806,
    province_code: 77,
    name: "บึงโขงหลง โพธิ์หมากแข้ง บึงกาฬ 38220",
    name_th: "บึงโขงหลง โพธิ์หมากแข้ง บึงกาฬ 38220",
    name_en: "Bueng Khong Long, Pho Mak Khaeng, buogkan, 38220",
  },
  {
    id: 380701,
    districtTh: "ปากคาด",
    districtEn: "Pak Khat",
    subdistrictTh: "นากั้ง",
    subdistrictEn: "Na Kang",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38190,
    district_code: 3807,
    province_code: 77,
    name: "ปากคาด นากั้ง บึงกาฬ 38190",
    name_th: "ปากคาด นากั้ง บึงกาฬ 38190",
    name_en: "Pak Khat, Na Kang, buogkan, 38190",
  },
  {
    id: 380702,
    districtTh: "ปากคาด",
    districtEn: "Pak Khat",
    subdistrictTh: "นาดง",
    subdistrictEn: "Na Dong",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38190,
    district_code: 3807,
    province_code: 77,
    name: "ปากคาด นาดง บึงกาฬ 38190",
    name_th: "ปากคาด นาดง บึงกาฬ 38190",
    name_en: "Pak Khat, Na Dong, buogkan, 38190",
  },
  {
    id: 380703,
    districtTh: "ปากคาด",
    districtEn: "Pak Khat",
    subdistrictTh: "โนนศิลา",
    subdistrictEn: "Non Sila",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38190,
    district_code: 3807,
    province_code: 77,
    name: "ปากคาด โนนศิลา บึงกาฬ 38190",
    name_th: "ปากคาด โนนศิลา บึงกาฬ 38190",
    name_en: "Pak Khat, Non Sila, buogkan, 38190",
  },
  {
    id: 380704,
    districtTh: "ปากคาด",
    districtEn: "Pak Khat",
    subdistrictTh: "ปากคาด",
    subdistrictEn: "Pak Khat",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38190,
    district_code: 3807,
    province_code: 77,
    name: "ปากคาด ปากคาด บึงกาฬ 38190",
    name_th: "ปากคาด ปากคาด บึงกาฬ 38190",
    name_en: "Pak Khat, Pak Khat, buogkan, 38190",
  },
  {
    id: 380705,
    districtTh: "ปากคาด",
    districtEn: "Pak Khat",
    subdistrictTh: "สมสนุก",
    subdistrictEn: "Som Sanuk",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38190,
    district_code: 3807,
    province_code: 77,
    name: "ปากคาด สมสนุก บึงกาฬ 38190",
    name_th: "ปากคาด สมสนุก บึงกาฬ 38190",
    name_en: "Pak Khat, Som Sanuk, buogkan, 38190",
  },
  {
    id: 380706,
    districtTh: "ปากคาด",
    districtEn: "Pak Khat",
    subdistrictTh: "หนองยอง",
    subdistrictEn: "Nong Yong",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38190,
    district_code: 3807,
    province_code: 77,
    name: "ปากคาด หนองยอง บึงกาฬ 38190",
    name_th: "ปากคาด หนองยอง บึงกาฬ 38190",
    name_en: "Pak Khat, Nong Yong, buogkan, 38190",
  },
  {
    id: 380801,
    districtTh: "บุ่งคล้า",
    districtEn: "Bung Khla",
    subdistrictTh: "โคกกว้าง",
    subdistrictEn: "Khok Kwang",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3808,
    province_code: 77,
    name: "บุ่งคล้า โคกกว้าง บึงกาฬ 38000",
    name_th: "บุ่งคล้า โคกกว้าง บึงกาฬ 38000",
    name_en: "Bung Khla, Khok Kwang, buogkan, 38000",
  },
  {
    id: 380802,
    districtTh: "บุ่งคล้า",
    districtEn: "Bung Khla",
    subdistrictTh: "บุ่งคล้า",
    subdistrictEn: "Bung Khla",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3808,
    province_code: 77,
    name: "บุ่งคล้า บุ่งคล้า บึงกาฬ 38000",
    name_th: "บุ่งคล้า บุ่งคล้า บึงกาฬ 38000",
    name_en: "Bung Khla, Bung Khla, buogkan, 38000",
  },
  {
    id: 380803,
    districtTh: "บุ่งคล้า",
    districtEn: "Bung Khla",
    subdistrictTh: "หนองเดิน",
    subdistrictEn: "Nong Doen",
    provinceTh: "บึงกาฬ",
    provinceEn: "buogkan",
    zipcode: 38000,
    district_code: 3808,
    province_code: 77,
    name: "บุ่งคล้า หนองเดิน บึงกาฬ 38000",
    name_th: "บุ่งคล้า หนองเดิน บึงกาฬ 38000",
    name_en: "Bung Khla, Nong Doen, buogkan, 38000",
  },
];
