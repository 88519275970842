import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Col, Row, Card, Tabs } from "antd";
import { tabsReturnCourseState } from "@tools/recoil/recoil-atom";
import Return from "./return/Return";
import History from "./history/History";

const ReturnCourse = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const [tabsReturnCourse, setTabsReturnCourse] = useRecoilState(
    tabsReturnCourseState
  );

  const tabsCallback = (key: string) => {
    setTabsReturnCourse(key);
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("returnCourse.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("nursery")}</b>
          </span>
        </Col>
        <Col lg={2} className="justify-col"></Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card">{t("allNursery")}</b>}
        >
          <Tabs
            className="tab-printdraw f-normal"
            defaultActiveKey={tabsReturnCourse}
            onChange={tabsCallback}
          >
            <TabPane tab={t("returnCourse.title")} key="1">
              <div className="mt-1">{<Return />}</div>
            </TabPane>
            <TabPane tab={t("record")} key="2">
              <div className="mt-1">
                <History />
              </div>
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </div>
  );
};

export default ReturnCourse;
