import { IItem } from "@interface/IOrder.interface";
import { Table } from "antd";
import { productName } from "@tools/util";
import { useTranslation } from "react-i18next";

type Props = {
  record: any;
  dataSource: any;
};

const ChildrenTable = ({ record, dataSource }: Props) => {
  const { t, i18n } = useTranslation();
  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "5%",
      render: (_: any, data: any, index: number) => {
        return <div key={data?.key}>{index + 1}</div>;
      },
    },

    {
      title: t("examinationName"),
      dataIndex: "product",
      key: "product",
      width: "10%",
      render: (product: IItem) => {
        return (
          <div>
            {productName(i18n.language, product?.nameTh, product?.nameEn)}
          </div>
        );
      },
    },

    {
      title: t("type"),
      dataIndex: "product",
      key: "product",
      render: (product: IItem) => {
        return (
          <div>
            {i18n.language === "th"
              ? product?.group?.nameTh
              : product?.group?.nameEn}
          </div>
        );
      },
    },
    {
      title: t("amount"),
      dataIndex: "product",
      key: "product",
      render: (_: any, data: any) => {
        return <div key={data?.key}>{data?.qty}</div>;
      },
    },

    {
      title: t("properties"),
      dataIndex: "product",
      key: "product",
      render: (product: any, data: any) => {
        return <div key={data?.key}>{product?.description}</div>;
      },
    },

    {
      title: t("howUse"),
      dataIndex: "product",
      key: "product",
      render: (product: any, data: any) => {
        return <div key={data?.key}>{product?.usage}</div>;
      },
    },
  ];

  return (
    <div>
      <Table
        className="table-order-list "
        columns={columns}
        dataSource={dataSource[record]?.children1?.[0]?.children}
        rowKey={"key"}
        expandRowByClick={true}
        showSorterTooltip={true}
        pagination={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default ChildrenTable;
