import ReactToPrint, { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { Col, Row } from "antd";
import { useGetVisitsByID } from "@services/api/visits";
import { RouteComponentProps } from "react-router-dom";
import { useGetVisitOrderHistory } from "@services/api/visits";
import { useHistory, useRouteMatch } from "react-router-dom";
import Header from "./Header";
import ReportPhysiotherapy from "@components/report_physiotherapy/ReportPhysiotherapy";

const ReportPhysicianOrder = () => {
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const { data } = useGetVisitsByID(visitID);
  const pageStyle = `  @page { size:A4;
        margin: 40px;}`;
  const printReportRef = useRef(null);
  const history = useHistory<RouteComponentProps["history"]>();
  const ordersList = useGetVisitOrderHistory(visitID);

  const handlePrint = useReactToPrint({
    content: () => printReportRef.current,
    pageStyle: pageStyle,
  });

  return (
    <div>
      <Header history={history} handlePrint={handlePrint} />
      <ReactToPrint content={() => printReportRef.current} />
      <div className="set-layout ">
        <div className="document-body ">
          <Row>
            <Col lg={24} className="display-center ">
              <div className="document-bill">
                <Row>
                  <Col lg={24}>
                    <ReportPhysiotherapy
                      refData={printReportRef}
                      visitsByID={data}
                      ordersList={ordersList?.data?.result?.[0]}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default ReportPhysicianOrder;
