import { useMainImageCanvasGroup } from "@provider/image_canvas";
import LeftMarker from "@assets/img/LeftCanvas.png";
import ImageCanvas from "@components/drawing_canvas/ImageCanvas";

const Left = () => {
  const { imageLeftCanvasRef, appointmentsByID, visitsByID, SOAP } =
    useMainImageCanvasGroup();
  return (
    <div>
      <ImageCanvas
        imageCanvasRef={imageLeftCanvasRef}
        image={
          SOAP
            ? visitsByID?.visitMarker?.soapMarker3?.[0]?.image || LeftMarker
            : `${
                appointmentsByID?.visit?.visitMarker?.doctorMarker3?.[0]
                  ?.image ||
                visitsByID?.visitMarker?.doctorMarker3?.[0]?.image ||
                LeftMarker
              }`
        }
      />
    </div>
  );
};

export default Left;
