import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Row } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import TableOrderList from "./OrderList";
import { IItem } from "@interface/IOrder.interface";

type Props = {
  orderList?: IItem[];
  title: string;
  disable: boolean;
};

const CreateOrderItem = ({ orderList, title, disable }: Props) => {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(!!orderList?.length);
    //eslint-disable-next-line
  }, [orderList]);

  const viewDetail = () => {
    setOpen(!open);
  };

  return (
    <div className="mt-2">
      <Card
        className="comment-card mt-2 card-out-padding "
        extra={
          <React.Fragment>
            <Row>
              <Col className="f-normal col-start t-dark-blue">
                <span className="f-bold mr-05 cursor " onClick={viewDetail}>
                  {`${t("orderList")} ${title}`}{" "}
                </span>

                <span className="ml-05">
                  {React.createElement(open ? UpOutlined : DownOutlined, {
                    onClick: viewDetail,
                  })}
                </span>
              </Col>
            </Row>
          </React.Fragment>
        }
      >
        <div className={open ? "open" : "close"}>
          <TableOrderList orderList={orderList} disable={disable} />
        </div>
      </Card>
    </div>
  );
};

export default CreateOrderItem;
