const login = "/login";
export const LOGIN = {
  title: login,
};
const dashboard = "/dashboard";
export const DASHBOARD = {
  title: dashboard,
};
export const reportDrugQ = "/ReportDrugQ";

const opd = "/profile/opd";
export const OPD = {
  title: opd,
  basicInfo: `${opd}/patient-basic-info`,
  otherInfo: `${opd}/patient-other-info`,
  contractingCompany: `${opd}/contracting-company`,
  consent: `${opd}/patient-profile/nursing-history/consent`,
  profile: `${opd}/patient-profile`,
  compare: `${opd}/patient-profile/nursing-history/lab-interface/compare`,
  bookToday: `${opd}/book-today`,
  medication: `${opd}/patient-profile/nursing-history/medication`,
  printCompare: `${opd}/patient-profile/nursing-history/lab-interface/compare/print`,
  medicalReport: `${opd}/patient-profile/nursing-history/medical-report`,
  bookInAdvance: `${opd}/book-in-advance`,
  consentDetail: `${opd}/patient-profile/nursing-history/consent`,
  medicalSummary: `${opd}/patient-profile/nursing-history/medical-summary`,
  UpLoadFileMedicalSummary: `${opd}/patient-profile/nursing-history/medical-summary/upload-file`,
  UpLoadFileRadiologyReport: `${opd}/patient-profile/nursing-history/radiology-report/upload-file`,
  UpLoadFileMedicalReport: `${opd}/patient-profile/nursing-history/medical-report/upload-file`,
  nursingHistory: `${opd}/patient-profile/nursing-history`,
  physicianOrder: `${opd}/patient-profile/nursing-history/physician-order`,
  treatmentRecord: `${opd}/patient-profile/nursing-history/treatment-record`,
  vitalSignReport: `${opd}/patient-profile/nursing-history/vital-sign`,
  detailLabResult: `${opd}/patient-profile/nursing-history/lab-interface/detail/lab-result`,
  radiologyReport: `${opd}/patient-profile/nursing-history/radiology-report`,
  leadingSymptoms: `${opd}/leading-symptoms`,
  importantSymptoms: `${opd}/patient-profile/important-symptoms`,
  detailLabInterface: `${opd}/patient-profile/nursing-history/lab-interface`,
  printDetailLabResult: `${opd}/patient-profile/nursing-history/lab-interface/detail/lab-result/print`,
  physicalTherapyRecord: `${opd}/patient-profile/nursing-history/physical-therapy-record`,
};

const notification = "/booking/notification";
export const NOTIFICATION = {
  title: notification,
  create: `${notification}/create`,
};

export const overviewMedicalRoom = "/booking/medical-room-reservation/overview";
export const slotsOverview = "/profile/queue/slots-overview";
export const overviewBookingAnotherRoom = "/booking/book-another-room/overview";

export const queue = "/profile/queue";

const transfer = "/profile/transfer";
export const TRANSFER = {
  title: `${transfer}`,
  detail: `${transfer}/detail`,
};

export const medicalRoomReservation = "/booking/medical-room-reservation";
export const bookAnotherRoom = "/booking/book-another-room";

export const doctorroom = "/doctorroom";
export const createOrder = "/doctorroom/create-order";
export const commentDoctorRoom = "/doctorroom/comment";
export const confirmOrder = "/physician-assistant/confirm-order";
export const confirmOrderList = "/physician-assistant/confirm-order/order-list";
export const executeOrder = "/physician-assistant/execute-order";
export const executeOrderList = "/physician-assistant/execute-order/order-list";
export const documentExecuteOrder =
  "/physician-assistant/execute-order/document";

const inchart = "/nurse-room/inchart";
export const INCHART = {
  title: inchart,
  waitDetail: `${inchart}/wait-detail`,
  betweenDetail: `${inchart}/between-detail`,
};

const returnCourse = "/financial-room/return-course";
export const RETURN_COURSE = {
  title: returnCourse,
  details: `${returnCourse}/detail`,
  historyList: `${returnCourse}/history-list`,
  report: `${returnCourse}/report`,
};

const lab = "/nurse-room/lab";
export const LAB = {
  title: lab,
  detail: `${lab}/detail`,
  historyList: `${lab}/history-list-lab`,
  historyListDetail: `${lab}/history-list-lab/detail`,
  respondDetail: `${lab}/respond/detail`,
};

const interfaceLab = "/nurse-room/interface-lab";
export const INTERFACE_LAB = {
  title: interfaceLab,
  detail: `${interfaceLab}/detail`,
  listLab: `${interfaceLab}/list`,
  compare: `${interfaceLab}/list/compare-lab`,
  printLabel: `${interfaceLab}/print-label/visit/`,
  printLabelDetail: `${interfaceLab}/list/detail/print-label/visit/`,
  printCompare: `${interfaceLab}/list/compare-lab/print`,
  interfaceDetails: `${interfaceLab}/list/detail`,
  labResultsDetail: `${interfaceLab}/list/detail/lab-result`,
  printLabResultsDetail: `${interfaceLab}/list/detail/lab-result/print`,
};

export const procedure = "/nurse-room/procedure";
export const procedureDetail = "/nurse-room/procedure/detail";
export const procedureReport = "/nurse-room/procedure/detail/report";
export const reportProcedureVisitAll = "/report-procedure/visit-all";
export const reportProcedureDetail = "/report-procedure/visit-all/detail";
export const painManagement = "/pain-management";
export const painManagementDetail = "/pain-management/detail";
export const dispenseMedicine = "/pharmacy-room/dispense-medicine";
export const dispenseMedicineDetail = "/pharmacy-room/dispense-medicine/detail";
export const reportDrug = "/pharmacy-room/dispense-medicine/report-drug";
export const returnMedicine = "/pharmacy-room/return-medicine";
export const returnMedicineDetail = "/pharmacy-room/return-medicine/detail";
export const reportDrugMedicineDetail = "/pharmacy-room/report-drug/detail";
export const pay = "/financial-room/pay";

const payListTab = "/financial-room/pay/list-tabs";
export const PAY_LIST_TAB = {
  title: payListTab,
  reportBill: `${payListTab}/billing-note/report`,
  reportCreateBill: `${payListTab}/create-billing-notes/report`,
  editReceipt: `${payListTab}/receipt`,
  reportInvoice: `${payListTab}/invoice/report`,
  reportReceipt: `${payListTab}/receipt/report`,
  billCreateInvoice: `${payListTab}/billing-notes/create-invoice`,
  beforeBillCreateBill: `${payListTab}/before-bill/create-billing-notes`,
  editBilling: `${payListTab}/billing-note`,
  invoiceCreateReceipt: `${payListTab}/invoice`,
  editInvoice: `${payListTab}/invoice`,
};

export const setting = "/setting";
export const SETTING = {
  title: `${setting}/general`,
  agent: `${setting}/agent/create`,
  wareHouse: `${setting}/ware-house`,
  agentManage: `${setting}/agent`,
  contractCompany: `${setting}/contract-company/create`,
  branchManagement: `${setting}/branch-management`,
  managePickUpGoods: `${setting}/ware-house/manage/pick-up-goods`,
  manageBounceGoods: `${setting}/ware-house/manage/bounce-goods`,
  createPurchaseOrder: `${setting}/ware-house/create/purchase-order`,
  wareHouseUploadFile: `${setting}/ware-house/upload-file`,
  managePurchaseOrder: `${setting}/ware-house/manage/purchase-order`,
  contractCompanyManage: `${setting}/contract-company`,
  manageBranchManagement: `${setting}/branch-management/manage`,
};

export const payDetail = "/financial-room/pay/detail";
export const transferCourse = "/financial-room/transfer-course";
export const transferCourseDetail = "/financial-room/transfer-course/detail";
export const signCourse = "/financial-room/sign-course";
export const signCourseDetail = "/financial-room/sign-course/detail";
export const slotsDetail = "/profile/opd/slots-detail";
export const general = "/setting/general";
export const createPrefix = "/setting/prefix/create";
export const createNationality = "/setting/nationality/create";
export const createCitizenship = "/setting/citizenship/create";
export const createCountry = "/setting/country/create";
export const correctGeneral = "/setting/correct/general";

const preRegistration = "/profile/pre-registration";
export const PRE_REGISTRATION = {
  title: preRegistration,
  event: `${preRegistration}/manage/event`,
  detail: `${preRegistration}/event`,
};

const preRegistrationMark = "/marketing/pre-registration";
export const PRE_REGISTRATIONMARK = {
  title: preRegistrationMark,
  event: `${preRegistrationMark}/manage/event`,
  detail: `${preRegistrationMark}/event`,
  patientHistory: `${preRegistrationMark}/patient-history/event`,
  reportHistory: `${preRegistrationMark}/manage/report/history`,
};

const coupon = "/marketing/coupon";
export const COUPON = {
  title: coupon,
  create: `${coupon}/manage/create`,
  detail: `${coupon}/detail`,
};

const promotion = "/marketing/promotion";
export const PROMOTION = {
  title: promotion,
  create: `${promotion}/manage/create`,
  detail: `${promotion}/detail`,
  duplicate: `${promotion}/duplicate`,
};

const event = "/marketing/event";
export const EVENT = {
  title: event,
  create: `${event}/manage/create`,
  detail: `${event}/detail`,
  view: `${event}/view/pre-register`,
};

const manageCourses = "/marketing/manage-courses";
export const MANAGE_COURSES = {
  title: manageCourses,
  create: `${manageCourses}/create`,
  update: `${manageCourses}/update`,
};

const userManage = "/user-manage";
export const USER_MANAGE = {
  title: userManage,
  createUser: `${userManage}/create-user`,
  updateUser: `${userManage}/update-user`,
  createRole: `${userManage}/create-role`,
  updateRole: `${userManage}/update-role`,
  createRoom: `${userManage}/create-room`,
  updateRoom: `${userManage}/update-room`,
  createUserRoom: `${userManage}/create-user-room`,
  updateUserRoom: `${userManage}/update-user-room`,
};

const medicalDocument = "/medical-document";
export const MEDICAL_DOCUMENT = {
  title: medicalDocument,
  create: `${medicalDocument}/create`,
  type: `${medicalDocument}/type`,
  createType: `${medicalDocument}/type/create`,
};

const reportSummary = "/report-summary";
export const REPORT_SUMMARY = {
  title: reportSummary,
  productReport: `${reportSummary}/product-report`,
  operatingRoomReport: `${reportSummary}/operating-room-report`,
  purchaseReport: `${reportSummary}/purchase-report`,
  agentReport: `${reportSummary}/agent-report`,
  //new
  receiptReport: `${reportSummary}/receipt-report`,
  invoiceReport: `${reportSummary}/invoice-report`,
  patientReport: `${reportSummary}/patient-report`,
  branchReport: `${reportSummary}/branch-report`,
  productPurchaseReport: `${reportSummary}/product/purchase-report`,
  productPurchaseHnReport: `${reportSummary}/product/purchase/hn-report`,
  productProcedurePurchaseReport: `${reportSummary}/product-procedure/purchase-report`,
  productProcedureReport: `${reportSummary}/product-procedure-report`,
  productPromotionPurchaseReport: `${reportSummary}/product-promotion/purchase-report`,
};
