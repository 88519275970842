import { EVENT } from "@assets/data/pathRouter";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Card, Col, Row, Tabs } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import React from "react";
import OurBranch from "./our_branch/OurBranch";
import RoleContext from "@provider/RoleProvider";
import BranchAnother from "./branch_another/BranchAnother";

const Event = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "EVENT";
  });

  const createEvent = () => {
    history.push(`${EVENT.create}`);
  };

  const view = () => {
    history.push(EVENT.view);
  };

  return (
    <div>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <div>
            <h1>{t("event.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("marketing.title")}</b>
          </span>
        </Col>
        <Col lg={2} className="justify-col">
          <Button className="btn-create " type="primary" onClick={view}>
            ดูกิจกรรม
          </Button>

          {medicalRecord?.[0]?.isEdit ? (
            <Button
              className="btn-create ml-1"
              type="primary"
              onClick={createEvent}
            >
              <PlusOutlined className="icons-size" /> {t("event.create")}
            </Button>
          ) : null}
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card ">{t("event.all")}</b>}
        >
          <Tabs
            className="tab-printdraw f-normal"
            defaultActiveKey="1"
            // onChange={tabsCallback}
          >
            <TabPane tab="กิจกรรม" key="1">
              <div className="mt-1">
                <OurBranch />
              </div>
            </TabPane>
            <TabPane tab="กิจกรรมสาขาอื่น" key="2">
              <div className="mt-1">
                <BranchAnother />
              </div>
            </TabPane>
          </Tabs>
        </Card>
      </div>
    </div>
  );
};

export default Event;
