import { SETTING } from "@assets/data/pathRouter";
import { FormInstance } from "antd/es/form/Form";
import { FC, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useCreateMedia } from "@services/api/media_object";
import { useUpdateBranch } from "@services/api/branch";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Card, Col, Form, Input, Row } from "antd";
import { useCreateBranch, useGetBranchById } from "@services/api/branch";
import { dataUrlToFile, enterNextInput, _isEmpty } from "@tools/util";
import { address } from "@assets/data/address";
import ImageProfile from "@components/file_space/ImageProfile";
import SelectorAddress from "@components/selector/SelectorAddress";
import { IAddress } from "@assets/data/address.interface";

type Props = {
  branchManageForm: FormInstance;
};

let branchAddress: IAddress[] = [...address];

const Fields: FC<Props> = ({ branchManageForm }) => {
  const { i18n, t } = useTranslation();
  const create = useCreateBranch();
  const update = useUpdateBranch();
  const createMedia = useCreateMedia();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const branchId = match.params.branchId;
  const branchById = useGetBranchById(branchId);

  useEffect(() => {
    const res = branchById?.data?.result[0];
    if (!!res) {
      const date = {
        ...res,
        image: res.image,
        imageId: res.imageId,
        hn: res.hn,
        nameTh: res.nameTh,
        nameEn: res.nameEn,
        code: res.code,
        email: res.email,
        phone: res.phone,
        secondNameTh: res.secondNameTh,
        secondNameEn: res.secondNameEn,
        taxId: res.taxId,
        addressEn: res.addressEn,
        addressTh: res.addressTh,
        subDistrict: branchAddress?.find(
          (item: any) => item?.subdistrictTh === res.subDistrictTh
        ),
        province: branchAddress?.find(
          (item: any) => item?.provinceTh === res.provinceTh
        ),
        district: branchAddress?.find(
          (item: any) => item?.districtTh === res.districtTh
        ),
        zipcode: branchAddress?.find(
          (item: any) => item?.subdistrictTh === res.subDistrictTh
        ),
      };
      branchManageForm.setFieldsValue(date);
    }
    //eslint-disable-next-line
  }, [branchById?.data]);
  const setHomeAddress = (id: number) => {
    const adders: any = branchAddress?.find((item: any) => item?.id === id);
    branchManageForm.setFieldsValue({
      subDistrict: adders,
      province: adders,
      district: adders,
      zipcode: adders,
    });
  };

  const onFinish = (value: any) => {
    const newValue = {
      ...value,
      districtTh: value.district.districtTh,
      districtEn: value.district.districtEn,
      subDistrictTh: value.subDistrict.subdistrictTh,
      subDistrictEn: value.subDistrict.subdistrictEn,
      provinceTh: value.province.provinceTh,
      provinceEn: value.province.provinceEn,
      zipcode: value.zipcode.zipcode,
    };
    if (newValue?.image?.includes("base64")) {
      const file = dataUrlToFile(newValue?.image, "branchImage.png");
      if (file) {
        const formData = new FormData();
        formData?.append("file", file);

        createMedia.mutate(formData, {
          onSuccess: (res) => {
            if (!_isEmpty(branchId)) {
              updateBranch({ ...newValue, imageId: res?.result?.[0]?.id });
            } else {
              createBranch({ ...newValue, imageId: res?.result?.[0]?.id });
            }
          },
          onError: (error) => {
            openNotification({ method: "POST_ERROR", message: String(error) });
          },
        });
      }
    } else {
      if (!_isEmpty(branchId)) {
        updateBranch(newValue);
      } else {
        createBranch({ ...newValue });
      }
    }
  };

  const createBranch = (value: any) => {
    delete value?.image;

    create.mutate(value, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.replace(`${SETTING?.branchManagement}`);
      },
      onError: (error) => {
        openNotification({ method: "POST_ERROR", message: String(error) });
      },
    });
  };

  const updateBranch = (value: any) => {
    delete value?.image;
    update.mutate(
      { data: value, id: branchId },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(`${SETTING?.branchManagement}`);
        },
        onError: (error) => {
          openNotification({ method: "PUT_ERROR", message: String(error) });
        },
      }
    );
  };

  const getFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e?.fileList;
  };

  const getFileName = () => {
    return false;
  };

  return (
    <div>
      <Form
        scrollToFirstError
        name="branchManageForm"
        form={branchManageForm}
        onFinish={onFinish}
        onKeyPress={(e) => {
          enterNextInput(e, "branchManageForm");
        }}
      >
        <Card
          className="card-prefix card-prefix-hd pb-2 "
          extra={
            <b className="fs-16 t-black-light ">
              {t("branchManagement.title")}
            </b>
          }
        >
          <Row gutter={[50, 0]} align="middle" className="pl-2 pr-2">
            <Col span={12}>
              <Form.Item name="image" getValueFromEvent={getFile}>
                <ImageProfile
                  accept=".png,.jpg,.jpeg"
                  form={branchManageForm}
                  name="image"
                  beforeUpload={getFileName}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Row gutter={[14, 14]}>
                <Col span={12}>
                  <div style={{ marginBottom: 10 }}>{`${t("code")}`}</div>
                  <Form.Item name="code" rules={[{ required: true }]}>
                    <Input placeholder={`${t("code")}`} />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <div style={{ marginBottom: 10 }}>{`${t("HN.")}`}</div>
                  <Form.Item name="hn" rules={[{ required: true }]}>
                    <Input placeholder={`${t("hn")}`} />
                  </Form.Item>
                </Col>

                <Col span={24}>{`${t("branchManagement.branchName")}`}</Col>

                <Col span={24}>
                  <Form.Item name="nameTh" rules={[{ required: true }]}>
                    <Input
                      placeholder={`${t("branchManagement.branchNameInputTh")}`}
                    />
                  </Form.Item>{" "}
                </Col>
                <Col span={24}>
                  <Form.Item name="nameEn" rules={[{ required: true }]}>
                    <Input
                      placeholder={`${t("branchManagement.branchNameInputEn")}`}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>{`${t("branchManagement.secondName")}`}</Col>

                <Col span={24}>
                  <Form.Item name="secondNameTh">
                    <Input
                      placeholder={`${t("branchManagement.secondNameInputTh")}`}
                    />
                  </Form.Item>{" "}
                </Col>
                <Col span={24}>
                  <Form.Item name="secondNameEn">
                    <Input
                      placeholder={`${t("branchManagement.secondNameInputEn")}`}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>{`${t("address")}`}</Col>

                <Col span={24}>
                  <Form.Item
                    name="addressTh"
                    rules={[
                      { required: true },
                      // {
                      //   pattern: /^[ก-๏0-9,./\s]+$/,
                      //   message: t('enterThai'),//Please enter the information in letters. Thai language only
                      // }
                    ]}
                  >
                    <Input placeholder={`${t("addressTH")}`} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item
                    name="addressEn"
                    rules={[
                      { required: true },
                      // {
                      //   pattern: /^[a-zA-Z0-9,./\s]+$/,
                      //   message: t('enterEnglish'),//Please enter information in English letters only.
                      // }
                    ]}
                  >
                    <Input placeholder={`${t("addressEN")}`} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="subDistrict" rules={[{ required: true }]}>
                    <SelectorAddress
                      placeholder={`${t("subdistrict")}`}
                      source={branchAddress}
                      // keyName="district"
                      keyName={
                        i18n.language === "th"
                          ? "subdistrictTh"
                          : "subdistrictEn"
                      }
                      keyData={i18n.language === "th" ? "name_th" : "name_en"}
                      keyValue="id"
                      onChange={(e) => {
                        setHomeAddress(e);
                      }}
                    />
                  </Form.Item>
                </Col>

                {/* <Col span={12}>
                  <Form.Item name="district" rules={[{ required: true }]}>
                    <Input placeholder={`${t("district")}`} />
                  </Form.Item>
                </Col> */}

                <Col span={12}>
                  <Form.Item name="district" rules={[{ required: true }]}>
                    <SelectorAddress
                      placeholder={`${t("district")}`}
                      source={branchAddress}
                      keyName={
                        i18n.language === "th" ? "districtTh" : "districtEn"
                      }
                      keyData={i18n.language === "th" ? "name_th" : "name_en"}
                      keyValue="id"
                      onChange={(e) => {
                        setHomeAddress(e);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name="province" rules={[{ required: true }]}>
                    <SelectorAddress
                      placeholder={`${t("province")}`}
                      source={branchAddress}
                      keyName={
                        i18n.language === "th" ? "provinceTh" : "provinceEn"
                      }
                      keyData={i18n.language === "th" ? "name_th" : "name_en"}
                      keyValue="id"
                      onChange={(e) => {
                        setHomeAddress(e);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item name="zipcode">
                    <SelectorAddress
                      placeholder={`${t("zipCode")}`}
                      source={branchAddress}
                      keyName="zipcode"
                      keyData={i18n.language === "th" ? "name_th" : "name_en"}
                      keyValue="id"
                      onChange={(e) => {
                        setHomeAddress(e);
                      }}
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>{`${t("contactInfo")}`}</Col>

                <Col span={12}>
                  <Form.Item
                    name="phone"
                    rules={[
                      {
                        pattern: /^[+]{0}[0-9]{9,10}$/,
                        message: t("pleaseNumber"),
                      },
                    ]}
                  >
                    <Input placeholder={`${t("phoneNumber")}`} />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item name="email" rules={[{ required: true }]}>
                    <Input type="email" placeholder={`${t("email")}`} />
                  </Form.Item>
                </Col>
                <Col span={24}>{`${t("taxId")}`}</Col>
                <Col span={24}>
                  <Form.Item name="taxId" rules={[{ required: true }]}>
                    <Input placeholder={`${t("taxId")}`} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Form>
    </div>
  );
};

export default Fields;
