import React, { Fragment, useEffect, useState } from "react";
import { TextBRHTML } from "@tools/util";
import { IAppointments } from "@interface/IAppointments.interface";
import { IGetDraftOrder } from "@interface/IDraftOrder.interface";
import { useTranslation } from "react-i18next";
import { keyLabInterface } from "@provider/lab_inteface_group";
import { useGetProductLab } from "@services/api/product";
import { Empty, Form, Row } from "antd";
import { commentDoctorRoom } from "@assets/data/pathRouter";
import { IResultLabInterface } from "@interface/IProduct.interface";
import { RouteComponentProps } from "react-router-dom";
import { DRUG_STATUS, ENUM_G6PD } from "@tools/util.enum";
import { useHistory, useRouteMatch } from "react-router-dom";
import { keyNameProductLabInterface } from "@interface/IProduct.interface";
import { Button, Card, Checkbox, Col } from "antd";
import { ENUM_ROOM_SIGNATURE, ORDER_TYPE } from "@tools/util.enum";
import Note from "@components/note_visit/NoteVisit";
import Link from "@assets/img/icon/Link.svg";
import File from "@assets/img/icon/File.svg";
import empty from "@assets/img/empty.svg";
import moment from "moment";
import VitalSign from "@components/vital_sign/VitalSign";
import ViewImage from "@components/drawing_canvas/ViewImage";
import ViewMarker from "@components/marker/view_marker/ViewMarker";
import SymbolView from "@components/symbol/Symbol";
import ModalMarker from "@components/marker/modal_marker/ModalMarker";
import OrderOneDay from "@components/draft_order/order_one_day/OrderOneDay";
import RoleContext from "@provider/RoleProvider";
import ImagePatient from "@components/image/ImagePatient";
import OrderOutType from "@components/draft_order/order_out_type/OrderOutType";
import LabInterface from "../lab_interface/LabInterface";
import EditMedicine from "@components/medicine/Medicine";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import VitalSignModal from "@components/vital_sign/modal/VitalSign";
import ReturnMedicine from "@components/medicine/Medicine";
import OrderContinuous from "@components/draft_order/order_continuous/OrderContinuous";
import RejectGroupOrder from "@components/order/reject/RejectGroupOrder";
import TransferComponent from "@components/transfer/Transfer";
import LabInterfaceGroup from "../lab_interface_group/LabInterfaceGroup";
import DataLabInterfaceOne from "@components/lab_interface/lab_interface_group_one/DataLabInterfaceOne";
import DataLabInterfaceTwo from "@components/lab_interface/lab_interface_group_two/DataLabInterfaceTwo";
import UpdateStatusMedicine from "@components/medicine/Medicine";
import MainPatientByIdProvider from "@provider/patient_by_id";
import MainMedicineDoctorRoomProvider from "@provider/medicine_doctor_room";
const labInterfaceOne = DataLabInterfaceOne();
const labInterfaceTwo = DataLabInterfaceTwo();

const data = { ...labInterfaceOne, ...labInterfaceTwo };

interface IVisible {
  doctorComment: boolean;
  leadingSymptoms: boolean;
  marker: boolean;
}

interface ILabInterface {
  labInterface: { [K in keyNameProductLabInterface]: IResultLabInterface[] };
  setLabInterface: (e: {
    [K in keyNameProductLabInterface]: IResultLabInterface[];
  }) => void;
}

interface IOpen {
  open: boolean;
  setOpen: (e: boolean) => void;
}

type Props = {
  draftOrderList?: IGetDraftOrder;
  appointmentByID?: IAppointments;
  open: IOpen;
  labInterface: ILabInterface;
};

const FormCreateOrder = ({
  draftOrderList,
  appointmentByID,
  open,
  labInterface,
}: Props) => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const appointmentsID = match.params.appointmentsID;
  const patientID = match.params.patientsID;
  const visitID: string = match.params.visitID;
  const [patientList] = Form.useForm();
  const productLabList = useGetProductLab();
  const [important] = Form.useForm();
  const [visible, setVisible] = useState<IVisible>({
    doctorComment: false,
    leadingSymptoms: false,
    marker: false,
  });

  useEffect(() => {
    important.setFieldsValue({
      height: appointmentByID?.patient?.height,
      ...appointmentByID?.visit?.data?.visit,
    });

    const marker = [
      ...(appointmentByID?.visit?.visitMarker?.doctorMarker0 || []),
      ...(appointmentByID?.visit?.visitMarker?.doctorMarker1 || []),
      ...(appointmentByID?.visit?.visitMarker?.doctorMarker2 || []),
      ...(appointmentByID?.visit?.visitMarker?.doctorMarker3 || []),
    ];
    open.setOpen(!!marker.length);

    //eslint-disable-next-line
  }, [appointmentByID]);

  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "MEDICAL";
  });

  useEffect(() => {
    var setLabInterfaceList: {
      [K in keyNameProductLabInterface]?: IResultLabInterface[];
    } = {};

    keyLabInterface.forEach((key: keyNameProductLabInterface) => {
      setLabInterfaceList = {
        ...setLabInterfaceList,
        [key]: data?.[key]?.map((item) => {
          const found = productLabList?.data?.result?.[0]?.[key]?.find(
            (element) => element?.sku === item?.sku
          );
          if (found) {
            return { ...item, ...found, disabled: true };
          } else {
            return { ...item, disabled: false };
          }
        }),
      };
    });

    labInterface?.setLabInterface({
      ...labInterface?.labInterface,
      ...setLabInterfaceList,
    });

    //eslint-disable-next-line
  }, [productLabList?.data]);

  useEffect(() => {
    if (!appointmentByID) return;
    patientList?.setFieldsValue({
      drugAllergy: appointmentByID?.patient?.drugAllergy,
      foodAllergy: appointmentByID?.patient?.foodAllergy,
    });
    // eslint-disable-next-line
  }, [appointmentByID]);

  const createComment = () => {
    history.push(
      `${commentDoctorRoom}/appointments/${appointmentsID}/visit/${visitID}/patient/${patientID}`
    );
  };

  const leadingSymptomsModal = () => {
    setVisible({ ...visible, leadingSymptoms: !visible?.leadingSymptoms });
  };

  const doctorCommentModal = () => {
    setVisible({ ...visible, doctorComment: !visible?.doctorComment });
  };

  const doctorCommentEdit = () => {
    history.push(
      `${commentDoctorRoom}/appointments/${appointmentsID}/visit/${visitID}/patient/${patientID}`
    );
  };

  const openMaker = () => {
    setVisible({ ...visible, marker: !visible?.marker });
  };

  const viewDetail = () => {
    open.setOpen(!open?.open);
  };

  return (
    <MainPatientByIdProvider value={{ patientById: appointmentByID?.patient }}>
      <Card
        className="bg-grey height-body"
        extra={
          <React.Fragment>
            <Row gutter={[14, 14]} className="text-row-profile" align="middle">
              <Col>
                <ImagePatient src={appointmentByID?.patient?.image} />
              </Col>
              <Col>
                <Row>
                  <Col xs={24} lg={24}>
                    {appointmentByID
                      ? i18n.language === "th"
                        ? `${appointmentByID?.patient?.firstNameTh}   ${appointmentByID?.patient?.lastNameTh} `
                        : `${appointmentByID?.patient?.firstNameEn}   ${appointmentByID?.patient?.lastNameEn} `
                      : ``}
                  </Col>

                  <Col xs={24} lg={24}>
                    <div className="code-profile">
                      HN :{" "}
                      {appointmentByID
                        ? `${appointmentByID?.patient?.hn}   `
                        : ``}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginLeft: "auto" }}>
                <div style={{ color: "#FEB139" }}>
                  <SymbolView />
                </div>
                <div
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {appointmentByID?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                    ? "G6PD Deficiency"
                    : null}
                </div>
              </Col>
            </Row>
          </React.Fragment>
        }
      >
        <div className="mb-05">
          <span className=" t-blue fs-18 ">{t("fullList")} </span>
          <span className=" t-grey-light  fs-12 ">
            {t("orderCreationDate")} :{" "}
          </span>
          <span className="t-grey fs-12 ">
            {appointmentByID
              ? `${moment(appointmentByID?.date).format("DD-MM-YYYY")}`
              : ``}
          </span>
        </div>
        <Form name="basicInfoForm" form={patientList}>
          <ImportantInfo />
        </Form>

        <Card
          className="comment-card mt-2 "
          extra={
            <Fragment>
              <Row gutter={[14, 14]} align="middle">
                <Col md={12} lg={12}>
                  {`${t("basicInformation")} / ${t("importantSymptoms")}`}
                </Col>
                <Col md={12} lg={12} className="col-end link-btn">
                  <Button type="link" onClick={leadingSymptomsModal}>
                    {t("seePaintings")}
                  </Button>
                </Col>
              </Row>
            </Fragment>
          }
        >
          <div
            className="t-black-light"
            dangerouslySetInnerHTML={{
              __html: TextBRHTML(
                visitID !== "undefined"
                  ? appointmentByID?.visit?.data?.print || ""
                  : appointmentByID?.detail || ""
              ),
            }}
          />

          {appointmentByID?.visit?.visitFile?.radiology && (
            <div className="f-normal mt-2 mb-1">Radiology Result</div>
          )}

          {appointmentByID?.visit?.visitFile?.radiology &&
            appointmentByID?.visit?.visitFile?.radiology?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })}
          {appointmentByID?.visit?.visitFile?.medical && (
            <div className="f-normal mt-2 mb-1">Medical Report</div>
          )}

          {appointmentByID?.visit?.visitFile?.medical &&
            appointmentByID?.visit?.visitFile?.medical?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })}
          {appointmentByID?.visit?.visitFile?.summary && (
            <div className="f-normal mt-2 mb-1">Summary Result</div>
          )}

          {appointmentByID?.visit?.visitFile?.summary &&
            appointmentByID?.visit?.visitFile?.summary?.map((item, index) => {
              return (
                <Row
                  key={index}
                  align="middle"
                  className="mt-05 cursor"
                  onClick={() => window.open(item?.filePath)}
                >
                  <img
                    src={Link}
                    alt="Link"
                    width={13}
                    height={13}
                    style={{ marginRight: "2px" }}
                  />
                  <img
                    src={File}
                    alt="File"
                    width={25}
                    height={25}
                    style={{ marginRight: "5px" }}
                  />
                  <div>{item?.fileName || ""}</div>
                </Row>
              );
            })}
        </Card>

        {visitID !== "undefined" && (
          <React.Fragment>
            <Card
              className="comment-card mt-2 "
              extra={
                <>
                  <Row gutter={[14, 14]} align="middle">
                    <Col md={12} lg={12}>
                      {t("doctorOpinion")}
                    </Col>
                    {
                      <Col md={12} lg={12} className="col-end link-btn">
                        <Button type="link" onClick={doctorCommentEdit}>
                          {t("edit")}
                        </Button>
                        <Button type="link" onClick={doctorCommentModal}>
                          {t("seePaintings")}
                        </Button>
                      </Col>
                    }
                  </Row>
                </>
              }
            >
              {appointmentByID?.visit?.suggestion?.print ? (
                <div
                  className="t-black-light"
                  dangerouslySetInnerHTML={{
                    __html: TextBRHTML(
                      appointmentByID?.visit?.suggestion?.print || ""
                    ),
                  }}
                />
              ) : (
                <div className="t-black-light">
                  <Empty
                    image={empty}
                    imageStyle={{
                      height: 60,
                    }}
                    description={
                      <span className="t-grey-white">{`${t("no")} ${t(
                        "doctorOpinion"
                      )}`}</span>
                    }
                  >
                    <Button type="primary" onClick={createComment}>
                      {t("createNow")}
                    </Button>
                  </Empty>
                </div>
              )}
            </Card>
            <Form name="vitalSignCrm" id="vitalSignCrm" form={important}>
              <Card
                className="comment-card mt-2 "
                extra={
                  <React.Fragment>
                    <Row gutter={[14, 14]}>
                      <Col lg={12}>Vital Sign CRM</Col>
                    </Row>
                  </React.Fragment>
                }
              >
                <div className="t-black-light">
                  <VitalSign medicalRecord={false} />
                </div>
              </Card>
            </Form>

            <VitalSignModal
              title="Vital Sign"
              visitsByID={appointmentByID?.visit}
              keyName="doctorRoom"
            />

            <Note
              title="Diagnosis/Impression"
              name="diagnosis"
              visitsByID={appointmentByID?.visit}
              medicalRecord={medicalRecord?.[0]?.isEdit}
              status="appointment"
            />

            {/* <Note
            title="Lab"
            name="lab"
            visitsByID={appointmentByID?.visit}
            medicalRecord={medicalRecord?.[0]?.isEdit}
            status="appointment"
          /> */}

            <Note
              title="Others"
              name="other"
              visitsByID={appointmentByID?.visit}
              medicalRecord={medicalRecord?.[0]?.isEdit}
              status="appointment"
            />

            <OrderOutType
              patientID={patientID}
              draftOrderList={draftOrderList}
              medicalRecord={medicalRecord?.[0]?.isEdit}
              eventId={appointmentByID?.visit?.eventId || 0}
            />
            <OrderOneDay
              patientID={patientID}
              draftOrderList={draftOrderList}
              medicalRecord={medicalRecord?.[0]?.isEdit}
              eventId={appointmentByID?.visit?.eventId || 0}
            />

            <OrderContinuous
              patientID={patientID}
              draftOrderList={draftOrderList}
              medicalRecord={medicalRecord?.[0]?.isEdit}
              eventId={appointmentByID?.visit?.eventId || 0}
            />

            <RejectGroupOrder />
            <MainMedicineDoctorRoomProvider
              value={{
                medicalRecord: medicalRecord?.[0]?.isEdit,
                title: "returnMedicine.title",
                buttonName: "editMedicineReturn",
                buttonAdd: "addMedicineReturn",
                key: "key",
                status: "return_drug",
                eventId: appointmentByID?.visit?.eventId || 0,
                drugStatus: DRUG_STATUS.CONTINUOUS,
              }}
            >
              <ReturnMedicine />
            </MainMedicineDoctorRoomProvider>

            <MainMedicineDoctorRoomProvider
              value={{
                medicalRecord: medicalRecord?.[0]?.isEdit,
                title: "changeDoseMedication",
                buttonName: "editDrugLabelChangeList",
                buttonAdd: "addDrugLabelChangeList",
                key: "id",
                status: "change_drug_label",
                eventId: appointmentByID?.visit?.eventId || 0,
                draftOrderList: draftOrderList,
                drugStatus: DRUG_STATUS.HOLDING,
              }}
            >
              <EditMedicine />
            </MainMedicineDoctorRoomProvider>

            <MainMedicineDoctorRoomProvider
              value={{
                medicalRecord: medicalRecord?.[0]?.isEdit,
                title: "drugStatusUpdate",
                buttonName: "drugStatusUpdate",
                key: "id",
                status: "change_drug_label",
                eventId: appointmentByID?.visit?.eventId || 0,
                draftOrderList: draftOrderList,
                drugStatus: DRUG_STATUS.HOLDING,
                drug: true,
              }}
            >
              <UpdateStatusMedicine />
            </MainMedicineDoctorRoomProvider>

            <div className="mt-2">
              <LabInterface
                title="LabInterface Group One"
                room={ENUM_ROOM_SIGNATURE.DOCTOR_ROOM}
              />
            </div>

            <TransferComponent
              patientID={patientID}
              draftOrderList={draftOrderList}
              title="LabInterface Other One"
              editRecord={medicalRecord?.[0]?.isEdit}
              type={ORDER_TYPE.LAB_INTERFACE_OTHER_ONE}
              eventId={appointmentByID?.visit?.eventId || 0}
              room={ENUM_ROOM_SIGNATURE.DOCTOR_ROOM}
            />

            <div className="mt-2 ">
              <LabInterfaceGroup
                title="LabInterface Group Two"
                room={ENUM_ROOM_SIGNATURE.DOCTOR_ROOM}
              />
            </div>

            <TransferComponent
              patientID={patientID}
              draftOrderList={draftOrderList}
              title="LabInterface Other Two"
              editRecord={medicalRecord?.[0]?.isEdit}
              type={ORDER_TYPE.LAB_INTERFACE_OTHER_TWO}
              eventId={appointmentByID?.visit?.eventId || 0}
              room={ENUM_ROOM_SIGNATURE.DOCTOR_ROOM}
            />

            <div className="mt-2 ">
              <Checkbox checked={open?.open} onChange={viewDetail}>
                <span className="f-normal t-dark-blue">
                  {t("physicalData")}
                </span>
              </Checkbox>
            </div>

            <div className={open?.open ? "open" : "close"}>
              <Row
                className="header-table mt-2"
                align="middle"
                justify="space-between"
              >
                <Col className="f-normal  t-dark-blue">
                  <span className="f-bold pr-05 ">{t("physicalData")}</span>
                </Col>
                {medicalRecord?.[0]?.isEdit ? (
                  <Col style={{ marginLeft: "auto" }}>
                    {appointmentByID?.visit?.visitMarker?.doctorMarker0 ||
                    appointmentByID?.visit?.visitMarker?.doctorMarker1 ||
                    appointmentByID?.visit?.visitMarker?.doctorMarker2 ||
                    appointmentByID?.visit?.visitMarker?.doctorMarker3 ? (
                      <Button type="primary" onClick={openMaker}>
                        {t("edit")}
                      </Button>
                    ) : null}
                  </Col>
                ) : null}
              </Row>

              {appointmentByID?.visit?.visitMarker?.doctorMarker0 ||
              appointmentByID?.visit?.visitMarker?.doctorMarker1 ||
              appointmentByID?.visit?.visitMarker?.doctorMarker2 ||
              appointmentByID?.visit?.visitMarker?.doctorMarker3 ? (
                <ViewMarker appointmentsByID={appointmentByID} />
              ) : (
                <div className="border-note p-1">
                  <Empty
                    image={empty}
                    imageStyle={{
                      height: 60,
                    }}
                    description={
                      <span className="t-grey-white">{`${t(
                        "addPhysicalPoint"
                      )}`}</span>
                    }
                  >
                    {medicalRecord?.[0]?.isEdit ? (
                      <Button type="primary" onClick={openMaker}>
                        + {t("addPhysicalPoint")}
                      </Button>
                    ) : null}
                  </Empty>
                </div>
              )}
            </div>
          </React.Fragment>
        )}

        <div className="pb-2 " />

        {visible?.leadingSymptoms && (
          <ViewImage
            title={`${t("basicInformation")} / ${t("importantSymptoms")}`}
            visible={visible?.leadingSymptoms}
            setModal={leadingSymptomsModal}
            drawing={appointmentByID?.visit?.data?.drawing || ""}
          />
        )}
        {visible.doctorComment && (
          <ViewImage
            visible={visible?.doctorComment}
            setModal={doctorCommentModal}
            drawing={appointmentByID?.visit.suggestion.drawing || ""}
          />
        )}

        {visible.marker && (
          <ModalMarker
            visible={visible?.marker}
            onModal={openMaker}
            appointmentsByID={appointmentByID}
          />
        )}
      </Card>
    </MainPatientByIdProvider>
  );
};

export default FormCreateOrder;
