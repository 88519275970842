import { symbol } from "@assets/data/data";
import { useTranslation } from "react-i18next";
import { useMainPatientById } from "@provider/patient_by_id";
import React from "react";

const SymbolView = () => {
  const { patientById } = useMainPatientById();
  const { i18n } = useTranslation();
  const dataSymbol = symbol?.filter((item) => {
    if (!patientById?.symbol?.includes(Number(item?.id))) {
      return false;
    }
    return item;
  });

  return (
    <React.Fragment>
      {dataSymbol
        ?.map((item) =>
          i18n.language === "th" ? item?.["nameTh"] : item?.["nameEn"]
        )
        ?.join(" , ")
        ?.trim()}
    </React.Fragment>
  );
};

export default SymbolView;
