import { Card, Form } from "antd";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";

const SettingProfile = () => {
  const { t } = useTranslation();
  const [formUser] = Form.useForm();
  const history = useHistory<RouteComponentProps["history"]>();

  return (
    <div>
      <Header formUser={formUser} history={history} />
      <div className="set-layout ">
        <Card
          className="card-prefix  pb-2"
          extra={
            <b className="fs-16 t-black-light ">{`${t("manageProfile")}`}</b>
          }
        >
          <Fields formUser={formUser} />
        </Card>
      </div>
    </div>
  );
};

export default SettingProfile;
