import { Tabs } from "antd";
import { tabsState } from "@tools/recoil/recoil-atom";
import { useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import History from "./history/History";
import Respond from "./respond/Respond";
import ConfirmLab from "./confirm_lab/ConfirmLab";

const TabsLab = () => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;
  const [tab, setTab] = useRecoilState(tabsState);

  const changeTab = (key: string) => {
    setTab({ ...tab, lab: key });
  };

  return (
    <Tabs
      className="tab-printdraw f-normal "
      defaultActiveKey={tab.lab}
      onChange={changeTab}
    >
      <TabPane tab={t("lab.title")} key="1" className="mt-2">
        <ConfirmLab />
      </TabPane>
      <TabPane tab={t("respond")} key="2" className="mt-2">
        <Respond />
      </TabPane>
      <TabPane tab={t("record")} key="3" className="mt-2">
        <History />
      </TabPane>
    </Tabs>
  );
};

export default TabsLab;
