import axios from "@axios/index";
import { IDraftBillByID } from "@interface/IDraftBill.interface";
import { IDraftBillList } from "@interface/IDraftBill.interface";
import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import { Elementary, ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, UseMutationResult } from "react-query";

interface IParams {
  page?: number;
  limit?: number;
  orderby?: string;
  draftBillNumber?: string;
  date?: string | undefined;
}

export const useGetDraftBillList = (
  id: string | number,
  params: IParams
): UseQueryResult<Elementary<IDraftBillList[]>> => {
  return useQuery(
    ["get-draft-bill-list", id, params],
    async () => {
      const res = await axios.get(`/draft-bill/patient/${id}`, {
        params: params,
      });

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
    },
    { enabled: !(!id && id !== 0) }
  );
};

export const useGetDraftBillById = (
  id: string | number
): UseQueryResult<ElementaryOneMore<IDraftBillByID>> => {
  return useQuery(
    ["get-draft-bill-by-id", id],
    async () => {
      const res = await axios.get(`/draft-bill/${id}`);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      } else if (res?.status >= 400 && res?.status <= 500) {
        return res?.data;
      }
    },
    { enabled: !(!id && id !== 0) }
  );
};

export const useUpdateDraftBill = (): UseMutationResult<any, Error> => {
  return useMutation(["update-draft-bill"], async ({ id, data }: any) => {
    const res = await axios.put(`/draft-bill/${id}`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteDraftBillById = (): UseMutationResult<any, Error> => {
  return useMutation(["del-draft-bill"], async (data: any) => {
    const res = await axios.delete(`/draft-bill/delete-product`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateDraftBillAddProducts = (): UseMutationResult<
  any,
  Error
> => {
  return useMutation(
    ["create-draft-bill-add-product"],
    async ({ data }: any) => {
      const res = await axios.post(`/draft-bill/add-product`, data);

      if (res?.status >= 200 && res?.status < 300) {
        return res?.data;
      }
      thrownResponse(res);
    }
  );
};

export const useUpdateDraftBillDiscount = (): UseMutationResult<any, Error> => {
  return useMutation(["update-draft-bill-discount"], async ({ data }: any) => {
    const res = await axios.put(`/draft-bill/update-discount`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
