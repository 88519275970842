import { ORDER_TYPE } from "@tools/util.enum";

const DataLabInterfaceOne = () => {
  const testCode = [
    {
      color: "#D291BC",
      name: "Food Intolerance Test (J121)",
      sku: "L001-NH-0826",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#D291BC",
      name: "MTHFR mutation (M355)",
      sku: "L001-NH-1098",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "red",
      name: "ANA Profile (T210)",
      sku: "L001-NH-2771",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#D291BC",
      name: "Apo E Genotype (M221)",
      sku: "L001-NH-1060",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "red",
      name: "Apolipoprotein B (C910)",
      sku: "L001-NH-0425",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "red",
      name: "Anti CCP (P345)",
      sku: "L001-NH-1931",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#D291BC",
      name: "ACTH (N760)",
      sku: "L001-NH-1737",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#D291BC",
      name: "PTH (N720)",
      sku: "L001-NH-1732",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "red",
      name: "Glycomark (1,5-AG)(C220)",
      sku: "L001-NH-0294",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "red",
      name: "RF (P280)",
      sku: "L001-NH-1920",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#56A5EC",
      name: "Fribinogen (K090)",
      sku: "L001-NH-0926",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#56A5EC",
      name: "FDP-D dimer (K245)",
      sku: "L001-NH-0948",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#D291BC",
      name: "Beta Crosslaps (N070)",
      sku: "L001-NH-1577",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#D291BC",
      name: "P1NP (N008)",
      sku: "L001-NH-1556",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
    {
      color: "#D291BC",
      name: "Osteocalcin (N075)",
      sku: "L001-NH-1578",
      type: ORDER_TYPE.LAB_INTERFACE_TWO,
    },
  ];
  const hematology = [
    {
      color: "#D291BC",
      name: "CBC (A010)",
      sku: "L001-NH-0032",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "Hematocrit (A190)",
      sku: "L001-NH-0053",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "ESR (A080)",
      sku: "L001-NH-0045",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "G-6-PD (A300)",
      sku: "L001-NH-0057",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "Malarial Parasite (A150)",
      sku: "L001-NH-0049",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "Reticulocyte Count (A120)",
      sku: "L001-NH-0047",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "Platelet Count (A070)",
      sku: "L001-NH-0044",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "Inclusion Body (A500)",
      sku: "L001-NH-0061",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "OF Test (A795)",
      sku: "L001-NH-0092",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
  ];

  const microbiology = [
    {
      color: "white",
      name: "AFB Stain (E230)",
      sku: "L001-NH-0515",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "white",
      name: "Gram's Stain (E220)",
      sku: "L001-NH-0514",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "white",
      name: "India Ink Preparation (D030)",
      sku: "L001-NH-0445",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "white",
      name: "KOH Preparation (D090)",
      sku: "L001-NH-0447",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "white",
      name: "TB Culture (E337)",
      sku: "L001-NH-0529",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "white",
      name: "Bacterial Identification (E005)",
      sku: "L001-NH-0480",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "white",
      name: "Hemo Culture & Sensitivity (E300)",
      sku: "L001-NH-0521",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
  ];
  const coagulation = [
    {
      color: "#56A5EC",
      name: "PTT (K060)",
      sku: "L001-NH-0923",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#56A5EC",
      name: "Prothrombin Time (K050)",
      sku: "L001-NH-0922",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#56A5EC",
      name: "Thrombin Time (K070)",
      sku: "L001-NH-0924",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "ABO Blood Group (A020)",
      sku: "L001-NH-0039",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "Rh Blood Group (A025)",
      sku: "L001-NH-0040",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "Direct Coombs test (L080)",
      sku: "L001-NH-0968",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "In-direct Coombs test (L050)",
      sku: "L001-NH-0967",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
  ];

  const urine = [
    {
      color: "#FFEF00",
      name: "Pregnancy Test (D120)",
      sku: "L001-NH-0449",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#FFEF00",
      name: "Urine Examination (D111)",
      sku: "L001-NH-0448",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#FFEF00",
      name: "Amylase (Urine)(C480)",
      sku: "L001-NH-0329",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#FFEF00",
      name: "Creatinine (Urine)(C413)",
      sku: "L001-NH-0306",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#FFAE42",
      name: "Protien (Urine)(C414)",
      sku: "L001-NH-0307",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#FFAE42",
      name: "Stool Examination (D380)",
      sku: "L001-NH-0462",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#FFAE42",
      name: "Stool Occult Blood (D330)",
      sku: "L001-NH-0459",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#FFAE42",
      name: "Stool Concentration Technique (D360)",
      sku: "L001-NH-0461",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
  ];

  const biochemistry1 = [
    {
      color: "gray",
      name: "Glucose (C040)",
      sku: "L001-NH-0261",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "BUN (C530)",
      sku: "L001-NH-0336",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Creatinine (+eGFR)(C521)",
      sku: "L001-NH-0335",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Cholesterol (C061)",
      sku: "L001-NH-0263",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "HDL-Cholesterol (C062)",
      sku: "L001-NH-0264",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "LDL-Cholesterol (C640)",
      sku: "L001-NH-0352",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Triglyceride (C650)",
      sku: "L001-NH-0353",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Uric Acid (C120)",
      sku: "L001-NH-0280",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Total Protien (C620)",
      sku: "L001-NH-0350",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Albumin (C630)",
      sku: "L001-NH-0351",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Billirubin (C560)",
      sku: "L001-NH-0339",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Electrolyte (C004)",
      sku: "L001-NH-0256",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "AST (SGOT)(C610)",
      sku: "L001-NH-0349",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
  ];

  const biochemistry2 = [
    {
      color: "red",
      name: "ALT (SGPT)(C600)",
      sku: "L001-NH-0348",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "ALP (C580)",
      sku: "L001-NH-0345",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "ALP-Isoenzyme (C585)",
      sku: "L001-NH-0346",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Phosphorus (C330)",
      sku: "L001-NH-0295",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Magnesium (C340)",
      sku: "L001-NH-0297",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Calcium (C100)",
      sku: "L001-NH-0277",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "GGT (Gamma GT)(C590)",
      sku: "L001-NH-0347",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "LDH (C130)",
      sku: "L001-NH-0281",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "LDH-Isoenzyme (C093)",
      sku: "L001-NH-0274",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "#D291BC",
      name: "HbA1C (C880)",
      sku: "L001-NH-0421",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Lipase (C025)",
      sku: "L001-NH-0258",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Amylase (C140)",
      sku: "L001-NH-0282",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Cholinesterase (C055)",
      sku: "L001-NH-0262",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
    {
      color: "red",
      name: "Acid Phosphatase (C515)",
      sku: "L001-NH-0117",
      type: ORDER_TYPE.LAB_INTERFACE_THREE,
    },
  ];

  const groupData = [
    {
      nameSD: "02 SD 0343",
      group: "A",
      testCode: "CBC + BUN + Cr (Plus eGFR) + G6PD",
      color1: "white",
      color2: "#D291BC",
      color3: "red",
      name: "Absolute 3",
      sku: "L001-NH-0001",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0398",
      group: "B",
      testCode: "Homocysteine + hs CRP",
      color1: "white",
      color2: "#D291BC",
      color3: "red",
      name: "Absolute 32",
      sku: "L001-NH-0002",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0399",
      group: "C1",
      testCode: "LDL (Direct) + HbA1C + Vit D Total + LFT + DHEAS",
      color1: "white",
      color2: "#D291BC",
      color3: "red",
      name: "Absolute 33",
      sku: "L001-NH-0026",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0400",
      group: "C2",
      testCode:
        "FBS + Lipid Profile + HbA1C + Vit D Total + Insulin + LFT + DHEAS",
      color1: "white",
      color2: "#D291BC",
      color3: "red",
      name: "Absolute 34",
      sku: "L001-NH-0027",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0401",
      group: "D1",
      testCode: "FSH + LH + Testosterone + Estradiol + SHBG",
      color1: "white",
      color2: "white",
      color3: "red",
      name: "Absolute 35",
      sku: "L001-NH-0028",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0402",
      group: "D2",
      testCode: "FSH + LH + Estradiol + Progesterone + SHBG",
      color1: "white",
      color2: "white",
      color3: "red",
      name: "Absolute 36",
      sku: "L001-NH-0029",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0357",
      group: "E",
      testCode: "IGF1 + IGFBP3",
      color1: "white",
      color2: "white",
      color3: "red",
      name: "Absolute 17",
      sku: "L001-NH-0006",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0397",
      group: "F",
      testCode: "TFT (TSH + T3 + T4 + Free T3 + Free T4)",
      color1: "white",
      color2: "white",
      color3: "red",
      name: "Absolute 31",
      sku: "L001-NH-0007",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "",
      group: "H1",
      testCode: "AFP + CEA + NSE + CA 19-9 + CA 153 + CA 125 + Ferritin",
      color1: "white",
      color2: "white",
      color3: "red",
      name: "",
      sku: "L001-NH-0009",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "",
      group: "H2",
      testCode: "AFP + CEA + NSE + CA 19-9 + PSA + Free PSA",
      color1: "white",
      color2: "white",
      color3: "red",
      name: "",
      sku: "L001-NH-0010",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0354",
      group: "",
      testCode:
        "Toxic Metals Profile in Urine (Cr, Co, Mn, Ni, As, Hg, Pb, Cd)",
      color1: "white",
      color2: "white",
      color3: "#FFEF00",
      name: "Absolute 14",
      sku: "L001-NH-0030",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "",
      group: "",
      testCode:
        "Toxic Metals in Urine Profile 2 (Pb, Hg, Cd, Mn, Cr, Co, Ni, As, Al)",
      color1: "white",
      color2: "white",
      color3: "#FFEF00",
      name: "S263",
      sku: "L001-NH-2706",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "02 SD 0353",
      group: "",
      testCode:
        "Toxic Metals Profile in Blood (Cr, Co, Mn, Ni, Hg, Pb, Cd) + Arsenic in Blood",
      color1: "white",
      color2: "white",
      color3: "#D291BC",
      name: "S261+S229",
      sku: "L001-NH-0031",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "",
      group: "",
      testCode:
        "Toxic Metals in Blood Profile 2 (Pb, Hg, Cd, Mn, Cr, Co, Ni, As, Al)",
      color1: "white",
      color2: "white",
      color3: "#D291BC",
      name: "S264",
      sku: "L001-NH-0011",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "",
      group: "",
      testCode: "Trace Elements Profile in Blood (Cu, Li, Mo, Se, Tl, Sn, Zn)",
      color1: "white",
      color2: "white",
      color3: "#D291BC",
      name: "S290",
      sku: "L001-NH-0013",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "",
      group: "",
      testCode: "Trace Elements Profile in Urine (Cu, Se, Tl, Zn)",
      color1: "white",
      color2: "white",
      color3: "#FFEF00",
      name: "S295",
      sku: "L001-NH-2713",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "",
      group: "",
      testCode: (
        <div>
          Antioxidant-8 Lipid and Water Soluble Vitamins
          <span style={{ color: "blue" }}> (Light Protected) </span>
          <span style={{ color: "red" }}>(Graphic Report)</span>
        </div>
      ),

      color1: "white",
      color2: "white",
      color3: "red",
      name: "S072",
      sku: "L001-NH-0015",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
    {
      nameSD: "",
      group: "",
      testCode: "VMA, HVA, 5-HIAA, Urine-HPLC (24-h Urine)",
      color1: "white",
      color2: "white",
      color3: "#FFEF00",
      name: "S206",
      sku: "L001-NH-0016",
      type: ORDER_TYPE.LAB_INTERFACE_ONE,
    },
  ];
  return {
    testCode,
    hematology,
    microbiology,
    coagulation,
    urine,
    biochemistry1,
    biochemistry2,
    groupData,
  };
};

export default DataLabInterfaceOne;
