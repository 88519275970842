import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Card, Col, Form, FormInstance, Input, Row } from "antd";

type Props = {
  agentForm: FormInstance;
  onFinish: (e: any) => void;
};

const Fields: FC<Props> = ({ agentForm, onFinish }) => {
  const { t } = useTranslation();

  return (
    <div className="set-layout ">
      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={<b className="fs-16 t-black-light ">{`${t("editAgent")} `}</b>}
      >
        <Card
          className="card-prefix card-prefix-width card-prefix-chi"
          extra={<b className="fs-14 c-white ">{`${t("editAgent")} `}</b>}
        >
          <Form onFinish={onFinish} name="agentForm" form={agentForm}>
            <Row gutter={[12, 12]}>
              <Col lg={12}>
                <Form.Item
                  id="firstNameTh"
                  label={`${t("agentName")} (TH)`}
                  name="firstNameTh"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  id="lastNameTh"
                  label={`${t("agentLastName")} (TH)`}
                  name="lastNameTh"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  id="firstNameEn"
                  label={`${t("agentName")} (EN)`}
                  name="firstNameEn"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <Form.Item
                  id="lastNameEn"
                  label={`${t("agentLastName")} (EN)`}
                  name="lastNameEn"
                  rules={[{ required: true }]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={24}>
                <Form.Item
                  id="descriptionTh"
                  label={`${t("details")} (TH)`}
                  name="descriptionTh"
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col lg={24}>
                <Form.Item
                  id="descriptionEn"
                  label={`${t("details")} (EN)`}
                  name="descriptionEn"
                >
                  <Input />
                </Form.Item>
              </Col>

              {/* <Col lg={24}>
                <Form.Item
                  id="branchId"
                  label={`${t("branch")}`}
                  name="branchId"
                  rules={[{ required: true }]}
                >
                  <Selector
                    placeholder={t("branch")}
                    Source={branchList?.data?.result?.[0]?.data || []}
                    keyName="name"
                    keyValue="id"
                  />
                </Form.Item>
              </Col> */}
            </Row>
          </Form>
        </Card>
      </Card>
    </div>
  );
};

export default Fields;
