import { Card, Form } from "antd";
import { useTranslation } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import React from "react";
import Fields from "./Fields";
import Header from "./Header";
import RoleContext from "@provider/RoleProvider";

const FormUser = () => {
  const { t } = useTranslation();
  const [formUser] = Form.useForm();
  const match: any = useRouteMatch();
  const userID = match.params.id;
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "USER";
  });

  return (
    <div>
      <Header
        formUser={formUser}
        userID={userID}
        history={history}
        medicalRecord={medicalRecord?.[0]?.isEdit}
      />
      <div className="set-layout ">
        <Card
          className="card-prefix  pb-2"
          extra={
            <b className="fs-16 t-black-light ">
              {userID ? `${t("editUser")}` : `${t("createUser")}`}
            </b>
          }
        >
          <Fields
            formUser={formUser}
            userID={userID}
            history={history}
            medicalRecord={medicalRecord?.[0]?.isEdit}
          />
        </Card>
      </div>
    </div>
  );
};

export default FormUser;
