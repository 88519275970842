import { IVisitFile } from "@interface/IVisitFile.interface";
import { thrownResponse } from "./auth";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { useMutation, useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useCreateVisitFile = () => {
  return useMutation(async (data: any) => {
    const res = await axios.post(`/visit-file`, data);
    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetVisitFile = (
  name: string,
  id?: number
): UseQueryResult<ElementaryOneMore<IVisitFile>> => {
  return useQuery([`get-visit-file-${name}`, name, id], async () => {
    const res = await axios.get(`/visit-file/${id}/${name}`);
    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useDeleteVisitFile = () => {
  return useMutation(async (id?: number) => {
    const res = await axios.delete(`/visit-file/${id}`);
    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
