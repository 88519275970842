import React, { FC, useEffect, useState } from "react";
import { gender, productName } from "@tools/util";
import { useTranslation } from "react-i18next";
import { Checkbox, Col, Row } from "antd";
import { useMainLabInterfaceGroup } from "@provider/lab_inteface_group";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";
import { Age } from "@tools/age";

const CheckboxGroup = Checkbox.Group;

type Props = {
  visible?: boolean;
  viewDetailOpen?: (e: boolean) => void;
};

const LabInterfaceGroupOne: FC<Props> = ({ visible, viewDetailOpen }) => {
  const { t, i18n } = useTranslation();
  const userList = React.useContext(UserContext);
  const {
    labInterfaceGroupOne,
    draftOrderList,
    labInterface,
    labInfoList,
    patient,
  } = useMainLabInterfaceGroup();

  const [select, setSelect] = useState(
    !!labInfoList
      ? [
          ...(labInfoList?.groups1?.[0]?.listKey || []),
          ...(labInfoList?.groups2?.[0]?.listKey || []),
          ...(labInfoList?.groups3?.[0]?.listKey || []),
        ]
      : [
          ...(draftOrderList?.result?.[0]?.labInterface?.groups1?.[0]
            ?.listKey || []),
          ...(draftOrderList?.result?.[0]?.labInterface?.groups2?.[0]
            ?.listKey || []),
          ...(draftOrderList?.result?.[0]?.labInterface?.groups3?.[0]
            ?.listKey || []),
        ]
  );
  useEffect(() => {
    if (!draftOrderList) return;
    const dataSelect = [
      ...(draftOrderList?.result?.[0]?.labInterface?.groups1?.[0]?.listKey ||
        []),
      ...(draftOrderList?.result?.[0]?.labInterface?.groups2?.[0]?.listKey ||
        []),
      ...(draftOrderList?.result?.[0]?.labInterface?.groups3?.[0]?.listKey ||
        []),
    ];
    setSelect(dataSelect);
    !!viewDetailOpen && viewDetailOpen(!!dataSelect?.length);
    //eslint-disable-next-line
  }, [draftOrderList]);
  useEffect(() => {
    if (!labInfoList) return;
    const dataSelect = [
      ...(labInfoList?.groups1?.[0]?.listKey || []),
      ...(labInfoList?.groups2?.[0]?.listKey || []),
      ...(labInfoList?.groups3?.[0]?.listKey || []),
    ];
    setSelect(dataSelect);
    !!viewDetailOpen && viewDetailOpen(!!dataSelect?.length);
    //eslint-disable-next-line
  }, [labInfoList]);

  const onChange = (list: any) => {
    setSelect(list);
    const labInterfaceSelect = list?.map((keyId: number) => {
      const resultList = [
        ...labInterface?.testCode,
        ...labInterface?.hematology,
        ...labInterface?.microbiology,
        ...labInterface?.coagulation,
        ...labInterface?.urine,
        ...labInterface?.biochemistry1,
        ...labInterface?.biochemistry2,
        ...labInterface?.groupData,
      ].find((key) => key?.id === keyId);
      return {
        id: resultList?.id,
        type: resultList?.type,
        qty: 1,
      };
    });
    labInterfaceGroupOne.current = labInterfaceSelect;
  };
  const KeyHomeAddress = patient?.homeAddress;
  const homeAddress =
    KeyHomeAddress?.addressTh || KeyHomeAddress?.districtTh
      ? [
          KeyHomeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
          KeyHomeAddress[
            i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
          ],
          KeyHomeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
          KeyHomeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
          KeyHomeAddress?.country,
          KeyHomeAddress?.zipcode,
        ]
          ?.join(" ")
          ?.trim()
      : "";

  return (
    <div>
      <CheckboxGroup onChange={onChange} value={select}>
        <table width="100%">
          <thead></thead>

          <tbody>
            <tr className="border-interface-lab">
              <td
                width="30%"
                rowSpan={2}
                colSpan={3}
                className="border-interface-lab-right"
              >
                <ImageLogo
                  className="circles"
                  src={userList?.branch?.image}
                  height="60px"
                  width="150px"
                  alt="logo"
                />
              </td>
              <td
                width="50%"
                rowSpan={2}
                className="border-interface-lab-right text-table-top-management t-start"
              >
                Patient Information :
                <div style={{ paddingLeft: 20 }}>
                  {i18n.language === "th"
                    ? `${patient?.firstNameTh} ${patient?.lastNameTh}`
                    : `${patient?.firstNameEn} ${patient?.lastNameEn}`}
                  {patient?.gender &&
                    ` ${t("sex")} : ${gender(patient?.gender, i18n?.language)}`}
                  {patient?.dateOfBirth &&
                    ` ${t("age")} : ${Age(patient?.dateOfBirth)}`}
                  {patient?.height &&
                    ` ${t("height")} : ${patient?.height || ""}`}
                </div>
                <div style={{ paddingLeft: 20 }}>
                  <span>{homeAddress}</span>
                </div>
                <div style={{ paddingLeft: 20 }}>
                  {patient?.mobile &&
                    ` ${t("contactNumber")} : ${patient?.mobile || ""}`}
                </div>
              </td>
              <td
                width="20%"
                colSpan={5}
                className="text-table-top-management t-start"
              >
                Note :
              </td>
            </tr>

            <tr className="border-interface-lab">
              <td width="5%" className="border-interface-lab-right"></td>
              <td
                width="20%"
                colSpan={5}
                className="text-table-top-management t-start"
              >
                J121 ขอคู่มือการแปลผล + Card
              </td>
            </tr>

            <tr className="border-interface-lab">
              <td colSpan={9} className="t-blue-theme t-center">
                Payer : 10L624 (2800001377) Absolute Health Clinic (C2F/LG){" "}
              </td>
            </tr>

            {labInterface?.groupData?.map((item, index) => {
              return (
                <tr
                  style={{ fontSize: "12px" }}
                  key={index}
                  className="border-interface-lab"
                >
                  <td
                    width="5%"
                    className="border-interface-lab-right t-center"
                  >
                    <Checkbox
                      disabled={
                        !visible
                          ? true
                          : item?.disabled
                          ? typeof item?.isTestCode === "boolean"
                            ? !item?.isTestCode
                            : !item?.disabled
                          : !item?.disabled
                      }
                      key={item?.id}
                      value={item?.id}
                    />
                  </td>
                  <td
                    width="10%"
                    className="border-interface-lab-right t-center"
                  >
                    {`${item?.nameSD}`}
                  </td>
                  <td
                    width="5%"
                    className="border-interface-lab-right t-center"
                  >
                    {`${item?.group}`}
                  </td>
                  <td
                    width="50%"
                    colSpan={2}
                    className="border-interface-lab-right"
                  >
                    {item?.testCode}
                  </td>
                  <td
                    width="5%"
                    style={{ backgroundColor: `${item?.color1}` }}
                    className="border-interface-lab-right"
                  ></td>
                  <td
                    width="5%"
                    style={{ backgroundColor: `${item?.color2}` }}
                    className="border-interface-lab-right"
                  ></td>
                  <td
                    width="5%"
                    style={{ backgroundColor: `${item?.color3}` }}
                    className="border-interface-lab-right "
                  ></td>
                  <td width="15%"> {`${item?.name}`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <Row
          className="mt-1"
          style={{ fontSize: "12px" }}
          gutter={[12, 1]}
          justify="center"
        >
          {labInterface?.testCode?.map((item, index) => {
            return (
              <React.Fragment key={index}>
                <Col span={1}>
                  <Checkbox
                    disabled={!visible ? true : !item?.disabled}
                    key={item?.id}
                    value={item?.id}
                  />
                </Col>
                <Col
                  span={1}
                  className="border-interface-lab"
                  style={{ backgroundColor: item?.color }}
                ></Col>
                <Col span={6}>{item?.name}</Col>
              </React.Fragment>
            );
          })}
        </Row>
        <Row
          justify="center"
          className="border-interface-lab mt-05 t-blue-theme"
        >
          N Health CLINICAL LABORATORY
        </Row>

        <Row
          justify="center"
          className="border-interface-lab-right border-interface-lab-left border-interface-lab-bottom t-blue-theme"
        >
          Biochemistry / Hematology / Microbiology / Coagulation & Blood Bank /
          Urine & Stool
        </Row>

        <Row style={{ fontSize: "12px" }}>
          <Col span={12}>
            <Row className="t-blue-theme">Biochemistry</Row>
            <Row>
              {[
                { biochemistry: labInterface?.biochemistry1 },
                { biochemistry: labInterface?.biochemistry2 },
              ]?.map((item, index) => {
                return (
                  <Col span={12} key={index}>
                    {item?.biochemistry?.map((children, indexChild) => {
                      return (
                        <Row
                          gutter={[12, 0]}
                          style={{ marginTop: "1px" }}
                          key={indexChild}
                        >
                          <Col span={3}>
                            <Checkbox
                              disabled={
                                !visible
                                  ? true
                                  : children?.disabled
                                  ? typeof children?.isTestCode === "boolean"
                                    ? !children?.isTestCode
                                    : !children?.disabled
                                  : !children?.disabled
                              }
                              key={children?.id}
                              value={children?.id}
                            />
                          </Col>
                          <Col
                            span={3}
                            className="border-interface-lab"
                            style={{ backgroundColor: children?.color }}
                          ></Col>
                          <Col span={18}>{children?.name}</Col>
                        </Row>
                      );
                    })}
                  </Col>
                );
              })}
            </Row>
          </Col>
          <Col span={6}>
            <Row className="t-blue-theme">Hematology</Row>
            {labInterface?.hematology?.map((item, index) => {
              return (
                <Row gutter={[12, 0]} style={{ marginTop: "1px" }} key={index}>
                  <Col span={3}>
                    <Checkbox
                      disabled={
                        !visible
                          ? true
                          : item?.disabled
                          ? typeof item?.isTestCode === "boolean"
                            ? !item?.isTestCode
                            : !item?.disabled
                          : !item?.disabled
                      }
                      key={item?.id}
                      value={item?.id}
                    />
                  </Col>
                  <Col
                    span={3}
                    className="border-interface-lab"
                    style={{ backgroundColor: item?.color }}
                  ></Col>
                  <Col span={18}>{item?.name}</Col>
                </Row>
              );
            })}

            <Row className="t-blue-theme">Microbiology</Row>
            {labInterface?.microbiology?.map((item, index) => {
              return (
                <Row gutter={[12, 0]} style={{ marginTop: "1px" }} key={index}>
                  <Col span={3}>
                    <Checkbox
                      disabled={
                        !visible
                          ? true
                          : item?.disabled
                          ? typeof item?.isTestCode === "boolean"
                            ? !item?.isTestCode
                            : !item?.disabled
                          : !item?.disabled
                      }
                      key={item?.id}
                      value={item?.id}
                    />
                  </Col>
                  <Col
                    span={3}
                    className="border-interface-lab"
                    style={{ backgroundColor: item?.color }}
                  ></Col>
                  <Col span={18}>{item?.name}</Col>
                </Row>
              );
            })}
          </Col>
          <Col span={6}>
            <Row className="t-blue-theme">Coagulation & Blood Bank</Row>
            {labInterface?.coagulation?.map((item, index) => {
              return (
                <Row gutter={[12, 0]} style={{ marginTop: "1px" }} key={index}>
                  <Col span={3}>
                    <Checkbox
                      disabled={
                        !visible
                          ? true
                          : item?.disabled
                          ? typeof item?.isTestCode === "boolean"
                            ? !item?.isTestCode
                            : !item?.disabled
                          : !item?.disabled
                      }
                      key={item?.id}
                      value={item?.id}
                    />
                  </Col>
                  <Col
                    span={3}
                    className="border-interface-lab"
                    style={{ backgroundColor: item?.color }}
                  ></Col>
                  <Col span={18}>{item?.name}</Col>
                </Row>
              );
            })}

            <Row className="t-blue-theme">Urine & Stool</Row>
            {labInterface?.urine?.map((item, index) => {
              return (
                <Row gutter={[12, 0]} style={{ marginTop: "1px" }} key={index}>
                  <Col span={3}>
                    <Checkbox
                      disabled={
                        !visible
                          ? true
                          : item?.disabled
                          ? typeof item?.isTestCode === "boolean"
                            ? !item?.isTestCode
                            : !item?.disabled
                          : !item?.disabled
                      }
                      key={item?.id}
                      value={item?.id}
                    />
                  </Col>
                  <Col
                    className="border-interface-lab"
                    span={3}
                    style={{ backgroundColor: item?.color }}
                  ></Col>
                  <Col span={18}>{item?.name}</Col>
                </Row>
              );
            })}
          </Col>
        </Row>
        <Row justify="start" className="border-interface-lab mt-05 p-05 ">
          OTHER :
          {` ${
            !!labInfoList
              ? labInfoList?.other1
                  ?.flatMap((children1) =>
                    children1?.children?.flatMap((children2) =>
                      children2?.children?.flatMap((children3) =>
                        productName(
                          i18n.language,
                          children3?.product?.nameTh,
                          children3?.product?.nameEn
                        )
                      )
                    )
                  )
                  ?.join(" , ")
                  ?.trim() || ""
              : draftOrderList?.result?.[0]?.labInterface?.other1
                  ?.flatMap((children1) =>
                    children1?.children?.flatMap((children2) =>
                      children2?.children?.flatMap((children3) =>
                        productName(
                          i18n.language,
                          children3?.product?.nameTh,
                          children3?.product?.nameEn
                        )
                      )
                    )
                  )
                  ?.join(" , ")
                  ?.trim() || ""
          }`}
        </Row>
      </CheckboxGroup>
    </div>
  );
};

export default LabInterfaceGroupOne;
