import React, { useState } from "react";
import TablePreRegistrationHistory from "./Table";
import SearchPreRegistrationHistory from "./Search";

const PreRegistrationHistory = () => {
  const [searchValue, setSearchValue] = useState<any>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  return (
    <div>
      <SearchPreRegistrationHistory
        setSearchValue={setSearchValue}
        setCurrentPage={setCurrentPage}
      />
      <TablePreRegistrationHistory
        searchValue={searchValue}
        currentPage={{
          currentPage: currentPage,
          setCurrentPage: setCurrentPage,
        }}
      />
    </div>
  );
};

export default PreRegistrationHistory;
