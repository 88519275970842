import React, { useState } from "react";
import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { ISelected } from "@interface/IOrder.interface";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { ISelectPatient } from "@interface/IGlobal.interface";
import { IGetPatientList } from "@interface/IOpd.interface";
import { useGetPatientsList } from "@services/api/patient";
import { Card, Col, Form, Row } from "antd";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import Selector from "@components/selector/Selector";
import OrderList from "../order_list/OrderList";
import ImagePatient from "@components/image/ImagePatient";

interface ITransferCourse {
  selectPatient: ISelectPatient;
  setSelectPatient: (e: ISelectPatient) => void;
}
type Props = {
  selectPatient: ITransferCourse;
  patientByID?: IGetPatientList;
  orderList: ISelectedExecuteOrder[];
  selection: ISelected;
  medicalRecord: boolean;
};

const Fields = ({
  selectPatient,
  patientByID,
  orderList,
  selection,
  medicalRecord,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState<boolean>(true);
  const patientList = useGetPatientsList({
    orderby: ENUM_ORDERBY.DESC,
    limit: 0,
  });

  const viewDetail = () => {
    setOpen(!open);
  };

  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room height-body  "
        extra={
          <>
            <Row
              justify="center"
              gutter={[14, 14]}
              className="text-row-profile ml-05"
              align="middle"
            >
              <Col xs={4} md={4} lg={3} xl={2}>
                <ImagePatient src={patientByID?.image} />
              </Col>
              <Col>
                <div>
                  {i18n.language === "th"
                    ? [patientByID?.firstNameTh, patientByID?.lastNameTh]
                        ?.join(" ")
                        ?.trim()
                    : [patientByID?.firstNameEn, patientByID?.lastNameEn]
                        ?.join(" ")
                        ?.trim()}
                </div>

                <div className="code-profile ">HN : {patientByID?.hn}</div>
              </Col>
              <Col>
                <span className="mr-05">{`${t("age")} :`} </span>
                <span className="t-grey-light">
                  {Age(patientByID?.dateOfBirth || "")}
                </span>
              </Col>

              <Col>
                <span className="mr-05">{`${t("sex")} :`} </span>
                <span className="t-grey-light">
                  {gender(patientByID?.gender, i18n?.language)}
                </span>
              </Col>

              <Col>
                <span className="mr-05">{`${t("phoneNumber")} :`} </span>
                <span className="t-grey-light">
                  {patientByID?.mobile || "-"}
                </span>
              </Col>
            </Row>
          </>
        }
      >
        <div className="mt-1 ">
          <Form name="editOrderQty">
            <Form.Item
              label={t("courseRecipient")}
              className="pre-register-form"
              name="id"
              validateStatus={selectPatient?.selectPatient.validateStatus}
              help={selectPatient?.selectPatient?.errorMsg}
            >
              <Selector
                disabled={!medicalRecord}
                onChange={(e) => {
                  if (e) {
                    selectPatient.setSelectPatient({
                      ...selectPatient?.selectPatient,
                      id: e,
                      validateStatus: "success",
                      errorMsg: null,
                    });
                  } else {
                    selectPatient.setSelectPatient({
                      ...selectPatient?.selectPatient,
                      id: e,
                      validateStatus: "error",
                      errorMsg: `${t("pleaseSelectACourseRecipient")}`,
                    });
                  }
                }}
                Source={
                  patientList?.data?.result?.[0]?.data
                    ?.filter((item) => {
                      if (item?.id !== 33) {
                        return item;
                      } else {
                        return false;
                      }
                    })
                    ?.map((value) => {
                      return {
                        id: value?.id,
                        name:
                          i18n.language === "th"
                            ? [value?.hn, value?.firstNameTh, value?.lastNameTh]
                                ?.join(" ")
                                ?.trim()
                            : [value?.hn, value?.firstNameEn, value?.lastNameEn]
                                ?.join(" ")
                                ?.trim(),
                      };
                    }) || []
                }
                keyName="name"
                keyValue="id"
              />
            </Form.Item>
          </Form>
        </div>
        <div className="mt-2 ">
          <Row className=" header-table ">
            <Col className="f-normal  t-dark-blue  ">
              <span className="f-bold mr-05 ">{t("confirmedOrderList")}</span>
              <span>
                {React.createElement(open ? UpOutlined : DownOutlined, {
                  onClick: viewDetail,
                })}
              </span>
            </Col>
          </Row>

          <div className={open ? "open" : "close"}>
            <OrderList
              orderList={orderList}
              selection={selection}
              medicalRecord={medicalRecord}
            />
          </div>
        </div>
      </Card>
    </>
  );
};

export default Fields;
