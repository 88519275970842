import { t } from "i18next";
import { dayjs } from "@tools/timezone";
import { Component } from "react";
import { IChild2 } from "@interface/IBill.interface";
import { ElementaryOneMore, IVisitBill } from "@interface/IGlobal.interface";
import { Button, Col, Divider, Row, Table } from "antd";
import Decimal from "@tools/decimal";
import ImageLogo from "@components/image/ImageLogo";
import { IDraftBillByID } from "@interface/IDraftBill.interface";
import { _amountToWordsEN, splitData } from "@tools/util";
import { IItem } from "@interface/IOrder.interface";
import React from "react";
import BigNumber from "bignumber.js";

type Props = {
  ref: any;
  billIDList?: ElementaryOneMore<IDraftBillByID>;
  divHeight: any;
  visible: IVisitBill;
  setVisible: (e: IVisitBill) => void;
  language: string;
};
type IOrderGroups = {
  key: any;
  level?: number | undefined;
  item?: IItem | undefined;
  children?: any[] | undefined;
}[];

class ComponentToPrint extends Component<Props> {
  render() {
    const patient = this.props?.billIDList?.result?.[0]?.order?.patient;
    const orderGroups: IOrderGroups =
      this.props?.billIDList?.result?.[0]?.order.groups?.flatMap((item) => {
        return item?.children?.flatMap((child) => {
          return child?.children?.flatMap((childLevel3) => {
            return {
              ...childLevel3,
              key: childLevel3?.item?.id,
            };
          });
        });
      }) || [];
    // const groupSizes = [8, 27];
    const groupSizes = [18, 27];
    const tData = splitData(orderGroups, ...groupSizes);

    const THBText =
      this.props.language === "th"
        ? require("thai-baht-text")
        : _amountToWordsEN;
    const nameTH = [
      // patient?.titleTh,
      patient?.firstNameTh,
      patient?.lastNameTh,
    ]
      ?.join(" ")
      ?.trim();
    const nameEN = [
      // patient?.titleEn,
      patient?.firstNameEn,
      patient?.lastNameEn,
    ]
      ?.join(" ")
      ?.trim();

    const userTh = [
      this.props?.billIDList?.result?.[0]?.user?.firstNameTh,
      this.props?.billIDList?.result?.[0]?.user?.lastNameTh,
    ]
      ?.join(" ")
      ?.trim();

    const userEn = [
      this.props?.billIDList?.result?.[0]?.user?.firstNameEn,
      this.props?.billIDList?.result?.[0]?.user?.lastNameEn,
    ]
      ?.join(" ")
      ?.trim();

    const mailingAddress = patient?.mailingAddress;
    const mailAddress =
      mailingAddress?.addressTh || mailingAddress?.districtTh
        ? [
            mailingAddress[
              this.props.language === "th" ? "addressTh" : "addressEn"
            ],
            mailingAddress[
              this.props.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            mailingAddress[
              this.props.language === "th" ? "districtTh" : "districtEn"
            ],
            mailingAddress[
              this.props.language === "th" ? "provinceTh" : "provinceEn"
            ],
            mailingAddress?.zipcode,
            mailingAddress?.country,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const _homeAddress = patient?.homeAddress;
    const homeAddress =
      _homeAddress?.addressTh || _homeAddress?.districtTh
        ? [
            _homeAddress[
              this.props.language === "th" ? "addressTh" : "addressEn"
            ],
            _homeAddress[
              this.props.language === "th" ? "subDistrictTh" : "subDistrictEn"
            ],
            _homeAddress[
              this.props.language === "th" ? "districtTh" : "districtEn"
            ],
            _homeAddress[
              this.props.language === "th" ? "provinceTh" : "provinceEn"
            ],
            _homeAddress?.country,
            _homeAddress?.zipcode,
          ]
            ?.join(" ")
            ?.trim()
        : "";
    const columns = [
      {
        title: "#",
        dataIndex: "id",
        key: "index",
        width: 50,
        render: (_: any, data: IChild2, index: number) => {
          return (
            <div>
              {data?.level === 1
                ? index + 1
                : data?.level === 2
                ? ""
                : data?.level === 3
                ? ""
                : data?.level === 4
                ? ""
                : data?.level === 5
                ? ""
                : index + 1}
            </div>
          );
        },
      },
      {
        title: `${t("productName")} / ${t("details")}`,
        dataIndex: "nameEn",
        key: "index",
        width: 300,
        render: (_: any, data: IChild2) => {
          return (
            <div>
              <div>
                {data?.level === 2
                  ? t("productName")
                  : data?.level === 3
                  ? data?.item?.product?.nameTh
                  : data?.level === 4
                  ? t("productName")
                  : data?.level === 5 && data?.item?.product?.nameTh}
              </div>
              <div style={{ color: "#777777" }}>
                {data?.item?.product?.descriptionTh}
              </div>
            </div>
          );
        },
      },
      {
        title: t("amount"),
        dataIndex: "qty",
        align: "center" as "center",
        width: 50,
        className: "relative",
        render: (qty: any, data: IChild2) => {
          return (
            <div className="Ctable-col-right">
              {data?.level === 2
                ? t("amount")
                : data?.level === 3
                ? data?.item?.qty
                : data?.level === 4
                ? ""
                : data?.level === 5
                ? ""
                : qty}
            </div>
          );
        },
      },

      {
        title: t("unitPrice"),
        dataIndex: "unitPrice",
        align: "right" as "right",
        with: 100,
        className: "relative",
        render: (_: any, data: IChild2) => {
          return (
            <div className="Ctable-col-right">
              {data?.level === 1
                ? ""
                : data?.level === 2
                ? t("unitPrice")
                : data?.level === 3
                ? Decimal(data?.item?.normal || 0)
                : data?.level === 4
                ? ""
                : data?.level === 5
                ? ""
                : ""}
            </div>
          );
        },
      },

      {
        title: t("discounts"),
        dataIndex: "unitPrice",
        align: "right" as "right",
        className: "relative",
        width: 100,
        render: (_: any, data: IChild2) => {
          return (
            <div className="Ctable-col-right">
              {data?.level === 1 ? (
                ""
              ) : data?.level === 2 ? (
                t("discounts")
              ) : data?.level === 3 ? (
                <div>
                  {data?.item?.discountPercent
                    ? `${data?.item?.discountPercent} % `
                    : data?.item?.discount
                    ? `${new BigNumber(data?.item?.discount).toFormat(2)} ฿ `
                    : ""}
                </div>
              ) : data?.level === 4 ? (
                ""
              ) : data?.level === 5 ? (
                ""
              ) : (
                ""
              )}
            </div>
          );
        },
      },
      {
        title: t("totalPrice"),
        dataIndex: "total",
        align: "right" as "right",
        className: "relative",
        width: 100,
        render: (total: any, data: IChild2) => {
          return (
            <div className="Ctable-col-right">
              {data?.level === 2
                ? t("totalPrice")
                : data?.level === 3
                ? Decimal(data?.item?.net || 0)
                : data?.level === 4
                ? t("amount")
                : data?.level === 5
                ? data?.item?.qty
                : Decimal(total)}
            </div>
          );
        },
      },
    ];
    const _TrHeaderPage = (page: number, size: number) => {
      return (
        <tr>
          <td
            style={{
              width: "50vw",
              fontWeight: "lighter",
              textAlign: "start",
            }}
          >
            {/* <div style={{ position: "absolute", right: 50, color: "#cccccc" }}> Page. {page}/{size} </div> */}
            <div className="mb-1">
              <ImageLogo
                src={this.props?.billIDList?.result?.[0]?.branch?.image}
                alt="Logo"
                width="200px"
                height="70px"
              />
            </div>
            <div className="example">
              <span className="break-inside ">
                {
                  this.props?.billIDList?.result?.[0]?.branch?.nameEn
                  // t("location.title")
                }
              </span>
              <span className="break-inside ">
                {
                  this.props?.billIDList?.result?.[0]?.branch?.nameTh
                  // t("location.title")
                }
              </span>
              <span className="break-inside ">
                {this.props.language === "th"
                  ? this.props?.billIDList?.result?.[0]?.branch?.addressTh
                  : this.props?.billIDList?.result?.[0]?.branch?.addressEn}
              </span>
              <span className="break-inside ">
                {[
                  this.props?.billIDList?.result?.[0]?.branch?.[
                    this.props.language === "th"
                      ? "subDistrictTh"
                      : "subDistrictEn"
                  ],
                  this.props?.billIDList?.result?.[0]?.branch?.[
                    this.props.language === "th" ? "districtTh" : "districtEn"
                  ],
                  this.props?.billIDList?.result?.[0]?.branch?.[
                    this.props.language === "th" ? "provinceTh" : "provinceEn"
                  ],
                  this.props?.billIDList?.result?.[0]?.branch?.zipcode,
                  this.props?.billIDList?.result?.[0]?.branch?.country,
                ]
                  ?.join(" ")
                  ?.trim()}
              </span>
            </div>

            <div>
              <span>{t("tel")} :</span>
              {this.props?.billIDList?.result?.[0]?.branch?.phone}
            </div>
            <div>
              <span>{t("taxId")} :</span>
              {this.props?.billIDList?.result?.[0]?.branch?.taxId}
            </div>
            <div className="pt-1">
              <span className="f-bold t-blue">{t("customer")} </span>
            </div>
            <div>
              <span className="break-inside ">
                {t("customerName")} :
                {this.props?.language === "th" ? nameTH : nameEN}
              </span>
            </div>
            <div>
              <span className="break-inside ">
                {t("address")} : {mailAddress || homeAddress || "-"}
              </span>
            </div>

            <div>
              <span className="break-inside ">
                {t("zipCode")} :
                {mailAddress
                  ? patient?.mailingAddress?.zipcode || "-"
                  : patient?.homeAddress?.zipcode || "-"}
              </span>
            </div>
          </td>

          <td
            style={{
              width: "50vw",
              fontWeight: "lighter",
              textAlign: "start",
            }}
          >
            <div className="t-center fs-20 t-blue f-bold">
              {t("medicalBillDetails")}
            </div>
            <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
              <Divider className="m-0 b-grey-white" />
            </div>

            <Row align="top" style={{ paddingBottom: "40px" }}>
              <Col span="6">{t("number")}</Col>

              <Col span="18">
                {this.props?.billIDList?.result?.[0]?.draftBillNumber}
              </Col>

              <Col span="6"> {t("date")}</Col>

              <Col span="18">
                {dayjs(this.props?.billIDList?.result?.[0]?.date).format(
                  "DD-MM-YYYY"
                )}
              </Col>
              <Col
                span="24"
                style={{ paddingTop: "15px", paddingBottom: "15px" }}
              >
                <Divider className="m-0 b-grey-white" />
              </Col>
            </Row>
          </td>
        </tr>
      );
    };

    return (
      <div className="book">
        {tData.map((groupItem, ind) => (
          <React.Fragment key={ind}>
            <div className="page">
              <div className="subpage">
                <div id="container">
                  <table className="report-drug f-light  " width="100%">
                    <tbody>
                      {String(ind) === "0"
                        ? _TrHeaderPage(ind + 1, tData.length)
                        : null}

                      <tr>
                        <td
                          colSpan={2}
                          style={{ paddingTop: ind !== 0 ? 0 : 19 }}
                        >
                          {/* <TableOrder groupItem={groupItem} /> */}
                          <Table
                            rowKey="key"
                            className="components-table-demo-nested table-selection table-confirm  table-list-children report-pay no-cursor"
                            rowClassName={(r) => {
                              if (r?.level === 1) {
                                return " no-header ";
                              } else if (r?.level === 2) {
                                return "table-header no-header ";
                              } else if (r?.level === 3) {
                                return " no-header ";
                              } else if (r?.level === 5) {
                                return "table-confirm-first no-header  ";
                              } else if (r?.level === 4) {
                                return "table-header no-header  ";
                              }
                              return "";
                            }}
                            columns={columns}
                            dataSource={groupItem}
                            pagination={false}
                          />
                        </td>
                      </tr>
                      {ind === tData.length - 1 && groupItem.length < 18 ? (
                        <>
                          <tr>
                            <td></td>
                            <td>
                              <table className="report-drug " width="100%">
                                <tbody>
                                  <tr className="t-end">
                                    <td style={{ width: "20vw" }}></td>

                                    <td style={{ width: "70vw" }}>
                                      <Row style={{ paddingTop: "20px" }}>
                                        <Col span={12}>{t("totalMoney")}</Col>
                                        <Col span={12}>
                                          {Decimal(
                                            Number(
                                              this.props?.billIDList
                                                ?.result?.[0]?.netPrice
                                            )
                                          )}
                                        </Col>

                                        <Col span={24}>
                                          <Row
                                            style={{
                                              backgroundColor: "#498dcb",
                                              color: "white",
                                            }}
                                          >
                                            <Col span={12}>
                                              {t("theTotalAmount")}
                                            </Col>
                                            <Col span={12}>
                                              {Decimal(
                                                Number(
                                                  this.props?.billIDList
                                                    ?.result?.[0]?.netPrice
                                                )
                                              )}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <span
                                style={{
                                  backgroundColor: "#498dcb",
                                  color: "white",
                                }}
                              >
                                {`(${THBText(
                                  Number(
                                    this.props?.billIDList?.result?.[0]
                                      ?.netPrice || 0
                                  )
                                )}) `}
                              </span>
                            </td>
                          </tr>
                        </>
                      ) : null}
                    </tbody>
                  </table>

                  {ind === tData.length - 1 && groupItem.length < 18 ? (
                    <div
                      // style={{ marginTop: `${heightTopPrint(this.props?.divHeight)}px` }}
                      className="footer-bill "
                    >
                      <Row
                        style={{ paddingTop: "80px" }}
                        align="middle"
                        className="break-inside"
                      >
                        <Col span={6} className="t-center pl-20 pt-10">
                          <div className=" f-normal t-center  image-signature">
                            {this?.props?.billIDList?.result?.[0]
                              ?.firstSignature && (
                              <img
                                src={
                                  this?.props?.billIDList?.result?.[0]
                                    ?.firstSignature || ""
                                }
                                alt="first"
                                height={70}
                              />
                            )}
                          </div>
                        </Col>
                        <Col span={6} className="t-center pl-20 pt-10">
                          <div className=" f-normal t-center  date-signature">
                            {dayjs(
                              this.props?.billIDList?.result?.[0]?.date
                            ).format("DD-MM-YYYY")}
                          </div>
                        </Col>
                        <Col span={6} className="t-center pl-20 pt-10">
                          <div className=" f-normal t-center  image-signature">
                            {this?.props?.billIDList?.result?.[0]
                              ?.secondSignature && (
                              <img
                                src={
                                  this?.props?.billIDList?.result?.[0]
                                    ?.secondSignature || ""
                                }
                                alt="second"
                                height={70}
                              />
                            )}
                          </div>
                        </Col>
                        <Col span={6} className="t-center pl-20 pt-10">
                          <div className=" f-normal t-center  date-signature">
                            {dayjs(
                              this.props?.billIDList?.result?.[0]?.date
                            ).format("DD-MM-YYYY")}
                          </div>
                        </Col>
                        <Col span={6} className="t-center pl-20 ">
                          <div className="pb-30">
                            <Divider className="m-0 b-grey-white" />
                          </div>

                          <Button
                            className="pb-1 signature-button"
                            onClick={(e) =>
                              this.props?.setVisible({
                                ...this.props?.visible,
                                first: !this.props?.visible.first,
                              })
                            }
                          >
                            {t("signHere")}
                          </Button>
                        </Col>
                        <Col span={6} className="t-center pl-20">
                          <div style={{ paddingBottom: "30px" }}>
                            <Divider className="m-0 b-grey-white" />
                          </div>
                        </Col>
                        <Col span={6} className="t-center pl-20">
                          <div className="pb-30">
                            <Divider className="m-0 b-grey-white" />
                          </div>
                          <Button
                            className="pb-1 signature-button"
                            onClick={(e) =>
                              this.props?.setVisible({
                                ...this.props?.visible,
                                second: !this.props?.visible.second,
                              })
                            }
                          >
                            {t("signHere")}
                          </Button>
                        </Col>
                        <Col span={6} className="t-center pl-20">
                          <div style={{ paddingBottom: "30px" }}>
                            <Divider className="m-0 b-grey-white" />
                          </div>
                        </Col>
                        <Col span={6} className="t-center pl-20 pt-10">
                          <div
                            style={{
                              fontSize:
                                Number(
                                  this.props?.language === "th"
                                    ? nameTH?.length || 0
                                    : nameEN?.length || 0
                                ) < 22
                                  ? 14
                                  : 250 /
                                    Number(
                                      this.props?.language === "th"
                                        ? nameTH?.length || 0
                                        : nameEN?.length || 0
                                    ),
                            }}
                          >
                            ({this.props?.language === "th" ? nameTH : nameEN})
                          </div>
                        </Col>
                        <Col span={6} className="t-center pl-20 pt-10">
                          {t("date")}
                        </Col>
                        <Col span={6} className="t-center pl-20 pt-10">
                          <div
                            style={{
                              fontSize:
                                Number(
                                  this.props?.language === "th"
                                    ? userTh?.length || 0
                                    : userEn?.length || 0
                                ) < 22
                                  ? 14
                                  : 250 /
                                    Number(
                                      this.props?.language === "th"
                                        ? userTh?.length || 0
                                        : userEn?.length || 0
                                    ),
                            }}
                          >
                            ({this.props?.language === "th" ? userTh : userEn})
                          </div>
                        </Col>
                        <Col span={6} className="t-center pl-20 pt-10">
                          {t("date")}
                        </Col>
                        <Col span={6} className="t-center pl-20 ">
                          <div>ผู้รับบริการ</div>
                        </Col>
                        <Col span={6} className="t-center pl-20 "></Col>
                        <Col span={6} className="t-center pl-20 ">
                          {`${t("location.title")}`}
                        </Col>
                        <Col span={6} className="t-center pl-20 "></Col>
                      </Row>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            {ind === tData.length - 1 && groupItem.length > 17 ? (
              <div className="page" key={ind}>
                <div className="subpage">
                  <table className="report-drug f-light  " width="100%">
                    <tbody>
                      {ind === tData.length - 1 ? (
                        <>
                          <tr>
                            <td></td>
                            <td>
                              <table className="report-drug " width="100%">
                                <tbody>
                                  <tr className="t-end">
                                    <td style={{ width: "20vw" }}></td>

                                    <td style={{ width: "70vw" }}>
                                      <Row style={{ paddingTop: "20px" }}>
                                        <Col span={12}>{t("totalMoney")}</Col>
                                        <Col span={12}>
                                          {Decimal(
                                            Number(
                                              this.props?.billIDList
                                                ?.result?.[0]?.netPrice
                                            )
                                          )}
                                        </Col>

                                        <Col span={24}>
                                          <Row
                                            style={{
                                              backgroundColor: "#498dcb",
                                              color: "white",
                                            }}
                                          >
                                            <Col span={12}>
                                              {t("theTotalAmount")}
                                            </Col>
                                            <Col span={12}>
                                              {Decimal(
                                                Number(
                                                  this.props?.billIDList
                                                    ?.result?.[0]?.netPrice
                                                )
                                              )}
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <span
                                style={{
                                  backgroundColor: "#498dcb",
                                  color: "white",
                                }}
                              >
                                {`(${THBText(
                                  Number(
                                    this.props?.billIDList?.result?.[0]
                                      ?.netPrice || 0
                                  )
                                )}) `}
                              </span>
                            </td>
                          </tr>
                        </>
                      ) : null}
                    </tbody>
                  </table>
                  <div
                    // style={{ marginTop: `${heightTopPrint(this.props?.divHeight)}px` }}
                    className="footer-bill "
                  >
                    <Row
                      style={{ paddingTop: "80px" }}
                      align="middle"
                      className="break-inside"
                    >
                      <Col span={6} className="t-center pl-20 pt-10">
                        <div className=" f-normal t-center  image-signature">
                          {this?.props?.billIDList?.result?.[0]
                            ?.firstSignature && (
                            <img
                              src={
                                this?.props?.billIDList?.result?.[0]
                                  ?.firstSignature || ""
                              }
                              alt="first"
                              height={70}
                            />
                          )}
                        </div>
                      </Col>
                      <Col span={6} className="t-center pl-20 pt-10">
                        <div className=" f-normal t-center  date-signature">
                          {dayjs(
                            this.props?.billIDList?.result?.[0]?.date
                          ).format("DD-MM-YYYY")}
                        </div>
                      </Col>
                      <Col span={6} className="t-center pl-20 pt-10">
                        <div className=" f-normal t-center  image-signature">
                          {this?.props?.billIDList?.result?.[0]
                            ?.secondSignature && (
                            <img
                              src={
                                this?.props?.billIDList?.result?.[0]
                                  ?.secondSignature || ""
                              }
                              alt="second"
                              height={70}
                            />
                          )}
                        </div>
                      </Col>
                      <Col span={6} className="t-center pl-20 pt-10">
                        <div className=" f-normal t-center  date-signature">
                          {dayjs(
                            this.props?.billIDList?.result?.[0]?.date
                          ).format("DD-MM-YYYY")}
                        </div>
                      </Col>
                      <Col span={6} className="t-center pl-20 ">
                        <div className="pb-30">
                          <Divider className="m-0 b-grey-white" />
                        </div>

                        <Button
                          className="pb-1 signature-button"
                          onClick={(e) =>
                            this.props?.setVisible({
                              ...this.props?.visible,
                              first: !this.props?.visible.first,
                            })
                          }
                        >
                          {t("signHere")}
                        </Button>
                      </Col>
                      <Col span={6} className="t-center pl-20">
                        <div style={{ paddingBottom: "30px" }}>
                          <Divider className="m-0 b-grey-white" />
                        </div>
                      </Col>
                      <Col span={6} className="t-center pl-20">
                        <div className="pb-30">
                          <Divider className="m-0 b-grey-white" />
                        </div>
                        <Button
                          className="pb-1 signature-button"
                          onClick={(e) =>
                            this.props?.setVisible({
                              ...this.props?.visible,
                              second: !this.props?.visible.second,
                            })
                          }
                        >
                          {t("signHere")}
                        </Button>
                      </Col>
                      <Col span={6} className="t-center pl-20">
                        <div style={{ paddingBottom: "30px" }}>
                          <Divider className="m-0 b-grey-white" />
                        </div>
                      </Col>
                      <Col span={6} className="t-center pl-20 pt-10">
                        <div
                          style={{
                            fontSize:
                              Number(
                                this.props?.language === "th"
                                  ? nameTH?.length || 0
                                  : nameEN?.length || 0
                              ) < 22
                                ? 14
                                : 250 /
                                  Number(
                                    this.props?.language === "th"
                                      ? nameTH?.length || 0
                                      : nameEN?.length || 0
                                  ),
                          }}
                        >
                          ({this.props?.language === "th" ? nameTH : nameEN})
                        </div>
                      </Col>
                      <Col span={6} className="t-center pl-20 pt-10">
                        {t("date")}
                      </Col>
                      <Col span={6} className="t-center pl-20 pt-10">
                        <div
                          style={{
                            fontSize:
                              Number(
                                this.props?.language === "th"
                                  ? userTh?.length || 0
                                  : userEn?.length || 0
                              ) < 22
                                ? 14
                                : 250 /
                                  Number(
                                    this.props?.language === "th"
                                      ? userTh?.length || 0
                                      : userEn?.length || 0
                                  ),
                          }}
                        >
                          ({this.props?.language === "th" ? userTh : userEn})
                        </div>
                      </Col>
                      <Col span={6} className="t-center pl-20 pt-10">
                        {t("date")}
                      </Col>
                      <Col span={6} className="t-center pl-20 ">
                        <div>ผู้รับบริการ</div>
                      </Col>
                      <Col span={6} className="t-center pl-20 "></Col>
                      <Col span={6} className="t-center pl-20 ">
                        {`${t("location.title")}`}
                      </Col>
                      <Col span={6} className="t-center pl-20 "></Col>
                    </Row>
                  </div>
                </div>
              </div>
            ) : null}
          </React.Fragment>
        ))}
      </div>
    );
  }
}
export default ComponentToPrint;
