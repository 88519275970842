import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Col, Row } from "antd";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { IDocumentDataList } from "@interface/IDocument.interface";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDeleteDocument, useGetDocument } from "@services/api/document";
import Link from "@assets/img/icon/Link.svg";
import File from "@assets/img/icon/File.svg";
import Modaler from "@components/modaler/Modaler";
import EditFileName from "@components/edit_file_name/EditFileName";
import RoleContext from "@provider/RoleProvider";
import { ENUM_MENU } from "@tools/util.enum";
import React from "react";

const ListMedicalDocument = () => {
  const { t, i18n } = useTranslation();
  const client = useQueryClient();
  const document = useGetDocument();
  const [visible, setVisible] = useState<boolean>(false);
  const [byID, setByID] = useState<number>();
  const deleteDocument = useDeleteDocument();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = useForm();
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === ENUM_MENU.DOCUMENT;
  });

  const onDelete = () => {
    deleteDocument.mutate(byID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
      },
      onError: (res) => {
        openNotification({ method: "DEL_ERROR", message: String(res) });
      },

      onSettled: () => {
        client.invalidateQueries(["get-document"]);
        setVisible(false);
      },
    });
  };

  const onManageModal = (e: boolean, upData?: boolean) => {
    setIsModalOpen(e);
    if (!!upData) {
      client.invalidateQueries(["get-document"]);
    }
  };

  return (
    <div>
      <Modaler
        visible={visible}
        onCancel={() => setVisible(false)}
        onOk={onDelete}
        loadingButton={deleteDocument?.isLoading}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("doYouWantToDelete")}`}
      </Modaler>
      {document?.data?.result?.[0]?.data?.map((item, index) => {
        return (
          <div key={index}>
            <div className="mt-1 mb-1 fs-16 t-blue f-normal">
              {i18n.language === "th" ? item?.nameTh : item?.nameEn}
            </div>

            {item?.data?.map((data: IDocumentDataList, i) => {
              return (
                <Row key={i} align="middle" className="mb-1 ">
                  <Col
                    className="cursor"
                    onClick={() => window.open(data?.file?.presigned)}
                  >
                    <Row align="middle">
                      <img
                        src={Link}
                        alt="Link"
                        width={13}
                        height={13}
                        style={{ marginRight: "2px" }}
                      />
                      <img
                        src={File}
                        alt="File"
                        width={25}
                        height={25}
                        style={{ marginRight: "5px" }}
                      />
                      <div>{data?.file?.fileName || ""}</div>
                    </Row>
                  </Col>
                  {medicalRecord?.[0]?.isEdit && (
                    <>
                      <Col
                        style={{
                          marginLeft: "auto",
                          paddingRight: 10,
                          color: "#094C97",
                        }}
                      >
                        <EditOutlined
                          onClick={() => {
                            onManageModal(!isModalOpen);

                            let name = data?.file?.fileName?.split(".");
                            let lastName = data?.file?.fileName?.slice(
                              data?.file?.fileName?.length -
                                Number(name?.[name?.length - 1]?.length + 1),
                              data?.file?.fileName?.length
                            );

                            form.setFieldsValue({
                              name: data?.file?.fileName?.slice(
                                0,
                                data?.file?.fileName?.length -
                                  Number(name?.[name?.length - 1]?.length + 1)
                              ),
                              lastName: lastName,
                              id: data?.file?.id,
                            });
                          }}
                        />
                      </Col>
                      <Col style={{ color: "red" }}>
                        <DeleteOutlined
                          onClick={() => {
                            setByID(data?.id);
                            setVisible(true);
                          }}
                        />
                      </Col>
                    </>
                  )}
                </Row>
              );
            })}
          </div>
        );
      })}
      {!!isModalOpen && (
        <EditFileName
          isModalOpen={isModalOpen}
          onManageModal={onManageModal}
          form={form}
        />
      )}
    </div>
  );
};

export default ListMedicalDocument;
