import { ENUM_ORDERBY } from "@tools/util.enum";
import { IReportAgent } from "@interface/IReport.interface";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Card, Col, Row, Image, Table } from "antd";
import { useGetReportCountPatientAgent } from "@services/api/report";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { useGetReportCountPatientAgentExport } from "@services/api/report";
import Excel from "@assets/img/icon/excel.png";
import Search from "./Search";
import MoTable from "@components/table/Table";
import BackHome from "@components/button/BackHome";
import dayjs from "dayjs";
import { ISearch } from "../inteface";
import BigNumber from "bignumber.js";

const AgentReport = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [download, setDownload] = useState(false);
  const [search, setSearch] = useState<ISearch>();
  const [limitPage, setLimitPage] = useState<number>(10);
  const [total, setTotal] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const { data } = useGetReportCountPatientAgent({
    page: currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    ...search,
  });
  useGetReportCountPatientAgentExport(download, {
    page: 1,
    limit: 0,
    orderby: ENUM_ORDERBY.DESC,
    ...search,
  });

  useEffect(() => {
    if (!data) return;
    setTotal(data?.result?.[0]?.total || 1);
  }, [data]);

  const onFinish = (value: ISearch) => {
    setDownload(false);

    setCurrentPage(1);
    let data = {
      ...value,
      startDate: value.start?.[0]
        ? dayjs(value.start?.[0]).format("YYYY-MM-DD")
        : undefined,
      endDate: value.start?.[1]
        ? dayjs(value.start?.[1]).format("YYYY-MM-DD")
        : undefined,
    };

    setSearch({ ...data });
  };

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      render: (_: IReportAgent, data: IReportAgent) => {
        return (
          <div>
            {i18n.language === "th"
              ? [data?.firstNameTh, data?.lastNameTh]?.join(" ")?.trim()
              : [data?.firstNameEn, data?.lastNameEn]?.join(" ")?.trim()}
          </div>
        );
      },
    },
  ];
  const expandedRowRender = (row: any) => {
    const columns = [
      {
        title: t("sort"),
        dataIndex: "order",
        key: "order",
        render: (_: any, data: any, index: number) => {
          return <div> {index + 1}</div>;
        },
      },
      {
        title: t("date"),
        key: "date",
        dataIndex: "visitDate",
        render: (visitDate: string) => {
          return visitDate && dayjs(visitDate).format("YYYY-MM-DD");
        },
      },
      {
        title: "HN",
        key: "hn",
        dataIndex: "hn",
      },
      {
        title: t("name"),
        key: "name",
        dataIndex: "name",
        render: (_: string, data: any) => {
          return (
            <div>
              {i18n.language === "th"
                ? [data?.firstNameTh, data?.lastNameTh]?.join(" ")?.trim()
                : [data?.firstNameEn, data?.lastNameEn]?.join(" ")?.trim()}
            </div>
          );
        },
      },
      {
        title: t("amount"),
        key: "count",
        dataIndex: "count",
        render: (count: string) => {
          return count || 0;
        },
      },
      {
        title: t("sum"),
        key: "sum",
        align: "right" as const,
        dataIndex: "sum",
        render: (sum: string) => {
          return <div>{`${new BigNumber(sum || 0).toFormat(2)}`}</div>;
        },
      },
      {
        title: t("congenitalDisease"),
        key: "diagnosis",
        dataIndex: "diagnosis",
        render: (diagnosis: string) => {
          return diagnosis || "";
        },
      },
    ];
    const data = row?.patient?.map((item: any, i: number) => {
      return { key: `${item.hn}-${i}`, ...item };
    });
    return <Table columns={columns} dataSource={data} pagination={false} />;
  };
  const newDatalist = data?.result?.[0]?.data?.map((item, i) => {
    return { key: i, ...item };
  });
  return (
    <div className="body-height">
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("reportSummary.title")}</h1>
              </div>
              <span className="t-light-gray">{t("report")} / </span>
              <span className="t-dark-blue f-normal">{t("agentReport")}</span>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={
            <Row align="middle">
              <Col>
                <b className="header-card ">{t("agentReport")}</b>
              </Col>
              <Col
                className="cursor"
                style={{ marginLeft: "auto" }}
                onClick={() => setDownload(true)}
              >
                <Image src={Excel} width={70} preview={false} />
              </Col>
            </Row>
          }
        >
          <Search onFinish={onFinish} />
          <MoTable
            rowClassName={() => {
              return "no-header no-cursor";
            }}
            className="table-selection"
            columns={columns}
            dataSource={newDatalist || []}
            expandable={{
              expandedRowRender,
              rowExpandable: (record) => record?.patient?.length > 0,
            }}
            onChangePage={onChangePage}
            scroll={{ y: window.innerHeight - 600, x: 500 }}
            rowKey="key"
            key="key"
            config={{
              total: total,
              pageSize: limitPage,
              currentPage: currentPage,
            }}
          />
        </Card>
      </div>
    </div>
  );
};

export default AgentReport;
