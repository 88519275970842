import { dayjs } from "@tools/timezone";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { cardType, day, month } from "@assets/data/data";
import { useGetHistoryPreregister } from "@services/api/patient";
import { useContext, useEffect, useState } from "react";
import { Card, Col, DatePicker, Form, FormInstance, Input, Row } from "antd";
import moment from "moment";
import TextArea from "antd/lib/input/TextArea";
import Selector from "@components/selector/Selector";
import FileSpace from "@components/file_space/FileSpace";
import UserContext from "@provider/UserProvider";
import OrderHistory from "../order_history/OrderHistory";
import SelectorChildrenValue from "@components/selector/SelectorChildrenValue";

type Props = {
  preRegister: FormInstance;
  patientById?: IGetPatientList;
};

const Fields = ({ preRegister, patientById }: Props) => {
  const [yearSelect, setYearSelect] = useState<any[]>([]);
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const eventID = match.params.eventID;
  const dateFormat = "DD-MM-YYYY";
  const draftOrderList = useGetHistoryPreregister(patientID, eventID);
  const { t, i18n } = useTranslation();
  const userList = useContext(UserContext);

  useEffect(() => {
    setYear();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    preRegister.setFieldsValue({
      ...patientById,
      serchPatient: patientById?.id || patientID || undefined,
      day: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("DD")
        : undefined,
      month: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("MM")
        : undefined,
      year: patientById?.dateOfBirth
        ? dayjs(patientById?.dateOfBirth).format("YYYY")
        : undefined,
      registrant:
        i18n.language === "th"
          ? [userList?.firstNameTh, userList?.lastNameTh]?.join(" ")?.trim()
          : [userList?.firstNameEn, userList?.lastNameEn]?.join(" ")?.trim(),
      date: patientById?.createdAt ? moment(patientById?.createdAt) : moment(),
      file: !!draftOrderList?.data?.data?.file
        ? [
            {
              url: draftOrderList?.data?.data?.file?.presigned,
              name: draftOrderList?.data?.data?.file?.fileName,
            },
          ]
        : undefined,
    });
    // eslint-disable-next-line
  }, [patientById, draftOrderList?.data]);

  const setYear = () => {
    const year = new Array(100)
      ?.fill({ name: "", id: "" })
      ?.map((_: any, index: number) => {
        return { name: `${2565 - index}`, id: `${2022 - index}` };
      });
    setYearSelect(year);
  };

  const changeYear = (year: string) => {
    if (!year) {
      preRegister.setFieldsValue({
        year: null,
      });
    } else {
      preRegister.setFieldsValue({
        year: (Number(year) - 543)?.toString(),
      });
    }
  };
  return (
    <>
      <Form name="preRegister " form={preRegister}>
        <Card
          className="card-prefix"
          extra={
            <b className="header-card t-blue fs-18">
              {t("preRegister.register")}
            </b>
          }
        >
          <Card className="mt-05">
            <Row>
              <Col span="12">
                <Row gutter={[14, 14]} className="margin-form">
                  <Col lg={6}>
                    <Form.Item label={t("prefixTh")} name="titleTh">
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("nameTh")} name="firstNameTh">
                      <Input disabled id="firstNameTh" />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("lastNameTh")} name="lastNameTh">
                      <Input disabled id="lastNameTh" />
                    </Form.Item>
                  </Col>

                  <Col lg={6}>
                    <Form.Item label={t("prefixEn")} name="titleEn">
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("nameEn")} name="firstNameEn">
                      <Input disabled id="firstNameEn" />
                    </Form.Item>
                  </Col>

                  <Col lg={9}>
                    <Form.Item label={t("lastNameEn")} name="lastNameEn">
                      <Input disabled id="lastNameEn" />
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item label={t("contactNumber")} name="mobile">
                      <Input disabled />
                    </Form.Item>
                  </Col>

                  <Col className="mt-1" span="24">
                    <div className="t-grey-form">{t("dateOfBirth")}:</div>
                    <div className="mt-form">
                      <Row gutter={[5, 14]}>
                        <Col lg={4}>
                          <Form.Item name="day">
                            <SelectorChildrenValue
                              placeholder={t("day")}
                              Source={day}
                              keyName="name"
                              keyValue="id"
                              disabled
                              id="day"
                              onChange={() => {
                                document.getElementById("month")?.focus();
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col lg={10}>
                          <Form.Item name="month">
                            <SelectorChildrenValue
                              placeholder={t("month")}
                              Source={month}
                              disabled
                              keyName={
                                i18n.language === "th" ? "nameTh" : "nameEn"
                              }
                              keyValue="id"
                              id="month"
                              onChange={() => {
                                document.getElementById("year")?.focus();
                              }}
                            />
                          </Form.Item>
                        </Col>

                        <Col lg={10}>
                          <Form.Item name="year">
                            <SelectorChildrenValue
                              placeholder={t("year")}
                              Source={yearSelect}
                              disabled
                              keyName="id"
                              keyValue="name"
                              id="year"
                              onChange={(e) => {
                                document.getElementById("remark")?.focus();
                                changeYear(e);
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    </div>
                  </Col>

                  <Col lg={10}>
                    <Form.Item
                      label={t("cardType")}
                      name="idCard"
                      rules={[{ required: true, message: t("pleaseSelect") }]}
                    >
                      <Selector
                        disabled
                        onChange={(e) => {
                          document.getElementById("idCardNumber")?.focus();
                          preRegister.setFieldsValue({
                            ...preRegister,
                            idCardNumber: "",
                          });
                        }}
                        placeholder=""
                        Source={cardType}
                        keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                        keyValue="id"
                        id="idCard"
                      />
                    </Form.Item>
                  </Col>
                  <Col lg={14}>
                    <Form.Item
                      shouldUpdate={(prev, next) =>
                        prev?.idCard !== next?.idCard
                      }
                    >
                      {(form: FormInstance) => {
                        const idCard = form.getFieldValue("idCard");

                        if (idCard === 1) {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[{ required: true }]}
                            >
                              <Input disabled id="idCardNumber" />
                            </Form.Item>
                          );
                        } else if (idCard === 0) {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[
                                () => ({
                                  validator(_, value) {
                                    if (!value) {
                                      return Promise.reject(
                                        new Error(`${t("pleaseInput")}!`)
                                      );
                                    } else if (!/^(-$)|\d+$/.test(value)) {
                                      return Promise.reject(
                                        new Error(t("pleaseFillInNumbers"))
                                      );
                                    } else if (value?.length < 13) {
                                      return Promise.reject(
                                        new Error(t("moreCardNumbers"))
                                      );
                                    } else if (value?.length > 13) {
                                      return Promise.reject(
                                        new Error(t("theCardNumberLess"))
                                      );
                                    } else {
                                      return Promise.resolve();
                                    }
                                  },
                                }),
                              ]}
                            >
                              <Input id="idCardNumber" disabled />
                            </Form.Item>
                          );
                        } else {
                          return (
                            <Form.Item
                              label={t("cardNumber")}
                              name="idCardNumber"
                              rules={[{ required: true }]}
                            >
                              <Input disabled />
                            </Form.Item>
                          );
                        }
                      }}
                    </Form.Item>
                  </Col>

                  <Col lg={24}>
                    <Form.Item label={t("details")} name="remark">
                      <TextArea
                        disabled
                        autoSize={{ minRows: 2, maxRows: 3 }}
                        placeholder={t("moreDetails")}
                      />
                    </Form.Item>
                  </Col>

                  {patientID && (
                    <Col lg={24}>
                      <Form.Item
                        label={t("file")}
                        name="file"
                        valuePropName="fileList"
                      >
                        <FileSpace
                          disabled={true}
                          maxCount={1}
                          accept=".pdf,.png,.JPG"
                          showUploadList={{
                            showDownloadIcon: true,
                          }}
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Row>
              </Col>
              <Col span="12">
                <Row gutter={[14, 14]} className="margin-form" justify="end">
                  <Col lg={16}>
                    <Form.Item
                      className="pre-register-form"
                      label={`${t("date")} :`}
                      name="date"
                    >
                      <DatePicker disabled format={dateFormat} />
                    </Form.Item>
                  </Col>

                  <Col lg={16}>
                    <Form.Item
                      className="pre-register-form"
                      label={`${t("registrant")} :`}
                      name="registrant"
                    >
                      <Input placeholder={t("registrant")} disabled />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>

            {patientID ? (
              <>
                <OrderHistory
                  patientID={patientID}
                  draftOrderList={draftOrderList?.data?.data?.groups}
                />
              </>
            ) : null}
          </Card>
        </Card>
      </Form>
    </>
  );
};

export default Fields;
