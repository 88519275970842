import React, { FC, useEffect, useState } from "react";
import { ENUM_ROLE_EN } from "@tools/util.enum";
import { FormInstance } from "antd/es/form/Form";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { MANAGE_COURSES } from "@assets/data/pathRouter";
import { IItem, IProduct } from "@interface/IOrder.interface";
import { openNotification } from "@components/notification/Notification";
import { useGetBranchList } from "@services/api/branch";
import { IProductCourseItem } from "@interface/IProductCourseItem";
import { useGetProductCourseItem } from "@services/api/product_course_item";
import { Button, Card, Col, Empty, TreeSelect } from "antd";
import { ICreateProductCourseItem } from "@interface/IProductCourseItem";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { useCreateProductCourseItem } from "@services/api/product_course_item";
import { useUpdateProductCourseItem } from "@services/api/product_course_item";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { Form, Input, Popover, Row } from "antd";
import empty from "@assets/img/empty.svg";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import BigNumber from "bignumber.js";
import UserContext from "@provider/UserProvider";
import ProductCategory from "@components/product_category/ProductCategory";
import { useBranchSelector } from "@provider/select_branch";

type Props = {
  formCourse: FormInstance;
};

const Fields: FC<Props> = ({ formCourse }) => {
  const { TreeNode } = TreeSelect;
  const { branchId } = useBranchSelector();
  const userList = React.useContext(UserContext);
  const { t, i18n } = useTranslation();
  const [visible, setVisible] = useState({ product: false, modal: false });
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const manageCourseId = match.params.id;
  const branchList = useGetBranchList(true, { limit: 0 });
  const productCourseItem = useGetProductCourseItem(manageCourseId);
  const create = useCreateProductCourseItem();
  const update = useUpdateProductCourseItem();
  const [ids, setIds] = useState<any>([]);
  const [orderList, setOrderList] = useState<any>({
    key: [{ key: [], dataList: [] }],
    order: [],
  });

  useEffect(() => {
    const roleList = ["SUPER_ADMIN", "SUPER_MARKETING"];
    let newBranchList: number[] = [];
    if (
      !roleList.includes(userList?.role) &&
      branchList?.data?.result?.[0]?.data?.[0]?.id
    ) {
      newBranchList = [branchList?.data?.result?.[0]?.data?.[0]?.id];
    } else if (
      roleList.includes(userList?.role) &&
      branchId &&
      branchId !== 0
    ) {
      newBranchList = [branchId];
    }
    if (!manageCourseId && manageCourseId !== 0) {
      formCourse.setFieldsValue({
        branchIds: newBranchList,
      });
    } else {
      formCourse.setFieldsValue({
        branchIds: productCourseItem?.data?.result?.[0]?.branchIds,
        ...productCourseItem?.data?.result?.[0]?.product,
      });

      setOrderList({
        key: [
          {
            key: productCourseItem?.data?.result?.[0]?.key || [],
            dataList: productCourseItem?.data?.result?.[0]?.courses || [],
          },
        ],
        order: productCourseItem?.data?.result?.[0]?.courses || [],
      });
    }
    const branchListIds = branchList?.data?.result?.[0]?.data?.map(
      ({ id }) => id
    );
    setIds(branchListIds);

    //eslint-disable-next-line
  }, [branchList?.data, productCourseItem?.data, manageCourseId]);

  const onManageModal = () => {
    setVisible({ ...visible, product: !visible.product });
  };

  const onModal = () => {
    setVisible({ ...visible, modal: !visible.modal });
  };

  const onFinish = (value: IProductCourseItem) => {
    const roleList = ["SUPER_ADMIN", "SUPER_MARKETING"];
    if (orderList?.order?.length) {
      const product: ICreateProductCourseItem = {
        product: value,
        branchIds:
          !roleList.includes(userList?.role) || (branchId && branchId !== 0)
            ? value?.branchIds
            : value?.branchIds?.length ===
              branchList?.data?.result?.[0]?.data?.length
            ? [0]
            : value?.branchIds,
        courses: orderList?.order?.map((productItem: IItem) => {
          return {
            productId: productItem?.id,
            qty: productItem?.qty,
          };
        }),
      };
      if (!manageCourseId && manageCourseId !== 0) {
        createProductCourseItem(product);
      } else {
        updateProductCourseItem(product);
      }
    } else {
      onModal();
    }
  };
  const createProductCourseItem = (product: ICreateProductCourseItem) => {
    create.mutate(product, {
      onSuccess: () => {
        openNotification({ method: "POST_SUCCESS" });
        history.replace(`${MANAGE_COURSES.title}`);
      },
      onError: (err) => {
        openNotification({ method: "POST_ERROR", message: String(err) });
      },
    });
  };

  const updateProductCourseItem = (product: ICreateProductCourseItem) => {
    delete product?.product?.branchIds;

    update.mutate(
      { data: product, id: manageCourseId },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(`${MANAGE_COURSES.title}`);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: any, data: any, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: t("nameList"),
      dataIndex: "nameTh",
      key: i18n.language === "th" ? "nameTh" : "nameEn",
      render: (name: string, data: IItem) => {
        return (
          <div>
            {i18n.language === "th"
              ? name || data?.product?.nameTh
              : name || data?.product?.nameEn || "-"}
          </div>
        );
      },
    },

    {
      title: t("type"),
      dataIndex: "group",
      key: "group",
      render: (_: any, data: IProduct) => {
        return (
          <div>
            {i18n.language === "th"
              ? data?.group?.nameTh
              : data?.group?.nameEn || "-"}
          </div>
        );
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",
      key: "qty",
      render: (qty: number) => {
        return <div>{qty || ""}</div>;
      },
    },

    {
      title: t("details"),
      dataIndex: "descriptionTh",
      key: "descriptionTh",
      render: (_: string, dataList: IProduct) => {
        return (
          <Popover
            content={
              i18n.language === "th"
                ? dataList?.descriptionTh || "-"
                : dataList?.descriptionEn || "-"
            }
            key={dataList?.id}
          >
            <div className="text-content">
              {i18n.language === "th"
                ? dataList?.descriptionTh || "-"
                : dataList?.descriptionEn || "-"}
            </div>
          </Popover>
        );
      },
    },

    {
      title: t("price"),
      dataIndex: "qty",
      key: "qty",
      render: (qty: string, data: IProduct) => {
        return (
          <div>
            {new BigNumber(qty || 0)
              .multipliedBy(data?.normal || 0)
              .toFormat(2)}
          </div>
        );
      },
    },
  ];

  return (
    <React.Fragment>
      <Modaler
        visible={visible?.modal}
        onCancel={onModal}
        onOk={onModal}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {`${t("youMustSelectTheProductFirst")}`}
      </Modaler>
      <Card
        className="card-prefix  pb-2"
        extra={
          <b className="fs-16 t-black-light ">
            {!manageCourseId && manageCourseId !== 0
              ? t("createCourseList")
              : t("updateCourseList")}
          </b>
        }
      >
        <Form
          name="formCourse"
          form={formCourse}
          onFinish={onFinish}
          scrollToFirstError
        >
          <Row gutter={[14, 14]}>
            <Col lg={12}>
              <Form.Item
                name="nameTh"
                label={`${t("name")} (TH)`}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                name="nameEn"
                label={`${t("name")} (EN)`}
                rules={[{ required: true }]}
              >
                <Input />
              </Form.Item>
            </Col>

            {/* <Col lg={12}>
              <Form.Item
                name="qty"
                label={`${t("amount")}`}
                rules={[
                  { required: true },
                  () => ({
                    validator(_, value) {
                      if (!/^(-$)|\d+$/.test(value) && !!value) {
                        return Promise.reject(new Error(t("enterOnlyNumbers")));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col> */}

            <Col lg={12}>
              <Form.Item
                name="descriptionTh"
                label={`${t("description")} (TH)`}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                name="descriptionEn"
                label={`${t("description")} (EN)`}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                name="foreigner"
                label={`${t("foreignerPrice")}`}
                rules={[
                  { required: true },
                  () => ({
                    validator(_, value) {
                      if (!/^(-$)|\d+$/.test(value) && !!value) {
                        return Promise.reject(new Error(t("enterOnlyNumbers")));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                name="normal"
                label={`${t("thaiPrice")}`}
                rules={[
                  { required: true },
                  () => ({
                    validator(_, value) {
                      if (!/^(-$)|\d+$/.test(value) && !!value) {
                        return Promise.reject(new Error(t("enterOnlyNumbers")));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col lg={12}>
              <Form.Item
                name="partner"
                label={`${t("partnerPrice")}`}
                rules={[
                  { required: true },
                  () => ({
                    validator(_, value) {
                      if (!value) {
                        return Promise.resolve();
                      } else if (!/^(-$)|\d+$/.test(value)) {
                        return Promise.reject(new Error(t("enterOnlyNumbers")));
                      } else {
                        return Promise.resolve();
                      }
                    },
                  }),
                ]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                shouldUpdate={(prev, next) => prev.branchIds !== next.branchIds}
                noStyle
              >
                {(form: FormInstance) => {
                  const branchIds = form?.getFieldValue("branchIds");

                  return (
                    <Form.Item label={`${t("branch")}`} name="branchIds">
                      <TreeSelect
                        className="user-room-update"
                        treeCheckable={true}
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        allowClear
                        disabled={
                          (userList?.role !== ENUM_ROLE_EN.SUPER_ADMIN &&
                            userList?.role !== ENUM_ROLE_EN.SUPER_MARKETING) ||
                          (branchId && branchId !== 0)
                            ? true
                            : false
                        }
                        placeholder="Please select"
                      >
                        {
                          <TreeNode
                            value="all"
                            title={
                              <React.Fragment>
                                <span>
                                  {branchIds?.length === 0 && (
                                    <span
                                      style={{
                                        display: "inline-block",
                                        color: "#ccc",
                                        cursor: "not-allowed",
                                      }}
                                    >
                                      Select all
                                    </span>
                                  )}
                                  {branchIds?.length !== 0 &&
                                    branchIds?.length <
                                      (branchList?.data?.result?.[0]?.data
                                        ?.length || 0) && (
                                      <span
                                        onClick={() =>
                                          formCourse.setFieldsValue({
                                            branchIds: [...ids],
                                          })
                                        }
                                        style={{
                                          display: "inline-block",
                                          color: "#286FBE",
                                          cursor: "pointer",
                                        }}
                                      >
                                        Select all
                                      </span>
                                    )}
                                </span>
                                <span style={{ marginLeft: "5px" }}>
                                  {branchIds?.length === 0 && (
                                    <span
                                      style={{
                                        display: "inline-block",
                                        color: "#ccc",
                                        cursor: "not-allowed",
                                      }}
                                    >
                                      Unselect all
                                    </span>
                                  )}
                                  {branchIds?.length > 0 && (
                                    <span
                                      onClick={() =>
                                        formCourse.setFieldsValue({
                                          branchIds: [],
                                        })
                                      }
                                      style={{
                                        display: "inline-block",
                                        color: "#286FBE",
                                        cursor: "pointer",
                                      }}
                                    >
                                      Unselect all
                                    </span>
                                  )}
                                </span>
                              </React.Fragment>
                            }
                            disableCheckbox={true}
                            disabled={true}
                          />
                        }

                        {Array.from(
                          new Map(
                            [
                              ...(branchList?.data?.result?.[0]?.data || []),
                              ...(productCourseItem?.data?.result?.[0]
                                ?.branchList || []),
                            ]?.map((item) => [item?.id, item])
                          )?.values()
                        )?.map((item, idx) => (
                          <TreeNode
                            key={item?.id}
                            value={item?.id}
                            title={
                              i18n.language === "th"
                                ? item?.nameTh
                                : item?.nameEn
                            }
                          ></TreeNode>
                        ))}
                      </TreeSelect>
                    </Form.Item>
                  );
                }}
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[14, 14]} className="mt-2">
            <Col span={24}>
              <Row className="header-table  " align="middle">
                <Col className="f-normal t-dark-blue">
                  <span className="f-bold mr-05 ">
                    {t("selectAProductList")}
                  </span>
                </Col>
                {orderList?.order?.length > 0 ? (
                  <Col style={{ marginLeft: "auto" }}>
                    <Button type="primary" onClick={onManageModal}>
                      {`${t("addOrder")}`}
                    </Button>
                  </Col>
                ) : (
                  " "
                )}
              </Row>
              <MoTable
                className="components-table-demo-nested table-selection table-confirm "
                pagination={false}
                locale={{
                  emptyText: (
                    <>
                      <Empty
                        image={empty}
                        imageStyle={{
                          height: 60,
                        }}
                        description={
                          <span className="t-grey-white">
                            {t("noInformation")}
                          </span>
                        }
                      >
                        <Button type="primary" onClick={onManageModal}>
                          {`+ ${t("addOrder")}`}
                        </Button>
                      </Empty>
                    </>
                  ),
                }}
                rowClassName={(r) => {
                  if (r?.level === 1) {
                    return " no-header no-cursor";
                  } else if (r?.level === 2) {
                    return "table-header no-header no-cursor";
                  } else if (r?.level === 3) {
                    return " no-header";
                  }
                  return "";
                }}
                expandRowByClick
                rowKey={"id"}
                key={"id"}
                columns={columns}
                dataSource={
                  orderList?.order?.length > 0 ? orderList?.order : []
                }
                // onClickRow={(e: IItem) => checkRow(e) && onClickRow(e)}
                onDoubleClick={false}
                scroll={{ x: "calc(100vw -20%)" }}
              />
            </Col>
          </Row>
        </Form>
      </Card>

      {visible && (
        <ProductCategory
          visible={visible?.product}
          onModal={onManageModal}
          status="manage-courses"
          orderList={{ orderList: orderList, setOrderList: setOrderList }}
        />
      )}
    </React.Fragment>
  );
};

export default Fields;
