import { Card } from "antd";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { VISIT_OLD_FILES } from "@tools/util.enum";
import Table from "./Table";
import Header from "./Header";

const HistoryUploadFile = () => {
  const { t } = useTranslation();

  const typeUpload: { [K in VISIT_OLD_FILES]: string } = {
    [VISIT_OLD_FILES?.VITAL_SIGN]: t("vitalSign"),
    [VISIT_OLD_FILES?.BIOLOGY]: t("biologicalTherapy"),
    [VISIT_OLD_FILES?.CONSENT]: t("consent"),
    [VISIT_OLD_FILES?.CORRESPONDENCE]: t("correspondence"),
    [VISIT_OLD_FILES?.LAB_RESULT]: t("labResults"),
    [VISIT_OLD_FILES?.MEDICAL_REPORT]: t("medicalReport"),
    [VISIT_OLD_FILES?.MEDICAL_SUMMARY]: t("medicalSummaryReports"),
    [VISIT_OLD_FILES?.PHYSICAL]: t("physicalTherapyRecords"),
    [VISIT_OLD_FILES?.PHYSICAL_ORDER]: t("physicianOrder"),
    [VISIT_OLD_FILES?.RADIOLOGY_REPORT]: t("radiologyReport"),
    [VISIT_OLD_FILES?.TREATMENT]: t("treatmentRecord"),
    [VISIT_OLD_FILES?.REPORT_DRUG]: t("medicationAdministration"),
  };
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const type: VISIT_OLD_FILES = match.params.fileId;
  return (
    <div>
      <Header title={typeUpload?.[type]} />
      <div className="set-layout ">
        <Card
          className="bg-grey table-card"
          extra={<b className="header-card ">{` ${typeUpload?.[type]}`}</b>}
        >
          <Table id={patientID} type={type} />
        </Card>
      </div>
    </div>
  );
};

export default HistoryUploadFile;
