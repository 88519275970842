import { Form } from "antd";
import { useTranslation } from "react-i18next";
import FormGeneral from "../../form/Form";

const FormCitizenship = () => {
  const { t } = useTranslation();
  const [confirmCitizenship] = Form.useForm();
  return (
    <div>
      <FormGeneral title={t("citizenship")} confirm={confirmCitizenship} />
    </div>
  );
};

export default FormCitizenship;
