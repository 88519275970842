import React, { useState } from "react";
import { OPD } from "@assets/data/pathRouter";
import { PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Card } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import UploadFile from "./UploadFile";
import PatientList from "@components/patient_list/PatientList";
import RoleContext from "@provider/RoleProvider";

const Opd = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const roleList = React.useContext(RoleContext);
  const [visible, setVisible] = useState(false);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "MEDICAL_RECORD";
  });

  const handleChangePage = () => {
    if (medicalRecord?.[0]?.isEdit) {
      history.push(OPD.basicInfo);
    }
  };

  return (
    <div>
      <Row className="second-header " align="middle">
        <Col>
          <div>
            <h1>{t("medicalRecord.title")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("nursery")}</b>
          </span>
        </Col>
        <Col style={{ marginLeft: "auto" }}>
          {medicalRecord?.[0]?.isEdit ? (
            <React.Fragment>
              <Button
                className="btn-create mr-05"
                type="primary"
                onClick={() => setVisible(!visible)}
              >
                {t("upload")}
              </Button>
              <Button
                className="btn-create "
                type="primary"
                onClick={handleChangePage}
              >
                <PlusOutlined className="icons-size" /> {t("createKeeper")}
              </Button>
            </React.Fragment>
          ) : null}
        </Col>
      </Row>
      <div className="set-layout ">
        <Card
          className="bg-grey "
          extra={<b className="header-card ">{t("allNursery")}</b>}
        >
          <PatientList path={OPD.profile} />
        </Card>
      </div>
      {visible && (
        <UploadFile visible={{ visible: visible, setVisible: setVisible }} />
      )}
    </div>
  );
};

export default Opd;
