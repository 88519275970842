import { Fragment } from "react";
import { UploadProps } from "antd/lib/upload";
import { useTranslation } from "react-i18next";
import { fileToDataUrl, _isEmpty } from "@tools/util";
import { Col, FormInstance, message, Upload, Image, Button } from "antd";
import AddImage from "@assets/img/icon/OPD/AddImage.svg";

interface Props extends UploadProps {
  form: FormInstance;
  name?: string;
  value?: any;
}

const ImageProfile = ({
  form,
  name = "media_name",
  disabled,
  value,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { Dragger } = Upload;

  const dummyRequest = ({ onSuccess }: any) => {
    setTimeout(() => {
      onSuccess("ok");
    }, 0);
  };

  const beforeUpload = (file: any) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("คุณสามารถอัปโหลดได้เฉพาะไฟล์ JPG/PNG เท่านั้น!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isLt2M) {
      message.error("รูปภาพต้องเล็กกว่า 2MB!");
    }

    return isJpgOrPng && isLt2M;
  };

  // function getBase64(img: any, callback: any) {
  //   const reader = new FileReader();
  //   if (img) {
  //     reader?.addEventListener("load", () => callback(reader.result));
  //     reader.readAsDataURL(img);
  //     // form.setFieldsValue({ [`${name}`]: img });
  //   }
  // }

  const Props = {
    name: "file",
    multiple: false,
    maxCount: 1,
    showUploadList: false,
    customRequest: dummyRequest,
    beforeUpload: beforeUpload,
    onChange(info: any) {
      const { status } = info.file;
      if (status !== "done") {
        fileToDataUrl?.(info.file.originFileObj)?.then((data) => {
          form.setFieldsValue({ [`${name}`]: data });
        });
        // getBase64(info.file.originFileObj, (imageUrl: any) => {
        //   // setImageUrl(imageUrl);
        //   form.setFieldsValue({ [`${name}`]: info?.file?.originFileObj });
        //   // setImageFile(info.file.originFileObj);
        // });
      }
    },

    onDrop(e: any) {
      console.log("Dropped files", e?.dataTransfer.files);
    },
  };

  return (
    <div>
      <Col lg={24}>
        <Dragger
          {...props}
          {...Props}
          disabled={disabled}
          className="uploadImage"
          // accept=".png,.jpg,.jpeg"
          listType="text"
        >
          {!_isEmpty(value) ? (
            <Image
              className="image-profile"
              src={value}
              width={"100%"}
              preview={false}
              style={{ objectFit: "cover" }}
            />
          ) : (
            <Fragment>
              <p className="ant-upload-drag-icon">
                <img src={AddImage} alt="AddImage" />
              </p>
              <div className="header-xs text-image">{t("clickImage")}</div>
            </Fragment>
          )}
        </Dragger>
        <div className=" mt-2 display-center ">
          <Button
            className="btn-delete"
            style={{ width: "100%" }}
            onClick={() => form.setFieldsValue({ [`${name}`]: undefined })}
          >
            {t("delete")}
          </Button>
        </div>
      </Col>
    </div>
  );
};

export default ImageProfile;
