import React, { useRef, useState } from "react";
import { IGroup } from "@interface/IDraftOrder.interface";
import { ORDER_TYPE } from "@tools/util.enum";
import { productName } from "@tools/util";
import { EditOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";
import { IItem, ISelected } from "@interface/IOrder.interface";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { useMainProductOutStanding } from "@provider/product_out_standing";
import { Form, InputNumber, Popover, Table } from "antd";
import Decimal from "@tools/decimal";
import EditModal from "@components/modaler/EditModal";
import RoleContext from "@provider/RoleProvider";

type Props = {
  orderList: ISelectedExecuteOrder[];
  selection: ISelected;
};

const OrderList = ({ orderList, selection }: Props) => {
  const { outStanding } = useMainProductOutStanding();
  const num = useRef(0);
  const { t, i18n } = useTranslation();
  const [editOrderQty] = Form.useForm();
  const [visible, setVisible] = useState<any>({
    number: false,
    discount: false,
  });
  const [product, setProduct] = useState<any>({
    idProduct: "",
    edit: "",
    value: null,
    level: null,
  });
  const roleList = React.useContext(RoleContext);
  const medicalRecord = roleList?.filter((item) => {
    return item?.nameEn === "PROCESS_ORDER";
  });

  const onCancelNumber = () => {
    setVisible({ ...visible, number: !visible?.number });
  };

  const updateOrderItem = async (value: any) => {
    if (product?.level === 5) {
      let every = false;
      orderList?.every((item: any) => {
        item?.children?.[0]?.children?.forEach((dataList: any) => {
          if (dataList?.children) {
            const indexList = dataList?.children?.[0]?.children?.findIndex(
              (list: any) => list?.id === product?.edit
            );

            if (indexList > -1) {
              every = true;
              dataList.children[0].children[indexList].qtyChange = Number(
                value?.qtyOutstanding
              );
              return false;
            }
            every = false;
            return false;
          }
        });

        if (every) {
          return false;
        }
        return true;
      });
    } else {
      orderList?.every((item: any) => {
        const indexList = item?.children?.[0]?.children?.findIndex(
          (list: any) => list?.id === product?.edit
        );

        if (indexList > -1) {
          item.children[0].children[indexList].qtyChange = Number(
            value?.qtyOutstanding
          );
          return false;
        }
        return true;
      });
    }

    setVisible({ discount: false, number: false });
  };
  const onSelectChange = (selected: any) => {
    const optionDrugCourse = outStanding
      ?.flatMap((parent) => {
        return parent?.items;
      })
      ?.filter(
        (item) =>
          (item?.product?.category?.id === "C-0006" ||
            item?.product?.category?.id === "C-0007") &&
          item?.type === ORDER_TYPE.OPTION_DRUG
      )
      ?.flatMap((parent) => {
        return parent?.children?.flatMap((child) => {
          return child?.children;
        });
      })
      ?.map((item) => {
        return item?.id;
      });

    const result = selected?.filter(
      (item: string) => !item?.includes("children")
    );

    selection.setSelection({
      ...selection.selection,
      course: Array.from(
        new Set([...(result || []), ...(optionDrugCourse || [])])
      ),
    });
  };

  const rowSelection = {
    selectedRowKeys: selection?.selection?.course,
    onChange: onSelectChange,
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: IItem, data: IGroup & IItem, index: number) => {
        return (
          <div>
            {data?.level === 1
              ? index + 1
              : data?.level === 2
              ? ""
              : data?.level === 3
              ? ""
              : data?.level === 4
              ? ""
              : ""}
          </div>
        );
      },
    },

    {
      title: t("nameList"),
      dataIndex: "name",
      key: "name",
      render: (_: IItem, data: IGroup & IItem) => {
        return (
          <div>
            {data?.level === 1
              ? i18n.language === "th"
                ? data?.children?.[0]?.children?.[0]?.product?.category?.nameTh
                : data?.children?.[0]?.children?.[0]?.product?.category?.nameEn
              : data?.level === 2
              ? t("nameList")
              : data?.level === 3
              ? productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )
              : data?.level === 4
              ? ""
              : ""}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: IItem, data: IGroup & IItem) => {
        const checkValue = () => {
          if (!data?.qtyChange && data?.qtyChange !== 0) {
            return data?.qtyOutstanding;
          }
          return data?.qtyChange || data?.qty;
        };

        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? `${t("amount")}/${t("totalNumber")}`
              : data?.level === 3
              ? `${checkValue()} / ${data?.qty}`
              : data?.level === 4
              ? t("nameList")
              : productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      align: "center",
      render: (item: string, data: IGroup & IItem) => {
        const option = data?.option ? JSON.parse(data?.option || `{}`) : {};

        return (
          <div className="text-content">
            {data?.level === 2 || data?.level === 4 ? (
              t("details")
            ) : data?.level === 3 || data?.level === 5 ? (
              <Popover
                content={
                  i18n.language === "th"
                    ? item ||
                      option?.productDescription ||
                      data?.product?.propertiesTh ||
                      "-"
                    : item ||
                      option?.productDescription ||
                      data?.product?.propertiesEn ||
                      "-"
                }
                key={data?.key}
              >
                <div className="text-content">
                  {i18n.language === "th"
                    ? item ||
                      option?.productDescription ||
                      data?.product?.propertiesTh ||
                      "-"
                    : item ||
                      option?.productDescription ||
                      data?.product?.propertiesEn ||
                      "-"}
                </div>
              </Popover>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      title: t("numberOfItems"),
      dataIndex: "product",
      key: "product",
      render: (product: IItem, data: IGroup & IItem) => {
        return (
          <>
            {data?.level === 1 ? (
              data?.children?.[0]?.children?.length
            ) : data?.level === 2 ? (
              data?.children?.[0]?.type === ORDER_TYPE.OPTION_DRUG ? (
                ""
              ) : (
                t("amount")
              )
            ) : data?.level === 3 ? (
              data?.children?.length > 0 ? (
                data?.type === ORDER_TYPE.OPTION_DRUG ? (
                  ""
                ) : (
                  data?.qtyOutstanding
                )
              ) : data?.type === ORDER_TYPE.OPTION_DRUG ? (
                ""
              ) : (
                <div>
                  {`${
                    data?.qtyChange !== undefined
                      ? data?.qtyChange
                      : data?.qtyOutstanding
                  } `}

                  {medicalRecord?.[0]?.isEdit ? (
                    <EditOutlined
                      onClick={(e) => {
                        setProduct({
                          ...product,
                          edit: data?.id,
                          level: data?.level,
                          number: data?.qtyOutstanding,
                        });

                        editOrderQty.setFieldsValue({
                          qtyOutstanding:
                            data?.qtyChange !== undefined
                              ? data?.qtyChange
                              : data?.qtyOutstanding,
                        });
                        onCancelNumber();
                        e?.stopPropagation();
                      }}
                    />
                  ) : null}
                </div>
              )
            ) : data?.level === 4 ? (
              data?.type === ORDER_TYPE.OPTION_DRUG ? (
                ""
              ) : (
                t("amount")
              )
            ) : data?.type !== ORDER_TYPE.OPTION_DRUG ? (
              <div>
                {`${
                  data?.qtyChange !== undefined
                    ? data?.qtyChange
                    : data?.qtyOutstanding
                } `}
                {medicalRecord?.[0]?.isEdit ? (
                  <EditOutlined
                    onClick={(e) => {
                      setProduct({
                        ...product,
                        edit: data?.id,
                        level: data?.level,
                        number: data?.qtyOutstanding,
                      });

                      editOrderQty.setFieldsValue({
                        qtyOutstanding:
                          data?.qtyChange !== undefined
                            ? data?.qtyChange
                            : data?.qtyOutstanding,
                      });
                      onCancelNumber();
                      e?.stopPropagation();
                    }}
                  />
                ) : null}
              </div>
            ) : null}
          </>
        );
      },
    },

    {
      title: "",
      dataIndex: "total",
      key: "total",
      align: "center",
      render: (_: IItem, data: IGroup & IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ``
              : data?.level === 2
              ? t("type")
              : data?.level === 3
              ? data?.type === ORDER_TYPE.ONE_DAY
                ? "One Day"
                : data?.type === ORDER_TYPE.CONTINUE
                ? "Continuous"
                : "-"
              : data?.level === 4
              ? ""
              : ""}
          </div>
        );
      },
    },
    {
      title: t("price"),
      dataIndex: "total",
      key: "total",
      render: (item: string | number, data: IGroup & IItem) => {
        return (
          <div>
            {data?.level === 1
              ? Decimal(item)
              : data?.level === 2
              ? t("price")
              : data?.level === 3
              ? Decimal(data?.net)
              : data?.level === 4
              ? t("price")
              : Decimal(data?.total)}
          </div>
        );
      },
    },
  ];

  return (
    <div>
      <EditModal
        visible={visible.number}
        onCancel={onCancelNumber}
        editOrder={editOrderQty}
        width="25%"
        header={<div>{t("modifyTheNumberOfProducts")}</div>}
      >
        <Form
          onFinish={updateOrderItem}
          name="editOrderQty"
          form={editOrderQty}
        >
          <Form.Item
            label={t("numberOfProducts")}
            name="qtyOutstanding"
            rules={[
              () => ({
                validator(_, value) {
                  if (value === "" || (!value && value !== 0)) {
                    return Promise.reject(
                      new Error(
                        `${t("theNumberOfProductsIsNotLessThan")} 1 ${t(
                          "andNotMore"
                        )} ${product?.number}!`
                      )
                    );
                  } else if (value < 1) {
                    return Promise.reject(
                      new Error(
                        `${t("theNumberOfProductsIsNotLessThan")} 1 ${t(
                          "andNotMore"
                        )} ${product?.number}!`
                      )
                    );
                  } else if (value <= product?.number && value >= 0) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      new Error(
                        `${t("theNumberOfProductsIsNotLessThan")} 1 ${t(
                          "andNotMore"
                        )} ${product?.number}!`
                      )
                    );
                  }
                },
              }),
            ]}
          >
            <InputNumber className="modify-product" min={1} />
          </Form.Item>
        </Form>
      </EditModal>

      <Table
        className="components-table-demo-nested table-selection table-confirm thead-table-row3 "
        rowClassName={(r) => {
          if (r?.level === 1) {
            return "no-header redio-rowClass";
          } else if (r?.level === 2) {
            return "table-header no-header redio-rowClass";
          } else if (r?.level === 3 && r?.children) {
            return "no-header ";
          } else if (r?.level === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "table-confirm-first table-confirm-level-course table-bottom--level-course-num";
            } else {
              return "table-confirm-first table-confirm-level-course";
            }
          } else if (r?.level === 4) {
            num.current = r?.children?.length;
            return "redio-rowClass table-header no-header table-confirm-level-course table-top-level-course ";
          }
          return "redio-rowClass";
        }}
        rowSelection={{ ...rowSelection, checkStrictly: false }}
        expandedRowKeys={selection?.selection?.expandAllRows}
        onExpandedRowsChange={(e) =>
          selection.setSelection({
            ...selection?.selection,
            expandAllRows: e,
          })
        }
        columns={columns}
        expandRowByClick={true}
        rowKey={"key"}
        dataSource={orderList || []}
        pagination={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default OrderList;
