import { discount } from "@assets/data/data";
import { EVENT_ROOM, ORDER_TYPE } from "@tools/util.enum";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMainMedicineDoctorRoom } from "@provider/medicine_doctor_room";
import { Button, Checkbox, DatePicker, ModalProps } from "antd";
import { Col, Divider, Form, FormInstance, Input, Modal, Row } from "antd";
import React from "react";
import Selector from "@components/selector/Selector";
import TextArea from "antd/lib/input/TextArea";
import CCheckbox from "@components/checkbox/Checkbox";

interface Props extends ModalProps {
  editOrder: FormInstance;
  visibleInput: boolean;
  handleUpDateOrderItem: (e: any) => void;
  title?: string;
  total?: string;
  outType?: boolean;
  isLoading: boolean;
}

const ModalUpdate = ({
  editOrder,
  visibleInput,
  handleUpDateOrderItem,
  title,
  total,
  outType,
  isLoading,
  ...props
}: Props) => {
  const { t } = useTranslation();
  const { status } = useMainMedicineDoctorRoom();
  const monthFormat = "MM-YYYY";
  const dateFormat = "DD-MM-YYYY";
  const location = useLocation();
  const locationPath = location.pathname;
  const typeList = [
    { name: "One Day", id: ORDER_TYPE.ONE_DAY },
    { name: "Continuous", id: ORDER_TYPE.CONTINUE },
  ];

  return (
    <Modal
      className="manage-modal"
      title={
        <>
          <Row>
            <Col lg={20} className="t-blue ">
              <b> {t("modifyProductInformation")}</b>
            </Col>
            <Col lg={4} className="col-end"></Col>
          </Row>
        </>
      }
      centered
      visible={visibleInput}
      onCancel={props?.onCancel}
      width="70%"
      footer={null}
      forceRender={false}
    >
      <div className="scroll">
        <Form
          className="p-2"
          onFinish={handleUpDateOrderItem}
          name="editOrder"
          form={editOrder}
        >
          <Form.Item name="room" noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name="product" noStyle hidden>
            <Input disabled />
          </Form.Item>
          <Form.Item name="parentId" noStyle hidden>
            <Input disabled />
          </Form.Item>

          <Form.Item name="categoryID" noStyle hidden>
            <Input disabled />
          </Form.Item>

          <Form.Item name="key" noStyle hidden>
            <Input disabled />
          </Form.Item>

          <Row gutter={[14, 14]} className="margin-form">
            <Col md={24} lg={24}>
              <Form.Item label={t("category")} name="name">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={12} lg={12}>
              <Form.Item label={t("nameList")} name="nameTh">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col md={12} lg={12}>
              <Form.Item label={t("type")} name="groupNameTh">
                <Input disabled />
              </Form.Item>
            </Col>

            <Form.Item
              noStyle
              shouldUpdate={(prev, next) =>
                prev.categoryID !== next.categoryID ||
                prev.product !== next.product
              }
            >
              {(form: FormInstance) => {
                const categoryID = form.getFieldValue("categoryID");
                const product = form.getFieldValue("product");
                if (
                  categoryID !== "C-0005" &&
                  product?.categoryId !== "C-0005"
                ) {
                  if (locationPath.search("doctorroom") >= 0) {
                    return (
                      <React.Fragment>
                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("Frequency (TH)")}
                            name="frequencyTh"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("Frequency (EN)")}
                            name="frequencyEn"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        {categoryID === "C-0001" && (
                          <Col md={12} lg={12}>
                            <Form.Item
                              label="Gratis"
                              valuePropName="checked"
                              name="gratis"
                            >
                              <Checkbox value={true}>Gratis</Checkbox>
                            </Form.Item>
                          </Col>
                        )}
                      </React.Fragment>
                    );
                  }
                  return (
                    <React.Fragment>
                      <Col md={12} lg={12}>
                        <Form.Item
                          label={t("Frequency (TH)")}
                          name="frequencyTh"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={12} lg={12}>
                        <Form.Item
                          label={t("Frequency (EN)")}
                          name="frequencyEn"
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      {categoryID === "C-0001" && (
                        <Col md={12} lg={12}>
                          <Form.Item
                            label="Gratis"
                            valuePropName="checked"
                            name="gratis"
                          >
                            <Checkbox disabled value={true}>
                              Gratis
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      )}
                    </React.Fragment>
                  );
                }
              }}
            </Form.Item>

            <Col md={24} lg={24}>
              <Form.Item
                shouldUpdate={(prev, next) =>
                  prev.categoryID === next.categoryID
                }
              >
                {(form: FormInstance) => {
                  const categoryID = form.getFieldValue("categoryID");
                  return (
                    <Row>
                      <Col md={24} lg={24}>
                        <Form.Item
                          label={
                            categoryID === "C-0002"
                              ? t("propertiesTh")
                              : t("detailsTh")
                          }
                          name="productDescriptionTh"
                        >
                          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                      </Col>
                      <Col md={24} lg={24}>
                        <Form.Item
                          label={
                            categoryID === "C-0002"
                              ? t("propertiesEn")
                              : t("detailsEn")
                          }
                          name="productDescriptionEn"
                        >
                          <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
                        </Form.Item>
                      </Col>
                    </Row>
                  );
                }}
              </Form.Item>
            </Col>
            {title === "editConfirm" ? (
              <>
                <Form.Item
                  noStyle
                  shouldUpdate={(prev, next) =>
                    prev.categoryID !== next.categoryID ||
                    prev.product !== next.product ||
                    prev.categoryParentId !== next.categoryParentId ||
                    prev.gratis !== next.gratis
                  }
                >
                  {(form: FormInstance) => {
                    const gratis = form.getFieldValue("gratis");
                    const product = form.getFieldValue("product");
                    const categoryID = form.getFieldValue("categoryID");
                    const categoryParentId =
                      form.getFieldValue("categoryParentId");
                    const disabled =
                      categoryID === "C-0006" ||
                      categoryID === "C-0007" ||
                      categoryParentId === "C-0006" ||
                      categoryParentId === "C-0007" ||
                      gratis
                        ? true
                        : false;
                    return (
                      <>
                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("amount")}
                            name="qty"
                            rules={[
                              {
                                required: true,
                              },
                              () => ({
                                validator(_, value) {
                                  if (!value || Number(value) > 0) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error(
                                      `${t(
                                        "theNumberOfProductsIsNotLessThan"
                                      )} 0!`
                                    )
                                  );
                                },
                              }),
                            ]}
                          >
                            <Input
                              type="number"
                              disabled={disabled}
                              suffix={product?.unit || ""}
                            />
                          </Form.Item>
                        </Col>

                        <Col md={12} lg={12}>
                          <Row>
                            <Col md={19} lg={19}>
                              <Form.Item
                                label={t("discounts")}
                                rules={[
                                  ({ getFieldValue }) => ({
                                    validator(_, value) {
                                      if (
                                        getFieldValue("discountType") === "%"
                                      ) {
                                        if (
                                          Number(value) >= -100 &&
                                          Number(value) <= 100
                                        ) {
                                          return Promise.resolve();
                                        } else {
                                          return Promise.reject(
                                            new Error(
                                              `${t(
                                                "APercentageOfNotLessThan"
                                              )} -100% ${t("and")} ${t(
                                                "thePercentageIsNotMoreThan"
                                              )} 100%`
                                            )
                                          );
                                        }
                                      } else {
                                        return Promise.resolve();
                                      }
                                    },
                                  }),
                                ]}
                                name="discount"
                              >
                                <Input
                                  type="number"
                                  min={0}
                                  className="order-summary"
                                  disabled={disabled}
                                />
                              </Form.Item>
                            </Col>

                            <Col md={5} lg={5} style={{ paddingTop: "48px" }}>
                              <Form.Item label="" name="discountType">
                                <Selector
                                  bordered={false}
                                  className="select-search text-select"
                                  placeholder={t("selectItem")}
                                  Source={discount}
                                  keyName="name"
                                  keyValue="name"
                                  disabled={disabled}
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </>
                    );
                  }}
                </Form.Item>
              </>
            ) : status === "change_drug_label" ? null : (
              <>
                <Col md={12} lg={12}>
                  <Form.Item
                    shouldUpdate={(prev, next) =>
                      prev.categoryID !== next.categoryID ||
                      prev.product !== next.product
                    }
                    noStyle
                  >
                    {(form) => {
                      const categoryId = form.getFieldValue("categoryID");
                      const disabled =
                        categoryId === "C-0006" || categoryId === "C-0007"
                          ? true
                          : false;
                      const product = form.getFieldValue("product");

                      return (
                        <Form.Item
                          label={t("amount")}
                          name="qty"
                          rules={[
                            {
                              required: true,
                            },
                            ({ getFieldValue }) => ({
                              validator(_, value) {
                                if (!value || getFieldValue("qty") > 0) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error(
                                    `${t(
                                      "theNumberOfProductsIsNotLessThan"
                                    )} 0!`
                                  )
                                );
                              },
                            }),
                          ]}
                        >
                          <Input
                            type="number"
                            disabled={disabled}
                            suffix={product?.unit || ""}
                          />
                        </Form.Item>
                      );
                    }}
                  </Form.Item>
                </Col>
                <Form.Item
                  hidden={locationPath.includes(
                    "marketing/pre-registration/manage"
                  )}
                  shouldUpdate={(prev, next) =>
                    prev.type !== next.type || prev?.disabled !== next?.disabled
                  }
                  noStyle
                >
                  {(form: FormInstance) => {
                    const disabled = form?.getFieldValue("disabled");
                    if (!disabled) {
                      return (
                        <Col md={12} lg={12}>
                          <Form.Item label="Type" name="type">
                            <Selector
                              placeholder={t("selectItem")}
                              Source={typeList}
                              keyName="name"
                              keyValue="id"
                            />
                          </Form.Item>
                        </Col>
                      );
                    }
                  }}
                </Form.Item>
              </>
            )}

            <Col md={24} lg={24}>
              <Form.Item label={t("remarks")} name="remark">
                <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
              </Form.Item>
            </Col>
            <Col md={24} lg={24}>
              <Form.Item
                shouldUpdate={(prev, next) =>
                  prev.categoryId !== next.categoryId ||
                  prev.product !== next.product
                }
              >
                {(form: FormInstance) => {
                  const categoryID = form.getFieldValue("categoryId");
                  const product = form.getFieldValue("product");
                  if (
                    categoryID === "C-0002" ||
                    product?.room === EVENT_ROOM.PHARMACY
                  ) {
                    return (
                      <Row gutter={[14, 14]}>
                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("productNameShown")}
                            name="nameShown"
                          >
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("genericName")}
                            name="genericName"
                          >
                            <Input />
                          </Form.Item>
                        </Col>
                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("quantityUsed")}
                            name="quantityUsed"
                          >
                            <Input suffix={product?.unitAlt || ""} />
                          </Form.Item>
                        </Col>

                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("startMedication")}
                            name="dateStart"
                          >
                            <DatePicker format={dateFormat} />
                          </Form.Item>
                        </Col>

                        <Col md={12} lg={12}>
                          <Form.Item label={t("stopMedicine")} name="dateStop">
                            <DatePicker format={dateFormat} />
                          </Form.Item>
                        </Col>

                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("medicineExp")}
                            name="drugExpiration"
                          >
                            <DatePicker format={monthFormat} picker="month" />
                          </Form.Item>
                        </Col>

                        <Col md={12} lg={12}>
                          <Form.Item
                            label={t("eatingPattern")}
                            name="eatingPattern"
                          >
                            <CCheckbox.EatingPattern />
                          </Form.Item>
                        </Col>

                        <Col md={12} lg={12}>
                          <Form.Item label={t("timeSpent")} name="timeSpent">
                            <CCheckbox.Period />
                          </Form.Item>
                        </Col>

                        <Col md={24} lg={24}>
                          <Form.Item
                            label={t("specialMethod")}
                            name="specialInstructions"
                          >
                            <TextArea
                              autoSize={{ minRows: 2, maxRows: 3 }}
                              id="remark"
                            />
                          </Form.Item>
                        </Col>

                        <Col md={24} lg={24}>
                          <Form.Item label={t("objective")} name="purpose">
                            <TextArea
                              autoSize={{ minRows: 2, maxRows: 3 }}
                              id="remark"
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                    );
                  }
                }}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>

      <Row gutter={[14, 14]}>
        <Col span={24}>
          <Divider className="m-0 " style={{ position: "absolute", top: 0 }} />
          <Row
            gutter={[14, 14]}
            justify="end"
            align="middle"
            className="footer"
          >
            <Col>
              <Button className="cancel " key="back" onClick={props?.onCancel}>
                {t("cancel")}
              </Button>
            </Col>
            <Col className="mr-05">
              <Button
                className="btn-create "
                loading={isLoading}
                onClick={() => editOrder.submit()}
              >
                {t("save")}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Modal>
  );
};

export default ModalUpdate;
