import { t } from "i18next";
import { FC } from "react";
import { ENUM_ROLE_EN } from "@tools/util.enum";
import { Button, Col, Form, Input, Row } from "antd";
import React from "react";
import provinces from "@assets/data/thai_provinces.json";
import UserContext from "@provider/UserProvider";
import SelectorDouble from "@components/selector/SelectorDouble";

type Props = {
  setSearchData: (e: any) => void;
  setCurrentPage: (e: number) => void;
};

const Search: FC<Props> = ({ setSearchData, setCurrentPage }) => {
  const userList = React.useContext(UserContext);
  const onFinish = (value: any) => {
    setSearchData(value);
    setCurrentPage(1);
  };

  return (
    <div className="pb-1">
      {userList?.role === ENUM_ROLE_EN.SUPER_ADMIN && (
        <Form onFinish={onFinish}>
          <Row gutter={[14, 14]}>
            <Col md={8} lg={10}>
              <Form.Item name="name">
                <Input placeholder={`${t("search")} `} />
              </Form.Item>
            </Col>

            <Col md={8} lg={10}>
              <Form.Item name="province">
                <SelectorDouble
                  Source={provinces}
                  keyName="name_th"
                  keyValue="name_th"
                  title={t("province")}
                  placeholder={t("all")}
                />
              </Form.Item>
            </Col>

            <Col>
              <Button className="mr-1 btn-search" htmlType="submit">
                {t("search")}
              </Button>
              <Button className="clear" htmlType="reset">
                {t("reset")}
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </div>
  );
};

export default Search;
