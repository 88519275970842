import { dayjs } from "@tools/timezone";
import { Button } from "antd";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { IGetWorkOrderItemCallbackLab } from "@interface/IWorkOrderITem.interface";
import { useGetWorkOrderItemHistoryLab } from "@services/api/work_order_item";
import MoTable from "@components/table/Table";

type Props = {
  searchValue: any;
  currentPage: number;
  setCurrentPage: (e: any) => void;
};

const Table = ({ searchValue, currentPage, setCurrentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const historyLabList = useGetWorkOrderItemHistoryLab({
    search: searchValue?.name,
    date: searchValue?.date
      ? dayjs(searchValue?.date).format("YYYY-MM-DD")
      : undefined,
    limit: limitPage,
    page: currentPage,
    orderby: ENUM_ORDERBY.DESC,
  });
  const [total, setTotal] = useState<number>(1);

  useEffect(() => {
    setTotal(historyLabList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [historyLabList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "hn",
      key: "hn",
      render: (_: any, data: any) => {
        return (
          <div className="f-normal " key={data?.id}>
            {data?.workOrder?.patient?.hn}
          </div>
        );
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "firstNameTH",
      key: "firstNameTH",
      render: (_: any, data: any) => {
        const name =
          i18n.language === "th"
            ? [
                data?.workOrder?.patient?.firstNameTh,
                data?.workOrder?.patient?.lastNameTh,
              ]
                ?.join(" ")
                ?.trim()
            : [
                data?.workOrder?.patient?.firstNameEn,
                data?.workOrder?.patient?.lastNameEn,
              ]
                ?.join(" ")
                ?.trim();
        return (
          <div className="f-normal " key={data?.id}>
            {name}
          </div>
        );
      },
    },

    {
      title: `${t("name")}${t("product")}`,
      dataIndex: "product",
      key: "product",
      render: (_: any, data: any) => {
        return (
          <div className="f-normal " key={data?.id}>
            {data?.product?.nameEn}
          </div>
        );
      },
    },

    {
      title: t("labDeadline"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string, data: IGetWorkOrderItemCallbackLab) => {
        if (
          dayjs(createdAt)
            .add(data?.product?.expiredAt || 0, "day")
            .format("DD-MM-YYYY") < dayjs().format("DD-MM-YYYY")
        ) {
          return (
            <div className="f-normal " style={{ color: "red" }}>
              {dayjs(createdAt)
                .add(data?.product?.expiredAt || 0, "day")
                .format("DD-MM-YYYY")}
            </div>
          );
        } else
          return (
            <div className="f-normal ">
              {dayjs(createdAt)
                .add(data?.product?.expiredAt || 0, "day")
                .format("DD-MM-YYYY")}
            </div>
          );
      },
    },

    {
      title: t("labDeliveryDate"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt: string) => {
        return (
          <div className="f-normal ">
            {dayjs(createdAt).format("DD-MM-YYYY")}
          </div>
        );
      },
    },

    {
      title: `${t("report")}${t("lab.title")}`,
      dataIndex: "link",
      key: "link",
      align: "center",
      render: (link: string) => {
        return (
          <>
            {link ? (
              <Button
                className="btn-view"
                onClick={(e) => {
                  window.open(link);

                  e.stopPropagation();
                }}
              >
                view
              </Button>
            ) : (
              "-"
            )}
          </>
        );
      },
    },

    {
      title: `${t("report")}${t("respond")}`,
      dataIndex: "callbackLink",
      key: "callbackLink",
      align: "center",
      render: (callbackLink: any) => {
        return (
          <div>
            {callbackLink ? (
              <Button
                className="reserve-report"
                onClick={(e) => {
                  window.open(callbackLink);
                  e.stopPropagation();
                }}
              >
                view
              </Button>
            ) : (
              "-"
            )}
          </div>
        );
      },
    },
  ];

  return (
    <>
      <MoTable
        className="no-cursor-header no-cursor"
        dataSource={historyLabList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
