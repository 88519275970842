import { useGetVisitsByID } from "@services/api/visits";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { Col, Drawer, Form, Row } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";
import Fields from "./Fields";
import Header from "./Header";
import Affixes from "@components/affixes/Affixes";
import OrderSummary from "./OrderSummary";

const FormPayDetail = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const visitByID = useGetVisitsByID(visitID);
  const [patientList] = Form.useForm();
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (!visitByID?.data) {
      return;
    }
    patientList?.setFieldsValue({
      foodAllergy: visitByID?.data?.patient?.foodAllergy || undefined,
      drugAllergy: visitByID?.data?.patient?.drugAllergy || undefined,
    });

    // eslint-disable-next-line
  }, [visitByID?.data]);

  const showDrawer = () => {
    setVisible(!visible);
  };

  const confirmPayment = () => {};

  return (
    <>
      <Header history={history} confirmPayment={confirmPayment} />
      <div className="set-layout body-height ">
        <Row gutter={[14, 14]}>
          <Col md={24} lg={18}>
            <Fields visitByID={visitByID?.data} patientList={patientList} />
          </Col>

          <Col lg={6} className="card-display">
            <OrderSummary />
          </Col>
        </Row>

        <Affixes onClick={showDrawer} />

        <Drawer
          width={"50%"}
          closable={false}
          placement="right"
          onClose={showDrawer}
          visible={visible}
        >
          <OrderSummary status="noCard" />
        </Drawer>
      </div>
    </>
  );
};

export default FormPayDetail;
