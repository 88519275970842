import { ITransferDetailContext } from "./interface";
import { createContext, useContext } from "react";

const MainTransferDetailContext = createContext<ITransferDetailContext>(
  {} as ITransferDetailContext
);

const MainTransferDetailProvider = ({
  children,
  value,
}: {
  children: JSX.Element;
  value: ITransferDetailContext;
}) => {
  return (
    <MainTransferDetailContext.Provider
      value={{
        ...value,
      }}
    >
      {children}
    </MainTransferDetailContext.Provider>
  );
};

export const useMainTransferDetail = () =>
  useContext(MainTransferDetailContext);
export default MainTransferDetailProvider;
