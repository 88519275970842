import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { procedureDetail } from "@assets/data/pathRouter";
import { useGetVisitList } from "@services/api/visits";
import { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ENUM_ORDERBY, ENUM_SERVICE_GROUP, ROOM_ENUM } from "@tools/util.enum";
import MoTable from "@components/table/Table";

type Props = {
  searchValue: any;
  currentPage: number;
  setCurrentPage: any;
};

const Table = ({ searchValue, currentPage, setCurrentPage }: Props) => {
  const { t, i18n } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const [total, setTotal] = useState<number>(1);
  const patientList = useGetVisitList({
    menu: ROOM_ENUM.PROCEDURE,
    gender: searchValue?.gender,
    name: searchValue?.name,
    mobile: searchValue?.mobile,
    page: currentPage,
    limit: limitPage,
    serviceGroups: [ENUM_SERVICE_GROUP.MEDICAL_PROCEDURE],
    orderby: ENUM_ORDERBY.ASC,
  });

  useEffect(() => {
    setTotal(patientList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [patientList?.data?.result?.[0]?.total]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(
      `${procedureDetail}/patient/${event.patientId}/visit/${event.id}`
    );
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: string, data: IGetPatientList, index: number) => {
        return (
          <div key={data?.id}>{limitPage * (currentPage - 1) + index + 1}</div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "patient",
      key: "hn",
      width: "20%",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (data: IGetPatientList) => {
        return (
          <div className="f-normal " key={data?.id}>
            {data?.hn}
          </div>
        );
      },
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "patient",
      key: "name",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (data: IGetPatientList) => {
        return (
          <div className="f-normal " key={data?.id}>
            {i18n.language === "th"
              ? `${data?.firstNameTh}   ${data?.lastNameTh}`
              : `${data?.firstNameEn}   ${data?.lastNameEn}`}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "patient",
      key: "gender",

      render: (data: IGetPatientList) => {
        let nameGender = gender(data?.gender, i18n?.language);

        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "patient",
      key: "dateOfBirth",
      width: "20%",
      sorter: (a: any, b: any) => a.age - b.age,
      render: (data: IGetPatientList) => {
        return <>{Age(data?.dateOfBirth)}</>;
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "patient",
      sorter: (a: any, b: any) => a.phone - b.phone,
      key: "phone",
      render: (data: IGetPatientList) => {
        return <>{data?.mobile}</>;
      },
    },
  ];

  return (
    <>
      <MoTable
        dataSource={patientList?.data?.result?.[0]?.data}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
