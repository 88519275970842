import { IParamet } from "@interface/IGlobal.interface";
import { PROMOTION } from "@assets/data/pathRouter";
import { BigNumber } from "bignumber.js";
import { productName } from "@tools/util";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { statusPromotion } from "@assets/data/data";
import { useGetBranchList } from "@services/api/branch";
import { useCreatePromotion } from "@services/api/promotion";
import { RouteComponentProps } from "react-router-dom";
import { Course, IPromotionList } from "@interface/IPromotion.interface";
import { useUpdatePromotionByID } from "@services/api/promotion";
import { useHistory, useRouteMatch } from "react-router-dom";
import { ICategory, IItem, IProduct } from "@interface/IOrder.interface";
import { useEffect, useRef, useState } from "react";
import { ENUM_PROMOTION_STATUS, ENUM_ROLE_EN } from "@tools/util.enum";
import { Button, Card, Col, DatePicker, TreeSelect } from "antd";
import { Empty, Form, FormInstance, Input, Row } from "antd";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import React from "react";
import empty from "@assets/img/empty.svg";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import Selector from "@components/selector/Selector";
import UserContext from "@provider/UserProvider";
import ProductCategory from "@components/product_category/ProductCategory";
import { useBranchSelector } from "@provider/select_branch";

type Props = {
  confirm: FormInstance;
  promotionById?: IParamet<IPromotionList[]>;
  promotionId: number;
};

const Fields = ({ confirm, promotionById, promotionId }: Props) => {
  const { TreeNode } = TreeSelect;
  const { branchId } = useBranchSelector();
  const history = useHistory<RouteComponentProps["history"]>();
  const branchList = useGetBranchList(true, { limit: 0 });
  const userList = React.useContext(UserContext);
  const match: any = useRouteMatch();
  const duplicateId = match?.params?.duplicateId;
  const { t, i18n } = useTranslation();
  const { RangePicker } = DatePicker;
  const create = useCreatePromotion();
  const update = useUpdatePromotionByID();
  const idProduct = useRef<number>();
  const [ids, setIds] = useState<any>([]);
  const [manage, setManage] = useState({
    product: false,
    delete: false,
  });
  const [orderList, setOrderList] = useState<{
    order: Course[];
  }>({
    order: [],
  });

  useEffect(() => {
    let price = 0;
    let totalForeigner = 0;
    let totalPartner = 0;
    orderList?.order?.forEach((item: Course) => {
      price = Number(
        new BigNumber(item?.normal || 0)
          .multipliedBy(item?.qty || 0)
          .plus(price)
      );
      totalForeigner = Number(
        new BigNumber(item?.foreigner || 0)
          .multipliedBy(item?.qty || 0)
          .plus(totalForeigner)
      );
      totalPartner = Number(
        new BigNumber(item?.partner || 0)
          .multipliedBy(item?.qty || 0)
          .plus(totalPartner)
      );
    });

    confirm.setFieldsValue({
      price: new BigNumber(price).toFormat(2),
      totalForeigner: new BigNumber(totalForeigner).toFormat(2),
      totalPartner: new BigNumber(totalPartner).toFormat(2),
    });

    //eslint-disable-next-line
  }, [orderList?.order]);

  useEffect(() => {
    if (!promotionId && promotionId !== 0) {
      const roleList = ["SUPER_ADMIN", "SUPER_MARKETING"];
      let newBranchList: number[] = [];
      if (
        !roleList.includes(userList?.role) &&
        branchList?.data?.result?.[0]?.data?.[0]?.id
      ) {
        newBranchList = [branchList?.data?.result?.[0]?.data?.[0]?.id];
      } else if (
        roleList.includes(userList?.role) &&
        branchId &&
        branchId !== 0
      ) {
        newBranchList = [branchId];
      }
      confirm.setFieldsValue({
        branchIds: newBranchList,
      });
    }

    const branchListIds = branchList?.data?.result?.[0]?.data?.map(
      ({ id }) => id
    );
    setIds(branchListIds);

    if (!promotionById?.result?.[0]?.courses?.length) return;
    setOrderList({ order: promotionById?.result?.[0]?.courses });

    //eslint-disable-next-line
  }, [branchList?.data, promotionById, promotionById]);

  const onFinish = (value: any) => {
    const roleList = ["SUPER_ADMIN", "SUPER_MARKETING"];
    const product = {
      productDetail: {
        startDate: value?.start?.[0],
        endDate: value?.start?.[1],
        descriptionEn: value?.descriptionEn,
        descriptionTh: value?.descriptionTh,
        foreigner: value?.foreigner,
        nameEn: value?.nameEn,
        nameTh: value?.nameTh,
        normal: value?.normal,
        partner: value?.partner,
        qty: Number(value?.qty),
        status: value?.status,
      },
      branchIds:
        !roleList.includes(userList?.role) || (branchId && branchId !== 0)
          ? value?.branchIds
          : value?.branchIds?.length ===
            branchList?.data?.result?.[0]?.data?.length
          ? [0]
          : value?.branchIds,
      productItems: orderList?.order?.map((productItem: Course) => {
        return {
          productId: productItem?.id,
          qty: productItem?.qty,
        };
      }),
    };
    if (promotionId) {
      updatePromotionById(product);
    } else {
      createPromotion(product);
    }
  };

  const createPromotion = (value: any) => {
    create.mutate(
      { data: value },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(`${PROMOTION.title}`);
        },
        onError: (res) => {
          openNotification({ method: "POST_ERROR", message: String(res) });
        },
      }
    );
  };

  const updatePromotionById = (value: any) => {
    update.mutate(
      { id: promotionId, data: value },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(`${PROMOTION.title}`);
        },
        onError: (res) => {
          openNotification({ method: "POST_ERROR", message: String(res) });
        },
      }
    );
  };

  const onModal = () => {
    setManage({ ...manage, delete: !manage?.delete });
  };

  const onDelete = () => {
    const item = orderList?.order?.filter((item: Course) => {
      return item?.id !== idProduct?.current;
    });

    setOrderList({ ...orderList, order: item });
    onModal();
  };

  const createNow = () => {
    setManage({ ...manage, product: true });
  };

  const onManageModal = () => {
    setManage({ ...manage, product: !manage?.product });
  };

  const checkProductOrder = (_: any) => {
    if (orderList?.order?.length > 0) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t("theProductListMustBeGreaterThan0")));
  };

  const columns = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: string, __: string, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: t("nameList"),
      dataIndex: "nameTh",
      key: "nameTh",
      render: (_: string, data: IPromotionList) => {
        return (
          <div>{productName(i18n.language, data?.nameTh, data?.nameEn)}</div>
        );
      },
    },

    {
      title: t("category"),
      dataIndex: "category",
      key: "category",
      render: (category: ICategory) => {
        return (
          <div>
            {i18n.language === "th"
              ? category?.nameTh || "-"
              : category?.nameEn || "-"}
          </div>
        );
      },
    },

    {
      title: t("type"),
      dataIndex: "group",
      key: "group",
      render: (_: string, data: IProduct) => {
        return <div>{data?.group?.nameTh || "-"}</div>;
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",
      key: "qty",
      render: (qty: string) => {
        return <div>{qty || "-"}</div>;
      },
    },

    {
      title: t("price"),
      dataIndex: "normal",
      key: "normal",
      render: (normal: string, data: IItem) => {
        let price = new BigNumber(normal || 0)
          .multipliedBy(data?.qty || 0)
          .toFormat(2);

        return <div>{price || "-"}</div>;
      },
    },
  ];
  const columnsDelete = [
    {
      title: t("sort"),
      dataIndex: "order",
      key: "order",
      render: (_: string, __: string, index: number) => {
        return <div>{index + 1}</div>;
      },
    },
    {
      title: t("nameList"),
      dataIndex: "nameTh",
      key: "nameTh",
      render: (_: string, data: IPromotionList) => {
        return (
          <div>{productName(i18n.language, data?.nameTh, data?.nameEn)}</div>
        );
      },
    },

    {
      title: t("category"),
      dataIndex: "category",
      key: "category",
      render: (category: ICategory) => {
        return (
          <div>
            {i18n.language === "th"
              ? category?.nameTh || "-"
              : category?.nameEn || "-"}
          </div>
        );
      },
    },

    {
      title: t("type"),
      dataIndex: "group",
      key: "group",
      render: (_: string, data: IProduct) => {
        return <div>{data?.group?.nameTh || "-"}</div>;
      },
    },

    {
      title: t("amount"),
      dataIndex: "qty",
      key: "qty",
      render: (qty: string) => {
        return <div>{qty || "-"}</div>;
      },
    },

    {
      title: t("price"),
      dataIndex: "normal",
      key: "normal",
      render: (normal: string, data: IItem) => {
        let price = new BigNumber(normal || 0)
          .multipliedBy(data?.qty || 0)
          .toFormat(2);

        return <div>{price || "-"}</div>;
      },
    },
    {
      title: t("manage"),
      dataIndex: "id",
      key: "id",
      render: (id: number) => {
        return (
          <DeleteOutlined
            onClick={(e) => {
              idProduct.current = id;

              onModal();
              e?.stopPropagation();
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="set-layout ">
      {manage?.delete && (
        <Modaler
          visible={manage?.delete}
          onCancel={onModal}
          onOk={onDelete}
          width="45%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              {`${t("areYouSure")}`}
            </div>
          }
        >
          {`${t("doYouWantToDelete")}`}
        </Modaler>
      )}

      <Card
        className="card-prefix card-prefix-hd pb-2"
        extra={<b className="fs-16 t-black-light ">{t("promotion.title")}</b>}
      >
        <Card
          className="card-prefix card-prefix-coupon-width card-prefix-chi"
          extra={<b className="fs-14 c-white ">{t("promotion.manage")}</b>}
        >
          <Form
            onFinish={onFinish}
            name="prefixForm"
            form={confirm}
            scrollToFirstError
          >
            <Form.Item
              noStyle
              shouldUpdate={(prev, next) => prev?.status !== next?.status}
            >
              {(form: FormInstance) => {
                const status = form.getFieldValue("status");
                const expiredStatus = !!duplicateId
                  ? false
                  : status === ENUM_PROMOTION_STATUS.EXPIRED;
                const publicStatus = !!duplicateId
                  ? false
                  : status === ENUM_PROMOTION_STATUS.PUBLIC;
                return (
                  <Row gutter={[14, 14]}>
                    <Col lg={12}>
                      <Form.Item
                        name="nameTh"
                        label={`${t("name")} (TH)`}
                        rules={[{ required: true }]}
                      >
                        <Input disabled={expiredStatus || publicStatus} />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <Form.Item
                        name="nameEn"
                        label={`${t("name")} (EN)`}
                        rules={[{ required: true }]}
                      >
                        <Input disabled={expiredStatus || publicStatus} />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        name="qty"
                        label={`${t("amount")}`}
                        rules={[
                          { required: true },
                          () => ({
                            validator(_, value) {
                              if (
                                !/^(-$)|\d+$/.test(value) &&
                                !!value &&
                                value !== 0
                              ) {
                                return Promise.reject(
                                  new Error(t("enterOnlyNumbers"))
                                );
                              } else {
                                if (!/^[1-9][0-9]*$/.test(value) && !!value) {
                                  return Promise.reject(
                                    new Error(t("theFirstIsAlwaysGreaterThan"))
                                  );
                                } else return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input disabled={expiredStatus || publicStatus} />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <Form.Item
                        name="descriptionTh"
                        label={`${t("description")} (TH)`}
                      >
                        <Input disabled={expiredStatus || publicStatus} />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="descriptionEn"
                        label={`${t("description")} (EN)`}
                      >
                        <Input disabled={expiredStatus || publicStatus} />
                      </Form.Item>
                    </Col>

                    <Col span={12}>
                      <Form.Item
                        name="status"
                        label={`${t("status")}`}
                        rules={[{ required: true }]}
                      >
                        <Selector.Translation
                          Source={statusPromotion?.filter((item) => {
                            return item?.id !== ENUM_PROMOTION_STATUS.EXPIRED;
                          })}
                          keyName={"name"}
                          keyValue="id"
                          disabled={expiredStatus}
                        />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        shouldUpdate={(prev, next) =>
                          prev.branchIds !== next.branchIds
                        }
                        noStyle
                      >
                        {(form: FormInstance) => {
                          const branchIds = form?.getFieldValue("branchIds");
                          return (
                            <Form.Item
                              label={`${t("branch")}`}
                              name="branchIds"
                            >
                              <TreeSelect
                                className="user-room-update"
                                treeCheckable={true}
                                showCheckedStrategy={TreeSelect.SHOW_CHILD}
                                allowClear
                                disabled={
                                  (userList?.role !==
                                    ENUM_ROLE_EN.SUPER_ADMIN &&
                                    userList?.role !==
                                      ENUM_ROLE_EN.SUPER_MARKETING) ||
                                  (branchId && branchId !== 0)
                                    ? true
                                    : false || expiredStatus || publicStatus
                                }
                                placeholder="Please select"
                              >
                                {
                                  <TreeNode
                                    value="all"
                                    title={
                                      <React.Fragment>
                                        <span>
                                          {branchIds?.length === 0 && (
                                            <span
                                              style={{
                                                display: "inline-block",
                                                color: "#ccc",
                                                cursor: "not-allowed",
                                              }}
                                            >
                                              Select all
                                            </span>
                                          )}
                                          {branchIds?.length !== 0 &&
                                            branchIds?.length <
                                              (branchList?.data?.result?.[0]
                                                ?.data?.length || 0) && (
                                              <span
                                                onClick={() =>
                                                  confirm.setFieldsValue({
                                                    branchIds: [...ids],
                                                  })
                                                }
                                                style={{
                                                  display: "inline-block",
                                                  color: "#286FBE",
                                                  cursor: "pointer",
                                                }}
                                              >
                                                Select all
                                              </span>
                                            )}
                                        </span>
                                        <span style={{ marginLeft: "5px" }}>
                                          {branchIds?.length === 0 && (
                                            <span
                                              style={{
                                                display: "inline-block",
                                                color: "#ccc",
                                                cursor: "not-allowed",
                                              }}
                                            >
                                              Unselect all
                                            </span>
                                          )}
                                          {branchIds?.length > 0 && (
                                            <span
                                              onClick={() =>
                                                confirm.setFieldsValue({
                                                  branchIds: [],
                                                })
                                              }
                                              style={{
                                                display: "inline-block",
                                                color: "#286FBE",
                                                cursor: "pointer",
                                              }}
                                            >
                                              Unselect all
                                            </span>
                                          )}
                                        </span>
                                      </React.Fragment>
                                    }
                                    disableCheckbox={true}
                                    disabled={true}
                                  />
                                }

                                {Array.from(
                                  new Map(
                                    [
                                      ...(branchList?.data?.result?.[0]?.data ||
                                        []),
                                      ...(promotionById?.result?.[0]
                                        ?.branchList || []),
                                    ]?.map((item) => [item?.id, item])
                                  )?.values()
                                )?.map((item, idx) => (
                                  <TreeNode
                                    key={item?.id}
                                    value={item?.id}
                                    title={
                                      i18n.language === "th"
                                        ? item?.nameTh
                                        : item?.nameEn
                                    }
                                  ></TreeNode>
                                ))}
                              </TreeSelect>
                            </Form.Item>
                          );
                        }}
                      </Form.Item>
                    </Col>

                    <Col md={24} lg={24}>
                      <Row gutter={[14, 14]}>
                        <Col span={24}>
                          <Row className="header-table" align="middle">
                            <Col className="f-normal  t-dark-blue">
                              <span className="f-bold mr-05 ">
                                {t("selectAProductList")}
                              </span>
                            </Col>
                            {orderList?.order?.length > 0 ||
                            promotionById?.result?.[0]?.courses ? (
                              <Col style={{ marginLeft: "auto" }}>
                                <Button
                                  type="primary"
                                  onClick={createNow}
                                  disabled={expiredStatus || publicStatus}
                                >
                                  {`${t("addOrder")}`}
                                </Button>
                              </Col>
                            ) : (
                              " "
                            )}
                          </Row>
                          <Form.Item
                            noStyle
                            shouldUpdate={(prev, next) =>
                              prev.status !== next.status
                            }
                          >
                            {(form: FormInstance) => {
                              const status = form.getFieldValue("status");
                              const expiredStatus = !!duplicateId
                                ? false
                                : status === ENUM_PROMOTION_STATUS.EXPIRED;
                              const publicStatus = !!duplicateId
                                ? false
                                : status === ENUM_PROMOTION_STATUS.PUBLIC;

                              return (
                                <Form.Item
                                  name="product"
                                  rules={[{ validator: checkProductOrder }]}
                                >
                                  <MoTable
                                    className="components-table-demo-nested table-selection table-confirm "
                                    pagination={false}
                                    locale={{
                                      emptyText: (
                                        <>
                                          <Empty
                                            image={empty}
                                            imageStyle={{
                                              height: 60,
                                            }}
                                            description={
                                              <span className="t-grey-white">
                                                {t("noInformation")}
                                              </span>
                                            }
                                          >
                                            <Button
                                              type="primary"
                                              onClick={createNow}
                                            >
                                              {`+ ${t("addOrder")}`}
                                            </Button>
                                          </Empty>
                                        </>
                                      ),
                                    }}
                                    rowClassName={(r) => {
                                      if (r?.level === 1) {
                                        return " no-header no-cursor";
                                      } else if (r?.level === 2) {
                                        return "table-header no-header no-cursor";
                                      } else if (r?.level === 3) {
                                        return " no-header";
                                      }
                                      return " no-header no-cursor";
                                    }}
                                    expandRowByClick
                                    rowKey={"id"}
                                    key={"id"}
                                    columns={
                                      publicStatus || expiredStatus
                                        ? columns
                                        : columnsDelete
                                    }
                                    dataSource={orderList?.order || []}
                                    // onClickRow={(e: IItem) => checkRow(e) && onClickRow(e)}
                                    onDoubleClick={false}
                                    scroll={{ x: "calc(100vw -20%)" }}
                                  />
                                </Form.Item>
                              );
                            }}
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        name="price"
                        label={`${t("initialThaiPrice")}`}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <Form.Item
                        name="normal"
                        label={`${t("thaiPrice")}`}
                        rules={[
                          { required: true },
                          () => ({
                            validator(_, value) {
                              if (!/^(-$)|\d+$/.test(value) && !!value) {
                                return Promise.reject(
                                  new Error(t("enterOnlyNumbers"))
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input disabled={expiredStatus || publicStatus} />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <Form.Item
                        name="totalForeigner"
                        label={`${t("initialForeignerPrice")}`}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <Form.Item
                        name="foreigner"
                        label={`${t("foreignerPrice")}`}
                        rules={[
                          { required: true },
                          () => ({
                            validator(_, value) {
                              if (!/^(-$)|\d+$/.test(value) && !!value) {
                                return Promise.reject(
                                  new Error(t("enterOnlyNumbers"))
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input disabled={expiredStatus || publicStatus} />
                      </Form.Item>
                    </Col>

                    <Col lg={12}>
                      <Form.Item
                        name="totalPartner"
                        label={`${t("initialPartnerPrice")}`}
                      >
                        <Input disabled />
                      </Form.Item>
                    </Col>
                    <Col lg={12}>
                      <Form.Item
                        name="partner"
                        label={`${t("partnerPrice")}`}
                        rules={[
                          { required: true },
                          () => ({
                            validator(_, value) {
                              if (!value) {
                                return Promise.resolve();
                              } else if (!/^(-$)|\d+$/.test(value)) {
                                return Promise.reject(
                                  new Error(t("enterOnlyNumbers"))
                                );
                              } else {
                                return Promise.resolve();
                              }
                            },
                          }),
                        ]}
                      >
                        <Input disabled={expiredStatus || publicStatus} />
                      </Form.Item>
                    </Col>

                    <Col span={24}>
                      <Form.Item
                        name="start"
                        label={t("promotionPeriod")}
                        rules={[
                          { required: true, message: `${t("pleaseSelect")}` },
                        ]}
                      >
                        <RangePicker
                          format="DD/MM/YYYY"
                          disabled={expiredStatus}
                        />
                      </Form.Item>
                    </Col>

                    {/* <Col md={12} lg={12}>
                  <Form.Item name="uses" valuePropName="checked" noStyle>
                    <Checkbox>{t("numberOfUses")}</Checkbox>
                  </Form.Item>
                </Col>
  
                <Col md={12} lg={12}>
                  <Form.Item name="right" valuePropName="checked" noStyle>
                    <Checkbox>{t("numberOfRightsPerson")}</Checkbox>
                  </Form.Item>
                </Col> */}

                    {/* <Col md={12} lg={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) => prev.uses !== next.uses}
                  >
                    {(form: FormInstance) => {
                      const uses = form.getFieldValue("uses");
  
                      if (uses) {
                        return (
                          <Row gutter={[14, 14]}>
                            <Col span={24}>
                              <Form.Item name="qtyUse">
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      }
                    }}
                  </Form.Item>
                </Col> */}

                    {/* <Col md={12} lg={12}>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prev, next) => prev.right !== next.right}
                  >
                    {(form: FormInstance) => {
                      const uses = form.getFieldValue("right");
  
                      if (uses) {
                        return (
                          <Row gutter={[14, 14]}>
                            <Col span={24}>
                              <Form.Item name="qty">
                                <Input />
                              </Form.Item>
                            </Col>
                          </Row>
                        );
                      }
                    }}
                  </Form.Item>
                </Col> */}
                  </Row>
                );
              }}
            </Form.Item>
          </Form>
        </Card>
      </Card>

      {manage?.product && (
        <ProductCategory
          visible={manage?.product}
          onModal={onManageModal}
          status="promotion"
          orderList={{ orderList: orderList, setOrderList: setOrderList }}
        />
      )}
    </div>
  );
};

export default Fields;
