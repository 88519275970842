import { MARITAL_STATUS, PATIENT_TYPE } from "@tools/util.enum";
import { COUPON_STATUS, ENUM_PROMOTION_STATUS } from "@tools/util.enum";
import { ENUM_ID_CARD, ENUM_ROLE_EN, ENUM_ROLE_TH } from "@tools/util.enum";

export interface IDataSelector {
  name?: string;
  id: string | number;
  nameEn?: string;
  nameTh?: string;
}

export interface IAgeRange {
  name: string;
  id: string | number;
  startAge: number;
  endAge: number;
}

export interface IDataSelectorLabel {
  label: string;
  value: string | number;
}

export const discount: IDataSelector[] = [
  { name: "%", id: 1 },
  { name: "฿", id: 2 },
];

export const gender: IDataSelector[] = [
  { nameTh: "ชาย", nameEn: "Male", id: "MALE" },
  { nameTh: "หญิง", nameEn: "Female", id: "FEMALE" },
];

export const statusPromotion: IDataSelector[] = [
  { name: "draft", id: ENUM_PROMOTION_STATUS.DAFT },
  { name: "publish", id: ENUM_PROMOTION_STATUS.PUBLIC },
  { name: "expire", id: ENUM_PROMOTION_STATUS.EXPIRED },
];

export const statusCoupon: IDataSelector[] = [
  { name: "draft", id: COUPON_STATUS.DRAFT },
  { name: "publish", id: COUPON_STATUS.PUBLIC },
  { name: "expire", id: COUPON_STATUS.EXPIRED },
];

export const statusReportDrug: IDataSelector[] = [
  { name: "Continue", id: "CONTINUOUS" },
  { name: "Discontinue", id: "DIS_CONTINUOUS" },
];

export const typeReportDrug: IDataSelector[] = [
  { name: "Day", id: "day" },
  { name: "Month", id: "month" },
  { name: "Year", id: "year" },
];

export const symbol: IDataSelector[] = [
  { nameEn: "Deceased", nameTh: "เสียชีวิต", id: 1 },
  { nameEn: "UP", nameTh: "ระวังสารคัดหลั่ง", id: 2 },
  { nameEn: "Anonymous", nameTh: "ปกปิดข้อมูล", id: 3 },
];

export const timeSlot: IDataSelector[] = [
  { nameEn: "Day", nameTh: "วัน", id: "DAY" },
  { nameEn: "Month", nameTh: "เดือน", id: "MONTH" },
  { nameEn: "Year", nameTh: "ปี", id: "YEAR" },
];

export const payment: IDataSelector[] = [
  { nameTh: "เงินสด", nameEn: "cash", id: "CASH" },
  { nameTh: "โอนเงิน", nameEn: "transfer money", id: "TRANSFER" },
  { nameTh: "บัตรเครดิต", nameEn: "credit card", id: "CREDIT" },
];

export const date: IDataSelector[] = [
  { nameTh: "ใหม่สุด", nameEn: "Newest", id: 1 },
  { nameTh: "เก่าสุด", nameEn: "Oldest", id: 2 },
];

export const month: IDataSelector[] = [
  { nameTh: "มกราคม", nameEn: "January", id: "01" },
  { nameTh: "กุมภาพันธ์", nameEn: "February", id: "02" },
  { nameTh: "มีนาคม", nameEn: "March", id: "03" },
  { nameTh: "เมษายน", nameEn: "April", id: "04" },
  { nameTh: "พฤษภาคม", nameEn: "May", id: "05" },
  { nameTh: "มิถุนายน", nameEn: "June", id: "06" },
  { nameTh: "กรกฎาคม", nameEn: "July", id: "07" },
  { nameTh: "สิงหาคม", nameEn: "August", id: "08" },
  { nameTh: "กันยายน", nameEn: "September", id: "09" },
  { nameTh: "ตุลาคม", nameEn: "October", id: "10" },
  { nameTh: "พฤศจิกายน", nameEn: "November", id: "11" },
  { nameTh: "ธันวาคม", nameEn: "December", id: "12" },
];

export const day: IDataSelector[] = [
  { name: "1", id: 1 },
  { name: "2", id: 2 },
  { name: "3", id: 3 },
  { name: "4", id: 4 },
  { name: "5", id: 5 },
  { name: "6", id: 6 },
  { name: "7", id: 7 },
  { name: "8", id: 8 },
  { name: "9", id: 9 },
  { name: "10", id: 10 },
  { name: "11", id: 11 },
  { name: "12", id: 12 },
  { name: "13", id: 13 },
  { name: "14", id: 14 },
  { name: "15", id: 15 },
  { name: "16", id: 16 },
  { name: "17", id: 17 },
  { name: "18", id: 18 },
  { name: "19", id: 19 },
  { name: "20", id: 20 },
  { name: "21", id: 21 },
  { name: "22", id: 22 },
  { name: "23", id: 23 },
  { name: "24", id: 24 },
  { name: "25", id: 25 },
  { name: "26", id: 26 },
  { name: "27", id: 27 },
  { name: "28", id: 28 },
  { name: "29", id: 29 },
  { name: "30", id: 30 },
  { name: "31", id: 31 },
];

export const typePrefix: IDataSelector[] = [
  { name: "TH", id: 0 },
  { name: "EN", id: 1 },

  // { name: "ชาย", id: 1 },
  // { name: "หญิง", id: 2 },
];

export const maritalStatus: IDataSelector[] = [
  { nameTh: "โสด", nameEn: "Single", id: MARITAL_STATUS.SINGLE },
  { nameTh: "สมรส", nameEn: "Married", id: MARITAL_STATUS.MARRIED },
  { nameTh: "หย่าร้าง", nameEn: "Widowed", id: MARITAL_STATUS.DIVORCED },
  { nameTh: "หม้าย", nameEn: "Divorced", id: MARITAL_STATUS.WIDOWED },
];

export const nationality: IDataSelector[] = [{ name: "ไทย", id: "1" }];

export const bloodType: IDataSelector[] = [
  { name: "RH Positive", id: "1" },
  { name: "RH Negative", id: "2" },
  { name: "Unknown", id: "3" },
];

export const right: IDataSelector[] = [
  { nameTh: "Thai", nameEn: "Thai", id: PATIENT_TYPE.THAI },
  { nameTh: "Foreigner", nameEn: "Foreigner", id: PATIENT_TYPE.FOREIGNER },
  {
    nameTh: "AH Partner Group",
    nameEn: "AH Partner Group",
    id: PATIENT_TYPE.AH_PARTNER_GROUP,
  },
];

export const bloodGroup: IDataSelector[] = [
  { name: "O", id: "1" },
  { name: "A", id: "2" },
  { name: "B", id: "3" },
  { name: "AB", id: "4" },
];

export const roleEn: IDataSelector[] = [
  { name: ENUM_ROLE_EN.MANAGER, id: ENUM_ROLE_EN.MANAGER },
  { name: ENUM_ROLE_EN.DOCTOR, id: ENUM_ROLE_EN.DOCTOR },
  { name: ENUM_ROLE_EN.CRM, id: ENUM_ROLE_EN.CRM },
  { name: ENUM_ROLE_EN.PA, id: ENUM_ROLE_EN.PA },
  { name: ENUM_ROLE_EN.NURSE, id: ENUM_ROLE_EN.NURSE },
  { name: ENUM_ROLE_EN.CASHIER, id: ENUM_ROLE_EN.CASHIER },
  { name: ENUM_ROLE_EN.PHARMACIST, id: ENUM_ROLE_EN.PHARMACIST },
  { name: ENUM_ROLE_EN.OTHER, id: ENUM_ROLE_EN.OTHER },
];

export const roleTh: IDataSelector[] = [
  { name: ENUM_ROLE_TH.MANAGER, id: ENUM_ROLE_EN.MANAGER },
  { name: ENUM_ROLE_TH.DOCTOR, id: ENUM_ROLE_EN.DOCTOR },
  { name: ENUM_ROLE_TH.CRM, id: ENUM_ROLE_EN.CRM },
  { name: ENUM_ROLE_TH.PA, id: ENUM_ROLE_EN.PA },
  { name: ENUM_ROLE_TH.NURSE, id: ENUM_ROLE_EN.NURSE },
  { name: ENUM_ROLE_TH.CASHIER, id: ENUM_ROLE_EN.CASHIER },
  { name: ENUM_ROLE_TH.PHARMACIST, id: ENUM_ROLE_EN.PHARMACIST },
  { name: ENUM_ROLE_TH.OTHER, id: ENUM_ROLE_EN.OTHER },
];

export const ageRange: IAgeRange[] = [
  { name: "0-10 ปี", id: 0, startAge: 0, endAge: 10 },
  { name: "11-20 ปี", id: 1, startAge: 11, endAge: 20 },
  { name: "21-30 ปี", id: 2, startAge: 21, endAge: 30 },
  { name: "31-40 ปี", id: 3, startAge: 31, endAge: 40 },
  { name: "41-50 ปี", id: 4, startAge: 41, endAge: 50 },
  { name: "51-60 ปี", id: 5, startAge: 51, endAge: 60 },
  { name: "61-70 ปี", id: 6, startAge: 61, endAge: 70 },
  { name: "71-80 ปี", id: 7, startAge: 71, endAge: 80 },
  { name: "81-90 ปี", id: 8, startAge: 81, endAge: 90 },
  { name: "91-100 ปี", id: 9, startAge: 91, endAge: 100 },
  { name: "101-110 ปี", id: 10, startAge: 101, endAge: 110 },
];

export const knowAbsolute: IDataSelector[] = [
  { nameTh: "Facebook", nameEn: "Facebook", id: "1" },
  { nameTh: "Google", nameEn: "Google", id: "2" },
  { nameTh: "Instagram", nameEn: "Instagram", id: "3" },
  { nameTh: "Website", nameEn: "Website", id: "4" },
  { nameTh: "มีผู้แนะนํามา", nameEn: "Have a referral", id: "5" },
  {
    nameTh: "รู้จักที่ตั้งของคลินิก",
    nameEn: "Know the location of the clinic",
    id: "6",
  },
  { nameTh: "งานสัมมนา", nameEn: "Seminar", id: "7" },
  { nameTh: "อื่นๆ โปรดระบุ", nameEn: "Other, please specify", id: "8" },
];

export const religion: IDataSelector[] = [
  { nameTh: "พุทธ", nameEn: "Buddhist", id: "0" },
  { nameTh: "อิสลาม", nameEn: "Islam", id: "1" },
  { nameTh: "ยูดาห์", nameEn: "Judah", id: "2" },
  { nameTh: "คริสต์", nameEn: "Christ", id: "3" },
  { nameTh: "บาไฮ", nameEn: "Baha'i", id: "4" },
  { nameTh: "ฮินดู", nameEn: "Hindu", id: "5" },
  { nameTh: "เชน", nameEn: "Jainism", id: "6" },
  { nameTh: "ซิกข์", nameEn: "Sikhs", id: "7" },
];

export const cardType: IDataSelector[] = [
  { nameTh: "บัตรปชช.", nameEn: "ID card", id: ENUM_ID_CARD.CITIZEN },
  { nameTh: "พาสปอร์ด", nameEn: "Passport", id: ENUM_ID_CARD.PASSPORT },
];

export const country: IDataSelector[] = [{ name: "ไทย", id: 1 }];

export const illnessOptions: IDataSelectorLabel[] = [
  { label: "Diabetes (เบาหวาน)", value: "1" },
  { label: "Autolmmune Disease (ภููมิบกพร่อง)", value: "2" },
  { label: "Gall Bladder Problems (ถุงน้ำดี)", value: "3" },
  { label: "Allergy (ภูมิแพ้)", value: "4" },
  { label: "Stroke (หลอดเลือดสมอง)", value: "5" },
  { label: "Gout (เก๊าท์)", value: "6" },
  { label: "Kidney Disease (ไต)", value: "7" },
  { label: "Hyperlipidemia (ไขมันสูง)", value: "8" },
  { label: "Heart Disease (หัวใจ)", value: "9" },
];

export const notificationDetail: IDataSelector[] = [
  { nameTh: "ก่อน 0 นาที", nameEn: "0 minutes before", id: 0 },
  { nameTh: "ก่อน 5 นาที", nameEn: "5 minutes before", id: 5 },
  { nameTh: "ก่อน 10 นาที", nameEn: "10 minutes before", id: 10 },
  { nameTh: "ก่อน 15 นาที", nameEn: "15 minutes before", id: 15 },
  { nameTh: "ก่อน 30 นาที", nameEn: "30 minutes before", id: 30 },
  { nameTh: "ก่อน 1 ชั่วโมง", nameEn: "1 hour before", id: 60 },
  { nameTh: "ก่อน 2 ชั่วโมง", nameEn: "2 hour before", id: 120 },
];

export const frequency: IDataSelector[] = [
  { name: "วันละ 1 ครั้ง", id: 1 },
  { name: "วันละ 2 ครั้ง", id: 2 },
  { name: "วันละ 3 ครั้ง", id: 3 },
];

export const selectDate: IDataSelector[] = [
  { nameTh: "วัน", nameEn: "Day", id: 1 },
  { nameTh: "สัปดาห์", nameEn: "Week", id: 2 },
  { nameTh: "เดือน", nameEn: "Month", id: 3 },
];

export const daySelect: IDataSelectorLabel[] = [
  { label: "sun", value: 0 },
  { label: "mon", value: 1 },
  { label: "tue", value: 2 },
  { label: "wed", value: 3 },
  { label: "thu", value: 4 },
  { label: "fri", value: 5 },
  { label: "sat", value: 6 },
];

export const remarkSender =
  "Absolute Health Integrative Medicine. ( 02-6515988 ) Urbis Buildind 3 rd. Floor (The AETAS BANGKOK Hotel) 53 Soi Ruamrudee, Ploenchic Rd., Lumpini Pathumwan, Bangkok 10330";
