import { IGetRoom } from "@interface/IRoom.interface";
import { IUserRoom } from "@interface/IUserRoom.interface";
import { IFormSlots } from "@interface/ISlots.interface";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Col, Modal, Row, Button, Divider, Form } from "antd";
import Fields from "./Fields";

type Props = {
  dataSlots?: IFormSlots;
  CommitChanges: (added: any) => void;
  doctorList: IUserRoom[];
  roomList: IGetRoom[] | [];
};

const FormScheduler = ({
  dataSlots,
  CommitChanges,
  doctorList,
  roomList,
}: Props) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState<boolean>(false);
  const [slotForm] = Form.useForm();

  useEffect(() => {
    setVisible(!visible);
    // eslint-disable-next-line
  }, []);

  const onCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title={`${t("booking.anotherBooking")}`}
      centered
      visible={visible}
      onOk={() => setVisible(true)}
      onCancel={onCancel}
      width="65%"
      footer={null}
    >
      <Fields
        dataSlots={dataSlots}
        CommitChanges={CommitChanges}
        slotForm={slotForm}
        setVisible={setVisible}
        doctorList={Array.from(
          new Map(doctorList?.map((item) => [item?.userId, item])).values()
        )}
        roomList={roomList}
      />
      <Divider className="mt-2" />
      <Row gutter={[14, 14]} justify="end">
        <Col style={{ marginLeft: "auto" }}>
          <Button className="cancel" key="back" onClick={onCancel}>
            {t("cancel")}
          </Button>
        </Col>
        <Col>
          <Button
            className="btn-create"
            onClick={() => slotForm.submit()}
            // loading={loadingButton}
          >
            {t("save")}
          </Button>
        </Col>
      </Row>
    </Modal>
  );
};

export default FormScheduler;
