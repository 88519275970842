import { IGetDraftOrder } from "@interface/IDraftOrder.interface";
import { thrownResponse } from "./auth";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { useQuery, UseQueryResult } from "react-query";
import { useMutation, UseMutationResult } from "react-query";
import axios from "@axios/index";

interface IUpDateOrderItem {
  data: any;
  patientId: number | string;
  itemId: string | number;
}

interface IDeleteDraftOrder {
  id: string | number | undefined;
  idProduct: {
    eventId?: number;
    itemId: string[];
    room?: ENUM_ROOM_SIGNATURE;
  };
}

export const useCreateNewAndProducts = () => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.post(`/draft-order/${id}/add-products`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      thrownResponse(res);
    }
  });
};

export const useDeleteDraftOrder = () => {
  return useMutation(async ({ id, idProduct }: IDeleteDraftOrder) => {
    const data = idProduct;
    const res = await axios.post(`/draft-order/${id}/del-item`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetDraftOrderList = (
  id: string | number,
  eventId: string | number
): UseQueryResult<ElementaryOneMore<IGetDraftOrder>> => {
  return useQuery(["get-draft-order-list", id, eventId], async () => {
    if (!id && id !== 0) {
      return;
    }

    const res = await axios.get(`/draft-order/${id}/${eventId}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status <= 500) {
      return res?.data;
    }
  });
};

export const useGetDraftOrderListMutation = () => {
  return useMutation(async ({ patientId, eventId }: any) => {
    const res = await axios.get(`/draft-order/${patientId}/${eventId}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useUpDateOrderItem = () => {
  return useMutation(async ({ data, patientId, itemId }: IUpDateOrderItem) => {
    const res = await axios.post(
      `/draft-order/${patientId}/update-item/${itemId}`,
      data
    );

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useCreateSaveToOrder = (): UseMutationResult => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.post(`/draft-order/${id}/save-to-order`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.status;
    }
    thrownResponse(res);
  });
};

export const useCreateNewDraftOrder = () => {
  return useMutation(async ({ data, id }: any) => {
    if (!id && id !== 0) {
      return null;
    }
    const res = await axios.post(`/draft-order/${id}/new`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.status;
    }
    thrownResponse(res);
  });
};

export const useCreatePreRegister = () => {
  return useMutation(async ({ data, id }: any) => {
    if (!id && id !== 0) {
      return null;
    }
    const res = await axios.post(`/draft-order/${id}/preregister`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.status;
    }
    thrownResponse(res);
  });
};

export const useUploadFileDraftOrder = () => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.post(`/draft-order/${id}/upload-file`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    thrownResponse(res);
  });
};

export const useDeleteFileDraftOrder = () => {
  return useMutation(async ({ patientId, eventId }: any) => {
    const res = await axios.delete(`/draft-order/${patientId}/${eventId}/file`);

    if (res?.status >= 200 && res?.status < 300) {
      return res;
    }
    thrownResponse(res);
  });
};

export const useCreateSaveToOrderToRegister = (): UseMutationResult => {
  return useMutation(async ({ patientId, eventId }: any) => {
    const res = await axios.post(
      `/draft-order/${patientId}/${eventId}/save-to-register`
    );

    if (res?.status >= 200 && res?.status < 300) {
      return res?.status;
    }
    thrownResponse(res);
  });
};

export const useCreateSignature = () => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.post(`/draft-order/${id}/signature`, data);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      thrownResponse(res);
    }
  });
};

export const useCreateAddProductDrugsOption = () => {
  return useMutation(async ({ id, data }: any) => {
    const res = await axios.post(
      `/draft-order/${id}/add-products/drugs-option`,
      data
    );

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      thrownResponse(res);
    }
  });
};
