import CanvasDraw from "react-canvas-draw";

const ViewDrawingCanvas = ({ drawing }) => {
    return (
        <div className="img-def">
            <CanvasDraw
                // ref={stageRef}
                saveData={drawing}
                immediateLoading={true}
                disabled={true}
                hideGrid={true}
            />
        </div>
    );
};

export default ViewDrawingCanvas;
