import React, { useEffect, useRef, useState } from "react";
// import { dayjs } from "@tools/timezone";
import { IItem } from "@interface/IOrder.interface";
import { IGroup } from "@interface/IDraftOrder.interface";
import { ColumnsType } from "antd/lib/table/interface";
import { useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { openNotification } from "@components/notification/Notification";
import { useUpDateOrderItem } from "@services/api/draft_order";
import { ENUM_ROOM_SIGNATURE } from "@tools/util.enum";
import { useDeleteDraftOrder } from "@services/api/draft_order";
import { Button, Form, Popover } from "antd";
import { ISelected, ITotalAndQuantity } from "@interface/IGlobal.interface";
import { checkThereKey, productName, TextBRHTML } from "@tools/util";
import { DeleteOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import Decimal from "@tools/decimal";
import Modaler from "@components/modaler/Modaler";
import MoTable from "@components/table/Table";
import BigNumber from "bignumber.js";
import ModalUpdate from "@components/draft_order/modal_update/ModalUpdate";
import moment from "moment";

interface IOrder extends IItem {
  align: string;
  level?: number;
}
type keyName = "oneDay" | "continuous";

type Props = {
  patientID: string | number;
  draftOrderList?: IGroup[];
  selected: ISelected;
  totalAndQuantity: ITotalAndQuantity;
  medicalRecord: boolean;
  nameKey: keyName;
  eventId: number;
};

const TableOrderList = ({
  patientID,
  draftOrderList,
  selected,
  totalAndQuantity,
  medicalRecord,
  nameKey,
  eventId,
}: Props) => {
  const { t, i18n } = useTranslation();
  const num = useRef(0);
  const [visible, setVisible] = useState({
    edit: false,
    delete: false,
  });
  const [product, setProduct] = useState<any>({
    idProduct: "",
    edit: "",
    total: "",
  });
  const match: any = useRouteMatch();
  const appointmentId = match.params.appointmentsID;
  const client = useQueryClient();
  const [editOrder] = Form.useForm();
  const { mutate, isLoading } = useUpDateOrderItem();
  const deleteDraftOrder = useDeleteDraftOrder();

  useEffect(() => {
    client.invalidateQueries(["get-draft-order-list"]);
    // eslint-disable-next-line
  }, []);

  const onModal = () => {
    setVisible({ ...visible, delete: !visible?.delete });
  };

  const handleDeleteDraftOrder = (id: string) => {
    deleteDraftOrder.mutate(
      {
        id: patientID,
        idProduct: {
          eventId: eventId,
          itemId: [id],
          room: ENUM_ROOM_SIGNATURE.PA_ROOM,
        },
      },
      {
        onSuccess: () => {
          openNotification({ method: "DEL_SUCCESS" });
        },
        onError: (err) => {
          openNotification({ method: "DEL_ERROR", message: String(err) });
        },
        onSettled: () => {
          client.invalidateQueries(["get-appointment-byID", appointmentId]);
          client.invalidateQueries(["get-draft-order-list"]);
        },
      }
    );

    onModal();
  };

  const onDelete = () => {
    handleDeleteDraftOrder(product?.idProduct);
  };

  const onSelectChange = (id: any, value: any) => {
    for (let i = 0; i < 6; i++) {
      const indexNumberParent = id?.indexOf(i);
      if (indexNumberParent > -1) {
        id?.splice(indexNumberParent, 1);
      }
      const indexStringParent = id?.indexOf(`children${i}`);
      if (indexStringParent > -1) {
        id?.splice(indexStringParent, 1);
      }
    }

    const newProduct = value?.filter((item: any) => {
      return item?.level === 3;
    });

    const newKey = newProduct?.flatMap((item: any) => {
      return item?.id;
    });

    selected.setSelectedRowKeys({
      ...selected?.selectedRowKeys,
      [nameKey]: newKey,
    });

    const itemList = checkThereKey(value, "string");
    let normal = 0;
    const total = itemList
      ?.map((item) => {
        normal = Number(new BigNumber(normal)?.plus(item?.total || 0));
        return item?.net;
      })
      ?.flat();
    let sum = 0;
    total?.forEach((item: any) => {
      sum = Number(new BigNumber(sum)?.plus(new BigNumber(item)));
    });

    totalAndQuantity.setTotalAndQuantity({
      ...totalAndQuantity?.totalAndQuantity,
      discount: null,

      [nameKey]: {
        normal: normal,
        total: sum,
        quantity: itemList?.length,
      },
      discountTotal:
        nameKey === "oneDay"
          ? new BigNumber(sum)?.plus(
              new BigNumber(
                totalAndQuantity?.totalAndQuantity?.continuous?.total
              )?.plus(totalAndQuantity?.totalAndQuantity?.labInterface?.total)
            )
          : new BigNumber(totalAndQuantity?.totalAndQuantity?.oneDay?.total)
              ?.plus(sum)
              ?.plus(totalAndQuantity?.totalAndQuantity?.labInterface?.total),
    });
  };

  const onCancel = () => {
    setVisible({ ...visible, edit: !visible?.edit });
  };

  const rowSelection = {
    selectedRowKeys: selected?.selectedRowKeys?.[nameKey],
    onChange: onSelectChange,
    // hideSelectAll: true,
    onSelect: (e: any) => {},
    getCheckboxProps: (record: any) => ({
      disabled: record?.level === 5 || record?.level === 4,
    }),
  };

  const onClickRow = async (event: IItem) => {
    editOrder.resetFields();
    if (event.id) {
      setProduct({ ...product, edit: event });
      const option = event?.option ? JSON.parse(event?.option || `{}`) : {};
      editOrder.setFieldsValue({
        ...event,
        ...event.product,
        nameShown: event?.product?.nameTh,
        genericName: event?.product?.nameEn,
        ...option,
        drugExpiration: option?.drugExpiration
          ? moment(option?.drugExpiration)
          : undefined,
        dateStart: option?.dateStart ? moment(option?.dateStart) : undefined,
        dateStop: option?.dateStop ? moment(option?.dateStop) : undefined,
        qty: event?.qty,
        specialInstructions: option?.specialInstructions || event?.usageTh,
        productDescriptionTh:
          event?.productDescriptionTh ||
          event?.product?.propertiesTh ||
          event?.product?.descriptionTh,
        productDescriptionEn:
          event?.productDescriptionEn ||
          event?.product?.propertiesEn ||
          event?.product?.descriptionEn,
        name: draftOrderList?.[0]?.name,
        categoryID: event?.product?.category?.id || event?.product?.categoryId,
        categoryParentId: event?.category?.id,
        discount:
          event?.discountType === "%"
            ? event?.discountPercent || 0
            : event?.discount || 0,
        discountType: event?.discountType || null,
        product: event?.product,
        frequencyTh: option?.frequencyTh || event?.product?.usageTh || "",
        frequencyEn: option?.frequencyEn || event?.product?.usageEn || "",
        groupNameTh: event?.product?.group?.nameTh,
      });
    }
  };

  const handleUpDateOrderItem = (value: any) => {
    let data;
    if (value?.discountType === "%") {
      data = {
        discountPercent: Number(value?.discount),
        discountType: value?.discountType,
        qty: Number(value?.qty),
      };
    } else if (value.discountType === "฿") {
      data = {
        discount: Number(value?.discount),
        discountType: value?.discountType,
        qty: Number(value?.qty),
      };
    } else if (value.qty) {
      data = {
        qty: Number(value.qty),
      };
    }
    const newData = {
      ...data,
      productDescriptionTh: value?.productDescriptionTh,
      productDescriptionEn: value?.productDescriptionEn,
      option: JSON.stringify(value),
      eventId: eventId,
      room: ENUM_ROOM_SIGNATURE.PA_ROOM,
    };
    mutate(
      { data: newData, patientId: patientID, itemId: product?.edit?.id },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
        },
        onError: () => {
          openNotification({ method: "PUT_ERROR" });
        },
        onSettled: () => {
          client.invalidateQueries(["get-appointment-byID", appointmentId]);
          client.invalidateQueries(["get-draft-order-list"]);
          onCancel();
        },
      }
    );
  };

  const columns: ColumnsType<IOrder> = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, data: any, index: number) => {
        return (
          <div>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? ""
              : data?.level === 3
              ? index + 1
              : ""}
          </div>
        );
      },
    },

    {
      title: `${t("name")}${t("list")}`,
      dataIndex: "name",
      key: "name",
      render: (_: any, data: IOrder) => {
        const name = data?.children?.[0]?.children?.[0]?.product?.category;

        return (
          <div>
            {data?.level === 1
              ? i18n.language === "th"
                ? name?.nameTh
                : name?.nameEn
              : data?.level === 2 || data?.level === 4
              ? t("nameList")
              : productName(
                  i18n.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )}
          </div>
        );
      },
    },
    {
      title: "",
      dataIndex: "product",
      key: "product",
      render: (_: any, data: any) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("type")
              : data?.level === 3
              ? data?.product?.group?.nameTh
              : ""}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "gratis",
      key: "gratis",
      render: (gratis: boolean, data: IGroup & IItem & any) => {
        return data?.category === "C-0001" ||
          data?.product?.categoryId === "C-0001" ||
          data?.children?.[0]?.product?.categoryId === "C-0001" ? (
          <React.Fragment>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("Gratis")
              : !gratis && data?.level === 3
              ? "NO"
              : data?.level === 3
              ? "YES"
              : ""}
          </React.Fragment>
        ) : (
          <React.Fragment></React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "productDescription",
      key: "productDescription",
      render: (productDescription: string, data: any) => {
        return (
          <div>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 || data?.level === 4 ? (
              t("details")
            ) : data?.level === 3 || data?.level === 5 ? (
              <Popover
                content={
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        i18n.language === "th"
                          ? TextBRHTML(
                              productDescription ||
                                data?.product?.propertiesTh ||
                                data?.product?.descriptionTh ||
                                ""
                            )
                          : TextBRHTML(
                              productDescription ||
                                data?.product?.propertiesEn ||
                                data?.product?.descriptionEn ||
                                ""
                            ),
                    }}
                  ></div>
                }
              >
                <div className="text-content">
                  {i18n.language === "th"
                    ? productDescription ||
                      data?.product?.propertiesTh ||
                      data?.product?.descriptionTh ||
                      "-"
                    : productDescription ||
                      data?.product?.propertiesEn ||
                      data?.product?.descriptionEn ||
                      "-"}
                </div>
              </Popover>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "option",
      key: "option",
      render: (option: string, data: any) => {
        const value = JSON.parse(option || `{}`);
        return (
          <div>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 ? (
              t("remarks")
            ) : data?.level === 3 ? (
              <Popover content={value?.remark || ""}>
                <div className="text-content">{value?.remark || ""}</div>
              </Popover>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: any, data: any) => {
        return (
          <div>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("unitPrice")
              : data?.level === 3
              ? Decimal(data?.normal)
              : ""}
          </div>
        );
      },
    },

    {
      title: `${t("name")}${t("list")}`,
      dataIndex: "product",
      key: "product",
      align: "center",
      render: (_: any, data: any) => {
        return (
          <React.Fragment>
            {data?.level === 1 ? (
              <div>{`${data?.children?.[0]?.children?.length}`}</div>
            ) : data?.level === 2 || data?.level === 4 ? (
              t("amount")
            ) : data?.level === 3 || data?.level === 5 ? (
              <div>{data?.qty + "   "}</div>
            ) : (
              ""
            )}
          </React.Fragment>
        );
      },
    },

    {
      title: "",
      dataIndex: "name",
      key: "name",
      render: (_: any, data: any) => {
        return (
          <div>
            {data?.level === 1 ? (
              ""
            ) : data?.level === 2 ? (
              t("discounts")
            ) : data?.level === 3 ? (
              <React.Fragment>
                {`${
                  data?.discountType === "%"
                    ? data?.discountPercent || 0
                    : data?.discount
                }   ${data?.discountType || ""} `}
              </React.Fragment>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      title: t("price"),
      dataIndex: "net",
      key: "net",
      render: (net: any, data: any) => {
        return (
          <div>
            {data?.level === 1
              ? Decimal(Number(data?.total))
              : data?.level === 2
              ? t("price")
              : data?.level === 3
              ? Decimal(Number(net))
              : ""}
          </div>
        );
      },
    },

    medicalRecord
      ? {
          title: t("manage"),
          dataIndex: "manage",
          key: "manage",
          render: (_: any, data: any) => {
            return (
              <div>
                {data?.level === 1 ? (
                  ""
                ) : data?.level === 2 || data?.level === 4 ? (
                  t("manage")
                ) : (data?.product && data?.level === 3) ||
                  data?.level === 5 ? (
                  <Button
                    type="primary"
                    onClick={(e) => {
                      onClickRow(data);
                      setProduct({
                        ...product,
                        total: data?.total,
                        edit: data,
                      });
                      onCancel();
                      e?.stopPropagation();
                    }}
                  >
                    {t("edit")}
                  </Button>
                ) : (
                  ""
                )}
              </div>
            );
          },
        }
      : {},

    medicalRecord
      ? {
          title: t("delete"),
          dataIndex: "product",
          key: "product",
          render: (product: any, data: any) => {
            return (
              <div>
                {data?.level === 1 ? (
                  ""
                ) : data?.level === 2 ? (
                  t("delete")
                ) : data?.product && data?.level === 3 ? (
                  <DeleteOutlined
                    onClick={(e) => {
                      setProduct({
                        ...product,
                        idProduct: data?.id,
                        total: data?.total,
                      });
                      onModal();
                      e?.stopPropagation();
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            );
          },
        }
      : {},
  ];

  return (
    <div>
      <Modaler
        visible={visible?.delete}
        onCancel={onModal}
        onOk={onDelete}
        width="45%"
        header={
          <div>
            <ExclamationCircleOutlined className="icon-warning-del" />
            {`${t("areYouSure")}`}
          </div>
        }
      >
        {t("doYouWantToDelete")}
      </Modaler>
      {!!visible.edit && (
        <ModalUpdate
          editOrder={editOrder}
          visibleInput={visible?.edit}
          onCancel={onCancel}
          handleUpDateOrderItem={handleUpDateOrderItem}
          title="editConfirm"
          total={product?.total}
          isLoading={isLoading}
        />
      )}

      <MoTable
        className="components-table-demo-nested table-selection table-confirm "
        rowKey={"key"}
        key={"key"}
        pagination={false}
        expandRowByClick={true}
        columns={columns}
        rowSelection={
          medicalRecord ? { ...rowSelection, checkStrictly: false } : undefined
        }
        dataSource={draftOrderList || []}
        rowClassName={(r) => {
          if (r?.level === 1) {
            return "no-header";
          } else if (r?.level === 2) {
            return "table-header no-header ";
          } else if (r?.level === 3 && r?.children) {
            return "no-header";
          } else if (r?.level === 5) {
            num.current = num?.current - 1;
            if (num?.current === 0) {
              return "redio-rowClass table-confirm-first table-confirm-level-course table-bottom--level-course-num";
            } else {
              return "redio-rowClass table-confirm-first table-confirm-level-course";
            }
          } else if (r?.level === 4) {
            num.current = r?.children?.length;
            return "redio-rowClass table-header no-header table-confirm-level-course table-top-level-course ";
          }
          return "";
        }}
        onDoubleClick={false}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </div>
  );
};

export default TableOrderList;
