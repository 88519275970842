import { dayjs } from "@tools/timezone";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { overviewMedicalRoom } from "@assets/data/pathRouter";
import { Button, Col, Drawer, Row } from "antd";
import { RouteComponentProps, useHistory } from "react-router-dom";
import Affixes from "@components/affixes/Affixes";
import Scheduler from "./Scheduler";
import ListQueue from "@components/list_queue/ListQueue";
import ModalBooking from "./modal_booking/ModalBooking";

const MedicalRoomReservation = () => {
  const { t } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const [visible, setVisible] = useState({ drawer: false, booking: false });
  const [currentDateChange, setCurrentDateChange] = useState<string>(
    dayjs().format("YYYY-MM-DD")
  );

  const handleOverView = () => {
    history.push(overviewMedicalRoom);
  };

  const showDrawer = () => {
    setVisible({ ...visible, drawer: !visible.drawer });
  };

  const showBooking = () => {
    setVisible({ ...visible, booking: !visible.booking });
  };

  return (
    <div>
      <Row className="second-header " align="middle">
        <Col>
          <div>
            <h1>{t("booking.doctorBooking")}</h1>
          </div>

          <span className="t-dark-blue f-normal">
            <b>{t("appointment")}</b>
          </span>
        </Col>
        <Col style={{ marginLeft: "auto" }}>
          <Button
            className="btn-create mr-05"
            type="primary"
            onClick={showBooking}
          >
            {t("appointment")}
          </Button>

          <Button className="seeMore" type="primary" onClick={handleOverView}>
            {t("seeOverview")}
          </Button>
        </Col>
      </Row>
      <div className="set-layout body-height ">
        <Row gutter={[14, 14]}>
          <Col md={24} lg={17}>
            <Scheduler
              currentDateChange={{
                currentDateChange: currentDateChange,
                setCurrentDateChange: setCurrentDateChange,
              }}
            />
          </Col>
          <Col lg={7} className="card-display">
            <ListQueue
              currentDateChange={{
                currentDateChange: currentDateChange,
                setCurrentDateChange: setCurrentDateChange,
              }}
            />
          </Col>
        </Row>

        <Affixes onClick={showDrawer} />

        <Drawer
          width={"50%"}
          closable={false}
          placement="right"
          onClose={showDrawer}
          visible={visible.drawer}
        >
          <ListQueue
            status="noCard"
            currentDateChange={{
              currentDateChange: currentDateChange,
              setCurrentDateChange: setCurrentDateChange,
            }}
          />
        </Drawer>
      </div>
      <ModalBooking visible={visible.booking} setModal={showBooking} />
    </div>
  );
};

export default MedicalRoomReservation;
