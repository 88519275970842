import { dayjs } from "@tools/timezone";
import { useTranslation } from "react-i18next";
import { useQueryClient } from "react-query";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";

type Props = {
  setSearchValue: (value: any) => void;
  setCurrentPage: (e: number) => void;
};

const Search = ({ setSearchValue, setCurrentPage }: Props) => {
  const { t } = useTranslation();
  const client = useQueryClient();
  const dateFormat = "DD-MM-YYYY";

  const onFinish = (values: any) => {
    setSearchValue({
      ...values,
      date: values.date ? dayjs(values.date) : undefined,
    });
    setCurrentPage(1);
    client.invalidateQueries(["order-reject-course"]);
  };
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={12} lg={9}>
            <Form.Item name="date">
              <DatePicker
                format={dateFormat}
                placeholder={t("courseReturnDate")}
              />
            </Form.Item>
          </Col>

          <Col md={12} lg={9}>
            <Form.Item name="receiptNumber">
              <Input placeholder={t("documentNumber")} />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
