import React, { useEffect, useRef, useState } from "react";
import { IGetVisits } from "@interface/IVisits.interface";
import { useRouteMatch } from "react-router-dom";
import { dataUrlToFile } from "@tools/util";
import { useQueryClient } from "react-query";
import { useCreateMedia } from "@services/api/media_object";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { IGetWorkOrderItem } from "@interface/IWorkOrder.interface";
import { Button, Card, Checkbox } from "antd";
import { EVENT_ROOM, ORDER_STATUS } from "@tools/util.enum";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useUpdateWorkOrderItemById } from "@services/api/work_order";
import { ENUM_G6PD, ENUM_SERVICE_GROUP } from "@tools/util.enum";
import { ENUM_ROOM_SIGNATURE, ORDER_TYPE } from "@tools/util.enum";
import { useUpdateWorkOrderCourseItemById } from "@services/api/work_order";
import { Col, Empty, Form, FormInstance, Row } from "antd";
import empty from "@assets/img/empty.svg";
import moment from "moment";
import Advice from "@components/advice/Advice";
import PTNote from "../pt_note/PTNote";
import OrderList from "../order_list/OrderList";
import SymbolView from "@components/symbol/Symbol";
import ViewMarker from "@components/marker/view_marker/ViewMarker";
import ModalMarker from "@components/marker/modal_marker/ModalMarker";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import PhysicalRecord from "../physical_record/PhysicalRecord";
import ProductCategory from "@components/product_category/ProductCategory";

interface ITypeOpen {
  oneDay: boolean;
  continuous: boolean;
  soap: boolean;
  notes: boolean;
}

interface IOpen {
  open: ITypeOpen;
  setOpen: (e: ITypeOpen) => void;
}

type Props = {
  patientID: number;
  visitsByID?: IGetVisits;
  patientList: FormInstance;
  orderList?: IGetWorkOrderItem;
  medicalRecord: boolean;
  open: IOpen;
};

const Fields = ({
  patientID,
  visitsByID,
  patientList,
  orderList,
  medicalRecord,
  open,
}: Props) => {
  const { t, i18n } = useTranslation();
  const match: any = useRouteMatch();
  const visitId = match.params.visitID;
  const stageRef = useRef<any>(null);
  const [workOrderID, setWorkOrderID] = useState<number>();
  const updateWorkOrderItemById = useUpdateWorkOrderItemById();
  const updateWorkOrderCourseItemById = useUpdateWorkOrderCourseItemById();
  const client = useQueryClient();
  const createMedia = useCreateMedia();

  const [visible, setVisible] = useState({
    open: true,
    maker: false,
    openSOAP: false,
    makerSOAP: false,
    oneDay: false,
    continuous: false,
  });

  useEffect(() => {
    setVisible({
      ...visible,
      openSOAP: !![
        ...(visitsByID?.visitMarker?.soapMarker0 || []),
        ...(visitsByID?.visitMarker?.soapMarker1 || []),
        ...(visitsByID?.visitMarker?.soapMarker2 || []),
        ...(visitsByID?.visitMarker?.soapMarker3 || []),
      ]?.length,
      open: !![
        ...(visitsByID?.visitMarker?.doctorMarker0 || []),
        ...(visitsByID?.visitMarker?.doctorMarker1 || []),
        ...(visitsByID?.visitMarker?.doctorMarker2 || []),
        ...(visitsByID?.visitMarker?.doctorMarker3 || []),
      ]?.length,
    });
    // eslint-disable-next-line
  }, [visitsByID]);

  const handleCreateMedia = (data: any) => {
    if (stageRef?.current) {
      let imageData =
        stageRef?.current?.canvas?.drawing?.toDataURL("image/png");
      const file = dataUrlToFile(imageData, "signature.png");
      if (file) {
        const formData = new FormData();
        formData?.append("file", file);

        createMedia.mutate(formData, {
          onSuccess: (res) => {
            if (
              data?.categoryId === "C-0006" ||
              data?.categoryId === "C-0007"
            ) {
              handleUpdateWorkOrderItemCourse({
                ...data,
                signatureId: res?.result?.[0]?.id,
              });
            } else {
              handleUpdateWorkOrderItem({
                ...data,
                signatureId: res?.result?.[0]?.id,
              });
            }
          },
          onError: (error) => {
            openNotification({ method: "POST_ERROR", message: String(error) });
          },
        });
      }
    } else {
      if (data?.categoryId === "C-0006" || data?.categoryId === "C-0007") {
        handleUpdateWorkOrderItemCourse(data);
      } else handleUpdateWorkOrderItem(data);
    }
  };

  const handleUpdateWorkOrderItem = (data: any) => {
    const option = {
      time: data?.time,
      times: data?.times,
      ...data,
    };
    updateWorkOrderItemById.mutate(
      {
        data: {
          ...data,
          status: ORDER_STATUS.PARTIAL,
          remark: data?.remark || "",
          use: data?.use || false,
          option: JSON.stringify(option),
        },
        id: workOrderID,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries([
            "get-workOrders-out-standing",
            patientID,
            {
              serviceGroup: ENUM_SERVICE_GROUP.PHYSICAL_THERAPY,
            },
          ]);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const handleUpdateWorkOrderItemCourse = (data: any) => {
    const option = {
      time: data?.time,
      times: data?.times,
      ...data,
    };
    updateWorkOrderCourseItemById.mutate(
      {
        data: {
          ...data,
          status: ORDER_STATUS.PARTIAL,
          remark: data?.remark || "",
          use: data?.use || false,
          option: JSON.stringify(option),
        },
        id: workOrderID,
      },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          client.invalidateQueries([
            "get-workOrders-out-standing",
            patientID,
            {
              serviceGroup: ENUM_SERVICE_GROUP.PHYSICAL_THERAPY,
            },
          ]);
        },
        onError: (err) => {
          openNotification({ method: "PUT_ERROR", message: String(err) });
        },
      }
    );
  };

  const openMaker = () => {
    setVisible({ ...visible, maker: !visible.maker });
  };

  const openMakerSOAP = () => {
    setVisible({ ...visible, makerSOAP: !visible.makerSOAP });
  };

  const viewDetail = (value?: boolean) => {
    setVisible({ ...visible, open: value || !visible.open });
  };

  const viewDetailSOAP = (value?: boolean) => {
    setVisible({ ...visible, openSOAP: value || !visible.openSOAP });
  };

  const viewDetailOneDay = () => {
    open?.setOpen({ ...open?.open, oneDay: !open?.open.oneDay });
  };
  const viewDetailContinuous = () => {
    open?.setOpen({ ...open?.open, continuous: !open?.open.continuous });
  };

  const viewDetailNotes = () => {
    open?.setOpen({ ...open?.open, notes: !open?.open.notes });
  };

  const onManageModal = (key: "oneDay" | "continuous") => {
    setVisible({
      ...visible,
      [key]: !visible?.[key],
    });
  };

  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room "
        extra={
          <>
            <Row
              gutter={[14, 14]}
              className="text-row-profile ml-05"
              align="middle"
            >
              <Col>
                <ImagePatient src={visitsByID?.patient?.image} />
              </Col>
              <Col>
                <Row>
                  <Col xs={24} md={24} lg={24}>
                    {i18n.language === "th"
                      ? ` ${visitsByID?.patient?.firstNameTh || " "}    ${
                          visitsByID?.patient?.lastNameTh || " "
                        }`
                      : ` ${visitsByID?.patient?.firstNameEn || " "}    ${
                          visitsByID?.patient?.lastNameEn || " "
                        }`}
                  </Col>

                  <Col xs={24} md={24} lg={24}>
                    <div className="code-profile">
                      HN : {visitsByID?.patient?.hn}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col className="pr-15" style={{ marginLeft: "auto" }}>
                <div className="t-center ">
                  <div className=" t-grey fs-18 ">{t("orderNumber")} </div>
                  <div className=" t-grey-light fs-12 ">
                    {visitsByID?.orderNo}{" "}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <Row align="middle" justify="space-between">
          <Col>
            <div className="mb-05">
              <span className=" t-blue fs-18 ">{t("fullList")} </span>
              <span className=" t-grey-light  fs-12 ">
                {`${t("orderCreationDate")} : `}
              </span>
              <span className="t-grey fs-12 ">
                {" "}
                {visitsByID
                  ? `${moment(visitsByID.date).format("DD-MM-YYYY")}`
                  : ``}
              </span>
            </div>
          </Col>
          <Col>
            <Col style={{ marginLeft: "auto" }}>
              <div style={{ color: "#FEB139" }}>
                <SymbolView />
              </div>
              <div
                style={{
                  color: "red",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {visitsByID?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                  ? "G6PD Deficiency"
                  : null}
              </div>
            </Col>
          </Col>
        </Row>

        <Form name="patientList" form={patientList}>
          <ImportantInfo />
        </Form>
        <Advice visitsByID={visitsByID} />

        <div className="mt-2 ">
          <Row className=" header-table" align="middle">
            <Col className="f-normal  t-dark-blue  ">
              <span className="f-bold mr-05 ">
                {`${t("listExecutedOrders")} One Day`}
              </span>
              <span>
                {React.createElement(
                  open?.open.oneDay ? UpOutlined : DownOutlined,
                  {
                    onClick: viewDetailOneDay,
                  }
                )}
              </span>
            </Col>
            {medicalRecord && (
              <Col style={{ marginLeft: "auto" }}>
                <Button
                  className="btn-print"
                  type="primary"
                  onClick={() =>
                    setVisible({
                      ...visible,
                      oneDay: !visible.oneDay,
                    })
                  }
                >
                  {`+ ${t("addOrder")}`}
                </Button>
              </Col>
            )}
          </Row>

          <div className={open?.open.oneDay ? "open" : "close"}>
            <OrderList
              orderList={orderList?.one?.groups || []}
              stageRef={stageRef || undefined}
              handleUpdateWorkOrderItem={handleCreateMedia}
              setWorkOrderID={setWorkOrderID}
              medicalRecord={medicalRecord}
            />
          </div>
        </div>

        <div className="mt-2">
          <Row className="header-table" align="middle">
            <Col className="f-normal t-dark-blue">
              <span className="f-bold mr-05 ">
                {`${t("listExecutedOrders")} Continuous`}
              </span>
              <span>
                {React.createElement(
                  open?.open.continuous ? UpOutlined : DownOutlined,
                  {
                    onClick: viewDetailContinuous,
                  }
                )}
              </span>
            </Col>
            {medicalRecord && (
              <Col style={{ marginLeft: "auto" }}>
                <Button
                  className="btn-print"
                  type="primary"
                  onClick={() =>
                    setVisible({
                      ...visible,
                      continuous: !visible.continuous,
                    })
                  }
                >
                  {`+ ${t("addOrder")}`}
                </Button>
              </Col>
            )}
          </Row>

          <div className={open?.open.continuous ? "open" : "close"}>
            <OrderList
              orderList={orderList?.continuous?.groups || []}
              stageRef={stageRef || undefined}
              handleUpdateWorkOrderItem={handleCreateMedia}
              setWorkOrderID={setWorkOrderID}
              medicalRecord={medicalRecord}
            />
          </div>
        </div>

        <div className="mt-2 ">
          <Checkbox checked={visible?.open} onChange={() => viewDetail()}>
            <span className="f-normal t-dark-blue">
              {t("physicalInformationDoctor")}
            </span>
          </Checkbox>
        </div>

        <div className={visible?.open ? "open" : "close"}>
          <Row
            className="header-table mt-2"
            justify="space-between"
            align="middle"
          >
            <Col lg={18} className="f-normal col-start t-dark-blue">
              <span className="f-bold pr-05 ">
                {t("physicalInformationDoctor")}
              </span>
            </Col>
          </Row>

          {visitsByID?.visitMarker?.doctorMarker0 ||
          visitsByID?.visitMarker?.doctorMarker1 ||
          visitsByID?.visitMarker?.doctorMarker2 ||
          visitsByID?.visitMarker?.doctorMarker3 ? (
            <ViewMarker visitsByID={visitsByID} />
          ) : (
            <div className="border-note p-1">
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">{`${t(
                    "addPhysicalPoint"
                  )}`}</span>
                }
              ></Empty>
            </div>
          )}
        </div>

        <div className="mt-2 ">
          <Checkbox
            checked={visible?.openSOAP}
            onChange={() => viewDetailSOAP()}
          >
            <span className="f-normal t-dark-blue">
              {t("physicalDataSOAP")}
            </span>
          </Checkbox>
        </div>

        <div className={visible?.openSOAP ? "open" : "close"}>
          <Row
            className="header-table mt-2"
            justify="space-between"
            align="middle"
          >
            <Col lg={18} className="f-normal col-start t-dark-blue">
              <span className="f-bold pr-05 ">{t("physicalDataSOAP")}</span>
            </Col>
            {medicalRecord ? (
              <Col lg={6} className="col-end">
                {visitsByID?.visitMarker?.soapMarker0 ||
                visitsByID?.visitMarker?.soapMarker1 ||
                visitsByID?.visitMarker?.soapMarker2 ||
                visitsByID?.visitMarker?.soapMarker3 ? (
                  <Button type="primary" onClick={openMakerSOAP}>
                    {t("edit")}
                  </Button>
                ) : null}
              </Col>
            ) : null}
          </Row>

          {visitsByID?.visitMarker?.soapMarker0 ||
          visitsByID?.visitMarker?.soapMarker1 ||
          visitsByID?.visitMarker?.soapMarker2 ||
          visitsByID?.visitMarker?.soapMarker3 ? (
            <ViewMarker visitsByID={visitsByID} SOAP={true} />
          ) : (
            <div className="border-note p-1">
              <Empty
                image={empty}
                imageStyle={{
                  height: 60,
                }}
                description={
                  <span className="t-grey-white">{`${t(
                    "addPhysicalPoint"
                  )}`}</span>
                }
              >
                {medicalRecord ? (
                  <Button type="primary" onClick={openMakerSOAP}>
                    + {t("addPhysicalPoint")}
                  </Button>
                ) : null}
              </Empty>
            </div>
          )}
        </div>

        <div className="mt-2 ">
          <Checkbox checked={open?.open.notes} onChange={viewDetailNotes}>
            <span className="f-normal t-dark-blue">
              {t("physicalTherapyTreatmentRecordForm")}
            </span>
          </Checkbox>
        </div>

        <div className={open?.open.notes ? "open" : "close"}>
          <PhysicalRecord
            title={t("physicalTherapyTreatmentRecordForm")}
            name="physicalNote"
            visitsByID={visitsByID}
            medicalRecord={medicalRecord}
            haveData={visitsByID?.physicalNote?.date}
          />
        </div>

        <PhysicalRecord
          title="SOAP Note"
          name="soapNote"
          visitsByID={visitsByID}
          medicalRecord={medicalRecord}
          open={open}
          haveData={visitsByID?.soapNote?.date}
        />

        <PTNote
          title="PT Note"
          medicalRecord={medicalRecord}
          visitsByID={visitsByID}
        />
      </Card>
      {visible?.maker && (
        <ModalMarker
          visible={visible?.maker}
          onModal={openMaker}
          visitsByID={visitsByID}
        />
      )}

      {visible?.makerSOAP && (
        <ModalMarker
          visible={visible?.makerSOAP}
          onModal={openMakerSOAP}
          visitsByID={visitsByID}
          SOAP={true}
        />
      )}

      {visible.oneDay && (
        <ProductCategory
          patientID={visitId}
          visible={visible.oneDay}
          onModal={() => onManageModal("oneDay")}
          status={EVENT_ROOM.PHYSIOTHERAPY}
          type={ORDER_TYPE.ONE_DAY}
          room={ENUM_ROOM_SIGNATURE.PHYSICAL_THERAPY_ROOM}
        />
      )}
      {visible.continuous && (
        <ProductCategory
          patientID={visitId}
          visible={visible.continuous}
          onModal={() => onManageModal("continuous")}
          status={EVENT_ROOM.PHYSIOTHERAPY}
          type={ORDER_TYPE.CONTINUE}
          room={ENUM_ROOM_SIGNATURE.PHYSICAL_THERAPY_ROOM}
        />
      )}
    </>
  );
};

export default Fields;
