import React, { FC } from "react";
import { IInvoice } from "@interface/IInvoice.interface";
import { UploadFile } from "antd/lib/upload/interface";
import { PAY_LIST_TAB } from "@assets/data/pathRouter";
import { PrinterFilled } from "@ant-design/icons";
import { fileToDataUrl } from "@tools/util";
import { RECEIPT_STATUS } from "@tools/util.enum";
import { useTranslation } from "react-i18next";
import { ElementaryOneMore } from "@interface/IGlobal.interface";
import { RouteComponentProps } from "react-router-dom";
import { IReceipt, IReceiptSlip } from "@interface/IReceipt.interface";
import { FormInstance, Input, Row, Table } from "antd";
import { Card, Col, DatePicker, Divider, Form } from "antd";
import CImage from "@components/image/CImage";
import Decimal from "@tools/decimal";
import TextArea from "antd/lib/input/TextArea";
import BigNumber from "bignumber.js";
import FileSpace from "@components/file_space/FileSpace";

type Props = {
  receiptIDList?: ElementaryOneMore<IReceipt>;
  receiptForm: FormInstance;
  onFinish: (values: any) => void;
  history: RouteComponentProps["history"];
  receiptID: number;
  medicalRecord: boolean;
};

const Fields = ({
  receiptIDList,
  receiptForm,
  onFinish,
  history,
  receiptID,
  medicalRecord,
}: Props) => {
  const dateFormat = "DD-MM-YYYY";
  const { t, i18n } = useTranslation();

  const columns = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      render: (_: any, __: any, index: number) => {
        return <div key={index}>{index + 1}</div>;
      },
    },
    {
      title: `${t("productName")} / ${t("details")}`,
      dataIndex: "name",
      key: "index",
    },
    {
      title: t("amount"),
      dataIndex: "qty",
      key: "index",
    },

    {
      title: t("totalPrice"),
      dataIndex: "total",
      render: (total: string) => {
        return Decimal(total || 0);
      },
    },

    {
      title: t("outstandingBalance"),
      dataIndex: "outstandingBalance",
      key: "index",
      render: (outstandingBalance: string) => {
        return new BigNumber(outstandingBalance || 0).toFormat(2);
      },
    },

    {
      title: t("paidAmount"),
      dataIndex: "outstandingBalance",
      key: "index",
      render: (outstandingBalance: string, data: IInvoice | any) => {
        return new BigNumber(data?.total || 0)
          .minus(outstandingBalance || 0)
          .toFormat(2);
      },
    },
  ];

  const onOpenViewReport = () => {
    history.push(`${PAY_LIST_TAB.reportReceipt}/${receiptID}`);
  };

  return (
    <Card
      className="bg-grey m-1-doctor-room"
      extra={
        <div>
          <div className="fs-20 f-normal t-grey-label">{t("editReceipt")}</div>
          <span className="fs-16 ">{`${t("number")} :`} </span>
          <span className="fs-16 t-blue">
            {receiptIDList?.result[0]?.receiptNumber}
          </span>
        </div>
      }
    >
      <Form name="invoiceForm" form={receiptForm} onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col lg={15}>
            <Row gutter={[14, 14]} align="middle">
              <Col lg={12}>
                <Form.Item label={t("customerName")} name="name">
                  <Input disabled />
                </Form.Item>

                <Form.Item label={t("address")} name="address">
                  <TextArea
                    disabled
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    placeholder=""
                  />
                </Form.Item>

                <Form.Item label={t("zipCode")} name="zipCode">
                  <Input disabled placeholder={t("zipCode")} />
                </Form.Item>
              </Col>

              <Col lg={12}>
                <div className="example">
                  <span className="f-bold ">{`${t("contact")} :`}</span>
                  <span className="break-inside ">
                    {i18n.language === "th"
                      ? receiptIDList?.result?.[0]?.branch?.nameTh
                      : receiptIDList?.result?.[0]?.branch?.nameEn}
                  </span>
                  <span className="break-inside ">
                    {[
                      receiptIDList?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "addressTh" : "addressEn"
                      ],
                      receiptIDList?.result?.[0]?.branch?.[
                        i18n.language === "th"
                          ? "subDistrictTh"
                          : "subDistrictEn"
                      ],
                      receiptIDList?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "districtTh" : "districtEn"
                      ],
                      receiptIDList?.result?.[0]?.branch?.[
                        i18n.language === "th" ? "provinceTh" : "provinceEn"
                      ],
                      receiptIDList?.result?.[0]?.branch?.zipcode,
                      receiptIDList?.result?.[0]?.branch?.country,
                    ]
                      ?.join(" ")
                      ?.trim()}
                  </span>
                </div>

                <div>
                  <span className="f-bold">{`${t("taxId")} :`}</span>{" "}
                  {receiptIDList?.result?.[0]?.branch?.taxId}
                </div>

                <div>
                  <span className="f-bold">{`${t("phone")} :`}</span>{" "}
                  {receiptIDList?.result?.[0]?.branch?.phone}
                </div>
              </Col>
            </Row>
          </Col>
          <Col lg={9}>
            <Row justify="end" align="middle">
              <Col className="col-end" span={24}>
                <PrinterFilled
                  style={{ fontSize: "32px" }}
                  onClick={onOpenViewReport}
                />
              </Col>
              <Col>{t("print")}</Col>
            </Row>
            <div className="fs-16 f-bold">{t("theTotalAmount")}</div>
            <div className="fs-20 f-bold t-blue mt-1 ">
              {new BigNumber(receiptIDList?.result?.[0]?.price || 0).toFormat(
                2
              )}
            </div>
            <Row gutter={[14, 14]} className="mt-1">
              <Col md={24} lg={24}>
                <Form.Item
                  label={t("date")}
                  className="pre-register-form"
                  name="date"
                >
                  <DatePicker disabled format={dateFormat} />
                </Form.Item>
              </Col>

              <Col md={24} lg={24}>
                <Form.Item
                  label={t("salesperson")}
                  name="sale"
                  className="pre-register-form"
                >
                  <Input disabled={true} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Divider />
        <Row gutter={[14, 14]}>
          <Col span={24}>
            <Form.Item
              label={t("referenceNumber")}
              name="referenceNumber"
              className="pre-register-form"
            >
              <Input disabled={true} />
            </Form.Item>
          </Col>
        </Row>
        <div className="mt-1 mb-1 ">
          <Table
            className="components-table-demo-nested table-selection table-confirm  "
            columns={columns}
            rowClassName={(r) => {
              if (r.id) {
                return " no-header no-cursor";
              }
              return "";
            }}
            dataSource={
              receiptIDList?.result[0]?.invoice?.bill?.order?.groups || []
            }
            rowKey="id"
            scroll={{ x: 500 }}
            pagination={false}
          />
        </div>

        <Row gutter={[14, 14]}>
          <Col span={15}>
            <Row>
              <Col span={24} className="mb-1">
                <Form.Item label={t("remarks")} name="description">
                  <TextArea
                    autoSize={{ minRows: 2, maxRows: 3 }}
                    disabled={!medicalRecord}
                  />
                </Form.Item>
              </Col>
              {receiptIDList?.result?.[0]?.status ===
              RECEIPT_STATUS?.COMPLETED ? (
                <Col span={24}>
                  <Form.Item name="imageId">
                    <ImageRender />
                  </Form.Item>
                </Col>
              ) : null}
            </Row>
          </Col>

          <Col span={9}>
            <Row className="mt-1" gutter={[14, 14]}>
              <Col span={12}>{t("totalMoney")}</Col>
              <Col span={12} className="col-end">
                <div>
                  {new BigNumber(
                    receiptIDList?.result?.[0]?.invoice?.totalPrice || 0
                  ).toFormat(2)}
                </div>
              </Col>

              <Col span={12}>{t("includeItems")}</Col>
              <Col span={12} className="col-end">
                <div>{receiptIDList?.result[0]?.sumQty}</div>
              </Col>

              <Col span={12}>{t("discounts")}</Col>
              <Col span={12} className="col-end">
                <div>
                  {receiptIDList?.result?.[0]?.invoice?.discount
                    ? `${new BigNumber(
                        receiptIDList?.result?.[0]?.invoice?.discount || 0
                      ).toFormat(2)}`
                    : receiptIDList?.result?.[0]?.invoice?.discountPercent
                    ? `${receiptIDList?.result?.[0]?.invoice?.discountPercent} %`
                    : `0.00`}
                </div>
              </Col>
              <Col span={12}>{t("theTotalAmount")}</Col>
              <Col span={12} className="col-end">
                <div>
                  {new BigNumber(receiptIDList?.result[0]?.price || 0).toFormat(
                    2
                  )}
                </div>
              </Col>
            </Row>

            <Divider />
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

interface IImageRender {
  value?: IReceiptSlip[];
  onChange?: (e: IReceiptSlip[] | any) => void;
}

const ImageRender: FC<IImageRender> = ({ value, onChange }) => {
  const fileToUrl = (fileList: UploadFile[]) => {
    fileToDataUrl?.(fileList?.[0]?.originFileObj || new File([], ""))?.then(
      (data) => {
        onChange &&
          onChange([
            ...(value || []),
            {
              image: data,
              uid: fileList?.[0]?.uid,
              name: fileList?.[0]?.name,
              fileName: fileList?.[0]?.fileName,
              originFileObj: fileList?.[0]?.originFileObj,
            },
          ]);
      }
    );
  };

  const deleteImage = (id: string | number) => {
    const image = value?.filter((item: IReceiptSlip) => {
      return item?.imageId !== id && item?.uid !== id;
    });
    onChange && onChange([...(image || [])]);
  };
  return (
    <React.Fragment>
      <Row gutter={[14, 14]}>
        {value?.map((item: IReceiptSlip, index: number) => {
          return (
            <Col key={index}>
              <CImage
                style={{ objectFit: "cover" }}
                height={100}
                width={100}
                src={item?.image}
                deleteImage={deleteImage}
                idItem={item?.imageId || item?.uid}
              />
            </Col>
          );
        })}
        <Col span={24}>
          <FileSpace
            maxCount={1}
            accept=".png,.JPG"
            showUploadList={false}
            onChange={(e) => fileToUrl(e.fileList)}
          />
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Fields;
