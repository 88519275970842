import { dayjs } from "@tools/timezone";
import { useTranslation } from "react-i18next";
import { statusReportDrug, typeReportDrug } from "@assets/data/data";
import { Button, Col, DatePicker, Form, FormInstance, Row } from "antd";
import Selector from "@components/selector/Selector";

type Props = {
  setSearchData: (e: any) => void;
};

const Search = ({ setSearchData }: Props) => {
  const { t } = useTranslation();
  const [search] = Form.useForm();
  const { RangePicker } = DatePicker;

  const onFinish = (value: any) => {
    setSearchData({
      startDate: value.start?.[0]
        ? dayjs(value.start?.[0]).format("YYYY-MM-DD")
        : undefined,
      endDate: value.start?.[1]
        ? dayjs(value.start?.[1]).format("YYYY-MM-DD")
        : undefined,
      type: value?.type,
      status: value?.status,
    });
  };

  return (
    <div className="pb-2 ">
      <Form form={search} name="search" onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col md={6} lg={8}>
            <Form.Item shouldUpdate={(prev, next) => prev.type !== next.type}>
              {(form: FormInstance) => {
                const type = form.getFieldValue("type");
                if (type === "year") {
                  return (
                    <Form.Item name="start">
                      <RangePicker picker="year" format="YYYY" />
                    </Form.Item>
                  );
                } else if (type === "month") {
                  return (
                    <Form.Item name="start">
                      <RangePicker picker="month" format="MM/YYYY" />
                    </Form.Item>
                  );
                } else if (type === "day" || !type) {
                  return (
                    <Form.Item name="start">
                      <RangePicker format="DD/MM/YYYY" />
                    </Form.Item>
                  );
                }
              }}
            </Form.Item>
          </Col>

          <Col lg={5}>
            <Form.Item name="type">
              <Selector
                placeholder="เลือกประเภท"
                Source={typeReportDrug}
                keyName="name"
                keyValue="id"
              />
            </Form.Item>
          </Col>

          <Col lg={5}>
            <Form.Item name="status">
              <Selector
                placeholder={t("selectStatus")}
                Source={statusReportDrug}
                keyName="name"
                keyValue="id"
              />
            </Form.Item>
          </Col>
          <Col md={4} lg={4} className="d-flex justify-between">
            <Button className="mr-1 btn-search" htmlType="submit" block>
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset" block>
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
