import React, { useState } from "react";
import { dayjs } from "@tools/timezone";
import { IAppointments } from "@interface/IAppointments.interface";
import { useTranslation } from "react-i18next";
import { ISearchDetailSlots } from "@interface/IAppointments.interface";
import { Card, Col, Row, Tabs } from "antd";
import Record from "./TableRecord";
import PatientQueue from "./TablePatientQueue";
import ImagePatient from "@components/image/ImagePatient";
import SlotsContext from "@provider/SlotsProvider";
import SearchPatientQueue from "./SearchPatientQueue";

type Props = {
  slotID: number;
  getAppointmentsList: (e?: ISearchDetailSlots) => void;
  current: {
    currentPage: number;
    setCurrentPage: any;
  };
  limit: {
    limitPage: number;
    setLimitPage: any;
  };
  total: number;
  appointmentsList?: IAppointments[];
};

const ListDetailScheduler = ({
  slotID,
  getAppointmentsList,
  current,
  limit,
  total,
  appointmentsList,
}: Props) => {
  const { t, i18n } = useTranslation();
  dayjs.locale(i18n.language);
  const { TabPane } = Tabs;
  const slotList = React.useContext(SlotsContext);
  const [search, setSearch] = useState<ISearchDetailSlots>({});

  const onFinish = (e: any) => {
    setSearch(e);
  };

  return (
    <div>
      <Card
        extra={
          <div>
            <Row className="text-row-profile" align="middle">
              <Col md={21}>
                <Row gutter={[14, 14]}>
                  <Col>
                    <ImagePatient src={slotList?.user?.image} />
                  </Col>

                  <Col>
                    <div>
                      <b className="header-card ">
                        {slotList?.user
                          ? i18n.language === "th"
                            ? [
                                slotList?.user?.firstNameTh,
                                slotList?.user?.lastNameTh,
                              ]
                                ?.join(" ")
                                ?.trim()
                            : [
                                slotList?.user?.firstNameEn,
                                slotList?.user?.lastNameEn,
                              ]
                                ?.join(" ")
                                ?.trim()
                          : "   "}
                      </b>
                    </div>
                    <Row>
                      <span className="number-room-d mr-1">
                        {slotList?.room ? slotList?.room?.name : " "}
                      </span>
                      <span className="mt-05 t-dark-blue fs-13">
                        {`${t("dailyQueue")} `}
                        {dayjs(slotList?.date && slotList?.date).format(
                          i18n.language === "th"
                            ? "DD MMMM BBBB"
                            : "DD MMMM YYYY"
                        )}
                      </span>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {/* <Col md={3} lg={3} className="wait-queue col-end">
                <div className="queue-status mt-05">
                  รอคิว <div className="queue-status-number">2</div>
                </div>
              </Col> */}
            </Row>
          </div>
        }
      >
        <Tabs defaultActiveKey="1" className="tab-printdraw ">
          <TabPane tab={t("patientQueue")} key="1" className="mt-2">
            <SearchPatientQueue onFinish={onFinish} />
            <PatientQueue
              getAppointmentsList={getAppointmentsList}
              current={{
                currentPage: current.currentPage,
                setCurrentPage: current.setCurrentPage,
              }}
              limit={{
                limitPage: limit.limitPage,
                setLimitPage: limit.setLimitPage,
              }}
              total={total}
              appointmentsList={appointmentsList}
              search={search}
            />
          </TabPane>
          <TabPane tab={t("record")} key="2" className="mt-2">
            <Record slotID={slotID} />
          </TabPane>
        </Tabs>
      </Card>
    </div>
  );
};

export default ListDetailScheduler;
