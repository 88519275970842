import { Form } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import Header from "./Header";
import FormBasic from "./form/Form";

const PatientBasicInfo = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const pathID = match.params.id;
  const [basicInfoForm] = Form.useForm();

  return (
    <div>
      <Header pathID={pathID} basicInfoForm={basicInfoForm} history={history} />

      <FormBasic
        pathID={pathID}
        basicInfoForm={basicInfoForm}
        history={history}
      />
    </div>
  );
};

export default PatientBasicInfo;
