import { dayjs } from "@tools/timezone";
import { IParamet } from "@interface/IGlobal.interface";
import { useContext } from "react";
import { IGetLabInfo } from "@interface/IWorkOrder.interface";
import { INTERFACE_LAB } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { useCreateRequest } from "@services/api/lab";
import { openNotification } from "@components/notification/Notification";
import { useHistory, useRouteMatch } from "react-router-dom";
import { Card, Col, Form, FormInstance, Row } from "antd";
import { ENUM_G6PD, ENUM_ROOM_SIGNATURE, ORDER_TYPE } from "@tools/util.enum";
import Advice from "@components/advice/Advice";
import moment from "moment";
import SymbolView from "@components/symbol/Symbol";
import DatailInput from "./DatailInput";
import TableResult from "./Table";
import RoleContext from "@provider/RoleProvider";
import ImagePatient from "@components/image/ImagePatient";
import LabInterface from "@views/doctor_room/tabs/counsel/create_order/lab_interface/LabInterface";
import ImportantInfo from "@components/historical_info/ImportantInfo";
import VisitingPatients from "./VisitingPatients";
import LabInterfaceGroup from "@views/doctor_room/tabs/counsel/create_order/lab_interface_group/LabInterfaceGroup";
import TransferComponent from "@components/transfer/Transfer";

type Props = {
  labInfoList?: IParamet<IGetLabInfo[]>;
  labInfo: FormInstance;
  patientList: FormInstance;
};

const Fields = ({ labInfoList, labInfo, patientList }: Props) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const request = useCreateRequest();
  const match: any = useRouteMatch();
  const visitID = match.params.visitID;
  const roleList = useContext(RoleContext);
  const interfaceLabRecord = roleList?.filter((item) => {
    return item?.nameEn === "INTERFACE_LAB";
  });

  const onFinish = (value: object) => {
    const labInfo = labInfoList?.result?.[0]?.info?.map((item) => {
      return {
        obsvervationDateTime: moment().format("YYYYMMDDHHmmss"),
        requestDateTime: moment().format("YYYYMMDDHHmmss"),
        specimenCode: moment().format("YYYYMMDDHHmmss"),
        specimenReceiveDateTime: moment().format("YYYYMMDDHHmmss"),
        testCode: item?.testCode,
        testName: item?.testName,
      };
    });

    const dataList = {
      ...value,
      labNumber: labInfoList?.result?.[0]?.labNumber,
      labInfo: labInfo,
      use: labInfoList?.result?.[0]?.use,
    };

    request.mutate(
      { id: visitID, data: dataList },
      {
        onSuccess: () => {
          openNotification({ method: "PUT_SUCCESS" });
          history.replace(`${INTERFACE_LAB.printLabel}${visitID}`);
        },
        onError: (res) => {
          openNotification({ method: "PUT_ERROR", message: String(res) });
        },
      }
    );
  };
  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room "
        extra={
          <>
            <Row gutter={[14, 14]} className="text-row-profile ml-05">
              <Col>
                <ImagePatient src={labInfoList?.result?.[0]?.patient?.image} />
              </Col>
              <Col>
                <Row>
                  <Col xs={24} lg={24}>
                    {i18n.language === "th"
                      ? ` ${
                          labInfoList?.result?.[0]?.patient?.firstNameTh || " "
                        }    ${
                          labInfoList?.result?.[0]?.patient?.lastNameTh || " "
                        }`
                      : ` ${
                          labInfoList?.result?.[0]?.patient?.firstNameEn || " "
                        }    ${
                          labInfoList?.result?.[0]?.patient?.lastNameEn || " "
                        }`}
                  </Col>

                  <Col xs={24} lg={24}>
                    <div className="code-profile">
                      HN : {labInfoList?.result?.[0]?.patient?.hn}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginLeft: "auto" }}>
                <div style={{ color: "#FEB139" }}>
                  <SymbolView />
                </div>
                <div
                  style={{
                    color: "red",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {labInfoList?.result?.[0]?.patient?.g6pd ===
                  ENUM_G6PD.DEFICIENCY
                    ? "G6PD Deficiency"
                    : null}
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <div className="mb-05">
          <span className=" t-blue fs-18 ">{t("fullList")} </span>
          <span className=" t-grey-light  fs-12 ">
            {`${t("orderCreationDate")}:`}{" "}
          </span>
          <span className="t-grey fs-12 ">
            {" "}
            {labInfoList ? `${dayjs().format("DD-MM-YYYY")}` : ``}
          </span>
        </div>
        <Form name="patientList" form={patientList}>
          <ImportantInfo />
        </Form>
        <Advice visitsByID={labInfoList?.result?.[0]?.visit} />

        <div className="mt-2 ">
          <LabInterface
            title="LabInterface Group One"
            room={ENUM_ROOM_SIGNATURE.INTERFACE_LAB_ROOM}
          />
        </div>

        <TransferComponent
          patientID={1}
          draftOrderList={undefined}
          title="LabInterface Other One"
          editRecord={interfaceLabRecord?.[0]?.isEdit}
          type={ORDER_TYPE.LAB_INTERFACE_OTHER_ONE}
          eventId={0}
          room={ENUM_ROOM_SIGNATURE.INTERFACE_LAB_ROOM}
        />

        <div className="mt-2 ">
          <LabInterfaceGroup
            title="LabInterface Group Two"
            room={ENUM_ROOM_SIGNATURE.INTERFACE_LAB_ROOM}
          />
        </div>

        <TransferComponent
          patientID={0}
          draftOrderList={undefined}
          title="LabInterface Other Two"
          editRecord={interfaceLabRecord?.[0]?.isEdit}
          type={ORDER_TYPE.LAB_INTERFACE_OTHER_TWO}
          eventId={0}
          room={ENUM_ROOM_SIGNATURE.INTERFACE_LAB_ROOM}
        />

        <Form name="labInfo" form={labInfo} onFinish={onFinish}>
          <div className="mt-2 ">
            <div className="f-normal fw-900 fs-15">Visiting Patients</div>
            <Card className="mt-1">
              <VisitingPatients />
            </Card>
          </div>

          <div className="mt-2 ">
            <div className="f-normal fw-900 fs-15">Request</div>
            <Card className="mt-1">
              <DatailInput />
            </Card>
          </div>
        </Form>

        <div className="mt-2 ">
          <div className="f-normal fw-900 fs-15">Info</div>
          <Card className="mt-1">
            <TableResult labInfoList={labInfoList} />
          </Card>
        </div>
      </Card>
    </>
  );
};

export default Fields;
