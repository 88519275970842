import { Elementary } from "@interface/IGlobal.interface";
import { thrownResponse } from "./auth";
import { useQuery, UseQueryResult } from "react-query";
import axios from "@axios/index";

export const useGetWarehouseSaleReturn = (
  params?: any
): UseQueryResult<Elementary<any[]>> => {
  return useQuery(["get-warehouse-sale-return", params], async () => {
    const res = await axios.get(`/warehouse/sale-return`, {
      params: params,
    });

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};

export const useGetWarehouseSaleReturnById = (
  id?: number
): UseQueryResult<Elementary<any[]>> => {
  return useQuery(["get-warehouse-sale-return", id], async () => {
    const res = await axios.get(`/warehouse/sale-return/${id}`);

    if (res?.status >= 200 && res?.status < 300) {
      return res?.data;
    } else if (res?.status >= 400 && res?.status < 500) {
      return res?.data;
    }
    thrownResponse(res);
  });
};
