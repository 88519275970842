import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import { useGetAppointmentByID } from "@services/api/appointments";
import { INum, ISelectedRowKeys } from "@interface/IGlobal.interface";
import { useHistory, useRouteMatch } from "react-router";
import Header from "./Header";
import FormOrderList from "./form/Form";
import MainPhysicianAssistantProvider from "@provider/physician_assistant";

const Order = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const appointmentsID = match.params.appointmentsID;
  const visitID = match.params.visitID;
  const patientID = match.params.patientID;
  const appointmentByID = useGetAppointmentByID(
    !appointmentsID && appointmentsID !== 0 ? false : true,
    appointmentsID
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<ISelectedRowKeys>({
    oneDay: [],
    continuous: [],
    other1: [],
    other2: [],
    labInterfaceGroup: [],
    optionDrug: [],
  });
  const [totalAndQuantity, setTotalAndQuantity] = useState<INum>({
    discountTotal: 0.0,
    discount: 0.0,
    selector: 2,
    percent: 0.0,
    oneDay: {
      total: 0.0,
      quantity: 0,
      normal: 0.0,
    },
    continuous: {
      total: 0.0,
      quantity: 0,
      normal: 0.0,
    },
    total: 0,
    labInterface: {
      total: 0.0,
      quantity: 0,
      normal: 0.0,
    },
  });

  const TAQSessionStorage = !!sessionStorage.getItem("totalAndQuantity")
    ? sessionStorage.getItem("totalAndQuantity") || "{}"
    : "{}";

  useEffect(() => {
    const totalAndQuantity: INum = JSON.parse(TAQSessionStorage);

    if (TAQSessionStorage !== "{}") {
      setTotalAndQuantity(totalAndQuantity);
    }
  }, [TAQSessionStorage]);

  return (
    <MainPhysicianAssistantProvider
      value={{
        appointmentById: appointmentByID?.data?.result?.[0],
        appointmentsId: appointmentsID,
        visitId: visitID,
      }}
    >
      <div>
        <Header
          history={history}
          patientID={patientID}
          selectedRowKeys={selectedRowKeys}
          totalAndQuantity={totalAndQuantity}
          eventId={appointmentByID?.data?.result?.[0]?.visit?.eventId || 0}
        />
        <FormOrderList
          patientID={patientID}
          selected={{
            selectedRowKeys: selectedRowKeys,
            setSelectedRowKeys: setSelectedRowKeys,
          }}
          totalAndQuantity={{
            totalAndQuantity: totalAndQuantity,
            setTotalAndQuantity: setTotalAndQuantity,
          }}
        />
      </div>
    </MainPhysicianAssistantProvider>
  );
};

export default Order;
