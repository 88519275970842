import { Age } from "@tools/age";
import { gender } from "@tools/util";
import { Elementary } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { IGetPatientList } from "@interface/IOpd.interface";
import { signCourseDetail } from "@assets/data/pathRouter";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

interface CurrentPage {
  currentPage: number;
  setCurrentPage: (page: number) => void;
}

interface LimitPage {
  limitPage: number;
  setLimitPage: (page: number) => void;
}

type Props = {
  currentPage: CurrentPage;
  limitPage: LimitPage;
  visitList?: Elementary<IGetPatientList[]>;
  total: number;
};

const Table = ({ currentPage, limitPage, visitList, total }: Props) => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") limitPage.setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${signCourseDetail}/patient/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage.limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: `${t("code")} HN`,
      dataIndex: "hn",
      key: "hn",
      width: "20%",
    },

    {
      title: `${t("name")} - ${t("lastName")} `,
      dataIndex: "firstNameTH",
      key: "firstNameTH",
      sorter: (a: any, b: any) => a.id - b.id,
      render: (_: any, data: any) => {
        const name =
          i18n.language === "th"
            ? [data?.firstNameTh, data?.lastNameTh]?.join(" ")?.trim()
            : [data?.firstNameEn, data?.lastNameEn]?.join(" ")?.trim();
        return (
          <div className="f-normal " key={data?.id}>
            {name}
          </div>
        );
      },
    },

    {
      title: t("sex"),
      dataIndex: "gender",
      key: "gender",
      render: (data: any) => {
        let nameGender = gender(data, i18n?.language);

        return <div>{nameGender}</div>;
      },
    },

    {
      title: t("age"),
      dataIndex: "dateOfBirth",
      key: "dateOfBirth",
      width: "20%",
      sorter: (a: any, b: any) => a.age - b.age,
      render: (dateOfBirth: string) => {
        return <>{dateOfBirth ? Age(dateOfBirth) : "-"}</>;
      },
    },

    {
      title: t("phoneNumber"),
      dataIndex: "mobile",
      sorter: (a: any, b: any) => a.phone - b.phone,
      key: "mobile",
      render: (mobile: string) => {
        return <>{mobile ? mobile : "-"}</>;
      },
    },
  ];
  return (
    <>
      <MoTable
        dataSource={visitList?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage.limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
