import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import BackHome from "@components/button/BackHome";

type Props = {
  history: RouteComponentProps["history"];
  confirmPayment: () => void;
};

const Header = ({ history, confirmPayment }: Props) => {
  const { t } = useTranslation();

  const confirm = () => {
    confirmPayment();
  };

  return (
    <>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("pay.title")}</h1>
              </div>
              <span className="t-light-gray">{t("nursery")} / </span>
              <span className="t-dark-blue f-normal">{t("pay.title")}</span>
            </Col>
          </Row>
        </Col>

        <Col lg={10} className="d-flex justify-between justify-col">
          <Button
            className="cancel mr-05 "
            htmlType="submit"
            onClick={history.goBack}
          >
            {t("cancel")}
          </Button>

          <Button className="btn-create" htmlType="submit" onClick={confirm}>
          {t("confirm")}
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Header;
