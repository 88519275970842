import { IItem } from "@interface/IOrder.interface";
import { SETTING } from "@assets/data/pathRouter";
import { remarkSender } from "@assets/data/data";
import { FormInstance } from "antd/es/form";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "@ant-design/icons";
import { openNotification } from "@components/notification/Notification";
import { RouteComponentProps } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  useCreateWarehousePurchaseOrder,
  useGetWarehouseBranchList,
  useUpdateWarehousePurchaseOrder,
} from "@services/api/warehouse_purchase_order";
import { useGetWarehousePurchaseOrderById } from "@services/api/warehouse_purchase_order";
import { useGetWarehousePurchaseOrderPoNumber } from "@services/api/warehouse_purchase_order";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import moment from "moment";
import Selector from "@components/selector/Selector";
import Editable from "@components/table/Editable";
import TextArea from "antd/lib/input/TextArea";
import ModalPurchaseOrder from "../modal/Modal";
import React from "react";
import UserContext from "@provider/UserProvider";
import { ENUM_ROLE_EN } from "@tools/util.enum";
import { useBranchSelector } from "@provider/select_branch";
import BigNumber from "bignumber.js";

type Props = {
  managePurchaseOrder: FormInstance;
};

const FieldsPurchaseOrder: FC<Props> = ({ managePurchaseOrder }) => {
  const { t, i18n } = useTranslation();
  const history = useHistory<RouteComponentProps["history"]>();
  const match: any = useRouteMatch();
  const purchaseOrderId = match.params.id;
  const [visible, setVisible] = useState(false);
  const [select, setSelect] = useState<IItem[]>([]);
  const purchaseOrderById = useGetWarehousePurchaseOrderById(purchaseOrderId);
  const poNumber = useGetWarehousePurchaseOrderPoNumber(purchaseOrderId);
  const info: any = purchaseOrderById?.data?.result?.[0];
  const createPurchaseOrder = useCreateWarehousePurchaseOrder();
  const updatePurchaseOrder = useUpdateWarehousePurchaseOrder();
  const branch = useGetWarehouseBranchList();
  const userList = React.useContext(UserContext);
  const { branchId } = useBranchSelector();

  useEffect(() => {
    if (purchaseOrderById?.data) {
      managePurchaseOrder.setFieldsValue({
        ...info,
        branchCode: info.branch.code,
        date: moment(info?.createdAt),
        remarkSender: remarkSender,
        poNumber: info.poNumber,
      });
      setSelect(
        info?.purchaseOrdersProducts?.map((item: any) => {
          return {
            ...item,
            ...item?.product,
          };
        })
      );
    }

    // eslint-disable-next-line
  }, [purchaseOrderById?.data, branch?.data]);

  useEffect(() => {
    if (poNumber?.data && !purchaseOrderById?.data) {
      managePurchaseOrder.setFieldsValue({
        remarkSender: remarkSender,
        date: moment(),
        poNumber: poNumber?.data?.result?.[0],
        branchCode:
          (userList.role !== ENUM_ROLE_EN.SUPER_ADMIN || branchId) &&
          !info?.branch?.code
            ? branch?.data?.result?.[0]?.data?.[0]?.code
            : undefined,
      });
    }
    // eslint-disable-next-line
  }, [poNumber?.data, branch?.data]);

  const onOk = (value: IItem[]) => {
    // calculatePrice(value)
    setSelect(value);
    setVisible(false);
  };

  const createPurchase = (value: any) => {
    if (!!select?.filter((item: IItem) => !item?.qty)?.length) {
      openNotification({
        method: "POST_ERROR",
        message: !!select?.length
          ? "มีสินค้าบางชิ้นไม่มีจำนวนที่ต้องการ"
          : "ไม่มีสินค้าในใบสั่งซื้อ",
      });
    } else {
      createPurchaseOrder.mutate(
        {
          remark: value?.remark,
          branchCode: value?.branchCode,
          products: select?.map((item) => {
            return {
              qty: item?.qty,
              productId: item?.id,
            };
          }),
        },
        {
          onSuccess: () => {
            openNotification({ method: "POST_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "POST_ERROR", message: String(err) });
          },
          onSettled: () => {
            history.replace(SETTING.wareHouse);
          },
        }
      );
    }
  };

  const updatePurchase = (value: any) => {
    if (!!select?.filter((item: IItem) => !item?.qty)?.length) {
      openNotification({
        method: "PUT_ERROR",
        message: !!select?.length
          ? "มีสินค้าบางชิ้นไม่มีจำนวนที่ต้องการ"
          : "ไม่มีสินค้าในใบสั่งซื้อ",
      });
    } else {
      updatePurchaseOrder.mutate(
        {
          id: purchaseOrderId,
          value: {
            remark: value.remark,
            branchCode: value.branchCode,
            products: select?.map((item) => {
              return {
                qty: item?.qty,
                productId: item?.id,
              };
            }),
          },
        },
        {
          onSuccess: () => {
            openNotification({ method: "PUT_SUCCESS" });
          },
          onError: (err) => {
            openNotification({ method: "PUT_ERROR", message: String(err) });
          },
          onSettled: () => {
            history.replace(SETTING.wareHouse);
          },
        }
      );
    }
  };

  const onFinish = (value: any) => {
    if (!!purchaseOrderId) {
      updatePurchase(value);
    } else {
      createPurchase(value);
    }
  };

  const Columns = [
    {
      title: "#",
      dataIndex: "key",
      render: (_: string, __: string, i: number) => {
        return i + 1;
      },
    },
    {
      title: "SKU",
      dataIndex: "group",
      render: (group: any) => {
        return group?.sku;
      },
    },
    {
      title: "Sub SKU",
      dataIndex: "sku",
      render: (sku: any) => {
        return sku;
      },
    },
    {
      title: t("productName"),
      dataIndex: "product",
      render: (_: any, row: any) => {
        return (
          <div>
            <div>
              {i18n.language === "th"
                ? row?.nameTh || row?.nameEn
                : row?.nameEn || row?.nameTh}
            </div>
            <div style={{ color: "#757783", fontSize: 12 }}>{row?.sku}</div>
          </div>
        );
      },
    },

    {
      title: t("desiredQuantity"),
      width: 250,
      dataIndex: "qty",
      editable: (info?.status === "PENDING_CONFIRM" || !info?.status) && true,
      inputType: "InputNumber",
      render: (data: any, row: any) => {
        return data > row?.sum ? (
          <span style={{ color: "red" }}>{data}</span>
        ) : (
          data
        );
      },
    },

    {
      title: t("unit"),
      dataIndex: "product",
      render: (_: any, row: any) => {
        return row?.unit || "ชิ้น";
      },
    },
    {
      title: "ราคารวม (฿)",
      dataIndex: "product",
      render: (_: any, row: any) => {
        const sum = (row?.qty || 0) * Number(row?.retailPrice);
        return BigNumber(sum)?.toFormat(2);
      },
    },
    {
      title: t("manage"),
      dataIndex: "product",
      align: "center",
      render: (_: any, row: any) => {
        return (
          <DeleteOutlined
            onClick={() => {
              if (!purchaseOrderById?.data) {
                const removeList = select?.filter((r: any) => {
                  return r?.sku !== row?.sku;
                });
                setSelect(removeList);
              }
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <Form onFinish={onFinish} form={managePurchaseOrder}>
        <Row gutter={[14, 14]}>
          <Col span={12}>
            <Form.Item
              name="poNumber"
              label={t("poNumber")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="date"
              label={t("orderDate")}
              rules={[{ required: true }]}
            >
              <DatePicker format="DD-MM-YYYY" disabled={true} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="remarkSender"
              label={t("remarkSender")}
              rules={[{ required: true }]}
            >
              <Input disabled />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="branchCode"
              label={t("orderDetails")}
              rules={[{ required: true }]}
            >
              <Selector
                Source={branch?.data?.result?.[0]?.data || []}
                keyName="addressFull"
                keyValue="code"
                disabled={
                  userList.role !== ENUM_ROLE_EN.SUPER_ADMIN ||
                  info?.branch?.code ||
                  branchId
                }
              />
            </Form.Item>
          </Col>
          <Col span={24} className="header-card">
            {t("productDetails")}
          </Col>

          <Col span={24}>
            <Editable
              rowKey="id"
              scroll={{ x: 1100 }}
              defaultColumns={Columns}
              dataSource={select}
              onSave={(value: any) => {
                console.log("value", value);
                setSelect(value);
              }}
            />
          </Col>

          <Col span={12}>
            <Row>
              {(info?.status === "PENDING_CONFIRM" || !info?.status) && (
                <Col span={24}>
                  <Button
                    className="btn-print"
                    onClick={() => setVisible(true)}
                  >
                    + เพิ่มสินค้า
                  </Button>
                </Col>
              )}
              <Col span={24}>
                <Form.Item name="remark" label={t("remarks")}>
                  <TextArea autoSize={{ minRows: 3, maxRows: 4 }} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      {!!visible && (
        <ModalPurchaseOrder
          visible={{ visible: visible, setVisible: setVisible }}
          onOk={onOk}
          selectedRowKeys={select}
        />
      )}
    </div>
  );
};

export default FieldsPurchaseOrder;
