import { t } from "i18next";
import { IItem } from "@interface/IOrder.interface";
import { dayjs } from "@tools/timezone";
import { _amountToWordsEN, productName } from "@tools/util";
import { heightTopPrint } from "@tools/heightTopPrint";
import { IGetDraftOrder } from "@interface/IDraftOrder.interface";
import { useTranslation } from "react-i18next";
import { EVENT_PATIENT_STATUS } from "@tools/util.enum";
import { ElementaryOneMore, IVisitBill } from "@interface/IGlobal.interface";
import { FC, MutableRefObject, useContext } from "react";
import { Button, Col, Divider, Row, Table } from "antd";
import { IGetHistoryPatient, IGetPatientList } from "@interface/IOpd.interface";
import Decimal from "@tools/decimal";
import ImageLogo from "@components/image/ImageLogo";
import UserContext from "@provider/UserProvider";

type Props = {
  refData: MutableRefObject<null>;
  patientByID?: IGetPatientList;
  divHeight: any;
  visible: IVisitBill;
  setVisible: (e: IVisitBill) => void;
  draftOrderList?: ElementaryOneMore<IGetDraftOrder> | any;
  keyValue: any;
  total?: number;
  status?: string;
  draftOrderListData?: IGetHistoryPatient;
};

const PrintReceipt: FC<Props> = ({
  refData,
  patientByID,
  divHeight,
  visible,
  setVisible,
  draftOrderList,
  keyValue,
  total = 0,
  status = "",
  draftOrderListData,
}) => {
  const userList = useContext(UserContext);
  const { i18n } = useTranslation();
  const THBText =
    i18n.language === "th" ? require("thai-baht-text") : _amountToWordsEN;
  const nameTH = [patientByID?.firstNameTh, patientByID?.lastNameTh]
    ?.join(" ")
    ?.trim();

  const nameEN = [patientByID?.firstNameEn, patientByID?.lastNameEn]
    ?.join(" ")
    ?.trim();

  const mailingAddress = patientByID?.mailingAddress;
  const mailAddress =
    mailingAddress?.addressTh || mailingAddress?.districtTh
      ? [
          mailingAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
          mailingAddress[
            i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
          ],
          mailingAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
          mailingAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
          mailingAddress?.country,
          mailingAddress?.zipcode,
        ]
          ?.join(" ")
          ?.trim()
      : "";
  const _homeAddress = patientByID?.homeAddress;
  const homeAddress =
    _homeAddress?.addressTh || _homeAddress?.districtTh
      ? [
          _homeAddress[i18n.language === "th" ? "addressTh" : "addressEn"],
          _homeAddress[
            i18n.language === "th" ? "subDistrictTh" : "subDistrictEn"
          ],
          _homeAddress[i18n.language === "th" ? "districtTh" : "districtEn"],
          _homeAddress[i18n.language === "th" ? "provinceTh" : "provinceEn"],
          _homeAddress?.country,
          _homeAddress?.zipcode,
        ]
          ?.join(" ")
          ?.trim()
      : "";

  const userTh = [userList?.firstNameTh, userList?.lastNameTh]
    ?.join(" ")
    ?.trim();
  const userEn = [userList?.firstNameEn, userList?.lastNameEn]
    ?.join(" ")
    ?.trim();

  const columns: any = [
    {
      title: "#",
      dataIndex: "id",
      key: "index",
      render: (_: any, data: IItem, index: number) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? index + 1
              : data?.level === 2
              ? ""
              : data?.level === 3
              ? ""
              : data?.level === 4
              ? ""
              : data?.level === 5
              ? ""
              : index + 1}
          </div>
        );
      },
    },
    {
      title: `${t("productName")} / ${t("details")}`,
      dataIndex: "nameEn",
      key: "index",
      render: (_: any, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? data?.name
              : data?.level === 2
              ? t("productName")
              : data?.level === 3
              ? productName(
                  i18n?.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )
              : data?.level === 4
              ? t("productName")
              : data?.level === 5
              ? productName(
                  i18n?.language,
                  data?.product?.nameTh,
                  data?.product?.nameEn
                )
              : data?.name}
          </div>
        );
      },
    },
    {
      title: t("list"),
      dataIndex: "qty",
      render: (qty: any, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? data?.children?.[0]?.children?.length
              : data?.level === 2
              ? t("amount")
              : data?.level === 3
              ? data?.qty
              : data?.level === 4
              ? t("amount")
              : data?.level === 5
              ? data?.qty
              : qty}
          </div>
        );
      },
    },
    {
      title: t("unit"),
      dataIndex: "title",
    },
    {
      title: t("unitPrice"),
      dataIndex: "unitPrice",
      render: (_: any, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? ""
              : data?.level === 2
              ? t("unitPrice")
              : data?.level === 3
              ? Decimal(data?.normal || 0)
              : data?.level === 4
              ? ""
              : data?.level === 5
              ? ""
              : ""}
          </div>
        );
      },
    },

    {
      title: t("totalPrice"),
      dataIndex: "total",
      render: (total: any, data: IItem) => {
        return (
          <div key={data?.key}>
            {data?.level === 1
              ? Decimal(data?.total)
              : data?.level === 2
              ? t("totalPrice")
              : data?.level === 3
              ? Decimal(data?.net)
              : data?.level === 4
              ? ""
              : data?.level === 5
              ? ""
              : Decimal(total)}
          </div>
        );
      },
    },
  ];

  const branch =
    draftOrderList?.result?.[0]?.branch || draftOrderListData?.branch;
  return (
    <div ref={refData}>
      <div id="container">
        <table className="report-drug f-light  " width="100%">
          <tbody>
            <tr>
              <td
                style={{
                  width: "50vw",
                  fontWeight: "lighter",
                  textAlign: "start",
                }}
              >
                <div className="mb-1">
                  <ImageLogo
                    src={branch?.image}
                    alt="Logo"
                    width="200px"
                    height="70px"
                  />
                </div>
                <div className="example">
                  <span className="break-inside ">
                    {
                      branch?.nameEn
                      // t("location.title")
                    }
                  </span>
                  <span className="break-inside ">
                    {
                      branch?.nameTh
                      // t("location.title")
                    }
                  </span>
                  <span className="break-inside ">
                    {i18n.language === "th"
                      ? branch?.addressTh
                      : branch?.addressEn}
                  </span>
                  <span className="break-inside ">
                    {[
                      branch?.[
                        i18n.language === "th"
                          ? "subDistrictTh"
                          : "subDistrictEn"
                      ],
                      branch?.[
                        i18n.language === "th" ? "districtTh" : "districtEn"
                      ],
                      branch?.[
                        i18n.language === "th" ? "provinceTh" : "provinceEn"
                      ],
                      branch?.zipcode,
                      branch?.country,
                    ]
                      ?.join(" ")
                      ?.trim()}
                  </span>
                </div>
                <div>
                  <span>{t("tel")} :</span> {branch?.phone}
                </div>
                <div>
                  <span>{t("taxId")} :</span> {branch?.taxId}
                </div>
                <div className="pt-1">
                  <span className="f-bold t-blue">{t("customer")} </span>
                </div>
                <div>
                  <span className="break-inside">
                    {t("customerName")} :{" "}
                    {i18n.language === "th" ? nameTH : nameEN}
                  </span>{" "}
                </div>
                <div>
                  <span className="break-inside ">
                    {t("address")} : {mailAddress || homeAddress || "-"}
                  </span>
                </div>

                <div>
                  <span className="break-inside ">
                    {t("zipCode")} :{" "}
                    {!!mailAddress
                      ? patientByID?.mailingAddress?.zipcode || "-"
                      : patientByID?.homeAddress?.zipcode || "-"}
                  </span>
                </div>
              </td>

              <td
                style={{
                  width: "50vw",
                  fontWeight: "lighter",
                  textAlign: "start",
                }}
              >
                <div className="t-center fs-20 t-blue f-bold">
                  {t("receipt1")}
                </div>
                <div style={{ paddingTop: "15px", paddingBottom: "15px" }}>
                  <Divider className="m-0 b-grey-white" />
                </div>

                <Row>
                  <Col span="6">{t("number")}</Col>

                  <Col span="18">
                    {/* {receiptIDList?.result?.[0]?.receiptNumber} */}
                  </Col>

                  <Col span="6"> {t("date")}</Col>

                  <Col span="18">{dayjs().format("DD-MM-YYYY")}</Col>

                  <Col
                    span="24"
                    style={{ paddingTop: "15px", paddingBottom: "15px" }}
                  >
                    <Divider className="m-0 b-grey-white" />
                  </Col>

                  <Col span="6">{t("contact")}</Col>
                  <Col span="18">
                    {i18n.language === "th" ? userTh : userEn}
                  </Col>
                </Row>
              </td>
            </tr>
            <tr>
              <td colSpan={2} style={{ paddingTop: "19px" }}>
                <Table
                  rowKey="key"
                  className="components-table-demo-nested table-selection table-confirm  table-list-children report-pay"
                  rowClassName={(r) => {
                    if (r?.level === 1) {
                      return " no-header ";
                    } else if (r?.level === 2) {
                      return "table-header no-header ";
                    } else if (r?.level === 3) {
                      return " no-header ";
                    } else if (r?.level === 5) {
                      return "table-confirm-first no-header  ";
                    } else if (r?.level === 4) {
                      return "table-header no-header  ";
                    }
                    return "";
                  }}
                  expandedRowKeys={keyValue ? keyValue?.flat(Infinity) : []}
                  columns={columns}
                  dataSource={
                    status === EVENT_PATIENT_STATUS?.IN_PROGRESS
                      ? draftOrderList || []
                      : draftOrderList?.result?.[0]?.outType.groups || []
                  }
                  pagination={false}
                />
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <table className="report-drug " width="100%">
                  <tbody>
                    <tr className="t-end">
                      <td>
                        <Row style={{ paddingTop: "30px" }}>
                          <Col span={12}>{t("totalNumber")}</Col>
                          <Col span={12}>
                            {Decimal(
                              Number(
                                status === EVENT_PATIENT_STATUS?.IN_PROGRESS
                                  ? total || 0
                                  : draftOrderList?.result?.[0]?.outType
                                      ?.groups?.[0]?.total || 0
                              )
                            )}
                          </Col>

                          <Col span={24}>
                            <Row
                              style={{
                                backgroundColor: "#498dcb",
                                color: "white",
                              }}
                            >
                              <Col span={12}>{t("theTotalAmount")}</Col>
                              <Col span={12}>
                                {Decimal(
                                  Number(
                                    status === EVENT_PATIENT_STATUS?.IN_PROGRESS
                                      ? total || 0
                                      : draftOrderList?.result?.[0]?.outType
                                          ?.groups?.[0]?.total || 0
                                  )
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <span style={{ backgroundColor: "#498dcb", color: "white" }}>
                  {`(${THBText(
                    Number(
                      status === EVENT_PATIENT_STATUS?.IN_PROGRESS
                        ? total || 0
                        : draftOrderList?.result?.[0]?.outType?.groups?.[0]
                            ?.total || 0
                    )
                  )})
              `}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div
        style={{
          marginTop: `${heightTopPrint(divHeight)}px`,
        }}
        className="footer-bill"
      >
        <Row
          style={{ paddingTop: "80px" }}
          align="middle"
          className="break-inside"
        >
          <Col span={6} className="t-center pl-20 pt-10">
            <div className=" f-normal t-center  image-signature">
              {draftOrderList?.result?.[0]?.firstSignature ? (
                <img
                  src={draftOrderList?.result?.[0]?.firstSignature || ""}
                  alt="first"
                  height={70}
                />
              ) : draftOrderListData?.data?.firstSignature?.presigned ? (
                <img
                  src={
                    draftOrderListData?.data?.firstSignature?.presigned || ""
                  }
                  alt="first"
                  height={70}
                />
              ) : null}
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div className=" f-normal t-center  date-signature">
              {dayjs(
                draftOrderList?.result?.[0]?.createdAt ||
                  draftOrderListData?.createdAt
              ).format("DD-MM-YYYY")}
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div className=" f-normal t-center  image-signature">
              {draftOrderList?.result?.[0]?.firstSignature ? (
                <img
                  src={draftOrderList?.result?.[0]?.secondSignature || ""}
                  alt="second"
                  height={70}
                />
              ) : draftOrderListData?.data?.firstSignature?.presigned ? (
                <img
                  src={
                    draftOrderListData?.data?.firstSignature?.presigned || ""
                  }
                  alt="first"
                  height={70}
                />
              ) : null}
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div className=" f-normal t-center  date-signature">
              {dayjs(
                draftOrderList?.result?.[0]?.createdAt ||
                  draftOrderListData?.createdAt
              ).format("DD-MM-YYYY")}
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 ">
            <div className="pb-30">
              <Divider className="m-0 b-grey-white" />
            </div>

            <Button
              className="pb-1 signature-button"
              onClick={(e) =>
                setVisible({
                  ...visible,
                  first: !visible.first,
                })
              }
            >
              {t("signHere")}
            </Button>
          </Col>
          <Col span={6} className="t-center pl-20">
            <div style={{ paddingBottom: "30px" }}>
              <Divider className="m-0 b-grey-white" />
            </div>
          </Col>
          <Col span={6} className="t-center pl-20">
            <div className="pb-30">
              <Divider className="m-0 b-grey-white" />
            </div>
            <Button
              className="pb-1 signature-button"
              onClick={(e) =>
                setVisible({
                  ...visible,
                  second: !visible.second,
                })
              }
            >
              {t("signHere")}
            </Button>
          </Col>
          <Col span={6} className="t-center pl-20">
            <div style={{ paddingBottom: "30px" }}>
              <Divider className="m-0 b-grey-white" />
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div
              style={{
                fontSize:
                  Number(
                    i18n.language === "th"
                      ? nameTH?.length || 0
                      : nameEN?.length || 0
                  ) < 22
                    ? 14
                    : 250 /
                      Number(
                        i18n.language === "th"
                          ? nameTH?.length || 0
                          : nameEN?.length || 0
                      ),
              }}
            >
              ({i18n.language === "th" ? nameTH : nameEN})
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            {t("date")}
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            <div
              style={{
                fontSize:
                  Number(
                    i18n.language === "th"
                      ? userTh?.length || 0
                      : userEn?.length || 0
                  ) < 22
                    ? 14
                    : 250 /
                      Number(
                        i18n.language === "th"
                          ? userTh?.length || 0
                          : userEn?.length || 0
                      ),
              }}
            >
              ({i18n.language === "th" ? userTh : userEn})
            </div>
          </Col>
          <Col span={6} className="t-center pl-20 pt-10">
            {t("date")}
          </Col>
          <Col span={6} className="t-center pl-20 ">
            <div> {i18n?.language === "th" ? "ผู้รับบริการ" : ""}</div>
          </Col>
          <Col span={6} className="t-center pl-20 "></Col>
          <Col span={6} className="t-center pl-20 ">
            {`${t("location.title")}`}
          </Col>
          <Col span={6} className="t-center pl-20 "></Col>
        </Row>
      </div>
    </div>
  );
};

export default PrintReceipt;
