import ImageCanvas from "@components/drawing_canvas/ImageCanvas";
import FrontMarker from "@assets/img/FrontCanvas.png";
import { useMainImageCanvasGroup } from "@provider/image_canvas";

const Front = () => {
  const { imageFrontCanvasRef, appointmentsByID, visitsByID, SOAP } =
    useMainImageCanvasGroup();

  return (
    <div>
      <ImageCanvas
        imageCanvasRef={imageFrontCanvasRef}
        image={
          SOAP
            ? visitsByID?.visitMarker?.soapMarker0?.[0]?.image || FrontMarker
            : `${
                appointmentsByID?.visit?.visitMarker?.doctorMarker0?.[0]
                  ?.image ||
                visitsByID?.visitMarker?.doctorMarker0?.[0]?.image ||
                FrontMarker
              }`
        }
      />
    </div>
  );
};

export default Front;
