import { dayjs } from "@tools/timezone";
import { EVENT } from "@assets/data/pathRouter";
import { ENUM_ORDERBY } from "@tools/util.enum";
import { ICurrentPage } from "@interface/IGlobal.interface";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useGetEventMyBranch } from "@services/api/event";
import { RouteComponentProps, useHistory } from "react-router-dom";
import MoTable from "@components/table/Table";

type Props = {
  searchValue: any;
  currentPage: ICurrentPage;
};

const Table = ({ searchValue, currentPage }: Props) => {
  const { t } = useTranslation();
  const [limitPage, setLimitPage] = useState<number>(10);
  const history = useHistory<RouteComponentProps["history"]>();
  const [total, setTotal] = useState<number>(1);
  const eventList = useGetEventMyBranch({
    page: currentPage.currentPage,
    limit: limitPage,
    orderby: ENUM_ORDERBY.DESC,
    ...searchValue,
  });

  useEffect(() => {
    if (!eventList?.data?.result?.[0]?.total) return;
    setTotal(eventList?.data?.result?.[0]?.total || 1);
    // eslint-disable-next-line
  }, [eventList?.data]);

  const onChangePage = (page: number, type?: string) => {
    if (type === "pageSize") setLimitPage(page);
    else currentPage.setCurrentPage(page);
  };

  const onClickRow = (event: any) => {
    history.push(`${EVENT.detail}/${event.id}`);
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      render: (_: any, data: any, index: number) => {
        return (
          <div key={data?.id}>
            {limitPage * (currentPage.currentPage - 1) + index + 1}
          </div>
        );
      },
    },

    {
      title: t("eventName"),
      dataIndex: "name",
      key: "name",
    },

    {
      title: t("startDate"),
      dataIndex: "startDate",
      key: "startDate",
      render: (startDate: string) => {
        return <>{dayjs(startDate).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: t("endDate"),
      dataIndex: "endDate",
      key: "endDate",
      render: (endDate: string) => {
        return <>{dayjs(endDate).format("DD-MM-YYYY")}</>;
      },
    },

    {
      title: t("place"),
      dataIndex: "location",
      key: "location",
      render: (location: string) => {
        return <div className="f-normal ">{location}</div>;
      },
    },
    {
      title: t("details"),
      dataIndex: "detail",
      key: "detail",
      render: (detail: string) => {
        return <div className="text-content">{detail}</div>;
      },
    },
  ];

  return (
    <>
      <MoTable
        rowKey="id"
        dataSource={eventList?.data?.result?.[0]?.data || []}
        columns={columns}
        onChangePage={onChangePage}
        config={{
          total: total,
          pageSize: limitPage,
          currentPage: currentPage.currentPage,
        }}
        onClickRow={onClickRow}
        scroll={{ x: "calc(100vw -20%)" }}
      />
    </>
  );
};

export default Table;
