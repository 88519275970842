import { useTranslation } from "react-i18next";
import { useMainDashboard } from "@provider/dashboard";
import { Card } from "antd";
import MoTable from "@components/table/Table";

const DashboardProduct = () => {
  const { t, i18n } = useTranslation();
  const { purchaseProduct } = useMainDashboard();

  const columns = [
    {
      title: t("productName"),
      dataIndex: "title",
      render: (_: string, result: any) => {
        return (
          <div>
            {i18n.language === "th"
              ? result?.nameTh || ""
              : result?.nameEn || ""}
          </div>
        );
      },
    },

    {
      title: t("sum"),
      dataIndex: "sum",
    },
  ];
  return (
    <div>
      <Card className="card-table-dashboard">
        <div>{t("productReport")}</div>

        <MoTable
          className="null"
          rowClassName={() => {
            return " no-header no-cursor";
          }}
          columns={columns}
          dataSource={purchaseProduct || []}
          pagination={false}
          scroll={{ y: 200, x: 500 }}
          rowKey="key"
          key="key"
        />
      </Card>
    </div>
  );
};

export default DashboardProduct;
