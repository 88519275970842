import { ENUM_G6PD } from "@tools/util.enum";
import { ISelected } from "@interface/IOrder.interface";
import { IGetVisits } from "@interface/IVisits.interface";
import { useTranslation } from "react-i18next";
import { ISelectedExecuteOrder } from "@interface/IWorkOrder.interface";
import { Card, Col, Form, FormInstance, Row } from "antd";
import moment from "moment";
import Fields from "./Fields";
import Advice from "@components/advice/Advice";
import SymbolView from "@components/symbol/Symbol";
import ImagePatient from "@components/image/ImagePatient";
import ImportantInfo from "@components/historical_info/ImportantInfo";

type Props = {
  patientID: number | string;
  orderList: ISelectedExecuteOrder[];
  visitsByID: IGetVisits | undefined;
  patientList: FormInstance;
  selection: ISelected;
};

const FormProfile = ({
  patientID,
  orderList,
  visitsByID,
  patientList,
  selection,
}: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Card
        className="bg-grey m-1-doctor-room height-body scrollbar-list "
        extra={
          <>
            <Row gutter={[14, 14]} align="middle">
              <Col>
                <ImagePatient src={visitsByID?.patient?.image} />
              </Col>
              <Col>
                <Row>
                  <Col xs={24} lg={24}>
                    {i18n.language === "th"
                      ? [
                          visitsByID?.patient?.firstNameTh,
                          visitsByID?.patient?.lastNameTh,
                        ]
                          ?.join(" ")
                          ?.trim()
                      : [
                          visitsByID?.patient?.firstNameEn,
                          visitsByID?.patient?.lastNameEn,
                        ]
                          ?.join(" ")
                          ?.trim()}
                  </Col>

                  <Col xs={24} lg={24}>
                    <div className="code-profile">
                      HN : {visitsByID?.patient?.hn}
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col style={{ marginLeft: "auto" }}>
                <div className="t-center ">
                  <div className=" t-grey fs-18 ">{t("orderNumber")} </div>
                  <div className=" t-grey-light fs-12 ">
                    {visitsByID?.orderNo || ""}{" "}
                  </div>
                </div>
              </Col>
            </Row>
          </>
        }
      >
        <Row justify="space-between" align="middle">
          <Col>
            <div className="mb-05">
              <span className=" t-blue fs-18 ">{t("fullList")} </span>
              <span className=" t-grey-light  fs-12 ">
                {`${t("orderCreationDate")}:`}{" "}
              </span>
              <span className="t-grey fs-12 ">
                {" "}
                {visitsByID
                  ? `${moment(visitsByID?.date).format("DD-MM-YYYY")}`
                  : ``}
              </span>
            </div>
          </Col>
          <Col style={{ marginLeft: "auto" }}>
            <div style={{ color: "#FEB139" }}>
              <SymbolView />
            </div>
            <div
              style={{
                color: "red",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {visitsByID?.patient?.g6pd === ENUM_G6PD.DEFICIENCY
                ? "G6PD Deficiency"
                : null}
            </div>
          </Col>
        </Row>

        <Form name="patientList" form={patientList}>
          <ImportantInfo />
        </Form>
        <Advice visitsByID={visitsByID} />

        <Fields
          patientID={patientID}
          orderList={orderList}
          selection={selection}
          visitsByID={visitsByID}
        />
      </Card>
    </>
  );
};

export default FormProfile;
