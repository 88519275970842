import { Tabs } from "antd";
import { useTranslation } from "react-i18next";
import Agent from "./agent/Agent";
import Prefix from "./prefix/Prefix";
import Country from "./country/Country";
import Nationality from "./nationality/Nationality";
import Citizenship from "./citizenship/Citizenship";
import ContractCompany from "./contract_company/ContractCompany";
type Props = {
  tabsGeneral: string;
  setTabsGeneral: (e: string) => void;
};

const TabsGeneral = ({ tabsGeneral, setTabsGeneral }: Props) => {
  const { t } = useTranslation();
  const { TabPane } = Tabs;

  const tabsList = [
    { name: t("namePrefix"), key: "1", display: <Prefix /> },
    { name: t("nationality"), key: "2", display: <Nationality /> },
    { name: t("citizenship"), key: "3", display: <Citizenship /> },
    { name: t("country"), key: "4", display: <Country /> },
    { name: t("contractCompany"), key: "5", display: <ContractCompany /> },
    { name: t("recommender"), key: "6", display: <Agent /> },
  ];

  const callBack = (key: string) => {
    setTabsGeneral(key);
  };

  return (
    <Tabs
      defaultActiveKey={tabsGeneral}
      className="tab-printdraw "
      onChange={callBack}
    >
      {tabsList?.map((item) => {
        return (
          <TabPane tab={item?.name} key={item?.key} className="mt-2">
            {item?.display}
          </TabPane>
        );
      })}
    </Tabs>
  );
};

export default TabsGeneral;
