import { useEffect } from "react";
import { ENUM_ROLE_EN } from "@tools/util.enum";
import { USER_MANAGE } from "@assets/data/pathRouter";
import { roleEn, roleTh } from "@assets/data/data";
import { IRoleFormValue } from "@interface/IRole.interface";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { useGetBranchList } from "@services/api/branch";
import { RouteComponentProps } from "react-router-dom";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { IPermission, ISubMenu } from "@interface/IRole.interface";
import { useCreateRole, useGetRoleForm } from "@services/api/role";
import { Checkbox, Col, Form, FormInstance, Input, Row, Switch } from "antd";
import React from "react";
import Selector from "@components/selector/Selector";
import UserContext from "@provider/UserProvider";

type Props = {
  formRole: FormInstance;
  history: RouteComponentProps["history"];
};
interface IRoleForm {
  value?: IPermission[];
  formRole: FormInstance;
  onChange?: (e?: IPermission[]) => void;
}

const Fields = ({ formRole, history }: Props) => {
  const { t, i18n } = useTranslation();
  const roleForm = useGetRoleForm();
  const createRole = useCreateRole();
  const branchList = useGetBranchList(true, { limit: 0 });
  const userList = React.useContext(UserContext);

  useEffect(() => {
    if (!roleForm?.data?.result?.length) {
      return;
    }

    formRole?.resetFields();
    formRole.setFieldsValue({
      ...roleForm?.data?.result?.[0],
      permission: roleForm?.data?.result?.[0]?.permission,
      branchId:
        userList?.role !== ENUM_ROLE_EN.SUPER_ADMIN
          ? branchList?.data?.result?.[0]?.data?.[0]?.id
          : undefined,
    });
    // eslint-disable-next-line
  }, [roleForm?.data?.result?.length, branchList?.data?.result?.[0]]);

  const onFinish = (data: IRoleFormValue) => {
    const reformed =
      data?.nameEn === ENUM_ROLE_EN.OTHER
        ? {
            ...data,
            nameTh: data?.nameOtherTh,
            nameEn: data?.nameOtherEn,
          }
        : {
            ...data,
          };
    delete reformed?.nameOtherTh;
    delete reformed?.nameOtherEn;

    createRole.mutate(
      { data: reformed },
      {
        onSuccess: () => {
          openNotification({ method: "POST_SUCCESS" });
          history.replace(`${USER_MANAGE.title}`);
        },
        onError: (res) => {
          openNotification({ method: "POST_ERROR", message: String(res) });
        },
      }
    );
  };

  return (
    <div>
      {roleForm?.data?.result ? (
        <Form name="roleForm" form={formRole} onFinish={onFinish}>
          <Row justify="center" align="middle" gutter={[14, 1]}>
            <Col md={24} lg={12}>
              <Form.Item
                name="nameTh"
                label={`${t("name")} (TH)`}
                rules={[{ required: true }]}
              >
                <Selector
                  Source={roleTh}
                  keyName="name"
                  keyValue="id"
                  onChange={(e) => {
                    formRole.setFieldsValue({ nameEn: e });
                  }}
                />
              </Form.Item>
            </Col>

            <Col md={24} lg={12}>
              <Form.Item
                name="nameEn"
                label={`${t("name")} (EN)`}
                rules={[{ required: true }]}
              >
                <Selector
                  Source={roleEn}
                  keyName="name"
                  keyValue="id"
                  onChange={(e) => {
                    formRole.setFieldsValue({ nameTh: e });
                  }}
                />
              </Form.Item>
            </Col>

            <Form.Item
              shouldUpdate={(prev, next) => prev?.nameEn !== next?.nameEn}
              noStyle
            >
              {(form: FormInstance) => {
                const name = form.getFieldValue("nameEn");
                if (name === ENUM_ROLE_EN.OTHER) {
                  return (
                    <>
                      <Col md={24} lg={12}>
                        <Form.Item
                          name="nameOtherTh"
                          label={`${t("nameOther")} (TH)`}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={24} lg={12}>
                        <Form.Item
                          name="nameOtherEn"
                          label={`${t("nameOther")} (EN)`}
                          rules={[{ required: true }]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </>
                  );
                }
              }}
            </Form.Item>

            <Col md={24} lg={24}>
              <Form.Item
                label={`${t("branch")}`}
                name="branchId"
                rules={[{ required: true }]}
              >
                <Selector
                  Source={branchList?.data?.result?.[0]?.data || []}
                  keyName={i18n.language === "th" ? "nameTh" : "nameEn"}
                  keyValue="id"
                  disabled={
                    userList?.role !== ENUM_ROLE_EN.SUPER_ADMIN ? true : false
                  }
                />
              </Form.Item>
            </Col>

            <Col md={15} lg={15} className="pt-1">
              <Form.Item name="permission">
                <RoleForm formRole={formRole} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <div className="fs-18">
          {i18n.language === "th" ? roleForm?.data?.TH : roleForm?.data?.EN}
        </div>
      )}
    </div>
  );
};

const RoleForm = ({ value, formRole, onChange }: IRoleForm) => {
  const { t, i18n } = useTranslation();
  const onUpdateIsEdit = (e: boolean, name: string) => {
    const next = value?.map((item: IPermission) => {
      if (item?.nameTh === name) {
        if (e === true) {
          return {
            ...item,
            isEdit: e,
            subMenu: item?.subMenu?.map((subMenu) => {
              return { ...subMenu, isEdit: true };
            }),
          };
        } else {
          return {
            ...item,
            isEdit: e,
            subMenu: item?.subMenu?.map((subMenu) => {
              return { ...subMenu, isEdit: false };
            }),
          };
        }
      } else {
        return {
          ...item,
          subMenu: item?.subMenu?.map((subMenu) => {
            if (subMenu?.nameTh === name) {
              return { ...subMenu, isEdit: e };
            } else {
              return subMenu;
            }
          }),
        };
      }
    });

    formRole.setFieldsValue({ ...formRole });
    onChange && onChange(next);
  };

  const onUpdate = (e: boolean, name: string) => {
    const next = value?.map((item: IPermission) => {
      if (item?.nameTh === name) {
        if (e === true) {
          return {
            ...item,
            active: e,
            subMenu: item?.subMenu?.map((subMenu) => {
              return { ...subMenu, active: true };
            }),
          };
        } else {
          return {
            ...item,
            active: e,
            subMenu: item?.subMenu?.map((subMenu) => {
              return { ...subMenu, active: false };
            }),
          };
        }
      } else {
        return {
          ...item,
          subMenu: item?.subMenu?.map((subMenu) => {
            if (subMenu?.nameTh === name) {
              return { ...subMenu, active: e };
            } else {
              return subMenu;
            }
          }),
        };
      }
    });

    formRole.setFieldsValue({ ...formRole });
    onChange && onChange(next);
  };

  return (
    <div>
      {value?.map((item, index) => {
        return (
          <div key={item?.nameEn}>
            <Row key={index} align="middle" className="pb-05">
              <Col span={20}>
                <Row gutter={[14, 14]} justify="center">
                  <Col span={24} className="role-col">
                    <Row className="role-col-area">
                      <Col>
                        {" "}
                        {i18n.language === "th"
                          ? item?.nameTh || ""
                          : item?.nameEn || ""}
                      </Col>
                      <Col>
                        <Switch
                          checked={item?.active}
                          onChange={(e: boolean) => onUpdate(e, item?.nameTh)}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>

              {item?.subMenu?.length > 0 ? null : (
                <Col span={4} className="pl-1">
                  <Checkbox
                    checked={item?.isEdit}
                    onChange={(e: CheckboxChangeEvent) =>
                      onUpdateIsEdit(e?.target?.checked, item?.nameTh)
                    }
                  >
                    {`${t("edit")}/${t("delete")}`}
                  </Checkbox>
                </Col>
              )}
            </Row>
            {item?.subMenu?.map((subMenu: ISubMenu, i: number) => {
              return (
                <Row key={subMenu?.nameEn} align="middle" className="pb-05">
                  <Col span={20}>
                    <Row gutter={[14, 14]} justify="end">
                      <Col span={22} className="role-col">
                        <Row className="role-col-area">
                          <Col>
                            {i18n.language === "th"
                              ? subMenu?.nameTh || ""
                              : subMenu?.nameEn || ""}
                          </Col>
                          <Col>
                            <Switch
                              disabled={item?.active ? false : true}
                              checked={subMenu?.active}
                              onChange={(e: boolean) =>
                                onUpdate(e, subMenu?.nameTh)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                  <Col span={4} className="pl-1">
                    <Checkbox
                      checked={subMenu?.isEdit}
                      onChange={(e: CheckboxChangeEvent) =>
                        onUpdateIsEdit(e?.target?.checked, subMenu?.nameTh)
                      }
                    >
                      {`${t("edit")}/${t("delete")}`}
                    </Checkbox>
                  </Col>
                </Row>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default Fields;
