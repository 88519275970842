import { Modal } from "antd";
import { useTranslation } from "react-i18next";
import ViewDrawingCanvas from "./ViewDrawingCanvas";

type Props = {
  visible: any;
  setModal: () => void;
  drawing?: string;
  title?: string;
};

const ViewImage = ({ visible, setModal, drawing, title }: Props) => {
  const { t } = useTranslation();

  return (
    <div>
      <Modal
        className="view-img"
        title={
          <>
            <div className="t-blue"> {title ? title : t("doctorOpinion")} </div>
          </>
        }
        centered
        visible={visible}
        onOk={setModal}
        onCancel={setModal}
        width="65%"
        footer={null}
      >
        <ViewDrawingCanvas drawing={drawing} />
      </Modal>
    </div>
  );
};

export default ViewImage;
