import { dayjs } from "@tools/timezone";
import { useForm } from "antd/lib/form/Form";
import { keyName } from "@interface/IVisits.interface";
import { useState } from "react";
import { useRouteMatch } from "react-router-dom";
import { useQueryClient } from "react-query";
import { useTranslation } from "react-i18next";
import { openNotification } from "@components/notification/Notification";
import { IFile, IVisitFile } from "@interface/IVisitFile.interface";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDeleteVisitFile, useGetVisitFile } from "@services/api/visit_file";
import React from "react";
import MoTable from "@components/table/Table";
import Modaler from "@components/modaler/Modaler";
import EditFileName from "@components/edit_file_name/EditFileName";

type Props = {
  [keyName: string]: keyName;
};

const Table = ({ keyName }: Props) => {
  const { t } = useTranslation();
  const [byID, setByID] = useState<number>();
  const match: any = useRouteMatch();
  const deleteFile = useDeleteVisitFile();
  const visitID = match.params.visitID;
  const visitByID = useGetVisitFile(keyName, visitID);
  const [isModalOpen, setIsModalOpen] = useState({
    delete: false,
    update: false,
  });
  const [form] = useForm();
  const client = useQueryClient();

  const onClickRow = (event: IVisitFile) => {
    window.open(event?.file?.presigned);
  };

  const onManageModal = (e: boolean, upData?: boolean) => {
    setIsModalOpen({
      ...isModalOpen,
      update: e,
    });
    if (!!upData) {
      client.invalidateQueries([`get-visit-file-${keyName}`, keyName, visitID]);
    }
  };

  const onModal = () => {
    setIsModalOpen({
      ...isModalOpen,
      delete: !isModalOpen?.delete,
    });
  };

  const onDelete = () => {
    deleteFile.mutate(byID, {
      onSuccess: () => {
        openNotification({ method: "DEL_SUCCESS" });
      },
      onError: (res) => {
        openNotification({ method: "DEL_ERROR", message: String(res) });
      },
      onSettled: () => {
        client.invalidateQueries([
          `get-visit-file-${keyName}`,
          keyName,
          visitID,
        ]);
        onModal();
      },
    });
  };

  const columns: any = [
    {
      title: t("sort"),
      dataIndex: "id",
      key: "index",
      align: "center",
      width: "14%",
      render: (_: any, data: IVisitFile, index: number) => {
        return <div key={data?.id}>{index + 1}</div>;
      },
    },

    {
      title: `${t("creationDate")}`,
      dataIndex: "file",
      key: "file",
      render: (file: IFile) => {
        return <>{dayjs(file?.createdAt).format("DD-MM-YYYY HH:mm")}</>;
      },
    },

    {
      title: t("fileName"),
      dataIndex: "file",
      key: "file",
      render: (file: IFile) => {
        return <>{file ? file?.fileName : "-"}</>;
      },
    },

    {
      title: t("manage"),
      dataIndex: "data",
      key: "data",
      render: (_: string, data: IVisitFile) => {
        return (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div style={{ paddingRight: 10, color: "#094C97" }}>
              <EditOutlined
                onClick={() => {
                  onManageModal(!isModalOpen?.update);

                  let name = data?.file?.fileName.split(".");
                  let lastName = data?.file?.fileName?.slice(
                    data?.file?.fileName?.length -
                      Number(name?.[name?.length - 1]?.length + 1),
                    data?.file?.fileName.length
                  );

                  form.setFieldsValue({
                    name: data?.file?.fileName?.slice(
                      0,
                      data?.file?.fileName?.length -
                        Number(name?.[name?.length - 1]?.length + 1)
                    ),
                    lastName: lastName,
                    id: data?.file?.id,
                  });
                }}
              />
            </div>
            <div style={{ color: "red" }}>
              <DeleteOutlined
                onClick={() => {
                  setByID(data?.id);
                  onModal();
                }}
              />
            </div>
          </div>
        );
      },
    },
  ];
  return (
    <React.Fragment>
      {!!isModalOpen.delete && (
        <Modaler
          visible={isModalOpen.delete}
          onCancel={onModal}
          onOk={onDelete}
          loadingButton={deleteFile?.isLoading}
          width="45%"
          header={
            <div>
              <ExclamationCircleOutlined className="icon-warning-del" />
              {`${t("areYouSure")}`}
            </div>
          }
        >
          {`${t("doYouWantToDelete")}`}
        </Modaler>
      )}
      <MoTable
        dataSource={visitByID?.data?.result || []}
        pagination={false}
        columns={columns}
        scroll={{ x: "calc(100vw -20%)" }}
        onClickRow={onClickRow}
      />
      {!!isModalOpen && (
        <EditFileName
          isModalOpen={isModalOpen.update}
          onManageModal={onManageModal}
          form={form}
        />
      )}
    </React.Fragment>
  );
};

export default Table;
