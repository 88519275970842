import { onImgError } from "@tools/util";
import { EyeOutlined } from "@ant-design/icons";
import { Image, ImageProps } from "antd";

interface Props extends ImageProps {}

const ImagePatient = ({ ...props }: Props) => {
  return (
    <Image
      {...props}
      className="img-profile"
      src={props?.src || onImgError()}
      preview={{
        mask: <EyeOutlined />,
      }}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null; // prevents looping
        currentTarget.src = onImgError();
      }}
    />
  );
};

export default ImagePatient;
