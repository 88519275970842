import { useTranslation } from "react-i18next";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";

type Props = {
  onFinish: (value: any) => void;
};

const Search = ({ onFinish }: Props) => {
  const { t } = useTranslation();
  const dateFormat = "DD-MM-YYYY";
  return (
    <div className="pb-1">
      <Form onFinish={onFinish}>
        <Row gutter={[14, 14]}>
          <Col lg={9} md={6} xl={9}>
            <Form.Item name="name">
              <Input placeholder={`${t("search")}`} />
            </Form.Item>
          </Col>

          <Col xl={5} xxl={5}>
            <Form.Item name="date">
              <DatePicker
                format={dateFormat}
                placeholder={t("chooseDateLeadingSymptoms")}
              />
            </Form.Item>
          </Col>

          <Col>
            <Button className="mr-1 btn-search" htmlType="submit">
              {t("search")}
            </Button>
            <Button className="clear" htmlType="reset">
              {t("reset")}
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Search;
