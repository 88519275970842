import React, { useState } from "react";
import { OPD } from "@assets/data/pathRouter";
import { useTranslation } from "react-i18next";
import { Button, Col, Row } from "antd";
import { RouteComponentProps } from "react-router-dom";
import { useHistory, useRouteMatch } from "react-router-dom";
import BackHome from "@components/button/BackHome";
import DetailLabInterface from "@components/lab_result/LabResult";

const LabResult = () => {
  const history = useHistory<RouteComponentProps["history"]>();
  const { t } = useTranslation();
  const match: any = useRouteMatch();
  const patientID = match.params.patientID;
  const visitID = match.params.visitID;
  const [visible, setVisible] = useState(false);

  const onModal = () => {
    setVisible(!visible);
  };

  const handlePrint = () => {
    history.push(
      `${OPD.printDetailLabResult}/patient/${patientID}/visit/${visitID}`
    );
  };
  return (
    <React.Fragment>
      <Row className="second-header " justify="space-between" align="middle">
        <Col>
          <Row gutter={[14, 14]}>
            <BackHome history={history} />

            <Col className="header-text">
              <div>
                <h1>{t("medicalRecord.title")}</h1>
              </div>
              <span className="t-light-gray">
                {`${t("nursery")} / ${t("historyFile")} / ${t(
                  "treatmentHistory"
                )} / ${t("details")} / `}
              </span>
              <span className="t-dark-blue f-bold">{t("labResults")}</span>
            </Col>
          </Row>
        </Col>

        <Col className="d-flex justify-between justify-col">
          <Button
            className="mr-05 reserve"
            htmlType="reset"
            onClick={handlePrint}
          >
            {t("print")}
          </Button>

          <Button className="mr-05 reserve" htmlType="reset" onClick={onModal}>
            {t("view")}
          </Button>
        </Col>
      </Row>
      <DetailLabInterface
        onModal={onModal}
        visible={{ visible: visible, setVisible: setVisible }}
      />
    </React.Fragment>
  );
};

export default LabResult;
