import React, { FC } from "react";
import { t } from "i18next";
import { dayjs } from "@tools/timezone";
import { useTranslation } from "react-i18next";
import { Col, Row } from "antd";
import logo from "@assets/img/logo/Ab.png";

type Props = {
  visitsByID?: {
    patient: {
      hn: string;
      titleTh: string;
      lastNameTh: string;
      firstNameTh: string;
      titleEn: string;
      firstNameEn: string;
      lastNameEn: string;
    };
    nameShown?: string;
    frequencyTh?: string;
    frequencyEn?: string;
    genericName?: string;
    eatingPattern?: string[];
    quantityUsed?: number;
    unitAlt?: string;
    unitAltTh?: string;
    unitAltEn?: string;
    timeSpent?: string[];
    specialInstructions?: string;
    descriptionTh?: string;
    descriptionEn?: string;
  };
};

const View: FC<Props> = ({ visitsByID }) => {
  const { i18n } = useTranslation();

  return (
    <div className="drugLabel t-black " style={{ padding: 4 }}>
      <div className="pr-05 pl-05  ">
        <Row justify="space-between">
          <Col className="fs-10-drug f-bold t-black">
            <div className="col-print-none">
              <img
                src={logo}
                alt="logo"
                style={{ width: "3cm", height: "1.1cm" }}
                className="image-print-none"
              />
            </div>
          </Col>
          <Col className="fs-10-drug f-bold t-black">
            <div>
              <div className="fs-12-drug f-bold t-black">
                HN : {`${visitsByID?.patient?.hn}`}
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <div className="pr-05 pl-05 pb-05 ">
        <div className="fs-12-drug">
          <span className="fs-12-drug f-bold t-black">
            {i18n?.language === "th"
              ? `${[
                  visitsByID?.patient?.titleTh,
                  visitsByID?.patient?.firstNameTh,
                  visitsByID?.patient?.lastNameTh,
                ]
                  ?.join(" ")
                  ?.trim()}`
              : `${[
                  visitsByID?.patient?.titleEn,
                  visitsByID?.patient?.firstNameEn,
                  visitsByID?.patient?.lastNameEn,
                ]
                  ?.join(" ")
                  ?.trim()}`}
          </span>
        </div>
        <React.Fragment>
          <div className="f-bold t-black fs-12-drug ">
            <span className="t-black">{`${
              visitsByID?.nameShown || ""
            }  `}</span>
            <span className="f-normal t-black">{`(${
              visitsByID?.genericName || ""
            })`}</span>
          </div>
        </React.Fragment>

        <div className="fs-12-drug t-black">
          <>
            <span>
              {`${t("oneMealAtATime")} ${visitsByID?.quantityUsed || 0} ${
                i18n?.language === "th"
                  ? visitsByID?.unitAltTh || ""
                  : visitsByID?.unitAltEn || ""
              }`}
            </span>
            {visitsByID?.eatingPattern?.map((item: string) => {
              return (
                <span key={item} className=" fs-12-drug ">
                  {` ${t(`${item}`)} `}
                </span>
              );
            })}
          </>
        </div>

        <div className="fs-12-drug t-black">
          <React.Fragment>
            {`${t("aDay")} `}
            <span className="fs-12-drug ">
              {`${visitsByID?.timeSpent?.length || 0} ${t("time1")}`}
            </span>
            {visitsByID?.timeSpent?.map((item: string) => {
              return (
                <span key={item} className=" fs-12-drug ">
                  {` ${t(`${item}`)} `}
                </span>
              );
            })}
          </React.Fragment>
        </div>
        <div className="fs-12-drug t-black">
          <span className="fs-12-drug f-bold t-black">
            {` ${visitsByID?.specialInstructions || ""}`}
          </span>
        </div>
        <div className="fs-12-drug t-black">
          <span className="fs-12-drug f-bold t-black">
            {` ${
              i18n.language === "th"
                ? visitsByID?.descriptionTh
                : visitsByID?.descriptionEn || ""
            }`}
          </span>
        </div>
        <Row justify="space-between">
          <Col className="fs-10-drug f-bold t-black"></Col>

          <div style={{ position: "absolute", bottom: 5, right: 10 }}>
            <span className="fs-10-drug f-bold t-black">
              {dayjs().format("DD-MM-YYYY")}
            </span>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default View;
